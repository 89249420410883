import React, {useEffect} from "react";

import {ReactComponent as IconCancel} from "../../../../images/cancel_24.svg";
import NmModal from "../../../ActualComponents/NmModal";
import ApplyButtons from "../../../ApplyButtons";
import NmBadge from "../../../NmBadge";
import NmTitle from "../../../NmTitle";

import {COLOR} from "../../../../constants/color";

import "./style.sass";

export const SmartLinkViewAllNumbers = (props) => {
    const {
        nums,
        onClose,
        headerAllNumbers,
        onClickDelete,
        onClickDeleteAll,
        clearError,
    } = props;

    useEffect(() => {
        if (nums.length === 0) {
            onClose();
            clearError();
        }
    }, [nums]);

    return (
        <NmModal
            size="md"
            isShowBackButton={true}
            isBackIconAlwaysVisible={true}
            goBack={onClose}
            header={
                <NmTitle size="lg">
                    {headerAllNumbers}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    className="justify-content-start"
                    cancelBtnContent="Удалить список"
                    onClose={onClickDeleteAll}
                />
            }
            classNameContent="all-numbers__content flex-column"
            onClose={onClose}
        >
            {
                nums.map((num, index) => {
                    return (
                        <NmBadge
                            key={num}
                            className="mt-2 me-2"
                            text={num}
                            mod="gray"
                            rightIcon={
                                <IconCancel
                                    cursor="pointer"
                                    title="Удалить"
                                    height={16}
                                    width={16}
                                    color={COLOR.SECONDARY_45}
                                    onClick={() => {
                                        onClickDelete(index, num);
                                    }}
                                />
                            }
                        />
                    );
                })
            }
        </NmModal>
    );
};