import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateContraindicationsWork, {defaultContraindicationsWorkForm} from "./hooks/useUpdateContraindicationsWork";

export default function ContractorContraindicationsWorkEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultContraindicationsWorkForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const {
        onChangeForm,
        submit,
        form,
        setForm,
        error,
    } = useUpdateContraindicationsWork({contractorId, onClose, getProfile});

    useEffect(() => {
        if (editData.workContraindications) {
            setForm({...editData});
        }
    }, [editData.workContraindications]);

    const {
        workContraindications,
        hasWorkContraindications,
    } = form;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Противопоказания к работе
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmCheckboxV2
                    name="hasWorkContraindications"
                    checked={hasWorkContraindications}
                    onChange={onChangeForm}
                    label="Противопоказания к работе отсутствуют"
                />
                <div className="nm-line" />
                {!hasWorkContraindications && <NmTextareaV2
                    error={error.workContraindications}
                    minRows={1}
                    maxRows={7}
                    required
                    maxLength={255}
                    label="Противопоказания к работе"
                    value={workContraindications}
                    name="workContraindications"
                    onChange={onChangeForm}
                />}
            </NmForm>
        </NmModal>
    );
}