import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {getKedoDocumentTypesValidation} from "../utils/getValidation";

import {kedoDirectoriesProgressActionSelector} from "../../../../../../../ducks/kedo/directories/selectors";

import {
    addAdmKedoTemplateDocumentType,
    updateAdmKedoTemplateDocumentType,
} from "../../../../../../../ducks/bff/adm/settings/directories/kedo/template-document-types/services";

export const useKedoTemplateDocumentTypesEdit = (params) => {
    const {
        fetchList,
        editData,
        onClose,
        fetchCounts,
    } = params;

    const dispatch = useDispatch();

    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    useEffect(() => {
        if (editData) {
            setValues({
                ...values,
                ...editData,
            });
        }
    }, []);

    const {
        handleSubmit,
        values,
        touched,
        setValues,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            name: "",
            nameForClient: "",
            templateId: "",
            availableClientUsers: true,
            onlyUkep: true,
        },
        validationSchema: getKedoDocumentTypesValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        const {
            name,
            nameForClient,
            templateId,
            onlyUkep,
            availableClientUsers,
        } = values;

        const requestData = {
            name,
            nameForClient,
            templateId,
            onlyUkep,
            availableClientUsers,
        };

        if (editData) {
            const {
                documentTypeId,
            } = editData;

            dispatch(updateAdmKedoTemplateDocumentType({
                documentTypeId,
                ...requestData,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(addAdmKedoTemplateDocumentType({
            ...requestData,
            onSuccess: () => {
                fetchList();
                fetchCounts();
                onClose();
            },
        }));
    }

    return {
        errors,
        handleSubmit,
        values,
        progressAction,
        onChange,
        touched,
    };
};