import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";

import ErrorTooltip from "../ErrorTooltip";
import NmDatePicker from "../NmDatePicker";
import NmLabel from "../NmLabel";

import {useClickOutside} from "../../../hooks/useClickOutside";

import bem from "../../../utils/bem";
import dateFormat from "../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import "./style.sass";

const NmDateRangePickerV2 = props => {
    const {
        isCurrentDateMax,
        handleOnChangeRangeDatepicker = () => {},
        className = "",
        onChange = () => {},
        onBlur,
        isClearable,
        placeholderStart = "дд.мм.гг",
        placeholderEnd = "дд.мм.гг",
        startFieldName,
        endFieldName,
        value = {},
        minDate = {
            start: null,
            end: null,
        },
        required = false,
        maxDate = {
            start: null,
            end: null,
        },
        returnString = true,
        size,
        isRelative,
        error,
        label,
        disabled,
        readOnlyInput,
        classNameError = "",
        suggestion,
        onMouseEnter,
        isVisibleTooltip,
        tooltip,
        showTimeSelect,
        datePickerDateFormat = "dd.MM.yy",
        timeIntervals,
        dateFormatMask,
    } = props;

    const [period, setPeriod] = useState({
        to: null,
        from: null,
    });
    const {
        to,
        from,
    } = period;

    const [block, element] = bem("nm-datepicker-range-v2", className);
    const [focusedStart, setFocusedStart] = useState(false);
    const [focusedEnd, setFocusedEnd] = useState(false);

    useEffect(() => {
        setPeriod({
            from: isNullOrWhitespace(value[startFieldName]) ? null : new Date(value[startFieldName]),
            to: isNullOrWhitespace(value[endFieldName]) ? null : new Date(value[endFieldName]),
        });

        if ((focusedStart || focusedEnd) && !disabled) {
            setFocusedStart(false);
            setFocusedEnd(false);
        }
    }, [value]);

    const handleOnChangeDatepicker = (value, name) => {
        if (returnString) {
            value = dateFormat(value, "yyyy-MM-dd");
        }

        handleOnChangeRangeDatepicker(value, name);
        onChange(null, {value, name: name});
    };

    const currentDate = isCurrentDateMax ? new Date() : null;

    const rootRef = useClickOutside(() => {
        setFocusedStart(false);
        setFocusedEnd(false);
    }, focusedStart || focusedEnd);

    const onMouseDown = () => {
        if (disabled) {
            return;
        }

        setFocusedStart(true);
        setFocusedEnd(true);
    };
    const onCalendarOpenStart = () => setFocusedStart(true);
    const onCalendarCloseStart = () => setFocusedStart(false);
    const onCalendarOpenEnd = () => setFocusedEnd(true);
    const onCalendarCloseEnd = () => setFocusedEnd(false);

    const focused = focusedStart || focusedEnd;
    const active = Boolean(from || to);
    const _error = Boolean(error);

    return (
        <div className={block()}>
            {
                label &&
                        <NmLabel
                            isVisibleTooltip={isVisibleTooltip}
                            required={required}
                            disabled={disabled}
                            label={label}
                            tooltip={tooltip}
                        />
            }
            <div
                tabIndex={0}
                ref={rootRef}
                onMouseEnter={onMouseEnter}
                className={element("control", {
                    focused,
                    error: _error,
                    size,
                    disabled,
                })}
            >
                <NmDatePicker
                    onCalendarOpen={!disabled && onCalendarOpenStart}
                    onCalendarClose={!disabled && onCalendarCloseStart}
                    name={startFieldName}
                    size={size}
                    classNameField={element("start-date", {
                        focused,
                        active,
                        error: _error,
                    })}
                    placeholderText={placeholderStart}
                    dateFormat={datePickerDateFormat}
                    minDate={minDate.start}
                    maxDate={maxDate.start || to || currentDate}
                    onChange={(event, {value}) => {
                        handleOnChangeDatepicker(value, startFieldName);
                    }}
                    selected={from}
                    onMouseDownInput={!disabled && onMouseDown}
                    isClearable={isClearable}
                    required={required}
                    onBlur={onBlur}
                    isErrorStyle={error}
                    readOnlyInput={readOnlyInput}
                    disabled={disabled}
                    timeCaption="Время"
                    showTimeSelect={showTimeSelect}
                    timeIntervals={timeIntervals}
                    dateFormatMask={dateFormatMask}
                    isRelative={isRelative}
                />
                <div className={element("divider")} />
                {
                    suggestion &&
                        <div className={element("suggestion")}>
                            {suggestion}
                        </div>
                }
                <NmDatePicker
                    onCalendarOpen={!disabled && onCalendarOpenEnd}
                    onCalendarClose={!disabled && onCalendarCloseEnd}
                    name={endFieldName}
                    size={size}
                    classNameField={element("end-date", {
                        focused,
                        active,
                        error: _error,
                    })}
                    placeholderText={placeholderEnd}
                    dateFormat={datePickerDateFormat}
                    minDate={minDate.end || from}
                    maxDate={maxDate.end || currentDate}
                    onChange={(event, {value}) => {
                        handleOnChangeDatepicker(value, endFieldName);
                    }}
                    selected={to}
                    onMouseDownInput={!disabled && onMouseDown}
                    popperPlacement="bottom-end"
                    isClearable={isClearable}
                    onBlur={onBlur}
                    required={required}
                    isErrorStyle={error}
                    readOnlyInput={readOnlyInput}
                    disabled={disabled}
                    timeCaption="Время"
                    showTimeSelect={showTimeSelect}
                    timeIntervals={timeIntervals}
                    dateFormatMask={dateFormatMask}
                    isRelative={isRelative}
                />
            </div>
            {
                error &&
                    <ErrorTooltip
                        className={classNameError}
                        error={error}
                    />
            }
        </div>
    );
};

export default withTranslation()(NmDateRangePickerV2);