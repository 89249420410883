import React from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {
    REVIEW_REPLY_STATUS_DICT,
    REVIEW_STATUS_OPTIONS, 
    SCORE_DICT,
} from "../../../../constants/reviews";

function ReviewsFilter(props) {
    const {
        subpage,
        filter,
        submitFilter,
        onClear,
        onChangeFilter,
    } = props;

    const {
        clientIdsFilter,
        fioFilter,
        phoneFilter,
        reviewTextFilter,
        statusesFilter,
        replyStatusesFilter,
        scoreFilter,
    } = filter;

    const scoreOptions = dictionaryToOptions(SCORE_DICT);

    const submit = () => {
        submitFilter(filter);
    };

    return (
        <NmForm
            addMargin
        >
            <FilterButtonsV2
                className="order-templates-filter__filter-buttons"
                onClear={onClear}
                onSearch={submit}
            />
            <NmInputV2
                size="lg"
                label="ФИО исполнителя"
                placeholder="Введите ФИО исполнителя"
                name="fioFilter"
                value={fioFilter}
                onChange={onChangeFilter}
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChangeFilter}
            />
            <FilterCustomer
                name="clientIdsFilter"
                placeholder="Выберите заказчика"
                value={clientIdsFilter}
                onChange={onChangeFilter}
                isMountLoad={false}
                multiple
            />
            <NmInputV2
                size="lg"
                label="Ключевые слова"
                placeholder="Введите ключевые слова"
                name="reviewTextFilter"
                value={reviewTextFilter}
                onChange={onChangeFilter}
            />
            <NmDropdownV2
                isClearable
                placeholder="Все"
                emptyOptionText="Все"
                label="Оценка"
                name="scoreFilter"
                onChange={onChangeFilter}
                value={scoreFilter}
                options={scoreOptions}
                size="lg"
            />
            <NmDropdownV2
                multiple
                isClearable
                label="Статус отзыва"
                placeholder="Выберите статус отзыва"
                name="statusesFilter"
                onChange={onChangeFilter}
                value={statusesFilter}
                options={REVIEW_STATUS_OPTIONS}
                size="lg"
            />
            <NmDropdownV2
                multiple
                isClearable
                label="Статус ответа"
                placeholder="Выберите статус ответа"
                name="replyStatusesFilter"
                onChange={onChangeFilter}
                value={replyStatusesFilter}
                options={dictionaryToOptions(REVIEW_REPLY_STATUS_DICT)}
                size="lg"
            />
        </NmForm>
    );
}

export default ReviewsFilter;