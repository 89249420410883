import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";

import NmModal from "../../../../../../components/ActualComponents/NmModal";
import AddItemsForm from "../../../../../../components/AddItemsForm";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useCreateOrUpdateProfessionalSkills, {defaultProfessionalSkillsForm} from "./hooks/useCreateOrUpdateProfessionalSkills";

export default function ContractorProfessionalSkillsEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultProfessionalSkillsForm},
        contractorId,
    } = props;

    const {t} = useTranslation();
    const {
        onChangeForm,
        handleAddSkill,
        submit,
        form,
        setForm,
        error,
        deleteSkill,
    } = useCreateOrUpdateProfessionalSkills({contractorId, onClose, getProfile});

    useEffect(() => {
        if (!isEmpty(editData.names)) {
            setForm({
                ...editData,
            });
        }
    }, [editData.names]);

    const {
        name,
        names,
    } = form;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Профессиональные навыки
                </NmTitle>
            }
            onClose={onClose}
        >
            <AddItemsForm
                error={error}
                inputValue={name}
                onClickDelete={deleteSkill}
                onAddItem={handleAddSkill}
                onChangeInput={onChangeForm}
                inputName="name"
                inputLabel="Введите проф. навык"
                items={names}
            />
        </NmModal>
    );
}