import React, {FC, ReactNode} from "react";

import {ReactComponent as IconSearch} from "../../../../images/search.svg";

import bem from "../../../../utils/bem";

import {DropdownWithInputSizeType} from "../types";

import "./style.sass";

interface IDropdownWithInputTextField extends React.InputHTMLAttributes<HTMLInputElement> {
    containerSize: DropdownWithInputSizeType,
    isShowSearchIcon?: boolean,
    rightContent?: ReactNode,
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void,
}

export const DropdownWithInputTextField: FC<IDropdownWithInputTextField> = (props) => {
    const {
        isShowSearchIcon,
        name,
        rightContent,
        value,
        onChange,
        onKeyDown,
        containerSize,
        placeholder,
        disabled,
        onFocus,
        onBlur,
        ...inputProps
    } = props;

    const [block, element] = bem("dropdown-with-input-text-field");

    return (
        <div className={block()}>
            <div className={element("container", {size: containerSize})}>
                {
                    isShowSearchIcon &&
                    <IconSearch className="dropdown-with-input-text-field__icon" />
                }
                <input
                    className="dropdown-with-input-text-field__search"
                    type="text"
                    onChange={onChange}
                    value={value}
                    name={name}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    {...inputProps}
                />
                {
                    rightContent &&
                    <div className="dropdown-with-input-text-field__right-content">
                        {rightContent}
                    </div>
                }
            </div>
        </div>
    );
};
