import React, {FC, useState} from "react";

import NmTitle from "../../NmTitle";
import NmModal from "../NmModal";
import ShowMoreText, {IShowMoreTextProps} from "./ShowMoreText";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmShowMoreText extends IShowMoreTextProps {
    anchor: "green" | "blue",
    type?: "page" | "list",
    title?: string,
    isLoaded?: boolean,
    openExpandedModal?: boolean
    setIsHiddenModal?: (hide: boolean) => void
}

const NmShowMoreText: FC<INmShowMoreText> = (props) => {
    const {
        anchor,
        type = "list",
        children,
        title,
        isLoaded = true,
        setIsHiddenModal,
        ...otherProps
    } = props;
    const [,element] = bem("nm-show-more-text");
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(!open);
    };

    if (!isLoaded) {
        return null;
    }

    return (
        <>
            {
                open &&
                <NmModal
                    size="md"
                    header={
                        title &&
                        <NmTitle
                            className={element("modal-title")}
                            size="lg"
                        >
                            {title}
                        </NmTitle>
                    }
                    classNameContent={element("modal-content")}
                    children={children}
                    zIndex={1051}
                    onClose={() => {
                        onOpen();

                        if (setIsHiddenModal) {
                            setIsHiddenModal(false);
                        }
                    }}
                />
            }
            <ShowMoreText
                {...otherProps}
                anchorClass={element("anchor", {
                    anchor,
                    type,
                })}
                onClick={() => {
                    onOpen();

                    if (setIsHiddenModal) {
                        setIsHiddenModal(true);
                    }
                }}
                children={children}
            />
        </>
    );
};

export default NmShowMoreText;