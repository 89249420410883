import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useClientBrandBff} from "./useBff";

import {updateClientBrandsStore} from "../../../../ducks/client/brands/actionCreators";
import {getClientBrandInitialState} from "../../../../ducks/client/brands/reducer";

export const useClientBrandsFetch = (params) => {
    const {
        pageNum,
        pageSize,
        filterData,
        clientId,
        archived,
    } = params;

    const methods = useClientBrandBff();

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        archived,
    ]);

    useEffect(() => {
        return () => {
            const initState = getClientBrandInitialState();

            dispatch(updateClientBrandsStore(initState));
        };
    }, []);

    const fetchList = () => {
        methods.fetchList({
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            isArchiveFilter: archived,
            ...filterData,
        });
    };

    return {
        fetchList,
        methods,
    };
};