import React from "react";
import {connect} from "react-redux";

import NmReasonCompletion from "../../../components/NmReasonCompletion";

import {END_ORDER_BY_CLIENTS_REASONS,END_ORDER_BY_CLIENTS_REASONS_BAD} from "../../../constants/order";

import {orderContractorCompleteOrder, orderProgressListSelector} from "../../../ducks/orderContractors";

import PropTypes from "prop-types";

import "./style.sass";

const OrderReasonCompletionModal = props => {
    const {
        clientId,
        contractorId,
        orderId,
        close,
        progress,
    } = props;

    const submitForm = form => {
        const {
            orderContractorCompleteOrder,
            handleSuccess,
            blockRow,
        } = props;

        const {
            reason: completeReason,
            reasonDescription,
        } = form;

        const reason = END_ORDER_BY_CLIENTS_REASONS_BAD[reasonDescription];

        const reqData = {
            clientId,
            contractorId,
            orderId,
            reason,
            completeReason,
        };

        blockRow(reqData);

        orderContractorCompleteOrder({
            ...reqData,
            onError: () => {
                blockRow({});
            },
            onSuccess: () => {
                handleSuccess("Исполнителю предложено завершение работ");
                close();
            },
        });
    };

    return (
        <NmReasonCompletion
            question="Почему Вы хотите завершить работы по заказу?"
            options={END_ORDER_BY_CLIENTS_REASONS}
            submit={submitForm}
            close={close}
            loading={progress}
            disabled={progress}
        />
    );
};

OrderReasonCompletionModal.propTypes = {
    clientId: PropTypes.string,
    contractorId: PropTypes.string,
    orderId: PropTypes.string,
    close: PropTypes.func,
    progress: PropTypes.bool,
    orderContractorCompleteOrder: PropTypes.func,
};

export default connect(
    state => ({
        progress: orderProgressListSelector(state),
    }),
    {
        orderContractorCompleteOrder,
    },
)(OrderReasonCompletionModal);