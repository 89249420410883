export const STATUS = {
    //Ожидает подтверждения
    AWAITING_CLIENT_CONFIRMATION: "AWAITING_CLIENT_CONFIRMATION",
    //Отклонено
    DECLINED: "DECLINED",
    //Ожидает подписания
    AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: "AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING",
    //Нанят
    HIRED: "HIRED",
    //Завершено
    COMPLETED: "COMPLETED",
    CLIENT_REVOKED: "CLIENT_REVOKED",
    CONTRACTOR_REVOKED: "CONTRACTOR_REVOKED",
    AWAITING_CONTRACTOR_CONFIRMATION: "AWAITING_CONTRACTOR_CONFIRMATION",
    //Приостановлено
    PAUSED: "PAUSED",
    //Ожидание подтверждения завершения заказа от заказчика
    AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION: "AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION",
    //Ожидание подтверждения завершения заказа от исполнителя
    AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION: "AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION",
    //Переговоры по спору
    NEGOTIATIONS_ON_THE_DISPUTE: "NEGOTIATIONS_ON_THE_DISPUTE",
    //Предварительно одобрена
    CLIENT_PREAPPROVED: "CLIENT_PREAPPROVED",
    //Заявка отклонена заказчиком
    CLIENT_DECLINED: "CLIENT_DECLINED",
    //Заявка отклонена исполнителем
    CONTRACTOR_DECLINED: "CONTRACTOR_DECLINED",
    //"Заявка заблокирована"
    BLOCKED: "BLOCKED",
};
export const STATUS_DICT = {
    //Ожидает подтверждения
    AWAITING_CLIENT_CONFIRMATION: {
        COLOR: "#999999",
    },
    //Отклонено
    DECLINED: {
        COLOR: "#999999",
    },
    //Ожидает подписания
    AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: {
        COLOR: "#999999",
    },
    //Нанят
    HIRED: {
        COLOR: "#128A10",
    },
    //Завершено
    COMPLETED: {
        COLOR: "#999999",
    },
    CLIENT_REVOKED: {
        COLOR: "#999999",
    },
    CONTRACTOR_REVOKED: {
        COLOR: "#999999",
    },
    AWAITING_CONTRACTOR_CONFIRMATION: {
        COLOR: "#999999",
    },
    //Приостановлено
    PAUSED: {
        COLOR: "#999999",
    },
    //Ожидание подтверждения завершения заказа от заказчика
    AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION: {
        COLOR: "#999999",
    },
    //Ожидание подтверждения завершения заказа от исполнителя
    AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION: {
        COLOR: "#999999",
    },
    //Переговоры по спору
    NEGOTIATIONS_ON_THE_DISPUTE: {
        COLOR: "#999999",
    },
    //Предварительно одобрена
    CLIENT_PREAPPROVED: {
        COLOR: "#555555",
    },
    //Заявка отклонена заказчиком
    CLIENT_DECLINED: {
        COLOR: "#999999",
    },
    //Заявка отклонена исполнителем
    CONTRACTOR_DECLINED: {
        COLOR: "#999999",
    },
    //"Заявка заблокирована"
    BLOCKED: {
        COLOR: "#999999",
    },
};


export const filterOrderStatuses = {
    ALL: "Все",
    PUBLISHED: "В работе",
    CONTRACTOR_SEARCH: "Поиск исполнителей",
    CLOSED: "Закрыт",
};

export const orderClientAdminStatuses = {
    ...filterOrderStatuses,
    DRAFT: "Черновик",
};

export const mapOrderStatuses = {
    ALL: "Все",
    PUBLISHED: "В работе",
    CONTRACTOR_SEARCH: "Поиск исполнителей",
};

export const ORDER_STATUS_DICT = {
    DRAFT: {
        TEXT: "Черновик",
        VALUE: "DRAFT",
        COLOR: "#000000",
        BACKGROUND: "#e3eef7",
    },
    CONTRACTOR_SEARCH: {
        TEXT: "Поиск исполнителей",
        VALUE: "CONTRACTOR_SEARCH",
        COLOR: "#128A10",
        BACKGROUND: "#F0F7ED",
    },
    PUBLISHED: {
        TEXT: "В работе",
        VALUE: "PUBLISHED",
        COLOR: "#FFFFFF",
        BACKGROUND: "#128A10",
    },
    CLOSED: {
        TEXT: "Закрыт",
        VALUE: "CLOSED",
        COLOR: "#CCCCCC",
        BACKGROUND: "#000000",
    },
};

export const STATUS_PAYMENT = {
    CONFIRMED: "CONFIRMED", // У клиента подменяется на "В процессе"
    NOT_CONFIRMED: "NOT_CONFIRMED",
    FOR_PAYMENT: "FOR_PAYMENT", // У клиента подменяется на "В процессе"
    CANCELLED: "CANCELLED",
    REJECTED: "REJECTED",
    REJECTED_CONTRACTOR: "REJECTED_BY_CONTRACTOR",
    AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
    PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
    PAYMENT_ERROR: "PAYMENT_ERROR", // У клиента подменяется на "В процессе"
    CONFIRMATION_ERROR: "CONFIRMATION_ERROR", // У клиента подменяется на "В процессе"
    NOT_CONFIRMED_ERROR: "NOT_CONFIRMED_ERROR",
};

export const EVENT_TYPE = {
    OrderContractorStatusChange: "Изменение статуса",
    Payment: "Инициализация платежа",
    PaymentCancelled: "Платеж отклонен самозанятым",
    PaymentDone: "Платеж завершен",
    PaymentReject: "Выплата отклонена",
};

export const EVENT_REGISTRY_TYPE = {
    CREATE_REGISTRY: "Создание реестра",
    UPDATE_REGISTRY: "Редактирование реестра",
    DUPLICATE_REGISTRY: "Дублирование реестра",
    IMPORT_REGISTRY: "Импорт реестра из файла",
    EDITING_SELECTED_ROWS_REGISTRY: "Редактирование отдельных строк реестра",
    START_REGISTRY: "Запуск реестра",
    START_ROW_REGISTRY: "Запуск строки в реестре",
    REPEATING_FAILED_REGISTRY: "Повтор ошибочного реестра",
    REPEATING_FAILED_ROWS: "Повтор невыполненных строк",
    REJECTING_FAILED_REGISTRY : "Отклонение ошибочного реестра",
    ADD_SELECTED_ROWS_REGISTRY: "Добавление отдельных строк",
    DELETE_SELECTED_ROWS_REGISTRY: "Удаление отдельных строк",
    ARCHIVING_REGISTRY: "Добавление в архив реестра",
    UNZIPPING_REGISTRY: "Восстановление из архива реестра",
    REPEATING_FAILED_ROWS_REGISTRY: "Повтор невыполненных строк",
    CANCEL_FAILED_ROWS_REGISTRY: "Отмена невыполненных строк",
};

export const STATUS_PAYMENT_TRANSCRIPTION = {
    [STATUS_PAYMENT.CONFIRMED]: "На подтверждении Наймикс",
    [STATUS_PAYMENT.FOR_PAYMENT]: "К оплате",
    [STATUS_PAYMENT.NOT_CONFIRMED]: "На подтверждении заказчиком",
    [STATUS_PAYMENT.CANCELLED]: "Аннулирован",
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: "На подтверждении исполнителем",
    [STATUS_PAYMENT.REJECTED]: "Отклонено",
    [STATUS_PAYMENT.REJECTED_CONTRACTOR]: "Отклонено исполнителем",
    [STATUS_PAYMENT.PAYMENT_COMPLETED]: "Оплачено",
    [STATUS_PAYMENT.PAYMENT_ERROR]: "Ошибка платежа",
    [STATUS_PAYMENT.CONFIRMATION_ERROR]: "Ошибка подтверждения Наймикс",
    [STATUS_PAYMENT.NOT_CONFIRMED_ERROR]: "Ошибка подтверждения заказчиком",
};

export const STATUS_PAYMENT_INDIVIDUAL_TRANSCRIPTION = {
    ...STATUS_PAYMENT_TRANSCRIPTION,
    [STATUS_PAYMENT.PAYMENT_COMPLETED]: "Исполнено",
};

// Депозит / Оплаты на рассмотрении / фильтр "Статус наймикс"
export const STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT = {
    [STATUS_PAYMENT.NOT_CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED, // Ожидание подтверждения от заказчика
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION, // Ожидание подтверждения от исполнителя
    [STATUS_PAYMENT.NOT_CONFIRMED_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED_ERROR, // Ошибка подтверждения заказчиком
};

// Депозит / В процессе оплаты, клиентские фильтры / фильтр "Статус наймикс"
export const CLIENT_STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS = {
    [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
    [STATUS_PAYMENT.CONFIRMATION_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMATION_ERROR,
    [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION,
    [STATUS_PAYMENT.NOT_CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED,
    [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
    [STATUS_PAYMENT.NOT_CONFIRMED_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED_ERROR,
};

// Депозит / В процессе оплаты, фильтры для админских ролей / фильтр "Статус наймикс"
export const STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS = {
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION,
    [STATUS_PAYMENT.NOT_CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED,
    [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
    [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
    [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
    [STATUS_PAYMENT.CONFIRMATION_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMATION_ERROR,
};

// Депозит / В процессе оплаты, изначальные фильтры страницы
export const STATUS_PAYMENT_FOR_DEPOSIT_PAYMENTS_IN_PROCESS_REPORT_STATUS_FILTER = {
    REGISTRY_STATUS_FILTER: [
        STATUS_PAYMENT.AWAITING_CONFIRMATION,
        STATUS_PAYMENT.NOT_CONFIRMED,
        STATUS_PAYMENT.NOT_CONFIRMED_ERROR,
    ],
    REPORT_STATUS_FILTER: [
        STATUS_PAYMENT.CONFIRMED,
        STATUS_PAYMENT.FOR_PAYMENT,
        STATUS_PAYMENT.PAYMENT_ERROR,
        STATUS_PAYMENT.CONFIRMATION_ERROR,
    ],
};

// Исполнитель / Доходы / Оплаты на рассмотрении / фильтр "Статус наймикс"
export const STATUS_PAYMENT_CONTRACTOR_INCOME_AWAITING = {
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION,
    [STATUS_PAYMENT.NOT_CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED,
    [STATUS_PAYMENT.NOT_CONFIRMED_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED_ERROR,
};

// Исполнитель / Доходы / В процессе оплаты / фильтр "Статус наймикс"
export const STATUS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS = {
    [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
    [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
    [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
    [STATUS_PAYMENT.CONFIRMATION_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMATION_ERROR,
};

// Исполнитель / Доходы / Завершенные платежи / фильтр "Статус наймикс"
export const STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED = {
    [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
    [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
    [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
};

export const STATUS_PAYMENT_COLOR_TRANSCRIPTION = {
    [STATUS_PAYMENT.CONFIRMED]: "gray",
    [STATUS_PAYMENT.FOR_PAYMENT]: "light-green",
    [STATUS_PAYMENT.NOT_CONFIRMED]: "gray",
    [STATUS_PAYMENT.CANCELLED]: "orange",
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: "gray",
    [STATUS_PAYMENT.REJECTED]: "red",
    [STATUS_PAYMENT.REJECTED_CONTRACTOR]: "red",
    [STATUS_PAYMENT.PAYMENT_COMPLETED]: "green",
    [STATUS_PAYMENT.PAYMENT_ERROR]: "red",
    [STATUS_PAYMENT.CONFIRMATION_ERROR]: "red",
    [STATUS_PAYMENT.NOT_CONFIRMED_ERROR]: "red",
};

export const STATUS_NAIMIX_FILTER = {
    [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
    [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
    [STATUS_PAYMENT.NOT_CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED,
    [STATUS_PAYMENT.CANCELLED]: STATUS_PAYMENT_TRANSCRIPTION.CANCELLED,
    [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION,
    [STATUS_PAYMENT.REJECTED]: STATUS_PAYMENT_TRANSCRIPTION.REJECTED,
    [STATUS_PAYMENT.REJECTED_CONTRACTOR]: STATUS_PAYMENT_TRANSCRIPTION.REJECTED_BY_CONTRACTOR,
    [STATUS_PAYMENT.PAYMENT_COMPLETED]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_COMPLETED,
    [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
    [STATUS_PAYMENT.CONFIRMATION_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMATION_ERROR,
    [STATUS_PAYMENT.NOT_CONFIRMED_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.NOT_CONFIRMED_ERROR,
};

export const STATUS_NAIMIX_INDIVIDUAL_FILTER = {
    ...STATUS_NAIMIX_FILTER,
    [STATUS_PAYMENT.PAYMENT_COMPLETED]: STATUS_PAYMENT_INDIVIDUAL_TRANSCRIPTION.PAYMENT_COMPLETED,
};

export const STATUS_OF_TRANSFER = {
    INIT: "INIT",
    PROCESSING: "PROCESSING",
    FAILED: "FAILED",
    FINISHED: "FINISHED",
};

export const STATUS_OF_TRANSFER_TRANSCRIPTION = {
    INIT: "Счёт инициализирован",
    PROCESSING: "Платёж в обработке",
    FAILED: "Платёж не выполнен",
    FINISHED: "Платёж завершен",
};

export const STATUS_OF_TRANSFER_BADGE_COLOR_TRANSCRIPT = {
    INIT: "gray",
    PROCESSING: "orange",
    FAILED: "red",
    FINISHED: "green",
};

export const STATUS_1C_LOG_TRANSCRIPTION = {
    INIT: "Счёт инициализирован",
    CREATED: "Платёж создан",
    PROCESSING: "Платёж в обработке",
    FAILED: "Платёж не выполнен",
    FINISHED: "Платёж завершен",
};

export const STATUS_OF_TRANSFER_NEW = {
    [STATUS_OF_TRANSFER.INIT]: {
        value: STATUS_OF_TRANSFER.INIT,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.INIT,
        color: "#999999",
    },
    [STATUS_OF_TRANSFER.CREATED]: {
        value: STATUS_OF_TRANSFER.CREATED,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.CREATED,
        color: "#999999",
    },
    [STATUS_OF_TRANSFER.PROCESSING]: {
        value: STATUS_OF_TRANSFER.PROCESSING,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.PROCESSING,
        color: "#FFB713",
    },
    [STATUS_OF_TRANSFER.FAILED]: {
        value: STATUS_OF_TRANSFER.FAILED,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.FAILED,
        color: "#EE1616",
    },
    [STATUS_OF_TRANSFER.FINISHED]: {
        value: STATUS_OF_TRANSFER.FINISHED,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.FINISHED,
        color: "#128A10",
    },
    [STATUS_OF_TRANSFER.EXPIRED]: {
        value: STATUS_OF_TRANSFER.EXPIRED,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.EXPIRED,
        color: "#EE1616",
    },
    [STATUS_OF_TRANSFER.UNKNOWN]: {
        value: STATUS_OF_TRANSFER.UNKNOWN,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.UNKNOWN,
        color: "#EE1616",
    },
    [STATUS_OF_TRANSFER.PREVIOUSFAILED]: {
        value: STATUS_OF_TRANSFER.PREVIOUSFAILED,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.PREVIOUSFAILED,
        color: "#EE1616",
    },
    [STATUS_OF_TRANSFER.RETRYING]: {
        value: STATUS_OF_TRANSFER.RETRYING,
        text: STATUS_OF_TRANSFER_TRANSCRIPTION.RETRYING,
        color: "#FFB713",
    },
};

export const NM_ITEM_STATUS = {
    SUCCESS: "SUCCESS",
    PART_SUCCESS: "PART_SUCCESS",
    PROGRESS: "PROGRESS",
    WAITING: "WAITING",
    ERROR: "ERROR",
};

export const STATUS_1C_LOG_TRANSFER = {
    INIT: {
        value: "INIT",
        text: "Инициализация",
        color: "#CCCCCC",
        mod: "gray",
    },
    CREATED: {
        value: "CREATED",
        text: "Успешно",
        color: "#3F9B14",
        mod: "green",
    },
    FAILED: {
        value: "FAILED",
        text: "Неуспешно",
        color: "#DB2828",
        mod: "red",
    },
    CANCELLED: {
        value: "CANCELLED",
        text: "Отменено",
        color: "#DB2828",
        mod: "red",
    },
};
// возможно не все статусы - уточнить у бэка
export const PERSONAL_DATA_BASED_INN_STATUS = {
    NOT_CONFIRMED: "NOT_CONFIRMED",
    NO_DATA: "NO_DATA",
    CONFIRMATION_IN_PROGRESS: "CONFIRMATION_IN_PROGRESS",
};
// возможно не все статусы - уточнить у бэка
export const PERSONAL_DATA_BASED_ID_DOC_STATUS = {
    CONFIRMED: "CONFIRMED",
    NOT_CONFIRMED: "NOT_CONFIRMED",
};