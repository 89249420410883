import * as yup from "yup";

import {validateBik, validateInn, validateKpp} from "../../../../../../utils/validate";
import {transformYupFormattedAmountToNumber} from "../../../../../../utils/yupTransforms";

import {BANK_IDENTIFICATION_STATUS} from "../../../../../../constants/directoriesBanks";
import {requiredMessage} from "../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

const getLimitsValidation = (props) => {
    const {
        conditionName,
        singleLimitMin,
        singleLimitMax,
        dayLimitMin,
        dayLimitMax,
        monthLimitMin,
        monthLimitMax,
    } = props;

    return (yup.object().shape({
        singleLimit: yup.number()
            .nullable()
            .when(conditionName, {
                is: (value) => value,
                then: yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(singleLimitMin, `Минимальное допустимое значение равно ${singleLimitMin}`)
                    .max(singleLimitMax, `Максимальное допустимое значение равно ${singleLimitMax}`),
            }),
        dayLimit: yup.number()
            .nullable()
            .when(conditionName, {
                is: (value) => value,
                then: yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(dayLimitMin, `Минимальное допустимое значение равно ${dayLimitMin}`)
                    .max(dayLimitMax, `Максимальное допустимое значение равно ${dayLimitMax}`),
            }),
        monthLimit: yup.number()
            .nullable()
            .when(conditionName, {
                is: (value) => value,
                then: yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(monthLimitMin, `Минимальное допустимое значение равно ${monthLimitMin}`)
                    .max(monthLimitMax, `Максимальное допустимое значение равно ${monthLimitMax}`),
            }),
    }));
};

const validationSchema = () => {
    return yup.object().shape({
        directPaymentLimits: getLimitsValidation({
            conditionName: "availableDirectPayments",
            singleLimitMin: 0,
            singleLimitMax: 600000,
            dayLimitMin: 0,
            dayLimitMax: 600000,
            monthLimitMin: 0,
            monthLimitMax: 600000,
        }),
        walletLimits: yup.object({
            [BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED]: getLimitsValidation({
                conditionName: "availableContractorWallet",
                singleLimitMin: 0,
                singleLimitMax: 15000,
                dayLimitMin: 0,
                dayLimitMax: 40000,
                monthLimitMin: 0,
                monthLimitMax: 40000,
            }),
            [BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED]: getLimitsValidation({
                conditionName: "availableContractorWallet",
                singleLimitMin: 0,
                singleLimitMax: 60000,
                dayLimitMin: 0,
                dayLimitMax: 100000,
                monthLimitMin: 0,
                monthLimitMax: 200000,
            }),
            [BANK_IDENTIFICATION_STATUS.IDENTIFIED]: getLimitsValidation({
                conditionName: "availableContractorWallet",
                singleLimitMin: 0,
                singleLimitMax: 600000,
                dayLimitMin: 0,
                dayLimitMax: 600000,
                monthLimitMin: 0,
                monthLimitMax: 600000,
            }),
        }),
        bankSecondName: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов"),
        bic: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("bic", "", function (value) {
                const error = validateBik(value);

                if (error) {
                    return this.createError({
                        message: error,
                        path: "bic",
                    });
                }

                return true;
            }),
        inn: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("inn", "", function (value) {
                const error = validateInn(value);

                if (error) {
                    return this.createError({
                        message: error,
                        path: "inn",
                    });
                }

                return true;
            }),
        kpp: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("kpp", "", function (value) {
                const error = validateKpp(value);

                if (error) {
                    return this.createError({
                        message: error,
                        path: "kpp",
                    });
                }

                return true;
            }),
        correspondentAccount: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .length(20, "Допустимое количество символов - 20"),
        checkingAccount: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .length(20, "Допустимое количество символов - 20"),
    });
};

export default validationSchema;