import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/stopwords";

/*TYPES*/
const GET_PAGE_STOP_WORDS_REQUEST = "GET_PAGE_STOP_WORDS_REQUEST";
const GET_PAGE_STOP_WORDS_SUCCESS = "GET_PAGE_STOP_WORDS_SUCCESS";
const GET_PAGE_STOP_WORDS_ERROR = "GET_PAGE_STOP_WORDS_ERROR";

const ADD_STOP_WORDS_REQUEST = "ADD_STOP_WORDS_REQUEST";
const ADD_STOP_WORDS_SUCCESS = "ADD_STOP_WORDS_SUCCESS";
const ADD_STOP_WORDS_ERROR = "ADD_STOP_WORDS_ERROR";

const UPDATE_STOP_WORDS_REQUEST = "UPDATE_STOP_WORDS_REQUEST";
const UPDATE_STOP_WORDS_SUCCESS = "UPDATE_STOP_WORDS_SUCCESS";
const UPDATE_STOP_WORDS_ERROR = "UPDATE_STOP_WORDS_ERROR";

const DELETE_STOP_WORDS_REQUEST = "DELETE_STOP_WORDS_REQUEST";
const DELETE_STOP_WORDS_SUCCESS = "DELETE_STOP_WORDS_SUCCESS";
const DELETE_STOP_WORDS_ERROR = "DELETE_STOP_WORDS_ERROR";

const CHECK_STOP_WORDS_REQUEST = "CHECK_STOP_WORDS_REQUEST";
const CHECK_STOP_WORDS_SUCCESS = "CHECK_STOP_WORDS_SUCCESS";
const CHECK_STOP_WORDS_ERROR = "CHECK_STOP_WORDS_ERROR";

//*INITIAL STATE*//
const initial = {
    pageData: {},
    progressPage: false,
    totalCount: 0,
    stopWordsList: [],
};

//*REDUCER*//
export default (state = initial, {type, payload}) => {
    switch (type) {
    case CHECK_STOP_WORDS_REQUEST:
        return {
            ...state,
            progressPage: true,
        };
    case CHECK_STOP_WORDS_SUCCESS:
        return {
            ...state,
            progressPage: false,
        };
    case GET_PAGE_STOP_WORDS_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case GET_PAGE_STOP_WORDS_SUCCESS:
        const {
            results: stopWordsList,
            totalCount,
        } = payload;

        return {
            ...state,
            progressPage: false,
            totalCount,
            stopWordsList,
        };
    case GET_PAGE_STOP_WORDS_ERROR:
    case CHECK_STOP_WORDS_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*ACTION CREATORS*//
export function getStopWordsPage(payload) {
    return {
        type: GET_PAGE_STOP_WORDS_REQUEST,
        payload,
    };
}

export function addStopWords(payload) {
    return {
        type: ADD_STOP_WORDS_REQUEST,
        payload,
    };
}

export function updateStopWords(payload) {
    return {
        type: UPDATE_STOP_WORDS_REQUEST,
        payload,
    };
}

export function deleteStopWords(payload) {
    return {
        type: DELETE_STOP_WORDS_REQUEST,
        payload,
    };
}

export function checkStopWords({data, onSuccess}) {
    return {
        type: CHECK_STOP_WORDS_REQUEST,
        payload: data,
        onSuccess,
    };
}

//*SELECTORS*//
export const stopWordsSelector = state => state.stopWords;
export const getStopWordsTotalPagesSelector = createSelector(stopWordsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getStopWordsTotalCountSelector = createSelector(stopWordsSelector, ({totalCount}) => totalCount);
export const getStopWordListSelector = createSelector(stopWordsSelector, ({stopWordsList}) => stopWordsList);
export const getStopWordProgressPageSelector = createSelector(stopWordsSelector, ({progressPage}) => progressPage);

//*SAGA*//
// /api/stopwords/page
export const getStopWordsPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_STOP_WORDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_PAGE_STOP_WORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAGE_STOP_WORDS_ERROR, payload: error});
    }
};

// /api/stopwords/add
export const addStopWordSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_STOP_WORDS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        const {value} = payload;
        const state = yield select();

        toastSuccess(`Стоп-слово "${value}" успешно добавлено`);

        yield put(getStopWordsPage(state.stopWords.pageData));

        yield put({type: ADD_STOP_WORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_STOP_WORDS_ERROR, payload: error});
    }
};

export const updateStopWordSaga = function* ({payload}) {
    try {
        const result = yield request.patch(`${controller}/${payload.id}`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_STOP_WORDS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        const state = yield select();

        yield put(getStopWordsPage(state.stopWords.pageData));

        yield put({type: UPDATE_STOP_WORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_STOP_WORDS_ERROR, payload: error});
    }
};

export const deleteStopWordSaga = function* ({payload}) {
    try {
        const result = yield request.delete(`${controller}/${payload.id}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DELETE_STOP_WORDS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Стоп-слово успешно удалено");

        const state = yield select();

        yield put(getStopWordsPage(state.stopWords.pageData));

        yield put({type: DELETE_STOP_WORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_STOP_WORDS_ERROR, payload: error});
    }
};

// /api/stopwords/filter/check
export const checkStopWordsSaga = function* ({payload, onSuccess}) {
    try {
        const result = yield request.post(`${controller}/filter/check`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: CHECK_STOP_WORDS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({type: CHECK_STOP_WORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_STOP_WORDS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_STOP_WORDS_REQUEST, getStopWordsPageSaga),
        takeEvery(ADD_STOP_WORDS_REQUEST, addStopWordSaga),
        takeEvery(UPDATE_STOP_WORDS_REQUEST, updateStopWordSaga),
        takeEvery(DELETE_STOP_WORDS_REQUEST, deleteStopWordSaga),
        takeEvery(CHECK_STOP_WORDS_REQUEST, checkStopWordsSaga),
    ]);
}
