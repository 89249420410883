import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getVisibleInstructionByType} from "../components/Instructions/utils/access";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {USER_INSTRUCTION_TYPE_DICT} from "../constants/dicts";
//import {createSelector} from "reselect";

const controller = "/userInstructions";
//*  TYPES  *//

const SAVE_INSTRUCTION_REQUEST = "SAVE_INSTRUCTION_REQUEST";
const SAVE_INSTRUCTION_SUCCESS = "SAVE_INSTRUCTION_SUCCESS";
const SAVE_INSTRUCTION_ERROR = "SAVE_INSTRUCTION_ERROR";

const GET_INSTRUCTIONS_LIST_REQUEST = "GET_INSTRUCTIONS_LIST_REQUEST";
const GET_INSTRUCTIONS_LIST_SUCCESS = "GET_INSTRUCTIONS_LIST_SUCCESS";
const GET_INSTRUCTIONS_LIST_ERROR = "GET_INSTRUCTIONS_LIST_ERROR";

//*  INITIAL STATE  *//

const initial = {
    progressSave: false,
    error: {},
    instructionList: [],
    instructionInfo: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case SAVE_INSTRUCTION_REQUEST:
        return {
            ...state,
            progressSave: true,
        };
    case SAVE_INSTRUCTION_SUCCESS:
        return {
            ...state,
            progressSave: false,
        };
    case GET_INSTRUCTIONS_LIST_SUCCESS: {
        return {
            ...state,
            instructionList: payload,
            progressList: false,
        };
    }
    case SAVE_INSTRUCTION_ERROR:
        return {
            ...state,
            progressSave: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function saveInstruction(payload) {
    return {
        type: SAVE_INSTRUCTION_REQUEST,
        payload,
    };
}

export function getInstructionsList() {
    return {
        type: GET_INSTRUCTIONS_LIST_REQUEST,
    };
}

//*  SELECTORS  *//
export const userInstructionsSelector = state => state.userInstructions;

export const getInstructionInfoGroupSelector = createSelector(userInstructionsSelector, ({instructionList}) => {
    const filterListByInstructionType = (instructionType) => instructionList.find(item => item.instructionType === instructionType) || {};

    return [
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.CLIENT_ADMIN_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.FOREMAN_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.PROJECT_MANAGER_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.OBJECT_MANAGER_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.HR_MANAGER_INSTRUCTION.VALUE),
        filterListByInstructionType(USER_INSTRUCTION_TYPE_DICT.CLIENT_ACCOUNTANT_INSTRUCTION.VALUE),
    ].filter((item) => getVisibleInstructionByType(item.instructionType));
});

//*  SAGA  *//

//POST /api/userInstructions/save - Сохраняет инфо о инструкции
export const saveUserInstructionSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/save`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SAVE_INSTRUCTION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Инструкция успешно сохранена");

        yield put({type: SAVE_INSTRUCTION_SUCCESS, payload: result});

        yield put(getInstructionsList());
    } catch (error) {
        yield put({type: SAVE_INSTRUCTION_ERROR, payload: error});
    }
};

//GET /api/userInstructions/getList Возвращает все информации о всех инструкциях списком
export const getInstructionsListSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getList`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_INSTRUCTIONS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_INSTRUCTIONS_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_INSTRUCTIONS_LIST_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(SAVE_INSTRUCTION_REQUEST, saveUserInstructionSaga),
        takeEvery(GET_INSTRUCTIONS_LIST_REQUEST, getInstructionsListSaga),
    ]);
}
