import * as yup from "yup";

import {isOnlyArrivalNotification} from "./isOnlyArrivalNotification";

import {dictionaryToOptions} from "../../../utils/objectHelper";

import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    CONTRACTOR_ARRIVAL_DOC_TYPE_DICT,
} from "../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {FULL_REGISTRATION_COMPONENT_TYPE, FULL_REGISTRATION_STEP} from "../constants";

export const getFullRegistrationResidencePlaceNotResidentStep = (params) => {
    const {
        isRequiredFields,
    } = params;

    return {
        value: FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
        modalTitle: "Регистрация иностранного гражданина по месту жительства",
        validation: (values) => {
            if (values.arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION) {
                return yup.object().shape({
                    address: isRequiredFields ? yup.string()
                        .required(VALIDATIONS_MESSAGE.REQUIRED)
                        .min(3, "Минимальная длина строки 3 символа")
                        .max(255, "Не более 255 символов") :
                        yup.string()
                            .min(3, "Минимальная длина строки 3 символа")
                            .max(255, "Не более 255 символов"),
                    registrationDate: isRequiredFields && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                    arrivalNoticeStayEndDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            }

            return yup.object().shape({
                mainDocumentStampAddress: isRequiredFields ? yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(3, "Минимальная длина строки 3 символа")
                    .max(255, "Не более 255 символов") :
                    yup.string()
                        .min(3, "Минимальная длина строки 3 символа")
                        .max(255, "Не более 255 символов"),
                mainDocumentStampIssuedDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                mainDocumentStampValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                [CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
            });
        },
        fields: (values) => {
            const generalField = {
                type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                label: "Регистрация",
                name: "arrivalDocType",
                options: dictionaryToOptions(CONTRACTOR_ARRIVAL_DOC_TYPE_DICT),
                disabled: isOnlyArrivalNotification({
                    citizenship: values.citizenship,
                    migrationStatus: values.migrationStatus,
                }),
            };

            if (values.arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION) {
                return [
                    generalField,
                    ...getArrivalNotificationFields(),
                ].filter(Boolean);
            }

            return [
                generalField,
                ...getStampFields(),
            ].filter(Boolean);
        },
    };
};

const getStampFields = () => {
    return [
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
            label: "Фотография страницы с адресом",
            name: CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS,
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Адрес",
            name: "mainDocumentStampAddress",
            required: true,
            placeholder: "Введите адрес",
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            label: "Дата выдачи в штампе",
            maxDate: new Date(),
            required: true,
            name: "mainDocumentStampIssuedDate",
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            label: "Дата окончания срока действия в штампе",
            minDate: new Date(),
            name: "mainDocumentStampValidToDate",
        },
    ];
};

const getArrivalNotificationFields = () => {
    return [
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
            label: "Фотография лицевой стороны бланка о прибытии",
            name: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST,
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
            label: "Фотография обратной стороны бланка о прибытии",
            name: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND,
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
            label: "Адрес",
            name: "address",
            required: true,
            placeholder: "Введите адрес",
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            label: "Дата постановки на учет",
            maxDate: new Date(),
            required: true,
            name: "registrationDate",
        },
        {
            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
            label: "Дата окончания срока пребывания",
            minDate: new Date(),
            required: true,
            name: "arrivalNoticeStayEndDate",
        },
    ];
};