import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {ORDER_WORK} from "../constants/messages";

const controller = "/contractors/worklog";
//*  TYPES  *//

const ADD_WORK_LOG_REQUEST = "ADD_WORK_LOG_REQUEST";
const ADD_WORK_LOG_SUCCESS = "ADD_WORK_LOG_SUCCESS";
const ADD_WORK_LOG_ERROR = "ADD_WORK_LOG_ERROR";

const GET_WORK_LOG_REQUEST = "GET_WORK_LOG_REQUEST";
const GET_WORK_LOG_SUCCESS = "GET_WORK_LOG_SUCCESS";
const GET_WORK_LOG_ERROR = "GET_WORK_LOG_ERROR";

const GET_WORK_LOG_PHOTO_REQUEST = "GET_WORK_LOG_PHOTO_REQUEST";
const GET_WORK_LOG_PHOTO_SUCCESS = "GET_WORK_LOG_PHOTO_SUCCESS";
const GET_WORK_LOG_PHOTO_ERROR = "GET_WORK_LOG_PHOTO_ERROR";

const UPDATE_WORK_LOG_FIELD = "UPDATE_WORK_LOG_FIELD";

const ADD_WORK_LOG_RANGE_REQUEST = "ADD_WORK_LOG_RANGE_REQUEST";
const ADD_WORK_LOG_RANGE_SUCCESS = "ADD_WORK_LOG_RANGE_SUCCESS";
const ADD_WORK_LOG_RANGE_ERROR = "ADD_WORK_LOG_RANGE_ERROR";

const UPDATE_WORK_LOG_RANGE_REQUEST = "UPDATE_WORK_LOG_RANGE_REQUEST";
const UPDATE_WORK_LOG_RANGE_SUCCESS = "UPDATE_WORK_LOG_RANGE_SUCCESS";
const UPDATE_WORK_LOG_RANGE_ERROR = "UPDATE_WORK_LOG_RANGE_ERROR";

const DELETE_WORK_LOG_RANGE_REQUEST = "DELETE_WORK_LOG_RANGE_REQUEST";
const DELETE_WORK_LOG_RANGE_SUCCESS = "DELETE_WORK_LOG_RANGE_SUCCESS";
const DELETE_WORK_LOG_RANGE_ERROR = "DELETE_WORK_LOG_RANGE_ERROR";

const APPROVE_WORK_LOG_EXPIRED_REQUEST = "APPROVE_WORK_LOG_EXPIRED_REQUEST";
const APPROVE_WORK_LOG_EXPIRED_SUCCESS = "APPROVE_WORK_LOG_EXPIRED_SUCCESS";
const APPROVE_WORK_LOG_EXPIRED_ERROR = "APPROVE_WORK_LOG_EXPIRED_ERROR";

//*  INITIAL STATE  *//

const initial = {
    currentRole: "",
    workLog: [],
    pageData: {},
    progressList: false,
    includingData: {},
    photoBase64: "",
    progressPhoto: false,
    progressExpired: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case APPROVE_WORK_LOG_EXPIRED_REQUEST:
        return {
            ...state,
            progressExpired: true,
        };
    case APPROVE_WORK_LOG_EXPIRED_SUCCESS:
        return {
            ...state,
            progressExpired: false,
        };
    case APPROVE_WORK_LOG_EXPIRED_ERROR:
        return {
            ...state,
            progressExpired: false,
        };
    case GET_WORK_LOG_PHOTO_REQUEST:
        return {
            ...state,
            progressPhoto: true,
        };
    case DELETE_WORK_LOG_RANGE_REQUEST:
    case ADD_WORK_LOG_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_WORK_LOG_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressList: true,
        };
    case GET_WORK_LOG_PHOTO_SUCCESS:
        return {
            ...state,
            photoBase64: payload,
            progressPhoto: false,
        };
    case UPDATE_WORK_LOG_FIELD:
        return {
            ...state,
            ...payload,
        };
    case GET_WORK_LOG_SUCCESS:
        const {
            workLog,
            totalHours,
            totalMinutes,
            objectLongitude,
            objectLatitude,
        } = payload;
        return {
            ...state,
            workLog,
            includingData: {
                totalHours,
                totalMinutes,
                objectLongitude,
                objectLatitude,
            },
            progressList: false,
        };
    case DELETE_WORK_LOG_RANGE_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case GET_WORK_LOG_ERROR:
    case ADD_WORK_LOG_ERROR:
        return {
            ...state,
            error: payload,
            progressList: false,
        };
    case DELETE_WORK_LOG_RANGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function addWorkLog(payload) {
    return {
        type: ADD_WORK_LOG_REQUEST,
        payload,
    };
}

export function deleteWorkLog(payload) {
    return {
        type: DELETE_WORK_LOG_RANGE_REQUEST,
        payload,
    };
}

export function getWorkLog(payload) {
    return {
        type: GET_WORK_LOG_REQUEST,
        payload,
    };
}

export function addRangeWorkLog(payload) {
    return {
        type: ADD_WORK_LOG_RANGE_REQUEST,
        payload,
    };
}

export function updateRangeWorkLog(payload) {
    return {
        type: UPDATE_WORK_LOG_RANGE_REQUEST,
        payload,
    };
}

export function getWorkLogPhoto(payload) {
    return {
        type: GET_WORK_LOG_PHOTO_REQUEST,
        payload,
    };
}

export function updateFieldWorkLog(payload) {
    return {
        type: UPDATE_WORK_LOG_FIELD,
        payload,
    };
}

export function approveExpiredWorkLog(payload) {
    return {
        type: APPROVE_WORK_LOG_EXPIRED_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorWorkLogSelector = state => state.contractorWorkLog;
export const workLogListSelector = createSelector(contractorWorkLogSelector, ({workLog}) => workLog);
export const workLogPageDataSelector = createSelector(contractorWorkLogSelector, ({pageData}) => pageData);
export const workLogProgressPhotoSelector = createSelector(contractorWorkLogSelector, ({progressPhoto}) => progressPhoto);
export const workLogIncludingDataSelector = createSelector(contractorWorkLogSelector, ({includingData}) => includingData);
export const workLogPhotoSelector = createSelector(contractorWorkLogSelector, ({photoBase64}) => photoBase64);
export const workLogProgressListSelector = createSelector(contractorWorkLogSelector, ({progressList}) => progressList);
export const workLogProgressExpiredSelector = createSelector(contractorWorkLogSelector, ({progressExpired}) => progressExpired);

//*  SAGA  *//

export const addWorkLogSaga = function* (action) {
    try {
        const {payload} = action;
        const {errorMessage} = yield request.post(`${controller}/add`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_WORK_LOG_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Успешно подтверждено");
        const state = yield select();
        yield put(getWorkLog(workLogPageDataSelector(state)));

        yield put({type: ADD_WORK_LOG_SUCCESS});
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: ADD_WORK_LOG_ERROR, payload: error});
    }
};

export const getWorkLogSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_WORK_LOG_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_WORK_LOG_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);
        yield put({type: GET_WORK_LOG_ERROR, payload: error});
    }
};

export const getPhotoSaga = function* (action) {
    try {
        const {
            payload: {
                downloadLink,
                isFillOrigin = true,
            },
        } = action;

        const urlRequest = isFillOrigin ? `/filestore/contractorwork/getBase64/?fileId=${downloadLink}` : downloadLink;

        const {base64str, errorMessage} = yield request.get(urlRequest);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_WORK_LOG_PHOTO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_WORK_LOG_PHOTO_SUCCESS, payload: base64str});
    } catch (error) {
        toastError(error.message);
        yield put({type: GET_WORK_LOG_PHOTO_ERROR, payload: error});
    }
};

export const deleteWorkLogSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const {errorMessage} = yield request.post(`${controller}/deleteRange`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DELETE_WORK_LOG_RANGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }
        toastSuccess(ORDER_WORK.ORDER_WORK_DELETE_RANGE_SUCCESS);
        const state = yield select();
        yield put(getWorkLog(workLogPageDataSelector(state)));

        yield put({type: DELETE_WORK_LOG_RANGE_SUCCESS, payload: ""});
    } catch (error) {
        toastError(error.message);
        yield put({type: DELETE_WORK_LOG_RANGE_ERROR, payload: error});
    }
};

//POST /api/contractors/worklog/addRange
export const addRangeWorkLogSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const {errorMessage} = yield request.post(`${controller}/addRange`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_WORK_LOG_RANGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }
        toastSuccess("Успешно сохранено");
        const state = yield select();
        yield put(getWorkLog(workLogPageDataSelector(state)));

        yield put({type: ADD_WORK_LOG_RANGE_SUCCESS, payload: ""});
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_WORK_LOG_RANGE_ERROR, payload: error});
    }
};

//POST /api/contractors/worklog/editRange
export const updateRangeWorkLogSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const {errorMessage} = yield request.post(`${controller}/editRange`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_WORK_LOG_RANGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }
        toastSuccess("Успешно сохранено");

        const state = yield select();
        yield put(getWorkLog(workLogPageDataSelector(state)));

        yield put({type: UPDATE_WORK_LOG_RANGE_SUCCESS, payload: ""});
    } catch (error) {
        toastError(error.message);
        yield put({type: UPDATE_WORK_LOG_RANGE_ERROR, payload: error});
    }
};

//POST /api/contractors/worklog/editRange
export const approveExpiredWorkLogSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const {errorMessage} = yield request.post(`${controller}/approveExpired`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DELETE_WORK_LOG_RANGE_ERROR});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: APPROVE_WORK_LOG_EXPIRED_SUCCESS});
    } catch (error) {
        toastError(error.message);
        yield put({type: APPROVE_WORK_LOG_EXPIRED_ERROR});
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_WORK_LOG_REQUEST, addWorkLogSaga),
        takeEvery(GET_WORK_LOG_REQUEST, getWorkLogSaga),
        takeEvery(GET_WORK_LOG_PHOTO_REQUEST, getPhotoSaga),
        takeEvery(ADD_WORK_LOG_RANGE_REQUEST, addRangeWorkLogSaga),
        takeEvery(UPDATE_WORK_LOG_RANGE_REQUEST, updateRangeWorkLogSaga),
        takeEvery(DELETE_WORK_LOG_RANGE_REQUEST, deleteWorkLogSaga),
        takeEvery(APPROVE_WORK_LOG_EXPIRED_REQUEST, approveExpiredWorkLogSaga),
    ]);
}
