import React, {Component} from "react";
import {Route, Switch} from "react-router";

import EventLogList from "../settings-logs/event-log-list";
import SigningDocumentsLogList from "../settings-logs/signing-documents-log-list";

import {
    LINK_SETTINGS_EVENT_LOG,
    LINK_SETTINGS_SIGNING_DOCUMENT_LOG,
} from "../../../constants/links";

class SettingsLogRoute extends Component {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={LINK_SETTINGS_EVENT_LOG}
                    component={() =>
                        <EventLogList
                            {...this.props}
                        />
                    }
                />
                <Route
                    exact
                    path={LINK_SETTINGS_SIGNING_DOCUMENT_LOG}
                    component={() =>
                        <SigningDocumentsLogList
                            {...this.props}
                        />
                    }
                />
            </Switch>
        );
    }
}

export default SettingsLogRoute;