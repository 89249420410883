import {
    addKedoUploadDocumentType,
    deleteKedoUploadDocumentType,
    getKedoUploadDocumentTypesPage,
    updateKedoUploadDocumentType,
} from "../../../../../../kedo/directories/actionCreators";

const controller = "/adm/settings/directories/kedo/upload-document-types";

export const updateAdmKedoUploadDocumentType = (payload) => {
    return updateKedoUploadDocumentType({
        url: `${controller}/update`,
        ...payload,
    });
};

export const getAdmKedoUploadDocumentTypesPage = (payload) => {
    return getKedoUploadDocumentTypesPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const addAdmKedoUploadDocumentType = (payload) => {
    return addKedoUploadDocumentType({
        url: `${controller}/add`,
        ...payload,
    });
};

export const deleteAdmKedoUploadDocumentType = (payload) => {
    return deleteKedoUploadDocumentType({
        url: `${controller}/delete`,
        ...payload,
    });
};
