import React from "react";
import {useTranslation} from "react-i18next";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";

import "./style.sass";

function ContractorEventLogFilter(props) {
    const {
        filter = {},
        submitFilter,
        setFilter,
        onChangeFilter,
        initFilter,
        typeOptions,
    } = props;
    const {
        fromDateFilter,
        toDateFilter,
        groupTypesFilter,
    } = filter;

    const {t} = useTranslation();

    const submit = () => {
        submitFilter(filter, true);
    };

    const clear = () => {
        submitFilter({
            ...initFilter,
        }, false);

        setFilter({
            ...initFilter,
        });
    };

    return (
        <NmForm addMargin>
            <NmDropdownV2
                multiple
                size="lg"
                search
                options={typeOptions}
                hideSelectedItems
                value={groupTypesFilter}
                label={t("settings-logs-list.event-type")}
                onChange={onChangeFilter}
                name="groupTypesFilter"
            />
            <NmDateRangePickerV2
                size="lg"
                label="Период"
                startFieldName="fromDateFilter"
                endFieldName="toDateFilter"
                value={{fromDateFilter, toDateFilter}}
                isClearable
                onChange={onChangeFilter}
            />
            <FilterButtonsV2
                onSearch={submit}
                onClear={clear}
            />
        </NmForm>
    );
}

export default ContractorEventLogFilter;