export const replacer = (mark, str, params) => {
    if (!str || str.indexOf(mark) === -1) {return "";}

    return str.replace(mark, params);
};

/***
 * Заменяет параметры в строке
 * @param string "{1} + {0} + {2}"
 * @param replacements ["a", "b", "c"]
 * @returns {*} b + a + c
 */
export function replaceParams(string, replacements) {
    return string.replace(/\{(\d+)}/g, function() {
        if (!replacements[arguments[1]]) {
            return arguments[0];
        }

        return replacements[arguments[1]];
    });
}