import React, {KeyboardEvent,useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import ClientUsersFioDropdown from "../../ClientUsersFioDropdown";
import PhoneWithCodeFilter from "../../PhoneWithCodeFilter";
import FilterButtonsV2 from "../FilterButtonsV2";
import FilterCustomer from "../FilterCustomer";
import FilterObject from "../FilterObject";
import FilterProject from "../FilterProject";
import FilterSpecialities from "../FilterSpecialities";
import NmCheckboxV2 from "../NmCheckboxV2";
import NmDadata from "../NmDadata";
import NmDatePicker from "../NmDatePicker";
import NmDateRangePickerV2 from "../NmDateRangePickerV2";
import NmDropdownV2 from "../NmDropdownV2";
import NmForm from "../NmForm";
import NmInputV2 from "../NmInputV2";
import NmRangeInput from "../NmRangeInput";

import bem from "../../../utils/bem";
import {getDadataAddress} from "../../../utils/dadata";

import "./style.sass";

export const FILTER = {
    // фильтр Заказчик
    CUSTOMER: "customer",
    // фильтр Вид деятельности
    SPECIALITIES: "specialities",
    // фильтр Проект
    PROJECT: "project",
    // фильтр Объект
    OBJECT: "object",
    // обычный инпут
    INPUT: "input",
    // от до
    RANGE_INPUT: "rangeInput",
    // обычный дропдаун
    DROPDOWN: "dropdown",
    // датапикер
    DATE: "date",
    // период дат
    DATE_RANGE: "dateRange",
    //дадата
    DADATA: "dadata",
    // чекбокс
    CHECKBOX: "checkbox",
    // фильтр Номер телефона с выбором страны
    PHONE_WITH_CODE: "phoneWithCode",
    // фильтр сотрудников
    CLIENT_USERS_FIO: "clientUsersFio",
};

// Все компоненты которые могут использоваться
const controls = {
    [FILTER.CUSTOMER]: {
        render: FilterCustomer,
    },
    [FILTER.PROJECT]: {
        render: FilterProject,
    },
    [FILTER.OBJECT]: {
        render: FilterObject,
    },
    [FILTER.SPECIALITIES]: {
        render: FilterSpecialities,
    },
    [FILTER.INPUT]: {
        render: NmInputV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.RANGE_INPUT]: {
        render: NmRangeInput,
        props: {
            size: "lg",
        },
    },
    [FILTER.DROPDOWN]: {
        render: NmDropdownV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.DATE]: {
        render: NmDatePicker,
        props: {
            size: "lg",
        },
    },
    [FILTER.DATE_RANGE]: {
        render: NmDateRangePickerV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.DADATA]: {
        render: NmDadata,
        props: {
            size: "lg",
            formatter: getDadataAddress,
        },
    },
    [FILTER.PHONE_WITH_CODE]: {
        render: PhoneWithCodeFilter,
    },
    [FILTER.CHECKBOX]: {
        render: NmCheckboxV2,
    },
    [FILTER.CLIENT_USERS_FIO]: {
        render: ClientUsersFioDropdown,
    },
};

// Преобразует из конфига начальный state
const getDefaultState = (filters: any, initState: any) => {
    if (initState) {
        return {...initState};
    }

    return filters.reduce((prev: any, currentElem: any) => {
        const {row} = currentElem;

        row.map((componentConfig: any) => {
            const {component} = componentConfig;

            switch (component) {
            case FILTER.DATE_RANGE: {
                const startFieldName = componentConfig.startFieldName;
                const endFieldName = componentConfig.endFieldName;

                prev[startFieldName] = componentConfig.value[startFieldName];
                prev[endFieldName] = componentConfig.value[endFieldName];

                break;
            }
            case FILTER.RANGE_INPUT: {
                const startFieldName = componentConfig.nameStart;
                const endFieldName = componentConfig.nameEnd;

                prev[startFieldName] = componentConfig.valueStart;
                prev[endFieldName] = componentConfig.valueEnd;

                break;
            }
            default: {
                const fieldName = componentConfig.name;

                prev[fieldName] = componentConfig.value || "";
            }
            }
        });

        return prev;
    }, {});
};

const getValue = ({name, props, form}: any) => {
    switch (name) {
    case FILTER.DATE_RANGE:
        return {
            value: {
                [props.startFieldName]: form[props.startFieldName],
                [props.endFieldName]: form[props.endFieldName],
            },
        };
    case FILTER.RANGE_INPUT:
        return {
            valueStart: form[props.nameStart],
            valueEnd: form[props.nameEnd],
        };
    case FILTER.PROJECT:
        return {
            projectIdsFilter: form[props.name],
        };
    case FILTER.OBJECT:
        return {
            objectIdsFilter: form[props.name],
        };
    case FILTER.INPUT:
        return {
            value: form[props.name] || "",
        };
    default:
        return {
            value: form[props.name],
        };
    }
};

interface IFilter {
    filters: any,
    filterState?: Record<string, any>,
    onSubmit: (params?: any) => void,
    updateFilter?: (params?: any) => void,
    onKeyDown?: (event: KeyboardEvent<HTMLFormElement>) => void,
    initState?: any,
    isInitStateEqualEmpty?: boolean,
    className?: string,
    clearFilter: (params?: any) => void,
    actionsBottomPosition?: boolean
}

const Filter = (props: IFilter) => {
    const {
        onKeyDown,
        filters,
        initState,
        filterState,
        onSubmit,
        updateFilter,
        className = "",
        isInitStateEqualEmpty = false,
        clearFilter,
        actionsBottomPosition,
    } = props;

    const {t} = useTranslation();
    const [_filters, setFilters] = useState([]);
    const [form, setForm] = useState(getDefaultState(_filters, initState));
    const [block, element] = bem("filter", className);

    useEffect(() => {
        const mapFilter = filters.filter(({isVisible = true}: any) => isVisible);

        setFilters(mapFilter);
    }, [filters]);

    useEffect(() => {
        if (filterState) {
            setForm(filterState);
        }
    }, [
        filterState,
    ]);

    const onChange = (event: any, {name, value, checked}: any) => {
        const _value = typeof checked === "boolean" ? checked : value;

        if (updateFilter) {
            updateFilter({
                ...form,
                [name]: _value,
            });

            if (filterState) {
                return;
            }
        }

        setForm({
            ...form,
            [name]: _value,
        });
    };

    const _onClear = () => {
        if (isInitStateEqualEmpty) {
            if (!filterState) {
                setForm({
                    ...initState,
                });
            }

            clearFilter({
                ...initState,
            });

            return;
        }

        if (!filterState) {
            setForm({
                ...getDefaultState(_filters, {}),
            });
        }

        clearFilter({
            ...getDefaultState(_filters, {}),
        });
    };

    const _onSubmit = () => {
        onSubmit(form);
    };

    const getProps = (componentName: string, props: any, t: any) => {
        const {
            useTranslation,
            disabledDependsName,
            ...otherProps
        } = props;
        const defaultProps = controls[componentName].props;
        const value = getValue({
            name: componentName,
            props: otherProps,
            form,
        });
        const disabledDependsValue = disabledDependsName && form[disabledDependsName];
        const disabled = Array.isArray(disabledDependsValue) ?
            Boolean(disabledDependsValue.length) :
            Boolean(disabledDependsValue);

        let resultProps: any = {
            ...otherProps,
            ...defaultProps,
            ...value,
            disabled: disabledDependsName && disabled,
            onChange,
        };

        if (useTranslation) {
            resultProps.label = t(resultProps.label);
        }

        if (componentName === FILTER.CHECKBOX) {
            resultProps = {
                ...resultProps,
                checked: Boolean(value.value),
            };
        }

        // Пробрасываем автоматически кнопки покзазать, очистить
        if (componentName === FILTER.DROPDOWN) {
            resultProps = {
                ...resultProps,
                onSubmit: resultProps.multiple ? _onSubmit : undefined,
                onClear: _onClear,
            };
        }

        if (componentName === FILTER.DADATA) {
            resultProps = {
                ...resultProps,
                initialAddressIds: value.value,
                onSubmit: resultProps.multiple ? _onSubmit : undefined,
                onClear: _onClear,
            };
        }

        return resultProps;
    };

    const getFilters = () => {
        return _filters.map(({row}: any, index: number) => {
            if (row.length === 1) {
                const [{
                    component: componentName,
                    ...props
                }] = row;

                const component = controls[componentName].render;
                const resultProps = getProps(componentName, props, t);

                return (
                    <div
                        key={index}
                        className={element("row")}
                    >
                        {React.createElement(component, resultProps)}
                    </div>
                );
            }

            if (row.length === 2) {
                return (
                    <div
                        key={index}
                        className={element("row", {divide: true})}
                    >
                        {
                            row.map((componentConfig: any) => {
                                const {
                                    component: componentName,
                                    ...props
                                } = componentConfig;

                                const component = controls[componentName].render;
                                const resultProps = getProps(componentName, props, t);

                                return React.createElement(component, resultProps);
                            })
                        }
                    </div>
                );
            }

            return (
                <div style={{color: "red"}}>
                    Максимальное количество столбцов 2
                </div>
            );
        });
    };

    return (
        <NmForm
            className={block()}
            onKeyDown={onKeyDown}
        >
            {!actionsBottomPosition && <FilterButtonsV2
                className="signing-documents-log-filter__actions"
                onClear={_onClear}
                onSearch={_onSubmit}
            />}
            {getFilters()}
            {actionsBottomPosition &&
                <FilterButtonsV2
                    className="signing-documents-log-filter__actions"
                    onClear={_onClear}
                    onSearch={_onSubmit}
                />
            }
        </NmForm>
    );
};

export default Filter;