import {isEqual} from "lodash";

import {getClientSettingsAdditionalModulesRequestData} from "./getRequestData";

export const isClientSettingsAdditionalModulesChanged = (params) => {
    const {
        values,
        card,
        retailClientUsersIds,
    } = params;

    const isRetailUsersChanged = retailClientUsersIds.length !== values.retailClientUsersIds.length;

    if (isRetailUsersChanged) {
        return true;
    }

    const requestData = getClientSettingsAdditionalModulesRequestData(values, card);

    const {
        retailClientUsers,
        ..._values
    } = requestData;

    const {
        retailClientUsers: _retailClientUsers,
        ..._card
    } = card;

    return Object.keys(_values).length === Object.keys(_card).length &&
        !isEqual(_values, _card);
};