import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import ButtonWithTooltip from "../../../components/ButtonWithTooltip";
import CheckboxList from "../../../components/CheckboxList";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import PhoneWithCodeFilter from "../../../components/PhoneWithCodeFilter";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";

import bem from "../../../utils/bem";
import dateFormat, {convertUtcToLocal, getDateObject} from "../../../utils/dateFormat";
import {phoneFormat} from "../../../utils/stringFormat";

import {LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD} from "../../../constants/links";

import {getClientCardSelector} from "../../../ducks/client";
import {downloadDocument} from "../../../ducks/documents";
import {
    addDocumentsExportPatents,
    getDocumentsListPatents,
    getDocumentsListTypesPatents,
    patentsDocumentsListSelector,
    patentsDocumentsListTypesSelector,
    patentsDocumentsProgressSelector,
    patentsDocumentsTotalCountSelector,
    patentsDocumentsTotalPagesSelector,
} from "../../../ducks/patentsDocuments";

import "./styles.sass";

const PatentsDocumentsList = (props) => {
    const {
        documentsList,
        totalCount,
        totalPages,
        types,
        getDocumentsListPatents,
        getDocumentsListTypesPatents,
        addDocumentsExportPatents,
        downloadDocument,
        progress,
        client: {
            clientId,
        },
    } = props;

    const pageSizes = [25, 50, 100];
    const [, element] = bem("patents-documents-list");
    const {t} = useTranslation();
    const [pageSize, setPageSize] = useState(pageSizes[0]);
    const [isSearch, setIsSearch] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [filter, setFilter] = useState({
        contractorNameFilter: "",
        contractorPhoneFilter: "",
        creationDateFromFilter: "",
        creationDateToFilter: "",
    });

    const onClickExport = () => {
        addDocumentsExportPatents({
            ...filter,
            clientIdFilter: clientId,
        });
    };

    const onDownloadItem = (item) => {
        const {
            documentDownloadLink,
            documentType,
        } = item;

        downloadDocument({
            downloadLink: documentDownloadLink,
            fileName: `${types[documentType]}.pdf`,
        });
    };

    const onChangePagination = (e, {activePage: pageNum}) => {
        setPageNum(pageNum);
        clientId && getDocumentsListPatents({
            ...filter,
            clientIdFilter: clientId,
            pageNum: pageNum,
            pageSize: pageSize,
        });

        document.querySelector(".client-card").scrollTo(0, 0);
    };

    const onChangePageSize = (pageSize) => {
        setPageSize(pageSize);
        clientId && getDocumentsListPatents({
            ...filter,
            clientIdFilter: clientId,
            pageNum: pageNum,
            pageSize: pageSize,
        });
    };

    const onChangeFilter = (event, {name, value}) => {
        setFilter((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const submitFilter = () => {
        const {
            contractorNameFilter,
            contractorPhoneFilter,
            creationDateFromFilter,
            creationDateToFilter,
        } = filter;

        clientId && getDocumentsListPatents({
            clientIdFilter: clientId,
            contractorNameFilter,
            contractorPhoneFilter,
            creationDateFromFilter,
            creationDateToFilter,
            pageNum: 1,
            pageSize,
        });

        setPageNum(1);
        setIsSearch(true);
    };

    const clearFilter = () => {
        setFilter(() => {
            return {
                contractorNameFilter: "",
                contractorPhoneFilter: "",
                creationDateFromFilter: "",
                creationDateToFilter: "",
            };
        });

        clientId && getDocumentsListPatents({
            clientIdFilter: clientId,
            pageNum: 1,
            pageSize,
        });

        setPageNum(1);
        setIsSearch(false);
    };

    const getRows = () => {
        return documentsList?.map(row => {
            const {
                creationDateTime,
                documentType,
                contractorName,
                contractorPhone,
                contractorLocatedOutsideRussia,
                documentId,
            } = row;

            return {
                ...row,
                key: documentId,
                contentRow: (
                    <NmListCard
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-15"
                        primaryHeader={types[documentType]}
                        labels={[
                            {label: "Дата и время", text: dateFormat(convertUtcToLocal(creationDateTime), "dd.MM.yyyy HH:mm")},
                            {label: "Реестр", text: renderRegistry(row)},
                            {label: "Исполнитель", text: contractorName},
                            {label: "Номер телефона", text: phoneFormat(contractorPhone, contractorLocatedOutsideRussia)},
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        actions={
                            <NmButton
                                onlyIcon
                                icon={<FileDownloadIcon />}
                                color="light-green"
                                onClick={() => onDownloadItem(row)}
                            />
                        }
                    />
                ),
            };
        });
    };

    const renderFilter = () => {
        const {
            contractorNameFilter,
            contractorPhoneFilter,
            creationDateFromFilter,
            creationDateToFilter,
        } = filter;

        return  (
            <NmForm addMargin>
                <NmInputV2
                    label={t("patents.contractorFio")}
                    value={contractorNameFilter || ""}
                    onChange={onChangeFilter}
                    name="contractorNameFilter"
                    size="lg"
                    placeholder={t("patents.contractorFioPlaceholder")}
                />
                <PhoneWithCodeFilter
                    name="contractorPhoneFilter"
                    value={contractorPhoneFilter}
                    onChange={onChangeFilter}
                />
                <NmDateRangePickerV2
                    value={{
                        "creationDateFromFilter": getDateObject(creationDateFromFilter),
                        "creationDateToFilter": getDateObject(creationDateToFilter),
                    }}
                    label={t("patents.creationDate")}
                    onChange={onChangeFilter}
                    size="lg"
                    startFieldName="creationDateFromFilter"
                    endFieldName="creationDateToFilter"
                />
                <ButtonWithTooltip
                    filter
                    size="lg"
                    onClick={onClickExport}
                    className={element("button-with-tooltip")}
                    buttonClass={element("upload")}
                    children="Выгрузить"
                    type="button"
                    tooltip={
                        (
                            <HelpTooltip
                                info
                                width={24}
                                height={24}
                                children={t("patents.exportDocsDescription")}
                                position="bottom-right"
                                type="light"
                            />
                        )
                    }
                />
                <FilterButtonsV2
                    onSearch={submitFilter}
                    onClear={clearFilter}
                />
            </NmForm>
        );
    };

    const renderRegistry = (item) => {
        const {
            registryName,
            patentRegistryId,
        } = item;

        const link = LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD.replace(":patentRegistryId", patentRegistryId)
            .replace(":clientId", clientId);

        return(
            <Link
                to={link}
            >
                {registryName}
            </Link>
        );
    };

    useEffect(() => {
        if (clientId) {
            getDocumentsListTypesPatents();
            getDocumentsListPatents({
                clientIdFilter: clientId,
                pageNum: 1,
                pageSize: pageSizes[0],
            });
        }

        setPageSize(pageSizes[0]);
        setPageNum(1);
    }, [clientId]);

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    children={t("patents.documentsListTitle")}
                />
            }
            typeFilter="vertical"
            filtersBase={renderFilter()}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onChangePagination}
            pageSizes={pageSizes}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalCount={totalCount}
            totalPages={totalPages}
            isLoaded={progress}
        >
            {
                documentsList?.length ? 
                    <CheckboxList rows={getRows()} /> : 
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
};

export default connect(
    state => ({
        documentsList: patentsDocumentsListSelector(state),
        totalCount: patentsDocumentsTotalCountSelector(state),
        totalPages: patentsDocumentsTotalPagesSelector(state),
        client: getClientCardSelector(state),
        types: patentsDocumentsListTypesSelector(state),
        progress: patentsDocumentsProgressSelector(state),
    }),
    {
        getDocumentsListPatents,
        getDocumentsListTypesPatents,
        addDocumentsExportPatents,
        downloadDocument,
    },
)(PatentsDocumentsList);