export const BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_REQUEST = "BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_REQUEST";
export const BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_SUCCESS = "BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_SUCCESS";
export const BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_ERROR = "BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_ERROR";

export const BFF_FINANCES_NPD_PAYMENTS_CONFIRM_REQUEST = "BFF_FINANCES_NPD_PAYMENTS_CONFIRM_REQUEST";
export const BFF_FINANCES_NPD_PAYMENTS_CONFIRM_SUCCESS = "BFF_FINANCES_NPD_PAYMENTS_CONFIRM_SUCCESS";
export const BFF_FINANCES_NPD_PAYMENTS_CONFIRM_ERROR = "BFF_FINANCES_NPD_PAYMENTS_CONFIRM_ERROR";

export const BFF_FINANCES_NPD_PAYMENTS_REJECT_REQUEST = "BFF_FINANCES_NPD_PAYMENTS_REJECT_REQUEST";
export const BFF_FINANCES_NPD_PAYMENTS_REJECT_SUCCESS = "BFF_FINANCES_NPD_PAYMENTS_REJECT_SUCCESS";
export const BFF_FINANCES_NPD_PAYMENTS_REJECT_ERROR = "BFF_FINANCES_NPD_PAYMENTS_REJECT_ERROR";

export const BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_REQUEST = "BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_REQUEST";
export const BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_SUCCESS = "BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_SUCCESS";
export const BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_ERROR = "BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_ERROR";

export const BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_REQUEST = "BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_REQUEST";
export const BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_SUCCESS = "BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_SUCCESS";
export const BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_ERROR = "BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_ERROR";

export const UPDATE_FINANCES_FINANCES_NPD_STORE = "UPDATE_FINANCES_RECEIPTS_CANCELLED_STORE";