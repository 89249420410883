import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Redirect} from "react-router";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import ApplyButtons from "../../../components/ApplyButtons";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";

import dateFormat, {
    convertUtcToLocal,
    formatDateWithoutTime,
} from "../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    formatAmount,
    formatNumber,
} from "../../../utils/stringFormat";
import {
    convertStringToDate,
    handleFieldFilter,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";
import {toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {LINK_CLIENT_INFO, LINK_FORBIDDEN_PAGE} from "../../../constants/links";
import {OPERATOR_REPORT_STATUSES, OPERATOR_REPORT_STATUSES_COLOR_TRANSCRIPT} from "../../../constants/operatorReports";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_CHIEF_ACCOUNTANT,
} from "../../../constants/roles";

import {
    clientListOptimizedOptionsSelector,
    clientOptionsCustomListSelector,
    getOptimizedClientList,
} from "../../../ducks/client";
import {
    currentUserRestrictionsSelector,
    getClientUserRestrictionsList,
} from "../../../ducks/clientUserRestrictions";
import {
    getClientOperatorReportStatusOptionsSelector,
    getOperatorReportStatusDictSelector,
    getOperatorReportStatusOptionsSelector,
} from "../../../ducks/documents";
import {
    addOperatorReport,
    changeOperatorReportsStatus,
    changeOperatorReportStatus,
    deleteOperatorReport,
    deleteOperatorReports,
    getOperatorReportFile,
    getOperatorReportsFileDocx,
    getOperatorReportsListSuccessLoadedSelector,
    getOperatorReportsLoadingSelector,
    getOperatorReportsPage,
    getOperatorReportsPageSelector,
    getOperatorReportsTotalCountSelector,
    getOperatorReportsTotalPagesSelector,
    getOperatorReportSuccessAddedSelector,
    updateFieldOperatorReport,
    updateOperatorReport,
} from "../../../ducks/operatorReports";

import "./style.sass";

class OperatorReports extends Component {

    constructor(props) {

        super(props);

        const {clientId} = props.match.params;

        this.clientId = clientId;

        this.isClientCard = !isNullOrWhitespace(this.clientId);

        this.state = {
            pageNum: 1,
            pageSize: 25,
            pageSizes: [25, 50, 100],
            numberSelected: 0,
            openFilter: false,
            isSearch: false,
        };

        this.isAutoUpdate = false;
        this.autoUpdateCount = 0;

        this.role = ls(USER_ROLE);
        this.isClientCard = !isNullOrWhitespace(clientId);
    }

    componentDidMount() {
        const {
            pageNum,
            pageSize,
            filterData,
            getClientUserRestrictionsList,
        } = this.props;

        if (pageNum) {
            this.setState(prevState => ({
                ...prevState,
                ...filterData,
                pageNum,
                pageSize,
            }), () => {
                this.fetchReportList();
                this.fetchClientListForFilter(null, null);
                this.fetchClientListForFilter(null, false, true);
            });

            return;
        }

        this.fetchReportList();
        this.fetchClientListForFilter(null, null);
        this.fetchClientListForFilter(null, false, true);
        getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessOperatorReports,
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOperatorReports,
                CLIENT_USER_RESTRICTIONS_VARIABLE.exportOperatorReportsDocx,
            ],
        });
    }

    componentDidUpdate(prevProps) {
        const {
            isListSuccessLoaded,
            updateFieldOperatorReport,
            isReportSuccessAdded,
        } = this.props;

        if (isReportSuccessAdded) {
            this.isAutoUpdate = true;
            this.autoUpdateCount = 0;
            updateFieldOperatorReport({isReportSuccessAdded: false});
        }

        if (isListSuccessLoaded) {
            updateFieldOperatorReport({isListSuccessLoaded: false});
            if (this.isAutoUpdate && this.autoUpdateCount < 6) {
                this.autoUpdateCount++;
                setTimeout(() => {
                    this.fetchReportList();
                }, 10000);
            } else {
                this.isAutoUpdate = false;
                this.autoUpdateCount = 0;
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {reportList} = props;
        const {reportList: oldList} = state;

        if (JSON.stringify(reportList) !== JSON.stringify(oldList)) {
            const selectedList = reportList.map(item => ({
                ...item,
            }));

            return {
                ...state,
                selectedList,
                reportList,
                numberSelected: 0,
            };
        }

        return null;
    }

    fetchReportList = () => {
        const {getOperatorReportsPage} = this.props;
        const {
            pageNum,
            pageSize,
            clientIdFilter,
            fromDateFilter,
            toDateFilter,
            statusFilter,
        } = this.state;

        const reqData = {
            pageNum,
            pageSize,
            clientIdFilter: this.isClientCard ? this.clientId : clientIdFilter,
            fromDateFilter,
            toDateFilter,
            statusFilter: handleFieldFilter(statusFilter),
        };

        getOperatorReportsPage(reqData);
    };

    filterReportList = () => {
        this.setState({
            pageNum: 1,
            isSearch: true,
        }, this.fetchReportList);
    };

    createReport = () => {
        const {addOperatorReport} = this.props;

        const {
            clientIdReport,
            toDateReport,
            fromDateReport,
        } = this.state;

        addOperatorReport({
            clientId: clientIdReport,
            toDate: toDateReport,
            fromDate: fromDateReport,
            onSuccess: this.fetchReportList,
        });
    };

    fetchClientListForFilter = (clientName, archived, isCustomRequest) => {
        const {getOptimizedClientList} = this.props;

        getOptimizedClientList({
            pageNum: 1,
            pageSize: 25,
            archivedFilter: archived,
            isCustomRequest,
            nameSubstringFilter: isNullOrWhitespace(clientName) ? null : clientName.trim(),
        });
    };

    onSearchClientChangeFilter = (clientNameFilter) => {
        this.setState({clientNameFilter}, () => {
            this.fetchClientListForFilter(clientNameFilter, null);
        });
    };

    onSearchClientChangeReport = (clientNameReport) => {
        this.setState({clientNameReport}, () => {
            this.fetchClientListForFilter(clientNameReport, false, true);
        });
    };

    onChangeClientReport = (e, {value: clientIdReport}) => {
        setTimeout(() => {
            this.setState({clientIdReport});
        }, 200);
        if (isNullOrWhitespace(clientIdReport)) {
            this.fetchClientListForFilter();
        }
    };

    onChangeClientFilter = (e, {value: clientIdFilter}) => {

        setTimeout(() => {
            this.setState({clientIdFilter});
        }, 200);
        if (isNullOrWhitespace(clientIdFilter)) {
            this.fetchClientListForFilter();
        }
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchReportList,
        );
    };

    handlePaginationChange = (e, {"activePage": pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchReportList,
        );
    };

    onDownload = ({clientId, reportId}) => {
        const {getOperatorReportFile} = this.props;

        getOperatorReportFile({
            onSuccess: () => {toastSuccess("Сформированная выгрузка доступна в разделе Экспорт отчетов");},
            clientId,
            reportId,
        });
    };

    onDownloadDocx = ({clientId, reportId}) => {
        const {getOperatorReportsFileDocx} = this.props;

        getOperatorReportsFileDocx({
            onSuccess: () => {toastSuccess("Сформированная выгрузка доступна в разделе Экспорт отчетов");},
            clientId,
            reportId,
        });
    };

    updateReportStatus = () => {
        const {changeOperatorReportStatus} = this.props;
        const {
            action: {
                status,
                report,
            },
        } = this.state;

        changeOperatorReportStatus({
            clientId: report.clientId,
            reportId: report.reportId,
            status,
            onSuccess: () => {
                this.handleCancelConfirm();
                this.fetchReportList();
            },
        });
    };

    updateReportsStatus = () => {
        const {changeOperatorReportsStatus} = this.props;
        const {
            selectedList,
            action: {
                status,
            },
        } = this.state;
        const reports = selectedList
            .filter(item => item.isSelected)
            .map((item) => {
                return {
                    reportId: item.reportId,
                    clientId: item.clientId,
                };
            });

        changeOperatorReportsStatus({
            reports,
            status,
            onSuccess: () => {
                this.handleCancelConfirm();
                this.fetchReportList();
            },
        });
    };

    deleteReport = () => {
        const {deleteOperatorReport} = this.props;
        const {
            action: {
                report,
            },
        } = this.state;

        deleteOperatorReport({
            reportId: report.reportId,
            clientId: report.clientId,
            onSuccess: () => {
                this.handleCancelConfirm();
                this.fetchReportList();
            },
        });
    };

    deleteReports = () => {
        const {deleteOperatorReports} = this.props;
        const {selectedList} = this.state;
        const reports = selectedList
            .filter(item => item.isSelected)
            .map((item) => {
                return {
                    reportId: item.reportId,
                    clientId: item.clientId,
                };
            });

        deleteOperatorReports({
            reports,
            onSuccess: () => {
                this.handleCancelConfirm();
                this.fetchReportList();
            },
        });
    };

    updateReportPeriod = () => {
        const {
            updateOperatorReport,
        } = this.props;

        const {
            fromDateEditReport,
            toDateEditReport,
            action: {
                report,
            },
        } = this.state;

        updateOperatorReport({
            clientId: report.clientId,
            reportId: report.reportId,
            fromDate: fromDateEditReport,
            toDate: toDateEditReport,
            onSuccess: () => {
                this.closeEditPeriodWindow();
                this.fetchReportList();
            },
        });
    };

    getMassActions = () => {
        const {t} = this.props;
        return {
            SIGN: {
                text: t("operator-reports.sign-report"),
                handler: this.updateReportsStatus,
                status: OPERATOR_REPORT_STATUSES.SIGNING_CUSTOMER,
                confirmText: t("operator-reports.sign-report").toLowerCase(),
                confirmationHandler: this.showConfirmWindow,
                isMassActions: true,
            },
            DELETE: {
                text: t("operator-reports.delete-report"),
                handler: this.deleteReports,
                confirmText: t("operator-reports.delete-report").toLowerCase(),
                confirmationHandler: this.showConfirmWindow,
                isMassActions: true,
            },
        };
    };

    showConfirmWindow = (action) => {
        this.setState({
            action,
            isOpenConfirmWindow: true,
        });
    };

    showEditPeriodWindow = (action) => {
        this.setState({
            action,
            isEditPeriod: true,
            fromDateEditReport: convertStringToDate(action.report.fromDate),
            toDateEditReport: convertStringToDate(action.report.toDate),
        });
    };

    closeEditPeriodWindow = () => {
        this.setState({
            action: null,
            isEditPeriod: false,
            fromDateEditReport: "",
            toDateEditReport: "",
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            isOpenConfirmWindow: false,
            action: null,
            isEditPeriod: false,
        });
    };

    renderConfirmWindow() {
        const {
            isOpenConfirmWindow,
            action,
        } = this.state;

        const {t} = this.props;

        if (isOpenConfirmWindow) {
            return (
                <NmConfirmV2
                    content={`${t("operator-reports.confirm-text")} ${action.confirmText} ${action.isMassActions ? t("operator-reports.selected-reports") : t("operator-reports.selected-report")}`}
                    onCancel={this.handleCancelConfirm}
                    onConfirm={action.handler}
                    confirmButton="Да"
                    cancelButton="Нет"
                />
            );
        }

        return null;
    }

    renderEditPeriodBlock = () => {
        const {
            isEditPeriod,
            action,
            fromDateEditReport,
            toDateEditReport,
        } = this.state;

        const {t} = this.props;

        if (isEditPeriod) {
            return (
                <NmModal
                    size="md"
                    onClose={this.closeEditPeriodWindow}
                    header={
                        <NmTitle size="lg">
                            {t("operator-reports.edit-period-header")}
                        </NmTitle>
                    }
                    footer={
                        <ApplyButtons
                            mobile="column"
                            onClose={this.closeEditPeriodWindow}
                            isHiddenCancelOnMobile
                            submitBtnContent={t("operator-reports.create-report")}
                            cancelBtnContent={t("button.cancel")}
                            submit={() => {
                                this.updateReportPeriod(action.report);
                            }}
                        />
                    }
                >
                    <NmForm addMargin>
                        <Text level="3">
                            {`${t("operator-reports.edit-period-content")} "${action.report.clientName}"`}
                        </Text>
                        <NmDateRangePickerV2
                            size="xl"
                            startFieldName="fromDateEditReport"
                            endFieldName="toDateEditReport"
                            value={{fromDateEditReport, toDateEditReport}}
                            isCurrentDateMax
                            isClearable={true}
                            handleOnChangeRangeDatepicker={this.handleOnChangeDatepicker}
                        />
                    </NmForm>
                </NmModal>
            );
        }
        return null;
    };

    onSelectedRows = (selectedList) => {
        this.setState({
            selectedList,
            numberSelected: selectedList.filter(item => item.isSelected && this.checkStatus(item)).length,
        });
    };

    checkStatus = ({status}) => {
        return status === OPERATOR_REPORT_STATUSES.DRAFT;
    };

    renderStatus = ({status}) => {
        const {
            operatorReportStatuses,
        } = this.props;

        return (
            <NmBadge
                noWrap
                mod={OPERATOR_REPORT_STATUSES_COLOR_TRANSCRIPT[status]}
                text={operatorReportStatuses[status]}
            />
        );
    };

    renderClientLink = ({clientName, brand, clientId}) => {
        const {
            pageNum,
            pageSize,
            clientIdFilter,
            fromDateFilter,
            toDateFilter,
            statusFilter,
            openFilter,
        } = this.state;

        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
                pageData={{pageNum, pageSize}}
                filterData={{
                    clientIdFilter,
                    fromDateFilter,
                    toDateFilter,
                    statusFilter,
                    openFilter,
                }}
            >
                {clientName}
                {brand && `(${brand})`}
            </ExtLink>
        );
    };

    getDateRange(item) {
        return `${formatDateWithoutTime(convertUtcToLocal(item.fromDate))} - ${formatDateWithoutTime(convertUtcToLocal(item.toDate))}`;
    }

    getMediaActions = (report) => {
        const {
            status,
        } = report;
        const {t} = this.props;

        const deleteReport = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    this.showConfirmWindow({
                        handler: this.deleteReport,
                        confirmText: t("operator-reports.delete-report").toLowerCase(),
                        report,
                    });
                },
                children: t("operator-reports.delete-report"),
            },
            visible: this.isAccessToEditStatusReport() && [OPERATOR_REPORT_STATUSES.DRAFT, OPERATOR_REPORT_STATUSES.SIGNING_CUSTOMER].includes(status),
        };

        const cancelReport = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    this.showConfirmWindow({
                        handler: this.updateReportStatus,
                        status: OPERATOR_REPORT_STATUSES.CANCELLED,
                        confirmText: t("operator-reports.cancel-report").toLowerCase(),
                        report,
                    });
                },
                children: t("operator-reports.cancel-report"),
            },
            visible: this.isAccessToEditStatusReport() && status === OPERATOR_REPORT_STATUSES.SIGNED,
        };

        const editReport = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    this.showEditPeriodWindow({
                        handler: this.updateReportPeriod,
                        confirmText: t("operator-reports.edit-report").toLowerCase(),
                        report,
                    });
                },
                children: t("operator-reports.edit-report"),
            },
            visible: this.isAccessToEditStatusReport(),
        };

        // Скачать в docx
        const downloadDocx = {
            component: COMPONENT.BUTTON,
            props: {
                color: "light-green",
                children: t("operator-reports.download-report"),
                onClick: () => {this.onDownloadDocx(report);},
            },
            visible: this.isAccessToDownloadReport(),
        };

        // Скачать в pdf
        const download = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {this.onDownload(report);},
                color: "light-green",
                children: "Выгрузить pdf",
            },
            visible: status !== OPERATOR_REPORT_STATUSES.PERIOD_ADJUSTMENT_REQUIRED,
        };

        const signReport = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    this.showConfirmWindow({
                        handler: this.updateReportStatus,
                        status: OPERATOR_REPORT_STATUSES.SIGNED,
                        confirmText: t("operator-reports.sign-report").toLowerCase(),
                        confirmationHandler: this.showConfirmWindow,
                        report,
                    });
                },
                children: t("operator-reports.sign-report"),
                color: "green",
            },
            visible: this.isAccessToSignReport() && status === OPERATOR_REPORT_STATUSES.SIGNING_CUSTOMER,
        };

        return {
            renderCount: {
                mobile: 0,
                tablet: 3,
                desktop: 3,
            },
            buttons: [
                download,
                signReport,
                downloadDocx,
                editReport,
                deleteReport,
                cancelReport,
            ],
        };
    }

    getRows = () => {
        const {
            selectedList,
        } = this.state;

        return selectedList.map(item => {
            const dateRange = this.getDateRange(item);
            const customer = !this.isClientCard ? [{
                label: "Заказчик", text: this.renderClientLink(item),
            }] : [];

            return {
                ...item,
                isSelected: Boolean(item.isSelected),
                showCheckBox: this.checkStatus(item),
                contentRow: (
                    <NmListCard
                        noDivider
                        checkbox
                        alignItems="flex-start"
                        classNameMainContent="col-md-16 col-xxl-8"
                        secondaryHeader={`Дата создания ${formatDateWithoutTime(convertUtcToLocal(item.toDate))}`}
                        secondaryHeaderStatus={this.renderStatus(item)}
                        labels={[
                            {label: "№", text: item.reportNum},
                            ...customer,
                            {label: "Период", text: dateRange},
                            {label: "Сумма оплат (руб.)", text: formatAmount(formatNumber(item.amountPayment, 2))},
                            {label: "Сумма вознаграждения (руб.)", text: formatAmount(formatNumber(item.amountRemuneration, 2))},
                        ]}
                        actionsClassName="col-3 justify-content-end"
                        mediaControls={this.getMediaActions(item)}
                    />
                ),
            };
        });
    };

    handleOnChangeDatepicker = (value, name) => {
        const _value = isNullOrWhitespace(value) ? undefined : dateFormat(value, "yyyy-MM-dd");

        this.setState({
            [name]: _value,
        });
    };

    handleOnChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }),
        );
    };

    onOpenFilter = (openFilter) => {
        this.setState({openFilter});
    };

    isAccessToCreateReport = () => {
        const {currentUserRestrictions} = this.props;

        return [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role)
            && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOperatorReports);
    };

    isAccessToEditStatusReport = () => {
        const {currentUserRestrictions} = this.props;

        return [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role) && !this.isClientCard
            && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOperatorReports);
    };

    isAccessToSignReport = () => {
        const {currentUserRestrictions} = this.props;

        return [CLIENT_ADMIN, CLIENT_ACCOUNTANT, NM_CHIEF_ACCOUNTANT].includes(this.role)
            && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOperatorReports);
    };

    isAccessToDownloadReport = () => {
        const {currentUserRestrictions} = this.props;

        return [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role)
            && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.exportOperatorReportsDocx);
    };

    clearFilter = () => {
        this.setState({
            clientIdFilter: "",
            fromDateFilter: "",
            toDateFilter: "",
            statusFilter: null,
            pageNum: 1,
            isSearch: false,
            clientNameFilter: "",
        }, this.fetchReportList);
    };

    renderContent() {
        const {numberSelected} = this.state;

        const {t} = this.props;

        return (
            <CheckboxList
                count={numberSelected}
                mediaControls={{
                    renderCount: {
                        desktop: 2,
                        tablet: 2,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "green",
                                onClick: () => {this.showConfirmWindow(this.getMassActions().SIGN);},
                                children: t("operator-reports.sign-report"),
                                disabled: numberSelected === 0,
                            },
                        },
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                onClick: () => {this.showConfirmWindow(this.getMassActions().DELETE);},
                                children: t("operator-reports.delete-report"),
                                disabled: numberSelected === 0,
                            },
                        },
                    ],
                }}
                rows={this.getRows()}
                onSelectedRows={this.isAccessToEditStatusReport() && this.onSelectedRows}
            />
        );
    };

    renderCreateReportBlock() {
        const {clientIdReport, fromDateReport, toDateReport} = this.state;
        const {clientListForCreateBlock, t} = this.props;

        if (this.isAccessToCreateReport() && !this.isClientCard) {
            return (
                <NmForm className="mb-4 mb-xxl-5">
                    <div className="col-16 col-xxl-8">
                        <div className="row align-items-end gx-2">
                            <div className="col-16 col-md-8 col-xxl-6">
                                <NmDropdownV2
                                    size="lg"
                                    isClearable
                                    label={t("operator-reports.customer")}
                                    placeholder="По всем заказчикам"
                                    onSearchChange={this.onSearchClientChangeReport}
                                    onChange={this.onChangeClientReport}
                                    search
                                    value={clientIdReport}
                                    options={clientListForCreateBlock}
                                />
                            </div>
                            <div className="col-16 col-md-8 col-xxl-6">
                                <NmDateRangePickerV2
                                    size="lg"
                                    label="Период"
                                    startFieldName="fromDateReport"
                                    endFieldName="toDateReport"
                                    value={{fromDateReport, toDateReport}}
                                    isCurrentDateMax
                                    isClearable={true}
                                    onChange={this.handleOnChange}
                                />
                            </div>
                            <div className="col-16 col-md-8 col-xxl-4 mt-3 mt-md-2 mt-xxl-0">
                                <NmButton
                                    size="lg"
                                    onClick={this.createReport}
                                >
                                    {t("operator-reports.create-report")}
                                </NmButton>
                            </div>
                        </div>
                    </div>
                </NmForm>
            );
        }
        return null;
    }

    renderFilter() {
        const {
            clientIdFilter,
            fromDateFilter,
            toDateFilter,
            statusFilter,
            clientNameFilter,
        } = this.state;

        const {
            clientListForFilter,
            reportStatusOptions,
            clientReportStatusOptions,
            t,
        } = this.props;

        return (
            <NmForm addMargin>
                {
                    !this.isClientCard ?
                        <NmDropdownV2
                            size="lg"
                            label={t("operator-reports.customer")}
                            selectOnBlur={false}
                            selectOnNavigation={false}
                            isClearable
                            noResultsMessage={t("no-search-result.content")}
                            onSearchChange={this.onSearchClientChangeFilter}
                            searchQuery={clientNameFilter}
                            onChange={this.onChangeClientFilter}
                            search
                            selection
                            value={clientIdFilter}
                            options={clientListForFilter}
                        /> :
                        null
                }
                <NmDateRangePickerV2
                    label="Период"
                    size="lg"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{fromDateFilter, toDateFilter}}
                    isCurrentDateMax
                    isClearable
                    onChange={this.handleOnChange}
                />
                <NmDropdownV2
                    size="lg"
                    label={t("operator-reports.status")}
                    name="statusFilter"
                    onChange={this.handleOnChange}
                    selection
                    value={statusFilter}
                    options={
                        [CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role) ?
                            clientReportStatusOptions :
                            reportStatusOptions
                    }
                />
                <FilterButtonsV2
                    onSearch={this.filterReportList}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    };

    render() {
        const {
            totalPages,
            totalCount,
            loading,
            currentUserRestrictions,
            t,
        } = this.props;
        const {
            pageNum,
            pageSize,
            selectedList,
            openFilter,
            isSearch,
        } = this.state;

        if (currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessOperatorReports)) {
            return <Redirect to={LINK_FORBIDDEN_PAGE} />;
        }

        return (
            <NmPage
                header={
                    <NmTitle size="xl">
                        {t("operator-reports.operator-reports-title")}
                    </NmTitle>
                }
                openFilter={openFilter}
                onOpenFilter={this.onOpenFilter}
                filtersBase={this.renderFilter()}
                typeFilter="vertical"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                className={this.isClientCard ? "operator-reports operator-reports_client-content" : "operator-reports"}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                isLoaded={loading}
            >
                {
                    this.renderConfirmWindow()
                }
                {
                    this.renderEditPeriodBlock()
                }
                {
                    this.renderCreateReportBlock()
                }
                {
                    selectedList?.length
                        ? this.renderContent()
                        : <NmEmptyPageV2
                            isSearch={isSearch}
                            fetchProgress={loading}
                        />
                }
            </NmPage>
        );
    };
}

export default withPageData(connect(
    state => ({
        totalPages: getOperatorReportsTotalPagesSelector(state),
        totalCount: getOperatorReportsTotalCountSelector(state),
        reportList: getOperatorReportsPageSelector(state),
        loading: getOperatorReportsLoadingSelector(state),
        clientListForFilter: clientListOptimizedOptionsSelector(state),
        clientListForCreateBlock: clientOptionsCustomListSelector(state),
        operatorReportStatuses: getOperatorReportStatusDictSelector(state),
        reportStatusOptions: getOperatorReportStatusOptionsSelector(state),
        clientReportStatusOptions: getClientOperatorReportStatusOptionsSelector(state),
        isListSuccessLoaded: getOperatorReportsListSuccessLoadedSelector(state),
        isReportSuccessAdded: getOperatorReportSuccessAddedSelector(state),
        currentUserRestrictions: currentUserRestrictionsSelector(state),
    }),
    {
        getOperatorReportsPage,
        getOptimizedClientList,
        addOperatorReport,
        getOperatorReportFile,
        changeOperatorReportStatus,
        changeOperatorReportsStatus,
        deleteOperatorReport,
        updateOperatorReport,
        deleteOperatorReports,
        updateFieldOperatorReport,
        getOperatorReportsFileDocx,
        getClientUserRestrictionsList,
    },
)(withTranslation()(OperatorReports)));