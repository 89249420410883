import {useState} from "react";
import {isEmpty} from "lodash";

import {formatDateWithoutTime} from "../../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {TICKET_MANAGER} from "../../../../../constants/crm/ticket";

import {FILTER_TYPE} from "../../../../../constants/ mailings/segmentationType";
import {SUB_PAGE_CRM_TICKET} from "../../../../../constants/link-params";

export function useTicketFilter(params) {
    const {
        subpage,
    } = params;

    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            callTypeFilter,
            categoryFilter,
            clientNameOrFioFilter,
            closeDateFrom,
            closeDateTo,
            createDateFrom,
            createDateTo,
            managerClientUserIdsFilter,
            numberFilter,
            phoneFilter,
            typeFilter,
            onlyMyTickets,
            addressFiasIdsFilter,
            timeZonesFilter,
        } = filter;

        const format = "yyyy-MM-dd";

        return {
            onlyMyTickets,
            callTypesFilter: callTypeFilter === FILTER_TYPE.ALL ? undefined : [callTypeFilter],
            typesFilter: typeFilter || undefined,
            numberFilter: numberFilter || undefined,
            closeDateFrom: isNullOrWhitespace(closeDateFrom) ? undefined : formatDateWithoutTime(closeDateFrom, format),
            closeDateTo: isNullOrWhitespace(closeDateTo) ? undefined : formatDateWithoutTime(closeDateTo, format),
            createDateFrom: isNullOrWhitespace(createDateFrom) ? undefined : formatDateWithoutTime(createDateFrom, format),
            createDateTo: isNullOrWhitespace(createDateTo) ? undefined : formatDateWithoutTime(createDateTo, format),
            clientNameOrFioFilter: handleFormString(clientNameOrFioFilter),
            phoneFilter: phoneFilter ? removePhoneMask(phoneFilter) : undefined,
            managerClientUserIdsFilter: isEmpty(managerClientUserIdsFilter) ? undefined : managerClientUserIdsFilter
                .filter(item => item !== TICKET_MANAGER.IS_AWAY),
            managerIsAway: isEmpty(managerClientUserIdsFilter) ? undefined : managerClientUserIdsFilter.includes(TICKET_MANAGER.IS_AWAY),
            categoriesFilter: isNullOrWhitespace(categoryFilter) ? undefined : [categoryFilter],
            addressFiasIdsFilter:
                addressFiasIdsFilter.length
                && ![
                    SUB_PAGE_CRM_TICKET.KEDO.LINK,
                ].includes(subpage)
                    ? addressFiasIdsFilter
                    : undefined,
            timeZonesFilter:
                timeZonesFilter.length
                && ![
                    SUB_PAGE_CRM_TICKET.KEDO.LINK,
                ].includes(subpage)
                    ? timeZonesFilter
                    : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}