import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {ImportFromFilePatternForm} from "../../../../../components/ActualComponents/ImportFromFilePatternV2/Form";
import {KedoStaffRegistryEditSubmitButtons} from "../submit-buttons";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {importKedoStaffFromFile} from "../../../../../ducks/kedo/staff/actionCreators";

export const KedoStaffRegistryAddFromExcel = (props) => {
    const {
        clientId,
        fetchList,
        onClose,
        refFooter,
    } = props;

    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);

    function onSubmit() {
        const formData = new FormData();

        formData.append("file", files[0]);

        dispatch(importKedoStaffFromFile({
            clientId,
            file: formData,
            onSuccess: () => {
                toastSuccess("Задача по загрузке списка сотрудников из файла передана в обработку.  Прогресс выполнения задачи доступен в разделе \"Список задач\"");

                fetchList();
                onClose();
            },
        }));
    }

    return (
        <>
            <ImportFromFilePatternForm
                maxSize={10}
                files={files}
                setFiles={setFiles}
                patternLink="/files/Шаблон_Реестр сотрудников.xlsx"
            />
            <KedoStaffRegistryEditSubmitButtons
                disabled={!files[0]}
                onSubmit={onSubmit}
                refFooter={refFooter}
                onClose={onClose}
            />
        </>
    );
};