import {combineReducers} from "redux";

import kedoLogs from "../kedo/logs/reducer";
import bankTkb from "./bank/tkb/reducer";
import bankW1 from "./bank/w1/reducer";
import bot from "./bot/reducer";
import clientsInstructionsKedo from "./clients/instructions/kedo/reducer";
import clientsModuleTariffs from "./clients/module-tariffs/reducer";
import notificationSendingToContractor from "./clients/notification-sending/to-contractor/reducer";
import clientsSettingsPayments from "./clients/settings/reducer";
import clientsSystemProperties from "./clients/system-properties/reducer";
import commonDicts from "./common/dicts/reducer";
import commonTree from "./common/tree/reducer";
import contractorTaskHistory from "./contractor/taskHistory/reducer";
import crowdActRegistry from "./crowd/actRegistry/reducer";
import crowdActRegistryEntry from "./crowd/actRegistryEntry/reducer";
import crowdPayment from "./crowd/payment/reducer";
import crowdTaskCard from "./crowd/taskCard/reducer";
import crowdTaskGroup from "./crowd/taskGroup/reducer";
import crowdTaskGroups from "./crowd/taskGroups/reducer";
import crowdTasks from "./crowd/tasks/reducer";
import crowdTasksAnalytics from "./crowd/tasksAnalytics/reducer";
import documentsAdditionalAgreements from "./documents/additional-agreements/reducer";
import documentsAdditionalAgreementsRegistry from "./documents/additional-agreements/registry/reducer";
import financesNdflPayments from "./finances/ndfl-payments/reducer";
import financesNpdPayments from "./finances/npd-payments/reducer";
import financesReceiptsCancelled from "./finances/receipts-cancelled/reducer";
import map from "./map/reducer";
import patentPayments from "./patent/reducer";
import plutoniumTransactionLog from "./plutonium/transaction-log/reducer";
import recruitmentAccessControlJobBoards from "./recruitment/access-control/job-boards/reducer";
import recruitmentAccessControlObservers from "./recruitment/access-control/observers/reducer";
import recruitmentAccessControlRecruitersGroups from "./recruitment/access-control/recruiters-groups/reducer";
import recruitmentAccessControl from "./recruitment/access-control/reducer";
import recruitmentAccessControlVacancyPersonalAccess from "./recruitment/access-control/vacancy-personal-access/reducer";
import recruitmentDirectory from "./recruitment/directory/reducer";
import recruitmentJobBoards from "./recruitment/jobBoards/reducer";
import recruitmentJobBoardsWebHook from "./recruitment/jobBoardsWebhook/reducer";
import recruitmentVacancy from "./recruitment/vacancy/reducer";
import recruitmentVacancyCandidates from "./recruitment/vacancyCandidates/reducer";
import recruitmentVacancyCandidatesResponses from "./recruitment/vacancyCandidates/responses/reducer";
import recruitmentVacancyCandidatesComments from "./recruitment/vacancyCandidatesComments/reducer";
import recruitmentVacancyResponses from "./recruitment/vacancyResponses/reducer";
import settingsInstructionsKedo from "./settings/instructions/kedo/reducer";
import systemTransactions from "./system-transactions/reducer";

export default (history) => combineReducers({
    clients: combineReducers({
        settings: clientsSettingsPayments,
        systemProperties: clientsSystemProperties,
        moduleTariffs: clientsModuleTariffs,
        instructions: combineReducers({
            kedo: clientsInstructionsKedo,
        }),
        kedo: combineReducers({
            logs: kedoLogs,
        }),
    }),
    common: combineReducers({
        dicts: commonDicts,
    }),
    recruitment: combineReducers({
        jobBoards: recruitmentJobBoards,
        jobBoardsWebhook: recruitmentJobBoardsWebHook,
        directory: recruitmentDirectory,
        vacancyCandidates: recruitmentVacancyCandidates,
        vacancyCandidatesResponses: recruitmentVacancyCandidatesResponses,
        vacancyCandidatesComments: recruitmentVacancyCandidatesComments,
        vacancy: recruitmentVacancy,
        vacancyResponses: recruitmentVacancyResponses,
        accessControl: combineReducers({
            common: recruitmentAccessControl,
            recruitersGroups: recruitmentAccessControlRecruitersGroups,
            jobBoards: recruitmentAccessControlJobBoards,
            observers: recruitmentAccessControlObservers,
            vacancyPersonalAccess: recruitmentAccessControlVacancyPersonalAccess,
        }),
    }),
    settings: combineReducers({
        instructions: combineReducers({
            kedo: settingsInstructionsKedo,
        }),
    }),
    bank: combineReducers({
        tkb: bankTkb,
        w1: bankW1,
    }),
    crowd: combineReducers({
        tasks: crowdTasks,
        taskCard: crowdTaskCard,
        taskGroups: crowdTaskGroups,
        taskGroup: crowdTaskGroup,
        payment: crowdPayment,
        tasksAnalytics: crowdTasksAnalytics,
        actRegistry: crowdActRegistry,
        actRegistryEntry: crowdActRegistryEntry,
    }),
    contractor: combineReducers({
        taskHistory: contractorTaskHistory,
    }),
    plutonium: combineReducers({
        transactionLog: plutoniumTransactionLog,
    }),
    finances: combineReducers({
        receiptsCancelled: financesReceiptsCancelled,
        npdPayments: financesNpdPayments,
        ndflPayments: financesNdflPayments,
    }),
    map,
    systemTransactions,
    patentPayments,
    bot,
    commonTree,
    documentsAdditionalAgreements,
    documentsAdditionalAgreementsRegistry,
    notificationSendingToContractor,
});