import React from "react";

import NmButton from "../NmButton";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const ButtonWithTooltip = props => {
    const {
        buttonClass = "",
        className = "",
        loading,
        disabled,
        size = "xl",
        onClick,
        tooltip,
        filter,
        children,
        ...otherProps
    } = props;
    const [block, element] = bem("button-with-tooltip", className);


    return (
        <div className={block()}>
            <NmButton
                loading={loading}
                disabled={disabled}
                size={size}
                className={buttonClass}
                onClick={onClick}
                {...otherProps}
            >
                {children}
            </NmButton>
            <div className={element("tlp", {filter})}>
                {tooltip}
            </div>
        </div>
    );
};

ButtonWithTooltip.propTypes = {
    tooltip: PropTypes.node,
    buttonClass: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    onClick: PropTypes.func,
};

export default ButtonWithTooltip;