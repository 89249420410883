import React from "react";

import CKETextEditor from "../../../../../components/ActualComponents/CKETextEditor";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";

export const RecruitmentVacancyDescriptionEditor = (props) => {
    const {
        value,
        onChange,
        error,
        isVisibleTooltip,
    } = props;

    return (
        <CKETextEditor
            label="Описание вакансии"
            placeholder="Опишите подробно детали вакансии"
            value={value}
            onChange={onChange}
            error={error}
            labelProps={{
                isVisibleTooltip,
                required: true,
                tooltip: (
                    <HelpTooltip
                        hover
                        info
                        width={20}
                        height={20}
                        text="Описание вакансии, которое будет отображаться исполнителям на площадке и на джоб-бордах"
                    />
                ),
            }}
        />
    );
};