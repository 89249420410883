import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/scores/contractor";
//*  TYPES  *//

const GET_CONTRACTOR_SCORES_PAGE_REQUEST = "GET_CONTRACTOR_SCORES_PAGE_REQUEST";
const GET_CONTRACTOR_SCORES_PAGE_SUCCESS = "GET_CONTRACTOR_SCORES_PAGE_SUCCESS";
const GET_CONTRACTOR_SCORES_PAGE_ERROR = "GET_CONTRACTOR_SCORES_PAGE_ERROR";

const ADD_CONTRACTOR_SCORE_REQUEST = "ADD_CONTRACTOR_SCORE_REQUEST";
const ADD_CONTRACTOR_SCORE_SUCCESS = "ADD_CONTRACTOR_SCORE_SUCCESS";
const ADD_CONTRACTOR_SCORE_ERROR = "ADD_CONTRACTOR_SCORE_ERROR";

const CONFIRM_CONTRACTOR_SCORE_REQUEST = "CONFIRM_CONTRACTOR_SCORE_REQUEST";
const CONFIRM_CONTRACTOR_SCORE_SUCCESS = "CONFIRM_CONTRACTOR_SCORE_SUCCESS";
const CONFIRM_CONTRACTOR_SCORE_ERROR = "CONFIRM_CONTRACTOR_SCORE_ERROR";

const DELETE_CONTRACTOR_SCORE_REQUEST = "DELETE_CONTRACTOR_SCORE_REQUEST";
const DELETE_CONTRACTOR_SCORE_SUCCESS = "DELETE_CONTRACTOR_SCORE_SUCCESS";
const DELETE_CONTRACTOR_SCORE_ERROR = "DELETE_CONTRACTOR_SCORE_ERROR";

const DECLINE_CONTRACTOR_SCORE_REQUEST = "DECLINE_CONTRACTOR_SCORE_REQUEST";
const DECLINE_CONTRACTOR_SCORE_SUCCESS = "DECLINE_CONTRACTOR_SCORE_SUCCESS";
const DECLINE_CONTRACTOR_SCORE_ERROR = "DECLINE_CONTRACTOR_SCORE_ERROR";

const UPDATE_CONTRACTOR_SCORE_REQUEST = "UPDATE_CONTRACTOR_SCORE_REQUEST";
const UPDATE_CONTRACTOR_SCORE_SUCCESS = "UPDATE_CONTRACTOR_SCORE_SUCCESS";
const UPDATE_CONTRACTOR_SCORE_ERROR = "UPDATE_CONTRACTOR_SCORE_ERROR";

const GET_CONTRACTOR_SCORES_COUNT_REQUEST = "GET_CONTRACTOR_SCORES_COUNT_REQUEST";
const GET_CONTRACTOR_SCORES_COUNT_SUCCESS = "GET_CONTRACTOR_SCORES_COUNT_SUCCESS";
const GET_CONTRACTOR_SCORES_COUNT_ERROR = "GET_CONTRACTOR_SCORES_COUNT_ERROR";

const CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_REQUEST = "CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_REQUEST";
const CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_SUCCESS = "CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_SUCCESS";
const CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_ERROR = "CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_ERROR";

const DELETE_CONTRACTOR_SCORES_REPLY_REQUEST = "DELETE_CONTRACTOR_SCORES_REPLY_REQUEST";
const DELETE_CONTRACTOR_SCORES_REPLY_SUCCESS = "DELETE_CONTRACTOR_SCORES_REPLY_SUCCESS";
const DELETE_CONTRACTOR_SCORES_REPLY_ERROR = "DELETE_CONTRACTOR_SCORES_REPLY_ERROR";

const EDIT_CONTRACTOR_SCORES_REPLY_REQUEST = "EDIT_CONTRACTOR_SCORES_REPLY_REQUEST";
const EDIT_CONTRACTOR_SCORES_REPLY_SUCCESS = "EDIT_CONTRACTOR_SCORES_REPLY_SUCCESS";
const EDIT_CONTRACTOR_SCORES_REPLY_ERROR = "EDIT_CONTRACTOR_SCORES_REPLY_ERROR";

//*  INITIAL STATE  *//

const initial = {
    contractorScore: {},
    pageData: {},
    list: [],
    totalCount: 0,
    progressPage: false,
    actionProgress: false,
};

//*  REDUCER  *//


export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CONTRACTOR_SCORES_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case ADD_CONTRACTOR_SCORE_REQUEST:
    case CONFIRM_CONTRACTOR_SCORE_REQUEST:
    case DELETE_CONTRACTOR_SCORE_REQUEST:
    case DECLINE_CONTRACTOR_SCORE_REQUEST:
    case UPDATE_CONTRACTOR_SCORE_REQUEST:
    case CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_REQUEST:
    case DELETE_CONTRACTOR_SCORES_REPLY_REQUEST:
    case EDIT_CONTRACTOR_SCORES_REPLY_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case GET_CONTRACTOR_SCORES_PAGE_SUCCESS:
        const {
            results: list,
            totalCount,
        } = payload;

        return {
            ...state,
            progressPage: false,
            totalCount,
            list,
        };
    case ADD_CONTRACTOR_SCORE_SUCCESS:
    case CONFIRM_CONTRACTOR_SCORE_SUCCESS:
    case DELETE_CONTRACTOR_SCORE_SUCCESS:
    case DECLINE_CONTRACTOR_SCORE_SUCCESS:
    case UPDATE_CONTRACTOR_SCORE_SUCCESS:
    case CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_SUCCESS:
    case DELETE_CONTRACTOR_SCORES_REPLY_SUCCESS:
    case EDIT_CONTRACTOR_SCORES_REPLY_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case GET_CONTRACTOR_SCORES_COUNT_SUCCESS:
        return {
            ...state,
            totalCount: payload,
        };
    case GET_CONTRACTOR_SCORES_PAGE_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    case ADD_CONTRACTOR_SCORE_ERROR:
    case CONFIRM_CONTRACTOR_SCORE_ERROR:
    case DELETE_CONTRACTOR_SCORE_ERROR:
    case DECLINE_CONTRACTOR_SCORE_ERROR:
    case UPDATE_CONTRACTOR_SCORE_ERROR:
    case CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_ERROR:
    case DELETE_CONTRACTOR_SCORES_REPLY_ERROR:
    case EDIT_CONTRACTOR_SCORES_REPLY_ERROR:
        return {
            ...state,
            actionProgress: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorScoresPage(payload) {
    return {
        type: GET_CONTRACTOR_SCORES_PAGE_REQUEST,
        payload,
    };
}

export function addContractorScore(payload) {
    return {
        type: ADD_CONTRACTOR_SCORE_REQUEST,
        payload,
    };
}

export function confirmContractorScore(payload) {
    return {
        type: CONFIRM_CONTRACTOR_SCORE_REQUEST,
        payload,
    };
}

export function deleteContractorScore(payload) {
    return {
        type: DELETE_CONTRACTOR_SCORE_REQUEST,
        payload,
    };
}

export function declineContractorScore(payload) {
    return {
        type: DECLINE_CONTRACTOR_SCORE_REQUEST,
        payload,
    };
}

export function updateContractorScore(payload) {
    return {
        type: UPDATE_CONTRACTOR_SCORE_REQUEST,
        payload,
    };
}

export function getContractorScoresCount(payload) {
    return {
        type: GET_CONTRACTOR_SCORES_COUNT_REQUEST,
        payload,
    };
}

export function changeStatusContractorScoresReply(payload) {
    return {
        type: CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_REQUEST,
        payload,
    };
}

export function editContractorScoresReply(payload) {
    return {
        type: EDIT_CONTRACTOR_SCORES_REPLY_REQUEST,
        payload,
    };
}

export function deleteContractorScoresReply(payload) {
    return {
        type: DELETE_CONTRACTOR_SCORES_REPLY_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorScoresSelector = state => state.contractorScores;
export const getContractorScoresTotalPagesSelector = createSelector(contractorScoresSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getContractorScoresTotalCountSelector = createSelector(contractorScoresSelector, ({totalCount}) => totalCount);
export const getContractorScoresListSelector = createSelector(contractorScoresSelector, ({list}) => list);
export const getContractorScoresProgressPageSelector = createSelector(contractorScoresSelector, ({progressPage}) => progressPage);
export const getContractorScoresActionProgressSelector = createSelector(contractorScoresSelector, ({actionProgress}) => actionProgress);

//*  SAGA  *//

//POST /api/scores/contractor/page/rich
export const getContractorScoresPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/page/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_SCORES_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CONTRACTOR_SCORES_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_SCORES_PAGE_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/add
export const addContractorScoreSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CONTRACTOR_SCORE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_CONTRACTOR_SCORE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_SCORE_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/confirmReviewOnContractor/{id}
export const confirmContractorScoreSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/confirmReviewOnContractor/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONFIRM_CONTRACTOR_SCORE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: CONFIRM_CONTRACTOR_SCORE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONFIRM_CONTRACTOR_SCORE_ERROR, payload: error});
    }
};

//DELETE /api/scores/contractor/deleteReviewOnContractor/{id}
export const deleteContractorScoreSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.delete(`${controller}/deleteReviewOnContractor/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_SCORE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_CONTRACTOR_SCORE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_SCORE_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/declineReviewOnClient/{id}
export const declineContractorScoreSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/declineReviewOnClient/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_SCORE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_CONTRACTOR_SCORE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_SCORE_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/updateReviewOnClient
export const updateContractorScoreSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/updateReviewOnClient`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_SCORE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: UPDATE_CONTRACTOR_SCORE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_SCORE_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/count
export const getContractorScoresCountSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/count`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_SCORES_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CONTRACTOR_SCORES_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_SCORES_COUNT_ERROR, payload: error});
    }
};

//DELETE /api/scores/contractor/{reviewId}/reply/delete
export const deleteContractorScoresReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
        } = payload;

        const result = yield request.delete(`${controller}/${reviewId}/reply/delete`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_SCORES_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: DELETE_CONTRACTOR_SCORES_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_SCORES_REPLY_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/{id}/reply/change-status
export const changeStatusContractorScoresReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/${reviewId}/reply/change-status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_ERROR, payload: error});
    }
};

//POST /api/scores/contractor/{reviewId}/reply/update
export const editContractorScoresReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/${reviewId}/reply/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDIT_CONTRACTOR_SCORES_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: EDIT_CONTRACTOR_SCORES_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: EDIT_CONTRACTOR_SCORES_REPLY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CONTRACTOR_SCORES_PAGE_REQUEST, getContractorScoresPageSaga),
        takeEvery(ADD_CONTRACTOR_SCORE_REQUEST, addContractorScoreSaga),
        takeEvery(CONFIRM_CONTRACTOR_SCORE_REQUEST, confirmContractorScoreSaga),
        takeEvery(DELETE_CONTRACTOR_SCORE_REQUEST, deleteContractorScoreSaga),
        takeEvery(DECLINE_CONTRACTOR_SCORE_REQUEST, declineContractorScoreSaga),
        takeEvery(UPDATE_CONTRACTOR_SCORE_REQUEST, updateContractorScoreSaga),
        takeEvery(GET_CONTRACTOR_SCORES_COUNT_REQUEST, getContractorScoresCountSaga),
        takeEvery(DELETE_CONTRACTOR_SCORES_REPLY_REQUEST, deleteContractorScoresReplySaga),
        takeEvery(CHANGE_STATUS_CONTRACTOR_SCORES_REPLY_REQUEST, changeStatusContractorScoresReplySaga),
        takeEvery(EDIT_CONTRACTOR_SCORES_REPLY_REQUEST, editContractorScoresReplySaga),
    ]);
}
