import React, {useContext, useRef} from "react";

import NmTitle from "../../../../NmTitle";
import NmModal from "../../../NmModal";
import {VerticalTabs} from "../../../VerticalTabs";
import {ClientSettingsAdditionalModules} from "../AdditionalModules";
import {ClientSettingsContext} from "../ContextProvider";
import {ClientSettingsDepositAndPayments} from "../DepositAndPayments";
import {ClientSettingsDocuments} from "../Documents";
import {ClientSettingsExternalApiMethods} from "../ExternalApiMethods";
import {ClientSettingsOther} from "../Other";
import {ClientSettingsWorkWithContractors} from "../WorkWithContractors";

import {useVerticalTabsMenu} from "../../../VerticalTabs/hooks/useTabMenu";
import {useClientSettingsForm} from "../../hooks/useForm";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {ADMIN, NM_CONSULTANT, NM_MANAGER} from "../../../../../constants/roles";
import {CLIENT_SETTINGS_TAB, CLIENT_SETTINGS_TAB_NAME} from "../../constants";

export const ClientSettingsForm = (props) => {
    const {
        onClose,
        clientId,
        name,
    } = props;

    const {
        tab,
        onOpenUnsavedDataConfirm,
    } = useContext(ClientSettingsContext);

    const role = ls(USER_ROLE);
    const isAccess = [ADMIN, NM_MANAGER, NM_CONSULTANT].includes(role);
    const header = `${isAccess ? "Редактирование параметров компании" : "Параметры компании"} — ${name}`;
    const refFooter = useRef(null);

    const {
        isMobile,
        isShowTabMenu,
        hideTabsMenu,
        showTabsMenu,
    } = useVerticalTabsMenu();

    const {
    } = useClientSettingsForm({
        clientId,
    });

    const updateTabsVisibility = (isReturnToTabs) => {
        if (isReturnToTabs) {
            showTabsMenu();

            return;
        }

        hideTabsMenu();
    };

    const onClickTab = (data) => {
        const {
            name,
            isReturnToTabs,
        } = data;

        if (tab.isChangedData) {
            onOpenUnsavedDataConfirm({
                tabName: name,
                onSuccess: () => {
                    updateTabsVisibility(isReturnToTabs);
                },
            });

            return;
        }

        updateTabsVisibility(isReturnToTabs);
    };

    const getFooter = () => {
        return (
            <div ref={refFooter} />
        );
    };

    return (
        <NmModal
            size="lg"
            isShowBackButton={
                !isShowTabMenu
            }
            goBack={() => {
                onClickTab({...tab, isReturnToTabs: true});
            }}
            header={
                <NmTitle size="lg">
                    {header}
                </NmTitle>
            }
            footer={getFooter()}
            onClose={onClose}
            loading={false}
        >
            <VerticalTabs
                tabName={tab.name}
                isShowContentName={true}
                isActiveGoToTab={!tab.isChangedData}
                onClickTab={onClickTab}
                isShowTabs={isShowTabMenu}
                isDisabledClickToCurrentTab={!isMobile}
                isShowContent={!isShowTabMenu || !isMobile}
                tabs={[
                    {
                        text: CLIENT_SETTINGS_TAB_NAME.ADDITIONAL_MODULES,
                        name: CLIENT_SETTINGS_TAB.ADDITIONAL_MODULES,
                        content: (
                            <ClientSettingsAdditionalModules
                                clientId={clientId}
                                role={role}
                                isAccess={isAccess}
                                refFooter={refFooter}
                                onClose={onClose}
                            />
                        ),
                    },
                    {
                        text: CLIENT_SETTINGS_TAB_NAME.DEPOSIT_AND_PAYMENTS,
                        name: CLIENT_SETTINGS_TAB.DEPOSIT_AND_PAYMENTS,
                        content: (
                            <ClientSettingsDepositAndPayments
                                clientId={clientId}
                                role={role}
                                isAccess={isAccess}
                                refFooter={refFooter}
                                onClose={onClose}
                            />
                        ),
                    },
                    {
                        text: CLIENT_SETTINGS_TAB_NAME.DOCUMENTS,
                        name: CLIENT_SETTINGS_TAB.DOCUMENTS,
                        content: (
                            <ClientSettingsDocuments
                                clientId={clientId}
                                role={role}
                                isAccess={isAccess}
                                refFooter={refFooter}
                                onClose={onClose}
                            />
                        ),
                    },
                    {
                        text: CLIENT_SETTINGS_TAB_NAME.WORK_WITH_CONTRACTORS,
                        name: CLIENT_SETTINGS_TAB.WORK_WITH_CONTRACTORS,
                        content: (
                            <ClientSettingsWorkWithContractors
                                clientId={clientId}
                                role={role}
                                isAccess={isAccess}
                                refFooter={refFooter}
                                onClose={onClose}
                            />
                        ),
                    },
                    {
                        text: CLIENT_SETTINGS_TAB_NAME.EXTERNAL_API_METHODS,
                        name: CLIENT_SETTINGS_TAB.EXTERNAL_API_METHODS,
                        content: (
                            <ClientSettingsExternalApiMethods
                                isAccess={isAccess}
                                clientId={clientId}
                                onClose={onClose}
                                refFooter={refFooter}
                            />
                        ),
                    },
                    {
                        text: CLIENT_SETTINGS_TAB_NAME.OTHER,
                        name: CLIENT_SETTINGS_TAB.OTHER,
                        content: (
                            <ClientSettingsOther
                                clientId={clientId}
                                role={role}
                                isAccess={isAccess}
                                refFooter={refFooter}
                                onClose={onClose}
                            />
                        ),
                    },
                ]}
            />
        </NmModal>
    );
};
