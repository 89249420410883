import {useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import {FORM_CONFIRM_CONTENT} from "../consts/form";
import {depositRateEditValidationScheme} from "../validation";

import {formatDateWithoutTime} from "../../../../../../utils/dateFormat";
import {getDepositRateEditInitialState} from "../utils/getInitialState";
import {getDepositRateEditRequestData} from "../utils/getRequestData";

import {
    changeClientPropertiesGphCommissions,
    changeClientPropertiesSmzCommissions,
} from "../../../../../../ducks/clientProperties";

export const useDepositRateEditForm = ({clientId, payment, isCivil, onClose, onFetchList}) => {
    const dispatch = useDispatch();
    const [isOpenConfirm, setOpenConfirm] = useState(false);

    const {
        values,
        errors,
        setFieldValue,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: getDepositRateEditInitialState(payment),
        validationSchema: depositRateEditValidationScheme,
        onSubmit: onSubmit,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
    });

    function onSubmit() {
        if (!isValid) {
            return;
        }

        onOpenConfirm();
    }

    function onOpenConfirm() {
        setOpenConfirm(true);
    }

    function onCloseConfirm() {
        setOpenConfirm(false);
    }

    function onSubmitConfirm() {
        const fields = getFields();
        const requestData = getDepositRateEditRequestData(values, fields);

        if (isCivil) {
            dispatch(changeClientPropertiesGphCommissions({
                ...requestData,
                clientId,
                onSuccess: () => {
                    onFetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(changeClientPropertiesSmzCommissions({
            ...requestData,
            clientId,
            onSuccess: () => {
                onFetchList();
                onClose();
            },
        }));
    }

    function onChange(event, {name, value, checked}, blockName) {
        if (name === "isDeferred") {
            setFieldValue(blockName, {
                ...values[blockName],
                isDeferred: checked,
                fromDatetime: null,
                deferredRate: "",
                regionTime: "MSK",
                deferredThreshold: "",
                deferredMinCommission: "",
            });

            return;
        }

        if (name === "fromDatetime") {
            const isMoreMinTime = value.getTime() > new Date().getTime();

            setFieldValue(`${blockName}.fromDatetime`, isMoreMinTime ? value : new Date());

            return;
        }

        setFieldValue(`${blockName}.${name}`, value || checked);
    }

    function getFields() {
        return [
            {
                title: `Ставка комиссии компании за выплаты ${isCivil ? "НДФЛ" : "НПД"}`,
                name: "clientCommissionChange",
            },
            {
                title: `Ставка комиссии исполнителей за выплаты по заказам ${isCivil ? "НДФЛ" : "НПД"}`,
                name: "contractorOrderCommissionChange",
            },
            {
                title: `Ставка комиссии исполнителей за реестровые выплаты ${isCivil ? "НДФЛ" : "НПД"}`,
                name: "contractorRegistryCommissionChange",
            },
            !isCivil && {
                title: "Дополнительная комиссия компании за выплаты НПД",
                name: "minCommissionChange",
            },
            isCivil && {
                title: "Минимальная комиссия компании за выплаты НДФЛ",
                name: "minimalCommissionNdflChange",
            },
        ].filter(item => Boolean(item));
    }

    function getConfirmContent() {
        const updateDate = values.clientCommissionChange.isDeferred ?
            formatDateWithoutTime(values.clientCommissionChange.fromDatetime) :
            formatDateWithoutTime(new Date());

        return FORM_CONFIRM_CONTENT
            .replace(":updateDate", updateDate)
            .replace(":typeContract", isCivil ? "НДФЛ" : "НПД");
    }

    return {
        isOpenConfirm,
        errors,
        values,
        onChange,
        handleSubmit,
        onSubmitConfirm,
        onCloseConfirm,
        fields: getFields(),
        confirmContent: getConfirmContent(),
    };
};