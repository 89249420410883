import React from "react";

import Tabs from "../../../../../components/ActualComponents/Tabs";

import {LINK_SETTINGS_MEMBERS_LIST} from "../../../../../constants/links";

import "./style.sass";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../../../../constants/link-params";

export default function SettingsUsersTabs(props: any) {
    const {
        subpage,
        otherCount,
        partnerCount,
    } = props;

    function getTabData() {
        const baseUrl = LINK_SETTINGS_MEMBERS_LIST.replace(":pageNumber", "1").replace(":pageSize", "25");

        const linkOtherUsers = baseUrl.replace(":subpage", SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK);
        const linkPartners = baseUrl.replace(":subpage", SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK);


        return [
            {
                counter: otherCount,
                text: "Сотрудники",
                link: linkOtherUsers,
                active: subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK,
            },
            {
                counter: partnerCount,
                text: "Партнеры",
                link: linkPartners,
                active: subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK,
            },
        ];
    }

    const getTabs = () => {
        return getTabData().map((item) => {
            const {
                counter,
                text,
                link,
                active,
            } = item;

            return {
                name: text,
                count: counter,
                link,
                active,
            };
        });
    };

    return (
        <Tabs
            secondary
            panes={getTabs()}
        />
    );
}