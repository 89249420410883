import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    clearRecruitmentAccessControlJobBoardsStore,
    searchRecruitmentAccessControlJobBoardAccounts,
} from "../../../ducks/bff/recruitment/access-control/job-boards/actionsCreators";
import {
    bffRecruitmentAccessControlJobBoardsListLoadingSelector,
    bffRecruitmentAccessControlJobBoardsListOptionsSelector,
} from "../../../ducks/bff/recruitment/access-control/job-boards/selectors";
import NmDropdownV2 from "../../ActualComponents/NmDropdownV2";

import {handleFormString} from "../../../utils/stringHelper";

export const RecruitmentAccountDropdown = (props) => {
    const {
        clientId,
        jobBoardType,
        isMountLoad,
        disabled,
        ...dropdownProps
    } = props;

    const _isMountLoad = () => {
        if (typeof isMountLoad === "boolean") {
            return isMountLoad;
        }

        return !jobBoardType;
    };

    const dispatch = useDispatch();

    const options = useSelector(bffRecruitmentAccessControlJobBoardsListOptionsSelector);
    const progress = useSelector(bffRecruitmentAccessControlJobBoardsListLoadingSelector);

    useEffect(() => {
        if (jobBoardType) {
            onSearchChange();
        }
    }, [
        jobBoardType,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentAccessControlJobBoardsStore());
        };
    }, []);

    const onSearchChange = (name) => {
        dispatch(searchRecruitmentAccessControlJobBoardAccounts({
            clientId,
            jobBoardType: handleFormString(jobBoardType),
            pageNum: 1,
            pageSize: 25,
            archived: false,
            name: handleFormString(name),
        }));
    };

    return (
        <NmDropdownV2
            search={true}
            {...dropdownProps}
            options={options}
            onSearchChange={onSearchChange}
            disabled={progress || disabled}
            isMountLoad={_isMountLoad()}
        />
    );
};