import {REQUISITES_FIELD_NAME} from "./requisites";

export const REPRESENTATIVE_FIELDS = [
    REQUISITES_FIELD_NAME.REPRESENTATIVE_FIRST_NAME,
    REQUISITES_FIELD_NAME.REPRESENTATIVE_LAST_NAME,
    REQUISITES_FIELD_NAME.REPRESENTATIVE_PATRONYMIC,
    REQUISITES_FIELD_NAME.REPRESENTATIVE_DOCUMENT,
    REQUISITES_FIELD_NAME.POSITION,
    REQUISITES_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO,
];
export const OPERTATOR_FIELDS = [
    REQUISITES_FIELD_NAME.OPERATOR,
    REQUISITES_FIELD_NAME.ADDRESS,
    REQUISITES_FIELD_NAME.MAIL_ADDRESS,
    REQUISITES_FIELD_NAME.PHONE,
    REQUISITES_FIELD_NAME.FAX,
    REQUISITES_FIELD_NAME.WEB_SITE,
    REQUISITES_FIELD_NAME.EMAIL,
];
export const BANK_FIELDS = [
    REQUISITES_FIELD_NAME.BANK_NAME,
    REQUISITES_FIELD_NAME.OGRN,
    REQUISITES_FIELD_NAME.INN,
    REQUISITES_FIELD_NAME.KPP,
    REQUISITES_FIELD_NAME.RS,
    REQUISITES_FIELD_NAME.KS,
    REQUISITES_FIELD_NAME.BIK,
];

export const BLOCK_DATA_NAME = {
    OPERATOR: "operatorInfo",
    REPRESENTATIVE: "representativeInfo",
    BANK: "bankInfo",
};

export const POSITIONS = [
    {
        text: "Генеральный директор",
        value: "Генеральный директор",
    },
];