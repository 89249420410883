import React from "react";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {useSpecialitiesEdit} from "./hooks/useEdit";

export const SettingsSpecialitiesEdit = (props) => {
    const {
        editData,
        fetchList,
        onClose,
    } = props;

    const {
        errors,
        handleSubmit,
        progressAction,
        onChange,
        values,
    } = useSpecialitiesEdit({
        editData,
        fetchList,
        onClose,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        editData ?
                            "Редактирование вида деятельности" :
                            "Добавление вида деятельности"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submitBtnContent={editData ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    name="value"
                    value={values.value}
                    error={errors.value}
                    onChange={onChange}
                    label="Вид деятельности"
                    maxLength={100}
                />
            </NmForm>
        </NmModal>
    );
};
