export const contractorResourcesStatusFilterOptions = [
    {
        key: 1,
        text: "Высокий",
        value: 1,
    },
    {
        key: 2,
        text: "Средний",
        value: 2,
    },
];

export const contractorResourcesClientStatusFilterOptions = [
    {
        key: "AWAITING_CLIENT_CONFIRMATION",
        text: "Ожидание подтверждения заявки от заказчика",
        value: "AWAITING_CLIENT_CONFIRMATION",
    },
    {
        key: "CLIENT_PREAPPROVED",
        text: "Заявка предварительно одобрена",
        value: "CLIENT_PREAPPROVED",
    },
    {
        key: "AWAITING_CONTRACTOR_CONFIRMATION",
        text: "Ожидание подтверждения заявки от исполнителя",
        value: "AWAITING_CONTRACTOR_CONFIRMATION",
    },
    {
        key: "AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING",
        text: "Ожидание подписания договора от исполнителя",
        value: "AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING",
    },
];

export const CONTRACTOR_RETAIL_TYPE = {
    MY_CONTRACTORS: "MY_CONTRACTORS",
    AVAILABLE_CONTRACTORS: "AVAILABLE_CONTRACTORS",
    ATTENTION_CONTRACTORS: "ATTENTION_CONTRACTORS",
};

export const RESOURCES_TYPE_SUGGESTION= {
    MY_CONTRACTORS: "Отображаются исполнители, с датой заключения рамочного договора ранее двух месяцев с текущей даты, получившие более 4 выплат за последние 120 дней (красная зона) или получившие более 2 выплат за последние 90 дней (желтая зона) от вашей компании, при этом интервал между последней оплатой и новым заказом не превышает 45 дней и дата последней заявки меньше или равна 60 дням",
    AVAILABLE_CONTRACTORS: "Исполнители, которые не оказывали услуги для вашей компании, но выполняли схожие заказы на площадке Наймикс",
    ATTENTION_CONTRACTORS: "Отображаются исполнители, получившие более 4 выплат от вашей компании за последние 180 дней",
};
