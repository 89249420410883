import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateWorkType, {defaultWorkTypeForm} from "./hooks/useUpdateWorkType";

import {
    specialitiesAllListV2Selector,
    specialitiesAllV2OptionsSelector,
    specialitiesClientOptionsV2Selector,
} from "../../../../../../ducks/speciality";

export default function ContractorWorkTypeEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultWorkTypeForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const specialitiesOptions = useSelector(specialitiesAllV2OptionsSelector);


    const {
        onChangeForm,
        submit,
        form,
        setForm,
    } = useUpdateWorkType({contractorId, onClose, getProfile});

    useEffect(() => {
        setForm({...editData});
    }, []);

    const {
        specialityIds,
        hasAdditionalSpecialities,
    } = form;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Дополнительные виды деятельности
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmCheckboxV2
                    checked={hasAdditionalSpecialities}
                    name="hasAdditionalSpecialities"
                    label="Виды деятельности отсутствуют"
                    onChange={onChangeForm}
                />
                <div className="nm-line" />
                {!hasAdditionalSpecialities && <NmDropdownV2
                    options={specialitiesOptions}
                    value={specialityIds}
                    name="specialityIds"
                    fullWidth={true}
                    onChange={onChangeForm}
                    search
                    multiple
                    placeholder="Выберите из списка"
                    label="Дополнительные виды деятельности"
                />}
            </NmForm>
        </NmModal>
    );
}