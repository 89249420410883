import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {calculatePayPopupWithRatingsAmounts} from "../../../../../../../components/ActCreation/utils/calculate";
import {getEndDate, getStartDate} from "../../../../../../../utils/dateFormat";
import {getNumberFromFormattedAmount} from "../../../../../../../utils/stringFormat";
import {convertStringToDate, handleFormString} from "../../../../../../../utils/stringHelper";

import {addCrowdPayment} from "../../../../../../../ducks/bff/crowd/payment/actionCreators";

const useCrowdTaskActCreationForm = (props) => {
    const {
        clientId,
        taskId,
        contractorId,
        onClose,
        taskCard,
        fetchInfo,
        taskWorkFinishedDateTime,
    } = props;

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        const {
            actTemplateId,
        } = taskCard;

        return {
            description: "",
            descriptionFns: "",
            actOfAcceptanceOfTaskTemplateId: actTemplateId,
            workStartDate: convertStringToDate(taskCard.workStartDate) || null,
            workEndDate: convertStringToDate(taskCard.workEndDate) || null,
            actDate: convertStringToDate(taskWorkFinishedDateTime) || null,
            workQualityScore: null,
            estimatedTimeScore: null,
            needContractorApprove: true,
        };
    }, [taskCard]);

    const onSubmit = () => {
        const {
            actOfAcceptanceOfTaskTemplateId,
            description,
            descriptionFns,
            estimatedTimeScore,
            reviewText,
            workEndDate,
            workQualityScore,
            workStartDate,
            needContractorApprove,
            actDate,
        } = values;

        const {
            tax,
        } = calculatePayPopupWithRatingsAmounts({
            isTaskAct: true,
            taskAmount: taskCard.paymentAmount,
        });

        dispatch(addCrowdPayment({
            clientId,
            taskId,
            contractorId,
            actTemplateId: actOfAcceptanceOfTaskTemplateId,
            amount: taskCard.paymentAmount,
            contractorTax: getNumberFromFormattedAmount(tax),
            description: handleFormString(taskCard.servicesDescription),
            workActDescription: handleFormString(description),
            workFNSDescription: handleFormString(descriptionFns),
            qualityRating: workQualityScore,
            timingRating: estimatedTimeScore,
            needContractorApprove,
            workStartDate: getStartDate(workStartDate),
            workEndDate: getEndDate(workEndDate),
            actFormingDate: getStartDate(actDate),
            contractorReview: handleFormString(reviewText),
            onSuccess: () => {
                onClose();
                fetchInfo();
            },
        }));
    };

    const handleChange = (e, {name, value, checked}) => {
        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const {
        values,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: false,
    });

    return {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
    };
};

export default useCrowdTaskActCreationForm;