import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    isUserFromNm,
} from "../../../../constants/roles";
import {TAB} from "../constants";

import {
    contractorDocumentCountsSelector,
    getRegistryFramecontractShortItemsSelector,
} from "../../../../ducks/documents";

export const useTabs = () => {
    const [tab, setTab] = useState(TAB.AGENCY_CONTRACT);

    const registryShortList = useSelector(getRegistryFramecontractShortItemsSelector);
    const {
        AGENCY_CONTRACT = 0,
        CONSENT_TO_PROCESSING_PERSONAL_DATA = 0,
        CONTRACTOR_IDENTIFICATION_SHEET = 0,
        FRAME_CONTRACT = 0,
        ORDER_APPLICATION = 0,
        TASK_APPLICATION = 0,
        ACT_OF_ACCEPTANCE_OF_WORK = 0,
        ACT_OF_ACCEPTANCE_OF_TASK = 0,
        CASH_RECEIPT = 0,
        CIVIL_FRAME_CONTRACT = 0,
        INDIVIDUAL_FRAME_CONTRACT = 0,
        NAIMIX_REWARD_RECEIPT = 0,
        RECEIPT_OF_PAYMENT = 0,
        TRANSACTION_RECEIPT = 0,
        CONTRACTOR_PHONE_TRANSACTION_RECEIPT = 0,
        INSURANCE_POLICY = 0,
    } = useSelector(contractorDocumentCountsSelector);

    const role = ls(USER_ROLE);

    const tabs = [
        {
            visible: isUserFromNm(role) && Boolean(AGENCY_CONTRACT),
            id: TAB.AGENCY_CONTRACT,
            active: tab === TAB.AGENCY_CONTRACT,
            name: "Агентский договор с Наймикс",
            onClick: () => setTab(TAB.AGENCY_CONTRACT),
        },
        {
            visible: isUserFromNm(role) && Boolean(CONSENT_TO_PROCESSING_PERSONAL_DATA),
            id: TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA,
            active: tab === TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA,
            name: "Согласие на обработку ПД",
            onClick: () => setTab(TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA),
        },
        {
            visible: Boolean(FRAME_CONTRACT + CIVIL_FRAME_CONTRACT + INDIVIDUAL_FRAME_CONTRACT),
            id: TAB.FRAME_CONTRACT,
            active: tab === TAB.FRAME_CONTRACT,
            name: "Договоры с заказчиками",
            onClick: () => setTab(TAB.FRAME_CONTRACT),
        },
        {
            visible: Boolean(ORDER_APPLICATION + TASK_APPLICATION),
            id: TAB.ORDER_APPLICATION,
            active: tab === TAB.ORDER_APPLICATION,
            name: "Заявки на выполнение работ (оказание услуг)",
            onClick: () => setTab(TAB.ORDER_APPLICATION),
        },
        {
            visible: Boolean(ACT_OF_ACCEPTANCE_OF_WORK + ACT_OF_ACCEPTANCE_OF_TASK),
            id: TAB.ACT_OF_ACCEPTANCE_OF_WORK,
            active: tab === TAB.ACT_OF_ACCEPTANCE_OF_WORK,
            name: "Акты выполненных работ",
            onClick: () => setTab(TAB.ACT_OF_ACCEPTANCE_OF_WORK),
        },
        {
            visible: isUserFromNm(role) && Boolean(INSURANCE_POLICY),
            id: TAB.INSURANCE_POLICY,
            active: tab === TAB.INSURANCE_POLICY,
            name: "Страховые полисы",
            onClick: () => setTab(TAB.INSURANCE_POLICY),
        },
        {
            visible: isUserFromNm(role) && Boolean(CASH_RECEIPT + NAIMIX_REWARD_RECEIPT + RECEIPT_OF_PAYMENT + TRANSACTION_RECEIPT + CONTRACTOR_PHONE_TRANSACTION_RECEIPT),
            id: TAB.RECEIPT_RNKO,
            active: tab === TAB.RECEIPT_RNKO,
            name: "Квитанции РНКО",
            onClick: () => setTab(TAB.RECEIPT_RNKO),
        },
        {
            visible: isUserFromNm(role) && registryShortList.length !== 0,
            id: TAB.DOCUMENTS_REGISTRY,
            active: tab === TAB.DOCUMENTS_REGISTRY,
            name: "Реестры на подписание рамочных договоров с исполнителем",
            onClick: () => setTab(TAB.DOCUMENTS_REGISTRY),
        },
        {
            visible: isUserFromNm(role) && Boolean(CONTRACTOR_IDENTIFICATION_SHEET),
            id: TAB.IDENTIFICATION_SHEET,
            active: tab === TAB.IDENTIFICATION_SHEET,
            name: "Лист идентификации",
            onClick: () => setTab(TAB.IDENTIFICATION_SHEET),
        },
        {
            visible: isUserFromNm(role) || [CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role),
            id: TAB.PENDING_CONTRACTS,
            active: tab === TAB.PENDING_CONTRACTS,
            name: "Договоры на подписании",
            onClick: () => setTab(TAB.PENDING_CONTRACTS),
        },
    ].filter(item => item.visible);

    useEffect(() => {
        if (tabs.length !== 0) {
            setTab(tabs[0].id);
        }
    }, [tabs.length]);

    return {
        tab,
        setTab,
        tabs,
    };
};