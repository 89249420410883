import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getContractorNotificationEventDict,
    getContractorNotificationEventPage,
    updateFieldContractorNotificationLog,
} from "../../../../../ducks/contractorNotificationLog";

export function useFetchListNotificationLog({filter = {}, pageSize, pageNum, contractorId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContractorNotificationEventDict());

        return () => {
            dispatch(updateFieldContractorNotificationLog({
                list: [],
                eventDict: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getContractorNotificationEventPage({
            pageNum,
            pageSize,
            contractorId,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}