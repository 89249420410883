import React from "react";

import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text from "../ActualComponents/Text";
import NmCheck from "../NmCheck";

import bem from "./../../utils/bem";

import {COLOR} from "../../constants/color";

import PropTypes from "prop-types";

import "./style.sass";

const NmStatus = (props) => {
    const {
        isSuccess,
        name,
        errorText,
        successText,
        className = "",
        iconClass = "",
    } = props;

    const [block, element] = bem("nm-status", className);

    const popupText = isSuccess ? successText : errorText;

    return (
        <div className={block()}>
            <NmAdvancedTooltip
                hover
                position="bottom-left"
                trigger={
                    <NmCheck
                        iconClass={`${element("icon")} ${iconClass}`}
                        isCheck={isSuccess}
                    />
                }
                children={popupText}
            />
            <Text
                level="3"
                color={COLOR.SECONDARY_90}
            >
                {name}
            </Text>
        </div>
    );
};

NmStatus.propTypes = {
    isSuccess: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    errorText: PropTypes.node,
    successText: PropTypes.node,
    className: PropTypes.string,
};

export default NmStatus;