import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmDivider from "../../../../../components/ActualComponents/NmDivider";
import Text from "../../../../../components/ActualComponents/Text";
import TemplatesDropdown from "../../../../../components/TemplatesDropdown";
import {OrderEditContext} from "../../context-provider";
import OrderFormPreConditionsObject from "./components/object";
import OrderEditFormPreConditionsOrderType from "./components/order-type";
import OrderEditPreConditionsPaymentType from "./components/payment-type";
import OrderEditPreConditionsProject from "./components/project";
import OrderEditFormPreConditionsResponsible from "./components/responsible";
import OrderEditPreConditionsResponsibleShowInfo from "./components/responsible-show-info";
import OrderFormPreConditionsTemplate from "./components/template";
import OrderEditPreConditionsTemplateType from "./components/template-type";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {toastError} from "../../../../../utils/toastHelper";

import {COLOR} from "../../../../../constants/color";
import {ORDER_CREATION_MODE} from "../../../../../constants/order";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../templates/constants";

import {getClientPropertiesCardSelector} from "../../../../../ducks/clientProperties";
import {downloadDocument} from "../../../../../ducks/documents";
import {documentCustomTemplateAggregationShortTemplatesSelector} from "../../../../../ducks/documentsCustomTemplate";
import {orderCreationDataSelector} from "../../../../../ducks/order";

import "./style.sass";

const OrderEditFormPreConditions = (props) => {
    const {
        isDisabled,
        responsibleOptions,
        isDuplicateOrder,
        creatingMode,
        handleChange,
        formError,
        formData,
        managerName,
        isPromptOn,
        onSearchObject,
        handleFocus,
        preloadedData,
        valueObjectFilter,
    } = props;
    const {
        clientId,
    } = useContext(OrderEditContext);
    const dispatch = useDispatch();
    const order = useSelector(orderCreationDataSelector);
    const allOptionsInfo = useSelector(documentCustomTemplateAggregationShortTemplatesSelector);
    const {customDocumentTemplatesAvailable} = useSelector(getClientPropertiesCardSelector);

    const role = ls(USER_ROLE);

    const isTemplate = creatingMode === ORDER_CREATION_MODE.TEMPLATE;

    const onClickDownloadFrameContract = () => {
        if (!formData.frameContractTemplateId) {
            toastError("Нет выбранного шаблона для скачивания");

            return;
        }

        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `/api/documents/custom/template/pdf/${formData.frameContractTemplateId}`,
        }));
    };

    const getFrameContract = () => {
        if (!isTemplate) {
            return null;
        }

        return (
            <div className="row">
                <div className="col-16 col-xl-8 d-flex align-items-center mb-3 mb-md-4">
                    <Text
                        level="2"
                        className="order-edit-form-pre-conditions__template-name me-2"
                        color={COLOR.SECONDARY_70}
                    >
                        Шаблон договора:
                    </Text>
                    <Text
                        level="2"
                        style={{cursor: "pointer"}}
                        noWrap={true}
                        color={COLOR.PASSIVE_100}
                        onClick={onClickDownloadFrameContract}
                    >
                        {formData.frameContractTemplateName}
                    </Text>
                </div>
            </div>
        );
    };

    return (
        <div className="order-edit-form-pre-conditions">
            <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                {
                    isTemplate &&
                    <div className="col-16 col-xl-8">
                        <OrderEditPreConditionsProject
                            disabled={isDisabled}
                            formData={formData}
                            error={formError.templateProjectIds}
                            onFocus={handleFocus}
                        />
                    </div>
                }
                <div className={isTemplate ? "col-16 col-xl-8 mt-3 mt-md-4 mt-xl-0" : "col-16"}>
                    <OrderFormPreConditionsObject
                        value={formData.objectId}
                        onChange={handleChange}
                        isDisabled={isDisabled}
                        isPromptOn={isPromptOn}
                        formData={formData}
                        valueObjectFilter={valueObjectFilter}
                        onSearchObject={onSearchObject}
                        handleFocus={handleFocus}
                        preloadedData={preloadedData}
                        formError={formError}
                        creatingMode={creatingMode}
                    />
                </div>
            </div>
            {
                !isTemplate &&
                <>
                    <OrderEditPreConditionsTemplateType
                        className="mb-3 mb-md-4"
                        isDisabled={isDisabled}
                        formData={formData}
                        onChange={handleChange}
                    />
                    <NmDivider
                        className="mb-3 mb-md-4"
                        color={COLOR.SECONDARY_10}
                    />
                </>
            }
            {
                formData.isTemplateType &&
                <OrderFormPreConditionsTemplate
                    className="mb-3 mb-md-4"
                    isDisabled={isDisabled}
                    formData={formData}
                    formError={formError}
                    onChange={handleChange}
                    isTemplate={isTemplate}
                />
            }
            {
                !isTemplate &&
                <div className="row gx-0 gx-md-4 align-items-start mb-3 mb-md-4">
                    <div className="col-16 col-md-8">
                        <OrderEditFormPreConditionsResponsible
                            role={role}
                            isDisabled={isDisabled}
                            creatingMode={creatingMode}
                            responsibleOptions={responsibleOptions}
                            formData={formData}
                            onChange={handleChange}
                            error={formError.orderManagerId}
                            onFocus={handleFocus}
                            managerName={managerName}
                        />
                    </div>
                    <div className="order-edit-form-pre-conditions__responsible-phone col-16 col-md-8 mt-3 mt-md-5">
                        <NmCheckboxV2
                            label="Отображать номер телефона ответственного по заказу"
                            disabled={
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                order.orderManagerPhoneVisible
                            }
                            checked={formData.orderManagerPhoneVisible}
                            onChange={handleChange}
                            name="orderManagerPhoneVisible"
                        />
                    </div>
                </div>
            }
            {
                isTemplate &&
                <OrderEditPreConditionsResponsibleShowInfo
                    className="mb-3 mb-md-4"
                    onChange={handleChange}
                    formData={formData}
                />
            }
            <OrderEditPreConditionsPaymentType
                className="mb-3 mb-md-4"
                creatingMode={creatingMode}
                isDisabled={isDisabled}
                onChange={handleChange}
                formData={formData}
            />
            {getFrameContract()}
            <div className="row gy-0 gx-xl-4">
                <div className="col-16 col-xl-8 mb-3 mb-md-4">
                    <OrderEditFormPreConditionsOrderType
                        creatingMode={creatingMode}
                        isDuplicateOrder={isDuplicateOrder}
                        onChange={handleChange}
                        formError={formError}
                        formData={formData}
                        isPromptOn={isPromptOn}
                    />
                </div>
            </div>
            <div className="row gx-0 gx-xl-4 mb-3 mb-md-4">
                <TemplatesDropdown
                    disabled={
                        !customDocumentTemplatesAvailable ||
                        isDisabled ||
                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                    }
                    isFetch={false}
                    className="col-16 col-xl-8 mb-3 mb-xl-0"
                    clientIdFilter={clientId}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
                    orderContractPaymentTypeFilter={formData.orderContractPaymentType}
                    buttonText="Скачать заявку"
                    placeholder="Выберите шаблон заявки"
                    label="Шаблон заявки"
                    name="orderApplicationTemplateId"
                    onChange={handleChange}
                    value={formData.orderApplicationTemplateId}
                    error={formError.orderApplicationTemplateId}
                    options={allOptionsInfo.orderApplicationTemplateId}
                    direction="top"
                    strongDirection
                    size="xl"
                />
                <TemplatesDropdown
                    disabled={
                        !customDocumentTemplatesAvailable ||
                        isDisabled ||
                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                    }
                    isFetch={false}
                    className="col-16 col-xl-8"
                    clientIdFilter={clientId}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                    orderContractPaymentTypeFilter={formData.orderContractPaymentType}
                    buttonText="Скачать акт"
                    placeholder="Выберите шаблон акта"
                    label="Шаблон акта"
                    name="actOfAcceptanceOfWorkTemplateId"
                    onChange={handleChange}
                    value={formData.actOfAcceptanceOfWorkTemplateId}
                    error={formError.actOfAcceptanceOfWorkTemplateId}
                    options={allOptionsInfo.actOfAcceptanceOfWorkTemplateId}
                    direction="top"
                    strongDirection
                    size="xl"
                />
            </div>
            {
                creatingMode === ORDER_CREATION_MODE.TEMPLATE &&
                <NmCheckboxV2
                    label="Выгрузка данных по заказам в отчет клиента"
                    onChange={handleChange}
                    name="uploadClientReport"
                    className="mb-3 mb-md-4"
                    checked={formData.uploadClientReport}
                />
            }
            {
                creatingMode === ORDER_CREATION_MODE.TEMPLATE &&
                <NmCheckboxV2
                    label="Актирование с параметрами"
                    onChange={handleChange}
                    name="parameterizedOrderWorkReportTemplate"
                    checked={formData.parameterizedOrderWorkReportTemplate}
                />
            }
        </div>
    );
};

export default OrderEditFormPreConditions;