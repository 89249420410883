import {useState} from "react";

import {formatDateWithoutTime} from "../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";

export function useClientGroupFilter() {
    const [filterDto, setFilter] = useState({});

    function mapFilterDto(filter) {
        const {
            clientInnFilter,
            clientNameFilter,
            contractorFioFilter,
            contractorInnFilter,
            contractorPhoneFilter,
            fromCreateDateFilter,
            groupNameFilter,
            toCreateDateFilter,
            clientUserPhoneFilter,
            clientUserEmailFilter,
            clientUserFioFilter,
        } = filter;

        const format = "yyyy-MM-dd";

        return {
            clientInnFilter: handleFormString(clientInnFilter),
            clientNameFilter: handleFormString(clientNameFilter),
            contractorFioFilter: handleFormString(contractorFioFilter),
            contractorInnFilter: handleFormString(contractorInnFilter),
            clientUserFioFilter: handleFormString(clientUserFioFilter),
            clientUserEmailFilter: handleFormString(clientUserEmailFilter),
            clientUserPhoneFilter: isNullOrWhitespace(clientUserPhoneFilter) ? undefined : removePhoneMask(clientUserPhoneFilter),
            contractorPhoneFilter: isNullOrWhitespace(contractorPhoneFilter) ? undefined : removePhoneMask(contractorPhoneFilter),
            groupNameFilter: handleFormString(groupNameFilter),
            fromCreateDateFilter: isNullOrWhitespace(fromCreateDateFilter) ? undefined : formatDateWithoutTime(fromCreateDateFilter, format),
            toCreateDateFilter: isNullOrWhitespace(toCreateDateFilter) ? undefined : formatDateWithoutTime(toCreateDateFilter, format),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
    };
}