import {
    BFF_SYSTEM_TRANSACTIONS_GET_PAGE_ERROR,
    BFF_SYSTEM_TRANSACTIONS_GET_PAGE_REQUEST,
    BFF_SYSTEM_TRANSACTIONS_GET_PAGE_SUCCESS,
    BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_ERROR,
    BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_REQUEST,
    BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_SUCCESS,
    UPDATE_SYSTEM_TRANSACTIONS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_SYSTEM_TRANSACTIONS_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_SYSTEM_TRANSACTIONS_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_SYSTEM_TRANSACTIONS_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_REQUEST: {
        return {
            ...state,
            logPageData: payload,
            logProgress: true,
        };
    }
    case BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_SUCCESS: {
        return {
            ...state,
            logList: payload.results,
            logTotalCount: payload.totalCount,
            logProgress: false,
        };
    }
    case BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_ERROR: {
        return {
            ...state,
            logProgress: false,
        };
    }
    case UPDATE_SYSTEM_TRANSACTIONS_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};