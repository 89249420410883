import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {downloadBlob} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/orders/group";
//*  TYPES  *//

const GET_ORDER_GROUP_PAGE_REQUEST = "GET_ORDER_GROUP_PAGE_REQUEST";
const GET_ORDER_GROUP_PAGE_SUCCESS = "GET_ORDER_GROUP_PAGE_SUCCESS";
const GET_ORDER_GROUP_PAGE_ERROR = "GET_ORDER_GROUP_PAGE_ERROR";

const GET_ORDER_GROUP_BY_ID_REQUEST = "GET_ORDER_GROUP_BY_ID_REQUEST";
const GET_ORDER_GROUP_BY_ID_SUCCESS = "GET_ORDER_GROUP_BY_ID_SUCCESS";
const GET_ORDER_GROUP_BY_ID_ERROR = "GET_ORDER_GROUP_BY_ID_ERROR";

const ADD_ORDER_GROUP_REQUEST = "ADD_ORDER_GROUP_REQUEST";
const ADD_ORDER_GROUP_SUCCESS = "ADD_ORDER_GROUP_SUCCESS";
const ADD_ORDER_GROUP_ERROR = "ADD_ORDER_GROUP_ERROR";

const DELETE_ORDER_GROUP_REQUEST = "DELETE_ORDER_GROUP_REQUEST";
const DELETE_ORDER_GROUP_SUCCESS = "DELETE_ORDER_GROUP_SUCCESS";
const DELETE_ORDER_GROUP_ERROR = "DELETE_ORDER_GROUP_ERROR";

const UPDATE_ORDER_GROUP_REQUEST = "UPDATE_ORDER_GROUP_REQUEST";
const UPDATE_ORDER_GROUP_SUCCESS = "UPDATE_ORDER_GROUP_SUCCESS";
const UPDATE_ORDER_GROUP_ERROR = "UPDATE_ORDER_GROUP_ERROR";

const ARCHIVED_ORDER_GROUP_REQUEST = "ARCHIVED_ORDER_GROUP_REQUEST";
const ARCHIVED_ORDER_GROUP_SUCCESS = "ARCHIVED_ORDER_GROUP_SUCCESS";
const ARCHIVED_ORDER_GROUP_ERROR = "ARCHIVED_ORDER_GROUP_ERROR";

const UPLOAD_ORDER_LIST_REQUEST = "UPLOAD_ORDER_LIST_REQUEST";
const UPLOAD_ORDER_LIST_SUCCESS = "UPLOAD_ORDER_LIST_SUCCESS";
const UPLOAD_ORDER_LIST_ERROR = "UPLOAD_ORDER_LIST_ERROR";

const DOWNLOAD_ORDER_GROUPS_REQUEST = "DOWNLOAD_ORDER_GROUPS_REQUEST";
const DOWNLOAD_ORDER_GROUPS_SUCCESS = "DOWNLOAD_ORDER_GROUPS_SUCCESS";
const DOWNLOAD_ORDER_GROUPS_ERROR = "DOWNLOAD_ORDER_GROUPS_ERROR";

const ADD_ORDERS_TO_GROUP_REQUEST = "ADD_ORDERS_TO_GROUP_REQUEST";
const ADD_ORDERS_TO_GROUP_SUCCESS = "ADD_ORDERS_TO_GROUP_SUCCESS";
const ADD_ORDERS_TO_GROUP_ERROR = "ADD_ORDERS_TO_GROUP_ERROR";

const DELETE_ORDERS_FROM_GROUP_REQUEST = "DELETE_ORDERS_FROM_GROUP_REQUEST";
const DELETE_ORDERS_FROM_GROUP_SUCCESS = "DELETE_ORDERS_FROM_GROUP_SUCCESS";
const DELETE_ORDERS_FROM_GROUP_ERROR = "DELETE_ORDERS_FROM_GROUP_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    card: {},
    pageData: {},
    progress: false,
    actionProgress: false,
    totalCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_ORDER_GROUP_PAGE_REQUEST:
        const {pageSize, pageNum} = payload;
        return {
            ...state,
            progress: true,
            pageData: {
                pageSize,
                pageNum,
            },
        };
    case GET_ORDER_GROUP_BY_ID_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case ADD_ORDER_GROUP_REQUEST:
    case DELETE_ORDER_GROUP_REQUEST:
    case UPDATE_ORDER_GROUP_REQUEST:
    case ARCHIVED_ORDER_GROUP_REQUEST:
    case DOWNLOAD_ORDER_GROUPS_REQUEST:
    case UPLOAD_ORDER_LIST_REQUEST:
    case ADD_ORDERS_TO_GROUP_REQUEST:
    case DELETE_ORDERS_FROM_GROUP_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case GET_ORDER_GROUP_PAGE_SUCCESS:
        const {
            groups: list,
            totalCount,
        } = payload;

        return {
            ...state,
            progress: false,
            list,
            totalCount,
        };
    case GET_ORDER_GROUP_BY_ID_SUCCESS:
        return {
            ...state,
            progress: false,
            card: payload,
        };
    case ADD_ORDER_GROUP_SUCCESS:
    case DELETE_ORDER_GROUP_SUCCESS:
    case UPDATE_ORDER_GROUP_SUCCESS:
    case ARCHIVED_ORDER_GROUP_SUCCESS:
    case DOWNLOAD_ORDER_GROUPS_SUCCESS:
    case UPLOAD_ORDER_LIST_SUCCESS:
    case ADD_ORDERS_TO_GROUP_SUCCESS:
    case DELETE_ORDERS_FROM_GROUP_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case GET_ORDER_GROUP_PAGE_ERROR:
    case GET_ORDER_GROUP_BY_ID_ERROR:
        return {
            ...state,
            progress: false,
        };
    case ADD_ORDER_GROUP_ERROR:
    case DELETE_ORDER_GROUP_ERROR:
    case UPDATE_ORDER_GROUP_ERROR:
    case ARCHIVED_ORDER_GROUP_ERROR:
    case DOWNLOAD_ORDER_GROUPS_ERROR:
    case UPLOAD_ORDER_LIST_ERROR:
    case ADD_ORDERS_TO_GROUP_ERROR:
    case DELETE_ORDERS_FROM_GROUP_ERROR:
        return {
            ...state,
            actionProgress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getOrderGroupsPage(payload) {
    return {
        type: GET_ORDER_GROUP_PAGE_REQUEST,
        payload,
    };
}

export function getOrderGroupById(payload) {
    return {
        type: GET_ORDER_GROUP_BY_ID_REQUEST,
        payload,
    };
}

export function addOrderGroup(payload) {
    return {
        type: ADD_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function deleteOrderGroup(payload) {
    return {
        type: DELETE_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function updateOrderGroup(payload) {
    return {
        type: UPDATE_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function archivedOrderGroup(payload) {
    return {
        type: ARCHIVED_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function uploadOrderList(payload) {
    return {
        type: UPLOAD_ORDER_LIST_REQUEST,
        payload,
    };
}

export function downloadOrderGroups(payload) {
    return {
        type: DOWNLOAD_ORDER_GROUPS_REQUEST,
        payload,
    };
}

export function addOrdersToGroup(payload) {
    return {
        type: ADD_ORDERS_TO_GROUP_REQUEST,
        payload,
    };
}

export function deleteOrdersFromGroup(payload) {
    return {
        type: DELETE_ORDERS_FROM_GROUP_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//
export const orderGroupSelector = state => state.orderGroup;
export const getOrderGroupsTotalPagesSelector = createSelector(orderGroupSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getOrderGroupsTotalCountSelector = createSelector(orderGroupSelector, ({totalCount}) => totalCount);
export const getOrderGroupsListSelector = createSelector(orderGroupSelector, ({list}) => list);
export const getOrderGroupCardSelector = createSelector(orderGroupSelector, ({card}) => card);
export const getOrderGroupsProgressSelector = createSelector(orderGroupSelector, ({progress}) => progress);
export const getOrderGroupsActionProgressSelector = createSelector(orderGroupSelector, ({actionProgress}) => actionProgress);

//*  SAGA  *//

//POST /api/orders/group/getPage
export const getOrderGroupsPageSaga = function* ({payload} ) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_GROUP_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ORDER_GROUP_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_ORDER_GROUP_PAGE_ERROR, payload: error});
    }
};

//POST /api/orders/group/getById
export const getOrderGroupByIdSaga = function* ({payload} ) {
    try {
        const result = yield request.post(`${controller}/getById`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_GROUP_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ORDER_GROUP_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_ORDER_GROUP_BY_ID_ERROR, payload: error});
    }
};

//POST /api/orders/group/createOrderGroup
export const addOrderGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/createOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ORDER_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_ORDER_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_ORDER_GROUP_ERROR, payload: error});
    }
};

//POST /api/orders/group/deleteOrderGroup
export const deleteOrderGroupSaga = function* ({payload} ) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ORDER_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_ORDER_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_ORDER_GROUP_ERROR, payload: error});
    }
};

//POST /api/orders/group/updateOrderGroup
export const updateOrderGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/updateOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_ORDER_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: UPDATE_ORDER_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_ORDER_GROUP_ERROR, payload: error});
    }
};

//POST /api/orders/group/archiveOrRestoreOrderGroup
export const archivedOrderGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/archiveOrRestoreOrderGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ARCHIVED_ORDER_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ARCHIVED_ORDER_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ARCHIVED_ORDER_GROUP_ERROR, payload: error});
    }
};

//POST /api/orders/group/uploadOrderList
const uploadOrderListSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            orderGroupId,
            type,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`/job/import/orders/import/${clientId}/${orderGroupId}/${type}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPLOAD_ORDER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Задача по импорту заказов передана в обработку");

        yield put({
            type: UPLOAD_ORDER_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPLOAD_ORDER_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/group/downloadOrderGroups
export const downloadOrderGroupsSaga = function* ({payload}) {
    try {
        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };

        const result = yield request.getFile(`${window.location.origin}/api${controller}/downloadOrderGroups`, requestHeaders);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                yield put({type: DOWNLOAD_ORDER_GROUPS_ERROR, payload: result});
                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();
        yield downloadBlob(blob, headers);

        yield put({type: DOWNLOAD_ORDER_GROUPS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: DOWNLOAD_ORDER_GROUPS_ERROR, payload: error.message});
    }
};

//POST /api/orders/group/addOrdersToGroup
export const addOrdersToGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/addOrdersToGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ORDERS_TO_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_ORDERS_TO_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_ORDERS_TO_GROUP_ERROR, payload: error});
    }
};

//POST /api/orders/group/deleteOrdersFromGroup
export const deleteOrdersFromGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteOrdersFromGroup`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ORDERS_FROM_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_ORDERS_FROM_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_ORDERS_FROM_GROUP_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ORDER_GROUP_PAGE_REQUEST, getOrderGroupsPageSaga),
        takeEvery(GET_ORDER_GROUP_BY_ID_REQUEST, getOrderGroupByIdSaga),
        takeEvery(ADD_ORDER_GROUP_REQUEST, addOrderGroupSaga),
        takeEvery(DELETE_ORDER_GROUP_REQUEST, deleteOrderGroupSaga),
        takeEvery(UPDATE_ORDER_GROUP_REQUEST, updateOrderGroupSaga),
        takeEvery(ARCHIVED_ORDER_GROUP_REQUEST, archivedOrderGroupSaga),
        takeEvery(UPLOAD_ORDER_LIST_REQUEST, uploadOrderListSaga),
        takeEvery(DOWNLOAD_ORDER_GROUPS_REQUEST, downloadOrderGroupsSaga),
        takeEvery(ADD_ORDERS_TO_GROUP_REQUEST, addOrdersToGroupSaga),
        takeEvery(DELETE_ORDERS_FROM_GROUP_REQUEST, deleteOrdersFromGroupSaga),
    ]);
}
