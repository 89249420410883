import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import Portal from "../../../../components/Portal";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import StopWordEdit from "./stopWordEdit";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import bem from "../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    addStopWords, deleteStopWords,
    getStopWordListSelector,
    getStopWordsPage,
    getStopWordsTotalCountSelector,
    getStopWordsTotalPagesSelector,
    updateStopWords,
} from "../../../../ducks/stopWords";

import "./style.sass";

const SettingsDirectoriesStopWords = () => {
    const isAccessEdit = [ADMIN, NM_MANAGER].includes(ls(USER_ROLE));
    const [block ] = bem("stop-words-list");
    const {t} = useTranslation();

    const stopWordList = useSelector(getStopWordListSelector);
    const totalCount = useSelector(getStopWordsTotalCountSelector);
    const totalPages = useSelector(getStopWordsTotalPagesSelector);

    const [editableStopWord, setEditableStopWord] = useState({});
    const [removableStopWord, setRemovableStopWord] = useState("");
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {valueFilter: ""},
        pageSize,
        setPagination,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const {
            valueFilter,
        } = filterData;

        dispatch(getStopWordsPage({
            pageNum,
            pageSize,
            valueFilter: valueFilter ? valueFilter : undefined,
        }));
    }, [pageNum, pageSize, filterData]);

    useEffect(() => {
        isOpenEditModal && setIsOpenEditModal(false);
        setOpenConfirmWindow(false);
        setEditableStopWord({});
        setRemovableStopWord("");
    }, [stopWordList]);

    const handleAddStopWordModal = () => {
        setIsOpenEditModal(true);
        setEditableStopWord({});
    };

    const handleSubmitEditModal = (values) => {
        const _values = {
            ...values,
            stopWordForms:
                values.stopWordForms.length
                    ? values.stopWordForms.split("\n").filter(item => !isNullOrWhitespace(item)).map(item => ({value: item}))
                    : undefined
            ,
        };

        if (values.id) {
            return dispatch(updateStopWords(_values));
        }

        dispatch(addStopWords(_values));
    };

    const handleCloseEditModal = () => {
        setIsOpenEditModal(false);
        setEditableStopWord({});
    };

    const handleEditStopWord = (stopWord) => {
        setIsOpenEditModal(true);
        setEditableStopWord({
            ...stopWord,
            stopWordForms: stopWord.stopWordForms.length ? stopWord.stopWordForms.map(item => item.value).join("\n") : "",
        });
    };

    const handleDeleteStopWord = (id) => {
        setOpenConfirmWindow(true);
        setRemovableStopWord(id);
    };

    const handleConfirmDeleteStopWord = () => {
        dispatch(deleteStopWords({id: removableStopWord}));
    };

    const handleCloseConfirmModal = () => {
        setOpenConfirmWindow(false);
    };

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "valueFilter",
                        label: t("stopWord.filter.value-label"),
                        placeholder: t("stopWord.filter.value-placeholder"),
                    },
                ],
            },
        ];
    }, []);

    const getMediaControls = (item) => {
        if (!isAccessEdit) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => handleEditStopWord(item),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => handleDeleteStopWord(item.id),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const getRows = () => {
        return stopWordList.map(item => {
            const labels = !isEmpty(item.stopWordForms) ?
                [{
                    label: "Словоформы",
                    noWrap: false,
                    inline: true,
                    text: renderWordForms(item.stopWordForms),
                }] :
                [];

            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={item.value}
                        classNameMainContent="col-16 col-xxl-15"
                        isFixedActions
                        labels={labels}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderWordForms = (forms) => {
        const valuesForms = forms.map(item => item.value);

        return valuesForms.join("; ");
    };

    return (
        <NmPage
            headerClassName="justify-content-end"
            onPaginationChange={onPaginationChange}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            className={block()}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filtersBase}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            controls={
                isAccessEdit &&
                <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={handleAddStopWordModal}
                >
                    {t("stopWord.add-btn")}
                </NmButton>
            }
        >
            {
                isOpenEditModal &&
                <Portal node={document.body}>
                    <StopWordEdit
                        onSubmit={handleSubmitEditModal}
                        onClose={handleCloseEditModal}
                        stopWordData={editableStopWord}
                    />
                </Portal>
            }
            {
                openConfirmWindow &&
                <NmConfirmV2
                    content={t("stopWord.confirm-delete")}
                    onCancel={handleCloseConfirmModal}
                    onConfirm={handleConfirmDeleteStopWord}
                    confirmButton={t("button.yes")}
                    cancelButton={t("button.no")}
                />
            }
            {
                stopWordList.length ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
};

export default SettingsDirectoriesStopWords;