import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterObject from "../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../components/ActualComponents/FilterProject";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmForm from "../../../../components/ActualComponents/NmForm";

import {
    dadataValueCityFormatter,
    getOptionsUniqCity,
} from "../../../../utils/dadata";

const initForm = {
    projectIds: [],
    objectIds: [],
    addressFiasIds: [],
};

const OrderAllApplicationsFilter = props => {
    const {
        clientId,
        submitFilter,
        filter,
    } = props;
    
    const {t} = useTranslation();

    const [form, setForm] = useState({
        ...initForm,
        ...filter,
    });

    const {
        projectIds,
        objectIds,
    } = form;

    const handleChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const submit = () => {
        submitFilter(form);
    };

    const clearFilter = () => {
        const {clearFilter} = props;

        clearFilter();

        setForm({
            ...initForm,
        });
    };

    return (
        <NmForm
            className="order-all-applications-filter"
            addMargin
        >
            <FilterProject
                size="lg"
                clientId={clientId}
                name="projectIds"
                onChange={handleChange}
                projectIdsFilter={projectIds}
                onSubmit={submit}
                placeholder={t("orders-filter.show-all")}
            />
            <FilterObject
                size="lg"
                clientId={clientId}
                name="objectIds"
                onChange={handleChange}
                objectIdsFilter={objectIds}
                projectIdsFilter={projectIds}
                onSubmit={submit}
                placeholder={t("orders-filter.show-all")}
            />
            <NmDadata
                size="lg"
                label="Город"
                multiple
                name="addressFiasIds"
                onChange={handleChange}
                formatter={dadataValueCityFormatter}
                fromBound="city"
                toBound="settlement"
                isAllSelectionShown={false}
                initialAddressIds={form.addressFiasIds}
            />
            <FilterButtonsV2
                onSearch={submit}
                onClear={clearFilter}
            />
        </NmForm>
    );
};

export default OrderAllApplicationsFilter;