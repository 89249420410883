import {all} from "redux-saga/effects";

import {saga as fileStoreRecruitmentSaga} from "../ducks/fileStoreRecruitment";
import {saga as onboardingRecruitersSaga} from "../ducks/onboardingRecruiters";

import {saga as actRegistriesSaga} from "../ducks/actRegistries";
import {saga as actRegistryPaymentSaga} from "../ducks/actRegistryPayment";
import {saga as addressSaga} from "../ducks/address";
import {saga as advertisementSaga} from "../ducks/advertisement";
import {saga as authSaga} from "../ducks/auth";
import {saga as availableClientUsersAccessSaga} from "../ducks/availableClientUsersAccess";
import {saga as bankDepositSaga} from "../ducks/bankDeposit";
import bffSaga from "../ducks/bff/saga";
import {saga as categorySpecialitiesSaga} from "../ducks/categorySpecialities";
import {saga as chatAddMessageSaga} from "../ducks/chat";
import {saga as chatListSaga} from "../ducks/chatList";
import {saga as civilSaga} from "../ducks/civil";
import {saga as clientSaga} from "../ducks/client";
import {saga as clientBrandsSaga} from "../ducks/client/brands/sagas";
import {saga as clientDadataSaga} from "../ducks/clientDadata";
import {saga as clientFinanceUploadLogListReadSaga} from "../ducks/clientFinanceUpload";
import {saga as clientGroupsSaga} from "../ducks/clientGroups";
import {saga as clientMemberSaga} from "../ducks/clientMember";
import {saga as clientObjectSaga} from "../ducks/clientObject";
import {saga as clientPropertiesSaga} from "../ducks/clientProperties";
import {saga as clientRefSaga} from "../ducks/clientRefProgram";
import {saga as clientRemoteContractorsSaga} from "../ducks/clientRemoteContractors";
import {saga as clientsApplicationSaga} from "../ducks/clientsApplication";
import {saga as clientSpecialityOfferSaga} from "../ducks/clientSpecialityOffer";
import {saga as clientUserRestrictionsSaga} from "../ducks/clientUserRestrictions";
import {saga as passwordRecovery} from "../ducks/clientUsers";
import {saga as collectionBankDetailsSaga} from "../ducks/collectionBankDetails";
import {saga as companyDocumentsRegistrySaga} from "../ducks/companyDocumentsRegistry";
import {saga as contractHistorySaga} from "../ducks/contractHistory";
import {saga as contractorSaga} from "../ducks/contractor";
import {saga as contractorAdditionalDocumentsSaga} from "../ducks/contractorAdditionalDocuments";
import {saga as contractorBankCheckSaga} from "../ducks/contractorBankCheck";
import {saga as contractorBanksSaga} from "../ducks/contractorBanks";
import {saga as deviceLogListReadSaga} from "../ducks/contractorDevice";
import {saga as contractorEventLogSaga} from "../ducks/contractorEventLog";
import {saga as contractorFinanceBalanceSaga} from "../ducks/contractorFinanceBalance";
import {saga as contractorFinanceIncomeIndividualSaga} from "../ducks/contractorFinanceIncomeIndividual";
import {saga as contractorFinanceIncomeNdflSaga} from "../ducks/contractorFinanceIncomeNdfl";
import {saga as contractorFinanceIncomeNpdSaga} from "../ducks/contractorFinanceIncomeNpd";
import {saga as contractorNoteForClientSaga} from "../ducks/contractorNoteForClient/sagas";
import {saga as contractorNotificationLogSaga} from "../ducks/contractorNotificationLog";
import {saga as contractorOtherFilesSaga} from "../ducks/contractorOtherFiles";
import {saga as contractorPendingPersonalDataSaga} from "../ducks/contractorPendingPersonalData";
import {saga as contractorProfileSaga} from "../ducks/contractorProfile";
import {saga as contractorPushNotificationSaga} from "../ducks/contractorPushNotification";
import {saga as contractorReceiptControllerSaga} from "../ducks/contractorReceiptController";
import {saga as contractorScanSaga} from "../ducks/contractorScan";
import {saga as contractorScoresSaga} from "../ducks/contractorScores";
import {saga as contractorsDictsSaga} from "../ducks/contractorsDicts";
import {saga as contractorsPersonalDataAccessSaga} from "../ducks/contractorsPersonalDataAccess";
import {saga as contractorsSubscriptionSaga} from "../ducks/contractorsSubscription";
import {saga as contractorWorkLogSaga} from "../ducks/contractorWorkLog";
import {saga as workSchedulesSaga} from "../ducks/contractorWorkSchedules";
import {saga as crmRecordingSaga} from "../ducks/crm/recording";
import {saga as ticketSaga} from "../ducks/crm/ticket";
import {saga as dadataSaga} from "../ducks/dadata";
import {saga as depositSaga} from "../ducks/deposit";
import {saga as dictSaga} from "../ducks/dict";
import {saga as createEmptyDisputeSaga} from "../ducks/dispute";
import {saga as addFireBaseTokenSaga} from "../ducks/disputeFirebase";
import {saga as documentSaga} from "../ducks/documents";
import {saga as documentsCustomTemplateSaga} from "../ducks/documentsCustomTemplate";
import {saga as documentsEditManualSaga} from "../ducks/documentsEditManual";
import {saga as documentsEdoRegistriesSaga} from "../ducks/documentsEdoRegistries";
import {saga as documentsEdoRegistriesItemsSaga} from "../ducks/documentsEdoRegistriesItems";
import {saga as documentsExportSaga} from "../ducks/documentsExport";
import {saga as edmPositionsSaga} from "../ducks/edmPositions";
import {saga as edmStaffSaga} from "../ducks/edmStaff";
import {saga as statementsSaga} from "../ducks/edmStatements";
import {saga as edmSubdivisionsSaga} from "../ducks/edmSubDivision";
import {saga as edoDocumentsSaga} from "../ducks/edocuments";
import {saga as emailSaga} from "../ducks/email";
import {saga as emailNotificationSaga} from "../ducks/emailNotification";
import {saga as eventsContractorSaga} from "../ducks/events/contractor/sagas";
import {saga as exportDocumentSaga} from "../ducks/exportDocument";
import {saga as externalJobBoardsSaga} from "../ducks/externalJobBoards";
import {saga as fcRegistrySaga} from "../ducks/fcRegistries";
import {saga as federalMigrationSaga} from "../ducks/federalMigration";
import {saga as feedback} from "../ducks/feedback";
import {saga as fileStoreSaga} from "../ducks/fileStore";
import {saga as financeCivilSaga} from "../ducks/financeCivil";
import {saga as financeExportSaga} from "../ducks/financeExport";
import {saga as financeLogListReadSaga} from "../ducks/financeLog";
import {saga as financePaymentsSaga} from "../ducks/financePayments/sagas";
import {saga as financeReportSaga} from "../ducks/financeReport";
import {saga as foremanContractorsSaga} from "../ducks/foremanContractors";
import {saga as foremanPaymentsSaga} from "../ducks/foremanPayments";
import {saga as foremanRequestsSaga} from "../ducks/foremanRequests";
import {saga as insuranceSaga} from "../ducks/insurance";
import {saga as internalPaymentsSaga} from "../ducks/internalPayments/sagas";
import {saga as jobSaga} from "../ducks/job";
import {saga as kedoDirectoriesSaga} from "../ducks/kedo/directories/sagas";
import {saga as kedoDocumentsSaga} from "../ducks/kedo/documents/sagas";
import {saga as kedoExportsSaga} from "../ducks/kedo/exports/sagas";
import {saga as kedoStaffSaga} from "../ducks/kedo/staff/sagas";
import {saga as kedoTemplatesSaga} from "../ducks/kedo/templates/sagas";
import {saga as keywordSaga} from "../ducks/keyword";
import {saga as logsSaga} from "../ducks/logs";
import {saga as mlSaga} from "../ducks/machineLearning";
import {saga as medicalBookSaga} from "../ducks/medicalBook";
import {saga as minCommissionSaga} from "../ducks/minCommissions";
import {saga as mlmodelsSaga} from "../ducks/mlmodels";
import {saga as mutualSettlementsReconciliationActsSaga} from "../ducks/mutualSettlementsReconciliationActs";
import {saga as naimixInfoReadSaga} from "../ducks/naimixInfo";
import {saga as notificationSaga} from "../ducks/notification";
import {saga as objectsSaga} from "../ducks/objects";
import {saga as onboardingSaga} from "../ducks/onboarding";
import {saga as onboardingContractorsSaga} from "../ducks/onboardingContractors";
import {saga as operatorReportsSaga} from "../ducks/operatorReports";
import {saga as orderSaga} from "../ducks/order";
import {saga as orderCategoriesSaga} from "../ducks/orderCategories";
import {saga as orderContractorsSaga} from "../ducks/orderContractors";
import {saga as orderContractsSaga} from "../ducks/orderContracts";
import {saga as orderGroupSaga} from "../ducks/orderGroup";
import {saga as orderSubcategoriesSaga} from "../ducks/orderSubcategories";
import {saga as orderWorkSaga} from "../ducks/orderWork";
import {saga as orderWorkReportSaga} from "../ducks/orderWorkReport";
import {saga as orderWorkReportTemplateSaga} from "../ducks/orderWorkReportTemplate";
import {saga as patentsDepositSaga} from "../ducks/patentsDeposits";
import {saga as patentsDocumentsSaga} from "../ducks/patentsDocuments";
import {saga as patentsDocumentsExport} from "../ducks/patentsDocumentsExport";
import {saga as patentsPaymentsSaga} from "../ducks/patentsPayments";
import {saga as patentsRegistrySaga} from "../ducks/patentsRegistry";
import {saga as printSaga} from "../ducks/print";
import {saga as projectParamsSaga} from "../ducks/projectParams";
import {saga as projectsSaga} from "../ducks/projects";
import {saga as promocodeSaga} from "../ducks/promocode";
import {saga as ratingsSaga} from "../ducks/ratings";
import {saga as registrationSaga} from "../ducks/registration";
import {saga as registriesSaga} from "../ducks/registry";
import {saga as registryInvitationsSaga} from "../ducks/registryInvitations";
import {saga as registryInvitationsContractorsSaga} from "../ducks/registryInvitationsContractors";
import {saga as registryPatentsSaga} from "../ducks/registryPatents";
import {saga as registryPaymentsSaga} from "../ducks/registryPayments";
import {saga as registryPaymentStatusesSaga} from "../ducks/registryPaymentStatuses";
import {saga as scoresSaga} from "../ducks/scores";
import {saga as settingsDirectoriesBanksSaga} from "../ducks/settingsDirectoriesBanks";
import {saga as settingsUsersRolesSaga} from "../ducks/settingsUsersRoles";
import {saga as smartLinkSaga} from "../ducks/smartLink/sagas";
import {saga as smsSaga} from "../ducks/sms";
import {saga as smzReportsSummarySaga} from "../ducks/smzReportsSummary";
import {saga as specialitySaga} from "../ducks/speciality";
import {saga as stopListSaga} from "../ducks/stopList";
import {saga as stopWordsSaga} from "../ducks/stopWords";
import {saga as strapiSaga} from "../ducks/strapi";
import {saga as systemPropertiesSaga} from "../ducks/systemPropertyHistory";
import {saga as systemSettingSaga} from "../ducks/systemSetting";
import {saga as tariffSaga} from "../ducks/tariff";
import {saga as taskSaga} from "../ducks/task/sagas";
import {saga as timesheetSaga} from "../ducks/timesheet";
import {saga as urlShortenerSaga} from "../ducks/urlShortener";
import {saga as userInstructionsSaga} from "../ducks/userInstructions";

export default function* rootSaga() {
    yield all([
        bffSaga(),
        authSaga(),
        clientSaga(),
        contractorSaga(),
        clientMemberSaga(),
        clientObjectSaga(),
        dictSaga(),
        fileStoreSaga(),
        orderSaga(),
        orderWorkSaga(),
        orderContractorsSaga(),
        orderWorkReportSaga(),
        documentSaga(),
        financeLogListReadSaga(),
        naimixInfoReadSaga(),
        clientFinanceUploadLogListReadSaga(),
        depositSaga(),
        feedback(),
        addressSaga(),
        registrationSaga(),
        federalMigrationSaga(),
        minCommissionSaga(),
        promocodeSaga(),
        deviceLogListReadSaga(),
        orderContractsSaga(),
        passwordRecovery(),
        contractorScanSaga(),
        createEmptyDisputeSaga(),
        chatAddMessageSaga(),
        contractorWorkLogSaga(),
        clientPropertiesSaga(),
        projectParamsSaga(),
        addFireBaseTokenSaga(),
        contractorReceiptControllerSaga(),
        contractHistorySaga(),
        clientRefSaga(),
        mlSaga(),
        logsSaga(),
        chatListSaga(),
        financeReportSaga(),
        financeExportSaga(),
        contractorOtherFilesSaga(),
        insuranceSaga(),
        userInstructionsSaga(),
        keywordSaga(),
        stopWordsSaga(),
        documentsExportSaga(),
        operatorReportsSaga(),
        orderCategoriesSaga(),
        emailNotificationSaga(),
        projectsSaga(),
        orderSubcategoriesSaga(),
        registriesSaga(),
        specialitySaga(),
        objectsSaga(),
        registryPaymentsSaga(),
        emailSaga(),
        registryPaymentStatusesSaga(),
        scoresSaga(),
        contractorScoresSaga(),
        contractorPushNotificationSaga(),
        contractorsSubscriptionSaga(),
        timesheetSaga(),
        foremanPaymentsSaga(),
        foremanContractorsSaga(),
        foremanRequestsSaga(),
        jobSaga(),
        ratingsSaga(),
        smsSaga(),
        edmPositionsSaga(),
        notificationSaga(),
        fcRegistrySaga(),
        bankDepositSaga(),
        workSchedulesSaga(),
        edmStaffSaga(),
        clientRemoteContractorsSaga(),
        contractorBankCheckSaga(),
        stopListSaga(),
        dadataSaga(),
        clientSpecialityOfferSaga(),
        edoDocumentsSaga(),
        urlShortenerSaga(),
        statementsSaga(),
        exportDocumentSaga(),
        patentsDepositSaga(),
        patentsDocumentsSaga(),
        patentsRegistrySaga(),
        patentsPaymentsSaga(),
        patentsDocumentsExport(),
        documentsEditManualSaga(),
        clientGroupsSaga(),
        contractorPendingPersonalDataSaga(),
        systemPropertiesSaga(),
        edmSubdivisionsSaga(),
        contractorEventLogSaga(),
        medicalBookSaga(),
        advertisementSaga(),
        contractorProfileSaga(),
        civilSaga(),
        orderGroupSaga(),
        registryInvitationsSaga(),
        printSaga(),
        registryInvitationsContractorsSaga(),
        financeCivilSaga(),
        actRegistriesSaga(),
        actRegistryPaymentSaga(),
        tariffSaga(),
        availableClientUsersAccessSaga(),
        clientDadataSaga(),
        categorySpecialitiesSaga(),
        smzReportsSummarySaga(),
        ticketSaga(),
        contractorsPersonalDataAccessSaga(),
        systemSettingSaga(),
        documentsEdoRegistriesSaga(),
        documentsEdoRegistriesItemsSaga(),
        clientsApplicationSaga(),
        crmRecordingSaga(),
        registryPatentsSaga(),
        mlmodelsSaga(),
        documentsCustomTemplateSaga(),
        collectionBankDetailsSaga(),
        orderWorkReportTemplateSaga(),
        contractorsDictsSaga(),
        contractorAdditionalDocumentsSaga(),
        strapiSaga(),
        contractorNotificationLogSaga(),
        settingsUsersRolesSaga(),
        clientUserRestrictionsSaga(),
        onboardingSaga(),
        onboardingRecruitersSaga(),
        onboardingContractorsSaga(),
        settingsDirectoriesBanksSaga(),
        mutualSettlementsReconciliationActsSaga(),
        contractorFinanceBalanceSaga(),
        contractorBanksSaga(),
        contractorFinanceIncomeNpdSaga(),
        contractorFinanceIncomeNdflSaga(),
        contractorFinanceIncomeIndividualSaga(),
        companyDocumentsRegistrySaga(),
        externalJobBoardsSaga(),
        fileStoreRecruitmentSaga(),
        kedoDocumentsSaga(),
        kedoDirectoriesSaga(),
        taskSaga(),
        kedoExportsSaga(),
        internalPaymentsSaga(),
        kedoTemplatesSaga(),
        clientBrandsSaga(),
        smartLinkSaga(),
        kedoStaffSaga(),
        eventsContractorSaga(),
        financePaymentsSaga(),
        contractorNoteForClientSaga(),
    ]);
}
