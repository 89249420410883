import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/urlshortener/shortened";
//*  TYPES  *//

const ADD_URL_SHORTENER_SHORTENED_REQUEST = "ADD_URL_SHORTENER_SHORTENED_REQUEST";
const ADD_URL_SHORTENER_SHORTENED_SUCCESS = "ADD_URL_SHORTENER_SHORTENED_SUCCESS";

//*  INITIAL STATE  *//

const initial = {
    shortenedLink: "",
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case ADD_URL_SHORTENER_SHORTENED_SUCCESS:
        const {
            shortenedLink,
        } = payload;

        return {
            ...state,
            shortenedLink,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function shortUrl(payload) {
    return {
        type: ADD_URL_SHORTENER_SHORTENED_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const urlShortenerSelector = ({urlShortener}) => urlShortener;
export const shortLinkSelector = createSelector(urlShortenerSelector, ({shortenedLink}) => shortenedLink);

//*  SAGA  *//

//GET /api/urlshortener/shortened/add
export const getMetricsForTimeSheetSaga = function* ({payload}) {
    try {
        const {
            callBack,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/add`, reqData);
        const {
            errorMessage,
            shortenedLink,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("getMetricsForTimeSheetSaga Error");

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack(shortenedLink);
        }

        yield put({
            type: ADD_URL_SHORTENER_SHORTENED_SUCCESS,
            payload: result,
        });

        toastSuccess("Ссылка скопирована");
    } catch (error) {
        toastError("Возникла ошибка");
        console.error("getMetricsForTimeSheetSaga Error");
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_URL_SHORTENER_SHORTENED_REQUEST, getMetricsForTimeSheetSaga),
    ]);
}
