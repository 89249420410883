import * as yup from "yup";

import {
    validateBik,
    validateInn,
    validateKs,
    validateRs,
} from "../../../../../../../utils/validate";

import {CONTRACTOR_FIELD} from "../../../../../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        [CONTRACTOR_FIELD.RS]: yup.string()
            .nullable()
            .when("bankRequisitesRequired", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test([CONTRACTOR_FIELD.RS], "", function (value) {
                        const bic = this.parent[CONTRACTOR_FIELD.BIC];

                        const error = validateRs(value, bic);

                        if (error) {
                            return this.createError({
                                message: error,
                                path: CONTRACTOR_FIELD.RS,
                            });
                        }

                        return true;
                    }),
            }),
        [CONTRACTOR_FIELD.BANK_NAME]: yup.string()
            .nullable()
            .max(255, "Максимальная длина - 255 символов")
            .when("bankRequisitesRequired", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(255, "Максимальная длина - 255 символов")
                    .min(1, "Минимальная длина - 1 символ"),
            }),
        [CONTRACTOR_FIELD.INN]: yup.string()
            .nullable()
            .when("bankRequisitesRequired", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test([CONTRACTOR_FIELD.INN], "", function (value) {
                        const error = validateInn(value);

                        if (error) {
                            return this.createError({
                                message: error,
                                path: CONTRACTOR_FIELD.INN,
                            });
                        }

                        return true;
                    }),
            }),
        [CONTRACTOR_FIELD.BIC]: yup.string()
            .nullable()
            .when("bankRequisitesRequired", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test([CONTRACTOR_FIELD.BIC], "", function (value) {
                        const error = validateBik(value);

                        if (error) {
                            return this.createError({
                                message: error,
                                path: CONTRACTOR_FIELD.BIC,
                            });
                        }

                        return true;
                    }),
            }),
        [CONTRACTOR_FIELD.BANK_ADDRESS]: yup.string()
            .nullable()
            .max(100, "Максимальная длина - 100 символов")
            .when("bankRequisitesRequired", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(100, "Максимальная длина - 100 символов"),
            }),
        [CONTRACTOR_FIELD.KS]: yup.string()
            .nullable()
            .when("bankRequisitesRequired", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test([CONTRACTOR_FIELD.KS], "", function (value) {
                        const bic = this.parent[CONTRACTOR_FIELD.BIC];

                        const error = validateKs(value, bic);

                        if (error) {
                            return this.createError({
                                message: error,
                                path: CONTRACTOR_FIELD.KS,
                            });
                        }

                        return true;
                    }),
            }),
    });
};

export default validationSchema;