import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {isNMUsers} from "../../../../../utils/access";
import {
    CURRENT_CLIENT_USER_ID,
    ls,
    USER_ROLE,
} from "../../../../../utils/localstorage";

import {ORDER_STATUS} from "../../../../../constants/clientList";
import {ORDER_SOURCE_TYPES} from "../../../../../constants/order";
import {FOREMAN} from "../../../../../constants/roles";

import {getOrderRichList} from "../../../../../ducks/order";
import {getOrderGroupById} from "../../../../../ducks/orderGroup";

export function useAddOrdersToGroupFetchData({clientId, orderGroupId, orderGroupNumber, pageSize, pageNum, filter}) {
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);
    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!orderGroupNumber) {
            fetchCard();
        }
    }, []);

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum]);

    const fetchCard = () => {
        dispatch(getOrderGroupById({
            clientId,
            orderGroupId,
        }));
    };

    const fetchList = () => {
        dispatch(getOrderRichList({
            clientId,
            clientUserIdsFilter: role === FOREMAN && currentUserId ? [currentUserId] : undefined,
            orderSourceTypes: isNMUsers() ? undefined : [ORDER_SOURCE_TYPES.TYPE_1, ORDER_SOURCE_TYPES.TYPE_2],
            withoutGroups: true,
            archivedFilter: false,
            statusFilter: [ORDER_STATUS.DRAFT, ORDER_STATUS.CLOSED, ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH],
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}