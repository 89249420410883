import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getOnboardingScenarioCard,
    getOnboardingScenarioList,
    updateOnboardingStore,
} from "../../../../../ducks/onboarding";

export function useFetchOnboardingScenarioCard({clientId, scenarioId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateOnboardingStore({
                card: [],
            }));
        };
    }, []);

    useEffect(() => {
        fetchCard();
    }, [scenarioId]);

    const fetchCard= () => {
        dispatch(getOnboardingScenarioCard({
            clientId,
            scenarioId,
        }));
    };

    return {
        fetchCard,
    };
}