import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

import {REF_TYPE} from "../constants/refProgram";

const controller = "/clientreferralprograms";

export const ADD_REF_REQUEST = "ADD_REF_REQUEST";
export const ADD_REF_SUCCESS = "ADD_REF_SUCCESS";
export const ADD_REF_ERROR = "ADD_REF_ERROR";

export const GET_REF_PROGRAMM_REQUEST = "GET_REF_PROGRAMM_REQUEST";
export const GET_REF_PROGRAMM_SUCCESS = "GET_REF_PROGRAMM_SUCCESS";
export const GET_REF_PROGRAMM_ERROR = "GET_REF_PROGRAMM_ERROR";

export const GET_REF_PROGRAMM_HISTORY_REQUEST = "GET_REF_PROGRAMM_HISTORY_REQUEST";
export const GET_REF_PROGRAMM_HISTORY_SUCCESS = "GET_REF_PROGRAMM_HISTORY_SUCCESS";
export const GET_REF_PROGRAMM_HISTORY_ERROR = "GET_REF_PROGRAMM_HISTORY_ERROR";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    [REF_TYPE.CLIENTS]: {
        pageData: {},
        totalCount: 0,
        list: [],
        progress: false,
    },
    [REF_TYPE.CONTRACTORS]: {
        pageData: {},
        totalCount: 0,
        list: [],
        progress: false,
    },
    clientRefData: {
        promocodeForContractor: {},
        promocodeForClient: {},
    },
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    const {
        typeFilter,
    } = payload || {};

    const {
        [typeFilter]: historyRefData,
    } = state;
    
    switch (type) {
    case ADD_REF_REQUEST:
    case GET_REF_PROGRAMM_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_REF_PROGRAMM_HISTORY_REQUEST:
        return {
            ...state,
            [typeFilter]: {
                ...historyRefData,
                progress: true,
                pageData: payload,
            },
        };
    case ADD_REF_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case GET_REF_PROGRAMM_SUCCESS:
        return {
            ...state,
            clientRefData: payload,
            progress: false,
        };
    case GET_REF_PROGRAMM_HISTORY_SUCCESS:
        const {
            clientReferralHistoryModels,
            totalCount,
        } = payload;

        return {
            ...state,
            [typeFilter]: {
                ...historyRefData,
                progress: false,
                list: clientReferralHistoryModels,
                totalCount,
            },
        };
    case GET_REF_PROGRAMM_HISTORY_ERROR:
        return {
            ...state,
            error: payload,
            [typeFilter]: {
                ...historyRefData,
                progress: false,
            },
        };
    case GET_REF_PROGRAMM_ERROR:
    case ADD_REF_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function addRefProgram(payload) {
    return {type: ADD_REF_REQUEST, payload};
}

export function getClientRefProgram(payload) {
    return {type: GET_REF_PROGRAMM_REQUEST, payload};
}

export function getClientRefProgramHistory(payload) {
    return {type: GET_REF_PROGRAMM_HISTORY_REQUEST, payload};
}

//*  SELECTORS  *//

export const clientRefProgramSelector = state => state.clientRefProgram;

export const clientRefProgramProgressSelector = createSelector(clientRefProgramSelector, ({progress}) => progress);

export const clientReferralHistorySelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.list);

export const contractorsReferralHistorySelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CONTRACTORS]: refData}) => refData.list);

export const clientReferralHistoryProgressSelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.progress);

export const contractorsReferralHistoryProgressSelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.progress);

export const clientReferralDataSelector = createSelector(clientRefProgramSelector, ({clientRefData}) => clientRefData);

export const clientReferralHistoryPageDataSelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CLIENTS]: refData}) => refData.pageData);

export const clientRefTotalPagesSelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CLIENTS]: refData}) => getTotalPages(refData.totalCount, refData.pageData.pageSize));

export const contractorsRefTotalPagesSelector = createSelector(clientRefProgramSelector, ({[REF_TYPE.CONTRACTORS]: refData}) => getTotalPages(refData.totalCount, refData.pageData.pageSize));

//*  SAGA  *//

export const addRefProgramSaga = function* ({payload}) {
    try {
        const {onSuccess = () => {}} = payload;

        const result = yield request.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_REF_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_REF_SUCCESS, payload: result});

        const state = yield select();
        yield put(getClientRefProgramHistory(clientReferralHistoryPageDataSelector(state)));

        const {
            clientId,
        } = payload;

        yield put(getClientRefProgram({clientId}));

    } catch (error) {
        yield put({
            type: ADD_REF_ERROR,
            payload: error,
        });
    }
};

export const getClientRefProgramSaga = function* (action) {
    try {
        const {
            payload: {
                clientId,
            },
        } = action;
        const result = yield request.get("/contractors/promotion/getPromocodesByClientId", {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REF_PROGRAMM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_REF_PROGRAMM_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_REF_PROGRAMM_ERROR,
            payload: error,
        });
    }
};

export const getClientRefProgramHistorySaga = function* (action) {
    try {
        const {payload} = action;
        const {typeFilter} = payload;
        const result = yield request.post(`${controller}/getPageClientReferralHistory`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REF_PROGRAMM_HISTORY_ERROR,
                payload: {
                    ...errorMessage,
                    typeFilter,
                },
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_REF_PROGRAMM_HISTORY_SUCCESS, payload: {
                ...result,
                typeFilter,
            },
        });
    } catch (error) {
        yield put({
            type: GET_REF_PROGRAMM_HISTORY_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_REF_REQUEST, addRefProgramSaga),
        takeEvery(GET_REF_PROGRAMM_REQUEST, getClientRefProgramSaga),
        takeEvery(GET_REF_PROGRAMM_HISTORY_REQUEST, getClientRefProgramHistorySaga),
    ]);
}

