import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
    BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST,
    BFF_BANK_TKB_HISTORY_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/bank/tkb";

// POST /bff/adm/bank/tkb/history/getPageRich
// Получение обогащенной страницы с депозитами из ТКБ
const getPageBankTkbHistory = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/history/getPageRich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_BANK_TKB_HISTORY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST, getPageBankTkbHistory),
    ]);
}