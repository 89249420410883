import axios from "axios";
import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect/lib/index";

import {call} from "../../node_modules/redux-saga/effects";
import {getProjectsList, projectPageDataSelector} from "./projects";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/clientDeposit";
const controllerV2 = "/clientDeposit/v2";

const bffController = "/adm/npd-deposit";
const bffCivilController = "/adm/ndfl-deposit";

//*  TYPES  *//

const DEPOSIT_LIST_REQUEST = "DEPOSIT_LIST_REQUEST";
const DEPOSIT_LIST_SUCCESS = "DEPOSIT_LIST_SUCCESS";
const DEPOSIT_LIST_ERROR = "DEPOSIT_LIST_ERROR";

const DEPOSIT_LIST_V2_REQUEST = "DEPOSIT_LIST_V2_REQUEST";
const DEPOSIT_LIST_V2_SUCCESS = "DEPOSIT_LIST_V2_SUCCESS";

const ADD_DEPOSIT_REQUEST = "ADD_DEPOSIT_REQUEST";
const ADD_DEPOSIT_SUCCESS = "ADD_DEPOSIT_SUCCESS";
const ADD_DEPOSIT_ERROR = "ADD_DEPOSIT_ERROR";

const DEPOSIT_HISTORY_LIST_REQUEST = "DEPOSIT_HISTORY_LIST_REQUEST";
const DEPOSIT_HISTORY_LIST_SUCCESS = "DEPOSIT_HISTORY_LIST_SUCCESS";
const DEPOSIT_HISTORY_LIST_ERROR = "DEPOSIT_HISTORY_LIST_ERROR";

const UPDATE_FIELD_DEPOSIT_STORE = "UPDATE_FIELD_DEPOSIT_STORE";

const EDIT_CLIENT_CURRENT_COMMISSION_RATE_REQUEST = "EDIT_CLIENT_CURRENT_COMMISSION_RATE_REQUEST";
const EDIT_CLIENT_CURRENT_COMMISSION_RATE_SUCCESS = "EDIT_CLIENT_CURRENT_COMMISSION_RATE_SUCCESS";
const EDIT_CLIENT_CURRENT_COMMISSION_RATE_ERROR = "EDIT_CLIENT_CURRENT_COMMISSION_RATE_ERROR";

const UPDATE_CLIENT_DEPOSIT_FAVORITE_REQUEST = "UPDATE_CLIENT_DEPOSIT_FAVORITE_REQUEST";
const UPDATE_CLIENT_DEPOSIT_FAVORITE_SUCCESS = "UPDATE_CLIENT_DEPOSIT_FAVORITE_SUCCESS";
const UPDATE_CLIENT_DEPOSIT_FAVORITE_ERROR = "UPDATE_CLIENT_DEPOSIT_FAVORITE_ERROR";

const GET_DEPOSIT_COMMISSION_RICH_HISTORY_REQUEST = "GET_DEPOSIT_COMMISSION_RICH_HISTORY_REQUEST";
const GET_DEPOSIT_COMMISSION_RICH_HISTORY_SUCCESS = "GET_DEPOSIT_COMMISSION_RICH_HISTORY_SUCCESS";
const GET_DEPOSIT_COMMISSION_RICH_HISTORY_ERROR = "GET_DEPOSIT_COMMISSION_RICH_HISTORY_ERROR";

const GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_REQUEST = "GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_REQUEST";
const GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_SUCCESS = "GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_SUCCESS";
const GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_ERROR = "GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_ERROR";

const EDIT_DEPOSIT_REQUEST = "EDIT_DEPOSIT_REQUEST";
const EDIT_DEPOSIT_SUCCESS = "EDIT_DEPOSIT_SUCCESS";
const EDIT_DEPOSIT_ERROR = "EDIT_DEPOSIT_ERROR";

const RETURN_DEPOSIT_REQUEST = "RETURN_DEPOSIT_REQUEST";
const RETURN_DEPOSIT_SUCCESS = "RETURN_DEPOSIT_SUCCESS";
const RETURN_DEPOSIT_ERROR = "RETURN_DEPOSIT_ERROR";

const UPDATE_DEPOSIT_COMMENT_REQUEST = "UPDATE_DEPOSIT_COMMENT_REQUEST";
const UPDATE_DEPOSIT_COMMENT_SUCCESS = "UPDATE_DEPOSIT_COMMENT_SUCCESS";
const UPDATE_DEPOSIT_COMMENT_ERROR = "UPDATE_DEPOSIT_COMMENT_ERROR";

const GET_CLIENT_DEPOSIT_VALUES_REQUEST = "GET_CLIENT_DEPOSIT_VALUES_REQUEST";
const GET_CLIENT_DEPOSIT_VALUES_SUCCESS = "GET_CLIENT_DEPOSIT_VALUES_SUCCESS";
const GET_CLIENT_DEPOSIT_VALUES_ERROR = "GET_CLIENT_DEPOSIT_VALUES_ERROR";

const GET_DEPOSIT_HISTORY_PAGE_REQUEST = "GET_DEPOSIT_HISTORY_PAGE_REQUEST";
const GET_DEPOSIT_HISTORY_PAGE_SUCCESS = "GET_DEPOSIT_HISTORY_PAGE_SUCCESS";
const GET_DEPOSIT_HISTORY_PAGE_ERROR = "GET_DEPOSIT_HISTORY_PAGE_ERROR";

const CORRECT_CLIENT_DEPOSIT_REQUEST = "CORRECT_CLIENT_DEPOSIT_REQUEST";
const CORRECT_CLIENT_DEPOSIT_SUCCESS = "CORRECT_CLIENT_DEPOSIT_SUCCESS";
const CORRECT_CLIENT_DEPOSIT_ERROR = "CORRECT_CLIENT_DEPOSIT_ERROR";

const GET_AVAILABLE_FOR_PAYMENTS_REQUEST = "GET_AVAILABLE_FOR_PAYMENTS_REQUEST";
const GET_AVAILABLE_FOR_PAYMENTS_SUCCESS = "GET_AVAILABLE_FOR_PAYMENTS_SUCCESS";
const GET_AVAILABLE_FOR_PAYMENTS_ERROR = "GET_AVAILABLE_FOR_PAYMENTS_ERROR";

const GET_DEPOSIT_VALUES_REQUEST = "GET_DEPOSIT_VALUES_REQUEST";
const GET_DEPOSIT_VALUES_SUCCESS = "GET_DEPOSIT_VALUES_SUCCESS";
const GET_DEPOSIT_VALUES_ERROR = "GET_DEPOSIT_VALUES_ERROR";

const IMPORT_DEPOSIT_REQUEST = "IMPORT_DEPOSIT_REQUEST";
const IMPORT_DEPOSIT_SUCCESS = "IMPORT_DEPOSIT_SUCCESS";
const IMPORT_DEPOSIT_ERROR = "IMPORT_DEPOSIT_ERROR";

const GET_PAID_API_PAGE_REQUEST = "GET_PAID_API_PAGE_REQUEST";
const GET_PAID_API_PAGE_SUCCESS = "GET_PAID_API_PAGE_SUCCESS";
const GET_PAID_API_PAGE_ERROR = "GET_PAID_API_PAGE_ERROR";

//*  INITIAL STATE  *//

const initial = {
    depositList: [],
    depositHistoryList: [],
    progressList: false,
    progressDepositCorrection: false,
    pageData: {},
    commissionHistoryProgress: false,
    commissionHistoryModels: [],
    commissionHistoryTotalCount: 0,
    commissionHistoryPageData: {},
    totalCount: 0,
    progressAdd: false,
    progressHistoryList: false,
    progressDeposit: false,
    progressAction: false,
    pageDataHistory: {},
    totalCountHistory: 0,
    deposit: {},
    depositValues: {},
    totalAddedAmount: 0,
    totalReturnedAmount: 0,
    availableForPayments: 0,
    depositValuesV2: {},
    civilDepositValuesV2: {},
    progressDepositImport: false,
    paidApiPageData: {},
    paidApiProgress: false,
    paidApiList: [],
    paidApiTotalCount: 0,
    paidApiTotalAmount: 0,
    valuesProgress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_DEPOSIT_VALUES_REQUEST:
        return {
            ...state,
            valuesProgress: true,
        };
    case GET_DEPOSIT_VALUES_ERROR:
        return {
            ...state,
            valuesProgress: false,
        };
    case GET_DEPOSIT_COMMISSION_RICH_HISTORY_REQUEST:
    case GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_REQUEST:
        return {
            ...state,
            commissionHistoryPageData: payload,
            commissionHistoryProgress: true,
        };
    case GET_PAID_API_PAGE_REQUEST:
        return {
            ...state,
            paidApiPageData: payload,
            paidApiProgress: true,
        };
    case GET_DEPOSIT_COMMISSION_RICH_HISTORY_SUCCESS:
        const {totalCount: commissionHistoryTotalCount, commissionHistoryModels} = payload;

        return {
            ...state,
            commissionHistoryModels,
            commissionHistoryTotalCount,
            commissionHistoryProgress: false,
        };
    case GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_SUCCESS:
        return {
            ...state,
            commissionHistoryModels: payload.results,
            commissionHistoryTotalCount: payload.totalCount,
            commissionHistoryProgress: false,
        };
    case GET_PAID_API_PAGE_SUCCESS:
        return {
            ...state,
            paidApiList: payload.results,
            paidApiTotalCount: payload.totalCount,
            paidApiTotalAmount: payload.totalAmount,
            paidApiProgress: false,
        };
    case GET_DEPOSIT_COMMISSION_RICH_HISTORY_ERROR:
    case GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_ERROR:
        return {
            ...state,
            commissionHistoryProgress: false,
        };
    case GET_PAID_API_PAGE_ERROR:
        return {
            ...state,
            paidApiProgress: false,
        };
    case DEPOSIT_LIST_V2_REQUEST:
    case DEPOSIT_LIST_REQUEST:
        return {
            ...state,
            progressList: true,
            pageData: payload,
        };
    case UPDATE_FIELD_DEPOSIT_STORE:
        return {
            ...state,
            ...payload,
        };
    case DEPOSIT_HISTORY_LIST_REQUEST:
    case GET_DEPOSIT_HISTORY_PAGE_REQUEST:
        return {
            ...state,
            progressHistoryList: true,
            pageDataHistory: payload.data,
        };
    case ADD_DEPOSIT_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case DEPOSIT_LIST_V2_SUCCESS:
        const {
            results,
            totalCount: listTotalCount,
        } = payload;

        return {
            ...state,
            depositList: results,
            totalCount: listTotalCount,
            progressList: false,
        };
    case DEPOSIT_LIST_SUCCESS:
        const {
            objects: depositList,
            totalCount,
        } = payload;

        return {
            ...state,
            depositList,
            totalCount,
            progressList: false,
        };
    case ADD_DEPOSIT_SUCCESS:
    case ADD_DEPOSIT_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case DEPOSIT_HISTORY_LIST_SUCCESS:
        const {
            objects,
        } = payload;

        return {
            ...state,
            depositHistoryList: objects,
            totalCountHistory: payload?.totalCount,
            progressHistoryList: false,
            progressList: false,
        };
    case GET_DEPOSIT_HISTORY_PAGE_SUCCESS:
        const {
            results: depositHistoryList,
            totalCount: totalCountHistory,
            totalAddedAmount,
            totalReturnedAmount,
        } = payload;

        return {
            ...state,
            depositHistoryList,
            totalCountHistory,
            totalAddedAmount,
            totalReturnedAmount,
            progressHistoryList: false,
            progressList: false,
        };
    case GET_CLIENT_DEPOSIT_VALUES_SUCCESS:
        return {
            ...state,
            depositValues: payload,
        };
    case DEPOSIT_LIST_ERROR:
        return {
            ...state,
            progressList: false,
        };
    case DEPOSIT_HISTORY_LIST_ERROR:
    case GET_DEPOSIT_HISTORY_PAGE_ERROR:
        return {
            ...state,
            progressHistoryList: false,
        };
    case CORRECT_CLIENT_DEPOSIT_REQUEST:
        return {
            ...state,
            progressDepositCorrection: true,
        };
    case CORRECT_CLIENT_DEPOSIT_SUCCESS:
    case CORRECT_CLIENT_DEPOSIT_ERROR:
        return {
            ...state,
            progressDepositCorrection: false,
        };
    case GET_AVAILABLE_FOR_PAYMENTS_SUCCESS:
        return {
            ...state,
            availableForPayments: payload,
        };
    case GET_DEPOSIT_VALUES_SUCCESS:
        const {
            isCivil,
            result,
        } = payload;

        if (isCivil) {
            return {
                ...state,
                valuesProgress: false,
                civilDepositValuesV2: result,
            };
        }

        return {
            ...state,
            valuesProgress: false,
            depositValuesV2: result,
        };
    case IMPORT_DEPOSIT_REQUEST:
        return {
            ...state,
            progressDepositImport: true,
        };
    case IMPORT_DEPOSIT_SUCCESS:
    case IMPORT_DEPOSIT_ERROR:
        return {
            ...state,
            progressDepositImport: false,
        };
    case RETURN_DEPOSIT_REQUEST:
    case EDIT_DEPOSIT_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case RETURN_DEPOSIT_SUCCESS:
    case RETURN_DEPOSIT_ERROR:
    case EDIT_DEPOSIT_SUCCESS:
    case EDIT_DEPOSIT_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getDepositList(payload) {
    return {
        type: DEPOSIT_LIST_REQUEST,
        payload,
    };
}

export function getDepositListV2(payload) {
    return {
        type: DEPOSIT_LIST_V2_REQUEST,
        payload,
    };
}

export function getHistoryDepositList(payload) {
    return {
        type: DEPOSIT_HISTORY_LIST_REQUEST,
        payload,
    };
}

export function getDepositHistoryPage(payload) {
    return {
        type: GET_DEPOSIT_HISTORY_PAGE_REQUEST,
        payload,
    };
}

export function addDeposit(payload) {
    return {
        type: ADD_DEPOSIT_REQUEST,
        payload,
    };
}

export function editDeposit(payload) {
    return {
        type: EDIT_DEPOSIT_REQUEST,
        payload,
    };
}

export function returnDeposit(payload) {
    return {
        type: RETURN_DEPOSIT_REQUEST,
        payload,
    };
}

export function updateDepositComment(payload) {
    return {
        type: UPDATE_DEPOSIT_COMMENT_REQUEST,
        payload,
    };
}

export function updateFieldDepositStore(payload) {
    return {
        type: UPDATE_FIELD_DEPOSIT_STORE,
        payload,
    };
}

export function editClientCurrentCommissionRate(payload) {
    return {
        type: EDIT_CLIENT_CURRENT_COMMISSION_RATE_REQUEST,
        payload,
    };
}

export function updateClientDepositFavorite(payload) {
    return {
        type: UPDATE_CLIENT_DEPOSIT_FAVORITE_REQUEST,
        payload,
    };
}

export function getRichCommissionHistory(payload) {
    return {
        type: GET_DEPOSIT_COMMISSION_RICH_HISTORY_REQUEST,
        payload,
    };
}

export function getRichContractorCommissionHistory(payload) {
    return {
        type: GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_REQUEST,
        payload,
    };
}

export function getClientDepositValues(payload) {
    return {
        type: GET_CLIENT_DEPOSIT_VALUES_REQUEST,
        payload,
    };
}

// корректировка депозита НПД
export function correctClientDeposit(payload) {
    return {
        type: CORRECT_CLIENT_DEPOSIT_REQUEST,
        payload,
    };
}

export function getAvailableForPayments(payload) {
    return {
        type: GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
        payload,
    };
}

export function getClientDepositValuesV2(payload) {
    return {
        type: GET_DEPOSIT_VALUES_REQUEST,
        payload,
    };
}

export function importDeposit(payload) {
    return {
        type: IMPORT_DEPOSIT_REQUEST,
        payload,
    };
}

export function getPaidApiPage(payload) {
    return {
        type: GET_PAID_API_PAGE_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const getDepositStoreSelector = state => state.deposit;
export const getDepositListSelector = createSelector(getDepositStoreSelector, ({depositList}) => depositList);

export const getTotalAddedAmountSelector = createSelector(getDepositStoreSelector, ({totalAddedAmount}) => totalAddedAmount);
export const getTotalReturnedAmountSelector = createSelector(getDepositStoreSelector, ({totalReturnedAmount}) => totalReturnedAmount);
export const getDepositValuesSelector = createSelector(getDepositStoreSelector, ({depositValues}) => depositValues);
export const getDepositValuesV2Selector = createSelector(getDepositStoreSelector, ({depositValuesV2}) => depositValuesV2);
export const getCivilDepositValuesV2Selector = createSelector(getDepositStoreSelector, ({civilDepositValuesV2}) => civilDepositValuesV2);
export const getProgressHistoryListSelector = createSelector(getDepositStoreSelector, ({progressHistoryList}) => progressHistoryList);
export const getDepositHistoryListSelector = createSelector(getDepositStoreSelector, ({depositHistoryList}) => depositHistoryList);
export const getDepositTotalPagesSelector = createSelector(getDepositStoreSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getDepositTotalCountSelector = createSelector(getDepositStoreSelector, ({totalCount}) => totalCount);
export const getDepositHistoryTotalPagesSelector = createSelector(getDepositStoreSelector, ({totalCountHistory, pageDataHistory: {pageSize = 0}}) => getTotalPages(totalCountHistory, pageSize));
export const getDepositHistoryTotalCountSelector = createSelector(getDepositStoreSelector, ({totalCountHistory}) => totalCountHistory);
export const getDepositPageDataSelector = createSelector(getDepositStoreSelector, ({pageData}) => pageData);
export const getDepositProgressAddSelector = createSelector(getDepositStoreSelector, ({progressAdd}) => progressAdd);
export const commissionHistoryModesSelector = createSelector(getDepositStoreSelector, ({commissionHistoryModels}) => commissionHistoryModels);
export const commissionHistoryTotalPagesSelector = createSelector(getDepositStoreSelector,
    ({commissionHistoryTotalCount, commissionHistoryPageData: {pageSize = 0}}) => getTotalPages(commissionHistoryTotalCount, pageSize));
export const commissionHistoryProgressSelector = createSelector(getDepositStoreSelector, ({commissionHistoryProgress}) => commissionHistoryProgress);
export const commissionHistoryTotalCountSelector = createSelector(getDepositStoreSelector, ({commissionHistoryTotalCount}) => commissionHistoryTotalCount);
export const availableForPaymentsSelector = createSelector(getDepositStoreSelector, ({availableForPayments}) => availableForPayments);
export const progressDepositImportSelector = createSelector(getDepositStoreSelector, ({progressDepositImport}) => progressDepositImport);
export const getPaidApiListSelector = createSelector(getDepositStoreSelector, ({paidApiList}) => paidApiList);
export const getPaidApiTotalPagesSelector = createSelector(getDepositStoreSelector, ({paidApiTotalCount, paidApiPageData: {pageSize = 0}}) => getTotalPages(paidApiTotalCount, pageSize));
export const getPaidApiTotalCountSelector = createSelector(getDepositStoreSelector, ({paidApiTotalCount}) => paidApiTotalCount);
export const getPaidApiTotalAmountSelector = createSelector(getDepositStoreSelector, ({paidApiTotalAmount}) => paidApiTotalAmount);
export const getPaidApiProgressSelector = createSelector(getDepositStoreSelector, ({paidApiProgress}) => paidApiProgress);
export const depositProgressActionSelector = createSelector(getDepositStoreSelector, ({progressAction}) => progressAction);
export const depositProgressListSelector = createSelector(getDepositStoreSelector, ({progressList}) => progressList);
export const depositValuesProgressSelector = createSelector(getDepositStoreSelector, ({valuesProgress}) => valuesProgress);
//*  SAGA  *//

//POST /api/clientDeposit/getPage
export const getDepositListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DEPOSIT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DEPOSIT_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DEPOSIT_LIST_ERROR, payload: error});
    }
};

export const getDepositListV2Saga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${bffController}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DEPOSIT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DEPOSIT_LIST_V2_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DEPOSIT_LIST_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/addClientDeposit
export const addDepositSaga = function* (action) {
    try {
        const {
            onSuccess,
            ...reqData
        } = action.payload;

        const result = yield request.bff.post(`${bffController}/addDeposit`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DEPOSIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Депозит успешно добавлен");

        yield put({type: ADD_DEPOSIT_SUCCESS, payload: result});

        const state = yield select();

        yield put(getDepositListV2(getDepositPageDataSelector(state)));
    } catch (error) {
        toastError(error.message);

        yield put({type: ADD_DEPOSIT_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/getPageClientDepositHistory
export const getDepositHistoryListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPageClientDepositHistory/`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DEPOSIT_HISTORY_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DEPOSIT_HISTORY_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DEPOSIT_HISTORY_LIST_ERROR, payload: error});
    }
};

//POST api/clientDeposit/getHistoryPage
export const getDepositHistoryPageSaga = function* ({payload}) {
    try {
        const {
            data: {
                isCivil,
                ...reqData
            },
        } = payload;

        const url = isCivil ? "/civil/clientDeposit/getHistoryPage" : `${controller}/getHistoryPage`;

        const result = yield request.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DEPOSIT_HISTORY_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_DEPOSIT_HISTORY_PAGE_SUCCESS, payload: result});
    } catch (error) {
        toastError(error);

        yield put({type: GET_DEPOSIT_HISTORY_PAGE_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/updateClientDepositFavorite
export const updateClientDepositFavoriteSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                isCivil,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`/clients/${isCivil ? "civil/" : ""}updateClientDepositFavorite/`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_CLIENT_DEPOSIT_FAVORITE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({type: UPDATE_CLIENT_DEPOSIT_FAVORITE_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: UPDATE_CLIENT_DEPOSIT_FAVORITE_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/editClientCurrentCommissionRate
export const editClientCurrentCommissionRateSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            clientId,
            currentCommissionRate,
            fromDatetime,
            regionTime,
            currentCommissionRateDeferred,
        } = payload;

        const result = yield request.post(`${controller}/editClientCurrentCommissionRate`, {
            clientId,
            currentCommissionRate,
            fromDatetime,
            currentCommissionRateDeferred,
            regionTime,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: EDIT_CLIENT_CURRENT_COMMISSION_RATE_ERROR});

            return {
                done: true,
            };
        }

        const state = yield select();

        yield put(getDepositListV2(getDepositPageDataSelector(state)));

        toastSuccess("Процентная ставка изменена");

        yield put({type: EDIT_CLIENT_CURRENT_COMMISSION_RATE_SUCCESS});
    } catch (error) {
        yield put({type: EDIT_CLIENT_CURRENT_COMMISSION_RATE_ERROR, payload: error});
    }
};

// POST /api/clientDeposit/getPageClientDepositCommissionHistory
export const getCommissionHistorySaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${bffController}/getClientDepositCommissionHistoryRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DEPOSIT_COMMISSION_RICH_HISTORY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_DEPOSIT_COMMISSION_RICH_HISTORY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DEPOSIT_COMMISSION_RICH_HISTORY_ERROR, payload: error});
    }
};

// POST /api/contractorCommission/history/getPage
export const getContractorCommissionHistorySaga = function* ({payload}) {
    try {
        const {
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${isCivil ? bffCivilController : bffController}/getContractorCommissionHistoryRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/editClientDepositHistory
export const editDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/editClientDepositHistory/`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDIT_DEPOSIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Изменения успешно сохранены");

        yield put({type: EDIT_DEPOSIT_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: EDIT_DEPOSIT_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/returnClientDeposit
export const returnDepositSaga = function* (action) {
    try {
        const {
            onSuccess,
            ...reqData
        } = action.payload;
        const result = yield request.bff.post(`${bffController}/returnDeposit`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RETURN_DEPOSIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Возврат средств проведен успешно");

        yield put({type: RETURN_DEPOSIT_SUCCESS, payload: result});

        const state = yield select();

        yield put(getDepositListV2(getDepositPageDataSelector(state)));
    } catch (error) {
        toastError(error.message);

        yield put({type: RETURN_DEPOSIT_ERROR, payload: error});
    }
};

//POST  /api/clientDeposit/updateClientDepositHistoryComment
export const updateDepositCommentSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/updateClientDepositHistoryComment/`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_DEPOSIT_COMMENT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");
        onSuccess();

        yield put({type: UPDATE_DEPOSIT_COMMENT_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: UPDATE_DEPOSIT_COMMENT_ERROR, payload: error});
    }
};

//GET /api/clientDeposit/getDepositValues
export const getClientDepositValuesSaga = function* ({payload}) {
    try {
        const {
            isCivil,
            clientId,
        } = payload;

        const url = isCivil ? "/civil/clientDeposit/getDepositValues" : `${controller}/getDepositValues`;

        const result = yield request.get(url, {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_DEPOSIT_VALUES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_DEPOSIT_VALUES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CLIENT_DEPOSIT_VALUES_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/correct/{clientId}
export const correctClientDepositSaga = function* ({payload}) {
    try {
        const {clientId, onSuccess} = payload;

        const result = yield request.post(`${controller}/correct/${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CORRECT_CLIENT_DEPOSIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: CORRECT_CLIENT_DEPOSIT_SUCCESS});
    } catch (error) {
        yield put({type: CORRECT_CLIENT_DEPOSIT_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/v2/getAvailableForPayments
//POST /api/civil/clientDeposit/v2/getAvailableForPayments
export const getAvailableForPaymentsSaga = function* ({payload}) {
    try {
        const {
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.post(`${isCivil ? "/civil" : ""}${controllerV2}/getAvailableForPayments`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_AVAILABLE_FOR_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_AVAILABLE_FOR_PAYMENTS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_AVAILABLE_FOR_PAYMENTS_ERROR, payload: error});
    }
};

//GET /api/clientDeposit/v2/getDepositValues
export const getClientDepositValuesV2Saga = function* ({payload}) {
    try {
        const {
            isCivil = false,
            clientId,
            projectId,
            objectId,
        } = payload;

        const data = {
            clientId,
            projectId,
            objectId,
        };

        const result = yield request.post(`${isCivil ? "/civil" : ""}${controller}/v2/getDepositValues`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DEPOSIT_VALUES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_DEPOSIT_VALUES_SUCCESS, payload: {isCivil, result}});
    } catch (error) {
        yield put({type: GET_DEPOSIT_VALUES_ERROR, payload: error});
    }
};

//POST /api/job/clientDeposit/object/import/{clientId}
export const importDepositFromFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            formData: data,
            onSuccess = () => {
            },
        } = payload;

        const result = yield call(axios, {
            url: `/api/job${controller}/object/import/${clientId}`,
            method: "post",

            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data,
        },
        );

        const {
            data: {
                errorMessage,
            },
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: IMPORT_DEPOSIT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Загрузка файла успешно завершена. Депозит по заданным объектам будет пополнен через некоторое время");

        const state = yield select();
        yield put(getProjectsList({...projectPageDataSelector(state)}));

        yield put({type: IMPORT_DEPOSIT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: IMPORT_DEPOSIT_ERROR, payload: error});
    }
};

//POST /api/clientDeposit/paidApi/page
export const getPaidApiPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/paidApi/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAID_API_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PAID_API_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAID_API_PAGE_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(DEPOSIT_LIST_REQUEST, getDepositListSaga),
        takeEvery(DEPOSIT_LIST_V2_REQUEST, getDepositListV2Saga),
        takeEvery(ADD_DEPOSIT_REQUEST, addDepositSaga),
        takeEvery(DEPOSIT_HISTORY_LIST_REQUEST, getDepositHistoryListSaga),
        takeEvery(EDIT_CLIENT_CURRENT_COMMISSION_RATE_REQUEST, editClientCurrentCommissionRateSaga),
        takeEvery(UPDATE_CLIENT_DEPOSIT_FAVORITE_REQUEST, updateClientDepositFavoriteSaga),
        takeEvery(GET_DEPOSIT_COMMISSION_RICH_HISTORY_REQUEST, getCommissionHistorySaga),
        takeEvery(EDIT_DEPOSIT_REQUEST, editDepositSaga),
        takeEvery(RETURN_DEPOSIT_REQUEST, returnDepositSaga),
        takeEvery(UPDATE_DEPOSIT_COMMENT_REQUEST, updateDepositCommentSaga),
        takeEvery(GET_CLIENT_DEPOSIT_VALUES_REQUEST, getClientDepositValuesSaga),
        takeEvery(GET_DEPOSIT_HISTORY_PAGE_REQUEST, getDepositHistoryPageSaga),
        takeEvery(CORRECT_CLIENT_DEPOSIT_REQUEST, correctClientDepositSaga),
        takeEvery(GET_DEPOSIT_CONTRACTOR_COMMISSION_RICH_HISTORY_REQUEST, getContractorCommissionHistorySaga),
        takeEvery(GET_DEPOSIT_VALUES_REQUEST, getClientDepositValuesV2Saga),
        takeEvery(GET_AVAILABLE_FOR_PAYMENTS_REQUEST, getAvailableForPaymentsSaga),
        takeEvery(IMPORT_DEPOSIT_REQUEST, importDepositFromFileSaga),
        takeEvery(GET_PAID_API_PAGE_REQUEST, getPaidApiPageSaga),
    ]);
}
