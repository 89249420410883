import {
    BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_REQUEST,
    BFF_BOT_UPDATE_STORE,
} from "./actions";

export const deleteAllBotSubscriptions = (payload) => {
    return {
        type: BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_REQUEST,
        payload,
    };
};

export const updateBotStore = (payload) => {
    return {
        type: BFF_BOT_UPDATE_STORE,
        payload,
    };
};