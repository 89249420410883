import React, {FC} from "react";

import {OptionType} from "../../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../../utils/bem";

import "./style.sass";

interface IDropdownWithInputOptionsContainer {
    isEmptyValues?: boolean,
    options: Array<OptionType>,
    addValue: (data: OptionType) => void,
}

export const DropdownWithInputOptionsContainer: FC<IDropdownWithInputOptionsContainer> = React.memo((props) => {
    const {
        isEmptyValues,
        options,
        addValue,
    } = props;

    const [block] = bem("dropdown-with-input-options-container");

    return (
        <div
            className={block({
                notEmptyValues: !isEmptyValues,
            })}
            onKeyDown={(event) => {
                console.log(event.key);
            }}
        >
            {
                options.map((item, index) => {
                    return (
                        <div
                            tabIndex={index}
                            key={item.key}
                            className="dropdown-with-input-options-container__option"
                            onClick={() => {
                                addValue(item);
                            }}
                        >
                            {item.text}
                        </div>
                    );
                })
            }
        </div>
    );
});
