import {useState} from "react";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import {filterFinanceWarningPayments} from "../utils/filterPayments";
import {formatFinancePaymentWarningCheckList} from "../utils/formatList";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

export const RADIO_TYPE = {
    DECLINE: "DECLINE", // Отменить
    SET_ASIDE: "SET_ASIDE", // Отложить
};

export const useFinancePaymentListWarningCheck = (params) => {
    const {
        warningPayments = [],
        onClose,
        confirmedPayments,
        confirmedDuplicatedPayments = [],
    } = params;

    const [selectedList, setSelectedList] = useState(formatFinancePaymentWarningCheckList(warningPayments));

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        handleBlur,
        touched,
    } = useFormik({
        initialValues: {
            solution: "",
            reasonForRefusal: "",
            selectedCount: 0,
        },
        onSubmit,
        validationSchema: yup.object().shape({
            solution: yup.string()
                .test("checkSelectedItems", "Обязательно для выбора позиции", function (value) {
                    if (this.parent.selectedCount < selectedList.length && !value) {
                        return false;
                    }

                    return true;
                }),
            reasonForRefusal: yup.string()
                .when("solution", {
                    is: RADIO_TYPE.DECLINE,
                    then: yup.string()
                        .required(VALIDATIONS_MESSAGE.REQUIRED),
                }),
        }),
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit() {
        // выплаты, выбранные чекбоксами - на подтверждение
        const selectedPayments = selectedList.filter(value => value.isSelected).map(value => {
            return {
                clientId: value.clientId,
                orderId: value.orderId,
                paymentNumber: value.paymentNumber,
            };
        });
        // необходимо подфильтровать список платежей дубликатов, т к в списке с варнингами могут быть повторения
        const resultConfirmedDuplicatedPayments = filterFinanceWarningPayments(confirmedDuplicatedPayments, selectedPayments);
        // выплаты с дубликатов + выбранные выплаты на подтверждение
        const listPayments = [...resultConfirmedDuplicatedPayments, ...selectedPayments];

        // выбран радиобатон - отменить
        if (values.solution === RADIO_TYPE.DECLINE) {
            // выплаты, не выбранные чекбоксами - на отмену
            const rejectPayments = {
                payments: selectedList.filter(value => !value.isSelected).map(value => {
                    return {
                        clientId: value.clientId,
                        orderId: value.orderId,
                        paymentNumber: value.paymentNumber,
                    };
                }),
                reasonForRefusal: values.reasonForRefusal,
            };

            params.onSubmit({rejectPayments, listPayments});
            onClose();

            return;
        }

        // выбран радиобатон - отложить
        if (values.solution === RADIO_TYPE.SET_ASIDE) {
            params.onSubmit({listPayments});
            onClose();

            return;
        }

        params.onSubmit({listPayments});
        onClose();
    }

    const onSelectedRows = (list) => {
        const selectedCount = list.filter(value => value.isSelected).length;

        // Обнуляем радиобатон, если все записи выбраны
        if (selectedCount === selectedList.length && values.solution) {
            setFieldValue("solution", "");
        }

        setFieldValue("selectedCount", selectedCount);
        setSelectedList(list);
    };

    const getConfirmedCount = () => {
        const filtered = filterFinanceWarningPayments(confirmedDuplicatedPayments, warningPayments);

        if (isEmpty(confirmedPayments)) {
            return filtered.length;
        }

        return confirmedPayments.length + filtered.length;
    };

    return {
        onSelectedRows,
        selectedList,
        selectedCount: values.selectedCount,
        confirmedCount: getConfirmedCount(),
        touched,
        values,
        errors,
        onChange,
        handleSubmit,
        handleBlur,
    };
};