import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ActRegistryLog from "../../../../components/ActRegistryLog";

import {
    clearRegistryPaymentLog,
    getRegistryPaymentLog,
    registryPaymentLogListSelector,
    registryPaymentLogTotalCountSelector,
    registryPaymentLogTotalPagesSelector,
} from "../../../../ducks/registryPayments";

function RegistriesListLog(props) {
    const dispatch = useDispatch();

    const list = useSelector(registryPaymentLogListSelector);
    const totalPages = useSelector(registryPaymentLogTotalPagesSelector);
    const totalCount = useSelector(registryPaymentLogTotalCountSelector);

    return (
        <ActRegistryLog
            {...props}
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            onClearStore={() => {
                dispatch(clearRegistryPaymentLog());
            }}
            onFetchList={(data) => {
                dispatch(getRegistryPaymentLog(data));
            }}
        />
    );
}

export default RegistriesListLog;