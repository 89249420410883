import React from "react";
import {Route, Switch, withRouter} from "react-router";

import CrowdTaskRegistry from "../../crowd/task-registry";
import Objects from "../../objects";
import OrderList from "../../order/order-list";
import ProjectsTeamList from "../projects-team-list";

import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST} from "../../../constants/links";


const ProjectsRoute = ({hideDetails}) => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_ORDER_LIST}
                render={
                    (props) =>
                        <OrderList
                            {...props}
                            projectObjectHideDetails={hideDetails}
                        />
                }
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST}
                render={(props) =>
                    <Objects
                        {...props}
                        hideDetailsProjectCard={hideDetails}
                    />
                }
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_TEAM_LIST}
                component={ProjectsTeamList}
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_TASK_LIST}
                component={CrowdTaskRegistry}
            />
        </Switch>
    );
};

export default withRouter(ProjectsRoute);