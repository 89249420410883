export const DOCUMENT_EXPORT_UPLOAD_STATUS = {
    INITIALIZATION: {
        TEXT: "Инициализация",
        VALUE: "INITIALIZATION",
        MOD: "gray",
    },
    SUCCESSFULLY: {
        TEXT: "Успешно",
        VALUE: "SUCCESSFULLY",
        MOD: "green",
    },
    UNSUCCESSFUL: {
        TEXT: "Неуспешно",
        VALUE: "UNSUCCESSFUL",
        MOD: "red",
    },
    CANCELLED: {
        TEXT: "Отменено",
        VALUE: "CANCELLED",
        MOD: "red",
    },
};

export const DOCUMENT_EXPORT_STATUS = {
    IN_PROGRESS: {
        TEXT: "В работе",
        VALUE: "IN_PROGRESS",
        MOD: "light-green",
    },
    FINISHED: {
        TEXT: "Успешно",
        VALUE: "FINISHED",
        MOD: "green",
    },
    FAILED: {
        TEXT: "Неуспешно",
        VALUE: "FAILED",
        MOD: "red",
    },
    CANCELLED: {
        TEXT: "Отменено",
        VALUE: "CANCELLED",
        MOD: "red",
    },
};