import React, {useEffect} from "react";

import {ReactComponent as MailIcon} from "../../images/mail_24.svg";
import {ReactComponent as PhoneIcon} from "../../images/phone_24.svg";
import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import NmButton from "../NmButton";
import NmReactCodeInput from "../NmReactCodeInput";
import NmTitle from "../NmTitle";

import bem from "../../utils/bem";
import {convertSecToMinutes} from "../../utils/dateFormat";
import {phoneFormat} from "../../utils/stringFormat";

import {COLOR} from "../../constants/color";
import {PHONE_CODE_TYPE} from "../../constants/phone";

import "./style.sass";

export type CodeConfirmType = {
    className?: string,
    title?: string,
    onClose: (flag: boolean) => void,
    sendConfirmationCodeToUser: (codeType?: string) => void,
    submitCode: () => void,
    errorMessage?: string,
    onChange: (e: any, value: any) => void,
    phone: string,
    timer: number,
    code?: string,
    loading: boolean,
    codeConfirmType?: string,
};

const CodeConfirm = (props: CodeConfirmType) => {
    const {
        className = "",
        title = "Подписание заявления",
        onClose = () => {
        },
        sendConfirmationCodeToUser,
        errorMessage,
        onChange,
        code,
        phone,
        timer,
        loading,
        submitCode,
        codeConfirmType,
    } = props;

    const [block, element] = bem("code-confirm", className);

    const handleClose = (): void => {
        onClose(false);
    };

    const handleKeyPress = ({key}: any) => {
        if (key === "Enter") {
            submitCode();
        }
    };

    const getText = () => {
        if (codeConfirmType === PHONE_CODE_TYPE.SMS) {
            return `СМС с кодом отправлено на номер ${phoneFormat(phone)}. Введите код из СМС`;
        }

        if (codeConfirmType === PHONE_CODE_TYPE.FLASH_CALL) {
            return `Звонок направлен на номер ${phoneFormat(phone)}. Введите последние 4 цифры номера`;
        }

        return "";
    };

    return (
        <NmModal
            size="sm"
            onClose={handleClose}
            header={
                <NmTitle
                    className={element("title")}
                    size="md"
                >
                    {title}
                </NmTitle>
            }
            loading={loading}
            footer={
                <div className={element("footer")}>
                    <NmButton
                        type={"submit"}
                        disabled={loading}
                        className={element("confirm-button")}
                        onClick={submitCode}
                        size="xl"
                    >
                        Подтвердить
                    </NmButton>
                    <Text.Sub
                        level="1"
                        className={element("timer")}
                    >
                        {
                            timer !== 0 ?
                                `Запросить новый код через ${convertSecToMinutes(timer)}` :
                                "Отправить код через"
                        }
                    </Text.Sub>
                    {
                        !timer &&
                        <ApplyButtons
                            mobile="column"
                            className="col-16 mt-6"
                            classNameMainButtons={`fluid flex-content-spaced ${element("send-code-buttons")}`}
                            onClose={() => sendConfirmationCodeToUser(PHONE_CODE_TYPE.SMS)}
                            submit={() => sendConfirmationCodeToUser(PHONE_CODE_TYPE.FLASH_CALL)}
                            submitBtnIcon={<PhoneIcon color={COLOR.WHITE} />}
                            submitBtnContent="Звонок"
                            cancelBtnContent="СМС"
                            cancelBtnIcon={<MailIcon color={COLOR.SECONDARY_45} />}
                        />
                    }
                </div>
            }
        >
            <NmForm
                className={block()}
                onKeyPress={handleKeyPress}
            >
                <Text
                    level="4"
                    className={element("phone-info")}
                >
                    {getText()}
                </Text>
                <div className={element("code")}>
                    <NmReactCodeInput
                        value={code}
                        errorMessage={errorMessage}
                        onChange={onChange}
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};

export default CodeConfirm;