import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

const validationAddGroupCompanies = () => {
    return yup.object().shape({
        name: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        description: yup.string(),
    });
};

export default validationAddGroupCompanies;