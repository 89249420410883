import React, {useContext} from "react";

import {BooleanAttributeEdit} from "../../../../BooleanAttributeEdit";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

export const RecruitmentCandidateHasCarEdit = (props) => {
    const {
        onClose,
        initialValue,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    const onSubmit = ({value: hasCar}) => {
        updateInformation({
            hasCar,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    };

    return (
        <BooleanAttributeEdit
            title="Есть свой автомобиль"
            labelTrue="Есть свой автомобиль"
            labelFalse="Нет своего автомобиля"
            initialValue={initialValue}
            progress={progressAction}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};
