import {postman} from "./postman";

const dbList = [
    {name: "fcm_vapid_details_db"},
    {name: "firebase-installations-database"},
    {name: "fcm_token_details_db"},
];
export const clearAllFirebaseIndexedDb = (index) => {
    const req = indexedDB.deleteDatabase(dbList[index].name);
    req.onerror = function () {
        console.log("Couldn't delete database");
    };
    req.onsuccess = function () {
        index++;
        if (index < dbList.length) {
            clearAllFirebaseIndexedDb(index);
        } else {
            //window.firebase.initializeApp(window.firebaseConfig);
        }
    };

    indexedDB.databases().then(r => console.log(r));
};

export async function addByFirebaseTokenAndClientUserId(index) {
    try {
        const messaging = window.firebase.messaging();

        async function addAsync() {
            const token = await messaging.getToken();

            const result = await postman.get("push/token/addByFirebaseTokenAndClientUserId", {
                params: {
                    token,
                },
            });

            const {errorMessage} = result;

            if (errorMessage) {
                return Promise.reject(errorMessage);
            }

            return Promise.resolve(result);
        }

        messaging.onTokenRefresh(async function () {
            await addAsync();
        });

        return addAsync();
    } catch (e) {
        return Promise.reject(e);
    }
};

/***
 * Поддерживается ли чат браузером
 * @returns {boolean}
 */
export const isChatSupported = () => window.firebase?.messaging.isSupported();


