import React from "react";

import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text from "../ActualComponents/Text";
import NmCheck from "../NmCheck";

import dateFormat from "../../utils/dateFormat";

import {COLOR} from "../../constants/color";
import {
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS,
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_DESCRIPTION,
} from "../../constants/contractor";

const ContractorIndividualEntrepreneurIndicator = (props) => {
    const {
        status,
        statusDate,
        className = "",
    } = props;

    const date = Boolean(statusDate) && status === CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED ?
        `от ${dateFormat(statusDate, "dd.MM.yyyy")}` :
        "";

    return (
        <div className={`flex ${className}`}>
            <NmAdvancedTooltip
                hover={true}
                position="bottom-left"
                trigger={
                    status &&
                    <NmCheck
                        isNotPresent={status === CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED}
                        isCheck={status === CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED}
                    />
                }
                children={`${CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_DESCRIPTION[status]} ${date}`}
            />
            <Text
                className="ms-2"
                level="3"
                color={COLOR.SECONDARY_90}
            >
                Статус ИП
            </Text>
        </div>
    );
};

export default ContractorIndividualEntrepreneurIndicator;