import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ImportFromFilePatternV2 from "../../../../components/ActualComponents/ImportFromFilePatternV2";
import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import ContractorPassportStatus from "../../../../components/ContractorPassportStatus";
import ExtLink from "../../../../components/ExtLink";
import InvitePerformerToNaimix from "../../../../components/InvitePerformerToNaimix";
import LocatedOutsideRussiaTooltip from "../../../../components/LocatedOutsideRussiaTooltip";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import Task from "../../../../components/NmTask";
import RegistryCardButton from "../../../../components/RegistryCardButton";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import {ReactComponent as AddBoxIcon} from "../../../../images/add_box.svg";
import {ReactComponent as IconClose} from "../../../../images/close.svg";
import {ReactComponent as IconDone} from "../../../../images/done_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../images/file_download.svg";
import {ReactComponent as UploadIcon} from "../../../../images/file_upload.svg";
import {ReactComponent as UserIcon} from "../../../../images/user_24.svg";
import InvitationContractorEditForm from "./components/edit-contractor-form";
import RegistryInvitationsCardFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useSelectedList} from "../../../../hooks/useSelectedList";
import useRegistryInvitationsCardAction from "./hooks/useAction";
import {useRegistryInvitationsFetchData} from "./hooks/useFetchData";
import {useRegistryInvitationsCardFilterDto} from "./hooks/useRegistryInvitationsCardFilterDto";

import dateFormat from "../../../../utils/dateFormat";
import {getFcStatusForRegistryItem} from "../../../../utils/fcRegistries";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {phoneFormat} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST, LINK_ORDER_CARD} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR} from "../../../../constants/roles";

import {history} from "../../../../store/configureStore";

import {clientCurrentMemberSelector} from "../../../../ducks/clientMember";
import {avatarBase64ImagesListSelector} from "../../../../ducks/fileStore";
import {
    getRegistryInvitationsActionProgressSelector,
    getRegistryInvitationsCardSelector,
    getRegistryInvitationsProgressSelector,
} from "../../../../ducks/registryInvitations";
import {
    getRegistryInvitationsContractorsActionProgressSelector,
    getRegistryInvitationsContractorsListSelector,
    getRegistryInvitationsContractorsProgressSelector,
    getRegistryInvitationsContractorsTotalCountSelector,
    getRegistryInvitationsContractorsTotalPagesSelector,
} from "../../../../ducks/registryInvitationsContractors";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../../constants/link-params";

export const REGISTRY_INVITATIONS_ITEM_STATUS = {
    DRAFT: "DRAFT",
    FAILED: "FAILED",
    DECLINED: "DECLINED",
    AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: "AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING",
    AWAITING_CONTRACTOR_CONFIRMATION: "AWAITING_CONTRACTOR_CONFIRMATION",
    HIRED: "HIRED",
    COMPLETED: "COMPLETED",
    CLIENT_PREAPPROVED: "CLIENT_PREAPPROVED",
};

const STATUS_COLOR_TRANSCRIPT = {
    DRAFT: "gray",
    FAILED: "red",
    DECLINED: "red",
    AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: "orange",
    AWAITING_CONTRACTOR_CONFIRMATION: "orange",
    AWAITING_CLIENT_CONFIRMATION: "orange",
    HIRED: "green",
    COMPLETED: "gray",
    CLIENT_PREAPPROVED: "orange",
    CONTRACTOR_REVOKED: "red",
};

const initFilterForm = {
    fioFilter: "",
    phoneFilter: "",
    serialNumber: "",
    orderNumFilter: "",
    contractFrameStatusFilter: null,
    paymentAbility: null,
};

function RegistryInvitationsCard(props) {
    const {
        match: {
            params: {
                clientId,
                registryInvitationId,
            },
        },
    } = props;

    const card = useSelector(getRegistryInvitationsCardSelector);
    const list = useSelector(getRegistryInvitationsContractorsListSelector);
    const totalCount = useSelector(getRegistryInvitationsContractorsTotalCountSelector);
    const totalPages = useSelector(getRegistryInvitationsContractorsTotalPagesSelector);
    const progressCard = useSelector(getRegistryInvitationsProgressSelector);
    const progressList = useSelector(getRegistryInvitationsContractorsProgressSelector);
    const progressRegistryAction = useSelector(getRegistryInvitationsActionProgressSelector);
    const progressContractorsAction = useSelector(getRegistryInvitationsContractorsActionProgressSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    const role = ls(USER_ROLE);
    const isAccessAction = ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);

    const {
        title,
        comment,
        serialNumber,
        archived,
    } = card;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        filter,
        onChangeFilter,
        setFilter,
    } = useFilter({initFilter: initFilterForm});

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useRegistryInvitationsCardFilterDto();

    const {
        fetchList,
        fetchTasks,
    } = useRegistryInvitationsFetchData({
        list,
        clientId,
        registryInvitationId,
        pageNum,
        pageSize,
        filter: filterDto,
    });

    const {
        setIsInviteViaEmailOpen,
        isInviteViaEmailOpen,
        setIsImportModalOpen,
        isImportModalOpen,
        onClickRunRegistry,
        onClickExportRegistry,
        onSubmitImportRegistryInvitationsContractors,
        deleteRegistryItems,
        isOpenInvitationContractorEditForm,
        setIsOpenInvitationContractorEditForm,
        editItem,
        onCloseEditForm,
        onClickEditRegistryItem,
        isOpenWarningConfirm,
        onConfirm,
        closeConfirm,
    } = useRegistryInvitationsCardAction({
        clientId,
        registryInvitationId,
        fetchList,
        clearSelectedRows,
        fetchTasks,
    });

    const handleOnClickBack = () => {
        history.push(LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST.replace(":clientId", clientId));
    };

    const getHeaderMediaControls = () => {
        return {
            renderCount: {
                desktop: 2,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: () => onClickRunRegistry(),
                        children: "Передать в работу",
                    },
                    visible: !archived && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onClick: onClickExportRegistry,
                        children: "Выгрузить реестр",
                        icon: <UploadIcon />,
                    },
                    visible: !archived && isAccessAction,
                },
            ],
        };
    };

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setFilter({...initFilterForm});
        setFilterDto({...initFilterForm});
        setIsSearch(false);
    };

    const getRDContent = (item) => {
        const {contractFrameDate, actualFrameContract} = item;
        const msg = getFcStatusForRegistryItem(item);

        return (
            <NmAdvancedTooltip
                trigger={
                    <div className="registry-card__fc-status">
                        {
                            actualFrameContract ?
                                <IconDone className="registry-card__fc-status-done" /> :
                                <IconClose className="registry-card__fc-status-error" />
                        }
                        {dateFormat(contractFrameDate, "dd.MM.yyyy")}
                    </div>
                }
                hover
            >
                {msg}
            </NmAdvancedTooltip>
        );
    };

    const getMediaActions = (item) => {
        if (!isAccessAction) {
            return null;
        }

        const {
            shortStatus,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onClickEditRegistryItem(item),
                        children: "Редактировать",
                    },
                    visible: !archived
                        && [
                            REGISTRY_INVITATIONS_ITEM_STATUS.DRAFT,
                            REGISTRY_INVITATIONS_ITEM_STATUS.FAILED,
                        ].includes(shortStatus),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onClickRunRegistry(item.baseModel.serialNumber);
                        },
                        children: "Передать в работу",
                    },
                    visible: !archived
                        && [
                            REGISTRY_INVITATIONS_ITEM_STATUS.DRAFT,
                            REGISTRY_INVITATIONS_ITEM_STATUS.FAILED,
                            REGISTRY_INVITATIONS_ITEM_STATUS.DECLINED,
                        ].includes(shortStatus),
                },
            ],
        };
    };

    const renderStatus = ({shortStatus, shortStatusTitle, baseModel: {statusDescription}}) => {
        return (
            <NmBadge
                condition
                mod={STATUS_COLOR_TRANSCRIPT[shortStatus]}
                text={shortStatusTitle}
                description={statusDescription}
            />
        );
    };

    const renderOrderLink = ({orderName, baseModel}) => {
        const {
            orderNum,
            orderId,
        } = baseModel;

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        const orderText = orderNum ? `${orderNum} - ${orderName}` : "-";

        return (
            orderId ?
                <ExtLink
                    to={orderLink}
                    pageData={{pageNum, pageSize}}
                    historyEnabled
                >
                    {orderText}
                </ExtLink> :
                orderText
        );
    };


    const getRows = () => {
        return list.map(item => {
            const {
                baseModel: {
                    contractorId,
                    serialNumber,
                },
                contractorFio,
                contractorPhone,
                locatedOutsideRussia,
            } = item;

            const {isSelected = false} = selectedList.find(item => (item.serialNumber === serialNumber)) || {};

            return {
                ...item,
                key: item.serialNumber,
                serialNumber,
                showCheckBox: true,
                isSelected,
                avatar: (
                    <Avatar
                        addedToMyClientGroup={item.addedToMyClientGroup}
                        contractorId={contractorId}
                        base64={userAvatarDict[contractorId]}
                    />
                ),
                contentRow: (
                    <NmListCard
                        avatar
                        checkbox
                        noDivider
                        fluidPrimaryHeader
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-14 col-xxl-8"
                        primaryHeader={contractorFio}
                        actionsClassName="col-1 col-xxl-8 justify-content-end"
                        labels={[
                            {label: "Номер и наименование заказа", text: renderOrderLink(item)},
                            {label: "Номер строки", text: serialNumber},
                            {
                                label: "Телефон",
                                text: phoneFormat(contractorPhone, locatedOutsideRussia),
                                classNameText: "flex align-items-center",
                                textOverflowUnset: true,
                                textTooltip: locatedOutsideRussia && <LocatedOutsideRussiaTooltip />,
                            },
                            {label: "РД", text: getRDContent(item), alignItems: "center", noWrap: false},
                            {
                                label: "Паспорт проверен",
                                text: (
                                    <ContractorPassportStatus
                                        contractor={item}
                                        size="md"
                                        isOnlyIcon
                                    />
                                ),
                                alignItems: "center",
                                noWrap: false,
                            },
                        ]}
                        secondaryHeaderStatus={renderStatus(item)}
                        mediaControls={getMediaActions(item)}
                    />
                ),
            };
        });
    };

    const renderInvitePerformerToNaimix = () => {
        return isInviteViaEmailOpen &&
            <InvitePerformerToNaimix
                close={() => setIsInviteViaEmailOpen(false)}
                clientId={clientId}
            />;
    };

    const onClickDeleteRegistryItems = () => {
        const requestData = selectedList.filter(({isSelected}) => isSelected).map((
            {
                baseModel: {
                    registryInvitationId,
                    clientId,
                    serialNumber,
                },
            }) => ({clientId, registryInvitationId, serialNumber}));

        deleteRegistryItems(requestData);
    };

    const renderImportForm = () => {
        return isImportModalOpen &&
            <ImportFromFilePatternV2
                patternLink="/files/Шаблон_Реестр приглашений.xlsx"
                onSubmit={onSubmitImportRegistryInvitationsContractors}
                onClose={() => setIsImportModalOpen(false)}
                progress={progressContractorsAction}
            />;
    };

    const renderEditForm = () => {
        return isOpenInvitationContractorEditForm &&
            <InvitationContractorEditForm
                registryInvitationId={registryInvitationId}
                clientId={clientId}
                isEdit={!isEmpty(editItem)}
                onClose={onCloseEditForm}
                editItem={editItem}
                fetchList={fetchList}
            />;
    };

    const renderWarningConfirm = () => {
        const content = "Обращаем внимание, что вы собираетесь заключить рамочный договор " +
            "с иностранным гражданином (лицом без гражданства). В соответствии с законодательством РФ " +
            "необходимо уведомить территориальное подразделение МВД России по вопросам миграции " +
            "о заключении договора с иностранным гражданином (лицом без гражданства) " +
            "в течение 3 рабочих дней с даты заключения такого договора.";
        
        return isOpenWarningConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={closeConfirm}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />;
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    text={`Реестр приглашений на заказы №${serialNumber || ""}`}
                    handleOnClickBack={handleOnClickBack}
                />
            }
            mediaControls={getHeaderMediaControls()}
            subHeader={
                <>
                    <div className="col-16 col-xxl-9">
                        <Text.Title level="4">
                            {title}
                        </Text.Title>
                    </div>
                    <Text level="3">
                        {comment}
                    </Text>
                </>
            }
            typeFilter="vertical"
            filtersBase={
                <RegistryInvitationsCardFilter
                    filter={filter}
                    onChange={onChangeFilter}
                    submitFilter={submitFilter}
                    clearFilter={clearFilter}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalCount={totalCount}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressCard || progressList || progressRegistryAction}
        >
            <Task />
            <div className="flex mb-4">
                <Text.Title
                    level="1"
                >
                    Исполнители
                </Text.Title>
                {
                    isAccessAction && !archived &&
                    <>
                        {
                            !currentMember.contractorInvitationProhibited &&
                            <>
                                <RegistryCardButton
                                    onClick={() => {
                                        setIsOpenInvitationContractorEditForm(true);
                                    }}
                                    className="registry-card__page-button"
                                    icon={<AddBoxIcon className="registry-card__page-button-icon" />}
                                >
                                    Добавить исполнителя
                                </RegistryCardButton>
                                <RegistryCardButton
                                    onClick={() => setIsImportModalOpen(true)}
                                    className="registry-card__page-button"
                                    icon={<FileDownloadIcon className="registry-card__page-button-icon" />}
                                >
                                    Загрузить список исполнителей
                                </RegistryCardButton>
                            </>
                        }
                        <RegistryCardButton
                            onClick={() => setIsInviteViaEmailOpen(true)}
                            className="registry-card__page-button"
                            icon={<UserIcon className="registry-card__page-button-icon" />}
                        >
                            Пригласить исполнителя в Наймикс
                        </RegistryCardButton>
                    </>

                }
            </div>
            {renderWarningConfirm()}
            {renderEditForm()}
            {renderImportForm()}
            {renderInvitePerformerToNaimix()}
            {
                list?.length ?
                    <CheckboxList
                        rows={getRows()}
                        header={
                            <SelectionCountWithAction
                                adaptiveLogic
                                count={countSelected}
                                buttonColor="grey"
                                buttonContent={!archived && "Удалить строку"}
                                onClick={onClickDeleteRegistryItems}
                            />
                        }
                        isCheckBox
                        onSelectedRows={isAccessAction && handleSelectedRows}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
}

export default RegistryInvitationsCard;