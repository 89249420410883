import React from "react";

import HelpTooltip from "../../ActualComponents/HelpTooltip";
import {RecruitmentAccountDropdown} from "../AccountDropdown";

export const RecruitmentAccountDropdownWithTooltip = (props) => {
    return (
        <RecruitmentAccountDropdown
            {...props}
            isVisibleTooltip={true}
            tooltip={
                <HelpTooltip
                    info
                    hover
                    text={"После создания вакансии можно изменить через операцию \"Изменение аккаунта\""}
                    position="bottom-left"
                />
            }
        />
    );
};