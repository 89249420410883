import {
    BFF_RECRUITMENT_ACCESS_CONTROL_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_UPDATE_STORE,
} from "./actions";

export const getTotalCountsRecruitmentAccessControl = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
        payload,
    };
};

export const getRecruitmentAccessControlCanSetCandidateStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_REQUEST,
        payload,
    };
};

export const updateRecruitmentAccessControlStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_UPDATE_STORE,
        payload,
    };
};

export const clearRecruitmentAccessControlStore = () => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_CLEAR_STORE,
    };
};