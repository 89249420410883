export const CHAT_LINK_PARAMS = {
    LINK_STATUS_PARAM: ":status",
    LINK_PAGENUMBER_PARAM: ":pageNumber",
    LINK_PAGESIZE_PARAM: ":pageSize",
    LINK_DISPUTEID_PARAM: ":disputeId?",
    LINK_CHATID_PARAM: ":chatId?",
    LINK_ORDERID_PARAM: ":orderId?",
    LINK_CLIENTID_PARAM: ":clientId",
    LINK_NEW_CHAT_PARAM: ":newChatContractorId",
    OPEN: "open",
    CLOSED: "close",
    CANCEL: "cancel",
};

export const DISPUTES_LS_PARAMS = {
    DISPUTES_LIST_PAGE_NUMBER: "disputesListPageNumber",
    DISPUTES_LIST_PAGE_SIZE: "disputesListPageSize",
    DISPUTES_LIST_STATUS: "disputesListStatus",
};

export const CHAT_LS_PARAMS = {
    CHAT_LIST_BACK_LINK: "chatListBackLink",
};

export const DISPUTE_STATUS = {
    APPROVED: "APPROVED",
    REFUSED: "REFUSED",
    CANCELED: "CANCELED",
};

export const DISPUTE_STATUS_RESULT_MSG = {
    [DISPUTE_STATUS.APPROVED]: "Одобрено. ",
    [DISPUTE_STATUS.REFUSED]: "Отклонено. ",
    [DISPUTE_STATUS.CANCELED]: "Самозанятый отменил спор",
};

export const DISPUTE_FIELD = {
    CAUSE_DISPUTE: "causeDispute",
    CLIENT_ID: "clientId",
    CONTRACTOR_ID: "contractorId",
    CREATION_DATE: "creationDate",
    DESCRIPTION: "descriptionDispute",
    DISPUTE_ID: "disputeId",
    RESULT: "disputeResult",
    ORDER_ID: "orderId",
    STATUS: "statusDispute",
    TREATMENT_CHANNEL: "treatmentChannel",
    CLIENT_NAME: "clientName",
    BRAND: "brand",
    CONTRACTOR_FIRST_NAME: "contractorFirstName",
    CONTRACTOR_LAST_NAME: "contractorLastName",
    CONTRACTOR_PATRONYMIC: "contractorPatronymic",
    FULL_NAME: "contractorFullName",
    ORDER_NAME: "orderName",
    ORDER_MANAGER_ID: "orderManagerId",
    CLIENT_ADMIN_ID: "clientAdminId",
    MANAGER_FIRST_NAME: "managerFirstName",
    MANAGER_LAST_NAME: "managerLastName",
    MANAGER_PATRONYMIC: "managerPatronymic",
    DISPUTE_STATUS_RESULT: "disputeStatusResult",
    DISPUTE_RESULT: "disputeResult",
    PHONE: "phone",
    DISPUTE_MESSAGES_COUNT: "messageCount",
    DISPUTE_UNREAD_MESSAGES_COUNT: "unreadMessageCount",
    AMOUNT: "amount",
    ACTIVE: "active",
    CHANGE_DATE: "changeDate",
    LAST_MESSAGE: "lastMessage",
};

export const DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE = "Данный браузер не поддерживает автоматическое обновление чата";