import React from "react";

import TemplatesDropdown from "../../../../../../components/TemplatesDropdown";

import {ORDER_WORK_REPORT_TYPE} from "../../../../../../constants/finance";
import {ORDER_KIND} from "../../../../../../constants/order";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../../templates/constants";

const OrderListMassTemplatePublicationNDFL = (props) => {
    const {
        clientId,
        onChange,
        form,
        allOptionsInfo,
    } = props;

    return (
        <>
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.CIVIL}
                buttonText="Скачать договор"
                placeholder="Введите наименование шаблона договора"
                label="Шаблон договора НДФЛ"
                name="frameContractCivilId"
                onChange={onChange}
                options={allOptionsInfo.frameContractCivilId}
                value={form.frameContractCivilId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.AMOUNT_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.CIVIL}
                buttonText="Скачать заявку"
                placeholder="Введите наименование шаблона заявки"
                label="Шаблон заявки типа заказа 1"
                name="orderApplicationCivilAmountBasedId"
                onChange={onChange}
                options={allOptionsInfo.orderApplicationCivilAmountBasedId}
                value={form.orderApplicationCivilAmountBasedId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.CIVIL}
                buttonText="Скачать заявку"
                placeholder="Введите наименование шаблона заявки"
                label="Шаблон заявки типа заказа 2"
                name="orderApplicationCivilVolumeOfWorkBasedId"
                onChange={onChange}
                options={allOptionsInfo.orderApplicationCivilVolumeOfWorkBasedId}
                value={form.orderApplicationCivilVolumeOfWorkBasedId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.AMOUNT_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.CIVIL}
                buttonText="Скачать акт"
                placeholder="Введите наименование шаблона акта"
                label="Шаблон акта НДФЛ типа заказа 1"
                name="actOfAcceptanceOfWorkCivilAmountBasedId"
                onChange={onChange}
                options={allOptionsInfo.actOfAcceptanceOfWorkCivilAmountBasedId}
                value={form.actOfAcceptanceOfWorkCivilAmountBasedId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.CIVIL}
                buttonText="Скачать акт"
                placeholder="Введите наименование шаблона акта"
                label="Шаблон акта НДФЛ типа заказа 2"
                name="actOfAcceptanceOfWorkCivilVolumeOfWorkBasedId"
                onChange={onChange}
                options={allOptionsInfo.actOfAcceptanceOfWorkCivilVolumeOfWorkBasedId}
                value={form.actOfAcceptanceOfWorkCivilVolumeOfWorkBasedId}
                size="xl"
            />
        </>
    );
};

export default OrderListMassTemplatePublicationNDFL;