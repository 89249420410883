import React from "react";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmAccordion from "../../../../components/NmAccordion";
import NmTitle from "../../../../components/NmTitle";
import UnavailableTemplates from "../../../../components/UnavailableTemplates";
import OrderListMassTemplatePublicationNDFL from "./components/ndfl";
import OrderListMassTemplatePublicationNPD from "./components/npd";

import {useOrderListMassTemplatePublicationForm} from "./hooks/useForm";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";

import "./style.sass";

const OrderListMassTemplatePublication = (props) => {
    const {
        isNpd,
        isNdfl,
        onClose,
        progress,
        clientId,
        customTemplateIds,
    } = props;
    const [block, element] = bem("order-list-mass-template-publication");
    const {
        form,
        onChange,
        onSubmit,
        allOptionsInfo,
        progressReplacementsForUnavailableSelector,
        isOpenUnavailableTemplates,
        onCloseUnavailableTemplates,
        onSubmitUnavailableTemplates,
    } = useOrderListMassTemplatePublicationForm({
        customTemplateIds,
        clientId,
        isNpd,
        isNdfl,
        onSubmit: props.onSubmit,
    });

    const getNpd = () => {
        if (!isNpd) {
            return null;
        }

        return (
            <NmAccordion
                noBorder
                noPadding
                className="mb-3 mb-md-4"
                classNameHeader="d-flex justify-content-between"
                classNameIcon="order-list-mass-template-publication__accordion-icon"
                defaultOpen={true}
                name={
                    <div className="d-flex align-items-center">
                        <Text.Sub
                            level="2"
                            medium
                            className="me-2"
                            color={COLOR.SECONDARY_100}
                        >
                            Заказы для исполнителей с типом налогообложения НПД
                        </Text.Sub>
                        <HelpTooltip
                            info
                            hover
                            text="В данном блоке требуется указать шаблоны, которые будут применены к выбранным Вами заказам для исполнителей с типом налогообложения НПД"
                        />
                    </div>
                }
            >
                {
                    form.useActualTemplates ?
                        <Text level="3">
Заказы будут опубликованы с шаблонами, выбранными в них до публикации
                        </Text> :
                        <OrderListMassTemplatePublicationNPD
                            clientId={clientId}
                            form={form}
                            onChange={onChange}
                            allOptionsInfo={allOptionsInfo}
                        />
                }
            </NmAccordion>
        );
    };

    const getNdfl = () => {
        if (!isNdfl) {
            return null;
        }

        return (
            <NmAccordion
                noBorder
                noPadding
                classNameHeader="d-flex justify-content-between"
                defaultOpen={true}
                classNameIcon="order-list-mass-template-publication__accordion-icon"
                name={
                    <div className="d-flex align-items-center">
                        <Text.Sub
                            level="2"
                            medium
                            className="me-2"
                            color={COLOR.SECONDARY_100}
                        >
                            Заказы для исполнителей с типом налогообложения НДФЛ
                        </Text.Sub>
                        <HelpTooltip
                            info
                            hover
                            text="В данном блоке требуется указать шаблоны, которые будут применены к выбранным Вами заказам для исполнителей с типом налогообложения НДФЛ"
                        />
                    </div>
                }
            >
                {
                    form.useActualTemplates ?
                        <Text level="3">
Заказы будут опубликованы с шаблонами, выбранными в них до публикации
                        </Text> :
                        <OrderListMassTemplatePublicationNDFL
                            form={form}
                            onChange={onChange}
                            clientId={clientId}
                            allOptionsInfo={allOptionsInfo}
                        />
                }
            </NmAccordion>
        );
    };

    const getUnavailableTemplates = () => {
        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                progress={progress}
                onClose={onCloseUnavailableTemplates}
                onSubmit={onSubmitUnavailableTemplates}
                header="Публикация заказов"
            />
        );
    };

    return (
        <NmModal
            className={block()}
            onClose={onClose}
            size="md"
            header={
                <NmTitle size="lg">
                    Публикация заказов
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    align="right"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submitBtnContent="Опубликовать"
                    submit={onSubmit}
                    loading={progress || progressReplacementsForUnavailableSelector}
                    disabled={progress || progressReplacementsForUnavailableSelector}
                />
            }
        >
            {getUnavailableTemplates()}
            <NmForm>
                <Text level="3">
                    Выберите какие шаблоны документов должны содержать заказы к публикации.
                </Text>
                <Text
                    level="3"
                    className="mb-3 mb-md-4"
                >
                    <Text
                        inline
                        className={element("attention")}
                        level="3"
                    >
                        Внимание!&nbsp;
                    </Text>
                    Выбранные шаблоны будут применены ко всем публикуемым заказам. Если Вы хотите опубликовать заказы с шаблонами, которые выбраны в них на текущий момент, то выставьте соответствующий флаг
                </Text>
                <NmCheckboxV2
                    name="useActualTemplates"
                    label="Опубликовать с текущим выбором шаблонов"
                    checked={form.useActualTemplates}
                    onChange={onChange}
                    className="my-4"
                />
                {getNpd()}
                {getNdfl()}
            </NmForm>
        </NmModal>
    );
};

export default OrderListMassTemplatePublication;