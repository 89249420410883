import {isEmpty} from "lodash";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {handleFormString} from "../../../../utils/stringHelper";

import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

export const useKedoTemplatesFilter = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const initFilter = {
        nameFilter: "",
        lastVersionAddedByClientUserIdsFilters: [],
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });


    function mapFilterDto(filter) {
        const {
            lastVersionAddedByClientUserIdsFilters,
            nameFilter,
        } = filter;

        return {
            lastVersionAddedByClientUserIdsFilters: isEmpty(lastVersionAddedByClientUserIdsFilters)
                ? undefined
                : [lastVersionAddedByClientUserIdsFilters],
            nameFilter: handleFormString(nameFilter),
        };
    }

    const filters = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    size: "lg",
                    label: "Наименование",
                    placeholder: "Введите наименование документа",
                    name: "nameFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.CLIENT_USERS_FIO,
                    search: true,
                    roleListFilter: [
                        ADMIN,
                        NM_MANAGER,
                    ],
                    name: "lastVersionAddedByClientUserIdsFilters",
                    label: "Создатель",
                    placeholder: "Введите ФИО создателя",
                    size: "lg",
                },
            ],
        },
    ];

    return {
        initFilter,
        mapFilterDto,
        filters,
        onClear,
        onSearch,
        isSearch,
        filterData,
    };
};