import React from "react";
import {useTranslation} from "react-i18next";
import copy from "copy-to-clipboard";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import Text from "../../../../components/ActualComponents/Text";
import NmButton from "../../../../components/NmButton";
import NmButtonContact from "../../../../components/NmButtonContact";
import {ReactComponent as PhoneIcon} from "../../../../images/phone_24.svg";
import ChatMobileHeader from "../mobile-header";

import {CURRENT_CLIENT_USER_ID, ls} from "../../../../utils/localstorage";
import {getFullName,phoneFormat} from "../../../../utils/stringFormat";
import {toastSuccess} from "../../../../utils/toastHelper";

import {CHAT_ACTION, CHAT_OPTION} from "../constants";

import "./style.sass";

const ChatSimpleHeader = (props) => {
    const {
        desktop,
        chat,
        sendLockUserChat,
        mobile,
        onClickBackToChatList,
        isOpenChat,
    } = props;
    const {t} = useTranslation();
    const fullName = getFullName(chat.contractorLastName, chat.contractorFirstName, chat.contractorPatronymic);
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);

    const getContextMenuOptions = () => {
        return chat.locked ?
            [CHAT_OPTION.UNBLOCK] :
            [CHAT_OPTION.BLOCK];
    };

    const onClickContextMenu = ({value: action}) => {
        switch (action) {
        case CHAT_ACTION.BLOCK:
            sendLockUserChat(false)();

            return;
        case CHAT_ACTION.UNBLOCK:
            sendLockUserChat(true)();

            return;
        default: return;
        }
    };

    const handleCopyContact = value => {
        copy(value);
        toastSuccess("Номер телефона скопирован");
    };

    const getPhoneButton = () => {
        const {phone} = chat;

        return (
            <NmButtonContact
                size="lg"
                position="bottom-right"
                icon={<PhoneIcon />}
                popup={phoneFormat(phone)}
                onClick={() => {
                    handleCopyContact(phone);
                }}
            />
        );
    };

    const getControls = () => {
        if (currentUserId !== chat.orderManagerId) {
            return null;
        }

        if (desktop) {
            return chat.locked ?
                <NmButton
                    className="ms-2 ms-md-3"
                    color="light-green"
                    size="lg"
                    onClick={sendLockUserChat(false)}
                >
                    {t("chat.unlock")}
                </NmButton> :
                <NmButton
                    className="ms-2 ms-md-3"
                    size="lg"
                    color="red"
                    onClick={sendLockUserChat(true)}
                >
                    {t("chat.block")}
                </NmButton>;
        }

        return (
            <>
                {
                    isOpenChat && mobile &&
                    <ChatMobileHeader
                        onClickBackToChatList={onClickBackToChatList}
                        fullName={props.fullName}
                    />
                }
                <ContextMenu
                    inline={false}
                    className="ms-2 ms-md-3"
                    options={getContextMenuOptions()}
                    onClickItem={onClickContextMenu}
                />
            </>
        );
    };

    return (
        <>
            {
                mobile && isOpenChat ?
                    null :
                    <Text.Title
                        className="chat-simple-header__text"
                        level="1"
                        bold
                    >
                        {fullName}
                    </Text.Title>
            }
            <div className="flex flex-aligned-center">
                {getPhoneButton()}
                {getControls()}
            </div>
        </>
    );
};

export default ChatSimpleHeader;