import React from "react";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../NmCheckboxV2";
import NmDropdownV2 from "../../../NmDropdownV2";

import {
    bffClientsSettingsAdditionalModulesRetailClientUsersOptionsSelector,
} from "../../../../../ducks/bff/clients/settings/selectors";

export const ClientSettingsAdditionalModulesResourcesAccess = (props) => {
    const {
        values,
        onChange,
        isAccess,
    } = props;

    const clientAccessResourcesOptions = useSelector(bffClientsSettingsAdditionalModulesRetailClientUsersOptionsSelector);

    return (
        <div className="row align-items-start gx-4">
            <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                <NmCheckboxV2
                    disabled={!isAccess}
                    name="retailClient"
                    onChange={onChange}
                    checked={values.retailClient}
                    className="mt-0 mt-xl-5"
                    label="Доступ к разделу «Ресурсы»"
                />
            </div>
            <div className="col-16 col-xl-8">
                <NmDropdownV2
                    search
                    multiple
                    disabled={!values.retailClient}
                    label="Выбрать пользователей"
                    name="retailClientUsersIds"
                    options={clientAccessResourcesOptions}
                    value={values.retailClientUsersIds}
                    onChange={onChange}
                    isMountLoad={false}
                />
            </div>
        </div>
    );
};
