import React from "react";

import {Input} from "semantic-ui-react";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmInput = props => {
    const {
        value,
        size = "md",
        className,
        ...otherProps
    } = props;
    const [block] = bem("nm-input");

    const getActiveFieldStyle = value => {
        return value || value === 0 ? "nm-input_active" : "nm-input_no-active";
    };

    return (
        <Input
            {...otherProps}
            value={value}
            className={`${block({size})} ${getActiveFieldStyle(value)} ${className}`}
        />
    );
};

NmInput.propTypes = {
    size: PropTypes.oneOf([
        "md",
        "lg",
        "xl",
    ]),
};

export default NmInput;