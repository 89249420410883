import React from "react";

import {ReactComponent as IconCancel} from "../../images/cancel_24.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text from "../ActualComponents/Text";

import "./style.sass";

const RegistryPaymentError = (props) => {
    const {
        color,
        statusText,
        error,
    } = props;

    return (
        <div className="registry-payment-error">
            <div>
                <Text
                    color={color}
                    level="2"
                >
                    {statusText}
                </Text>
            </div>
            {
                error ?
                    <NmAdvancedTooltip
                        hover
                        type="error"
                        position="top-right"
                        classNameTooltip="registry-payment-error__error-tooltip"
                        children={error}
                        trigger={
                            <IconCancel className="registry-payment-error__status-value" />
                        }
                    /> :
                    null
            }
        </div>
    );
};

export default RegistryPaymentError;