import {useDispatch, useSelector} from "react-redux";

import {history} from "../../../../../../../../store/configureStore";
import {useContractorAdditionalVerification} from "./useErrorModal";

import {getAdditionalDocumentVerificationUpdatedData} from "../../../utils/getUpdatedData";

import {LINK_CONTRACTOR_PERSONAL_INFO} from "../../../../../../../../constants/links";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS,
} from "../../../constants";

import {getPendingPersonalData} from "../../../../../../../../ducks/contractor";
import {
    contractorAdditionalDocumentsAdministratorCheckFileTypesSelector,
    contractorAdditionalDocumentsFileSelector,
    decideContractorAdditionalDocument,
} from "../../../../../../../../ducks/contractorAdditionalDocuments";
import {
    fileContractorOriginalScanObjSelector,
    getContractorAdditionalDocumentFile,
} from "../../../../../../../../ducks/fileStore";

export const useContractorAdditionalDocumentsVerification = ({contractorId, updateDocumentStepForm, currentStep}) => {
    const dispatch = useDispatch();

    const scans = useSelector(fileContractorOriginalScanObjSelector);
    const allFileTypes = useSelector(contractorAdditionalDocumentsAdministratorCheckFileTypesSelector);
    const file = useSelector(contractorAdditionalDocumentsFileSelector);

    const {
        errorModalData,
        onOpenErrorModal,
        onCloseErrorModal,
        onClickCancelErrorModal,
    } = useContractorAdditionalVerification({
        contractorId,
    });

    const handleSave = (params) => {
        const {
            updatedPersonalData,
            fileDecisions,
            finishCheck,
            setOpenConfirmWindow,
        } = params;
        const {
            changeId,
            contractorId,
        } = file;

        const {
            scans,
            fieldsForSending: updatedPersonalDataFields = [],
        } = CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[currentStep.fileType] || {};

        const updatedData = getAdditionalDocumentVerificationUpdatedData({
            updatedPersonalData,
            updatedPersonalDataFields,
        });

        const _fileDecisions = fileDecisions.filter((item) => scans.includes(item.fileType));

        const requestData = {
            changeId,
            contractorId,
            fileDecisions: _fileDecisions,
            updatedData,
            finishCheck,
            onSuccess: () => {
                setOpenConfirmWindow(false);
                // Обновляем форму для проверки следующего типа документа
                updateDocumentStepForm();
            },
            onError: (result) => {
                const {
                    errorCode,
                    errorMessage,
                } = result;

                if (errorCode === "UNSUPPORTED_FOR_CURRENT_CONTRACTOR_STATUS") {
                    onOpenErrorModal({
                        content: errorMessage,
                    });
                }
            },
        };

        dispatch(decideContractorAdditionalDocument(requestData));
    };

    function handleClose() {
        dispatch(getPendingPersonalData({
            contractorId,
        }));

        history.push(LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId));
    }

    const fetchScans = (params) => {
        const {
            fileId,
            fileType,
            contractorId,
        } = allFileTypes[params.type] || {};

        if (!contractorId) {
            // Скрываем лоадер
            params.onSuccess();

            return;
        }

        dispatch(getContractorAdditionalDocumentFile({
            ...params,
            id: fileId,
            type: fileType,
            contractorId,
        }));
    };

    const getFileId = ({fileType}) => {
        const {
            fileId,
        } = allFileTypes[fileType] || {};

        return fileId;
    };

    return {
        handleClose,
        scans,
        handleSave,
        fetchScans,
        getFileId,
        errorModalData,
        onCloseErrorModal,
        onClickCancelErrorModal,
    };
};