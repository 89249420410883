import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";

import {STATUS_PASSPORT_INN} from "../../NmStatusPassportInnContractor";

import {getInitialTouched} from "../../../utils/objectHelper";

import {CONTRACTOR_FIELD} from "../../../constants/contractor";

import {contractorCardSelector} from "../../../ducks/contractor";

const {
    ID_DOC_NUMBER,
    LAST_NAME,
    FIRST_NAME,
    PATRONYMIC,
} = CONTRACTOR_FIELD;

const useContractorVerificationDataForm = (params) => {
    const {
        validationSchema,
        initialValues,
        handleClose,
        pendingPersonalDataFields = [],
    } = params;
    const {innPassportDataValidStatus} = useSelector(contractorCardSelector);
    const contractor = useSelector(contractorCardSelector);

    const [isFocus, setFocus] = useState(false);
    const [innStatus, setInnStatus] = useState(innPassportDataValidStatus);

    useEffect(() => {
        if (innPassportDataValidStatus) {
            setInnStatus(innPassportDataValidStatus);
        }
    }, [innPassportDataValidStatus]);

    const {
        values,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        validateForm,
        dirty,
        isValid,
    } = useFormik({
        initialValues: {
            ...contractor,
            ...initialValues,
        },
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema,
        initialTouched: getInitialTouched(initialValues),
    });

    const handleFocus = () => {
        setFocus(true);
    };

    const _handleBlur = () => {
        setFocus(false);
    };

    const _handleChange = (e, {value, name, checked}) => {
        if ([
            ID_DOC_NUMBER,
            LAST_NAME,
            FIRST_NAME,
            PATRONYMIC,
        ].includes(name)) {
            setInnStatus(STATUS_PASSPORT_INN.NOT_CHECKED.VALUE);
        }

        if (typeof checked === "boolean") {
            setFieldValue(name, checked);

            return;
        }

        setFieldValue(name, value);
    };

    function getChanged(name) {
        return pendingPersonalDataFields.includes(name);
    }

    return {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange: _handleChange,
        handleBlur: _handleBlur,
        handleFocus,
        errors,
        touched,
        isValid,
        setFocus,
        dirty,
        isFocus,
        validateForm,
        innStatus,
        setInnStatus,
        getChanged,
        handleClose,
    };
};

export default useContractorVerificationDataForm;