import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import CrowdTaskActRegistryEditModal from "./components/edit-modal";
import CrowdTaskActRegistryLogModal from "./components/log-modal";

import {usePagination} from "../../../hooks/usePagination";
import {useCrowdTaskActRegistryAction} from "./hooks/useAction";
import {useCrowdTaskActRegistryFetch} from "./hooks/useFetch";
import {useCrowdTaskActRegistryFilter} from "./hooks/useFilter";

import {formatLocalDate} from "../../../utils/dateFormat";
import {formatAmountWithNullChecking} from "../../../utils/stringFormat";
import {getSimpleText} from "../../../utils/stringHelper";

import {COLOR} from "../../../constants/color";
import {LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD} from "../../../constants/links";

import {
    bffCrowdActRegistryListSelector,
    bffCrowdActRegistryProgressActionSelector,
    bffCrowdActRegistryProgressSelector,
    bffCrowdActRegistryTotalCountSelector,
    bffCrowdActRegistryTotalPagesSelector,
} from "../../../ducks/bff/crowd/actRegistry/selectors";

import {CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME, CROWD_ACT_REGISTRY_STATUS} from "../../../constants/crowd/act-registry";

const CrowdTaskActRegistry = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffCrowdActRegistryListSelector);
    const totalCount = useSelector(bffCrowdActRegistryTotalCountSelector);
    const totalPages = useSelector(bffCrowdActRegistryTotalPagesSelector);
    const progress = useSelector(bffCrowdActRegistryProgressSelector);
    const progressAction = useSelector(bffCrowdActRegistryProgressActionSelector);

    const [archivedFilter, setArchivedFilter] = useState(false);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
        filters,
    } = useCrowdTaskActRegistryFilter({
        clientId,
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useCrowdTaskActRegistryFetch({
        clientId,
        pageNum,
        pageSize,
        archivedFilter,
        filter: filterData,
    });

    const {
        getMediaControls,
        getListItemMediaControls,
        modalData,
        onCloseModal,
    } = useCrowdTaskActRegistryAction({
        clientId,
        fetchList,
        archivedFilter,
        setArchivedFilter,
        progress,
        setPagination,
        pageSize,
    });

    const rows = useMemo(() => {
        return list.map(item => {
            const {
                registryId,
                createDate,
                status,
                registryNumber,
                name,
                needContractorApprove,
                sum,
                countBlock,
                hasFailedEntries,
            } = item;

            const cardLink = LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD
                .replace(":clientId", clientId)
                .replace(":registryId", registryId);

            const {
                [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.DONE]: doneCount = 0,
                [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.FORMING]: formingCount = 0,
                [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.REJECTED]: rejectedCount = 0,
                [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.PROCESSING]: processingCount = 0,
                [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.ERROR]: errorCount = 0,
            } = countBlock?.countRecords || {};

            return {
                ...item,
                key: registryId,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={CROWD_ACT_REGISTRY_STATUS[status]?.TEXT || status}
                                mod={CROWD_ACT_REGISTRY_STATUS[status]?.MOD || "gray"}
                            />
                        }
                        secondaryHeader={
                            createDate &&
                            `Реестр от: ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`
                        }
                        primaryHeader={
                            <ExtLink
                                pageData={{pageNum, pageSize}}
                                filterData={filterData}
                                historyEnabled
                                to={cardLink}
                            >
                                {`№${registryNumber} - ${name}`}
                            </ExtLink>
                        }
                        classNameMainContent="col-16 col-xxl-7"
                        labels={[
                            {
                                label: "Требуется подтверждение акта исполнителем",
                                text: getSimpleText(needContractorApprove),
                            },
                        ]}
                        cards={
                            [
                                {
                                    title: "Сумма реестра, ₽",
                                    value: formatAmountWithNullChecking(sum),
                                    className: "col-16 col-md-8 col-xxl-4",
                                },
                                {
                                    title: <>
                                        Не&nbsp;сформировано/В&nbsp;процессе/Отклонено/В&nbsp;ошибке/Завершено
                                    </>,
                                    subTitle: hasFailedEntries && <div className="flex align-items-center">
                                        <Text color={COLOR.NEGATIVE_100}>
                                            Ошибка
                                        </Text>
                                        <NmAdvancedTooltip
                                            className="ms-2"
                                            children="Найдены ошибки или отклоненные оплаты"
                                            hover={true}
                                            trigger={
                                                <InfoIcon
                                                    color={COLOR.NEGATIVE_100}
                                                    width={20}
                                                    height={20}
                                                />
                                            }
                                        />
                                    </div>,
                                    values: [
                                        {text: formingCount},
                                        {text: processingCount},
                                        {text: rejectedCount},
                                        {text: errorCount},
                                        {text: doneCount},
                                    ],
                                    className: "col-16 col-md-8 col-xxl-6",
                                },
                            ]
                        }
                        cardsWithContainer={true}
                        cardsContainerClassName="col-16 col-xxl-8 align-items-end"
                        mediaControls={getListItemMediaControls(item)}
                        actionsClassName="col-1 justify-content-end"
                    />
                ),
            };
        });
    }, [list]);

    const renderEditModal = () => {
        return (
            modalData?.isOpenEdit &&
            <CrowdTaskActRegistryEditModal
                clientId={clientId}
                isEdit={modalData.isEdit}
                editData={modalData.data}
                fetchList={fetchList}
                onClose={onCloseModal}
            />
        );
    };

    const renderConfirm = () => {
        const {
            content,
            confirmButton,
            cancelButton,
            onConfirm,
        } = modalData || {};

        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={onCloseModal}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderLogModal = () => {
        return (
            modalData?.isOpenLogModal &&
            <CrowdTaskActRegistryLogModal
                onClose={onCloseModal}
                {...modalData}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    tooltipText="Раздел предназначен для формирования оплат по заданиям. Реестр актов по заданиям считается завершенным, если оплаты по заданиям сформированы"
                >
                    Реестр актов по заданиям
                </NmTitle>
            }
            mediaControls={getMediaControls()}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progress}
        >
            {renderEditModal()}
            {renderConfirm()}
            {renderLogModal()}
            {
                !isEmpty(list) ?
                    <CheckboxList rows={rows} /> :
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
};

export default CrowdTaskActRegistry;