import React from "react";
import {useTranslation} from "react-i18next";

import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import AmountInput from "../../../components/AmountInput";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import {styleTextArea} from "../../../styles/inline";

import useDepositReturnForm from "./hooks/useDepositReturnForm";

import {BANK_TYPE, BANK_TYPE_CARD_OPTIONS} from "../../../constants/clientSettings";

import {depositFundsDependedType} from "../../../types";
import PropTypes from "prop-types";

const DepositReturn = (props) => {
    const {
        dependentData,
        isEdit,
        close,
        onReturnDeposit,
        onEditDeposit,
        isLoading,
        isCivil,
    } = props;

    const key = isEdit ? "deposit-return-edit-title" : "deposit-return-title";
    const bankOptions = isCivil ?
        BANK_TYPE_CARD_OPTIONS.filter(({value}) => value !== BANK_TYPE.TKB.VALUE) :
        BANK_TYPE_CARD_OPTIONS;

    const {
        t,
    } = useTranslation();

    const {
        values,
        errors,
        handleOnChange,
        handleSubmit,
    } = useDepositReturnForm(
        dependentData,
        isEdit,
        onEditDeposit,
        onReturnDeposit,
        close,
    );

    return (
        <NmModal
            size="md"
            onClose={close}
            zIndex={1000}
            className="deposit-return"
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={close}
                    submit={handleSubmit}
                    disabled={isLoading}
                />
            }
            header={
                <NmTitle size="lg">
                    {t(`deposit-modal.${key}`, {
                        clientName: dependentData.clientName,
                    })}
                </NmTitle>
            }
        >
            <NmForm addMargin>
                <AmountInput
                    newInput
                    size="xl"
                    name="amount"
                    label={`${t("deposit-modal.sum")}, ₽`}
                    error={errors.amount}
                    required
                    placeholder={t("deposit-modal.sum")}
                    value={values.amount}
                    onChange={handleOnChange}
                />
                <NmTextareaV2
                    autoSize={false}
                    label={t("deposit-modal.reason")}
                    error={errors.comment}
                    required
                    name="comment"
                    placeholder={t("deposit-modal.reason")}
                    style={styleTextArea}
                    value={values.comment}
                    onChange={handleOnChange}
                />
                <NmDropdownV2
                    required
                    disabled={isEdit}
                    label="Банк"
                    placeholder="Выберите банк"
                    name="bankType"
                    options={bankOptions}
                    value={values.bankType}
                    onChange={handleOnChange}
                    error={errors.bankType}
                />
            </NmForm>
        </NmModal>
    );
};

DepositReturn.propTypes = {
    isEdit: PropTypes.bool,
    close: PropTypes.func,
    dependentData: depositFundsDependedType,
    isLoading: PropTypes.bool,
};

DepositReturn.defaultProps = {
    isEdit: false,
    dependentData: PropTypes.object,
    close: () => {
    },
    isLoading: false,
};

export default DepositReturn;