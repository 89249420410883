import {isEmpty} from "lodash";

import dateFormat from "../../../../utils/dateFormat";
import {getNumberFromFormattedAmount} from "../../../../utils/stringFormat";

import {SMART_LINK_FILTER_BODY_TYPE} from "../constants";

export const getSmartLinkCommonFilter = (values) => {
    if (values.filterType === SMART_LINK_FILTER_BODY_TYPE.NUMBERS) {
        return {
            smartLinkId: values.smartLinkId,
            nums: values.nums,
        };
    }

    return {
        smartLinkId: values.smartLinkId,
        clientIds: isEmpty(values.projectIds) && isEmpty(values.objectIds) ? values.clientIds : [],
        projectIds: values.projectIds,
        objectIds: values.objectIds,
        fromDate: values.fromDate ?
            dateFormat(values.fromDate, "yyyy-MM-dd") :
            undefined,
        toDate: values.toDate ?
            dateFormat(values.toDate, "yyyy-MM-dd") :
            undefined,
        fromAmount: values.fromAmount ? getNumberFromFormattedAmount(values.fromAmount) : undefined,
        toAmount: values.toAmount ? getNumberFromFormattedAmount(values.toAmount) : undefined,
        specialityIds: isEmpty(values.specialityIds) ? undefined : values.specialityIds,
        cityFiasId: values.cityFiasId ? values.cityFiasId : undefined,
        regionFiasId: values.regionFiasId ? values.regionFiasId : undefined,
        clientCategoryIds: isEmpty(values.clientCategoryIds) ? undefined : values.clientCategoryIds,
    };
};