import React, {useEffect,useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../NmDropdownV2";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";
import {getOptions} from "../../../utils/dadata";

import {DADATA} from "../../../constants/dadata";

import {strapiDktnSelector} from "../../../ducks/strapi";

import {dadataAddressType, dadataSuggestionType} from "../../../types/dadata";

import "./style.sass";

enum DadataFiasLevel {
    house = "house",
    street = "street",
    region = "region",
    area = "area",
    city = "city",
    settlement = "settlement",
    planningStructure = "planningStructure",
    stead = "stead",
    block = "block",
    flat = "flat"
}

interface INmDadata {
    formatter?: (item: dadataAddressType, schema?: Record<DadataFiasLevel, Array<DadataFiasLevel>>) => {},
    fromBound?: string,
    toBound?: string,
    initialOptions?: Array<OptionType>,
    initialAddressIds?: Array<string> | string,
    count?: number,
    multiple?: boolean,
    name?: string,
    onChange: (event?: any, params?: any) => void,
    className?: string,
    size?: "lg" | "xl",
    label?: string,
    maxFiasLevel?: string,
    isClearable?: boolean,
    onSubmit?: () => void,
    isAllSelectionShown?: boolean,
    optionsFormatter?: (suggestions: Array<dadataSuggestionType>) => void,
    // Для ситуаций когда с бэкэнда приходят не только fias_id и
    // по этим данным можно загрузить данные для отображения в компонент
    filter?: (suggestion: dadataSuggestionType) => void,
    isLoadInitialFromDadata?: boolean
}

const LOAD_MODE = {
    MULTIPLE_INITIAL: "multipleInitialLoad",
};

const SAVING_VALUE_MODE = {
    INIT: "init",
    FROM_DROPDOWN_ITEM: "dropdownItem",
};

const NmDadata = (props: INmDadata) => {
    const {
        multiple,
        name,
        formatter,
        fromBound,
        toBound,
        onChange,
        initialOptions,
        initialAddressIds,
        className,
        optionsFormatter,
        isLoadInitialFromDadata = true,
        filter,
        count,
    } = props;

    const dtkn = useSelector(strapiDktnSelector);

    const [block] = bem("dadata-select", className);

    const [options, setOptions] = useState<any>([]);
    const [addressIds, setAddressIds] = useState<Array<string> | string>(multiple ? [] : "");
    const [opts, setOpts] = useState<any>([]);
    const [savedFrom, setSavedFrom] = useState(SAVING_VALUE_MODE.INIT);

    useEffect(() => {
        if (initialOptions) {
            setOptions(initialOptions);
        }
    }, [initialOptions]);

    useEffect(() => {
        if (isEmpty(initialAddressIds)) {
            setAddressIds(multiple ? [] : "");
        }

        if (!initialAddressIds) {
            return;
        }

        setAddressIds(initialAddressIds);

        if (savedFrom !== SAVING_VALUE_MODE.INIT) {
            return;
        }

        if (
            initialAddressIds.length !== 0 &&
            typeof initialAddressIds !== "string" &&
            isLoadInitialFromDadata
        ) {
            initialAddressIds.forEach((item: string) => {
                fetchAddress(item, DADATA.DADATA_ADDRESS_URL_BY_ID, LOAD_MODE.MULTIPLE_INITIAL);
            });
        }

        // В случае одиночного выбора
        if (typeof initialAddressIds === "string" && isLoadInitialFromDadata) {
            fetchAddress(initialAddressIds, DADATA.DADATA_ADDRESS_URL_BY_ID);
        }
    }, [initialAddressIds]);

    const fetchAddress = (searchQuery: string, url: string, mode?: string) => {
        // При пустом значении поиска не имеет смысла искать
        if (url === DADATA.DADATA_ADDRESS_URL && !searchQuery) {
            return;
        }

        const params = {
            method: "POST",
            mode: "cors" as RequestMode,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Token ${dtkn}`,
            },
            body: JSON.stringify({
                count,
                query: searchQuery,
                from_bound: fromBound ? {"value": fromBound} : undefined,
                to_bound: toBound ? {"value": toBound}: undefined,
            }),
        };

        fetch(url, params)
            .then((response) => response.json())
            .then(({suggestions}) => {
                const opts = getOptions({
                    suggestions,
                    formatter,
                    optionsFormatter,
                    filter,
                });

                if (mode === LOAD_MODE.MULTIPLE_INITIAL) {
                    setOpts((state: any) => [...state, ...opts]);
                    setOptions((state: any) => [...state, ...opts]);

                    return;
                }

                setOptions(opts);
            })
            .then((error) => {
                console.warn(error);
            });
    };

    const onSearchChange = (valueFilter: string) => {
        fetchAddress(valueFilter, DADATA.DADATA_ADDRESS_URL);
    };

    const handleChange = (e: any, option: any) => {
        const {value} = option;

        setSavedFrom(SAVING_VALUE_MODE.FROM_DROPDOWN_ITEM);
        setAddressIds(value);
        onChange(e, {name, ...option});
    };

    return (
        <NmDropdownV2
            {...props}
            className={block()}
            onSearchChange={onSearchChange}
            onChange={handleChange}
            multiple={multiple}
            search
            dadata
            value={addressIds}
            options={options}
            initialOptions={opts}
        />
    );
};

export default NmDadata;