import {getSmartLinkCommonFilter} from "./getCommonFilter";

export const getSmartLinkRequestData = (values) => {
    const commonFilter = getSmartLinkCommonFilter(values);

    const {
        filterType,
        type,
    } = values;

    return {
        name: values.name,
        filterType,
        type,
        ...commonFilter,
    };
};