import {
    CLIENT_SYSTEM_PROPERTIES_UPDATE_STORE,
    GET_CLIENT_SYSTEM_PROPERTIES_REQUEST,
    UPDATE_CLIENT_SYSTEM_PROPERTIES_REQUEST,
} from "./actions";

export const getClientSystemProperties = () => {
    return {
        type: GET_CLIENT_SYSTEM_PROPERTIES_REQUEST,
    };
};

export const updateClientSystemProperties = (payload) => {
    return {
        type: UPDATE_CLIENT_SYSTEM_PROPERTIES_REQUEST,
        payload,
    };
};

export const updateClientSystemPropertiesStore = (payload) => {
    return {
        type: CLIENT_SYSTEM_PROPERTIES_UPDATE_STORE,
        payload,
    };
};