import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useAdditionalAgreementsAction} from "../../../hooks/useAction";

import {isAccessDelete} from "../utils/isAccess";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {
    DOCUMENT_ADDITIONAL_AGREEMENT_STATUS,
} from "../../../../../../constants/documentType";

import {
    cancelAdditionalAgreement,
    deleteAdditionalAgreements,
} from "../../../../../../ducks/bff/documents/additional-agreements/registry/actionCreators";
import {
    bffAdditionalAgreementsRegistryCardSelector,
} from "../../../../../../ducks/bff/documents/additional-agreements/registry/selectors";
import {downloadDocument} from "../../../../../../ducks/documents";

export const useDocumentsAdditionalAgreementsAction = (params) => {
    const {
        selectedIds,
    } = params;

    const dispatch = useDispatch();

    const card = useSelector(bffAdditionalAgreementsRegistryCardSelector);

    const {
        onOpenModal,
        modalData,
        onCloseModal,
        getListMediaControls,
    } = useAdditionalAgreementsAction({
        isCard: true,
    });

    const headerControls = useMemo(() => {
        return getListMediaControls(card);
    }, [card]);

    const getRowMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            size: "lg",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                confirmProps: {
                                    content: "Вы действительно хотите удалить строку реестра?",
                                    onConfirm: () => {
                                        dispatch(deleteAdditionalAgreements({
                                            id: card.id,
                                            selectedIds: [item.id],
                                        }));
                                    },
                                },
                            });
                        },
                        children: "Удалить строку",
                    },
                    visible: isAccessDelete(item),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                confirmProps: {
                                    content: "Вы действительно хотите отменить подписание по данной строке реестра?",
                                    onConfirm: () => {
                                        dispatch(cancelAdditionalAgreement({
                                            id: item.id,
                                        }));
                                    },
                                },
                            });
                        },
                        children: "Отменить",
                    },
                    visible: [
                        DOCUMENT_ADDITIONAL_AGREEMENT_STATUS.AWAITING_SIGNATURE,
                    ].includes(item.status?.value),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            dispatch(downloadDocument({
                                isDownload: false,
                                downloadLink: item.signedAdditionalAgreementPdfDownloadLink,
                            }));
                        },
                        children: "Доп. соглашение",
                    },
                    visible: [
                        DOCUMENT_ADDITIONAL_AGREEMENT_STATUS.SIGNED,
                    ].includes(item.status?.value)
                    && item.signedAdditionalAgreementPdfDownloadLink,
                },
            ],
        };
    };

    const onClickDeleteRows = () => {
        onOpenModal({
            confirmProps: {
                content: "Вы действительно хотите удалить строки реестра?",
                onConfirm: () => {
                    dispatch(deleteAdditionalAgreements({
                        id: card.id,
                        selectedIds,
                    }));
                },
            },
        });
    };

    const onClickAddContractor = () => {
        onOpenModal({
            isOpenAddContractor: true,
        });
    };

    return {
        getRowMediaControls,
        headerControls,
        modalData,
        onCloseModal,
        onClickDeleteRows,
        onClickAddContractor,
    };
};