import React from "react";

import {COMPANY_SAMPLE_TYPE} from "../../containers/settings/settings-users/edit-modal";
import FilterButtonsV2 from "../ActualComponents/FilterButtonsV2";
import FilterObject from "../ActualComponents/FilterObject";
import FilterProject from "../ActualComponents/FilterProject";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmRadioV2 from "../ActualComponents/NmRadioV2";

import {useFilter} from "../../hooks/useFilter";

import {CLIENT_MEMBER_FIELD_NAME} from "../../constants/clientList";
import {MEMBER_POSITIONS_OPTIONS} from "../../constants/memberPositions";
import {NM_CONSULTANT, NM_MANAGER} from "../../constants/roles";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../constants/link-params";

function UserFilter(props) {
    const {
        clientId,
        submitFilter,
        initFilter,
        filter: _filter,
        roleOptions,
        subpage,
        currentRole,
        clientsForSelectionOptions,
    } = props;

    const {
        onChangeFilter,
        filter: {
            fioFilter,
            phoneFilter,
            roleListFilter,
            clientUserPositionFilter,
            accessibleProjectIdsFilter,
            accessibleObjectFilter,
            emailFilter,
            availableClients,
            sampleType,
        },
        filter,
        setFilter,
    } = useFilter({initFilter: _filter || initFilter});

    const handleOnSearch = () => {
        submitFilter(filter, true);
    };

    const handleOnClear = () => {
        submitFilter({...initFilter}, false);
        setFilter({...initFilter});
    };

    function getLabel() {
        let fioLabel = "ФИО сотрудника";
        let phoneFilterLabel = "Номер телефона сотрудника";
        let emailLabel = "E-mail сотрудника";

        if (subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK) {
            fioLabel = "ФИО партнера";
            phoneFilterLabel = "Номер телефона партера";
            emailLabel = "E-mail партнера";
        }

        return {
            fioLabel,
            phoneFilterLabel,
            emailLabel,
        };
    }

    return (
        <NmForm addMargin>
            <NmInputV2
                label={getLabel().fioLabel}
                value={fioFilter}
                name="fioFilter"
                size="lg"
                onChange={onChangeFilter}
                placeholder="Введите ФИО"
            />
            <NmInputV2
                placeholder="Введите номер телефона"
                mask="+7 (999) 999 99 99"
                maskChar={null}
                label={getLabel().phoneFilterLabel}
                value={phoneFilter}
                name="phoneFilter"
                size="lg"
                onChange={onChangeFilter}
            />
            <NmInputV2
                label={getLabel().emailLabel}
                value={emailFilter}
                name="emailFilter"
                size="lg"
                onChange={onChangeFilter}
                placeholder="Введите E-mail"
            />
            {subpage !== SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK && <NmInputV2
                size="lg"
                onChange={onChangeFilter}
                name="clientUserPositionFilter"
                label="Должность"
                placeholder="Введите должность"
                value={clientUserPositionFilter}
            />}
            {subpage !== SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK && <NmDropdownV2
                placeholder="Показать все"
                size="lg"
                selectOnBlur={false}
                search
                name="roleListFilter"
                multiple
                value={roleListFilter}
                options={roleOptions}
                label="Роль"
                onChange={onChangeFilter}
            />}
            {clientId && currentRole !== NM_CONSULTANT && <FilterProject
                placeholder="Показать все"
                clientId={clientId}
                name="accessibleProjectIdsFilter"
                onChange={onChangeFilter}
                projectIdsFilter={accessibleProjectIdsFilter}
                isActiveStyle={false}
            />}
            {clientId && currentRole !== NM_CONSULTANT && <FilterObject
                placeholder="Показать все"
                clientId={clientId}
                name="accessibleObjectFilter"
                onChange={onChangeFilter}
                objectIdsFilter={accessibleObjectFilter}
                projectIdsFilter={accessibleProjectIdsFilter}
                isActiveStyle={false}
            />}
            {subpage !== SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK && roleListFilter?.find(role => [NM_CONSULTANT, NM_MANAGER].includes(role)) && <>
                <NmRadioV2
                    label="Все компании"
                    checked={COMPANY_SAMPLE_TYPE.ALL === sampleType}
                    name="sampleType"
                    value={COMPANY_SAMPLE_TYPE.ALL}
                    onChange={onChangeFilter}
                />
                <NmRadioV2
                    label="Выбрать одну или несколько компаний"
                    checked={COMPANY_SAMPLE_TYPE.MULTIPLE === sampleType}
                    name="sampleType"
                    value={COMPANY_SAMPLE_TYPE.MULTIPLE}
                    onChange={onChangeFilter}
                />
                <NmDropdownV2
                    placeholder="Показать все"
                    size="lg"
                    selectOnBlur={false}
                    search
                    name="availableClients"
                    multiple
                    disabled={COMPANY_SAMPLE_TYPE.ALL === sampleType}
                    value={availableClients}
                    options={clientsForSelectionOptions}
                    label="Компании"
                    onChange={onChangeFilter}
                />
            </>}
            <FilterButtonsV2
                onClear={handleOnClear}
                onSearch={handleOnSearch}
            />
        </NmForm>
    );
}

export default UserFilter;