import {
    BFF_CROWD_TASK_GROUPS_ADD_REQUEST,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_DELETE_REQUEST,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST,
    UPDATE_CROWD_TASK_GROUPS_FILED_STORE,
} from "./actions";

export const getPageCrowdTaskGroups = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUPS_ADD_REQUEST,
        payload,
    };
};

export const updateCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST,
        payload,
    };
};

export const deleteCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUPS_DELETE_REQUEST,
        payload,
    };
};

export const archiveCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST,
        payload,
    };
};

export const unarchiveCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST,
        payload,
    };
};

export const updateStoreCrowdTaskGroups = (payload) => {
    return {
        type: UPDATE_CROWD_TASK_GROUPS_FILED_STORE,
        payload,
    };
};