import React, {FC, useState} from "react";

import {ReactComponent as FileCopy} from "../../images/file_copy_24.svg";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmDadataInput from "../ActualComponents/NmDadataInput";
import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";
import {ReactDadata} from "../ReactDadata";

import {toastSuccess} from "../../utils/toastHelper";

import {COLOR} from "../../constants/color";

interface ICityCodeDetect {
    onClose: () => void,
    toBound?: ReactDadata.BoundsType,
    fromBound?: ReactDadata.BoundsType,
    address?: ReactDadata.DadataSuggestion,
    filter: (suggestion: ReactDadata) => void
    returnOnlyForm?: boolean,
}

const CityCodeDetect: FC<ICityCodeDetect> = (props) => {
    const {
        onClose,
        filter,
        address,
        fromBound,
        toBound,
        returnOnlyForm = false,
    } = props;

    const [cityFiasId, setCityFiasId] = useState("");
    const [query, setQuery] = useState("");

    const renderFiasId = () => {
        if (!cityFiasId) {
            return (
                <Text color={COLOR.SECONDARY_45}>
                    не заполнено
                </Text>
            );
        }

        return (
            <div className="align-items-center advertisement-list__fias-id">
                <Text
                    type="text"
                    level="3"
                    color={COLOR.SECONDARY_100}
                >
                    {cityFiasId}
                </Text>
                <FileCopy
                    width="20px"
                    height="20px"
                    onClick={() => {
                        navigator.clipboard.writeText(cityFiasId);
                        toastSuccess("Текст скопирован в буфер обмена");
                    }}
                    className="ms-1 pointer"
                />
            </div>
        );
    };

    const renderForm = () => {
        return (
            <NmForm className="gx-4">
                <div className="mb-4">
                    Введите название города для определения кода ФИАС
                </div>
                <NmDadataInput
                    label="Город"
                    placeholder="Введите название города"
                    filter={filter}
                    onChange={(event) => {
                        setQuery(event?.value);
                        setCityFiasId(event?.data?.fias_id);
                    }}
                    query={query}
                    fromBound={fromBound}
                    toBound={toBound}
                    className="mb-1"
                    address={address}
                />
                <div className="flex flex-wrap">
                    <Text
                        color={COLOR.SECONDARY_70}
                        type="sub"
                        level="1"
                        className="me-1"
                    >
                        Код ФИАС:
                    </Text>
                    {renderFiasId()}
                </div>
            </NmForm>
        );
    };

    const renderHeader = () => {
        return (
            <div className="flex align-items-center">
                <NmTitle size="md">
                    Определение кода города
                </NmTitle>
                <HelpTooltip
                    type="light"
                    className="ms-1"
                    info
                    hover
                    children='Код города из справочника ФИАС может понадобиться для заполнения поля "Город" в строке объявления загружаемого файла Excel'
                />
            </div>
        );
    };

    const getContent = () => {
        if (returnOnlyForm) {
            return (
                <>
                    <div className="mb-4">
                        {renderHeader()}
                    </div>
                    {renderForm()}
                </>
            );
        }

        return (
            <NmModal
                size="md"
                header={renderHeader()}
                footer={
                    <ApplyButtons
                        isHiddenCancelOnMobile
                        mobile="column"
                        submitBtnContent="ОК"
                        submit={onClose}
                        onClose={onClose}
                        cancelBtnContent="Отменить"
                    />
                }
                onClose={onClose}
            >
                {renderForm()}
            </NmModal>
        );
    };

    return getContent();
};

export default CityCodeDetect;