import {useState} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

export const useOrderActRegistryFilter = ({setPagination, pageSize}) => {
    const [filter, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const filtersBase = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    placeholder: "Наименование реестра актов",
                    label: "Наименование реестра актов",
                    name: "nameFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    dateFormat: "dd.MM.yy",
                    startFieldName: "fromDateCreateFilter",
                    endFieldName: "toDateCreateFilter",
                    label: "Дата создания",
                    isClearable: true,
                    value: {
                        fromDate: null,
                        toDate: null,
                    },
                },
            ],
        },
    ];

    const submitFilter = (data) => {
        setIsSearch(true);
        setFilter(data);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setIsSearch(false);
        setFilter({});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    return {
        filter,
        submitFilter,
        clearFilter,
        filtersBase,
        isSearch,
    };
};