import {useMemo, useState} from "react";

export const useSettingsServiceTaskStatsSort = () => {
    const [usageCountSort, setUsageCountSort] = useState("DESC");

    const onClickSort = ({sortType}) => {
        setUsageCountSort(sortType);
    };

    const sortOptions = useMemo(() => {
        return [
            {
                key: "date",
                value: "date",
                sortType: usageCountSort,
                text: "По дате",
                asc: "ASC",
                desc: "DESC",
            },
        ];
    }, [usageCountSort]);

    return {
        onClickSort,
        usageCountSort,
        sortOptions,
    };
};