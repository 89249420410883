import React from "react";

import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import ExtLink from "../../../components/ExtLink";

import bem from "../../../utils/bem";
import {getFullName, phoneFormat} from "../../../utils/stringFormat";

import {COLOR} from "../../../constants/color";
import {LINK_CLIENT_INFO, LINK_CONTRACTOR_PROFILE} from "../../../constants/links";

import "./style.sass";

export const IncomingCallModal = ({className, ticket, onClose}) => {
    const [block, element] = bem("incoming-call-modal", className);
    const {
        clientModel,
        contractorModel,
        clientUserModel,
        baseModel: {fromNumber},
    } = ticket;

    function getCallerInfo() {
        const {name, clientId} = clientModel || {};
        const {lastName, firstName, patronymic} = clientUserModel || {};
        const {
            lastName: contractorLastName,
            firstName: contractorFirstName,
            patronymic: contractorPatronymic,
            fio,
            contractorId,
        } = contractorModel || {};

        if (clientModel && clientUserModel) {
            const linkCard = LINK_CLIENT_INFO.replace(":clientId", clientId);
            return {
                name: <div>
                    <ExtLink
                        to={linkCard}
                    >
                        {name}
                    </ExtLink>
                    &nbsp;
                    {getFullName(lastName, firstName, patronymic)}
                </div>,
                logo: clientModel.base64Logo,
            };
        }

        if (clientModel) {
            const linkCard = LINK_CLIENT_INFO.replace(":clientId", clientId);

            return {
                name: name ?
                    <ExtLink
                        to={linkCard}
                    >
                        {name}
                    </ExtLink> :
                    <Text color={COLOR.SECONDARY_75}>
Не определено
                    </Text>,
                logo: clientModel.base64Logo,
            };
        }

        if (contractorModel) {
            const content = contractorLastName ? getFullName(contractorLastName, contractorFirstName, contractorPatronymic) : fio;
            const linkCard = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

            return {
                name: content ?
                    <ExtLink
                        to={linkCard}
                    >
                        {content}
                    </ExtLink> :
                    <Text color={COLOR.SECONDARY_75}>
Не определено
                    </Text>,
                logo: contractorModel.base64Logo,
            };
        }

        return {
            name: null,
        };
    }

    const {
        name: callerName,
        logo,
    } = getCallerInfo();

    return (
        <NmModal
            size="sm"
            classNameContent={block()}
            onClose={onClose}
        >
            <Text
                level="2"
                color={COLOR.SECONDARY_75}
            >
                Входящий звонок
            </Text>

            <Text level="5">
                {phoneFormat(fromNumber)}
            </Text>

            <div className={element("avatar")}>
                <Avatar
                    size="sm"
                    base64={logo ? logo : undefined}
                />
                {!callerName ? (
                    <Text color={COLOR.SECONDARY_75}>
Не определено
                    </Text>
                ) : (
                    callerName
                )}
            </div>
        </NmModal>
    );
};
