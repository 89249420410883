

export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST =
    "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_SUCCESS =
    "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR =
    "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_STORE = "BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_STORE";