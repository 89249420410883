import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    KEDO_TEMPLATES_ADD_ERROR,
    KEDO_TEMPLATES_ADD_REQUEST,
    KEDO_TEMPLATES_ADD_SUCCESS,
    KEDO_TEMPLATES_DELETE_ERROR,
    KEDO_TEMPLATES_DELETE_REQUEST,
    KEDO_TEMPLATES_DELETE_SUCCESS,
    KEDO_TEMPLATES_GET_PAGE_ERROR,
    KEDO_TEMPLATES_GET_PAGE_REQUEST,
    KEDO_TEMPLATES_GET_PAGE_SUCCESS,
    KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR,
    KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST,
    KEDO_TEMPLATES_LOGS_GET_PAGE_SUCCESS,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_SUCCESS,
    KEDO_TEMPLATES_UPDATE_ERROR,
    KEDO_TEMPLATES_UPDATE_REQUEST,
    KEDO_TEMPLATES_UPDATE_SUCCESS,
    KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR,
    KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST,
    KEDO_TEMPLATES_VALIDATE_FIELDS_SUCCESS,
    KEDO_TEMPLATES_VALIDATE_PDF_ERROR,
    KEDO_TEMPLATES_VALIDATE_PDF_REQUEST,
    KEDO_TEMPLATES_VALIDATE_PDF_SUCCESS,
} from "./actions";

import {ACCESS_TOKEN_KEY, ls} from "../../../utils/localstorage";
import request, {getMultipartHeaders, isResponseSuccessOfJsonType} from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

// POST /bff/adm/document-templates/kedo/templates/getPage
// Получение страницы шаблонов
const getKedoTemplatesPageSaga = function* ({payload}) {
    const {
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_TEMPLATES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/document-templates/kedo/templates/delete
// Удаление шаблона
const deleteKedoTemplateSaga = function* ({payload}) {
    const {
        url,
        templateId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.delete(url, {params: {templateId}});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Шаблон удален");

        onSuccess();

        yield put({
            type: KEDO_TEMPLATES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/document-templates/kedo/templates/add
// Добавление шаблона
const addKedoTemplateSaga = function* ({payload}) {
    const {
        url,
        formData,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(url, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Шаблон сохранен");

        onSuccess();

        yield put({
            type: KEDO_TEMPLATES_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/document-templates/kedo/templates/searchTemplateUsersFieldNames
// Поиска кастомных полей в шаблоне
const getKedoTemplateUsersFieldNamesSaga = function* ({payload}) {
    const {
        url,
        formData,
    } = payload;

    try {
        const result = yield request.bff.post(url, formData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/document-templates/kedo/templates/update
// Обновление шаблона
const updateKedoTemplateSaga = function* ({payload}) {
    const {
        url,
        formData,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(url, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Шаблон сохранен");

        onSuccess();

        yield put({
            type: KEDO_TEMPLATES_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/document-templates/kedo/templates/validatePdf
// Валидация генерации документа по шаблону
const validateKedoTemplatePdfSaga = function* ({payload}) {
    const {
        url,
        formData,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.getFile(
            url,
            {
                headers: {
                    Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                },
                method: "POST",
                body: formData,
            },
        );

        if (isResponseSuccessOfJsonType(result)) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                if (errorMessage) {
                    toastError(errorMessage);

                    yield put({
                        type: KEDO_TEMPLATES_VALIDATE_PDF_ERROR,
                        payload: data,
                    });
                }

                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();

        onSuccess(blob);

        yield put({
            type: KEDO_TEMPLATES_VALIDATE_PDF_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_VALIDATE_PDF_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/document-templates/kedo/templates/validateFields
// Валидация полей шаблона
const validateKedoTemplateFieldsSaga = function* ({payload}) {
    const {
        url,
        formData,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(url, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: KEDO_TEMPLATES_VALIDATE_FIELDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/document-templates/kedo/logs/getPage
// Получение страницы журнала событий шаблонов
const getKedoTemplatesLogsPageSaga = function* ({payload}) {
    const {
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_TEMPLATES_LOGS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_TEMPLATES_GET_PAGE_REQUEST, getKedoTemplatesPageSaga),
        takeEvery(KEDO_TEMPLATES_DELETE_REQUEST, deleteKedoTemplateSaga),
        takeEvery(KEDO_TEMPLATES_ADD_REQUEST, addKedoTemplateSaga),
        takeEvery(KEDO_TEMPLATES_UPDATE_REQUEST, updateKedoTemplateSaga),
        takeEvery(KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST, getKedoTemplateUsersFieldNamesSaga),
        takeEvery(KEDO_TEMPLATES_VALIDATE_PDF_REQUEST, validateKedoTemplatePdfSaga),
        takeEvery(KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST, validateKedoTemplateFieldsSaga),
        takeEvery(KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST, getKedoTemplatesLogsPageSaga),
    ]);
}