import React from "react";
import {useDispatch} from "react-redux";

import FinancePaymentList from "../finance-payment-list";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";

const FinanceAdminIndividualPayments = (props) => {
    return (
        <FinancePaymentList
            {...props}
            orderWorkReportType={ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
            header="Оплаты (ИП)"
        />
    );
};

export default FinanceAdminIndividualPayments;