export const ORDER_TEMPLATES_EDIT_ACT_TEMPLATE_INIT_FORM = {
    description: "",
    descriptionFns: "",
    actDate: null,
    estimatedTimeScore: 0,
    workQualityScore: 0,
    actOfAcceptanceOfWorkTemplateId: "",
    reviewText: "",
};

export const ORDER_TEMPLATES_ACT_TEMPLATE_LIVING_FIELDS = [
    "description",
    "descriptionFns",
    "reviewText",
];