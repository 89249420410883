import {
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_UPDATE_STORE,
} from "./actions";

export const getRecruitmentJobBoardSubscriptions = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
        payload,
    };
};

export const clearRecruitmentJobBoardsStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE,
        payload,
    };
};

export const getRecruitmentWebhook = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST,
        payload,
    };
};
export const addRecruitmentWebhook = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST,
        payload,
    };
};
export const deleteRecruitmentWebhook = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST,
        payload,
    };
};

export const updateRecruitmentJobBoardsWebhookStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_UPDATE_STORE,
        payload,
    };
};
