import {citizenshipsDict} from "../../../../../../constants/citizenships";
import {CONTRACTOR_MIGRATION_STATUS} from "../../../../../../constants/contractor";

export const getContractorPassportTitle = (params) => {
    const {
        migrationStatus,
        citizenship,
    } = params;

    if ([CONTRACTOR_MIGRATION_STATUS.REFUGEE].includes(migrationStatus)) {
        return "Удостоверение беженца";
    }

    if ([CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE].includes(migrationStatus)) {
        return "Свидетельство о предоставлении временного убежища";
    }

    if ([citizenshipsDict.NOT_SPECIFIED.value].includes(citizenship)) {
        return "Временное удостоверение личности лица без гражданства";
    }

    return "Паспорт";
};