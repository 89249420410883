import React, {useEffect,useState} from "react";
import {useDispatch} from "react-redux";

import ActCreation from "../../../../components/ActCreation";

import {formatDateWithoutTime} from "../../../../utils/dateFormat";

import {updateFieldOrderStore} from "../../../../ducks/order";

const BrigadePaymentsEditModal = (props) => {
    const {
        close,
        submit,
        progressAction,
        amount,
        contractorId,
        paymentInfo,
        className,
        clientId,
        orderId,
        orderCreatedByTemplateId,
        orderContractPaymentType,
        orderCard: {
            description,
            workStartDate,
            orderKind,
            workUnitPrice,
            contractorCommission,
        },
    } = props;

    const [formData, setFormData] = useState({
        description: "",
        descriptionFns: "",
        reviewText: "",
        sumOfMoney: amount,
        workEndDate: formatDateWithoutTime(new Date(), "yyyy-MM-dd"),
        workStartDate: formatDateWithoutTime(new Date(), "yyyy-MM-dd"),
        actOfAcceptanceOfWorkTemplateId: props.actOfAcceptanceOfWorkTemplateId,
    });
    const [score, setScore] = useState({
        workQualityScore: null,
        estimatedTimeScore: null,
    });
    const {workQualityScore, estimatedTimeScore} = score;
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateFieldOrderStore("card", {}));
        };
    }, []);

    const handleChange = (event, {name, value}) => {
        if (name === "description") {
            setFormData({
                ...formData,
                [name]: value,
                "descriptionFns": value.slice(0, 160),
            });

            return;
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleOnRate = (event, {name, value}) => {
        setScore({
            ...score,
            [name]: value,
        });
    };

    const submitForm = () => {
        const {sumOfMoney, description, descriptionFns, reviewText} = formData;

        submit({
            amount: sumOfMoney ? sumOfMoney.toString().replace(",", ".") : sumOfMoney,
            description,
            descriptionFns,
            reviewText,
            contractorWorkQualityScore: workQualityScore,
            contractorEstimatedTimeScore: estimatedTimeScore,
        });
    };

    return (
        <ActCreation
            zIndex={1100}
            className={className}
            handleClose={close}
            orderDescription={description}
            contractorId={contractorId}
            clientId={clientId}
            orderId={orderId}
            orderContractPaymentType={orderContractPaymentType}
            formData={formData}
            progress={progressAction}
            workQualityScore={workQualityScore}
            orderCreatedByTemplateId={orderCreatedByTemplateId}
            estimatedTimeScore={estimatedTimeScore}
            startOrderDate={workStartDate}
            orderKind={orderKind}
            amount={amount}
            isForemanForm
            paymentInfo={paymentInfo}
            handleChange={handleChange}
            handleOnRate={handleOnRate}
            submit={submitForm}
            workUnitPrice={workUnitPrice}
            contractorCommission={contractorCommission}
        />
    );
};

export default BrigadePaymentsEditModal;