import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import ContractorStatistics from "../../../../components/ContractorStatistics";
import NmTitle from "../../../../components/NmTitle";
import ContractorWorkExperienceEditModal from "./components/сontractor-work-experience-edit-modal";

import {isNMUsers} from "../../../../utils/access";
import {formatLocalDate} from "../../../../utils/dateFormat";

import {orderStatisticsSelector} from "../../../../ducks/contractor";
import {
    contractorProfileWorkExperiencesSelector,
    deleteContractorWorkExperience,
} from "../../../../ducks/contractorProfile";

import "./style.sass";

export const WORK_EXPERIENCE_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

export const WORK_EXPERIENCE_OPTIONS = [
    {
        key: WORK_EXPERIENCE_OPTION.EDIT,
        text: "Редактировать",
        value: WORK_EXPERIENCE_OPTION.EDIT,
    },
    {
        key: WORK_EXPERIENCE_OPTION.REMOVE,
        text: "Удалить",
        value: WORK_EXPERIENCE_OPTION.REMOVE,
    },
];

export default function ContractorWorkExperience(props) {
    const {
        className,
        contractorId,
        getProfile,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const {workExperiences, workExperiencePresent} = useSelector(contractorProfileWorkExperiencesSelector);
    const {
        isLoaded: isOrderStatisticsLoaded,
        categoryStatistics,
    } = useSelector(orderStatisticsSelector);

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [isShowStatisticModal, setIsShowStatisticModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const onClickAction = ({value}, item) => {
        const {workExperienceId} = item;

        if (value === WORK_EXPERIENCE_OPTION.EDIT) {
            toggleEditModal({
                ...item,
                workExperience: !workExperiencePresent,
            });
            return;
        }

        if (value === WORK_EXPERIENCE_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem(workExperienceId);
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?",
            });
            setShowConfirm(true);
        }
    };

    const deleteElem = (workExperienceId) => {
        dispatch(deleteContractorWorkExperience({
            contractorId,
            workExperienceId,
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    const toggleStatistics = () => {
        setIsShowStatisticModal(prevState => !prevState);
    };

    function mapContent() {
        if (!workExperiences.length) {
            return <div className="flex flex-aligned-center justify-content-between mb-md-2 mb-xxl-2 mb-xl-2">
                <Text level="1">
                    Сведения пока не добавлены
                </Text>
            </div>;
        }

        return workExperiences.map((item) => {
            return (
                <div
                    key={item.workExperienceId}
                    className="contractor-work-experience-block"
                >
                    <div className="flex flex-aligned-center justify-content-between mb-md-2 mb-xxl-2 mb-xl-2">
                        <div>
                            <Text>
                                {item.employerName}
                                {" "}
с
                                {formatLocalDate(item.startWorkDate)}
                                {" "}
по
                                {formatLocalDate(item.endWorkDate)}
                            </Text>
                        </div>
                        {isNMUsers() && <ContextMenu
                            notBorder
                            horizontalIcon
                            transform={false}
                            disabled={false}
                            options={WORK_EXPERIENCE_OPTIONS}
                            onClickItem={(data) => {
                                onClickAction(data, item);
                            }}
                        />}
                    </div>
                    <div className="mb-md-2 mb-xxl-2 mb-xl-2">
                        <Text bold>
                            {item.position}
                        </Text>
                    </div>
                    <div className="mb-md-2 mb-xxl-2 mb-xl-2">
                        <Text>
                            {item.responsibilities}
                        </Text>
                    </div>
                </div>
            );
        });
    }

    const isOrdersStatistics = isOrderStatisticsLoaded && categoryStatistics.length > 0;

    return (
        <>
            {
                isShowStatisticModal && (
                    <NmModal
                        header={
                            <NmTitle size="lg">
                                Статистика выполненных заказов
                            </NmTitle>
                        }
                        onClose={() => toggleStatistics()}
                    >
                        <ContractorStatistics
                            list={categoryStatistics}
                        />
                    </NmModal>
                )
            }
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal && <ContractorWorkExperienceEditModal
                editData={editData}
                getProfile={getProfile}
                contractorId={contractorId}
                onClose={() => toggleEditModal()}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                onClickAdd={toggleEditModal}
                title="Опыт работы"
                headerAdditionalContent={
                    isOrdersStatistics &&
                    <div
                        onClick={() => toggleStatistics()}
                        className="contractor-work-experience__link"
                    >
                        Статистика выполненных заказов
                    </div>
                }
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}