import React from "react";
import {useMedia} from "react-media";

import bem from "../../../utils/bem";

import "./style.sass";

export const MenuItemsContainer = (props) => {
    const {
        children,
        className = "",
    } = props;
    const [block] = bem("menu-items-container", className);
    const isPreDesktop = useMedia({query: {minWidth: 1200, maxWidth: 1919}});

    const onScroll = () => {
        if (!isPreDesktop) {
            return;
        }

        props.onScroll();
    };

    return (
        <div
            onScroll={onScroll}
            className={block()}
        >
            {children}
        </div>
    );
};
