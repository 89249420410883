import React from "react";
import {useDispatch, useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import ContractorOldPersonalDataEdit from "./components/old-personal-data";

import {useModal} from "../../../../../hooks/useModal";

import {convertUtcToLocal, formatDateWithoutTime} from "../../../../../utils/dateFormat";

import {
    contractorCardSelector,
    getRichContractorById,
} from "../../../../../ducks/contractor";
import {
    contractorPendingPersonalDataProgressSelector,
    refreshInnAndPassportDataValidStatus,
} from "../../../../../ducks/contractorPendingPersonalData";

const ContractorOldPersonalData = (props) => {
    const {
        className,
        isEditable,
    } = props;
    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const progress = useSelector(contractorPendingPersonalDataProgressSelector);
    const card = useSelector(contractorCardSelector);
    const dispatch = useDispatch();

    const checkPersonalData = () => {
        dispatch(refreshInnAndPassportDataValidStatus({
            contractorId: card.contractorId,
            inn: card.inn,
            citizenship: card.citizenship,
            idDocNumber: card.idDocNumber,
            lastName: card.lastName,
            firstName: card.firstName,
            patronymic: card.patronymic,
            birthDate: card.birthDate,
            previousPassportData: true,
            onSuccess: () => {
                dispatch(getRichContractorById(card.contractorId));
            },
        }));
    };

    const renderEditForm = () => {
        if (!modalData) {
            return null;
        }

        return (
            <ContractorOldPersonalDataEdit
                onClose={onCloseModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Старые персональные данные"
            titleLevel="4"
            onClickEdit={onOpenModal}
            accessEdit={isEditable}
            mediaControlsRenderCount={{
                mobile: 0,
                tablet: 2,
                desktop: 2,
            }}
            mediaControlsButtons={[{
                onClick: checkPersonalData,
                size: "sm",
                children: "Проверить",
                color: "light-green",
                disabled: progress,
            }]}
        >
            {renderEditForm()}
            <ContractorInfoCardContent
                labels={[
                    card.previousPassportLastName && {label: "Фамилия", text: card.previousPassportLastName},
                    card.previousPassportFirstName && {label: "Имя", text: card.previousPassportFirstName},
                    card.previousPassportPatronymic && {label: "Отчество", text: card.previousPassportPatronymic},
                    card.previousPassportBirthDate && {
                        label: "Дата рождения",
                        text: formatDateWithoutTime(convertUtcToLocal(card.previousPassportBirthDate)),
                    },
                    card.previousPassportIdDocNumber && {
                        label: "Серия и номер паспорта",
                        text: card.previousPassportIdDocNumber,
                    },
                ]}
            />
        </NmMiniInfoCard>
    );
};

export default ContractorOldPersonalData;