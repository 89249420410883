import React from "react";

import bem from "../../../../utils/bem";

import "./style.sass";

export const AgencyContractFileLink = (props) => {
    const {
        onClick,
        key,
        fileName,
        isLastElement,
    } = props;

    const [block] = bem("agency-contract-file-link");

    return (
        <span
            key={key}
            onClick={onClick}
            className={block()}
        >
            {fileName}
            {!isLastElement && ", "}
        </span>
    );
};