import React from "react";
import {Route, Switch} from "react-router";

import AuthRedirect from "../components/AuthRedirect";
import BoardConnect from "../components/BoardConnect";
import ForbiddenPage from "../components/ForbiddenPage";
import NotFoundPage from "../components/NotFoundPage";
import ProtectedRoute from "../components/ProtectedRoute";
import SystemTransactions from "../components/SystemTransactions";
import Test from "../components/Test";
import ChatList from "./chats/chat-list";
import ClientCard from "./client/card";
import {ClientInstructions} from "./client/instructions";
import ClientListV2 from "./client-list";
import ClientRemoteContractors from "./contractor/client-remote-contractors";
import ContractorCard from "./contractor/contractor-card";
import ContractorInfoAdditionalDocumentsVerification
    from "./contractor/contractor-info-new/components/additional-documents-verification";
import ContractorResources from "./contractor/contractor-resources";
import ContractorVerificationData from "./contractor/contractor-verification-data";
import NmContractorList from "./contractor/nm-contractor-list";
import AddContractorsToClientGroupForm from "./contractor/nm-contractor-list/add-contractors-to-client-group-form";
import TicketCard from "./crm/ticket/card";
import CrmTicketList from "./crm/ticket/list";
import DepositAdminList from "./deposit/admin-list";
import DepositCivilAdminList from "./deposit/civil-admin-list";
import DisputesLog from "./disputes/disputes-list";
import ExportDocument from "./export-document";
import FinanceAdminNdflPayments from "./finance/admin-civil-payments";
import FinanceAdminIndividualPayments from "./finance/admin-individual-payments";
import FinanceAdminNpdPayments from "./finance/admin-npd-payments";
import FinanceCanceledChecks from "./finance/calcelled-checks";
import FinanceBank from "./finance/finance-bank";
import FinanceCrowdPayments from "./finance/finance-crowd-payments";
import FinanceCrowdTasks from "./finance/finance-crowd-tasks";
import FinanceExport from "./finance/finance-export";
import FinanceOrders from "./finance/finance-orders";
import FinancePatentPayments from "./finance/finance-patent-payments";
import FinanceMutualSettlementsReconciliationActs from "./finance/mutual-settlements-reconciliation-acts";
import PatentPivotTable from "./finance/patent-pivot-table";
import FinancePivotGph from "./finance/pivot-gph";
import FinancePivotSmz from "./finance/pivot-smz";
import FinanceUpload from "./finance-upload";
import KedoTemplates from "./kedo/templates";
import Login from "./login";
import LoginChange from "./login/change";
import Marketing from "./mailings";
import Map from "./map";
import MapObjectsList from "./map/components/objects-list";
import PromoCodeChannelCard from "./promocode/promocode-channel-card";
import PromoCodeList from "./promocode/promocode-channel-list";
import RegistryList from "./registries/registry-list";
import OperatorReports from "./reports/operator-reports";
import ReviewsPage from "./reviews";
import Settings from "./settings";
import GroupCard from "./settings/settings-client-group/group-card";
import StopList from "./stop-list";
import TaskList from "./task/list";
import Templates from "./templates";

import {
    LINK_ADDING_CLIENT_REMOTE_CONTRACTORS,
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_AUTH_REDIRECT,
    LINK_BOARD_CONNECT,
    LINK_CHANGE_PASSWORD,
    LINK_CHAT_LIST,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CLIENT_CARD,
    LINK_CLIENT_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CLIENT_STOP_LIST,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS,
    LINK_CONTRACTOR_LIST,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_CONTRACTOR_VERIFICATION_DATA,
    LINK_CONTRACTORS_RESOURCES,
    LINK_CRM_TICKET_CARD,
    LINK_CRM_TICKET_LIST,
    LINK_DEPOSIT_LIST,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_FINANCE_BANK,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_CROWD_TASKS,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_FINANCE_EXPORT_FULL,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_SYSTEM_TRANSACTIONS,
    LINK_FINANCE_UPLOAD,
    LINK_FORBIDDEN_PAGE,
    LINK_FULL_INFO_MAP,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_LOGIN,
    LINK_MAILINGS_LIST,
    LINK_MAP_OBJECTS_LIST,
    LINK_MARKETING_BUILDER,
    LINK_PATENT_PIVOT_TABLE,
    LINK_PROMOCODE_CHANNEL_CARD,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_REQUEST,
    LINK_REVIEWS_LIST,
    LINK_SETTINGS,
    LINK_SETTINGS_CLIENT_GROUP_CARD,
    LINK_TEMPLATES_KEDO,
    LINK_TEMPLATES_LIST,
    LINK_TEMPLATES_LIST_WITH_TAB,
    LinkNavigator,
    TASK_LIST_LINK,
} from "../constants/links";

export function MainRoute() {
    return (
        <Switch>
            <Route
                exact
                path={LINK_LOGIN}
                component={Login}
            />
            <Route
                exact
                path={LINK_REQUEST}
                component={Login}
            />
            <Route
                exact
                path={LINK_CHANGE_PASSWORD}
                component={LoginChange}
            />
            <Route
                exact
                path={"/test"}
                component={Test}
            />
            <ProtectedRoute
                path={LINK_SETTINGS_CLIENT_GROUP_CARD}
                component={GroupCard}
            />
            <ProtectedRoute
                path={LINK_CLIENT_LIST}
                component={ClientListV2}
            />
            <ProtectedRoute
                exact
                path={LINK_CRM_TICKET_LIST}
                component={CrmTicketList}
            />
            <ProtectedRoute
                path={LINK_TEMPLATES_KEDO}
                component={KedoTemplates}
            />
            <ProtectedRoute
                exact
                path={LINK_TEMPLATES_LIST}
                component={Templates}
            />
            <ProtectedRoute
                exact
                path={LINK_TEMPLATES_LIST_WITH_TAB}
                component={Templates}
            />
            <ProtectedRoute
                exact
                path={LINK_CRM_TICKET_CARD}
                component={TicketCard}
            />
            <ProtectedRoute
                path={LINK_CLIENT_CARD}
                component={ClientCard}
            />
            <ProtectedRoute
                exact
                path={LINK_CONTRACTOR_LIST}
                component={NmContractorList}
            />
            <ProtectedRoute
                exact
                path={LINK_CLIENT_ON_CHECK_CONTRACTORS}
                component={NmContractorList}
            />
            <ProtectedRoute
                exact
                path={LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS}
                component={NmContractorList}
            />
            <ProtectedRoute
                exact
                path={LINK_CONTRACTORS_RESOURCES}
                component={ContractorResources}
            />
            <ProtectedRoute
                exact
                path={LINK_CLIENT_REMOTE_CONTRACTORS}
                component={ClientRemoteContractors}
            />
            <ProtectedRoute
                exact
                path={LINK_CONTRACTOR_ONBOARDING_LEADS}
                component={ClientRemoteContractors}
            />
            <ProtectedRoute
                exact
                path={LINK_ADDING_CLIENT_REMOTE_CONTRACTORS}
                component={AddContractorsToClientGroupForm}
            />
            <ProtectedRoute
                exact
                path={LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS}
                component={ContractorInfoAdditionalDocumentsVerification}
            />
            <ProtectedRoute
                path={LINK_CONTRACTOR_CARD}
                component={ContractorCard}
            />
            <ProtectedRoute
                path={LINK_CONTRACTOR_VERIFICATION_DATA}
                component={ContractorVerificationData}
            />
            <ProtectedRoute
                path={LINK_CONTRACTOR_VERIFICATION_DATA}
                component={ContractorVerificationData}
            />
            <ProtectedRoute
                exact
                path={LINK_DEPOSIT_LIST}
                component={DepositAdminList}
            />
            <ProtectedRoute
                exact
                path={LinkNavigator.admin.civilDeposit}
                component={DepositCivilAdminList}
            />
            <ProtectedRoute
                path={LINK_FINANCE_PAYMENT_LIST}
                component={FinanceAdminNpdPayments}
            />
            <ProtectedRoute
                exact
                path={LINK_FINANCE_NDFL_PAYMENT_LIST}
                component={FinanceAdminNdflPayments}
            />
            <ProtectedRoute
                exact
                path={LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST}
                component={FinanceAdminIndividualPayments}
            />
            <ProtectedRoute
                path={LINK_FINANCE_UPLOAD}
                component={FinanceUpload}
            />
            <ProtectedRoute
                path={LINK_FINANCE_ORDERS}
                component={FinanceOrders}
            />
            <ProtectedRoute
                path={LINK_FINANCE_CROWD_TASKS}
                component={FinanceCrowdTasks}
            />
            <ProtectedRoute
                path={LINK_SETTINGS}
                component={Settings}
            />
            <ProtectedRoute
                exact
                path={LINK_PROMOCODE_CHANNEL_LIST}
                component={PromoCodeList}
            />
            <ProtectedRoute
                exact
                path={LINK_FULL_INFO_MAP}
                component={Map}
            />
            <ProtectedRoute
                exact
                path={LINK_MAP_OBJECTS_LIST}
                component={MapObjectsList}
            />
            <ProtectedRoute
                exact
                path={LINK_PROMOCODE_CHANNEL_CARD}
                component={PromoCodeChannelCard}
            />
            <ProtectedRoute
                exact
                path={LINK_REVIEWS_LIST}
                component={ReviewsPage}
            />
            <Route
                exact
                path={LINK_FORBIDDEN_PAGE}
                component={ForbiddenPage}
            />
            <ProtectedRoute
                path={LINK_DISPUTES}
                component={DisputesLog}
            />
            <ProtectedRoute
                path={LINK_DISPUTES_CLIENT_ADMIN}
                component={DisputesLog}
            />
            <ProtectedRoute
                path={LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST}
                component={ChatList}
            />
            <ProtectedRoute
                path={LINK_FINANCE_OPERATOR_REPORTS}
                component={OperatorReports}
            />
            <ProtectedRoute
                path={LINK_FINANCE_SYSTEM_TRANSACTIONS}
                component={SystemTransactions}
            />
            <ProtectedRoute
                path={LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS}
                component={FinanceMutualSettlementsReconciliationActs}
            />
            <ProtectedRoute
                path={LINK_FINANCE_CANCELED_CHECKS}
                component={FinanceCanceledChecks}
            />
            <ProtectedRoute
                path={LINK_FINANCE_REGISTRY_PAYMENTS}
                component={RegistryList}
            />
            <ProtectedRoute
                path={LINK_FINANCE_PATENT_PAYMENT_LIST}
                component={FinancePatentPayments}
            />
            <ProtectedRoute
                path={LINK_FINANCE_CROWD_PAYMENT_LIST}
                component={FinanceCrowdPayments}
            />
            <ProtectedRoute
                path={LINK_INSTRUCTION_CLIENT_ADMIN}
                component={ClientInstructions}
            />
            <ProtectedRoute
                path={LINK_INSTRUCTION_KEDO_CLIENT_ADMIN}
                component={ClientInstructions}
            />
            <ProtectedRoute
                path={LINK_DISPUTES_CHAT_LIST}
                component={ChatList}
            />
            <ProtectedRoute
                path={LINK_ALL_ROLES_CHAT_LIST}
                component={ChatList}
            />
            <ProtectedRoute
                path={LINK_CHAT_LIST}
                component={ChatList}
            />
            <ProtectedRoute
                path={LINK_FINANCE_PIVOT_TABLE}
                component={FinancePivotSmz}
            />
            <ProtectedRoute
                path={LINK_CIVIL_PIVOT_TABLE}
                component={FinancePivotGph}
            />
            <ProtectedRoute
                path={LINK_PATENT_PIVOT_TABLE}
                component={PatentPivotTable}
            />
            <ProtectedRoute
                path={LINK_FINANCE_EXPORT_FULL}
                component={FinanceExport}
            />
            <ProtectedRoute
                path={TASK_LIST_LINK}
                component={TaskList}
            />
            <ProtectedRoute
                exact
                path={LINK_FINANCE_EXPORT_DOCUMENTS}
                component={ExportDocument}
            />
            <ProtectedRoute
                exact
                path={LINK_FINANCE_BANK}
                component={FinanceBank}
            />
            <ProtectedRoute
                exact
                path={LINK_MAILINGS_LIST}
                component={Marketing}
            />
            <ProtectedRoute
                exact
                path={LINK_MARKETING_BUILDER}
                component={Marketing}
            />
            <ProtectedRoute
                exact
                path={LINK_CLIENT_STOP_LIST}
                component={StopList}
            />
            <Route
                path={LINK_AUTH_REDIRECT}
                component={AuthRedirect}
            />
            <Route
                path={LINK_BOARD_CONNECT}
                component={BoardConnect}
            />
            <Route
                exact
                path="/"
                component={() => {
                    const url = "/login";

                    window.location.replace(url);

                    // fix Error: component(...) nothing was returned from render
                    return null;
                }}
            />
            <Route
                path="*"
                component={NotFoundPage}
            />
        </Switch>
    );
}