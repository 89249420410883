import {ORDER_KIND} from "../../../../../constants/order";

export const handleOrderActRegistryFrom = (params) => {
    const {
        card = {},
        paymentsSettings,
        values = {},
    } = params;
    const {
        smzMarketplace,
    } = paymentsSettings;

    return {
        description: card.description || "",
        creatorClientUserId: card.creatorClientUserId || "",
        name: card.name || "",
        actOfAcceptanceOfWorkTemplateId: getActOfAcceptanceOfWorkTemplateId(card, values),
        isSecondTypeImportOrder: card.actOrderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
        paymentMethod: card.paymentMethod || smzMarketplace?.paymentMethod,
    };
};

const getActOfAcceptanceOfWorkTemplateId = (card, values) => {
    if (card.actOfAcceptanceOfWorkTemplateId) {
        return card.actOfAcceptanceOfWorkTemplateId;
    }

    if (values.actOfAcceptanceOfWorkTemplateId) {
        return values.actOfAcceptanceOfWorkTemplateId;
    }

    return "";
};