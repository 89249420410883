import {useEffect, useState} from "react";
import {useMedia} from "react-media";

import {useGetPrevValue} from "../../../../hooks/useGetPrevValue";

export const useVerticalTabsMenu = () => {
    const isMobile = useMedia({query: {maxWidth: 767}});

    const prevIsMobile = useGetPrevValue(isMobile);

    const [isShowTabMenu, showMenu] = useState(() => true);

    useEffect(() => {
        if (prevIsMobile && !isMobile && !isShowTabMenu) {
            showMenu(true);
        }
    }, [isMobile]);

    const hideTabsMenu = () => {
        if (isMobile) {
            showMenu(false);
        }
    };

    const showTabsMenu = () => {
        if (isMobile) {
            showMenu(true);
        }
    };

    return {
        isMobile,
        isShowTabMenu,
        hideTabsMenu,
        showTabsMenu,
    };
};