import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import ContractorSnilsInfoEditForm from "./components/edit-form";

import {useContractorAdditionalDocuments} from "../../hooks/useAdditionalDocuments";
import {useSnilsInfoCardAction} from "./hooks/useAction";

import {getCardModeByStatusSegment} from "../../utils/statusSegment";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../constants/clientList";
import {COLOR} from "../../../../../constants/color";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS,
    CONTRACTOR_FIELD,
} from "../../../../../constants/contractor";

import {contractorCardSelector} from "../../../../../ducks/contractor";

const SnilsInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const card = useSelector(contractorCardSelector);

    const {
        contractorId,
    } = card;

    const {
        isOpenEditForm,
        toggleEditModal,
    } = useSnilsInfoCardAction({});

    const {
        requestDocumentButton,
        documentInfo,
        renderDeleteConfirm,
        toggleShowDeleteConfirm,
    } = useContractorAdditionalDocuments({
        contractorId,
        documentType: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.SNILS,
    });

    const data = documentInfo.data || {};

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <ContractorSnilsInfoEditForm
                contractorId={contractorId}
                data={data}
                onClose={toggleEditModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="СНИЛС (доп. документ)"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            onClickDelete={toggleShowDeleteConfirm}
            isVisibleDeleteButton={isEditable && documentInfo.canBeDeleted}
            accessEdit={isEditable}
            mode={getCardModeByStatusSegment(documentInfo.status)}
            lastUpdate={documentInfo.updateDateTime}
            mediaControlsRenderCount={{
                mobile: 0,
                tablet: 3,
                desktop: 3,
            }}
            mediaControlsButtons={[requestDocumentButton]}
        >
            {renderDeleteConfirm()}
            {renderEditForm()}
            {
                documentInfo.status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT ?
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_45}
                    >
                        Сведения пока не добавлены
                    </Text> :
                    <ContractorInfoCardContent
                        labels={[
                            {label: "Номер", text: data[CONTRACTOR_FIELD.SNILS_NUM]},
                        ]}
                        scans={[
                            {
                                label: "Лицевая сторона СНИЛС",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN,
                            },
                        ]}
                    />
            }
        </NmMiniInfoCard>
    );
};

export default SnilsInfoCard;