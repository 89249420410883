import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {removePhoneMask} from "../../../utils/stringFormat";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";
import {LENGTH_FOREIGN_PHONE, PHONE_MASK} from "../../../constants/phone";
import {
    CONTRACTOR_SEARCH_TYPE,
    CONTRACTOR_SEARCH_TYPE_OPTIONS,
    REGISTRY_SEARCH_STEP,
} from "../../../constants/registry";
import {ADMIN} from "../../../constants/roles";
import {nameWithLatinReg} from "../../../constants/validationRules";

import {
    contractorProgressSelector,
    contractorSearchListSelector,
    contractorTotalCountSelector,
    getContractorList,
} from "../../../ducks/contractor";
import {
    checkPatentRegistryContractorPresentPayments,
    patentsRegistryCardSelector,
} from "../../../ducks/patentsRegistry";

import PropTypes from "prop-types";

import "./style.sass";

class RegistrySearchContractor extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        onClose: PropTypes.func,
        isPatentsRegistry: PropTypes.bool,
        orderContractPaymentType: PropTypes.string,
    };

    static defaultProps = {
        onClose: () => {
        },
    };

    constructor(props) {
        super(props);

        const {clientId} = props;

        this.state = {
            error: undefined,
        };

        this.role = ls(USER_ROLE);
        this.clientId = [ADMIN].includes(this.role) ? clientId : undefined;
    }

    componentDidUpdate(prevProps) {
        const {
            checkPatentRegistryContractorPresentPayments,
            progressSearch,
            totalCount,
            nextStep,
            list,
            isPatentsRegistry,
            clientId,
            patentCard: {
                patentRegistryId,
            },
        } = this.props;
        const [contractor] = list;

        const {progressSearch: oldProgressSearch} = prevProps;
        const {
            form: {
                searchType,
            },
        } = this.props;

        if (progressSearch !== oldProgressSearch && !progressSearch) {
            if (totalCount === 0) {
                this.setState({
                    error: searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? "Исполнитель с данным номером не найден" : "Исполнитель с данным ФИО не найден",
                });
                return;
            }

            if (totalCount === 1 && isPatentsRegistry) {
                const {
                    contractorId,
                    phone,
                } = contractor;

                checkPatentRegistryContractorPresentPayments({
                    data: {
                        clientId,
                        contractorId,
                        contractorPhone: phone,
                        patentRegistryId,
                    },
                    onSuccess: this.checkPatentRegistryContractorOnSuccess,
                });
                return;
            }

            if (totalCount === 1) {
                nextStep(
                    REGISTRY_SEARCH_STEP.STEP_3.VALUE,
                    {
                        ...contractor,
                    },
                    REGISTRY_SEARCH_STEP.STEP_1.VALUE,
                );
                return;
            }

            nextStep(REGISTRY_SEARCH_STEP.STEP_2.VALUE);
        }

    }

    onChange = (e, {value, name}) => {
        const {onChange} = this.props;

        if(name === "searchType") {
            this.setState({
                error: undefined,
            });
        }

        onChange(e, {value, name});
    };

    checkPatentRegistryContractorOnSuccess = ({result}) => {
        const {
            nextStep,
            list,
        } = this.props;
        const [contractor] = list;

        if (result) {
            this.setState({
                error: "Исполнитель уже добавлен в реестр",
            });

            return;
        }

        nextStep(
            REGISTRY_SEARCH_STEP.STEP_3.VALUE,
            {
                ...contractor,
            },
            REGISTRY_SEARCH_STEP.STEP_1.VALUE,
        );
    };

    isValidForm = () => {
        const {
            form: {
                searchType,
                phoneNumber,
                fullName,
            },
        } = this.props;

        if (searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE) {
            if (removePhoneMask(phoneNumber).length < LENGTH_FOREIGN_PHONE.MIN) {
                this.setState({error: "Введите номер телефона полностью"});
                return false;
            }
            this.setState({error: undefined});
            return true;
        }
        const _fullName = fullName.trim();

        if (_fullName.length < 5) {
            this.setState({error: "Минимальная длина строки 5 символов"});
            return false;
        }

        if (_fullName.length > 150) {
            this.setState({error: "Максимальная длина строки 150 символов"});
            return false;
        }

        if (!nameWithLatinReg.test(_fullName)) {
            this.setState({error: "Неверный формат ФИО"});
            return false;
        }

        this.setState({error: undefined});

        return true;
    };

    search = () => {
        const {
            getContractorList,
            form: {
                searchType,
                phoneNumber,
                fullName,
            },
            isPatentsRegistry,
            orderContractPaymentType,
        } = this.props;

        const isValid = this.isValidForm();

        if (!isValid) {
            return;
        }

        const filterType = searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? "phoneFilter" : "fioFilter";
        const filterValue = searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? removePhoneMask(phoneNumber) : fullName.trim();

        getContractorList({
            [filterType]: filterValue,
            sortType: "FIO_ASC",
            pageSize: 5,
            pageNum: 1,
            clientId: this.clientId,
            forbiddenListFilterEnabled: !isPatentsRegistry && ![
                ORDER_WORK_REPORT_TYPE.CIVIL,
                ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
            ].includes(orderContractPaymentType),
        });
    };

    renderActions() {
        const {
            t,
            onClose,
            progressSearch,
        } = this.props;

        return (
            <ApplyButtons
                isHiddenCancelOnMobile
                mobile="column"
                loading={progressSearch}
                onClose={onClose}
                submit={this.search}
                cancelBtnContent={t("button.cancel")}
                submitBtnContent="Далее"
            />
        );
    }

    render() {
        const {
            error,
        } = this.state;
        const {
            onClose,
            form: {
                searchType,
                phoneNumber,
                fullName,
            },
            onChange,
        } = this.props;

        return (
            <NmModal
                size="md"
                onClose={onClose}
                footer={this.renderActions()}
                header={
                    <NmTitle size="lg">
                        Поиск исполнителя
                    </NmTitle>
                }
            >
                <NmForm
                    onSubmit={this.search}
                    className="registry-search-contractor__form"
                >
                    <NmDropdownV2
                        size="xl"
                        name="searchType"
                        value={searchType}
                        className="registry-search-contractor__control"
                        onChange={this.onChange}
                        options={CONTRACTOR_SEARCH_TYPE_OPTIONS}
                    />
                    {
                        searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ?
                            <NmInputV2
                                size="xl"
                                placeholder="Телефон"
                                mask={PHONE_MASK.FOREIGN}
                                onChange={onChange}
                                maskChar={null}
                                name="phoneNumber"
                                value={phoneNumber}
                                error={error}
                            /> :
                            <NmInputV2
                                size="xl"
                                placeholder="ФИО"
                                onChange={onChange}
                                maskChar={null}
                                name="fullName"
                                value={fullName}
                                error={error}
                            />
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        progressSearch: contractorProgressSelector(state),
        totalCount: contractorTotalCountSelector(state),
        list: contractorSearchListSelector(state),
        patentCard: patentsRegistryCardSelector(state),
    }),
    {
        getContractorList,
        checkPatentRegistryContractorPresentPayments,
    },
)(withTranslation()(RegistrySearchContractor));