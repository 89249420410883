import React from "react";

import useEventsData from "./hooks/useEventsData";
import useServerSentEvents from "./hooks/useServerSentEvents";

const ServerSentEvents = () => {
    useServerSentEvents();
    useEventsData();

    return null;
};

export default ServerSentEvents;