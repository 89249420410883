import {createSelector} from "reselect";

import {bffRecruitmentVacancyResponsesSelector} from "../recruitment/vacancyResponses/selectors";

import {getPageNum, getTotalPages} from "../../../utils/mathHelper";

import {MARKER_TYPE} from "../../../containers/map/constants";

export const bffMapSelector = state => state.bff.map;
export const bffMapClustersSelector = createSelector(bffMapSelector, ({clusters}) => clusters);

export const bffMapContractorsSelector = createSelector(bffMapSelector, ({contractors}) => contractors);
export const bffMapContractorsTotalCountSelector = createSelector(bffMapSelector, ({contractorsTotalCount}) => contractorsTotalCount);
export const bffMapContractorsTotalPagesSelector = createSelector(bffMapSelector, ({contractorsTotalCount, contractorsPageData: {pageSize = 0}}) => getTotalPages(contractorsTotalCount, pageSize));
export const bffMapContractorsPageNumSelector = createSelector(bffMapSelector, ({contractors = [], contractorsPageData = {}}) => {
    return getPageNum(contractors.length, contractorsPageData.pageSize);
});
export const bffMapInviteModalContractorsSelector = createSelector(bffMapSelector, ({inviteModalData: {contractors}}) => contractors);
export const bffMapInviteModalContractorsTotalCountSelector = createSelector(bffMapSelector, ({inviteModalData: {contractorsTotalCount}}) => contractorsTotalCount);
export const bffMapInviteModalContractorsTotalPagesSelector = createSelector(bffMapSelector, ({inviteModalData: {contractorsTotalCount, contractorsPageData: {pageSize = 0}}}) => getTotalPages(contractorsTotalCount, pageSize));
export const bffMapInviteModalContractorsPageNumSelector = createSelector(bffMapSelector, ({inviteModalData: {contractors = [], contractorsPageData = {}}}) => {
    return getPageNum(contractors.length, contractorsPageData.pageSize);
});

export const bffMapAdvertsSelector = createSelector(bffMapSelector, ({adverts}) => adverts);
export const bffMapAdvertsTotalCountSelector = createSelector(bffMapSelector, ({advertsTotalCount}) => advertsTotalCount);
export const bffMapAdvertsTotalPagesSelector = createSelector(bffMapSelector, ({advertsTotalCount, advertsPageData: {pageSize = 0}}) => getTotalPages(advertsTotalCount, pageSize));

export const bffMapOrdersSelector = createSelector(bffMapSelector, ({orders}) => orders);
export const bffMapOrdersTotalCountSelector = createSelector(bffMapSelector, ({ordersTotalCount}) => ordersTotalCount);
export const bffMapOrdersTotalPagesSelector = createSelector(bffMapSelector, ({ordersTotalCount, ordersPageData: {pageSize = 0}}) => getTotalPages(ordersTotalCount, pageSize));
export const bffMapOrdersPageNumSelector = createSelector(bffMapSelector, ({orders = [], ordersPageData = {}}) => {
    return getPageNum(orders.length, ordersPageData.pageSize);
});
export const bffMapInviteModalOrdersSelector = createSelector(bffMapSelector, ({inviteModalData: {orders}}) => orders);
export const bffMapInviteModalOrdersTotalCountSelector = createSelector(bffMapSelector, ({inviteModalData: {ordersTotalCount}}) => ordersTotalCount);
export const bffMapInviteModalOrdersTotalPagesSelector = createSelector(bffMapSelector, ({inviteModalData: {ordersTotalCount, ordersPageData: {pageSize = 0}}}) => getTotalPages(ordersTotalCount, pageSize));
export const bffMapInviteModalOrdersPageNumSelector = createSelector(bffMapSelector, ({inviteModalData: {orders = [], ordersPageData = {}}}) => {
    return getPageNum(orders.length, ordersPageData.pageSize);
});

export const bffMapTasksSelector = createSelector(bffMapSelector, ({tasks}) => tasks);
export const bffMapTasksTotalCountSelector = createSelector(bffMapSelector, ({tasksTotalCount}) => tasksTotalCount);
export const bffMapTasksTotalPagesSelector = createSelector(bffMapSelector, ({tasksTotalCount, tasksPageData: {pageSize = 0}}) => getTotalPages(tasksTotalCount, pageSize));
export const bffMapTasksPageNumSelector = createSelector(bffMapSelector, ({tasks = [], tasksPageData = {}}) => {
    return getPageNum(tasks.length, tasksPageData.pageSize);
});
export const bffMapInviteModalTasksSelector = createSelector(bffMapSelector, ({inviteModalData: {tasks}}) => tasks);
export const bffMapInviteModalTasksTotalCountSelector = createSelector(bffMapSelector, ({inviteModalData: {tasksTotalCount}}) => tasksTotalCount);
export const bffMapInviteModalTasksTotalPagesSelector = createSelector(bffMapSelector, ({inviteModalData: {tasksTotalCount, tasksPageData: {pageSize = 0}}}) => getTotalPages(tasksTotalCount, pageSize));
export const bffMapInviteModalTasksPageNumSelector = createSelector(bffMapSelector, ({inviteModalData: {tasks = [], tasksPageData = {}}}) => {
    return getPageNum(tasks.length, tasksPageData.pageSize);
});

export const bffMapProgressSelector = createSelector(bffMapSelector, ({progress}) => progress);
export const bffMapProgressActionSelector = createSelector(bffMapSelector, ({progressAction}) => progressAction);
export const bffMapProgressListSelector = createSelector(bffMapSelector, ({progressList}) => progressList);
export const bffMapInviteModalProgressListSelector = createSelector(bffMapSelector, ({inviteModalData: {progressList}}) => progressList);
export const bffMapPageDataSelector = createSelector(bffMapSelector, ({mapPageData}) => mapPageData);
export const bffMapObjectsCountsSelector = createSelector(bffMapSelector, ({counts}) => counts);