import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Avatar from "../../../components/Avatar";
import CheckboxList from "../../../components/CheckboxList";
import LocatedOutsideRussiaTooltip from "../../../components/LocatedOutsideRussiaTooltip";
import NmCheck from "../../../components/NmCheck";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import {ReactComponent as UploadIcon} from "../../../images/file_upload.svg";
import {history} from "../../../store/configureStore";

import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import {useObjectContractorsListAction} from "./hooks/useAction";
import {useObjectContractorsListFetch} from "./hooks/useFetch";
import {useObjectContractorsListFilter} from "./hooks/useFilter";

import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFullName, phoneFormat} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {LINK_CONTRACTOR_PROFILE} from "../../../constants/links";
import {
    ADMIN,
    CLIENT_ADMIN,
    FOREMAN,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";

import {avatarBase64ImagesListSelector} from "../../../ducks/fileStore";
import {
    onboardingContractorsListProgressSelector,
    onboardingContractorsListSelector,
    onboardingContractorsListTotalCountSelector,
    onboardingContractorsListTotalPagesSelector,
    onboardingContractorsProgressActionSelector,
} from "../../../ducks/onboardingContractors";

const ObjectContractorsList = (props) => {
    const {
        match: {
            params: {
                clientId,
                objectId,
            },
        },
    } = props;

    const totalPages = useSelector(onboardingContractorsListTotalPagesSelector);
    const totalCount = useSelector(onboardingContractorsListTotalCountSelector);
    const list = useSelector(onboardingContractorsListSelector);
    const progress = useSelector(onboardingContractorsListProgressSelector);
    const progressAction = useSelector(onboardingContractorsProgressActionSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        initFilter,
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    } = useObjectContractorsListFilter({});

    const {
        fetchList,
    } = useObjectContractorsListFetch({
        clientId,
        objectId,
        pageSize,
        pageNum,
        filter: filterDto,
    });

    const {
        exportList,
        deleteContractors,
        confirmData,
        setConfirmData,
        onCloseConfirm,
    } = useObjectContractorsListAction({
        clientId,
        objectId,
        pageSize,
        pageNum,
        fetchList,
        selectedList,
        clearSelectedRows,
        filter: filterDto,
    });

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    const getMediaControls = () => {
        return {
            renderCount: {
                desktop: 1,
                tablet: 1,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: exportList,
                        children: "Выгрузить список",
                        color: "grey",
                        icon: <UploadIcon />,
                        disabled: totalCount < 1 || progressAction,
                    },
                    visible: ![RECRUITER].includes(role),
                    settings: {
                        exception: true,
                    },
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            const {
                contractorId,
                creationDateTime,
                lastName,
                firstName,
                patronymic,
                phone,
                inn,
                taxStatusState,
                locatedOutsideRussia,
            } = item;

            const {isSelected = false} = selectedList.find(item => (item.contractorId === contractorId)) || {};
            const disabledCheckBox = ![
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                PROJECT_MANAGER,
                OBJECT_MANAGER,
                FOREMAN,
            ].includes(role);

            return {
                ...item,
                showCheckBox: true,
                disabledCheckBox,
                isSelected,
                avatar: <Avatar
                    contractorId={contractorId}
                    base64={userAvatarDict[contractorId]}
                />,
                contentRow: (
                    <NmListCard
                        checkbox
                        avatar
                        noDivider
                        secondaryHeader={`Дата приглашения: ${formatDate(convertUtcToLocal(creationDateTime), "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={getFullName(lastName, firstName, patronymic)}
                        primaryHeaderLink
                        onClickLink={() => {
                            history.push(LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId));
                        }}
                        classNameMainContent="col-16 col-xl-10"
                        labels={[
                            {
                                label: "Телефон",
                                text: phoneFormat(phone, locatedOutsideRussia),
                                classNameText: "flex align-items-center",
                                textOverflowUnset: true,
                                textTooltip:
                                    locatedOutsideRussia &&
                                    <LocatedOutsideRussiaTooltip />,
                            },
                            {
                                label: "ИНН",
                                text: inn || "-",
                            },
                            {
                                text: <NmCheck
                                    isCheck={taxStatusState === "PRESENT"}
                                    label="Самозанятый"
                                />,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;
        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    return (
        <NmPage
            paginationPaddingBottom
            heightUnset
            header={
                <NmTitle size="xl">
                    Исполнители
                </NmTitle>
            }
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            isLoaded={progress}
            typeFilter={![RECRUITER].includes(role) && "vertical"}
            filtersBase={
                ![RECRUITER].includes(role) &&
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={(form) => submitFilter(form, true)}
                    clearFilter={() => submitFilter(initFilter, true)}
                />
            }
            mediaControls={getMediaControls()}
        >
            {renderConfirmWindow()}
            {
                totalCount > 0 ?
                    <CheckboxList
                        rows={getRows()}
                        header={
                            <SelectionCountWithAction
                                adaptiveLogic
                                count={countSelected}
                                buttonColor="grey"
                                buttonContent="Удалить"
                                onClick={() => {
                                    setConfirmData({
                                        content: "Вы действительно хотите удалить выбранные строки из списка исполнителей?",
                                        onConfirm: deleteContractors,
                                    });
                                }}
                                disabled={progressAction}
                            />
                        }
                        isCheckBox
                        onSelectedRows={handleSelectedRows}
                    /> : <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
};

export default ObjectContractorsList;