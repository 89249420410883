import React from "react";

import NmAdvancedTooltip from "../../ActualComponents/NmAdvancedTooltip";

const AvatarIndicator = (props) => {
    const {
        className,
        icon,
        tooltipText,
        classNameTooltip,
    } = props;

    return (
        <div className={className}>
            <NmAdvancedTooltip
                children={tooltipText}
                classNameTooltip={classNameTooltip}
                hover
                position="bottom-left"
                trigger={icon}
            />
        </div>
    );
};

export default AvatarIndicator;