import React from "react";

import FinancePaymentList from "../finance-payment-list";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";

const FinanceClientNpdPayments = (props) => {
    return (
        <FinancePaymentList
            {...props}
            isNpd={true}
            header="Выплаты исполнителям (НПД)"
            orderWorkReportType={ORDER_WORK_REPORT_TYPE.SMZ}
        />
    );
};

export default FinanceClientNpdPayments;