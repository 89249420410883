import React, {FC} from "react";
import Media from "react-media";
import {isEmpty} from "lodash";

import NmAccordion from "../../NmAccordion";
import NmInfoCard, {INmInfoCard, TNmInfoCardDesktopViewFrom} from "../NmInfoCard";

import bem from "../../../utils/bem";

import "./style.sass";

type TCard = INmInfoCard & {
    isVisible: boolean,
    isHiddenToggleButton: boolean,
    defaultOpen: boolean
};

interface INmPageInfoCardsAccordion {
    cards: Array<TCard>,
    desktopViewFrom?: TNmInfoCardDesktopViewFrom,
    bootstrap?: boolean,
    className?: string,
    isHiddenNameAfterOpening?: boolean,
    // наименование в адаптивном варианте
    name?: string,
    isEachCardAccordion?: boolean,
}

const TRANSLATE_MEDIA = {
    "xxl": {minWidth: 1920},
    "xl": {minWidth: 1200},
    "md": {minWidth: 768},
};

const NmPageInfoCardsAccordion: FC<INmPageInfoCardsAccordion> = (props) => {
    const {
        cards,
        bootstrap = false,
        name,
        isHiddenNameAfterOpening = true,
        isEachCardAccordion = false,
        desktopViewFrom = "xl",
        className = "",
    } = props;
    const [,element] = bem("nm-page-info-cards-accordion");

    const getDesktopCards = (desktop?: boolean) => {
        const gridClassName = bootstrap ? "row" : "nm-page-info-cards-accordion";
        const blockClassName = desktop ? `${gridClassName} ${className}` : `row ${className}`;

        return (
            <div className={blockClassName}>
                {
                    cards.filter(({isVisible = true}) => isVisible).map((value, index) => {
                        return getCard(value, index, desktop);
                    })
                }
            </div>
        );
    };

    const getDefaultCard = (item: TCard, index: number, containerClassName: string) => {
        const {
            className,
            ...props
        } = item;

        return (
            <div
                key={index}
                className={`${className} ${containerClassName}`}
            >
                <NmInfoCard
                    desktopViewFrom={desktopViewFrom}
                    {...props}
                />
            </div>
        );
    };

    const getCard = (item: TCard, index: number, desktop?: boolean) => {
        const {
            className,
            ...props
        } = item;

        const containerClassName = element("card-container", {bootstrap, desktopViewFrom});

        if (isEachCardAccordion) {
            if (desktop) {
                return getDefaultCard(item, index, containerClassName);
            }

            return (
                <NmAccordion
                    key={index}
                    className={`${className} ${containerClassName}`}
                    isHiddenToggleButton={cards.length === 1}
                    isHiddenNameAfterOpening={isHiddenNameAfterOpening}
                    name={getName(item)}
                >
                    <NmInfoCard
                        desktopViewFrom={desktopViewFrom}
                        {...props}
                    />
                </NmAccordion>
            );
        }

        return getDefaultCard(item, index, containerClassName);
    };

    const getName = (card: TCard) => {
        if (name) {
            return (
                <NmInfoCard
                    desktopViewFrom={desktopViewFrom}
                    title={name}
                />
            );
        }

        const {
            className,
            labels,
            ...otherData
        } = card;

        return (
            <NmInfoCard
                desktopViewFrom={desktopViewFrom}
                {...otherData}
            />
        );
    };

    if (isEmpty(cards)) {
        return null;
    }

    const getContent = () => {
        if (isEachCardAccordion) {
            return cards.map((item) => {
                const {
                    isHiddenToggleButton = false,
                    defaultOpen = false,
                } = item;

                return (
                    <NmAccordion
                        className={element("accordion")}
                        isHiddenToggleButton={isHiddenToggleButton}
                        isHiddenNameAfterOpening={isHiddenNameAfterOpening}
                        name={getName(item)}
                        defaultOpen={defaultOpen}
                    >
                        <NmInfoCard
                            desktopViewFrom={desktopViewFrom}
                            {...item}
                        />
                    </NmAccordion>
                );
            });
        }

        return (
            <NmAccordion
                className={className}
                isHiddenToggleButton={cards.length === 1}
                isHiddenNameAfterOpening={isHiddenNameAfterOpening}
                name={getName(cards.filter(({isVisible = true}) => isVisible)[0])}
            >
                {getDesktopCards()}
            </NmAccordion>
        );
    };

    return (
        <Media query={TRANSLATE_MEDIA[desktopViewFrom]}>
            {
                desktop =>
                    desktop ?
                        getDesktopCards(desktop) :
                        getContent()
            }
        </Media>
    );
};

export default NmPageInfoCardsAccordion;