import React from "react";

import {ReactComponent as AdsIcon} from "../../../images/ads_24.svg";
import {ReactComponent as BusinessIcon} from "../../../images/business_24.svg";
import {ReactComponent as ChatIcon} from "../../../images/chat_24.svg";
import {ReactComponent as CloudDownloadIcon} from "../../../images/cloud_download_24.svg";
import {ReactComponent as CloudUploadIcon} from "../../../images/cloud_upload_24.svg";
import {ReactComponent as DescriptionIcon} from "../../../images/description_24.svg";
import {ReactComponent as EdoIcon} from "../../../images/edo_24.svg";
import {ReactComponent as HammerIcon} from "../../../images/hammer_24.svg";
import {ReactComponent as InsertChartIcon} from "../../../images/insert_chart_24.svg";
import {ReactComponent as OrdersIcon} from "../../../images/library_books_24.svg";
import {ReactComponent as LightbulbIcon} from "../../../images/lightbulb_on_24.svg";
import {ReactComponent as MailIcon} from "../../../images/mail_24.svg";
import {ReactComponent as MapIcon} from "../../../images/map-icon.svg";
import {ReactComponent as NotificationsIcon} from "../../../images/notifications_24.svg";
import {ReactComponent as PattentIcon} from "../../../images/pattent_24.svg";
import {ReactComponent as PhoneCallbackIcon} from "../../../images/phone_callback.svg";
import {ReactComponent as ReviewIcon} from "../../../images/rate_review_24.svg";
import {ReactComponent as ReceiptIcon} from "../../../images/receipt_24.svg";
import {ReactComponent as SettingsIcon} from "../../../images/settings_24.svg";
import {ReactComponent as TasksIcon} from "../../../images/tasks.svg";
import {ReactComponent as TopicIcon} from "../../../images/topic_24.svg";
import {ReactComponent as UserIcon} from "../../../images/user_24.svg";
import {ReactComponent as UsersIcon} from "../../../images/users_24.svg";
import {ReactComponent as WalletIcon} from "../../../images/wallet_24.svg";

const ICON: any = {
    business_24: BusinessIcon,
    topic_24: TopicIcon,
    notifications_24: NotificationsIcon,
    hammer_24: HammerIcon,
    description_24: DescriptionIcon,
    users_24: UsersIcon,
    wallet_24: WalletIcon,
    pattent_24: PattentIcon,
    library_books_24: OrdersIcon,
    insert_chart_24: InsertChartIcon,
    mail_24: MailIcon,
    map_24: MapIcon,
    receipt_24: ReceiptIcon,
    cloud_download_24: CloudDownloadIcon,
    cloud_upload_24: CloudUploadIcon,
    settings_24: SettingsIcon,
    tasks: TasksIcon,
    ads_24: AdsIcon,
    rate_review_24: ReviewIcon,
    phone_callback: PhoneCallbackIcon,
    chat_24: ChatIcon,
    user_24: UserIcon,
    lightbulb_on_24: LightbulbIcon,
    edo_24: EdoIcon,
};

interface IMenuIcon {
    name: string,
    className?: string
}

const MenuIcon = (props: IMenuIcon) => {
    const {
        name,
        className,
    } = props;

    return ICON[name] ?
        React.createElement(ICON[name], {className}) :
        null;
};

export default MenuIcon;