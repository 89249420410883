import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import Tabs from "../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";

import {usePagination} from "../../../hooks/usePagination";

import {formatLocalDate} from "../../../utils/dateFormat";
import {_delay} from "../../../utils/delay";
import {formatAmountWithNullChecking, getFullName} from "../../../utils/stringFormat";

import {OBJECT_LOG_DEPOSIT_ACTION} from "../../../constants/objects";

import {
    getObjectsDepositHistoryPage,
    objectsDepositHistoryPageSelector,
    objectsDepositHistoryTotalCountSelector,
    objectsDepositHistoryTotalPagesSelector,
} from "../../../ducks/objects";

export default function ObjectLogDepositHistoryModal(props) {
    const {onClose, logData} = props;
    const {name, clientId, objectId} = logData;

    const [loading, setLoading] = useState(false);
    const [isCivil, setIsCivil] = useState(false);

    const dispatch = useDispatch();

    const list = useSelector(objectsDepositHistoryPageSelector);
    const totalPages = useSelector(objectsDepositHistoryTotalPagesSelector);
    const totalCount = useSelector(objectsDepositHistoryTotalCountSelector);

    const {pageNum, pageSize, onChangePageSize, onPaginationChange} = usePagination();

    useEffect(() => {
        fetchList();
    },
    [pageNum, pageSize, isCivil],
    );

    const fetchList = () => {
        setLoading(true);
        dispatch(
            getObjectsDepositHistoryPage({
                pageNum,
                pageSize,
                objectId,
                clientId,
                isCivil,
                getResult: () => _delay(() => setLoading(false)),
            }),
        );
    };


    function getTabs() {
        return [
            {
                name: "Депозит объекта НПД",
                active: !isCivil,
                onClick: () => setIsCivil(false),
            },
            {
                name: "Депозит объекта НДФЛ",
                active: isCivil,
                onClick: () => setIsCivil(true),
            },
        ];
    }

    function getRows() {
        return list.map(item => {
            const {action, datetime, amount, firstName, lastName, patronymic} = item;

            return {
                ...item,
                key: datetime,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-md-12 col-xxl-12 col-xl-12"
                        primaryHeader={OBJECT_LOG_DEPOSIT_ACTION[action] || action}
                        labels={[
                            {
                                label: "Дата",
                                text: formatLocalDate(datetime, "dd.MM.yyyy HH:mm"),
                            },
                            {
                                label: "Пользователь",
                                text: getFullName(lastName, firstName, patronymic),
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма, ₽",
                                value: formatAmountWithNullChecking(amount),
                                className: "col-16 col-md-4 col-xl-4 col-xxl-4",
                            },
                        ]}
                    />
                ),
            };
        });
    }

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={<NmTitle size="lg">
История изменений депозита объекта
                {name}
            </NmTitle>}
            loading={loading}
        >
            <NmPage
                noPadding
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                <Tabs
                    className="mb-4"
                    panes={getTabs()}
                />
                {!!list.length && <CheckboxList rows={getRows()} />}
                {!list.length && <NmEmptyPageV2
                    title="Данные отсутствуют"
                />}
            </NmPage>
        </NmModal>
    );
}
