import {isEmpty} from "lodash";

import {enableClientSettingsUser} from "../../../utils/enableUser";

export const getClientSettingsOtherRequestData = (values) => {
    const {
        availableClientManagersIds,
        hasAmountBasedDrafts, // для логики формы
        hasVolumeOfWorkBasedDrafts, // для логики формы
        ...form
    } = values;

    return {
        ...form,
        availableClientManagers: !isEmpty(availableClientManagersIds) ?
            availableClientManagersIds.map(enableClientSettingsUser) :
            [],
    };
};