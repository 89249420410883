import React, {useState} from "react";
import {useDispatch} from "react-redux";

import NmButton from "../../../../../components/NmButton";
import PatentsAccountBasisReplenishment from "../../../../patents/account-basis-replenishment";
import DepositReplenishmentAccountModal from "../../../deposit-replenishment-account-modal";

import {generateInvoiceOfDepositReplenishmentByCivil} from "../../../../../ducks/print";

const DepositClientListAccountBasis = (props) => {
    const {
        clientId,
        accountNumber,
        isCivil,
    } = props;
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const openAccountModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onSubmitCivil = (data) => {
        dispatch(generateInvoiceOfDepositReplenishmentByCivil(data));
    };

    const getModal = () => {
        if (!isOpen) {
            return null;
        }

        return isCivil ?
            <PatentsAccountBasisReplenishment
                title="Счет-основание на пополнение депозита НДФЛ"
                accountNumber={accountNumber}
                clientId={clientId}
                onSubmit={onSubmitCivil}
                onClose={onCloseModal}
            />
            :
            <DepositReplenishmentAccountModal
                dependentData={{clientId}}
                close={onCloseModal}
            />;

    };

    return (
        <>
            <NmButton
                color="grey"
                size="xl"
                onClick={openAccountModal}
            >
                Сформировать счет-основание
            </NmButton>
            {getModal()}
        </>
    );
};

export default DepositClientListAccountBasis;