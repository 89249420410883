import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {isEmpty} from "lodash";

import NmForm from "../ActualComponents/NmForm";
import NmListCard from "../ActualComponents/NmList/Card";
import NmModal from "../ActualComponents/NmModal";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import ApplyButtons from "../ApplyButtons";
import CheckboxList from "../CheckboxList";
import NmTitle from "../NmTitle";

import {isNullOrWhitespace} from "../../utils/stringHelper";

import PropTypes from "prop-types";

import "./style.sass";

class CommentsRetailModal extends Component {
    static propTypes = {
        close: PropTypes.func,
        submit: PropTypes.func,
        comments: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
    };

    static defaultProps = {
        close: () => {
        },
        submit: () => {
        },
        status: "",
        person: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            text: "",
            textErrorMessage: false,
            isSubmit: false,
        };
    }

    onChange = (e, {value: text}) => {
        this.setState({text});

        if (isNullOrWhitespace(text)) {
            this.setState({
                isSubmit: true,
            });
            return;
        }
        this.setState({
            textErrorMessage: false,
            isSubmit: true,
        });
    };

    submit = () => {
        const {submit} = this.props;
        const {text} = this.state;

        if (isNullOrWhitespace(text)) {
            this.setState({
                textErrorMessage: "Обязательное поле",
                isSubmit: false,
            });
            return;
        }

        if (text.length > 255) {
            this.setState({
                textErrorMessage: "Максимальная длина - 255 символов",
            });
            return;
        }
        submit(text);
    };

    renderComments() {
        const {
            retail: {
                commentList = [],
            },
        } = this.props;

        if (isEmpty(commentList)) {
            return null;
        }

        return (
            <CheckboxList
                className="comments-retail-modal__list"
                rows={commentList.map((item, index) => {
                    const {clientName, comment} = item;

                    return {
                        ...item,
                        key: index,
                        contentRow: (
                            <NmListCard
                                noDivider
                                labels={[
                                    {label: "Наименование компании", text: clientName},
                                    {label: "Комментарий", text: comment},
                                ]}
                            />
                        ),
                    };
                })}
            />
        );
    }

    render() {
        const {
            close,
            title,
            t,
        } = this.props;
        const {
            text,
            textErrorMessage,
            isSubmit,
        } = this.state;

        const titleText = title || "Комментарии";

        return (
            <NmModal
                size="md"
                className="comments-retail-modal"
                onClose={close}
                footer={
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        submitBtnContent={t("button.save")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={close}
                        disabled={!isSubmit}
                        submit={this.submit}
                    />
                }
                header={
                    <NmTitle size="lg">
                        {titleText}
                    </NmTitle>
                }
            >
                <NmForm className="comments-retail-modal__content">
                    {this.renderComments()}
                    <NmTextareaV2
                        className="comments-retail-modal__textarea"
                        error={textErrorMessage}
                        placeholder="Введите комментарий"
                        minRows={7}
                        maxRows={7}
                        value={text}
                        onChange={this.onChange}
                        maxLength="255"
                    />
                </NmForm>
            </NmModal>
        );
    }
}

export default withTranslation()(CommentsRetailModal);
