import {useState} from "react";
import {useDispatch} from "react-redux";

import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {CONTRACTOR_STATUS} from "../../../constants/contractor";
import {CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES} from "../../../containers/contractor/contractor-verification-data/constants";

import {
    getFileContractorAdditionalScan,
    getFileContractorScan,
} from "../../../ducks/fileStore";

export const useContractorVerificationLoadingScans = (params) => {
    const {
        contractorId,
        previousFiles,
        status,
        edmStatus,
        fetchScansFunc,
        isAdditionalDocuments,
    } = params;

    const [loadedScans, setLoadedScans] = useState([]);
    const [progressScans, setProgressScans] = useState([]);

    const dispatch = useDispatch();

    function isLoadScan(type, pending = true, checkProgress = false) {
        const checkIsLoadingScanCallback = ({contractorFileType, includingPending}) => {
            return contractorFileType === type && includingPending === pending;
        };

        return Boolean(loadedScans.find(checkIsLoadingScanCallback)) ||
            checkProgress && Boolean(progressScans.find(checkIsLoadingScanCallback));
    }

    function handleFetchOriginalScan(contractorFileType, field, includingPending) {
        setProgressScans((prevState) => [...prevState, {contractorFileType, includingPending}]);

        if (fetchScansFunc) {
            fetchScansFunc({
                contractorId,
                contractorFileType,
                isPending: includingPending,
                includingPending,
                thumbnail: false,
                field,
                onSuccess: () => {
                    setLoadedScans((prevState) => [...prevState, {contractorFileType, includingPending}]);
                },
                onError: () => {
                    setLoadedScans((prevState) => [...prevState, {contractorFileType, includingPending}]);
                },
            });

            return;
        }

        if (contractorFileType === CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN) {
            dispatch(getFileContractorAdditionalScan({
                contractorId,
                contractorFileType: CONTRACTOR_FILE_TYPES.INN,
                fileFieldName: CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN,
                thumbnail: false,
                field,
                onSuccess: () => {
                    setLoadedScans((prevState) => [...prevState, {contractorFileType, includingPending}]);
                },
                onError: () => {
                    setLoadedScans((prevState) => [...prevState, {contractorFileType, includingPending}]);
                },
            }));

            return;
        }

        dispatch(
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending: includingPending,
                includingPending,
                thumbnail: false,
                field,
                onSuccess: () => {
                    setLoadedScans((prevState) => [...prevState, {contractorFileType, includingPending}]);
                },
                onError: () => {
                    setLoadedScans((prevState) => [...prevState, {contractorFileType, includingPending}]);
                },
            }),
        );
    }

    function fetchScans(type) {
        if (!isLoadScan(type, true, true)) {
            if (params.fetchScans) {
                params.fetchScans({
                    type,
                    field: "contractorOriginalScanObj",
                    includingPending: false,
                    onSuccess: () => {
                        setLoadedScans((prevState) => [...prevState, {contractorFileType: type, includingPending: true}]);
                    },
                    onError: () => {
                        setLoadedScans((prevState) => [...prevState, {contractorFileType: type, includingPending: true}]);
                    },
                });
            } else {
                handleFetchOriginalScan(type, "contractorOriginalScanObj", true);
            }
        }

        if (
            !isAdditionalDocuments &&
            !isLoadScan(type, false, true) &&
            [status, edmStatus].includes(CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES) &&
            [...new Set(previousFiles.map(({type}) => type))].includes(type)
        ) {
            if (params.fetchScans) {
                params.fetchScans({
                    type,
                    field: "contractorOriginalPreviousScanObj",
                    includingPending: false,
                    onSuccess: () => {
                        setLoadedScans((prevState) => [...prevState, {contractorFileType: type, includingPending: true}]);
                    },
                    onError: () => {
                        setLoadedScans((prevState) => [...prevState, {contractorFileType: type, includingPending: true}]);
                    },
                });
            } else {
                handleFetchOriginalScan(type, "contractorOriginalPreviousScanObj", false);
            }
        }
    }

    return {
        isLoadScan,
        fetchScans,
    };
};