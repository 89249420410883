import React from "react";
import {useDispatch} from "react-redux";

import ImportFromFilePatternV2 from "../../../../../../components/ActualComponents/ImportFromFilePatternV2";

import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

import {importTariffScale} from "../../../../../../ducks/tariff";

export const SettingsDirectoriesTariffGridImport = ({onClose, fetchList}) => {
    const dispatch = useDispatch();

    const onImport = ({setProgress, file}) => {
        setProgress(true);

        const formData = new FormData();

        formData.append("file", file);

        dispatch(importTariffScale({
            formData,
            onSuccess: () => {
                setProgress(false);
                toastSuccess("Сведения успешно загружены в справочник");
                onClose();
                fetchList();
            },
            onError: () => {
                setProgress(false);
                toastError("Сведения не удалось загрузить в справочник");
            },
        }));
    };

    return (
        <ImportFromFilePatternV2
            patternLink="/files/Шаблон_Тарифная сетка.xlsx"
            onSubmit={onImport}
            onClose={onClose}
        />
    );
};