import React from "react";

import {CitizenshipWithFlag} from "../../../../../components/CitizenshipWithFlag";
import ContractorVerificationInfoBlock from "../info-block";

import {formatDateWithoutTime} from "../../../../../utils/dateFormat";
import {getFullRegistrationPassportFields} from "../form/utils/getPassportFields";
import {getContractorPassportTitle} from "../form/utils/getPassportTitle";

import {GENDER_DICT} from "../../../../../constants/contractorInfo";

export const ContractorVerificationDataStampBottomContent = (props) => {
    const {
        localizationData,
        migrationStatus,
        citizenship,
        values,
    } = props;

    const passport = getFullRegistrationPassportFields({
        values,
        citizenship,
        migrationStatus,
        localizationData,
    });

    return (
        <ContractorVerificationInfoBlock
            list={[
                {
                    title: getContractorPassportTitle({
                        migrationStatus,
                        citizenship,
                    }),
                    isTitle: true,
                },
                {
                    title: localizationData.citizenship,
                    value: <CitizenshipWithFlag
                        textLevel="4"
                        citizenship={values.citizenship}
                        flagPositionEnd
                    />,
                },
                ...passport,
                {
                    title: localizationData.titlePersonalData,
                    isTitle: true,
                },
                {
                    title: localizationData.lastName,
                    value: values.lastName,
                },
                {
                    title: localizationData.firstName,
                    value: values.firstName,
                },
                {
                    title: localizationData.patronymic,
                    value: values.patronymic || "Нет",
                },
                {
                    title: localizationData.birthDate,
                    value: formatDateWithoutTime(values.birthDate),
                },
                {
                    title: localizationData.gender,
                    value: GENDER_DICT[values.gender],
                },
            ]}
        />
    );
};