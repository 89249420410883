import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";
import {PATTERN_NUMBER} from "../../../deposit/client-list/hooks/useGetlDepositData";

import {convertUtcToLocal, getEndDate, getStartDate} from "../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

import {LINK_FINANCE_CROWD_PAYMENT_LIST} from "../../../../constants/links";
import {REG_EXP} from "../../../../constants/regExp";

import {history} from "../../../../store/configureStore";

import {STATUS_TASK_PAYMENT_OPTIONS} from "../../../../constants/crowd/task-payment";

const useFinanceCrowdPaymentsFilter = (props) => {
    const {
        pageSize,
        setPagination,
        clientId,
        paymentNumberFilter,
    } = props;

    const initFilter = {
        clientId: null,
        paymentNumber: paymentNumberFilter || "",
        contractorFIO: "",
        contractorInn: "",
        contractorPhone: "",
        taskNumber: "",
        taskName: "",
        statuses: [],
        creationDateFrom: null,
        creationDateTo: null,
        operationDateFrom: null,
        operationDateTo: null,
        amountFrom: "",
        amountTo: "",
        commissionFrom: "",
        commissionTo: "",
    };

    const mapFilterDto = () => {
        const {
            clientId,
            statuses,
            creationDateFrom,
            creationDateTo,
            operationDateFrom,
            operationDateTo,
            paymentNumber,
            taskNumber,
            amountFrom,
            amountTo,
            commissionFrom,
            commissionTo,
            contractorFIO,
            contractorInn,
            contractorPhone,
            taskName,
            projectIdsFilter,
            objectIdsFilter,
        } = filter;

        return {
            clientId: clientId ? clientId : undefined,
            statuses: statuses?.length ? statuses : undefined,
            creationDateFrom: convertUtcToLocal(getStartDate(creationDateFrom)),
            creationDateTo: convertUtcToLocal(getEndDate(creationDateTo)),
            operationDateFrom: convertUtcToLocal(getStartDate(operationDateFrom)),
            operationDateTo: convertUtcToLocal(getEndDate(operationDateTo)),
            paymentNumber: handleNumber(paymentNumber),
            taskNumber: handleNumber(taskNumber),
            amountFrom: handleNumber(amountFrom),
            amountTo: handleNumber(amountTo),
            commissionFrom: handleNumber(commissionFrom),
            commissionTo: handleNumber(commissionTo),
            contractorFIO: handleFormString(contractorFIO),
            contractorInn: handleFormString(contractorInn),
            taskName: handleFormString(taskName),
            contractorPhone: contractorPhone ? removePhoneMask(contractorPhone) : undefined,
            projectIds: projectIdsFilter?.length ? projectIdsFilter : undefined,
            objectIds: objectIdsFilter?.length ? objectIdsFilter : undefined,
        };
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
        setFilter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            !clientId && {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        label: "Заказчик",
                        name: "clientId",
                        isBff: true,
                        search: true,
                    },
                ],
            },
            clientId && {
                row: [
                    {
                        component: FILTER.PROJECT,
                        name: "projectIdsFilter",
                        clientId: clientId,
                        isActiveStyle: false,
                        placeholder: "Показать все",
                    },
                ],
            },
            clientId && {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIdsFilter",
                        clientId: clientId,
                        isActiveStyle: false,
                        projectIdsFilter: filter.projectIdsFilter,
                        placeholder: "Показать все",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "paymentNumber",
                        label: "Номер оплаты",
                        placeholder: "Введите номер оплаты",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorFIO",
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО исполнителя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorInn",
                        label: "ИНН исполнителя",
                        placeholder: "Введите ИНН исполнителя",
                        maskChar: null,
                        mask: "999999999999999999999999999999999999999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "contractorPhone",
                        label: "Телефон исполнителя",
                        placeholder: "Введите телефон исполнителя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNumber",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskName",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "statuses",
                        label: "Статус оплаты по заданию",
                        placeholder: "Не выбран",
                        options: STATUS_TASK_PAYMENT_OPTIONS,
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateFrom",
                        endFieldName: "creationDateTo",
                        isClearable: true,
                        label: "Дата создания оплаты",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "operationDateFrom",
                        endFieldName: "operationDateTo",
                        isClearable: true,
                        label: "Дата последнего статуса оплаты по заданию",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "amountFrom",
                        nameEnd: "amountTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Сумма операции",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "commissionFrom",
                        nameEnd: "commissionTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Комиссия заказчика",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
        ].filter(item => Boolean(item));
    }, [
        clientId,
        filter,
    ]);

    const _onClear = () => {
        if (!clientId && paymentNumberFilter) {
            history.push(LINK_FINANCE_CROWD_PAYMENT_LIST.replace("/:paymentNumberFilter?", ""));

            return;
        }

        onClear();
    };

    return {
        initFilter,
        isSearch,
        onClear: _onClear,
        onSearch,
        filter,
        filterData,
        filters,
        setFilter,
    };
};

export default useFinanceCrowdPaymentsFilter;