import {
    BFF_SYSTEM_TRANSACTIONS_GET_PAGE_REQUEST,
    BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_REQUEST,
    UPDATE_SYSTEM_TRANSACTIONS_STORE,
} from "./actions";

export const getPageSystemTransactions = (payload) => {
    return {
        type: BFF_SYSTEM_TRANSACTIONS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageLogSystemTransaction = (payload) => {
    return {
        type: BFF_SYSTEM_TRANSACTIONS_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateStoreSystemTransactions = (payload) => {
    return {
        type: UPDATE_SYSTEM_TRANSACTIONS_STORE,
        payload,
    };
};