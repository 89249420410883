import React from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";

import {statementsTypesSelector} from "../../../../ducks/edmStatements";

import "./style.sass";

import {EDM_STATEMENTS_STATUS_OPTIONS} from "../../../../constants/document-management/statements";

const DocumentManagementStatementFilter = (props) => {
    const {
        submitFilter,
        initForm,
        setValueTypeFilter,
        filter,
        setFilter,
        onChangeFilter,
    } = props;

    const {
        senderFio,
        receiverFio,
        createdFrom,
        createdTo,
        dateFrom,
        dateTo,
        name,
        approverFio,
        stmtState,
        stmtTypeIds,
    } = filter;

    const statementsTypesOptions = useSelector(statementsTypesSelector);
    const statementsStatusOptions = EDM_STATEMENTS_STATUS_OPTIONS;

    const onSearchTypeChange = (searchQuery) => {
        setValueTypeFilter(searchQuery);
    };

    const submit = () => {
        submitFilter(filter, true);
    };

    const clear = () => {
        submitFilter({
            ...initForm,
        }, false);

        setFilter({
            ...initForm,
        });
    };

    return (
        <NmForm
            addMargin
            className="document-management-statement-filter"
        >
            <FilterButtonsV2
                className="document-management-statement-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
            <NmInputV2
                size="lg"
                label="Наименование заявления"
                name="name"
                value={name}
                onChange={onChangeFilter}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="createdFrom"
                endFieldName="createdTo"
                value={{createdFrom, createdTo}}
                label="Дата заявления"
                onChange={onChangeFilter}
                className="document-management-statement-filter__datepicker-range"
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="dateFrom"
                endFieldName="dateTo"
                value={{dateFrom, dateTo}}
                label="Период загрузки заявления"
                onChange={onChangeFilter}
                className="document-management-statement-filter__datepicker-range document-management-statement-filter__last-date-picker"

            />
            <NmInputV2
                size="lg"
                label="ФИО отправителя"
                name="senderFio"
                value={senderFio}
                onChange={onChangeFilter}
            />
            <NmInputV2
                size="lg"
                label="ФИО согласующего"
                name="approverFio"
                value={approverFio}
                onChange={onChangeFilter}
            />
            <NmInputV2
                size="lg"
                label="ФИО получателя"
                name="receiverFio"
                value={receiverFio}
                onChange={onChangeFilter}
            />
            <NmDropdownV2
                size="lg"
                label="Тип заявления"
                name="stmtTypeIds"
                search
                onChange={onChangeFilter}
                onSubmit={submit}
                value={stmtTypeIds}
                options={statementsTypesOptions}
                multiple
                onSearchChange={onSearchTypeChange}
            />
            <NmDropdownV2
                size="lg"
                label="Статус заявления"
                name="stmtState"
                onChange={onChangeFilter}
                value={stmtState}
                options={statementsStatusOptions}
            />
        </NmForm>
    );
};

export default DocumentManagementStatementFilter;