import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {AddContractorToRegistry} from "../../../../../components/ActualComponents/AddContractorToRegistry";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import {getFullName, phoneFormat} from "../../../../../utils/stringFormat";

import {COLOR} from "../../../../../constants/color";

import {
    addItemToAdditionalAgreementRegistry,
    clearDocumentsAdditionalAgreementContractors,
    getAdditionalAgreementContractorCandidates,
    getAdditionalAgreementFrameContractorCandidates,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/actionCreators";
import {
    bffAdditionalAgreementRegistryContractorsSelector,
    bffAdditionalAgreementRegistryFrameContractsOptionsSelector,
    bffAdditionalAgreementsRegistryProgressActionSelector,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/selectors";

export const AddContractorToAdditionalAgreementRegistry = (props) => {
    const {
        id,
        clientId,
        onClose,
    } = props;

    const contractors = useSelector(bffAdditionalAgreementRegistryContractorsSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearDocumentsAdditionalAgreementContractors());
        };
    }, []);

    const getContractors = (data) => {
        dispatch(getAdditionalAgreementContractorCandidates(data));
    };

    return (
        <AddContractorToRegistry
            clientId={clientId}
            onClose={onClose}
            totalPages={contractors.totalPages}
            list={contractors.list}
            totalCount={contractors.totalCount}
            progressSearch={contractors.progress}
            getContractors={getContractors}
        >
            {
                (params) => (
                    <SubmitContractor
                        id={id}
                        onClose={onClose}
                        {...params}
                    />
                )
            }
        </AddContractorToRegistry>
    );
};

const SubmitContractor = (props) => {
    const {
        id,
        onClose,
        contractorData: {
            lastName,
            firstName,
            patronymic,
            inn,
            phone,
            contractorId,
        },
    } = props;

    const dispatch = useDispatch();

    const frameContractOptions = useSelector(bffAdditionalAgreementRegistryFrameContractsOptionsSelector);
    const progress = useSelector(bffAdditionalAgreementsRegistryProgressActionSelector);

    useEffect(() => {
        dispatch(getAdditionalAgreementFrameContractorCandidates({
            id,
            contractorId,
        }));
    }, []);

    const {
        handleSubmit,
        values,
        setFieldValue,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            frameContractIds: [],
        },
        validateOnBlur: false,
    });

    function onSubmit() {
        dispatch(addItemToAdditionalAgreementRegistry({
            registryId: id,
            contractorId,
            frameContractIds: values.frameContractIds,
        }));
    }

    const isChecked = !isEmpty(frameContractOptions) && frameContractOptions.length === values.frameContractIds.length;

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Поиск исполнителя
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="column"
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={isEmpty(values.frameContractIds)}
                    cancelBtnContent="Отменить"
                    submitBtnContent="Добавить"
                />
            }
            onClose={onClose}
            loading={progress}
            classNameContent="flex-column"
        >
            <NmForm addMargin={true}>
                <div>
                    <Text.Sub
                        level="2"
                        medium={true}
                        className="mb-1"
                        color={COLOR.SECONDARY_90}
                    >
                        {getFullName(
                            lastName,
                            firstName,
                            patronymic,
                        )}
                    </Text.Sub>
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_75}
                    >
                        {`${inn}, ${phoneFormat(phone)}`}
                    </Text>
                </div>
                <div>
                    <Text.Sub
                        level="3"
                        medium={true}
                        color={COLOR.SECONDARY_100}
                        className="mb-2 mb-md-3"
                    >
                        Договоры
                    </Text.Sub>
                    <NmCheckboxV2
                        className="mb-3"
                        onChange={(_event, {checked}) => {
                            if (isChecked) {
                                setFieldValue("frameContractIds", []);

                                return;
                            }

                            const frameContractIds = frameContractOptions.map(item => item.value);

                            setFieldValue("frameContractIds", frameContractIds);
                        }}
                        checked={isChecked}
                        label="Выбрать все договоры"
                    />
                    <NmDropdownV2
                        placeholder="Выберите договоры"
                        multiple={true}
                        options={frameContractOptions}
                        value={values.frameContractIds}
                        onChange={(_event, {value}) => {
                            setFieldValue("frameContractIds", value);
                        }}
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};