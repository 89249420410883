import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import {ceil, round} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmPageInfoCardsAccordion from "../../../components/ActualComponents/NmPageInfoCardsAccordion";
import NmRadioV2 from "../../../components/ActualComponents/NmRadioV2";
import NmTitle from "../../../components/NmTitle";
import {
    Form,
} from "semantic-ui-react";

import {updateCaretPosition} from "../../../utils/dom";
import {
    clearSpace,
    divideNumberIntoDigits,
    formatAmount,
    formatNumber,
} from "../../../utils/stringFormat";
import {handleNumber, isNullOrWhitespace} from "../../../utils/stringHelper";

import {amountRegexDelimited} from "../../../constants/validationRules";

import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";

import PropTypes from "prop-types";

import "./style.sass";

const COMMISSION_TYPE = {
    NET: "NET",
    GROSS: "GROSS",
    GROSS_WITHOUT_COMMISSION: "GROSS_WITHOUT_COMMISSION",
};

class DepositCalculator extends Component {
    static propTypes = {
        amount: PropTypes.number,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        amount: 100,
        handleClose: () => {
        },
    };

    constructor(props) {
        super(props);
        const {amount} = props;

        this.state = {
            amount: amount || 100,
            commissionType: COMMISSION_TYPE.NET,
            deposit: 0,
            order: 0,
            contractor: 0,
            tax: 0,
            contractorCommissionWarning: "",
        };
    }

    componentDidMount() {
        const {
            clientProperties: {
                currentCommissionRate,
                contractorSmzOrderPaymentsCommission,
            },
        } = this.props;

        this.setState({
            clientCommission: isNullOrWhitespace(currentCommissionRate) ? 0 : (currentCommissionRate * 100).toFixed(2) * 1,
            contractorCommission: isNullOrWhitespace(contractorSmzOrderPaymentsCommission) ? 0 : (contractorSmzOrderPaymentsCommission * 100).toFixed(2) * 1,
        }, this.recalc);
    }

    handleChangeRadioGroup = (commissionType) => {
        return () => {
            this.setState({
                commissionType,
            }, this.recalc);
        };
    };

    recalc = () => {
        const {
            clientProperties: {
                minCommissionAmount,
                paymentsThresholdAmount,
            },
        } = this.props;

        const {
            amount: rawAmount,
            commissionType,
            clientCommission: commission,
            contractorCommission: contrCommission,
        } = this.state;

        let deposit, order, orderSystem, contractor, tax, baseDepositRate, baseOrderRate, baseCashRate, sum;

        const amount = handleNumber(clearSpace(rawAmount));
        const contractorTax = 0.06;

        const clientCommission = Number.parseFloat(handleNumber(clearSpace(commission))) / 100;

        const contractorCommission = Number.parseFloat(handleNumber(clearSpace(contrCommission))) / 100;

        switch (commissionType) {
        case COMMISSION_TYPE.NET:
            // % депозита от базы
            baseDepositRate = (1 + (contractorTax + contractorCommission + clientCommission) / (1 - contractorTax - contractorCommission));
            // % заказа от базы
            baseOrderRate = baseDepositRate / (1 + clientCommission);
            // Расчетный заказ
            order = round(amount * baseOrderRate, 2);
            // Налог
            tax = ceil(round(order * contractorTax, 4), 2);
            // Заказ в системе
            orderSystem = round((amount + tax) / (1 - contractorCommission), 2);
            // Депозит
            deposit = round(orderSystem * (1 + clientCommission), 2);
            // Сумма на карту без расщепления
            sum = orderSystem - round(orderSystem * contractorCommission, 2);
            // Сумма на карту с расщеплением
            contractor = sum - tax;
            break;
        case COMMISSION_TYPE.GROSS_WITHOUT_COMMISSION:
            baseDepositRate = (1 - contractorTax) * (1 + clientCommission) / (1 - contractorCommission - contractorTax);
            baseOrderRate = baseDepositRate / (1 + clientCommission);
            order = round(amount * baseOrderRate, 2);
            baseCashRate = baseOrderRate * (1 - contractorCommission - contractorTax);
            tax = ceil(round(order * contractorTax, 4), 2);
            orderSystem = round((baseCashRate * amount + tax) / (1 - contractorCommission), 2);
            deposit = round(orderSystem * (1 + clientCommission), 2);
            sum = orderSystem - round((orderSystem * contractorCommission), 2);
            contractor = sum - tax;
            break;
        case COMMISSION_TYPE.GROSS:
            baseDepositRate = (1 + clientCommission);
            baseOrderRate = baseDepositRate / (1 + clientCommission);
            order = round(amount * baseOrderRate, 2);
            baseCashRate = 1 - contractorCommission - contractorTax;
            tax = ceil(round(order * contractorTax, 4), 2);
            orderSystem = round(order, 2);
            deposit = round(orderSystem * (1 + clientCommission), 2);
            sum = orderSystem - round((orderSystem * contractorCommission), 2);
            contractor = sum - tax;
            break;
            // no default
        }

        if (order < paymentsThresholdAmount && clientCommission !== 0) {
            deposit += minCommissionAmount;
        }

        this.setState({
            deposit, order: orderSystem, contractor, tax,
        });
    };

    onChange = (e, {value, name}) => {
        if ("clientCommission" === name && Number.parseFloat(value || 0) > 100) {
            return;
        }

        value = clearSpace(value);

        if (value && !amountRegexDelimited.test(value)) {
            return;
        }

        const {amount} = this.state;

        value = divideNumberIntoDigits(value);


        if ("contractorCommission" === name) {
            if (Number.parseFloat(value || 0) > 49.99) {
                return this.setState({
                    [name]: "49.99",
                    contractorCommissionWarning: "Значение не может превышать 49.99",
                }, () => {
                    this.recalc();
                });
            }

            this.resetContractorCommissionWarning();
        }

        const element = e.target;
        const caretPosition = e.target.selectionStart;

        this.setState({
            [name]: value || 0,
        }, () => {
            this.recalc();

            if (!["clientCommission", "contractorCommission"].includes(name)) {
                updateCaretPosition(value, amount, element, caretPosition);
            }
        });
    };

    resetContractorCommissionWarning = () => {
        this.setState({
            contractorCommissionWarning: "",
        });
    };

    renderCommissionRadioGroup() {
        const {commissionType} = this.state;

        const {t} = this.props;

        return (
            <>
                <div className="deposit-calc__agreement-type">
                    {t("deposit-calculator.contractor-agreement-type")}
                </div>
                <Form.Field>
                    <div className="deposit-calc__radio-container">
                        <NmRadioV2
                            className="deposit-calc__radio"
                            label={t("deposit-calculator.net-income")}
                            checked={commissionType === COMMISSION_TYPE.NET}
                            onChange={this.handleChangeRadioGroup(COMMISSION_TYPE.NET)}
                        />
                        <HelpTooltip
                            className="deposit-calc__info-tooltip"
                            width={18}
                            height={18}
                            type="light"
                            position="bottom-left"
                            text={t("deposit-calculator.contractor-commission-and-taxes-at-client-expense")}
                        />
                    </div>
                </Form.Field>
                <Form.Field>
                    <div className="deposit-calc__radio-container">
                        <NmRadioV2
                            className="deposit-calc__radio"
                            label={t("deposit-calculator.gross-income-without-commission")}
                            checked={commissionType === COMMISSION_TYPE.GROSS_WITHOUT_COMMISSION}
                            onChange={this.handleChangeRadioGroup(COMMISSION_TYPE.GROSS_WITHOUT_COMMISSION)}
                        />
                        <HelpTooltip
                            className="deposit-calc__info-tooltip"
                            width={18}
                            height={18}
                            type="light"
                            position="bottom-left"
                            text={t("deposit-calculator.contractor-commission-at-client-expense")}
                        />
                    </div>
                </Form.Field>
                <Form.Field>
                    <div className="deposit-calc__radio-container">
                        <NmRadioV2
                            className="deposit-calc__radio"
                            label={t("deposit-calculator.gross-income")}
                            checked={commissionType === COMMISSION_TYPE.GROSS}
                            onChange={this.handleChangeRadioGroup(COMMISSION_TYPE.GROSS)}
                        />
                        <HelpTooltip
                            className="deposit-calc__info-tooltip"
                            width={18}
                            height={18}
                            type="light"
                            position="bottom-left"
                            text={t("deposit-calculator.platform-commission-and-taxes-are-paid-by-contractor")}
                        />
                    </div>
                </Form.Field>
            </>
        );
    };

    getCalculatedBlock = ({mobile, tablet}) => {
        const {t} = this.props;
        const {
            deposit,
            order,
            contractor,
            tax,
        } = this.state;

        const isMobileAndTablet = mobile || tablet;

        return (
            <NmPageInfoCardsAccordion
                bootstrap
                className="gx-4"
                cards={[
                    {
                        title: t("deposit-calculator.deposit"),
                        value: `${formatNumber(formatAmount(deposit.toFixed(2)))} ${mobile ? "₽" :t("rub")}`,
                        className: "col-xl-8 col-xxl-8 mb-xl-4",
                        helpTooltip: {
                            text: t("deposit-calculator.you-need-to-make-a-deposit-for-this-sum"),
                            position: isMobileAndTablet ? "bottom-left" : "bottom",
                            type: "light",
                        },
                    },
                    {
                        title: t("deposit-calculator.order"),
                        value: `${formatNumber(formatAmount(order.toFixed(2)))} ${mobile ? "₽" :t("rub")}`,
                        className: "col-xl-8 col-xxl-8 mb-xl-4",
                        helpTooltip: {
                            text: t("deposit-calculator.to-create-an-order-for-this-sum"),
                            position: isMobileAndTablet ? "bottom-left" : "bottom-right",
                            type: "light",
                        },
                    },
                    {
                        title: t("deposit-calculator.contractor"),
                        value: `${formatNumber(formatAmount(contractor.toFixed(2)))} ${mobile ? "₽" :t("rub")}`,
                        className: "col-xl-8 col-xxl-8",
                        helpTooltip: {
                            text: t("deposit-calculator.will-get-on-hand"),
                            position: isMobileAndTablet ? "bottom-left" : "bottom",
                            type: "light",
                        },
                    },
                    {
                        title: t("deposit-calculator.tax"),
                        value: `${formatNumber(formatAmount(tax.toFixed(2)))} ${mobile ? "₽" :t("rub")}`,
                        className: "col-xl-8 col-xxl-8",
                        helpTooltip: {
                            text: t("deposit-calculator.to-contractor-card-or-wallet"),
                            position: isMobileAndTablet ? "bottom-left" : "bottom-right",
                            type: "light",
                        },
                    },
                ]}
            />
        );
    };

    renderFields(matches) {
        const {t} = this.props;
        const {
            amount,
            clientCommission,
            contractorCommission,
        } = this.state;

        return (
            <div className="deposit-calc__container">
                <div className="deposit-calc__row">
                    <NmInputV2
                        size="xl"
                        onChange={this.onChange}
                        name="amount"
                        className="deposit-calc__amount"
                        value={amount || ""}
                        label={t("deposit-calculator.amount")}
                        placeholder={t("deposit-calculator.amount")}
                    />
                    <NmInputV2
                        size="xl"
                        onChange={this.onChange}
                        name="clientCommission"
                        className="deposit-calc__commission"
                        value={clientCommission || ""}
                        label="Комиссия заказчика, %"
                        placeholder={t("deposit-calculator.commission")}
                    />
                    <NmInputV2
                        size="xl"
                        onChange={this.onChange}
                        name="contractorCommission"
                        className="deposit-calc__cntr-commission"
                        value={contractorCommission || ""}
                        label="Комиссия исполнителя, %"
                        placeholder={t("deposit-calculator.commission")}
                        warning={this.state.contractorCommissionWarning}
                        onBlur={this.resetContractorCommissionWarning}
                    />
                </div>
                {
                    this.getCalculatedBlock(matches)
                }
            </div>
        );
    }

    render() {
        const {t, handleClose} = this.props;

        return (
            <NmModal
                size="md"
                onClose={handleClose}
                header={
                    <NmTitle size="lg">
                        {t("deposit-calculator.deposit-calculator-title")}
                    </NmTitle>
                }
                classNameContent="deposit-calc__content"
            >
                <NmForm addMargin>
                    {
                        this.renderCommissionRadioGroup()
                    }
                    <Media
                        queries={{
                            mobile: {maxWidth: 767},
                            tablet: {minWidth: 768, maxWidth: 1199},
                        }}
                    >
                        {(matches) => this.renderFields(matches)}
                    </Media>
                </NmForm>
            </NmModal>
        );
    };
}

export default connect(
    state => ({
        clientProperties: getClientPropertiesCardSelector(state),
    }), {},
)(withTranslation()(DepositCalculator));