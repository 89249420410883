import React from "react";
import {Route, Switch, withRouter} from "react-router";

import {SettingsDirectoriesBanksBalances} from "../balances";
import {SettingsDirectoriesBanksDetails} from "../details";
import {SettingsDirectoriesBanksSbp} from "../sbp";

import {
    LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES,
    LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
    LINK_SETTINGS_DIRECTORIES_BANKS_SBP,
} from "../../../../../constants/links";

const SettingsDirectoriesBanksRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS}
                component={SettingsDirectoriesBanksDetails}
            />
            <Route
                exact
                path={LINK_SETTINGS_DIRECTORIES_BANKS_SBP}
                component={SettingsDirectoriesBanksSbp}
            />
            <Route
                exact
                path={LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES}
                component={SettingsDirectoriesBanksBalances}
            />
        </Switch>
    );
};

export default withRouter(SettingsDirectoriesBanksRoute);