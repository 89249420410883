import {all, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractor/bank/check";

const GET_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST = "GET_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST";
const GET_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS = "GET_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS";
const GET_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR = "GET_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR";

const REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST = "REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST";
const REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS = "REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS";
const REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR = "REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR";

const UPDATE_FIELDS_CONTRACTOR_PAYMENT_POSSIBILITY = "UPDATE_FIELDS_CONTRACTOR_PAYMENT_POSSIBILITY";

const REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_REQUEST = "REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_REQUEST";
const REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_SUCCESS = "REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_SUCCESS";
const REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_ERROR = "REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_ERROR";

export let bankCheckCancelToken = axios.CancelToken.source();

const initialState = {
    progress: false,
    refreshActionProcess: false,
    contractorsPossibility: [],
    registryPaymentsPossibility: [],
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR:
        return {
            ...state,
            refreshActionProcess: false,
        };
    case REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST:
        return {
            ...state,
            refreshActionProcess: true,
        };
    case REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS:
        return {
            ...state,
            refreshActionProcess: false,
        };
    case GET_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST:
        return {
            ...state,
        };
    case GET_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS:
        const {
            nameField = "contractorsPossibility",
            data: {items},
        } = payload;

        return {
            ...state,
            [nameField]: items,
        };
    case UPDATE_FIELDS_CONTRACTOR_PAYMENT_POSSIBILITY:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export function getContractorsPaymentPossibility(payload) {
    return {
        type: GET_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST,
        payload,
    };
}

export function updateFieldsContractorPaymentPossibilityState(payload) {
    return {
        type: UPDATE_FIELDS_CONTRACTOR_PAYMENT_POSSIBILITY,
        payload,
    };
}

export function refreshContractorsPaymentPossibility(payload) {
    return {
        type: REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST,
        payload,
    };
}

export function refreshOrderContractorsBankPossibility(payload) {
    return {
        type: REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_REQUEST,
        payload,
    };
}

const contractorPaymentPossibilitySelector = ({contractorBankCheck}) => contractorBankCheck;
export const contractorPaymentPossibilityRefreshActionProgressSelector = createSelector(contractorPaymentPossibilitySelector, ({refreshActionProcess}) => refreshActionProcess);
export const contractorsPaymentPossibilityListSelector = createSelector(contractorPaymentPossibilitySelector, ({contractorsPossibility}) => contractorsPossibility);
export const contractorRegistryPaymentPossibilityListSelector = createSelector(contractorPaymentPossibilitySelector, ({registryPaymentsPossibility}) => registryPaymentsPossibility);

function* getContractorsPaymentPossibilitySaga({payload}) {
    try {
        const {
            handleResponse = () => {},
            nameField,
            onRequest,
            ...reqData
        } = payload;

        if (onRequest) {
            onRequest();
        }

        bankCheckCancelToken = axios.CancelToken.source();

        const data = yield request.axios({
            method: "POST",
            url: `${controller}/cache/v2/find`,
            data: reqData,
            cancelToken: bankCheckCancelToken.token,
        });

        const {errorMessage} = data;

        if (errorMessage) {
            yield put({
                type: GET_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        //handleResponse(data);

        yield put({
            type: GET_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS,
            payload: {
                data,
                nameField,
            },
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR,
            payload: error,
        });
    }
}

function* refreshContractorsPaymentPossibilitySaga({payload}) {
    try {
        const {
            handleResponse = () => {},
            contractorIds,
            useFnsThreshold,
        } = payload;

        const result = yield request.post(`${controller}/cache/v2/refresh`, {contractorIds, useFnsThreshold});

        handleResponse(result);

        yield put({
            type: REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_ERROR,
        });
    }
}

function* refreshOrderContractorsPaymentPossibilitySaga({payload}) {
    try {
        const result = yield request.post(`${controller}/cache/v2/refresh/order-contractors`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_ERROR,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST, getContractorsPaymentPossibilitySaga),
        takeEvery(REFRESH_CONTRACTORS_PAYMENT_POSSIBILITY_REQUEST, refreshContractorsPaymentPossibilitySaga),
        takeEvery(REFRESH_ORDER_CONTRACTORS_BANK_POSSIBILITY_REQUEST, refreshOrderContractorsPaymentPossibilitySaga),
    ]);
}