import React from "react";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";

import {EDM_STAFF_PRIVILEGES} from "../../../../../constants/edmStaff";

const RequestInnList = (props) => {
    const {
        className,
        onChangeRequestInnList,
        isRequestInnList,
        disabled,
    } = props;

    return (
        <NmCheckboxV2
            info
            disabled={disabled}
            className={className}
            onChange={onChangeRequestInnList}
            label="Запросить лист с ИНН"
            helpTooltipType="right"
            name={EDM_STAFF_PRIVILEGES.RECEIVE_STATEMENT}
            checked={isRequestInnList}
            isVisibleTooltip
            tooltip={
                <HelpTooltip
                    hover
                    type="light"
                >
                    Пользователю будет предложено подтвердить ИНН одним из вариантов:
                    <ol style={{paddingLeft: 12, margin: 0}}>
                        <li>
Фотография свидетельства о постановке на учет физического лица в налоговом органе (ИНН)
                        </li>
                        <li>
Скриншот личного кабинета Госуслуги, с присутствующим полем ИНН
                        </li>
                        <li>
Скриншот личного кабинета Мой налог, с присутствующим полем ИНН
                        </li>
                        <li>
Фотография патента на осуществление трудовой деятельности
                        </li>
                    </ol>
                </HelpTooltip>
            }
        />
    );
};

export default RequestInnList;