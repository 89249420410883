import React from "react";
import {Route, Switch, withRouter} from "react-router";

import TemplatesActsOrders from "../acts-orders";
import TemplatesActsTasks from "../acts-tasks";
import AdditionalAgreementList from "../additional-agreements";
import TemplatesApplicationsOrders from "../applications-orders";
import TemplatesApplicationsTasks from "../applications-tasks";
import TemplatesContracts from "../contracts";
import TemplatesLogs from "../logs";
import OtherDocumentsList from "../other-documents";

import {
    LINK_CLIENT_CARD_TEMPLATES,
    LINK_TEMPLATES,
} from "../../../../constants/links";
import {
    TEMPLATES_SUB_TAB,
    TEMPLATES_TAB,
} from "../../constants";

const TemplatesRoute = (props) => {
    const {clientId} = props.match.params;

    let LINK = LINK_TEMPLATES;

    if (clientId) {
        LINK = LINK_CLIENT_CARD_TEMPLATES;
    }

    return (
        <Switch>
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.CONTRACTS.LINK}`}
                component={TemplatesContracts}
            />
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.APPLICATIONS.LINK}/${TEMPLATES_SUB_TAB.ORDERS.LINK}`}
                component={TemplatesApplicationsOrders}
            />
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.APPLICATIONS.LINK}/${TEMPLATES_SUB_TAB.TASKS.LINK}`}
                component={TemplatesApplicationsTasks}
            />
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.ACTS.LINK}/${TEMPLATES_SUB_TAB.ORDERS.LINK}`}
                component={TemplatesActsOrders}
            />
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.ACTS.LINK}/${TEMPLATES_SUB_TAB.TASKS.LINK}`}
                component={TemplatesActsTasks}
            />
            {
                !clientId &&
                <Route
                    exact
                    path={`${LINK}/${TEMPLATES_TAB.LOGS.LINK}`}
                    component={TemplatesLogs}
                />
            }
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.ADDITIONAL_AGREEMENT.LINK}`}
                component={AdditionalAgreementList}
            />
            <Route
                exact
                path={`${LINK}/${TEMPLATES_TAB.OTHER_DOCUMENTS.LINK}`}
                component={OtherDocumentsList}
            />
        </Switch>
    );
};

export default withRouter(TemplatesRoute);