import React from "react";
import {useSelector} from "react-redux";

import ContractorFinanceIncome from "../income";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
} from "../../../../constants/links";

import {
    contractorFinanceIncomeNdflListSelector,
    contractorFinanceIncomeNdflProgressSelector,
    contractorFinanceIncomeNdflTotalCountSelector,
    contractorFinanceIncomeNdflTotalPagesSelector,
    contractorSummaryFinanceIncomeNdflSelector,
    getSummaryContractorFinanceIncomeNdfl,
    updateContractorFinanceIncomeNdflStore,
} from "../../../../ducks/contractorFinanceIncomeNdfl";

export const ContractorFinanceIncomeNdfl = (props) => {
    const list = useSelector(contractorFinanceIncomeNdflListSelector);
    const progress = useSelector(contractorFinanceIncomeNdflProgressSelector);
    const totalPages = useSelector(contractorFinanceIncomeNdflTotalPagesSelector);
    const totalCount = useSelector(contractorFinanceIncomeNdflTotalCountSelector);

    return (
        <ContractorFinanceIncome
            isNdfl
            tabsLinks={{
                awaiting: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
                completed: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
                inProcess: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
            }}
            list={list}
            progress={progress}
            totalPages={totalPages}
            totalCount={totalCount}
            clearStore={updateContractorFinanceIncomeNdflStore}
            balanceSelector={contractorSummaryFinanceIncomeNdflSelector}
            fetchSummaryData={getSummaryContractorFinanceIncomeNdfl}
            {...props}
        />
    );
};
