import React from "react";

import {ReactComponent as IconChevron} from "../../images/chevron-down.svg";
import NmButton from "../NmButton";

import {useBackPrevPage} from "./hooks/usePrevPage";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const ButtonBack = (props) => {
    const {
        size = "lg",
        isMobile,
        className = "",
        pathname,
    } = props;
    const [block, element] = bem("nm-button-back", className);

    const {
        navigateBack,
    } = useBackPrevPage();

    const onClick = () => {
        if (pathname) {
            navigateBack({
                pathname,
            });

            return;
        }

        props.onClick();
    };

    return (
        <NmButton
            {...props}
            size={size}
            color="grey"
            className={block({isMobile})}
            onlyIcon
            icon={
                <IconChevron
                    className={element("icon")}
                />
            }
            onClick={onClick}
        />
    );
};

ButtonBack.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ButtonBack;