import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {updateRecruitmentVacancyCandidatesStore} from "../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentVacancyCandidatesErrorSelector,
    bffRecruitmentVacancyCandidatesProgressActionSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import NmConfirmV2 from "../../ActualComponents/NmConfirmV2";

import {toastError} from "../../../utils/toastHelper";

const MESSAGE = ":errorMessage. Вы действительно хотите создать аналогичного кандидата?";

const RecruitmentCandidateError = (props) => {
    const {
        onSubmit,
    } = props;

    const error = useSelector(bffRecruitmentVacancyCandidatesErrorSelector);
    const progressAction = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!error?.errorMessage) {
            return;
        }

        if (error.errorCode !== "DUPLICATE_CANDIDATE_FOUND") {
            toastError(error.errorMessage);
        }
    }, [error]);

    useEffect(() => {
        return () => {
            clearStore();
        };
    }, []);

    const clearStore = () => {
        dispatch(updateRecruitmentVacancyCandidatesStore({
            error: null,
        }));
    };

    if (error?.errorCode !== "DUPLICATE_CANDIDATE_FOUND") {
        return null;
    }

    const onCancel = () => {
        clearStore();
    };

    return (
        <NmConfirmV2
            content={MESSAGE.replace(":errorMessage", error.errorMessage)}
            onCancel={onCancel}
            onConfirm={() => {
                onSubmit({
                    ignoreDuplicates: true,
                    onSuccess: onCancel,
                });
            }}
            isNeedClosing={false}
            confirmButton="Подтвердить"
            cancelButton="Отменить"
            disabled={progressAction}
        />
    );
};

export default RecruitmentCandidateError;