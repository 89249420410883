import {KEDO_LOGS_INTEGRATION_1C_PAGE_REQUEST, KEDO_LOGS_UPDATE_STORE} from "./actions";

export const getKedoLogsIntegration1cPage = (payload) => {
    return {
        type: KEDO_LOGS_INTEGRATION_1C_PAGE_REQUEST,
        payload,
    };
};

export const updateKedoLogsStore = (payload) => {
    return {
        type: KEDO_LOGS_UPDATE_STORE,
        payload,
    };
};