import React, {FC, useState} from "react";
import {DropzoneOptions,useDropzone} from "react-dropzone";

import ErrorTooltip from "../../components/ActualComponents/ErrorTooltip";
import {ReactComponent as Svg} from "../../images/cross.svg";

import bem from "../../utils/bem";

import "./style.sass";

const DESCRIPTION = "Выберите или перетащите файл";

const getAcceptedExtensions = (mimeTypes: Array<string> | string): string => {
    const types = Array.isArray(mimeTypes) ? mimeTypes : [mimeTypes];
    const result = types.map((type: string) => `*${type.split("/")[1]}`).join(", ");

    return result;
};

const getKbFromMb = (mb: number) => mb * 1024 * 1024;

interface IDropzoneAppMini extends DropzoneOptions {
    maxSizeMb?: number,
    description?: string,
    className?: string,
    children?: never,
}

interface IThumb extends File {
    preview: string,
} 
// компонент не адаптирован под множественную загрузку файлов, так как нет требований и дизайна
const DropzoneAvatar: FC<IDropzoneAppMini> = (props) => {
    const {
        description = DESCRIPTION,
        className,
        disabled,
        ...dropzoneProps
    } = props;

    const [block, element] = bem("dropzone-app-mini", className);

    const [errors, setErrors] = useState<string | null>(null);

    const {getRootProps, getInputProps} = useDropzone({
        onDropRejected: rejectedFiles => {
            if (dropzoneProps.maxSizeMb && (rejectedFiles[0].file.size > getKbFromMb(dropzoneProps.maxSizeMb))) {
                setErrors("Превышен максимальный размер файла");
            }

            if (Array.isArray(dropzoneProps.accept) && dropzoneProps.accept?.length !== 0) {
                const result = !dropzoneProps.accept.includes(rejectedFiles[0].file.type) ? "Неверный тип файла" : null;

                setErrors(result);
            }
        },
        onDropAccepted: () => {
            setErrors(null);
        },
        disabled,
        maxSize: dropzoneProps.maxSizeMb ? getKbFromMb(dropzoneProps.maxSizeMb) : undefined,
        multiple: false,
        ...dropzoneProps,
    });

    return (
        <div 
            className={block({error: Boolean(errors), disabled})}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <header className={element("description")}>
                {description}
            </header>
            <div className={element("icon-wrapper")}>
                <Svg className={element("icon")} />
            </div>
            {
                (dropzoneProps.accept || dropzoneProps.maxSizeMb) &&
                <footer className={element("footer")}>
                    {
                        dropzoneProps.accept &&
                        <div className={element("accept-types")}>
                            {`В формате ${getAcceptedExtensions(dropzoneProps.accept)}`}
                        </div>
                    }
                    {
                        dropzoneProps.maxSizeMb &&
                        <div className={element("max-size")}>
                            {`Максимальный размер ${dropzoneProps.maxSizeMb}MB`}
                        </div> 
                    }
                </footer>
            }
            {
                errors &&
                <ErrorTooltip 
                    className={element("error")}
                    error={errors}
                /> 
            }
        </div>
    );
};

export default DropzoneAvatar;