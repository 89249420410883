import React from "react";

import {ReactComponent as IconReset} from "../../images/reset_24.svg";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const RefreshButton = props => {
    const {
        containerIcon,
        onClick,
        animate,
        className = "",
        disabled = false,
        style = {},
    } = props;
    const [block] = bem("refresh-button", className);

    const handleClick = () => {
        if (disabled) {
            return;
        }

        onClick();
    };

    if (containerIcon) {
        return (
            <IconReset
                className={block({animate, disabled, containerIcon})}
                onClick={handleClick}
                style={style}
            />
        );
    }

    return (
        <div
            onClick={handleClick}
            className={`material-icons ${block({animate, disabled})}`}
        >
            autorenew
        </div>
    );
};

RefreshButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    animate: PropTypes.bool,
    className: PropTypes.string,
};

export default RefreshButton;