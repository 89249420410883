import React, {useContext} from "react";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../ActualComponents/NmForm";
import NmInputV2 from "../../../../ActualComponents/NmInputV2";
import NmModal from "../../../../ActualComponents/NmModal";
import NmTextareaV2 from "../../../../ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

import {getRecruitmentCandidateOtherRequestData} from "../Any/utils/getRequestData";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

export const RecruitmentCandidateAdditionalInfoEdit = (props) => {
    const {
        onClose,
        index,
        title = "Добавление дополнительной информации",
        information,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        progressAction,
        card,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        setFieldTouched,
    } = useFormik({
        onSubmit,
        initialValues: {
            field: information?.field || "",
            text: information?.text || "",
        },
        validateOnBlur: false,
        enableReinitialize: false,
        validationSchema: yup.object().shape({
            field: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            text: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });

    function onSubmit(values) {
        const isEdit = Boolean(information);

        const requestData = getRecruitmentCandidateOtherRequestData({
            anyInformation: card.anyInformation || [],
            values,
            index,
            isEdit,
        });

        updateInformation({
            anyInformation: requestData,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    }

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile={true}
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    size="xl"
                    required
                    maxLength={255}
                    onChange={onChange}
                    name="field"
                    value={values.field}
                    placeholder="Введите название"
                    label="Заголовок"
                    onBlur={() => setFieldTouched("field", true)}
                    error={touched?.field && errors?.field}
                />
                <NmTextareaV2
                    size="xl"
                    required
                    value={values.text}
                    minRows={4}
                    label="Описание"
                    placeholder="Введите описание"
                    maxLength={255}
                    name="text"
                    onBlur={() => setFieldTouched("text", true)}
                    error={touched?.text && errors?.text}
                    onChange={onChange}
                />
            </NmForm>
        </NmModal>
    );
};
