import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ClientCardPage from "../../../../components/ClientCardPage";
import ClientCardDocumentsRoute from "./Route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_CARD_TEMPLATES_ACTS_ORDERS,
    LINK_CLIENT_CARD_TEMPLATES_ACTS_TASKS,
    LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_ORDERS,
    LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_TASKS,
    LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_OTHER_DOCUMENTS,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../constants/roles";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {getTotalCountHistorySelector} from "../../../../ducks/contractHistory";
import {getDocumentTotalCountSelector} from "../../../../ducks/documents";
import {getDocumentExportTotalCountSelector} from "../../../../ducks/documentsExport";
import {fcRegistryListTotalCountSelector} from "../../../../ducks/fcRegistries";
import {
    mutualSettlementsReconciliationActTotalCountSelector,
} from "../../../../ducks/mutualSettlementsReconciliationActs";
import {getOperatorReportsTotalCountSelector} from "../../../../ducks/operatorReports";

import "./style.sass";

const ClientCardDocuments = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
        location: {
            pathname,
        },
    } = props;

    const role = ls(USER_ROLE);

    const {t} = useTranslation();

    const totalCountExport = useSelector(getDocumentExportTotalCountSelector);
    const totalCountDocument = useSelector(getDocumentTotalCountSelector);
    const totalCountOperatorReports = useSelector(getOperatorReportsTotalCountSelector);
    const totalCountAgencyHistory = useSelector(getTotalCountHistorySelector);
    const totalCountFcRegistry = useSelector(fcRegistryListTotalCountSelector);
    const totalCountReconciliationsActs = useSelector(mutualSettlementsReconciliationActTotalCountSelector);
    const {customDocumentTemplatesAvailable} = useSelector(getClientPropertiesCardSelector);

    const getLink = () => {
        const exportDocumentList = LINK_CLIENT_EXPORT_DOCUMENT_LIST.replace(":clientId", clientId);
        const documentList = LINK_CLIENT_DOCUMENT_LIST.replace(":clientId", clientId);
        const notificationsList = LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST.replace(":clientId", clientId);
        const operatorReports = LINK_CLIENT_OPERATOR_REPORTS.replace(":clientId", clientId);
        const agencyContract = LINK_CLIENT_AGENCY_CONTRACT.replace(":clientId", clientId);
        const typicalDocuments = LINK_CLIENT_STANDART_DOCUMENTS.replace(":clientId", clientId);
        const fcRegistryList = LINK_CLIENT_REGISTRY_FC_LIST.replace(":clientId", clientId);
        const templateList = LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST.replace(":clientId", clientId);
        const reconciliationsActs = LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS.replace(":clientId", clientId);

        return {
            exportDocumentList,
            documentList,
            notificationsList,
            operatorReports,
            agencyContract,
            fcRegistryList,
            typicalDocuments,
            reconciliationsActs,
            templateList,
        };
    };

    const totalCount = useMemo(() => {
        const {
            exportDocumentList,
            documentList,
            notificationsList,
            operatorReports,
            agencyContract,
            reconciliationsActs,
            fcRegistryList,
        } = getLink();

        return {
            [exportDocumentList]: totalCountExport,
            [operatorReports]: totalCountOperatorReports,
            [documentList]: totalCountDocument,
            [notificationsList]: totalCountDocument,
            [agencyContract]: totalCountAgencyHistory,
            [fcRegistryList]: totalCountFcRegistry,
            [reconciliationsActs]: totalCountReconciliationsActs,
        }[pathname] || 0;
    }, [pathname, totalCountExport, totalCountOperatorReports, totalCountDocument, totalCountAgencyHistory, totalCountFcRegistry]);

    const tabLinks = useMemo(() => {
        const {
            exportDocumentList,
            documentList,
            notificationsList,
            operatorReports,
            agencyContract,
            fcRegistryList,
            typicalDocuments,
            reconciliationsActs,
            templateList,
        } = getLink();

        return [
            {
                active: documentList,
                link: documentList,
                name: t("documents-list.list"),
                isVisible: true,
            },
            {
                active: exportDocumentList,
                link: exportDocumentList,
                name: t("documents-list.export"),
                isVisible: true,
            },
            {
                active: notificationsList,
                link: notificationsList,
                name: t("documents-list.notifications"),
                isVisible: true,
            },
            {
                active: operatorReports,
                link: operatorReports,
                name: t("documents-list.operatorReports"),
                isVisible: true,
            },
            {
                active: agencyContract,
                link: agencyContract,
                name: t("documents-list.agencyContract"),
                isVisible: true,
            },
            {
                active: reconciliationsActs,
                link: reconciliationsActs,
                name: "Акт сверки взаиморасчетов",
                isVisible: true,
            },
            {
                active: typicalDocuments,
                link: typicalDocuments,
                name: t("documents-list.typicalDocuments"),
                isVisible: true,
            },
            {
                active: fcRegistryList,
                link: fcRegistryList,
                name: "Реестры на подписание рамочных договоров с исполнителями",
                isVisible: true,
            },
            {
                active: [
                    templateList,
                    LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_ORDERS.replace(":clientId", clientId),
                    LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_TASKS.replace(":clientId", clientId),
                    LINK_CLIENT_CARD_TEMPLATES_ACTS_ORDERS.replace(":clientId", clientId),
                    LINK_CLIENT_CARD_TEMPLATES_ACTS_TASKS.replace(":clientId", clientId),
                    LINK_CLIENT_CARD_TEMPLATES_OTHER_DOCUMENTS.replace(":clientId", clientId),
                ],
                link: templateList,
                name: "Шаблоны документов",
                isVisible: customDocumentTemplatesAvailable && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role),
            },
        ].filter(({isVisible}) => isVisible);
    }, [
        customDocumentTemplatesAvailable,
    ]);

    return (
        <ClientCardPage
            title="Документы"
            tabs={tabLinks}
            count={totalCount}
        >
            <ClientCardDocumentsRoute
                {...props}
            />
        </ClientCardPage>
    );
};

export default ClientCardDocuments;
