import React, {FC} from "react";

import {ReactComponent as ChevronDownIcon} from "../../../images/chevron-down.svg";
import {ReactComponent as ChevronUpIcon} from "../../../images/chevron-up_24.svg";
import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import NmButton from "../../NmButton";
import Text from "../Text";

import bem from "../../../utils/bem";

import {COLOR} from "../../../constants/color";

import "./style.sass";

type VerticalMovingListItemInfoType = {
    key: string,
    text: string,
    value: string,
    isShowDeleteButton: boolean,
}

interface IVerticalMovingList {
    list: Array<VerticalMovingListItemInfoType>,
    onChange: (event?: any, params?: any) => void,
    name: string,
}

export const VerticalMovingList: FC<IVerticalMovingList> = (props) => {
    const {
        list,
        onChange,
        name,
    } = props;

    const [block, element] = bem("vertical-moving-list");

    const moveUp = (item: VerticalMovingListItemInfoType, itemIndex: number) => {
        if (itemIndex === 0) {
            return;
        }

        const _list = [...list];
        _list[itemIndex] = list[itemIndex - 1];
        _list[itemIndex - 1] = item;

        onChange(null, {value: _list, name});
    };

    const moveDown = (item: VerticalMovingListItemInfoType, itemIndex: number) => {
        if (itemIndex === list.length - 1) {
            return;
        }

        const _list = [...list];
        _list[itemIndex] = list[itemIndex + 1];
        _list[itemIndex + 1] = item;

        onChange(null, {value: _list, name});
    };

    const deleteItem = (index: number) => {
        const _list = [...list];

        _list.splice(index, 1);

        onChange(null, {value: _list, name});
    };

    return (
        <div className={block()}>
            {
                list.map((item, index) => {
                    const {
                        isShowDeleteButton = true,
                    } = item;

                    return (
                        <div
                            key={item.key}
                            className={element("item-container")}
                        >
                            <div className={element("number")}>
                                {index + 1}
                            </div>
                            <div className={element("central-block")}>
                                <Text
                                    level="3"
                                    noWrap
                                    className={element("item-name")}
                                >
                                    {item.text}
                                </Text>
                                <NmButton
                                    disabled={index === 0}
                                    onlyIcon
                                    icon={
                                        <ChevronUpIcon />
                                    }
                                    size="lg"
                                    color="grey"
                                    onClick={() => moveUp(item, index)}
                                />
                                <NmButton
                                    className="ms-4"
                                    disabled={index === list.length - 1}
                                    onlyIcon
                                    icon={
                                        <ChevronDownIcon />
                                    }
                                    size="lg"
                                    color="grey"
                                    onClick={() => moveDown(item, index)}
                                />
                            </div>
                            <div className={element("icon-container")}>
                                {
                                    isShowDeleteButton &&
                                    <CloseIcon
                                        className={element("delete-icon")}
                                        color={COLOR.NEGATIVE_100}
                                        onClick={() => deleteItem(index)}
                                    />
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};