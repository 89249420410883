import React from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {useContractorOldPersonalData} from "../../hooks/useAction";

import {contractorProgressSelector} from "../../../../../../../ducks/contractor";

const ContractorOldPersonalDataEdit = (props) => {
    const {
        onClose,
    } = props;

    const progress = useSelector(contractorProgressSelector);

    const {
        values,
        setFieldTouched,
        errors,
        touched,
        onSubmit,
        handleChange,
    } = useContractorOldPersonalData({
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Старые персональные данные
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submit={onSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    name="previousPassportLastName"
                    value={values.previousPassportLastName}
                    onChange={handleChange}
                    type="text"
                    label="Фамилия"
                    placeholder="Введите фамилию"
                />
                <NmInputV2
                    size="xl"
                    name="previousPassportFirstName"
                    value={values.previousPassportFirstName}
                    onChange={handleChange}
                    type="text"
                    label="Имя"
                    placeholder="Введите имя"
                />
                <NmInputV2
                    size="xl"
                    name="previousPassportPatronymic"
                    value={values.previousPassportPatronymic}
                    onChange={handleChange}
                    type="text"
                    label="Отчество"
                    placeholder="Введите отчество"
                />
                <div className="row">
                    <div className="col-md-8">
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            label="Дата рождения"
                            maxDate={new Date()}
                            name="previousPassportBirthDate"
                            selected={values.previousPassportBirthDate}
                            onChange={handleChange}
                            isClearable
                        />
                    </div>
                    <div className="col-md-8">
                        <NmInputV2
                            size="xl"
                            label="Серия и номер паспорта"
                            mask="9999 999999"
                            maskChar={null}
                            name="previousPassportIdDocNumber"
                            value={values.previousPassportIdDocNumber}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("previousPassportIdDocNumber", true)}
                            error={touched && touched.previousPassportIdDocNumber ?
                                errors && errors.previousPassportIdDocNumber :
                                undefined
                            }
                            placeholder="Введите Серию и номер паспорта"
                        />
                    </div>
                </div>
            </NmForm>
        </NmModal>
    );
};

export default ContractorOldPersonalDataEdit;