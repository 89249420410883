import React, {useEffect, useState} from "react";
import {useMedia} from "react-media";
import {useDispatch, useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as FileDownloadIcon} from "../../../../images/file_download.svg";
import {ReactComponent as PhoneCallbackIcon} from "../../../../images/phone_callback.svg";
import {ReactComponent as PhoneForwardedIcon} from "../../../../images/phone_forwarded.svg";
import {history} from "../../../../store/configureStore";
import AttachModal from "../attach-modal";

import {usePagination} from "../../../../hooks/usePagination";

import {durationInMinutes, formatLocalDate} from "../../../../utils/dateFormat";
import {getFullName} from "../../../../utils/stringFormat";

import {CALL_TYPE_DICT, SUB_PAGE_CRM_TICKET_CARD} from "../../../../constants/crm/ticket";
import {
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CRM_TICKET_CARD,
} from "../../../../constants/links";

import {getRecordingConversation} from "../../../../ducks/crm/recording";
import {
    getTicketCallHistoryRichPage,
    linkCallToTicket,
    ticketCallHistoryListSelector,
    ticketCallHistoryTotalCountSelector,
    ticketCallHistoryTotalPagesSelector,
    ticketUnattachedCallHistoryListSelector,
    ticketUnattachedCallHistoryTotalCountSelector, ticketUnattachedCallHistoryTotalPagesSelector,
    updateTicketStore} from "../../../../ducks/crm/ticket";

import "./style.sass";

function CallHistory(props) {
    const dispatch = useDispatch();
    const {
        ticketId,
        match: {
            params: {
                subpage,
                _subpage,
                clientId,
                contractorId,
            },
        },
        card,
    } = props;

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const isMobile = useMedia({query: {maxWidth: 767}});

    const [attachTicket, setAttachTicket] = useState("");

    const isUnattachedPage = _subpage === SUB_PAGE_CRM_TICKET_CARD.UNATTACHED.LINK;

    const ticketUnattachedCallHistoryList = useSelector(ticketUnattachedCallHistoryListSelector);
    const ticketCallHistoryList = useSelector(ticketCallHistoryListSelector);
    const list = isUnattachedPage ? ticketUnattachedCallHistoryList :ticketCallHistoryList;
    const totalCount = useSelector(isUnattachedPage ? ticketUnattachedCallHistoryTotalCountSelector : ticketCallHistoryTotalCountSelector);
    const totalPages = useSelector(isUnattachedPage ? ticketUnattachedCallHistoryTotalPagesSelector : ticketCallHistoryTotalPagesSelector);

    useEffect(() => {
        if (!ticketId) {
            return;
        }
        fetchList();
        return () => {
            dispatch(updateTicketStore({
                callHistoryList: [],
                callHistoryTotalCount: 0,
                callHistoryPageData: {},
                unattachedCallList: [],
                unattachedCallListTotal: 0,
            }));
        };
    }, [
        pageNum,
        pageSize,
        ticketId,
        _subpage,
        card?.baseModel?.fromNumber,
    ]);
    function getCardLink() {
        if (clientId) {
            return LINK_CLIENT_CARD_CRM_TICKET_CARD.replace(":clientId", clientId);
        }
        if (contractorId) {
            return LINK_CONTRACTOR_CRM_TICKET_CARD.replace(":contractorId", contractorId);
        }

        return LINK_CRM_TICKET_CARD;
    }

    const fetchList = (isCheckList = false) => {
        dispatch(getTicketCallHistoryRichPage({
            isUnattachedPage,
            ticketIsNullFilter: isUnattachedPage,
            ticketId: isUnattachedPage ? undefined : ticketId,
            toNumberFilter: isUnattachedPage ? card?.baseModel?.fromNumber : undefined,
            pageSize,
            pageNum,
            getResult: ({totalCount}) => {
                if (!totalCount && isUnattachedPage && isCheckList) {
                    const linkCard = getCardLink()
                        .replace(":subpage", subpage)
                        .replace(":ticketId", ticketId)
                        .replace(":_subpage", SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK);
                    history.push(linkCard);
                }
            },
        }));
    };

    const submitAttachForm = (form) => {
        dispatch(linkCallToTicket({
            ...form,
            getResult: ({errorMessage}) => {
                if (!errorMessage) {
                    closeAttachModal();
                    fetchList(true);
                }
            },
        }));
    };

    const handleDownload = (item) => {
        const {callId} = item.baseModel;

        dispatch(getRecordingConversation({
            callId,
        }));
    };

    function renderPrimaryHeader(item) {
        const {
            baseModel: {
                callType,
                createDate,
            },
            managerModel,
        } = item;
        const {
            lastName,
            firstName,
            patronymic,
            fio,
        } = managerModel || {};

        const _fio = (lastName ? getFullName(lastName, firstName, patronymic) : fio) || "Не определено";
        const time = formatLocalDate(createDate, "dd.MM.yyyy HH:mm");

        const callTypeDict = {
            [CALL_TYPE_DICT.INCOMING.VALUE]: {
                COMPONENT: <PhoneCallbackIcon
                    className={`call-history__phone call-history__phone_${CALL_TYPE_DICT.INCOMING.COLOR}`}
                />,
            },
            [CALL_TYPE_DICT.OUTGOING.VALUE]: {
                COMPONENT: <PhoneForwardedIcon
                    className={`call-history__phone call-history__phone_${CALL_TYPE_DICT.OUTGOING.COLOR}`}
                />,
            },
        };

        if (isMobile) {
            return <div className="d-flex flex-column">
                <div className="call-history__create-date">
                    {time}
                </div>
                <div className="d-flex flex-aligned-center mt-2">
                    {callTypeDict[callType].COMPONENT}
                    <div className="call-history__fio">
                        {_fio}
                    </div>
                </div>
            </div>;
        }

        return (
            <div className="d-flex  flex-aligned-center">
                {callTypeDict[callType].COMPONENT}
                <div className="call-history__fio">
                    {_fio}
                </div>
                <div className="call-history__create-date">
                    {time}
                </div>
            </div>
        );
    }

    function renderAction(item) {
        const {baseModel: {recorded}} = item;

        if (isMobile && isUnattachedPage) {
            return getContextMenu(item);
        }

        return <>
            {isUnattachedPage && <NmButton
                size="xl"
                className="me-2"
                onClick={() => openAttachModal(item)}
                color="grey"
            >
                Привязать к обращению
            </NmButton>}
            {recorded && <NmButton
                size="xl"
                onClick={() => handleDownload(item)}
                color="grey"
                icon={<FileDownloadIcon />}
                onlyIcon
            />}
        </>;
    }

    const openAttachModal = ({
        baseModel: {
            id,
        },
    }) => {
        setAttachTicket(id);
    };

    const closeAttachModal = () => {
        setAttachTicket("");
    };

    function getOptions(recorded) {
        const options = [];
        isUnattachedPage && options.push({
            key: "attach",
            value: "attach",
            text: "Привязать к обращению",
        });
        recorded && options.push({
            key: "download",
            value: "download",
            text: "Скачать",
        });

        return options;
    }

    const onClickItemContextMenu = ({value: action}, item) => {
        if (action === "attach") {
            openAttachModal(item);
            return;
        }

        handleDownload(item);
    };

    const getContextMenu = (item) => {
        const {baseModel: {recorded}} = item;
        return (
            <ContextMenu
                options={getOptions(recorded)}
                onClickItem={(option) => onClickItemContextMenu(option, item)}
            />
        );
    };


    function getRows() {
        return list.map(item => {
            const {
                baseModel: {
                    callEndDate,
                    callStartDate,
                    ticketId,
                },
            } = item;

            return {
                ...item,
                key: ticketId,
                ticketId,
                showCheckBox: true,
                contentRow: (
                    <NmListCard
                        checkbox
                        noDivider
                        classNameMainContent={isUnattachedPage ? "col-12 col-xxl-12" : "col-15 col-xxl-15"}
                        primaryHeader={renderPrimaryHeader(item)}
                        labels={[
                            {
                                label: "Длительность звонка",
                                text: callStartDate === null && !!callEndDate ? "звонок пропущен" : durationInMinutes(callStartDate, callEndDate),
                                noWrap: false,
                            },
                        ]}
                        primaryHeaderTwoLines
                        actions={renderAction(item)}
                        actionsClassName={isUnattachedPage ? "col-4 justify-content-end" : "col-1 justify-content-end"}
                    />
                ),
            };
        });
    }

    return (
        <>
            <AttachModal
                phoneFilter={card?.baseModel?.fromNumber}
                isOpened={!!attachTicket}
                ticketCallId={attachTicket}
                onClose={closeAttachModal}
                onSubmit={submitAttachForm}
            />
            <NmPage
                noPadding
                currentPageNum={pageNum}
                totalCount={totalCount}
                totalPages={totalPages}
                currentPageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
            >
                {list.length === 0 && <NmEmptyPageV2 title="Информация отсутствует" />}
                {list.length > 0 && <CheckboxList
                    rows={getRows()}
                />}
            </NmPage>
        </>
    );
}

export default CallHistory;