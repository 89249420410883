import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/ordercategories";
const newController = "/client/categories";
//*  TYPES  *//

const GET_PAGE_ORDER_CATEGORY_LIST_REQUEST = "GET_PAGE_ORDER_CATEGORY_LIST_REQUEST";
const GET_PAGE_ORDER_CATEGORY_LIST_SUCCESS = "GET_PAGE_ORDER_CATEGORY_LIST_SUCCESS";
const GET_PAGE_ORDER_CATEGORY_LIST_ERROR = "GET_PAGE_ORDER_CATEGORY_LIST_ERROR";

const UPDATE_ORDER_CATEGORY_REQUEST = "UPDATE_ORDER_CATEGORY_REQUEST";
const UPDATE_ORDER_CATEGORY_SUCCESS = "UPDATE_ORDER_CATEGORY_SUCCESS";
const UPDATE_ORDER_CATEGORY_ERROR = "UPDATE_ORDER_CATEGORY_ERROR";

const ADD_ORDER_CATEGORY_REQUEST = "ADD_ORDER_CATEGORY_REQUEST";
const ADD_ORDER_CATEGORY_SUCCESS = "ADD_ORDER_CATEGORY_SUCCESS";
const ADD_ORDER_CATEGORY_ERROR = "ADD_ORDER_CATEGORY_ERROR";

const DELETE_ORDER_CATEGORY_REQUEST = "DELETE_ORDER_CATEGORY_REQUEST";
const DELETE_ORDER_CATEGORY_SUCCESS = "DELETE_ORDER_CATEGORY_SUCCESS";
const DELETE_ORDER_CATEGORY_ERROR = "DELETE_ORDER_CATEGORY_ERROR";

const UPDATE_FIELD_ORDER_CATEGORY_LIST = "UPDATE_FIELD_ORDER_CATEGORY_LIST";

const GET_ORDER_CATEGORY_ALL_REQUEST = "GET_ORDER_CATEGORY_ALL_REQUEST";
const GET_ORDER_CATEGORY_ALL_SUCCESS = "GET_ORDER_CATEGORY_ALL_SUCCESS";
const GET_ORDER_CATEGORY_ALL_ERROR = "GET_ORDER_CATEGORY_ALL_ERROR";


//*  INITIAL STATE  *//

const initial = {
    pageData: {},
    progressPage: false,
    progress: false,
    totalCount: 0,
    orderCategoryList: [],
    isActionSuccess: false,
    orderCategoriesAll: [],
    categoryCard: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case ADD_ORDER_CATEGORY_REQUEST:
    case UPDATE_ORDER_CATEGORY_REQUEST:
    case DELETE_ORDER_CATEGORY_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_PAGE_ORDER_CATEGORY_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case GET_ORDER_CATEGORY_ALL_SUCCESS:
        return {
            ...state,
            orderCategoriesAll: payload,
        };
    case GET_PAGE_ORDER_CATEGORY_LIST_SUCCESS:
        const {
            results: orderCategoryList,
            totalCount,
        } = payload;

        return {
            ...state,
            progressPage: false,
            totalCount,
            orderCategoryList,
        };
    case UPDATE_FIELD_ORDER_CATEGORY_LIST:
        return {
            ...state,
            ...payload,
        };
    case GET_PAGE_ORDER_CATEGORY_LIST_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    case ADD_ORDER_CATEGORY_SUCCESS:
    case UPDATE_ORDER_CATEGORY_SUCCESS:
    case DELETE_ORDER_CATEGORY_SUCCESS:
        return {
            ...state,
            progress: false,
            isActionSuccess: true,
        };
    case ADD_ORDER_CATEGORY_ERROR:
    case UPDATE_ORDER_CATEGORY_ERROR:
    case DELETE_ORDER_CATEGORY_ERROR:
        return {
            ...state,
            progress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getOrderCategoriesPage(payload) {
    return {
        type: GET_PAGE_ORDER_CATEGORY_LIST_REQUEST,
        payload,
    };
}

export function updateOrderCategory(payload) {
    return {
        type: UPDATE_ORDER_CATEGORY_REQUEST,
        payload,
    };
}

export function deleteOrderCategory(payload) {
    return {
        type: DELETE_ORDER_CATEGORY_REQUEST,
        payload,
    };
}

export function addOrderCategory(payload) {
    return {
        type: ADD_ORDER_CATEGORY_REQUEST,
        payload,
    };
}

export function updateFieldOrderCategory(payload) {
    return {
        type: UPDATE_FIELD_ORDER_CATEGORY_LIST,
        payload,
    };
}

export function getOrderCategoriesAll() {
    return {
        type: GET_ORDER_CATEGORY_ALL_REQUEST,
    };
}

//*  SELECTORS  *//
export const orderCategorySelector = state => state.orderCategory;
export const getOrderCategoryTotalPagesSelector = createSelector(orderCategorySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getOrderCategoryTotalCountSelector = createSelector(orderCategorySelector, ({totalCount}) => totalCount);
export const getOrderCategoriesListSelector = createSelector(orderCategorySelector, ({orderCategoryList}) => orderCategoryList);
export const getOrderCategoriesActionSuccessSelector = createSelector(orderCategorySelector, ({isActionSuccess}) => isActionSuccess);
export const getOrderCategoriesProgressPageSelector = createSelector(orderCategorySelector, ({progressPage}) => progressPage);
export const getOrderCategoriesProgressSelector = createSelector(orderCategorySelector, ({progress}) => progress);
export const orderCategoriesAllSelector = createSelector(orderCategorySelector, ({orderCategoriesAll}) => orderCategoriesAll);
export const getOrderCategoriesAllOptionsSelector = createSelector(orderCategorySelector, ({orderCategoriesAll}) => {
    return orderCategoriesAll.map(item => {
        return {
            key: item.id,
            value: item.id,
            text: item.value,
        };
    });
});
export const orderCategoryCardSelector = createSelector(orderCategorySelector, ({categoryCard}) => categoryCard);

//*  SAGA  *//

export const getOrderCategoriesPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${newController}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_ORDER_CATEGORY_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PAGE_ORDER_CATEGORY_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAGE_ORDER_CATEGORY_LIST_ERROR, payload: error});
    }
};

export const addOrderCategorySaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const result = yield request.post(`${newController}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_ORDER_CATEGORY_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Категория успешно создана");

        const state = yield select();

        yield put(getOrderCategoriesPage(state.orderCategory.pageData));

        yield put({type: ADD_ORDER_CATEGORY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_ORDER_CATEGORY_ERROR, payload: error});
    }
};

export const updateOrderCategorySaga = function* (action) {
    try {
        const {
            payload: {
                orderCategoryUuid,
                orderCategory,
            },
        } = action;

        const result = yield request.patch(`${newController}/${orderCategoryUuid}`, orderCategory);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_ORDER_CATEGORY_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        const state = yield select();

        yield put(getOrderCategoriesPage(state.orderCategory.pageData));

        yield put({type: UPDATE_ORDER_CATEGORY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_ORDER_CATEGORY_ERROR, payload: error});
    }
};

export const deleteOrderCategorySaga = function* ({payload}) {
    try {
        const {
            orderCategoryUuid,
            onSuccess,
            onError,
        } = payload;

        const result = yield request.delete(`${newController}/${orderCategoryUuid}`);

        const {errorMessage, results} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError(results);

            yield put({type: DELETE_ORDER_CATEGORY_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Категория успешно удалена");

        yield put({type: DELETE_ORDER_CATEGORY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_ORDER_CATEGORY_ERROR, payload: error});
    }
};

export const getOrderCategoryAllSaga = function* (action) {
    try {
        const result = yield request.get(`${newController}/all`);
        const {errorMessage} = result;
        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_ORDER_CATEGORY_ALL_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }
        yield put({type: GET_ORDER_CATEGORY_ALL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_ORDER_CATEGORY_ALL_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_ORDER_CATEGORY_LIST_REQUEST, getOrderCategoriesPageSaga),
        takeEvery(UPDATE_ORDER_CATEGORY_REQUEST, updateOrderCategorySaga),
        takeEvery(DELETE_ORDER_CATEGORY_REQUEST, deleteOrderCategorySaga),
        takeEvery(ADD_ORDER_CATEGORY_REQUEST, addOrderCategorySaga),
        takeEvery(GET_ORDER_CATEGORY_ALL_REQUEST, getOrderCategoryAllSaga),
    ]);
}
