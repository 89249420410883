import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../components/ActualComponents/DropzoneV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import useDataForm from "./hooks/useDataForm";

import {
    documentsEdoRegistriesGetFileProgressActionSelector,
    documentsEdoRegistriesProgressActionSelector,
} from "../../../../../ducks/documentsEdoRegistries";
import {edmStaffListOptionsSelector} from "../../../../../ducks/edmStaff";
import {edoDocumentTypesUploadDocumentsSelector} from "../../../../../ducks/edocuments";

import {
    EDO_DOCUMENT_FIELD,
    TYPE_LOADING_EDO_DOCUMENT,
} from "../../../../../constants/document-management/document-list";

const EdoDocumentsRegistriesEditForm = (props) => {
    const {
        clientId,
        onClose,
        editData,
        fetchList,
    } = props;

    const edoDocumentTypesOptions = useSelector(edoDocumentTypesUploadDocumentsSelector);
    const ownerOptions = useSelector(edmStaffListOptionsSelector);
    const progress = useSelector(documentsEdoRegistriesProgressActionSelector);
    const getFileProgress = useSelector(documentsEdoRegistriesGetFileProgressActionSelector);

    const isEdit = !isEmpty(editData);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleChangeTypeDocument,
        handleSubmit,
        handleOnSearchDocumentType,
        handleDownloadEmptyTemplate,
        isShowTypeLoading,
        handleDrop,
    } = useDataForm({fetchList, onClose, clientId, isEdit, editData, ownerOptions});

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Реестр на подписание документов ЭДО
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={progress || getFileProgress}
        >
            <NmForm>
                <Text.Sub
                    className="col-16 mb-2 mb-md-4"
                    level="2"
                >
                    Параметры реестра
                </Text.Sub>
                <NmInputV2
                    required
                    size="xl"
                    label="Название"
                    placeholder="Введите название реестра"
                    name="name"
                    value={values.name}
                    maxLength={100}
                    onChange={handleChange}
                    error={touched.name && errors.name}
                />
                <NmTextareaV2
                    className="mt-2"
                    maxLength={255}
                    label="Комментарий"
                    placeholder="При необходимости укажите комментарий"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                />
                <NmDropdownV2
                    className="mt-2"
                    size="xl"
                    placeholder="Выберите из списка"
                    fullWidth
                    required
                    label="Ответственный"
                    search
                    name="ownerId"
                    onChange={handleChange}
                    value={values.ownerId}
                    options={ownerOptions}
                    error={touched.ownerId && errors.ownerId}
                />
                <Text.Sub
                    className="col-16 mb-2 mt-2"
                    level="2"
                >
                    Параметры документа
                </Text.Sub>
                <NmDropdownV2
                    size="xl"
                    placeholder="Выберите из списка"
                    fullWidth
                    required
                    label="Тип документа"
                    search
                    name="documentType"
                    onChange={handleChangeTypeDocument}
                    value={values.documentType}
                    options={edoDocumentTypesOptions}
                    onSearchChange={handleOnSearchDocumentType}
                    error={touched.documentType && errors.documentType}
                    disabled={isEdit}
                />
                {
                    isShowTypeLoading &&
                    <div className="row mt-4">
                        <div className="col-16 col-md-8">
                            <NmRadioV2
                                label="Использовать предложенный шаблон"
                                name="templateType"
                                value={TYPE_LOADING_EDO_DOCUMENT.TEMPLATE}
                                onChange={handleChange}
                                checked={values.templateType === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE}
                                disabled={isEdit}
                            />
                            {
                                values.params.type &&
                                <div
                                    className="document-management-loading-document__link-pre-filled-template"
                                    onClick={handleDownloadEmptyTemplate}
                                >
                                    Посмотреть предложенный шаблон
                                </div>
                            }
                        </div>
                        <div className="col-16 col-md-8">
                            <NmRadioV2
                                label="Загрузить заполненный документ"
                                name="templateType"
                                value={TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT}
                                onChange={handleChange}
                                checked={values.templateType === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT}
                                disabled={isEdit}
                            />
                        </div>
                    </div>
                }
                {
                    (!isShowTypeLoading || values.templateType === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT) &&
                    <DropzoneV2
                        className="mt-4"
                        isDownload
                        multiple={false}
                        files={values.file ? [values.file] : []}
                        onChange={handleDrop}
                        format="*.PDF/A"
                        accept=".pdf"
                        maxSize={15}
                        error={touched[EDO_DOCUMENT_FIELD.FILE] && errors[EDO_DOCUMENT_FIELD.FILE]}
                    />
                }
            </NmForm>
        </NmModal>
    );
};

export default EdoDocumentsRegistriesEditForm;