import {useDispatch} from "react-redux";

import {
    addRecruitersRecruitmentAccessControlRecruitersGroupsCard,
    deleteRecruiterRecruitmentAccessControlRecruitersGroupsCard,
} from "../../../../../../ducks/bff/recruitment/access-control/recruiters-groups/actionsCreators";

import {useModal} from "../../../../../../hooks/useModal";

import {toastSuccess} from "../../../../../../utils/toastHelper";

export const useRecruitmentAccessControlRecruitersGroupsCardAction = (props) => {
    const {
        fetchList,
        groupId,
    } = props;

    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useModal();

    const dispatch = useDispatch();

    const onAdd = ({selectedIds}) => {
        dispatch(addRecruitersRecruitmentAccessControlRecruitersGroupsCard({
            selectedIds,
            id: groupId,
            onSuccess: () => {
                toastSuccess("Рекрутеры успешно добавлены в группу");
                onCloseModal();
                fetchList();
            },
        }));
    };

    const onDelete = () => {
        dispatch(deleteRecruiterRecruitmentAccessControlRecruitersGroupsCard({
            id: groupId,
            clientUserId: modalData.clientUserId,
            onSuccess: () => {
                toastSuccess("Рекртуер успешно удален");
                fetchList();
            },
        }));
    };

    return {
        modalData,
        onOpenModal,
        onCloseModal,
        onDelete,
        onAdd,
    };
};