export const TAB = {
    /*Агентский договор с Наймикс*/
    AGENCY_CONTRACT: "AGENCY_CONTRACT",
    /*Согласие на обработку ПД*/
    CONSENT_TO_PROCESSING_PERSONAL_DATA: "CONSENT_TO_PROCESSING_PERSONAL_DATA",
    /*Договоры с заказчиками*/
    FRAME_CONTRACT: "FRAME_CONTRACT",
    /*Заявки на выполнение работ (оказание услуг)*/
    ORDER_APPLICATION: "ORDER_APPLICATION",
    /*Акты выполненных работ*/
    ACT_OF_ACCEPTANCE_OF_WORK: "ACT_OF_ACCEPTANCE_OF_WORK",
    /*Страховые полисы*/
    INSURANCE_POLICY: "INSURANCE_POLICY",
    /*Квитанции РНКО*/
    RECEIPT_RNKO: "RECEIPT_RNKO",
    /*Реестры на подписание рамочных договоров с исполнителем*/
    DOCUMENTS_REGISTRY: "DOCUMENTS_REGISTRY",
    /*Лист идентификации личности*/
    IDENTIFICATION_SHEET: "IDENTIFICATION_SHEET",
    /*Договоры на подписании*/
    PENDING_CONTRACTS: "PENDING_CONTRACTS",
};