import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import Box from "../../../../../components/ActualComponents/Box";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {
    addRecruitmentWebhook,
    deleteRecruitmentWebhook,
    getRecruitmentWebhook,
    updateRecruitmentJobBoardsWebhookStore,
} from "../../../../../ducks/bff/recruitment/jobBoardsWebhook/actionCreators";
import {
    bffRecruitmentDirectoryWebhookActionProgressSelector,
    bffRecruitmentDirectoryWebhookCardProgressSelector,
    bffRecruitmentDirectoryWebhookCardSelector,
} from "../../../../../ducks/bff/recruitment/jobBoardsWebhook/selectors";

import {formatLocalDate} from "../../../../../utils/dateFormat";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../constants";

const RecruitmentSubscriptionManagementModal = (props) => {
    const {
        data,
        onClose = () => {
        },
    } = props;

    const dispatch = useDispatch();

    const {
        userName,
        userEmail,
        subscription = {},
    } = useSelector(bffRecruitmentDirectoryWebhookCardSelector);
    const progressCard = useSelector(bffRecruitmentDirectoryWebhookCardProgressSelector);
    const progressAction = useSelector(bffRecruitmentDirectoryWebhookActionProgressSelector);

    const {
        jobBoardType,
        externalId,
    } = data;

    const {
        id,
        lastModifiedDate,
    } = subscription || {};

    useEffect(() => {
        fetchSubscription();

        return () => {
            dispatch(updateRecruitmentJobBoardsWebhookStore({
                progressAction: false,
                progress: false,
                card: {},
            }));
        };
    }, []);

    const fetchSubscription = () => {
        dispatch(getRecruitmentWebhook({
            externalId,
            jobBoardType,
        }));
    };

    const addSubscription = () => {
        dispatch(addRecruitmentWebhook({
            externalId,
            jobBoardType,
            onSuccess: () => {
                toastSuccess("Подписка успешно создана");
                fetchSubscription();
            },
        }));
    };

    const deleteSubscription = () => {
        dispatch(deleteRecruitmentWebhook({
            externalId,
            jobBoardType,
            onSuccess: () => {
                toastSuccess("Подписка успешно удалена");
                fetchSubscription();
            },
        }));
    };

    const modalData = useMemo(() => {
        if (id) {
            return {
                subscriptionPrimaryHeader: "Текущая подписка",
                submitBtnContent: "Удалить",
                onSubmit: deleteSubscription,
            };
        }

        return {
            subscriptionPrimaryHeader: "Создать подписку",
            submitBtnContent: "Создать",
            onSubmit: addSubscription,
        };
    }, [subscription]);

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Управление подписками пользователя
                </NmTitle>
            }
            footer={
                !progressCard &&
                <ApplyButtons
                    isHiddenCancelOnMobile
                    submit={modalData.onSubmit}
                    onClose={onClose}
                    mobile="column"
                    disabled={progressAction}
                    submitBtnContent={modalData.submitBtnContent}
                    cancelBtnContent="Закрыть"
                />
            }
            onClose={onClose}
            loading={progressCard}
        >

            <Box
                theme="list"
                className="col-16"
            >
                {
                    !progressCard &&
                    <NmListCard
                        noDivider={true}
                        primaryHeader={
                            <Text bold={true}>
                                {modalData.subscriptionPrimaryHeader}
                            </Text>
                        }
                        labels={[
                            id &&
                            jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER && {
                                label: "Идентификатор подписки",
                                text: id,
                            },
                            {
                                label: "Аккаунт",
                                text: `${userName || ""} ${userEmail || ""}`,
                            },
                            lastModifiedDate && {
                                label: "Дата последнего события",
                                text: formatLocalDate(lastModifiedDate, "dd.MM.yyyy HH:mm"),
                            },
                        ]}
                        classNameMainContent="col-16"
                    />
                }
            </Box>
        </NmModal>
    );
};

export default RecruitmentSubscriptionManagementModal;