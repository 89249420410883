import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {round} from "lodash";

import {formatAmountWithNullChecking, getNumberFromFormattedAmount} from "../../../../utils/stringFormat";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";

export const useCalculate = ({orderAmountForPayment}) => {
    const [calculator, setCalculator] = useState({
        cardSum: 0,
        contractorCommission: 0,
        clientCommission: 0,
    });

    const {
        civilPaymentCommission,
        contractorCivilRegistryPaymentsCommission: civilContractorCommission,
    } = useSelector(getClientPropertiesCardSelector);

    useEffect(() => {
        orderAmountForPayment = getNumberFromFormattedAmount(orderAmountForPayment || 0);

        const contractorCommission = round(orderAmountForPayment * civilContractorCommission, 2);

        setCalculator({
            cardSum: round(orderAmountForPayment - contractorCommission, 2),
            contractorCommission: contractorCommission,
            clientCommission: round(orderAmountForPayment * civilPaymentCommission, 2),
        });
    }, [orderAmountForPayment]);

    return {
        data: [
            {
                title: "Сумма на карту, ₽",
                value: formatAmountWithNullChecking(calculator.cardSum),
                className: "mb-2",
            },
            {
                title: "Комиссия исполнителя, ₽",
                value: formatAmountWithNullChecking(calculator.contractorCommission),
                className: "mb-2",
            },
            {
                title: "Комиссия заказчика, ₽",
                value: formatAmountWithNullChecking(calculator.clientCommission),
            },
        ],
    };
};