import React, {ReactNode} from "react";

import {ReactComponent as InfoIcon} from "../../images/alert_24.svg";
import HelpTooltip, {IHelpTooltip} from "../ActualComponents/HelpTooltip";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import {TNmTooltipType} from "../ActualComponents/NmTooltip";

import bem from "../../utils/bem";

import "./style.sass";

interface INmBadge {
    text: string,
    className?: string,
    condition?: boolean,
    description?: string,
    tooltipPosition?: any,
    noWrap?: boolean,
    mod?: BadgeModType,
    icon?: ReactNode,
    onClick?: () => void,
    tooltipType?: TNmTooltipType,
    tooltipHover?: boolean,
    maxContent?: boolean
    blockClassName?: string,
    classNameTooltip?: string,
    disabled?: boolean,
    pointer?: boolean,
    title?: string,
    leftTooltipText?: string,
    leftTooltipProps?: IHelpTooltip,
    rightTooltipText?: string,
    rightTooltipProps?: IHelpTooltip,
    rightIcon?: ReactNode
}

export type BadgeModType = "gray" | "orange" | "green" | "red" | "light-green" | "white" | "light-red"

const NmBadge = (props: INmBadge) => {
    const {
        text,
        className = "",
        condition,
        description,
        blockClassName = "",
        onClick,
        icon,
        noWrap,
        mod = "",
        tooltipPosition = "top",
        classNameTooltip = "",
        tooltipType,
        tooltipHover,
        rightIcon,
        disabled,
        pointer,
        title,
        rightTooltipText,
        rightTooltipProps,
        leftTooltipText,
        leftTooltipProps,
        maxContent = false,
    } = props;
    const [block, element] = bem("nm-badge", className);

    const getRightTooltip = () => {
        if (!rightTooltipText && !rightTooltipProps) {
            return null;
        }

        return (
            <div className="nm-badge__right-icon">
                <HelpTooltip
                    width={16}
                    height={16}
                    inheritColor={true}
                    classNameTooltip={element("badge-tooltip", {
                        bottomRight: tooltipPosition === "bottom-right",
                        bottomLeft: tooltipPosition === "bottom-left",
                        topRight: tooltipPosition === "top-right",
                        topLeft: tooltipPosition === "top-left",
                        rightBottom: tooltipPosition === "right-bottom",
                        rightTop: tooltipPosition === "right-top",
                        leftBottom: tooltipPosition === "left-bottom",
                        leftTop: tooltipPosition === "left-top",
                    })}
                    children={rightTooltipText}
                    position={tooltipPosition}
                    {...rightTooltipProps}
                />
            </div>
        );
    };

    const getLeftTooltip = () => {
        if (!leftTooltipText && !leftTooltipProps) {
            return null;
        }

        return (
            <div className="nm-badge__left-icon">
                <HelpTooltip
                    width={16}
                    height={16}
                    inheritColor={true}
                    classNameTooltip={element("badge-tooltip", {
                        bottomRight: tooltipPosition === "bottom-right",
                        bottomLeft: tooltipPosition === "bottom-left",
                        topRight: tooltipPosition === "top-right",
                        topLeft: tooltipPosition === "top-left",
                        rightBottom: tooltipPosition === "right-bottom",
                        rightTop: tooltipPosition === "right-top",
                        leftBottom: tooltipPosition === "left-bottom",
                        leftTop: tooltipPosition === "left-top",
                    })}
                    children={leftTooltipText}
                    position={tooltipPosition}
                    {...leftTooltipProps}
                />
            </div>
        );
    };

    return (
        <div
            title={title}
            className={`flex flex-aligned-center relative ${blockClassName}`}
        >
            <div
                onClick={onClick}
                className={block({
                    mod,
                    condition,
                    noWrap,
                    maxContent,
                    disabled,
                    pointer,
                })}
            >
                {getLeftTooltip()}
                {
                    icon &&
                    <div className="nm-badge__left-icon">
                        {icon}
                    </div>
                }
                {text}
                {
                    rightIcon &&
                    <div className="nm-badge__right-icon">
                        {rightIcon}
                    </div>
                }
                {getRightTooltip()}
            </div>
            {
                description &&
                <NmAdvancedTooltip
                    hover={tooltipHover}
                    type={tooltipType}
                    position={tooltipPosition}
                    children={description}
                    className="nm-badge__advancedTooltip"
                    classNameTooltip={classNameTooltip}
                    trigger={
                        <InfoIcon
                            className={element("tooltip-icon")}
                            height={16}
                            width={16}
                        />
                    }
                />
            }
        </div>
    );
};

export default NmBadge;