import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

const responsesSelector = state => state.bff.recruitment.vacancyCandidatesResponses;
export const bffRecruitmentVacancyCandidatesResponsesProgressSelector = createSelector(responsesSelector, ({progress}) => progress);
export const bffRecruitmentVacancyCandidatesResponsesProgressActionSelector = createSelector(responsesSelector, ({progressAction}) => progressAction);
export const bffRecruitmentVacancyCandidatesResponsesErrorSelector = createSelector(responsesSelector, ({error}) => error);
export const bffRecruitmentVacancyCandidatesResponsesListSelector = createSelector(responsesSelector, ({list}) => list);
export const bffRecruitmentVacancyCandidatesResponsesTotalCountSelector = createSelector(responsesSelector, ({totalCount}) => totalCount);
export const bffRecruitmentVacancyCandidatesResponsesTotalPagesSelector = createSelector(
    responsesSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffRecruitmentVacancyCandidatesResponsesPageDataSelector = createSelector(responsesSelector, ({pageData}) => pageData);
export const bffRecruitmentVacancyCandidatesResponsesProgressCardSelector = createSelector(responsesSelector, ({progressCard}) => progressCard);
const responsesSelectorCard = state => state.bff.recruitment.vacancyCandidatesResponses.card;
export const bffRecruitmentVacancyCandidatesResponsesCardSelector = createSelector(responsesSelectorCard, (card) => {
    const {
        responseFields,
        actualizeFields,
    } = card;

    return {
        ...card,
        responseFields: !isEmpty(responseFields) ? {
            list: responseFields,
            card: responseFields.reduce((result, item) => {
                return {
                    ...result,
                    [item.name]: item.value,
                };
            }, {}),
        } : {},
        actualizeFields: !isEmpty(actualizeFields) ? {
            list: actualizeFields,
            response: actualizeFields.reduce((result, item) => {
                return {
                    ...result,
                    [item.name]: item.responseValue,
                };
            }, {}),
            candidate: actualizeFields.reduce((result, item) => {
                return {
                    ...result,
                    [item.name]: item.candidateValue,
                };
            }, {}),
        } : {},
    };
});