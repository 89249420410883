export const getCurrentStep = ({activeItems, tabs}) => {
    const lastStepIndex = activeItems.length - 1;

    return tabs[lastStepIndex].value;
};

export const getNextStep = ({activeItems, tabs}) => {
    const nextStepIndex = activeItems.length;
    const value = tabs[nextStepIndex]?.value || "ERROR_NO_NEXT_STEPS";

    if (value === "ERROR_NO_NEXT_STEPS") {
        return tabs[nextStepIndex - 1].value;
    }

    return value;
};