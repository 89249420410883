import React from "react";
import {useTranslation} from "react-i18next";
import ReactPanZoom from "react-image-pan-zoom-rotate";

import Text from "../../../../../components/ActualComponents/Text";

import "./style.sass";

const ContractorVerificationDataCheckImage = (props) => {
    const {
        src,
        onClick,
    } = props;
    const {t} = useTranslation();

    return (
        <>
            <Text
                onClick={onClick}
                className="contractor-verification-check-image__open-image mb-2"
                level="3"
            >
                Открыть изображение в новом окне
            </Text>
            <div className="contractor-verification-check-image__container">
                <ReactPanZoom
                    image={src}
                    alt={t("contractor-document-scans.scan-alt")}
                />
            </div>
        </>
    );
};

export default ContractorVerificationDataCheckImage;