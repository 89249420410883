import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import {checkPassportDataBy, checkPassportDataForeigner, checkPassportDataRu} from "../../controlDateReadOnly";
import validationSchema from "../validation";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {getInitialTouched} from "../../../../../utils/objectHelper";
import {removePhoneMask} from "../../../../../utils/stringFormat";
import {convertStringToDate, handleDateFieldFilter, isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";
import {isClientUser} from "../../../../../constants/roles";

import {
    getArchivePhonesByContractorId,
    getPendingPersonalData,
    updateContractor,
} from "../../../../../ducks/contractor";

const useFormData = (card) => {
    const [agreeToReplaceTheExistingPhone, setAgreeToReplaceTheExistingPhone] = useState(false);
    const [newPhone, setNewPhone] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isConfirmChangePhoneModalShown, setIsConfirmChangePhoneModalShown] = useState(false);
    const [confirmChangePhoneModalText, setConfirmChangePhoneModalText] = useState("");

    const [isArchivedPhonesShown, setIsArchivedPhonesShown] = useState(false);

    const wrapperRef = useRef(null);

    const dispatch = useDispatch();

    const role = ls(USER_ROLE);

    const initialValues = {
        [CONTRACTOR_FIELD.LAST_NAME]: card[CONTRACTOR_FIELD.LAST_NAME] || "",
        [CONTRACTOR_FIELD.FIRST_NAME]: card[CONTRACTOR_FIELD.FIRST_NAME] || "",
        [CONTRACTOR_FIELD.PATRONYMIC]: card[CONTRACTOR_FIELD.PATRONYMIC] || "",
        [CONTRACTOR_FIELD.BIRTH_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.BIRTH_DATE]) || null,
        [CONTRACTOR_FIELD.GENDER]: card[CONTRACTOR_FIELD.GENDER] || "",
        [CONTRACTOR_FIELD.ADDRESS]: card[CONTRACTOR_FIELD.ADDRESS] || "",
        [CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS]: card[CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS] || "",
        [CONTRACTOR_FIELD.PHONE]: card[CONTRACTOR_FIELD.PHONE] || "",
        [CONTRACTOR_FIELD.EMAIL]: card[CONTRACTOR_FIELD.EMAIL] || "",
        [CONTRACTOR_FIELD.INN]: card[CONTRACTOR_FIELD.INN] || "",
        [CONTRACTOR_FIELD.ADDED_VIA_ADMIN_UI]: card[CONTRACTOR_FIELD.ADDED_VIA_ADMIN_UI] || false,

        [CONTRACTOR_FIELD.NAIMIX_JOINING_DATE]: card[CONTRACTOR_FIELD.NAIMIX_JOINING_DATE] || "",

        [CONTRACTOR_FIELD.CITIZENSHIP]: card[CONTRACTOR_FIELD.CITIZENSHIP] || "",
        [CONTRACTOR_FIELD.PASSPORT_TYPE]: card[CONTRACTOR_FIELD.PASSPORT_TYPE] || null,
        [CONTRACTOR_FIELD.EDM_PASSPORT_TYPE]: card[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE] || null,
        [CONTRACTOR_FIELD.BIRTH_PLACE]: card[CONTRACTOR_FIELD.BIRTH_PLACE] || "",
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: card[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY] || "",
        [CONTRACTOR_FIELD.ID_DOC_NUMBER]: card[CONTRACTOR_FIELD.ID_DOC_NUMBER] || "",
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]) || null,
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]: card[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE] || "",
        [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]) || null,
        [CONTRACTOR_FIELD.EIN]: card[CONTRACTOR_FIELD.EIN] || "",
        [CONTRACTOR_FIELD.EDM_STATUS]: card[CONTRACTOR_FIELD.EDM_STATUS] || "",
        [CONTRACTOR_FIELD.STATUS]: card[CONTRACTOR_FIELD.STATUS] || "",
    };

    const {
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        isValid,
        dirty,
        setValues,
        status,
        setStatus,
        validateForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: ((values, {setSubmitting}) => {
            const requestData = {
                ...card,
                ...values,
                [CONTRACTOR_FIELD.BIRTH_DATE]: handleDateFieldFilter(values[CONTRACTOR_FIELD.BIRTH_DATE]),
                [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: handleDateFieldFilter(values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]),
                [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: handleDateFieldFilter(values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]),
                [CONTRACTOR_FIELD.PHONE]: newPhone ? newPhone : removePhoneMask(values[CONTRACTOR_FIELD.PHONE]),
                agreeToReplaceTheExistingPhone,
                handleResponse: (errorMessage, errorCode) => {
                    handleResponseSave(errorMessage);

                    if (errorCode === "CONTRACTOR_BASIC_REGISTERED") {
                        setConfirmChangePhoneModalText(errorMessage);
                        setAgreeToReplaceTheExistingPhone(true);
                        setIsConfirmChangePhoneModalShown(true);
                        setNewPhone(removePhoneMask(values[CONTRACTOR_FIELD.PHONE]));
                    }
                },
                onSuccess: () => {
                    if (!isClientUser(role)) {
                        fetchPersonalPendingData();
                    }

                    setNewPhone(null);
                },
            };

            dispatch(updateContractor(requestData));

            setSubmitting(false);
        }),
        enableReinitialize: true,
        validationSchema: validationSchema(),
        validateOnBlur: false,
        initialTouched: getInitialTouched(initialValues),
    });

    useEffect(() => {
        const {
            contractorId,
        } = card;

        dispatch(getArchivePhonesByContractorId({
            contractorId,
            pageNum: 1,
            pageSize: 25,
        }));
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const citizenship = values[CONTRACTOR_FIELD.CITIZENSHIP];

        if (citizenship === citizenshipsDict.RU.value) {
            const message = checkPassportDataRu({
                values,
                card: values,
            });

            setStatus({[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: message});
        }

        if (citizenship === citizenshipsDict.BY.value) {
            const message = checkPassportDataBy({
                values,
                card: values,
            });

            setStatus({[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: message});
        }

        if (![citizenshipsDict.RU.value, citizenshipsDict.BY.value, citizenshipsDict.TJ.value].includes(citizenship)) {
            const message = checkPassportDataForeigner({
                values,
                card: values,
            });

            setStatus({[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: message});
        }
    }, []);

    useEffect(() => {
        if (dirty) {
            setStatus({});
        }
    }, [dirty]);

    const cancelEdit = () => {
        setIsEdit(false);
        setValues(initialValues);
    };

    const toggleCard = () => {
        validateForm().then(() => {});

        if (isEdit && !isValid) {
            return;
        }

        if (isEdit && isValid) {
            handleSubmit();
        }

        setIsEdit(prevState => !prevState);
    };

    const fetchPersonalPendingData = () => {
        const {
            contractorId,
        } = card;

        dispatch(getPendingPersonalData({
            contractorId,
        }));
    };

    const handleConfirmChangePhone = () => {

        handleSubmit();
        setIsConfirmChangePhoneModalShown(false);
    };

    const handleResponseSave = (result) => {
        if (isNullOrWhitespace(result)) {
            return;
        }

        cancelEdit();
    };

    const handleClickOutside = (event) => {
        if (wrapperRef?.current && !wrapperRef?.current.contains(event.target)) {
            setIsArchivedPhonesShown(false);
        }
    };

    const handleChangeArchivedPhonesShown = () => {
        setIsArchivedPhonesShown(true);
    };

    const getError = (field) => {
        if (status && status[field]) {
            return status[field];
        }

        if (touched[field] && errors[field]) {
            return errors[field];
        }
    };

    return {
        values,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        handleChange,
        errors,
        touched,
        isEdit,
        isArchivedPhonesShown,
        isConfirmChangePhoneModalShown,
        setIsConfirmChangePhoneModalShown,
        confirmChangePhoneModalText,
        wrapperRef,
        status,
        setStatus,
        getError,
        cancelEdit,
        toggleCard,
        handleChangeArchivedPhonesShown,
        handleClickOutside,
        handleConfirmChangePhone,
    };

};

export default useFormData;