import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import {mapMetricForChart} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/foreman/timesheet";
//*  TYPES  *//

const GET_TIME_SHEET_PAGE_REQUEST = "GET_TIME_SHEET_PAGE_REQUEST";
const GET_TIME_SHEET_PAGE_SUCCESS = "GET_TIME_SHEET_PAGE_SUCCESS";
const GET_TIME_SHEET_PAGE_ERROR = "GET_TIME_SHEET_PAGE_ERROR";

const GET_METRICS_FOR_TIME_SHEET_REQUEST = "GET_METRICS_FOR_TIME_SHEET_REQUEST";
const GET_METRICS_FOR_TIME_SHEET_SUCCESS = "GET_METRICS_FOR_TIME_SHEET_SUCCESS";
const GET_METRICS_FOR_TIME_SHEET_ERROR = "GET_METRICS_FOR_TIME_SHEET_ERROR";

//*  INITIAL STATE  *//

const initial = {
    timeSheet: {},
    list: [],
    totalCount: 0,
    pageData: {},
    metrics: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_TIME_SHEET_PAGE_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: {...payload},
        };
    case GET_METRICS_FOR_TIME_SHEET_SUCCESS:
        return {
            ...state,
            metrics: payload,
        };
    case GET_TIME_SHEET_PAGE_SUCCESS:
        const {
            timeSheets: list,
            totalCount,
        } = payload;

        return {
            ...state,
            progress: false,
            list,
            totalCount,
        };
    case GET_TIME_SHEET_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getTimeSheetPage(payload) {
    return {
        type: GET_TIME_SHEET_PAGE_REQUEST,
        payload,
    };
}

export function getMetricsForTimeSheet(payload) {
    return {
        type: GET_METRICS_FOR_TIME_SHEET_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const timesheetSelector = ({timesheet}) => timesheet;
export const timesheetTotalPagesSelector = createSelector(timesheetSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const timesheetListSelector = createSelector(timesheetSelector, ({list}) => list);
export const timesheetMetricSelector = createSelector(timesheetSelector, ({metrics}) => mapMetricForChart(metrics));
export const timesheetTotalCountSelector = createSelector(timesheetSelector, ({totalCount}) => totalCount);
export const timesheetProgressSelector = createSelector(timesheetSelector, ({progress}) => progress);

//*  SAGA  *//

//GET api/timesheet/getById?timesheetId={uuid}
export const getTimeSheetPageSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TIME_SHEET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TIME_SHEET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TIME_SHEET_PAGE_ERROR, payload: error});
    }
};

//GET /api/timesheet/getMetricsForTimeSheet
export const getMetricsForTimeSheetSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/getMetricsForTimeSheet`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_METRICS_FOR_TIME_SHEET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_METRICS_FOR_TIME_SHEET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_METRICS_FOR_TIME_SHEET_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_TIME_SHEET_PAGE_REQUEST, getTimeSheetPageSaga),
        takeEvery(GET_METRICS_FOR_TIME_SHEET_REQUEST, getMetricsForTimeSheetSaga),
    ]);
}
