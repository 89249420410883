import React from "react";

import CountRefreshButton from "../ActualComponents/CountRefreshButton";

export const ContractorsCountRefreshButton = (props) => {
    return (
        <CountRefreshButton
            label="Количество исполнителей"
            {...props}
        />
    );
};
