import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import CheckboxList from "../../../../components/CheckboxList";

import {useSelectedList} from "../../../../hooks/useSelectedList";

import bem from "../../../../utils/bem";
import {formatAmount, formatNumber, getFormattedFullName, getFullName} from "../../../../utils/stringFormat";

import PropTypes from "prop-types";

import "./style.sass";

const FinancePaymentListDuplicateCheck = props => {
    const {
        payments = [],
        close,
        submit,
        t,
    } = props;
    const [block, element] = bem("finance-payment-list-duplicate");
    const dispatch = useDispatch();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useSelectedList();

    const submitForm = () => {
        const payments = selectedList.filter(value => value.isSelected).map(value => ({
            clientId: value.clientId,
            orderId: value.orderId,
            paymentNumber: value.paymentNumberConfirmed,
        }));

        close();
        submit(payments);
    };

    const getRows = () => {
        return payments.map((item) => {
            const {
                paymentNumberConfirmed,
                paymentsNumberDuplicated,
                sumOfMoney,
                firstName,
                lastName,
                patronymic,
            } = item;

            const {isSelected = false} = selectedList.find(value => (
                value.paymentNumberConfirmed === paymentNumberConfirmed
            )) || {};

            return {
                ...item,
                key: paymentNumberConfirmed,
                showCheckBox: true,
                isSelected,
                contentRow: (
                    <NmListCard
                        checkbox
                        onlyLabels
                        alignItems="flex-end"
                        classNameMainContent="col-16 col-xxl-8"
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-8"
                        labels={[
                            {
                                label: "Номер подтверждаемой выплаты",
                                text: paymentNumberConfirmed,
                                columnOnMobile: true,
                            },
                            {
                                label: "Номер аналогичной выплаты",
                                text: paymentsNumberDuplicated.join(", "),
                                columnOnMobile: true,
                            },
                            {
                                label: "Исполнитель",
                                text: getFormattedFullName(getFullName(lastName, firstName, patronymic)),
                                columnOnMobile: true,
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма, ₽",
                                value: formatAmount(formatNumber(sumOfMoney, 2)),
                                className: "col-16 col-md-4 mt-md-4 mt-xxl-0",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="lg"
            onClose={close}
            className={block()}
            warningText={t("finance-payment-list.duplicate-warning")}
            footer={
                <ApplyButtons
                    mobile="column"
                    disabled={countSelected === 0}
                    onClose={close}
                    cancelBtnContent={t("button.cancel")}
                    submitBtnContent={t("button.confirm")}
                    submit={submitForm}
                />
            }
        >
            <CheckboxList
                className="col-16"
                isCheckBox
                count={countSelected}
                onSelectedRows={handleSelectedRows}
                rows={getRows()}
            />
        </NmModal>
    );
};

FinancePaymentListDuplicateCheck.propTypes = {
    submit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default withTranslation()(FinancePaymentListDuplicateCheck);