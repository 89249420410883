import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as BookmarkIcon} from "../../../images/bookmark_24.svg";
import {ReactComponent as SettingsIcon} from "../../../images/settings_24.svg";
import {useClientListSettings} from "./useClientSettings";
import {useClientListConfirm} from "./useConfirm";
import {useClientListFavorite} from "./useFavorite";
import {useNewClient} from "./useNewClient";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {SYSTEM_PROPERTIES_MESSAGE} from "../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {LINK_CLIENT_LIST} from "../../../constants/links";
import {ADMIN, NM_CONSULTANT, NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";
import {CLIENT_LIST_OPERATION} from "../constants";

import {history} from "../../../store/configureStore";

import {
    getClientSystemProperties,
    updateClientSystemProperties,
} from "../../../ducks/bff/clients/system-properties/actionCreators";
import {clientListOptimizedSelector, clientProgressSelector, setArchivedClient} from "../../../ducks/client";
import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";

export const useClientListAction = (params) => {
    const {
        fetchList,
        setPagination,
        setArchivedFilter,
        archivedFilter,
    } = params;
    const role = ls(USER_ROLE);

    const dispatch = useDispatch();
    const progress = useSelector(clientProgressSelector);
    const list = useSelector(clientListOptimizedSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    const {t} = useTranslation();

    const [logModalData, setLogModalData] = useState({});

    const {
        modalData,
        showConfirmWindow,
        handleCancel,
    } = useClientListConfirm({
        fetchList,
    });

    const {
        onOpenClientSettings,
        onCloseClientSettings,
        settingsData,
    } = useClientListSettings();

    const {
        isOpenNewClient,
        onOpenNewClient,
        onCloseNewClient,
    } = useNewClient();

    const {
        favoriteData,
        onChangeFavorite,
        onCloseFavorite,
        updateFavorite,
    } = useClientListFavorite({
        fetchList,
    });

    const onArchived = (clientId, type) => {
        const onSuccess = () => {
            fetchList();
        };

        dispatch(setArchivedClient(clientId, type, onSuccess));
    };

    const handleChangeTerminateContract = ({clientId, name, activitySuspended}) => {
        if (activitySuspended) {
            return toastError("Процедура расторжения агентского договора с компанией уже запущена");
        }

        showConfirmWindow({
            formId: "TerminateContractorForm",
            clientId,
            name,
            type: CLIENT_LIST_OPERATION.TERMINATE_CONTRACT,
            titleConfirm: "Расторжение агентского договора",
            submitBtnContent: "Расторгнуть договор",
            cancelBtnContent: "Отменить",
        });
    };

    const handleConfirm = () => {
        const {
            confirmData: {
                clientId,
                flag,
                requestData,
            },
            currentOperation,
        } = modalData;

        if (currentOperation === CLIENT_LIST_OPERATION.ARCHIVE) {
            onArchived(clientId, !flag);
        }

        if (
            [
                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN,
            ].includes(currentOperation)
        ) {
            dispatch(updateClientSystemProperties({
                data: requestData,
                onSuccess: () => {
                    toastSuccess(
                        requestData.paymentsWithoutConfirmNmAdmin
                        || requestData.civilPaymentsWithoutConfirmNmAdmin
                        || requestData.individualPaymentsWithoutConfirmNmAdmin
                            ? SYSTEM_PROPERTIES_MESSAGE.SUCCESS_SET_PAYMENT_WITHOUT_ADMIN
                            : SYSTEM_PROPERTIES_MESSAGE.SUCCESS_CANCEL_SET_PAYMENT_WITHOUT_ADMIN,
                    );
                    dispatch(getClientSystemProperties());
                },
            }));
        }

        handleCancel();
    };

    const getListCardActions = (value) => {
        const {
            clientId,
            name,
            favorite,
        } = value;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <BookmarkIcon />,
                        onlyIcon: true,
                        color: favorite ? "light-green" : "grey",
                        onClick: () => {
                            onChangeFavorite({
                                favorite,
                                clientId,
                                clientName: name,
                            });
                        },
                    },
                    asset: {
                        mobile: {
                            children: favorite ?
                                "Удалить из избранного" :
                                "Добавить в избранное",
                        },
                        tablet: {
                            children: favorite ?
                                "Удалить из избранного" :
                                "Добавить в избранное",
                        },
                    },
                    visible: ![NM_OPERATOR].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <SettingsIcon />,
                        onlyIcon: true,
                        color: "grey",
                        onClick: () => {
                            onOpenClientSettings({clientId, name});
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Настройки компании",
                        },
                        tablet: {
                            children: "Настройки компании",
                        },
                    },
                    visible: [ADMIN].includes(role)
                        && !value.archived
                        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessCompanySettings),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Расторгнуть договор",
                        color: "grey",
                        onClick: () => {
                            handleChangeTerminateContract(value);
                        },
                    },
                    visible: [ADMIN, NM_MANAGER].includes(role) && !value.archived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Лог изменений настроек компании",
                        onClick: () => {
                            setLogModalData({
                                clientId,
                                clientName: name,
                            });
                        },
                    },
                    visible: [ADMIN].includes(role),
                },
            ],
        };
    };

    const mediaControls = useMemo(() => {
        const archive = getArchiveButton(t, archivedFilter, {mobile: true});

        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: onOpenNewClient,
                        size: "xl",
                        children: "Создать компанию",
                        color: "green",
                        icon: <AddIcon />,
                    },
                    visible: [ADMIN, NM_CONSULTANT, NM_MANAGER].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: () => {
                            if (archivedFilter) {
                                history.push(LINK_CLIENT_LIST);
                                setArchivedFilter(false);
                                setPagination({pageNum: 1, pageSize: 25});

                                return;
                            }


                            history.push({
                                pathname: LINK_CLIENT_LIST,
                                search: "?active=false",
                            });
                            setArchivedFilter(true);
                            setPagination({pageNum: 1, pageSize: 25});
                        },
                        disabled: progress,
                    },
                    visible: [ADMIN, NM_CONSULTANT, NM_MANAGER, NM_OPERATOR].includes(role),
                },
            ],
        };
    }, [list]);

    return {
        getListCardActions,
        favoriteData,
        onCloseClientSettings,
        onCloseFavorite,
        updateFavorite,
        settingsData,
        isOpenNewClient,
        onCloseNewClient,
        mediaControls,
        modalData,
        showConfirmWindow,
        handleCancel,
        handleConfirm,
        logModalData,
        setLogModalData,
    };
};