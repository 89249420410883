import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import FilterContractor from "../../../components/ActualComponents/FilterContractor";
import FilterCustomer from "../../../components/ActualComponents/FilterCustomer";
import FilterObject from "../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../components/ActualComponents/FilterProject";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import ButtonWithTooltip from "../../../components/ButtonWithTooltip";
import PhoneWithCodeFilter from "../../../components/PhoneWithCodeFilter";

import {SimpleDropdownOptions} from "../../../constants/contractorList";

import PropTypes from "prop-types";

import "./style.sass";

class DocumentFilter extends Component {
    static propTypes = {
        onKeyPress: PropTypes.func,
        onChange: PropTypes.func,
        sendFilter: PropTypes.func,
        clearFilter: PropTypes.func,
        clientIdDefault: PropTypes.string,
    };

    static defaultProps = {
        onKeyPress: () => {
        },
        onChange: () => {
        },
        sendFilter: () => {
        },
        clearFilter: () => {

        },
        exportDocuments: () => {

        },
        filter: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: {},
        };
    }

    handleOnKeyPress = ({key}) => {
        if (key !== "Enter") {
            return;
        }

        const {sendFilter} = this.props;
        sendFilter();
    };

    static getDerivedStateFromProps(props, state) {
        const {filter} = props;
        const {filter: oldFilter} = state;

        if (JSON.stringify(filter) !== JSON.stringify(oldFilter)) {

            return {
                filter,
            };
        }

        return null;
    }

    renderButtons = () => {
        const {
            sendFilter,
            clearFilter,
        } = this.props;

        return (
            <>
                <FilterButtonsV2
                    className="document-filter__actions"
                    onSearch={sendFilter}
                    onClear={clearFilter}
                />
            </>
        );
    };

    render() {
        const {
            onChange,
            documentTypeDictOptions,
            documentTypeFilter,
            isSettings,
            isShowExportButton,
            onExport,
            t,
            clientIdDefault,
            isNotificationPage,
        } = this.props;

        const {
            filter: {
                orderNumFilter,
                orderNameFilter,
                fromDateFilter,
                toDateFilter,
                clientId,
                projectIdsFilter,
                objectIdsFilter,
                externalDocumentFilter,
                taskNumFilter,
                taskNameFilter,
                contractorIdFilter,
                contractorNameFilter,
                contractorPhoneFilter,
            },
        } = this.state;

        return (
            <NmForm
                addMargin
                className="document-filter"
                onKeyPress={this.handleOnKeyPress}
            >
                {
                    isSettings &&
                    <FilterCustomer
                        size="lg"
                        isClearable
                        onChange={onChange}
                        search
                        name="clientId"
                        value={clientId}
                    />
                }
                {
                    isNotificationPage ?
                        <>
                            <NmInputV2
                                size="lg"
                                onChange={onChange}
                                name="contractorNameFilter"
                                value={contractorNameFilter}
                                placeholder="Введите ФИО исполнителя"
                                label={t("document-filter.full-name")}
                            />
                            <PhoneWithCodeFilter
                                name="contractorPhoneFilter"
                                value={contractorPhoneFilter}
                                onChange={onChange}
                            />
                        </> :
                        <FilterContractor
                            size="lg"
                            onChange={onChange}
                            name="contractorIdFilter"
                            value={contractorIdFilter}
                            relatedClientId={clientIdDefault}
                            ignoreClientGroupRelations={true}
                        />
                }
                <NmInputV2
                    size="lg"
                    mask="999999999999"
                    maskChar={null}
                    label={t("document-filter.order-num")}
                    placeholder="Введите номер"
                    value={orderNumFilter}
                    name="orderNumFilter"
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    label={t("document-filter.order-name")}
                    placeholder="Введите название заказа"
                    value={orderNameFilter}
                    name="orderNameFilter"
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    mask="999999999999"
                    maskChar={null}
                    label="Номер задания"
                    placeholder="Введите номер задания"
                    value={taskNumFilter}
                    name="taskNumFilter"
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    label="Название задания"
                    placeholder="Введите название задания"
                    value={taskNameFilter}
                    name="taskNameFilter"
                    onChange={onChange}
                />
                <NmDropdownV2
                    size="lg"
                    multiple
                    label={t("document-filter.document-types")}
                    name="documentTypeFilter"
                    options={documentTypeDictOptions}
                    value={documentTypeFilter}
                    onChange={onChange}
                    placeholder={t("client-documents.select-documents-placeholder")}
                    isAllSelectionShown={false}
                />
                <FilterProject
                    isActiveStyle={false}
                    label="Проекты"
                    clientId={clientId || clientIdDefault}
                    projectIdsFilter={projectIdsFilter}
                    name="projectIdsFilter"
                    onChange={onChange}
                    placeholder="Выберите проекты"
                />
                <FilterObject
                    label="Объекты"
                    isActiveStyle={false}
                    clientId={clientId || clientIdDefault}
                    projectIdsFilter={projectIdsFilter}
                    onChange={onChange}
                    objectIdsFilter={objectIdsFilter}
                    name="objectIdsFilter"
                    placeholder="Выберите объекты"
                />
                <NmDropdownV2
                    size="lg"
                    label="Внешний РД"
                    name="externalDocumentFilter"
                    options={[
                        {
                            key: null,
                            text: "Все",
                            value: null,
                        },
                        ...SimpleDropdownOptions,
                    ]}
                    value={externalDocumentFilter}
                    onChange={onChange}
                    placeholder="Все"
                />
                <NmDateRangePickerV2
                    size="lg"
                    label={t("document-filter.period")}
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{fromDateFilter, toDateFilter}}
                    isClearable={true}
                    handleOnChangeRangeDatepicker={(value, name) => {
                        onChange(null, {value, name});
                    }}
                />
                {
                    (isShowExportButton && !isSettings) &&
                    <ButtonWithTooltip
                        filter
                        size="lg"
                        onClick={onExport}
                        className="document-filter__button-with-tooltip"
                        buttonClass="document-filter__upload"
                        children={t("button.export")}
                        type="button"
                        tooltip={
                            (
                                <HelpTooltip
                                    info
                                    width={24}
                                    height={24}
                                    children={t("documents-list.unloading-documents-specified-filters")}
                                    position="bottom-right"
                                    type="light"
                                />
                            )
                        }
                    />
                }
                {this.renderButtons()}
            </NmForm>
        );
    }
}


export default withTranslation()(DocumentFilter);
