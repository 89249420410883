import React, {Component} from "react";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import OrderContractors, {ORDER_CONTRACTORS_SORT_TYPE} from "../../../../components/OrderContractors";
import {OrderContractorsFilter} from "../contractors-filter";
import OrderCardFindPerformers from "../find-performers";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../utils/dateFormat";
import {CURRENT_CLIENT_ID, ls, USER_ROLE} from "../../../../utils/localstorage";
import {filterEmptyValues} from "../../../../utils/objectHelper";
import {getNumberFromFormattedAmount, removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {CONTRACTORS_TABS_TYPES, TIME_INTERVAL_PAYMENT_POSSIBILITY} from "../../../../constants/contractor";
import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";
import {LINK_CLIENT_INVITING_CONTRACTORS_LIST, LINK_ORDER_CARD} from "../../../../constants/links";
import {ORDER_SOURCE_TYPES} from "../../../../constants/order";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../constants/roles";

import {clientCurrentMemberSelector} from "../../../../ducks/clientMember";
import {
    updateFieldContractorStore,
} from "../../../../ducks/contractor";
import {
    bankCheckCancelToken,
    getContractorsPaymentPossibility,
    refreshContractorsPaymentPossibility,
} from "../../../../ducks/contractorBankCheck";
import {
    getPageOrderContractors,
    getProgressOrderCardSelector,
    orderApplicationCountMapSelector,
    orderCardSelector, orderContractorListSelector,
    orderContractorProgressSelector,
    orderContractorTotalCountSelector,
    orderContractorTotalPagesSelector,
    updateOrderContractorsFound,
} from "../../../../ducks/order";

import "./style.sass";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR, TAB_TYPE_BY_SUB_PAGE} from "../../../../constants/link-params";

class OrderCardContractors extends Component {
    state = {
        pageNum: 1,
        pageSize: 25,
        sortType: ORDER_CONTRACTORS_SORT_TYPE.CREATE_DATE_ASC,
        filterData: {},
    };

    role = ls(USER_ROLE);

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {subpage} = this.props;
        const {subpage: _subpage} = prevProps;

        if (subpage !== _subpage) {
            this.setState({
                pageNum: 1,
                pageSize: 25,
            }, this.fetchList);
        }
    }

    componentDidMount() {
        this.fetchList();
    }

    componentWillUnmount() {
        const {updateFieldContractorStore} = this.props;

        bankCheckCancelToken.cancel();
        clearTimeout(this.checkBankPossibleTimer);

        updateFieldContractorStore({allApplications: [], allApplicationCountMap: {}});
    }

    get baseUrl() {
        const {orderId, clientId} = this.props;

        const linkOrder = LINK_ORDER_CARD.replace(":clientId", clientId);

        return linkOrder.replace(":orderId", orderId).replace(":page", SELF_CONTRACTOR);
    }

    get invitingContractorsLink() {
        const {orderId, clientId} = this.props;
        const _clientId = clientId ? clientId : ls(CURRENT_CLIENT_ID);

        return LINK_CLIENT_INVITING_CONTRACTORS_LIST
            .replace(":type", CONTRACTORS_TABS_TYPES.BY_ORDER)
            .replace(":clientId", _clientId)
            .replace(":orderId", orderId);
    }

    refreshBankChecks = () => {
        const {list, refreshContractorsPaymentPossibility} = this.props;

        const contractorIds = [...new Set(list.map(item => item.contractorId))];

        if (isEmpty(contractorIds)) {
            return;
        }

        refreshContractorsPaymentPossibility({
            contractorIds,
            useFnsThreshold: true,
            handleResponse: this.onSuccessRefreshBankChecks,
        });
    };

    onSuccessRefreshBankChecks = ({errorMessage}) => {
        if (errorMessage) {
            return;
        }

        this.getBankChecks();
    };

    getBankChecks = () => {
        const {
            getContractorsPaymentPossibility,
            list,
            clientId,
        } = this.props;

        const contractorIds = [...new Set(list.map(item => item.contractorId))];

        if (isEmpty(contractorIds)) {
            return;
        }

        getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(this.checkBankPossibleTimer);
            },
            handleResponse: this.onSuccessGetBankChecks,
            clientId,
            contractorIds,
        });
    };

    onSuccessGetBankChecks = (result = {}) => {
        const {
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            return;
        }

        this.checkBankPossibleTimer = setTimeout(this.getBankChecks, TIME_INTERVAL_PAYMENT_POSSIBILITY);
    };

    fetchList = () => {
        const {
            getPageOrderContractors,
            subpage,
            orderId,
            clientId,
        } = this.props;
        const {
            pageNum,
            pageSize,
            sortType,
            filterData,
        } = this.state;

        const tabType = TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE;

        const {
            applicationInitDateFrom,
            applicationInitDateTo,
            signFrameContract,
            applicationType,
            phone,
            ageTo,
            ageFrom,
            smzStatus,
            registrationStatuses,
            gender,
            citizenships,
            fio,
        } = filterData;

        const contractorsCriteria = filterEmptyValues({
            fio: handleFormString(fio),
            phone: phone ? removePhoneMask(phone) : undefined,
            ageTo: ageTo ? getNumberFromFormattedAmount(ageTo) : undefined,
            ageFrom: ageFrom ? getNumberFromFormattedAmount(ageFrom) : undefined,
            smzStatus: smzStatus === EMPTY_OPTION_KEY ? undefined : smzStatus,
            registrationStatuses: isEmpty(registrationStatuses) ? undefined : registrationStatuses,
            gender: gender === EMPTY_OPTION_KEY ? undefined : gender,
            citizenships: isEmpty(citizenships) ? undefined : citizenships,
        });

        const documentsCriteria = filterEmptyValues({
            signFrameContract: signFrameContract === EMPTY_OPTION_KEY ? undefined : signFrameContract,
        });

        const orderContractorsLogsCriteria = filterEmptyValues({
            applicationInitDateFrom: getStartFilterDateWithTimeInUtc(applicationInitDateFrom),
            applicationInitDateTo: getEndFilterDateWithTimeInUtc(applicationInitDateTo),
            applicationType: applicationType === EMPTY_OPTION_KEY ? undefined : applicationType,
        });

        getPageOrderContractors(filterEmptyValues({
            clientId,
            orderId,
            tabType,
            pageNum,
            pageSize,
            sortType: [
                TAB_TYPE_BY_SUB_PAGE.responses.TAB_TYPE,
                TAB_TYPE_BY_SUB_PAGE.invited.TAB_TYPE,
                TAB_TYPE_BY_SUB_PAGE["pre-approved"].TAB_TYPE,
            ].includes(tabType) ? sortType : undefined,
            contractorsCriteria: isEmpty(contractorsCriteria) ? undefined : contractorsCriteria,
            documentsCriteria: isEmpty(documentsCriteria) ? undefined : documentsCriteria,
            orderContractorsLogsCriteria: isEmpty(orderContractorsLogsCriteria) ? undefined : orderContractorsLogsCriteria,
        }));
    };

    onChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    get isShowActions() {
        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)) {
            return false;
        }

        const {
            order: {
                insurance,
                status,
                contractorsNeededCount,
                orderSource,
            },
            countMap,
        } = this.props;

        if ([ORDER_SOURCE_TYPES.API, ORDER_SOURCE_TYPES.REGISTRY].includes(orderSource)) {
            return false;
        }

        return status === ORDER_STATUS.PUBLISHED && !insurance ||
            status === ORDER_STATUS.PUBLISHED && insurance && countMap[SUB_PAGE_ORDER_CONTRACTOR.HIRED.TAB_TYPE] === contractorsNeededCount ||
            status === ORDER_STATUS.CONTRACTOR_SEARCH;
    }

    onClickSort = ({sortType}) => {
        this.setState({
            sortType,
        }, this.fetchList);
    };

    onSearch = (filterData) => {
        this.setState({
            filterData,
        }, this.fetchList);
    };

    clearFilter = () => {
        this.setState({
            filterData: {},
        }, this.fetchList);
    };

    render() {
        const {
            order: {
                name,
                orderNum,
                status,
            },
            countMap,
            subpage,
            orderId,
            clientId,
            totalPages,
            list,
            progress,
            totalCount,
            currentMember,
        } = this.props;
        const {
            pageNum,
            pageSize,
            sortType,
            filterData,
        } = this.state;

        return (
            <NmPage
                header={
                    <NmPageHeader
                        text="Исполнители"
                    />
                }
                filtersBase={
                    <OrderContractorsFilter
                        onSearch={this.onSearch}
                        clearFilter={this.clearFilter}
                        filterData={filterData}
                    />
                }
                widthByFilter={true}
                typeFilter="vertical"
                className="order-card-contractors"
                classNameContent="order-card-contractors__content"
                totalPages={totalPages}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                onChangePageSize={this.onChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                showHeaderBlock={false}
            >
                <OrderContractors
                    totalCount={totalCount}
                    pageSize={pageSize}
                    baseUrl={this.baseUrl}
                    subpage={subpage}
                    orderId={orderId}
                    clientId={clientId}
                    fetchList={this.fetchList}
                    countMap={countMap}
                    list={list}
                    progress={progress}
                    orderStatus={status}
                    refreshBankChecks={this.refreshBankChecks}
                    getBankChecks={this.getBankChecks}
                    sortType={sortType}
                    onClickSort={this.onClickSort}
                >
                    {
                        !currentMember.contractorInvitationProhibited &&
                        <OrderCardFindPerformers
                            orderData={{name, orderNum}}
                            className="order-card-contractors__find-performers"
                            invitingContractorsLink={this.invitingContractorsLink}
                            isShow={this.isShowActions}
                        />
                    }
                </OrderContractors>
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        order: orderCardSelector(state),
        countMap: orderApplicationCountMapSelector(state),
        totalPages: orderContractorTotalPagesSelector(state),
        progressCard: getProgressOrderCardSelector(state),
        list: orderContractorListSelector(state),
        progress: orderContractorProgressSelector(state),
        totalCount: orderContractorTotalCountSelector(state),
        currentMember: clientCurrentMemberSelector(state),
    }),
    {
        getPageOrderContractors,
        refreshContractorsPaymentPossibility,
        getContractorsPaymentPossibility,
        updateOrderContractorsFound,
        updateFieldContractorStore,
    },
)(OrderCardContractors);