import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";

import ExtLink from "../../../components/ExtLink";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import TableDiv from "../../../components/TableDiv";
import {DEPOSIT_SUB_PAGE} from "../../deposit/client-list";
import NmDepositHistoryWindow from "../nm-deposit-history-window/index";
import PivotFilter from "../patent-pivot-table/pivot-filter";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {formatAmount, formatNumber} from "../../../utils/stringFormat";
import {convertStringToDate, getAmount, isNullOrWhitespace} from "../../../utils/stringHelper";

import {patentsPivotHeaderList} from "../../../constants/headersTable";
import {LINK_CLIENT_DEPOSIT_LIST} from "../../../constants/links";

import {
    getPatentsPaymentsSummary,
    getPatentsPaymentsTotalSummary,
    patentsPaymentsSummaryListSelector,
    patentsPaymentsSummaryListTotalPagesSelector,
    patentsPaymentsSummaryProgressSelector,
    patentsPaymentsSummaryTotalCountSelector,
    patentsPaymentsTotalSummaryDataSelector,
    patentsPaymentsTotalSummaryProgressSelector,
} from "../../../ducks/patentsPayments";

import "./style.sass";

class PatentPivotTable extends Component {
    constructor(props) {

        super(props);

        const {
            pageNumber,
            pageSize,
            clientId,
            from,
            to,
        } = props.match.params;

        this.state = {
            pageNum: pageNumber || 1,
            pageSize: pageSize ? Number(pageSize) : 25,
            filterData: {
                clientId: clientId || null,
                toDate: convertStringToDate(to),
                fromDate: convertStringToDate(from),
            },
            sortByDateDesc: false,
        };
    }

    componentDidMount() {
        this.fetchList();
        this.fetchTotalData();
    };

    fetchList = () => {
        const {
            getPatentsPaymentsSummary,
        } = this.props;

        const {
            pageNum,
            pageSize,
            sortByDateDesc,
            filterData = {},
        } = this.state;

        getPatentsPaymentsSummary({
            data: {
                pageNum,
                pageSize,
                sortByDateDesc,
                ...filterData,
            },
        });
    };

    fetchTotalData = () => {
        const {
            getPatentsPaymentsTotalSummary,
        } = this.props;

        const {
            filterData = {},
        } = this.state;

        getPatentsPaymentsTotalSummary({
            data: {...filterData},
        });
    };

    handleChangeSearch = (sortField, orderType) => {
        if (!isNullOrWhitespace(orderType)) {
            this.setState(
                {
                    sortByDateDesc: orderType === "desc",
                },
                this.fetchList,
            );
        }
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {"activePage": pageNum}) => {
        const {
            pageNum: pageNumOld,
        } = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    getTableHeaders = () => {
        const {
            sortByDateDesc,
        } = this.state;

        const newSortState = sortByDateDesc ? "desc" : "asc";

        return patentsPivotHeaderList.map(value => ({
            ...value,
            typeSort: value.typeSort ? newSortState : value.typeSort,
        }));
    };

    getDepositReplenishment = (item, index) => {
        if (item.depositReplenishment === 0) {
            return null;
        }

        const {list} = this.props;

        return (
            <div className="patent-pivot-table__replenishment">
                <span>
                    {`${formatAmount(formatNumber(item.arrival, 2))} `}
                </span>
                {
                    item.depositHistory &&
                    <NmDepositHistoryWindow
                        data={item.depositHistory}
                        arrivalFieldName="arrival"
                        dateFieldName="date"
                        currentPosition={index + 1}
                        listLength={list.length}
                    />
                }
            </div>
        );
    };

    renderSummary = (totalData) => {
        const {t} = this.props;
        return {
            ...totalData,
            orderReserveAmount: getAmount(totalData.orderReserveAmount),
            arrivalColumn: this.getDepositReplenishment(totalData),
            paymentNotCompletedAmount: getAmount(totalData.paymentNotCompletedAmount),
            clientName: t("finance-pivot-table.total"),
            lastArrivalDate: "",
        };
    };

    mapTableData = () => {
        const {
            list,
            totalData = [],
        } = this.props;

        const {
            pageNum,
            pageSize,
            clientIdFilter,
            fromDateFilter,
            toDateFilter,
        } = this.state;

        const rows = list.map((item, index) => {
            const clientDepositLink = LINK_CLIENT_DEPOSIT_LIST
                .replace(":clientId", item.clientId)
                .replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);

            return {
                ...item,
                orderReserveAmount: getAmount(item.orderReserveAmount),
                arrivalColumn: this.getDepositReplenishment(item, index),
                paymentNotCompletedAmount: getAmount(item.paymentNotCompletedAmount),
                lastArrivalDate: dateFormat(convertUtcToLocal(item.lastArrivalDate)),
                clientName: (
                    <ExtLink
                        pageData={{pageNum, pageSize}}
                        filterData={{clientIdFilter, fromDateFilter, toDateFilter}}
                        to={clientDepositLink}
                        historyEnabled={true}
                        className="patent-pivot-table__client-name"
                    >
                        {item.clientName}
                        {item.brand && `(${item.brand})`}
                    </ExtLink>
                ),
            };
        });

        rows.unshift(this.renderSummary(totalData));

        return {
            headers: this.getTableHeaders(),
            rows,
        };
    };

    search = (filterData) => {
        this.setState({
            filterData,
        }, this.fetchList);
    };

    renderContent() {
        return (
            <div className="patent-pivot-table__overflow-container">
                <TableDiv
                    className="patent-pivot-table__table"
                    handleChangeSearch={this.handleChangeSearch}
                    tableData={this.mapTableData()}
                />
            </div>
        );
    };

    render() {
        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        const {
            progress,
            totalDataProgress,
            totalPages,
            totalCount,
        } = this.props;

        return (
            <NmPage
                header={
                    <NmPageHeader
                        text="Сводная по патентам"
                    />
                }
                filtersBase={
                    <PivotFilter
                        search={this.search}
                        filterData={filterData}
                    />
                }
                typeFilter="vertical"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                className="patent-pivot-table"
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                isLoaded={progress || totalDataProgress}
            >
                {this.renderContent()}
            </NmPage>
        );
    };

}

export default connect(
    state => ({
        list: patentsPaymentsSummaryListSelector(state),
        totalData: patentsPaymentsTotalSummaryDataSelector(state),
        progress: patentsPaymentsSummaryProgressSelector(state),
        totalDataProgress: patentsPaymentsTotalSummaryProgressSelector(state),
        totalPages: patentsPaymentsSummaryListTotalPagesSelector(state),
        totalCount: patentsPaymentsSummaryTotalCountSelector(state),
    }),
    {
        getPatentsPaymentsSummary,
        getPatentsPaymentsTotalSummary,
    },
)(withTranslation()(PatentPivotTable));