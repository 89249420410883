import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import OrderTemplateInfo from "../template-info";
import OrderActRegistryEdit from "./edit";

import {closeConfirmAction, onConfirmAction} from "../../../hooks/useConfirm";
import {usePagination} from "../../../hooks/usePagination";
import {useOrderActRegistryAction} from "./hooks/useAction";
import {useOrderActRegistryFetch} from "./hooks/useFetch";
import {useOrderActRegistryFilter} from "./hooks/useFilter";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {formatAmountWithNullChecking, getFullName} from "../../../utils/stringFormat";

import {PAYMENT_METHOD_DICT} from "../../../constants/clientSettings";
import {LINK_CLIENT_ORDER_ACT_REGISTRY_CARD} from "../../../constants/links";

import {
    actRegistriesListSelector, actRegistriesProgressListSelector,
    actRegistriesTotalCountSelector,
    actRegistriesTotalPagesSelector,
} from "../../../ducks/actRegistries";

const OrderActRegistry = (props) => {
    const {match: {params: {clientId}}} = props;
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const totalCount = useSelector(actRegistriesTotalCountSelector);
    const totalPages = useSelector(actRegistriesTotalPagesSelector);
    const list = useSelector(actRegistriesListSelector);
    const progress = useSelector(actRegistriesProgressListSelector);

    const {
        submitFilter,
        clearFilter,
        filter,
        filtersBase,
        isSearch,
    } = useOrderActRegistryFilter({
        setPagination,
        pageSize,
    });

    const {
        mediaControls,
        getListCardActions,
        archived,
        editData,
        openEdit,
        role,
        onCloseEdit,
        dispatchConfirm,
        isOpenConfirm,
        contentConfirm,
    } = useOrderActRegistryAction({
        setPagination,
        pageSize,
    });

    const {
        onFetchList,
    } = useOrderActRegistryFetch({
        pageNum,
        pageSize,
        archived,
        filter,
        clientId,
    });

    const getRows = () => {
        return list.map((item) => {
            const {
                creatorFirstName,
                creatorLastName,
                paymentsTotalCount,
                paymentsSignedCount,
                paymentsInProgressCount,
                paymentsErrorCount,
                paymentsDeclineCount,
                ordinalNumber,
                registryId,
                createdAt,
                registryPaymentSum,
                name,
                clientId,
                creatorPatronymic,
                paymentMethod,
            } = item;

            // Всего
            const totalCount = paymentsTotalCount || 0;
            // В процессе
            const inProgressCount = paymentsInProgressCount || 0;
            // Подписано исполнителем
            const paidCount = paymentsSignedCount || 0;
            // Отклонено
            const rejectCount = paymentsErrorCount + paymentsDeclineCount;

            const link = LINK_CLIENT_ORDER_ACT_REGISTRY_CARD.replace(":clientId", clientId).replace(":registryId", registryId);

            return {
                ...item,
                key: registryId,
                contentRow: (
                    <NmListCard
                        secondaryHeader={`Реестр от ${dateFormat(convertUtcToLocal(createdAt))}`}
                        classNameMainContent="col-16 col-xxl-9"
                        primaryHeader={
                            <Link to={link}>
                                {`Оплата по реестру №${ordinalNumber} — ${name}`}
                            </Link>
                        }
                        labels={[
                            {label: "Ответственный", text: getFullName(creatorLastName, creatorFirstName, creatorPatronymic)},
                            {
                                label: "Шаблон акта",
                                text: (
                                    <OrderTemplateInfo
                                        id={item.actOfAcceptanceOfWorkTemplateId}
                                        linkName={item.actOfAcceptanceOfWorkTemplateName}
                                        link={item.actOfAcceptanceOfWorkTemplatePdfDownloadLink}
                                    />
                                ),
                                textTitle: item.actOfAcceptanceOfWorkTemplateName,
                            },
                            {label: "Способ проведения оплат", text: PAYMENT_METHOD_DICT[paymentMethod]},
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-6 align-items-stretch"
                        cards={[
                            {
                                title: "Всего/В процессе/Подписано исполнителем/Отклонено",
                                values: [{bold: true, text: totalCount}, {text: inProgressCount}, {text: paidCount}, {text: rejectCount}],
                                className: "col-16 col-md-8",
                            },
                            {
                                title: "Сумма реестра, ₽\n",
                                value: formatAmountWithNullChecking(registryPaymentSum),
                                className: "col-16 col-md-8",
                            },
                        ]}
                        actionsClassName="col-1 col-xxl-1 justify-content-end"
                        mediaControls={getListCardActions(item, onFetchList)}
                    />
                ),
            };
        });
    };

    const getEdit = () => {
        if (!openEdit) {
            return null;
        }

        return (
            <OrderActRegistryEdit
                role={role}
                clientId={clientId}
                editData={editData}
                onClose={onCloseEdit}
                onFetchList={onFetchList}
            />
        );
    };

    const getConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                content={contentConfirm}
                onCancel={() => dispatchConfirm(closeConfirmAction())}
                onConfirm={() => dispatchConfirm(onConfirmAction())}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                isOnlyConfirm
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    count={totalCount}
                >
                    Реестры актов
                </NmTitle>
            }
            mediaControls={mediaControls}
            totalPages={totalPages}
            totalCount={totalCount}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            pageNum={pageNum}
            pageSize={pageSize}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    filters={filtersBase}
                    onSubmit={submitFilter}
                    clearFilter={clearFilter}
                />
            }
            isLoaded={progress}
        >
            {getEdit()}
            {getConfirm()}
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};

export default OrderActRegistry;