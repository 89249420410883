import {convertUTCTimeToLocalTime} from "../../../../utils/dateFormat";

export const getCheckText = (onlyCheck, checkWithPhoto, checkTimeFrom, checkTimeOut) => {
    const checksTimeText = checkTimeFrom ? ` (с ${convertUTCTimeToLocalTime(checkTimeFrom).replace(":", ".")} до ${convertUTCTimeToLocalTime(checkTimeOut).replace(":", ".")})` : "";

    if (onlyCheck && !checkWithPhoto) {
        return `Да${checksTimeText}`;
    }

    if (onlyCheck && checkWithPhoto) {
        return `Да, с фотографией${checksTimeText}`;
    }

    return "Нет";
};

const TRUNCATED_LINES_COUNT_BY_MEDIA_QUERIES = {
    MOBILE: 2,
    TABLET: 3,
    DESKTOP: 5,
};

// для задания кол-ва строк обрезаемых в описании заказа в шапке
export const getLinesByMediaQueries = ({mobile, tablet}) => {
    if (mobile) {
        return TRUNCATED_LINES_COUNT_BY_MEDIA_QUERIES.MOBILE;
    }

    if (tablet) {
        return TRUNCATED_LINES_COUNT_BY_MEDIA_QUERIES.TABLET;
    }

    return TRUNCATED_LINES_COUNT_BY_MEDIA_QUERIES.DESKTOP;
};