import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {isNullOrWhitespace} from "../../utils/stringHelper";

import {requiredMessage} from "../../constants/validationRules";

import {reasonCompletionOptionType} from "../../types";
import PropTypes from "prop-types";

import "./style.sass";

class NmReasonCompletion extends Component {
    static propTypes = {
        close: PropTypes.func,
        submit: PropTypes.func,
        question: PropTypes.string,
        options: PropTypes.arrayOf(reasonCompletionOptionType),
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        close: () => {
        },
        submit: () => {
        },
        question: "",
        options: [],
        disabled: false,
        loading: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            textareaError:  null,
            form: {
                reason: props.options.length !== 0 ? props.options[0].value : "",
                reasonDescription: "",
                isOpenTextarea: false,
            },
        };
    }

    validate = () => {
        const {
            form,
            isOpenTextarea,
            textareaError,
        } = this.state;
        const {textarea} = form;

        if (isOpenTextarea && isNullOrWhitespace(textarea)) {
            this.setState({
                textareaError: requiredMessage,
            });

            return false;
        }

        if (textareaError) {
            this.setState({
                textareaError: null,
            });
        }

        return true;
    }

    submit = () => {
        const {submit} = this.props;
        const {form} = this.state;

        const isValid = this.validate();

        if (!isValid) {
            return false;
        }

        submit(form);
    };

    handleChange = (event, {name, value, options}) => {
        if (name === "reason" && options && options.length) {
            const {value: reasonDescription} = options[0];

            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    reasonDescription,
                    [name]: value,
                },
            }));

            return;
        }

        this.setState(prevState => ({
            ...prevState,
            textareaError: null,
            isOpenTextarea: value === options.length - 1,
            form: {
                ...prevState.form,
                reasonDescription: "",
                [name]: value,
            },
        }));
    };

    handleChangeTextarea = (event, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value,
            },
        }));
    };

    renderAnswerDescription(options) {
        const {
            form: {
                reasonDescription,
                textarea,
            },
            isOpenTextarea,
            textareaError,
        } = this.state;

        return (
            <>
                <div className="nm-reason-completion__answer-item-description">
                    {options.map(({value, text}) => (
                        <NmRadioV2
                            key={value}
                            className="nm-reason-completion__radio"
                            radio
                            label={text}
                            value={value}
                            name="reasonDescription"
                            checked={reasonDescription === value}
                            onChange={(event, data) => {this.handleChange(event, {...data, options});}}
                        />
                    ),
                    )}
                </div>
                {
                    isOpenTextarea &&
                    <NmTextareaV2
                        value={textarea}
                        name="textarea"
                        onChange={this.handleChangeTextarea}
                        maxLength={255}
                        placeholder="Введите причину завершения заказа"
                        className="nm-reason-completion__textarea"
                        error={textareaError}
                    />
                }
            </>
        );
    }

    renderAnswerItem = ({text, value, options = []}, index) => {
        const {form} = this.state;
        const {reason} = form;
        const isChecked = reason === value;

        return (
            <div key={index}>
                <NmRadioV2
                    key={value}
                    className="nm-reason-completion__radio"
                    radio
                    label={text}
                    value={value}
                    name="reason"
                    checked={isChecked}
                    onChange={(event, data) => this.handleChange(event, {...data, options})}
                />
                {
                    (options && reason === value) &&
                    this.renderAnswerDescription(options)
                }
            </div>
        );
    };

    render() {
        const {
            close,
            question,
            options,
            t,
            loading,
            disabled,
        } = this.props;

        return (
            <NmModal
                size="md"
                className="nm-reason-completion"
                onClose={close}
                header={
                    <NmTitle
                        align="center"
                        size="lg"
                    >
                        {question}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent={t("button.confirm")}
                        cancelBtnContent={t("button.cancel")}
                        disabled={disabled}
                        loading={loading}
                        onClose={close}
                        submit={this.submit}
                    />
                }
            >
                <NmForm>
                    {options.map(this.renderAnswerItem)}
                </NmForm>
            </NmModal>
        );
    }
}

export default withTranslation()(NmReasonCompletion);
