import {useEffect, useState} from "react";

import {downloadTermsOfUse} from "../utils/strapiService";

export function useTermOfUseDocumentStrapi() {
    const [data, setData] = useState({fileName: "", url: ""});

    useEffect(() => {
        const fetchData = async () => {
            const dataStrapi = await downloadTermsOfUse();

            setData(dataStrapi);
        };
        fetchData();
    }, []);
    return {
        ...data,
    };
}