import {useState} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {convertUtcToLocal, getEndDate, getStartDate} from "../../../../../utils/dateFormat";

import {documentCustomTemplateEventLogTypeOptionsSelector} from "../../../../../ducks/documentsCustomTemplate";

export function useTemplatesLogsFilter() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const documentCustomTemplateEventLogTypeOptions = useSelector(documentCustomTemplateEventLogTypeOptionsSelector);

    function mapFilterDto(filter) {
        const {
            typeFilter,
            fromDateTimeFilter,
            toDateTimeFilter,
        } = filter;

        return {
            typeFilter: typeFilter?.length ? typeFilter : undefined,
            fromDateTimeFilter: convertUtcToLocal(getStartDate(fromDateTimeFilter)),
            toDateTimeFilter: convertUtcToLocal(getEndDate(toDateTimeFilter)),
        };
    }

    const filtersBase = [
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    name: "typeFilter",
                    label: "Тип события",
                    options: documentCustomTemplateEventLogTypeOptions,
                    placeholder: "Выберите из списка",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    dateFormat: "dd.MM.yy",
                    startFieldName: "fromDateTimeFilter",
                    endFieldName: "toDateTimeFilter",
                    label: "Период события",
                    isClearable: true,
                    value: {
                        fromDateLastUpdateFilter: null,
                        toDateLastUpdateFilter: null,
                    },
                },
            ],
        },
    ];

    const setFilterDto = (filter) => {
        setFilter(filter);
    };

    return {
        filterDto,
        mapFilterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
        filtersBase,
    };
}