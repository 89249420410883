import React from "react";
import {Switch} from "react-router";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import {KedoPositions} from "./positions";
import {KedoSubdivisions} from "./subdivisions";

import {
    KEDO_LOAD_DOCUMENT_TYPES,
    KEDO_POSITIONS,
    KEDO_SUBDIVISIONS,
    KEDO_TEMPLATE_DOCUMENT_TYPES,
} from "../../../constants/links";

import {KedoDocumentTypes} from "./document-types";
import {KedoTemplateDocumentTypes} from "./template-document-types";

export const KedoDirectoryRoutes = (props) => {
    const {
        baseUrl,
    } = props;

    return (
        <Switch>
            <CheckRoleRoute
                exact
                path={`${baseUrl}${KEDO_POSITIONS}`}
                render={(props) => {
                    return (
                        <KedoPositions
                            {...props}
                            baseUrl={baseUrl}
                        />
                    );
                }}
            />
            <CheckRoleRoute
                exact
                path={`${baseUrl}${KEDO_SUBDIVISIONS}`}
                render={(props) => {
                    return (
                        <KedoSubdivisions
                            {...props}
                            baseUrl={baseUrl}
                        />
                    );
                }}
            />
            <CheckRoleRoute
                path={`${baseUrl}${KEDO_LOAD_DOCUMENT_TYPES}`}
                render={(props) => {
                    return (
                        <KedoDocumentTypes
                            {...props}
                            baseUrl={baseUrl}
                        />
                    );
                }}
            />
            <CheckRoleRoute
                exact
                path={`${baseUrl}${KEDO_TEMPLATE_DOCUMENT_TYPES}`}
                render={(props) => {
                    return (
                        <KedoTemplateDocumentTypes
                            {...props}
                            baseUrl={baseUrl}
                        />
                    );
                }}
            />
        </Switch>
    );
};