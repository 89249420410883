import React, {useState} from "react";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as EditIcon} from "../../../../images/mode.svg";
import EdmStructuralUnitEditModal from "./components/edit-modal";
import DocumentManagementStructuralUtilsFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useStructuralUtilsFetchList} from "./hooks/useFetchList";
import {useStructuralUtilsActions} from "./hooks/useStructuralUtilsActions";
import {useStructuralUtilsEditModal} from "./hooks/useStructuralUtilsEditModal";
import {useStructuralUtilsFilter} from "./hooks/useStructuralUtilsFilter";

import {isAcceptedDictionaryManageEdm} from "../../../../utils/user-rights/edmStaff";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../constants/color";

import {getClientCardSelector} from "../../../../ducks/client";
import {
    edmSubdivisionsListSelector,
    edmSubdivisionsTotalCountSelector,
    edmSubdivisionsTotalPagesSelector,
} from "../../../../ducks/edmSubDivision";
import {edoAccessListSelector} from "../../../../ducks/edocuments";

import "./style.sass";

const initFilter = {
    valueFilter: "",
};

export default function DocumentManagementDictionariesStructuralUnits(props) {
    const {match} = props;

    const {
        params: {
            clientId,
        },
    } = match;

    const [isSearch, setIsSearch] = useState(false);

    const totalCount = useSelector(edmSubdivisionsTotalCountSelector);
    const totalPages = useSelector(edmSubdivisionsTotalPagesSelector);
    const list = useSelector(edmSubdivisionsListSelector);
    const edoAccessList = useSelector(edoAccessListSelector);
    const {archived: isClientArchived} = useSelector(getClientCardSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({initFilter});

    const {
        filterDto,
        setFilterDto,
    } = useStructuralUtilsFilter();

    const {
        loadingList,
        fetchList,
    } = useStructuralUtilsFetchList({pageNum, pageSize, filterDto, clientId});

    const {
        submit,
        cancelEditModal,
        openEditModal,
        onChangeForm,
        isShowModal,
        editForm,
        formError,
    } = useStructuralUtilsEditModal(clientId, fetchList);

    const {
        isShowConfirm,
        toggleConfirm,
        deleteItem,
    } = useStructuralUtilsActions(fetchList);

    function getMediaControls(util) {
        if (!isShowManageControls()) {
            return null;
        }
        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        icon: <EditIcon />,
                        onClick: () => {
                            openEditModal(util);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        icon: <DeleteIcon />,
                        onClick: () => {
                            toggleConfirm(util);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    }

    const isShowManageControls = () => {
        return isAcceptedDictionaryManageEdm(edoAccessList) && !isClientArchived;
    };

    function getAddButton() {
        if (!isShowManageControls()) {
            return null;
        }

        return (
            <NmButton
                size="xl"
                onClick={() => {
                    openEditModal();
                }}
                className="document-management-dictionaries-structural-units__button"
            >
                Добавить подразделение
            </NmButton>
        );
    }

    function getRows() {
        return list.map(util => {
            return {
                ...util,
                key: util.subdivisionId,
                contentRow: (
                    <NmListCard
                        alignItems="stretch"
                        noDivider
                        onlyLabels
                        classNameMainContent="col-16 col-xxl-14 d-flex align-items-center"
                        labels={[
                            {
                                render: () => (
                                    <Text
                                        style={{paddingRight: 24}}
                                        color={COLOR.SECONDARY_100}
                                        level="5"
                                    >
                                        {util.value}
                                    </Text>
                                ),
                            },
                        ]}
                        mediaControls={getMediaControls(util)}
                        actionsClassName="col-2 justify-content-end"
                    />
                ),
            };
        });
    }

    const submitFilter = (filter, isSearch) => {
        setFilterDto(filter);
        setIsSearch(isSearch);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    function renderDeleteConfirmWindow() {
        if (!isShowConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                content="Вы действительно хотите удалить данное подразделение из справочника?"
                onCancel={() => {
                    toggleConfirm();
                }}
                onConfirm={deleteItem}
                confirmButton="Да"
                cancelButton="Нет"
            />
        );
    }

    const isShowControls = totalCount || isSearch;

    return (
        <>
            {renderDeleteConfirmWindow()}
            {isShowModal && <EdmStructuralUnitEditModal
                onCancel={cancelEditModal}
                submit={submit}
                data={editForm}
                onChange={onChangeForm}
                formError={formError}
            />}
            <NmPage
                header={
                    <NmTitle
                        size="lg"
                        children="Структурные подразделения"
                    />
                }
                headerClassName="document-management-dictionaries-structural-units__header"
                controlsClassName="document-management-dictionaries-structural-units__controls"
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                typeFilter="vertical"
                isLoaded={loadingList}
                filtersBase={
                    <DocumentManagementStructuralUtilsFilter
                        setFilter={setFilter}
                        onChangeFilter={onChangeFilter}
                        initFilter={initFilter}
                        filter={filter}
                        submitFilter={submitFilter}
                    />
                }
                controls={
                    isShowControls &&
                    getAddButton()
                }
                totalCount={totalCount}
            >
                {
                    !(totalCount || loadingList) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction={isShowManageControls() ? "Добавить подразделение" : null}
                            onClickAction={() => {
                                openEditModal();
                            }}
                            isSearch={isSearch}
                        /> :
                        <CheckboxList
                            rows={getRows()}
                        />
                }
            </NmPage>
        </>
    );

}