import {ORDER_WORK_REPORT_TYPE} from "../../../../../constants/finance";
import {ORDER_KIND} from "../../../../../constants/order";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../templates/constants";

export const ORDER_LIST_MASS_TEMPLATE_PUBLICATION_FETCH_QUERIES_SCHEME = {
    NDFL: [
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.CIVIL,
            },
            NAME: "frameContractCivilId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.CIVIL,
                orderKindFilter: ORDER_KIND.AMOUNT_BASED_ORDER,
            },
            NAME: "orderApplicationCivilAmountBasedId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.CIVIL,
                orderKindFilter: ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
            },
            NAME: "orderApplicationCivilVolumeOfWorkBasedId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.CIVIL,
                orderKindFilter: ORDER_KIND.AMOUNT_BASED_ORDER,
            },
            NAME: "actOfAcceptanceOfWorkCivilAmountBasedId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.CIVIL,
                orderKindFilter: ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
            },
            NAME: "actOfAcceptanceOfWorkCivilVolumeOfWorkBasedId",
        },
    ],
    NPD: [
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.SMZ,
            },
            NAME: "frameContractSmzId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.SMZ,
                orderKindFilter: ORDER_KIND.AMOUNT_BASED_ORDER,
            },
            NAME: "orderApplicationSmzAmountBasedId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.SMZ,
                orderKindFilter: ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
            },
            NAME: "orderApplicationSmzVolumeOfWorkBasedId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.SMZ,
                orderKindFilter: ORDER_KIND.AMOUNT_BASED_ORDER,
            },
            NAME: "actOfAcceptanceOfWorkSmzAmountBasedId",
        },
        {
            PARAMS: {
                documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
                orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.SMZ,
                orderKindFilter: ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER,
            },
            NAME: "actOfAcceptanceOfWorkSmzVolumeOfWorkBasedId",
        },
    ],
};