import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmPagination from "../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../components/CheckboxList";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";

import {formatLocalDate} from "../../../utils/dateFormat";
import {floatToPercent, removeFinalZeros} from "../../../utils/mathHelper";
import {getFormattedFullName, getFullName} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {commissionHistoryModesSelector} from "../../../ducks/deposit";

import PropTypes from "prop-types";

import "./style.sass";

class DepositCommissionHistory extends Component {
    static propTypes = {
        close: PropTypes.func,
        data: PropTypes.object,
    };

    static defaultProps = {
        close: () => {
        },
        data: {},
    };

    constructor() {
        super();

        this.state = {
            pageData: {
                pageNum: 1,
                pageSize: 10,
            },
        };

        this.pageSizes = [5, 10, 15];
    }

    componentDidMount() {
        this.fetchList();
    }

    componentWillUnmount() {
        const {onUpdateStore} = this.props;

        onUpdateStore({
            commissionHistoryModels: [],
            commissionHistoryTotalCount: 0,
            commissionHistoryPageData: {},
        });
    }

    fetchList = () => {
        const {data: {clientId, commissionType}, onFetchList} = this.props;
        const {pageData} = this.state;

        onFetchList({
            clientId,
            commissionType,
            ...pageData,
        });
    };

    changePageSize = pageSize => {
        this.setState({
            pageData : {
                pageSize,
                pageNum: 1,
            },
        },
        this.fetchList,
        );
    };

    changePageNum = (e, {activePage: pageNum}) => {
        const {pageData: {pageNum: pageNumOld}} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum,
            },
        }),
        this.fetchList,
        );
    };

    getRows() {
        const {list, historyCommissionContractor, data: {commissionType}} = this.props;

        const _list = isNullOrWhitespace(commissionType) ? list : historyCommissionContractor;

        return _list.map((item) => {
            const {
                fromDatetime,
                toDatetime,
                currentCommissionRate,
                changerLastName,
                changerFirstName,
                changerPatronymic,
            } = item;
            const rate = floatToPercent(currentCommissionRate);
            const startDate = formatLocalDate(fromDatetime, "dd.MM.yyyy HH:mm");
            const endDate = formatLocalDate(toDatetime, "dd.MM.yyyy HH:mm");
            const fullName = getFullName(changerLastName, changerFirstName, changerPatronymic);

            return {
                ...item,
                key: item.historyId,
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={[
                            {
                                label: "Период действия",
                                text: `с ${startDate} по - ${endDate}`,
                            },
                            {
                                label: "Ставка",
                                text: `${removeFinalZeros(rate)}%`,
                            },
                            {
                                label: "Инициатор",
                                text: getFormattedFullName(fullName),
                            },
                        ]}
                    />
                ),
            };
        });
    }

    renderPagination() {
        const {totalPages, totalCount} = this.props;
        const {pageData: {pageNum, pageSize}} = this.state;

        return (
            <NmPagination
                className="mt-2 mt-md-5 mt-xxl-7"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={this.changePageSize}
                onChangePagination={this.changePageNum}
            />
        );
    }

    renderContent() {
        const {list, historyCommissionContractor, data: {commissionType}, t} = this.props;

        if (isNullOrWhitespace(commissionType) && !list.length || !isNullOrWhitespace(commissionType) && !historyCommissionContractor.length) {
            return (
                <NmEmptyPageV2
                    title={t("current-commission-rate.empty-history-commission")}
                />
            );
        }

        return (
            <div className="dc-history__container">
                <CheckboxList
                    className="dc-history__list"
                    rows={this.getRows()}
                />
                {this.renderPagination()}
            </div>
        );
    }

    render() {
        const {close, data: {title}} = this.props;

        return (
            <NmModal
                onClose={close}
                size="md"
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
            >
                {this.renderContent()}
            </NmModal>
        );
    }
}

export default withPageData(connect(
    state => ({
        historyCommissionContractor: commissionHistoryModesSelector(state),
    }), {},
)(withTranslation()(DepositCommissionHistory)));