import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

import {LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES} from "../../../../../constants/links";

export const bffRecruitmentDirectorySelector = state => state.bff.recruitment.accessControl.observers;

export const bffRecruitmentAccessControlObserversProgressSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({progress}) => progress,
);
export const bffRecruitmentAccessControlObserversProgressActionSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({progressAction}) => progressAction,
);

const observerListSelector = ({list}) => {
    return list.map(item => {

        return {
            ...item,
            vacanciesList: getModalVacanciesList(item.vacancies),
        };
    });
};

// Для отображения в списке в модальном окне
export const getModalVacanciesList = (list) => {
    return list?.map(item => {
        return {
            vacancyId: item.vacancyId,
            text: `№${item.vacancyNumber} ${item.title}`,
            link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES
                .replace(":vacancyId", item.vacancyId)
                .replace(":clientId", item.clientId),
        };
    });
};

export const bffRecruitmentAccessControlObserverListSelector = createSelector(
    bffRecruitmentDirectorySelector,
    observerListSelector,
);
export const bffRecruitmentAccessControlObserverTotalCountSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({totalCount}) => totalCount,
);
export const bffRecruitmentAccessControlObserverTotalPagesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);

const vacanciesSelector = ({vacancies}) => {
    return vacancies.map(item => {
        const name = `№${item.vacancyNumber} - ${item.title}`;

        return {
            ...item,
            value: item.vacancyId,
            name,
            text: name,
            showCheckBox: true,
            isShowChevronButton: false,
        };
    });
};

export const bffRecruitmentAccessControlObserverVacanciesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    vacanciesSelector,
);
export const bffRecruitmentAccessControlObserverVacanciesTotalCountSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({vacanciesTotalCount}) => vacanciesTotalCount,
);
export const bffRecruitmentAccessControlObserverVacanciesTotalPagesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({vacanciesTotalCount, vacanciesPageData: {pageSize = 0}}) => {
        return getTotalPages(vacanciesTotalCount, pageSize);
    },
);
export const bffRecruitmentAccessControlObserverVacanciesProgressSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({observersProgress}) => observersProgress,
);