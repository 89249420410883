import React, {useEffect, useState} from "react";
import {useMedia} from "react-media";
import {useDispatch, useSelector} from "react-redux";

import {addCandidateRecruitmentVacancy} from "../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector,
} from "../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    getRecruitmentVacancyCandidatesForResponse,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentVacancyCandidatesForResponseListSelector,
    bffRecruitmentVacancyCandidatesForResponseProgressSelector,
    bffRecruitmentVacancyCandidatesForResponseTotalCountSelector,
    bffRecruitmentVacancyCandidatesForResponseTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {
    setCandidateRecruitmentVacancyResponse,
} from "../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import FilterButtonsV2 from "../../../ActualComponents/FilterButtonsV2";
import HelpTooltip from "../../../ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../ActualComponents/NmEmptyPageV2";
import NmForm from "../../../ActualComponents/NmForm";
import NmInputV2 from "../../../ActualComponents/NmInputV2";
import NmModalCardList from "../../../ActualComponents/NmModal/CardList";
import NmPagination from "../../../ActualComponents/NmPagination";
import Text from "../../../ActualComponents/Text";
import ButtonWithContextMenu from "../../../BottonWithContextMenu";
import NmButton from "../../../NmButton";
import NmHint from "../../../NmHint";
import NmLoader from "../../../NmLoader";
import {FUNNELS_BUTTON_CLASSNAMES} from "../../CandidatesCardHeader/components/ContextMenu";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import {phoneFormat, removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {COLOR} from "../../../../constants/color";
import {LINKING_RESPONSE_TO_CANDIDATE_RECRUITMENT_FORM_ID} from "../constants";

export const LinkingResponseToCandidateRecruitmentFromList = (props) => {
    const {
        clientId,
        vacancyId,
        responseId,
        isAddCandidate,
        onClose,
        onSuccess,
        defaultSelectedId = "",
    } = props;

    const dispatch = useDispatch();

    const isMobile = useMedia({query: {maxWidth: 767}});

    const list = useSelector(bffRecruitmentVacancyCandidatesForResponseListSelector);
    const totalCount = useSelector(bffRecruitmentVacancyCandidatesForResponseTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentVacancyCandidatesForResponseTotalPagesSelector);
    const progress = useSelector(bffRecruitmentVacancyCandidatesForResponseProgressSelector);
    const funnelStatusOptions = useSelector(bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector);

    const [selectedId, setSelectedId] = useState(defaultSelectedId);
    const [status, setStatus] = useState({});

    const {
        pageNum,
        pageSize,
        onPaginationChange,
    } = usePagination("bg-modal", 5);

    function mapFilterDto(filter) {
        return {
            name: handleFormString(filter.name),
            phone: handleFormString(removePhoneMask(filter.phone)),
            email: handleFormString(filter.email),
        };
    }

    const {
        onClear,
        onChangeFilter,
        filter,
        onSearch,
        filterData,
        isSearch,
    } = useFilter({
        initFilter: {
            name: "",
            phone: "",
            email: "",
        },
        mapFilterDto,
    });

    useEffect(() => {
        fetchList();
    }, [pageNum, filterData]);

    const fetchList = () => {
        dispatch(getRecruitmentVacancyCandidatesForResponse({
            pageNum,
            pageSize,
            clientId,
            responseId,
            ...filterData,
        }));
    };

    const onSubmit = () => {
        if (!selectedId) {
            toastError("Кандидат не выбран");

            return;
        }

        if (isAddCandidate) {
            dispatch(addCandidateRecruitmentVacancy({
                vacancyId,
                candidateId: selectedId,
                statusId: status.value,
                onSuccess: () => {
                    onClose();
                    onSuccess();
                    toastSuccess("Кандидат успешно добавлен в вакансию");
                },
            }));

            return;
        }

        dispatch(setCandidateRecruitmentVacancyResponse({
            responseId,
            clientId,
            candidateId: selectedId,
            statusId: status.value,
            onSuccess: () => {
                onClose();
                onSuccess();
                toastSuccess("Отклик успешно привязан к кандидату");
            },
        }));
    };

    const getFilters = () => {
        const filters = (
            <div className="row gx-4 mb-4">
                <div className="col-16 col-md-8">
                    <NmInputV2
                        size="xl"
                        placeholder="ФИО"
                        onChange={onChangeFilter}
                        name="name"
                        value={filter.name}
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-0">
                    <NmInputV2
                        size="xl"
                        mask="+7 (999) 999 99 99"
                        name="phone"
                        value={filter.phone}
                        placeholder="Телефон"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-4">
                    <NmInputV2
                        size="xl"
                        placeholder="E-mail"
                        name="email"
                        onChange={onChangeFilter}
                        value={filter.email}
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-4">
                    <FilterButtonsV2
                        size="xl"
                        onSearch={() => {
                            onSearch(filter);
                        }}
                        onClear={onClear}
                    />
                </div>
            </div>
        );

        if (isMobile) {
            return (
                <NmHint
                    defaultOpen={true}
                    buttonClassName="mt-2"
                >
                    {filters}
                </NmHint>
            );
        }

        return filters;
    };

    const getOptions = (info) => {
        return funnelStatusOptions.map(item => {
            return {
                ...item,
                onClick: () => {
                    setSelectedId(info.candidateId);
                    setStatus(item);
                },
            };
        });
    };

    const getButton = (item) => {
        if (responseId) {
            return (
                <NmButton
                    color="light-green"
                    disabled={item.candidateId === selectedId}
                    onClick={() => {
                        setSelectedId(item.candidateId);
                    }}
                >
                    {item.candidateId === selectedId ? "Выбран" : "Выбрать"}
                </NmButton>
            );
        }

        return (
            <ButtonWithContextMenu
                buttonProps={{
                    size: "xl",
                    type: "button",
                    color: "light-green",
                    children: item.candidateId === selectedId && status ? status.text : "Пригласить",
                    isIconPositionEnd: true,
                    isContentFullWidth: true,
                    className: FUNNELS_BUTTON_CLASSNAMES.MAIN,
                    classNameTextWithIconContainer: FUNNELS_BUTTON_CLASSNAMES.TEXT_WITH_ICON_CONTAINER,
                }}
                children={item.candidateId === selectedId && status ? status.text : "Пригласить"}
                options={getOptions(item)}
            />
        );
    };

    const getList = () => {
        return list.map(item => {
            return {
                ...item,
                title: item.similarCandidate && (
                    <Text
                        level="2"
                        color={COLOR.INERT_100}
                    >
                        Похожий кандидат
                    </Text>
                ),
                singleValues: [
                    {
                        label: "ФИО",
                        value: item.name || "ФИО не указано",
                    },
                    {label: "Телефон", value: phoneFormat(item.phone) || "-"},
                    {
                        label: "E-mail",
                        value: item.email || "-",
                    },
                ],
                actions: item.hasContractor ?
                    (
                        <div className="d-flex relative">
                            <Text
                                level="2"
                                color={COLOR.SECONDARY_100}
                            >
                                Выбор недоступен
                            </Text>
                            <HelpTooltip
                                hover
                                className="ms-1"
                                position="bottom-left"
                                width={18}
                                height={18}
                                text="К данному кандидату уже привязан исполнитель Наймикс"
                            />
                        </div>
                    ) :
                    getButton(item),
            };
        });
    };

    return (
        <>
            {getFilters()}
            {
                progress &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            {
                list.length ?
                    <NmForm
                        id={LINKING_RESPONSE_TO_CANDIDATE_RECRUITMENT_FORM_ID}
                        onSubmit={onSubmit}
                        addMargin
                    >
                        <NmModalCardList
                            className="edm-staff-new-selecting-form__list"
                            list={getList()}
                            pagination={
                                totalPages > 0 &&
                                <NmPagination
                                    pageNum={pageNum}
                                    pageSize={5}
                                    totalCount={totalCount}
                                    totalPages={totalPages}
                                    onChangePagination={onPaginationChange}
                                    isHasPageSize={false}
                                />
                            }
                            progress={progress}
                        />
                    </NmForm> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    />
            }
        </>
    );
};
