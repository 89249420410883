import {
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_CLEAR_STORE,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST,
} from "./actions";

export const addRecruitmentVacancyPersonalAccess = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyPersonalAccessList = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyPersonalAccessAvailableUsers = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST,
        payload,
    };
};

export const clearRecruitmentVacancyPersonalAccessStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_CLEAR_STORE,
        payload,
    };
};