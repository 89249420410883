import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/adm/operator-report";
//*  TYPES  *//

const GET_OPERATOR_REPORTS_PAGE_REQUEST = "GET_OPERATOR_REPORTS_PAGE_REQUEST";
const GET_OPERATOR_REPORTS_PAGE_SUCCESS = "GET_OPERATOR_REPORTS_PAGE_SUCCESS";
const GET_OPERATOR_REPORTS_PAGE_ERROR = "GET_OPERATOR_REPORTS_PAGE_ERROR";

const ADD_OPERATOR_REPORTS_REQUEST = "ADD_OPERATOR_REPORTS_REQUEST";
const ADD_OPERATOR_REPORTS_SUCCESS = "ADD_OPERATOR_REPORTS_SUCCESS";
const ADD_OPERATOR_REPORTS_ERROR = "ADD_OPERATOR_REPORTS_ERROR";

const DELETE_OPERATOR_REPORT_REQUEST = "DELETE_OPERATOR_REPORT_REQUEST";
const DELETE_OPERATOR_REPORT_SUCCESS = "DELETE_OPERATOR_REPORT_SUCCESS";
const DELETE_OPERATOR_REPORT_ERROR = "DELETE_OPERATOR_REPORT_ERROR";

const DELETE_OPERATOR_REPORTS_REQUEST = "DELETE_OPERATOR_REPORTS_REQUEST";
const DELETE_OPERATOR_REPORTS_SUCCESS = "DELETE_OPERATOR_REPORTS_SUCCESS";
const DELETE_OPERATOR_REPORTS_ERROR = "DELETE_OPERATOR_REPORTS_ERROR";

const UPDATE_OPERATOR_REPORTS_REQUEST = "UPDATE_OPERATOR_REPORTS_REQUEST";
const UPDATE_OPERATOR_REPORTS_SUCCESS = "UPDATE_OPERATOR_REPORTS_SUCCESS";
const UPDATE_OPERATOR_REPORTS_ERROR = "UPDATE_OPERATOR_REPORTS_ERROR";

const CHANGE_OPERATOR_REPORT_STATUS_REQUEST = "CHANGE_OPERATOR_REPORT_STATUS_REQUEST";
const CHANGE_OPERATOR_REPORT_STATUS_SUCCESS = "CHANGE_OPERATOR_REPORT_STATUS_SUCCESS";
const CHANGE_OPERATOR_REPORT_STATUS_ERROR = "CHANGE_OPERATOR_REPORT_STATUS_ERROR";

const GET_OPERATOR_REPORTS_FILE_REQUEST = "GET_OPERATOR_REPORTS_FILE_REQUEST";
const GET_OPERATOR_REPORTS_FILE_SUCCESS = "GET_OPERATOR_REPORTS_FILE_SUCCESS";
const GET_OPERATOR_REPORTS_FILE_ERROR = "GET_OPERATOR_REPORTS_FILE_ERROR";

const GET_OPERATOR_REPORTS_FILE_DOCX_REQUEST = "GET_OPERATOR_REPORTS_FILE_DOCX_REQUEST";
const GET_OPERATOR_REPORTS_FILE_DOCX_SUCCESS = "GET_OPERATOR_REPORTS_FILE_DOCX_SUCCESS";
const GET_OPERATOR_REPORTS_FILE_DOCX_ERROR = "GET_OPERATOR_REPORTS_FILE_DOCX_ERROR";

const CHANGE_OPERATOR_REPORTS_STATUS_REQUEST = "CHANGE_OPERATOR_REPORTS_STATUS_REQUEST";
const CHANGE_OPERATOR_REPORTS_STATUS_SUCCESS = "CHANGE_OPERATOR_REPORTS_STATUS_SUCCESS";
const CHANGE_OPERATOR_REPORTS_STATUS_ERROR = "CHANGE_OPERATOR_REPORTS_STATUS_ERROR";

const UPDATE_FIELD_OPERATOR_REPORT = "UPDATE_FIELD_OPERATOR_REPORT";

//*  INITIAL STATE  *//

const initial = {
    operatorReportsPage: [],
    progressPage: false,
    isListSuccessLoaded: false,
    isReportSuccessAdded: false,
    totalCount: 0,
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case UPDATE_FIELD_OPERATOR_REPORT:
        return {
            ...state,
            ...payload,
        };
    case GET_OPERATOR_REPORTS_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case DELETE_OPERATOR_REPORTS_REQUEST:
    case DELETE_OPERATOR_REPORT_REQUEST:
    case CHANGE_OPERATOR_REPORTS_STATUS_REQUEST:
    case ADD_OPERATOR_REPORTS_REQUEST:
        return {
            ...state,
            progressPage: true,
        };
    case CHANGE_OPERATOR_REPORTS_STATUS_SUCCESS:
    case CHANGE_OPERATOR_REPORT_STATUS_SUCCESS:
    case DELETE_OPERATOR_REPORT_SUCCESS:
    case DELETE_OPERATOR_REPORTS_SUCCESS:
        return {
            ...state,
            progressPage: false,
        };
    case ADD_OPERATOR_REPORTS_SUCCESS:
        return {
            ...state,
            isReportSuccessAdded: true,
            progressPage: false,
        };
    case GET_OPERATOR_REPORTS_PAGE_SUCCESS:
        const {
            reports,
            totalCount,
        } = payload;
        return {
            ...state,
            progressPage: false,
            isListSuccessLoaded: true,
            totalCount,
            operatorReportsPage: reports,
        };
    case ADD_OPERATOR_REPORTS_ERROR:
    case DELETE_OPERATOR_REPORTS_ERROR:
    case CHANGE_OPERATOR_REPORT_STATUS_ERROR:
    case DELETE_OPERATOR_REPORT_ERROR:
    case CHANGE_OPERATOR_REPORTS_STATUS_ERROR:
    case GET_OPERATOR_REPORTS_PAGE_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getOperatorReportsPage(payload) {
    return {
        type: GET_OPERATOR_REPORTS_PAGE_REQUEST,
        payload,
    };
}

export function addOperatorReport(payload) {
    return {
        type: ADD_OPERATOR_REPORTS_REQUEST,
        payload,
    };
}

export function changeOperatorReportStatus(payload) {
    return {
        type: CHANGE_OPERATOR_REPORT_STATUS_REQUEST,
        payload,
    };
}

export function changeOperatorReportsStatus(payload) {
    return {
        type: CHANGE_OPERATOR_REPORTS_STATUS_REQUEST,
        payload,
    };
}

export function deleteOperatorReport(payload) {
    return {
        type: DELETE_OPERATOR_REPORT_REQUEST,
        payload,
    };
}

export function deleteOperatorReports(payload) {
    return {
        type: DELETE_OPERATOR_REPORTS_REQUEST,
        payload,
    };
}

export function updateOperatorReport(payload) {
    return {
        type: UPDATE_OPERATOR_REPORTS_REQUEST,
        payload,
    };
}

export function updateFieldOperatorReport(payload) {
    return {
        type: UPDATE_FIELD_OPERATOR_REPORT,
        payload,
    };
}

export function getOperatorReportFile(payload) {
    return {
        type: GET_OPERATOR_REPORTS_FILE_REQUEST,
        payload,
    };
}

export function getOperatorReportsFileDocx(payload) {
    return {
        type: GET_OPERATOR_REPORTS_FILE_DOCX_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const operatorReports = state => state.operatorReports;
export const getOperatorReportsPageSelector = createSelector(operatorReports, ({operatorReportsPage}) => operatorReportsPage);
export const getOperatorReportsListSuccessLoadedSelector = createSelector(operatorReports, ({isListSuccessLoaded}) => isListSuccessLoaded);
export const getOperatorReportSuccessAddedSelector = createSelector(operatorReports, ({isReportSuccessAdded}) => isReportSuccessAdded);
export const getOperatorReportsLoadingSelector = createSelector(operatorReports, ({progressPage}) => progressPage);
export const getOperatorReportsTotalCountSelector = createSelector(operatorReports, ({totalCount}) => totalCount);
export const getOperatorReportsTotalPagesSelector = createSelector(operatorReports, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));

//*  SAGA  *//

//POST /bff/adm/operator-report/page
// Список отчетов операторов
export const getOperatorReportsPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_OPERATOR_REPORTS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_OPERATOR_REPORTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: GET_OPERATOR_REPORTS_PAGE_ERROR, payload: error});
    }
};

//POST /bff/adm/operator-report/add
// Сформировать отчет оператора
export const addOperatorReportSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_OPERATOR_REPORTS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Формирование отчетов оператора успешно завершено");

        yield put({
            type: ADD_OPERATOR_REPORTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_OPERATOR_REPORTS_ERROR, payload: error.message});
    }
};

//POST /bff/adm/operator-report/status/change
// Обновление статуса отчета
export const changeOperatorReportStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/status/change`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHANGE_OPERATOR_REPORT_STATUS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Статус отчёта изменён");

        yield put({
            type: CHANGE_OPERATOR_REPORT_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: CHANGE_OPERATOR_REPORT_STATUS_ERROR, payload: error.message});
    }
};

//POST /bff/adm/operator-report/status/change-many
// Обновление статусов отчетов
export const changeOperatorReportsStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/status/change-many`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CHANGE_OPERATOR_REPORTS_STATUS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Статусы отчётов изменены");

        yield put({
            type: CHANGE_OPERATOR_REPORTS_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: CHANGE_OPERATOR_REPORTS_STATUS_ERROR, payload: error.message});
    }
};

//POST /bff/adm/operator-report/delete
// Удаление отчета оператора
export const deleteOperatorReportSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/delete`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_OPERATOR_REPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Отчёт удалён");

        yield put({
            type: DELETE_OPERATOR_REPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: DELETE_OPERATOR_REPORT_ERROR, payload: error.message});
    }
};

//POST /bff/adm/operator-report/period/update
// Обновление периода отчета
export const updateOperatorReportSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/period/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_OPERATOR_REPORTS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Период отчёта изменён");

        yield put({
            type: UPDATE_OPERATOR_REPORTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: UPDATE_OPERATOR_REPORTS_ERROR, payload: error.message});
    }
};

//POST /bff/adm/operator-report/delete-many
// Удаление отчетов оператора
export const deleteOperatorReportsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            reports,
        } = payload;

        const result = yield request.bff.post(`${controller}/delete-many`, reports);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_OPERATOR_REPORTS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Отчёты успешно удалены");

        yield put({
            type: DELETE_OPERATOR_REPORTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: DELETE_OPERATOR_REPORTS_ERROR, payload: error.message});
    }
};

//GET /bff/adm/operator-report/{clientId}/{reportId}/file-pdf
export const getOperatorReportsFileSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            reportId,
        } = payload;

        const result = yield request.bff.get(`${controller}/${clientId}/${reportId}/file-pdf`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_OPERATOR_REPORTS_FILE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: GET_OPERATOR_REPORTS_FILE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);
        yield put({type: GET_OPERATOR_REPORTS_FILE_ERROR, payload: error.message});
    }
};

//GET /bff/adm/operator-report/{clientId}/{reportId}/file-docx
// Получение отчета оператора в формате docx
export const getOperatorReportsFileDocxSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            reportId,
        } = payload;

        const result = yield request.bff.get(`${controller}/${clientId}/${reportId}/file-docx`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_OPERATOR_REPORTS_FILE_DOCX_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: GET_OPERATOR_REPORTS_FILE_DOCX_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);
        yield put({type: GET_OPERATOR_REPORTS_FILE_DOCX_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_OPERATOR_REPORTS_PAGE_REQUEST, getOperatorReportsPageSaga),
        takeEvery(ADD_OPERATOR_REPORTS_REQUEST, addOperatorReportSaga),
        takeEvery(CHANGE_OPERATOR_REPORT_STATUS_REQUEST, changeOperatorReportStatusSaga),
        takeEvery(DELETE_OPERATOR_REPORT_REQUEST, deleteOperatorReportSaga),
        takeEvery(UPDATE_OPERATOR_REPORTS_REQUEST, updateOperatorReportSaga),
        takeEvery(CHANGE_OPERATOR_REPORTS_STATUS_REQUEST, changeOperatorReportsStatusSaga),
        takeEvery(DELETE_OPERATOR_REPORTS_REQUEST, deleteOperatorReportsSaga),
        takeEvery(GET_OPERATOR_REPORTS_FILE_REQUEST, getOperatorReportsFileSaga),
        takeEvery(GET_OPERATOR_REPORTS_FILE_DOCX_REQUEST, getOperatorReportsFileDocxSaga),
    ]);
}
