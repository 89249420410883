import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getPageAdmCrowdObjectTasks,
    getPageAdmCrowdProjectTasks,
    getPageAdmCrowdTasks,
} from "../../../../ducks/bff/adm/crowd-tasks/services";
import {
    getCrowdTaskGroup,
    getCrowdTaskGroupInGroupPage,
    getCrowdTaskGroupOutGroupPage,
    updateStoreCrowdTaskGroup,
} from "../../../../ducks/bff/crowd/taskGroup/actionCreators";
import {
    bffCrowdTaskGroupListSelector,
    bffCrowdTaskGroupProgressActionSelector,
    bffCrowdTaskGroupProgressSelector,
    bffCrowdTaskGroupTotalCountSelector,
    bffCrowdTaskGroupTotalPagesSelector,
} from "../../../../ducks/bff/crowd/taskGroup/selectors";
import {updateStoreCrowdTasks} from "../../../../ducks/bff/crowd/tasks/actionCreators";
import {
    bffCrowdTasksListSelector,
    bffCrowdTasksProgressActionSelector,
    bffCrowdTasksProgressSelector,
    bffCrowdTasksTotalCountSelector,
    bffCrowdTasksTotalPagesSelector,
    bffCrowdTasksTotalPaymentAmountSelector,
} from "../../../../ducks/bff/crowd/tasks/selectors";

const useCrowdTaskRegistryFetch = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filter,
        archivedFilter,
        isGroupCardPage,
        isGroupCardSearchPage,
        taskGroupId,
        projectId,
        objectId,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(bffCrowdTasksListSelector);
    const totalTasksPaymentAmount = useSelector(bffCrowdTasksTotalPaymentAmountSelector);
    const totalCount = useSelector(bffCrowdTasksTotalCountSelector);
    const totalPages = useSelector(bffCrowdTasksTotalPagesSelector);
    const progress = useSelector(bffCrowdTasksProgressSelector);
    const progressAction = useSelector(bffCrowdTasksProgressActionSelector);
    const groupList = useSelector(bffCrowdTaskGroupListSelector);
    const groupTotalCount = useSelector(bffCrowdTaskGroupTotalCountSelector);
    const groupTotalPages = useSelector(bffCrowdTaskGroupTotalPagesSelector);
    const groupProgress = useSelector(bffCrowdTaskGroupProgressSelector);
    const groupProgressAction = useSelector(bffCrowdTaskGroupProgressActionSelector);

    useEffect(() => {
        if (isGroupCardPage) {
            dispatch(getCrowdTaskGroup({
                clientId,
                taskGroupId,
            }));
        }

        return () => {
            dispatch(updateStoreCrowdTasks({
                list: [],
                totalCount: 0,
            }));
            dispatch(updateStoreCrowdTaskGroup({
                card: {},
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filter,
        archivedFilter,
        isGroupCardPage,
        isGroupCardSearchPage,
    ]);


    const fetchList = () => {
        const generalReqData = {
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            ...filter,
        };

        if (isGroupCardSearchPage) {
            dispatch(getCrowdTaskGroupOutGroupPage({
                taskGroupIdFilter: taskGroupId,
                archivedFilter: false,
                ...generalReqData,
            }));

            return;
        }

        if (isGroupCardPage) {
            dispatch(getCrowdTaskGroupInGroupPage({
                taskGroupIdFilter: taskGroupId,
                archivedFilter: false,
                ...generalReqData,
            }));

            return;
        }

        if (objectId) {
            dispatch(getPageAdmCrowdObjectTasks({
                clientIdFilter: clientId,
                objectIdFilter: objectId,
                archivedFilter,
                ...generalReqData,
            }));

            return;
        }

        if (projectId) {
            dispatch(getPageAdmCrowdProjectTasks({
                clientIdFilter: clientId,
                projectIdFilter: projectId,
                archivedFilter,
                ...generalReqData,
            }));

            return;
        }

        dispatch(getPageAdmCrowdTasks({
            archivedFilter,
            ...generalReqData,
        }));
    };

    const getPageData = () => {
        if (isGroupCardPage) {
            return {
                list: groupList,
                totalCount: groupTotalCount,
                totalPages: groupTotalPages,
                progress: groupProgress,
                progressAction: groupProgressAction,
            };
        }

        return {
            list,
            totalCount,
            totalPages,
            progress,
            progressAction,
        };
    };

    return {
        fetchList,
        totalTasksPaymentAmount,
        ...getPageData(),
    };
};

export default useCrowdTaskRegistryFetch;