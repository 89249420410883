import {UserType} from "../edit-modal";

import {clearSpace, removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";
export type UserRequestDataType  = {
    clientId: string,
    lastName: string,
    firstName: string,
    patronymic?: string,
    password?: string,
    position?: string,
    phone: string,
    login: string,
    role: string,
    email: string,
    fullName?: string,
    inn?: string,
    locked?: boolean,
    deleted?: boolean,
    clientUserId: string,
    active?: boolean,
    snils?: string,
    partnerPromotionClientIdList?: string[],
    partnerPromotionContractorIdList?: string[]
}
export default function mapUserFormToRequestData(userForm: UserType) {
    return {
        ...userForm,
        position: userForm.position ? userForm.position : null,
        email: userForm.email.trim(),
        firstName: userForm.firstName.trim(),
        lastName: userForm.lastName.trim(),
        password: userForm.password?.trim(),
        patronymic: userForm.patronymic?.trim(),
        phone:  removePhoneMask(userForm.phone),
        login: userForm.login.trim(),
        role: userForm.role,
        inn: clearSpace(handleFormString(userForm.inn)),
    };
}