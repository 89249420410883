import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {downloadBlob} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {MESSAGES_EXPORT_TOAST} from "../constants/messages";

const scenarioController = "/adm/onboarding/scenario/contractors";
const objectController = "/adm/onboarding/object/contractors";

const GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST = "GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST";
const GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS = "GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS";
const GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR = "GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR";

const EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST = "EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST";
const EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS = "EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS";
const EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR = "EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR";

const REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST = "REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST";
const REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS = "REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS";
const REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_ERROR = "REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_ERROR";

const GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST = "GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST";
const GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS = "GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS";
const GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR = "GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR";

const EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST = "EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST";
const EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS = "EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS";
const EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR = "EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR";

const DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST = "DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST";
const DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS = "DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS";
const DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR = "DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR";

const RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST = "RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST";
const RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS = "RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS";
const RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_ERROR = "RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_ERROR";

const ONBOARDING_CONTRACTORS_UPDATE_STORE = "ONBOARDING_CONTRACTORS_UPDATE_STORE";

const initial = {
    list: [],
    card: {},
    listProgress: false,
    pageData: 0,
    progressAction: false,
    isSearch: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST:
    case GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            isSearch: true,
            listProgress: true,
        };
    case GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS:
    case GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS:
        const {results, totalCount} = payload;

        return {
            ...state,
            list: results,
            totalCount,
            listProgress: false,
        };
    case GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR:
    case GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR:
        return {
            ...state,
            error: payload,
            listProgress: false,
        };
    case EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST:
    case REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST:
    case EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST:
    case DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST:
    case RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS:
    case EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR:
    case REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS:
    case REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_ERROR:
    case EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS:
    case EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR:
    case DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS:
    case DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR:
    case RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS:
    case RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_ERROR:
        return {
            ...state,
            progressAction: false,
        };

    case ONBOARDING_CONTRACTORS_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};

export const getOnboardingScenarioContactorsList = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST,
    payload,
});

export const exportOnboardingScenarioContactorsList = (payload) => ({
    type: EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST,
    payload,
});

export const refreshStatusesOnboardingScenarioContactors = (payload) => ({
    type: REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST,
    payload,
});


export const updateOnboardingContactorsStore = (payload) => ({
    type: ONBOARDING_CONTRACTORS_UPDATE_STORE,
    payload,
});

export const getOnboardingObjectContactorsList = (payload) => ({
    type: GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST,
    payload,
});

export const exportOnboardingObjectContactorsList = (payload) => ({
    type: EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST,
    payload,
});

export const deleteOnboardingObjectContactorsList = (payload) => ({
    type: DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST,
    payload,
});

export const resubmitContractOnboardingContactorsList = (payload) => ({
    type: RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST,
    payload,
});

export const onboardingContractorsSelector = state => state.onboardingContractors;
export const onboardingContractorsListSelector = createSelector(onboardingContractorsSelector, ({list}) => list);
export const onboardingContractorsProgressActionSelector = createSelector(onboardingContractorsSelector, ({progressAction}) => progressAction);
export const onboardingContractorsListTotalPagesSelector = createSelector(onboardingContractorsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const onboardingContractorsListTotalCountSelector = createSelector(onboardingContractorsSelector, ({totalCount}) => totalCount);
export const onboardingContractorsListIsSearchSelector = createSelector(onboardingContractorsSelector, ({isSearch}) => isSearch);
export const onboardingContractorsListProgressSelector = createSelector(onboardingContractorsSelector, ({listProgress}) => listProgress);

export const getOnboardingScenarioContactorsListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${scenarioController}/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR, payload: error});
    }
};

export const exportOnboardingScenarioContactorsListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${scenarioController}/export`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }


        toastSuccess(MESSAGES_EXPORT_TOAST.replace(":name", "исполнителей"));

        yield put({type: EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_ERROR, payload: error});
    }
};

export const refreshStatusesOnboardingScenarioContactorsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${scenarioController}/statuses/refresh`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }


        toastSuccess("Обновление статусов запущено");
        yield put({type: REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_ERROR, payload: error});
    }
};

export const getOnboardingObjectContactorsListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${objectController}/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.filter(({contractorId}) => Boolean(contractorId)).map(({contractorId}) => contractorId);

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR, payload: error});
    }
};

export const exportOnboardingObjectContactorsListSaga = function* ({payload}) {
    try {
        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };

        const result = yield request.getFile(`${window.location.origin}/bff${objectController}/export`, requestHeaders);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                yield put({
                    type: EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR,
                });

                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();

        yield downloadBlob(blob, headers);

        yield put({type: EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR, payload: error});
    }
};

export const deleteOnboardingObjectContactorsListSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;
        const result = yield request.bff.post(`${objectController}/delete`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_ERROR, payload: error});
    }
};

export const resubmitContractOnboardingContactorsListSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;
        const result = yield request.bff.post(`${scenarioController}/frameContract/resubmit`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST, getOnboardingScenarioContactorsListSaga),
        takeEvery(EXPORT_ONBOARDING_SCENARIO_CONTRACTORS_LIST_REQUEST, exportOnboardingScenarioContactorsListSaga),
        takeEvery(REFRESH_STATUSES_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST, refreshStatusesOnboardingScenarioContactorsSaga),
        takeEvery(GET_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST, getOnboardingObjectContactorsListSaga),
        takeEvery(EXPORT_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST, exportOnboardingObjectContactorsListSaga),
        takeEvery(DELETE_ONBOARDING_OBJECT_CONTRACTORS_LIST_REQUEST, deleteOnboardingObjectContactorsListSaga),
        takeEvery(RESUBMIT_CONTRACT_ONBOARDING_SCENARIO_CONTRACTORS_REQUEST, resubmitContractOnboardingContactorsListSaga),
    ]);
}