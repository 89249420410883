import React from "react";

import NmModal from "../ActualComponents/NmModal";
import NmTitle from "../NmTitle";

import useTableItemList from "./hooks/useTableItemList";

import "./style.sass";

type TableItemListProps = {
    list: string[],
    title: string,
    maxShowElements?: number,
    isDisplayIndex?: boolean
}

function TableItemList(props: TableItemListProps): JSX.Element {
    const {list, title, maxShowElements = 1, isDisplayIndex = false} = props;
    const [isVisibleModal, toggleVisibleModal] = useTableItemList();

    function renderElements() {
        return list.slice(0, maxShowElements).join(" • ");
    }

    function renderListForModal() {
        return list.map((item, index) => {
            return (
                <div className="table-item-list-modal__item">
                    {isDisplayIndex && <div className="table-item-list-modal__index">
                        {index + 1}
                    </div>}
                    {item}
                </div>
            );
        });
    }

    if (list.length === 0) {
        return <span>
Информация отсутствует
        </span>;
    }

    return (
        <>
            {isVisibleModal &&
                <NmModal
                    size="md"
                    header={
                        <NmTitle
                            size="lg"
                        >
                            {title}
                        </NmTitle>
                    }
                    onClose={() => toggleVisibleModal()}
                >
                    <div className="table-item-list-modal">
                        {renderListForModal()}
                    </div>
                </NmModal>
            }
            <div className="table-item-list">
                <div>
                    {renderElements()}
                </div>
                {list.length > maxShowElements &&
                    <div className="flex">
                        <div>
                            {" "}
•
                            {" "}
                        </div>
                        <div
                            className="table-item-list__toggle"
                            onClick={(event) => {
                                event.stopPropagation();

                                toggleVisibleModal();
                            }}
                        >
                            Еще 
                            {" "}
                            {list.length - maxShowElements}
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default TableItemList;