import {useState} from "react";
import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN} from "../../../../constants/roles";

import {deleteOnboardingLead} from "../../../../ducks/onboarding";

export const useContractorOnboardingLeadsAction = (params) => {
    const {
        fetchList,
    } = params;

    const role = ls(USER_ROLE);
    const [confirmData, setConfirmData] = useState({});
    const dispatch = useDispatch();

    const onClickDelete = (item) => {
        setConfirmData({
            content: "Вы действительно хотите удалить лид для онбординга?",
            confirm: () => {
                dispatch(deleteOnboardingLead({
                    scenarioId: item.scenarioId,
                    leadId: item.leadId,
                    onSuccess: () => {
                        setConfirmData({});
                        fetchList();
                    },
                }));
            },
        });
    };

    const getMediaControls = (item) => {
        if (![ADMIN].includes(role)) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onClickDelete(item);
                        },
                        color: "grey",
                        children: "Удалить",
                    },
                },
            ],
        };
    };

    return {
        getMediaControls,
        setConfirmData,
        confirmData,
    };
};