import React from "react";

import {loadPageData} from "../../utils/pageData";

export const withPageData = (Component) => {
    return class extends React.Component {
        constructor(props) {
            super(props);

            const {location} = props;

            const loadedPageData = loadPageData(location);

            const {isLoadDataTarget} = loadedPageData;
            const {pageNum, pageSize, filterData = {}} = loadedPageData;

            this.state = {
                pageNum: isLoadDataTarget ? pageNum : null,
                pageSize: isLoadDataTarget ? pageSize : null,
                filterData: isLoadDataTarget ? filterData : null,
            };
        }

        render() {
            const {pageNum, pageSize, filterData} = this.state;

            return (
                <Component
                    {...this.props}
                    filterData={filterData}
                    pageNum={pageNum}
                    pageSize={pageSize}
                />
            );
        }
    };
};