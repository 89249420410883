import {useState} from "react";
import {useDispatch} from "react-redux";

import {
    updateContractorAdditionalSpecialities,
    updateContractorFamilyStatus,
    updateContractorForeignLanguage,
} from "../../../../../../../ducks/contractorProfile";

export const defaultWorkTypeForm = {
    specialityIds: [],
    hasAdditionalSpecialities: false,
};

export default function useUpdateWorkType({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultWorkTypeForm});
    const dispatch = useDispatch();

    const {
        specialityIds,
        hasAdditionalSpecialities,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        if (name === "hasAdditionalSpecialities" && checked) {
            setForm(prevState => ({
                ...prevState,
                hasAdditionalSpecialities: checked,
                specialityIds: [],
            }));
            return;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const submit = () => {
        dispatch(updateContractorAdditionalSpecialities({
            contractorId,
            specialityIds,
            hasAdditionalSpecialities: !hasAdditionalSpecialities,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
    };
}