import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { isEmpty } from "lodash";

import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import dateFormat, {getDateObject} from "../../../../utils/dateFormat";
import {handleNumber} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {DOCUMENT_CORRECTION_FIELD_NAME} from "../../../../constants/documentsCorrection";
import {requiredMessage} from "../../../../constants/validationRules";

import {
    documentsEditManualDictFieldNameSelector,
    documentsEditManualFieldNamesOptionsSelector,
    getDocumentsEditManualFieldNames,
} from "../../../../ducks/documentsEditManual";

import "./style.sass";

const validationRule = {
    fieldName: {
        required: requiredMessage,
    },
    newValue: {
        required: requiredMessage,
    },
};

const PARAM_TYPE = {
    STRING: "STRING",
    DATE: "DATE",
};

interface ISettingsDocumentsCorrectionEdit {
    onClose: () => void,
    documentId: string,
    onSubmit: (data: any) => void,
    isHiddenModal: boolean,
    progress: boolean
}

const SettingsDocumentsCorrectionEdit = (props: ISettingsDocumentsCorrectionEdit) => {
    const {
        onClose,
        documentId,
        isHiddenModal,
        progress,
    } = props;
    const dispatch = useDispatch();
    const options = useSelector(documentsEditManualFieldNamesOptionsSelector);
    const fieldNames = useSelector(documentsEditManualDictFieldNameSelector);
    const [form, setForm] = useState({
        fieldName: "",
        newValue: "",
    });
    const [error, setError] = useState({
        fieldName: null,
        newValue: null,
    });
    const [inputType, setInputType] = useState(PARAM_TYPE.STRING);

    useEffect(() => {
        dispatch(getDocumentsEditManualFieldNames({documentId}));
    }, []);

    const onChange = (event: any, {name, value}: any) => {
        if (name === "fieldName") {
            const {fieldValue, type} = fieldNames.find((item: any) => (item.fieldName === value)) || {};

            setForm({
                ...form,
                [name]: value,
                newValue: type === PARAM_TYPE.DATE ? getDateObject(fieldValue) : fieldValue,
            });
            setInputType(type);

            return;
        }

        setForm({
            ...form,
            [name]: value,
        });
    };

    const isValid = () => {
        const error: any = validate(form, validationRule, "");

        setError(error);

        return isEmpty(error);
    };

    const getNewValue = () => {
        if (inputType === PARAM_TYPE.DATE) {
            return dateFormat(form.newValue, "yyyy-MM-dd");
        }

        if (
            [
                DOCUMENT_CORRECTION_FIELD_NAME.ORDER_CIVIL_CONTRACT_AMOUNT,
                DOCUMENT_CORRECTION_FIELD_NAME.ORDER_AMOUNT_FOR_CONTRACTOR,
            ].includes(form.fieldName)
        ) {
            return handleNumber(form.newValue);
        }

        return form.newValue;
    };

    const onSubmit = () => {
        if (!isValid()) {
            return;
        }

        props.onSubmit({
            ...form,
            newValue: getNewValue(),
        });
    };

    return (
        <NmModal
            onClose={onClose}
            className="settings-documents-correction-edit"
            header={
                <NmTitle size="lg">
                    Редактирование
                </NmTitle>
            }
            isHiddenModal={isHiddenModal}
            footer={
                <ApplyButtons
                    onClose={onClose}
                    disabled={progress}
                    submitBtnContent="Подтвердить"
                    cancelBtnContent="Отменить"
                    submit={onSubmit}
                    align="right"
                />
            }
        >
            <NmForm addMargin>
                <NmDropdownV2
                    size="xl"
                    name="fieldName"
                    label="Параметр"
                    options={options}
                    value={form.fieldName}
                    onChange={onChange}
                    error={error.fieldName}
                />
                {
                    inputType === PARAM_TYPE.DATE ?
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            name="newValue"
                            label="Новое значение"
                            onChange={onChange}
                            selected={form.newValue}
                            error={error.newValue}
                            returnString={false}
                        /> :
                        <NmInputV2
                            size="xl"
                            name="newValue"
                            label="Новое значение"
                            value={form.newValue}
                            onChange={onChange}
                            error={error.newValue}
                        />
                }
            </NmForm>
        </NmModal>
    );
};

export default SettingsDocumentsCorrectionEdit;