import React, {FC} from "react";

import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import NmAdvancedTooltip from "../NmAdvancedTooltip";

import {COLOR} from "../../../constants/color";

interface UkepIndicatorProps {
    className?: string,
}

export const KedoLoginIndicator: FC<UkepIndicatorProps> = (props) => {
    const {
        className,
    } = props;

    return (
        <NmAdvancedTooltip
            className={className}
            children="Сотрудник еще ни разу не входил в личный кабинет КЭДО"
            hover
            type="light"
            position="bottom-left"
            trigger={
                <InfoIcon
                    color={COLOR.NEGATIVE_100}
                    width={20}
                    height={20}
                />
            }
        />
    );
};