import React from "react";

import FinancePatentPayments from "../../../finance/finance-patent-payments";
import ContractorCardFinancesTabs from "../tabs";

function ContractorCardFinancesPatents(props) {
    return (
        <FinancePatentPayments
            {...props}
            isBff={true}
            overflowUnset={true}
            noPadding={true}
            header={
                <ContractorCardFinancesTabs {...props} />
            }
            contractorId={props.match.params.contractorId}
        />
    );
}

export default ContractorCardFinancesPatents;