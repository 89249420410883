export const SMART_LINK_PAGE_REQUEST = "SMART_LINK_PAGE_REQUEST";
export const SMART_LINK_PAGE_SUCCESS = "SMART_LINK_PAGE_SUCCESS";
export const SMART_LINK_PAGE_ERROR = "SMART_LINK_PAGE_ERROR";

export const SMART_LINK_GET_CARD_REQUEST = "SMART_LINK_GET_CARD_REQUEST";
export const SMART_LINK_GET_CARD_SUCCESS = "SMART_LINK_GET_CARD_SUCCESS";
export const SMART_LINK_GET_CARD_ERROR = "SMART_LINK_GET_CARD_ERROR";

export const SMART_LINK_UPDATE_REQUEST = "SMART_LINK_UPDATE_REQUEST";
export const SMART_LINK_UPDATE_SUCCESS = "SMART_LINK_UPDATE_SUCCESS";
export const SMART_LINK_UPDATE_ERROR = "SMART_LINK_UPDATE_ERROR";

export const SMART_LINK_IMPORT_NUMBER_REQUEST = "SMART_LINK_IMPORT_NUMBER_REQUEST";
export const SMART_LINK_IMPORT_NUMBER_SUCCESS = "SMART_LINK_IMPORT_NUMBER_SUCCESS";
export const SMART_LINK_IMPORT_NUMBER_ERROR = "SMART_LINK_IMPORT_NUMBER_ERROR";

export const SMART_LINK_CHECK_ORDER_EXISTS_REQUEST = "SMART_LINK_CHECK_ORDER_EXISTS_REQUEST";
export const SMART_LINK_CHECK_ORDER_EXISTS_SUCCESS = "SMART_LINK_CHECK_ORDER_EXISTS_SUCCESS";
export const SMART_LINK_CHECK_ORDER_EXISTS_ERROR = "SMART_LINK_CHECK_ORDER_EXISTS_ERROR";

export const SMART_LINK_GET_COUNT_BY_FILTER_REQUEST = "SMART_LINK_GET_COUNT_BY_FILTER_REQUEST";
export const SMART_LINK_GET_COUNT_BY_FILTER_SUCCESS = "SMART_LINK_GET_COUNT_BY_FILTER_SUCCESS";
export const SMART_LINK_GET_COUNT_BY_FILTER_ERROR = "SMART_LINK_GET_COUNT_BY_FILTER_ERROR";

export const SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST = "SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST";
export const SMART_LINK_CLIENT_SETTINGS_PROJECT_SUCCESS = "SMART_LINK_CLIENT_SETTINGS_PROJECT_SUCCESS";
export const SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR = "SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR";

export const SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST = "SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST";
export const SMART_LINK_CLIENT_SETTINGS_OBJECT_SUCCESS = "SMART_LINK_CLIENT_SETTINGS_OBJECT_SUCCESS";
export const SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR = "SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR";

export const SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST = "SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST";
export const SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_SUCCESS = "SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_SUCCESS";
export const SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR = "SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR";

export const SMART_LINK_GET_DETAILS_TREE_REQUEST = "SMART_LINK_GET_DETAILS_TREE_REQUEST";
export const SMART_LINK_GET_DETAILS_TREE_SUCCESS = "SMART_LINK_GET_DETAILS_TREE_SUCCESS";
export const SMART_LINK_GET_DETAILS_TREE_ERROR = "SMART_LINK_GET_DETAILS_TREE_ERROR";

export const SMART_LINK_ARCHIVE_REQUEST = "SMART_LINK_ARCHIVE_REQUEST";
export const SMART_LINK_ARCHIVE_SUCCESS = "SMART_LINK_ARCHIVE_SUCCESS";
export const SMART_LINK_ARCHIVE_ERROR = "SMART_LINK_ARCHIVE_ERROR";

export const SMART_LINK_FILTER_PART_SAVE_REQUEST = "SMART_LINK_FILTER_PART_SAVE_REQUEST";
export const SMART_LINK_FILTER_PART_SAVE_SUCCESS = "SMART_LINK_FILTER_PART_SAVE_SUCCESS";
export const SMART_LINK_FILTER_PART_SAVE_ERROR = "SMART_LINK_FILTER_PART_SAVE_ERROR";

export const SMART_LINK_CLIENT_PART_SAVE_REQUEST = "SMART_LINK_CLIENT_PART_SAVE_REQUEST";
export const SMART_LINK_CLIENT_PART_SAVE_SUCCESS = "SMART_LINK_CLIENT_PART_SAVE_SUCCESS";
export const SMART_LINK_CLIENT_PART_SAVE_ERROR = "SMART_LINK_CLIENT_PART_SAVE_ERROR";

export const SMART_LINK_CREATE_REQUEST = "SMART_LINK_CREATE_REQUEST";
export const SMART_LINK_CREATE_SUCCESS = "SMART_LINK_CREATE_SUCCESS";
export const SMART_LINK_CREATE_ERROR = "SMART_LINK_CREATE_ERROR";

export const SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST = "SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST";
export const SMART_LINK_EDIT_FILTER_PART_SAVE_SUCCESS = "SMART_LINK_EDIT_FILTER_PART_SAVE_SUCCESS";
export const SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR = "SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR";

export const SMART_LINK_CACHED_CLIENTS_REQUEST = "SMART_LINK_CACHED_CLIENTS_REQUEST";
export const SMART_LINK_CACHED_CLIENTS_SUCCESS = "SMART_LINK_CACHED_CLIENTS_SUCCESS";
export const SMART_LINK_CACHED_CLIENTS_ERROR = "SMART_LINK_CACHED_CLIENTS_ERROR";

export const SMART_LINK_DELETE_CACHED_CLIENT_REQUEST = "SMART_LINK_DELETE_CACHED_CLIENT_REQUEST";
export const SMART_LINK_DELETE_CACHED_CLIENT_SUCCESS = "SMART_LINK_DELETE_CACHED_CLIENT_SUCCESS";
export const SMART_LINK_DELETE_CACHED_CLIENT_ERROR = "SMART_LINK_DELETE_CACHED_CLIENT_ERROR";

export const SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST = "SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST";
export const SMART_LINK_DELETE_ALL_CACHED_CLIENTS_SUCCESS = "SMART_LINK_DELETE_ALL_CACHED_CLIENTS_SUCCESS";
export const SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR = "SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR";

export const SMART_LINK_UPDATE_STORE = "SMART_LINK_UPDATE_STORE";