import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import formatDate from "../../../utils/dateFormat";

import {SSE_EVENT_TYPE} from "../../../constants/sse";

import {
    chatListSelector,
    chatMessageListSelector,
    chatMessageMarkRead,
    crowdChatMessageMarkRead,
    currentChatSelector,
    updateChatMessages,
    updateFieldChat,
} from "../../../ducks/chat";
import {getDisputeById} from "../../../ducks/dispute";
import {sseEventDataSelector} from "../../../ducks/serverSentEvents";

const useEventsData = () => {
    const dispatch = useDispatch();

    const event = useSelector(sseEventDataSelector);
    const chatList = useSelector(chatListSelector);
    const currentChat = useSelector(currentChatSelector);
    const messageList = useSelector(chatMessageListSelector);

    useEffect(() => {
        const messageData = event.data || {};

        if (!event || !messageData) {
            return;
        }

        if (event.eventType === SSE_EVENT_TYPE.DISPUTES_CHANGE_STATUS && currentChat.disputeId) {
            const {disputeId} = currentChat;

            dispatch(getDisputeById(disputeId));

            return;
        }

        const chatIndex = chatList.map(({chatId}) => chatId).indexOf(messageData.chatId);

        if (chatIndex !== -1) {
            updateChatList(messageData, event.eventType);
        }

        const isUpdateCurrentChat = (messageData.chatId && messageData.chatId === currentChat.chatId)
            || (messageData.chatId && messageData.chatId === currentChat.disputeId)
            || (messageData.disputeId && messageData.disputeId === currentChat.disputeId);

        if (isUpdateCurrentChat) {
            updateChatMessageList(messageData, event.eventType);
        }
    }, [event]);

    const markRead = (message, eventType) => {
        if (eventType === SSE_EVENT_TYPE.NEW_MESSAGE_CROWD_CHAT) {
            dispatch(crowdChatMessageMarkRead({
                chatId: message.chatId,
                messageId: message.messageId,
                partiesId: message.partiesId,
                clientId: message.clientId || currentChat.clientId,
            }));

            return;
        }

        dispatch(chatMessageMarkRead({
            chatId: message.chatId,
            disputeId: message.disputeId,
            messageId: message.messageId,
            partiesId: message.partiesId,
            isCrmChat: currentChat.isNamemixChat,
            isDisputeChat: Boolean(currentChat.disputeId),
        }));
    };

    const updateChatList = (message, eventType) => {
        if ([
            SSE_EVENT_TYPE.NEW_MESSAGE_CHAT,
            SSE_EVENT_TYPE.NEW_MESSAGE_CROWD_CHAT,
        ].includes(eventType)) {
            const chat = chatList.find(({chatId}) => chatId === message.chatId);
            const isCurrentChat = message.chatId === currentChat.chatId;

            const chatUpdatesFields = {
                chatId: message.chatId,
                lastMessageDate: message.dateTimeCreate,
                lastMessage: {
                    content: message.content,
                    read: isCurrentChat,
                    userId: message.senderInfo?.userId,
                },
                unreadMessageCount: isCurrentChat ? chat.unreadMessageCount : chat.unreadMessageCount + 1,
            };
            const _chatList = [
                {
                    ...chat,
                    ...chatUpdatesFields,
                },
                ...chatList.filter(item => item.chatId !== message.chatId),
            ];

            dispatch(updateFieldChat({chatList: _chatList}));
        }
    };

    const updateChatMessageList = (message, eventType) => {
        if ([
            SSE_EVENT_TYPE.NEW_MESSAGE_CHAT,
            SSE_EVENT_TYPE.NEW_MESSAGE_DISPUTE_CHAT,
            SSE_EVENT_TYPE.NEW_MESSAGE_CHAT_CRM,
            SSE_EVENT_TYPE.NEW_MESSAGE_CROWD_CHAT,
        ].includes(eventType)) {
            markRead(message, eventType);

            dispatch(updateChatMessages({
                ...message,
                partiesId: message.partiesId || message.senderInfo?.userId,
                partiesType: message.partiesType || message.senderInfo?.roleType,
                fullName: message.fullName || currentChat.contractorFio,
                dateTimeCreate: formatDate(message.dateTimeCreate || new Date(), "yyyy-MM-dd HH:mm"),
                files: message.files || message.fileInfoList,
            }));
        }

        if ([SSE_EVENT_TYPE.READ_CHAT].includes(eventType)) {
            const {dateTimeCreate} = messageList.find((item) => (item.messageId === message.lastMessageId)) || {};

            const _messageList = messageList.map(item => {
                if (new Date(item.dateTimeCreate).getTime() <= new Date(dateTimeCreate).getTime()) {
                    return {
                        ...item,
                        read: true,
                    };
                }

                return item;
            });

            dispatch(updateFieldChat({
                messageList: _messageList,
            }));
        }
    };

};

export default useEventsData;