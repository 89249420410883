import React from "react";

import ContextMenu from "../../../../../components/ActualComponents/ContextMenu";

import useMobileOrderTemplatesAction from "../../hooks/useMobileAction";

import {getHeaderMobileOrderTemplatesOptions} from "../../utils/getOptions";

const OrderTemplatesMobileHeaderDropdown = (props) => {
    const {
        archived,
        onOpenFilter,
        onToggleArchived,
        onOpenTemplateEdit,
    } = props;
    const {onClickMobileAction} = useMobileOrderTemplatesAction({
        onToggleArchived,
        onOpenFilter,
        onOpenTemplateEdit,
    });

    return (
        <ContextMenu
            inline={false}
            size="xl"
            options={getHeaderMobileOrderTemplatesOptions(archived)}
            onClickItem={onClickMobileAction}
        />
    );
};

export default OrderTemplatesMobileHeaderDropdown;