import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Media from "react-media";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import {NmPageCardHeader} from "../../../components/ActualComponents/NmPageCardHeader";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import CheckboxList from "../../../components/CheckboxList";
import NmAccordion from "../../../components/NmAccordion";
import NmPage from "../../../components/NmPage";
import NmSelectedList from "../../../components/NmSelectedList";
import Task from "../../../components/NmTask";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import InviteContractorToOrderForm from "../../contractor/nm-contractor-list/invite-contractor-to-order-form";
import NmBaseClientContractorListFilter
    from "../../contractor/nm-contractor-list/nm-base-client-contractor-list-filter";
import AdvertisementEditModal from "../edit-modal";
import {AdvertisementErrors} from "../errors";
import AdvertisementContractorCard from "./components/contractor-card";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import {useAdvertisementAction} from "./hooks/useAdvertisementAction";
import {useAdvertisementContractorsFilter} from "./hooks/useAdvertisementContractorsFilter";
import {useAdvertisementFetchData} from "./hooks/useAdvertisementFetchData";
import {useContractorsOnOrdersTab} from "./hooks/useContractorsOnOrdersTab";

import {getUserRole} from "../../../utils/access";
import {getDadataAddressObj, getWorkAddressInfoArr} from "../../../utils/dadata";
import {formatDateWithoutTime} from "../../../utils/dateFormat";
import {getFormattedFullName} from "../../../utils/stringFormat";
import {getTargetAge} from "./utils";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    ADVERTISEMENT_SOURCE_TYPE,
    ADVERTISEMENT_STATUS_DICT,
} from "../../../constants/advertisement";
import {COLOR} from "../../../constants/color";
import {GENDER_DICT} from "../../../constants/contractorInfo";
import {
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
} from "../../../constants/links";
import {
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    RECRUITER,
} from "../../../constants/roles";

import {
    getAdvertisementCardSelector,
    getAdvertisementContractorListSelector,
    getAdvertisementContractorsTotalCountSelector,
    getAdvertisementContractorsTotalPagesSelector,
    getAdvertisementProgressSelector,
} from "../../../ducks/advertisement";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {
    contractorStatusOptionsSelector,
    getCitizenshipOptionsSelector,
    getCitizenshipSelector,
    getContractorStatusDict,
    getWorkRegionsWithRemoteWorkOptionsSelector,
} from "../../../ducks/contractor";
import {downloadDocument} from "../../../ducks/documents";
import {avatarBase64ImagesListSelector} from "../../../ducks/fileStore";
import {jobTasksAdvertisementSelector} from "../../../ducks/job";
import {
    specialitiesAllV2OptionsSelector,
} from "../../../ducks/speciality";

import "./style.sass";

export const SUB_PAGE_ADVERTISEMENT = {
    //interested
    INTERESTED: {
        VALUE: "INTERESTED",
        LINK: "interested",
        TEXT: "Заинтересованные",
    },
    HIRED: {
        VALUE: "HIRED",
        LINK: "hired",
        TEXT: "Нанятые",
    },
    //received payments
    RECEIVED_PAYMENTS: {
        VALUE: "RECEIVED_PAYMENTS",
        LINK: "received-payments",
        TEXT: "Получившие выплаты",
    },
};

const initFilter = {
    statusFilter: null,
    subcategoryIdsFilter: null,
    categoryIdsFilter: null,
    schedulesFilter: null,
    citizenshipFilter: null,
    clientGroupClientIds: [],
    orderStatusFilter: null,
    genderFilter: null,
    phoneFilter: "",
    ageFromFilter: "",
    innFilter: "",
    ageToFilter: "",
    fioFilter: "",
    projectParamFilter: null,
    specialityIdsFilter: null,
    contractorFilter: null,
    fullyRegisteredFilter: null,
    joinDateFromFilter: null,
    joinDateToFilter: null,
    fullRegistrationDateFromFilter: null,
    fullRegistrationDateToFilter: null,
    bankRegistrationFilter: null,
    bankConfirmationFilter: null,
    addressFiasIdsFilter: null,
    initRegionValueArr: [],
    workWithClient: null,
    sortType: null,
    remoteWorkFilter: null,
    blocked: null,
    blockedCauseFilter: null,
    lastLoginFilter: null,
};

function AdvertisementCard(props) {
    const {
        match: {
            params: {
                clientId,
                subPage,
                advertisementId,
            },
        },
        isVisibleFiltersButton = true,
    } = props;

    const card = useSelector(getAdvertisementCardSelector);
    const advertisementTasks = useSelector(jobTasksAdvertisementSelector);
    const contractorList = useSelector(getAdvertisementContractorListSelector);
    const totalCount = useSelector(getAdvertisementContractorsTotalCountSelector);
    const totalPages = useSelector(getAdvertisementContractorsTotalPagesSelector);
    const {
        progress,
        progressAction,
        progressContractorList,
    } = useSelector(getAdvertisementProgressSelector);
    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const contractorStatusOptions = useSelector(contractorStatusOptionsSelector);
    const workRegions = useSelector(getWorkRegionsWithRemoteWorkOptionsSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const citizenshipDict = useSelector(getCitizenshipSelector);
    const {canViewContractorContacts} = useSelector(getClientPropertiesCardSelector);
    const contractorsOnOrdersTab = useContractorsOnOrdersTab({
        clientId,
        advertisementId,
        countContractorOnOrders: card.countContractorOnOrders,
    });

    const role = getUserRole();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useSelectedList();

    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({initFilter});

    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
    } = useAdvertisementContractorsFilter();

    const {
        fetchCard,
        fetchList,
    } = useAdvertisementFetchData({
        subPage,
        pageSize,
        pageNum,
        filter: filterDto,
        initFilter,
        clientId,
        advertisementId,
    });

    const {
        isInvitePopupShown,
        setInvitePopupShown,
        contractorId,
        setContractorId,
        isShowConfirm,
        setIsShowConfirm,
        isShowEditModal,
        setShowEditModal,
        duplicate,
        setDuplicate,
        isEdit,
        setIsEdit,
        closeAdvertisement,
    } = useAdvertisementAction();

    const selectedContractorIds = selectedList.filter(({isSelected}) => isSelected).map(({contractorId}) => contractorId);
    const jobId = `${clientId}:${advertisementId}`;
    const isMassOperation = countSelected !== 0;

    const {
        baseModel: {
            name,
            status,
            description,
            ageFrom,
            ageTo,
            dateFrom,
            dateTo,
            publicationDateFrom,
            publicationDateTo,
            gender,
            citizenships = [],
            fileName,
            workAddressInfo,
            sourceType,
        },
        countContractorInterest,
        countContractorOrderHired,
        countContractorOrderPayment,
        fioManager,
        specialityName,
        filePath,
    } = card;


    useEffect(() => {
        fetchCard();
        dispatch(getContractorStatusDict());
    }, []);

    function downloadFile() {
        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink: filePath,
        }));
    }

    const link = LINK_CLIENT_ADVERTISEMENT.replace(":clientId", clientId);

    function getLink(param, count) {
        return {
            count,
            name: SUB_PAGE_ADVERTISEMENT[param].TEXT,
            link: LINK_CLIENT_CARD_ADVERTISEMENT
                .replace(":clientId", clientId)
                .replace(":advertisementId", advertisementId)
                .replace(":subPage", SUB_PAGE_ADVERTISEMENT[param].LINK),
            active: subPage === SUB_PAGE_ADVERTISEMENT[param].LINK,
        };
    }

    function tabData() {
        return [
            getLink(SUB_PAGE_ADVERTISEMENT.INTERESTED.VALUE, countContractorInterest),
            getLink(SUB_PAGE_ADVERTISEMENT.HIRED.VALUE, countContractorOrderHired),
            getLink(SUB_PAGE_ADVERTISEMENT.RECEIVED_PAYMENTS.VALUE, countContractorOrderPayment),
            contractorsOnOrdersTab,
        ];
    }

    function renderDescription() {
        return (
            <Text
                level="3"
                color={COLOR.SECONDARY_90}
                className="advertisement-card__info-text"
            >
                <NmShowMoreText
                    anchor="blue"
                    title={name}
                    lines={2}
                    children={description}
                    more="Читать полностью"
                />
            </Text>
        );
    }

    function renderDescriptionCard() {
        return (
            <Media query={{minWidth: 1200}}>
                {
                    desktop =>
                        desktop ?
                            getInfo() :
                            <>
                                {renderDescription()}
                                <NmAccordion
                                    defaultOpen
                                    name="Подробная информация"
                                >
                                    {firstInfoBlock()}
                                    {secondInfoBlock()}
                                </NmAccordion>
                            </>
                }
            </Media>
        );
    }

    function getInfo() {
        return (
            <div className="row">
                <div className="col-8 col-xxl-5">
                    {firstInfoBlock(true)}
                </div>
                <div className="offset-1 col-7 col-xxl-5">
                    {secondInfoBlock()}
                </div>
            </div>
        );
    }

    function secondInfoBlock() {
        return (
            <>
                <NmLabelText
                    boldText
                    type="page"
                    className="advertisement-card__name"
                    label="Вид деятельности"
                    text={specialityName}
                />
                <NmLabelText
                    boldText
                    type="page"
                    className="advertisement-card__name"
                    columnOnMobile
                    label="Город ведения деятельности"
                    text={getDadataAddressObj(workAddressInfo || {}, true).text}
                />
                {
                    citizenships?.length ?
                        <NmSelectedList
                            className="advertisement-card__name"
                            labelClassName="advertisement-card__selected-list"
                            itemClassName="advertisement-card__selected-list advertisement-card__selected-list-item"
                            label="Гражданство"
                            showedItemsCount={2}
                            showListWithoutValue={true}
                            list={citizenships.map((item) => ({text: citizenshipDict[item]}))}
                            dropdownWidth={250}
                        /> :
                        <NmLabelText
                            boldText
                            type="page"
                            className="advertisement-card__name"
                            label="Гражданство"
                            text="Все"
                        />
                }
                <NmLabelText
                    boldText
                    type="page"
                    className="advertisement-card__name"
                    label="Пол"
                    text={GENDER_DICT[gender] || "Все"}
                />
                <NmLabelText
                    boldText
                    type="page"
                    className="advertisement-card__name"
                    label="Возраст"
                    text={getTargetAge(ageFrom, ageTo)}
                />
                {
                    fileName &&
                    <NmLabelText
                        boldText
                        type="page"
                        className="advertisement-card__name"
                        label="Файл"
                        color="blue"
                        text={
                            <div
                                className="advertisement-card__link"
                                onClick={downloadFile}
                            >
                                {fileName}
                            </div>
                        }
                    />
                }
            </>
        );
    }

    function firstInfoBlock(isShowDescription) {
        return (
            <>
                {isShowDescription && renderDescription()}
                <NmLabelText
                    boldText
                    type="page"
                    columnOnMobile
                    className="advertisement-card__name"
                    label="Период публикации объявления"
                    text={`${formatDateWithoutTime(publicationDateFrom)} - ${formatDateWithoutTime(publicationDateTo)}`}
                />
                <NmLabelText
                    boldText
                    type="page"
                    columnOnMobile
                    className="advertisement-card__name"
                    label="Период в объявлении"
                    text={`${formatDateWithoutTime(dateFrom)} - ${formatDateWithoutTime(dateTo)}`}
                />
                {
                    fioManager &&
                    <NmLabelText
                        type="page"
                        boldText
                        className="advertisement-card__name"
                        label="Ответственный"
                        text={getFormattedFullName(fioManager)}
                    />
                }
            </>
        );
    }

    function getRows() {
        return contractorList.map(({baseModel, contractor}) => {
            const {
                contractorId,
                lastName,
                firstName,
                patronymic,
                fullName,
                workAddressInfo,
                departureAddressesInfo,
                addedToMyClientGroup,
            } = contractor || {};

            const {isSelected = false} = selectedList.find(item => (item.contractorId === contractorId)) || {};

            const contractorInfo = {
                ...contractor,
                isSelected: false,
                fullName: lastName ? `${lastName} ${firstName} ${patronymic || ""}` : fullName,
                workAddressInfoArr: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
                interestDate: baseModel?.interestDate,
            };

            return {
                ...contractor,
                key: contractorId,
                showCheckBox: canViewContractorContacts && !contractor?.archived,
                isSelected,
                avatar:(
                    <Avatar
                        addedToMyClientGroup={addedToMyClientGroup}
                        contractorId={contractorId}
                        base64={userAvatarDict[contractorId]}
                    />
                ),
                contentRow: (
                    <AdvertisementContractorCard
                        contractor={!isEmpty(contractor) ? contractorInfo : {}}
                        clientId={clientId}
                        isMassOperation={isMassOperation}
                        openInvitePopup={openInvitePopup}
                        specialityOptions={specialityOptions}
                        fetchList={fetchList}
                        setContractorId={setContractorId}
                    />
                ),
            };
        });
    }

    function sendFilter() {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(true);
    }

    function clearFilter() {
        setFilter(initFilter);
        setFilterDto(initFilter);
        setIsSearch(false);
    }

    function openInvitePopup() {
        setInvitePopupShown(true);
    }

    function closeAdvertisementOnConfirm() {
        closeAdvertisement({
            requestData: [{
                advertId: advertisementId,
                clientId,
            }],
            onSuccess: fetchCard,
        });
    }

    function renderConfirm() {
        return (
            isShowConfirm &&
            <NmConfirmV2
                content="Вы действительно хотите отменить публикацию объявления?"
                onCancel={() => {
                    setIsShowConfirm(false);
                }}
                onConfirm={closeAdvertisementOnConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                disabled={progressAction}
            />
        );
    }

    function getMediaControls() {
        if ([
            NM_CONSULTANT,
            NM_CHIEF_ACCOUNTANT,
            NM_COORDINATOR,
            FOREMAN,
        ].includes(role)) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Отменить публикацию",
                        onClick: () => setIsShowConfirm(true),
                    },
                    visible: [
                        ADVERTISEMENT_STATUS_DICT.AWAIT_PUBLISH.VALUE,
                        ADVERTISEMENT_STATUS_DICT.PUBLISHED.VALUE,
                    ].includes(status)
                    && !(role === RECRUITER && sourceType !== ADVERTISEMENT_SOURCE_TYPE.RECRUITMENT),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Редактировать",
                        onClick: () => {
                            setShowEditModal(true);
                            setIsEdit(true);
                        },
                    },
                    visible: [
                        ADVERTISEMENT_STATUS_DICT.AWAIT_PUBLISH.VALUE,
                        ADVERTISEMENT_STATUS_DICT.PUBLISHED.VALUE,
                    ].includes(status)
                    && ![RECRUITER].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Создать дубликат",
                        onClick: () => {
                            setDuplicate(true);
                            setShowEditModal(true);
                        },
                    },
                    visible: ![RECRUITER].includes(role),
                },
            ],
        };
    }

    const getContent = () => {
        if (props.children) {
            return props.children;
        }

        if (!contractorList.length) {
            return (
                <NmEmptyPageV2 isSearch={isSearch} />
            );
        }

        return (
            <CheckboxList
                header={
                    canViewContractorContacts &&
                    <SelectionCountWithAction
                        adaptiveLogic
                        count={countSelected}
                        buttonColor="green"
                        buttonContent="Пригласить на заказ"
                        onClick={openInvitePopup}
                    />
                }
                rows={getRows()}
                isShowMassActions
                onSelectedRows={canViewContractorContacts && handleSelectedRows}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageCardHeader
                    to={link}
                    statusDict={ADVERTISEMENT_STATUS_DICT}
                    content={name}
                    status={status}
                />
            }
            titleClassName="advertisement-card__title"
            mediaControls={getMediaControls()}
            subHeader={renderDescriptionCard()}
            isLoaded={progress || progressContractorList}
            isVisiblePagination={!props.children}
            onPaginationChange={onPaginationChange}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            typeFilter={isVisibleFiltersButton && "vertical"}
            filtersBase={
                isVisibleFiltersButton &&
                <NmBaseClientContractorListFilter
                    t={t}
                    workRegions={workRegions}
                    specialityOptions={specialityOptions}
                    contractorStatusOptions={contractorStatusOptions}
                    сitizenshipOptions={citizenshipOptions}
                    filterData={filter}
                    sendFilter={sendFilter}
                    onChange={onChangeFilter}
                    clearFilter={clearFilter}
                    changeSpecialities={onChangeFilter}
                />
            }
        >
            <Task />
            <ByTaskUpdater
                fetch={fetchCard}
                taskIds={advertisementTasks}
                dataIds={[jobId]}
            />
            <Tabs
                className="advertisement-card__tabs"
                panes={tabData()}
            />
            {renderConfirm()}
            {
                isInvitePopupShown &&
                <InviteContractorToOrderForm
                    loading={progressAction}
                    close={() => {
                        setInvitePopupShown(false);
                    }}
                    isMassOperation={isMassOperation}
                    contractorIds={isMassOperation ? selectedContractorIds : [contractorId]}
                    clientId={clientId}
                    advertId={advertisementId}
                    onSuccess={fetchList}
                />
            }
            <AdvertisementErrors />
            {
                isShowEditModal &&
                <AdvertisementEditModal
                    duplicate={duplicate}
                    isEdit={isEdit}
                    clientId={clientId}
                    fetchList={fetchCard}
                    onClose={() => {
                        setShowEditModal(false);
                        setIsEdit(false);
                        setDuplicate(false);
                    }}
                />
            }
            {getContent()}
        </NmPage>
    );
}

export default AdvertisementCard;