import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getRecruitmentJobBoards} from "../../ducks/bff/recruitment/jobBoards/actionCreators";
import {
    bffRecruitmentActiveJobBoardsListSelector,
    bffRecruitmentJobBoardsPageDataSelector,
    bffRecruitmentJobBoardsProgressActionSelector,
    bffRecruitmentJobBoardsProgressAuthorizationSelector,
    bffRecruitmentJobBoardsProgressSelector,
} from "../../ducks/bff/recruitment/jobBoards/selectors";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmModal from "../ActualComponents/NmModal";
import NmModalCardList from "../ActualComponents/NmModal/CardList";
import Text from "../ActualComponents/Text";
import JobBoardLogo from "../JobBoardLogo";
import NmButton from "../NmButton";
import NmTitle from "../NmTitle";

import {useModal} from "../../hooks/useModal";
import {useJobBoardConnect} from "./hooks/useConnect";

import {COLOR} from "../../constants/color";
import {
    RECRUITMENT_SOURCE_JOB_BOARD,
    RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE,
} from "../../containers/recruitment/vacancies/constants";

export const JobBoardsConnect = (props) => {
    const {
        onClose,
        clientId,
    } = props;

    const progress = useSelector(bffRecruitmentJobBoardsProgressSelector);
    const progressAction = useSelector(bffRecruitmentJobBoardsProgressActionSelector);
    const progressAuthorization = useSelector(bffRecruitmentJobBoardsProgressAuthorizationSelector);
    const pageData = useSelector(bffRecruitmentJobBoardsPageDataSelector);
    const list = useSelector(bffRecruitmentActiveJobBoardsListSelector);
    const dispatch = useDispatch();

    const {
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
        modalData: confirmData,
    } = useModal();

    useEffect(() => {
        dispatch(getRecruitmentJobBoards({clientId}));
    }, []);

    const {
        connectJobBoard,
    } = useJobBoardConnect();

    const getRows = () => {
        return list.map(jobBoardType => {
            return {
                icon: (
                    <JobBoardLogo
                        name={jobBoardType}
                        width={48}
                        height={48}
                    />
                ),
                mainContent: (
                    <Text
                        level="5"
                        color={COLOR.SECONDARY_100}
                    >
                        {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[jobBoardType]}
                    </Text>
                ),
                actions: (
                    <NmButton
                        size="lg"
                        color="green"
                        disabled={
                            pageData.jobBoardType === jobBoardType &&
                            progressAction
                        }
                        onClick={() => {
                            if (jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU) {
                                onOpenConfirm({
                                    content: "Для подключения обратитесь к своему менеджеру.",
                                });

                                return;
                            }

                            connectJobBoard({
                                clientId,
                                jobBoardType,
                            });
                        }}
                        children="Подключить"
                        className="fluid"
                    />
                ),
            };
        });
    };

    const getModal = () => {
        if (isOpenConfirm) {
            return (
                <NmConfirmV2
                    onCancel={onCloseConfirm}
                    onConfirm={onCloseConfirm}
                    confirmButton="Ок"
                    isOnlyConfirm={true}
                    content={confirmData.content}
                    size="sm"
                />
            );
        }

        return (
            <NmModal
                size="md"
                style={{minHeight: 348}}
                onClose={onClose}
                header={
                    <NmTitle size="md">
                        Джоб-борды (рекрутмент)
                    </NmTitle>
                }
                classNameContent="flex-column"
                loading={progress || progressAuthorization}
                loaderContent={progressAuthorization ? "Подключение к Наймиксу..." : ""}
            >
                <NmModalCardList
                    list={getRows()}
                    classNameMainContent="justify-content-center"
                />
            </NmModal>
        );
    };

    return getModal();
};
