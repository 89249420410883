import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useQuery} from "../../hooks/useQuery";

import {LINK_LOGIN} from "../../constants/links";

import {history} from "../../store/configureStore";

import {getAuthorizationSession} from "../../ducks/auth";

const AuthRedirect = () => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        const refreshToken = query.get("_t");
        const resultUrl = query.get("res_url");

        if (!refreshToken || !resultUrl) {
            return history.push(LINK_LOGIN);
        }

        dispatch(getAuthorizationSession({
            refreshToken,
            onSuccess: () => {
                window.location.href = resultUrl;
            },
        }));
    }, []);

    return (
        <>
            Идет авторизация, пожалуйста подождите...
        </>
    );
};

export default AuthRedirect;