import React from "react";

import DisputesLog from "../../../disputes/disputes-list";

import "./style.sass";

const ClientCardDisputes = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    return (
        <DisputesLog
            {...props}
            clientIdFilter={clientId}
            isShowClientFilter={false}
            className="client-card-disputes"
        />
    );
};

export default ClientCardDisputes;