import React from "react";

import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";

import {getHoursOptions, getMinutesOptions} from "../../../../../../../utils/dateFormat";

export const OrderEditOutTime = (props) => {
    const {
        timeHours,
        timeMinutes,
        onChange,
        onFocus,
        disabled,
        nameHours,
        nameMinutes,
        label,
    } = props;

    const timeHoursOptions = getHoursOptions({
        returnString: true,
    });
    const timeMinutesOptions = getMinutesOptions({
        returnString: true,
    });

    return (
        <div className="row align-items-end gx-2">
            <div className="col-8">
                <NmDropdownV2
                    disabled={disabled}
                    label={label}
                    placeholder="Часы"
                    search={true}
                    size="xl"
                    isClearable={true}
                    name={nameHours}
                    options={timeHoursOptions}
                    value={timeHours}
                    onChange={onChange}
                    onFocus={onFocus}
                />
            </div>
            <div className="col-8">
                <NmDropdownV2
                    disabled={disabled}
                    placeholder="Минуты"
                    isClearable={true}
                    search={true}
                    size="xl"
                    name={nameMinutes}
                    options={timeMinutesOptions}
                    value={timeMinutes}
                    onChange={onChange}
                    onFocus={onFocus}
                />
            </div>
        </div>
    );
};