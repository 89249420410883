import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getSettingsDirectoriesBanksLogsPage} from "../../../../../../../ducks/settingsDirectoriesBanks";

export const useFetchContractorWalletWithdrawAvailableLog = ({pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getSettingsDirectoriesBanksLogsPage({
            keyFilter: "contractorWalletWithdrawAvailable",
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};