import React from "react";

import {ReactComponent as HoReCaIcon} from "../../images/horeca-indicator.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";

import "./style.sass";

const HoReCaIndicator = (props) => {
    const {
        className,
    } = props;

    const getIcon = () => {
        return (
            <HoReCaIcon
                height={18}
                width={18}
            />
        );
    };

    const tooltipText="Исполнитель в закрытом контуре \"Внештат\"";

    return (
        <div className={className}>
            <NmAdvancedTooltip
                classNameTooltip={"horeca-indicator__tooltip"}
                children={tooltipText}
                trigger={getIcon()}
                hover
                position="bottom-left"
            />
        </div>
    );
};

export default HoReCaIndicator;