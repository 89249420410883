import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {documentType1c, documentType1cAll} from "../constants/documentType";
import {DOCUMENTS_MESSAGE} from "../constants/messages";

const controller = "/one";
//*  TYPES  *//

const CLIENT_FINANCE_UPL0AD_READ_REQUEST = "CLIENT_FINANCE_UPL0AD_READ_REQUEST";
const CLIENT_FINANCE_UPL0AD_READ_SUCCESS = "CLIENT_FINANCE_UPL0AD_READ_SUCCESS";
const CLIENT_FINANCE_UPL0AD_READ_ERROR = "CLIENT_FINANCE_UPL0AD_READ_ERROR";

const CLIENT_FINANCE_UPL0AD_LOG_READ_REQUEST = "CLIENT_FINANCE_UPL0AD_LOG_READ_REQUEST";
const CLIENT_FINANCE_UPL0AD_LOG_READ_SUCCESS = "CLIENT_FINANCE_UPL0AD_LOG_READ_SUCCESS";
const CLIENT_FINANCE_UPL0AD_LOG_READ_ERROR = "CLIENT_FINANCE_UPL0AD_LOG_READ_ERROR";

const CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_REQUEST = "CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_REQUEST";
const CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_SUCCESS = "CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_SUCCESS";
const CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_ERROR = "CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_ERROR";

//*  INITIAL STATE  *//

const initial = {
    totalCount: 0,
    pageData: {},
    error: null,
    progressCreate: false,
    financeLog: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CLIENT_FINANCE_UPL0AD_READ_REQUEST:
        return {
            ...state,
            progressCreate: true,
        };
    case CLIENT_FINANCE_UPL0AD_LOG_READ_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case CLIENT_FINANCE_UPL0AD_READ_SUCCESS:
        return {
            ...state,
            progressCreate: false,
        };
    case CLIENT_FINANCE_UPL0AD_LOG_READ_SUCCESS:
        const {
            oneUnloadList = [],
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            totalCount,
            financeLog: [...oneUnloadList],
        };
    case CLIENT_FINANCE_UPL0AD_READ_ERROR:
        return {
            ...state,
            progressCreate: false,
        };
    case CLIENT_FINANCE_UPL0AD_LOG_READ_ERROR:
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getDocumentXml(payload) {
    return {
        type: CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_REQUEST,
        payload,
    };
}

export function getClientFinanceUploadLog(payload) {
    return {
        type: CLIENT_FINANCE_UPL0AD_LOG_READ_REQUEST,
        payload,
    };
}

export function clientFinanceUpload(payload) {
    return {
        type: CLIENT_FINANCE_UPL0AD_READ_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientFinanceUploadLogSelector = state => state.clientFinanceUploadLog;

export const getTotalPagesClientFinanceUploadLogSelector = createSelector(
    clientFinanceUploadLogSelector,
    ({
        totalCount,
        pageData: {
            pageSize,
        },
    }) => getTotalPages(totalCount, pageSize));

export const clientFinanceUploadLogTotalCountSelector = createSelector(clientFinanceUploadLogSelector, ({totalCount}) => totalCount);

export const clientFinanceUploadLogPageDataSelector = createSelector(clientFinanceUploadLogSelector, ({pageData}) => pageData);

export const getClientFinanceUploadLogListSelector = createSelector(clientFinanceUploadLogSelector, ({financeLog}) => financeLog);

export const clientFinanceUploadLogProgressSelector = createSelector(clientFinanceUploadLogSelector, ({progress}) => progress);

export const clientFinanceUploadProgressCreateSelector = createSelector(clientFinanceUploadLogSelector, ({progressCreate}) => progressCreate);

//*  SAGA  *//

export const clientFinanceUploadReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/createDocument`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: CLIENT_FINANCE_UPL0AD_READ_ERROR, payload: {errorMessage}});

            return {
                done: true,
            };
        }

        toastSuccess("Загрузка успешно выполнена");

        yield put({type: CLIENT_FINANCE_UPL0AD_READ_SUCCESS, payload: result});

        const state = yield select();
        yield put(getClientFinanceUploadLog(clientFinanceUploadLogPageDataSelector(state)));
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_FINANCE_UPL0AD_READ_ERROR,
            payload: error,
        });
    }
};

// POST /api/one/documentXml
export const getDocumentXmlSaga = function* (action) {
    try {
        const {
            payload: {
                fileStorePath,
                documentType,
                extension = "xml",
            },
        } = action;
        const myInit = {
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "application/json",
            },
            method: "POST",
            body: fileStorePath,
        };


        const response = yield fetch(`${new URL(window.location.href).origin}/api${controller}/getDocument/`, myInit);

        if (response.status === 404) {
            toastError(DOCUMENTS_MESSAGE.ERROR_404);

            return {
                done: true,
            };
        }

        const blob = yield response.blob();
        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);
        tempLink.setAttribute("download", `${documentType1cAll[documentType].text}.${extension}`);

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

        yield put({
            type: CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_SUCCESS,
            // payload: result
        });
    } catch (error) {
        yield put({
            type: CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_ERROR,
            payload: error,
        });
    }
};


//POST /api/one/unload/getPage
export const clientFinanceUploadLogListReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/unload/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_FINANCE_UPL0AD_LOG_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_FINANCE_UPL0AD_LOG_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_FINANCE_UPL0AD_LOG_READ_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_FINANCE_UPL0AD_READ_REQUEST, clientFinanceUploadReadSaga),
        takeEvery(CLIENT_FINANCE_UPL0AD_LOG_READ_REQUEST, clientFinanceUploadLogListReadSaga),
        takeEvery(CLIENT_FINANCE_DOWNLOAD_DOCUMENT_XML_REQUEST, getDocumentXmlSaga),
    ]);
}
