import * as Yup from "yup";

import {handleFormString} from "../../../../utils/stringHelper";

import {CLIENT_WORK_TYPE} from "../../../../constants/clientSettings";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

export const getClientWorkTariffValidation = () => {
    return Yup.string().when("clientWorkType", {
        is: (value) => [
            CLIENT_WORK_TYPE.ONLY_KEDO,
            CLIENT_WORK_TYPE.NAIMIX_KEDO,
        ].includes(value),
        then: Yup.string()
            .transform(handleFormString)
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};