import React from "react";
import {useSelector} from "react-redux";

import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import {
    bffRecruitmentVacancyCandidatesCardSelector,
    bffRecruitmentVacancyCandidatesProgressActionSelector,
    bffRecruitmentVacancyCandidatesProgressCardSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {RecruitmentCandidatesEditForm} from "../edit-form";

export const RecruitmentCandidatesEdit = (props) => {
    const {
        onClose,
        editData,
        fetchData,
        clientId,
    } = props;

    const progress = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);
    const progressCard = useSelector(bffRecruitmentVacancyCandidatesProgressCardSelector);
    const card = useSelector(bffRecruitmentVacancyCandidatesCardSelector);

    return (
        <NmModal
            size="md"
            className="comments-retail-modal"
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent={editData ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    disabled={progress}
                    formId="edit"
                />
            }
            loading={progressCard}
            header={
                <NmTitle size="lg">
                    {editData ? "Редактирование кандидата" : "Добавление кандидата"}
                </NmTitle>
            }
        >
            <RecruitmentCandidatesEditForm
                formId="edit"
                clientId={clientId}
                editData={editData}
                onClose={onClose}
                fetchData={fetchData}
                card={card}
            />
        </NmModal>
    );
};
