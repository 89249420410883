import React from "react";

import svgList from "../../svgList";

import bem from "../../utils/bem";

const JSX_ATTRS = {
    "xmlns:xlink": "xmlnsXlink",
    "xml:space": "xmlSpace",
    "enable-background": "enableBackground",
    "stroke-width": "strokeWidth",
    "stroke-miterlimit": "strokeMiterlimit",
    "fill-opacity": "fillOpacity",
    "stroke-linecap": "strokeLinecap",
    "stroke-linejoin": "strokeLinejoin",
};

const DIRECTIONS = {
    top: 0,
    right: 90,
    bottom: 180,
    left: 270,
};

const convertDomAttrsToJsx = (attrs) => {
    const attrsArr = Object.entries(attrs);
    const result = attrsArr.map(([attrName, attrValue]) => {
        const name = attrName in JSX_ATTRS ? JSX_ATTRS[attrName] : attrName;

        return [name , attrValue];
    });

    return Object.fromEntries(result);
};

const getDirection = (direction) => {
    const angle = DIRECTIONS[direction];
    const rotate = `rotate(${angle}deg)`;
    const transformStyle = angle
        ? {
            WebkitTransform: rotate,
            transform: rotate,
        }
        : {};
    
    return {
        ...transformStyle,
    };
};

const NmSvg = (props) => {
    const {
        name,
        direction = "top",
        className,
    } = props;

    const [block] = bem("nm-svg", className);

    if (!svgList[name]) {
        console.error(`${name} отсутсвует в svgList.jsx!`);
        return null;
    }

    const {
        props: {
            children,
            ...svgAttrs
        },
    } = svgList[name];

    const renderChildren = () => {
        const childrenArr = Array.isArray(children) ? children : [children];   

        return (
            childrenArr.map(({type, props}) => (
                React.createElement(type, convertDomAttrsToJsx(props))
            ))
        );
    };

    return (
        <svg
            className={block()}
            style={getDirection(direction)}
            {...svgAttrs}
        >
            {renderChildren()}
        </svg>
    );
};

export default NmSvg;