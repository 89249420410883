import React, {ForwardedRef, ReactNode, useContext, useEffect} from "react";
import {debounce} from "lodash";

import {AppContext} from "../../AppContext";

import bem from "../../../utils/bem";
import {scrollToBottom} from "../../../utils/scrollToBottom";

import "./style.sass";

interface IScrollableContainer {
    children: ReactNode,
    className?: string,
    isScrollToBottom?: boolean,
    ref: ForwardedRef<HTMLDivElement | null>,
    fullHeight?: boolean,
    isBarOffset?: boolean,
    absoluteFill?: boolean,
    saveScrollPosition?: boolean,
}

const ScrollableContainer = React.forwardRef<HTMLDivElement | null, IScrollableContainer>((props, ref) => {
    const {
        children,
        className,
        isScrollToBottom = false,
        fullHeight,
        isBarOffset,
        absoluteFill,
        saveScrollPosition,
    } = props;

    const {
        setScrollPosition,
        containerRef,
    } = useContext<any>(AppContext);

    const [block] = bem("scrollable-container", className);

    useEffect(() => {
        if (isScrollToBottom && typeof ref !== "function") {
            if (ref) {
                scrollToBottom(ref);
            }
        }
    }, [isScrollToBottom]);

    const updateScrollPosition = debounce((value) => {
        setScrollPosition(value);
    }, 150);

    const getReference = () => {
        if (saveScrollPosition) {
            return containerRef;
        }

        return ref;
    };

    return (
        <div
            ref={getReference()}
            className={block({
                fullHeight,
                barOffset: isBarOffset,
                absoluteFill,
            })}
            onScroll={(event) => {
                updateScrollPosition((event.target as HTMLElement).scrollTop);
            }}
        >
            {children}
        </div>
    );
});

export default ScrollableContainer;