import React from "react";

import Box from "../../../../../components/ActualComponents/Box";
import DropdownWithInput from "../../../../../components/ActualComponents/DropdownWithInput";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import AmountInput from "../../../../../components/AmountInput";
import NmBadge from "../../../../../components/NmBadge";

import {PATTERN_NUMBER} from "../../../../deposit/client-list/hooks/useGetlDepositData";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {KEDO_TEMPLATE_DATA_TYPES, KEDO_TEMPLATE_DATA_TYPES_DICT} from "./constants";

const KedoTemplatesEditFieldForm = (props) => {
    const {
        index,
        touched,
        values,
        errors,
        setFieldTouched,
        setFieldValue,
        className,
    } = props;

    const names = {
        field: `userReferenceParamModels[${index}].field`,
        type: `userReferenceParamModels[${index}].type`,
        name: `userReferenceParamModels[${index}].name`,
        minValue: `userReferenceParamModels[${index}].minValue`,
        maxValue: `userReferenceParamModels[${index}].maxValue`,
        maxLength: `userReferenceParamModels[${index}].maxLength`,
        availableValues: `userReferenceParamModels[${index}].availableValues`,
    };

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <Box
            className={className}
            theme="page"
        >
            <NmBadge
                text={values.field}
                mod="green"
            />
            <NmDropdownV2
                required
                label="Тип поля"
                placeholder="Не выбрано"
                name={names.type}
                className="mt-4"
                options={dictionaryToOptions(KEDO_TEMPLATE_DATA_TYPES_DICT)}
                value={values.type}
                onChange={onChange}
                error={
                    touched &&
                    touched.type &&
                    errors &&
                    errors.type
                }
            />
            <NmInputV2
                required
                size="xl"
                onChange={onChange}
                name={names.name}
                className="mt-3 mt-md-4"
                value={values.name}
                placeholder="Введите наименование поля"
                label="Наименование поля для пользователя"
                onBlur={() => {setFieldTouched(names.name, true);}}
                error={
                    touched &&
                    touched.name &&
                    errors &&
                    errors.name
                }
            />
            {
                [
                    KEDO_TEMPLATE_DATA_TYPES.MONEY,
                    KEDO_TEMPLATE_DATA_TYPES.DOUBLE,
                ].includes(values.type) &&
                <div className="row mt-3 mt-md-4">
                    <div className="col-16 col-md-8">
                        <AmountInput
                            newInput
                            size="xl"
                            label="Минимальное значение"
                            name={names.minValue}
                            value={values.minValue}
                            placeholder="Введите значение"
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <AmountInput
                            newInput
                            size="xl"
                            label="Максимальное значение"
                            name={names.maxValue}
                            value={values.maxValue}
                            placeholder="Введите значение"
                            onChange={onChange}
                        />
                    </div>
                </div>
            }
            {
                [
                    KEDO_TEMPLATE_DATA_TYPES.INTEGER,
                ].includes(values.type) &&
                <div className="row mt-3 mt-md-4">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            onChange={onChange}
                            name={names.minValue}
                            value={values.minValue}
                            placeholder="Введите число"
                            label="Минимальное значение"
                            pattern={PATTERN_NUMBER}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            onChange={onChange}
                            name={names.maxValue}
                            value={values.maxValue}
                            placeholder="Введите число"
                            label="Максимальное значение"
                            pattern={PATTERN_NUMBER}
                        />
                    </div>
                </div>
            }
            {
                [
                    KEDO_TEMPLATE_DATA_TYPES.STRING,
                ].includes(values.type) &&
                <NmInputV2
                    size="xl"
                    onChange={onChange}
                    className="mt-3 mt-md-4"
                    name={names.maxLength}
                    value={values.maxLength}
                    placeholder="Введите число"
                    label="Максимальное количество символов"
                />
            }
            {
                [
                    KEDO_TEMPLATE_DATA_TYPES.MULTIPLE_STRING_SELECTION,
                    KEDO_TEMPLATE_DATA_TYPES.STRING_SELECTION,
                ].includes(values.type) &&
                <DropdownWithInput
                    size="xl"
                    required
                    label="Варианты значений"
                    placeholder="Введите варианты"
                    className="mt-3 mt-md-4"
                    minCharacters={1}
                    maxCharacters={100}
                    minSearchLength={4}
                    isDetectEqualValues={true}
                    isClearInputValueOnBlur={true}
                    name={names.availableValues}
                    error={
                        touched &&
                        touched.availableValues &&
                        errors &&
                        errors.availableValues
                    }
                    values={values.availableValues}
                    onChange={onChange}
                />
            }
        </Box>
    );
};

export default KedoTemplatesEditFieldForm;