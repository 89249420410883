import {
    BFF_MAP_EXPORT_ERROR,
    BFF_MAP_EXPORT_REQUEST,
    BFF_MAP_EXPORT_SUCCESS,
    BFF_MAP_GET_ADVERTS_ERROR,
    BFF_MAP_GET_ADVERTS_REQUEST,
    BFF_MAP_GET_ADVERTS_SUCCESS,
    BFF_MAP_GET_CLUSTERS_ERROR,
    BFF_MAP_GET_CLUSTERS_REQUEST,
    BFF_MAP_GET_CLUSTERS_SUCCESS,
    BFF_MAP_GET_CONTRACTORS_ERROR,
    BFF_MAP_GET_CONTRACTORS_REQUEST,
    BFF_MAP_GET_CONTRACTORS_SUCCESS,
    BFF_MAP_GET_LIST_PAGE_COUNTS_SUCCESS,
    BFF_MAP_GET_ORDERS_ERROR,
    BFF_MAP_GET_ORDERS_REQUEST,
    BFF_MAP_GET_ORDERS_SUCCESS,
    BFF_MAP_GET_TASKS_ERROR,
    BFF_MAP_GET_TASKS_REQUEST,
    BFF_MAP_GET_TASKS_SUCCESS,
    BFF_MAP_SEND_INVITE_TASK_ERROR,
    BFF_MAP_SEND_INVITE_TASK_REQUEST,
    BFF_MAP_SEND_INVITE_TASK_SUCCESS,
    BFF_MAP_UPDATE_STORE,
} from "./actions";

export const initialMapEntityLists = {
    contractorsPageData: {},
    contractors: [],
    contractorsTotalCount: 0,
    ordersPageData: {},
    orders: [],
    ordersTotalCount: 0,
    advertsPageData: {},
    adverts: [],
    advertsTotalCount: 0,
    tasksPageData: {},
    tasks: [],
    tasksTotalCount: 0,
    progressList: false,
};

const initial = {
    mapPageData: {},
    clusters: [],
    error: null,
    progress: false,
    progressAction: false,
    counts: {},
    ...initialMapEntityLists,
    inviteModalData: initialMapEntityLists,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_MAP_GET_CLUSTERS_REQUEST: {
        return {
            ...state,
            mapPageData: payload,
            progress: true,
        };
    }
    case BFF_MAP_GET_CLUSTERS_SUCCESS: {
        return {
            ...state,
            clusters: payload,
            progress: false,
        };
    }
    case BFF_MAP_GET_CLUSTERS_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_MAP_GET_CONTRACTORS_REQUEST: {
        const data = {
            contractorsPageData: payload,
            progressList: true,
        };

        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    ...data,
                },
            };
        }

        return {
            ...state,
            contractorsPageData: payload,
            progressList: true,
        };
    }
    case BFF_MAP_GET_ORDERS_REQUEST: {
        const data = {
            ordersPageData: payload,
            progressList: true,
        };

        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    ...data,
                },
            };
        }

        return {
            ...state,
            ...data,
        };
    }
    case BFF_MAP_GET_ADVERTS_REQUEST: {
        const data = {
            advertsPageData: payload,
            progressList: true,
        };

        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    ...data,
                },
            };
        }

        return {
            ...state,
            ...data,
        };
    }
    case BFF_MAP_GET_TASKS_REQUEST: {
        const data = {
            tasksPageData: payload,
            progressList: true,
        };

        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    ...data,
                },
            };
        }

        return {
            ...state,
            ...data,
        };
    }
    case BFF_MAP_GET_CONTRACTORS_SUCCESS: {
        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    contractors: state.inviteModalData.contractorsPageData.isLoadMore ?
                        state.inviteModalData.contractors.concat(payload.results) :
                        payload.results,
                    contractorsTotalCount: payload.totalCount,
                    progressList: false,
                },
            };
        }

        return {
            ...state,
            contractors: state.contractorsPageData.isLoadMore ?
                state.contractors.concat(payload.results) :
                payload.results,
            contractorsTotalCount: payload.totalCount,
            progressList: false,
        };
    }
    case BFF_MAP_GET_ORDERS_SUCCESS: {
        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    orders: state.inviteModalData.ordersPageData.isLoadMore ?
                        state.inviteModalData.orders.concat(payload.results) :
                        payload.results,
                    ordersTotalCount: payload.totalCount,
                    progressList: false,
                },
            };
        }

        return {
            ...state,
            orders: state.ordersPageData.isLoadMore ?
                state.orders.concat(payload.results) :
                payload.results,
            ordersTotalCount: payload.totalCount,
            progressList: false,
        };
    }
    case BFF_MAP_GET_ADVERTS_SUCCESS: {
        return {
            ...state,
            adverts: state.advertsPageData.isLoadMore ?
                state.adverts.concat(payload.results) :
                payload.results,
            advertsTotalCount: payload.totalCount,
            progressList: false,
        };
    }
    case BFF_MAP_GET_TASKS_SUCCESS: {
        if (payload.isInviteModal) {
            return {
                ...state,
                inviteModalData: {
                    ...state.inviteModalData,
                    tasks: state.inviteModalData.tasksPageData.isLoadMore ?
                        state.inviteModalData.tasks.concat(payload.results) :
                        payload.results,
                    tasksTotalCount: payload.totalCount,
                    progressList: false,
                },
            };
        }

        return {
            ...state,
            tasks: state.tasksPageData.isLoadMore ?
                state.tasks.concat(payload.results) :
                payload.results,
            tasksTotalCount: payload.totalCount,
            progressList: false,
        };
    }
    case BFF_MAP_GET_CONTRACTORS_ERROR:
    case BFF_MAP_GET_ORDERS_ERROR:
    case BFF_MAP_GET_ADVERTS_ERROR:
    case BFF_MAP_GET_TASKS_ERROR: {
        return {
            ...state,
            inviteModalData: {
                ...state.inviteModalData,
                progressList: false,
            },
            progressList: false,
        };
    }
    case BFF_MAP_GET_LIST_PAGE_COUNTS_SUCCESS: {
        return {
            ...state,
            counts: payload,
        };
    }
    case BFF_MAP_EXPORT_REQUEST:
    case BFF_MAP_SEND_INVITE_TASK_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_MAP_EXPORT_SUCCESS:
    case BFF_MAP_EXPORT_ERROR:
    case BFF_MAP_SEND_INVITE_TASK_SUCCESS:
    case BFF_MAP_SEND_INVITE_TASK_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case BFF_MAP_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};