import React from "react";

import bem from "../../../../../../../utils/bem";

import "./style.sass";

const OrderEditFormPublishLabelText = (props) => {
    const {
        className,
        label,
        text,
    } = props;
    const [block] = bem("order-edit-form-publish-label-text", className);

    return (
        <div className={block()}>
            <div className="order-edit-form-publish-label-text__label">
                {label}
            </div>
            <div className="order-edit-form-publish-label-text__p">
                {text}
            </div>
        </div>
    );
};

export default OrderEditFormPublishLabelText;