import React, {Component} from "react";
import {Route, Switch} from "react-router";

import DepartmentFederalMigrationList from "../../department-federal-migration/department-federal-migration-list";
import SettingsDirectoriesBanks from "../directories/banks";
import SettingsDirectoriesCategorySpecialities from "../directories/category-specialities";
import SettingsDirectoriesFlashCall from "../directories/flash-call";
import KeywordsPage from "../directories/keywords";
import SettingsDirectoriesOrderCategoryList from "../directories/order-category-list";
import SettingsDirectoriesSpecialities from "../directories/specialities";
import {SpecialtyOfferContainer} from "../directories/speciality-offer-container";
import SettingsDirectoriesStopWords from "../directories/stop-words";
import SettingsDirectoriesTarrifGrid from "../directories/tariff-grid";
import SettingsDirectoriesWithdrawalCommission from "../directories/withdrawal-commission";

import {
    LINK_SETTINGS_DEPARTMENT_FEDERAL_MIGRATION_LIST,
    LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES,
    LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
    LINK_SETTINGS_DIRECTORIES_BANKS_SBP,
    LINK_SETTINGS_DIRECTORIES_CATEGORY_SPECIALITIES,
    LINK_SETTINGS_DIRECTORIES_FLASH_CALL,
    LINK_SETTINGS_DIRECTORIES_TARRIF_GRID,
    LINK_SETTINGS_DIRECTORIES_WITHDRAWAL_COMMISSION,
    LINK_SETTINGS_DOCUMENT_TYPES,
    LINK_SETTINGS_KEYWORDS,
    LINK_SETTINGS_ORDER_CATEGORIES,
    LINK_SETTINGS_SPECIALTY_LIST,
    LINK_SETTINGS_SPECIALTY_OFFER,
    LINK_SETTINGS_STATEMENT_TYPES,
    LINK_SETTINGS_STOP_WORDS,
} from "../../../constants/links";

import SettingsDirectoriesDocumentTypesContainer from "../directories/document-types-container";
import SettingsDirectoriesStatementTypesContainer from "../directories/statement-types-container";

class DirectoriesRoute extends Component {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={LINK_SETTINGS_KEYWORDS}
                    component={KeywordsPage}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_ORDER_CATEGORIES}
                    component={SettingsDirectoriesOrderCategoryList}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_SPECIALTY_LIST}
                    component={SettingsDirectoriesSpecialities}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DEPARTMENT_FEDERAL_MIGRATION_LIST}
                    component={DepartmentFederalMigrationList}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_SPECIALTY_OFFER}
                    component={SpecialtyOfferContainer}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DOCUMENT_TYPES}
                    component={SettingsDirectoriesDocumentTypesContainer}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_STATEMENT_TYPES}
                    component={SettingsDirectoriesStatementTypesContainer}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_STOP_WORDS}
                    component={SettingsDirectoriesStopWords}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_TARRIF_GRID}
                    component={SettingsDirectoriesTarrifGrid}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_CATEGORY_SPECIALITIES}
                    component={SettingsDirectoriesCategorySpecialities}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS}
                    component={SettingsDirectoriesBanks}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_BANKS_SBP}
                    component={SettingsDirectoriesBanks}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES}
                    component={SettingsDirectoriesBanks}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_FLASH_CALL}
                    component={SettingsDirectoriesFlashCall}
                />
                <Route
                    exact
                    path={LINK_SETTINGS_DIRECTORIES_WITHDRAWAL_COMMISSION}
                    component={SettingsDirectoriesWithdrawalCommission}
                />
            </Switch>
        );
    }
}

export default DirectoriesRoute;