import {formatFinanceListAmount} from "./formatAmount";

import {ORDER_WORK_REPORT_MIN_BANK_COMMISSION} from "../../../../constants/orderWorkReport";

export const getFinanceListNdflCommission = (payment) => {
    const {
        legalEntityCommissionAmount,
        currentCommissionRate,
        legalEntityCommissionTotalAmount,
        minimalNdflCommissionAmount,
    } = payment;

    if (legalEntityCommissionTotalAmount <= minimalNdflCommissionAmount) {
        return [
            {
                bold: true,
                text: formatFinanceListAmount(minimalNdflCommissionAmount),
            },
        ];
    }

    const commissionClient = formatFinanceListAmount(legalEntityCommissionAmount);
    const rate = formatFinanceListAmount(currentCommissionRate * 100);
    const commissionClientWithPercent = `${commissionClient}(${rate}%)`;

    return [
        {
            bold: true,
            text: commissionClientWithPercent,
        },
    ];
};