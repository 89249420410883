import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import CardApp from "../../../../components/CardApp";
import {CitizenshipWithFlag} from "../../../../components/CitizenshipWithFlag";
import LabelTextApp from "../../../../components/LabelTextApp";

import useFormData from "./hooks/useFormData";
import useReadFormError from "./hooks/useReadFormError";

import dateFormat, {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {
    phoneFormat,
} from "../../../../utils/stringFormat";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {
    CONTRACTOR_FIELD,
    EDM_PASSPORT_TYPE,
    EDM_UA_PASSPORT_TYPE,
    EDM_UA_PASSPORT_TYPE_OPTIONS,
} from "../../../../constants/contractor";
import {
    CONTRACTOR_CARD_SUB_BLOCK_FIELDS,
    CONTRACTOR_SUB_BLOCK_NAME,
    contractorMask,
    GENDER,
    GENDER_OPTIONS,
} from "../../../../constants/contractorInfo";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    contractorCardSelector,
    getCitizenshipWithFlagsOptionsSelector,
    getContractorPassportData,
    pendingPersonalDataSelector,
} from "../../../../ducks/contractor";
import {getPassportTypeDictSelector, getPassportTypeOptionsSelector} from "../../../../ducks/dict";

import "./style.sass";

const ContractorFullPersonalData2 = (props) => {
    const {
        isEditable,
        isClientRole,
        className,
    } = props;

    const {t} = useTranslation();

    const card = useSelector(contractorCardSelector);
    const citizenshipOptions = useSelector(getCitizenshipWithFlagsOptionsSelector);
    const passportData = useSelector(getContractorPassportData);
    const passportTypeDict = useSelector(getPassportTypeDictSelector);
    const passportTypeOptions = useSelector(getPassportTypeOptionsSelector);
    const pendingPersonalData = useSelector(pendingPersonalDataSelector);

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isConfirmChangePhoneModalShown,
        setIsConfirmChangePhoneModalShown,
        confirmChangePhoneModalText,
        isEdit,
        getError,
        cancelEdit,
        toggleCard,
        handleConfirmChangePhone,
    } = useFormData(card);

    const {
        errorsReadOnlyCard,
    } = useReadFormError(values,card);

    const localizationData = {
        title: t("contractor-full-personal-data.title"),
        lastName: t("contractor-full-personal-data.last-name"),
        firstName: t("contractor-full-personal-data.first-name"),
        patronymic: t("contractor-full-personal-data.patronymic"),
        birthDate: t("contractor-full-personal-data.birth-date"),
        gender: t("contractor-full-personal-data.gender"),
        actualAddress: values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.RU.value ? t("contractor-full-personal-data.actual-address") : t("contractor-full-personal-data.place-residence"),
        notSpecified: t("is-not-specified.content"),
        email: "E-mail",
        inn: t("contractor-full-personal-data.inn"),
        phoneNumber: t("contractor-full-personal-data.phone-number"),
        bankcardNumber: t("contractor-payment-data.bankcard-number"),
        sendBankNumberCard: t("contractor-payment-data.send-bank-number-card"),
        checkBankStatus: t("contractor-finance-info.check-bank-status"),
        registeredBank: t("contractor-payment-data.registered-bank"),
        notRegisteredBank: t("contractor-payment-data.not-registered-bank"),
        cardNotAdded: t("contractor-payment-data.card-not-added"),
        contractorDocumentIdBankText: t("contractor-payment-data.contractor-document-Bank"),
        fix: t("contractor-payment-data.fix"),
        checkingBankStatus: t("contractor-payment-data.checking-bank-status"),
        placeBirth: t("contractor-passport-data.place-birth"),
        idDocNum: t("contractor-passport-data.passport-series-number"),
        idDocIssuedBy: t("contractor-passport-data.passport-issue-by"),
        idDocIssuedDate: t("contractor-passport-data.migration-card-date-issue"),
        idDocIssuedDepartmentCode: t("contractor-passport-data.passport-unit-code"),
        idDocValidToDate: t("contractor-passport-data.id-doc-valid-to-date"),
        passportNumber: t("contractor-passport-data.passport-number"),
        passportType: t("contractor-passport-data.passport-type"),
        ein: t("contractor-passport-data.ein"),
    };

    const isUzOrTjOrUa = [citizenshipsDict.UZ.value, citizenshipsDict.UA.value, citizenshipsDict.TJ.value].includes(values[CONTRACTOR_FIELD.CITIZENSHIP]);
    const isTjOrUa = [citizenshipsDict.UA.value, citizenshipsDict.TJ.value].includes(values[CONTRACTOR_FIELD.CITIZENSHIP]);
    const isKgOrKz = [citizenshipsDict.KG.value, citizenshipsDict.KZ.value].includes(values[CONTRACTOR_FIELD.CITIZENSHIP]);
    const isRu = citizenshipsDict.RU.value === values[CONTRACTOR_FIELD.CITIZENSHIP];
    const isUa = citizenshipsDict.UA.value === values[CONTRACTOR_FIELD.CITIZENSHIP];
    const isUz = citizenshipsDict.UZ.value === values[CONTRACTOR_FIELD.CITIZENSHIP];

    const role = ls(USER_ROLE);

    const classNameCard = () => {
        const {
            data,
        } = pendingPersonalData;

        const {personalData} = data || {};

        if (isEmpty(personalData)) {
            return className;
        }

        if (!hasAllPendingPersonalData()) {
            return className;
        }

        return `full-personal-data__card full-personal-data__card_border ${className}`;
    };

    const classNameSubBlock = (firstSubBlockName, secondSubBlockName) => {
        const {
            data,
        } = pendingPersonalData;

        const {pendingPersonalDataFields = []} = data || {};

        if (isEmpty(pendingPersonalDataFields)) {
            return "";
        }

        if (hasAllPendingPersonalData()) {
            return "";
        }

        if (getHasPendingSubBlock(firstSubBlockName, secondSubBlockName)) {
            return "full-personal-data__divider_yellow";
        }

        return "";
    };

    const renderText = (value) => {
        return value || localizationData.notSpecified;
    };

    const hasAllPendingPersonalData = () => {
        const hasGeneralPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.GENERAL);
        const hasContractPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.CONTRACT_INFO);
        const hasTaxDataPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.TAX_DATA);
        const hasPassportInfoPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.PASSPORT_INFO);
        const hasNumberInfoPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.NUMBER_INFO);
        const hasAdditionalPassportInfoPendingPersonalData = getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.ADDITIONAL_PASSPORT_INFO);
        const hasPaymentDataPendingPersonalData = isUzOrTjOrUa ? true : getHasPendingSubBlock(CONTRACTOR_SUB_BLOCK_NAME.PAYMENT_DATA);

        return hasGeneralPendingPersonalData &&
            hasContractPendingPersonalData &&
            hasTaxDataPendingPersonalData &&
            hasPassportInfoPendingPersonalData &&
            hasNumberInfoPendingPersonalData &&
            hasAdditionalPassportInfoPendingPersonalData &&
            hasPaymentDataPendingPersonalData;
    };

    const getHasPendingSubBlock = (subBlockName, secondSubBlockName) => {
        const {
            data,
        } = pendingPersonalData;

        const citizenship = passportData[CONTRACTOR_FIELD.CITIZENSHIP] || values[CONTRACTOR_FIELD.CITIZENSHIP];

        const {pendingPersonalDataFields = []} = data || {};
        const subBlockFieldsByCitizenship = CONTRACTOR_CARD_SUB_BLOCK_FIELDS[citizenship];
        const subBlockFields = secondSubBlockName ? [...subBlockFieldsByCitizenship[subBlockName], ...subBlockFieldsByCitizenship[secondSubBlockName]] : subBlockFieldsByCitizenship[subBlockName];

        return subBlockFields.some(field => pendingPersonalDataFields.includes(field));
    };

    const renderSubmissionDateTime = () => {
        const {data} = pendingPersonalData;

        if (!data) {
            return null;
        }

        const {submissionDateTime} = data;

        if (!submissionDateTime) {
            return null;
        }

        return (
            submissionDateTime &&
            <div className="full-personal-data__submission-date-time">
                Последнее изменение 
                {" "}
                {formatLocalDate(submissionDateTime)}
            </div>
        );
    };

    const renderPassportType = (options, field) => {
        return (
            <NmDropdownV2
                size="lg"
                className="col-md-16"
                label={localizationData.passportType}
                required
                name={field}
                value={values[field]}
                options={options}
                onChange={(e, {name, value}) => setFieldValue(name, value)}
                onBlur={() => setFieldTouched(field, true)}
                error={getError(field)}
            />
        );
    };

    const renderPassportDataCardReadOnly = () => {
        const dict = isTjOrUa ? EDM_UA_PASSPORT_TYPE : EDM_PASSPORT_TYPE;
        const passportType = passportData[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE] || passportData[CONTRACTOR_FIELD.PASSPORT_TYPE];

        const {TEXT: EDM_PASSPORT_TYPE_TEXT} = dict[passportType] || {};

        return (
            <>
                <div className="row">
                    <div className="col-md-16 passport-data__item">
                        <CitizenshipWithFlag
                            textLevel="4"
                            citizenship={passportData[CONTRACTOR_FIELD.CITIZENSHIP]}
                        />
                    </div>
                </div>
                {isKgOrKz &&
                <div className="row passport-data__item">
                    <LabelTextApp
                        className="col-md-16 passport-data__item"
                        label={localizationData.passportType}
                        text={renderText(passportTypeDict[passportData[CONTRACTOR_FIELD.PASSPORT_TYPE]])}
                    />
                </div>
                }
                {isTjOrUa &&
                <div className="row passport-data__item">
                    <LabelTextApp
                        className="col-md-16"
                        label={localizationData.passportType}
                        text={renderText(EDM_PASSPORT_TYPE_TEXT)}
                    />
                </div>
                }
                <LabelTextApp
                    className="passport-data__item"
                    label={localizationData.placeBirth}
                    text={renderText(passportData[CONTRACTOR_FIELD.BIRTH_PLACE])}
                />
                <div
                    className={`full-personal-data__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.PASSPORT_INFO, CONTRACTOR_SUB_BLOCK_NAME.NUMBER_INFO)}`}
                />
                {renderNumberReadOnly()}
                <LabelTextApp
                    className="passport-data__item"
                    label={localizationData.idDocIssuedBy}
                    text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY])}
                />
                <div
                    className={`full-personal-data__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.NUMBER_INFO, CONTRACTOR_SUB_BLOCK_NAME.ADDITIONAL_PASSPORT_INFO)}`}
                />
                <div className="row passport-data__item align-items-end">
                    <LabelTextApp
                        className="col-16 col-md-8"
                        label={localizationData.idDocIssuedDate}
                        text={renderText(dateFormat(passportData[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE], "dd.MM.yyyy"))}
                    />
                    {isRu ?
                        <LabelTextApp
                            className="col-16 col-md-8 mt-2 mt-md-0"
                            label={localizationData.idDocIssuedDepartmentCode}
                            text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE])}
                        /> :
                        <LabelTextApp
                            className="col-16 col-md-8 mt-2 mt-md-0"
                            label={localizationData.idDocValidToDate}
                            text={renderText(dateFormat(passportData[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE], "dd.MM.yyyy"))}
                        />
                    }
                </div>
            </>
        );
    };

    const renderNumberReadOnly = () => {
        if (passportData[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.RU.value) {
            return (
                <LabelTextApp
                    className="contractor-form-card__item"
                    label={localizationData.idDocNum}
                    text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_NUMBER])}
                />
            );
        }

        if (passportData[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.AM.value) {
            return (
                <LabelTextApp
                    className="contractor-form-card__item"
                    label={localizationData.passportNumber}
                    text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_NUMBER])}
                />
            );
        }

        if (isTjOrUa) {
            const number = <LabelTextApp
                className="contractor-form-card__item"
                label={localizationData.passportNumber}
                text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_NUMBER])}
            />;
            if (passportData[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE] === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE) {
                return (
                    <>
                        {number}
                        <LabelTextApp
                            className="contractor-form-card__item"
                            label={localizationData.ein}
                            text={renderText(passportData[CONTRACTOR_FIELD.EIN])}
                        />
                    </>
                );
            }
            return (
                <>
                    {number}
                    {isUa && <LabelTextApp
                        className="contractor-form-card__item"
                        label={localizationData.ein}
                        text={renderText(passportData[CONTRACTOR_FIELD.EIN])}
                    />}
                </>
            );
        }

        if (isUz) {
            return <LabelTextApp
                className="contractor-form-card__item"
                label={localizationData.passportNumber}
                text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_NUMBER])}
            />;
        }

        return (
            <>
                <LabelTextApp
                    className="contractor-form-card__item"
                    label={localizationData.ein}
                    text={renderText(passportData[CONTRACTOR_FIELD.EIN])}
                />
                <LabelTextApp
                    className="contractor-form-card__item"
                    label={localizationData.passportNumber}
                    text={renderText(passportData[CONTRACTOR_FIELD.ID_DOC_NUMBER])}
                />
            </>
        );
    };

    const renderCardReadOnly = () => {
        return (
            <>
                <LabelTextApp
                    className="full-personal-data__item"
                    label={localizationData.lastName}
                    text={renderText(card[CONTRACTOR_FIELD.LAST_NAME])}
                />
                <LabelTextApp
                    className="full-personal-data__item"
                    label={localizationData.firstName}
                    text={renderText(card[CONTRACTOR_FIELD.FIRST_NAME])}
                />
                {card[CONTRACTOR_FIELD.PATRONYMIC] ? <LabelTextApp
                    className="full-personal-data__item"
                    label={localizationData.patronymic}
                    text={card[CONTRACTOR_FIELD.PATRONYMIC]}
                /> : null}
                <LabelTextApp
                    className="full-personal-data__item"
                    label={localizationData.birthDate}
                    text={renderText(dateFormat(card[CONTRACTOR_FIELD.BIRTH_DATE], "dd.MM.yyyy"))}
                />
                <LabelTextApp
                    className="full-personal-data__item"
                    label={localizationData.gender}
                    text={renderText(GENDER[card[CONTRACTOR_FIELD.GENDER]])}
                />
                <div className={`full-personal-data__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.GENERAL, CONTRACTOR_SUB_BLOCK_NAME.CONTRACT_INFO)}`} />
                {
                    citizenshipsDict.RU.value === card[CONTRACTOR_FIELD.CITIZENSHIP] &&
                    <LabelTextApp
                        className="full-personal-data__item"
                        label={localizationData.actualAddress}
                        text={renderText(card[CONTRACTOR_FIELD.ADDRESS])}
                    />
                }
                {
                    citizenshipsDict.KG.value === card[CONTRACTOR_FIELD.CITIZENSHIP] &&
                    <LabelTextApp
                        className="full-personal-data__item"
                        label={localizationData.actualAddress}
                        text={renderText(card[CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS])}
                    />
                }
                {
                    !isClientRole &&
                    <>
                        <div className="full-personal-data__input-phone-wrapper full-personal-data__item">
                            <LabelTextApp
                                label={localizationData.phoneNumber}
                                text={renderText(phoneFormat(card[CONTRACTOR_FIELD.PHONE]))}
                            />
                        </div>
                        <LabelTextApp
                            className="full-personal-data__item"
                            label={localizationData.email}
                            text={renderText(card[CONTRACTOR_FIELD.EMAIL])}
                        />
                    </>
                }
                {
                    (
                        citizenshipsDict.KG.value === card[CONTRACTOR_FIELD.CITIZENSHIP] ||
                        citizenshipsDict.RU.value === card[CONTRACTOR_FIELD.CITIZENSHIP] ||
                        !isClientRole
                    ) &&
                    <div className={`full-personal-data__divider ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.TAX_DATA, CONTRACTOR_SUB_BLOCK_NAME.CONTRACT_INFO)}`} />
                }
                <LabelTextApp
                    label={localizationData.inn}
                    text={renderText(card[CONTRACTOR_FIELD.INN])}
                />
                <div
                    className={`full-personal-data__divider full-personal-data__hide-desktop ${classNameSubBlock(CONTRACTOR_SUB_BLOCK_NAME.PASSPORT_INFO, CONTRACTOR_SUB_BLOCK_NAME.NUMBER_INFO)}`}
                />
            </>
        );
    };

    const renderNumberEdit = () => {
        if (isRu) {
            return (
                <NmInputV2
                    size="lg"
                    required
                    label={localizationData.idDocNum}
                    mask="9999 999999"
                    maskChar={null}
                    className="col-md-16"
                    name={CONTRACTOR_FIELD.ID_DOC_NUMBER}
                    value={values[CONTRACTOR_FIELD.ID_DOC_NUMBER]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getError(CONTRACTOR_FIELD.ID_DOC_NUMBER)}
                />
            );
        }

        if (values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.AM.value) {
            return (
                <NmInputV2
                    size="lg"
                    required
                    label={localizationData.passportNumber}
                    className="col-md-16"
                    name={CONTRACTOR_FIELD.ID_DOC_NUMBER}
                    value={values[CONTRACTOR_FIELD.ID_DOC_NUMBER]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getError(CONTRACTOR_FIELD.ID_DOC_NUMBER)}
                />
            );
        }

        if (isTjOrUa) {
            if (values[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE] === EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE) {
                return (
                    <>
                        {isUa && <NmInputV2
                            size="lg"
                            label={localizationData.ein}
                            className="col-md-16 full-personal-data__item"
                            name={CONTRACTOR_FIELD.EIN}
                            value={values[CONTRACTOR_FIELD.EIN]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getError(CONTRACTOR_FIELD.EIN)}
                        />}
                        <NmInputV2
                            size="lg"
                            required
                            label={localizationData.passportNumber}
                            className="col-md-16"
                            name={CONTRACTOR_FIELD.ID_DOC_NUMBER}
                            value={values[CONTRACTOR_FIELD.ID_DOC_NUMBER]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getError(CONTRACTOR_FIELD.ID_DOC_NUMBER)}
                        />
                    </>
                );
            }
            return (
                <>
                    <NmInputV2
                        size="lg"
                        required
                        label={localizationData.ein}
                        className="col-md-16 full-personal-data__item"
                        name={CONTRACTOR_FIELD.EIN}
                        value={values[CONTRACTOR_FIELD.EIN]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getError(CONTRACTOR_FIELD.EIN)}
                    />
                    <NmInputV2
                        size="lg"
                        required
                        label={localizationData.passportNumber}
                        className="col-md-16"
                        name={CONTRACTOR_FIELD.ID_DOC_NUMBER}
                        value={values[CONTRACTOR_FIELD.ID_DOC_NUMBER]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getError(CONTRACTOR_FIELD.ID_DOC_NUMBER)}
                    />
                </>
            );
        }

        if (isUz) {
            return (
                <NmInputV2
                    size="lg"
                    required
                    label={localizationData.passportNumber}
                    className="col-md-16"
                    name={CONTRACTOR_FIELD.ID_DOC_NUMBER}
                    value={values[CONTRACTOR_FIELD.ID_DOC_NUMBER]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getError(CONTRACTOR_FIELD.ID_DOC_NUMBER)}
                />
            );
        }


        return (
            <>
                <NmInputV2
                    size="lg"
                    label={localizationData.ein}
                    className="col-md-16 full-personal-data__item"
                    name={CONTRACTOR_FIELD.EIN}
                    value={values[CONTRACTOR_FIELD.EIN]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getError(CONTRACTOR_FIELD.EIN)}
                />
                <NmInputV2
                    size="lg"
                    required
                    label={localizationData.passportNumber}
                    className="col-md-16"
                    name={CONTRACTOR_FIELD.ID_DOC_NUMBER}
                    value={values[CONTRACTOR_FIELD.ID_DOC_NUMBER]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getError(CONTRACTOR_FIELD.ID_DOC_NUMBER)}
                />
            </>
        );
    };
    const renderPassportDataCardEdit = () => {
        return (
            <div className="fluid no-padding-container">
                <div className="row full-personal-data__item">
                    <NmDropdownV2
                        size="lg"
                        className="col-md-16"
                        required
                        name={CONTRACTOR_FIELD.CITIZENSHIP}
                        value={values[CONTRACTOR_FIELD.CITIZENSHIP]}
                        options={citizenshipOptions}
                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                        onBlur={() => setFieldTouched(CONTRACTOR_FIELD.CITIZENSHIP, true)}
                        error={getError(CONTRACTOR_FIELD.CITIZENSHIP)}
                    />
                </div>
                {isKgOrKz &&
                <div className="row passport-data__item">
                    {renderPassportType(passportTypeOptions, CONTRACTOR_FIELD.PASSPORT_TYPE)}
                </div>
                }
                {isTjOrUa &&
                <div className="row passport-data__item">
                    {renderPassportType(EDM_UA_PASSPORT_TYPE_OPTIONS, CONTRACTOR_FIELD.EDM_PASSPORT_TYPE)}
                </div>
                }
                <NmDadataInput
                    size="lg"
                    className="col-md-16"
                    label={localizationData.placeBirth}
                    query={values[CONTRACTOR_FIELD.BIRTH_PLACE]}
                    onChange={({value} ) => setFieldValue(CONTRACTOR_FIELD.BIRTH_PLACE, value)}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.BIRTH_PLACE, true)}
                    error={getError(CONTRACTOR_FIELD.BIRTH_PLACE)}
                />
                <div className="full-personal-data__divider" />
                <div className="row passport-data__item">
                    {renderNumberEdit()}
                </div>
                <div className="full-personal-data__divider" />
                <div className="row full-personal-data__item">
                    <NmInputV2
                        size="lg"
                        required
                        label={localizationData.idDocIssuedBy}
                        className="col-md-16"
                        name={CONTRACTOR_FIELD.ID_DOC_ISSUED_BY}
                        value={values[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getError(CONTRACTOR_FIELD.ID_DOC_ISSUED_BY)}
                    />
                </div>
                <div className="row  full-personal-data__item">
                    <div className="col-16 col-md-8">
                        <NmDatePicker
                            size="lg"
                            dateFormatMask="99.99.9999"
                            label={localizationData.idDocIssuedDate}
                            maxDate={new Date()}
                            required
                            name={CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE}
                            selected={values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE, true)}
                            error={getError(CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE)}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-2 mt-md-0">
                        {
                            values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.RU.value ?
                                <NmInputV2
                                    size="lg"
                                    required
                                    label={localizationData.idDocIssuedDepartmentCode}
                                    mask="999-999"
                                    maskChar={null}
                                    name={CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE}
                                    value={values[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={getError(CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE)}
                                /> :
                                <NmDatePicker
                                    size="lg"
                                    dateFormatMask="99.99.9999"
                                    label={localizationData.idDocValidToDate}
                                    minDate={new Date()}
                                    required
                                    name={CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE}
                                    selected={values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]}
                                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE, true)}
                                    error={getError(CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE)}
                                />
                        }
                    </div>
                </div>
            </div>
        );
    };

    const renderCardEdit = () => {
        const isDisabled = !(role === ADMIN || role === NM_MANAGER);

        return (
            <div className="container-fluid no-padding-container">
                <NmInputV2
                    size="lg"
                    className="full-personal-data__item"
                    name={CONTRACTOR_FIELD.LAST_NAME}
                    value={values[CONTRACTOR_FIELD.LAST_NAME]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    label={localizationData.lastName}
                    error={getError(CONTRACTOR_FIELD.LAST_NAME)}
                />
                <NmInputV2
                    size="lg"
                    className="full-personal-data__item"
                    name={CONTRACTOR_FIELD.FIRST_NAME}
                    value={values[CONTRACTOR_FIELD.FIRST_NAME]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    label={localizationData.firstName}
                    error={getError(CONTRACTOR_FIELD.FIRST_NAME)}
                />
                <NmInputV2
                    size="lg"
                    className="full-personal-data__item"
                    name={CONTRACTOR_FIELD.PATRONYMIC}
                    value={values[CONTRACTOR_FIELD.PATRONYMIC]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    label={localizationData.patronymic}
                />
                <div className="row full-personal-data__item">
                    <div className="col-16 col-md-8">
                        <NmDatePicker
                            size="lg"
                            dateFormatMask="99.99.9999"
                            label={localizationData.birthDate}
                            maxDate={new Date()}
                            required
                            name={CONTRACTOR_FIELD.BIRTH_DATE}
                            selected={values[CONTRACTOR_FIELD.BIRTH_DATE]}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.BIRTH_DATE, true)}
                            error={getError(CONTRACTOR_FIELD.BIRTH_DATE)}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-2 mt-md-0">
                        <NmDropdownV2
                            size="lg"
                            label={localizationData.gender}
                            required
                            name={CONTRACTOR_FIELD.GENDER}
                            value={values[CONTRACTOR_FIELD.GENDER]}
                            options={GENDER_OPTIONS}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.GENDER, true)}
                            error={getError(CONTRACTOR_FIELD.GENDER)}
                        />
                    </div>
                </div>
                <div
                    className="full-personal-data__divider"
                />
                {
                    citizenshipsDict.RU.value === values[CONTRACTOR_FIELD.CITIZENSHIP] &&
                    <NmDadataInput
                        size="lg"
                        className="full-personal-data__item"
                        label={localizationData.actualAddress}
                        query={values[CONTRACTOR_FIELD.ADDRESS]}
                        onChange={({value} ) => setFieldValue(CONTRACTOR_FIELD.ADDRESS, value)}
                        onBlur={() => setFieldTouched(CONTRACTOR_FIELD.ADDRESS, true)}
                        error={getError(CONTRACTOR_FIELD.ADDRESS)}
                    />
                }
                {
                    citizenshipsDict.KG.value === card[CONTRACTOR_FIELD.CITIZENSHIP] &&
                    <NmDadataInput
                        size="lg"
                        className="full-personal-data__item"
                        label={localizationData.actualAddress}
                        query={values[CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS]}
                        onChange={({value} ) => setFieldValue(CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS, value)}
                        onBlur={() => setFieldTouched(CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS, true)}
                        error={getError(CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS)}
                    />
                }
                <div className="row full-personal-data__item">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="lg"
                            label={localizationData.phoneNumber}
                            required
                            mask={contractorMask.PHONE.RUS}
                            maskChar={null}
                            name={CONTRACTOR_FIELD.PHONE}
                            value={values[CONTRACTOR_FIELD.PHONE]}
                            disabled={isDisabled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getError(CONTRACTOR_FIELD.PHONE)}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-2 mt-md-0">
                        <NmInputV2
                            size="lg"
                            label={localizationData.email}
                            name={CONTRACTOR_FIELD.EMAIL}
                            value={values[CONTRACTOR_FIELD.EMAIL]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getError(CONTRACTOR_FIELD.EMAIL)}
                        />
                    </div>
                </div>
                <div className="full-personal-data__divider" />
                {
                    card[CONTRACTOR_FIELD.ADDED_VIA_ADMIN_UI] ?
                        <div className="row full-personal-data__item">
                            <NmInputV2
                                size="lg"
                                label={localizationData.inn}
                                mask="999999999999"
                                maskChar={null}
                                name={CONTRACTOR_FIELD.INN}
                                value={values[CONTRACTOR_FIELD.INN]}
                                className="col-md-8"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={getError(CONTRACTOR_FIELD.INN)}
                            />
                        </div> :
                        <LabelTextApp
                            label={localizationData.inn}
                            text={renderText(values[CONTRACTOR_FIELD.INN])}
                        />
                }
            </div>
        );
    };

    if (isConfirmChangePhoneModalShown) {
        return (
            <NmModal
                size="md"
                onClose={() => setIsConfirmChangePhoneModalShown(false)}
                footer={
                    <ApplyButtons
                        className="full-personal-data__apply-buttons"
                        submitBtnContent="Да"
                        cancelBtnContent="Отмена"
                        onClose={() => setIsConfirmChangePhoneModalShown(false)}
                        submit={handleConfirmChangePhone}
                    />
                }
            >
                {confirmChangePhoneModalText}
            </NmModal>
        );
    }

    return (
        <CardApp
            className={`contractor-form-card ${classNameCard()}`}
            isEditable={isEditable}
            classNameHeader="full-personal-data__header"
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
        >
            <NmForm className="full-personal-data__form row">
                <div className="col-16 col-xxl-8">
                    <div className="full-personal-data__title full-personal-data__title_column-in-mobile">
                        {localizationData.title}
                        {renderSubmissionDateTime()}
                    </div>
                    {isEdit ? renderCardEdit() : renderCardReadOnly()}
                </div>
                <div className="col-16 col-xxl-8 mt-4 mt-xxl-0">
                    <div className="full-personal-data__title">
                        {
                            isUz ? "Заграничный паспорт" : "Паспорт"
                        }
                        {errorsReadOnlyCard && <HelpTooltip
                            width={24}
                            height={24}
                            position="bottom-left"
                            hover
                            type={"error"}
                            className="full-personal-data__tooltip"
                            text={errorsReadOnlyCard}
                                               />}
                    </div>
                    {isEdit ? renderPassportDataCardEdit() : renderPassportDataCardReadOnly()}
                </div>
            </NmForm>
        </CardApp>
    );
};

export default ContractorFullPersonalData2;