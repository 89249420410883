export const CLIENT_USER_RESTRICTIONS_VARIABLE = {
    editDepositNpd: "editDepositNpd", //Возможность редактирования депозита НПД
    editDepositNdfl: "editDepositNdfl", //Возможность редактирования депозита НДФЛ
    accessCompanySettings: "accessCompanySettings", //Настройка параметров компании
    settingAutoSendPayment: "settingAutoSendPayment", //Настройка автоотправки платежей в банк
    accessOperatorReports: "accessOperatorReports", //Доступ к подразделу Отчеты оператора
    actionsOperatorReports: "actionsOperatorReports", //Редактирование отчётов оператора
    exportOperatorReportsDocx: "exportOperatorReportsDocx", //Скачивание отчётов оператора в docx
    accessBank: "accessBank", //Доступ к подразделу Финансы > Банк
    editInterestRateNpd: "editInterestRateNpd", //Редактирование процентной ставки комиссии компании по выплатам НПД
    depositNpdRefill: "depositNpdRefill", //Пополнение депозита компании по выплатам НПД
    depositNpdRefund: "depositNpdRefund", //Возврат депозита компании по выплатам НПД
    editInterestRateNDFL: "editInterestRateNDFL", //Редактирование процентной ставки комиссии компании по выплатам НДФЛ
    depositNDFLRefill: "depositNDFLRefill", //Пополнение депозита компании по выплатам НДФЛ
    depositNDFLRefund: "depositNDFLRefund", //Возврат депозита компании по выплатам НДФЛ
    paymentPatentCancel: "paymentPatentCancel", //Отмена оплаты патента со статусом "Платеж не выполнен"
    accessCanceledChecksFts: "accessCanceledChecksFts", //Доступ к подразделу Аннулированные чеки ФНС России
    accessSystemTransactions: "accessSystemTransactions", //Доступ к подразделу Финансы > Системные транзакции
    paymentNpdSolution: "paymentNpdSolution", //Подтверждение/Отклонение оплат НПД
    paymentNpdReceipt: "paymentNpdReceipt", //Создание и отправка Offline-чеков об оплате НПД
    paymentNpdReceiptCancel: "paymentNpdReceiptCancel", //Аннулирование чеков ФНС об оплате НПД
    paymentNDFLSolution: "paymentNDFLSolution", //Подтверждение/Отклонение оплат НДФЛ
    accessReconciliationReports: "accessReconciliationReports", //Доступ к подразделу Акты сверки
    actionsReconciliationReports: "actionsReconciliationReports", //Действия с формированием и удалением актов сверки
    exportReconciliationReportsDocx: "exportReconciliationReportsDocx", //Скачивание акт сверки в формате docx
    closeRequestCRM: "closeRequestCRM", //	Закрытие обращения в разделе  CRM КЦ, если пользоваться не является ответственным
    editParamSystemTickets: "editParamSystemTickets", //Изменение параметра "Системные тикеты"
};