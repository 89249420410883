import {useState} from "react";
import {useDispatch} from "react-redux";

import {
    deleteRecruitmentDirectoryFunnel,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

import {toastSuccess} from "../../../../../utils/toastHelper";

const useRecruitmentDirectoriesFunnelsAction = (props) => {
    const {
        clientId,
        fetchList,
    } = props;

    const [confirmData, setConfirmData] = useState({});
    const [isOpenEditForm, setOpenModalData] = useState(false);
    const [editModalData, setEditModalData] = useState({});

    const dispatch = useDispatch();

    const openEditModal = (data = {}) => {
        setEditModalData(data);
        setOpenModalData(true);
    };

    const closeEditModal = () => {
        setOpenModalData(false);
        setEditModalData({});
    };

    const openDeleteConfirm = (item) => {
        setConfirmData({
            content: `Вы действительно хотите удалить воронку статусов ${item.name}?`,
            onConfirm: () => onDelete(item),
        });
    };

    const onDelete = ({id}) => {
        dispatch(deleteRecruitmentDirectoryFunnel({
            id,
            onSuccess: () => {
                toastSuccess("Воронка статусов успешно удалена");
                onCloseConfirm();
                fetchList();
            },
        }));
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    return {
        confirmData,
        editModalData,
        isOpenEditForm,
        onCloseConfirm,
        openDeleteConfirm,
        openEditModal,
        closeEditModal,
    };
};

export default useRecruitmentDirectoriesFunnelsAction;