import React from "react";
import {Route, Switch, withRouter} from "react-router";

import {RecruitmentVacanciesDescription} from "./description";
import RecruitmentVacancyJobBoardList from "./job-board-list";

import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_JOB_BOARDS,
} from "../../../../constants/links";

const RecruitmentVacancyCardRoute = (props) => {
    return (
        <Switch>
            <Route
                path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION}
                component={RecruitmentVacanciesDescription}
            />
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_JOB_BOARDS}
                component={RecruitmentVacancyJobBoardList}
            />
        </Switch>
    );
};

export default withRouter(RecruitmentVacancyCardRoute);