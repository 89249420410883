export const BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_REQUEST = "BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_REQUEST";
export const BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_SUCCESS = "BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_SUCCESS";
export const BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_ERROR = "BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_ERROR";

export const BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_REQUEST = "BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_REQUEST";
export const BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_SUCCESS = "BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_SUCCESS";
export const BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_ERROR = "BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_ERROR";

export const BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST = "BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST";
export const BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS = "BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS";
export const BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_ERROR = "BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_ERROR";

export const BFF_SETTINGS_INSTRUCTIONS_KEDO_UPDATE_STORE = "BFF_SETTINGS_INSTRUCTIONS_KEDO_UPDATE_STORE";