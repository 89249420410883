import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmButton from "../../../../components/NmButton";
import {withPageData} from "../../../../components/withPageData";
import {Dimmer, Loader} from "semantic-ui-react";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {subscribeOnContractorsFormRule} from "../../../../constants/validationRules";

import {
    contractorsSubscriptionProgressSelector,
    subscribeToContractors,
} from "../../../../ducks/contractorsSubscription";

import PropTypes from "prop-types";

import "./style.sass";

class ContractorsSubscriptionBlock extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        orderId: PropTypes.string,
        filter: PropTypes.object,
    };

    constructor(props) {

        super(props);

        this.state = {
            formData: {},
            errorForm: {},
        };

        this.role = ls(USER_ROLE);
    };


    isFormValid() {
        const {
            formData,
        } = this.state;

        const errorForm = validate(formData, subscribeOnContractorsFormRule, "");

        this.setState({errorForm});

        return Object.values(errorForm).length === 0;
    };

    subscribe = () => {
        const {
            subscribeToContractors,
            filter,
            clientId,
            orderId,
        } = this.props;

        const {
            phoneFilter,
            addressFiasIdsFilter,
            citizenshipFilter,
            genderFilter,
            ageFromFilter,
            ageToFilter,
            fioFilter,
            workWithClient,
            remoteWork,
            specialityIdsFilter,
        } = filter;

        const {
            formData: {
                email,
            },
        } = this.state;

        if (!this.isFormValid()) {
            return;
        }

        subscribeToContractors({
            email,
            clientId,
            orderId,
            phone: handleFormString(removePhoneMask(phoneFilter)),
            addressFiasIds: addressFiasIdsFilter,
            remoteWork,
            citizenship: citizenshipFilter,
            gender: handleFormString(genderFilter),
            workWithClient,
            ageFrom: ageFromFilter,
            ageTo: ageToFilter,
            fio: handleFormString(fioFilter),
            specialityIds: specialityIdsFilter,
        });
    };

    get localizationData() {
        const {t} = this.props;

        return {
            email: t("contractors-subscription-form.email"),
            header: t("contractors-subscription-form.header"),
            subHeader: t("contractors-subscription-form.sub-header"),
            enterEmailText: t("contractors-subscription-form.enter-email"),
            subscribe: t("button.subscribe"),
        };
    };

    handleChangeFormData = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };

    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    renderPreloader() {
        const {
            t,
            progress,
        } = this.props;

        return (
            <Dimmer
                active={progress}
                inverted
            >
                <Loader content={t("loader.content")} />
            </Dimmer>
        );
    }

    render() {
        const {
            formData,
            errorForm,
        } = this.state;

        return (
            <div className="contractors-subscription-block">
                {this.renderPreloader()}
                <NmForm>
                    <div className="contractors-subscription-block__header">
                        {this.localizationData.header}
                    </div>
                    <div className="contractors-subscription-block__body">
                        <div
                            className="contractors-subscription-block__subheader"
                        >
                            {this.localizationData.subHeader}
                        </div>
                        <div className="contractors-subscription-block__row">
                            <NmInputV2
                                size="lg"
                                name={CONTRACTOR_FIELD.EMAIL}
                                onChange={this.handleChangeFormData}
                                error={errorForm[CONTRACTOR_FIELD.EMAIL]}
                                placeholder={this.localizationData.enterEmailText}
                                value={formData[CONTRACTOR_FIELD.EMAIL]}
                            />
                            <NmButton
                                className="contractors-subscription-block__button"
                                onClick={this.subscribe}
                            >
                                {this.localizationData.subscribe}
                            </NmButton>
                        </div>
                    </div>
                </NmForm>
            </div>
        );
    };
};

export default withPageData(connect(
    state => ({
        progress: contractorsSubscriptionProgressSelector(state),
    }),
    {
        subscribeToContractors,
    },
)(withTranslation()(ContractorsSubscriptionBlock)));