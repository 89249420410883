import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST, BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";

const controller = "adm/recruitment/vacancyCandidates";

// POST /bff/adm/recruitment/vacancyCandidates/{id}/responses
// Получить отклики кандидата
const getRecruitmentVacancyCandidatesResponses = function* ({payload}) {
    const {
        isLoadMore,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/responses`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
        });
    }
};

// DELETE /bff/adm/recruitment/vacancyCandidates/{candidateId}/responses/{responseId}
// Удалить связь отклика и кандидата
const deleteRecruitmentVacancyCandidatesResponse = function* ({payload}) {
    const {
        candidateId,
        responseId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${candidateId}/responses/${responseId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/vacancyCandidates/{candidateId}/responses/{responseId}
// Получить отклик кандидата
const getRecruitmentVacancyCandidatesResponse = function* ({payload}) {
    const {
        candidateId,
        responseId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${candidateId}/responses/${responseId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST, getRecruitmentVacancyCandidatesResponses),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST, deleteRecruitmentVacancyCandidatesResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST, getRecruitmentVacancyCandidatesResponse),
    ]);
}