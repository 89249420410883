import React from "react";
import {Route,Switch} from "react-router-dom";

import Tabs from "../../components/ActualComponents/Tabs";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import SmartLink from "../marketing/smart-link";
import {AdminMailingsList} from "./mailings-list";

import {getUserRole} from "../../utils/access";

import {LINK_MAILINGS_LIST, LINK_MARKETING_BUILDER} from "../../constants/links";
import {ADMIN, NM_MANAGER} from "../../constants/roles";

const Marketing = (props) => {
    const role = getUserRole();

    const tabs = [
        {
            name: "Рассылка уведомлений",
            link: LINK_MAILINGS_LIST,
        },
        {
            name: "Конструктор ссылок",
            link: LINK_MARKETING_BUILDER,
        },
    ];

    return (
        <NmPage
            header={
                <NmPageHeader text="Маркетинг" />
            }
            subHeader={
                [ADMIN, NM_MANAGER].includes(role) &&
                <Tabs panes={tabs} />
            }
        >
            <Switch>
                <Route
                    exact
                    path={LINK_MAILINGS_LIST}
                    component={AdminMailingsList}
                />
                <Route
                    exact
                    path={LINK_MARKETING_BUILDER}
                    component={SmartLink}
                />
            </Switch>
        </NmPage>
    );
};

export default Marketing;