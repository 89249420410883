import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getCrowdTaskContractorsCompleted,
    getCrowdTaskContractorsWorking,
    updateStoreCrowdTaskCard,
} from "../../../../../../ducks/bff/crowd/taskCard/actionCreators";

const useCrowdTaskCardInWorkPageFetch = (props) => {
    const {
        clientId,
        taskId,
        isCompletedPage,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdTaskCard({
                workingCard: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchInfo();
    }, [isCompletedPage]);

    const fetchInfo = () => {
        if (isCompletedPage) {
            dispatch(getCrowdTaskContractorsCompleted({
                clientId,
                taskId,
            }));

            return;
        }
        dispatch(getCrowdTaskContractorsWorking({
            clientId,
            taskId,
        }));
    };

    return {
        fetchInfo,
    };
};

export default useCrowdTaskCardInWorkPageFetch;