import {
    SMART_LINK_ARCHIVE_REQUEST,
    SMART_LINK_CACHED_CLIENTS_REQUEST,
    SMART_LINK_CHECK_ORDER_EXISTS_REQUEST,
    SMART_LINK_CLIENT_PART_SAVE_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST,
    SMART_LINK_CREATE_REQUEST,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST,
    SMART_LINK_DELETE_CACHED_CLIENT_REQUEST,
    SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST,
    SMART_LINK_FILTER_PART_SAVE_REQUEST,
    SMART_LINK_GET_CARD_REQUEST,
    SMART_LINK_GET_COUNT_BY_FILTER_REQUEST,
    SMART_LINK_GET_DETAILS_TREE_REQUEST,
    SMART_LINK_IMPORT_NUMBER_REQUEST,
    SMART_LINK_PAGE_REQUEST,
    SMART_LINK_UPDATE_REQUEST,
    SMART_LINK_UPDATE_STORE,
} from "./actions";

export const getSmartLinkPage = (payload) => {
    return {
        type: SMART_LINK_PAGE_REQUEST,
        payload,
    };
};

export const getSmartLinkCard = (payload) => {
    return {
        type: SMART_LINK_GET_CARD_REQUEST,
        payload,
    };
};


export const updateSmartLink = (payload) => {
    return {
        type: SMART_LINK_UPDATE_REQUEST,
        payload,
    };
};

export const importSmartLinkOrderNumbers = (payload) => {
    return {
        type: SMART_LINK_IMPORT_NUMBER_REQUEST,
        payload,
    };
};

export const checkSmartLinkOrderNumberExists = (payload) => {
    return {
        type: SMART_LINK_CHECK_ORDER_EXISTS_REQUEST,
        payload,
    };
};

export const getSmartLinkCountByFilter = (payload) => {
    return {
        type: SMART_LINK_GET_COUNT_BY_FILTER_REQUEST,
        payload,
    };
};

export const getSmartLinkClientProjects = (payload) => {
    return {
        type: SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST,
        payload,
    };
};

export const getSmartLinkClientObjects = (payload) => {
    return {
        type: SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST,
        payload,
    };
};

export const getSmartLinkObjectsProjectList = (payload) => {
    return {
        type: SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST,
        payload,
    };
};

export const getSmartLinkDetailsTree = (payload) => {
    return {
        type: SMART_LINK_GET_DETAILS_TREE_REQUEST,
        payload,
    };
};

export const archiveSmartLink = (payload) => {
    return {
        type: SMART_LINK_ARCHIVE_REQUEST,
        payload,
    };
};

export const saveSmartLinkFilterPart = (payload) => {
    return {
        type: SMART_LINK_FILTER_PART_SAVE_REQUEST,
        payload,
    };
};

export const editSmartLinkFilterPart = (payload) => {
    return {
        type: SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST,
        payload,
    };
};

export const saveSmartLinkClientPart = (payload) => {
    return {
        type: SMART_LINK_CLIENT_PART_SAVE_REQUEST,
        payload,
    };
};

export const createSmartLink = (payload) => {
    return {
        type: SMART_LINK_CREATE_REQUEST,
        payload,
    };
};

export const getSmartLinkCachedClients = (payload) => {
    return {
        type: SMART_LINK_CACHED_CLIENTS_REQUEST,
        payload,
    };
};

export const deleteSmartLinkCachedClient = (payload) => {
    return {
        type: SMART_LINK_DELETE_CACHED_CLIENT_REQUEST,
        payload,
    };
};

export const deleteSmartLinkAllCachedClients = (payload) => {
    return {
        type: SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST,
        payload,
    };
};

export const updateSmartLinkStore = (payload) => {
    return {
        type: SMART_LINK_UPDATE_STORE,
        payload,
    };
};