import {
    EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_MAILING,
    EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_SMART_LINK,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_REQUEST,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST,
} from "./actions";

export const getEventsContractorAggregatedParamsByMailing = (payload) => {
    return {
        type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_REQUEST,
        payload,
    };
};

export const clearEventsContractorAggregatedParamsByMailing = () => {
    return {
        type: EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_MAILING,
    };
};

export const getEventsContractorAggregatedParamsBySmartLink = (payload) => {
    return {
        type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST,
        payload,
    };
};

export const clearEventsContractorAggregatedParamsBySmartLink = () => {
    return {
        type: EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_SMART_LINK,
    };
};