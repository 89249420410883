import {useEffect, useState} from "react";

import {checkMigrationCard} from "../../controlDateReadOnly";

import {getDay, getStartDate} from "../../../../../utils/dateFormat";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

const useEditFormWarning = (values, card) => {
    const [warning, setWarning] = useState({});

    useEffect(() => {
        const passportDate = card[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const migrationCardDate = values[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE];

        if (new Date(migrationCardDate) < new Date(passportDate)) {
            setWarning( prevState => ({
                ...prevState,
                [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: "Возможно, Ваша миграционная карта устарела. Игнорируйте это сообщение, если у Вас есть основания не продлевать миграционную карту",
            }));

            return;
        }

        setWarning(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: undefined,
        }));

    }, [values]);

    useEffect(() => {
        const migrationCardDate = values[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE];
        const migrationCardDateEnd = values[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE];
        const milliseconds = (getStartDate(new Date(migrationCardDateEnd)) - getStartDate(new Date(migrationCardDate)));
        const templateError = "Возможно, некорректная дата окончания срока действия документа";
        const errorMessage = getDay(milliseconds) > DAYS_FOR_CHECK_MIGRATION_CARD ? templateError : checkMigrationCard({values, card});

        setWarning(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: errorMessage,
        }));
    }, [values]);

    return {
        warning,
        setWarning,
    };
};

export default useEditFormWarning;

const DAYS_FOR_CHECK_MIGRATION_CARD = 90;