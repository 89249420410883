import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        fullName: yup.string()
            .nullable()
            .max(50, "Допустимое количество символов 150")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};

export default validationSchema;