import React, {FC, ReactNode, useState} from "react";

import {ReactComponent as IconChevron} from "../../../../images/chevron-down.svg";
import Text from "../../Text";
import NmListCard, {TLabels} from "../Card";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";

import {TMediaButtonsConfig} from "../../MediaControls/types";

import "./style.sass";

interface INmListAccordionCard {
    className?: string,
    headerText?: string,
    labels?: TLabels,
    mediaControls?: TMediaButtonsConfig,
    defaultOpen?: boolean,
    children: ReactNode,
    disabled?: boolean,
}

const NmListAccordionCard: FC<INmListAccordionCard> = (props) => {
    const {
        className,
        headerText,
        labels,
        mediaControls,
        defaultOpen = false,
        children,
        disabled,
    } = props;

    const [block, element] = bem("nm-list-accordion-card", className);

    const [isShown, setShown] = useState<boolean>(defaultOpen);

    const renderPrimaryHeader = () => {
        return (
            <div
                className={element("header", {pointer: !disabled})}
                onClick={
                    disabled
                        ? undefined
                        : () => setShown(!isShown)
                }
            >
                {
                    !disabled &&
                    <IconChevron
                        width={20}
                        height={20}
                        className={element("icon", {open: isShown})}
                        color={COLOR.PASSIVE_100}
                    />
                }
                <Text
                    color={disabled ? COLOR.SECONDARY_100 : COLOR.PASSIVE_100}
                    noWrap={true}
                >
                    {headerText}
                </Text>
            </div>
        );
    };

    return (
        <div className={block()}>
            <NmListCard
                noDivider={true}
                primaryHeader={renderPrimaryHeader()}
                primaryHeaderNoWrap={true}
                isFixedActions={true}
                mediaControls={mediaControls}
                labels={labels}
            />
            {
                isShown &&
                <div className={element("content")}>
                    {children}
                </div>
            }
        </div>
    );
};

export default NmListAccordionCard;