import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";

import {useKedoDocumentTypesBff} from "../../../hooks/useBff";

import {getKedoDocumentTypesValidation} from "../utils/getValidation";

import {kedoDirectoriesProgressActionSelector} from "../../../../../../../ducks/kedo/directories/selectors";

export const useKedoDocumentTypesEdit = (params) => {
    const {
        fetchList,
        editData,
        onClose,
        clientId,
        fetchCounts,
    } = params;

    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const {
        addDocumentType,
        updateDocumentType,
    } = useKedoDocumentTypesBff();

    useEffect(() => {
        if (editData) {
            setValues({
                ...values,
                ...editData,
            });
        }
    }, []);

    const {
        handleSubmit,
        values,
        touched,
        setValues,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            name: "",
            externalId: "",
            clientId: "",
            availableClientUsers: true,
            onlyUkep: true,
        },
        validationSchema: getKedoDocumentTypesValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        const {
            name,
            externalId,
            clientId,
            availableClientUsers,
            onlyUkep,
        } = values;

        const requestData = {
            name,
            externalId,
            availableClientUsers,
            onlyUkep,
        };

        if (editData) {
            const {
                documentTypeId,
            } = editData;

            updateDocumentType({
                clientId: values.clientId,
                documentTypeId,
                ...requestData,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            });

            return;
        }

        addDocumentType({
            clientId,
            ...requestData,
            onSuccess: () => {
                fetchList();
                fetchCounts();
                onClose();
            },
        });
    }

    return {
        errors,
        handleSubmit,
        values,
        progressAction,
        onChange,
        touched,
    };
};