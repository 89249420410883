import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import {LoginButton} from "../button";

import {getEmailError} from "../../../utils/validate";

import {
    clearFieldStore,
    endRecoverySelector,
    passwordRecoveryErrorSelector,
    progressPasswordRecoverySelector,
    requestRecovery,
} from "../../../ducks/clientUsers";

import PropTypes from "prop-types";

import "./style.sass";

class LoginRecovery extends Component {
    static propTypes = {
        progressRecovery: PropTypes.bool,
        isCompleteRecovery: PropTypes.bool,
        requestRecovery: PropTypes.func,
        clearFieldStore: PropTypes.func,
    };

    static defaultProps = {
        progressRecovery: false,
        isCompleteRecovery: false,
        requestRecovery: () => {},
        clearFieldStore: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            error: undefined,
        };
    };

    componentWillUnmount() {
        const { clearFieldStore } = this.props;

        clearFieldStore({isCompleteRecovery: false, error: ""});
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            email,
            error,
        } = this.state;
        const {error: propsError} = this.props;
        const {email: _email} = prevState;

        if (email && email !== _email && (error || propsError)) {
            this.validate();

            if (propsError) {
                const {clearFieldStore} = this.props;

                clearFieldStore({error: ""});
            }
        }
    }

    handleChange = (event, {value}) => {
        this.setState({
            email: value,
        });
    };

    validate = () => {
        const {email} = this.state;

        const error = getEmailError(email);

        this.setState({
            error,
        });

        return error;
    };

    recoveryPassword = () => {
        const {email} = this.state;
        const {requestRecovery, progressRecovery} = this.props;

        if (progressRecovery) {
            return;
        }

        const error = this.validate();

        if (error) {
            return;
        }

        requestRecovery({email});
    };

    renderForm() {
        const {email, error} = this.state;
        const {t, error: _error, progressRecovery} = this.props;

        return (
            <>
                <NmInputV2
                    label={t("recovery-password.enter-name-msg")}
                    required
                    size="xl"
                    openNaimix
                    className="login-recovery__input"
                    value={email}
                    onChange={this.handleChange}
                    placeholder="Введите адрес почты"
                    error={error || _error}
                />
                <div className="d-flex justify-content-end mt-4 mt-md-5">
                    <LoginButton
                        size="xl"
                        className="login-recovery__button"
                        disabled={progressRecovery}
                        onClick={this.recoveryPassword}
                    >
                        {t("recovery-password.send-new-password-button")}
                    </LoginButton>
                </div>
            </>
        );
    }

    render() {
        const {
            close,
            isCompleteRecovery,
            t,
        } = this.props;

        return (
            <NmModal
                openNaimix
                header={
                    <div className="login-recovery__title">
                        {t("recovery-password.recovery-password-title")}
                    </div>
                }
                onClose={close}
                className="login-recovery"
            >
                <NmForm className="login-recovery__form">
                    {
                        !isCompleteRecovery ?
                            this.renderForm() :
                            <div className="login-recovery__p">
                                {t("recovery-password.message-sent")}
                            </div>
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        progressRecovery: progressPasswordRecoverySelector(state),
        isCompleteRecovery: endRecoverySelector(state),
        error: passwordRecoveryErrorSelector(state),
    }),
    {
        requestRecovery,
        clearFieldStore,
    },
)(withTranslation()(LoginRecovery));
