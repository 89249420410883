import React, {FC, useState} from "react";
import {Link} from "react-router-dom";

import {MarkerListModal} from "../MarkerListModal";
import Text from "../Text";

import {getClassNames} from "../../../utils/classNames";
import {isLastElementInArray} from "../../../utils/objectHelper";

import {COLOR} from "../../../constants/color";

import "./style.sass";

interface MarkerListProps {
    list: Array<MarkerListType>,
    classNameOffset?: string,
    maxVisibleElements?: number,
    modalHeader?: string,
    setIsHiddenModal?: (value: boolean) => void,
}

export type MarkerListType = {
    text: string,
    link?: string,
    children?: React.ReactNode,
}

export const MarkerList: FC<MarkerListProps> = (props) => {
    const {
        list,
        classNameOffset,
        maxVisibleElements,
        modalHeader,
        setIsHiddenModal,
    } = props;

    const [isOpenModal, setIsOpenModal] = useState(false);

    const getList = (slicedList?: Array<MarkerListType>) => {
        return (slicedList || list).map((item, index) => {
            const isLast = isLastElementInArray(list, index);

            return (
                <li
                    key={index}
                    className={
                        getClassNames([
                            "marker-list__item",
                            !isLast && classNameOffset,
                        ])
                    }
                >
                    <Text level="3">
                        {
                            item.link ?
                                <Link
                                    title="Перейти по ссылке"
                                    className="marker-list__link"
                                    to={item.link}
                                >
                                    {item.text}
                                </Link> :
                                item.text
                        }
                    </Text>
                    {
                        item.children &&
                        <div className="mt-1">
                            {item.children}
                        </div>
                    }
                </li>
            );
        });
    };

    const getContent = () => {
        if (typeof maxVisibleElements === "number" && list.length > maxVisibleElements) {
            return (
                <>
                    {getList(list.slice(0, maxVisibleElements))}
                    <Text
                        level="3"
                        color={COLOR.PASSIVE_100}
                        onClick={() => {
                            setIsOpenModal(true);

                            if (setIsHiddenModal) {
                                setIsHiddenModal(true);
                            }
                        }}
                        underline
                    >
                            Посмотреть все
                    </Text>
                    {
                        isOpenModal &&
                        <MarkerListModal
                            header={modalHeader}
                            list={list}
                            onClose={() => {
                                setIsOpenModal(false);

                                if (setIsHiddenModal) {
                                    setIsHiddenModal(false);
                                }
                            }}
                        />
                    }
                </>
            );
        }

        return getList();
    };

    return (
        <ul className="marker-list">
            {getContent()}
        </ul>
    );
};