import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getActRegistries} from "../../../../ducks/actRegistries";

export const useOrderActRegistryFetch = ({archived, pageNum, pageSize, filter, clientId}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        onFetchList();
    }, [pageNum, pageSize, filter, archived]);

    const onFetchList = () => {
        dispatch(getActRegistries({
            ...filter,
            clientId,
            archivedFilter: archived,
            pageNum,
            pageSize,
        }));
    };

    return {
        onFetchList,
    };
};