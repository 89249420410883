import {ORDER_CREATION_MODE} from "../../../../constants/order";

export const getOrderEditHeader = (params) => {
    const {
        creatingMode,
        editOrderId,
        isDuplicateOrder,
        t,
    } = params;

    if (creatingMode === ORDER_CREATION_MODE.TEMPLATE) {
        return editOrderId ?
            t("order-new.templateEdit") :
            t("order-new.templateCreating");
    }

    return editOrderId &&
    !isDuplicateOrder &&
    creatingMode !== ORDER_CREATION_MODE.FROM_TEMPLATE ?
        t("order-new.title-edit") :
        t("order-new.title-new");
};