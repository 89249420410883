import {useFormik} from "formik";

import {depositReturnValidationScheme} from "../validation";

import {getNumberFromFormattedAmount} from "../../../../utils/stringFormat";

function useDepositReturnForm(dependentData, isEdit, onEditDeposit, onReturnDeposit, close){
    const initialValues = {
        amount: dependentData.amount || "",
        comment: dependentData.comment || "",
        bankType: dependentData.bankType || null,
    };

    const {
        values,
        errors,
        isValid,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues,
        validationSchema: depositReturnValidationScheme,
        onSubmit: onSave,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    });

    function handleOnChange(e, {value, name}) {
        setFieldValue(name, value);
    }

    function onSave() {
        const {
            clientId,
            clientDepositHistoryId,
        } = dependentData;

        if (isValid) {
            const data = {
                ...values,
                amount: getNumberFromFormattedAmount(values.amount),
                clientId,
                onSuccess: () => {
                    close();
                },
            };

            isEdit ? onEditDeposit({...data, clientDepositHistoryId}) : onReturnDeposit(data);
        }
    }

    return {
        values,
        errors,
        isValid,
        setFieldValue,
        handleSubmit,
        handleOnChange,
    };
}

export default useDepositReturnForm;