import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";

const controller = "/mlmodels";
//*  TYPES  *//

const ML_MODELS_PREDICT_AMOUNT_REQUEST = "ML_MODELS_PREDICT_AMOUNT_REQUEST";
const ML_MODELS_PREDICT_AMOUNT_SUCCESS = "ML_MODELS_PREDICT_AMOUNT_SUCCESS";
const ML_MODELS_PREDICT_AMOUNT_ERROR = "ML_MODELS_PREDICT_AMOUNT_ERROR";

const ML_MODELS_STORE_CLEAR = "ML_MODELS_STORE_CLEAR";

//*  INITIAL STATE  *//

const initial = {
    predictAmount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case ML_MODELS_PREDICT_AMOUNT_SUCCESS:
        return {
            ...state,
            predictAmount: payload,
        };
    case ML_MODELS_STORE_CLEAR:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function predictMlmodelsAmount(payload) {
    return {
        type: ML_MODELS_PREDICT_AMOUNT_REQUEST,
        payload,
    };
}

export function updateMlmodelsStore(payload) {
    return {
        type: ML_MODELS_STORE_CLEAR,
        payload,
    };
}

//*  SELECTORS  *//
export const mlmodelsSelector = state => state.mlmodels;

export const mlmodelsPredictAmountSelector = createSelector(mlmodelsSelector, ({predictAmount}) => predictAmount);

//*  SAGA  *//

export const predictMlmodelsAmountSaga = function* (action) {
    try {
        const {payload} = action;

        const {predictAmount} = yield request.post(`${controller}/predictAmount`, payload);

        yield put({
            type: ML_MODELS_PREDICT_AMOUNT_SUCCESS,
            payload: predictAmount,
        });
    } catch (error) {
        yield put({
            type: ML_MODELS_PREDICT_AMOUNT_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(ML_MODELS_PREDICT_AMOUNT_REQUEST, predictMlmodelsAmountSaga),
    ]);
}
