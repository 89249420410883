import {
    BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_REQUEST,
    BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_REQUEST,
    UPDATE_FINANCES_RECEIPTS_CANCELLED_STORE,
} from "./actions";

export const getPageFinancesReceiptsCancelled = (payload) => {
    return {
        type: BFF_FINANCES_RECEIPTS_CANCELLED_GET_PAGE_REQUEST,
        payload,
    };
};

export const exportFinancesReceiptsCancelled = (payload) => {
    return {
        type: BFF_FINANCES_RECEIPTS_CANCELLED_EXPORT_REQUEST,
        payload,
    };
};

export const updateStoreFinancesReceiptsCancelled = (payload) => {
    return {
        type: UPDATE_FINANCES_RECEIPTS_CANCELLED_STORE,
        payload,
    };
};