import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FinancePivot from "../../../components/FinancePivot";

import {getFinancePivotTableHeaders} from "../../../components/FinancePivot/utils/getTableHeaders";

import {
    getSmzReportsSummary,
    getSmzReportsSummaryDepositHistory,
    smzReportsSummaryListSelector,
    smzReportsSummaryProgressSelector,
    smzReportsSummaryTotalCountSelector,
    smzReportsSummaryTotalPagesSelector,
    smzReportsSummaryTotalSummarySelector,
    updateSmzReportsSummaryStore} from "../../../ducks/smzReportsSummary";

const headers = getFinancePivotTableHeaders({defaultDateSortType: "desc"});

const FinancePivotSmz = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(smzReportsSummaryListSelector);
    const summary = useSelector(smzReportsSummaryTotalSummarySelector);
    const totalCount = useSelector(smzReportsSummaryTotalCountSelector);
    const totalPages = useSelector(smzReportsSummaryTotalPagesSelector);
    const progressDepositHistory = useSelector(smzReportsSummaryProgressSelector);

    useEffect(() => {
        return () => {
            dispatch(updateSmzReportsSummaryStore({
                reports: [],
                totalSummary: {},
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const fetchList = (data) => {
        dispatch(getSmzReportsSummary(data));
    };

    const onClickHistory = (data) => {
        dispatch(getSmzReportsSummaryDepositHistory(data));
    };

    return (
        <FinancePivot
            header="Сводная (НПД)"
            defaultDateSortType="DESC"
            isInitLoad={false}
            list={list}
            headers={headers}
            summary={summary}
            totalCount={totalCount}
            totalPages={totalPages}
            fetchList={fetchList}
            onClickHistory={onClickHistory}
            progressDepositHistory={progressDepositHistory}
            {...props}
        />
    );
};

export default FinancePivotSmz;