import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {isUserFromNm} from "../../../../../constants/roles";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../constants";

import {getDocumentCustomTemplatePage, updateDocumentCustomTemplateStore} from "../../../../../ducks/documentsCustomTemplate";

export const useTemplateContractsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        documentTypeFilter = TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
    } = params;
    const role = ls(USER_ROLE);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getDocumentCustomTemplatePage({
            ...filterData,
            inContextOfClientId: clientId,
            clientIdsFilter: isUserFromNm(role) && clientId ? [clientId] : filterData.clientIdsFilter,
            documentTypeFilter,
            externalDocumentFilter: documentTypeFilter === TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT ?
                filterData.externalDocumentFilter : undefined,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};