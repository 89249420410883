export const KEDO_DOCUMENTS_GET_PAGE_REQUEST = "KEDO_DOCUMENTS_GET_PAGE_REQUEST";
export const KEDO_DOCUMENTS_GET_PAGE_SUCCESS = "KEDO_DOCUMENTS_GET_PAGE_SUCCESS";
export const KEDO_DOCUMENTS_GET_PAGE_ERROR = "KEDO_DOCUMENTS_GET_PAGE_ERROR";

export const KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST = "KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST";
export const KEDO_DOCUMENTS_GET_HISTORY_PAGE_SUCCESS = "KEDO_DOCUMENTS_GET_HISTORY_PAGE_SUCCESS";
export const KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR = "KEDO_DOCUMENTS_GET_HISTORY_PAGE_ERROR";

export const KEDO_DOCUMENTS_EXPORT_REQUEST = "KEDO_DOCUMENTS_EXPORT_REQUEST";
export const KEDO_DOCUMENTS_EXPORT_SUCCESS = "KEDO_DOCUMENTS_EXPORT_SUCCESS";
export const KEDO_DOCUMENTS_EXPORT_ERROR = "KEDO_DOCUMENTS_EXPORT_ERROR";

export const KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST = "KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST";
export const KEDO_DOCUMENTS_DELETE_DRAFT_SUCCESS = "KEDO_DOCUMENTS_DELETE_DRAFT_SUCCESS";
export const KEDO_DOCUMENTS_DELETE_DRAFT_ERROR = "KEDO_DOCUMENTS_DELETE_DRAFT_ERROR";

export const KEDO_DOCUMENTS_UPDATE_STORE = "KEDO_DOCUMENTS_UPDATE_STORE";