import React, {useEffect, useState} from "react";

import {useGetPrevValue} from "../../hooks/useGetPrevValue";

interface IByTaskUpdater {
    fetch: () => void,
    taskIds: Array<string>,
    dataIds: Array<string>
}

const ByTaskUpdater = (props: IByTaskUpdater) => {
    const {
        fetch,
        taskIds = [],
        dataIds,
    } = props;
    const [isNeedUpdate, setUpdate] = useState(false);
    const prevIsNeedUpdate = useGetPrevValue(isNeedUpdate);

    useEffect(() => {
        const isExistTask = taskIds.some((id) => dataIds.includes(id));

        setUpdate(isExistTask);
    }, [taskIds, dataIds]);

    useEffect(() => {
        if (prevIsNeedUpdate && !isNeedUpdate) {
            fetch();
        }
    }, [isNeedUpdate]);

    return (
        <>
        </>
    );
};

export default ByTaskUpdater;