import React from "react";

import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import {ReactComponent as HamburgerIcon} from "../../../images/menu_24.svg";

import bem from "../../../utils/bem";

import "./style.sass";

interface IMenuHamburger {
    onClick: () => void,
    open: boolean
}

const MenuHamburger = ({onClick, open}: IMenuHamburger) => {
    const [block, element] = bem("menu-hamburger");

    return (
        <div className={block()}>
            <CloseIcon
                onClick={onClick}
                className={`${element("close", {visible: open})} material-icons`}
            />
            <HamburgerIcon
                onClick={onClick}
                className={element("hamburger", {visible: !open})}
            />
        </div>
    );
};

export default MenuHamburger;