export const getFinancePivotTableHeaders = ({defaultDateSortType, isCivil}) => {
    return [
        {
            key: "clientName",
            content: "Заказчик",
        },
        {
            key: "balance",
            content: "Остаток, ₽",
            isRurField: true,
        },
        {
            key: "comingAmount",
            content: "Приход, ₽",
        },
        {
            key: "paidAmount",
            content: "Выплачено, ₽",
            isRurField: true,
        },
        {
            key: "customerCommission",
            content: "Комиссия\n заказчика, ₽",
            isRurField: true,
        },
        {
            key: "paidWithCommission",
            content: "Выплачено\n с комиссией, ₽",
            isRurField: true,
        },
        {
            key: "contractorCommission",
            content: "Комиссия\n исполнителя, ₽",
            isRurField: true,
        },
        {
            key: "pendingForApis",
            content: "Тарифицируемые услуги, ₽",
            isRurField: true,
            isVisible: !isCivil,
        },
        {
            key: "incomeAmount",
            content: "Доход, ₽",
            isRurField: true,
        },
        {
            key: "pendingPayments",
            content: "Незавершенные платежи, ₽",
        },
        {
            key: "pendingPaymentsCustomerCommission",
            content: "Комиссия заказчика\n по незавершенным\n платежам",
        },
        {
            key: "dateOfLastDeposit",
            content: "Дата последнего\n пополнения",
            isSort: true,
            typeSort: defaultDateSortType.toLowerCase(),
        },
    ].filter(({isVisible = true}) => isVisible);
};