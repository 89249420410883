import React, {FC} from "react";

import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";

import bem from "../../../utils/bem";

import {COLOR} from "../../../constants/color";

import "./style.sass";

interface IBadgeAlert {
    className?: string,
    success: boolean,
}

const BadgeAlert: FC<IBadgeAlert> = (props) => {
    const {
        className = "",
        success = false,
    } = props;
    const [block] = bem("badge-alert", className);

    const getIcon = () => {
        const generalIconProps = {
            width: 32,
            height: 32,
        };

        if (success) {
            return <DoneIcon
                {...generalIconProps}
                color={COLOR.PRIMARY_100}
            />;
        }

        return <CloseIcon
            {...generalIconProps}
            color={COLOR.NEGATIVE_100}
        />;
    };

    return (
        <div className={block({success})}>
            {getIcon()}
        </div>
    );
};

export default BadgeAlert;