import React, {FC, useState} from "react";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {OptionType} from "../../../document-management/document-management-statement/list/item/utils/getOptions";

import {DROPDOWN_ALL_NO_YES_OPTIONS, EMPTY_OPTION_KEY} from "../../../../constants/dropdown";
import {ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS} from "../../../../constants/finance";

import "./style.sass";

const initForm = {
    statusFilter: null,
    creationDateFromFilter: null,
    creationDateToFilter: null,
    registryNameFilter: "",
    creatorClientUserNameFilter: "",
    contractTypeFilter: "",
    contractorNameFilter: "",
    externalDocumentFilter: EMPTY_OPTION_KEY,
    contractorPhoneFilter: "",
};

type FcRegistryListFilterProps = {
    submitFilter: Function,
    statusOptions: OptionType[],
    filterData: any
};

const FcRegistryListFilter: FC<FcRegistryListFilterProps> = (props: FcRegistryListFilterProps): JSX.Element => {
    const {
        submitFilter,
        statusOptions,
        filterData,
    } = props;

    const [form, setForm] = useState(isEmpty(filterData)?{...initForm}: filterData);

    const {
        statusFilter,
        creationDateFromFilter,
        creationDateToFilter,
        registryNameFilter,
        creatorClientUserNameFilter,
        contractTypeFilter,
        externalDocumentFilter,
        contractorNameFilter,
        contractorPhoneFilter,
    } = form;

    const onChange = (event: object, {name, value}: any): void => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const submit = (): void => {
        submitFilter(form, true);
    };

    const clear = (): void => {
        submitFilter({
            ...initForm,
        }, false);

        setForm({
            ...initForm,
        });
    };

    return (
        <NmForm
            addMargin
            type="filter"
            className="fc-registry-list-filter"
        >
            <FilterButtonsV2
                className="fc-registry-list-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
            <NmDropdownV2
                size="lg"
                placeholder="Показать все"
                className="fc-registry-list-filter__dropdown"
                label="Статус"
                name="statusFilter"
                onChange={onChange}
                value={statusFilter || ""}
                options={statusOptions || []}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="creationDateFromFilter"
                endFieldName="creationDateToFilter"
                value={{creationDateFromFilter, creationDateToFilter}}
                label="Дата создания"
                onChange={onChange}
                className="fc-registry-list-filter__datepicker-range"
            />
            <NmInputV2
                size="lg"
                label="Название реестра"
                placeholder="Введите название"
                name="registryNameFilter"
                value={registryNameFilter}
                className="fc-registry-list-filter__row"
                onChange={onChange}
            />
            <NmInputV2
                label="Ответственный"
                placeholder="Введите ответственного"
                size="lg"
                name="creatorClientUserNameFilter"
                value={creatorClientUserNameFilter}
                className="fc-registry-list-filter__row"
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                placeholder="Показать все"
                className="fluid"
                label="Договор"
                name="contractTypeFilter"
                onChange={onChange}
                value={contractTypeFilter}
                options={ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS}
            />
            <NmDropdownV2
                size="lg"
                placeholder="Показать все"
                label="Внешний РД"
                name="externalDocumentFilter"
                onChange={onChange}
                value={externalDocumentFilter}
                options={DROPDOWN_ALL_NO_YES_OPTIONS}
            />
            <NmInputV2
                size="lg"
                label="ФИО исполнителя"
                placeholder="Введите ФИО"
                name="contractorNameFilter"
                onChange={onChange}
                value={contractorNameFilter}
            />
            <PhoneWithCodeFilter
                label="Номер телефона исполнителя"
                name="contractorPhoneFilter"
                value={contractorPhoneFilter}
                onChange={onChange}
            />
        </NmForm>
    );
};

export default FcRegistryListFilter;