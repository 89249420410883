import React from "react";
import {Route, Switch, withRouter} from "react-router";

import CrowdTaskCardInWorkPage from "./in-work";
import CrowdTaskCardInvitedList from "./invited-list";
import CrowdTaskCardQueueList from "./queue-list";
import CrowdTaskCardRejectedList from "./rejected-list";

import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
} from "../../../../constants/links";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../../constants/link-params";

const CrowdTaskCardRoute = (props) => {
    const linkInvited = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);
    const linkQueue = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.QUEUE.LINK);
    const linkInWork = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.IN_WORK.LINK);
    const linkCompleted = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.COMPLETED.LINK);
    const linkRejected = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.REFUSED.LINK);

    return (
        <Switch>
            <Route
                exact
                path={linkInvited}
                component={CrowdTaskCardInvitedList}
            />
            <Route
                exact
                path={linkQueue}
                component={CrowdTaskCardQueueList}
            />
            <Route
                exact
                path={linkInWork}
                component={CrowdTaskCardInWorkPage}
            />
            <Route
                exact
                path={linkCompleted}
                component={CrowdTaskCardInWorkPage}
            />
            <Route
                exact
                path={linkRejected}
                component={CrowdTaskCardRejectedList}
            />
        </Switch>
    );
};

export default withRouter(CrowdTaskCardRoute);