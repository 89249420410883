import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/orders/contractor-event";

//*  TYPES  *//

const CONTRACTOR_NOTIFICATION_LOG_PAGE_REQUEST = "CONTRACTOR_NOTIFICATION_LOG_PAGE_REQUEST";
const CONTRACTOR_NOTIFICATION_LOG_PAGE_SUCCESS = "CONTRACTOR_NOTIFICATION_LOG_PAGE_SUCCESS";
const CONTRACTOR_NOTIFICATION_LOG_PAGE_ERROR = "CONTRACTOR_NOTIFICATION_LOG_PAGE_ERROR";

const CONTRACTOR_NOTIFICATION_EVENT_DICT_REQUEST = "CONTRACTOR_NOTIFICATION_EVENT_DICT_REQUEST";
const CONTRACTOR_NOTIFICATION_EVENT_DICT_SUCCESS = "CONTRACTOR_NOTIFICATION_EVENT_DICT_SUCCESS";
const CONTRACTOR_NOTIFICATION_EVENT_DICT_ERROR = "CONTRACTOR_NOTIFICATION_EVENT_DICT_ERROR";

const UPDATE_CONTRACTOR_NOTIFICATION_LOG_FIELD = "UPDATE_CONTRACTOR_NOTIFICATION_LOG_FIELD";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    eventDict: {},
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_NOTIFICATION_LOG_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case CONTRACTOR_NOTIFICATION_LOG_PAGE_SUCCESS:
        const {
            results,
            totalCount,
        } = payload;

        return {
            ...state,
            totalCount,
            list: results,
            progress: false,
        };
    case CONTRACTOR_NOTIFICATION_LOG_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CONTRACTOR_NOTIFICATION_EVENT_DICT_SUCCESS:
        return {
            ...state,
            eventDict: payload,
        };
    case UPDATE_CONTRACTOR_NOTIFICATION_LOG_FIELD:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorNotificationEventPage(payload) {
    return {
        type: CONTRACTOR_NOTIFICATION_LOG_PAGE_REQUEST,
        payload,
    };
}

export function getContractorNotificationEventDict() {
    return {
        type: CONTRACTOR_NOTIFICATION_EVENT_DICT_REQUEST,
    };
}

export function updateFieldContractorNotificationLog(payload) {
    return {
        type: UPDATE_CONTRACTOR_NOTIFICATION_LOG_FIELD,
        payload,
    };
}

//*  SELECTORS  *//

const contractorNotificationLogSelector = state => state.contractorNotificationLog;
export const contractorNotificationLogTotalPagesSelector = createSelector(contractorNotificationLogSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const contractorNotificationLogListSelector = createSelector(contractorNotificationLogSelector, ({list}) => list);
export const contractorNotificationLogTotalCountSelector = createSelector(contractorNotificationLogSelector, ({totalCount}) => totalCount);
export const contractorNotificationLogProgressSelector = createSelector(contractorNotificationLogSelector, ({progress}) => progress);
export const contractorNotificationLogEventDictSelector = createSelector(contractorNotificationLogSelector, ({eventDict}) => eventDict);

//*  SAGA  *//

//POST /api/orders/contractor-event/page
const getContractorNotificationEventPageSaga = function* ({payload}) {
    try {
        const {errorMessage, ...result} = yield request.post(`${controller}/page`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_NOTIFICATION_LOG_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_NOTIFICATION_LOG_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: CONTRACTOR_NOTIFICATION_LOG_PAGE_ERROR, payload: error});
    }
};

//GET /api/orders/contractor-event/dict
const getContractorNotificationEventDictSaga = function* () {
    try {
        const {errorMessage, ...result} = yield request.get(`${controller}/dict`);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_NOTIFICATION_EVENT_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_NOTIFICATION_EVENT_DICT_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: CONTRACTOR_NOTIFICATION_EVENT_DICT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_NOTIFICATION_LOG_PAGE_REQUEST, getContractorNotificationEventPageSaga),
        takeEvery(CONTRACTOR_NOTIFICATION_EVENT_DICT_REQUEST, getContractorNotificationEventDictSaga),
    ]);
}