import {
    addDays,
    addYears,
    getStartDate,
} from "../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {validatePassportDateRu} from "../../../utils/validate";

import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    CONTRACTOR_FIELD,
    CONTRACTOR_MIGRATION_STATUS,
    CONTRACTOR_STATUS,
} from "../../../constants/contractor";

//проверка для паспорта РФ
export const checkPassportDataRu = ({values, card, isVerification}) => {
    const birthDate = values[CONTRACTOR_FIELD.BIRTH_DATE];
    const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

    if (!birthDate || !passportDate) {
        return "";
    }

    const isStatusContractorPassed = checkStatusContractor(card, isVerification);

    if (!isStatusContractorPassed) {
        return "";
    }

    const isValid = validatePassportDateRu({
        passportDate: new Date(passportDate),
        birthDate,
    });

    return isValid ? "" : "Срок действия документа истек";

};

//Проверка для паспорта иностранцев(!RU, !BY, !TJ)
export const checkPassportDataForeigner = ({values, card, isVerification, isRegistrationForm}) => {
    const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];

    if (!passportDateEnd) {
        return "";
    }

    const message = "Срок действия документа истек";

    return checkEndDate({
        field: new Date(passportDateEnd),
        card,
        message,
        isVerification,
        isRegistrationForm,
    });
};

//Проверка для паспорта Белорусов
export const checkPassportDataBy = ({values, card, isVerification}) => {
    const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];

    if (!passportDateEnd) {
        return "";
    }

    const message = "Срок действия документа истек";
    const field = getCheckDate(passportDateEnd, DATA_FOR_CHECK_PASSPORT_DATE_END_BY.YEARS, DATA_FOR_CHECK_PASSPORT_DATE_END_BY.DAYS);

    return checkEndDate({
        field,
        card,
        message,
        isVerification,
    });
};

//Проверка миграционной карты
export const checkMigrationCard = ({values, card, isVerification}) => {
    const migrationCardDateEnd = values[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE];

    if (!migrationCardDateEnd) {
        return "";
    }

    const message = "Срок действия документа истек. Игнорируйте это сообщение. если у Вас есть основания не менять документ";

    return checkEndDate({
        field: new Date(migrationCardDateEnd),
        card,
        message,
        isVerification,
    });
};

//Проверка ДМС
export const checkVhiPolicy = ({values, card, isVerification}) => {
    const vhiPolicyDateEnd = values[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE];
    const employmentPatentIssuedDate = values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE];
    const migrationStatus = values[CONTRACTOR_FIELD.MIGRATION_STATUS];

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.PATENT && vhiPolicyDateEnd) {
        const field = getCheckDate(employmentPatentIssuedDate, 1, 0);

        const leftPartEquation = getStartDate(field);
        const rightPartEquation = getStartDate(vhiPolicyDateEnd);

        if (!leftPartEquation || !rightPartEquation) {
            return;
        }

        if (leftPartEquation.getTime() > rightPartEquation.getTime()) {

            return "Срок действия полиса или договора ДМС не покрывает весь период работ. Вам будет необходимо продлить срок действия договора (полиса) ДМС по окончании действия и подгрузить новую форму в личном кабинете";
        }
    }

    const message = "Срок действия документа истек";

    return checkEndDate({
        field: vhiPolicyDateEnd && new Date(vhiPolicyDateEnd),
        card,
        message,
        isVerification,
    });
};

//Проверка уведомления о прибытии (оба типа)
export const checkArrivalNoticeCard = ({values, card, isVerification}) => {
    const arrivalDocType = values[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE];
    const migrationStatus = values[CONTRACTOR_FIELD.MIGRATION_STATUS];
    const residentPermitDateEnd = values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE];
    const arrivalNoticeStayDateEnd = values[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE];
    const message = "Срок действия документа истек. Игнорируйте это сообщение. если у Вас есть основания не менять документ";

    if (arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION && arrivalNoticeStayDateEnd) {
        const field = getCheckDate(arrivalNoticeStayDateEnd, 0, 0);

        return checkEndDate({field, card, message, isVerification});
    }

    if (
        (
            arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT ||
            migrationStatus === CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT
        ) &&
        residentPermitDateEnd
    ) {
        return checkEndDate({
            field: new Date(residentPermitDateEnd),
            card,
            message,
            isVerification,
        });
    }

    return "";
};

//Проверка патента
export const checkPatentCard = ({values, card, isVerification}) => {
    const patentDate = values[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE];

    if (!patentDate) {
        return "";
    }

    const checkDate = getCheckDate(patentDate, DATA_FOR_CHECK_PATENT_DATE_END.YEARS);
    const leftPartEquation = getStartDate(checkDate);
    const rightPartEquation = getStartDate(new Date());

    if (leftPartEquation.getTime() < rightPartEquation.getTime()) {

        return "Срок действия документа истек";
    }

    return "";

};

//вспомогательные функции
export const getCheckDate = (birthDate, numberYears = 0, numberDays = 0) => {
    if (isNullOrWhitespace(birthDate)) {
        return;
    }

    const addedYears = addYears(birthDate, numberYears);

    return addDays(addedYears, numberDays);
};

const checkStatusContractor = ({registrationStatus}, isVerification) => {
    if (!isVerification) {
        return [CONTRACTOR_STATUS.BANK_CHECK_PASSED, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(registrationStatus);
    }

    return [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(registrationStatus);
};
export const checkEndDate = ({field, card, message, isVerification, isRegistrationForm}) => {
    if (!field) {
        return "";
    }

    const isStatusContractorPassed = checkStatusContractor(card, isVerification);
    const leftPartEquation = getStartDate(field);
    const rightPartEquation = getStartDate(new Date());

    if (!isStatusContractorPassed && !isRegistrationForm) {
        return "";
    }
    if (leftPartEquation < rightPartEquation) {

        return message;
    }

    return "";
};

const DATA_FOR_CHECK_PASSPORT_DATE_END_BY = {
    YEARS: 0,
    DAYS: 30,
};

const DATA_FOR_CHECK_PATENT_DATE_END = {
    YEARS: 1,
    DAYS: 0,
};