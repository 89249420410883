import React, {Component} from "react";
import {
    addDays,
    addMonths,
    endOfDay,
    endOfMonth,
    endOfWeek,
    isSameDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from "date-fns";
import ru from "date-fns/locale/ru";

import NmDatePickerInput from "../ActualComponents/NmDatePicker/Input";
import NmLabel from "../ActualComponents/NmLabel";
import {NmDatePickerRangeView} from "./components/View";

import dateFormat from "../../utils/dateFormat";

import {COLOR} from "../../constants/color";

import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./style.sass";

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};


class NmDatePickerRange extends Component {

    static propTypes = {
        formatDate: PropTypes.string,
        selectDateRange: PropTypes.func.isRequired,
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        className: PropTypes.string,
        minDate: PropTypes.object,
        maxDate: PropTypes.object,
    };

    static defaultProps = {
        formatDate: "dd.MM.yyyy",
        className: "",
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpenCalendar: false,
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({isOpenCalendar: false});
        }
    };

    staticRangeHandler = {
        range: {},
        isSelected(range) {
            const definedRange = this.range();
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            );
        },
    };

    defaultStaticRanges = this.createStaticRanges([
        {
            label: "Сегодня",
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday,
            }),
        },
        {
            label: "Вчера",
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday,
            }),
        },
        {
            label: "Текущий месяц",
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfMonth,
            }),
        },
        {
            label: "Предыдущий месяц",
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth,
            }),
        },
        {
            label: "Очистить",
            range: () => ({
                startDate: null,
                endDate: null,
            }),
        },
    ]);

    get value() {
        const {
            startDate,
            endDate,
            formatDate,
        } = this.props;

        if (!startDate || !endDate) {
            return "";
        }

        return `${dateFormat(startDate, formatDate)} - ${dateFormat(endDate, formatDate)}`;
    }

    get ranges() {
        const {
            startDate,
            endDate,
        } = this.props;

        return [{
            startDate: startDate || new Date(),
            endDate: endDate || new Date(),
            key: "selection",
        }];
    }

    createStaticRanges(ranges) {
        return ranges.map(range => ({...this.staticRangeHandler, ...range}));
    }

    handleSelect = (selectionRange) => {
        const {selectDateRange} = this.props;

        const {
            selection: {
                endDate,
                startDate,
            },
        } = selectionRange;

        selectDateRange({
            endDate,
            startDate,
        });
    };

    handleClickIcon = () => {
        this.setState(prevState => ({isOpenCalendar: !prevState.isOpenCalendar}));
    };

    renderPopup() {
        const {
            minDate,
            maxDate,
            formatDate,
        } = this.props;

        return (
            <NmDatePickerRangeView
                className="nm-date-picker-range__calendar"
                dateDisplayFormat={formatDate}
                minDate={minDate}
                maxDate={maxDate}
                showDateDisplay={false}
                editableDateInputs={false}
                showSelectionPreview={true}
                direction="horizontal"
                inputRanges={[]}
                staticRanges={this.defaultStaticRanges}
                locale={ru}
                rangeColors={[COLOR.PRIMARY_100]}
                ranges={this.ranges}
                onChange={this.handleSelect}
                showPreview={false}
            />
        );
    }

    render() {
        const {isOpenCalendar} = this.state;
        const {className, size} = this.props;

        return (
            <div
                className={`nm-date-picker-range ${className}`}
                ref={this.ref}
            >
                <NmLabel
                    label="Период"
                />
                <NmDatePickerInput
                    size={size}
                    className="nm-date-picker-range__input"
                    onClick={this.handleClickIcon}
                    value={this.value}
                    dateFormatMask="99.99.9999 - 99.99.9999"
                    readOnlyInput={true}
                />
                {isOpenCalendar && this.renderPopup()}
            </div>
        );
    }
}

export default NmDatePickerRange;