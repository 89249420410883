import React from "react";

import "./style.sass";

const ContractorInfoBlock = (props) => {
    return (
        <div className={`contractor-info-block ${props.className}`}>
            {props.children}
        </div>
    );
};

export default ContractorInfoBlock;