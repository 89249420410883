import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import {ReactComponent as HorecaLogo} from "../../images/horeca-logo.svg";
import {ReactComponent as MaintenanceIcon} from "../../images/maintenance.svg";
import Text from "../ActualComponents/Text";
import MenuItem from "../MenuItem";
import NmIcon from "../NmIcon";
import ServerSentEvents from "../ServerSentEvents";
import MenuCounter from "./Counter";
import MenuHamburger from "./Hamburger";
import {MenuItemsContainer} from "./ItemsContainer";
import MenuProfile from "./Profile";

import {isNMUsers} from "../../utils/access";
import {filterDynamicLinks} from "../../utils/clientHelper";
import {linkActiveChecker} from "../../utils/gueryStringHelper";
import {CURRENT_CLIENT_ID, CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../utils/localstorage";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {COLOR} from "../../constants/color";
import {MENU_DESIGN_TYPE} from "../../constants/menu";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_CONSULTANT,
    NM_MANAGER,
    NM_PARTNER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../constants/roles";
import {SSE_EVENT_TYPE} from "../../constants/sse";

import {changeAccount, logoutRequest} from "../../ducks/auth";
import {
    crmChatGetMessageCount,
    crmChatUnderMessageCountSelector,
} from "../../ducks/chat";
import {
    chatUnreadCountSelector,
    getChatUnreadCount,
} from "../../ducks/chatList";
import {
    getRelatedClientUsers,
    relatedClientUsersListSelector,
    updateFieldClientStore,
} from "../../ducks/client";
import {clientCurrentMemberSelector, getClientUserCount} from "../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../ducks/clientProperties";
import {getNonDecisionCount} from "../../ducks/clientSpecialityOffer";
import {currentUserRestrictionsSelector, getClientUserRestrictionsList} from "../../ducks/clientUserRestrictions";
import {disputesTotalCountOpenSelector, getOpenDisputesTotalCount} from "../../ducks/dispute";
import {getStatementsInfoCount} from "../../ducks/edmStatements";
import {getEdoDocumentInfoCount} from "../../ducks/edocuments";
import {foremanNewRequestsAndPaymentsCountSelector, getNewRequestsAndPaymentsCount} from "../../ducks/foremanRequests";
import {getNotificationUnreadCount, notificationUnreadCountSelector} from "../../ducks/notification";
import {
    cancelledChecksIndicatorSelector,
    hasCancelledChecks,
    updateFieldOrderWorkReportStore,
} from "../../ducks/orderWorkReport";
import {sseEventDataSelector} from "../../ducks/serverSentEvents";
import {getMaintenanceNoticePublished} from "../../ducks/systemSetting";

import {menuType} from "../../types";
import PropTypes from "prop-types";

import "./style.sass";

import {adminMenuList} from "../../constants/menu-links";

class Menu extends Component {
    static propTypes = {
        menuList: PropTypes.arrayOf(menuType),
        pathname: PropTypes.string,
        logoutRequest: PropTypes.func,
        currentMember: PropTypes.object,
    };

    static defaultProps = {
        menuList: adminMenuList,
        pathname: "",
        logoutRequest: () => {
        },
        currentMember: {},
        media: {},
    };

    constructor(props) {
        super(props);
        const {
            menuList,
        } = props;

        this.role = ls(USER_ROLE);
        this.versionApp = "1.32.9";
        this.clientId = ls(CURRENT_CLIENT_ID);
        this.clientUserId = ls(CURRENT_CLIENT_USER_ID);

        this.state = {
            menuList,
            childMenuPosition: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {designMenuType} = state;

        if (!designMenuType && !isEqual(designMenuType, props.card.designMenuType)) {
            return {
                ...state,
                designMenuType: props.card.designMenuType,
            };
        }

        return null;
    }

    componentDidMount() {
        const {
            getOpenDisputesTotalCount,
            getNewRequestsAndPaymentsCount,
            getNotificationUnreadCount,
            getNonDecisionCount,
            getStatementsInfoCount,
            getEdoDocumentInfoCount,
            getRelatedClientUsers,
            getClientUserCount,
            getMaintenanceNoticePublished,
            getClientUserRestrictionsList,
        } = this.props;

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(this.role)) {
            getNewRequestsAndPaymentsCount();
            getNotificationUnreadCount({clientId: this.clientId});
            this.hasCancelledChecks();
            this.getChatsUnreadMessageCount();
        }

        if (HR_MANAGER === this.role) {
            getNotificationUnreadCount({clientId: this.clientId});
        }

        if ([HR_MANAGER, FOREMAN, OBJECT_MANAGER, PROJECT_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)) {
            getStatementsInfoCount();
            getEdoDocumentInfoCount();
        }

        if (![NM_CONSULTANT, RNKO, HR_MANAGER, NM_PARTNER].includes(this.role)) {
            getOpenDisputesTotalCount();
        }

        if ([ADMIN, NM_MANAGER].includes(this.role)) {
            getNonDecisionCount();
            // Индикатор аннулированных чеков
            this.hasCancelledChecks();
            getClientUserCount({
                clientId: this.clientId,
            });
        }

        getRelatedClientUsers();

        getMaintenanceNoticePublished();

        getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessSystemTransactions,
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessReconciliationReports,
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessBank,
            ],
        });

        this.updateMenu();
    }

    componentWillUnmount() {
        const {
            updateFieldOrderWorkReportStore,
        } = this.props;

        updateFieldOrderWorkReportStore({
            hasCancelledChecks: false,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            pathname,
            currentMember: {
                clientUserId,
            },
            menuList,
            card,
            event,
            currentUserRestrictions,
        } = this.props;

        if (
            pathname !== prevProps.pathname
            || (clientUserId && clientUserId !== prevProps.currentMember.clientUserId)
        ) {
            this.updateMenu();
        }

        if (
            !isEmpty(menuList)
            && !isEmpty(card)
            && (
                !isEqual(prevProps.menuList, menuList)
                || !isEqual(card, prevProps.card)
            )
        ) {
            this.setState({menuList: [...menuList]}, () => {
                this.updateMenu();
            });
        }

        if (
            !isEmpty(event)
            && !isEqual(prevProps.event, event)
            && [SSE_EVENT_TYPE.NEW_MESSAGE_CHAT].includes(event.eventType)
        ) {
            this.getChatsUnreadMessageCount();
        }

        if (!isEqual(prevProps.currentUserRestrictions, currentUserRestrictions)) {
            this.setMenu();
        }
    }

    getChatsUnreadMessageCount = () => {
        const {
            crmChatGetMessageCount,
            getChatUnreadCount,
        } = this.props;

        getChatUnreadCount();
        crmChatGetMessageCount({readFilter: false});
    };

    hasCancelledChecks = () => {
        const {hasCancelledChecks} = this.props;

        hasCancelledChecks({
            clientId: this.clientId,
            clientUserId: this.clientUserId,
        });
    };

    renderMenuCounter(count) {
        if (isNullOrWhitespace(count) || count === 0) {
            return null;
        }

        return (
            <MenuCounter count={count} />
        );
    }

    getInnerLinkContent(item) {
        const {
            icon,
            id,
            openChild,
        } = item;
        const {
            hasCancelledChecksIndicator,
            disputeTotalCount,
            unreadCount,
            newRequestsAndPaymentsCount,
            specialityNonDecisionCount,
            unreadMessagesCount,
            crmUnreadMessagesCount,
        } = this.props;


        if (icon === "business_24" && !openChild &&
            [
                CLIENT_ADMIN,
                PROJECT_MANAGER,
                OBJECT_MANAGER,
                FOREMAN,
                CLIENT_ACCOUNTANT,
            ].includes(this.role)) {

            return this.renderMenuCounter(newRequestsAndPaymentsCount);
        }

        if (icon === "engineering") {
            return this.renderMenuCounter(newRequestsAndPaymentsCount);
        }

        if (id === "disputes") {
            return this.renderMenuCounter(disputeTotalCount);
        }

        if (id === "notifications") {
            return this.renderMenuCounter(unreadCount);
        }

        if (id === "settings" && [ADMIN, NM_MANAGER].includes(this.role)) {
            return this.renderMenuCounter(specialityNonDecisionCount);
        }

        if (id === "chat-list") {
            return this.renderMenuCounter(unreadMessagesCount + crmUnreadMessagesCount);
        }

        if (
            hasCancelledChecksIndicator &&
            id === "finance" &&
            !openChild &&
            [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role)
        ) {
            return (
                <NmIcon
                    className="nmx-menu__canceled-checks-indicator"
                    name="canceled-checks-indicator"
                />
            );
        }

        return null;
    }

    openMenuItem = (id) => {
        const {menuList} = this.state;

        const _menuList = menuList.map(item => {
            if (item.id === id) {
                const {openChild} = item;

                return {
                    ...item,
                    openChild: !openChild,
                };
            }

            return {
                ...item,
                openChild: false,
            };
        });

        this.setState(prevState => ({
            ...prevState,
            menuList: _menuList,
            openId: prevState.openId === id ? null : id,
        }));
    };

    setMenu = () => {
        const {
            pathname,
            card: {
                foremanEnabled: foremanFunctionalityAvailable,
                retailClient,
                retailClientUserIds,
                insuranceAvailable,
                registryPaymentsAvailable,
                civilRegistryPaymentsAvailable,
                individualRegistryPaymentsAvailable,
                migrantLicensePaymentEnabled,
                isRecruitmentAvailable,
                crowdTasksAvailable,
            },
            currentMember: {
                clientUserId,
            },
            media: {preDesktop},
            currentUserRestrictions,
        } = this.props;
        const {menuList} = this.state;

        const _menuList = menuList.map(item => {
            if (item.childMenu) {
                // Фильтруем подменю взаивимсоти от настроек клиента
                const filteredChildMenu = item.childMenu.filter(item => {
                    const {
                        isVisible = true,
                    } = item;
                    
                    return isVisible && filterDynamicLinks({
                        item,
                        checks: {
                            foremanFunctionalityAvailable,
                            insuranceAvailable,
                            registryPaymentsAvailable,
                            civilRegistryPaymentsAvailable,
                            individualRegistryPaymentsAvailable,
                            isAccessClientResources: retailClient && retailClientUserIds.includes(clientUserId),
                            migrantLicensePaymentEnabled,
                            isRecruitmentAvailable,
                            crowdTasksAvailable,
                        },
                        restrictions: currentUserRestrictions,
                    });
                });

                // Определяем активность пункта подменю
                const activeChildItem = filteredChildMenu.find(item => {
                    const isActiveLink = linkActiveChecker(pathname, {
                        activeExceptions: item.activeExceptions,
                        activePath: item.activePath,
                        to: item.to,
                    });

                    return isActiveLink === true;
                });

                return {
                    ...item,
                    // в адаптивной версии меню от 1200 до 1920 при клике по ссылкам подменю надо закрывать подменю
                    openChild: preDesktop ? false : !!activeChildItem,
                    childMenu: [
                        ...filteredChildMenu,
                    ],
                };
            }

            return item;
        });

        this.setState({
            menuList: _menuList,
        });
    };

    updateMenu = () => {
        const {pathname} = this.props;
        const {menuList} = this.state;

        // Когда уже есть какой-то открытый пункт меню в процессе использования
        const isOpenChildByConfig = menuList.filter(item => Boolean(item.openChild)).length > 0;

        if (isOpenChildByConfig) {
            this.setMenu();

            return;
        }

        // На момент первоначальной загрузки меню, когда в меню определяем открыто ли подменю по ссылке
        const isOpenChild = menuList.filter(item => Boolean(item.childMenu)).some(item => item.childMenu.some(item => {
            const isActiveChild = linkActiveChecker(pathname, {
                activePath: item.activePath,
                to: item.to,
            });

            return isActiveChild === true;
        }));

        if (isOpenChild) {
            this.setMenu();
        }
    };

    renderMenuItems() {
        const {
            pathname,
            hasCancelledChecksIndicator,
            media: {preDesktop},
        } = this.props;

        const {
            menuList,
            childMenuPosition,
            designMenuType,
            menuOpen,
        } = this.state;

        return menuList.filter(({isVisible = true}) => isVisible).map((item) => {
            const {
                childMenu,
                activePath,
                icon,
                to,
                content,
                isSearchActiveInAllPath,
                svg,
                id,
                openChild,
                activeExceptions,
            } = item;

            const isActiveLink = linkActiveChecker(pathname, {
                activePath,
                to,
                isSearchActiveInAllPath,
                activeExceptions,
            });

            return (
                <MenuItem
                    key={id}
                    svg={svg}
                    pathname={pathname}
                    openChild={openChild}
                    childMenuPosition={childMenuPosition}
                    setChildMenuPosition={this.setChildMenuPosition}
                    onClick={() => {
                        this.openMenuItem(id);
                    }}
                    onLinkClick={() => {
                        menuOpen && this.toggleMobileMenu();
                    }}
                    isActiveLink={isActiveLink}
                    icon={icon}
                    content={content}
                    childMenu={childMenu}
                    innerContent={this.getInnerLinkContent(item)}
                    to={to}
                    hasCancelledChecksIndicator={hasCancelledChecksIndicator}
                    preDesktop={preDesktop}
                    designMenuType={designMenuType}
                />
            );
        });
    }

    handleLogout = () => {
        this.props.logoutRequest();
    };

    get classNames() {
        const {menuOpen} = this.state;
        const classNames = ["nmx-menu"];

        if (this.role === CLIENT_ADMIN || this.role === PROJECT_MANAGER) {
            classNames.push("nmx-menu_client-admin");
        }

        if (menuOpen) {
            classNames.push("nmx-menu_open");
        }

        return classNames.join(" ");
    }

    setChildMenuPosition = (childMenuPosition) => {
        this.setState({
            childMenuPosition,
        });
    };

    toggleMobileMenu = () => {
        this.setState(prevState => {
            if (!prevState.menuOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }

            return {
                ...prevState,
                menuOpen: !prevState.menuOpen,
            };
        });
    };

    onScrollMenu = () => {
        const {openId} = this.state;

        if (openId) {
            this.openMenuItem(openId);
            this.setChildMenuPosition({});
        }
    };

    getMaintenanceSticker = () => {
        const {isMaintenanceNoticePublished} = this.props;

        if (!isMaintenanceNoticePublished) {
            return null;
        }

        return (
            <div className="nmx-menu__info nmx-menu__info_maintenance">
                <MaintenanceIcon
                    width={34}
                    height={34}
                    color={COLOR.INERT_70}
                    className="nmx-menu__maintenance-icon"
                />
                <div className="nmx-menu__maintenance-container">
                    <Text
                        level="3"
                        color={COLOR.INERT_70}
                        className="mt-3"
                    >
                        Ведутся технические работы
                    </Text>
                </div>
            </div>
        );
    };

    getLogo = () => {
        const {designMenuType} = this.state;

        if (isNMUsers()) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_naimix" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.HO_RE_CA_MENU) {
            return (
                <HorecaLogo className="nmx-menu__logo nmx-menu__logo_hoReCa" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.MTS_BANK_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_mts" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.ROS_KAP_STROI_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_ros-kap-stroi" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.HELP_RESOURCE_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_help-resource" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.PROMOPOISK_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_promopoisk" />
            );
        }

        return (
            <div className="nmx-menu__logo nmx-menu__logo_naimix" />
        );
    };

    render() {
        const {
            t,
            media,
            currentMember,
            listRelatedClientUsers,
            pathname,
            specialityNonDecisionCount,
        } = this.props;
        const {menuOpen} = this.state;

        return (
            <>
                <ServerSentEvents />
                <div className={this.classNames}>
                    <div className="nmx-menu__container">
                        <MenuProfile
                            currentMember={currentMember}
                            clientId={this.clientId}
                            media={media}
                            open={menuOpen}
                            listRelatedClientUsers={listRelatedClientUsers}
                            pathname={pathname}
                            specialityNonDecisionCount={specialityNonDecisionCount}
                            handleLogout={this.handleLogout}
                        />
                        <MenuHamburger
                            onClick={this.toggleMobileMenu}
                            open={menuOpen}
                        />
                        <MenuItemsContainer
                            className="nmx-menu__list-container"
                            onScroll={this.onScrollMenu}
                        >
                            {this.renderMenuItems()}
                        </MenuItemsContainer>
                        <div className="nmx-menu__bottom-container">
                            {this.getMaintenanceSticker()}
                            <div className="nmx-menu__info">
                                {this.getLogo()}
                                <div className="nmx-menu__version">
                                    <span className="nmx-menu__version-word">
                                        {t("menu.version-text")}
                                    </span>
                                    <span>
                                        {` UI ${this.versionApp}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    menuOpen &&
                    <div
                        onClick={this.toggleMobileMenu}
                        className="nmx-menu__layer"
                    />
                }
            </>
        );
    }
}


export default connect(
    state => ({
        pathname: state.router.location.pathname,
        currentMember: clientCurrentMemberSelector(state),
        disputeTotalCount: disputesTotalCountOpenSelector(state),
        unreadCount: notificationUnreadCountSelector(state),
        newRequestsAndPaymentsCount: foremanNewRequestsAndPaymentsCountSelector(state),
        card: getClientPropertiesCardSelector(state),
        specialityNonDecisionCount: state.clientSpecialityOffer.nonDecisionCount,
        hasCancelledChecksIndicator: cancelledChecksIndicatorSelector(state),
        listRelatedClientUsers: relatedClientUsersListSelector(state),
        unreadMessagesCount: chatUnreadCountSelector(state),
        crmUnreadMessagesCount: crmChatUnderMessageCountSelector(state),
        event: sseEventDataSelector(state),
        isMaintenanceNoticePublished: state.systemSetting.isMaintenanceNoticePublished,
        currentUserRestrictions: currentUserRestrictionsSelector(state),
    }),
    {
        logoutRequest,
        getOpenDisputesTotalCount,
        getNewRequestsAndPaymentsCount,
        getNotificationUnreadCount,
        getNonDecisionCount,
        hasCancelledChecks,
        updateFieldOrderWorkReportStore,
        getStatementsInfoCount,
        getEdoDocumentInfoCount,
        getRelatedClientUsers,
        changeAccount,
        updateFieldClientStore,
        getClientUserCount,
        getChatUnreadCount,
        crmChatGetMessageCount,
        getMaintenanceNoticePublished,
        getClientUserRestrictionsList,
    },
)(withTranslation()(Menu));
