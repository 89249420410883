import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../NmDropdownV2";

import {specialitiesAllV2OptionsSelector} from "../../../ducks/speciality";

const FilterSpecialities = props => {
    const {
        multiple = true,
        ...otherProps
    } = props;

    const options = useSelector(specialitiesAllV2OptionsSelector);

    return (
        <NmDropdownV2
            size="lg"
            {...otherProps}
            multiple={multiple}
            search
            options={options}
        />
    );
};

export default FilterSpecialities;