import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageCrowdTaskGroups,
    updateStoreCrowdTaskGroups,
} from "../../../../ducks/bff/crowd/taskGroups/actionCreators";

const useCrowdTaskGroupsFetch = (props) => {
    const {
        clientId,
        filter,
        pageNum,
        pageSize,
        archivedFilter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdTaskGroups({
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter, archivedFilter]);

    const fetchList = () => {
        dispatch(getPageCrowdTaskGroups({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            archivedFilter,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};

export default useCrowdTaskGroupsFetch;