import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getClientGroups} from "../../../../../ducks/clientGroups";
import {getPageDocumentsSignatureLog} from "../../../../../ducks/documents";

export function useFetchListDocumentsLog({filter = {}, pageSize, pageNum, contractorIdFilter}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        setLoading(true);
        const requestData = {
            data: {
                pageNum,
                pageSize,
                contractorIdFilter,
                ...filter,
            },
            handleResponse: () => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            },
        };

        dispatch(getPageDocumentsSignatureLog(requestData));
    }
    ;

    return {
        loading,
        fetchList,
    };
}