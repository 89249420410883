import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect/lib/index";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/contractHistory";
//*  TYPES  *//

const CONTRACT_HISTORY_ADD_REQUEST = "CONTRACT_HISTORY_ADD_REQUEST";
const CONTRACT_HISTORY_ADD_SUCCESS = "CONTRACT_HISTORY_ADD_SUCCESS";
const CONTRACT_HISTORY_ADD_ERROR = "CONTRACT_HISTORY_ADD_ERROR";

const CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST = "CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST";
const CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS = "CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS";
const CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR = "CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR";

const CONTRACT_HISTORY_GET_LIST_REQUEST = "CONTRACT_HISTORY_GET_LIST_REQUEST";
const CONTRACT_HISTORY_GET_LIST_SUCCESS = "CONTRACT_HISTORY_GET_LIST_SUCCESS";
const CONTRACT_HISTORY_GET_LIST_ERROR = "CONTRACT_HISTORY_GET_LIST_ERROR";

const CONTRACT_HISTORY_GET_FILE_REQUEST = "CONTRACT_HISTORY_GET_FILE_REQUEST";
const CONTRACT_HISTORY_GET_FILE_SUCCESS = "CONTRACT_HISTORY_GET_FILE_SUCCESS";
const CONTRACT_HISTORY_GET_FILE_ERROR = "CONTRACT_HISTORY_GET_FILE_ERROR";

const CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST = "CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST";
const CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS = "CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS";
const CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR = "CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR";

const CONTRACT_HISTORY_CLEAR_FIELDS = "CONTRACT_HISTORY_CLEAR_FIELDS";

//*  INITIAL STATE  *//

const initial = {
    actualVersion: {},
    pageData: {},
    historyList: [],
    totalCount: 0,
    error: null,
    requestData: {},
    addProgress: false,
    actualFileNames: [],
    progressUpdate: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case CONTRACT_HISTORY_GET_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
        };
    case CONTRACT_HISTORY_ADD_REQUEST:
        return {
            ...state,
            addProgress: true,
        };
    case CONTRACT_HISTORY_CLEAR_FIELDS:
        return {
            ...state,
            ...initial,
        };
    case CONTRACT_HISTORY_ADD_SUCCESS:
        return {
            ...state,
            addProgress: false,
        };
    case CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
        };
    case CONTRACT_HISTORY_ADD_ERROR:
        return {
            ...state,
            addProgress: false,
        };
    case CONTRACT_HISTORY_GET_LIST_SUCCESS:
        const {
            contractHistoryEntityList,
            totalCount,
        } = payload;

        return {
            ...state,
            historyList: contractHistoryEntityList,
            totalCount,
        };
    case CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST:
        return {
            ...state,
            requestData: payload,
        };
    case CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS:
        return {
            ...state,
            actualVersion: payload,
        };
    case CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR:
        return {
            ...state,
            progressUpdate: false,
        };
    case CONTRACT_HISTORY_GET_LIST_ERROR:
        return {
            ...state,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getHistoryList(payload) {
    return {
        type: CONTRACT_HISTORY_GET_LIST_REQUEST,
        payload,
    };
}

export function getActualVersion(payload) {
    return {
        type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST,
        payload,
    };
}

export function addActualVersion(payload) {
    return {
        type: CONTRACT_HISTORY_ADD_REQUEST,
        payload,
    };
}


export function getContractHistoryFile(payload) {
    return {
        type: CONTRACT_HISTORY_GET_FILE_REQUEST,
        payload,
    };
}

export function updateActualVersion(payload) {
    return {
        type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST,
        payload,
    };
}


export function clearFields() {
    return {
        type: CONTRACT_HISTORY_CLEAR_FIELDS,
    };
}

//*  SELECTORS  *//

export const contractHistorySelector = state => state.contractHistory;
export const getContractHistoryListSelector = createSelector(contractHistorySelector, ({historyList}) => historyList);
export const getActualVersionSelector = createSelector(contractHistorySelector, ({actualVersion}) => actualVersion);
export const getPageDataSelector = createSelector(contractHistorySelector, ({pageData}) => pageData);
export const getAddProgressSelector = createSelector(contractHistorySelector, ({addProgress}) => addProgress);
export const getTotalPagesHistorySelector = createSelector(contractHistorySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getTotalCountHistorySelector = createSelector(contractHistorySelector, ({totalCount}) => totalCount);
export const getActualFileNamesSelector = createSelector(contractHistorySelector, ({actualVersion: {fileNames}}) => fileNames);
//*  SAGA  *//

//POST /api/contractHistory/add
export const addActualVersionSaga = function* (action) {
    try {
        const {payload} = action;
        const {params, data} = payload;

        const result = yield request.post(`${controller}/add?clientId=${params.clientId}`, data, {
            params,
            ...getMultipartHeaders(),
        },
        );
        const {data: responseData = {}} = result;

        const {errorMessage} = responseData;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Файлы успешно добавлены");

        const state = yield select();

        const {clientId} = params;

        yield put(getActualVersion({clientId}));
        yield put(getHistoryList(getPageDataSelector(state)));

        yield put({
            type: CONTRACT_HISTORY_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CONTRACT_HISTORY_ADD_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractHistory/getPage
export const getHistoryListSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse = () => {
                },
                ...data
            },
        } = action;

        const result = yield request.post(`${controller}/getRichPage`, data);
        const {errorMessage} = result;

        handleResponse();
        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_GET_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }


        yield put({type: CONTRACT_HISTORY_GET_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONTRACT_HISTORY_GET_LIST_ERROR, payload: error});
    }
};

//GET /api/contractHistory/getPage
export const getAgencyContractActualSaga = function* (action) {
    try {
        const {payload} = action;
        const {clientId} = payload;

        const result = yield request.get(`${controller}/getActual?clientId=${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }


        yield put({type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS, payload: result === null ? {} : result});
    } catch (error) {
        yield put({type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR, payload: error});
    }
};

//GET /api/contractHistory/getFile
export const getContractHistoryFileSaga = function* (action) {
    try {
        const {
            payload: {
                fileName,
                contractHistoryId,
                isDownload,
            },
        } = action;

        const myInit = {
            method: "POST",
            body: JSON.stringify({contractHistoryId, fileName}),
        };
        const urlRequest = `${new URL(window.location.href).origin}/api/contractHistory/getFile`;
        const response = yield request.getFile(urlRequest, myInit);

        const blob = yield response.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        if (isDownload) {
            tempLink.setAttribute("download", fileName);
        } else {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

        yield put({type: CONTRACT_HISTORY_GET_FILE_SUCCESS});

    } catch (error) {
        yield put({type: CONTRACT_HISTORY_GET_FILE_ERROR, payload: error});
    }
};

//POST /api/contractHistory/update
export const updateActualVersionSaga = function* (action) {
    try {
        const {payload} = action;
        const {params, data} = payload;

        const result = yield request.post(`${controller}/update`, data, {
            params,
            ...getMultipartHeaders(),
        },
        );

        const {data: responseData = {}} = result;

        const {errorMessage} = responseData;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Файлы успешно обновлены");

        const state = yield select();

        yield put(getActualVersion(getActualVersionSelector(state)));

        yield put({
            type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACT_HISTORY_ADD_REQUEST, addActualVersionSaga),
        takeEvery(CONTRACT_HISTORY_GET_LIST_REQUEST, getHistoryListSaga),
        takeEvery(CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST, getAgencyContractActualSaga),
        takeEvery(CONTRACT_HISTORY_GET_FILE_REQUEST, getContractHistoryFileSaga),
        takeEvery(CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST, updateActualVersionSaga),
    ]);
}
