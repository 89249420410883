import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_REQUEST = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_REQUEST";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_SUCCESS = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_SUCCESS";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_ERROR = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_ERROR";

const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_REQUEST = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_REQUEST";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SUCCESS = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SUCCESS";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_ERROR = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_ERROR";

const FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_REQUEST = "FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_REQUEST";
const FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_SUCCESS = "FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_SUCCESS";
const FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_ERROR = "FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_ERROR";

const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_REQUEST = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_REQUEST";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_SUCCESS = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_SUCCESS";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_ERROR = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_ERROR";

const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_REQUEST = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_REQUEST";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_SUCCESS = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_SUCCESS";
const FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_ERROR = "FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_ERROR";

const FILE_STORE_RECRUITMENT_UPDATE_STORE = "FILE_STORE_RECRUITMENT_UPDATE_STORE";

const controller = "/filestore/recruitment";

export const getFileStoreRecruitmentInitialState = () => {
    return {
        responseAvatarBase64Str: null,
        avatarBase64Str: null,
        progressAction: false,
        progress: false,
        avatarBase64Images: {},
        pageData: {},
    };
};

const initialState = getFileStoreRecruitmentInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_REQUEST:
        return {
            ...state,
            pageData: payload,
        };
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_SUCCESS:
        return {
            ...state,
            avatarBase64Images: {
                ...state.avatarBase64Images,
                ...payload,
            },
        };
    case FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_REQUEST:
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_ERROR:
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_ERROR:
        return {
            ...state,
            progress: false,
        };
    case FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_SUCCESS:
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SUCCESS:
        const {
            nameField,
            base64str,
        } = payload;

        return {
            ...state,
            progress: false,
            [nameField]: base64str,
        };
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_REQUEST:
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_SUCCESS:
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_ERROR:
    case FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case FILE_STORE_RECRUITMENT_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export function addRecruitmentCandidateAvatar(payload) {
    return {
        type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_REQUEST,
        payload,
    };
}

export function getRecruitmentCandidateAvatar(payload) {
    return {
        type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_REQUEST,
        payload,
    };
}

export function getRecruitmentResponseAvatar(payload) {
    return {
        type: FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_REQUEST,
        payload,
    };
}

export function deleteRecruitmentCandidateAvatar(payload) {
    return {
        type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_REQUEST,
        payload,
    };
}

export function updateFileStoreRecruitmentCandidateStore(payload) {
    return {
        type: FILE_STORE_RECRUITMENT_UPDATE_STORE,
        payload,
    };
}

export function getRecruitmentCandidateSmallThumbnails(payload) {
    return {
        type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_REQUEST,
        payload,
    };
}

export const fileStoreRecruitmentSelector = state => state.fileStoreRecruitment;

export const fileStoreRecruitmentProgressSelector = createSelector(fileStoreRecruitmentSelector, ({progress}) => progress);
export const fileStoreRecruitmentProgressActionSelector = createSelector(fileStoreRecruitmentSelector, ({progressAction}) => progressAction);
const base64Selector = (state, nameField) => {
    const {
        [nameField]: base64,
    } = state.fileStoreRecruitment;

    return base64;
};
export const fileStoreRecruitmentAvatarBase64Selector =
    createSelector(
        base64Selector,
        (base64) => base64,
    );
export const fileStoreRecruitmentResponseAvatarBase64Selector = createSelector(fileStoreRecruitmentSelector, ({responseAvatarBase64Str}) => responseAvatarBase64Str);
export const fileStoreRecruitmentAvatarBase64ImagesSelector = createSelector(fileStoreRecruitmentSelector, ({avatarBase64Images}) => avatarBase64Images);
export const fileStoreRecruitmentPageDataSelector = createSelector(fileStoreRecruitmentSelector, ({pageData}) => pageData);

const getAvatarType = (thumbnail, smallThumbnail) => {
    if (thumbnail) {
        return "getThumbnail";
    }

    if (smallThumbnail) {
        return "getSmallThumbnail";
    }

    return "get";
};

//GET /api/filestore/recruitment/candidate/avatar/get/base64
// Получение файла аватара кандидата в формате base64
export const getRecruitmentCandidateAvatarSaga = function* ({payload}) {
    const {
        getResult,
        candidateId,
        thumbnail = false,
        smallThumbnail = false,
        nameField,
    } = payload;

    const type = getAvatarType(thumbnail, smallThumbnail);

    try {
        const result = yield request.get(`${controller}/candidate/avatar/${type}/base64` , {
            params: {
                candidateId,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SUCCESS,
            payload: {...result, nameField},
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_ERROR,
            payload: error,
        });
    }
};

//GET /api/filestore/recruitment/response/avatar/get/base64
// Получение файла аватара отклика в формате base64
export const getRecruitmentResponseAvatarSaga = function* ({payload}) {
    const {
        getResult,
        responseId,
        thumbnail = false,
        smallThumbnail = false,
        nameField = "",
    } = payload;

    const type = getAvatarType(thumbnail, smallThumbnail);

    try {
        const result = yield request.get(`${controller}/response/avatar/${type}/base64`, {
            params: {
                responseId,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_SUCCESS,
            payload: {...result, nameField},
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_ERROR,
            payload: error,
        });
    }
};

// POST /api/filestore/recruitment/candidate/avatar/add
// Добавление файла аватара кандидата
export const addRecruitmentCandidateAvatarSaga = function* ({payload}) {
    const {
        candidateId,
        file,
        onSuccess,
    } = payload;

    try {
        const result = yield request.post(`${controller}/candidate/avatar/add`, file, {
            params: {
                candidateId,
            },
            ...getMultipartHeaders(),
        },
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Аватар успешно обновлен");

        onSuccess();

        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError("При сохранении произошла ошибка. Попробуйте еще раз позднее");
        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_ERROR,
            payload: error.message,
        });
    }
};

// DELETE /api/filestore/recruitment/candidate/avatar/delete
// Удаление файла аватара кандидата
export const deleteRecruitmentCandidateAvatarSaga = function* ({payload}) {
    const {
        onSuccess,
        candidateId,
    } = payload;

    try {
        const result = yield request.delete(`${controller}/candidate/avatar/delete`, {
            params: {
                candidateId,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Аватар успешно удален");

        onSuccess();

        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /api/filestore/recruitment/candidate/avatar/getSmallThumbnails/base64
// POST /api/filestore/recruitment/candidate/response/getSmallThumbnails/base64
// Получение списка маленьких миниатюр аватара кандидатов / откликов в формате base64
export const getRecruitmentCandidateSmallThumbnailsSaga = function* ({payload}) {
    const {
        ids,
        isResponses,
    } = payload;

    try {
        const result = yield request.post(`${controller}/${isResponses ? "response" : "candidate"}/avatar/getSmallThumbnails/base64`, ids);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_SUCCESS,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_ADD_REQUEST, addRecruitmentCandidateAvatarSaga),
        takeEvery(FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_DELETE_REQUEST, deleteRecruitmentCandidateAvatarSaga),
        takeEvery(FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_REQUEST, getRecruitmentCandidateAvatarSaga),
        takeEvery(FILE_STORE_RECRUITMENT_RESPONSE_AVATAR_GET_REQUEST, getRecruitmentResponseAvatarSaga),
        takeEvery(FILE_STORE_RECRUITMENT_CANDIDATE_AVATAR_GET_SMALL_THUMBNAILS_REQUEST, getRecruitmentCandidateSmallThumbnailsSaga),
    ]);
}