import React from "react";

import {ReactComponent as LocatedOutsideRussiaIcon} from "../../images/located-outside-russia-icon.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";

const LocatedOutsideRussiaTooltip = (props) => {
    const {
        width = 20,
        height = 20,
    } = props;

    return (
        <NmAdvancedTooltip
            hover
            position="bottom-left"
            trigger={
                <LocatedOutsideRussiaIcon
                    width={width}
                    height={height}
                />
            }
        >
            Исполнитель за пределами России
        </NmAdvancedTooltip>
    );
};

export default LocatedOutsideRussiaTooltip;