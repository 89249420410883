import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import {edoStaffListToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {EDM_STAFF_LIST_REQUEST_TOAST} from "../constants/messages";

const edmStaffController = "/staff";

const EDM_GET_STAFF_REQUEST = "EDM_GET_STAFF_REQUEST";
const EDM_GET_STAFF_SUCCESS = "EDM_GET_STAFF_SUCCESS";
const EDM_GET_STAFF_ERROR = "EDM_GET_STAFF_ERROR";

const EDM_GET_STAFF_LIST_REQUEST = "EDM_GET_STAFF_LIST_REQUEST";
const EDM_GET_STAFF_LIST_SUCCESS = "EDM_GET_STAFF_LIST_SUCCESS";
const EDM_GET_STAFF_LIST_ERROR = "EDM_GET_STAFF_LIST_ERROR";

const EDM_ADD_STAFF_REQUEST = "EDM_ADD_STAFF_REQUEST";
const EDM_ADD_STAFF_SUCCESS = "EDM_ADD_STAFF_SUCCESS";
const EDM_ADD_STAFF_ERROR = "EDM_ADD_STAFF_ERROR";

const EDM_UPDATE_STAFF_REQUEST = "EDM_UPDATE_STAFF_REQUEST";
const EDM_UPDATE_STAFF_SUCCESS = "EDM_UPDATE_STAFF_SUCCESS";
const EDM_UPDATE_STAFF_ERROR = "EDM_UPDATE_STAFF_ERROR";

const EDM_ARCHIVE_STAFF_REQUEST = "EDM_ARCHIVE_STAFF_REQUEST";
const EDM_ARCHIVE_STAFF_SUCCESS = "EDM_ARCHIVE_STAFF_SUCCESS";
const EDM_ARCHIVE_STAFF_ERROR = "EDM_ARCHIVE_STAFF_ERROR";

const EDM_STAFF_FIND_CONTRACTORS_REQUEST = "EDM_STAFF_FIND_CONTRACTORS_REQUEST";
const EDM_STAFF_FIND_CONTRACTORS_SUCCESS = "EDM_STAFF_FIND_CONTRACTORS_SUCCESS";
const EDM_STAFF_FIND_CONTRACTORS_ERROR = "EDM_STAFF_FIND_CONTRACTORS_ERROR";

const EDM_STAFF_FIND_USERS_REQUEST = "EDM_STAFF_FIND_USERS_REQUEST";
const EDM_STAFF_FIND_USERS_SUCCESS = "EDM_STAFF_FIND_USERS_SUCCESS";
const EDM_STAFF_FIND_USERS_ERROR = "EDM_STAFF_FIND_USERS_ERROR";

const EDM_STAFF_IMPORT_REQUEST = "EDM_STAFF_IMPORT_REQUEST";
const EDM_STAFF_IMPORT_SUCCESS = "EDM_STAFF_IMPORT_SUCCESS";
const EDM_STAFF_IMPORT_ERROR = "EDM_STAFF_IMPORT_ERROR";

const EDM_STAFF_USER_EXIST_REQUEST = "EDM_STAFF_USER_EXIST_REQUEST";
const EDM_STAFF_USER_EXIST_SUCCESS = "EDM_STAFF_USER_EXIST_SUCCESS";
const EDM_STAFF_USER_EXIST_ERROR = "EDM_STAFF_USER_EXIST_ERROR";

const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    listProgress: false,
    staffCard: {},
    gettingStaffProgress: false,
    findingContractorsList: [],
    findingContractorsProgress: false,
    findingContractorsPageData: {},
    findingContractorsTotalCount: 0,
    findingUsersProgress: false,
    findingUsersPageData: {},
    findingUsersTotalCount: 0,
    findingUsersList: [],
    actionProgress: false,
    clientUserStaffExist: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case EDM_STAFF_FIND_USERS_REQUEST:
        return {
            ...state,
            findingUsersProgress: true,
            findingUsersPageData: payload,
        };
    case EDM_STAFF_FIND_CONTRACTORS_REQUEST:
        return {
            ...state,
            findingContractorsProgress: true,
            findingContractorsPageData: payload.data,
        };
    case EDM_UPDATE_STAFF_REQUEST:
    case EDM_ARCHIVE_STAFF_REQUEST:
    case EDM_ADD_STAFF_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case EDM_GET_STAFF_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            listProgress: true,
        };
    case EDM_GET_STAFF_REQUEST:
        return {
            ...state,
            gettingStaffProgress: true,
        };
    case EDM_STAFF_FIND_CONTRACTORS_SUCCESS:
        const {
            results: findingContractorsList,
            totalCount: findingContractorsTotalCount,
        } = payload;

        return {
            ...state,
            findingContractorsTotalCount,
            findingContractorsList,
            findingContractorsProgress: false,
        };
    case EDM_STAFF_FIND_USERS_SUCCESS:
        const {
            results: findingUsersList,
            totalCount: findingUsersTotalCount,
        } = payload;

        return {
            ...state,
            findingUsersList,
            findingUsersTotalCount,
            findingUsersProgress: false,
        };
    case EDM_GET_STAFF_SUCCESS:
        return {
            ...state,
            staffCard: payload,
            gettingStaffProgress: false,
        };
    case EDM_UPDATE_STAFF_SUCCESS:
    case EDM_ARCHIVE_STAFF_SUCCESS:
    case EDM_ADD_STAFF_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case EDM_GET_STAFF_LIST_SUCCESS:
        const {
            results: list,
            totalCount,
        } = payload;

        return {
            ...state,
            list,
            totalCount,
            listProgress: false,
        };
    case EDM_STAFF_USER_EXIST_SUCCESS:
        return {
            ...state,
            clientUserStaffExist: payload,
        };
    case EDM_STAFF_FIND_CONTRACTORS_ERROR:
        return {
            ...state,
            error: payload,
            findingContractorsProgress: false,
        };
    case EDM_STAFF_FIND_USERS_ERROR:
        return {
            ...state,
            error: payload,
            findingUsersProgress: false,
        };
    case EDM_GET_STAFF_LIST_ERROR:
        return {
            ...state,
            error: payload,
            listProgress: false,
        };
    case EDM_UPDATE_STAFF_ERROR:
    case EDM_ARCHIVE_STAFF_ERROR:
    case EDM_ADD_STAFF_ERROR:
        return {
            ...state,
            error: payload,
            actionProgress: false,
        };
    case EDM_GET_STAFF_ERROR: {
        return {
            ...state,
            error: payload,
            gettingStaffProgress: false,
        };
    }
    default:
        return state;
    }
};

export function getEdmStaffById(payload) {
    return {
        type: EDM_GET_STAFF_REQUEST,
        payload,
    };
}

export function getEdmStaffList(payload) {
    return {
        type: EDM_GET_STAFF_LIST_REQUEST,
        payload,
    };
}

export function addEdmStaff(payload) {
    return {
        type: EDM_ADD_STAFF_REQUEST,
        payload,
    };
}

export function updateEdmStaff(payload) {
    return {
        type: EDM_UPDATE_STAFF_REQUEST,
        payload,
    };
}

export function archiveEdmStaff(payload) {
    return {
        type: EDM_ARCHIVE_STAFF_REQUEST,
        payload,
    };
}

export function findContractorsForEdmStaff(payload) {
    return {
        type: EDM_STAFF_FIND_CONTRACTORS_REQUEST,
        payload,
    };
}

export function findUsersForEdmStaff(payload) {
    return {
        type: EDM_STAFF_FIND_USERS_REQUEST,
        payload,
    };
}

export function importEdmStaffs(payload) {
    return {
        type: EDM_STAFF_IMPORT_REQUEST,
        payload,
    };
}

export function edmStaffUserExist(payload) {
    return {
        type: EDM_STAFF_USER_EXIST_REQUEST,
        payload,
    };
}

export const edmStaffSelector = state => state.edmStaff;

export const edmStaffCardSelector = createSelector(edmStaffSelector, ({staffCard}) => staffCard);
export const edmStaffCardProgressSelector = createSelector(edmStaffSelector, ({gettingStaffProgress}) => gettingStaffProgress);

export const edmStaffActionProgressSelector = createSelector(edmStaffSelector, ({actionProgress}) => actionProgress);

export const edmStaffListSelector = createSelector(edmStaffSelector, ({list}) => list);
export const edmStaffListOptionsSelector = createSelector(edmStaffSelector, ({list}) => edoStaffListToOptions(list));
export const edmStaffListProgressSelector = createSelector(edmStaffSelector, ({listProgress}) => listProgress);
export const edmStaffListTotalCountSelector = createSelector(edmStaffSelector, ({totalCount}) => totalCount);
export const edmStaffListTotalPagesSelector = createSelector(edmStaffSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));

export const edmStaffFindingContractorListSelector = createSelector(edmStaffSelector, ({findingContractorsList}) => findingContractorsList);
export const edmStaffFindingContractorTotalPagesSelector = createSelector(edmStaffSelector,
    ({findingContractorsTotalCount, findingContractorsPageData: {pageSize = 0}}) => getTotalPages(findingContractorsTotalCount, pageSize));

export const edmStaffFindingUserListSelector = createSelector(edmStaffSelector, ({findingUsersList}) => findingUsersList);
export const edmStaffFindingUserProgressSelector = createSelector(edmStaffSelector, ({findingUsersProgress}) => findingUsersProgress);
export const edmStaffFindingUserTotalPagesSelector = createSelector(edmStaffSelector,
    ({findingUsersTotalCount, findingUsersPageData: {pageSize = 0}}) => getTotalPages(findingUsersTotalCount, pageSize));
export const clientUserStaffExistSelector = createSelector(edmStaffSelector, ({clientUserStaffExist}) => clientUserStaffExist);


export const getEdmStaffByIdSaga = function* (action) {
    try {
        const {payload: {
            data,
            onSuccess,
        }} = action;

        const {
            clientId,
            staffId,
        } = data;

        const result = yield request.get(`${edmStaffController}/${clientId}/${staffId}`);
        if (result.errorMessage) {
            yield put({type: EDM_GET_STAFF_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_GET_STAFF_SUCCESS, payload: result});

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_GET_STAFF_ERROR,
            payload: error,
        });
    }
};

export const getEdmStaffListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${edmStaffController}/richPage`, payload);

        if (result.errorMessage) {
            yield put({type: EDM_GET_STAFF_LIST_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_GET_STAFF_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(EDM_STAFF_LIST_REQUEST_TOAST.ERROR);

        yield put({
            type: EDM_GET_STAFF_LIST_ERROR,
            payload: error,
        });
    }
};

export const addEdmStaffSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`${edmStaffController}/add`, data);

        if (result.errorMessage) {
            yield put({type: EDM_ADD_STAFF_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_ADD_STAFF_SUCCESS, payload: result});

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Сотрудник добавлен успешно");

    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_ADD_STAFF_ERROR,
            payload: error,
        });
    }
};

export const updateEdmStaffSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {
            clientId,
            staffId,
        } = data;

        const result = yield request.patch(`${edmStaffController}/${clientId}/${staffId}`, {
            ...data,
            staffId: undefined,
        });

        if (result.errorMessage) {
            yield put({type: EDM_ADD_STAFF_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_ADD_STAFF_SUCCESS, payload: result});

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_ADD_STAFF_ERROR,
            payload: error,
        });
    }
};

export const findContractorsForEdmStaffSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`${edmStaffController}/suggestions/contractors/page`, data);

        if (result.errorMessage) {
            yield put({type: EDM_STAFF_FIND_CONTRACTORS_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_STAFF_FIND_CONTRACTORS_SUCCESS, payload: result});

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_STAFF_FIND_CONTRACTORS_ERROR,
            payload: error,
        });
    }
};

export const findUsersForEdmStaffSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${edmStaffController}/suggestions/users/page`, payload);

        if (result.errorMessage) {
            yield put({type: EDM_STAFF_FIND_USERS_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_STAFF_FIND_USERS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_STAFF_FIND_USERS_ERROR,
            payload: error,
        });
    }
};

export const archiveEdmStaffSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {
            clientId,
            staffId,
        } = data;

        const result = yield request.put(`${edmStaffController}/${clientId}/${staffId}/archive`, data);

        if (result.errorMessage) {
            yield put({type: EDM_ARCHIVE_STAFF_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_ARCHIVE_STAFF_SUCCESS, payload: result});

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_ARCHIVE_STAFF_ERROR,
            payload: error,
        });
    }
};

export const importEdmStaffSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                getResult,
            },
        } = action;

        const result = yield request.post("/job/import/staff/", data, {...getMultipartHeaders()});

        if (getResult) {
            getResult(result);
        }

        if (result.errorMessage) {
            yield put({type: EDM_ARCHIVE_STAFF_ERROR, payload: result.errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: EDM_ARCHIVE_STAFF_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);

        yield put({
            type: EDM_ARCHIVE_STAFF_ERROR,
            payload: error,
        });
    }
};

export const edmStaffUserExistSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.get(`${edmStaffController}/${clientId}/user/${clientUserId}/exist`);

        if (result.errorMessage) {
            yield put({
                type: EDM_STAFF_USER_EXIST_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: EDM_STAFF_USER_EXIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_STAFF_USER_EXIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(EDM_GET_STAFF_REQUEST, getEdmStaffByIdSaga),
        takeEvery(EDM_GET_STAFF_LIST_REQUEST, getEdmStaffListSaga),
        takeEvery(EDM_ADD_STAFF_REQUEST, addEdmStaffSaga),
        takeEvery(EDM_UPDATE_STAFF_REQUEST, updateEdmStaffSaga),
        takeEvery(EDM_ARCHIVE_STAFF_REQUEST, archiveEdmStaffSaga),
        takeEvery(EDM_STAFF_FIND_CONTRACTORS_REQUEST, findContractorsForEdmStaffSaga),
        takeEvery(EDM_STAFF_FIND_USERS_REQUEST, findUsersForEdmStaffSaga),
        takeEvery(EDM_STAFF_IMPORT_REQUEST, importEdmStaffSaga),
        takeEvery(EDM_STAFF_USER_EXIST_REQUEST, edmStaffUserExistSaga),
    ]);
}