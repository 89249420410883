import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getClientListFilterRequestData} from "../utils/getFilterRequestData";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";

import {getAdmClientPageOptimized} from "../../../ducks/bff/adm/client/services";
import {getClientSystemProperties} from "../../../ducks/bff/clients/system-properties/actionCreators";
import {updateFieldClientStore} from "../../../ducks/client";
import {getClientUserRestrictionsList} from "../../../ducks/clientUserRestrictions";

export const useClientListFetch = ({filterData, pageNum, pageSize, archivedFilter}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize, archivedFilter]);

    useEffect(() => {
        dispatch(getClientSystemProperties());
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessCompanySettings,
                CLIENT_USER_RESTRICTIONS_VARIABLE.settingAutoSendPayment,
            ],
        }));

        return () => {
            dispatch(updateFieldClientStore("card", {}));
        };
    }, []);

    const fetchList = () => {
        const filterRequestData = getClientListFilterRequestData(filterData);

        const requestData = {
            pageNum,
            pageSize,
            ...filterRequestData,
            archivedFilter,
            includeBase64Logo: true,
        };

        dispatch(getAdmClientPageOptimized(requestData));
    };

    return {
        fetchList,
    };
};