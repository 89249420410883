import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmModalCardList from "../../../../../../components/ActualComponents/NmModal/CardList";
import NmPagination from "../../../../../../components/ActualComponents/NmPagination";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";

import {usePagination} from "../../../../../../hooks/usePagination";
import useDataForm from "./hooks/useFormData";

import {getFullName, phoneFormat} from "../../../../../../utils/stringFormat";

import {PHONE_MASK} from "../../../../../../constants/phone";
import {CONTRACTOR_SEARCH_TYPE, CONTRACTOR_SEARCH_TYPE_OPTIONS} from "../../../../../../constants/registry";

import {
    contractorProgressSelector,
    contractorSearchListSelector,
    contractorTotalCountSelector,
    contractorTotalPagesSelector,
} from "../../../../../../ducks/contractor";

function InvitationContractorEditForm(props) {
    const {
        editItem,
        clientId,
        registryInvitationId,
        isEdit,
        onClose,
        fetchList,
    } = props;

    const list = useSelector(contractorSearchListSelector);
    const totalPages = useSelector(contractorTotalPagesSelector);
    const totalCount = useSelector(contractorTotalCountSelector);
    const progressSearch = useSelector(contractorProgressSelector);

    const {
        pageNum,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        onClear,
        touched,
        setFieldTouched,
        isContractorsListFrom,
        setIsContractorsListFrom,
        submitRegistryItem,
        setIsSearchContractor,
    } = useDataForm({
        clientId,
        registryInvitationId,
        isEdit,
        list,
        totalCount,
        progressSearch,
        pageNum,
        onClose,
        editItem,
        fetchList,
    });

    const {
        searchType,
        phoneNumber,
        fullName,
        orderNum,
    } = values;

    const getCards = () => {
        return list.map(item => {
            return {
                ...item,
                singleValues: [
                    {
                        label: "ФИО",
                        value: item.lastName ? getFullName(item.lastName, item.firstName, item.patronymic) : item.fullName,
                    },
                    {label: "Телефон", value: phoneFormat(item.phone)},
                ],
                actions: <NmButton
                    color="light-green"
                    className="registry-found-contractors__pick"
                    onClick={() => submitRegistryItem(item.contractorId)}
                >
                    Выбрать
                </NmButton>,
            };
        });
    };

    const renderContractorsListFromFooter = () => {
        return (
            <div className="flex flex-content-spaced fluid-flex-grow">
                <NmButton
                    color="grey"
                    size="xl"
                    onClick={() => {
                        setIsSearchContractor(false);
                        setIsContractorsListFrom(false);
                    }}
                >
                    Назад
                </NmButton>
                <NmButton
                    color="grey"
                    size="xl"
                    onClick={onClose}
                >
                    Отменить
                </NmButton>
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Приглашение на заказ
                </NmTitle>
            }
            footer={
                isContractorsListFrom ?
                    renderContractorsListFromFooter() :
                    <div className="flex flex-content-spaced fluid-flex-grow">
                        <NmButton
                            color="grey"
                            size="xl"
                            onClick={onClear}
                        >
                            Очистить
                        </NmButton>
                        <ApplyButtons
                            isHiddenCancelOnMobile
                            mobile="column"
                            loading={progressSearch}
                            onClose={onClose}
                            submit={handleSubmit}
                            cancelBtnContent="Отменить"
                            submitBtnContent="Подтвердить"
                        />
                    </div>
            }
            onClose={onClose}
        >
            {
                isContractorsListFrom ?
                    <NmForm>
                        <NmModalCardList
                            list={getCards()}
                            pagination={
                                <NmPagination
                                    pageNum={pageNum}
                                    totalPages={totalPages}
                                    onChangePagination={onPaginationChange}
                                />
                            }
                        />
                    </NmForm> :
                    <NmForm
                        onSubmit={handleSubmit}
                    >
                        <div className="flex flex-content-spaced fluid-flex-grow">
                            <NmDropdownV2
                                size="xl"
                                name="searchType"
                                value={searchType}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                options={CONTRACTOR_SEARCH_TYPE_OPTIONS}
                            />
                            {
                                searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ?
                                    <NmInputV2
                                        size="xl"
                                        className="ms-3"
                                        placeholder="Телефон"
                                        mask={PHONE_MASK.FOREIGN}
                                        onChange={handleChange}
                                        onBlur={() => setFieldTouched("phoneNumber", true)}
                                        maskChar={null}
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        error={touched?.phoneNumber && errors.phoneNumber}
                                    /> :
                                    <NmInputV2
                                        size="xl"
                                        className="ms-3"
                                        placeholder="ФИО"
                                        onChange={handleChange}
                                        onBlur={() => setFieldTouched("fullName", true)}
                                        maskChar={""}
                                        name="fullName"
                                        value={fullName}
                                        error={touched?.fullName && errors?.fullName}
                                    />
                            }
                        </div>
                        <NmInputV2
                            className="mt-5"
                            size="xl"
                            placeholder="Введите номер заказа"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("orderNum", true)}
                            maskChar={""}
                            name="orderNum"
                            value={orderNum}
                            error={touched?.orderNum && errors?.orderNum}
                        />
                    </NmForm>
            }
        </NmModal>
    );
}

export default InvitationContractorEditForm;