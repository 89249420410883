import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getListClients,
} from "../../../../../../ducks/clientGroups";

export function useGetListClients({filter, pageSize, pageNum, groupId, importProgress}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!importProgress) {
            fetchList();
        }
    }, [filter, pageSize, pageNum, groupId, importProgress]);

    function fetchList() {
        const requestData = {
            pageSize,
            pageNum,
            groupId,
            ...filter,
        };

        dispatch(getListClients(requestData));
    }
}