import {useEffect, useState} from "react";

import {checkPassportDataBy, checkPassportDataForeigner, checkPassportDataRu} from "../../controlDateReadOnly";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

const useReadFormError = (values, card) => {
    const [errorsReadOnlyCard, setErrorsReadOnlyCard] = useState("");

    useEffect(() => {
        const citizenship = values[CONTRACTOR_FIELD.CITIZENSHIP];
        let error = "";

        if (citizenship === citizenshipsDict.RU.value) {
            error = checkPassportDataRu({values, card});
        }

        if (citizenship === citizenshipsDict.BY.value) {
            error = checkPassportDataBy({values, card});
        }

        if (![citizenshipsDict.RU.value, citizenshipsDict.BY.value].includes(citizenship)) {
            error = checkPassportDataForeigner({values, card});
        }

        setErrorsReadOnlyCard(error);

    }, [values]);

    return {
        errorsReadOnlyCard,
    };
};

export default useReadFormError;