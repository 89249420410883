import React, {useEffect,useState} from "react";
import {isEmpty,isEqual} from "lodash";

import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmIcon from "../NmIcon";
import OrderScheduleAdd from "./Add";

import {toastWarning} from "../../utils/toastHelper";

import "./style.sass";

export const handleSchedulesFrom = (data, reducedDays, disabledCheckbox) => {
    const _reducedDays = JSON.parse(JSON.stringify(reducedDays));

    return data.reduce((prev, {dayOfWeek, startWorkTime, endWorkTime}, index) => {
        const _prev = [...prev];
        const dayConfig = _prev.find(value => (value.name === dayOfWeek)) || {};

        dayConfig.disabledCheckbox = disabledCheckbox;
        dayConfig.checked = true;
        dayConfig.times.push({startWorkTime, endWorkTime, position: index});

        return _prev;
    }, _reducedDays);
};

export const handleSchedulesTo = (days) => {
    return days.reduce((accum, day) => {
        const {name: dayOfWeek, times, checked} = day;

        if (!checked || !times) {
            return accum;
        }

        if (times.length === 0) {
            return accum;
        }

        times.forEach(({startWorkTime, endWorkTime}) => {
            accum.push({dayOfWeek, startWorkTime, endWorkTime});
        });

        return accum;
    }, []);
};

const getNumberFromTime = time => +time.replace(":", "");

const OrderSchedule = (props) => {
    const {
        className,
        title,
        handleChange,
        formDataSchedules,
    } = props;

    const [days, setDays] = useState([]);
    const [open, setOpen] = useState(false);
    const [addConfig, setAddConfig] = useState({});

    useEffect(() => {
        if (!isEqual(formDataSchedules, days)) {
            setDays(formDataSchedules);
        }
    }, [formDataSchedules]);

    useEffect(() => {
        if (!isEmpty(days)) {
            handleChange(null, {name: "schedules", value: days});
        }
    }, [days]);

    const onChange = (event, {name, checked}) => {
        const day = days.find(item => (item.name === name));

        if (day.disabled) {
            toastWarning("Данный день недели не входит в заданный период работ");

            return;
        }

        day.checked = checked;

        setDays([...days]);
    };

    const removeTime = (position, dayName) => {
        const _days = [...days];
        const day = _days.find(day => (day.name === dayName)) || {};

        const delPosition =  day.times && day.times.findIndex(time => (time.position === position)) || {};

        day.times.splice(delPosition, 1);

        setDays(_days);
    };

    const addTime = (dayName) => {
        const {times} = days.find(day => (day.name === dayName)) || {};

        setOpen(!open);
        setAddConfig({dayName, times});
    };

    const submit = (time) => {
        const {dayName} = addConfig;
        const _days = [...days];
        const day = _days.find(day => (day.name === dayName)) || {};

        const position = day.times.length === 0 ? 0 : day.times.length - 1;

        day.times.push({...time, position});

        day.times.sort(({startWorkTime: aStart}, {startWorkTime: bStart}) => getNumberFromTime(aStart) - getNumberFromTime(bStart));

        setDays(_days);
    };

    const getDays = () => {
        return days.map((day, index) => {
            const {
                text,
                name,
                checked,
                times,
                errorMessage,
                disabledCheckbox,
            } = day;

            return (
                <div
                    key={index}
                    className="order-schedule__container"
                >
                    <div className="order-schedule__error">
                        {errorMessage && errorMessage}
                    </div>
                    <div className="order-schedule__col">
                        <NmCheckboxV2
                            disabled={disabledCheckbox}
                            label={text}
                            name={name}
                            checked={checked}
                            onChange={onChange}
                        />
                        {
                            checked &&
                                <div className="order-schedule__timetable">
                                    {getTimes(times, name, disabledCheckbox)}
                                </div>
                        }
                    </div>
                </div>
            );
        });
    };

    const getTimes = (times, dayName, disabledCheckbox) => {
        return (
            <>
                {
                    times.map(({startWorkTime, endWorkTime}, index) =>
                        <div
                            key={index}
                            className="order-schedule__control"
                        >
                            <div className="order-schedule__time">
                                <div className="order-schedule__time-start">
                                    {startWorkTime}
                                </div>
                                <div className="order-schedule__time-end">
                                    {endWorkTime}
                                </div>
                            </div>
                            {
                                !disabledCheckbox &&
                                <NmIcon
                                    name="close"
                                    className="order-schedule__close"
                                    onClick={() => {removeTime(index, dayName);}}
                                />
                            }
                        </div>,
                    )
                }
                <div
                    onClick={() => {addTime(dayName);}}
                    className="order-schedule__time"
                >
                    <div className="order-schedule__time-start">
с
                    </div>
                    <div className="order-schedule__time-end">
по
                    </div>
                </div>
            </>
        );
    };

    const getModalAdd = () => {
        const {times} = addConfig;

        return open  &&
            <OrderScheduleAdd
                times={times}
                submit={submit}
                close={addTime}
            />;
    };

    return (
        <div className={`order-schedule ${className}`}>
            {title}
            <div className="order-schedule__row">
                {getDays()}
                {getModalAdd()}
            </div>
        </div>
    );
};

OrderSchedule.propTypes = {};

export default OrderSchedule;