import {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {AppContext} from "../components/AppContext";

export const useLoadScrollPosition = (params = {}) => {
    const {
        isUpdateScrollPosition,
    } = params;

    const {
        containerRef,
    } = useContext(AppContext);

    const location = useSelector((state) => state.router.location);

    const {
        state = {},
        pathname,
    } = location;

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (isEmpty(state) || !containerRef?.current) {
            return;
        }

        if (
            !state.scrollTop
            // для предотвращения выполнения логики в случае если переходим на страницу с таким же компонентом,
            // где используется хук
            || state.pathname !== pathname
        ) {
            return;
        }

        if (
            typeof isUpdateScrollPosition !== "boolean"
            && state.pathname === pathname
        ) {
            containerRef.current.scrollTop = state.scrollTop;

            return;
        }

        if (isUpdateScrollPosition || isLoaded) {
            return;
        }

        setIsLoaded(true);
        containerRef.current.scrollTop = state.scrollTop;
    }, [
        isUpdateScrollPosition,
    ]);

    return null;
};