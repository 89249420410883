import {useState} from "react";

import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {CROWD_CHAT_STATUS_FILTER_TYPE} from "../../../../constants/crowd/chats";

export function useChatClientFilter({initFilter, isCrowd}) {
    const [filterDto, setFilter] = useState(initFilter);
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            projectIdFilter,
            objectIdFilter,
            specialityIds,
            contractorPhone,
            contractorNameFilter,
            messageStatus,
            ...filterData
        } = filter;

        if (isCrowd) {
            return {
                specialityIds: specialityIds?.length ? specialityIds : undefined,
                contractorPhone: contractorPhone ? removePhoneMask(contractorPhone) : undefined,
                contractorFio: handleFormString(contractorNameFilter),
                messageStatus: messageStatus ? messageStatus : CROWD_CHAT_STATUS_FILTER_TYPE.ALL,
            };
        }

        return {
            projectIdsFilter: projectIdFilter ? [projectIdFilter] : undefined,
            objectIdsFilter: objectIdFilter ? [objectIdFilter] : undefined,
            contractorNameFilter: handleFormString(contractorNameFilter),
            ...filterData,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
    };
}