import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getContractorScoresActionProgressSelector,
    getContractorScoresCount,
    getContractorScoresListSelector,
    getContractorScoresPage,
    getContractorScoresProgressPageSelector,
    getContractorScoresTotalCountSelector,
    getContractorScoresTotalPagesSelector,
} from "../../../ducks/contractorScores";
import {
    clientFeedbackListProgressSelector,
    clientFeedbackListSelector,
    clientFeedbackListTotalCountSelector,
    clientFeedbackListTotalPagesSelector,
    getClientFeedbackCount,
    getClientFeedbackPage,
    getProgressClientFeedbackActionSelector,
} from "../../../ducks/scores";

import {SUBPAGE_CLIENTS_REVIEWS} from "../../../constants/link-params";

export function useReviewsFetchList({pageSize, pageNum, filter, subpage}) {
    const listClient = useSelector(clientFeedbackListSelector);
    const totalCountClient = useSelector(clientFeedbackListTotalCountSelector);
    const totalPagesClient = useSelector(clientFeedbackListTotalPagesSelector);
    const progressPageClient = useSelector(clientFeedbackListProgressSelector);
    const progressClientAction = useSelector(getProgressClientFeedbackActionSelector);
    const listContractor = useSelector(getContractorScoresListSelector);
    const totalCountContractor = useSelector(getContractorScoresTotalCountSelector);
    const totalPagesContractor = useSelector(getContractorScoresTotalPagesSelector);
    const progressPageContractor = useSelector(getContractorScoresProgressPageSelector);
    const progressContractorAction = useSelector(getContractorScoresActionProgressSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum, subpage]);

    useEffect(() => {
        getContractorReviewsCount();
        getClientReviewsCount();
    }, []);


    const fetchList = () => {
        if (subpage === SUBPAGE_CLIENTS_REVIEWS) {
            dispatch(getClientFeedbackPage({
                pageNum,
                pageSize,
                emptyReviewText: false,
                ...filter,
            }));

            return;
        }

        dispatch(getContractorScoresPage({
            pageNum,
            pageSize,
            emptyReviewText: false,
            ...filter,
        }));
    };

    const getContractorReviewsCount = () => {
        dispatch(getContractorScoresCount({
            emptyReviewText: false,
        }));
    };

    const getClientReviewsCount = () => {
        dispatch(getClientFeedbackCount({
            emptyReviewText: false,
        }));
    };

    const getListByTab = () => {
        if (subpage === SUBPAGE_CLIENTS_REVIEWS) {
            return ({
                list: listClient,
                totalCount: totalCountClient,
                totalPages: totalPagesClient,
                progressPage: progressPageClient,
                progressAction: progressClientAction,
            });
        }

        return ({
            list: listContractor.map(item => ({
                contractorScore: true,
                ...item,
            })),
            totalCount: totalCountContractor,
            totalPages: totalPagesContractor,
            progressPage: progressPageContractor,
            progressAction: progressContractorAction,
        });
    };

    const {
        list,
        totalCount,
        totalPages,
        progressPage,
        progressAction,
    } = getListByTab();

    return {
        fetchList,
        totalCountContractor,
        totalCountClient,
        list,
        totalCount,
        totalPages,
        progressPage,
        progressAction,
        getContractorReviewsCount,
        getClientReviewsCount,
    };
}