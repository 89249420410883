export const BANK_IDENTIFICATION_STATUS = {
    NOT_IDENTIFIED: "NOT_IDENTIFIED",
    SIMPLIFIED_IDENTIFIED: "SIMPLIFIED_IDENTIFIED",
    IDENTIFIED: "IDENTIFIED",
};

export const BANK_IDENTIFICATION_STATUS_DICT = {
    [BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED]: "Лимиты “Не идентифицированный”, ₽",
    [BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED]: "Лимиты “Упрощенно идентифицированный”, ₽",
    [BANK_IDENTIFICATION_STATUS.IDENTIFIED]: "Лимиты “Полная идентификация”, ₽",
};