import {
    getCommonOnBoardingScenarioFrameContractTypeDict,
    getOnboardingContractorSourceDict,
    getPhoneCodeAll,
} from "../ducks/bff/common/dicts/actionCreators";
import {
    getCallTypeStatus,
    getCaseStatus,
    getCitizenships,
    getCitizenshipsSmzOnly,
    getMigrationStatusDict,
    getVhiPolicyFormats,
    getVhiPolicyTypes,
    getWorkRegions,
} from "../ducks/contractor";
import {getContractorEventDict} from "../ducks/contractorEventLog";
import {
    getContractorsAdditionalDocumentFileTypeDict,
    getContractorsAdditionalDocumentStatusDict,
    getContractorsAdditionalDocumentTypeDict,
    getContractorsRecruitmentCurrencyDict,
    getContractorsRecruitmentSchedules,
    getContractorsRecruitmentSocialMediaDict,
    getContractorsRecruitmentWorkExperienceDict,
} from "../ducks/contractorsDicts";
import {
    fetchContractorArrivalDocTypeDict,
    fetchContractorPassportTypeDict,
    fetchIndustries,
    fetchUnits,
} from "../ducks/dict";
import {
    getDocumentStatusDict,
    getDocumentTypeDictsList,
    getDocumentTypeSignatureLogDicts,
    getOperatorReportStatusList,
    getUserInstructionTypeDict,
} from "../ducks/documents";
import {getExportDocumentStatusDict} from "../ducks/exportDocument";
import {getFederalMigrationDict} from "../ducks/federalMigration";
import {
    getContractorStatuses,
    getDepositHistoryApisDict,
    getForemanPaymentStatuses,
    getForemanRequestStatues,
    getForemanRequestTypes,
    getIndustryProfessions,
    getOrderStatusList,
    getOrderWorkUnits,
    getProfessions,
} from "../ducks/order";
import {getSpecialitiesAllV2} from "../ducks/speciality";

export const catalogsDispatchList = [
    fetchUnits,
    getOrderStatusList,
    getDocumentTypeDictsList,
    getDocumentStatusDict,
    fetchIndustries,
    getContractorStatuses,
    getProfessions,
    getCitizenships,
    getCitizenshipsSmzOnly,
    getVhiPolicyFormats,
    getVhiPolicyTypes,
    getCaseStatus,
    getCallTypeStatus,
    getWorkRegions,
    getFederalMigrationDict,
    getIndustryProfessions,
    fetchContractorArrivalDocTypeDict,
    fetchContractorPassportTypeDict,
    getOperatorReportStatusList,
    getUserInstructionTypeDict,
    getDocumentTypeSignatureLogDicts,
    getForemanPaymentStatuses,
    getForemanRequestStatues,
    getForemanRequestTypes,
    getOrderWorkUnits,
    getExportDocumentStatusDict,
    getContractorsAdditionalDocumentTypeDict,
    getContractorsAdditionalDocumentStatusDict,
    getContractorsAdditionalDocumentFileTypeDict,
    getMigrationStatusDict,
    getContractorEventDict,
    getDepositHistoryApisDict,
    getCommonOnBoardingScenarioFrameContractTypeDict,
    getOnboardingContractorSourceDict,
    getSpecialitiesAllV2,
    getPhoneCodeAll,
    getContractorsRecruitmentCurrencyDict,
    getContractorsRecruitmentWorkExperienceDict,
    getContractorsRecruitmentSocialMediaDict,
    getContractorsRecruitmentSchedules,
];