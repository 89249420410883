import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {toastSuccess} from "../../../../../../../utils/toastHelper";

import {CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE} from "../../../../../../../constants/crowd/task";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

import {rejectCrowdTaskContractors} from "../../../../../../../ducks/bff/crowd/taskCard/actionCreators";

const useCrowdTaskCardContractorRejectModalForm = (props) => {
    const {
        onClose,
        fetchInfo,
        clientId,
        taskId,
        contractorId,
    } = props;

    const dispatch = useDispatch();

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            rejectionReasonType,
            rejectionReasonDescription,
        } = values;

        dispatch(rejectCrowdTaskContractors({
            clientId,
            taskId,
            contractorId,
            rejectionReasonType,
            rejectionReasonDescription: rejectionReasonType === CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE.OTHER.VALUE ?
                rejectionReasonDescription :
                undefined,
            onSuccess: () => {
                onClose();
                fetchInfo();
                toastSuccess("Исполнитель отклонен");
            },
        }));
    };

    const {
        values,
        handleSubmit,
        errors,
        touched,
        handleChange,
        setFieldValue,
        isValid,
    } = useFormik({
        onSubmit,
        initialValues: {
            rejectionReasonType: CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE.APPLIED_BY_MISTAKE.VALUE,
            rejectionReasonDescription: "",
        },
        validateOnBlur: false,
        enableReinitialize: false,
        validationSchema: yup.object().shape({
            rejectionReasonDescription: yup.string().when("rejectionReasonType", {
                is: CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE.OTHER.VALUE,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        }),
    });

    useEffect(() => {
        setFieldValue("rejectionReasonDescription", "");
    }, [values.rejectionReasonType]);

    return {
        values,
        handleChange,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
    };
};

export default useCrowdTaskCardContractorRejectModalForm;