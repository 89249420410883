import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

export const isPartiallyCheckedClient = (params) => {
    const {
        client,
        checked,
        projects,
        nextAllPagesSelectionType,
    } = params;

    if (
        typeof client.changedCheckedValue === "boolean"
        && client.changedCheckedValue !== checked
        && nextAllPagesSelectionType
    ) {
        return true;
    }

    const isSomeProjectPartiallyChecked = projects.some(item => item.partiallyChecked);
    const isSomeProjectNotEqual = projects.some(item => item.checked !== checked);
    const isEveryProjectHasEqualValue = projects.some(item => item.checked === projects[0].checked);
    const isEveryProjectChecked = isEveryProjectHasEqualValue && projects[0].checked;
    const isEveryProjectNotChecked = isEveryProjectHasEqualValue && !projects[0].checked;

    return isSomeProjectNotEqual
        || isSomeProjectPartiallyChecked
        || nextAllPagesSelectionType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY
        || isEveryProjectChecked && nextAllPagesSelectionType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY
        || isEveryProjectNotChecked && nextAllPagesSelectionType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY;
};