import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageRecruitmentDirectoryJobRequirement,
    updateRecruitmentDirectoryStore,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

const useRecruitmentDirectoriesJobRequirementsFetchList = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                jobRequirementPageData: {},
                jobRequirementList: [],
                jobRequirementTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const fetchList = () => {
        dispatch(getPageRecruitmentDirectoryJobRequirement({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};

export default useRecruitmentDirectoriesJobRequirementsFetchList;