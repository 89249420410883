import React, {createContext} from "react";

export const MapInviteModalContext = createContext({});

const MapInviteModalContextProvider = (props) => {
    const {
        data,
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        isInviteToOrderOrTask,
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
        children,
    } = props;

    return (
        <MapInviteModalContext.Provider
            value={{
                data,
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                isInviteToOrderOrTask,
                selectedList,
                countSelected,
                handleSelectedRows,
                clearSelectedRows,
            }}
        >
            {children}
        </MapInviteModalContext.Provider>
    );
};

export default MapInviteModalContextProvider;