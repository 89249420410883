import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import RejectionSigningDocumentModal from "../../../components/RejectionSigningDocumentModal";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as FileUploadIcon} from "../../../images/file_upload.svg";
import {ReactComponent as BlockedIcon} from "../../../images/lock_24.svg";
import {ReactComponent as NoOnIcon} from "../../../images/no_on.svg";
import {ReactComponent as YesOnIcon} from "../../../images/yes_on.svg";
import DocumentManagementDocumentListFilter from "../document-management-document-list-filter";
import DocumentManagementLoadingDocument2 from "../document-managment-loading-document-v2";
import DocumentManagementDocumentListCodeConfirm from "./components/code-confirm";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFormattedFullName, replaceInformation} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    isUserFromNm,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {downloadDocument} from "../../../ducks/documents";
import {taskEdoExportDocument} from "../../../ducks/edmStatements";
import {
    archiveEdoDocument,
    clearEdoPreFilledTemplate,
    edoAccessListSelector,
    edoDocumentsListSelector,
    edoDocumentsTotalCountSelector,
    edoDocumentsTotalPagesSelector,
    edoSignProgressSelector,
    getRichPageEdoDocuments,
    signEdoDocument,
} from "../../../ducks/edocuments";

import "./style.sass";

import {
    EDO_ACCESS_RIGHTS,
    EDO_DOCUMENT_ACTION,
    EDO_DOCUMENT_STATUS,
    EDO_RECEIVER_STATUS,
    EDO_SENDER_STATUS,
} from "../../../constants/document-management/document-list";

const initFilterForm = {
    senderFio: "",
    documentName: "",
    receiverFio: "",
    documentDateTimeBefore: null,
    documentDateTimeAfter: null,
    uploadDateTimeBefore: null,
    uploadDateTimeAfter: null,
    documentTypes: [],
    documentStatus: undefined,
};

class DocumentManagementDocumentList extends Component {
    static propTypes = {};

    static defaultProps = {};

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    staffId,
                },
            },
        } = props;

        this.clientId = clientId;
        this.staffId = staffId;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            isShowLoadingModal: false,
            openConfirmWindow: false,
            showRejectionSigningDocumentModal: false,
            showCodeConfirmModal: false,
            showSignConfirmModal: false,
            action: {},
            selectedList: [],
            countSelected: 0,
            archived: false,
            loading: false,
            filter: {
                ...initFilterForm,
            },
            filterDto: {
                ...initFilterForm,
            },
            selectedDoc: {},
            isSearch: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList = () => {
        const {getRichPageEdoDocuments} = this.props;
        this.toggleLoading();

        this.setState({
            selectedList: [],
            countSelected: 0,
        });

        const {
            pageNum,
            pageSize,
            archived,
            filterDto,
        } = this.state;

        getRichPageEdoDocuments({
            clientId: this.clientId,
            pageNum,
            pageSize,
            archived,
            getSuccess: this.toggleLoading,
            staffId: this.staffId,
            ...filterDto,
        });
    };

    onPaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    onChangePageSize = pageSize => {
        this.setState(
            {
                dropdownPaymentNumber: "",
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handleDownload = ({docId: documentId}) => {
        const {downloadDocument} = this.props;

        downloadDocument({
            downloadLink: `${window.location.origin}/api/filestore/edocuments/signed/${documentId}`,
            extension: "pdf",
            fileName: `${documentId}_signed.pdf`,
        });
    };

    toggleShowLoadingModal = (selectedDoc = {}) => {
        this.setState(prevState => ({
            ...prevState,
            isShowLoadingModal: !prevState.isShowLoadingModal,
            selectedDoc,
        }));
    };

    handleSelectedRows = (selectedList, isAllSelected) => {
        this.setState({
            isAllSelected,
            selectedList,
            countSelected: selectedList.filter(value => value.isSelected).length,
        });

    };

    get selectedDocumentIds() {
        const {selectedList} = this.state;

        return selectedList.filter(value => value.isSelected).map(({docId}) => docId);
    }

    renderStatus(item) {
        const {docStatus: status} = item;

        const {
            MOD,
            TEXT,
        } = EDO_DOCUMENT_STATUS[status];

        return (
            <NmBadge
                mod={MOD}
                text={TEXT}
            />
        );
    }

    renderCard({mod, rejectReason, text, fullName, isBlocked}) {
        const Icon = mod === EDO_SENDER_STATUS.REJECTED.MOD ? NoOnIcon : YesOnIcon;

        return {
            title: text,
            className: "col-16 col-md-5 col-xxl-3 mt-md-4 mt-xxl-0",
            icon: (
                <Icon
                    className={`document-management-document-list-content-cards-card__icon document-management-document-list-content-cards-card__icon_${mod}`}
                    width={16}
                    height={16}
                />
            ),
            fluidValue: true,
            value: (
                <div className="document-management-document-list-content-cards-card__fullname">
                    {
                        <div className="document-management-document-list-content-cards-card__fullname-text">
                            {getFormattedFullName(fullName || "")}
                        </div>
                    }
                    {
                        isBlocked &&
                        <BlockedIcon
                            className="document-management-document-list__card-value-icon"
                            color={COLOR.NEGATIVE_100}
                        />
                    }
                    {
                        rejectReason &&
                        <HelpTooltip
                            type="light"
                            className="document-management-document-list__card-value-icon"
                            position="bottom-right"
                        >
                            {rejectReason}
                        </HelpTooltip>
                    }
                </div>
            ),
        };
    }

    toggleRejectionSigningDocumentModal = () => {
        this.setState(({showRejectionSigningDocumentModal}) => ({
            showRejectionSigningDocumentModal: !showRejectionSigningDocumentModal,
        }));
    };

    toggleCodeConfirm = () => {
        const {
            clientProperties: {
                edmSignWithoutSmsCodeEnable,
            },
        } = this.props;

        if (edmSignWithoutSmsCodeEnable) {
            this.setState(({showSignConfirmModal}) => ({
                showSignConfirmModal: !showSignConfirmModal,
            }));

            return;
        }

        this.setState(({showCodeConfirmModal}) => ({
            showCodeConfirmModal: !showCodeConfirmModal,
        }));
    };

    toggleArchived = () => {
        this.setState(({archived}) => ({
            archived: !archived,
        }), this.fetchList);
    };

    toggleLoading = () => {
        this.setState(({loading}) => ({
            loading: !loading,
        }));
    };

    confirmSignDocumentsWithoutSmsCode = () => {
        const {
            signEdoDocument,
        } = this.props;

        signEdoDocument({
            documentIds: this.selectedDocumentIds,
            getResult: (errorMessage) => {
                if (isNullOrWhitespace(errorMessage)) {
                    this.fetchList();
                    this.toggleCodeConfirm();

                    return;
                }
                toastError(errorMessage);
            },
        });
    };

    /*
    Редактировать - кнопка для перехода к форме редактирования параметров документа, доступна только на
     странице активных (не архивных) документов в том случае ,если текущий пользователь является ответственным документа И статус документа = "На подписании", "Отклонен"
В архив - кнопка для переноса документа в архив, доступна только на странице активных (не архивных) документов при статусах документа "Подписан", "Отклонен"
Из архива - кнопка для переноса документа из архива, доступна только на странице архивных документов
    * */

    getOptions(item) {
        const {edoAccessList} = this.props;

        if (![CLIENT_ADMIN, HR_MANAGER, PROJECT_MANAGER].includes(this.role)) {
            return null;
        }

        const {
            currentUser: {
                clientUserId: currentUserId,
            },
        } = this.props;

        const {
            archived,
            docStatus,
            ownerSignInfo: {
                clientUserId: ownerClientUserId,
            },
        } = item;
        const ACTION = {
            EDIT: {
                key: EDO_DOCUMENT_ACTION.EDIT.VALUE,
                value: EDO_DOCUMENT_ACTION.EDIT.VALUE,
                text: EDO_DOCUMENT_ACTION.EDIT.TEXT,
            },
            IN_ARCHIVE: {
                key: EDO_DOCUMENT_ACTION.IN_ARCHIVE.VALUE,
                value: EDO_DOCUMENT_ACTION.IN_ARCHIVE.VALUE,
                text: EDO_DOCUMENT_ACTION.IN_ARCHIVE.TEXT,
            },
            FROM_ARCHIVE: {
                key: EDO_DOCUMENT_ACTION.FROM_ARCHIVE.VALUE,
                value: EDO_DOCUMENT_ACTION.FROM_ARCHIVE.VALUE,
                text: EDO_DOCUMENT_ACTION.FROM_ARCHIVE.TEXT,
            },
        };

        const isAccessEdit = edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_DOCUMENTS_EDIT);

        if (archived) {
            return isAccessEdit ? [
                ACTION.FROM_ARCHIVE,
            ] : null;
        }

        if (docStatus === EDO_DOCUMENT_STATUS.REJECTED.VALUE && isAccessEdit) {
            if (currentUserId === ownerClientUserId) {
                return [
                    ACTION.EDIT,
                    ACTION.IN_ARCHIVE,
                ];
            }
            return [
                ACTION.IN_ARCHIVE,
            ];
        }

        if (docStatus === EDO_DOCUMENT_STATUS.ON_SIGNING.VALUE && isAccessEdit) {
            if (currentUserId === ownerClientUserId) {
                return [
                    ACTION.EDIT,
                ];
            }
            return null;
        }

        return isAccessEdit ? [
            ACTION.IN_ARCHIVE,
        ] : null;
    }

    archive(documentId, archived) {
        const {archiveEdoDocument} = this.props;
        archiveEdoDocument({
            documentId,
            archived,
            getResult: (errorMessage) => {
                if (!errorMessage) {
                    this.fetchList();
                }
            },
        });
    }

    onClickActionItem({value}, item) {
        switch (value) {
        case EDO_DOCUMENT_ACTION.IN_ARCHIVE.VALUE:
        case EDO_DOCUMENT_ACTION.FROM_ARCHIVE.VALUE: {
            this.archive(item.docId, value === EDO_DOCUMENT_ACTION.IN_ARCHIVE.VALUE);
            break;
        }
        case EDO_DOCUMENT_ACTION.EDIT.VALUE: {
            this.toggleShowLoadingModal(item);
            break;
        }
        default:
            break;
        }
    }

    getContentRow(showCheckBox, item) {
        const {
            dateDocument,
            dateTimeUpload,
            docName,
            docType,
            responsibleFio,
            receiverSignInfo: {
                fio: receiverFio,
                signStatus: receiverStatus,
                rejectReason: receiverRejectReason,
                contractorBlocked: receiverBlocked,
            },
            senderSignInfo: {
                fio: senderFio,
                signStatus: senderStatus,
                rejectReason: senderRejectReason,
                contractorBlocked: senderBlocked,
            },
            information,
        } = item;

        const {
            MOD: receiverMod,
            TEXT: receiverText,
        } = EDO_RECEIVER_STATUS[receiverStatus] || {};
        const {
            MOD: senderMod,
            TEXT: senderText,
        } = EDO_SENDER_STATUS[senderStatus] || {};

        const options = this.getOptions(item);

        const _information = !isNullOrWhitespace(information) ? [{
            label: "Дополнительные сведения",
            text: (
                <Text
                    noWrap
                    dangerouslySetInnerHTML={{__html: replaceInformation(information)}}
                    level="3"
                />
            ),
        }] : [];

        return (
            <NmListCard
                checkbox
                primaryHeaderLink
                classNameMainContent="col-16 col-xxl-9"
                secondaryHeaderStatus={this.renderStatus(item)}
                secondaryHeader={`Документ от ${formatLocalDate(dateTimeUpload, "dd.MM.yyyy HH:mm")}`}
                primaryHeader={
                    <div
                        onClick={() => {
                            this.handleDownload(item);
                        }}
                    >
                        {docName}
                    </div>
                }
                labels={[
                    {label: "Тип документа", text: docType},
                    {label: "Дата документа", text: formatLocalDate(dateDocument)},
                    {label: "Ответственный", text: getFormattedFullName(responsibleFio || "")},
                    ..._information,
                ]}
                cards={[
                    this.renderCard({
                        status: senderStatus,
                        rejectReason: senderRejectReason,
                        mod: senderMod,
                        fullName: senderFio,
                        text: senderText,
                        isBlocked: senderBlocked,
                    }),
                    this.renderCard({
                        status: receiverStatus,
                        rejectReason: receiverRejectReason,
                        mod: receiverMod,
                        fullName: receiverFio,
                        text: receiverText,
                        isBlocked: receiverBlocked,
                    }),
                ]}
                actionsClassName="col-1"
                actions={options !== null &&
                <ContextMenu
                    options={options}
                    onClickItem={(option) => {
                        this.onClickActionItem(option, item);
                    }}
                />
                }
            />
        );
    }

    getPermission(item) {
        const {
            currentUser: {
                clientUserId: currentUserId,
            },
        } = this.props;

        const {
            receiverSignInfo: {
                clientUserId: receiverClientUserId,
                signStatus: receiverStatus,
            },
            senderSignInfo: {
                clientUserId: senderClientUserId,
                signStatus: senderStatus,
            },
        } = item;

        if (senderClientUserId === currentUserId && receiverClientUserId === currentUserId) {
            return [senderStatus, receiverStatus].includes(EDO_SENDER_STATUS.ON_SIGNED.VALUE);
        }

        if (senderClientUserId === currentUserId) {
            return senderStatus === EDO_SENDER_STATUS.ON_SIGNED.VALUE;
        }

        if (receiverClientUserId === currentUserId) {
            return receiverStatus === EDO_RECEIVER_STATUS.ON_SIGNED.VALUE;
        }

        return false;
    }

    getRows() {
        const {
            edoDocumentsList,
        } = this.props;
        const {
            selectedList,
            archived,
        } = this.state;


        return edoDocumentsList.map((item) => {
            const {isSelected = false} = selectedList.find(_item => (_item.docId === item.docId)) || {};

            const showCheckBox = EDO_DOCUMENT_STATUS.ON_SIGNING.VALUE === item.docStatus &&
                !archived && this.getPermission(item);

            return {
                ...item,
                key: item.docId,
                isSelected,
                showCheckBox,
                contentRow: this.getContentRow(showCheckBox, item),
            };
        });
    }

    submitFilter = (filterDto) => {
        this.setState({
            filterDto,
            pageNum: 1,
            isSearch: true,
        }, this.fetchList);
    };

    onChangeFilter = (filter) => {
        this.setState({
            filter,
        });
    };

    clearFilter = () => {
        this.setState({
            filterDto: {
                ...initFilterForm,
            },
            filter: {
                ...initFilterForm,
            },
            pageNum: 1,
            isSearch: false,
        }, this.fetchList);
    };

    renderList() {
        const {
            archived,
            countSelected,
        } = this.state;

        const rows = this.getRows();

        const hasShowCheckBox = rows.find(item => item.showCheckBox);

        return (
            <CheckboxList
                count={countSelected}
                mediaControls={{
                    renderCount: {
                        desktop: 2,
                        tablet: 2,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                size: "lg",
                                children: "Подписать",
                                onClick: this.toggleCodeConfirm,
                                disabled: countSelected === 0,
                            },
                            isVisible: !archived,
                        },
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                size: "lg",
                                children: "Отклонить",
                                onClick: this.toggleRejectionSigningDocumentModal,
                                disabled: countSelected === 0,
                            },
                            isVisible: !archived,
                        },
                    ],
                }}
                onSelectedRows={isUserFromNm(this.role) || archived || !hasShowCheckBox ? null : this.handleSelectedRows}
                rows={rows}
            />
        );
    }

    onAddTaskEdoExportDocument = () => {
        const {taskEdoExportDocument} = this.props;
        const {
            archived,
            filter,
        } = this.state;

        taskEdoExportDocument({
            clientId: this.clientId,
            archived,
            staffId: this.staffId,
            ...filter,
            getResult: (errorMessage) => {
                if (!errorMessage) {
                    toastSuccess("Выгрузка успешно запущена и в ближайшее время станет доступна в разделе ЭДО → Экспорт");
                }
            },
        });
    };

    getMediaControls = (isShowControls, isAccessLoad) => {
        const {t} = this.props;
        const {
            archived,
            loading,
        } = this.state;

        const archive = getArchiveButton(t, archived, {mobile: true});

        return {
            renderCount: {
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Загрузить документ",
                        icon: <FileUploadIcon />,
                        onClick: () => {
                            this.toggleShowLoadingModal();
                        },
                    },
                    visible: !archived && isShowControls && isAccessLoad,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Выгрузить",
                        onClick: this.onAddTaskEdoExportDocument,
                    },
                    visible: !archived && isShowControls,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: this.toggleArchived,
                        disabled: loading,
                    },
                },
            ],
        };
    };

    renderSignConfirmWindow = () => {
        const {singProgress} = this.props;
        const {showSignConfirmModal} = this.state;

        if (!showSignConfirmModal) {
            return null;
        }

        return (
            <NmConfirmV2
                size="sm"
                title="Подписание документа"
                content={"Нажмите на кнопку \"Подписать\" для подписания документа"}
                onCancel={this.toggleCodeConfirm}
                onConfirm={this.confirmSignDocumentsWithoutSmsCode}
                confirmButton="Подписать"
                loading={singProgress}
                disabled={singProgress}
                isOnlyConfirm
            />
        );
    };

    render() {
        const {
            pageNum,
            pageSize,
            isShowLoadingModal,
            showCodeConfirmModal,
            showRejectionSigningDocumentModal,
            archived,
            loading,
            selectedDoc,
            isSearch,
            filter,
        } = this.state;

        const {
            totalPages,
            edoAccessList,
            clearEdoPreFilledTemplate,
            totalCount,
        } = this.props;

        const isShowControls = totalCount || isSearch;
        const isAccessLoad = !isUserFromNm(this.role) && edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_DOCUMENTS_ADD) && ![FOREMAN, OBJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role);

        return (
            <NmPage
                header={
                    <NmPageHeader
                        text="Документы"
                        totalCount={totalCount}
                    />
                }
                isLoaded={loading}
                mediaControls={this.getMediaControls(isShowControls, isAccessLoad)}
                typeFilter="vertical"
                className="document-management-document-list"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.onChangePageSize}
                onPaginationChange={this.onPaginationChange}
                filtersBase={
                    <DocumentManagementDocumentListFilter
                        initForm={initFilterForm}
                        filter={filter}
                        clientId={this.clientId}
                        submitFilter={this.submitFilter}
                        clearFilter={this.clearFilter}
                        role={this.role}
                        onChangeFilter={this.onChangeFilter}
                    />
                }
                totalCount={totalCount}
            >
                {
                    showRejectionSigningDocumentModal &&
                    <RejectionSigningDocumentModal
                        fetchList={this.fetchList}
                        documentIds={this.selectedDocumentIds}
                        onClose={this.toggleRejectionSigningDocumentModal}
                    />
                }
                {
                    showCodeConfirmModal &&
                    <DocumentManagementDocumentListCodeConfirm
                        fetchList={this.fetchList}
                        documentIds={this.selectedDocumentIds}
                        onClose={this.toggleCodeConfirm}
                    />
                }
                {this.renderSignConfirmWindow()}
                {
                    isShowLoadingModal &&
                    <DocumentManagementLoadingDocument2
                        data={isEmpty(selectedDoc) ? undefined : selectedDoc}
                        fetchList={this.fetchList}
                        clientId={this.clientId}
                        onClose={() => {
                            this.toggleShowLoadingModal();
                            clearEdoPreFilledTemplate();
                        }}
                    />
                }
                {
                    !(totalCount || loading) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isShowAction={isAccessLoad && !archived}
                            textAction="Загрузить документ"
                            onClickAction={() => {
                                this.toggleShowLoadingModal();
                            }}
                            isSearch={isSearch}
                        /> :
                        this.renderList()
                }
            </NmPage>

        );
    }
}


export default withPageData(connect(
    state => ({
        edoDocumentsList: edoDocumentsListSelector(state),
        totalPages: edoDocumentsTotalPagesSelector(state),
        currentUser: clientCurrentMemberSelector(state),
        edoAccessList: edoAccessListSelector(state),
        totalCount: edoDocumentsTotalCountSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
        singProgress: edoSignProgressSelector(state),
    }),
    {
        getRichPageEdoDocuments,
        archiveEdoDocument,
        clearEdoPreFilledTemplate,
        downloadDocument,
        taskEdoExportDocument,
        signEdoDocument,
    },
)(withTranslation()(DocumentManagementDocumentList)));
