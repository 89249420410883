import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const kedoDocumentsExportSelector = state => state.kedoExports;
export const kedoExportsListSelector = createSelector(
    kedoDocumentsExportSelector,
    ({list}) => list,
);
export const kedoExportsTotalCountSelector = createSelector(
    kedoDocumentsExportSelector,
    ({totalCount}) => totalCount,
);
export const kedoExportsTotalPagesSelector = createSelector(
    kedoDocumentsExportSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const kedoExportsProgressSelector = createSelector(
    kedoDocumentsExportSelector,
    ({progress}) => progress,
);
export const kedoExportsProgressActionSelector = createSelector(
    kedoDocumentsExportSelector,
    ({progressAction}) => progressAction,
);