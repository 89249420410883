import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../../ActualComponents/NmDropdownV2";

import {
    specialitiesAllV2OptionsSelector,
    specialitiesClientVisibilityAllListV2Selector,
    updateSpecialitiesStore,
} from "../../../ducks/speciality";

export const NotificationSpecialityDropdown = (props) => {
    const {
        clientId,
        onChange,
        value,
    } = props;

    const dispatch = useDispatch();

    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const clientSpecialityOptions = useSelector(specialitiesClientVisibilityAllListV2Selector);

    useEffect(() => {
        return () => {
            if (clientId) {
                return;
            }

            dispatch(updateSpecialitiesStore({
                clientVisibilitySpecialities: [],
            }));
        };
    }, []);

    return (
        <NmDropdownV2
            size="xl"
            className="fluid"
            search
            multiple
            isClearable
            placeholder="Выберите виды деятельности"
            label="Виды деятельности"
            name="specialityIds"
            options={clientId ? clientSpecialityOptions : specialityOptions}
            value={value}
            onChange={onChange}
        />
    );
};