import React, {FC} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmDivider {
    className?: string,
    color?: string
}

const NmDivider: FC<INmDivider> = (props) => {
    const {
        className = "",
        color,
    } = props;
    const [block] = bem("nm-divider", className);

    return (
        <div
            style={{backgroundColor: color}}
            className={block()}
        />
    );
};

export default NmDivider;