import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../../components/ActualComponents/Filter";

import {PATTERN_NUMBER} from "../../../deposit/client-list/hooks/useGetlDepositData";

import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {CONTRACTOR_REGISTRATION_STATUS} from "../../../../constants/contractor";
import {GENDER_OPTIONS_WITH_ALL_OPTION} from "../../../../constants/contractorInfo";
import {DROPDOWN_ALL_NO_YES_OPTIONS} from "../../../../constants/dropdown";

import {getContractorsApplicationTypes} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsContractorApplicationTypesOptionsSelector,
    bffCommonDictsContractorApplicationTypesSelector,
} from "../../../../ducks/bff/common/dicts/selectors";
import {getCitizenshipOptionsSelector} from "../../../../ducks/contractor";

export const OrderContractorsFilter = (props) => {
    const {
        onSearch,
        clearFilter,
        filterData,
    } = props;

    const dispatch = useDispatch();

    const contractorApplicationTypesOptions = useSelector(bffCommonDictsContractorApplicationTypesOptionsSelector);
    const contractorApplicationTypes = useSelector(bffCommonDictsContractorApplicationTypesSelector);

    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);

    useEffect(() => {
        if (isEmpty(contractorApplicationTypes)) {
            dispatch(getContractorsApplicationTypes());
        }
    }, []);

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО исполнителя",
                        name: "fio",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "phone",
                        label: "Телефон",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "applicationInitDateFrom",
                        endFieldName: "applicationInitDateTo",
                        label: "Дата отклика/приглашения",
                        placeholder: "Введите ФИО исполнителя",
                        isClearable: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Тип",
                        placeholder: "Выберите тип",
                        name: "applicationType",
                        options: contractorApplicationTypesOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "РД подписан",
                        placeholder: "Выберите",
                        name: "signFrameContract",
                        options: DROPDOWN_ALL_NO_YES_OPTIONS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус СМЗ",
                        placeholder: "Выберите статус СМЗ",
                        name: "smzStatus",
                        options: DROPDOWN_ALL_NO_YES_OPTIONS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус регистрации",
                        placeholder: "Выберите статус регистрации",
                        name: "registrationStatuses",
                        options: dictionaryToOptions(CONTRACTOR_REGISTRATION_STATUS),
                        multiple: true,
                        search: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: GENDER_OPTIONS_WITH_ALL_OPTION,
                        label: "Пол",
                        name: "gender",
                        placeholder: "Все",
                    },
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "ageFrom",
                        nameEnd: "ageTo",
                        label: "Возраст",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "citizenships",
                        label: "Гражданство",
                        placeholder: "Выберите гражданство",
                        search: true,
                        multiple: true,
                        options: citizenshipOptions,
                    },
                ],
            },
        ];
    }, [
        contractorApplicationTypesOptions,
    ]);

    return (
        <Filter
            initState={filterData}
            filters={filtersBase}
            onSubmit={onSearch}
            clearFilter={clearFilter}
        />
    );
};