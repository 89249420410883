import {useState} from "react";
import {isEmpty} from "lodash";

import {useFilter} from "../../../../hooks/useFilter";

const initFilter = {
    name: "",
    createDatetimeFrom: null,
    createDatetimeTo: null,
    ownerFio: "",
    senderFio: "",

};

export const useEdoDocumentsRegistriesFilter = ({setPagination, pageSize}) => {
    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({});
    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState({});

    const onSearch = () => {
        if (isEmpty(filter)) {
            return;
        }

        setFilterData(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClear = () => {
        setFilterData({});
        setIsSearch(false);
        setFilter(initFilter);
    };

    return {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    };
};