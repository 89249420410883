/*
CLOSE: "Закрыт"
IN_PROCESS: "Обрабатывается"
IN_WORK: "В работе"
MISSED: "Пропущен"
NEW: "Новое"
RECALL: "Перезвонить"
* */
export const TICKET_STATUS_DICT = {
    NEW: {
        VALUE: "NEW",
        TEXT: "Новое",
        COLOR: "#666666",
        BACKGROUND: "#FFFFFF",
        BORDER: "1px solid #E4E4E4",
        MOD: "gray",
    },
    IN_WORK: {
        VALUE: "IN_WORK",
        TEXT: "В работе",
        COLOR: "#FFFFFF",
        BACKGROUND: "#128A10",
        MOD: "green",
    },
    IN_PROCESS: {
        VALUE: "IN_PROCESS",
        TEXT: "Обрабатывается",
        COLOR: "#3C9C3B",
        BACKGROUND: "#E9F4E5",
        MOD: "light-green",
    },
    CLOSED: {
        VALUE: "CLOSED",
        TEXT: "Закрыто",
        COLOR: "#555555",
        BACKGROUND: "#F1F3F7",
        MOD: "gray",
    },
    MISSED: {
        VALUE: "MISSED",
        TEXT: "Пропущен",
        COLOR: "#FFFFFF",
        BACKGROUND: "#EE1616",
        MOD: "red",
    },
    RECALL: {
        VALUE: "RECALL",
        TEXT: "Перезвонить",
        COLOR: "#FFFFFF",
        BACKGROUND: "#FFB713",
        MOD: "orange",
    },
};

export const SUB_PAGE_CRM_TICKET_CARD = {
    //Партнер
    PARAMS: {
        LINK: "params",
        VALUE: "PARAMS",
        TEXT: "Параметры обращения",
    },
    //
    CALL_HISTORY: {
        LINK: "call-history",
        VALUE: "CALL_HISTORY",
        TEXT: "История звонков",
    },
    HISTORY: {
        LINK: "history",
        VALUE: "HISTORY",
        TEXT: "История обращений",

    },
    LOG: {
        LINK: "log",
        VALUE: "LOG",
        TEXT: "Журнал событий",
    },
    UNATTACHED: {
        LINK: "unattached",
        VALUE: "UNATTACHED",
        TEXT: "Непривязанные исходящие",
    },
    SETTINGS: {
        LINK: "settings",
        VALUE: "SETTINGS",
        TEXT: "Настройки",
    },
};

export const CALL_TYPE_DICT = {
    INCOMING: {
        VALUE: "INCOMING",
        TEXT: "Входящий звонок",
        COLOR: "green",
    },
    OUTGOING: {
        VALUE: "OUTGOING",
        TEXT: "Исходящий звонок",
        COLOR: "yellow",
    },
    CHAT: {
        VALUE: "CHAT",
        TEXT: "Чат",
    },
    KEDO_CHAT: {
        VALUE: "KEDO_CHAT",
    },
    SYSTEM: {
        VALUE: "SYSTEM",
        TEXT: "Система",
    },
    NM_STAFF: {
        VALUE: "NM_STAFF",
        TEXT: "Сотрудник Наймикс",
    },
};

export const HEADER_TICKET_EVENT_LOG = [
    {
        key: "createDate",
        content: "Дата",
    },
    {
        key: "operatorFullName",
        content: "ФИО",
    },
    {
        key: "description",
        content: "Описание события",
    },
];

export const TYPE_TICKET = {
    AD_OR_OFFER: "AD_OR_OFFER",
    CHANGE_DATA: "CHANGE_DATA",
    JUR_ISSUES: "JUR_ISSUES",
    OTHER: "OTHER",
    PAYMENT_ORDERS: "PAYMENT_ORDERS",
    QUESTIONS_ABOUT_COMPANY: "QUESTIONS_ABOUT_COMPANY",
    REGISTRATION: "REGISTRATION",
    SEARCH_ORDERS: "SEARCH_ORDERS",
    TAXES: "TAXES",
    TECHNICAL_ISSUES: "TECHNICAL_ISSUES",
    CONTRACTOR_NOT_EXIST_FROM_EDO: "CONTRACTOR_NOT_EXIST_FROM_EDO",
    FAILED_REGISTRY_PAYMENTS: "FAILED_REGISTRY_PAYMENTS",
    LONG_SIGNING_CONTRACT: "LONG_SIGNING_CONTRACT",
    NOT_DEFINED  : "NOT_DEFINED  ",
};


export const TYPE_TICKET_DICT = {
    [TYPE_TICKET.AD_OR_OFFER]: "Реклама/предложения",
    [TYPE_TICKET.CHANGE_DATA]: "Изменения данных",
    [TYPE_TICKET.JUR_ISSUES]: "Обращение от юридического лица",
    [TYPE_TICKET.OTHER]: "Иное",
    [TYPE_TICKET.PAYMENT_ORDERS]: "Оплата заказов",
    [TYPE_TICKET.QUESTIONS_ABOUT_COMPANY]: "Вопросы о компании",
    [TYPE_TICKET.REGISTRATION]: "Регистрация",
    [TYPE_TICKET.SEARCH_ORDERS]: "Поиск заказов",
    [TYPE_TICKET.TAXES]: "Налоги",
    [TYPE_TICKET.TECHNICAL_ISSUES]: "Технические неисправности",
    [TYPE_TICKET.NOT_DEFINED  ]: "Не определено",
};

export const TICKET_SORT_TYPE = {
    MISSED_RECALL_IN_WORK: "MISSED_RECALL_IN_WORK", // для чатов
    RECALL_IN_WORK_MISSED_CLOSED: "RECALL_IN_WORK_MISSED_CLOSED",
};

export const TICKET_MANAGER = {
    IS_AWAY: "IS_AWAY",
};

export const TICKET_MANAGER_AWAY_OPTION = {
    key: TICKET_MANAGER.IS_AWAY,
    value: TICKET_MANAGER.IS_AWAY,
    text: "Отсутствует",
};

export const TICKET_TYPE = {
    CALL_FAILED: {
        TEXT: "Звонок не состоялся",
        VALUE: "CALL_FAILED",
    },
};

export const CRM_SYSTEM_SETTINGS = [
    {
        key: "isSystemTicketBlockedContractorEnabled",
        text: "Исполнитель заблокирован на площадке",
    },
    {
        key: "isSystemTicketDeclineBankEnabled",
        text: "Отклонен банком",
    },
    {
        key: "isSystemTicketContractorNotExistFromEdoEnabled",
        text: "Исполнитель отсутствует на площадке в качестве получателя документа в рамках ЭДО",
    },
    {
        key: "isSystemTicketContractorInvitationEnabled",
        text: "Исполнитель приглашен в Наймикс",
    },
    {
        key: "isSystemTicketDeclineOperatorEnabled",
        text: "Отклонен оператором",
    },
    {
        key: "isSystemTicketFailedPaymentEnabled",
        text: "Не прошел платеж",
    },
    {
        key: "isSystemTicketFailedRegistryPaymentEnabled",
        text: "Не прошел реестровый платеж",
    },
    {
        key: "isSystemTicketLongSigningContractEnabled",
        text: "Исполнитель долго не подписывает РД",
    },
    {
        key: "isSystemTicketNotEnoughMoneyToPayTaxEnabled",
        text: "Недостаточно средств для уплаты налога",
    },
];

export const CRM_CHAT_MESSAGE_TYPE = {
    MESSAGE: "MESSAGE",
    PAID_PAYMENT_NOTIFICATION_FOR_CONTRACTOR: "PAID_PAYMENT_NOTIFICATION_FOR_CONTRACTOR",
};