import {
    deleteKedoDocumentsDraft,
    exportKedoDocuments,
    getKedoDocumentsHistoryPage,
    getKedoDocumentsPage,
} from "../../../../kedo/documents/actionCreators";

const controller = "/adm/clients/kedo/documents";

export const getClientsKedoDocumentsPage = (payload) => {
    return getKedoDocumentsPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const getClientsKedoDocumentsHistoryPage = (payload) => {
    return getKedoDocumentsHistoryPage({
        url: `${controller}/getHistoryPage`,
        ...payload,
    });
};

export const exportClientKedoDocuments = (payload) => {
    return exportKedoDocuments({
        url: `${controller}/export`,
        ...payload,
    });
};

export const deleteClientKedoDocumentsDraft = (payload) => {
    return deleteKedoDocumentsDraft({
        url: `${controller}/deleteDraft`,
        ...payload,
    });
};