import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
// import "./style.sass";
import NmBadge from "../../../../components/NmBadge";
import {WORK_EXPERIENCE_OPTION} from "../contractor-work-experience";
import {ADDITIONAL_SPECIALITIES_OPTION} from "../contractor-work-type";
import ContractorDriverLicenseEditModal from "./components/сontractor-driver-license-edit-modal";

import {ADVERTISEMENT_MASS_OPTION} from "../../../advertisement/list/constants";

import {
    contractorDrivingLicenseDictSelector,
    contractorDrivingLicenseSelector, deleteContractorWorkExperience,
    getDriverLicenseDictionary, updateContractorDriverLicense,
} from "../../../../ducks/contractorProfile";

export const DRIVING_LICENSE_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const OPTIONS = [
    {
        key: DRIVING_LICENSE_OPTION.EDIT,
        text: "Редактировать",
        value: DRIVING_LICENSE_OPTION.EDIT,
    },
    {
        key: DRIVING_LICENSE_OPTION.REMOVE,
        text: "Удалить",
        value: DRIVING_LICENSE_OPTION.REMOVE,
    },
];

export default function ContractorDrivingLicense(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;
    const dispatch = useDispatch();

    const contractorDrivingLicenseDict = useSelector(contractorDrivingLicenseDictSelector);
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    useEffect(() => {
        dispatch(getDriverLicenseDictionary());
    }, []);

    const {
        driverLicenses,
        ownCar,
        driverLicensePresent,
    } = useSelector(contractorDrivingLicenseSelector);

    const onClickItem = ({value}) => {
        if (value === DRIVING_LICENSE_OPTION.EDIT) {
            toggleEditModal({
                licenses: [...driverLicenses],
                ownCar,
                driverLicensePresent: !driverLicensePresent,
            });
            return;
        }

        if (value === DRIVING_LICENSE_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem();
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?",
            });
            setShowConfirm(true);
        }
    };

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    const deleteElem = () => {
        dispatch(updateContractorDriverLicense({
            contractorId,
            licenses: [],
            ownCar: false,
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };


    function mapContent() {
        if (!driverLicensePresent) {
            return <Text level="1">
                Водительские права отсутствуют
            </Text>;
        }

        if (!driverLicenses?.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return driverLicenses.map(item => {
            return (
                <div
                    key={item}
                    className="mt-2"
                >
                    <NmCheckboxV2
                        disabled={true}
                        checked={true}
                        onChange={() => {
                        }}
                        label={`${item} - ${contractorDrivingLicenseDict[item]}`}
                    />
                </div>
            );
        });
    }

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal && <ContractorDriverLicenseEditModal
                editData={editData}
                getProfile={getProfile}
                contractorId={contractorId}
                onClose={() => toggleEditModal()}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Водительские права"
                onClickAdd={!driverLicenses?.length ? () => onClickItem({value: ADDITIONAL_SPECIALITIES_OPTION.EDIT}) : null}
                options={driverLicenses?.length ? OPTIONS : null}
                onClickItem={onClickItem}
            >
                {Boolean(driverLicenses?.length) && <Text level="4">
                    {`${ownCar ? "Имеется" : "Отсутствует"} личный автомобиль`}
                </Text>}
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}