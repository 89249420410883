import React from "react";

import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";
import {Divider} from "semantic-ui-react";

import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {innFormat, phoneFormat} from "../../../../utils/stringFormat";

import {MEMBER_POSITIONS_DICT} from "../../../../constants/memberPositions";
import {ROLE_DICT, roleSettingsList} from "../../../../constants/roles";

import "./style.sass";

const EdmStaffCardClientUserForm = (props) => {
    const {
        clientUser: {
            firstName,
            lastName,
            patronymic = "",
            position,
            phone,
            email,
            inn,
            snils,
            role,
        },
    } = props;

    return (
        <CardApp
            title="Персональные данные"
            className="edm-staff-card-client-user-form"
            isEditable={false}
        >
            <LabelTextApp
                label="Фамилия"
                text={lastName}
                className="edm-staff-card-client-user-form__info-elem"
            />
            <LabelTextApp
                label="Имя"
                text={firstName}
                className="edm-staff-card-client-user-form__info-elem"
            />
            <LabelTextApp
                label="Отчество"
                text={patronymic}
                className="edm-staff-card-client-user-form__info-elem"
            />
            <Divider />
            <LabelTextApp
                label="Должность"
                text={MEMBER_POSITIONS_DICT[position]}
                className="edm-staff-card-client-user-form__info-elem"
            />
            <Divider />
            <div className="flex">
                <LabelTextApp
                    label="Телефон"
                    text={phoneFormat(phone)}
                    className="edm-staff-card-client-user-form__info-elem column-50"
                />
                <LabelTextApp
                    label="E-mail"
                    text={email}
                    className="edm-staff-card-client-user-form__info-elem column-50"
                />
            </div>
            <Divider />
            <div className="flex">
                <LabelTextApp
                    label="ИНН"
                    text={innFormat(inn)}
                    className="edm-staff-card-client-user-form__info-elem column-50"
                />
                <LabelTextApp
                    label="Снилс"
                    text={snils}
                    className="edm-staff-card-client-user-form__info-elem column-50"
                />
            </div>
            <Divider />
            <LabelTextApp
                label="Роль"
                text={ROLE_DICT[role].TEXT}
                className="edm-staff-card-client-user-form__info-elem"
            />
        </CardApp>
    );
};

export default EdmStaffCardClientUserForm;