import {isEmpty} from "lodash";
import * as yup from "yup";

import {
    emailReg,
    maxSizeFile15MB,
    PDF_EXTENSION,
} from "../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

import {
    EDO_DOCUMENT_FIELD,
    TEMPLATE_DOCUMENT,
    TYPE_LOADING_EDO_DOCUMENT,
} from "../../../constants/document-management/document-list";

const validationSchemaEdo = () => {
    return {
        typeLoading: yup.string(),
        type: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        senderId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        documentDateTime: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        receiverIds: yup.mixed()
            .when("documentId", {
                is: value => (!value),
                then: yup.array()
                    .min(1, VALIDATIONS_MESSAGE.REQUIRED)
                    .of(yup.string())
                    .nullable(),
            }),
        receiverId: yup.string()
            .when("documentId", {
                is: value => (Boolean(value)),
                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        description: yup.string(),
        documentName: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("description", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
        file: yup.mixed()
            .nullable()
            .when("typeLoading", {
                is: TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT,
                then: yup.mixed()
                    .test("file", VALIDATIONS_MESSAGE.REQUIRED, function (value) {
                        if (!value) {
                            return false;
                        }

                        if (value.size > maxSizeFile15MB) {
                            return this.createError({
                                message: VALIDATIONS_MESSAGE.MAX_SIZE_15MB,
                                path: EDO_DOCUMENT_FIELD.FILE,
                            });
                        }

                        const parts = value && value.name.split(".");

                        if (parts.length < 2 || parts.pop() !== PDF_EXTENSION) {
                            return this.createError({
                                message: VALIDATIONS_MESSAGE.INCORRECT_FORMAT_FILE,
                                path: EDO_DOCUMENT_FIELD.FILE,
                            });
                        }

                        return true;
                    }),
            }),
        params: yup.object()
            .when("typeLoading", {
                is: TYPE_LOADING_EDO_DOCUMENT.TEMPLATE,
                then: yup.object().shape(
                    {
                        type: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                        documentNumber: yup.string()
                            .nullable()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.TRADE_SECRET_NONDISCLOSURE_OBLIGATION,
                                        TEMPLATE_DOCUMENT.CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8,
                                        TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        targets: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA,
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        personalData: yup.mixed()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA,
                                then: yup.array().min(1, VALIDATIONS_MESSAGE.REQUIRED).of(yup.string()),
                            }),
                        documentSubject: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        documentPlace: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        startWorkDate: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        contractDate: yup.string()
                            .nullable()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        contractNumber: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        endWorkDate: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        periodFrom: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        periodTo: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        platformName: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        paymentCurrency: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        email: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .matches(emailReg, { message: VALIDATIONS_MESSAGE.INCORRECT_EMAIL, excludeEmptyString: false })
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        jobList: yup.array()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                then: yup.array()
                                    .of(
                                        yup.object().shape({
                                            count: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                                            price: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                                            workName: yup.string()
                                                .required(VALIDATIONS_MESSAGE.REQUIRED)
                                                .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                                        }),
                                    ),
                            })
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                then: yup.array()
                                    .of(
                                        yup.object().shape({
                                            count: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                                            price: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                                            linkToResult: yup.string()
                                                .required(VALIDATIONS_MESSAGE.REQUIRED)
                                                .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                                            workName: yup.string()
                                                .required(VALIDATIONS_MESSAGE.REQUIRED)
                                                .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                                        }),
                                    ),
                            }),

                    },
                ),
            }),
    };
};

export default validationSchemaEdo;

const checkSymbolInput = (value) => {
    if (value && value.includes(">")) {
        return false;
    }

    if (value && value.includes("<")) {
        return false;
    }

    return true;
};