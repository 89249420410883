import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

export const getClientPropertiesParamSelectorType = (client) => {
    if (client.partiallyChecked) {
        return CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY;
    }

    if (client.checked) {
        return CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY;
    }

    return CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY;
};