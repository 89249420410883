import React from "react";

import {ContractorFinanceIncomeNdfl} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED} from "../../../../../constants/status";

import {
    getContractorCompletedFinanceNdflPayments,
} from "../../../../../ducks/contractorFinanceIncomeNdfl";

const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED);

export const ContractorFinanceIncomeNdflCompleted = (props) => {
    return (
        <ContractorFinanceIncomeNdfl
            fetchList={getContractorCompletedFinanceNdflPayments}
            isShowNaimixStatusFilter={false}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
