import {
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CLEAR_STORE,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_UPDATE_STORE,
} from "./actions";

export function retryContractorNotification(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST,
        payload,
    };
}

export function cancelContractorNotification(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST,
        payload,
    };
}

export function getContractorsNotificationsPage(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST,
        payload,
    };
}

export function getNotificationContractorCount(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_REQUEST,
        payload,
    };
}

export function addContractorNotification(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST,
        payload,
    };
}

export function clearContractorNotificationStore() {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CLEAR_STORE,
    };
}

export function updateContractorNotificationStore(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_UPDATE_STORE,
        payload,
    };
}

export function getNotificationSendingContractorId(payload) {
    return {
        type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST,
        payload,
    };
}