export const UNAVAILABLE_TEMPLATES_MESSAGE = {
    CLIENT_DEFAULT_FRAME_CONTRACT_CHANGED: "Один или несколько шаблонов были изменены. Измененные шаблоны будут заменены на шаблоны ниже. Все равно продолжить?",
    TEMPLATES_WAS_DELETED: "Один или несколько шаблонов были изменены. Измененные шаблоны будут заменены на шаблоны ниже. Все равно продолжить?",
    TEMPLATES_SETTINGS_WAS_DISABLED: {
        ORDER: "Опция использования своих типов документов была выключена. Обратитесь к администрации Наймикс или сохраните заказ с шаблоном договора Наймикс. По ссылке ниже можно загрузить пример того, как будет выглядеть договор",
        TEMPLATE_ORDER: "Опция использования своих типов документов была выключена. Обратитесь к администрации Наймикс или сохраните шаблон заказа с шаблоном договора Наймикс. По ссылке ниже можно загрузить пример того, как будет выглядеть договор",
        REGISTRY_PAYMENTS: "Опция использования своих типов документов была выключена. Обратитесь к администрации Наймикс или передайте реестр в оплату с шаблоном(ами) документа(ов) Наймикс. По ссылкам ниже можно загрузить примеры того, как будут выглядеть представленные документы",
        REGISTRY_ACTS: "Опция использования своих типов документов была выключена. Обратитесь к администрации Наймикс или отправьте акты с шаблоном акта Наймикс. По ссылке ниже можно загрузить пример того, как будет выглядеть акт",
        REGISTRY_DOCUMENTS_RD: "Опция использования своих типов документов была выключена. Обратитесь к администрации Наймикс или передайте реестр на подписание с шаблоном договора Наймикс. По ссылке ниже можно загрузить пример того, как будет выглядеть договор",
    },
};

export const UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE = {
    ORDER: "ORDER",
    TEMPLATE_ORDER: "TEMPLATE_ORDER",
    REGISTRY_PAYMENTS: "REGISTRY_PAYMENTS",
    REGISTRY_ACTS: "REGISTRY_ACTS",
    REGISTRY_DOCUMENTS_RD: "REGISTRY_DOCUMENTS_RD",
};