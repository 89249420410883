import * as yup from "yup";

import {removePhoneMask} from "../../../../utils/stringFormat";
import {validatePhone} from "../../../../utils/validate";

import {FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE} from "../../../../constants/frameContractExternal";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        contractorId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        contractNumber: yup.string()
            .nullable()
            .max(100, "Максимальная длина - 100 символов")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        contractSignDate: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        phone: yup.string()
            .nullable()
            .when("searchType", {
                is: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
                then: yup.string()
                    .nullable()
                    .transform(removePhoneMask)
                    .test("checkPhone", "Номер телефона должен состоять из 10-15 цифр", validatePhone)
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        inn: yup.string()
            .nullable()
            .when("searchType", {
                is: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.INN.VALUE,
                then: yup.string()
                    .nullable()
                    .length(12, "Введите ИНН полностью")
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        objectId: yup.string()
            .nullable()
            .when("useObjectId", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};

export default validationSchema;