import * as yup from "yup";

const validationSchema = () => {
    return yup.object().shape({
        projectParam: yup.string()
            .nullable()
            .when("leadType", {
                is: value => (!value),
                then: yup.string()
                    .nullable()
                    .required("Заполните хотя бы одно из полей")
                    .max(255, "Не более 255 символов"),
            }),
        leadType: yup.string()
            .nullable()
            .when("projectParam", {
                is: value => (!value),
                then: yup.string()
                    .nullable()
                    .required("Заполните хотя бы одно из полей"),
            }),
    }, ["projectParam", "leadType"]);
};

export default validationSchema;