import React from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../../components/PhoneWithCodeFilter";

import "./style.sass";

const ClientGroupFilter = (props) => {
    const {
        submitFilter = () => {},
        initForm = {},
        filter = {},
        setFilter = () => {},
        onChangeFilter  = () => {},
    } = props;

    const {
        clientInnFilter,
        clientNameFilter,
        clientUserFioFilter,
        clientUserEmailFilter,
        clientUserPhoneFilter,
        contractorInnFilter,
        contractorPhoneFilter,
        fromCreateDateFilter,
        groupNameFilter,
        toCreateDateFilter,
    } = filter;

    const submit = () => {
        submitFilter(filter);
    };

    const clear = () => {
        submitFilter({
            ...initForm,
        }, false);

        setFilter({
            ...initForm,
        });
    };

    return (
        <NmForm
            addMargin
            className="document-management-statement-filter"
        >
            <FilterButtonsV2
                className="document-management-statement-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
            <NmInputV2
                placeholder="Введите наименование группы компаний"
                size="lg"
                label="Наименование группы компаний"
                name="groupNameFilter"
                value={groupNameFilter}
                onChange={onChangeFilter}
            />
            <NmInputV2
                placeholder="Введите наименование заказчика"
                size="lg"
                label="Наименование заказчика"
                name="clientNameFilter"
                value={clientNameFilter}
                onChange={onChangeFilter}
            />
            <NmInputV2
                placeholder="Введите ИНН заказчика"
                size="lg"
                maskChar={null}
                mask="999999999999999999999999999999999999999999999999"
                label="ИНН заказчика"
                name="clientInnFilter"
                value={clientInnFilter}
                onChange={onChangeFilter}
            />
            <NmInputV2
                placeholder="Введите ИНН исполнителя"
                mask="999999999999999999999999999999999999999999999999"
                size="lg"
                maskChar={null}
                label="ИНН исполнителя"
                name="contractorInnFilter"
                value={contractorInnFilter}
                onChange={onChangeFilter}
            />
            <PhoneWithCodeFilter
                name="contractorPhoneFilter"
                value={contractorPhoneFilter}
                onChange={onChangeFilter}
                className="contractor-resources-filter__row"
                label="Телефон исполнителя"
            />
            <NmInputV2
                placeholder="Введите ФИО сотрудника"
                size="lg"
                label="ФИО сотрудника"
                name="clientUserFioFilter"
                value={clientUserFioFilter}
                onChange={onChangeFilter}
            />
            <NmInputV2
                placeholder="Введите e-mail сотрудника"
                size="lg"
                label="E-mail сотрудника"
                name="clientUserEmailFilter"
                value={clientUserEmailFilter}
                onChange={onChangeFilter}
            />
            <NmInputV2
                placeholder="Введите телефон сотрудника"
                mask="+7 (999) 999 99 99"
                maskChar={null}
                size="lg"
                label="Телефон сотрудника"
                name="clientUserPhoneFilter"
                value={clientUserPhoneFilter}
                onChange={onChangeFilter}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="fromCreateDateFilter"
                endFieldName="toCreateDateFilter"
                value={{fromCreateDateFilter, toCreateDateFilter}}
                label="Дата создания группы компаний"
                onChange={onChangeFilter}
                className="document-management-statement-filter__datepicker-range"
            />
        </NmForm>
    );
};

export default ClientGroupFilter;