import {useEffect} from "react";

import {checkPassportDataRu} from "../../../../contractor_info/controlDateReadOnly";

import {validatePassportDate} from "../../../../../../utils/validate";

import {citizenshipsDict} from "../../../../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../../../../constants/clientList";
import {CONTRACTOR_FIELD} from "../../../../../../constants/contractor";

const useRuWarnings = (params) => {
    const {
        values,
        setScanWarnings,
        setFieldWarnings,
    } = params;

    useEffect(() => {
        if (
            ![
                citizenshipsDict.RU.value,
            ].includes(values.citizenship)
        ) {
            return;
        }

        const {
            errorMessageFirstCheck,   //первая проверка только для полей
            errorMessageSecondCheck,  //вторая проверка подсвчивает поля и сканы
        } = getErrorMessage(values);

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: errorMessageFirstCheck ? errorMessageFirstCheck : errorMessageSecondCheck,
        }));


        setScanWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO]: errorMessageFirstCheck ? undefined : errorMessageSecondCheck,
            [CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION]: errorMessageFirstCheck ? undefined : errorMessageSecondCheck,
        }));

    }, [values]);


    return null;
};

export default useRuWarnings;

const getErrorMessage = (values) => {
    const secondCheck = checkPassportDataRu({
        values: values,
        card: values,
        isVerification: true,
    });
    const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
    const firstCheck = validatePassportDate({
        passportDate: new Date(passportDate),
        birthDate: values[CONTRACTOR_FIELD.BIRTH_DATE],
        intervalAges: {
            first: 14,
            second: 20,
            third: 45,
        },
    });

    let errorMessageFirstCheck;
    let errorMessageSecondCheck;

    errorMessageFirstCheck = !firstCheck && passportDate ? "Некорректная дата выдачи паспорта" : undefined;
    errorMessageSecondCheck = secondCheck ? secondCheck : undefined;

    return {
        errorMessageFirstCheck,
        errorMessageSecondCheck,
    };
};