import {getCurrentStep} from "./getCurrentStep";

import {isAnyInvalidParamInString} from "../../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {REG_EXP} from "../../../../constants/regExp";
import {
    requiredMessage,
    specialityRule,
} from "../../../../constants/validationRules";
import {
    ORDER_CREATE_STEPS,
    ORDER_EDIT_AMOUNT_BASED_TYPE_VALIDATION_RULE,
    ORDER_EDIT_DRAFT_VALIDATION_RULE,
    ORDER_EDIT_PRE_CONDITIONS_STEP_VALIDATION_RULE,
    ORDER_EDIT_TEMPLATE_AMOUNT_BASED_TYPE_VALIDATION_RULE,
    ORDER_EDIT_TEMPLATE_PRE_CONDITIONS_STEP_VALIDATION_RULE,
    ORDER_EDIT_TEMPLATE_VOLUME_OF_WORK_BASED_TYPE_VALIDATION_RULE,
    ORDER_EDIT_VOLUME_OF_FORK_BASED_TYPE_VALIDATION_RULE} from "../constants";

export const getOrderEditValidations = (params) => {
    const {
        isTemplate,
        isSecondType,
        activeItems,
        isDisabledSpeciality,
        creationStatus,
        tabs,
        isDetectingParamsInActDescription,
        isTemplateType,
    } = params;

    if (creationStatus === ORDER_STATUS.DRAFT) {
        return ORDER_EDIT_DRAFT_VALIDATION_RULE;
    }

    let validations;

    const currentStep = getCurrentStep({activeItems, tabs});

    if (currentStep === ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE) {
        const orderValidationRule = isTemplateType ? {
            ...ORDER_EDIT_PRE_CONDITIONS_STEP_VALIDATION_RULE,
            templateId: {
                required: requiredMessage,
            },
        } : ORDER_EDIT_PRE_CONDITIONS_STEP_VALIDATION_RULE;

        return isTemplate ?
            ORDER_EDIT_TEMPLATE_PRE_CONDITIONS_STEP_VALIDATION_RULE :
            orderValidationRule;
    }

    if (isTemplate) {
        validations = isSecondType ?
            ORDER_EDIT_TEMPLATE_VOLUME_OF_WORK_BASED_TYPE_VALIDATION_RULE :
            ORDER_EDIT_TEMPLATE_AMOUNT_BASED_TYPE_VALIDATION_RULE;
    } else {
        validations = isSecondType ?
            ORDER_EDIT_VOLUME_OF_FORK_BASED_TYPE_VALIDATION_RULE :
            ORDER_EDIT_AMOUNT_BASED_TYPE_VALIDATION_RULE;
    }

    if (currentStep === ORDER_CREATE_STEPS.ACT_TEMPLATE.VALUE) {
        if (
            isTemplate &&
            isDetectingParamsInActDescription
        ) {
            return {
                actDescription: {
                    func: validateParamsInActDescription,
                },
            };
        }

        return {};
    }

    if (!isDisabledSpeciality) {
        validations = {...validations, speciality: specialityRule};
    }

    return validations;
};

function validateParamsInActDescription(description) {
    if (!REG_EXP.STRING_PARAM.test(description)) {
        return "На первом шаге активирован чекбокс “Актирование с параметрами”: необходимо указать хотя бы один параметр";
    }

    if (isAnyInvalidParamInString(description)) {
        return "При включении признака \"Актирование с параметрами\" заданные в {} значения параметров могут быть только числами от 0 до n";
    }

    return null;
}