import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../../../../components/ActualComponents/Filter";

import {getEndDate, getStartDate} from "../../../../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../../../../utils/stringHelper";

import {
    bffCommonDictsLeadRegistrationStatusDictOptionsSelector,
} from "../../../../../../../ducks/bff/common/dicts/selectors";

export function useOnboardingLeadsListFilter({}) {
    const registrationStatusDictOptions = useSelector(bffCommonDictsLeadRegistrationStatusDictOptionsSelector);

    const initFilter = {
    };

    function mapFilterDto(filter) {
        const {
            creationDateTimeFrom,
            creationDateTimeTo,
            externalLeadIdFilter,
            externalContractorInnFilter,
            externalContractorPhoneFilter,
            externalContractorNameFilter,
            registrationStatusFilter,
        } = filter;

        return {
            creationDateTimeFrom: isNullOrWhitespace(creationDateTimeFrom) ? undefined : getStartDate(creationDateTimeFrom, "yyyy-MM-dd"),
            creationDateTimeTo: isNullOrWhitespace(creationDateTimeTo) ? undefined : getEndDate(creationDateTimeTo, "yyyy-MM-dd"),
            externalLeadIdFilter: handleFormString(externalLeadIdFilter),
            externalContractorInnFilter: handleFormString(externalContractorInnFilter),
            externalContractorPhoneFilter: externalContractorPhoneFilter ? removePhoneMask(externalContractorPhoneFilter) : undefined,
            externalContractorNameFilter: handleFormString(externalContractorNameFilter),
            registrationStatusFilter,
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFrom",
                        endFieldName: "creationDateTimeTo",
                        isClearable: true,
                        label: "Дата создания лида",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label:"Статус регистрации",
                        placeholder: "Статус регистрации",
                        name: "registrationStatusFilter",
                        multiple: true,
                        isClearable: true,
                        search: true,
                        options: registrationStatusDictOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalContractorNameFilter",
                        label: "ФИО",
                        placeholder: "ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        placeholder: "Номер телефона",
                        label: "Номер телефона",
                        name: "externalContractorPhoneFilter",
                        mask: "+7 (999) 999 9999",
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalContractorInnFilter",
                        label: "ИНН",
                        placeholder: "ИНН",
                        mask: "999999999999",
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalLeadIdFilter",
                        label: "Идентификатор лида",
                        placeholder: "Идентификатор лида",
                    },
                ],
            },
        ];
    }, [registrationStatusDictOptions]);

    return {
        initFilter,
        filters,
        mapFilterDto,
    };
}