import React from "react";
import {isEmpty} from "lodash";

import Text from "../../../../components/ActualComponents/Text";

import {
    DEVICE_TYPE_TRANSLATE,
    NETWORK_TYPE_TRANSLATE,
} from "../../../../constants/eventLog";

const ATTRIBUTE_TRANSLATE = {
    rs: "Расчетный счет",
    bankName: "Наименование банка",
    inn: "ИНН",
    bic: "БИК",
    ks: "Корр. счет",
    bankAddress: "Адрес банка",
    individualRs: "Расчетный счет ИП",
};

export const CHANGES_NEW_MAIL_ATTRIBUTE_NAME = "email"; // Новый e-mail исполнителя

export const getContractorLogChangesLabels = (changes) => {
    if (!changes) {
        return [];
    }

    if (changes.newValues.length === 1 && changes.newValues[0].name === "cardNumber") {
        return [
            {label: "Новый номер", text: changes.newValues[0].value || "-"},
            {label: "Старый номер", text: changes.oldValues[0].value || "-", className: "mb-2"},
        ];
    }

    if (changes.newValues.length === 1 && changes.newValues[0].name === CHANGES_NEW_MAIL_ATTRIBUTE_NAME) {
        return [
            {label: "Новый", text: changes.newValues[0].value || "-"},
            {label: "Старый", text: changes.oldValues[0].value || "-", className: "mb-2"},
        ];
    }

    return [
        {
            text: <Text level="4">
Новые реквизиты
            </Text>,
        },
        ...changes.newValues.map((item, index, array) => {
            const isLastElement = array.length - 1 === index;

            return {
                className: isLastElement ? "mb-2" : "",
                label: ATTRIBUTE_TRANSLATE[item.name],
                text: item.value || "-",
                noWrap: item.name !== "bankAddress",
            };
        }),
        {
            text: <Text level="4">
Старые реквизиты
            </Text>,
        },
        ...changes.oldValues.map((item, index, array) => {
            const isLastElement = array.length - 1 === index;

            return {
                className: isLastElement ? "mb-2" : "",
                label: ATTRIBUTE_TRANSLATE[item.name],
                text: item.value || "-",
                noWrap: item.name !== "bankAddress",
            };
        }),
    ];
};

export const getDeviceInfoLabels = (data) => {
    if (isEmpty(data)) {
        return [];
    }

    return ([
        {label: "Тип устройства", text: DEVICE_TYPE_TRANSLATE[data.type] || "-"},
        {label: "Наименование устройства", text: data.name || "-"},
        {label: "Модель устройства", text: data.model || "-"},
        {label: "Версия устройства", text: data.version || "-"},
    ]);
};

export const getNetworkInfoLabels = (data) => {
    if (isEmpty(data)) {
        return [];
    }

    return ([
        {label: "Тип сети", text: NETWORK_TYPE_TRANSLATE[data.type] || "-"},
        {label: "ipV4/V6", text: data.ip || "-"},
    ]);
};