import { useState } from "react";

export const useContractorVerificationDataRequestInnList = () => {
    const [isRequestInnList, setRequestInnList] = useState(false);

    const onChangeRequestInnList = () => {
        setRequestInnList(!isRequestInnList);
    };

    return {
        isRequestInnList,
        onChangeRequestInnList,
    };
};