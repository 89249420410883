import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import {ClientSettingsContext} from "../../ContextProvider";

import {getInitialTouched} from "../../../../../../utils/objectHelper";
import {formatAmountWithComma} from "../../../../../../utils/stringFormat";
import {toastSuccess} from "../../../../../../utils/toastHelper";
import {transformYupFormattedAmountToNumber} from "../../../../../../utils/yupTransforms";
import {getClientSettingsWorkWithContractorsRequestData} from "../utils/getRequestData";
import {isClientSettingsWorkWithContractorsChanged} from "../utils/isChangedData";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";
import {CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE, CLIENT_SETTINGS_TAB} from "../../../constants";

import {
    getClientsSettingsContractors,
    updateClientsSettingsContractors,
} from "../../../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsContractorsSelector,
} from "../../../../../../ducks/bff/clients/settings/selectors";

export const useClientSettingsWorkWithContractorsForm = (params) => {
    const {
        clientId,
        onClose,
    } = params;

    const {
        isOpenUnsavedDataConfirm,
        onCancelUnsavedDataConfirm,
        updateChanged,
    } = useContext(ClientSettingsContext);

    const dispatch = useDispatch();
    const card = useSelector(bffClientsSettingsContractorsSelector);
    const {
        paymentAmountWarnThreshold,
        ...initialValues
    } = card;

    useEffect(() => {
        dispatch(getClientsSettingsContractors({clientId}));
    }, []);

    const {
        values,
        setFieldValue,
        errors,
        touched,
        setTouched,
        setValues,
        validateForm,
    } = useFormik({
        initialValues: {
            paymentAmountWarnThreshold: typeof paymentAmountWarnThreshold === "number" ?
                formatAmountWithComma(card.paymentAmountWarnThreshold) :
                "",
            ...initialValues,
        },
        validationSchema: yup.object().shape({
            paymentAmountWarnThreshold: yup.number().when("warnPaymentsAmountRepeatsLastMonth", {
                is: true,
                then: yup.number()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .transform(transformYupFormattedAmountToNumber)
                    .min(0, "Минимальное значение - 0")
                    .max(100, "Максимальное допустимое значение равно 100"),
            }),
        }),
        enableReinitialize: true,
    });

    useEffect(() => {
        const isChangedData = isClientSettingsWorkWithContractorsChanged({
            values,
            card,
        });

        updateChanged({
            name: CLIENT_SETTINGS_TAB.WORK_WITH_CONTRACTORS,
            isChangedData,
        });
    }, [card, values]);

    const onSubmit = async ({isClose}) => {
        const errors = await validateForm();

        if (!isEmpty(errors)) {
            await setTouched(getInitialTouched(values, true));

            return;
        }

        const requestData = getClientSettingsWorkWithContractorsRequestData(values);

        dispatch(updateClientsSettingsContractors({
            clientId,
            ...requestData,
            onSuccess: () => {
                toastSuccess(CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE);

                if (isOpenUnsavedDataConfirm) {
                    onCancelUnsavedDataConfirm();
                }

                if (isClose) {
                    onClose();

                    return;
                }

                dispatch(getClientsSettingsContractors({clientId}));
            },
        }));
    };

    const onChange = (event, {name, value, checked}) => {
        if (name === "grantPersonalDataAccess") {
            setValues({
                ...values,
                [name]: checked,
                grantDocumentScansAccess: checked,
            });

            return;
        }

        if (name === "warnPaymentsAmountRepeatsLastMonth" && !checked) {
            setValues({
                ...values,
                paymentAmountWarnThreshold: 0,
                [name]: checked,
            });

            return;
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    return {
        onChange,
        onSubmit,
        values,
        errors,
        touched,
        isLoading: isEmpty(card),
    };
};