import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {Route, Switch} from "react-router-dom";

import LoginAuth from "./auth";
import LoginCookie from "./cookie";
import LoginFooter from "./footer";
import LoginHeader from "./header";
import LoginJoin from "./join";
import MaintenanceBanner from "./maintenance-banner";

import {ls} from "../../utils/localstorage";
import {isPromoPoiskPage} from "../../utils/url";

import {
    LINK_LOGIN,
    LINK_REQUEST} from "../../constants/links";

import "./style.sass";

//const versionApp = `UI beta version: ${process.env.REACT_APP_VERSION}`;

const Login = () => {
    const [isAgreeCookieSave, setAgreeCookieSave] = useState(ls("isAgreeCookieSave") === "true");

    const cookieAgree = () => {
        setAgreeCookieSave(true);

        ls("isAgreeCookieSave", true);
    };

    return (
        <div className="login">
            <Helmet>
                <title>
                    {
                        isPromoPoiskPage() ?
                            "Промопоиск - Авторизация" :
                            "Наймикс - Авторизация"
                    }
                </title>
                <meta
                    name="keywords"
                    content="наймикс личный кабинет, наймикс лк, наймекс лк, наймикс войти, наймикс вход для юридических лиц,
                     наймикс пароль, наймикс логин, наймикс забыли пароль, naimix.info, naimix, наймекс личный кабинет,
                      личный кабинет, naimix личный кабинет, namemix личный кабинет, naimex личный кабинет, войти самозанятые,
                       наймикс вход для самозанятых"
                />
                <meta
                    name="description"
                    content="Вход в Личный кабинет Наймикс"
                />
            </Helmet>
            <LoginHeader />
            <div className="login__content">
                <MaintenanceBanner />
                <Switch>
                    <Route
                        exact
                        path={LINK_LOGIN}
                        component={LoginAuth}
                    />
                    <Route
                        exact
                        path={LINK_REQUEST}
                        component={LoginJoin}
                    />
                </Switch>
            </div>
            <LoginFooter />
            {
                !isAgreeCookieSave &&
                <LoginCookie handleClose={cookieAgree} />
            }
        </div>
    );
};

export default Login;
