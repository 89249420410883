import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../components/CheckboxList";
import JobBoardLogo from "../../../../../components/JobBoardLogo";
import NmHintButton from "../../../../../components/NmHint/Button";
import NmLoader from "../../../../../components/NmLoader";
import NmSelectedList from "../../../../../components/NmSelectedList";
import ViewParsedHtmlTextButton from "../../../../../components/ViewParsedHtmlTextButton";
import {
    bffRecruitmentDirectoryAvitoBillingTypesSelector,
    bffRecruitmentDirectoryAvitoBusinessAreasSelector,
    bffRecruitmentDirectoryAvitoExperiencesSelector,
    bffRecruitmentDirectoryAvitoProfessionsSelector,
    bffRecruitmentDirectoryAvitoSchedulesSelector,
} from "../../../../../ducks/bff/recruitment/directory/selectors";
import {
    bffRecruitmentActiveJobBoardsListSelector,
    bffRecruitmentJobBoardsProgressSelector,
} from "../../../../../ducks/bff/recruitment/jobBoards/selectors";
import {
    bffRecruitmentVacancyCardSelector,
    bffRecruitmentVacancyJobBoardsListFiltersSelector,
    bffRecruitmentVacancyJobBoardsListProgressFiltersSelector,
    bffRecruitmentVacancyProgressActionSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {SUB_PAGE_ADVERTISEMENT} from "../../../../advertisement/card";
import OrderListStatus from "../../../../order/order-list/order-list-status";
import CreateVacancyOnJobBoardForm from "../../components/create-vacancy-on-job-board";
import RecruitmentSubscriptionManagementModal from "../../components/subscription-management-modal";
import {RecruitmentVacancyJobBoardAccountChange} from "../change-job-board-account";
import {Loader} from "semantic-ui-react";

import useRecruitmentVacancyJobBoardListAction from "./hooks/useAction";
import useRecruitmentVacancyJobBoardListFetch from "./hooks/useFetch";

import bem from "../../../../../utils/bem";
import {formatLocalDate, getDatePeriodLabelText} from "../../../../../utils/dateFormat";
import {formatAmount, getSalaryText} from "../../../../../utils/stringFormat";
import {getTargetAge} from "../../../../advertisement/card/utils";

import {COLOR} from "../../../../../constants/color";
import {GENDER_DICT} from "../../../../../constants/contractorInfo";
import {LINK_CLIENT_CARD_ADVERTISEMENT} from "../../../../../constants/links";
import {
    RECRUITMENT_SOURCE_JOB_BOARD,
    RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE,
} from "../../constants";

import {history} from "../../../../../store/configureStore";

import {getAdvertisementCardSelector, getAdvertisementProgressSelector} from "../../../../../ducks/advertisement";
import {getCitizenshipSelector} from "../../../../../ducks/contractor";
import {
    externalJobBoardsHhAreasOptionsSelector,
    externalJobBoardsHhBillingTypeDictSelector,
    externalJobBoardsHhExperienceDictSelector,
    externalJobBoardsHhScheduleDictSelector,
} from "../../../../../ducks/externalJobBoards";

import "./style.sass";

const RecruitmentVacancyJobBoardList = (props) => {
    const {
        match: {
            params: {
                clientId,
                vacancyId,
            },
        },
    } = props;

    const citizenshipDict = useSelector(getCitizenshipSelector);

    const listInfo = useSelector(bffRecruitmentVacancyJobBoardsListFiltersSelector);
    const activeJobBoardsList = useSelector(bffRecruitmentActiveJobBoardsListSelector);
    const naimixVacancyCard = useSelector(bffRecruitmentVacancyCardSelector);
    const naimixAdvertisementCard = useSelector(getAdvertisementCardSelector);
    const {progress: naimixAdvertisementCardProgress} = useSelector(getAdvertisementProgressSelector);
    const progress = useSelector(bffRecruitmentVacancyJobBoardsListProgressFiltersSelector);
    const progressJobBoardsConnectedInfo = useSelector(bffRecruitmentJobBoardsProgressSelector);
    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);
    const hhAreasOptions = useSelector(externalJobBoardsHhAreasOptionsSelector);
    const hhExperienceDict = useSelector(externalJobBoardsHhExperienceDictSelector);
    const hhScheduleDict = useSelector(externalJobBoardsHhScheduleDictSelector);
    const hhBillingTypeDict = useSelector(externalJobBoardsHhBillingTypeDictSelector);
    const avitoProfessionsDict = useSelector(bffRecruitmentDirectoryAvitoProfessionsSelector);
    const avitoSchedulesDict = useSelector(bffRecruitmentDirectoryAvitoSchedulesSelector);
    const avitoExperiencesDict = useSelector(bffRecruitmentDirectoryAvitoExperiencesSelector);
    const avitoBillingTypesDict = useSelector(bffRecruitmentDirectoryAvitoBillingTypesSelector);
    const avitoBusinessAreasDict = useSelector(bffRecruitmentDirectoryAvitoBusinessAreasSelector);

    const [block, element] = bem("recruitment-vacancy-job-board-list");

    const {
        fetchInfo,
    } = useRecruitmentVacancyJobBoardListFetch({
        clientId,
        vacancyId,
    });

    const {
        confirmData,
        setConfirmData,
        getMediaControls,
        downloadFile,
        createVacancyData,
        setCreateVacancyData,
        naimixHideDetails,
        setNaimixHideDetails,
        modalData,
        onCloseModal,
    } = useRecruitmentVacancyJobBoardListAction({
        clientId,
        vacancyId,
        fetchInfo,
        naimixVacancyCard,
    });

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                loading={progressAction}
            />
        );
    };

    const renderCreateVacancyOnJobBoardForm = () => {
        return (
            !isEmpty(createVacancyData) &&
            <CreateVacancyOnJobBoardForm
                {...createVacancyData}
                clientId={clientId}
                vacancyId={vacancyId}
                onClose={() => setCreateVacancyData({})}
                fetchInfo={fetchInfo}
            />
        );
    };

    const getHhText = (value, dict) => {
        if (!value) {
            return "-";
        }

        return dict.find(item => item.id === value)?.name || value;
    };

    const getLabels = (item) => {
        const generalLabels = [
            {
                label: "Название вакансии",
                text: item.title || "-",
            },
            {
                label: "Описание вакансии",
                text: item.description ? (
                    <ViewParsedHtmlTextButton
                        modalTitle={item.title}
                        children={item.description}
                    />
                ) : "-",
            },
        ];
        const salaryLabel = {
            label: "Зарплата, ₽",
            text: getSalaryText(item),
        };
        const salaryRangeLabel = {
            label: "Зарплата, ₽",
            text: !item.salary && !item.salaryTo ?
                "-" :
                `${item.salary ? formatAmount(item.salary) : "-"} - ${item.salaryTo ? formatAmount(item.salaryTo) : "-"}`,
        };
        const genderLabel = {
            label: "Пол",
            text: GENDER_DICT[item.gender] || "Все",
        };
        const experienceLabel = {
            label: "Опыт работы",
            text: item.experience || "-",
        };
        const scheduleLabel = {
            label: "График работы",
            text: item.schedule || "-",
        };

        if (!item.externalId && item.jobBoardType !== RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX) {
            return [];
        }

        const account = {
            label: "Аккаунт",
            text: `${item.jobBoardAccount?.clientUserName} ${item.jobBoardAccount?.login}`,
        };

        switch (item.jobBoardType) {
        case RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX: {
            const {
                recruiterName,
                funnelName,
                specialityName,
                dateFrom,
                dateTo,
                publishDateFrom,
                publishDateTo,
                createDate,
                workAddressInfo,
                fileName,
                ageFrom,
                ageTo,
                citizenships,
                additionalRequirements,
            } = item;

            const detailsLabels = !naimixHideDetails ? [
                {
                    label: "Воронка статусов",
                    text: funnelName || "-",
                },
                {
                    label: "Вид деятельности",
                    text: specialityName || "-",
                },
                {
                    label: "Город",
                    text: workAddressInfo || "-",
                    noWrap: false,
                },
                salaryLabel,
                {
                    label: "Гражданство",
                    text: citizenships?.length ?
                        <NmSelectedList
                            showedItemsCount={2}
                            isShowDetailModal={true}
                            showListWithoutValue={true}
                            modalTitle="Гражданство"
                            list={citizenships.map((item) => ({text: citizenshipDict[item]}))}
                        /> :
                        "Все",
                },
                genderLabel,
                {
                    label: "Возраст",
                    text: getTargetAge(ageFrom, ageTo),
                },
                {
                    label: "Дополнительные требования",
                    text: additionalRequirements?.length ? (
                        <NmShowMoreText
                            anchor="blue"
                            title="Дополнительные требования"
                            lines={1}
                            children={additionalRequirements.map(({value}) => value).join(", ")}
                            more="Подробнее"
                        />
                    ) :
                        "-",
                },
                {
                    label: "Файл",
                    text: fileName || "-",
                    color: fileName && "blue",
                    cursorPointer: Boolean(fileName),
                    onClickText: () => downloadFile(item),
                },
            ] : [];

            return ([
                ...generalLabels,
                {
                    label: "Дата создания",
                    text: createDate ? formatLocalDate(createDate, "dd.MM.yyyy HH:mm") : "-",
                },
                {
                    label: "Период размещения",
                    text: getDatePeriodLabelText(publishDateFrom, publishDateTo),
                },
                {
                    label: "Период работ",
                    text: getDatePeriodLabelText(dateFrom, dateTo),
                },
                {
                    label: "Рекрутер",
                    text: recruiterName || "-",
                },
                ...detailsLabels,
                {
                    text: <NmHintButton
                        onClick={() => setNaimixHideDetails(prevState => !prevState)}
                        isShown={!naimixHideDetails}
                        closeButtonText="Скрыть детали"
                        openButtonText="Показать детали"
                        className={element("hint-button")}
                          />,
                },
            ]);
        }
        case RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER:
        case RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU: {
            return [
                account,
                ...generalLabels,
                salaryLabel,
                {
                    label: "Тип размещения вакансии",
                    text: getHhText(item.billingType, hhBillingTypeDict),
                },
                {
                    label: "Город",
                    text: item.city ? hhAreasOptions.find(_item => _item.value === item.city)?.text : "-",
                },
                {
                    label: "Опыт работы",
                    text: getHhText(item.experience, hhExperienceDict),
                },
                {
                    label: "График работы",
                    text: getHhText(item.schedule, hhScheduleDict),
                },
            ];
        }
        case RECRUITMENT_SOURCE_JOB_BOARD.AVITO: {
            return [
                account,
                ...generalLabels,
                salaryLabel,
                {
                    label: "Тип размещения вакансии",
                    text: avitoBillingTypesDict[item.billingType] || "-",
                },
                {
                    label: "Профессия",
                    text: avitoProfessionsDict[item.profession] || "-",
                },
                {
                    label: "Сфера деятельности",
                    text: avitoBusinessAreasDict[item.businessArea] || "-",
                },
                {
                    label: "Опыт работы",
                    text: avitoExperiencesDict[item.experience] || "-",
                },
                {
                    label: "График работы",
                    text: avitoSchedulesDict[item.schedule] || "-",
                },
            ];
        }
        case RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB: {
            return [
                account,
                ...generalLabels,
                salaryRangeLabel,
                genderLabel,
                {
                    label: "Специальность",
                    text: item.specialityName || "-",
                },
                {
                    label: "Тип доступа вакансии",
                    text: item.specialityName || "-",
                },
                {
                    label: "Город",
                    text: item.cityName || "-",
                },
                experienceLabel,
                scheduleLabel,
            ];
        }
        default: {
            return [];
        }
        }
    };

    const getCards = (item) => {
        if (item.jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX || !item.externalId) {
            return null;
        }

        return [
            Boolean(item.vacancyId) && {
                title: "Количество обезличенных откликов",
                value: item.responsesCount || 0,
                className: "col-16 col-xxl-10",
            },
        ];
    };

    const handleOnClickAdvertisementLink = (advertisementId) => {
        const to = LINK_CLIENT_CARD_ADVERTISEMENT
            .replace(":clientId", clientId)
            .replace(":advertisementId", advertisementId)
            .replace(":subPage", SUB_PAGE_ADVERTISEMENT.INTERESTED.LINK);
        history.replace(to);
    };


    const getOtherContent = (item) => {
        if (item.jobBoardType !== RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX || !item.externalId) {
            return null;
        }

        if (naimixAdvertisementCardProgress) {
            return (
                <div className={element("advertisement-card-loader")}>
                    <Loader
                        active
                        inline
                    />
                </div>
            );
        }

        const {
            baseModel: {
                publicationDateFrom,
                publicationDateTo,
                dateFrom,
                dateTo,
                status,
                createDate,
                name,
                countContractors,
                description,
            },
            fioManager,
            countContractorViews,
        } = naimixAdvertisementCard;

        return (
            <NmListCard
                className={element("advertisement-card")}
                secondaryHeaderStatus={
                    <OrderListStatus
                        status={status}
                        isAdvertisement
                    />
                }
                secondaryHeader={`Объявление от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                onClickLink={() => handleOnClickAdvertisementLink(item.externalId)}
                primaryHeader={name}
                primarySubHeader={
                    <div className="col-16">
                        <NmShowMoreText
                            anchor="blue"
                            title={name}
                            lines={2}
                            children={description}
                            more="Подробнее"
                        />
                    </div>
                }
                primaryHeaderLink
                primaryHeaderTwoLines
                labels={[
                    {label: "Ответственный", text: fioManager},
                    {
                        label: "Период размещения",
                        text: `${formatLocalDate(publicationDateFrom)} - ${formatLocalDate(publicationDateTo)}`,
                        noWrap: false,
                    },
                    {
                        label: "Период в объявлении",
                        text: `${formatLocalDate(dateFrom)} - ${formatLocalDate(dateTo)}`,
                        noWrap: false,
                    },
                ]}
                cards={[
                    {
                        title: "Охват",
                        value: countContractors,
                        className: "col-16 col-xl-8",
                    },
                    {
                        title: "Просмотры",
                        value: countContractorViews || 0,
                        className: "col-16 col-xl-8 mt-md-4 mt-xl-0",
                    },
                ]}
                cardsWithContainer
                classNameMainContent="col-16 col-xl-10"
                cardsContainerClassName="col-16 col-xl-6 mt-md-4"
            />
        );
    };

    const getRows = () => {
        const list = [
            RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX,
            ...activeJobBoardsList,
        ];

        return list.map(jobBoardType => {
            const isNaimix = jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX;
            const info = listInfo.find(item => item.jobBoardType === jobBoardType) || {jobBoardType};
            const infoWithParams = isNaimix ? {
                ...naimixVacancyCard,
                ...info,
            } : {
                ...info.jobBoardParams,
                ...info,
            };

            return {
                key: jobBoardType,
                avatar: <JobBoardLogo
                    className={element("item-logo")}
                    name={jobBoardType}
                />,
                contentRow: (
                    <NmListCard
                        avatar
                        classNameMainContent="col-16 col-xxl-10"
                        primaryHeader={RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[jobBoardType]}
                        primarySubHeader={
                            !isNaimix && !info.externalId &&
                            <Text
                                level="2"
                                color={COLOR.SECONDARY_70}
                            >
                                Вакансия не создана на джоб-борде
                            </Text>
                        }
                        classNamePrimaryHeader={element("item-primary-header")}
                        labels={getLabels(infoWithParams)}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-6 mt-md-4 mt-xxl-0"
                        cards={getCards(infoWithParams)}
                        noDivider={isNaimix || !info.externalId}
                        otherContent={getOtherContent(info)}
                        mediaControls={getMediaControls(infoWithParams)}
                        isFixedActions
                    />
                ),
            };
        });
    };

    const renderSubscriptionManagementModal = () => {
        return (
            modalData?.isOpenWebhooksModal &&
            <RecruitmentSubscriptionManagementModal
                data={modalData}
                onClose={onCloseModal}
            />
        );
    };

    const renderChangeAccountInfo = () => {
        if (!modalData?.isOpenChangeAccount) {
            return null;
        }

        return (
            <RecruitmentVacancyJobBoardAccountChange
                clientId={clientId}
                vacancyId={vacancyId}
                account={modalData?.account || {}}
                onClose={onCloseModal}
                fetchInfo={fetchInfo}
            />
        );
    };

    return (
        <div className={block()}>
            {renderConfirmModal()}
            {renderCreateVacancyOnJobBoardForm()}
            {renderSubscriptionManagementModal()}
            {renderChangeAccountInfo()}
            {
                (progress || progressJobBoardsConnectedInfo) &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            <CheckboxList
                classNameAvatar={element("item-logo")}
                className={element("checkbox-list")}
                rows={getRows()}
            />
        </div>
    );
};

export default RecruitmentVacancyJobBoardList;