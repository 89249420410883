import {ceil, round} from "lodash";

import {formatNumber, formatNumberWithComma, getNumberFromFormattedAmount} from "../../../utils/stringFormat";
import {handleNumber} from "../../../utils/stringHelper";

import {ORDER_KIND} from "../../../constants/order";

export const calculatePayPopupWithRatingsAmounts = (params) => {
    const {
        isTemplateAct,
        orderKind,
        volumeOfWork,
        workUnitPrice,
        contractorCommission,
        sumOfMoney,
        isTaskAct,
        taskAmount,
    } = params;

    if (isTaskAct)  {
        const tax = getTax({
            amount: taskAmount,
        });

        const card = getCardAmount({
            amount: taskAmount,
            tax: getNumberFromFormattedAmount(tax),
            commission: 0,
        });

        return {
            amount: taskAmount,
            tax,
            card: getNumberFromFormattedAmount(card),
        };
    }

    if (isTemplateAct) {
        const amount = getAmount({
            isTemplateAct,
            workUnitPrice,
            volumeOfWork,
            orderKind,
            sumOfMoney,
        });

        const tax = getTax({
            amount,
        });

        const commission = getCommission({
            contractorCommission,
            amount,
        });

        const card = getCardAmount({
            amount,
            tax,
            commission,
            contractorCommission,
        });

        return {
            amount: orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER ? amount : sumOfMoney,
            tax,
            commission,
            card,
        };
    }

    if (orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER) {
        const volume = volumeOfWork ? volumeOfWork : 0;
        const amount = round(workUnitPrice * getNumberFromFormattedAmount(volume), 2);
        const tax = ceil(amount * 0.06, 2);
        const commission = round(amount * contractorCommission, 2);
        const card = amount - tax - commission;

        return {
            amount,
            tax,
            commission,
            card,
        };
    }

    const amount = sumOfMoney ? getNumberFromFormattedAmount(sumOfMoney) : 0;
    const tax = ceil(amount * 0.06, 2);
    const commission = round(amount * contractorCommission, 2);
    const card = amount - tax - commission;

    return {
        amount: sumOfMoney,
        tax,
        commission,
        card,
    };
};

const getVolumeOfWork = ({isTemplateAct, volumeOfWork}) => {
    if (isTemplateAct && typeof volumeOfWork !== "number") {
        return "-";
    }

    return volumeOfWork ? volumeOfWork : 0;
};

// Сумма заказа
const getAmount = (params) => {
    const {
        isTemplateAct,
        workUnitPrice,
        volumeOfWork,
        orderKind,
        sumOfMoney,
    } = params;

    if (typeof workUnitPrice !== "number") {
        return "-";
    }

    if (orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER) {
        const volume = getVolumeOfWork({isTemplateAct, volumeOfWork});

        if (volume === "-") {
            return "-";
        }

        const amount = round(workUnitPrice * getNumberFromFormattedAmount(volume), 2);

        return round(amount);
    }

    const amount = sumOfMoney ? getNumberFromFormattedAmount(sumOfMoney) : 0;

    return formatNumberWithComma(amount);
};

const getTax = (params) => {
    const {
        amount,
    } = params;

    // сумма не определена
    if (amount === "-") {
        return "-";
    }

    const tax = ceil(amount * 0.06, 2);

    return formatNumberWithComma(tax);
};

// Комиссия
const getCommission = (params) => {
    const {
        amount,
        contractorCommission,
    } = params;

    if (
        amount === "-" || // сумма не определена
            typeof contractorCommission !== "number" // не заполнено поле contractorCommission в модели заказа
    ) {
        return "-";
    }

    const commission = round(amount * contractorCommission, 2);

    return formatNumberWithComma(commission);
};

// На карту
const getCardAmount = (params) => {
    const {
        amount,
        tax,
        commission,
    } = params;

    const isAnyNotDefined = () => {
        return [
            amount,
            commission,
            tax,
        ].some(value => value === "-");
    };

    if (isAnyNotDefined()) {
        return "-";
    }

    const cardAmount = amount - tax - commission;

    return formatNumberWithComma(cardAmount);
};