import React, {useContext} from "react";
import {isEmpty} from "lodash";

import ContextMenu from "../../../../ActualComponents/ContextMenu";
import NmDivider from "../../../../ActualComponents/NmDivider";
import NmMiniInfoCard from "../../../../ActualComponents/NmMiniInfoCard";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";
import {RecruitmentCandidateInformationDeletingConfirm} from "../DeletingConfirm";
import {RecruitmentCandidateResumeLink} from "../ResumeLink";
import {RecruitmentCandidateFilesEdit} from "./Edit";

import {useModal} from "../../../../../hooks/useModal";

import {isLastElementInArray} from "../../../../../utils/objectHelper";
import {getCandidateInformationEditAction} from "../../utils/getEditAction";

import {COLOR} from "../../../../../constants/color";

export const RecruitmentCandidateFiles = (props) => {
    const {
        isAccessActions,
        files,
    } = props;

    const {
        deleteFile,
        fetchInformation,
        card,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const deleteItem = () => {
        deleteFile({
            id: confirmData.id,
            fileName: confirmData.fileName,
            onSuccess: () => {
                onCloseConfirm();
                fetchInformation();
            },
        });
    };

    return (
        <>
            {
                isOpenEdit &&
                <RecruitmentCandidateFilesEdit
                    onClose={onCloseEdit}
                />
            }
            {
                isOpenConfirm &&
                <RecruitmentCandidateInformationDeletingConfirm
                    onCancel={onCloseConfirm}
                    onConfirm={deleteItem}
                />
            }
            <NmMiniInfoCard
                title="Прикрепленные файлы"
                titleLevel="4"
                onClickAdd={() => {
                    onOpenEdit({});
                }}
                accessEdit={props.isAccessActions}
            >
                {
                    isEmpty(files) ?
                        "-" :
                        files.map((item, index) => {
                            const isLast = isLastElementInArray(files, index);

                            return (
                                <React.Fragment key={item.fileName}>
                                    <div className="d-flex justify-content-between align-items-start align-items-xxl-center mb-2">
                                        <RecruitmentCandidateResumeLink
                                            type={item.type}
                                            fileName={item.fileName}
                                            candidateId={card.candidateId}
                                            isName={true}
                                            name={item.fileName}
                                        />
                                        {
                                            isAccessActions &&
                                            <ContextMenu
                                                notBorder
                                                horizontalIcon
                                                disabled={false}
                                                clearWidth
                                                className="ms-1"
                                                options={getCandidateInformationEditAction({
                                                    onClickDelete: () => {
                                                        onOpenConfirm(item);
                                                    },
                                                })}
                                            />
                                        }
                                    </div>
                                    {
                                        !isLast &&
                                        <NmDivider
                                            color={COLOR.SECONDARY_5}
                                            className="mt-2 mb-3 mb-md-4"
                                        />
                                    }
                                </React.Fragment>
                            );
                        })
                }
            </NmMiniInfoCard>
        </>
    );
};
