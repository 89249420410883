import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import NmButton from "../../../../components/NmButton";
import {ReactComponent as HorecaLogo} from "../../../../images/horeca-logo.svg";
import {ReactComponent as PhoneIcon} from "../../../../images/phone_open-naimix_24.svg";

import {useRightsStatusStrapi} from "../../../../hooks/useRightsStatusStrapi";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";
import {MENU_DESIGN_TYPE} from "../../../../constants/menu";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";

import "./style.sass";

const OnboardingCheckDesignModal = (props) => {
    const {
        onClose,
        primaryColor,
        primaryTextColor,
        secondaryColor,
        secondaryTextColor,
        logo,
        welcomeString,
        supportPhone,
    } = props;

    const [file, setFile] = useState({});

    const [block, element] = bem("onboarding-check-design-modal");

    const {text: rightStatusText} = useRightsStatusStrapi();

    const card = useSelector(getClientPropertiesCardSelector);

    const isHoreca = card.designMenuType === MENU_DESIGN_TYPE.HO_RE_CA_MENU;

    useEffect(() => {
        if (logo) {
            const fileWithPreview = Object.assign(logo, {
                preview: URL.createObjectURL(logo),
            },
            );

            setFile(fileWithPreview);
        }
    }, []);

    const getLogo = () => {
        if (isHoreca) {
            return (
                <HorecaLogo
                    className={element("horeca-logo")}
                    color={COLOR.BLACK_100}
                />
            );
        }

        if (file.preview) {
            return (
                <img
                    className={element("custom-logo")}
                    src={file.preview}
                    alt={""}
                />
            );
        }

        return <div className={element("logo")} />;
    };

    return (
        <NmModal
            size="lg"
            className={block()}
            onClose={onClose}
        >
            <div>
                <div className={element("header")}>
                    <div className={element("header-container")}>
                        {getLogo()}
                        <div className={element("phone", {horeca: isHoreca})}>
                            <PhoneIcon
                                className={element("phone-icon")}
                            />
                            <a className={element("phone-number")}>
                                {supportPhone}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={element("info-container")}>
                    <div className={element("info-content")}>
                        <div className={element("info-block", {horeca: isHoreca})}>
                            <Text level="4">
                                {welcomeString}
                            </Text>
                            <NmButton
                                size="xl"
                                color={isHoreca ? "horeca" : undefined}
                                className={element("button")}
                                style={{
                                    backgroundColor: primaryColor,
                                    color: primaryTextColor,
                                }}
                            >
                                Начать
                            </NmButton>
                        </div>
                    </div>
                </div>
                <div
                    className={element("footer", {horeca: isHoreca})}
                    style={
                        isHoreca
                            ? undefined
                            : {
                                backgroundColor: secondaryColor,
                                color: secondaryTextColor,
                            }
                    }
                >
                    <div className={element("footer-container")}>
                        <div className={element("copyright")}>
                            {rightStatusText}
                        </div>
                        <div className={element("links")}>
                            <a
                                className={element("privacy")}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{color: secondaryTextColor}}
                            >
                                Политика обработки персональных данных
                            </a>
                            <a
                                className={element("agreement")}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{color: secondaryTextColor}}
                            >
                                Пользовательское соглашение
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </NmModal>
    );
};

export default OnboardingCheckDesignModal;