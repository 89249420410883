import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import {withPageData} from "../../../../components/withPageData";
import {history} from "../../../../store/configureStore";
import NmBaseClientContractorListFilter from "../nm-base-client-contractor-list-filter";
import NmBaseContractorListFilter from "../nm-base-contractor-list-filter";
import NmBaseInviteContractorListFilter from "../nm-base-invite-contractor-list-filter";

import {CONTRACTOR_LIST_FILTER_TYPE} from "../../../../constants/contractorList";

import {adressByIdProgressSelector} from "../../../../ducks/address";
import {clientGroupOptionsSelector} from "../../../../ducks/client";
import {
    contractorRegistrationStatesOptionsSelector,
    contractorsOrderStatusOptionsSelector,
    contractorStatusOptionsSelector,
    getCitizenshipOptionsSelector,
    getCitizenshipSmzOnlyOptionsSelector,
    getWorkRegionsWithRemoteWorkOptionsSelector,
} from "../../../../ducks/contractor";
import {
    contractorDrivingLicenseDictOptionsSelector,
    contractorEducationLevelDictOptionsSelector,
    contractorLanguagesDictOptionsSelector,
} from "../../../../ducks/contractorProfile";
import {projectParamListOptionsSelector} from "../../../../ducks/projectParams";
import {specialitiesAllV2OptionsSelector} from "../../../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

class NmGeneralContractorListFilter extends Component {
    static propTypes = {
        filterType: PropTypes.oneOf([
            CONTRACTOR_LIST_FILTER_TYPE.ADMIN_FILTER,
            CONTRACTOR_LIST_FILTER_TYPE.CLIENT_FILTER,
            CONTRACTOR_LIST_FILTER_TYPE.INVITE_FILTER,
        ]),
        filter: PropTypes.object,
        sendFilter: PropTypes.func,
        hideFilter: PropTypes.func,
    };

    static defaultProps = {
        sendFilter: () => {
        },
    };

    constructor(props) {

        super(props);

        const {
            filter,
        } = props;

        this.state = {
            filterData: {...filter},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            filter: oldFilter,
        } = prevProps;
        const {
            filter,
        } = this.props;

        if (!isEqual(oldFilter, filter)) {
            this.setState({filterData: {...filter}});
        }
    }


    static getDerivedStateFromProps(props, state) {
        const {
            filterData,
        } = state;

        const {
            filter: {
                initRegionValueArr,
                addressFiasIdsFilter,
            },
        } = props;

        const {
            addressFiasIdsFilter: currentAddressFiasIdsFilter,
        } = filterData;

        //предзаполнение регионов при поиске на заказ
        if (!isEqual(currentAddressFiasIdsFilter, addressFiasIdsFilter) && currentAddressFiasIdsFilter === undefined) {
            return {
                ...state,
                filterData: {
                    ...filterData,
                    addressFiasIdsFilter,
                    initRegionValueArr,
                },
            };
        }

        return state;
    };

    clearFilter = () => {
        const {
            sendFilter,
        } = this.props;

        const filterData = {
            registrationStatusFilter: null,
            subcategoryIdsFilter: null,
            categoryIdsFilter: null,
            schedulesFilter: null,
            citizenshipFilter: null,
            migrationStatusesFilter: [],
            clientGroupClientIds: [],
            orderStatusFilter: null,
            genderFilter: null,
            phoneFilter: "",
            ageFromFilter: "",
            innFilter: "",
            ageToFilter: "",
            fioFilter: "",
            projectParamFilter: null,
            specialityIdsFilter: null,
            driverLicenseFilter: null,
            familyStatusFilter: null,
            educationLevelFilter: null,
            foreignLanguageFilter: null,
            ownCarFilter: null,
            contractorFilter: null,
            fullyRegisteredFilter: null,
            joinDateFromFilter: null,
            joinDateToFilter: null,
            fullRegistrationDateFromFilter: null,
            fullRegistrationDateToFilter: null,
            bankRegistrationFilter: null,
            bankConfirmationFilter: null,
            addressFiasIdsFilter: null,
            initRegionValueArr: [],
            workWithClient: null,
            sortType: null,
            remoteWorkFilter: null,
            blocked: null,
            blockedCauseFilter: null,
            innPassportDataValidStatusFilter: null,
            lastLoginFilter: null,
        };

        this.setState({
            filterData,
        }, sendFilter(filterData, false));
    };

    changeSpecialities = (e, {name, value}) => {
        this.handleFilter(e, {name, value});
    };

    updateWindowHistory = (name, value) => {
        const {pathname} = this.props;
        const fullState = window.history.state || {};

        const {state} = fullState;

        if (state) {
            const {filterData} = state;
            const newFilterData = {
                ...filterData,
                [name]: value,
            };

            history.replace(pathname, {...state, filterData: newFilterData, isLoadDataTarget: true});
        }
    };

    handleFilter = (e, {name, value, checked}) => {
        this.updateWindowHistory(name, value);

        this.setState(prevState => ({
            ...prevState,
            filterData: {
                ...prevState.filterData,
                [name]: checked === false ? checked : checked || value,
            },
        }));
    };

    render() {
        const {
            filterData,
        } = this.state;

        const {
            t,
            filterType,
            workRegions,
            specialityOptions,
            citizenshipOptions,
            citizenshipOptionsSmzOnly,
            contractorStatusOptions,
            projectParamsOptions,
            contractorRegistrationStatesOptions,
            clientGroupOptions,
            orderAddressProgress,
            driverLicenseOptions,
            languagesOptions,
            contractorOrderStatusOptions,
            educationOptions,
            sendFilter,
            hideFilter,
        } = this.props;

        switch (filterType) {
        case CONTRACTOR_LIST_FILTER_TYPE.INVITE_FILTER:
            return (
                <div
                    className="flex-flow-wrap"
                >
                    <NmBaseInviteContractorListFilter
                        t={t}
                        workRegions={workRegions}
                        сitizenshipOptions={citizenshipOptionsSmzOnly}
                        contractorStatusOptions={contractorStatusOptions}
                        specialityOptions={specialityOptions}
                        orderAddressProgress={orderAddressProgress}
                        filterData={filterData}
                        sendFilter={sendFilter(filterData, true)}
                        onChange={this.handleFilter}
                        clearFilter={this.clearFilter}
                        educationOptions={educationOptions}
                        languagesOptions={languagesOptions}
                        driverLicenseOptions={driverLicenseOptions}
                    />
                </div>
            );
        case CONTRACTOR_LIST_FILTER_TYPE.CLIENT_FILTER:
            return (
                <div
                    className="flex-flow-wrap"
                >
                    <NmBaseClientContractorListFilter
                        t={t}
                        workRegions={workRegions}
                        specialityOptions={specialityOptions}
                        сitizenshipOptions={citizenshipOptions}
                        contractorStatusOptions={contractorStatusOptions}
                        filterData={filterData}
                        sendFilter={sendFilter(filterData)}
                        onChange={this.handleFilter}
                        clearFilter={this.clearFilter}
                        changeSpecialities={this.changeSpecialities}
                        educationOptions={educationOptions}
                        languagesOptions={languagesOptions}
                        driverLicenseOptions={driverLicenseOptions}
                    />
                </div>
            );
        case CONTRACTOR_LIST_FILTER_TYPE.ADMIN_FILTER:
            return (
                <NmBaseContractorListFilter
                    t={t}
                    hideFilter={hideFilter}
                    workRegions={workRegions}
                    contractorStatusOptions={contractorStatusOptions}
                    specialityOptions={specialityOptions}
                    сitizenshipOptions={citizenshipOptions}
                    clientGroupOptions={clientGroupOptions}
                    onClientGroupSearch={this.onClientGroupSearch}
                    contractorRegistrationStatesOptions={contractorRegistrationStatesOptions}
                    contractorOrderStatusOptions={contractorOrderStatusOptions}
                    educationOptions={educationOptions}
                    languagesOptions={languagesOptions}
                    driverLicenseOptions={driverLicenseOptions}
                    fnsLabelTooltip={t("contractor-list.fns-filter-help")}
                    fnsLabel={t("contractor-list.FNS")}
                    filterData={filterData}
                    projectParamsOptions={projectParamsOptions}
                    sendFilter={sendFilter(filterData)}
                    onChange={this.handleFilter}
                    clearFilter={this.clearFilter}
                    changeSpecialities={this.changeSpecialities}
                />
            );
        default:
            return null;
        }
    };
}

export default withPageData(connect(
    state => ({
        workRegions: getWorkRegionsWithRemoteWorkOptionsSelector(state),
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        citizenshipOptions: getCitizenshipOptionsSelector(state),
        citizenshipOptionsSmzOnly: getCitizenshipSmzOnlyOptionsSelector(state),
        educationOptions: contractorEducationLevelDictOptionsSelector(state),
        languagesOptions: contractorLanguagesDictOptionsSelector(state),
        driverLicenseOptions: contractorDrivingLicenseDictOptionsSelector(state),
        clientGroupOptions: clientGroupOptionsSelector(state),
        projectParamsOptions: projectParamListOptionsSelector(state),
        contractorStatusOptions: contractorStatusOptionsSelector(state),
        contractorOrderStatusOptions: contractorsOrderStatusOptionsSelector(state),
        orderAddressProgress: adressByIdProgressSelector(state),
        contractorRegistrationStatesOptions: contractorRegistrationStatesOptionsSelector(state),
    }),
    {},
)(withTranslation()(NmGeneralContractorListFilter)));