import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {
    searchRecruitmentRejectionReason,
    updateRecruitmentDirectoryStore,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    bffRecruitmentDirectoryRejectionReasonOptionsSelector,
} from "../../../../../ducks/bff/recruitment/directory/selectors";
import NmDropdownV2 from "../../../../ActualComponents/NmDropdownV2";
import NmForm from "../../../../ActualComponents/NmForm";
import NmModal from "../../../../ActualComponents/NmModal";
import NmTextareaV2 from "../../../../ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";

export const RecruitmentVacancyRejectCandidate = (props) => {
    const {
        onClose,
        reject,
        progressAction,
        clientId,
    } = props;

    const dispatch = useDispatch();

    const options = useSelector(bffRecruitmentDirectoryRejectionReasonOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                rejectionReasonsList: [],
                rejectionReasonsPageData: {},
                rejectionReasonsTotalCount: 0,
            }));
        };
    }, []);

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
    } = useFormik({
        onSubmit,
        initialValues: {
            rejectionComment: "",
            rejectionReasonId: "",
        },
        validationSchema: yup.object().shape({
            rejectionComment: yup.string().min(3, "Не менее 3 символов"),
        }),
    });

    function onSubmit(values) {
        reject({
            rejectionComment: values.rejectionComment ? values.rejectionComment : undefined,
            rejectionReasonId: values.rejectionReasonId ? values.rejectionReasonId : undefined,
        });
    }

    const onSearchChange = (value) => {
        dispatch(searchRecruitmentRejectionReason({
            clientId,
            pageNum: 1,
            pageSize: 25,
            value: value ? value : undefined,
        }));
    };

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Отказ кандидату в вакансии
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    cancelBtnContent="Отменить"
                    submitBtnContent="Подтвердить"
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progressAction}
                />
            }
        >
            <NmForm addMargin>
                <NmDropdownV2
                    size="xl"
                    search
                    label="Причина отказа"
                    placeholder="Выберите причину отказа"
                    options={options}
                    value={values.rejectionReasonId}
                    name="rejectionReasonId"
                    onChange={onChange}
                    onSearchChange={onSearchChange}
                />
                <NmTextareaV2
                    size="xl"
                    label="Комментарий"
                    placeholder="Оставьте комментарий"
                    name="rejectionComment"
                    minRows={5}
                    value={values.rejectionComment}
                    onChange={onChange}
                    maxLength={5000}
                    error={touched.rejectionComment && errors.rejectionComment}
                />
            </NmForm>
        </NmModal>
    );
};