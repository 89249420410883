import {useMemo, useState} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../components/ActualComponents/Filter";

import {getUtcDateFilter} from "../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {handleFormString} from "../../../utils/stringHelper";

import {COMMON_ALL_OPTION} from "../../../constants/common";
import {ONBOARDING_SCENARIO_STATUS_DICT} from "../../../constants/onboarding";

import {bffCommonOnBoardingScenarioFrameContractTypeDictOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";

export function useOnboardingListFilter({}) {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const statusOptions = dictionaryToOptions(ONBOARDING_SCENARIO_STATUS_DICT);
    const frameContractTypeDictOptions = useSelector(bffCommonOnBoardingScenarioFrameContractTypeDictOptionsSelector);

    function mapFilterDto(filter) {
        const {
            nameFilter,
            numberFilter,
            creationDateTimeFrom,
            creationDateTimeTo,
            statusFilter,
            frameContractTypeFilter,
        } = filter;

        return {
            ...filter,
            nameFilter: handleFormString(nameFilter),
            numberFilter: handleFormString(numberFilter),
            creationDateTimeFrom: creationDateTimeFrom ? getUtcDateFilter(creationDateTimeFrom) : undefined,
            creationDateTimeTo: creationDateTimeTo ? getUtcDateFilter(creationDateTimeTo) : undefined,
            statusFilter: statusFilter ? statusFilter : undefined,
            frameContractTypeFilter: frameContractTypeFilter !== COMMON_ALL_OPTION.key ? frameContractTypeFilter : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Название",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "numberFilter",
                        label: "Номер сценария",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFrom",
                        endFieldName: "creationDateTimeTo",
                        isClearable: true,
                        label: "Дата",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус",
                        placeholder: "Выберите статус",
                        name: "statusFilter",
                        options: statusOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: false,
                        label: "Тип договора",
                        placeholder: "Выберите",
                        name: "frameContractTypeFilter",
                        options: frameContractTypeDictOptions,
                    },
                ],
            },
        ];
    }, [frameContractTypeDictOptions]);

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    };
}