import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ApplyButtons from "../../../../ApplyButtons";
import NmAccordionV2 from "../../../../NmAccordionV2";
import NmLoader from "../../../../NmLoader";
import NmTitle from "../../../../NmTitle";
import HelpTooltip from "../../../HelpTooltip";
import NmCheckboxV2 from "../../../NmCheckboxV2";
import NmConfirmV2 from "../../../NmConfirmV2";
import NmDropdownV2 from "../../../NmDropdownV2";
import NmModal from "../../../NmModal";
import Text from "../../../Text";
import {ClientSettingsChangedDataModal} from "../ChangedDataModal";
import {ClientSettingsSubmitButtons} from "../SubmitButtons";

import {useClientSettingsDepositAndPaymentsForm} from "./hooks/useForm";

import {dictionaryToOptions, isLastElementInArray} from "../../../../../utils/objectHelper";

import {
    BANK_TYPE_CARD_OPTIONS,
    BANK_TYPE_WITH_PATH_DICT,
} from "../../../../../constants/clientSettings";
import {COLOR} from "../../../../../constants/color";
import {NM_CONSULTANT} from "../../../../../constants/roles";

import {
    bffClientsSettingsPaymentsObjectForPaidApisOptionsSelector,
    bffClientsSettingsPaymentsSelector,
} from "../../../../../ducks/bff/clients/settings/selectors";

export const ClientSettingsDepositAndPayments = (props) => {
    const {
        clientId,
        isAccess,
        role,
        refFooter,
        onClose,
    } = props;

    const card = useSelector(bffClientsSettingsPaymentsSelector);
    const objectOptions = useSelector(bffClientsSettingsPaymentsObjectForPaidApisOptionsSelector);

    const {
        values,
        errors,
        touched,
        onSubmit,
        getPaymentMethodOptions,
        isShowDepositDistributedByObjectsConfirm,
        onCloseDepositDistributedByObjectConfirm,
        onConfirmDepositDistributedByObjectsShutdown,
        isOpenConfirm,
        onCloseConfirm,
        onChange,
        paymentBlockList,
    } = useClientSettingsDepositAndPaymentsForm({
        clientId,
        onClose,
    });

    const getDepositDistributedByObjectsConfirm = () => {
        if (!isShowDepositDistributedByObjectsConfirm) {
            return null;

        }
        return (
            <NmConfirmV2
                warning
                isHiddenCancelOnMobile
                mobile="column"
                content="При выключении настройки распределения депозита по объектам весь распределенный по объектам депозит и нераспределенный по объектам депозит компании перейдет в общий депозит компании"
                onCancel={onCloseDepositDistributedByObjectConfirm}
                onConfirm={onConfirmDepositDistributedByObjectsShutdown}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getWarningConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmModal
                size="sm"
                contentAlign="center"
                onClose={onCloseConfirm}
                footer={
                    <ApplyButtons
                        mobile="column"
                        align="center"
                        submitBtnContent="ОК"
                        submit={onCloseConfirm}
                    />
                }
                children="Изменение модели ведения оплат невозможно, поскольку применено массовое отключение опции оплат без согласования администратором Наймикс"
            />
        );
    };

    const renderPaymentsBlock = (params) => {
        const {
            title,
            marketplaceField,
            registryField,
            apiField,
            isLast,
        } = params;

        return (
            <NmAccordionV2
                noStyles={true}
                className={!isLast && "mb-3 mb-md-4"}
                title={
                    <Text
                        type="title"
                        level="4"
                        color={COLOR.SECONDARY_75}
                    >
                        {title}
                    </Text>
                }
            >
                {getBankBlock({
                    title: "По заказам",
                    field: marketplaceField,
                })}
                {getBankBlock({
                    title: "По реестрам",
                    field: registryField,
                })}
                {getBankBlock({
                    title: "По API",
                    field: apiField,
                })}
            </NmAccordionV2>
        );
    };

    const getBankBlock = (params) => {
        const {
            title,
            field,
        } = params;

        console.log("card", card);
        function getAccessCardBanks() {
            return card[field]?.banksCard?.map(({bankType, paymentPathType}) =>
                `${bankType}+${paymentPathType}`,
            ) || [];
        }

        function getAccessBanks(fieldBanks) {
            return card[field]?.[fieldBanks]?.map(({bankType}) => bankType) || [];
        }

        const bankTypeCardOptions = dictionaryToOptions(BANK_TYPE_WITH_PATH_DICT).filter(item => getAccessCardBanks().includes(item.value));
        const bankTypeSbpOptions = BANK_TYPE_CARD_OPTIONS.filter(item => getAccessBanks("banksSbp").includes(item.value));
        const bankTypeRequisitesOptions = BANK_TYPE_CARD_OPTIONS.filter(item => getAccessBanks("banksRequisites").includes(item.value));
        const isOnlyTypeRequisites = [
            "individualMarketplace",
            "individualRegistry",
            "individualApi",
        ].includes(field);

        return (
            <>
                <NmTitle size="md">
                    {title}
                </NmTitle>
                {
                    !isOnlyTypeRequisites &&
                    <>
                        <div className="row align-items-start gx-4 mb-3 mb-md-4">
                            <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                                <NmCheckboxV2
                                    name="useBankTypeCard"
                                    onChange={(event, params) => {
                                        onChange(event, params, field);
                                    }}
                                    className="mt-0 mt-xl-5"
                                    checked={values[field]?.useBankTypeCard}
                                    label="На карты РФ"
                                />
                            </div>
                            <div className="col-16 col-xl-8">
                                <NmDropdownV2
                                    required={values[field]?.useBankTypeCard}
                                    disabled={!values[field]?.useBankTypeCard}
                                    label="Выберите банк / путь оплаты"
                                    name="bankTypeCard"
                                    options={bankTypeCardOptions}
                                    value={values[field]?.bankTypeCard}
                                    onChange={(event, params) => {
                                        onChange(event, params, field);
                                    }}
                                    error={
                                        touched[field]?.bankTypeCard &&
                                        errors[field]?.bankTypeCard
                                    }
                                />
                            </div>
                        </div>
                        <div className="row align-items-start gx-4 mb-3 mb-md-4">
                            <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                                <NmCheckboxV2
                                    name="useBankTypeSbp"
                                    onChange={(event, params) => {
                                        onChange(event, params, field);
                                    }}
                                    className="mt-0 mt-xl-5"
                                    checked={values[field]?.useBankTypeSbp}
                                    label="Через СБП"
                                />
                            </div>
                            <div className="col-16 col-xl-8">
                                <NmDropdownV2
                                    required={values[field]?.useBankTypeSbp}
                                    disabled={!values[field]?.useBankTypeSbp}
                                    label="Выберите банк"
                                    name="bankTypeSbp"
                                    options={bankTypeSbpOptions}
                                    value={values[field]?.bankTypeSbp}
                                    onChange={(event, params) => {
                                        onChange(event, params, field);
                                    }}
                                    error={
                                        touched[field]?.bankTypeSbp &&
                                        errors[field]?.bankTypeSbp
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
                <div className="row align-items-start gx-4 mb-3 mb-md-4">
                    <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                        <NmCheckboxV2
                            name="useBankTypeRequisites"
                            onChange={(event, params) => {
                                onChange(event, params, field);
                            }}
                            className="mt-0 mt-xl-5"
                            checked={values[field]?.useBankTypeRequisites}
                            label="По банковским платежным реквизитам"
                        />
                    </div>
                    <div className="col-16 col-xl-8">
                        <NmDropdownV2
                            required={values[field]?.useBankTypeRequisites}
                            disabled={!values[field]?.useBankTypeRequisites}
                            label="Выберите банк"
                            name="bankTypeRequisites"
                            options={bankTypeRequisitesOptions}
                            value={values[field]?.bankTypeRequisites}
                            onChange={(event, params) => {
                                onChange(event, params, field);
                            }}
                            error={
                                touched[field]?.bankTypeRequisites &&
                                errors[field]?.bankTypeRequisites
                            }
                        />
                    </div>
                </div>
                {
                    !isOnlyTypeRequisites &&
                    <div className="row align-items-start gx-4">
                        <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                            <Text
                                level="3"
                                color={COLOR.SECONDARY_75}
                                className="mt-0 mt-xl-5"
                            >
                                Способ по-умолчанию
                            </Text>
                        </div>
                        <div className="col-16 col-xl-8">
                            <NmDropdownV2
                                required
                                label="Выберите способ"
                                name="paymentMethod"
                                options={getPaymentMethodOptions(field)}
                                value={values[field]?.paymentMethod}
                                onChange={(event, params) => {
                                    onChange(event, params, field);
                                }}
                                error={
                                    touched[field]?.paymentMethod &&
                                    errors[field]?.paymentMethod
                                }
                            />
                        </div>
                    </div>
                }
            </>
        );
    };

    return (
        <>
            <ClientSettingsChangedDataModal onSubmit={onSubmit} />
            {
                isEmpty(card) &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            {getWarningConfirm()}
            {getDepositDistributedByObjectsConfirm()}
            {
                NM_CONSULTANT !== role &&
                <>
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        disabled={!isAccess}
                        name="smzPaymentsWithoutConfirmNmAdmin"
                        onChange={onChange}
                        checked={values.smzPaymentsWithoutConfirmNmAdmin}
                        label="Ведение оплат без согласования администратором Наймикс (НПД)"
                    />
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        disabled={!isAccess}
                        name="civilPaymentsWithoutConfirmNmAdmin"
                        onChange={onChange}
                        checked={values.civilPaymentsWithoutConfirmNmAdmin}
                        label="Ведение оплат без согласования администратором Наймикс (НДФЛ)"
                    />
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        disabled={!isAccess}
                        name="individualPaymentsWithoutConfirmNmAdmin"
                        onChange={onChange}
                        checked={values.individualPaymentsWithoutConfirmNmAdmin}
                        label="Ведение оплат без согласования администратором Наймикс (ИП)"
                    />
                </>
            }
            <div className="row align-items-center mb-3 mb-md-4">
                <div className="col-16 col-xl-8 mb-3 mb-xl-0">
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        name="distributeDepositByObjects"
                        onChange={onChange}
                        checked={values.distributeDepositByObjects}
                        label="Распределение депозита по объектам"
                    />
                </div>
                <div className="col-16 col-xl-8">
                    <NmDropdownV2
                        search
                        required={values.distributeDepositByObjects}
                        disabled={!values.distributeDepositByObjects}
                        label="Объект для списания с депозита"
                        name="objectIdForPaidApis"
                        options={objectOptions}
                        value={values.objectIdForPaidApis}
                        onChange={onChange}
                        error={
                            touched?.objectIdForPaidApis &&
                            errors?.objectIdForPaidApis
                        }
                        isVisibleTooltip
                        tooltip={
                            <HelpTooltip
                                hover
                                info
                                children="При включении платных методов API для компании списание средств будет происходить с депозита выбранного объекта"
                            />
                        }
                    />
                </div>
            </div>
            {
                paymentBlockList.map((item, index) => {
                    const isLast = isLastElementInArray(paymentBlockList, index);

                    return (renderPaymentsBlock({
                        ...item,
                        isLast,
                    }));
                })
            }
            <ClientSettingsSubmitButtons
                refFooter={refFooter}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
