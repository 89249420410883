import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {RecruitmentRecruitersAddForm} from "../../../../../../../components/Recruitment/RecruitersAddForm";
import {
    clearAvailableRecruitersRecruitmentAccessControlRecruitersGroupsCardStore,
    getAvailableRecruitersPageRecruitmentAccessControlRecruitersGroupsCard,
} from "../../../../../../../ducks/bff/recruitment/access-control/recruiters-groups/actionsCreators";
import {
    bffRecruitmentAccessControlRecruitersGroupsCardAddLoadingSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListLoadingSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListTotalCountSelector,
    bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListTotalPagesSelector,
} from "../../../../../../../ducks/bff/recruitment/access-control/recruiters-groups/selectors";

export const RecruitmentAccessControlRecruitersGroupsCardAddRecruiters = (props) => {
    const {
        onAdd,
        onClose,
        groupId,
        clientId,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListSelector);
    const listTotalCount = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListTotalCountSelector);
    const listLoading = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListLoadingSelector);
    const listTotalPages = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListTotalPagesSelector);
    const addLoading = useSelector(bffRecruitmentAccessControlRecruitersGroupsCardAddLoadingSelector);

    useEffect(() => {
        return () => {
            dispatch(clearAvailableRecruitersRecruitmentAccessControlRecruitersGroupsCardStore());
        };
    }, []);

    const fetchList = (data) => {
        const {
            pageNum,
            pageSize,
            ...filterData
        } = data;

        dispatch(getAvailableRecruitersPageRecruitmentAccessControlRecruitersGroupsCard({
            id: groupId,
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return (
        <RecruitmentRecruitersAddForm
            addLoading={addLoading}
            list={list}
            listLoading={listLoading}
            listTotalCount={listTotalCount}
            listTotalPages={listTotalPages}
            onAdd={onAdd}
            onClose={onClose}
            modalLoaderContent={addLoading ? "Добавление рекрутеров в группу..." : "Загрузка..."}
            modalHeader="Добавление рекрутеров в группу"
            isMultipleSelection={true}
            fetchList={fetchList}
        />
    );
};