export const getVacancyTitle = (params) => {
    const {
        isVisibleVacancyText,
        vacancyNumber,
        title,
        workAddressInfo,
    } = params;

    const mainText = `${isVisibleVacancyText ? "Вакансия " : ""}№${vacancyNumber} - ${title}`;

    if (workAddressInfo) {
        return `${mainText} - ${workAddressInfo}`;
    }

    return mainText;
};