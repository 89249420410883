import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../components/ActualComponents/NmPageInfoCardsAccordion";
import Tabs from "../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import FinanceBankFilter from "./finance-bank-filter";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import {useFinanceBankAction} from "./hooks/useAction";
import {useFinanceBankFetch} from "./hooks/useFetch";
import {useFinanceBankSort} from "./hooks/useSort";

import dateFormat from "../../../utils/dateFormat";
import {formatNumberAmount} from "../../../utils/stringFormat";

import {STATUS_BANK_DEPOSIT} from "../../../constants/bankDeposit";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {LINK_CLIENT_INFO, LINK_FORBIDDEN_PAGE} from "../../../constants/links";

import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";

import "./style.sass";

export const FINANCE_BANK_SUB_PAGE = {
    W1: "W1",
    TKB: "TKB",
};

const FinanceBank = () => {
    const [subPage, setSubPage] = useState(FINANCE_BANK_SUB_PAGE.W1);

    const isTkbPage = subPage === FINANCE_BANK_SUB_PAGE.TKB;
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
    } = useFilter({
        initFilter: {
            operationTypeFilter: "all",
            fromDateFilter: null,
            toDateFilter: null,
            clientIdFilter: "",
        },
        pageSize,
        setPagination,
    });

    const {
        sortType,
        sortOptions,
        onClickSort,
    } = useFinanceBankSort();

    const {
        fetchList,
        totalPages,
        list,
        data,
        totalCount,
        progress,
    } = useFinanceBankFetch({
        pageNum,
        pageSize,
        filterData,
        sortType,
        isTkbPage,
    });

    const {
        getMediaControls,
        mediaControls,
    } = useFinanceBankAction({
        fetchList,
        filter,
    });

    const renderClientInfo = (item) => {
        const {
            name,
            brand,
            clientId,
            clientNamePlatform,
            clientName,
        } = item;

        const linkToClientCard = LINK_CLIENT_INFO.replace(":clientId", clientId);

        return [
            {
                label: "Название контрагента (на площадке)",
                columnOnMobile: true,
                text: (
                    <ExtLink
                        to={linkToClientCard}
                        pageData={{
                            pageNum,
                            pageSize,
                        }}
                        historyEnabled
                    >
                        {clientNamePlatform}
                        {brand && `(${brand})`}
                    </ExtLink>
                ),
            },
            {
                label: "Название контрагента (в банке)",
                columnOnMobile: true,
                text: isTkbPage ? clientName : name,
            },
        ];
    };

    const getRowCards = (item) => {
        const amount = formatNumberAmount(item.amount, false);
        const direction = isTkbPage ? !item.credit : item.direction;

        return [
            {
                title: "Приход, ₽",
                value: direction ? amount : "-",
                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
            },
            {
                title: "Расход, ₽",
                value: !direction ? amount : "-",
                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
            },
        ].filter(value => value);
    };

    const renderStatus = (item) => {
        const {
            status,
            descriptionError,
        } = item;

        const {
            BADGE_MOD,
            TEXT,
        } = STATUS_BANK_DEPOSIT[status] || {
            BADGE_MOD: "gray",
            TEXT: "Не определено",
        };

        return (
            <NmBadge
                condition
                blockClassName="finance-bank__badge"
                mod={BADGE_MOD}
                text={TEXT}
                rightIcon={
                    (
                        status === STATUS_BANK_DEPOSIT.ERROR.VALUE &&
                        descriptionError !== null
                    ) &&
                    <HelpTooltip
                        info
                        type="light"
                        isTypeTooltipEqualTypeIcon={false}
                        width={16}
                        height={16}
                        classNameIcon="finance-bank__help-tooltip-icon"
                        position="bottom-left"
                    >
                        {descriptionError}
                    </HelpTooltip>
                }
            />
        );
    };

    const renderRequisites = (item) => {
        const {
            inn,
            bik,
            bankName,
            paymentAccount,
            clientInn,
            clientBik,
            clientBank,
            clientAccount,
        } = item;


        if (isTkbPage) {
            return [
                {
                    label: "ИНН",
                    text: clientInn,
                },
                {
                    label: "БИК",
                    text: clientBik,
                },
                {
                    label: "Банк",
                    text: clientBank,
                },
                {
                    label: "Счет",
                    text: clientAccount,
                },
            ];
        }

        return [
            {
                label: "ИНН",
                text: inn,
            },
            {
                label: "БИК",
                text: bik,
            },
            {
                label: "Банк",
                text: bankName,
            },
            {
                label: "Счет",
                text: paymentAccount,
            },
        ];
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const generalProps = {
                classNameMainContent: "col-16 col-xxl-11",
                secondaryHeaderRelative: true,
                secondaryHeaderStatus: renderStatus(item),
                cards: getRowCards(item),
            };

            const generalLabels = [
                ...renderClientInfo(item),
                ...renderRequisites(item),
            ];

            if (isTkbPage) {
                return {
                    ...item,
                    key: item.depositId,
                    contentRow: (
                        <NmListCard
                            {...generalProps}
                            primaryHeader={item.description}
                            labels={[
                                {
                                    label: "Дата в банке",
                                    text: dateFormat(item.operationDate, "dd.MM.yyyy HH:mm"),
                                },
                                {
                                    label: "Дата на площадке",
                                    text: dateFormat(item.createDatePlatform, "dd.MM.yyyy HH:mm"),
                                },
                                ...generalLabels,
                            ]}
                            cards={getRowCards(item)}
                        />
                    ),
                };
            }

            return {
                ...item,
                key: item.depositId,
                contentRow: (
                    <NmListCard
                        {...generalProps}
                        primaryHeader={item.reason}
                        labels={[
                            {
                                label: "Дата в банке",
                                text: dateFormat(item.transactionDate, "dd.MM.yyyy"),
                            },
                            {
                                label: "Дата на площадке",
                                text: dateFormat(item.createDatePlatform, "dd.MM.yyyy"),
                            },
                            ...generalLabels,
                        ]}
                        actionsClassName="col-1"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const cards = useMemo(() => {
        const {
            startBalance,
            endBalance,
            coming,
            consumption,
        } = data;

        return [
            {
                title: "Остаток на начало",
                value: typeof startBalance === "number" ?
                    formatNumberAmount(startBalance) :
                    "-",
                className: "finance-bank__amount-card",
            },
            {
                title: "Приход",
                value: typeof startBalance === "number" ?
                    formatNumberAmount(coming) :
                    "-",
                className: "finance-bank__amount-card",
            },
            {
                title: "Расход",
                value: typeof startBalance === "number"
                    ? formatNumberAmount(consumption) :
                    "-",
                className: "finance-bank__amount-card",
            },
            {
                title: "Остаток на конец",
                value: typeof startBalance === "number" ?
                    formatNumberAmount(endBalance) :
                    "-",
                className: "finance-bank__amount-card",
            },
        ];
    }, [data]);

    const getTabs = () => {
        return ([
            {
                name: "W1",
                active: subPage === FINANCE_BANK_SUB_PAGE.W1,
                onClick: () => {
                    setSubPage(FINANCE_BANK_SUB_PAGE.W1);
                },
            },
            {
                name: "ТКБ",
                active: subPage === FINANCE_BANK_SUB_PAGE.TKB,
                onClick: () => {
                    setSubPage(FINANCE_BANK_SUB_PAGE.TKB);
                },
            },
        ]);
    };

    if (currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessBank)) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    return (
        <NmPage
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            headerClassName="relative"
            subHeader={
                <>
                    <Tabs
                        className="mb-6"
                        panes={getTabs()}
                    />
                    <NmPageInfoCardsAccordion
                        bootstrap={false}
                        desktopViewFrom="xl"
                        cards={cards}
                    />
                </>
            }
            typeFilter="vertical"
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            filtersBase={
                <FinanceBankFilter
                    onChangeFilter={onChangeFilter}
                    onClear={onClear}
                    filter={filter}
                    sendFilter={() => {onSearch(filter);}}
                />
            }
            isAutoPositionForFilterButton={false}
            mediaControls={mediaControls}
            header={
                <NmPageHeader
                    text="Банк"
                />
            }
            totalCount={totalCount}
            isLoaded={progress}
        >
            {
                list.length ?
                    <CheckboxList
                        sort
                        sortOptions={sortOptions}
                        onClickSort={onClickSort}
                        rows={rows}
                    /> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default FinanceBank;
