import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";

import {FILTER} from "../../../components/ActualComponents/Filter";

import {useFilter} from "../../../hooks/useFilter";

const initFilter = {
    nameSubstringFilter: "",
    clientUserFioFilter: "",
    clientUserPhoneFilter: "",
    clientUserEmailFilter: "",
};

export const useClientListFilter = ({setPagination, pageSize}) => {
    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({});
    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState({});
    const {t} = useTranslation();

    const onSearch = (filter) => {
        if (isEmpty(filter)) {
            return;
        }

        setFilterData({...filter});
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClear = () => {
        setFilterData({});
        setIsSearch(false);
        setFilter(initFilter);
    };

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        autoComplete: "off",
                        label: t("client-filter.client-name"),
                        name: "nameSubstringFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "clientUserFioFilter",
                        label: t("client-filter.client-user"),
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        mask: "+7 (999) 999 9999",
                        maskChar: null,
                        label: t("client-filter.client-user-phone-number"),
                        name: "clientUserPhoneFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "clientUserEmailFilter",
                        label: t("client-filter.client-email"),
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "innFilter",
                        label: "ИНН",
                    },
                ],
            },
        ];
    }, []);

    return {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
        filtersBase,
    };
};