import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getByIdRecruitmentVacancy,
    updateRecruitmentVacancyStore,
} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";

export const useRecruitmentVacanciesCardFetch = (params) => {
    const {
        vacancyId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchCard();
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyStore({
                card: {},
            }));
        };
    }, []);

    const fetchCard = () => {
        dispatch(getByIdRecruitmentVacancy({
            vacancyId,
        }));
    };

    return {
        fetchCard,
    };
};