import {isEmpty} from "lodash";

import dateFormat from "../../../../../../utils/dateFormat";

export const getRecruitmentCandidateExperienceRequestData = (params) => {
    const {
        experiences,
        values,
        editData,
    } = params;

    const experience = formatRecruitmentCandidateExperience(values);

    if (isEmpty(editData)) {
        return [
            ...experiences,
            experience,
        ];
    }

    return experiences.map((item, index) => {
        if (index === editData.index) {
            return {
                ...experience,
                id: item.id,
            };
        }

        return item;
    });
};

export const formatRecruitmentCandidateExperience = (values) => {
    const {
        monthStart,
        yearStart,
        monthEnd,
        yearEnd,
        organisation,
        position,
    } = values;

    return {
        periodFrom: (yearStart || monthStart) ? dateFormat(new Date(yearStart, monthStart - 1), "yyyy-MM-dd") : undefined,
        periodTo: (yearEnd || monthEnd) ? dateFormat(new Date(yearEnd, monthEnd - 1), "yyyy-MM-dd") : undefined,
        organisation,
        position,
    };
};