import React, {Component} from "react";
import {connect} from "react-redux";

import NmModal from "../../../components/ActualComponents/NmModal";
import NmPagination from "../../../components/ActualComponents/NmPagination";
import NmTitle from "../../../components/NmTitle";

import {getFullName} from "../../../utils/stringFormat";

import {TYPE_INFO} from "../../../constants/promocode/dict";

import {
    getClientListByPromocodeSelector,
    getClientsByPromocode,
    getContractorsByPromocode,
    getPromocodeContractorListSelector, 
    promocodeClientTotalCountSelector,
    promocodeClientTotalPagesSelector, 
    promocodeContractorTotalCountSelector,
    promocodeContractorTotalPagesSelector,
    updateFieldPromocodeStore,
} from "../../../ducks/promocode";

import PropTypes from "prop-types";

import "./style.sass";

class PromoCodeContractorModal extends Component {
    static propTypes = {
        close: PropTypes.func,
        promocode: PropTypes.string,
    };

    static defaultProps = {
        contractorTotalPages: 1,
        clientTotalPages: 1,
        close: () => {
        },
    };

    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];

        this.state = {
            pageNum: 1,
            pageSize: this.pageSizes[0],
        };
    }

    styleRow = {
        marginTop: 4,
        fontSize: 14,
        display: "flex",
        justifyContent: "center",
    };

    componentDidMount() {
        this.fetchList();
    }

    fetchList() {
        const {
            getContractorsByPromocode,
            getClientsByPromocode,
            promocode,
            selectedTypeInfo,
        } = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        const reqData = {
            promocode,
            pageSize,
            pageNum,
            actualFilter: true,
        };

        if (selectedTypeInfo === TYPE_INFO.CONTRACTOR) {
            getContractorsByPromocode(reqData);
        } else {
            getClientsByPromocode(reqData);
        }
    }

    componentWillUnmount() {
        const {updateFieldPromocodeStore} = this.props;

        updateFieldPromocodeStore({
            clientList: [],
            contractorList: [],
        });
    }

    mapContractorsToTableData() {
        const {contractorList} = this.props;

        if (contractorList.length === 0) {
            return "Информация отсутствует";
        }

        return contractorList.filter(Boolean).map(({contractorId, lastName, firstName, patronymic, fullName}) => (
            <div
                key={contractorId}
                style={this.styleRow}
            >
                {lastName ? getFullName(lastName, firstName, patronymic): fullName}
            </div>
        ));
    }

    mapClientListToTableData() {
        const {clientList} = this.props;

        if (clientList.length === 0) {
            return "Информация отсутствует";
        }

        return clientList.map(({contractorId, name}) => (
            <div
                key={contractorId}
                style={this.styleRow}
            >
                {name}
            </div>
        ));
    }

    renderTitle(selectedTypeInfo) {
        if (selectedTypeInfo === TYPE_INFO.CONTRACTOR) {
            return "Исполнители, которые применили данный промо-код при регистрации в мобильном приложении";
        }

        return "Заказчики, которые применили данный промо-код";
    }

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;
        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            }, this.fetchList);
    };

    handleOnChangePageSize = pageSize => {
        const {
            selectedTypeInfo,
            contractorTotalCount, 
            clientTotalCount,
        } = this.props;

        const {
            pageSize: pageSizeFromState,
            pageNum,
        } = this.state;

        const totalCount = selectedTypeInfo === TYPE_INFO.CONTRACTOR ? contractorTotalCount : clientTotalCount;
        this.setState(
            {
                pageSize,
                pageNum: totalCount > pageSizeFromState ? 1 : pageNum,
            }, this.fetchList);
    };

    render() {
        const {
            close,
            selectedTypeInfo,
            contractorTotalPages, 
            clientTotalPages, 
            contractorTotalCount, 
            clientTotalCount,
        } = this.props;
        const {
            pageNum, 
            pageSize,
        } = this.state;

        return (
            <NmModal
                onClose={close}
                className="promo-code-contractor-modal"
                classNameContent="promo-code-contractor-modal__content"
                header={
                    <NmTitle size="lg">
                        {this.renderTitle(selectedTypeInfo)}
                    </NmTitle>
                }
            >
                {selectedTypeInfo === TYPE_INFO.CONTRACTOR ? this.mapContractorsToTableData() : this.mapClientListToTableData()}
                <NmPagination
                    className="promo-code-contractor-modal__pagination"
                    totalCount={selectedTypeInfo === TYPE_INFO.CONTRACTOR ? contractorTotalCount : clientTotalCount}
                    pageNum={pageNum}
                    totalPages={selectedTypeInfo === TYPE_INFO.CONTRACTOR ? contractorTotalPages : clientTotalPages}
                    pageSize={pageSize}
                    onChangePageSize={this.handleOnChangePageSize}
                    onChangePagination={this.handlePaginationChange}
                />
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        contractorList: getPromocodeContractorListSelector(state),
        clientList: getClientListByPromocodeSelector(state),
        contractorTotalPages: promocodeContractorTotalPagesSelector(state),
        clientTotalPages: promocodeClientTotalPagesSelector(state),
        contractorTotalCount: promocodeContractorTotalCountSelector(state),
        clientTotalCount: promocodeClientTotalCountSelector(state),
    }),
    {
        getContractorsByPromocode,
        getClientsByPromocode,
        updateFieldPromocodeStore,
    })(PromoCodeContractorModal);

