import * as yup from "yup";

import {getNumberFromFormattedAmount} from "../../../utils/stringFormat";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

const MIN_SUM_VALUE = 0.01;

export const getObjectChangeDepositValidation = () => {
    return yup.object().shape({
        amount: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("check-min-value", "Минимальное значение 0,01", function (value) {
                return getNumberFromFormattedAmount(value) >= MIN_SUM_VALUE;
            }),
    });
};