import React, {CSSProperties, FC, ReactNode} from "react";
import {isEmpty} from "lodash";

import HelpTooltip from "../ActualComponents/HelpTooltip";
import {TNmTooltipType} from "../ActualComponents/NmTooltip";
import Text from "../ActualComponents/Text";

import bem from "../../utils/bem";

import "./style.sass";

export type TNmTitleAlign = "left" | "center" | "right";

interface INmTitle {
    count?: number,
    size?: "md" | "lg" | "xl",
    status?: string,
    subTitle?: string,
    className?: string,
    children: ReactNode,
    isShowCount?: boolean,
    noWrapToLines?: boolean,
    align?: TNmTitleAlign,
    tooltipText?: string,
    tooltipType?: TNmTooltipType,
    isMobileSubTitle?: boolean,
    hidden?: boolean,
    style?: CSSProperties,
    noWrap?: boolean,
}

const NmTitle: FC<INmTitle> = (props) => {
    const {
        children,
        count,
        size = "xl",
        status,
        subTitle,
        className,
        isShowCount = true,
        noWrapToLines,
        align,
        tooltipText = "",
        tooltipType,
        isMobileSubTitle,
        hidden,
        style,
        noWrap,
    } = props;

    const [block, element] = bem("nm-title", className);

    return (
        <div
            className={block({align, hidden})}
            style={style}
        >
            <div className={element("container", {fluid: !isEmpty(subTitle), noWrap})}>
                <h2 className={element("value", {size, noWrapToLines, noWrap})}>
                    {children}
                </h2>
                {
                    typeof count === "number" && count !== 0 && isShowCount &&
                    <div className={element("count-wrapper")}>
                        <div className={element("count")}>
                            {count}
                        </div>
                    </div>
                }
                {
                    !isEmpty(status) &&
                    <div className={element("status-wrapper")}>
                        <div className={element("status")}>
                            {status}
                        </div>
                    </div>
                }
                {
                    tooltipText &&
                    <div className={element("tooltip")}>
                        <HelpTooltip
                            type={tooltipType}
                            position="bottom-left"
                            width={24}
                            height={24}
                            text={tooltipText}
                        />
                    </div>
                }
            </div>
            {
                !isEmpty(subTitle) &&
                <Text
                    level="2"
                    className={element("subtitle", {
                        mobile: isMobileSubTitle,
                    })}
                >
                    {subTitle}
                </Text>
            }
        </div>
    );
};

export default NmTitle;