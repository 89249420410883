import {useDispatch} from "react-redux";

import {useDebounceFetch} from "./useDebounceFetch";

export function useFetchDictionary({fetchCallback, pageNum = 1, pageSize = 100}) {
    const dispatch = useDispatch();

    const fetchDictionary = (valueFilter) => {
        const payload = {
            pageNum,
            pageSize,
            valueFilter,
        };
        dispatch(fetchCallback(payload));
    };

    const {setValueFilter, valueFilter} = useDebounceFetch({fetchCallback: fetchDictionary});

    return {
        setValueFilter,
        valueFilter,
    };
}