export function getListUniqueOptions(list = []) {
    return list.reduce((acc, item) => {
        if (acc.map[item.value])
        {return acc;}

        acc.map[item.value] = true;

        acc.resultItems.push(item);
        return acc;
    }, {
        map: {},
        resultItems: [],
    }).resultItems;
}

export function getArrayRange(start, stop, step) {
    return Array.from(
        {length: (stop - start) / step + 1},
        (value, index) => start + index * step,
    );
}