import React, {ChangeEvent, FC, useState} from "react";

import {ReactComponent as CheckIcon} from "../../../images/checkV2.svg";
import {ReactComponent as DeleteIcon} from "../../../images/close_24.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import NmButton from "../../NmButton";
import NmInputV2 from "../NmInputV2";

import bem from "../../../utils/bem";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import "./style.sass";

interface IStrParamEditor {
    name?: string,
    onChange: (event?: any, params?: any) => void
    params?: Array<string>
}

const StrParamEditor: FC<IStrParamEditor> = (props) => {
    const {
        onChange,
        params = [],
        name,
    } = props;
    const [block, element] = bem("str-param-editor");

    const [isAddParam, setIsAddParam] = useState(false);
    const [newParam, setNewParam] = useState("");
    const [editParam, setEditParam] = useState("");
    const [keyEditParam, setKeyEditParam] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onChangeNewParam = (e: ChangeEvent<HTMLInputElement>, {value}: {value: string}) => {
        setNewParam(value);
    };

    const onChangeEditParam = (e: ChangeEvent<HTMLInputElement>, {value}: {value: string}) => {
        setEditParam(value);
    };

    function renderItem(param: string) {
        return (
            <>
                <div
                    title={param}
                    className={element("param")}
                >
                    {param}
                </div>
                <div className={element("icons")}>
                    <ModeIcon
                        className={element("icon", {ml: true, edit: true})}
                        onClick={() => {
                            setErrorMessage("");
                            setKeyEditParam(param);
                            setEditParam(param);
                        }}
                    />
                    <DeleteIcon
                        onClick={() => {
                            deleteParam(param);
                        }}
                        className={element("icon", {ml: true, del: true})}
                    />
                </div>
            </>
        );
    }

    function renderEditItem(param: string) {
        return (
            <>
                <NmInputV2
                    size="lg"
                    error={errorMessage}
                    label={`Редактирование параметра "${param}"`}
                    value={editParam}
                    name="editParam"
                    onChange={onChangeEditParam}
                />
                <div className={element("icons")}>
                    <div className={element("icon-container")}>
                        <CheckIcon
                            className={element("icon", {confirm: true})}
                            onClick={() => editNewParam(param)}
                        />
                    </div>
                    <div className={element("icon-container")}>
                        <DeleteIcon
                            onClick={() => {
                                setKeyEditParam("");
                                setEditParam("");
                            }}
                            className={element("icon", {del: true})}
                        />
                    </div>
                </div>
            </>
        );
    }

    function mapParams() {
        const _params = params.map(item => {
            return (
                <div
                    key={item}
                    className={element("row")}
                >
                    {keyEditParam && keyEditParam === item ? renderEditItem(item) : renderItem(item)}
                </div>
            );
        });

        _params.unshift(renderNewElement());

        return _params;
    }

    const addNewParam = () => {
        setErrorMessage("");

        if(isNullOrWhitespace(newParam)){
            setErrorMessage("Заполните значение");
            return;
        }

        if(params.includes(newParam.trim())){
            setErrorMessage("Значение уже существует");
            return;
        }

        if (onChange) {
            onChange(null, {name, value: [...params, newParam.trim()]});
        }

        setIsAddParam(false);
        setNewParam("");
    };

    const editNewParam = (oldItem: string) => {
        setErrorMessage("");

        if(isNullOrWhitespace(editParam)){
            setErrorMessage("Заполните значение");
            return;
        }

        if(params.includes(editParam.trim())){
            setErrorMessage("Значение уже существует");
            return;
        }

        const _params = params.map(p => p === oldItem ? editParam.trim() : p);

        if (onChange) {
            onChange(null, {name, value: _params});
        }

        setKeyEditParam("");
    };

    const deleteParam = (param: string) => {
        const _params = params.filter(item => item !== param);

        if (onChange) {
            onChange(null, {name, value: _params});
        }
    };

    function renderNewElement() {
        return (
            <div
                key="new"
                className={element("row-new")}
            >
                {
                    isAddParam &&
                    <>
                        <NmInputV2
                            size="lg"
                            label="Новый параметр"
                            value={newParam}
                            name="newParam"
                            onChange={onChangeNewParam}
                            error={errorMessage}
                        />
                        <div className={element("icon-container", {ml: true})}>
                            <CheckIcon
                                className={element("icon", {confirm: true})}
                                onClick={addNewParam}
                            />
                        </div>
                    </>
                }
                {
                    !isAddParam &&
                    <NmButton
                        className={element("add")}
                        color="white"
                        onClick={() => {
                            setErrorMessage("");
                            setIsAddParam(true);
                        }}
                    >
                        Добавить новое значение
                    </NmButton>
                }
            </div>
        );
    }

    return (
        <div className={block()}>
            {mapParams()}
        </div>
    );
};

export default StrParamEditor;