import React, {useState} from "react";
import {isEmpty} from "lodash";

import JobBoardLogo from "../../../components/JobBoardLogo";
import {ChevronIcon} from "../../ActualComponents/ChevronIcon";
import NmLabelText from "../../ActualComponents/NmLabelText";
import Text from "../../ActualComponents/Text";
import ViewParsedHtmlTextButton from "../../ViewParsedHtmlTextButton";
import RecruitersWithAccessModal from "../RecruitersWithAccessModal";

import {getClassNames} from "../../../utils/classNames";
import {getDatePeriodLabelText} from "../../../utils/dateFormat";
import {getSalaryText} from "../../../utils/stringFormat";

import {COLOR} from "../../../constants/color";

export const RecruitmentVacancyDetails = (props) => {
    const {
        vacancy,
        className,
        clientId,
    } = props;

    const [isVisible, setVisible] = useState(false);

    const renderJobBoards = ({jobBoards}) => {
        if (isEmpty(jobBoards)) {
            return "-";
        }

        return (
            <div className="flex flex-aligned-center">
                {
                    jobBoards.map(item => {
                        return (
                            <JobBoardLogo
                                className="me-2"
                                name={item}
                                width={18}
                                height={18}
                            />
                        );
                    })
                }
            </div>
        );
    };

    return (
        <div className={className}>
            <div
                onClick={() => {
                    setVisible(!isVisible);
                }}
                style={{cursor: "pointer"}}
                className={getClassNames([
                    "d-flex align-items-center",
                    isVisible && "mb-6",
                ])}
            >
                <Text
                    color={COLOR.PASSIVE_100}
                    level="3"
                >
                    Детали вакансии
                </Text>
                <ChevronIcon
                    width={20}
                    height={20}
                    color={COLOR.PASSIVE_100}
                    rotate={isVisible ? 0 : 180}
                    className="ms-1"
                />
            </div>
            {
                isVisible &&
                <div className="row gx-10">
                    <div className="col-16 col-xxl-5">
                        {
                            [
                                {
                                    label: "Право доступа",
                                    text: (
                                        <RecruitersWithAccessModal
                                            modalTitle="Рекрутеры, имеющие доступ"
                                            textLevel="3"
                                            vacancy={vacancy}
                                            clientId={clientId}
                                        />
                                    ),
                                    className: "mb-2",
                                },
                                {
                                    label: "Описание вакансии",
                                    text: vacancy.description ?
                                        <ViewParsedHtmlTextButton
                                            textLevel="3"
                                            children={vacancy.description}
                                        /> :
                                        "-",
                                    className: "mb-2",
                                },
                                {
                                    label: "Заказчик",
                                    text: vacancy.clientName || "-",
                                    className: "mb-2",
                                },
                                {
                                    label: "Вид деятельности",
                                    text: vacancy.specialityName || "-",
                                    className: "mb-2",
                                },
                                {
                                    label: "Период размещения",
                                    text: getDatePeriodLabelText(vacancy.publishDateFrom, vacancy.publishDateTo),
                                    noWrap: false,
                                    className: "mb-2",
                                },
                            ].map(item => {
                                return (
                                    <NmLabelText
                                        type="page"
                                        label={item.label}
                                        text={item.text}
                                        className={item.className}
                                    />
                                );
                            })
                        }
                    </div>
                    <div className="col-16 col-xxl-5">
                        {
                            [
                                {
                                    label: "Зарплата, ₽",
                                    text: getSalaryText(vacancy, "-"),
                                    className: "mb-2",
                                },
                                {
                                    label: "Город",
                                    text: vacancy.workAddressInfo || "-",
                                    className: "mb-2",
                                },
                                {
                                    label: "Рекрутер",
                                    text: vacancy.recruiterName || "-",
                                    className: "mb-2",
                                },
                                {
                                    label: "Джоб-борд",
                                    text: renderJobBoards(vacancy),
                                    className: "align-items-center",
                                },
                            ].map(item => {
                                return (
                                    <NmLabelText
                                        type="page"
                                        label={item.label}
                                        text={item.text}
                                        className={item.className}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
};