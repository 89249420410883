import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {nanoid} from "nanoid";

import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmDatePicker from "../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../components/ActualComponents/NmForm";
import NmModal from "../../components/ActualComponents/NmModal";
import NmButton from "../../components/NmButton";
import NmTitle from "../../components/NmTitle";
import PhotoReport from "../../components/PhotoReport";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../images/close_24.svg";
import {ReactComponent as ConfirmIcon} from "../../images/done_24.svg";
import {ReactComponent as EditIcon} from "../../images/mode.svg";
import TimeSheetNewModal from "./time-sheet-new-modal";

import bem from "../../utils/bem";
import dateFormat, {
    convertMinutesToHours,
    convertUtcToLocal,
    getBeginningWeekAndEndWeekByCurrentDate,
    getChecksDatepickerMinTime,
    getEndDate,
    getStartDate,
    today,
} from "../../utils/dateFormat";
import {
    CURRENT_CLIENT_ID,
    CURRENT_CLIENT_USER_ID,
    ls,
    USER_ROLE,
} from "../../utils/localstorage";
import {
    getFormattedFullName,
    getFullName,
} from "../../utils/stringFormat";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import {ORDER_WORK} from "../../constants/messages";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../constants/roles";
import {STATUS} from "../../constants/status";

import {clientCurrentMemberSelector} from "../../ducks/clientMember";
import {
    contractorProgressApplicationsSelector,
    contractorsApplicationsOptionsSelector,
    contractorsTimeSheetSelector,
    getContractorsByOrder,
    updateFieldContractorStore,
} from "../../ducks/contractor";
import {
    addWorkLog,
    approveExpiredWorkLog,
    deleteWorkLog,
    getWorkLog,
    updateFieldWorkLog,
    workLogIncludingDataSelector,
    workLogListSelector,
    workLogProgressExpiredSelector,
    workLogProgressListSelector,
} from "../../ducks/contractorWorkLog";
import {orderCardSelector} from "../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

import {SUB_PAGE_ORDER_CONTRACTOR} from "../../constants/link-params";

class TimeSheet extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        clientId: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        contractorId: PropTypes.string.isRequired,
        contractorStatus: PropTypes.string,
        checkInRequired: PropTypes.bool,
        checkOutRequired: PropTypes.bool,
        isOpenOrder: PropTypes.bool,
        needFetchContractors: PropTypes.bool,
    };

    static defaultProps = {
        needFetchContractors: true,
    };

    constructor(props) {
        super(props);

        const {
            monday,
            sunday,
        } = getBeginningWeekAndEndWeekByCurrentDate();
        const {
            contractorId,
            contractorStatus,
            order: {
                workStartDate,
                workEndDate,
            },
        } = props;

        const _workStartDate = getStartDate(workStartDate);
        const _workEndDate = getEndDate(workEndDate);

        this.state = {
            contractorId,
            contractorStatus,
            startDateFilter: monday > _workEndDate ? _workStartDate : monday,
            endDateFilter: sunday > _workEndDate ? _workEndDate : sunday,
            workLog: {},
            isOpenPhotoReport: false,
            dataPhotoReport: {},
            isOpenFormWorkLog: false,
            includingDataForm: {},
        };

        const [block, element] = bem("time-sheet");

        this.block = block;
        this.element = element;

        this.role = ls(USER_ROLE);
        this.isAccess = ![NM_COORDINATOR, NM_OPERATOR].includes(this.role);
        this.currentUserId = ls(CURRENT_CLIENT_USER_ID);
        this.currentClientId = ls(CURRENT_CLIENT_ID);
    }

    componentDidMount() {
        const {needFetchContractors} = this.props;

        if (needFetchContractors) {
            this.fetchContractors();
        }

        this.fetchList();
    }

    componentWillUnmount() {
        const {updateFieldContractorStore, updateFieldWorkLog} = this.props;

        updateFieldWorkLog({workLog: []});
        updateFieldContractorStore({timeSheetContractors: []});
    }

    fetchContractors = () => {
        const {
            getContractorsByOrder,
            orderId,
            clientId,
        } = this.props;

        getContractorsByOrder({
            clientId,
            orderId,
            tabType: SUB_PAGE_ORDER_CONTRACTOR.HIRED.TAB_TYPE,
            pageNum: 1,
            pageSize: 100,
            nameField: "timeSheetContractors",
        });
    };

    fetchList = () => {
        const {getWorkLog, clientId, orderId} = this.props;
        const {startDateFilter, endDateFilter, contractorId} = this.state;

        getWorkLog({
            clientId,
            orderId: orderId,
            contractorId: contractorId,
            pageNum: 1,
            pageSize: 100,
            startDateFilter: isNullOrWhitespace(startDateFilter) ? "" : getStartDate(new Date(startDateFilter)),
            endDateFilter: isNullOrWhitespace(endDateFilter) ? "" : getEndDate(new Date(endDateFilter)),
        });
    };

    handleOnChangeFilter = (event, {value, name}) => {
        if (name === "contractorId") {
            const {contractors} = this.props;

            const contractorInfo = contractors.find(item => (item.contractorId === value)) || {};

            const {contractorStatus, contractorId} = contractorInfo;

            this.setState({
                [name]: value,
                contractorStatus,
                contractorId,
            }, this.fetchList);

            return;
        }

        this.setState({
            [name]: value,
        }, this.fetchList);
    };

    deleteWorkLog = (contractorWorkLogId) => {
        const {deleteWorkLog, clientId, orderId} = this.props;
        const {contractorId} = this.state;

        const requestData = {
            contractorWorkLogId, clientId, orderId, contractorId,
        };

        deleteWorkLog(requestData);

        this.cancelConfirmDeleteWindow();
    };

    saveWorkLog = () => {
        const {
            addWorkLog,
            currentMember: {
                lastName,
                firstName,
                patronymic,
            },
            clientId,
            orderId,
        } = this.props;
        const {
            workLog: {
                type,
                localDatetime,
            },
            contractorId,
        } = this.state;

        // Если на форме не выбрано время
        if (!localDatetime) {
            return;
        }

        const data = {
            regClientUserId: this.currentUserId,
            regClientUserClientId: this.currentClientId,
            regClientUserName: getFormattedFullName(getFullName(lastName, firstName, patronymic)),
            clientId,
            contractorId,
            type,
            localDatetime: convertUtcToLocal(localDatetime).toJSON(),
            orderId,
        };

        addWorkLog(data);

        this.clearWorkLog();
    };

    clearWorkLog = () => {
        this.setState({workLog: {}});
    };

    openFormWorkLog = (event, data = {}) => {
        const {currentMember: {canEditContractorWorkLog}} = this.props;

        if ([CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !canEditContractorWorkLog) {
            return;
        }

        this.setState({
            isOpenFormWorkLog: true,
            includingDataForm: {...data},
        });
    };

    closeFormWorkLog = () => {
        this.setState({
            isOpenFormWorkLog: false,
        });
    };

    showConfirmDeleteWindow = (e, editWorkLogId) => {
        const {currentMember: {canEditContractorWorkLog}} = this.props;

        if ([CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !canEditContractorWorkLog) {
            return;
        }

        e.preventDefault();
        this.setState({openConfirmDeleteWindow: true, editWorkLogId});
    };

    cancelConfirmDeleteWindow = () => {
        this.setState({openConfirmDeleteWindow: false, editWorkLogId: null});
    };

    renderConfirmDeleteWindow() {
        const {
            openConfirmDeleteWindow,
            editWorkLogId,
        } = this.state;
        const {t} = this.props;

        return openConfirmDeleteWindow &&
            <NmConfirmV2
                content={ORDER_WORK.ORDER_WORK_DELETE_RANGE_TEXT}
                onCancel={this.cancelConfirmDeleteWindow}
                onConfirm={() => {
                    this.deleteWorkLog(editWorkLogId);
                }}
                cancelButton={t("button.no")}
                confirmButton={t("button.yes")}
            />;
    }

    handleOnChangeLocalDatetime = (event, {value: localDatetime}) => {
        this.setState(prevState => {
            const {workLog} = prevState;
            const {contractorWorkLogId, type} = workLog;

            if (type === "OUT") {
                const {list} = this.props;
                const needWorkLog = list.find(item => (item.contractorWorkLogId === contractorWorkLogId)) || {};
                const {inLocalDatetime} = needWorkLog;
                const copyInDate = new Date(inLocalDatetime);
                // При подтверждении ухода, при клике сразу на время выбирается текущая дата, которая может быть меньше
                // чем minDate(в случае type === "OUT" задается из inLocalDatetime) передаваемая в датапикер
                // если дата не проходит по условию выставляем минимальную дату + выбранное время
                const valueTime = dateFormat(localDatetime, "HH:mm").split(":");
                const _localDatetime = getStartDate(localDatetime) < getStartDate(inLocalDatetime) ?
                    copyInDate.setHours(valueTime[0], valueTime[1], 0) :
                    localDatetime;

                return {
                    ...prevState,
                    workLog: {
                        ...prevState.workLog,
                        // При подтверждении выхода, при выборе даты меньшей чем в чекине,
                        // при условии что существует дата чекина,
                        // устанавливается дата такая же как в чекине + 1мс, для избежания ошибки;
                        localDatetime: new Date(_localDatetime).getTime() <= copyInDate.getTime() && inLocalDatetime ?
                            new Date(copyInDate.setMilliseconds(copyInDate.getMilliseconds() + 1)) : localDatetime,
                    },
                };
            }

            return {
                ...prevState,
                workLog: {
                    ...prevState.workLog,
                    localDatetime,
                },
            };
        });
    };

    get minTime() {
        const {list} = this.props;
        const {workLog} = this.state;

        const {contractorWorkLogId, localDatetime: outLocalDatetime} = workLog;

        const needWorkLog = list.find(item => (item.contractorWorkLogId === contractorWorkLogId));
        const {inLocalDatetime} = needWorkLog;

        return getChecksDatepickerMinTime(inLocalDatetime, outLocalDatetime);
    }

    openConfirmExpired = (confirmData) => {
        const {
            currentMember: {canEditContractorWorkLog},
        } = this.props;

        if (
            [CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && canEditContractorWorkLog ||
            [ADMIN, CLIENT_ADMIN, NM_MANAGER, NM_COORDINATOR].includes(this.role)
        ) {
            this.setState(prevState => ({
                ...prevState,
                confirmData: confirmData ? confirmData : {},
                isOpenExpiredConfirm: !prevState.isOpenExpiredConfirm,
            }));
        }
    };

    renderConfirmInBlock(item = {}) {
        const {expired, expirationApproved} = item;
        const {
            workLog: {localDatetime},
        } = this.state;

        const {
            list,
            checkInRequired,
            checkOutRequired,
            isOpenOrder,
            t,
        } = this.props;

        const [firstWorkLog = {}] = list;

        let minDate = new Date();

        if (checkOutRequired && checkInRequired && firstWorkLog.outLocalDatetime) {
            minDate = new Date(firstWorkLog.outLocalDatetime);
        }

        if (checkInRequired && !checkOutRequired && firstWorkLog.inLocalDatetime) {
            minDate = new Date(firstWorkLog.inLocalDatetime);
        }

        return (
            <div
                key={nanoid(3)}
                className={this.element("row", {expired: expired && !expirationApproved})}
            >
                <NmDatePicker
                    size="lg"
                    showTimeSelect
                    timeCaption={t("time-sheet.time")}
                    timeIntervals={1}
                    minDate={minDate}
                    placeholderText={t("time-sheet.date-time")}
                    dateFormat="dd.MM.yy HH:mm"
                    dateFormatMask="99.99.99 99:99"
                    name="localDatetime"
                    selected={localDatetime}
                    onChange={this.handleOnChangeLocalDatetime}
                />
                <div className="time-sheet__column-work-hours flex flex-vertical-center">
                    <NmButton
                        size="lg"
                        onClick={this.saveWorkLog}
                    >
                        {t("button.save")}
                    </NmButton>
                    <DeleteIcon
                        className="time-sheet__close"
                        onClick={this.clearWorkLog}
                    />
                    {
                        isOpenOrder && ![NM_CHIEF_ACCOUNTANT].includes(this.role) && expired && !expirationApproved &&
                        <ConfirmIcon
                            className="time-sheet__confirm"
                            onClick={() => {this.openConfirmExpired(item);}}
                        />
                    }
                </div>
            </div>
        );
    }

    renderConfirmOutBlock(item) {
        const {
            inLocalDatetime,
            expired,
            expirationApproved,
        } = item;
        const {
            workLog: {
                localDatetime,
            },
        } = this.state;
        const {t, isOpenOrder} = this.props;

        return (
            <div
                key={nanoid(3)}
                className={this.element("row", {expired: expired && !expirationApproved})}
            >
                <NmDatePicker
                    size="lg"
                    className="time-sheet-date"
                    showTimeSelect
                    timeCaption={t("time-sheet.time")}
                    timeIntervals={1}
                    minDate={new Date(inLocalDatetime)}
                    minTime={this.minTime}
                    maxTime={today.iso.end()}
                    placeholderText={t("time-sheet.date-time")}
                    dateFormat="dd.MM.yy HH:mm"
                    dateFormatMask="99.99.99 99:99"
                    name="localDatetime"
                    selected={localDatetime}
                    onChange={this.handleOnChangeLocalDatetime}
                />
                <div className="time-sheet__column-work-hours flex flex-vertical-center">
                    <NmButton
                        size="lg"
                        className="time-sheet-btn-filled"
                        onClick={this.saveWorkLog}
                    >
                        {t("button.save")}
                    </NmButton>
                    <DeleteIcon
                        className="time-sheet__close"
                        onClick={this.clearWorkLog}
                    />
                    {
                        isOpenOrder && ![NM_CHIEF_ACCOUNTANT].includes(this.role) && expired && !expirationApproved &&
                        <ConfirmIcon
                            className="time-sheet__confirm"
                            onClick={() => {this.openConfirmExpired(item);}}
                        />
                    }
                </div>
            </div>
        );
    }

    openPhotoReport = (dataPhotoReport) => {
        this.setState({
            isOpenPhotoReport: true,
            dataPhotoReport,
        });
    };

    closePhotoReport = () => {
        this.setState({
            isOpenPhotoReport: false,
            dataPhotoReport: {},
        });
    };

    openConfirmationWindow(workLog) {
        this.setState({
            workLog,
        });
    }

    renderEmpty() {
        const {contractorStatus} = this.state;
        const {
            checkOutRequired,
            checkInRequired,
            isOpenOrder,
            currentMember: {canEditContractorWorkLog},
            t,
        } = this.props;

        return (
            <div className={this.element("row")}>
                {checkInRequired ?
                    <div className="time-sheet__column-work-start">
-
                    </div> : null}
                {checkOutRequired ?
                    <div className="time-sheet__column-work-end">
-
                    </div> : null}
                <div className="time-sheet__column-work-hours flex-vertical-center">
                    {
                        this.isAccess && isOpenOrder && ![STATUS.BLOCKED, STATUS.COMPLETED].includes(contractorStatus) &&
                        !([CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, NM_CHIEF_ACCOUNTANT].includes(this.role) && !canEditContractorWorkLog) &&
                        <NmButton
                            size="lg"
                            color="light-green"
                            className="time-sheet__button"
                            onClick={() => {
                                this.openConfirmationWindow({
                                    contractorWorkLogId: null,
                                    type: checkOutRequired && !checkInRequired ? "OUT" : "IN",
                                });
                            }}
                        >
                            {checkOutRequired && !checkInRequired ? t("time-sheet.confirm-out-button") : t("time-sheet.confirm-in-button")}
                        </NmButton>
                    }
                </div>
            </div>
        );
    }

    renderOnlyCheckIn() {
        const {
            list,
            checkOutRequired,
            checkInRequired,
        } = this.props;
        const {
            workLog: {
                contractorWorkLogId,
            },
        } = this.state;

        if (contractorWorkLogId === null) {
            return this.renderConfirmInBlock();
        }

        if (!list.length) {
            return this.renderEmpty();
        }

        if (!checkOutRequired && list[0].inLocalDatetime) {
            return this.renderEmpty();
        }


        if (!checkInRequired && list[0].outLocalDatetime) {
            return this.renderEmpty();
        }


        if (!list[0].outLocalDatetime) {
            return null;
        }

        return this.renderEmpty();
    }

    renderListOnlyCheckOut(item, fio) {
        const {expired, expirationApproved} = item;

        return (
            <div
                key={nanoid(4)}
                className={this.element("row", {expired: expired && !expirationApproved})}
            >
                <div className="time-sheet__column-work-start">
                    {this.renderCheckOut(item, fio)}
                </div>
                <div className="time-sheet__column-work-hours flex flex-vertical-center flex flex-content-spaced">
                    <div />
                    {this.renderEditBlock(item)}
                </div>
            </div>
        );
    }

    renderCheckIn = (item, fio) => {
        const {t} = this.props;
        const {
            inLocalDatetime,
            inRegClientUserName,
            contractorId,
            inPhotoInfo,
            inLongitude,
            inLatitude,
            inDistanceToObject,
            inRegClientUserClientId,
        } = item;

        return inLocalDatetime ? (
            <>
                <div className="time-sheet__time">
                    {dateFormat(inLocalDatetime, "dd.MM HH:mm")}
                </div>
                <div className="time-sheet__column-work-approve">
                    <div>
                        {t("time-sheet.confirm-in")}
                    </div>
                    <div className="time-sheet__confirm-fio">
                        {this.renderFio(inRegClientUserName, contractorId)}
                    </div>
                </div>
                <div
                    className="time-sheet__info"
                    onClick={() => {
                        this.openPhotoReport({
                            link: inPhotoInfo,
                            fio,
                            longitude: inLongitude,
                            latitude: inLatitude,
                            distanceToObject: inDistanceToObject,
                        });
                    }}
                >
                    {inPhotoInfo ? <i className="material-icons">
panorama
                    </i> : null}
                    {
                        !inRegClientUserClientId &&
                        <>
                            <i className="material-icons">
room
                            </i>
                            <div className="time-sheet__distance">
                                до объекта
                                {" "}
                                {inDistanceToObject || 0}
м
                            </div>
                        </>
                    }
                </div>

            </>
        ) : (
            "-"
        );
    };

    renderListOnlyCheckIn(item, fio) {
        const {expired, expirationApproved} = item;

        return (
            <div
                key={nanoid(3)}
                className={this.element("row", {expired: expired && !expirationApproved})}
            >
                <div className="time-sheet__column-work-start">
                    {this.renderCheckIn(item, fio)}
                </div>
                <div className="time-sheet__column-work-hours flex flex-vertical-center flex flex-content-spaced">
                    <div />
                    {this.renderEditBlock(item)}
                </div>
            </div>
        );
    }

    renderEditBlock(item) {
        const {expired, expirationApproved} = item;
        const {
            isOpenOrder,
            currentMember: {
                canEditContractorWorkLog,
            },
        } = this.props;
        const {contractorStatus} = this.state;

        if (
            ![FOREMAN, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT, ADMIN, NM_MANAGER].includes(this.role) ||
            contractorStatus === STATUS.COMPLETED ||
            contractorStatus === STATUS.BLOCKED ||
            ([CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !canEditContractorWorkLog)
        ) {
            return null;
        }

        return (
            <div>
                <EditIcon
                    className="time-sheet__edit"
                    onClick={(event) => {
                        this.openFormWorkLog(event, {
                            contractorWorkLogId: item.contractorWorkLogId,
                            inLocalDatetime: item.inLocalDatetime,
                            outLocalDatetime: item.outLocalDatetime,
                        });
                    }}
                />
                <DeleteIcon
                    className="time-sheet__delete"
                    onClick={(e) => {
                        this.showConfirmDeleteWindow(e, item.contractorWorkLogId);
                    }}
                />
                {
                    isOpenOrder &&![NM_CHIEF_ACCOUNTANT].includes(this.role) && expired && !expirationApproved &&
                    <ConfirmIcon
                        className="time-sheet__confirm"
                        onClick={() => {this.openConfirmExpired(item);}}
                    />
                }
            </div>
        );
    }

    renderFio(userName, contractorId) {
        const {options} = this.props;

        if (userName) {
            return userName;
        }

        if (!contractorId) {
            return "-";
        }

        const contractor = options.find(op => op.value === contractorId) || {};
        const {text} = contractor;

        if (!text) {
            return "";
        }

        return getFormattedFullName(text);
    }

    renderCheckOut = (item, fio) => {
        const {t} = this.props;
        const {
            outDistanceToObject,
            outRegClientUserName,
            contractorId,
            outLongitude,
            outLatitude,
            outPhotoInfo,
            outLocalDatetime,
            outRegClientUserClientId,
        } = item;

        return (
            <>
                <div className="time-sheet__time">
                    {dateFormat(outLocalDatetime, "dd.MM HH:mm")}
                </div>
                <div className="time-sheet__column-work-approve">
                    <div>
                        {t("time-sheet.confirm-out")}
                    </div>
                    <div>
                        {this.renderFio(outRegClientUserName, contractorId)}
                    </div>
                </div>
                <div
                    className="time-sheet__info"
                    onClick={() => {
                        this.openPhotoReport({
                            link: outPhotoInfo,
                            fio,
                            longitude: outLongitude,
                            latitude: outLatitude,
                            distanceToObject: outDistanceToObject,
                        });
                    }}
                >
                    {outPhotoInfo ? <i className="material-icons">
panorama
                    </i> : null}
                    {
                        !outRegClientUserClientId &&
                        <>
                            <i className="material-icons">
room
                            </i>
                            <div className="time-sheet__distance">
                                до объекта
                                {" "}
                                {outDistanceToObject || 0}
м
                            </div>
                        </>
                    }
                </div>
            </>
        );
    };

    renderList() {
        const {
            list,
            options,
            checkInRequired,
            checkOutRequired,
            isOpenOrder,
            currentMember: {canEditContractorWorkLog},
            t,
        } = this.props;
        const {
            workLog: {type, contractorWorkLogId: contractorWorkLogIdState},
            contractorStatus,
        } = this.state;

        return list.map(item => {
            const {expired, expirationApproved} = item;
            const contractor = options.find(op => op.value === item.contractorId) || {};
            const {text: fio} = contractor;

            if (contractorWorkLogIdState === item.contractorWorkLogId && type === "IN") {
                return this.renderConfirmInBlock(item);
            }

            if (contractorWorkLogIdState === item.contractorWorkLogId && type === "OUT") {
                return this.renderConfirmOutBlock(item);
            }

            if (checkInRequired && !checkOutRequired) {
                return this.renderListOnlyCheckIn(item, fio);
            }

            if (!checkInRequired && checkOutRequired) {
                return this.renderListOnlyCheckOut(item, fio);
            }

            return (
                <div
                    key={nanoid(3)}
                    className={this.element("row", {expired: expired && !expirationApproved})}
                >
                    <div className="time-sheet__column-work-start">
                        {this.renderCheckIn(item, fio)}
                    </div>
                    <div className="time-sheet__column-work-end">
                        {item.outLocalDatetime ? this.renderCheckOut(item, fio) : "-"}
                    </div>
                    {item.outLocalDatetime ? (
                        <div className="time-sheet__column-work-hours flex flex-vertical-center flex-content-spaced">
                            <div>
                                {isNullOrWhitespace(item.workTimeMinutes) ? 0 : convertMinutesToHours(item.workTimeMinutes)}
                            </div>
                            {this.renderEditBlock(item)}
                        </div>
                    ) :
                        this.isAccess && isOpenOrder && contractorStatus !== STATUS.COMPLETED ||
                        (this.isAccess && isOpenOrder && contractorStatus === STATUS.BLOCKED && list.length !== 0 && list[0].checkInRequired) ?
                            <div className="time-sheet__column-work-hours flex flex-vertical-center">
                                {
                                    !(
                                        [CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, NM_CHIEF_ACCOUNTANT].includes(this.role) &&
                                        !canEditContractorWorkLog
                                    ) &&
                                    <NmButton
                                        size="lg"
                                        color="light-green"
                                        className="time-sheet__button"
                                        onClick={() => {
                                            this.openConfirmationWindow({
                                                contractorWorkLogId: item.contractorWorkLogId,
                                                type: "OUT",
                                            });
                                        }}
                                    >
                                        {t("time-sheet.confirm-out-button")}
                                    </NmButton>
                                }
                                {
                                    isOpenOrder && ![NM_CHIEF_ACCOUNTANT].includes(this.role) && expired && !expirationApproved &&
                                    <ConfirmIcon
                                        className="time-sheet__confirm"
                                        onClick={() => {this.openConfirmExpired(item);}}
                                    />
                                }
                            </div> :
                            null
                    }
                </div>
            );},
        );
    }

    renderHeader() {
        const {
            options,
            checkOutRequired,
            checkInRequired,
            includingData: {
                totalMinutes,
            },
            progressWorkLog,
            progressContractors,
            t,
        } = this.props;
        const {
            contractorId,
            startDateFilter,
            endDateFilter,
        } = this.state;

        return (
            <div className="time-sheet__header">
                <div className="time-sheet__fio time-sheet-control">
                    <NmDropdownV2
                        label={t("time-sheet.full-name")}
                        disabled={progressContractors}
                        size="xl"
                        options={options}
                        value={contractorId}
                        name="contractorId"
                        onChange={this.handleOnChangeFilter}
                    />
                </div>
                <NmDateRangePickerV2
                    size="xl"
                    disabled={progressWorkLog || progressContractors}
                    label={t("time-sheet.period")}
                    onChange={this.handleOnChangeFilter}
                    className="time-sheet__period"
                    startFieldName="startDateFilter"
                    endFieldName="endDateFilter"
                    dateFormat="dd.MM.yy"
                    value={{
                        startDateFilter,
                        endDateFilter,
                    }}
                    isCurrentDateMax
                />
                {checkInRequired && checkOutRequired ?
                    <div className="time-sheet__time-total">
                        <div className="time-sheet__time-total-label">
                            {t("time-sheet.order-time")}
                        </div>
                        <div className="time-sheet__time-total-count">
                            {isNullOrWhitespace(totalMinutes) ? "0 ч" : convertMinutesToHours(totalMinutes)}
                        </div>
                    </div> :
                    null}
            </div>
        );
    }

    renderAddTimeSheetRecord(t) {
        const {
            isOpenOrder,
            currentMember: {
                canEditContractorWorkLog,
            },
        } = this.props;
        const {contractorStatus} = this.state;

        return ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role) && isOpenOrder && ![STATUS.BLOCKED, STATUS.COMPLETED].includes(contractorStatus) ?
            <NmButton
                size="lg"
                disabled={[CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !canEditContractorWorkLog}
                onClick={this.openFormWorkLog}
                icon={
                    <AddIcon />
                }
            >
                {t("time-sheet.add-note")}
            </NmButton> :
            null;
    }

    confirmExpired = () => {
        const {approveExpiredWorkLog} = this.props;
        const {confirmData} = this.state;

        approveExpiredWorkLog({
            ...confirmData,
            onSuccess: () => {
                this.openConfirmExpired();
                this.fetchList();
            },
        });
    };

    renderConfirm = () => {
        const {progressExpired} = this.props;
        const {isOpenExpiredConfirm} = this.state;

        return isOpenExpiredConfirm &&
            <NmConfirmV2
                disabled={progressExpired}
                isNeedClosing={false}
                onCancel={this.openConfirmExpired}
                onConfirm={this.confirmExpired}
                content="Вы уверены, что хотите согласовать время выполнения заказа?"
                confirmButton="Продолжить"
                cancelButton="Отменить"
            />;
    };

    render() {
        const {
            onClose,
            checkOutRequired,
            checkInRequired,
            clientId,
            orderId,
            includingData: {
                objectLongitude,
                objectLatitude,
            },
            progressWorkLog,
            progressContractors,
            t,
        } = this.props;
        const {
            contractorId,
            isOpenPhotoReport,
            dataPhotoReport,
            isOpenFormWorkLog,
            includingDataForm,
        } = this.state;

        return (
            <>
                {this.renderConfirmDeleteWindow()}
                {
                    isOpenPhotoReport &&
                    <PhotoReport
                        data={{
                            ...dataPhotoReport,
                            objectLongitude,
                            objectLatitude,
                        }}
                        onClose={this.closePhotoReport}
                    />
                }
                {
                    isOpenFormWorkLog &&
                    <TimeSheetNewModal
                        data={{
                            contractorId,
                            clientId,
                            orderId,
                            ...includingDataForm,
                            checkOutRequired,
                            checkInRequired,
                        }}
                        checkinData={{
                            checkOutRequired,
                            checkInRequired,
                        }}
                        onClose={this.closeFormWorkLog}
                    />
                }
                <NmModal
                    size="lg"
                    onClose={onClose}
                    className="time-sheet"
                    header={
                        <NmTitle size="lg">
                            {t("time-sheet.time-sheet-title")}
                        </NmTitle>
                    }
                    loading={progressWorkLog || progressContractors}
                >
                    <NmForm>
                        {this.renderHeader()}
                        <div className="time-sheet__content">
                            {this.renderAddTimeSheetRecord(t)}
                            <div className="time-sheet__row time-sheet__header-row">
                                {
                                    checkInRequired ?
                                        <div className="time-sheet__column-work-start">
                                            {t("time-sheet.date-from")}
                                        </div> :
                                        null
                                }
                                {
                                    checkOutRequired ?
                                        <div className="time-sheet__column-work-end">
                                            {t("time-sheet.end")}
                                        </div> :
                                        null
                                }
                                {
                                    checkInRequired && checkOutRequired ?
                                        <div className="time-sheet__column-work-hours">
                                            {t("time-sheet.hours-count")}
                                        </div> :
                                        null
                                }
                            </div>
                            {this.renderOnlyCheckIn()}
                            {this.renderList()}
                            {this.renderConfirm()}
                        </div>
                    </NmForm>
                </NmModal>
            </>
        );
    }
}

export default connect(
    state => ({
        options: contractorsApplicationsOptionsSelector(state),
        contractors: contractorsTimeSheetSelector(state),
        list: workLogListSelector(state),
        includingData: workLogIncludingDataSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        progressWorkLog: workLogProgressListSelector(state),
        progressContractors: contractorProgressApplicationsSelector(state),
        progressExpired: workLogProgressExpiredSelector(state),
        order: orderCardSelector(state),
    }),
    {
        getWorkLog,
        addWorkLog,
        deleteWorkLog,
        getContractorsByOrder,
        updateFieldWorkLog,
        updateFieldContractorStore,
        approveExpiredWorkLog,
    },
)(withTranslation()(TimeSheet));
