import {useDispatch} from "react-redux";

import {
    addRecruitmentAccessControlRecruitersGroups,
    deleteRecruitmentAccessControlRecruitersGroups,
} from "../../../../../ducks/bff/recruitment/access-control/recruiters-groups/actionsCreators";

import {useModal} from "../../../../../hooks/useModal";

import {toastSuccess} from "../../../../../utils/toastHelper";

export const useRecruitmentAccessControlRecruitersGroupsAction = (props) => {
    const {
        fetchList,
        clientId,
    } = props;

    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useModal();

    const dispatch = useDispatch();

    const onAdd = ({name})=> {
        dispatch(addRecruitmentAccessControlRecruitersGroups({
            name,
            clientId,
            onSuccess: () => {
                onCloseModal();
                toastSuccess("Группа успешно добавлена");
                fetchList();
            },
        }));
    };

    const onDelete = () => {
        dispatch(deleteRecruitmentAccessControlRecruitersGroups({
            id: modalData.id,
            onSuccess: () => {
                toastSuccess("Группа успешно удалена");
                fetchList();
            },
        }));
    };

    return {
        onDelete,
        onAdd,
        onOpenModal,
        onCloseModal,
        modalData,
    };
};