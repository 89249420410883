import * as yup from "yup";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {CONTRACTOR_MIGRATION_STATUS} from "../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {FULL_REGISTRATION_COMPONENT_TYPE, FULL_REGISTRATION_STEP} from "../constants";

export const getFullRegistrationSelfieStep = (params) => {
    const {
        isRequiredFields,
        migrationStatus,
        citizenship,
        locatedOutsideRussia,
    } = params;

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.REFUGEE) {
        return {
            value: FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            modalTitle: "Селфи с удостоверением беженца",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE]: isRequiredFields && yup.object().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE,
                        required: false,
                        label: "Селфи с удостоверением беженца",
                    },
                ];
            },
        };
    }

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE) {
        return {
            value: FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            modalTitle: "Селфи со свидетельством о предоставлении временного убежища",
            VALIDATION:() => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE]: isRequiredFields && yup.object().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE,
                        required: false,
                        label: "Селфи со свидетельством о предоставлении временного убежища",
                    },
                ];
            },
        };
    }

    if (
        citizenship === citizenshipsDict.NOT_SPECIFIED.value &&
        (
            locatedOutsideRussia ||
            [
                CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
                CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
            ].includes(migrationStatus)
        )
    ) {
        return {
            value: FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            modalTitle: "Селфи с удостоверением личности лица без гражданства",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE]: isRequiredFields && yup.object().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE,
                        required: false,
                        label: "Селфи с временным удостоверением личности лица без гражданства",
                    },
                ];
            },
        };
    }

    return {
        value: FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
        modalTitle: "Селфи с основным документом",
        validation: () => {
            return yup.object().shape({
                [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: isRequiredFields && yup.object().required(VALIDATIONS_MESSAGE.REQUIRED),
            });
        },
        fields: () => {
            return [
                {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                    name: CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
                    required: false,
                    label: "Селфи с паспортом",
                },
            ];
        },
    };
};