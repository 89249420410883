import {useEffect} from "react";
import {isEmpty} from "lodash";

export const useVerificationDocumentFormValidate = (params) => {
    const {
        comparisonData,
        validateForm,
    } = params;

    useEffect(() => {
        if (!comparisonData) {
            return;
        }

        if (!isEmpty(comparisonData.personalData)) {
            setTimeout(() => {
                validateForm();
            }, 0);
        }
    }, [comparisonData]);

    return null;
};