import React from "react";

import "./style.sass";

const ContractorVerificationSelfieInfo = (props) => {
    const {
        text = "Убедитесь, что на фотографии один и тот же человек",
    } = props;

    return (
        <div className="contractor-verification-selfie-info">
            {text}
        </div>
    );
};

export default ContractorVerificationSelfieInfo;