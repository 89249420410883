import formatDate, {getMonthName} from "../../../../utils/dateFormat";

export const getRecruitmentCandidatesExperiencePeriod = (periodFrom, periodTo) => {
    const fromDate = getMonthAndYearFormattedDate(periodFrom);
    const toDate = getMonthAndYearFormattedDate(periodTo);

    return `${fromDate} - ${toDate}`;
};

const getMonthAndYearFormattedDate = (date) => {
    if (!date) {
        return "";
    }

    const fromDate = new Date(date);
    const fromYear = formatDate(fromDate, "yyyy");
    const fromMonth = getMonthName(fromDate);

    return `${fromMonth} ${fromYear}`;
};