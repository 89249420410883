import {useMemo, useState} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import formatDate from "../../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

export function useListNotificationEventFilter({eventTypeDict}) {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            eventTypesFilter = [],
            logStartDateFilter,
            logEndDateFilter,
        } = filter;

        return {
            eventTypesFilter: eventTypesFilter.length ? eventTypesFilter : undefined,
            logStartDateFilter: isNullOrWhitespace(logStartDateFilter) ? undefined : formatDate(logStartDateFilter, "yyyy-MM-dd"),
            logEndDateFilter: isNullOrWhitespace(logEndDateFilter) ? undefined : formatDate(logEndDateFilter, "yyyy-MM-dd"),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label:"Тип события",
                        placeholder: "Выберите тип события",
                        name: "eventTypesFilter",
                        multiple: true,
                        isClearable: true,
                        search: true,
                        options: dictionaryToOptions(eventTypeDict),
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "logStartDateFilter",
                        endFieldName: "logEndDateFilter",
                        isClearable: true,
                        label: "Период",
                    },
                ],
            },
        ];
    }, [eventTypeDict]);

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    };
}