import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../components/ActualComponents/FilterCustomer";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmTooltip from "../../../components/ActualComponents/NmTooltip";
import Tabs from "../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import PhoneWithCodeFilter from "../../../components/PhoneWithCodeFilter";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {history} from "../../../store/configureStore";
import DisputesNew from "../disputes-new";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {isChatSupported} from "../../../utils/firebaseHelper";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {phoneFormat, removePhoneMask} from "../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastWarning} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    CHAT_LINK_PARAMS,
    CHAT_LS_PARAMS,
    DISPUTE_FIELD,
    DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE,
    DISPUTE_STATUS_RESULT_MSG,
    DISPUTES_LS_PARAMS,
} from "../../../constants/disputes";
import {
    LINK_CONTRACTOR_PROFILE,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
} from "../../../constants/links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    isUserFromNm,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {
    getClientCardSelector,
} from "../../../ducks/client";
import {updateFieldClientMemberStore} from "../../../ducks/clientMember";
import {
    causesDisputeDictsOptionsSelector,
    disputeListLoadingSelector,
    disputesListSelector,
    disputesSelfTotalCountOpenSelector,
    disputeStatusSelector,
    disputeTotalCountForOrderCardSelector,
    disputeTotalPagesSelector,
    getDisputeCausesDict,
    getDisputesList,
    getDisputeStatusDict,
    updateFieldDisputeStore,
} from "../../../ducks/dispute";

import {disputeType} from "../../../types";
import PropTypes from "prop-types";

class DisputesList extends Component {
    static propTypes = {
        disputesList: PropTypes.arrayOf(disputeType),
        orderId: PropTypes.string,
        clientIdFilter: PropTypes.string,
        isShowClientFilter: PropTypes.bool,
    };

    static defaultProps = {
        disputesList: [],
        isShowClientFilter: true,
    };

    constructor(props) {
        super(props);

        const {pageNumber, status, pageSize, disputeId, orderId, clientId} = props.match.params;

        this.clientId = clientId;
        this.isClientCard = Boolean(props.clientIdFilter);

        this.orderId = orderId;

        this.backLink = props.location.pathname;

        const disputeListStatus = status || CHAT_LINK_PARAMS.OPEN;

        this.state = {
            isOpenDisputeNew: !isNullOrWhitespace(disputeId),
            disputeId,
            disputeListStatus,
            orderType: "desc",
            pageNum: Number(pageNumber) || 1,
            pageSize: Number(pageSize) || 25,
            isSearch: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getDisputeCausesDict,
            getDisputeStatusDict,
            pageNum,
        } = this.props;

        this.disputesListLink = this.isClientCard ? LINK_DISPUTES_CLIENT_ADMIN : LINK_DISPUTES;

        ls(CHAT_LS_PARAMS.CHAT_LIST_BACK_LINK, "");

        this.orderIdParam = isNullOrWhitespace(this.orderId) ? "" : this.orderId;

        getDisputeCausesDict();
        getDisputeStatusDict();

        if (pageNum) {
            const {pageSize} = this.props;

            this.setState(prevState => ({
                ...prevState,
                pageNum,
                pageSize,
            }), () => {
                this.fetchDisputesList();
            });

            return;
        }

        this.fetchDisputesList();
    }

    componentDidUpdate() {
        const {
            openListTotalCount: openListTotalCountFromProps,
        } = this.props;

        const {
            disputeListStatus,
            openListTotalCount,
        } = this.state;

        //на бэке реализовано, что счётчик открытых обновляется при фильтре закрытых NAMEMIX-12785
        // это фикс, чтоб счётчик не обновлялся
        if ((disputeListStatus === CHAT_LINK_PARAMS.OPEN || isNullOrWhitespace(openListTotalCount)) && openListTotalCount !== openListTotalCountFromProps) {
            this.setState({
                openListTotalCount: openListTotalCountFromProps,
            });
        }
    }

    fetchDisputesList = () => {
        const {
            getDisputesList,
            clientIdFilter: _clientIdFilter,
        } = this.props;

        const {
            pageNum,
            pageSize,
            disputeListStatus,
            clientIdFilter,
            phoneFilter,
            orderNumFilter,
            fioFilter,
            field,
            orderType,
        } = this.state;

        const disputeStatusFilter = disputeListStatus === CHAT_LINK_PARAMS.CLOSED ? [disputeListStatus.toUpperCase(), CHAT_LINK_PARAMS.CANCEL.toUpperCase()] : [disputeListStatus.toUpperCase()];

        const reqData = {
            pageNum,
            pageSize,
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
            orderNumFilter: handleFormString(orderNumFilter),
            fioFilter: handleFormString(fioFilter),
            // _clientIdFilter - для фильтрации в случае если страница открыта из карточки компании в админской части
            clientIdFilter: _clientIdFilter ? _clientIdFilter : clientIdFilter,
            disputeStatusFilter,
            orderIdFilter: this.orderId,
            field,
            orderType,
        };

        getDisputesList(reqData);
    };

    renderTooltip(text) {
        return (
            <NmTooltip
                onClose={this.setCurrentTooltipIndex(-1)}
                body={[{
                    textBlock: text,
                }]}
                type="light"
                compound
            />
        );
    };

    setCurrentTooltipIndex = (id) => {
        return (e) => {
            e.stopPropagation();
            this.setState({
                currentTooltipIndex: id,
            });
        };
    };

    renderContractorName = (item) => {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            historyData,
        } = this.props;

        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", item[DISPUTE_FIELD.CONTRACTOR_ID]);
        const contractorFullName = `${item[DISPUTE_FIELD.CONTRACTOR_LAST_NAME]} ${item[DISPUTE_FIELD.CONTRACTOR_FIRST_NAME]} ${item[DISPUTE_FIELD.CONTRACTOR_PATRONYMIC] || ""}`;
        const isRenderContractorLink = ![CLIENT_ADMIN, CLIENT_ACCOUNTANT, FOREMAN, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role);

        return (
            isRenderContractorLink ?
                <ExtLink
                    extData={historyData}
                    pageData={{
                        pageNum, pageSize,
                    }}
                    to={contractorLink}
                    historyEnabled
                >
                    {contractorFullName}
                </ExtLink> :
                contractorFullName
        );
    };

    getRows = () => {
        const {
            disputeListStatus,
        } = this.state;
        const {
            disputesList,
            disputeCauses,
        } = this.props;

        return disputesList.map(item => {
            const {
                clientName,
                brand,
                orderName,
            } = item;
            const subjectObj = disputeCauses.find(subjectItem => subjectItem.value === item[DISPUTE_FIELD.CAUSE_DISPUTE]);
            const _clientName = brand ? `${clientName} (${brand})` : clientName;

            return {
                ...item,
                key: item.disputeId,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={`Дата начала спора ${dateFormat(convertUtcToLocal(item[DISPUTE_FIELD.CREATION_DATE]))}`}
                        primaryHeaderLink
                        primaryHeader={this.renderContractorName(item)}
                        labels={[
                            {label: "Телефон", text: phoneFormat(item[DISPUTE_FIELD.PHONE], item.contractorLocatedOutsideRussia)},
                            {label: "Компания", text: _clientName},
                            {label: "Заказ", text: orderName || "-"},
                            {label: "Причина спора", columnOnMobile: true, text: subjectObj ? subjectObj.text : "-"},
                            disputeListStatus !== CHAT_LINK_PARAMS.OPEN ?
                                {label: "Результат спора", columnOnMobile: true, text: item[DISPUTE_FIELD.DISPUTE_RESULT] || DISPUTE_STATUS_RESULT_MSG[item[DISPUTE_FIELD.DISPUTE_STATUS_RESULT]]} :
                                null,
                        ]}
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                mobile: 0,
                                tablet: 0,
                                desktop: 2,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        size: "lg",
                                        color: "grey",
                                        children: "Параметры спора",
                                        onClick: () => this.handleClickShowDispute(item),
                                    },
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        size: "lg",
                                        color: "light-green",
                                        children: "Перейти в чат",
                                        count: item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT],
                                        onClick: this.showChatList(item[DISPUTE_FIELD.DISPUTE_ID]),
                                    },
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    handleClickShowDispute = (e) => {
        const {
            updateFieldDisputeStore,
        } = this.props;

        const {
            pageNum,
            pageSize,
            disputeListStatus,
        } = this.state;

        updateFieldDisputeStore("dispute", {});

        this.setState(prevState => ({
            ...prevState,
            isOpenDisputeNew: !prevState.isOpenDisputeNew,
            disputeId: e.disputeId,
        }));

        if (e.disputeId) {
            history.push(this.disputesListLink
                .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId)
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, e.disputeId));
        }
    };

    handleCloseDisputeNew = () => {
        const {
            pageNum,
            pageSize,
            disputeListStatus,
        } = this.state;
        const {updateFieldDisputeStore} = this.props;

        this.setState({
            isOpenDisputeNew: false,
            disputeId: null,
        }, this.fetchDisputesList);

        history.push(this.disputesListLink
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
            .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "")
            .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId));

        updateFieldDisputeStore("isDisputeSaveSuccess", false);
        updateFieldDisputeStore("isDisputeChangeStatusSuccess", false);
        updateFieldDisputeStore("dispute", {});
    };

    handleChangePageSize = pageSize => {
        const {
            pageNum,
            disputeListStatus,
        } = this.state;

        ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_NUMBER, pageNum);
        ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_SIZE, pageSize);
        history.push(this.disputesListLink
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId)
            .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "")
            .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus));

        this.setState(
            {
                pageNum: 1,
                pageSize,
            },
            this.fetchDisputesList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {
            pageNum: pageNumOld,
            pageSize,
            disputeListStatus,
        } = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_NUMBER, pageNum);
        ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_SIZE, pageSize);
        history.push(this.disputesListLink
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId)
            .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "")
            .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus));

        this.setState({
            pageNum,
        }, this.fetchDisputesList);
    };

    setStatus = (disputeListStatus) => {
        const {
            pageSize,
            pageNum,
        } = this.state;

        return () => {
            this.setState({
                disputeListStatus,
            }, () => {
                this.clearFilter();
            });

            if (!this.isClientCard) {
                history.push(this.disputesListLink
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                    .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, ""));
            }
        };
    };

    showChatList = (disputeId) => {
        return (e) => {
            if (!isChatSupported()) {
                toastWarning(DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE);
            }

            if (!isNullOrWhitespace(e)) {
                e.stopPropagation();
            }

            const {
                pageSize,
                pageNum,
                disputeListStatus,
            } = this.state;

            const {location, historyData} = this.props;
            const state = {prevPath: location.pathname, pageData: {pageNum, pageSize}, extData: historyData};

            disputeId = isNullOrWhitespace(disputeId) ? "all" : disputeId;

            ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_NUMBER, pageNum);
            ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_SIZE, pageSize);
            ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_STATUS, disputeListStatus);

            if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)) {
                const to = LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST
                    .replace(":clientId", this.clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "10")
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderIdParam);

                history.push({pathname: to, state});
                return;
            }

            const to = LINK_DISPUTES_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "10")
                .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderIdParam);

            history.push({pathname: to, state});
        };
    };

    handleFilter = (e, {name, value}) => {
        this.setState({[name]: value});
    };

    onChangeClientFilter = (e, {value: clientIdFilter}) => {
        this.setState({clientIdFilter});
    };

    submitFilter = () => {
        this.setState({pageNum: 1, isSearch: true}, this.fetchDisputesList);
    };

    clearFilter = () => {
        this.setState({
            clientIdFilter: null,
            phoneFilter: "",
            fioFilter: "",
            orderNumFilter: "",
            pageNum: 1,
            isSearch: false,
        }, this.fetchDisputesList);
    };

    renderTabs() {
        const {
            disputeListStatus,
            openListTotalCount,
        } = this.state;
        const {t} = this.props;

        return (
            <Tabs
                className="mb-4"
                panes={[
                    {
                        active: disputeListStatus === CHAT_LINK_PARAMS.OPEN,
                        name: t("disputes-list.open-disputes"),
                        onClick: this.setStatus(CHAT_LINK_PARAMS.OPEN),
                        count: openListTotalCount,
                    },
                    {
                        active: disputeListStatus === CHAT_LINK_PARAMS.CLOSED,
                        name: t("disputes-list.close-disputes"),
                        onClick: this.setStatus(CHAT_LINK_PARAMS.CLOSED),
                    },
                ]}
            />
        );
    }

    renderFilter() {
        const {
            clientIdFilter,
            phoneFilter,
            fioFilter,
            orderNumFilter,
        } = this.state;

        const {
            isShowClientFilter,
            t,
        } = this.props;

        return (
            <NmForm addMargin>
                {
                    isUserFromNm(ls(USER_ROLE)) && isShowClientFilter &&
                    <FilterCustomer
                        onChange={this.onChangeClientFilter}
                        placeholder={t("disputes-list.enter-client-name")}
                        search={true}
                        value={clientIdFilter}
                        isClearSearchOnBlur={true}
                    />
                }
                <PhoneWithCodeFilter
                    name="phoneFilter"
                    value={phoneFilter}
                    onChange={this.handleFilter}
                    className="contractor-resources-filter__row"
                />
                <NmInputV2
                    size="lg"
                    onChange={this.handleFilter}
                    label={t("disputes-list.fullname-filter")}
                    name="fioFilter"
                    value={fioFilter}
                />
                <NmInputV2
                    size="lg"
                    mask="999999999999"
                    maskChar={null}
                    label={t("disputes-list.order-number")}
                    value={orderNumFilter}
                    name="orderNumFilter"
                    onChange={this.handleFilter}
                />
                <FilterButtonsV2
                    onSearch={this.submitFilter}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    onClickSort = ({sortType: orderType}) => {
        this.setState({
            orderType,
        }, this.fetchDisputesList);
    };

    render() {
        const {
            pageSize,
            pageNum,
            isOpenDisputeNew,
            disputeId,
            orderType,
            isSearch,
        } = this.state;

        const {
            totalPages,
            isLoading,
            t,
            totalCount,
            disputesList,
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return (
            <NmPage
                className="disputes-list"
                header={
                    <NmPageHeader
                        size="xl"
                        text={t("disputes-list.disputes-list-title")}
                    />
                }
                controls={
                    [ADMIN, NM_MANAGER].includes(this.role) && !isClientArchived &&
                    <NmButton
                        size="xl"
                        color="green"
                        icon={<AddIcon />}
                        onClick={this.handleClickShowDispute}
                    >
                        Создать спор
                    </NmButton>
                }
                typeFilter="vertical"
                filtersBase={this.renderFilter()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                isLoaded={isLoading}
            >
                {isOpenDisputeNew ?
                    <DisputesNew
                        disputeId={disputeId}
                        showChatList={this.showChatList}
                        handleClose={this.handleCloseDisputeNew}
                    /> : null}
                {this.renderTabs()}
                {
                    disputesList?.length
                        ? <CheckboxList
                            sort
                            sortOptions={[{
                                key: "fio",
                                text: "По дате начала спора",
                                sortType: orderType,
                                asc: "asc",
                                desc: "desc",
                            }]}
                            onClickSort={this.onClickSort}
                            rows={this.getRows()}
                        />
                        : <NmEmptyPageV2
                            isSearch={isSearch}
                            fetchProgress={isLoading}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        location: state.router.location,
        disputesList: disputesListSelector(state),
        totalPages: disputeTotalPagesSelector(state),
        totalCount: disputeTotalCountForOrderCardSelector(state),
        disputeCauses: causesDisputeDictsOptionsSelector(state),
        disputeStatusList: disputeStatusSelector(state),
        isLoading: disputeListLoadingSelector(state),
        openListTotalCount: disputesSelfTotalCountOpenSelector(state),
        client: getClientCardSelector(state),
    }),
    {
        getDisputesList,
        updateFieldDisputeStore,
        getDisputeCausesDict,
        getDisputeStatusDict,
        updateFieldClientMemberStore,
    },
)(withPageData(withTranslation()(DisputesList)));
