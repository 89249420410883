export const ACT_REGISTRY_CONTRACTOR_STATUS_DICT = {
    WAITING_ORDER_CONFIRMATION: "Ожидание подтверждения заявки от исполнителя",
    WAITING_CONTRACTOR_SIGN: "Ожидание подписания договора от исполнителя",
    IN_PROGRESS: "Заказ в работе",
    DECLINED_BY_CONTRACTOR: "Заявка отклонена исполнителем",
    DECLINED_BY_CLIENT: "Заявка отозвана заказчиком",
};

export const ACT_REGISTRY_PAYMENT_STATUS_DICT = {
    AWAITING_CONFIRMATION: "Ожидание подтверждения от исполнителя",
    REJECTED: "Отклонено",
    REJECTED_BY_CONTRACTOR: "Отклонено самозанятым",
    NOT_CONFIRMED: "Ожидание подтверждения от заказчика",
    CONFIRMED: "Ожидание подтверждения от администратора \"Наймикс\"",
    FOR_PAYMENT: "К оплате",
    CANCELLED: "Аннулирован",
    PAYMENT_COMPLETED: "Платеж завершен",
    PAYMENT_ERROR: "Ошибка платежа",
    CONFIRMATION_ERROR: "Ошибка подтверждения Наймикс",
    NOT_CONFIRMED_ERROR: "Ошибка подтверждения заказчиком",
};

export const ACT_REGISTRY_ITEM_STATUS_DICT = {
    FORMED: "Формируется",
    IN_WORK: "В работе",
    SIGNED: "Подписано исполнителем",
    ERROR_SEND_ACT: "Ошибка при отправке акта",
    DECLINED: "Отклонено",
};

export const ACT_REGISTRY_PAYMENTS_STATUS_DICT = {
    AWAITING_CONFIRMATION: {
        VALUE: "AWAITING_CONFIRMATION",
        TEXT: "Ожидание подтверждения от исполнителя",
        COLOR: "#555555",
    },
    REJECTED: {
        VALUE: "REJECTED",
        TEXT: "Отклонено",
        COLOR: "#EE1616",
    },
    REJECTED_BY_CONTRACTOR: {
        VALUE: "REJECTED_BY_CONTRACTOR",
        TEXT: "Отклонено самозанятым",
        COLOR: "#EE1616",
        MESSAGE: "Исполнитель отклонил подписание договора",
    },
    NOT_CONFIRMED: {
        VALUE: "NOT_CONFIRMED",
        TEXT: "Ожидание подтверждения от заказчика",
        COLOR: "#555555",
    },
    CONFIRMED: {
        VALUE: "CONFIRMED",
        TEXT: "Ожидание подтверждения от администратора \"Наймикс\"",
        COLOR: "#555555",
    },
    FOR_PAYMENT: {
        VALUE: "FOR_PAYMENT",
        TEXT: "К оплате",
        COLOR: "#555555",
    },
    CANCELLED: {
        VALUE: "CANCELLED",
        TEXT: "Аннулирован",
        COLOR: "#EE1616",
    },
    PAYMENT_COMPLETED: {
        VALUE: "PAYMENT_COMPLETED",
        TEXT: "Платеж завершен",
        COLOR: "#128A10",
    },
    PAYMENT_ERROR: {
        VALUE: "PAYMENT_ERROR",
        TEXT: "Ошибка платежа",
        COLOR: "#EE1616",
    },
};