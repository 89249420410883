import React from "react";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";

import {usePagination} from "../../../hooks/usePagination";
import {useKedoExportsAction} from "./hooks/useAction";
import {useKedoExportsBff} from "./hooks/useBff";
import {useKedoExportsDicts} from "./hooks/useDicts";
import {useKedoExportsFetch} from "./hooks/useFetch";

import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFormattedFullName, getFullName} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER} from "../../../constants/roles";
import {KEDO_EXPORTS_STATUS, KEDO_EXPORTS_STATUS_BADGE_MOD} from "./constants";

export const KedoExports = (props) => {
    const clientId = props.match.params.clientId;

    const {
        exportStateTypesDict,
        exportTypesDict,
    } = useKedoExportsDicts();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        fetchList,
    } = useKedoExportsFetch({
        pageNum,
        pageSize,
        clientId,
    });

    const role = ls(USER_ROLE);

    const {
        totalCount,
        progressAction,
        progress,
        list,
        totalPages,
    } = useKedoExportsBff();

    const {
        onCloseConfirm,
        isOpenConfirm,
        download,
        cancelExport,
        onOpenConfirm,
    } = useKedoExportsAction({
        fetchList,
    });

    const getMediaControls = (document) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "light-green",
                        icon: <FileDownloadIcon />,
                        onlyIcon: true,
                        onClick: () => {
                            download(document);
                        },
                    },
                    asset: {
                        mobile: {children: "Скачать"},
                        tablet: {children: "Скачать"},
                    },
                    visible:
                        [
                            ADMIN,
                            NM_MANAGER,
                            CLIENT_ADMIN,
                        ].includes(role)
                        && [
                            KEDO_EXPORTS_STATUS.SUCCESS,
                        ].includes(document.state),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "red",
                        icon: <CloseIcon />,
                        disabled: progressAction,
                        onlyIcon: true,
                        onClick: () => {
                            onOpenConfirm(document);
                        },
                    },
                    asset: {
                        mobile: {children: "Отменить"},
                        tablet: {children: "Отменить"},
                    },
                    visible:
                        [
                            ADMIN,
                            NM_MANAGER,
                            CLIENT_ADMIN,
                        ].includes(role)
                        && [
                            KEDO_EXPORTS_STATUS.IN_PROGRESS,
                        ].includes(document.state),
                },
            ],
        };
    };

    const getBadge = (item) => {
        const text = item.state === KEDO_EXPORTS_STATUS.IN_PROGRESS
            ? `${exportStateTypesDict[item.state]} (${item.progress.percent})%`
            : exportStateTypesDict[item.state];

        return (
            <NmBadge
                mod={KEDO_EXPORTS_STATUS_BADGE_MOD[item.state] || "gray"}
                text={text || "-"}
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.exportId,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        secondaryHeaderStatus={getBadge(item)}
                        secondaryHeader={`Дата создания ${formatLocalDate(item.createdAt, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={item.name}
                        labels={[
                            {
                                label: "Тип выгрузки",
                                text: exportTypesDict[item.exportType] || "-",
                                noWrap: false,
                            },
                            {
                                label: "Инициатор",
                                text: getFormattedFullName(getFullName(
                                    item.creatorLastName,
                                    item.creatorFirstName,
                                    item.creatorPatronymic,
                                )) || "-",
                                noWrap: false,
                            },
                            {
                                label: "Количество документов",
                                text: item.progress.totalCount,
                                noWrap: false,
                            },
                        ]}
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const getConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile={true}
                content="Вы действительно хотите отменить формирование выгрузки?"
                onCancel={onCloseConfirm}
                onConfirm={cancelExport}
                isNeedClosing={false}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Экспорт документов
                </NmTitle>
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={progress}
        >
            {getConfirm()}
            {
                isEmpty(list) ?
                    <NmEmptyPageV2 title="Данные отсутствуют" /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};