import React, {useMemo, useRef} from "react";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ScrollableContainer from "../../../../components/ActualComponents/ScrollableContainer";
import Tabs from "../../../../components/ActualComponents/Tabs";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import Steps from "../../../../components/Steps";
import MapInviteModalContextProvider from "./components/context-provider";
import MapInviteModalContractorList from "./components/contractors-list";
import MapInviteModalGeneralForm from "./components/general-form";
import MapInviteModalOrderList from "./components/order-list";
import MapInviteModalTaskList from "./components/task-list";
import {Loader} from "semantic-ui-react";

import {useSelectedList} from "../../../../hooks/useSelectedList";
import {useMapInviteModalForm} from "./hooks/useForm";

import {MARKER_TYPE} from "../../constants";
import {MAP_INVITE_MODAL_STEP} from "./constants";

import {
    bffMapInviteModalProgressListSelector,
    bffMapProgressActionSelector,
} from "../../../../ducks/bff/map/selectors";

const MapInviteModal = (props) => {
    const {
        data = {},
        onClose = () => {
        },
    } = props;

    const progressList = useSelector(bffMapInviteModalProgressListSelector);
    const progressAction = useSelector(bffMapProgressActionSelector);
    const isInviteToOrderOrTask = data.type === MARKER_TYPE.CONTRACTOR;
    const $contentRef = useRef(null);

    const steps = useMemo(() => {
        if (isInviteToOrderOrTask) {
            return [
                MAP_INVITE_MODAL_STEP.GENERAL,
                MAP_INVITE_MODAL_STEP.ORDERS_OR_TASKS,
            ];
        }

        return [
            MAP_INVITE_MODAL_STEP.GENERAL,
            MAP_INVITE_MODAL_STEP.CONTRACTORS,
        ];
    }, [isInviteToOrderOrTask]);

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        currentStep,
        activeStepsItems,
        handleSubmit,
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        infiniteScrollProps,
        isGeneralStep,
        goBackStep,
        activeTab,
        setActiveTab,
        isOpenConfirm,
        confirmData,
        onOpenConfirm,
        onCloseConfirm,
    } = useMapInviteModalForm({
        data,
        steps,
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
        isInviteToOrderOrTask,
        onClose,
    });

    const getSubmitButton = () => {
        if (isGeneralStep) {
            return {
                submitBtnContent: "Далее",
                submit: handleSubmit,
            };
        }

        if (isInviteToOrderOrTask) {
            return {
                submitBtnContent: activeTab === MARKER_TYPE.ORDER ?
                    "Пригласить на заказы" :
                    "Пригласить на задания",
                submit: handleSubmit,
            };
        }

        return {
            submitBtnContent: "Пригласить",
            submit: handleSubmit,
        };
    };

    const getList = () => {
        if (currentStep === MAP_INVITE_MODAL_STEP.CONTRACTORS.value) {
            return <MapInviteModalContractorList />;
        }

        if (isInviteToOrderOrTask && activeTab === MARKER_TYPE.ORDER) {
            return <MapInviteModalOrderList />;
        }

        if (isInviteToOrderOrTask && activeTab === MARKER_TYPE.CROWD_TASK) {
            return <MapInviteModalTaskList />;
        }

        return null;
    };

    const renderListContainer = () => {
        return (
            <ScrollableContainer
                ref={$contentRef}
                fullHeight={true}
            >
                {
                    currentStep === MAP_INVITE_MODAL_STEP.ORDERS_OR_TASKS.value &&
                    <Tabs
                        secondary={true}
                        className="mt-2 mb-4"
                        panes={[
                            {
                                name: "Заказы",
                                active: activeTab === MARKER_TYPE.ORDER,
                                onClick: () => setActiveTab(MARKER_TYPE.ORDER),
                            },
                            {
                                name: "Задания",
                                active: activeTab === MARKER_TYPE.CROWD_TASK,
                                onClick: () => setActiveTab(MARKER_TYPE.CROWD_TASK),
                            },
                        ]}
                    />
                }
                {getList()}
                {
                    progressList &&
                    <div className="d-flex align-items-center justify-content-center fluid mt-4">
                        <Loader
                            className="col-16"
                            active={true}
                            inline={true}
                        />
                    </div>
                }
                {
                    infiniteScrollProps &&
                    <div {...infiniteScrollProps} />
                }
            </ScrollableContainer>
        );
    };

    const getForm = () => {
        if (currentStep === MAP_INVITE_MODAL_STEP.GENERAL.value) {
            return <MapInviteModalGeneralForm />;
        }

        if (
            [
                MAP_INVITE_MODAL_STEP.CONTRACTORS.value,
                MAP_INVITE_MODAL_STEP.ORDERS_OR_TASKS.value,
            ].includes(currentStep)
        ) {
            return renderListContainer();
        }

        return null;
    };

    const renderConfirm = () => {
        return (
            isOpenConfirm &&
            <NmConfirmV2
                zIndex={9999}
                title={confirmData.title}
                content={confirmData.content}
                onCancel={onCloseConfirm}
                onConfirm={confirmData.onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                disabled={progressAction}
                isNeedClosing={false}
            />
        );
    };

    const renderInviteModal = () => {
        return (
            <NmModal
                isHiddenModal={isOpenConfirm}
                zIndex={9999}
                classNameContent="flex flex-column"
                onClose={onClose}
                header={
                    <NmTitle size="lg">
                        {
                            isInviteToOrderOrTask ?
                                "Приглашение на заказы/задания" :
                                "Приглашение исполнителей"
                        }
                    </NmTitle>
                }
                size="md"
                footer={
                    <ApplyButtons
                        mobile="column"
                        disabled={
                            progressAction
                            || (
                                !countSelected
                                && [
                                    MAP_INVITE_MODAL_STEP.CONTRACTORS.value,
                                    MAP_INVITE_MODAL_STEP.ORDERS_OR_TASKS.value,
                                ].includes(currentStep)
                            )
                        }
                        onClickAdditional={goBackStep}
                        additionalBtnContent={!isGeneralStep && "Назад"}
                        isHiddenAdditionalOnMobile
                        cancelBtnContent={"Отменить"}
                        onClose={onClose}
                        {...getSubmitButton()}
                    />
                }
                fixedHeight={true}
            >
                <MapInviteModalContextProvider
                    data={data}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isInviteToOrderOrTask={isInviteToOrderOrTask}
                    selectedList={selectedList}
                    countSelected={countSelected}
                    handleSelectedRows={handleSelectedRows}
                    clearSelectedRows={clearSelectedRows}
                >
                    <Steps
                        withAdditionalParams
                        items={steps}
                        activeItems={activeStepsItems}
                        className="mb-4"
                    />
                    {getForm()}
                </MapInviteModalContextProvider>
            </NmModal>
        );
    };

    return (
        <>
            {renderConfirm()}
            {renderInviteModal()}
        </>
    );
};

export default MapInviteModal;