import {all, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";

import {getUserAvatarListThumbnails} from "../../fileStore";
import {
    BFF_MAP_EXPORT_ERROR,
    BFF_MAP_EXPORT_REQUEST,
    BFF_MAP_EXPORT_SUCCESS,
    BFF_MAP_GET_ADVERTS_ERROR,
    BFF_MAP_GET_ADVERTS_REQUEST,
    BFF_MAP_GET_ADVERTS_SUCCESS,
    BFF_MAP_GET_CLUSTERS_ERROR,
    BFF_MAP_GET_CLUSTERS_REQUEST,
    BFF_MAP_GET_CLUSTERS_SUCCESS,
    BFF_MAP_GET_CONTRACTORS_ERROR,
    BFF_MAP_GET_CONTRACTORS_REQUEST,
    BFF_MAP_GET_CONTRACTORS_SUCCESS,
    BFF_MAP_GET_LIST_PAGE_COUNTS_ERROR,
    BFF_MAP_GET_LIST_PAGE_COUNTS_REQUEST,
    BFF_MAP_GET_LIST_PAGE_COUNTS_SUCCESS,
    BFF_MAP_GET_ORDERS_ERROR,
    BFF_MAP_GET_ORDERS_REQUEST,
    BFF_MAP_GET_ORDERS_SUCCESS,
    BFF_MAP_GET_TASKS_ERROR,
    BFF_MAP_GET_TASKS_REQUEST,
    BFF_MAP_GET_TASKS_SUCCESS,
    BFF_MAP_SEND_INVITE_TASK_ERROR,
    BFF_MAP_SEND_INVITE_TASK_REQUEST,
    BFF_MAP_SEND_INVITE_TASK_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

const controller = "/adm/map";

export let mapMarkersCancelToken = axios.CancelToken.source();

//POST /bff/adm/map/getAllClusters
// Получение меток для карты
const getMapClusters = function* ({payload}) {
    try {
        const {
            onRequest,
            ...reqData
        } = payload;


        if (onRequest) {
            onRequest();
        }

        mapMarkersCancelToken = axios.CancelToken.source();

        const result = yield request.bff.axios({
            method: "POST",
            url: `${controller}/getAllClusters`,
            data: reqData,
            cancelToken: mapMarkersCancelToken.token,
        });

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: BFF_MAP_GET_CLUSTERS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_MAP_GET_CLUSTERS_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_MAP_GET_CLUSTERS_ERROR,
        });
    }
};

// POST /bff/adm/map/getContractorsPage
// Получение исполнителей
const getMapContractors = function* ({payload}) {
    try {
        const {
            isLoadMore,
            isInviteModal,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getContractorsPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_GET_CONTRACTORS_ERROR,
            });
            return {
                done: true,
            };
        }

        if (result.results.length) {
            const contractorIds = result.results.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_MAP_GET_CONTRACTORS_SUCCESS,
            payload: {
                ...result,
                isInviteModal,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_GET_CONTRACTORS_ERROR,
        });
    }
};

// POST /bff/adm/map/getOrdersPage
// Получение страницы заказов
const getMapOrders = function* ({payload}) {
    try {
        const {
            isLoadMore,
            isInviteModal,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getOrdersPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_GET_ORDERS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_MAP_GET_ORDERS_SUCCESS,
            payload: {
                ...result,
                isInviteModal,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_GET_ORDERS_ERROR,
        });
    }
};

// POST /bff/adm/map/getAdvertsPage
// Получение страницы объявлений
const getMapAdverts = function* ({payload}) {
    try {
        const {
            isLoadMore,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getAdvertsPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_GET_ADVERTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_MAP_GET_ADVERTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_GET_ADVERTS_ERROR,
        });
    }
};

// POST /bff/adm/map/getCrowdTasksPage
// Получение страницы крауд тасков
const getMapTasks = function* ({payload}) {
    try {
        const {
            isLoadMore,
            isInviteModal,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getCrowdTasksPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_GET_TASKS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_MAP_GET_TASKS_SUCCESS,
            payload: {
                ...result,
                isInviteModal,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_GET_TASKS_ERROR,
        });
    }
};

// POST /bff/adm/map/exportEntries
// Инициализации экспорта карты
const exportMap = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/exportEntries`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_EXPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Запущен экспорт элементов карты. Отчёт доступен в разделе Экспорт → Экспорт отчетов");

        yield put({
            type: BFF_MAP_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_EXPORT_ERROR,
        });
    }
};

// POST /bff/adm/map/getEntriesCounts
// Получение счетчиков для списка карты
const getMapListPageCounts = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getEntriesCounts`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_GET_LIST_PAGE_COUNTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_MAP_GET_LIST_PAGE_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_GET_LIST_PAGE_COUNTS_ERROR,
        });
    }
};

// POST /bff/adm/map/addSendInvitesTask
// Создание джоба на рассылку приглашений
const sendInviteTaskMap = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/addSendInvitesTask`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_MAP_SEND_INVITE_TASK_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_MAP_SEND_INVITE_TASK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_MAP_SEND_INVITE_TASK_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_MAP_GET_CLUSTERS_REQUEST, getMapClusters),
        takeEvery(BFF_MAP_GET_CONTRACTORS_REQUEST, getMapContractors),
        takeEvery(BFF_MAP_GET_ORDERS_REQUEST, getMapOrders),
        takeEvery(BFF_MAP_GET_ADVERTS_REQUEST, getMapAdverts),
        takeEvery(BFF_MAP_GET_TASKS_REQUEST, getMapTasks),
        takeEvery(BFF_MAP_EXPORT_REQUEST, exportMap),
        takeEvery(BFF_MAP_GET_LIST_PAGE_COUNTS_REQUEST, getMapListPageCounts),
        takeEvery(BFF_MAP_SEND_INVITE_TASK_REQUEST, sendInviteTaskMap),
    ]);
}