import React, {useState} from "react";
import {useDispatch} from "react-redux";

import ClientListSystemPropertiesHistory from "../components/system-proprties-history";
import TerminateContractorForm from "../components/terminate-contract-form";

import {replacer} from "../../../utils/replacer";

import {CLIENT_LIST_CONFIRM_MESSAGE, CLIENT_LIST_OPERATION} from "../constants";

import {setTerminateContractClient} from "../../../ducks/client";

export const useClientListConfirm = (params) => {
    const {
        fetchList,
    } = params;
    const [modalData, setModalData] = useState({
        confirmData: {},
        currentOperation: "NO_OPERATION",
    });

    const dispatch = useDispatch();

    const showConfirmWindow = (data) => {
        const {
            clientId,
            flag,
            name,
            type,
            additionalMessage = "",
            titleConfirm,
            submitBtnContent,
            formId,
            cancelBtnContent,
            isHiddenConfirmationButtons,
            requestData,
        } = data;

        let message;

        if (type === CLIENT_LIST_OPERATION.ARCHIVE) {
            message = flag ?
                replacer(":name", CLIENT_LIST_CONFIRM_MESSAGE.RESTORED, name) :
                replacer(":name", `${additionalMessage} ${CLIENT_LIST_CONFIRM_MESSAGE.ARCHIVED}`, name);
        }

        if (type === CLIENT_LIST_OPERATION.TERMINATE_CONTRACT) {
            message = <TerminateContractorForm
                clientId={clientId}
                submit={(data) => {
                    dispatch(setTerminateContractClient({
                        clientId,
                        data,
                        onSuccess: () => {
                            handleCancel();
                            fetchList();
                        },
                        onError: handleCancel,
                    }));
                }
                }
            />;
        }

        if (type === CLIENT_LIST_OPERATION.LOCK) {
            message = flag ?
                replacer(":name", CLIENT_LIST_CONFIRM_MESSAGE.UNLOCK, name) :
                replacer(":name", CLIENT_LIST_CONFIRM_MESSAGE.LOCK, name);
        }

        if (type === CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN) {
            message = CLIENT_LIST_CONFIRM_MESSAGE.SET_PAYMENT_WITH_ADMIN;
        }

        if (type === CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN) {
            message = CLIENT_LIST_CONFIRM_MESSAGE.SET_PAYMENT_WITHOUT_ADMIN;
        }

        if (type === CLIENT_LIST_OPERATION.GET_LOG_EDITING_OPTIONS) {
            message = <ClientListSystemPropertiesHistory />;
        }

        if (
            [
                CLIENT_LIST_OPERATION.CIVIL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE,
                CLIENT_LIST_OPERATION.INDIVIDUAL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE,
            ].includes(type)) {
            message = <ClientListSystemPropertiesHistory typeFilter={type} />;
        }

        setModalData({
            openConfirmWindow: true,
            currentOperation: type,
            textConfirm: message,
            titleConfirm,
            isHiddenConfirmationButtons: isHiddenConfirmationButtons,
            confirmData: {
                clientId,
                flag: flag ? flag : false,
                formId,
                requestData,
            },
            cancelBtnContent,
            submitBtnContent,
        });
    };

    const handleCancel = () => {
        setModalData({
            openConfirmWindow: false,
            confirmData: {},
            currentOperation: "",
            textConfirm: "",
        });
    };

    return {
        modalData,
        showConfirmWindow,
        handleCancel,
    };
};