import React, {useContext, useEffect, useState } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

import {ReactComponent as IconChevron} from "../../../../images/chevron-down.svg";
import NmButton from "../../../NmButton";

import bem from "../../../../utils/bem";

import "./style.sass";

interface ITabsButton {
    children?: React.ReactNode;
    first?: boolean,
    last?: boolean,
    isDisabled: boolean,
    onClick: VoidFunction;
}

function TabsButton({children, isDisabled, onClick, first, last}: ITabsButton) {
    const [block, element] = bem("tabs-button");

    if (isDisabled) {
        return null;
    }

    return (
        <NmButton
            className={block({first, last})}
            size="lg"
            color="grey"
            onlyIcon
            onClick={onClick}
            icon={<IconChevron className={element("icon", {first, last})} />}
        >
            {children}
        </NmButton>
    );
}

export function LeftButton() {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleItemsWithoutSeparators,
        initComplete,
    } = useContext(VisibilityContext);

    const [disabled, setDisabled] = useState(
        !initComplete || (initComplete && isFirstItemVisible),
    );

    useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

    return (
        <TabsButton
            first
            isDisabled={disabled}
            onClick={() => scrollPrev()}
        />
    );
}

export function RightButton() {
    const {
        isLastItemVisible,
        visibleItemsWithoutSeparators,
        scrollNext,
        initComplete,
    } = useContext(VisibilityContext);

    const [disabled, setDisabled] = useState(
        !initComplete || (initComplete && !visibleItemsWithoutSeparators.length && isLastItemVisible),
    );

    useEffect(() => {
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleItemsWithoutSeparators]);

    return (
        <TabsButton
            last
            isDisabled={disabled}
            onClick={() => {
                scrollNext();
            }}
        />
    );
}
