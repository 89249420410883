import {getUserRole} from "../../../../../../utils/access";

import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../../../../constants/roles";

export const isVisibleRecruitmentInviteOrder = () => {
    const role = getUserRole();

    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
        RECRUITER,
        CLIENT_ACCOUNTANT,
    ].includes(role);
};