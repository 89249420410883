import {createSelector} from "reselect";

export const bffRecruitmentAccessControlRecruitersGroupSelector = state => state.bff.recruitment.accessControl.common;

export const bffRecruitmentAccessControlTotalCountsSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupSelector,
    ({recruitersGroupsTotalCount, observersTotalCount, jobBoardsTotalCount}) => ({recruitersGroupsTotalCount, observersTotalCount, jobBoardsTotalCount}),
);
export const bffRecruitmentAccessControlCanSetCandidateStatusSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupSelector,
    ({canSetCandidateStatus}) => canSetCandidateStatus,
);