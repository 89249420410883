import React, {useState} from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../../../components/ActualComponents/FilterButtonsV2";
import NmEmptyPageV2 from "../../../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmPage from "../../../../../../../components/NmPage";
import NmTitle from "../../../../../../../components/NmTitle";
import TableDiv from "../../../../../../../components/TableDiv";

import {usePagination} from "../../../../../../../hooks/usePagination";
import {useSelectedList} from "../../../../../../../hooks/useSelectedList";
import {useFetchMembers} from "../../hooks/useFetchMembers";
import {useMembersFilter} from "../../hooks/useMembersFilter";

import bem from "../../../../../../../utils/bem";
import {phoneFormat} from "../../../../../../../utils/stringFormat";

import {addGroupMemberFormHeaders} from "../../../../../../../constants/headersTable";

import {
    membersForGroupTotalCountSelector,
    membersForGroupTotalPagesSelector,
    membersListForGroupSelector,
    progressGroupMembersActionSelector,
    progressMembersListFromGroupSelector,
} from "../../../../../../../ducks/client";

import "./style.sass";

const filterInitialState = {
    fioFilter: "",
    phoneFilter: "",
    emailFilter: "",
};

const AddGroupMemberForm = (props) => {
    const {
        groupId,
        submit,
        onClose,
    } = props;

    const [block, element] = bem("add-group-member-form");

    const list = useSelector(membersListForGroupSelector);
    const totalPage = useSelector(membersForGroupTotalPagesSelector);
    const totalCount = useSelector(membersForGroupTotalCountSelector);
    const progress = useSelector(progressMembersListFromGroupSelector);
    const progressAction = useSelector(progressGroupMembersActionSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("bg-modal");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useSelectedList();

    const {
        filterDto,
        isSearch,
        setIsSearch,
        setFilterDto,
    } = useMembersFilter({excludeClientGroupId: groupId});
    useFetchMembers({filter: filterDto, pageSize, pageNum});

    const [filter, setFilter] = useState(filterInitialState);

    const {fioFilter, phoneFilter, emailFilter} = filter;

    const onChange = (event, {name, value}) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const submitFilter = () => {
        setIsSearch(true);
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setIsSearch(false);
        setFilter(filterInitialState);
        setFilterDto(filterInitialState);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onSubmit = () => {
        const requestData = selectedList.filter(({isSelected}) => isSelected).map(({clientId, clientUserId}) => ({clientId, clientUserId}));
        submit(requestData);
    };

    const mapTableData = () => {
        const rows = list.map(item => {
            const user = selectedList.find(value => (value.clientUserId === item.clientUserId)) || {};
            const {
                isSelected,
            } = user;
            const {phone} = item;

            return {
                ...item,
                showCheckBox: true,
                isSelected,
                phone: phoneFormat(phone),
            };
        });

        return {
            rows,
            headers: addGroupMemberFormHeaders,
        };
    };

    const renderFilter= () => {
        return (
            <NmForm
                horizontal
                className={element("filter")}
            >
                <NmInputV2
                    size="lg"
                    placeholder="Введите ФИО сотрудника"
                    label="ФИО сотрудника"
                    name="fioFilter"
                    value={fioFilter}
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    placeholder="Введите телефон сотрудника"
                    label="Телефон сотрудника"
                    name="phoneFilter"
                    value={phoneFilter}
                    mask="+7 (999) 999 9999"
                    maskChar={null}
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    placeholder="Введите e-mail сотрудника"
                    label="E-mail сотрудника"
                    name="emailFilter"
                    value={emailFilter}
                    onChange={onChange}
                />
                <FilterButtonsV2
                    onSearch={submitFilter}
                    onClear={clearFilter}
                />
            </NmForm>
        );
    };

    const renderContent =() => {
        return (
            <NmPage
                className={block()}
                filtersBase={renderFilter()}
                isLoaded={progress || progressAction}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                totalPages={totalPage}
                totalCount={totalCount}
                currentPageNum={pageNum}
                currentPageSize={pageSize}
            >
                {
                    !(totalCount || progress) ?
                        <NmEmptyPageV2
                            isSearch={isSearch}
                        /> :
                        <TableDiv
                            className={element("table")}
                            tableData={mapTableData()}
                            isCheckBox
                            onSelectedRows={handleSelectedRows}
                        />
                }
            </NmPage>
        );
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Добавление сотрудника
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submit={onSubmit}
                    submitBtnContent="Добавить"
                    onClose={onClose}
                    cancelBtnContent="Отменить"
                    disabled={!countSelected}
                />
            }
        >
            {renderContent()}
        </NmModal>
    );
};

export default AddGroupMemberForm;