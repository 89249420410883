import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useFilter} from "../../../../../hooks/useFilter";

import {removePhoneMask} from "../../../../../utils/stringFormat";

import {registryFilterByTicketSelector, updateTicketStore} from "../../../../../ducks/crm/ticket";

const initFilter = {
    name: "",
    createDatetimeFrom: null,
    createDatetimeTo: null,
    ownerFio: "",
    senderFio: "",
    canBeReceiverInStaff: null,
    receiverWorkInStaff: null,
    receiverInStaff: null,
    contractorRegistered: null,
};

export const useEdoDocumentsRegistriesCardFilter = ({setPagination, pageSize}) => {
    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({});

    const registryFilterByTicket = useSelector(registryFilterByTicketSelector);

    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(registryFilterByTicket)) {
            setFilter({
                ...filter,
                ...registryFilterByTicket,
            });

            onSearch({registryFilterByTicket});
        }

        return () => {
            dispatch(updateTicketStore({
                ticketRegistryFilter: {},
            }));
        };
    }, [registryFilterByTicket]);

    const onSearch = ({registryFilterByTicket = {}}) => {
        if (isEmpty(filter) && isEmpty(registryFilterByTicket)) {
            return;
        }

        setFilterData({
            ...filter,
            ...registryFilterByTicket,
            receiverPhone: filter.receiverPhone ? removePhoneMask(filter.receiverPhone) : undefined,
        });
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClear = () => {
        setFilterData({});
        setIsSearch(false);
        setFilter(initFilter);
    };

    return {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    };
};