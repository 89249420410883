import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {DEFINING_REGISTRY_PARAMETER_TYPE_DICT} from "../../../../constants/clientList";

import {
    getClientCardSelector,
    updateClient,
} from "../../../../ducks/client";

import PropTypes from "prop-types";

const DefiningRegistryParameterBlock = props => {
    const {
        isEditable,
    } = props;

    const dispatch = useDispatch();

    const clientCardData = useSelector(getClientCardSelector);

    const [isEdit, setEditable] = useState(false);
    const [form, setForm] = useState(clientCardData);

    const submitClientData = () => {
        dispatch(
            updateClient({
                ...clientCardData,
                definingRegistryParameterType: form.definingRegistryParameterType,
            }),
        );
    };

    const cancelEditMode = () => {
        setEditable(false);
        setForm(clientCardData);
    };

    const toggleCard = () => {
        const {
            definingRegistryParameterType,
        } = clientCardData;

        if (isEdit && definingRegistryParameterType !== form.definingRegistryParameterType) {
            submitClientData();
        } else {
            setForm({definingRegistryParameterType});
        }
        setEditable(!isEdit);
    };

    const renderReadOnlyCard = () => {
        const {
            definingRegistryParameterType,
        } = clientCardData;

        return (
            <LabelTextApp
                label="Определяющий параметр"
                className="client-card-block__label"
                text={DEFINING_REGISTRY_PARAMETER_TYPE_DICT[definingRegistryParameterType]}
            />
        );
    };

    const handleChange = (e, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const renderEditModeCard = () => {
        const definingRegistryParameterTypeOptions = dictionaryToOptions(DEFINING_REGISTRY_PARAMETER_TYPE_DICT);

        return (
            <NmForm addMargin>
                <NmDropdownV2
                    size="lg"
                    label="Определяющий параметр"
                    name="definingRegistryParameterType"
                    value={form.definingRegistryParameterType}
                    onChange={handleChange}
                    options={definingRegistryParameterTypeOptions}
                />
            </NmForm>
        );
    };


    return (
        <CardApp
            title="Основной параметр для реестров"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEditMode}
            isEdit={isEdit}
        >
            {!isEdit ?
                renderReadOnlyCard() :
                renderEditModeCard()}
        </CardApp>
    );
};

DefiningRegistryParameterBlock.propTypes = {
    isEditable: PropTypes.bool,
};

export default DefiningRegistryParameterBlock;