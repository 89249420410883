import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {RecruitmentRecruitersAddForm} from "../../../../../components/Recruitment/RecruitersAddForm";
import {
    addRecruitmentVacancyPersonalAccess,
    clearRecruitmentVacancyPersonalAccessStore,
    getRecruitmentVacancyPersonalAccessAvailableUsers,
} from "../../../../../ducks/bff/recruitment/access-control/vacancy-personal-access/actionCreators";
import {
    bffRecruitmentVacancyPersonalAccessAvailableUsersListSelector,
    bffRecruitmentVacancyPersonalAccessAvailableUsersTotalCountSelector,
    bffRecruitmentVacancyPersonalAccessAvailableUsersTotalPagesSelector,
    bffRecruitmentVacancyPersonalAccessProgressActionSelector,
    bffRecruitmentVacancyPersonalAccessProgressSelector,
} from "../../../../../ducks/bff/recruitment/access-control/vacancy-personal-access/selectors";

export const RecruitmentProvideVacancyAccess = (props) => {
    const {
        onClose,
        clientId,
        vacancyId,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(bffRecruitmentVacancyPersonalAccessAvailableUsersListSelector);
    const listTotalCount = useSelector(bffRecruitmentVacancyPersonalAccessAvailableUsersTotalCountSelector);
    const listLoading = useSelector(bffRecruitmentVacancyPersonalAccessProgressSelector);
    const listTotalPages = useSelector(bffRecruitmentVacancyPersonalAccessAvailableUsersTotalPagesSelector);
    const progressAction = useSelector(bffRecruitmentVacancyPersonalAccessProgressActionSelector);

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentVacancyPersonalAccessStore());
        };
    }, []);

    const fetchList = (data) => {
        const {
            pageNum,
            pageSize,
            ...filterData
        } = data;

        dispatch(getRecruitmentVacancyPersonalAccessAvailableUsers({
            pageNum,
            pageSize,
            clientId,
            ...filterData,
        }));
    };

    const onAdd = ({clientUserId}) => {
        dispatch(addRecruitmentVacancyPersonalAccess({
            vacancyId,
            clientId,
            clientUserId,
            onSuccess: () => {
                onClose();
            },
        }));
    };

    return (
        <RecruitmentRecruitersAddForm
            modalHeader="Предоставление индивидуального доступа к вакансии"
            isMultipleSelection={false}
            list={list}
            listLoading={listLoading}
            addLoading={progressAction}
            listTotalCount={listTotalCount}
            listTotalPages={listTotalPages}
            onClose={onClose}
            onAdd={onAdd}
            fetchList={fetchList}
        />
    );
};