import React from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";

import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";
import {bffRecruitmentAccessControlRecruitersGroupsAddLoadingSelector} from "../../../../../../ducks/bff/recruitment/access-control/recruiters-groups/selectors";
import {getRecruitmentAccessControlRecruitersGroupsAddGroupFormValidationSchema} from "./validation";

export const RecruitmentAccessControlRecruitersGroupsAddGroupForm = (props) => {
    const {
        onAdd,
        onClose,
    } = props;

    const addLoading = useSelector(bffRecruitmentAccessControlRecruitersGroupsAddLoadingSelector);

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
    } = useFormik({
        onSubmit: onAdd,
        initialValues: {name: ""},
        validationSchema: getRecruitmentAccessControlRecruitersGroupsAddGroupFormValidationSchema(),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    const handleChange = (_, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            loading={addLoading}
            header={
                <NmTitle size="lg">
                    Добавление группы рекрутеров
                </NmTitle>
            }
            loaderContent="Добавление группы..."
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent="Добавить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                />
            }
        >
            <NmInputV2
                required
                size="xl"
                label="Название группы"
                placeholder="Введите название группы"
                name="name"
                value={values.name}
                onChange={handleChange}
                maxLength={100}
                error={touched.name && errors.name}
            />
        </NmModal>
    );
};