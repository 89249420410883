import {useState} from "react";

import {clearSpace, removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../../utils/stringHelper";

export function useActRegistryCardFilterDto() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            fioFilter,
            phoneFilter,
            paymentAbility,
            contractFrameStatusFilter,
            civilContractAmountFilter,
            orderAmountForPaymentFilter,
            orderAmountSumFilter,
            registryPaymentFilterStatuses,
            paymentNumberFilter,
        } = filter;

        return {
            ...filter,
            fioFilter: handleFormString(fioFilter),
            phoneFilter: removePhoneMask(phoneFilter),
            paymentAbility: paymentAbility && paymentAbility !== "ALL" ? paymentAbility === "YES" : undefined,
            contractFrameStatusFilter: contractFrameStatusFilter?.length ? contractFrameStatusFilter : undefined,
            civilContractAmountFilter: civilContractAmountFilter ? handleNumber(clearSpace(civilContractAmountFilter)) : civilContractAmountFilter,
            orderAmountForPaymentFilter: orderAmountForPaymentFilter ? handleNumber(clearSpace(orderAmountForPaymentFilter)) : orderAmountForPaymentFilter,
            orderAmountSumFilter: orderAmountSumFilter ? handleNumber(clearSpace(orderAmountSumFilter)) : orderAmountSumFilter,
            registryPaymentFilterStatuses: registryPaymentFilterStatuses?.length ? registryPaymentFilterStatuses : undefined,
            paymentNumberFilter: +paymentNumberFilter || undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };


    return {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}