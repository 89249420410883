import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../../constants/clientList";

export const CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS = {
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.SNILS]: {
        name: "СНИЛС",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN,
        ],
        fieldsForSending: [
            "snilsNum",
        ],
    },
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.EMPLOYMENT_HISTORY]: {
        name: "Трудовая книжка",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN,
        ],
        fieldsForSending: [
            "employmentHistoryNum",
        ],
    },
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_CERTIFICATION_REPORT]: {
        name: "Медицинское освидетельствование",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN,
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN,
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN,
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN,
        ],
    },
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.FINGERPRINTING]: {
        name: "Дактилоскопия",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN,
            CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN,
        ],
        fieldsForSending: [
            "fingerprintingDocumentIssuedBy",
            "fingerprintingDocumentIssuedDate",
            "fingerprintingDocumentNum",
        ],
    },
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_BOOK]: {
        name: "Медицинская книжка",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN,
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN,
        ],
        fieldsForSending: [
            "medicalBookNumber",
        ],
    },
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.COVID_19_VACCINATION_CERTIFICATE]: {
        name: "Сертификат о вакцинации от COVID-19 или QR-код",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.COVID_19_VACCINATION_CERTIFICATE_SCAN,
        ],
    },
    [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO]: {
        name: "Сведения о ранее выданных паспортах",
        scans: [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN,
        ],
    },
};