import {CONTRACTOR_ARRIVAL_DOC_TYPE_CODE} from "../../../../constants/contractor";

export const getNotificationOfArrivalFirstValidation = (values) => {
    if (values.arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION) {
        return [
            "address",
            "registrationDate",
            "arrivalNoticeStayEndDate",
        ];
    }

    return [
        "residencePermitIssuedDate",
        "residencePermitValidToDate",
        "residencePermitIssuedBy",
        "residencePermitDecisionNumber",
        "residencePermitDecisionDate",
    ];
};