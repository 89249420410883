export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_SUCCESS = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR";

export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_SUCCESS = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR";

export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST";
export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_SUCCESS = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR";

export const BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_CLEAR_STORE = "BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_CLEAR_STORE";