export const loadPageData = (location) => {
    if (!location) {
        return {};
    }

    const {state} = location;

    if (!state) {
        return {};
    }

    const {pageData, filterData, isLoadDataTarget = true} = state;

    if (!pageData) {
        return {};
    }

    return {
        ...pageData,
        filterData,
        isLoadDataTarget,
    };
};