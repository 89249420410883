import {clearSpace} from "./stringFormat";

export const hasScrollbar = () => document.body.scrollHeight > document.body.clientHeight;

/* Ширина скролла */
export const getScrollbarSize = () => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar";

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = "scroll";

    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
};

/***
 * Обновляет положение каретки при вводе чисел формата 3 213.31 3 333 213,32
 * с разделением на разряды
 * с разделителем . или ,
 * с 2мя цифрами после разделителя
 * @param valueFromInput - значение из инпута, сохраняемое в state
 * @param savedValueBeforeManipulations - значение в handleChange До обновления state (метод вызывается в колбэке после обновления)
 * @param element - элемент
 * @param caretPosition - позиция каретки
 */
export const updateCaretPosition = (valueFromInput, savedValueBeforeManipulations, element, caretPosition) => {
    const inputValue = clearSpace(valueFromInput);
    const currentValue = clearSpace(savedValueBeforeManipulations);

    // опредляем максимальное положение каретки до разделителя, если он присутствует
    const maxPositionCaret = valueFromInput.indexOf(".") !== -1 || valueFromInput.indexOf(",") !== -1 ? valueFromInput.length - 2 : valueFromInput.length;

    if (inputValue.indexOf(".") !== -1 && currentValue.indexOf(".") === -1 ||
        inputValue.indexOf(".") === -1 && currentValue.indexOf(".") !== -1 ||
        inputValue.indexOf(",") !== -1 && currentValue.indexOf(",") === -1 ||
        inputValue.indexOf(",") === -1 && currentValue.indexOf(",") !== -1
    )  {
        if (inputValue.length < currentValue.length) {
            const symbolPosition = currentValue.indexOf(".") !== -1 ? currentValue.indexOf(".") : currentValue.indexOf(",");
            const diff = currentValue.length - symbolPosition - 1;

            element.selectionStart = valueFromInput.length - diff;
            element.selectionEnd = valueFromInput.length - diff;

            return;
        }

        const dotCommaPos = valueFromInput.indexOf(".") !== -1 ? valueFromInput.indexOf(".") : valueFromInput.indexOf(",");
        const symbolPosition = dotCommaPos + 1;

        element.selectionStart = symbolPosition;
        element.selectionEnd = symbolPosition;

        return;
    }

    if (caretPosition > maxPositionCaret) {
        return;
    }

    if (inputValue.length < currentValue.length && inputValue.length % 3 !== 0) {
        element.selectionStart = caretPosition;
        element.selectionEnd = caretPosition;

        return;
    }

    const symbol = valueFromInput[caretPosition];
    const isSpace = symbol ? symbol.indexOf(" ") === -1 : false;

    if (inputValue.length % 3 === 1 && isSpace) {
        element.selectionStart = caretPosition + 1;
        element.selectionEnd = caretPosition + 1;

        return;
    }

    if (inputValue.length % 3 === 0 && inputValue.length < currentValue.length) {
        const position = caretPosition === 0 ? 0 : caretPosition - 1;

        element.selectionStart = position;
        element.selectionEnd = position;

        return;
    }

    element.selectionStart = caretPosition;
    element.selectionEnd = caretPosition;
};