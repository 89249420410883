import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

import {removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../../utils/stringHelper";

import {
    STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS_OPTIONS,
    STATUS_TASK_PAYMENT_OPTIONS,
} from "../../../../../constants/crowd/task-payment";

export const useCrowdTaskActRegistryCardFilter = (props) => {
    const {
        pageSize,
        setPagination,
    } = props;

    const initFilter = {
        rowNumber: "",
        taskNumber: "",
        taskName: "",
        contractorFIO: "",
        contractorPhone: "",
        paymentNumber: "",
        paymentStatuses: [],
        statuses: [],
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        const {
            rowNumber,
            taskNumber,
            taskName,
            contractorFIO,
            contractorPhone,
            paymentNumber,
            paymentStatus,
            status,
            ...otherFilters
        } = filter;

        return {
            rowNumber: handleNumber(rowNumber),
            taskNumber: handleNumber(taskNumber),
            paymentNumber: handleNumber(paymentNumber),
            taskName: handleFormString(taskName),
            contractorFIO: handleFormString(contractorFIO),
            contractorPhone: contractorPhone ? removePhoneMask(contractorPhone) : undefined,
            paymentStatuses: paymentStatus ? [paymentStatus] : undefined,
            statuses: status ? [status] : undefined,
            ...otherFilters,
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "rowNumber",
                        label: "Номер строки",
                        placeholder: "Введите номер строки",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNumber",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskName",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorFIO",
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "contractorPhone",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "paymentNumber",
                        label: "Номер оплаты",
                        placeholder: "Введите номер оплаты",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус оплаты",
                        placeholder: "Не выбран",
                        clearable: true,
                        compact: true,
                        name: "paymentStatus",
                        options: STATUS_TASK_PAYMENT_OPTIONS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус строки реестра",
                        placeholder: "Не выбран",
                        clearable: true,
                        compact: true,
                        name: "status",
                        options: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS_OPTIONS,
                    },
                ],
            },
        ];
    }, []);

    return {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    };
};