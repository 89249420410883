import * as React from "react";

import {ReactComponent as OpenNewTabIcon} from "../../../images/square_on_right_top_arrow_arrow_icon.svg";

export const ExternalResumeLink = (props) => {
    const {
        originalUrl,
        className,
    } = props;

    return (
        <a
            style={{cursor: "pointer", display: "flex"}}
            title="Открыть в новой вкладке"
            target="_blank"
            href={originalUrl}
            rel="noreferrer"
            className={className}
        >
            <OpenNewTabIcon
                width={20}
                height={20}
            />
        </a>
    );
};