import React, {Component} from "react";

import ErrorTooltip from "../ActualComponents/ErrorTooltip";
import {Rating} from "semantic-ui-react";

import PropTypes from "prop-types";

import "./style.sass";

class NmRatingFieldsForm extends Component {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.shape({
            rating: PropTypes.number,
            name: PropTypes.string,
            newError: PropTypes.string,
            label: PropTypes.string,
            disabled: PropTypes.bool,
        })).isRequired,
        handleOnRate: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    handleOnRate = (e, {rating: value, name}) => {
        const {handleOnRate} = this.props;

        handleOnRate(e, {name, value});
    };

    mapContent() {
        const {children} = this.props;

        return children.map(item => {
            const {
                rating,
                maxRating = 5,
                name,
                label,
                disabled,
                controlClassName = "",
                classNameRating = "",
                newError,
                error,
            } = item;

            return (
                <div
                    key={name}
                    className="job-evaluation-rating"
                >
                    <div className={`job-evaluation-rating__control ${controlClassName}`}>
                        {
                            label &&
                            <div className="job-evaluation-rating__label">
                                {label}
                            </div>
                        }
                        <Rating
                            disabled={disabled}
                            onRate={this.handleOnRate}
                            size="huge"
                            icon="star"
                            name={name}
                            rating={rating}
                            maxRating={maxRating}
                            className={classNameRating}
                        />
                    </div>
                    {
                        newError &&
                        <ErrorTooltip error={newError} />
                    }
                    {error &&
                        <div
                            className="ui pointing above prompt label job-evaluation-rating__error"
                        >
                            {error}
                        </div>
                    }
                </div>
            );
        });
    }

    render() {
        return this.mapContent();
    }
}

export default NmRatingFieldsForm;
