import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import CheckboxList from "../../../components/CheckboxList";
import CityCodeDetect from "../../../components/CityCodeDetect";
import MassConfirm from "../../../components/MassConfirm";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import OrderListStatus from "../../order/order-list/order-list-status";
import {SUB_PAGE_ADVERTISEMENT} from "../card";
import AdvertisementEditModal from "../edit-modal";
import AdvertisementFilter from "./components/filter";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import {useAdvertisementActions} from "./hooks/useAdvertisementActions";
import {useAdvertisementFetchList} from "./hooks/useAdvertisementFetchList";
import {useAdvertisementFilter} from "./hooks/useAdvertisementFilter";
import {useAdvertisementImportList} from "./hooks/useAdvertisementImportList";
import {useAdvertisementMassAction} from "./hooks/useAdvertisementMassAction";

import {dadataFromCityToApartmentFilter, getDadataAddressObj} from "../../../utils/dadata";
import {formatLocalDate} from "../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {getTargetAge} from "../card/utils";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {GENDER_DICT} from "../../../constants/contractorInfo";
import {EMPTY_OPTION_KEY} from "../../../constants/dropdown";
import {LINK_CLIENT_CARD_ADVERTISEMENT} from "../../../constants/links";
import {
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";
import {
    ACTION_MASS_MOBILE_OPTIONS,
    ADVERTISEMENT_MASS_OPTION,
    ADVERTISEMENT_STATUS,
    ADVERTISEMENT_TOAST_MESSAGE,
} from "./constants";

import {history} from "../../../store/configureStore";

import {
    getAdvertisementListFormattedToTaskIdsSelector,
    getAdvertisementListSelector,
    getAdvertisementProgressSelector,
    getAdvertisementTotalCountSelector,
    getAdvertisementTotalPagesSelector,
} from "../../../ducks/advertisement";
import {clientCurrentMemberSelector, getClientMemberList} from "../../../ducks/clientMember";
import {
    getCitizenships,
    getCitizenshipSelector,
} from "../../../ducks/contractor";
import {jobTasksAdvertisementSelector} from "../../../ducks/job";
import {updateFieldsStoreProject} from "../../../ducks/projects";

import "./style.sass";

const initFilter = {
    name: "",
    specialityIds: [],
    workAddressFiasId: "",
    workAddressFiasValue: "",
    publicationFromFilter: null,
    publicationToFilter: null,
    fromDateCreatedFilter: null,
    toDateCreatedFilter: null,
    status: EMPTY_OPTION_KEY,
};

function AdvertisementList(props) {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const totalCount = useSelector(getAdvertisementTotalCountSelector);
    const totalPages = useSelector(getAdvertisementTotalPagesSelector);
    const list = useSelector(getAdvertisementListSelector);
    const listFormattedToTaskIds = useSelector(getAdvertisementListFormattedToTaskIdsSelector);
    const advertisementTasks = useSelector(jobTasksAdvertisementSelector);
    const {
        clientUserId,
        pushNotificationPublicationProhibited,
    } = useSelector(clientCurrentMemberSelector);
    const citizenshipsDict = useSelector(getCitizenshipSelector);

    const [showMoreAdvertId, setShowMoreAdvertId] = useState("");

    const {
        progress,
        progressAction,
    } = useSelector(getAdvertisementProgressSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCitizenships());
        getManagerList();

        return () => {
            dispatch(updateFieldsStoreProject({managerListByUser: []}));
        };
    }, []);

    function getManagerList() {
        dispatch(getClientMemberList({
            clientId,
            archiveFilter: false,
            fieldName: "managerListByUser",
            pageNum: 1,
            pageSize: 500,
        }),
        );
    }

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({initFilter});

    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        isOpenFilter,
        setIsOpenFilter,
    } = useAdvertisementFilter();

    const {
        loading,
        fetchList,
    } = useAdvertisementFetchList({pageSize, pageNum, filter: filterDto, clientId});
    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const isShowControls = totalCount || isSearch;

    const {
        isShowMassConfirm,
        setShowMassConfirm,
        checkPublishPossible,
        publishAdvertisement,
        checkedList = [],
        massActionType,
        setMassActionType,
        deleteAdvertisement,
    } = useAdvertisementMassAction({fetchList, clearSelectedRows});

    const {
        onClickActionItem,
        setShowEditModal,
        isShowEditModal,
        duplicate,
        isEdit,
        onCloseEditModal,
        role,
        getOptions,
        confirmData,
        onCloseConfirm,
    } = useAdvertisementActions({
        deleteAdvertisement,
        clientUserId,
        pushNotificationPublicationProhibited,
    });

    const isShowActions = ![
        NM_CHIEF_ACCOUNTANT,
        NM_COORDINATOR,
        NM_CONSULTANT,
        RECRUITER,
    ].includes(role)
        && !(role === FOREMAN && pushNotificationPublicationProhibited);

    const {
        isShowImportFileModal,
        setIsShowImportFileModal,
        submitImportForm,
        progressImport,
    } = useAdvertisementImportList({clientId, fetchList});

    const [showModal, setShowModal] = useState(false);

    const onClickMobileDropdownItem = ({value: action}) => {
        switch (action) {
        case ADVERTISEMENT_MASS_OPTION.PUBLISH:
            return checkPublishPossible(getMassActionRequestData());
        case ADVERTISEMENT_MASS_OPTION.REMOVE:
            setMassActionType(ADVERTISEMENT_MASS_OPTION.REMOVE);
            setShowMassConfirm(true);

            return;
        default:
            return;


        }
    };

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        !_isSearch && setFilter(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    function renderActions(item) {
        const options = getOptions(item);

        if (options.length === 0) {
            return null;
        }

        return (
            <ContextMenu
                options={getOptions(item)}
                onClickItem={(option) => {
                    onClickActionItem(option, item);
                }}
            />
        );
    }

    const handleOnClickLink = (advertisementId) => {
        const to = LINK_CLIENT_CARD_ADVERTISEMENT
            .replace(":clientId", clientId)
            .replace(":advertisementId", advertisementId)
            .replace(":subPage", SUB_PAGE_ADVERTISEMENT.INTERESTED.LINK);
        history.replace(to);
    };

    function renderImportForm() {
        if (!isShowImportFileModal) {
            return null;
        }

        return (
            <ImportFromFilePatternV2
                patternLink="/files/Шаблон_Объявлений.xlsx"
                onSubmit={submitImportForm}
                onClose={() => setIsShowImportFileModal(false)}
                progress={progressImport}
            />
        );
    }

    function getShowMore(content, title) {
        return (
            <div className="advertisement-list__show-more-container">
                <NmShowMoreText
                    anchor="blue"
                    title={title}
                    lines={2}
                    children={content}
                    more="Подробнее"
                />
            </div>
        );
    }

    function renderCitizenships(advertId, citizenships) {
        if (isEmpty(citizenships)) {
            return "Все";
        }

        const citizenshipsList = citizenships.map(citizenship => citizenshipsDict[citizenship]);

        if (citizenshipsList.length <= 2) {
            return citizenshipsList.join(", ");
        }

        const isShowMore = showMoreAdvertId === advertId;
        const citizenshipsListString = (isShowMore ? citizenshipsList : citizenshipsList.slice(0, 2)).join(", ");

        return <>
            {citizenshipsListString}
            <span
                className="advertisement-list__more"
                onClick={() => setShowMoreAdvertId(prev => prev ? "" : advertId)}
            >
                {isShowMore ? " Cкрыть" : " Ещё"}
            </span>
        </>;
    }

    function getRows() {
        return list.map(item => {
            const {
                baseModel: {
                    publicationDateFrom,
                    publicationDateTo,
                    dateFrom,
                    dateTo,
                    status,
                    advertId,
                    workAddressInfo,
                    createDate,
                    name,
                    gender,
                    countContractors,
                    citizenships,
                    ageFrom,
                    ageTo,
                    description,
                },
                baseModel,
                fioManager,
                specialityName,
                countContractorInterest,
                countContractorNotInterest,
                countContractorOrderHired,
                countContractorOrderPayment,
                countContractorViews,
            } = item;

            const {isSelected = false} = selectedList.find(item => (item.advertId === advertId)) || {};

            return {
                ...item,
                key: advertId,
                advertId,
                showCheckBox: ADVERTISEMENT_STATUS.DRAFT.VALUE === status,
                isSelected,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <OrderListStatus
                                status={status}
                                isAdvertisement
                            />
                        }
                        secondaryHeader={`Объявление от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        onClickLink={() => handleOnClickLink(advertId)}
                        primaryHeader={name}
                        primarySubHeader={getShowMore(description, name)}
                        primaryHeaderLink
                        primaryHeaderTwoLines
                        labels={[
                            {label: "Ответственный", text: fioManager},
                            {
                                label: "Период публикации",
                                text: `${formatLocalDate(publicationDateFrom)} - ${formatLocalDate(publicationDateTo)}`,
                                noWrap: false,
                            },
                            {
                                label: "Период в объявлении",
                                text: `${formatLocalDate(dateFrom)} - ${formatLocalDate(dateTo)}`,
                                noWrap: false,
                            },
                            {
                                label: "Вид деятельности",
                                text: specialityName || "-",
                                noWrap: false,
                            },
                            {
                                label: "Город ведения деятельности",
                                text: workAddressInfo ? getDadataAddressObj(workAddressInfo || {}, true).text : "-",
                            },
                            {
                                label: "Гражданство",
                                text: renderCitizenships(advertId, citizenships),
                                noWrap: false,
                                inline: true,
                            },
                            {
                                label: "Пол",
                                text: isNullOrWhitespace(gender) ? "Все" : GENDER_DICT[gender],
                                noWrap: false,
                            },
                            {
                                label: "Возраст",
                                text: getTargetAge(ageFrom, ageTo),
                                noWrap: false,
                            },
                        ]}
                        cards={[
                            {
                                title: "Охват",
                                value: countContractors,
                                className: "col-16 col-md-4 col-xl-2 col-xxl-2 mt-xl-4 mt-md-4",
                            },
                            {
                                title: "Просмотры",
                                value: countContractorViews || 0,
                                className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                            },
                            {
                                title: "Не интересно",
                                value: countContractorNotInterest || 0,
                                className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                            },
                            {
                                title: "Интересно",
                                value: countContractorInterest || 0,
                                className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                            },
                            {
                                title: "Нанято",
                                value: countContractorOrderHired || 0,
                                className: "col-16 col-md-4 col-xl-2 col-xxl-2 mt-xl-4 mt-md-4",
                            },
                            {
                                title: "Получили выплату",
                                value: countContractorOrderPayment || 0,
                                className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                            },
                        ]}
                        cardsWithContainer
                        classNameMainContent="col-16 col-xxl-6"
                        cardsContainerClassName="col-16 col-md-14 col-xl-14 col-xxl-9 mt-md-4 mt-xxl-0"
                        actionsClassName="col-1 col-md-2 col-xl-1 col-xxl-1 justify-content-end"
                        actions={renderActions(baseModel)}
                    />
                ),
            };
        });
    }

    const getMediaControls = () => {
        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <AddIcon />,
                        children: "Добавить объявление",
                        onClick: () => setShowEditModal(true),
                    },
                    visible: isShowControls && isShowActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Загрузить список",
                        onClick: () => {
                            setIsShowImportFileModal(true);
                        },
                    },
                    visible: isShowActions,
                },
                {
                    component: COMPONENT.BUTTON_WITH_TOOLTIP,
                    props: {
                        children: "Определить код города",
                        onClick: () => setShowModal(true),
                        size: "xl",
                        color: "grey",
                        tooltip: (
                            <HelpTooltip
                                width={24}
                                height={24}
                                type="light"
                                position="bottom-right"
                                info
                                hover
                                children='Код города из справочника ФИАС может понадобиться для заполнения поля "Город" в строке объявления загружаемого файла Excel'
                            />
                        ),
                    },
                    visible: isShowActions || role === RECRUITER,
                },
            ],
        };
    };

    function getMobileDropdownMassActionOptions() {
        return ACTION_MASS_MOBILE_OPTIONS;
    }

    function getOtherActions() {
        return (
            <NmButton
                color="grey"
                onClick={() => {
                    setMassActionType(ADVERTISEMENT_MASS_OPTION.REMOVE);
                    setShowMassConfirm(true);
                }}
                disabled={countSelected === 0}
            >
                Удалить черновики
            </NmButton>
        );
    }

    function getMassActionRequestData() {
        return selectedList.filter(({isSelected}) => isSelected).map((item) => {
            const {
                baseModel: {
                    clientId,
                    publicationDateFrom,
                    advertId,
                },
            } = item;

            return {
                clientId,
                publicationDateFrom,
                advertId,
            };
        });
    }

    function renderMassConfirm() {
        const isDeleteAction = massActionType === ADVERTISEMENT_MASS_OPTION.REMOVE;
        const list = isDeleteAction ?
            selectedList.filter(({isSelected}) => isSelected).map(({baseModel: {name}}) => {
                return {
                    name,
                };
            }) :
            checkedList.map(({advertId, success}) => {
                const advertisement = selectedList.find(({baseModel}) => baseModel.advertId === advertId);

                return {
                    name: advertisement?.baseModel.name,
                    publishPossible: success,
                };
            });
        const publicationCount = checkedList.filter(({success}) => success).length;
        const isNoPossible = publicationCount === 0;

        return (
            isShowMassConfirm &&
            <MassConfirm
                title={isDeleteAction ? "Удаление объявлений" : "Публикация объявлений"}
                text={
                    isNoPossible && !isDeleteAction ?
                        "У всех выбранных объявлений заполнены не все обязательные поля для публикации" :
                        `Вы действительно хотите ${isDeleteAction ? "удалить" : "опубликовать"} нижепредставленные объявления (в статусе «Черновик»)?`
                }
                onCancel={() => {
                    setShowMassConfirm(false);
                }}
                onConfirm={
                    isDeleteAction ?
                        () => deleteAdvertisement({
                            requestData: getMassActionRequestData(),
                            toastSuccess: selectedList.length > 1 && ADVERTISEMENT_TOAST_MESSAGE.CLOSE_ADVERTS.SUCCESS,
                        }) :
                        publishAdvertisement
                }
                confirmButton={isNoPossible && !isDeleteAction ? "Oк" : "Подтвердить"}
                cancelButton="Отменить"
                list={list}
                publicationCount={publicationCount}
                impossibleCount={checkedList.length - publicationCount}
                disabled={progressAction}
                isShowIconItems={massActionType !== ADVERTISEMENT_MASS_OPTION.REMOVE}
                warningLabel="Невозможно опубликовать"
                unablePublishTooltip="Объявления, которые были выбраны в списке, но не могут быть опубликованы по причине незаполненных обязательных полей или некорректно заполненной даты публикации"
                isShowCounters={!isDeleteAction}
            />
        );
    }

    function renderModalWindowForFiasId() {
        if (!showModal) {
            return null;
        }

        return (
            <CityCodeDetect
                onClose={() => setShowModal(false)}
                filter={dadataFromCityToApartmentFilter}
            />
        );
    }

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;
        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    return (
        <NmPage
            className="fluid-flex-grow"
            typeFilter="vertical"
            header={
                <NmTitle
                    size="xl"
                    count={isShowControls && totalCount}
                >
                    Объявления
                </NmTitle>
            }
            filtersBase={
                <AdvertisementFilter
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                    initFilter={initFilter}
                    submitFilter={submitFilter}
                />
            }
            openFilter={isOpenFilter}
            onOpenFilter={() => setIsOpenFilter(!isOpenFilter)}
            mediaControls={getMediaControls()}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            isLoaded={progress || loading}
        >
            <Task />
            <ByTaskUpdater
                fetch={fetchList}
                taskIds={advertisementTasks}
                dataIds={listFormattedToTaskIds}
            />
            {
                isShowEditModal &&
                <AdvertisementEditModal
                    duplicate={duplicate}
                    isClearCard={true}
                    isEdit={isEdit}
                    clientId={clientId}
                    fetchList={fetchList}
                    onClose={onCloseEditModal}
                />
            }
            {renderConfirmWindow()}
            {renderImportForm()}
            {renderMassConfirm()}
            {renderModalWindowForFiasId()}
            {!list.length ? <NmEmptyPageV2
                isShowAction={isShowActions}
                title="Создавайте, размещайте и управляйте вашими объявлениями на площадке Наймикс"
                description="Для начала работы просто создайте свое первое объявление и сохраните его как черновик или опубликуйте его на нашей площадке для поиска заинтересованных исполнителей"
                isSearch={isSearch}
                textAction="Добавить объявление"
                onClickAction={() => setShowEditModal(true)}
                fetchProgress={progress}
            /> :
                <CheckboxList
                    isShowMassActions={![
                        PROJECT_MANAGER,
                        OBJECT_MANAGER,
                        NM_CHIEF_ACCOUNTANT,
                        NM_CONSULTANT,
                        NM_COORDINATOR,
                        FOREMAN,
                        RECRUITER,
                    ].includes(role)}
                    header={
                        ![
                            NM_CHIEF_ACCOUNTANT,
                            NM_COORDINATOR,
                            FOREMAN,
                            RECRUITER,
                        ].includes(role) &&
                        <SelectionCountWithAction
                            adaptiveLogic
                            count={countSelected}
                            onClick={() => checkPublishPossible(getMassActionRequestData())}
                            buttonColor="green"
                            buttonContent="Опубликовать объявления"
                            otherActions={getOtherActions()}
                        />
                    }
                    actionOptions={getMobileDropdownMassActionOptions(countSelected)}
                    onClickActionItem={onClickMobileDropdownItem}
                    onSelectedRows={![PROJECT_MANAGER, OBJECT_MANAGER, NM_CHIEF_ACCOUNTANT, NM_CONSULTANT, NM_COORDINATOR, FOREMAN].includes(role) && handleSelectedRows}
                    rows={getRows()}
                />}
        </NmPage>
    );
}

export default AdvertisementList;