import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {useContractorAdditionalDocuments} from "../../../../../hooks/useAdditionalDocuments";

import {getInitialTouched} from "../../../../../../../../utils/objectHelper";
import {toastError} from "../../../../../../../../utils/toastHelper";
import {getContractorScanListFiles} from "../../../../../../contractor_scan_list/utils/getFiles";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
    CONTRACTOR_FILE_TYPES,
} from "../../../../../../../../constants/clientList";
import {FILE_ERROR} from "../../../../../../../../constants/messages";
import {maxSizeScanFile} from "../../../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../../../constants/validationsYup";

import {updateDataContractorAdditionalDocument} from "../../../../../../../../ducks/contractorAdditionalDocuments";
import {
    addFileContractorAdditionalScanV2,
    fileContractorOriginalScanObjSelector,
    getContractorAdditionalDocumentFile,
    updateFieldFilesStore,
} from "../../../../../../../../ducks/fileStore";

export const useDataContractorEmploymentHistoryEditForm = ({data, contractorId, onClose}) => {
    const contractorFileType = CONTRACTOR_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN;

    const contractorScanObj = useSelector(fileContractorOriginalScanObjSelector);

    const {
        getAdditionalDocumentAggregation,
        getAdditionalOriginalScan,
        getPrevScan,
    } = useContractorAdditionalDocuments({contractorId});

    const initialValues = {
        employmentHistoryNum: data.employmentHistoryNum  || "",
    };

    const [files, setFiles] = useState(getPrevScan(contractorFileType));
    const [isEditFile, setIsEditFile] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        getAdditionalOriginalScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN);

        return () => {
            dispatch(updateFieldFilesStore({
                contractorOriginalScanObj: {},
            }));
        };
    }, []);

    useEffect(() => {
        setFiles(getPrevScan(contractorFileType));
    }, [contractorScanObj]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const [file] = files;

        if (!file) {
            setErrors({
                file: VALIDATIONS_MESSAGE.REQUIRED,
            });

            return;
        }

        if (file && isEditFile) {
            uploadFile(file);

            return;
        }

        updateData();
    };

    const updateData = () => {
        const requestData = {
            contractorId,
            data: {...values},
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.EMPLOYMENT_HISTORY,
            onSuccess: () => {
                getAdditionalDocumentAggregation();
                onClose();
            },
        };

        dispatch(updateDataContractorAdditionalDocument(requestData));
    };

    const uploadFile = (file) => {
        if (file.size > maxSizeScanFile) {
            toastError(FILE_ERROR.SCAN_SIZE);

            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        dispatch(addFileContractorAdditionalScanV2({
            contractorId,
            contractorFileType,
            file: formData,
            onSuccess: () => {
                dispatch(getContractorAdditionalDocumentFile({
                    contractorId,
                    type: contractorFileType,
                    thumbnail: true,
                }));

                updateData();
            },
        }));
    };

    const onDropScan = (files) => {
        setFiles(files);
        setIsEditFile(true);
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        handleChange,
        setFieldTouched,
        errors,
        setErrors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        initialTouched: getInitialTouched(initialValues),
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        setFieldValue,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
        files,
        onDropScan,
    };
};