import {getPersonalDataStep} from "./getPersonalDataStep";
import {getFullRegistrationSelfieStep} from "./getSelfieStep";
import {getFullRegistrationFields} from "./helpers/getFields";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {
    CONTRACTOR_MIGRATION_STATUS,
    CONTRACTOR_VHI_POLICE_FORMAT,
} from "../../../constants/contractor";
import {
    FULL_REGISTRATION_COMPONENT_TYPE,
    FULL_REGISTRATION_STEP,
} from "../constants";


export function getDocNumber({series, number}) {
    if (!series && !number) {
        return null;
    }

    return `${series ? series : ""} ${number ? number : ""}`.trim();
}

export const getFullRegistrationRequestData = ({values, steps, withoutInn}) => {
    const fields = steps.reduce((accum, step) => {
        // если на шаге нет полей
        if (!step.fields) {
            return accum;
        }

        const fields = getFullRegistrationFields({
            step,
            values: {
                ...values,
                migrationCardPresent: true,
                vhiPolicyPresent: true,
            },
        });

        if ([FULL_REGISTRATION_STEP.MIGRATION_CARD].includes(step.value)) {
            // Если переключатель выключен, надо назначить null полям
            if (!values.migrationCardPresent) {
                // задаем полям null
                const resultFields = fields.reduce((result, fieldName) => {
                    return {
                        ...result,
                        [fieldName]: null,
                    };
                }, {});

                const {
                    migrationCardSeries,
                    migrationCardNumber,
                    ...otherForm
                } = resultFields;

                return {
                    ...accum,
                    ...otherForm,
                    migrationCardNumber: null,
                    migrationCardPresent: false,
                };
            }

            // Переключатель включен
            const resultFields = fields.reduce((result, fieldName) => {
                return {
                    ...result,
                    [fieldName]: values[fieldName],
                };
            }, {});

            const {
                migrationCardSeries,
                migrationCardNumber,
                ...otherForm
            } = resultFields;

            // Необходимо склеить в одно поле серию и номер
            return {
                ...accum,
                ...otherForm,
                migrationCardNumber: getDocNumber({
                    series: values.migrationCardSeries,
                    number: values.migrationCardNumber,
                }),
                migrationCardPresent: true,
            };
        }

        if ([FULL_REGISTRATION_STEP.VHI_POLICY_SCAN].includes(step.value)) {
            // Если переключатель выключен, надо назначить null полям
            if (!values.vhiPolicyPresent) {
                // задаем полям null
                const resultFields = fields.reduce((result, fieldName) => {
                    return {
                        ...result,
                        [fieldName]: null,
                    };
                }, {});

                return {
                    ...accum,
                    ...resultFields,
                    vhiPolicyPresent: false,
                };
            }

            // Переключатель включен
            const resultFields = fields.reduce((result, fieldName) => {
                return {
                    ...result,
                    [fieldName]: values[fieldName],
                };
            }, {});

            return {
                ...accum,
                ...resultFields,
                vhiPolicyPresent: true,
            };
        }

        if ([FULL_REGISTRATION_STEP.RESIDENCE_PERMIT].includes(step.value)) {
            const resultFields = fields.reduce((result, fieldName) => {
                return {
                    ...result,
                    [fieldName]: values[fieldName],
                };
            }, {});

            const {
                residencePermitSeries,
                residencePermitNumber,
                ...otherForm
            } = resultFields;

            // Необходимо склеить в одно поле серию и номер
            return {
                ...accum,
                ...otherForm,
                residencePermitNumber: getDocNumber({
                    series: values.residencePermitSeries,
                    number: values.residencePermitNumber,
                }),
            };
        }

        const resultFields = fields.reduce((result, fieldName) => {
            return {
                ...result,
                [fieldName]: values[fieldName],
            };
        }, {});

        const {
            hasInn,
            ...reqData
        } = resultFields;

        return {
            ...accum,
            ...reqData,
        };
    }, {});

    return {
        ...fields,
        inn: withoutInn ? null : fields.inn,
        migrationStatus: getMigrationStatus(values),
    };
};

const getMigrationStatus = (values) => {
    if (
        [
            citizenshipsDict.RU.value,
            citizenshipsDict.LG.value,
            citizenshipsDict.DN.value,
        ].includes(values.citizenship)
    ) {
        return CONTRACTOR_MIGRATION_STATUS.NONE;
    }

    return values.migrationStatus;
};

const selfieSteps = [
    CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE,
    CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE,
    CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE,
    CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
];

const migrationCardScans = [
    CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD,
    CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD,
];

const personalDataScans = [
    CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD,
    CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK,
    CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA,
    CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD,
    CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO,
    CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
    CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
    CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
    CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE,
];

export const getFullRegistrationRequestDataFiles = (params) => {
    const {
        values,
        isRequiredFields,
        migrationStatus,
        citizenship,
        locatedOutsideRussia,
    } = params;

    // Список типов файлов, добавленных на форме
    let addedToFormFileTypes = Object.values(CONTRACTOR_FILE_TYPES).filter(fileType => values[fileType]);

    const isSomePersonalDataFileExists = addedToFormFileTypes.some(fileType => personalDataScans.includes(fileType));

    if (isSomePersonalDataFileExists) {
        const personalDataStep = getPersonalDataStep({
            migrationStatus,
            citizenship,
            isRequiredFields,
        });

        const fields = personalDataStep.fields(values);
        // Текущие прикрепленные сканы, которые необходимо сохранить
        const scans = fields
            .filter((item) => item.type === FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE)
            .map(item => item.name);

        // Список файлов для исключения
        const selfieWhichNotLoad = personalDataScans.filter(fileType => !scans.includes(fileType));

        addedToFormFileTypes = addedToFormFileTypes.filter(fileType => !selfieWhichNotLoad.includes(fileType));
    }

    // Прикреплен ли к форме хотя бы один документ миграционной карты
    const isSomeMigrationCardFileExists = addedToFormFileTypes.some(fileType => migrationCardScans.includes(fileType));

    // Зачищаем миграционную карту при наличии прикрепленного файла и выключенном переключателе
    if (!values.migrationCardPresent && isSomeMigrationCardFileExists) {
        addedToFormFileTypes = addedToFormFileTypes.filter(fileType => !migrationCardScans.includes(fileType));
    }

    // Прикреплен ли к форме хотя бы один документ селфи
    const isSomeSelfieFileExists = addedToFormFileTypes.some(fileType => selfieSteps.includes(fileType));

    if (isSomeSelfieFileExists) {
        const selfieStep = getFullRegistrationSelfieStep({
            migrationStatus,
            citizenship,
            isRequiredFields,
            locatedOutsideRussia,
        });
        // Текущий прикрепленный тип селфи на форме, который необходимо сохранить
        const [{name: selfieFileType}] = selfieStep.fields({});
        // Список файлов для исключения
        const selfieWhichNotLoad = selfieSteps.filter(fileType => selfieFileType !== fileType);

        addedToFormFileTypes = addedToFormFileTypes.filter(fileType => !selfieWhichNotLoad.includes(fileType));
    }

    if (!values.vhiPolicyPresent) {
        addedToFormFileTypes = addedToFormFileTypes.filter(fileType =>
            ![
                CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN,
                CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE,
                CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE,
            ].includes(fileType),
        );
    }

    if (values.vhiPolicyPresent && values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC) {
        addedToFormFileTypes = addedToFormFileTypes.filter(fileType =>
            ![CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN].includes(fileType),
        );
    }

    if (values.vhiPolicyPresent && values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PAPER) {
        addedToFormFileTypes = addedToFormFileTypes.filter(fileType =>
            ![
                CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE,
                CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE,
            ].includes(fileType),
        );
    }

    return addedToFormFileTypes.filter(contractorFileType => {
        const [file] = values[contractorFileType];

        return Boolean(file);
    });
};