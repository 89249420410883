import React from "react";

import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";

const ContractorAdditionalDocumentCheckingErrorModal = (props) => {
    const {
        onClickCancel,
        onClose,
        content,
    } = props;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    mobile="column"
                    onClose={onClickCancel}
                    align="center"
                    cancelBtnContent="Выйти из проверки"
                />
            }
            className="text-center"
            onClose={onClose}
        >
            {content}
        </NmModal>
    );
};

export default ContractorAdditionalDocumentCheckingErrorModal;