import {all, put} from "@redux-saga/core/effects";
import {takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/contractors-registry/contractor/finance/income-ndfl";

//*  TYPES  *//

const CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_REQUEST = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_REQUEST";
const CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_SUCCESS = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_ERROR = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_ERROR";

const CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_REQUEST = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_REQUEST";
const CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_SUCCESS = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_ERROR = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_ERROR";

const CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_REQUEST = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_REQUEST";
const CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_SUCCESS = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_ERROR = "CONTRACTORS_REGISTRY_CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_ERROR";

const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_REQUEST = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_REQUEST";
const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_SUCCESS = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_SUCCESS";
const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_ERROR = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_ERROR";

const CONTRACTOR_FINANCE_INCOME_NDFL_UPDATE_STORE = "CONTRACTOR_FINANCE_INCOME_NDFL_UPDATE_STORE";

//*  INITIAL STATE  *//
const initial = {
    list: [],
    totalCount: 0,
    progress: false,
    pageData: {},
    contractorBalance: {},
};

//*  REDUCER  *//
export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_REQUEST:
    case CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_REQUEST:
    case CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_SUCCESS:
    case CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_SUCCESS:
    case CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_SUCCESS:
        return {
            ...state,
            list: payload.results,
            progress: false,
            totalCount: payload.totalCount,
        };
    case CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_ERROR:
    case CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_ERROR:
    case CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_SUCCESS:
        return {
            ...state,
            contractorBalance: payload,
        };
    case CONTRACTOR_FINANCE_INCOME_NDFL_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorInProgressFinanceNdflPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_REQUEST,
        payload,
    };
}

export function getContractorAwaitingFinanceNdflPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_REQUEST,
        payload,
    };
}

export function getContractorCompletedFinanceNdflPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_REQUEST,
        payload,
    };
}

export function getSummaryContractorFinanceIncomeNdfl(payload) {
    return {
        type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_REQUEST,
        payload,
    };
}

export function updateContractorFinanceIncomeNdflStore(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_NDFL_UPDATE_STORE,
        payload,
    };
}

//*  SELECTORS  *//
export const contractorFinanceIncomeNdflSelector = state => state.contractorFinanceIncomeNdfl;
export const contractorFinanceIncomeNdflProgressSelector = createSelector(contractorFinanceIncomeNdflSelector, ({progress}) => progress);
export const contractorFinanceIncomeNdflListSelector = createSelector(contractorFinanceIncomeNdflSelector, ({list}) => list);
export const contractorFinanceIncomeNdflTotalCountSelector = createSelector(contractorFinanceIncomeNdflSelector, ({totalCount}) => totalCount);
export const contractorFinanceIncomeNdflTotalPagesSelector = createSelector(contractorFinanceIncomeNdflSelector, ({totalCount, pageData: {pageSize = 0}}) => {
    return getTotalPages(totalCount, pageSize);
});
export const contractorSummaryFinanceIncomeNdflSelector = createSelector(contractorFinanceIncomeNdflSelector, ({contractorBalance}) => contractorBalance);

//POST bff/adm/contractors-registry/contractor/finance/income-ndfl/in-progress/page
export const getContractorInProgressFinanceNdflPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/in-progress/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/contractors-registry/contractor/finance/income-ndfl/awaiting/page
export const getContractorAwaitingFinanceNdflPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/awaiting/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/contractors-registry/contractor/finance/income-ndfl/completed/page
export const getContractorCompletedFinanceNdflPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/completed/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/contractors-registry/contractor/finance/income-ndfl/getSummary/{contractorId}
export const getSummaryContractorFinanceIncomeNdflSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getSummary/${contractorId}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_FINANCE_INCOME_NDFL_IN_PROGRESS_REQUEST, getContractorInProgressFinanceNdflPaymentsSaga),
        takeEvery(CONTRACTOR_FINANCE_INCOME_NDFL_AWAITING_PROGRESS_REQUEST, getContractorAwaitingFinanceNdflPaymentsSaga),
        takeEvery(CONTRACTOR_FINANCE_INCOME_NDFL_COMPLETED_REQUEST, getContractorCompletedFinanceNdflPaymentsSaga),
        takeEvery(GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_NDFL_REQUEST, getSummaryContractorFinanceIncomeNdflSaga),
    ]);
}
