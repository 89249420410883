import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/ordercontracts";

const TERMINATE_FRAME_CONTRACT_REQUEST = "TERMINATE_FRAME_CONTRACT_REQUEST";
const TERMINATE_FRAME_CONTRACT_SUCCESS = "TERMINATE_FRAME_CONTRACT_SUCCESS";
const TERMINATE_FRAME_CONTRACT_ERROR = "TERMINATE_FRAME_CONTRACT_ERROR";

const RESIDENT_VALIDATION_REQUEST = "RESIDENT_VALIDATION_REQUEST";
const RESIDENT_VALIDATION_SUCCESS = "RESIDENT_VALIDATION_SUCCESS";
const RESIDENT_VALIDATION_ERROR = "RESIDENT_VALIDATION_ERROR";

const initialState = {
    progress: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case RESIDENT_VALIDATION_REQUEST:
    case TERMINATE_FRAME_CONTRACT_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case RESIDENT_VALIDATION_SUCCESS:
    case TERMINATE_FRAME_CONTRACT_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case RESIDENT_VALIDATION_ERROR:
    case TERMINATE_FRAME_CONTRACT_ERROR:
        return {
            ...state,
            progress: false,
        };
    default:
        return state;
    }
};

export const terminateFrameContract = payload => ({
    type: TERMINATE_FRAME_CONTRACT_REQUEST,
    payload,
});

export const validateContractorForContractAction = payload => ({
    type: RESIDENT_VALIDATION_REQUEST,
    payload,
});

const orderContractsSelector = ({orderContracts}) => orderContracts;

export const orderContractsProgressSelector = createSelector(orderContractsSelector, ({progress}) => progress);

function* terminateFrameContractSaga({payload}) {
    try {
        const {
            data: {
                documentId,
            },
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/frameContract/${documentId}/terminate`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TERMINATE_FRAME_CONTRACT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Договор успешно расторгнут");

        yield put({
            type: TERMINATE_FRAME_CONTRACT_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess(result);
    } catch (error) {
        yield put({
            type: TERMINATE_FRAME_CONTRACT_ERROR,
        });
    }
}

function* validateContractorForContractActionSaga({payload}) {
    try {
        const {
            data: {
                documentId,
            },
            onSuccess,
            onNeedShowWarning,
        } = payload;

        const response = yield request.get(`${controller}/isFrameContractTerminateWithNonResident?documentId=${documentId}`, {});

        const {
            errorMessage,
            result,
        } = response;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RESIDENT_VALIDATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (result) {
            onNeedShowWarning && onNeedShowWarning();
        } else {
            onSuccess && onSuccess();
        }

        yield put({
            type: RESIDENT_VALIDATION_SUCCESS,
            payload: result,
        });


    } catch (error) {
        yield put({
            type: RESIDENT_VALIDATION_ERROR,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(TERMINATE_FRAME_CONTRACT_REQUEST, terminateFrameContractSaga),
        takeEvery(RESIDENT_VALIDATION_REQUEST, validateContractorForContractActionSaga),
    ]);
}