import React from "react";
import {useSelector} from "react-redux";

import NmDadataBank from "../../../../../../../components/ActualComponents/NmDadataBank";
import NmDadataInput from "../../../../../../../components/ActualComponents/NmDadataInput";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import LabelTextApp from "../../../../../../../components/LabelTextApp";
import NmTitle from "../../../../../../../components/NmTitle";
import {ReactComponent as InfoIcon} from "../../../../../../../images/info_24.svg";

import {usePaymentDataInfoEditForm} from "./hooks/useData";

import {formatBankCardV2} from "../../../../../../../utils/stringFormat";

import {COLOR} from "../../../../../../../constants/color";
import {CONTRACTOR_FIELD} from "../../../../../../../constants/contractor";

import {
    contractorProgressSelector,
    getContractorPaymentDataSelector,
} from "../../../../../../../ducks/contractor";

const PaymentDataInfoEditForm = (props) => {
    const {
        onClose = () => {
        },
        fetchContractorInfo = () => {
        },
        contractorId,
        isIndividualEntrepreneurForm = false,
    } = props;

    const progress = useSelector(contractorProgressSelector);
    const paymentData = useSelector(getContractorPaymentDataSelector);

    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
        handleChangeAddress,
    } = usePaymentDataInfoEditForm({
        contractorId,
        onClose,
        isIndividualEntrepreneurForm,
        fetchContractorInfo,
    });

    const renderWarning = () => {
        return (
            values.bankRequisitesRequired &&
            <Text
                className="flex flex-aligned-center mt-2 mb-2"
                level="2"
                color={COLOR.SECONDARY_75}
            >
                <InfoIcon
                    color={COLOR.SECONDARY_45}
                    height={16}
                    width={16}
                />
                Внимание! Реквизиты обязательны для заполнения
            </Text>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {
                        isIndividualEntrepreneurForm ?
                            "Редактирование реквизитов для получения платежей ИП" :
                            "Платежные данные"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                {
                    !isIndividualEntrepreneurForm &&
                    <LabelTextApp
                        label="Номер банковской карты"
                        className="col-md-16"
                        text={formatBankCardV2(paymentData[CONTRACTOR_FIELD.BANK_CARD_NUMBER])}
                    />
                }
                {renderWarning()}
                <NmInputV2
                    required={values.bankRequisitesRequired}
                    size="xl"
                    className="col-md-16"
                    mask="99999999999999999999"
                    label="Номер расчетного счета"
                    name={CONTRACTOR_FIELD.RS}
                    value={values[CONTRACTOR_FIELD.RS]}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.RS, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.RS] &&
                        errors?.[CONTRACTOR_FIELD.RS]
                    }
                />
                <NmDadataBank
                    size="xl"
                    name="bankInfo"
                    label="Поиск: наименование, адрес, ИНН или БИК банка"
                    onChange={handleChange}
                    className="fluid"
                    isClearable
                    count={10}
                />
                <NmInputV2
                    required={values.bankRequisitesRequired}
                    size="xl"
                    className="col-md-16"
                    label="Наименование банка"
                    name={CONTRACTOR_FIELD.BANK_NAME}
                    value={values[CONTRACTOR_FIELD.BANK_NAME]}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.BANK_NAME, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.BANK_NAME] &&
                        errors?.[CONTRACTOR_FIELD.BANK_NAME]
                    }
                />
                <NmInputV2
                    required={values.bankRequisitesRequired}
                    size="xl"
                    className="col-md-16"
                    mask="9999999999"
                    label="ИНН"
                    name={CONTRACTOR_FIELD.INN}
                    value={values[CONTRACTOR_FIELD.INN]}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.INN, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.INN] &&
                        errors?.[CONTRACTOR_FIELD.INN]
                    }
                />
                <NmInputV2
                    required={values.bankRequisitesRequired}
                    size="xl"
                    className="col-md-16"
                    mask="999999999"
                    label="БИК"
                    name={CONTRACTOR_FIELD.BIC}
                    value={values[CONTRACTOR_FIELD.BIC]}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.BIC, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.BIC] &&
                        errors?.[CONTRACTOR_FIELD.BIC]
                    }
                />
                <NmDadataInput
                    required={values.bankRequisitesRequired}
                    size="xl"
                    className="col-md-16"
                    label="Адрес банка"
                    name={CONTRACTOR_FIELD.BANK_ADDRESS}
                    query={values[CONTRACTOR_FIELD.BANK_ADDRESS]}
                    onChange={handleChangeAddress}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.BANK_ADDRESS, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.BANK_ADDRESS] &&
                        errors?.[CONTRACTOR_FIELD.BANK_ADDRESS]
                    }
                />
                <NmInputV2
                    required={values.bankRequisitesRequired}
                    size="xl"
                    className="col-md-16"
                    mask="99999999999999999999"
                    label="Корр. счет"
                    name={CONTRACTOR_FIELD.KS}
                    value={values[CONTRACTOR_FIELD.KS]}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.KS, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.KS] &&
                        errors?.[CONTRACTOR_FIELD.KS]
                    }
                />
            </NmForm>
        </NmModal>
    );
};

export default PaymentDataInfoEditForm;