import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/crowd/actregistryentry";

//POST /bff/adm/crowd/actregistryentry/page
const getPageCrowdActRegistryEntry = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/page/short/validation/failed
// Список строк реестра
const getPageErrorsCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/page/short/validation/failed`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/create
const createCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/create`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/edit
const editCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/edit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR,
        });
    }
};

//GET /bff/adm/crowd/actregistryentry/{id}
const getByIdCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            entryId,
        } = payload;

        const result = yield request.bff.get(`${controller}/${entryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR,
        });
    }
};

//DELETE /bff/adm/crowd/actregistryentry/{id}
const deleteCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            entryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/${entryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/mass/delete
const massDeleteCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/mass/delete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/check/duplicates
// Проверка дубликатов строк
const checkDuplicateCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/check/duplicates`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/validation/for-registry-run
// Валидация строк перед запуском реестра
const validationCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/validation/for-registry-run`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryentry/repeat
// Повтор строки
const repeatCrowdActRegistryEntry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/repeat`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST, getPageCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST, createCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST, editCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST, getByIdCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST, deleteCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST, massDeleteCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST, checkDuplicateCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST, getPageErrorsCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST, validationCrowdActRegistryEntry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST, repeatCrowdActRegistryEntry),
    ]);
}