import React, {Component} from "react";

import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import Search from "../Search";
import {Grid} from "semantic-ui-react";

import {
    formatAmount,
    formatNumber,
} from "../../utils/stringFormat";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import PropTypes from "prop-types";

import "./style.sass";

class TableDiv extends Component {
    static propTypes = {
        className: PropTypes.string,
        isAllSelected: PropTypes.bool,
        tableData: PropTypes.object,
        isCheckBox: PropTypes.bool,
        handleChangeSearch: PropTypes.func,
        onSelectedRows: PropTypes.func,
        onClickRow: PropTypes.func,
        pageSize: PropTypes.number,
        isOverflowX: PropTypes.bool,
        // className для крайней строки в списке, для корректной работы надо отправить pageSize
        lastRowClassName: PropTypes.string,
    };

    static defaultProps = {
        isAllSelected: false,
        lastRowClassName: "",
        className: "",
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    get allCheckboxValue() {
        const {isCheckBox} = this.props;

        if (!isCheckBox) {
            return false;
        }

        const {tableData: {rows}} = this.props;

        const activeCheckboxCount = rows.filter(value => value.showCheckBox && !value.disabledCheckBox).length;

        return activeCheckboxCount && activeCheckboxCount === rows.filter(value => value.isSelected).length;
    }

    onSelectRow = (e, checked, index) => {
        e.preventDefault();

        const {onSelectedRows, tableData} = this.props;
        const {rows} = tableData;

        const _rows = [...rows];
        _rows[index]["isSelected"] = checked;

        return onSelectedRows(_rows);
    };

    onSelectAllRows = (e, {checked}) => {
        e.stopPropagation();
        const {onSelectedRows, tableData} = this.props;
        const {rows} = tableData;

        const _rows = rows.map(item => ({
            ...item,
            isSelected: item.showCheckBox !== undefined && item.showCheckBox && checked && !item.disabledCheckBox,
        }
        ));

        return onSelectedRows(_rows, checked);
    };

    get disabledHeaderCheckbox() {
        const {tableData} = this.props;
        const {rows = []} = tableData;

        const length = rows.filter(value => value.disabledCheckBox).length;

        return length === rows.length;
    }

    renderHeaders() {
        const {
            tableData,
            isCheckBox,
            isAllSelected,
            handleChangeSearch,
        } = this.props;
        const {headers} = tableData;

        return (
            <Grid.Row
                className="nmx-grid-table__header"
            >
                {isCheckBox &&
                    <Grid.Column>
                        <NmCheckboxV2
                            onChange={this.onSelectAllRows}
                            disabled={this.disabledHeaderCheckbox}
                            checked={this.allCheckboxValue || isAllSelected}
                        />
                    </Grid.Column>
                }
                {headers.map(({key, content, align, suggestion, isSort, typeSort, children}) => {
                    return (
                        <Grid.Column
                            className={align ? `nmx-grid-table__column_${align}` : ""}
                            key={`header-${key}`}
                        >
                            {suggestion ?
                                <>
                                    <span>
                                        {content}
                                    </span>
                                    <HelpTooltip
                                        width={24}
                                        height={24}
                                        hover
                                        text={suggestion}
                                        className="nmx-grid-table__tooltip"
                                    />
                                </>
                                : content
                            }
                            {children}
                            {isSort &&
                                <Search
                                    isSort={isSort}
                                    sort={typeSort}
                                    handleChangeSearch={(value) => {
                                        handleChangeSearch(key, value);
                                    }}
                                />
                            }
                        </Grid.Column>
                    );
                })}
            </Grid.Row>
        );
    }

    renderRows() {
        const {
            tableData: {
                rows,
            },
            onClickRow,
            isCheckBox,
            pageSize,
            lastRowClassName,
        } = this.props;

        return rows.map((cell, index) => {
            const {
                disabledCheckBox = false,
                disabledRow = false,
                rowMessage = "",
                rowClass = "",
            } = cell;

            const suggestionModifyClass = index + 1 === pageSize ? `nmx-grid-table__row_last ${lastRowClassName}` : "";

            return (
                <Grid.Row
                    onClick={(e) => {
                        if (e.target.classList.contains("column") && onClickRow) {
                            onClickRow(cell);
                        }
                    }}
                    className={`nmx-grid-table__row ${rowClass} ${suggestionModifyClass}`}
                    key={index}
                >
                    {isCheckBox ?
                        <Grid.Column key="cell-checkbox">
                            {cell.showCheckBox ? <NmCheckboxV2
                                onChange={(e, {checked}) => {
                                    this.onSelectRow(e, checked, index);
                                }}
                                disabled={disabledCheckBox}
                                //defaultChecked={cell.isSelected}
                                checked={cell.isSelected ? cell.isSelected : false}
                            /> : null}
                        </Grid.Column> :
                        null
                    }
                    {this.renderColumns(cell)}
                    {disabledRow ?
                        <div className="nmx-grid-table__row-layer nmx-grid-table__row-layer_disabled">
                            {rowMessage && <div className="nmx-grid-table__row-layer-message">
                                {rowMessage}
                            </div>}
                        </div> :
                        null
                    }
                    {rowMessage && !disabledRow ?
                        <div className="nmx-grid-table__row-layer">
                            <div className="nmx-grid-table__row-layer-message">
                                {rowMessage}
                            </div>
                        </div> :
                        null
                    }
                </Grid.Row>
            );
        });
    }

    renderColumns(cell) {
        const {tableData} = this.props;
        const {headers} = tableData;

        return headers.map(({key, isRurField, align}) => {
            const alignClass = isNullOrWhitespace(align) ? "" : `nmx-grid-table__column_${align}`;

            return (
                <Grid.Column
                    className={`nmx-grid-table__column ${alignClass}`}
                    key={`header-${key}`}
                >
                    {isRurField ? formatAmount(formatNumber(cell[key], 2)) : cell[key]}
                </Grid.Column>
            );
        });
    }

    get classNameGrid() {
        const {isCheckBox, isOverflowX} = this.props;
        const classNames = [
            isOverflowX && "grid-checkbox_overflowX",
            isCheckBox && "grid-checkbox",
        ];

        return classNames.filter(Boolean).join(" ");
    }

    get tableClassNames() {
        const {
            className,
            isOverflowX,
        } = this.props;

        return [
            "nmx-grid-table",
            className,
            isOverflowX && "nmx-grid-table_scrollable-container",
        ].join(" ");
    }

    render() {
        const {
            tableData: {headers},
        } = this.props;

        return (
            <div className={this.tableClassNames}>
                <Grid
                    className={this.classNameGrid}
                    columns={headers.length}
                >
                    {this.renderHeaders()}
                    {this.renderRows()}
                </Grid>
            </div>
        );
    }
}

export default TableDiv;