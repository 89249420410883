import {
    INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST,
    INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_REQUEST,
    INTERNAL_PAYMENTS_TKB_PAY_REQUEST,
    INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST,
    INTERNAL_PAYMENTS_UPDATE_STORE,
} from "./actions";

export function createTkbPayment(payload) {
    return {
        type: INTERNAL_PAYMENTS_TKB_PAY_REQUEST,
        payload,
    };
}

export function getTkbPaymentsLogs(payload) {
    return {
        type: INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST,
        payload,
    };
}

export function getTkbPaymentsStatusBatch(payload) {
    return {
        type: INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST,
        payload,
    };
}

export function getTkbPaymentsAllowedEmails(payload) {
    return {
        type: INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_REQUEST,
        payload,
    };
}

export function updateInternalPaymentsStore(payload) {
    return {
        type: INTERNAL_PAYMENTS_UPDATE_STORE,
        payload,
    };
}