import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const bffRecruitmentAccessControlJobBoardsSelector = state => state.bff.recruitment.accessControl.jobBoards;

export const bffRecruitmentAccessControlJobBoardsListSelector = createSelector(
    bffRecruitmentAccessControlJobBoardsSelector,
    ({list}) => list,
);
export const bffRecruitmentAccessControlJobBoardsListLoadingSelector = createSelector(
    bffRecruitmentAccessControlJobBoardsSelector,
    ({listLoading}) => listLoading,
);
export const bffRecruitmentAccessControlJobBoardsListTotalCountSelector = createSelector(
    bffRecruitmentAccessControlJobBoardsSelector,
    ({listTotalCount}) => listTotalCount,
);

export const bffRecruitmentAccessControlJobBoardsListTotalPagesSelector = createSelector(
    bffRecruitmentAccessControlJobBoardsSelector,
    ({listTotalCount, listPageData: {pageSize = 0}}) => getTotalPages(listTotalCount, pageSize),
);
export const bffRecruitmentAccessControlJobBoardsListOptionsSelector = createSelector(
    bffRecruitmentAccessControlJobBoardsSelector,
    ({list}) => list.map(item => {
        return {
            key: item.jobBoardAccountId,
            value: item.jobBoardAccountId,
            text: `${item.clientUserName} ${item.login}`,
        };
    }),
);