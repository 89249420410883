export const POSTFIX_PROMOTION = {
    NOT_POSTFIX: {
        VALUE: "NOT_POSTFIX",
        TEXT: "Без постфикса",
    },
    POSTFIX: {
        VALUE: "POSTFIX",
        TEXT: "C последовательным постфиксом",
    },
    RANDOM_POSTFIX: {
        VALUE: "RANDOM_POSTFIX",
        TEXT: "C произвольным постфиксом",
    },
};

export const TYPE_PROMOTION = {
    SINGLE: {
        VALUE: "SINGLE",
        TEXT: "Одноразовое",
    },
    MULTIPLE: {
        VALUE: "MULTIPLE",
        TEXT: "Многоразовое",
    },
};

export const TYPE_INFO = {
    CONTRACTOR: "contractor",
    CLIENT: "client",
};

export const PROMOCODE_TYPE = {
    CLIENTS: "CLIENTS",
    CONTRACTORS: "CONTRACTORS",
};
