export const BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST = "BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_VACANCY_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR = "BFF_RECRUITMENT_VACANCY_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST = "BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST";
export const BFF_RECRUITMENT_VACANCY_GET_BY_ID_SUCCESS = "BFF_RECRUITMENT_VACANCY_GET_BY_ID_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR = "BFF_RECRUITMENT_VACANCY_GET_BY_ID_ERROR";

export const BFF_RECRUITMENT_VACANCY_ADD_REQUEST = "BFF_RECRUITMENT_VACANCY_ADD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_ADD_SUCCESS = "BFF_RECRUITMENT_VACANCY_ADD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_ADD_ERROR = "BFF_RECRUITMENT_VACANCY_ADD_ERROR";

export const BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST = "BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_UPDATE_SUCCESS = "BFF_RECRUITMENT_VACANCY_UPDATE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_UPDATE_ERROR = "BFF_RECRUITMENT_VACANCY_UPDATE_ERROR";

export const BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST = "BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST";
export const BFF_RECRUITMENT_VACANCY_PUBLISH_SUCCESS = "BFF_RECRUITMENT_VACANCY_PUBLISH_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR = "BFF_RECRUITMENT_VACANCY_PUBLISH_ERROR";

export const BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST = "BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_ARCHIVE_SUCCESS = "BFF_RECRUITMENT_VACANCY_ARCHIVE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR = "BFF_RECRUITMENT_VACANCY_ARCHIVE_ERROR";

export const BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST = "BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_UNARCHIVE_SUCCESS = "BFF_RECRUITMENT_VACANCY_UNARCHIVE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR = "BFF_RECRUITMENT_VACANCY_UNARCHIVE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST = "BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CLOSE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CLOSE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CLOSE_ERROR = "BFF_RECRUITMENT_VACANCY_CLOSE_ERROR";

export const BFF_RECRUITMENT_VACANCY_DELETE_REQUEST = "BFF_RECRUITMENT_VACANCY_DELETE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_DELETE_SUCCESS = "BFF_RECRUITMENT_VACANCY_DELETE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_DELETE_ERROR = "BFF_RECRUITMENT_VACANCY_DELETE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST = "BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANCEL_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANCEL_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANCEL_ERROR = "BFF_RECRUITMENT_VACANCY_CANCEL_ERROR";

export const BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST = "BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_BATCH_DELETE_SUCCESS = "BFF_RECRUITMENT_VACANCY_BATCH_DELETE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR = "BFF_RECRUITMENT_VACANCY_BATCH_DELETE_ERROR";

export const BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST = "BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST";
export const BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_SUCCESS = "BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR = "BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_ERROR";

export const BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST = "BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_SUCCESS = "BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR = "BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_ERROR";

export const BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST = "BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST";
export const BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_SUCCESS = "BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR = "BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_ERROR";

export const BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST = "BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_SUCCESS = "BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR = "BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_VACANCY_HIDE_REQUEST = "BFF_RECRUITMENT_VACANCY_HIDE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_HIDE_SUCCESS = "BFF_RECRUITMENT_VACANCY_HIDE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_HIDE_ERROR = "BFF_RECRUITMENT_VACANCY_HIDE_ERROR";

export const BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST = "BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_SUCCESS = "BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR = "BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_ERROR";

export const BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST = "BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_SUCCESS = "BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR = "BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_ERROR";

export const BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST = "BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_SUCCESS = "BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR = "BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_ERROR";

export const BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST = "BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_SUCCESS = "BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR = "BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_ERROR";

export const BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST = "BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_EXPORT_SUCCESS = "BFF_RECRUITMENT_VACANCY_EXPORT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_EXPORT_ERROR = "BFF_RECRUITMENT_VACANCY_EXPORT_ERROR";

export const BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST = "BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_SUCCESS = "BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR = "BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_ERROR";

export const BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST = "BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_SUCCESS = "BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR = "BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_ERROR";

export const BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST = "BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST";
export const BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_SUCCESS = "BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR = "BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_ERROR";

export const BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST = "BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST";
export const BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_SUCCESS = "BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR = "BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_ERROR";

export const BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST = "BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_SUCCESS = "BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR = "BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_ERROR";

export const BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST = "BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_SUCCESS = "BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR = "BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_ERROR";

export const BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST = "BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_SUCCESS = "BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_ERROR = "BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_ERROR";

export const BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_REQUEST = "BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_SUCCESS = "BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_ERROR = "BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_ERROR";

export const BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_REQUEST = "BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_REQUEST";
export const BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_SUCCESS = "BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR = "BFF_RECRUITMENT_VACANCY_ADD_AVITO_LOGO_ERROR";

export const BFF_RECRUITMENT_VACANCY_UPDATE_STORE = "BFF_RECRUITMENT_VACANCY_UPDATE_STORE";