import * as yup from "yup";

import {removePhoneMask} from "../../../../../../utils/stringFormat";
import {validatePhone} from "../../../../../../utils/validate";

import {CONTRACTOR_SEARCH_TYPE} from "../../../../../../constants/registry";
import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        phoneNumber: yup.string()
            .nullable()
            .when("searchType", {
                is: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .transform(removePhoneMask)
                    .test("checkPhone", "Номер телефона должен состоять из 10-15 цифр", validatePhone),
            }),
        fullName: yup.string()
            .nullable()
            .when("searchType", {
                is: CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(150, "Не более 150 символов")
                    .min(5, "Минимальная длина строки - 5 символов"),
            }),
        orderNum: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};

export default validationSchema;