import {useState} from "react";

export const useTemplatesClientUsageSort = () => {
    const [orderType, setOrderType] = useState("USING_TEMPLATE_FIRST");

    const onClickSort = (params) => {
        const {value} = params;

        setOrderType(value);
    };

    return {
        orderType,
        onClickSort,
    };
};