import React from "react";
import {withTranslation} from "react-i18next";

import {usePrivacyPolicyStrapi} from "../../../hooks/usePrivacyPolicyStrapi";
import {useRightsStatusStrapi} from "../../../hooks/useRightsStatusStrapi";
import {useTermOfUseDocumentStrapi} from "../../../hooks/useTermOfUseDocumentStrapi";

import bem from "../../../utils/bem";
import {isPromoPoiskPage} from "../../../utils/url";

import "./style.sass";

const LoginFooter = ({t}) => {
    const [block, element] = bem("footer");
    const {url: PRIVACY_POLICY, fileName} = usePrivacyPolicyStrapi();
    const {url: TERM_OF_DOCUMENT_URL, fileName: TERM_OF_DOCUMENT_FILE_NAME} = useTermOfUseDocumentStrapi();
    const {text: rightStatusText} = useRightsStatusStrapi();

    return (
        <footer className={block({promoPoisk: isPromoPoiskPage()})}>
            <div className={element("container")}>
                <div className={element("copyright")}>
                    {rightStatusText}
                </div>
                <div className={element("links")}>
                    <a
                        className={element("privacy")}
                        download={fileName}
                        href={PRIVACY_POLICY}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("login.privacy-policy")}
                    </a>
                    <a
                        className={element("agreement")}
                        download={TERM_OF_DOCUMENT_FILE_NAME}
                        href={TERM_OF_DOCUMENT_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("login.user-agreement")}
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default withTranslation()(LoginFooter);