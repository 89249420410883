import React from "react";

import "./style.sass";

const IconBlacklist = (props) => {
    const {className = ""} = props;
    return (
        <div className={`blacklist-icon ${className}`} />
    );
};

export default IconBlacklist;