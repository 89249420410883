import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import formatDate from "../../../../utils/dateFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

const useFinanceCanceledChecksFilter = (props) => {
    const {
        setPagination,
        pageSize,
        urlClientId,
    } = props;

    const initFilter = {
        fromDateCancelledFilter: null,
        toDateCancelledFilter: null,
        clientIdFilter: null,
        projectIdsFilter: [],
        objectIdsFilter: [],
        contractorFioFilter: "",
        paymentNumberFilter: "",
        taskPaymentNumberFilter: "",
        orderNameFilter: "",
        orderNumFilter: "",
        taskNameFilter: "",
        taskNumFilter: "",
    };

    function mapFilterDto(filter) {
        const {
            clientIdFilter,
            fromDateCancelledFilter,
            toDateCancelledFilter,
            projectIdsFilter,
            objectIdsFilter,
            orderNumFilter,
            taskNumFilter,
            contractorFioFilter,
            orderNameFilter,
            paymentNumberFilter,
            taskPaymentNumberFilter,
            taskNameFilter,
        } = filter;

        return {
            clientIdFilter: urlClientId || handleFormString(clientIdFilter),
            fromDateCancelledFilter: fromDateCancelledFilter ? formatDate(fromDateCancelledFilter, "yyyy-MM-dd") : undefined,
            toDateCancelledFilter: toDateCancelledFilter ? formatDate(toDateCancelledFilter, "yyyy-MM-dd") : undefined,
            projectIdsFilter: projectIdsFilter?.length ? projectIdsFilter : undefined,
            objectIdsFilter: objectIdsFilter?.length ? objectIdsFilter : undefined,
            contractorFioFilter: handleFormString(contractorFioFilter),
            orderNameFilter: handleFormString(orderNameFilter),
            taskNameFilter: handleFormString(taskNameFilter),
            paymentNumberFilter: handleNumber(paymentNumberFilter),
            taskPaymentNumberFilter: handleNumber(taskPaymentNumberFilter),
            orderNumFilter: handleNumber(orderNumFilter),
            taskNumFilter: handleNumber(taskNumFilter),
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        setFilter,
    } = useFilter({
        initFilter,
        setPagination,
        pageSize,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            !urlClientId && {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        name: "clientIdFilter",
                        isClearable: true,
                        label: "Заказчик",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorFioFilter",
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер выплаты",
                        maskChar: null,
                        mask: "999999999999",
                        name: "paymentNumberFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер оплаты по заданию",
                        maskChar: null,
                        mask: "999999999999",
                        name: "taskPaymentNumberFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер заказа",
                        maskChar: null,
                        mask: "999999999999",
                        name: "orderNumFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер задания",
                        maskChar: null,
                        mask: "999999999999",
                        name: "taskNumFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "orderNameFilter",
                        label: "Название заказа",
                        placeholder: "Введите название заказа",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNameFilter",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PROJECT,
                        name: "projectIdsFilter",
                        clientId: urlClientId || filter.clientIdFilter,
                        isActiveStyle: false,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIdsFilter",
                        clientId: urlClientId || filter.clientIdFilter,
                        isActiveStyle: false,
                        projectIdsFilter: filter.projectIdsFilter,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "fromDateCancelledFilter",
                        endFieldName: "toDateCancelledFilter",
                        isClearable: true,
                        label: "Период аннулирования",
                    },
                ],
            },
        ].filter(item => Boolean(item));
    }, [
        urlClientId,
        filter,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        filters,
        setFilter,
    };
};

export default useFinanceCanceledChecksFilter;