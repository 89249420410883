export const getRecruitmentCandidateOtherRequestData = (params) => {
    const {
        anyInformation,
        values,
        index: informationIndex,
        isEdit,
    } = params;

    if (!isEdit) {
        return [
            ...anyInformation,
            values,
        ];
    }

    return anyInformation.map((item, index) => {
        if (index === informationIndex) {
            return {
                ...values,
                id: item.id,
            };
        }

        return item;
    });
};