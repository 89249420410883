import React from "react";
import Media from "react-media";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../components/ActualComponents/Text";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import ClientReviewReplyEditModal from "../../../client/feedbacks/components/review-reply-edit-modal";

import {useModal} from "../../../../hooks/useModal";

import bem from "../../../../utils/bem";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {phoneFormat} from "../../../../utils/stringFormat";

import {COLOR} from "../../../../constants/color";
import {ROLE_DICT} from "../../../../constants/roles";

import "./style.sass";

const ReviewReply = (props) => {
    const {
        review,
        reply,
        isClientPage,
        isContractorPage,
        contractorScore,
        className,
        fetchList,
        isAccessEdit,
    } = props;

    const [block, element] = bem("review-reply", className);

    const {
        isOpen,
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const renderEditModal = () => {
        return (
            isOpen &&
            <ClientReviewReplyEditModal
                title={
                    contractorScore
                        ? "Ответ на отзыв компании"
                        : "Ответ на отзыв исполнителя"
                }
                isEdit={true}
                contractorScore={contractorScore}
                data={reply}
                review={review}
                onClose={onCloseModal}
                fetchList={fetchList}
            />
        );
    };

    const getTooltipContent = () => {
        return (
            <div>
                <Text
                    level="2"
                    bold={true}
                    color={COLOR.WHITE}
                    children={`от ${reply?.creatorFullName || "-"}`}
                />
                <NmLabelText
                    type="page"
                    label="Номер телефона"
                    text={phoneFormat(reply?.creatorPhone) || "-"}
                    textColor={COLOR.WHITE}
                    labelColor={COLOR.SECONDARY_40}
                />
                <NmLabelText
                    type="page"
                    label="Email"
                    text={reply?.creatorEmail || "-"}
                    textColor={COLOR.WHITE}
                    labelColor={COLOR.SECONDARY_40}
                />
                <NmLabelText
                    type="page"
                    label="Роль"
                    text={ROLE_DICT[reply?.creatorRole]?.TEXT || "-"}
                    textColor={COLOR.WHITE}
                    labelColor={COLOR.SECONDARY_40}
                />
            </div>
        );
    };

    return (
        <div className={block()}>
            {renderEditModal()}
            <div className="flex align-items-center mb-2">
                <Text
                    level="3"
                    color={COLOR.SECONDARY_45}
                >
                    {
                        (isClientPage || (!contractorScore && !isContractorPage))
                            ? "Ответ компании"
                            : "Ответ исполнителя"
                    }
                    {` (от ${formatLocalDate(reply?.dateTime)})`}
                </Text>
                {
                    (isClientPage || (!contractorScore && !isContractorPage)) &&
                    <HelpTooltip
                        className="ml-2"
                        info={true}
                        position="top-left"
                        children={getTooltipContent()}
                    />
                }
            </div>
            <div className="flex flex-aligned-center">
                <Media
                    queries={{
                        mobile: {maxWidth: 767},
                    }}
                >
                    {
                        ({mobile}) => (
                            <NmShowMoreText
                                width={mobile ? 500 : 800}
                                anchor="blue"
                                lines={1}
                                children={reply?.text || "-"}
                                more="Читать полностью"
                            />
                        )
                    }
                </Media>
                {
                    isAccessEdit &&
                    <ModeIcon
                        className={element("mode-icon")}
                        color={COLOR.SECONDARY_45}
                        onClick={() => onOpenModal({
                            isOpen: true,
                        })}
                        width={20}
                        height={20}
                    />
                }
            </div>
        </div>
    );
};

export default ReviewReply;