import {useState} from "react";
import {useDispatch} from "react-redux";

import {
    convertDateToDateWithoutTimeString,
    handleFormString,
    isNullOrWhitespace,
} from "../../../../../../../utils/stringHelper";
import validate from "../../../../../../../utils/validate";

import {requiredMessage} from "../../../../../../../constants/validationRules";
import {EDU_LEVEL} from "../../../constants";

import {
    addContractorEducation,
    updateContractorEducation,
} from "../../../../../../../ducks/contractorProfile";

export const defaultEducationForm = {
    educationId: null,
    educationForm: "",
    educationInstitutionName: "",
    educationLevel: "",
    faculty: "",
    graduationYear: "",
    speciality: "",
};

export default function useCreateOrUpdateEducation({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultEducationForm});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const {
        educationForm,
        educationId,
        educationInstitutionName,
        educationLevel,
        faculty,
        graduationYear,
        speciality,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        if (name === "educationLevel") {
            setForm(prevState => ({
                ...prevState,
                educationLevel: value,
            }));
            setError({});
            return;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));

    };

    const validateForm = () => {
        let rule = {
            educationLevel: {
                required: requiredMessage,
            },
            educationInstitutionName: {
                required: requiredMessage,
                maxLength: {
                    value: 100,
                    message: "Максимальная длина строки 100 символов",
                },
            },
        };

        if ([
            EDU_LEVEL.HIGHER_EDUCATION.VALUE,
            EDU_LEVEL.INCOMPLETE_HIGHER_EDUCATION.VALUE,
            EDU_LEVEL.SECONDARY_SPECIAL_EDUCATION.VALUE,
        ].includes(educationLevel)) {
            rule = {
                ...rule,
                educationForm: {
                    required: requiredMessage,
                },
                faculty: {
                    required: requiredMessage,
                    maxLength: {
                        value: 100,
                        message: "Максимальная длина строки 100 символов",
                    },
                },
                speciality: {
                    required: requiredMessage,
                    maxLength: {
                        value: 100,
                        message: "Максимальная длина строки 100 символов",
                    },
                },

            };
        }

        if ([EDU_LEVEL.HIGHER_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_SPECIAL_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_EDUCATION.VALUE].includes(educationLevel)) {
            rule = {
                ...rule,
                graduationYear: {
                    required: requiredMessage,
                },
            };
        }

        const errors = validate(form, rule, "");

        setError({...errors});

        return Object.values(errors).length === 0;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        const method = educationId ? updateContractorEducation : addContractorEducation;
        dispatch(method({
            educationForm: handleFormString(educationForm),
            contractorId,
            educationId,
            educationInstitutionName: handleFormString(educationInstitutionName),
            educationLevel,
            faculty: handleFormString(faculty),
            graduationYear: convertDateToDateWithoutTimeString(graduationYear),
            speciality: handleFormString(speciality),
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        error,
    };
}