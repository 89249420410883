import {isEmpty} from "lodash";

export const getUnavailableTemplatesFlags = (data) => {
    const {
        result, // Список шаблонов
        customDocumentTemplatesDisabledForClient,  // Опция "Свои шаблоны" была отключена
        clientDefaultFrameContractChanged, // Был изменен дефолтный РД
    } = data;

    const isResult = !isEmpty(result);

    // Флаг для отображения мо с удаленными шаблонами
    const isTemplatesDeleted = isResult &&
        !customDocumentTemplatesDisabledForClient &&
        !clientDefaultFrameContractChanged;

    // Флаг для отображения мо с отключенной опцией
    const isDisabledTemplateClientSetting = isResult &&
        customDocumentTemplatesDisabledForClient &&
        !clientDefaultFrameContractChanged;

    // Флаг для отображения мо с измененными шаблонами РД по умолчанию
    const isChangedDefaultTemplateRD = isResult &&
        !customDocumentTemplatesDisabledForClient &&
        clientDefaultFrameContractChanged;

    return {
        isTemplatesDeleted,
        isDisabledTemplateClientSetting,
        isChangedDefaultTemplateRD,
    };
};