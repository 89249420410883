import {
    BFF_KEDO_STAFF_ADD_ERROR,
    BFF_KEDO_STAFF_ADD_REQUEST,
    BFF_KEDO_STAFF_ADD_SUCCESS,
    BFF_KEDO_STAFF_COMMON_ERROR,
    BFF_KEDO_STAFF_COMMON_REQUEST,
    BFF_KEDO_STAFF_COMMON_SUCCESS,
    BFF_KEDO_STAFF_GET_BY_ID_ERROR,
    BFF_KEDO_STAFF_GET_BY_ID_REQUEST,
    BFF_KEDO_STAFF_GET_BY_ID_SUCCESS,
    BFF_KEDO_STAFF_GET_PAGE_ERROR,
    BFF_KEDO_STAFF_GET_PAGE_REQUEST,
    BFF_KEDO_STAFF_GET_PAGE_SUCCESS,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS,
    BFF_KEDO_STAFF_UPDATE_ERROR,
    BFF_KEDO_STAFF_UPDATE_REQUEST,
    BFF_KEDO_STAFF_UPDATE_STORE,
    BFF_KEDO_STAFF_UPDATE_SUCCESS,
} from "./actions";

export const getKedoStaffInitialState = () => {
    return {
        pageData: {},
        list: [],
        card: {},
        commonCard: {},
        totalCount: 0,
        signatureTransactionsList: [],
        signatureTransactionsTotalCount: 0,
        progressSignatureTransactions: false,
        signatureTransactionsPageData: {},
        progress: false,
        progressCard: false,
        progressCommonCard: false,
        progressAction: false,
    };
};

const initialState = getKedoStaffInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case BFF_KEDO_STAFF_COMMON_SUCCESS:
        return {
            ...state,
            commonCard: payload,
            progressCommonCard: false,
        };
    case BFF_KEDO_STAFF_COMMON_REQUEST:
        return {
            ...state,
            progressCommonCard: true,
        };
    case BFF_KEDO_STAFF_COMMON_ERROR:
        return {
            ...state,
            progressCommonCard: false,
        };
    case BFF_KEDO_STAFF_GET_BY_ID_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case BFF_KEDO_STAFF_GET_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progressCard: false,
        };
    case BFF_KEDO_STAFF_GET_BY_ID_ERROR:
        return {
            ...state,
            progressCard: false,
        };
    case BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST:
        return {
            ...state,
            progressSignatureTransactions: true,
            signatureTransactionsPageData: payload,
        };
    case BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS:
        return {
            ...state,
            progressSignatureTransactions: false,
            signatureTransactionsList: payload.results,
            signatureTransactionsTotalCount: payload.totalCount,
        };
    case BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR:
        return {
            ...state,
            progressSignatureTransactions: false,
        };
    case BFF_KEDO_STAFF_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_KEDO_STAFF_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.result,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_KEDO_STAFF_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_KEDO_STAFF_UPDATE_REQUEST:
    case BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST:
    case BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST:
    case BFF_KEDO_STAFF_ADD_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_KEDO_STAFF_UPDATE_SUCCESS:
    case BFF_KEDO_STAFF_UPDATE_ERROR:
    case BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS:
    case BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR:
    case BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS:
    case BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR:
    case BFF_KEDO_STAFF_ADD_SUCCESS:
    case BFF_KEDO_STAFF_ADD_ERROR:  {
        return {
            ...state,
            progressAction: false,
        };
    }
    case BFF_KEDO_STAFF_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};