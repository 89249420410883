import React from "react";

import Text from "../../../../components/ActualComponents/Text";
import ButtonBack from "../../../../components/ButtonBack";

import bem from "../../../../utils/bem";

import "./style.sass";

const ChatMobileHeader = ({onClickBackToChatList, fullName}) => {
    const [, element] = bem("chat-mobile-header");

    return (
        <>
            <ButtonBack
                onClick={onClickBackToChatList}
                className="me-2 me-md-3"
            />
            <Text.Title
                className={element("name")}
                level="1"
            >
                {fullName}
            </Text.Title>
        </>
    );
};

export default ChatMobileHeader;