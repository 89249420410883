import React from "react";

import {ReactComponent as  CloseIcon} from "../../../images/close.svg";
import {ReactComponent as  InfoOutlineIcon} from "../../../images/info_outline.svg";

import bem from "../../../utils/bem";

import "./style.sass";

export type TNmTooltipPosition = "top" |
    "top-left" |
    "top-right" |
    "bottom" |
    "bottom-left" |
    "bottom-right" |
    "left" |
    "left-top" |
    "left-bottom" |
    "right" |
    "right-top" |
    "right-bottom";

export type TNmTooltipType = "error" | "light" | "dark" | "warning";

export type TNmTooltipBody = {titleBlock?: string, textBlock?: string, postScripts?: boolean}[];

export type TNmTooltip = {
    position?: TNmTooltipPosition,
    type?: TNmTooltipType,
    size?: "sm" | "md" | "lg",
    className?: string,
    children?: any,
    isPromptOn?: boolean,
    compound?: boolean,
    header?: {icon?: boolean, title?: string},
    body?: TNmTooltipBody,
    onClose?(e: any): void,
    hover?: boolean,
    classNameText?: string,
    isTriggerListCardIcon?: boolean,
    noPadding?: boolean,
    isFilterTooltip?: boolean,
}

const NmTooltip: React.FC<TNmTooltip> = (props: TNmTooltip) => {
    const {
        children,
        position = "bottom",
        type,
        className = "",
        isPromptOn = true,
        compound,
        header,
        body,
        onClose,
        size,
        hover,
        classNameText = "",
        isTriggerListCardIcon,
        noPadding = false,
        isFilterTooltip = false,
    } = props;

    if (!isPromptOn) {
        return null;
    }

    const [block, element] = bem("nm-tooltip", className);

    const  renderCompoundBlock = () => {
        return (
            <>
                {
                    header &&
                    <div className={element("header", {type})}>
                        {
                            header?.icon &&
                            <InfoOutlineIcon className={element("header-icon")} />
                        }
                        {
                            header?.title &&
                            <div
                                className={element("header-title")}
                                children={header.title}
                            />
                        }
                    </div>
                }
                <div className={element("body")}>
                    {
                        body?.length &&
                        body.map(({titleBlock, textBlock, postScripts}, index) => (
                            <div
                                key={index}
                                className={element("body-item", {postScripts})}
                            >
                                {
                                    titleBlock &&
                                    <div
                                        className={element("subtitle")}
                                        children={titleBlock}
                                    />
                                }
                                {
                                    textBlock &&
                                    <div
                                        className={`${element("text")} ${classNameText}`}
                                        children={textBlock}
                                    />
                                }
                            </div>
                        ))
                    }
                </div>
            </>
        );
    };

    return (
        <div
            className={block({
                position,
                type,
                compound,
                triggerListCardIcon: isTriggerListCardIcon,
                size,
                filterTooltip: isFilterTooltip,
            })}
        >
            <div
                className={element("content", {
                    noPadding,
                    withCloseButton: onClose && !hover,
                })}
            >
                {
                    onClose && !hover &&
                    <CloseIcon
                        color="red"
                        className={element("header-close")}
                        onClick={onClose}
                    />
                }
                {
                    compound ? renderCompoundBlock() : children
                }
            </div>
        </div>
    );
};

export default NmTooltip;