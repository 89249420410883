import {urlify} from "./urlify";

import {REG_EXP} from "../../../constants/regExp";

export const getTextWithNoTagLinks = (text: string) => {
    if (!text) {
        return "";
    }

    const urlRegex = new RegExp(REG_EXP.URL, "g");

    return urlRegex.test(text) ? urlify(text) : text;
};