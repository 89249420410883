import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getOptimizedPaymentList, getPaymentPageDataSelector} from "./financeReport";
import {getOrderWorkReportList, getOrderWorkReportPageDataSelector} from "./orderWorkReport";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {isNullOrWhitespace} from "../utils/stringHelper";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CHECK_MESSAGE} from "../constants/messages";

const controller = "/plutonium";
//*  TYPES  *//

const FINANCE_LOG_READ_REQUEST = "FINANCE_LOG_READ_REQUEST";
const FINANCE_LOG_READ_SUCCESS = "FINANCE_LOG_READ_SUCCESS";
const FINANCE_LOG_READ_ERROR = "FINANCE_LOG_READ_ERROR";

const FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST = "FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST";
const FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS = "FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS";
const FINANCE_PAYMENT_STEPS_LOG_READ_ERROR = "FINANCE_PAYMENT_STEPS_LOG_READ_ERROR";

const UPDATE_FINANCE_LOG_FIELD_REQUEST = "UPDATE_FINANCE_LOG_FIELD_REQUEST";

const FINANCE_CANCEL_RECEIPT_REQUEST = "FINANCE_CANCEL_RECEIPT_REQUEST";
const FINANCE_CANCEL_RECEIPT_SUCCESS = "FINANCE_CANCEL_RECEIPT_SUCCESS";
const FINANCE_CANCEL_RECEIPT_ERROR = "FINANCE_CANCEL_RECEIPT_ERROR";

const FINANCE_CREATE_OFFLINE_CHECK_REQUEST = "FINANCE_CREATE_OFFLINE_CHECK_REQUEST";
const FINANCE_CREATE_OFFLINE_CHECK_SUCCESS = "FINANCE_CREATE_OFFLINE_CHECK_SUCCESS";
const FINANCE_CREATE_OFFLINE_CHECK_ERROR = "FINANCE_CREATE_OFFLINE_CHECK_ERROR";

const FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST = "FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST";
const FINANCE_REGISTRATION_OFFLINE_CHECK_SUCCESS = "FINANCE_REGISTRATION_OFFLINE_CHECK_SUCCESS";
const FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR = "FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR";

const FINANCE_INDIVIDUAL_GET_CONCLUSION_REQUEST = "FINANCE_INDIVIDUAL_GET_CONCLUSION_REQUEST";
const FINANCE_INDIVIDUAL_GET_CONCLUSION_SUCCESS = "FINANCE_INDIVIDUAL_GET_CONCLUSION_SUCCESS";
const FINANCE_INDIVIDUAL_GET_CONCLUSION_ERROR = "FINANCE_INDIVIDUAL_GET_CONCLUSION_ERROR";

const FINANCE_RETRY_PAYMENT_REQUEST = "FINANCE_RETRY_PAYMENT_REQUEST";
const FINANCE_RETRY_PAYMENT_SUCCESS = "FINANCE_RETRY_PAYMENT_SUCCESS";
const FINANCE_RETRY_PAYMENT_ERROR = "FINANCE_RETRY_PAYMENT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    transactionLog: [],
    progressCorrect: false,
    bankStatusMessage: "",
    systemTransactionLog: [],
    progressIndividualConclusion: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case FINANCE_INDIVIDUAL_GET_CONCLUSION_REQUEST:
        return {
            ...state,
            progressIndividualConclusion: true,
        };
    case FINANCE_INDIVIDUAL_GET_CONCLUSION_SUCCESS:
        return {
            ...state,
            progressIndividualConclusion: false,
            bankStatusMessage: payload,
        };
    case FINANCE_LOG_READ_REQUEST:
    case FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case UPDATE_FINANCE_LOG_FIELD_REQUEST:
        return {
            ...state,
            ...payload,
        };
    case FINANCE_LOG_READ_SUCCESS:
        const {
            logs = [],
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            transactionLog: [...logs],
            totalCount,
        };
    case FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS:
        const {
            results = [],
        } = payload;

        return {
            ...state,
            progress: false,
            transactionLog: [...results],
            totalCount: payload.totalCount,
        };
    case FINANCE_INDIVIDUAL_GET_CONCLUSION_ERROR:
        return {
            ...state,
            bankStatusMessage: "",
            progressIndividualConclusion: false,
        };
    case FINANCE_CANCEL_RECEIPT_ERROR:
    case FINANCE_LOG_READ_ERROR:
    case FINANCE_PAYMENT_STEPS_LOG_READ_ERROR: {
        return {
            ...state,
            error: payload,
            progress: false,
        };
    }
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getFinanceLog(payload) {
    return {
        type: FINANCE_LOG_READ_REQUEST,
        payload,
    };
}

export function getPaymentStepsLog(payload) {
    return {
        type: FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST,
        payload,
    };
}

export function updateFinanceLogField(payload) {
    return {
        type: UPDATE_FINANCE_LOG_FIELD_REQUEST,
        payload,
    };
}

export function cancelReceipt(payload) {
    return {
        type: FINANCE_CANCEL_RECEIPT_REQUEST,
        payload,
    };
}

export function createOfflineCheck(payload) {
    return {
        type: FINANCE_CREATE_OFFLINE_CHECK_REQUEST,
        payload,
    };
}

export function registrationOfflineCheck(payload) {
    return {
        type: FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST,
        payload,
    };
}

export function getIndividualConclusion(payload) {
    return {
        type: FINANCE_INDIVIDUAL_GET_CONCLUSION_REQUEST,
        payload,
    };
}

export function retryPayment(payload) {
    return {
        type: FINANCE_RETRY_PAYMENT_REQUEST,
        payload,
    };
}


//*  SELECTORS  *//

export const financeLogSelector = state => state.financeLog;

export const financeLogTotalPagesSelector = createSelector(financeLogSelector, ({
    totalCount,
    pageData: {pageSize},
}) => getTotalPages(totalCount, pageSize));

export const financeLogTotalCountSelector = createSelector(financeLogSelector, ({totalCount}) => totalCount);

export const financeLogListSelector = createSelector(financeLogSelector, ({transactionLog}) => transactionLog);

export const financeLogProgressSelector = createSelector(financeLogSelector, ({progress}) => progress);

//*  SAGA  *//

export const financeLogListReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/transactionLog/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_LOG_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_LOG_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_LOG_READ_ERROR,
            payload: error,
        });
    }
};

export const getPaymentStepsLogSaga = function* ({payload}) {
    try {
        const {
            isCivil,
            isPlutonium,
            ...reqData
        } = payload;

        const pathByPaymentType = isCivil ? "ndfl-payments" : "npd-payments";
        const pathByBankType = isPlutonium ? "plutonium-logs" : "payment-steps-log";

        const result = yield request.bff.post(`/adm/${pathByPaymentType}/${pathByBankType}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_PAYMENT_STEPS_LOG_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_PAYMENT_STEPS_LOG_READ_ERROR,
            payload: error,
        });
    }
};

//POST /api/taxAuthorities/cancelReceipt
export const cancelReceiptSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post("taxAuthorities/cancelReceipt", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_CANCEL_RECEIPT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Чек успешно аннулирован.");

        const state = yield select();
        yield put(getOptimizedPaymentList(getPaymentPageDataSelector(state)));

        yield put({
            type: FINANCE_CANCEL_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_CANCEL_RECEIPT_ERROR,
            payload: error,
        });
    }
};
//GET /api/taxAuthorities/registrationOfflineCheck
//GET /api/taxAuthorities/getByPaymentNumberOrderIdReceipt

export const registrationOfflineCheckSaga = function* (action) {
    try {
        const {payload} = action;
        const {orderId, paymentNumber} = payload;

        const result = yield request.get("taxAuthorities/getByPaymentNumberOrderIdReceipt", {
            params: {
                orderId,
                paymentNumber,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (isNullOrWhitespace(result)) {
            toastError("Отсутствует оффлайн чек");
            return {
                done: true,
            };
        }

        const resultRegistration = yield request.get("taxAuthorities/registrationOfflineCheck", {
            params: {
                receiptId: result,
            },
        });

        const {
            errorMessage: errorMessageResultRegistration,
            link,
        } = resultRegistration;

        if (errorMessageResultRegistration) {
            toastError(errorMessageResultRegistration);

            return {
                done: true,
            };
        }

        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", link);
        tempLink.setAttribute("target", "_blank");

        document.body.appendChild(tempLink);

        tempLink.click();

        setTimeout(() => {
            tempLink.remove();
        }, 500);

        const state = yield select();
        yield put(getOrderWorkReportList(getOrderWorkReportPageDataSelector(state)));

        yield put({
            type: FINANCE_REGISTRATION_OFFLINE_CHECK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR,
            payload: error,
        });
    }
};

//POST /api/taxAuthorities/createOfflineCheck
export const createOfflineCheckSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post("taxAuthorities/createOfflineCheck", payload);

        const {errorMessage, downloadLink} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_CREATE_OFFLINE_CHECK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Оффлайн чек успешно создан");

        const response = yield fetch(`${new URL(window.location.href).origin}/${downloadLink}`, {
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
            },
            method: "GET",
        });

        if (response.status === 404) {
            toastError(CHECK_MESSAGE.ERROR_404);

            return {
                done: true,
            };
        }

        const blob = yield response.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);
        tempLink.setAttribute("target", "_blank");

        document.body.appendChild(tempLink);

        tempLink.click();

        setTimeout(() => {
            tempLink.remove();
        }, 500);

        yield put({
            type: FINANCE_CREATE_OFFLINE_CHECK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_CREATE_OFFLINE_CHECK_ERROR,
            payload: error,
        });
    }
};

export const getIndividualConclusionSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            handleResponse = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/individual/getConclusion`, {params: {contractorId}});

        const {errorMessage} = result;

        handleResponse();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_INDIVIDUAL_GET_CONCLUSION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_INDIVIDUAL_GET_CONCLUSION_SUCCESS,
            payload: "Самозанятый прошел проверку в банке",
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: FINANCE_INDIVIDUAL_GET_CONCLUSION_ERROR,
            payload: error,
        });
    }
};

//POST /api/plutonium/invoice/{paymentNumber}/retryPayment
export const retryPaymentSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            data: {
                paymentNumber,
            },
            isCivil,
            handleResponse,
        } = payload;

        const result = yield request.bff.post(`/adm/${isCivil ? "ndfl-payments" : "npd-payments"}/repeatPayment/${paymentNumber}`);

        const {errorMessage} = result;

        handleResponse(result);

        if (errorMessage) {
            yield put({
                type: FINANCE_RETRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
        } else {
            yield put({
                type: FINANCE_RETRY_PAYMENT_SUCCESS,
                payload: errorMessage,
            });
        }

    } catch (error) {
        toastError(error.message);

        yield put({
            type: FINANCE_RETRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FINANCE_LOG_READ_REQUEST, financeLogListReadSaga),
        takeEvery(FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST, getPaymentStepsLogSaga),
        takeEvery(FINANCE_CANCEL_RECEIPT_REQUEST, cancelReceiptSaga),
        takeEvery(FINANCE_CREATE_OFFLINE_CHECK_REQUEST, createOfflineCheckSaga),
        takeEvery(FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST, registrationOfflineCheckSaga),
        takeEvery(FINANCE_INDIVIDUAL_GET_CONCLUSION_REQUEST, getIndividualConclusionSaga),
        takeEvery(FINANCE_RETRY_PAYMENT_REQUEST, retryPaymentSaga),
    ]);
}
