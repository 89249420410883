import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2Content from "../../../../components/ActualComponents/NmConfirmV2/Content";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import CheckboxList from "../../../../components/CheckboxList";
import TableSelectedLabel from "../../../../components/TableSelectedLabel";

import {
    formatAmount,
    formatNumber,
    getNumberFromFormattedAmount,
} from "../../../../utils/stringFormat";

import {
    payRegistry,
    transferRegistryToPay,
} from "../../../../ducks/registry";

import PropTypes from "prop-types";

import "./style.sass";

const SUGGESTION = {
    REGISTRY_SEQ_NUM: "Выплаты не имеют идентификационного номера, поскольку реестр ещё не передан в оплату. В столбце указаны порядковые номера выплат(строк) реестра.",
    PAYMENTS_NUMBER_DUPLICATE: "В данном столбце указаны идентификационные номера выплат, созданных не в рамках данного реестра.",
    REGISTRY_SEQ_NUMS_DUPLICATE: "В данном столбце указаны порядковые номера аналогичных выплат(строк), содержащихся в рамках данного реестра.",
};

class RegistryDuplicatePaymentsList extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        registryId: PropTypes.string,
        close: PropTypes.func,
        data: PropTypes.object,
        isCard: PropTypes.bool,
        handleError: PropTypes.func,
    };

    static defaultProps = {
        selectedList: [],
        close: () => {},
        handleError: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            payModel: {},
            countSelected: 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {data: {payModel}} = props;
        const {payModel: oldPayModel} = state;

        if (!isEqual(payModel, oldPayModel)) {
            const selectedList = payModel.map(item => ({
                ...item,
            }));

            return {
                ...state,
                selectedList,
                payModel,
            };
        }

        return null;
    }

    getList = (value) => {
        if (typeof value === "string") {
            return value;
        }

        return value.join(", ") || "-";
    };

    renderAmount = amount => {
        return amount === null ? "" : formatAmount(formatNumber(getNumberFromFormattedAmount(amount), 2));
    };

    getTooltip = (suggestion) => {
        return (
            <HelpTooltip
                height={18}
                width={18}
                className="registry-duplicate-payments-list__tooltip"
                hover
                children={suggestion}
            />
        );
    };

    getRows = () => {
        const {selectedList} = this.state;

        return selectedList.map(row => {
            const {
                registrySeqNumsDuplicate,
                paymentsNumberDuplicate,
                registrySeqNum,
                contractorFullName,
                amount,
            } = row;

            return {
                ...row,
                key: registrySeqNum,
                showCheckBox: true,
                isSelected: Boolean(row.isSelected),
                contentRow: (
                    <NmListCard
                        onlyLabels
                        checkbox
                        classNameMainContent="col-16 col-xxl-13"
                        labels={[
                            {
                                label: "Номер подтверждаемой существующей выплаты",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: registrySeqNum,
                                tooltip: this.getTooltip(SUGGESTION.REGISTRY_SEQ_NUM),
                            },
                            {
                                label: "Номер аналогичной существующей выплаты",
                                noWrap: false,
                                noWrapLabel: false,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                columnOnMobile: true,
                                text: this.getList(paymentsNumberDuplicate),
                                tooltip: this.getTooltip(SUGGESTION.PAYMENTS_NUMBER_DUPLICATE),
                            },
                            {
                                label: "Номер аналогичной выплаты в реестре",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: this.getList(registrySeqNumsDuplicate),
                                tooltip: this.getTooltip(SUGGESTION.REGISTRY_SEQ_NUMS_DUPLICATE),
                            },
                            {
                                label: "Исполнитель",
                                columnOnMobile: true,
                                text: contractorFullName,
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма, ₽",
                                value: this.renderAmount(amount),
                                className: "col-16 col-md-4 col-xxl-3 mt-md-4 mt-xxl-0",
                                boldValue: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    onSelectedRows = (selectedList) => {
        const countSelected = selectedList.filter(value => value.isSelected).length;

        this.setState({
            selectedList,
            countSelected,
        });
    };

    onSubmit = () => {
        const {
            payRegistry,
            transferRegistryToPay,
            clientId,
            registryId,
            isCard,
            close,
            handleError,
        } = this.props;
        const {selectedList} = this.state;

        const registrySeqNumRemoveList = selectedList.filter(item => !item.isSelected).map(({registrySeqNum}) => registrySeqNum);

        if (isCard){
            transferRegistryToPay({
                data: {
                    clientId,
                    registryId,
                    checkDuplicate: false,
                    registrySeqNumRemoveList,
                },
                handleError: handleError,
            });

            close();
            return;
        }

        payRegistry({
            data: {
                clientId,
                registryId,
                checkDuplicate: false,
                registrySeqNumRemoveList,
            },
            handleError: handleError,
        });

        close();
    };

    render() {
        const {
            t,
            close,
        } = this.props;
        const {countSelected} = this.state;

        return (
            <NmModal
                size="lg"
                onClose={close}
                footer={
                    <ApplyButtons
                        onClose={close}
                        submit={this.onSubmit}
                        cancelBtnContent={t("button.cancel")}
                        submitBtnContent={t("button.confirm")}
                    />
                }
            >
                <div className="registry-duplicate-payments-list__container">
                    <NmConfirmV2Content
                        warning
                        content={t("registry-duplicate-payments-list.warning-message")}
                    />
                    <div className="mt-3 mt-md-5">
                        <CheckboxList
                            classNameContent="registry-duplicate-payments-list__content"
                            header={<TableSelectedLabel
                                count={countSelected}
                            />}
                            onSelectedRows={this.onSelectedRows}
                            rows={this.getRows()}
                        />
                    </div>
                </div>
            </NmModal>
        );
    }
}

export default connect(
    state => ({}),
    {
        payRegistry,
        transferRegistryToPay,
    },
)(withTranslation()(RegistryDuplicatePaymentsList));