import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const bffRecruitmentAccessControlRecruitersGroupsSelector = state => state.bff.recruitment.accessControl.recruitersGroups;

export const bffRecruitmentAccessControlRecruitersGroupsListSelector = createSelector(bffRecruitmentAccessControlRecruitersGroupsSelector, ({list}) => list);
export const bffRecruitmentAccessControlRecruitersGroupsListLoadingSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({listLoading}) => listLoading,
);
export const bffRecruitmentAccessControlRecruitersGroupsListTotalCountSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({listTotalCount}) => listTotalCount,
);

export const bffRecruitmentAccessControlRecruitersGroupsListTotalPagesSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({listTotalCount, listPageData: {pageSize = 0}}) => getTotalPages(listTotalCount, pageSize),
);

export const bffRecruitmentAccessControlRecruitersGroupsAddLoadingSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({addLoading}) => addLoading,
);

export const bffRecruitmentAccessControlRecruitersGroupsCardListSelector = createSelector(bffRecruitmentAccessControlRecruitersGroupsSelector, ({cardList}) => cardList);
export const bffRecruitmentAccessControlRecruitersGroupsCardListLoadingSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardListLoading}) => cardListLoading,
);
export const bffRecruitmentAccessControlRecruitersGroupsCardListTotalCountSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardListTotalCount}) => cardListTotalCount,
);
export const bffRecruitmentAccessControlRecruitersGroupsCardListTotalPagesSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardListTotalCount, cardListPageData: {pageSize = 0}}) => getTotalPages(cardListTotalCount, pageSize),
);

export const bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardAvailableRecruitersList}) => cardAvailableRecruitersList,
);

export const bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListTotalCountSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardAvailableRecruitersListTotalCount}) => cardAvailableRecruitersListTotalCount,
);

export const bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListTotalPagesSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardAvailableRecruitersListTotalCount, cardAvailableRecruitersListPageData: {pageSize = 0}}) => getTotalPages(cardAvailableRecruitersListTotalCount, pageSize),
);

export const bffRecruitmentAccessControlRecruitersGroupsCardAvailableRecruitersListLoadingSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardAvailableRecruitersListLoading}) => cardAvailableRecruitersListLoading,
);

export const bffRecruitmentAccessControlRecruitersGroupsCardAddLoadingSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardAddRecruitersLoading}) => cardAddRecruitersLoading,
);

export const bffRecruitmentAccessControlRecruitersGroupsCardNameSelector = createSelector(
    bffRecruitmentAccessControlRecruitersGroupsSelector,
    ({cardName}) => cardName,
);