import {
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST,
} from "./actions";

export const getPageRecruitmentAccessControlRecruitersGroups = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentAccessControlRecruitersGroups = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_REQUEST,
        payload,
    };
};

export const addRecruitmentAccessControlRecruitersGroups = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST,
        payload,
    };
};

export const getPageRecruitmentAccessControlRecruitersGroupsCard = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST,
        payload,
    };
};

export const deleteRecruiterRecruitmentAccessControlRecruitersGroupsCard = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_REQUEST,
        payload,
    };
};

export const addRecruitersRecruitmentAccessControlRecruitersGroupsCard = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST,
        payload,
    };
};

export const getAvailableRecruitersPageRecruitmentAccessControlRecruitersGroupsCard = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST,
        payload,
    };
};

export const clearAvailableRecruitersRecruitmentAccessControlRecruitersGroupsCardStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_CLEAR_STORE,
        payload,
    };
};

export const clearRecruitmentAccessControlRecruitersGroupsCardStore = () => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_CLEAR_STORE,
    };
};

export const clearRecruitmentAccessControlRecruitersGroupsStore = () => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE,
    };
};