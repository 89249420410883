import React from "react";

import bem from "../../../../../utils/bem";

import "./style.sass";

const ContractorVerificationInputContainer = ({children, full}) => {
    const [block] = bem("contractor-verification-input-container");

    return (
        <div className={block({full})}>
            {children}
        </div>
    );
};

export default ContractorVerificationInputContainer;