import * as yup from "yup";

import {addYears} from "../../../../utils/dateFormat";

import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES]: yup.string()
            .max(5, "Допустимое количество символов 5")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM]: yup.string()
            .max(10, "Допустимое количество символов 10")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER]: yup.string()
            .max(50, "Допустимое количество символов 50")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]: yup.string()
            .nullable()
            .test("check", "Срок действия патента истек", function (value) {
                if (!value) {
                    return true;
                }
                const checkDate = addYears(value, YEAR_FOR_CHECK_EMPLOYMENT_PATENT_ISSUED_DATE);

                return checkDate > new Date();
            }),
    });
};

export default validationSchema;

const YEAR_FOR_CHECK_EMPLOYMENT_PATENT_ISSUED_DATE = 1;