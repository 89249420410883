export const EDU_LEVEL = {
    HIGHER_EDUCATION: {
        TEXT: "Высшее образование",
        VALUE: "HIGHER_EDUCATION",
    },
    INCOMPLETE_HIGHER_EDUCATION: {
        TEXT: "Неполное высшее образование",
        VALUE: "INCOMPLETE_HIGHER_EDUCATION",
    },
    SCHOOL_STUDENT: {
        TEXT: "Учащийся школы",
        VALUE: "SCHOOL_STUDENT",
    },
    SECONDARY_EDUCATION: {
        TEXT: "Среднее образование",
        VALUE: "SECONDARY_EDUCATION",
    },
    SECONDARY_SPECIAL_EDUCATION: {
        TEXT: "Среднее специальное образование",
        VALUE: "SECONDARY_SPECIAL_EDUCATION",
    },
};