import * as yup from "yup";

import {CREATE_ACTS_TYPE} from "../../../../../../registries/registryActsType";

import {getDateWithoutTime} from "../../../../../../../utils/dateFormat";
import {clearSpace} from "../../../../../../../utils/stringFormat";
import {handleNumber} from "../../../../../../../utils/stringHelper";

import {MIN_PAYOUT_TO_CONTRACTOR, ORDER_KIND} from "../../../../../../../constants/order";
import {descriptionPaymentRegWithSlach} from "../../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const FNS_ERROR = "Неверный формат описания выполненных работ для чека ФНС России. Для ввода доступны следующие символы: кириллица, латиница, цифры, пробелы, знаки . , : ; - \" ' * № ! ? ( ) { } % < > + [ ] « » |";

export const getValidation = ({actOrderKind}) => {
    return yup.object().shape({
        orderDescription: yup.string()
            .min(5, "Минимальная длина строки - 5 символов")
            .max(5000, "Допустимое количество символов 5000")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        orderNumber: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .min(3, "Минимальная длина строки - 3 символа")
            .max(20, "Допустимое количество символов 5000"),
        comment: yup.string()
            .min(5, "Минимальная длина строки - 5 символов")
            .max(5000, "Допустимое количество символов 5000"),
        actDate: yup.object()
            .nullable()
            .test("check", "Дата акта не может быть раньше конца периода работ.", function(value) {
                if (!value || !this.parent.orderWorkEndDate) {
                    return true;
                }

                if (getDateWithoutTime(value) < getDateWithoutTime(this.parent.orderWorkEndDate)) {
                    return false;
                }
            }),
        orderWorkStartDate: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        orderWorkEndDate: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        contractorWorkQualityScore: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        contractorEstimatedTimeScore: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        sumOrAmount:  actOrderKind === ORDER_KIND.AMOUNT_BASED_ORDER ? yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("check", "Не может быть менее 10 Р", function(value) {
                return handleNumber(clearSpace(value)) >= MIN_PAYOUT_TO_CONTRACTOR;
            }) : yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        orderDescriptionFns: yup.string()
            .min(5, "Минимальная длина строки - 5 символов")
            .max(5000, "Допустимое количество символов 5000")
            .test("orderDescriptionFNS", FNS_ERROR, function(value) {
                if (!value) {
                    return true;
                }

                return descriptionPaymentRegWithSlach.test(value);
            })
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};