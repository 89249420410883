import {useDispatch} from "react-redux";

import {getRecruitmentJobBoardsConnectUrl} from "../../../ducks/bff/recruitment/jobBoards/actionCreators";

import {toastError} from "../../../utils/toastHelper";

export const useJobBoardConnect = () => {
    const dispatch = useDispatch();

    const connectJobBoard = (params) => {
        const {
            jobBoardType,
            clientId,
        } = params;

        dispatch(getRecruitmentJobBoardsConnectUrl({
            jobBoardType,
            clientId,
            onSuccess: (url) => {
                if (!url) {
                    toastError("Не получен урл на площадку");
                }

                window.location.replace(url);
            },
        }));
    };

    return {
        connectJobBoard,
    };
};