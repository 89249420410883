import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {isClientUser} from "../../../../constants/roles";

import {getClientsKedoLogsIntegration1cPage} from "../../../../ducks/bff/clients/kedo/logs/services";
import {getCompaniesKedoLogsIntegration1cPage} from "../../../../ducks/bff/companies/company/kedo/logs/services";

export const useKedoLogsBff = () => {
    const dispatch = useDispatch();

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const fetchPage = (data) => {
        if (isClientRole) {
            dispatch(getClientsKedoLogsIntegration1cPage(data));

            return;
        }

        dispatch(getCompaniesKedoLogsIntegration1cPage(data));
    };

    return {
        fetchPage,
    };
};