import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    RecruitmentCandidatesCardPage,
} from "../../../../../components/Recruitment/CandidateCard";
import {
    getRecruitmentVacancyResponseCardById,
    rejectRecruitmentResponse,
    updateRecruitmentVacancyResponsesStore,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import {
    bffRecruitmentVacancyResponsesCardSelector,
    bffRecruitmentVacancyResponsesProgressActionSelector,
    bffRecruitmentVacancyResponsesProgressCardSelector,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/selectors";

export const RecruitmentVacancyResponseCard = (props) => {
    const {
        responseId,
        vacancyId,
        fetchList,
        clientId,
        cardLink,
        isNewResponses,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(bffRecruitmentVacancyResponsesProgressCardSelector);
    const card = useSelector(bffRecruitmentVacancyResponsesCardSelector);
    const progressAction = useSelector(bffRecruitmentVacancyResponsesProgressActionSelector);

    useEffect(() => {
        if (
            vacancyId
            && responseId
        ) {
            fetchCard();
        }
    }, [responseId]);

    const fetchCard = () => {
        dispatch(getRecruitmentVacancyResponseCardById({
            vacancyId,
            responseId,
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyResponsesStore({
                card: {},
            }));
        };
    }, []);

    const buttonFunnelMoveName = card.statusName ? card.statusName : "Пригласить";


    const reject = (params) => {
        dispatch(rejectRecruitmentResponse(params));
    };

    return (
        <RecruitmentCandidatesCardPage
            fetchList={fetchList}
            responseId={responseId}
            clientId={clientId}
            vacancyId={vacancyId}
            progress={progress}
            progressAction={progressAction}
            isEmptyCard={isEmpty(card)}
            fetchCard={fetchCard}
            card={card}
            cardLink={cardLink}
            buttonFunnelMoveName={buttonFunnelMoveName}
            reject={reject}
            isNewResponses={isNewResponses}
        />
    );
};