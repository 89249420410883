import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getClientUserRestrictionsList} from "./clientUserRestrictions";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {getFormattedFullName} from "../utils/stringFormat";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT_MEMBER, MEMBER_ARCHIVE_SUCCESS} from "../constants/messages";

const controller = "/clients/users";
//*  TYPES  *//

const CLIENT_MEMBER_READ_REQUEST = "CLIENT_MEMBER_READ_REQUEST";
const CLIENT_MEMBER_READ_SUCCESS = "CLIENT_MEMBER_READ_SUCCESS";
const CLIENT_MEMBER_READ_ERROR = "CLIENT_MEMBER_READ_ERROR";

const CLIENT_MEMBERS_READ_REQUEST = "CLIENT_MEMBERS_READ_REQUEST";
const CLIENT_MEMBERS_READ_SUCCESS = "CLIENT_MEMBERS_READ_SUCCESS";
const CLIENT_MEMBERS_READ_ERROR = "CLIENT_MEMBERS_READ_ERROR";

const CLIENT_CURRENT_MEMBER_READ_REQUEST = "CLIENT_CURRENT_MEMBER_READ_REQUEST";
const CLIENT_CURRENT_MEMBER_READ_SUCCESS = "CLIENT_CURRENT_MEMBER_READ_SUCCESS";
const CLIENT_CURRENT_MEMBER_READ_ERROR = "CLIENT_CURRENT_MEMBER_READ_ERROR";

const CLIENT_MEMBER_ADD_REQUEST = "CLIENT_MEMBER_ADD_REQUEST";
const CLIENT_MEMBER_ADD_SUCCESS = "CLIENT_MEMBER_ADD_SUCCESS";
const CLIENT_MEMBER_ADD_ERROR = "CLIENT_MEMBER_ADD_ERROR";

const CLIENT_MEMBER_UPDATE_REQUEST = "CLIENT_MEMBER_UPDATE_REQUEST";
const CLIENT_MEMBER_UPDATE_SUCCESS = "CLIENT_MEMBER_UPDATE_SUCCESS";
const CLIENT_MEMBER_UPDATE_ERROR = "CLIENT_MEMBER_UPDATE_ERROR";

const CLIENT_MEMBER_LIST_READ_REQUEST = "CLIENT_MEMBER_LIST_READ_REQUEST";
const CLIENT_MEMBER_LIST_READ_SUCCESS = "CLIENT_MEMBER_LIST_READ_SUCCESS";
const CLIENT_MEMBER_LIST_READ_ERROR = "CLIENT_MEMBER_LIST_READ_ERROR";

const CLIENT_SETTING_USERS_REQUEST = "CLIENT_SETTING_USERS_REQUEST";
const CLIENT_SETTING_USERS_SUCCESS = "CLIENT_SETTING_USERS_SUCCESS";

const CLIENT_MEMBER_CLEAR_STORE = "CLIENT_MEMBER_CLEAR_STORE";

const CLIENT_MEMBER_UPDATE_FIELD_STORE = "CLIENT_MEMBER_UPDATE_FIELD_STORE";

const CLIENT_MEMBER_ARCHIVE_REQUEST = "CLIENT_MEMBER_ARCHIVE_REQUEST";
const CLIENT_MEMBER_ARCHIVE_ERROR = "CLIENT_MEMBER_ARCHIVE_ERROR";

const SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST = "SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST";
const SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS = "SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS";
const SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR = "SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR";

const GET_HIDE_DEPOSIT_DETAILS_REQUEST = "GET_HIDE_DEPOSIT_DETAILS_REQUEST";
const GET_HIDE_DEPOSIT_DETAILS_SUCCESS = "GET_HIDE_DEPOSIT_DETAILS_SUCCESS";
const GET_HIDE_DEPOSIT_DETAILS_ERROR = "GET_HIDE_DEPOSIT_DETAILS_ERROR";

const SET_HIDE_DEPOSIT_DETAILS_REQUEST = "SET_HIDE_DEPOSIT_DETAILS_REQUEST";
const SET_HIDE_DEPOSIT_DETAILS_SUCCESS = "SET_HIDE_DEPOSIT_DETAILS_SUCCESS";
const SET_HIDE_DEPOSIT_DETAILS_ERROR = "SET_HIDE_DEPOSIT_DETAILS_ERROR";

const GET_CLIENT_USER_COUNT_REQUEST = "GET_CLIENT_USER_COUNT_REQUEST";
const GET_CLIENT_USER_COUNT_SUCCESS = "GET_CLIENT_USER_COUNT_SUCCESS";
const GET_CLIENT_USER_COUNT_ERROR = "GET_CLIENT_USER_COUNT_ERROR";

const GENERATE_PASSWORD_CLIENT_USER_REQUEST = "GENERATE_PASSWORD_CLIENT_USER_REQUEST";
const GENERATE_PASSWORD_CLIENT_USER_SUCCESS = "GENERATE_PASSWORD_CLIENT_USER_SUCCESS";
const GENERATE_PASSWORD_CLIENT_USER_ERROR = "GENERATE_PASSWORD_CLIENT_USER_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    card: {},
    pageData: {},
    error: null,
    progress: false,
    progressList: false,
    progressAdd: false,
    isSuccessAdd: false,
    progressUpdate: false,
    isSuccessUpdate: false,
    currentMember: {},
    userIdsList: [],
    usersForFilterList: [],
    adminMembers: [],
    projectManagers: [],
    foremanList: [],
    progressGetUsers: false,
    progressCurrentUser: true,
    nmUsers: [],
    nmUsersTotalCountData: {
        otherCount: 0,
        partnerCount: 0,
        totalCount: 0,
    },
    nmUsersPageData: {},
    clientsForSelection: [],
    hideDepositDetails: false,
    hideDepositDetailsProgress: false,
    clientUserCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CLIENT_MEMBER_LIST_READ_REQUEST:
        return {
            ...state,
            progressList: true,
            progressGetUsers: true,
            pageData: payload,
        };
    case CLIENT_MEMBER_UPDATE_FIELD_STORE:
        return {
            ...state,
            ...payload,
        };
    case CLIENT_SETTING_USERS_REQUEST:
        return {
            ...state,
            nmUsersPageData: payload,
        };
    case CLIENT_SETTING_USERS_SUCCESS:
        return {
            ...state,
            nmUsers: payload.clientUsers,
            nmUsersTotalCountData: {
                otherCount: payload.otherCount,
                partnerCount: payload.partnerCount,
                totalCount: payload.totalCount,
            },
            clientsForSelection: payload.clientsForSelection,

        };
    case CLIENT_MEMBER_CLEAR_STORE:
        return {
            ...initial,
        };
    case CLIENT_MEMBER_UPDATE_REQUEST:
    case GENERATE_PASSWORD_CLIENT_USER_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case CLIENT_MEMBER_ADD_REQUEST:
        return {
            ...state,
            progressAdd: true,
        };
    case CLIENT_CURRENT_MEMBER_READ_REQUEST:
        return {
            ...state,
            progressCurrentUser: true,
        };
    case CLIENT_MEMBERS_READ_REQUEST:
    case CLIENT_MEMBER_READ_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case SET_HIDE_DEPOSIT_DETAILS_REQUEST:
        return {
            ...state,
            hideDepositDetailsProgress: true,
        };
    case CLIENT_MEMBER_ADD_SUCCESS:
        return {
            ...state,
            progressAdd: false,
            isSuccessAdd: true,
        };
    case CLIENT_MEMBER_UPDATE_SUCCESS:
    case GENERATE_PASSWORD_CLIENT_USER_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
            isSuccessUpdate: true,
        };
    case CLIENT_CURRENT_MEMBER_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            progressCurrentUser: false,
            currentMember: payload,
        };
    case CLIENT_MEMBERS_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            list: payload,
        };
    case SET_HIDE_DEPOSIT_DETAILS_SUCCESS:
        return {
            ...state,
            hideDepositDetailsProgress: false,
        };
    case CLIENT_CURRENT_MEMBER_READ_ERROR:
        return {
            ...state,
            progressCurrentUser: false,
        };
    case CLIENT_MEMBER_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            card: payload,
        };
    case CLIENT_MEMBER_LIST_READ_SUCCESS:
        const {
            clientUsers = [],
            totalCount = 0,
            fieldName = "list",
        } = payload;

        return {
            ...state,
            progressList: false,
            progressGetUsers: false,
            usersForFilterList: clientUsers,
            [fieldName]: clientUsers,
            totalCount,
        };
    case GET_CLIENT_USER_COUNT_SUCCESS:
        return {
            ...state,
            clientUserCount: payload,
        };
    case CLIENT_MEMBER_ADD_ERROR:
        return {
            ...state,
            progressAdd: false,
            isSuccessAdd: false,
            error: payload,
        };
    case CLIENT_MEMBER_LIST_READ_ERROR:
        return {
            ...state,
            progressGetUsers: false,
            progressList: false,
            error: payload,
        };
    case CLIENT_MEMBER_UPDATE_ERROR:
    case GENERATE_PASSWORD_CLIENT_USER_ERROR:
        return {
            ...state,
            progressUpdate: false,
            isSuccessUpdate: false,
            error: payload,
        };
    case CLIENT_MEMBERS_READ_ERROR:
    case CLIENT_MEMBER_READ_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case SET_HIDE_DEPOSIT_DETAILS_ERROR:
        return {
            ...state,
            hideDepositDetailsProgress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientMemberById(payload) {
    return {
        type: CLIENT_MEMBER_READ_REQUEST,
        payload,
    };
}

export function getClientMemberByIds(payload) {
    return {
        type: CLIENT_MEMBERS_READ_REQUEST,
        payload,
    };
}

export function getClientCurrentMemberByLogin(payload) {
    return {
        type: CLIENT_CURRENT_MEMBER_READ_REQUEST,
        payload,
    };
}

export function addClientMember(payload) {
    return {
        type: CLIENT_MEMBER_ADD_REQUEST,
        payload: payload,
    };
}

export function clearClientMemberStore() {
    return {
        type: CLIENT_MEMBER_CLEAR_STORE,
    };
}

export function updateFieldClientMemberStore(payload) {
    return {
        type: CLIENT_MEMBER_UPDATE_FIELD_STORE,
        payload,
    };
}

export function updateClientMember(payload) {
    return {
        type: CLIENT_MEMBER_UPDATE_REQUEST,
        payload,
    };
}

export function getClientMemberList(payload) {
    return {
        type: CLIENT_MEMBER_LIST_READ_REQUEST,
        payload,
    };
}

export function getClientUserAndPartnerPage(payload) {
    return {
        type: CLIENT_SETTING_USERS_REQUEST,
        payload,
    };
}

export function archiveMember(payload) {
    return {
        type: CLIENT_MEMBER_ARCHIVE_REQUEST,
        payload,
    };
}

export function sendComplaintOnContractorVaccinationCertificate(payload) {
    return {
        type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST,
        payload,
    };
}

export function getHideDepositDetails(payload) {
    return {
        type: GET_HIDE_DEPOSIT_DETAILS_REQUEST,
        payload,
    };
}

export function setHideDepositDetails(payload) {
    return {
        type: SET_HIDE_DEPOSIT_DETAILS_REQUEST,
        payload,
    };
}

export function getClientUserCount(payload) {
    return {
        type: GET_CLIENT_USER_COUNT_REQUEST,
        payload,
    };
}

export function generatePasswordClientMember(payload) {
    return {
        type: GENERATE_PASSWORD_CLIENT_USER_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientMemberSelector = state => state.clientMember;

export const clientMemberOptionsSelector = createSelector(clientMemberSelector, state => {
    return state.usersForFilterList.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: `${item.lastName} ${item.firstName} ${item.patronymic === null ? "" : item.patronymic}`,
    }));
},
);

export const clientCurrentMemberFullNameSelector = createSelector(clientMemberSelector, ({currentMember}) => {
    const {
        lastName,
        firstName,
        patronymic,
    } = currentMember || {};

    return lastName ? `${lastName} ${firstName} ${patronymic}` : "";
});


export const clientsForSelectionOptionsSelector = createSelector(clientMemberSelector, ({clientsForSelection}) => {
    return clientsForSelection.map(({clientId, name}) => {
        return {
            key: clientId,
            text: name,
            value: clientId,
        };
    });
});

export const getProgressCurrentUser = createSelector(clientMemberSelector, ({progressCurrentUser}) => progressCurrentUser);

export const clientCurrentMemberSelector = createSelector(clientMemberSelector, ({currentMember}) => currentMember || {});

export const clientCurrentMemberIdSelector = createSelector(clientMemberSelector, ({currentMember: {clientUserId}}) => clientUserId);
export const clientCurrentClientIdSelector = createSelector(clientMemberSelector, ({currentMember: {clientId}}) => clientId);

export const clientMemberCardSelector = createSelector(clientMemberSelector, ({card}) => card);

export const clientMemberListSelector = createSelector(clientMemberSelector, ({list}) => list);
export const clientProjectManagerListSelector = createSelector(clientMemberSelector, ({projectManagers}) => projectManagers);
export const clientForemanListSelector = createSelector(clientMemberSelector, ({foremanList}) => foremanList);
export const clientMemberProgressSelector = createSelector(clientMemberSelector, ({progress}) => progress);
export const clientMemberProgressAddSelector = createSelector(clientMemberSelector, ({progressAdd}) => progressAdd);
export const clientMemberProgressListSelector = createSelector(clientMemberSelector, ({progressList}) => progressList);
export const clientMemberProgressGetUsersSelector = createSelector(clientMemberSelector, ({progressGetUsers}) => progressGetUsers);

export const clientMemberIsSuccessAddSelector = createSelector(clientMemberSelector, ({isSuccessAdd}) => isSuccessAdd);

export const clientMemberProgressUpdateSelector = createSelector(clientMemberSelector, ({progressUpdate}) => progressUpdate);

export const clientMemberIsSuccessUpdateSelector = createSelector(clientMemberSelector, ({isSuccessUpdate}) => isSuccessUpdate);

export const clientMemberTotalCountSelector = createSelector(clientMemberSelector, ({totalCount}) => totalCount);

export const clientMemberTotalPagesSelector = createSelector(clientMemberSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const hideDepositDetailsSelector = createSelector(clientMemberSelector, ({hideDepositDetails}) => hideDepositDetails);

export const clientUserCountSelector = createSelector(clientMemberSelector, ({clientUserCount}) => clientUserCount);

export const clientMemberFullNameClientSelector = createSelector(clientMemberSelector, ({list}) => {
    return list.map(({lastName, clientUserId, firstName, patronymic}) => ({
        clientUserId,
        fullName: `${lastName} ${firstName} ${patronymic || ""}`,
    }));
});

export const hideDepositDetailsProgressSelector = createSelector(clientMemberSelector, ({hideDepositDetailsProgress}) => hideDepositDetailsProgress);

//**  nmUsersTotalCountData: {
//         otherCount: 0,
//         partnerCount: 0,
//         totalCount: 0
//     },
//     nmUsersPageData: {}

export const clientMemberNmUsersSelector = createSelector(clientMemberSelector, ({nmUsers}) => {
    return nmUsers;
});

export const nmMemberOptionsSelector = createSelector(clientMemberSelector, ({nmUsers}) => {
    return nmUsers.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: `${item.lastName} ${item.firstName} ${item.patronymic === null ? "" : item.patronymic}`,
    }));
},
);

export const clientMemberNmUsersTotalCountDataSelector = createSelector(clientMemberSelector, ({nmUsersTotalCountData}) => {
    return nmUsersTotalCountData;
});

export const clientMemberNmUsersTotalPagesDataSelector = createSelector(clientMemberSelector, ({nmUsersTotalCountData, nmUsersPageData, totalCount}) => {
    return {
        totalCount: nmUsersTotalCountData.totalCount,
        otherTotalPages: getTotalPages(nmUsersTotalCountData.otherCount, nmUsersPageData.pageSize ?? 0),
        partnerTotalPages: getTotalPages(nmUsersTotalCountData.partnerCount, nmUsersPageData.pageSize ?? 0),
    };
});


export const orderAdminMembersSelector = createSelector(clientMemberSelector, ({adminMembers}) => adminMembers);

export const orderManagerOptionsNoObjectSelector = createSelector(clientMemberSelector, ({adminMembers}) =>
    adminMembers.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: getFormattedFullName(`${item.lastName} ${item.firstName} ${item.patronymic}`),
    })),
);

export const currentMemberOptionTypeSelector = createSelector(clientMemberSelector, ({currentMember}) => ([{
    key: currentMember.clientUserId,
    value: currentMember.clientUserId,
    text: getFormattedFullName(`${currentMember.lastName} ${currentMember.firstName} ${currentMember.patronymic}`),
}]));

export const managerOptionsSelector = createSelector(clientMemberSelector, ({projectManagers}) => {
    return projectManagers.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: `${item.lastName} ${item.firstName} ${item.patronymic === null ? "" : item.patronymic}`,
        role: item.role,
    }));
});


export const foremanOptionsSelector = createSelector(clientMemberSelector, ({foremanList}) => {
    return foremanList.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: `${item.lastName} ${item.firstName} ${item.patronymic === null ? "" : item.patronymic}`,
    }));
});


//*  SAGA  *//

export const clientMemberReadSaga = function* (action) {
    try {
        const {payload: clientUserId} = action;

        const result = yield request.get(`${controller}/getById`, {params: {clientUserId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_MEMBER_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_MEMBER_READ_ERROR,
            payload: error,
        });
    }
};

export const clientMembersReadSaga = function* (action) {
    try {
        const {payload: clientUserIds} = action;

        const result = yield request.post(`${controller}/getByIds`, clientUserIds);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBERS_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_MEMBERS_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_MEMBERS_READ_ERROR,
            payload: error,
        });
    }
};

export const clientCurrentMemberReadSaga = function* (action) {
    try {
        const {payload: login} = action;
        const result = yield request.get(`${controller}/getRichByLogin`, {params: {login}});

        const {
            errorMessage,
            roleId,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_CURRENT_MEMBER_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (roleId) {
            yield put(getClientUserRestrictionsList());
        }

        yield put({
            type: CLIENT_CURRENT_MEMBER_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_CURRENT_MEMBER_READ_ERROR,
            payload: error,
        });
    }
};

export const updateClientMemberSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess,
                ...data
            },
        } = action;

        const result = yield request.post(`${controller}/update`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        toastSuccess(CLIENT_MEMBER.UPDATE_SUCCESS_TEXT);
        yield put({
            type: CLIENT_MEMBER_UPDATE_SUCCESS,
        });
    } catch (error) {
        toastError(CLIENT_MEMBER.UPDATE_ERROR_TEXT);

        yield put({
            type: CLIENT_MEMBER_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const addClientMemberSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/add`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        toastSuccess(CLIENT_MEMBER.ADD_SUCCESS_TEXT);

        yield put({
            type: CLIENT_MEMBER_ADD_SUCCESS,
        });
    } catch (error) {
        toastError(CLIENT_MEMBER.ADD_ERROR_TEXT);

        yield put({
            type: CLIENT_MEMBER_ADD_ERROR,
            payload: error,
        });
    }
};

export const clientMemberListReadSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.post(`${controller}/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_MEMBER_LIST_READ_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({
            type: CLIENT_MEMBER_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const clientArchiveMemberSaga = function* (action) {
    try {
        const {
            payload,
        } = action;
        const {
            canFetchList = true,
            ...data
        } = payload;

        const result = yield request.put(`${controller}/archive`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_ARCHIVE_ERROR,
                payload: result,
            });
        }

        const state = yield select();
        if (canFetchList) {
            yield put(getClientMemberList(state.clientMember.pageData));
        }

        if (!errorMessage) {
            toastSuccess(data.archive ? MEMBER_ARCHIVE_SUCCESS.TO : MEMBER_ARCHIVE_SUCCESS.FROM);
        }
    } catch (error) {
        yield put({
            type: CLIENT_MEMBER_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

export const getClientUserAndPartnerPageSaga = function* ({payload}) {
    try {
        const {getResult, ...reqData} = payload;

        const result = yield request.post(`${controller}/getClientUserAndPartnerPage`, reqData);
        getResult && getResult(result);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_SETTING_USERS_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: CLIENT_MEMBER_LIST_READ_ERROR,
            payload: error,
        });
    }
};

// GET /api/clients/users/sendComplaintOnContractorVaccinationCertificate

const sendComplaintOnContractorVaccinationCertificateSaga = function* ({payload}) {
    try {
        const {getResult, ...params} = payload;

        const result = yield request.get(`${controller}/sendComplaintOnContractorVaccinationCertificate`, {params});

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR,
            payload: error,
        });
    }
};

const getHideDepositDetailsSaga = function* ({payload}) {
    try {
        const {
            page,
            getResult = () => {},
        } = payload;

        const result = yield request.get(`${controller}/getHideDetails${page}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_HIDE_DEPOSIT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: GET_HIDE_DEPOSIT_DETAILS_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: GET_HIDE_DEPOSIT_DETAILS_ERROR,
            payload: error,
        });
    }
};

const setHideDepositDetailsSaga = function* ({payload}) {
    try {
        const {
            page,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/setHideDetails${page}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_HIDE_DEPOSIT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SET_HIDE_DEPOSIT_DETAILS_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: SET_HIDE_DEPOSIT_DETAILS_ERROR,
            payload: error,
        });
    }
};

const getClientUserCountSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getClientUserCount`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_USER_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_USER_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_USER_COUNT_ERROR,
            payload: error,
        });
    }
};

const generatePasswordClientMemberSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/generatePassword`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GENERATE_PASSWORD_CLIENT_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GENERATE_PASSWORD_CLIENT_USER_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: GENERATE_PASSWORD_CLIENT_USER_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_MEMBER_READ_REQUEST, clientMemberReadSaga),
        takeEvery(CLIENT_MEMBER_LIST_READ_REQUEST, clientMemberListReadSaga),
        takeEvery(CLIENT_MEMBER_UPDATE_REQUEST, updateClientMemberSaga),
        takeEvery(CLIENT_MEMBER_ADD_REQUEST, addClientMemberSaga),
        takeEvery(CLIENT_CURRENT_MEMBER_READ_REQUEST, clientCurrentMemberReadSaga),
        takeEvery(CLIENT_MEMBERS_READ_REQUEST, clientMembersReadSaga),
        takeEvery(CLIENT_MEMBER_ARCHIVE_REQUEST, clientArchiveMemberSaga),
        takeEvery(CLIENT_SETTING_USERS_REQUEST, getClientUserAndPartnerPageSaga),
        takeEvery(SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST, sendComplaintOnContractorVaccinationCertificateSaga),
        takeEvery(GET_HIDE_DEPOSIT_DETAILS_REQUEST, getHideDepositDetailsSaga),
        takeEvery(SET_HIDE_DEPOSIT_DETAILS_REQUEST, setHideDepositDetailsSaga),
        takeEvery(GET_CLIENT_USER_COUNT_REQUEST, getClientUserCountSaga),
        takeEvery(GENERATE_PASSWORD_CLIENT_USER_REQUEST, generatePasswordClientMemberSaga),
    ]);
}
