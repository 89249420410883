import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import {LoginButton} from "../button";
import LoginCard from "../card";
import LoginCardContent from "../card-content";
import LoginSuccessNotification from "../success-notification";
import LoginTitle from "../title";

import bem from "../../../utils/bem";
import {removePhoneMask} from "../../../utils/stringFormat";
import validate from "../../../utils/validate";

import {joiningRequestRule} from "../../../constants/validationRules";

import {registrationStatusSelector, sendRequest} from "../../../ducks/registration";

import "./style.sass";

const LoginJoin = ({className = ""}) => {
    const [block, element] = bem("joining-request", className);
    const [form, setForm] = useState({
        organizationName: "",
        email: "",
        phone: "",
    });
    const {
        organizationName,
        email,
        phone,
    } = form;
    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [error, setError] = useState({});
    const {
        organizationNameError,
        phoneError,
        emailError,
    } = error;
    const dispatch = useDispatch();
    const progress = useSelector(registrationStatusSelector);

    useEffect(() => {
        if (!isEmpty(error)) {
            validationForm();
        }
    }, [form]);

    const handleChange = (event, {name, value, checked}) => {
        setForm({
            ...form,
            [name]: value || checked,
        });
    };

    const validationForm = () => {
        const data = {
            ...form,
            phone: removePhoneMask(phone),
        };

        const _error = validate(data, joiningRequestRule, "Error");

        setError(_error);

        return Object.keys(_error).length === 0;
    };

    const submit = () => {
        const isValid = validationForm();

        if (!isValid) {
            return;
        }

        const reqData = {
            ...form,
            phone: removePhoneMask(phone),
            email: email ? email : undefined,
            onSuccess: () => {
                setOpenSuccess(true);
            },
            timezoneOffsetToMinutes: new Date().getTimezoneOffset(),
        };

        dispatch(sendRequest({...reqData}));
    };

    return (
        <LoginCard className={block()}>
            {
                isOpenSuccess &&
                <LoginSuccessNotification
                    title="Заявка отправлена"
                    description="Заявка на подключение к сервису Наймикс успешно отправлена. В ближайшее время специалисты компании свяжутся с вами."
                    buttonText="Понятно"
                    onClose={() => {
                        setOpenSuccess(false);
                    }}
                    onSubmit={() => {
                        setOpenSuccess(false);
                    }}
                />
            }
            <LoginTitle>
Заявка на подключение заказчиков к&nbsp;Наймикс
            </LoginTitle>
            <LoginCardContent>
                <NmForm>
                    <NmInputV2
                        required
                        openNaimix
                        size="xl"
                        name="organizationName"
                        label="Название компании"
                        labelClassName="d-flex"
                        placeholder="Введите название компании"
                        className="mb-3 mb-md-4"
                        onChange={handleChange}
                        value={organizationName}
                        error={organizationNameError}
                    />
                    <NmInputV2
                        required
                        openNaimix
                        size="xl"
                        label="Номер телефона"
                        labelClassName="d-flex"
                        mask="+7 (999) 999 99 99"
                        placeholder="Введите номер телефона"
                        onChange={handleChange}
                        name="phone"
                        className="mb-4 mb-md-5"
                        value={phone}
                        error={phoneError}
                    />
                    <NmInputV2
                        required
                        openNaimix
                        size="xl"
                        label="Email"
                        labelClassName="d-flex"
                        placeholder="Введите email"
                        className="mb-3 mb-md-4"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        error={emailError}
                    />
                    <LoginButton
                        primary
                        size="xl"
                        className={element("button")}
                        disabled={progress}
                        loading={progress}
                        onClick={submit}
                    >
                        Отправить заявку
                    </LoginButton>
                </NmForm>
            </LoginCardContent>
        </LoginCard>
    );
};

export default LoginJoin;