import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as IconLock} from "../../images/lock_24.svg";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputPassword from "../ActualComponents/NmInputPassword";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import ApplyButtons from "../ApplyButtons";
import NmButton from "../NmButton";
import NmTitle from "../NmTitle";

import {CLIENT_MEMBER_FIELD_NAME} from "../../constants/clientList";
import {FOREMAN, RECRUITER} from "../../constants/roles";
import {USERS_ROLES_TYPE} from "../../constants/settingsUsersRoles";

import {
    getSettingsUsersRolesRichPage,
    settingsUsersRolesOptionsSelector,
    updateFieldSettingsUsersRoles,
} from "../../ducks/settingsUsersRoles";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../constants/link-params";

const MemberEdit = (props: any) => {
    const {
        title,
        loading,
        formError,
        translate,
        user,
        submit,
        onChange,
        subpage,
        isClient,
        roleOptions,
        promocodePartnerContractorsOptions,
        promocodePartnerClientsOptions,
        toggleEditPassword,
        isEditPassword,
        passwordForm,
        onBlurRepeatPassword,
        errorRepeatPasswordMessage,
        onClose,
        disabledRole,
        innNecessary,
        isAccessToTimeSheet,
    } = props;

    const roleIdOptions = useSelector(settingsUsersRolesOptionsSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSettingsUsersRolesRichPage({
            pageNum: 1,
            pageSize: 100,
            type: USERS_ROLES_TYPE.CLIENT,
            archived: false,
        }));

        return () => {
            dispatch(updateFieldSettingsUsersRoles({
                list: [],
                pageData: {},
                totalCount: 0,
            }));
        };
    }, []);

    function renderPasswords() {
        return (
            <>
                <div className="row">
                    <div className="col-16 col-md-4">
                        <NmRadioV2
                            label="Ввести пароль"
                            checked={!user.sendPassword}
                            name="sendPassword"
                            value={false}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-16 col-md-12">
                        <NmRadioV2
                            label="Сгенерировать автоматически и отправить на почту"
                            checked={user.sendPassword}
                            name="sendPassword"
                            value={true}
                            onChange={onChange}
                        />
                    </div>
                </div>
                {
                    !user.sendPassword &&
                    <>
                        <NmInputPassword
                            labelClassName="d-flex"
                            required
                            label={translate("client-member-new.password")}
                            size="xl"
                            error={formError.password}
                            autoComplete="new-password"
                            name="password"
                            placeholder="Введите пароль"
                            value={passwordForm.password}
                            onChange={onChange}
                        />
                        <NmInputPassword
                            labelClassName="d-flex"
                            required
                            size="xl"
                            error={formError.repeatPassword || errorRepeatPasswordMessage}
                            autoComplete="new-password"
                            name="repeatPassword"
                            placeholder={translate("client-member-new.repeat-password")}
                            value={passwordForm.repeatPassword}
                            onBlur={onBlurRepeatPassword}
                            onChange={onChange}
                        />
                    </>
                }
            </>
        );
    }

    function renderOrderPublication() {
        if (user?.role !== FOREMAN) {
            return null;
        }

        return (
            <>
                <div className="row">
                    <div className="col-8">
                        <NmCheckboxV2
                            onChange={onChange}
                            label="Запрет на публикацию заказов"
                            name="ordersPublicationProhibited"
                            checked={user.ordersPublicationProhibited}
                            isVisibleTooltip
                            tooltip={
                                <HelpTooltip
                                    attention
                                    info
                                    width={18}
                                    height={18}
                                    text="В случае выбора сотруднику будет недоступна публикация заказов на маркетплейсе. Только сохранение заказов в черновик"
                                    position="bottom"
                                />
                            }
                        />
                    </div>
                    <div className={"col-8"}>
                        <NmCheckboxV2
                            onChange={onChange}
                            label="Запрет на формирование актов"
                            name="paymentsCreationProhibited"
                            checked={user.paymentsCreationProhibited}
                            isVisibleTooltip
                            tooltip={
                                <HelpTooltip
                                    attention
                                    info
                                    width={18}
                                    height={18}
                                    text="В случае выбора сотруднику будет недоступно создание актов по заказам для исполнителей"
                                    position="bottom"
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <NmCheckboxV2
                            onChange={onChange}
                            label="Запрет на размещение объявлений"
                            name="pushNotificationPublicationProhibited"
                            checked={user.pushNotificationPublicationProhibited}
                            isVisibleTooltip
                            tooltip={
                                <HelpTooltip
                                    attention
                                    info
                                    width={18}
                                    height={18}
                                    text="В случае выбора сотруднику будет недоступна публикация объявлений на маркетплейсе."
                                    position="bottom"
                                />
                            }
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            className="member-edit"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={loading}
                    onClose={onClose}
                    submitBtnContent={user.clientUserId ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={submit}
                />
            }
        >
            <NmForm
                autoComplete="off"
                addMargin
            >
                <NmTitle size="md">
                    Персональные данные
                </NmTitle>
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label={translate("client-member-new.last-name")}
                    name={CLIENT_MEMBER_FIELD_NAME.LAST_NAME}
                    error={formError?.lastName}
                    value={user.lastName}
                    required
                    placeholder="Введите фамилию"
                    onChange={onChange}
                />
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label={translate("client-member-new.first-name")}
                    name={CLIENT_MEMBER_FIELD_NAME.FIRST_NAME}
                    error={formError.firstName}
                    value={user.firstName}
                    required
                    placeholder="Введите имя"
                    onChange={onChange}
                />
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label={translate("client-member-new.patronymic")}
                    name={CLIENT_MEMBER_FIELD_NAME.PATRONYMIC}
                    error={formError.patronymic}
                    value={user.patronymic}
                    placeholder="Введите отчество"
                    onChange={onChange}
                />
                {
                    isClient &&
                    <>
                        <NmInputV2
                            label="Снилс"
                            size="xl"
                            mask="999-999-999-99"
                            name={CLIENT_MEMBER_FIELD_NAME.SNILS}
                            error={formError[CLIENT_MEMBER_FIELD_NAME.SNILS]}
                            value={user[CLIENT_MEMBER_FIELD_NAME.SNILS]}
                            placeholder="СНИЛС"
                            onChange={onChange}
                        />
                        <NmInputV2
                            required={innNecessary}
                            label="ИНН"
                            size="xl"
                            mask="9999 999999 99"
                            name={CLIENT_MEMBER_FIELD_NAME.INN}
                            error={formError[CLIENT_MEMBER_FIELD_NAME.INN]}
                            value={user[CLIENT_MEMBER_FIELD_NAME.INN]}
                            placeholder="ИНН"
                            onChange={onChange}
                        />
                    </>
                }
                {subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK || isClient &&
                    <>
                        <NmTitle
                            className="mt-8"
                            size="md"
                        >
                            Роль
                        </NmTitle>
                        <NmInputV2
                            size="xl"
                            maxLength={100}
                            placeholder="Введите должность"
                            label={translate("client-member-new.position")}
                            name={CLIENT_MEMBER_FIELD_NAME.POSITION}
                            onChange={onChange}
                            error={formError.position}
                            value={user.position}
                        />
                        <NmDropdownV2
                            required
                            size="xl"
                            placeholder="Выберите роль"
                            disabled={disabledRole}
                            label={translate("client-member-new.role")}
                            name={CLIENT_MEMBER_FIELD_NAME.ROLE}
                            onChange={onChange}
                            error={formError.role}
                            value={user.role}
                            options={roleOptions}
                        />
                        {
                            user.role === RECRUITER &&
                            <NmCheckboxV2
                                onChange={onChange}
                                label="Старший рекрутер"
                                name="seniorRecruiter"
                                checked={user.seniorRecruiter}
                            />
                        }
                        <NmDropdownV2
                            size="xl"
                            placeholder="Выберите роль"
                            disabled={disabledRole}
                            label="Новая роль"
                            name={CLIENT_MEMBER_FIELD_NAME.ROLE_ID}
                            onChange={onChange}
                            error={formError.roleId}
                            value={user.roleId}
                            options={roleIdOptions}
                        />
                        <NmCheckboxV2
                            onChange={onChange}
                            disabled={!isAccessToTimeSheet}
                            label={translate("client-member-new.time-sheet-available")}
                            name="canEditContractorWorkLog"
                            checked={user.canEditContractorWorkLog}
                        />
                        {renderOrderPublication()}
                    </>
                }
                <NmTitle
                    className="mt-8"
                    size="md"
                >
                    Контакты
                </NmTitle>
                <NmInputV2
                    required
                    size="xl"
                    maskChar={null}
                    mask="+7 999 999 9999"
                    label={translate("client-member-new.phone-number")}
                    name={CLIENT_MEMBER_FIELD_NAME.PHONE}
                    error={formError.phone}
                    value={user.phone}
                    placeholder="Введите телефон"
                    onChange={onChange}
                />
                <NmInputV2
                    required
                    size="xl"
                    maxLength={255}
                    autoComplete="off"
                    label="Email"
                    placeholder="Введите email"
                    name={CLIENT_MEMBER_FIELD_NAME.EMAIL}
                    error={formError.email}
                    value={user.email}
                    onChange={onChange}
                />
                <NmTitle
                    className="mt-8"
                    size="md"
                >
                    Настройки заказов
                </NmTitle>
                <NmCheckboxV2
                    label="Дата начала работ не ранее, чем за 3 дня до заказа"
                    name={CLIENT_MEMBER_FIELD_NAME.REQUIRE_WORK_START_DATE_THREE_DAYS_OFFSET_FOR_ORDER}
                    checked={user.requireWorkStartDateThreeDaysOffsetForOrder}
                    onChange={onChange}
                />
                <NmCheckboxV2
                    label="Нет доступа к приглашениям"
                    name={CLIENT_MEMBER_FIELD_NAME.CONTRACTOR_INVITATION_PROHIBITED}
                    checked={user.contractorInvitationProhibited}
                    onChange={onChange}
                />
                <NmTitle
                    className="mt-8"
                    size="md"
                >
                    Логин
                </NmTitle>
                <NmInputV2
                    required
                    size="xl"
                    maxLength={255}
                    autoComplete="off"
                    label="Логин"
                    placeholder="Введите логин"
                    name={CLIENT_MEMBER_FIELD_NAME.LOGIN}
                    error={formError.login}
                    value={user.login}
                    onChange={onChange}
                />
                {subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK &&
                    <>
                        <NmTitle
                            className="mt-8"
                            size="md"
                        >
                            Каналы привлечения
                        </NmTitle>
                        <NmDropdownV2
                            size="xl"
                            label="Каналы привлечения для компаний"
                            placeholder="Каналы привлечения для компаний"
                            search
                            name={CLIENT_MEMBER_FIELD_NAME.PARTNER_PROMOTION_CLIENTS_ID_LIST}
                            onChange={onChange}
                            error={formError.partnerPromotionClientIdList}
                            value={user.partnerPromotionClientIdList || ""}
                            options={promocodePartnerClientsOptions}
                            multiple
                        />
                        <NmDropdownV2
                            size="xl"
                            placeholder="Каналы привлечения для исполнителей"
                            label="Каналы привлечения для исполнителей"
                            search
                            name={CLIENT_MEMBER_FIELD_NAME.PARTNER_PROMOTION_CONTRACTOR_ID_LIST}
                            onChange={onChange}
                            error={formError.partnerPromotionContractorIdList}
                            value={user.partnerPromotionContractorIdList || []}
                            options={promocodePartnerContractorsOptions}
                            multiple
                        />
                    </>
                }
                <NmTitle
                    className="mt-8"
                    size="md"
                >
                    Пароль
                </NmTitle>
                {
                    user.clientUserId &&
                    <NmButton
                        size="xl"
                        color="white"
                        onClick={toggleEditPassword}
                        icon={
                            <IconLock />
                        }
                    >
                        {isEditPassword ? "Отменить" : "Сменить пароль"}
                    </NmButton>
                }
                {
                    isEditPassword && user.clientUserId &&
                    <NmTitle
                        className="mt-8"
                        size="md"
                    >
                        Смена пароля
                    </NmTitle>
                }
                {isEditPassword && renderPasswords()}
            </NmForm>
        </NmModal>
    );
};

export default MemberEdit;