import React from "react";
import {useDispatch, useSelector} from "react-redux";

import CardInfoContainer from "../../../../../components/ActualComponents/CardInfoContainer";
import Filter from "../../../../../components/ActualComponents/Filter";
import {NmLabelTextList} from "../../../../../components/ActualComponents/LabelTextList";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../../components/ActualComponents/NmShowMoreText";
import {RegistryHeaderWithActions} from "../../../../../components/ActualComponents/RegistryHeaderWithActions";
import Text from "../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../components/CheckboxList";
import ExtLink from "../../../../../components/ExtLink";
import NmBadge from "../../../../../components/NmBadge";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import SelectionCountWithAction from "../../../../../components/SelectionCountWithAction";
import {ReactComponent as AddBoxIcon} from "../../../../../images/add_box.svg";
import {AddContractorToAdditionalAgreementRegistry} from "../add-contractor";
import {DocumentsAdditionalAgreementsWarnings} from "../warnings";

import {usePagination} from "../../../../../hooks/usePagination";
import {useSelectedList} from "../../../../../hooks/useSelectedList";
import {useDocumentsAdditionalAgreementsAction} from "./hooks/useAction";
import {useAdditionalAgreementCardFetch} from "./hooks/useFetch";
import {useAdditionalAgreementsCardFilters} from "./hooks/useFilters";

import {formatLocalDate} from "../../../../../utils/dateFormat";
import {getFullName} from "../../../../../utils/stringFormat";
import {isAccessDelete} from "./utils/isAccess";

import {COLOR} from "../../../../../constants/color";
import {
    DOCUMENT_ADDITIONAL_AGREEMENT_BADGE_STATUS_MOD,
    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS,
    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD,
} from "../../../../../constants/documentType";
import {
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CONTRACTOR_PERSONAL_INFO,
} from "../../../../../constants/links";

import {
    bffAdditionalAgreementsRegistryCardSelector,
    bffAdditionalAgreementsRegistryListSelector,
    bffAdditionalAgreementsRegistryProgressCardSelector,
    bffAdditionalAgreementsRegistryProgressSelector,
    bffAdditionalAgreementsRegistryTotalCountSelector,
    bffAdditionalAgreementsRegistryTotalPagesSelector,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/selectors";
import {downloadDocument} from "../../../../../ducks/documents";

export const DocumentsAdditionalAgreementsCard = (props) => {
    const clientId = props.match.params.clientId;
    const id = props.match.params.id;

    const dispatch = useDispatch();

    const card = useSelector(bffAdditionalAgreementsRegistryCardSelector);
    const list = useSelector(bffAdditionalAgreementsRegistryListSelector);
    const progressCard = useSelector(bffAdditionalAgreementsRegistryProgressCardSelector);
    const totalPages = useSelector(bffAdditionalAgreementsRegistryTotalPagesSelector);
    const totalCount = useSelector(bffAdditionalAgreementsRegistryTotalCountSelector);
    const progress = useSelector(bffAdditionalAgreementsRegistryProgressSelector);

    const {
        selectedIds,
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useSelectedList({
        list,
        idName: "id",
    });

    const {
        getRowMediaControls,
        headerControls,
        modalData,
        onCloseModal,
        onClickDeleteRows,
        onClickAddContractor,
    } = useDocumentsAdditionalAgreementsAction({
        id,
        selectedIds,
    });

    const {
        pageNum,
        pageSize,
        paginationData,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useAdditionalAgreementsCardFilters({
        pageSize,
        setPagination,
    });

    useAdditionalAgreementCardFetch({
        paginationData,
        filterData,
        id,
    });

    const getContractorLink = (item) => {
        const text = getFullName(
            item.contractorLastName,
            item.contractorFirstName,
            item.contractorPatronymic,
        );
        const link = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", item.contractorId);

        return (
            <ExtLink to={link}>
                {text}
            </ExtLink>
        );
    };

    const getRows = () => {
        return selectedList.map(item => {
            return {
                ...item,
                key: item.id,
                showCheckBox: true,
                disabledCheckBox: !isAccessDelete(item),
                contentRow: (
                    <NmListCard
                        noDivider={true}
                        checkbox={true}
                        secondaryHeader={
                            item.signedDateTime &&
                            `Дата подписания: ${formatLocalDate(item.signedDateTime)}`
                        }
                        secondaryHeaderStatus={
                            item.status &&
                            <NmBadge
                                mod={DOCUMENT_ADDITIONAL_AGREEMENT_BADGE_STATUS_MOD[item.status?.value]}
                                text={item.status?.description}
                                rightTooltipText={item.failReason}
                            />
                        }
                        primaryHeader={getContractorLink(item)}
                        labels={[
                            {label: "Номер строки", text: item.num},
                            {label: "Версия персональных данных", text: `от ${formatLocalDate(item.personalDataVersionDateTime)}`},
                            {
                                label: "Договор",
                                text: item.relatedFrameContractName,
                                color: "blue",
                                columnOnMobile: true,
                                cursorPointer: true,
                                onClickText: () => {
                                    dispatch(downloadDocument({
                                        isDownload: true,
                                        downloadLink: item.relatedFrameContractPdfDownloadLink,
                                        fileName: `${item.relatedFrameContractName}.pdf`,
                                    }));
                                },
                                noWrap: false,
                            },
                        ]}
                        isFixedActions={true}
                        classNameMainContent="col-16 col-xxl-8"
                        mediaControls={getRowMediaControls(item)}
                    />
                ),
            };
        });
    };

    const getInfo = () => {
        return (
            <CardInfoContainer className="mb-4 mb-xl-24px">
                <Text.Title
                    level="4"
                    bold={true}
                    color={COLOR.SECONDARY_100}
                    className="mb-1 mb-xl-2"
                >
                    {`Реестр доп. соглашений от ${formatLocalDate(card.creationDateTime, "dd.MM.yyyy HH:mm")}`}
                </Text.Title>
                <NmLabelTextList
                    list={[
                        {
                            label: "Ответственный",
                            text: getFullName(
                                card.creatorLastName,
                                card.creatorFirstName,
                                card.creatorPatronymic,
                            ),
                        },
                        {
                            label: "Шаблон доп. соглашения",
                            text: card.documentTemplateName,
                            color: "blue",
                            columnOnMobile: true,
                            cursorPointer: true,
                            onClick: () => {
                                dispatch(downloadDocument({
                                    isDownload: true,
                                    downloadLink: card.documentTemplateDownloadLink,
                                    fileName: `${card.documentTemplateName}.pdf`,
                                }));
                            },
                            noWrap: false,
                        },
                        {
                            label: "Комментарий",
                            text: (
                                <NmShowMoreText
                                    anchor="green"
                                    lines={1}
                                    children={card.commentary}
                                    more="Читать полностью"
                                />
                            ),
                            noWrap: false,
                        },
                    ]}
                />
            </CardInfoContainer>
        );
    };

    const getConfirmWindow = () => {
        if (!modalData?.confirmProps) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile={true}
                content={modalData.confirmProps.content}
                onCancel={onCloseModal}
                onConfirm={modalData.confirmProps.onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getAddContractorModal = () => {
        if (!modalData?.isOpenAddContractor) {
            return null;
        }

        return (
            <AddContractorToAdditionalAgreementRegistry
                id={id}
                clientId={clientId}
                onClose={onCloseModal}
            />
        );
    };

    return (
        <NmPage
            isLoaded={progress || progressCard}
            typeFilter="vertical"
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            widthByFilter={true}
            openFilter={true}
            titleHidden={true}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            header={
                <NmPageHeader
                    noWrapTitle={true}
                    badge={
                        <NmBadge
                            mod={DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD[card.status?.value]}
                            text={card.status?.description}
                        />
                    }
                    backLink={LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST.replace(":clientId", clientId)}
                    text={card.name}
                />
            }
            mediaControls={headerControls}
        >
            <DocumentsAdditionalAgreementsWarnings />
            {getInfo()}
            {getConfirmWindow()}
            {getAddContractorModal()}
            <RegistryHeaderWithActions
                count={totalCount}
                header="Исполнители"
                className="mb-4 mb-xl-24px"
                buttons={[
                    {
                        children: "Добавить исполнителя",
                        icon: <AddBoxIcon />,
                        onClick: onClickAddContractor,
                        visible: [
                            DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.CREATED,
                        ].includes(card.status?.value)
                            && !card.creatorSystem,
                    },
                ]}
            />
            {
                list.length !== 0 ?
                    <CheckboxList
                        rows={getRows()}
                        header={
                            <SelectionCountWithAction
                                adaptiveLogic={true}
                                count={countSelected}
                                buttonColor="grey"
                                buttonContent="Удалить строку"
                                onClick={onClickDeleteRows}
                            />
                        }
                        actionOptions={[
                            {
                                key: "delete",
                                value: "delete",
                                text: "Удалить строку",
                                onClick: onClickDeleteRows,
                                disabled: countSelected === 0,
                            },
                        ]}
                        onSelectedRows={handleSelectedRows}
                    /> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
};