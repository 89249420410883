import React, {FC, FunctionComponent, SVGProps} from "react";

export type TIconDict = {
    [name: string]: FunctionComponent<SVGProps<SVGSVGElement>>
};

interface IDictIcon extends React.SVGProps<SVGSVGElement> {
    name: string,
    dict: TIconDict,
}

const DictIcon: FC<IDictIcon> = (props) => {
    const {
        name,
        dict,
        ...otherProps
    } = props;

    return name && dict[name] ?
        React.createElement(dict[name], {...otherProps} as SVGProps<SVGSVGElement>) :
        null;
};

export default DictIcon;