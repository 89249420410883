import React from "react";

import {STATUS_PAYMENT} from "../../../../constants/status";

export const getPaymentError = (payment) => {
    const {
        bankDetailsError,
        reasonForRefusal,
        confirmationErrorDetails,
    } = payment;

    const paymentStatus = payment.paymentStatus?.value;

    if (confirmationErrorDetails) {
        return confirmationErrorDetails;
    }

    if (
        (
            [
                STATUS_PAYMENT.REJECTED,
                STATUS_PAYMENT.REJECTED_CONTRACTOR,
                STATUS_PAYMENT.CONFIRMED,
            ].includes(paymentStatus)
            && reasonForRefusal !== null)
        || (
            [STATUS_PAYMENT.PAYMENT_ERROR].includes(paymentStatus)
            && bankDetailsError
        )
    ) {
        return (
            <>
                {
                    STATUS_PAYMENT.REJECTED_CONTRACTOR === paymentStatus ?
                        `Исполнитель отклонил акт выполненных работ по причине: ${reasonForRefusal} ` :
                        reasonForRefusal
                }
                {
                    [STATUS_PAYMENT.PAYMENT_ERROR].includes(paymentStatus) &&
                    bankDetailsError
                }
            </>
        );
    }
};