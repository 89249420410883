import axios from "axios";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

import {JOINING_REQUEST_TOAST} from "../constants/messages";

const controller = "registration";

const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
const REGISTRATION_ERROR = "REGISTRATION_ERROR";

const REGISTRATION_STATUS_CHANGE = "REGISTRATION_STATUS_CHANGE";

const REGISTRATION_GET_PAGE_REQUEST = "REGISTRATION_GET_PAGE_REQUEST";
const REGISTRATION_GET_PAGE_SUCCESS = "REGISTRATION_GET_PAGE_SUCCESS";
const REGISTRATION_GET_PAGE_ERROR = "REGISTRATION_GET_PAGE_ERROR";

const initial = {
    progress: null,
    errorMessage: "",
    progressGetPage: false,
    pageData: {},
    totalCount: null,
    clientRegistrationRequests: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case REGISTRATION_GET_PAGE_SUCCESS: {
        const {clientRegistrationRequests, totalCount} = payload;

        return {
            ...state,
            clientRegistrationRequests,
            totalCount,
            progressGetPage: false,
        };
    }
    case REGISTRATION_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progressGetPage: true,
        };
    }
    case REGISTRATION_REQUEST: {
        return {
            ...state,
            progress: true,
        };
    }
    case REGISTRATION_SUCCESS: {
        return {
            ...state,
            progress: false,
        };
    }
    case REGISTRATION_GET_PAGE_ERROR:
    case REGISTRATION_ERROR: {
        return {
            ...state,
            progress: false,
            errorMessage: payload,
        };
    }
    case REGISTRATION_STATUS_CHANGE: {
        return {
            ...state,
            progress: false,
        };
    }
    default:
        return state;
    }
};


export function sendRequest(payload) {
    return {
        type: REGISTRATION_REQUEST,
        payload,
    };
}

export function statusChange() {
    return {
        type: REGISTRATION_STATUS_CHANGE,
    };
}

export function getClientRegistrations(payload) {
    return {
        type: REGISTRATION_GET_PAGE_REQUEST,
        payload,
    };
}

export const registrationSelector = state => state.registration;
export const registrationStatusSelector = createSelector(registrationSelector, ({progress}) => progress);
export const joiningRequestSelector = createSelector(registrationSelector, ({clientRegistrationRequests}) => clientRegistrationRequests);
export const joiningRequestsTotalPagesSelector = createSelector(registrationSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const joiningRequestsTotalCountSelector = createSelector(registrationSelector, ({totalCount}) => totalCount);
export const registrationProgressGetPageSelector = createSelector(registrationSelector, ({progressGetPage}) => progressGetPage);

export const registrationSendSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;
        const result = yield call(axios, {
            url: `api/${controller}/send`,
            method: "post",
            data: reqData,
        });

        const {success, errorMessage} = result.data;

        if (success) {
            yield put({
                type: REGISTRATION_SUCCESS,
                payload: result,
            });

            onSuccess();
        } else {
            toastError(errorMessage);

            yield put({
                type: REGISTRATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }


    } catch (error) {
        toastError(JOINING_REQUEST_TOAST.ERROR);

        yield put({
            type: REGISTRATION_ERROR,
            payload: error,
        });
    }
};


export const registrationRequestsSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRATION_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRATION_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRATION_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRATION_REQUEST, registrationSendSaga),
        takeEvery(REGISTRATION_GET_PAGE_REQUEST, registrationRequestsSaga),
    ]);
}
