import {
    BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_UPDATE_STORE,
} from "./actions";

export const getRecruitmentJobBoards = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST,
        payload,
    };
};

export const getRecruitmentJobBoardsConnectUrl = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST,
        payload,
    };
};

export const authorizeRecruitmentJobBoards = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST,
        payload,
    };
};

export const updateRecruitmentJobBoardsStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_UPDATE_STORE,
        payload,
    };
};