import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getUserAvatarThumbnail,
    updateFieldFilesStore,
} from "../../../../ducks/fileStore";

export const useFetchAvatar = ({contractorId, avatarPresent}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateFieldFilesStore({
                avatarBase64Image: "",
            }));
        };
    }, []);

    useEffect(() => {
        if (contractorId && Boolean(avatarPresent)) {
            getUserAvatar();
        }
    }, [contractorId, avatarPresent]);

    const getUserAvatar = () => {
        dispatch(getUserAvatarThumbnail({contractorId}));
    };

    return {
        getUserAvatar,
    };
};