export const SETTINGS_SUPPORT_FIELD_NAME = {
    ANSWER: "answer",
    CASE_NUMBER: "caseNumber",
    CASE_STATUS: "caseStatus",
    CLIENT_ID: "clientId",
    CONTRACTOR_ID: "contractorId",
    DATE_TIME: "dateTime",
    DESCRIPTION: "description",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    ORDER_ID: "orderId",
    ORDER_NUM: "orderNum",
    PATRONYMIC: "patronymic",
    PAYMENT_NUMBER: "paymentNumber",
    PHONE: "phone",
    TOPIC_OF_APPEAL: "topicOfAppeal",
    TYPE_OF_APPEAL: "typeOfAppeal",
};

export const SETTINGS_NAIMIX_INFO_FIELD_NAME = {
    ADDRESS: "address",
    BANK_NAME: "bankName",
    BIK: "bik",
    DOCUMENT: "document",
    EMAIL: "email",
    INN: "inn",
    INTEGRATED_SOFT: "integratedSoft",
    KPP: "kpp",
    KS: "ks",
    MAIL_ADDRESS: "mailAddress",
    NAME: "name",
    NAMEMIX_REPRESENTATIVE_FIO: "namemixRepresentativeFio",
    OPERATOR: "operator",
    OGRN: "orgn",
    PHONE: "phone",
    POSITION: "position",
    REPRESENTATIVE_DOCUMENT: "representativeDocument",
    REPRESENTATIVE_FIRST_NAME: "representativeFirstName",
    REPRESENTATIVE_LAST_NAME: "representativeLastName",
    REPRESENTATIVE_PATRONYMIC: "representativePatronymic",
    RS: "rs",
    WEB_SITE: "webSite",
};

export const EDO_DOCUMENT_TOAST_SUCCESS = {
    ADD: "Тип документа \":name\" успешно добавлен",
    DELETE: "Тип документа успешно удален",
    EDIT: "Изменения успешно сохранены",
};

export const EDO_STATEMENT_TOAST_SUCCESS = {
    ADD: "Тип заявления \":name\" успешно добавлен",
    DELETE: "Тип заявления успешно удален",
    EDIT: "Изменения успешно сохранены",
};

export const PAYMENT_TYPE = {
    MARKETPLACE: "MARKETPLACE",
    REGISTRY: "REGISTRY",
    API: "API",
};

export const PAYMENT_TYPE_TRANSLATE = {
    [PAYMENT_TYPE.MARKETPLACE]: "По заказам",
    [PAYMENT_TYPE.REGISTRY]: "По реестрам",
    [PAYMENT_TYPE.API]: "По API",
};