import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../../../../components/ActualComponents/Filter";
import NmCheck from "../../../../../../../components/NmCheck";
import {
    getOnboardingScenarioRecruitersNamesList,
    onboardingRecruitersNamesListOptionsSelector,
} from "../../../../../../../ducks/onboardingRecruiters";

import {removePhoneMask} from "../../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../../utils/stringHelper";

import {COMMON_ALL_OPTION} from "../../../../../../../constants/common";
import {
    ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS_OPTIONS,
    ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS_OPTIONS,
} from "../../../../../../../constants/onboarding";

import {bffCommonDictsOnBoardingContractorSourceSelector} from "../../../../../../../ducks/bff/common/dicts/selectors";
import {getCitizenshipOptionsSelector} from "../../../../../../../ducks/contractor";

export function useOnboardingContractorsListFilter({clientId, scenarioId}) {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const dispatch = useDispatch();

    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const onboardingRecruitersNamesListOptions = useSelector(onboardingRecruitersNamesListOptionsSelector);
    const contractorSourceOptions = useSelector(bffCommonDictsOnBoardingContractorSourceSelector);

    const presentOptions = [
        {
            key: "PRESENT",
            text: <NmCheck isCheck={true} />,
            value: "PRESENT",
        },
        {
            key: "NOT_PRESENT",
            text: <NmCheck isCheck={false} />,
            value: "NOT_PRESENT",
        },
    ];
    const progressOptions = [
        {
            key: "COMPLETED",
            text: <NmCheck isCheck={true} />,
            value: "COMPLETED",
        },
        {
            key: "IN_PROGRESS",
            text: <NmCheck isCheck={false} />,
            value: "IN_PROGRESS",
        },
    ];


    useEffect(() => {
        dispatch(getOnboardingScenarioRecruitersNamesList({
            pageSize: 100,
            pageNum: 1,
            clientIdFilter: clientId,
            scenarioIdFilter: scenarioId,
        }));
    }, []);

    function mapFilterDto(filter) {
        const {
            contractorNameFilter,
            contractorPhoneFilter,
            contractorInnFilter,
            mainScenarioStatusFilter,
            sourceFilter,
        } = filter;

        return {
            ...filter,
            contractorNameFilter: handleFormString(contractorNameFilter),
            contractorPhoneFilter: removePhoneMask(contractorPhoneFilter),
            contractorInnFilter: handleFormString(contractorInnFilter),
            boundScenariosStatusFilter: mainScenarioStatusFilter,
            sourceFilter: sourceFilter !== COMMON_ALL_OPTION.key ? sourceFilter : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorNameFilter",
                        label: "ФИО исполнителя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorPhoneFilter",
                        label: "Номер телефона",
                        mask: "+7 (999) 999 99 99",
                        maskChar: null,
                        formatChars: {
                            "1": "[0-6|9]",
                            "9": "[0-9]",
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorInnFilter",
                        label: "ИНН",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "contractorCitizenshipFilter",
                        label: "Гражданство",
                        placeholder: "Выберите гражданство",
                        search: true,
                        multiple: true,
                        options: citizenshipOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "recruiterIdFilter",
                        label: "ФИО рекрутера",
                        search: true,
                        options: onboardingRecruitersNamesListOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "registrationStatusStateFilter",
                        label: "Статус самозанятого",
                        options: presentOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "taxOfferRightsStateFilter",
                        label: "Автоуплата налога",
                        options: presentOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "taxStatusStateFilter",
                        label: "Партнёрство с Наймикс в ФНС",
                        options: presentOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "frameContractStatusFilter",
                        label: "Статус договора",
                        options: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS_OPTIONS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "additionalDocumentsStatus",
                        label: "Статус доп. документов",
                        options: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS_OPTIONS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "otherDocumentStatusFilter",
                        label: "Статус иного документа",
                        options: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS_OPTIONS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "mainScenarioStatusFilter",
                        label: "Статус сценариев",
                        options: progressOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "sourceFilter",
                        label: "Источник",
                        options: contractorSourceOptions,
                    },
                ],
            },
        ];
    }, [citizenshipOptions, presentOptions, progressOptions]);

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    };
}