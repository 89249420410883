import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";

import {ReactComponent as ChatIcon} from "../../../../../../../images/chat_24.svg";
import {ReactComponent as PhoneIcon} from "../../../../../../../images/phone_24.svg";

import {phoneFormat, phoneFormatWithoutSevenNumber} from "../../../../../../../utils/stringFormat";
import {toastSuccess} from "../../../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_CROWD_TASK_CHATS} from "../../../../../../../constants/links";

import {inviteWithdrawCrowdTaskContractors} from "../../../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {getClientPropertiesCardSelector} from "../../../../../../../ducks/clientProperties";

const useCrowdTaskCardContractorListAction = (props) => {
    const {
        fetchList,
        isInvitedPage,
        isQueuePage,
        clientId,
        taskId,
        onOpenConfirm,
        onCloseConfirm,
    } = props;

    const dispatch = useDispatch();

    const {canViewContractorContacts} = useSelector(getClientPropertiesCardSelector);

    const [contractorRejectModalData, setContractorRejectModalData] = useState({});

    const handleCopyContact = (phone) => {
        copy(phoneFormatWithoutSevenNumber(phone));
        toastSuccess("Номер телефона скопирован.");
    };

    const getMediaControls = (item) => {
        const {
            phone,
            contractorId,
            chatUnreadMessagesCount,
        } = item || {};

        return {
            renderCount: {
                desktop: 4,
                tablet: 4,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON_CONTACT,
                    props: {
                        onClick: () => handleCopyContact(phone),
                        icon: <PhoneIcon />,
                        color: "grey",
                        onlyIcon: true,
                        size: "lg",
                        popup: phoneFormat(phone),
                    },
                    asset: {
                        mobile: {
                            children: "Скопировать телефон",
                        },
                    },
                    visible: canViewContractorContacts && Boolean(phone),
                },
                {
                    component: COMPONENT.BUTTON_CONTACT,
                    props: {
                        isLink: true,
                        href: LINK_CLIENT_CROWD_TASK_CHATS
                            .replace(":clientId", clientId)
                            .replace(":contractorId?", contractorId),
                        onlyIcon: true,
                        icon: <ChatIcon />,
                        color: "grey",
                        size: "lg",
                        popup: "Перейти в чат",
                        showIndicator: Boolean(chatUnreadMessagesCount),
                    },
                    asset: {
                        mobile: {
                            children: "Перейти в чат",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        size: "xl",
                        onClick: () => onOpenConfirm({
                            content: "Вы действительно хотите отозвать приглашение?",
                            onConfirm: () => inviteWithdraw(contractorId),
                        }),
                        children: "Отозвать приглашение",
                    },
                    visible: isInvitedPage,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "xl",
                        onClick: () => setContractorRejectModalData({
                            clientId,
                            taskId,
                            contractorId,
                        }),
                        children: "Отказать",
                    },
                    visible: isQueuePage,
                },
            ],
        };
    };

    const inviteWithdraw = (contractorId) => {
        dispatch(inviteWithdrawCrowdTaskContractors({
            clientId,
            taskId,
            contractorId,
            onSuccess: () => {
                fetchList();
                onCloseConfirm();
                toastSuccess("Приглашение отозвано");
            },
        }));
    };

    return {
        handleCopyContact,
        getMediaControls,
        contractorRejectModalData,
        setContractorRejectModalData,
    };
};

export default useCrowdTaskCardContractorListAction;