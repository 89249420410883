import React, {useContext} from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import RangeStepInput from "../../../../../../components/RangeStepInput";
import {MapInviteModalContext} from "../context-provider";

import {specialitiesAllV2OptionsSelector} from "../../../../../../ducks/speciality";

const MapInviteModalGeneralForm = () => {
    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);

    const {
        values,
        handleChange,
        isInviteToOrderOrTask,
    } = useContext(MapInviteModalContext);

    return (
        <NmForm addMargin={true}>
            <RangeStepInput
                required={true}
                step={1}
                min={1}
                max={15}
                label={
                    isInviteToOrderOrTask ?
                        "Радиус поиска заказа/задания" :
                        "Радиус поиска исполнителей"
                }
                className="fluid"
                name="distance"
                value={values.distance}
                onDistanceChange={handleChange}
            />
            {
                isInviteToOrderOrTask ?
                    <NmDateRangePickerV2
                        size="xl"
                        label="Период выполнения работ"
                        startFieldName="workDateFrom"
                        endFieldName="workDateTo"
                        value={{
                            workDateFrom: values.workDateFrom,
                            workDateTo: values.workDateTo,
                        }}
                        isClearable={true}
                        onChange={handleChange}
                    /> :
                    <NmDatePicker
                        size="xl"
                        label="Дата последнего визита в приложение"
                        placeholderText="дд.мм.гг"
                        dateFormat="dd.MM.yy"
                        selected={values.lastLoginDateFromFilter}
                        name="lastLoginDateFromFilter"
                        onChange={handleChange}
                        maxDate={new Date()}
                    />
            }
            <NmDropdownV2
                size="xl"
                name="specialityIdsFilter"
                label={`Вид деятельности ${isInviteToOrderOrTask ? "заказа/задания" : ""}`}
                placeholder={`Выберите вид деятельности ${isInviteToOrderOrTask ? "заказа/задания" : ""}`}
                options={specialityOptions}
                value={values.specialityIdsFilter}
                onChange={handleChange}
                search={true}
                multiple={true}
            />
        </NmForm>
    );
};

export default MapInviteModalGeneralForm;