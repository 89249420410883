import React, {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import NmLabelText from "../../../../../../components/ActualComponents/NmLabelText";

import {getFullName} from "../../../../../../utils/stringFormat";

//Вынесено в отдельный компонент, т.к на ios условие криво отрабатывает, нужна доп перерисовка, чтобы отображалось

function ActRegistryCardCreatorInfo({card}) {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (!isEmpty(card)) {
            setValue(getFullName(card.creatorLastName, card.creatorFirstName, card.creatorPatronymic));
        }
    }, [card]);

    return (
        <NmLabelText
            type="page"
            label="Ответственный"
            text={value || "Не определено"}
        />
    );
}

export default ActRegistryCardCreatorInfo;