import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {useModulesAndTariffsEditModalForm} from "./hooks/useForm";

import {clientModuleTariffsProgressActionSelector} from "../../../../../../ducks/bff/clients/module-tariffs/selectors";

const ModulesAndTariffsEditModal = (props) => {
    const {
        clientId,
        onClose,
        editData = {},
        fetch,
    } = props;

    const progressAction = useSelector(clientModuleTariffsProgressActionSelector);

    const isEdit = !isEmpty(editData);

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    } = useModulesAndTariffsEditModalForm({
        clientId,
        editData,
        isEdit,
        onClose,
        fetch,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit
                        ? `Редактирование модуля "${editData.tariffName}"`
                        : "Добавление модуля"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submit={handleSubmit}
                    onClose={onClose}
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={progressAction}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    required={true}
                    size="xl"
                    label="Название"
                    placeholder="Введите название"
                    name="tariffName"
                    value={values.tariffName}
                    onChange={handleChange}
                    error={touched.tariffName && errors.tariffName}
                />
                <NmInputV2
                    required={true}
                    size="xl"
                    label="Тариф"
                    placeholder="Укажите тариф"
                    name="tariffValue"
                    value={values.tariffValue}
                    onChange={handleChange}
                    error={touched.tariffValue && errors.tariffValue}
                />
                <NmTextareaV2
                    label="Описание"
                    placeholder="Введите описание"
                    maxLength={500}
                    minRows={3}
                    name="tariffDescription"
                    value={values.tariffDescription}
                    onChange={handleChange}
                    error={touched.tariffDescription && errors.tariffDescription}
                />
            </NmForm>
        </NmModal>
    );
};

export default ModulesAndTariffsEditModal;