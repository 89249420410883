import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import Text from "../../../../../components/ActualComponents/Text";
import AmountInput from "../../../../../components/AmountInput";
import DefaultDropzone from "../../../../../components/DefaultDropzone";
import NmButton from "../../../../../components/NmButton";
import NmIcon from "../../../../../components/NmIcon";
import OrderDropdown from "../../../../../components/OrderDropdown";
import OrderKeywords from "../../../../../components/OrderKeywords";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../../images/file_download.svg";
import {ReactComponent as DocumentIcon} from "../../../../../images/text_snippet_24.svg";
import OrderEditCalculator from "../../calculator";
import OrderEditFieldVolume from "../../field/volume";
import {isValidWorkersCount} from "../../index";
import OrderEditFormGeneralDescription from "./components/description";
import {OrderEditOutTime} from "./components/out-time";
import {OrderEditFormWorkUnit} from "./components/unit-of-work";
import {Loader} from "semantic-ui-react";

import {addDays} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {ORDER_FIELDS_NAME} from "../../../../../constants/clientList";
import {
    ORDER_CREATION_MODE,
    ORDER_EDIT_SPECIALTIES_TEXT,
} from "../../../../../constants/order";
import {NM_COORDINATOR} from "../../../../../constants/roles";

import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {fileStoreOrderDocumentProgressSelector} from "../../../../../ducks/fileStore";
import {orderWorkUnitsOptionsSelector} from "../../../../../ducks/order";
import {specialitiesProgressByClientIdSelector} from "../../../../../ducks/speciality";

const DOCUMENTS_TYPES = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
const MAX_SIZE = 4194304;

class OrderEditGeneral extends Component {
    role = ls(USER_ROLE);

    state = {
        fileError: null,
        name: "",
    };

    get translate() {
        const {t} = this.props;

        return {
            forOnePersonText: t("order-new.forOnePersonText"),
            selectFromList: t("order-new.selectFromList"),
            exampleName: t("order-new.exampleName"),
            nameSuggestionTitle: t("order-new.nameSuggestionTitle"),
            nameSuggestionContent: t("order-new.nameSuggestionContent"),
            descriptionLabel: t("order-new.descriptionLabel"),
            descriptionPlaceholder: t("order-new.descriptionPlaceholder"),
            descriptionSuggestionContent: t("order-new.descriptionSuggestionContent"),
            dateSuggestionContent: t("order-new.dateSuggestionContent"),
            countPerformersSuggestionContent: t("order-new.countPerformersSuggestionContent"),
            countPerformersLabel: t("order-new.countPerformersLabel"),
            orderPriceLabel: t("order-new.order-price"),
            noResultMessage: t("order-new.noResultMessage"),
            workPeriodHeader: t("order-new.work-period"),
            orderNameLabel: t("order-new.orderNameLabel"),
            documentationLabel: t("order-new.documentationLabel"),
            nameTemplateLabel: t("order-new.nameTemplateLabel"),
            placeholderTemplate: t("order-new.placeholderTemplate"),
        };
    }

    onAmountChange = (event, {name, value}) => {
        const {handleChange} = this.props;

        handleChange(event, {name, value: value.replace(/\s/g, "")});
    };

    onChangeContractorNeededCount = (event, {name, value}) => {
        const {
            handleChange,
            isSecondType,
            formData: {
                workUnit,
            },
        } = this.props;

        if (isSecondType && workUnit === "SERVICE" && isValidWorkersCount(value) && value !== "") {
            handleChange(event, {name: ORDER_FIELDS_NAME.VOLUME_OF_WORK, value});
        }

        handleChange(event, {name, value});
    };

    dropDocument = (files) => {
        const {dropDocument} = this.props;

        if (!files && !files.length) {
            return;
        }

        const [file] = files;
        const {size} = file;

        const documentFileUrl = URL.createObjectURL(file);

        if (size > MAX_SIZE) {
            this.setState({
                fileError: "Максимальный размер файла - 4 мб",
            });

            return;
        }

        const {fileError} = this.state;

        if (fileError) {
            this.setState({
                fileError: null,
            });
        }

        dropDocument({documentFile: file, documentFileUrl});
    };

    removeDocument = () => {
        const {dropDocument} = this.props;

        dropDocument({documentFile: {}, documentFileUrl: ""});
    };

    renderDocument() {
        const {
            documentFile: {name},
            documentFileUrl,
            progressDocument,
            isDisabled,
        } = this.props;
        const {
            fileError,
        } = this.state;
        const {
            documentationLabel,
        } = this.translate;

        return (
            <div className="order-edit__col">
                <div className="order-edit__i-header">
                    {documentationLabel}
                </div>
                <div className={`order-edit__file-zone ${name ? "order-edit__file-zone_uploaded" : ""}`}>
                    {
                        progressDocument ?
                            <Loader
                                className="order-edit__loader"
                                active
                                inline
                                size="tiny"
                            /> :
                            <div className="order-edit__file-zone-content">
                                <DefaultDropzone
                                    className="order-edit__dropzone"
                                    multiple={false}
                                    accept={DOCUMENTS_TYPES}
                                    onDropFiles={this.dropDocument}
                                    disabled={isDisabled}
                                >
                                    {
                                        !name ?
                                            <NmButton
                                                color="grey"
                                                icon={
                                                    <AddIcon />
                                                }
                                                disabled={isDisabled}
                                                className="order-edit__add-document"
                                            >
                                                Добавить документ
                                            </NmButton> :
                                            <div className="order-edit__document">
                                                <DocumentIcon className="order-edit__document-icon" />
                                                <Text
                                                    level="3"
                                                    title={name}
                                                    className="order-edit__document-name"
                                                >
                                                    {name}
                                                </Text>
                                            </div>
                                    }
                                </DefaultDropzone>
                                {
                                    name &&
                                    <div className="order-edit__document">
                                        <a
                                            download={name}
                                            href={documentFileUrl}
                                        >
                                            <FileDownloadIcon className="order-edit__document-icon" />
                                        </a>
                                        {
                                            !isDisabled &&
                                            <CloseIcon
                                                className="order-edit__document-icon order-edit__document-icon_ml order-edit__document-icon_red"
                                                onClick={this.removeDocument}
                                            />
                                        }
                                    </div>
                                }
                            </div>

                    }
                    {
                        fileError &&
                        <div className="order-edit__file-zone-error">
                            {fileError}
                        </div>
                    }
                </div>
            </div>
        );
    }

    getDropdownAdditionalContent = () => {
        const {
            isDisabledOfferedSpeciality,
        } = this.props;

        return !isDisabledOfferedSpeciality ?
            <div className="order-edit__specialties-additional">
                <div className="order-edit__specialties-p">
                    {ORDER_EDIT_SPECIALTIES_TEXT.INSTRUCTION}
                </div>
                <div className="order-edit__specialties-p">
                    {ORDER_EDIT_SPECIALTIES_TEXT.ABOUT}
                    <NmIcon
                        className="order-edit__specialties-icon"
                        name="like"
                    />
                    <NmIcon
                        className="order-edit__specialties-icon"
                        name="dislike"
                    />
                </div>
            </div> :
            null;
    };

    render() {
        const {
            formData,
            formData: {
                insurance,
            },
            formError,
            isPromptOn,
            handleChange,
            handleFocus,
            periodMinDate,
            isDisabled,
            isSecondType,
            calculatorData,
            specialitiesOptions,
            offeredSpecialityName,
            specialityExistsError,
            progressSpecialities,
            isDisabledOfferedSpeciality,
            saveSpecialityByEnter,
            isDisabledSpeciality,
            creatingMode,
            isForemans,
            order,
            scale,
            isMobile,
            objectLatitude,
            objectLongitude,
            isDuplicateOrder,
            currentMember,
        } = this.props;

        const {
            selectFromList,
            exampleName,
            nameSuggestionTitle,
            nameSuggestionContent,
            descriptionLabel,
            descriptionPlaceholder,
            descriptionSuggestionContent,
            dateSuggestionContent,
            countPerformersSuggestionContent,
            countPerformersLabel,
            orderPriceLabel,
            workPeriodHeader,
            orderNameLabel,
            nameTemplateLabel,
            placeholderTemplate,
        } = this.translate;

        const isCreatingModeTemplate = creatingMode === ORDER_CREATION_MODE.TEMPLATE;
        const isRequireWorkStartDateThreeDaysOfOrder = !isCreatingModeTemplate
            && currentMember.requireWorkStartDateThreeDaysOffsetForOrder
            && !isForemans;

        return (
            <div className="order-edit__main-content-row">
                <div className="order-edit__fields">
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        <div className="col-16 col-md-8 mb-3 mb-md-0">
                            <OrderDropdown
                                disabled={
                                    isDisabledSpeciality ||
                                    progressSpecialities ||
                                    isDisabled ||
                                    (
                                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                        Boolean(order.specialityId)
                                    )
                                }
                                label="Вид деятельности"
                                options={specialitiesOptions}
                                additionalContent={this.getDropdownAdditionalContent()}
                                search
                                required
                                name="specialityId"
                                placeholder={selectFromList}
                                onChange={handleChange}
                                value={formData.specialityId}
                                error={specialityExistsError || formError.speciality}
                                onFocus={handleFocus}
                                valueWhichNotInOptions={offeredSpecialityName}
                                isCustomField={isDisabledOfferedSpeciality}
                                saveSpecialityByEnter={saveSpecialityByEnter}
                            />
                        </div>
                        <div className="col-16 col-md-8">
                            <NmDateRangePickerV2
                                disabled={
                                    this.role === NM_COORDINATOR ||
                                    (
                                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                        Boolean(order.workEndDate)
                                    )
                                }
                                required={!isCreatingModeTemplate}
                                size="xl"
                                label={workPeriodHeader}
                                startFieldName="workStartDate"
                                endFieldName="workEndDate"
                                minDate={{
                                    start: isRequireWorkStartDateThreeDaysOfOrder
                                        ? addDays(new Date(), 3)
                                        : periodMinDate,
                                    end: formData.workStartDate || new Date(),
                                }}
                                value={{
                                    workStartDate: formData.workStartDate,
                                    workEndDate: formData.workEndDate,
                                }}
                                onChange={handleChange}
                                dateFormat="dd.MM.yy"
                                returnString={false}
                                onMouseEnter={handleFocus}
                                isVisibleTooltip={isPromptOn}
                                tooltip={
                                    <HelpTooltip
                                        info
                                        hover
                                        text={dateSuggestionContent}
                                        position="bottom"
                                    />
                                }
                                error={formError.workStartDate || formError.workEndDate}
                            />
                        </div>
                    </div>
                    {
                        isCreatingModeTemplate &&
                        <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                            <NmInputV2
                                size="xl"
                                label={nameTemplateLabel}
                                disabled={
                                    isDisabled ||
                                    (
                                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                        Boolean(order.name)
                                    )
                                }
                                required
                                maxLength={100}
                                error={formError.templateName}
                                name="templateName"
                                value={formData.templateName}
                                className="order-edit__input"
                                placeholder={placeholderTemplate}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                        </div>
                    }
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        <NmInputV2
                            size="xl"
                            label={orderNameLabel}
                            disabled={
                                isDisabled ||
                                (
                                    creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                    Boolean(order.name)
                                )
                            }
                            maxLength={100}
                            required={!isCreatingModeTemplate}
                            error={formError.name}
                            name="name"
                            value={formData.name}
                            placeholder={exampleName}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            isVisibleTooltip={isPromptOn && !isCreatingModeTemplate}
                            tooltip={
                                <HelpTooltip
                                    info
                                    hover
                                    compound
                                    body={[{textBlock: nameSuggestionContent}]}
                                    header={{title: nameSuggestionTitle}}
                                    position="bottom-left"
                                />
                            }
                            onMouseEnter={handleFocus}
                        />
                    </div>
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        <div className="col-16 col-md-8">
                            <OrderEditOutTime
                                label="Время выхода"
                                disabled={
                                    isDisabled ||
                                    (
                                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                        Boolean(order.outTime)
                                    )
                                }
                                nameHours="outTimeHours"
                                nameMinutes="outTimeMinutes"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                timeHours={formData.outTimeHours}
                                timeMinutes={formData.outTimeMinutes}
                            />
                        </div>
                        <div className="col-16 col-md-8">
                            <OrderEditOutTime
                                label="Длительность заказа"
                                disabled={
                                    isDisabled ||
                                    (
                                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                        Boolean(order.timeForExecutionHours)
                                    )
                                }
                                nameHours="timeForExecutionHours"
                                nameMinutes="timeForExecutionMinutes"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                timeHours={formData.timeForExecutionHours}
                                timeMinutes={formData.timeForExecutionMinutes}
                            />
                        </div>
                    </div>
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        <OrderEditFormGeneralDescription
                            label={descriptionLabel}
                            disabled={
                                isDisabled ||
                                (
                                    creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                    Boolean(order.description)
                                )
                            }
                            error={formError.description}
                            suggestionContent={descriptionSuggestionContent}
                            required={!isCreatingModeTemplate}
                            value={formData.description}
                            placeholder={descriptionPlaceholder}
                            onFocus={handleFocus}
                            onMouseEnter={handleFocus}
                            isVisibleTooltip={isPromptOn}
                        />
                    </div>
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        <OrderKeywords
                            disabled={
                                isDisabled ||
                                (
                                    creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                    !isEmpty(order.keywords)
                                )
                            }
                            error={formError.keywords}
                            name={ORDER_FIELDS_NAME.KEYWORDS}
                            values={formData.keywords}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onMouseEnter={handleFocus}
                            isVisibleTooltip={isPromptOn}
                        />
                    </div>
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        {
                            isSecondType ?
                                <>
                                    <div className="col-16 col-md-6 mb-3 mb-md-0">
                                        <OrderEditFormWorkUnit
                                            isSecondType={isSecondType}
                                            isDisabled={isDisabled}
                                            creatingMode={creatingMode}
                                            onFocus={handleFocus}
                                            onMouseEnter={handleFocus}
                                            isVisibleTooltip={isPromptOn}
                                            scale={scale}
                                            formData={formData}
                                            formError={formError}
                                            objectLatitude={objectLatitude}
                                            objectLongitude={objectLongitude}
                                        />
                                    </div>
                                    <div className="col-16 col-md-5 mb-3 mb-md-0">
                                        <OrderEditFieldVolume
                                            required={!isCreatingModeTemplate}
                                            disabled={
                                                isDisabled ||
                                                (
                                                    formData[ORDER_FIELDS_NAME.WORK_UNIT] === "SERVICE" &&
                                                    isSecondType
                                                ) ||
                                                (
                                                    creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                                    Boolean(order.volumeOfWork)
                                                )
                                            }
                                            error={formError.volumeOfWork}
                                            value={formData.volumeOfWork}
                                            onChange={handleChange}
                                            isVisibleTooltip={isPromptOn}
                                            onFocus={handleFocus}
                                            unitValue={formData.workUnit}
                                        />
                                    </div>
                                </> :
                                <div className="col-16 col-md-8">
                                    <AmountInput
                                        size="xl"
                                        newInput
                                        label={orderPriceLabel}
                                        required={!isCreatingModeTemplate}
                                        disabled={
                                            isDisabled ||
                                            (
                                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                                Boolean(order.amount)
                                            )
                                        }
                                        name="amount"
                                        error={formError.amount}
                                        value={formData.amount}
                                        placeholder="0,00"
                                        onChange={this.onAmountChange}
                                        onFocus={handleFocus}
                                    />
                                </div>
                        }
                        <div className={isSecondType ? "col-16 col-md-5" : "col-16 col-md-8"}>
                            <NmInputV2
                                size="xl"
                                label={countPerformersLabel}
                                required={!isCreatingModeTemplate}
                                disabled={
                                    isDisabled ||
                                    (
                                        creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                        Boolean(order.contractorsNeededCount)
                                    )
                                }
                                name={isSecondType ? "contractorsNeededCountSecondType" : "contractorsNeededCount"}
                                error={isSecondType ? formError.contractorsNeededCountSecondType : formError.contractorsNeededCount}
                                value={isSecondType ? formData.contractorsNeededCountSecondType : formData.contractorsNeededCount}
                                placeholder="0"
                                onChange={this.onChangeContractorNeededCount}
                                onFocus={handleFocus}
                                errorClassName="order-edit__error-keywords"
                                onMouseEnter={handleFocus}
                                isVisibleTooltip={isPromptOn}
                                tooltip={
                                    <HelpTooltip
                                        info
                                        hover
                                        text={countPerformersSuggestionContent}
                                        position="bottom-right"
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        <NmCheckboxV2
                            disabled={
                                Boolean(order.status) &&
                                order.contractorRecruitmentRequired &&
                                !isCreatingModeTemplate &&
                                !isDuplicateOrder
                            }
                            label="Требуется подбор исполнителя"
                            name="contractorRecruitmentRequired"
                            checked={formData.contractorRecruitmentRequired}
                            onChange={handleChange}
                            isVisibleTooltip={true}
                            tooltip={
                                <HelpTooltip
                                    text={
                                        <div>
                                            Публикуя заказ с параметром «Требуется подбор», вы получаете:
                                            <ul>
                                                <li>
Подбор исполнителей на заказ нашими рекрутерами
                                                </li>
                                                <li>
Приоритетную выдачу вашего заказа для исполнителей
                                                </li>
                                                <li>
Рассылку пуш-уведомлений исполнителям о вашем заказе
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    position="top"
                                    noPointerEvents={true}
                                    info={true}
                                    hover={true}
                                />
                            }
                        />
                    </div>
                    <div className="row gx-0 gx-md-4 mb-3 mb-md-4">
                        {this.renderDocument()}
                    </div>
                </div>
                <OrderEditCalculator
                    isMobile={isMobile}
                    insurance={insurance}
                    isSecondType={isSecondType}
                    calculatorData={calculatorData}
                />
            </div>
        );
    }
}

export default connect(state => ({
    unitOptions: orderWorkUnitsOptionsSelector(state),
    progressDocument: fileStoreOrderDocumentProgressSelector(state),
    progressSpecialities: specialitiesProgressByClientIdSelector(state),
    currentMember: clientCurrentMemberSelector(state),
}), null,
)(withTranslation()(OrderEditGeneral));