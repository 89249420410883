import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import {ACT_REGISTRY_SEARCH_STEP} from "../index";
import validationSchema from "../validation";

import {removePhoneMask} from "../../../../../../../utils/stringFormat";

import {CONTRACTOR_SEARCH_TYPE} from "../../../../../../../constants/registry";

import {getContractorList} from "../../../../../../../ducks/contractor";

const useDataForm = ({clientId, list, totalCount, progressSearch, pageNum}) => {
    const [isSearchContractor, setIsSearchContractor] = useState(false);
    const [step, setStep] = useState(ACT_REGISTRY_SEARCH_STEP.STEP_1);
    const [prevStep, setPrevStep] = useState("");
    const [contractorData, setContractorData] = useState({});

    const onSetStep = (newStep) => {
        setPrevStep(step);
        setStep(newStep);
    };

    const dispatch = useDispatch();

    const initialValues = {
        phoneNumber: "",
        fullName: "",
        inn: "",
        searchType: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
    };

    useEffect(() => {
        if (isSearchContractor && !progressSearch) {
            if (totalCount === 0) {
                const errorData = {
                    name: "",
                    errorText: "",
                };

                if (values.searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE) {
                    errorData.name = "phoneNumber";
                    errorData.errorText = "Исполнитель с данным номером не найден";
                }

                if (values.searchType === CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE) {
                    errorData.name = "fullName";
                    errorData.errorText = "Исполнитель с данным ФИО не найден";
                }

                if (values.searchType === CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE) {
                    errorData.name = "inn";
                    errorData.errorText = "Исполнитель с данным ИНН не найден";
                }

                setErrors({
                    [errorData.name]: errorData.errorText,
                });

                return;
            }

            if (totalCount === 1) {
                const [{contractorId}] = list;

                selectContractor({contractorId});

                return;
            }

            setStep(ACT_REGISTRY_SEARCH_STEP.STEP_2);
        }
    }, [list]);

    useEffect(() => {
        if (isSearchContractor) {
            searchContractor();
        }
    }, [pageNum]);

    const selectContractor = (item) => {
        setContractorData(item);
        onSetStep(ACT_REGISTRY_SEARCH_STEP.STEP_3);
    };

    const getFilterType = () => {
        const {searchType} = values;

        if (searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE) {
            return "phoneFilter";
        }

        if (searchType === CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE) {
            return "fullName";
        }

        return "innFilter";
    };

    const getFilterValue = () => {
        const {
            searchType,
            phoneNumber,
            fullName,
            inn,
        } = values;

        if (searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE) {
            return removePhoneMask(phoneNumber);
        }

        if (searchType === CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE) {
            return fullName.trim();
        }

        return inn;
    };

    const searchContractor = () => {
        if (!isValid) {
            return;
        }

        dispatch(getContractorList({
            [getFilterType()]: getFilterValue(),
            sortType: "FIO_ASC",
            pageSize: 5,
            pageNum,
            clientId,
        }));

        setIsSearchContractor(true);
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
        handleChange,
        touched,
        setFieldTouched,
        setErrors,
    } = useFormik({
        onSubmit: searchContractor,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        touched,
        setFieldTouched,
        setIsSearchContractor,
        selectContractor,
        searchContractor,
        step,
        setStep,
        onSetStep,
        contractorData,
        prevStep,
    };
};

export default useDataForm;