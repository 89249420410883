import {
    BFF_CLIENTS_USERS_SETTINGS_UPDATE_STORE,
    BFF_COMMON_TREE_LIST_ERROR,
    BFF_COMMON_TREE_LIST_REQUEST,
    BFF_COMMON_TREE_LIST_SUCCESS,
    BFF_COMMON_TREE_OBJECTS_PAGE_ERROR,
    BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST,
    BFF_COMMON_TREE_OBJECTS_PAGE_SUCCESS,
    BFF_COMMON_TREE_PROJECTS_PAGE_ERROR,
    BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST,
    BFF_COMMON_TREE_PROJECTS_PAGE_SUCCESS,
    BFF_COMMON_TREE_SAVE_ERROR,
    BFF_COMMON_TREE_SAVE_REQUEST,
    BFF_COMMON_TREE_SAVE_SUCCESS,
} from "./actions";

import {getTotalPages} from "../../../../utils/mathHelper";

const initial = {
    projects: {
        clientInfo: {},
        list: [],
        totalCount: 0,
        pageData: {},
    },
    objects: {
        lists: {},
    },
    projectsAndObjects: {},
    progress: false,
    progressAction: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_COMMON_TREE_LIST_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case BFF_COMMON_TREE_LIST_SUCCESS:
        return {
            ...state,
            progress: false,
            projectsAndObjects: payload,
        };
    case BFF_COMMON_TREE_LIST_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_COMMON_TREE_SAVE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case BFF_COMMON_TREE_SAVE_ERROR:
        return {
            ...state,
            progressAction: false,
            error: payload,
        };
    case BFF_COMMON_TREE_SAVE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST: {
        const [projectId] = payload.projectIdsFilter;

        const {
            [projectId]: objects = {},
        } = state.objects.lists;

        return {
            ...state,
            objects: {
                ...state.objects,
                lists: {
                    ...state.objects.lists,
                    [projectId]: {
                        ...objects,
                        isLoading: true,
                        pageData: payload,
                    },
                },
            },
        };
    }
    case BFF_COMMON_TREE_OBJECTS_PAGE_ERROR:
        return {
            ...state,
            objects: {
                ...state.objects,
                progress: false,
            },
        };
    case BFF_COMMON_TREE_OBJECTS_PAGE_SUCCESS: {
        const {
            [payload.projectId]: objects = {},
        } = state.objects.lists;
        const {list = []} = objects;
        const {
            totalCount,
            results,
            nextAllPagesSelectionType,
            ...otherPayload
        } = payload;

        return {
            ...state,
            objects: {
                ...state.objects,
                ...otherPayload,
                lists: {
                    ...state.objects.lists,
                    [payload.projectId]: {
                        ...objects,
                        list: [
                            ...list,
                            ...results,
                        ],
                        nextAllPagesSelectionType,
                        isLoading: false,
                        totalCount: totalCount,
                        totalPages: getTotalPages(totalCount, objects.pageData.pageSize),
                    },
                },
            },
        };
    }
    case BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST:
        return {
            ...state,
            projects: {
                ...state.projects,
                pageData: payload,
                progress: true,
            },
        };
    case BFF_COMMON_TREE_PROJECTS_PAGE_ERROR:
        return {
            ...state,
            progress: false,
            projects: {
                ...state.projects,
                progress: false,
            },
        };
    case BFF_COMMON_TREE_PROJECTS_PAGE_SUCCESS:
        const {
            results,
            totalCount,
            ...otherPayload
        } = payload;

        return {
            ...state,
            projects: {
                ...state.projects,
                clientInfo: otherPayload,
                list: state.projects.pageData.isSearchCleared ?
                    results :
                    [
                        ...state.projects.list,
                        ...results,
                    ],
                totalCount,
                progress: false,
            },
        };
    case BFF_CLIENTS_USERS_SETTINGS_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default: return state;
    }
};