import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getAdvertisementPage} from "../../../../ducks/advertisement";

export function useAdvertisementFetchList({filter = {}, clientId, pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        setLoading(true);

        const requestData = {
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            ...filter,
            getResult: () => {
                setTimeout(() => setLoading(false), 500);
            },
        };

        dispatch(getAdvertisementPage(requestData));
    };

    return {
        loading,
        fetchList,
    };
}