import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {
    getPageRecruitmentDirectoryFunnel,
    getPageRecruitmentDirectoryJobRequirement,
    updateRecruitmentDirectoryStore,
} from "../../../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    addRecruitmentVacancy,
    getTotalCandidatesRecruitmentVacancy,
    updateRecruitmentVacancy,
} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import validationSchema from "../validation";

import {getInitialTouched} from "../../../../../../utils/objectHelper";
import {handleNumber} from "../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../utils/toastHelper";
import {getVacancyEditReqData, getVacancyFormData} from "../../../utils/dto";

import {EMPTY_OPTION_KEY} from "../../../../../../constants/dropdown";
import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES,
} from "../../../../../../constants/links";
import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";
import {RECRUITMENT_VACANCY_STATUS} from "../../../constants";

import {history} from "../../../../../../store/configureStore";

import {downloadDocument} from "../../../../../../ducks/documents";
import {getFile} from "../../../../../../ducks/fileStore";

const useRecruitmentVacancyEditForm = (props) => {
    const {
        onClose,
        fetchData,
        isEdit,
        editData,
        clientId,
    } = props;

    const [confirmData, setConfirmData] = useState({});

    const initialValues = useMemo(() => {
        const {
            isDuplicate,
            gender,
            publishDateFrom,
            publishDateTo,
            recruiterId,
            ...otherEditData
        } = editData;

        return {
            recruiterId: recruiterId || "",
            totalCandidatesCount: 0,
            title: "",
            description: "",
            dateFrom: null,
            dateTo: null,
            publishDateFrom: !isDuplicate && publishDateFrom ? publishDateFrom : null,
            publishDateTo: !isDuplicate && publishDateTo ? publishDateTo : null,
            workAddressFiasId: null,
            workAddressInfo: "",
            specialityId: null,
            citizenships: [],
            requirementIds: [],
            ageFrom: "",
            ageTo: "",
            gender: gender || EMPTY_OPTION_KEY,
            salaryFrom: "",
            salaryTo: "",
            funnelId: null,
            files: editData.fileName ? [{
                name: editData.fileName,
                filePath: editData.filePath,
                isRemote: true,
            }] : [],
            isDuplicate,
            ...otherEditData,
        };
    }, [editData]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPageRecruitmentDirectoryJobRequirement({clientId}));
        dispatch(getPageRecruitmentDirectoryFunnel({clientId}));

        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                jobRequirementPageData: {},
                jobRequirementList: [],
                jobRequirementTotalCount: 0,
                funnelPageData: {},
                funnelList: [],
                funnelTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        if (editData.fileName) {
            const {
                fileName,
                filePath,
            } = editData;

            dispatch(getFile({
                fileName,
                filePath,
                setFile: ({file}) => {
                    setFieldValue("fileValue", file);
                },
            }));
        }
    }, [editData]);

    const getSubmitSuccessText = (status) => {
        if (status === RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE) {
            return "Вакансия взята в работу";
        }

        if (status === RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE && !isEdit) {
            return "Вакансия успешно создана";
        }

        return "Вакансия успешно обновлена";
    };

    const onSubmit  = async (status) => {
        if (status === RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE && !values.title) {
            setFieldError("title", VALIDATIONS_MESSAGE.REQUIRED);
            setFieldTouched("title", true);

            return;
        }

        let errors = {};

        if (status !== RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE) {
            errors = await validateForm();
        }

        if (!isEmpty(errors)) {
            await setTouched(getInitialTouched(values, true));

            return;
        }

        const formData = getVacancyFormData({
            clientId,
            status,
            ...getVacancyEditReqData(values),
        });

        const reqData ={
            formData,
            onSuccess: () => {
                toastSuccess(getSubmitSuccessText(status));
                fetchData();
                onClose();
            },
        };

        if (isEdit) {
            dispatch(updateRecruitmentVacancy({
                vacancyId: editData.vacancyId,
                ...reqData,
            }));

            return;
        }

        dispatch(addRecruitmentVacancy({
            ...reqData,
            getResult: ({id}) => {
                if (id) {
                    const link = LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", id);

                    history.push(link);
                }
            },
        }));
    };

    const handleDownload = ({fileName, filePath}) => {
        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink: filePath,
        }));
    };

    const openPublishConfirm = async () => {
        const errors = await validateForm();

        if (!isEmpty(errors)) {
            await setTouched(getInitialTouched(values, true));

            return;
        }

        setConfirmData({
            content: "Вы действительно хотите взять вакансию в работу? В случае подтверждения статус вакансии меняется на \"В работу\" и она становится доступна для размещения на джоб-бордах",
            onConfirm: () => onSubmit(RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE),
        });
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    const checkCandidatesCount = () => {
        const reqData = {
            genderFilter: values.gender !== EMPTY_OPTION_KEY ? values.gender : undefined,
            ageFromFilter: handleNumber(values.ageFrom),
            ageToFilter: handleNumber(values.ageTo),
            addressFiasIdsFilter: values.workAddressFiasId ? [values.workAddressFiasId] : undefined,
            specialityIdsFilter: values.specialityId ? [values.specialityId] : undefined,
            citizenshipFilter: values.citizenships.length ? values.citizenships : undefined,
        };

        dispatch(getTotalCandidatesRecruitmentVacancy({
            clientId,
            ...reqData,
            getResult: (result) => {
                setFieldValue("totalCandidatesCount", result.value || 0);
            },
        }));
    };

    const onChangeDropzone = (files) => {
        if (!isEmpty(values.fileValue)) {
            setFieldValue("fileValue", null);
        }

        setFieldValue("files", files);
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        handleBlur,
        errors,
        validateForm,
        handleChange,
        setTouched,
        setFieldTouched,
        setFieldError,
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema: validationSchema(),
        enableReinitialize: true,
    });

    return {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        handleChange,
        confirmData,
        handleBlur,
        handleDownload,
        onSubmit,
        onCloseConfirm,
        openPublishConfirm,
        checkCandidatesCount,
        onChangeDropzone,
    };
};

export default useRecruitmentVacancyEditForm;