import {all, call,put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getProgressExport} from "./job";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {UPLOAD_STATUS, UPLOAD_TYPE} from "../constants/financeExport";
import {FINANCE_EXPORT_MESSAGES} from "../constants/messages";

const controller = "/financesexport";

const FINANCE_EXPORT_GET_PAGE_REQUEST = "FINANCE_EXPORT_GET_PAGE_REQUEST";
const FINANCE_EXPORT_GET_PAGE_SUCCESS = "FINANCE_EXPORT_GET_PAGE_SUCCESS";
const FINANCE_EXPORT_GET_PAGE_ERROR = "FINANCE_EXPORT_GET_PAGE_ERROR";

const FINANCE_EXPORT_ADD_TASK_REQUEST = "FINANCE_EXPORT_ADD_TASK_REQUEST";
const FINANCE_EXPORT_ADD_TASK_SUCCESS = "FINANCE_EXPORT_ADD_TASK_SUCCESS";
const FINANCE_EXPORT_ADD_TASK_ERROR = "FINANCE_EXPORT_ADD_TASK_ERROR";

const FINANCES_EXPORT_GET_RECEIPT_REQUEST = "FINANCES_EXPORT_GET_RECEIPT_REQUEST";
const FINANCES_EXPORT_GET_RECEIPT_SUCCESS = "FINANCES_EXPORT_GET_RECEIPT_SUCCESS";
const FINANCES_EXPORT_GET_RECEIPT_ERROR = "FINANCES_EXPORT_GET_RECEIPT_ERROR";

const FINANCES_EXPORT_LINK_SAVE = "FINANCES_EXPORT_LINK_SAVE";
const FINANCES_EXPORT_UPDATE_STORE = "FINANCES_EXPORT_UPDATE_STORE";

const IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST = "IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST";
const IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS = "IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS";
const IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR = "IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR";

const initial = {
    taskList: [],
    loading: false,
    totalCount: 0,
    pageData: {},
    addTaskSuccess: false,
    backLink: "",
    progress: false,
    isReferralPromoCodeClientsRewardsExportAvailable: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case FINANCES_EXPORT_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    case FINANCES_EXPORT_LINK_SAVE:
        return {
            ...state,
            backLink: payload,
        };
    case FINANCE_EXPORT_GET_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            loading: true,
            addTaskSuccess: false,
        };
    case FINANCE_EXPORT_ADD_TASK_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case FINANCE_EXPORT_ADD_TASK_SUCCESS:
        return {
            ...state,
            loading: false,
            addTaskSuccess: true,
        };
    case FINANCE_EXPORT_GET_PAGE_SUCCESS:
        return {
            ...state,
            taskList: payload.financesModels,
            totalCount: payload.totalCount,
            loading: false,
        };
    case FINANCE_EXPORT_ADD_TASK_ERROR:
    case FINANCE_EXPORT_GET_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            loading: false,
        };
    case IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS:
        return {
            ...state,
            isReferralPromoCodeClientsRewardsExportAvailable: payload.result,
        };
    case FINANCES_EXPORT_GET_RECEIPT_REQUEST:
    case FINANCES_EXPORT_GET_RECEIPT_SUCCESS:
    case FINANCES_EXPORT_GET_RECEIPT_ERROR:
    default:
        return state;
    }
};

export function getFinanceExportPage(payload) {
    return {
        type: FINANCE_EXPORT_GET_PAGE_REQUEST,
        payload,
    };
}

export function addFinanceExportTask(payload) {
    return {
        type: FINANCE_EXPORT_ADD_TASK_REQUEST,
        payload,
    };
}

export function getReceiptByOrder(payload) {
    return {
        type: FINANCES_EXPORT_GET_RECEIPT_REQUEST,
        payload,
    };
}

export function saveFinanceLinkBack(payload) {
    return {
        type: FINANCES_EXPORT_LINK_SAVE,
        payload,
    };
}

export function updateFinanceStore(payload) {
    return {
        type: FINANCES_EXPORT_UPDATE_STORE,
        payload,
    };
}

export function getReferralPromoCodeClientsRewardsExportAvailable() {
    return {
        type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST,
    };
}

export const financeExportSelector = state => state.financeExport;
export const financeBackLinkSelector = createSelector(financeExportSelector, ({backLink}) => backLink);
export const financeExportDataSelector = createSelector(financeExportSelector, ({taskList}) => taskList);

export const addFinanceExportSuccessTaskSelector = createSelector(financeExportSelector, ({addTaskSuccess}) => addTaskSuccess);

export const financeExportLoadingSelector = createSelector(financeExportSelector, ({loading}) => loading);

export const financeExportTotalPagesSelector = createSelector(financeExportSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const financeExportTotalCountSelector = createSelector(financeExportSelector, ({totalCount}) => {
    return totalCount;
});

export const financeExportDataTaskIdsSelector = createSelector(financeExportSelector, ({taskList}) => (taskList
    .filter(({uploadStatus}) => uploadStatus === UPLOAD_STATUS.INITIALIZATION.key))
    .map(({taskId}) => (taskId))
    .filter(Boolean));
export const referralPromoCodeClientsRewardsExportAvailableSelector = createSelector(financeExportSelector, ({isReferralPromoCodeClientsRewardsExportAvailable}) => isReferralPromoCodeClientsRewardsExportAvailable);

export const getFinanceExportPageSaga = function* ({payload}) {
    try {

        const {onSuccess, ...rest} = payload;
        const result = yield request.post(`${controller}/getFinancePage`, rest);
        if (result.errorMessage) {
            yield put({
                type: FINANCE_EXPORT_GET_PAGE_ERROR,
                payload: result.errorMessage,
            });
            toastError(result.errorMessage);
            return {
                done: true,
            };
        }

        if (onSuccess) {
            yield call(onSuccess, result);
        }

        yield put({
            type: FINANCE_EXPORT_GET_PAGE_SUCCESS, payload: result,
        });

        const state = yield select();
        const taskInProgressIds = financeExportDataTaskIdsSelector(state);

        if (taskInProgressIds.length !== 0) {
            yield put(getProgressExport({
                reqData: taskInProgressIds,
            }));
        }
    } catch (error) {
        yield put({
            type: FINANCE_EXPORT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};
//POST /api/job/export/addExport
export const addFinanceExportTaskSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const {errorMessage, error, ...result} = yield request.post("/job/export/addExport", data);
        if (errorMessage || error) {
            yield put({
                type: FINANCE_EXPORT_ADD_TASK_ERROR,
                payload: errorMessage,
            });
            toastError(errorMessage || "Не удалось создать задачу");
            return {
                done: true,
            };
        }
        yield put({
            type: FINANCE_EXPORT_ADD_TASK_SUCCESS, payload: result,
        });

        const {uploadType} = data;

        if (getResult) {
            getResult(errorMessage);
        } else {
            toastSuccess([
                UPLOAD_TYPE.REGISTRY_EXPORT.value,
                UPLOAD_TYPE.REGISTRY_EXPORT_WITH_TABLE.value,
                UPLOAD_TYPE.ACT_REGISTRY_EXPORT.value,
            ].includes(uploadType) ? FINANCE_EXPORT_MESSAGES.ADD_TASK_EXPORT_REGISTRY_SUCCESS : FINANCE_EXPORT_MESSAGES.ADD_TASK_SUCCESS);
        }

    } catch (error) {
        yield put({
            type: FINANCE_EXPORT_ADD_TASK_ERROR,
            payload: error,
        });
    }
};

export const getReceiptByOrderSaga = function* (action) {
    try {
        const {payload} = action;
        const {clientId, orderId} = payload;

        const result = yield request.get(`${controller}/getReceiptFileByOrderId/${clientId}/${orderId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCES_EXPORT_GET_RECEIPT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FINANCES_EXPORT_GET_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCES_EXPORT_GET_RECEIPT_ERROR,
            payload: error,
        });
    }
};

//GET /api/financesexport/isReferralPromoCodeClientsRewardsExportAvailable
// Получить флаг возможности выгрузки экспорта промо кодов клиентов
export const getReferralPromoCodeClientsRewardsExportAvailableSaga = function* () {
    try {
        const {errorMessage, ...result} = yield request.get(`${controller}/isReferralPromoCodeClientsRewardsExportAvailable`);

        if (errorMessage) {
            yield put({
                type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR,
                payload: errorMessage,
            });

            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FINANCE_EXPORT_GET_PAGE_REQUEST, getFinanceExportPageSaga),
        takeEvery(FINANCE_EXPORT_ADD_TASK_REQUEST, addFinanceExportTaskSaga),
        takeEvery(FINANCES_EXPORT_GET_RECEIPT_REQUEST, getReceiptByOrderSaga),
        takeEvery(IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST, getReferralPromoCodeClientsRewardsExportAvailableSaga),
    ]);
}