import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useConfirm, {closeConfirmAction, onConfirmAction, openConfirmAction} from "../../../../../hooks/useConfirm";

import {
    categorySpecialitiesProgressSelector,
    deleteCategorySpecialities,
} from "../../../../../ducks/categorySpecialities";

export const useCategorySpecialitiesAction = ({fetchList}) => {
    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm(true);

    const dispatch = useDispatch();
    const progress = useSelector(categorySpecialitiesProgressSelector);

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData(null);
    };

    const onOpenEdit = (editData) => {
        if (editData) {
            setEditData(editData);
        }

        setOpenEdit(true);
    };

    const onDelete = (id, onClose) => {
        dispatch(deleteCategorySpecialities({
            id,
            onSuccess: () => {
                onClose();
                fetchList();
            },
        }));
    };

    const onConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    const onOpenDelete = ({id}) => {
        dispatchConfirm(
            openConfirmAction(
                "Вы уверены что хотите удалить запись справочника?",
                ({onClose}) => onDelete(id, onClose),
            ),
        );
    };

    const onCancelConfirm = () => {
        dispatchConfirm(closeConfirmAction());
    };

    return {
        isOpenEdit,
        progress,
        editData,
        isOpenConfirm,
        contentConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    };
};