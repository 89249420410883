import {isEmpty} from "lodash";
import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getContractorVerificationPersonalData} from "../containers/contractor/contractor-verification-data/utils/dto";
import {getApplicationCount} from "../utils/contractors";
import {getFiasIdByAddressType, getWorkAddressInfoArr} from "../utils/dadata";
import {getTotalPages} from "../utils/mathHelper";
import {citizenshipToOptions, dictionaryToOptions, filterObject, retailObjectsToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {getFullName, phoneFormat} from "../utils/stringFormat";
import {toastError, toastSuccess, toastWarning} from "../utils/toastHelper";

import {
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE,
    REMOTE_WORK_OPTION,
} from "../constants/contractor";
import {CONTRACTOR_CARD_FIELDS} from "../constants/contractorInfo";
import {CONTRACTOR_MESSAGE} from "../constants/messages";
import {CONTRACTOR} from "../constants/roles";

const controller = "/contractors";
//*  TYPES  *//

const CONTRACTOR_RICH_READ_REQUEST = "CONTRACTOR_RICH_READ_REQUEST";
const CONTRACTOR_RICH_READ_SUCCESS = "CONTRACTOR_RICH_READ_SUCCESS";
const CONTRACTOR_RICH_READ_ERROR = "CONTRACTOR_RICH_READ_ERROR";

const CONTRACTOR_READ_REQUEST = "CONTRACTOR_READ_REQUEST";
const CONTRACTOR_READ_SUCCESS = "CONTRACTOR_READ_SUCCESS";
const CONTRACTOR_READ_ERROR = "CONTRACTOR_READ_ERROR";

const CONTRACTOR_ADD_REQUEST = "CONTRACTOR_ADD_REQUEST";
const CONTRACTOR_ADD_SUCCESS = "CONTRACTOR_ADD_SUCCESS";
const CONTRACTOR_ADD_ERROR = "CONTRACTOR_ADD_ERROR";

const CONTRACTOR_UPDATE_REQUEST = "CONTRACTOR_UPDATE_REQUEST";
const CONTRACTOR_UPDATE_SUCCESS = "CONTRACTOR_UPDATE_SUCCESS";
const CONTRACTOR_UPDATE_ERROR = "CONTRACTOR_UPDATE_ERROR";

const CONTRACTOR_LIST_READ_REQUEST = "CONTRACTOR_LIST_READ_REQUEST";
const CONTRACTOR_LIST_READ_SUCCESS = "CONTRACTOR_LIST_READ_SUCCESS";
const CONTRACTOR_LIST_READ_ERROR = "CONTRACTOR_LIST_READ_ERROR";

const CONTRACTOR_ORDER_STATUS_DICT_REQUEST = "CONTRACTOR_ORDER_STATUS_DICT_REQUEST";
const CONTRACTOR_ORDER_STATUS_DICT_SUCCESS = "CONTRACTOR_ORDER_STATUS_DICT_SUCCESS";
const CONTRACTOR_ORDER_STATUS_DICT_ERROR = "CONTRACTOR_ORDER_STATUS_DICT_ERROR";

const CONTRACTOR_ADD_FAVORITE_REQUEST = "CONTRACTOR_ADD_FAVORITE_REQUEST";
const CONTRACTOR_ADD_FAVORITE_SUCCESS = "CONTRACTOR_ADD_FAVORITE_SUCCESS";
const CONTRACTOR_ADD_FAVORITE_ERROR = "CONTRACTOR_ADD_FAVORITE_ERROR";

const CONTRACTOR_ADD_TO_BLACKLIST_REQUEST = "CONTRACTOR_ADD_TO_BLACKLIST_REQUEST";
const CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS = "CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS";
const CONTRACTOR_ADD_TO_BLACKLIST_ERROR = "CONTRACTOR_ADD_TO_BLACKLIST_ERROR";

const CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST = "CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST";
const CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS = "CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS";
const CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR = "CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR";

const CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST = "CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST";
const CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS = "CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS";
const CONTRACTOR_DELETE_FROM_FAVORITE_ERROR = "CONTRACTOR_DELETE_FROM_FAVORITE_ERROR";

const CONTRACTOR_RICH_LIST_READ_REQUEST = "CONTRACTOR_RICH_LIST_READ_REQUEST";
const CONTRACTOR_RICH_LIST_READ_SUCCESS = "CONTRACTOR_RICH_LIST_READ_SUCCESS";
const CONTRACTOR_RICH_LIST_READ_ERROR = "CONTRACTOR_RICH_LIST_READ_ERROR";

const BFF_CONTRACTOR_RICH_LIST_READ_REQUEST = "BFF_CONTRACTOR_RICH_LIST_READ_REQUEST";
const BFF_CONTRACTOR_RICH_LIST_READ_SUCCESS = "BFF_CONTRACTOR_RICH_LIST_READ_SUCCESS";
const BFF_CONTRACTOR_RICH_LIST_READ_ERROR = "BFF_CONTRACTOR_RICH_LIST_READ_ERROR";

const CONTRACTOR_SHORT_LIST_READ_REQUEST = "CONTRACTOR_SHORT_LIST_READ_REQUEST";
const CONTRACTOR_SHORT_LIST_READ_SUCCESS = "CONTRACTOR_SHORT_LIST_READ_SUCCESS";
const CONTRACTOR_SHORT_LIST_READ_ERROR = "CONTRACTOR_SHORT_LIST_READ_ERROR";

const CONTRACTOR_HIRING_RICH_LIST_READ_REQUEST = "CONTRACTOR_HIRING_RICH_LIST_READ_REQUEST";
const CONTRACTOR_HIRING_RICH_LIST_READ_SUCCESS = "CONTRACTOR_HIRING_RICH_LIST_READ_SUCCESS";
const CONTRACTOR_HIRING_RICH_LIST_READ_ERROR = "CONTRACTOR_HIRING_RICH_LIST_READ_ERROR";

const CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST = "CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST";
const CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS = "CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS";
const CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR = "CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR";

const CONTRACTOR_CLEAR_STORE = "CONTRACTOR_CLEAR_STORE";

const CONTRACTOR_UPDATE_FIELD_STORE = "CONTRACTOR_UPDATE_FIELD_STORE";

const CONTRACTOR_CITIZENSHIP_REQUEST = "CONTRACTOR_CITIZENSHIP_REQUEST";
const CONTRACTOR_CITIZENSHIP_SUCCESS = "CONTRACTOR_CITIZENSHIP_SUCCESS";
const CONTRACTOR_CITIZENSHIP_ERROR = "CONTRACTOR_CITIZENSHIP_ERROR";

const CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST = "CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST";
const CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS = "CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS";
const CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR = "CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR";

const CONTRACTOR_VHI_POLICY_FORMAT_REQUEST = "CONTRACTOR_VHI_POLICY_FORMAT_REQUEST";
const CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS = "CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS";
const CONTRACTOR_VHI_POLICY_FORMAT_ERROR = "CONTRACTOR_VHI_POLICY_FORMAT_ERROR";

const CONTRACTOR_VHI_POLICY_TYPE_REQUEST = "CONTRACTOR_VHI_POLICY_TYPE_REQUEST";
const CONTRACTOR_VHI_POLICY_TYPE_SUCCESS = "CONTRACTOR_VHI_POLICY_TYPE_SUCCESS";
const CONTRACTOR_VHI_POLICY_TYPE_ERROR = "CONTRACTOR_VHI_POLICY_TYPE_ERROR";

const CONTRACTOR_WORKREGION_REQUEST = "CONTRACTOR_WORKREGION_REQUEST";
const CONTRACTOR_WORKREGION_SUCCESS = "CONTRACTOR_WORKREGION_SUCCESS";
const CONTRACTOR_WORKREGION_ERROR = "CONTRACTOR_WORKREGION_ERROR";

const CONTRACTOR_SUPPORT_REQUEST = "CONTRACTOR_SUPPORT_REQUEST";
const CONTRACTOR_SUPPORT_SUCCESS = "CONTRACTOR_SUPPORT_SUCCESS";
const CONTRACTOR_SUPPORT_ERROR = "CONTRACTOR_SUPPORT_ERROR";

const CONTRACTOR_SUPPORT_CARD_REQUEST = "CONTRACTOR_SUPPORT_CARD_REQUEST";
const CONTRACTOR_SUPPORT_CARD_SUCCESS = "CONTRACTOR_SUPPORT_CARD_SUCCESS";
const CONTRACTOR_SUPPORT_CARD_ERROR = "CONTRACTOR_SUPPORT_CARD_ERROR";

const CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST = "CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST";
const CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS = "CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS";
const CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR = "CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR";

const CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST = "CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST";
const CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS = "CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS";
const CONTRACTOR_SUPPORT_CASE_STATUS_ERROR = "CONTRACTOR_SUPPORT_CASE_STATUS_ERROR";

const CONTRACTOR_SUPPORT_UPDATE_REQUEST = "CONTRACTOR_SUPPORT_UPDATE_REQUEST";
const CONTRACTOR_SUPPORT_UPDATE_SUCCESS = "CONTRACTOR_SUPPORT_UPDATE_SUCCESS";
const CONTRACTOR_SUPPORT_UPDATE_ERROR = "CONTRACTOR_SUPPORT_UPDATE_ERROR";

const CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST = "CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST";
const CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS = "CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS";
const CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR = "CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR";

const GET_CONTRACTOR_BY_PHONE_REQUEST = "GET_CONTRACTOR_BY_PHONE_REQUEST";
const GET_CONTRACTOR_BY_PHONE_SUCCESS = "GET_CONTRACTOR_BY_PHONE_SUCCESS";
const GET_CONTRACTOR_BY_PHONE_ERROR = "GET_CONTRACTOR_BY_PHONE_ERROR";

const GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_REQUEST = "GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_REQUEST";
const GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_SUCCESS = "GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_SUCCESS";
const GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_ERROR = "GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_ERROR";

const CONTRACTOR_CHANGE_AUTO_TAXPAY_REQUEST = "CONTRACTOR_CHANGE_AUTO_TAXPAY_REQUEST";
const CONTRACTOR_CHANGE_AUTO_TAXPAY_SUCCESS = "CONTRACTOR_CHANGE_AUTO_TAXPAY_SUCCESS";
const CONTRACTOR_CHANGE_AUTO_TAXPAY_ERROR = "CONTRACTOR_CHANGE_AUTO_TAXPAY_ERROR";

const CONTRACTOR_CANCEL_AUTO_TAXPAY_REQUEST = "CONTRACTOR_CANCEL_AUTO_TAXPAY_REQUEST";
const CONTRACTOR_CANCEL_AUTO_TAXPAY_SUCCESS = "CONTRACTOR_CANCEL_AUTO_TAXPAY_SUCCESS";
const CONTRACTOR_CANCEL_AUTO_TAXPAY_ERROR = "CONTRACTOR_CANCEL_AUTO_TAXPAY_ERROR";

const CONTRACTOR_CHECK_PROMOCODE_REQUEST = "CONTRACTOR_CHECK_PROMOCODE_REQUEST";
const CONTRACTOR_CHECK_PROMOCODE_SUCCESS = "CONTRACTOR_CHECK_PROMOCODE_SUCCESS";
const CONTRACTOR_CHECK_PROMOCODE_ERROR = "CONTRACTOR_CHECK_PROMOCODE_ERROR";

const CONTRACTOR_REGISTRATION_PROMOCODE_REQUEST = "CONTRACTOR_REGISTRATION_PROMOCODE_REQUEST";
const CONTRACTOR_REGISTRATION_PROMOCODE_SUCCESS = "CONTRACTOR_REGISTRATION_PROMOCODE_SUCCESS";
const CONTRACTOR_REGISTRATION_PROMOCODE_ERROR = "CONTRACTOR_REGISTRATION_PROMOCODE_ERROR";

const GET_PROMOCODE_BY_CONTRACTOR_ID_REQUEST = "GET_PROMOCODE_BY_CONTRACTOR_ID_REQUEST";
const GET_PROMOCODE_BY_CONTRACTOR_ID_SUCCESS = "GET_PROMOCODE_BY_CONTRACTOR_ID_SUCCESS";
const GET_PROMOCODE_BY_CONTRACTOR_ID_ERROR = "GET_PROMOCODE_BY_CONTRACTOR_ID_ERROR";

const GET_CONTRACTOR_ORDER_STATISTICS = "GET_CONTRACTOR_ORDER_STATISTICS";
const SET_CONTRACTOR_ORDER_STATISTICS = "SET_CONTRACTOR_ORDER_STATISTICS";
const GET_CONTRACTOR_ORDER_STATISTICS_ERROR = "GET_CONTRACTOR_ORDER_STATISTICS_ERROR";

const GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST = "GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST";
const GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS = "GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS";
const GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR = "GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR";

const GET_CONTRACTOR_COUNT_REQUEST = "GET_CONTRACTOR_COUNT_REQUEST";
const GET_CONTRACTOR_COUNT_SUCCESS = "GET_CONTRACTOR_COUNT_SUCCESS";
const GET_CONTRACTOR_COUNT_ERROR = "GET_CONTRACTOR_COUNT_ERROR";

const ARCHIVE_CONTRACTOR_REQUEST = "ARCHIVE_CONTRACTOR_REQUEST";
const ARCHIVE_CONTRACTOR_SUCCESS = "ARCHIVE_CONTRACTOR_SUCCESS";
const ARCHIVE_CONTRACTOR_ERROR = "ARCHIVE_CONTRACTOR_ERROR";

const CONTRACTOR_ARCHIVE_PHONES_REQUEST = "CONTRACTOR_ARCHIVE_PHONES_REQUEST";
const CONTRACTOR_ARCHIVE_PHONES_SUCCESS = "CONTRACTOR_ARCHIVE_PHONES_SUCCESS";
const CONTRACTOR_ARCHIVE_PHONES_ERROR = "CONTRACTOR_ARCHIVE_PHONES_ERROR";

const CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST";
const CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS";
const CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR";

const CONTRACTOR_STATUS_DICT_REQUEST = "CONTRACTOR_STATUS_DICT_REQUEST";
const CONTRACTOR_STATUS_DICT_SUCCESS = "CONTRACTOR_STATUS_DICT_SUCCESS";
const CONTRACTOR_STATUS_DICT_ERROR = "CONTRACTOR_STATUS_DICT_ERROR";

const GET_CONTRACTORS_BY_ORDER_REQUEST = "GET_CONTRACTORS_BY_ORDER_REQUEST";
const GET_CONTRACTORS_BY_ORDER_SUCCESS = "GET_CONTRACTORS_BY_ORDER_SUCCESS";
const GET_CONTRACTORS_BY_ORDER_ERROR = "GET_CONTRACTORS_BY_ORDER_ERROR";

const GET_ALL_APPLICATIONS_REQUEST = "GET_ALL_APPLICATIONS_REQUEST";
const GET_ALL_APPLICATIONS_SUCCESS = "GET_ALL_APPLICATIONS_SUCCESS";
const GET_ALL_APPLICATIONS_ERROR = "GET_ALL_APPLICATIONS_ERROR";

const GET_CONTRACTORS_RETAILS_PAGE_REQUEST = "GET_CONTRACTORS_RETAILS_PAGE_REQUEST";
const GET_CONTRACTORS_RETAILS_PAGE_SUCCESS = "GET_CONTRACTORS_RETAILS_PAGE_SUCCESS";
const GET_CONTRACTORS_RETAILS_PAGE_ERROR = "GET_CONTRACTORS_RETAILS_PAGE_ERROR";

const UPDATE_CONTRACTOR_RETAIL_REQUEST = "UPDATE_CONTRACTOR_RETAIL_REQUEST";
const UPDATE_CONTRACTOR_RETAIL_SUCCESS = "UPDATE_CONTRACTOR_RETAIL_SUCCESS";
const UPDATE_CONTRACTOR_RETAIL_ERROR = "UPDATE_CONTRACTOR_RETAIL_ERROR";

const GET_NOTE_REQUEST = "GET_NOTE_REQUEST";
const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS";
const GET_NOTE_ERROR = "GET_NOTE_ERROR";

const ADD_NOTE_REQUEST = "ADD_NOTE_REQUEST";
const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";

const UPDATE_NOTE_REQUEST = "UPDATE_NOTE_REQUEST";
const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
const UPDATE_NOTE_ERROR = "UPDATE_NOTE_ERROR";

const GET_BLOCKED_HISTORY_REQUEST = "GET_BLOCKED_HISTORY_REQUEST";
const GET_BLOCKED_HISTORY_SUCCESS = "GET_BLOCKED_HISTORY_SUCCESS";
const GET_BLOCKED_HISTORY_ERROR = "GET_BLOCKED_HISTORY_ERROR";

const GET_PHONES_BY_XLSX_FILE_REQUEST = "GET_PHONES_BY_XLSX_FILE_REQUEST";
const GET_PHONES_BY_XLSX_FILE_SUCCESS = "GET_PHONES_BY_XLSX_FILE_SUCCESS";
const GET_PHONES_BY_XLSX_FILE_ERROR = "GET_PHONES_BY_XLSX_FILE_ERROR";

const GET_BLOCKED_CONTRACTORS_REQUEST = "GET_BLOCKED_CONTRACTORS_REQUEST";
const GET_BLOCKED_CONTRACTORS_SUCCESS = "GET_BLOCKED_CONTRACTORS_SUCCESS";
const GET_BLOCKED_CONTRACTORS_ERROR = "GET_BLOCKED_CONTRACTORS_ERROR";

const GET_PENDING_PERSONAL_DATA_REQUEST = "GET_PENDING_PERSONAL_DATA_REQUEST";
const GET_PENDING_PERSONAL_DATA_SUCCESS = "GET_PENDING_PERSONAL_DATA_SUCCESS";

const POST_PENDING_PERSONAL_DATA_REQUEST = "POST_PENDING_PERSONAL_DATA_REQUEST";
const POST_PENDING_PERSONAL_DATA_SUCCESS = "POST_PENDING_PERSONAL_DATA_SUCCESS";
const POST_PENDING_PERSONAL_DATA_ERROR = "POST_PENDING_PERSONAL_DATA_ERROR";

const GET_CONTRACTOR_DATA_BANK_STATE_SUCCESS = "GET_CONTRACTOR_DATA_BANK_STATE_SUCCESS";
const GET_CONTRACTOR_DATA_BANK_STATE_REQUEST = "GET_CONTRACTOR_DATA_BANK_STATE_REQUEST";

const SEND_CONTRACTOR_DATA_TO_BANK_REQUEST = "SEND_CONTRACTOR_DATA_TO_BANK_REQUEST";
const SEND_CONTRACTOR_DATA_TO_BANK_SUCCESS = "SEND_CONTRACTOR_DATA_TO_BANK_SUCCESS";

const RETAIL_EXPORT_REQUEST = "RETAIL_EXPORT_REQUEST";
const RETAIL_EXPORT_SUCCESS = "RETAIL_EXPORT_SUCCESS";
const RETAIL_EXPORT_ERROR = "RETAIL_EXPORT_ERROR";

const CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST = "CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST";
const CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS = "CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS";
const CONTRACTOR_EDM_PASSPORT_TYPE_ERROR = "CONTRACTOR_EDM_PASSPORT_TYPE_ERROR";

const CONTRACTOR_REGISTRATION_STATE_REQUEST = "CONTRACTOR_REGISTRATION_STATE_REQUEST";
const CONTRACTOR_REGISTRATION_STATE_SUCCESS = "CONTRACTOR_REGISTRATION_STATE_SUCCESS";
const CONTRACTOR_REGISTRATION_STATE_ERROR = "CONTRACTOR_REGISTRATION_STATE_ERROR";

const RETAIL_START_JOB_REQUEST = "RETAIL_START_JOB_REQUEST";
const RETAIL_START_JOB_SUCCESS = "RETAIL_START_JOB_SUCCESS";
const RETAIL_START_JOB_ERROR = "RETAIL_START_JOB_ERROR";

const GET_CONTRACTOR_BANK_STATUS_REQUEST = "GET_CONTRACTOR_BANK_STATUS_REQUEST";
const GET_CONTRACTOR_BANK_STATUS_SUCCESS = "GET_CONTRACTOR_BANK_STATUS_SUCCESS";
const GET_CONTRACTOR_BANK_STATUS_ERROR = "GET_CONTRACTOR_BANK_STATUS_ERROR";

const GET_RETAIL_PROJECT_LIST_REQUEST = "GET_RETAIL_PROJECT_LIST_REQUEST";
const GET_RETAIL_PROJECT_LIST_SUCCESS = "GET_RETAIL_PROJECT_LIST_SUCCESS";
const GET_RETAIL_PROJECT_LIST_ERROR = "GET_RETAIL_PROJECT_LIST_ERROR";

const GET_RETAIL_OBJECT_LIST_REQUEST = "GET_RETAIL_OBJECT_LIST_REQUEST";
const GET_RETAIL_OBJECT_LIST_SUCCESS = "GET_RETAIL_OBJECT_LIST_SUCCESS";
const GET_RETAIL_OBJECT_LIST_ERROR = "GET_RETAIL_OBJECT_LIST_ERROR";

const GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST = "GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST";
const GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS = "GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS";
const GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR = "GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR";

const GET_PERSONAL_DATA_INN_STATUS_REQUEST = "GET_PERSONAL_DATA_INN_STATUS_REQUEST";
const GET_PERSONAL_DATA_INN_STATUS_SUCCESS = "GET_PERSONAL_DATA_INN_STATUS_SUCCESS";

const GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST = "GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST";
const GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS = "GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS";

const CONTRACTOR_GET_OPTIMIZED_HIRING_RICH_PAGE_REQUEST = "CONTRACTOR_GET_OPTIMIZED_HIRING_RICH_PAGE_REQUEST";

const GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST = "GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST";
const GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS = "GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS";
const GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR = "GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR";

const GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST = "GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST";
const GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS = "GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS";
const GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR = "GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR";

const PERSONAL_DATA_PENDING_CONFIRM_EDM_REQUEST = "PERSONAL_DATA_PENDING_CONFIRM_EDM_REQUEST";
const PERSONAL_DATA_PENDING_CONFIRM_EDM_SUCCESS = "PERSONAL_DATA_PENDING_CONFIRM_EDM_SUCCESS";
const PERSONAL_DATA_PENDING_CONFIRM_EDM_ERROR = "PERSONAL_DATA_PENDING_CONFIRM_EDM_ERROR";

const UPDATE_PROJECT_PARAMS_REQUEST = "UPDATE_PROJECT_PARAMS_REQUEST";
const UPDATE_PROJECT_PARAMS_SUCCESS = "UPDATE_PROJECT_PARAMS_SUCCESS";
const UPDATE_PROJECT_PARAMS_ERROR = "UPDATE_PROJECT_PARAMS_ERROR";

const UPDATE_LEADS_COMPLETED_REQUEST = "UPDATE_LEADS_COMPLETED_REQUEST";
const UPDATE_LEADS_COMPLETED_SUCCESS = "UPDATE_LEADS_COMPLETED_SUCCESS";
const UPDATE_LEADS_COMPLETED_ERROR = "UPDATE_LEADS_COMPLETED_ERROR";

const UPDATE_PREVIOUS_PASSPORT_REQUEST = "UPDATE_PREVIOUS_PASSPORT_REQUEST";
const UPDATE_PREVIOUS_PASSPORT_SUCCESS = "UPDATE_PREVIOUS_PASSPORT_SUCCESS";
const UPDATE_PREVIOUS_PASSPORT_ERROR = "UPDATE_PREVIOUS_PASSPORT_ERROR";

const GET_ACTIVE_ORDER_NUMBERS_REQUEST = "GET_ACTIVE_ORDER_NUMBERS_REQUEST";
const GET_ACTIVE_ORDER_NUMBERS_SUCCESS = "GET_ACTIVE_ORDER_NUMBERS_SUCCESS";
const GET_ACTIVE_ORDER_NUMBERS_ERROR = "GET_ACTIVE_ORDER_NUMBERS_ERROR";

const CONTRACTOR_RICH_MAP_PAGE_REQUEST = "CONTRACTOR_RICH_MAP_PAGE_REQUEST";
const CONTRACTOR_RICH_MAP_PAGE_SUCCESS = "CONTRACTOR_RICH_MAP_PAGE_SUCCESS";
const CONTRACTOR_RICH_MAP_PAGE_ERROR = "CONTRACTOR_RICH_MAP_PAGE_ERROR";

const UPDATE_CONTRACTOR_BLOCKED_REQUEST = "UPDATE_CONTRACTOR_BLOCKED_REQUEST";
const UPDATE_CONTRACTOR_BLOCKED_SUCCESS = "UPDATE_CONTRACTOR_BLOCKED_SUCCESS";
const UPDATE_CONTRACTOR_BLOCKED_ERROR = "UPDATE_CONTRACTOR_BLOCKED_ERROR";

const GET_EMPTY_RECORD_REQUEST = "GET_EMPTY_RECORD_REQUEST";
const GET_EMPTY_RECORD_SUCCESS = "GET_EMPTY_RECORD_SUCCESS";
const GET_EMPTY_RECORD_ERROR = "GET_EMPTY_RECORD_ERROR";

const DELETE_EMPTY_RECORD_REQUEST = "DELETE_EMPTY_RECORD_REQUEST";
const DELETE_EMPTY_RECORD_SUCCESS = "DELETE_EMPTY_RECORD_SUCCESS";
const DELETE_EMPTY_RECORD_ERROR = "DELETE_EMPTY_RECORD_ERROR";

const IMPORT_CONTRACTORS_REQUEST = "IMPORT_CONTRACTORS_REQUEST";
const IMPORT_CONTRACTORS_SUCCESS = "IMPORT_CONTRACTORS_SUCCESS";
const IMPORT_CONTRACTORS_ERROR = "IMPORT_CONTRACTORS_ERROR";

const GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST = "GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST";
const GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS = "GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS";
const GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR = "GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR";

const CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST = "CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST";
const CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS = "CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS";
const CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR = "CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR";

const CONTRACTOR_TAX_STATUS_REFRESH_REQUEST = "CONTRACTOR_TAX_STATUS_REFRESH_REQUEST";
const CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS = "CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS";
const CONTRACTOR_TAX_STATUS_REFRESH_ERROR = "CONTRACTOR_TAX_STATUS_REFRESH_ERROR";

const CONTRACTOR_INN_VALIDATE_REQUEST = "CONTRACTOR_INN_VALIDATE_REQUEST";
const CONTRACTOR_INN_VALIDATE_SUCCESS = "CONTRACTOR_INN_VALIDATE_SUCCESS";
const CONTRACTOR_INN_VALIDATE_ERROR = "CONTRACTOR_INN_VALIDATE_ERROR";

const GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST = "GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST";
const GET_CONTRACTOR_FILES_REQUIRED_LIST_SUCCESS = "GET_CONTRACTOR_FILES_REQUIRED_LIST_SUCCESS";
const GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR = "GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR";

const GET_INN_BY_PERSONAL_INFO_REQUEST = "GET_INN_BY_PERSONAL_INFO_REQUEST";
const GET_INN_BY_PERSONAL_INFO_SUCCESS = "GET_INN_BY_PERSONAL_INFO_SUCCESS";
const GET_INN_BY_PERSONAL_INFO_ERROR = "GET_INN_BY_PERSONAL_INFO_SUCCESS";

const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST";
const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS";
const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR";

const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST";
const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS";
const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR";

const CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST = "CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST";
const CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_SUCCESS = "CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_SUCCESS";
const CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR = "CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR";

const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST";
const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS";
const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR";

const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST";
const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS";
const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR";

const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST";
const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS";
const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR";

const DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST = "DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST";
const DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS = "DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS";
const DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR = "DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR";

const GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST = "GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    shortModelList: [],
    totalCount: 0,
    card: {
        departureAddressesInfo: {},
    },
    pageData: {},
    error: null,
    progress: false,
    isSuccessAdd: false,
    progressAdd: false,
    listByIdsParams: {},
    citizenship: {},
    citizenshipSmzOnlyDict: {},
    contractorMigrationStatusDict: {},
    migrationStatusDict: {},
    vhiPolicyFormatDict: {},
    vhiPolicyTypeDict: {},
    workRegion: {},
    supports: [],
    contractorList: [],
    pageDataSupport: {},
    totalCountSupport: 0,
    cardSupport: {},
    callTypeStatus: {},
    caseStatus: {},
    progressSupportUpdate: false,
    contractorOrderWorkReportInfo: {},
    progressCard: false,
    contractorsNotForeman: [],
    progressNotForeman: false,
    pageDataNotForeman: {},
    totalCountNotForeman: 0,
    actionProgress: false,
    orderStatistics: {
        categoryStatistics: [],
        isLoaded: false,
    },
    archivePhones: {
        isLoaded: false,
        allTotalCount: null,
        blackListCount: null,
        favoriteCount: null,
        phoneModels: [],
        totalCount: 0,
    },
    contractorsByOrder: [],
    pageDataContractorsByOrder: {},
    totalCountContractorsByOrder: 0,
    applicationCountMap: {},
    contractorCount: 0,
    progressApplications: false,
    note: {
        value: "",
        isNoteLoaded: false,
    },
    blockedHistory: {
        list: [],
        isBlockedHistoryLoaded: false,
    },
    allApplications: [],
    statusDict: {},
    pageDataAllApplications: {},
    allApplicationCountMap: {},
    totalCountAllApplications: 0,
    progressAllApplications: false,
    timeSheetContractors: [],
    contractorOrderStatusDict: {},
    blockedContractors: {
        isBlockedContractorsLoaded: false,
        contractors: [],
    },
    isContractorLoaded: false,
    pendingPersonalData: {
        isLoaded: false,
        data: null,
    },
    progressCheckOnEnd: false,
    contractorsRetail: [],
    totalCountRetail: 0,
    pageDataRetail: {},
    progressRetail: false,
    contractorDataBankState: {
        isBankStatesLoaded: false,
        bankStates: {},
    },
    contractorEdmPassportType: {},
    registrationStates: {},
    contractorBankStatus: {
        status: {},
        isLoaded: false,
    },
    retailProjectsNameIdMap: [],
    retailObjectsNameIdMap: [],
    innStatus: {
        status: null,
        isLoaded: false,
    },
    secondTryingIndicator: false,
    passportStatus: {
        status: null,
        isLoaded: false,
    },
    progressComparison: false,
    progressEdmConfirm: false,
    progressPendingDataConfirm: false,
    mapContractorsList: [],
    mapContractorsTotalCount: 0,
    mapContractorsPageData: {},
    mapContractorsProgress: false,
    emptyRecordProgress: false,
    importContractorsProgress: false,
    comparisonData: {},
    updateLeadsCompletedProgress: false,
    progressCheckInn: false,
    recommendationModelType: "",
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST:
    case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST:
        return {
            ...state,
            progressCheckOnEnd: true,
        };
    case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS:
    case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS:
        return {
            ...state,
            progressCheckOnEnd: false,
        };
    case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR:
    case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR:
        return {
            ...state,
            progressCheckOnEnd: false,
        };
    case GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS:
        return {
            ...state,
            comparisonData: payload,
            progressComparison: false,
        };
    case GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR:
        return {
            ...state,
            progressComparison: false,
        };
    case GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST:
        return {
            ...state,
            progressComparison: true,
        };
    case CONTRACTOR_GET_OPTIMIZED_HIRING_RICH_PAGE_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case GET_CONTRACTOR_COUNT_SUCCESS:
        return {
            ...state,
            contractorCount: payload,
        };
    case GET_CONTRACTORS_RETAILS_PAGE_REQUEST:
        return {
            ...state,
            pageDataRetail: payload,
            progressRetail: true,
        };
    case CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS:
        return {
            ...state,
            contractorList: payload,
        };
    case GET_PHONES_BY_XLSX_FILE_REQUEST:
    case GET_CONTRACTOR_BY_PHONE_REQUEST:
    case GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST:
    case CONTRACTOR_TAX_STATUS_REFRESH_REQUEST:
    case CONTRACTOR_REGISTRATION_PROMOCODE_REQUEST:
    case CONTRACTOR_CHECK_PROMOCODE_REQUEST:
    case GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST:
    case UPDATE_CONTRACTOR_RETAIL_REQUEST:
    case CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST:
    case CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST:
    case CONTRACTOR_ADD_TO_BLACKLIST_REQUEST:
    case CONTRACTOR_ADD_FAVORITE_REQUEST:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST:
    case DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST:
    case CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST:
    case CONTRACTOR_HIRING_RICH_LIST_READ_REQUEST:
    case CONTRACTOR_RICH_LIST_READ_REQUEST:
    case BFF_CONTRACTOR_RICH_LIST_READ_REQUEST:
    case CONTRACTOR_SHORT_LIST_READ_REQUEST:
    case CONTRACTOR_LIST_READ_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST:
        return {
            ...state,
            progressNotForeman: true,
            pageDataNotForeman: payload,
        };
    case CONTRACTOR_UPDATE_FIELD_STORE:
        return {
            ...state,
            ...payload,
            progress: false,
        };
    case CONTRACTOR_SUPPORT_CARD_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case CONTRACTOR_SUPPORT_REQUEST:
        return {
            ...state,
            pageDataSupport: {...payload},
        };
    case CONTRACTOR_CLEAR_STORE:
        return {
            ...initial,
        };
    case CONTRACTOR_SUPPORT_UPDATE_REQUEST:
        return {
            ...state,
            progressSupportUpdate: true,
        };
    case CONTRACTOR_UPDATE_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CONTRACTOR_ADD_REQUEST:
        return {
            ...state,
            progressAdd: true,
            isSuccessAdd: false,
        };
    case CONTRACTOR_READ_REQUEST:
    case CONTRACTOR_CHANGE_AUTO_TAXPAY_REQUEST:
    case CONTRACTOR_CANCEL_AUTO_TAXPAY_REQUEST:
    case CONTRACTOR_RICH_READ_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case PERSONAL_DATA_PENDING_CONFIRM_EDM_REQUEST:
        return {
            ...state,
            progressEdmConfirm: true,
        };
    case POST_PENDING_PERSONAL_DATA_REQUEST:
        return {
            ...state,
            progressPendingDataConfirm: true,
        };
    case CONTRACTOR_RICH_MAP_PAGE_REQUEST:
        return {
            ...state,
            mapContractorsProgress: true,
            mapContractorsPageData: payload,
        };
    case CONTRACTOR_INN_VALIDATE_REQUEST:
    case GET_INN_BY_PERSONAL_INFO_REQUEST:
        return {
            ...state,
            progressCheckInn: true,
        };
    case GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS:
        return {
            ...state,
            progressNotForeman: false,
            contractorsNotForeman: payload.contractors,
            totalCountNotForeman: payload.totalCount,
        };
    case CONTRACTOR_ADD_SUCCESS:
        return {
            ...state,
            progressAdd: false,
            isSuccessAdd: true,
        };
    case CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS:
    case CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR:
    case CONTRACTOR_UPDATE_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS:
        return {
            ...state,
            actionProgress: false,
            contractorOrderWorkReportInfo: payload,
        };
    case GET_PHONES_BY_XLSX_FILE_SUCCESS:
    case GET_CONTRACTOR_BY_PHONE_SUCCESS:
    case GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS:
    case CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS:
    case CONTRACTOR_REGISTRATION_PROMOCODE_SUCCESS:
    case CONTRACTOR_CHECK_PROMOCODE_SUCCESS:
    case UPDATE_CONTRACTOR_RETAIL_SUCCESS:
    case CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS:
    case CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS:
    case CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS:
    case CONTRACTOR_ADD_FAVORITE_SUCCESS:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS:
    case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR:
    case DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS:
    case DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR:
        return {
            ...state,
            actionProgress: false,
        };
    case CONTRACTOR_ORDER_STATUS_DICT_SUCCESS:
        return {
            ...state,
            contractorOrderStatusDict: payload,
        };
    case CONTRACTOR_STATUS_DICT_SUCCESS:
        return {
            ...state,
            statusDict: payload,
        };
    case CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS:
        return {
            ...state,
            vhiPolicyFormatDict: payload,
        };
    case CONTRACTOR_VHI_POLICY_TYPE_SUCCESS:
        return {
            ...state,
            vhiPolicyTypeDict: payload,
        };
    case CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS:
        return {
            ...state,
            citizenshipSmzOnlyDict: payload,
        };
    case CONTRACTOR_CITIZENSHIP_SUCCESS:
        return {
            ...state,
            citizenship: payload,
        };
    case CONTRACTOR_WORKREGION_SUCCESS:
        return {
            ...state,
            workRegion: payload,
        };
    case CONTRACTOR_SUPPORT_SUCCESS:
        const {
            supports,
            totalCount: totalCountSupport = 0,
        } = payload;
        return {
            ...state,
            totalCountSupport,
            supports,
        };
    case CONTRACTOR_SUPPORT_CARD_SUCCESS:
        return {
            ...state,
            cardSupport: payload,
            progressCard: false,
        };
    case CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS:
        return {
            ...state,
            caseStatus: payload,
        };
    case CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS:
        return {
            ...state,
            callTypeStatus: payload,
        };
    case CONTRACTOR_SUPPORT_UPDATE_SUCCESS:
        return {
            ...state,
            progressSupportUpdate: false,
        };
    case GET_CONTRACTORS_RETAILS_PAGE_SUCCESS:
        const {
            contractors: contractorsRetail,
            totalCount: totalCountRetail,
        } = payload;

        return {
            ...state,
            contractorsRetail,
            totalCountRetail,
            progressRetail: false,
        };
    case CONTRACTOR_READ_SUCCESS:
    case CONTRACTOR_RICH_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            card: payload,
            isContractorLoaded: true,
        };
    case CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS:
    case CONTRACTOR_HIRING_RICH_LIST_READ_SUCCESS:
    case CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS:
    case CONTRACTOR_RICH_LIST_READ_SUCCESS:
    case BFF_CONTRACTOR_RICH_LIST_READ_SUCCESS:
    case CONTRACTOR_LIST_READ_SUCCESS:
        const {
            contractors = [],
            totalCount = 0,
            secondTry = false,
            recommendationModelType,
        } = payload;

        return {
            ...state,
            progress: false,
            list: contractors,
            totalCount,
            secondTryingIndicator: secondTry,
            recommendationModelType,
        };
    case CONTRACTOR_SHORT_LIST_READ_SUCCESS:
        return {
            ...state,
            totalCount: payload.totalCount,
            shortModelList: payload.results,
            progress: false,
        };
    case CONTRACTOR_CANCEL_AUTO_TAXPAY_SUCCESS:
    case CONTRACTOR_CHANGE_AUTO_TAXPAY_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case PERSONAL_DATA_PENDING_CONFIRM_EDM_SUCCESS:
        return {
            ...state,
            progressEdmConfirm: false,
        };
    case POST_PENDING_PERSONAL_DATA_SUCCESS:
        return {
            ...state,
            progressPendingDataConfirm: false,
        };
    case GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS: {
        const {
            result,
            nameField = "migrationStatusDict",
        } = payload;

        return {
            ...state,
            [nameField]: result,
        };
    }
    case CONTRACTOR_INN_VALIDATE_SUCCESS:
    case GET_INN_BY_PERSONAL_INFO_SUCCESS:
        return {
            ...state,
            progressCheckInn: false,
        };
    case GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            progressNotForeman: false,
        };
    case CONTRACTOR_ADD_ERROR:
        return {
            ...state,
            isSuccessAdd: false,
            progressAdd: false,
            error: payload,
        };
    case CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR:
    case CONTRACTOR_LIST_READ_ERROR:
    case CONTRACTOR_HIRING_RICH_LIST_READ_ERROR:
    case CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR:
    case CONTRACTOR_RICH_LIST_READ_ERROR:
    case BFF_CONTRACTOR_RICH_LIST_READ_ERROR:
    case CONTRACTOR_SHORT_LIST_READ_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case CONTRACTOR_UPDATE_ERROR:
    case CONTRACTOR_READ_ERROR:
    case CONTRACTOR_RICH_READ_ERROR:
    case CONTRACTOR_SUPPORT_CARD_ERROR:
        return {
            ...state,
            progress: false,
            progressCard: false,
            error: payload,
        };
    case CONTRACTOR_SUPPORT_UPDATE_ERROR:
        return {
            ...state,
            error: payload,
            progressSupportUpdate: false,
        };
    case GET_PHONES_BY_XLSX_FILE_ERROR:
    case GET_CONTRACTOR_BY_PHONE_ERROR:
    case GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR:
    case CONTRACTOR_TAX_STATUS_REFRESH_ERROR:
    case CONTRACTOR_REGISTRATION_PROMOCODE_ERROR:
    case CONTRACTOR_CHECK_PROMOCODE_ERROR:
    case GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR:
    case UPDATE_CONTRACTOR_RETAIL_ERROR:
    case CONTRACTOR_ADD_TO_BLACKLIST_ERROR:
    case CONTRACTOR_DELETE_FROM_FAVORITE_ERROR:
    case CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR:
    case CONTRACTOR_ADD_FAVORITE_ERROR:
        return {
            ...state,
            actionProgress: false,
            error: payload,
        };
    case CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR:
    case GET_CONTRACTOR_COUNT_ERROR:
    case CONTRACTOR_CANCEL_AUTO_TAXPAY_ERROR:
    case CONTRACTOR_CHANGE_AUTO_TAXPAY_ERROR:
    case CONTRACTOR_SUPPORT_ERROR:
    case CONTRACTOR_WORKREGION_ERROR:
    case CONTRACTOR_CITIZENSHIP_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case CONTRACTOR_INN_VALIDATE_ERROR:
    case GET_INN_BY_PERSONAL_INFO_ERROR:
        return {
            ...state,
            progressCheckInn: false,
        };
    case SET_CONTRACTOR_ORDER_STATISTICS:
        return {
            ...state,
            orderStatistics: {
                ...payload,
                isLoaded: true,
            },
        };
    case CONTRACTOR_REGISTRATION_STATE_SUCCESS:
        return {
            ...state,
            registrationStates: payload,
        };
    case CONTRACTOR_ARCHIVE_PHONES_REQUEST:
        return {
            ...state,
            archivePhones: {
                isLoaded: false,
            },
        };
    case GET_ALL_APPLICATIONS_REQUEST:
        return {
            ...state,
            progressAllApplications: true,
            pageDataAllApplications: payload,
        };
    case GET_CONTRACTORS_BY_ORDER_REQUEST:
        return {
            ...state,
            progressApplications: true,
            pageDataContractorsByOrder: payload,
        };
    case GET_ALL_APPLICATIONS_SUCCESS: {
        const {
            contractors: allApplications,
            totalCount: totalCountAllApplications,
            applicationCountMap: allApplicationCountMap,
        } = payload;

        return {
            ...state,
            allApplications,
            totalCountAllApplications,
            allApplicationCountMap,
            progressAllApplications: false,
        };
    }
    case GET_CONTRACTORS_BY_ORDER_SUCCESS:
        const {
            contractors: contractorsByOrder,
            totalCount: totalCountContractorsByOrder,
            applicationCountMap,
            nameField = "contractorsByOrder",
        } = payload;

        return {
            ...state,
            [nameField]: contractorsByOrder,
            totalCountContractorsByOrder,
            applicationCountMap,
            progressApplications: false,
        };
    case GET_ALL_APPLICATIONS_ERROR:
        return {
            ...state,
            progressAllApplications: false,
        };
    case GET_CONTRACTORS_BY_ORDER_ERROR:
        return {
            ...state,
            progressApplications: false,
        };
    case CONTRACTOR_ORDER_STATUS_DICT_ERROR:
    case CONTRACTOR_STATUS_DICT_ERROR:
        return {
            ...state,
            error: payload,
        };
    case CONTRACTOR_ARCHIVE_PHONES_SUCCESS:
        return {
            ...state,
            archivePhones: {
                ...payload,
                isLoaded: true,
            },
        };
    case GET_NOTE_SUCCESS:
        return {
            ...state,
            note: {
                value: payload,
                isNoteLoaded: true,
            },
        };
    case ADD_NOTE_SUCCESS:
        return {
            ...state,
            note: {
                value: payload,
                isNoteLoaded: true,
            },
        };
    case UPDATE_NOTE_SUCCESS:
        return {
            ...state,
            note: {
                value: payload,
                isNoteLoaded: true,
            },
        };
    case GET_BLOCKED_HISTORY_REQUEST:
        return {
            ...state,
            blockedHistory: {
                ...state.blockedHistory,
                isBlockedHistoryLoaded: false,
            },
        };
    case GET_BLOCKED_HISTORY_SUCCESS:
        return {
            ...state,
            blockedHistory: {
                list: payload,
                isBlockedHistoryLoaded: true,
            },
        };
    case GET_BLOCKED_CONTRACTORS_SUCCESS:
        return {
            ...state,
            blockedContractors: {
                contractors: payload,
                isBlockedContractorsLoaded: true,
            },
        };
    case GET_PENDING_PERSONAL_DATA_SUCCESS:
        return {
            ...state,
            pendingPersonalData: {
                data: payload,
                isLoaded: true,
            },
        };
    case GET_CONTRACTOR_DATA_BANK_STATE_SUCCESS:
        return {
            ...state,
            contractorDataBankState: {
                bankStates: payload,
                isBankStatesLoaded: true,
            },
        };
    case CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS:
        return {
            ...state,
            contractorEdmPassportType: payload,
        };
    case GET_CONTRACTOR_BANK_STATUS_SUCCESS:
        return {
            ...state,
            contractorBankStatus: {
                status: payload,
                isLoaded: true,
            },
        };
    case GET_RETAIL_PROJECT_LIST_SUCCESS:
        return {
            ...state,
            retailProjectsNameIdMap: payload,
        };
    case GET_RETAIL_OBJECT_LIST_SUCCESS:
        return {
            ...state,
            retailObjectsNameIdMap: payload,
        };
    case GET_PERSONAL_DATA_INN_STATUS_SUCCESS:
        return {
            ...state,
            innStatus: {
                status: payload,
                isLoaded: true,
            },
        };
    case GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS:
        return {
            ...state,
            passportStatus: {
                status: payload,
                isLoaded: true,
            },
        };
    case CONTRACTOR_RICH_MAP_PAGE_SUCCESS:
        return {
            ...state,
            mapContractorsList: payload.contractors,
            mapContractorsTotalCount: payload.totalCount,
            mapContractorsProgress: false,
        };
    case PERSONAL_DATA_PENDING_CONFIRM_EDM_ERROR:
        return {
            ...state,
            progressEdmConfirm: false,
        };
    case POST_PENDING_PERSONAL_DATA_ERROR:
        return {
            ...state,
            progressPendingDataConfirm: false,
        };
    case CONTRACTOR_RICH_MAP_PAGE_ERROR:
        return {
            ...state,
            mapContractorsProgress: false,
        };
    case GET_EMPTY_RECORD_REQUEST:
    case DELETE_EMPTY_RECORD_REQUEST:
        return {
            ...state,
            emptyRecordProgress: true,
        };
    case GET_EMPTY_RECORD_SUCCESS:
    case DELETE_EMPTY_RECORD_SUCCESS:
        return {
            ...state,
            emptyRecordProgress: false,
        };
    case GET_EMPTY_RECORD_ERROR:
    case DELETE_EMPTY_RECORD_ERROR:
        return {
            ...state,
            emptyRecordProgress: false,
        };
    case IMPORT_CONTRACTORS_REQUEST:
        return {
            ...state,
            importContractorsProgress: true,
        };
    case IMPORT_CONTRACTORS_SUCCESS:
        return {
            ...state,
            importContractorsProgress: false,
        };
    case IMPORT_CONTRACTORS_ERROR:
        return {
            ...state,
            importContractorsProgress: false,
        };
    case UPDATE_LEADS_COMPLETED_REQUEST:
        return {
            ...state,
            updateLeadsCompletedProgress: true,
        };
    case UPDATE_LEADS_COMPLETED_SUCCESS:
        return {
            ...state,
            updateLeadsCompletedProgress: false,
        };
    case UPDATE_LEADS_COMPLETED_ERROR:
        return {
            ...state,
            updateLeadsCompletedProgress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorOrderWorkReportInfo(payload) {
    return {
        type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST,
        payload,
    };
}

export function getContractorCount(payload) {
    return {
        type: GET_CONTRACTOR_COUNT_REQUEST,
        payload,
    };
}

export function getContractorById(payload) {
    return {
        type: CONTRACTOR_READ_REQUEST,
        payload,
    };
}

export function getContractorOrderStatusDict(payload) {
    return {
        type: CONTRACTOR_ORDER_STATUS_DICT_REQUEST,
        payload,
    };
}

export function updateProjectParams(payload) {
    return {
        type: UPDATE_PROJECT_PARAMS_REQUEST,
        payload,
    };
}

export function updateLeadsCompleted(payload) {
    return {
        type: UPDATE_LEADS_COMPLETED_REQUEST,
        payload,
    };
}

export function getRichContractorById(payload) {
    return {
        type: CONTRACTOR_RICH_READ_REQUEST,
        payload,
    };
}

export function getContractorStatusDict() {
    return {
        type: CONTRACTOR_STATUS_DICT_REQUEST,
    };
}

export function addFavoriteContractors(payload) {
    return {
        type: CONTRACTOR_ADD_FAVORITE_REQUEST,
        payload,
    };
}

export function addContractorToBlackList(payload) {
    return {
        type: CONTRACTOR_ADD_TO_BLACKLIST_REQUEST,
        payload,
    };
}

export function deleteContractorFromBlackList(payload) {
    return {
        type: CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST,
        payload,
    };
}

export function deleteFavoriteContractors(payload) {
    return {
        type: CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST,
        payload,
    };
}

export function addContractor(payload) {
    return {
        type: CONTRACTOR_ADD_REQUEST,
        payload,
    };
}

export function archiveContractor(payload) {
    return {
        type: ARCHIVE_CONTRACTOR_REQUEST,
        payload,
    };
}

export function updateSupport(payload) {
    return {
        type: CONTRACTOR_SUPPORT_UPDATE_REQUEST,
        payload,
    };
}

export function getVhiPolicyFormats() {
    return {
        type: CONTRACTOR_VHI_POLICY_FORMAT_REQUEST,
    };
}

export function getVhiPolicyTypes() {
    return {
        type: CONTRACTOR_VHI_POLICY_TYPE_REQUEST,
    };
}

export function getCitizenships() {
    return {
        type: CONTRACTOR_CITIZENSHIP_REQUEST,
    };
}

export function getCitizenshipsSmzOnly() {
    return {
        type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST,
    };
}

export function getWorkRegions() {
    return {
        type: CONTRACTOR_WORKREGION_REQUEST,
    };
}

export function getSupportByCaseNumber(payload) {
    return {
        type: CONTRACTOR_SUPPORT_CARD_REQUEST,
        payload,
    };
}

export function getCaseStatus() {
    return {
        type: CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST,
    };
}

export function getCallTypeStatus() {
    return {
        type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST,
    };
}

export function getSupports(payload) {
    return {
        type: CONTRACTOR_SUPPORT_REQUEST,
        payload,
    };
}

export function clearContractorStore() {
    return {
        type: CONTRACTOR_CLEAR_STORE,
    };
}

export function getContractorByIds(payload) {
    return {
        type: CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST,
        payload,
    };
}

export function updateFieldContractorStore(payload) {
    return {
        type: CONTRACTOR_UPDATE_FIELD_STORE,
        payload,
    };
}

export function updateContractor(payload) {
    return {
        type: CONTRACTOR_UPDATE_REQUEST,
        payload,
    };
}

export function getContractorList(payload) {
    return {
        type: CONTRACTOR_LIST_READ_REQUEST,
        payload,
    };
}

export function getContractorRichList(payload) {
    return {
        type: CONTRACTOR_RICH_LIST_READ_REQUEST,
        payload,
    };
}

export function bffGetContractorRichList(payload) {
    return {
        type: BFF_CONTRACTOR_RICH_LIST_READ_REQUEST,
        payload,
    };
}

export function getContractorShortList(payload) {
    return {
        type: CONTRACTOR_SHORT_LIST_READ_REQUEST,
        payload,
    };
}

export function getHiringContractorRichList(payload) {
    return {
        type: CONTRACTOR_HIRING_RICH_LIST_READ_REQUEST,
        payload,
    };
}

export function getHiringContractorOptimizedList(payload) {
    return {
        type: CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST,
        payload,
    };
}

export function changeContractorAutoTaxPayming(payload) {
    return {
        type: CONTRACTOR_CHANGE_AUTO_TAXPAY_REQUEST,
        payload,
    };
}

export function cancelContractorAutoTaxPayming(payload) {
    return {
        type: CONTRACTOR_CANCEL_AUTO_TAXPAY_REQUEST,
        payload,
    };
}

export function getContractorByPhone(payload) {
    return {
        type: GET_CONTRACTOR_BY_PHONE_REQUEST,
        payload,
    };
}

export function checkContractorPromocode(payload) {
    return {
        type: CONTRACTOR_CHECK_PROMOCODE_REQUEST,
        payload,
    };
}

export function registrationContractorPromocode(payload) {
    return {
        type: CONTRACTOR_REGISTRATION_PROMOCODE_REQUEST,
        payload,
    };
}

export function getPromocodeByContractorId(payload) {
    return {
        type: GET_PROMOCODE_BY_CONTRACTOR_ID_REQUEST,
        payload,
    };
}

export function getCountOfContractorsForPushNotifications(payload) {
    return {
        type: GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_REQUEST,
        payload,
    };
}

export function getNotForemanContractorsPage(payload) {
    return {
        type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST,
        payload,
    };
}

export function getArchivePhonesByContractorId(payload) {
    return {
        type: CONTRACTOR_ARCHIVE_PHONES_REQUEST,
        payload,
    };
}

export const getOrderStatistics = (payload) => ({
    type: GET_CONTRACTOR_ORDER_STATISTICS,
    payload,
});

export function getContractorsByOrder(payload) {
    return {
        type: GET_CONTRACTORS_BY_ORDER_REQUEST,
        payload,
    };
}

export function getAllApplications(payload) {
    return {
        type: GET_ALL_APPLICATIONS_REQUEST,
        payload,
    };
}

export function getPhonesByXlsxFile(payload) {
    return {
        type: GET_PHONES_BY_XLSX_FILE_REQUEST,
        payload,
    };
}

export function getNote(payload) {
    return {
        type: GET_NOTE_REQUEST,
        payload,
    };
}

export function addNote(payload) {
    return {
        type: ADD_NOTE_REQUEST,
        payload,
    };
}

export function updateNote(payload) {
    return {
        type: UPDATE_NOTE_REQUEST,
        payload,
    };
}

export function getBlockedHistory(payload) {
    return {
        type: GET_BLOCKED_HISTORY_REQUEST,
        payload,
    };
}

export function getBlockedContractors(payload) {
    return {
        type: GET_BLOCKED_CONTRACTORS_REQUEST,
        payload,
    };
}

export function getPendingPersonalData(payload) {
    return {
        type: GET_PENDING_PERSONAL_DATA_REQUEST,
        payload,
    };
}

export function postPendingPersonalData(payload) {
    return {
        type: POST_PENDING_PERSONAL_DATA_REQUEST,
        payload,
    };
}

export function getContractorsRetailPage(payload) {
    return {
        type: GET_CONTRACTORS_RETAILS_PAGE_REQUEST,
        payload,
    };
}

export function updateContractorRetail(payload) {
    return {
        type: UPDATE_CONTRACTOR_RETAIL_REQUEST,
        payload,
    };
}

export function getContractorBankStates(payload) {
    return {
        type: GET_CONTRACTOR_DATA_BANK_STATE_REQUEST,
        payload,
    };
}

export function sendContractorDataToBank(payload) {
    return {
        type: SEND_CONTRACTOR_DATA_TO_BANK_REQUEST,
        payload,
    };
}

export function retailExport(payload) {
    return {
        type: RETAIL_EXPORT_REQUEST,
        payload,
    };
}

export function getContractorEdmPassportType(payload) {
    return {
        type: CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST,
        payload,
    };
}

export function getContractorRegistrationStates() {
    return {
        type: CONTRACTOR_REGISTRATION_STATE_REQUEST,
    };
}

export function retailStartJob() {
    return {
        type: RETAIL_START_JOB_REQUEST,
    };
}

export function getContractorBankStatus(payload) {
    return {
        type: GET_CONTRACTOR_BANK_STATUS_REQUEST,
        payload,
    };
}

export function retailGetProjectList() {
    return {
        type: GET_RETAIL_PROJECT_LIST_REQUEST,
    };
}

export function retailGetObjectList(payload) {
    return {
        type: GET_RETAIL_OBJECT_LIST_REQUEST,
        payload,
    };
}

export function getInnStatus(payload) {
    return {
        type: GET_PERSONAL_DATA_INN_STATUS_REQUEST,
        payload,
    };
}

export function getPassportStatus(payload) {
    return {
        type: GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST,
        payload,
    };
}

export function getPersonalDataPendingComparison(payload) {
    return {
        type: GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST,
        payload,
    };
}

export function getPersonalDataPendingComparisonAvailability(payload) {
    return {
        type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST,
        payload,
    };
}

export function confirmPersonalDataEdm(payload) {
    return {
        type: PERSONAL_DATA_PENDING_CONFIRM_EDM_REQUEST,
        payload,
    };
}

export function getCanViewEventLogClientAdmin(payload) {
    return {
        type: GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST,
        payload,
    };
}

export function updatePreviousPassport(payload) {
    return {
        type: UPDATE_PREVIOUS_PASSPORT_REQUEST,
        payload,
    };
}

export function getContractorRichMapList(payload) {
    return {
        type: CONTRACTOR_RICH_MAP_PAGE_REQUEST,
        payload,
    };
}

export function getActiveOrderNumbers(payload) {
    return {
        type: GET_ACTIVE_ORDER_NUMBERS_REQUEST,
        payload,
    };
}

export function updateContractorBlocked(payload) {
    return {
        type: UPDATE_CONTRACTOR_BLOCKED_REQUEST,
        payload,
    };
}

export function getEmptyRecord(payload) {
    return {
        type: GET_EMPTY_RECORD_REQUEST,
        payload,
    };
}

export function deleteEmptyRecord(payload) {
    return {
        type: DELETE_EMPTY_RECORD_REQUEST,
        payload,
    };
}

export function importContractors(payload) {
    return {
        type: IMPORT_CONTRACTORS_REQUEST,
        payload,
    };
}

export function getMigrationStatusDict(payload) {
    return {
        type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST,
        payload,
    };
}

export function getPendingAdditionalDocumentsRichPage(payload) {
    return {
        type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST,
        payload,
    };
}

export function updateContractorRegistrationStatus(payload) {
    return {
        type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST,
        payload,
    };
}

export function checkInnBeforeAddition(payload) {
    return {
        type: CONTRACTOR_INN_VALIDATE_REQUEST,
        payload,
    };
}

export function getContractorFilesRequiredList(payload) {
    return {
        type: GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST,
        payload,
    };
}

export function refreshContractorTaxStatus(payload) {
    return {
        type: CONTRACTOR_TAX_STATUS_REFRESH_REQUEST,
        payload,
    };
}

export function getInnByPersonalInfo(payload) {
    return {
        type: GET_INN_BY_PERSONAL_INFO_REQUEST,
        payload,
    };
}

export function endCheckOnContractorPersonalDataStatus(payload) {
    return {
        type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
        payload,
    };
}

export function endMyCheckOnContractorPersonalDataStatus(payload) {
    return {
        type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
        payload,
    };
}

export function contractorPersonalDataPendingDraftComplete(payload) {
    return {
        type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST,
        payload,
    };
}

export function updateContractorIndividualEntrepreneurLegalInfo(payload) {
    return {
        type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST,
        payload,
    };
}

export function updateContractorIndividualEntrepreneurBankRequisites(payload) {
    return {
        type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST,
        payload,
    };
}

export function updateContractorIndividualEntrepreneurStatus(payload) {
    return {
        type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST,
        payload,
    };
}

export function deleteContractorIndividualEntrepreneurData(payload) {
    return {
        type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorSelector = state => state.contractor;
export const contractorListSelector = createSelector(contractorSelector, ({list}) => {
    return list.map(item => {
        const {
            lastName,
            firstName,
            patronymic,
            phone,
            fullName,
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            isSelected: false,
            fullName: lastName ? `${lastName} ${firstName} ${patronymic || ""}` : fullName,
            phone: phoneFormat(phone),
            workAddressInfoArr: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});

export const contractorsSecondTryingIndicatorSelector = createSelector(contractorSelector, ({secondTryingIndicator}) => secondTryingIndicator);

// Заявки
export const contractorsListByOrderSelector = createSelector(contractorSelector, ({contractorsByOrder}) => {
    return contractorsByOrder.map(item => {
        const {
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});

export const contractorStatusOptionsSelector = createSelector(contractorSelector, ({statusDict}) => {
    const order = [
        "BASIC_REGISTRATION",
        "ADMINISTRATOR_CHECK",
        "ADMINISTRATOR_CHECK_CHANGES",
        "ADMINISTRATOR_CHECK_PASSED",
    ];

    return dictionaryToOptions(statusDict).sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));
});

export const contractorsOrderStatusOptionsSelector = createSelector(contractorSelector, ({contractorOrderStatusDict}) => dictionaryToOptions(contractorOrderStatusDict));
export const contractorsTotalPagesByOrderSelector = createSelector(contractorSelector, ({totalCountContractorsByOrder, pageDataContractorsByOrder: {pageSize}}) => getTotalPages(totalCountContractorsByOrder, pageSize));
export const contractorsTotalCountByOrderSelector = createSelector(contractorSelector, ({totalCountContractorsByOrder}) => totalCountContractorsByOrder);
export const contractorsApplicationCountMap = createSelector(contractorSelector, ({applicationCountMap}) => applicationCountMap);
export const contractorProgressApplicationsSelector = createSelector(contractorSelector, ({progressApplications}) => progressApplications);
export const contractorCountOrderTabSelector = createSelector(contractorSelector, ({applicationCountMap}) => getApplicationCount(applicationCountMap));
export const contractorStatusDictSelector = createSelector(contractorSelector, ({statusDict}) => statusDict);
export const contractorsTimeSheetSelector = createSelector(contractorSelector, ({timeSheetContractors, contractorsByOrder}) =>
    timeSheetContractors.length === 0 ? contractorsByOrder : timeSheetContractors,
);
export const contractorsRetailTotalPagesSelector = createSelector(contractorSelector, ({totalCountRetail, pageDataRetail: {pageSize}}) => getTotalPages(totalCountRetail, pageSize));
export const contractorsRetailPageSelector = createSelector(contractorSelector, ({contractorsRetail}) => contractorsRetail);
export const contractorsRetailProgressSelector = createSelector(contractorSelector, ({progressRetail}) => progressRetail);
export const contractorsApplicationsOptionsSelector = createSelector(contractorSelector, ({timeSheetContractors, contractorsByOrder}) => {
    const dataSource = timeSheetContractors.length === 0 ? contractorsByOrder : timeSheetContractors;

    return dataSource.map(item => ({
        key: item.contractorId,
        value: item.contractorId,
        text: getFullName(`${item.lastName} ${item.firstName} ${item.patronymic || ""}`),
    }));
});
export const contractorsRetailTotalCountSelector = createSelector(contractorSelector, ({totalCountRetail}) => totalCountRetail);
export const progressPendingDataConfirmProgressSelector = createSelector(contractorSelector, ({progressPendingDataConfirm}) => progressPendingDataConfirm);
export const contractorNoteSelector = createSelector(contractorSelector, ({note}) => note.value);

// Все заявки
export const contractorsAllApplicationsSelector = createSelector(contractorSelector, ({allApplications}) => {
    return allApplications.map(item => {
        const {
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});
export const contractorsAllApplicationsTotalPagesSelector = createSelector(contractorSelector, ({totalCountAllApplications, pageDataAllApplications: {pageSize}}) => getTotalPages(totalCountAllApplications, pageSize));
export const contractorsAllApplicationCountMap = createSelector(contractorSelector, ({allApplicationCountMap}) => allApplicationCountMap);
export const contractorsAllApplicationsProgressSelector = createSelector(contractorSelector, ({progressAllApplications}) => progressAllApplications);
export const contractorsAllApplicationsTotalCountSelector = createSelector(contractorSelector, ({totalCountAllApplications}) => totalCountAllApplications);

export const allApplicationsCountSelector = createSelector(contractorSelector, ({allApplicationCountMap}) => getApplicationCount(allApplicationCountMap));

export const contractorSearchListSelector = createSelector(contractorSelector, ({list}) => list);

export const contractorNotForemanListSelector = createSelector(contractorSelector, ({contractorsNotForeman}) => contractorsNotForeman);
export const contractorProgressNotForemanListSelector = createSelector(contractorSelector, ({progressNotForeman}) => progressNotForeman);
export const contractorTotalPagesNotForemanListSelector = createSelector(contractorSelector, ({totalCountNotForeman, pageDataNotForeman: {pageSize}}) => getTotalPages(totalCountNotForeman, pageSize));

export const contractorCounterMapSelector = createSelector(contractorSelector,
    ({
        allTotalCount,
        blackListCount,
        favoriteCount,
    }) => {
        return {
            allTotalCount,
            blackListCount,
            favoriteCount,
        };
    });

export const contractorCardSelector = createSelector(contractorSelector, ({card}) => card);
export const getContractorInitialPersonalData = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.INITIAL_PERSONAL_DATA, card),
);
export const getContractorMigrationData = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.MIGRATION_DATA, card),
);
export const getContractorArrivalNoticeData = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.ARRIVAL_NOTICE_DATA, card),
);
export const getContractorPassportData = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.PASSPORT_DATA, card),
);
export const getContractorFullPersonalData = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.FULL_PERSONAL_DATA, card),
);
export const getContractorPaymentDataSelector = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.PAYMENT_DATA, card),
);
export const getContractorEmploymentHistoryData = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.EMPLOYMENT_HISTORY, card),
);
export const getContractorSnilsInfo = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.SNILS_INFO, card),
);
export const getContractorVhiPolicyInfo = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.VHI_POLICY_INFO, card),
);
export const getContractorEmploymentPatentInfo = createSelector(contractorSelector,
    ({card}) => filterObject(CONTRACTOR_CARD_FIELDS.EMPLOYMENT_PATENT_INFO, card),
);
export const contractorListWithFullNameSelector = createSelector(contractorSelector, ({contractorList}) => {
    return contractorList.map(item => ({
        ...item,
        fullName: getFullName(item.lastName, item.firstName, item.patronymic),
    }));
});
// fullName условие - первичная или не первичная регистрация исполнителя
export const contractorListHistoryPaySelector = createSelector(contractorSelector, ({contractorList}) => {
    return contractorList.map(value => ({...value, clientUserId: value.contractorId, role: CONTRACTOR}));
});
export const contractorTotalPagesSelector = createSelector(contractorSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const contractorTotalCountSelector = createSelector(contractorSelector, ({totalCount}) => totalCount);
export const contractorActionProgressCheckOnEndSelector = createSelector(contractorSelector, ({progressCheckOnEnd}) => progressCheckOnEnd);
export const contractorActionProgressSelector = createSelector(contractorSelector, ({actionProgress}) => actionProgress);
export const contractorPageDataSelector = createSelector(contractorSelector, ({pageData}) => pageData);
export const contractorProgressSelector = createSelector(contractorSelector, state => state.progress);
export const contractorProgressAddSelector = createSelector(contractorSelector, ({progressAdd}) => progressAdd);

export const contractorIsSuccessAddSelector = createSelector(contractorSelector, ({isSuccessAdd}) => isSuccessAdd);
export const getCitizenshipSelector = createSelector(contractorSelector, ({citizenship}) => citizenship);
export const getCitizenshipWithFlagsOptionsSelector = createSelector(contractorSelector, ({citizenship}) => citizenshipToOptions(citizenship));
export const getCitizenshipOptionsSelector = createSelector(contractorSelector, ({citizenship}) => dictionaryToOptions(citizenship));
export const getCitizenshipSmzOnlyOptionsSelector = createSelector(contractorSelector, ({citizenshipSmzOnlyDict}) => dictionaryToOptions(citizenshipSmzOnlyDict));
export const getWorkRegionsOptionsSelector = createSelector(contractorSelector, ({workRegion}) => dictionaryToOptions(workRegion));
export const getWorkRegionsWithRemoteWorkOptionsSelector = createSelector(contractorSelector, ({workRegion}) => {
    return [REMOTE_WORK_OPTION].concat(dictionaryToOptions(workRegion));
});
export const getSupportTotalPagesSelector = createSelector(contractorSelector, ({totalCountSupport, pageDataSupport: {pageSize = 0}}) => getTotalPages(totalCountSupport, pageSize));
export const getSupportTotalCountSelector = createSelector(contractorSelector, ({totalCountSupport}) => totalCountSupport);
export const getSupportListSelector = createSelector(contractorSelector, ({supports}) => supports);
export const getCardSupportSelector = createSelector(contractorSelector, ({cardSupport}) => cardSupport);
export const getCallTypeStatusDictSelector = createSelector(contractorSelector, ({callTypeStatus}) => callTypeStatus);
export const getCaseStatusDictSelector = createSelector(contractorSelector, ({caseStatus}) => caseStatus);
export const getCaseStatusOptionsSelector = createSelector(contractorSelector, ({caseStatus}) => dictionaryToOptions(caseStatus));
export const getProgressSupportUpdateSelector = createSelector(contractorSelector, ({progressSupportUpdate}) => progressSupportUpdate);
export const getProgressCardSelector = createSelector(contractorSelector, ({progressCard}) => progressCard);
export const contractorOptionsListSelector = createSelector(contractorSelector, ({list}) => list.map(({contractorId: value, firstName, lastName, patronymic, fullName}) => ({
    key: value,
    value,
    text: lastName ? `${lastName} ${firstName} ${patronymic || ""}` : fullName,
})));
export const orderStatisticsSelector = createSelector(contractorSelector, ({orderStatistics}) => orderStatistics);
export const contractorCountSelector = createSelector(contractorSelector, ({contractorCount}) => contractorCount);
export const getArchivePhonesSelector = createSelector(contractorSelector, ({archivePhones}) => archivePhones);
export const getBlockedContractorsIdsSelector = createSelector(
    contractorSelector,
    ({blockedContractors}) => blockedContractors.contractors
        .filter(({blocked}) => blocked)
        .map(({contractorId}) => contractorId));
export const pendingPersonalDataSelector = createSelector(contractorSelector, ({pendingPersonalData}) => pendingPersonalData);
export const contractorEdmPassportTypeSelector = createSelector(contractorSelector, ({contractorEdmPassportType}) => dictionaryToOptions(contractorEdmPassportType));
export const contractorEdmStatusesSelector = createSelector(contractorSelector, ({statusDict}) => statusDict);
export const contractorRegistrationStatesOptionsSelector = createSelector(contractorSelector, ({registrationStates}) => dictionaryToOptions(registrationStates));
export const contractorBankStatusSelector = createSelector(contractorSelector, ({contractorBankStatus}) => contractorBankStatus);
export const retailProjectNameIdMapSelector = createSelector(contractorSelector, ({retailProjectsNameIdMap}) => retailObjectsToOptions(retailProjectsNameIdMap));
export const retailObjectNameIdMapSelector = createSelector(contractorSelector, ({retailObjectsNameIdMap}) => retailObjectsToOptions(retailObjectsNameIdMap));

export const contractorOrderWorkReportInfoSelector = createSelector(contractorSelector, ({contractorOrderWorkReportInfo}) => contractorOrderWorkReportInfo);

export const progressEdmConfirmSelector = createSelector(contractorSelector, ({progressEdmConfirm}) => progressEdmConfirm);

export const contractorsMapListSelector = createSelector(contractorSelector, ({mapContractorsList}) => mapContractorsList);
export const contractorsMapTotalCountSelector = createSelector(contractorSelector, ({mapContractorsTotalCount}) => mapContractorsTotalCount);
export const contractorsMapTotalPagesSelector = createSelector(contractorSelector, ({mapContractorsTotalCount, mapContractorsPageData}) => {
    const {pageSize} = mapContractorsPageData;
    return pageSize ? Math.ceil(mapContractorsTotalCount / pageSize) : 0;
});
export const contractorsMapProgressSelector = createSelector(contractorSelector, ({mapContractorsProgress}) => mapContractorsProgress);
export const emptyRecordProgressSelector = createSelector(contractorSelector, ({emptyRecordProgress}) => emptyRecordProgress);
export const importContractorsProgressSelector = createSelector(contractorSelector, ({importContractorsProgress}) => importContractorsProgress);
export const updateLeadsCompletedProgressSelector = createSelector(contractorSelector, ({updateLeadsCompletedProgress}) => updateLeadsCompletedProgress);
export const contractorVhiPolicyFormatsDictSelector = createSelector(contractorSelector, ({vhiPolicyFormatDict}) => vhiPolicyFormatDict);
export const contractorVhiPolicyTypesDictSelector = createSelector(contractorSelector, ({vhiPolicyTypeDict}) => vhiPolicyTypeDict);
export const migrationStatusDictSelector = createSelector(contractorSelector, ({migrationStatusDict}) => migrationStatusDict);
export const contractorMigrationStatusDictSelector = createSelector(contractorSelector, ({contractorMigrationStatusDict}) => contractorMigrationStatusDict);
export const migrationStatusOptionsSelector = createSelector(contractorSelector, ({migrationStatusDict}) => dictionaryToOptions(migrationStatusDict));
export const contractorComparisonDataFormSelector = createSelector(contractorSelector, ({comparisonData}) => {
    const {
        pendingPersonalData = {},
        pendingPersonalData: {
            personalData = {},
            noFileFileDecisions,
            files = [],
        } = {},
        previousPersonalData = {},
        previousFiles = [],
    } = comparisonData;

    return {
        pendingPersonalData,
        files: files.concat(noFileFileDecisions || []),
        personalData: !isEmpty(comparisonData) ? getContractorVerificationPersonalData(personalData) : {},
        previousPersonalData: getContractorVerificationPersonalData(previousPersonalData),
        previousFiles,
    };
});
export const migrationComparisonDataProgressSelector = createSelector(contractorSelector, ({progressComparison}) => progressComparison);
export const contractorComparisonDataSavedFilesSelector = createSelector(contractorSelector, ({comparisonData}) => {
    const {
        pendingPersonalData: {
            files = [],
            rejectionReasons,
        } = {},
    } = comparisonData;

    return files.filter(({decisionType}) => decisionType).map(({type, decisionType, fileId}) => ({
        [type]: {
            decisionType,
            fileId,
            fileType: type,
            rejectionReason: rejectionReasons?.[type] || "",
        },
    }));
});
export const checkInnProgressSelector = createSelector(contractorSelector, ({progressCheckInn}) => progressCheckInn);
export const recommendationModelTypeSelector = createSelector(contractorSelector, ({recommendationModelType}) => recommendationModelType);
export const contractorShortModelListSelector = createSelector(contractorSelector, ({shortModelList}) => shortModelList);

//*  SAGA  *//

export const contractorReadSaga = function* (action) {
    try {
        const {payload: contractorId} = action;
        const result = yield request.get(`${controller}/getById`, {params: {contractorId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_READ_ERROR,
            payload: error,
        });
    }
};

//GET
// /api/contractors/canViewEventLogClientAdmin
// Есть ли доступ к журналу событий исполнителя, который относится к замкнутом контуру заказчика
export const getCanViewEventLogClientAdminSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.get(`${controller}/canViewEventLogClientAdmin`, {params});

        yield onSuccess(result);
    } catch (error) {
        console.error(error);
        yield put({
            type: CONTRACTOR_READ_ERROR,
            payload: error,
        });
    }
};

export const richContractorReadSaga = function* (action) {
    try {
        const {payload: contractorId} = action;
        const result = yield request.get(`${controller}/getRichById`, {params: {contractorId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RICH_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_RICH_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RICH_READ_ERROR,
            payload: error,
        });
    }
};

export const updateContractorSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse = () => {
                },
                onSuccess = () => {
                },
                onError,
                isGetAfter = true,
                successText,
                ...data
            },
        } = action;

        const {
            contractorId,
            workAddressFiasId,
            workAddressInfo,
            departureAddressesFiasIds,
            departureAddressesInfo,
        } = data;

        const dataFormatted = {
            ...data,
            workAddressFiasId: workAddressFiasId ? workAddressFiasId : (workAddressInfo ? getFiasIdByAddressType(workAddressInfo) : null),
            departureAddressesFiasIds: departureAddressesFiasIds ? departureAddressesFiasIds : (departureAddressesInfo ? Object.keys(departureAddressesInfo) : null),
            departureAddressesInfo: null,
            workAddressInfo: null,
        };

        const {
            errorMessage,
            errorCode,
            invalidFieldNames,
        } = yield request.post(`${controller}/update`, dataFormatted);

        handleResponse(errorMessage, errorCode);

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError({errorCode, invalidFieldNames});
            }

            yield put({
                type: CONTRACTOR_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(successText || CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put({
            type: CONTRACTOR_UPDATE_SUCCESS,
        });

        yield onSuccess();

        if (isGetAfter) {
            yield put({type: CONTRACTOR_RICH_READ_REQUEST, payload: contractorId});
        }
    } catch (error) {
        toastError(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_ERROR_TEXT);

        yield put({
            type: CONTRACTOR_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const addContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_ADD_SUCCESS_TEXT);
        yield put({
            type: CONTRACTOR_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADD_ERROR,
            payload: error,
        });
    }
};

export const getHiringContractorOptimizedListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getOptimizedHiringRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const getHiringContractorRichListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getHiringRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_HIRING_RICH_LIST_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_HIRING_RICH_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_HIRING_RICH_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const contractorRichListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getRichPage`, payload);

        yield put({
            type: CONTRACTOR_RICH_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RICH_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const bffContractorRichListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.bff.post("/adm/contractors-registry/registry/getPage", payload);

        yield put({
            type: BFF_CONTRACTOR_RICH_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_RICH_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const getContractorShortListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getShortPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_SHORT_LIST_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SHORT_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SHORT_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const contractorListReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const deleteContractorFromBlackListSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.delete(`${controller}/blacklist/${contractorId ? "delete" : "deleteAll"}`, {
            data,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        toastSuccess("Удаление из чёрного списка выполнено успешно");
        yield put({
            type: CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
            payload: error,
        });
    }
};

export const deleteFavoriteContractorsSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.delete(`${controller}/favourite/${contractorId ? "delete" : "deleteAll"}`, {
            data,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        toastSuccess("Удаление из избранных выполнено успешно");
        yield put({
            type: CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
            payload: error,
        });
    }
};

export const addContractorToBlackListSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.post(`${controller}/blacklist/${contractorId ? "add" : "addAll"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Добавление в чёрный список выполнено успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
            payload: error,
        });
    }
};

export const addFavoriteContractorsSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.post(`${controller}/favourite/${contractorId ? "add" : "addAll"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_ADD_FAVORITE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Добавление в избранные выполнено успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADD_FAVORITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_ADD_FAVORITE_ERROR,
            payload: error,
        });
    }
};

export const contractorListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getListByIds`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
            payload: error,
        });
    }
};

export const getSupportListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/support/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SUPPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SUPPORT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/support/getById/{caseNumber}
export const getSupportCardSaga = function* (action) {
    try {
        const {payload} = action;
        const {caseNumber} = payload;

        const result = yield request.get(`${controller}/support/getById/${caseNumber}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SUPPORT_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SUPPORT_CARD_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/citizenship
export const getContractorCitizenshipReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/v2/citizenship`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_CITIZENSHIP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_CITIZENSHIP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_CITIZENSHIP_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/citizenship
export const getCitizenshipsSmzOnlySaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/v2/citizenship/smz-only`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/vhi-policy-format
// Список форм страхового полиса
export const getContractorVhiPolicyFormatsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/vhi-policy-format`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_VHI_POLICY_FORMAT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_VHI_POLICY_FORMAT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/vhi-policy-type
// Список типов страхового полиса
export const getContractorVhiPolicyTypesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/vhi-policy-type`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_VHI_POLICY_TYPE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_VHI_POLICY_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_VHI_POLICY_TYPE_ERROR,
            payload: error,
        });
    }
};

export const getContractorWorkRegionsReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/workRegion`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_WORKREGION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_WORKREGION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_WORKREGION_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/contractorSupportCaseStatus
export const getCaseStatusReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorSupportCaseStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_CASE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SUPPORT_CASE_STATUS_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/contractorSupportCallTypeStatus
export const getSupportCallTypeStatusSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorSupportCallTypeStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR,
            payload: error,
        });
    }
};

export const cancelContractorAutoTaxPaymingSaga = function* (action) {
    try {
        const {
            payload: {
                contractorId,
                isChecked,
            },
        } = action;

        const result = yield request.post(`/taxAuthorities/taxPayments/cancel?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_CANCEL_AUTO_TAXPAY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(`${isChecked ? "Выключение" : "Включение"} автоматическаой уплаты налогов успешно отменено `);

        yield put({
            type: CONTRACTOR_CANCEL_AUTO_TAXPAY_SUCCESS,
            payload: result,
        });

        yield put(getContractorById(contractorId));
    } catch (error) {
        toastError("Не удалось выполнить действие");
        yield put({
            type: CONTRACTOR_CANCEL_AUTO_TAXPAY_ERROR,
            payload: error,
        });
    }
};

export const changeContractorAutoTaxPaymingSaga = function* (action) {
    try {
        const {
            payload: {
                data: {
                    smzTaxOffer,
                    contractorId,
                },
                onSuccess,
            },
        } = action;

        const result = yield request.post(`/taxAuthorities/taxPayments/set?contractorId=${contractorId}&smzTaxOffer=${smzTaxOffer}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_CHANGE_AUTO_TAXPAY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(`Автоматическая уплата налогов успешно ${smzTaxOffer ? "включена" : "выключена"}`);

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_CHANGE_AUTO_TAXPAY_SUCCESS,
            payload: result,
        });

        yield put(getRichContractorById(contractorId));
    } catch (error) {
        toastError("Не удалось выполнить действие");
        yield put({
            type: CONTRACTOR_CHANGE_AUTO_TAXPAY_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/support/update
export const updateSupportSaga = function* (action) {
    try {
        const {payload} = action;

        const {errorMessage} = yield request.post(`${controller}/support/update`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_SUPPORT_UPDATE_SUCCESS_TEXT);
        yield put({
            type: CONTRACTOR_SUPPORT_UPDATE_SUCCESS,
        });

    } catch (error) {
        toastError(CONTRACTOR_MESSAGE.CONTRACTOR_SUPPORT_UPDATE_ERROR_TEXT);
        yield put({
            type: CONTRACTOR_SUPPORT_UPDATE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getByPhone
export const getContractorByPhoneSaga = function* (action) {
    try {
        const {
            payload: {
                data: params,
                handleResponse,
            },
        } = action;

        const result = yield request.get(`${controller}/getByPhone`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_BY_PHONE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_CONTRACTOR_BY_PHONE_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_CONTRACTOR_BY_PHONE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/support/update
export const getCountOfContractorsForPushNotificationsSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                handleResponse,
            },
        } = action;

        const result = yield request.post(`${controller}/countOfContractorsForPushNotifications`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/promotion/check
export const checkContractorPromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                handleResponse,
            },
        } = action;

        const result = yield request.post(`${controller}/promotion/check`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_CHECK_PROMOCODE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: CONTRACTOR_CHECK_PROMOCODE_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CONTRACTOR_CHECK_PROMOCODE_ERROR,
            payload: error,
        });
    }
};

//POST/api/contractors/promotion/check
export const registrationContractorPromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                handleResponse,
            },
        } = action;

        const result = yield request.post(`${controller}/promotion/registration`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_REGISTRATION_PROMOCODE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {promocode} = data;
        toastSuccess(`Промокод \"${promocode}\" успешно применен. Промокод можно использовать только один раз.`);

        handleResponse(result);

        yield put({
            type: CONTRACTOR_REGISTRATION_PROMOCODE_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CONTRACTOR_REGISTRATION_PROMOCODE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/promotion/getPromocodeByContractorId

export const getPromocodeByContractorIdSaga = function* (action) {
    try {
        const {
            payload: {
                data: params,
                handleResponse,
            },
        } = action;

        const result = yield request.get(`${controller}/promotion/getPromocodeByContractorId`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROMOCODE_BY_CONTRACTOR_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_PROMOCODE_BY_CONTRACTOR_ID_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_PROMOCODE_BY_CONTRACTOR_ID_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/getNotForemanContractorsPage

export const getNotForemanContractorsPageSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const result = yield request.post(`${controller}/getNotForemanContractorsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR,
            payload: error,
        });
    }
};

const getContractorCountSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/count`, payload);

        yield put({
            type: GET_CONTRACTOR_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_COUNT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getOrderStatistics
export const getOrdersStatisticsByContractorIdSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getOrderStatistics?contractorId=${payload}`);

        yield put({type: SET_CONTRACTOR_ORDER_STATISTICS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_ORDER_STATISTICS_ERROR, payload: error});
    }
};

// POST /api/contractors/phones/getArchivePhones
export const getArchivePhonesSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/phones/getArchivePhones`, payload);

        yield put({type: CONTRACTOR_ARCHIVE_PHONES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONTRACTOR_ARCHIVE_PHONES_ERROR, payload: error});
    }
};

//POST /api/contractors/getApplicationsRichPage
export const getContractorsByOrderSaga = function* ({payload}) {
    try {
        const {nameField, ...reqData} = payload;
        const result = yield request.post(`${controller}/getApplicationsRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CONTRACTORS_BY_ORDER_SUCCESS,
            payload: {
                ...result,
                nameField,
            },
        });
    } catch (error) {
        yield put({type: GET_CONTRACTORS_BY_ORDER_ERROR, payload: error});
    }
};

//POST /api/contractors/getAllApplicationsRichPage
export const getAllApplicationsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getAllApplicationsRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            return {
                done: true,
            };
        }

        yield put({type: GET_ALL_APPLICATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_ALL_APPLICATIONS_ERROR, payload: error});
    }
};

// GET /api/contractors/notes/client/find
export const getNoteSaga = function* ({payload}) {
    try {
        const {
            clientId,
            contractorId,
        } = payload;

        const result = yield request.get(`${controller}/notes/client/find?clientId=${clientId}&contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: GET_NOTE_SUCCESS, payload: result.text});
    } catch (error) {
        yield put({type: GET_NOTE_ERROR, payload: error});
    }
};

// POST /api/contractors/notes/client/add
export const addNoteSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            clientId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/notes/client/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        toastSuccess("Заметка успешно добавлена.");
        onSuccess();

        yield put({type: ADD_NOTE_SUCCESS, payload: result.text});
        yield put({type: GET_NOTE_REQUEST, payload: {contractorId, clientId}});
    } catch (error) {
        yield put({type: ADD_NOTE_ERROR, payload: error});
    }
};

export const getContractorOrderStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorOrderStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_ORDER_STATUS_DICT_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: CONTRACTOR_ORDER_STATUS_DICT_ERROR, payload: error});
    }
};

export const getContractorStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getRegistrationStatusDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_STATUS_DICT_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: CONTRACTOR_STATUS_DICT_ERROR, payload: error});
    }
};

// PATCH /api/contractors/notes/client/update
export const updateNoteSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            clientId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.patch(`${controller}/notes/client/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: UPDATE_NOTE_SUCCESS, payload: result.text});
        yield put({type: GET_NOTE_REQUEST, payload: {contractorId, clientId}});
    } catch (error) {
        yield put({type: UPDATE_NOTE_ERROR, payload: error});
    }
};

// POST /api/contractors/getBlockedHistory
export const getBlockedHistorySaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getBlockedHistory?contractorId=${payload}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: GET_BLOCKED_HISTORY_SUCCESS, payload: result.blockedContractors});
    } catch (error) {
        yield put({type: GET_BLOCKED_HISTORY_ERROR, payload: error});
    }
};

//POST /api/contractors/getPhonesByXlsxFile
export const getPhonesByXlsxFileSaga = function* ({payload}) {
    const {
        clientId,
        formData,
        handleResponse,
    } = payload;

    try {
        const result = yield request.post(
            `${controller}/getPhonesByXlsxFileAndOldPhones`,
            formData,
            {
                ...getMultipartHeaders(),
                params: {
                    clientId,
                },
            },
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PHONES_BY_XLSX_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_PHONES_BY_XLSX_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_PHONES_BY_XLSX_FILE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getContractorBlockedList
export const getBlockedContractorsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getContractorBlockedListPost`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: GET_BLOCKED_CONTRACTORS_SUCCESS, payload: result.contractors});
    } catch (error) {
        yield put({type: GET_BLOCKED_CONTRACTORS_ERROR, payload: error});
    }
};

//POST /api/contractors/retail/getContractorPage
export const getContractorsRetailPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/retail/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: GET_CONTRACTORS_RETAILS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTORS_RETAILS_PAGE_ERROR, payload: error});
    }
};

//POST /api/contractors/retail/update
export const updateContractorRetailSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/retail/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        getSuccess();
        yield put({type: UPDATE_CONTRACTOR_RETAIL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_RETAIL_ERROR, payload: error});
    }
};

// GET /api/contractors/personaldata/pending/get
export const getPendingPersonalDataSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/personaldata/pending/get?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: GET_PENDING_PERSONAL_DATA_SUCCESS, payload: result});

    } catch ({message}) {
        console.error("getPendingPersonalDataSaga ERROR", message);
    }
};

// POST /api/contractors/personaldata/pending/decide
export const postPendingPersonalDataSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
            onError,
        } = payload;

        const result = yield request.post(`${controller}/personaldata/pending/decide`, data);

        const {errorMessage, warningMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: POST_PENDING_PERSONAL_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (warningMessage) {
            toastWarning(warningMessage);
        }

        onSuccess();

        yield put({type: POST_PENDING_PERSONAL_DATA_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: POST_PENDING_PERSONAL_DATA_ERROR, payload: error,
        });
    }
};

// GET contractors/contractorDataToBankState
export const getContractorBankStatesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            contractorId,
        } = payload;

        const result = yield request.get(`${controller}/contractorDataToBankState?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: GET_CONTRACTOR_DATA_BANK_STATE_SUCCESS, payload: result});
    } catch ({message}) {
        toastError(message);
    }
};

// POST /api/contractors/sendContractorDataToBank
export const postContractorDataToBankSaga = function* ({payload}) {
    try {
        const {
            data: {
                contractorId,
                sendPersonalData,
                sendDocumentScans,
            },
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/sendContractorDataToBank?contractorId=${contractorId}&sendPersonalData=${sendPersonalData}&sendDocumentScans=${sendDocumentScans}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Данные отправлены в банк");

        yield put({type: SEND_CONTRACTOR_DATA_TO_BANK_SUCCESS, payload: result});
    } catch ({message}) {
        toastError(message);
    }
};

// POST /api/job/export/addRetailExport
export const retailExportSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post("/job/export/addRetailExport", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: RETAIL_EXPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".", {type: "success"});

        yield put({
            type: RETAIL_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: RETAIL_EXPORT_ERROR, payload: error.message});
    }
};

export const getContractorOrderWorkReportInfoSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess = () => {
                },
                data,
            },
        } = action;

        const result = yield request.get(`${controller}/getContractorOrderWorkReport`, {params: data});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR, payload: error.message});
    }
};

//POST /api/contractors/archiveContractor
export const archiveContractorSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess = () => {
                },
                contractorId,
            },
        } = action;

        const result = yield request.post(`${controller}/archiveContractor?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ARCHIVE_CONTRACTOR_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: ARCHIVE_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: ARCHIVE_CONTRACTOR_ERROR, payload: error.message});
    }
};

//GET /api/contractors/dicts/contractorEdmPassportType
export const getContractorEdmPassportTypeSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorEdmPassportType`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONTRACTOR_EDM_PASSPORT_TYPE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: CONTRACTOR_EDM_PASSPORT_TYPE_ERROR, payload: error.message});
    }
};


export const getContractorRegistrationStatesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getRegistrationStateFilterValuesDict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONTRACTOR_REGISTRATION_STATE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_REGISTRATION_STATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: CONTRACTOR_REGISTRATION_STATE_ERROR, payload: error.message});
    }
};

//GET /api/contractors/retail/startJob
export const retailStartJobSaga = function* () {
    try {
        const result = yield request.get(`${controller}/retail/startJob`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: RETAIL_START_JOB_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Анализ запущен");

        yield put({
            type: RETAIL_START_JOB_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: RETAIL_START_JOB_ERROR, payload: error.message});
    }
};

// GET /api/contractors/getContractorBankStatus
export const getContractorBankStatusSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getContractorBankStatus?contractorId=${payload}`);

        yield put({
            type: GET_CONTRACTOR_BANK_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_BANK_STATUS_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/retail/getProjects
export const getRetailProjectIdNameMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/retail/getProjects`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_RETAIL_PROJECT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_RETAIL_PROJECT_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_RETAIL_PROJECT_LIST_ERROR,
            payload: error,
        });
    }
};

//GET api/contractors/retail/getObjects
export const getRetailObjectIdNameMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/retail/getObjects`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_RETAIL_OBJECT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_RETAIL_OBJECT_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_RETAIL_OBJECT_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/personaldata/pending/getPersonalDataBasedInnStatus
export const getInnStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;

        const result = yield request.post(`${controller}/personaldata/pending/getPersonalDataBasedInnStatus`, {...data});

        onSuccess(result);

        yield put({
            type: GET_PERSONAL_DATA_INN_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
    }
};


//POST /api/contractors/personaldata/pending/getPersonalDataBasedIdDocStatus
export const getPassportStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;

        const result = yield request.post(`${controller}/personaldata/pending/getPersonalDataBasedIdDocStatus`, {...data});

        onSuccess(result);

        yield put({
            type: GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
    }
};

// GET /api/contractors/personaldata/pending/comparison
export const getPersonalDataPendingComparisonSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/personaldata/pending/comparison?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS,
            payload: result,
        });

    } catch ({message}) {
        toastError(message);

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR,
            payload: message,
        });
    }
};

// GET /api/contractors/personaldata/pending/comparison/availability
export const getPersonalDataPendingComparisonAvailabilitySaga = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess = () => {
            },
            onError = () => {
            },
        } = payload;

        const result = yield request.get(`${controller}/personaldata/pending/comparison/availability?contractorId=${contractorId}`);
        const {errorCode} = result;

        if (errorCode) {
            onError(result);

            yield put({
                type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS,
            payload: result,
        });

    } catch ({message}) {
        toastError(message);

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR,
            payload: message,
        });
    }
};

//POST /api/contractors/personaldata/pending/confirm/edm
export const confirmPersonalDataEdmSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;
        const result = yield request.post(`${controller}/personaldata/pending/confirm/edm`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: PERSONAL_DATA_PENDING_CONFIRM_EDM_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: PERSONAL_DATA_PENDING_CONFIRM_EDM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: PERSONAL_DATA_PENDING_CONFIRM_EDM_ERROR, payload: error.message});
    }
};


//POST /api/contractors/updateProjectParams
export const updateProjectParamsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/updateProjectParams`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_PROJECT_PARAMS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield onSuccess();
        yield put(getRichContractorById(payload.contractorId));
        yield put({
            type: UPDATE_PROJECT_PARAMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: UPDATE_PROJECT_PARAMS_ERROR, payload: error.message});
    }
};

//POST /api/contractors/updateLeadsCompleted //Обновление поля завершения обработки исполнителя
export const updateLeadsCompletedSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/updateLeadsCompleted`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_LEADS_COMPLETED_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield onSuccess();
        yield put(getRichContractorById(payload.contractorId));
        yield put({
            type: UPDATE_LEADS_COMPLETED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: UPDATE_LEADS_COMPLETED_ERROR, payload: error.message});
    }
};

//POST /api/contractors/updatePreviousPassport
export const updatePreviousPassportSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/updatePreviousPassport`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_PREVIOUS_PASSPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put(getRichContractorById(payload.contractorId));
        yield put({
            type: UPDATE_PREVIOUS_PASSPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: UPDATE_PREVIOUS_PASSPORT_ERROR, payload: error.message});
    }
};

//POST /api/contractors/getActiveOrderNumbersByContractor
export const getActiveOrderNumbersSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            contractorId,
        } = payload;
        const result = yield request.post(`${controller}/getActiveOrderNumbersByContractor?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_ACTIVE_ORDER_NUMBERS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: GET_ACTIVE_ORDER_NUMBERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: GET_ACTIVE_ORDER_NUMBERS_ERROR, payload: error.message});
    }
};

//POST /api/contractors/getRichMapPage
export const getContractorRichMapListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getRichMapPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_RICH_MAP_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_RICH_MAP_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RICH_MAP_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST
// /api/contractors/blocked/update

export const updateContractorBlockedSaga = function* (action) {
    try {
        const {payload: {
            getResult,
            ...data
        }} = action;
        const result = yield request.post(`${controller}/blocked/update`, data);
        const {errorMessage} = result;
        getResult && getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_BLOCKED_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put({
            type: UPDATE_CONTRACTOR_BLOCKED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_BLOCKED_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getEmptyRecord
export const getEmptyRecordSaga = function* ({payload}) {
    try {
        const {
            contractorPhone,
            getResult = () => {},
        } = payload;
        const result = yield request.get(`${controller}/getEmptyRecord`, {params: {contractorPhone}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_EMPTY_RECORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: GET_EMPTY_RECORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_EMPTY_RECORD_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/deleteEmptyRecord
export const deleteEmptyRecordSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteEmptyRecord?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_EMPTY_RECORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: DELETE_EMPTY_RECORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_EMPTY_RECORD_ERROR,
            payload: error,
        });
    }
};

//POST /api/job/contractor/prefilled/import
const importContractorsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            formData,
        } = payload;
        const result = yield request.post("/job/contractor/prefilled/import", formData, {...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: IMPORT_CONTRACTORS_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Задача по импорту исполнителей передана в обработку");
        onSuccess();

        yield put({
            type: IMPORT_CONTRACTORS_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: IMPORT_CONTRACTORS_ERROR,
        });
    }
};

//GET /api/contractors/dicts/migration-status
// Список миграционных статусов исполнителя
export const getMigrationStatusDictSaga = function* ({payload = {}}) {
    try {
        const {
            nameField,
            citizenship,
            getResult = () => {},
        } = payload;

        const result = yield request.get(`${controller}/dicts/migration-status`, {params: {citizenship}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS,
            payload: {
                result,
                nameField,
            },
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

export const getPendingAdditionalDocumentsRichPageSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getRichPage/pendingAdditionalDocuments`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR,
            payload: error,
        });
    }
};

export const updateContractorRegistrationStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            onError = () => {},
        } = payload;
        const result = yield request.post(`${controller}/registrationStatus/update`, payload);
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            errorCode !== CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE.SEMI_REQUIRED_PERSONAL_DATA_NOT_FILLED &&
            toastError(errorMessage);

            onError(result);
            yield put({
                type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const refreshContractorTaxStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            getResult = () => {},
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/taxStatus/refresh`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_TAX_STATUS_REFRESH_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult(result);
        onSuccess();

        yield put({
            type: CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_TAX_STATUS_REFRESH_ERROR,
            payload: error,
        });
    }
};

export const checkInnBeforeAdditionSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/inn/validateBeforeAddition`, reqData);
        getResult(result);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_INN_VALIDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_INN_VALIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_INN_VALIDATE_ERROR,
            payload: error,
        });
    }
};

export const getContractorFilesRequiredListSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/personalData/files/required/list`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: GET_CONTRACTOR_FILES_REQUIRED_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR,
            payload: error,
        });
    }
};

export const getInnByPersonalInfoSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;
        const result = yield request.bff.post("/adm/contractors-registry/contractor/personal-data/getInnByPersonalInfo", reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_INN_BY_PERSONAL_INFO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: GET_INN_BY_PERSONAL_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_INN_BY_PERSONAL_INFO_ERROR,
            payload: error,
        });
    }
};


export const endMyCheckOnContractorPersonalDataStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;
        const result = yield request.bff.post(
            `/adm/contractors-registry/contractor/personal-data/endMyCheckOnContractorPersonalDataStatus?contractorId=${contractorId}`,
        );

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
            payload: error,
        });
    }
};

export const endCheckOnContractorPersonalDataStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;
        const result = yield request.bff.post(
            `/adm/contractors-registry/contractor/personal-data/endCheckOnContractorPersonalDataStatus?contractorId=${contractorId}`,
        );

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/personaldata/pending/draft/complete
export const contractorPersonalDataPendingDraftCompleteSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;
        const result = yield request.post(`${controller}/personaldata/pending/draft/complete?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR,
            payload: error,
        });
    }
};

//PUT /bff/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/bank-requisites-and-email/update
// Обновление банковских реквизитов
export const updateContractorIndividualEntrepreneurBankRequisitesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put("adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/bank-requisites-and-email/update", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR,
            payload: error,
        });
    }
};

//PUT
// /bff/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/legal-info/update
// Обновление инфо о ИП
export const updateContractorIndividualEntrepreneurLegalInfoSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put("/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/legal-info/update", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR,
            payload: error,
        });
    }
};

//POST
// /bff/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/legal-info-and-status/retrieve-and-save
// Получение статуса ИП исполнителя из внешнего источника
export const updateContractorIndividualEntrepreneurStatusSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/legal-info-and-status/retrieve-and-save", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR,
            payload: error,
        });
    }
};

//DELETE
// /bff/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/delete
// Удаление инфо о ИП
export const deleteContractorIndividualEntrepreneurDataSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...params
        } = payload;

        const result = yield request.bff.delete("/adm/contractors-registry/contractor/personal-data/individual-entrepreneur/data/delete", {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_READ_REQUEST, contractorReadSaga),
        takeEvery(CONTRACTOR_LIST_READ_REQUEST, contractorListReadSaga),
        takeEvery(CONTRACTOR_RICH_LIST_READ_REQUEST, contractorRichListSaga),
        takeEvery(BFF_CONTRACTOR_RICH_LIST_READ_REQUEST, bffContractorRichListSaga),
        takeEvery(CONTRACTOR_SHORT_LIST_READ_REQUEST, getContractorShortListSaga),
        takeEvery(CONTRACTOR_HIRING_RICH_LIST_READ_REQUEST, getHiringContractorRichListSaga),
        takeEvery(CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST, getHiringContractorOptimizedListSaga),
        takeEvery(CONTRACTOR_UPDATE_REQUEST, updateContractorSaga),
        takeEvery(CONTRACTOR_ADD_REQUEST, addContractorSaga),
        takeEvery(CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST, contractorListSaga),
        takeEvery(CONTRACTOR_CITIZENSHIP_REQUEST, getContractorCitizenshipReadSaga),
        takeEvery(CONTRACTOR_VHI_POLICY_FORMAT_REQUEST, getContractorVhiPolicyFormatsSaga),
        takeEvery(CONTRACTOR_VHI_POLICY_TYPE_REQUEST, getContractorVhiPolicyTypesSaga),
        takeEvery(CONTRACTOR_WORKREGION_REQUEST, getContractorWorkRegionsReadSaga),
        takeEvery(CONTRACTOR_SUPPORT_REQUEST, getSupportListSaga),
        takeEvery(CONTRACTOR_SUPPORT_CARD_REQUEST, getSupportCardSaga),
        takeEvery(CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST, getSupportCallTypeStatusSaga),
        takeEvery(CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST, getCaseStatusReadSaga),
        takeEvery(CONTRACTOR_CHANGE_AUTO_TAXPAY_REQUEST, changeContractorAutoTaxPaymingSaga),
        takeEvery(CONTRACTOR_CANCEL_AUTO_TAXPAY_REQUEST, cancelContractorAutoTaxPaymingSaga),
        takeEvery(CONTRACTOR_RICH_READ_REQUEST, richContractorReadSaga),
        takeEvery(CONTRACTOR_SUPPORT_UPDATE_REQUEST, updateSupportSaga),
        takeEvery(GET_CONTRACTOR_BY_PHONE_REQUEST, getContractorByPhoneSaga),
        takeEvery(GET_COUNT_OF_CONTRACTORS_FOR_PUSH_NOTIFICATION_REQUEST, getCountOfContractorsForPushNotificationsSaga),
        takeEvery(GET_CONTRACTOR_ORDER_STATISTICS, getOrdersStatisticsByContractorIdSaga),
        takeEvery(CONTRACTOR_CHECK_PROMOCODE_REQUEST, checkContractorPromocodeSaga),
        takeEvery(CONTRACTOR_REGISTRATION_PROMOCODE_REQUEST, registrationContractorPromocodeSaga),
        takeEvery(GET_PROMOCODE_BY_CONTRACTOR_ID_REQUEST, getPromocodeByContractorIdSaga),
        takeEvery(GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST, getNotForemanContractorsPageSaga),
        takeEvery(CONTRACTOR_ARCHIVE_PHONES_REQUEST, getArchivePhonesSaga),
        takeEvery(CONTRACTOR_ADD_FAVORITE_REQUEST, addFavoriteContractorsSaga),
        takeEvery(CONTRACTOR_ADD_TO_BLACKLIST_REQUEST, addContractorToBlackListSaga),
        takeEvery(CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST, deleteContractorFromBlackListSaga),
        takeEvery(CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST, deleteFavoriteContractorsSaga),
        takeEvery(GET_CONTRACTORS_BY_ORDER_REQUEST, getContractorsByOrderSaga),
        takeEvery(GET_CONTRACTOR_COUNT_REQUEST, getContractorCountSaga),
        takeEvery(GET_ALL_APPLICATIONS_REQUEST, getAllApplicationsSaga),
        takeEvery(GET_NOTE_REQUEST, getNoteSaga),
        takeEvery(ADD_NOTE_REQUEST, addNoteSaga),
        takeEvery(UPDATE_NOTE_REQUEST, updateNoteSaga),
        takeEvery(CONTRACTOR_STATUS_DICT_REQUEST, getContractorStatusDictSaga),
        takeEvery(CONTRACTOR_ORDER_STATUS_DICT_REQUEST, getContractorOrderStatusDictSaga),
        takeEvery(GET_BLOCKED_HISTORY_REQUEST, getBlockedHistorySaga),
        takeEvery(GET_PHONES_BY_XLSX_FILE_REQUEST, getPhonesByXlsxFileSaga),
        takeEvery(GET_BLOCKED_CONTRACTORS_REQUEST, getBlockedContractorsSaga),
        takeEvery(GET_CONTRACTORS_RETAILS_PAGE_REQUEST, getContractorsRetailPageSaga),
        takeEvery(UPDATE_CONTRACTOR_RETAIL_REQUEST, updateContractorRetailSaga),
        takeEvery(GET_PENDING_PERSONAL_DATA_REQUEST, getPendingPersonalDataSaga),
        takeEvery(POST_PENDING_PERSONAL_DATA_REQUEST, postPendingPersonalDataSaga),
        takeEvery(GET_CONTRACTOR_DATA_BANK_STATE_REQUEST, getContractorBankStatesSaga),
        takeEvery(SEND_CONTRACTOR_DATA_TO_BANK_REQUEST, postContractorDataToBankSaga),
        takeEvery(RETAIL_EXPORT_REQUEST, retailExportSaga),
        takeEvery(GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST, getContractorOrderWorkReportInfoSaga),
        takeEvery(ARCHIVE_CONTRACTOR_REQUEST, archiveContractorSaga),
        takeEvery(CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST, getContractorEdmPassportTypeSaga),
        takeEvery(CONTRACTOR_REGISTRATION_STATE_REQUEST, getContractorRegistrationStatesSaga),
        takeEvery(RETAIL_START_JOB_REQUEST, retailStartJobSaga),
        takeEvery(GET_CONTRACTOR_BANK_STATUS_REQUEST, getContractorBankStatusSaga),
        takeEvery(GET_RETAIL_PROJECT_LIST_REQUEST, getRetailProjectIdNameMapSaga),
        takeEvery(GET_RETAIL_OBJECT_LIST_REQUEST, getRetailObjectIdNameMapSaga),
        takeEvery(GET_PERSONAL_DATA_INN_STATUS_REQUEST, getInnStatusSaga),
        takeEvery(GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST, getPassportStatusSaga),
        takeEvery(GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST, getPersonalDataPendingComparisonSaga),
        takeEvery(GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST, getPersonalDataPendingComparisonAvailabilitySaga),
        takeEvery(PERSONAL_DATA_PENDING_CONFIRM_EDM_REQUEST, confirmPersonalDataEdmSaga),
        takeEvery(GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST, getCanViewEventLogClientAdminSaga),
        takeEvery(UPDATE_PROJECT_PARAMS_REQUEST, updateProjectParamsSaga),
        takeEvery(UPDATE_LEADS_COMPLETED_REQUEST, updateLeadsCompletedSaga),
        takeEvery(UPDATE_PREVIOUS_PASSPORT_REQUEST, updatePreviousPassportSaga),
        takeEvery(GET_ACTIVE_ORDER_NUMBERS_REQUEST, getActiveOrderNumbersSaga),
        takeEvery(CONTRACTOR_RICH_MAP_PAGE_REQUEST, getContractorRichMapListSaga),
        takeEvery(UPDATE_CONTRACTOR_BLOCKED_REQUEST, updateContractorBlockedSaga),
        takeEvery(GET_EMPTY_RECORD_REQUEST, getEmptyRecordSaga),
        takeEvery(DELETE_EMPTY_RECORD_REQUEST, deleteEmptyRecordSaga),
        takeEvery(IMPORT_CONTRACTORS_REQUEST, importContractorsSaga),
        takeEvery(GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST, getMigrationStatusDictSaga),
        takeEvery(CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST, getPendingAdditionalDocumentsRichPageSaga),
        takeEvery(CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST, updateContractorRegistrationStatusSaga),
        takeEvery(CONTRACTOR_TAX_STATUS_REFRESH_REQUEST, refreshContractorTaxStatusSaga),
        takeEvery(CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST, getCitizenshipsSmzOnlySaga),
        takeEvery(CONTRACTOR_INN_VALIDATE_REQUEST, checkInnBeforeAdditionSaga),
        takeEvery(GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST, getContractorFilesRequiredListSaga),
        takeEvery(GET_INN_BY_PERSONAL_INFO_REQUEST, getInnByPersonalInfoSaga),
        takeEvery(CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST, contractorPersonalDataPendingDraftCompleteSaga),
        takeEvery(
            CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
            endCheckOnContractorPersonalDataStatusSaga,
        ),
        takeEvery(
            CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
            endMyCheckOnContractorPersonalDataStatusSaga,
        ),
        takeEvery(
            UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST,
            updateContractorIndividualEntrepreneurBankRequisitesSaga,
        ),
        takeEvery(
            UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST,
            updateContractorIndividualEntrepreneurLegalInfoSaga,
        ),
        takeEvery(
            UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST,
            updateContractorIndividualEntrepreneurStatusSaga,
        ),
        takeEvery(
            DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST,
            deleteContractorIndividualEntrepreneurDataSaga,
        ),
    ]);
}
