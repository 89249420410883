import React from "react";

import CrowdTaskCardContractorList from "../components/contractor-list";

import {
    getPageCrowdTaskContractorsQueue,
} from "../../../../../ducks/bff/crowd/taskCard/actionCreators";

const CrowdTaskCardQueueList = (props) => {
    const {
        match: {
            params: {
                clientId,
                taskId,
            },
        },
    } = props;

    return (
        <CrowdTaskCardContractorList
            clientId={clientId}
            taskId={taskId}
            fetchMethod={getPageCrowdTaskContractorsQueue}
            isQueuePage={true}
        />
    );
};

export default CrowdTaskCardQueueList;