import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {getCrowdActRegistry} from "../../../../../ducks/bff/crowd/actRegistry/actionCreators";
import {
    getPageCrowdActRegistryEntry,
    updateStoreCrowdActRegistryEntry,
} from "../../../../../ducks/bff/crowd/actRegistryEntry/actionCreators";
import {jobTasksCrowdActRegistrySelector} from "../../../../../ducks/job";

export const useCrowdTaskActRegistryCardFetch = (props) => {
    const {
        clientId,
        registryId,
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();
    const progressTasks = useSelector(jobTasksCrowdActRegistrySelector);
    const currentRegistryProgressTask = progressTasks.filter(item => {
        const details = JSON.parse(item.details);

        return registryId === details.registryId;
    });
    const prevTasksList = useGetPrevValue(currentRegistryProgressTask);

    useEffect(() => {
        fetchCard();

        return () => {
            dispatch(updateStoreCrowdActRegistryEntry({
                pageData: {},
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filter,
    ]);

    useEffect(() => {
        if (isEmpty(currentRegistryProgressTask) && !isEmpty(prevTasksList)) {
            fetchCard();
            fetchList();
        }
    }, [currentRegistryProgressTask]);

    const fetchCard = () => {
        dispatch(getCrowdActRegistry({registryId}));
    };

    const fetchList = () => {
        dispatch(getPageCrowdActRegistryEntry({
            clientId,
            registryId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchCard,
        fetchList,
    };
};