import {useEffect} from "react";
import {isEmpty} from "lodash";

import {useFilter} from "../../../../../../hooks/useFilter";

import {getContractorLastLoginFilters} from "../../../../../../utils/contractors";
import {removePhoneMask} from "../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";

import {citizenshipsDict} from "../../../../../../constants/citizenships";

const useCrowdTaskCardInviteCandidatesListFilter = (props) => {
    const {
        pageSize,
        setPagination,
        taskCard,
    } = props;

    const {
        specialityId,
        objectAddressSettlementOrUpperFiasId,
    } = taskCard;

    const initFilter = {
        fioFilter: "",
        citizenshipFilter: [citizenshipsDict.RU.value],
    };

    function mapFilterDto(filter) {
        const {
            fioFilter,
            phoneFilter,
            lastLoginFilter,
            citizenshipFilter,
            ...otherFilters
        } = filter;

        const lastLoginFilters = getContractorLastLoginFilters(lastLoginFilter);

        return {
            fioFilter: handleFormString(fioFilter),
            phoneFilter: handleFormString(removePhoneMask(phoneFilter)),
            ...lastLoginFilters,
            ...otherFilters,
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        setFilter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    useEffect(() => {
        if (!isEmpty(taskCard)) {
            const defaultFilter = {
                ...initFilter,
                specialityIdsFilter: specialityId ? [specialityId] : [],
                addressFiasIdsFilter: objectAddressSettlementOrUpperFiasId ?
                    [objectAddressSettlementOrUpperFiasId] :
                    [],
            };

            setFilter(defaultFilter);
            onSearch(defaultFilter);
        }
    }, [taskCard]);

    return {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
    };
};

export default useCrowdTaskCardInviteCandidatesListFilter;