import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {isClientUser} from "../../../../constants/roles";

import {getCompaniesKedoPageCount} from "../../../../ducks/bff/adm/settings/directories/kedo/services";
import {getClientsKedoPageCount} from "../../../../ducks/bff/clients/kedo/directories/services";

export const useKedoDirectoryFetchCount = (params = {}) => {
    const {
        clientId,
    } = params;

    const dispatch = useDispatch();
    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const fetchCounts = () => {
        if (isClientRole) {
            dispatch(getClientsKedoPageCount({
                clientId,
            }));

            return;
        }

        dispatch(getCompaniesKedoPageCount({
            clientId,
        }));
    };

    return {
        fetchCounts,
    };
};