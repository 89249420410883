import {
    add,
    differenceInYears,
    endOfWeek,
    format,
    formatDuration,
    intervalToDuration,
    parseISO,
    startOfWeek,
} from "date-fns";
import {ru} from "date-fns/locale";

import {getArrayRange} from "./listHelper";
import {isNullOrWhitespace} from "./stringHelper";

export function getDateWithoutTime(date) {
    date.setHours(0, 0, 0, 0);

    return date;
}

export function calculateAge(birthday) {
    let age = 0;

    if (!birthday) {
        return age;
    }

    const dateNow = new Date();
    const differentMonth = dateNow.getMonth() - new Date(birthday).getMonth();
    const differentDay = dateNow.getDate() - new Date(birthday).getDate();

    age = dateNow.getFullYear() - new Date(birthday).getFullYear();

    if ((differentMonth < 0) || (differentMonth === 0 && differentDay < 0)) {
        return age - 1;
    }

    return age;
}

export function calculateAgeToDate(birthday, toDate) {
    let age = 0;

    if (!birthday) {
        return age;
    }

    const date = new Date(toDate);
    const differentMonth = date.getMonth() - new Date(birthday).getMonth();
    const differentDay = date.getDate() - new Date(birthday).getDate();

    age = date.getFullYear() - new Date(birthday).getFullYear();

    if ((differentMonth < 0) || (differentMonth === 0 && differentDay < 0)) {
        return age - 1;
    }

    return age;
}

export const durationInMinutes = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return "Не определено";
    }

    return formatDuration(intervalToDuration({start: new Date(startDate), end: new Date(endDate)}), {
        format: ["minutes", "seconds"],
        locale: ru,
    });
};

export function getDay(milliseconds) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const result = Math.round(milliseconds / millisecondsPerDay);

    if (result >= 1) {
        return result;
    }

    return 0;
}

export function getBeginningWeekAndEndWeekByCurrentDate(formatString) {
    const currentDate = new Date();

    const monday = startOfWeek(currentDate, {weekStartsOn: 1});
    const sunday = endOfWeek(currentDate, {weekStartsOn: 1});

    if (formatString) {
        return {
            monday: format(monday, formatString),
            sunday: format(sunday, formatString),
        };
    }

    return {
        monday,
        sunday,
    };
}

export default function dateFormat(date, formatDate = "dd.MM.yyyy HH:mm") {
    if ([undefined, null, ""].includes(date)) {
        return "";
    }

    let _date = date;

    if (typeof _date !== "string") {
        _date = _date.toISOString();
    }

    const formattedDate = format(parseISO(_date), formatDate);

    if (formattedDate !== "Invalid Date") {
        return formattedDate;
    }

    return date;
}

export function formatDateWithoutTime(date, formatDate = "dd.MM.yyyy") {
    if (date === undefined || date === null) {
        return "";
    }

    let _date = date;

    if (typeof _date !== "string") {
        _date = _date.toISOString();
    }

    const formattedDate = format(parseISO(_date), formatDate);

    if (!["Invalid Date", "Invalid time value"].includes(formattedDate)) {
        return formattedDate;
    }

    return date;
}

export function formatLocalDate(date, formatDate = "dd.MM.yyyy") {
    return dateFormat(convertUtcToLocal(date), formatDate);
}

export function convertUtcToLocal(dateString) {
    if (isNullOrWhitespace(dateString)) {
        return;
    }

    const date = new Date(dateString);

    const timezoneOffset = date.getTimezoneOffset();

    let timezoneOffsetResult = 0;

    if (timezoneOffset > 0) {
        timezoneOffsetResult = Number(`-${timezoneOffset}`);
    } else if (timezoneOffset < 0) {
        timezoneOffsetResult = Math.abs(timezoneOffset);
    }

    return new Date(date.valueOf() + timezoneOffsetResult * 60 * 1000);
}

export function getStartFilterDateWithTimeInUtc(date) {
    return getUtcDateFilter(getStartDate(date));
}

export function getEndFilterDateWithTimeInUtc(date) {
    return getUtcDateFilter(getEndDate(date));
}

//toDo: хелпер на момент написания комментария используются для формирования дат для отправки в фильтрах,
// необходимо произвести рефакторинг и заменить на хелперы getStartFilterDateWithTimeInUtc, getEndFilterDateWithTimeInUtc
export function getUtcDateFilter(dateString) {
    if (isNullOrWhitespace(dateString)) {
        return;
    }

    return new Date(dateString).toISOString();
}

/***
 * из формата локального времени HH:mm возвращает время в utc
 * @param timeString
 * @returns {string}
 */
export const convertLocalTimeToUtcTime = (timeString) => {
    const hoursAndMinutes = timeString.split(":");
    const hours = +hoursAndMinutes[0];
    const minutes = +hoursAndMinutes[1];

    // важно только время
    const _date = new Date(2010, 0, 1, hours, minutes);

    // дата в utc
    const isoDate = _date.toISOString();

    // формат utc - 2010-01-01T11:00:00.000Z
    // забираем время
    return isoDate.substr(11, 5);
};


/***
 * из формата utc времени HH:mm возвращает локальное
 * @param timeString
 * @returns {string}
 */
export const convertUTCTimeToLocalTime = (timeString) => {
    const hoursAndMinutes = timeString.split(":");
    const hours = +hoursAndMinutes[0];
    const minutes = +hoursAndMinutes[1];

    // важно только время
    const _date = new Date(2010, 0, 1, hours, minutes);

    const localDate = convertUtcToLocal(_date);

    return dateFormat(localDate, "HH:mm");
};

export const getEndDate = (date) => {
    if (isNullOrWhitespace(date)) {
        return;
    }

    return new Date(new Date(date).setHours(23, 59, 59, 999));
};

export const getStartDate = (date) => {
    if (isNullOrWhitespace(date)) {
        return;
    }

    return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const addDays = (date, numberDays) => {
    if (!date) {
        return null;
    }

    return new Date(new Date(date).setDate(date.getDate() + numberDays));
};

export const addYears = (date, numberYears) => {
    if (!date) {
        return null;
    }

    date = new Date(date);

    return new Date(date.setFullYear(date.getFullYear() + numberYears));
};

export const addYearsOrDays = (date, numberYears, numberDays) => {
    if (isNullOrWhitespace(date)) {
        return;
    }

    const addedYears = addYears(date, numberYears);

    return addDays(addedYears, numberDays);
};

export const convertMinutesToHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.abs(totalMinutes % 60);
    return `${hours} ч ${minutes} м`;
};

export const today = {
    current: {
        now: () => new Date(),
    },
    iso: {
        start: () => new Date(new Date().setHours(0, 0, 0, 0)),
        now: () => new Date(),
        end: () => new Date(new Date().setHours(23, 59, 59, 999)),
    },
    local: {
        start: () => new Date(new Date(new Date().setHours(0, 0, 0, 0)).toString().split("GMT")[0] + " UTC").toISOString(),
        now: () => new Date(new Date().toString().split("GMT")[0] + " UTC").toISOString(),
        end: () => new Date(new Date(new Date().setHours(23, 59, 59, 999)).toString().split("GMT")[0] + " UTC").toISOString(),
    },
};

export const compareDatesWithoutTime = (date1, date2) => {
    if (!date1) {
        date1 = new Date();
    }
    return getDateWithoutTime(new Date(date1)).getTime() === getDateWithoutTime(new Date(date2)).getTime();
};

export const compareDatesWithTime = (dateToCompare, checkDifference = 0) => {
    const currentTime = today.current.now().getTime();
    const timeDifferenceInMs = currentTime - convertUtcToLocal(new Date(dateToCompare)).getTime();
    const timeDifferenceInHours = timeDifferenceInMs / 3_600_000;

    return timeDifferenceInHours < checkDifference;
};

export const monthOption = {
    "01": "Январь",
    "02": "Февраль",
    "03": "Март",
    "04": "Апрель",
    "05": "Май",
    "06": "Июнь",
    "07": "Июль",
    "08": "Август",
    "09": "Сентябрь",
    "10": "Октябрь",
    "11": "Ноябрь",
    "12": "Декабрь",
};

export const dateMonthOption = {
    0: "января",
    1: "февраля",
    2: "марта",
    3: "апреля",
    4: "мая",
    5: "июня",
    6: "июля",
    7: "августа",
    8: "сентября",
    9: "октября",
    10: "ноября",
    11: "декабря",
};

export const getMonthOptions = () => {
    return Object.keys(monthOption).map(key => {
        return {
            key,
            value: key,
            text: monthOption[key],
        };
    });
};

const weekOption = [
    "ВС",
    "ПН",
    "ВТ",
    "СР",
    "ЧТ",
    "ПТ",
    "СБ",
];

//формат даты yyyy-MM-dd
export const formatDateForChart = (date) => {
    const splitDate = date.split("-");
    const [, monthNum, day] = splitDate;
    const month = monthOption[monthNum];

    const weekNum = new Date(date).getDay();

    const week = weekOption[weekNum];

    return `${month}, ${day}, ${week}`;
};

/***
 * Определяет дату с минимальным временем для чекинов в табеле учета времени
 * @param inLocalDatetime
 * @param outLocalDatetime
 * @returns {*}
 */
export const getChecksDatepickerMinTime = (inLocalDatetime, outLocalDatetime) => {
    if (isNullOrWhitespace(inLocalDatetime)) {
        return today.iso.start();
    }

    if (typeof inLocalDatetime === "string") {
        inLocalDatetime = new Date(inLocalDatetime);
    }

    const dateNow = dateFormat(new Date(), "dd.MM.yyyy");
    const inDate = dateFormat(inLocalDatetime, "dd.MM.yyyy");

    if (isNullOrWhitespace(outLocalDatetime) && inDate >= dateNow) {
        return inLocalDatetime;
    }

    const outDate = dateFormat(outLocalDatetime, "dd.MM.yyyy");

    return inDate === outDate ? inLocalDatetime : today.iso.start();
};

export const getDateObject = (date) => {
    return isNullOrWhitespace(date) ? null : new Date(date);
};

export const getYearFromDateString = (dateString) => {
    return getDateObject(dateString)?.getFullYear();
};

export const convertSecToMinutes = (sec = 0) => {
    if (isNullOrWhitespace(sec)) {
        return "";

    }

    return [
        Math.floor(sec / 60).toString().padStart(2, "0"),
        (sec % 60).toString().padStart(2, "0"),
    ].join(":");
};

export function formatExportDate(date, format = "dd.MM.yyyy") {
    return dateFormat(convertUtcToLocal(date), format);
}

export function getPeriodString(startDate, endDate, local = false, format = "dd.MM.yyyy") {
    if (local) {
        return `${formatExportDate(startDate)} - ${formatExportDate(endDate)}`;
    }
    return `${dateFormat(startDate, format)} - ${dateFormat(endDate, format)}`;
}

/***
 * Возвращает имя месяца по дате в именительном патеже
 * @param date - объект или строка
 * @returns {string}
 */
export const getMonthName = date => {
    let _date = date;

    if (typeof _date !== "string") {
        _date = _date.toISOString();
    }

    return format(parseISO(_date), "LLLL", {locale: ru});
};

// Определить позднее ли дата другой даты на указанной количество годов
export const isDateLaterThenNumberOfYears = (data) => {
    const {
        checkedDate,
        date,
        numberOfYears,
    } = data;

    const tempCheckedDate = add(date, {
        days: 1,
    });

    const diffInYears = differenceInYears(checkedDate, tempCheckedDate);

    return diffInYears >= numberOfYears;
};

export const getTimeZoneOptions = () => {
    return getArrayRange(-12, 12, 1)
        .map(item => {
            const zone = item > 0 ? `+${item}` : item;

            return `UTC${zone ? zone : ""}`;
        })
        .map(item => {
            return {
                key: item,
                value: item,
                text: item,
            };
        });
};

export const addHourUnitTextToValue = (value, emptyText = "") => {
    if (!value) {
        return emptyText;
    }

    return `${value} ч`;
};

export const getHoursOptions = (params = {}) => {
    const {
        returnString,
    } = params;

    return getArrayRange(0, 23, 1)
        .map(item => {
            const value = returnString ? String(item) : item;

            return {
                key: value,
                value,
                text: addHourUnitTextToValue(value),
            };
        });
};

export const getMinutesOptions = (params = {}) => {
    const {
        returnString,
    } = params;

    return getArrayRange(0, 45, 15)
        .map(item => {
            const value = returnString ? String(item) : item;

            return {
                key: value,
                value,
                text: `${item} мин`,
            };
        });
};

export const getMinutes = (hours, minutes) => {
    return hours * 60 + minutes;
};

export const getChatMessageDate = (date) => {
    if (!date) {
        return "";
    }

    const _date = getStartDate(date);
    const dateTime = _date.getTime();
    const todayDateTime = getStartDate(new Date()).getTime();
    const yesterdayDateTime = getStartDate(addDays(new Date(), -1)).getTime();
    const yearAgoDateTime = getStartDate(addYears(new Date(), -1)).getTime();

    if (dateTime === todayDateTime) {
        return "Сегодня";
    }

    if (dateTime === yesterdayDateTime) {
        return "Вчера";
    }

    if (dateTime > yearAgoDateTime) {
        return `${_date.getDate()} ${dateMonthOption[_date.getMonth()]}`;
    }

    return formatLocalDate(date, "dd.MM.yyyy");
};

export const getDatePeriodLabelText = (dateFrom, dateTo) => {
    const _dateFrom = formatLocalDate(dateFrom);
    const _dateTo = formatLocalDate(dateTo);

    if (dateFrom && !dateTo) {
        return `от ${_dateFrom}`;
    }

    if (!dateFrom && dateTo) {
        return `до ${_dateTo}`;
    }

    return `${_dateFrom} - ${_dateTo}`;
};

export const dateWithoutTimezone = (date) => {
    if (!date) {
        return "";
    }
    return new Date(date).toISOString().slice(0, -1);
};

/**
 * хелпер для получения часов из времени в формате "HH:mm"
 * @param time "02:30"
 * @returns {number|string} "2"
 */
export const getHoursFromTime = (time) => {
    if (!time) {
        return "";
    }

    const [hours] = time.split(":");

    const rounded = Math.round(hours);

    return String(rounded);
};

/**
 * хелпер для получения минут из времени в формате "HH:mm"
 * @param time "02:30"
 * @returns {number|string|string} "30"
 */
export const getMinutesFromTime = (time) => {
    if (!time) {
        return "";
    }

    const [, minutes] = time.split(":");

    const rounded = Math.round(minutes);

    return String(rounded);
};

/**
 * Хелпер для склеивания времени в формат "HH:mm" из значений формы (выбора из дропдаунов) для отправки на BE
 * @param formData
 * @returns {string}
 */
export const getTimeFromHoursAndMinutes = (formData) => {
    const {
        timeMinutes,
        timeHours,
    } = formData;

    if (!timeMinutes && !timeHours) {
        return;
    }

    if (timeHours && !timeMinutes) {
        return `${formatTime(timeHours)}:00`;
    }

    if (timeMinutes && !timeHours) {
        return `00:${formatTime(timeMinutes)}`;
    }

    return `${formatTime(timeHours)}:${formatTime(timeMinutes)}`;
};

const formatTime = (timeHours) => {
    return timeHours.length === 1 ? `0${timeHours}` : timeHours;
};