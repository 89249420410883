import {getPaymentStatusChecksFailed} from "../../../finance/finance-payment-list/utils/getStatusChecksFailed";

export const getRegistryPaymentStatus = (paymentStatuses, status) => {
    const statusChecksFailed = getPaymentStatusChecksFailed(status);

    if (statusChecksFailed) {
        return statusChecksFailed;
    }

    return paymentStatuses[status];
};