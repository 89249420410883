import {useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {getInitialTouched} from "../../../../../../utils/objectHelper";
import {handleFormString} from "../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../constants/validationRules";

import {
    createCrowdActRegistry,
    editCrowdActRegistry,
} from "../../../../../../ducks/bff/crowd/actRegistry/actionCreators";

export const useCrowdTaskActRegistryEditModalForm = (props) => {
    const {
        clientId,
        isEdit,
        editData,
        onClose,
        fetchList,
    } = props;

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        return {
            name: editData.name || "",
            comment: editData.comment || "",
            needContractorApprove: isEdit ? Boolean(editData.needContractorApprove) : true,
        };
    }, [editData]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const reqData = {
            clientId,
            name: handleFormString(values.name),
            comment: handleFormString(values.comment),
            needContractorApprove: values.needContractorApprove,
            onSuccess: () => {
                fetchList();
                onClose();
            },
        };

        if (isEdit) {
            dispatch(editCrowdActRegistry({
                registryId: editData.registryId,
                ...reqData,
            }));

            return;
        }

        dispatch(createCrowdActRegistry(reqData));
    };

    const handleChange = (e, {name, value, checked}) => {
        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const {
        values,
        touched,
        errors,
        setFieldValue,
        isValid,
        handleSubmit,
    } = useFormik({
        onSubmit,
        initialValues,
        initialTouched: getInitialTouched(initialValues),
        validationSchema: yup.object().shape({
            name: yup.string()
                .required(requiredMessage)
                .max(100, "Максимальная длина - 100 символов"),
            comment: yup.string()
                .max(255, "Максимальная длина - 255 символов"),
        }),
        enableReinitialize: true,
    });

    return {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    };
};