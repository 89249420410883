export const MAP_INIT_ZOOM = 10;

export const MAP_MOSCOW_CENTER_COORDS = {
    LATITUDE: 55.7449,
    LONGITUDE: 37.6199,
};

export const MAP_INIT_FILTER_DATA = {
    distanceFilter: {
        coordinateLat: MAP_MOSCOW_CENTER_COORDS.LATITUDE,
        coordinateLon: MAP_MOSCOW_CENTER_COORDS.LONGITUDE,
        distance: 10, // 10км
        zoom: 1,
    },
    clientIdsFilter: [],
    projectIdsFilter: [],
    objectIdsFilter: [],
    locationAddressFiasIdsFilter: undefined,
    types: [],
    ageFromFilter: "",
    ageToFilter: "",
};

export const MAP_FILTER_RADIUS_CIRCLE_OPTIONS = {
    color: "#3388ff",
    fillColor: "#3388ff",
    fillOpacity: 0.2,
};

export const MARKER_TYPE = {
    ORDER: "ORDER",
    CONTRACTOR: "CONTRACTOR",
    ADVERT: "ADVERT",
    CROWD_TASK: "CROWD_TASK",
};

export const MAP_CONTRACTOR_SORT_TYPE = {
    LAST_LOGIN_DATE_ASC: "LAST_LOGIN_DATE_ASC",
    LAST_LOGIN_DATE_DESC: "LAST_LOGIN_DATE_DESC",
    DISTANCE_ASC: "DISTANCE_ASC",
    DISTANCE_DESC: "DISTANCE_DESC",
};

export const MAP_ORDER_SORT_TYPE = {
    WORK_START_DATE_ASC: "WORK_START_DATE_ASC",
    WORK_START_DATE_DESC: "WORK_START_DATE_DESC",
    PUBLICATION_DATE_ASC: "PUBLICATION_DATE_ASC",
    PUBLICATION_DATE_DESC: "PUBLICATION_DATE_DESC",
};

export const MAP_TASK_SORT_TYPE = {
    TASK_SEARCH_DEFAULT_ORDER: "TASK_SEARCH_DEFAULT_ORDER",
    PUBLICATION_DATE_TIME_ASC: "PUBLICATION_DATE_TIME_ASC",
    PUBLICATION_DATE_TIME_DESC: "PUBLICATION_DATE_TIME_DESC",
    WORK_START_DATE_ASC: "WORK_START_DATE_ASC",
    WORK_START_DATE_DESC: "WORK_START_DATE_DESC",
};

export const MAP_ADVERT_SORT_TYPE = {
    PUBLICATION_DATE_DESC: "PUBLICATION_DATE_DESC",
    PUBLICATION_DATE_ASC: "PUBLICATION_DATE_ASC",
    CREATE_DATE_DESC: "CREATE_DATE_DESC",
    CREATE_DATE_ASC: "CREATE_DATE_ASC",
    PUBLICATION_DATE_FROM_DESC: "PUBLICATION_DATE_FROM_DESC",
    PUBLICATION_DATE_FROM_ASC : "PUBLICATION_DATE_FROM_ASC ",
};