import {history} from "../../../../../../../../store/configureStore";

import {useModal} from "../../../../../../../../hooks/useModal";

import {LINK_CONTRACTOR_PERSONAL_INFO} from "../../../../../../../../constants/links";

export const useContractorAdditionalVerification = (params) => {
    const {
        contractorId,
    } = params;
    const {
        modalData: errorModalData,
        onOpenModal: onOpenErrorModal,
        onCloseModal: onCloseErrorModal,
    } = useModal();

    const onClickCancelErrorModal = () => {
        const link = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId);

        history.push(link);
    };

    return {
        errorModalData,
        onOpenErrorModal,
        onCloseErrorModal,
        onClickCancelErrorModal,
    };
};