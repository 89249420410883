import {round} from "lodash";

import {getNumberFromFormattedAmount} from "./stringFormat";
import {isNullOrWhitespace} from "./stringHelper";

export const getTotalPages = (totalCount, pageSize = 25) => {
    const totalPage = Math.ceil(totalCount / pageSize);

    return !isNaN(totalPage) ? totalPage : 0;
};

export const getPageNum = (listLength, pageSize) => {
    return pageSize ? Math.floor(listLength / pageSize) : 1;
};

export const isInteger = (num) => (num ^ 0) === num;

export const handleCardRate = (value) => {
    if (isNullOrWhitespace(value)) {
        return "";
    }

    const tempRate = parseFloat(value) * 100;
    return isInteger(tempRate) ? tempRate : tempRate.toFixed(2);
};

export const calculateRate = value => {
    if (typeof value !== "string") {
        value = String(value);
    }

    const resultRate = parseFloat(value.trim());

    return isInteger(resultRate) ? (resultRate / 100).toFixed(2) : (resultRate / 100).toFixed(4);
};

export const isValidFraction = value => {
    value = value.replace(/\s+/g, "");
    const regex = /^-?\d+\.{0,1}\d{0,2}$/;

    return regex.test(value);
};

export const isValidCoordinate = value => {
    const regex = /^\d+\.{0,1}\d{0,6}$/;

    return regex.test(value);
};

export const calculateSalaryWorker = (amount, count) => Math.round(parseFloat(String(amount).replace(/\s/g, "")) / parseFloat(count));

export const floatToPercent = num => {
    const tempRate = Number(num * 100);

    return isInteger(tempRate) ? tempRate : tempRate.toFixed(2);
};

/***
 * Переводит число в проценты
 * @param num
 * @returns {number}
 */
export const getPercent = num => num * 100;

/***
 * Переводит число в проценты и заменяет точку в числе на запятую
 * @param num
 * @returns {string}
 */
export const getPercentInLocalFormat = (num) => String(getPercent(num)).replace(".", ",");

/***
 * Переводит число в проценты и заменяет точку в числе на запятую
 * @param num
 * @returns {string}
 */
export const getRoundedPercentInLocalFormat = (num) => removeFinalZeros(String(getPercent(num).toFixed(2))).replace(".", ",");

/***
 * Округляет до 2-х символовм после разделителя с точностью до сотых, если 0.006 > 0.01
 * @param number : number
 * @param digits : number
 * @returns {string}
 */
export const roundNumberWithPoint = (number = 0, digits = 2) => typeof number === "number" ? round(number, digits).toFixed(digits) : "";

/***
 * Удаление нулей после запятой/точки в конце числа
 * @returns {string}
 */
export const removeFinalZeros = (value) => String(value).replace(/(\.[0-9]*[1-9])0+$|\.0*$/,"$1");

export const getPercentFromNumber = (number) => getNumberFromFormattedAmount(number) / 100;

export const getTimeFromMins = (value) => {
    const hours = Math.trunc(value/60);
    const minutes = value % 60;

    return hours + "ч " + minutes + "мин";
};

export const getCount = (count) => {
    if (!count || typeof count !== "number") {
        return null;
    }

    return count > 99 ? "99+" : count;
};

Number.prototype.toFixedNoRounding = function(n) {
    const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
    const a = this.toString().match(reg)[0];
    const dot = a.indexOf(".");
    if (dot === -1) { // integer, insert decimal dot and pad up zeros
        return a + "." + "0".repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? (a + "0".repeat(b)) : a;
};