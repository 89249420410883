export const getFilesWithPreview = (files: Array<File>, accept?: string | Array<string>) => {
    if (!accept) {
        return files;
    }

    return files.map((file) => {
        if ((file.name && accept.indexOf("image") !== -1) || (file.name && file.type.includes("image/"))) {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
        }

        return file;
    });
};