import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import {KedoLoginIndicator} from "../../../components/ActualComponents/KedoLoginIndicator";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import {UkepIndicator} from "../../../components/ActualComponents/UkepIndicator";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as ArchiveIcon} from "../../../images/archive.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import {KedoStaffRegistryEdit} from "./components/edit";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useKedoStaffRegistryAction} from "./hooks/useAction";
import {useKedoStaffRegistryDicts} from "./hooks/useDicts";
import {useKedoStaffRegistryFetch} from "./hooks/useFetch";
import {useKedoStaffRegistryFilters} from "./hooks/useFilters";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {getUserRole} from "../../../utils/access";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFullName} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_KEDO_PROFILE} from "../../../constants/links";
import {ADMIN, CLIENT_ADMIN, isClientUser, KEDO_HR, NM_MANAGER} from "../../../constants/roles";

import {bffCommonDictsKedoRoleDictSelector} from "../../../ducks/bff/common/dicts/selectors";
import {
    bffKedoStaffListSelector,
    bffKedoStaffProgressActionSelector,
    bffKedoStaffProgressSelector,
    bffKedoStaffTotalCountSelector,
    bffKedoStaffTotalPageSelector,
} from "../../../ducks/kedo/staff/selectors";

export const KedoStaffRegistry = (props) => {
    const clientId = props.match.params.clientId;

    const [archiveFilter, setArchiveFilter] = useState(false);

    const roleDict = useSelector(bffCommonDictsKedoRoleDictSelector);
    const {t} = useTranslation();

    useKedoStaffRegistryDicts();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useKedoStaffRegistryFilters({
        pageSize,
        setPagination,
        clientId,
    });

    const {
        fetchList,
    } = useKedoStaffRegistryFetch({
        pageNum,
        pageSize,
        filterData,
        archiveFilter,
        clientId,
    });

    const role = ls(USER_ROLE);

    const list = useSelector(bffKedoStaffListSelector);
    const totalCount = useSelector(bffKedoStaffTotalCountSelector);
    const totalPages = useSelector(bffKedoStaffTotalPageSelector);
    const isLoading = useSelector(bffKedoStaffProgressSelector);
    const progressAction = useSelector(bffKedoStaffProgressActionSelector);

    const {
        isOpen,
        onOpenModal,
        modalData,
        onCloseModal,
    } = useModal();

    const {
        updateArchiveStatus,
        onCloseConfirm,
        onOpenConfirm,
        confirmData,
        isOpenConfirm,
    } = useKedoStaffRegistryAction({
        fetchList,
        archiveFilter,
    });

    const getConfirmWindow = () => {
        if (!isOpenConfirm) {
            return null;
        }

        const fullName = getFullName(
            confirmData.lastName,
            confirmData.firstName,
            confirmData.patronymic,
        );

        return (
            <NmConfirmV2
                mobile="column"
                title={
                    archiveFilter
                        ? `Вы действительно хотите восстановить из архива сотрудника ${fullName}?`
                        : `Вы действительно хотите добавить в архив сотрудника ${fullName}?`
                }
                content={
                    archiveFilter
                        ? "В случае подтверждения данный сотрудник получит доступ к КЭДО."
                        : "В случае подтверждения данный сотрудник потеряет доступ к КЭДО."
                }
                onCancel={onCloseConfirm}
                disabled={progressAction}
                onConfirm={updateArchiveStatus}
                isNeedClosing={false}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getMediaControls = (item) => {
        const editButtonAsset = {children: "Редактировать"};
        const archiveButtonAsset = {children: !archiveFilter ? "В архив" : "Восстановить из архива"};

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        icon: <ModeIcon />,
                        color: "grey",
                        onlyIcon: true,
                        onClick: () => {
                            onOpenModal(item);
                        },
                    },
                    asset: {
                        mobile: editButtonAsset,
                        tablet: editButtonAsset,
                    },
                    visible: !archiveFilter && [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        icon: <ArchiveIcon />,
                        children: "",
                        onlyIcon: true,
                        size: "lg",
                        onClick: () => {
                            onOpenConfirm(item);
                        },
                    },
                    asset:  {
                        mobile: archiveButtonAsset,
                        tablet: archiveButtonAsset,
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role),
                },
            ],
        };
    };

    const getPrimaryHeader = (item) => {
        const fullName = getFullName(
            item.lastName,
            item.firstName,
            item.patronymic,
        );

        const content = (
            <>
                {fullName}
                {
                    !item.loggedIn &&
                    <KedoLoginIndicator className="ms-2" />
                }
            </>
        );

        const role = getUserRole();
        const title = "Открыть карточку сотрудника для просмотра";

        if (isClientUser(role)) {
            return (
                <div
                    style={{cursor: "pointer"}}
                    title={title}
                    onClick={() => {
                        onOpenModal({
                            ...item,
                            isViewMode: true,
                        });
                    }}
                    className="d-flex align-items-center"
                >
                    {content}
                </div>
            );
        }

        return (
            <ExtLink
                historyEnabled
                title={title}
                to={
                    LINK_CLIENT_KEDO_PROFILE
                        .replace(":clientId", item.clientId)
                        .replace(":kedoStaffId", item.kedoStaffId)
                }
                className="d-flex align-items-center"
            >
                {content}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.kedoStaffId,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        primaryHeaderLink
                        primaryHeader={getPrimaryHeader(item)}
                        labels={[
                            {
                                label: "Роль",
                                text: (
                                    <div className="d-flex align-items-center">
                                        {roleDict[item.role] || "-"}
                                        {
                                            item.role === KEDO_HR &&
                                            item.ukepAvailable &&
                                            <UkepIndicator className="ms-1" />
                                        }
                                    </div>
                                ),
                                noWrap: false,
                            },
                            {
                                label: "Должность",
                                text: item.position || "-",
                                noWrap: false,
                            },
                            {
                                label: "Структурное подразделение",
                                text: item.subdivision || "-",
                                noWrap: false,
                            },
                        ]}
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                        primaryHeaderWordBreak
                    />
                ),
            };
        });
    };

    const mediaControls = useMemo(() => {
        const archive = getArchiveButton(t, archiveFilter, {mobile: true});

        return {
            renderCount: {
                mobile: 1,
                tablet: 1,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Добавить сотрудника",
                        icon: <AddIcon />,
                        onClick: () => {
                            onOpenModal({});
                        },
                    },
                    visible: !archiveFilter && [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: () => {
                            setArchiveFilter(!archiveFilter);
                        },
                    },
                },
            ],
        };
    }, [
        archiveFilter,
    ]);

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Сотрудники
                </NmTitle>
            }
            mediaControls={mediaControls}
            openFilter
            typeFilter="vertical"
            widthByFilter
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={isLoading}
        >
            {getConfirmWindow()}
            {
                isOpen &&
                <KedoStaffRegistryEdit
                    fetchList={fetchList}
                    onClose={onCloseModal}
                    clientId={clientId}
                    kedoStaffId={modalData.kedoStaffId}
                    isViewMode={modalData.isViewMode}
                />
            }
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};