import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEqual} from "lodash";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {CRM_SETTINGS_SYSTEM_TICKETS_CHANGE_KEY} from "../constants";

import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";
import {
    getSystemSetting,
    getSystemSettingTicket,
    setAllSystemSetting,
    setSystemTickedEnabledSetting,
    systemSettingTicketSelector,
} from "../../../../../ducks/systemSetting";

export const useCrmSettingsAction = ({}) => {
    const dispatch = useDispatch();
    const [enableSystemTickets, setEnableSystemTickets] = useState(false);

    const ticketSettings = useSelector(systemSettingTicketSelector);

    const initialValues = useMemo(() => {
        return {
            ...ticketSettings,
        };
    }, [ticketSettings]);

    useEffect(() => {
        dispatch(getSystemSetting({
            key: CRM_SETTINGS_SYSTEM_TICKETS_CHANGE_KEY,
            onSuccess: (result) => {
                setEnableSystemTickets(result);
            },
        }));

        dispatch(getClientUserRestrictionsList({
            restrictions: [CLIENT_USER_RESTRICTIONS_VARIABLE.editParamSystemTickets],
        }));

        fetchSettings();
    }, []);

    const onChangeSystemTickets = (event, {checked}) => {
        dispatch(setSystemTickedEnabledSetting({
            value: checked,
            onSuccess: () => {
                setEnableSystemTickets(checked);
            },
        }));
    };

    const fetchSettings = () => {
        dispatch(getSystemSettingTicket());
    };

    const onSubmit = () => {
        dispatch(setAllSystemSetting({
            ...values,
            onSuccess: () => {
                toastSuccess("Изменения сохранены");
                fetchSettings();
            },
        }));
    };

    const {
        values,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
    });

    return {
        enableSystemTickets,
        onChangeSystemTickets,
        values,
        setFieldValue,
        onSubmit,
        isChanges: !isEqual(ticketSettings, values),
    };
};