import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {formatFileOptions} from "../../../constants/financeExport";

import PropTypes from "prop-types";

import "./style.sass";

class StopListExportModal extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
    };

    state = {
        formatType: formatFileOptions[0].value,
    };

    submit = () => {
        const {
            onCancel,
            onSubmit,
        } = this.props;
        const {formatType} = this.state;

        onSubmit(formatType);

        onCancel();
    };

    handleOnChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    render() {
        const {onCancel} = this.props;
        const {formatType} = this.state;

        return (
            <NmModal
                size="md"
                className="stop-list-export"
                onClose={onCancel}
                header={
                    <NmTitle size="lg">
                        Выгрузка стоп-листа компании в файл
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent="Выгрузить"
                        cancelBtnContent="Отменить"
                        onClose={onCancel}
                        submit={this.submit}
                    />
                }
            >
                <NmForm>
                    <NmDropdownV2
                        label="Выберите формат выгружаемого файла"
                        onChange={this.handleOnChange}
                        name="formatType"
                        selection
                        value={formatType}
                        options={formatFileOptions}
                    />
                </NmForm>
            </NmModal>
        );
    }
}

export default withTranslation()(StopListExportModal);