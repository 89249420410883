export const getCandidateInformationEditAction = (params) => {
    const {
        onClickEdit,
        onClickDelete,
        isVisibleDelete = true,
    } = params;

    return [
        {
            key: "edit",
            value: "edit",
            text: "Редактировать",
            onClick: onClickEdit,
            visible: Boolean(onClickEdit),
        },
        {
            key: "delete",
            value: "delete",
            text: "Удалить",
            onClick: onClickDelete,
            visible: Boolean(onClickDelete) && isVisibleDelete,
        },
    ];
};