import {useState} from "react";

export const useOrderActRegistryCardEditPayment = ({setIsAddContractorModalOpen}) => {
    const [isOpenEditPayment, setOpenEdit] = useState(false);
    const [editPaymentData, setEditData] = useState({});

    const onOpenEditPayment = (item) => {
        setEditData(item);

        if (!item.contractorId) {
            setIsAddContractorModalOpen(true);

            return;
        }

        setOpenEdit(true);
    };

    const onCloseEditPayment = () => {
        setOpenEdit(false);
        setEditData({});
    };

    return {
        isOpenEditPayment,
        editPaymentData,
        onOpenEditPayment,
        onCloseEditPayment,
    };
};