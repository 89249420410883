import {FILTER} from "../../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../../hooks/useFilter";

import {removePhoneMask} from "../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";

export const useRecruitmentAccessControlRecruitersGroupsCardFilter = (params) => {
    const {
        setPagination,
        pageSize,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            name: "",
            position: "",
            email: "",
            phone: "",
        },
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "ФИО рекрутера",
                    placeholder: "Введите ФИО",
                    name: "name",
                    maxLength: 150,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "Должность",
                    placeholder: "Введите должность",
                    name: "position",
                    maxLength: 100,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    placeholder: "+7",
                    label: "Телефон",
                    name: "phone",
                    mask: "+7 (999) 999 9999",
                    maskChar: null,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "E-mail",
                    placeholder: "Введите e-mail",
                    name: "email",
                    maxLength: 255,
                },
            ],
        },
    ];

    function mapFilterDto(filter) {
        return {
            name: handleFormString(filter.name),
            phone: filter.phone ? removePhoneMask(filter.phone) : undefined,
            email: handleFormString(filter.email),
            position: handleFormString(filter.position),
        };
    }

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    };
};