import {
    checkArrivalNoticeCard,
    checkMigrationCard,
    checkPassportDataBy,
    checkPassportDataForeigner,
    checkPassportDataRu,
    checkPatentCard, checkVhiPolicy,
} from "../contractor_info/controlDateReadOnly";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {CONTRACTOR_FIELD, EDM_PASSPORT_TYPE} from "../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../constants/contractorInfo";


export const checkPassportDate = (data) => {
    const citizenship = data[CONTRACTOR_FIELD.CITIZENSHIP];
    let error = {};

    if (citizenship === citizenshipsDict.RU.value) {
        const errorMessage = checkPassportDataRu({
            values: data,
            card: data,
        });
        error = {
            [CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO]: errorMessage,
            [CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION]: errorMessage,
        };
    }

    if (citizenship === citizenshipsDict.BY.value) {
        const errorMessage = checkPassportDataBy({
            values: data,
            card: data,
        });
        error = {
            [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: errorMessage,
            [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE]: errorMessage,
        };
    }

    if (![citizenshipsDict.RU.value, citizenshipsDict.BY.value].includes(citizenship)) {
        const isInternalPassport = data[CONTRACTOR_FIELD.PASSPORT_TYPE] === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT || data[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE] === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE;
        const errorMessage = checkPassportDataForeigner({
            values: data,
            card: data,
        });

        error = isInternalPassport ?
            {
                [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE]: errorMessage,
                [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE]: errorMessage,
            } :
            {
                [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: errorMessage,
            };
    }

    return error;
};

export const checkMigrationCardDate = (data) => {
    const citizenship = data[CONTRACTOR_FIELD.CITIZENSHIP];
    let error = {};

    if (!(citizenship === citizenshipsDict.RU.value)) {
        const errorMessage = checkMigrationCard({
            values: data,
            card: data,
        });

        error = {
            [CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD]: errorMessage,
            [CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD]: errorMessage,
        };
    }

    return error;
};

export const checkArrivalNoticeCardDate = (data) => {
    const citizenship = data[CONTRACTOR_FIELD.CITIZENSHIP];
    const isResidencePermit = data[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT;

    let error = {};

    if (!(citizenship === citizenshipsDict.RU.value)) {
        const errorMessage = checkArrivalNoticeCard({
            values: data,
            card: data,
        });

        error = isResidencePermit ?
            {
                [CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT]: errorMessage,
            } :
            {
                [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST]: errorMessage,
                [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND]: errorMessage,
            };
    }

    return error;
};

export const checkPatentCardDate = (data) => {
    const citizenship = data[CONTRACTOR_FIELD.CITIZENSHIP];
    let error = {};

    if ([citizenshipsDict.TJ.value, citizenshipsDict.UZ.value, citizenshipsDict.UA.value].includes(citizenship)) {
        const errorMessage = checkPatentCard({
            values: data,
            card: data,
        });

        error = {
            [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: errorMessage,
            [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: errorMessage,
            [CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN]: errorMessage,
        };
    }

    return error;
};

export const checkVhiPolicyDate = (data) => {
    const citizenship = data[CONTRACTOR_FIELD.CITIZENSHIP];
    let error = {};

    if (citizenshipsDict.RU.value !== citizenship) {
        const errorMessage = checkVhiPolicy({
            values: data,
            card: data,
        });

        error = {
            [CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN]: errorMessage,
        };

        return error;
    }
};