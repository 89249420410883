import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import TemplatesClientUsage from "../../../../../../components/TemplatesClientUsage";

import {
    getTemplateDocumentClientUsagesLogTypes,
    getTemplateDocumentClientUsagesOrderTypes,
    updateCommonPaymentDicts,
} from "../../../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsClientUsagesLogTypesSelector,
    bffCommonDictsClientUsagesOrderTypesOptionsSelector,
} from "../../../../../../ducks/bff/common/dicts/selectors";
import {
    updateKedoDirectoriesStore,
} from "../../../../../../ducks/kedo/directories/actionCreators";
import {
    kedoDirectoriesProgressActionSelector,
    kedoDirectoriesTemplateClientUsageSelector,
} from "../../../../../../ducks/kedo/directories/selectors";

import {
    getAdmKedoTemplateDocumentTypeClientUsagePage,
    updateAdmKedoTemplateDocumentTypeClientUsage,
} from "../../../../../../ducks/bff/adm/settings/directories/kedo/template-document-types/services";

export const KedoTemplatesClientUsage = (props) => {
    const {
        fetchTemplates,
        onClose,
        documentTypeId,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);
    const {
        totalCount,
        list,
        totalPages,
    } = useSelector(kedoDirectoriesTemplateClientUsageSelector);
    const actionTypeDict = useSelector(bffCommonDictsClientUsagesLogTypesSelector);
    const sortOptions = useSelector(bffCommonDictsClientUsagesOrderTypesOptionsSelector);

    useEffect(() => {
        dispatch(getTemplateDocumentClientUsagesLogTypes());
        dispatch(getTemplateDocumentClientUsagesOrderTypes());

        return () => {
            dispatch(updateCommonPaymentDicts({
                clientUsagesLogTypes: {},
                clientUsagesOrderTypes: {},
            }));
            dispatch(updateKedoDirectoriesStore({
                clientUsage: {},
            }));
        };
    }, []);

    const updateClientUsage = (data) => {
        dispatch(updateAdmKedoTemplateDocumentTypeClientUsage(data));
    };

    const fetchList = (data) => {
        dispatch(getAdmKedoTemplateDocumentTypeClientUsagePage({
            ...data,
            documentTypeIdsFilter: [documentTypeId],
        }));
    };

    return (
        <TemplatesClientUsage
            fetchTemplates={fetchTemplates}
            onClose={onClose}
            progressAction={progressAction}
            totalCount={totalCount}
            list={list}
            totalPages={totalPages}
            actionTypeDict={actionTypeDict}
            updateClientUsage={updateClientUsage}
            fetchList={fetchList}
            sortOptions={sortOptions}
        />
    );
};