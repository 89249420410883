import {createSelector} from "reselect";

import {getTotalPages} from "../../utils/mathHelper";

export const internalPaymentsSelector = state => state.internalPayments;
export const internalPaymentsTotalPagesSelector = createSelector(
    internalPaymentsSelector,
    ({pageData: {pageSize}, totalCount}) => getTotalPages(totalCount, pageSize),
);
export const internalPaymentsTotalCountSelector = createSelector(
    internalPaymentsSelector,
    ({totalCount}) => totalCount,
);
export const internalPaymentsListSelector = createSelector(
    internalPaymentsSelector,
    ({list}) => list,
);
export const internalPaymentsTkbStatusesBatchSelector = createSelector(
    internalPaymentsSelector,
    ({statuses}) => {
        return Object.fromEntries(
            statuses.map(
                item => {
                    return [item.orderId, `${item.status || "-"}, ${item.description || "-"}`];
                },
            ),
        );
    },
);
export const internalPaymentsProgressSelector = createSelector(
    internalPaymentsSelector,
    ({progress}) => progress,
);
export const internalPaymentsProgressActionSelector = createSelector(
    internalPaymentsSelector,
    ({progressAction}) => progressAction,
);
export const internalPaymentsAllowedEmailsSelector = createSelector(
    internalPaymentsSelector,
    ({allowedEmails}) => allowedEmails,
);