export const BFF_CROWD_PAYMENT_GET_PAGE_REQUEST = "BFF_CROWD_PAYMENT_GET_PAGE_REQUEST";
export const BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS = "BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS";
export const BFF_CROWD_PAYMENT_GET_PAGE_ERROR = "BFF_CROWD_PAYMENT_GET_PAGE_ERROR";

export const BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST = "BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST";
export const BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS = "BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS";
export const BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR = "BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR";

export const BFF_CROWD_PAYMENT_REPEAT_REQUEST = "BFF_CROWD_PAYMENT_REPEAT_REQUEST";
export const BFF_CROWD_PAYMENT_REPEAT_SUCCESS = "BFF_CROWD_PAYMENT_REPEAT_SUCCESS";
export const BFF_CROWD_PAYMENT_REPEAT_ERROR = "BFF_CROWD_PAYMENT_REPEAT_ERROR";

export const BFF_CROWD_PAYMENT_REJECT_REQUEST = "BFF_CROWD_PAYMENT_REJECT_REQUEST";
export const BFF_CROWD_PAYMENT_REJECT_SUCCESS = "BFF_CROWD_PAYMENT_REJECT_SUCCESS";
export const BFF_CROWD_PAYMENT_REJECT_ERROR = "BFF_CROWD_PAYMENT_REJECT_ERROR";

export const BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST = "BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST";
export const BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS = "BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS";
export const BFF_CROWD_PAYMENT_MASS_REJECT_ERROR = "BFF_CROWD_PAYMENT_MASS_REJECT_ERROR";

export const BFF_CROWD_PAYMENT_APPROVE_REQUEST = "BFF_CROWD_PAYMENT_APPROVE_REQUEST";
export const BFF_CROWD_PAYMENT_APPROVE_SUCCESS = "BFF_CROWD_PAYMENT_APPROVE_SUCCESS";
export const BFF_CROWD_PAYMENT_APPROVE_ERROR = "BFF_CROWD_PAYMENT_APPROVE_ERROR";

export const BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST = "BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST";
export const BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS = "BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS";
export const BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR = "BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR";

export const BFF_CROWD_PAYMENT_ADD_REQUEST = "BFF_CROWD_PAYMENT_ADD_REQUEST";
export const BFF_CROWD_PAYMENT_ADD_SUCCESS = "BFF_CROWD_PAYMENT_ADD_SUCCESS";
export const BFF_CROWD_PAYMENT_ADD_ERROR = "BFF_CROWD_PAYMENT_ADD_ERROR";

export const BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST = "BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST";
export const BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS = "BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS";
export const BFF_CROWD_PAYMENT_GET_BY_ID_ERROR = "BFF_CROWD_PAYMENT_GET_BY_ID_ERROR";

export const BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST = "BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST";
export const BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS = "BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS";
export const BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR = "BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR";

export const BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST = "BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST";
export const BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS = "BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS";
export const BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR = "BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR";

export const UPDATE_CROWD_PAYMENT_STORE = "UPDATE_CROWD_PAYMENT_STORE";