import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {useCounter} from "../../../../../../../../hooks/useCounter";
import {useContractorAdditionalDocuments} from "../../../../../hooks/useAdditionalDocuments";

import {toastError} from "../../../../../../../../utils/toastHelper";
import {getContractorScanListFiles} from "../../../../../../contractor_scan_list/utils/getFiles";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../../constants/clientList";
import {FILE_ERROR} from "../../../../../../../../constants/messages";
import {maxSizeScanFile} from "../../../../../../../../constants/validationRules";

import {
    addFileContractorAdditionalScanV2,
    fileContractorScanObjSelector,
    getContractorAdditionalDocumentFile,
} from "../../../../../../../../ducks/fileStore";

export const useDataMedicalPersonalInfoCardEditForm = ({contractorId, onClose}) => {
    const contractorScanObj = useSelector(fileContractorScanObjSelector);

    const getPrevScan = (scanType) => {
        return getContractorScanListFiles(contractorScanObj)[scanType] || [];
    };

    const initialFiles = {
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN),
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN),
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN),
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN),
    };

    const initialValues = {
        files: initialFiles,
    };

    const [editedFiles, setEditedFiles] = useState([]);

    const dispatch = useDispatch();


    const {
        getAdditionalDocumentAggregation,
    } = useContractorAdditionalDocuments({contractorId});

    const {
        isCounterFinish,
        increaseCounter,
    } = useCounter({countList: editedFiles});

    useEffect(() => {
        if (isCounterFinish) {
            getAdditionalDocumentAggregation();
            onClose();
        }
    }, [isCounterFinish]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        if (!editedFiles.length) {
            onClose();

            return;
        }


        editedFiles.forEach(scanType => {
            const [file] = values.files[scanType];

            if (file) {
                uploadFile(file, scanType);
            }
        });
    };

    const onDropScan = (_files, scanType) => {
        setValues({
            ...values,
            files: {
                ...values.files,
                [scanType]: _files,
            },
        });
        setEditedFiles([
            ...new Set([
                ...editedFiles,
                scanType,
            ]),
        ]);
    };

    const uploadFile = (file, contractorFileType) => {
        if (!file) {
            return;
        }

        if (file.size > maxSizeScanFile) {
            toastError(FILE_ERROR.SCAN_SIZE);

            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        dispatch(addFileContractorAdditionalScanV2({
            contractorId,
            contractorFileType,
            file: formData,
            onSuccess: () => {
                increaseCounter();
                dispatch(getContractorAdditionalDocumentFile({
                    contractorId,
                    type: contractorFileType,
                    thumbnail: true,
                }));
            },
        }));
    };

    const {
        handleSubmit,
        values,
        setValues,
        errors,
        isValid,
        touched,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        files: values.files,
        errors,
        onDropScan,
        handleSubmit,
        touched,
    };
};