import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {openLinkByUrl} from "../../../../../utils/downloadBlob";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";
import {toastWarning} from "../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";

import {downloadDocument} from "../../../../../ducks/documents";

export const useContractorIncomePaymentCardAction = ({role, isShowActions = true}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onDownloadFile = ({documentType, downloadLink, isAccessToken, isDownload}) => {
        if (isNullOrWhitespace(downloadLink)) {
            toastWarning(t("finance-payment-list.check-not-formed"));

            return;
        }

        dispatch(downloadDocument({
            isDownload,
            documentType,
            downloadLink,
            isAccessToken,
        }));
    };

    const onDownloadOnlineCheck = ({paymentNumber}) => {

        dispatch(downloadDocument({
            documentType: `${t("finance-payment-list.online-check-commission")}_${paymentNumber}`,
            downloadLink: `/api/documents/getAtolIncomeCheckFile?paymentNumber=${paymentNumber}`,
        }));
    };

    const getMediaControls = (payment) => {
        if (!isShowActions) {
            return null;
        }

        const {
            taxUrl,
            linkFNS,
            atolUrl,
            naimixRewardReceiptUrl,
            cashReceiptUrl,
            platformAmount,
        } = payment;

        const _linkFNS = taxUrl || linkFNS;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownloadFile({
                                isDownload: false,
                                downloadLink: atolUrl,
                            });
                        },
                        children: "Атол чек",
                    },
                    visible: Boolean(atolUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownloadFile({
                                isDownload: false,
                                downloadLink: cashReceiptUrl,
                            });
                        },
                        children: "Квитанция о перечислении денежных средств",
                    },
                    visible: Boolean(cashReceiptUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownloadFile({
                                isDownload: false,
                                downloadLink: naimixRewardReceiptUrl,
                            });
                        },
                        children: "Квитанция о вознаграждении Наймикс",
                    },
                    visible: Boolean(naimixRewardReceiptUrl) && platformAmount !== 0,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            openLinkByUrl(_linkFNS);
                        },
                        children: "Чек ФНС России",
                    },
                    visible: Boolean(_linkFNS),
                },
            ],
        };
    };

    return {
        getMediaControls,
    };
};