import * as yup from "yup";

import {removePhoneMask} from "../../../../../../../utils/stringFormat";
import {validatePhone} from "../../../../../../../utils/validate";

import {emailReg, requiredMessage} from "../../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        phone: yup.string()
            .required(requiredMessage)
            .when("locatedOutsideRussia", {
                is: false,
                then: yup.string()
                    .transform((value) => {
                        return removePhoneMask(value).trim();
                    })
                    .length(11, "Номер телефона должен состоять из 11 цифр"),
            })
            .when("locatedOutsideRussia", {
                is: true,
                then: yup.string()
                    .transform(removePhoneMask)
                    .test("checkPhone", "Номер телефона должен состоять из 10-15 цифр", validatePhone),
            }),
        email: yup.string()
            .max(255, "Не более 255 символов")
            .matches(emailReg, {message: VALIDATIONS_MESSAGE.INCORRECT_EMAIL, excludeEmptyString: false}),
        phoneCodeId: yup.string()
            .nullable()
            .when("locatedOutsideRussia", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};

export default validationSchema;