import React from "react";
import {useFormik} from "formik";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

export const BooleanAttributeEdit = (props) => {
    const {
        title,
        labelTrue,
        labelFalse,
        progress,
        onClose,
        onSubmit,
        initialValue = false,
    } = props;

    const {
        values,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues: {
            value: initialValue,
        },
        onSubmit,
    });

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    disabled={values.value === null || progress}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm>
                <NmRadioV2
                    label={labelTrue}
                    checked={values.value === true}
                    className="mb-3 mb-md-4"
                    value={true}
                    onChange={() => {
                        setFieldValue("value", true);
                    }}
                />
                <NmRadioV2
                    label={labelFalse}
                    checked={values.value === false}
                    value={false}
                    onChange={() => {
                        setFieldValue("value", false);
                    }}
                />
            </NmForm>
        </NmModal>
    );
};
