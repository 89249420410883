import React, {Component} from "react";

import HelpTooltip from "../ActualComponents/HelpTooltip";

import PropTypes from "prop-types";

import "./style.sass";

class LabelTextApp extends Component {
    static propTypes = {
        text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.element,
        ]),
        emptyText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
        label: PropTypes.string,
        labelTooltip: PropTypes.string,
        textTooltip: PropTypes.string,
        className: PropTypes.string,
        classNameLabel: PropTypes.string,
        classNameText: PropTypes.string,
    };

    static defaultProps = {
        text: "",
        emptyText: "Не указано",
        label: "",
        className: "",
        classNameLabel: "",
        classNameText: "",
    };

    constructor() {
        super();

        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const {
            label,
            text,
            emptyText,
            className,
            classNameLabel,
            classNameText,
            labelTooltip,
            tooltipPosition,
            textTooltip,
        } = this.props;

        return (
            <div className={`label-text ${className}`}>
                <div className={`label-text__name ${classNameLabel}`}>
                    {label}
                    {
                        labelTooltip &&
                        <HelpTooltip
                            hover
                            className="label-text__name-tooltip"
                            text={labelTooltip}
                            position={tooltipPosition}
                        />
                    }
                </div>
                <div className="flex align-items-center">
                    <div className={`label-text__content ${classNameText}`}>
                        {text || emptyText}
                    </div>
                    {
                        textTooltip &&
                        <div className="label-text__name-tooltip">
                            {textTooltip}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default LabelTextApp;
