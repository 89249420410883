import React from "react";
import {useDispatch, useSelector} from "react-redux";

import CountRefreshButton from "../../../../../components/ActualComponents/CountRefreshButton";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import Text from "../../../../../components/ActualComponents/Text";

import {getSmartLinkCommonFilter} from "../../utils/getCommonFilter";

import {COLOR} from "../../../../../constants/color";
import {SMART_LINK_FORM_TYPE, SMART_LINK_WARNING_COUNT} from "../../constants";

import {getSmartLinkCountByFilter} from "../../../../../ducks/smartLink/actionCreators";
import {smartLinkCountSelector, smartLinkProgressCountSelector} from "../../../../../ducks/smartLink/selectors";

export const SmartLinkCount = (props) => {
    const {
        values,
    } = props;

    const dispatch = useDispatch();

    const count = useSelector(smartLinkCountSelector);
    const loading = useSelector(smartLinkProgressCountSelector);

    const getCountByFilter = () => {
        const data = getSmartLinkCommonFilter(values);

        dispatch(getSmartLinkCountByFilter({
            ...data,
            type: values.type,
            filterType: values.filterType,
        }));
    };

    const onClickRefresh = () => {
        getCountByFilter();
    };

    const targetText = values.type === SMART_LINK_FORM_TYPE.ORDER ? "заказов" : "заданий";

    return (
        <div className="d-flex flex-column align-items-start">
            <CountRefreshButton
                count={count}
                loading={loading}
                label={`Найдено ${targetText}`}
                onClick={onClickRefresh}
            />
            {
                Boolean(count) && count > SMART_LINK_WARNING_COUNT &&
                <div className="d-flex align-items-center mt-1">
                    <Text
                        color={COLOR.INERT_100}
                        level="2"
                        className="me-1"
                    >
                        {`Большое количество ${targetText}`}
                    </Text>
                    <HelpTooltip
                        info
                        hover
                        text={`Не советуем отправлять исполнителю ссылку, с таким большим количеством ${targetText}. Это может усложнить его выбор`}
                        position="bottom-left"
                    />
                </div>
            }
        </div>
    );
};