import {DEPOSIT_SUB_PAGE} from "../../../deposit/client-list";

import {
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
} from "../../../../constants/links";

export const getPatentsDepositLinks = (clientId) => {
    const REPLENISHMENT_AND_REFUNDS = LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS)
        .replace(":subTab", "");
    const COMPLETED_PAYMENTS = LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS)
        .replace(":subTab", DEPOSIT_SUB_PAGE.PATENTS);
    const PAYMENTS_IN_PROCESS = LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)
        .replace(":subTab", DEPOSIT_SUB_PAGE.PATENTS);
    const PAYMENTS_PENDING = LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING);

    return {
        REPLENISHMENT_AND_REFUNDS,
        COMPLETED_PAYMENTS,
        PAYMENTS_IN_PROCESS,
        PAYMENTS_PENDING,
    };
};