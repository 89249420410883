import {useState} from "react";
import {useDispatch} from "react-redux";

import {ADVERTISEMENT_MASS_OPTION} from "../constants";

import {
    advertisementClose,
    advertisementPublishMultiple,
    advertisementPublishMultipleCheck,
} from "../../../../ducks/advertisement";

export function useAdvertisementMassAction({fetchList, clearSelectedRows}) {
    const [isShowMassConfirm, setShowMassConfirm] = useState(false);
    const [massActionType, setMassActionType] = useState("");
    const [checkedList, setChecckedList] = useState([]);

    const dispatch = useDispatch();

    const setInitialStates = () => {
        setChecckedList([]);
        setMassActionType("");
        setShowMassConfirm(false);
    };

    const checkPublishPossible = (requestData) => {
        dispatch(advertisementPublishMultipleCheck({
            requestData,
            getResult: (result) => {
                setMassActionType(ADVERTISEMENT_MASS_OPTION.PUBLISH);
                setChecckedList(result);
                setShowMassConfirm(true);
            },
        }));
    };

    const publishAdvertisement = () => {
        const requestData = checkedList
            .filter(({success}) => success)
            .map((item) => {
                const {
                    clientId,
                    advertId,
                    publicationDateFrom,
                } = item;

                return {
                    clientId,
                    publicationDateFrom,
                    advertId,
                };
            });

        if (!requestData.length) {
            setChecckedList([]);
            setShowMassConfirm(false);

            return;
        }

        dispatch(advertisementPublishMultiple({
            requestData,
            onSuccess: () => {
                setInitialStates();
                clearSelectedRows();
                fetchList();
            },
            isShowToastSuccess: requestData.length > 1,
        }));
    };

    const deleteAdvertisement = ({requestData, toastSuccess}) => {
        dispatch(advertisementClose({
            requestData,
            toastSuccess,
            onSuccess: () => {
                setInitialStates();
                clearSelectedRows();
                fetchList();
            },
        }));
    };

    return {
        isShowMassConfirm,
        setShowMassConfirm,
        massActionType,
        setMassActionType,
        checkedList,
        checkPublishPossible,
        publishAdvertisement,
        deleteAdvertisement,
    };
}