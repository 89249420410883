import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {getCommonClientWorkType} from "../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsClientWorkTypeDictOptionsSelector} from "../../ducks/bff/common/dicts/selectors";

export const ClientWorkTypeDropdown = (props) => {
    const {
        value,
        onChange,
        error,
    } = props;

    const dispatch = useDispatch();

    const clientWorkTypeOptions = useSelector(bffCommonDictsClientWorkTypeDictOptionsSelector);

    useEffect(() => {
        if (isEmpty(clientWorkTypeOptions)) {
            dispatch(getCommonClientWorkType());
        }
    }, []);

    return (
        <NmDropdownV2
            required
            label="Режим работы компании"
            placeholder="Выберите режим"
            name="clientWorkType"
            options={clientWorkTypeOptions}
            value={value}
            onChange={onChange}
            error={error}
        />
    );
};