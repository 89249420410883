import {getKedoTemplateRestrictionsType} from "./getRestrictionType";

import {KEDO_TEMPLATE_DATA_TYPES} from "../constants";

export const getKedoTemplateRequestData = (values, id) => {
    const {
        templateName,
        files,
    } = values;

    const formData = new FormData();
    const file = files[0];

    if (file.lastModified) {
        formData.append("templateFile", file);
    }

    const userReferenceParamModels = values.userReferenceParamModels.map(model => {
        return {
            field: model.field,
            type: model.type === KEDO_TEMPLATE_DATA_TYPES.MULTIPLE_STRING_SELECTION
                ? KEDO_TEMPLATE_DATA_TYPES.STRING_SELECTION
                : model.type,
            name: model.name,
            restrictions: getRestrictions(model),
        };
    });

    const templateModel = {
        name: templateName,
        userReferenceParamModels,
    };

    if (id) {
        templateModel.id = id;
    }

    formData.append("templateModel", new Blob([JSON.stringify(templateModel)], {
        type: "application/json",
    }));

    return formData;
};

const getRestrictions = (model) => {
    const restriction = getKedoTemplateRestrictionsType(model);

    if (!restriction) {
        return [];
    }

    const restrictionKeys = Object.keys(restriction)
        .filter(field => restriction[field]);

    const isRestriction = restrictionKeys.length > 1;

    if (isRestriction) {
        const _restriction =  restrictionKeys.reduce((result, field) => {
            return {
                ...result,
                [field]: restriction[field],
            };
        }, {});

        return [_restriction];
    }

    return [];
};