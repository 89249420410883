export type TFileWithPreview = File & TFileDescription;

export interface IDropzoneFileError {
    message: string;
    code: EnumDropzoneErrorCode | string;
}

export type TLocalErrorFile = {id: string, name: string, errors: Array<IDropzoneFileError>};

export type TOnDownload = {
    fileName: string,
    filePath: string,
}

export type TDropzoneConfirmData = {name: string, isError: boolean};

export type TFileDescription = {
    name?: string,
    base64Str?: string,
    type?: string,
    preview?: string,
    isRemote?: boolean,
    isProgress?: boolean,
}

export type TDropzoneOnRemoveFile = {
    name: string,
    isError: boolean,
    isRemote?: boolean,
}

export enum EnumDropzoneErrorCode {
    fileInvalidType = "file-invalid-type",
    fileTooLarge = "file-too-large",
    fileTooSmall = "file-too-small",
    tooManyFiles = "too-many-files",
    winForbiddenSymbols = "win-forbidden-symbols",
    equalNames = "equal-names",
}

export type TDropzoneFileRejection = {
    file: File;
    errors: IDropzoneFileError[];
}