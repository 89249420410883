import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {InstructionsPage} from "../page";

import {USER_INSTRUCTION_TYPE_DICT} from "../../../../constants/dicts";

import {addUserInstructionFile, getUserInstructionFile} from "../../../../ducks/fileStore";
import {
    getInstructionInfoGroupSelector,
    getInstructionsList,
    saveInstruction,
} from "../../../../ducks/userInstructions";

export const InstructionsNaimix = () => {
    const dispatch = useDispatch();

    const instructionInfoGroup = useSelector(getInstructionInfoGroupSelector);

    const fetchList = () => {
        dispatch(getInstructionsList());
    };

    const getFile = (item) => {
        const {
            filename: fileName,
            instructionType,
        } = item;

        dispatch(getUserInstructionFile({
            instructionType,
            fileName,
        }));
    };

    const addFile = (data) => {
        const {
            instructionType,
            file,
            additionalParams,
        } = data;

        dispatch(addUserInstructionFile({
            instructionType,
            file,
            additionalParams,
        }));
    };

    const save = (info) => {
        dispatch(saveInstruction(info));
    };

    return (
        <InstructionsPage
            fetchList={fetchList}
            instructionInfoGroup={instructionInfoGroup}
            getFile={getFile}
            addFile={addFile}
            save={save}
            instructionTypeDict={USER_INSTRUCTION_TYPE_DICT}
        />
    );
};