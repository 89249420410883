import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdTasksAnalyticsSelector = state => state.bff.crowd.tasksAnalytics;
export const bffCrowdTasksAnalyticsProgressSelector = createSelector(bffCrowdTasksAnalyticsSelector, ({progress}) => progress);
export const bffCrowdTasksAnalyticsCountersProgressSelector = createSelector(bffCrowdTasksAnalyticsSelector, ({countersProgress}) => countersProgress);
export const bffCrowdTasksAnalyticsListSelector = createSelector(bffCrowdTasksAnalyticsSelector, ({list}) => list);
export const bffCrowdTasksAnalyticsTotalCountSelector = createSelector(bffCrowdTasksAnalyticsSelector, ({totalCount}) => totalCount);
export const bffCrowdTasksAnalyticsTotalPagesSelector = createSelector(bffCrowdTasksAnalyticsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdTasksAnalyticsCountAggregationSelector = createSelector(bffCrowdTasksAnalyticsSelector, ({countAggregation}) => countAggregation);
