import {DEPOSIT_SUB_PAGE} from "../containers/deposit/client-list";
import {CLIENT_WORK_TYPE} from "./clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "./clientUserRestrictions";
import {CONTRACTORS_TABS_TYPES} from "./contractor";
import {CHAT_LINK_PARAMS} from "./disputes";
import {EDO_ACCESS_RIGHTS} from "./document-management/document-list";
import {FINANCE_PIVOT_LINK_PARAMS} from "./financePivot";
import {
    ATTENTION_CONTRACTORS,
    AVAILABLE_CONTRACTORS,
    BRIGADE_ORDER_OBJECT_OBJECT,
    MY_CONTRACTORS,
    SUB_PAGE_CROWD_TASK_ANALYTICS,
    SUBPAGE_CLIENTS_REVIEWS,
    SUBPAGE_CONTRACTORS_REVIEWS,
} from "./link-params";
import {
    LINK_BRIGADE,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_CLIENT_CARD,
    LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES,
    LINK_CLIENT_KEDO_DIRECTORIES_POSITIONS,
    LINK_CLIENT_KEDO_DIRECTORIES_SUBDIVISIONS,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_LIST,
    LINK_CLIENT_MAILINGS_LIST,
    LINK_CLIENT_MEMBERS,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_CANDIDATES,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS,
    LINK_CLIENT_RECRUITMENT_VACANCIES,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST_ARCHIVED,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST_NO_ARCHIVED,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_STOP_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_CONTRACTORS_RESOURCES,
    LINK_CRM_TICKET,
    LINK_CRM_TICKET_LIST_IN_WORK,
    LINK_DEPOSIT_LIST,
    LINK_DISPUTES,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_FEEDBACKS,
    LINK_FINANCE_ACTIVE,
    LINK_FINANCE_BANK,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_CROWD_TASKS,
    LINK_FINANCE_EXPORT,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_FINANCE_EXPORT_FULL,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_PIVOT,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_SYSTEM_TRANSACTIONS,
    LINK_FINANCE_UPLOAD,
    LINK_FULL_INFO_MAP,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_INSURANCE_HISTORY,
    LINK_MAILINGS_LIST,
    LINK_MARKETING_BUILDER,
    LINK_OBJECTS,
    LINK_PATENT_PIVOT_TABLE,
    LINK_PROJECTS,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_REVIEWS_LIST,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_INFO,
    LINK_STOP_LIST,
    LINK_TEMPLATES,
    LINK_TEMPLATES_CONTRACTS_LIST,
    LINK_TEMPLATES_KEDO,
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
    LinkNavigator,
    TASK_LIST_LINK,
} from "./links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_OPERATOR,
    RECRUITER,
} from "./roles";

import {ls, USER_ROLE} from "../utils/localstorage";

import {TEMPLATES_TAB} from "../containers/templates/constants";

export const MOCK_PEREKRESTOR_ID = "a63e0a34-7db5-4590-9745-40e71ea4613b";

const CONTRACTOR_CHILD_MENU = {
    LIST: {
        content: "Реестр исполнителей",
        to: LINK_CONTRACTOR_LIST,
        activePath: "/contractor",
        activeExceptions: [
            LINK_CONTRACTORS_RESOURCES,
            LINK_CLIENT_REMOTE_CONTRACTORS,
            LINK_CLIENT_ON_CHECK_CONTRACTORS,
            LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
            LINK_CONTRACTOR_ONBOARDING_LEADS,
        ],
    },
    CLIENT_CONTRACTORS: {
        content: "Исполнители заказчиков",
        to: LINK_CLIENT_REMOTE_CONTRACTORS,
        activePath: [
            LINK_CLIENT_REMOTE_CONTRACTORS,
            LINK_CONTRACTOR_ONBOARDING_LEADS,
        ],
    },
    RESOURCES: {
        content: "Ресурсы",
        to: LINK_CONTRACTORS_RESOURCES,
        activePath: LINK_CONTRACTORS_RESOURCES,
    },
    ON_CHECK: {
        content: "Исполнители на проверке",
        to: LINK_CLIENT_ON_CHECK_CONTRACTORS,
        activePath: [LINK_CLIENT_ON_CHECK_CONTRACTORS, LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS],
    },
};

const FINANCE_CHILD_MENU = {
    LINK_FINANCE_PAYMENT_LIST: {
        content: "Оплаты (НПД)",
        to: LINK_FINANCE_PAYMENT_LIST,
        activePath: LINK_FINANCE_PAYMENT_LIST,
    },
    CONTRACT_PAYMENTS: {
        content: "Оплаты (НДФЛ)",
        to: LINK_FINANCE_NDFL_PAYMENT_LIST,
        activePath: LINK_FINANCE_NDFL_PAYMENT_LIST,
    },
    INDIVIDUAL_PAYMENTS: {
        content: "Оплаты (ИП)",
        to: LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
        activePath: LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    },
    LINK_FINANCE_PATENT_PAYMENT_LIST: {
        content: "Оплаты патентов",
        to: LINK_FINANCE_PATENT_PAYMENT_LIST.replace("/:paymentNumberFilter?", ""),
        activePath: LINK_FINANCE_PATENT_PAYMENT_LIST.replace("/:paymentNumberFilter?", ""),
    },
    LINK_FINANCE_CROWD_PAYMENT_LIST: {
        content: "Оплаты по заданиям",
        to: LINK_FINANCE_CROWD_PAYMENT_LIST.replace("/:paymentNumberFilter?", ""),
    },
    LINK_FINANCE_ORDERS: {
        content: "Заказы",
        to: LINK_FINANCE_ORDERS,
    },
    LINK_FINANCE_CROWD_TASKS: {
        content: "Задания",
        to: LINK_FINANCE_CROWD_TASKS,
    },
    LINK_FINANCE_PIVOT_TABLE: {
        content: "Сводная (НПД)",
        to: LINK_FINANCE_PIVOT_TABLE
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "25")
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_CLIENTID_PARAM, "")
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_FROM_DATE, "")
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_TO_DATE, ""),
        activePath: LINK_FINANCE_PIVOT,
    },
    LINK_CIVIL_PIVOT_TABLE: {
        content: "Сводная (НДФЛ)",
        to: LINK_CIVIL_PIVOT_TABLE,
    },
    LINK_PATENT_PIVOT_TABLE: {
        content: "Сводная по патентам",
        to: LINK_PATENT_PIVOT_TABLE,
    },
    LINK_FINANCE_BANK: {
        restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessBank,
        content: "Банк",
        to: LINK_FINANCE_BANK,
    },
    LINK_FINANCE_OPERATOR_REPORTS: {
        content: "Отчёты оператора",
        to: LINK_FINANCE_OPERATOR_REPORTS,
    },
    LINK_FINANCE_RECONCILIATION_ACTS: {
        restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessReconciliationReports,
        content: "Акт сверки взаиморасчетов",
        to: LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    },
    LINK_FINANCE_SYSTEM_TRANSACTIONS: {
        restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessSystemTransactions,
        content: "Системные транзакции",
        to: LINK_FINANCE_SYSTEM_TRANSACTIONS.replace("/:transactionNumFilter?", ""),
    },
    LINK_FINANCE_CANCELED_CHECKS: {
        id: "canceledChecks",
        activePath: LINK_FINANCE_CANCELED_CHECKS,
        content: "Аннулированные чеки ФНС России",
        to: LINK_FINANCE_CANCELED_CHECKS,
    },
    LINK_FINANCE_REGISTRY_PAYMENTS: {
        activePath: [LINK_FINANCE_REGISTRY_PAYMENTS.replace(":archived", "false"), LINK_FINANCE_REGISTRY_PAYMENTS.replace(":archived", "true")],
        content: "Реестровые выплаты",
        to: LINK_FINANCE_REGISTRY_PAYMENTS.replace(":archived", false),
    },
};

const ADMIN_LINK = {
    LINK_CLIENT_LIST: {
        id: "company",
        to: LINK_CLIENT_LIST.replace(":archived", false),
        activePath: LINK_CLIENT_LIST,
        icon: "business_24",
        content: "Компании",
    },
    LINK_CONTRACTOR_LIST: {
        id: "contractors",
        activePath: ["/contractor", LINK_CONTRACTORS_RESOURCES],
        activeExceptions: ["client-card", "projects"],
        icon: "users_24",
        content: "Исполнители",
    },
    LINK_MAILINGS_LIST: {
        id: "mailing",
        to: LINK_MAILINGS_LIST,
        activePath: [
            LINK_MAILINGS_LIST,
            LINK_MARKETING_BUILDER,
        ],
        icon: "mail_24",
        content: "Маркетинг",
    },
    LINK_DEPOSIT_LIST: {
        id: "deposit-smz",
        to: LINK_DEPOSIT_LIST,
        activePath: LINK_DEPOSIT_LIST,
        icon: "wallet_24",
        content: "Депозит (НПД)",
    },
    LINK_CIVIL_DEPOSIT_LIST: {
        id: "deposit-gph",
        to: LinkNavigator.admin.civilDeposit,
        activePath: LinkNavigator.admin.civilDeposit,
        icon: "wallet_24",
        content: "Депозит (НДФЛ)",
    },
    LINK_FINANCE_PAYMENT_LIST: {
        id: "finance",
        activePath: [
            LINK_FINANCE_ACTIVE,
            LINK_CIVIL_PIVOT_TABLE,
            LINK_PATENT_PIVOT_TABLE,
        ],
        icon: "wallet_24",
        content: "Финансы",
        childMenu: [
            FINANCE_CHILD_MENU.LINK_FINANCE_PAYMENT_LIST,
            FINANCE_CHILD_MENU.CONTRACT_PAYMENTS,
            FINANCE_CHILD_MENU.INDIVIDUAL_PAYMENTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PATENT_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_ORDERS,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_TASKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_CIVIL_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_PATENT_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_FINANCE_BANK,
            FINANCE_CHILD_MENU.LINK_FINANCE_OPERATOR_REPORTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_RECONCILIATION_ACTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_SYSTEM_TRANSACTIONS,
            FINANCE_CHILD_MENU.LINK_FINANCE_CANCELED_CHECKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_REGISTRY_PAYMENTS,
        ],
    },
    LINK_FINANCE_EXPORT_FULL: {
        id: "export",
        content: "Экспорт",
        icon: "cloud_download_24",
        activePath: LINK_FINANCE_EXPORT,
        childMenu: [
            {
                content: "Экспорт отчетов",
                to: LINK_FINANCE_EXPORT_FULL
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_CLIENTID_PARAM, ""),
                activePath: LINK_FINANCE_EXPORT_FULL
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_CLIENTID_PARAM, ""),
            },
            {
                content: "Экспорт документов",
                to: LINK_FINANCE_EXPORT_DOCUMENTS,
                activePath: LINK_FINANCE_EXPORT_DOCUMENTS,
            },
        ],
    },
    LINK_FINANCE_UPLOAD: {
        id: "upload",
        to: LINK_FINANCE_UPLOAD,
        activePath: LINK_FINANCE_UPLOAD,
        icon: "cloud_upload_24",
        content: "1С",
    },
    LINK_PROMOCODE_CHANNEL_LIST: {
        id: "promocode",
        to: LINK_PROMOCODE_CHANNEL_LIST,
        activePath: [LINK_PROMOCODE_CHANNEL_LIST, "promocode-channel-card"],
        icon: "receipt_24",
        content: "Каналы привлечения",
    },
    LINK_FULL_INFO_MAP: {
        id: "infoMap",
        to: LINK_FULL_INFO_MAP,
        activePath: [LINK_FULL_INFO_MAP, "info-map"],
        icon: "map_24",
        content: "Карта",
    },
    LINK_REVIEWS_LIST: {
        id: "reviews",
        to: LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CLIENTS_REVIEWS),
        activePath: [LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CLIENTS_REVIEWS), LINK_REVIEWS_LIST.replace(":subpage", SUBPAGE_CONTRACTORS_REVIEWS)],
        icon: "rate_review_24",
        content: "Отзывы",
    },
    LINK_DISPUTES: {
        id: "disputes",
        to: LINK_DISPUTES
            .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, CHAT_LINK_PARAMS.OPEN)
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, 1)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, 25)
            .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, ""),
        activePath: "/disputes",
        icon: "hammer_24",
        content: "Споры",
    },
    LINK_SETTINGS_INFO: {
        id: "settings",
        to: LINK_SETTINGS_INFO,
        activePath: "/settings",
        icon: "settings_24",
        content: "Настройки",
    },
    LINK_TASK_LIST: {
        id: "tasks",
        to: TASK_LIST_LINK,
        activePath: "/tasks",
        icon: "tasks",
        content: "Список задач",
    },
    LINK_CRM_TICKET_LIST: {
        id: "ticket",
        to: LINK_CRM_TICKET_LIST_IN_WORK,
        activePath: LINK_CRM_TICKET,
        icon: "phone_callback",
        content: "CRM КЦ",
    },
    TEMPLATES: {
        id: "templates",
        to: LINK_TEMPLATES_CONTRACTS_LIST,
        activePath: LINK_TEMPLATES,
        icon: "description_24",
        content: "Шаблоны документов",
    },
    ALL_TEMPLATES: {
        id: "templates",
        activePath: [
            LINK_TEMPLATES,
            LINK_TEMPLATES_KEDO,
        ],
        icon: "description_24",
        content: "Шаблоны документов",
        childMenu: [
            {
                content: "Шаблоны Наймикс",
                to: LINK_TEMPLATES_CONTRACTS_LIST,
                activePath: LINK_TEMPLATES_CONTRACTS_LIST,
            },
            {
                content: "Шаблоны КЭДО",
                to: LINK_TEMPLATES_KEDO_LIST,
                activePath: [
                    LINK_TEMPLATES_KEDO_LIST,
                    LINK_TEMPLATES_KEDO_LOG,
                ],
            },
        ],
    },
};

// Админ наймикса
export const adminMenuList = [
    ADMIN_LINK.LINK_CLIENT_LIST,
    {
        ...ADMIN_LINK.LINK_CONTRACTOR_LIST,
        childMenu: [
            CONTRACTOR_CHILD_MENU.LIST,
            CONTRACTOR_CHILD_MENU.ON_CHECK,
            CONTRACTOR_CHILD_MENU.RESOURCES,
            CONTRACTOR_CHILD_MENU.CLIENT_CONTRACTORS,
        ],
    },
    ADMIN_LINK.LINK_DEPOSIT_LIST,
    ADMIN_LINK.LINK_CIVIL_DEPOSIT_LIST,
    ADMIN_LINK.LINK_FINANCE_PAYMENT_LIST,
    ADMIN_LINK.LINK_FINANCE_EXPORT_FULL,
    ADMIN_LINK.ALL_TEMPLATES,
    ADMIN_LINK.LINK_FINANCE_UPLOAD,
    ADMIN_LINK.LINK_PROMOCODE_CHANNEL_LIST,
    ADMIN_LINK.LINK_FULL_INFO_MAP,
    ADMIN_LINK.LINK_REVIEWS_LIST,
    ADMIN_LINK.LINK_DISPUTES,
    ADMIN_LINK.LINK_MAILINGS_LIST,
    ADMIN_LINK.LINK_CRM_TICKET_LIST,
    ADMIN_LINK.LINK_SETTINGS_INFO,
    ADMIN_LINK.LINK_TASK_LIST,
];

export const operatorMenuList = [
    ADMIN_LINK.LINK_CLIENT_LIST,
    {
        ...ADMIN_LINK.LINK_CONTRACTOR_LIST,
        childMenu: [
            CONTRACTOR_CHILD_MENU.LIST,
            CONTRACTOR_CHILD_MENU.ON_CHECK,
            CONTRACTOR_CHILD_MENU.CLIENT_CONTRACTORS,
        ],
    },
    {
        ...ADMIN_LINK.LINK_FINANCE_PAYMENT_LIST,
        childMenu: [
            FINANCE_CHILD_MENU.LINK_FINANCE_PAYMENT_LIST,
            FINANCE_CHILD_MENU.CONTRACT_PAYMENTS,
            FINANCE_CHILD_MENU.INDIVIDUAL_PAYMENTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PATENT_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_CANCELED_CHECKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_REGISTRY_PAYMENTS,
        ],
    },
    {
        ...ADMIN_LINK.LINK_FINANCE_EXPORT_FULL,
        childMenu: [
            {
                content: "Экспорт отчетов",
                to: LINK_FINANCE_EXPORT_FULL
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_CLIENTID_PARAM, ""),
                activePath: LINK_FINANCE_EXPORT_FULL
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_CLIENTID_PARAM, ""),
            },
        ],
    },
    ADMIN_LINK.LINK_DISPUTES,
    ADMIN_LINK.LINK_MAILINGS_LIST,
    ADMIN_LINK.LINK_CRM_TICKET_LIST,
    {
        id: "settings",
        to: LINK_SETTINGS_FNS_SERVICE,
        activePath: "/settings",
        icon: "settings_24",
        content: "Настройки",
    },
    ADMIN_LINK.LINK_TASK_LIST,
];

//NM_PARTNER Партнер наймикс
export const partnerMenuList = [
    {
        ...ADMIN_LINK.LINK_FINANCE_EXPORT_FULL,
        childMenu: [
            ADMIN_LINK.LINK_FINANCE_EXPORT_FULL.childMenu[0],
        ],
    },
];

// Координатор Наймикса
export const nmCoordinatorMenuList = [
    ADMIN_LINK.LINK_CLIENT_LIST,
    {
        ...ADMIN_LINK.LINK_CONTRACTOR_LIST,
        childMenu: [
            CONTRACTOR_CHILD_MENU.LIST,
            CONTRACTOR_CHILD_MENU.ON_CHECK,
            CONTRACTOR_CHILD_MENU.RESOURCES,
        ],
    },
    ADMIN_LINK.LINK_DEPOSIT_LIST,
    ADMIN_LINK.LINK_CIVIL_DEPOSIT_LIST,
    {
        ...ADMIN_LINK.LINK_FINANCE_PAYMENT_LIST,
        childMenu: [
            FINANCE_CHILD_MENU.LINK_FINANCE_PAYMENT_LIST,
            FINANCE_CHILD_MENU.CONTRACT_PAYMENTS,
            FINANCE_CHILD_MENU.INDIVIDUAL_PAYMENTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PATENT_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_ORDERS,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_TASKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_CIVIL_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_PATENT_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_FINANCE_OPERATOR_REPORTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_RECONCILIATION_ACTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_REGISTRY_PAYMENTS,
        ],
    },
    ADMIN_LINK.LINK_FINANCE_EXPORT_FULL,
    ADMIN_LINK.LINK_FINANCE_UPLOAD,
    ADMIN_LINK.LINK_PROMOCODE_CHANNEL_LIST,
    ADMIN_LINK.LINK_REVIEWS_LIST,
    ADMIN_LINK.LINK_DISPUTES,
    ADMIN_LINK.LINK_MAILINGS_LIST,
    ADMIN_LINK.LINK_CRM_TICKET_LIST,
    ADMIN_LINK.LINK_SETTINGS_INFO,
];

// Главный бухгалтер
export const nmChiefAccountantMenuList = [
    ADMIN_LINK.LINK_CLIENT_LIST,
    {
        ...ADMIN_LINK.LINK_CONTRACTOR_LIST,
        childMenu: [
            CONTRACTOR_CHILD_MENU.LIST,
            CONTRACTOR_CHILD_MENU.ON_CHECK,
            CONTRACTOR_CHILD_MENU.RESOURCES,
        ],
    },
    ADMIN_LINK.LINK_DEPOSIT_LIST,
    ADMIN_LINK.LINK_CIVIL_DEPOSIT_LIST,
    {
        ...ADMIN_LINK.LINK_FINANCE_PAYMENT_LIST,
        childMenu: [
            FINANCE_CHILD_MENU.LINK_FINANCE_PAYMENT_LIST,
            FINANCE_CHILD_MENU.CONTRACT_PAYMENTS,
            FINANCE_CHILD_MENU.INDIVIDUAL_PAYMENTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PATENT_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_ORDERS,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_TASKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_CIVIL_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_PATENT_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_FINANCE_BANK,
            FINANCE_CHILD_MENU.LINK_FINANCE_OPERATOR_REPORTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_RECONCILIATION_ACTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_SYSTEM_TRANSACTIONS,
            FINANCE_CHILD_MENU.LINK_FINANCE_REGISTRY_PAYMENTS,
        ],
    },
    ADMIN_LINK.LINK_FINANCE_EXPORT_FULL,
    ADMIN_LINK.LINK_FINANCE_UPLOAD,
    ADMIN_LINK.LINK_PROMOCODE_CHANNEL_LIST,
    ADMIN_LINK.LINK_REVIEWS_LIST,
    ADMIN_LINK.LINK_DISPUTES,
    ADMIN_LINK.LINK_MAILINGS_LIST,
    ADMIN_LINK.LINK_SETTINGS_INFO,
    ADMIN_LINK.LINK_TASK_LIST,
];

// Менеджер Наймикса
export const nmManagerMenuList = [
    ADMIN_LINK.LINK_CLIENT_LIST,
    {
        ...ADMIN_LINK.LINK_CONTRACTOR_LIST,
        childMenu: [
            CONTRACTOR_CHILD_MENU.LIST,
            CONTRACTOR_CHILD_MENU.ON_CHECK,
            CONTRACTOR_CHILD_MENU.RESOURCES,
            CONTRACTOR_CHILD_MENU.CLIENT_CONTRACTORS,
        ],
    },
    ADMIN_LINK.LINK_DEPOSIT_LIST,
    ADMIN_LINK.LINK_CIVIL_DEPOSIT_LIST,
    {
        ...ADMIN_LINK.LINK_FINANCE_PAYMENT_LIST,
        childMenu: [
            FINANCE_CHILD_MENU.LINK_FINANCE_PAYMENT_LIST,
            FINANCE_CHILD_MENU.CONTRACT_PAYMENTS,
            FINANCE_CHILD_MENU.INDIVIDUAL_PAYMENTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PATENT_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_PAYMENT_LIST,
            FINANCE_CHILD_MENU.LINK_FINANCE_ORDERS,
            FINANCE_CHILD_MENU.LINK_FINANCE_CROWD_TASKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_CIVIL_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_PATENT_PIVOT_TABLE,
            FINANCE_CHILD_MENU.LINK_FINANCE_OPERATOR_REPORTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_CANCELED_CHECKS,
            FINANCE_CHILD_MENU.LINK_FINANCE_RECONCILIATION_ACTS,
            FINANCE_CHILD_MENU.LINK_FINANCE_REGISTRY_PAYMENTS,
        ],
    },
    ADMIN_LINK.LINK_FINANCE_EXPORT_FULL,
    ADMIN_LINK.ALL_TEMPLATES,
    ADMIN_LINK.LINK_FINANCE_UPLOAD,
    ADMIN_LINK.LINK_PROMOCODE_CHANNEL_LIST,
    ADMIN_LINK.LINK_FULL_INFO_MAP,
    ADMIN_LINK.LINK_REVIEWS_LIST,
    ADMIN_LINK.LINK_DISPUTES,
    ADMIN_LINK.LINK_MAILINGS_LIST,
    ADMIN_LINK.LINK_CRM_TICKET_LIST,
    ADMIN_LINK.LINK_SETTINGS_INFO,
    ADMIN_LINK.LINK_TASK_LIST,
];

// Консультант Наймикса
export const nmConsultantMenuList = [
    ADMIN_LINK.LINK_CLIENT_LIST,
];

// СБ
export const RNKOMenuList = [
    {
        ...ADMIN_LINK.LINK_CONTRACTOR_LIST,
        childMenu: [
            CONTRACTOR_CHILD_MENU.LIST,
            CONTRACTOR_CHILD_MENU.ON_CHECK,
        ],
    },
];

// ЭДО пункты меню
function getEdoMenuList(accessList, clientId) {
    const _menuOptions = [];

    if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_GENERAL)) {
        _menuOptions[0] = {
            id: "edo",
            icon: "description_24",
            svg: true,
            content: "ЭДО",
            isSearchActiveInAllPath: true,
            activePath: ["/document-management", "/edm-staff-card"],
            childMenu: [],
        };

        if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_DOCUMENTS_SHOW)) {
            _menuOptions[0] = {
                ..._menuOptions[0],
                childMenu: [
                    ..._menuOptions[0].childMenu,
                    {
                        id: "DOCUMENTS",
                        content: "Документы",
                        to: LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST.replace(":clientId", clientId),
                        activePath: LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST.replace(":clientId", clientId),
                    },
                ],
            };
        }

        if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_SHOW)) {
            _menuOptions[0] = {
                ..._menuOptions[0],
                childMenu: [
                    ..._menuOptions[0].childMenu,
                    {
                        content: "Реестры документов",
                        to: LinkNavigator.client.edo.documentsRegistries.replace(":clientId", clientId),
                        activePath: LinkNavigator.client.edo.documentsRegistries.replace(":clientId", clientId),
                    },
                ],
            };
        }

        if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_SHOW)) {
            _menuOptions[0] = {
                ..._menuOptions[0],
                childMenu: [
                    ..._menuOptions[0].childMenu,
                    {
                        id: "STATEMENTS",
                        content: "Заявления",
                        to: LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS.replace(":clientId", clientId),
                        activePath: LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS.replace(":clientId", clientId),
                    },
                ],
            };
        }

        if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_SHOW)) {
            _menuOptions[0] = {
                ..._menuOptions[0],
                childMenu: [
                    ..._menuOptions[0].childMenu,
                    {
                        content: "Кадры",
                        to: LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL.replace(":clientId", clientId),
                        activePath: LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL.replace(":clientId", clientId),
                    },
                ],
            };
        }

        if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_DOCUMENTS_SHOW) || accessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_SHOW)) {
            _menuOptions[0] = {
                ..._menuOptions[0],
                childMenu: [
                    ..._menuOptions[0].childMenu,
                    {
                        content: "Экспорт",
                        to: LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT.replace(":clientId", clientId),
                        activePath: LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT.replace(":clientId", clientId),
                    },
                ],
            };
        }

        if (accessList.includes(EDO_ACCESS_RIGHTS.EDM_DICTIONARY_SHOW)) {
            _menuOptions[0] = {
                ..._menuOptions[0],
                childMenu: [
                    ..._menuOptions[0].childMenu,
                    {
                        content: "Справочники",
                        to: LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT.replace(":clientId", clientId),
                        activePath: [
                            LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT.replace(":clientId", clientId),
                            LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT.replace(":clientId", clientId),
                        ],
                    },
                ],
            };
        }
    }
    return _menuOptions;
}

function getPatentsMenuList(patentEnabled, clientId) {
    if (!patentEnabled) {
        return [];
    }

    return [
        {
            id: "patents",
            icon: "pattent_24",
            svg: true,
            content: "Патенты",
            activePath: [
                "/patents",
                LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
                LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
            ],
            childMenu: [
                {
                    content: "Оплата патентов",
                    to: LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST.replace(":clientId", clientId),
                    activePath: LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST.replace(":clientId", clientId),
                },
                {
                    content: "Депозит по патентам",
                    to: LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                    activePath: [
                        LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                        LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                        LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                    ],
                },
                {
                    content: "Документы по патентам",
                    to: LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST.replace(":clientId", clientId),
                    activePath: LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST.replace(":clientId", clientId),
                },
                {
                    content: "Экспорт",
                    to: LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST.replace(":clientId", clientId),
                    activePath: LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST.replace(":clientId", clientId),
                },
            ],
        },
    ];
}

function getRecruitmentMenuList(params) {
    const {
        clientId,
        clientProperties: {
            isRecruitmentAvailable,
        },
        currentMember,
    } = params;

    if (!isRecruitmentAvailable) {
        return [];
    }

    const {recruitment} = getClientLink({
        clientId,
        currentMember,
    });

    return [{
        ...recruitment.link,
        childMenu: [
            recruitment.vacancies,
            recruitment.candidates,
            recruitment.directories,
            recruitment.accessControl,
        ],
    }];
}

function getCrowdMenuList(clientId, crowdTasksAvailable) {
    if (!crowdTasksAvailable) {
        return [];
    }

    const {crowd} = getClientLink({
        clientId,
    });

    return [{
        ...crowd.link,
        childMenu: [
            crowd.taskRegistry,
            crowd.taskGroups,
            crowd.taskChats,
            crowd.taskActRegistry,
            crowd.taskAnalytics,
        ],
    }];
}

//TODO добавить передачу clientProperties
const getClientLink = (params) => {
    const {
        clientId,
        customDocumentTemplatesAvailable,
        addBrandNameToObject,
        individualRegistryPaymentsAvailable,
        contractorPushNotificationsAllowed,
        createAdditionalAgreementOnContractorPDChange,
        currentMember = {},
    } = params;

    const {
        seniorRecruiter,
    } = currentMember;

    const role = ls(USER_ROLE);

    const orderLink = LINK_CLIENT_ORDER_LIST.replace(":clientId", clientId);
    const orderTemplatesLink = LINK_CLIENT_ORDER_TEMPLATES_LIST.replace(":clientId", clientId);
    const orderGroupsLink = LINK_CLIENT_ORDER_GROUPS_LIST.replace(":clientId", clientId);
    const orderRegistryInvitationsLink = LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST.replace(":clientId", clientId);
    const orderActRegistryLink = LinkNavigator.client.orders.actRegistry.replace(":clientId", clientId);

    const recruitmentDirectoriesLink = LINK_CLIENT_RECRUITMENT_DIRECTORIES.replace(":clientId", clientId);
    const recruitmentDirectoriesFunnelsLink = LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS.replace(":clientId", clientId);
    const recruitmentVacanciesLink = LINK_CLIENT_RECRUITMENT_VACANCIES.replace(":clientId", clientId);
    const recruitmentCandidates = LINK_CLIENT_RECRUITMENT_CANDIDATES.replace(":clientId", clientId);
    const recruitmentAccessControlLink = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL.replace(":clientId", clientId);
    const recruitmentAccessControlGroupsLink = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS.replace(":clientId", clientId);
    const recruitmentAccessControlJobBoardsLink = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS.replace(":clientId", clientId);

    const showAccessControlRecruitersGroups = [ADMIN, CLIENT_ADMIN, NM_OPERATOR].includes(role) || role === RECRUITER && seniorRecruiter;

    const crowdTaskRegistryLink = LINK_CLIENT_CROWD_TASK_REGISTRY.replace(":clientId", clientId);
    const crowdTaskGroupsLink = LINK_CLIENT_CROWD_TASK_GROUPS.replace(":clientId", clientId);
    const crowdTaskChatsLink = LINK_CLIENT_CROWD_TASK_CHATS
        .replace(":clientId", clientId)
        .replace(":contractorId?", "");
    const crowdTaskActRegistryLink = LINK_CLIENT_CROWD_TASK_ACT_REGISTRY.replace(":clientId", clientId);
    const crowdTaskAnalyticsLink = LINK_CLIENT_CROWD_TASK_ANALYTICS
        .replace(":clientId", clientId)
        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK);

    return {
        orders: {
            link: {
                id: "orders",
                to: orderLink,
                icon: "library_books_24",
                content: "Заказы",
                activeExceptions: ["analytics"],
                activePath: [
                    orderLink,
                    orderTemplatesLink,
                    orderActRegistryLink,
                    orderGroupsLink,
                    orderRegistryInvitationsLink,
                ],
            },
            actRegistry: {
                content: "Реестр актов",
                to: orderActRegistryLink,
                activePath: orderActRegistryLink,
            },
            default: {
                content: "Реестр заказов",
                to: orderLink,
                activePath: orderLink,
            },
            groups: {
                content: "Группы заказов",
                to: orderGroupsLink,
                activePath: orderGroupsLink,
            },
            registryInvitations: {
                content: "Реестр приглашений",
                to: orderRegistryInvitationsLink,
                activePath: orderRegistryInvitationsLink,
            },
            templates: {
                content: "Шаблоны заказов",
                to: orderTemplatesLink,
                activePath: orderTemplatesLink,
            },
        },
        reports: {
            link: {
                id: "reports",
                content: "Отчеты",
                openChild: false,
                svg: true,
                icon: "insert_chart_24",
                activePath: [
                    LINK_CLIENT_FINANCE_EXPORT_FULL
                        .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                        .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
                        .replace(":clientId", clientId),
                    LINK_CLIENT_FINANCE_UPLOAD.replace(":clientId", clientId),
                    "order-analytics",
                ],
            },
            exportReports: {
                content: "Экспорт отчетов",
                to: LINK_CLIENT_FINANCE_EXPORT_FULL
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
                    .replace(":clientId", clientId),
                activePath: `${LINK_CLIENT_CARD.replace(":clientId", clientId)}${LINK_FINANCE_EXPORT}`,
            },
            orderAnalytic: {
                content: "Аналитика по заказам",
                to: LINK_CLIENT_ORDERS_ANALYTICS.replace(":clientId", clientId),
                activePath: ["order-analytics"],
            },
            upload1C: {
                content: "1С",
                to: LINK_CLIENT_FINANCE_UPLOAD.replace(":clientId", clientId),
                svg: true,
            },
        },
        advertisement: {
            link: {
                id: "advertisement",
                to: LINK_CLIENT_ADVERTISEMENT.replace(":clientId", clientId),
                icon: "ads_24",
                content: "Объявления",
                activePath: ["advertisement"],
            },
        },
        finance: {
            link: {
                id: "finance",
                content: "Финансы",
                activePath: [
                    LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId)
                        .replace(":clientId?/", clientId),
                    "/canceled-checks",
                    LINK_INSURANCE_HISTORY,
                    LINK_DEPOSIT_LIST,
                    LINK_CLIENT_REGISTRY_PAYMENTS_LIST_ARCHIVED
                        .replace(":clientId", clientId)
                        .replace("/:paymentNumberFilter?", ""),
                    LINK_CLIENT_REGISTRY_PAYMENTS_LIST_NO_ARCHIVED
                        .replace(":clientId", clientId)
                        .replace("/:paymentNumberFilter?", ""),
                    "finance/registry",
                    LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", ""),
                    LinkNavigator.client.finance.civilPerformerPayments.replace(":clientId", clientId)
                        .replace(":paymentNumberFilter?", ""),
                ],
                icon: "wallet_24",
            },
            canceledChecks: {
                id: "canceledChecks",
                activePath: LINK_CLIENT_CANCELLED_CHECKS.replace(":clientId", clientId),
                content: "Аннулированные чеки ФНС России",
                to: LINK_CLIENT_CANCELLED_CHECKS.replace(":clientId", clientId),
            },
            insurance: {
                id: "insurance",
                to: LINK_CLIENT_INSURANCE_HISTORY.replace(":clientId", clientId),
                content: "Страхование",
            },
            deposit: {
                id: "deposit",
                activePath: [
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.SPENT_LIMIT),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                ],
                to: LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                content: "Депозит (НПД)",
            },
            civilDeposit: {
                id: "civilDeposit",
                activePath: [
                    LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                    LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                    LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.SPENT_LIMIT),
                    LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING),
                    LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                ],
                to: LinkNavigator.client.finance.civilDeposit.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                content: "Депозит (НДФЛ)",
            },
            performerPayments: {
                id: "performerPayments",
                content: "Выплаты исполнителям (НПД)",
                to: LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId),
                activePath: [
                    LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId),
                    LINK_CLIENT_PAYMENTS_TASKS_LIST.replace(":clientId", clientId)
                        .replace(":paymentNumberFilter?", ""),
                ],
                activeExceptions: ["registry"],
            },
            performerCivilPayments: {
                id: "performerCivilPayments",
                content: "Выплаты исполнителям (НДФЛ)",
                to: LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId),
                activeExceptions: ["registry"],
            },
            performerIndividualPayments: {
                id: "performerIndividualPayments",
                content: "Выплаты исполнителям (ИП)",
                to: LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", clientId),
                activeExceptions: ["registry"],
                isVisible: individualRegistryPaymentsAvailable,
            },
            registryPayments: {
                id: "registryPayments",
                content: "Реестровые выплаты",
                to: LINK_CLIENT_REGISTRY_PAYMENTS_LIST
                    .replace(":clientId", clientId)
                    .replace(":archived", "false")
                    .replace("/:paymentNumberFilter?", ""),
                activePath: ["finance/registry"],
            },
        },
        contractors: {
            link: {
                id: "contractors",
                activePath: ["contractor", "/available-contractors", "/my-contractors", LINK_STOP_LIST, "on-check"],
                icon: "users_24",
                isSearchActiveInAllPath: true,
                activeExceptions: ["resources", "projects"],
                content: "Исполнители",
            },
            list: {
                to: LINK_CLIENT_CONTRACTORS_LIST.replace(":clientId", clientId).replace(":type", CONTRACTORS_TABS_TYPES.ALL),
                activePath: [
                    LINK_CLIENT_CONTRACTORS_LIST
                        .replace(":type", CONTRACTORS_TABS_TYPES.FAVORITE)
                        .replace(":clientId", clientId),
                    LINK_CLIENT_CONTRACTORS_LIST
                        .replace(":type", CONTRACTORS_TABS_TYPES.BLACK_LIST)
                        .replace(":clientId", clientId),
                    LINK_CLIENT_CONTRACTORS_LIST
                        .replace(":type", CONTRACTORS_TABS_TYPES.ALL)
                        .replace(":clientId", clientId),
                    "contractor-card",
                    "contractor-list",
                ],
                activeExceptions: ["resources", "projects"],
                content: "Реестр исполнителей",
            },
            stopList: {
                to: LINK_CLIENT_STOP_LIST.replace(":clientId", clientId),
                isSearchActiveInAllPath: true,
                content: "Стоп-лист",
            },
            resources: {
                content: "Ресурсы",
                id: "resources",
                to: LINK_CLIENT_CONTRACTORS_RESOURCES.replace(":clientId", clientId).replace(":type", MY_CONTRACTORS),
                activePath: [
                    LINK_CLIENT_CONTRACTORS_RESOURCES.replace(":clientId", clientId).replace(":type", MY_CONTRACTORS),
                    LINK_CLIENT_CONTRACTORS_RESOURCES.replace(":clientId", clientId).replace(":type", AVAILABLE_CONTRACTORS),
                    LINK_CLIENT_CONTRACTORS_RESOURCES.replace(":clientId", clientId).replace(":type", ATTENTION_CONTRACTORS),
                ],
            },
        },
        documents: {
            link: {
                id: "documents",
                icon: "description_24",
                svg: true,
                content: "Документы",
                isSearchActiveInAllPath: true,
                activePath: [
                    LINK_CLIENT_DOCUMENT_LIST.replace(":clientId", clientId),
                    LINK_CLIENT_EXPORT_DOCUMENT_LIST.replace(":clientId", clientId),
                    LINK_CLIENT_OPERATOR_REPORTS.replace(":clientId", clientId),
                    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST.replace(":clientId", clientId),
                    LINK_CLIENT_AGENCY_CONTRACT.replace(":clientId", clientId),
                    LINK_CLIENT_STANDART_DOCUMENTS.replace(":clientId", clientId),
                    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS.replace(":clientId", clientId),
                    LINK_CLIENT_REGISTRY_FC_LIST.replace(":clientId", clientId),
                    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST.replace(":clientId", clientId),
                ],
                isVisible: true,
            },
            list: {
                content: "Реестр документов",
                to: LINK_CLIENT_DOCUMENT_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_DOCUMENT_LIST.replace(":clientId", clientId),
                isVisible: true,
            },
            fcRegistries: {
                content: "Реестры на подписание РД",
                to: LINK_CLIENT_REGISTRY_FC_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_REGISTRY_FC_LIST.replace(":clientId", clientId),
                isVisible: true,
            },
            registriesOfAdditionalAgreements: {
                content: "Реестры на подписание доп. соглашений",
                to: LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST.replace(":clientId", clientId),
                isVisible: ![CLIENT_ACCOUNTANT].includes(role) && createAdditionalAgreementOnContractorPDChange,
            },
            export: {
                content: "Экспорт документов",
                to: LINK_CLIENT_EXPORT_DOCUMENT_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_EXPORT_DOCUMENT_LIST.replace(":clientId", clientId),
                isVisible: true,
            },
            notificationsOVM: {
                content: "Уведомления ОВМ",
                to: LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST.replace(":clientId", clientId),
                isVisible: true,
            },
            operatorReports: {
                content: "Отчёты оператора",
                to: LINK_CLIENT_OPERATOR_REPORTS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_OPERATOR_REPORTS.replace(":clientId", clientId),
                isVisible: true,
            },
            agencyContract: {
                content: "Агентский договор",
                to: LINK_CLIENT_AGENCY_CONTRACT.replace(":clientId", clientId),
                activePath: LINK_CLIENT_AGENCY_CONTRACT.replace(":clientId", clientId),
                isVisible: true,
            },
            typicalDocumentsNaimix: {
                content: "Типовые документы Наймикс",
                to: LINK_CLIENT_STANDART_DOCUMENTS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_STANDART_DOCUMENTS.replace(":clientId", clientId),
                isVisible: true,
            },
            mutualSettlementsReconciliationActs: {
                content: "Акт сверки взаиморасчетов",
                to: LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS.replace(":clientId", clientId),
                isVisible: true,
            },
            templates: {
                content: "Шаблоны документов",
                to: LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST.replace(":clientId", clientId),
                activePath: [
                    LINK_TEMPLATES_CONTRACTS_LIST,
                    LINK_TEMPLATES_KEDO_LOG,
                    `${LINK_TEMPLATES}/${TEMPLATES_TAB.CONTRACTS.LINK}`,
                    `${LINK_TEMPLATES}/${TEMPLATES_TAB.ACTS.LINK}`,
                    `${LINK_TEMPLATES}/${TEMPLATES_TAB.LOGS.LINK}`,
                    `${LINK_TEMPLATES}/${TEMPLATES_TAB.APPLICATIONS.LINK}`,
                    `${LINK_TEMPLATES}/${TEMPLATES_TAB.ADDITIONAL_AGREEMENT.LINK}`,
                ],
                isVisible: customDocumentTemplatesAvailable,
            },
        },
        kedo: {
            link: {
                id: "kedo",
                icon: "edo_24",
                svg: true,
                content: "КЭДО",
                activePath: [
                    LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_DIRECTORIES_SUBDIVISIONS.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_DIRECTORIES_POSITIONS.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                ],
            },
            staff: {
                content: "Сотрудники",
                to: LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
            },
            documents: {
                content: "Документооборот",
                to: LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
            },
            exports: {
                content: "Экспорт документов",
                to: LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
            },
            directories: {
                content: "Справочники",
                to: LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES.replace(":clientId", clientId),
                activePath: [
                    LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_DIRECTORIES_SUBDIVISIONS.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_DIRECTORIES_POSITIONS.replace(":clientId", clientId),
                ],
            },
            logs: {
                content: "Лог интеграции с 1С",
                to: LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
            },
        },
        company: {
            link: {
                id: "company",
                icon: "business_24",
                svg: true,
                content: "Компания",
                activeExceptions: [
                    LinkNavigator.client.finance.depositRegistryOrders
                        .replace(":clientId", clientId)
                        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)
                        .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS),
                    LinkNavigator.client.finance.civilDepositRegistryOrders
                        .replace(":clientId", clientId)
                        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)
                        .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS),
                    LINK_CLIENT_RECRUITMENT.replace(":clientId", clientId),
                    "templates",
                ],
                activePath: [
                    "/info",
                    LINK_CLIENT_MEMBERS,
                    LINK_BRIGADE,
                    LINK_PROJECTS,
                    LINK_FEEDBACKS,
                    LINK_OBJECTS,
                    LINK_CLIENT_BRANDS.replace(":clientId", clientId),
                ],
            },
            info: {
                content: "Информация",
                to: LINK_CLIENT_INFO.replace(":clientId", clientId),
                activePath: LINK_CLIENT_INFO.replace(":clientId", clientId),
            },
            projects: {
                id: "projects",
                to: LINK_CLIENT_PROJECTS_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_PROJECTS_LIST.replace(":clientId", clientId),
                content: "Проекты",
                activeExceptions: [
                    LinkNavigator.client.finance.depositRegistryOrders
                        .replace(":clientId", clientId)
                        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)
                        .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS),
                    LinkNavigator.client.finance.civilDepositRegistryOrders
                        .replace(":clientId", clientId)
                        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)
                        .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS),
                    "templates",
                ],
            },
            objects: {
                id: "objects",
                to: LINK_CLIENT_OBJECTS_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_OBJECTS_LIST.replace(":clientId", clientId),
                content: "Объекты",
            },
            members: {
                to: LINK_CLIENT_MEMBERS_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_MEMBERS_LIST.replace(":clientId", clientId),
                content: "Сотрудники",
            },
            brigadiers: {
                id: "brigade",
                content: "Бригадиры",
                to: LINK_CLIENT_BRIGADE.replace(":clientId", clientId)
                    .replace(":clientId?/", clientId)
                    .replace(":page", BRIGADE_ORDER_OBJECT_OBJECT),
                activePath: LINK_CLIENT_BRIGADE.replace(":clientId", clientId)
                    .replace(":clientId?/", clientId)
                    .replace(":page", ""),
            },
            feedbacks: {
                to: LINK_CLIENT_FEEDBACKS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_FEEDBACKS.replace(":clientId", clientId),
                content: "Отзывы",
            },
            brands: {
                to: LINK_CLIENT_BRANDS.replace(":clientId", clientId),
                activePath: LINK_CLIENT_BRANDS.replace(":clientId", clientId),
                content: "Бренды",
                isVisible: addBrandNameToObject,
            },
        },
        disputes: {
            link: {
                id: "disputes",
                content: "Споры",
                to: LINK_DISPUTES_CLIENT_ADMIN
                    .replace(":clientId", clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, CHAT_LINK_PARAMS.OPEN)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, 1)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, 25)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "")
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, ""),
                activePath: [
                    "/disputes/status",
                    `/disputes/${clientId}/chats`,
                ],
                icon: "hammer_24",
            },
        },
        notifications: {
            link: {
                id: "notifications",
                activePath: [LINK_SETTING_NOTIFICATIONS_LIST, LINK_CLIENT_NOTIFICATIONS_LIST.replace(":clientId", clientId)],
                icon: "notifications_24",
                svg: true,
                content: "Уведомления",
            },
            list: {
                to: LINK_CLIENT_NOTIFICATIONS_LIST.replace(":clientId", clientId),
                activePath: [LINK_CLIENT_NOTIFICATIONS_LIST.replace(":clientId", clientId)],
                content: "Уведомления",
            },
            mailing: {
                to: LINK_CLIENT_SETTING_NOTIFICATIONS_LIST.replace(":clientId", clientId),
                activePath: LINK_CLIENT_SETTING_NOTIFICATIONS_LIST.replace(":clientId", clientId),
                content: "Рассылки на почту",
            },
        },
        instructions: {
            link: {
                id: "instructions",
                to: LINK_INSTRUCTION_CLIENT_ADMIN.replace(":clientId", clientId),
                icon: "topic_24",
                content: "Инструкции",
                activePath: [
                    LINK_INSTRUCTION_CLIENT_ADMIN.replace(":clientId", clientId),
                    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN.replace(":clientId", clientId),
                ],
            },
        },
        tasks: {
            link: {
                id: "tasks",
                to: "/tasks",
                icon: "tasks",
                content: "Список задач",
            },
        },
        chats: {
            link: {
                id: "chat-list",
                to: LINK_CLIENT_CHAT_LIST
                    .replace(":clientId", clientId)
                    .replace(":orderId?/:chatId?/:newChatContractorId?", ""),
                icon: "chat_24",
                content: "Чаты",
            },
        },
        mailings: {
            link: {
                id: "mailings",
                to: LINK_CLIENT_MAILINGS_LIST.replace(":clientId", clientId),
                icon: "mail_24",
                content: "Рассылки уведомлений",
                isVisible: contractorPushNotificationsAllowed && role !== CLIENT_ACCOUNTANT,
            },
        },
        recruitment: {
            link: {
                id: "recruitment",
                to: recruitmentDirectoriesLink,
                icon: "user_24",
                content: "Рекрутмент",
                activePath: [
                    recruitmentDirectoriesLink,
                    recruitmentVacanciesLink,
                    recruitmentCandidates,
                    recruitmentAccessControlLink,
                ],
            },
            vacancies: {
                content: "Вакансии",
                to: recruitmentVacanciesLink,
                activePath: recruitmentVacanciesLink,
            },
            directories: {
                content: "Справочники",
                to: recruitmentDirectoriesFunnelsLink,
                activePath: recruitmentDirectoriesLink,
            },
            candidates: {
                content: "Кандидаты по вакансиям",
                to: recruitmentCandidates,
                activePath: recruitmentCandidates,
            },
            accessControl: {
                content: "Управление доступом",
                to: showAccessControlRecruitersGroups ? recruitmentAccessControlGroupsLink : recruitmentAccessControlJobBoardsLink,
                activePath: recruitmentAccessControlLink,
            },
        },
        crowd: {
            link: {
                id: "crowd",
                to: crowdTaskRegistryLink,
                icon: "lightbulb_on_24",
                content: "Задания",
                activePath: [
                    crowdTaskRegistryLink,
                    crowdTaskGroupsLink,
                    crowdTaskChatsLink,
                    crowdTaskActRegistryLink,
                    crowdTaskAnalyticsLink,
                ],
            },
            taskRegistry: {
                content: "Реестр заданий",
                to: crowdTaskRegistryLink,
                activePath: crowdTaskRegistryLink,
            },
            taskGroups: {
                content: "Группы заданий",
                to: crowdTaskGroupsLink,
                activePath: crowdTaskGroupsLink,
            },
            taskChats: {
                content: "Чаты заданий",
                to: crowdTaskChatsLink,
                activePath: crowdTaskChatsLink,
            },
            taskActRegistry: {
                content: "Реестр актов по заданиям",
                to: crowdTaskActRegistryLink,
                activePath: crowdTaskActRegistryLink,
            },
            taskAnalytics: {
                content: "Аналитика по заданиям",
                to: crowdTaskAnalyticsLink,
                activePath: [
                    crowdTaskAnalyticsLink,
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.SIGN_CONTRACT.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.HIRED.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.IN_WORK.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.ON_REVIEW.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK),
                ],
            },
        },
    };
};

// Администратор компании/Бухгалтер
export const clientAdminMenuList = (params) => {
    const {
        clientId,
        edoAccessList,
        clientUserStaffExist,
        migrantLicensePaymentEnabled,
        clientProperties,
        role,
    } = params;

    const {
        customDocumentTemplatesAvailable,
        crowdTasksAvailable,
        withoutContract,
        clientWorkType,
        addBrandNameToObject,
        individualRegistryPaymentsAvailable,
        contractorPushNotificationsAllowed,
        createAdditionalAgreementOnContractorPDChange,
    } = clientProperties;

    const {
        orders,
        finance,
        contractors,
        documents,
        reports,
        company,
        disputes,
        notifications,
        instructions,
        tasks,
        advertisement,
        chats,
        kedo,
        mailings,
        crowd,
    } = getClientLink({
        clientId,
        customDocumentTemplatesAvailable,
        addBrandNameToObject,
        individualRegistryPaymentsAvailable,
        contractorPushNotificationsAllowed,
        createAdditionalAgreementOnContractorPDChange,
    });

    const kedoLinks = role === CLIENT_ACCOUNTANT || clientWorkType === CLIENT_WORK_TYPE.ONLY_NAIMIX ?
        [] :
        [{
            ...kedo.link,
            childMenu: [
                kedo.staff,
                kedo.documents,
                kedo.exports,
                kedo.directories,
                kedo.logs,
            ],
        }];
    const patents = ls(USER_ROLE) === CLIENT_ACCOUNTANT || !clientUserStaffExist ?
        [] :
        getPatentsMenuList(migrantLicensePaymentEnabled, clientId);
    const childOrder = ls(USER_ROLE) === CLIENT_ACCOUNTANT ?
        [
            orders.default,
            orders.groups,
            orders.registryInvitations,
            orders.actRegistry,
        ] :
        [
            orders.default,
            orders.groups,
            orders.registryInvitations,
            orders.actRegistry,
            orders.templates,
        ];

    if (withoutContract) {
        return [
            ...kedoLinks,
            {
                ...company.link,
                childMenu: [
                    company.info,
                    company.members,
                    company.projects,
                    company.objects,
                    company.feedbacks,
                ],
            },
            ...getEdoMenuList(edoAccessList, clientId),
            ...patents,
            {
                ...reports.link,
                childMenu: [
                    reports.exportReports,
                ],
            },
            {
                ...notifications.link,
                ...notifications.list,
            },
        ];
    }

    const financeChild = clientId === MOCK_PEREKRESTOR_ID ? [
        finance.performerPayments,
        finance.performerCivilPayments,
        finance.performerIndividualPayments,
        finance.registryPayments,
        // finance.deposit,
        finance.insurance,
        finance.canceledChecks,
    ]
        : [
            finance.performerPayments,
            finance.performerCivilPayments,
            finance.performerIndividualPayments,
            finance.registryPayments,
            finance.deposit,
            finance.civilDeposit,
            finance.insurance,
            finance.canceledChecks,
        ];
    return [
        {
            ...orders.link,
            childMenu: childOrder,
        },
        ...getRecruitmentMenuList({clientId, clientProperties}),
        ...getCrowdMenuList(clientId, crowdTasksAvailable),
        advertisement.link,
        {
            ...finance.link,
            childMenu: financeChild,
        },
        {
            ...contractors.link,
            childMenu: [
                contractors.list,
                contractors.stopList,
                contractors.resources,
            ],
        },
        {
            ...documents.link,
            childMenu: [
                documents.list,
                documents.fcRegistries,
                documents.registriesOfAdditionalAgreements,
                documents.export,
                documents.notificationsOVM,
                documents.operatorReports,
                documents.mutualSettlementsReconciliationActs,
                documents.agencyContract,
                documents.typicalDocumentsNaimix,
                documents.templates,
            ].filter(({isVisible}) => isVisible),
        },
        ...kedoLinks,
        ...getEdoMenuList(edoAccessList, clientId),
        ...patents,
        {
            ...reports.link,
            childMenu: [
                reports.exportReports,
                reports.upload1C,
                reports.orderAnalytic,
            ],
        },
        {
            ...company.link,
            childMenu: [
                company.info,
                company.members,
                company.projects,
                company.objects,
                company.brigadiers,
                company.feedbacks,
                company.brands,
            ].filter(({isVisible = true}) => isVisible),
        },
        disputes.link,
        {
            ...notifications.link,
            childMenu: [
                notifications.list,
                notifications.mailing,
            ],
        },
        instructions.link,
        tasks.link,
        chats.link,
        mailings.link,
    ];
};

// Менеджер проекта
export const clientProjectManagerMenuList = (params) => {
    const {
        clientId,
        edoAccessList,
        clientUserStaffExist,
        migrantLicensePaymentEnabled,
        clientProperties,
    } = params;

    const {
        customDocumentTemplatesAvailable,
        crowdTasksAvailable,
        withoutContract,
        individualRegistryPaymentsAvailable,
        createAdditionalAgreementOnContractorPDChange,
    } = clientProperties;

    const {
        orders,
        finance,
        contractors,
        documents,
        reports,
        company,
        disputes,
        notifications,
        instructions,
        advertisement,
        tasks,
        chats,
        crowd,
    } = getClientLink({
        clientId,
        customDocumentTemplatesAvailable,
        individualRegistryPaymentsAvailable,
        createAdditionalAgreementOnContractorPDChange,
    });

    const patents = !clientUserStaffExist ?
        [] :
        getPatentsMenuList(migrantLicensePaymentEnabled, clientId);

    if (withoutContract) {
        return [
            {
                ...company.link,
                childMenu: [
                    company.projects,
                    company.objects,
                    company.members,
                    company.feedbacks,
                ],
            },
            ...getEdoMenuList(edoAccessList, clientId),
            {
                ...reports.link,
                childMenu: [
                    reports.exportReports,
                ],
            },
            {
                ...notifications.link,
                ...notifications.list,
            },
        ];
    }

    const financeChild = clientId === MOCK_PEREKRESTOR_ID ? [
        finance.performerPayments,
        finance.performerCivilPayments,
        finance.performerIndividualPayments,
        finance.registryPayments,
        //finance.deposit,
        finance.insurance,
        finance.canceledChecks,
    ]
        : [
            finance.performerPayments,
            finance.performerCivilPayments,
            finance.performerIndividualPayments,
            finance.registryPayments,
            finance.deposit,
            finance.civilDeposit,
            finance.insurance,
            finance.canceledChecks,
        ];

    return [
        {
            ...orders.link,
            childMenu: [
                orders.default,
                orders.groups,
                orders.actRegistry,
                orders.registryInvitations,
            ],
        },
        ...getRecruitmentMenuList({clientId, clientProperties}),
        ...getCrowdMenuList(clientId, crowdTasksAvailable),
        advertisement.link,
        {
            ...finance.link,
            childMenu: financeChild,
        },
        {
            ...contractors.link,
            childMenu: [
                contractors.list,
                contractors.resources,
            ],
        },
        {
            ...documents.link,
            childMenu: [
                documents.list,
                documents.fcRegistries,
                documents.registriesOfAdditionalAgreements,
                documents.export,
                documents.notificationsOVM,
                documents.mutualSettlementsReconciliationActs,
            ],
        },
        ...getEdoMenuList(edoAccessList, clientId),
        ...patents,
        {
            ...reports.link,
            childMenu: [
                reports.exportReports,
                reports.upload1C,
                reports.orderAnalytic,
            ],
        },
        {
            ...company.link,
            childMenu: [
                company.members,
                company.projects,
                company.objects,
                company.brigadiers,
                company.feedbacks,
            ],
        },
        disputes.link,
        {
            ...notifications.link,
            ...notifications.list,
        },
        instructions.link,
        tasks.link,
        chats.link,
    ];
};

// Менеджер объекта
export const clientObjectManagerMenuList = (params) => {
    const {
        clientId,
        edoAccessList,
        clientProperties,
    } = params;

    const {
        customDocumentTemplatesAvailable,
        crowdTasksAvailable,
        withoutContract,
        individualRegistryPaymentsAvailable,
    } = clientProperties;

    const {
        orders,
        finance,
        contractors,
        documents,
        reports,
        company,
        disputes,
        notifications,
        advertisement,
        instructions,
        tasks,
        chats,
        crowd,
    } = getClientLink({
        clientId,
        customDocumentTemplatesAvailable,
        individualRegistryPaymentsAvailable,
    });

    if (withoutContract) {
        return [
            {
                ...company.link,
                childMenu: [
                    company.projects,
                    company.objects,
                    company.members,
                    company.feedbacks,
                ],
            },
            ...getEdoMenuList(edoAccessList, clientId),
            {
                ...reports.link,
                childMenu: [
                    reports.exportReports,
                ],
            },
            {
                ...notifications.link,
                ...notifications.list,
            },
        ];
    }


    return [
        {
            ...orders.link,
            childMenu: [
                orders.default,
                orders.groups,
                orders.actRegistry,
                orders.registryInvitations,
            ],
        },
        ...getRecruitmentMenuList({clientId, clientProperties}),
        ...getCrowdMenuList(clientId, crowdTasksAvailable),
        advertisement.link,
        {
            ...finance.link,
            childMenu: [
                finance.performerPayments,
                finance.performerCivilPayments,
                finance.performerIndividualPayments,
                finance.registryPayments,
                finance.canceledChecks,
            ],
        },
        {
            ...contractors.link,
            childMenu: [
                contractors.list,
                contractors.resources,
            ],
        },
        {
            ...documents.link,
            childMenu: [
                documents.list,
                documents.fcRegistries,
                documents.export,
                documents.notificationsOVM,
                documents.mutualSettlementsReconciliationActs,
            ],
        },
        ...getEdoMenuList(edoAccessList, clientId),
        {
            ...reports.link,
            childMenu: [
                reports.exportReports,
                reports.orderAnalytic,
            ],
        },
        {
            ...company.link,
            childMenu: [
                company.members,
                company.projects,
                company.objects,
                company.brigadiers,
                company.feedbacks,
            ],
        },
        disputes.link,
        {
            ...notifications.link,
            ...notifications.list,
        },
        instructions.link,
        tasks.link,
        chats.link,
    ];
};

// Координатор/прораб
export const foremanMenuList = (params) => {
    const {
        clientId,
        edoAccessList,
        clientProperties,
    } = params;

    const {
        customDocumentTemplatesAvailable,
        withoutContract,
        individualRegistryPaymentsAvailable,
    } = clientProperties;

    const {
        orders,
        advertisement,
        finance,
        contractors,
        documents,
        disputes,
        reports,
        company,
        notifications,
        instructions,
        tasks,
        chats,
    } = getClientLink({
        clientId,
        customDocumentTemplatesAvailable,
        individualRegistryPaymentsAvailable,
    });

    if (withoutContract) {
        return [
            {
                ...company.link,
                childMenu: [
                    company.projects,
                    company.objects,
                    company.feedbacks,
                ],
            },
            ...getEdoMenuList(edoAccessList, clientId),
            {
                ...reports.link,
                childMenu: [
                    reports.exportReports,
                ],
            },
            {
                ...notifications.link,
                ...notifications.list,
            },
        ];
    }

    return [
        {
            ...orders.link,
            childMenu: [
                orders.default,
                orders.groups,
                orders.actRegistry,
                orders.registryInvitations,
            ],
        },
        ...getRecruitmentMenuList({clientId, clientProperties}),
        advertisement.link,
        {
            ...finance.link,
            childMenu: [
                finance.performerPayments,
                finance.performerCivilPayments,
                finance.performerIndividualPayments,
                finance.registryPayments,
                finance.canceledChecks,
            ],
        },
        {
            ...contractors.link,
            childMenu: [
                contractors.list,
                contractors.resources,
            ],
        },
        ...getEdoMenuList(edoAccessList, clientId),
        {
            ...reports.link,
            childMenu: [
                reports.exportReports,
                reports.orderAnalytic,
            ],
        },
        {
            ...company.link,
            childMenu: [
                company.projects,
                company.objects,
                company.brigadiers,
                company.feedbacks,
            ],
        },
        {
            ...documents.link,
            childMenu: [
                documents.list,
                documents.fcRegistries,
                documents.export,
                documents.notificationsOVM,
                documents.mutualSettlementsReconciliationActs,
            ],
        },
        disputes.link,
        {
            ...notifications.link,
            ...notifications.list,
        },
        instructions.link,
        tasks.link,
        chats.link,
    ];
};

// Рекрутер
export const recruiterMenuList = (params) => {
    const {
        clientId,
        clientProperties,
        currentMember,
    } = params;

    const {
        customDocumentTemplatesAvailable,
        withoutContract,
    } = clientProperties;

    const {
        orders,
        contractors,
        documents,
        company,
        tasks,
        advertisement,
    } = getClientLink({
        clientId,
        customDocumentTemplatesAvailable,
        currentMember,
    });

    if (withoutContract) {
        return [
            {
                ...company.link,
                childMenu: [
                    company.projects,
                    company.objects,
                ],
            },
        ];
    }

    return [
        {
            ...orders.link,
            childMenu: [
                orders.default,
            ],
        },
        ...getRecruitmentMenuList({
            clientId,
            clientProperties,
            currentMember,
        }),
        advertisement.link,
        {
            ...contractors.link,
            childMenu: [
                contractors.list,
                contractors.resources,
            ],
        },
        {
            ...company.link,
            childMenu: [
                company.projects,
                company.objects,
            ],
        },
        {
            ...documents.link,
            childMenu: [
                documents.export,
                documents.notificationsOVM,
                documents.typicalDocumentsNaimix,
            ],
        },
        tasks.link,
    ];
};

// HR-менеджер
export const hrManagerMenuList = (params) => {
    const {
        clientId,
        edoAccessList,
        clientProperties,
    } = params;

    const {
        customDocumentTemplatesAvailable,
        withoutContract,
    } = clientProperties;

    const {
        company,
        notifications,
        instructions,
        documents,
        contractors,
        tasks,
        chats,
    } = getClientLink({
        clientId,
        customDocumentTemplatesAvailable,
    });

    if (withoutContract) {
        return [
            {
                ...company.link,
                childMenu: [
                    company.info,
                    company.feedbacks,
                ],
            },
            ...getEdoMenuList(edoAccessList, clientId),
            {
                ...notifications.link,
                childMenu: [
                    notifications.list,
                    notifications.mailing,
                ],
            },
        ];
    }

    return [
        ...getRecruitmentMenuList({clientId, clientProperties}),
        {
            ...company.link,
            childMenu: [
                company.info,
                company.feedbacks,
            ],
        },
        {
            ...contractors.link,
            childMenu: [
                contractors.list,
            ],
        },
        {
            ...documents.link,
            childMenu: [
                documents.list,
                documents.fcRegistries,
                documents.export,
                documents.notificationsOVM,
                documents.typicalDocumentsNaimix,
            ],
        },
        ...getEdoMenuList(edoAccessList, clientId),
        {
            ...notifications.link,
            childMenu: [
                notifications.list,
                notifications.mailing,
            ],
        },
        instructions.link,
        tasks.link,
        chats.link,
    ];
};

// Наблюдатель в рекурутменте
export const getRecruitmentObserverMenuList = (params) => {
    const {
        clientId,
        clientProperties: {
            isRecruitmentAvailable,
        },
    } = params;

    const {recruitment} = getClientLink({
        clientId,
    });

    return isRecruitmentAvailable
        ? [{
            ...recruitment.link,
            childMenu: [
                recruitment.vacancies,
                recruitment.candidates,
            ],
        }]
        : [];
};