import React, {Component} from "react";

import NmInputV2 from "../ActualComponents/NmInputV2";
import NmInput from "../NmInput";

import {updateCaretPosition} from "../../utils/dom";
import {getNumberFromFormattedAmount} from "../../utils/stringFormat";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import {
    amountRegexDelimited,
    amountRegexUseDeleteBeforeSpace,
    regDigits,
    regS,
} from "../../constants/validationRules";

import PropTypes from "prop-types";

import "./style.sass";

const formatValue = (value) => {
    value = value ? value : "";
    value = typeof value !== "string" ? String(value) : value;
    value = value.replace(regS, "");
    value = value.replace(regDigits, "$1 ");
    value = value.replace(".", ",");

    return value;
};

class AmountInput extends Component {
    static propTypes = {
        addZeroAfterDivider: PropTypes.bool,
    };

    static defaultProps = {
        addZeroAfterDivider: false,
    };

    constructor(props) {
        super(props);
        const {
            value,
        } = props;

        this.state = {
            defaultValue: null,
            addZeroAfterDivider: false,
            formValue: formatValue(value || ""),
        };
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props;
        const {defaultValue} = this.state;

        if (value !== defaultValue) {
            this.setState({
                formValue: formatValue(value),
                defaultValue: value,
            });
        }
    }

    setValue = (event, value) => {
        const {formValue} = this.state;

        const element = event.target;
        const caretPosition = event.target.selectionStart;

        value = formatValue(value);

        this.setState({
            formValue: value,
        }, () => {
            updateCaretPosition(value, formValue, element, caretPosition);
        });
    };

    isValidAmount = (value) => {
        value = value.replace(/\s+/g, "");

        return amountRegexDelimited.test(value);
    };

    handleChange = (event, {name, value}) => {
        const {maxValue} = this.props;

        if (!this.isValidAmount(value) && value !== "") {
            return;
        }

        if (
            !isNullOrWhitespace(maxValue)
            && value
            && getNumberFromFormattedAmount(value) > maxValue
        ) {
            value = maxValue;
        }

        const {onChange} = this.props;

        this.setValue(event, value);

        onChange(event, {name, value});
    };

    handleKeydown = (event) => {
        if (event.key === "Delete") {
            const caretPosition = event.target.selectionStart;
            const value = this.state.formValue;
            const preValue = value.substr(caretPosition, 2);

            if (amountRegexUseDeleteBeforeSpace.test(preValue)) {
                this.setValue(event, `${value.substr(0, caretPosition + 1)}${value.substr(caretPosition + 2, value.length)}`);
                event.preventDefault();
                return false;
            }
        }
    }

    handleFocus = () => {
        const {onFocus} = this.props;

        if (onFocus) {
            onFocus();
        }
    };

    onBlur = () => {
        const {addZeroAfterDivider, onBlur} = this.props;

        if (onBlur) {
            onBlur();
        }

        if (!addZeroAfterDivider) {
            return;
        }

        const {formValue} = this.state;

        if (formValue.indexOf(",") === -1) {
            if (formValue) {
                this.setState(prevState => ({
                    ...prevState,
                    formValue: `${prevState.formValue},00`,
                }));
            }

            return;
        }

        const zeroMap = new Map([[0, "00"], [1, "0"], [2, ""]]);

        const split = formValue.split(",");

        const value = `${formValue}${zeroMap.get(split[1].length)}`;

        this.setState({
            formValue: value,
        });
    };

    render() {
        const {formValue} = this.state;
        const {addZeroAfterDivider, newInput, ...rest} = this.props;

        return newInput ?
            <NmInputV2
                className="amount-input"
                {...rest}
                value={formValue}
                onChange={this.handleChange}
                onKeyDown={this.handleKeydown}
                onFocus={this.handleFocus}
                onBlur={this.onBlur}
            /> :
            <NmInput
                className="amount-input"
                {...rest}
                value={formValue}
                onChange={this.handleChange}
                onKeyDown={this.handleKeydown}
                onFocus={this.handleFocus}
                onBlur={this.onBlur}
            />;
    }
}

export default AmountInput;