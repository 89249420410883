import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    clientRemoteContractorsListProgressSelector,
    clientRemoteContractorsListSelector,
    clientRemoteContractorsListTotalCountSelector,
    clientRemoteContractorsListTotalPagesSelector,
    clientRemoteContractorsProgressActionSelector,
    getClientRemoteContractorsPage,
    updateClientRemoteContractorStore,
} from "../../../../ducks/clientRemoteContractors";
import {getContractorStatusDict} from "../../../../ducks/contractor";
import {
    updateFieldFilesStore,
} from "../../../../ducks/fileStore";

export const useClientRemoteContractorsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
    } = params;
    const dispatch = useDispatch();

    const list = useSelector(clientRemoteContractorsListSelector);
    const progress  = useSelector(clientRemoteContractorsListProgressSelector);
    const totalPages  = useSelector(clientRemoteContractorsListTotalPagesSelector);
    const totalCount  = useSelector(clientRemoteContractorsListTotalCountSelector);
    const progressAction = useSelector(clientRemoteContractorsProgressActionSelector);

    useEffect(() => {
        dispatch(getContractorStatusDict());

        return () => {
            dispatch(updateClientRemoteContractorStore({
                list: [],
            }));
            dispatch(updateFieldFilesStore({
                avatarBase64ImagesList: [],
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
    ]);

    const fetchList = () => {
        dispatch(getClientRemoteContractorsPage({
            data: {
                pageNum,
                pageSize,
                ...filterData,
            },
        }));
    };

    return {
        fetchList,
        list,
        progress,
        totalPages,
        totalCount,
        progressAction,
    };
};