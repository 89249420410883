import React from "react";
import {withTranslation} from "react-i18next";

import {
    Dimmer,
    Loader,
} from "semantic-ui-react";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmLoader = (props) => {
    const {
        content = props.t("loader.content"),
        active = false,
        inverted = false,
        inline,
        classNameDimmer = "",
        classNameLoader = "",
        transparentBackground,
        relativeDimmer = false,
        size,
        fixed,
        zIndex,
    } = props;

    const [block] = bem("nm-loader", classNameDimmer);

    return (
        <Dimmer
            style={{zIndex}}
            active={active}
            inverted={inverted}
            className={block({
                transparent: transparentBackground,
                relativeDimmer,
                fixed,
            })}
        >
            <Loader
                size={size}
                className={classNameLoader}
                content={content}
                inline={inline}
            />
        </Dimmer>
    );
};

NmLoader.propTypes = {
    active: PropTypes.bool,
    inverted: PropTypes.bool,
    content: PropTypes.any,
};

export default withTranslation()(NmLoader);