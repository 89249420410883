import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";

import {bffRecruitmentVacancyCandidatesCardSelector} from "../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {
    addRecruitmentVacancyCandidatesComment,
    editRecruitmentVacancyCandidatesComment,
} from "../../../ducks/bff/recruitment/vacancyCandidatesComments/actionCreators";
import {
    bffRecruitmentVacancyCandidatesCommentsProgressActionSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidatesComments/selectors";
import {ModalTextareaEdit} from "../../ActualComponents/ModalTextareaEdit";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

export const RecruitmentCandidateCommentsEdit = (props) => {
    const {
        editData,
        onClose,
        fetchList,
        candidateId,
        vacancyId,
    } = props;

    const dispatch = useDispatch();

    const card = useSelector(bffRecruitmentVacancyCandidatesCardSelector);
    const progressAction = useSelector(bffRecruitmentVacancyCandidatesCommentsProgressActionSelector);

    const onSubmit = (values) => {
        if (editData.id) {
            dispatch(editRecruitmentVacancyCandidatesComment({
                ...editData,
                candidateId,
                vacancyId,
                comment: values.comment,
                onSuccess: () => {
                    onClose();
                    fetchList({isLoadMore: false, pageNum: 1});
                },
            }));
        }
    };

    return (
        <ModalTextareaEdit
            title={`Комментарий к кандидату ${card.name}`}
            progressAction={progressAction}
            validations={
                yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(3, "Минимальная длина строки 3 символа")
            }
            required={true}
            maxLength={5000}
            name="comment"
            initialValues={{
                comment: editData.comment || "",
            }}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
