import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_MIGRATION_STATUS} from "../../../constants/contractor";

export const isRequiredFullRegistrationVhiPolicySwitch = (params) => {
    const {
        citizenship,
        migrationStatus,
    } = params;

    return [
        CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
        CONTRACTOR_MIGRATION_STATUS.STUDENT,
        CONTRACTOR_MIGRATION_STATUS.PATENT,
    ].includes(migrationStatus) ||
        (
            [
                citizenshipsDict.AM.value,
                citizenshipsDict.KZ.value,
                citizenshipsDict.KG.value,
            ].includes(citizenship) &&
            migrationStatus === CONTRACTOR_MIGRATION_STATUS.NONE
        );
};