import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {downloadBlob} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {getClientUsagesOrderTypesSortOptions} from "../utils/selectors";
import {toastError, toastSuccess} from "../utils/toastHelper";

export const controller = "/documents/custom/template";

const DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST";
const DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS";
const DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR";

const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE";

const initialState = {
    list: [],
    shortList: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    fetchError: false,
    typeDict: {},
    clientUsageList: [],
    clientUsagePageData: {},
    clientUsageTotalCount: 0,
    clientUsageActionTypeDict: {},
    clientUsageOrderTypesDict: {},
    templateLogs: [],
    templateLogsPageData: {},
    templateLogsTotalCount: 0,
    additionEffectDict: {},
    pageData: {},
    frameContractTemplatesList: [],
    orderApplicationTemplatesList: [],
    actTemplatesList: [],
    otherDocumentsTemplatesList: [],
    eventLogs: [],
    eventLogsTotalCount: 0,
    eventLogsPageData: {},
    eventLogTypeDict: {},
    defaultTemplatesAggregation: {},
    replacementsForUnavailableList: [],
    progressReplacementsForUnavailable: false,
    customDocumentTemplatesDisabledForClient: false,
    aggregationShortTemplates: {},
    progressFile: false,
    templateLogsProgress: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS:
        return {
            ...state,
            defaultTemplatesAggregation: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS:
        return {
            ...state,
            clientUsageOrderTypesDict: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS:
        return {
            ...state,
            clientUsageActionTypeDict: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS: {
        const {results = [], totalCount = 0} = payload;

        return {
            ...state,
            clientUsageList: results,
            clientUsageTotalCount: totalCount,
        };
    }
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST:
        return {
            ...state,
            clientUsagePageData: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST:
        return {
            ...state,
            progressReplacementsForUnavailable: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR:
        return {
            ...state,
            progressReplacementsForUnavailable: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS:
        return {
            ...state,
            customDocumentTemplatesDisabledForClient: payload.customDocumentTemplatesDisabledForClient,
            clientDefaultFrameContractChanged: payload.clientDefaultFrameContractChanged,
            replacementsForUnavailableList: payload.result || [],
            progressReplacementsForUnavailable: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST:
        return {
            ...state,
            templateLogsPageData: payload,
            templateLogsProgress: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS:
        return {
            ...state,
            eventLogTypeDict: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS: {
        const {results, totalCount} = payload;

        return {
            ...state,
            templateLogs: results,
            templateLogsTotalCount: totalCount,
            templateLogsProgress: false,
        };
    }
    case DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS:
        const {
            fieldName = "shortList",
            aggregationFieldName,
            isAggregation,
        } = payload;

        if (isAggregation) {
            return {
                ...state,
                [fieldName]: {
                    ...state[fieldName],
                    [aggregationFieldName]: payload.results,
                },
            };
        }

        return {
            ...state,
            [fieldName]: payload.results,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR:
        return {
            ...state,
            progressFile: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS:
        return {
            ...state,
            progressFile: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST:
        return {
            ...state,
            progressFile: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_SUCCESS:
        return {
            ...state,
            typeDict: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_SUCCESS:
        return {
            ...state,
            additionEffectDict: payload,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS:
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR:
    case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS:
    case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR:
    case DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS:
    case DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR: {
        return {
            ...state,
            templateLogsProgress: false,
        };
    }
    case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST:
    case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST:
    case DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS:
        const {results, totalCount} = payload;

        return {
            ...state,
            list: results,
            totalCount,
            progress: false,
            fetchError: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST:
        const {
            pageNum,
            pageSize,
        } = payload;

        return {
            ...state,
            eventLogsPageData: {
                pageNum,
                pageSize,
            },
            progress: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS:
        const {
            results: eventLogs,
            totalCount: eventLogsTotalCount,
        } = payload;

        return {
            ...state,
            eventLogs,
            eventLogsTotalCount,
            progress: false,
            fetchErrorLogs: false,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR:
        return {
            ...state,
            progress: false,
            fetchError: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR:
        return {
            ...state,
            progress: false,
            fetchErrorLogs: true,
        };
    case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export const getDocumentCustomTemplatePage = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST,
        payload,
    };
};

export const deleteDocumentCustomTemplate = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST,
        payload,
    };
};

export const addDocumentCustomTemplate = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateShortDefaultAggregation = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateFileById = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateLogs = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST,
        payload,
    };
};

export const updateDocumentCustomTemplate = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST,
        payload,
    };
};

export const updateDocumentCustomTemplateClientUsage = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateClientUsagePage = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateClientUsageActionTypeDict = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateClientUsageOrderTypesDict = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST,
        payload,
    };
};

// Сделать шаблон по умолчанию у клиента
export const setDefaultDocumentsCustomTemplateById = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateShortDefault = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateAdditionEffectDict = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateTypeDict = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateShortActual = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST,
        payload,
    };
};

export const updateDocumentCustomTemplateStore = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE,
        payload,
    };
};

export const validateDocumentCustomTemplateFields = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST,
        payload,
    };
};

export const generateDocumentCustomTemplatePdf = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST,
        payload,
    };
};

export const getDocumentsCustomTemplateEventLogList = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST,
        payload,
    };
};

export const getDocumentsCustomTemplateReplacementsForUnavailableList = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST,
        payload,
    };
};

export const getDocumentsCustomTemplateEventLogTypeDict = () => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST,
    };
};

export const documentsCustomTemplateSelector = state => state.documentsCustomTemplate;

export const documentCustomTemplateTotalPagesSelector = createSelector(documentsCustomTemplateSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const documentCustomTemplateTotalCountSelector = createSelector(documentsCustomTemplateSelector, ({totalCount}) => totalCount);
export const documentCustomTemplateListSelector = createSelector(documentsCustomTemplateSelector, ({list}) => list);
export const documentCustomTemplateProgressSelector = createSelector(documentsCustomTemplateSelector, ({progress}) => progress);
export const documentCustomTemplateProgressActionSelector = createSelector(documentsCustomTemplateSelector, ({progressAction}) => progressAction);
export const documentCustomTemplateFetchErrorSelector = createSelector(documentsCustomTemplateSelector, ({fetchError}) => fetchError);
export const documentCustomTemplateAdditionEffectDictSelector = createSelector(documentsCustomTemplateSelector, ({additionEffectDict}) => additionEffectDict);
export const documentCustomTemplateTypeDictSelector = createSelector(documentsCustomTemplateSelector, ({typeDict}) => typeDict);
export const documentCustomTemplateProgressFileSelector = createSelector(documentsCustomTemplateSelector, ({progressFile}) => progressFile);
export const documentCustomTemplateShortListSelector = createSelector(documentsCustomTemplateSelector, ({shortList}) => shortList);
export const documentCustomTemplateShortListOptionsSelector = createSelector(documentsCustomTemplateSelector, ({shortList}) => {
    return shortList.map(item => {
        return {
            key: item.id,
            value: item.id,
            text: item.name,
        };
    });
});
export const documentCustomTemplateAggregationShortTemplatesSelector = createSelector(documentsCustomTemplateSelector, ({aggregationShortTemplates}) => {
    return Object.keys(aggregationShortTemplates).reduce((accum, key) => {
        accum[key] = aggregationShortTemplates[key].map(item => {
            return {
                key: item.id,
                value: item.id,
                text: item.name,
            };
        });

        return accum;
    }, {});
});
export const documentCustomTemplateEventLogTypeDictSelector = createSelector(documentsCustomTemplateSelector, ({eventLogTypeDict}) => eventLogTypeDict);
export const documentCustomTemplateEventLogTypeOptionsSelector = createSelector(documentsCustomTemplateSelector, ({eventLogTypeDict}) => dictionaryToOptions(eventLogTypeDict));
export const documentCustomTemplateLogsTotalPagesSelector =
    createSelector(documentsCustomTemplateSelector, ({templateLogsTotalCount, templateLogsPageData: {pageSize = 0}}) => getTotalPages(templateLogsTotalCount, pageSize));
export const documentCustomTemplateLogsTotalCountSelector = createSelector(documentsCustomTemplateSelector, ({templateLogsTotalCount}) => templateLogsTotalCount);
export const documentCustomTemplateLogsListSelector = createSelector(documentsCustomTemplateSelector, ({templateLogs}) => templateLogs);
export const documentCustomTemplateClientUsageTotalPagesSelector =
    createSelector(documentsCustomTemplateSelector, ({clientUsageTotalCount, clientUsagePageData: {pageSize = 0}}) => getTotalPages(clientUsageTotalCount, pageSize));
export const documentCustomTemplateClientUsageTotalCountSelector = createSelector(documentsCustomTemplateSelector, ({clientUsageTotalCount}) => clientUsageTotalCount);
export const documentCustomTemplateClientUsageListSelector = createSelector(documentsCustomTemplateSelector, ({clientUsageList}) => clientUsageList);
export const documentCustomTemplateClientUsagesActionTypeDictSelector = createSelector(documentsCustomTemplateSelector, ({clientUsageActionTypeDict}) => clientUsageActionTypeDict);

export const documentCustomTemplateClientUsageOrderTypesSortOptionsSelector = createSelector(
    documentsCustomTemplateSelector,
    ({clientUsageOrderTypesDict}) => getClientUsagesOrderTypesSortOptions(clientUsageOrderTypesDict),
);
export const frameContractTemplatesListSelector = createSelector(documentsCustomTemplateSelector, ({frameContractTemplatesList}) => frameContractTemplatesList);
export const orderApplicationTemplatesListSelector = createSelector(documentsCustomTemplateSelector, ({orderApplicationTemplatesList}) => orderApplicationTemplatesList);
export const actTemplatesListSelector = createSelector(documentsCustomTemplateSelector, ({actTemplatesList}) => actTemplatesList);
export const frameContractTemplatesOptionsSelector = createSelector(documentsCustomTemplateSelector, ({frameContractTemplatesList}) => {
    return frameContractTemplatesList.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name,
    }));
},
);
export const orderApplicationTemplatesOptionsSelector = createSelector(documentsCustomTemplateSelector, ({orderApplicationTemplatesList}) => {
    return orderApplicationTemplatesList.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name,
    }));
},
);
export const actTemplatesOptionsSelector = createSelector(documentsCustomTemplateSelector, ({actTemplatesList}) => {
    return actTemplatesList.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name,
    }));
},
);
export const otherDocumentsTemplatesOptionsSelector = createSelector(documentsCustomTemplateSelector, ({otherDocumentsTemplatesList}) => {
    return otherDocumentsTemplatesList.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name,
    }));
},
);
export const documentCustomTemplateEventLogListSelector = createSelector(documentsCustomTemplateSelector, ({eventLogs}) => eventLogs);
export const documentCustomTemplateEventLogFetchErrorListSelector = createSelector(documentsCustomTemplateSelector, ({fetchErrorLogs}) => fetchErrorLogs);
export const documentCustomTemplateDefaultAggregationSelector = createSelector(documentsCustomTemplateSelector, ({defaultTemplatesAggregation}) => defaultTemplatesAggregation);
export const documentCustomTemplateEventLogTotalCountSelector =     createSelector(documentsCustomTemplateSelector, ({eventLogsTotalCount}) => eventLogsTotalCount);
export const documentCustomTemplateEventLogTotalPagesSelector =
    createSelector(documentsCustomTemplateSelector, ({eventLogsTotalCount, eventLogsPageData: {pageSize = 0}}) => getTotalPages(eventLogsTotalCount, pageSize));
export const documentCustomTemplateReplacementsForUnavailableSelector = createSelector(documentsCustomTemplateSelector, ({replacementsForUnavailableList}) =>
    replacementsForUnavailableList);
export const documentCustomTemplateProgressReplacementsForUnavailableSelector = createSelector(documentsCustomTemplateSelector, ({progressReplacementsForUnavailable}) =>
    progressReplacementsForUnavailable);
export const documentCustomTemplateLogsProgressSelector = createSelector(documentsCustomTemplateSelector, ({templateLogsProgress}) => templateLogsProgress);

// POST /api/documents/custom/template/page
// Получение страницы шаблонов документов
export const getDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/delete/{id}
// Удаление шаблона документов
export const deleteDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;
        const result = yield request.post(`${controller}/delete/${id}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Шаблон успешно удален");

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/add
// Добавление шаблона документов
export const addDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            requestData,
        } = payload;
        const result = yield request.post(`${controller}/add`, requestData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR, payload: error});
    }
};

// /api/documents/custom/template/addition-effect/dict
// Получение словаря эффекта добавления версии документа
export const getDocumentsCustomTemplateAdditionEffectDictSaga = function* () {
    try {
        const result = yield request.post(`${controller}/addition-effect/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_ERROR, payload: error});
    }
};

// /api/documents/custom/template/document-type/dict
// Получение словаря типа документа
export const getDocumentsCustomTemplateTypeDictSaga = function* () {
    try {
        const result = yield request.post(`${controller}/document-type/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_ERROR, payload: error});
    }
};

// /api/documents/custom/template/short/page
// Получение страницы укороченных моделей шаблонов документов
export const getDocumentsCustomTemplateShortPageSaga = function* ({payload}) {
    try {
        const {
            isAggregation,
            aggregationFieldName,
            fieldName,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/short/page`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS,
            payload: {
                ...result,
                fieldName,
                aggregationFieldName,
                isAggregation,
            },
        });
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/update
// Обновление шаблона документов
export const updateDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            requestData,
        } = payload;
        const result = yield request.post(`${controller}/update`, requestData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/validate
// Проверка валидности шаблона документов перед загрузкой - в контексте полей шаблона
export const validateDocumentsCustomTemplatePageSaga = function* ({payload}) {
    const {
        onSuccess,
        onError,
        requestData,
    } = payload;

    try {
        const result = yield request.post(`${controller}/validation/fields`, requestData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS, payload: result});
    } catch (error) {
        onError();
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR, payload: error});
    }
};

// /api/documents/custom/template/instance/log/page
// Получение страницы лога шаблонов документов
export const getDocumentsCustomTemplateLogsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/instance/log/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/validation/pdf
// Проверка валидности шаблона документов перед загрузкой - в контексте генерации PDF файла
export const generateDocumentsCustomTemplatePdfSaga = function* ({payload}) {
    const {
        onError,
        requestData,
    } = payload;

    const myInit = {
        mode: "cors",
        cache: "default",
        method: "POST",
        body: requestData,
        headers: {
            Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
        },
    };

    try {
        const response = yield fetch(`/api${controller}/validation/pdf`, myInit);

        // обработка ошибки
        if (response.status === 200 && response.headers.get("Content-Type") && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                onError && onError();

                if (errorMessage) {
                    toastError(errorMessage);
                } else {
                    toastError(data);
                }

                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        yield downloadBlob(blob, response.headers);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS});
    } catch (error) {
        onError();
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR, payload: error});
    }
};

// Получить файл шаблона по ссылке
export const getDocumentCustomTemplateFileByIdSaga = function* ({payload}) {
    const {
        id,
        onSuccess,
        name,
        downloadLink,
    } = payload;

    try {
        const response = yield request.getFile(downloadLink, {params: id});

        const blob = yield response.blob();

        const file = new File([blob], name, {type: blob.type});

        onSuccess(file);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR, payload: error});
    }
};

// POST /api/documents/custom/template/client-usage/page
// Получение страницы клиентов, с обогащением, использующих заданный шаблон
export const getDocumentsCustomTemplateClientUsagePageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/client-usage/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/client-usage/update
// Обновление списка доступа компаний к конкретному шаблону
export const updateDocumentsCustomTemplateClientUsageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            changed,
        } = payload;
        const result = yield request.post(`${controller}/client-usage/update`, changed);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR, payload: error});
    }
};

// /api/documents/custom/template/client-usage/page/actions/type/dict
// Получение словаря типа события лога
export const getDocumentsCustomTemplateClientUsageDictSaga = function* () {
    try {
        const result = yield request.post(`${controller}/client-usage/page/actions/type/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR, payload: error});
    }
};

// /api/documents/custom/template/client-usage/page/order-types/dict
// Получение словаря типа сортировки
export const getDocumentsCustomTemplateClientUsageOrderTypeDictSaga = function* () {
    try {
        const result = yield request.post(`${controller}/client-usage/page/order-types/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR, payload: error});
    }
};

// /api/documents/custom/template/log/event/page
//Получение страницы логов кастомных документов, с обогащением
export const getDocumentsCustomTemplateEventLogListSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}/log/event/page`, data);

        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR, payload: error});
    }
};

//POST
///api/documents/custom/template/log/event/type/dict
//Получение словаря типа события

export const getDocumentsCustomTemplateEventLogTypeDictSaga = function* () {
    try {

        const result = yield request.post(`${controller}/log/event/type/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR, payload: error});
    }
};

// /api/documents/custom/template/set-default/{id}
// Удаление шаблона документов
export const setDefaultDocumentsCustomTemplateByIdSaga = function* ({payload}) {
    try {
        const {
            id,
            clientId,
            onSuccess,
        } = payload;
        const result = yield request.post(`${controller}/set-default/${clientId}/${id}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR, payload: error});
    }
};

// POST /api/documents/custom/template/short/default
// Получение актуальной укороченной модели шаблонов документов
export const getDocumentsCustomTemplateShortDefaultSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/short/default`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR, payload: error});
    }
};

// POST /api/documents/custom/template/short/default/aggregation
// Получение модели, агрегирующей укороченные модели актуальных шаблонов документов
export const getDocumentsCustomTemplateShortDefaultAggregation = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/short/default/aggregation`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR, payload: error});
    }
};

// POST /api/documents/custom/template/replacements-for-unavailable/list
// Получение списка шаблонов, заменяющих теперь недоступные шаблоны, подходящие под критерию
export const getDocumentsCustomTemplateReplacementsForUnavailableListSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/replacements-for-unavailable/list`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST, getDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST, deleteDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST, addDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_ADDITION_EFFECT_DICT_REQUEST, getDocumentsCustomTemplateAdditionEffectDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_TYPE_DICT_REQUEST, getDocumentsCustomTemplateTypeDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST, getDocumentsCustomTemplateShortPageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST, updateDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST, validateDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST, getDocumentsCustomTemplateLogsSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST, generateDocumentsCustomTemplatePdfSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST, getDocumentCustomTemplateFileByIdSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST, getDocumentsCustomTemplateClientUsagePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST, updateDocumentsCustomTemplateClientUsageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST, getDocumentsCustomTemplateClientUsageDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST, getDocumentsCustomTemplateClientUsageOrderTypeDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST, setDefaultDocumentsCustomTemplateByIdSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST, getDocumentsCustomTemplateEventLogListSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST, getDocumentsCustomTemplateEventLogTypeDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST, getDocumentsCustomTemplateShortDefaultSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST, getDocumentsCustomTemplateShortDefaultAggregation),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST, getDocumentsCustomTemplateReplacementsForUnavailableListSaga),
    ]);
}