import {
    EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_MAILING,
    EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_SMART_LINK,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_ERROR,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_REQUEST,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_SUCCESS,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_SUCCESS,
} from "./actions";

export const getEventsContractorInitialState = () => {
    return {
        aggregatedParamsByMailing: {},
        aggregatedParamsByMailingProgress: false,
        aggregatedParamsBySmartLink: {},
        aggregatedParamsBySmartLinkProgress: false,
    };
};

const initialState = getEventsContractorInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_REQUEST:
        return {
            ...state,
            aggregatedParamsByMailingProgress: true,
        };
    case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_SUCCESS:
        return {
            ...state,
            aggregatedParamsByMailingProgress: false,
            aggregatedParamsByMailing: payload,
        };
    case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_ERROR:
        return {
            ...state,
            aggregatedParamsByMailingProgress: false,
        };
    case EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_MAILING:
        return {
            ...state,
            aggregatedParamsByMailingProgress: initialState.aggregatedParamsByMailingProgress,
            aggregatedParamsByMailing: initialState.aggregatedParamsByMailing,
        };
    case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST:
        return {
            ...state,
            aggregatedParamsBySmartLinkProgress: true,
        };
    case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_SUCCESS:
        return {
            ...state,
            aggregatedParamsBySmartLinkProgress: false,
            aggregatedParamsBySmartLink: payload,
        };
    case EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR:
        return {
            ...state,
            aggregatedParamsBySmartLinkProgress: false,
        };
    case EVENTS_CONTRACTOR_CLEAR_AGGREGATED_PARAMS_BY_SMART_LINK:
        return {
            ...state,
            aggregatedParamsBySmartLinkProgress: initialState.aggregatedParamsBySmartLinkProgress,
            aggregatedParamsBySmartLink: initialState.aggregatedParamsBySmartLink,
        };
    default:
        return state;
    }
};