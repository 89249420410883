import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {CHANGES_NEW_MAIL_ATTRIBUTE_NAME} from "../containers/contractor/contractor-event-log/utils/getLabels";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions, sortOptionsAlphabetically} from "../utils/objectHelper";
import request from "../utils/postman";

const controller = "/contractors/log/event";
//*  TYPES  *//

const CONTRACTOR_EVENT_LOG_PAGE_REQUEST = "CONTRACTOR_EVENT_LOG_PAGE_REQUEST";
const CONTRACTOR_EVENT_LOG_PAGE_SUCCESS = "CONTRACTOR_EVENT_LOG_PAGE_SUCCESS";
const CONTRACTOR_EVENT_LOG_PAGE_ERROR = "CONTRACTOR_EVENT_LOG_PAGE_ERROR";

const CONTRACTOR_EVENT_LOG_PD_PAGE_REQUEST = "CONTRACTOR_EVENT_LOG_PD_PAGE_REQUEST";
const CONTRACTOR_EVENT_LOG_PD_PAGE_SUCCESS = "CONTRACTOR_EVENT_LOG_PD_PAGE_SUCCESS";
const CONTRACTOR_EVENT_LOG_PD_PAGE_ERROR = "CONTRACTOR_EVENT_LOG_PD_PAGE_ERROR";

const CONTRACTOR_EVENT_DICT_REQUEST = "CONTRACTOR_EVENT_DICT_REQUEST";
const CONTRACTOR_EVENT_DICT_SUCCESS = "CONTRACTOR_EVENT_DICT_SUCCESS";
const CONTRACTOR_EVENT_DICT_ERROR = "CONTRACTOR_EVENT_DICT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    contractorEventLogList: [],
    contractorEventLogPdList: [],
    contractorEventLogPdPageData: {},
    contractorEventLogPageData: {},
    contractorEventLogTotalCount: 0,
    contractorEventLogPdTotalCount: 0,
    contractorEventDict: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_EVENT_LOG_PAGE_REQUEST:
        return {
            ...state,
            contractorEventLogPageData: payload,
        };
    case CONTRACTOR_EVENT_LOG_PD_PAGE_REQUEST:
        return {
            ...state,
            contractorEventLogPdPageData: payload,
        };
    case CONTRACTOR_EVENT_DICT_SUCCESS:
        return {
            ...state,
            contractorEventDict: payload,
        };
    case CONTRACTOR_EVENT_LOG_PAGE_SUCCESS:
        const {
            results: contractorEventLogList,
            totalCount: contractorEventLogTotalCount,
        } = payload;

        return {
            ...state,
            contractorEventLogTotalCount,
            contractorEventLogList,
        };
    case CONTRACTOR_EVENT_LOG_PD_PAGE_SUCCESS:
        const {
            results: contractorEventLogPdList,
            totalCount: contractorEventLogPdTotalCount,
        } = payload;

        return {
            ...state,
            contractorEventLogPdTotalCount,
            contractorEventLogPdList,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorEventLogPage(payload) {
    return {
        type: CONTRACTOR_EVENT_LOG_PAGE_REQUEST,
        payload,
    };
}

export function getContractorEventLogPdPage(payload) {
    return {
        type: CONTRACTOR_EVENT_LOG_PD_PAGE_REQUEST,
        payload,
    };
}

export function getContractorEventDict() {
    return {
        type: CONTRACTOR_EVENT_DICT_REQUEST,
    };
}

//*  SELECTORS  *//

const contractorEventLogSelector = state => state.contractorEventLog;
export const contractorEventLogTotalPagesSelector = createSelector(contractorEventLogSelector, ({contractorEventLogTotalCount, contractorEventLogPageData: {pageSize = 0}}) => getTotalPages(contractorEventLogTotalCount, pageSize));
export const contractorEventLogListSelector = createSelector(contractorEventLogSelector, ({contractorEventLogList}) => {
    return contractorEventLogList.map(item => {
        if (item.changes) {
            return {
                ...item,
                isNewEmailEvent: item.changes.some(item => item.attribute.includes(CHANGES_NEW_MAIL_ATTRIBUTE_NAME)),
                changes: {
                    newValues: item.changes.map(item => {
                        return {
                            name: item.attribute,
                            value: item.newValue,
                        };
                    }),
                    oldValues: item.changes.map(item => {
                        return {
                            name: item.attribute,
                            value: item.oldValue,
                        };
                    }),
                },
            };
        }

        return {
            ...item,
            changes: null,
        };
    });
});
export const contractorEventLogTotalCountSelector = createSelector(contractorEventLogSelector, ({contractorEventLogTotalCount}) => contractorEventLogTotalCount);
export const contractorEventDictSelector = createSelector(contractorEventLogSelector, ({contractorEventDict}) => contractorEventDict);
export const contractorEventDictOptionsSelector = createSelector(contractorEventLogSelector, ({contractorEventDict}) => {
    const list = dictionaryToOptions(contractorEventDict);

    return sortOptionsAlphabetically(list);
});
export const contractorEventLogPdListSelector = createSelector(contractorEventLogSelector, ({contractorEventLogPdList}) => contractorEventLogPdList);
export const contractorEventLogPdTotalPagesSelector = createSelector(contractorEventLogSelector, ({contractorEventLogPdTotalCount, contractorEventLogPdPageData: {pageSize = 0}}) => getTotalPages(contractorEventLogPdTotalCount, pageSize));
export const contractorEventLogPdTotalCountSelector = createSelector(contractorEventLogSelector, ({contractorEventLogPdTotalCount}) => contractorEventLogPdTotalCount);
//*  SAGA  *//


const getContractorEventLogPageSaga = function* ({payload}) {
    const {
        getResult = () => {
        },
        ...data
    } = payload;
    try {

        const {errorMessage, ...result} = yield request.post(`${controller}/page/rich`, data);

        yield getResult(errorMessage);

        if (errorMessage) {
            console.error(errorMessage);

            return {
                done: true,
            };
        }


        yield put({type: CONTRACTOR_EVENT_LOG_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield getResult();
        yield put({type: CONTRACTOR_EVENT_LOG_PAGE_ERROR, payload: error});
    }
};

const getContractorEventDictSaga = function* ({}) {
    try {
        const {errorMessage, ...result} = yield request.post(`${controller}/dict`);


        if (errorMessage) {
            console.error(errorMessage);

            return {
                done: true,
            };
        }


        yield put({type: CONTRACTOR_EVENT_DICT_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: CONTRACTOR_EVENT_DICT_ERROR, payload: error});
    }
};

//POST
// /api/contractors/pd/log/event/getRichPage

const getContractorEventLogPdPageSaga = function* ({payload}) {
    const {
        getResult = () => {
        },
        ...data
    } = payload;
    try {

        const {errorMessage, ...result} = yield request.post("/contractors/pd/log/event/getRichPage", data);

        yield getResult(errorMessage);

        if (errorMessage) {
            console.error(errorMessage);

            return {
                done: true,
            };
        }


        yield put({type: CONTRACTOR_EVENT_LOG_PD_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield getResult();
        yield put({type: CONTRACTOR_EVENT_LOG_PD_PAGE_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_EVENT_LOG_PAGE_REQUEST, getContractorEventLogPageSaga),
        takeEvery(CONTRACTOR_EVENT_DICT_REQUEST, getContractorEventDictSaga),
        takeEvery(CONTRACTOR_EVENT_LOG_PD_PAGE_REQUEST, getContractorEventLogPdPageSaga),
    ]);
}
