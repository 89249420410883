import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdTaskCardSelector = state => state.bff.crowd.taskCard;
export const bffCrowdTasksCardRichSelector = createSelector(bffCrowdTaskCardSelector, ({card}) => card);
export const bffCrowdTasksCardProgressSelector = createSelector(bffCrowdTaskCardSelector, ({progress}) => progress);
export const bffCrowdTasksCardProgressActionSelector = createSelector(bffCrowdTaskCardSelector, ({progressAction}) => progressAction);
export const bffCrowdTasksCardContractorsListSelector = createSelector(bffCrowdTaskCardSelector, ({list}) => list);
export const bffCrowdTasksCardContractorsTotalCountSelector = createSelector(bffCrowdTaskCardSelector, ({totalCount}) => totalCount);
export const bffCrowdTasksCardContractorsTotalPagesSelector = createSelector(bffCrowdTaskCardSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdTasksCardProgressListSelector = createSelector(bffCrowdTaskCardSelector, ({progressList}) => progressList);
export const bffCrowdTasksCardWorkingSelector = createSelector(bffCrowdTaskCardSelector, ({workingCard}) => workingCard);
export const bffCrowdTasksCardWorkingProgressSelector = createSelector(bffCrowdTaskCardSelector, ({workingCardProgress}) => workingCardProgress);
export const bffCrowdTasksLogListSelector = createSelector(bffCrowdTaskCardSelector, ({logList}) => logList);
export const bffCrowdTasksLogTotalCountSelector = createSelector(bffCrowdTaskCardSelector, ({logTotalCount}) => logTotalCount);
export const bffCrowdTasksLogTotalPagesSelector = createSelector(bffCrowdTaskCardSelector,
    ({logTotalCount, logPageData: {pageSize = 0}}) => getTotalPages(logTotalCount, pageSize),
);
export const bffCrowdTasksLogProgressSelector = createSelector(bffCrowdTaskCardSelector, ({logProgress}) => logProgress);
export const bffCrowdTasksContractorsCountsSelector = createSelector(bffCrowdTaskCardSelector, ({contractorsCounts}) => contractorsCounts);

export const bffCrowdTasksErrorSelector = createSelector(bffCrowdTaskCardSelector, ({error}) => error);
export const bffCrowdTasksActionPageDataSelector = createSelector(bffCrowdTaskCardSelector, ({actionPageData}) => actionPageData);