import {useMemo} from "react";

import {CREATE_ACTS_TYPE} from "../../../registryActsType";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {ORDER_AMOUNT_CALCULATION_METHOD, ORDER_WORK_REPORT_TYPE} from "../../../../../constants/finance";

export function useGetRegistryNewInitFormValues(props) {
    const {
        registryObj,
        clientInfo,
        objectList,
        clientsSettingsPayments,
    } = props;

    const {
        registryPaymentsAvailable,
        civilRegistryPaymentsAvailable,
        individualRegistryPaymentsAvailable,
        documentTablesEnabled: clientDocumentTablesEnabled,
    } = clientInfo;

    const {
        smzRegistry,
        civilRegistry,
        individualRegistry,
    } = clientsSettingsPayments;

    const {
        name,
        description,
        objectId,
        objectName,
        orderApplicationTemplateId,
        registryId,
        actOfAcceptanceOfWorkTemplateId,
        createActsOfAcceptanceOfWorkType,
        orderAmountCalculationMethod,
        documentTablesEnabled,
        creatorClientUserId,
        orderContractPaymentType,
        paymentMethod,
    } = registryObj;

    const initialOrderContractPaymentType  = useMemo(() => {
        if (registryPaymentsAvailable) {
            return ORDER_WORK_REPORT_TYPE.SMZ;
        }

        if (civilRegistryPaymentsAvailable) {
            return ORDER_WORK_REPORT_TYPE.CIVIL;
        }

        if (individualRegistryPaymentsAvailable) {
            return ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
        }

        return null;
    }, [clientInfo]);

    const initialPaymentMethod  = useMemo(() => {
        if (initialOrderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ) {
            return smzRegistry?.paymentMethod;
        }

        if (initialOrderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL) {
            return civilRegistry?.paymentMethod;
        }

        if (initialOrderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            return individualRegistry?.paymentMethod;
        }

        return null;
    }, [initialOrderContractPaymentType]);

    const isCreateActsNone = createActsOfAcceptanceOfWorkType === CREATE_ACTS_TYPE.NONE;
    const object = objectList.find((item) => {
        return item.objectId === objectId;
    });

    return {
        name: name ? name : "",
        description: description ? description : "",
        objectId: objectId ? objectId : "",
        projectId: object?.projectId ? object.projectId : undefined,
        valueObjectFilter: objectId ? objectName : "",
        orderApplicationTemplateId: orderApplicationTemplateId ? orderApplicationTemplateId : "",
        actOfAcceptanceOfWorkTemplateId: actOfAcceptanceOfWorkTemplateId ? actOfAcceptanceOfWorkTemplateId : "",
        creatorClientUserId: creatorClientUserId ? creatorClientUserId : "",
        orderContractPaymentType: orderContractPaymentType || initialOrderContractPaymentType,
        orderAmountCalculationMethod: orderAmountCalculationMethod || ORDER_AMOUNT_CALCULATION_METHOD.NET,
        isCreateActs: !isCreateActsNone,
        createActsOfAcceptanceOfWorkType: isCreateActsNone || !createActsOfAcceptanceOfWorkType ? CREATE_ACTS_TYPE.AUTOMATICALLY : createActsOfAcceptanceOfWorkType,
        documentTablesEnabled: registryId ? documentTablesEnabled : clientDocumentTablesEnabled,
        paymentMethod: paymentMethod || initialPaymentMethod,
    };
}