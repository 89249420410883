export const RECRUITMENT_VACANCY_LOG_TYPE = {
    CREATE_VACANCY: "CREATE_VACANCY",
    CREATE_VACANCY_FROM_JOB_BOARD: "CREATE_VACANCY_FROM_JOB_BOARD",
    CREATE_VACANCY_AT_JOB_BOARD: "CREATE_VACANCY_AT_JOB_BOARD",
    IMPORT_RESPONSES_FROM_JOB_BOARD: "IMPORT_RESPONSES_FROM_JOB_BOARD",
    UPDATE_VACANCY_PARAM: "UPDATE_VACANCY_PARAM",
    UPDATE_VACANCY_PARAM_FROM_JOB_BOARD: "UPDATE_VACANCY_PARAM_FROM_JOB_BOARD",
    UPDATE_VACANCY_PARAM_AT_JOB_BOARD: "UPDATE_VACANCY_PARAM_AT_JOB_BOARD",
    CLOSE_VACANCY: "CLOSE_VACANCY",
    CANCEL_PUBLICATION: "CANCEL_PUBLICATION",
    ARCHIVE_VACANCY: "ARCHIVE_VACANCY",
    UNARCHIVE_VACANCY: "UNARCHIVE_VACANCY",
    DELETE_VACANCY: "DELETE_VACANCY",
    ADD_CANDIDATE_TO_VACANCY: "ADD_CANDIDATE_TO_VACANCY",
};

export const RECRUITMENT_VACANCY_LOG_TYPE_DICT = {
    CREATE_VACANCY: "Создание вакансии на площадке вручную",
    CREATE_VACANCY_FROM_JOB_BOARD: "Создание вакансии на площадке через джоб-борд",
    CREATE_VACANCY_AT_JOB_BOARD: "Создание вакансии на джоб-борде через площадку",
    IMPORT_RESPONSES_FROM_JOB_BOARD: "Выгрузка откликов по вакансии из джоб-бордов на площадку",
    UPDATE_VACANCY_PARAM: "Обновление параметров вакансии на площадке вручную",
    UPDATE_VACANCY_PARAM_FROM_JOB_BOARD: "Обновление параметров вакансии на площадке через джоб-борд",
    UPDATE_VACANCY_PARAM_AT_JOB_BOARD: "Обновление параметров вакансии на джоб-борде через площадку",
    CLOSE_VACANCY: "Закрытие вакансии на площадке",
    ARCHIVE_VACANCY: "Перенос вакансии на площадке в архив",
    UNARCHIVE_VACANCY: "Восстановление вакансии на площадке из архива",
    DELETE_VACANCY: "Удаление вакансии с площадки",
    CREATE_VACANCY_FROM_XLS: "Создание вакансии на площадке через загрузку файла",
    ADD_CANDIDATE_TO_VACANCY: "Привязка кандидата к вакансии",
    NEW_PERSONAL_ACCESS: "Предоставления индивидуального доступа к вакансии",
    JOB_BOARD_ACCOUNT_CHANGED: "Изменение аккаунта вакансии от джоб-борда",
};

export const RECRUITMENT_VACANCY_LOG_FIELD_DICT = {
    title: "Название вакансии",
    description: "Описание вакансии",
    dateFrom: "Период работ (от)",
    dateTo: "Период работ (до)",
    publishDateFrom: "Период размещения (от)",
    publishDateTo: "Период размещения (до)",
    salary: "Зарплата",
    requirementIds: "Дополнительные требования",
    specialityId: "Вид деятельности",
    workAddressFiasId: "Город публикации вакансии",
    citizenships: "Гражданство",
    ageFrom: "Возраст (от)",
    ageTo: "Возраст (до)",
    gender: "Пол",
    recruiterId: "Рекрутер",
    funnelId: "Воронка статусов",
};