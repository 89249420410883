import React, {useEffect, useState} from "react";

import FilterButtonsV2 from "../../ActualComponents/FilterButtonsV2";
import NmEmptyPageV2 from "../../ActualComponents/NmEmptyPageV2";
import NmInputV2 from "../../ActualComponents/NmInputV2";
import NmListCard from "../../ActualComponents/NmList/Card";
import NmModal from "../../ActualComponents/NmModal";
import NmPagination from "../../ActualComponents/NmPagination";
import ApplyButtons from "../../ApplyButtons";
import CheckboxList from "../../CheckboxList";
import NmButton from "../../NmButton";
import NmTitle from "../../NmTitle";
import SelectionCountWithAction from "../../SelectionCountWithAction";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";

import bem from "../../../utils/bem";
import {getFullName, phoneFormat, removePhoneMask} from "../../../utils/stringFormat";
import {handleFormString} from "../../../utils/stringHelper";

import "./index.sass";

export const RecruitmentRecruitersAddForm = (props) => {
    const {
        addLoading,
        listLoading,
        listTotalCount,
        listTotalPages,
        list,
        onClose,
        onAdd,
        modalLoaderContent,
        modalHeader,
        isMultipleSelection,
        fetchList,
    } = props;

    const [_, element] = bem("recruitment-recruiters-add-form");

    const [clientUserId, setSelectedClientUserId] = useState();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card", 5);

    const {
        onClear,
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
    } = useFilter({
        initFilter: {
            name: "",
            position: "",
            email: "",
            phone: "",
        },
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        return {
            name: handleFormString(filter.name),
            phone: filter.phone ? removePhoneMask(filter.phone) : undefined,
            email: handleFormString(filter.email),
            position: handleFormString(filter.position),
        };
    }

    useEffect(() => {
        clearSelectedRows();
    }, [
        pageNum,
        pageSize,
    ]);

    useEffect(() => {
        fetchList({
            pageNum,
            pageSize,
            ...filterData,
        });
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const getRows = () => {
        return list.map((item) => {
            const {isSelected} = selectedList.find(_item => (_item.key === item.clientUserId)) || {};

            return {
                key: item.clientUserId,
                showCheckBox: isMultipleSelection,
                isSelected,
                dynamicRowClassName: isMultipleSelection && "pt-10 pt-md-3 pt-xxl-5",
                contentRow: (
                    <NmListCard
                        noDivider
                        isFixedActions
                        labels={[
                            {
                                label: "ФИО",
                                className: !isMultipleSelection ? element("label") : "",
                                text: getFullName(item.lastName, item.firstName, item.patronymic),
                            },
                            {label: "Должность", text: item.position || "-"},
                            {label: "Телефон", text: phoneFormat(item.phone)},
                            {label: "E-mail", text: item.email},
                        ]}
                        actions={!isMultipleSelection && getChooseButton({item})}
                        actionsClassName="d-none d-md-block"
                        otherContent={
                            !isMultipleSelection &&
                            <div className="d-block mt-2 d-md-none">
                                {getChooseButton({item, className: element("choose-button")})}
                            </div>
                        }
                    />
                ),
            };
        });
    };

    const getFilters = () => {
        return (
            <div className="row gx-4 mb-4">
                <div className="col-16 col-md-8">
                    <NmInputV2
                        label="ФИО"
                        size="lg"
                        placeholder="Введите ФИО"
                        onChange={onChangeFilter}
                        name="name"
                        value={filter.name }
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-0">
                    <NmInputV2
                        size="lg"
                        mask="+7 (999) 999 99 99"
                        name="phone"
                        value={filter.phone}
                        placeholder="+7"
                        label="Номер телефона"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-4">
                    <NmInputV2
                        size="lg"
                        label="Должность"
                        placeholder="Введите должность"
                        name="position"
                        onChange={onChangeFilter}
                        value={filter.position}
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-4">
                    <NmInputV2
                        size="lg"
                        label="E-mail"
                        placeholder="Введите e-mail"
                        name="email"
                        onChange={onChangeFilter}
                        value={filter.email}
                    />
                </div>
                <div className="col-16 col-md-8 mt-2 mt-md-4">
                    <FilterButtonsV2
                        onSearch={() => {
                            onSearch(filter);
                        }}
                        onClear={onClear}
                    />
                </div>
            </div>
        );
    };

    const getChooseButton = (props) => {
        const {
            item,
            className,
        } = props;

        return (
            <NmButton
                color="light-green"
                disabled={item.clientUserId === clientUserId}
                onClick={() => {
                    setSelectedClientUserId(item.clientUserId);
                }}
                className={className}
            >
                {item.clientUserId === clientUserId ? "Выбран" : "Выбрать"}
            </NmButton>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            loading={addLoading || listLoading}
            header={
                <NmTitle size="lg">
                    {modalHeader}
                </NmTitle>
            }
            loaderContent={modalLoaderContent}
            classNameContent="flex-column"
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={() => {
                        const dataForSubmit = isMultipleSelection ?
                            {
                                selectedIds: selectedList
                                    .filter(item => item.isSelected)
                                    .map(({key}) => key),
                            } :
                            {
                                clientUserId,
                            };

                        onAdd(dataForSubmit);
                    }}
                    disabled={isMultipleSelection ? !countSelected : !clientUserId}
                    submitBtnContent="Добавить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                />
            }
        >
            {getFilters()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2 isSearch={isSearch} /> :
                    <CheckboxList
                        rows={getRows()}
                        header={
                            isMultipleSelection &&
                            <SelectionCountWithAction
                                adaptiveLogic
                                count={countSelected}
                            />
                        }
                        onSelectedRows={isMultipleSelection && handleSelectedRows}
                    />
            }
            <NmPagination
                className="mt-4 mt-md-5 mt-xxl-7"
                totalCount={listTotalCount}
                pageSizes={[5, 10, 15]}
                pageNum={pageNum}
                totalPages={listTotalPages}
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onChangePagination={onPaginationChange}
            />
        </NmModal>
    );
};