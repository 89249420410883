import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import PaymentDataInfoEditForm from "./components/edit-form";

import {usePaymentDataInfoCardAction} from "./hooks/useAction";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {formatBankCardV2} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";
import {
    isUserFromNm,
    NM_OPERATOR,
} from "../../../../../constants/roles";

import {
    contractorCardSelector,
    getContractorPaymentDataSelector,
} from "../../../../../ducks/contractor";

const PaymentDataInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const role = ls(USER_ROLE);

    const card = useSelector(contractorCardSelector);
    const paymentData = useSelector(getContractorPaymentDataSelector);

    const {
        contractorId,
        bankRequisitesRequired,
        bankRequisites,
        paymentDetailsUpdatedDateTime,
    } = card;

    const {
        isOpenEditForm,
        toggleEditModal,
    } = usePaymentDataInfoCardAction({});

    const getIsEditable = () => {
        if (!isEditable) {
            return false;
        }

        return !([NM_OPERATOR].includes(role) && !isNullOrWhitespace(bankRequisites[CONTRACTOR_FIELD.RS]));
    };

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <PaymentDataInfoEditForm
                contractorId={contractorId}
                card={card}
                onClose={toggleEditModal}
            />
        );
    };

    const getBankRequisitesLabels = () => {
        if (!bankRequisitesRequired && !isUserFromNm(role)) {
            return [];
        }

        return [
            {label: "Номер расчетного счета", text: bankRequisites[CONTRACTOR_FIELD.RS]},
            {label: "Наименование банка", text: bankRequisites[CONTRACTOR_FIELD.BANK_NAME]},
            {label: "ИНН", text: bankRequisites[CONTRACTOR_FIELD.INN]},
            {label: "БИК", text: bankRequisites[CONTRACTOR_FIELD.BIC]},
            {label: "Адрес банка", text: bankRequisites[CONTRACTOR_FIELD.BANK_ADDRESS]},
            {label: "Корр. счет", text: bankRequisites[CONTRACTOR_FIELD.KS]},
        ];
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Платежные данные"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            accessEdit={getIsEditable()}
            lastUpdate={paymentDetailsUpdatedDateTime}
        >
            {renderEditForm()}
            <ContractorInfoCardContent
                labels={[
                    {
                        label: "Номер банковской карты",
                        text: formatBankCardV2(paymentData[CONTRACTOR_FIELD.BANK_CARD_NUMBER]),
                    },
                    ...getBankRequisitesLabels(),
                ]}
            />
        </NmMiniInfoCard>
    );
};

export default PaymentDataInfoCard;