import {useEffect, useState} from "react";

import {TASK_STATUS} from "../../../../../constants/task";

export function useImportTasks(tasks, groupId) {
    // const tasksImportContractorsGroup =  useSelector(tasksImportContractorsGroupSelector);

    const [importProgress, setProgress] = useState(false);

    useEffect(() => {
        if (tasks.find(({status, keys}) => [TASK_STATUS.IN_PROGRESS.VALUE, null].includes(status) && keys.includes(groupId))) {
            setProgress(true);
            return;
        }
        setProgress(false);
    }, [tasks]);

    return {importProgress};
}