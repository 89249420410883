import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as NoOnIcon} from "../../../../../images/no_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../../images/yes_on.svg";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {COLOR} from "../../../../../constants/color";
import {
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE,
    CONTRACTOR_STATUS,
} from "../../../../../constants/contractor";

import {
    contractorCardSelector,
    contractorProgressSelector,
    getRichContractorById,
    updateContractorRegistrationStatus,
} from "../../../../../ducks/contractor";

const options = [
    {
        key: CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED,
        value: CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED,
        text: (
            <div className="d-flex align-items-center">
                <YesOnIcon
                    className="me-2"
                    width={20}
                    height={20}
                    color={COLOR.PRIMARY_100}
                />
                Паспорт проверен
            </div>
        ),
    },
    {
        key: CONTRACTOR_STATUS.BASIC_REGISTRATION,
        value: CONTRACTOR_STATUS.BASIC_REGISTRATION,
        text: (
            <div className="d-flex align-items-center">
                <NoOnIcon
                    width={20}
                    height={20}
                    className="me-2"
                    color={COLOR.NEGATIVE_100}
                />
                Паспорт не проверен
            </div>
        ),
    },
];

const ContractorGeneralInfoRegistrationStatusChanging = (props) => {
    const {
        onClose,
    } = props;

    const [confirmData, setConfirmData] = useState({});

    const dispatch = useDispatch();

    const contractor = useSelector(contractorCardSelector);
    const progress = useSelector(contractorProgressSelector);

    const [registrationStatus, setRegistrationStatus] = useState(() => {
        if ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED].includes(contractor.registrationStatus)) {
            return CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED;
        }

        return CONTRACTOR_STATUS.BASIC_REGISTRATION;
    });

    const onSubmit = ({confirm = false}) => {
        dispatch(updateContractorRegistrationStatus({
            ignoreSemiRequiredFields: confirm,
            contractorId: contractor.contractorId,
            registrationStatus,
            onError: ({errorCode, errorMessage}) => {
                if (errorCode === CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE.SEMI_REQUIRED_PERSONAL_DATA_NOT_FILLED) {
                    setConfirmData({
                        content: errorMessage,
                        onConfirm: () => onSubmit({confirm: true}),
                        loading: progress,
                    });
                }
            },
            onSuccess: () => {
                dispatch(getRichContractorById(contractor.contractorId));

                onClose();

                if (registrationStatus === CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED) {
                    toastSuccess("Статус паспорта изменён на \"Паспорт проверен\"");

                    return;
                }

                toastSuccess("Статус паспорта изменён на \"Паспорт не проверен\"");
            },
        }));
    };

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
            loading,
        } = confirmData;

        return (
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                loading={loading}
                isOnlyConfirm
            />
        );
    };

    return (
        !isEmpty(confirmData) ?
            renderConfirmModal() :
            <NmModal
                size="sm"
                header={
                    <NmTitle size="lg">
                        Статус проверки паспорта
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submit={onSubmit}
                        submitBtnContent="Сохранить"
                        disabled={progress}
                    />
                }
                onClose={onClose}
            >
                <NmDropdownV2
                    size="xl"
                    placeholder="Выберите значение"
                    onChange={(event, {value}) => {
                        setRegistrationStatus(value);
                    }}
                    value={registrationStatus}
                    options={options}
                />
            </NmModal>
    );
};

export default ContractorGeneralInfoRegistrationStatusChanging;