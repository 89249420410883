import {DEPOSIT_SUB_PAGE} from "../index";

import {STATUS_PAYMENT_FOR_DEPOSIT_PAYMENTS_IN_PROCESS_REPORT_STATUS_FILTER} from "../../../../constants/status";

export const getDepositRegistryStatusFilters = (params) => {
    const {
        subPage,
        reportStatusFilter,
    } = params;

    const registryStatusFilter = params.registryStatusFilter?.length ? params.registryStatusFilter : undefined;

    if (![DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS].includes(subPage)) {
        return {
            reportStatusFilter,
            registryStatusFilter,
        };
    }

    const status = reportStatusFilter[0];

    const isFiltered = status && reportStatusFilter.length === 1;

    if (!isFiltered) {
        return {
            reportStatusFilter,
            registryStatusFilter,
        };
    }

    const {
        REGISTRY_STATUS_FILTER,
        REPORT_STATUS_FILTER,
    } = STATUS_PAYMENT_FOR_DEPOSIT_PAYMENTS_IN_PROCESS_REPORT_STATUS_FILTER;

    if (REGISTRY_STATUS_FILTER.includes(status)) {
        return {
            reportStatusFilter: null,
            registryStatusFilter: [status],
        };
    }

    if (REPORT_STATUS_FILTER.includes(status)) {
        return {
            reportStatusFilter: [status],
            registryStatusFilter: null,
        };
    }
};