import React from "react";
import {useTranslation} from "react-i18next";

import Tabs from "../ActualComponents/Tabs";
import NmPage from "../NmPage";
import NmTitle from "../NmTitle";

import "./style.sass";

const Instructions = (props) => {
    const {
        noPadding,
        pages,
        tabs,
        isVisibleTabs = true,
    } = props;

    const {t} = useTranslation();

    return (
        <NmPage
            noPadding={noPadding}
            className="instruction"
            header={
                <NmTitle size="xl">
                    {t("instruction.admin-card-title")}
                </NmTitle>
            }
        >
            {
                isVisibleTabs &&
                <Tabs
                    secondary={true}
                    className="advertisement-card__tabs"
                    panes={tabs}
                />
            }
            {pages}
        </NmPage>
    );
};

export default Instructions;
