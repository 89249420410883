import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearRecruitmentAccessControlRecruitersGroupsStore,
    getPageRecruitmentAccessControlRecruitersGroups,
} from "../../../../../ducks/bff/recruitment/access-control/recruiters-groups/actionsCreators";

export const useRecruitmentAccessControlRecruitersGroupsFetchList = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentAccessControlRecruitersGroupsStore());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const fetchList = () => {
        dispatch(getPageRecruitmentAccessControlRecruitersGroups({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};