import React, {useRef} from "react";
import {useMedia} from "react-media";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../components/CheckboxList";
import NmLoader from "../../../../components/NmLoader";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {
    bffRecruitmentVacancyCandidatesListSelector,
    bffRecruitmentVacancyCandidatesPageDataSelector,
    bffRecruitmentVacancyCandidatesProgressSelector,
    bffRecruitmentVacancyCandidatesTotalCountSelector,
    bffRecruitmentVacancyCandidatesTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {fileStoreRecruitmentAvatarBase64ImagesSelector} from "../../../../ducks/fileStoreRecruitment";
import {RecruitmentVacancyResponseListCard} from "../../vacancies/components/list-card";
import {RecruitmentCandidatesCard} from "../card";
import {RecruitmentCandidatesEdit} from "../edit";

import {useInfiniteScrollPagination} from "../../../../hooks/useInfiniteScrollPagination";
import {useRecruitmentCandidatesActions} from "./hooks/useActions";
import {useRecruitmentCandidatesFetch} from "./hooks/useFetch";
import {useRecruitmentCandidatesFilters} from "./hooks/useFilters";

import {
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO,
} from "../../../../constants/links";

import {history} from "../../../../store/configureStore";

const RecruitmentCandidates = (props) => {
    const {
        match,
    } = props;
    const {
        clientId,
        id: candidateId,
    } = match.params;

    const totalCount = useSelector(bffRecruitmentVacancyCandidatesTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentVacancyCandidatesTotalPagesSelector);
    const list = useSelector(bffRecruitmentVacancyCandidatesListSelector);
    const userAvatarDict = useSelector(fileStoreRecruitmentAvatarBase64ImagesSelector);
    const progressList = useSelector(bffRecruitmentVacancyCandidatesProgressSelector);
    const {isLoadMore} = useSelector(bffRecruitmentVacancyCandidatesPageDataSelector);

    const $contentRef = useRef(null);

    const isMobile = useMedia({query: {maxWidth: 767}});

    const {
        headerMediaControls,
        isOpenEdit,
        onCloseEdit,
    } = useRecruitmentCandidatesActions({});

    const {
        filtersBase,
        onClear,
        onSearch,
        isSearch,
        filterData,
    } = useRecruitmentCandidatesFilters({
    });

    const {
        infiniteScrollProps,
        setPageData,
        pageData,
    } = useInfiniteScrollPagination({
        totalPages,
        fetchCallback: ({pageNum}) => {
            fetchList({
                ...filterData,
                pageNum,
            });
        },
    });

    const {
        fetchList,
    } = useRecruitmentCandidatesFetch({
        clientId,
        $contentRef,
        setPageData,
        pageData,
    });

    const onSubmitFilter = (values) => {
        onSearch(values);
        fetchList({
            ...values,
            isLoadMore: false,
        });
        setPageData({
            pageNum: 1,
        });
    };

    const onClearFilter = () => {
        onClear();
        fetchList({
            isLoadMore: false,
        });
        setPageData({
            pageNum: 1,
        });
    };

    const onClickCard = (id) => {
        history.push(LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO.replace(":id", id).replace(":clientId", match.params.clientId));
    };

    const getRows = () => {
        return list.map(item => {
            const isActive = item.candidateId === match.params.id;

            return {
                key: item.candidateId,
                isActive,
                contentRow: (
                    <RecruitmentVacancyResponseListCard
                        isActive={isActive}
                        candidateId={item.candidateId}
                        base64={userAvatarDict[item.candidateId]}
                        onClickCard={() => {
                            if (isActive) {
                                return;
                            }

                            onClickCard(item.candidateId);
                        }}
                        name={item.name}
                        age={item.age}
                        lastOrganisation={item.lastOrganisation}
                        lastPosition={item.lastPosition}
                    />
                ),
            };
        });
    };

    const getRightContent = () => {
        return (
            <RecruitmentCandidatesCard
                fetchList={() => {
                    fetchList({
                        ...filterData,
                        isLoadMore: false,
                    });
                }}
                candidateId={candidateId}
                clientId={match.params.clientId}
            />
        );
    };

    const isShowRightContent = Boolean(candidateId);

    const getContent = () => {
        if (!list.length && progressList) {
            return (
                <NmLoader
                    inverted={true}
                    active={true}
                />
            );
        }

        if (!list.length) {
            return (
                <NmEmptyPageV2
                    isSearch={isSearch}
                    title="Список кандидатов по вакансиям пуст"
                />
            );
        }

        return (
            <>
                {
                    !isLoadMore && progressList &&
                    <NmLoader
                        inverted={true}
                        active={true}
                    />
                }
                <CheckboxList
                    rows={getRows()}
                    contentRef={$contentRef}
                    isShowRightContent={isShowRightContent}
                    rightContentLink={LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO.replace(":clientId", match.params.clientId)}
                    rightContent={getRightContent()}
                    rightContentId={match.params.id}
                    infiniteScrollProps={infiniteScrollProps}
                    infiniteProgress={progressList && isLoadMore}
                />
            </>
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Кандидаты по вакансиям
                </NmTitle>
            }
            isHideHeader={isMobile && isShowRightContent}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filtersBase}
                    onSubmit={onSubmitFilter}
                    clearFilter={onClearFilter}
                />
            }
            typeFilter="vertical"
            mediaControls={headerMediaControls}
        >
            {
                isOpenEdit &&
                <RecruitmentCandidatesEdit
                    onClose={onCloseEdit}
                    fetchData={fetchList}
                    clientId={clientId}
                />
            }
            {getContent()}
        </NmPage>
    );
};

export default RecruitmentCandidates;