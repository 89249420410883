import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {Dimmer, Loader} from "semantic-ui-react";

import bem from "../../../../../utils/bem";
import validate from "../../../../../utils/validate";

import {EDM_POSITION_ACTION_TYPE} from "../../../../../constants/edmPosition";
import {edmPositionRule} from "../../../../../constants/validationRules";

import {
    addEdmPosition,
    edmPositionActionProgressSelector,
    updateEdmPosition,
} from "../../../../../ducks/edmPositions";

const DocumentManagementNewPositionForm = (props) => {
    const {
        onCancel,
        type,
        clientId,
        positionForEdit = {},
    } = props;

    const [position, setPosition] = useState("");

    const [formError, setFormError] = useState({});

    const actionProgress = useSelector(edmPositionActionProgressSelector);

    const [block ] = bem("document-management-new-position-form");

    useEffect(() => {
        if (type === EDM_POSITION_ACTION_TYPE.EDIT) {
            setPosition(positionForEdit.value);
        }
    }, []);

    const dispatch = useDispatch();

    const isFormValid = () => {
        const formError = validate({position}, edmPositionRule, "");

        setFormError(formError);

        return Object.values(formError).length === 0;
    };

    const submit = () => {
        if (!isFormValid()) {
            return;
        }

        const reqData = {
            data: {
                clientId,
                value: position,
                positionId: positionForEdit.positionId,
            },
            onSuccess: onCancel,
        };

        if (type === EDM_POSITION_ACTION_TYPE.NEW) {
            dispatch(addEdmPosition(reqData));
        } else {
            dispatch(updateEdmPosition(reqData));
        }
    };

    const onChange = (e, {value}) => {
        setPosition(value);
    };

    const renderPreloader = () => {
        return (
            <Dimmer
                active={actionProgress}
                inverted
            >
                <Loader content=" " />
            </Dimmer>
        );
    };

    return (
        <NmModal
            onClose={onCancel}
            className={block()}
            size="md"
            header={
                <NmTitle size="lg">
                    {type === EDM_POSITION_ACTION_TYPE.NEW ? "Добавление должности" : "Редактирование должности"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={onCancel}
                    submit={submit}
                    submitBtnContent={type === EDM_POSITION_ACTION_TYPE.NEW ? "Добавить" : "Сохранить"}
                    cancelBtnContent="Отменить"
                />
            }
        >
            {renderPreloader()}
            <NmForm>
                <NmInputV2
                    placeholder="Введите должность"
                    size="xl"
                    label="Должность"
                    error={formError["position"]}
                    name="position"
                    value={position}
                    onChange={onChange}
                />
            </NmForm>
        </NmModal>
    );
};

export default DocumentManagementNewPositionForm;