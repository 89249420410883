import {useState} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {SimpleDropdownOptions} from "../../../../../constants/contractorList";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../constants";

export const useTemplateContractsFilter = ({setPagination, pageSize, documentTypeFilter}) => {
    const initFilterData = {
        externalDocumentFilter: null,
    };

    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState(initFilterData);

    const onSearch = (data) => {
        setFilterData(data);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClear = () => {
        setFilterData(initFilterData);
        setIsSearch(false);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const frameContractFilters = documentTypeFilter === TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT ? [
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    name: "externalDocumentFilter",
                    size: "lg",
                    label: "Внешний РД",
                    options: [
                        {
                            key: null,
                            text: "Все",
                            value: null,
                        },
                        ...SimpleDropdownOptions,
                    ],
                },
            ],
        },
    ] : [];

    const filtersBase = [
        {
            row: [
                {
                    component: FILTER.CUSTOMER,
                    multiple: true,
                    name: "clientIdsFilter",
                },
            ],
        },
        ...frameContractFilters,
    ];

    return {
        onSearch,
        isSearch,
        filterData,
        filtersBase,
        onClear,
    };
};