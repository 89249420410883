import {NM_ITEM_STATUS} from "./status";

export const PATENTS_REGISTRY_STATUS = {
    FORMED: "FORMED",
    PROCESSING: "PROCESSING",
    NOT_DEPOSITED:"NOT_DEPOSITED",
    DEPOSITED_TOTALLY: "DEPOSITED_TOTALLY",
    DEPOSITED_PARTIALLY: "DEPOSITED_PARTIALLY",
};

export const PATENTS_REGISTRY_STATUS_DICT = {
    [PATENTS_REGISTRY_STATUS.FORMED]: "Формируется",
    [PATENTS_REGISTRY_STATUS.PROCESSING]: "Передан в оплату",
    [PATENTS_REGISTRY_STATUS.NOT_DEPOSITED]:"Не оплачен",
    [PATENTS_REGISTRY_STATUS.DEPOSITED_TOTALLY]: "Оплачен полностью",
    [PATENTS_REGISTRY_STATUS.DEPOSITED_PARTIALLY]: "Оплачен частично",
};

export const PATENTS_REGISTRY_STATUS_COLOR_TRANSCRIPT = {
    [PATENTS_REGISTRY_STATUS.FORMED]: "gray",
    [PATENTS_REGISTRY_STATUS.PROCESSING]: "orange",
    [PATENTS_REGISTRY_STATUS.NOT_DEPOSITED]:"red",
    [PATENTS_REGISTRY_STATUS.DEPOSITED_TOTALLY]: "green",
    [PATENTS_REGISTRY_STATUS.DEPOSITED_PARTIALLY]: "light-green",
};

export const PATENTS_REGISTRY_LIST_STATUS_FILTER_DICT = {
    ALL: "Показать все",
    ...PATENTS_REGISTRY_STATUS_DICT,
};