import {SCAN_DECISION_REASON, SCAN_DECISION_TYPE} from "../../../../constants/contractor";

export const CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES = {
    // Тип скана в заявке Свидетельство о постановке на учет физического лица в налоговом органе (ИНН),
    // Необходим при выставлении чекбокса запросить лист с ИНН для fileDecisions, поле decisionType
    INN_SCAN: "INN_SCAN",
};

export const CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES_TRANSLATE = {
    INN_SCAN: "Свидетельство о постановке на учет физического лица в налоговом органе (ИНН)",
};

export const CONTRACTOR_VERIFICATION_DATE_REQUEST_INN_DECISION = {
    fileType: CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN,
    decisionReason: SCAN_DECISION_REASON[SCAN_DECISION_TYPE.NEED_INN_SCAN],
    decisionType: SCAN_DECISION_TYPE.NEED_INN_SCAN,
};