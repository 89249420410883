import React, {useMemo} from "react";
import {useState} from "react";
import {isEmpty} from "lodash";
import {nanoid} from "nanoid";

import {ReactComponent as IconChevron} from "../../../images/chevron-down.svg";
import {DropdownSelectedValue} from "../NmDropdownV2/SelectedValue";

import bem from "../../../utils/bem";

import "./style.sass";

interface TagSelect {
    direction?: "top" | "bottom",
    list: Array<SelectedItemType>,
    onClickDelete: (data: DeleteParamsType) => void,
    className?: string,
}

export type DeleteParamsType = {
    list: SelectedItemType[],
    item: SelectedItemType,
    index?: number
}

type SelectedItemType = {
    text?: string,
    value?: string,
    isSelected?: boolean,
}

const TagSelect: React.FC<TagSelect> = (props) => {
    const {
        direction,
        list,
        onClickDelete,
        className,
    } = props;

    const items = useMemo(() => {
        return list.filter(({isSelected = true}) => isSelected);
    }, [
        list,
    ]);

    const [open, setOpen] = useState(false);

    const [block, element] = bem("tag-select", className);

    return (
        <div className={block({direction})}>
            <div
                onClick={() => {
                    setOpen(!open);
                }}
                className={element("info", {active: !isEmpty(items)})}
            >
                <div className={element("stat")}>
                    Выбрано:
                    {" "}
                    {items.length}
                </div>
                <IconChevron
                    className={element("icon", {open})}
                />
            </div>
            {
                open &&
                <div className={element("wrapper")}>
                    <div className={element("container")}>
                        {
                            items.map((item, index) => {
                                const {
                                    text,
                                    value,
                                } = item;

                                return (
                                    <DropdownSelectedValue
                                        key={nanoid(3)}
                                        text={text}
                                        classNames={{
                                            block: "tag-select__item",
                                        }}
                                        onClickDelete={() => {
                                            onClickDelete({
                                                list: list.map(item => {
                                                    if (item.value === value) {
                                                        return {
                                                            ...item,
                                                            isSelected: false,
                                                            isChanged: true,
                                                        };
                                                    }

                                                    return item;
                                                }),
                                                item,
                                                index,
                                            });
                                        }}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default TagSelect;