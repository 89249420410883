import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {BaseDirectoryPage} from "../../../../components/BasicDirectory";
import {KedoDirectoriesTabs} from "../tabs";

import {useKedoDirectoryFetchCount} from "../hooks/useFetchCount";
import {useKedoDirectoriesSort} from "../hooks/useSort";
import {useKedoPositionsBff} from "./hooks/useBff";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {ADMIN, CLIENT_ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {updateKedoDirectoriesStore} from "../../../../ducks/kedo/directories/actionCreators";
import {
    kedoDirectoriesPositionsSelector,
    kedoDirectoriesProgressActionSelector,
} from "../../../../ducks/kedo/directories/selectors";

export const KedoPositions = (props) => {
    const clientId = props.match.params.clientId;

    const [isSearch, setIsSearch] = useState(false);

    const {
        fetchCounts,
    } = useKedoDirectoryFetchCount({
        clientId,
    });

    const {
        onClickSort,
        sortType,
        sortOptions,
    } = useKedoDirectoriesSort();

    const {
        totalPages,
        totalCount,
        list,
        progress,
    } = useSelector(kedoDirectoriesPositionsSelector);
    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const dispatch = useDispatch();

    const role = ls(USER_ROLE);

    const {
        deletePosition,
        addPosition,
        fetchPositionsPage,
        updatePosition,
    } = useKedoPositionsBff({
        clientId,
        fetchCounts,
        sortType,
    });

    useEffect(() => {
        return () => {
            dispatch(updateKedoDirectoriesStore({
                positions: {},
            }));
        };
    }, []);

    return (
        <>
            <KedoDirectoriesTabs
                clientId={clientId}
                isSearch={isSearch}
                positionsCount={totalCount}
                {...props}
            />
            <BaseDirectoryPage
                sort={true}
                isClientField={!clientId}
                sortType={sortType}
                sortOptions={sortOptions}
                onClickSort={onClickSort}
                title="Должности"
                setIsSearch={setIsSearch}
                isEditable={[ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role)}
                isNeedClosingOnSubmit={false}
                isLoaded={progress}
                progressAction={progressAction}
                totalPages={totalPages}
                totalCount={totalCount}
                list={list}
                onAdd={addPosition}
                onDelete={deletePosition}
                getItems={fetchPositionsPage}
                onEdit={updatePosition}
                inputTypeLabel="Должность"
                inputTypeMinLength={3}
                inputTypeMaxLength={100}
                addButtonText="Добавить должность"
                popupTitleAdd="Добавление должности"
                popupTitleEdit="Редактирование должности"
                popupTitleDelete="Вы действительно хотите удалить данную должность из справочника?"
                labelFilter="Должность"
                placeholderFilter="Введите наименование"
                inputTypePlaceholder="Введите должность"
            />
        </>
    );
};