export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST =
    "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_SUCCESS =
    "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR =
    "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_CLEAR_STORE =
    "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_CLEAR_STORE";

export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_CLEAR_STORE = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_CLEAR_STORE";
export const BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE";