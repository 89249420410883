import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

import {getClientNamesFilter} from "../../../../../components/ActualComponents/Filter/utils/getClientsFilter";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../templates/constants";

import {getCustomDocumentTemplateNames} from "../../../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsCustomDocumentTemplateNamesOptionsSelector} from "../../../../../ducks/bff/common/dicts/selectors";

export const useKedoTemplateDocumentTypesFilter = ({setPagination, pageSize}) => {
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const dispatch = useDispatch();

    const customDocumentTemplateNamesOptions = useSelector(bffCommonDictsCustomDocumentTemplateNamesOptionsSelector);

    const fetchNames = (clientName) => {
        dispatch(getCustomDocumentTemplateNames({
            pageNum: 1,
            pageSize: 25,
            documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.KEDO_DOCUMENT,
            nameFilter: isNullOrWhitespace(clientName) ? undefined : clientName.trim(),
        }));
    };

    const onSearchChange = (valueFilter) => {
        fetchNames(valueFilter);
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    getClientNamesFilter(),
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Наименование",
                        placeholder: "Введите наименование",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameForClientFilter",
                        label: "Наименование для компании",
                        placeholder: "Введите наименование",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        search: true,
                        multiple: true,
                        name: "customTemplateIdsFilter",
                        label: "Наименование шаблона",
                        placeholder: "Введите наименование",
                        options: customDocumentTemplateNamesOptions,
                        onSearchChange,
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "onlyUkepFilter",
                        label: "Требуется УКЭП компании",
                        placeholder: "Не выбрано",
                        options: [
                            {
                                key: true,
                                value: true,
                                text: "Да",
                            },
                            {
                                key: false,
                                value: false,
                                text: "Нет",
                            },
                        ],
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "availableClientUsersFilter",
                        label: "Первый подписант",
                        placeholder: "Не выбрано",
                        options: [
                            {
                                key: true,
                                value: true,
                                text: "Сотрудник",
                            },
                            {
                                key: false,
                                value: false,
                                text: "Компания",
                            },
                        ],
                        size: "lg",
                    },
                ],
            },
        ].filter(Boolean);
    }, [
        customDocumentTemplateNamesOptions,
    ]);

    return {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    };
};