import {isEmpty} from "lodash";

import dateFormat from "../../../../utils/dateFormat";
import {getNumberFromFormattedAmount} from "../../../../utils/stringFormat";
import {
    handleFormString,
    handleNumber, isNullOrWhitespace,
} from "../../../../utils/stringHelper";

import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";

export const getVacanciesRequestData = (filter) => {
    const {
        dateFrom,
        dateTo,
        statuses,
        title,
        recruiterName,
        workAddressFiasIds,
        vacancyNumber,
        createDateFrom,
        createDateTo,
        lastModifiedDateFrom,
        lastModifiedDateTo,
        ...filterData
    } = filter;

    return {
        dateFrom: !isNullOrWhitespace(dateFrom) ? dateFormat(dateFrom, "yyyy-MM-dd") : undefined,
        dateTo: !isNullOrWhitespace(dateTo) ? dateFormat(dateTo, "yyyy-MM-dd") : undefined,
        createDateFrom: !isNullOrWhitespace(createDateFrom) ? dateFormat(createDateFrom, "yyyy-MM-dd") : undefined,
        createDateTo: !isNullOrWhitespace(createDateTo) ? dateFormat(createDateTo, "yyyy-MM-dd") : undefined,
        lastModifiedDateFrom: !isNullOrWhitespace(lastModifiedDateFrom) ? dateFormat(lastModifiedDateFrom, "yyyy-MM-dd") : undefined,
        lastModifiedDateTo: !isNullOrWhitespace(lastModifiedDateTo) ? dateFormat(lastModifiedDateTo, "yyyy-MM-dd") : undefined,
        statuses: statuses?.length ? statuses : undefined,
        title: handleFormString(title),
        recruiterName: handleFormString(recruiterName),
        workAddressFiasIds: isEmpty(workAddressFiasIds) ? undefined : workAddressFiasIds,
        vacancyNumber: handleNumber(vacancyNumber),
        ...filterData,
    };
};

export const getVacancyEditReqData = (data) => {
    const {
        additionalRequirements,
        totalCandidatesCount,
        isDuplicate,
        status,
        ...otherData
    } = data;

    const _data = isDuplicate ? {
        files: data.files,
        fileValue: data.fileValue,
    } : otherData;

    return {
        ..._data,
        recruiterId: handleFormString(data.recruiterId),
        title: handleFormString(data.title),
        description: handleFormString(data.description),
        dateFrom: dateFormat(data.dateFrom, "yyyy-MM-dd"),
        dateTo: dateFormat(data.dateTo, "yyyy-MM-dd"),
        publishDateFrom: dateFormat(data.publishDateFrom, "yyyy-MM-dd"),
        publishDateTo: dateFormat(data.publishDateTo, "yyyy-MM-dd"),
        specialityId: handleFormString(data.specialityId),
        salaryTo: getNumberFromFormattedAmount(data.salaryTo),
        salaryFrom: getNumberFromFormattedAmount(data.salaryFrom),
        workAddressFiasId: handleFormString(data.workAddressFiasId),
        workAddressInfo: handleFormString(data.workAddressInfo),
        ageFrom: handleNumber(data.ageFrom),
        ageTo: handleNumber(data.ageTo),
        gender: data.gender,
        funnelId: handleFormString(data.funnelId),
        requirementIds: data.requirementIds?.length ? data.requirementIds : undefined,
        citizenships: data.citizenships?.length ? data.citizenships : undefined,
    };
};

export const getVacancyFormData = (data) => {
    const {
        files,
        fileValue,
        fileName,
        gender,
        ...otherValues
    } = data;

    const formData = new FormData();

    Object.keys(otherValues).forEach((name) => {
        const value = otherValues[name];

        if (value) {
            formData.append(name, value);
        }
    });

    if (gender !== EMPTY_OPTION_KEY) {
        formData.append("gender", gender);
    }

    if (!isEmpty(files)) {
        if (files[0].isRemote && fileValue) {
            formData.append("file", fileValue);
        }

        if (!files[0].isRemote) {
            formData.append("file", files[0]);
        }
    }

    return formData;
};