import React, {useEffect, useState} from "react";
import ReactJson from "react-json-view";
import Form from "@rjsf/core";
import {isEmpty} from "lodash";

import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {fnsRequest} from "../../../utils/fnsService";
import {toastError} from "../../../utils/toastHelper";

import "./style.sass";

const FnsServiceSendModal = props => {
    const {onClose, fnsData} = props;

    const {method, title, data, typeMethod} = fnsData;

    const [form, setForm] = useState({});
    useEffect(() => {
        setForm(data);
    }, [data]);

    const [result, setResult] = useState({});

    const submit = ({formData}) => {
        fnsRequest({
            data: formData,
            url: method,
            typeMethod,
        })
            .then(result => {
                setResult(result);
            })
            .catch(error => {
                toastError(error);
            });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <NmModal
            className="send-modal"
            onClose={handleClose}
            header={
                <NmTitle size="md">
Отправка параметров:
                    {title}
                </NmTitle>
            }
        >
            <div className="send-modal__content">
                <Form
                    schema={form}
                    onSubmit={submit}
                    id="FNS"
                >
                    <ApplyButtons
                        onClose={handleClose}
                        className="send-modal__buttons-apply"
                        submitBtnContent="Выполнить запрос"
                        cancelBtnContent="Отменить"
                        formId="FNS"
                        // submit={submit}
                    />
                </Form>
                {!isEmpty(result) && (
                    <div>
                        <NmTitle
                            size="md"
                            className="send-modal__title-response"
                        >
                            Ответ:
                        </NmTitle>
                        <ReactJson src={result} />
                    </div>
                )}
            </div>
        </NmModal>
    );
};

export default FnsServiceSendModal;
