import React, {ReactNode, useState} from "react";

import {ReactComponent as IconChevron} from "../../images/chevron-down.svg";

import bem from "../../utils/bem";

import "./style.sass";

interface INmAccordion {
    noBorder?: Boolean,
    noPadding?: Boolean,
    name?: string | ReactNode,
    children: ReactNode,
    className?: string,
    cloClass?: string,
    onOpen?: (open: boolean) => void,
    defaultOpen?: boolean,
    classNameTitle?: string,
    isHiddenNameAfterOpening?: boolean,
    isHiddenToggleButton?: boolean,
    classNameIcon?: string,
    classNameHeader?: string,
}

const NmAccordion = (props: INmAccordion) => {
    const {
        name,
        children,
        className,
        defaultOpen = false,
        classNameTitle = "",
        isHiddenNameAfterOpening,
        isHiddenToggleButton,
        classNameHeader = "",
        classNameIcon = "",
        noBorder,
        noPadding,
        onOpen,
    } = props;
    const [open, setOpen] = useState(defaultOpen);
    const [block, element] = bem("nm-accordion", className);

    const onClick = () => {
        setOpen(!open);
        onOpen && onOpen(!open);
    };

    return (
        <div className={block({open, noBorder, noPadding})}>
            <div className={`${element("header")} ${classNameHeader}`}>
                {
                    isHiddenNameAfterOpening && open ? null :
                        <div className={`${element("name")} ${classNameTitle}`}>
                            {name}
                        </div>
                }
                {
                    !isHiddenToggleButton &&
                    <IconChevron
                        onClick={onClick}
                        className={`${element("icon")} ${classNameIcon}`}
                    />
                }
            </div>
            {open && children}
        </div>
    );
};

export default NmAccordion;