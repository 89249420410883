import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {getContractorStatusDict} from "../../../../../ducks/contractor";
import {
    getByIdDocumentsEdoRegistries,
} from "../../../../../ducks/documentsEdoRegistries";
import {
    getDocumentsEdoRegistriesItemsPage,
    updateDocumentsEdoRegistriesStoreItems,
} from "../../../../../ducks/documentsEdoRegistriesItems";
import {getEdoDocumentTypes} from "../../../../../ducks/edocuments";
import {
    getAllTasks,
    jobTasksImportEdoRegistrySelector,
} from "../../../../../ducks/job";

export const useEdoDocumentsRegistriesCardFetch = ({filterData, pageNum, pageSize, clientId, registryId}) => {
    const currentMember = useSelector(clientCurrentMemberSelector);
    const importTasks = useSelector(jobTasksImportEdoRegistrySelector);

    const prevTasksList = useGetPrevValue(importTasks);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContractorStatusDict());

        dispatch(getEdoDocumentTypes({
            clientId,
            pageNum: 1,
            pageSize: 200,
        }));

        fetchCard();
    }, []);

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);

    useEffect(() => {
        if (isEmpty(importTasks) && !isEmpty(prevTasksList)) {
            fetchList();
        }
    }, [importTasks]);

    useEffect(() => {
        return () => {
            dispatch(updateDocumentsEdoRegistriesStoreItems({
                list: [],
                totalCount: 0,
                pageData: {},
                card: {},
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getDocumentsEdoRegistriesItemsPage({
            ...filterData,
            registryId,
            clientId,
            pageNum,
            pageSize,
        }));
    };

    const fetchCard = () => {
        dispatch(getByIdDocumentsEdoRegistries({
            clientId,
            registryId,
        }));
    };

    const fetchTasks = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        dispatch(getAllTasks({
            clientId,
            clientUserId,
        }));
    };

    return {
        fetchCard,
        fetchList,
        fetchTasks,
    };
};