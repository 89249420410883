import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmPage from "../../../../components/NmPage";
import SigningDocumentsLogFilter from "./signing-documents-log-filter";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";
import {phoneFormat} from "../../../../utils/stringFormat";
import {
    handleDateFieldFilter,
    handleFormString,
} from "../../../../utils/stringHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_INFO,
    LINK_CONTRACTOR_PROFILE,
} from "../../../../constants/links";

import {
    documentTypeSignatureLogDictsSelector,
    downloadDocument,
    getDocumentSignatureLogListSelector,
    getDocumentSignatureTotalCountSelector,
    getDocumentSignatureTotalPagesSelector,
    getPageDocumentsSignatureLog,
} from "../../../../ducks/documents";

import "./style.sass";

class SigningDocumentsLogList extends Component {

    constructor(props) {
        super(props);

        this.defaultFilter = {
            transactionIdFilter: "",
            documentIdFilter: "",
            clientIdFilter: "",
            fromDateFilter: null,
            toDateFilter: null,
            documentTypeFilter: null,
        };

        this.state = {
            pageSize: 25,
            pageNum: 1,
            loading: false,
            ...this.defaultFilter,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList = () => {
        this.setLoading(true);
        const {getPageDocumentsSignatureLog} = this.props;
        const {
            pageNum,
            pageSize,
            fromDateFilter,
            toDateFilter,
            documentIdFilter,
            transactionIdFilter,
            documentTypeFilter,
            clientIdFilter,
        } = this.state;

        getPageDocumentsSignatureLog({
            data: {
                pageNum,
                pageSize,
                fromDateFilter: handleDateFieldFilter(fromDateFilter),
                toDateFilter: handleDateFieldFilter(toDateFilter),
                documentIdFilter: handleFormString(documentIdFilter),
                transactionIdFilter: handleFormString(transactionIdFilter),
                clientIdFilter: handleFormString(clientIdFilter),
                documentTypeFilter,
            },
            handleResponse: this.handleResponseList,
        });
    };

    setLoading = (loading) => {
        this.setState({loading});
    };

    handleResponseList = () => {
        this.setLoading(false);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    sendFilter = (filter) => {
        this.setState(prevState => ({
            ...prevState,
            pageNum: 1,
            ...filter,
        }), this.fetchList);
    };

    renderNameCell(event) {
        const {
            clientPerformerId,
            contractorPerformerId,
            performerName,
        } = event;

        if (!clientPerformerId && !contractorPerformerId) {
            return performerName;
        }

        let to = LINK_CLIENT_INFO.replace(":clientId", clientPerformerId);

        if (contractorPerformerId) {
            to = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorPerformerId);
        }

        return (
            <ExtLink
                to={to}
                historyEnabled
            >
                {performerName}
            </ExtLink>
        );
    }

    downloadDocument = ({downloadLink, documentType}) => {
        const {downloadDocument} = this.props;

        const parse = require("url-parse");

        const {query, pathname} = parse(downloadLink);

        downloadDocument({
            isDownload: true,
            isFillOrigin: true,
            documentType,
            downloadLink: `${pathname}${query}`,
        });
    };

    renderActions(item) {
        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => this.downloadDocument(item),
                        color: "grey",
                        children: "Скачать",
                    },
                },
            ],
        };
    }

    getRows = () => {
        const {list, documentTypeDict} = this.props;

        return list.map(item => {
            const {
                transactionDatetime,
                clientUserPerformerEmail,
                contractorPerformerPhone,
                contractorPerformerSmsCode,
                performerIpAddress,
                documentType,
                transactionId,
                documentId,
                clientPerformerId,
                contractorPerformerId,
            } = item;

            const labels = !clientPerformerId && !contractorPerformerId ? [] : [
                {label: "Подписант", text: this.renderNameCell(item)},
                {label: "Идентификатор документа", text: documentId},
                {label: "№ лога транзакции", text: transactionId},
                {label: "Номер телефона", text: phoneFormat(contractorPerformerPhone)},
                !["ORDER_APPLICATION", "ACT_OF_ACCEPTANCE_OF_WORK"].includes(documentType) && {label: "Код подтверждения из SMS", text: contractorPerformerSmsCode},
                clientPerformerId && {label: "IP-адрес", text: performerIpAddress},
                clientPerformerId && {label: "Логин (e-mail)", text: clientUserPerformerEmail || "-"},
            ];

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={`Дата и время: ${formatDate(convertUtcToLocal(transactionDatetime), "dd.MM.yyyy HH:mm:ss")}`}
                        primaryHeader={documentTypeDict[documentType]}
                        classNameMainContent="col-16"
                        labels={labels}
                        mediaControls={this.renderActions(item)}
                        isFixedActions
                    />
                ),
            };
        });
    }

    renderBaseFilter() {
        return (
            <SigningDocumentsLogFilter
                defaultFilter={this.defaultFilter}
                sendFilter={this.sendFilter}
            />
        );
    }

    render() {
        const {
            header,
            tabs,
            totalPages,
            totalCount,
            list,
        } = this.props;

        const {
            pageNum,
            pageSize,
            loading,
        } = this.state;

        return (
            <NmPage
                header={header}
                className="signing-documents-log"
                isLoaded={loading}
                typeFilter="vertical"
                filtersBase={this.renderBaseFilter()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
            >
                {tabs}
                {
                    list?.length ?
                        <CheckboxList
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2 />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: getDocumentSignatureLogListSelector(state),
        totalPages: getDocumentSignatureTotalPagesSelector(state),
        documentTypeDict: documentTypeSignatureLogDictsSelector(state),
        totalCount: getDocumentSignatureTotalCountSelector(state),
    }),
    {
        getPageDocumentsSignatureLog,
        downloadDocument,
    },
)(withTranslation()(SigningDocumentsLogList));