import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {TYPE_LOADING_EDO_DOCUMENT} from "../../../../../constants/document-management/document-list";

const validationSchema = () => {
    return yup.object().shape({
        name: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        ownerId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        documentType: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        file: yup.mixed()
            .nullable()
            .test("file", VALIDATIONS_MESSAGE.REQUIRED, function (value) {
                if(value?.size || !this.parent.documentType){
                    return true;
                }
                if(this.parent.isShowTypeLoading || this.parent.useTemplateFile){
                    return this.parent.templateType === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE;
                }
                return false;
            }),
    });
};

export default validationSchema;

/*
       .when("templateType", {
                is: (templateType) => {
                    return TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT === templateType;
                },
                then: yup.mixed()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
            })
* */