import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

const validationRegisterPatent = () => {
    return yup.object().shape({
        registryName: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        objectId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        registryComment: yup.string(),
    });

};

export default validationRegisterPatent;
