import {useState} from "react";
import {useSelector} from "react-redux";

import {convertUtcToLocal} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {ORDER_STATUS} from "../../../../../constants/clientList";
import {VISIBLE_TO_CONTRACTOR_TRANSLATE} from "../../../../../constants/order";
import {FOREMAN} from "../../../../../constants/roles";

import {clientCurrentMemberIdSelector} from "../../../../../ducks/clientMember";

export function useAddOrdersToGroupFilterDto() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const currentUserId = useSelector(clientCurrentMemberIdSelector);
    const role = ls(USER_ROLE);

    function mapFilterDto(filter) {
        const {
            statusFilter,
            dateWorkEndFilter,
            dateWorkStartFilter,
            fromDateCreateFilter,
            toDateCreateFilter,
            projectIdsFilter = [],
            objectIdsFilter,
            visibleToContractor,
            orderContractPaymentTypeFilter,
            addressFiasIdsFilter,
        } = filter;

        const statuses = [ORDER_STATUS.DRAFT, ORDER_STATUS.CLOSED, ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH];

        return {
            ...filterDto,
            ...filter,
            objectIdsFilter: objectIdsFilter && objectIdsFilter.length ? objectIdsFilter : undefined,
            projectIdsFilter: projectIdsFilter.length ? projectIdsFilter : undefined,
            dateWorkEndFilter: dateWorkEndFilter ? convertUtcToLocal(dateWorkEndFilter) : undefined,
            dateWorkStartFilter: dateWorkStartFilter ? convertUtcToLocal(dateWorkStartFilter) : undefined,
            fromDateCreateFilter: fromDateCreateFilter ? convertUtcToLocal(fromDateCreateFilter) : undefined,
            toDateCreateFilter: toDateCreateFilter ? convertUtcToLocal(toDateCreateFilter) : undefined,
            statusFilter: statusFilter === "ALL" ? statuses : [statusFilter],
            orderContractPaymentTypeFilter: orderContractPaymentTypeFilter === "ALL" ? undefined : orderContractPaymentTypeFilter,
            clientUserIdsFilter: role === FOREMAN && currentUserId ? [currentUserId] : undefined,
            visibleToContractor: VISIBLE_TO_CONTRACTOR_TRANSLATE[visibleToContractor],
            addressFiasIdsFilter,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };


    return {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}