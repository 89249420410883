import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import NmSelectedList from "../../../../components/NmSelectedList";
import {TemplatesContext} from "../../contexts/context";
import CustomDocumentTemplatesClientUsage from "../client-usage";
import TemplatesEdit from "../edit";
import TemplateContractsVersions from "./components/versions";

import {useModal} from "../../../../hooks/useModal";
import {usePagination} from "../../../../hooks/usePagination";
import useClientListTemplateModal from "../../hooks/useClientListTemplateModal";
import useVersionsTemplateModal from "../../hooks/useVersionsTemplateModal";
import useTemplateContractsAction from "./hooks/useAction";
import {useTemplateContractsFetch} from "./hooks/useFetch";
import {useTemplateContractsFilter} from "./hooks/useFilter";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getFullName} from "../../../../utils/stringFormat";

import {
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../../constants/finance";
import {ORDER_KIND} from "../../../../constants/order";
import {isUserFromNm} from "../../../../constants/roles";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

import {
    documentCustomTemplateFetchErrorSelector,
    documentCustomTemplateListSelector,
    documentCustomTemplateProgressSelector,
    documentCustomTemplateTotalCountSelector,
    documentCustomTemplateTotalPagesSelector,
} from "../../../../ducks/documentsCustomTemplate";

const TemplatesContracts = (props) => {
    const {
        documentTypeFilter = TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
        headerText = "Договоры",
    } = props;

    const {
        clientId,
    } = props.match.params;

    const role = ls(USER_ROLE);
    const isNmUser = isUserFromNm(role);

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        isOpenEdit,
        onCloseEdit,
    } = useContext(TemplatesContext);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("template-contracts");

    const {
        onSearch,
        isSearch,
        filterData,
        filtersBase,
        onClear,
    } = useTemplateContractsFilter({
        setPagination,
        pageSize,
        documentTypeFilter,
    });

    const {
        fetchList,
    } = useTemplateContractsFetch({
        filterData,
        pageNum,
        pageSize,
        documentTypeFilter,
        clientId,
    });

    const {
        closeModal,
        versionData,
        isOpenVersionsTemplateModal,
        openModal: openVersionsTemplateModal,
    } = useVersionsTemplateModal();

    const {
        clientUsageData,
        isOpenModal: isOpenModalClientListTemplateModal,
        openModal: openModalClientListTemplateModal,
        closeModal: closeModalClientListTemplateModal,
    } = useClientListTemplateModal();

    const {
        getListMediaControls,
    } = useTemplateContractsAction({
        clientId,
        fetchList,
        openVersionsTemplateModal,
        openModalClientListTemplateModal,
        onOpenConfirm,
    });

    const totalPages = useSelector(documentCustomTemplateTotalPagesSelector);
    const list = useSelector(documentCustomTemplateListSelector);
    const totalCount = useSelector(documentCustomTemplateTotalCountSelector);
    const progress = useSelector(documentCustomTemplateProgressSelector);
    const fetchError = useSelector(documentCustomTemplateFetchErrorSelector);

    function mapUsedByClients(usedByClients) {
        return usedByClients.map(({name, clientId}) => ({text: name, value: clientId, key: clientId}));
    }

    const getLabels = (item) => {
        const {
            orderContractPaymentType,
            orderKind,
        } = item;

        if (
            [
                TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS,
                TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION,
                TEMPLATES_TYPE_DOCUMENTS.ADDITIONAL_AGREEMENT,
            ].includes(documentTypeFilter)
        ) {
            return [];
        }

        const contractPaymentTypeLabel = {
            label: "Договор",
            textTitle: ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType] || "-",
            text: ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType] || "-",
        };
        const orderKindLabel = {
            label: "Тип заказа",
            text: orderKind === ORDER_KIND.AMOUNT_BASED_ORDER ? "Тип заказа 1" : "Тип заказа 2",
        };

        if (
            [
                TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
                TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
            ].includes(documentTypeFilter)
            && orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ
        ) {
            return [
                contractPaymentTypeLabel,
                orderKindLabel,
            ];
        }

        return [
            contractPaymentTypeLabel,
        ];
    };

    const getCompanies = ({naimixTemplate, usedByClients}) => {
        if (naimixTemplate) {
            return "Все компании";
        }

        return !isEmpty(usedByClients) ? <NmSelectedList
            showListWithoutValue
            showedItemsCount={3}
            highlightingFirstItem={false}
            list={mapUsedByClients(usedByClients)}
        /> : "-";
    };

    function getRows() {
        return list.map(item => {
            const {
                id,
                naimixTemplate,
                lastVersionDateTime,
                addedByClientUserLastName,
                addedByClientUserFirstName,
                addedByClientUserPatronymic,
                defaultTemplate,
                name,
                documentType,
                externalDocument,
            } = item;

            return {
                ...item,
                key: id,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={`Дата последней версии: ${formatLocalDate(lastVersionDateTime, "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={
                            naimixTemplate &&
                            <NmBadge
                                text="Шаблон Наймикс"
                                mod="orange"
                            />
                        }
                        secondaryHeaderNoReverse={true}
                        secondaryHeaderAdditionStatus={
                            defaultTemplate && clientId &&
                            <NmBadge
                                text="Шаблон по умолчанию"
                                mod="light-green"
                            />
                        }
                        primaryHeader={name}
                        classNameMainContent="col-16 col-xxl-12"
                        labels={[
                            documentType === TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT && externalDocument && {
                                text: "Внешний",
                            },
                            isUserFromNm(role) ? {
                                label: "Создатель",
                                text: naimixTemplate ?
                                    "Наймикс" :
                                    getFullName(addedByClientUserLastName, addedByClientUserFirstName, addedByClientUserPatronymic),
                            } : null,
                            ...getLabels(item),
                            isUserFromNm(role) ? {
                                label: "Компании",
                                text: getCompanies(item),
                                noWrap: false,
                                flexWrap: true,
                            } : null,
                        ]}
                        isFixedActions
                        actionsClassName="col-4"
                        mediaControls={getListMediaControls(item)}
                    />
                ),
            };
        });
    }

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            confirmButton,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton={confirmButton || "Да"}
                cancelButton="Отмена"
            />
        );
    };

    return (
        <NmPage
            noPadding
            widthByFilter
            className="template-contracts"
            typeFilter={isNmUser && !clientId ? "vertical" : null}
            filtersBase={
                isNmUser && !clientId &&
                <Filter
                    initState={filterData}
                    filters={filtersBase}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            header={<NmPageHeader
                text={headerText}
                totalCount={totalCount}
            />}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {renderConfirm()}
            {
                isOpenEdit &&
                <TemplatesEdit
                    pathname={props.location.pathname}
                    fetchList={fetchList}
                    onClose={onCloseEdit}
                />
            }
            {
                isOpenModalClientListTemplateModal &&
                <CustomDocumentTemplatesClientUsage
                    fetchTemplates={fetchList}
                    onClose={closeModalClientListTemplateModal}
                    customDocumentTemplateIdFilter={clientUsageData.customDocumentTemplateIdFilter}
                />
            }
            {
                isOpenVersionsTemplateModal &&
                <TemplateContractsVersions
                    templateIdFilter={versionData.templateIdFilter}
                    onClose={closeModal}
                />
            }
            {list.length === 0 && <NmEmptyPageV2
                isSearch={isSearch}
                title="Информация отсутствует"
                fetchProgress={progress}
                fetchError={fetchError}
            />}
            {list.length > 0 && <CheckboxList
                rows={getRows()}
            />}
        </NmPage>
    );
};

export default TemplatesContracts;