import {all, put, takeEvery} from "redux-saga/effects";

import {
    BFF_KEDO_STAFF_ADD_ERROR,
    BFF_KEDO_STAFF_ADD_REQUEST,
    BFF_KEDO_STAFF_ADD_SUCCESS,
    BFF_KEDO_STAFF_COMMON_ERROR,
    BFF_KEDO_STAFF_COMMON_REQUEST,
    BFF_KEDO_STAFF_COMMON_SUCCESS,
    BFF_KEDO_STAFF_GET_BY_ID_ERROR,
    BFF_KEDO_STAFF_GET_BY_ID_REQUEST,
    BFF_KEDO_STAFF_GET_BY_ID_SUCCESS,
    BFF_KEDO_STAFF_GET_PAGE_ERROR,
    BFF_KEDO_STAFF_GET_PAGE_REQUEST,
    BFF_KEDO_STAFF_GET_PAGE_SUCCESS,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS,
    BFF_KEDO_STAFF_UPDATE_ERROR,
    BFF_KEDO_STAFF_UPDATE_REQUEST,
    BFF_KEDO_STAFF_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";
import {getBffUrl} from "../../../utils/url";

const controllers = {
    client: "/adm/companies/company/kedo/staff-registry",
    admin: "/adm/clients/kedo/staff-registry",
};

// POST /bff/adm/companies/company/kedo/staff-registry/update
// Обновление сотрудника
const updateKedoStaff = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.admin}/update`,
            adminRolesUrl: `${controllers.client}/update`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_UPDATE_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_UPDATE_ERROR,
        });
    }
};

// POST /bff/adm/companies/company/kedo/staff-registry/updateArchiveStatus
// Обновление архивного статуса
const updateKedoArchiveStatusStaff = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.admin}/updateArchiveStatus`,
            adminRolesUrl: `${controllers.client}/updateArchiveStatus`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
        });
    }
};

// POST /bff/adm/companies/company/kedo/staff-registry/importFromFile
// Добавление списка сотрудников из файла
const importKedoStaffFromFile = function* ({payload}) {
    const {
        onSuccess,
        file,
        clientId,
        isClientCard = true,
    } = payload;

    const otherUrl = `/importFromFile?clientId=${clientId}`;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.admin}${otherUrl}`,
            adminRolesUrl: `${controllers.client}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.post(
            url,
            file,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
        });
    }
};

// POST /bff/adm/companies/company/kedo/staff-registry/getPage
// Получение страницы Сотрудники
const getKedoStaffPage = function* ({payload}) {
    const otherUrl = "/getPage";

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.admin}${otherUrl}`,
            adminRolesUrl: `${controllers.client}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/companies/company/kedo/staff-registry/add
// Получение страницы Сотрудники
const addKedoStaff = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    const otherUrl = "/add";

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.admin}${otherUrl}`,
            adminRolesUrl: `${controllers.client}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_ADD_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_ADD_ERROR,
        });
    }
};

// GET /bff/adm/companies/company/kedo/staff-registry/getById
// Получение страницы сотрудника по id
const getKedoStaffById = function* ({payload}) {
    const {
        pathname,
        isClientCard = true,
        ...reqData
    } = payload;

    const otherUrl = "/getById";

    const url = getBffUrl(
        {
            [pathname]: "adm/companies/company/kedo/staff-registry/staff-card/profile",
            isClientCard,
            clientRolesUrl: `${controllers.admin}${otherUrl}`,
            adminRolesUrl: `${controllers.client}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.get(url, {params: reqData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_GET_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_GET_BY_ID_ERROR,
        });
    }
};

// GET /bff/adm/companies/company/kedo/staff-registry/staff-card/common
// Получение общей страницы сотрудника
const getKedoStaffCommon = function* ({payload}) {
    const url = getBffUrl(
        {
            isClientCard: true,
            adminRolesUrl: "adm/companies/company/kedo/staff-registry/staff-card/common",
        },
    );

    try {
        const result = yield request.bff.get(url, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_COMMON_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_COMMON_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_COMMON_ERROR,
        });
    }
};

// POST /bff/adm/companies/company/kedo/staff-registry/staff-card/signature-transactions
// Получение страницы журнала подписания документов сотрудника
const getKedoStaffSignatureTransactions = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controllers.client}/staff-card/signature-transactions`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_KEDO_STAFF_UPDATE_REQUEST, updateKedoStaff),
        takeEvery(BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST, updateKedoArchiveStatusStaff),
        takeEvery(BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST, importKedoStaffFromFile),
        takeEvery(BFF_KEDO_STAFF_GET_PAGE_REQUEST, getKedoStaffPage),
        takeEvery(BFF_KEDO_STAFF_ADD_REQUEST, addKedoStaff),
        takeEvery(BFF_KEDO_STAFF_GET_BY_ID_REQUEST, getKedoStaffById),
        takeEvery(BFF_KEDO_STAFF_COMMON_REQUEST, getKedoStaffCommon),
        takeEvery(BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST, getKedoStaffSignatureTransactions),
    ]);
}