import {all} from "redux-saga/effects";

import {saga as kedoLogs} from "../kedo/logs/sagas";
import {saga as bankTkbSaga} from "./bank/tkb/sagas";
import {saga as bankW1Saga} from "./bank/w1/sagas";
import {saga as botSaga} from "./bot/sagas";
import {saga as clientsInstructionsKedoSaga} from "./clients/instructions/kedo/sagas";
import {saga as clientsModuleTariffsSaga} from "./clients/module-tariffs/sagas";
import {saga as notificationSendingToContractorSaga} from "./clients/notification-sending/to-contractor/sagas";
import {saga as clientsSettingsSaga} from "./clients/settings/sagas";
import {saga as clientsSystemPropertiesSaga} from "./clients/system-properties/sagas";
import {saga as commonDictsSaga} from "./common/dicts/sagas";
import {saga as commonTreeSaga} from "./common/tree/sagas";
import {saga as contractorTaskHistorySaga} from "./contractor/taskHistory/sagas";
import {saga as crowdActRegistrySaga} from "./crowd/actRegistry/sagas";
import {saga as crowdActRegistryEntrySaga} from "./crowd/actRegistryEntry/sagas";
import {saga as crowdPaymentSaga} from "./crowd/payment/sagas";
import {saga as crowdTaskCardSaga} from "./crowd/taskCard/sagas";
import {saga as crowdTaskGroupSaga} from "./crowd/taskGroup/sagas";
import {saga as crowdTaskGroupsSaga} from "./crowd/taskGroups/sagas";
import {saga as crowdTasksSaga} from "./crowd/tasks/sagas";
import {saga as crowdTasksAnalyticsSaga} from "./crowd/tasksAnalytics/sagas";
import {saga as documentsAdditionalAgreementsRegistrySaga} from "./documents/additional-agreements/registry/sagas";
import {saga as documentsAdditionalAgreementsSaga} from "./documents/additional-agreements/sagas";
import {saga as financesNdflPaymentsSaga} from "./finances/ndfl-payments/sagas";
import {saga as financesNpdPaymentsSaga} from "./finances/npd-payments/sagas";
import {saga as financesReceiptsCancelledSaga} from "./finances/receipts-cancelled/sagas";
import {saga as mapSaga} from "./map/sagas";
import {saga as patentPaymentsSaga} from "./patent/sagas";
import {saga as plutoniumTransactionLogSaga} from "./plutonium/transaction-log/sagas";
import {saga as recruitmentAccessControlJobBoards} from "./recruitment/access-control/job-boards/sagas";
import {saga as recruitmentAccessControlObservers} from "./recruitment/access-control/observers/sagas";
import {saga as recruitmentAccessControlRecruitersGroups} from "./recruitment/access-control/recruiters-groups/sagas";
import {saga as recruitmentAccessControl} from "./recruitment/access-control/sagas";
import {saga as recruitmentAccessControlVacancyPersonalAccess} from "./recruitment/access-control/vacancy-personal-access/sagas";
import {saga as recruitmentDirectorySaga} from "./recruitment/directory/sagas";
import {saga as recruitmentJobBoardsSaga} from "./recruitment/jobBoards/sagas";
import {saga as recruitmentJobBoardsWebHookSaga} from "./recruitment/jobBoardsWebhook/sagas";
import {saga as recruitmentVacancySaga} from "./recruitment/vacancy/sagas";
import {saga as recruitmentVacancyCandidatesResponsesSaga} from "./recruitment/vacancyCandidates/responses/sagas";
import {saga as recruitmentVacancyCandidatesSaga} from "./recruitment/vacancyCandidates/sagas";
import {saga as recruitmentVacancyCandidatesCommentsSaga} from "./recruitment/vacancyCandidatesComments/sagas";
import {saga as recruitmentVacancyResponsesSaga} from "./recruitment/vacancyResponses/sagas";
import {saga as settingsInstructionsKedoSaga} from "./settings/instructions/kedo/sagas";
import {saga as systemTransactionsSaga} from "./system-transactions/sagas";

export default function* rootBffSaga() {
    yield all([
        clientsSettingsSaga(),
        commonDictsSaga(),
        mapSaga(),
        recruitmentDirectorySaga(),
        recruitmentVacancyCandidatesSaga(),
        recruitmentVacancySaga(),
        recruitmentVacancyCandidatesCommentsSaga(),
        recruitmentVacancyResponsesSaga(),
        recruitmentVacancyCandidatesResponsesSaga(),
        recruitmentJobBoardsSaga(),
        recruitmentAccessControl(),
        recruitmentAccessControlRecruitersGroups(),
        bankW1Saga(),
        bankTkbSaga(),
        crowdTasksSaga(),
        crowdTaskCardSaga(),
        crowdTaskGroupsSaga(),
        settingsInstructionsKedoSaga(),
        crowdTaskGroupSaga(),
        crowdPaymentSaga(),
        clientsInstructionsKedoSaga(),
        systemTransactionsSaga(),
        crowdTasksAnalyticsSaga(),
        crowdActRegistrySaga(),
        crowdActRegistryEntrySaga(),
        contractorTaskHistorySaga(),
        kedoLogs(),
        clientsSystemPropertiesSaga(),
        clientsModuleTariffsSaga(),
        patentPaymentsSaga(),
        plutoniumTransactionLogSaga(),
        financesReceiptsCancelledSaga(),
        financesNpdPaymentsSaga(),
        financesNdflPaymentsSaga(),
        recruitmentAccessControlJobBoards(),
        recruitmentAccessControlObservers(),
        recruitmentAccessControlVacancyPersonalAccess(),
        recruitmentJobBoardsWebHookSaga(),
        botSaga(),
        commonTreeSaga(),
        documentsAdditionalAgreementsSaga(),
        documentsAdditionalAgreementsRegistrySaga(),
        notificationSendingToContractorSaga(),
    ]);
}
