/*
{
  "success": true,
  "statuses": [
    {
      "name": "TO_PREPARE",
      "description": "На подготовке"
    },
    {
      "name": "ON_REVIEW",
      "description": "На проверке"
    },
    {
      "name": "ON_SIGNING",
      "description": "На подписании"
    },
    {
      "name": "SIGNED",
      "description": "Подписано"
    },
    {
      "name": "REJECTED",
      "description": "Отклонено"
    }
  ]
}
* */

/*
statementFile - файл заявления
attachmentFile - файл приложения к заявлению

approverIds - идентификаторы кадров, выбранных в качестве согласующих
statementType - идентификатор типа заявления
statementDateTime - Дата заявления
statementName - наименование заявления
clientId - идентификатор компании, в рамках которой отправляется заявление
receiverId идентификатор кадра, выбранного получателем заявления
description - доп информация.
* */
export const EDO_STATEMENT_LOADING_FIELD = {
    STATEMENT_FILE: "statementFile",
    STATEMENT_FILE_MULTIPART: "statementFile.multipartFile",
    STATEMENT_FILE_CONTENT_TYPE: "statementFile.contentType",
    STATEMENT_FILE_NAME: "statementFile.name",
    ATTACHMENT_FILE_MULTIPART: "attachmentFile.multipartFile",
    ATTACHMENT_FILE_CONTENT_TYPE: "attachmentFile.contentType",
    ATTACHMENT_FILE_NAME: "attachmentFile.name",
    ATTACHMENT_FILE: "attachmentFile",
    APPROVED_IDS: "approverIds",
    STATEMENT_TYPE: "statementType",
    STATEMENT_NAME: "statementName",
    CLIENT_ID: "clientId",
    RECEIVER_ID: "receiverId",
    DESCRIPTION: "description",
    STATEMENT_DATE_TIME: "statementDateTime",
    STATEMENT_ID: "statementId",
};

export const EDM_STATEMENT_STATUS = {
    TO_PREPARE: {
        TEXT: "На подготовке",
        VALUE: "TO_PREPARE",
        COLOR: "orange",
    },
    ON_REVIEW: {
        TEXT: "На рассмотрении",
        VALUE: "ON_REVIEW",
        COLOR: "orange",
    },
    ON_SIGNING: {
        TEXT: "На подписании",
        VALUE: "ON_SIGNING",
        COLOR: "orange",
    },
    ON_SENDER: {
        TEXT: "На подписании у отправителя",
        VALUE: "ON_SENDER",
        COLOR: "orange",
    },
    ON_RECEIVER: {
        TEXT: "На подтверждении у получателя",
        VALUE: "ON_RECEIVER",
        COLOR: "orange",
    },
    SIGNED: {
        TEXT: "Подписано",
        VALUE: "SIGNED",
        COLOR: "green",
    },
    REJECTED: {
        TEXT: "Отклонено",
        VALUE: "REJECTED",
        COLOR: "red",
    },
};

export const EDM_STATEMENTS_STATUS_OPTIONS = [
    {
        text: "Все",
        value: "all",
        key: "all",
    },
    ...Object.values(EDM_STATEMENT_STATUS).map(({TEXT: text, VALUE: value}) => ({
        text,
        value,
        key: value,
    })).filter(({value}) => value !== EDM_STATEMENT_STATUS.TO_PREPARE.VALUE),
];

export const EDM_STATEMENT_PARTICIPANTS_TYPE = {
    SENDER: {
        TEXT: "Отправитель",
        VALUE: "SENDER",
    },
    RECEIVER: {
        TEXT: "Получатель",
        VALUE: "RECEIVER",
    },
    REVIEWER: {
        TEXT: "HR-менеджер",
        VALUE: "REVIEWER",
    },
    APPROVER: {
        TEXT: "Согласующий",
        VALUE: "APPROVER",
    },
};

export const EDM_STATEMENT_PARTICIPANTS_STATUS = {
    [EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.VALUE]: {
        IN_LINE_FOR_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.TEXT} ожидает`,
            VALUE: "IN_LINE_FOR_SIGNING",
            MOD: "gray",
        },
        ON_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.TEXT} подписывает`,
            VALUE: "ON_SIGNING",
            MOD: "orange",
        },
        SIGNED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.TEXT} подписал`,
            VALUE: "SIGNED",
            MOD: "green",
        },
        REJECTED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.TEXT} отклонил`,
            VALUE: "REJECTED",
            MOD: "red",
        },
    },
    [EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.VALUE]: {
        IN_LINE_FOR_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.TEXT} ожидает`,
            VALUE: "IN_LINE_FOR_SIGNING",
            MOD: "in-progress",
        },
        ON_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.TEXT} подписывает`,
            VALUE: "ON_SIGNING",
            MOD: "on-signing",
        },
        SIGNED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.TEXT} подписал`,
            VALUE: "SIGNED",
            MOD: "signed",
        },
        REJECTED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.TEXT} отклонил`,
            VALUE: "REJECTED",
            MOD: "rejected",
        },
    },
    [EDM_STATEMENT_PARTICIPANTS_TYPE.REVIEWER.VALUE]: {
        IN_LINE_FOR_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.REVIEWER.TEXT} ожидает`,
            VALUE: "IN_LINE_FOR_SIGNING",
            MOD: "in-progress",
        },
        ON_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.REVIEWER.TEXT} подписывает`,
            VALUE: "ON_SIGNING",
            MOD: "on-signing",
        },
        SIGNED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.REVIEWER.TEXT} одобрил`,
            VALUE: "SIGNED",
            MOD: "signed",
        },
        REJECTED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.REVIEWER.TEXT} отклонил`,
            VALUE: "REJECTED",
            MOD: "rejected",
        },
    },
    [EDM_STATEMENT_PARTICIPANTS_TYPE.RECEIVER.VALUE]: {
        IN_LINE_FOR_SIGNING: {
            TEXT: "В очереди получателя",
            VALUE: "IN_LINE_FOR_SIGNING",
            MOD: "in-progress",
        },
        ON_SIGNING: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.RECEIVER.TEXT} подписывает`,
            VALUE: "ON_SIGNING",
            MOD: "on-signing",
        },
        SIGNED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.RECEIVER.TEXT} подписал`,
            VALUE: "SIGNED",
            MOD: "signed",
        },
        REJECTED: {
            TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.RECEIVER.TEXT} отклонил`,
            VALUE: "REJECTED",
            MOD: "rejected",
        },
    },
};

export const TEMPLATE_STATEMENT = {
    APPLICATION_REDUCTION_PERSONAL_INCOME_TAX_BY_AMOUNT_OF_ADVANCE: "EDM_STATEMENT_TEMPLATE_2021_000001",
    APPLICATION_FOR_ISSUANCE_OF_AN_EARLY_ADVANCE_PAYMENT: "EDM_STATEMENT_TEMPLATE_2021_000004",
    LOAN_AGREEMENT: "EDM_STATEMENT_TEMPLATE_2021_000002",//шаблон приходит МП
};

//ТЕ шаблоны,которые заполняеются с фронт прижения
export const ADMIN_TEMPLATE_STATEMENT = {
    APPLICATION_REDUCTION_PERSONAL_INCOME_TAX_BY_AMOUNT_OF_ADVANCE: "EDM_STATEMENT_TEMPLATE_2021_000001",
    APPLICATION_FOR_ISSUANCE_OF_AN_EARLY_ADVANCE_PAYMENT: "EDM_STATEMENT_TEMPLATE_2021_000004",
};

export const STATEMENT_PARAMS = {
    [TEMPLATE_STATEMENT.APPLICATION_REDUCTION_PERSONAL_INCOME_TAX_BY_AMOUNT_OF_ADVANCE]: [
        "amountOfIncome",
    ],
    [TEMPLATE_STATEMENT.APPLICATION_FOR_ISSUANCE_OF_AN_EARLY_ADVANCE_PAYMENT]: [
        "period",
        "advanceAmount",
    ],
    [TEMPLATE_STATEMENT.LOAN_AGREEMENT]: [
        "loanAmount",
        "statementNumber",
        "subjectRU",
    ],
};