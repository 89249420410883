import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import {MediaButtons} from "../../../components/ActualComponents/MediaControls";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../components/ActualComponents/NmPageInfoCardsAccordion";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import {RESULT_STATUS} from "../../../components/BankIndicator";
import BankIndicatorIcon from "../../../components/BankIndicator/components/icon";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import InvitePerformerToNaimix from "../../../components/InvitePerformerToNaimix";
import LocatedOutsideRussiaTooltip from "../../../components/LocatedOutsideRussiaTooltip";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddBoxIcon} from "../../../images/add_box.svg";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";
import {ReactComponent as UserIcon} from "../../../images/user_24.svg";
import RegistryStepSearchContractor from "../../registries/registry-step-contractor-search";
import CreateEditPatentPayment from "../create-edit-patent-payment";
import PatentsCardFilter from "./filter";
import PatentsCardIFNS from "./IFNS";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {
    formatAmount,
    formatAmountWithNullChecking,
    formatNumber,
    phoneFormat,
    removePhoneMask,
} from "../../../utils/stringFormat";
import {
    handleFormString,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {DEFINING_REGISTRY_PARAMETER_TYPE} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../../constants/links";
import {
    FINANCE_PATENTS_PAYMENTS_STATUS_COLOR_TRANSCRIPT,
    PATENTS_PAYMENTS_ACTION,
    PATENTS_PAYMENTS_ITEM_ACTION,
    PATENTS_PAYMENTS_STATUS_CODE,
} from "../../../constants/patentsPayments";
import {
    PATENTS_REGISTRY_STATUS,
    PATENTS_REGISTRY_STATUS_COLOR_TRANSCRIPT,
    PATENTS_REGISTRY_STATUS_DICT,
} from "../../../constants/patentsRegistry";
import {
    ADMIN,
    CLIENT_ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {getClientCardSelector} from "../../../ducks/client";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {availableForPaymentsSelector, getAvailableForPayments} from "../../../ducks/deposit";
import {downloadDocument} from "../../../ducks/documents";
import {addPatentsRegistryPaymentsDocumentsExport} from "../../../ducks/documentsExport";
import {avatarBase64ImagesListSelector} from "../../../ducks/fileStore";
import {
    getBalanceDepositPatents,
    patentsDepositBalanceSelector,
} from "../../../ducks/patentsDeposits";
import {
    addPatentsPaymentsFromFile,
    deletePatentsPayment,
    getPatentsPayments,
    getPaymentStatusDict,
    patentsPaymentsActionProgressSelector,
    patentsPaymentsListSelector,
    patentsPaymentsListTotalPagesSelector,
    patentsPaymentsProgressListSelector,
    patentsPaymentsTotalCountSelector,
    paymentStatusDictSelector,
} from "../../../ducks/patentsPayments";
import {
    checkPatentRegistryPaymentsDuplicates,
    deleteFailedPatentRegistryPayments,
    getPatentRegistryById,
    patentsRegistriesActionRegistryProgressSelector,
    patentsRegistriesProgressCardSelector,
    patentsRegistryCardSelector,
    payPatentRegistry,
    payUnpaidPatentRegistry,
    refreshPatentRegistryPaymentsStatus,
    updateFieldPatentRegistryStore} from "../../../ducks/patentsRegistry";

import "./style.sass";

class PatentsRegistryCard extends Component {
    static propTypes = {};

    static defaultProps = {};

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    patentRegistryId,
                },
            },
        } = props;

        this.clientId = clientId;
        this.registryId = patentRegistryId;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            filterData: {},
            selectedList: [],
            isShowModalWindowEditPatentPayment: false,
            currentPayment: {},
            selectedPaymentsSum: 0,
            isInnTypeImportContractors: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getBalanceDepositPatents,
            getPaymentStatusDict,
        } = this.props;

        document.querySelector(".client-card").scrollTo(0, 0);

        getPaymentStatusDict();
        this.fetchRegistry();
        this.fetchList();

        getBalanceDepositPatents({
            clientId: this.clientId,
        });
    }

    componentWillUnmount() {
        const {updateFieldPatentRegistryStore} = this.props;

        updateFieldPatentRegistryStore({
            card: {},
        });
    }

    static getDerivedStateFromProps(props, state) {
        const {list: oldList, selectedList} = state;
        const {list} = props;

        if (!isEqual(list, oldList) || list.length !== selectedList.length) {
            return {
                ...state,
                selectedList: list,
                selectedListCounter: 0,
                selectedPaymentsSum: 0,
                list,
            };
        }

        return state;
    };

    get isClientArchived() {
        const {
            client: {
                archived,
            },
        } = this.props;

        return archived;
    }

    fetchRegistry = () => {
        const {
            getPatentRegistryById,
            getAvailableForPayments,
        } = this.props;

        getPatentRegistryById({
            clientId: this.clientId,
            patentRegistryId: this.registryId,
            getResult: ({objectId}) => {
                if (objectId) {
                    getAvailableForPayments({
                        isCivil: true,
                        clientId: this.clientId,
                        objectId,
                    });
                }
            },
        });
    };

    fetchList = (filterData = {}) => {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            getPatentsPayments,
        } = this.props;

        const {
            phoneFilter,
        } = filterData;

        getPatentsPayments({
            pageNum,
            pageSize,
            clientIdFilter: this.clientId,
            patentRegistryIdFilter: this.registryId,
            ...filterData,
            phoneFilter: handleFormString(removePhoneMask(phoneFilter)),
        });
    };

    deleteRegistryItems = () => {
        const {deletePatentsPayment} = this.props;
        const {selectedList} = this.state;

        const patentPaymentIds = selectedList.filter(({isSelected}) => isSelected).map(({patentPaymentId}) => patentPaymentId);

        deletePatentsPayment({
            clientId: this.clientId,
            patentRegistryId: this.registryId,
            patentPaymentIds,
            onSuccess: this.onActionSuccess,
        });
    };

    payRegistry = () => {
        const {payPatentRegistry} = this.props;

        payPatentRegistry({
            clientId: this.clientId,
            patentRegistryId: this.registryId,
            onSuccess: this.onActionSuccess,
        });
    };

    payUnpaidRegistry = () => {
        const {payUnpaidPatentRegistry} = this.props;

        payUnpaidPatentRegistry({
            clientId: this.clientId,
            patentRegistryId: this.registryId,
            onSuccess: this.onActionSuccess,
        });
    };

    checkDuplicatesPayments = () => {
        const {checkPatentRegistryPaymentsDuplicates} = this.props;

        checkPatentRegistryPaymentsDuplicates({
            data: {
                clientId: this.clientId,
                patentRegistryId: this.registryId,
            },
            onSuccess: ({result}) => {
                if (result) {
                    this.showActionConfirm(PATENTS_PAYMENTS_ACTION.DELETE_DUPLICATES_ITEMS);

                    return;
                }

                this.payRegistry();
            },
        });
    };

    deleteFailedPayments = () => {
        const {deleteFailedPatentRegistryPayments} = this.props;

        deleteFailedPatentRegistryPayments({
            clientId: this.clientId,
            patentRegistryId: this.registryId,
            onSuccess: this.onActionSuccess,
        });
    };

    refreshStatus = () => {
        const {refreshPatentRegistryPaymentsStatus} = this.props;

        refreshPatentRegistryPaymentsStatus({
            clientId: this.clientId,
            patentRegistryId: this.registryId,
        });
    };

    onActionSuccess = () => {
        this.setState({
            isOpenConfirm: false,
            selectedPaymentsSum: 0,
        });

        this.fetchList();
        this.fetchRegistry();
    };

    submitFilter = (filterData) => {
        this.setState({
            pageNum: 1,
        }, this.fetchList(filterData));
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                dropdownPaymentNumber: "",
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handleOnClickBack = () => {
        history.push(LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST.replace(":clientId", this.clientId));
    };

    openImportFileModal = (isShow) => {
        const {client} = this.props;

        return () => {
            this.setState({
                isOpenImportItemsModal: isShow,
                isInnTypeImportContractors: client.definingRegistryParameterType === DEFINING_REGISTRY_PARAMETER_TYPE.INN,
            });

            if (!isShow) {
                this.onActionSuccess();
            }
        };
    };

    toggleTypeImportContractors = (event, {checked}) => {
        this.setState(prevState => {
            return {
                ...prevState,
                isInnTypeImportContractors: checked,
            };
        });
    };

    submitImportForm = ({file}) => {
        const {addPatentsPaymentsFromFile} = this.props;
        const {isInnTypeImportContractors} = this.state;

        const formData = new FormData();
        formData.append("file", file);

        addPatentsPaymentsFromFile({
            data: formData,
            clientId: this.clientId,
            patentRegistryId: this.registryId,
            clientDefiningRegistryParameterType: isInnTypeImportContractors ? DEFINING_REGISTRY_PARAMETER_TYPE.INN : DEFINING_REGISTRY_PARAMETER_TYPE.PHONE,
            onSuccess: this.openImportFileModal(false),
        });
    };

    isDisabledCheckBox = (status) => {
        const {
            card: {
                archived,
            },
        } = this.props;

        if (![ADMIN, CLIENT_ADMIN, PROJECT_MANAGER, NM_MANAGER].includes(this.role)) {
            return true;
        }

        return !([PATENTS_PAYMENTS_STATUS_CODE.ERROR, PATENTS_PAYMENTS_STATUS_CODE.DECLINED].includes(status) || isNullOrWhitespace(status)) || archived;
    };

    getRows = () => {
        const {
            list,
            userAvatarDict,
        } = this.props;
        const {
            selectedList,
        } = this.state;

        return list.map(item => {
            const registryPayment = selectedList.find(value => (value.num === item.num)) || {};
            const {
                isSelected,
            } = registryPayment;

            const {
                num,
                paymentSum,
                contractorFio,
                period,
                contractorPhone,
                contractorInn,
                paymentStatus,
                statusDate,
                address,
                recipientFio,
                contractorId,
                locatedOutsideRussia,
            } = item;

            return {
                ...item,
                isSelected,
                disabledCheckBox: this.isDisabledCheckBox(paymentStatus),
                showCheckBox: true,
                error: this.getRowClass(item),
                avatar: (
                    <Avatar
                        addedToMyClientGroup={item.addedToMyClientGroup}
                        contractorId={contractorId}
                        base64={userAvatarDict[contractorId]}
                    />
                ),
                contentRow: (
                    <NmListCard
                        checkbox
                        avatar
                        classNameMainContent="col-16 col-xxl-9"
                        secondaryHeader={`Налоговый период: ${dateFormat(convertUtcToLocal(period), "dd.MM.yyyy")}`}
                        primaryHeader={contractorFio ? contractorFio : "Не определено"}
                        primaryHeaderNoWrap
                        labels={[
                            {label: "Номер строки", text: num},
                            {
                                label: "Номер телефона",
                                text: phoneFormat(contractorPhone, locatedOutsideRussia),
                                classNameText: "flex align-items-center",
                                textOverflowUnset: true,
                                textTooltip: locatedOutsideRussia && <LocatedOutsideRussiaTooltip />,
                            },
                            contractorInn && {label: "ИНН", text: contractorInn},
                            {label: "Адрес исполнителя", text: address},
                            {label: "Возможность оплаты", text: this.renderPossibilityPayment(item), alignItems: "center", noWrap: false},
                            {label: "Получатель заявлений в кадрах", text: recipientFio ? recipientFio : "Не определено"},
                            {label: "Реквизиты получателя (ИФНС)", text: this.renderRecipientDetails(item), noWrap: false},
                        ]}
                        cards={[
                            {
                                title: "Сумма, ₽",
                                value: formatAmount(formatNumber(paymentSum, 2)),
                                className: "col-16 col-md-6 col-xxl-8 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Статус и дата оплаты",
                                subTitle: this.renderStatus(item),
                                value: dateFormat(convertUtcToLocal(statusDate), "dd.MM.yyyy HH:mm"),
                                className: "col-16 col-md-10 col-xxl-8 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsContainerClassName="col-16 col-md-10 col-xxl-6"
                        cardsWithContainer
                        actionsClassName="col-1"
                        actions={this.renderActionCell(item)}
                    />
                ),
            };
        });
    };

    handleChange = (updatedState) => {
        return () => {
            this.setState({
                ...updatedState,
            });
        };
    };

    onSelectedRows = (selectedList, isAllSelected) => {
        const selectedPaymentsSum = selectedList
            .filter(item => item.isSelected)
            .reduce((accum, {paymentSum}) => {
                return accum + paymentSum;
            }, 0);

        this.setState({
            isAllSelected,
            selectedList,
            selectedListCounter: selectedList.filter(item => item.isSelected).length,
            selectedPaymentsSum,
        });
    };

    closeActionConfirm = () => {
        this.setState({
            dropdownAction: null,
            isOpenConfirm: false,
        });
    };

    showDeleteItemsConfirm = () => {
        this.setState({
            dropdownAction: {
                text: "Вы действительно хотите удалить выбранные строки из реестра?",
                action: this.deleteRegistryItems,
            },
            isOpenConfirm: true,
        });
    };

    handleDownload({downloadLink, isDownload}) {
        const {
            downloadDocument,
        } = this.props;

        downloadDocument({
            isDownload,
            downloadLink,
        });
    }

    addRegistryDocumentsExport = () => {
        const {
            addPatentsRegistryPaymentsDocumentsExport,
            list,
        } = this.props;

        if (list.length === 0) {
            toastError("В реестре отсутствуют документы для выгрузки");

            return;
        }

        addPatentsRegistryPaymentsDocumentsExport({
            clientId: this.clientId,
            registryId: this.registryId,
        });
    };

    onClickActionCell = (option, item) => {
        switch (option.value) {
        case PATENTS_PAYMENTS_ITEM_ACTION.EDIT:
            this.setState(prevState => ({
                ...prevState,
                currentPayment: item,
                isShowModalWindowEditPatentPayment: true,
            }));
            return;
        case PATENTS_PAYMENTS_ITEM_ACTION.PAYMENT_ORDER:
            const {receiptDocumentDownloadLink} = item;

            this.handleDownload({
                isDownload: false,
                downloadLink: receiptDocumentDownloadLink,
            });

            return;
            // no default
        }
    };

    renderAmount = amount => {
        return amount === null ? "0,00" : formatAmount(formatNumber(amount, 2));
    };

    get cardInfo() {
        const {
            card: {
                registerSum,
                requiredDeposit,
            },
            clientProperties: {
                depositDistributedByObjects,
            },
            availableForPayments,
        } = this.props;

        const {
            selectedPaymentsSum,
        } = this.state;

        return [
            {
                title: "Сумма выбранных строк",
                value: `${this.renderAmount(selectedPaymentsSum)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: "Сумма реестра",
                value: `${this.renderAmount(registerSum)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: "Необходимый депозит",
                value: `${this.renderAmount(requiredDeposit)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: "Доступно для выплат по объекту",
                value: `${formatAmountWithNullChecking(availableForPayments)} ₽`,
                className: "col-xl-4 col-xxl-2",
                isVisible: depositDistributedByObjects,
            },
        ];
    }

    showActionConfirm = (type) => {
        switch (type) {
        case PATENTS_PAYMENTS_ACTION.TRANSFER_TO_PAY:
            this.setState({
                dropdownAction: {
                    text: "Вы действительно хотите передать реестр в оплату?",
                    action: this.checkDuplicatesPayments,
                },
                isOpenConfirm: true,
            });
            return;
        case PATENTS_PAYMENTS_ACTION.REPEAT_TRANSFER_TO_PAY:
            this.setState({
                dropdownAction: {
                    text: "Вы действительно хотите повторить невыполненные платежи реестра?",
                    action: this.payUnpaidRegistry,
                },
                isOpenConfirm: true,
            });
            return;
        case PATENTS_PAYMENTS_ACTION.CANCEL_DECLINED:
            this.setState({
                dropdownAction: {
                    text: "Вы действительно хотите удалить невыполненные платежи реестра?",
                    action: this.deleteFailedPayments,
                },
                isOpenConfirm: true,
            });
            return;
        case PATENTS_PAYMENTS_ACTION.DELETE_DUPLICATES_ITEMS:
            this.setState({
                dropdownAction: {
                    text: "Внимание! В реестре найдены дубли строк по оплате патента одному и тому же исполнителю. " +
                        "Для передачи реестра в оплату их необходимо удалить. " +
                        "Вы подтверждаете операцию удаления дублей и передачи реестра в оплату?",
                    action: this.payRegistry,
                },
                isOpenConfirm: true,
            });
            return;
            // no default
        }
    };

    getItemActionOptions = (item) => {
        const {
            card: {
                status,
                archived,
            },
        } = this.props;
        const {
            paymentStatus,
            receiptDocumentDownloadLink,
        } = item;

        const options = {
            EDIT: {
                key: PATENTS_PAYMENTS_ITEM_ACTION.EDIT,
                text: "Редактировать",
                value: PATENTS_PAYMENTS_ITEM_ACTION.EDIT,
            },
            PAYMENT_ORDER: {
                key: PATENTS_PAYMENTS_ITEM_ACTION.PAYMENT_ORDER,
                text: "Платежное поручение",
                value: PATENTS_PAYMENTS_ITEM_ACTION.PAYMENT_ORDER,
            },
        };

        const resultOptions = [];

        if (this.isClientArchived) {
            return resultOptions;
        }

        if (
            (
                [
                    PATENTS_REGISTRY_STATUS.FORMED,
                ].includes(status)
            || [
                PATENTS_PAYMENTS_STATUS_CODE.ERROR,
                PATENTS_PAYMENTS_STATUS_CODE.DECLINED,
            ].includes(paymentStatus)
            )
            && [
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                PROJECT_MANAGER,
            ].includes(this.role)
            && !archived
        ) {
            resultOptions.push(options.EDIT);
        }

        if ([PATENTS_PAYMENTS_STATUS_CODE.DEPOSITED].includes(paymentStatus) && receiptDocumentDownloadLink) {
            resultOptions.push(options.PAYMENT_ORDER);
        }

        return resultOptions;
    };

    renderActionCell(item) {
        const options = this.getItemActionOptions(item, this.role);

        if (isEmpty(options)) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    this.onClickActionCell(option, item);
                }}
            />
        );
    };

    renderAddContractorToNmForm() {
        const {
            openAddContractorForm,
        } = this.state;

        return openAddContractorForm &&
            <InvitePerformerToNaimix
                close={this.handleChange({openAddContractorForm: false})}
                clientId={this.clientId}
            />;
    };

    renderRecipientDetails = (item) => {
        return (
            <PatentsCardIFNS
                item={item}
            />
        );
    };

    renderStatus = ({paymentStatus, statusReason}) => {
        const {paymentStatusDict} = this.props;

        if (!paymentStatus) {
            return "-";
        }

        return (
            <div className="patents-card__payment-status">
                <Text
                    color={FINANCE_PATENTS_PAYMENTS_STATUS_COLOR_TRANSCRIPT[paymentStatus]}
                    level="2"
                >
                    {paymentStatusDict[paymentStatus]}
                </Text>
                {
                    (
                        [
                            PATENTS_PAYMENTS_STATUS_CODE.ERROR,
                            PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR,
                            PATENTS_PAYMENTS_STATUS_CODE.DECLINED,
                        ].includes(paymentStatus)
                        && statusReason
                    ) &&
                    <HelpTooltip
                        hover
                        info
                        position="top"
                        width={18}
                        height={18}
                        text={statusReason}
                        className="patents-card__info-icon"
                    />
                }
            </div>
        );
    };

    renderPossibilityPayment = ({paymentAbility, paymentAbilityReason}) => {
        return (
            <div className="patents-card__payment-ability">
                {
                    paymentAbility ?
                        <BankIndicatorIcon result={RESULT_STATUS.CHECK_PASSED} /> :
                        <NmAdvancedTooltip
                            hover
                            position="top"
                            className="patents-card__payment-ability-tooltip"
                            suggestion={paymentAbilityReason}
                            trigger={
                                <BankIndicatorIcon result={RESULT_STATUS.CHECK_FAILED} />
                            }
                        >
                            {paymentAbilityReason}
                        </NmAdvancedTooltip>
                }
            </div>
        );
    };

    renderImportPaymentsForm() {
        const {actionPaymentsProgress} = this.props;
        const {
            isOpenImportItemsModal,
            isInnTypeImportContractors,
        } = this.state;

        const link = isInnTypeImportContractors ?
            "/files/Шаблон_Реестр оплат патентов по ИНН.xlsx" :
            "/files/Шаблон_Реестр оплат патентов по номеру телефона.xlsx";

        return isOpenImportItemsModal &&
            <ImportFromFilePatternV2
                patternLink={link}
                isDefiningParam={true}
                definingParamChecked={isInnTypeImportContractors}
                onChangeDefiningParam={this.toggleTypeImportContractors}
                onSubmit={this.submitImportForm}
                onClose={this.openImportFileModal(false)}
                progress={actionPaymentsProgress}
                templateText={
                    isInnTypeImportContractors
                        ? "предложенному шаблону по ИНН"
                        : "предложенному шаблону по номерам телефона"
                }
            />;
    };

    renderList() {
        const {
            selectedList,
            selectedListCounter,
        } = this.state;

        if (isEmpty(selectedList)) {
            return (
                <NmEmptyPageV2
                    title="Данные отсутствуют"
                />
            );
        }

        return (
            <CheckboxList
                count={selectedListCounter}
                mediaControls={{
                    renderCount: {
                        desktop: 1,
                        tablet: 1,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                disabled: !selectedListCounter,
                                onClick: this.showDeleteItemsConfirm,
                                children: "Удалить строку",
                                color: "grey",
                            },
                        },
                    ],
                }}
                onSelectedRows={!this.isClientArchived && this.onSelectedRows}
                rows={this.getRows()}
            />
        );
    };

    renderProjectInfo = ({projectName, projectId, clientId}) => {
        if (!projectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId || this.clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    renderObjectInfo = ({objectName, projectId, objectId, clientId}) => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    renderRegistryInfo() {
        const {
            card,
        } = this.props;

        const {
            registryName,
            registryComment,
        } = card;

        return (
            <>
                <div className="mb-2 mb-4">
                    <Text.Title
                        bold
                        level="4"
                        color={COLOR.SECONDARY_100}
                        className="mb-1"
                    >
                        {registryName}
                    </Text.Title>
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_100}
                    >
                        {registryComment}
                    </Text>
                    <NmLabelText
                        type="page"
                        label="Проект"
                        text={this.renderProjectInfo(card)}
                    />
                    <NmLabelText
                        type="page"
                        label="Объект"
                        text={this.renderObjectInfo(card)}
                    />
                </div>
                <NmPageInfoCardsAccordion
                    desktopViewFrom="xxl"
                    name="Финансовые показатели"
                    cards={this.cardInfo}
                />
            </>
        );
    };

    renderRegistryStatus() {
        const {
            card: {
                status,
            },
        } = this.props;

        return (
            <NmBadge
                text={PATENTS_REGISTRY_STATUS_DICT[status]}
                mod={PATENTS_REGISTRY_STATUS_COLOR_TRANSCRIPT[status]}
            />
        );
    };

    openSearchContractor = () => {
        this.setState({
            isOpenSearchContractor: true,
        });
    };

    closeContractorParamsEdit = () => {
        this.setState({
            isOpenContractorParamsEdit: false,
            isOpenSearchContractor: false,
            contractorData: {},
            paymentParams: {},
            isEditRealContractor: false,
        });
    };

    renderSearchPerformer() {
        const {isOpenSearchContractor, paymentParams = {}} = this.state;

        return isOpenSearchContractor &&
                <RegistryStepSearchContractor
                    isEdit={paymentParams.registrySeqNum}
                    paymentParams={paymentParams}
                    onClose={this.closeContractorParamsEdit}
                    clientId={this.clientId}
                    patentRegistryId={this.registryId}
                    registryType="patents"
                    fetchList={this.fetchList}
                    fetchRegistry={this.fetchRegistry}
                />;
    }

    renderSubHeader() {
        const {
            totalCount,
            card: {
                archived,
                status,
            },
            actionProgress,
        } = this.props;

        const isVisibleButtons = ([ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role) && !archived && !this.isClientArchived);

        return (
            <div className="mb-3 mb-md-5 patents-card__list-header">
                <NmPageHeader
                    size="lg"
                    text="Исполнители"
                    totalCount={totalCount}
                />
                <MediaButtons
                    className="patents-card__media-buttons"
                    classNameContextMenu="patents-card__media-context-menu"
                    config={{
                        renderCount: {
                            desktop: 3,
                            tablet: 0,
                            mobile: 0,
                        },
                        buttons: [
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    children: "Добавить исполнителя",
                                    icon: (
                                        <AddBoxIcon
                                            width={24}
                                            height={24}
                                        />
                                    ),
                                    onClick: this.openSearchContractor,
                                    className: "patents-card__page-button",
                                },
                                visible: isVisibleButtons && [PATENTS_REGISTRY_STATUS.FORMED].includes(status),
                            },
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    icon: (
                                        <FileDownloadIcon
                                            width={24}
                                            height={24}
                                        />
                                    ),
                                    children: "Загрузить список исполнителей",
                                    onClick: this.openImportFileModal(true),
                                    className: "patents-card__page-button",
                                },
                                visible: isVisibleButtons && [PATENTS_REGISTRY_STATUS.FORMED].includes(status),
                            },
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    icon: (
                                        <UserIcon
                                            width={24}
                                            height={24}
                                        />
                                    ),
                                    children: "Пригласить исполнителя в Наймикс",
                                    onClick: this.handleChange({openAddContractorForm: true}),
                                    className: "patents-card__page-button",
                                },
                                visible: isVisibleButtons,
                            },
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    disabled: actionProgress,
                                    onClick: this.refreshStatus,
                                    children: "Обновить платежные данные",
                                },
                                visible: ![NM_OPERATOR].includes(this.role),
                            },
                        ],
                    }}
                />
            </div>
        );
    };

    getRowClass(item) {
        const requiredFields = [
            item.address,
            item.paymentSum,
            item.period,
            item.recipientName,
            item.recipientInn,
            item.recipientKpp,
            item.recipientOktmo,
            item.recipientRs,
            item.recipientBik,
            item.reason,
            item.recipientFio,
        ];

        return requiredFields.some(elem => isNullOrWhitespace(elem) || isEqual(elem, "Не определено"));
    };

    renderConfirm() {
        const {t} = this.props;
        const {
            isOpenConfirm,
            dropdownAction,
        } = this.state;

        if (!isOpenConfirm) {
            return null;
        }

        const {
            action,
            text,
        } = dropdownAction;

        return isOpenConfirm &&
            <NmConfirmV2
                onCancel={this.closeActionConfirm}
                onConfirm={action}
                confirmButton={t("button.confirm")}
                cancelButton={t("button.cancel")}
                content={text}
                size="sm"
            />;
    };

    renderEditPatentPayment = () => {
        const {isShowModalWindowEditPatentPayment, currentPayment} = this.state;
        const {
            contractorId,
            contractorFio,
            contractorPhone,
            clientId,
            patentRegistryId,
            ...rest
        } = currentPayment;

        return isShowModalWindowEditPatentPayment
            && (
                <CreateEditPatentPayment
                    onClose={() => {
                        this.setState(prevState => ({
                            ...prevState,
                            isShowModalWindowEditPatentPayment: false,
                            currentPayment: {},
                        }));
                    }}
                    contractorData={{
                        contractorId,
                        contractorFio,
                        contractorPhone,
                    }}
                    clientId={clientId}
                    fetchList={this.fetchList}
                    patentRegistryId={patentRegistryId}
                    data={rest}
                    isEdit
                />
            );
    };

    getMediaControls() {
        const {card, totalCount} = this.props;
        const {
            status,
            declinedCount,
            archived,
        } = card;

        const isVisible = !this.isClientArchived &&
            totalCount &&
            !archived &&
            [ADMIN, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role);

        const isVisibleUnActed = ![
            PATENTS_REGISTRY_STATUS.FORMED,
        ].includes(status) &&
            [
                PATENTS_REGISTRY_STATUS.PROCESSING,
                PATENTS_REGISTRY_STATUS.NOT_DEPOSITED,
                PATENTS_REGISTRY_STATUS.DEPOSITED_PARTIALLY,
            ].includes(status) &&
            declinedCount;

        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        onClick: this.addRegistryDocumentsExport,
                        children: "Выгрузить документы",
                    },
                    visible: !archived && ![NM_OPERATOR].includes(this.role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => this.showActionConfirm(PATENTS_PAYMENTS_ACTION.TRANSFER_TO_PAY),
                        children: "Передать в оплату",
                    },
                    visible: isVisible &&
                        [PATENTS_REGISTRY_STATUS.FORMED].includes(status),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Оплатить невыполненные",
                        onClick: () => this.showActionConfirm(PATENTS_PAYMENTS_ACTION.REPEAT_TRANSFER_TO_PAY),
                    },
                    visible: isVisible && isVisibleUnActed,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отменить невыполненные",
                        onClick: () => this.showActionConfirm(PATENTS_PAYMENTS_ACTION.CANCEL_DECLINED),
                    },
                    visible: isVisible && isVisibleUnActed,
                },
            ],
        };
    }

    render() {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            totalPages = 0,
            listProgress,
            cardProgress,
            actionProgress,
            totalCount,
        } = this.props;

        return (
            <NmPage
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                className="patents-card"
                isLoaded={listProgress || cardProgress || actionProgress}
                header={
                    <NmPageHeader
                        text="Реестр на оплату патентов"
                        handleOnClickBack={this.handleOnClickBack}
                        badge={this.renderRegistryStatus()}
                    />
                }
                subHeader={this.renderRegistryInfo()}
                mediaControls={this.getMediaControls()}
                typeFilter="vertical"
                filtersBase={
                    <PatentsCardFilter
                        submitFilter={this.submitFilter}
                    />
                }
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                totalCount={totalCount}
            >
                {this.renderSearchPerformer()}
                {this.renderConfirm()}
                {this.renderAddContractorToNmForm()}
                {this.renderImportPaymentsForm()}
                {this.renderSubHeader()}
                {this.renderList()}
                {this.renderEditPatentPayment()}
            </NmPage>
        );
    }
}


export default withPageData(connect(
    state => ({
        list: patentsPaymentsListSelector(state),
        listProgress: patentsPaymentsProgressListSelector(state),
        totalCount: patentsPaymentsTotalCountSelector(state),
        totalPages: patentsPaymentsListTotalPagesSelector(state),
        cardProgress: patentsRegistriesProgressCardSelector(state),
        actionProgress: patentsRegistriesActionRegistryProgressSelector(state),
        actionPaymentsProgress: patentsPaymentsActionProgressSelector(state),
        card: patentsRegistryCardSelector(state),
        depositBalance: patentsDepositBalanceSelector(state),
        paymentStatusDict: paymentStatusDictSelector(state),
        client: getClientCardSelector(state),
        userAvatarDict: avatarBase64ImagesListSelector(state),
        availableForPayments: availableForPaymentsSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
    }),
    {
        getPatentsPayments,
        addPatentsPaymentsFromFile,
        downloadDocument,
        getPatentRegistryById,
        payPatentRegistry,
        deletePatentsPayment,
        deleteFailedPatentRegistryPayments,
        checkPatentRegistryPaymentsDuplicates,
        getBalanceDepositPatents,
        getPaymentStatusDict,
        refreshPatentRegistryPaymentsStatus,
        updateFieldPatentRegistryStore,
        getAvailableForPayments,
        payUnpaidPatentRegistry,
        addPatentsRegistryPaymentsDocumentsExport,
    },
)(withTranslation()(PatentsRegistryCard)));
