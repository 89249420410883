export const INTERNAL_PAYMENTS_TKB_PAY_REQUEST = "INTERNAL_PAYMENTS_TKB_PAY_REQUEST";
export const INTERNAL_PAYMENTS_TKB_PAY_SUCCESS = "INTERNAL_PAYMENTS_TKB_PAY_SUCCESS";
export const INTERNAL_PAYMENTS_TKB_PAY_ERROR = "INTERNAL_PAYMENTS_TKB_PAY_ERROR";

export const INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST = "INTERNAL_PAYMENTS_LOGS_GET_PAGE_REQUEST";
export const INTERNAL_PAYMENTS_LOGS_GET_PAGE_SUCCESS = "INTERNAL_PAYMENTS_LOGS_GET_PAGE_SUCCESS";
export const INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR = "INTERNAL_PAYMENTS_LOGS_GET_PAGE_ERROR";

export const INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_REQUEST = "INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_REQUEST";
export const INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_SUCCESS = "INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_SUCCESS";
export const INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_ERROR = "INTERNAL_PAYMENTS_TKB_ALLOWED_EMAILS_ERROR";

export const INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST = "INTERNAL_PAYMENTS_TKB_STATUS_BATCH_REQUEST";
export const INTERNAL_PAYMENTS_TKB_STATUS_BATCH_SUCCESS = "INTERNAL_PAYMENTS_TKB_STATUS_BATCH_SUCCESS";
export const INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR = "INTERNAL_PAYMENTS_TKB_STATUS_BATCH_ERROR";

export const INTERNAL_PAYMENTS_UPDATE_STORE = "INTERNAL_PAYMENTS_UPDATE_STORE";