import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {RecruitmentAccountDropdown} from "../../../../../components/Recruitment/AccountDropdown";
import RecruitmentResponseSource from "../../../../../components/Recruitment/ResponseSource";
import {RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT} from "../../../../../constants/recruitment";
import {
    clearRecruitmentAccessControlJobBoardsStore,
} from "../../../../../ducks/bff/recruitment/access-control/job-boards/actionsCreators";
import {updateVacancyJobBoardAccount} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {bffRecruitmentVacancyProgressActionSelector} from "../../../../../ducks/bff/recruitment/vacancy/selectors";

import {getClassNames} from "../../../../../utils/classNames";
import {formatLocalDate} from "../../../../../utils/dateFormat";
import {isLastElementInArray} from "../../../../../utils/objectHelper";

export const RecruitmentVacancyJobBoardAccountChange = (props) => {
    const {
        clientId,
        vacancyId,
        account,
        onClose,
        fetchInfo,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);

    const [value, setValue] = useState("");

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentAccessControlJobBoardsStore());
        };
    }, []);

    const onSubmit = () => {
        dispatch(updateVacancyJobBoardAccount({
            vacancyId,
            clientId,
            jobBoardType: account.jobBoardType,
            jobBoardAccountId: value,
            onSuccess: () => {
                onClose();
                fetchInfo();
            },
        }));
    };

    const getAccountInfo = () => {
        return [
            {
                label: "ФИО владельца аккаунта в системе Наймикс",
                text: account.jobBoardAccount?.clientUserName || "-",
            },
            {
                label: "Джоб-борд",
                text: (
                    <RecruitmentResponseSource
                        isVisibleOnlyJobBoardName={true}
                        source={{
                            responseSource: account.jobBoardType,
                        }}
                    />
                ),
            },
            {
                label: "Логин от джоб-борда",
                text: account.jobBoardAccount?.login || "-",
            },
            {
                label: "Статус",
                text: RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT[account.jobBoardAccount?.status] || "-",
            },
            {
                label: "Дата создания",
                text: account.createDate ? formatLocalDate(account.createDate, "dd.MM.yyyy") : "-",
            },
        ].map((item, index, array) => {
            const {
                label,
                text,
            } = item;

            const isLast = isLastElementInArray(array, index);

            return (
                <NmLabelText
                    key={label}
                    label={label}
                    noWrap={false}
                    noWrapLabel={false}
                    columnOnMobile={true}
                    text={text}
                    className={getClassNames([
                        !isLast && "mb-2",
                    ])}
                />
            );
        });
    };

    return (
        <NmModal
            size="md"
            classNameContent="flex-column"
            header={
                <NmTitle size="lg">
                    Изменение аккаунта вакансии от джоб-борда
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    disabled={progressAction}
                    onClose={onClose}
                    submit={onSubmit}
                />
            }
            onClose={onClose}
        >
            <Text.Sub
                level="1"
                medium={true}
                className="mb-2"
            >
                Текущий аккаунт
            </Text.Sub>
            {getAccountInfo()}
            <RecruitmentAccountDropdown
                clientId={clientId}
                jobBoardType={account.jobBoardType}
                search={true}
                label="Изменить аккаунт"
                placeholder="Выберите новый аккаунт"
                className="mt-4 mt-md-6"
                name="jobBoardAccountId"
                value={value}
                onChange={(event, params) => {
                    setValue(params.value);
                }}
            />
        </NmModal>
    );
};