import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_MIGRATION_STATUS} from "../../../constants/contractor";

export const isOnlyArrivalNotification = (params) => {
    const {
        migrationStatus,
        citizenship,
    } = params;

    return !([
        CONTRACTOR_MIGRATION_STATUS.REFUGEE,
        CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
        CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
        CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
    ].includes(migrationStatus) ||
        [citizenshipsDict.NOT_SPECIFIED.value].includes(citizenship));
};