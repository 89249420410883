import React from "react";
import {isEmpty} from "lodash";

import SocialNetworkLogo from "../../../SocialNetworkLogo";
import {RecruitmentCandidateResumeLink} from "../components/ResumeLink";
import {getRecruitmentCandidatesExperiencePeriod} from "./getExperiencePeriod";

const getExperience = (experiences) => {
    return isEmpty(experiences) ?
        "-" :
        <ul style={{margin: 0, paddingInlineStart: 24}}>
            {experiences.map((item, index) => {
                const period = getRecruitmentCandidatesExperiencePeriod(item.periodFrom, item.periodTo);

                return (
                    <li key={`${item.period}${index}`}>
                        {`${period} ${item.organisation}, ${item.position}`}
                    </li>
                );
            })
            }
        </ul>;
};

export const getRecruitmentCandidateInformationValues = (card, dicts) => {
    const {
        educationLevelDict,
        workExperienceDict,
        driverLicenseDict,
        languageDict,
        schedulesDict,
    } = dicts;

    const education = educationLevelDict[card.education] || "-";
    const workExperience = workExperienceDict[card.workExperience] || "-";
    const driverLicences = !isEmpty(card.driverLicences) ? card.driverLicences.map(item => driverLicenseDict[item]).join(", ") : "-";
    const hasCar = getBooleanAttribute({
        textTrue: "Есть свой автомобиль",
        textFalse: "Нет своего автомобиля",
        value: card.hasCar,
    });
    const languages = !isEmpty(card.languages) ? card.languages.map(item => languageDict[item]).join(", ") : "-";
    const schedules = card.schedules ? card.schedules.map(item => schedulesDict[item]).join(", ") : "-";
    const readyForTrip = getBooleanAttribute({
        textTrue: "Готов к командировкам",
        textFalse: "Не готов к командировкам",
        value: card.readyForTrip,
    });
    const readyForMoving = getBooleanAttribute({
        textTrue: "Готов к переезду",
        textFalse: "Не готов к переезду",
        value: card.readyForMoving,
    });
    const resumes = !isEmpty(card.resumes) ? card.resumes.map(item => {
        return (
            <RecruitmentCandidateResumeLink
                key={item.fileName}
                type={item.type}
                fileName={item.fileName}
                candidateId={card.candidateId}
                className="me-1"
            />
        );
    }) : "-";
    const socialMedia = !isEmpty(card.socialMedia) ?
        card.socialMedia.map(item => {
            return (
                <a
                    key={item.id}
                    href={item.url}
                    title="Перейти по ссылке"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <SocialNetworkLogo
                        width={24}
                        height={24}
                        name={item.type}
                        className="me-1"
                    />
                </a>
            );
        }) : "-";
    const salary = !isEmpty(card.salary) ? `${card.salary.amount} ${card.salary.currency}` : "-";
    const notes = card.notes || "-";

    return {
        education,
        workExperience,
        driverLicences,
        hasCar,
        languages,
        schedules,
        readyForTrip,
        readyForMoving,
        resumes,
        socialMedia,
        salary,
        notes,
        experiences: getExperience(card.experiences),
    };
};

export const getBooleanAttribute = (params) => {
    const {
        textTrue,
        textFalse,
        value,
    } = params;

    if (value === true) {
        return textTrue;
    }

    if (value === false) {
        return textFalse;
    }

    return "-";
};