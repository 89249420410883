import React from "react";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {DataChange} from "../../../filter/hooks/useStatementFilter";
import {RejectErrorReasonType, RejectFormType} from "../../hooks/useRejectSigningStatement";

import bem from "../../../../../../utils/bem";

// (e: any, value: DataChange) => void,

export type RejectionSigningStatementModal = {
    className?: string,
    onClose: () => void,
    submit: () => void,
    errorForm: RejectErrorReasonType,
    form: RejectFormType,
    onChange: (event: any, data: DataChange) => void
}

const RejectionSigningStatementModal = (props: RejectionSigningStatementModal) => {
    const {
        className = "",
        onClose = () => {
        },
        submit,
        errorForm,
        onChange,
        form: {
            rejectionReason,
        },
    } = props;

    const [block, element] = bem("rejection-signing-statement", className);

    return (
        <NmModal
            size="md"
            className={element("modal")}
            onClose={onClose}
            header={
                <NmTitle
                    size="lg"
                >
                    Отклонение заявления
                </NmTitle>
            }
            footer={
                <div className={block()}>
                    <ApplyButtons
                        isHiddenCancelOnMobile
                        mobile="column"
                        onClose={onClose}
                        submitBtnContent="Подтвердить"
                        cancelBtnContent="Отменить"
                        submit={submit}
                    />
                </div>
            }
        >
            <NmForm>
                <NmTextareaV2
                    error={errorForm.rejectionReason}
                    placeholder="Введите причину отклонения"
                    minRows={4}
                    maxRows={6}
                    maxLength="255"
                    name="rejectionReason"
                    onChange={onChange}
                    value={rejectionReason}
                />
            </NmForm>
        </NmModal>
    );
};

export default RejectionSigningStatementModal;