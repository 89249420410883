import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {
    getRichActRegistry,
    updateActRegistriesStore,
} from "../../../../../ducks/actRegistries";
import {getPageActRegistryPayments} from "../../../../../ducks/actRegistryPayment";
import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {
    getAllTasks,
    jobTasksActRegistrySelector,
} from "../../../../../ducks/job";

export function useActRegistryCardFetchData({clientId, registryId, pageSize, pageNum, filter, payProgress}) {
    const progressTasks = useSelector(jobTasksActRegistrySelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    const dispatch = useDispatch();
    const prevTasksList = useGetPrevValue(progressTasks);

    useEffect(() => {
        fetchCard();

        return () => {
            dispatch(updateActRegistriesStore({card: {}}));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum, payProgress]);

    useEffect(() => {
        if (isEmpty(progressTasks) && !isEmpty(prevTasksList)) {
            fetchList();
        }
    }, [progressTasks]);

    const fetchTasks = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        dispatch(getAllTasks({
            clientId,
            clientUserId,
        }));
    };

    const fetchCard = () => {
        dispatch(getRichActRegistry({
            clientId,
            registryId,
        }));
    };

    const fetchList = () => {
        dispatch(getPageActRegistryPayments({
            clientId,
            registryId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
        fetchTasks,
    };
}