export const SELF_CONTRACTOR = "performers",
    PAY = "pay",
    DISPUTES = "disputes";

export const SUB_PAGE_ORDER_CONTRACTOR = {
    //отклики
    RESPONSES: {
        LINK: "responses",
        TAB_TYPE: "RESPONSE_TAB",
    },
    //приглашенные
    INVITED: {
        LINK: "invited",
        TAB_TYPE: "INVITED_TAB",
    },
    //предварительно одобренные
    PRE_APPROVED: {
        LINK: "pre-approved",
        TAB_TYPE: "PRE_APPROVED_TAB",
    },
    //подписывают договор
    SIGN_AGREEMENT: {
        LINK: "sign-agreement",
        TAB_TYPE: "CONTRACT_SIGN_TAB",
    },
    //нанятые
    HIRED: {
        LINK: "hired",
        TAB_TYPE: "HIRED_TAB",
    },
    //отказы
    REFUSALS: {
        LINK: "refusals",
        TAB_TYPE: "REFUSED_TAB",
    },
};

export const SUB_PAGE_SETTINGS_MEMBER = {
    //Партнер
    PARTNERS: {
        LINK: "partners",
        TAB_TYPE: "PARTNERS_TAB",
    },
    //
    GENERAL: {
        LINK: "general",
        TAB_TYPE: "GENERAL_TAB",
    },
};

export const TAB_TYPE_BY_SUB_PAGE = {
    //отклики
    responses: {
        LINK: "responses",
        TAB_TYPE: "RESPONSE_TAB",
    },
    //приглашенные
    invited: {
        LINK: "invited",
        TAB_TYPE: "INVITED_TAB",
    },
    //предварительно одобренные
    "pre-approved": {
        LINK: "pre-approved",
        TAB_TYPE: "PRE_APPROVED_TAB",
    },
    //подписывают договор
    "sign-agreement": {
        LINK: "sign-agreement",
        TAB_TYPE: "CONTRACT_SIGN_TAB",
    },
    //нанятые
    "hired": {
        LINK: "hired",
        TAB_TYPE: "HIRED_TAB",
    },
    //отказы
    "refusals": {
        LINK: "refusals",
        TAB_TYPE: "REFUSED_TAB",
    },
};

/*
CLOSE: "Закрыт"
IN_PROCESS: "Обрабатывается"
IN_WORK: "В работе"
MISSED: "Пропущен"
NEW: "Новое"
RECALL: "Перезвонить"
* */

export const SUB_PAGE_CRM_TICKET = {
    CHATS: {
        LINK: "chats",
        COUNT_NAME: "UNREAD_MESSAGES",
        TEXT: "Чаты",
    },
    //Партнер
    IN_WORK: {
        LINK: "in-work",
        STATUS: "IN_WORK",
        TEXT: "В работе",
    },
    //
    IN_PROCESS: {
        LINK: "in-process",
        STATUS: "IN_PROCESS",
        TEXT: "Обрабатывается",

    },
    RECALL: {
        LINK: "recall",
        STATUS: "RECALL",
        TEXT: "Перезвонить",

    },
    MISSED: {
        LINK: "missed",
        STATUS: "MISSED",
        TEXT: "Пропущено",
    },
    CLOSED: {
        LINK: "closed",
        STATUS: "CLOSED",
        TEXT: "Закрыто",
    },
    KEDO: {
        LINK: "kedo",
        TEXT: "КЭДО",
        COUNT_NAME: "KEDO_CHATS",
    },
    SETTINGS: {
        LINK: "settings",
        STATUS: "SETTINGS",
        TEXT: "Настройки",
    },
};

export const BRIGADE_ORDER_OBJECT_OBJECT = "order-object-list";
export const BRIGADE_TIME_SHEET = "timesheet";
export const BRIGADE_PAYMENTS = "payments";
export const BRIGADE_LIST = "list";

export const MY_CONTRACTORS = "my-contractors",
    AVAILABLE_CONTRACTORS = "available-contractors",
    ATTENTION_CONTRACTORS = "attention-contractors";

export const SUBPAGE_CONTRACTORS_REVIEWS = "about-contractors",
    SUBPAGE_CLIENTS_REVIEWS = "about-clients";

export const SUB_PAGE_CROWD_TASK_CARD = {
    //Приглашенные
    INVITED: {
        LINK: "invited",
        COUNT_NAME: "invitedContractorsCount",
        TEXT: "Приглашенные",
    },
    //В очереди
    QUEUE: {
        LINK: "queue",
        COUNT_NAME: "inQueueContractorsCount",
        TEXT: "В очереди",
    },
    //В работе
    IN_WORK: {
        LINK: "in-work",
        COUNT_NAME: "workingContractorsCount",
        TEXT: "В работе",
    },
    //Выполнил
    COMPLETED: {
        LINK: "completed",
        COUNT_NAME: "completedContractorsCount",
        TEXT: "Выполнил",
    },
    //Отказы
    REFUSED: {
        LINK: "refused",
        COUNT_NAME: "refusedContractorsCount",
        TEXT: "Отказы",
    },
};

export const SUB_PAGE_CROWD_TASK_ANALYTICS = {
    NO_RESPONSES: {
        LINK: "no-responses",
        COUNT_NAME: "noResponsesCount",
        TEXT: "Без откликов",
    },
    SIGN_CONTRACT: {
        LINK: "sign-contract",
        COUNT_NAME: "onContractSigningCount",
        TEXT: "Подписывают договор",
    },
    HIRED: {
        LINK: "hired",
        COUNT_NAME: "hiredCount",
        TEXT: "Нанятые",
    },
    IN_WORK: {
        LINK: "in-work",
        COUNT_NAME: "atWorkCount",
        TEXT: "В работе",
    },
    ON_REVIEW: {
        LINK: "on-review",
        COUNT_NAME: "onVerificationCount",
        TEXT: "На проверке",
    },
    IN_PAYMENT: {
        LINK: "in-payment",
        COUNT_NAME: "inProcessOfPaymentCount",
        TEXT: "В оплате",
    },
};
