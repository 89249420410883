import React, {FC} from "react";

import {getClassNames} from "../../../utils/classNames";

import "./style.sass";

interface CardInfoContainerProps {
    className?: string,
    children?: React.ReactNode,
}

const CardInfoContainer: FC<CardInfoContainerProps> = (props) => {
    const {
        children,
        className,
    } = props;

    return (
        <div
            className={getClassNames([
                "card-info-container",
                className,
            ])}
        >
            {children}
        </div>
    );
};

export default CardInfoContainer;