import React, {useState} from "react";
import {useDispatch} from "react-redux";

import NmModal from "../../../../../../components/ActualComponents/NmModal";
import {ReactComponent as ImageIcon} from "../../../../../../images/img_24.svg";

import bem from "../../../../../../utils/bem";
import {toastError} from "../../../../../../utils/toastHelper";

import {downloadDocument} from "../../../../../../ducks/documents";

import "./style.sass";

const CrowdTaskContractorImageBlock = ({link, className}) => {
    const [block, element] = bem("crowd-task-contractor-image-block", className);
    const [imageModalData, setImageModalData] = useState({});

    const dispatch = useDispatch();

    const openImageModal = () => {
        setImageModalData({
            isOpen: true,
            progress: true,
        });

        dispatch(downloadDocument({
            downloadLink: link,
            fileName: "test",
            isDownload: false,
            onError: () => {
                toastError("Не удалось загрузить файл");
                setImageModalData({});
            },
            getResultFile: (file) => {
                const fileWithPreview = Object.assign(file, {
                    preview: URL.createObjectURL(file),
                },
                );

                setImageModalData({
                    isOpen: true,
                    progress: false,
                    file: fileWithPreview,
                });
            },
        }));
    };

    const renderImageModal = () => {
        return (
            imageModalData.isOpen &&
            <NmModal
                size="sm"
                onClose={() => setImageModalData({})}
                loading={imageModalData.progress}
            >
                {
                    imageModalData.file &&
                    <img
                        className="col-16"
                        src={imageModalData.file.preview}
                        alt={""}
                    />
                }
            </NmModal>
        );
    };

    return (
        <>
            {renderImageModal()}
            <div
                className={block()}
                onClick={openImageModal}
            >
                <ImageIcon />
            </div>
        </>
    );
};

export default CrowdTaskContractorImageBlock;