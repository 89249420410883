import React from "react";

import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";

import {EXTERNAL_JOB_BOARDS_LIST, RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../constants";

export const JobBoardsCheckboxForm = (props) => {
    const {
        onChange,
        selectedJobBoards,
        activeJobBoardsTypes,
    } = props;

    return (
        <NmForm addMargin>
            {
                EXTERNAL_JOB_BOARDS_LIST.map(type => {
                    const checked = selectedJobBoards.includes(type);
                    const disabled = activeJobBoardsTypes ? !activeJobBoardsTypes.includes(type) : false;

                    return (
                        <NmCheckboxV2
                            label={RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[type]}
                            checked={checked}
                            disabled={disabled}
                            name={type}
                            onChange={onChange}
                        />
                    );
                })
            }
        </NmForm>
    );
};