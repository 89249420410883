export const CONTRACTOR_RECEIPT = {
    CREATE_SUCCESS: "Онлайн-чек успешно сформирован",
    CREATE_ERROR: "Не удалось сформировать онлайн-чек",
};

export const CONTRACTOR_CORRECTION_RECEIPT = {
    CREATE_SUCCESS: "Чек коррекции успешно сформирован",
    CREATE_ERROR: "Не удалось сформировать чек коррекции",
};

export const CONTRACTOR_RETURN_RECEIPT = {
    CREATE_SUCCESS: "Чек на возврат прихода успешно сформирован",
    CREATE_ERROR: "Не удалось сформировать чек на возврат прихода",
};

export const ARCHIVE = {
    CLIENT_SET_ARCHIVED_SUCCESS_TEXT: "Компания успешно заархивирована",
    CLIENT_SET_ARCHIVED_ERROR_TEXT: "Не удалось заархивировать компанию",
    CLIENT_SET_RESTORED_SUCCESS_TEXT: "Компания успешно восстановлена",
    CLIENT_SET_RESTORED_ERROR_TEXT: "Не удалось восстановить компанию",
};

export const LOCK = {
    CLIENT_SET_LOCKED_SUCCESS_TEXT: "Компания отключена",
    CLIENT_SET_LOCKED_ERROR_TEXT: "Не удалось отключить компанию",
    CLIENT_SET_UNLOCKED_SUCCESS_TEXT: "Компания включена",
    CLIENT_SET_UNLOCKED_ERROR_TEXT: "Не удалось включить компанию",
};

export const CLIENT = {
    CLIENT_ADD_SUCCESS_TEXT: "Компания успешно добавлена",
    CLIENT_ADD_ERROR_TEXT: "Не удалось добавить компанию",
    CLIENT_UPDATE_SUCCESS_TEXT: "Компания успешно отредактирована",
    CLIENT_UPDATE_ERROR_TEXT: "Не удалось отредактировать компанию",
};

export const CLIENT_MEMBER = {
    ADD_SUCCESS_TEXT: "Сотрудник успешно добавлен",
    ADD_ERROR_TEXT: "Не удалось добавить сотрудника",
    UPDATE_SUCCESS_TEXT: "Данные сотрудника успешно отредактированы",
    UPDATE_ERROR_TEXT: "Не удалось отредактировать данные сотрудника",
};

export const CLIENT_MEMBER_ARCHIVE = {
    TO: "Вы действительно хотите добавить сотрудника :name в архив?",
    FROM: "Вы действительно хотите восстановить сотрудника :name из архива?",
};

export const ORDER = {
    ORDER_CREATE_DUPLICATE: "Дубликат заказа успешно создан",
    ORDER_ADD_SUCCESS_TEXT: "Заказ успешно опубликован",
    ORDER_ADD_ERROR_TEXT: "Не удалось опубликовать заказ",
    ORDER_UPDATE_SUCCESS_TEXT: "Заказ успешно обновлен",
    ORDER_UPDATE_ERROR_TEXT: "Не удалось обновить заказ",
    ORDER_CONFIRM_CLOSE_TEXT: "Вы действительно хотите закрыть заказ \":num\"?",
    ORDER_CONFIRM_PUBLISHED_TEXT: "Вы действительно хотите опубликовать заказ?",
    ORDER_CONFIRM_PUBLISHED_DIRECT_TEXT: "После того как на заказе появится хотя бы один нанятый исполнитель, изменить его параметры будет нельзя. Опубликовать заказ?",
    COMPLETE_SUCCESS: "Закрытие успешно завершено",
    COMPLETE_LATER_SUCCESS: "Закрытие произойдет автоматически после подтверждения завершения работ каждым исполнителем",
    ORDER_COMPLETE_ERROR_TEXT: "Не удалось закрыть заказ",
    ORDER_CONTRACTOR_RECRUITMENT_WARNING_TEXT: "При публикации заказа с параметром “Требуется подбор” вы соглашаетесь на использование платной услуги.",
    ORDER_PUBLIC_CONTRACTOR_RECRUITMENT_WARNING_TEXT: "При публикации заказа с параметром “Требуется подбор” вы соглашаетесь на использование платной услуги. \nПосле того как на заказе появится хотя бы один нанятый исполнитель, изменить его параметры будет нельзя. Опубликовать заказ?",
};

export const TEMPLATE_MESSAGE = {
    ADD_SUCCESS: "Шаблон успешно добавлен",
    ADD_ERROR: "Не удалось добавить шаблон",
    UPDATE_SUCCESS: "Шаблон успешно обновлен",
    UPDATE_ERROR: "Не удалось обновить шаблон",
};

export const ORDER_WORK = {
    ORDER_WORK_ADD_SUCCESS_TEXT: "Работа успешно добавлена",
    ORDER_WORK_ADD_ERROR_TEXT: "Не удалось добавить работу",
    ORDER_WORK_DELETE_RANGE_TEXT: "Вы действительно хотите удалить запись?",
    ORDER_WORK_DELETE_RANGE_SUCCESS: "Запись успешно удалена",
};

export const ORDER_WORK_REPORT = {
    FOUNDED_DUPLICATED_PAYMENTS: "\tВнимание! По Вашей компании найдены незавершенные выплаты этому исполнителю с аналогичной суммой и описанием выполненных работ: :payments. \n\n\tВы уверены, что хотите создать еще одну выплату для исполнителя :name на сумму :sum Р?",
    UPDATE_PAYMENT_ERROR: "Не удалось обновить статус платежа",
    UPDATE_PAYMENT_SUCCESS: "Статус платежа успешно обновлен",
    ADD_SUCCESS_TEXT: "Акт сформирован и отправлен на согласование исполнителю",
    ADD_ERROR_TEXT: "Не удалось добавить запись в историю",
    ADD_CONFIRM_TEXT: "Вы действительно хотите сформировать акт для исполнителя, :name?",
    PAYMENT_REJECT: {
        SUCCESS: "Задача на отклонение выплат успешно запущена",
        ERROR: "Не удалось отклонить выплаты: :number",
        SIMPLE_ERROR: "Не удалось отклонить выплаты",
    },
    PAYMENT_REPORT: {
        SUCCESS: "Задача на подтверждение выплат :number запущена",
        ERROR: "Не удалось подтвердить выплату :number",
    },
    PAYMENT_TASK: {
        SUCCESS: "Задача на проведение выплат :number успешно запущена",
        ERROR: "Не удалось запустить задачу на проведение выплат",
    },
    PAYMENT_CONFIRM_ERROR: "Не удалось подтвердить выплаты",
    PAYMENT_CONFIRM: "Вы действительно хотите подтвердить выплату с номером: \":number\"?",
    CANCEL_CHECK_CONFIRM: "Вы действительно хотите аннулировать чек с номером выплаты: \":number\"?",
};

export const STAFF = {
    STAFF_ADD_SUCCESS_TEXT: "Исполнители успешно добавлены",
    STAFF_ADD_ERROR_TEXT: "Не удалось добавить исполнителей",
    STAFF_DELETE_ERROR_TEXT: "Не удалось отменить приглашение исполнителя",
};

export const DOCUMENTS_MESSAGE = {
    ERROR_404: "Не удалось найти документ",
};

export const CHECK_MESSAGE = {
    ERROR_404: "Нет ссылки на чек",
};

export const CONTRACTOR_MESSAGE = {
    CONTRACTOR_ADD_SUCCESS_TEXT: "Исполнитель успешно добавлен",
    CONTRACTOR_UPDATE_SUCCESS_TEXT: "Данные успешно обновлены",
    CONTRACTOR_UPDATE_ERROR_TEXT: "Не удалось обновить данные самозанятого",
    CONTRACTOR_SUPPORT_UPDATE_SUCCESS_TEXT: "Данные успешно сохранены",
    CONTRACTOR_SUPPORT_UPDATE_ERROR_TEXT: "Не удалось сохранить данные",
    CONTRACTOR_CHANGE_STATUS_SUCCESS: "Статус самозанятого успешно изменен",
    CONTRACTOR_CHANGE_STATUS_ERROR: "Не удалось обновить статус самозанятого",
    CONTRACTOR_CHANGE_STATUS_CLIENT_REVOKED: "Самозанятому, :name, отказано",
    CONTRACTOR_CHANGE_STATUS_COMPLETED: "Самозанятый, :name, снят с заказа",
    CONTRACTOR_CHANGE_STATUS_HIRED: "Самозанятый, :name, приглашён",
    CONTRACTOR_CHANGE_STATUS_AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: "Самозанятый, :name, приглашён на заказ",
};

export const SETTINGS_INFO_MESSAGE = {
    SETTINGS_INFO_SUCCESS_TEXT: "Данные успешно обновлены",
    SETTINGS_INFO_ERROR_TEXT: "Не удалось обновить данные",
};

export const CONFIRM_CONTRACTOR_ADD_TEXT = "Вы действительно хотите пригласить на заказ самозанятого :name?";
export const CONFIRM_CONTRACTORS_ADD_TEXT = "Вы действительно хотите добавить в заказ самозанятых, :name?";
export const CONFIRM_CONTRACTOR_PAY_TEXT = "Вы действительно хотите оплатить работы самозанятого, :name?";
export const CONFIRM_CONTRACTOR_DISMISSED_TEXT = "Вы действительно хотите уволить самозанятого, :name?";

export const CONFIRM_CONTRACTOR_INVITE_TEXT = "Вы действительно хотите пригласить на собеседование самозанятого, :name?";
export const CONFIRM_CONTRACTORS_INVITE_TEXT = "Вы действительно хотите пригласить на собеседование самозанятых, :name?";
export const CONFIRM_CONTRACTOR_REJECT_TEXT = "Вы действительно хотите отказать самозанятому, :name?";
export const CONFIRM_CONTRACTOR_HIRE_TEXT = "Вы действительно хотите пригласить на заказ самозанятого :name?";
export const CONFIRM_CONTRACTORS_REJECT_TEXT = "Вы действительно хотите отказать выбранным самозанятым?";

export const FILE_ERROR = {
    SIZE: "Превышен размер файла в\u00A02 мб",
    SIZE_25MB: "Превышен размер файла в\u00A025 мб",
    SCAN_SIZE: "Превышен размер файла в\u00A010 мб",
    AGENCY_CONTRACT_SIZE: "Превышен размер файла в\u00A010 мб",
    TYPE: "Неправильный тип файла",
    MAX_LENGTH: "Версия агентского договора не может содержать более 10 файлов.",
};

export const RECOVERY_CODE = {
    INVALID_LOGIN: "Пустое поле email",
    ALREADY_EXISTS: "Менее трёх часов назад уже был сделан запрос на смену пароля, проверьте вашу почту",
    NOT_EXISTS: "Пользователь с этим логином не существует",
    EMAIL_NOT_SENT: "Email не был отправлен, смена пароля отменена",
    INVALID_TOKEN: "Неверная ссылка",
    RECOVERY_NOT_REQUESTED: "Восстановление пароля не запрашивалось в течение ближайших 3 часов",
};

export const CONTRACTOR_SCAN_SEND = {
    SUCCESS: "Сканы документов успешно отправлены",
    ERROR: "Не удалось отправить документы",
};

export const DISPUTE_MESSAGES = {
    EMPTY_DISPUTE_CREATE_ERROR: "Произошла ошибка. Спор не будет создан.",
    DISPUTE_CREATE_SUCCESS: "Спор успешно сохранён.",
    DISPUTE_GET_LIST_ERROR: "Не удалось получить список споров.",
    DISPUTE_CHANGE_STATUS_ERROR: "Не удалось изменить статус спора.",
    DISPUTE_ADD_MESSAGE_ERROR: "Не удалось отправить сообщение.",
    DISPUTE_CHANGE_STATUS_SUCCESS: "Cтатус спора успешно изменён.",
    DISPUTE_IMAGES_COUNT_ERROR: "Разрешается добавлять не более 5 изображений",
    DISPUTE_ADD_TO_CHAT_SUCCESS: "Администратор компании успешно добавлен в чат",
};

export const CHAT_MESSAGES = {
    LOCK_CHAT_SUCCESS: "Чат заблокирован",
    UNLOCK_CHAT_SUCCESS: "Чат разблокирован",
};

export const FINANCE_EXPORT_MESSAGES = {
    ADD_TASK_SUCCESS: "Сформированная выгрузка доступна в разделе Экспорт отчетов",
    ADD_TASK_EXPORT_REGISTRY_SUCCESS: "Экспорт реестра запущен. Скачать файл по готовности можно в разделе \"Экспорт отчетов\"",
    ADD_CANCELLED_EXPORT_SUCCESS: "Сформированная выгрузка доступна в разделе \"Экспорт\"",
    ADD_TASK_EXPORT_EDM_REGISTRY_SUCCESS: "Выгрузка успешно запущена и в ближайшее время станет доступна в разделе \"ЭДО → Экспорт\"",
};

export const MEMBER_ARCHIVE_SUCCESS = {
    FROM: "Сотрудник восстановлен из архива",
    TO: "Сотрудник успешно перемещен в архив",
};

export const JOINING_REQUEST_TOAST = {
    ERROR: "Не удалось отправить заявку на подключение. Обратитесь в техподдержку",
};

export const EDM_STAFF_LIST_REQUEST_TOAST = {
    ERROR: "Не удалось получить список сотрудников",
};

export const MESSAGES_EXPORT_TOAST = "Будет запущен процесс выгрузки списка :name, чтобы скачать файл перейдите в раздел \"Экспорт\" → \"Экспорт отчетов\"";

export const MESSAGE_NOT_CHOSEN = "не выбрано";

export const MESSAGE_ERROR_403 = "Для выполнения операции недостаточно прав доступа";