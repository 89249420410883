import React, {Component, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    MapContainer,
    Marker,
    TileLayer,
    Tooltip,
} from "react-leaflet";
import {connect} from "react-redux";

import NmModal from "../ActualComponents/NmModal";
import NmLoader from "../NmLoader";
import NmTitle from "../NmTitle";

import {useLeafletHideLogo} from "../OpenStreetMap/hooks/useHideLogo";

import {blueIcon} from "../../constants/map";

import {
    getWorkLogPhoto,
    updateFieldWorkLog,
    workLogPhotoSelector,
    workLogProgressPhotoSelector,
} from "../../ducks/contractorWorkLog";

import PropTypes from "prop-types";

import "./style.sass";

const PhotoReportMap = props => {
    const {
        t,
        className,
        data: {
            longitude,
            latitude,
            objectLongitude,
            objectLatitude,
        },
    } = props;

    useLeafletHideLogo();

    const [map, setMap] = useState(null);

    const coords = longitude || latitude ? [
        latitude,
        longitude,
    ] : [
        objectLatitude,
        objectLongitude,
    ];

    useEffect(() => {
        if (map) {
            map.setView(coords);
        }
    }, [map]);

    const getContractorGeo = () => {
        if (latitude === null || longitude === null) {
            return null;
        }

        return (
            <Marker
                icon={blueIcon}
                position={[latitude, longitude]}
            >
                <Tooltip
                    permanent
                    opacity={1}
                    offset={[0, -36]}
                    direction="top"
                >
                    {t("photo-report.placemark-icon-caption")}
                </Tooltip>
            </Marker>
        );
    };

    const getObjectGeo = () => {
        if (objectLatitude === null || objectLongitude === null) {
            return null;
        }

        return (
            <Marker
                icon={blueIcon}
                position={[objectLatitude, objectLongitude]}
            >
                <Tooltip
                    permanent
                    opacity={1}
                    offset={[0, -36]}
                    direction="top"
                >
                    {t("photo-report.placemark-object-icon-caption")}
                </Tooltip>
            </Marker>
        );
    };

    return (
        <MapContainer
            className={className}
            whenCreated={setMap}
            zoom={13}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {getContractorGeo()}
            {getObjectGeo()}
        </MapContainer>
    );
};

class PhotoReport extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        clientId: PropTypes.string,
        orderId: PropTypes.string,
        contractorId: PropTypes.string,
    };

    static defaultProps = {
        onClose: () => {
        },
        data: {},
    };

    componentDidMount() {
        const {
            data: {
                link: downloadLink,
            },
            getWorkLogPhoto,
        } = this.props;

        if (downloadLink) {
            getWorkLogPhoto({
                downloadLink,
            });
        }
    }

    componentWillUnmount() {
        const {updateFieldWorkLog} = this.props;

        updateFieldWorkLog({
            photoBase64: "",
        });
    }

    getTitle() {
        const {
            photoBase64,
            progressPhoto,
            data: {
                fio,
                distanceToObject = 0,
            },
            t,
        } = this.props;

        if (progressPhoto) {
            return "";
        }

        return photoBase64 ?
            `${t("photo-report.title")} - ${fio}` :
            `${t("photo-report.title-geolocation")} - ${fio} - ${t("photo-report.distance-to-object")} ${distanceToObject || 0}м`;
    }

    render() {
        const {
            onClose,
            photoBase64,
            progressPhoto,
            t,
            data,
        } = this.props;
        const {
            link,
        } = data;

        const mapClassName = link ? "photo-report__map photo-report__map_short" : "photo-report__map";
        const imageClassName = link ? "photo-report__image photo-report__image_spaced" : "photo-report__image";

        return (
            <NmModal
                onClose={onClose}
                className="photo-report"
                header={
                    <NmTitle size="lg">
                        {this.getTitle()}
                    </NmTitle>
                }
            >
                <NmLoader
                    active={progressPhoto}
                    inverted
                />
                <div className={imageClassName}>
                    {
                        photoBase64 ?
                            <img
                                src={` data:image/jpeg;charset=utf-8;base64, ${photoBase64}`}
                                className="client-card__img"
                                alt={t("photo-report.not-photo")}
                            /> :
                            null
                    }
                </div>
                <PhotoReportMap
                    className={mapClassName}
                    data={data}
                    t={t}
                />
            </NmModal>
        );
    }
}


export default connect(
    state => ({
        photoBase64: workLogPhotoSelector(state),
        progressPhoto: workLogProgressPhotoSelector(state),
    }),
    {
        getWorkLogPhoto,
        updateFieldWorkLog,
    },
)(withTranslation()(PhotoReport));
