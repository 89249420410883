import React, {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import {ChevronIcon} from "../../ChevronIcon";
import NmCheckboxV2 from "../../NmCheckboxV2";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";

import {ITreeItem} from "../types";

import "./style.sass";

export interface ITreeList {
    checked?: boolean,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: ITreeList) => void,
    parent?: ITreeList,
    onClickChevron: (data: ITreeList) => void,
    type?: string,
    value?: string,
    index?: number,
    item?: ITreeItem,
    getChildren?: (data: ITreeList, open: boolean) => void,
    disabled?: boolean,
    excludeChevronIconType?: string,
    checkedProjects?: Array<string>,
    isChecked?: (data?: ITreeItem) => boolean | undefined,
    isIndeterminate?: (data?: ITreeItem) => boolean | undefined,
}

export const TreeNode = (props: ITreeList) => {
    const {
        onClickChevron,
        item,
        getChildren,
        disabled,
        excludeChevronIconType,
        checkedProjects,
    } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (item?.expanded) {
            setOpen(true);
        }
    }, [item?.expanded]);

    useEffect(() => {
        if (disabled) {
            setOpen(false);
        }
    }, [disabled]);

    const [block ] = bem("tree-node");

    const isNotVisibleChevronIcon = excludeChevronIconType &&
        item?.type &&
        excludeChevronIconType.includes(item.type);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, data: ITreeList) => {
        if (props.onChange) {
            props.onChange(event, data);
        }
    };

    const getList = () => {
        if (!open || !item) {
            return null;
        }

        if (!isEmpty(item.children) && item.children) {
            return (
                <li className="tree-node__li tree-node__row tree-node__row_column">
                    {
                        item.children.map((item, index) => {
                            return (
                                <TreeNode
                                    key={item.value}
                                    index={index}
                                    item={item}
                                    parent={props}
                                    checked={item.checked}
                                    onChange={onChange}
                                    value={item.value}
                                    type={item.type}
                                    onClickChevron={onClickChevron}
                                    getChildren={getChildren}
                                    excludeChevronIconType={excludeChevronIconType}
                                    checkedProjects={checkedProjects}
                                    //isChecked={isChecked}
                                    //isIndeterminate={isIndeterminate}
                                />
                            );
                        })
                    }
                </li>
            );
        }

        return null;
    };

    const getChecked = () => {
        if (disabled) {
            return false;
        }

        // if (isChecked) {
        //     const indeterminate = isIndeterminate ? isIndeterminate(item) : undefined;
        //
        //     if (typeof indeterminate === "boolean" && indeterminate) {
        //         return indeterminate;
        //     }
        //
        //     const checked = isChecked(item);
        //
        //     if (typeof checked === "boolean") {
        //         return checked;
        //     }
        // }

        return item?.partiallyChecked ? true : Boolean(item?.checked);
    };

    const getIndeterminate = () => {
        if (disabled) {
            return false;
        }

        // if (isIndeterminate) {
        //     const indeterminate = isIndeterminate(item);
        //
        //     if (typeof indeterminate === "boolean") {
        //         return indeterminate;
        //     }
        // }

        return item?.partiallyChecked;
    };

    const getChevronIcon = () => {
        if (isNotVisibleChevronIcon) {
            return null;
        }

        return (
            <ChevronIcon
                cursor="pointer"
                width={24}
                height={24}
                color={COLOR.SECONDARY_45}
                rotate={open ? 180 : 0}
                className="tree-node__chevron-icon"
                onClick={() => {
                    if (disabled) {
                        return;
                    }

                    if (onClickChevron) {
                        onClickChevron(props);
                    }

                    setOpen(!open);
                }}
            />
        );
    };

    return (
        <ul
            className={block({
                offset: Boolean(props.parent),
                noChevronIcon: isNotVisibleChevronIcon,
            })}
        >
            <div className="tree-node__row">
                {getChevronIcon()}
                {
                    item &&
                    <NmCheckboxV2
                        disabled={disabled}
                        onChange={(event, data) => {
                            onChange(event, {
                                ...props,
                                ...data,
                                checked: item.partiallyChecked && !data.checked ? true : data.checked,
                            });
                        }}
                        classNameLabel="tree-node__checkbox-label"
                        indeterminate={getIndeterminate()}
                        checked={getChecked()}
                        label={item.label}
                    />
                }
            </div>
            {getList()}
            {
                getChildren
                    ? getChildren(props, open)
                    : null
            }
        </ul>
    );
};
