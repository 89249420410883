import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import ContractorFamilyStatusEditModal from "./components/contractor-family-status-edit-modal";

import {CONTRACTOR_FAMILY_STATUS} from "../../../../constants/contractor";

import {
    contractorFamilyStatusSelector,
    updateContractorFamilyStatus,
} from "../../../../ducks/contractorProfile";

export const FAMILY_STATUS_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const FAMILY_STATUS_OPTIONS = [
    {
        key: FAMILY_STATUS_OPTION.EDIT,
        text: "Редактировать",
        value: FAMILY_STATUS_OPTION.EDIT,
    },
    {
        key: FAMILY_STATUS_OPTION.REMOVE,
        text: "Удалить",
        value: FAMILY_STATUS_OPTION.REMOVE,
    },
];

export default function ContractorFamilyStatus(props) {
    const {
        className,
        contractorId,
        getProfile,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const contractorFamilyStatus = useSelector(contractorFamilyStatusSelector);

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const onClickAction = ({value}) => {
        if (value === FAMILY_STATUS_OPTION.EDIT) {
            toggleEditModal({status: contractorFamilyStatus});
            return;
        }

        if (value === FAMILY_STATUS_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem();
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?" ,
            });
            setShowConfirm(true);
        }
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    const deleteElem = () => {
        dispatch(updateContractorFamilyStatus({
            contractorId,
            status: null,
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };

    function mapContent() {
        if (!contractorFamilyStatus) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return (
            <div className="d-flex flex-column">
                <Text>
                    {CONTRACTOR_FAMILY_STATUS[contractorFamilyStatus].TEXT}
                </Text>
            </div>
        );
    }

    return (
        <>
            {
                isShowEditModal && <ContractorFamilyStatusEditModal
                    onClose={() => toggleEditModal()}
                    getProfile={getProfile}
                    contractorId={contractorId}
                    editData={editData}
                />
            }
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Семейное положение"
                onClickAdd={!contractorFamilyStatus ? () => onClickAction({value: FAMILY_STATUS_OPTION.EDIT}) : null}
                options={contractorFamilyStatus ? FAMILY_STATUS_OPTIONS : null}
                onClickItem={onClickAction}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}