/***
 * для сборки className из нескольких
 * @param array
 * @returns {string}
 */
export const getClassNames = (array: Array<any> = []) => {
    if (array.length === 0) {
        return "";
    }

    return array.filter(className => className).join(" ");
};