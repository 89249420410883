import React from "react";
import {isEmpty} from "lodash";

import {ReactComponent as LogoIcon} from "../../../images/company-logo.svg";
import LogoThumbnail from "../../LogoThumbnail";
import NmButton from "../../NmButton";

import bem from "../../../utils/bem";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFullName} from "../../../utils/stringFormat";

import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER, RNKO,
    ROLE_DICT,
} from "../../../constants/roles";

import "./style.sass";

const UserInfo = (props) => {
    const {
        data,
        listRelatedClientUsers,
        handleClickChangeAccount,
        handleClickLogout,
        base64Logo,
    } = props;
    const {
        lastName,
        firstName,
        clientName,
        patronymic,
        role,
        clientBrand: currentClientBrand,
    } = data;

    const [block, element] = bem("user-info");

    const isShowList = !isEmpty(listRelatedClientUsers) && listRelatedClientUsers.length > 1;

    const isAdminUser = [
        ADMIN,
        NM_MANAGER,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
        NM_CONSULTANT,
        RNKO,
    ].includes(ls(USER_ROLE));

    const getListRelatedClientUsers = () => {
        if (!isShowList) {
            return null;
        }

        return listRelatedClientUsers.map((value) => {
            const {
                clientFullName,
                clientId,
                clientName,
                clientUserId,
                logoImageUrl,
                clientBrand,
            } = value;

            return (
                <div
                    key={clientId}
                    className={element("other-account-item")}
                    onClick={() => handleClickChangeAccount({
                        changeClientId: clientId,
                        changeClientUserId: clientUserId,
                    })}
                >
                    {
                        logoImageUrl ?
                            <LogoThumbnail
                                className={element("other-account-logo")}
                                size="sm"
                                src={logoImageUrl}
                            /> :
                            <LogoIcon className={element("other-account-logo")} />
                    }
                    <div className={element("other-account-title")}>
                        <div className={element("other-account-client-name")}>
                            <div className={element("block-wrapper")}>
                                {clientFullName}
                                {clientBrand ? ` (${clientBrand})` : ""}
                            </div>
                        </div>
                        <div className={element("other-account-client-brand")}>
                            <div className={element("block-wrapper")}>
                                {clientName}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    const getLogo = () => {
        if (isAdminUser) {
            return <div className={element("logo", {"admin": true})} />;
        }

        return (
            base64Logo ?
                <LogoThumbnail
                    className={element("logo")}
                    size="sm"
                    src={`data:image/jpeg;charset=utf-8;base64, ${base64Logo}`}
                /> :
                <LogoIcon className={element("logo")} />
        );

    };

    return (
        <div className={block()}>
            {
                getLogo()
            }
            {lastName &&
            <div className={element("user-name")}>
                {getFullName(lastName, firstName, patronymic)}
            </div>
            }
            {clientName &&
            <div className={element("client-name")}>
                {clientName}
                {currentClientBrand ? ` (${currentClientBrand})` : ""}
            </div>
            }
            <div className={element("role")}>
                {ROLE_DICT[role]?.TEXT}
            </div>
            {isShowList && <div className={element("divider")} />}
            <div className={element("other-account-list")}>
                {getListRelatedClientUsers()}
            </div>
            <NmButton
                color="grey"
                className={element("button-logout")}
                onClick={handleClickLogout}
            >
                Выйти
            </NmButton>
        </div>
    );
};

export default UserInfo;