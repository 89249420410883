import React, {Component} from "react";

import {MarkerListModal} from "../ActualComponents/MarkerListModal";
import ExtLink from "../ExtLink";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

class NmSelectedList extends Component {
    static propTypes = {
        values: PropTypes.array,
        list: PropTypes.array,
        className: PropTypes.string,
        showedItemsCount: PropTypes.number,
        label: PropTypes.string,
        showListWithoutValue: PropTypes.bool, //флаг, когда в качестве значений принимаем весь список,
        highlightingFirstItem: PropTypes.bool,
        isShowDetailModal: PropTypes.bool,
        modalTitle: PropTypes.string,
        type: PropTypes.string,
    };

    static defaultProps = {
        values: [],
        list: [],
        className: "",
        showedItemsCount: 2,
        label: "",
        showListWithoutValue: false,
        highlightingFirstItem: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isShowAllItems: false,
            isOpenModal: false,
        };

        const [, element] = bem("nm-selected-list");

        this.element = element;
    };

    showAll = (isShow) => {
        this.setState({
            isShowAllItems: isShow,
        });
    };

    getLimiter = () => {
        const {isLimiterComma} = this.props;

        if (isLimiterComma) {
            return <div className="nm-selected-list__comma-delimiter">
,
            </div>;
        }

        return <div className="nm-selected-list__delimiter" />;
    };

    renderItem = (params) => {
        const {
            onClick,
            text,
            title,
            linkTitle = "Перейти по ссылке",
            remoteWork,
            index,
            showedItemsValuesLength,
            isShowMore,
            link,
        } = params;
        const {
            highlightingFirstItem,
            itemClassName = "",
            classNameItemContainer = "",
            type,
            isItemCursorPointer,
            itemColor,
        } = this.props;

        const isShowLimiter = (
            index + 1 < showedItemsValuesLength
        ) || isShowMore;

        return (
            <div
                title={title}
                style={{
                    cursor: isItemCursorPointer ? "pointer" : "default",
                    color: itemColor,
                }}
                onClick={onClick}
                className={`nm-selected-list__item-container ${classNameItemContainer}`}
                key={index}
            >
                <div
                    className={`${this.element("showed-list-item", {
                        highlighted: highlightingFirstItem && index === 0,
                        type,
                    })} ${itemClassName}`}
                >
                    {
                        link ?
                            <ExtLink
                                title={linkTitle}
                                to={link}
                            >
                                {text}
                            </ExtLink> :
                            text
                    }
                    {index === 0 && remoteWork &&
                        <span className="nm-selected-list__showed-list-item">
                            {" "}
(Удалённая работа)
                        </span>}
                </div>
                {
                    isShowLimiter &&
                    this.getLimiter()
                }
            </div>
        );
    };

    renderList(isShowAllItems, isShowMore) {
        const {
            showedItemsCount,
            showListWithoutValue,
            list: listOptions,
        } = this.props;

        const list = this.list;
        const showedCount = showedItemsCount > list.length || isShowAllItems ? list.length : showedItemsCount;
        const showedItemsValues = list.slice(0, showedCount);
        const showedItemsValuesLength = showedItemsValues.length;

        if (showListWithoutValue) {
            return (
                <>
                    {showedItemsValues.map((item, index) => {
                        return this.renderItem({
                            ...item,
                            index,
                            showedItemsValuesLength,
                            isShowMore,
                        });
                    })}
                </>
            );
        }

        return (
            <>
                {
                    listOptions
                        .filter((item) => showedItemsValues.includes(item.key))
                        .sort((first, second) => {
                            return list.indexOf(first.key) - list.indexOf(second.key);
                        })
                        .map((item, index, array) => {
                            return this.renderItem({
                                ...item,
                                index,
                                showedItemsValuesLength,
                                array,
                            });
                        })
                }
            </>
        );
    };

    get list() {
        const {
            values,
            showListWithoutValue,
            list,
        } = this.props;

        return showListWithoutValue ? list : values;
    }

    renderItems() {
        const {
            showedItemsCount,
            label,
            classNameContent = "",
            classNameContentFull = "",
            labelClassName,
            isShowDetailModal,
            type,
            onClickShowMore,
            isPreventDefaultClickShowMore,
        } = this.props;

        const {
            isShowAllItems,
        } = this.state;

        const list = this.list;
        const isShowMore = showedItemsCount < list.length && !isShowAllItems;

        return (
            <div
                className={`nm-selected-list__content ${classNameContent} ${isShowAllItems && classNameContentFull ? classNameContentFull : ""}`}
            >
                {
                    label &&
                    <div className={`${this.element("label", {type})} ${labelClassName}`}>
                        {`${label}:`}
                    </div>
                }
                {
                    this.renderList(
                        isShowAllItems,
                        isShowMore,
                    )
                }
                {
                    isShowMore &&
                    <div
                        className={this.element("show-btn", {type})}
                        onClick={() => {
                            if (onClickShowMore) {
                                onClickShowMore();
                            }

                            if (isPreventDefaultClickShowMore) {
                                return;
                            }

                            if (isShowDetailModal) {
                                this.setState({
                                    isOpenModal: true,
                                });

                                return;
                            }

                            this.showAll(true);
                        }}
                    >
                        {`Ещё ${list.length - showedItemsCount}`}
                    </div>
                }
                {
                    isShowAllItems &&
                    <div
                        className={this.element("show-btn", {type, hide: true})}
                        onClick={() => this.showAll(false)}
                    >
                        Скрыть
                    </div>
                }
            </div>
        );
    };

    render() {
        const {
            className,
            modalTitle,
        } = this.props;

        const {
            isOpenModal,
        } = this.state;

        return (
            <div className={`nm-selected-list ${className}`}>
                {
                    isOpenModal &&
                    <MarkerListModal
                        header={modalTitle}
                        list={this.list}
                        onClose={() => {
                            this.setState({
                                isOpenModal: false,
                            });
                        }}
                    />
                }
                {this.renderItems()}
            </div>
        );
    };
}

export default NmSelectedList;
