import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import DropzoneV2 from "../../../../../components/ActualComponents/DropzoneV2";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import AmountInput from "../../../../../components/AmountInput";
import FileViewerPdf from "../../../../../components/FileVIewerPdf";
import NmButton from "../../../../../components/NmButton";
import NmTitle from "../../../../../components/NmTitle";
import Steps from "../../../../../components/Steps";
import {Dimmer, Loader} from "semantic-ui-react";

import {DataChange} from "../../filter/hooks/useStatementFilter";
import {
    AdditionalParamsStatementTemplateType,
    STATEMENT_ACTION_TYPE,
    STATEMENT_LOADING_TYPE,
} from "./hooks/useLoadingForm";

import bem from "../../../../../utils/bem";
import {OptionType} from "../item/utils/getOptions";

import "./style.sass";

import {UPLOAD_EDO_STEP} from "../../../../../constants/document-management/document-list";
import {
    EDO_STATEMENT_LOADING_FIELD,
    STATEMENT_PARAMS,
    TEMPLATE_STATEMENT,
} from "../../../../../constants/document-management/statements";

export type StatementLoadingFormType = {
    statementDateTime?: Date | null,
    statementFile: any[],
    attachmentFile: any[],
    approverIds?: string[],
    statementType?: string,
    statementName?: string,
    clientId: string,
    receiverId?: string,
    description?: string,
    statementId?: string,
    statementFileName?: string,
    attachmentFileName?: string,
    typeLoading: string
}

export type StatementLoadingErrorFormType = {
    statementFile: string,
    attachmentFile: string,
    approverIds?: string,
    statementType?: string,
    statementName?: string,
    clientId: string,
    receiverId?: string,
    description?: string,
    statementDateTime?: string,
    amountOfIncome?: string,
    period?: string,
    advanceAmount?: string
}

export type OnSearchStatementType = {
    searchQuery?: string,
}

export type StatementLoadingFormProps = {
    statementTypeOptions: OptionType[],
    edoReceiversOptions: OptionType[],
    edoApproversOptions: OptionType[],
    checkForm: () => void,
    isOpenConfirmWindow: boolean,
    submit: () => void,
    handleClose: () => void,
    className?: string,
    loading: boolean,
    isLoadingCard: boolean,
    handleOnSearchStatementType: (e: any, value: any) => void,
    onChangeForm: (e: any, value: DataChange) => void,
    form: StatementLoadingFormType,
    handleDownload: (data: {statementId: string}) => void,
    errorForm?: StatementLoadingErrorFormType,
    handleOnSearchReceiver: (e: any, value: OnSearchStatementType) => void,
    handleOnSearchApproved: (e: any, value: OnSearchStatementType) => void,
    setFile: (field: string, file?: File[]) => void,
    setVisibleConfirm: (flag: boolean) => void,
    setVisibleCodeConfirm: (flag: boolean) => void,
    action: string,
    currentStep: number[],
    switchAction: Function,
    handleDownloadEmptyTemplate: Function,
    previewFile: Blob,
    onChangeAdditionalParams: (e: any, value: DataChange) => void,
    additionalParams: AdditionalParamsStatementTemplateType,
    setStep: Function
}

const DocumentManagementStatementLoadingForm = (props: StatementLoadingFormProps) => {
    const {
        isOpenConfirmWindow,
        handleClose,
        className,
        loading,
        isLoadingCard,
        handleOnSearchStatementType,
        handleOnSearchApproved,
        handleOnSearchReceiver,
        onChangeForm,
        form,
        handleDownload,
        statementTypeOptions,
        edoApproversOptions,
        edoReceiversOptions,
        errorForm,
        setFile,
        checkForm,
        setVisibleConfirm,
        setVisibleCodeConfirm,
        action,
        currentStep,
        switchAction,
        handleDownloadEmptyTemplate,
        previewFile,
        onChangeAdditionalParams,
        additionalParams,
        setStep,
    } = props;

    const {
        statementFile,
        attachmentFile,
        approverIds,
        statementType,
        statementName,
        receiverId,
        description,
        statementDateTime,
        typeLoading,
    } = form;

    const steps = useMemo(() => [
        {
            name: "Заполнение реквизитов",
        },
        {
            name: "Подписание",
        },
    ], []);

    const [block, element] = bem("document-management-statement-loading-form", className);

    const renderConfirmWindow = () => {
        return isOpenConfirmWindow &&
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile
                content="Вы действительно хотите подать заявление своему работодателю?"
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onCancel={() => setVisibleConfirm(false)}
                onConfirm={() => setVisibleCodeConfirm(true)}
                isNeedClosing={false}
            />;
    };

    const switchSubmit = () => {
        if (currentStep === UPLOAD_EDO_STEP.FIRST) {
            checkForm();
            return;
        }
        if (currentStep === UPLOAD_EDO_STEP.SECOND) {
            switchAction();
            return;
        }
    };

    const handleTypeLoading = (e: any, {name}: DataChange) => {
        onChangeForm(e, {name: "typeLoading", value: name});
    };

    function submitBtnContent() {
        if (currentStep === UPLOAD_EDO_STEP.FIRST) {
            return "Далее";
        }
        if (currentStep === UPLOAD_EDO_STEP.SECOND) {
            return action === STATEMENT_ACTION_TYPE.REVIEW ? "Одобрить" : "Подписать";
        }
    }

    const templateType = useMemo(() => statementTypeOptions.find(item => item.value === statementType)?.templateType ?? "", [statementTypeOptions, statementType]);

    function hasTemplate() {
        return [
            TEMPLATE_STATEMENT.APPLICATION_REDUCTION_PERSONAL_INCOME_TAX_BY_AMOUNT_OF_ADVANCE,
            TEMPLATE_STATEMENT.APPLICATION_FOR_ISSUANCE_OF_AN_EARLY_ADVANCE_PAYMENT,
        ]?.includes(templateType);
    }

    function hasParamsByFieldName(fieldName: string): boolean {
        return STATEMENT_PARAMS[templateType]?.includes(fieldName);
    }

    const onDownload = () => {
        handleDownload({statementId: form.statementId || ""});
    };

    return (
        <NmModal
            size="md"
            onClose={handleClose}
            header={
                <NmTitle
                    size="lg"
                >
                    Подача заявления
                </NmTitle>
            }
            footer={
                <div className="flex flex-aligned-center flex-content-spaced">
                    <NmButton
                        size="xl"
                        color="white"
                        onClick={() => {
                            if (UPLOAD_EDO_STEP.SECOND === currentStep) {
                                setStep(UPLOAD_EDO_STEP.FIRST);
                                return;
                            }
                            handleClose();
                        }}
                    >
                        {UPLOAD_EDO_STEP.SECOND === currentStep ? "Назад" : "Отменить"}
                    </NmButton>
                    <NmButton
                        size="xl"
                        onClick={switchSubmit}
                        type="submit"
                        loading={loading}
                        disabled={loading}
                    >
                        {submitBtnContent()}
                    </NmButton>
                </div>
            }
        >
            {isLoadingCard && <Dimmer
                active={isLoadingCard}
                inverted
                              >
                <Loader />
            </Dimmer>}
            {renderConfirmWindow()}
            <NmForm
                addMargin
                className={block()}
            >
                <Steps
                    items={steps}
                    activeItems={currentStep}
                />
                {
                    currentStep === UPLOAD_EDO_STEP.FIRST && <>
                        <NmDropdownV2
                            disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                            size="xl"
                            placeholder="Выберите из списка"
                            label="Тип заявления"
                            search
                            name={EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE}
                            onChange={onChangeForm}
                            value={statementType || ""}
                            options={statementTypeOptions}
                            error={errorForm?.statementType}
                            onSearchChange={handleOnSearchStatementType}
                        />
                        {
                            action !== STATEMENT_ACTION_TYPE.REVIEW && hasTemplate() &&
                            <>
                                <div className={element("row")}>
                                    <div className={element("control")}>
                                        <NmRadioV2
                                            label="Использовать предложенный шаблон"
                                            name={STATEMENT_LOADING_TYPE.TEMPLATE}
                                            value={STATEMENT_LOADING_TYPE.TEMPLATE}
                                            checked={typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE}
                                            onChange={handleTypeLoading}
                                        />
                                        <div
                                            className={element("link-pre-filled-template")}
                                            onClick={() => handleDownloadEmptyTemplate()}
                                        >
                                            Посмотреть предложенный шаблон
                                        </div>
                                    </div>
                                    <div className={element("control")}>
                                        <NmRadioV2
                                            label="Загрузить заполненное заявление"
                                            name={STATEMENT_LOADING_TYPE.SIMPLE}
                                            value={STATEMENT_LOADING_TYPE.SIMPLE}
                                            onChange={handleTypeLoading}
                                            checked={typeLoading === STATEMENT_LOADING_TYPE.SIMPLE}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        <NmDatePicker
                            disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                            className="mt-3 mt-md-4"
                            error={errorForm?.statementDateTime}
                            label="Дата заявления"
                            placeholderText="дд.мм.гггг"
                            dateFormatMask="99.99.9999"
                            name={EDO_STATEMENT_LOADING_FIELD.STATEMENT_DATE_TIME}
                            dateFormat="dd.MM.yyyy"
                            onChange={onChangeForm}
                            selected={statementDateTime}
                            isClearable
                        />
                        <NmInputV2
                            disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                            size="xl"
                            error={errorForm?.statementName}
                            label="Наименование заявления"
                            name={EDO_STATEMENT_LOADING_FIELD.STATEMENT_NAME}
                            value={statementName}
                            onChange={onChangeForm}
                        />
                        {
                            hasParamsByFieldName("amountOfIncome") && typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE &&
                            <AmountInput
                                className="mt-3 mt-md-4"
                                disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                                newInput
                                label="Сумма уменьшения НДФЛ, ₽"
                                addZeroAfterDivider
                                value={additionalParams.amountOfIncome}
                                placeholder="0,00"
                                name="amountOfIncome"
                                size="xl"
                                onChange={onChangeAdditionalParams}
                                error={errorForm?.amountOfIncome}
                            />
                        }
                        {
                            hasParamsByFieldName("loanAmount") && typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE && action === STATEMENT_ACTION_TYPE.REVIEW &&
                            <>
                                <div className={element("row")}>
                                    <div className={element("dropdown")}>
                                        <AmountInput
                                            disabled
                                            newInput
                                            label="Сумма займа, ₽"
                                            addZeroAfterDivider
                                            value={additionalParams.loanAmount}
                                            placeholder="0,00"
                                            name="loanAmount"
                                            size="xl"
                                            onChange={onChangeAdditionalParams}
                                        />
                                    </div>
                                    <div className={element("dropdown")}>
                                        <NmInputV2
                                            disabled
                                            size="xl"
                                            label="Номер заявления"
                                            name="statementNumber"
                                            value={additionalParams.statementNumber}
                                            onChange={onChangeAdditionalParams}
                                        />
                                    </div>
                                </div>
                                <NmInputV2
                                    disabled
                                    size="xl"
                                    label="Реквизиты получателя (ИФНС)"
                                    name="subjectRU"
                                    value={additionalParams.subjectRU}
                                    onChange={onChangeAdditionalParams}
                                />
                            </>
                        }
                        {
                            hasParamsByFieldName("advanceAmount") && hasParamsByFieldName("period") && typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE &&
                            <div className={element("row")}>
                                <div className={element("dropdown")}>
                                    <AmountInput
                                        disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                                        newInput
                                        label="Сумма аванса, ₽"
                                        addZeroAfterDivider
                                        value={additionalParams.advanceAmount}
                                        placeholder="0,00"
                                        name="advanceAmount"
                                        size="xl"
                                        onChange={onChangeAdditionalParams}
                                        error={errorForm?.advanceAmount}
                                    />
                                </div>
                                <div className={element("dropdown")}>
                                    <NmDatePicker
                                        disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                                        error={errorForm?.period}
                                        label="Период"
                                        placeholderText="дд.мм.гггг"
                                        name="period"
                                        dateFormat="dd.MM.yyyy"
                                        onChange={onChangeAdditionalParams}
                                        selected={additionalParams.period}
                                        isClearable
                                    />
                                </div>
                            </div>
                        }
                        <div className={element("dropzone")}>
                            {
                                STATEMENT_LOADING_TYPE.SIMPLE === typeLoading &&
                                <DropzoneV2
                                    isDownload
                                    files={statementFile}
                                    onChange={(files: File[]) => {
                                        setFile(EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE, files);
                                    }}
                                    maxSize={15}
                                    format="*.PDF/A"
                                    accept=".pdf"
                                    innerLabel="Выберите или перетащите файл"
                                    onDownload={onDownload}
                                    disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                                    error={errorForm?.statementFile}
                                />
                            }
                            {
                                STATEMENT_LOADING_TYPE.SIMPLE === typeLoading &&
                                <div className={element("link")}>
                                    <Link
                                        to="/files/PDF-A_guide.pdf"
                                        target="_blank"
                                    >
                                        Как сохранить документы в формате PDF/A?
                                    </Link>
                                </div>
                            }
                        </div>
                        <NmTextareaV2
                            minRows={2}
                            label="Дополнительные сведения"
                            maxLength="255"
                            name={EDO_STATEMENT_LOADING_FIELD.DESCRIPTION}
                            onChange={onChangeForm}
                            value={description}
                        />
                        <DropzoneV2
                            isVisibleLabel={true}
                            label="Дополнительный файл (прикрепляется при необходимости)"
                            isDownload
                            uploadBtnText={form.attachmentFileName ? "Заменить файл" : undefined}
                            files={attachmentFile}
                            onChange={(files: File[]) => {
                                setFile(EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE, files);
                            }}
                            maxSize={15}
                            format="*.PDF, *.DOCX, *.XLSX, *.PNG, *.JPG"
                            accept=".png, .jpeg, .doc, .docx, .pdf"
                            innerLabel="Выберите или перетащите файл"
                            onDownload={onDownload}
                            error={errorForm?.attachmentFile}
                            disabled={action === STATEMENT_ACTION_TYPE.REVIEW}
                        />
                        <div className={element("row")}>
                            <div className={element("dropdown")}>
                                <NmDropdownV2
                                    size="xl"
                                    placeholder="Выберите согласующих"
                                    label="Согласующий"
                                    search
                                    name={EDO_STATEMENT_LOADING_FIELD.APPROVED_IDS}
                                    onChange={onChangeForm}
                                    multiple
                                    value={approverIds ?? []}
                                    options={edoApproversOptions}
                                    onSearchChange={handleOnSearchApproved}
                                    error={errorForm?.approverIds}
                                />
                            </div>
                            <div className={element("dropdown")}>
                                <NmDropdownV2
                                    size="xl"
                                    placeholder="Выберите из списка"
                                    label="Получатель"
                                    name={EDO_STATEMENT_LOADING_FIELD.RECEIVER_ID}
                                    search
                                    onChange={onChangeForm}
                                    value={receiverId || ""}
                                    options={edoReceiversOptions}
                                    onSearchChange={handleOnSearchReceiver}
                                    error={errorForm?.receiverId}
                                />
                            </div>
                        </div>
                    </>
                }
                {
                    currentStep === UPLOAD_EDO_STEP.SECOND &&
                    <FileViewerPdf
                        className={element("pdf-viewer")}
                        file={previewFile}
                    />
                }
            </NmForm>
        </NmModal>
    );
};

export default DocumentManagementStatementLoadingForm;