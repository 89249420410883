import React, {createContext, useState} from "react";

export const TemplatesContext = createContext({});

export const TemplatesProvider = ({ children }) => {
    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});

    const onOpenEdit = (data) => {
        setOpenEdit(true);

        if (data) {
            setEditData(data);
        }
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData({});
    };

    return (
        <TemplatesContext.Provider
            value={{
                isOpenEdit,
                onOpenEdit,
                onCloseEdit,
                editData,
            }}
        >
            {children}
        </TemplatesContext.Provider>
    );
};