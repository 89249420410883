export const BFF_CROWD_TASK_GROUP_GET_REQUEST = "BFF_CROWD_TASK_GROUP_GET_REQUEST";
export const BFF_CROWD_TASK_GROUP_GET_SUCCESS = "BFF_CROWD_TASK_GROUP_GET_SUCCESS";
export const BFF_CROWD_TASK_GROUP_GET_ERROR = "BFF_CROWD_TASK_GROUP_GET_ERROR";

export const BFF_CROWD_TASK_GROUP_ADD_REQUEST = "BFF_CROWD_TASK_GROUP_ADD_REQUEST";
export const BFF_CROWD_TASK_GROUP_ADD_SUCCESS = "BFF_CROWD_TASK_GROUP_ADD_SUCCESS";
export const BFF_CROWD_TASK_GROUP_ADD_ERROR = "BFF_CROWD_TASK_GROUP_ADD_ERROR";

export const BFF_CROWD_TASK_GROUP_REMOVE_REQUEST = "BFF_CROWD_TASK_GROUP_REMOVE_REQUEST";
export const BFF_CROWD_TASK_GROUP_REMOVE_SUCCESS = "BFF_CROWD_TASK_GROUP_REMOVE_SUCCESS";
export const BFF_CROWD_TASK_GROUP_REMOVE_ERROR = "BFF_CROWD_TASK_GROUP_REMOVE_ERROR";

export const BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST = "BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST";
export const BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_SUCCESS = "BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_SUCCESS";
export const BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR = "BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_ERROR";

export const BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST = "BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST";
export const BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_SUCCESS = "BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_SUCCESS";
export const BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR = "BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_ERROR";

export const BFF_CROWD_TASK_GROUP_IMPORT_REQUEST = "BFF_CROWD_TASK_GROUP_IMPORT_REQUEST";
export const BFF_CROWD_TASK_GROUP_IMPORT_SUCCESS = "BFF_CROWD_TASK_GROUP_IMPORT_SUCCESS";
export const BFF_CROWD_TASK_GROUP_IMPORT_ERROR = "BFF_CROWD_TASK_GROUP_IMPORT_ERROR";

export const BFF_CROWD_TASK_GROUP_EXPORT_REQUEST = "BFF_CROWD_TASK_GROUP_EXPORT_REQUEST";
export const BFF_CROWD_TASK_GROUP_EXPORT_SUCCESS = "BFF_CROWD_TASK_GROUP_EXPORT_SUCCESS";
export const BFF_CROWD_TASK_GROUP_EXPORT_ERROR = "BFF_CROWD_TASK_GROUP_EXPORT_ERROR";

export const BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST = "BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST";
export const BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_SUCCESS = "BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR = "BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_ERROR";

export const BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST = "BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST";
export const BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_SUCCESS = "BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR = "BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_ERROR";

export const BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST = "BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST";
export const BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_SUCCESS = "BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR = "BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_ERROR";

export const BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST = "BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST";
export const BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_SUCCESS = "BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR = "BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_ERROR";

export const UPDATE_CROWD_TASK_GROUP_FILED_STORE = "UPDATE_CROWD_TASK_GROUP_FILED_STORE";