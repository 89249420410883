import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import TableDiv from "../../../components/TableDiv";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {getFileDataByLink} from "../../../utils/gueryStringHelper";

import {EDM_EXPORT_STATUS} from "../../../constants/edmExport";
import {UPLOAD_STATUS} from "../../../constants/financeExport";
import {edoExportListHeaders} from "../../../constants/headersTable";

import {downloadDocument} from "../../../ducks/documents";
import {
    downloadEdoExportFiles, edoExportDataTaskIdsSelector,
    edoExportListProgressSelector,
    edoExportListSelector,
    edoExportListTotalCountSelector,
    edoExportListTotalPagesSelector, edoExportStatusListSelector,
    getEdoExportList, getEdoExportStatuses,
} from "../../../ducks/edmStatements";
import {cancelExportById} from "../../../ducks/job";

import "./style.sass";

const EDO_EXPORT_SUCCESS_STATUS = "SUCCESS";

const DocumentManagementExport = props => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [confirmData, setConfirmData] = useState({});


    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const totalPages = useSelector(edoExportListTotalPagesSelector);

    const totalCount = useSelector(edoExportListTotalCountSelector);

    const list = useSelector(edoExportListSelector);

    const progress = useSelector(edoExportListProgressSelector);

    const taskIds = useSelector(edoExportDataTaskIdsSelector);

    const taskResults = useSelector(edoExportStatusListSelector);

    useEffect(() => {
        const statusesTimer = setTimeout(() => getStatuses(), 1000);

        return () => {
            clearTimeout(statusesTimer);
        };
    }, [taskIds]);

    function fetchList() {
        dispatch(getEdoExportList(
            {
                data: {
                    pageNum,
                    pageSize,
                    clientId,
                },
            },
        ));
    };

    function fetchExportStatusReult() {
        dispatch(
            getEdoExportStatuses({
                data: taskIds,
            }),
        );
    };

    function getStatuses() {
        if (taskIds.length === 0) {
            return;
        }

        if (taskResults.some(({state}) => {
            return state === EDM_EXPORT_STATUS.SUCCESS;
        })) {
            fetchExportStatusReult();
            fetchList();
            return;
        }

        fetchExportStatusReult();
    };

    const download = (data) => {
        const {fileName, fileLink, exportType, exportId} = data;

        if(exportType === "REGISTRY"){
            const fileData = getFileDataByLink(fileLink, `${fileName}.xlsx`);
            dispatch(downloadDocument({
                ...fileData,
            }));
            return;
        }
        dispatch(downloadEdoExportFiles({
            exportId,
        }));
    };

    const cancelExport = (exportId) => {
        dispatch(cancelExportById({
            exportId,
            onSuccess: fetchList,
        }));
    };


    function renderAction(data) {
        if (data.state === EDM_EXPORT_STATUS.IN_PROGRESS) {
            return (<NmButton
                onClick={() => setConfirmData({
                    content: "Вы уверены, что хотите отменить формирование документа?",
                    onConfirm: () => cancelExport(data.exportId),
                    confirmButton: "Да",
                    cancelButton: "Нет",
                })}
                color="grey"
            >
                Отменить
            </NmButton>);
        }

        return (data.state === EDM_EXPORT_STATUS.SUCCESS &&
            <NmButton
                onClick={() => download(data)}
                color="light-green"
            >
                Скачать
            </NmButton>
        );
    };

    function getTaskStatus(item) {
        const {
            state,
            exportId,
            stateDescription,
        } = item;

        if (state === EDM_EXPORT_STATUS.IN_PROGRESS) {
            const task = taskResults.find(item => item.exportId === exportId);

            return task ? task.process : "";
        }
        return stateDescription;
    };

    function mapData() {
        const rows = list.map(item => {
            return {
                ...item,
                dateCreated: dateFormat(convertUtcToLocal(item.dateCreated)),
                action: renderAction(item),
                stateDescription: getTaskStatus(item),
            };
        });

        return {
            headers: edoExportListHeaders,
            rows,
        };
    };

    function handlePaginationChange(e, {activePage: newPageNum}) {
        if (pageNum === newPageNum) {
            return;
        }

        setPageNum(newPageNum);
    };

    function onChangePageSize(pageSize) {
        setPageSize(pageSize);
    };

    const renderModalWindow = () => {
        const {
            content,
            onConfirm,
            confirmButton,
            cancelButton,
        } = confirmData;

        return !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    };

    return (
        <NmPage
            totalPages={totalPages}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            className="edm-export-list"
            onChangePageSize={onChangePageSize}
            onPaginationChange={handlePaginationChange}
            totalCount={totalCount}
            isLoaded={progress}
            header={
                <NmPageHeader
                    text="Экспорт документов и заявлений ЭДО"
                    totalCount={totalCount}
                />
            }
        >
            {renderModalWindow()}
            {!(totalCount || progress) ?
                <NmEmptyPageV2
                    title="Список выгрузок пуст"
                /> :
                <TableDiv
                    className="edm-export-list__table"
                    tableData={mapData()}
                />
            }
        </NmPage>
    );
};

DocumentManagementExport.propTypes = {};

export default (DocumentManagementExport);