import React from "react";

import HelpTooltip from "../HelpTooltip";
import NmCheckboxV2, {TNmCheckboxParams} from "../NmCheckboxV2";

import bem from "../../../utils/bem";

import "./styles.sass";

export interface INmHorizontalToggleV2 {
    leftLabel?: string,
    rightLabel?: string,
    className?: string,
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, params: TNmCheckboxParams) => void,
    name?: string,
    disabled?: boolean,
    contentRows?: {titleBlock?: string, textBlock?: string, postScripts?: boolean}[],
    suggestion?: string,
    duplex?: boolean,
    single?: boolean,
    tooltipIconWidth?: number,
    noWrapLabel?: boolean,
    tooltipIconHeight?: number
}

const NmHorizontalToggleV2: React.FC<INmHorizontalToggleV2> = (props) => {
    const {
        leftLabel,
        rightLabel,
        className,
        checked,
        onChange,
        name,
        disabled,
        contentRows,
        suggestion,
        single = false,
        duplex = true,
        noWrapLabel,
        tooltipIconWidth = 24,
        tooltipIconHeight = 24,
    } = props;

    const [block, element] = bem("nm-horizontal-toggle-v2", className);

    return (
        <div className={block()}>
            {
                leftLabel &&
                <span
                    className={element("label", {
                        active: single ? checked : !checked,
                        disabled,
                        noWrap: noWrapLabel,
                    })}
                >
                    {leftLabel}
                </span>
            }
            <NmCheckboxV2
                className={element("switcher", {
                    marginLeft: Boolean(leftLabel),
                    marginRight: Boolean(rightLabel),
                })}
                checked={checked}
                onChange={onChange}
                name={name}
                disabled={disabled}
                toggle
                duplex={duplex}
            />
            {
                rightLabel &&
                <span className={element("label", {active: checked, disabled})}>
                    {rightLabel}
                </span>
            }
            {
                (!!contentRows?.length || !!suggestion) &&
                <HelpTooltip
                    hover
                    info
                    width={tooltipIconWidth}
                    height={tooltipIconHeight}
                    className={element("helper")}
                    children={suggestion}
                    compound={!!contentRows?.length}
                    body={contentRows}
                />
            }
        </div>
    );
};

export default NmHorizontalToggleV2;