import {
    BFF_CLIENTS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST,
    BFF_CLIENTS_INSTRUCTIONS_KEDO_UPDATE_STORE,
} from "./actions";

export const getClientsKedoInstructions = (payload) => {
    return {
        type: BFF_CLIENTS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST,
        payload,
    };
};

export const updateClientsKedoInstructionsStore = (payload) => {
    return {
        type: BFF_CLIENTS_INSTRUCTIONS_KEDO_UPDATE_STORE,
        payload,
    };
};