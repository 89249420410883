import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getClientGroups} from "../../../../ducks/clientGroups";


export function useClientGroupFetchList({filter = {}, pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        setLoading(true);
        const requestData = {
            pageNum,
            pageSize,
            ...filter,
            getResult: () => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            },
        };

        dispatch(getClientGroups(requestData));
    };

    return {
        loading,
        fetchList,
    };
}