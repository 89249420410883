import {all, call,put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import {
    edoDocumentStatusesToDictionary,
    edoDocumentStatusesToOptions,
    edoDocumentTypesToOptions,
    edoDocumentTypesToOptionsUploadEDO,
    edoStaffListByClientUserToOptions,
    edoStaffListToOptions,
} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {
    EDO_DOCUMENT_TOAST_SUCCESS,
    EDO_STATEMENT_TOAST_SUCCESS,
} from "../constants/settings";

const controller = "/edocuments";
//*  TYPES  *//

const GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST = "GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST";
const GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS = "GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS";

const GET_EDO_DOCUMENT_STATES_REQUEST = "GET_EDO_DOCUMENT_STATES_REQUEST";
const GET_EDO_DOCUMENT_STATES_SUCCESS = "GET_EDO_DOCUMENT_STATES_SUCCESS";

const GET_DOCUMENT_INFO_COUNT_REQUEST = "GET_DOCUMENT_INFO_COUNT_REQUEST";
const GET_DOCUMENT_INFO_COUNT_SUCCESS = "GET_DOCUMENT_INFO_COUNT_SUCCESS";
const GET_DOCUMENT_INFO_COUNT_ERROR = "GET_DOCUMENT_INFO_COUNT_ERROR";

const REJECT_EDO_DOCUMENT_REQUEST = "REJECT_EDO_DOCUMENT_REQUEST";

const SIGN_EDO_DOCUMENT_REQUEST = "SIGN_EDO_DOCUMENT_REQUEST";
const SIGN_EDO_DOCUMENT_SUCCESS = "SIGN_EDO_DOCUMENT_SUCCESS";
const SIGN_EDO_DOCUMENT_ERROR = "SIGN_EDO_DOCUMENT_ERROR";

const GET_EDO_DOCUMENT_TYPES_REQUEST = "GET_EDO_DOCUMENT_TYPES_REQUEST";
const GET_EDO_DOCUMENT_TYPES_SUCCESS = "GET_EDO_DOCUMENT_TYPES_SUCCESS";

const GET_PAGE_EDO_STAFF_REQUEST = "GET_PAGE_EDO_STAFF_REQUEST";
const GET_PAGE_EDO_STAFF_SUCCESS = "GET_PAGE_EDO_STAFF_SUCCESS";

const UPLOAD_EDO_DOCUMENT_REQUEST = "UPLOAD_EDO_DOCUMENT_REQUEST";

const SEND_EDO_CODE_VERIFICATION_REQUEST = "SEND_EDO_CODE_VERIFICATION_REQUEST";

const ARCHIVE_EDO_DOCUMENT_REQUEST = "ARCHIVE_EDO_DOCUMENT_REQUEST";

const REPLACE_EDO_DOCUMENT_REQUEST = "REPLACE_EDO_DOCUMENT_REQUEST";


// типы документов справочник
const DELETE_EDO_DOCUMENT_TYPE_REQUEST = "DELETE_DOCUMENT_TYPE_REQUEST";

const UPDATE_EDO_DOCUMENT_TYPE_REQUEST = "UPDATE_DOCUMENT_TYPE_REQUEST";

const ADD_EDO_DOCUMENT_TYPE_REQUEST = "ADD_DOCUMENT_TYPE_REQUEST";

// Типы заявлений справочник
const DELETE_EDO_STATEMENT_TYPE_REQUEST = "DELETE_EDO_STATEMENT_TYPE_REQUEST";

const UPDATE_EDO_STATEMENT_TYPE_REQUEST = "UPDATE_EDO_STATEMENT_TYPE_REQUEST";

const ADD_EDO_STATEMENT_TYPE_REQUEST = "ADD_EDO_STATEMENT_TYPE_REQUEST";

const GET_EDO_STATEMENT_TYPES_REQUEST = "GET_EDO_STATEMENT_TYPES_REQUEST";
const GET_EDO_STATEMENT_TYPES_SUCCESS = "GET_EDO_STATEMENT_TYPES_SUCCESS";

const UPDATE_EDO_DOCUMENTS_STORE = "UPDATE_EDO_DOCUMENTS_STORE";

const GET_EDO_ACCESS_LIST_REQUEST = "GET_EDO_ACCESS_LIST_REQUEST";
const GET_EDO_ACCESS_LIST_SUCCESS = "GET_EDO_ACCESS_LIST_SUCCESS";

const GET_EDO_PRE_FILLED_TEMPLATE_REQUEST = "GET_EDO_PRE_FILLED_TEMPLATE_REQUEST";
const GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS = "GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS";

const CLEAR_EDO_PRE_FILLED_TEMPLATE = "CLEAR_EDO_PRE_FILLED_TEMPLATE";

const UPLOAD_EDO_TEMPLATE_REQUEST = "UPLOAD_EDO_TEMPLATE_REQUEST";

const UPDATE_EDO_TEMPLATE_REQUEST = "UPDATE_EDO_TEMPLATE_REQUEST";

const VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST = "VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST";

const CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST = "CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST";

//*  INITIAL STATE  *//

export const EDO_STAFF_FIELD_LIST = {
    RECEIVERS: "receivers",
    SENDERS: "senders",
    APPROVER: "approver",
};

const initial = {
    documents: [],
    totalCount: 0,
    pageData: {},
    documentInfoCount: 0,
    documentTypes: {
        pageData: {},
        totalCount: 0,
        list: [],
    },
    statementTypes: {
        pageData: {},
        totalCount: 0,
        list: [],
    },
    statuses: [],
    [EDO_STAFF_FIELD_LIST.RECEIVERS]: [],
    [EDO_STAFF_FIELD_LIST.SENDERS]: [],
    [EDO_STAFF_FIELD_LIST.APPROVER]: [],
    accessList: [],
    preFilledTemplate: undefined,
    signProgress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case UPDATE_EDO_DOCUMENTS_STORE:
        return {
            ...state,
            ...payload,
        };
    case GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST:
        const {
            pageSize,
            pageNum,
        } = payload;
        return {
            ...state,
            pageData: {
                pageSize,
                pageNum,
            },
        };
    case GET_EDO_DOCUMENT_TYPES_REQUEST:
        return {
            ...state,
            documentTypes: {
                ...state.documentTypes,
                pageData: payload,
            },
        };
    case GET_EDO_STATEMENT_TYPES_REQUEST:
        return {
            ...state,
            statementTypes: {
                ...state.statementTypes,
                pageData: payload,
            },
        };
    case SIGN_EDO_DOCUMENT_REQUEST:
        return {
            ...state,
            signProgress: true,
        };
    case GET_EDO_STATEMENT_TYPES_SUCCESS: {
        const {
            results: list = [],
            totalCount,
        } = payload;

        return {
            ...state,
            statementTypes: {
                ...state.statementTypes,
                list,
                totalCount,
            },
        };
    }
    case GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS:
        const {
            content: documents = [],
            totalCount,
        } = payload;

        return {
            ...state,
            totalCount,
            documents,
        };
    case GET_EDO_DOCUMENT_STATES_SUCCESS:
        const {statuses = []} = payload;

        return {
            ...state,
            statuses,
        };
    case GET_EDO_DOCUMENT_TYPES_SUCCESS: {
        const {
            results: list = [],
            totalCount,
        } = payload;

        return {
            ...state,
            documentTypes: {
                ...state.documentTypes,
                list,
                totalCount,
            },
        };
    }
    case GET_PAGE_EDO_STAFF_SUCCESS:
        const {
            results: staffList = [],
            field,
        } = payload;

        return {
            ...state,
            [field]: staffList,
        };
    case GET_EDO_ACCESS_LIST_SUCCESS:
        return {
            ...state,
            accessList: Object.values(payload),
        };

    case GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS:
        return {
            ...state,
            preFilledTemplate: payload,
        };
    case CLEAR_EDO_PRE_FILLED_TEMPLATE:
        return {
            ...state,
            preFilledTemplate: undefined,
        };
    case GET_DOCUMENT_INFO_COUNT_SUCCESS:
        return {
            ...state,
            documentInfoCount: payload,
        };
    case SIGN_EDO_DOCUMENT_SUCCESS:
    case SIGN_EDO_DOCUMENT_ERROR:
        return {
            ...state,
            signProgress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getRichPageEdoDocuments(payload) {
    return {
        type: GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST,
        payload,
    };
}

export function getEdoDocumentInfoCount(payload) {
    return {
        type: GET_DOCUMENT_INFO_COUNT_REQUEST,
        payload,
    };
}

export function getEdoDocumentStates(payload) {
    return {
        type: GET_EDO_DOCUMENT_STATES_REQUEST,
        payload,
    };
}

export function rejectEdoDocument(payload) {
    return {
        type: REJECT_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function signEdoDocument(payload) {
    return {
        type: SIGN_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function getEdoDocumentTypes(payload) {
    return {
        type: GET_EDO_DOCUMENT_TYPES_REQUEST,
        payload,
    };
}

export function getPageEdoStaff(payload) {
    return {
        type: GET_PAGE_EDO_STAFF_REQUEST,
        payload,
    };
}

export function uploadEdoDocument(payload) {
    return {
        type: UPLOAD_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function sendCodeVerification(payload) {
    return {
        type: SEND_EDO_CODE_VERIFICATION_REQUEST,
        payload,
    };
}

export function archiveEdoDocument(payload) {
    return {
        type: ARCHIVE_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function replaceEdoDocument(payload) {
    return {
        type: REPLACE_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function deleteEdoDocumentType(payload) {
    return {
        type: DELETE_EDO_DOCUMENT_TYPE_REQUEST,
        payload,
    };
}

export function updateEdoDocumentType(payload) {
    return {
        type: UPDATE_EDO_DOCUMENT_TYPE_REQUEST,
        payload,
    };
}

export function addEdoDocumentType(payload) {
    return {
        type: ADD_EDO_DOCUMENT_TYPE_REQUEST,
        payload,
    };
}

export function deleteEdoStatementType(payload) {
    return {
        type: DELETE_EDO_STATEMENT_TYPE_REQUEST,
        payload,
    };
}

export function updateEdoStatementType(payload) {
    return {
        type: UPDATE_EDO_STATEMENT_TYPE_REQUEST,
        payload,
    };
}

export function addEdoStatementType(payload) {
    return {
        type: ADD_EDO_STATEMENT_TYPE_REQUEST,
        payload,
    };
}

export function getEdoStatementTypes(payload) {
    return {
        type: GET_EDO_STATEMENT_TYPES_REQUEST,
        payload,
    };
}

export function updateEdoDocumentsStore(payload) {
    return {
        type: UPDATE_EDO_DOCUMENTS_STORE,
        payload,
    };
}

export function getEdoAccessList(payload) {
    return {
        type: GET_EDO_ACCESS_LIST_REQUEST,
        payload,
    };
}

export function getEdoPreFilledTemplate(payload) {
    return {
        type: GET_EDO_PRE_FILLED_TEMPLATE_REQUEST,
        payload,
    };
}

export function validateEdoCompletedDocument(payload) {
    return {
        type: VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST,
        payload,
    };
}

export function clearEdoPreFilledTemplate() {
    return {
        type: CLEAR_EDO_PRE_FILLED_TEMPLATE,
    };
}

export function uploadTemplate(payload) {
    return {
        type: UPLOAD_EDO_TEMPLATE_REQUEST,
        payload,
    };
}

export function updateTemplate(payload) {
    return {
        type: UPDATE_EDO_TEMPLATE_REQUEST,
        payload,
    };
}

export function checkEdoDuplicateDocument(payload) {
    return {
        type: CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST,
        payload,
    };
}

// //*  SELECTORS  *//

export const edoDocumentsSelector = state => state.edoDocuments;
export const edoDocumentsListSelector = createSelector(edoDocumentsSelector, ({documents}) => documents);
export const edoDocumentsTotalPagesSelector = createSelector(edoDocumentsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const edoDocumentsTotalCountSelector = createSelector(edoDocumentsSelector, ({totalCount}) => totalCount);

export const edoDocumentStatusesOptionsSelector = createSelector(edoDocumentsSelector, ({statuses}) => edoDocumentStatusesToOptions(statuses, true));

export const edoDocumentTypesOptionsSelector = createSelector(edoDocumentsSelector, ({documentTypes: {list}}) => edoDocumentTypesToOptions(list));
export const edoDocumentTypesUploadDocumentsSelector = createSelector(edoDocumentsSelector, ({documentTypes: {list}}) => edoDocumentTypesToOptionsUploadEDO(list));

export const edoDocumentInfoCountSelector = createSelector(edoDocumentsSelector, ({documentInfoCount}) => documentInfoCount);

export const edoReceiversOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListToOptions(documents[EDO_STAFF_FIELD_LIST.RECEIVERS]));

export const edoReceiversByClientUserOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListByClientUserToOptions(documents[EDO_STAFF_FIELD_LIST.RECEIVERS]));

export const edoSendersOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListToOptions(documents[EDO_STAFF_FIELD_LIST.SENDERS]));

export const edoApproversOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListToOptions(documents[EDO_STAFF_FIELD_LIST.APPROVER]));

export const edoAccessListSelector = createSelector(edoDocumentsSelector, ({accessList}) => accessList);

// Справочники ЭДО заявления
export const edoStatementTypesSelector = createSelector(edoDocumentsSelector, ({statementTypes: {list}}) => list);
export const edoStatementTypesTotalcountSelector = createSelector(edoDocumentsSelector, ({statementTypes: {totalCount}}) => totalCount);
export const edoDocumentsStatementTypesTotalPagesSelector = createSelector(edoDocumentsSelector,
    ({statementTypes: {totalCount, pageData: {pageSize = 0}}}) => getTotalPages(totalCount, pageSize));

// Справочники ЭДО заявления
export const edoDocumentTypesSelector = createSelector(edoDocumentsSelector, ({documentTypes: {list}}) => list);
export const edoDocumentsTypesTotalPagesSelector = createSelector(edoDocumentsSelector,
    ({documentTypes, documentTypes: {totalCount, pageData: {pageSize = 0}}}) => getTotalPages(totalCount, pageSize));

export const edoDocumentsTypesTotalCountSelector = createSelector(edoDocumentsSelector,
    ({documentTypes: {totalCount}}) => totalCount);

export const edoPreFilledTemplate = createSelector(edoDocumentsSelector, ({preFilledTemplate}) => preFilledTemplate);
export const edoSignProgressSelector  = createSelector(edoDocumentsSelector, ({signProgress}) => signProgress);

// //*  SAGA  *//

//POST /api/edocuments/info/richPage
const getRichPageEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getSuccess = () => {
            },
            ...data
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.post(`${controller}/info/richPage`, data);

        if (errorMessage) {
            console.error("Method: getRichPageEdoDocumentSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({type: GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getRichPageEdoDocumentSaga: ", error);
    }
};

//GET /api/edocuments/info/count
const getDocumentInfoCountSaga = function* () {
    try {
        const result = yield request.get(`${controller}/info/count`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: GET_DOCUMENT_INFO_COUNT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_DOCUMENT_INFO_COUNT_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getDocumentInfoCountSaga: ", error);
    }
};

//GET /api/edocuments/info/edoStates
const getEdoDocumentStatesSaga = function* (action) {
    try {
        const {
            errorMessage,
            ...result
        } = yield request.get(`${controller}/info/edoStates`);

        if (errorMessage) {
            console.error("Method: getEdoDocumentStatesSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield put({type: GET_EDO_DOCUMENT_STATES_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getEdoDocumentStatesSaga: ", error);
    }
};

//PATCH /api/edocuments/signing/reject
const rejectEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getSuccess = () => {
            },
            ...data
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(`${controller}/signing/reject`, data);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: rejectEdoDocumentSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield getSuccess();
        yield put(getEdoDocumentInfoCount());
        yield toastSuccess("Успешно отменена");
    } catch (error) {
        console.error("Method: rejectEdoDocumentSaga: ", error);
    }
};

//PATCH /api/edocuments/signing/sign
const signEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...data
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(`${controller}/signing/sign`, data);

        getResult(errorMessage);

        if (errorMessage) {
            errorMessage !== "SMS_CODE_ERROR" && toastError(errorMessage);
            console.error("Method: signEdoDocumentSaga: ", errorMessage);
            yield put({type: SIGN_EDO_DOCUMENT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put(getEdoDocumentInfoCount());
        yield put({type: SIGN_EDO_DOCUMENT_SUCCESS});
        yield toastSuccess("Успешно подписан документ");
    } catch (error) {
        yield put({type: SIGN_EDO_DOCUMENT_ERROR, payload: error});
        console.error("Method: signEdoDocumentSaga: ", error);
    }
};

//POST /api/documents/types/page
const getEdoDocumentTypesSaga = function* ({payload}) {
    try {
        const {
            getSuccess = () => {
            },
            ...data
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.post("documents/types/page", data);

        if (errorMessage) {
            console.error("Method: getEdoDocumentTypesSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({type: GET_EDO_DOCUMENT_TYPES_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getEdoDocumentTypesSaga: ", error);
    }
};

//POST /api/staff/page
const getPageStaffSaga = function* ({payload}) {
    try {
        const {
            getSuccess = () => {
            },
            field,
            ...data
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.post("staff/page", data);

        if (errorMessage) {
            console.error("Method: getPageStaffSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({
            type: GET_PAGE_EDO_STAFF_SUCCESS, payload: {
                field,
                ...result,
            },
        });
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

//POST /api/edocuments/signing/upload
const uploadEdoDocumentSaga = function* ({payload}) {
    const {
        meta,
        handleError,
        formData,
    } = payload;

    try {
        const {
            errorMessage,
        } = yield request.post(`${controller}/signing/upload`, formData, {...getMultipartHeaders()});

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            console.error("Method: uploadEdoDocumentSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        yield toastSuccess("Документ успешно загружен");
    } catch (error) {
        if (handleError) {
            handleError();
        }

        console.error("Method: getPageStaffSaga: ", error);
    }
};

//POST /api/edocuments/signing/upload/template
const uploadEdoDocumentByTemplateSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
            handleError,
        } = payload;

        const {
            errorMessage,
        } = yield request.post(`${controller}/signing/upload/template`, data);

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            console.error("Method: uploadEdoDocumentByTemplateSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        yield toastSuccess("Документ успешно загружен");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};


//PATCH /api/edocuments/signing/replace/template
const updateEdoDocumentByTemplateSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
            handleError,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(`${controller}/signing/replace/template`, data);

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            console.error("Method: updateEdoDocumentByTemplateSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        yield toastSuccess("Документ успешно загружен");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

//GET /api/edocuments/signing/verification

const sendCodeVerificationSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...params
        } = payload;

        const result = yield request.get(`${controller}/signing/verification`, {params});

        getResult(result);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: sendCodeVerificationSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess("Код успешно отправлен");
    } catch (error) {
        console.error("Method: sendCodeVerificationSaga: ", error);
    }
};

///api/edocuments/info/archive/{documentId}
//PATCH /api/edocuments/info/unArchive/{documentId}

const archiveEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            archived,
            documentId,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(`${controller}/info/${archived ? "archive" : "unArchive"}/${documentId}`);

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: archiveEdoDocumentSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        yield toastSuccess(`Документ успешно ${archived ? "архивирован" : "восстановлен из архива"}`);
    } catch (error) {
        console.error("Method: archiveEdoDocumentSaga: ", error);
    }
};

//PATCH /api/edocuments/signing/replace

const replaceEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            formData,
            meta,
            handleError,
        } = payload;

        const {
            errorMessage,
        } = yield request.patch(`${controller}/signing/replace`, formData, {...getMultipartHeaders()});


        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            console.error("Method: replaceEdoDocumentSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        yield toastSuccess("Документ успешно изменен");
    } catch (error) {
        console.error("Method: replaceEdoDocumentSaga: ", error);
    }
};
// DELETE /api/documents/types/{id}
const deleteEdoDocumentTypeSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
        } = payload;
        const {
            errorMessage,
        } = yield request.delete(`documents/types/${id}`);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: deleteEdoDocumentTypeSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        callBack();

        toastSuccess(EDO_DOCUMENT_TOAST_SUCCESS.DELETE);
    } catch (error) {
        console.error("Method: deleteEdoDocumentTypeSaga: ", error);
    }
};

// PATCH /api/documents/types/{id}
const updateEdoDocumentTypeSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
            value,
        } = payload;
        const {
            errorMessage,
        } = yield request.patch(`documents/types/${id}`, {id, value});

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: updateEdoDocumentTypeSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        callBack();

        toastSuccess(EDO_DOCUMENT_TOAST_SUCCESS.EDIT);
    } catch (error) {
        console.error("Method: updateEdoDocumentTypeSaga: ", error);
    }
};

// POST api/documents/types/add
const addEdoDocumentTypeSaga = function* ({payload}) {
    try {
        const {
            value,
            callBack,
        } = payload;
        const {
            errorMessage,
        } = yield request.post("documents/types/add", {value});

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: addEdoDocumentTypeSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        callBack();

        toastSuccess(EDO_DOCUMENT_TOAST_SUCCESS.ADD.replace(":name", value));
    } catch (error) {
        console.error("Method: addEdoDocumentTypeSaga: ", error);
    }
};

// DELETE /api/statements/types/{id}
const deleteEdoStatementTypeSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
        } = payload;
        const {
            errorMessage,
        } = yield request.delete(`statements/types/${id}`);

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: deleteEdoStatementTypeSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        callBack();

        toastSuccess(EDO_STATEMENT_TOAST_SUCCESS.DELETE);
    } catch (error) {
        console.error("Method: deleteEdoStatementTypeSaga: ", error);
    }
};

// PATCH /api/statements/types/{id}
const updateEdoStatementTypeSaga = function* ({payload}) {
    try {
        const {
            callBack,
            id,
            value,
        } = payload;
        const {
            errorMessage,
        } = yield request.patch(`statements/types/${id}`, {id, value});

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: updateEdoStatementTypeSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        callBack();

        toastSuccess(EDO_STATEMENT_TOAST_SUCCESS.EDIT);
    } catch (error) {
        console.error("Method: updateEdoStatementTypeSaga: ", error);
    }
};

// POST api/statements/types/add
const addEdoStatementTypeSaga = function* ({payload}) {
    try {
        const {
            value,
            callBack,
        } = payload;
        const {
            errorMessage,
        } = yield request.post("statements/types/add", {value});

        if (errorMessage) {
            toastError(errorMessage);
            console.error("Method: addEdoStatementTypeSaga: ", errorMessage);

            return {
                done: true,
            };
        }

        callBack();

        toastSuccess(EDO_STATEMENT_TOAST_SUCCESS.ADD.replace(":name", value));
    } catch (error) {
        console.error("Method: addEdoStatementTypeSaga: ", error);
    }
};

//POST /api/statements/types/page
const getEdoStatementTypesSaga = function* ({payload}) {
    try {
        const {
            getSuccess = () => {
            },
            ...data
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.post("statements/types/page", data);

        if (errorMessage) {
            console.error("Method: getEdoStatementTypesSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({type: GET_EDO_STATEMENT_TYPES_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getEdoStatementTypesSaga: ", error);
    }
};

//GET /api/edocuments/access/self
const getEdoAccessListSaga = function* ({payload = {}}) {
    const {
        getResult = () => {},
        onError = () => {},
    } = payload;

    try {
        const {
            errorMessage,
            ...result
        } = yield request.get(`${controller}/access/self`);

        getResult();

        if (errorMessage) {
            console.error("Method: getEdoAccessSaga: ", errorMessage);

            onError();

            return {
                done: true,
            };
        }

        yield put({type: GET_EDO_ACCESS_LIST_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getEdoAccessSaga: ", error);

        onError();
    }
};

//POST /api/edocuments/signing/template/pre-filled
const getEdoPreFilledTemplateSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
        } = payload;

        const {
            typeValidate = "",
            ...pushDAta
        } = data;

        const {errorMessage} = yield request.post(`${controller}/signing/${typeValidate}template/validate`, pushDAta);

        if (errorMessage) {
            console.error("Method: getEdoPreFilledTemplateSaga: ", errorMessage);

            toastError(errorMessage);

            if (meta) {
                yield call(meta, null);
            }

            return {
                done: true,
            };
        }

        const myInit = {
            method: "post",
            body: JSON.stringify(data),
        };

        const response = yield request.getFile(`${window.location.origin}/api/edocuments/signing/${typeValidate}template/pre-filled`, myInit);

        const blob = yield response.blob();

        yield put({type: GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS, payload: blob});

        if (meta) {
            yield call(meta, null);
        }

    } catch (error) {
        console.error("Method: getEdoAccessSaga: ", error);
    }
};

//POST /api/edocuments/signing/checkFile
const validateEdoCompletedDocumentSaga = function* ({payload}) {
    try {
        const {
            handleSuccess,
            handleError,
            data,
        } = payload;

        const {errorMessage} = yield request.post(`${controller}/signing/checkFile`, data);

        if (errorMessage) {
            console.error("Method: validateEdoCompletedDocumentSaga: ", errorMessage);

            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            return {
                done: true,
            };
        }

        if (handleSuccess) {
            yield call(handleSuccess, null);
        }

    } catch (error) {
        console.error("Method: validateEdoCompletedDocumentSaga: ", error);
    }
};

//POST api/edocuments/signing/checkPreviouslySent
const checkEdoDuplicateDocumentSaga = function* ({payload}) {
    try {
        const {
            handleSuccess,
            handleError,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/signing/checkPreviouslySent`, data);


        if (handleSuccess) {
            yield call(() => handleSuccess(result), null);
        }

    } catch (error) {
        console.error("Method: checkEdoDuplicateDocumentSaga: ", error);
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST, getRichPageEdoDocumentSaga),
        takeEvery(GET_EDO_DOCUMENT_STATES_REQUEST, getEdoDocumentStatesSaga),
        takeEvery(REJECT_EDO_DOCUMENT_REQUEST, rejectEdoDocumentSaga),
        takeEvery(SIGN_EDO_DOCUMENT_REQUEST, signEdoDocumentSaga),
        takeEvery(GET_EDO_DOCUMENT_TYPES_REQUEST, getEdoDocumentTypesSaga),
        takeEvery(GET_PAGE_EDO_STAFF_REQUEST, getPageStaffSaga),
        takeEvery(UPLOAD_EDO_DOCUMENT_REQUEST, uploadEdoDocumentSaga),
        takeEvery(SEND_EDO_CODE_VERIFICATION_REQUEST, sendCodeVerificationSaga),
        takeEvery(ARCHIVE_EDO_DOCUMENT_REQUEST, archiveEdoDocumentSaga),
        takeEvery(REPLACE_EDO_DOCUMENT_REQUEST, replaceEdoDocumentSaga),
        takeEvery(DELETE_EDO_DOCUMENT_TYPE_REQUEST, deleteEdoDocumentTypeSaga),
        takeEvery(UPDATE_EDO_DOCUMENT_TYPE_REQUEST, updateEdoDocumentTypeSaga),
        takeEvery(ADD_EDO_DOCUMENT_TYPE_REQUEST, addEdoDocumentTypeSaga),
        takeEvery(DELETE_EDO_STATEMENT_TYPE_REQUEST, deleteEdoStatementTypeSaga),
        takeEvery(UPDATE_EDO_STATEMENT_TYPE_REQUEST, updateEdoStatementTypeSaga),
        takeEvery(ADD_EDO_STATEMENT_TYPE_REQUEST, addEdoStatementTypeSaga),
        takeEvery(GET_EDO_STATEMENT_TYPES_REQUEST, getEdoStatementTypesSaga),
        takeEvery(GET_EDO_ACCESS_LIST_REQUEST, getEdoAccessListSaga),
        takeEvery(GET_EDO_PRE_FILLED_TEMPLATE_REQUEST, getEdoPreFilledTemplateSaga),
        takeEvery(UPLOAD_EDO_TEMPLATE_REQUEST, uploadEdoDocumentByTemplateSaga),
        takeEvery(UPDATE_EDO_TEMPLATE_REQUEST, updateEdoDocumentByTemplateSaga),
        takeEvery(GET_DOCUMENT_INFO_COUNT_REQUEST, getDocumentInfoCountSaga),
        takeEvery(VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST, validateEdoCompletedDocumentSaga),
        takeEvery(CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST, checkEdoDuplicateDocumentSaga),
    ]);
}