import * as yup from "yup";

import {clearSpace} from "../../../utils/stringFormat";
import {handleNumber} from "../../../utils/stringHelper";
import {checkInnFormat} from "../../../utils/validate";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

const validationPatentPayment = () => {
    return yup.object().shape({
        address: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        period: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        paymentSum: yup.string()
            .test("amount", VALIDATIONS_MESSAGE.REQUIRED, function (value) {
                if (!value) {
                    return false;
                }

                const parseValue = handleNumber(clearSpace(value));

                if (parseValue < 1) {
                    return this.createError({
                        message: "Минимальное значение 1",
                        path: "paymentSum",
                    });
                }

                if (parseValue >= 1000000000 ) {
                    return this.createError({
                        message: "Максимальное значение 999 999 999,99",
                        path: "paymentSum",
                    });
                }

                return true;
            }),
        recipientId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        recipientName: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        recipientInn: yup.string()
            .required("Введите ИНН полностью")
            .min(10, "Введите ИНН полностью")
            .test("inn", "Неверный формат ИНН", function (value) {
                return !checkInnFormat(value);
            }),
        recipientKpp: yup.string()
            .required("Введите КПП полностью")
            .min(9, "Введите КПП полностью"),
        recipientKbk: yup.string(),
        recipientOktmo: yup.string()
            .test("oktmo", "ОКТМО может содержать 8 или 11 символов", function (value) {
                if (value?.length === 8) {
                    return true;
                }

                if (value?.length === 11) {
                    return true;
                }

                return false;
            }),
        recipientBik: yup.string()
            .required("Введите БИК полностью")
            .min(9, "Введите БИК полностью"),
        recipientRs: yup.string()
            .required("Введите р/с полностью")
            .min(20, "Введите р/с полностью"),
    });

};

export default validationPatentPayment;
