import React, {ReactNode} from "react";

import bem from "../../../../utils/bem";

import "./style.sass";

export type TNmListCardTitleChildren = ReactNode | string;

interface INmListCardTitle {
    children: ReactNode | string,
    className?: string,
    title?: string,
    onClickLink?: Function
}

const NmListCardTitle = (props: INmListCardTitle) => {
    const {
        children,
        className,
        title,
        onClickLink = () => {},
    } = props;
    const [block] = bem("nm-list-card-title", className);

    return (
        <div
            title={title}
            className={block()}
            onClick={() => onClickLink()}
        >
            {children}
        </div>
    );
};

export default NmListCardTitle;