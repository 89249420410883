import React, {useState} from "react";
import {useDispatch} from "react-redux";

import NmButton from "../NmButton";
import NmTitle from "../NmTitle";

import {addPumpingOutDepositReceiptPlanFromGoogleDisk} from "../../ducks/job";

const DownloadDataFromDeposits = () => {
    const [progress, setProgress] = useState(false);
    const dispatch = useDispatch();

    const startDownload = () => {
        setProgress(true);
        dispatch(addPumpingOutDepositReceiptPlanFromGoogleDisk({
            onSuccess: () => {setProgress(false);},
            onError: () => {setProgress(false);},
        }));
    };

    return (
        <>
            <NmTitle
                size="xl"
                className="mb-5"
            >
                Скачивание данных из плана поступления депозитов
            </NmTitle>
            <div className="mb-5">
                <NmButton
                    disabled={progress}
                    onClick={startDownload}
                >
                    Начать
                </NmButton>
            </div>
        </>
    );
};

export default DownloadDataFromDeposits;