import React, {useEffect} from "react";

import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {KedoDirectoryRoutes} from "./routes";

import {useKedoDirectoryFetchCount} from "./hooks/useFetchCount";

import {openLinkByUrl} from "../../../utils/downloadBlob";
import {ls, USER_ROLE} from "../../../utils/localstorage";

import {isClientUser} from "../../../constants/roles";

export const KedoDirectories = (props) => {
    const clientId = props.match.params.clientId;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const {
        fetchCounts,
    } = useKedoDirectoryFetchCount({
        clientId,
    });

    useEffect(() => {
        fetchCounts();
    }, []);

    if (isClientRole) {
        return (
            <NmPage
                header={
                    <NmTitle
                        size="xl"
                    >
                        Справочники
                    </NmTitle>
                }
                controls={
                    <NmButton
                        size="xl"
                        color="light-green"
                        onClick={() => {
                            openLinkByUrl(process.env.REACT_APP_NAIMIX_KEDO);
                        }}
                    >
                        Войти в КЭДО
                    </NmButton>
                }
            >
                <KedoDirectoryRoutes baseUrl={props.match.path} />
            </NmPage>
        );
    }

    return <KedoDirectoryRoutes baseUrl={props.match.path} />;
};