import React from "react";

import TemplatesContracts from "../contracts";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

const TemplatesApplicationsTasks = (props) => {
    return (
        <TemplatesContracts
            headerText="Заявки по заданиям"
            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION}
            {...props}
        />
    );
};

export default TemplatesApplicationsTasks;