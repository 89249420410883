export const ONBOARDING_SCENARIO_LOG_TYPE = {
    SCENARIO_CREATION: "Создание сценария",
    SCENARIO_EDITING: "Редактирование сценария",
    SCENARIO_ACTIVATION: "Активация сценария",
    SCENARIO_DEACTIVATION: "Деактивация сценария",
    RECRUITER_ADDING: "Создание ссылки для рекрутера",
    CONTRACTOR_ADDING: "Добавление отдельных строк",
};

export const ONBOARDING_SCENARIO_STATUS_DICT = {
    ACTIVE: "Активно",
    INACTIVE: "Неактивно",
};

export const ONBOARDING_SCENARIO_STATUS = {
    ACTIVE: {
        VALUE: "ACTIVE",
        TEXT: "Активно",
        MOD: "green",
        COLOR: "#FFFFFF",
        BACKGROUND: "#128A10",
    },
    INACTIVE: {
        VALUE: "INACTIVE",
        TEXT: "Неактивно",
        MOD: "gray",
        COLOR: "#666666",
        BACKGROUND: "#F1F3F7",
    },
};

export const ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE = {
    INSTANTLY: "INSTANTLY",
    AFTER_CONTRACT_SIGNING : "AFTER_CONTRACT_SIGNING",
};

export const ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE_DICT = {
    [ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.INSTANTLY]: "Сразу",
    [ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.AFTER_CONTRACT_SIGNING]: "После подписания РД",
};

export const ONBOARDING_SCENARIO_CITIZENSHIP_GROUP = {
    RESIDENTS: "RESIDENTS",
    NOT_RESIDENTS: "NOT_RESIDENTS",
};

export const ONBOARDING_SCENARIO_CITIZENSHIP_GROUP_DICT = {
    [ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS]: "Только граждане РФ",
    [ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS]: "Только иностранные граждане",
};

export const ONBOARDING_SCENARIO_CARD_TAB = {
    RECRUITERS: "RECRUITERS",
    CONTRACTORS: "CONTRACTORS",
    LEADS: "LEADS",
};

export const ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS = {
    SIGNED: {
        TEXT: "Подписан",
        VALUE: "SIGNED",
        MOD: "green",
    },
    ON_SIGNING: {
        TEXT: "На подписании",
        VALUE: "ON_SIGNING",
        MOD: "gray",
    },
    DECLINED : {
        TEXT: "Отклонен",
        VALUE: "DECLINED",
        MOD: "red",
    },
};

export const ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS = {
    PRESENT: {
        TEXT: "Прикреплены",
        VALUE: "PRESENT",
        MOD: "green",
    },
    PARTIALLY_PRESENT: {
        TEXT: "Прикреплены частично",
        VALUE: "PARTIALLY_PRESENT",
        MOD: "light-green",
    },
    NOT_PRESENT : {
        TEXT: "Не прикреплены",
        VALUE: "NOT_PRESENT",
        MOD: "gray",
    },
};

export const ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS_OPTIONS = [
    {
        key: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.SIGNED.VALUE,
        text: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.SIGNED.TEXT,
        value: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.SIGNED.VALUE,
    },
    {
        key: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.ON_SIGNING.VALUE,
        text: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.ON_SIGNING.TEXT,
        value: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.ON_SIGNING.VALUE,
    },
    {
        key: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.DECLINED.VALUE,
        text: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.DECLINED.TEXT,
        value: ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.DECLINED.VALUE,
    },
];

export const ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS_OPTIONS = [
    {
        key: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.PRESENT.VALUE,
        text: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.PRESENT.TEXT,
        value: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.PRESENT.VALUE,
    },
    {
        key: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.PARTIALLY_PRESENT.VALUE,
        text: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.PARTIALLY_PRESENT.TEXT,
        value: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.PARTIALLY_PRESENT.VALUE,
    },
    {
        key: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT.VALUE,
        text: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT.TEXT,
        value: ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT.VALUE,
    },
];

export const ONBOARDING_SCENARIO_UPDATE_ERROR_CODE = {
    UPDATED_ONBOARDING_SCENARIO_ALREADY_IN_USE_BY_CONTRACTORS: "UPDATED_ONBOARDING_SCENARIO_ALREADY_IN_USE_BY_CONTRACTORS",
};

export const ONBOARDING_BOUND_SCENARIOS_STATUS = {
    COMPLETED: "COMPLETED",
    NOT_COMPLETED : "NOT_COMPLETED",
};

export const ONBOARDING_TAX_STATUS = {
    PRESENT: "PRESENT",
};

export const ONBOARDING_LEAD_TYPE = {
    INVITATION: "INVITATION",
    LEAD: "LEAD",
};

export const ONBOARDING_SCENARIO_FRAME_CONTRACT_TYPE = {
    NONE: "NONE",
    CIVIL: "CIVIL",
    SMZ: "SMZ",
};