import React from "react";

import NmAdvancedTooltip from "../../../../../components/ActualComponents/NmAdvancedTooltip";
import ExtLink from "../../../../../components/ExtLink";
import NmCheck from "../../../../../components/NmCheck";
import NmReadonlyRating from "../../../../../components/NmReadonlyRating";

import {LINK_CONTRACTOR_PROFILE} from "../../../../../constants/links";

const CrowdTaskContractorInfo = (props) => {
    const {
        contractor = {},
        isShowLink = false,
        isMiniRatingInfo = false,
    } = props;

    const {
        contractorId,
        name,
        contractorRating,
        contractorStatusAcquired,
        contractorStatusNotAcquiredReasons,
        idDocVerified,
        idDocNotVerifiedReasons,
        contractSigned,
    } = contractor || {};

    const getContractorLink = (contractorId, name) => {
        const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

        return (
            <ExtLink
                historyEnabled
                to={link}
            >
                {name}
            </ExtLink>
        );
    };

    const getIndicator = ({isCheck, label, children}) => {
        return (
            <NmAdvancedTooltip
                className="ms-2"
                hover
                position="bottom-left"
                trigger={
                    <NmCheck
                        isCheck={isCheck}
                        label={label}
                    />
                }
                children={children}
            />
        );
    };

    const getPassportStatusTooltipText = () => {
        if (idDocVerified && idDocNotVerifiedReasons?.length) {
            return idDocNotVerifiedReasons.join(",");
        }

        if (idDocVerified && !idDocNotVerifiedReasons?.length) {
            return "Исполнитель прошел проверку документов";
        }

        return (
            <p>
                Проверка не пройдена по следующим причинам:
                <ul>
                    {idDocNotVerifiedReasons?.map(item => <li>
                        {item}
                    </li>)}
                </ul>
            </p>
        );
    };

    return (
        <div className="flex flex-flow-wrap flex-aligned-center">
            {isShowLink && getContractorLink(contractorId, name)}
            <NmReadonlyRating
                rating={contractorRating || 0}
                maxRating={isMiniRatingInfo ? 1 : 5}
                isMiniRatingInfo={isMiniRatingInfo}
            />
            {
                getIndicator({
                    isCheck: contractorStatusAcquired,
                    label: "Самозанятый",
                    children: contractorStatusAcquired ?
                        "Исполнитель зарегистрирован в качестве самозанятого и подтвердил партнерство с Наймикс" :
                        <p>
                            Проверка не пройдена по следующим причинам:
                            <ul>
                                {contractorStatusNotAcquiredReasons?.map(item => <li>
                                    {item}
                                </li>)}
                            </ul>
                        </p>,
                })
            }
            {
                getIndicator({
                    isCheck: idDocVerified,
                    label: "Паспорт проверен",
                    children: getPassportStatusTooltipText(),
                })
            }
            {
                getIndicator({
                    isCheck: contractSigned,
                    label: "РД НПД",
                    children: contractSigned ?
                        "С исполнителем заключен рамочный договор" :
                        "Рамочный договор с исполнителем отсутствует",
                })
            }
        </div>
    );
};

export default CrowdTaskContractorInfo;