import {
    CLIENT_BRANDS_ADD_ERROR,
    CLIENT_BRANDS_ADD_LOGO_ERROR,
    CLIENT_BRANDS_ADD_LOGO_REQUEST,
    CLIENT_BRANDS_ADD_LOGO_SUCCESS,
    CLIENT_BRANDS_ADD_REQUEST,
    CLIENT_BRANDS_ADD_SUCCESS,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_SUCCESS,
    CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR,
    CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST,
    CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_SUCCESS,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_SUCCESS,
    CLIENT_BRANDS_DELETE_LOGO_ERROR,
    CLIENT_BRANDS_DELETE_LOGO_REQUEST,
    CLIENT_BRANDS_DELETE_LOGO_SUCCESS,
    CLIENT_BRANDS_GET_LOGS_ERROR,
    CLIENT_BRANDS_GET_LOGS_REQUEST,
    CLIENT_BRANDS_GET_LOGS_SUCCESS,
    CLIENT_BRANDS_GET_PAGE_ERROR,
    CLIENT_BRANDS_GET_PAGE_REQUEST,
    CLIENT_BRANDS_GET_PAGE_SUCCESS,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_SUCCESS,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_SUCCESS,
    CLIENT_BRANDS_UPDATE_ERROR,
    CLIENT_BRANDS_UPDATE_REQUEST,
    CLIENT_BRANDS_UPDATE_STORE,
    CLIENT_BRANDS_UPDATE_SUCCESS,
} from "./actions";

import {getTotalPages} from "../../../utils/mathHelper";

export const getClientBrandInitialState = () => {
    return {
        list: [],
        pageData: {},
        totalCount: 0,
        progress: false,
        logsPageData: {},
        logsList: [],
        logsTotalCount: 0,
        logsProgress: false,
        progressAction: false,
        projects: {
            progress: true,
            list: [],
            totalCount: 0,
            pageData: {},
        },
        objects: {
            lists: {},
        },
        projectsAndObjects: {},
        progressSearch: false,
    };
};

const initialState = getClientBrandInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST:
        return {
            ...state,
            progressSearch: true,
        };
    case CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_SUCCESS:
        return {
            ...state,
            projectsAndObjects: payload,
            progressSearch: false,
        };
    case CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR:
        return {
            ...state,
            progressSearch: false,
        };
    case CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST:
        const projectId = payload.projectId;

        const {
            [projectId]: objects = {},
        } = state.objects.lists;

        return {
            ...state,
            objects: {
                ...state.objects,
                lists: {
                    ...state.objects.lists,
                    [projectId]: {
                        ...objects,
                        isLoading: true,
                        pageData: payload,
                    },
                },
            },
        };
    case CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_SUCCESS: {
        const {
            [payload.projectId]: objects = {},
        } = state.objects.lists;
        const {list = []} = objects;
        const {
            totalCount,
            results,
            nextAllPagesSelectionType,
            ...otherPayload
        } = payload;

        const _results = results || [];

        return {
            ...state,
            objects: {
                ...state.objects,
                ...otherPayload,
                lists: {
                    ...state.objects.lists,
                    [payload.projectId]: {
                        ...objects,
                        list: [
                            ...list,
                            ..._results,
                        ],
                        nextAllPagesSelectionType,
                        isLoading: false,
                        totalCount: totalCount,
                        totalPages: getTotalPages(totalCount, objects.pageData.pageSize),
                    },
                },
            },
        };
    }
    case CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR:
        return {
            ...state,
            objects: {
                ...state.objects,
                progress: false,
            },
        };
    case CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST:
        return {
            ...state,
            projects: {
                ...state.projects,
                pageData: payload,
                progress: true,
            },
        };
    case CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_SUCCESS:
        const {
            results,
            totalCount,
        } = payload;

        return {
            ...state,
            projects: {
                ...state.projects,
                list: state.projects.pageData.isSearchCleared ?
                    results :
                    [
                        ...state.projects.list,
                        ...results,
                    ],
                totalCount,
                progress: false,
            },
        };
    case CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR:
        return {
            ...state,
            projects: {
                ...state.projects,
                progress: false,
            },
        };
    case CLIENT_BRANDS_GET_LOGS_REQUEST:
        return {
            ...state,
            logsPageData: payload,
            logsProgress: true,
        };
    case CLIENT_BRANDS_GET_LOGS_ERROR:
        return {
            ...state,
            logsProgress: false,
        };
    case CLIENT_BRANDS_GET_LOGS_SUCCESS:
        return {
            ...state,
            logsList: payload.results,
            logsTotalCount: payload.totalCount,
            logsProgress: false,
        };
    case CLIENT_BRANDS_GET_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case CLIENT_BRANDS_GET_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    case CLIENT_BRANDS_GET_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_SUCCESS:
    case CLIENT_BRANDS_ADD_LOGO_ERROR:
    case CLIENT_BRANDS_ADD_LOGO_SUCCESS:
    case CLIENT_BRANDS_ADD_ERROR:
    case CLIENT_BRANDS_ADD_SUCCESS:
    case CLIENT_BRANDS_DELETE_LOGO_ERROR:
    case CLIENT_BRANDS_DELETE_LOGO_SUCCESS:
    case CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR:
    case CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_SUCCESS:
    case CLIENT_BRANDS_UPDATE_ERROR:
    case CLIENT_BRANDS_UPDATE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST:
    case CLIENT_BRANDS_UPDATE_REQUEST:
    case CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST:
    case CLIENT_BRANDS_DELETE_LOGO_REQUEST:
    case CLIENT_BRANDS_ADD_REQUEST:
    case CLIENT_BRANDS_ADD_LOGO_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case CLIENT_BRANDS_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};