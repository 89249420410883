import {
    addKedoTemplateDocumentType,
    deleteKedoTemplateDocumentType,
    getKedoTemplateDocumentTypeClientUsagePage,
    getKedoTemplateDocumentTypesPage,
    updateKedoTemplateDocumentType,
    updateKedoTemplateDocumentTypeClientUsage,
} from "../../../../../../kedo/directories/actionCreators";

const controller = "/adm/settings/directories/kedo/template-document-types";

export const updateAdmKedoTemplateDocumentType = (payload) => {
    return updateKedoTemplateDocumentType({
        url: `${controller}/update`,
        ...payload,
    });
};

export const updateAdmKedoTemplateDocumentTypeClientUsage = (payload) => {
    return updateKedoTemplateDocumentTypeClientUsage({
        url: `${controller}/updateClientUsage`,
        ...payload,
    });
};

export const getAdmTemplateDocumentTypesPage = (payload) => {
    return getKedoTemplateDocumentTypesPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const getAdmKedoTemplateDocumentTypeClientUsagePage = (payload) => {
    return getKedoTemplateDocumentTypeClientUsagePage({
        url: `${controller}/getClientUsagePage`,
        ...payload,
    });
};

export const addAdmKedoTemplateDocumentType = (payload) => {
    return addKedoTemplateDocumentType({
        url: `${controller}/add`,
        ...payload,
    });
};

export const deleteAdmKedoTemplateDocumentType = (payload) => {
    return deleteKedoTemplateDocumentType({
        url: `${controller}/delete`,
        ...payload,
    });
};
