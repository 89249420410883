import {useState} from "react";
import {useDispatch} from "react-redux";

import {handleFormString, isNullOrWhitespace} from "../../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../../constants/validationRules";

import {
    updateContractorWorkContraindication,
} from "../../../../../../../ducks/contractorProfile";

export const defaultContraindicationsWorkForm = {
    workContraindications: "",
    hasWorkContraindications: false,
};

export default function useUpdateContraindicationsWork({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultContraindicationsWorkForm});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const {
        workContraindications,
        hasWorkContraindications,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        if (name === "hasWorkContraindications" && checked) {
            setForm(prevState => ({
                ...prevState,
                hasWorkContraindications: checked,
                workContraindications: "",
            }));
            setError({});
            return;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const validateForm = () => {
        if (hasWorkContraindications) {
            return true;
        }

        if (isNullOrWhitespace(workContraindications)) {
            setError({workContraindications: requiredMessage});
            return false;
        }

        setError({});
        return true;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        dispatch(updateContractorWorkContraindication({
            contractorId,
            workContraindications: handleFormString(workContraindications),
            hasWorkContraindications: !hasWorkContraindications,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        error,
    };
}