import React from "react";

import Text from "../../../components/ActualComponents/Text";

import {useCalculate} from "./hooks/useCalculate";

import {COLOR} from "../../../constants/color";

const RegistriesCivilCalculator = ({orderAmountForPayment}) => {
    const {
        data,
    } = useCalculate({orderAmountForPayment});

    return (
        <div className="registries-civil-calculator">
            {
                data.map(({title, value, className = ""}, index) => {
                    return (
                        <div
                            key={index}
                            className={`d-flex justify-content-between ${className}`}
                        >
                            <Text
                                level="3"
                                color={COLOR.SECONDARY_70}
                            >
                                {title}
                            </Text>
                            <Text.Sub
                                className="ms-1"
                                medium
                                level="1"
                            >
                                {value}
                            </Text.Sub>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default RegistriesCivilCalculator;