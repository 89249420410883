import {postman} from "./postman";

export async function fnsRequest({
    typeMethod = "get",
    url,
    data,
}) {
    try {
        async function request() {
            const result = await postman[typeMethod](url, typeMethod === "get" ?  {
                params: data,
            } : {...data});

            const {errorMessage} = result;

            if (errorMessage) {
                return Promise.reject(errorMessage);
            }

            return Promise.resolve(result);
        }

        return request();
    } catch (e) {
        return Promise.reject(e);
    }
};