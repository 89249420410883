import React, {useEffect} from "react";
import {useMedia} from "react-media";
import {isEmpty} from "lodash";

import {ReactComponent as IconSearch} from "../../images/search.svg";
import CheckboxAccordionList from "../ActualComponents/CheckboxAccordionList";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import NmBadge from "../NmBadge";
import NmButton from "../NmButton";
import NmPage from "../NmPage";
import NmTitle from "../NmTitle";

import {closeConfirmAction, onConfirmAction} from "../../hooks/useConfirm";
import useTemplatesClientUsageForm from "./hooks/useForm";
import {useTemplatesClientUsageSort} from "./hooks/useSort";

import bem from "../../utils/bem";
import {formatLocalDate} from "../../utils/dateFormat";

import {COLOR} from "../../constants/color";

import PropTypes from "prop-types";

import "./style.sass";

const TemplatesClientUsage = (props) => {
    const {
        fetchTemplates,
        onClose,
        progressAction,
        totalCount,
        list,
        totalPages,
        actionTypeDict,
        updateClientUsage,
        fetchList,
        sortOptions,
        isVisibleSetDefaultTemplateButton,
    } = props;
    const isMobile = useMedia({query: {maxWidth: 767}});

    const {
        orderType,
        onClickSort,
    } = useTemplatesClientUsageSort();

    const {
        pageSize,
        pageNum,
        onChangePageSize,
        onPaginationChange,
        isSearch,
        onSearch,
        filter,
        onChangeFilter,
        onClickAddToDefault,
        onSubmit,
        selectedList,
        onSelectRow,
        setSelectedList,
        contentConfirm,
        dispatchConfirm,
        isOpenConfirm,
    } = useTemplatesClientUsageForm({
        orderType,
        onClose,
        fetchTemplates,
        updateClientUsage,
        fetchList,
    });

    const [, element] = bem("templates-client-usage");

    useEffect(() => {
        if (isEmpty(list)) {
            return;
        }

        const _list = list.map(item => {
            return {
                ...item,
                isSelected: item.enabled,
            };
        });
        setSelectedList(_list);
    }, [list]);

    const getDisabledFrom = ({disabledDateTime, useOnObjects}) => {
        if (useOnObjects) {
            return (
                <HelpTooltip
                    info
                    width={24}
                    height={24}
                    position="bottom-left"
                    text={"Шаблон не может быть отключен, так как используется для подписания на уровне объектов компании. Для отключения шаблона замените шаблон или отключите опцию \"Заключать РД на объекте\" у объектов."}
                />
            );
        }

        if (!disabledDateTime) {
            return null;
        }

        return (
            <NmBadge
                condition
                mod="gray"
                text={`отключено с ${formatLocalDate(disabledDateTime, "dd.MM.yy")}`}
            />
        );
    };

    function renderName(item) {
        const {
            clientName,
        } = item;

        return (
            <div className="templates-client-usage__header-row">
                <div className="templates-client-usage__name-container">
                    <div
                        title={clientName}
                        className="templates-client-usage__name"
                    >
                        {clientName}
                    </div>
                    {getDisabledFrom(item)}
                </div>
            </div>
        );
    }

    const getContent = (item, index) => {
        const {actions} = item;

        return (
            <>
                {
                    isVisibleSetDefaultTemplateButton && isMobile &&
                    <div className="templates-client-usage__content-default-button">
                        {getDefaultButtonContent(item, index)}
                    </div>
                }
                <div className="templates-client-usage__content">
                    {
                        actions.map((item, index) => {
                            const {
                                actionType,
                                actionDateTime,
                            } = item;

                            return (
                                <Text
                                    key={index}
                                    color={COLOR.SECONDARY_45}
                                    className="templates-client-usage__content-item"
                                    level="2"
                                >
                                    {`${actionTypeDict[actionType]} c ${formatLocalDate(actionDateTime, "dd.MM.yy")}`}
                                </Text>
                            );
                        })
                    }
                </div>
            </>
        );
    };

    const getButton = (item, index) => {
        const {
            isSelected,
            defaultTemplate,
        } = item;

        return (
            <Text
                level="3"
                className={element("button", {
                    green: defaultTemplate,
                    disabled: !isSelected,
                })}
                onClick={() => {
                    onClickAddToDefault(item, index);
                }}
            >
                {defaultTemplate ? "Установлено по умолчанию" : "Установить по умолчанию"}
            </Text>
        );
    };

    const tooltipContent = ({isSelected, defaultTemplate}) => {
        if (!isSelected) {
            return "Вы можете выбрать компании и установить или убрать им данный шаблон по умолчанию";
        }

        return defaultTemplate ?
            "При нажатии с данного шаблона будет снят признак шаблона по умолчанию" :
            "При нажатии данный шаблон получит признак \"Шаблон по умолчанию\" для соответствующего типа документов";
    };

    const getDefaultButtonContent = (item, index) => {
        return (
            <NmAdvancedTooltip
                hover
                position="bottom-right"
                trigger={getButton(item, index)}
            >
                {tooltipContent(item)}
            </NmAdvancedTooltip>
        );
    };

    function getRows() {
        return selectedList.map((item, index) => {
            const {
                isSelected = false,
                useOnObjects,
                actions,
            } = item;

            const isActions = !isEmpty(actions);

            return {
                ...item,
                isSelected,
                isShowChevronButton: isActions,
                showCheckBox: true,
                disabledCheckBox: useOnObjects,
                additionalHeaderContent: (
                    isVisibleSetDefaultTemplateButton && !isMobile &&
                    <div className={element("button-container", {noHistory: !isActions})}>
                        {getDefaultButtonContent(item, index)}
                    </div>
                ),
                name: renderName(item),
                content: getContent(item, index),
            };
        });
    }

    const getConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                content={contentConfirm}
                onCancel={() => dispatchConfirm(closeConfirmAction())}
                onConfirm={() => dispatchConfirm(onConfirmAction())}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                isOnlyConfirm
            />
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle
                    size="lg"
                >
                    Список компаний
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="column"
                    onClose={onClose}
                    submit={onSubmit}
                    cancelBtnContent="Отменить"
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                />
            }
        >
            <NmPage
                className="templates-client-usage"
                classNameContent="templates-client-usage__page"
                noPadding
                showHeaderBlock={false}
                typeFilter="horizontal"
                filtersBase={
                    <NmForm className="flex-aligned-end mb-3 mb-md-4">
                        <div className="d-flex align-items-end fluid">
                            <div className="templates-client-usage__filter-input">
                                <NmInputV2
                                    size="lg"
                                    label="Наименование компании"
                                    value={filter.clientNameFilter}
                                    onChange={(e, {value}) => onChangeFilter(e, {name: "clientNameFilter", value})}
                                    placeholder="Введите наименование компании"
                                />
                            </div>
                            <NmButton
                                className="templates-client-usage__filter-search"
                                onlyIcon
                                size="lg"
                                color="grey"
                                onClick={onSearch}
                                icon={
                                    <IconSearch />
                                }
                            />
                        </div>
                    </NmForm>
                }
                isHasPageSize={false}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalCount={totalCount}
                totalPages={totalPages}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
            >
                {getConfirm()}
                {list.length === 0 && <NmEmptyPageV2
                    clearStyles
                    isSearch={isSearch}
                    title="Информация отсутствует"
                />}
                {list.length > 0 && <CheckboxAccordionList
                    isRowPositionRelative
                    isModalSortSize
                    sort
                    onClickSort={onClickSort}
                    sortOptions={sortOptions}
                    isShowAllCheckbox={false}
                    classNameHeaderName="templates-client-usage__list-header-name"
                    onSelectedRows={onSelectRow}
                    list={getRows()}
                />}
            </NmPage>
        </NmModal>
    );
};

TemplatesClientUsage.propTypes = {
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default TemplatesClientUsage;
