import React from "react";

import {ReactComponent as IconCancel} from "../../images/cancel_24.svg";
import {InfoMark} from "../ActualComponents/InfoMark";
import {MarkerList} from "../ActualComponents/MarkerList";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import ExtLink from "../ExtLink";
import NmTitle from "../NmTitle";

import {COLOR} from "../../constants/color";
import {LINK_CONTRACTOR_CARD} from "../../constants/links";

export const ContractorBlackListWarningModal = (props) => {
    const {
        onClose,
        contractorsInBlacklist,
        isMassOperation,
    } = props;

    const getTitle = () => {
        return (
            <NmTitle size="lg">
                <div className="d-flex flex-column align-items-center flex-md-row">
                    <IconCancel
                        width={24}
                        height={24}
                        color={COLOR.NEGATIVE_100}
                        className="mb-1 mb-md-0 me-2"
                    />
                    Невозможно отправить приглашение
                </div>
            </NmTitle>
        );
    };

    const getContractorInfo = () => {
        const [{fio, id}] = contractorsInBlacklist;

        return (
            <Text
                level="4"
                color={COLOR.SECONDARY_90}
            >
                Исполнитель&nbsp;
                <ExtLink
                    to={LINK_CONTRACTOR_CARD.replace(":contractorId", id)}
                >
                    {fio}
                </ExtLink>
                &nbsp;состоит в&nbsp;черном списке компании.
            </Text>
        );
    };

    const getContent = () => {
        return (
            <>
                {getContractorInfo()}
                <Text
                    level="4"
                    color={COLOR.SECONDARY_90}
                    className="mt-2"
                >
                    {"Исключите исполнителя из черного списка в разделе Исполнители > Черный список, чтобы повторить попытку"}
                </Text>
            </>
        );
    };

    if (isMassOperation) {
        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        Приглашения отправлены частично
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        mobile="column"
                        submitBtnContent="Понятно"
                        submit={onClose}
                    />
                }
                onClose={onClose}
                classNameContent="flex-column"
            >
                <InfoMark
                    isAttention={true}
                    text="Некоторые исполнители состоят в черном списке компании.
                    Им невозможно отправить приглашение. Исключите исполнителей из черного списка в
                    разделе Исполнители > Черный список, чтобы повторить попытку"
                    className="mb-4"
                />
                <MarkerList
                    classNameOffset="mb-3"
                    list={contractorsInBlacklist.map(item => {
                        return {
                            text: item.fio,
                        };
                    })}
                />
            </NmModal>
        );
    }

    return (
        <NmConfirmV2
            size="sm"
            title={getTitle()}
            mobile="column"
            content={getContent()}
            onCancel={onClose}
            onConfirm={onClose}
            confirmButton="Понятно"
        />
    );
};

export default ContractorBlackListWarningModal;