import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";
import Task from "../../../../components/NmTask";
import NmTitle from "../../../../components/NmTitle";
import MapAdvertsList from "./components/advert-list";
import MapContractorsList from "./components/contractors-list";
import MapOrdersList from "./components/order-list";
import MapTasksList from "./components/task-list";

import {getRequestDataFromMapFilterData} from "../../utils/dto";

import {MARKER_TYPE} from "../../constants";

import {getMapListPageCounts} from "../../../../ducks/bff/map/actionCreators";
import {bffMapObjectsCountsSelector} from "../../../../ducks/bff/map/selectors";

function MapObjectsList(props) {
    const {
        filter,
        isSearch,
        onChangeFilter,
        onOpenInviteModal,
    } = props;

    const counts = useSelector(bffMapObjectsCountsSelector);

    const {
        ordersCount = 0,
        advertsCount = 0,
        crowdTasksCount = 0,
        contractorsCount = 0,
    } = counts;

    const [subPage, setSubPage] = useState(MARKER_TYPE.ORDER);

    const dispatch = useDispatch();

    useEffect(() => {
        onChangeFilter(null, {name: "types", value: [MARKER_TYPE.ORDER]});
    }, []);

    useEffect(() => {
        const requestData = getRequestDataFromMapFilterData(filter);

        dispatch(getMapListPageCounts(requestData));
    }, [filter]);

    const onClickTab = (subPage) => {
        setSubPage(subPage);
        onChangeFilter(null, {name: "types", value: [subPage]});
    };

    const getTabs = () => {
        return ([
            {
                name: "Заказы",
                count: ordersCount,
                active: subPage === MARKER_TYPE.ORDER,
                onClick: () => onClickTab(MARKER_TYPE.ORDER),
            },
            {
                name: "Задания",
                count: crowdTasksCount,
                active: subPage === MARKER_TYPE.CROWD_TASK,
                onClick: () => onClickTab(MARKER_TYPE.CROWD_TASK),
            },
            {
                name: "Исполнители",
                count: contractorsCount,
                active: subPage === MARKER_TYPE.CONTRACTOR,
                onClick: () => onClickTab(MARKER_TYPE.CONTRACTOR),
            },
            {
                name: "Объявления",
                count: advertsCount,
                active: subPage === MARKER_TYPE.ADVERT,
                onClick: () => onClickTab(MARKER_TYPE.ADVERT),
            },
        ]);
    };

    const renderContent = () => {
        if (subPage === MARKER_TYPE.CONTRACTOR) {
            return (
                <MapContractorsList
                    filter={filter}
                    isSearch={isSearch}
                    onOpenInviteModal={onOpenInviteModal}
                />
            );
        }

        if (subPage === MARKER_TYPE.ORDER) {
            return (
                <MapOrdersList
                    filter={filter}
                    isSearch={isSearch}
                    onOpenInviteModal={onOpenInviteModal}
                />
            );
        }

        if (subPage === MARKER_TYPE.ADVERT) {
            return (
                <MapAdvertsList
                    filter={filter}
                    isSearch={isSearch}
                />
            );
        }

        if (subPage === MARKER_TYPE.CROWD_TASK) {
            return (
                <MapTasksList
                    filter={filter}
                    isSearch={isSearch}
                    onOpenInviteModal={onOpenInviteModal}
                />
            );
        }

        return null;
    };

    return (
        <>
            <Task />
            <NmTitle className="mt-4 mb-4">
                Режим списков
            </NmTitle>
            <Tabs
                panes={getTabs()}
            />
            {renderContent()}
        </>
    );
}

export default MapObjectsList;