import {useEffect, useState} from "react";

import {downloadPrivacyPolicy} from "../utils/strapiService";

export function usePrivacyPolicyStrapi() {
    const [data, setData] = useState({fileName: "", url: ""});

    useEffect(() => {
        const fetchData = async () => {
            const dataStrapi = await downloadPrivacyPolicy();

            setData(dataStrapi);
        };
        fetchData();
    }, []);
    return {
        ...data,
    };
}