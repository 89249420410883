export const OPERATION_TYPES_CONTRACTOR_FINANCE = {
    BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_CARD: "BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_CARD",
    BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_PHONE: "BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_PHONE",
    CONTRACTOR_BALANCE_REPLENISHMENT: "CONTRACTOR_BALANCE_REPLENISHMENT",
    DEBT_PAYMENT: "DEBT_PAYMENT",
    DIRECT_PAYMENT_TO_CARD: "DIRECT_PAYMENT_TO_CARD",
    PATENT_PAYMENT: "PATENT_PAYMENT",
    PENALTY_PAYMENT: "PENALTY_PAYMENT",
    REPLENISHMENT_FOR_DEBT_PAYMENT: "REPLENISHMENT_FOR_DEBT_PAYMENT",
    REPLENISHMENT_FOR_PATENT_PAYMENT: "REPLENISHMENT_FOR_PATENT_PAYMENT",
    REPLENISHMENT_FOR_PENALTY_PAYMENT: "REPLENISHMENT_FOR_PENALTY_PAYMENT",
    REPLENISHMENT_FOR_TAX_PAYMENT: "REPLENISHMENT_FOR_TAX_PAYMENT",
    TAX_MONEY_BOX_REPLENISHMENT: "TAX_MONEY_BOX_REPLENISHMENT",
    TAX_PAYMENT: "TAX_PAYMENT",
    TAX_PAYMENT_OFF: "TAX_PAYMENT_OFF",
    TAX_PAYMENT_ON: "TAX_PAYMENT_ON",
    TRANSFER_TAX_TO_CONTRACTOR_CARD: "TRANSFER_TAX_TO_CONTRACTOR_CARD",
    WITHDRAWAL_TO_CONTRACTOR_CARD: "WITHDRAWAL_TO_CONTRACTOR_CARD",
    WITHDRAWAL_TO_CONTRACTOR_PHONE: "WITHDRAWAL_TO_CONTRACTOR_PHONE",
    WITHDRAW_FROM_CONTRACTOR_BALANCE_TO_CARD: "WITHDRAW_FROM_CONTRACTOR_BALANCE_TO_CARD",
    CONTRACTOR_CROWD_BALANCE_REPLENISHMENT: "CONTRACTOR_CROWD_BALANCE_REPLENISHMENT",
    CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD: "CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD",
    DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT: "DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT",
};

export const OPERATION_TYPES_CONTRACTOR_FINANCE_DICT = {
    [OPERATION_TYPES_CONTRACTOR_FINANCE.BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_CARD]: "Пополнение для вывода средств на карту",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.BALANCE_REPLENISHMENT_FOR_WITHDRAWAL_TO_CONTRACTOR_PHONE]: "Пополнение для вывода средств на телефон",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_BALANCE_REPLENISHMENT]: "Пополнение баланса исполнителя",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.DEBT_PAYMENT]: "Уплата задолженности",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_CARD]: "Прямой перевод на карту",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.PATENT_PAYMENT]: "Уплата патента",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.PENALTY_PAYMENT]: "Уплата пени",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.REPLENISHMENT_FOR_DEBT_PAYMENT]: "Пополнение для уплаты задолженности",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.REPLENISHMENT_FOR_PATENT_PAYMENT]: "Пополнение для уплаты патента",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.REPLENISHMENT_FOR_PENALTY_PAYMENT]: "Пополнение для уплаты пени",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.REPLENISHMENT_FOR_TAX_PAYMENT]: "Пополнение для уплаты налога",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.TAX_MONEY_BOX_REPLENISHMENT]: "Пополнение налоговой копилки",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.TAX_PAYMENT]: "Уплата налога",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.TAX_PAYMENT_OFF]: "Выключение оплаты налогов",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.TAX_PAYMENT_ON]: "Включение оплаты налогов",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.TRANSFER_TAX_TO_CONTRACTOR_CARD]: "Перевод налогов на карту",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAWAL_TO_CONTRACTOR_CARD]: "Вывод средств на карту",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAWAL_TO_CONTRACTOR_PHONE]: "Вывод средств на телефон",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.WITHDRAW_FROM_CONTRACTOR_BALANCE_TO_CARD]: "Перевод с баланса на карту",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.CONTRACTOR_CROWD_BALANCE_REPLENISHMENT]: "Зачисление на баланс по заданию",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.CROWD_WITHDRAWAL_TO_CONTRACTOR_CARD]: "Вывод средств по заданию",
    [OPERATION_TYPES_CONTRACTOR_FINANCE.DIRECT_PAYMENT_TO_INDIVIDUAL_ACCOUNT]: "Прямой перевод на р/с ИП",
};

export const CONTRACTOR_FINANCE_PAYMENT_TYPE = {
    CROWD: "CROWD",
};

export const PAYMENT_TYPES_CONTRACTOR_FINANCE_DICT = {
    CARD: "Вывод средств на карту",
    CIVIL: "Оплаты НДФЛ",
    DEBT: "Уплата задолженности",
    PATENT: "Оплаты патентов",
    PENALTY: "Уплата пени",
    PHONE: "Вывод средств на телефон",
    SMZ: "Оплаты НПД",
    TAX: "Уплата налога",
    CROWD: "Оплаты по заданию",
    CROWD_CARD: "Вывод средств по заданию",
    INDIVIDUAL: "Оплаты ИП",
};

export const BANK_TYPES_CONTRACTOR_FINANCE = {
    PLUTONIUM_W1: "PLUTONIUM_W1",
    HERMES_W1: "HERMES_W1",
};

export const BANK_TYPES_CONTRACTOR_FINANCE_DICT = {
    [BANK_TYPES_CONTRACTOR_FINANCE.PLUTONIUM_W1]: "W1Plutonium",
    [BANK_TYPES_CONTRACTOR_FINANCE.HERMES_W1]: "W1Hermes",
};

export const OPERATION_CONTRACTOR_FINANCE_STATUS_DICT = {
    SUCCESSFULLY: "Успешно",
    UNSUCCESSFULLY: "Неуспешно",
};

export const OPERATION_CONTRACTOR_FINANCE_STATUS = {
    SUCCESSFULLY: {
        VALUE: "SUCCESSFULLY",
        TEXT: "Успешно",
        MOD: "green",
    },
    UNSUCCESSFULLY : {
        VALUE: "UNSUCCESSFULLY",
        TEXT: "Неуспешно",
        MOD: "red",
    },
};