import React, {ReactNode} from "react";
import {Link} from "react-router-dom";

import bem from "../../../../utils/bem";

import "./style.sass";

interface ITabsItem {
    itemId: string,
    className?: string,
    link?: string,
    count?: number,
    isCount: boolean,
    isActive: boolean,
    secondary?: boolean
    onClick?: () => void,
    name: string | ReactNode,
    handleRefreshCounters?: () => void,
}

const TabsItem = (props: ITabsItem) => {
    const {
        className = "",
        link,
        count,
        isCount,
        isActive,
        secondary,
        name,
        handleRefreshCounters,
    } = props;
    const [block, element] = bem("tabs-item", className);

    const getContent = () => {
        return (
            <>
                <span
                    className={element("text", {
                        active: isActive,
                        secondary,
                    })}
                >
                    {name}
                </span>
                {
                    isCount &&
                    <div className={element("counter")}>
                        {count}
                    </div>
                }
            </>
        );
    };

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }

        if (handleRefreshCounters) {
            handleRefreshCounters();
        }
    };

    if (link) {
        return (
            <Link
                key={link}
                className={block({
                    active: isActive,
                    noCount: !isCount && secondary,
                    secondary,
                })}
                onClick={(event) => {
                    if (isActive) {
                        event.preventDefault();

                        return;
                    }

                    onClick();
                }}
                to={link}
            >
                {getContent()}
            </Link>
        );
    }

    return (
        <div
            key={link}
            className={block({
                active: isActive,
                noCount: !isCount && secondary,
                secondary,
            })}
            onClick={() => {
                if (isActive) {
                    return;
                }

                onClick();
            }}
        >
            {getContent()}
        </div>
    );
};

export default TabsItem;