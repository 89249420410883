import {useState} from "react";

import {getEndDate, getStartDate} from "../../../../../utils/dateFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../../utils/stringHelper";

export function useContractorEventDocumentsLogFilter() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            documentTypeFilter,
            fromDateFilter,
            toDateFilter,
            transactionIdFilter,
            clientIdFilter,
            documentIdFilter,
        } = filter;

        return {
            transactionIdFilter: handleFormString(transactionIdFilter),
            documentIdFilter: handleFormString(documentIdFilter),
            clientIdFilter: handleFormString(clientIdFilter),
            documentTypeFilter: documentTypeFilter ?  documentTypeFilter : undefined,
            fromDateTimeFilter: isNullOrWhitespace(fromDateFilter) ? undefined : getStartDate(new Date(fromDateFilter)),
            toDateTimeFilter: isNullOrWhitespace(toDateFilter) ? undefined : getEndDate(new Date(toDateFilter)),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
    };
}