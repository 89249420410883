import React from "react";

import bem from "../../../../../utils/bem";

import "./style.sass";

const ContractorVerificationRowContainer = ({children}) => {
    const [block] = bem("contractor-verification-row-container");

    return (
        <div className={block()}>
            {children}
        </div>
    );
};

export default ContractorVerificationRowContainer;