import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import ContractorEventLogFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useContractorEventLogFetchList} from "./hooks/useContractorEventLogFetchList";
import {useEventLogFilter} from "./hooks/useEventLogFilter";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {
    getContractorLogChangesLabels,
    getDeviceInfoLabels,
    getNetworkInfoLabels,
} from "../utils/getLabels";

import {ROLE_DICT} from "../../../../constants/roles";

import {
    contractorEventDictSelector,
    contractorEventLogListSelector,
    contractorEventLogTotalCountSelector,
    contractorEventLogTotalPagesSelector,
} from "../../../../ducks/contractorEventLog";


const initFilter = {
    groupTypesFilter: [],
    fromDateFilter: null,
    toDateFilter: null,
};

function ContractorEventLogList(props) {
    const {
        match: {
            params: {
                contractorId: contractorIdFilter,
            },
        },
    } = props;
    const totalPages = useSelector(contractorEventLogTotalPagesSelector);
    const totalCount = useSelector(contractorEventLogTotalCountSelector);
    const events = useSelector(contractorEventLogListSelector);
    const contractorEventDict = useSelector(contractorEventDictSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");
    const {
        setFilter,
        filter,
        onChangeFilter,
    } = useFilter({initFilter});
    const {
        filterDto,
        setFilterDto,
        contractorEventDictOptions,
        setIsSearch,
        isSearch,
    } = useEventLogFilter();
    const {loading} = useContractorEventLogFetchList({
        filter: filterDto,
        pageNum,
        pageSize,
        contractorIdFilter,
    });

    function renderFullName(performer) {
        const {clientUserRole, name} = performer;

        return (`${name}${ROLE_DICT[clientUserRole] ? ` (${ROLE_DICT[clientUserRole].TEXT})` : ""}`);
    }

    const getPrimaryHeader = (item) => {
        if (item.description) {
            return item.description;
        }

        return contractorEventDict[item.groupType];
    };

    const getEventDescriptionText = (item) => {
        if (item.description) {
            return item.description;
        }

        return item.info;
    };

    const getRows = () => {
        return events.map(item => {
            const {
                dateTime,
                performer,
                device,
                network,
                changes,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={formatDate(convertUtcToLocal(dateTime), "dd.MM.yyyy HH:mm:ss")}
                        primaryHeader={getPrimaryHeader(item)}
                        classNameMainContent="col-16"
                        labels={[
                            ...getContractorLogChangesLabels(changes),
                            {label: "ФИО", text: renderFullName(performer)},
                            (
                                !changes
                                || item.isNewEmailEvent
                            ) &&
                            {
                                label: "Описание события",
                                className: "d-inline-block",
                                text: getEventDescriptionText(item),
                                columnOnMobile: true,
                                columnOnTablet: true,
                                inline: true,
                                noWrap: false,
                            },
                            ...getDeviceInfoLabels(device),
                            ...getNetworkInfoLabels(network),
                        ]}
                    />
                ),
            };
        });
    };

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    return (
        <NmPage
            noPadding
            heightUnset
            header={
                <NmPageHeader
                    text="Журнал событий"
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            isLoaded={loading}
            typeFilter="vertical"
            filtersBase={
                <ContractorEventLogFilter
                    submitFilter={submitFilter}
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                    initFilter={initFilter}
                    setFilter={setFilter}
                    typeOptions={contractorEventDictOptions}
                />
            }
        >
            {totalCount < 1 ?
                <NmEmptyPageV2
                    title="Информация отсутствует"
                    isSearch={isSearch}
                    fetchProgress={loading}
                /> : <CheckboxList
                    rows={getRows()}
                />
            }
        </NmPage>
    );
}

export default ContractorEventLogList;