import React, {useContext} from "react";

import NmMiniInfoCard from "../../../../ActualComponents/NmMiniInfoCard";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";
import {RecruitmentCandidateInformationDeletingConfirm} from "../DeletingConfirm";
import {RecruitmentCandidateDriverLicencesEdit} from "./Edit";

import {useModal} from "../../../../../hooks/useModal";

import {getCandidateInformationEditAction} from "../../utils/getEditAction";
import {isNotEmptyCandidateInformationValue} from "../../utils/isNotEmptyValue";

export const RecruitmentCandidateDriverLicences = (props) => {
    const {
        content,
        value,
        isAccessActions,
    } = props;

    const {
        updateInformation,
        fetchInformation,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        isOpen: isOpenDeleteConfirm,
        onOpenModal: onOpenDeleteConfirm,
        onCloseModal: onCloseDeleteConfirm,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const onDelete = () => {
        updateInformation({
            driverLicences: null,
            onSuccess: () => {
                onCloseDeleteConfirm();
                fetchInformation();
            },
        });
    };

    return (
        <>
            {
                isOpenEdit &&
                <RecruitmentCandidateDriverLicencesEdit
                    onClose={onCloseEdit}
                    initialValue={value}
                />
            }
            {
                isOpenDeleteConfirm &&
                <RecruitmentCandidateInformationDeletingConfirm
                    onCancel={onCloseDeleteConfirm}
                    onConfirm={onDelete}
                />
            }
            <NmMiniInfoCard
                title="Категории водительских прав"
                titleLevel="4"
                options={getCandidateInformationEditAction({
                    isVisibleDelete: isNotEmptyCandidateInformationValue(content),
                    onClickDelete: () => {
                        onOpenDeleteConfirm({});
                    },
                    onClickEdit: () => {
                        onOpenEdit({});
                    },
                })}
                accessEdit={isAccessActions}
            >
                {content}
            </NmMiniInfoCard>
        </>
    );
};
