import {useState} from "react";
import {useFormik} from "formik";

import {depositOfFundValidationSchema} from "../validation";

import {getRequestData} from "../utils/getRequestData";

const useDepositOfFundForm = (isEdit, dependentData = {}, close, onEditDeposit, onAddDeposit) => {
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const initialValues = {
        amount: dependentData.amount || "",
        bankType: dependentData.bankType || null,
    };

    const {
        isValid,
        values,
        errors,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues,
        onSubmit: openConfirm,
        validationSchema: depositOfFundValidationSchema,
        validateOnBlur: false,
        enableReinitialize: true,
        validateOnChange: false,
    });

    function handleOnChange(e, {value, name}) {
        setFieldValue(name, value);
    }

    function onSave() {
        closeConfirm();

        const reqData = getRequestData(values);

        const {
            clientId,
            clientDepositHistoryId,
        } = dependentData;

        const data = {
            ...reqData,
            clientId,
            onSuccess: () => {
                close();
            },
        };

        if (isEdit) {
            onEditDeposit({
                ...data,
                clientDepositHistoryId,
            });

            return;
        }

        onAddDeposit(data);
    }

    function openConfirm() {
        if (!isValid) {
            return;
        }

        setIsOpenConfirm(true);
    }

    function closeConfirm() {
        setIsOpenConfirm(false);
    }

    return {
        isOpenConfirm,
        values,
        errors,
        isValid,
        handleSubmit,
        setFieldValue,
        handleOnChange,
        onSave,
        closeConfirm,
        openConfirm,
        setIsOpenConfirm,
    };
};

export default useDepositOfFundForm;