import {
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
    BFF_CROWD_ACT_REGISTRY_RUN_REQUEST,
    BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS,
    UPDATE_CROWD_ACT_REGISTRY_STORE,
} from "./actions";

const initial = {
    pageData: {},
    card: {},
    list: [],
    totalCount: 0,
    progress: false,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    progressCard: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    case BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST:
        return {
            ...state,
            logPageData: payload,
            logProgress: true,
        };
    case BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS:
        return {
            ...state,
            logList: payload.results,
            logTotalCount: payload.totalCount,
            logProgress: false,
        };
    case BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR:
        return {
            ...state,
            logProgress: false,
        };
    case BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progressCard: false,
        };
    case BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR:
        return {
            ...state,
            progressCard: false,
        };
    case BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST:
    case BFF_CROWD_ACT_REGISTRY_RUN_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_CREATE_ERROR:
    case BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_EDIT_ERROR:
    case BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR:
    case BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR:
    case BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_DELETE_ERROR:
    case BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR:
    case BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR:
    case BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS:
    case BFF_CROWD_ACT_REGISTRY_RUN_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case UPDATE_CROWD_ACT_REGISTRY_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};