import {useEffect, useState} from "react";

import {checkArrivalNoticeCard} from "../../controlDateReadOnly";

import {getDay} from "../../../../../utils/dateFormat";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_ARRIVAL_DOC_TYPE_CODE, CONTRACTOR_FIELD} from "../../../../../constants/contractor";

const useEditFormWarning = (values, card) => {
    const [warning, setWarning] = useState({});

    useEffect(() => {
        const error = checkArrivalNoticeCard({values, card});

        if (values[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT && error) {
            setWarning(prevState => ({
                ...prevState,
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: error,
            }));

            return;
        }

        const errorMessage = error ? error : _checkArrivalNoticeCard();

        setWarning(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: undefined,
            [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: errorMessage,
        }));
    }, [values]);

    //вторая проверка
    function _checkArrivalNoticeCard() {
        const registrationDate = values[CONTRACTOR_FIELD.REGISTRATION_DATE];
        const arrivalNoticeStayEndDate = values[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE];
        const milliseconds = (new Date(arrivalNoticeStayEndDate) - new Date(registrationDate));
        const isRu = citizenshipsDict.RU.value === card[CONTRACTOR_FIELD.CITIZENSHIP];

        return (!isRu && getDay(milliseconds) > DAYS_FOR_CHECK_ARRIVAL_NOTICE_CARD) ?
            "Возможно, некорректная дата окончания срока действия документа" :
            undefined;
    }

    return {
        warning,
        setWarning,
    };
};

export default useEditFormWarning;

const DAYS_FOR_CHECK_ARRIVAL_NOTICE_CARD = 90;