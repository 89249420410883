import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";

import {usePagination} from "../../../hooks/usePagination";
import {useContractorOnboardingLeadsAction} from "./hooks/useAction";
import {useContractorOnboardingLeadsFetch} from "./hooks/useFetch";
import {useContractorOnboardingLeadsFilter} from "./hooks/useFilter";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {phoneFormat} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {COLOR} from "../../../constants/color";
import {
    LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD,
    LINK_CLIENT_INFO,
    LINK_CONTRACTOR_PROFILE,
} from "../../../constants/links";
import {CONTRACTOR_ONBOARDING_LEADS} from "./constants";

import {
    contractorStatusDictSelector,
} from "../../../ducks/contractor";
import {avatarBase64ImagesListSelector} from "../../../ducks/fileStore";

const ContractorOnboardingLeads = (props) => {
    const {
        location: {
            state = {},
        },
    } = props;
    const {
        pageData = {},
    } = state;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination(
        "nm-page",
        pageData.pageSize,
        pageData.pageNum,
    );

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    } = useContractorOnboardingLeadsFilter({
        pageSize,
        setPagination,
        filterData: state.filterData,
    });

    const {
        fetchList,
        list,
        progress,
        totalPages,
        totalCount,
        progressAction,
    } = useContractorOnboardingLeadsFetch({
        filterData,
        pageNum,
        pageSize,
    });

    const {
        getMediaControls,
        setConfirmData,
        confirmData,
    } = useContractorOnboardingLeadsAction({
        fetchList,
    });

    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const statusDict = useSelector(contractorStatusDictSelector);

    const renderLink = (fullName, contractorId) => {
        if (isNullOrWhitespace(contractorId)) {
            return fullName;
        }

        const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

        return (
            <ExtLink
                pageData={{pageNum, pageSize}}
                filterData={
                    filterData
                }
                historyEnabled
                to={link}
            >
                {fullName}
            </ExtLink>
        );
    };

    const renderClientInfo = ({clientId, clientName, brand}) => {
        if (!clientId) {
            return "-";
        }

        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        const brandValue = brand ? ` (${brand})` : "";

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {`${clientName}${brandValue}`}
            </ExtLink>
        );
    };

    const renderScenarioInfo = ({clientId, scenarioId, scenarioNumber, scenarioName}) => {
        if (!scenarioId) {
            return "-";
        }

        const scenarioLink = LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD
            .replace(":clientId", clientId)
            .replace(":scenarioId", scenarioId);

        return (
            <ExtLink
                to={scenarioLink}
                historyEnabled
            >
                {`№ ${scenarioNumber} - ${scenarioName}`}
            </ExtLink>
        );
    };

    const getLabelsByTab = (item) => {
        const {
            firstPersonalDataSubmissionDateTime,
            lastPersonalDataSubmissionDateTime,
            registrationStatus,
            basicRegistrationDateTime,
            externalLeadId,
            source,
        } = item;

        return [
            {
                label: "Телефон",
                text: item.externalContractorPhone ? phoneFormat(item.externalContractorPhone) : "-",
            },
            {
                label: "ИНН",
                text: item.externalContractorInn || "-",
                noWrap: false,
                textTooltip: item.actualContractorInnNotMatchExternalContractorInn && (
                    <HelpTooltip
                        hover
                        attention
                        height={18}
                        width={18}
                        text="Обратите внимание, что переданный в лиде ИНН не совпадает с ИНН в персональных данных исполнителя"
                    />
                ),
            },
            {
                label: "Дата простой регистрации",
                columnOnMobile: true,
                text: basicRegistrationDateTime ? dateFormat(convertUtcToLocal(basicRegistrationDateTime)) : "-",
            },
            {
                label: "Дата первой и последней заявки ПД",
                columnOnMobile: true,
                text: `${dateFormat(convertUtcToLocal(firstPersonalDataSubmissionDateTime))} - ${dateFormat(convertUtcToLocal(lastPersonalDataSubmissionDateTime))}`,
            },
            {
                label: "Идентификатор лида",
                text: externalLeadId || "-",
            },
            {
                label: "Источник создания лида",
                text: source.description || "-",
            },
            {
                label: "Сценарий онбординга",
                text: renderScenarioInfo(item),
            },
            {
                label: "Заказчик",
                text: renderClientInfo(item),
            },
        ];
    };

    const getSecondaryHeaderStatus = (item) => {
        return (
            <NmBadge
                text={item.registrationStatus.description}
                mod={CONTRACTOR_ONBOARDING_LEADS[item.registrationStatus.value || "UNKNOWN"]}
            />
        );
    };

    const rows = useMemo(() => {
        return list.map(item => {
            return {
                ...item,
                key: item.id,
                avatar: <Avatar
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
                isWithSubHeader: true,
                contentRow: (
                    <NmListCard
                        avatar
                        secondaryHeader={
                            <div className="d-flex align-content-center">
                                <Text
                                    color={COLOR.SECONDARY_45}
                                    level="2"
                                    className="me-1"
                                >
                                    Лид от:
                                </Text>
                                <Text
                                    color={COLOR.SECONDARY_100}
                                    level="2"
                                >
                                    {dateFormat(convertUtcToLocal(item.creationDateTime))}
                                </Text>
                            </div>
                        }
                        primaryHeaderNoWrap
                        secondaryHeaderStatus={getSecondaryHeaderStatus(item)}
                        noDivider
                        classNameMainContent="col-16 col-xxl-9"
                        primaryHeader={renderLink(item.externalContractorName, item.contractorId)}
                        actionsClassName="col-1 col-xxl-7 px-0"
                        isFixedActions
                        mediaControls={getMediaControls(item)}
                        labels={getLabelsByTab(item)}
                    />
                ),
            };
        });
    }, [
        list,
        statusDict,
        userAvatarDict,
    ]);

    return (
        <NmPage
            noPadding
            className="client-remote-contractors"
            header={
                <NmPageHeader
                    size="lg"
                    text="Лиды для онбординга"
                    totalCount={totalCount}
                />
            }
            totalCount={totalCount}
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
        >
            {
                confirmData.content &&
                <NmConfirmV2
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progressAction}
                    isNeedClosing={false}
                    content={confirmData.content}
                    onCancel={() => {
                        setConfirmData({});
                    }}
                    onConfirm={confirmData.confirm}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                />
            }
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    /> :
                    <CheckboxList
                        withCheckbox={false}
                        rows={rows}
                    />
            }
        </NmPage>
    );
};

export default ContractorOnboardingLeads;

