import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmPagination from "../../../components/ActualComponents/NmPagination";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import ButtonBack from "../../../components/ButtonBack";
import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";
import {ReactComponent as DoubleCheck} from "../../../images/double-check.svg";
import {ReactComponent as PeopleIcon} from "../../../images/people.svg";
import Chat from "../chat";
import {Dimmer, Loader} from "semantic-ui-react";

import {formatLocalDate} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {getTextByProperty} from "../../../utils/objectHelper";
import {getFullName} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {ORDER_FIELDS_NAME} from "../../../constants/clientList";
import {
    CHAT_LINK_PARAMS,
    CHAT_LS_PARAMS,
    DISPUTE_FIELD,
    DISPUTE_STATUS,
    DISPUTES_LS_PARAMS,
} from "../../../constants/disputes";
import {FIREBASE_MSG_TYPE} from "../../../constants/firebase";
import {
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST} from "../../../constants/links";
import {CLIENT_ACCOUNTANT, CLIENT_ADMIN, OBJECT_MANAGER, PROJECT_MANAGER} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {
    chatLoadingSelector,
    getDisputeMessageList,
    getMessageList,
} from "../../../ducks/chat";
import {
    chatIsLockedSuccessSelector,
    chatListAllSelector,
    chatListTotalPagesSelector,
    getSimpleChatList,
    updateFieldChatListStore,
} from "../../../ducks/chatList";
import {
    disputeListLoadingSelector,
    disputesChatListSelector,
    disputesChatsTotalCountSelector,
    disputeSelector,
    disputeTotalCountForOrderCardSelector,
    disputeTotalPagesSelector,
    getChatList,
    updateFieldDisputeStore,
} from "../../../ducks/dispute";
import {
    addFireBaseToken,
    disputeFirebaseTokenSelector,
    initFirebase,
} from "../../../ducks/disputeFirebase";
import {financeBackLinkSelector, updateFinanceStore} from "../../../ducks/financeExport";
import {
    getOrderById,
    getOrderCardSelector,
    getProgressOrderCardSelector,
    updateFieldOrderStore,
} from "../../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

const ALL_DISPUTES_PARAM = "all";

class ChatList extends Component {
    static propTypes = {
        getClientList: PropTypes.func,
        prevPath: PropTypes.string,
    };

    static defaultProps = {};

    constructor(props) {
        super();

        const {pageNumber, status, disputeId, orderId, chatId, clientId} = props.match.params;

        this.clientId = clientId || "";
        this.orderId = orderId || "";

        const disputeIdUpd = isNullOrWhitespace(disputeId) || disputeId === "all" ? "" : disputeId;

        this.isSimpleChat = !isNullOrWhitespace(chatId) || chatId === "all";

        this.state = {
            disputeListStatus: status,
            disputeId: disputeIdUpd,
            pageNum: Number(pageNumber),
            pageSize: 25,
            historyData: "",
            newMessagesCount: 0,
            chatId: chatId === "all" ? "" : chatId,
        };

        this.role = ls(USER_ROLE);
        this.currentClientUserId = ls(CURRENT_CLIENT_USER_ID);
    }

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, pageName, extData} = state;

            this.setState({
                historyData: {prevPath, pageData, pageName, extData},
            });
        }
    };

    componentDidMount() {
        const {
            getOrderById,
        } = this.props;

        this.saveHistoryData();

        this.startMessagingSceduler();

        if (this.isSimpleChat) {
            this.fetchChatList();
            getOrderById({
                orderId: this.orderId,
                clientId: this.clientId,
            });
        } else {
            this.fetchDisputesList();
        }
    }

    componentWillUnmount() {
        const {updateFieldOrderStore, updateFieldChatListStore, updateFinanceStore} = this.props;

        updateFieldOrderStore("card", {});
        updateFieldChatListStore("chatList", []);
        updateFieldChatListStore("chat", {});
        updateFinanceStore({backLink: ""});
    }

    componentDidUpdate(prevProps) {
        const {
            isAddedMessageSuccess,
            isChatLockedSuccess,
            order,
            progressOrderCard,
        } = this.props;

        if (!progressOrderCard && isEmpty(order)) {
            this.fetchOrder();
        }

        if (isChatLockedSuccess) {
            this.fetchChatList();
        }

    }

    addFirebaseToken = () => {
        const {
            addFireBaseToken,
        } = this.props;

        try {

            const messaging = window.firebase.messaging();

            messaging.getToken().then((token) => {
                addFireBaseToken(token);
            });

        } catch (e) {

        }
    };

    fetchOrder = () => {
        const {
            getOrderById,
        } = this.props;

        if(this.orderId && this.clientId){
            getOrderById({
                orderId: this.orderId,
                clientId: this.clientId,
            });
        }
    };

    fetchChatList = () => {
        const {
            getSimpleChatList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            chatId,
        } = this.state;

        const reqData = {
            chatId,
            pageNum,
            pageSize,
            orderIdFilter: this.orderId,
        };

        getSimpleChatList(reqData);
    };

    fetchDisputesList = () => {
        const {
            getChatList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            disputeListStatus,
            disputeId,
        } = this.state;

        const disputeStatusFilter = disputeListStatus === CHAT_LINK_PARAMS.CLOSED ?
            [disputeListStatus.toUpperCase(), CHAT_LINK_PARAMS.CANCEL.toUpperCase()] :
            [disputeListStatus.toUpperCase()];

        const reqData = {
            disputeId,
            pageNum,
            pageSize,
            disputeStatusFilter,
            orderIdFilter: this.orderId,
        };

        getChatList(reqData);
    };

    changeChat = (chatId) => {
        const {
            pageNum,
            pageSize,
            historyData,
        } = this.state;

        return () => {
            this.setState({
                chatId,
            });

            if (this.clientId) {
                history.replace(LINK_CHAT_LIST
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                    .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatId)
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId)
                    .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId), historyData,
                );

                return;
            }

            history.replace(LINK_ALL_ROLES_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatId)
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId), historyData,
            );
        };
    };

    changeDispute = (disputeId) => {
        const {
            disputeListStatus,
            pageNum,
            pageSize,
        } = this.state;

        return () => {
            this.setState({
                disputeId,
            });

            if ([CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role)) {
                history.push(LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
                    .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
                );
            } else {
                history.push(LINK_DISPUTES_CHAT_LIST
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
                );
            }
        };
    };

    startMessagingSceduler = () => {
        const {
            getDisputeMessageList,
            getMessageList,
            t,
        } = this.props;

        try {

            const messaging = window.firebase.messaging();

            messaging.requestPermission && messaging.requestPermission().then(() => {
                messaging.onMessage((message) => {
                    let {
                        disputeId: currentDisputeId,
                        chatId: currentChatId,
                    } = this.props.match.params;

                    currentDisputeId = (isNullOrWhitespace(currentDisputeId) || currentDisputeId === ALL_DISPUTES_PARAM) ? "" : currentDisputeId;

                    const {
                        data: {
                            type,
                            disputeId,
                            chatId,
                        },
                    } = message;

                    if (type === FIREBASE_MSG_TYPE.UPDATE_DISPUTE_CHAT && !this.isSimpleChat) {
                        if (disputeId === currentDisputeId) {
                            getDisputeMessageList({disputeId, pageNum: 1, pageSize: 50});
                            this.fetchDisputesList();
                        } else {
                            this.fetchDisputesList();
                        }
                    }
                    if (type === FIREBASE_MSG_TYPE.UPDATE_CHAT && this.isSimpleChat) {
                        if (currentChatId === chatId) {
                            getMessageList({chatId, pageNum: 1, pageSize: 50});
                            this.fetchChatList();
                        } else {
                            this.fetchChatList();
                        }
                    }
                });
                messaging.onTokenRefresh(() => {
                    this.addFirebaseToken();
                });
                this.addFirebaseToken();
            }).catch(function (err) {
                console.warn(`${t("chat-list.notifications-disabled")}.`, err);
            });
        } catch (e) {
            console.warn(`${t("chat-list.notifications-disabled")}.`);
        }
    };

    isCurrentUserMsg = (lastMessage) => {
        return this.currentClientUserId === lastMessage.userId;
    };

    getLastMessageSenderText = (lastMessage) => {
        const {
            t,
        } = this.props;

        if (this.isCurrentUserMsg(lastMessage)) {
            return `${t("chat-list.you")}: `;
        }
        const fullName = lastMessage.lastName ? `${lastMessage.lastName} ${lastMessage.firstName ? `${lastMessage.firstName[0]}.` : ""}` : lastMessage.fullName;

        return `${fullName}: `;
    };

    mapData = () => {
        const {
            disputesList,
            t,
        } = this.props;

        const {
            disputeId,
        } = this.state;

        return disputesList.map((item) => {
            const isDisputeOpen = item.statusDispute === CHAT_LINK_PARAMS.OPEN.toUpperCase();
            const isDisputeApproved = item[DISPUTE_FIELD.DISPUTE_STATUS_RESULT] === DISPUTE_STATUS.APPROVED;
            const fullName = item[DISPUTE_FIELD.CONTRACTOR_LAST_NAME] ?
                getFullName(item[DISPUTE_FIELD.CONTRACTOR_LAST_NAME], item[DISPUTE_FIELD.CONTRACTOR_FIRST_NAME], item[DISPUTE_FIELD.CONTRACTOR_PATRONYMIC]) :
                item[DISPUTE_FIELD.FULL_NAME];

            return (
                <div
                    key={item.disputeId}
                    className={item.disputeId === disputeId ? "chat-list__row chat-list__row_active" : "chat-list__row"}
                    onClick={this.changeDispute(item.disputeId)}
                >
                    <div className="chat-list__icon">
                        <PeopleIcon className="chat-list__icon" />
                        {
                            !isNullOrWhitespace(item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT]) && item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT] !== 0 ?
                                <div className="chat-list__msg">
                                    {item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT]}
                                </div> :
                                null
                        }
                    </div>
                    <div className="chat-list__details">
                        <div className="chat-list__details-info">
                            <Text.Sub
                                level="1"
                                medium
                                className="chat-list__name"
                            >
                                {fullName}
                            </Text.Sub>
                            {
                                isDisputeOpen ?
                                    "" :
                                    <div
                                        className={isDisputeOpen ? "chat-list__result chat-list__result_open" : "chat-list__result"}
                                    >
                                        {`${t("chat-list.dispute-closed")}, ${isDisputeApproved ? `${t("chat-list.approved")}.` : `${t("chat-list.rejected")}.`}`}
                                    </div>
                            }
                            <Text
                                level="2"
                                className="chat-list__label"
                            >
                                {item[DISPUTE_FIELD.CLIENT_NAME]}
                                {item[DISPUTE_FIELD.BRAND] && `(${item[DISPUTE_FIELD.BRAND]})`}
                            </Text>
                            <Text
                                level="2"
                                className="chat-list__label"
                            >
                                {item[DISPUTE_FIELD.ORDER_NAME]}
                            </Text>
                        </div>
                        <Text
                            level="2"
                            className="chat-list__time"
                        >
                            {formatLocalDate(item.lastMessageDate, "dd.MM.yyyy HH:mm")}
                        </Text>
                    </div>
                </div>

            );
        });
    };

    renderLastMsgCheck = (lastMsg) => {
        if (this.isCurrentUserMsg(lastMsg)) {
            return lastMsg.read ?
                <DoubleCheck className="chat-list__check" /> :
                <DoneIcon className="chat-list__check" />;
        }
        return null;
    };

    mapChatListData = () => {
        const {
            chatList,
        } = this.props;

        const {
            chatId,
        } = this.state;

        return (
            <>
                {chatList.map((item) => {
                    const lastMessage = getTextByProperty(item[DISPUTE_FIELD.LAST_MESSAGE], "content");
                    const fullName = item[DISPUTE_FIELD.CONTRACTOR_LAST_NAME] ?
                        getFullName(item[DISPUTE_FIELD.CONTRACTOR_LAST_NAME], item[DISPUTE_FIELD.CONTRACTOR_FIRST_NAME], item[DISPUTE_FIELD.CONTRACTOR_PATRONYMIC]) :
                        item[DISPUTE_FIELD.FULL_NAME];

                    return (
                        <div
                            className={item.chatId === chatId ? "chat-list__row chat-list__row_active" : "chat-list__row"}
                            onClick={this.changeChat(item.chatId)}
                            key={item.chatId}
                        >
                            <div className="chat-list__icon">
                                <PeopleIcon className={item.locked ? "chat-list__icon chat-list__icon_locked" : "chat-list__icon"} />
                                {
                                    !isNullOrWhitespace(item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT]) &&
                                    item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT] !== 0 ?
                                        <div className="chat-list__msg chat-list__msg_green">
                                            {item[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT]}
                                        </div> :
                                        null
                                }
                            </div>
                            <div className="chat-list__details">
                                <div className="chat-list__details-info">
                                    <Text.Sub
                                        level="1"
                                        medium
                                        className="chat-list__name"
                                    >
                                        {fullName}
                                    </Text.Sub>
                                    {
                                        isNullOrWhitespace(lastMessage) ?
                                            null :
                                            <div className="d-flex">
                                                <Text
                                                    level="2"
                                                    className="chat-list__label"
                                                >
                                                    {this.getLastMessageSenderText(item[DISPUTE_FIELD.LAST_MESSAGE])}
                                                    {lastMessage}
                                                </Text>
                                                {
                                                    this.renderLastMsgCheck(item[DISPUTE_FIELD.LAST_MESSAGE])
                                                }
                                            </div>
                                    }
                                </div>
                                <div className="chat-list__time">
                                    {formatLocalDate(item.lastMessageDate, "dd.MM.yyyy HH:mm")}
                                </div>
                            </div>
                        </div>
                    );
                })
                }
            </>);
    };

    setStatus = (disputeListStatus) => {
        const {
            pageSize,
            pageNum,
            disputeId,
        } = this.state;

        return () => {
            this.setState({
                disputeListStatus,
            }, this.fetchDisputesList);

            if ([CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role)) {
                history.push(LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST
                    .replace(":clientId", this.clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
                );

                return;
            }

            history.push(LINK_DISPUTES_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
            );

        };
    };

    handleChangePageSize = pageSize => {
        const {
            pageNum,
            disputeListStatus,
            disputeId,
        } = this.state;

        history.push(LINK_DISPUTES_CHAT_LIST
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
            .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
            .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
        );
        this.setState(
            {
                pageSize,
            },
            this.fetchDisputesList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {
            pageNum: pageNumOld,
            pageSize,
            disputeListStatus,
            disputeId,
        } = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        history.push(LINK_DISPUTES_CHAT_LIST
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, disputeListStatus)
            .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, disputeId)
            .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
        );

        this.setState({
            pageNum,
        }, this.fetchDisputesList);
    };

    handleOnClickBack = () => {
        const {
            backLink,
            location,
        } = this.props;

        const {
            historyData,
        } = this.state;

        const {
            prevPath,
            extData,
            pageData,
        } = historyData;

        if (extData) {
            history.push(prevPath, {pageData, extData});

            return;
        }

        if (prevPath && prevPath !== location.pathname) {
            const {prevPath, ...otherData} = historyData;

            history.push(prevPath, otherData);

            return;
        }

        if (backLink) {
            history.push(backLink);

            return;
        }

        if (isNullOrWhitespace(ls(CHAT_LS_PARAMS.CHAT_LIST_BACK_LINK))) {
            const isOpen = ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_STATUS) || CHAT_LINK_PARAMS.OPEN;
            const pageNum = ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_NUMBER) || 1;
            const pageSize = ls(DISPUTES_LS_PARAMS.DISPUTES_LIST_PAGE_SIZE) || 10;

            ls(CHAT_LS_PARAMS.CHAT_LIST_BACK_LINK, "");

            if ([CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role)) {
                history.push(LINK_DISPUTES_CLIENT_ADMIN
                    .replace(":clientId", this.clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, isOpen)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, ""));

                return;
            }

            history.push(LINK_DISPUTES
                .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, isOpen)
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, ""));

            return;
        }

        history.push(ls(CHAT_LS_PARAMS.CHAT_LIST_BACK_LINK));
    };

    renderTabs() {
        const {
            disputeListStatus,
        } = this.state;

        const {
            totalCountOpen,
            t,
        } = this.props;

        return (
            <Tabs
                className="chat-list__tabs"
                panes={[
                    {
                        count: totalCountOpen,
                        name:  t("chat-list.open-chats"),
                        active: disputeListStatus === CHAT_LINK_PARAMS.OPEN,
                        onClick: this.setStatus(CHAT_LINK_PARAMS.OPEN),
                    },
                    {
                        name: t("chat-list.closed-chats"),
                        active: disputeListStatus === CHAT_LINK_PARAMS.CLOSED,
                        onClick: this.setStatus(CHAT_LINK_PARAMS.CLOSED),
                    },
                ]}
            />
        );
    }

    renderSimpleChatListCaption = (order) => {
        const {
            t,
        } = this.props;

        return (<>
            {`${t("chat-list.chats-order")} #${order.orderNum || ""}`}
            <div>
                {order[ORDER_FIELDS_NAME.NAME]}
            </div>
        </>
        );
    };

    onChangeSimpleChatPagination = (e, {activePage: pageNum}) => {
        const {
            pageNum: pageNumOld,
            pageSize,
            chatId,
        } = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchChatList);

        const chatIdParam = chatId ? chatId : "all";

        if (this.clientId) {
            history.push(LINK_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
                .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatIdParam)
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId)
                .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId),
            );

            return;
        }

        history.push(LINK_ALL_ROLES_CHAT_LIST
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatIdParam)
            .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, this.orderId),
        );
    };

    renderSimpleChatList() {
        const {
            pageNum,
        } = this.state;

        const {
            chatListTotalPages,
        } = this.props;

        return (
            <>
                <div className="chat-list__content">
                    {this.mapChatListData()}
                </div>
                <div className="chat-list__pagination flex flex-content-center">
                    <NmPagination
                        pageNum={pageNum}
                        totalPages={chatListTotalPages}
                        onChangePagination={this.onChangeSimpleChatPagination}
                    />
                </div>
            </>
        );
    };

    renderDisputeChatList() {
        const {
            pageNum,
        } = this.state;

        const {
            totalPages,
        } = this.props;

        return (
            <>
                {this.renderTabs()}
                <div className="chat-list__content">
                    {this.mapData()}
                </div>
                <div className="chat-list__pagination flex flex-content-center">
                    <NmPagination
                        pageNum={pageNum}
                        totalPages={totalPages}
                        onChangePagination={this.handlePaginationChange}
                    />
                </div>
            </>
        );
    }

    renderChatListHeader() {
        const {
            order,
            t,
        } = this.props;

        return (
            <div className="chat-list__header">
                <ButtonBack
                    className="me-2 me-md-3"
                    onClick={this.handleOnClickBack}
                />
                <Text.Title level="1">
                    {this.isSimpleChat ? this.renderSimpleChatListCaption(order) : t("chat-list.chat-list-title")}
                </Text.Title>
            </div>
        );
    }

    get chatContractorFullName() {
        if (this.isSimpleChat) {
            const {chatList} = this.props;
            const {chatId} = this.state;

            const {contractorLastName, contractorFirstName, contractorPatronymic} = chatList.find(value => (value.chatId === chatId))|| {};

            return getFullName(contractorLastName, contractorFirstName, contractorPatronymic);
        }

        const {disputesList} = this.props;
        const {disputeId} = this.state;

        const {contractorLastName, contractorFirstName, contractorPatronymic} = disputesList.find(value => (value.disputeId === disputeId))|| {};

        return  getFullName(contractorLastName, contractorFirstName, contractorPatronymic);
    }

    renderChat() {
        const {
            order,
        } = this.props;

        const {
            status,
        } = order;

        const {
            disputeId,
            chatId,
        } = this.state;

        return disputeId || chatId ?
            <Chat
                onClickBackToChatList={() => {
                    this.setState({
                        disputeId: "",
                        chatId: "",
                    });
                }}
                fullName={this.chatContractorFullName}
                orderStatus={status}
                disputeId={disputeId}
                chatId={chatId}
                orderId={this.orderId}
                orderManagerId={order[ORDER_FIELDS_NAME.RESPONSIBLE]}
            /> :
            <div className="chat-list_empty" />;
    }

    renderPreloader() {
        const {
            isLoading,
            isChatLoading,
            t,
        } = this.props;

        return (
            <>
                {isLoading || isChatLoading ?
                    <Dimmer
                        active
                        inverted
                    >
                        <Loader content={t("loader.content")}>
                        </Loader>
                    </Dimmer> : ""
                }
            </>
        );
    }

    render() {
        const {chatId, disputeId} = this.state;

        const isOpenChat = Boolean(chatId || disputeId);

        return (
            <div className="chat-list">
                <Media query={{maxWidth: 767}}>
                    {
                        mobile => {
                            return (
                                <>
                                    {
                                        mobile && isOpenChat ?
                                            null :
                                            <div className="chat-list__values">
                                                {
                                                    this.renderPreloader()
                                                }
                                                {
                                                    this.renderChatListHeader()
                                                }
                                                {
                                                    this.isSimpleChat ? this.renderSimpleChatList() : this.renderDisputeChatList()
                                                }
                                            </div>
                                    }
                                    {
                                        mobile && !isOpenChat ?
                                            null :
                                            this.renderChat()
                                    }
                                </>
                            );
                        }
                    }
                </Media>
            </div>
        );
    }
}

export default connect(
    state => ({
        location: state.router.location,
        disputesList: disputesChatListSelector(state),
        totalPages: disputeTotalPagesSelector(state),
        totalCount: disputeTotalCountForOrderCardSelector(state),
        token: disputeFirebaseTokenSelector(state),
        dispute: disputeSelector(state),
        isLoading: disputeListLoadingSelector(state),
        isChatLoading: chatLoadingSelector(state),
        totalCountOpen: disputesChatsTotalCountSelector(state),
        chatList: chatListAllSelector(state),
        backLink: financeBackLinkSelector(state),
        chatListTotalPages: chatListTotalPagesSelector(state),
        isChatLockedSuccess: chatIsLockedSuccessSelector(state),
        order: getOrderCardSelector(state),
        progressOrderCard: getProgressOrderCardSelector(state),
    }),
    {
        getChatList,
        updateFieldDisputeStore,
        addFireBaseToken,
        initFirebase,
        getMessageList,
        getDisputeMessageList,
        updateFinanceStore,
        getSimpleChatList,
        getOrderById,
        updateFieldOrderStore,
        updateFieldChatListStore,
    },
)(withTranslation()(ChatList));
