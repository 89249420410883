import React from "react";
import {useTranslation} from "react-i18next";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import {DEPOSIT_SUB_PAGE} from "../../../deposit/client-list";
import PatentsRoute from "./Route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST} from "../../../../constants/links";
import {
    ADMIN, NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR, NM_MANAGER} from "../../../../constants/roles";

import "./style.sass";

const ClientCardPatents = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;
    
    const {t} = useTranslation();
    const role = ls(USER_ROLE);
    
    const getLink = () => {
        const paymentPatentsList = LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST.replace(":clientId", clientId);
        const depositsPatentsList = LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);
        const documentPatentsList = LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST.replace(":clientId", clientId);
        const exportsPatentsList = LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST.replace(":clientId", clientId);
        
        return {
            paymentPatentsList,
            depositsPatentsList,
            documentPatentsList,
            exportsPatentsList,
        };
    };
    
    const getTabLinks = () => {
        const {
            paymentPatentsList,
            depositsPatentsList,
            documentPatentsList,
            exportsPatentsList,
        } = getLink();
        
        return [
            {
                active: paymentPatentsList,
                link: paymentPatentsList,
                name: t("patents.payments"),
            },
            {
                active: [
                    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS).replace(":subTab", DEPOSIT_SUB_PAGE.PATENTS),
                    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS).replace(":subTab", DEPOSIT_SUB_PAGE.PATENTS),
                ],
                link: depositsPatentsList,
                name: t("patents.deposit"),
            },
            {
                active: documentPatentsList,
                link: documentPatentsList,
                name: t("patents.documents"),
            },
            {
                active: exportsPatentsList,
                link: exportsPatentsList,
                name: t("patents.export"),
            },
        ];
    };
    
    return (
        <div className="client-card-patents">
            {
                ([ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) &&
                <>
                    <NmTitle size="xl">
                        {t("patents.title")}
                    </NmTitle>
                    <Tabs
                        {...props}
                        className="client-card-documents__tabs"
                        panes={getTabLinks()}
                        secondary
                    />
                </>
            }
            <PatentsRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardPatents;
