export const CLIENT_BRANDS_UPDATE_REQUEST = "CLIENT_BRANDS_UPDATE_REQUEST";
export const CLIENT_BRANDS_UPDATE_SUCCESS = "CLIENT_BRANDS_UPDATE_SUCCESS";
export const CLIENT_BRANDS_UPDATE_ERROR = "CLIENT_BRANDS_UPDATE_ERROR";

export const CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST = "CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST";
export const CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_SUCCESS = "CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_SUCCESS";
export const CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR = "CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_ERROR";

export const CLIENT_BRANDS_GET_PAGE_REQUEST = "CLIENT_BRANDS_GET_PAGE_REQUEST";
export const CLIENT_BRANDS_GET_PAGE_SUCCESS = "CLIENT_BRANDS_GET_PAGE_SUCCESS";
export const CLIENT_BRANDS_GET_PAGE_ERROR = "CLIENT_BRANDS_GET_PAGE_ERROR";

export const CLIENT_BRANDS_DELETE_LOGO_REQUEST = "CLIENT_BRANDS_DELETE_LOGO_REQUEST";
export const CLIENT_BRANDS_DELETE_LOGO_SUCCESS = "CLIENT_BRANDS_DELETE_LOGO_SUCCESS";
export const CLIENT_BRANDS_DELETE_LOGO_ERROR = "CLIENT_BRANDS_DELETE_LOGO_ERROR";

export const CLIENT_BRANDS_ADD_REQUEST = "CLIENT_BRANDS_ADD_REQUEST";
export const CLIENT_BRANDS_ADD_SUCCESS = "CLIENT_BRANDS_ADD_SUCCESS";
export const CLIENT_BRANDS_ADD_ERROR = "CLIENT_BRANDS_ADD_ERROR";

export const CLIENT_BRANDS_ADD_LOGO_REQUEST = "CLIENT_BRANDS_ADD_LOGO_REQUEST";
export const CLIENT_BRANDS_ADD_LOGO_SUCCESS = "CLIENT_BRANDS_ADD_LOGO_SUCCESS";
export const CLIENT_BRANDS_ADD_LOGO_ERROR = "CLIENT_BRANDS_ADD_LOGO_ERROR";

export const CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST = "CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST";
export const CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_SUCCESS = "CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_SUCCESS";
export const CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR = "CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_ERROR";

export const CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST = "CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST";
export const CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_SUCCESS = "CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_SUCCESS";
export const CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR = "CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_ERROR";

export const CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST = "CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST";
export const CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_SUCCESS = "CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_SUCCESS";
export const CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR = "CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_ERROR";

export const CLIENT_BRANDS_GET_LOGS_REQUEST = "CLIENT_BRANDS_GET_LOGS_REQUEST";
export const CLIENT_BRANDS_GET_LOGS_SUCCESS = "CLIENT_BRANDS_GET_LOGS_SUCCESS";
export const CLIENT_BRANDS_GET_LOGS_ERROR = "CLIENT_BRANDS_GET_LOGS_ERROR";

export const CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST = "CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST";
export const CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_SUCCESS = "CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_SUCCESS";
export const CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR = "CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_ERROR";

export const CLIENT_BRANDS_UPDATE_STORE = "CLIENT_BRANDS_UPDATE_STORE";