import React from "react";

import bem from "../../../utils/bem";

import "./style.sass";

//вынес в компонент ошибку для поля формы(сейчас реализовано как в семантике). В дальнейшем стилизуем как утвердят и будем использовать.
export type ErrorTooltipType = {
    error: Array<string> | string
    className?: string,
    openNaimix?: boolean,
    promoPoisk?: boolean,
    warning?: Array<string> | string
}

const ErrorTooltip = (props: ErrorTooltipType) => {
    const {
        error,
        openNaimix,
        promoPoisk,
        className,
        warning,
    } = props;
    const [block] = bem("error-tooltip", className);

    return (
        <div
            className={block({
                warning: error ? false : Boolean(warning),
                openNaimix,
                promoPoisk,
            })}
        >
            {error || warning}
        </div>
    );
};

export default ErrorTooltip;