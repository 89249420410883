import {
    cancelKedoExportById,
    getKedoExportsPage,
} from "../../../../kedo/exports/actionCreators";

const controller = "/adm/clients/kedo/exports";

export const getClientsKedoExportsPage = (payload) => {
    return getKedoExportsPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const cancelClientsKedoExportById = (payload) => {
    return cancelKedoExportById({
        url: `${controller}/cancelById`,
        ...payload,
    });
};