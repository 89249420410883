import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import "./style.sass";

class InsuranceHistoryInfo extends Component {
    render() {
        const {
            reserveAmount,
            formatAmount,
            t,
        } = this.props;

        return (
            <div className="insurance-history-info">
                <div className="insurance-history-info__item">
                    <div className="insurance-history-info__label">
                        {t("client-insurance.info-reserve-amount")}
                    </div>
                    <div className="insurance-history-info__value">
                        {formatAmount(reserveAmount)}
                    </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(InsuranceHistoryInfo);