import {useState} from "react";

export const useClientListSettings = () => {
    const [settingsData, setSettingsData] = useState(null);

    const onOpenClientSettings = ({clientId, name}) => {
        setSettingsData({
            clientId,
            name,
        });
    };

    const onCloseClientSettings = () => {
        setSettingsData(null);
    };

    return {
        settingsData,
        onOpenClientSettings,
        onCloseClientSettings,
    };
};