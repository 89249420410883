export const CONTRACTOR_BANK_IDENTIFICATION_STATUS = {
    NOT_IDENTIFIED: {
        VALUE: "NOT_IDENTIFIED",
        TEXT: "Не идентифицированный",
        MOD: "gray",
    },
    SIMPLIFIED_IDENTIFIED: {
        VALUE: "SIMPLIFIED_IDENTIFIED",
        TEXT: "Упрощенно идентифицированный",
        MOD: "light-green",
    },
    IDENTIFIED: {
        VALUE: "IDENTIFIED",
        TEXT: "Идентифицированный",
        MOD: "green",
    },
};

export const CONTRACTOR_BANK_STATUS_DICT = {
    CREATED: "Исполнитель создан в банке",
    ACTIVATING: "Выполняется проверка исполнителя",
    ACTIVATED: "Исполнитель прошел проверку",
    BLOCKED: "Исполнитель заблокирован в банке",
};