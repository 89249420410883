import {useState} from "react";
import {isEmpty} from "lodash";

import {useSaveScrollNavigation} from "./useSaveScrollNavigation";

export function useFilter(params) {
    const {
        initFilter = {},
        setPagination,
        onPaginationChange,
        pageSize,
        mapFilterDto,
    } = params;

    const {
        state,
    } = useSaveScrollNavigation();

    const [filter, setFilter] = useState(initFilter);
    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState(state.filterData || {});

    const handleChangeFilter = (event, {name, value, checked}) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: checked !== undefined ? checked : value,
        }));
    };

    const onSearch = (submittedFilterData) => {
        if (isEmpty(submittedFilterData)) {
            return;
        }

        const filterData = mapFilterDto ? mapFilterDto(submittedFilterData) : submittedFilterData;

        setFilterData(filterData);

        if (params.setFilterData) {
            params.setFilterData(filterData);
        }

        setIsSearch(true);

        if (params.setIsSearch) {
            params.setIsSearch(true);
        }

        if (onPaginationChange) {
            onPaginationChange(null, {activePage: 1});
        }

        if (setPagination) {
            setPagination({
                pageSize,
                pageNum: 1,
            });
        }
    };

    const onClear = () => {
        if (params.setFilterData) {
            params.setFilterData({});
        }

        setFilterData({});

        setIsSearch(false);

        if (params.setIsSearch) {
            params.setIsSearch(false);
        }

        setFilter(initFilter);
    };

    return {
        isSearch,
        setIsSearch,
        filterData,
        onClear,
        onSearch,
        filter,
        onChangeFilter: handleChangeFilter,
        setFilter,
    };
}