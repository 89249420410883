import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getAdditionalAgreements} from "./actionCreators";
import {
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST,
} from "./actions";
import {bffAdditionalAgreementsPageDataSelector} from "./selectors";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

export const controller = "/adm/clients/client-card/documents/additional-agreement-registries";

// POST /bff/adm/clients/client-card/documents/additional-agreement-registries/page
// Получить страницу реестров
const getAdditionalAgreementsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/documents/additional-agreement-registries/status/dict
// Словарь статуса реестра
const getAdditionalAgreementStatusDict = function* () {
    try {
        const result = yield request.bff.get(`${controller}/status/dict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/documents/additional-agreement-registries/submit
// Передать на подписание (список)
const submitAdditionalAgreements = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/submit`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = bffAdditionalAgreementsPageDataSelector(state);

        yield put(getAdditionalAgreements(pageData));
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/documents/additional-agreement-registries/add
// Добавить реестр
const addAdditionalAgreement = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const pageData = bffAdditionalAgreementsPageDataSelector(state);

        yield put(getAdditionalAgreements(pageData));

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/additional-agreement-registries/update
// Отредактировать реестр
const updateAdditionalAgreement = function* ({payload}) {
    try {
        const result = yield request.bff.put(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const pageData = bffAdditionalAgreementsPageDataSelector(state);

        yield put(getAdditionalAgreements(pageData));

        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST, getAdditionalAgreementsSaga),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST, submitAdditionalAgreements),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_REQUEST, getAdditionalAgreementStatusDict),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST, addAdditionalAgreement),
        takeEvery(BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST, updateAdditionalAgreement),
    ]);
}