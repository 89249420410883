import {TCheck} from "../index";

type TCheckContractor = {
    contractorId: string,
    contractorsPossibility: Array<TCheck>
}

/***
 * Обрабатывает результаты проверки возможности оплаты СЗ, возвращает данные для дальнейшего отображения
 * @param contractorId - СЗ
 * @param checksList - список проверок возможности оплаты
 */
export const checkContractorPaymentPossibility = ({contractorId, contractorsPossibility}: TCheckContractor) => {
    const {
        result,
        checkPlanned = true,
        resultReasons = [],
    } = contractorsPossibility.find(item => item.contractorId === contractorId) || {};

    if (!result) {
        return {
            isVisibleIndicator: false,
            checkPlanned,
            result,
            resultReasons,
        };
    }

    return {
        isVisibleIndicator: true,
        checkPlanned,
        result,
        resultReasons,
    };
};