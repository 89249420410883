import React from "react";

import TemplatesContracts from "../contracts";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

const TemplatesActsOrders = (props) => {
    return (
        <TemplatesContracts
            headerText="Акты по заказам"
            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
            {...props}
        />
    );
};

export default TemplatesActsOrders;