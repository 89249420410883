import React, {useState} from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import NmLoader from "../NmLoader";

import {useDetectPdfWidth} from "./hooks/useDetectPdfWidth";

import bem from "../../utils/bem";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./style.sass";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default (props) => {
    const {file, className = ""} = props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [block, element] = bem("pdf-viewer", className);
    const {
        width,
    } = useDetectPdfWidth();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className={block()}>
            <Document
                file={file}
                className={element("document")}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                    <NmLoader
                        active
                        inverted
                        content="Загрузка PDF документа"
                    />
                }
            >
                <Page
                    renderAnnotationLayer={false}
                    width={width}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                />
            </Document>
            <div className={element("container-navigation")}>
                <div className={element("actions-page")}>
                    <button
                        className={element("navigation-button", {"disabled": (pageNumber <= 1)})}
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        <i className={`material-icons ${element("icon")}`}>
chevron_left
                        </i>
                    </button>
                    <div>
                        Страница 
                        {" "}
                        {pageNumber || (numPages ? 1 : "--")}
                        {" "}
из 
                        {" "}
                        {numPages || "--"}
                    </div>
                    <button
                        className={element("navigation-button", {"disabled": (pageNumber >= numPages)})}
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        <i className={`material-icons ${element("icon")}`}>
chevron_right
                        </i>
                    </button>
                </div>

            </div>
        </div>
    );
};