import React, {Component} from "react";

import "./style.sass";

class TableSelectedLabel extends Component {
    get classNames() {
        const {count} = this.props;

        if (count > 0) {
            return "table-selected-label__count table-selected-label__count_active";
        }

        return "table-selected-label__count table-selected-label__count_no-active";
    }

    render() {
        const {className, count = 0} = this.props;

        return (
            <div className={`table-selected-label ${className}`}>
                <span className="table-selected-label__text">
Выбрано:
                </span>
                <div className={this.classNames}>
                    {count}
                </div>
            </div>
        );
    }
}

export default TableSelectedLabel;