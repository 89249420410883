import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getPositionsPage, updateCommonPaymentDicts} from "../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsPositionsOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";

interface UsePositionsParams {
    clientId: string,
    searchQuery?: string,
}

export const usePositions = (params: UsePositionsParams) => {
    const {
        clientId,
        searchQuery,
    } = params;

    const dispatch = useDispatch();
    const options = useSelector(bffCommonDictsPositionsOptionsSelector);

    useEffect(() => {
        if (searchQuery) {
            fetchList(searchQuery);
        }
    }, [searchQuery]);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                positions: [],
            }));
        };
    }, []);

    const fetchList = (nameFilter?: string) => {
        dispatch(getPositionsPage({
            clientIdFilter: clientId,
            pageNum: 1,
            pageSize: 25,
            nameFilter: nameFilter ? nameFilter : undefined,
        }));
    };

    const onSearchChange = (nameFilter: string) => {
        fetchList(nameFilter);
    };

    return {
        onSearchChange,
        options,
        label: "Должность",
        placeholder: "Выберите должность",
    };
};
