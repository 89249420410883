import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ActRegistryLog from "../../../../components/ActRegistryLog";

import {
    getRegistryPatentsLog,
    registryPatentsLogsListSelector,
    registryPatentsLogTotalCountSelector,
    registryPatentsLogTotalPagesSelector, updateRegistryPatentsStore,
} from "../../../../ducks/registryPatents";

function PatentsListLog(props) {
    const dispatch = useDispatch();

    const list = useSelector(registryPatentsLogsListSelector);
    const totalPages = useSelector(registryPatentsLogTotalPagesSelector);
    const totalCount = useSelector(registryPatentsLogTotalCountSelector);

    return (
        <ActRegistryLog
            {...props}
            isRegistryPatents
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            onClearStore={() => {
                dispatch(updateRegistryPatentsStore({
                    list: [],
                    totalCount: 0,
                    pageData: {
                        pageSize: 25,
                        pageNum: 1,
                    },
                }));
            }}
            onFetchList={(data) => {
                dispatch(getRegistryPatentsLog(data));
            }}
        />
    );
}

export default PatentsListLog;