export const PATENTS_PAYMENTS_STATUS_CODE = {
    PROCESSING: "PROCESSING",
    DEPOSITED: "DEPOSITED",
    DECLINED:"DECLINED",
    ERROR: "ERROR",
    PAYMENT_ERROR: "PAYMENT_ERROR",
};
export const FINANCE_PATENTS_PAYMENTS_STATUS_COLOR_TRANSCRIPT = {
    PROCESSING: "#555555",
    DEPOSITED: "#128A10",
    ERROR: "#EE1616",
    PAYMENT_ERROR: "#EE1616",
    DECLINED:"#EE1616",
};

export const FINANCE_PATENTS_PAYMENTS_STATUS_DICT = {
    PROCESSING: "В процессе",
    DEPOSITED: "Оплачено",
    PAYMENT_ERROR: "Ошибка платежа",
    DECLINED:"Отклонено",
};

export const FINANCE_PATENTS_PAYMENTS_STATUS_MOD = {
    PROCESSING: "gray",
    DEPOSITED: "green",
    ERROR: "red",
    PAYMENT_ERROR: "red",
    DECLINED:"red",
};

export const PATENTS_PAYMENTS_REASON_DICT_DESCRIPTION = {
    ADVANCE_REQUEST: "Заявление на аванс",
    LOAN_AGREEMENT: "Договор займа",
};

export const PATENTS_PAYMENTS_REASON_DICT = {
    ADVANCE_REQUEST: "ADVANCE_REQUEST",
    LOAN_AGREEMENT: "LOAN_AGREEMENT",
};

export const PATENTS_PAYMENTS_ITEM_ACTION = {
    EDIT: "EDIT",
    PAYMENT_ORDER: "PAYMENT_ORDER",
};

export const PATENTS_PAYMENTS_ACTION = {
    TRANSFER_TO_PAY: "TRANSFER_TO_PAY",
    REPEAT_TRANSFER_TO_PAY: "REPEAT_TRANSFER_TO_PAY",
    CANCEL_DECLINED: "CANCEL_DECLINED",
    DELETE_DUPLICATES_ITEMS: "DELETE_DUPLICATES_ITEMS",
};

export const FINANCE_PATENTS_PAYMENTS_ACTION = {
    PAYMENT_ORDER: "PAYMENT_ORDER",
    REFRESH_STATUS: "REFRESH_STATUS",
    LOG_BANK_INTERACTION: "LOG_BANK_INTERACTION",
    CANCEL_PAYMENT: "CANCEL_PAYMENT",
};

export const DEPOSIT_FINANCE_PATENTS_PAYMENTS_STATUS_FILTER_OPTIONS = [
    {
        key: PATENTS_PAYMENTS_STATUS_CODE.PROCESSING,
        text: "В процессе",
        value: PATENTS_PAYMENTS_STATUS_CODE.PROCESSING,
    },
    {
        key: PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR,
        text: "Ошибка платежа",
        value: PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR,
    },
];