import {
    BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_UPDATE_STORE} from "./actions";

export const getPageRecruitmentDirectoryStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addRecruitmentDirectoryStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST,
        payload,
    };
};

export const updateRecruitmentDirectoryStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentDirectoryStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST,
        payload,
    };
};

export const getPageRecruitmentDirectoryJobRequirement = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST,
        payload,
    };
};

export const addRecruitmentDirectoryJobRequirement = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST,
        payload,
    };
};

export const updateRecruitmentDirectoryJobRequirement = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentDirectoryJobRequirement = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST,
        payload,
    };
};

export const getPageRecruitmentDirectoryFunnel = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST,
        payload,
    };
};

export const addRecruitmentDirectoryFunnel = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST,
        payload,
    };
};

export const updateRecruitmentDirectoryFunnel = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentDirectoryFunnel = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST,
        payload,
    };
};

export const getCountsRecruitmentDirectory = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST,
        payload,
    };
};

export const getAvitoProfessions = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST,
        payload,
    };
};

export const getAvitoSchedules = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST,
        payload,
    };
};

export const getAvitoExperiences = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST,
        payload,
    };
};

export const getAvitoBillingTypes = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST,
        payload,
    };
};

export const getAvitoBusinessAreas = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST,
        payload,
    };
};
export const getAvitoActualBusinessAreas = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST,
        payload,
    };
};

export const searchRecruitmentRejectionReason = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST,
        payload,
    };
};

export const addRecruitmentRejectionReason = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST,
        payload,
    };
};

export const editRecruitmentRejectionReason = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST,
        payload,
    };
};

export const deleteRecruitmentRejectionReason = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST,
        payload,
    };
};

export const updateRecruitmentDirectoryStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_DIRECTORY_UPDATE_STORE,
        payload,
    };
};

export const getHHVacancyBrandedTemplates = (payload) => {
    return {
        type: BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_REQUEST,
        payload,
    };
};