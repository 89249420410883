import {
    addKedoDirectorySubdivision,
    deleteKedoDirectorySubdivision,
    getKedoDirectorySubdivisionsPage,
    updateKedoDirectorySubdivision,
} from "../../../../../../kedo/directories/actionCreators";

const controller = "/adm/settings/directories/kedo/subdivisions";

export const getSettingsKedoSubdivisionsPage = (payload) => {
    return getKedoDirectorySubdivisionsPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const updateSettingsKedoSubdivision = (payload) => {
    return updateKedoDirectorySubdivision({
        url: `${controller}/update`,
        ...payload,
    });
};

export const addSettingsKedoSubdivision = (payload) => {
    return addKedoDirectorySubdivision({
        url: `${controller}/add`,
        ...payload,
    });
};

export const deleteSettingsKedoSubdivision = (payload) => {
    return deleteKedoDirectorySubdivision({
        url: `${controller}/delete`,
        ...payload,
    });
};