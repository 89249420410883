import React from "react";

import NmButton from "../../components/NmButton";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmButtonContact = (props) => {
    const {
        onClick,
        popup,
        className,
        icon,
        style = {},
        position,
        size = "xl",
        isLink,
        href,
        showIndicator,
    } = props;
    const [block, element] = bem("nm-button-contact", className);

    return (
        <NmAdvancedTooltip
            hover
            position={position}
            className={block()}
            children={popup}
            trigger={
                <div>
                    {showIndicator && <div className={element("indicator")} />}
                    <NmButton
                        onlyIcon
                        style={style}
                        icon={icon}
                        color="grey"
                        size={size}
                        type="button"
                        onClick={onClick}
                        isLink={isLink}
                        href={href}
                    />
                </div>
            }
        />
    );
};

NmButtonContact.propTypes = {
    onClick: PropTypes.func.isRequired,
    popup: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    style: PropTypes.object,
    className: PropTypes.string,
};

export default NmButtonContact;