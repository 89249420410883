import React from "react";

import OrderEdit from "../../../order-edit";

import {useOrderTemplatesEditActTemplateForm} from "../edit-act-template/hooks/useForm";

import {ORDER_CREATION_MODE} from "../../../../../constants/order";

const OrderTemplatesEdit = (props) => {
    const {
        objectId,
        projectId,
        clientId,
        handleClose,
        editActTemplateData,
        fetchList,
    } = props;

    const {
        form,
        onChange,
        onSubmit,
    } = useOrderTemplatesEditActTemplateForm({
        clientId,
        editActTemplateData,
        fetchList,
    });

    return (
        <OrderEdit
            creatingMode={ORDER_CREATION_MODE.TEMPLATE}
            handleClose={handleClose}
            objectId={objectId}
            projectId={projectId}
            clientId={clientId}
            orderStatus={editActTemplateData.status}
            editOrderId={editActTemplateData.orderId}
            disabledOfferedSpeciality
            fetchList={fetchList}
            onSubmitActCreation={onSubmit}
            onChangeActCreation={onChange}
            actCreationForm={form}
        />
    );
};

export default OrderTemplatesEdit;