import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getDadataAddressObj} from "../utils/dadata";
import {blobToFile, downloadLocalFile} from "../utils/downloadBlob";
import {dictionaryToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";

const controller = "/contractors/";
//*  TYPES  *//

const GET_CONTRACTOR_PROFILE_REQUEST = "GET_CONTRACTOR_PROFILE_REQUEST";
const GET_CONTRACTOR_PROFILE_SUCCESS = "GET_CONTRACTOR_PROFILE_SUCCESS";
const GET_CONTRACTOR_PROFILE_ERROR = "GET_CONTRACTOR_PROFILE_ERROR";

const ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
const ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
const ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR = "ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR";

const UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
const UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
const UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR = "UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR";

const DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
const DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
const DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR = "DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR";

const ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST = "ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST";
const ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS = "ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS";
const ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR = "ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR";

const GET_EDUCATION_DICT_REQUEST = "GET_EDUCATION_DICT_REQUEST";
const GET_EDUCATION_DICT_SUCCESS = "GET_EDUCATION_DICT_SUCCESS";
const GET_EDUCATION_DICT_ERROR = "GET_EDUCATION_DICT_ERROR";

const GET_EDUCATION_LEVEL_DICT_REQUEST = "GET_EDUCATION_LEVEL_DICT_REQUEST";
const GET_EDUCATION_LEVEL_DICT_SUCCESS = "GET_EDUCATION_LEVEL_DICT_SUCCESS";
const GET_EDUCATION_LEVEL_DICT_ERROR = "GET_EDUCATION_LEVEL_DICT_ERROR";

const ADD_CONTRACTOR_EDUCATION_REQUEST = "ADD_CONTRACTOR_EDUCATION_REQUEST";
const ADD_CONTRACTOR_EDUCATION_SUCCESS = "ADD_CONTRACTOR_EDUCATION_SUCCESS";
const ADD_CONTRACTOR_EDUCATION_ERROR = "ADD_CONTRACTOR_EDUCATION_ERROR";

const UPDATE_CONTRACTOR_EDUCATION_REQUEST = "UPDATE_CONTRACTOR_EDUCATION_REQUEST";
const UPDATE_CONTRACTOR_EDUCATION_SUCCESS = "UPDATE_CONTRACTOR_EDUCATION_SUCCESS";
const UPDATE_CONTRACTOR_EDUCATION_ERROR = "UPDATE_CONTRACTOR_EDUCATION_ERROR";

const DELETE_CONTRACTOR_EDUCATION_REQUEST = "DELETE_CONTRACTOR_EDUCATION_REQUEST";
const DELETE_CONTRACTOR_EDUCATION_SUCCESS = "DELETE_CONTRACTOR_EDUCATION_SUCCESS";
const DELETE_CONTRACTOR_EDUCATION_ERROR = "DELETE_CONTRACTOR_EDUCATION_ERROR";

const GET_DRIVER_LICENSE_DICTIONARY_REQUEST = "GET_DRIVER_LICENSE_DICTIONARY_REQUEST";
const GET_DRIVER_LICENSE_DICTIONARY_SUCCESS = "GET_DRIVER_LICENSE_DICTIONARY_SUCCESS";
const GET_DRIVER_LICENSE_DICTIONARY_ERROR = "GET_DRIVER_LICENSE_DICTIONARY_ERROR";

const UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST = "UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST";
const UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS = "UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS";
const UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR = "UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR";

const GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST = "GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST";
const GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS = "GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS";
const GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR = "GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR";

const GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_REQUEST = "GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_REQUEST";
const GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_SUCCESS = "GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_SUCCESS";
const GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_ERROR = "GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_ERROR";

const UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST = "UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST";
const UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS = "UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS";
const UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR = "UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR";

const DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST = "DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST";
const DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS = "DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS";
const DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR = "DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR";

const ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST = "ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST";
const ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS = "ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS";
const ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR = "ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR";

const UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST = "UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST";
const UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS = "UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS";
const UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR = "UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR";

const ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST = "ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST";
const ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS = "ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS";
const ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR = "ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR";

const DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST = "DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST";
const DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS = "DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS";
const DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR = "DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR";

const GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST = "GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST";
const GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS = "GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS";
const GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR = "GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR";

const UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST = "UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST";
const UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS = "UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS";
const UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR = "UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR";

const MERGE_CONTRACTOR_PROF_SKILLS_REQUEST = "MERGE_CONTRACTOR_PROF_SKILLS_REQUEST";
const MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS = "MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS";
const MERGE_CONTRACTOR_PROF_SKILLS_ERROR = "MERGE_CONTRACTOR_PROF_SKILLS_ERROR";

const ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST = "ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST";
const ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS = "ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS";
const ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR = "ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR";

const GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST = "GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST";
const GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS = "GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS";
const GET_CONTRACTOR_PORTFOLIO_FILE_ERROR = "GET_CONTRACTOR_PORTFOLIO_FILE_ERROR";

const DELETE_CONTRACTOR_PORTFOLIO_REQUEST = "DELETE_CONTRACTOR_PORTFOLIO_REQUEST";
const DELETE_CONTRACTOR_PORTFOLIO_SUCCESS = "DELETE_CONTRACTOR_PORTFOLIO_SUCCESS";
const DELETE_CONTRACTOR_PORTFOLIO_ERROR = "DELETE_CONTRACTOR_PORTFOLIO_ERROR";

const UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST = "UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST";
const UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS = "UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS";
const UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR = "UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR";

const UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST = "UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST";
const UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS = "UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS";
const UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR = "UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR";

const UPDATE_CONTRACTOR_MAIN_WORK_REQUEST = "UPDATE_CONTRACTOR_MAIN_WORK_REQUEST";
const UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS = "UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS";
const UPDATE_CONTRACTOR_MAIN_WORK_ERROR = "UPDATE_CONTRACTOR_MAIN_WORK_ERROR";

const UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST = "UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST";
const UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS = "UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS";
const UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR = "UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR";

const UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST = "UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST";
const UPDATE_CONTRACTOR_MAIN_WORK_CITY_SUCCESS = "UPDATE_CONTRACTOR_MAIN_WORK_ADDRESS_SUCCESS";
const UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR = "UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR";

const GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST = "GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST";
const GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS = "GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS";
const GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR = "GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR";

const UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_REQUEST = "UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_REQUEST";
const UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_SUCCESS = "UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_SUCCESS";
const UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_ERROR = "UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_ERROR";

//updateDepartureAddresses

const CLEAR_CONTRACTOR_PROFILE_STORE = "CLEAR_CONTRACTOR_PROFILE_STORE";
//updateAdditionalSpecialities
//*  INITIAL STATE  *//

const initial = {
    workExperiences: {
        workExperiences: [],
    },
    educationDict: {},
    educationLevelDict: {},
    driverLicenseDict: {},
    educations: [],
    drivingLicenseObj: {
        driverLicenses: [],
        ownCar: false,
    },
    contractorLanguagesDict: {},
    contractorLanguageLevelsDict: {},
    contractorLanguage: {
        foreignLanguagePresent: false,
        foreignLanguages: [],
    },
    additionalSpeciality: {
        additionalSpecialities: [],
        additionalSpecialityPresent: false,
    },
    contractorCourseCertificateList: [],
    familyStatus: null,
    keySkills: [],
    medicalBook: {},
    vaccinationCertificate: {},
    portfolioList: [],
    workContraindication: {},
    mainSpeciality: "",
    schedules: [],
    departureAddressesInfo: {},
    workAddressInfo: {},
    remoteWork: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CONTRACTOR_PROFILE_SUCCESS:
        return {
            ...state,
            remoteWork: payload.remoteWork,
            workExperiences: {
                workExperiences: payload.workExperiences || [],
                workExperiencePresent: payload.workExperiencePresent,
            },
            educations: payload.educations || [],
            drivingLicenseObj: {
                driverLicenses: payload.driverLicenses,
                ownCar: payload.ownCar,
                driverLicensePresent: payload.driverLicensePresent,
            },
            contractorLanguage: {
                foreignLanguagePresent: payload.foreignLanguagePresent,
                foreignLanguages: payload.foreignLanguages || [],
            },
            additionalSpeciality: {
                additionalSpecialities: payload.additionalSpecialities,
                additionalSpecialityPresent: payload.additionalSpecialityPresent,
            },
            contractorCourseCertificateList: payload.contractorCourseCertificateList || [],
            familyStatus: payload.familyStatus,
            keySkills: payload.keySkills,
            medicalBook: {
                medicalBookFileName: payload.medicalBookFileName,
                medicalBookFileType: payload.medicalBookFileType,
                medicalBookNumber: payload.medicalBookNumber,
                medicalBookPresent: payload.medicalBookPresent,
                medicalBookQRFileName: payload.medicalBookQRFileName,
                medicalBookQRFileType: payload.medicalBookQRFileType,
            },
            vaccinationCertificate: {
                vaccinationCertificateFileName: payload.vaccinationCertificateFileName,
                vaccinationCertificateFileType: payload.vaccinationCertificateFileType,
                vaccinationCertificatePresent: payload.vaccinationCertificatePresent,
            },
            portfolioList: payload.contractorPortfolioList || [],
            workContraindication: {
                workContraindications: payload.workContraindications,
                workContraindicationsPresent: payload.workContraindicationsPresent,
            },
            mainSpeciality: payload.mainSpeciality,
            schedules: payload.schedules || [],
            departureAddressesInfo: {
                departureAddressesInfo: payload.departureAddressesInfo,
                departureAddressesPresent: payload.departureAddressesPresent,
            },
            workAddressInfo: payload.workAddressInfo || {},
        };
    case GET_CONTRACTOR_PROFILE_ERROR:
    case GET_EDUCATION_DICT_ERROR:
    case GET_EDUCATION_LEVEL_DICT_ERROR:
        return {
            ...state,
            error: payload,
        };
    case GET_EDUCATION_DICT_SUCCESS:
        return {
            ...state,
            educationDict: payload,
        };
    case GET_DRIVER_LICENSE_DICTIONARY_SUCCESS:
        return {
            ...state,
            driverLicenseDict: payload,
        };
    case GET_EDUCATION_LEVEL_DICT_SUCCESS:
        return {
            ...state,
            educationLevelDict: payload,
        };
    case GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS:
        return {
            ...state,
            contractorLanguagesDict: payload,
        };
    case GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_SUCCESS:
        return {
            ...state,
            contractorLanguageLevelsDict: payload,
        };
    case CLEAR_CONTRACTOR_PROFILE_STORE:
        return {
            ...initial,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function clearContractorProfileStore() {
    return {
        type: CLEAR_CONTRACTOR_PROFILE_STORE,
    };
}

export function getContractorProfile(payload) {
    return {
        type: GET_CONTRACTOR_PROFILE_REQUEST,
        payload,
    };
}

export function getContractorLanguagesDict() {
    return {
        type: GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST,
    };
}

export function getContractorEducationDict() {
    return {
        type: GET_EDUCATION_DICT_REQUEST,
    };
}

export function addContractorWorkExperience(payload) {
    return {
        type: ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function updateContractorWorkExperience(payload) {
    return {
        type: UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function deleteAllContractorWorkExperience(payload) {
    return {
        type: ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function deleteContractorWorkExperience(payload) {
    return {
        type: DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
        payload,
    };
}

export function getContractorEducationLevelDict() {
    return {
        type: GET_EDUCATION_LEVEL_DICT_REQUEST,
    };
}

export function updateContractorEducation(payload) {
    return {
        type: UPDATE_CONTRACTOR_EDUCATION_REQUEST,
        payload,
    };
}

export function addContractorEducation(payload) {
    return {
        type: ADD_CONTRACTOR_EDUCATION_REQUEST,
        payload,
    };
}

export function deleteContractorEducation(payload) {
    return {
        type: DELETE_CONTRACTOR_EDUCATION_REQUEST,
        payload,
    };
}

export function getDriverLicenseDictionary() {
    return {
        type: GET_DRIVER_LICENSE_DICTIONARY_REQUEST,
    };
}

export function updateContractorDriverLicense(payload) {
    return {
        type: UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST,
        payload,
    };
}

export function getContractorLanguagesLevelDict() {
    return {
        type: GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_REQUEST,
    };
}

export function updateContractorForeignLanguage(payload) {
    return {
        type: UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
        payload,
    };
}

export function deleteContractorForeignLanguage(payload) {
    return {
        type: DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
        payload,
    };
}

export function addContractorCourseCertificate(payload) {
    return {
        type: ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
        payload,
    };
}

export function updateContractorCourseCertificate(payload) {
    return {
        type: UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
        payload,
    };
}

export function addContractorCourseCertificateFile(payload) {
    return {
        type: ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
        payload,
    };
}

export function deleteContractorCourseCertificate(payload) {
    return {
        type: DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
        payload,
    };
}

export function getContractorCourseCertificateFile(payload) {
    return {
        type: GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
        payload,
    };
}

export function updateContractorFamilyStatus(payload) {
    return {
        type: UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST,
        payload,
    };
}

export function mergeContractorProfessionalSkills(payload) {
    return {
        type: MERGE_CONTRACTOR_PROF_SKILLS_REQUEST,
        payload,
    };
}

export function addContractorPortfolioFile(payload) {
    return {
        type: ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
        payload,
    };
}

export function getContractorPortfolioFile(payload) {
    return {
        type: GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
        payload,
    };
}

export function deleteContractorPortfolio(payload) {
    return {
        type: DELETE_CONTRACTOR_PORTFOLIO_REQUEST,
        payload,
    };
}

export function updateContractorWorkContraindication(payload) {
    return {
        type: UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST,
        payload,
    };
}

export function updateContractorAdditionalSpecialities(payload) {
    return {
        type: UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST,
        payload,
    };
}

export function updateContractorMainSpeciality(payload) {
    return {
        type: UPDATE_CONTRACTOR_MAIN_WORK_REQUEST,
        payload,
    };
}

export function updateDepartureAddresses(payload) {
    return {
        type: UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST,
        payload,
    };
}

export function updateContractorMainWorkAddress(payload) {
    return {
        type: UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST,
        payload,
    };
}

export function getContractorProfileResumeFile(payload) {
    return {
        type: GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST,
        payload,
    };
}

export function updateContractorProfileMedicalBookPresent(payload) {
    return {
        type: UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

const contractorProfileSelector = state => state.contractorProfile;
export const contractorProfileWorkExperiencesSelector = createSelector(contractorProfileSelector, ({workExperiences}) => workExperiences);
export const contractorEducationsSelector = createSelector(contractorProfileSelector, ({educations}) => educations);
export const contractorEducationLevelDictSelector = createSelector(contractorProfileSelector, ({educationLevelDict}) => educationLevelDict);
export const contractorEducationLevelDictWithNoneSelector = createSelector(contractorProfileSelector, ({educationLevelDict}) => {
    return {
        NONE: "Нет",
        ...educationLevelDict,
    };
});
export const contractorEducationDictSelector = createSelector(contractorProfileSelector, ({educationDict}) => educationDict);
export const contractorEducationDictOptionsSelector = createSelector(contractorProfileSelector, ({educationDict}) => dictionaryToOptions(educationDict));
export const contractorEducationLevelDictOptionsSelector = createSelector(contractorProfileSelector, ({educationLevelDict}) => dictionaryToOptions(educationLevelDict));
export const contractorDrivingLicenseSelector = createSelector(contractorProfileSelector, ({drivingLicenseObj}) => drivingLicenseObj);
export const contractorDrivingLicenseDictOptionsSelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => dictionaryToOptions(driverLicenseDict));
export const contractorDrivingLicenseDictSelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => driverLicenseDict);
export const contractorDrivingLicenseDictWithKeySelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => {
    return Object.keys(driverLicenseDict).reduce((result, key) => {
        return {
            ...result,
            [key]: `${key}, ${driverLicenseDict[key]}`,
        };
    }, {});
});
export const contractorDrivingLicenseDictKeyDictSelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => {
    return Object.keys(driverLicenseDict).reduce((result, key) => {
        return {
            ...result,
            [key]: key,
        };
    }, {});
});
export const contractorLanguageLevelsDictSelector = createSelector(contractorProfileSelector, ({contractorLanguageLevelsDict}) => contractorLanguageLevelsDict);
export const contractorLanguagesDictSelector = createSelector(contractorProfileSelector, ({contractorLanguagesDict}) => contractorLanguagesDict);
export const contractorLanguageSelector = createSelector(contractorProfileSelector, ({contractorLanguage}) => contractorLanguage);
export const contractorLanguagesDictOptionsSelector = createSelector(contractorProfileSelector, ({contractorLanguagesDict}) => dictionaryToOptions(contractorLanguagesDict));
export const contractorLanguageLevelsDictOptionsSelector = createSelector(contractorProfileSelector, ({contractorLanguageLevelsDict}) => dictionaryToOptions(contractorLanguageLevelsDict));
export const contractorAdditionalSpecialitySelector = createSelector(contractorProfileSelector, ({additionalSpeciality}) => additionalSpeciality);
export const contractorCourseCertificateListSelector = createSelector(contractorProfileSelector, ({contractorCourseCertificateList}) => contractorCourseCertificateList);
export const contractorFamilyStatusSelector = createSelector(contractorProfileSelector, ({familyStatus}) => familyStatus);
export const contractorKeySkillsSelector = createSelector(contractorProfileSelector, ({keySkills}) => keySkills);
export const contractorMedicalBookSelector = createSelector(contractorProfileSelector, ({medicalBook}) => medicalBook);
export const contractorVaccinationCertificateSelector = createSelector(contractorProfileSelector, ({vaccinationCertificate}) => vaccinationCertificate);
export const contractorPortfolioListSelector = createSelector(contractorProfileSelector, ({portfolioList}) => portfolioList);
export const contractorWorkContraindicationSelector = createSelector(contractorProfileSelector, ({workContraindication}) => workContraindication);
export const contractorMainSpecialitySelector = createSelector(contractorProfileSelector, ({mainSpeciality}) => mainSpeciality);
export const contractorSchedulesSelector = createSelector(contractorProfileSelector, ({schedules}) => schedules);
export const contractorDepartureAddressesInfoSelector = createSelector(contractorProfileSelector, ({departureAddressesInfo}) => departureAddressesInfo);
export const contractorDepartureAddressesInfoListSelector = createSelector(contractorProfileSelector, ({departureAddressesInfo: {departureAddressesInfo}}) => departureAddressesInfo ? Object.values(departureAddressesInfo).map(item => getDadataAddressObj(item)) : null);
export const contractorWorkAddressInfoSelector = createSelector(contractorProfileSelector, ({workAddressInfo}) => getDadataAddressObj(workAddressInfo));
export const contractorRemoteWorkSelector = createSelector(contractorProfileSelector, ({remoteWork}) => remoteWork);
//*  SAGA  *//

export const getContractorProfileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;
        const result = yield request.get(`${controller}profile/get`, {params});

        yield getResult();

        yield put({type: GET_CONTRACTOR_PROFILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_PROFILE_ERROR, payload: error});
    }
};

//POST /api/contractors/work-experience/add
const addContractorWorkExperienceSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}work-experience/add`, data);

        yield getResult();

        yield put({type: ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR, payload: error});
    }
};

const updateContractorWorkExperienceSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.put(`${controller}work-experience/update`, data);

        yield getResult();

        yield put({type: UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR, payload: error});
    }
};

const deleteContractorWorkExperienceSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.delete(`${controller}work-experience/delete`, {data});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR, payload: error});
    }
};

const deleteAllContractorWorkExperienceSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;
        const result = yield request.delete(`${controller}work-experience/delete/all`, {params});

        yield getResult();

        yield put({
            type: ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/education/dict/educationForm
const getContractorEducationDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}education/dict/educationForm`);

        yield put({
            type: GET_EDUCATION_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_EDUCATION_DICT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/education/dict/educationLevel
const getContractorEducationLevelDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}education/dict/educationLevel`);


        yield put({
            type: GET_EDUCATION_LEVEL_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_EDUCATION_LEVEL_DICT_ERROR,
            payload: error,
        });
    }
};
//POST
// /api/contractors/education/add
const addContractorEducationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}education/add`, data);

        yield getResult();

        yield put({type: ADD_CONTRACTOR_EDUCATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_EDUCATION_ERROR, payload: error});
    }
};

const updateContractorEducationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.put(`${controller}education/update`, data);

        yield getResult();

        yield put({type: UPDATE_CONTRACTOR_EDUCATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_EDUCATION_ERROR, payload: error});
    }
};

//DELETE
// /api/contractors/education/delete
const deleteContractorEducationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.delete(`${controller}education/delete`, {data});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_EDUCATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_EDUCATION_ERROR, payload: error});
    }
};

//GET
// /api/contractors/getDriverLicenseDictionary
const getDriverLicenseDictionarySaga = function* () {
    try {
        const result = yield request.get(`${controller}getDriverLicenseDictionary`);

        yield put({type: GET_DRIVER_LICENSE_DICTIONARY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DRIVER_LICENSE_DICTIONARY_ERROR, payload: error});
    }
};

//POST
// /api/contractors/updateDriverLicenses
const updateContractorDriverLicenseSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateDriverLicenses`, data);

        yield getResult(result.errorMessage);

        yield put({type: UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR, payload: error});
    }
};

//GET
// /api/contractors/dicts/contractorLanguages
// contractorLanguages
const getContractorLanguagesDictionarySaga = function* () {
    try {
        const result = yield request.get(`${controller}dicts/contractorLanguages`);

        yield put({type: GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR, payload: error});
    }
};

//GET
// /api/contractors/dicts/contractorLanguageLevels
const getContractorLanguageLevelsDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}dicts/contractorLanguageLevels`);

        yield put({type: GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_ERROR, payload: error});
    }
};

//POST
///api/contractors/updateForeignLanguage

const updateContractorForeignLanguageSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateForeignLanguage`, data);

        yield getResult(result.errorMessage);

        yield put({type: UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR, payload: error});
    }
};

//POST
// /api/contractors/deleteForeignLanguage
// Удаление иностранных языков

const deleteContractorForeignLanguageSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}deleteForeignLanguage`, data);

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR, payload: error});
    }
};

//POST
///api/contractors/course/certificate/add
const addContractorCourseCertificateSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}course/certificate/add`, data);

        yield getResult(result);

        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR, payload: error});
    }
};

//PUT
// /api/contractors/course/certificate/update
const updateContractorCourseCertificateSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.put(`${controller}course/certificate/update`, data);

        yield getResult({
            ...result,
            courseCertificateId: data.courseCertificateId,
        });

        yield put({type: UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR, payload: error});
    }
};

// POST /api/filestore/contractorFiles/course/certificate/add

const addContractorCourseCertificateFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            formData,
        } = payload;
        const result = yield request.post("/filestore/contractorFiles/course/certificate/add", formData, {...getMultipartHeaders()});

        yield getResult(result.errorMessage);

        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR, payload: error});
    }
};

//DELETE
// /api/contractors/course/certificate/delete
const deleteContractorCourseCertificateSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.delete(`${controller}course/certificate/delete`, {data});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR, payload: error});
    }
};

//GET /api/filestore/contractorFiles/course/certificate/get
//Получение файла сертификата курса исполнителя
const getContractorCourseCertificateFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            fileName,
            certificateId,
            contractorId,
        } = payload;
        const result = yield request.getFile(`${window.location.origin}/api/filestore/contractorFiles/course/certificate/get?certificateId=${certificateId}&contractorId=${contractorId}`);

        const blob = yield result.blob();

        yield getResult(blobToFile(blob, fileName));

        yield put({type: GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR, payload: error});
    }
};


//POST
// /api/contractors/updateFamilyStatus
// Обновление семейного положения
const updateContractorFamilyStatusSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateFamilyStatus`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR, payload: error});
    }
};

//PUT
// /api/contractors/key/skills/merge
const mergeContractorProfessionalSkillsSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.put(`${controller}key/skills/merge`, data);

        yield getResult(result);

        yield put({type: MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: MERGE_CONTRACTOR_PROF_SKILLS_ERROR, payload: error});
    }
};

//POST /api/filestore/contractorFiles/portfolio/add
const addContractorPortfolioFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            formData,
        } = payload;
        const result = yield request.post("/filestore/contractorFiles/portfolio/add", formData, {...getMultipartHeaders()});

        yield getResult(result);

        yield put({type: ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR, payload: error});
    }
};

//GET /api/filestore/contractorFiles/portfolio/get
const getContractorPortfolioFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            fileName,
            portfolioId,
            contractorId,
        } = payload;
        const result = yield request.getFile(`${window.location.origin}/api/filestore/contractorFiles/portfolio/get?portfolioId=${portfolioId}&contractorId=${contractorId}`);

        const blob = yield result.blob();

        yield getResult(blobToFile(blob, fileName));

        yield put({type: GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_PORTFOLIO_FILE_ERROR, payload: error});
    }
};

//DELETE /api/filestore/contractorFiles/portfolio/delete
const deleteContractorPortfolioSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;
        const result = yield request.delete("/filestore/contractorFiles/portfolio/delete", {params});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_PORTFOLIO_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_PORTFOLIO_ERROR, payload: error});
    }
};

//POST
// /api/contractors/updateWorkContraindications

const updateContractorWorkContraindicationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateWorkContraindications`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR, payload: error});
    }
};

//POST
// /api/contractors/updateAdditionalSpecialities
const updateContractorAdditionalSpecialitiesSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateAdditionalSpecialities`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR, payload: error});
    }
};

//POST
// /api/contractors/updateMainSpeciality
const updateContractorMainSpecialitySaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateMainSpeciality`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_ERROR, payload: error});
    }
};

//POST
//api/contractors/updateWorkAddress
const updateContractorMainWorkAddressSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateWorkAddress`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_CITY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR, payload: error});
    }
};

//POST
// /api/contractors/updateDepartureAddresses
const updateDepartureAddressesSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.post(`${controller}updateDepartureAddresses`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR, payload: error});
    }
};

const getContractorProfileResumeFileSaga = function* ({payload}) {
    const {
        fileName,
        contractorId,
        isDownload = true,
        onSuccess,
    } = payload;

    try {
        const result = yield request.getFile(`${window.location.origin}/api/documents/getContractorResumeFile?contractorId=${contractorId}`);

        const blob = yield result.blob();

        if (isDownload) {
            yield downloadLocalFile(blob, fileName);
        }

        if (onSuccess) {
            onSuccess(blob);
        }

        yield put({type: GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR, payload: error});
    }
};

//PUT
// /api/contractors/profile/update/medicalBookPresent

const updateContractorProfileMedicalBookPresentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.put(`${controller}profile/update/medicalBookPresent`, data);

        yield put({type: UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CONTRACTOR_PROFILE_REQUEST, getContractorProfileSaga),
        takeEvery(ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST, addContractorWorkExperienceSaga),
        takeEvery(UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST, updateContractorWorkExperienceSaga),
        takeEvery(DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST, deleteContractorWorkExperienceSaga),
        takeEvery(ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST, deleteAllContractorWorkExperienceSaga),
        takeEvery(GET_EDUCATION_DICT_REQUEST, getContractorEducationDictSaga),
        takeEvery(GET_EDUCATION_LEVEL_DICT_REQUEST, getContractorEducationLevelDictSaga),
        takeEvery(ADD_CONTRACTOR_EDUCATION_REQUEST, addContractorEducationSaga),
        takeEvery(UPDATE_CONTRACTOR_EDUCATION_REQUEST, updateContractorEducationSaga),
        takeEvery(DELETE_CONTRACTOR_EDUCATION_REQUEST, deleteContractorEducationSaga),
        takeEvery(GET_DRIVER_LICENSE_DICTIONARY_REQUEST, getDriverLicenseDictionarySaga),
        takeEvery(UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST, updateContractorDriverLicenseSaga),
        takeEvery(GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST, getContractorLanguagesDictionarySaga),
        takeEvery(GET_CONTRACTOR_LANGUAGES_LEVEL_DICTIONARY_REQUEST, getContractorLanguageLevelsDictSaga),
        takeEvery(UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST, updateContractorForeignLanguageSaga),
        takeEvery(DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST, deleteContractorForeignLanguageSaga),
        takeEvery(ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST, addContractorCourseCertificateSaga),
        takeEvery(UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST, updateContractorCourseCertificateSaga),
        takeEvery(ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST, addContractorCourseCertificateFileSaga),
        takeEvery(DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST, deleteContractorCourseCertificateSaga),
        takeEvery(GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST, getContractorCourseCertificateFileSaga),
        takeEvery(UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST, updateContractorFamilyStatusSaga),
        takeEvery(MERGE_CONTRACTOR_PROF_SKILLS_REQUEST, mergeContractorProfessionalSkillsSaga),
        takeEvery(ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST, addContractorPortfolioFileSaga),
        takeEvery(GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST, getContractorPortfolioFileSaga),
        takeEvery(DELETE_CONTRACTOR_PORTFOLIO_REQUEST, deleteContractorPortfolioSaga),
        takeEvery(UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST, updateContractorWorkContraindicationSaga),
        takeEvery(UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST, updateContractorAdditionalSpecialitiesSaga),
        takeEvery(UPDATE_CONTRACTOR_MAIN_WORK_REQUEST, updateContractorMainSpecialitySaga),
        takeEvery(UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST, updateDepartureAddressesSaga),
        takeEvery(UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST, updateContractorMainWorkAddressSaga),
        takeEvery(GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST, getContractorProfileResumeFileSaga),
        takeEvery(UPDATE_CONTRACTOR_PROFILE_MEDICAL_BOOK_PRESENT_REQUEST, updateContractorProfileMedicalBookPresentSaga),
    ]);
}
