export const MAP_INVITE_MODAL_STEP = {
    GENERAL: {
        value: "GENERAL",
        name: "Основная информация",
    },
    CONTRACTORS: {
        value: "CONTRACTORS",
        name: "Приглашение исполнителей",
    },
    ORDERS_OR_TASKS: {
        value: "ORDERS_OR_TASKS",
        name: "Выбор заказов/заданий",
    },
};

export const MAP_INVITE_MATCHING_TYPE = {
    CONTRACTOR_ON_ORDERS: "CONTRACTOR_ON_ORDERS",
    CONTRACTOR_ON_CROWD_TASKS: "CONTRACTOR_ON_CROWD_TASKS",
    ORDER_FOR_CONTRACTORS: "ORDER_FOR_CONTRACTORS",
    CROWD_TASK_FOR_CONTRACTORS: "CROWD_TASK_FOR_CONTRACTORS",
};