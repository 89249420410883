import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {getEditActTemplateDataFromCard, getEditActTemplateRequestData} from "../utils/dto";
import {isTemplateOrderActEmpty} from "../utils/isActEmpty";

import {ORDER_TEMPLATES_EDIT_ACT_TEMPLATE_INIT_FORM} from "../constants";

import {
    addOrderWorkReportTemplate,
    deleteOrderWorkReportTemplate,
    getOrderWorkReportTemplateById,
    orderWorkReportTemplateCardSelector,
    updateOrderWorkReportTemplate,
    updateOrderWorkReportTemplateStore,
} from "../../../../../../ducks/orderWorkReportTemplate";

export const useOrderTemplatesEditActTemplateForm = (params) => {
    const {
        clientId,
        onClose,
        fetchList,
        editActTemplateData,
    } = params;

    const dispatch = useDispatch();

    const card = useSelector(orderWorkReportTemplateCardSelector);

    const [form, setForm] = useState(() => {
        if (editActTemplateData.orderWorkReportTemplateId) {
            return {
                ...ORDER_TEMPLATES_EDIT_ACT_TEMPLATE_INIT_FORM,
            };
        }

        return {
            ...ORDER_TEMPLATES_EDIT_ACT_TEMPLATE_INIT_FORM,
            clientId,
            orderId: editActTemplateData.orderId,
        };
    });

    useEffect(() => {
        const {
            orderWorkReportTemplateId,
        } = editActTemplateData;

        if (orderWorkReportTemplateId) {
            dispatch(getOrderWorkReportTemplateById({orderWorkReportTemplateId}));
        }

        return () => {
            dispatch(updateOrderWorkReportTemplateStore({card: {}}));
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(card)) {
            const formData = getEditActTemplateDataFromCard({
                card,
                orderId: editActTemplateData.orderId,
            });

            setForm(formData);
        }
    }, [card]);

    const onSubmit = (response) => {
        const {
            guid,
        } = response;

        const requestData = getEditActTemplateRequestData({
            form,
        });

        if (isTemplateOrderActEmpty(requestData)) {
            if (form.orderWorkReportTemplateId) {
                dispatch(deleteOrderWorkReportTemplate({
                    orderWorkReportTemplateId: form.orderWorkReportTemplateId,
                    onSuccess: () => {
                        fetchList();

                        if (onClose) {
                            onClose();
                        }
                    },
                }));

                return;
            }

            fetchList();

            if (onClose) {
                onClose();
            }

            return;
        }

        if (form.orderWorkReportTemplateId) {
            dispatch(updateOrderWorkReportTemplate({
                ...requestData,
                orderId: guid,
                onSuccess: () => {
                    fetchList();

                    if (onClose) {
                        onClose();
                    }
                },
            }));

            return;
        }

        dispatch(addOrderWorkReportTemplate({
            ...requestData,
            orderId: guid,
            onSuccess: () => {
                fetchList();

                if (onClose) {
                    onClose();
                }
            },
        }));
    };

    const onChange = (event, formData) => {
        setForm(form => ({
            ...form,
            ...formData,
        }));
    };

    return {
        form,
        onChange,
        onSubmit,
    };
};