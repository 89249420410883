import {useContext, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {ClientSettingsContext} from "../../ContextProvider";
import validationSchema from "../validation";

import {useModal} from "../../../../../../hooks/useModal";

import {getInitialTouched} from "../../../../../../utils/objectHelper";
import {toastSuccess} from "../../../../../../utils/toastHelper";
import {getBankWithPath} from "../utils/getBankWithPath";
import {getClientSettingsDepositAndPaymentsBlockBanksValues} from "../utils/getBlockBanksValues";
import {isClientSettingsDepositAndPaymentsChanged} from "../utils/isChangedData";

import {
    BANK_PATH,
    PAYMENT_METHOD,
    PAYMENT_METHOD_OPTION_DICT,
} from "../../../../../../constants/clientSettings";
import {CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE, CLIENT_SETTINGS_TAB} from "../../../constants";

import {
    getClientsSettingsPayments,
    updateClientsSettingsPayments,
} from "../../../../../../ducks/bff/clients/settings/actionCreators";
import {bffClientsSettingsPaymentsSelector} from "../../../../../../ducks/bff/clients/settings/selectors";
import {clientSystemPropertiesDataSelector} from "../../../../../../ducks/bff/clients/system-properties/selectors";

export const useClientSettingsDepositAndPaymentsForm = (params) => {
    const {
        clientId,
        onClose,
    } = params;

    const {
        isOpenUnsavedDataConfirm,
        onCancelUnsavedDataConfirm,
        updateChanged,
    } = useContext(ClientSettingsContext);

    const data = useSelector(bffClientsSettingsPaymentsSelector);
    const systemClientProperties = useSelector(clientSystemPropertiesDataSelector);

    const dispatch = useDispatch();

    const paymentBlockList = [
        {
            title: "Оплаты НПД",
            marketplaceField: "smzMarketplace",
            registryField: "smzRegistry",
            apiField: "smzApi",
        },
        {
            title: "Оплаты НДФЛ",
            marketplaceField: "civilMarketplace",
            registryField: "civilRegistry",
            apiField: "civilApi",
        },
        {
            title: "Оплаты ИП",
            marketplaceField: "individualMarketplace",
            registryField: "individualRegistry",
            apiField: "individualApi",
        },
    ];

    const blockList = [
        "smzMarketplace",
        "smzRegistry",
        "smzApi",
        "civilMarketplace",
        "civilRegistry",
        "civilApi",
        "individualMarketplace",
        "individualRegistry",
        "individualApi",
    ];

    const initialValues = useMemo(() => {
        const initialBlock = {
            paymentMethod: null,
            bankTypeCard: null,
            bankTypeSbp: null,
            bankTypeRequisites: null,
            cardDirect: false,
        };

        if (isEmpty(data)) {
            return blockList.reduce((acc, current) => {
                acc[current] = initialBlock;

                return acc;
            }, {});
        }

        return Object.keys(data).reduce((acc, current) => {
            if (blockList.includes(current)) {
                const block = data[current] || {};

                acc[current] = {
                    ...initialBlock,
                    ...block,
                    bankTypeCard: block.bankTypeCard ?
                        getBankWithPath(block.bankTypeCard, block.cardDirect) :
                        null,
                    useBankTypeCard: Boolean(block.bankTypeCard),
                    useBankTypeSbp: Boolean(block.bankTypeSbp),
                    useBankTypeRequisites: Boolean(block.bankTypeRequisites),
                };

                return acc;
            }

            acc[current] = data[current];

            return acc;
        }, {});
    }, [data]);

    useEffect(() => {
        dispatch(getClientsSettingsPayments({clientId}));
    }, []);

    const onSubmit = async ({isClose}) => {
        const errors = await validateForm();

        if (!isEmpty(errors)) {
            await setTouched(getInitialTouched(values, true));

            return;
        }

        const reqData = Object.keys(values).reduce((acc, current) => {
            if (blockList.includes(current)) {
                const block = values[current] || {};

                acc[current] = getClientSettingsDepositAndPaymentsBlockBanksValues(block);

                return acc;
            }

            acc[current] = values[current];

            return acc;
        }, {});

        dispatch(updateClientsSettingsPayments({
            clientId,
            reqData,
            onSuccess: () => {
                toastSuccess(CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE);

                if (isOpenUnsavedDataConfirm) {
                    onCancelUnsavedDataConfirm();
                }

                if (isClose) {
                    onClose();

                    return;
                }

                dispatch(getClientsSettingsPayments({clientId}));
            },
        }));
    };

    const getPaymentMethodOptions = (field) => {
        const block = values[field] || {};

        return [
            block.useBankTypeCard && PAYMENT_METHOD_OPTION_DICT.CARD_LOCAL,
            block.useBankTypeSbp && PAYMENT_METHOD_OPTION_DICT.SBP,
            block.useBankTypeRequisites && PAYMENT_METHOD_OPTION_DICT.BANK_REQUISITES,
        ].filter(item => Boolean(item));
    };

    const {
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        onOpenModal: onOpenDepositDistributedByObjectConfirm,
        onCloseModal: onCloseDepositDistributedByObjectConfirm,
        isOpen: isShowDepositDistributedByObjectsConfirm,
    } = useModal();

    const onChange = (event, {name, value, checked}, field) => {
        const {
            paymentsWithoutConfirmNmAdmin,
            civilPaymentsWithoutConfirmNmAdmin,
            individualPaymentsWithoutConfirmNmAdmin,
        } = systemClientProperties;

        if (name === "smzPaymentsWithoutConfirmNmAdmin" && !paymentsWithoutConfirmNmAdmin) {
            onOpenConfirm({});

            return;
        }

        if (name === "civilPaymentsWithoutConfirmNmAdmin" && !civilPaymentsWithoutConfirmNmAdmin) {
            onOpenConfirm({});

            return;
        }

        if (name === "individualPaymentsWithoutConfirmNmAdmin" && !individualPaymentsWithoutConfirmNmAdmin) {
            onOpenConfirm({});

            return;
        }

        if (name === "distributeDepositByObjects" && !checked) {
            onOpenDepositDistributedByObjectConfirm({});

            return;
        }

        if (name === "useBankTypeRequisites" && !checked) {
            setValues(prevState => ({
                ...prevState,
                [field]: {
                    ...prevState[field],
                    useBankTypeRequisites: false,
                    bankTypeRequisites: null,
                    ...getPaymentMethodData(values, PAYMENT_METHOD.BANK_REQUISITES),
                },
            }));

            return;
        }

        if (name === "useBankTypeSbp" && !checked) {
            setValues(prevState => ({
                ...prevState,
                [field]: {
                    ...prevState[field],
                    useBankTypeSbp: false,
                    bankTypeSbp: null,
                    ...getPaymentMethodData(values, PAYMENT_METHOD.SBP),
                },
            }));

            return;
        }

        if (name === "useBankTypeCard" && !checked) {
            setValues(prevState => ({
                ...prevState,
                [field]: {
                    ...prevState[field],
                    useBankTypeCard: false,
                    bankTypeCard: null,
                },
            }));

            return;
        }

        setFieldValue(field ? `${field}.${name}` : name, typeof checked === "boolean" ? checked : value);
    };

    const onConfirmDepositDistributedByObjectsShutdown = () => {
        setValues(prevState => ({
            ...prevState,
            distributeDepositByObjects: false,
            objectIdForPaidApis: null,
        }));
        onCloseDepositDistributedByObjectConfirm();
    };

    function getPaymentMethodData(values, disabledValue) {
        function getValue(name) {
            return values[name] === disabledValue ? PAYMENT_METHOD.CARD_LOCAL : values[name];
        }

        return {
            paymentMethodMarketplace: getValue("paymentMethodMarketplace"),
            paymentMethodRegistry: getValue("paymentMethodRegistry"),
            paymentMethodAPI: getValue("paymentMethodAPI"),
        };
    }

    const {
        values,
        setFieldValue,
        errors,
        touched,
        setTouched,
        setValues,
        validateForm,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: validationSchema(),
    });

    useEffect(() => {
        const isChangedData = isClientSettingsDepositAndPaymentsChanged({
            values,
            card: data,
        });

        updateChanged({
            name: CLIENT_SETTINGS_TAB.DEPOSIT_AND_PAYMENTS,
            isChangedData,
        });
    }, [data, values]);

    return {
        values,
        setFieldValue,
        errors,
        touched,
        setValues,
        onSubmit,
        getPaymentMethodOptions,
        isShowDepositDistributedByObjectsConfirm,
        onCloseDepositDistributedByObjectConfirm,
        onConfirmDepositDistributedByObjectsShutdown,
        isOpenConfirm,
        onCloseConfirm,
        onChange,
        paymentBlockList,
    };
};