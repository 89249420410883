import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {useDataMedicalPersonalInfoCardEditForm} from "./hooks/useData";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";

import {fileContractorScanProgressAddSelector} from "../../../../../../../ducks/fileStore";

const MedicalPersonalInfoCardEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
    } = props;

    const progress = useSelector(fileContractorScanProgressAddSelector);

    const {
        files,
        errors,
        onDropScan,
        handleSubmit,
        touched,
    } = useDataMedicalPersonalInfoCardEditForm({
        contractorId,
        onClose,
    });

    const renderDropzone = ({label, scanType}) => {
        return (
            <DropzoneV2
                isTopImagePreview
                label={label}
                isVisibleLabel
                files={files[scanType]}
                multiple={false}
                maxSize={10}
                accept=".bmp, .png, .jpeg, .jpg"
                format="*.bmp, *.png, *.jpeg, *.jpg"
                onChange={(files) => onDropScan(files, scanType)}
                uploadBtnText={isEmpty(files[scanType]) ? "Выбрать файл" : "Заменить"}
                error={
                    touched?.files?.[scanType] &&
                    errors?.files?.[scanType]
                }
            />
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Медицинское освидетельствование (доп. документ)
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                {
                    [
                        {
                            label: "Фотография медицинского заключения с персональной информацией",
                            scanType: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN,
                        },
                        {
                            label: "Фотография стороны сертификата об отсутствии ВИЧ",
                            scanType: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN,
                        },
                        {
                            label: "Фотография с подписями и печатями врачей",
                            scanType: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN,
                        },
                        {
                            label: "Фотография медицинского заключения с результатами",
                            scanType: CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN,
                        },
                    ].map(item => {
                        return (renderDropzone(item));
                    })
                }
            </NmForm>
        </NmModal>
    );
};

export default MedicalPersonalInfoCardEditForm;