import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {history} from "../../../store/configureStore";
import CrowdTaskLogList from "../../crowd/task-registry/components/log-modal";

import {usePagination} from "../../../hooks/usePagination";
import useCrowdTaskRegistryAction from "./hooks/useAction";
import useCrowdTaskRegistryFetch from "./hooks/useFetch";
import useCrowdTaskRegistryFilter from "./hooks/useFilter";

import dateFormat, {formatLocalDate} from "../../../utils/dateFormat";
import {formatAmount, formatNumber} from "../../../utils/stringFormat";
import {getClientNameWithBrand} from "../../../utils/stringHelper";

import {CROWD_TASK_STATUS} from "../../../constants/crowd/task";
import {LINK_CLIENT_CROWD_TASK_REGISTRY_CARD, LINK_CLIENT_INFO} from "../../../constants/links";

import {
    bffCrowdTasksListSelector,
    bffCrowdTasksProgressSelector,
    bffCrowdTasksTotalCountSelector,
    bffCrowdTasksTotalPagesSelector,
} from "../../../ducks/bff/crowd/tasks/selectors";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../constants/link-params";

const FinanceCrowdTasks = () => {
    const list = useSelector(bffCrowdTasksListSelector);
    const totalCount = useSelector(bffCrowdTasksTotalCountSelector);
    const totalPages = useSelector(bffCrowdTasksTotalPagesSelector);
    const progress = useSelector(bffCrowdTasksProgressSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        initFilter,
        onClear,
        onSearch,
        filterData,
        filters,
        isSearch,
    } = useCrowdTaskRegistryFilter({
        pageSize,
        setPagination,
    });

    const {} = useCrowdTaskRegistryFetch({
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {
        getMediaControls,
        logFormData,
        setLogFormData,
    } = useCrowdTaskRegistryAction({});

    const onClickTaskLink = ({clientId, taskId}) => {
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":clientId", clientId)
            .replace(":taskId", taskId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        history.push(link);
    };

    const getClientLink = ({clientId, clientNameData}) => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);
        const {
            name,
            brand,
        } = clientNameData || {};

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {getClientNameWithBrand(name, brand)}
            </ExtLink>
        );
    };

    const getNumberLink = ({number, taskId, brand, clientId}) => {
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":clientId", clientId)
            .replace(":taskId", taskId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {number}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            const {
                taskId,
                creationDateTime,
                status,
                name,
                number,
                paymentAmount,
                workStartDate,
                workEndDate,
            } = item;

            return {
                ...item,
                key: taskId,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={CROWD_TASK_STATUS[status]?.TEXT || status}
                                mod={CROWD_TASK_STATUS[status]?.MOD || "gray"}
                            />
                        }
                        secondaryHeader={
                            creationDateTime &&
                            `Дата создания: ${formatLocalDate(creationDateTime, "dd.MM.yyyy HH:mm")}`
                        }
                        primaryHeader={`№${number} - ${name}`}
                        primaryHeaderLink={true}
                        onClickLink={() => onClickTaskLink(item)}
                        classNameMainContent="col-16 col-xxl-7"
                        labels={[
                            {
                                label: "Номер",
                                text: getNumberLink(item),
                            },
                            {
                                label: "Заказчик",
                                text: getClientLink(item),
                            },
                        ]}
                        cards={
                            [
                                {
                                    title: "Сумма задания, ₽",
                                    value: formatAmount(formatNumber(paymentAmount, 2)),
                                    className: "col-16 col-md-4 col-xxl-8 mt-md-4 mt-xxl-0",
                                },
                                {
                                    title: "Период выполнения задания",
                                    value: `${dateFormat(workStartDate, "dd.MM.yyyy")} - ${dateFormat(workEndDate, "dd.MM.yyyy")}`,
                                    className: "col-16 col-md-4 col-xxl-8 mt-md-4 mt-xxl-0",
                                },
                            ]
                        }
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-5 align-items-end"
                        mediaControls={getMediaControls(item)}
                        actionsClassName="col-1 col-xxl-4 justify-content-end"
                    />
                ),
            };
        });
    };

    const renderLogModal = () => {
        return (
            !isEmpty(logFormData) &&
            <CrowdTaskLogList
                taskData={logFormData}
                onClose={() => setLogFormData({})}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle size="xl">
                    Задания
                </NmTitle>
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderLogModal()}
            {
                totalCount ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default FinanceCrowdTasks;