import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import LinkMarkdown from "../../../components/LinkMarkdown";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as DescriptionIcon} from "../../../images/description_24.svg";
import {history} from "../../../store/configureStore";

import {formatLocalDate} from "../../../utils/dateFormat";

import {COLOR} from "../../../constants/color";
import {CHAT_LINK_PARAMS} from "../../../constants/disputes";
import {LINK_CHAT_LIST, LINK_CLIENT_CROWD_TASK_CHATS} from "../../../constants/links";
import {NOTIFICATION_TYPE} from "../../../constants/notifications";

import {downloadDocument} from "../../../ducks/documents";
import {addViewNotification, getNotificationUnreadCount} from "../../../ducks/notification";

import "./style.sass";

class NotificationsCard extends Component {
    componentDidMount() {
        const {
            addViewNotification,
            getNotificationUnreadCount,
            notification: {
                notificationId,
                viewed,
            },
            clientId,
            fetchList,
        } = this.props;

        if (viewed === false) {
            addViewNotification({
                clientId,
                notificationId,
                getSuccess: () => {
                    getNotificationUnreadCount({clientId});
                    fetchList();
                },
            });
        }
    }

    renderInfoFiles() {
        const {
            notification: {
                files,
            },
        } = this.props;

        if (isEmpty(files)) {
            return null;
        }

        const mapFiles = (selectedFiles) => {
            return selectedFiles.map(file => {
                const {name} = file;

                return (
                    <div
                        key={name}
                        onClick={() => {
                            this.downloadFile(file);
                        }}
                        className="notification-card__files-block"
                    >
                        <DescriptionIcon
                            width={24}
                            height={24}
                            color={COLOR.PRIMARY_100}
                            className="notification-card__description-icon"
                        />
                        <Text
                            noWrap
                            size="sm"
                            level="2"
                            color={COLOR.PRIMARY_100}
                        >
                            {name}
                        </Text>
                    </div>
                );
            });
        };

        return mapFiles(files);
    }

    downloadFile(file) {
        const {downloadDocument} = this.props;
        const {link, name: fileName} = file;

        downloadDocument({
            isDownload: true,
            isFillOrigin: true,
            fileName,
            downloadLink: link,
        });
    }

    pushChatLink = (notificationType, notificationData) => {
        const {location} = this.props;
        const {
            chatId,
            orderId,
            clientId,
            contractorId,
        } = notificationData;

        const orderChatsLink = LINK_CHAT_LIST
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "25")
            .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatId)
            .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId)
            .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, clientId);

        const crowdChatsLink = LINK_CLIENT_CROWD_TASK_CHATS
            .replace(":clientId", clientId)
            .replace(":contractorId?", contractorId);

        const state = {
            prevPath: location.pathname,
        };

        if (notificationType === NOTIFICATION_TYPE.NEW_CROWD_CHAT_MESSAGE) {
            history.push({pathname: crowdChatsLink, state});

            return;
        }

        history.push({pathname: orderChatsLink, state});
    };

    /*
    chatId: "da382995-bb3f-473e-915a-563ca1457b02"
clientId: "78ecaaa7-eb33-45cb-9856-fc456ba2e132"
orderId: "65e36c5a-2c24-455b-86b6-bc63ba6b4fb9"
    * */
    render() {
        const {
            close,
            notification: {
                title,
                sendTime,
                message,
                notificationData,
                notificationType,
            },
            notificationMessage,
        } = this.props;

        const {chatId} = notificationData || {};

        return (
            <NmModal
                size="md"
                onClose={close}
                className="notification-card"
                classNameContent="notification-card__content"
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                footer={
                    chatId &&
                    <NmButton
                        color="grey"
                        className="notification-card__button"
                        popup="Перейти в чат"
                        onClick={() => this.pushChatLink(notificationType, notificationData)}
                    >
                        Перейти в чат
                    </NmButton>
                }
            >
                {
                    <Text
                        preWrap={true}
                        level="3"
                        color={COLOR.SECONDARY_75}
                    >
                        {
                            notificationMessage ?
                                notificationMessage :
                                <LinkMarkdown>
                                    {message}
                                </LinkMarkdown>
                        }
                    </Text>
                }
                <Text
                    level="2"
                    color={COLOR.SECONDARY_75}
                    className="mt-2"
                >
                    {formatLocalDate(sendTime, "dd.MM.yyyy HH:mm")}
                </Text>
                {this.renderInfoFiles()}
            </NmModal>
        );
    }
}

export default connect(
    state => ({}),
    {
        downloadDocument,
        addViewNotification,
        getNotificationUnreadCount,
    },
)(withTranslation()(NotificationsCard));