export const CROWD_TASK_STATUS = {
    DRAFT: {
        VALUE: "DRAFT",
        TEXT: "Черновик",
        MOD: "gray",
    },
    CONTRACTOR_SEARCH: {
        VALUE: "CONTRACTOR_SEARCH",
        TEXT: "Опубликовано",
        MOD: "green",
    },
    PAYMENT_IN_PROGRESS: {
        VALUE: "PAYMENT_IN_PROGRESS",
        TEXT: "В оплате",
        MOD: "orange",
    },
    COMPLETED: {
        VALUE: "COMPLETED",
        TEXT: "Выполнено",
        MOD: "gray",
    },
    NOT_COMPLETED: {
        VALUE: "NOT_COMPLETED",
        TEXT: "Не выполнено",
        MOD: "red",
    },
};

export const CROWD_TASK_STATUS_OPTIONS = [
    {
        key: CROWD_TASK_STATUS.DRAFT.VALUE,
        text: CROWD_TASK_STATUS.DRAFT.TEXT,
        value: CROWD_TASK_STATUS.DRAFT.VALUE,
    },
    {
        key: CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE,
        text: CROWD_TASK_STATUS.CONTRACTOR_SEARCH.TEXT,
        value: CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE,
    },
    {
        key: CROWD_TASK_STATUS.PAYMENT_IN_PROGRESS.VALUE,
        text: CROWD_TASK_STATUS.PAYMENT_IN_PROGRESS.TEXT,
        value: CROWD_TASK_STATUS.PAYMENT_IN_PROGRESS.VALUE,
    },
    {
        key: CROWD_TASK_STATUS.COMPLETED.VALUE,
        text: CROWD_TASK_STATUS.COMPLETED.TEXT,
        value: CROWD_TASK_STATUS.COMPLETED.VALUE,
    },
    {
        key: CROWD_TASK_STATUS.NOT_COMPLETED.VALUE,
        text: CROWD_TASK_STATUS.NOT_COMPLETED.TEXT,
        value: CROWD_TASK_STATUS.NOT_COMPLETED.VALUE,
    },
];

export const CROWD_TASK_WORKING_CONTRACTOR_STATUS = {
    INVITED: {
        VALUE: "INVITED",
        TEXT: "Приглашенный",
        COLOR: "gray",
    },
    IN_QUEUE: {
        VALUE: "IN_QUEUE",
        TEXT: "В очереди",
        COLOR: "gray",
    },
    SIGNING_CONTRACT: {
        VALUE: "SIGNING_CONTRACT",
        TEXT: "Подписывает договор",
        COLOR: "orange",
    },
    HIRED: {
        VALUE: "HIRED",
        TEXT: "Нанятый",
        COLOR: "light-green",
    },
    AT_WORK: {
        VALUE: "AT_WORK",
        TEXT: "В работе",
        COLOR: "green",
    },
    ON_VERIFICATION: {
        VALUE: "ON_VERIFICATION",
        TEXT: "На проверке",
        COLOR: "orange",
    },
    IN_PROCESS_OF_PAYMENT: {
        VALUE: "IN_PROCESS_OF_PAYMENT",
        TEXT: "В оплате",
        COLOR: "orange",
    },
    REFUSED_CLIENT: {
        VALUE: "REFUSED",
        TEXT: "Отклонено заказчиком",
        COLOR: "red",
    },
    REFUSED_CONTRACTOR: {
        VALUE: "REFUSED",
        TEXT: "Отклонено исполнителем",
        COLOR: "red",
    },
    COMPLETED: {
        VALUE: "COMPLETED",
        TEXT: "Выполнил",
        COLOR: "green",
    },
};

export const STATUS_TASK_CONTRACTOR_REJECT_TYPE_TRANSLATE = {
    CONTRACTOR: "Отклонено исполнителем",
    CLIENT: "Отклонено заказчиком",
};

export const CROWD_TASK_WORKING_CONTRACTOR_STEPS = [
    {
        name: CROWD_TASK_WORKING_CONTRACTOR_STATUS.SIGNING_CONTRACT.TEXT,
        value: CROWD_TASK_WORKING_CONTRACTOR_STATUS.SIGNING_CONTRACT.VALUE,
    },
    {
        name: CROWD_TASK_WORKING_CONTRACTOR_STATUS.HIRED.TEXT,
        value: CROWD_TASK_WORKING_CONTRACTOR_STATUS.HIRED.VALUE,
    },
    {
        name: CROWD_TASK_WORKING_CONTRACTOR_STATUS.AT_WORK.TEXT,
        value: CROWD_TASK_WORKING_CONTRACTOR_STATUS.AT_WORK.VALUE,
    },
    {
        name: CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.TEXT,
        value: CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
    },
    {
        name: CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.TEXT,
        value: CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE,
    },
];

export const CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE = {
    APPLIED_BY_MISTAKE: {
        VALUE: "APPLIED_BY_MISTAKE",
        TEXT: "Откликнулся по ошибке",
    },
    REFUSED_TO_WORK: {
        VALUE: "REFUSED_TO_WORK",
        TEXT: "Отказался выполнять задание",
    },
    TASK_NOT_RELEVANT: {
        VALUE: "TASK_NOT_RELEVANT",
        TEXT: "Задание перестало быть актуальным",
    },
    NOT_SATISFIED_WITH_TERMS: {
        VALUE: "NOT_SATISFIED_WITH_TERMS",
        TEXT: "Не устраивают условия",
    },
    UNFIT_PROFILE: {
        VALUE: "UNFIT_PROFILE",
        TEXT: "Не подходит по профилю",
    },
    OTHER : {
        VALUE: "OTHER",
        TEXT: "Другое",
    },
};

export const TASK_CONTRACTOR_STATUS_OPTIMUM_FORM = {
    "-1": "Анкета открыта для редактирования",
    "-2": "Пользователь вышел из анкеты без сохранения",
    "2": "Анкета заполнена",
    "3": "Анкета проверена",
    "4": "Анкета отправлена на доработку",
    "7": "Анкета принята",
    "8": "Анкета отклонена",
};