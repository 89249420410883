import {COLOR} from "../../../../../constants/color";

import {STATUS_TASK_PAYMENT} from "../../../../../constants/crowd/task-payment";

export const getTaskActRegistryPaymentStatus = (status) => {
    function getColor() {
        if (status === STATUS_TASK_PAYMENT.SUCCESSFUL.VALUE) {
            return COLOR.PRIMARY_100;
        }

        if (
            [
                STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
                STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
                STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
                STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
            ].includes(status)
        ) {
            return COLOR.NEGATIVE_100;
        }

        return COLOR.SECONDARY_70;
    }

    return {
        color: getColor(),
        text: STATUS_TASK_PAYMENT[status]?.TEXT,
    };
};