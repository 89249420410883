import axios from "axios";
import {push} from "connected-react-router";
import {isEmpty} from "lodash";
import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getCurrentClientById} from "./client";
import {getClientCurrentMemberByLogin} from "./clientMember";
import {getClientPropertiesById, getClientPropertiesCardSelector} from "./clientProperties";
import {getEdoAccessList} from "./edocuments";

import {getAccessLink, getUserRole} from "../utils/access";
import dateFormat from "../utils/dateFormat";
import {
    ACCESS_TOKEN_KEY,
    CURRENT_CLIENT_ID,     CURRENT_CLIENT_USER_ID,
    IS_NEW_USER,
    ls,
    PREV_PATH,
    PREV_PATHNAME,     REFRESH_TOKEN_KEY,
    USER_ID,
    USER_LOGIN,
    USER_ROLE} from "../utils/localstorage";
import request, {setAccessToken} from "../utils/postman";
import {clearSpace} from "../utils/stringFormat";
import {isNullOrWhitespace} from "../utils/stringHelper";
import {toastError} from "../utils/toastHelper";

import {FINANCE_PIVOT_LINK_PARAMS} from "../constants/financePivot";
import {
    LINK_CLIENT_CARD,
    LINK_CLIENT_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_RECRUITMENT_VACANCIES,
    LINK_CONTRACTOR_LIST,
    LINK_CRM_TICKET_LIST_IN_WORK,
    LINK_FINANCE_EXPORT_FULL,
    LINK_HOME,
    LINK_LOGIN} from "../constants/links";
import {MENU_DESIGN_TYPE} from "../constants/menu";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    isClientUser,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../constants/roles";

const controller = "/api/auth";

//*  TYPES  *//

const AUTHORIZATION_REQUEST = "AUTHORIZATION_REQUEST";
const AUTHORIZATION_SUCCESS = "AUTHORIZATION_SUCCESS";
const AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR";

const GET_AUTHORIZATION_SESSION_REQUEST = "GET_AUTHORIZATION_SESSION_REQUEST";

const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
const REFRESH_TOKEN_ACCESS = "REFRESH_TOKEN_ACCESS";
const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

const STORE_TOKENS = "STORE_TOKENS";

const LOGOUT_REQUEST = "LOGOUT_REQUEST";

const CLEAR_TOKENS = "CLEAR_TOKENS";

const AUTHORIZATION_UPDATE_FIELD_STORE = "AUTHORIZATION_UPDATE_FIELD_STORE";

const CHANGE_ACCOUNT_REQUEST = "CHANGE_ACCOUNT_REQUEST";
const CHANGE_ACCOUNT_ERROR = "CHANGE_ACCOUNT_ERROR";
const CHANGE_ACCOUNT_SUCCESS = "CHANGE_ACCOUNT_SUCCESS";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    auth: false,
    message: null,
    flag: true,
    userInfo: {},
    prevPathObj: {
        pathname: "",
        path: "",
    },
};

const tokenKeys = [
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
];

const aceessKeys = [
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    USER_ID, USER_ROLE,
    USER_LOGIN,
    CURRENT_CLIENT_USER_ID,
    CURRENT_CLIENT_ID,
    IS_NEW_USER,
];

const getTokensData = () => tokenKeys.reduce(
    (data, key) => ({
        ...data,
        [key]: ls(key),
    }),
    {},
);

setAccessToken(getTokensData());

window.addEventListener("storage", (event) => {
    if (tokenKeys.includes(event.key)) {
        setAccessToken(getTokensData());
    }
});


//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case AUTHORIZATION_REQUEST:
        return {
            ...state,
            progress: true,
        };

    case AUTHORIZATION_SUCCESS:
        return {
            ...state,
            progress: false,
            auth: true,
            userInfo: payload,
        };
    case AUTHORIZATION_ERROR:
        return {
            ...state,
            progress: false,
            auth: false,
            message: payload,
        };
    case AUTHORIZATION_UPDATE_FIELD_STORE:
        const {
            field,
            value,
        } = payload;
        return {
            ...state,
            [field]: value,
        };
    case STORE_TOKENS:
        return {
            ...state,
            ...payload,
        };
    case REFRESH_TOKEN_ACCESS:
    case REFRESH_TOKEN_ERROR:
        return {
            ...state,
            flag: true,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getAuthorizationSession(payload) {
    return {
        type: GET_AUTHORIZATION_SESSION_REQUEST,
        payload,
    };
}

export function authorizationRequest(login, password) {
    return {
        type: AUTHORIZATION_REQUEST,
        payload: {
            login,
            password,
        },
    };
}

export function clearLocalStorage() {
    return {
        type: CLEAR_TOKENS,
        payload: {},
    };
}

export function updateFieldAuthorizationStore(field, value) {
    return {
        type: AUTHORIZATION_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function logoutRequest() {
    return {
        type: LOGOUT_REQUEST,
        payload: {},
    };
}

export function storeTokens(stuff) {
    return {
        type: STORE_TOKENS,
        payload: stuff,
    };
}

export function changeAccount(payload) {
    return {
        type: CHANGE_ACCOUNT_REQUEST,
        payload,
    };
}

export function refreshTokenAction(payload) {
    return {
        type: REFRESH_TOKEN_REQUEST,
        payload,
    };
}

export const authSelector = state => state.auth;

export const userInfoSelector = createSelector(authSelector, ({userInfo}) => userInfo);
export const authProgressSelector = createSelector(authSelector, ({progress}) => progress);

//*  SAGA  *//

function* showToasterTerminateContract(clientId, role) {
    const {activitySuspended, archived} = yield request.get("/clients/getRichById", {params: {clientId}});
    const accessByRole = [CLIENT_ADMIN, FOREMAN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT, HR_MANAGER].includes(role);

    if (activitySuspended && !archived && accessByRole) {
        const {results} = yield request.get(`/clients/${clientId}/terminateContractInfo`);
        const terminationDate = !isEmpty(results) && results[0].terminationDate ? dateFormat(results[0].terminationDate , "dd.MM.yyyy") : "";

        toastError(`От вашей компании поступило уведомление о расторжении агентского договора с оператором электронной площадки. Договор будет расторгнут и доступ к площадке заблокирован ${terminationDate}`);
    }
}

function getLink(clientId, role) {
    const link = {
        NM_PARTNER: LINK_FINANCE_EXPORT_FULL
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "50")
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_CLIENTID_PARAM, ""),
        FOREMAN: LINK_CLIENT_ORDER_LIST.replace(":clientId", clientId),
        RNKO: LINK_CONTRACTOR_LIST.replace(":pageNumber", 1).replace(":pageSize", 25),
        HR_MANAGER: LINK_CLIENT_CARD.replace(":clientId", clientId) + "/info",
        CLIENT_ADMIN: LINK_CLIENT_CARD.replace(":clientId", clientId) + "/info",
        CLIENT_ACCOUNTANT: LINK_CLIENT_CARD.replace(":clientId", clientId) + "/info",
        PROJECT_MANAGER: LINK_CLIENT_PROJECTS_LIST.replace(":clientId", clientId),
        OBJECT_MANAGER: LINK_CLIENT_PROJECTS_LIST.replace(":clientId", clientId),
        [NM_OPERATOR]: LINK_CRM_TICKET_LIST_IN_WORK,
        RECRUITER: LINK_CLIENT_ORDER_LIST.replace(":clientId", clientId),
        RECRUITMENT_OBSERVER: LINK_CLIENT_RECRUITMENT_VACANCIES.replace(":clientId", clientId),
    }[role];

    return link || LINK_CLIENT_LIST.replace(":archived", false);
}

function getPrevPathname(prevPathObj, role) {
    const {
        pathname: lsPrevPathname,
        path: lsPrevPath,
    } = prevPathObj;

    if (isNullOrWhitespace(lsPrevPathname) ||
        isNullOrWhitespace(lsPrevPath) ||
        [LINK_HOME, LINK_LOGIN].includes(lsPrevPathname)
    ) {
        return;
    }

    if (getAccessLink({role}).indexOf(lsPrevPath) === -1) {
        return;
    }

    return lsPrevPathname;
}

export const authorization = function* (action) {
    try {
        const {login, password} = action.payload;

        const res = yield call(axios, {
            url: `${controller}/login`,
            method: "post",
            data: {
                login,
                password,
            },
        });

        const {data = {}} = res;
        const {errorMessage, role, clientId, clientUserId} = data;
        const loginWithoutSpaces = clearSpace(login);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: AUTHORIZATION_ERROR,
                payload: errorMessage,
            });
            return {done: true};
        }

        yield put({
            type: AUTHORIZATION_SUCCESS,
            payload: {userName: loginWithoutSpaces},
        });

        yield put(storeTokens(res.data));

        yield ls(USER_ROLE, role);

        if (ls(CURRENT_CLIENT_USER_ID) !== clientUserId) {
            ls(IS_NEW_USER, true);
        }
        ls(CURRENT_CLIENT_USER_ID, clientUserId);
        //yield fork(userReadSaga);
        yield ls(CURRENT_CLIENT_ID, clientId);
        yield ls(USER_LOGIN, loginWithoutSpaces);

        yield put(getCurrentClientById(clientId));
        yield put(getClientCurrentMemberByLogin(loginWithoutSpaces));

        const {
            auth: {
                prevPathObj,
            },
        } = yield select();


        yield put(push(getPrevPathname(prevPathObj, role) || getLink(clientId, role)));

        yield showToasterTerminateContract(clientId, role);

    } catch (error) {
        toastError(error.message);

        yield put({
            type: AUTHORIZATION_ERROR,
            payload: "Неверный логин/пароль",
        });
    }
};

export const refreshToken = function* (action = {}) {
    try {
        const {
            payload = {},
        } = action;
        const {
            onSuccess,
        } = payload;

        const res = yield call(axios, {
            url: `${controller}/refreshToken`,
            method: "post",
            headers: {Authorization: `Bearer ${ls(REFRESH_TOKEN_KEY)}`},
        },
        );

        const {data = {}} = res;
        const {error, errorMessage} = data;

        if (error || errorMessage) {
            yield put({
                type: AUTHORIZATION_ERROR,
                payload: error || errorMessage,
            });

            console.log("data.errorMessage", errorMessage);
            console.log("data.error", error);
            yield put(push(LINK_LOGIN));

            return {done: true};
        }

        yield put(storeTokens(data));
        yield put({
            type: REFRESH_TOKEN_SUCCESS,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        if (error.message.indexOf("401") !== -1) {
            console.log("error", error);
            yield put(push(LINK_LOGIN));
        }
    }
};

export const logout = function* (action) {
    try {
        yield call(axios, {
            url: `${controller}/logout`,
            method: "post",
            headers: {Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`},
        },
        );

        // yield ls(PREV_PATHNAME, undefined);

        yield put(clearLocalStorage());

        const state = yield select();
        const card = getClientPropertiesCardSelector(state);
        const role = getUserRole();

        if (
            isClientUser(role)
            && card.designMenuType === MENU_DESIGN_TYPE.HO_RE_CA_MENU
        ) {
            window.location.href = process.env.REACT_APP_HORECA_LOGIN_PAGE_LINK;
        } else {
            yield put(push(LINK_LOGIN));
        }
    } catch (error) {
        if (error.message.indexOf("401") !== -1) {
            yield put(push(LINK_LOGIN));
            yield ls(PREV_PATHNAME, undefined);

            yield put(clearLocalStorage());
        }
    }
};

function* handleTokens(action) {
    const tokens = action.payload;
    yield setAccessToken(tokens);

    yield tokenKeys.forEach(k => ls(k, tokens[k]));
}

function* deleteTokens() {
    setAccessToken(null);
    yield aceessKeys.forEach(k => localStorage.removeItem(k));
}

//POST /api/auth/changeAccount
export const changeAccountSaga = function* ({payload}) {
    try {
        const {onSuccess, ...requestData} = payload;

        const initParams = {
            headers: {Authorization: `Bearer ${ls(REFRESH_TOKEN_KEY)}`},
        };

        const data = yield request.post("/auth/changeAccount", requestData, initParams);

        const {errorMessage, role, clientId, clientUserId} = data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_ACCOUNT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put(storeTokens(data));
        yield put(getEdoAccessList());

        yield ls(USER_ROLE, role);

        if (ls(CURRENT_CLIENT_USER_ID) !== clientUserId) {
            ls(IS_NEW_USER, true);
        }
        ls(CURRENT_CLIENT_USER_ID, clientUserId);

        yield ls(CURRENT_CLIENT_ID, clientId);

        yield put(getCurrentClientById(clientId));

        yield put(getClientCurrentMemberByLogin(ls(USER_LOGIN)));

        yield put(push(getPrevPathname(role) || getLink(clientId, role)));

        yield showToasterTerminateContract(clientId, role);

        yield put(getClientPropertiesById({clientId}));

        if (onSuccess) {
            yield onSuccess();
        }

        yield put({
            type: CHANGE_ACCOUNT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: CHANGE_ACCOUNT_ERROR,

        });
    }
};

export const getAuthorizationSessionSaga = function* ({payload}) {
    try {
        const {refreshToken, onSuccess} = payload;
        const res = yield call(axios, {
            url: `${controller}/session/${refreshToken}`,
            method: "get",
        },
        );

        const {data = {}} = res;
        const {errorMessage, role, clientId, clientUserId, login} = data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: AUTHORIZATION_ERROR,
                payload: errorMessage,
            });
            return {done: true};
        }

        yield put({
            type: AUTHORIZATION_SUCCESS,
            payload: {userName: login},
        });

        yield put(storeTokens(res.data));

        yield ls(USER_ROLE, role);

        if (ls(CURRENT_CLIENT_USER_ID) !== clientUserId) {
            ls(IS_NEW_USER, true);
        }

        ls(CURRENT_CLIENT_USER_ID, clientUserId);

        yield ls(CURRENT_CLIENT_ID, clientId);
        yield ls(USER_LOGIN, login);

        yield put(getCurrentClientById(clientId));
        yield put(getClientCurrentMemberByLogin(login));

        onSuccess();

        yield showToasterTerminateContract(clientId, role);
    } catch (error) {
        toastError(error.message);

        yield put({
            type: AUTHORIZATION_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeLatest(AUTHORIZATION_REQUEST, authorization),
        takeLatest(REFRESH_TOKEN_REQUEST, refreshToken),
        takeLatest(STORE_TOKENS, handleTokens),
        takeLatest(CLEAR_TOKENS, deleteTokens),
        takeLatest(LOGOUT_REQUEST, logout),
        takeLatest(CHANGE_ACCOUNT_REQUEST, changeAccountSaga),
        takeLatest(GET_AUTHORIZATION_SESSION_REQUEST, getAuthorizationSessionSaga),
    ]);
}
