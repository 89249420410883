import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";

import {usePagination} from "../../../hooks/usePagination";
import {useKedoTemplateLogsFetch} from "./hooks/useFetch";
import {useKedoTemplateLogsFilter} from "./hooks/useFilter";

import {formatLocalDate} from "../../../utils/dateFormat";
import {getFormattedFullName, getFullName} from "../../../utils/stringFormat";

import {
    kedoTemplateLogsListSelector,
    kedoTemplatesProgressSelector,
    kedoTemplatesTotalCountSelector,
    kedoTemplatesTotalPageSelector,
} from "../../../ducks/kedo/templates/selectors";

export const KedoTemplatesLog = () => {
    const list = useSelector(kedoTemplateLogsListSelector);
    const totalPages = useSelector(kedoTemplatesTotalPageSelector);
    const totalCount = useSelector(kedoTemplatesTotalCountSelector);
    const progress = useSelector(kedoTemplatesProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    } = useKedoTemplateLogsFilter({
        pageSize,
        setPagination,
    });

    useKedoTemplateLogsFetch({
        pageNum,
        pageSize,
        filterData,
    });

    const getRows = () => {
        return list.map(item => {
            const {
                dateTime,
                info,
                customDocumentTemplateName,
                performer,
            } = item;

            return {
                key: item.id,
                contentRow: (
                    <NmListCard
                        secondaryHeader={`Дата и время ${formatLocalDate(dateTime, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={info}
                        classNameMainContent="col-16 col-xxl-12"
                        labels={[
                            {
                                label: "Наименование шаблона",
                                text: customDocumentTemplateName || "-",
                            },
                            {
                                label: "ФИО ответственного",
                                text: performer ? getFormattedFullName(
                                    getFullName(
                                        performer.lastName,
                                        performer.firstName,
                                        performer.patronymic,
                                    ),
                                ) : "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            typeFilter="vertical"
            widthByFilter
            noPadding={true}
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Журнал событий
                </NmTitle>
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {
                list.length ?
                    <CheckboxList rows={getRows()} /> :
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
};