import React from "react";

import {RecruitmentCandidateAvatar} from "../../CandidateAvatar";

import dateFormat from "../../../../utils/dateFormat";
import {phoneFormat} from "../../../../utils/stringFormat";

import {GENDER_DICT} from "../../../../constants/contractorInfo";

export const getRecruitmentCandidateResponseValues = (card, dicts) => {
    const {
        citizenshipDict,
        isResponse,
        responseId,
        candidateId,
    } = dicts;

    const specialities = card.specialities?.join(", ");
    const birthDate = dateFormat(card.birthDate, "dd.MM.yyyy");
    const gender = GENDER_DICT[card.gender];
    const phone = phoneFormat(card.phone);
    const isAvatar = card.avatar;
    const avatar = isResponse ?
        <RecruitmentCandidateAvatar
            isFetch={isAvatar}
            responseId={responseId}
            style={{
                width: 50,
                height: 50,
            }}
        /> :
        <RecruitmentCandidateAvatar
            isEditable={false}
            isFetch={false}
            style={{
                width: 50,
                height: 50,
            }}
            candidateId={candidateId}
        />;
    const citizenship = citizenshipDict[card.citizenship];
    const addresses = card.addresses?.join(", ");

    return {
        applicantName: card.applicantName,
        email: card.email,
        age: card.age,
        birthDate,
        gender,
        phone,
        citizenship,
        addresses,
        specialities,
        avatar,
    };
};