import {
    DELETE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
    DELETE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
    DELETE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS,
} from "./actions";

const initialState = {
    progressAction: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST:
    case DELETE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS:
    case UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR:
    case DELETE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS:
    case DELETE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    default:
        return state;
    }
};