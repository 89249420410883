import React, {FC} from "react";
import {useMedia} from "react-media";
import {isEmpty} from "lodash";

import {ReactComponent as FilterIcon} from "../../../images/filter_outline_24.svg";
import ButtonWithContextMenu from "../../BottonWithContextMenu";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import DefaultDropzone from "../../DefaultDropzone";
import NmButton from "../../NmButton";
import NmButtonContact from "../../NmButtonContact";
import RegistryCardButton from "../../RegistryCardButton";
import ContextMenu from "../ContextMenu";
import {IconButton} from "../IconButton";
import NmHorizontalToggleV2 from "../NmHorizontalToggleV2";
import Text from "../Text";

import bem from "../../../utils/bem";
import {extractButtonProps} from "./utils/extractButtonProps";
import {getMediaControlsQueries} from "./utils/getQueries";
import {transformOptions} from "./utils/transformOptions";

import {COMPONENT} from "./constants";

import {IMediaButtons, TDevice, TMediaButton} from "./types";

import "./style.sass";

const TRANSLATE_COMPONENT = {
    [COMPONENT.BUTTON]: NmButton,
    [COMPONENT.ICON_BUTTON]: IconButton,
    [COMPONENT.HORIZONTAL_TOGGLE]: NmHorizontalToggleV2,
    [COMPONENT.BUTTON_WITH_TOOLTIP]: ButtonWithTooltip,
    [COMPONENT.BUTTON_WITH_CONTEXT_MENU]: ButtonWithContextMenu,
    [COMPONENT.TEXT]: Text,
    [COMPONENT.REGISTRY_BUTTON]: RegistryCardButton,
    [COMPONENT.BUTTON_CONTACT]: NmButtonContact,
    [COMPONENT.DEFAULT_DROPZONE]: DefaultDropzone,
};

export const MediaButtons: FC<IMediaButtons> = (props) => {
    const {
        inline = false,
        notBorder = false,
        buttonOffset = "md",
        classNameContextMenu = "",
        config: {
            size,
            buttons,
            renderCount = {},
            innerContextMenu,
        },
        className = "",
    } = props;
    const {
        mobile = 0,
        tablet = 2,
        desktop = 3,
    } = renderCount;
    const [block, element] = bem("media-buttons", className);
    const device: TDevice = useMedia({
        queries: getMediaControlsQueries(renderCount),
    });
    let exceptionRenderedCount = 0;

    const getContextMenu = () => {
        const options = transformOptions({
            buttons,
            renderCount: {
                mobile,
                tablet,
                preDesktop: renderCount.preDesktop,
                desktop,
            },
            device,
        });

        if (options.length === 0) {
            return null;
        }

        return (
            <ContextMenu
                inline={inline}
                notBorder={notBorder}
                size={size}
                className={`${element("button", {ml: buttonOffset})} ${classNameContextMenu}`}
                options={options}
            />
        );
    };

    const getException = ({filter, props, Component}: {filter?: boolean, props: any, Component: React.ElementType}, index: number) => {
        if (!filter) {
            return (
                <div className={element("button", {ml: buttonOffset})}>
                    <Component
                        size={size}
                        {...props}
                    />
                </div>
            );
        }

        if (device.mobile) {
            return null;
        }

        return (
            <div
                key={index}
                className={element("button", {ml: buttonOffset})}
            >
                <NmButton
                    {...props}
                    onlyIcon={true}
                    color="grey"
                    icon={<FilterIcon />}
                    size="xl"
                    children={device.mobile ? "Открыть фильтр" : ""}
                />
            </div>
        );
    };

    return (
        <div className={block()}>
            {
                buttons.map((item: TMediaButton, index: number) => {
                    const {
                        component = "button",
                        props,
                        visible = true,
                        settings = {},
                        className = "",
                        isNeedAssetRendering,
                        isAdditional = false,
                        asset = {},
                    } = item;
                    const {
                        filter,
                        exception,
                    } = settings;
                    const numberOfRenderedElement = index + 1 - exceptionRenderedCount;

                    if (exception) {
                        exceptionRenderedCount++;
                    }

                    if (!visible) {
                        return null;
                    }

                    const {[component]: Component} = TRANSLATE_COMPONENT;

                    if (exception || filter) {
                        return getException({props, filter, Component}, index);
                    }

                    if (device.mobile && numberOfRenderedElement > mobile) {
                        return null;
                    }

                    if (device.tablet && numberOfRenderedElement > tablet) {
                        return null;
                    }

                    if (
                        device.preDesktop
                        && typeof renderCount.preDesktop === "number"
                        && numberOfRenderedElement > renderCount.preDesktop
                    ) {
                        return null;
                    }

                    if (device.desktop && numberOfRenderedElement > desktop) {
                        return null;
                    }

                    const compClassName = `${element("button", {ml: buttonOffset, additional: isAdditional})} ${className}`;

                    if (!isEmpty(asset)) {
                        const config = extractButtonProps(asset, device);

                        if (isNeedAssetRendering) {
                            return (
                                <div
                                    key={index}
                                    className={compClassName}
                                >
                                    {asset.mobile}
                                </div>
                            );
                        }

                        return (
                            <div
                                key={index}
                                className={compClassName}
                            >
                                <Component
                                    size={size}
                                    {...props}
                                    {...config}
                                />
                            </div>
                        );
                    }

                    return (
                        <div
                            key={index}
                            className={compClassName}
                        >
                            <Component
                                size={size}
                                {...props}
                            />
                        </div>
                    );
                })
            }
            {getContextMenu()}
            {innerContextMenu}
        </div>
    );
};