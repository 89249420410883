import {
    ADMIN,
    CLIENT_ADMIN,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../constants/roles";

export function isShowPromoCodesBlock (role) {
    return [ADMIN, NM_MANAGER, NM_OPERATOR, NM_COORDINATOR, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER].includes(role);
};

export function isShowClientPromoCodesBlock (role) {
    return [ADMIN, NM_MANAGER, NM_OPERATOR, NM_COORDINATOR].includes(role);
};

export function isShowContractorsPromoCodesBlock (role) {
    return [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_OPERATOR, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER].includes(role);
};

export function isPromoCodesEditable (role) {
    return [ADMIN, NM_MANAGER].includes(role);
};

export function isCommissionRateEditable (role) {
    return [ADMIN, NM_MANAGER].includes(role);
};