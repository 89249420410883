import {StatementListItemType, StatementParticipantType} from "../item/utils/mapItemListToForm";

import {EDO_SENDER_STATUS} from "../../../../../constants/document-management/document-list";

export type SelectStatement = {
    isSelected?: boolean,
    id: string
};

export function getIsSelectedById(selectedList: SelectStatement[], id: string): boolean {
    const {isSelected = false} = selectedList.find(_item => (_item.id === id)) || {};
    return isSelected;
}

export function getShowCheckBox(item: StatementListItemType, archived: boolean, currentClientUserId: string): boolean {
    const find = (it: StatementParticipantType) => it.clientUserId === currentClientUserId && [EDO_SENDER_STATUS.ON_SIGNING.VALUE].includes(it.state);
    const participant = item.participants?.find(find);

    return !archived && Boolean(participant);
}