import React from "react";

import {KedoStaffRegistryCardDataBlock} from "../data-block";

import {useKedoStaffRegistryValues} from "../../hooks/useValues";

import {isLastElementInArray} from "../../../../../utils/objectHelper";

export const KedoStaffRegistryCardView = () => {
    const {
        fields,
    } = useKedoStaffRegistryValues();

    const getInfo = () => {
        return fields.map((item, index) => {
            const isLast = isLastElementInArray(index, fields);

            return (
                <KedoStaffRegistryCardDataBlock
                    key={item.header}
                    header={item.header}
                    items={item.items}
                    className={isLast ? "" : "mb-3 mb-md-4"}
                />
            );
        });
    };

    return getInfo();
};