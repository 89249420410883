import React from "react";
import {withTranslation} from "react-i18next";

import {ReactComponent as ArchiveIcon} from "../../images/archive.svg";
import {ReactComponent as ListIcon} from "../../images/list.svg";
import NmButton from "../NmButton";

import PropTypes from "prop-types";

import "./style.sass";

const ButtonArchive = props => {
    const {
        t,
        onClick,
        className = "",
        archivedFilter,
        disabled,
    } = props;

    const handleClick = () => {
        if (disabled) {
            return;
        }

        onClick();
    };

    return (
        <NmButton
            color="grey"
            size="xl"
            disabled={disabled}
            className={`nm-button-archive ${className}`}
            onClick={handleClick}
            icon={archivedFilter ? <ListIcon /> : <ArchiveIcon />}
        >
            {
                archivedFilter ?
                    t("order-list.list") :
                    t("order-list.archive")
            }
        </NmButton>
    );
};

ButtonArchive.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    archivedFilter: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default withTranslation()(ButtonArchive);