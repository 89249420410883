export const REFRESH_TOKEN_KEY = "refreshToken";
export const ACCESS_TOKEN_KEY = "accessToken";
export const USER_ID = "userGuid";
export const USER_LOGIN = "login";
export const USER_ROLE = "role";
export const CURRENT_CLIENT_ID = "currentClientId";
export const CURRENT_CLIENT_USER_ID = "currentClientUserId";
export const IS_NEW_USER = "isNewUser";
export const ORDER_PAGE_KEY = "orderPage";
export const ORDER_FILTER_KEY = "filter";
export const ORDER_PAGE_DATA_KEY = "pageData";
export const ORDER_CLIENT_ID = "orderClientId";
export const PREV_PATH__KEY = "prevPath";
export const PREV_PATHNAME = "PREV_PATHNAME";
export const PREV_PATH = "PREV_PATH";

export function ls(key, value) {
    let _value = value;

    if (typeof value === "object") {
        _value = JSON.stringify(_value);
    }

    if (arguments.length === 2) {
        localStorage.setItem(key, _value);
    } else {
        return localStorage.getItem(key);
    }
}

export function setOrderPageDataToLs(data) {
    const currentOrderDataLs = JSON.parse(ls(ORDER_PAGE_KEY));

    ls(ORDER_PAGE_KEY, {
        ...currentOrderDataLs,
        ...data,
    });
}

export function setPrevPathToLs(prevPath) {
    ls(PREV_PATH__KEY, prevPath);
}


