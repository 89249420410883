import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";

import {usePagination} from "../../../../../hooks/usePagination";
import {useKedoStaffSignatureTransactionsFetch} from "../../hooks/useSignatureTransactionsFetch";
import {useKedoStaffSignatureTransactionsFilter} from "../../hooks/useSignatureTransactionsFilter";

import {formatLocalDate} from "../../../../../utils/dateFormat";

import {
    getKedoDocumentsCreatedViaTypes,
    getKedoOfficeTypes,
    getKedoSignaturesTypes, getKedoSignatureTransactionsActionTypes, getKedoSignatureTransactionsDecideTypes,
} from "../../../../../ducks/bff/common/dicts/actionCreators";
import {downloadDocument} from "../../../../../ducks/documents";
import {
    bffKedoStaffProgressSignatureTransactionsSelector,
    bffKedoStaffSignatureTransactionsListSelector,
    bffKedoStaffSignatureTransactionsTotalCountSelector,
    bffKedoStaffSignatureTransactionsTotalPageSelector,
} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffSignatureTransactions = (props) => {
    const {
        kedoStaffId,
    } = props.match.params;

    const list = useSelector(bffKedoStaffSignatureTransactionsListSelector);
    const progress = useSelector(bffKedoStaffProgressSignatureTransactionsSelector);
    const totalCount = useSelector(bffKedoStaffSignatureTransactionsTotalCountSelector);
    const totalPages = useSelector(bffKedoStaffSignatureTransactionsTotalPageSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        filters,
        filterData,
        onClear,
        onSearch,
    } = useKedoStaffSignatureTransactionsFilter();

    useKedoStaffSignatureTransactionsFetch({
        filterData,
        pageNum,
        pageSize,
        kedoStaffId,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getKedoOfficeTypes());
        dispatch(getKedoSignaturesTypes());
        dispatch(getKedoDocumentsCreatedViaTypes());
        dispatch(getKedoSignatureTransactionsActionTypes());
        dispatch(getKedoSignatureTransactionsDecideTypes());
    }, []);

    const download = (link) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `${window.location.origin}${link}`,
        }));
    };

    function getRows() {
        return list.map((item, index) => {
            return {
                contentRow: <NmListCard
                    secondaryHeader={`Дата операции ${formatLocalDate(item.createdAt, "dd.MM.yyyy HH:mm")}`}
                    key={index}
                    primaryHeaderLink
                    onClickLink={() => download(item.documentAllFilesLink)}
                    primaryHeader={`${item.documentNum} - ${item.documentName}`}
                    labels={[
                        {label: "Идентификатор события", text: item.transactionId},
                        {label: "Id документа", text: item.documentId},
                        {label: "Тип загрузки документа", text: item.documentFileCreatedViaName},
                        {label: "Наименование типа документа", text: item.documentTypeName},
                        {label: "Действие подписанта", text: item.actionTypeName},
                        item.rejectReason && {label: "Причина", text: item.rejectReason},
                        {label: "Статус", text: item.decideTypeName},
                        {label: "Тип подписи", text: item.signatureTypeName || "-"},
                        {label: "Роль пользователя", text: item.kedoStaffRoleName},
                        {label: "Тип кабинета", text: item.officeTypeName},
                        {label: "IP-адрес", text: item.networkIp || "-"},
                        {label: "User Agent", text: item.deviceName || "-"},
                        {label: "Тип сети", text: item.networkType || "-"},
                        item.networkAddress && {label: "Гео", text: item.networkAddress},
                        {label: "Логин", text: item.kedoStaffLogin},
                    ]}
                />,
            };
        });
    }

    return (
        <NmPage
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            typeFilter="vertical"
            isLoaded={progress}
            totalCount={totalCount}
            openFilter
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            totalPages={totalPages}
            header={
                <NmTitle size="xl">
                    Журнал подписания документов
                </NmTitle>
            }
        >
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};