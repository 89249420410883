import {useState} from "react";

import {handleFormString, handleNumber, isNullOrWhitespace} from "../../../../utils/stringHelper";

export default function useFilter(clientId, subPage) {
    const [filterDto, setFilter] = useState({});

    function mapFilterDto(filter) {
        const {
            //пополнения и возвраты дипозита
            action,
            projectIdsFilter,
            objectIdsFilter,
            regionFilter,
            contractorFioFilter,
            fromSumMoneyFilter,
            toSumMoneyFilter,
            paymentNumberFilter,
            orderNumFilter,
            orderNameFilter,
            fromClientCommissionAmountFilter,
            toClientCommissionAmountFilter,
            reportStatusFilter,
            registryNumFilter,
            statusOfTheTransferFilter,
            sourceTypeFilter,
            specialityIds,
            addressFiasIds,
            regionsFilter,
        } = filter;

        return {
            ...filter,
            action: isNullOrWhitespace(action) ? undefined : action,
            projectIdsFilter: isNullOrWhitespace(projectIdsFilter) ? undefined : projectIdsFilter,
            specialityIds: isNullOrWhitespace(specialityIds) ? undefined : specialityIds,
            objectIdsFilter: isNullOrWhitespace(objectIdsFilter) ? undefined : objectIdsFilter,
            regionFilter: isNullOrWhitespace(regionFilter) ? undefined : regionFilter,
            contractorFioFilter: handleFormString(contractorFioFilter),
            fromSumMoneyFilter: handleNumber(fromSumMoneyFilter),
            toSumMoneyFilter: handleNumber(toSumMoneyFilter),
            paymentNumberFilter: handleFormString(paymentNumberFilter),
            orderNumFilter: handleFormString(orderNumFilter),
            registryNumFilter: handleFormString(registryNumFilter),
            orderNameFilter: handleFormString(orderNameFilter),
            reportStatusFilter: isNullOrWhitespace(reportStatusFilter) ? undefined : [reportStatusFilter],
            statusOfTheTransferFilter: isNullOrWhitespace(statusOfTheTransferFilter) ? undefined : statusOfTheTransferFilter,
            fromClientCommissionAmountFilter: handleNumber(fromClientCommissionAmountFilter),
            toClientCommissionAmountFilter: handleNumber(toClientCommissionAmountFilter),
            sourceTypeFilter: isNullOrWhitespace(sourceTypeFilter) || sourceTypeFilter === "ALL" ? undefined : sourceTypeFilter,
            addressFiasIds: isNullOrWhitespace(addressFiasIds) ? undefined : addressFiasIds,
            regionsFilter: isNullOrWhitespace(regionsFilter) ? undefined : regionsFilter,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
    };
}