import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "../fileStore";

import {getTotalPages} from "../../utils/mathHelper";
import {dictionaryToOptions} from "../../utils/objectHelper";
import request from "../../utils/postman";
import {toastError} from "../../utils/toastHelper";

import {TYPE_TICKET_DICT} from "../../constants/crm/ticket";

const controller = "/crm/ticket";
const bffController = "/adm/crm/ticket";
//*  TYPES  *//

const GET_TICKET_RICH_PAGE_REQUEST = "GET_TICKET_RICH_PAGE_REQUEST";
const GET_TICKET_RICH_PAGE_SUCCESS = "GET_TICKET_RICH_PAGE_SUCCESS";
const GET_TICKET_RICH_PAGE_ERROR = "GET_TICKET_RICH_PAGE_ERROR";

const GET_TICKET_CALL_TYPE_DICT_REQUEST = "GET_TICKET_CALL_TYPE_DICT_REQUEST";
const GET_TICKET_CALL_TYPE_DICT_ERROR = "GET_TICKET_CALL_TYPE_DICT_ERROR";
const GET_TICKET_CALL_TYPE_DICT_SUCCESS = "GET_TICKET_CALL_TYPE_DICT_SUCCESS";

const GET_TICKET_CATEGORY_DICT_REQUEST = "GET_TICKET_CATEGORY_DICT_REQUEST";
const GET_TICKET_CATEGORY_DICT_ERROR = "GET_TICKET_CATEGORY_DICT_ERROR";
const GET_TICKET_CATEGORY_DICT_SUCCESS = "GET_TICKET_CATEGORY_DICT_SUCCESS";

const GET_TICKET_TYPE_DICT_REQUEST = "GET_TICKET_TYPE_DICT_REQUEST";
const GET_TICKET_TYPE_DICT_ERROR = "GET_TICKET_TYPE_DICT_ERROR";
const GET_TICKET_TYPE_DICT_SUCCESS = "GET_TICKET_TYPE_DICT_SUCCESS";

const GET_TICKET_STATUS_DICT_REQUEST = "GET_TICKET_STATUS_DICT_REQUEST";
const GET_TICKET_STATUS_DICT_ERROR = "GET_TICKET_STATUS_DICT_ERROR";
const GET_TICKET_STATUS_DICT_SUCCESS = "GET_TICKET_STATUS_DICT_SUCCESS";

const GET_TICKET_RICH_BY_ID_REQUEST = "GET_TICKET_RICH_BY_ID_REQUEST";
const GET_TICKET_RICH_BY_ID_ERROR = "GET_TICKET_RICH_BY_ID_ERROR";
const GET_TICKET_RICH_BY_ID_SUCCESS = "GET_TICKET_RICH_BY_ID_SUCCESS";

const GET_TICKET_COMMENTS_RICH_REQUEST = "GET_TICKET_COMMENTS_RICH_REQUEST";
const GET_TICKET_COMMENTS_RICH_ERROR = "GET_TICKET_COMMENTS_RICH_ERROR";
const GET_TICKET_COMMENTS_RICH_SUCCESS = "GET_TICKET_COMMENTS_RICH_SUCCESS";

const ADD_TICKET_COMMENT_REQUEST = "ADD_TICKET_COMMENT_REQUEST";
const ADD_TICKET_COMMENT_ERROR = "ADD_TICKET_COMMENT_ERROR";
const ADD_TICKET_COMMENT_SUCCESS = "ADD_TICKET_COMMENT_SUCCESS";

const UPDATE_TICKET_REQUEST = "UPDATE_TICKET_REQUEST";
const UPDATE_TICKET_ERROR = "UPDATE_TICKET_ERROR";
const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";

const CLOSE_TICKETS_REQUEST = "CLOSE_TICKETS_REQUEST";
const CLOSE_TICKETS_ERROR = "CLOSE_TICKETS_ERROR";
const CLOSE_TICKETS_SUCCESS = "CLOSE_TICKETS_SUCCESS";

const CHECK_TICKETS_BEFORE_CLOSE_REQUEST = "CHECK_TICKETS_BEFORE_CLOSE_REQUEST";
const CHECK_TICKETS_BEFORE_CLOSE_SUCCESS = "CHECK_TICKETS_BEFORE_CLOSE_SUCCESS";
const CHECK_TICKETS_BEFORE_CLOSE_ERROR = "CHECK_TICKETS_BEFORE_CLOSE_ERROR";

const LINK_CALL_TO_TICKET_REQUEST = "LINK_CALL_TO_TICKET_REQUEST";
const LINK_CALL_TO_TICKET_SUCCESS = "LINK_CALL_TO_TICKET_SUCCESS";
const LINK_CALL_TO_TICKET_ERROR = "LINK_CALL_TO_TICKET_ERROR";

const TAKE_IN_WORK_TICKET_REQUEST = "TAKE_IN_WORK_TICKET_REQUEST";
const TAKE_IN_WORK_TICKET_SUCCESS = "TAKE_IN_WORK_TICKET_SUCCESS";
const TAKE_IN_WORK_TICKET_ERROR = "TAKE_IN_WORK_TICKET_ERROR";

const GET_TICKET_CALL_HISTORY_RICH_PAGE_REQUEST = "GET_TICKET_CALL_HISTORY_RICH_PAGE_REQUEST";
const GET_TICKET_CALL_HISTORY_RICH_PAGE_SUCCESS = "GET_TICKET_CALL_HISTORY_RICH_PAGE_SUCCESS";
const GET_TICKET_CALL_HISTORY_RICH_PAGE_ERROR = "GET_TICKET_CALL_HISTORY_RICH_PAGE_ERROR";

const GET_TICKET_CALL_HISTORY_TOTAL_COUNT_REQUEST = "GET_TICKET_CALL_HISTORY_TOTAL_COUNT_REQUEST";
const GET_TICKET_CALL_HISTORY_TOTAL_COUNT_SUCCESS = "GET_TICKET_CALL_HISTORY_TOTAL_COUNT_SUCCESS";
const GET_TICKET_CALL_HISTORY_TOTAL_COUNT_ERROR = "GET_TICKET_CALL_HISTORY_TOTAL_COUNT_ERROR";

const GET_TICKET_EVENT_LOG_RICH_PAGE_REQUEST = "GET_TICKET_EVENT_LOG_RICH_PAGE_REQUEST";
const GET_TICKET_EVENT_LOG_RICH_PAGE_SUCCESS = "GET_TICKET_EVENT_LOG_RICH_PAGE_SUCCESS";
const GET_TICKET_EVENT_LOG_RICH_PAGE_ERROR = "GET_TICKET_EVENT_LOG_RICH_PAGE_ERROR";

const CHANGE_TICKET_MANAGER_REQUEST = "CHANGE_TICKET_MANAGER_REQUEST";
const CHANGE_TICKET_MANAGER_SUCCESS = "CHANGE_TICKET_MANAGER_SUCCESS";
const CHANGE_TICKET_MANAGER_ERROR = "CHANGE_TICKET_MANAGER_ERROR";

const MULTIPLE_CHANGE_TICKET_MANAGER_REQUEST = "MULTIPLE_CHANGE_TICKET_MANAGER_REQUEST";
const MULTIPLE_CHANGE_TICKET_MANAGER_SUCCESS = "MULTIPLE_CHANGE_TICKET_MANAGER_SUCCESS";
const MULTIPLE_CHANGE_TICKET_MANAGER_ERROR = "MULTIPLE_CHANGE_TICKET_MANAGER_ERROR";

const INIT_CALL_TICKET_MANAGER_REQUEST = "INIT_CALL_TICKET_MANAGER_REQUEST";
const INIT_CALL_TICKET_MANAGER_SUCCESS = "INIT_CALL_TICKET_MANAGER_SUCCESS";
const INIT_CALL_TICKET_MANAGER_ERROR = "INIT_CALL_TICKET_MANAGER_ERROR";

const REOPEN_TICKET_REQUEST = "REOPEN_TICKET_REQUEST";
const REOPEN_TICKET_SUCCESS = "REOPEN_TICKET_SUCCESS";
const REOPEN_TICKET_ERROR = "REOPEN_TICKET_ERROR";

const GET_TICKET_TOTAL_COUNT_REQUEST = "GET_TICKET_TOTAL_COUNT_REQUEST";
const GET_TICKET_TOTAL_COUNT_SUCCESS = "GET_TICKET_TOTAL_COUNT_SUCCESS";
const GET_TICKET_TOTAL_COUNT_ERROR = "GET_TICKET_TOTAL_COUNT_ERROR";

const GET_TICKET_COUNTS_REQUEST = "GET_TICKET_COUNTS_REQUEST";
const GET_TICKET_COUNTS_SUCCESS = "GET_TICKET_COUNTS_SUCCESS";
const GET_TICKET_COUNTS_ERROR = "GET_TICKET_COUNTS_ERROR";

const ADD_CONTRACTOR_TICKET_REQUEST = "ADD_CONTRACTOR_TICKET_REQUEST";
const ADD_CONTRACTOR_TICKET_SUCCESS = "ADD_CONTRACTOR_TICKET_SUCCESS";
const ADD_CONTRACTOR_TICKET_ERROR = "ADD_CONTRACTOR_TICKET_ERROR";

const HAS_CONTRACTOR_TICKET_IN_WORK_REQUEST = "HAS_CONTRACTOR_TICKET_IN_WORK_REQUEST";
const HAS_CONTRACTOR_TICKET_IN_WORK_SUCCESS = "HAS_CONTRACTOR_TICKET_IN_WORK_SUCCESS";
const HAS_CONTRACTOR_TICKET_IN_WORK_ERROR = "HAS_CONTRACTOR_TICKET_IN_WORK_ERROR";

const GET_TICKET_UNATTACHED_CALL_HISTORY_RICH_PAGE_SUCCESS = "GET_TICKET_UNATTACHED_CALL_HISTORY_RICH_PAGE_SUCCESS";

const UPDATE_TICKET_STORE = "UPDATE_TICKET_STORE";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    pageData: {},
    categoryDict: {},
    callTypeDict: {},
    typeDict: {},
    statusDict: {},
    card: {},
    comments: [],
    commentsPageData: {},
    commentsTotalCount: 0,
    callHistoryPageData: {},
    callHistoryList: [],
    callHistoryTotalCount: 0,
    eventLogTotalCount: 0,
    eventLogPageData: {},
    eventLogList: [],
    ticketHistoryTotalCount: 0,
    unattachedCallList: [],
    unattachedCallListTotal: 0,
    unattachedCallTabTotalCount: 0,
    reopenProgress: false,
    ticketCounts: {},
    ticketRegistryFilter: {},
    progressChangeOperator: false,
    progressAction: false,
    hasContractorTicketsInWork: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_TICKET_RICH_PAGE_REQUEST:
        return {
            ...state,
            pageData: {
                pageSize: payload.pageSize,
                pageNum: payload.pageNum,
            },
            progress: true,
        };
    case GET_TICKET_CALL_HISTORY_RICH_PAGE_REQUEST:
        return {
            ...state,
            callHistoryPageData: {
                pageSize: payload.pageSize,
                pageNum: payload.pageNum,
            },
        };
    case GET_TICKET_EVENT_LOG_RICH_PAGE_REQUEST:
        return {
            ...state,
            eventLogPageData: {
                pageSize: payload.pageSize,
                pageNum: payload.pageNum,
            },
        };
    case GET_TICKET_CALL_HISTORY_RICH_PAGE_SUCCESS:
        return {
            ...state,
            callHistoryList: payload.results,
            callHistoryTotalCount: payload.totalCount,
        };
    case GET_TICKET_CALL_HISTORY_TOTAL_COUNT_SUCCESS:
        return {
            ...state,
            unattachedCallTabTotalCount: payload,
        };
    case GET_TICKET_UNATTACHED_CALL_HISTORY_RICH_PAGE_SUCCESS:
        return {
            ...state,
            unattachedCallList: payload.results,
            unattachedCallListTotal: payload.totalCount,
        };
    case GET_TICKET_EVENT_LOG_RICH_PAGE_SUCCESS:
        return {
            ...state,
            eventLogList: payload.results,
            eventLogTotalCount: payload.totalCount,
        };
    case GET_TICKET_COMMENTS_RICH_REQUEST:
        return {
            ...state,
            commentsPageData: {
                pageSize: payload.pageSize,
                pageNum: payload.pageNum,
            },
        };
    case REOPEN_TICKET_REQUEST:
        return {
            ...state,
            reopenProgress: true,
        };
    case GET_TICKET_TOTAL_COUNT_SUCCESS:
        return {
            ...state,
            ticketHistoryTotalCount: payload,
        };
    case GET_TICKET_RICH_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    case GET_TICKET_RICH_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    case GET_TICKET_RICH_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
        };
    case GET_TICKET_CATEGORY_DICT_SUCCESS:
        return {
            ...state,
            categoryDict: payload,
        };
    case GET_TICKET_COMMENTS_RICH_SUCCESS:
        return {
            ...state,
            comments: payload.results,
            commentsTotalCount: payload.totalCount,
        };
    case GET_TICKET_CALL_TYPE_DICT_SUCCESS:
        return {
            ...state,
            callTypeDict: payload,
        };
    case GET_TICKET_TYPE_DICT_SUCCESS:
        return {
            ...state,
            typeDict: payload,
        };
    case GET_TICKET_STATUS_DICT_SUCCESS:
        return {
            ...state,
            statusDict: payload,
        };
    case REOPEN_TICKET_SUCCESS:
        return {
            ...state,
            reopenProgress: false,
        };
    case GET_TICKET_COUNTS_SUCCESS:
        return {
            ...state,
            ticketCounts: payload,
        };
    case REOPEN_TICKET_ERROR:
        return {
            ...state,
            reopenProgress: false,
        };
    case CHANGE_TICKET_MANAGER_REQUEST:
    case MULTIPLE_CHANGE_TICKET_MANAGER_REQUEST:
        return {
            ...state,
            progressChangeOperator: true,
        };
    case CHANGE_TICKET_MANAGER_SUCCESS:
    case MULTIPLE_CHANGE_TICKET_MANAGER_SUCCESS:
    case CHANGE_TICKET_MANAGER_ERROR:
    case MULTIPLE_CHANGE_TICKET_MANAGER_ERROR:
        return {
            ...state,
            progressChangeOperator: false,
        };
    case ADD_CONTRACTOR_TICKET_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case ADD_CONTRACTOR_TICKET_SUCCESS:
    case ADD_CONTRACTOR_TICKET_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case HAS_CONTRACTOR_TICKET_IN_WORK_SUCCESS:
        return {
            ...state,
            hasContractorTicketsInWork: payload,
        };
    case UPDATE_TICKET_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getTicketRichPage(payload) {
    return {
        type: GET_TICKET_RICH_PAGE_REQUEST,
        payload,
    };
}

export function getTicketCallTypeDict() {
    return {
        type: GET_TICKET_CALL_TYPE_DICT_REQUEST,
    };
}

export function getTicketCategoryDict() {
    return {
        type: GET_TICKET_CATEGORY_DICT_REQUEST,
    };
}

export function getTicketTypeDict() {
    return {
        type: GET_TICKET_TYPE_DICT_REQUEST,
    };
}

export function linkCallToTicket(payload) {
    return {
        type: LINK_CALL_TO_TICKET_REQUEST,
        payload,
    };
}

export function getTicketStatusDict() {
    return {
        type: GET_TICKET_STATUS_DICT_REQUEST,
    };
}

export function getTicketRichById(payload) {
    return {
        type: GET_TICKET_RICH_BY_ID_REQUEST,
        payload,
    };
}

export function getTicketCommentsRich(payload) {
    return {
        type: GET_TICKET_COMMENTS_RICH_REQUEST,
        payload,
    };
}

export function addTicketComment(payload) {
    return {
        type: ADD_TICKET_COMMENT_REQUEST,
        payload,
    };
}

export function updateTicket(payload) {
    return {
        type: UPDATE_TICKET_REQUEST,
        payload,
    };
}

export function closeTickets(payload) {
    return {
        type: CLOSE_TICKETS_REQUEST,
        payload,
    };
}

export function checkTicketsBeforeClose(payload) {
    return {
        type: CHECK_TICKETS_BEFORE_CLOSE_REQUEST,
        payload,
    };
}

export function takeInWorkTicket(payload) {
    return {
        type: TAKE_IN_WORK_TICKET_REQUEST,
        payload,
    };
}

export function getTicketCallHistoryRichPage(payload) {
    return {
        type: GET_TICKET_CALL_HISTORY_RICH_PAGE_REQUEST,
        payload,
    };
}

export function getTicketCallHistoryTotalCount(payload) {
    return {
        type: GET_TICKET_CALL_HISTORY_TOTAL_COUNT_REQUEST,
        payload,
    };
}

export function updateTicketStore(payload = {}) {
    return {
        type: UPDATE_TICKET_STORE,
        payload,
    };
}

export function getTicketEventLogRichPage(payload) {
    return {
        type: GET_TICKET_EVENT_LOG_RICH_PAGE_REQUEST,
        payload,
    };
}

export function changeTicketManager(payload) {
    return {
        type: CHANGE_TICKET_MANAGER_REQUEST,
        payload,
    };
}

export function multipleChangeTicketManager(payload) {
    return {
        type: MULTIPLE_CHANGE_TICKET_MANAGER_REQUEST,
        payload,
    };
}

export function initCallTicketManager(payload) {
    return {
        type: INIT_CALL_TICKET_MANAGER_REQUEST,
        payload,
    };
}

export function reopenTicket(payload) {
    return {
        type: REOPEN_TICKET_REQUEST,
        payload,
    };
}

export function getTicketTotalCount(payload) {
    return {
        type: GET_TICKET_TOTAL_COUNT_REQUEST,
        payload,
    };
}

export function getTicketCounts(payload) {
    return {
        type: GET_TICKET_COUNTS_REQUEST,
        payload,
    };
}

export function addContractorTicket(payload) {
    return {
        type: ADD_CONTRACTOR_TICKET_REQUEST,
        payload,
    };
}

export function hasContractorTicketInWork(payload) {
    return {
        type: HAS_CONTRACTOR_TICKET_IN_WORK_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const ticketSelector = ({ticket}) => ticket;
export const ticketTotalPagesSelector = createSelector(ticketSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const ticketOptionsSelector = createSelector(ticketSelector, ({list}) => list.map(({
    baseModel,
}) => {
    return {
        key: baseModel.ticketId,
        text: `Обращение №${baseModel.orderNumber} - ${baseModel.type ? TYPE_TICKET_DICT[baseModel.type] : "Не определено"}`,
        value: baseModel.ticketId,
    };
}));
export const ticketListSelector = createSelector(ticketSelector, ({list}) => list);
export const ticketProgressListSelector = createSelector(ticketSelector, ({progress}) => progress);
export const ticketTotalCountSelector = createSelector(ticketSelector, ({totalCount}) => totalCount);
export const ticketCategoryDictSelector = createSelector(ticketSelector, ({categoryDict}) => categoryDict);
export const ticketCallTypeDictSelector = createSelector(ticketSelector, ({callTypeDict}) => callTypeDict);
export const ticketTypeDictSelector = createSelector(ticketSelector, ({typeDict}) => typeDict);
export const ticketStatusDictSelector = createSelector(ticketSelector, ({statusDict}) => statusDict);
export const ticketCategoryOptionsSelector = createSelector(ticketSelector, ({categoryDict}) => dictionaryToOptions(categoryDict));
export const ticketCallTypeOptionsSelector = createSelector(ticketSelector, ({callTypeDict}) => dictionaryToOptions(callTypeDict, true));
export const ticketTypeOptionsSelector = createSelector(ticketSelector, ({typeDict}) => dictionaryToOptions(typeDict));
export const ticketCardSelector = createSelector(ticketSelector, ({card}) => card);

export const ticketCommentsSelector = createSelector(ticketSelector, ({comments}) => comments);
export const ticketCommentsCountSelector = createSelector(ticketSelector, ({commentsTotalCount}) => commentsTotalCount);
export const ticketCommentsTotalPagesSelector = createSelector(ticketSelector, ({
    commentsTotalCount,
    commentsPageData: {pageSize = 0},
}) => getTotalPages(commentsTotalCount, pageSize));

export const ticketCallHistoryListSelector = createSelector(ticketSelector, ({callHistoryList}) => callHistoryList);
export const ticketCallHistoryTotalCountSelector = createSelector(ticketSelector, ({callHistoryTotalCount}) => callHistoryTotalCount);
export const ticketCallHistoryTotalPagesSelector = createSelector(ticketSelector, ({
    callHistoryTotalCount,
    callHistoryPageData: {pageSize = 0},
}) => getTotalPages(callHistoryTotalCount, pageSize));


export const ticketUnattachedCallHistoryListSelector = createSelector(ticketSelector, ({unattachedCallList}) => unattachedCallList);
export const ticketUnattachedCallHistoryTotalCountSelector = createSelector(ticketSelector, ({unattachedCallListTotal}) => unattachedCallListTotal);
export const ticketUnattachedCallHistoryTotalPagesSelector = createSelector(ticketSelector, ({
    unattachedCallListTotal,
    callHistoryPageData: {pageSize = 0},
}) => getTotalPages(unattachedCallListTotal, pageSize));

export const ticketEventLogListSelector = createSelector(ticketSelector, ({eventLogList}) => eventLogList);
export const ticketEventLogTotalCountSelector = createSelector(ticketSelector, ({eventLogTotalCount}) => eventLogTotalCount);
export const ticketEventLogTotalPagesSelector = createSelector(ticketSelector, ({
    eventLogTotalCount,
    eventLogPageData: {pageSize = 0},
}) => getTotalPages(eventLogTotalCount, pageSize));

export const ticketHistoryTotalCountSelector = createSelector(ticketSelector, ({ticketHistoryTotalCount}) => ticketHistoryTotalCount);
export const unattachedCallTabTotalCountSelector = createSelector(ticketSelector, ({unattachedCallTabTotalCount}) => unattachedCallTabTotalCount);
export const reopenProgressSelector = createSelector(ticketSelector, ({reopenProgress}) => reopenProgress);
export const ticketCountsSelector = createSelector(ticketSelector, ({ticketCounts}) => ticketCounts);
export const registryFilterByTicketSelector = createSelector(ticketSelector, ({ticketRegistryFilter}) => ticketRegistryFilter);
export const ticketProgressChangeOperatorSelector = createSelector(ticketSelector, ({progressChangeOperator}) => progressChangeOperator);
export const ticketProgressActionSelector = createSelector(ticketSelector, ({progressAction}) => progressAction);
export const hasContractorTicketsInWorkSelector = createSelector(ticketSelector, ({hasContractorTicketsInWork}) => hasContractorTicketsInWork);

//*  SAGA  *//

//POST /bff/adm/crm/ticket/page
// Получение обогащенной страницы с обращениями
export const getTicketRichPageSaga = function* (action) {
    try {
        const {
            payload: {
                isFetchAvatars = false,
                getResult,
                ...data
            },
        } = action;
        const result = yield request.bff.post(`${bffController}/page`, data);
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_RICH_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {results} = result;
        if (isFetchAvatars && results?.length) {
            const contractorIds = results.map(({baseModel: {contractorId}}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: GET_TICKET_RICH_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_RICH_PAGE_ERROR, payload: error});
    }
};

//GET
// /api/ticket/ticketCallTypeMap
// словарь тип вызова
export const getTicketCallTypeDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/ticketCallTypeMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_CALL_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_CALL_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_CALL_TYPE_DICT_ERROR, payload: error});
    }
};

//GET
// /api/ticket/ticketCategoryMap
// словарь категория обращения
export const getTicketCategoryDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/ticketCategoryMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_CATEGORY_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_CATEGORY_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_CATEGORY_DICT_ERROR, payload: error});
    }
};

//GET
// /api/ticket/ticketTypeMap
// словарь тип обращения
export const getTicketTypeDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/ticketTypeMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_TYPE_DICT_ERROR, payload: error});
    }
};

//GET
// /api/crm/ticket/ticketStatusMap
// словарь тип обращения
export const getTicketStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/ticketStatusMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_STATUS_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_STATUS_DICT_ERROR, payload: error});
    }
};

//GET
// /api/crm/ticket/getRichById
// получение обогащенного обращения
export const getTicketRichByIdSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...params
            },
        } = action;
        const result = yield request.get(`${controller}/getRichById`, {params});
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_RICH_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_RICH_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_RICH_BY_ID_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/comment/getRichPage

export const getTicketCommentsRichSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/comment/getRichPage`, data);
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_COMMENTS_RICH_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_COMMENTS_RICH_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_COMMENTS_RICH_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/comment/add

export const addTicketCommentSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/comment/add`, data);
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_TICKET_COMMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ADD_TICKET_COMMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_TICKET_COMMENT_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/update

export const updateTicketSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/update`, data);
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_TICKET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: UPDATE_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_TICKET_ERROR, payload: error});
    }
};

//POST /bff/adm/crm/ticket/close-all
// Закрытие обращений
export const closeTicketsSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.bff.post(`${bffController}/close-all`, data);
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLOSE_TICKETS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CLOSE_TICKETS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CLOSE_TICKETS_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/checkBeforeCloseAll
export const checkTicketsBeforeCloseSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/checkBeforeCloseAll`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_TICKETS_BEFORE_CLOSE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CHECK_TICKETS_BEFORE_CLOSE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHECK_TICKETS_BEFORE_CLOSE_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/inWork
// взятие обращения в работу
export const takeInWorkTicketSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/inWork`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TAKE_IN_WORK_TICKET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: TAKE_IN_WORK_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TAKE_IN_WORK_TICKET_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/call/history/getRichPage
// получение обогащенной страницы с вызовами по обращению
export const getTicketCallHistoryRichPageSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                isUnattachedPage,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/call/history/getRichPage`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_CALL_HISTORY_RICH_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: isUnattachedPage ? GET_TICKET_UNATTACHED_CALL_HISTORY_RICH_PAGE_SUCCESS : GET_TICKET_CALL_HISTORY_RICH_PAGE_SUCCESS,
            payload: {
                isUnattachedPage,
                ...result,
            },
        });

    } catch (error) {
        yield put({type: GET_TICKET_CALL_HISTORY_RICH_PAGE_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/call/history/getTotalCount
// получение счетчика обогащенной страницы с вызовами по обращению
export const getTicketCallHistoryTotalCountSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                isUnattachedPage,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/call/history/getTotalCount`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_CALL_HISTORY_TOTAL_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_TICKET_CALL_HISTORY_TOTAL_COUNT_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: GET_TICKET_CALL_HISTORY_TOTAL_COUNT_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/event/log/getRichPage
// получение обогащенной страницы с вызовами по обращению
export const getTicketEventLogRichPageSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/event/log/getRichPage`, data);
        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_EVENT_LOG_RICH_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_EVENT_LOG_RICH_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_EVENT_LOG_RICH_PAGE_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/changeManager
// перевод на другого оператора
export const changeTicketManagerSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/changeManager`, data);
        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_TICKET_MANAGER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CHANGE_TICKET_MANAGER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_TICKET_MANAGER_ERROR, payload: error});
    }
};

//POST /bff/adm/crm/ticket/multiple-change-manager
// Множественный перевод на другого оператора
export const multipleChangeTicketManagerSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${bffController}/multiple-change-manager`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MULTIPLE_CHANGE_TICKET_MANAGER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: MULTIPLE_CHANGE_TICKET_MANAGER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: MULTIPLE_CHANGE_TICKET_MANAGER_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/call/init
// инициирование звонка сотрудником наймикс

export const initCallTicketSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/call/init`, data);
        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: INIT_CALL_TICKET_MANAGER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: INIT_CALL_TICKET_MANAGER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: INIT_CALL_TICKET_MANAGER_ERROR, payload: error});
    }
};

//POST
// /api/crm/ticket/reopen
// переоткрытие обращения

export const reopenTicketSaga = function* (action) {
    try {
        const {
            payload: {
                getResult = () => {
                },
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/reopen`, data);
        const {errorMessage} = result;

        getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REOPEN_TICKET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REOPEN_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REOPEN_TICKET_ERROR, payload: error});
    }
};


//POST
// /api/crm/ticket/getTotalCount
// получение кол-ва обращений

export const getTicketTotalCountSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/getTotalCount`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_TOTAL_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_TOTAL_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_TOTAL_COUNT_ERROR, payload: error});
    }
};

///POST
// /api/crm/ticket/call/history/linkCallToTicket
// Привязать вызов к тикету

export const linkCallToTicketSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/call/history/linkCallToTicket`, {}, {params: data});
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: LINK_CALL_TO_TICKET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: LINK_CALL_TO_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: LINK_CALL_TO_TICKET_ERROR, payload: error});
    }
};

//POST /bff/adm/crm/ticket/count
// Кол-во обращений
export const getTicketCountsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${bffController}/count`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_TICKET_COUNTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_TICKET_COUNTS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_TICKET_COUNTS_ERROR, payload: error});
    }
};

//POST /api/crm/ticket/addTicketByNaimix
// добавление обращения со стороны юзеров наймикса
export const addContractorTicketSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/addTicketByNaimix`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CONTRACTOR_TICKET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({type: ADD_CONTRACTOR_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_TICKET_ERROR, payload: error});
    }
};

//GET /api/crm/ticket/hasNotClosedChatTickets
// проверка наличия обращения в работе у исполнителя
export const hasContractorTicketInWorkSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/hasNotClosedChatTickets`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: HAS_CONTRACTOR_TICKET_IN_WORK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: HAS_CONTRACTOR_TICKET_IN_WORK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: HAS_CONTRACTOR_TICKET_IN_WORK_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_TICKET_RICH_PAGE_REQUEST, getTicketRichPageSaga),
        takeEvery(GET_TICKET_CALL_TYPE_DICT_REQUEST, getTicketCallTypeDictSaga),
        takeEvery(GET_TICKET_CATEGORY_DICT_REQUEST, getTicketCategoryDictSaga),
        takeEvery(GET_TICKET_TYPE_DICT_REQUEST, getTicketTypeDictSaga),
        takeEvery(GET_TICKET_STATUS_DICT_REQUEST, getTicketStatusDictSaga),
        takeEvery(GET_TICKET_RICH_BY_ID_REQUEST, getTicketRichByIdSaga),
        takeEvery(GET_TICKET_COMMENTS_RICH_REQUEST, getTicketCommentsRichSaga),
        takeEvery(ADD_TICKET_COMMENT_REQUEST, addTicketCommentSaga),
        takeEvery(UPDATE_TICKET_REQUEST, updateTicketSaga),
        takeEvery(CLOSE_TICKETS_REQUEST, closeTicketsSaga),
        takeEvery(CHECK_TICKETS_BEFORE_CLOSE_REQUEST, checkTicketsBeforeCloseSaga),
        takeEvery(TAKE_IN_WORK_TICKET_REQUEST, takeInWorkTicketSaga),
        takeEvery(GET_TICKET_CALL_HISTORY_RICH_PAGE_REQUEST, getTicketCallHistoryRichPageSaga),
        takeEvery(GET_TICKET_EVENT_LOG_RICH_PAGE_REQUEST, getTicketEventLogRichPageSaga),
        takeEvery(CHANGE_TICKET_MANAGER_REQUEST, changeTicketManagerSaga),
        takeEvery(MULTIPLE_CHANGE_TICKET_MANAGER_REQUEST, multipleChangeTicketManagerSaga),
        takeEvery(INIT_CALL_TICKET_MANAGER_REQUEST, initCallTicketSaga),
        takeEvery(REOPEN_TICKET_REQUEST, reopenTicketSaga),
        takeEvery(GET_TICKET_TOTAL_COUNT_REQUEST, getTicketTotalCountSaga),
        takeEvery(LINK_CALL_TO_TICKET_REQUEST, linkCallToTicketSaga),
        takeEvery(GET_TICKET_CALL_HISTORY_TOTAL_COUNT_REQUEST, getTicketCallHistoryTotalCountSaga),
        takeEvery(GET_TICKET_COUNTS_REQUEST, getTicketCountsSaga),
        takeEvery(ADD_CONTRACTOR_TICKET_REQUEST, addContractorTicketSaga),
        takeEvery(HAS_CONTRACTOR_TICKET_IN_WORK_REQUEST, hasContractorTicketInWorkSaga),
    ]);
}
