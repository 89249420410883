import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmPage from "../../../../components/NmPage";
import {history} from "../../../../store/configureStore";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {handleDateFieldFilter, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {LINK_CLIENT_MEMBERS_CARD, LINK_CONTRACTOR_CARD, LINK_SETTINGS_MEMBERS_CARD} from "../../../../constants/links";
import {NM_COORDINATOR, NM_MANAGER} from "../../../../constants/roles";

import {
    clientListOptimizedOptionsSelector,
    getOptimizedClientList,
} from "../../../../ducks/client";
import {
    getActionSectionTypeDict,
    getActionTypeDict,
    getEvents,
    logsDictOptionsSelector,
    logsDictValueSelector,
    logsEventsSelector,
    logsEventTypeOptionsSelector,
    logsGetPageProgressSelector, logsTotalCountSelector,
    logsTotalPagesSelector,
    updateFieldStore,
} from "../../../../ducks/logs";

import PropTypes from "prop-types";

import "./style.sass";

class EventLogList extends Component {
    static propTypes = {
        header: PropTypes.node,
        tabs: PropTypes.node,
        events: PropTypes.array,
        totalPages: PropTypes.number,
        clientListForFilter: PropTypes.array,
        value: PropTypes.array,
        options: PropTypes.array,
        progress: PropTypes.bool,
        getEvents: PropTypes.array,
        getOptimizedClientList: PropTypes.array,
        getActionSectionTypeDict: PropTypes.array,
    };

    static defaultProps = {
        events: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            pageSizes: [25, 50, 100],
            pageNum: 1,
            pageSize: 25,
            sortType: undefined,
            clientIdsFilter: "",
            fromDateFilterfromDate: null,
            toDate: null,
            specialty: {},
            sections: [],
            clientNameFilter: undefined,
        };

        this.role = ls(USER_ROLE);
        this.clientId = "00000000-0000-0000-0000-000000000000";
        this.isEditable = this.role !== NM_COORDINATOR && this.role !== NM_MANAGER;
    }

    componentDidMount() {
        this.fetchDict();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {sections} = prevState;
        const {sections: newSections} = this.state;
        const {value: oldValue} = prevProps;
        const {value} = this.props;

        if (sections.length === 0 && JSON.stringify(value) !== JSON.stringify(oldValue)) {
            this.fetchEvents();
        }

        if (!sections.find(value => value === "CLIENT") && newSections.find(value => value === "CLIENT")) {
            this.fetchClientListForFilter();
        }

        if (sections.find(value => value === "CLIENT") && !newSections.find(value => value === "CLIENT")) {
            this.setState({
                clientIdsFilter: "",
            });
        }
    }

    componentWillUnmount() {
        const {updateFieldStore} = this.props;

        updateFieldStore({events: [], dict: {}});
    }

    clearFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            toDateFilter: "",
            fromDateFilter: "",
            sections: [],
            clientIdsFilter: "",
            eventTypes: [],
        }), this.fetchEvents);
    };

    fetchClientListForFilter = () => {
        const {getOptimizedClientList} = this.props;
        const {clientNameFilter} = this.state;

        getOptimizedClientList({
            archivedFilter: false,
            pageNum: 1,
            pageSize: 25,
            nameSubstringFilter: isNullOrWhitespace(clientNameFilter) ? null : clientNameFilter.trim(),
        });
    };

    fetchDict = () => {
        const {
            getActionSectionTypeDict,
            getActionTypeDict,
        } = this.props;

        getActionSectionTypeDict();
        getActionTypeDict();
    };

    fetchEvents = () => {
        const {
            getEvents,
            value,
        } = this.props;

        const {
            pageSize,
            pageNum,
            fromDateFilter,
            toDateFilter,
            sections,
            clientIdsFilter,
            eventTypes,
        } = this.state;

        getEvents({
            clientId: clientIdsFilter,
            sortType: "desc",
            sections: sections.length ? sections : value,
            pageSize,
            pageNum,
            eventTypes: eventTypes && eventTypes.length ? eventTypes : undefined,
            fromDate: handleDateFieldFilter(fromDateFilter),
            toDate: handleDateFieldFilter(toDateFilter),
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchEvents,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchEvents,
        );
    };

    onSearchChange = (clientNameFilter) => {
        this.setState({clientNameFilter}, this.fetchClientListForFilter);
    };

    onChangeFilter = (e, {value: clientIdsFilter}) => {
        this.setState(prevState => ({
            ...prevState,
            clientIdsFilter,
            pageNum: 1,
        }), this.fetchEvents);
    };

    renderName = ({lastName, firstName, patronymic}) => {
        if (!lastName) {
            return null;
        }

        if (!patronymic) {
            return `${lastName} ${firstName}`;
        }

        return `${lastName} ${firstName} ${patronymic}`;
    };

    renderInitiator(event) {
        const {
            clientId,
            clientUserModel,
            contractorModel,
        } = event;

        if (clientUserModel === null && contractorModel === null) {
            return "Не определено";
        }

        const initiator = clientUserModel || contractorModel || {};
        const content = this.renderName(initiator);
        const className = this.isEditable ? "settings-log-list__fullname" : "settings-log-list";

        if (clientUserModel) {
            const onClick = () => {
                if (this.isEditable) {
                    this.onClickEditRow(clientUserModel, clientId);
                }
            };

            return (
                <div
                    className={className}
                    onClick={onClick}
                >
                    {content}
                </div>
            );
        }

        const {contractorId} = contractorModel;
        const to = LINK_CONTRACTOR_CARD.replace(":contractorId", contractorId);

        return (
            <ExtLink
                to={to}
                historyEnabled
            >
                {content}
            </ExtLink>
        );
    }

    getRows = () => {
        const {events} = this.props;

        return events.map(item => {
            const {
                dateTime,
                actionModel: {
                    actionTypeStr,
                    actionSectionTypeStr,
                },
                messages = [],
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={`Дата и время: ${formatDate(convertUtcToLocal(dateTime), "dd.MM.yyyy HH:mm:ss")}`}
                        primaryHeader={actionTypeStr}
                        classNameMainContent="col-16"
                        labels={[
                            {label: "Раздел", text: actionSectionTypeStr || "Не определено"},
                            {label: "Инициатор", text: this.renderInitiator(item)},
                            {
                                label: "Событие",
                                text: messages.length ?
                                    <NmShowMoreText
                                        anchor="blue"
                                        lines={3}
                                        children={messages.join("; ")}
                                        more="Подробнее"
                                    /> : "-",
                                columnOnMobile: true,
                                columnOnTablet: true,
                                flexWrap: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    onClickEditRow = (clientContractor, clientId) => {
        if (clientId === "00000000-0000-0000-0000-000000000000") {
            history.push(LINK_SETTINGS_MEMBERS_CARD
                .replace(":clientContractorId", clientContractor.clientUserId));
        } else {
            history.push(LINK_CLIENT_MEMBERS_CARD
                .replace(":clientId", clientId)
                .replace(":clientContractorId", clientContractor.clientUserId));
        }
    };

    handleOnChangeDate({name, date}) {
        this.setState({
            [name]: date,
        });
    }

    handleChange = (event, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    submitAdd = () => {
        this.fetchEvents();
    };

    handleOnChangeDatepicker = (value, name) => {
        const _value = isNullOrWhitespace(value) ? undefined : formatDate(value, "yyyy-MM-dd");

        this.setState({
            [name]: _value,
        });
    };

    renderFilter = () => {
        const {
            clientListForFilter,
            options,
            eventTypeOptions,
            t,
        } = this.props;

        const {
            clientIdsFilter,
            fromDateFilter,
            toDateFilter,
            sections,
            eventTypes,
        } = this.state;

        return (
            <NmForm addMargin>
                <NmDateRangePickerV2
                    size="lg"
                    label="Период"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{fromDateFilter, toDateFilter}}
                    isClearable={true}
                    handleOnChangeRangeDatepicker={this.handleOnChangeDatepicker}
                />
                <NmDropdownV2
                    multiple
                    search
                    size="lg"
                    options={options}
                    value={sections}
                    label={t("settings-logs-list.section-filter-name")}
                    onChange={this.handleChange}
                    name="sections"
                    onFilterSearchChange={this.submitAdd}
                />
                <NmDropdownV2
                    multiple
                    size="lg"
                    search
                    options={eventTypeOptions}
                    hideSelectedItems
                    value={eventTypes}
                    label={t("settings-logs-list.event-type")}
                    onChange={this.handleChange}
                    onFilterSearchChange={this.submitAdd}
                    name="eventTypes"
                />
                {sections.find(value => value === "CLIENT") &&
                <NmDropdownV2
                    label={t("settings-logs-list.client-filter-name")}
                    size="lg"
                    selectOnBlur={false}
                    clearable
                    compact
                    noResultsMessage={t("no-search-result.content")}
                    onSearchChange={this.onSearchChange}
                    onChange={this.onChangeFilter}
                    search
                    selection
                    value={clientIdsFilter}
                    options={clientListForFilter}
                />
                }
                <FilterButtonsV2
                    onSearch={this.submitAdd}
                    onClear={this.clearFilter}
                />
            </NmForm>);
    }

    render() {
        const {
            header,
            tabs,
            totalPages,
            totalCount,
            progress,
            events,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <NmPage
                noPadding
                header={header}
                className="settings-log-list"
                typeFilter="vertical"
                filtersBase={this.renderFilter()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalPages={totalPages}
                totalCount={totalCount}
                isLoaded={progress}
            >
                {tabs}
                {
                    events?.length ?
                        <CheckboxList
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2 />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        events: logsEventsSelector(state),
        totalPages: logsTotalPagesSelector(state),
        totalCount: logsTotalCountSelector(state),
        clientListForFilter: clientListOptimizedOptionsSelector(state),
        value: logsDictValueSelector(state),
        options: logsDictOptionsSelector(state),
        progress: logsGetPageProgressSelector(state),
        eventTypeOptions: logsEventTypeOptionsSelector(state),
    }),
    {
        getEvents,
        getOptimizedClientList,
        getActionSectionTypeDict,
        updateFieldStore,
        getActionTypeDict,
    },
)(withTranslation()(EventLogList));