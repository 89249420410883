export const RECRUITMENT_VACANCY_STATUS = {
    DRAFT: {
        VALUE: "DRAFT",
        TEXT: "Черновик",
        MOD: "white",
    },
    PUBLISHED: {
        VALUE: "PUBLISHED",
        TEXT: "В работе",
        MOD: "green",
    },
    CLOSED: {
        VALUE: "CLOSED",
        TEXT: "Закрыта",
        MOD: "gray",
    },
};

export const RECRUITMENT_VACANCY_STATUS_OPTIONS = [
    {
        key: RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE,
        text: RECRUITMENT_VACANCY_STATUS.DRAFT.TEXT,
        value: RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE,
    },
    {
        key: RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE,
        text: RECRUITMENT_VACANCY_STATUS.PUBLISHED.TEXT,
        value: RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE,
    },
    {
        key: RECRUITMENT_VACANCY_STATUS.CLOSED.VALUE,
        text: RECRUITMENT_VACANCY_STATUS.CLOSED.TEXT,
        value: RECRUITMENT_VACANCY_STATUS.CLOSED.VALUE,
    },
];

export const RECRUITMENT_SOURCE_JOB_BOARD = {
    AVITO: "AVITO",
    HEAD_HUNTER: "HEAD_HUNTER",
    SUPER_JOB: "SUPER_JOB",
    ZARPLATA_RU: "ZARPLATA_RU",
    NAIMIX: "NAIMIX",
};

export const RECRUITMENT_RESPONSE_STATUS = {
    NONE: "NONE", // Нет
    THINKING: "THINKING", // Подумать
    REJECTED: "REJECTED", // Отказ
};

export const RECRUITMENT_RESPONSE_STATUS_DICT = {
    NONE: "Нет",
    THINKING: "Подумать",
    REJECTED: "Отказ",
};

export const RECRUITMENT_CANDIDATES_MASS_OPTION = {
    MOVE_FUNNEL: "MOVE_FUNNEL",
    INVITE_ORDER: "INVITE_ORDER",
};

export const RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE = {
    [RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER]: "HeadHunter",
    [RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB]: "SuperJob",
    [RECRUITMENT_SOURCE_JOB_BOARD.AVITO]: "Авито",
    [RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU]: "Зарплата",
    [RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX]: "Наймикс",
};

export const EXTERNAL_JOB_BOARDS_LIST = [
    RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER,
    RECRUITMENT_SOURCE_JOB_BOARD.AVITO,
    RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB,
    RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU,
];

export const VACANCY_JOB_BOARDS_LIST = [
    RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX,
    ...EXTERNAL_JOB_BOARDS_LIST,
];

export const AVITO_EXPERIENCE = {
    NO_MATTER: "noMatter",
    MORE_THAN_5: "moreThan5",
    MORE_THAN_3: "moreThan3",
    MORE_THAN_1: "moreThan1",
    MORE_THAN_10: "moreThan10",
};

export const AVITO_BILLING_TYPES = {
    SINGLE: "single",
    PACKAGE: "package",
    PACKAGE_OR_SINGLE: "packageOrSingle",
};

export const AVITO_SCHEDULE = {
    PART_TIME: "partTime",
    FLEXIBLE: "flexible",
    FLY_IN_FLY_OUT: "flyInFlyOut",
    SHIFT: "shift",
    FULL_DAY: "fullDay",
    REMOTE: "remote",
};

export const SUPER_JOB_PUBLISHED_OPTIONS = [
    {
        key: true,
        text: "Открытая",
        value: true,
    },
    {
        key: false,
        text: "Закрытая",
        value: false,
    },
];

export const RECRUITMENT_VACANCY_DESCRIPTION_ACCESS_DICTS = {
    PERSONAL: "Индивидуальный доступ",
    GROUP: "Доступ через группу",
    SENIOR_RECRUITER: "Старший рекрутер",
};