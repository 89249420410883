import React, {Component} from "react";

import NmInputV2 from "../ActualComponents/NmInputV2";

import PropTypes from "prop-types";

import "./style.sass";

class NmNumberRangePicker extends Component {
    static propTypes = {
        onStartFieldChange: PropTypes.func,
        onEndFieldChange: PropTypes.func,
        startFieldName: PropTypes.string,
        endFieldName: PropTypes.string,
        label: PropTypes.string,
        startFieldMask: PropTypes.string,
        endFieldMask: PropTypes.string,
        startFieldPlaceholder: PropTypes.string,
        endFieldPlaceholder: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.object,
        size: PropTypes.string,
    };

    static defaultProps = {
        onStartFieldChange: () => {},
        onEndFieldChange: () => {},
        startFieldName: "startFilter",
        endFieldName: "endFilter",
        startFieldMask: "99",
        endFieldMask: "99",
        className: "",
        size: "lg",
        value: {},
    };


    render() {
        const {
            onStartFieldChange,
            onEndFieldChange,
            startFieldName,
            endFieldName,
            startFieldMask,
            endFieldMask,
            label,
            className,
            startFieldPlaceholder,
            endFieldPlaceholder,
            size,
            value,
        } = this.props;

        const {
            [startFieldName]: startValue,
            [endFieldName]: endValue,
        } = value;

        return (
            <div className={`nm-number-range-picker flex flex-aligned-end ${className}`}>
                <NmInputV2
                    mask={startFieldMask}
                    onChange={onStartFieldChange}
                    maskChar={null}
                    name={startFieldName}
                    label={label}
                    value={startValue}
                    placeholder={startFieldPlaceholder}
                    size={size}
                />
                <NmInputV2
                    mask={endFieldMask}
                    onChange={onEndFieldChange}
                    maskChar={null}
                    name={endFieldName}
                    value={endValue}
                    placeholder={endFieldPlaceholder}
                    size={size}
                />
            </div>
        );
    };
}

export default NmNumberRangePicker;
