import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractors/device";
//*  TYPES  *//

const DEVICE_LOG_READ_REQUEST = "DEVICE_LOG_READ_REQUEST";
const DEVICE_LOG_READ_SUCCESS = "DEVICE_LOG_READ_SUCCESS";
const DEVICE_LOG_READ_ERROR = "DEVICE_LOG_READ_ERROR";


//*  INITIAL STATE  *//

const initial = {
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    deviceLog: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case DEVICE_LOG_READ_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case DEVICE_LOG_READ_SUCCESS:
        const {
            contractorDeviceList = [],
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            deviceLog: [...contractorDeviceList],
            totalCount,
        };
    case DEVICE_LOG_READ_ERROR:
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//


export function getDeviceLog(payload) {
    return {
        type: DEVICE_LOG_READ_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const deviceLogSelector = state => state.deviceLog;

export const deviceLogTotalPagesSelector = createSelector(deviceLogSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));

export const deviceLogTotalCountSelector = createSelector(deviceLogSelector, ({totalCount}) => totalCount);

export const deviceLogProgressSelector = createSelector(deviceLogSelector, ({progress}) => progress);

//*  SAGA  *//

export const deviceLogListReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DEVICE_LOG_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DEVICE_LOG_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DEVICE_LOG_READ_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(DEVICE_LOG_READ_REQUEST, deviceLogListReadSaga),
    ]);
}
