export const DEPOSIT_FIELD = {
    DESCRIPTION: "description",
    AMOUNT: "amount",
    CLIENT_ID: "clientId",
    CLIENT_NAME: "clientName",
    DEPOSIT_AMOUNT: "depositAmount",
    ORDER_AMOUNT: "orderAmount",
    PAY_AMOUNT: "payAmount",
    COMMENT: "comment",
};

export const DEPOSIT_HISTORY_FIELD = {
    ACTION: "action",
    AMOUNT: "amount",
    CLIENT_USER_ID: "clientUserId",
    DATE_TIME: "dateTime",
    DESCRIPTION: "description",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    PATRONYMIC: "patronymic",
    FULLNAME: "fullName",
};

export const DEPOSIT_REPLENISHMENT_ACCOUNT = {
    INVOICE_DATE: "invoiceDate",
    INVOICE_NUMBER: "invoiceNumber",
    TOTAL_AMOUNT: "totalAmount",
    BANK_TYPE: "bankType",
};

