import {EnumDropzoneErrorCode, IDropzoneFileError} from "../types";

type THandleDropzoneValidations = {
    errors: Array<IDropzoneFileError>,
    format?: string,
    maxSize?: number,
    minSize?: number,
}

export const handleDropzoneValidations = (params: THandleDropzoneValidations) => {
    const {
        errors,
        format,
        maxSize,
        minSize,
    } = params;

    return errors.map(({code, message}: IDropzoneFileError) => {
        if (code === EnumDropzoneErrorCode.fileTooLarge) {
            return {
                textBlock: `Размер файла превышает допустимый размер: ${maxSize}МБ. Файл не будет загружен`,
            };
        }

        if (code === EnumDropzoneErrorCode.fileTooSmall) {
            return {
                textBlock: `Размер файла меньше допустимого размера: ${minSize}МБ. Файл не будет загружен`,
            };
        }

        if (code === EnumDropzoneErrorCode.tooManyFiles) {
            return {
                textBlock: "Слишком много файлов",
            };
        }

        if (code === EnumDropzoneErrorCode.fileInvalidType) {
            if (!format) {
                return {
                    textBlock: "Файл имеет недопустимый формат",
                };
            }

            return {
                textBlock: `Файл должен иметь формат: ${format}`,
            };
        }

        return {
            textBlock: message,
        };
    });
};