import React, {FC} from "react";

import {ReactComponent as ArrowForwardIcon} from "../../../images/arrow_forward_24.svg";
import Text from "../Text";

import bem from "../../../utils/bem";

import { COLOR } from "../../../constants/color";

import "./style.sass";

interface IStepList {
    className?: string,
    list: Array<string> | [],
    textColor?: string,
    iconColor?: string,
}

const StepList: FC<IStepList> = (props) => {
    const {
        className = "",
        list = [],
        textColor,
        iconColor,
    } = props;

    const [block] = bem("step-list", className);

    return (
        <div className={block()}>
            {
                list.map((item, index) => {
                    return (
                        <div
                            key={item}
                            className="flex align-items-center me-1"
                        >
                            <Text
                                color={textColor}
                                level="3"
                                noWrap
                            >
                                {item}
                            </Text>
                            {
                                index !== list.length - 1 &&
                                <ArrowForwardIcon
                                    className="ms-1"
                                    color={iconColor || COLOR.SECONDARY_70}
                                    width={16}
                                    height={16}
                                />
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

export default StepList;