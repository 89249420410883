import React, {ReactNode, RefObject, SyntheticEvent, useEffect, useRef, useState} from "react";
import {isEmpty} from "lodash";
import {nanoid} from "nanoid";

import {ReactComponent as IconChevron} from "../../images/chevron-down.svg";
import {ReactComponent as IconClose} from "../../images/close.svg";
import {ReactComponent as IconSearch} from "../../images/search.svg";
import ErrorTooltip from "../ActualComponents/ErrorTooltip";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmLabel, {INmLabel} from "../ActualComponents/NmLabel";
import NmButton from "../NmButton";

import {useClickOutside} from "../../hooks/useClickOutside";

import {OptionType} from "../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../utils/bem";

import "./style.sass";

const getSelectedTitle = (items: TOptions) => (
    items.map(({text}: OptionType) => `${text};\n`).join("")
);

interface IButtons {
    onSubmit: () => void;
    onCancel: () => void;
}

const Buttons = (props: IButtons) => {
    const {
        onSubmit,
        onCancel,
    } = props;

    return (
        <div className="order-dropdown__buttons">
            <NmButton
                color="light-green"
                className="order-dropdown__submit"
                onClick={onSubmit}
            >
                Показать
            </NmButton>
            <NmButton
                className="order-dropdown__cancel"
                onClick={onCancel}
                color="grey"
            >
                Очистить
            </NmButton>
        </div>
    );
};

type TOptions = Array<OptionType> | [];

export interface IOrderDropdown extends INmLabel {
    options: TOptions,
        value: string | Array<string>,
        name?: string,
        size?: "xl" | "lg",
        search?: boolean,
        additionalContent?: any,
        placeholder?: string,
        disabled?: boolean,
        className?: string,
        error?: Array<string> | string,
        onFocus?: (event?: any) => void,
        isCustomField?: boolean,
        valueWhichNotInOptions?: string,
        multiple?: Boolean,
        isActiveStyle?: boolean,
        fullWidth?: boolean,
        saveSpecialityByEnter?: (value: string) => void,
        isAllSelectionShown?: boolean,
        required?: boolean,
        direction?: "bottom" | "top",
        onChange: (event: any, params: object) => void,
        onBlur?: (event: any) => void,
        onSubmit?: () => void,
        onCancel?: () => void,
        onSearchChange?: (event: any, params: any) => void,
        children?: never,
}

const OrderDropdown = (props: IOrderDropdown) => {
    const {
        options = [],
        size = "xl",
        label,
        additionalContent,
        onChange,
        placeholder,
        name,
        value,
        search,
        onBlur,
        disabled,
        className = "",
        error,
        onFocus,
        isCustomField,
        valueWhichNotInOptions,
        multiple = false,
        onSearchChange,
        onSubmit,
        onCancel,
        isActiveStyle,
        fullWidth,
        saveSpecialityByEnter,
        isAllSelectionShown = true,
        required,
        direction = "bottom",
    } = props;

    const [open, setOpen] = useState(false);
    const [text, setText] = useState<string | ReactNode>("");
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [countSelected, setCountSelected] = useState(0);
    const [selected, setSelected] = useState<TOptions>([]);
    const [openSelected, setOpenSelected] = useState(false);

    const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);

    const [block, element] = bem("order-dropdown", className);

    useEffect(() => {
        // Одиночный выбор
        if (!multiple) {
            const {text} = options.find((item) => (item.value === value)) || {};

            setText(text);
        }

        // Подгрузка значений при мультивыборе 1 раз
        if (multiple && !isOptionsLoaded && options.length !== 0 && !isEmpty(value)) {
            setIsOptionsLoaded(true);

            const selectedOptions = options.filter((item) => value.includes(item.value));

            setText(selectedOptions.map(item => item.text).join(", "));
            setSelected(selectedOptions);
        }
    }, [options]);

    useEffect(() => {
        // Мультивыбор
        if (multiple && Array.isArray(value)) {
            setCountSelected(value.length);

            // Подгрузка значений при мультивыборе
            if (value.length !== 0 && options.length !== 0) {
                const selectedOptions = options.filter((item) => value.includes(item.value));

                setText(selectedOptions.map(item => item.text).join(", "));
                !onSearchChange && setSelected(selectedOptions);
            }

            // Для стирания
            if (value.length === 0 || !value) {
                setOpenSelected(false);
                setSelected([]);
                setText("");
            }
        }

        // Одиночный выбор
        if (!multiple) {
            const {text} = options.find(item => (item.value === value)) || {};

            setText(text);

            setInputValue("");
        }
    }, [value]);

    useEffect(() => {
        if (valueWhichNotInOptions) {
            setText(valueWhichNotInOptions);
        }
    }, [valueWhichNotInOptions]);

    useEffect(() => {
        if (isCustomField && valueWhichNotInOptions && !open && inputRef && inputRef.current) {
            inputRef.current.blur();
        }

        if (search && open && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [open]);

    const handleClickOutsideWithValueWhichNotInOptions = (target: Element) => {
        // Определяем в какое место сделан клик, нужно для правильной логики отображаещегося значения
        // В момент клика по значению из списка В случае если есть значение valueWhichNotInOptions, которого нет в списке ->
        // дропдаун закрывается -> useClickOutside отрабатывает раньше,
        // чем обновляется значение в родителе valueWhichNotInOptions (затирание) -
        // что приводит к отображению valueWhichNotInOptions в закрытом дродауне, что является неверным
        const isClickedItem = target.classList.contains(element("item"));

        // Если был произведен выбор из списка не сохраняем отображаемое значение в дропдаун
        if (isClickedItem) {
            return;
        }

        // Отображаем в закрытом состянии дропдауна значение из родителя
        // т к оно не добавлено (не был прожат энтер)
        setText(valueWhichNotInOptions);
    };

    const rootRef: RefObject<HTMLDivElement> = useClickOutside((e: SyntheticEvent) => {
        const target = e.target as Element;

        const isException = target.classList.contains(element("word-icon"));

        if (open && !isException) {
            setOpen(false);
            // Обрабатываем клик вне дропдауна если есть значение, которого нет в списке
            if (valueWhichNotInOptions) {
                handleClickOutsideWithValueWhichNotInOptions(target);

                return;
            }

            // Если значения подтягиваются с бэка в список, при наличии в инпуте значения,
            // обновляем список к дефолтному значению только в случае множественного выбора,
            // по текущей логике в случае обнуления у немножественного выбора приводит к ошибке
            // toDO: надо подумать над этим тщательней
            if (onSearchChange && inputValue && multiple) {
                onSearchChange(null, {searchQuery: ""});
            }

            setInputValue("");
        }
    }, open);

    const toggle = () => {
        if (disabled) {
            return;
        }

        if (open) {
            setOpen(false);
        }

        // При окрытии дродауна в случае если есть значение которого нет в options,
        // зачищаем отображаемое значение, так как оно переходит в input
        if (!open && valueWhichNotInOptions) {
            setText("");
            setInputValue(valueWhichNotInOptions);
        }

        if (!open) {
            setOpen(true);
        }
    };

    const handleClick = (opt: OptionType) => {
        if (multiple) {
            onChangeCheckbox(opt);

            return;
        }

        onChange(null, {name, ...opt});
        setOpen(false);
    };

    const handleClickAllOptions = () => {
        if (options.length === value?.length) {
            setSelected([]);
            setText("");
            onChange(null, {name, value: []});

            return;
        }

        const parseOptions = options.map(({value, text, key}: OptionType) => ({value, text, key}));
        const parseText = options.map(({text}: OptionType) => (text)).join(", ");
        const parseValue = options.map(({value}: OptionType) => (value));

        setSelected(parseOptions);
        setText(() => parseText);
        onChange(null, {name, value: parseValue});
    };

    const onClickSelected = () => {
        if (value.length === 0) {
            return;
        }

        setOpenSelected(!openSelected);
    };

    const deleteWord = (id: string) => {
        const selectedList = [...value];
        const _selected = [...selected];

        const index = selectedList.findIndex(item => (item === id));

        selectedList.splice(index, 1);
        _selected.splice(index, 1);

        setSelected(_selected);
        setText(_selected.map(item => item.text).join(", "));
        onChange(null, {name, value: selectedList});
    };

    const getSelected = () => {
        if (!multiple) {
            return null;
        }

        return (
            <div className={element("selected-area", {empty: options.length === 0})}>
                <div className={element("selected-container")}>
                    <div
                        onClick={onClickSelected}
                        className={element("selected", {active: (value && value.length > 0)})}
                    >
                        <div className={element("selected-text")}>
                            Выбрано: 
                            {" "}
                            {countSelected}
                        </div>
                        <IconChevron
                            className={element("selected-icon", {open: openSelected})}
                        />
                    </div>
                    {
                        openSelected &&
                        <div className={element("words")}>
                            {
                                selected.map((item: OptionType) => {
                                    const {
                                        text,
                                        value,
                                    } = item;

                                    return (
                                        <div
                                            key={nanoid(3)}
                                            className={element("word")}
                                        >
                                            <div className={element("word-text")}>
                                                {text}
                                            </div>
                                            <IconClose
                                                onClick={() => {
                                                    deleteWord(value);
                                                }}
                                                className={element("word-icon")}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        );
    };

    const onChangeCheckbox = (opt: OptionType) => {
        const {value: val, ...rest} = opt;
        const selectedList = value && [...value] || [];
        const _selected = [...selected];

        if (selectedList.includes(val)) {
            const index = selectedList.findIndex(item => item === val);

            _selected.splice(index, 1);

            selectedList.splice(index, 1);
        } else {
            const result = options.find(item => (item.value === val));

            result && _selected.push({
                text: result.text,
                value: result.value,
                key: result.key,
            });

            selectedList.push(val);
        }


        setSelected(_selected);
        setText(_selected.map(item => item.text).join(", "));

        onChange(null, {
            name,
            ...opt,
            value: selectedList,

        });
    };

    const getOptions = () => {

        let _options = options;

        if (search) {
            _options = options.filter(value => search && typeof value.text === "string" && value.text.toLowerCase().includes(inputValue.toLowerCase()));
        }

        return (
            <>
                {
                    isAllSelectionShown &&
                    (multiple && options.length > 1) &&
                    <div
                        className={element("item", {
                            selected: (options && options.length) === (value && value.length),
                            multiple,
                        })}
                        onClick={() => {
                            if (multiple) {
                                return;
                            }

                            handleClickAllOptions();
                        }}
                    >
                        <div className={element("item-content")}>
                            <NmCheckboxV2
                                classNameLabel="order-dropdown__checkbox-label"
                                onChange={handleClickAllOptions}
                                label="Выбрать все"
                                checked={options.length === value?.length}
                                className={element("checkbox")}
                            />
                        </div>
                    </div>
                }
                {
                    _options.map((opt: OptionType) => {
                        const {
                            key,
                            value: _value,
                            text,
                        } = opt;

                        return (
                            <div
                                key={key}
                                className={element("item", {
                                    selected: !isEmpty(value) && value.includes(_value),
                                    multiple,
                                })}
                                onClick={() => {
                                    if (multiple) {
                                        return;
                                    }

                                    handleClick(opt);
                                }}
                            >
                                <div
                                    title={typeof text !== "string" ? undefined : text}
                                    className={element("item-content")}
                                >
                                    {
                                        multiple ?
                                            <NmCheckboxV2
                                                classNameLabel="order-dropdown__checkbox-label"
                                                onChange={() => {
                                                    handleClick(opt);
                                                }}
                                                label={text}
                                                checked={value?.includes(_value)}
                                                className={element("checkbox")}
                                            /> :
                                            text
                                    }
                                </div>
                            </div>
                        );
                    })}
            </>
        );

    };

    const onChangeInput = (event: any) => {
        setInputValue(event.target.value);

        if (onSearchChange) {
            onSearchChange(event, {
                searchQuery: event.target.value,
            });
        }
    };

    const getValue = () => {
        if (text && valueWhichNotInOptions) {
            return text;
        }

        if (inputValue && open) {
            return "";
        }

        if (text) {
            return text;
        }

        if (inputValue) {
            return "";
        }

        if (!text && placeholder) {
            return placeholder;   }


        return "";
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            updateAfterPressEnter();
        }
    };

    const updateAfterPressEnter = () => {
        const filteredOptions = options.filter((value: OptionType) =>
            typeof value.text === "string" && value.text.toLowerCase().includes(inputValue.toLowerCase()));

        if (inputValue && filteredOptions.length === 1 && !multiple) {
            const [opt] = filteredOptions;

            handleClick(opt);
            setOpen(false);

            if (inputRef && inputRef.current && text === inputValue) {
                inputRef.current.blur();
                setInputValue("");

                return;
            }

            if (inputRef && inputRef.current) {
                inputRef.current.blur();
            }
        }

        if (filteredOptions.length !== 0) {
            return;
        }

        const isAddedOfferedSpeciality = saveSpecialityByEnter && saveSpecialityByEnter(inputValue);

        if (isAddedOfferedSpeciality) {
            setText(inputValue);
        }

        setOpen(false);
    };

    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
    };

    const getInputIcon = () => open && (
        <div className={element("input-icon-container")}>
            <IconSearch className={element("input-icon")} />
        </div>
    );

    const getActiveValue = () => {
        if (Boolean(value) && !multiple) {
            return true;
        }

        if ((multiple && value && value.length !== 0) || isActiveStyle) {
            return true;
        }

        if (valueWhichNotInOptions) {
            return true;
        }

        return false;
    };

    const _onCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (multiple) {
            setSelected([]);
            setText("");
            setInputValue("");
        }

        onChange(null, {
            name,
            value: multiple ? [] : "",
        });
    };

    return (
        <div
            className={block({
                open,
                error: Boolean(error),
                size,
                fullWidth,
            })}
            onKeyDown={handleKeyDown}
            id={name}
            onBlur={onBlur}
        >
            {
                label &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                />
            }
            <div
                ref={rootRef}
                onClick={toggle}
                tabIndex={0}
                className={element("content", {
                    open,
                    active: open || getActiveValue(),
                    disabled,
                    size,
                    direction,
                    search,
                })}
                onFocus={handleFocus}
                title={(selected && selected.length !== 0) ? getSelectedTitle(selected) : ""}
            >
                <div className={element("icon-container")}>
                    <IconChevron
                        className={element("icon", {open})}
                    />
                </div>
                <div className={element("text", {active: getActiveValue()})}>
                    {getValue()}
                </div>
                {
                    search && open &&
                    <div className={element("input-container")}>
                        <div className={element("relative-container")}>
                            {getInputIcon()}
                            <input
                                ref={inputRef}
                                type="text"
                                onChange={onChangeInput}
                                value={inputValue}
                                disabled={disabled}
                                name={name}
                            />
                        </div>
                    </div>
                }
                {
                    open &&
                    <div className={element("list")}>
                        <div className={element("list-wrapper")}>
                            {getSelected()}
                            <div className={element("list-container")}>
                                {getOptions()}
                            </div>
                        </div>
                        {
                            additionalContent && additionalContent
                        }
                        {
                            onSubmit &&
                            <Buttons
                                onSubmit={onSubmit}
                                onCancel={_onCancel}
                            />
                        }
                    </div>
                }
            </div>
            {
                error &&
                <ErrorTooltip error={error} />
            }
        </div>
    );
};

export default OrderDropdown;