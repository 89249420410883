import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
    BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
    BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
    BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST,
    BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
    BFF_CROWD_ACT_REGISTRY_RUN_REQUEST,
    BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST,
    BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/crowd/actregistry";

//POST /bff/adm/crowd/actregistry/page
// Список реестров
const getPageCrowdActRegisty = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/create
// Создание реестра
const createCrowdActRegistry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/create`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_CREATE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/edit
// Изменение реестра
const editCrowdActRegistry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/edit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EDIT_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/archive/{id}
// Переместить в архив
const archiveCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/archive/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_ARCHIVE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/unarchive/{id}
// Переместить из архива
const unarchiveCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/unarchive/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_UNARCHIVE_ERROR,
        });
    }
};

//GET /bff/adm/crowd/actregistry/{id}
// Поиск реестра по идентификатору
const getCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
        } = payload;

        const result = yield request.bff.get(`${controller}/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_GET_BY_ID_ERROR,
        });
    }
};

//DELETE /bff/adm/crowd/actregistry/{id}
// Удаление реестра
const deleteCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/import/{id}
const importCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            formData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/import/${registryId}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_IMPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_IMPORT_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/export
// Экспорт реестра
const exportCrowdActRegistry = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/export`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_EXPORT_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistry/run/{id}
// Запуск реестра
const runCrowdActRegistry = function* ({payload}) {
    try {
        const {
            registryId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/run/${registryId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_RUN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_RUN_ERROR,
        });
    }
};

//POST /bff/adm/crowd/actregistryevent/get/page
const getPageCrowdActRegistryLog = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/crowd/actregistryevent/get/page", payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_ACT_REGISTRY_GET_PAGE_REQUEST, getPageCrowdActRegisty),
        takeEvery(BFF_CROWD_ACT_REGISTRY_CREATE_REQUEST, createCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_EDIT_REQUEST, editCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_ARCHIVE_REQUEST, archiveCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_UNARCHIVE_REQUEST, unarchiveCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_GET_BY_ID_REQUEST, getCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_DELETE_REQUEST, deleteCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_IMPORT_REQUEST, importCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_EXPORT_REQUEST, exportCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_RUN_REQUEST, runCrowdActRegistry),
        takeEvery(BFF_CROWD_ACT_REGISTRY_LOG_GET_PAGE_REQUEST, getPageCrowdActRegistryLog),
    ]);
}