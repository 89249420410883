import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {getNumberFromFormattedAmount} from "../../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../../utils/toastHelper";

import {BANK_IDENTIFICATION_STATUS} from "../../../../../../../constants/directoriesBanks";

import {updateSettingsDirectoriesBank} from "../../../../../../../ducks/settingsDirectoriesBanks";

export const useSettingsDirectoriesBankEditFormData = ({onClose, editData, fetchList}) => {
    const dispatch = useDispatch();

    const getWalletLimitsInitial = (name) => {
        const limits = editData.walletLimits.find((item) => (item.bankIdentificationStatus === name)) || {};

        return {
            ...limits,
            availableContractorWallet: editData.availableContractorWallet,
        };
    };

    const initialValues = {
        bankSecondName: "",
        bic: "",
        inn: "",
        kpp: "",
        correspondentAccount: "",
        checkingAccount: "",
        ...editData,
        directPaymentLimits: {
            ...editData.directPaymentLimits[0] || {},
            availableDirectPayments: editData.availableDirectPayments,
        },
        walletLimits: {
            [BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED]: getWalletLimitsInitial(BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED),
            [BANK_IDENTIFICATION_STATUS.IDENTIFIED]: getWalletLimitsInitial(BANK_IDENTIFICATION_STATUS.IDENTIFIED),
            [BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED]: getWalletLimitsInitial(BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED),
        },
    };

    const onChange = (event, {name, value, checked}, blockName, bankIdentificationStatus) => {
        if (bankIdentificationStatus) {
            setFieldValue(blockName, {
                ...values[blockName],
                [bankIdentificationStatus]: {
                    ...values[blockName][bankIdentificationStatus],
                    bankIdentificationStatus,
                    [name]: typeof checked === "boolean" ? checked : value,
                },
            });

            return;
        }

        setFieldValue(blockName, {
            ...values[blockName],
            bankIdentificationStatus,
            [name]: typeof checked === "boolean" ? checked : value,
        });
    };

    const getRequestDataLimits = (item) => {
        return {
            ...item,
            singleLimit: getNumberFromFormattedAmount(item.singleLimit),
            dayLimit: getNumberFromFormattedAmount(item.dayLimit),
            monthLimit: getNumberFromFormattedAmount(item.monthLimit),
        };
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const reqData = {
            ...editData,
            bankSecondName: handleFormString(values.bankSecondName),
            bic: handleFormString(values.bic),
            inn: handleFormString(values.inn),
            kpp: handleFormString(values.kpp),
            checkingAccount: handleFormString(values.checkingAccount),
            correspondentAccount: handleFormString(values.correspondentAccount),
            directPaymentLimits: [getRequestDataLimits(values.directPaymentLimits)],
            walletLimits: Object.values(values.walletLimits).map(item => getRequestDataLimits(item)),
        };

        dispatch(updateSettingsDirectoriesBank({
            ...reqData,
            onSuccess: () => {
                onClose();
                fetchList();
                toastSuccess("Параметры успешно обновлены");
            },
        }));
    };

    function getLimitFields() {
        return [
            editData.availableContractorWallet && {
                title: "Лимиты по переводам через кошелек",
                subTitle: "Лимиты для уровня \"Не идентифицированный\"",
                blockName: "walletLimits",
                bankIdentificationStatus: BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED,
            },
            editData.availableContractorWallet && {
                subTitle: "Лимиты для уровня \"Упрощенно идентифицированный\"",
                blockName: "walletLimits",
                bankIdentificationStatus: BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED,
            },
            editData.availableContractorWallet && {
                subTitle: "Лимиты для уровня \"Полная идентификация\"",
                blockName: "walletLimits",
                bankIdentificationStatus: BANK_IDENTIFICATION_STATUS.IDENTIFIED,
            },
            editData.availableDirectPayments && {
                title: "Лимиты по переводам напрямую на карту",
                blockName: "directPaymentLimits",
                bankIdentificationStatus: null,
            },
        ].filter(item => Boolean(item));
    }

    const {
        handleSubmit,
        values,
        handleChange,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        handleChange,
        limitFields: getLimitFields(),
        onChange,
    };
};