import {
    KEDO_TEMPLATES_ADD_REQUEST,
    KEDO_TEMPLATES_DELETE_REQUEST,
    KEDO_TEMPLATES_GET_PAGE_REQUEST,
    KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST,
    KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST,
    KEDO_TEMPLATES_UPDATE_REQUEST,
    KEDO_TEMPLATES_UPDATE_STORE,
    KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST,
    KEDO_TEMPLATES_VALIDATE_PDF_REQUEST,
} from "./actions";

export const getKedoTemplatesPage = (payload) => {
    return {
        type: KEDO_TEMPLATES_GET_PAGE_REQUEST,
        payload,
    };
};

export const deleteKedoTemplate = (payload) => {
    return {
        type: KEDO_TEMPLATES_DELETE_REQUEST,
        payload,
    };
};

export const addKedoTemplate = (payload) => {
    return {
        type: KEDO_TEMPLATES_ADD_REQUEST,
        payload,
    };
};

export const getKedoTemplateUsersFieldNames = (payload) => {
    return {
        type: KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST,
        payload,
    };
};

export const updateKedoTemplate = (payload) => {
    return {
        type: KEDO_TEMPLATES_UPDATE_REQUEST,
        payload,
    };
};

export const validateKedoTemplatePdf = (payload) => {
    return {
        type: KEDO_TEMPLATES_VALIDATE_PDF_REQUEST,
        payload,
    };
};

export const validateKedoTemplateFields = (payload) => {
    return {
        type: KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST,
        payload,
    };
};

export const getKedoTemplatesLogsPage = (payload) => {
    return {
        type: KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateKedoTemplatesStore = (payload) => {
    return {
        type: KEDO_TEMPLATES_UPDATE_STORE,
        payload,
    };
};