import {isEqual} from "lodash";

import {getClientSettingsOtherRequestData} from "./getRequestData";

export const isClientSettingsOtherChanged = (params) => {
    const {
        values,
    } = params;

    const isAvailableClientManagersChanged = params.availableClientManagersIds.length !== values.availableClientManagersIds.length;

    if (isAvailableClientManagersChanged) {
        return true;
    }

    const requestData = getClientSettingsOtherRequestData(values);

    const {
        availableClientManagers,
        ..._values
    } = requestData;

    const card = getCardForEqual(params.card);

    return Object.keys(_values).length === Object.keys(card).length &&
        !isEqual(_values, card);
};

const getCardForEqual = (card) => {
    const {
        availableClientManagers,
        hasAmountBasedDrafts,
        hasVolumeOfWorkBasedDrafts,
        ...result
    } = card;

    return result;
};