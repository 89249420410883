import {citizenshipsDict} from "../../../../../../constants/citizenships";
import {
    CONTRACTOR_MIGRATION_STATUS,
    EDM_PASSPORT_TYPE,
} from "../../../../../../constants/contractor";

export const getContractorPassportType = (params) => {
    const {
        citizenship,
        migrationStatus,
        passportTypeOptions,
        passportType,
        edmPassportType,
    } = params;

    if (
        [
            citizenshipsDict.BY.value,
            citizenshipsDict.KZ.value,
            citizenshipsDict.KG.value,
        ].includes(citizenship) &&
        ![
            CONTRACTOR_MIGRATION_STATUS.REFUGEE,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
        ].includes(migrationStatus)
    ) {
        return {
            isVisiblePassportType: true,
            passportType: passportTypeOptions[passportType],
        };
    }

    if (
        [
            citizenshipsDict.TJ.value,
            citizenshipsDict.UA.value,
        ].includes(citizenship) &&
        ![
            CONTRACTOR_MIGRATION_STATUS.REFUGEE,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
        ].includes(migrationStatus)
    ) {
        return {
            isVisiblePassportType: true,
            passportType: EDM_PASSPORT_TYPE[edmPassportType]?.TEXT,
        };
    }

    return {
        isVisiblePassportType: false,
    };
};