import * as yup from "yup";

import {addYears, calculateAge, getStartDate} from "../../../../utils/dateFormat";

import {CONTRACTOR_ARRIVAL_DOC_TYPE_CODE, CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationSchema = () => {

    return yup.object().shape({
        [CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]: yup.string()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .length(10, "Допустимое количество символов 9"),
            }),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]: yup.string()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test("check", "Некорректная дата принятия решения для документа", function (value) {
                        const birthDate = this.parent[CONTRACTOR_FIELD.BIRTH_DATE];
                        const userAges = calculateAge(birthDate);
                        const startDate = this.parent[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE];
                        const isNewDateOfReceiving = new Date(startDate) > new Date(2019,10,1); // это дата выдачи c 01.11.2019 года

                        if (!birthDate || !startDate || !isNewDateOfReceiving || !value) { //если дата рождения и дата выдачи не заполнены - контроль не показываем
                            return true;
                        }

                        if (userAges < USER_AGE_LIMIT.FIRST_LIMIT) {
                            return true;
                        }

                        if (userAges >= USER_AGE_LIMIT.FIRST_LIMIT && userAges < USER_AGE_LIMIT.SECOND_LIMIT) {
                            const leftPartEquation = addYears(birthDate, USER_AGE_LIMIT.FIRST_LIMIT);
                            const rightPartEquation = addYears(birthDate, USER_AGE_LIMIT.SECOND_LIMIT);

                            return (leftPartEquation <= new Date(value)) && (new Date(value) < rightPartEquation) && isNewDateOfReceiving;
                        }

                        if (userAges >= USER_AGE_LIMIT.SECOND_LIMIT && userAges < USER_AGE_LIMIT.THIRD_LIMIT) {
                            const leftPartEquation = addYears(birthDate, USER_AGE_LIMIT.SECOND_LIMIT);
                            const rightPartEquation = addYears(birthDate, USER_AGE_LIMIT.THIRD_LIMIT);

                            return (leftPartEquation <= new Date(value)) && (new Date(value) < rightPartEquation) && isNewDateOfReceiving;
                        }

                        if (userAges >= USER_AGE_LIMIT.THIRD_LIMIT) {
                            const leftPartEquation = addYears(birthDate, USER_AGE_LIMIT.THIRD_LIMIT);

                            return (leftPartEquation <= new Date(value)) && isNewDateOfReceiving;
                        }

                        return false;
                    }),
            }),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]: yup.string()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS]: yup.string()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .nullable(),
            }),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .nullable(),
            }),
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                then: yup.string()
                    .test("check", "Некорректная дата окончания срока действия документа", function (value) {
                        const startDate = this.parent[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE];

                        if (!startDate || !value) { //проверка заполенено ли поле даты выдачи
                            return true;
                        }

                        const isOldDateOfReceiving = new Date(startDate) < new Date(2019,10,1); // это дата выдачи до 01.11.2019 года

                        const registrationDate = addYears(startDate, YEAR_FOR_CHECK_RESIDENCE_PERMIT_VALID_TO_DATE);
                        const leftPartEquation = getStartDate(registrationDate);
                        const rightParEquation = getStartDate(new Date(value));

                        return !(isOldDateOfReceiving && !(leftPartEquation.getTime() === rightParEquation.getTime()));

                    })
                    .nullable(),
            }),
        [CONTRACTOR_FIELD.ADDRESS]: yup.string()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        [CONTRACTOR_FIELD.REGISTRATION_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .nullable(),
            }),
        [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE, {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .nullable(),
            }),
    });
};

export default validationSchema;

const USER_AGE_LIMIT = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 20,
    THIRD_LIMIT: 45,
};

const YEAR_FOR_CHECK_RESIDENCE_PERMIT_VALID_TO_DATE = 5;