import React, {useEffect,useState} from "react";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import validate from "../../../../utils/validate";

import {
    CONTRACTOR_FIELD,
    CONTRACTOR_STATUS,
} from "../../../../constants/contractor";
import {CONTRACTOR_CARD_FIELDS} from "../../../../constants/contractorInfo";
import {
    requiredMessage,
} from "../../../../constants/validationRules";

import {
    contractorCardSelector,
    getContractorSnilsInfo,
    pendingPersonalDataSelector,
    updateContractor,
} from "../../../../ducks/contractor";

import "./style.sass";

const SnilsInfo = (props) => {
    const {
        isEditable,
        className,
        t,
    } = props;

    const [form, setForm] = useState({
        [CONTRACTOR_FIELD.SNILS_PRESENT]: false,
        [CONTRACTOR_FIELD.SNILS_NUM]: "",
    });

    const [isEdit, setIsEdit] = useState(false);
    const [errorForm, setErrorForm] = useState({});

    const dispatch = useDispatch();

    const card = useSelector(contractorCardSelector);
    const snilsInfo = useSelector(getContractorSnilsInfo);
    const {data} = useSelector(pendingPersonalDataSelector);
    const {pendingPersonalDataFields = []} = data || {};

    useEffect(() => {
        if (!isEmpty(snilsInfo)) {
            setForm({...snilsInfo});
        }
    }, [snilsInfo]);

    const isValidForm = () => {
        const rule = form[CONTRACTOR_FIELD.SNILS_PRESENT] ? {
            [CONTRACTOR_FIELD.SNILS_NUM]: {
                required: requiredMessage,
            },
        } : {};

        const error = validate(form, rule, "");

        setErrorForm({...error});

        return Object.values(error).length === 0;
    };

    const save = () => {
        dispatch(updateContractor({
            ...card,
            ...form,
            snilsNum: form.snilsNum.replace(" ", "").split("-").join(""),
        }));
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setErrorForm({});
        setForm({...snilsInfo});
    };

    const toggleCard = () => {
        if (isEdit) {
            const isValid = isValidForm();

            if (!isValid) {
                return;
            }
        }

        setIsEdit(!isEdit);

        if (isEdit) {
            save();
        }
    };

    const handleChange = (e, {value, name, checked}) => {
        setForm({
            ...form,
            [name]: checked === undefined ? value : checked,
            snilsNum: (name === "snilsPresent" && checked === false) ? "" : value,
        });
    };

    const renderText = (value) => {
        return value || "Не указан";
    };

    const hasBorder = () => CONTRACTOR_CARD_FIELDS.SNILS_INFO.some(field => pendingPersonalDataFields.includes(field));

    const renderCardReadOnly = () => {
        return (
            <>
                <NmCheckboxV2
                    label="CНИЛС присутствует"
                    disabled={true}
                    checked={form[CONTRACTOR_FIELD.SNILS_PRESENT]}
                    onChange={() => {}}
                />
                {
                    form[CONTRACTOR_FIELD.SNILS_PRESENT] &&
                    <LabelTextApp
                        className="mt-3 mt-md-4"
                        label="Номер"
                        text={renderText(form[CONTRACTOR_FIELD.SNILS_NUM])}
                    />
                }
            </>
        );
    };

    const renderCardEdit = () => {
        return (
            <NmForm className="fluid">
                <div className="row">
                    <div className="col-16">
                        <NmCheckboxV2
                            name={CONTRACTOR_FIELD.SNILS_PRESENT}
                            label="CНИЛС присутствует"
                            onChange={handleChange}
                            checked={form[CONTRACTOR_FIELD.SNILS_PRESENT]}
                        />
                    </div>
                </div>
                {
                    form[CONTRACTOR_FIELD.SNILS_PRESENT] &&
                    <div className="row mt-3 mt-md-4">
                        <div className="col-16">
                            <NmInputV2
                                size="lg"
                                mask="999-999-999 99"
                                className="snils-info__input"
                                error={errorForm[CONTRACTOR_FIELD.SNILS_NUM]}
                                label="Номер"
                                onChange={handleChange}
                                value={form[CONTRACTOR_FIELD.SNILS_NUM]}
                                name={CONTRACTOR_FIELD.SNILS_NUM}
                                required
                            />
                        </div>
                    </div>
                }
            </NmForm>
        );
    };

    return (
        <CardApp
            className={className}
            border={hasBorder()}
            title="СНИЛС"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
        >
            {isEdit ? renderCardEdit() : renderCardReadOnly()}
        </CardApp>
    );
};

export default withTranslation()(SnilsInfo);