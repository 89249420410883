import React, {ReactNode, useState} from "react";

import AmountInput from "../../AmountInput";
import ErrorTooltip from "../ErrorTooltip";
import NmInputV2 from "../NmInputV2";
import NmLabel from "../NmLabel";

import bem from "../../../utils/bem";

import "./style.sass";

export interface INmRangeInput {
    label?: string,
    required?: boolean,
    valueStart?: string,
    valueEnd?: string,
    placeholderStart?: string,
    placeholderEnd?: string,
    maskStart?: string,
    maskEnd?: string,
    nameStart?: string,
    nameEnd?: string,
    errorEnd?: string | null,
    errorStart?: string | null,
    onChange?: (event: any, params: any) => void,
    error?: string,
    size?: "xl" | "lg",
    isClearable?: boolean,
    disabled?: boolean,
    className?: string,
    pattern?: string,
    iconStart?: ReactNode,
    iconEnd?: ReactNode,
    onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void,
    isAmountInput?: boolean,
    tooltip?: ReactNode,
    isVisibleTooltip?: boolean
}

const NmRangeInput = (props: INmRangeInput) => {
    const {
        label,
        required,
        maskStart,
        nameStart = "startFilter",
        valueStart = "",
        placeholderStart,
        maskEnd,
        onChange,
        nameEnd = "endFilter",
        valueEnd = "",
        placeholderEnd,
        error,
        size = "xl",
        isClearable,
        errorStart,
        errorEnd,
        disabled,
        className = "",
        pattern,
        iconStart,
        iconEnd,
        onBlur,
        isAmountInput,
        tooltip,
        isVisibleTooltip,
    } = props;
    const [block, element] = bem("nm-range-input", className);
    const [startFocused, setStartFocused] = useState(false);
    const [endFocused, setEndFocused] = useState(false);

    const _onFocusStart = () => {
        setStartFocused(true);
    };

    const _onFocusEnd = () => {
        setEndFocused(true);
    };

    const _onBlurStart = (event: React.FocusEvent<HTMLInputElement>) => {
        setStartFocused(false);

        if (onBlur) {
            onBlur(event);
        }
    };

    const _onBlurEnd = (event: React.FocusEvent<HTMLInputElement>) => {
        setEndFocused(false);

        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <div className={block()}>
            {
                label &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                    isVisibleTooltip={isVisibleTooltip}
                    tooltip={tooltip}
                />
            }
            <div
                tabIndex={0}
                className={element("control", {
                    focused: startFocused || endFocused,
                    active: Boolean(valueStart || valueEnd),
                    error: Boolean(errorStart || errorEnd || error),
                    size,
                    disabled,
                })}
            >
                {
                    isAmountInput ?
                        <AmountInput
                            newInput
                            className={element("start")}
                            size={size}
                            onChange={onChange}
                            name={nameStart}
                            value={valueStart}
                            placeholder={placeholderStart}
                            isClearable={isClearable}
                            error={errorStart}
                            onFocus={_onFocusStart}
                            onBlur={_onBlurStart}
                            disabled={disabled}
                            pattern={pattern}
                            icon={iconStart}
                        /> :
                        <NmInputV2
                            className={element("start")}
                            size={size}
                            onChange={onChange}
                            mask={maskStart}
                            maskChar={null}
                            name={nameStart}
                            value={valueStart}
                            placeholder={placeholderStart}
                            isClearable={isClearable}
                            error={errorStart}
                            onFocus={_onFocusStart}
                            onBlur={_onBlurStart}
                            disabled={disabled}
                            pattern={pattern}
                            icon={iconStart}
                        />
                }
                <div className={element("divider")} />
                {
                    isAmountInput ?
                        <AmountInput
                            newInput
                            className={element("end")}
                            size={size}
                            onChange={onChange}
                            mask={maskEnd}
                            maskChar={null}
                            name={nameEnd}
                            value={valueEnd}
                            error={errorEnd}
                            placeholder={placeholderEnd}
                            isClearable={isClearable}
                            onFocus={_onFocusEnd}
                            onBlur={_onBlurEnd}
                            disabled={disabled}
                            pattern={pattern}
                            icon={iconEnd}
                        /> :
                        <NmInputV2
                            className={element("end")}
                            size={size}
                            onChange={onChange}
                            mask={maskEnd}
                            maskChar={null}
                            name={nameEnd}
                            value={valueEnd}
                            error={errorEnd}
                            placeholder={placeholderEnd}
                            isClearable={isClearable}
                            onFocus={_onFocusEnd}
                            onBlur={_onBlurEnd}
                            disabled={disabled}
                            pattern={pattern}
                            icon={iconEnd}
                        />
                }
            </div>
            {
                error &&
                <ErrorTooltip error={error} />
            }
        </div>
    );
};

export default NmRangeInput;