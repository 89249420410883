import {all, put, takeEvery} from "@redux-saga/core/effects";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/client/speciality/offer";

const CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST = "CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST";
const CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS = "CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS";
const CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR = "CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR";

const CLIENT_SPECIALITY_OFFERS_REQUEST = "CLIENT_SPECIALITY_OFFERS_REQUEST";
const CLIENT_SPECIALITY_OFFERS_SUCCESS = "CLIENT_SPECIALITY_OFFERS_SUCCESS";
const CLIENT_SPECIALITY_OFFERS_ERROR = "CLIENT_SPECIALITY_OFFERS_ERROR";

const CLIENT_SPECIALITY_OFFERS_DICTIONARY_REQUEST = "CLIENT_SPECIALITY_OFFERS_DICTIONARY_REQUEST";
const CLIENT_SPECIALITY_OFFERS_DICTIONARY_SUCCESS = "CLIENT_SPECIALITY_OFFERS_DICTIONARY_SUCCESS";
const CLIENT_SPECIALITY_OFFERS_DICTIONARY_ERROR = "CLIENT_SPECIALITY_OFFERS_DICTIONARY_ERROR";


const CLIENT_SPECIALITY_OFFERS_DECISION_REQUEST = "CLIENT_SPECIALITY_OFFERS_DECISION_REQUEST";
const CLIENT_SPECIALITY_OFFERS_DECISION_SUCCESS = "CLIENT_SPECIALITY_OFFERS_DECISION_SUCCESS";
const CLIENT_SPECIALITY_OFFERS_DECISION_ERROR = "CLIENT_SPECIALITY_OFFERS_DECISION_ERROR";

const CLIENT_SPECIALITY_OFFERS_EDIT_REQUEST = "CLIENT_SPECIALITY_OFFERS_EDIT_REQUEST";
const CLIENT_SPECIALITY_OFFERS_EDIT_SUCCESS = "CLIENT_SPECIALITY_OFFERS_EDIT_SUCCESS";
const CLIENT_SPECIALITY_OFFERS_EDIT_ERROR = "CLIENT_SPECIALITY_OFFERS_EDIT_ERROR";

const CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST = "CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST";
const CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS = "CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS";
const CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR = "CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR";

const initial = {
    progress: false,
    nonDecisionCount: 0,
    offers: [],
    isOffersLoaded: false,
    dictionary: [],
    isDictionaryLoaded: false,
    clientSpecialityOffers: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS:
        return {
            ...state,
            progress: false,
            nonDecisionCount: payload,
        };
    case CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFERS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_SPECIALITY_OFFERS_SUCCESS:
        return {
            ...state,
            progress: false,
            offers: payload,
            isOffersLoaded: true,
        };
    case CLIENT_SPECIALITY_OFFERS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFERS_DICTIONARY_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_SPECIALITY_OFFERS_DICTIONARY_SUCCESS:
        return {
            ...state,
            progress: false,
            dictionary: payload,
            isDictionaryLoaded: true,
        };
    case CLIENT_SPECIALITY_OFFERS_DICTIONARY_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFERS_DECISION_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_SPECIALITY_OFFERS_DECISION_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFERS_DECISION_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFERS_EDIT_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_SPECIALITY_OFFERS_EDIT_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case CLIENT_SPECIALITY_OFFERS_EDIT_ERROR:
        return {
            ...state,
            progress: false,
        };
    default:
        return state;
    }
};

export const getNonDecisionCount = () => ({
    type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST,
});

export const getSpecialityOffers = payload => ({
    type: CLIENT_SPECIALITY_OFFERS_REQUEST,
    payload,
});

export const getSpecialityOffersDictionary = () => ({
    type: CLIENT_SPECIALITY_OFFERS_DICTIONARY_REQUEST,
});

export const setSpecialityOffersDecision = payload => ({
    type: CLIENT_SPECIALITY_OFFERS_DECISION_REQUEST,
    payload,
});

export const setSpecialityOffersEdit = payload => ({
    type: CLIENT_SPECIALITY_OFFERS_EDIT_REQUEST,
    payload,
});

export const findByOrderAndClientIds = payload => ({
    type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST,
    payload,
});

export const findByOrderAndClientIdsSaga = function* ({payload}) {
    try {
        const {
            callBack,
            reqData,
        } = payload;

        const result = yield request.post(`${controller}/findByOrderAndClientIds`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        if (callBack) {
            callBack(result);
        }

        yield put({
            type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_ERROR,
            payload: error,
        });
    }
};

export const getNonDecisionCountSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getNonDecisionCount`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        yield put({
            type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_ERROR,
            payload: error,
        });
    }
};

//POST /api/client/speciality/offer/getPage
export const getSpecialityOffersSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFERS_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_ERROR,
            payload: error,
        });
    }
};

//GET /api/client/speciality/offer/getOfferedClientDictionary
export const getSpecialityOffersDictionarySaga = function* () {
    try {
        const result = yield request.get(`${controller}/getOfferedClientDictionary`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFERS_DICTIONARY_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_DICTIONARY_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_DICTIONARY_ERROR,
            payload: error,
        });
    }
};

//POST /api/client/speciality/offer/decision
export const setSpecialityOffersDecisionSaga = function* ({payload}) {
    try {
        const {accept} = payload;

        const result = yield request.post(`${controller}/decision`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFERS_DECISION_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        toastSuccess(accept ? "Вид деятельности добавлен в справочник" : "Вид деятельности отклонён");

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_DECISION_SUCCESS,
            payload: result,
        });

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_REQUEST,
            payload: {
                clientIdFilter: "",
                clientNameFilter: "",
                pageNum: 1,
                pageSize: 25,
            },
        });

        yield put({
            type: CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST,
        });

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_DICTIONARY_REQUEST,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_DECISION_ERROR,
            payload: error,
        });
    }
};

//POST /api/client/speciality/offer/edit
export const setSpecialityOffersEditSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/edit`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SPECIALITY_OFFERS_EDIT_ERROR,
                payload: errorMessage,
            });

            return {done: true};
        }

        toastSuccess("Изменения успешно сохранены");

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_EDIT_SUCCESS,
            payload: result,
        });

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_REQUEST,
            payload: {
                clientIdFilter: "",
                clientNameFilter: "",
                pageNum: 1,
                pageSize: 25,
            },
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SPECIALITY_OFFERS_EDIT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_SPECIALITY_OFFER_NON_DECISION_COUNT_REQUEST, getNonDecisionCountSaga),
        takeEvery(CLIENT_SPECIALITY_OFFERS_REQUEST, getSpecialityOffersSaga),
        takeEvery(CLIENT_SPECIALITY_OFFERS_DICTIONARY_REQUEST, getSpecialityOffersDictionarySaga),
        takeEvery(CLIENT_SPECIALITY_OFFERS_DECISION_REQUEST, setSpecialityOffersDecisionSaga),
        takeEvery(CLIENT_SPECIALITY_OFFERS_EDIT_REQUEST, setSpecialityOffersEditSaga),
        takeEvery(CLIENT_SPECIALITY_OFFER_FIND_BY_ORDER_AND_CLIENT_ID_REQUEST, findByOrderAndClientIdsSaga),
    ]);
}