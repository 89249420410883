import {ls, USER_ROLE} from "../../../utils/localstorage";

import {
    LINK_CONTRACTOR_BANKS_LIST,
    LINK_CONTRACTOR_CARD_EVENT_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_LIST,
    LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_TASK_LIST} from "../../../constants/links";
import {isClientUser, RNKO} from "../../../constants/roles";

export const getContractorCardRouteAccess = (params) => {
    const {
        path,
    } = params;

    const role = ls(USER_ROLE);

    if (
        role === RNKO &&
        ![
            LINK_CONTRACTOR_REVIEWS_LIST,
            LINK_CONTRACTOR_PERSONAL_INFO,
            LINK_CONTRACTOR_BANKS_LIST,
        ].includes(path)
    ) {
        return false;
    }

    if (
        isClientUser(role) &&
        ![
            LINK_CONTRACTOR_ORDER_LIST,
            LINK_CONTRACTOR_TASK_LIST,
            LINK_CONTRACTOR_CARD_EVENT_LOG,
            LINK_CONTRACTOR_CARD_EVENT_LOG_LIST,
            LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG,
            LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY,
            LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG,
            LINK_CONTRACTOR_DOCUMENT_LIST,
            LINK_CONTRACTOR_PROFILE,
            LINK_CONTRACTOR_REVIEWS_LIST,
            LINK_CONTRACTOR_PERSONAL_INFO,
        ].includes(path)
    ) {
        return true;
    }

    return true;
};