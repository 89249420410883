import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    DELETE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
    DELETE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST, DELETE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS,
} from "./actions";

import request from "../../utils/postman";
import {toastError} from "../../utils/toastHelper";

// POST /api/contractors/{contractorId}/note-for-client
const updateContractorNoteForClient = function* ({payload}) {
    const {
        onSuccess,
        contractorId,
        ...reqData
    } = payload;

    try {
        const result = yield request.post(`/contractors/${contractorId}/note-for-client`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
            payload: error,
        });
    }
};

// DELETE /api/contractors/{contractorId}/note-for-client
const deleteContractorNoteForClient = function* ({payload}) {
    const {
        onSuccess,
        contractorId,
    } = payload;

    try {
        const result = yield request.delete(`/contractors/${contractorId}/note-for-client`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: DELETE_CONTRACTOR_NOTE_FOR_CLIENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CONTRACTOR_NOTE_FOR_CLIENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST, updateContractorNoteForClient),
        takeEvery(DELETE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST, deleteContractorNoteForClient),
    ]);
}