import React from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import FilterObject from "../../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../../components/ActualComponents/FilterProject";
import FilterSpecialities from "../../../../../components/ActualComponents/FilterSpecialities";
import NmDadata from "../../../../../components/ActualComponents/NmDadata";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import {DROPDOWN_ALL_NO_YES_OPTIONS} from "../../../../../constants/dropdown";

import "./style.sass";

function OrderTemplatesFilter(props){
    const {
        clientId,
        submitFilter,
        initForm,
        filter = {},
        setFilter,
        onChangeFilter,
        setIsSearch,
    } = props;

    const {
        objectIdsFilter = [],
        projectIdsFilter,
        specialityIds,
        name,
        addressFiasIds,
        hasOrderWorkReportTemplateFilter,
        templateObjectId,
    } = filter;

    const submit = () => {
        submitFilter(filter);
    };

    const clear = () => {
        submitFilter({
            ...initForm,
        });

        setFilter({
            ...initForm,
        });

        setIsSearch(false);
    };

    return (
        <NmForm
            addMargin
        >
            <FilterButtonsV2
                className="order-templates-filter__filter-buttons"
                onClear={clear}
                onSearch={submit}
            />
            <FilterProject
                clientId={clientId}
                label="Название проекта"
                name="projectIdsFilter"
                search
                onChange={onChangeFilter}
                multiple
                projectIdsFilter={projectIdsFilter}
                isActiveStyle={projectIdsFilter.length !== 0}
            />
            <FilterObject
                clientId={clientId}
                projectIdsFilter={projectIdsFilter}
                size="lg"
                label="Название объекта"
                name="objectIdsFilter"
                search
                onChange={onChangeFilter}
                objectIdsFilter={objectIdsFilter}
                isActiveStyle={objectIdsFilter.length !== 0}
            />
            <NmInputV2
                size="lg"
                label="ID объекта"
                name="templateObjectId"
                value={templateObjectId}
                onChange={onChangeFilter}
            />
            <NmInputV2
                size="lg"
                label="Название шаблона заказа"
                name="name"
                value={name}
                onChange={onChangeFilter}
            />
            <NmDropdownV2
                size="lg"
                label="Наличие шаблона акта"
                name="hasOrderWorkReportTemplateFilter"
                options={DROPDOWN_ALL_NO_YES_OPTIONS}
                value={hasOrderWorkReportTemplateFilter}
                onChange={onChangeFilter}
            />
            <NmDadata
                size="lg"
                label="Город"
                multiple
                name="addressFiasIds"
                onChange={onChangeFilter}
                isAllSelectionShown={false}
                fromBound="city"
                toBound="settlement"
                initialAddressIds={addressFiasIds}
                onSubmit={submit}
            />
            <FilterSpecialities
                label="Вид деятельности"
                name="specialityIds"
                onChange={onChangeFilter}
                onSubmit={submit}
                value={specialityIds}
                multiple
            />
        </NmForm>
    );
}

export default React.memo(OrderTemplatesFilter);