import React, {Component} from "react";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const STEP = {
    FIRST: 0,
};

class Steps extends Component {
    constructor(props) {
        super(props);

        const [block, element] = bem("steps", props.className);

        this.block = block;
        this.element = element;
    }

    static propTypes = {
        items: PropTypes.array,
        activeItems: PropTypes.array,
        className: PropTypes.string,
        withAdditionalParams: PropTypes.bool,
    };

    static defaultProps = {
        items: [],
        activeItems: [STEP.FIRST],
        withAdditionalParams: false,
        className: "",
    };

    renderItemStepNumber = ({stepNumber, isCompletedStep = false}) => {
        return (
            <span className={this.element("number", {completed: isCompletedStep})}>
                {stepNumber}
            </span>
        );
    };

    isActiveStep = (item, index) => {
        const {
            withAdditionalParams,
            activeItems,
            activeStep,
        } = this.props;

        if (activeStep) {
            return item.value === activeStep;
        }

        const includesValueCheck = withAdditionalParams ? item.value : index;

        return activeItems.includes(includesValueCheck);
    };

    render() {
        const {
            items,
            activeItems,
            className,
        } = this.props;

        return (
            <div className={this.block()}>
                <div className={this.element("line")} />
                {
                    items.map((item, index) => {
                        const stepNumber = index + 1;
                        const isCompletedStep = stepNumber < activeItems.length;

                        return (
                            <div
                                key={index}
                                className={this.element("point", {
                                    completed: isCompletedStep,
                                    active: this.isActiveStep(item, index),
                                    "no-active": !this.isActiveStep(item, index),
                                })}
                            >
                                {
                                    isCompletedStep ?
                                        <>
                                            <span className="steps__icon-check material-icons">
                                                check
                                            </span>
                                            {this.renderItemStepNumber({
                                                stepNumber,
                                                isCompletedStep: true,
                                            })}
                                        </> :
                                        <>
                                            {this.renderItemStepNumber({
                                                stepNumber,
                                                isCompletedStep: false,
                                            })}
                                            <span className="steps__symbol-point">
.
                                            </span>
                                            <span className="steps__space">
                                                &nbsp;
                                            </span>
                                        </>
                                }
                                <div
                                    className={this.element("name", {
                                        completed: isCompletedStep,
                                        active: this.isActiveStep(item, index),
                                    })}
                                >
                                    {item.name}
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }
}

export default Steps;