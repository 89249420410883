import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {taskEdoExportDocument} from "../../../../../ducks/edmStatements";

export function useHeaderActions({
    clientId,
    archived,
    currentStaffId,
    filter,
}) {
    const dispatch = useDispatch();

    const onUnload = () => {
        dispatch(taskEdoExportDocument({
            clientId,
            archived,
            currentStaffId,
            resourceUrl: "statements",
            ...filter,
            getResult: (errorMessage) => {
                if (!errorMessage) {
                    toastSuccess("Выгрузка успешно запущена и в ближайшее время станет доступна в разделе ЭДО → Экспорт");
                }
            },
        }));
    };

    return {
        onUnload,
    };
}