import {
    TASK_LIST_GET_PAGE_ERROR,
    TASK_LIST_GET_PAGE_REQUEST,
    TASK_LIST_GET_PAGE_SUCCESS,
    TASK_UPDATE_STORE,
} from "./actions";

export const getTaskInitialState = () => {
    return {
        list: [],
        pageData: {},
        totalCount: 0,
        progress: false,
    };
};

const initialState = getTaskInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case TASK_LIST_GET_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case TASK_LIST_GET_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    case TASK_LIST_GET_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case TASK_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default: return state;
    }
};