import {useState} from "react";
import {useDispatch} from "react-redux";

import {deleteEdmSubdivision} from "../../../../../ducks/edmSubDivision";

export function useStructuralUtilsActions(fetchList) {
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [removedItem, setRemovedItem] = useState({});

    const dispatch = useDispatch();

    const deleteItem = () => {
        dispatch(deleteEdmSubdivision({
            ...removedItem,
            onSuccess: () => {
                fetchList();
            },
        }));
    };

    const toggleConfirm = (item = {}) => {
        setIsShowConfirm(prevState => !prevState);
        setRemovedItem({...item});
    };

    return {
        isShowConfirm,
        removedItem,
        toggleConfirm,
        deleteItem,
    };
}