import {createSelector} from "reselect";

export const eventsContractorSelector = state => state.eventsContractor;
export const eventsContractorAggregatedParamsByMailingProgress = createSelector(
    eventsContractorSelector,
    ({aggregatedParamsByMailingProgress}) => aggregatedParamsByMailingProgress,
);
export const eventsContractorAggregatedParamsByMailing = createSelector(
    eventsContractorSelector,
    ({aggregatedParamsByMailing}) => aggregatedParamsByMailing,
);
export const eventsContractorAggregatedParamsBySmartLinkProgress = createSelector(
    eventsContractorSelector,
    ({aggregatedParamsBySmartLinkProgress}) => aggregatedParamsBySmartLinkProgress,
);
export const eventsContractorAggregatedParamsBySmartLink = createSelector(
    eventsContractorSelector,
    ({aggregatedParamsBySmartLink}) => aggregatedParamsBySmartLink,
);