import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../components/CardApp";
import IconTextApp from "../../../components/IconTextApp";
import LabelTextApp from "../../../components/LabelTextApp";
import {REQUISITES_FIELD_NAME} from "../../../constants/requisites";
import {Divider} from "semantic-ui-react";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getPartObj} from "../../../utils/objectHelper";
import {
    phoneFormat,
    removePhoneMask,
} from "../../../utils/stringFormat";
import validate from "../../../utils/validate";

import {CLIENT_FILE_TYPES, FEDERAL_MIGRATION_FIELD} from "../../../constants/clientList";
import {ADMIN} from "../../../constants/roles";
import {
    BANK_FIELDS,
    BLOCK_DATA_NAME,
    OPERTATOR_FIELDS,
    POSITIONS,
    REPRESENTATIVE_FIELDS} from "../../../constants/settingsRepresentative";
import {
    SETTINGS_INFO_RULE,
} from "../../../constants/validationRules";

import {
    addClientFileToFileStore,
    clearFileStoreReduxStore,
    clearFileStoreSignatureReduxStore,
    clearFileStoreStampReduxStore,
    fileClientSignatureSelector,
    fileClientStampSelector,
    getFileClientSignature,
    getFileClientStamp,
} from "../../../ducks/fileStore";
import {
    getNaimixInfo,
    getNaimixInfoCardSelector,
    naimixInfoProgressSelector,
    updateFieldNaimixInfoStore,
    updateNaimixInfo} from "../../../ducks/naimixInfo";

import {fileType, naimixInfoType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class SettingsInfo extends Component {
    static propTypes = {
        progressUpdate: PropTypes.bool,
        clientSignature: fileType,
        clientStamp: fileType,
        naimixInfo: naimixInfoType,
        updateNaimixInfo: PropTypes.func,
        getNaimixInfo: PropTypes.func,
        getFileClientSignature: PropTypes.func,
        getFileClientStamp: PropTypes.func,
        addClientFileToFileStore: PropTypes.func,
        clearFileStoreReduxStore: PropTypes.func,
        clearFileStoreSignatureReduxStore: PropTypes.func,
        clearFileStoreStampReduxStore: PropTypes.func,
        updateFieldNaimixInfoStore: PropTypes.func,
    };

    static defaultProps = {
        progressUpdate: false,
        clientSignature: {},
        clientStamp: {},
        naimixInfo: {},
        updateNaimixInfo: () => {
        },
        getNaimixInfo: () => {
        },
        getFileClientSignature: () => {
        },
        getFileClientStamp: () => {
        },
        addClientFileToFileStore: () => {
        },
        clearFileStoreReduxStore: () => {
        },
        clearFileStoreSignatureReduxStore: () => {
        },
        clearFileStoreStampReduxStore: () => {
        },
        updateFieldNaimixInfoStore: () => {
        },
    };

    static EDIT_BLOCKS = {
        REPRESENTATIVE: "representative",
        OPERATOR: "operator",
        BANK_INFO: "bankInfo",
    };

    constructor() {
        super();

        this.state = {
            isEditOperatorInfo: false,
            isEditBankInfo: false,
            isEditRepresentativeInfo: false,
            isEditStamp: false,
            isEditSignature: false,
            fileStamp: [],
            fileSignature: [],
            editNaimixInfo: {},
            editNaimixInfoOperator: {},
            errorsObj: {},
            representativeInfo: {},
            operatorInfo: {},
            bankInfo: {},
        };

        this.role = ls(USER_ROLE);
        this.isEditable = this.role === ADMIN;

        this.representativeRule = getPartObj(SETTINGS_INFO_RULE, REPRESENTATIVE_FIELDS);
        this.operatorRule = getPartObj(SETTINGS_INFO_RULE, OPERTATOR_FIELDS);
        this.bankRule = getPartObj(SETTINGS_INFO_RULE, BANK_FIELDS);


        this.clientId = "00000000-0000-0000-0000-000000000000";
    }

    componentDidMount() {
        this.fetchClientSignature();
        this.fetchClientStamp();
        this.fetchNaimixInfo();
    }

    componentWillUnmount() {
        const {updateFieldNaimixInfoStore, clearFileStoreReduxStore, clearFileStoreSignatureReduxStore, clearFileStoreStampReduxStore} = this.props;

        updateFieldNaimixInfoStore("card", {});
        clearFileStoreReduxStore();
        clearFileStoreSignatureReduxStore();
        clearFileStoreStampReduxStore();
    }

    static getDerivedStateFromProps(newProps, state) {
        const {naimixInfo: oldInfo} = state;
        const {naimixInfo} = newProps;

        if (JSON.stringify(naimixInfo) !== JSON.stringify(oldInfo)) {
            return {
                ...state,
                naimixInfo,
                representativeInfo: getPartObj(naimixInfo, REPRESENTATIVE_FIELDS),
                operatorInfo: getPartObj(naimixInfo, OPERTATOR_FIELDS),
                bankInfo: getPartObj(naimixInfo, BANK_FIELDS),
            };
        }
        return null;
    }

    fetchClientSignature = () => {
        const {getFileClientSignature} = this.props;
        getFileClientSignature(this.clientId);
    };

    fetchClientStamp = () => {
        const {getFileClientStamp} = this.props;

        getFileClientStamp(this.clientId);
    };

    fetchNaimixInfo = () => {
        const {getNaimixInfo} = this.props;

        getNaimixInfo();
    };

    isValidForm(rule, editBlock) {
        const contractor = {...this.state[editBlock]};

        if (editBlock === BLOCK_DATA_NAME.OPERATOR) {
            contractor[REQUISITES_FIELD_NAME.PHONE] = removePhoneMask(contractor[REQUISITES_FIELD_NAME.PHONE]);
            contractor[REQUISITES_FIELD_NAME.FAX] = removePhoneMask(contractor[REQUISITES_FIELD_NAME.FAX]);
        }

        const error = validate(contractor, rule);

        this.setState({errorsObj: {...error}});

        return Object.values(error).length === 0;
    }


    updateInfo = (updatedData) => {
        const {updateNaimixInfo} = this.props;
        const {
            naimixInfo,
        } = this.state;

        const data = {
            ...naimixInfo,
            ...updatedData,
        };

        const requestData = {
            ...data,
            [REQUISITES_FIELD_NAME.PHONE]: removePhoneMask(data[REQUISITES_FIELD_NAME.PHONE]),
            [REQUISITES_FIELD_NAME.FAX]: removePhoneMask(data[REQUISITES_FIELD_NAME.FAX]),
        }
        ;
        updateNaimixInfo(requestData);

    };

    uploadFile = (type) => {
        let files = [];

        const isStamp = type === "stamp";

        if (isStamp) {
            const {fileStamp} = this.state;
            files = fileStamp;
        } else {
            const {fileSignature} = this.state;
            files = fileSignature;
        }

        const [file] = files;

        if (!file) {
            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        const {
            addClientFileToFileStore,
            clearFileStoreStampReduxStore,
            clearFileStoreSignatureReduxStore,
        } = this.props;

        const clientId = this.clientId;

        addClientFileToFileStore({
            clientId,
            clientFileType: isStamp ? CLIENT_FILE_TYPES.STAMP : CLIENT_FILE_TYPES.SIGNATURE,
            file: formData,
        });

        isStamp ? clearFileStoreStampReduxStore() : clearFileStoreSignatureReduxStore();

        if (isStamp) {
            this.setState(prevState => ({
                ...prevState,
                fileStamp: [],
                isEditStamp: !prevState.isEditStamp,
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                fileSignature: [],
                isEditSignature: !prevState.isEditSignature,
            }));
        }
    };

    handleOnChange = (blockName) => {
        return (e, {value, name}) => {
            this.setState(prevState => ({
                ...prevState,
                [blockName]: {
                    ...prevState[blockName],
                    [name]: value,
                },
            }),
            );
        };
    };

    onDropStamp = (files) => {
        this.setState(prevState => ({
            ...prevState,
            fileStamp: files,
        }));
    };

    onDropSignature = (files) => {
        this.setState(prevState => ({
            ...prevState,
            fileSignature: files,
        }));
    };

    cancelOperatorEdit = () => {
        const {operatorInfoBeforeEdit} = this.state;

        this.setState({
            isEditOperatorInfo: false,
            errorsObj: {},
            operatorInfo: operatorInfoBeforeEdit,
        });
    };

    toggleOperatorEdit = (isEditOperatorInfo) => {
        const {operatorInfo} = this.state;

        if (isEditOperatorInfo) {
            this.setState({isEditOperatorInfo, operatorInfoBeforeEdit: operatorInfo});
            return;
        }

        if (!this.isValidForm(this.operatorRule, BLOCK_DATA_NAME.OPERATOR)) {
            return;
        }

        this.setState({isEditOperatorInfo}, () => {
            this.updateInfo(operatorInfo);
        });
    };

    cancelBankInfoEdit = () => {
        const {bankInfoBeforeEdit} = this.state;

        this.setState({
            isEditBankInfo: false,
            errorsObj: {},
            bankInfo: bankInfoBeforeEdit,
        });
    };

    toggleBankInfoEdit = (isEditBankInfo) => {
        const {bankInfo} = this.state;

        if (isEditBankInfo) {
            this.setState({isEditBankInfo, bankInfoBeforeEdit: bankInfo});
            return;
        }

        if (!this.isValidForm(this.bankRule, BLOCK_DATA_NAME.BANK)) {
            return;
        }

        this.setState({isEditBankInfo}, () => {
            this.updateInfo(bankInfo);
        });
    };

    cancelRepresentativeInfoEdit = () => {
        const {representativeInfoBeforeEdit} = this.state;

        this.setState({
            isEditRepresentativeInfo: false,
            errorsObj: {},
            representativeInfo: representativeInfoBeforeEdit,
        });
    };

    toggleRepresentativeInfoEdit = (isEditRepresentativeInfo) => {
        const {representativeInfo} = this.state;

        if (isEditRepresentativeInfo) {
            this.setState({isEditRepresentativeInfo, representativeInfoBeforeEdit: representativeInfo});
            return;
        }

        if (!this.isValidForm(this.representativeRule, BLOCK_DATA_NAME.REPRESENTATIVE)) {
            return;
        }

        this.setState({isEditRepresentativeInfo}, () => {
            this.updateInfo(representativeInfo);
        });
    };

    cancelStampEdit = () => {
        const {fileStampBeforeEdit} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEditStamp: false,
            fileStamp: fileStampBeforeEdit,
        }));
    };

    toggleStampEdit = () => {
        const {isEditStamp, fileStamp} = this.state;

        if (isEditStamp) {
            this.uploadFile("stamp");
        } else {
            this.setState({
                fileStampBeforeEdit: fileStamp,
            });
        }


        this.setState(() => ({
            isEditStamp: !isEditStamp,
        }));
    };

    cancelSignatureEdit = () => {
        const {fileSignatureBeforeEdit} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEditSignature: false,
            fileSignature: fileSignatureBeforeEdit,
        }));
    };

    toggleSignatureEdit = () => {
        const {isEditSignature, fileSignature} = this.state;

        if (isEditSignature) {
            this.uploadFile("signature");
            this.setState(prevState => ({
                ...prevState,
            }));
        } else {
            this.setState({
                fileSignatureBeforeEdit: fileSignature,
            });
        }


        this.setState(() => ({
            isEditSignature: !isEditSignature,
        }));
    };

    handleOnChangeAddress(blockName, compName, withZipCode) {
        return (data) => {
            this.handleOnChange(blockName)(null, {
                value: withZipCode ? (data.unrestricted_value ? data.unrestricted_value : data.value) : data.value,
                name: compName,
            });
        };
    }

    renderOperatorViewBlock(naimixInfo) {
        const {t} = this.props;

        return (
            <div>
                <LabelTextApp
                    label={t("settings-info-operator-block.legal-entity-name")}
                    text={naimixInfo[REQUISITES_FIELD_NAME.OPERATOR]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label={t("settings-info-operator-block.uprlp-address")}
                    text={
                        <IconTextApp
                            icon="map marker alternate"
                            text={naimixInfo[REQUISITES_FIELD_NAME.ADDRESS]}
                            colorText="#174A97"
                            iconColor="#174A97"
                        />
                    }
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label={t("settings-info-operator-block.postal-address")}
                    text={
                        <IconTextApp
                            icon="map marker alternate"
                            text={naimixInfo[REQUISITES_FIELD_NAME.MAIL_ADDRESS]}
                            colorText="#174A97"
                            iconColor="#174A97"
                        />
                    }
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label={t("settings-info-operator-block.phone-number")}
                    text={
                        <IconTextApp
                            icon="phone"
                            text={
                                phoneFormat(naimixInfo[REQUISITES_FIELD_NAME.PHONE]) ||
                                t("is-not-specified.content")
                            }
                        />
                    }
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label={t("settings-info-operator-block.website")}
                    text={naimixInfo[REQUISITES_FIELD_NAME.WEB_SITE]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="Email"
                    text={naimixInfo[REQUISITES_FIELD_NAME.EMAIL]}
                />
                <Divider className="divider-fluid" />
            </div>
        );
    }


    renderOperatorEditBlock(naimixInfo) {
        const {errorsObj} = this.state;
        const {t} = this.props;

        return (
            <NmForm>
                <NmInputV2
                    size="lg"
                    label={t("settings-info-operator-block.legal-entity-name")}
                    name={REQUISITES_FIELD_NAME.OPERATOR}
                    required
                    error={errorsObj[`${REQUISITES_FIELD_NAME.OPERATOR}ErrorMessage`]}
                    placeholder={t("settings-info-operator-block.legal-entity-name")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.OPERATOR]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.OPERATOR)}
                />
                <Divider className="divider-fluid" />
                <NmDadataInput
                    size="lg"
                    label={t("settings-info-operator-block.uprlp-address")}
                    query={naimixInfo[REQUISITES_FIELD_NAME.ADDRESS]}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.ADDRESS}ErrorMessage`]}
                    name={naimixInfo[REQUISITES_FIELD_NAME.ADDRESS]}
                    onChange={this.handleOnChangeAddress(BLOCK_DATA_NAME.OPERATOR, FEDERAL_MIGRATION_FIELD.ADDRESS)}
                    placeholder={t("settings-info-operator-block.uprlp-address")}
                />
                <Divider className="divider-fluid" />
                <NmDadataInput
                    size="lg"
                    label={t("settings-info-operator-block.postal-address")}
                    query={naimixInfo[REQUISITES_FIELD_NAME.MAIL_ADDRESS]}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.MAIL_ADDRESS}ErrorMessage`]}
                    name={REQUISITES_FIELD_NAME.MAIL_ADDRESS}
                    onChange={this.handleOnChangeAddress(BLOCK_DATA_NAME.OPERATOR, REQUISITES_FIELD_NAME.MAIL_ADDRESS, true)}
                    placeholder={t("settings-info-operator-block.postal-address")}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    name={REQUISITES_FIELD_NAME.PHONE}
                    mask="+7 999 999 9999"
                    placeholder={t("settings-info-operator-block.phone-number")}
                    className="order-modal__input"
                    type="tel"
                    required
                    value={naimixInfo[REQUISITES_FIELD_NAME.PHONE]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.OPERATOR)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.PHONE}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label={t("settings-info-operator-block.website")}
                    name={REQUISITES_FIELD_NAME.WEB_SITE}
                    required
                    placeholder={t("settings-info-operator-block.website")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.WEB_SITE]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.OPERATOR)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.WEB_SITE}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="Email"
                    name={REQUISITES_FIELD_NAME.EMAIL}
                    required
                    placeholder="Email"
                    value={naimixInfo[REQUISITES_FIELD_NAME.EMAIL]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.OPERATOR)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.EMAIL}ErrorMessage`]}
                />
            </NmForm>
        );
    }

    renderOperatorForm() {
        const {isEditOperatorInfo, operatorInfo} = this.state;
        const {t} = this.props;

        return (
            <CardApp
                classNameHeader="settings-info__card-header"
                title={t("settings-info-operator-block.title")}
                className="client-card-block settings-info__operator-block"
                onClickIcon={() => {
                    this.toggleOperatorEdit(!isEditOperatorInfo);
                }}
                onClickCancelIcon={() => {
                    this.cancelOperatorEdit();
                }}
                isEditable={this.isEditable}
                isEdit={isEditOperatorInfo}
            >
                {isEditOperatorInfo ? this.renderOperatorEditBlock(operatorInfo) : this.renderOperatorViewBlock(operatorInfo)}
            </CardApp>
        );
    }

    renderBankInfoViewBlock(naimixInfo) {
        const {t} = this.props;

        return (
            <div>
                <LabelTextApp
                    label={t("settings-info-payment-details-block.bank-name")}
                    text={naimixInfo[REQUISITES_FIELD_NAME.BANK_NAME]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="ОГРН"
                    text={naimixInfo[REQUISITES_FIELD_NAME.OGRN]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="ИНН"
                    text={naimixInfo[REQUISITES_FIELD_NAME.INN]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="КПП"
                    text={naimixInfo[REQUISITES_FIELD_NAME.KPP]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="Р/с"
                    text={naimixInfo[REQUISITES_FIELD_NAME.RS]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="К/с"
                    text={naimixInfo[REQUISITES_FIELD_NAME.KS]}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label="БИК"
                    text={naimixInfo[REQUISITES_FIELD_NAME.BIK]}
                />
                <Divider className="divider-fluid" />
            </div>
        );
    }

    renderBankInfoEditBlock(naimixInfo) {
        const {errorsObj} = this.state;
        const {t} = this.props;

        return (
            <NmForm>
                <NmInputV2
                    size="lg"
                    label={t("settings-info-payment-details-block.bank-name")}
                    name={REQUISITES_FIELD_NAME.BANK_NAME}
                    required
                    placeholder={t("settings-info-payment-details-block.bank-name")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.BANK_NAME]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.BANK_NAME}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="ОГРН"
                    name={REQUISITES_FIELD_NAME.OGRN}
                    required
                    placeholder="ОГРН"
                    value={naimixInfo[REQUISITES_FIELD_NAME.OGRN]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.OGRN}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="ИНН"
                    name={REQUISITES_FIELD_NAME.INN}
                    required
                    placeholder="ИНН"
                    value={naimixInfo[REQUISITES_FIELD_NAME.INN]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.INN}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="КПП"
                    name={REQUISITES_FIELD_NAME.KPP}
                    required
                    placeholder="КПП"
                    value={naimixInfo[REQUISITES_FIELD_NAME.KPP]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.KPP}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="Р/с"
                    name={REQUISITES_FIELD_NAME.RS}
                    required
                    placeholder="Р/с"
                    value={naimixInfo[REQUISITES_FIELD_NAME.RS]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.RS}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="К/с"
                    name={REQUISITES_FIELD_NAME.KS}
                    required
                    placeholder="К/с"
                    value={naimixInfo[REQUISITES_FIELD_NAME.KS]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.KS}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label="БИК"
                    name={REQUISITES_FIELD_NAME.BIK}
                    required
                    placeholder="БИК"
                    value={naimixInfo[REQUISITES_FIELD_NAME.BIK]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.BANK)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.BIK}ErrorMessage`]}
                />
            </NmForm>
        );
    }

    renderBankInfoForm() {
        const {isEditBankInfo, bankInfo} = this.state;
        const {t} = this.props;

        return (
            <CardApp
                classNameHeader="settings-info__card-header"
                title={t("settings-info-payment-details-block.title")}
                className="client-card-block"
                onClickIcon={() => {
                    this.toggleBankInfoEdit(!isEditBankInfo);
                }}
                onClickCancelIcon={() => {
                    this.cancelBankInfoEdit();
                }}
                isEditable={this.isEditable}
                isEdit={isEditBankInfo}
            >
                {isEditBankInfo ? this.renderBankInfoEditBlock(bankInfo) : this.renderBankInfoViewBlock(bankInfo)}
            </CardApp>
        );
    }

    renderRepresentativeViewBlock(naimixInfo) {
        const {t} = this.props;

        return (<div>
            <LabelTextApp
                label={t("settings-info-representative-block.first-name")}
                text={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_FIRST_NAME]}
            />
            <Divider className="divider-fluid" />
            <LabelTextApp
                label={t("settings-info-representative-block.last-name")}
                text={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_LAST_NAME]}
            />
            <Divider className="divider-fluid" />
            <LabelTextApp
                label={t("settings-info-representative-block.patronymic")}
                text={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_PATRONYMIC]}
            />
            <Divider className="divider-fluid" />
            <LabelTextApp
                label={t("settings-info-representative-block.position")}
                text={naimixInfo[REQUISITES_FIELD_NAME.POSITION]}
            />
            <Divider className="divider-fluid" />
            <LabelTextApp
                label={t("settings-info-representative-block.full-name-representative-genitive")}
                text={naimixInfo[REQUISITES_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO]}
            />
            <Divider className="divider-fluid" />
            <LabelTextApp
                label={t("settings-info-representative-block.name-details-document-genitive")}
                text={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_DOCUMENT]}
            />
            <Divider className="divider-fluid" />
        </div>);
    }

    renderRepresentativeEditBlock(naimixInfo) {
        const {errorsObj} = this.state;
        const {t} = this.props;

        return (
            <NmForm>
                <NmInputV2
                    size="lg"
                    label={t("settings-info-representative-block.first-name")}
                    name={REQUISITES_FIELD_NAME.REPRESENTATIVE_FIRST_NAME}
                    required
                    placeholderText={t("settings-info-representative-block.first-name")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_FIRST_NAME]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.REPRESENTATIVE)}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.REPRESENTATIVE_FIRST_NAME}ErrorMessage`]}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label={t("settings-info-representative-block.last-name")}
                    name={REQUISITES_FIELD_NAME.REPRESENTATIVE_LAST_NAME}
                    required
                    placeholderText={t("settings-info-representative-block.last-name")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_LAST_NAME]}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.REPRESENTATIVE_LAST_NAME}ErrorMessage`]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.REPRESENTATIVE)}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label={t("settings-info-representative-block.patronymic")}
                    name={REQUISITES_FIELD_NAME.REPRESENTATIVE_PATRONYMIC}
                    required
                    placeholderText={t("settings-info-representative-block.patronymic")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_PATRONYMIC]}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.REPRESENTATIVE_PATRONYMIC}ErrorMessage`]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.REPRESENTATIVE)}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label={t("settings-info-representative-block.position")}
                    required
                    error={errorsObj[`${REQUISITES_FIELD_NAME.POSITION}ErrorMessage`]}
                    placeholder={t("settings-info-representative-block.position")}
                    name={REQUISITES_FIELD_NAME.POSITION}
                    options={POSITIONS}
                    value={naimixInfo[REQUISITES_FIELD_NAME.POSITION]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.REPRESENTATIVE)}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label={t("settings-info-representative-block.full-name-representative-genitive")}
                    name={REQUISITES_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO}
                    placeholderText={t("settings-info-representative-block.full-name-representative-genitive")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO]}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO}ErrorMessage`]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.REPRESENTATIVE)}
                />
                <Divider className="divider-fluid" />
                <NmInputV2
                    size="lg"
                    label={t("settings-info-representative-block.name-details-document-genitive")}
                    name={REQUISITES_FIELD_NAME.REPRESENTATIVE_DOCUMENT}
                    required
                    placeholderText={t("settings-info-representative-block.name-details-document-genitive")}
                    value={naimixInfo[REQUISITES_FIELD_NAME.REPRESENTATIVE_DOCUMENT]}
                    error={errorsObj[`${REQUISITES_FIELD_NAME.REPRESENTATIVE_DOCUMENT}ErrorMessage`]}
                    onChange={this.handleOnChange(BLOCK_DATA_NAME.REPRESENTATIVE)}
                />
            </NmForm>
        );
    }

    renderStampEdit() {
        const {fileStamp} = this.state;

        return (
            <DropzoneV2
                isImagePreview
                maxSize={10}
                files={fileStamp}
                format="*.jpeg, *.jpg, *.png, *.bmp, *.gif"
                accept=".jpeg, .jpg, .png, .bmp, .gif"
                onChange={this.onDropStamp}
            />
        );
    }

    renderStampReadOnly() {
        const {clientStamp, t} = this.props;
        const {base64str} = clientStamp || "";

        return (
            <div className="settings-info__image-container">
                {!base64str ?
                    <span
                        className="client-card-block__text"
                    >
                        {t("settings-info-representative-block.file-not-uploaded")}
                    </span>
                    :
                    <img
                        alt={t("settings-info-representative-block.image")}
                        src={` data:image/jpeg;charset=utf-8;base64, ${base64str}`}
                        className="client-card-block__img"
                    />
                }
            </div>
        );
    }

    renderStampForm() {
        const {isEditStamp} = this.state;
        const {t} = this.props;
        return (
            <CardApp
                classNameHeader="settings-info__card-header"
                title={t("settings-info-representative-block.stamp")}
                className="client-card-block"
                isEditable={this.isEditable}
                onClickIcon={this.toggleStampEdit}
                onClickCancelIcon={() => {
                    this.cancelStampEdit();
                }}
                isEdit={isEditStamp}
            >
                {isEditStamp ? this.renderStampEdit() : this.renderStampReadOnly()}
            </CardApp>
        );
    }

    renderSignatureEdit() {
        const {fileSignature} = this.state;

        return (
            <DropzoneV2
                isImagePreview
                maxSize={10}
                files={fileSignature}
                format="*.jpeg, *.jpg, *.png, *.bmp, *.gif"
                accept=".jpeg, .jpg, .png, .bmp, .gif"
                onChange={this.onDropSignature}
            />
        );
    }

    renderSignatureReadOnly() {
        const {clientSignature, t} = this.props;

        const {base64str} = clientSignature;

        return (
            <div className="settings-info__image-container">
                {!base64str ?
                    <span
                        className="client-card-block__text"
                    >
                        {t("settings-info-representative-block.file-not-uploaded")}
                    </span>
                    :
                    <img
                        alt={t("settings-info-representative-block.file")}
                        src={`data:charset=utf-8;base64, ${base64str}`}
                        className="client-card-block__img"
                    />
                }
            </div>
        );
    }

    renderSignatureForm() {
        const {isEditSignature} = this.state;
        const {t} = this.props;

        return (
            <CardApp
                classNameHeader="settings-info__card-header"
                title={t("settings-info-representative-block.facsimile")}
                className="client-card-block mt-4"
                isEditable={this.isEditable}
                onClickIcon={this.toggleSignatureEdit}
                onClickCancelIcon={() => {
                    this.cancelSignatureEdit();
                }}
                isEdit={isEditSignature}
            >
                {isEditSignature ? this.renderSignatureEdit() : this.renderSignatureReadOnly()}
            </CardApp>
        );
    }

    renderRepresentativeForm() {
        const {isEditRepresentativeInfo, representativeInfo} = this.state;
        const {t} = this.props;

        return (
            <CardApp
                classNameHeader="settings-info__card-header"
                title={t("settings-info-representative-block.title")}
                className="client-card-block"
                onClickIcon={() => {
                    this.toggleRepresentativeInfoEdit(!isEditRepresentativeInfo);
                }}
                onClickCancelIcon={() => {
                    this.cancelRepresentativeInfoEdit();
                }}
                isEditable={this.isEditable}
                isEdit={isEditRepresentativeInfo}
            >
                {isEditRepresentativeInfo ? this.renderRepresentativeEditBlock(representativeInfo) : this.renderRepresentativeViewBlock(representativeInfo)}
            </CardApp>
        );
    }

    render() {
        return (
            <>
                <div className="settings-info">
                    <div className="row">
                        <div className="col-16 col-md-8 col-lg-4">
                            {this.renderOperatorForm()}
                        </div>
                        <div className="col-16 col-md-8 col-lg-4 mt-4 mt-md-0">
                            {this.renderBankInfoForm()}
                        </div>
                        <div className="col-16 col-md-8 col-lg-4 mt-4 mt-lg-0">
                            {this.renderRepresentativeForm()}
                        </div>
                        <div className="col-16 col-md-8 col-lg-4 mt-4 mt-lg-0">
                            {this.renderStampForm()}
                            {this.renderSignatureForm()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(
    state => ({
        progressUpdate: naimixInfoProgressSelector(state),
        clientSignature: fileClientSignatureSelector(state),
        clientStamp: fileClientStampSelector(state),
        naimixInfo: getNaimixInfoCardSelector(state),
    }),
    {
        updateNaimixInfo,
        getNaimixInfo,
        getFileClientSignature,
        getFileClientStamp,
        addClientFileToFileStore,
        clearFileStoreReduxStore,
        clearFileStoreSignatureReduxStore,
        clearFileStoreStampReduxStore,
        updateFieldNaimixInfoStore,
    },
)(withTranslation()(SettingsInfo));