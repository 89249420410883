import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import Text from "../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../components/CheckboxList";
import NmBadge from "../../../../components/NmBadge";

import {usePagination} from "../../../../hooks/usePagination";

import bem from "../../../../utils/bem";
import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {COLOR} from "../../../../constants/color";

import {
    getSystemPropertiesHistory,
    listSelector,
    totalCountSelector,
    totalPagesSelector,
} from "../../../../ducks/systemPropertyHistory";

import "./style.sass";

const ClientListSystemPropertiesHistory = (props) => {
    const {
        typeFilter = "PAYMENT_WITHOUT_CONFIRM_NM_ADMIN_TYPE",
    } = props;
    const totalCount = useSelector(totalCountSelector);
    const totalPages = useSelector(totalPagesSelector);
    const listHistory = useSelector(listSelector);

    const [block ] = bem("list-system-properties-history");

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    useEffect(() => {
        const requestData = {
            pageNum,
            pageSize,
            typeFilter,
        };

        const fetchData = () => {
            dispatch(getSystemPropertiesHistory(requestData));
        };

        fetchData();
    }, [pageNum, pageSize]);

    const getRows = () => {
        return listHistory.map((item) => {
            const {
                createDate,
                paymentsWithoutConfirmNmAdmin,
                clientFio,
            } = item;

            return {
                ...item,
                key: item.id,
                contentRow: (
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                            <NmBadge
                                text={paymentsWithoutConfirmNmAdmin ? "Включено" : "Выключено"}
                                mod={paymentsWithoutConfirmNmAdmin ? "green" : "red"}
                                className="me-2"
                            />
                            <Text
                                level="2"
                                color={COLOR.SECONDARY_70}
                            >
                                {dateFormat(convertUtcToLocal(createDate))}
                            </Text>
                        </div>
                        <NmLabelText
                            noWrap
                            label="Инициатор"
                            text={clientFio}
                        />
                    </div>
                ),
            };
        });
    };

    return (
        <div className={block()}>
            <div>
                <CheckboxList
                    rows={getRows()}
                />
            </div>
            <NmPagination
                className="mt-2 mt-md-5 mt-md-7"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onChangePagination={onPaginationChange}
            />
        </div>
    );
};

export default ClientListSystemPropertiesHistory;