import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";

export const ADVERTISEMENT_TOAST_MESSAGE = {
    UPDATE: {
        SUCCESS: "Объявление успешно отредактировано",
    },
    PUBLISH: {
        SUCCESS: "Объявление успешно сохранено",
    },
    ADD_DRAFT: {
        SUCCESS: "Черновик объявления успешно сохранен",
    },
    CLOSE_ADVERTS: {
        SUCCESS: "Задача на удаление объявлений передана в обработку",
    },
    CANCEL_PUBLICATION: {
        SUCCESS: "Задача по отмене публикации передана в обработку",
    },
};

export const ADVERTISEMENT_MASS_OPTION = {
    PUBLISH: "PUBLISH",
    REMOVE: "REMOVE",
};

export const ACTION_MASS_MOBILE_OPTIONS = [
    {
        key: ADVERTISEMENT_MASS_OPTION.PUBLISH,
        text: "Опубликовать объявления",
        value: ADVERTISEMENT_MASS_OPTION.PUBLISH,
    },
    {
        key: ADVERTISEMENT_MASS_OPTION.REMOVE,
        text: "Удалить черновики",
        value: ADVERTISEMENT_MASS_OPTION.REMOVE,
    },
];

export const CONTEXT_MENU_OPTIONS = {
    IMPORT: {
        key: "IMPORT",
        text: "Загрузить список",
        value: "IMPORT",
    },
    GET_CODE_CITY: {
        key: "GET_CODE_CITY",
        text: "Определить код города",
        value: "GET_CODE_CITY",
    },
};

export const ADVERTISEMENT_STATUS = {
    DRAFT: {
        VALUE: "DRAFT",
        TEXT: "Черновик",
    },
    PUBLISHED: {
        VALUE: "PUBLISHED",
        TEXT: "Опубликовано",
    },
    CLOSED: {
        VALUE: "CLOSED",
        TEXT: "Закрыто",
    },
    AWAIT_PUBLISH: {
        TEXT: "Ожидание запуска",
        VALUE: "AWAIT_PUBLISH",
    },
};

export const ADVERTISEMENT_STATUS_OPTIONS = [{
    text: "Показать все",
    key: EMPTY_OPTION_KEY,
    value: EMPTY_OPTION_KEY,
},
...Object.values(ADVERTISEMENT_STATUS).map(({VALUE, TEXT}) => ({
    key: VALUE,
    value: VALUE,
    text: TEXT,
})),
];

export const ADVERTISEMENT_ACTION_TYPE = {
    EDIT: {
        VALUE: "EDIT",
        TEXT: "Редактировать",
    },
    CANCEL_PUBLISH: {
        VALUE: "CANCEL_PUBLISH",
        TEXT: "Отменить публикацию",
    },
    CREATE_DUPLICATE: {
        VALUE: "CREATE_DUPLICATE",
        TEXT: "Создать дубликат",
    },
    DELETE: {
        VALUE: "DELETE",
        TEXT: "Удалить",
    },
};

export const ADVERTISEMENT_ACTION_TYPE_OPTION = {
    EDIT: {
        key: ADVERTISEMENT_ACTION_TYPE.EDIT.VALUE,
        value: ADVERTISEMENT_ACTION_TYPE.EDIT.VALUE,
        text: ADVERTISEMENT_ACTION_TYPE.EDIT.TEXT,
    },
    CANCEL_PUBLISH: {
        key: ADVERTISEMENT_ACTION_TYPE.CANCEL_PUBLISH.VALUE,
        value: ADVERTISEMENT_ACTION_TYPE.CANCEL_PUBLISH.VALUE,
        text: ADVERTISEMENT_ACTION_TYPE.CANCEL_PUBLISH.TEXT,
    },
    CREATE_DUPLICATE: {
        key: ADVERTISEMENT_ACTION_TYPE.CREATE_DUPLICATE.VALUE,
        value: ADVERTISEMENT_ACTION_TYPE.CREATE_DUPLICATE.VALUE,
        text: ADVERTISEMENT_ACTION_TYPE.CREATE_DUPLICATE.TEXT,
    },
    DELETE: {
        key: ADVERTISEMENT_ACTION_TYPE.DELETE.VALUE,
        value: ADVERTISEMENT_ACTION_TYPE.DELETE.VALUE,
        text: ADVERTISEMENT_ACTION_TYPE.DELETE.TEXT,
    },
};