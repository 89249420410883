import React, {useState} from "react";

import {ReactComponent as ChevronIcon} from "../../images/chevron-down.svg";
import ContextMenuContent from "../ActualComponents/ContextMenu/Content";
import NmButton from "../NmButton";

import {useClickOutside} from "../../hooks/useClickOutside";

import bem from "../../utils/bem";

import "./style.sass";

const ButtonWithContextMenu = (props) => {
    const {
        className = "",
        buttonProps = {},
        options = [],
        onClickItem = () => {},
        tooltip,
    } = props;

    const [open, setOpen] = useState(false);

    const [block, element] = bem("button-with-context-menu", className);

    const ref = useClickOutside(() => {
        setOpen(false);
    }, open);

    return (
        <div
            ref={ref}
            className={block()}
        >
            <div className={element("button")}>
                <div className={element("button-elements")}>
                    <NmButton
                        {...buttonProps}
                        icon={<ChevronIcon />}
                        isIconPositionEnd
                        onClick={() => {
                            setOpen(true);
                        }}
                    />
                    {
                        open &&
                        <ContextMenuContent
                            inline
                            className={element("context-menu-content")}
                            onClickItem={onClickItem}
                            onCloseContextMenu={() => setOpen(false)}
                            options={options}
                            position="bottom"
                        />
                    }
                </div>
            </div>
            {
                tooltip &&
                <div className={element("tooltip")}>
                    {tooltip}
                </div>
            }
        </div>
    );
};

export default ButtonWithContextMenu;