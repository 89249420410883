import {getDocumentStatesFilter} from "./getDocumentStatesFilter";

import {convertUtcToLocal, formatDateWithoutTime} from "../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../utils/objectHelper";

export const getFiltersData = (filterData) => {
    return filterEmptyValues({
        ...filterData,
        createdToFilter: filterData.createdToFilter ?
            formatDateWithoutTime(convertUtcToLocal(filterData.createdToFilter), "yyyy-MM-dd") :
            undefined,
        createdFromFilter: filterData.createdFromFilter ?
            formatDateWithoutTime(convertUtcToLocal(filterData.createdFromFilter), "yyyy-MM-dd") :
            undefined,
        documentStatesFilter: getDocumentStatesFilter(filterData.documentStatesFilter),
    });
};