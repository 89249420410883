import {
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST,
    UPDATE_CONTRACTOR_TASK_HISTORY_STORE,
} from "./actions";

export const getPageContractorTaskHistory = (payload) => {
    return {
        type: BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateStoreContractorTaskHistory = (payload) => {
    return {
        type: UPDATE_CONTRACTOR_TASK_HISTORY_STORE,
        payload,
    };
};