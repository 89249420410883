import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import {findContractorVerificationError} from "../../../containers/contractor/contractor-verification-data/utils/findError";

const FIRST_STEP = 1;

export const useContractorVerificationFormNavigation = (params) => {
    const {
        stepsConfig,
        pendingFileTypes,
        debugStepName,
        errors,
    } = params;
    const [step, setStep] = useState(FIRST_STEP);
    const [steps, setSteps] = useState(pendingFileTypes);

    useEffect(() => {
        if (!isEmpty(pendingFileTypes)) {
            setSteps(pendingFileTypes);
        }
    }, [pendingFileTypes]);

    const currentScanType = debugStepName ? debugStepName : steps[step - 1];
    const lastStep = steps.length;
    const fields = stepsConfig[currentScanType]?.fields?.filter(item => item);

    const disabledNavigation = fields ?
        findContractorVerificationError(fields, errors) :
        false;

    return {
        currentScanType,
        disabledNavigation,
        step,
        setStep,
        steps,
        lastStep,
    };
};