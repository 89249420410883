import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import formatDate, {getEndDate, getStartDate} from "../../../../utils/dateFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

import {CROWD_TASK_STATUS_OPTIONS} from "../../../../constants/crowd/task";

const useCrowdTaskRegistryFilter= (props) => {
    const {
        pageSize,
        setPagination,
    } = props;

    const initFilter = {};

    function mapFilterDto(filter) {
        const {
            clientId,
            workDatesFromFilter,
            workDatesToFilter,
            creationDateTimeFromFilter,
            creationDateTimeToFilter,
            statusFilter,
            networkNameFilter,
            numberFilter,
            nameFilter,
            paymentNumberFilter,
        } = filter;

        return {
            clientId: handleFormString(clientId),
            workDatesFromFilter: workDatesFromFilter ? formatDate(workDatesFromFilter, "yyyy-MM-dd") : undefined,
            workDatesToFilter: workDatesToFilter ? formatDate(workDatesToFilter, "yyyy-MM-dd") : undefined,
            creationDateTimeFromFilter: getStartDate(creationDateTimeFromFilter),
            creationDateTimeToFilter: getEndDate(creationDateTimeToFilter),
            statusFilter: statusFilter ? statusFilter : undefined,
            networkNameFilter: handleFormString(networkNameFilter),
            numberFilter: handleNumber(numberFilter),
            paymentNumberFilter: handleNumber(paymentNumberFilter),
            nameFilter: handleFormString(nameFilter),
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        label: "Заказчик",
                        placeholder: "Выберите заказчика",
                        name: "clientIdFilter",
                        isClearable: true,
                        isBff: true,
                        search: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "workDatesFromFilter",
                        endFieldName: "workDatesToFilter",
                        isClearable: true,
                        label: "Период работ",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "numberFilter",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "paymentNumberFilter",
                        label: "Номер оплаты по заданию",
                        placeholder: "Введите номер оплаты по заданию",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFromFilter",
                        endFieldName: "creationDateTimeToFilter",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "networkNameFilter",
                        label: "Сеть",
                        placeholder: "Введите сеть",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус",
                        placeholder: "Выберите статус",
                        name: "statusFilter",
                        options: CROWD_TASK_STATUS_OPTIONS,
                    },
                ],
            },
        ];
    }, []);

    return {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        filters,
    };
};

export default useCrowdTaskRegistryFilter;