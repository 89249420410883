import {
    BFF_PATENT_PAYMENT_DECLINE_ERROR,
    BFF_PATENT_PAYMENT_DECLINE_REQUEST,
    BFF_PATENT_PAYMENT_DECLINE_SUCCESS,
    BFF_PATENT_PAYMENT_REFRESH_ERROR,
    BFF_PATENT_PAYMENT_REFRESH_REQUEST,
    BFF_PATENT_PAYMENT_REFRESH_SUCCESS,
    BFF_PATENT_PAYMENTS_GET_PAGE_ERROR,
    BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST,
    BFF_PATENT_PAYMENTS_GET_PAGE_SUCCESS,
    UPDATE_PATENT_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_PATENT_PAYMENTS_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.payments,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_PATENT_PAYMENTS_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_PATENT_PAYMENT_REFRESH_REQUEST:
    case BFF_PATENT_PAYMENT_DECLINE_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_PATENT_PAYMENT_REFRESH_SUCCESS:
    case BFF_PATENT_PAYMENT_DECLINE_SUCCESS:
    case BFF_PATENT_PAYMENT_REFRESH_ERROR:
    case BFF_PATENT_PAYMENT_DECLINE_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case UPDATE_PATENT_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};