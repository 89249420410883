import * as yup from "yup";

import {checkResidencePermitDecisionDate} from "./checkResidencePermitDecisionDate";
import {getFullRegistrationBirthDateValidation} from "./validations/birthDate";
import {getFullRegistrationIssuedDateValidation} from "./validations/issuedDate";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {idDocNumberReg} from "../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {FULL_REGISTRATION_REG_EXP} from "../constants/regExp";

export const getFullRegistrationValidations = (params) => {
    const {
        fields,
        isRequiredFields,
    } = params;

    const lastName = isRequiredFields ?
        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).matches(
            FULL_REGISTRATION_REG_EXP.NAME,
            "Только кириллица, пробелы и знаки -",
        ).max(50, "Допустимое количество символов 50") :
        yup.string().matches(
            FULL_REGISTRATION_REG_EXP.NAME,
            "Только кириллица, пробелы и знаки -",
        ).max(50, "Допустимое количество символов 50");

    const validations = {
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
        lastName,
        firstName: lastName,
        patronymic: yup.string().matches(
            FULL_REGISTRATION_REG_EXP.NAME,
            "Только кириллица, пробелы и знаки -",
        ).max(50, "Допустимое количество символов 50"),
        birthDate: isRequiredFields ?
            getFullRegistrationBirthDateValidation().required(VALIDATIONS_MESSAGE.REQUIRED) :
            getFullRegistrationBirthDateValidation(),
        gender: isRequiredFields && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        statelessPersonResidencePermitNumber: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
            yup.string().max(50, "Допустимое количество символов 50"),
        statelessPersonResidencePermitIssuedBy: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(255, "Допустимое количество символов 255") :
            yup.string().max(255, "Допустимое количество символов 255"),
        statelessPersonResidencePermitIssuedDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        statelessPersonResidencePermitValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        statelessPersonTemporaryIdNum: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
            yup.string().max(50, "Допустимое количество символов 50"),
        statelessPersonTemporaryIdIssuedBy: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(255, "Допустимое количество символов 255") :
            yup.string().max(255, "Допустимое количество символов 255"),
        statelessPersonTemporaryIdIssuedDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        statelessPersonTemporaryIdValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        statelessPersonTemporaryIdBirthPlace:  isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).min(2, "Минимальная длина строки 2 символа").max(255, "Допустимое количество символов 255") :
            yup.string().min(2, "Минимальная длина строки 2 символа").max(255, "Допустимое количество символов 255"),
        statelessPersonTemporaryIdIssueReason: yup.string().max(50, "Не более 50 символов"),
        temporaryRefugeeCertificateNum: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
            yup.string().max(50, "Допустимое количество символов 50"),
        temporaryRefugeeCertificateIssuedBy: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(255, "Допустимое количество символов 255") :
            yup.string().max(255, "Допустимое количество символов 255"),
        temporaryRefugeeCertificatePersonalFileNum: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
            yup.string().max(50, "Допустимое количество символов 50"),
        temporaryRefugeeCertificateIssuedDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        temporaryRefugeeCertificateValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        temporaryRefugeeCertificateBirthPlace: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).min(2, "Минимальная длина строки 2 символа").max(255, "Допустимое количество символов 255") :
            yup.string().min(2, "Минимальная длина строки 2 символа").max(255, "Допустимое количество символов 255"),
        refugeeCertificateNum: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
            yup.string().max(50, "Допустимое количество символов 50"),
        refugeeCertificateIssuedBy: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(255, "Допустимое количество символов 255") :
            yup.string().max(255, "Допустимое количество символов 255"),
        refugeeCertificatePersonalFileNum: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
            yup.string().max(50, "Допустимое количество символов 50"),
        refugeeCertificateIssuedDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        refugeeCertificateValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        refugeeCertificateBirthPlace: isRequiredFields ?
            yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).min(2, "Минимальная длина строки 2 символа").max(255, "Не более 255 символов") :
            yup.string().min(2, "Минимальная длина строки 2 символа").max(255, "Не более 255 символов"),
        temporaryResidencePermitNum: isRequiredFields ?
            yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .max(50, "Допустимое количество символов 50") :
            yup.string()
                .max(50, "Допустимое количество символов 50"),
        temporaryResidencePermitDecisionDate: isRequiredFields &&
            yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
        temporaryResidencePermitValidToDate: isRequiredFields ?
            yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .test(
                    "checkResidencePermitDecisionDate",
                    "Период между датой принятия решения и сроком действия не должен превышать 3 года + 1 день",
                    function (value) {
                        return checkResidencePermitDecisionDate({
                            checkedDate: value,
                            date: this.parent.temporaryResidencePermitDecisionDate,
                        });
                    },
                ) :
            yup.string()
                .nullable()
                .test(
                    "checkResidencePermitDecisionDate",
                    "Период между датой принятия решения и сроком действия не должен превышать 3 года + 1 день",
                    function (value) {
                        return checkResidencePermitDecisionDate({
                            checkedDate: value,
                            date: this.parent.temporaryResidencePermitDecisionDate,
                        });
                    },
                )
        ,
        temporaryResidencePermitDecisionIssuedBy: isRequiredFields ?
            yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .max(255, "Допустимое количество символов 255") :
            yup.string()
                .nullable()
                .max(255, "Допустимое количество символов 255"),
        idDocNumber: isRequiredFields ? yup.string()
            .nullable()
            .when("citizenship", {
                is: citizenshipsDict.RU.value,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .length(11, "Допустимое количество символов 10"),
            })
            .when("citizenship", {
                is: value => ![citizenshipsDict.RU.value].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(5, "Минимальная длина номера 5 символов")
                    .max(20, "Не более 20 символов")
                    .matches(idDocNumberReg, "Ввод только латинских букв и цифр"),
            }) :
            yup.string()
                .nullable()
                .when("citizenship", {
                    is: citizenshipsDict.RU.value,
                    then: yup.string()
                        .nullable()
                        .length(11, "Допустимое количество символов 10"),
                })
                .when("citizenship", {
                    is: value => ![citizenshipsDict.RU.value].includes(value),
                    then: yup.string()
                        .nullable()
                        .min(5, "Минимальная длина номера 5 символов")
                        .max(20, "Не более 20 символов")
                        .matches(idDocNumberReg, "Ввод только латинских букв и цифр"),
                })
        ,
        birthPlace: isRequiredFields ? yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED).min(2, "Минимальная длина строки 2 символа").max(255, "Не более 255 символов") :
            yup.string().nullable().min(2, "Минимальная длина строки 2 символа").max(255, "Не более 255 символов"),
        idDocIssuedBy: isRequiredFields ?
            yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED).max(255, "Допустимое количество символов 255") :
            yup.string().nullable().max(255, "Допустимое количество символов 255"),
        idDocIssuedDate: isRequiredFields ?
            getFullRegistrationIssuedDateValidation().required(VALIDATIONS_MESSAGE.REQUIRED) :
            getFullRegistrationIssuedDateValidation(),
        idDocValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        idDocIssuedByDepartmentCode: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
    };

    return fields.reduce((result, fieldName) => {
        return {
            ...result,
            [fieldName]: validations[fieldName],
        };
    }, {});
};