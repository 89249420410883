import {
    addKedoTemplate,
    deleteKedoTemplate,
    getKedoTemplatesLogsPage,
    getKedoTemplatesPage,
    getKedoTemplateUsersFieldNames,
    updateKedoTemplate,
    validateKedoTemplateFields,
    validateKedoTemplatePdf,
} from "../../../../../kedo/templates/actionCreators";

const controller = "/adm/document-templates/kedo/templates";

export const getAdmKedoTemplatesPage = (payload) => {
    return getKedoTemplatesPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const addAdmKedoTemplate = (payload) => {
    return addKedoTemplate({
        url: `${controller}/add`,
        ...payload,
    });
};

export const updateAdmKedoTemplate = (payload) => {
    return updateKedoTemplate({
        url: `${controller}/update`,
        ...payload,
    });
};

export const getAdmKedoTemplateUsersFieldNames = (payload) => {
    return getKedoTemplateUsersFieldNames({
        url: `${controller}/searchTemplateUsersFieldNames`,
        ...payload,
    });
};

export const validateAdmKedoTemplatePdf = (payload) => {
    return validateKedoTemplatePdf({
        url: `${controller}/validatePdf`,
        ...payload,
    });
};

export const validateAdmKedoTemplateFields = (payload) => {
    return validateKedoTemplateFields({
        url: `${controller}/validateFields`,
        ...payload,
    });
};

export const getAdmKedoTemplatesLogsPage = (payload) => {
    return getKedoTemplatesLogsPage({
        url: `${controller}/logs/getPage`,
        ...payload,
    });
};

export const deleteAdmKedoTemplate = (payload) => {
    return deleteKedoTemplate({
        url: `${controller}/delete`,
        ...payload,
    });
};