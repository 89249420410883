export const REGISTRY_ACTION_TYPE = {
    DELETE: "delete",
    EDIT: "edit",
};

export const REGISTRY_PAYMENT_ERRORS = {
    APPLICATION_DATE_IS_BEFORE_FRAME_CONTRACT_DATE_OR_IS_AFTER_WORK_START_DATE: "APPLICATION_DATE_IS_BEFORE_FRAME_CONTRACT_DATE_OR_IS_AFTER_WORK_START_DATE",
};

export const STATUS_COLOR_LIST = {
    DRAFT: {
        border: "1px solid #E4E4E4",
        color: "#666666",
    },
    FOR_PAYMENT: {
        background: "#F1F3F7",
        color: "#666666",
    },
    FULLY_PAID: {
        background: "#128A10",
        color: "#FFFFFF",
    },
    NOT_PAID: {
        color: "#FFFFFF",
        background: "#EE1616",
    },
    PARTIALLY_PAID: {
        background: "#E9F4E5",
        color: "#128A10",
    },
};

export const REGISTRY_STATUS_DICT = {
    DRAFT: {
        VALUE: "DRAFT",
        TEXT: "Формируется",
        COLOR: "#666666",
        BACKGROUND: "#FFFFFF",
        BORDER: "1px solid #E4E4E4",
    },
    FOR_PAYMENT: {
        VALUE: "FOR_PAYMENT",
        TEXT: "Передан в оплату",
        COLOR: "#666666",
        BACKGROUND: "#F1F3F7",
    },
    NOT_PAID: {
        VALUE: "NOT_PAID",
        TEXT: "Не оплачен",
        COLOR: "#FFFFFF",
        BACKGROUND: "#EE1616",
    },
    FULLY_PAID: {
        VALUE: "FULLY_PAID",
        TEXT: "Оплачен полностью",
        COLOR: "#FFFFFF",
        BACKGROUND: "#128A10",
    },
    PARTIALLY_PAID: {
        VALUE: "PARTIALLY_PAID",
        TEXT: "Оплачен частично",
        COLOR: "#128A10",
        BACKGROUND: "#E9F4E5",
    },
};

export const REGISTRY_STATUS = {
    PARTIALLY_PAID: "PARTIALLY_PAID",
    FOR_PAYMENT: "FOR_PAYMENT",
    NOT_PAID: "NOT_PAID",
    FULLY_PAID: "FULLY_PAID",
    DRAFT: "DRAFT",
};

export const REGISTRY_PAYMENTS_STATUS_DICT = {
    FOR_PAYMENT: {
        VALUE: "FOR_PAYMENT",
        TEXT: "К оплате",
        COLOR: "#555555",
    },
    AWAITING_SIGNATURE: {
        VALUE: "AWAITING_SIGNATURE",
        TEXT: "Ожидает подписания договора от исполнителя",
        COLOR: "#555555",
    },
    AWAITING_CONTRACTOR_CONFIRMATION: {
        VALUE: "AWAITING_CONTRACTOR_CONFIRMATION",
        TEXT: "Ожидание подтверждения от исполнителя",
        COLOR: "#555555",
    },
    CONTRACTOR_DECLINED_CONTRACT_SIGNING: {
        VALUE: "CONTRACTOR_DECLINED_CONTRACT_SIGNING",
        TEXT: "Отклонено",
        MESSAGE: "Исполнитель отклонил подписание договора",
        COLOR: "#555555",
    },
    PAID: {
        VALUE: "PAID",
        TEXT: "Оплачено",
        COLOR: "#128A10",
    },
    IN_PROGRESS: {
        VALUE: "IN_PROGRESS",
        TEXT: "В обработке",
        MESSAGE: "В обработке",
        COLOR: "#555555",
    },
    DECLINED : {
        VALUE: "DECLINED",
        TEXT: "Отклонено",
        COLOR: "#555555",
    },
    ERROR: {
        VALUE: "ERROR",
        TEXT: "Ошибка при передаче в оплату",
        COLOR: "#EE1616",
    },
    BANK_ERROR: {
        VALUE: "BANK_ERROR",
        TEXT: "Ошибка платежа",
        COLOR: "#EE1616",
    },
    BANK_DECLINED: {
        VALUE: "BANK_DECLINED",
        TEXT: "Ошибка платежа",
        COLOR: "#EE1616",
    },
    CONTRACTOR_DECLINED: {
        VALUE: "CONTRACTOR_DECLINED",
        TEXT: "Отклонено",
        COLOR: "#EE1616",
    },
    NM_ADMIN_DECLINED: {
        VALUE: "NM_ADMIN_DECLINED",
        TEXT: "Отклонено",
        COLOR: "#EE1616",
    },
    CANCELED_BY_ADMIN: {
        VALUE: "CANCELED_BY_ADMIN",
        TEXT: "Отклонено",
        COLOR: "#EE1616",
    },
    PAYMENT_ERROR : {
        VALUE: "PAYMENT_ERROR",
        TEXT: "Ошибка платежа",
        COLOR: "#EE1616",
        CLIENT_COLOR: "#555555",
    },
};

export const REGISTRY_OPERATION = {
    // Передать в оплату (контекстное меню в странице-карточке, кнопка передеать в оплату)
    GO_REGISTRY_PAY: "GO_REGISTRY_PAY",
    PAY_REGISTRY_WITH_ACT_DATE_CONFIRM: "PAY_REGISTRY_WITH_ACT_DATE_CONFIRM",
    PAY_REGISTRY_PAYMENT_WITH_ACT_DATE_CONFIRM: "PAY_REGISTRY_PAYMENT_WITH_ACT_DATE_CONFIRM",
    // Повторить невыполненные платежи
    PAY_NOT_COMPLETED_PAYMENTS: "PAY_NOT_COMPLETED_PAYMENTS",
    // Отменить невыполненные оплаты
    CANCEL_NOT_COMPLETED_PAYMENTS: "CANCEL_NOT_COMPLETED_PAYMENTS",
    // Повторить платеж (список)
    PAY: "PAY",
    // Чек ФНС (список)
    CHECK_FNS: "CHECK_FNS",
    // Редактировать (список)
    EDIT: "EDIT",
    // Отменить (список)
    CANCEL: "CANCEL",
    // Акт выполненных работ (список)
    DOWNLOAD_ACT: "DOWNLOAD_ACT",
    // Выгрузить реестр (контекстное меню в странице-карточке)
    DOWNLOAD_REGISTRY: "DOWNLOAD_REGISTRY",
    // Калькулятор (контекстное меню в странице-карточке)
    CALCULATOR: "CALCULATOR",
    // Добавить исполнителя  (контекстное меню в странице-списке)
    ADD_PERFORMER: "ADD_PERFORMER",
    // Загрузить список исполнителей  (контекстное меню в странице-списке)
    LOAD_PERFORMERS_FROM_FILE: "LOAD_PERFORMERS_FROM_FILE",
    // Пригласить исполнителя в Наймикс  (контекстное меню в странице-списке)
    INVITE_TO_NAIMIX: "INVITE_TO_NAIMIX",
    // Обновить исполнителей  (контекстное меню в странице-списке)
    REFRESH_PERFORMERS: "REFRESH_PERFORMERS",
    // Обновить платежные данные  (контекстное меню в странице-списке)
    REFRESH_PAYMENT_POSSIBILITY: "REFRESH_PAYMENT_POSSIBILITY",
    // Скрыть описание работ  (контекстное меню в странице-списке)
    HIDE_DESCRIPTION: "HIDE_DESCRIPTION",
    // Удалить строку  (массовое действие над списком)
    DELETE_ROW: "DELETE_ROW",
    // Выгрузить документы
    ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT: "ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT",
};

export const REGISTRY_CARD_OPTIONS = {
    GO_REGISTRY_PAY: {
        key: REGISTRY_OPERATION.GO_REGISTRY_PAY,
        text: "Передать в оплату",
        value: REGISTRY_OPERATION.GO_REGISTRY_PAY,
    },
    DOWNLOAD_REGISTRY: {
        key: REGISTRY_OPERATION.DOWNLOAD_REGISTRY,
        text: "Выгрузить реестр",
        value: REGISTRY_OPERATION.DOWNLOAD_REGISTRY,
    },
    ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT: {
        key: REGISTRY_OPERATION.ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT,
        text: "Выгрузить документы",
        value: REGISTRY_OPERATION.ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT,
    },
    CALCULATOR: {
        key: REGISTRY_OPERATION.CALCULATOR,
        text: "Калькулятор",
        value: REGISTRY_OPERATION.CALCULATOR,
    },
    ADD_PERFORMER: {
        key: REGISTRY_OPERATION.ADD_PERFORMER,
        text: "Добавить исполнителя",
        value: REGISTRY_OPERATION.ADD_PERFORMER,
    },
    LOAD_PERFORMERS_FROM_FILE: {
        key: REGISTRY_OPERATION.LOAD_PERFORMERS_FROM_FILE,
        text: "Загрузить список исполнителей",
        value: REGISTRY_OPERATION.LOAD_PERFORMERS_FROM_FILE,
    },
    INVITE_TO_NAIMIX: {
        key: REGISTRY_OPERATION.INVITE_TO_NAIMIX,
        text: "Пригласить исполнителя в Наймикс",
        value: REGISTRY_OPERATION.INVITE_TO_NAIMIX,
    },
    REFRESH_PERFORMERS: {
        key: REGISTRY_OPERATION.REFRESH_PERFORMERS,
        text: "Обновить исполнителей",
        value: REGISTRY_OPERATION.REFRESH_PERFORMERS,
    },
    REFRESH_PAYMENT_POSSIBILITY: {
        key: REGISTRY_OPERATION.REFRESH_PAYMENT_POSSIBILITY,
        text: "Обновить платежные данные",
        value: REGISTRY_OPERATION.REFRESH_PAYMENT_POSSIBILITY,
    },
};

export const REGISTRY_CONFIRM_TEXT = {
    PAY: "Вы действительно хотите повторить невыполненный платеж реестра?",
    CANCEL: "Вы действительно хотите отменить оплату по данной строке реестра?",
    GO_REGISTRY_PAY: "Вы действительно хотите передать в оплату реестр \":name\"?",
    CANCEL_NOT_COMPLETED_PAYMENTS: "Вы действительно хотите отменить невыполненные оплаты в реестре \":name\"?",
    PAY_NOT_COMPLETED_PAYMENTS: "Вы действительно хотите повторить невыполненные платежи реестра?",
    DELETE_PAYMENTS: "Вы действительно хотите удалить выбранные строки из реестра?",
    CANCEL_SELECTED: "Вы действительно хотите отменить выплаты по выбранным строкам реестра?",
};

export const REGISTRY_TOAST_TEXT = {
    SUCCESS_INVITE_VIA_EMAIL: "Приглашение успешно отправлено",
    PAY_SUCCESS: "Успешно запущена задача оплаты.",
    CANCEL_SUCCESS: "Выплата успешно отменена",
};

export const REGISTRY_PAYMENTS_CATEGORY_FIELDS = {
    CATEGORY_ID: "categoryId",
    SUBCATEGORY_ID: "subcategoryId",
};
export const CONTRACTOR_SEARCH_TYPE = {
    FULL_NAME: {
        VALUE: "FULL-NAME",
        TEXT: "ФИО",
    },
    PHONE_NUMBER: {
        VALUE: "PHONE_NUMBER",
        TEXT: "Номер телефона",
    },
    INN: {
        VALUE: "INN",
        TEXT: "ИНН",
    },
};

export const CONTRACTOR_SEARCH_TYPE_OPTIONS = [
    {
        key: CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE,
        text: CONTRACTOR_SEARCH_TYPE.FULL_NAME.TEXT,
        value: CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE,
    },
    {
        key: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
        text: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.TEXT,
        value: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
    },
];

export const ACT_REGISTRY_CONTRACTOR_SEARCH_TYPE_OPTIONS = [
    ...CONTRACTOR_SEARCH_TYPE_OPTIONS,
    {
        key: CONTRACTOR_SEARCH_TYPE.INN.VALUE,
        text: CONTRACTOR_SEARCH_TYPE.INN.TEXT,
        value: CONTRACTOR_SEARCH_TYPE.INN.VALUE,
    },
];

export const REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS = [
    {
        key: "SIGNED",
        text: "Подписан",
        value: "SIGNED",
    },
    {
        key: "TERMINATED",
        text: "Расторгнут",
        value: "TERMINATED",
    },
    {
        key: "NONE",
        text: "Отсутствует",
        value: "NONE",
    },
];

export const REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS = [
    {
        key: "ALL",
        text: "Все",
        value: "ALL",
    },
    {
        key: "YES",
        text: "Да",
        value: "YES",
    },
    {
        key: "NO",
        text: "Нет",
        value: "NO",
    },
];

export const REGISTRY_IMPORT_FILE_TYPE = {
    IMPORT_CONTRACTOR_LIST: "IMPORT_CONTRACTOR_LIST",
};

export const REGISTRY_SEARCH_STEP = {
    STEP_1: {
        VALUE: "REGISTRATION_SEARCH_CONTRACTOR",
    },
    STEP_2: {
        VALUE: "REGISTRATION_FOUND_CONTRACTORS",
    },
    STEP_3: {
        VALUE: "ADDING_ORDER_PARAMETERS_CONTRACTOR",
    },
};

export const CONTRACTOR_TAX = 0.06;
export const CONTRACTOR_COMMISSION = 0.04;

export const REGISTRY_PAYMENT_ERROR_CODE = {
    //Не пройдена проверка на наличие ИНН компании в поле Комментарий
    COMMENT_VALID_ERROR: "COMMENT_VALID_ERROR",
};