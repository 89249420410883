import React from "react";

import {ReactComponent as InfoIcon} from "../../../../images/info_24.svg";

import "./style.sass";

const FullRegistrationValidationError = () => {
    return (
        <div className="full-registration-validation-error">
            <InfoIcon
                className="full-registration-validation-error__icon"
            />
            Найдены незаполненные или некорректно заполненные поля. Пожалуйста, проверьте полноту и корректность указанных данных, а затем повторите попытку
        </div>
    );
};

export default FullRegistrationValidationError;