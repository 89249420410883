import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as yup from "yup";

import {
    uploadRecruitmentVacancyCandidatesResume,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentVacancyCandidatesProgressActionSelector,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import DropzoneV2 from "../../../../ActualComponents/DropzoneV2";
import NmForm from "../../../../ActualComponents/NmForm";
import NmInputV2 from "../../../../ActualComponents/NmInputV2";
import NmModal from "../../../../ActualComponents/NmModal";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

import {getRecruitmentCandidateResumesRequestData} from "./utils/getRequestData";

import {requiredMessage} from "../../../../../constants/validationRules";

export const RecruitmentCandidateResumesEdit = (props) => {
    const {
        onClose,
        candidateId,
    } = props;

    const dispatch = useDispatch();

    const {
        fetchInformation,
    } = useContext(RecruitmentCandidateInformationContext);

    const progress = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);

    const {
        values,
        handleSubmit,
        errors,
        setFieldValue,
        setFieldTouched,
        touched,
    } = useFormik({
        initialValues: {
            comment: "",
            files: [],
        },
        validationSchema: Yup.object().shape({
            files: Yup.array().min(1, requiredMessage),
            comment: yup.string().required(requiredMessage),
        }),
        onSubmit,
    });

    function onSubmit() {
        const requestData = getRecruitmentCandidateResumesRequestData(values, candidateId);

        dispatch(uploadRecruitmentVacancyCandidatesResume({
            ...requestData,
            onSuccess: () => {
                fetchInformation();
                onClose();
            },
        }));
    }

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    const onChangeFile = (name, files) => {
        setFieldValue(name, files);
    };

    return (
        <NmModal
            size="md"
            className="comments-retail-modal"
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    disabled={progress}
                    submit={handleSubmit}
                />
            }
            header={
                <NmTitle size="lg">
                    Резюме
                </NmTitle>
            }
        >
            <NmForm addMargin={true}>
                <DropzoneV2
                    required
                    format="*.PDF"
                    accept=".pdf"
                    maxSize={25}
                    files={values.files}
                    onChange={(files) => {
                        onChangeFile("files", files);
                    }}
                    error={touched?.files && errors?.files}
                />
                <NmInputV2
                    required={true}
                    size="xl"
                    onChange={onChange}
                    name="comment"
                    maxLength={100}
                    label="Комментарий"
                    placeholder="Введите комментарий, например откуда загружено резюме"
                    value={values.comment}
                    onBlur={() => {setFieldTouched("comment", true);}}
                    error={touched && touched.comment ?
                        errors && errors.comment :
                        undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
};
