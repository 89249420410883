import React, {useContext} from "react";
import {useFormik} from "formik";

import NmDropdownV2 from "../../../../ActualComponents/NmDropdownV2";
import NmForm from "../../../../ActualComponents/NmForm";
import NmInputV2 from "../../../../ActualComponents/NmInputV2";
import NmModal from "../../../../ActualComponents/NmModal";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

import {getMonthOptions} from "../../../../../utils/dateFormat";
import {getRecruitmentCandidateExperienceValues} from "./utils/getInitialValues";
import {getRecruitmentCandidateExperienceRequestData} from "./utils/getRequestData";
import {getRecruitmentExperienceValidation} from "./utils/getValidation";

export const RecruitmentCandidateExperienceEdit = (props) => {
    const {
        onClose,
        editData,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        progressAction,
        card,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        handleSubmit,
    } = useFormik({
        initialValues: getRecruitmentCandidateExperienceValues(editData),
        validationSchema: getRecruitmentExperienceValidation(card),
        onSubmit,
    });

    function onSubmit() {
        const experiences = getRecruitmentCandidateExperienceRequestData({
            experiences: card.experiences || [],
            values,
            editData,
        });

        updateInformation({
            experiences,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    }

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    Опыт работы
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
            classNameContent="flex-column"
        >
            <NmForm addMargin={true}>
                <div className="row">
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            required={true}
                            size="xl"
                            label="Месяц начала работы"
                            placeholder="Выберите месяц"
                            name="monthStart"
                            options={getMonthOptions()}
                            value={values.monthStart}
                            onChange={onChange}
                            onBlur={() => {setFieldTouched("monthStart", true);}}
                            error={touched?.monthStart && errors?.monthStart ? errors?.monthStart : undefined}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required={true}
                            size="xl"
                            mask="9999"
                            label="Год начала работы"
                            placeholder="Введите год"
                            maskChar={null}
                            onChange={onChange}
                            name="yearStart"
                            value={values.yearStart}
                            onBlur={() => {setFieldTouched("yearStart", true);}}
                            error={touched?.yearStart && errors?.yearStart ? errors?.yearStart : undefined}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            required={true}
                            size="xl"
                            label="Месяц окончания работы"
                            placeholder="Выберите месяц"
                            name="monthEnd"
                            options={getMonthOptions()}
                            value={values.monthEnd}
                            onChange={onChange}
                            onBlur={() => {setFieldTouched("monthEnd", true);}}
                            error={touched?.monthEnd && errors?.monthEnd ? errors?.monthEnd : undefined}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <NmInputV2
                            required={true}
                            size="xl"
                            mask="9999"
                            label="Год окончания работы"
                            placeholder="Введите год"
                            maskChar={null}
                            onChange={onChange}
                            name="yearEnd"
                            value={values.yearEnd}
                            onBlur={() => {setFieldTouched("yearEnd", true);}}
                            error={touched?.yearEnd && errors?.yearEnd ? errors?.yearEnd : undefined}
                        />
                    </div>
                </div>
                <NmInputV2
                    required={true}
                    label="Наименование организации"
                    placeholder="Введите наименование"
                    size="xl"
                    onChange={onChange}
                    name="organisation"
                    value={values.organisation}
                    onBlur={() => {setFieldTouched("organisation", true);}}
                    error={touched?.organisation && errors?.organisation ? errors?.organisation : undefined}
                />
                <NmInputV2
                    required={true}
                    label="Должность"
                    placeholder="Введите описание должности"
                    size="xl"
                    onChange={onChange}
                    name="position"
                    value={values.position}
                    onBlur={() => {setFieldTouched("position", true);}}
                    error={touched?.position && errors?.position ? errors?.position : undefined}
                />
            </NmForm>
        </NmModal>
    );
};
