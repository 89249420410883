import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {handleFormString} from "../../../../../utils/stringHelper";

import {
    addCollectionBankDetails,
    updateCollectionBankDetails,
} from "../../../../../ducks/collectionBankDetails";

const useDataForm = ({card, isEdit, fetchList, onClose}) => {
    const dispatch = useDispatch();

    const initialValues = {
        projectParam: "",
        leadType: "",
        ...card,
    };

    const handleChange = (e, {name, value}) => {
        if (name === "projectParam" && value.length > 255) {
            value = value.slice(0, 255);
        }

        setFieldValue(name, value);
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            id,
            leadType,
            projectParam,
        } = values;

        const onSuccess = () => {
            fetchList();
            onClose();
        };

        if (isEdit) {
            dispatch(updateCollectionBankDetails({
                id,
                leadType: handleFormString(leadType),
                projectParam: handleFormString(projectParam),
                onSuccess,
            }));

            return;
        }

        dispatch(addCollectionBankDetails({
            leadType: handleFormString(leadType),
            projectParam: handleFormString(projectParam),
            onSuccess,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        errors,
        touched,
        handleChange,
        handleSubmit,
    };
};

export default useDataForm;