import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import {formatLocalDate, getUtcDateFilter} from "../../../../utils/dateFormat";
import {getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {
    getOnboardingScenarioLog,
    onboardingLogListProgressSelector,
    onboardingLogListSelector,
    onboardingLogListTotalCountSelector,
    onboardingLogListTotalPagesSelector,
    updateOnboardingStore,
} from "../../../../ducks/onboarding";

const OnboardingScenarioLogModal = (props) => {
    const {
        onClose = () => {
        },
        clientId,
        scenarioId,
        name,
    } = props;

    const list = useSelector(onboardingLogListSelector);
    const totalPages = useSelector(onboardingLogListTotalPagesSelector);
    const totalCount = useSelector(onboardingLogListTotalCountSelector);
    const progress = useSelector(onboardingLogListProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({setPagination, pageSize});

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateOnboardingStore({
                logList: [],
                logTotalCount: 0,
                logPageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        const {
            dateTimeFrom,
            dateTimeTo,
        } = filterData;

        dispatch(getOnboardingScenarioLog({
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            scenarioIdFilter: scenarioId,
            dateTimeFrom: dateTimeFrom ? getUtcDateFilter(dateTimeFrom) : undefined,
            dateTimeTo: dateTimeTo ? getUtcDateFilter(dateTimeTo) : undefined,
        }));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                dateTime,
                typeDescription,
                initiatorInfo,
                details,
            } = item;

            return {
                ...item,
                key: dateTime,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-md-12 col-xxl-12 col-xl-12"
                        primaryHeader={typeDescription}
                        labels={[
                            {
                                label: "Дата и время",
                                text: formatLocalDate(dateTime, "dd.MM.yyyy HH:mm:ss"),
                            },
                            details.contractorLastName && {
                                label: "Исполнитель",
                                text: getFullName(details.contractorLastName, details.contractorFirstName, details.contractorPatronymic),
                            },
                            details.contractorPhone && {
                                label: "Телефон",
                                text: phoneFormat(details.contractorPhone),
                            },
                            details.recruiterFullName && {
                                label: "Рекрутер",
                                text: details.recruiterFullName,
                            },
                            {
                                label: "Инициатор",
                                text: getFullName(initiatorInfo.lastName, initiatorInfo.firstName, initiatorInfo.patronymic),
                            },
                            details.description && {
                                label: "Описание события",
                                text: details.description,
                                noWrap: false,
                                columnOnMobile: true,
                                flexWrap: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог событий по сценарию "
                    {name}
"
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={
                    <NmForm
                        horizontal
                        addMargin
                    >
                        <div className="col-8">
                            <NmDateRangePickerV2
                                size="lg"
                                className="col-6"
                                startFieldName="dateTimeFrom"
                                endFieldName="dateTimeTo"
                                value={{dateTimeFrom: filter.dateTimeFrom, dateTimeTo: filter.dateTimeTo}}
                                isClearable
                                label="Дата"
                                onChange={onChangeFilter}
                            />
                        </div>
                        <FilterButtonsV2
                            onClear={onClear}
                            onSearch={() => onSearch(filter)}
                        />
                    </NmForm>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        />
                }
            </NmPage>
        </NmModal>
    );
};

export default OnboardingScenarioLogModal;