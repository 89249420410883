import React, {useState} from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../components/ActualComponents/NmRangeInput";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {
    clearSpace,
    divideNumberIntoDigits,
    removePhoneMask,
} from "../../../../utils/stringFormat";
import {handleNumber} from "../../../../utils/stringHelper";

import {amountRegexDelimited} from "../../../../constants/validationRules";

import {paymentStatusDictSelector} from "../../../../ducks/patentsPayments";

import PropTypes from "prop-types";

import "./style.sass";

const isNum = value => /^[0-9]+$/.test(value);

const PatentsCardFilter = props => {
    const {
        submitFilter,
    } = props;

    const [filter, setFilter] = useState({});
    const {
        fioFilter,
        phoneFilter,
        registryPaymentFilterStatuses,
        startSumFilter,
        endSumFilter,
    } = filter;

    const statusDict = useSelector(paymentStatusDictSelector);
    const statusOptions = dictionaryToOptions(statusDict);

    const onChange = (event, {name, value}) => {
        if (["startSumFilter", "endSumFilter"].includes(name)) {

            value = clearSpace(value);

            if (value && !amountRegexDelimited.test(value)) {
                return;
            }

            value = divideNumberIntoDigits(value);
        }

        if (name === "paymentNumberFilter" && value.length && !isNum(value)) {
            return;
        }

        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const submit = () => {
        const reqData = {
            contractorNameFilter: fioFilter,
            fromSumFilter: startSumFilter ? handleNumber(clearSpace(startSumFilter)) : startSumFilter,
            toSumFilter: endSumFilter ? handleNumber(clearSpace(endSumFilter)) : endSumFilter,
            statusFilter: registryPaymentFilterStatuses,
            contractorPhoneFilter: phoneFilter ? removePhoneMask(phoneFilter) : phoneFilter,
        };

        submitFilter(reqData);
    };

    const clear = () => {
        setFilter({});
        submitFilter();
    };

    return (
        <NmForm
            addMargin
            className="patents-card-filter"
        >
            <NmInputV2
                size="lg"
                name="fioFilter"
                onChange={onChange}
                value={fioFilter || ""}
                label="ФИО"
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                name="registryPaymentFilterStatuses"
                options={statusOptions}
                onChange={onChange}
                value={registryPaymentFilterStatuses || ""}
                label="Статус оплаты"
                placeholder="Показать все"
            />
            <NmRangeInput
                label="Сумма"
                nameEnd="endSumFilter"
                nameStart="startSumFilter"
                onChange={onChange}
                placeholderEnd="До"
                placeholderStart="От"
                size="lg"
                valueStart={startSumFilter || ""}
                valueEnd={endSumFilter || ""}
                isClearable

            />
            <FilterButtonsV2
                onSearch={submit}
                onClear={clear}
            />
        </NmForm>
    );
};

PatentsCardFilter.propTypes = {
    submitFilter: PropTypes.func,
    clearFilter: PropTypes.func,
};

export default PatentsCardFilter;