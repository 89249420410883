import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import DropdownWithInput from "../../../../../../../components/ActualComponents/DropdownWithInput";
import ErrorTooltip from "../../../../../../../components/ActualComponents/ErrorTooltip";
import HelpTooltip from "../../../../../../../components/ActualComponents/HelpTooltip";
import NmDateRangePickerV2 from "../../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmTextareaV2 from "../../../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../../../components/ActualComponents/Text";
import AmountInput from "../../../../../../../components/AmountInput";
import {CrowdTaskEditContext} from "../context-provider";
import {Divider} from "semantic-ui-react";

import bem from "../../../../../../../utils/bem";
import {getHoursOptions, getMinutesOptions} from "../../../../../../../utils/dateFormat";
import {handleCardRate} from "../../../../../../../utils/mathHelper";
import {formatAmount, formatNumber} from "../../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../../utils/stringHelper";

import {COLOR} from "../../../../../../../constants/color";

import {getCurrentNmContractDateConclusionSelector} from "../../../../../../../ducks/client";
import {
    getKeywordsAutocompletePage,
    getKeywordsOptionsSelector,
} from "../../../../../../../ducks/keyword";
import {specialitiesClientOptionsV2Selector} from "../../../../../../../ducks/speciality";

import "./style.sass";

const CrowdTaskEditGeneralInformationForm = () => {
    const {
        values,
        touched,
        errors,
        handleChange,
        calculatorData,
    } = useContext(CrowdTaskEditContext);

    const dispatch = useDispatch();

    const [block, element] = bem("crowd-task-edit-general-information-form");

    const nmContractDateConclusion = useSelector(getCurrentNmContractDateConclusionSelector);
    const specialityOptions = useSelector(specialitiesClientOptionsV2Selector);
    const keywordsOptions = useSelector(getKeywordsOptionsSelector);
    const timeHoursOptions = getHoursOptions();
    const timeMinutesOptions = getMinutesOptions();

    const fetchKeywords = (keywordStartWithFilter) => {
        dispatch(getKeywordsAutocompletePage({
            pageNum: 1,
            pageSize: 150,
            keywordStartWithFilter: handleFormString(keywordStartWithFilter),
        }));
    };

    const renderCalculatedIndicatorsRow = (props) => {
        const {
            label,
            text,
            level,
            tooltip,
            medium,
        } = props;

        return (
            <div className="flex flex-content-spaced mb-2">
                <div className="col-10 flex flex-aligned-end">
                    <Text
                        level={level}
                        color={COLOR.SECONDARY_90}
                    >
                        {label}
                    </Text>
                    {
                        tooltip &&
                        <HelpTooltip
                            hover
                            info
                            children={tooltip}
                            position="top-left"
                        />
                    }
                </div>
                <Text
                    className={element("info-amount")}
                    level={level}
                    color={COLOR.SECONDARY_90}
                    medium={medium}
                    noWrap
                >
                    {text}
                </Text>
            </div>
        );
    };


    const renderCalculator = () => {
        const {
            paymentAmount,
            contractorTaxAmount,
            amountToCard,
            platformTaxAmount,
            fromDepositAmount,
            crowdTaskPaymentsCommission,
        } = calculatorData;

        return (
            <div className={element("calculated-indicators")}>
                <Text
                    level="3"
                    color={COLOR.SECONDARY_90}
                    noWrap
                >
                    Расчетные показатели задания
                </Text>
                <div className={element("calculated-indicators-info")}>
                    {renderCalculatedIndicatorsRow(
                        {
                            label: "Сумма списания с депозита (лимита)",
                            text: formatAmount(formatNumber(fromDepositAmount || 0, 2)),
                            level: "4",
                            medium: true,
                            tooltip: "Необходимо пополнить р/с компании на данную сумму",
                        })}
                    {renderCalculatedIndicatorsRow({
                        label: "Сумма задания, ₽:",
                        text: formatAmount(formatNumber(paymentAmount || 0, 2)),
                        level: "2",
                    })}
                    {renderCalculatedIndicatorsRow({
                        label: `Комиссия площадки, ₽ (${String(handleCardRate(crowdTaskPaymentsCommission)).replace(".", ",")}%):`,
                        text: `+${formatAmount(formatNumber(platformTaxAmount || 0, 2))}`,
                        additionalText: "+0,00",
                        level: "2",
                    })}
                    <Divider className="divider-fluid mb-2 mt-2" />
                    {renderCalculatedIndicatorsRow(
                        {
                            label: "Сумма задания",
                            text: formatAmount(formatNumber(paymentAmount || 0, 2)),
                            level: "4",
                            medium: true,
                        })}
                    {renderCalculatedIndicatorsRow({
                        label: "Зачисление на баланс, ₽:",
                        text: formatAmount(formatNumber(amountToCard || 0, 2)),
                        level: "2",
                    })}
                    {renderCalculatedIndicatorsRow({
                        label: "Налог исполнителя, ₽:",
                        text: `+${formatAmount(formatNumber(contractorTaxAmount || 0, 2))}`,
                        additionalText: "+0,00",
                        level: "2",
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={block()}>
            <NmForm
                className={element("form")}
                addMargin={true}
            >
                <div className="row">
                    <div className="col-8">
                        <NmDropdownV2
                            required
                            search={true}
                            size="xl"
                            name="specialityId"
                            label="Вид деятельности"
                            options={specialityOptions}
                            value={values.specialityId}
                            onChange={handleChange}
                            error={touched.specialityId && errors.specialityId}
                        />
                    </div>
                    <div className="col-8">
                        <NmDateRangePickerV2
                            required
                            isClearable
                            size="xl"
                            label="Период выполнения работ"
                            onChange={handleChange}
                            startFieldName="workStartDate"
                            endFieldName="workEndDate"
                            value={{
                                workStartDate: values.workStartDate,
                                workEndDate: values.workEndDate,
                            }}
                            minDate={{
                                start: nmContractDateConclusion ? new Date(nmContractDateConclusion) : null,
                                end: values.workStartDate ? new Date(values.workStartDate) : new Date(),
                            }}
                            error={
                                (touched.workStartDate && errors.workStartDate) ||
                                (touched.workEndDate && errors.workEndDate)
                            }
                        />
                    </div>
                </div>
                <NmInputV2
                    required
                    size="xl"
                    label="Название задания"
                    placeholder="Например: Устройство кирпичных стен"
                    maxLength={100}
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    error={touched.name && errors.name}
                />
                <NmTextareaV2
                    required
                    minRows={2}
                    maxRows={3}
                    label="Описание услуг (работ)"
                    placeholder="Введите описание услуг (работ)"
                    name="servicesDescription"
                    maxLength={1500}
                    value={values.servicesDescription}
                    onChange={handleChange}
                    error={touched.servicesDescription && errors.servicesDescription}
                />
                <DropdownWithInput
                    size="xl"
                    label="Ключевые слова"
                    fetchCallback={fetchKeywords}
                    placeholder="Введите ключевые слова"
                    minCharacters={3}
                    maxCharacters={100}
                    minSearchLength={4}
                    isDetectEqualValues={true}
                    isClearInputValueOnBlur={true}
                    options={keywordsOptions}
                    values={values.keywords}
                    name="keywords"
                    onChange={handleChange}
                    error={touched.keywords && errors.keywords}
                />
                <NmInputV2
                    required
                    size="xl"
                    label="Название сети"
                    placeholder="Введите название сети"
                    value={values.networkName}
                    name="networkName"
                    onChange={handleChange}
                    error={touched.networkName && errors.networkName}
                />
                <div className="row">
                    <div className="col-7">
                        <AmountInput
                            newInput
                            required
                            size="xl"
                            label="Сумма задания, ₽"
                            placeholder="0,00"
                            value={values.paymentAmount}
                            name="paymentAmount"
                            onChange={handleChange}
                            error={touched.paymentAmount && errors.paymentAmount}
                        />
                    </div>
                    <div className="col-9">
                        <div className="row align-items-end gx-2">
                            <div className="col-8">
                                <NmDropdownV2
                                    required
                                    search={true}
                                    size="xl"
                                    name="timeHours"
                                    label="Время выполнения"
                                    options={timeHoursOptions}
                                    value={values.timeHours}
                                    onChange={handleChange}
                                    tooltip={
                                        <HelpTooltip
                                            hover
                                            info
                                            children={"Укажите примерное время выполнения задания\nот 15 минут до 23 часов 45 минут"}
                                            position="top-left"
                                        />
                                    }
                                />
                            </div>
                            <div className="col-8">
                                <NmDropdownV2
                                    search={true}
                                    size="xl"
                                    name="timeMinutes"
                                    options={timeMinutesOptions}
                                    value={values.timeMinutes}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {
                            touched.timeHours && errors.timeHours &&
                            <ErrorTooltip
                                className={element("error")}
                                error={errors.timeHours}
                            />
                        }
                    </div>
                </div>
            </NmForm>
            {renderCalculator()}
        </div>
    );
};

export default CrowdTaskEditGeneralInformationForm;