import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import NmAdvancedTooltip from "../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmBadge from "../../../../../components/NmBadge";
import {ReactComponent as InfoIcon} from "../../../../../images/info_20.svg";

import dateFormat, {convertUtcToLocal, formatLocalDate} from "../../../../../utils/dateFormat";
import {formatAmountWithNullChecking, phoneFormat} from "../../../../../utils/stringFormat";
import {getClientNameWithBrand} from "../../../../../utils/stringHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {
    DOCUMENT_SOURCE_TYPE,
    DOCUMENT_STATUS,
    DOCUMENT_STATUS_COLOR_TRANSCRIPT,
    DOCUMENT_TYPE,
} from "../../../../../constants/documentType";
import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../../constants/finance";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_REGISTRY_FC_CARD,
    LINK_ORDER_CARD,
} from "../../../../../constants/links";
import {TAB} from "../../constants";

import {history} from "../../../../../store/configureStore";

import {
    getDocumentStatusDictSelector,
    getDocumentTypeDictsSelector,
} from "../../../../../ducks/documents";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../../constants/link-params";

const ContractorDocumentsItem = (props) => {
    const {
        tab,
        list,
        openDocument,
    } = props;

    const {t} = useTranslation();
    const statusDict = useSelector(getDocumentStatusDictSelector);

    const typeDict = useSelector(getDocumentTypeDictsSelector);

    const getClient = (doc, withBrand) => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", doc.clientId);

        if (withBrand) {
            return {
                label: "Компания",
                text: (
                    <Link to={clientLink}>
                        {getClientNameWithBrand(doc.clientName, doc.brand)}
                    </Link>
                ),
            };
        }

        return {
            label: "Компания",
            text: (
                <Link to={clientLink}>
                    {doc.clientName}
                </Link>
            ),
        };
    };

    const getLabels = (doc) => {
        const {
            documentType,
            orderWorkStartDate,
            orderWorkEndDate,
            clientId,
            documentStatus,
            expirationDatetime,
            orderId,
            orderNum,
            orderName,
            objectId,
            objectName,
            projectId,
            externalDocument,
            contractNumber,
            externalDocumentDate,
            contractorPhone,
            contractorInn,
            contractorName,
            crowdTaskId,
            contractType,
            additionalAgreementInfo,
        } = doc;

        const orderLink = LINK_ORDER_CARD
            .replace(":orderId", orderId)
            .replace(":clientId", clientId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
        const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":taskId", crowdTaskId)
            .replace(":clientId", clientId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);
        const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":objectId", objectId)
            .replace(":clientId", clientId)
            .replace(":projectId", projectId);
        const numAndName = {
            label: crowdTaskId ? "Номер и название задания" : "Номер и название заказа",
            text: (
                <Link to={crowdTaskId ? taskLink : orderLink}>
                    {`${orderNum} ${orderName}`}
                </Link>
            ),
        };
        const object = objectId && {
            label: "Объект", text: (
                <Link to={objectLink}>
                    {objectName}
                </Link>
            ),
        };
        const period = {
            label: crowdTaskId ? "Период задания" : "Период заказа",
            text: `${dateFormat(orderWorkStartDate, "dd.MM.yyyy")} - ${dateFormat(orderWorkEndDate, "dd.MM.yyyy")}`,
        };

        switch (tab) {
            case TAB.AGENCY_CONTRACT: {
                const dateTermination = documentStatus === DOCUMENT_STATUS.AGENCY_CONTRACT_TERMINATED ? [{
                    label: t("contractor-documents.date-termination"),
                    text: dateFormat(convertUtcToLocal(expirationDatetime)),
                }] : [];

                return [
                    ...dateTermination,
                ];
            }
            case TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA: {
                const dateTermination = documentStatus === DOCUMENT_STATUS.CONSENT_TO_PROCESSING_PERSONAL_DATA_ARCHIVED ? [{
                    label: t("contractor-documents.date-termination"),
                    text: dateFormat(convertUtcToLocal(expirationDatetime)),
                }] : [];

                return [
                    ...dateTermination,
                ];
            }
            case TAB.FRAME_CONTRACT: {
                const client = getClient(doc, true);

                if (additionalAgreementInfo) {
                    const contract = {
                        label: "Договор",
                        text: `Договор №${additionalAgreementInfo.relatedFrameContractNum} от ${additionalAgreementInfo.relatedFrameContractDate}`,
                    };
                    const personalDataVersion = {
                        label: "Версия персональных данных",
                        text: `от ${formatLocalDate(additionalAgreementInfo?.personalDataDate)}`,
                    };

                    return [
                        contract,
                        client,
                        personalDataVersion,
                    ];
                }

                const dateTermination = documentStatus === DOCUMENT_STATUS.FRAME_CONTRACT_TERMINATED ? [{
                    label: t("contractor-documents.date-termination"),
                    text: dateFormat(convertUtcToLocal(expirationDatetime)),
                }] : [];
                const externalDocumentLabel = externalDocument && {
                    text: "Внешний",
                    noWrap: false,
                    columnOnMobile: false,
                    alignItems: "center",
                    textTooltip: (
                        <NmAdvancedTooltip
                            position="bottom-left"
                            type="light"
                            children={
                                <div>
                                    <div>
                                        Номер договора:
                                        {" "}
                                        {contractNumber}
                                    </div>
                                    <div>
                                        Дата договора:
                                        {" "}
                                        {dateFormat(externalDocumentDate, "dd.MM.yyyy")}
                                    </div>
                                    <div>
                                        Телефон исполнителя:
                                        {" "}
                                        {phoneFormat(contractorPhone)}
                                    </div>
                                    <div>
                                        ИНН исполнителя:
                                        {" "}
                                        {contractorInn}
                                    </div>
                                    <div>
                                        ФИО исполнителя:
                                        {" "}
                                        {contractorName}
                                    </div>
                                </div>
                            }
                            trigger={
                                <InfoIcon
                                    height={18}
                                    width={18}
                                />
                            }
                        />
                    ),
                };

                return [
                    externalDocumentLabel,
                    {label: "Тип договора", text: typeDict[documentType]},
                    client,
                    object,
                    ...dateTermination,
                ];
            }
            case TAB.ORDER_APPLICATION: {
                const dateTermination = [
                    DOCUMENT_STATUS.ORDER_APPLICATION_COMPLETED,
                    DOCUMENT_STATUS.TASK_APPLICATION_COMPLETED,
                ].includes(documentStatus) ? [{
                        label: t("contractor-documents.date-termination"),
                        text: dateFormat(convertUtcToLocal(expirationDatetime)),
                    }] : [];
                const client = getClient(doc, true);

                return [
                    client,
                    numAndName,
                    period,
                    ...dateTermination,
                ];
            }
            case TAB.ACT_OF_ACCEPTANCE_OF_WORK: {
                return [
                    getClient(doc, true),
                    numAndName,
                    period,
                ];
            }
            case TAB.INSURANCE_POLICY: {
                return [
                    getClient(doc),
                    numAndName,
                    period,
                ];
            }
            case TAB.RECEIPT_RNKO: {
                const orderInfo = ![
                    DOCUMENT_TYPE.TRANSACTION_RECEIPT,
                    DOCUMENT_TYPE.RECEIPT_OF_PAYMENT,
                    DOCUMENT_TYPE.CONTRACTOR_PHONE_TRANSACTION_RECEIPT,
                ].includes(documentType) ? [
                        {label: "Период заказа", text: `${orderWorkStartDate} - ${orderWorkEndDate}`},
                        numAndName,
                    ] : [];
                const quintationType = {label: "Тип квитанции", text: typeDict[documentType]};

                return [
                    quintationType,
                    ...orderInfo,
                ];
            }
            case TAB.PENDING_CONTRACTS: {
                if (additionalAgreementInfo) {
                    const client = getClient(doc, true);
                    const contract = {
                        label: "Договор",
                        text: `Договор №${additionalAgreementInfo.relatedFrameContractNum} от ${additionalAgreementInfo.relatedFrameContractDate}`,
                    };
                    const personalDataVersion = {
                        label: "Версия персональных данных",
                        text: `от ${formatLocalDate(additionalAgreementInfo?.personalDataDate)}`,
                    };

                    return [
                        contract,
                        client,
                        personalDataVersion,
                    ];
                }

                return [
                    {label: "Тип договора", text: ORDER_WORK_REPORT_TYPE_TRANSLATE[contractType]},
                    getClient(doc),
                    object,
                ];
            }
        }
    };

    const getCards = (doc) => {
        if (tab === TAB.ACT_OF_ACCEPTANCE_OF_WORK) {
            const {
                serviceSum,
            } = doc;

            return [{
                title: "Сумма, ₽",
                value: formatAmountWithNullChecking(serviceSum),
                className: "col-16 col-md-2",
            }];
        }
    };

    const getActions = (doc) => {
        const {
            documentType,
            downloadLink,
            fileLink,
            registryId,
            orderId,
            clientId,
            sourceType,
        } = doc;

        if (tab !== TAB.DOCUMENTS_REGISTRY) {
            const _downloadLink = tab === TAB.IDENTIFICATION_SHEET ? fileLink : downloadLink;
            const registryLink = LINK_CLIENT_REGISTRY_FC_CARD
                .replace(":clientId", clientId)
                .replace(":registryId", registryId);
            const orderLink = LINK_ORDER_CARD
                .replace(":clientId", clientId)
                .replace(":orderId", orderId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
            const isVisibleRegistryLink = !sourceType || sourceType === DOCUMENT_SOURCE_TYPE.FRAME_CONTRACT;

            return {
                renderCount: {
                    desktop: 3,
                    tablet: 3,
                    mobile: 0,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            color: "grey",
                            size: "lg",
                            onClick: () => {
                                openDocument(documentType, _downloadLink);
                            },
                            children: "Скачать",
                        },
                        visible: Boolean(_downloadLink),
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            color: "grey",
                            size: "lg",
                            onClick: () => history.push(registryLink),
                            children: "Перейти в реестр",
                        },
                        visible: tab === TAB.PENDING_CONTRACTS && Boolean(registryId) && isVisibleRegistryLink,

                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            color: "grey",
                            size: "lg",
                            onClick: () => history.push(orderLink),
                            children: "Перейти в заказ",
                        },
                        visible: tab === TAB.PENDING_CONTRACTS && Boolean(orderId),
                    },
                ],
            };
        }
    };

    const getStatusBadge = ({documentStatus}) => {
        if (
            [
                TAB.ACT_OF_ACCEPTANCE_OF_WORK,
                TAB.RECEIPT_RNKO,
                TAB.IDENTIFICATION_SHEET,
            ].includes(tab)
        ) {
            return null;
        }

        if (tab === TAB.PENDING_CONTRACTS) {
            return (
                <NmBadge
                    noWrap
                    mod="light-green"
                    text="На подписании"
                />
            );
        }

        return (
            <NmBadge
                noWrap
                mod={DOCUMENT_STATUS_COLOR_TRANSCRIPT[documentStatus] || "gray"}
                text={statusDict[documentStatus]}
            />
        );
    };

    const getSecondaryHeader = (doc) => {
        const {
            additionalAgreementInfo,
            datetime,
            dateTime,
            approvedDateTime,
        } = doc;

        if (additionalAgreementInfo) {
            return `${t("contractor-documents.creationDate")} ${dateFormat(convertUtcToLocal(datetime))}`;
        }

        if (tab === TAB.PENDING_CONTRACTS) {
            return dateFormat(convertUtcToLocal(dateTime));
        }

        if (tab === TAB.IDENTIFICATION_SHEET) {
            return `Дата и время: ${approvedDateTime ? dateFormat(convertUtcToLocal(approvedDateTime)) : "-"}`;
        }

        return `${t("contractor-documents.creationDate")} ${dateFormat(convertUtcToLocal(datetime))}`;
    };

    const getRows = () => {
        return list.map((doc) => {
            const classNameMainContent = tab === TAB.ACT_OF_ACCEPTANCE_OF_WORK ?
                "col-16 col-md-12" :
                `col-16 col-md-14 ${tab === TAB.IDENTIFICATION_SHEET ? "mb-2" : ""}`;
            const actionsClassName = tab === TAB.ACT_OF_ACCEPTANCE_OF_WORK ? "col-md-1" : "col-md-2 justify-content-end";

            return {
                ...doc,
                contentRow: (
                    <NmListCard
                        classNameMainContent={classNameMainContent}
                        noDivider
                        primaryHeader={doc.additionalAgreementInfo?.documentName}
                        secondaryHeader={getSecondaryHeader(doc)}
                        secondaryHeaderStatus={getStatusBadge(doc)}
                        cards={getCards(doc)}
                        labels={getLabels(doc)}
                        actionsClassName={actionsClassName}
                        isFixedActions
                        mediaControls={getActions(doc)}
                    />
                ),
            };
        });
    };

    return (
        <CheckboxList
            rows={getRows()}
        />
    );
};

export default ContractorDocumentsItem;