import React from "react";
import {useDispatch} from "react-redux";

import {downloadDocument} from "../../../ducks/documents";

import "./style.sass";

const OrderTemplateInfo = (props) => {
    const {
        link,
        id,
        linkName,
        useLinkName = true,
    } = props;

    const dispatch = useDispatch();

    if (!id) {
        return "-";
    }

    const onClickDownload = () => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: link,
            fileName: useLinkName && `${linkName}.pdf`,
        }));
    };

    return (
        <span
            onClick={onClickDownload}
            className="order-template-info"
            title={linkName}
        >
            {linkName}
        </span>
    );
};

export default OrderTemplateInfo;