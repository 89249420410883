import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {getPageCrowdPaymentAdm} from "../../../../ducks/bff/adm/crowd-payments/services";
import {getPageCrowdPaymentClient} from "../../../../ducks/bff/clients/crowd-payments/services";

import {TASK_PAYMENTS_SORT_TYPE} from "../../../../constants/crowd/task-payment";

const useFinanceCrowdPaymentsFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
        clientId,
        paymentNumberFilter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter, clientId, paymentNumberFilter]);

    const fetchList = () => {
        const clientIdsFilter = filter.clientId ? [filter.clientId] : undefined;

        const reqData = {
            pageNum,
            pageSize,
            sortingType: clientId ? TASK_PAYMENTS_SORT_TYPE.CONTRACTOR_NPD_PAYMENTS : undefined,
            clientIds: clientId ? [clientId] : clientIdsFilter,
            paymentNumber: !isEmpty(filter) ? filter.paymentNumber : paymentNumberFilter,
            ...filter,
        };

        if (clientId) {
            dispatch(getPageCrowdPaymentClient(reqData));

            return;
        }

        dispatch(getPageCrowdPaymentAdm(reqData));
    };

    return {
        fetchList,
    };
};

export default useFinanceCrowdPaymentsFetch;