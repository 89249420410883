import {useMemo} from "react";
import {useSelector} from "react-redux";

import {RESPONSE_CANDIDATE_FIELD_NAMES} from "../index";

import {useRecruitmentCandidateInformationDicts} from "../../CandidateInformation/hooks/useDicts";

import {getRecruitmentCandidateInformationValues} from "../../CandidateInformation/utils/getAdditionalValues";
import {getRecruitmentCandidateResponseValues} from "../utils/getValues";

import {getCitizenshipSelector} from "../../../../ducks/contractor";

export const useRecruitmentResponseDataTransferValues = (params) => {
    const {
        card = {},
        list = [],
        isResponse ,
        responseId,
        candidateId,
    } = params;

    const {
        driverLicenseDict,
        languageDict,
        educationLevelDict,
        schedulesDict,
        workExperienceDict,
    } = useRecruitmentCandidateInformationDicts();

    const citizenshipDict = useSelector(getCitizenshipSelector);

    const values = useMemo(() => {
        const {
            education,
            workExperience,
            driverLicences,
            hasCar,
            languages,
            schedules,
            readyForTrip,
            readyForMoving,
            resumes,
            socialMedia,
            salary,
            notes,
            experiences,
        } = getRecruitmentCandidateInformationValues(card, {
            educationLevelDict,
            workExperienceDict,
            driverLicenseDict,
            languageDict,
            schedulesDict,
        });

        const {
            applicantName,
            birthDate,
            gender,
            phone,
            email,
            citizenship,
            age,
            photo,
            addresses,
            specialities,
            avatar,
        } = getRecruitmentCandidateResponseValues(card, {
            citizenshipDict,
            isResponse,
            responseId,
            candidateId,
        });

        const names = list.map(item => item.name);

        const result = {
            specialities: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.specialities,
                value: specialities,
            },
            education: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.education,
                value: education,
            },
            workExperience: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.workExperience,
                value: workExperience,
            },
            driverLicences: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.driverLicences,
                value: driverLicences,
            },
            hasCar: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.hasCar,
                value: hasCar,
            },
            experiences: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.experiences,
                value: experiences,
                className: "flex-column",
            },
            age: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.age,
                value: age,
            },
            photo: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.photo,
                value: photo,
                className: "flex-column",
            },
            addresses: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.addresses,
                value: addresses,
            },
            languages: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.languages,
                value: languages,
            },
            schedules: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.schedules,
                value: schedules,
            },
            readyForTrip: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.readyForTrip,
                value: readyForTrip,
            },
            readyForMoving: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.readyForMoving,
                value: readyForMoving,
            },
            resumes: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.resumes,
                value: resumes,
            },
            socialMedia: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.socialMedia,
                value: socialMedia,
            },
            salary: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.salary,
                value: salary,
            },
            notes: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.notes,
                value: notes,
            },
            applicantName: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.applicantName,
                value: applicantName,
            },
            birthDate: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.birthDate,
                value: birthDate,
            },
            gender: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.gender,
                value: gender,
            },
            phone: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.phone,
                value: phone,
            },
            email: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.email,
                value: email,
            },
            citizenship: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.citizenship,
                value: citizenship,
            },
            avatar: {
                label: RESPONSE_CANDIDATE_FIELD_NAMES.avatar,
                value: avatar,
                className: "flex-column",
            },
        };

        return Object.keys(result)
            .filter(name => names.includes(name))
            .reduce((accum, name) => {
                return {
                    ...accum,
                    [name]: result[name],
                };
            }, {});
    }, [
        educationLevelDict,
        workExperienceDict,
        driverLicenseDict,
        languageDict,
        schedulesDict,
        card,
        list,
    ]);

    return values;
};