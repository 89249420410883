export const getRecruitmentCandidateResumesRequestData = (values, candidateId) => {
    const formData = new FormData();

    formData.append("comment", values.comment);
    formData.append("file", values.files[0]);

    return {
        id: candidateId,
        formData,
    };
};