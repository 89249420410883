import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmButton from "../../../../components/NmButton";
import NmColorPicker from "../../../../components/NmColorPicker";
import NmTitle from "../../../../components/NmTitle";
import OrderTemplateInfo from "../../../order/template-info";
import OnboardingCheckDesignModal from "../check-design-modal";
import OnboardingEditFormLogo from "../edit-form-logo";
import {Message} from "semantic-ui-react";

import {useOnboardingScenarioEditFormData} from "./hooks/useData";

import bem from "../../../../utils/bem";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES} from "../../../../constants/clientList";
import {COLOR} from "../../../../constants/color";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    ONBOARDING_SCENARIO_CITIZENSHIP_GROUP,
    ONBOARDING_SCENARIO_CITIZENSHIP_GROUP_DICT,
    ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE,
} from "../../../../constants/onboarding";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS,
} from "../../../contractor/contractor-info-new/components/additional-documents-verification/constants";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {getCitizenshipOptionsSelector} from "../../../../ducks/contractor";
import {
    additionalDocTypeDictSelector,
    onboardingBindingVariantsOptionsSelector,
    onboardingIsClientBelongsGroupSelector,
    onboardingObjectVariantsOptionsSelector,
    onboardingObjectVariantsSelector,
    onboardingProjectParamsVariantsOptionsSelector,
    onboardingPromoCodeVariantsOptionsSelector,
    onboardingScenarioProgressSelector,
} from "../../../../ducks/onboarding";

import "./style.sass";

const OnboardingScenarioEditForm = (props) => {
    const {
        card = {},
        clientId,
        onClose = () => {
        },
        fetchList,
    } = props;

    const [block, element] = bem("onboarding-scenario-edit-form");

    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const projectParamsOptions = useSelector(onboardingProjectParamsVariantsOptionsSelector);
    const promoCodeOptions = useSelector(onboardingPromoCodeVariantsOptionsSelector);
    const objectsList = useSelector(onboardingObjectVariantsSelector);
    const objectOptions = useSelector(onboardingObjectVariantsOptionsSelector);
    const bindingVariantsOptions = useSelector(onboardingBindingVariantsOptionsSelector);
    const isClientBelongsGroup = useSelector(onboardingIsClientBelongsGroupSelector);
    const additionalDocTypeDict = useSelector(additionalDocTypeDictSelector);
    const {
        onboardingContractorRegistrationWithoutInnAvailable,
        individualRegistryPaymentsAvailable,
    } = useSelector(getClientPropertiesCardSelector);
    const progress = useSelector(onboardingScenarioProgressSelector);

    const isEdit = !isEmpty(card);
    const isHasContractors = Boolean(card.contractorsCount);

    const _citizenshipOptions = [
        ...dictionaryToOptions(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP_DICT),
        ...citizenshipOptions,
    ];

    const additionalDocumentList = [
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.SNILS,
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.EMPLOYMENT_HISTORY,
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_BOOK,
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.FINGERPRINTING,
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_CERTIFICATION_REPORT,
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.COVID_19_VACCINATION_CERTIFICATE,
        CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO,
    ];

    const {
        values,
        errors,
        touched,
        handleChange,
        handleChangeAdditionalDocumentParams,
        handleChangeCitizenshipRule,
        handleSubmit,
        openCheckDesignModal,
        setOpenCheckDesignModal,
        onCloseConfirmModal,
        confirmData,
        onSearchChangeObject,
    } = useOnboardingScenarioEditFormData({
        card,
        clientId,
        isEdit,
        fetchList,
        onClose,
        isHasContractors,
    });

    const selectedObject = objectsList.find(item => item.objectId === values.objectId) || {};

    const getCitizenshipDisabledItems = () => {
        if (values.citizenships?.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS)) {
            return [ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS];
        }

        if (values.citizenships.includes(ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS)) {
            return [ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS];
        }

        return [];
    };

    const renderConfirmModal = () => {
        const {
            content,
            submit,
        } = confirmData;

        return (
            <NmConfirmV2
                size="sm"
                content={content}
                onCancel={onCloseConfirmModal}
                onConfirm={submit}
                confirmButton="ОК"
                isOnlyConfirm
                mobile="column"
            />
        );
    };

    const renderContent = () => {
        if (!isEmpty(confirmData)) {
            return renderConfirmModal();
        }

        if (openCheckDesignModal) {
            return (<OnboardingCheckDesignModal
                onClose={() => setOpenCheckDesignModal(false)}
                primaryColor={values.primaryColor}
                primaryTextColor={values.primaryTextColor}
                secondaryColor={values.secondaryColor}
                secondaryTextColor={values.secondaryTextColor}
                welcomeString={values.welcomeString}
                supportPhone={values.supportPhone}
                logo={values.files && values.files.length ? values.files[0] : null}
            />);
        }

        return (<NmModal
            className={block()}
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование сценария" : "Сценарий"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={isEdit ? "Сохранить" : "Создать"}
                    cancelBtnContent="Отменить"
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                <NmTextareaV2
                    disabled={isHasContractors}
                    size="xl"
                    required
                    value={values.name}
                    name="name"
                    label="Название"
                    placeholder="Введите название"
                    maxLength={150}
                    onChange={handleChange}
                    className="mb-4"
                    error={touched?.name && errors?.name}
                />
                <NmDropdownV2
                    disabled={isHasContractors}
                    size="xl"
                    required
                    search
                    placeholder="Выберите объект"
                    label="Объект"
                    name="objectId"
                    onChange={handleChange}
                    onSearchChange={onSearchChangeObject}
                    searchQuery={values.valueObjectFilter}
                    value={values.objectId}
                    options={objectOptions}
                    error={touched?.objectId && errors?.objectId}
                    isVisibleTooltip
                    tooltip={
                        <HelpTooltip
                            hover
                            info
                            width={18}
                            height={18}
                            text={"При регистрации исполнитель будет автоматически занесён в список исполнителей, находящийся в разделе Объекты подраздел \"Исполнители\""}
                            position="bottom-left"
                        />
                    }
                />
                <NmCheckboxV2
                    disabled={!values.objectId || isHasContractors}
                    label="Предложить подписать договор"
                    checked={values.offerContractToSign}
                    name="offerContractToSign"
                    onChange={handleChange}
                />
                <div className="flex flex-column mb-4">
                    <div className="flex flex-aligned-center">
                        <NmRadioV2
                            label="НПД"
                            additionalLabelContent={
                                selectedObject.smzFrameContractTemplateDownloadLink &&
                                <>
                                    (
                                    <OrderTemplateInfo
                                        id={selectedObject.smzFrameContractTemplateName}
                                        linkName="Просмотр договора"
                                        useLinkName={false}
                                        link={selectedObject.smzFrameContractTemplateDownloadLink}
                                    />
                                    )
                                </>
                            }
                            checked={values.contractType === ORDER_WORK_REPORT_TYPE.SMZ}
                            name="contractType"
                            value={ORDER_WORK_REPORT_TYPE.SMZ}
                            disabled={!values.offerContractToSign || isHasContractors}
                            onChange={handleChange}
                        />
                    </div>
                    <NmRadioV2
                        className="mt-2"
                        label="НДФЛ"
                        additionalLabelContent={
                            selectedObject.civilFrameContractTemplateDownloadLink &&
                            <>
                                (
                                <OrderTemplateInfo
                                    id={selectedObject.civilFrameContractTemplateName}
                                    linkName="Просмотр договора"
                                    useLinkName={false}
                                    link={selectedObject.civilFrameContractTemplateDownloadLink}
                                />
                                )
                            </>
                        }
                        checked={values.contractType === ORDER_WORK_REPORT_TYPE.CIVIL}
                        disabled={!values.offerContractToSign || isHasContractors}
                        name="contractType"
                        value={ORDER_WORK_REPORT_TYPE.CIVIL}
                        onChange={handleChange}
                    />
                    {
                        individualRegistryPaymentsAvailable &&
                        <NmRadioV2
                            className="mt-2"
                            label="ИП"
                            additionalLabelContent={
                                selectedObject.individualFrameContractTemplateDownloadLink &&
                                <>
                                    (
                                    <OrderTemplateInfo
                                        id={selectedObject.individualFrameContractTemplateName}
                                        linkName="Просмотр договора"
                                        useLinkName={false}
                                        link={selectedObject.individualFrameContractTemplateDownloadLink}
                                    />
                                    )
                                </>
                            }
                            checked={values.contractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                            disabled={!values.offerContractToSign || isHasContractors}
                            name="contractType"
                            value={ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                            onChange={handleChange}
                        />
                    }
                </div>
                {
                    (touched?.contractType && errors?.contractType) && <Message negative>
                        {errors.contractType}
                    </Message>
                }
                <NmCheckboxV2
                    disabled={
                        !values.offerContractToSign
                        || [ORDER_WORK_REPORT_TYPE.INDIVIDUAL].includes(values.contractType)
                        || values.signingAllowedOnlyWithSmzTaxOffer
                        || isHasContractors
                    }
                    label="Разрешить подписывать РД до проверки персональных данных Администрацией Наймикс"
                    checked={values.signingAllowedWithoutFullRegistration}
                    name="signingAllowedWithoutFullRegistration"
                    onChange={handleChange}
                />
                <NmCheckboxV2
                    disabled={
                        !values.offerContractToSign
                        || [ORDER_WORK_REPORT_TYPE.CIVIL, ORDER_WORK_REPORT_TYPE.INDIVIDUAL].includes(values.contractType)
                        || values.signingAllowedOnlyWithSmzTaxOffer
                        || isHasContractors
                    }
                    label="Разрешить подписывать РД при отсутствии статуса НПД и партнерства с Наймикс"
                    checked={values.signingAllowedWithoutOkTaxStatus}
                    name="signingAllowedWithoutOkTaxStatus"
                    onChange={handleChange}
                    isVisibleTooltip
                    tooltip={<HelpTooltip
                        text="Исполнители смогут подписывать договоры при отсутствии статуса НПД и подключенного партнерства с Наймикс"
                        info
                        position="bottom-right"
                        outline={false}
                        hover
                    />}
                />
                <NmCheckboxV2
                    disabled={
                        !values.offerContractToSign
                        || [ORDER_WORK_REPORT_TYPE.CIVIL, ORDER_WORK_REPORT_TYPE.INDIVIDUAL].includes(values.contractType)
                        || values.signingAllowedWithoutOkTaxStatus
                        || values.signingAllowedWithoutFullRegistration
                        || isHasContractors
                    }
                    label="Разрешить подписывать РД только при наличии подключенной автоуплаты налога"
                    checked={values.signingAllowedOnlyWithSmzTaxOffer}
                    name="signingAllowedOnlyWithSmzTaxOffer"
                    onChange={handleChange}
                    isVisibleTooltip
                    tooltip={<HelpTooltip
                        text="Исполнители смогут подписывать договоры только при наличии подключенной автоматической уплаты налога"
                        info
                        position="bottom-right"
                        outline={false}
                        hover
                    />}
                />
                <NmCheckboxV2
                    disabled={!selectedObject.otherDocumentTemplateDownloadLink || isHasContractors}
                    label="Предложить подписать документ"
                    additionalLabelContent={
                        selectedObject.otherDocumentTemplateDownloadLink &&
                        <>
                            (
                            <OrderTemplateInfo
                                id={selectedObject.otherDocumentTemplateName}
                                linkName={selectedObject.otherDocumentTemplateName}
                                useLinkName={false}
                                link={selectedObject.otherDocumentTemplateDownloadLink}
                            />
                            )
                        </>
                    }
                    isAlignItemsCenter
                    checked={values.offerOtherDocumentToSign}
                    name="offerOtherDocumentToSign"
                    onChange={handleChange}
                    isVisibleTooltip={!selectedObject.otherDocumentTemplateDownloadLink}
                    tooltip={<HelpTooltip
                        text={"Для возможности предложить исполнителям подписать документ, перейдите в карточку редактирования выбранного вами объекта, отметьте настройку \"Запрашивать подписание иного документа на объекте\", и выберите шаблон иного документа"}
                        info
                        position="bottom-right"
                        outline={false}
                        hover
                    />}
                />
                <NmCheckboxV2
                    disabled={!values.offerOtherDocumentToSign || isHasContractors}
                    label="Документ обязателен к подписанию"
                    checked={values.otherDocumentRequired}
                    name="otherDocumentRequired"
                    onChange={handleChange}
                />
                <NmTitle size="md">
                    Параметры исполнителей
                </NmTitle>
                <NmDropdownV2
                    search
                    multiple
                    placeholder="Выберите гражданство"
                    label="Гражданство"
                    disabledItems={getCitizenshipDisabledItems()}
                    skipItemsAllSelected={[
                        ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.NOT_RESIDENTS,
                        ONBOARDING_SCENARIO_CITIZENSHIP_GROUP.RESIDENTS,
                    ]}
                    onChange={handleChangeCitizenshipRule}
                    value={values.citizenships}
                    options={_citizenshipOptions}
                    error={errors?.citizenships}
                />
                <Text level="3">
                    Запросить дополнительные документы
                </Text>
                {
                    additionalDocumentList.map(item => {
                        return (
                            <div className="row align-items-center mb-4">
                                <div className="col-16 col-md-8">
                                    <NmCheckboxV2
                                        disabled={
                                            !Object.keys(additionalDocTypeDict).includes(item) ||
                                            isHasContractors
                                        }
                                        label={CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[item]?.name}
                                        checked={values.additionalDocumentParams[item]?.tracked}
                                        name="tracked"
                                        onChange={(e, {name, checked}) => {
                                            handleChangeAdditionalDocumentParams(item, name, checked);
                                        }}
                                    />
                                </div>
                                <div className="col-16 col-md-8">
                                    <NmCheckboxV2
                                        disabled={
                                            !values.additionalDocumentParams[item]?.tracked ||
                                            isHasContractors
                                        }
                                        label="Обязательно"
                                        checked={values.additionalDocumentParams[item]?.required}
                                        name="required"
                                        onChange={(e, {name, checked}) => {
                                            handleChangeAdditionalDocumentParams(item, name, checked);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })
                }
                <NmCheckboxV2
                    disabled={
                        !onboardingContractorRegistrationWithoutInnAvailable
                        || isHasContractors
                        || values.contractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL
                    }
                    label="Разрешить регистрацию без ИНН"
                    checked={values.registrationAllowedWithoutInn}
                    name="registrationAllowedWithoutInn"
                    onChange={handleChange}
                />
                <NmTitle size="md">
                    Параметры сценария
                </NmTitle>
                <div className="row align-items-center mb-4">
                    <div className="col-16 col-md-8">
                        <NmCheckboxV2
                            disabled={!projectParamsOptions.length || isHasContractors}
                            label="Привязать ProjectParams"
                            checked={values.applyProjectParams}
                            name="applyProjectParams"
                            onChange={handleChange}
                            isVisibleTooltip={!projectParamsOptions.length}
                            tooltip={<HelpTooltip
                                text="Нет действующего ProjectParams"
                                info
                                position="bottom-right"
                                outline={false}
                                hover
                            />}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            disabled={!values.applyProjectParams || isHasContractors}
                            required={values.applyProjectParams}
                            size="xl"
                            placeholder="Выберите"
                            label="ProjectParams"
                            name="projectParams"
                            onChange={handleChange}
                            value={values.projectParams}
                            options={projectParamsOptions}
                            error={touched?.projectParams && errors?.projectParams}
                            search
                        />
                    </div>
                </div>
                <div className="row align-items-center mb-4">
                    <div className="col-16 col-md-8">
                        <NmCheckboxV2
                            disabled={!promoCodeOptions.length || isHasContractors}
                            label="Привязать промокод"
                            checked={values.applyPromoCode}
                            name="applyPromoCode"
                            onChange={handleChange}
                            isVisibleTooltip={!promoCodeOptions.length}
                            tooltip={<HelpTooltip
                                text="Нет действующего Промокода"
                                info
                                position="bottom-right"
                                outline={false}
                                hover
                            />}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            disabled={!values.applyPromoCode || isHasContractors}
                            required={values.applyPromoCode}
                            size="xl"
                            placeholder="Выберите"
                            label="Промокод"
                            name="promoCode"
                            onChange={handleChange}
                            value={values.promoCode}
                            options={promoCodeOptions}
                            error={touched?.promoCode && errors?.promoCode}
                            search
                        />
                    </div>
                </div>
                <NmDropdownV2
                    disabled={!isClientBelongsGroup || isHasContractors}
                    multiple
                    size="xl"
                    placeholder="Выберите сценарий"
                    label="Добавить связанные сценарии"
                    name="boundScenarios"
                    onChange={handleChange}
                    value={values.boundScenarios}
                    options={bindingVariantsOptions}
                    search
                />
                <NmCheckboxV2
                    disabled={!isClientBelongsGroup || isHasContractors}
                    label="Добавить в Закрытый контур"
                    checked={values.applyClientGroup}
                    name="applyClientGroup"
                    onChange={handleChange}
                    isVisibleTooltip={!isClientBelongsGroup}
                    tooltip={<HelpTooltip
                        text={"Для создания \"Закрытого контура\" обратитесь к Вашему менеджеру Наймикс"}
                        info
                        position="bottom-right"
                        outline={false}
                        hover
                    />}
                />
                <div className="flex flex-aligned-center mb-4">
                    <NmRadioV2
                        label="Сразу"
                        checked={values.clientGroupApplicationMode === ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.INSTANTLY}
                        name="clientGroupApplicationMode"
                        value={ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.INSTANTLY}
                        disabled={!values.applyClientGroup || isHasContractors}
                        onChange={handleChange}
                    />
                    <NmRadioV2
                        className="ms-2"
                        label="После подписания РД"
                        checked={values.clientGroupApplicationMode === ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.AFTER_CONTRACT_SIGNING}
                        disabled={!values.applyClientGroup || !values.offerContractToSign || isHasContractors}
                        name="clientGroupApplicationMode"
                        value={ONBOARDING_SCENARIO_STATUS_APPLICATION_MODE.AFTER_CONTRACT_SIGNING}
                        onChange={handleChange}
                    />
                </div>
                <div className="row align-items-center mb-4">
                    <div className="col-16 col-md-8">
                        <NmCheckboxV2
                            disabled={!values.applyClientGroup || isHasContractors}
                            label="Выводить из закрытого контура после расторжения РД"
                            checked={values.removeFromClientGroupAfterContractTermination}
                            name="removeFromClientGroupAfterContractTermination"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required={values.removeFromClientGroupAfterContractTermination}
                            disabled={!values.removeFromClientGroupAfterContractTermination || isHasContractors}
                            size="xl"
                            label="Через, дней"
                            name="removeFromClientGroupAfterContractTerminationDaysOffset"
                            value={values.removeFromClientGroupAfterContractTerminationDaysOffset}
                            placeholder="Введите от 0 до 120"
                            onChange={handleChange}
                            error={touched?.removeFromClientGroupAfterContractTerminationDaysOffset && errors?.removeFromClientGroupAfterContractTerminationDaysOffset}
                        />
                    </div>
                </div>
                <NmTitle size="md">
                    Дизайн страницы
                </NmTitle>
                <NmCheckboxV2
                    label="Задать пользовательский стиль"
                    checked={values.customDesignEnabled}
                    name="customDesignEnabled"
                    onChange={handleChange}
                    isVisibleTooltip
                    tooltip={<HelpTooltip
                        text={"Вы можете собрать дизайн страницы под стиль компании"}
                        info
                        position="bottom-right"
                        outline={false}
                        hover
                    />}
                />
                <div className="row mb-4">
                    <div className="col-16 col-md-8">
                        <NmColorPicker
                            disabled={!values.customDesignEnabled}
                            label="Основной цвет:"
                            setColor={(color) => handleChange(null, {name: "primaryColor", value: color})}
                            currentColor={values.primaryColor}
                        />
                        <NmColorPicker
                            disabled={!values.customDesignEnabled}
                            className="mt-2"
                            label="Текст:"
                            setColor={(color) => handleChange(null, {name: "primaryTextColor", value: color})}
                            currentColor={values.primaryTextColor}
                            size="md"
                        />
                        <Text
                            level="3"
                            color={COLOR.SECONDARY_45}
                            className="mt-2"
                        >
                            Пример
                        </Text>
                        <div className={element("preview-box")}>
                            <NmButton
                                style={{
                                    backgroundColor: values.primaryColor,
                                    color: values.primaryTextColor,
                                }}
                            >
                                Кнопка
                            </NmButton>
                        </div>
                    </div>
                    <div className="col-16 col-md-8">
                        <NmColorPicker
                            disabled={!values.customDesignEnabled}
                            label="Дополнительный цвет:"
                            setColor={(color) => handleChange(null, {name: "secondaryColor", value: color})}
                            currentColor={values.secondaryColor}
                        />
                        <NmColorPicker
                            disabled={!values.customDesignEnabled}
                            className="mt-2"
                            label="Текст:"
                            setColor={(color) => handleChange(null, {name: "secondaryTextColor", value: color})}
                            currentColor={values.secondaryTextColor}
                            size="md"
                        />
                        <Text
                            level="3"
                            color={COLOR.SECONDARY_45}
                            className="mt-2"
                        >
                            Пример
                        </Text>
                        <div className={element("preview-box")}>
                            <NmButton
                                style={{
                                    backgroundColor: values.secondaryColor,
                                    color: values.secondaryTextColor,
                                }}
                            >
                                Кнопка
                            </NmButton>
                        </div>
                    </div>
                </div>
                <OnboardingEditFormLogo
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                />
                <NmTextareaV2
                    disabled={!values.customDesignEnabled}
                    size="xl"
                    required
                    value={values.welcomeString}
                    name="welcomeString"
                    label="Текст для исполнителей при переходе по ссылке"
                    placeholder="Введите текст"
                    maxLength={300}
                    onChange={handleChange}
                    error={touched?.welcomeString && errors?.welcomeString}
                />
                <div className="col-16 col-md-8">
                    <NmInputV2
                        disabled={!values.customDesignEnabled}
                        required
                        size="xl"
                        mask="+7 (999) 999 99 99"
                        label="Телефон поддержки"
                        name="supportPhone"
                        value={values.supportPhone}
                        placeholder="Телефон"
                        onChange={handleChange}
                        error={touched?.supportPhone && errors?.supportPhone}
                    />
                </div>
                <div>
                    <NmButton
                        color="light-green"
                        onClick={() => setOpenCheckDesignModal(true)}
                    >
                        Посмотреть результат дизайна
                    </NmButton>
                </div>
            </NmForm>
        </NmModal>);
    };

    return renderContent();
};

export default OnboardingScenarioEditForm;