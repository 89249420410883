import React, { FC, ReactNode } from "react";

import bem from "../../../../utils/bem";

import "./style.sass";

interface INmInfoCardText {
    text?: string | number | ReactNode,
    children?: string,
    className?: string,
    bold?: boolean,
    fluid?: boolean,
    wrap?: boolean,
    overflowEllipse?: boolean,
    color?: string,
    title?: string
}

const NmInfoCardText: FC<INmInfoCardText> = (props) => {
    const {
        text,
        children,
        className = "",
        fluid,
        wrap,
        bold,
        overflowEllipse = false,
        color,
        title,
    } = props;
    const [block] = bem("nm-info-card-text", className);

    return (
        <div
            style={{color}}
            title={title}
            className={block({bold, wrap, fluid, overflowEllipse})}
        >
            {children || text}
        </div>
    );
};

export default NmInfoCardText;