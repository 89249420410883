import React from "react";
import {Route, Switch, withRouter} from "react-router";

import Brigade from "../../../brigade";
import Objects from "../../../objects";
import ProjectsList from "../../../projects/projects-list";
import {ClientBrands} from "../../brands";
import ClientContactInfo from "../../client-contact-info";
import ClientMemberList from "../../client-members";
import ClientFeedbackList from "../../feedbacks";

import {
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_INFO,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_OBJECTS_LIST,     LINK_CLIENT_PROJECTS_LIST} from "../../../../constants/links";


export const ClientCardCompanyRoute = (props) => {
    const {
        selectedClientId,
        isEditable,
    } = props;

    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_INFO}
                render={props => (
                    <ClientContactInfo
                        {...props}
                        selectedClientId={selectedClientId}
                        isEditable={isEditable}
                    />
                )}
            />
            <Route
                exact
                path={LINK_CLIENT_MEMBERS_LIST}
                render={() => (
                    <ClientMemberList
                        {...props}
                        selectedClientId={selectedClientId}
                        isEditable={isEditable}
                    />
                )}
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_LIST}
                component={ProjectsList}
            />
            <Route
                exact
                path={LINK_CLIENT_OBJECTS_LIST}
                component={Objects}
            />
            <Route
                path={LINK_CLIENT_BRIGADE}
                component={Brigade}
            />
            <Route
                exact
                path={LINK_CLIENT_FEEDBACKS}
                component={ClientFeedbackList}
            />
            <Route
                exact
                path={LINK_CLIENT_BRANDS}
                component={ClientBrands}
            />
        </Switch>
    );
};

export default withRouter(ClientCardCompanyRoute);