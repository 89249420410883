import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getDocumentsEdoRegistriesPage,
    updateDocumentsEdoRegistriesStore,
} from "../../../../ducks/documentsEdoRegistries";

export const useEdoDocumentsRegistriesFetch = ({clientId, filterData, archived, pageNum, pageSize, list, setPagination}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, archived, pageNum, pageSize]);

    useEffect(() => {
        if (!list.length && pageNum !== 1) {
            setPagination({
                pageSize,
                pageNum: 1,
            });
        }
    }, [list]);

    useEffect(() => {
        return () => {
            dispatch(updateDocumentsEdoRegistriesStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getDocumentsEdoRegistriesPage({
            ...filterData,
            clientId,
            archived,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};