import React from "react";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import {KedoDirectories} from "../../../kedo/directories";
import {KedoDocuments} from "../../../kedo/documents";
import {KedoExports} from "../../../kedo/exports";
import {KedoLogs} from "../../../kedo/logs";
import {KedoStaffRegistry} from "../../../kedo/staff-registry";
import {KedoStaffRegistryCard} from "../../../kedo/staff-registry/components/card";

import {
    LINK_CLIENT_KEDO_DIRECTORIES,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO_PROFILE,
} from "../../../../constants/links";

const ClientCardKedoRoute = () => {
    return (
        <Switch>
            <Route
                path={LINK_CLIENT_KEDO_PROFILE}
                component={KedoStaffRegistryCard}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_KEDO_MEMBERS}
                component={KedoStaffRegistry}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_KEDO_DOCUMENTS}
                component={KedoDocuments}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_KEDO_EXPORTS}
                component={KedoExports}
            />
            <Route
                path={LINK_CLIENT_KEDO_LOGS}
                component={KedoLogs}
            />
            <Route
                path={LINK_CLIENT_KEDO_DIRECTORIES}
                component={KedoDirectories}
            />
        </Switch>
    );
};

export default ClientCardKedoRoute;