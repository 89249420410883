export const KEDO_TEMPLATES_GET_PAGE_REQUEST = "KEDO_TEMPLATES_GET_PAGE_REQUEST";
export const KEDO_TEMPLATES_GET_PAGE_SUCCESS = "KEDO_TEMPLATES_GET_PAGE_SUCCESS";
export const KEDO_TEMPLATES_GET_PAGE_ERROR = "KEDO_TEMPLATES_GET_PAGE_ERROR";

export const KEDO_TEMPLATES_DELETE_REQUEST = "KEDO_TEMPLATES_DELETE_REQUEST";
export const KEDO_TEMPLATES_DELETE_SUCCESS = "KEDO_TEMPLATES_DELETE_SUCCESS";
export const KEDO_TEMPLATES_DELETE_ERROR = "KEDO_TEMPLATES_DELETE_ERROR";

export const KEDO_TEMPLATES_ADD_REQUEST = "KEDO_TEMPLATES_ADD_REQUEST";
export const KEDO_TEMPLATES_ADD_SUCCESS = "KEDO_TEMPLATES_ADD_SUCCESS";
export const KEDO_TEMPLATES_ADD_ERROR = "KEDO_TEMPLATES_ADD_ERROR";

export const KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST = "KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_REQUEST";
export const KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_SUCCESS = "KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_SUCCESS";
export const KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR = "KEDO_TEMPLATES_SEARCH_TEMPLATE_USERS_FIELD_NAMES_ERROR";

export const KEDO_TEMPLATES_UPDATE_REQUEST = "KEDO_TEMPLATES_UPDATE_REQUEST";
export const KEDO_TEMPLATES_UPDATE_SUCCESS = "KEDO_TEMPLATES_UPDATE_SUCCESS";
export const KEDO_TEMPLATES_UPDATE_ERROR = "KEDO_TEMPLATES_UPDATE_ERROR";

export const KEDO_TEMPLATES_VALIDATE_PDF_REQUEST = "KEDO_TEMPLATES_VALIDATE_PDF_REQUEST";
export const KEDO_TEMPLATES_VALIDATE_PDF_SUCCESS = "KEDO_TEMPLATES_VALIDATE_PDF_SUCCESS";
export const KEDO_TEMPLATES_VALIDATE_PDF_ERROR = "KEDO_TEMPLATES_VALIDATE_PDF_ERROR";

export const KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST = "KEDO_TEMPLATES_VALIDATE_FIELDS_REQUEST";
export const KEDO_TEMPLATES_VALIDATE_FIELDS_SUCCESS = "KEDO_TEMPLATES_VALIDATE_FIELDS_SUCCESS";
export const KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR = "KEDO_TEMPLATES_VALIDATE_FIELDS_ERROR";

export const KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST = "KEDO_TEMPLATES_LOGS_GET_PAGE_REQUEST";
export const KEDO_TEMPLATES_LOGS_GET_PAGE_SUCCESS = "KEDO_TEMPLATES_LOGS_GET_PAGE_SUCCESS";
export const KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR = "KEDO_TEMPLATES_LOGS_GET_PAGE_ERROR";

export const KEDO_TEMPLATES_UPDATE_STORE = "KEDO_TEMPLATES_UPDATE_STORE";