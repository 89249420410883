import {useDispatch} from "react-redux";

import {useKedoDocumentsBff} from "./useBff";

import {useModal} from "../../../../hooks/useModal";

import {toastSuccess} from "../../../../utils/toastHelper";

import {downloadDocument} from "../../../../ducks/documents";

export const useKedoDocumentsAction = (params) => {
    const {
        fetchList,
        clientId,
    } = params;

    const dispatch = useDispatch();

    const {
        deleteDraft,
        exportDocuments,
    } = useKedoDocumentsBff();

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const deleteDocument = (documentId) => {
        deleteDraft({
            clientId,
            documentIds: [documentId],
            onSuccess: () => {
                fetchList();

                toastSuccess("Черновик документа успешно удален");

                onCloseConfirm();
            },
        });
    };

    const download = (document) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `${window.location.origin}${document.allFilesLink}`,
        }));
    };

    return {
        deleteDocument,
        onOpenConfirm,
        confirmData,
        isOpenConfirm,
        onCloseConfirm,
        download,
        exportDocuments,
    };
};
