import React, {MouseEventHandler, ReactNode} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

export type TTextLevel = "1" | "2" | "3" | "4" | "5";

interface IBaseText {
    bold?: boolean,
    medium?: boolean,
    children?: any,
    level?: TTextLevel,
    italic?: boolean,
    underline?: boolean,
    color?: string,
    className?: string,
    title?: string,
    noWrap?: boolean,
    inline?: boolean,
    disabled?: boolean,
    preWrap?: boolean,
    dangerouslySetInnerHTML?: any,
    style?: React.CSSProperties,
    onClick?: MouseEventHandler<any>,
    isCursorPointer?: boolean,
    isCursorPointerByOnClick?: boolean,
}

interface IText extends IBaseText {
    type?: "title" | "sub" | "text",
}

const Text = (props: IText) => {
    const {
        children,
        bold,
        medium,
        title,
        level,
        type = "text",
        inline,
        style,
        color,
        noWrap,
        className = "",
        dangerouslySetInnerHTML,
        italic,
        underline,
        onClick,
        isCursorPointer = false,
        isCursorPointerByOnClick = true,
        disabled,
        preWrap,
    } = props;

    const [block] = bem("text", className);

    const getCursorPointer = () => {
        if (onClick && isCursorPointerByOnClick) {
            return true;
        }

        return isCursorPointer;
    };

    return (
        <div
            style={{...style, color}}
            title={title}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            onClick={onClick}
            className={block({
                bold,
                medium,
                level,
                type,
                italic,
                underline,
                inline,
                noWrap,
                disabled,
                preWrap,
                cursorPointer: getCursorPointer(),
            }, props.className)}
        >
            {children}
        </div>
    );
};

const TextTitle = (props: IBaseText) => {
    return <Text
        {...props}
        type="title"
    />;
};

const TextSub = (props: IBaseText) => {
    return <Text
        {...props}
        type="sub"
    />;
};

Text.Title = TextTitle;
Text.Sub = TextSub;

export default Text;