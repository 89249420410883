import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {EMPTY_OPTION_KEY} from "../../../constants/dropdown";
import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";
import {OBJECT_STATUS_FILTER_DICT} from "../../../constants/objects";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../templates/constants";

import {getDocumentCustomTemplateShortActual} from "../../../ducks/documentsCustomTemplate";
import {
    getRichObjects,
    updateFieldsObjectsStore,
} from "../../../ducks/objects";

export const useObjectsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        projectId,
        sortType,
        sortName,
        statusFilter,
        archived,
    } = params;
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        sortType,
        sortName,
        statusFilter,
        archived,
    ]);

    useEffect(() => {
        dispatch(getDocumentCustomTemplateShortActual({
            isAggregation: true,
            aggregationFieldName: "smzFrameContractTemplateId",
            clientIdFilter: clientId,
            orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.SMZ,
            documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
            externalDocumentFilter: false,
            fieldName: "aggregationShortTemplates",
            pageNum: 1,
            pageSize: 200,
        }));

        dispatch(getDocumentCustomTemplateShortActual({
            isAggregation: true,
            aggregationFieldName: "civilFrameContractTemplateId",
            clientIdFilter: clientId,
            orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.CIVIL,
            documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
            externalDocumentFilter: false,
            fieldName: "aggregationShortTemplates",
            pageNum: 1,
            pageSize: 200,
        }));

        dispatch(getDocumentCustomTemplateShortActual({
            isAggregation: true,
            aggregationFieldName: "individualFrameContractTemplateId",
            clientIdFilter: clientId,
            orderContractPaymentTypeFilter: ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
            documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
            externalDocumentFilter: false,
            fieldName: "aggregationShortTemplates",
            pageNum: 1,
            pageSize: 200,
        }));

        return () => {
            dispatch(updateFieldsObjectsStore({objects: []}));
        };
    }, []);


    const fetchList = () => {
        dispatch(getRichObjects({
            ...filterData,
            smzFrameContractEnabled: filterData.smzFrameContractEnabled === EMPTY_OPTION_KEY ? undefined : filterData.smzFrameContractEnabled,
            civilFrameContractEnabled: filterData.civilFrameContractEnabled === EMPTY_OPTION_KEY ? undefined : filterData.civilFrameContractEnabled,
            individualFrameContractEnabled: filterData.individualFrameContractEnabled === EMPTY_OPTION_KEY ? undefined : filterData.individualFrameContractEnabled,
            orderType: sortType,
            sortName,
            pageNum,
            pageSize,
            clientId,
            projectId,
            status: (!projectId || statusFilter === OBJECT_STATUS_FILTER_DICT.ALL.VALUE) ? undefined : statusFilter,
            archivedFilter: archived,
        }));
    };

    return {
        fetchList,
    };
};