import React, {Component} from "react";
import {connect} from "react-redux";

import {
    ls,
    USER_ROLE,
} from "../../utils/localstorage";

import {HR_MANAGER, NM_CONSULTANT, NM_COORDINATOR, NM_PARTNER, RNKO} from "../../constants/roles";

import {clientCurrentMemberSelector} from "../../ducks/clientMember";
import {
    getAllTasks,
    jobTasksNotEmptySelector,
    updateJobFieldStore,
} from "../../ducks/job";

class Task extends Component {
    constructor(props) {
        super(props);

        this.interval = null;
        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;
        if (clientId && clientUserId && ![NM_CONSULTANT, NM_COORDINATOR, RNKO, HR_MANAGER, NM_PARTNER].includes(this.role)) {
            this.fetchList();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            jobTasksNotEmpty,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;
        const {
            currentMember: {
                clientId: oldClientId,
                clientUserId: oldClientUserId,
            },
        } = prevProps;

        if (clientId && clientUserId && clientId !== oldClientId && clientUserId !== oldClientUserId) {
            this.fetchList();
        }

        if (jobTasksNotEmpty && !this.interval) {
            this.interval = setInterval(this.fetchList, 3000);
        } else if (!jobTasksNotEmpty) {
            this.interval && clearInterval(this.interval);
            this.interval = null;
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        const {updateJobFieldStore} = this.props;

        updateJobFieldStore({tasks: []});
    }

    fetchList = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
            fetchList,
        } = this.props;

        if (fetchList) {
            fetchList();

            return;
        }

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    render() {
        return (
            <>
            </>
        );
    }
}

export default connect(
    state => ({
        currentMember: clientCurrentMemberSelector(state),
        jobTasksNotEmpty: jobTasksNotEmptySelector(state),
    }),
    {
        getAllTasks,
        updateJobFieldStore,
    },
)(Task);