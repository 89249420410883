import React from "react";

import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import {STATUS_PASSPORT_INN} from "../../../../../components/NmStatusPassportInnContractor";
import ContractorVerificationInputContainer from "../input-container";
import ContractorVerificationRowContainer from "../row-container";

import {GENDER_OPTIONS} from "../../../../../constants/contractorInfo";

const ContractorVerificationDataGeneralFields = (props) => {
    const {
        localizationData,
        getChanged,
        values,
        handleChange,
        handleBlur,
        handleFocus,
        getPreviousValue,
        errors,
        setInnStatus,
        setFieldValue,
        isBirthPlace,
    } = props;

    return (
        <>
            <ContractorVerificationRowContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("lastName")}
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        type="text"
                        label={localizationData.lastName}
                        tooltip={getPreviousValue("lastName")}
                        required
                        error={errors && errors.lastName}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("firstName")}
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        type="text"
                        label={localizationData.firstName}
                        tooltip={getPreviousValue("firstName")}
                        required
                        error={errors && errors.firstName}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("patronymic")}
                        name="patronymic"
                        value={values.patronymic}
                        onChange={handleChange}
                        type="text"
                        label={localizationData.patronymic}
                        tooltip={getPreviousValue("patronymic")}
                        error={errors && errors.patronymic}
                    />
                </ContractorVerificationInputContainer>
            </ContractorVerificationRowContainer>
            <ContractorVerificationRowContainer>
                <ContractorVerificationInputContainer>
                    <NmDropdownV2
                        size="xl"
                        changed={getChanged("gender")}
                        name="gender"
                        label={localizationData.gender}
                        required
                        value={values.gender}
                        options={GENDER_OPTIONS}
                        onFocus={handleFocus}
                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                        error={errors && errors.gender}
                        tooltip={getPreviousValue("gender", GENDER_OPTIONS)}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmDatePicker
                        size="xl"
                        dateFormatMask="99.99.9999"
                        changed={getChanged("birthDate")}
                        name="birthDate"
                        label={localizationData.birthDate}
                        maxDate={new Date()}
                        required
                        selected={values.birthDate}
                        onChange={(e, {name, value}) => {
                            setInnStatus(STATUS_PASSPORT_INN.NOT_CHECKED.VALUE);
                            setFieldValue(name, value);
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        error={errors && errors.birthDate}
                        tooltip={getPreviousValue("birthDate")}
                    />
                </ContractorVerificationInputContainer>
                {
                    isBirthPlace &&
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("birthPlace")}
                            name="birthPlace"
                            label={localizationData.placeBirth}
                            value={values.birthPlace}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            labelTooltip={getPreviousValue("birthPlace")}
                            error={errors && errors.birthPlace}
                            tooltip={getPreviousValue("birthPlace")}
                            required
                        />
                    </ContractorVerificationInputContainer>
                }
            </ContractorVerificationRowContainer>
        </>
    );
};

export default ContractorVerificationDataGeneralFields;