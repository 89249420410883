import React from "react";
import {nanoid} from "nanoid";

import NmLabel from "../ActualComponents/NmLabel";

import PropTypes from "prop-types";

import "./style.sass";

const forceNumber = function (n) {
    n = Number(n);
    if (isNaN(n) || typeof n === "undefined") {
        n = 0;
    }
    return n;
};

export default class RangeStepInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseDown: false,
            isDragging: false,
            value: props.value || 0,
        };
        this.onInput = this.onInput.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.id = `${props.name}_${nanoid(3)}`;
        this.domRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            const e = document.querySelector(`input[id=${this.id}]`);
            e.style.setProperty("--value", this.state.value);
            e.addEventListener("input", () => e.style.setProperty("--value", this.state.value));
        }
    }

    handleChangeValue = (e, {value}) => {
        this.setState(prevState => ({
            ...prevState,
            value,
        }));

        !this.props.isUpdateValueAfterMouseUp && this.props.onDistanceChange(null, {name: this.props.name, value});
    };

    render() {
        return (
            <div className="range-step-input">
                {
                    this.props.label &&
                    <NmLabel
                        required={this.props.required}
                        label={this.props.label}
                    />
                }
                <input
                    type="range"
                    ref={this.domRef}
                    className={this.props.className}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    value={this.state.value}
                    name={this.props.name}
                    id={this.id}
                    style={{
                        "--value": this.state.value,
                        "--min": this.props.min,
                        "--max": this.props.max,
                    }}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    onMouseDown={this.onMouseDown}
                    onMouseUp={this.onMouseUp}
                    onMouseMove={this.onMouseMove}
                    onClick={this.onClick}
                    onInput={this.onInput}
                />
                <div className="flex flex-content-spaced">
                    <div>
                        {`${this.props.min} км`}
                    </div>
                    <div>
                        {`${this.state.value} км`}
                    </div>
                    <div>
                        {`${this.props.max} км`}
                    </div>
                </div>
            </div>);
    }

    onMouseDown() {
        this.setState({isMouseDown: true});

        if (this.props.hold) {
            if (this.holdLoop) {
                clearInterval(this.holdLoop);
            }

            const oldVal = this.state.value;

            const self = this;
            setTimeout(function () {
                if (self.holdLoop) {
                    clearInterval(self.holdLoop);
                }
                self.holdLoop = self.makeHoldLoop(oldVal);
                // Add some initial delay on the click-hold functionality.
            }, 250);
        }
    }

    onMouseUp() {
        this.setState({
            isMouseDown: false,
            isDragging: false,
        });

        if (this.holdLoop) {
            clearInterval(this.holdLoop);
        }

        if (this.props.isUpdateValueAfterMouseUp) {
            this.props.onDistanceChange(null, {name: this.props.name, value: this.state.value});
        }
    }

    onMouseMove() {
        if (this.state.isMouseDown) {
            this.setState({isDragging: true});
        }
    }

    onInput(e) {
        const step = this.props.step;
        const newVal = forceNumber(e.target.value);
        const oldVal = this.state.value;

        if (
            // Disable the oninput filter with the user is dragging
        // the slider's knob.
            !(this.state.isMouseDown && this.state.isDragging) &&
            oldVal
        ) {
            e.target.value = (newVal > oldVal) ? oldVal + step : oldVal - step;
            this.handleChangeValue(null, {value: newVal});
        }
        this.handleChangeValue(null, {value: newVal});
    }

    makeHoldLoop(oldVal) {
        const self = this;

        return setInterval(function () {
            if (!self.state.isMouseDown || self.state.isDragging) {
                // The user isn't holding the cursor anymore, or the cursor
                // is being dragged. Clean up and cancel.
                if (self.holdLoop) {
                    clearInterval(self.holdLoop);
                }
                return false;
            }

            const input = self.domRef.current;
            let newVal = self.props.value;

            if (
                oldVal > newVal &&
                (newVal - self.props.step) >= self.props.min
            ) {
                newVal -= self.props.step;
            } else if (
                oldVal < newVal &&
                (newVal + self.props.step) <= self.props.max
            ) {
                newVal += self.props.step;
            }

            if (oldVal === newVal) {
                return false;
            }

            // Directly setting input.value will cause the new value
            // to not be recognized, because of React.
            // https://stackoverflow.com/a/46012210/173630
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype, "value").set;
            nativeInputValueSetter.call(input, newVal);

            // Trigger an onChange event.
            const e = new Event("change", {bubbles: true, newVal});

            return input.dispatchEvent(e);
        }, 100);
    }
};

RangeStepInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    step: PropTypes.number.isRequired,
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.string,

    // Determines whether the slider changes value when the cursor is
    // held on it.
    hold: PropTypes.bool,
};

RangeStepInput.defaultProps = {
    hold: true,
    onChange: () => {},
    name: "",
    isUpdateValueAfterMouseUp: false,
};