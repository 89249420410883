import React from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {isNullOrWhitespace} from "../../utils/stringHelper";

import {getClientUsersFio} from "../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsClientUsersFioOptionsSelector,
} from "../../ducks/bff/common/dicts/selectors";

const ClientUsersFioDropdown = (props) => {
    const {
        roleListFilter,
        ...otherProps
    } = props;
    const dispatch = useDispatch();

    const clientUsersFioOptions = useSelector(bffCommonDictsClientUsersFioOptionsSelector);

    const fetchList = (clientName) => {
        dispatch(getClientUsersFio({
            pageNum: 1,
            pageSize: 25,
            roleListFilter,
            fioFilter: isNullOrWhitespace(clientName) ? undefined : clientName.trim(),
        }));
    };

    const onSearchChange = (valueFilter) => {
        fetchList(valueFilter);
    };

    return (
        <NmDropdownV2
            label="ФИО ответственного"
            placeholder="Не выбрано"
            options={clientUsersFioOptions}
            onSearchChange={onSearchChange}
            {...otherProps}
        />
    );
};

export default ClientUsersFioDropdown;