import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../components/ActualComponents/NmForm";
import NmModal from "../../components/ActualComponents/NmModal";
import NmModalCardList from "../../components/ActualComponents/NmModal/CardList";
import NmPagination from "../../components/ActualComponents/NmPagination";
import NmShowMoreText from "../../components/ActualComponents/NmShowMoreText";
import NmTitle from "../../components/NmTitle";
import {
    Dimmer,
    Loader,
} from "semantic-ui-react";

import dateFormat, {convertUtcToLocal} from "../../utils/dateFormat";
import {
    formatAmount, formatAmountWithComma,
    formatNumber,
} from "../../utils/stringFormat";

import {LABEL_ORDER_HISTORY_BY_ROLE, ORDER_KIND} from "../../constants/order";
import {
    STATUS_PAYMENT,
    STATUS_PAYMENT_TRANSCRIPTION,
} from "../../constants/status";

import {
    clientMemberListSelector,
    getClientMemberByIds,
    updateFieldClientMemberStore,
} from "../../ducks/clientMember";
import {
    contractorListHistoryPaySelector,
    getContractorByIds,
    updateFieldContractorStore,
} from "../../ducks/contractor";
import {unitsAbbreviationDictSelector} from "../../ducks/order";
import {
    fetchWorkLogLastChangePayment,
    getOrderWorkProgressSelector,
    getOrderWorkReportLastChangePaymentsSelector,
    getOrderWorkReportList,
    getOrderWorkReportListSelector,
    getOrderWorkReportLogs,
    getOrderWorkReportLogsSelector,
    getOrderWorkReportTotalPagesSelector,
    orderWorkReportListTotalCount,
    updateFieldOrderWorkReportStore,
} from "../../ducks/orderWorkReport";

import {reportType} from "../../types";
import PropTypes from "prop-types";

import "./style.sass";

class PayoutHistory extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        handleClose: PropTypes.func,
        orderId: PropTypes.string,
        contractorId: PropTypes.string,
        list: PropTypes.arrayOf(reportType),
        pagination: PropTypes.object,
        getOrderWorkReportList: PropTypes.func,
        updateFieldOrderWorkReportStore: PropTypes.func,
    };

    static defaultProps = {
        clientId: "",
        handleClose: () => {
        },
        orderId: "",
        contractorId: "",
        list: [],
        pagination: {totalPages: 0, totalCount: 0},
        getOrderWorkReportList: () => {},
        updateFieldOrderWorkReportStore: () => {},
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        this.state = {
            fromDateFilter: undefined,
            toDateFilter: undefined,
            pageNum: 1,
            pageSize: this.pageSizes[0],
            clientUsers: [],
            contractorsList: [],
            lastActionsInfo: [],
            isHiddenModal: false,
        };
    }

    componentDidMount() {
        this.fetchPayoutHistoryList();
    }

    componentWillUnmount() {
        const {updateFieldOrderWorkReportStore, updateFieldClientMemberStore, updateFieldContractorStore} = this.props;

        updateFieldOrderWorkReportStore({list: [], listLastChangePayments: []});
        updateFieldClientMemberStore({list: []});
        updateFieldContractorStore({contractorList: []});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list: oldList, listLastChangePayments: oldListLastChangePayments, clientUsers: oldClientUsers, contractorsList: oldContractorsList} = prevProps;
        const {list, listLastChangePayments, clientUsers, contractorsList} = this.props;
        const {clientUsers: clientUsersState, contractorsList: contractorsListState} = this.state;

        if (list.length && JSON.stringify(oldList) !== JSON.stringify(list)) {
            this.fetchLastChangesPayments();
        }

        if (listLastChangePayments.length && JSON.stringify(oldListLastChangePayments) !== JSON.stringify(listLastChangePayments)) {
            this.fetchClientUsers();
            this.fetchContractors();

            if (clientUsersState.length !== 0 || contractorsListState.length !== 0) {
                this.transformReports();
            }
        }

        if (clientUsers.length && JSON.stringify(oldClientUsers) !== JSON.stringify(clientUsers)) {
            this.setState({
                clientUsers,
            });

            this.transformReports();
        }

        if (contractorsList.length && JSON.stringify(oldContractorsList) !== JSON.stringify(contractorsList)) {
            this.setState({
                contractorsList,
            });

            this.transformReports();
        }
    }

    transformReports = () => {
        const {listLastChangePayments, clientUsers, contractorsList} = this.props;

        const users = clientUsers.concat(contractorsList);

        const lastActionsInfo = listLastChangePayments.map(value => {
            const findId = value.performerId ? value.performerId : value.contractorId;
            const user = users.find(value => (value.clientUserId === findId)) || {};

            return {
                ...value,
                role: user.role,
                fullName: findId === "00000000-0000-0000-0000-000000000000" ? "Администратор Наймикса" : `${user.lastName || ""} ${user.firstName || ""} ${user.patronymic || ""}`,
            };
        });

        this.setState({
            lastActionsInfo,
        });
    };

    fetchContractors = () => {
        const {getContractorByIds, listLastChangePayments} = this.props;

        const ids =  Array.from(new Set(listLastChangePayments.map(value => value.contractorId)));
        const reqData = ids.filter(value => value !== null);

        getContractorByIds(reqData);
    };

    fetchClientUsers = () => {
        const {getClientMemberByIds, listLastChangePayments} = this.props;

        const ids =  Array.from(new Set(listLastChangePayments.map(value => value.performerId)));
        const reqData = ids.filter(value => value !== null);

        getClientMemberByIds(reqData);
    };

    fetchLastChangesPayments = () => {
        const {list, fetchWorkLogLastChangePayment} = this.props;

        fetchWorkLogLastChangePayment({paymentsNumber: list.map(value => value.paymentNumber)});
    };

    fetchPayoutHistoryList = () => {
        const {
            clientId,
            orderId,
            contractorId,
            getOrderWorkReportList,
        } = this.props;
        const {
            fromDateFilter,
            toDateFilter,
            pageNum,
            pageSize,
        } = this.state;

        getOrderWorkReportList({
            clientId,
            pageNum,
            pageSize,
            orderId,
            contractorIdFilter: contractorId,
            fromDateFilter: fromDateFilter ? dateFormat(fromDateFilter, "yyyy-MM-dd") : undefined,
            toDateFilter: toDateFilter ? dateFormat(toDateFilter, "yyyy-MM-dd") : undefined,
        });
    };

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value});
    };

    clear = () => {
        this.setState({
            fromDateFilter: undefined,
            toDateFilter: undefined,
            pageNum: 1,
        }, this.fetchPayoutHistoryList);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchPayoutHistoryList,
        );
    };

    handleChangePageSize = pageSize => {
        const {
            pagination: {
                totalCount,
            },
        } = this.props;

        this.setState(prevState => {
            return {
                ...prevState,
                pageSize,
                pageNum: totalCount <= pageSize ? 1 : prevState.pageNum,
            };
        },
        this.fetchPayoutHistoryList,
        );
    };

    search = () => {
        this.setState({pageNum: 1}, this.fetchPayoutHistoryList);
    };

    getRefusedDescription = ({paymentStatus, reasonForRefusal, role, fullName}) => {
        if (paymentStatus === STATUS_PAYMENT.REJECTED) {
            return [
                {
                    label: role ? `${LABEL_ORDER_HISTORY_BY_ROLE[role]} (${fullName || ""})` : `Причина отклонения (${fullName || ""})`,
                    value: reasonForRefusal,
                },
            ];
        }

        return [];
    };

    getCards() {
        const {lastActionsInfo} = this.state;
        const {list, workUnitsDict, orderKind} = this.props;

        return list.map((item) => {
            const roleInfo = lastActionsInfo.find(value => (value.paymentNumber === item.paymentNumber)) || {};

            const workUnitPrice = `${formatAmountWithComma(item.workUnitPrice)} Р/${workUnitsDict.get(item.workUnit) || ""}`;

            const isVolumeBased = orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER;

            const volumeValues = isVolumeBased ? [
                {label: "Стоимость за единицу", value: workUnitPrice},
                {label: "Оплачиваемый объем работ", value: item.volumeOfWork},
            ] : [];

            const values = [
                {label: "Дата", value: dateFormat(convertUtcToLocal(item.date), "dd.MM.yy HH:mm:ss")},
                {label: "Статус", value: STATUS_PAYMENT_TRANSCRIPTION[item.paymentStatus]},
                ...volumeValues,
            ];

            const singleValues = [
                {
                    label: "Описание",
                    value: item.description,
                },
                {
                    label: "Комментарий",
                    value: item.comment ?
                        <NmShowMoreText
                            anchor="green"
                            lines={1}
                            children={item.comment}
                            more="Читать полностью"
                            setIsHiddenModal={(hide) => {
                                this.setState({isHiddenModal: hide});
                            }}
                        /> :
                        "-",
                    classNameValue: "flex-grow-1",
                },
                ...this.getRefusedDescription({
                    paymentStatus: item.paymentStatus,
                    reasonForRefusal: item.reasonForRefusal,
                    role: roleInfo.role,
                    fullName: roleInfo.fullName,
                }),
            ];

            return {
                title: `№${item.paymentNumber}`,
                additionalTitleValue: <>
                    {formatNumber(formatAmount(item.sumOfMoney.toFixed(2)))}
                    {" "}
&#x20bd;
                </>,
                values,
                singleValues,
            };
        });
    }

    renderFilter() {
        const {t} = this.props;
        const {
            fromDateFilter,
            toDateFilter,
        } = this.state;

        return (
            <NmForm horizontal>
                <div className="payout-history__period">
                    <NmDateRangePickerV2
                        size="lg"
                        label={t("orders-filter.order-work-period")}
                        onChange={this.handleChange}
                        startFieldName="fromDateFilter"
                        endFieldName="toDateFilter"
                        value={{
                            fromDateFilter,
                            toDateFilter,
                        }}
                        isCurrentDateMax
                        returnString={false}
                    />
                </div>
                <FilterButtonsV2
                    onSearch={this.search}
                    onClear={this.clear}
                />
            </NmForm>
        );
    }

    renderPagination() {
        const {pagination} = this.props;
        const {totalPages, totalCount} = pagination;

        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <NmPagination
                className="payout-history__pagination"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={this.handleChangePageSize}
                onChangePagination={this.handlePaginationChange}
            />
        );
    }

    renderContent() {
        const {listTotalCount} = this.props;

        return listTotalCount > 0 ?
            <NmModalCardList list={this.getCards()} /> :
            <div className="payout-history__message">
                Не найдено записей
            </div>;
    }

    render() {
        const {
            handleClose,
            progressList,
        } = this.props;

        const {
            isHiddenModal,
        } = this.state;

        return (
            <NmModal
                header={
                    <NmTitle size="lg">
                        История выплат
                    </NmTitle>
                }
                className="payout-history"
                zIndex={1050}
                onClose={handleClose}
                isHiddenModal={isHiddenModal}
            >
                <div className="payout-history__content">
                    {progressList ?
                        <Dimmer
                            active
                            inverted
                        >
                            <Loader>
                            </Loader>
                        </Dimmer> :
                        null
                    }
                    {this.renderFilter()}
                    {this.renderContent()}
                    {this.renderPagination()}
                </div>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        list: getOrderWorkReportListSelector(state),
        listLastChangePayments: getOrderWorkReportLastChangePaymentsSelector(state),
        pagination: getOrderWorkReportTotalPagesSelector(state),
        clientUsers: clientMemberListSelector(state),
        progressList: getOrderWorkProgressSelector(state),
        listTotalCount: orderWorkReportListTotalCount(state),
        orderWorkReportLogs: getOrderWorkReportLogsSelector(state),
        contractorsList: contractorListHistoryPaySelector(state),
        workUnitsDict: unitsAbbreviationDictSelector(state),
    }),
    {
        getOrderWorkReportList,
        getClientMemberByIds,
        getOrderWorkReportLogs,
        getContractorByIds,
        fetchWorkLogLastChangePayment,
        updateFieldClientMemberStore,
        updateFieldContractorStore,
        updateFieldOrderWorkReportStore,
    })(withTranslation()(PayoutHistory));
