import pointInPolygon from "point-in-polygon";

import {MARKER_TYPE} from "../constants";

export const getMapDrawSelectedMarkers = (mapInfo, polygonCoords) => {
    const selectedOrdersMarkers = mapInfo.orders ? getSelectedMarkers(mapInfo.orders, polygonCoords) : null;
    const selectedContractorsMarkers = mapInfo.contractors ? getSelectedMarkers(mapInfo.contractors, polygonCoords) : null;
    const selectedAdvertsMarkers = mapInfo.adverts ? getSelectedMarkers(mapInfo.adverts, polygonCoords) : null;
    const selectedCrowdTasksMarkers = mapInfo.crowdTasks ? getSelectedMarkers(mapInfo.crowdTasks, polygonCoords) : null;

    const mapSelectedOrders = mapInfo.orders ? getSelectedMarkersMap(selectedOrdersMarkers) : null;
    const mapSelectedContractors = mapInfo.contractors ? getSelectedMarkersMap(selectedContractorsMarkers) : null;
    const mapSelectedAdverts = mapInfo.adverts ? getSelectedMarkersMap(selectedAdvertsMarkers) : null;
    const mapSelectedCrowdTasks = mapInfo.adverts ? getSelectedMarkersMap(selectedCrowdTasksMarkers) : null;

    const ordersItemMarkerList = mapInfo.orders ? formatToMapItemMarker({
        markers: selectedOrdersMarkers,
        type: MARKER_TYPE.ORDER,
    }) : [];
    const contractorsItemMarkerList = mapInfo.contractors ? formatToMapItemMarker({
        markers: selectedContractorsMarkers,
        type: MARKER_TYPE.CONTRACTOR,
    }) : [];
    const advertsItemMarkerList = mapInfo.adverts ? formatToMapItemMarker({
        markers: selectedAdvertsMarkers,
        type: MARKER_TYPE.ADVERT,
    }) : [];
    const crowdTasksItemMarkerList = mapInfo.crowdTasks ? formatToMapItemMarker({
        markers: selectedCrowdTasksMarkers,
        type: MARKER_TYPE.CROWD_TASK,
    }) : [];

    return {
        itemMarkerList: [
            ...ordersItemMarkerList,
            ...contractorsItemMarkerList,
            ...advertsItemMarkerList,
            ...crowdTasksItemMarkerList,
        ],
        orders: {
            mapSelected: mapSelectedOrders,
        },
        contractors: {
            mapSelected: mapSelectedContractors,
        },
        adverts: {
            mapSelected: mapSelectedAdverts,
        },
        crowdTasks: {
            mapSelected: mapSelectedCrowdTasks,
        },
    };
};

const formatToMapItemMarker = (params) => {
    const {
        markers,
        type,
    } = params;

    return markers.map((item) => {
        return {
            ...item,
            type,
        };
    });
};

const getSelectedMarkers = (markers, polygonCoords) => {
    return markers.filter(value => {
        const {
            clusterAvgGeoLat,
            clusterAvgGeoLon,
        } = value;

        // с карты приходит массив в виде [lon, lat]
        const reversedPolygonCoords = polygonCoords.map(item => {
            const lon = item[0];
            const lat = item[1];

            return [lat, lon];
        });

        return pointInPolygon([clusterAvgGeoLat, clusterAvgGeoLon], reversedPolygonCoords);
    });
};

const getSelectedMarkersMap = (markers) => {
    return markers.reduce((result, value) => {
        const {
            clusterAvgGeoLat,
            clusterAvgGeoLon,
        } = value;

        return {
            [`${clusterAvgGeoLat}${clusterAvgGeoLon}`]: true,
            ...result,
        };
    }, {});
};