import {PROJECT_STATUS_DICT} from "./projects";
import {ADMIN, CLIENT_ACCOUNTANT, CLIENT_ADMIN, NM_MANAGER, PROJECT_MANAGER} from "./roles";

export const MAP_MARKER_COLOR = {
    DEFAULT: "rgb(2, 117, 216)",
    SELECTED_OVM: "rgb(12, 87, 152)",
    OBJECT: "red",
};

export const OBJECT_ACTION_DICT = {
    ADD_DEPOSIT: {
        VALUE: "add-deposit",
        TEXT: "Пополнить депозит объекта",
    },
    RETURN_DEPOSIT: {
        VALUE: "return-deposit",
        TEXT: "Вернуть депозит объекта",
    },
    EDIT: {
        VALUE: "edit",
        TEXT: "Редактировать объект",
    },
    OPEN: {
        VALUE: "open",
        TEXT: "Открыть объект",
        CONFIRM_KEY: "objects.open-confirm-text",
    },
    CLOSE: {
        VALUE: "close",
        TEXT: "Закрыть объект",
        CONFIRM_KEY: "objects.close-confirm-text",
    },
    LOG_DEPOSIT_HISTORY: {
        VALUE: "LOG_DEPOSIT_HISTORY",
        TEXT: "История изменений депозита объекта",
    },
};

export const OBJECT_STATUS_DICT = {
    IN_WORK: {
        VALUE: "IN_WORK",
        TEXT: "В работе",
        COLOR: "#128A10",
        BADGE_MOD: "light-green",
    },
    CLOSE: {
        VALUE: "CLOSE",
        TEXT: "Закрыт",
        COLOR: "#7B7B7B",
        BADGE_MOD: "gray",
    },
};

export const OBJECT_ACTION_OPTIONS = (projectStatus, objectStatus, isClientArchived) => {
    const editOption = {
        key: OBJECT_ACTION_DICT.EDIT.VALUE,
        value: OBJECT_ACTION_DICT.EDIT.VALUE,
        text: OBJECT_ACTION_DICT.EDIT.TEXT,
    };

    const logDepositHistoryOption = {
        key: OBJECT_ACTION_DICT.LOG_DEPOSIT_HISTORY.VALUE,
        value: OBJECT_ACTION_DICT.LOG_DEPOSIT_HISTORY.VALUE,
        text: OBJECT_ACTION_DICT.LOG_DEPOSIT_HISTORY.TEXT,
    };

    if (isClientArchived && OBJECT_STATUS_DICT.IN_WORK.VALUE === objectStatus) {
        return [
            {
                key: OBJECT_ACTION_DICT.CLOSE.VALUE,
                value: OBJECT_ACTION_DICT.CLOSE.VALUE,
                text: OBJECT_ACTION_DICT.CLOSE.TEXT,
            },
            logDepositHistoryOption,
        ];
    }

    if (projectStatus === PROJECT_STATUS_DICT.CLOSE.VALUE && objectStatus === OBJECT_STATUS_DICT.CLOSE.VALUE && !isClientArchived) {
        return [
            editOption,
            logDepositHistoryOption,
        ];
    }

    if (objectStatus === OBJECT_STATUS_DICT.CLOSE.VALUE && !isClientArchived) {
        return [
            {
                key: OBJECT_ACTION_DICT.OPEN.VALUE,
                value: OBJECT_ACTION_DICT.OPEN.VALUE,
                text: OBJECT_ACTION_DICT.OPEN.TEXT,
            },
            logDepositHistoryOption,
        ];
    }


    if (!isClientArchived) {
        return [
            editOption,
            {
                key: OBJECT_ACTION_DICT.CLOSE.VALUE,
                value: OBJECT_ACTION_DICT.CLOSE.VALUE,
                text: OBJECT_ACTION_DICT.CLOSE.TEXT,
            },
            logDepositHistoryOption,
        ];
    }

    return null;
};

export const OBJECT_STATUS_FILTER_DICT = {
    IN_WORK: {
        VALUE: "IN_WORK",
        TEXT: "Открытые",
    },
    CLOSE: {
        VALUE: "CLOSE",
        TEXT: "Закрытые",
    },
    ALL: {
        VALUE: "ALL",
        TEXT: "Все",
    },
};

export const OBJECT_LOG_DEPOSIT_ACTION = {
    ADD_OBJECT_DEPOSIT: "Пополнение депозита объекта",
    RETURN_OBJECT_DEPOSIT: "Возврат депозита объекта",
};