import {all, put, select, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {ACTION_TYPE, BRIGADE_PAYMENTS_TOAST_MESSAGE} from "../containers/brigade/brigade-payments/brigadePayments";
import {getNewRequestsAndPaymentsCount} from "./foremanRequests";

import {ls, USER_ROLE} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {getFullName} from "../utils/stringFormat";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT_ADMIN, FOREMAN, OBJECT_MANAGER, PROJECT_MANAGER} from "../constants/roles";

const controller = "/foreman/payments";

const GET_FOREMAN_PAYMENTS_SUCCESS = "GET_FOREMAN_PAYMENTS_SUCCESS";
const GET_FOREMAN_PAYMENTS_ERROR = "GET_FOREMAN_PAYMENTS_ERROR";

const GET_RICH_FOREMAN_PAYMENTS_REQUEST = "GET_RICH_FOREMAN_PAYMENTS_REQUEST";

const GET_FOREMAN_CONTRACTORS_RICH_LIST_REQUEST = "GET_FOREMAN_CONTRACTORS_RICH_LIST_REQUEST";
const GET_FOREMAN_CONTRACTORS_RICH_LIST_SUCCESS = "GET_FOREMAN_CONTRACTORS_RICH_LIST_SUCCESS";
const GET_FOREMAN_CONTRACTORS_RICH_LIST_ERROR = "GET_FOREMAN_CONTRACTORS_RICH_LIST_ERROR";

const CONFIRM_FOREMAN_PAYMENT_SUCCESS = "CONFIRM_FOREMAN_PAYMENT_SUCCESS";
const CONFIRM_FOREMAN_PAYMENT_ERROR = "CONFIRM_FOREMAN_PAYMENT_ERROR";

const CONFIRM_FOREMAN_PAYMENTS_LIST_SUCCESS = "CONFIRM_FOREMAN_PAYMENTS_LIST_SUCCESS";
const CONFIRM_FOREMAN_PAYMENTS_LIST_ERROR = "CONFIRM_FOREMAN_PAYMENTS_LIST_ERROR";

const REJECT_FOREMAN_PAYMENT_SUCCESS = "REJECT_FOREMAN_PAYMENT_SUCCESS";
const REJECT_FOREMAN_PAYMENT_ERROR = "REJECT_FOREMAN_PAYMENT_ERROR";

const FOREMAN_PAYMENT_ACTION_REQUEST = "FOREMAN_PAYMENT_ACTION_REQUEST";
const FOREMAN_PAYMENT_ACTION_ERROR = "FOREMAN_PAYMENT_ACTION_ERROR";

const ACTION = {
    [ACTION_TYPE.CONFIRM]: {
        success: CONFIRM_FOREMAN_PAYMENT_SUCCESS,
        error: CONFIRM_FOREMAN_PAYMENT_ERROR,
        query: "confirm",
        toastMessage: BRIGADE_PAYMENTS_TOAST_MESSAGE.SUCCESS_CONFIRM,
    },
    [ACTION_TYPE.REJECT]: {
        success: REJECT_FOREMAN_PAYMENT_SUCCESS,
        error: REJECT_FOREMAN_PAYMENT_ERROR,
        query: "reject",
        toastMessage: BRIGADE_PAYMENTS_TOAST_MESSAGE.SUCCESS_REJECT,
    },
    [ACTION_TYPE.CONFIRM_LIST]: {
        success: CONFIRM_FOREMAN_PAYMENTS_LIST_SUCCESS,
        error: CONFIRM_FOREMAN_PAYMENTS_LIST_ERROR,
        query: "confirmList",
        toastMessage: BRIGADE_PAYMENTS_TOAST_MESSAGE.SUCCESS_CONFIRM_LIST,
    },
    [ACTION_TYPE.CONFIRM_WITH_DUPLICATE]: {
        success: CONFIRM_FOREMAN_PAYMENT_SUCCESS,
        error: CONFIRM_FOREMAN_PAYMENT_ERROR,
        query: "confirm",
        toastMessage: BRIGADE_PAYMENTS_TOAST_MESSAGE.SUCCESS_CONFIRM,
    },
};

const initialState = {
    pageData: {},
    list: [],
    progressList: false,
    totalAmount: 0,
    error: {},
    foremanContractorsRichList: [],
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case FOREMAN_PAYMENT_ACTION_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case CONFIRM_FOREMAN_PAYMENT_SUCCESS:
    case CONFIRM_FOREMAN_PAYMENTS_LIST_SUCCESS:
    case REJECT_FOREMAN_PAYMENT_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case GET_FOREMAN_CONTRACTORS_RICH_LIST_SUCCESS:
        return {
            ...state,
            foremanContractorsRichList: payload,
        };
    case GET_RICH_FOREMAN_PAYMENTS_REQUEST: {
        return {
            ...state,
            progressList: true,
            pageData: payload,
        };
    }
    case GET_FOREMAN_PAYMENTS_SUCCESS: {
        const {totalCount, totalAmount, payments} = payload;

        return {
            ...state,
            progressList: false,
            totalCount,
            totalAmount,
            list: payments,
        };
    }
    case FOREMAN_PAYMENT_ACTION_ERROR: {
        return {
            ...state,
            confirmTextDuplicateForemanPayment: payload,
        };
    }
    case CONFIRM_FOREMAN_PAYMENTS_LIST_ERROR:
    case REJECT_FOREMAN_PAYMENT_ERROR:
    case CONFIRM_FOREMAN_PAYMENT_ERROR:
        return {
            ...state,
            progressAction: false,
            progressList: false,
        };
    case GET_FOREMAN_PAYMENTS_ERROR: {
        return {
            ...state,
            progressList: false,
            error: payload,
        };
    }
    default:
        return state;
    }
};

//* ACTION CREATORS *//

export function makeAction(data, typeAction, onSuccess) {
    return {
        type: FOREMAN_PAYMENT_ACTION_REQUEST,
        payload: {
            data,
            typeAction,
            onSuccess,
        },
    };
}

export function getRichForemanPayments(payload) {
    return {
        type: GET_RICH_FOREMAN_PAYMENTS_REQUEST,
        payload,
    };
}

export function getForemanContractorsRichList(payload) {
    return {
        type: GET_FOREMAN_CONTRACTORS_RICH_LIST_REQUEST,
        payload,
    };
}

//* SELECTORS *//

export const foremanPaymentsSelector = state => state.foremanPayments;
export const foremanPaymentsListSelector = createSelector(foremanPaymentsSelector, ({list}) => list);
export const foremanPaymentsProgressListSelector = createSelector(foremanPaymentsSelector, ({progressList}) => progressList);
export const foremanPaymentsProgressAction = createSelector(foremanPaymentsSelector, ({progressAction}) => progressAction);
export const foremanPaymentsTotalPagesSelector = createSelector(foremanPaymentsSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));
export const foremanPaymentsTotalCountSelector = createSelector(foremanPaymentsSelector, ({totalCount}) => totalCount);
export const foremanPaymentsTotalAmountSelector = createSelector(foremanPaymentsSelector, ({totalAmount}) => totalAmount);
export const confirmTextDuplicateForemanPaymentSelector = createSelector(foremanPaymentsSelector, ({confirmTextDuplicateForemanPayment}) => confirmTextDuplicateForemanPayment);
export const foremanContractorsOptionsSelector = createSelector(foremanPaymentsSelector, ({foremanContractorsRichList}) => {
    return foremanContractorsRichList.map(foreman => {
        const {
            contractorId,
            foremanContractorFirstName,
            foremanContractorLastName,
            foremanContractorPatronymic,
        } = foreman;

        return {
            key: contractorId,
            value: contractorId,
            text: getFullName(foremanContractorLastName, foremanContractorFirstName, foremanContractorPatronymic),
        };
    });
});

//* SAGA *//

function* getRichForemanPaymentsSaga({payload}) {
    try {
        const result = yield request.post(`${controller}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_PAYMENTS_ERROR,
            payload: error,
        });
    }
}

function* getForemanContractorsRichListSaga({payload: {clientId}}) {
    try {
        const result = yield request.post(`${controller}/getForemanContractorsRichList?clientId=${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_CONTRACTORS_RICH_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_CONTRACTORS_RICH_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_CONTRACTORS_RICH_LIST_ERROR,
            payload: error,
        });
    }
}

function* makeActionSaga({payload: {data, typeAction, onSuccess}}) {
    try {
        const result = yield request.post(`${controller}/${ACTION[typeAction].query}`, data);

        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            if (errorCode === "FOUND_UNCOMPLETED_CONTRACTOR_PAYMENTS") {
                yield put({
                    type: FOREMAN_PAYMENT_ACTION_ERROR,
                    payload: errorMessage,
                });
                
                return {
                    done: true,
                };
            }
            
            yield put({
                type: ACTION[typeAction].error,
                payload: errorMessage,
            });

            toastError(errorMessage);

            return {
                done: true,
            };
        }

        const state = yield select();

        yield put(getRichForemanPayments(state.foremanPayments.pageData));

        toastSuccess(ACTION[typeAction].toastMessage);

        yield put({
            type: ACTION[typeAction].success,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        const userRole = ls(USER_ROLE);

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(userRole)) {
            yield put(getNewRequestsAndPaymentsCount());
        }
    } catch (error) {
        yield put({
            type: ACTION[typeAction].error,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_RICH_FOREMAN_PAYMENTS_REQUEST, getRichForemanPaymentsSaga),
        takeEvery(GET_FOREMAN_CONTRACTORS_RICH_LIST_REQUEST, getForemanContractorsRichListSaga),
        takeEvery(FOREMAN_PAYMENT_ACTION_REQUEST, makeActionSaga),
    ]);
}