import {isEqual} from "lodash";

import {getClientSettingsWorkWithContractorsRequestData} from "./getRequestData";

export const isClientSettingsWorkWithContractorsChanged = (params) => {
    const {
        values,
        card,
    } = params;

    const requestData = getClientSettingsWorkWithContractorsRequestData(values);

    return !isEqual(requestData, card);
};