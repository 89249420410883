import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../constants/roles";

export function isShowClientContractorListTabs (role, isClientPage) {
    if (!isClientPage) {
        return false;
    }

    return [
        ADMIN,
        NM_MANAGER,
        NM_OPERATOR,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
    ].includes(role) ||
        isClientUser(role);
}

export function isShowContractorListCheckBoxes (role, isClientPage) {
    //для заказчика отображаем всегда
    //для сотрудников НМ, только на карточке компании
    return (isClientPage && [ADMIN, NM_MANAGER].includes(role)) || [CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(role);
}

export function isShowContractorListFilterForNmUser (isClientPage, isInvitingPage) {
    //показываем для сотрудников НМ фильтр, если он на странице подбора на заказ или в общем списке исполнителей
    return !isClientPage || (isClientPage && isInvitingPage);
}

export function isShowUnitedContractorListFilterForNmUser (role, isClientPage, isInvitingPage) {
    //показываем для сотрудников НМ фильтр, если он не на странице подбора на заказ или в общем списке исполнителей
    return [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, RNKO].includes(role) && !isClientPage && !isInvitingPage;
}

export function isAcceptedAddFavoritesContractor (role, isClientPage) {
    //добавление в избранное только со страницы заказчика
    return isClientPage && [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(role);
}

export function isAcceptedAddBlacklistedContractor (role, isClientPage) {
    //добавление в чёрный список только со страницы заказчика
    return isClientPage && [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(role);
}

export function isAcceptedAddContractorToNmByEmailAndSms (role) {
    return [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(role);
}

export function isAcceptedAddOrCloseContractorToOrder (role, isInvitingPage) {
    //для заказчика отображаем всегда
    //для сотрудников НМ, только на странице приглашения
    return (isInvitingPage && [ADMIN, NM_MANAGER].includes(role)) || [CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(role);
}

export function isAcceptedMassAddContractorToOrder (role, isInvitingPage, canViewContractorContacts) {
    return (isInvitingPage && [ADMIN, NM_MANAGER].includes(role)) ||
        (canViewContractorContacts &&[CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(role));
}

export function isAcceptedAddingContractorByForm (role, isClientPage) {
    return [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role) && !isClientPage;
}

export function isAcceptedShowProjectParamsFilter (role, isClientPage) {
    return [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, RNKO].includes(role) && !isClientPage;
}