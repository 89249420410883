import {all, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";
import {createSelector} from "reselect";

import {
    APP_NAIMIX_INFO_LINK,
    LINK_CMS_STRAPI_PROD,
    LOCAL_PROD_NAIMIX_INFO_LINK,
    NAIMIX_INFO_LINK,
} from "../constants/links";

const STRAPI_DTKN_REQUEST = "STRAPI_DTKN_REQUEST";
const STRAPI_DTKN_SUCCESS = "STRAPI_DTKN_SUCCESS";
const STRAPI_DTKN_ERROR = "STRAPI_DTKN_ERROR";

const STRAPI_SALES_MANAGERS_REQUEST = "STRAPI_SALES_MANAGERS_REQUEST";
const STRAPI_SALES_MANAGERS_SUCCESS = "STRAPI_SALES_MANAGERS_SUCCESS";
const STRAPI_SALES_MANAGERS_ERROR = "STRAPI_SALES_MANAGERS_ERROR";

const initialState = {
    dtkn: "",
    salesManagers: [],
};

const isProd = [LOCAL_PROD_NAIMIX_INFO_LINK, NAIMIX_INFO_LINK, APP_NAIMIX_INFO_LINK].includes(window.location.origin);

const domain = isProd ? LINK_CMS_STRAPI_PROD : "https://strapi.mmtr.ru";

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case STRAPI_DTKN_SUCCESS:
        return {
            ...state,
            dtkn: payload,
        };
    case STRAPI_SALES_MANAGERS_SUCCESS:
        return {
            ...state,
            salesManagers: payload.data,
        };
    default: return state;
    }
};

export const getDadataKey = (payload) => {
    return {
        type: STRAPI_DTKN_REQUEST,
        payload,
    };
};

export const getSalesManagers = () => {
    return {
        type: STRAPI_SALES_MANAGERS_REQUEST,
    };
};

export const strapiSelector = ({strapi}) => strapi;
export const strapiDktnSelector = createSelector(strapiSelector, (state) => state.dtkn);
export const strapiSalesManagersSelector = createSelector(strapiSelector, (state) => state.salesManagers);

// /api/filter/check
export const getDadataKeySaga = function* ({payload}) {
    try {
        const {data} = yield axios.get(`${LINK_CMS_STRAPI_PROD}/api/dtkn?populate=*`, payload);

        const result = data?.data?.attributes;

        if (!result) {
            yield put({type: STRAPI_DTKN_ERROR, payload: result});

            return {
                done: true,
            };
        }

        yield put({type: STRAPI_DTKN_SUCCESS, payload: result.dtkn});
    } catch (error) {
        yield put({type: STRAPI_DTKN_ERROR, payload: error});
    }
};

export const getSalesManagersSaga = function* () {
    try {
        const {data} = yield axios.get(`${domain}/api/sales-managers?populate=*`);

        if (!data) {
            yield put({type: STRAPI_SALES_MANAGERS_ERROR});

            return {
                done: true,
            };
        }

        yield put({type: STRAPI_SALES_MANAGERS_SUCCESS, payload: data});
    } catch (error) {
        yield put({type: STRAPI_SALES_MANAGERS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(STRAPI_DTKN_REQUEST, getDadataKeySaga),
        takeEvery(STRAPI_SALES_MANAGERS_REQUEST, getSalesManagersSaga),
    ]);
}