import {useDispatch} from "react-redux";

import {
    addRecruitmentRejectionReason,
    deleteRecruitmentRejectionReason,
    editRecruitmentRejectionReason,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

import {toastSuccess} from "../../../../../utils/toastHelper";

export const useRecruitmentDirectoriesRejectionReasonsAction = ({clientId}) => {
    const dispatch = useDispatch();

    const onAdd = ({value, callBack = () => {}})=> {
        dispatch(addRecruitmentRejectionReason({
            clientId,
            value,
            onSuccess: () => {
                toastSuccess("Причина отказа успешно добавлена");
                callBack();
            },
        }));
    };

    const onEdit = ({id, value, callBack = () => {}}) => {
        dispatch(editRecruitmentRejectionReason({
            id,
            value,
            onSuccess: () => {
                toastSuccess("Причина отказа успешно обновлена");
                callBack();
            },
        }));
    };

    const onDelete = ({id, callBack = () => {}}) => {
        dispatch(deleteRecruitmentRejectionReason({
            id,
            onSuccess: () => {
                toastSuccess("Причина отказа успешно удалена");
                callBack();
            },
        }));
    };

    return {
        onAdd,
        onEdit,
        onDelete,
    };
};