import {ARM, BLR, KAZ, KGZ, RU} from "./citizenships";
import {DEPOSIT_HISTORY_ACTION} from "./deposit/actions";
import {documentType1c} from "./documentType";

import { financeLogSelector } from "../ducks/financeLog";

export const citizenshipOptions = () => {
    return [
        {
            key: "RU",
            text: RU,
            value: "RU",
        },
        {
            key: "ARM",
            text: ARM,
            value: "ARM",
        },
        {
            key: "BLR",
            text: BLR,
            value: "BLR",
        },
        {
            key: "KAZ",
            text: KAZ,
            value: "KAZ",
        },
        {
            key: "KGZ",
            text: KGZ,
            value: "KGZ",
        },
    ];
};

export const industryOptions = () => {
    return [
        {
            key: 1,
            text: "Строительство",
            value: "Строительство",
        },
    ];
};
/*
CARPENTER: "Плотник"
CONCRETEWORKER: "Бетонщик"
DRYWALLER: "Гипсокартонщик"
ELECTRICIAN: "Электрик"
FIREFIGHTER_INSTALLER: "Монтажник систем пожаротушения"
INSTALLER: "Монтажник"
MASON: "Каменщик"
PAINTER: "Штукатур-маляр"
PLASTERER: "Штукатур"
PLUMBER: "Сантехник"
ROOFER: "Кровельщик"
SECURITY_INSTALLER: "Монтажник систем безопасности"
STEELMAN: "Арматурщик"
TILER: "Плиточник"
UNIVERSAL_WORKER: "Мастер-универсал отделочных работ"
WELDER: "Сварщик"
* */
//{"UNIVERSAL_WORKER":"Мастер-универсал отделочных работ","MASON":"Каменщик","ROOFER":"Кровельщик","INSTALLER":"Монтажник"}
export const specialtyOptions = () => {
    return [
        {

            key: "UNIVERSAL_WORKER",
            text: "Мастер-универсал отделочных работ",
            value: "UNIVERSAL_WORKER",
        },
        {

            key: "PAINTER",
            text: "Штукатур-маляр",
            value: "PAINTER",
        },
        {

            key: "PLASTERER",
            text: "Штукатур",
            value: "PLASTERER",
        },
        {

            key: "PLUMBER",
            text: "Сантехник",
            value: "PLUMBER",
        },
        {

            key: "MASON",
            text: "Каменщик",
            value: "MASON",
        },
        {

            key: "ROOFER",
            text: "Кровельщик",
            value: "ROOFER",
        },
        {

            key: "INSTALLER",
            text: "Монтажник",
            value: "INSTALLER",
        },
        {
            key: "CARPENTER",
            text: "Плотник",
            value: "CARPENTER",
        },
        {
            key: "CONCRETEWORKER",
            text: "Бетонщик",
            value: "CONCRETEWORKER",
        },
        {
            key: "DRYWALLER",
            text: "Гипсокартонщик",
            value: "DRYWALLER",
        },
        {
            key: "ELECTRICIAN",
            text: "Электрик",
            value: "ELECTRICIAN",
        },
        {
            key: "FIREFIGHTER_INSTALLER",
            text: "Монтажник систем пожаротушения",
            value: "FIREFIGHTER_INSTALLER",
        },
        {
            key: "SECURITY_INSTALLER",
            text: "Монтажник систем безопасности",
            value: "SECURITY_INSTALLER",
        },
        {
            key: "STEELMAN",
            text: "Арматурщик",
            value: "STEELMAN",
        },
        {
            key: "TILER",
            text: "Плиточник",
            value: "TILER",
        },
        {
            key: "WELDER",
            text: "Сварщик",
            value: "WELDER",
        },
    ];
};

export const document1cTypeOptions = () => {
    return Object.values(documentType1c);
};

export const actionDepositOptions = (isManager) => {
    const manager=[
        {
            key: DEPOSIT_HISTORY_ACTION.CLOSE_ORDER.value,
            text: "Закрытие заказа",
            value: DEPOSIT_HISTORY_ACTION.CLOSE_ORDER.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.PUBLISHED_ORDER.value,
            text: "Публикация заказа",
            value: DEPOSIT_HISTORY_ACTION.PUBLISHED_ORDER.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.REGISTRY_PAYMENT.value,
            text: "Оплата по реестру",
            value: DEPOSIT_HISTORY_ACTION.REGISTRY_PAYMENT.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.CANCELLED_REGISTRY_PAYMENT.value,
            text: "Отклонение оплаты по реестру",
            value: DEPOSIT_HISTORY_ACTION.CANCELLED_REGISTRY_PAYMENT.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.PAYMENT.value,
            text: "Оплата",
            value: DEPOSIT_HISTORY_ACTION.PAYMENT.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.CLOSE_INSURANCE.value,
            text: "Возврат суммы страхования",
            value: DEPOSIT_HISTORY_ACTION.CLOSE_INSURANCE.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.CANCELLED_PAYMENT.value,
            text: "Отмена платежа",
            value: DEPOSIT_HISTORY_ACTION.CANCELLED_PAYMENT.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.ADD_INSURANCE.value,
            text: "Списание суммы страхования",
            value: DEPOSIT_HISTORY_ACTION.ADD_INSURANCE.value,
        },
    ];
    const all = [
        {
            key: DEPOSIT_HISTORY_ACTION.ADD_DEPOSIT.value,
            text: "Добавление депозита",
            value: DEPOSIT_HISTORY_ACTION.ADD_DEPOSIT.value,
        },
        {
            key: DEPOSIT_HISTORY_ACTION.RETURN_DEPOSIT.value,
            text: DEPOSIT_HISTORY_ACTION.RETURN_DEPOSIT.text,
            value: DEPOSIT_HISTORY_ACTION.RETURN_DEPOSIT.value,
        },
    ];
    return isManager ? manager : manager.concat(all);
};

export const actionDepositOptionsUnsecured = () => {
    return [
        {
            key: DEPOSIT_HISTORY_ACTION.PAYMENT_COMPLETED_ORDER_UNSECURED.value,
            text: "Завершение платежа по заказу без обеспечения",
            value: DEPOSIT_HISTORY_ACTION.PAYMENT_COMPLETED_ORDER_UNSECURED.value,
        },
    ];
};
//*
//{
//"MSK": "Москва"
//}
// /
export const federalRegionsOptions = () => {
    return [
        {
            key: "MSK",
            text: "Москва",
            value: "MSK",
        },
        {
            key: "TATARSTANREPUBLIC",
            text: "Республика Татарстан",
            value: "TATARSTANREPUBLIC",
        },
    ];
};