import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as UploadIcon} from "../../../images/file_upload.svg";
import {ReactComponent as ListIcon} from "../../../images/list.svg";
import {ReactComponent as MapIcon} from "../../../images/map-icon.svg";
import {useClearMapModalData} from "./useClearModalData";

import {toastSuccess} from "../../../utils/toastHelper";
import {getRequestDataFromMapFilterData} from "../utils/dto";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {MAP_INIT_FILTER_DATA, MARKER_TYPE} from "../constants";

import {exportMap} from "../../../ducks/bff/map/actionCreators";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {addTaskAnalyseLocation, getAllTasks, jobTasksAnalysisLocationSelector} from "../../../ducks/job";

export const useMapPage = () => {
    const dispatch = useDispatch();

    const [showMap, setShowMap] = useState(true);
    const [isShowModalInfo, setShowModalInfo] = useState(false);
    const [modalInfoData, setModalInfoData] = useState([]);
    const [confirmData, setConfirmData] = useState({});
    const [filterData, setFilterData] = useState({...MAP_INIT_FILTER_DATA});

    const analysisTasks = useSelector(jobTasksAnalysisLocationSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    const {
        clearModalData,
    } = useClearMapModalData({});

    const exportMapEntries = () => {
        const requestData = getRequestDataFromMapFilterData(filterData);

        dispatch(exportMap(requestData));
    };

    const runAnalyse = () => {
        dispatch(addTaskAnalyseLocation({
            onSuccess: () => {
                getTasks();
                toastSuccess("Задача по анализу успешно запущена");
            },
        }));
    };

    const getTasks = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        dispatch(getAllTasks({
            clientId,
            clientUserId,
        }));
    };

    const dataCoordinates = useMemo(() => {
        return modalInfoData.reduce((acc, {type, geoLat, geoLon}) => {
            const coordinates = {geoLat, geoLon};

            if (type === MARKER_TYPE.CONTRACTOR) {
                acc.contractorCoordinatesFilter.push(coordinates);
            }

            if (type === MARKER_TYPE.ORDER) {
                acc.orderCoordinatesFilter.push(coordinates);
            }

            if (type === MARKER_TYPE.ADVERT) {
                acc.advertCoordinatesFilter.push(coordinates);
            }

            if (type === MARKER_TYPE.CROWD_TASK) {
                acc.taskCoordinatesFilter.push(coordinates);
            }

            return acc;
        }, {
            contractorCoordinatesFilter: [],
            orderCoordinatesFilter: [],
            advertCoordinatesFilter: [],
            taskCoordinatesFilter: [],
        });
    }, [modalInfoData]);

    const clearFilter = () => {
        setShowModalInfo(false);
        setModalInfoData([]);
    };

    const onMarkerClick = (params) => {
        const {
            data,
        } = params;

        clearModalData();
        onOpenModal(data);
    };

    const onOpenModal = (data) => {
        setModalInfoData(data);
        setShowModalInfo(true);
    };

    const onCloseModal = () => {
        setModalInfoData([]);
        setShowModalInfo(false);
    };

    const mediaControls = useMemo(() => {
        return {
            renderCount: {
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setShowMap(!showMap);
                            onCloseModal();
                        },
                        color: "grey",
                        icon: showMap ?
                            <ListIcon /> : <MapIcon />,
                        children: showMap ? "Список" : "Карта",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: exportMapEntries,
                        icon: <UploadIcon
                            width={18}
                            height={18}
                        />,
                        color: "grey",
                        children: "Выгрузить",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setConfirmData({
                                content: "Вы уверены что хотите запустить анализ? После выполнения анализа все собранные данные о геопозиции исполнителей будут удалены. Продолжить?",
                                confirmButton: "Продолжить",
                                cancelButton: "Отмена",
                                submit: runAnalyse,
                            });
                        },
                        color: "light-green",
                        children: "Запустить анализ",
                        disabled: Boolean(analysisTasks.length),
                    },
                    visible: !showMap,
                },
            ],
        };
    }, [
        showMap,
        filterData,
        analysisTasks,
    ]);

    return {
        modalInfoData,
        clearFilter,
        showMap,
        isShowModalInfo,
        confirmData,
        onMarkerClick,
        mediaControls,
        onCloseModal,
        setConfirmData,
        dataCoordinates,
        filterData,
        setFilterData,
        onOpenModal,
    };
};