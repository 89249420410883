import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../components/ActualComponents/Text";
import DefaultDropzone from "../../../../../components/DefaultDropzone";
import NmButton from "../../../../../components/NmButton";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as IconChevron} from "../../../../../images/chevron-down.svg";
import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as AudioFileIcon} from "../../../../../images/file-audio.svg";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import {useCounter} from "../../../../../hooks/useCounter";

import {getFilesWithPreview} from "../../../../../components/ActualComponents/DropzoneV2/utils/getFilesWithPreview";
import {isNMUsers} from "../../../../../utils/access";
import bem from "../../../../../utils/bem";
import dateFormat, {dateWithoutTimezone} from "../../../../../utils/dateFormat";
import {getDocumentIconName, getFileExtension} from "../../../../../utils/file";
import {CURRENT_CLIENT_ID, CURRENT_CLIENT_USER_ID, ls} from "../../../../../utils/localstorage";
import {guid, isNullOrWhitespace} from "../../../../../utils/stringHelper";
import {toastError} from "../../../../../utils/toastHelper";

import {AUDIO_EXTENSIONS, CHAT_FILES_ACCEPT, IMAGE_EXTENSIONS} from "../../../../../constants/chats";
import {USER_TYPE} from "../../../../../constants/roles";
import {maxSize15MB, maxSizeScanFile} from "../../../../../constants/validationRules";

import {
    chatAddMessage,
    chatListSelector,
    crmChatWithNamemixAddMessage,
    crowdChatAddMessage,
    currentChatSelector,
    updateChatMessages,
    updateFieldChat,
} from "../../../../../ducks/chat";
import {
    addDocumentToChat,
    addDocumentToCrowdChat,
    fileContractorScanProgressAddSelector,
} from "../../../../../ducks/fileStore";

import "./style.sass";

export default function ClientChatSendMessage({clientId, isCrowd}) {
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const chatInfo = useSelector(currentChatSelector);
    const chatList = useSelector(chatListSelector);
    const progressAddFiles = useSelector(fileContractorScanProgressAddSelector);
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);
    const currentClientId = ls(CURRENT_CLIENT_ID);

    const dispatch = useDispatch();

    const [block, element] = bem("chat-client-send-message");

    const {chatId, isNamemixChat} = chatInfo;

    const {
        isCounterFinish,
        increaseCounter,
    } = useCounter({countList: files});

    useEffect(() => {
        if (!isEmpty(chatInfo)) {
            setMessage("");
            setFiles([]);
            setUploadedFiles([]);
        }
    }, [chatInfo]);

    useEffect(() => {
        if (isCounterFinish) {
            sendMsg();
        }
    }, [isCounterFinish]);

    const handleOnChangeMessage = (e, {value}) => {
        setMessage(value);
    };

    function setCurrentChat(currentChat) {
        dispatch(updateFieldChat({
            currentChat,
        }));
    }

    const sendMsg = () => {
        if (isNullOrWhitespace(message) && !files.length) {
            return;
        }

        const temporaryMessageId = guid();

        const newMessage = {
            content: message,
            partiesId: currentUserId,
            chatId,
            files: uploadedFiles || [],
            temporaryMessageId,
        };

        setMessage("");
        setFiles([]);
        setUploadedFiles([]);

        dispatch(updateChatMessages({
            ...newMessage,
            dateTimeCreate: dateFormat(new Date(), "yyyy-MM-dd HH:mm"),
        }));

        const getResult = (result) => {
            if (!chatId) {
                setCurrentChat({
                    ...chatInfo,
                    chatId: result.chatId,
                });
            }

            updateChatList({
                ...newMessage,
                ...result,
            });
        };

        const reqData = {
            ...newMessage,
            getResult,
        };

        if (isNamemixChat) {
            dispatch(crmChatWithNamemixAddMessage({
                ...reqData,
                partiesId: currentUserId,
            }));

            return;
        }

        if (isCrowd) {
            dispatch(crowdChatAddMessage({
                senderInfo: {
                    roleType: isNMUsers() ? USER_TYPE.NAIMIX_USER.VALUE : USER_TYPE.CLIENT_USER.VALUE,
                    clientId: currentClientId,
                    userId: currentUserId,
                },
                clientId,
                contractorId: chatInfo.contractorId,
                content: message,
                fileInfoList: uploadedFiles || [],
                temporaryMessageId,
                getResult,
            }));

            return;
        }

        dispatch(chatAddMessage({
            simpleChat: true,
            ...reqData,
        }));
    };

    const updateChatList = (newMessage) => {
        const newMessageInfo = {
            lastMessageDate: newMessage.dateTimeCreate || dateWithoutTimezone(new Date()),
            lastMessage: {
                ...newMessage,
                userId: currentUserId,
            },
        };

        if (isNamemixChat) {
            setCurrentChat({
                ...chatInfo,
                ...newMessageInfo,
            });

            return;
        }

        const chat = chatList.find(({chatId}) => chatId === chatInfo.chatId);

        const chatUpdatesFields = {
            chatId: chatInfo.chatId,
            ...newMessageInfo,
        };

        const _chatList = [
            {
                ...chat,
                ...chatUpdatesFields,
            },
            ...chatList.filter(item => item.chatId !== chatInfo.chatId),
        ];

        dispatch(updateFieldChat({chatList: _chatList}));
    };

    const uploadFiles = (newFiles) => {
        if (!newFiles || !newFiles.length) {
            return;
        }

        const files = newFiles.filter(file => {
            const fileExtension = getFileExtension(file.name);
            const maxSize = AUDIO_EXTENSIONS.includes(fileExtension)
                ? maxSize15MB
                : maxSizeScanFile;
            const isSizeError = file.size > maxSize;

            isSizeError && toastError(`${file.name} - Превышен максимальный размер файла`);

            return !isSizeError;
        });

        setFiles(prevState => [
            ...prevState,
            ...getFilesWithPreview(files, "image/*"),
        ]);
    };

    const uploadFile = (file) => {
        const formData = new FormData();

        formData.append("file", file);

        const getResult = ({fileName, fileId, fileUuid}) => {
            setUploadedFiles(value => [
                ...value,
                {
                    fileName,
                    fileId,
                    fileUuid,
                    file,
                },
            ]);

            increaseCounter();
        };

        if (isCrowd) {
            dispatch(addDocumentToCrowdChat({
                chatId,
                clientId,
                contractorId: chatInfo.contractorId,
                file: formData,
                fileName: file.name,
                getResult,
            }));
            
            return;
        }

        dispatch(addDocumentToChat({
            chatId,
            isFromChat: true,
            file: formData,
            isSimpleChat: true,
            isCrmNamemixChat: isNamemixChat,
            getResult,
        }));
    };

    const onSubmit = () => {
        if (!files.length) {
            sendMsg();

            return;
        }

        files.forEach(file => {
            uploadFile(file);
        });
    };

    const handleKeyDown = (event) => {
        if (
            event.key === "Enter"
            && (
                event.altKey ||
                event.ctrlKey ||
                event.metaKey
            )
        ) {
            setMessage(message + "\r\n");

            return;
        }

        if (
            event.key === "Enter" &&
            !event.shiftKey
        ) {
            event.preventDefault();
            onSubmit();
        }
    };

    const removeFile = (index) => {
        const _files = [...files];

        _files.splice(index, 1);
        setFiles(_files);
    };

    const getFileIcon = (fileExtension) => {
        if (AUDIO_EXTENSIONS.includes(fileExtension)) {
            return (
                <AudioFileIcon
                    width={24}
                    height={24}
                />
            );
        }

        return (
            <Icon name={getDocumentIconName(fileExtension)} />
        );
    };

    const renderPreviewFiles = () => {
        if (!files.length) {
            return null;
        }

        return (
            <div className={element("files")}>
                {
                    files.map((item, index) => {
                        const fileExtension = getFileExtension(item.name);

                        return (
                            <div className={element("file-preview")}>
                                <CloseIcon
                                    width={16}
                                    height={16}
                                    className={element("file-remove")}
                                    onClick={() => removeFile(index)}
                                />
                                {IMAGE_EXTENSIONS.includes(fileExtension)
                                    ? <img
                                        alt={item.name}
                                        className={element("file-preview-image")}
                                        src={item.preview}
                                    />
                                    : <div className="flex align-items-center mb-2">
                                        {getFileIcon(fileExtension)}
                                        <Text
                                            title={item.name}
                                            level="3"
                                            noWrap
                                            className={element("file-preview-filename")}
                                        >
                                            {item.name}
                                        </Text>
                                    </div>
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    return (
        <div>
            {renderPreviewFiles()}
            <div className={block()}>
                <DefaultDropzone
                    disabled={progressAddFiles}
                    maxFiles={5}
                    accept={CHAT_FILES_ACCEPT}
                    onDropFiles={uploadFiles}
                >
                    <NmButton
                        size="xl"
                        onlyIcon
                        color="grey"
                        icon={
                            <AddIcon
                                width={24}
                                height={24}
                            />
                        }
                    />
                </DefaultDropzone>
                <NmTextareaV2
                    disabled={progressAddFiles}
                    fluidHeight
                    minRows={1}
                    maxRows={4}
                    className={element("text-area")}
                    placeholder="Введите сообщение..."
                    name="msg"
                    maxLength="500"
                    value={message}
                    onChange={handleOnChangeMessage}
                    onKeyDown={handleKeyDown}
                />
                <NmButton
                    disabled={progressAddFiles}
                    size="xl"
                    color="grey"
                    onlyIcon
                    onClick={onSubmit}
                    icon={
                        <IconChevron
                            width={24}
                            height={24}
                            className={element("footer-send")}
                        />
                    }
                />
            </div>
        </div>
    );
}