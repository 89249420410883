import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

export const useRecruitmentDirectoriesFunnelsFilter = (params) => {
    const {
        setPagination,
        pageSize,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            name: "",
        },
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Название воронки",
                        placeholder: "Введите название воронки",
                        name: "name",
                    },
                ],
            },
        ];
    }, []);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    };
};