import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "adm/recruitment/vacancyCandidates";

// PUT /bff/adm/recruitment/vacancyCandidates/{id}
// Редактирование кандидата
const editRecruitmentVacancyCandidate = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
        });
    }
};

// DELETE /bff/adm/recruitment/vacancyCandidates/{id}
// Удаление кандидата
const deleteRecruitmentVacancyCandidate = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates
// Создание кандидата
const createRecruitmentVacancyCandidate = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(controller, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/getPage
// Получить список кандидатов
const getRecruitmentVacancyCandidates = function* ({payload}) {
    const {
        isLoadMore,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/getPage`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/getPage
// Получить карточку кандидата
const getRecruitmentVacancyCandidatesCard = function* ({payload}) {
    const {candidateId} = payload;

    try {
        const result = yield request.bff.get(`${controller}/${candidateId}/card`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/{id}/uploadResume
// Добавить файл резюме
const uploadRecruitmentVacancyCandidatesResume = function* ({payload}) {
    const {
        onSuccess,
        id,
        formData,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${id}/uploadResume`, formData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/{id}/setStatus
// Установить статус кандидату кандидата
const setRecruitmentVacancyCandidateStatus = function* ({payload}) {
    const {
        candidateId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/setStatus`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/{candidateId}/updateFromResponse
// Перенести данные из отклика в кандидата
const updateRecruitmentVacancyCandidatesFromResponse = function* ({payload}) {
    const {
        candidateId,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/updateFromResponse`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/getPageForResponse
// Получить список кандидатов для карточки привязки отклика к кандидату
const getRecruitmentVacancyCandidatesForResponse = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPageForResponse`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/${id}/uploadFile
// Добавить файл
const uploadRecruitmentVacancyCandidatesFileSaga = function* ({payload}) {
    const {
        formData,
        candidateId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/uploadFile`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/${id}/deleteFile
// Удалить файл
const deleteRecruitmentVacancyCandidatesFileSaga = function* ({payload}) {
    const {
        onSuccess,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/deleteFile`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidates/${id}/deleteResume
// Удалить файл резюме
const deleteRecruitmentVacancyCandidatesResumeSaga = function* ({payload}) {
    const {
        onSuccess,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/deleteResume`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
        });
    }
};

//GET /bff/adm/recruitment/vacancyCandidates/{candidateId}/getFeed
// Получить ленту событий по кандидату
const getFeedListRecruitmentVacancyCandidates = function* ({payload}) {
    try {
        const {
            candidateId,
            ...params
        } = payload;

        const result = yield request.bff.get(`${controller}/${candidateId}/getFeed`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancyCandidates/{id}/reject
// Установить статус отказ кандидату
const rejectRecruitmentCandidateSaga = function* ({payload}) {
    const {
        candidateId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST, editRecruitmentVacancyCandidate),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST, deleteRecruitmentVacancyCandidate),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST, createRecruitmentVacancyCandidate),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST, getRecruitmentVacancyCandidates),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST, getRecruitmentVacancyCandidatesCard),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST, uploadRecruitmentVacancyCandidatesResume),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST, setRecruitmentVacancyCandidateStatus),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST, updateRecruitmentVacancyCandidatesFromResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST, getRecruitmentVacancyCandidatesForResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST, uploadRecruitmentVacancyCandidatesFileSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST, deleteRecruitmentVacancyCandidatesFileSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST, deleteRecruitmentVacancyCandidatesResumeSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST, getFeedListRecruitmentVacancyCandidates),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST, rejectRecruitmentCandidateSaga),
    ]);
}