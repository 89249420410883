import React from "react";

import NmButton from "../../../../../components/NmButton";

import bem from "../../../../../utils/bem";
import formatDate, {formatLocalDate} from "../../../../../utils/dateFormat";
import {getFullName, phoneFormat} from "../../../../../utils/stringFormat";

import "./style.sass";

interface IOrderCardDisputesCard {
    dispute: any,
    onOpenNewDispute: (id: string) => void,
    disputeCauses: any,
    goChat: any,
    className?: string
}

const OrderCardDisputesCard = (props: IOrderCardDisputesCard) => {
    const {
        dispute: {
            contractorFirstName,
            contractorPatronymic,
            contractorLastName,
            creationDate,
            phone,
            descriptionDispute,
            disputeId,
            causeDispute,
        },
        onOpenNewDispute,
        disputeCauses,
        className = "",
    } = props;
    const [block, element] = bem("order-card-disputes-card", className);

    const onOpen = () => {
        onOpenNewDispute(disputeId);
    };

    const goChat = () => {
        props.goChat(disputeId)();
    };

    return (
        <div className={block()}>
            <div
                onClick={onOpen}
                className={element("title")}
            >
                {disputeCauses[causeDispute]}
            </div>
            <div className={element("row")}>
                <div className={element("container")}>
                    <div className={element("label")}>
                        ФИО:
                    </div>
                    <div className={element("value")}>
                        {getFullName(contractorLastName, contractorFirstName, contractorPatronymic)}
                    </div>
                </div>
                <div className={element("container")}>
                    <div className={element("label")}>
                        Телефон:
                    </div>
                    <div className={element("value")}>
                        {phoneFormat(phone)}
                    </div>
                </div>
                <div className={element("container")}>
                    <div className={element("label")}>
                        Дата начала спора:
                    </div>
                    <div className={element("value")}>
                        {formatLocalDate(creationDate, "dd.MM.yyyy HH:mm")}
                    </div>
                </div>
            </div>
            <div className={element("container")}>
                <div className={element("label")}>
                    Описание проблемы:
                </div>
                <div className={element("value")}>
                    {descriptionDispute}
                </div>
            </div>
            <NmButton
                color="grey"
                className={element("button")}
                onClick={goChat}
            >
                Перейти в чат
            </NmButton>
        </div>
    );
};

export default OrderCardDisputesCard;