export const REF_TYPE = {
    CLIENTS: "CLIENTS",
    CONTRACTORS: "CONTRACTORS",
};

export const REFERRAL_ACTION = {
    ADD_TO_ARCHIVE: "ADD_TO_ARCHIVE",
    UPDATE: "UPDATE",
    RETURN_FROM_ARCHIVE: "RETURN_FROM_ARCHIVE",
};

export const REFERRAL_ACTION_TEXT = {
    [REFERRAL_ACTION.ADD_TO_ARCHIVE]: "Промо-код добавлен в архив",
    [REFERRAL_ACTION.RETURN_FROM_ARCHIVE]: "Промо-код восстановлен из архива",
};

export const REFERRAL_CHANNEL_NAME = {
    PARTNER: "(Партнер Наймикс)",
};