import {
    DELETE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
    UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
} from "./actions";

export function updateContractorNoteForClient(payload) {
    return {
        type: UPDATE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
        payload,
    };
}

export function deleteContractorNoteForClient(payload) {
    return {
        type: DELETE_CONTRACTOR_NOTE_FOR_CLIENT_REQUEST,
        payload,
    };
}