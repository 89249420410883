import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/contractors-registry/contractor/finance/balance";

//*  TYPES  *//

const GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST = "GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST";
const GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS = "GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS";
const GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR = "GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR";

const GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST = "GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST";
const GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS = "GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS";
const GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR = "GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR";

const SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST = "SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST";
const SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS = "SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS";
const SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR = "SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR";

const CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST = "CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST";
const CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS = "CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS";
const CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR = "CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR";

const CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST = "CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST";
const CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS = "CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS";
const CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR = "CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR";

const CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST = "CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST";
const CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS = "CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS";
const CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR = "CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR";

const CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE = "CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    card: {},
    pageData: {},
    progress: false,
    progressAction: false,
    totalCount: 0,
    contractorBalance: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST:
        const {pageSize, pageNum} = payload;
        return {
            ...state,
            progress: true,
            pageData: {
                pageSize,
                pageNum,
            },
        };
    case GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS:
        const {
            results,
            totalCount,
        } = payload;

        return {
            ...state,
            progress: false,
            list: results,
            totalCount,
        };
    case GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST:
    case SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST:
    case CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST:
    case CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS:
    case CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR:
    case SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS:
    case SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR:
    case CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS:
    case CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR:
    case CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_SUCCESS:
    case CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS:
        return {
            ...state,
            contractorBalance: payload,
        };
    case GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR:
        return {
            ...state,
            contractorBalance: {
                isError: true,
            },
        };
    case CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getPageContractorFinanceBalance(payload) {
    return {
        type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST,
        payload,
    };
}

export function getSummaryContractorFinanceBalance(payload) {
    return {
        type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST,
        payload,
    };
}

export function setContractorSmzTaxOffer(payload) {
    return {
        type: SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST,
        payload,
    };
}

export function changeOpenWithdrawalMoneyOfWalletInBank(payload) {
    return {
        type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST,
        payload,
    };
}

export function cancelContractorSmzTaxOffer(payload) {
    return {
        type: CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST,
        payload,
    };
}

export function updateContractorFinanceBalanceStore(payload) {
    return {
        type: CONTRACTOR_FINANCE_BALANCE_UPDATE_STORE,
        payload,
    };
}

export function changeContractorBlockedBankOperation(payload) {
    return {
        type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorFinanceBalanceSelector = state => state.contractorFinanceBalance;
export const getContractorFinanceBalanceTotalPagesSelector = createSelector(contractorFinanceBalanceSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getContractorFinanceBalanceTotalCountSelector = createSelector(contractorFinanceBalanceSelector, ({totalCount}) => totalCount);
export const getContractorFinanceBalanceListSelector = createSelector(contractorFinanceBalanceSelector, ({list}) => list);
export const getContractorFinanceBalanceProgressSelector = createSelector(contractorFinanceBalanceSelector, ({progress}) => progress);
export const getContractorFinanceBalanceProgressActionSelector = createSelector(contractorFinanceBalanceSelector, ({progressAction}) => progressAction);
export const getContractorBalanceSelector = createSelector(contractorFinanceBalanceSelector, ({contractorBalance}) => contractorBalance);

//*  SAGA  *//

//POST /bff/adm/contractors-registry/contractor/finance/balance/getPage
export const getPageContractorFinanceBalanceSaga = function* ({payload} ) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAGE_CONTRACTOR_FINANCE_BALANCE_ERROR, payload: error});
    }
};

//GET /bff/adm/contractors-registry/contractor/finance/balance/getSummary/{contractorId}
export const getSummaryContractorFinanceBalanceSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getSummary/${contractorId}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_ERROR, payload: error});
    }
};

//PUT /bff/adm/contractors-registry/contractor/finance/balance/setSmzTaxOffer
export const setContractorSmzTaxOfferSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            smzTaxOffer,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.put(`${controller}/setSmzTaxOffer?contractorId=${contractorId}&smzTaxOffer=${smzTaxOffer}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SET_CONTRACTOR_SMZ_TAX_OFFER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: SET_CONTRACTOR_SMZ_TAX_OFFER_ERROR, payload: error});
    }
};

//PUT /bff/adm/contractors-registry/contractor/finance/balance/cancelEnabledSmzTaxOfferNextMonth
export const cancelContractorSmzTaxOfferSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.put(`${controller}/cancelEnabledSmzTaxOfferNextMonth?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR, payload: error});
    }
};

//PUT /bff/adm/contractors-registry/contractor/finance/balance/changeOpenWithdrawalMoneyOfWalletInBank/{contractorId}
export const changeOpenWithdrawalMoneyOfWalletInBankSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.put(`${controller}/changeOpenWithdrawalMoneyOfWalletInBank/${contractorId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_ERROR, payload: error});
    }
};

// POST /bff/adm/contractors-registry/contractor/finance/balance/changeContractorBlockedBankOperation
// Заблокировать/разблокировать вывод денежных средств
export const changeContractorBlockedBankOperationSaga = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/changeContractorBlockedBankOperation`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_CONTRACTOR_FINANCE_BALANCE_REQUEST, getPageContractorFinanceBalanceSaga),
        takeEvery(GET_SUMMARY_CONTRACTOR_FINANCE_BALANCE_REQUEST, getSummaryContractorFinanceBalanceSaga),
        takeEvery(SET_CONTRACTOR_SMZ_TAX_OFFER_REQUEST, setContractorSmzTaxOfferSaga),
        takeEvery(CANCEL_CONTRACTOR_ENABLED_SMZ_TAX_OFFER_REQUEST, cancelContractorSmzTaxOfferSaga),
        takeEvery(CHANGE_OPEN_WITHDRAWAL_MONEY_OF_WALLET_IN_BANK_REQUEST, changeOpenWithdrawalMoneyOfWalletInBankSaga),
        takeEvery(CHANGE_CONTRACTOR_BLOCKED_BANK_OPERATION_REQUEST, changeContractorBlockedBankOperationSaga),
    ]);
}