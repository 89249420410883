import React, {FC} from "react";
import InputMask from "react-input-mask";

import {ReactComponent as CancelIcon} from "../../../images/cancel_24.svg";
import ErrorTooltip from "../ErrorTooltip";
import NmLabel from "../NmLabel";

import bem from "../../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmInputV2: FC<any> = React.forwardRef((props, ref) => {
    const {
        value,
        size = "md",
        className,
        onChange,
        label,
        disabled = false,
        alwaysShowMask = false,
        error,
        warning,
        required,
        maxLength,
        errorClassName,
        mask,
        name,
        isClearable,
        pattern,
        icon,
        clearStyles,
        changed,
        suggestion,
        onMouseEnter,
        isVisibleTooltip,
        openNaimix,
        promoPoisk,
        labelClassName,
        tooltip,
        isVisibleDefaultTitle = true,
        ...otherProps
    } = props;

    const [block, element] = bem("nm-input-v2", className);

    const _onChange = (event: any) => {
        const {value, name} = event.target;

        if (Boolean(pattern) && value && !new RegExp(pattern).test(value)) {
            return;
        }

        onChange(event, {value, name});
    };

    const onClear = (event: any) => {
        onChange(event, {name, value: ""});
    };

    const getInput = () => {
        return mask ?
            <InputMask
                className={element("control", {
                    size,
                    error: Boolean(error),
                    active: Boolean(value) || value === 0,
                    openNaimix,
                    promoPoisk,
                    clearable: isClearable,
                    clearStyles,
                    changed,
                    masked: true,
                })}
                alwaysShowMask={alwaysShowMask}
                onChange={_onChange}
                disabled={disabled}
                value={value}
                mask={mask}
                name={name}
                ref={ref}
                {...otherProps}
            >
                {
                    (inputProps: any) => {
                        return (
                            <input
                                {...inputProps}
                                disabled={disabled}
                            />
                        );
                    }

                }
            </InputMask> :
            <input
                {...otherProps}
                maxLength={maxLength}
                disabled={disabled}
                onChange={_onChange}
                title={isVisibleDefaultTitle && value}
                value={value}
                name={name}
                className={element("control", {
                    size,
                    error: Boolean(error),
                    active: Boolean(value) || value === 0,
                    openNaimix,
                    promoPoisk,
                    clearable: isClearable,
                    changed,
                })}
                ref={ref}
            />;

    };

    return (
        <div className={`${block({size})}`}>
            {
                label &&
                <NmLabel
                    className={labelClassName}
                    isVisibleTooltip={isVisibleTooltip}
                    required={required}
                    disabled={disabled}
                    label={label}
                    tooltip={tooltip}
                />
            }
            <div
                onMouseEnter={onMouseEnter}
                className={element("container")}
            >
                {getInput()}
                {
                    isClearable && value && !disabled &&
                        <CancelIcon
                            onClick={onClear}
                            className={element("clear")}
                        />
                }
                {
                    maxLength &&
                    <div className={element("counter", {disabled})}>
                        {`${value ? value.length : 0}/${maxLength}`}
                    </div>
                }
                {icon}
                {
                    suggestion &&
                        <div className={element("suggestion")}>
                            {suggestion}
                        </div>
                }
            </div>
            {
                (error || warning) &&
                <ErrorTooltip
                    openNaimix={openNaimix}
                    promoPoisk={promoPoisk}
                    className={errorClassName}
                    error={error}
                    warning={warning}
                />
            }
        </div>
    );
});

NmInputV2.propTypes = {
    size: PropTypes.oneOf([
        "md",
        "lg",
        "xl",
    ]),
    label: PropTypes.string,
    maxLength: PropTypes.number,
};

export default NmInputV2;