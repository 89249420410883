import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/insurance";
//*  TYPES  *//

const GET_PAGE_HISTORY_INSURANCE_REQUEST = "GET_PAGE_HISTORY_INSURANCE_REQUEST";
const GET_PAGE_HISTORY_INSURANCE_SUCCESS = "GET_PAGE_HISTORY_INSURANCE_SUCCESS";
const GET_PAGE_HISTORY_INSURANCE_ERROR = "GET_PAGE_HISTORY_INSURANCE_ERROR";

const GET_INSURANCE_BY_CLIENT_ID_REQUEST = "GET_INSURANCE_BY_CLIENT_ID_REQUEST";
const GET_INSURANCE_BY_CLIENT_ID_SUCCESS = "GET_INSURANCE_BY_CLIENT_ID_SUCCESS";
const GET_INSURANCE_BY_CLIENT_ID_ERROR = "GET_INSURANCE_BY_CLIENT_ID_ERROR";

//*  INITIAL STATE  *//

const initial = {
    insuranceHistoryList: [],
    insuranceHistoryProgress: false,
    insuranceHistoryTotalCount: 0,
    insuranceHistoryPageData: {},
    insuranceProgress: false,
    reserveAmount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_PAGE_HISTORY_INSURANCE_REQUEST:
        return {
            ...state,
            insuranceHistoryProgress: true,
            insuranceHistoryPageData: payload,
        };
    case GET_PAGE_HISTORY_INSURANCE_SUCCESS:
        const {
            insuranceHistory: insuranceHistoryList,
            totalCount: insuranceHistoryTotalCount,
        } = payload;

        return {
            ...state,
            insuranceHistoryList,
            insuranceHistoryTotalCount,
            insuranceHistoryProgress: false,
        };
    case GET_INSURANCE_BY_CLIENT_ID_REQUEST:
        return {
            ...state,
            insuranceProgress: true,
        };
    case GET_INSURANCE_BY_CLIENT_ID_SUCCESS:
        return {
            ...state,
            insuranceProgress: false,
            reserveAmount: payload,
        };
    case GET_INSURANCE_BY_CLIENT_ID_ERROR:
        return {
            ...state,
            insuranceProgress: false,
            error: payload,
        };
    case GET_PAGE_HISTORY_INSURANCE_ERROR:
        return {
            ...state,
            insuranceHistoryProgress: false,
            error: payload,
        };

    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getPageHistoryInsurance(payload) {
    return {
        type: GET_PAGE_HISTORY_INSURANCE_REQUEST,
        payload,
    };
}

export function getInsuranceByClientId(payload) {
    return {
        type: GET_INSURANCE_BY_CLIENT_ID_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const insuranceSelector = state => state.insurance;
export const getInsuranceHistoryTotalPagesSelector = createSelector(
    insuranceSelector,
    ({insuranceHistoryPageData: {pageSize}, insuranceHistoryTotalCount}) => getTotalPages(insuranceHistoryTotalCount, pageSize),
);
export const getInsuranceHistoryTotalCountSelector = createSelector(
    insuranceSelector,
    ({insuranceHistoryTotalCount}) => insuranceHistoryTotalCount,
);
export const getInsuranceHistoryListSelector = createSelector(
    insuranceSelector,
    ({insuranceHistoryList}) => insuranceHistoryList,
);
export const getReserveAmountSelector = createSelector(
    insuranceSelector,
    ({reserveAmount}) => reserveAmount,
);
export const getInsuranceProgressSelector = createSelector(
    insuranceSelector,
    ({insuranceProgress}) => insuranceProgress,
);
export const getInsuranceHistoryProgressSelector = createSelector(
    insuranceSelector,
    ({insuranceHistoryProgress}) => insuranceHistoryProgress,
);

//*  SAGA  *//
//POST /api/insurance/getPageInsuranceHistory
export const getPageInsuranceHistorySaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPageInsuranceHistory`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_HISTORY_INSURANCE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_HISTORY_INSURANCE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_HISTORY_INSURANCE_ERROR,
            payload: error,
        });
    }
};

export const getInsuranceByClientIdSaga = function* (action) {
    try {
        const {
            payload: params,
        } = action;

        const result = yield request.get(`${controller}/getByClientId`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_INSURANCE_BY_CLIENT_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_INSURANCE_BY_CLIENT_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_INSURANCE_BY_CLIENT_ID_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_HISTORY_INSURANCE_REQUEST, getPageInsuranceHistorySaga),
        takeEvery(GET_INSURANCE_BY_CLIENT_ID_REQUEST, getInsuranceByClientIdSaga),
    ]);
}
