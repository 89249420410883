export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE";

export const BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_UPDATE_STORE = "BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_UPDATE_STORE";