import React from "react";

import {ReactComponent as WarningIcon} from "../../../images/scan_solution_icon_warning.svg";
import NmRadioV2 from "../../ActualComponents/NmRadioV2";
import NmCheck from "../../NmCheck";

import bem from "../../../utils/bem";

import "./style.sass";

export type ScanSolutionItem = {
    checked: boolean,
    solutionIcon: object,
    checkIconType?: boolean,
    warningIconType?: boolean,
    onChange: () => void,
    title?: string,
    text?: string
}

const ScanSolutionItem = (props: ScanSolutionItem) => {
    const {
        checked,
        solutionIcon,
        checkIconType,
        warningIconType,
        onChange,
        title,
        text,
    } = props;

    const [block, element] = bem("scan-solution-item");

    return (
        <div>
            <div
                className={block({active: checked})}
                onClick={onChange}
                title={text}
            >
                <NmRadioV2
                    checked={checked}
                    onChange={() => {}}
                />
                <div className={element("icon")}>
                    {solutionIcon}
                    {
                        warningIconType ?
                            <WarningIcon className={element("icon-check")} /> :
                            <NmCheck
                                classNameIcon={element("icon-check")}
                                isCheck={checkIconType}
                            />
                    }
                </div>
            </div>
            {
                title &&
                <div className={element("title")}>
                    {title}
                </div>
            }
        </div>
    );
};

export default ScanSolutionItem;