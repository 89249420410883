import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";

import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";

import {
    formatLocalDate,
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../../utils/dateFormat";
import {handleNumber} from "../../../../../utils/stringHelper";

import {CROWD_ACT_REGISTRY_ENTRY_FIELDS_DICT} from "./constants";

import {
    getPageCrowdActRegistryLog,
    updateStoreCrowdActRegistry,
} from "../../../../../ducks/bff/crowd/actRegistry/actionCreators";
import {
    bffCrowdActRegistryLogListSelector,
    bffCrowdActRegistryLogProgressSelector,
    bffCrowdActRegistryLogTotalCountSelector,
    bffCrowdActRegistryLogTotalPagesSelector,
} from "../../../../../ducks/bff/crowd/actRegistry/selectors";

import {CROWD_ACT_REGISTRY_LOG_TYPE_DICT} from "../../../../../constants/crowd/act-registry";

const CrowdTaskActRegistryLogModal = (props) => {
    const {
        onClose = () => {
        },
        clientId,
        registryId,
        number,
        name,
    } = props;

    const list = useSelector(bffCrowdActRegistryLogListSelector);
    const totalPages = useSelector(bffCrowdActRegistryLogTotalPagesSelector);
    const totalCount = useSelector(bffCrowdActRegistryLogTotalCountSelector);
    const progress = useSelector(bffCrowdActRegistryLogProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({setPagination, pageSize});

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdActRegistry({
                logList: [],
                logTotalCount: 0,
                logPageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        const {
            createDateFrom,
            createDateTo,
            taskNumber,
        } = filterData;

        dispatch(getPageCrowdActRegistryLog({
            pageNum,
            pageSize,
            clientId,
            registryId,
            createDateFrom: getStartFilterDateWithTimeInUtc(createDateFrom),
            createDateTo: getEndFilterDateWithTimeInUtc(createDateTo),
            taskNumbers: taskNumber ? [handleNumber(taskNumber)] : undefined,
        }));
    };

    const getFieldsValue = ({currentField, fields}) => {
        if (currentField === "workStartDate") {
            return `c ${formatLocalDate(fields["workStartDate"])} по ${formatLocalDate(fields["workEndDate"])}`;
        }

        if (currentField === "actFormingDate") {
            return formatLocalDate(fields[currentField]);
        }

        return fields[currentField];
    };

    const getFieldsInfo = ({currentField, fields}) => {
        if (currentField === "workEndDate") {
            return null;
        }

        const label = CROWD_ACT_REGISTRY_ENTRY_FIELDS_DICT[currentField];

        return `${label}: ${getFieldsValue({currentField, fields})}`;
    };

    const getRows = () => {
        return list.map(item => {
            const {
                eventId,
                createDate,
                type,
                additionalInfo,
                initiatorFIO,
            } = item;

            return {
                ...item,
                key: eventId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-md-12 col-xxl-12 col-xl-12"
                        primaryHeader={CROWD_ACT_REGISTRY_LOG_TYPE_DICT[type] || type}
                        labels={[
                            additionalInfo?.rowNumbers?.length && {
                                label: "Номера строк",
                                text: additionalInfo.rowNumbers.join(", "),
                            },
                            additionalInfo?.taskNumbers?.length && {
                                label: "Номера заданий",
                                text: additionalInfo.taskNumbers.join(", "),
                            },
                            additionalInfo?.taskNumber && {
                                label: "Номер задания",
                                text: additionalInfo.taskNumber,
                            },
                            additionalInfo?.rowNumber && {
                                label: "Номер строки",
                                text: additionalInfo.rowNumber,
                            },
                            additionalInfo?.addedCount && {
                                label: "Кол-во добавленных строк",
                                text: additionalInfo.addedCount || 0,
                            },
                            additionalInfo?.fields?.length && {
                                label: "Детали",
                                text: Object.keys(additionalInfo.fields)
                                    .map(item => getFieldsInfo({
                                        currentField: item,
                                        fields: additionalInfo.fields,
                                    }))
                                    .filter(item => Boolean(item))
                                    .join(", "),
                                noWrap: false,
                                inline: true,
                            },
                            {
                                label: "Дата",
                                text: formatLocalDate(createDate, "dd.MM.yyyy HH:mm:ss"),
                            },
                            {
                                label: "Инициатор",
                                text: initiatorFIO || "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    История по реестру #
                    {number}
                    {" "}
- 
                    {" "}
                    {name}
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={
                    <NmForm
                        className="flex align-items-end"
                        horizontal={true}
                    >
                        <div className="col-16 col-md-7 col-xl-5">
                            <NmDateRangePickerV2
                                size="lg"
                                startFieldName="createDateFrom"
                                endFieldName="createDateTo"
                                value={{createDateFrom: filter.createDateFrom, createDateTo: filter.createDateTo}}
                                isClearable
                                label="Период события"
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-4">
                            <NmInputV2
                                size="lg"
                                label="Номер задания"
                                placeholder="Введите номер"
                                name="taskNumber"
                                value={filter.taskNumber}
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-4">
                            <FilterButtonsV2
                                onClear={onClear}
                                onSearch={() => onSearch(filter)}
                            />
                        </div>
                    </NmForm>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        />
                }
            </NmPage>
        </NmModal>
    );
};

export default CrowdTaskActRegistryLogModal;