import {useState} from "react";

export const useEdit = () => {
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const onOpenEdit = (item) => {
        setModalData(item);
        setOpen(true);
    };

    const onCloseEdit = () => {
        setModalData({});
        setOpen(false);
    };

    return {
        open,
        onOpenEdit,
        onCloseEdit,
        modalData,
    };
};