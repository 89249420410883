import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import {isEmpty} from "lodash";

import FinanceLogPopup from "../../containers/finance-log-popup";
import Filter from "../ActualComponents/Filter";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmListCard from "../ActualComponents/NmList/Card";
import CheckboxList from "../CheckboxList";
import ExtLink from "../ExtLink";
import NmBadge from "../NmBadge";
import NmPage from "../NmPage";
import {NmPageHeader} from "../NmPageHeader";

import {useFilter} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {useSystemTransactionsAction} from "./hooks/useAction";
import {useSystemTransactionsFetch} from "./hooks/useFetch";
import {useSystemTransactionsFilter} from "./hooks/useFilter";

import {formatLocalDate} from "../../utils/dateFormat";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {formatAmountWithNullChecking} from "../../utils/stringFormat";

import {BANK_TYPE} from "../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {FINANCE_LOG_TYPE} from "../../constants/financeLogType";
import {LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST, LINK_FORBIDDEN_PAGE} from "../../constants/links";
import {STATUS_OF_TRANSFER_BADGE_COLOR_TRANSCRIPT} from "../../constants/status";
import {BANK_SYSTEM_TRANSACTION_TYPE} from "./constants";

import {
    bffSystemTransactionsListSelector,
    bffSystemTransactionsProgressSelector,
    bffSystemTransactionsTotalCountSelector,
    bffSystemTransactionsTotalPagesSelector,
} from "../../ducks/bff/system-transactions/selectors";
import {currentUserRestrictionsSelector} from "../../ducks/clientUserRestrictions";

import "./style.sass";

const SystemTransactions = (props) => {
    const {
        match: {
            params: {
                clientId,
                transactionNumFilter,
            },
        },
    } = props;

    const role = ls(USER_ROLE);

    const {t} = useTranslation();

    const list = useSelector(bffSystemTransactionsListSelector);
    const progress = useSelector(bffSystemTransactionsProgressSelector);
    const totalPages = useSelector(bffSystemTransactionsTotalPagesSelector);
    const totalCount = useSelector(bffSystemTransactionsTotalCountSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        getMediaControls,
        logModalData,
        setLogModalData,
    } = useSystemTransactionsAction({
        role,
        clientId,
    });

    const {
        initFilter,
        mapFilterDto,
        filters,
    } = useSystemTransactionsFilter({
        transactionNumFilter,
    });

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter,
        mapFilterDto,
        setPagination,
        pageSize,
    });

    useSystemTransactionsFetch({
        filter: filterData,
        pageNum,
        pageSize,
    });

    const getContractorLink = ({fio, contractorId}) => {
        const link = LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST.replace(":contractorId", contractorId);

        return (
            <ExtLink
                pageData={{pageNum, pageSize}}
                filterData={{
                    contractorIdFilter: filter.contractorId,
                }}
                historyEnabled
                to={link}
            >
                {fio}
            </ExtLink>
        );
    };

    const getTransactionRequisites = ({type, withdrawalPhoneOrBankCardNumber}) => {
        if (type?.value === "TRANSFER_CARD") {
            return withdrawalPhoneOrBankCardNumber.substr(withdrawalPhoneOrBankCardNumber.length - 8);
        }

        return withdrawalPhoneOrBankCardNumber;
    };

    const getRows = () => {
        return list.map(item => {
            const {
                transactionNumber,
                status,
                createdAt,
                transactionId,
                amount,
                withdrawalPhoneOrBankCardNumber,
                systemToWalletSum,
                commissionAmount,
                type,
                bankType,
                amountWithoutCommission,
            } = item;

            const amountPerCard = type.value === BANK_SYSTEM_TRANSACTION_TYPE.CROWD_TRANSFER_CARD
                ? amountWithoutCommission
                : amount;

            return {
                ...item,
                key: transactionId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-7"
                        primaryHeader={transactionNumber}
                        secondaryHeader={`Дата транзакции  ${formatLocalDate(createdAt, "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={
                            <NmBadge
                                text={status?.description}
                                mod={STATUS_OF_TRANSFER_BADGE_COLOR_TRANSCRIPT[status?.value] || "gray"}
                            />
                        }
                        labels={[
                            {
                                label: "Тип транзакции",
                                text: `${type?.description} ${withdrawalPhoneOrBankCardNumber ? getTransactionRequisites(item) : ""}`,
                            },
                            {
                                label: "ФИО исполнителя",
                                text: getContractorLink(item),
                            },
                            {
                                label: "Банк",
                                text: BANK_TYPE[bankType]?.TEXT || "-",
                            },
                        ]}
                        cards={[
                            systemToWalletSum && {
                                title: "Списано со счета Наймикс, ₽",
                                value: formatAmountWithNullChecking(systemToWalletSum),
                                className: "col-16 col-md-5 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Сумма на карту, ₽",
                                value: formatAmountWithNullChecking(amountPerCard),
                                className: "col-16 col-md-5 mt-md-4 mt-xxl-0",
                            },
                            commissionAmount && {
                                title: "Сумма комиссии, ₽",
                                value: formatAmountWithNullChecking(commissionAmount),
                                className: "col-16 col-md-5 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-8 align-items-end"
                        actionsClassName="col-1"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderLogModal = () => {
        return (
            !isEmpty(logModalData) &&
            <FinanceLogPopup
                handleClose={() => setLogModalData({})}
                logType={FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG}
                logData={logModalData}
            />
        );
    };

    if (currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.accessSystemTransactions)) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    return (
        <NmPage
            className="system-transactions"
            isLoaded={progress}
            header={
                <NmPageHeader
                    text={t("system-transactions.header")}
                    totalCount={totalCount}
                />
            }
            showHeaderBlock={true}
            typeFilter={"vertical"}
            filtersBase={
                <Filter
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        >
            {renderLogModal()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};

export default SystemTransactions;