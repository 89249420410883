import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../../../utils/toastHelper";

import {importRecruitmentVacancy} from "../../../../../../ducks/job";

export const useRecruitmentVacancyImportForm = (props) => {
    const {
        clientId,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const onSubmit = ({file}) => {
        const formData = new FormData();

        formData.append("mFile", file);
        formData.append("clientId", clientId);

        dispatch(importRecruitmentVacancy({
            formData,
            onSuccess: () => {
                onClose();
                toastSuccess("Загрузка файла успешно завершена. Новые вакансии станут доступны через некоторое время.");
            },
        }));
    };

    return {
        onSubmit,
    };
};