import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../../constants/clientList";
import {CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS} from "../constants";

// обрабатываем типы файла, полученные с бэка в нужный порядок
export const getAdditionalDocumentVerificationFiles = (params) => {
    const {
        fileType,
        pendingFileTypes,
    } = params;

    if (
        [CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_BOOK].includes(fileType)
    ) {
        return CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[fileType].scans
            .filter(fileType => {
                // Для медецинской книжки всегда отоброжаем разворот главной страницы
                if ([CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN].includes(fileType)) {
                    return fileType;
                }

                // Скан с qr отображаем по наличию скана
                return pendingFileTypes.includes(fileType);
            });
    }

    return CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[fileType].scans;
};