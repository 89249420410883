import React, {FC} from "react";

import {ReactComponent as IconClose} from "../../../../images/close.svg";

import "./style.sass";

interface IDropdownSelectedValue {
    text?: string,
    onClickDelete: () => void,
    classNames?: {
        block?: string,
        text?: string,
        icon?: string,
    },
    disabled?: boolean,
}

export const DropdownSelectedValue: FC<IDropdownSelectedValue> = (props) => {
    const {
        classNames,
        text,
        onClickDelete,
        disabled,
    } = props;

    return (
        <div
            className={`dropdown-selected-value ${classNames?.block}`}
            title={typeof text === "string" ? text : ""}
        >
            <div className={`dropdown-selected-value__text ${classNames?.text}`}>
                {text}
            </div>
            {
                !disabled &&
                <IconClose
                    onClick={onClickDelete}
                    className={`dropdown-selected-value__icon ${classNames?.icon}`}
                />
            }
        </div>
    );
};
