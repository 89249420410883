import {
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_REQUEST,
    UPDATE_PLUTONIUM_TRANSACTION_LOG_STORE,
} from "./actions";

export const getPagePlutoniumTransactionLog = (payload) => {
    return {
        type: BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateStorePatent = (payload) => {
    return {
        type: UPDATE_PLUTONIUM_TRANSACTION_LOG_STORE,
        payload,
    };
};