import React from "react";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import useDataForm from "./hooks/useDataForm";
import useEditFormWarning from "./hooks/useEditFormWarning";
import useReadFormWarning from "./hooks/useReadFormWarning";

import {formatDateWithoutTime} from "../../../../utils/dateFormat";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {CONTRACTOR_CARD_FIELDS} from "../../../../constants/contractorInfo";

import {
    pendingPersonalDataSelector,
} from "../../../../ducks/contractor";

import "./style.sass";

const VhiPolicy2 = (props) => {
    const {
        isEditable,
        className,
        card,
    } = props;

    const {data} = useSelector(pendingPersonalDataSelector);
    const {pendingPersonalDataFields = []} = data || {};

    const {
        values,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        isEdit,
        toggleCard,
        cancelEdit,
    } = useDataForm(card);

    const {warning} = useEditFormWarning(values, card);

    const {warningReadOnlyCard} = useReadFormWarning(values, card);

    const {citizenship} = card;
    const isUzOrTjOrUa = [citizenshipsDict.UZ.value, citizenshipsDict.UA.value, citizenshipsDict.TJ.value].includes(citizenship);

    const renderText = (value) => {
        return value || "Не указан";
    };

    const hasBorder = () => CONTRACTOR_CARD_FIELDS.VHI_POLICY_INFO.some(field => pendingPersonalDataFields.includes(field));

    const onChange = (e, {name, value, checked}) => {
        if (name === CONTRACTOR_FIELD.VHI_POLICY_PRESENT) {
            setFieldValue(CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE, null);
            setFieldValue(CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE, null);
        }

        setFieldValue(name, value || checked);
    };

    const renderCardReadOnly = () => {
        return (
            <>
                {
                    !isUzOrTjOrUa &&
                    <NmCheckboxV2
                        className="vhi-policy__item vhi-policy__item_full-width"
                        label="Договор ДМС или полис ДМС присутствует"
                        checked={values[CONTRACTOR_FIELD.VHI_POLICY_PRESENT]}
                        disabled={true}
                        onChange={() => {}}
                    />
                }
                {
                    (isUzOrTjOrUa || !isUzOrTjOrUa && values[CONTRACTOR_FIELD.VHI_POLICY_PRESENT]) &&
                    <>
                        <LabelTextApp
                            className="snils-info__item"
                            label="Дата начала действия договора"
                            text={renderText(formatDateWithoutTime(values[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]))}
                        />
                        <LabelTextApp
                            label="Дата окончания действия договора"
                            text={renderText(formatDateWithoutTime(values[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]))}
                        />
                    </>
                }
            </>
        );
    };

    const renderCardEdit = () => {
        return (
            <NmForm className="fluid">
                {
                    !isUzOrTjOrUa &&
                    <NmCheckboxV2
                        className="vhi-policy__item vhi-policy__item_full-width"
                        name={CONTRACTOR_FIELD.VHI_POLICY_PRESENT}
                        label="Договор ДМС или полис ДМС присутствует"
                        onChange={onChange}
                        checked={values[CONTRACTOR_FIELD.VHI_POLICY_PRESENT]}
                    />
                }
                {
                    (isUzOrTjOrUa || !isUzOrTjOrUa && values[CONTRACTOR_FIELD.VHI_POLICY_PRESENT]) &&
                    <>
                        <div className="row vhi-policy__item">
                            <NmDatePicker
                                size="lg"
                                required
                                dateFormatMask="99.99.9999"
                                label="Дата начала действия договора"
                                maxDate={values[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE] || new Date()}
                                name={CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE}
                                onChange={onChange}
                                onBlur={() => setFieldTouched(CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE, true)}
                                selected={values[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]}
                                isClearable
                                error={touched && touched[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE] ?
                                    errors && errors[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE] :
                                    undefined
                                }
                            />
                        </div>
                        <div className="row vhi-policy__item">
                            <NmDatePicker
                                size="lg"
                                required
                                dateFormatMask="99.99.9999"
                                label="Дата окончания действия договора"
                                minDate={values[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE] || new Date()}
                                name={CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE}
                                onChange={onChange}
                                onBlur={() => setFieldTouched(CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE, true)}
                                selected={values[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]}
                                isClearable
                                error={touched && touched[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE] ?
                                    errors && errors[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE] :
                                    undefined
                                }
                                warning={warning && warning[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]}
                            />
                        </div>
                    </>
                }
            </NmForm>
        );
    };

    return (
        <CardApp
            className={className}
            border={hasBorder()}
            title="Договор ДМС или полис ДМС"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
            warning={warningReadOnlyCard}
        >
            {isEdit ? renderCardEdit() : renderCardReadOnly()}
        </CardApp>
    );
};

export default VhiPolicy2;