import React from "react";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmTitle from "../../../../../../components/NmTitle";

import {formatLocalDate} from "../../../../../../utils/dateFormat";
import {formatAmount} from "../../../../../../utils/stringFormat";

import {SYSTEM_TRANSACTION_TYPE} from "../../../../../../constants/finance";

const ProcessPaymentListModal = (props) => {
    const {
        onClose = () => {
        },
        crowdPayments = [],
        taxPayments = [],
    } = props;

    const getRows = (list) => {
        return list.map(item => {
            const {
                type,
                amount,
                paymentNumber,
                createdAt,
            } = item;

            return {
                ...item,
                key: createdAt,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Операция",
                                text: SYSTEM_TRANSACTION_TYPE[type] || type,
                            },
                            {
                                label: "Номер транзакции",
                                text: paymentNumber || "-",
                            },
                            {
                                label: "Дата запуска",
                                text: formatLocalDate(createdAt, "dd.MM.yyyy HH:mm:ss"),
                            },
                            {
                                label: "Сумма",
                                text: formatAmount(amount, true),
                            },
                        ]}
                    />
                ),
            };
        });
    };

    const renderList = (title, list) => {
        return (
            <div className="flex flex-column col-16 mt-4">
                <NmTitle
                    size="md"
                    className="mb-4"
                >
                    {title}
                </NmTitle>
                <CheckboxList
                    rows={getRows(list)}
                />
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Операции «в процессе»
                </NmTitle>
            }
        >
            {
                Boolean(taxPayments.length) &&
                renderList("По налогам", taxPayments)
            }
            {
                Boolean(crowdPayments.length) &&
                renderList("По заданиям", crowdPayments)
            }
            {
                !taxPayments.length &&
                !crowdPayments.length &&
                <NmEmptyPageV2 />
            }
        </NmModal>
    );
};

export default ProcessPaymentListModal;