import {all, put} from "@redux-saga/core/effects";
import {takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/contractors-registry/contractor/finance/income-individual";

//*  TYPES  *//

const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_REQUEST = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_REQUEST";
const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_SUCCESS = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_ERROR = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_ERROR";

const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_REQUEST = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_REQUEST";
const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_SUCCESS = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_ERROR = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_ERROR";

const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_REQUEST = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_REQUEST";
const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_SUCCESS = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_SUCCESS";
const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_ERROR = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_ERROR";

const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_REQUEST = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_REQUEST";
const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_SUCCESS = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_SUCCESS";
const GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_ERROR = "GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_ERROR";

const CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_UPDATE_STORE = "CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_UPDATE_STORE";

//*  INITIAL STATE  *//
const initial = {
    list: [],
    totalCount: 0,
    progress: false,
    pageData: {},
    contractorBalance: {},
};

//*  REDUCER  *//
export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_REQUEST:
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_REQUEST:
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_SUCCESS:
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_SUCCESS:
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_SUCCESS:
        return {
            ...state,
            list: payload.results,
            progress: false,
            totalCount: payload.totalCount,
        };
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_ERROR:
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_ERROR:
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_SUCCESS:
        return {
            ...state,
            contractorBalance: payload,
        };
    case CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorInProgressFinanceIndividualPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_REQUEST,
        payload,
    };
}

export function getContractorAwaitingFinanceIndividualPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_REQUEST,
        payload,
    };
}

export function getContractorCompletedFinanceIndividualPayments(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_REQUEST,
        payload,
    };
}

export function getSummaryContractorFinanceIncomeIndividual(payload) {
    return {
        type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_REQUEST,
        payload,
    };
}

export function updateContractorFinanceIncomeIndividualStore(payload) {
    return {
        type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_UPDATE_STORE,
        payload,
    };
}

//*  SELECTORS  *//
export const contractorFinanceIncomeIndividualSelector = state => state.contractorFinanceIncomeIndividual;
export const contractorFinanceIncomeIndividualProgressSelector = createSelector(contractorFinanceIncomeIndividualSelector, ({progress}) => progress);
export const contractorFinanceIncomeIndividualListSelector = createSelector(contractorFinanceIncomeIndividualSelector, ({list}) => list);
export const contractorFinanceIncomeIndividualTotalCountSelector = createSelector(contractorFinanceIncomeIndividualSelector, ({totalCount}) => totalCount);
export const contractorFinanceIncomeIndividualTotalPagesSelector = createSelector(contractorFinanceIncomeIndividualSelector, ({totalCount, pageData: {pageSize = 0}}) => {
    return getTotalPages(totalCount, pageSize);
});
export const contractorSummaryFinanceIncomeIndividualSelector = createSelector(contractorFinanceIncomeIndividualSelector, ({contractorBalance}) => contractorBalance);

//POST bff/adm/contractors-registry/contractor/finance/income-individual/in-progress/page
export const getContractorInProgressFinanceIndividualPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/in-progress/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/contractors-registry/contractor/finance/income-individual/awaiting/page
export const getContractorAwaitingFinanceIndividualPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/awaiting/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/contractors-registry/contractor/finance/income-individual/completed/page
export const getContractorCompletedFinanceIndividualPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/completed/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/contractors-registry/contractor/finance/income-individual/getSummary/{contractorId}
export const getSummaryContractorFinanceIncomeIndividualSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getSummary/${contractorId}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_IN_PROGRESS_REQUEST, getContractorInProgressFinanceIndividualPaymentsSaga),
        takeEvery(CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_AWAITING_PROGRESS_REQUEST, getContractorAwaitingFinanceIndividualPaymentsSaga),
        takeEvery(CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_COMPLETED_REQUEST, getContractorCompletedFinanceIndividualPaymentsSaga),
        takeEvery(GET_SUMMARY_CONTRACTOR_FINANCE_INCOME_INDIVIDUAL_REQUEST, getSummaryContractorFinanceIncomeIndividualSaga),
    ]);
}