import {PAYMENT_METHOD_OPTION_DICT} from "../constants/clientSettings";
import {ORDER_WORK_REPORT_TYPE} from "../constants/finance";
import {PAYMENT_TYPE} from "../constants/settings";

const getPaymentMethodOptions = (props) => {
    const {
        bankTypeCard,
        bankTypeSbp,
        bankTypeRequisites,
    } = props || {};
    return [
        Boolean(bankTypeCard) && PAYMENT_METHOD_OPTION_DICT.CARD_LOCAL,
        Boolean(bankTypeSbp) && PAYMENT_METHOD_OPTION_DICT.SBP,
        Boolean(bankTypeRequisites) && PAYMENT_METHOD_OPTION_DICT.BANK_REQUISITES,
    ].filter(item => Boolean(item));
};

export const getPaymentMethodOptionsByClientPaymentsSettings = ({paymentsSettings, paymentType, contractType}) => {
    const {
        smzRegistry = {},
        civilRegistry = {},
        smzMarketplace = {},
        civilMarketplace = {},
        individualRegistry = {},
        individualMarketplace = {},
    } = paymentsSettings || {};

    if (contractType === ORDER_WORK_REPORT_TYPE.CIVIL) {
        const settingsBlock = paymentType === PAYMENT_TYPE.REGISTRY ? civilRegistry : civilMarketplace;

        return getPaymentMethodOptions(settingsBlock);
    }

    if (contractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
        const settingsBlock = paymentType === PAYMENT_TYPE.REGISTRY ? individualRegistry : individualMarketplace;

        return getPaymentMethodOptions(settingsBlock);
    }

    const settingsBlock = paymentType === PAYMENT_TYPE.REGISTRY ? smzRegistry : smzMarketplace;

    return getPaymentMethodOptions(settingsBlock);
};

export const getSameBankTypeCard = ({paymentsSettings, contractType}) => {
    const {
        smzRegistry,
        smzMarketplace = {},
        smzApi = {},
        civilRegistry = {},
        civilMarketplace = {},
        civilApi = {},
    } = paymentsSettings || {};

    if (
        contractType === ORDER_WORK_REPORT_TYPE.CIVIL &&
        civilRegistry.bankTypeCard === civilMarketplace.bankTypeCard &&
        civilMarketplace.bankTypeCard === civilApi.bankTypeCard
    ) {
        return civilRegistry.bankTypeCard;
    }

    if (
        smzRegistry.bankTypeCard === smzMarketplace.bankTypeCard &&
        smzMarketplace.bankTypeCard === smzApi.bankTypeCard
    ) {
        return smzRegistry.bankTypeCard;
    }

    return null;
};
