import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositList from "../list";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {
    addCivilClientDeposit,
    civilCommissionHistoryListSelector,
    civilCommissionHistoryProgressSelector,
    civilCommissionHistoryTotalCountSelector,
    civilCommissionHistoryTotalPagesSelector,
    civilDepositListSelector,
    civilDepositListTotalCountSelector,
    civilDepositListTotalPagesSelector,
    civilDepositProgressListSelector,
    civilProgressActionSelector,
    getCivilCommissionHistoryList,
    getCivilDepositList,
    returnCivilClientDeposit,
    updateCivilStore,
} from "../../../ducks/civil";
import {getRichContractorCommissionHistory} from "../../../ducks/deposit";

const DepositCivilAdminList = (props) => {
    const dispatch = useDispatch();

    const progressCivilAction = useSelector(civilProgressActionSelector);
    const list = useSelector(civilDepositListSelector);
    const totalPages = useSelector(civilDepositListTotalPagesSelector);
    const totalCount = useSelector(civilDepositListTotalCountSelector);
    const progress = useSelector(civilDepositProgressListSelector);

    // История изменения комиссиии
    const historyList = useSelector(civilCommissionHistoryListSelector);
    const historyTotalPages = useSelector(civilCommissionHistoryTotalPagesSelector);
    const historyProgress = useSelector(civilCommissionHistoryProgressSelector);
    const historyTotalCount = useSelector(civilCommissionHistoryTotalCountSelector);

    const onFetchList = ({nameSubstringFilter, pageNum, pageSize}) => {
        dispatch(getCivilDepositList({
            nameSubstringFilter,
            pageNum,
            pageSize,
        }));
    };

    const onFetchCommissionHistory = (reqData) => {
        const {commissionType} = reqData;

        if (!isNullOrWhitespace(commissionType)) {
            dispatch(getRichContractorCommissionHistory({
                isCivil: true,
                ...reqData,
            }));

            return;
        }

        dispatch(getCivilCommissionHistoryList(reqData));
    };

    const onUpdateStore = (data) => {
        dispatch(updateCivilStore(data));
    };

    const onAddDeposit = (data) => {
        dispatch(addCivilClientDeposit(data));
    };

    const onReturnDeposit = (data) => {
        dispatch(returnCivilClientDeposit(data));
    };

    return (
        <DepositList
            {...props}
            isCivil
            header="Депозит (НДФЛ)"
            commissionHistory={{
                list: historyList,
                totalPages: historyTotalPages,
                historyProgress: historyProgress,
                historyTotalCount: historyTotalCount,
                onFetchList: onFetchCommissionHistory,
                onUpdateStore,
            }}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            onAddDeposit={onAddDeposit}
            onReturnDeposit={onReturnDeposit}
            onFetchList={onFetchList}
            progressAction={progressCivilAction}
            progressList={progress}
        />
    );
};

export default DepositCivilAdminList;