export const getAddress = data => {
    const {
        address: {
            state,
            city,
            road,
            city_district,
            house_number,
        },
    } = data;

    // Регион = назвагнию города (state = city)
    if (["Москва", "Севастополь", "Санкт-Петербург"].includes(state)) {
        const addressElements = [city || "", road || "", city_district || "", house_number || ""];
        const filteredAddress = addressElements.filter(value => value.length !== 0);

        return filteredAddress.join(", ");
    }

    const addressElements = [state || "", city || "", road || "", city_district || "", house_number || ""];
    const filteredAddress = addressElements.filter(value => value.length !== 0);

    return filteredAddress.join(", ");
};