import {isEmpty} from "lodash";
import * as yup from "yup";

import {maxSizeScanFile } from "../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationTerminateContract = () => {
    return yup.object().shape({
        appDateTime: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        terminationDateTime: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        notices: yup.mixed()
            .test("notices", VALIDATIONS_MESSAGE.MAX_SIZE_10MB, function (files) {
                const maxSizeInvalidFiles = [];

                files.forEach(file => {
                    if (file.size > maxSizeScanFile) {
                        maxSizeInvalidFiles.push(file.name);
                    }
                });

                if (!isEmpty(maxSizeInvalidFiles)) {
                    return this.createError({
                        message: `Файлы: "${maxSizeInvalidFiles.join(", ")}" превышают максимальный допустимый размер файла - 10Мб`,
                        path: "notices",
                    });
                }
                return true;
            }),

    });

};

export default validationTerminateContract;