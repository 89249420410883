import {useEffect} from "react";
import {useMemo} from "react";
import {useDispatch} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {SimpleWithEmptyDropdownOptions} from "../../../../../constants/contractorList";

import {
    getPageDictionaryFlashCall,
    updateCommonPaymentDicts,
} from "../../../../../ducks/bff/common/dicts/actionCreators";

export const useSettingsDirectoriesFlashCallFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                flashCallPageData: {},
                flashCallList: [],
                flashCallTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter]);

    const fetchList = () => {
        dispatch(getPageDictionaryFlashCall({
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "countryNameFilter",
                        label: "Страна",
                        placeholder: "Введите страну",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "phoneCodeFilter",
                        label: "Код",
                        placeholder: "Введите код",
                        maskChar: null,
                        mask: "99999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Возможность звонков",
                        placeholder: "Все",
                        name: "callsSupported",
                        options: SimpleWithEmptyDropdownOptions,
                    },
                ],
            },
        ];
    }, []);

    return {
        filters,
        fetchList,
    };
};