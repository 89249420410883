import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {REGISTRY_TOAST_TEXT} from "../constants/registry";

const controller = "/email";

const EMAIL_INVITATION_SEND_REQUEST = "EMAIL_INVITATION_SEND_REQUEST";
const EMAIL_INVITATION_SEND_SUCCESS = "EMAIL_INVITATION_SEND_SUCCESS";
const EMAIL_INVITATION_SEND_ERROR = "EMAIL_INVITATION_SEND_ERROR";

const EMAIL_COMPLAINT_SEND_REQUEST = "EMAIL_COMPLAINT_SEND_REQUEST";
const EMAIL_COMPLAINT_SEND_SUCCESS = "EMAIL_COMPLAINT_SEND_SUCCESS";
const EMAIL_COMPLAINT_SEND_ERROR = "EMAIL_COMPLAINT_SEND_ERROR";

const initial = {
    invitationProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case EMAIL_INVITATION_SEND_REQUEST:
        return {
            ...state,
            invitationProgress: true,
        };
    case EMAIL_INVITATION_SEND_SUCCESS:
        return {
            ...state,
            invitationProgress: false,
        };
    case EMAIL_INVITATION_SEND_ERROR:
        return {
            ...state,
            error: payload,
        };
    case EMAIL_COMPLAINT_SEND_ERROR:
        return {
            ...state,
            error: payload,
        };
    default:
        return state;
    }
};

export const inviteViaEmail = payload => {
    return {
        type: EMAIL_INVITATION_SEND_REQUEST,
        payload,
    };
};

export const complaintByEmail = payload => {
    return {
        type: EMAIL_COMPLAINT_SEND_REQUEST,
        payload,
    };
};

export const emailSelector = state => state.email;
export const emailInvitationProgressSelector = createSelector(emailSelector, ({invitationProgress}) => invitationProgress);

const inviteViaEmailSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const {errorMessage} = yield request.post(`${controller}/invitationSendMail/send`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EMAIL_INVITATION_SEND_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(REGISTRY_TOAST_TEXT.SUCCESS_INVITE_VIA_EMAIL);
        yield put({type: EMAIL_INVITATION_SEND_SUCCESS});
    } catch (error) {
        yield put({type: EMAIL_INVITATION_SEND_ERROR, payload: error});
    }
};


// POST /api/email/complaintEmail/send
const complaintByEmailSaga = function* ({payload}) {
    const {
        data,
        onSuccess,
        onError,
    } = payload;

    try {
        const {errorMessage} = yield request.post(`${controller}/complaintEmail/send`, data);

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: EMAIL_COMPLAINT_SEND_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Жалоба на исполнителя успешно создана и в ближайшее время будет обработана");
        onSuccess();

        yield put({
            type: EMAIL_COMPLAINT_SEND_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        onError();

        yield put({
            type: EMAIL_COMPLAINT_SEND_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(EMAIL_INVITATION_SEND_REQUEST, inviteViaEmailSaga),
        takeEvery(EMAIL_COMPLAINT_SEND_REQUEST, complaintByEmailSaga),
    ]);
}