import React from "react";
import {useTranslation} from "react-i18next";

import NmTitle from "../../../../components/NmTitle";

import bem from "../../../../utils/bem";
import {getOrderEditHeader} from "../utils/getHeader";

import "./style.sass";

const OrderEditHeader = props => {
    const {
        creatingMode,
        editOrderId,
        isDuplicateOrder,
        className,
    } = props;
    const [block, element] = bem("order-edit-header", className);
    const {t} = useTranslation();

    return (
        <div className={block()}>
            <div className={element("title-row")}>
                <div className={element("title-container")}>
                    <NmTitle size="lg">
                        {getOrderEditHeader({
                            creatingMode,
                            editOrderId,
                            isDuplicateOrder,
                            t,
                        })}
                    </NmTitle>
                </div>
            </div>
        </div>
    );
};

export default OrderEditHeader;