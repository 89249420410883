import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {SETTINGS_INFO_MESSAGE} from "../constants/messages";

const controller = "/naimixInfo";
//*  TYPES  *//

const NAIMIX_INFO_READ_REQUEST = "NAIMIX_INFO_READ_REQUEST";
const NAIMIX_INFO_READ_SUCCESS = "NAIMIX_INFO_READ_SUCCESS";
const NAIMIX_INFO_READ_ERROR = "NAIMIX_INFO_READ_ERROR";

const NAIMIX_INFO_UPDATE_REQUEST = "NAIMIX_INFO_UPDATE_REQUEST";
const NAIMIX_INFO_UPDATE_SUCCESS = "NAIMIX_INFO_UPDATE_SUCCESS";
const NAIMIX_INFO_UPDATE_ERROR = "NAIMIX_INFO_UPDATE_ERROR";

const NAIMIX_INFO_UPDATE_FIELD_STORE = "NAIMIX_INFO_UPDATE_FIELD_STORE";

//*  INITIAL STATE  *//

const initial = {
    card: {},
    error: null,
    progress: false,
    currentInfo: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case NAIMIX_INFO_UPDATE_FIELD_STORE:
        const {
            field,
            value,
        } = payload;
        return {
            ...state,
            progress: false,
            [field]: value,
        };
    case NAIMIX_INFO_UPDATE_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case NAIMIX_INFO_READ_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case NAIMIX_INFO_UPDATE_SUCCESS:
        return {
            ...state,
            progress: false,
            progressUpdate: false,
        };
    case NAIMIX_INFO_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            card: payload.naimixInfo,
        };
    case NAIMIX_INFO_UPDATE_ERROR:
    case NAIMIX_INFO_READ_ERROR:
        return {
            ...state,
            progress: false,
            progressUpdate: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function getNaimixInfo(payload) {
    return {
        type: NAIMIX_INFO_READ_REQUEST,
        payload,
    };
}

export function updateFieldNaimixInfoStore(field, value) {
    return {
        type: NAIMIX_INFO_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function updateNaimixInfo(payload) {
    return {
        type: NAIMIX_INFO_UPDATE_REQUEST,
        payload,
    };
}


//*  SELECTORS  *//
export const naimixInfoSelector = state => state.naimixInfo;

export const getNaimixInfoCardSelector = createSelector(naimixInfoSelector, ({card}) => card);

export const naimixInfoProgressSelector = createSelector(naimixInfoSelector, ({progress}) => progress);

export const naimixInfoProgressUpdateSelector = createSelector(naimixInfoSelector, ({progressUpdate}) => progressUpdate);

export const naimixInfoErrorSelector = createSelector(naimixInfoSelector, state => {
    return state.error;
});

//*  SAGA  *//

export const naimixInfoReadSaga = function* (action) {
    try {
        const result = yield request.post(`${controller}/load`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: NAIMIX_INFO_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: NAIMIX_INFO_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: NAIMIX_INFO_READ_ERROR,
            payload: error,
        });
    }
};


export const updateNaimixInfoSaga = function* (action) {
    try {
        const {payload} = action;

        const {errorMessage} = yield request.post(`${controller}/save`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: NAIMIX_INFO_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(SETTINGS_INFO_MESSAGE.SETTINGS_INFO_SUCCESS_TEXT);
        yield put({
            type: NAIMIX_INFO_UPDATE_SUCCESS,
        });

        yield put(getNaimixInfo());
    } catch (error) {
        toastError(SETTINGS_INFO_MESSAGE.SETTINGS_INFO_ERROR_TEXT);
        yield put({
            type: NAIMIX_INFO_UPDATE_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(NAIMIX_INFO_READ_REQUEST, naimixInfoReadSaga),
        takeEvery(NAIMIX_INFO_UPDATE_REQUEST, updateNaimixInfoSaga),
    ]);
}
