import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {isEmpty} from "lodash";

import DropzoneV2 from "../ActualComponents/DropzoneV2";
import NmButton from "../NmButton";

import {isNullOrWhitespace} from "../../utils/stringHelper";

import "./style.sass";

const NmDropzoneApiImages = (props) => {
    const {
        maxImgCount = 10,
        onGetFile,
        docMap,
        isDownload = true,
        isFileNameListIncludesBase64str,
        isFetchFullImage,
        isAddFilesByOnDrop,
        required,
        label,
        tooltip,
        isVisibleTooltip,
        isVisibleLabel,
        isClearFilesTrigger,
        isShowDeleteButton,
        isShowDropzone,
        onDownloadFile,
        onDeleteFile,
        onChange,
        fileNameList,
        onUploadFile,
    } = props;
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (isClearFilesTrigger) {
            setFiles([]);
        }
    }, [isClearFilesTrigger]);

    useEffect(() => {
        if (!isEmpty(fileNameList) && !isFileNameListIncludesBase64str) {
            fileNameList.forEach((fileName) => {
                if (isNullOrWhitespace(docMap[fileName])) {
                    onGetFile({fileName});
                }
            });
        }
    }, [fileNameList]);

    const onDropFile = files => {
        if (isAddFilesByOnDrop) {
            onUploadFile(files);

            return;
        }

        if (onChange) {
            onChange(files);
        }

        setFiles(files);
    };

    const getImageSource = (fileName) => {
        if (docMap[fileName] && docMap[fileName] !== true) {
            return {
                base64str: docMap[fileName],
                isProgress: false,
            };
        }

        return {
            base64str: docMap[fileName],
            isProgress: true,
        };
    };

    const onOpenFullImage = ({name}) => {
        if (!isFetchFullImage) {
            return;
        }

        onGetFile({fileName: name, thumbnail: false});
    };

    const onCloseFullImage = ({name}) => {
        if (!isFetchFullImage) {
            return;
        }

        onGetFile({fileName: name, thumbnail: true});
    };

    const getRenderedFiles = () => {
        if (isFileNameListIncludesBase64str) {
            return fileNameList;
        }

        return props.fileNameList.map((name = "") => {
            const {
                base64str,
                isProgress,
            } = getImageSource(name);

            return {
                name,
                type: "image/",
                isRemote: true,
                base64str,
                isProgress,
            };
        });
    };

    const uploadFile = () => {
        onUploadFile(files);

        setFiles([]);
    };

    const renderDropZone = () => {
        const additionalFiles = getRenderedFiles();

        return (
            <DropzoneV2
                className="mb-3"
                format="*.bmp, *.png, *.jpeg, *.jpg"
                label={label}
                maxSize={10}
                files={files}
                required={required}
                tooltip={tooltip}
                isDownload={isDownload}
                isShowDropzone={isShowDropzone && files.length < maxImgCount && fileNameList.length < maxImgCount}
                isVisibleTooltip={isVisibleTooltip}
                isVisibleLabel={isVisibleLabel}
                isShowDeleteButton={isShowDeleteButton}
                onCloseFullImage={onCloseFullImage}
                onOpenFullImage={onOpenFullImage}
                onDownload={onDownloadFile}
                onDeleteFile={onDeleteFile}
                isImagePreview
                onChange={onDropFile}
                additionalFiles={additionalFiles}
                multiple={true}
            />
        );
    };

    const getUploadButton = () => {
        if (isEmpty(files)) {
            return null;
        }

        return (
            <NmButton
                color="green"
                size="xl"
                className="mt-2"
                onClick={uploadFile}
            >
                Отправить файл
            </NmButton>
        );
    };

    return (
        <>
            {renderDropZone()}
            {getUploadButton()}
        </>
    );
};

export default withTranslation()(NmDropzoneApiImages);
