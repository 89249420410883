import React from "react";

import TemplatesContracts from "../contracts";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

const TemplatesActsTasks = (props) => {
    return (
        <TemplatesContracts
            headerText="Акты по заданиям"
            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK}
            {...props}
        />
    );
};

export default TemplatesActsTasks;