import React from "react";

import {RECRUITMENT_SOCIAL_NETWORKS} from "../../constants/recruitment";
import {ReactComponent as BehanceIcon} from "../../images/social-networks/behance.svg";
import {ReactComponent as FacebookIcon} from "../../images/social-networks/facebook.svg";
import {ReactComponent as InstagramIcon} from "../../images/social-networks/instagram.svg";
import {ReactComponent as LinkedInIcon} from "../../images/social-networks/linkedIn.svg";
import {ReactComponent as OkIcon} from "../../images/social-networks/ok.svg";
import {ReactComponent as SkypeIcon} from "../../images/social-networks/skype.svg";
import {ReactComponent as TelegramIcon} from "../../images/social-networks/telegram.svg";
import {ReactComponent as TenChatIcon} from "../../images/social-networks/tenChat.svg";
import {ReactComponent as VkIcon} from "../../images/social-networks/vk.svg";
import {ReactComponent as WhatsappIcon} from "../../images/social-networks/whatsapp.svg";
import {ReactComponent as YandexDzenIcon} from "../../images/social-networks/yandexDzen.svg";
import DictIcon, {TIconDict} from "../ActualComponents/DictIcon";

const iconDict: TIconDict = {
    [RECRUITMENT_SOCIAL_NETWORKS.WHATSAPP]: WhatsappIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.TELEGRAM]: TelegramIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.VK]: VkIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.OK]: OkIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.SKYPE]: SkypeIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.INSTAGRAM]: InstagramIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.FACEBOOK]: FacebookIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.LINKEDIN]: LinkedInIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.YANDEX_ZEN]: YandexDzenIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.BEHANCE]: BehanceIcon,
    [RECRUITMENT_SOCIAL_NETWORKS.TEN_CHAT]: TenChatIcon,
};

interface ISocialNetworkLogo extends React.SVGProps<SVGSVGElement> {
    name: string,
}

const SocialNetworkLogo = (props: ISocialNetworkLogo) => {
    return (
        <DictIcon
            dict={iconDict}
            {...props}
        />
    );
};

export default SocialNetworkLogo;