import React from "react";

import NmModal from "../../../../components/ActualComponents/NmModal";
import {RESULT_STATUS} from "../../../../components/BankIndicator";
import BankIndicatorIcon from "../../../../components/BankIndicator/components/icon";
import NmTitle from "../../../../components/NmTitle";

export const OrderPayPopupErrors = ({errors, onClose}) => {
    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Ошибки при формировании акта
                </NmTitle>
            }
            onClose={onClose}
            classNameContent="flex-column"
        >
            {
                errors.map(({errorCode, errorMessage}, index) => {
                    return (
                        <div
                            key={errorCode || index}
                            className="d-flex align-items-start mb-2 mb-md-4"
                        >
                            <BankIndicatorIcon
                                size="lg"
                                className="me-2"
                                result={RESULT_STATUS.CHECK_FAILED}
                            />
                            {errorMessage}
                        </div>
                    );
                })
            }
        </NmModal>
    );
};