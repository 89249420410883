import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import TemplatesDropdown from "../../../../components/TemplatesDropdown";
import UnavailableTemplates from "../../../../components/UnavailableTemplates";
import {getValidationScheme} from "./validation";

import {useUnavailableTemplates} from "../../../../components/UnavailableTemplates/hooks/useTemplates";

import {getUnavailableTemplatesFlags} from "../../../../components/UnavailableTemplates/utils";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getPaymentMethodOptionsByClientPaymentsSettings} from "../../../../utils/paymentMethodHelper";
import {handleOrderActRegistryFrom} from "./utils/dto";

import {
    UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE,
} from "../../../../components/UnavailableTemplates/constants";
import {DOCUMENT_TEMPLATES_LIST_FIELD, DOCUMENT_TYPE} from "../../../../constants/documentType";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {ORDER_KIND} from "../../../../constants/order";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    isUserFromNm,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";
import {PAYMENT_TYPE} from "../../../../constants/settings";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../templates/constants";
import {ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS} from "../../order-edit/constants";

import {
    actRegistriesCardSelector,
    actRegistriesProgressActionSelector,
    actRegistriesProgressCardSelector,
    addActRegistry,
    getActRegistry,
    updateActRegistriesStore,
    updateActRegistry,
} from "../../../../ducks/actRegistries";
import {
    getClientsSettingsPayments,
    updateStoreClientsSetting,
} from "../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsPaymentsProgressSelector,
    bffClientsSettingsPaymentsSelector,
} from "../../../../ducks/bff/clients/settings/selectors";
import {clientMemberOptionsSelector, getClientMemberList} from "../../../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {
    actTemplatesOptionsSelector,
    updateDocumentCustomTemplateStore,
} from "../../../../ducks/documentsCustomTemplate";

const OrderActRegistryEdit = (props) => {
    const {
        onClose,
        onFetchList,
        clientId,
        role,
        editData,
    } = props;

    const dispatch = useDispatch();

    const paymentsSettings = useSelector(bffClientsSettingsPaymentsSelector);
    const paymentsSettingsProgress = useSelector(bffClientsSettingsPaymentsProgressSelector);
    const clientMembers = useSelector(clientMemberOptionsSelector);
    const card = useSelector(actRegistriesCardSelector);
    const progressCard = useSelector(actRegistriesProgressCardSelector);
    const progressAction = useSelector(actRegistriesProgressActionSelector);
    const actTemplatesOptions = useSelector(actTemplatesOptionsSelector);
    const {customDocumentTemplatesAvailable} = useSelector(getClientPropertiesCardSelector);

    const paymentMethodOptions = getPaymentMethodOptionsByClientPaymentsSettings({
        paymentsSettings,
        paymentType: PAYMENT_TYPE.MARKETPLACE,
        contractType: ORDER_WORK_REPORT_TYPE.SMZ,
    });

    const isEdit = Boolean(editData);

    const isUserAdmin = isUserFromNm(ls(USER_ROLE));

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues: handleOrderActRegistryFrom({
            card,
            paymentsSettings,
        }),
        validationSchema: getValidationScheme(isUserAdmin),
        validateOnBlur: false,
    });

    useEffect(() => {
        if (isEmpty(card) && isEmpty(paymentsSettings)) {
            return;
        }

        const formValues = handleOrderActRegistryFrom({
            card,
            paymentsSettings,
            values,
        });

        setValues({
            ...values,
            ...formValues,
        });
    }, [card, paymentsSettings]);

    useEffect(() => {
        dispatch(getClientsSettingsPayments({clientId}));

        if (editData) {
            const {
                registryId,
                clientId,
            } = editData;

            dispatch(getActRegistry({
                registryId,
                clientId,
            }));
        }

        if (isUserAdmin) {
            fetchClientMembers();
        }

        return () => {
            dispatch(updateActRegistriesStore({card: {}}));
            dispatch(updateDocumentCustomTemplateStore({actTemplatesList: []}));
            dispatch(updateStoreClientsSetting({payments: {}}));
        };
    }, []);

    const {
        progressReplacementsForUnavailableSelector,
        isOpenUnavailableTemplates,
        onOpenUnavailableTemplates,
        onCloseUnavailableTemplates,
        checkUnavailableTemplates,
    } = useUnavailableTemplates();

    const fetchClientMembers = () => {
        dispatch(getClientMemberList({
            clientId,
            archiveFilter: false,
            roleListFilter: [FOREMAN, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT],
            pageNum: 1,
            pageSize: 300,
        }));
    };

    const onEditForm = () => {
        const creatorData = {
            creatorClientId: isUserFromNm(role) ? clientId : undefined,
            creatorClientUserId: isUserFromNm(role) ? values.creatorClientUserId : undefined,
        };

        const actOrderKind = values.isSecondTypeImportOrder ? ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER : ORDER_KIND.AMOUNT_BASED_ORDER;

        if (isEdit) {
            const {registryId} = editData;

            dispatch(updateActRegistry({
                ...card,
                clientId,
                registryId,
                ...values,
                ...creatorData,
                actOrderKind,
                orderContractPaymentType: "SMZ",
                onSuccess: () => {
                    onFetchList();
                    onClose();
                },
            }));

            return;
        }


        dispatch(addActRegistry({
            clientId,
            ...values,
            ...creatorData,
            actOrderKind,
            orderContractPaymentType: "SMZ",
            onSuccess: () => {
                onFetchList();
                onClose();
            },
        }));
    };

    function onSubmit() {
        if (!isValid) {
            return;
        }

        checkUnavailableTemplates({
            clientId,
            actsRegistryId: editData ? editData.registryId : undefined,
            customDocumentIds: [
                values.actOfAcceptanceOfWorkTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isTemplatesDeleted,
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем для удаленных шаблонов
                if (isTemplatesDeleted || isDisabledTemplateClientSetting) {
                    onOpenUnavailableTemplates();

                    return;
                }

                onEditForm();
            },
        });
    }

    const onChange = (event, {name, value, checked}) => {
        if (name === "isSecondTypeImportOrder" && !checked) {
            setValues(prevState => ({
                ...prevState,
                [name]: checked,
                workUnit: null,
                workUnitPrice: "",
            }));
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const getUnavailableTemplates = () => {
        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_ACTS}
                progress={progressAction}
                onClose={onCloseUnavailableTemplates}
                onSubmit={onEditForm}
                header={isEdit ? "Редактирование реестра" : "Создание реестра"}
            />
        );
    };

    return (
        <NmModal
            size="md"
            header={
                <div className="flex align-items-center">
                    <NmTitle size="lg">
                        {isEdit ? "Редактирование реестра актов" : "Создание реестра актов"}
                    </NmTitle>
                    <NmHorizontalToggleV2
                        className="ms-2"
                        disabled={isEdit}
                        leftLabel="Тип заказа 1"
                        rightLabel="Тип заказа 2"
                        tooltipIconHeight={20}
                        tooltipIconWidth={20}
                        name="isSecondTypeImportOrder"
                        checked={values.isSecondTypeImportOrder}
                        onChange={onChange}
                        contentRows={ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS}
                    />
                </div>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submit={handleSubmit}
                    disabled={progressAction || progressReplacementsForUnavailableSelector}
                    submitBtnContent={isEdit ? "Сохранить" : "Создать"}
                    onClose={onClose}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={progressCard}
        >
            {getUnavailableTemplates()}
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    name="name"
                    required
                    onChange={onChange}
                    value={values.name}
                    error={touched.name && errors.name}
                    placeholder="Название реестра"
                    label="Название"
                />
                {
                    isUserAdmin &&
                    <NmDropdownV2
                        search
                        label="Ответственный"
                        error={touched.creatorClientUserId && errors.creatorClientUserId}
                        options={clientMembers}
                        required
                        placeholder="Выберите ответственного"
                        value={values.creatorClientUserId}
                        name="creatorClientUserId"
                        onChange={onChange}
                        size="xl"
                    />
                }
                <NmInputV2
                    maxLength={255}
                    size="xl"
                    name="description"
                    value={values.description}
                    label="Комментарий"
                    onChange={onChange}
                    placeholder="При необходимости введите комментарий"
                />
                <TemplatesDropdown
                    disabled={!customDocumentTemplatesAvailable}
                    clientIdFilter={clientId}
                    isFetch
                    isFetchDefault={!editData}
                    actsRegistryIdFilter={editData?.registryId}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                    orderKindFilter={values.isSecondTypeImportOrder ? ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER : ORDER_KIND.AMOUNT_BASED_ORDER}
                    buttonText="Скачать акт"
                    placeholder="Выберите шаблон акта"
                    label="Шаблон акта"
                    name="actOfAcceptanceOfWorkTemplateId"
                    onChange={onChange}
                    options={actTemplatesOptions}
                    value={values.actOfAcceptanceOfWorkTemplateId}
                    fieldName={DOCUMENT_TEMPLATES_LIST_FIELD[DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_WORK]}
                    size="xl"
                />
                <NmDropdownV2
                    required
                    disabled={paymentsSettingsProgress}
                    label="Способ проведения оплаты"
                    placeholder="Выберите способ проведения оплаты"
                    name="paymentMethod"
                    options={paymentMethodOptions}
                    value={values.paymentMethod}
                    onChange={onChange}
                    error={touched.paymentMethod && errors.paymentMethod}
                />
            </NmForm>
        </NmModal>
    );
};

export default OrderActRegistryEdit;