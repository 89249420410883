import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import TemplatesDropdown from "../../../../../components/TemplatesDropdown";

import {useSuccessActionCallback} from "../../../../../hooks/useSuccessAction";

import {handleFormString} from "../../../../../utils/stringHelper";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../templates/constants";

import {
    addAdditionalAgreement,
    updateAdditionalAgreement,
    updateAdditionalAgreementsStore,
} from "../../../../../ducks/bff/documents/additional-agreements/actionCreators";
import {
    getRegistryOfAdditionalAgreement,
    updateRegistryOfAdditionalAgreementStore,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/actionCreators";
import {
    bffAdditionalAgreementsRegistryInitialValuesSelector,
    bffAdditionalAgreementsRegistryProgressCardSelector,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/selectors";
import {
    bffAdditionalAgreementErrorSelector,
    bffAdditionalAgreementsProgressActionSelector,
} from "../../../../../ducks/bff/documents/additional-agreements/selectors";

export const DocumentsAdditionalAgreementEdit = (props) => {
    const {
        id,
        clientId,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const progressCard = useSelector(bffAdditionalAgreementsRegistryProgressCardSelector);
    const progressAction = useSelector(bffAdditionalAgreementsProgressActionSelector);
    const initialValues = useSelector(bffAdditionalAgreementsRegistryInitialValuesSelector);
    const error = useSelector(bffAdditionalAgreementErrorSelector);

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateAdditionalAgreementsStore({
                    error: null,
                }));
            }

            onClose();
        },
        progressAction,
        error,
    });

    const initialOption = useMemo(() => {
        if (id) {
            return {
                key: initialValues?.documentTemplateId,
                value: initialValues?.documentTemplateId,
                text: initialValues?.documentTemplateName,
            };
        }
    }, [
        initialValues,
    ]);

    const {
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema:  yup.object().shape({
            documentTemplateId: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            name: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });

    useEffect(() => {
        if (id) {
            dispatch(getRegistryOfAdditionalAgreement({
                id,
            }));
        }

        return () => {
            dispatch(updateRegistryOfAdditionalAgreementStore({
                card: {},
            }));
        };
    }, []);

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit() {
        const data = {
            id,
            ...values,
            commentary: handleFormString(values.commentary),
        };

        if (id) {
            dispatch(updateAdditionalAgreement({
                ...data,
            }));

            return;
        }

        dispatch(addAdditionalAgreement(data));
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`${id ? "Редактирование" : "Добавление"} реестра на подписание доп. соглашений`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    submit={handleSubmit}
                    onClose={onClose}
                    mobile="column"
                    submitBtnContent={id ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                />
            }
            loading={progressCard || progressAction}
            classNameContent="flex-column"
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    size="xl"
                    required={true}
                    name="name"
                    value={values.name}
                    onChange={onChange}
                    label="Наименование"
                    placeholder="Введите название реестра"
                    maxLength={255}
                    error={
                        touched?.name && errors?.name ?
                            errors?.name :
                            undefined
                    }
                />
                <TemplatesDropdown
                    required={true}
                    label="Шаблон доп. соглашения"
                    initialOption={initialOption}
                    isFetchDefault={!id}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ADDITIONAL_AGREEMENT}
                    name="documentTemplateId"
                    clientIdFilter={clientId}
                    value={values.documentTemplateId}
                    onChange={onChange}
                    buttonText="Скачать шаблон"
                    error={
                        touched?.documentTemplateId && errors?.documentTemplateId ?
                            errors?.documentTemplateId :
                            undefined
                    }
                />
                <NmTextareaV2
                    minRows={2}
                    label="Комментарий"
                    placeholder="Оставьте комментарий"
                    name="commentary"
                    maxLength="1000"
                    value={values.commentary}
                    onChange={onChange}
                />
            </NmForm>
        </NmModal>
    );
};