import React, {Component} from "react";
import {Link} from "react-router-dom";

import "./style.sass";

class ForbiddenPage extends Component {
    render() {
        return (
            <div className="forbidden-page">
                <div className="forbidden-page__content">
                    <div className="forbidden-page__status">
403
                    </div>
                    <div className="forbidden-page__value">
Страница недоступна
                    </div>
                    <div>
                        {" "}
К сожалению, к запрошенной вами страницы доступ отсутствует
                    </div>
                    <div style={{"marginTop": "35px"}}>
Попробуйте
                        <Link to={"/login"}>
авторизоваться
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

}

export default ForbiddenPage;