import React from "react";

import NmHorizontalToggleV2 from "../../../../../../../components/ActualComponents/NmHorizontalToggleV2";

const OrderEditPreConditionsTemplateType = (props) => {
    const {
        isDisabled,
        onChange,
        formData,
        className,
    } = props;

    return (
        <NmHorizontalToggleV2
            className={className}
            disabled={isDisabled}
            leftLabel="Свободный заказ"
            rightLabel="Заказ по шаблону"
            tooltipIconHeight={20}
            tooltipIconWidth={20}
            name="isTemplateType"
            checked={formData.isTemplateType}
            onChange={onChange}
            suggestion={"Список объектов зависит от выбора Свободный заказ / Заказ по шаблону. При выбранной позиции \"Заказ по шаблону\" в списке содержатся только те объекты, в рамках которых имеются шаблоны заказов"}
        />
    );
};

export default OrderEditPreConditionsTemplateType;