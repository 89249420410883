import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {dadataValueCityFormatter} from "../../../../utils/dadata";
import {getDateObject, getTimeZoneOptions} from "../../../../utils/dateFormat";

import {
    CALL_TYPE_DICT,
    TICKET_MANAGER_AWAY_OPTION,
} from "../../../../constants/crm/ticket";
import {NM_OPERATOR} from "../../../../constants/roles";

import {
    clientMemberOptionsSelector,
    currentMemberOptionTypeSelector,
} from "../../../../ducks/clientMember";
import {
    ticketCallTypeOptionsSelector,
    ticketCategoryOptionsSelector,
    ticketTypeOptionsSelector,
} from "../../../../ducks/crm/ticket";

import {SUB_PAGE_CRM_TICKET} from "../../../../constants/link-params";

function TicketFilter(props) {
    const {
        submitFilter,
        initFilter,
        role,
        filter: {
            callTypeFilter,
            categoryFilter,
            clientNameOrFioFilter,
            closeDateFrom,
            closeDateTo,
            createDateFrom,
            createDateTo,
            managerClientUserIdsFilter,
            numberFilter,
            onlyMyTickets,
            phoneFilter,
            typeFilter,
            addressFiasIdsFilter,
            timeZonesFilter,
        },
        onChangeFilter,
        filter,
        setFilter,
        isShowFieldClientNameAndPhone = true,
        isShowCallTypeFilter = true,
        subpage,
    } = props;

    const ticketCategoryOptions = useSelector(ticketCategoryOptionsSelector);
    const ticketCallTypeOptions = useSelector(ticketCallTypeOptionsSelector);
    const ticketTypeOptions = useSelector(ticketTypeOptionsSelector);
    const managerOptions = useSelector(clientMemberOptionsSelector);
    const currentMemberOptions = useSelector(currentMemberOptionTypeSelector);

    const ticketCallTypeOptionsWithoutChat = ticketCallTypeOptions.filter(({value}) => {
        return ![
            CALL_TYPE_DICT.CHAT.VALUE,
            CALL_TYPE_DICT.KEDO_CHAT.VALUE,
        ].includes(value);
    });

    const handleOnSearch = () => {
        submitFilter(filter, true);
    };

    const getManagerOptions = useMemo(() => {
        return managerOptions.find(({value}) => currentMemberOptions.find(({value: _value}) => _value === value)) ?
            [
                TICKET_MANAGER_AWAY_OPTION,
                ...managerOptions,
            ] :
            [
                TICKET_MANAGER_AWAY_OPTION,
                ...currentMemberOptions,
                ...managerOptions,
            ];
    }, [managerOptions, currentMemberOptions]);
    const timeZonesOptions = getTimeZoneOptions();

    const handleOnClear = () => {
        submitFilter({...initFilter}, false);
        setFilter({...initFilter});
    };

    const handleOnlyMyTicket = (event, {name, checked}) => {
        onChangeFilter(event, {name, checked});
        onChangeFilter(event, {
            name: "managerClientUserIdsFilter",
            value: checked ? [currentMemberOptions[0].value]
                : managerClientUserIdsFilter.filter(item => currentMemberOptions[0].value !== item),
        });
    };

    return (
        <NmForm addMargin>
            <NmInputV2
                pattern={/^\d+$/}
                label="Номер обращения"
                value={numberFilter}
                name="numberFilter"
                size="lg"
                onChange={onChangeFilter}
                placeholder="Введите номер"
            />
            {
                isShowFieldClientNameAndPhone &&
                <PhoneWithCodeFilter
                    label="Телефон"
                    name="phoneFilter"
                    value={phoneFilter}
                    isVisibleCountry={![SUB_PAGE_CRM_TICKET.KEDO.LINK].includes(subpage)}
                    onChange={onChangeFilter}
                />
            }
            {
                isShowFieldClientNameAndPhone &&
                <NmInputV2
                    label="ФИО / Наименование компании"
                    value={clientNameOrFioFilter}
                    name="clientNameOrFioFilter"
                    size="lg"
                    onChange={onChangeFilter}
                    placeholder="Поиск"
                />
            }
            {
                ![
                    SUB_PAGE_CRM_TICKET.KEDO.LINK,
                ].includes(subpage) &&
                <>
                    <NmDadata
                        size="lg"
                        label="Город"
                        multiple
                        name="addressFiasIdsFilter"
                        onChange={onChangeFilter}
                        formatter={dadataValueCityFormatter}
                        placeholder="Выберите город"
                        fromBound="city"
                        toBound="city"
                        isAllSelectionShown={false}
                        initialAddressIds={addressFiasIdsFilter}
                    />
                    <NmDropdownV2
                        size="lg"
                        placeholder="Выберите часовой пояс"
                        selectOnBlur={false}
                        multiple
                        search
                        name="timeZonesFilter"
                        value={timeZonesFilter}
                        options={timeZonesOptions}
                        label="Часовой пояс исполнителя"
                        onChange={onChangeFilter}
                    />
                </>
            }
            <NmDropdownV2
                placeholder="Не выбрана"
                size="lg"
                selectOnBlur={false}
                search
                name="categoryFilter"
                value={categoryFilter}
                options={ticketCategoryOptions}
                label="Категория обращения"
                onChange={onChangeFilter}
            />
            <NmDropdownV2
                placeholder="Не выбран"
                size="lg"
                selectOnBlur={false}
                search
                name="typeFilter"
                multiple
                value={typeFilter}
                options={ticketTypeOptions}
                label="Тип обращения"
                onChange={onChangeFilter}
            />
            {
                isShowCallTypeFilter &&
                <NmDropdownV2
                    placeholder="Не выбран"
                    size="lg"
                    selectOnBlur={false}
                    search
                    name="callTypeFilter"
                    value={callTypeFilter}
                    options={
                        subpage === SUB_PAGE_CRM_TICKET.CLOSED.LINK ?
                            ticketCallTypeOptions : ticketCallTypeOptionsWithoutChat
                    }
                    label="Источник"
                    onChange={onChangeFilter}
                />
            }
            <NmDropdownV2
                placeholder="Не выбран"
                size="lg"
                selectOnBlur={false}
                search
                name="managerClientUserIdsFilter"
                multiple
                value={managerClientUserIdsFilter}
                options={getManagerOptions}
                label="Ответственный"
                onChange={onChangeFilter}
            />
            {role === NM_OPERATOR && <NmCheckboxV2
                name="onlyMyTickets"
                label="Мои обращения"
                checked={onlyMyTickets}
                onChange={handleOnlyMyTicket}
            />}
            <NmDateRangePickerV2
                label="Дата создания"
                onChange={onChangeFilter}
                size="lg"
                dateFormat="dd.MM.yy"
                startFieldName="createDateFrom"
                endFieldName="createDateTo"
                isClearable
                value={{
                    createDateFrom: getDateObject(createDateFrom),
                    createDateTo: getDateObject(createDateTo),
                }}
            />
            <NmDateRangePickerV2
                label="Дата закрытия обращения"
                onChange={onChangeFilter}
                size="lg"
                dateFormat="dd.MM.yy"
                startFieldName="closeDateFrom"
                endFieldName="closeDateTo"
                isClearable
                value={{
                    closeDateFrom: getDateObject(closeDateFrom),
                    closeDateTo: getDateObject(closeDateTo),
                }}
            />
            <FilterButtonsV2
                onClear={handleOnClear}
                onSearch={handleOnSearch}
            />
        </NmForm>
    );
}

export default TicketFilter;