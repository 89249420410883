import React from "react";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import ClientCardEdoRoute from "./Route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS,
    LinkNavigator,
} from "../../../../constants/links";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import "./style.sass";

const ClientCardEdo = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;
    const role = ls(USER_ROLE);

    const getLink = () => {
        const documentList = LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST.replace(":clientId", clientId);
        const personnel = LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL.replace(":clientId", clientId);
        const exportLink = LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT.replace(":clientId", clientId);
        const dictionaries = LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT.replace(":clientId", clientId);
        const statements = LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS.replace(":clientId", clientId);
        const documentsRegistries = LinkNavigator.client.edo.documentsRegistries.replace(":clientId", clientId);

        return {
            documentList,
            statements,
            personnel,
            exportLink,
            dictionaries,
            documentsRegistries,
        };
    };

    const getTabLinks = () => {
        const {
            documentList,
            personnel,
            statements,
            exportLink,
            dictionaries,
            documentsRegistries,
        } = getLink();

        return [
            {
                active: documentList,
                link: documentList,
                name: "Документы",
            },
            {
                active: documentsRegistries,
                link: documentsRegistries,
                name: "Реестры документов",
            },
            {
                active: statements,
                link: statements,
                name: "Заявления",
            },
            {
                active: personnel,
                link: personnel,
                name: "Кадры",
            },
            {
                active: exportLink,
                link: exportLink,
                name: "Экспорт",
            },
            {
                active: dictionaries,
                link: dictionaries,
                name: "Справочники",
            },
        ];
    };

    return (
        <div className="client-card-documents">
            {
                ![CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, HR_MANAGER].includes(role) &&
                    <>
                        <NmTitle size="xl">
                            ЭДО
                        </NmTitle>
                        <Tabs
                            {...props}
                            className="client-card-documents__tabs"
                            panes={getTabLinks()}
                            secondary
                        />
                    </>
            }
            <ClientCardEdoRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardEdo;
