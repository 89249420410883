import {
    KEDO_EXPORTS_CANCEL_BY_ID_ERROR,
    KEDO_EXPORTS_CANCEL_BY_ID_REQUEST,
    KEDO_EXPORTS_CANCEL_BY_ID_SUCCESS,
    KEDO_EXPORTS_GET_PAGE_ERROR,
    KEDO_EXPORTS_GET_PAGE_REQUEST,
    KEDO_EXPORTS_GET_PAGE_SUCCESS,
    KEDO_EXPORTS_UPDATE_STORE,
} from "./actions";

import {getDefaultPageInitialState} from "../../../utils/store";

const initialState = getDefaultPageInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case KEDO_EXPORTS_CANCEL_BY_ID_ERROR:
    case KEDO_EXPORTS_CANCEL_BY_ID_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case KEDO_EXPORTS_CANCEL_BY_ID_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case KEDO_EXPORTS_GET_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case KEDO_EXPORTS_GET_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    case KEDO_EXPORTS_GET_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case KEDO_EXPORTS_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default: return state;
    }
};