import React, {ReactNode, RefObject, SyntheticEvent, useState} from "react";

import ErrorTooltip from "../ErrorTooltip";
import NmDropdownV2 from "../NmDropdownV2";
import NmInputV2 from "../NmInputV2";
import NmLabel from "../NmLabel";

import {useClickOutside} from "../../../hooks/useClickOutside";

import {OptionType} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";

import "./style.sass";

interface IInputWithDropdown {
    valueSelect: string,
    valueInput?: string
    inputPlaceholder?: string
    className?: string
    inputName?: string
    selectName?: string
    options: OptionType[]
    label?: string
    onChange: (event?: any, params?: any) => void,
    error?: any,
    required?: boolean,
    disabled?: boolean,
    input?: ReactNode,
    onFocus?: (event?: any) => void,
    classNameDropdown?: string,
    classNameInput?: string,
    classNameDivider?: string,
    disabledDropdown?: boolean,
    disabledInput?: boolean,
    suggestion?: ReactNode,
    onMouseEnter?: (event: any) => void,
    tooltip?: ReactNode,
    isVisibleTooltip?: boolean,
    signalTooltip?: ReactNode,
}

const InputWithDropdownV2 = (props: IInputWithDropdown) => {
    const {
        valueSelect,
        valueInput,
        inputPlaceholder,
        className = "",
        inputName,
        selectName,
        classNameInput = "",
        classNameDropdown = "",
        options,
        label,
        error,
        required,
        disabled,
        input,
        onFocus,
        classNameDivider = "",
        disabledDropdown,
        signalTooltip,
        disabledInput,
        suggestion,
        onMouseEnter,
        tooltip,
        isVisibleTooltip,
        ...otherProps
    } = props;
    const [block, element] = bem("input-with-dropdown-v2", className);
    const [focused, setFocused] = useState(false);

    const onMouseDown = () => {
        !disabled && setFocused(true);
    };

    const rootRef: RefObject<HTMLDivElement> = useClickOutside((e: SyntheticEvent) => {
        !disabled && setFocused(false);
    }, focused);

    return (
        <div
            onFocus={onFocus}
            tabIndex={0}
            className={block()}
        >
            {
                label &&
                <NmLabel
                    required={required}
                    disabled={
                        disabled ||
                        disabledInput && disabledDropdown
                    }
                    label={label}
                    tooltip={tooltip}
                    signalTooltip={signalTooltip}
                    isVisibleTooltip={isVisibleTooltip}
                />
            }
            <div
                onMouseEnter={onMouseEnter}
                onMouseDown={onMouseDown}
                ref={rootRef}
                className={element("inputs", {
                    focused,
                    active: Boolean(valueSelect || valueInput),
                    error: Boolean(error),
                    disabled: disabled || disabledInput && disabledDropdown,
                })}
            >
                {
                    input ?
                        input :
                        <NmInputV2
                            {...otherProps}
                            className={classNameInput}
                            disabled={disabledInput || disabled}
                            value={valueInput}
                            placeholder={inputPlaceholder}
                            name={inputName}
                        />
                }
                <div className={`${element("divider", {disabled: disabled || disabledInput && disabledDropdown})} ${classNameDivider}`} />
                <NmDropdownV2
                    className={`${element("dropdown", {disabled: disabledDropdown || disabled})} ${classNameDropdown}`}
                    value={valueSelect}
                    disabled={disabledDropdown || disabled}
                    name={selectName}
                    options={options}
                    {...otherProps}
                />
                {
                    suggestion &&
                    <div className={element("suggestion")}>
                        {suggestion}
                    </div>
                }
            </div>
            {
                error &&
                <ErrorTooltip error={error} />
            }
        </div>
    );
};

export default InputWithDropdownV2;