import React from "react";

import {ReactComponent as WarningIcon} from "../../images/no-info.svg";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmCheck = (props) => {
    const {
        blockClassName = "",
        isCheck,
        label,
        tooltip,
        iconClass,
        labelClass = "",
        classNameIcon,
        isWarning = false,
        isNotPresent = false,
        noWrapLabel = false,
    } = props;
    const [block, element] = bem("nm-check", blockClassName);

    const className = `${element(isCheck ? "success-icon" : "wrong-icon")} ${iconClass}`;
    const labelClassName = `${element("label", {noWrap: noWrapLabel})} ${labelClass}`;

    function getIcon() {
        if (isWarning) {
            return (
                <WarningIcon
                    className={`${element("warning-icon")} ${classNameIcon}`}
                    title={tooltip}
                />
            );
        }

        if (isNotPresent) {
            return (
                <WarningIcon
                    className={`${element("not-present-icon")} ${classNameIcon}`}
                    title={tooltip}
                />
            );
        }

        return (
            <div
                className={`${className} ${classNameIcon}`}
                title={tooltip}
            />
        );
    }

    return (
        <div className={block()}>
            {getIcon()}
            {
                label &&
                <span className={labelClassName}>
                    {label}
                </span>
            }
        </div>
    );
};

NmCheck.propTypes = {
    labelClass: PropTypes.string,
    isCheck: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltip: PropTypes.string,
    className: PropTypes.string,
    classNameIcon: PropTypes.string,
    isWarning: PropTypes.bool,
};

NmCheck.defaultProps = {
    isCheck: false,
    tooltip: "",
    className: "",
    classNameIcon: "",
};

export default NmCheck;
