import React, {useContext} from "react";

import {BooleanAttributeEdit} from "../../../../BooleanAttributeEdit";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

export const RecruitmentCandidateReadingMovingEdit = (props) => {
    const {
        onClose,
        initialValue,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    const onSubmit = ({value: readyForMoving}) => {
        updateInformation({
            readyForMoving,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    };

    return (
        <BooleanAttributeEdit
            title="Возможность переезда"
            labelTrue="Готов к переезду"
            labelFalse="Не готов к переезду"
            initialValue={initialValue}
            progress={progressAction}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};
