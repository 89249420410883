import React from "react";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../../components/CheckboxList";
import NmSelectedList from "../../../../components/NmSelectedList";
import NmTitle from "../../../../components/NmTitle";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {getFullName} from "../../../../utils/stringFormat";

import PropTypes from "prop-types";

import "./style.sass";

const TemplatesVersions = (props) => {
    const {
        onClose,
        pageNum,
        pageSize,
        totalPages,
        totalCount,
        onChangePageSize,
        onPaginationChange,
        list,
        progress,
    } = props;

    function mapUsedByClients(usedByClients) {
        return usedByClients.map(({name, clientId}) => ({text: name, value: clientId, key: clientId}));
    }

    function getRows() {
        return list.map(item => {
            const {
                name,
                id,
                usedByClients,
                addedDateTime,
                addedByClientUserFirstName,
                addedByClientUserLastName,
                addedByClientUserPatronymic,
            } = item;

            return {
                ...item,
                key: id,
                contentRow: (
                    <NmListCard
                        primaryHeader={name}
                        primaryHeaderTitle={name}
                        classNamePrimaryHeaderValue="templates-versions__primary-header-value"
                        classNameMainContent="col-16"
                        noDivider
                        labels={[
                            {
                                label: "Создатель",
                                text: getFullName(addedByClientUserLastName, addedByClientUserFirstName, addedByClientUserPatronymic),
                            },
                            {
                                label: "Дата создания",
                                text: formatLocalDate(addedDateTime, "dd.MM.yyyy HH:mm"),
                            },
                            {
                                label: "Компании",
                                columnOnMobile: true,
                                text: <NmSelectedList
                                    showListWithoutValue
                                    showedItemsCount={3}
                                    highlightingFirstItem={false}
                                    list={mapUsedByClients(usedByClients)}
                                />,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            className="templates-versions"
            header={
                <div className="templates-versions__title">
                    <NmTitle
                        size="lg"
                        className="me-2"
                    >
                        Версии шаблона
                    </NmTitle>
                    <HelpTooltip
                        info
                        hover
                        position="bottom-left"
                        text="Версия шаблона действует от даты добавления версии до даты ее изменения"
                    />
                </div>
            }
            classNameContent="flex-column"
            loading={progress}
        >
            {
                list.length === 0 &&
                <NmEmptyPageV2
                    classNameEmptyPage="templates-versions__empty-page"
                    title="Информация отсутствует"
                />
            }
            {
                list.length > 0 &&
                <>
                    <CheckboxList
                        className="templates-versions__list"
                        rows={getRows()}
                    />
                    <NmPagination
                        className="mt-2 mt-md-5 mt-xxl-7"
                        totalCount={totalCount}
                        pageNum={pageNum}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        onChangePageSize={onChangePageSize}
                        onChangePagination={onPaginationChange}
                    />
                </>
            }
        </NmModal>
    );
};

TemplatesVersions.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default TemplatesVersions;
