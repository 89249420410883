import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    searchRecruitmentRejectionReason,
    updateRecruitmentDirectoryStore,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

export const useRecruitmentRejectionReasonsFetchList = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                rejectionReasonsPageData: {},
                rejectionReasonsList: [],
                rejectionReasonsTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const fetchList = () => {
        dispatch(searchRecruitmentRejectionReason({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};