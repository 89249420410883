import React, {useState} from "react";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import "./style.sass";


const AddMLModelForm = (props) => {
    const {
        onSubmit,
        progress,
        onClose,
        model={},
        t,
    } = props;

    const [form, setForm] = useState(model);

    const handleChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const getForm = () => {
        return(
            <div>
                <NmInputV2
                    label="Наименование"
                    value={form.name}
                    name="name"
                    onChange={handleChange}
                />
                <NmInputV2
                    label="Описание"
                    value={form.description}
                    name="description"
                    onChange={handleChange}
                />
                <NmInputV2
                    label="Метод"
                    value={form.method}
                    name="method"
                    onChange={handleChange}
                />
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            className="ad-ml-model-form"
            header={
                <NmTitle size="lg">
                    Добавление модели
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    disabled={progress}
                    submit={() => {
                        onSubmit(form);
                    }}
                />
            }
        >
            <NmForm
                addMargin
                className="commission-rate"
            >
                {getForm()}
            </NmForm>
        </NmModal>
    );
};

export default AddMLModelForm;