import React from "react";
import {useDispatch} from "react-redux";

import {
    deleteFileRecruitmentVacancyCandidatesComment,
} from "../../ducks/bff/recruitment/vacancyCandidatesComments/actionCreators";
import {DropzoneUploadedArea} from "../ActualComponents/DropzoneV2/components/uploaded-area";

import {CURRENT_CLIENT_USER_ID, ls} from "../../utils/localstorage";
import {toastSuccess} from "../../utils/toastHelper";

import {downloadDocument} from "../../ducks/documents";

const RecruitmentCommentFiles = (props) => {
    const {
        clientId,
        commentId,
        initiatorId,
        fileNames = [],
        fetchInfo = () => {},
    } = props;

    const dispatch = useDispatch();

    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

    const files = fileNames.map(item => ({name: item}));

    const onDownloadFile = ({name}) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `/api/filestore/recruitment/candidate/comment/get?clientId=${clientId}&commentId=${commentId}&&fileName=${name}`,
            fileName: name,
        }));
    };

    const onRemoveFile = ({name}) => {
        dispatch(deleteFileRecruitmentVacancyCandidatesComment({
            id: commentId,
            clientId,
            fileName : name,
            onSuccess: () => {
                toastSuccess("Файл успешно удален");
                fetchInfo();
            },
        }));
    };

    return (
        <DropzoneUploadedArea
            files={files}
            isFixedTooltip={true}
            isShowDeleteButton={currentClientUserId === initiatorId}
            multiple={true}
            isDownload={true}
            onDownloadFile={onDownloadFile}
            onRemoveFile={onRemoveFile}
        />
    );
};

export default RecruitmentCommentFiles;