import React, {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {debounce} from "lodash";

import {getTariffScaleRateByObjectAddress} from "../../../../ducks/tariff";

export const OrderEditTariffScaleChecker = (props) => {
    const {
        isSecondType,
        addressId,
        setTariffScale,
        formData: {
            workUnitPrice,
            workUnit,
            specialityId,
        },
    } = props;

    const dispatch = useDispatch();

    const fetchList = () => {
        dispatch(getTariffScaleRateByObjectAddress({
            addressId,
            specialityId,
            unit: workUnit,
            onSuccess: (scale) => {
                setTariffScale(scale);
            },
        }));
    };

    const delayedFetching = useCallback(debounce(() => {
        fetchList();
    }, 350), [specialityId, addressId, workUnit]);

    useEffect(() => {
        if (workUnit && workUnitPrice && specialityId && addressId) {
            delayedFetching();
        }
    }, [workUnitPrice, specialityId, addressId, workUnit]);

    if (!isSecondType) {
        return null;
    }

    return <></>;
};