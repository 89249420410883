import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import {
    getPageNum,
    getTotalPages,
} from "../../../../utils/mathHelper";

export const bffRecruitmentVacancyResponsesSelector = state => state.bff.recruitment.vacancyResponses;
export const bffRecruitmentVacancyResponsesListSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({list}) => list);
export const bffRecruitmentVacancyResponsesListPageDataSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({pageData}) => pageData);
export const bffRecruitmentVacancyResponsesTotalCountSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({totalCount}) => totalCount);
export const bffRecruitmentVacancyResponsesTotalPageSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffRecruitmentVacancyResponsesPageNumSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({list = [], pageData = {}}) => {
    return getPageNum(list.length, pageData.pageSize);
});
export const bffRecruitmentVacancyResponsesProgressSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({progress}) => progress);
export const bffRecruitmentVacancyResponsesProgressActionSelector = createSelector(bffRecruitmentVacancyResponsesSelector, ({progressAction}) => progressAction);
export const bffRecruitmentVacancyResponseIdsSelector = createSelector(
    bffRecruitmentVacancyResponsesSelector,
    ({list}) => {
        return list.map(item => item.responseId).filter(Boolean);
    });
export const bffRecruitmentVacancyResponsesCardSelector = createSelector(
    bffRecruitmentVacancyResponsesSelector,
    ({card}) => {
        return {
            ...card,
            jobBoardsResumes: !isEmpty(card.resumes) ? card.resumes.filter(item => item.type) : [],
            otherResumes: !isEmpty(card.resumes) ? card.resumes.filter(item => !item.type) : [],
            name: card.applicantName,
        };
    },
);
export const bffRecruitmentVacancyResponsesProgressCardSelector = createSelector(
    bffRecruitmentVacancyResponsesSelector,
    ({progressCard}) => progressCard,
);