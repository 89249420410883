import React from "react";
import {useSelector} from "react-redux";

import Text, {TTextLevel} from "../ActualComponents/Text";

import bem from "../../utils/bem";

import {citizenshipsDict} from "../../constants/citizenships";
import {COLOR} from "../../constants/color";

import {getCitizenshipSelector} from "../../ducks/contractor";

import "./style.sass";

interface ICitizenshipWithFlag {
    textLevel?: TTextLevel,
    citizenship: string,
    flagPositionEnd?: boolean,
    emptyContent?: string,
    countryName?: string,
}

export function CitizenshipWithFlag(params: ICitizenshipWithFlag) {
    const {
        textLevel = "3",
        citizenship,
        flagPositionEnd = false,
        emptyContent = "-",
        countryName,
    } = params;

    const citizenshipDict = useSelector(getCitizenshipSelector);
    const [, element] = bem("citizenship-with-flag");
    const text = countryName || citizenshipDict[citizenship];

    if (!citizenship && !countryName) {
        return emptyContent;
    }

    const getImg = () => {
        if (citizenship === citizenshipsDict.NOT_SPECIFIED.value) {
            return null;
        }

        const modifiers = {
            level: textLevel,
            end: flagPositionEnd,
        };

        return (
            <div className={element("image-container", modifiers)}>
                <img
                    alt={citizenship}
                    className={element("image")}
                    src={`/images/citizenship/${citizenship}.png`}
                />
            </div>
        );
    };

    return (
        <Text
            className="citizenship-with-flag"
            level={textLevel}
            color={COLOR.SECONDARY_90}
        >
            {!flagPositionEnd && getImg()}
            {text || ""}
            {flagPositionEnd && getImg()}
        </Text>
    );
}