import React, {Component} from "react";
import {Route, Switch} from "react-router";

import ContractorEventDocumentLogList from "../contractor-event-document-log";
import ContractorEventLogList from "../contractor-event-log-list";
import ContractorEventLogOrderHistory from "../contractor-event-log-order-history";
import ContractorEventNotificationLogList from "../contractor-event-notification-log";

import {
    LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_LIST,
    LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY,
} from "../../../../constants/links";

class ContractorEventLogRoute extends Component {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY}
                    component={ContractorEventLogOrderHistory}
                />
                <Route
                    exact
                    path={LINK_CONTRACTOR_CARD_EVENT_LOG_LIST}
                    component={ContractorEventLogList}
                />
                <Route
                    exact
                    path={LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG}
                    component={ContractorEventDocumentLogList}
                />
                <Route
                    exact
                    path={LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG}
                    component={ContractorEventNotificationLogList}
                />
            </Switch>
        );
    }
}

export default ContractorEventLogRoute;