import React, {useState} from "react";

import {ReactComponent as AddIcon} from "../../../../../../../images/add.svg";

import {COMPONENT} from "../../../../../../../components/ActualComponents/MediaControls/constants";

export const useEdoDocumentsRegistriesAction = () => {
    const [confirmData, setConfirmData] = useState({});
    const [isOpenEditForm, setIsOpenEditForm] = useState(false);
    const [isInviteViaEmailOpen, setIsInviteViaEmailOpen] = useState(false);
    const [editFormData, setEditFormData] = useState({});

    const getHeaderMediaControls = () => {
        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <AddIcon />,
                        onClick: () => setIsInviteViaEmailOpen(true),
                        size: "xl",
                        children: "Пригласить исполнителя в Наймикс",
                        color: "green",
                    },
                },
            ],
        };
    };

    return {
        headerMediaControls: getHeaderMediaControls(),
        confirmData,
        setConfirmData,
        isOpenEditForm,
        setIsOpenEditForm,
        setEditFormData,
        editFormData,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
    };
};