import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const notificationSendingToContractorSelector = state => state.bff.notificationSendingToContractor;
export const notificationSendingToContractorProgressSelector = createSelector(
    notificationSendingToContractorSelector,
    ({progress}) => progress,
);
export const notificationSendingToContractorProgressActionSelector = createSelector(
    notificationSendingToContractorSelector,
    ({progressAction}) => progressAction,
);
export const notificationSendingToContractorProgressAddSelector = createSelector(
    notificationSendingToContractorSelector,
    ({progressAdd}) => progressAdd,
);
export const notificationSendingToContractorListSelector = createSelector(
    notificationSendingToContractorSelector,
    ({list}) => list,
);
export const notificationSendingToContractorTotalCountSelector = createSelector(
    notificationSendingToContractorSelector,
    ({totalCount}) => totalCount,
);
export const notificationSendingToContractorTotalPagesSelector = createSelector(
    notificationSendingToContractorSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const notificationSendingToContractorPageDataSelector = createSelector(
    notificationSendingToContractorSelector,
    ({pageData}) => pageData,
);
export const notificationSendingToContractorCardSelector = createSelector(
    notificationSendingToContractorSelector,
    ({card}) => card,
);