import React from "react";

import {Loader} from "semantic-ui-react";

import bem from "../../utils/bem";

import "./style.sass";

const RegistryCardButton = (props) => {
    const {
        onClick,
        className,
        icon,
        disabled,
        children,
        progress,
        loaderText = "",
    } = props;
    const [block, element] = bem("registry-card-button", className);

    return (
        <div
            className={block({disabled})}
            onClick={onClick}
        >
            <div className={element("icon")}>
                {icon}
            </div>
            {children}
            {
                progress &&
                <div className="flex align-items-center ms-4">
                    <Loader
                        className="me-2"
                        active
                        inline
                        size="tiny"
                    />
                    {loaderText}
                </div>
            }
        </div>
    );
};

export default RegistryCardButton;