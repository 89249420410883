import {useSelector} from "react-redux";

import {
    contractorDrivingLicenseDictKeyDictSelector,
    contractorEducationLevelDictWithNoneSelector,
    contractorLanguagesDictSelector,
} from "../../../../ducks/contractorProfile";
import {
    contractorDictsRecruitmentSchedulesSelector,
    contractorDictsRecruitmentWorkExperienceDictSelector,
} from "../../../../ducks/contractorsDicts";

export const useRecruitmentCandidateInformationDicts = () => {
    const educationLevelDict = useSelector(contractorEducationLevelDictWithNoneSelector);
    const driverLicenseDict = useSelector(contractorDrivingLicenseDictKeyDictSelector);
    const languageDict = useSelector(contractorLanguagesDictSelector);
    const schedulesDict = useSelector(contractorDictsRecruitmentSchedulesSelector);
    const workExperienceDict = useSelector(contractorDictsRecruitmentWorkExperienceDictSelector);

    return {
        driverLicenseDict,
        languageDict,
        educationLevelDict,
        schedulesDict,
        workExperienceDict,
    };
};