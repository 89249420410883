import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as BlackListIcon} from "../../../../../../images/blacklist.svg";
import {ReactComponent as CheckedBlackListIcon} from "../../../../../../images/blacklist-checked.svg";
import {ReactComponent as FavouriteIcon} from "../../../../../../images/favorite.svg";
import {ReactComponent as CheckedFavouriteIcon} from "../../../../../../images/favorite-checked.svg";
import {ReactComponent as UserIcon} from "../../../../../../images/user_24.svg";

import {toastSuccess} from "../../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {requiredMessage} from "../../../../../../constants/validationRules";

import {inviteCrowdTaskContractor} from "../../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {bffCrowdTasksCardRichSelector} from "../../../../../../ducks/bff/crowd/taskCard/selectors";
import {
    addContractorToBlackList,
    addFavoriteContractors,
    deleteContractorFromBlackList,
    deleteFavoriteContractors,
} from "../../../../../../ducks/contractor";
import {getContractorProfileResumeFile} from "../../../../../../ducks/contractorProfile";

const useCrowdTaskCardInviteCandidatesListAction = (props) => {
    const {
        clientId,
        taskId,
        fetchList,
        onOpenConfirm,
        onCloseConfirm,
        selectedList,
        clearSelectedRows,
    } = props;

    const dispatch = useDispatch();

    const taskCard = useSelector(bffCrowdTasksCardRichSelector);

    const [isInviteViaEmailOpen, setIsInviteViaEmailOpen] = useState(false);
    const [blackListReason, setBlackListReason] = useState({});

    const getMediaControls = (item) => {
        const {
            contractorId,
            blacklist,
            favourite,
            name,
        } = item;

        const mobileBlackListText = {children: blacklist ? "Удалить из чёрного списка" : "Добавить в чёрный список"};
        const mobileFavouriteText = {children: favourite ? "Удалить из избранных" : "Добавить в избранные"};

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 5,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        onlyIcon: true,
                        icon: favourite ? <CheckedFavouriteIcon /> : <FavouriteIcon />,
                        onClick: () => onOpenConfirm({
                            content:
                                favourite ?
                                    `Вы действительно хотите удалить исполнителя ${name} из избранных?` :
                                    `Вы действительно хотите добавить исполнителя ${name} в избранные?`
                            ,
                            onConfirm: () => changeFavoriteParam(item),
                        }),
                    },
                    asset: {
                        mobile: mobileFavouriteText,
                        tablet: mobileFavouriteText,
                    },

                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        onlyIcon: true,
                        icon: blacklist ? <CheckedBlackListIcon /> : <BlackListIcon />,
                        onClick: () => onOpenConfirm({
                            content:
                                blacklist ?
                                    `Вы действительно хотите удалить исполнителя ${name} из черного списка?` :
                                    `Вы действительно хотите добавить исполнителя ${name} в черный список?`
                            ,
                            onConfirm: ({reason}) => changeBlacklistParam(item, reason),
                            showReason: !blacklist,
                        }),
                    },
                    asset: {
                        mobile: mobileBlackListText,
                        tablet: mobileBlackListText,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        children: "Скачать резюме",
                        onClick: () => downloadResume(item),
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "green",
                        children: "Пригласить на задание",
                        onClick: () => onOpenConfirm({
                            content: `Вы действительно хотите пригласить исполнителя на задание ${taskCard.name}?`,
                            onConfirm: () => inviteContractors({contractorId}),
                        }),
                    },
                },
            ],
        };
    };

    const getHeaderMediaControls = () => {
        return {
            renderCount: {
                desktop: 1,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <UserIcon
                            width={24}
                            height={24}
                        />,
                        children: "Пригласить исполнителя в Наймикс",
                        onClick: () => setIsInviteViaEmailOpen(true),
                        className: "patents-card__page-button",
                    },
                },
            ],
        };
    };

    const openMassInviteConfirm = () => {
        onOpenConfirm({
            content: `Вы действительно хотите пригласить исполнителей на задание ${taskCard.name}?`,
            onConfirm: () => inviteContractors({isMassOperation: true}),
        });
    };

    const openMassFavoriteConfirm = () => {
        onOpenConfirm({
            content: "Вы действительно хотите добавить исполнителей в избранные?",
            onConfirm: massAddFavoriteContractors,
        });
    };

    const massAddFavoriteContractors = () => {
        const contractorIds = selectedList.filter(({isSelected}) => isSelected).map(({contractorId}) => contractorId);

        dispatch(addFavoriteContractors({
            data: {
                contractorIds,
                clientId,
            },
            onSuccess: () => {
                fetchList();
                clearSelectedRows();
                onCloseConfirm();
            },
        }));
    };

    const inviteContractors = ({isMassOperation = false, contractorId}) => {
        const selectedContractorIds = selectedList.filter(({isSelected}) => isSelected).map(({contractorId}) => {
            return {
                contractorId,
                taskId,
                clientId,
            };
        });
        const reqData = isMassOperation ?
            selectedContractorIds :
            [{
                contractorId,
                taskId,
                clientId,
            }];

        dispatch(inviteCrowdTaskContractor({
            reqData,
            isMassOperation,
            onSuccess: () => {
                toastSuccess(
                    isMassOperation ?
                        "Исполнители успешно приглашены на задание" :
                        "Исполнитель успешно приглашен на задание",
                );
                fetchList();
                clearSelectedRows();
                onCloseConfirm();
            },
        }));
    };

    const downloadResume = ({contractorId, name}) => {
        dispatch(getContractorProfileResumeFile({
            contractorId,
            fileName: `Резюме_${name}`,
        }));
    };

    const changeBlacklistParam = ({contractorId, blacklist}, reason) => {
        if (blacklist) {
            dispatch(deleteContractorFromBlackList({
                data: {
                    contractorId,
                    clientId,
                },
                onSuccess: () => {
                    onCloseConfirm();
                    fetchList();
                },
            }));

            return;
        }

        if (!reason) {
            setBlackListReason({
                ...blacklist,
                error: requiredMessage,
            });

            return;
        }

        dispatch(addContractorToBlackList({
            data: {
                contractorId,
                clientId,
                reason,
            },
            onSuccess: () => {
                onCloseConfirm();
                fetchList();
                setBlackListReason({});
            },
        }));
    };

    const changeFavoriteParam = ({contractorId, favourite}) => {
        const onSuccess = () => {
            onCloseConfirm();
            fetchList();
        };

        if (favourite) {
            dispatch(deleteFavoriteContractors({
                data: {
                    contractorId,
                    clientId,
                },
                onSuccess,
            }));

            return;
        }

        dispatch(addFavoriteContractors({
            data: {
                contractorId,
                clientId,
            },
            onSuccess,
        }));
    };

    return {
        getMediaControls,
        openMassInviteConfirm,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        getHeaderMediaControls,
        blackListReason,
        setBlackListReason,
        openMassFavoriteConfirm,
    };
};

export default useCrowdTaskCardInviteCandidatesListAction;