import React, {useEffect, useState} from "react";
import {
    MapContainer,
    Marker,
    TileLayer,
    Tooltip,
    useMapEvents,
} from "react-leaflet";
import {useSelector} from "react-redux";
import {isEqual} from "lodash";

import {useLeafletHideLogo} from "./hooks/useHideLogo";

import {redIcon} from "../../constants/map";

import {federalMigrationFetchedByCoordsSelector} from "../../ducks/federalMigration";

const MarkersOVM = (props) => {
    const {
        placeMarks,
        updateMarkers,
    } = props;

    if (!placeMarks.length) {
        return null;
    }

    return placeMarks.map((value, index) => {
        const {
            lng,
            lat,
            icon,
            nameDepartment,
            federalId,
        } = value;

        return (
            <Marker
                key={index}
                icon={icon}
                eventHandlers={{
                    click: () => {
                        updateMarkers(federalId);
                    },
                }}
                position={{lng, lat}}
            >
                {
                    nameDepartment ?
                        <Tooltip
                            opacity={1}
                            offset={[0, -36]}
                            direction="top"
                        >
                            {nameDepartment}
                        </Tooltip> :
                        null
                }
            </Marker>
        );
    });
};

const LocationMarker = props => {
    const {
        fetchAddressByCoords,
        coords,
        tooltipContent,
        updateForm,
    } = props;

    const [position, setPosition] = useState(null);

    useEffect(() => {
        if (coords) {
            setPosition(coords);
        }
    }, [coords]);

    const map = useMapEvents({
        click(event) {
            setPosition(event.latlng);

            const {lat,lng} = event.latlng;

            updateForm({
                latitude: lat,
                longitude: lng,
            });

            map.setView([lat, lng]);

            fetchAddressByCoords({lat, lng});
        },
    });

    return position === null ? null : (
        <Marker
            icon={redIcon}
            position={position}
        >
            {
                tooltipContent ?
                    <Tooltip
                        permanent
                        opacity={1}
                        offset={[0, -36]}
                        direction="top"
                    >
                        {tooltipContent}
                    </Tooltip> :
                    null
            }
        </Marker>
    );
};

const OpenStreetMap = props => {
    const {
        longitude,
        latitude,
        tooltipContent,
        fetchAddressByCoords,
        updateForm,
        className,
        OVMs = false,
        placeMarks,
        scrollWheelZoom = true,
        mapInfo,
        updateMarkers,
    } = props;

    const [map, setMap] = useState(null);
    const fetchedByCoords = useSelector(federalMigrationFetchedByCoordsSelector);

    const coords = latitude && longitude ? {lat: latitude, lng: longitude} : null;

    useLeafletHideLogo();

    useEffect(() => {
        if (longitude && latitude && map) {
            map.setView([latitude, longitude]);
        }
    }, [latitude, longitude, map]);

    return (
        <MapContainer
            className={className}
            whenCreated={setMap}
            center={[55.75, 37.57]}
            zoom={9}
            scrollWheelZoom={scrollWheelZoom}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker
                coords={coords}
                fetchAddressByCoords={fetchAddressByCoords}
                tooltipContent={tooltipContent}
                updateForm={updateForm}
            />
            {
                OVMs && isEqual(fetchedByCoords, coords) &&
                    <MarkersOVM
                        placeMarks={placeMarks}
                        updateMarkers={updateMarkers}
                    />
            }
        </MapContainer>
    );
};

export default OpenStreetMap;