import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";
import SelectPhoneCodeForm from "../../../../../../../components/SelectPhoneCodeForm";

import {useDataContractorContactInfoEditForm} from "./hooks/useData";

import {ls, USER_ROLE} from "../../../../../../../utils/localstorage";
import {getPhoneMask} from "../../../../../../../utils/stringFormat";

import {CONTRACTOR_FIELD} from "../../../../../../../constants/contractor";
import {ADMIN, NM_MANAGER} from "../../../../../../../constants/roles";

import {bffCommonDictsPhoneCodeListSelector} from "../../../../../../../ducks/bff/common/dicts/selectors";
import {contractorProgressSelector} from "../../../../../../../ducks/contractor";

const ContractorContactInfoEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
    } = props;

    const progress = useSelector(contractorProgressSelector);
    const phoneCodeList = useSelector(bffCommonDictsPhoneCodeListSelector);

    const role = ls(USER_ROLE);

    const {
        values,
        setFieldValue,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
    } = useDataContractorContactInfoEditForm({
        contractorId,
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Контактные данные
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                <SelectPhoneCodeForm
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                />
                <div className="row">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            label="Телефон"
                            required
                            mask={getPhoneMask(values.phoneCodeId, values.locatedOutsideRussia, phoneCodeList)}
                            maskChar={null}
                            name={CONTRACTOR_FIELD.PHONE}
                            value={values[CONTRACTOR_FIELD.PHONE]}
                            disabled={
                                ![ADMIN, NM_MANAGER].includes(role) ||
                                values.locatedOutsideRussia && !values.phoneCodeId
                            }
                            onChange={handleChange}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.PHONE, true)}
                            error={
                                touched?.[CONTRACTOR_FIELD.PHONE] &&
                                errors?.[CONTRACTOR_FIELD.PHONE]
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            label="Email"
                            name={CONTRACTOR_FIELD.EMAIL}
                            value={values[CONTRACTOR_FIELD.EMAIL]}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched(CONTRACTOR_FIELD.EMAIL, true)}
                            error={
                                touched?.[CONTRACTOR_FIELD.EMAIL] &&
                                errors?.[CONTRACTOR_FIELD.EMAIL]
                            }
                        />
                    </div>
                </div>
            </NmForm>
        </NmModal>
    );
};

export default ContractorContactInfoEditForm;