import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import validationAddClient from "./validation-rule";

import bem from "../../../../../../../utils/bem";

import {clientListOptimizedOptionsSelector, getOptimizedClientList} from "../../../../../../../ducks/client";

import "./style.sass";

const AddClientsForm = (props) => {
    const {
        className,
        submit,
    } = props;

    const [block, element] = bem("add-group-form", className);
    const options = useSelector(clientListOptimizedOptionsSelector);

    const dispatch = useDispatch();

    const fetchList = (name) => {
        dispatch(getOptimizedClientList({
            pageNum: 1,
            pageSize: 100,
            archivedFilter: false,
            nameSubstringFilter: name || undefined,
            clientsNotGroupsFilter: true,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            clientIds: [],
        },
        onSubmit: (values) => {submit(values);},
        validationSchema: validationAddClient(),
    });

    const handleChangeDropdown = (e, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmForm
            id="AddClientForm"
            noValidate
            onSubmit={handleSubmit}
            className={block()}
        >
            <div className={element("control")}>
                Выберите заказчиков, которых требуется добавить в группу компаний
            </div>
            <NmDropdownV2
                size="xl"
                fullWidth
                placeholder="Выберите заказчика"
                required
                label="Заказчик"
                search
                className={element("control")}
                name="clientIds"
                onSearchChange={fetchList}
                multiple
                onChange={handleChangeDropdown}
                onBlur={() => setFieldTouched("clientIds", true)}
                value={values.clientIds}
                options={options}
                error={touched.clientIds
                    ? errors && errors.clientIds
                    : false
                }
            />
        </NmForm>
    );
};

AddClientsForm.defaultProps = {
    data: {
        clientIds: "",
    },
};

export default AddClientsForm;