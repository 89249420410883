import {formatAmountWithNullChecking} from "../../../utils/stringFormat";

export const getDepositValuesCards = (params) => {
    const {
        depositValues,
        isCivil,
        depositDistributedByObjects,
        projectId,
        objectId,
        isLoading,
        isAccordion,
    } = params;

    const {
        nonDistributedDeposit = 0, //Нераспред. депозит
        availableForOrdersAmount = 0,// Доступно для заказов
        availableForPaymentsAmount = 0, // Доступно для выплат
        awaitingConfirmationPaymentsAmount = 0, // Оплаты на рассмотрении
        depositAmount = 0, // Депозит
        inProgressPaymentsAmount = 0, // В процессе оплаты
        limitAmount = 0, // Лимит заказов
        limitSpentAmount = 0, // Израсходовано лимита
    } = depositValues;

    const projectPage = projectId ? " по проекту" : "";
    const objectPage = objectId ? " по объекту" : "";

    const getDepositTitle = () => {
        if (objectId) {
            return "Депозит объекта";
        }

        if (projectId) {
            return "Депозит проекта";
        }

        return depositDistributedByObjects ? "Распред. депозит" : "Депозит";
    };

    const getDepositTooltip = () => {
        if (!depositDistributedByObjects) {
            return null;
        }

        return {
            text: isCivil?
                "Содержит сумму распределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НДФЛ" :
                "Содержит сумму распределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НПД",
            type: "light",
            position: "bottom-left",
        };
    };

    const isShowNotSetText = !depositDistributedByObjects && (projectId || objectId);

    return {
        nonDistributedDeposit:  {
            isLoading,
            title: "Нераспред. депозит",
            value: `${formatAmountWithNullChecking(nonDistributedDeposit)} ₽`,
            helpTooltip: {
                text: isCivil?
                    "Содержит сумму нераспределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НДФЛ. Этот депозит не может использоваться в заказах и оплатах до тех пор, пока он не будет распределен по объектам компании. Для распределения депозита по объектам перейдите в раздел \"Компания → Проекты\", выберите необходимый в проекте объект и нажмите \"Пополнить депозит объекта\"" :
                    "Содержит сумму нераспределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НПД. Этот депозит не может использоваться в заказах и оплатах до тех пор, пока он не будет распределен по объектам компании. Для распределения депозита по объектам перейдите в раздел \"Компания → Проекты\", выберите необходимый в проекте объект и нажмите \"Пополнить депозит объекта\"",
                type: "light",
                position: "bottom-left",
            },
        },
        deposit:  {
            isLoading,
            title: getDepositTitle(),
            value: !isShowNotSetText ? `${formatAmountWithNullChecking(depositAmount)} ₽` : "Не установлен",
            helpTooltip: getDepositTooltip(),
        },
        awaitingConfirmationPayments: {
            isLoading,
            title: `Оплаты на рассмотрении${projectPage}${objectPage}`,
            value: `${formatAmountWithNullChecking(awaitingConfirmationPaymentsAmount)} ₽`,
            helpTooltip: {
                text: isCivil ?
                    "Содержит сумму выплат по договорам НДФЛ, которые находятся на подтверждении у исполнителей и заказчиков. Для ознакомления со списком оплат на рассмотрении перейдите в раздел \"Депозит (НДФЛ) → Оплаты на рассмотрении\"" :
                    "Содержит сумму выплат Самозанятым и ИП, которые находятся на подтверждении у исполнителей и заказчиков, сумму оплат по заданиям на проверке. Для ознакомления со списком оплат на рассмотрении перейдите в раздел \"Депозит (НПД) → Оплаты на рассмотрении\"",
                type: "light",
                position: "bottom-left",
            },
        },
        inProgressPayments: {
            isLoading,
            title: `В процессе оплаты${projectPage}${objectPage}`,
            value: `${formatAmountWithNullChecking(inProgressPaymentsAmount)} ₽`,
            helpTooltip: {
                text: isCivil?
                    "Содержит суммы выплат исполнителям с типом налогообложения НДФЛ и выплат по патентам, которые находятся в процессе проведения в банке, а также сумму, которая зарезервирована под реестровые и созданные через API выплаты для таких исполнителей. Для ознакомления со списком перейдите в раздел \"Депозит (НДФЛ) → В процессе оплаты\"" :
                    "Содержит сумму выплат Самозанятым и ИП, которые находятся в процессе проведения в банке, а также сумму, которая зарезервирована под реестровые и созданные через API выплаты Самозанятым, оплаты по заданиям в процессе согласования исполнителем или администрацией Наймикс. Для ознакомления со списком перейдите в раздел \"Депозит (НПД) → В процессе оплаты\"",
                type: "light",
                position: "bottom-left",
            },
        },
        availableForPayments: {
            isLoading,
            title: `Доступно для выплат${projectPage}${objectPage}`,
            value: !isShowNotSetText ? `${formatAmountWithNullChecking(availableForPaymentsAmount)} ₽` : "Не установлено",
            helpTooltip: {
                text: isCivil ?
                    "Содержит сумму свободных средств для выплат по договорам НДФЛ. Рассчитывается как разница между депозитом и выплатами \"в процессе\"" :
                    "Содержит сумму свободных средств для выплат Самозанятым и ИП. Рассчитывается как разница между депозитом и выплатами \"в процессе\"",
                type: "light",
                position: "bottom-left",
            },
        },
        limit: {
            isLoading,
            title: `Лимит заказов${projectPage}${objectPage}`,
            value: !isShowNotSetText ? `${formatAmountWithNullChecking(limitAmount)} ₽` : "Не установлен",
            helpTooltip: {
                text: isCivil ?
                    "Максимальная сумма, на которую единовременно могут быть созданы заказы для работающих по договорам НДФЛ исполнителей. Рассчитывается как сумма депозита и лимита для заказов без обеспечения (НДФЛ)" :
                    "Максимальная сумма, на которую единовременно могут быть созданы заказы и задания для Самозанятых. Рассчитывается как сумма депозита и лимита для заказов без обеспечения (НПД)",
                type: "light",
                position: "bottom-left",
            },
        },
        limitSpent: {
            isLoading,
            title: `Израсходовано лимита${projectPage}${objectPage}`,
            value: `${formatAmountWithNullChecking(limitSpentAmount)} ₽`,
            helpTooltip: {
                text: isCivil ?
                    "Сумма, которая в настоящее время зарезервирована в заказах для работающих по договорам НДФЛ исполнителей, с учетом комиссии" :
                    "Сумма, которая в настоящее время зарезервирована в заказах и заданиях для Самозанятых с учетом комиссии. Для ознакомления со списком заказов и заданий, которые зарезервировали лимит, перейдите в раздел \"Депозит (НПД) → Израсходовано лимита\"",
                type: "light",
                position: "bottom-left",
            },
        },
        availableForOrders: {
            isLoading,
            title: `Доступно для заказов${projectPage}${objectPage}`,
            value: !isShowNotSetText ? `${formatAmountWithNullChecking(availableForOrdersAmount)} ₽` : "Не установлено",
            helpTooltip: {
                text: isCivil ?
                    "Остаток суммы лимита для публикации новых заказов для работающих по договорам НДФЛ исполнителей. Рассчитывается как разница между лимитом заказов и израсходованным лимитом" :
                    "Остаток суммы лимита для публикации новых заказов и заданий для Самозанятых. Рассчитывается как разница между лимитом заказов и израсходованным лимитом",
                type: "light",
                position: isAccordion ? "bottom-left" : "bottom-right",
            },
        },
    };
};