import React from "react";

import {ReactComponent as NoOnIcon} from "../../../../images/no_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../images/yes_on.svg";

import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {COLOR} from "../../../../constants/color";
import {KedoParticipantDocumentStatus} from "../constants";

interface GetDocumentStatusOfSignatory {
    status?: string,
    date?: string,
    rejectReason?: string,
    isSender?: boolean,
    errorMessage?: string,
    participantStatesDict: Record<string, string>,
    isShowOnSignGosKey?: boolean,
}

export interface GetDocumentStatusOfSignatoryReturn {
    icon: React.ReactNode,
    iconColor: string,
    titleColor: string,
    title: string,
    date?: string,
    text?: string,
}

export const getKedoParticipantDocumentStatus = (params: GetDocumentStatusOfSignatory): GetDocumentStatusOfSignatoryReturn | null => {
    const {
        status,
        date,
        rejectReason,
        isSender,
        isShowOnSignGosKey = true,
    } = params;

    const nameOfSignatory = isSender ? "Отправитель" : "Получатель";

    if (status === KedoParticipantDocumentStatus.IN_LINE_FOR_SIGNING) {
        return {
            icon: YesOnIcon,
            iconColor: COLOR.SECONDARY_10,
            titleColor: COLOR.INERT_100,
            title: "В очереди получателя",
        };
    }

    if (status === KedoParticipantDocumentStatus.SIGNED) {
        return {
            icon: YesOnIcon,
            iconColor: COLOR.PRIMARY_100,
            titleColor: COLOR.PRIMARY_100,
            title: `${nameOfSignatory} подписал`,
            date: dateFormat(convertUtcToLocal(date), "dd.MM.yyyy"),
        };
    }

    if (status === KedoParticipantDocumentStatus.REJECTED) {
        return {
            icon: NoOnIcon,
            iconColor: COLOR.NEGATIVE_100,
            titleColor: COLOR.NEGATIVE_100,
            title: `${nameOfSignatory} отклонил`,
            text: rejectReason,
        };
    }

    if (status === KedoParticipantDocumentStatus.ON_SIGNING && isShowOnSignGosKey) {
        return {
            icon: YesOnIcon,
            iconColor: COLOR.SECONDARY_10,
            titleColor: COLOR.INERT_100,
            title: `${nameOfSignatory} подписывает`,
            text: "Документ находится на подписании у сотрудника в приложении Госключ",
        };
    }

    if (status === KedoParticipantDocumentStatus.GOS_KEY_SEND_WAITING) {
        return {
            icon: YesOnIcon,
            iconColor: COLOR.SECONDARY_10,
            titleColor: COLOR.BLACK_80,
            title: "Отправка в Госключ",
            text: "Документ передается в кабинет Госключа и скоро станет доступен для подписания",
        };
    }

    if (KedoParticipantDocumentStatus.GOS_KEY_SEND_ERROR === status) {
        return {
            icon: NoOnIcon,
            iconColor: COLOR.NEGATIVE_100,
            titleColor: COLOR.NEGATIVE_100,
            title: "Ошибка отправки в Госключ",
            text: "Не удалось отправить документ в Госключ. Повторите попытку отправки с помощью функции \"Отправить в Госключ повторно\"",
        };
    }

    return null;
};