import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmPage from "../../../components/NmPage";
import TableDiv from "../../../components/TableDiv";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    phoneFormat,
    removePhoneMaskWithCountryCode,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {headersTechsupportList as headers} from "../../../constants/headersTable";
import {LINK_SETTINGS_TECHSUPPORT_CARD} from "../../../constants/links";
import {
    ADMIN,
    NM_MANAGER,
} from "../../../constants/roles";

import {
    getCallTypeStatusDictSelector,
    getCaseStatusDictSelector,
    getSupportListSelector,
    getSupports, getSupportTotalCountSelector,
    getSupportTotalPagesSelector,
} from "../../../ducks/contractor";

import {supportType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class TechsupportList extends Component {
    static propTypes = {
        totalPages: PropTypes.number,
        selectedClientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        supports: PropTypes.arrayOf(supportType),
        callTypeStatusDict: PropTypes.object,
        caseStatusDict: PropTypes.object,
        getSupports: PropTypes.func,
    };

    static defaultProps = {
        totalPages: 0,
        selectedClientId: "",
        supports: [],
        callTypeStatusDict: {},
        caseStatusDict: {},
        getSupports: () => {},
    };

    pageSizes = [25, 50, 100];

    constructor() {
        super();

        this.state = {
            pageNum: 1,
            pageSize: 25,
            phoneNumberFilter: "",
            fromDateFilter: undefined,
            toDateFilter: undefined,
        };

        this.role = ls(USER_ROLE);
        this.isEditable = this.role === ADMIN || NM_MANAGER;
    }

    componentDidMount() {
        document.addEventListener("keypress", this.handleKeyPressFilter);

        this.fetchList();
    }

    componentWillUnmount() {
        document.removeEventListener("keypress", this.handleKeyPressFilter);
    }

    handleKeyPressFilter = ({key}) => {
        if (key === "Enter") {
            this.fetchList();
        }
    };

    fetchList = () => {
        const {getSupports} = this.props;
        const {
            phoneNumberFilter: phoneFilter,
            pageNum,
            pageSize,
            fromDateFilter,
            toDateFilter,
        } = this.state;

        getSupports({
            pageNum,
            pageSize,
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMaskWithCountryCode(phoneFilter),
            fromDateFilter: fromDateFilter ? dateFormat(fromDateFilter, "yyyy-MM-dd") : undefined,
            toDateFilter: toDateFilter ? dateFormat(toDateFilter, "yyyy-MM-dd") : undefined,
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    handleChangeSearch = (e, {value, name}) => {
        this.setState(prevState => (
            {
                ...prevState,
                [name]: value,
                pageNum: 1,
            }),
        );
    };

    mapTableData() {
        const {supports, callTypeStatusDict, caseStatusDict} = this.props;

        const rows = supports.map(item => {
            const linkToCard = LINK_SETTINGS_TECHSUPPORT_CARD.replace(":caseNumber", item.caseNumber);

            const fioContractor = `${item.lastName} ${item.firstName} ${item.patronymic || ""}`;
            return {
                ...item,
                fioContractor:  this.isEditable ?
                    <Link to={linkToCard}>
                        {fioContractor}
                    </Link> : fioContractor,
                phone: phoneFormat(item.phone),
                caseStatus: caseStatusDict[item.caseStatus],
                typeOfAppeal: callTypeStatusDict[item.typeOfAppeal],
                dateTime: dateFormat(convertUtcToLocal(item.dateTime)),
            };
        });

        return {
            headers,
            rows,
        };
    }

    clearFilter = () => {
        this.setState({
            phoneNumberFilter: "",
            fromDateFilter: undefined,
            toDateFilter: undefined,
        }, this.fetchList);
    };

    renderFilter() {
        const {
            phoneNumberFilter,
            fromDateFilter,
            toDateFilter,
        } = this.state;

        const {
            t,
        } = this.props;

        return (
            <NmForm
                horizontal
                className="tech-support-list__filter"
            >
                <div className="tech-support-list__filter-contractor">
                    <NmInputV2
                        size="lg"
                        label={t("techsupport-list.contractor-phone-number")}
                        value={phoneNumberFilter}
                        name="phoneNumberFilter"
                        mask="+7 999 999 9999"
                        onChange={this.handleChangeSearch}
                    />
                </div>
                <div className="tech-support-list__filter-date">
                    <NmDateRangePickerV2
                        size="lg"
                        label={t("techsupport-list.period")}
                        onChange={this.handleChangeSearch}
                        placeholderStart={t("techsupport-list.beginning-period")}
                        placeholderEnd={t("techsupport-list.end-period")}
                        startFieldName="fromDateFilter"
                        endFieldName="toDateFilter"
                        value={{
                            fromDateFilter,
                            toDateFilter,
                        }}
                        isCurrentDateMax
                    />
                </div>
                <FilterButtonsV2
                    className="tech-support-list__filter-actions"
                    onSearch={this.fetchList}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    render() {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            totalPages,
            totalCount,
        } = this.props;

        return (
            <NmPage
                className="tech-support-list"
                typeFilter="horizontal"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                showHeaderBlock={false}
                filtersBase={this.renderFilter()}
                noPadding
            >
                <TableDiv
                    tableData={this.mapTableData()}
                />
            </NmPage>
        );
    }
}


export default connect(
    state => ({
        supports: getSupportListSelector(state),
        totalPages: getSupportTotalPagesSelector(state),
        totalCount: getSupportTotalCountSelector(state),
        callTypeStatusDict: getCallTypeStatusDictSelector(state),
        caseStatusDict: getCaseStatusDictSelector(state),
    }),
    {
        getSupports,
    },
)(withTranslation()(TechsupportList));

