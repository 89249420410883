import {dictionaryToOptions} from "./objectHelper";

export const getClientUsagesOrderTypesSortOptions = (dict) => {
    const defaultSort = "USING_TEMPLATE_FIRST"; // Используют шаблон

    const options = dictionaryToOptions(dict);

    return options.map(item => {
        if (item.value === defaultSort) {
            return {
                ...item,
                isDefaultSort: true,
            };
        }

        return item;
    });
};