export const FINANCE_LOG_TYPE = {
    TRANSACTION_LOG: "transactionLog",
    ORDER_WORK_REPORT_LOG: "orderWorkReportLog",
    PATENT_PAYMENTS_LOG: "patentPaymentsLog",
    SYSTEM_TRANSACTION_LOG: "systemTransactionLog",
};

export const FINANCE_FIELD_NAME = {
    ACCEPT_DATE: "acceptDate",
    ARCHIVED: "archived",
    BANK_CARD_NUMBER: "bankCardNumber",
    CLIENT_ID: "clientId",
    CLIENT_NAME: "clientName",
    CLIENT_USER_ID: "clientUserId",
    CONTRACTOR_ID: "contractorId",
    DATE: "date",
    DESCRIPTION: "description",
    //Дата операции - дата последнего изменения статуса "Наймикс" в формате ДД.ММ.ГГГГ ЧЧ:ММ
    LAST_UPDATE: "lastUpdate",
    LINK: "link",
    ORDER_ID: "orderId",
    ORDER_NUM: "orderNum",
    ORDER_WORK_REPORT_ID: "orderWorkReportId",
    PAYMENT_NUMBER: "paymentNumber",
    PAYMENT_STATUS: "paymentStatus",
    //Комиссия исполнителя - сумма комиссии, равная 4% от суммы операции
    //называется так, потому что это изначально комиссия с платежа платформе
    PLATFORM_AMOUNT: "platformAmount",
    REASON_FOR_REFUSAL: "reasonForRefusal",
    RECEIPT_ID: "receiptId",
    SMZ_AMOUNT: "smzAmount",
    STATUS_OF_THE_TRANSFER: "statusOfTheTransfer",
    //Сумма операции - суммы выплаты, указанная НУ при инициировании выплаты СЗму
    SUM_OF_MONEY: "sumOfMoney",
    TAX_AMOUNT: "taxAmount",
};