import React, { useEffect,useRef, useState } from "react";

import {Input} from "semantic-ui-react";

import PropTypes from "prop-types";

import "./style.sass";

const Search = ({ value, sort, isMobile, className = "", handleChangeSearch, handleKeyDown, content, isSort, isSearch }) => {
    const [isOpen, setIsOpen] = useState(false);
    const $helpContainer = useRef(null);
    const $inputSearch = useRef(null);

    useEffect(() => {
        if (isOpen && $inputSearch && $inputSearch.current) {
            $inputSearch.current.focus();
        }
    }, [isOpen]);

    const calculateWidth = () => {
        if ($helpContainer.current && $helpContainer)
        {return `${$helpContainer.current.getBoundingClientRect().width}px`;}
    };

    const handleBlur = () => {
        setIsOpen(false);
    };

    const handleChange = ({ value }) => {
        handleChangeSearch(value);
    };

    const handleClickSort = () => {
        if (sort === "desc") {
            handleChangeSearch("asc");

            return;
        }

        handleChangeSearch("desc");
    };

    const onKeyDown = (keyCode, { value }) => {
        if (!handleKeyDown) {return;}

        handleKeyDown(keyCode, value);
    };

    const getClassNames = () => {
        const classNames = {
            filterInput: ["search-filter-input-current"],
            sortIconEditMode: ["material-icons notranslate"],
            sortIconViewMode: ["material-icons icon-sort notranslate search-filter-input__icon"],
        };

        if (!isSort) {
            classNames.filterInput.push("search-filter-input-current-no-sort");
        } else {
            classNames.filterInput.push("search-filter-input-current-sort");
        }

        if (sort === "asc") {
            classNames.sortIconEditMode.push("transformed");
            classNames.sortIconViewMode.push("transformed");
        }

        return {
            filterInput: classNames.filterInput.join(" "),
            sortIconEditMode: classNames.sortIconEditMode.join(" "),
            sortIconViewMode: classNames.sortIconViewMode.join(" "),
        };
    };

    return (
        isOpen ?
            <div
                className={`td-container ${className}`}
                style={{maxWidth: isMobile ? "auto" : calculateWidth()}}
            >
                <Input className="search-filter-input">
                    {isSearch ?
                        <>
                            <div className="icon-search">
                                <i className="material-icons notranslate">
search
                                </i>
                            </div>
                            <input
                                onKeyDown={(e) => {onKeyDown(e.keyCode, e.target); }}
                                placeholder={content}
                                ref={$inputSearch}
                                value={value}
                                onBlur={handleBlur}
                                onChange={(e) => { handleChange(e.target); }}
                                className={getClassNames().filterInput}
                            />
                        </> :
                        null
                    }
                    {isSort ?
                        <div
                            onMouseDown={handleClickSort}
                            className="icon-sort"
                        >
                            <i className={getClassNames().sortIconEditMode}>
sort
                            </i>
                        </div> :
                        null
                    }
                </Input>
            </div> :
            <div
                ref={$helpContainer}
                className="td-container search-filter-no-active"
            >
                {content}
                {isSearch ?
                    <i
                        onClick={() => {
                            setIsOpen(true);
                        }}
                        className="icon-search material-icons notranslate"
                    >
search
                    </i> :
                    null
                }
                {isSort ?
                    <i
                        onClick={handleClickSort}
                        className={getClassNames().sortIconViewMode}
                    >
sort
                    </i> :
                    null
                }
            </div>
    );
};

Search.propTypes = {
    sort: PropTypes.string,
    isMobile: PropTypes.bool,
    className: PropTypes.string,
    handleChangeSearch: PropTypes.func,
    handleKeyDown: PropTypes.func,
    content: PropTypes.string,
    isSort: PropTypes.bool,
};

export default Search;