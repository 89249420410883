import {ls, USER_ROLE} from "../../../utils/localstorage";

import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

export const getVisibleInstructionByType = (type) => {
    const role = ls(USER_ROLE);

    const access = {
        CLIENT_ADMIN_INSTRUCTION: [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
        ].includes(role),
        FOREMAN_INSTRUCTION:
            [
                ADMIN,
                NM_MANAGER,
                NM_COORDINATOR,
                NM_CHIEF_ACCOUNTANT,
                CLIENT_ADMIN,
                CLIENT_ACCOUNTANT,
                PROJECT_MANAGER,
                OBJECT_MANAGER,
                FOREMAN,
            ].includes(role),
        PROJECT_MANAGER_INSTRUCTION: [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            PROJECT_MANAGER,
        ].includes(role),
        OBJECT_MANAGER_INSTRUCTION: [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
        ].includes(role),
        HR_MANAGER_INSTRUCTION: [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            PROJECT_MANAGER,
            HR_MANAGER,
        ].includes(role),
        CLIENT_ACCOUNTANT_INSTRUCTION: [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
        ].includes(role),
    };

    return access[type];
};