import {isEmpty} from "lodash";

import {roundNumberWithPoint} from "./mathHelper";
import {isNullOrWhitespace} from "./stringHelper";

import {contractorMask} from "../constants/contractorInfo";
import {LENGTH_FOREIGN_PHONE} from "../constants/phone";
import {phoneReg} from "../constants/validationRules";
import {RECRUITMENT_SOURCE_JOB_BOARD} from "../containers/recruitment/vacancies/constants";

export const phoneFormat = (text, locatedOutsideRussia = false) => {
    if (!text) {
        return "";
    }

    if (locatedOutsideRussia || !phoneReg.test(text)) {
        return phoneFormatLocatedOutsideRussia(text);
    }

    return text.toString().replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");
};

export const phoneFormatLocatedOutsideRussia = text => {
    return text ? `+${text}` : "";
};

export const fioInitialsFormat = fio => {
    return fio ? fio
        .split(/\s+/).map((w, i) => i ? w.substring(0, 1).toUpperCase() + "." : w).join(" ")
        .replace(/\s[.]$/, "") : "";
};

export const replaceInformation = (information) => {
    const matchAll = Array.from((information || "").matchAll("((http(s)?:\\/\\/)|(www\\.))([^\\.]+)\\.([^\\s]+)"));

    let stringHtml = `${information}`;

    const replaceAt = (search, replacement) => {
        return stringHtml.split(search).join(replacement);
    };

    matchAll.forEach(item => {
        stringHtml = replaceAt(item[0], `<a href=${item[0]} target="_blank">${item[0]}</a>`);
    });

    return stringHtml;
};

/***
 * С бэка обрабатывает номер телефона в отформатированный вид без +7 вначале 70002821117 -> (000) 282-11-17
 * @param text
 * @returns {string}
 */
export const phoneFormatWithoutSevenNumber = text => {
    return text ? text.toString().replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "($2) $3-$4-$5") : "";
};

export const innFormat = text => {
    return text ? text.toString().replace(/(\d{4})(\d{6})(\d{2})/, "$1-$2-$3") : "";
};

export const removePhoneMask = (phone = "") => phone === null ? null : phone.replace(/[+_ ()-]/gi, "");
export const removeEinMask = (ein) => isNullOrWhitespace(ein) ? null : ein.toString().replace("-", "");
export const removeOgrnipMask = (value) => isNullOrWhitespace(value) ? null : value.toString().replace(/[ -]/gi, "");
export const removeSnilsMask = (snils = "") => snils === null ? null : snils.split("_").join("").split("-").join("").split(" ").join("");
export const removePhoneMaskWithCountryCode = (phone = "") => phone === null ? null : phone.replace(/\+7|_| |/gi, "");
export const removePhoneMaskWithParentheses = (phone = "") => phone === null ? null : phone.replace(/\+7|_| |\(|\)|/gi, "");
export const addPhoneMaskWithParentheses = (phone = "") => phone === null ? null : phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/gi, "+7 ($1) $2 $3 $4");
export const formatNumber = (num, fixedNumber) => {
    if (isNullOrWhitespace(num)) {
        return "";
    }
    let _num = num;

    if (fixedNumber) {
        _num = Number(num).toFixed(fixedNumber);
    }

    return _num.toString().replace(".", ",");
};
export const formatAmount = (number, isShowRubleIcon = false) => {
    return `${String(number).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ")}${isShowRubleIcon ? " ₽" : ""}`;
};

/***
 * доработанный formatAmount с проверкой на null и заменой текстом
 * @param number
 * @param text
 * @returns {string}
 */
export const formatAmountWithNullChecking = (number, text = "0,00") => {
    if (isNullOrWhitespace(number)) {
        return text;
    }

    return formatAmount(formatNumber(number, 2));
};

/***
 * доработанный formatAmount, из числа 12311.12 делает строку типа 12 311,12
 * @param number
 * @returns {string}
 */
export const formatAmountWithComma = number => formatAmount(number).replace(".", ",");

/***
 * из строки типа 12 123 132,12 получает число, если число на входе, то возвращает число
 * @param num
 * @returns {number}
 */
export const getNumberFromFormattedAmount = (num) => {
    if (typeof num === "number") {
        return num;
    }

    return parseFloat(clearSpace(String(num).replace(",", ".")));
};

export const getNumberForEquals = (num) => {
    if (typeof num === "number") {
        return num;
    }
    if (isEmpty(num)) {
        return "";
    }
    return parseFloat(clearSpace(String(num).replace(",", ".")));
};

export const formatBankCard = text => {
    return text ? text.toString().replace(/\s+/g, "").replace(/(\d{4})(\d{4})(\d{4})(\d)/, "$1 $2 $3 $4") : "";
};
export const formatBankCardV2 = (text) => {
    if (isNullOrWhitespace(text)) {
        return "";
    }
    const _text = text.toString().replace(/\s+/g, "");

    return `${_text.substring(0, 4)} ${"*".repeat(4)} ${"*".repeat(4)} ${_text.substr(12)}`;
};

export const removeVolumeMask = (volume = "") => volume === null ? null : volume.replace(/_/gi, "0");

export const getFullName = (lastName = "", firstName = "", patronymic = "") => {
    const _firstName = firstName ? ` ${firstName}` : "";
    const _patronymic = patronymic ? ` ${patronymic}` : "";

    return `${lastName || ""}${_firstName}${_patronymic}`;
};

export const getFormattedFullName = (fullName) => {

    if (!fullName) {
        return "";
    }

    const words = fullName.split(" ");

    if (words[1]) {
        words[1] = ` ${words[1].charAt(0).toUpperCase()}.`;
    }
    if (words[2]) {
        words[2] = ` ${words[2].charAt(0).toUpperCase()}.`;
    }

    return words.join(" ");
};

export const formatNumberAmount = (amount, mark = true) => isNullOrWhitespace(amount) ? "" : `${formatAmount(formatNumber(amount, 2))} ${mark ? "₽" : ""}`;

/**
 * Очистить пробелы
 * @param str
 * @returns {string}
 */

export const clearSpace = str => {
    if (isNullOrWhitespace(str)) {
        return str;
    }

    if (typeof str !== "string") {
        str = str.toString();
    }

    return str.replace(/\s+/g, "");
};

/**
 * Сопоставить пробелы, которые не являются символами табуляции или перевода строки, и заменить их обычным пробелом
 * @param str
 * @returns {string}
 */

export const replaceSpace = str => {
    if (isNullOrWhitespace(str)) {
        return str;
    }

    if (typeof str !== "string") {
        str = str.toString();
    }

    return str.replace(/(?=\s)[^\r\n\t]/g, " ");
};

export function divideNumberIntoDigits(num) {
    if (isNullOrWhitespace(num)) {
        return num;
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

/***
 * Для ситуаций, когда на форме может использоваться разделитель у числа в виде запятой,
 * также преобразует значение в число
 * @param formAmount
 * @returns {number}
 */
export const getFormAmount = formAmount => formAmount && !isNaN(Number(formAmount.replace(",", "."))) ? Number(formAmount.replace(",", ".")) : 0;

/***
 * Округляет и заменяет точку на запятую в записи числа.
 * @param number : number
 * @returns {*}
 */
export const formatNumberWithComma = (number) => formatAmount(roundNumberWithPoint(number).replace(".", ","));
export const handleNewLineString = (str) => {
    if (isNullOrWhitespace(str)) {
        return "";
    }
    return str.replace(/\\n/g, "\n");
};

export const stringWithLinks = (text) => {
    const matchAll = Array.from((text || "").matchAll("((http(s)?:\\/\\/)|(www\\.))([^\\.]+)\\.([^\\s]+)"));

    let stringHtml = `${text}`;

    const replaceAt = (search, replacement) => {
        return stringHtml.split(search).join(replacement);
    };

    matchAll.forEach(item => {
        stringHtml = replaceAt(item[0], `<a href=${item[0]} target="_blank">${item[0]}</a>`);
    });

    return stringHtml;
};

export const getPhoneMask = (phoneCodeId, locatedOutsideRussia, phoneCodeList) => {
    if (!locatedOutsideRussia) {
        return contractorMask.PHONE.RUS;
    }

    const {code = ""} = phoneCodeList.find(item => item.codeId === phoneCodeId) || {};
    const maskChar = "9";

    return `+${getPhoneCodeForMask(code)}${maskChar.repeat(LENGTH_FOREIGN_PHONE.MAX - code.length)}`;
};

export const getPhoneCodeForMask = (code) => {
    if (!code) {
        return "";
    }

    const token = "9";

    return code.split(token).join("\\"+token);
};
export const getSalaryText = (item, emptyText = "Не указано") => {
    const {
        jobBoardType,
    } = item;

    const salaryFrom = item.salaryFrom ? formatAmount(item.salaryFrom) : null;
    const salaryTo = item.salaryTo ? formatAmount(item.salaryTo) : null;

    if (!salaryFrom && !salaryTo) {
        return emptyText;
    }

    if (salaryFrom && !salaryTo) {
        return `от ${salaryFrom} руб.`;
    }

    if (!salaryFrom && salaryTo) {
        return `до ${salaryTo} руб.`;
    }

    if (salaryFrom === salaryTo) {
        if (jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER) {
            return `до ${salaryFrom} руб.`;
        }

        if (
            [
                RECRUITMENT_SOURCE_JOB_BOARD.AVITO,
                RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX,
            ].includes(jobBoardType)
        ) {
            return `${salaryFrom} руб.`;
        }
    }

    return `от ${salaryFrom} до ${salaryTo} руб.`;
};