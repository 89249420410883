import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import useUsersRolesEditFormData from "./hooks/useFormData";

import {USERS_ROLES_TYPE} from "../../../../../constants/settingsUsersRoles";

import {currentUserRestrictionsAllOptionsSelector} from "../../../../../ducks/clientUserRestrictions";
import {settingsUsersRolesProgressActionSelector} from "../../../../../ducks/settingsUsersRoles";

function SettingsUsersRolesEditForm(props) {
    const {
        isEdit,
        fetchList,
        card = {},
        restrictionDescriptions = {},
        subPage,
        onClose = () => {},
    } = props;

    const actionProgress = useSelector(settingsUsersRolesProgressActionSelector);
    const restrictionsAllOptions = useSelector(currentUserRestrictionsAllOptionsSelector);
    const restrictionsOptions = restrictionsAllOptions.map(item => {
        return {
            ...item,
            tooltipText: restrictionDescriptions[item.value] || "",
        };
    });

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useUsersRolesEditFormData({card, isEdit, fetchList, subPage, onClose});

    const getTitle = () => {
        if (subPage === USERS_ROLES_TYPE.NAIMIX) {
            return isEdit ? "Редактирование роли Наймикс" : "Добавление новой роли Наймикс";
        }

        return isEdit ? "Редактирование роли компании" : "Добавление новой роли компании";
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {getTitle()}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={actionProgress}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    value={values.name}
                    name="name"
                    label="Название роли"
                    placeholder="Введите название для роли"
                    onChange={handleChange}
                    error={errors?.name}
                />
                <NmDropdownV2
                    size="xl"
                    required
                    placeholder="Выберите ограничения"
                    label="Ограничения роли"
                    name="restrictions"
                    onChange={handleChange}
                    error={errors?.restrictions}
                    value={values.restrictions}
                    options={restrictionsOptions}
                    multiple={true}
                    search={true}
                />
                <NmInputV2
                    size="xl"
                    required
                    value={values.description}
                    name="description"
                    label="Описание роли"
                    placeholder="Введите описание для удобства понимания назначения роли"
                    onChange={handleChange}
                    error={errors?.description}
                />
            </NmForm>
        </NmModal>
    );
}

export default SettingsUsersRolesEditForm;