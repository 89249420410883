import {
    BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST,
    BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST,
    BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST,
    BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST,
    BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST,
    UPDATE_CROWD_TASK_CARD_STORE,
} from "./actions";

export const getCardCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST,
        payload,
    };
};

export const getByNumberCardCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST,
        payload,
    };
};

export const getPageCrowdTaskContractorsInvited = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST,
        payload,
    };
};

export const inviteCrowdTaskContractor = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST,
        payload,
    };
};

export const getPageCrowdTaskContractorsQueue = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCrowdTaskContractorsWorking = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST,
        payload,
    };
};

export const rejectResultCrowdTaskContractorsWorking = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST,
        payload,
    };
};

export const approveResultCrowdTaskContractorsWorking = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST,
        payload,
    };
};

export const forceFinishCrowdTaskContractorsWorking = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST,
        payload,
    };
};

export const rejectCrowdTaskContractors = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST,
        payload,
    };
};

export const getCrowdTaskContractorsCompleted = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST,
        payload,
    };
};

export const inviteWithdrawCrowdTaskContractors = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST,
        payload,
    };
};

export const getPageCrowdTaskContractorsRejected = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageCrowdTaskContractorsInviteCandidates = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST,
        payload,
    };
};

export const scheduleOptimumIntegrationCrowdTaskContractors = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST,
        payload,
    };
};

export const getPageCrowdTaskLog = (payload) => {
    return {
        type: BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCountsCrowdTaskContractors = (payload) => {
    return {
        type: BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_REQUEST,
        payload,
    };
};

export const updateStoreCrowdTaskCard = (payload) => {
    return {
        type: UPDATE_CROWD_TASK_CARD_STORE,
        payload,
    };
};