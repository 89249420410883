import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import FilterSpecialities from "../../../../../../components/ActualComponents/FilterSpecialities";
import InputWithDropdownV2 from "../../../../../../components/ActualComponents/InputWithDropdownV2";
import NmDadata from "../../../../../../components/ActualComponents/NmDadata";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import AmountInput from "../../../../../../components/AmountInput";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {getInitialTariffGridEditValues} from "./utils/getInitialValues";
import {getTariffGridEditValidation} from "./utils/getValidation";

import {orderWorkUnitsOptionsSelector} from "../../../../../../ducks/order";
import {
    getByIdTariffScale,
    tariffScaleCardSelector,
    tariffScaleProgressCardSelector,
    updateTariffScaleStore,
} from "../../../../../../ducks/tariff";

export const SettingsDirectoriesTariffGridEdit = (props) => {
    const {
        progress,
        onClose,
        onSubmit,
        editData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (editData) {
            const {tariffScaleId} = editData;

            dispatch(getByIdTariffScale({tariffScaleId}));
        }

        return () => {
            dispatch(updateTariffScaleStore({card: {}}));
        };
    }, []);

    const card = useSelector(tariffScaleCardSelector);
    const progressCard = useSelector(tariffScaleProgressCardSelector);
    const unitOptions = useSelector(orderWorkUnitsOptionsSelector);

    const initialValues = {
        ...getInitialTariffGridEditValues(card),
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit: _onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: getTariffGridEditValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => setFieldValue(name, value);

    function _onSubmit() {
        if (!isValid) {
            return;
        }

        onSubmit(values);
    }

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Добавление сведений в тарифную сетку
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submit={handleSubmit}
                    onClose={onClose}
                    disabled={progress}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            loading={progressCard}
            onClose={onClose}
        >
            <NmForm addMargin>
                <FilterSpecialities
                    size="xl"
                    required
                    label="Вид деятельности"
                    placeholder="Выберите вид деятельности"
                    name="specialityId"
                    onChange={onChange}
                    value={values.specialityId}
                    error={touched.specialityId && errors.specialityId}
                    multiple={false}
                />
                <NmDadata
                    size="xl"
                    required
                    label="Город"
                    name="addressId"
                    minSearchLength={2}
                    placeholder="Введите город"
                    onChange={onChange}
                    multiple={false}
                    isAllSelectionShown={false}
                    fromBound="city"
                    toBound="city"
                    initialAddressIds={values.addressId}
                    error={touched.addressId && errors.addressId}
                />
                <div className="col-16 col-md-8">
                    <InputWithDropdownV2
                        size="xl"
                        label="Ставка"
                        required
                        selectName="unit"
                        valueSelect={values.unit}
                        error={
                            (touched.rate && errors.rate) ||
                            (touched.unit && errors.unit)
                        }
                        options={unitOptions}
                        onChange={onChange}
                        input={
                            <AmountInput
                                newInput
                                addZeroAfterDivider
                                value={values.rate}
                                placeholder="0,00"
                                name="rate"
                                size="xl"
                                onChange={onChange}
                            />
                        }
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};