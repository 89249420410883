export const ADVERTISEMENT_STATUS_DICT = {
    DRAFT: {
        VALUE: "DRAFT",
        TEXT: "Черновик",
        COLOR: "#666666",
        BACKGROUND: "#FFFFFF",
        BORDER: "1px solid #E4E4E4",
    },
    CLOSED: {
        VALUE: "CLOSED",
        TEXT: "Закрыто",
        COLOR: "#666666",
        BACKGROUND: "#F1F3F7",
    },
    PUBLISHED: {
        VALUE: "PUBLISHED",
        TEXT: "Опубликовано",
        COLOR: "#FFFFFF",
        BACKGROUND: "#128A10",
    },
    AWAIT_PUBLISH: {
        VALUE: "AWAIT_PUBLISH",
        TEXT: "Ожидание запуска",
        COLOR: "#128A10",
        BACKGROUND: "#E9F4E5",
    },
};

export const ADVERTISEMENT_SOURCE_TYPE = {
    RECRUITMENT: "RECRUITMENT",
};