import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {MarkerList} from "../../ActualComponents/MarkerList";
import NmLabelText from "../../ActualComponents/NmLabelText";
import NmModal from "../../ActualComponents/NmModal";
import Text from "../../ActualComponents/Text";
import ApplyButtons from "../../ApplyButtons";
import ExtLink from "../../ExtLink";
import NmTitle from "../../NmTitle";

import {getClassNames} from "../../../utils/classNames";
import {getDadataAddressObj} from "../../../utils/dadata";
import {formatLocalDate, getDatePeriodLabelText} from "../../../utils/dateFormat";
import {phoneFormat} from "../../../utils/stringFormat";

import {COLOR} from "../../../constants/color";
import {GENDER_DICT} from "../../../constants/contractorInfo";
import {LINK_ORDER_CARD} from "../../../constants/links";
import {NOTIFICATION_SOURCE} from "../../../constants/notifications";

import {downloadDocument} from "../../../ducks/documents";
import {
    clearEventsContractorAggregatedParamsByMailing,
    getEventsContractorAggregatedParamsByMailing,
} from "../../../ducks/events/contractor/actionCreators";
import {
    eventsContractorAggregatedParamsByMailing,
    eventsContractorAggregatedParamsByMailingProgress,
} from "../../../ducks/events/contractor/selectors";
import {specialitiesAllV2OptionsSelector} from "../../../ducks/speciality";

import {FILTER_TYPE, MAILINGS_STATUS} from "../../../constants/ mailings/segmentationType";
import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../constants/link-params";

interface IProps {
    onCloseModal: () => void,
    data: {
        id?: string,
        name: string,
        title: string,
        text: string,
        createDateTime: string,
        sendDateTime: string,
        author: string,
        gender: keyof typeof GENDER_DICT | null,
        lastLoginDateFrom: string,
        lastLoginDateTo: string,
        contractorOnly: boolean,
        excludeClientGroupContractors: boolean,
        recipientsCount: number,
        status: keyof typeof MAILINGS_STATUS,
        addressesInfo: Record<string, object>,
        phones: Array<string>,
        orderNumFilter: string,
        orderId: string,
        orderClientId: string,
        smartLinkIdFilter: string,
        clientId?: string,
        filesNameLinkList: Array<{
            id: string,
            link: string,
            name: string,
        }>,
        specialityIds: Array<string>,
        filterType: "ALL" | "BY_PARAMETERS" | "BY_PHONES",
        notificationSource: keyof typeof NOTIFICATION_SOURCE,
    },
    clientId?: string,
}

export const MailingsListCardModal: FC<IProps> = (params) => {
    const {
        onCloseModal,
        data,
        clientId,
    } = params;

    const dispatch = useDispatch();

    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);

    const loading = useSelector(eventsContractorAggregatedParamsByMailingProgress);
    const aggregatedData = useSelector(eventsContractorAggregatedParamsByMailing);

    const [isHiddenModal, setIsHiddenModal] = useState(false);

    const {
        id,
        name,
        title,
        text,
        createDateTime,
        sendDateTime,
        author,
        gender,
        lastLoginDateFrom,
        lastLoginDateTo,
        contractorOnly,
        recipientsCount,
        excludeClientGroupContractors,
        status,
        addressesInfo,
        phones,
        orderNumFilter,
        orderId,
        orderClientId,
        smartLinkIdFilter,
        filesNameLinkList,
        specialityIds,
        filterType,
    } = data;

    useEffect(() => {
        dispatch(getEventsContractorAggregatedParamsByMailing({
            mailingId: id,
        }));

        return () => {
            dispatch(clearEventsContractorAggregatedParamsByMailing());
        };
    }, []);

    const renderCities = () => {
        if (addressesInfo) {
            const options = Object.values(addressesInfo).map((item) => {
                return {text: getDadataAddressObj(item).text || ""};
            });

            return (
                <MarkerList
                    list={options}
                    maxVisibleElements={5}
                    modalHeader="Города ведения деятельности"
                />
            );
        }


        return "-";

    };

    const renderPhones = () => {
        if (!phones?.length) {
            return "-";
        }

        const options = phones.map((item) => {
            return {text: phoneFormat(item)};
        });

        return (
            <MarkerList
                list={options}
                maxVisibleElements={5}
                modalHeader="Номера телефонов"
                setIsHiddenModal={setIsHiddenModal}
            />
        );
    };

    const renderSpecialities = () => {
        if (!specialityIds?.length || !specialityOptions?.length) {
            return "-";
        }

        const specialitiesNames = specialityIds.map((specialityId) => {
            return specialityOptions.find((specialityOption: { key: string }) => specialityOption.key === specialityId);
        });

        return (
            <MarkerList
                list={specialitiesNames.filter(item => item)}
                maxVisibleElements={5}
                modalHeader="Виды деятельности"
            />
        );
    };

    const renderOrderInfo = () => {
        if (!orderId || !orderClientId || !orderNumFilter) {
            return "-";
        }

        const text = `№ ${orderNumFilter}`;

        if (
            clientId
            && data.clientId !== data.orderClientId
        ) {
            return text;
        }

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", orderClientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <ExtLink
                to={orderLink}
                historyEnabled
            >
                {text}
            </ExtLink>
        );
    };

    const renderSmartLinkInfo = () => {
        if (!smartLinkIdFilter) {
            return "-";
        }

        return `${process.env.REACT_APP_NAIMIX_OPEN_SITE_LINK}/sl/${smartLinkIdFilter}`;
    };

    const renderFiles = () => {
        return filesNameLinkList.map((file, index) => {
            const {name, link} = file;

            return (
                <Text
                    key={index}
                    inline
                    noWrap
                    className="me-1"
                    color={COLOR.PASSIVE_100}
                    onClick={() => {
                        dispatch(downloadDocument({
                            isDownload: true,
                            downloadLink: link,
                            fileName: name,
                        }));
                    }}
                >
                    {`${name}  `}
                </Text>
            );
        });
    };

    const renderSegmentationByPhone = () => {
        return (
            <NmLabelText
                label="Номера телефонов"
                className={getClassNames([
                    "mb-1 mb-md-2",
                    phones?.length && "flex-column",
                ])}
                text={renderPhones()}
            />
        );
    };

    const renderSegmentationByParameters = () => {
        return (
            <>
                <NmLabelText
                    label="Города ведения деятельности"
                    className={getClassNames([
                        "mb-1 mb-md-2",
                        addressesInfo && "flex-column",
                    ])}
                    text={renderCities()}
                />
                {
                    data.notificationSource === NOTIFICATION_SOURCE.ADMIN_UI &&
                    <NmLabelText
                        label="Последний визит"
                        text={getDatePeriodLabelText(lastLoginDateFrom, lastLoginDateTo)}
                        className="mb-1 md-md-2"
                    />
                }
                <NmLabelText
                    label="Виды деятельности"
                    className={getClassNames([
                        "mb-1 mb-md-2",
                        specialityIds?.length && "flex-column",
                    ])}
                    text={renderSpecialities()}
                />
                <NmLabelText
                    label="Пол"
                    className="mb-1 mb-md-2"
                    text={gender ? GENDER_DICT[gender] : "-"}
                />
                {
                    data.notificationSource === NOTIFICATION_SOURCE.ADMIN_UI &&
                    <NmLabelText
                        label="Отправить только самозанятым"
                        className="mb-1 mb-md-2"
                        text={contractorOnly ? "Да" : "Нет"}
                    />
                }
            </>
        );

    };

    return (
        <NmModal
            loading={loading}
            isHiddenModal={isHiddenModal}
            onClose={onCloseModal}
            contentAlign="left"
            header={
                <NmTitle size="lg">
                    {name}
                </NmTitle>
            }
            classNameContent="flex-column"
            size="md"
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Понятно"
                    submit={onCloseModal}
                />
            }
        >
            <Text
                color={COLOR.SECONDARY_90}
                level="2"
                className="mb-1 mb-md-2"
                type="sub"
                medium
            >
                Основная информация
            </Text>
            <NmLabelText
                label="Заголок рассылки"
                text={title}
                className="mb-1 md-md-2"
            />
            <NmLabelText
                label="Cодержание рассылки"
                text={text}
                wrapped
                className="mb-1 md-md-2 flex-column"
            />
            <NmLabelText
                columnOnMobile
                label="Дата сохранения рассылки"
                text={formatLocalDate(createDateTime, "dd.MM.yyyy HH:mm")}
                className="mb-1 md-md-2"
            />
            <NmLabelText
                columnOnMobile
                label="Дата отправки рассылки"
                text={formatLocalDate(sendDateTime, "dd.MM.yyyy HH:mm")}
                className="mb-1 md-md-2"
            />
            <NmLabelText
                label="Статус рассылки"
                text={MAILINGS_STATUS[status]?.TEXT}
                className="mb-1 md-md-2"
            />
            <NmLabelText
                label="Автор рассылки"
                text={author}
                className="mb-1 md-md-2"
            />
            {
                filesNameLinkList?.length &&
                <NmLabelText
                    label="Прикрепленные файлы"
                    inline
                    columnOnMobile
                    noWrap={false}
                    text={renderFiles()}
                />
            }
            <Text
                color={COLOR.SECONDARY_90}
                level="4"
                type="title"
                className="mb-1 mb-md-2 mt-3 mt-md-4"
                medium
            >
                Сегментация
            </Text>
            {filterType === FILTER_TYPE.BY_PARAMS && renderSegmentationByParameters()}
            {filterType === FILTER_TYPE.BY_PHONE_NUMBERS && renderSegmentationByPhone()}
            {
                data.notificationSource === NOTIFICATION_SOURCE.ADMIN_UI &&
                <NmLabelText
                    label="Исключить исполнителей из закрытого контура"
                    className="mb-1 mb-md-2"
                    inline
                    noWrapLabelOnMobile
                    text={excludeClientGroupContractors ? "Да" : "Нет"}
                />
            }
            <NmLabelText
                label="Количество получателей"
                className="mb-1 mb-md-2"
                text={recipientsCount || "-"}
            />
            <NmLabelText
                label="Ссылка на заказ"
                text={renderOrderInfo()}
                className="mb-1 mb-md-2"
            />
            {
                data.notificationSource === NOTIFICATION_SOURCE.ADMIN_UI &&
                <NmLabelText
                    label="Ссылка на подборку заказов/заданий"
                    text={renderSmartLinkInfo()}
                />
            }
            {
                data.notificationSource === NOTIFICATION_SOURCE.ADMIN_UI &&
                <>
                    <Text
                        color={COLOR.SECONDARY_90}
                        level="4"
                        type="title"
                        className="mb-1 mb-md-2 mt-3 mt-md-4"
                        medium
                    >
                        Показатели конверсии
                    </Text>
                    <NmLabelText
                        label="Охват"
                        text={recipientsCount || 0}
                        className="mb-1 mb-md-2"
                    />
                    <NmLabelText
                        label="Просмотрено"
                        text={aggregatedData?.notificationViews || 0}
                        className="mb-1 mb-md-2"
                    />
                    <NmLabelText
                        label="Прочитано"
                        text={aggregatedData?.notificationClicks || 0}
                        className="mb-1 mb-md-2"
                    />
                    <NmLabelText
                        label="Переход на заказ"
                        text={aggregatedData?.orderClicks || 0}
                        className="mb-1 mb-md-2"
                    />
                    <NmLabelText
                        label="Отклик"
                        text={aggregatedData?.orderResponses || 0}
                    />
                </>
            }
        </NmModal>
    );
};