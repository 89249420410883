import {useState} from "react";
import {useDispatch} from "react-redux";

import {
    isNullOrWhitespace,
} from "../../../../../../../utils/stringHelper";
import {toastError} from "../../../../../../../utils/toastHelper";

import {
    mergeContractorProfessionalSkills,
} from "../../../../../../../ducks/contractorProfile";

export const defaultProfessionalSkillsForm = {
    names: [],
    name: "",
};

export default function useCreateOrUpdateProfessionalSkills({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultProfessionalSkillsForm});
    const [error, setError] = useState(undefined);
    const dispatch = useDispatch();

    const {
        names,
        name,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const deleteSkill = (name) => {
        setError(undefined);
        setForm(prevState => ({
            ...prevState,
            names: prevState.names.filter(i => i !== name),
        }));
    };

    const handleAddSkill = () => {
        if (isNullOrWhitespace(name)) {
            setError("Заполните навык");
            return;
        }

        if (names.find(el => el === name.trim())) {
            setError("Навык уже существует");
            return;
        }

        if (names.length === 10) {
            setError("Максимально допустимое количество навыков для добавления - 10");
            return;
        }

        if (name.length > 100) {
            setError("Введен некорректный навык. Максимальное число символов 100");
            return;
        }

        setError(undefined);

        setForm(prevState => ({
            ...prevState,
            names: [prevState.name, ...prevState.names],
        }));
    };

    const submit = () => {
        dispatch(mergeContractorProfessionalSkills({
            contractorId,
            names,
            getResult: ({errorMessage}) => {
                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }
                onClose();
                getProfile();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        handleAddSkill,
        deleteSkill,
        error,
    };
}