import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";

import {handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";

import {
    edoDocumentStatusesOptionsSelector,
    edoDocumentTypesOptionsSelector,
    getEdoDocumentStates,
    getEdoDocumentTypes,
} from "../../../ducks/edocuments";

import "./style.sass";

const DocumentManagementDocumentListFilter = (props) => {
    const {
        submitFilter,
        filter,
        onChangeFilter,
        clearFilter,
    } = props;

    const {
        senderFio,
        documentName,
        receiverFio,
        documentDateTimeBefore,
        documentDateTimeAfter,
        uploadDateTimeBefore,
        uploadDateTimeAfter,
        documentTypes,
        documentStatus,
    } = filter;


    const dispatch = useDispatch();

    const fetchEdoDocumentStates = (valueFilter) => {
        dispatch(getEdoDocumentTypes({
            pageNum: 1,
            pageSize: 200,
            valueFilter: handleFormString(valueFilter),
        },
        ));
    };

    useEffect(() => {
        dispatch(getEdoDocumentStates());
        fetchEdoDocumentStates();
    }, []);

    const edoDocumentStatusesOptions = useSelector(edoDocumentStatusesOptionsSelector);
    const edoDocumentTypesOptions = useSelector(edoDocumentTypesOptionsSelector);

    const onChange = (event, {name, value}) => {
        onChangeFilter({
            ...filter,
            [name]: value,
        });
    };

    const submit = () => {
        const {
            senderFio,
            documentName,
            receiverFio,
            documentDateTimeBefore,
            documentDateTimeAfter,
            uploadDateTimeBefore,
            uploadDateTimeAfter,
            documentTypes,
            documentStatus,
        } = filter;

        const data = {
            documentName: handleFormString(documentName),
            receiverInfo: isNullOrWhitespace(receiverFio) ? undefined : {
                subStringName: handleFormString(receiverFio),
            },
            senderInfo: isNullOrWhitespace(senderFio) ? undefined : {
                subStringName: handleFormString(senderFio),
            },
            documentDateTimeBefore,
            documentDateTimeAfter,
            uploadDateTimeBefore,
            uploadDateTimeAfter,
            documentTypes,
            documentStatus: handleFormString(documentStatus),
        };


        submitFilter(data);
    };

    const handleOnSearchDocumentType = (valueFilter) => {
        if (valueFilter && valueFilter.length < 3) {
            return;
        }

        setTimeout(() => {
            fetchEdoDocumentStates(valueFilter);
        }, 800);
    };

    return (
        <NmForm
            addMargin
            className="document-management-document-list-filter"
        >
            <FilterButtonsV2
                className="document-management-document-list-filter__buttons"
                onSearch={submit}
                onClear={clearFilter}
            />
            <NmInputV2
                size="lg"
                label="Наименование документа"
                name="documentName"
                value={documentName}
                onChange={onChange}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="documentDateTimeBefore"
                endFieldName="documentDateTimeAfter"
                value={{documentDateTimeBefore, documentDateTimeAfter}}
                label="Дата документа"
                onChange={onChange}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="uploadDateTimeBefore"
                endFieldName="uploadDateTimeAfter"
                value={{uploadDateTimeBefore, uploadDateTimeAfter}}
                label="Период загрузки документа"
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                label="ФИО отправителя"
                name="senderFio"
                value={senderFio}
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                label="ФИО получателя"
                name="receiverFio"
                value={receiverFio}
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                label="Тип документа"
                name="documentTypes"
                search
                onChange={onChange}
                onCancel={clearFilter}
                onSubmit={submit}
                value={documentTypes}
                options={edoDocumentTypesOptions}
                multiple
                isMountLoad={false}
                onSearchChange={handleOnSearchDocumentType}
            />
            <NmDropdownV2
                size="lg"
                label="Статус документа"
                name="documentStatus"
                onChange={onChange}
                value={documentStatus}
                options={edoDocumentStatusesOptions}
            />
        </NmForm>
    );
};

export default DocumentManagementDocumentListFilter;