import {useState} from "react";

export function useLogHistory() {
    const [isOpenLogDepositHistoryModal, setIsOpenLogDepositHistoryModal] = useState(false);
    const [logData, setLogData] = useState({});

    const toggleLogDepositHistoryModal = (_logData = {}) => {
        setIsOpenLogDepositHistoryModal(prevState => !prevState);
        setLogData(_logData);
    };

    return {
        isOpenLogDepositHistoryModal,
        toggleLogDepositHistoryModal,
        logData,
    };
}