// для получения списка шаблонов, дефолтных шаблонов
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../templates/constants";

export const ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES = [
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
        fieldName: "frameContractTemplateId",
        nameDocumentField: "frameContractTemplateName",
    },
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
        fieldName: "orderApplicationTemplateId",
    },
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
        fieldName: "actOfAcceptanceOfWorkTemplateId",
    },
];