import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import MemberEdit from "../../../components/MemberEdit";
import {history} from "../../../store/configureStore";
import {Dimmer, Loader} from "semantic-ui-react";

import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {
    clearSpace,
    getFullName,
    removePhoneMask,
    removeSnilsMask,
} from "../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";
import validate from "../../../utils/validate";

import {CLIENT_MEMBER_FIELD_NAME} from "../../../constants/clientList";
import {SETTINGS_MEMBERS_LS_URL_PARAMS} from "../../../constants/contractorInfo";
import {
    LINK_CLIENT_MEMBERS_LIST,
    LINK_SETTINGS_MEMBERS_LIST,
} from "../../../constants/links";
import {MEMBER_POSITIONS_DICT} from "../../../constants/memberPositions";
import {
    ADMIN,
    CLIENT_ADMIN,
    GR,
    isUserFromNm,
    NM_CONSULTANT,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    roleOptionsByCurrentUserRole,
    roleSettingsList,
    roleSettingsListByCurrentMemberRole,
} from "../../../constants/roles";
import {
    clientMemberRule,
    passwordRule,
    requiredMessage,
    settingsMemberRule,
} from "../../../constants/validationRules";

import {
    clientMemberCardSelector,
    clientMemberIsSuccessUpdateSelector,
    clientMemberProgressUpdateSelector,
    generatePasswordClientMember,
    getClientMemberById,
    updateClientMember,
    updateFieldClientMemberStore} from "../../../ducks/clientMember";
import {
    getClientPropertiesById,
    getClientPropertiesCardSelector,
} from "../../../ducks/clientProperties";

import {memberType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class ClientMemberCard extends Component {
    static propTypes = {
        clientMember: memberType,
        onCloseModel: PropTypes.func,
        open: PropTypes.bool,
        fetchList: PropTypes.func,
        className: PropTypes.string,
        progressUpdate: PropTypes.bool,
        isSuccessUpdate: PropTypes.bool,
        getClientMemberById: PropTypes.func,
        updateClientMember: PropTypes.func,
        updateFieldClientMemberStore: PropTypes.func,
    };

    static defaultProps = {
        onCloseModel: () => {
        },
        open: false,
        fetchList: () => {
        },
        className: "",
        progressUpdate: false,
        isSuccessUpdate: false,
        clientMember: {},
        getClientMemberById: () => {
        },
        updateClientMember: () => {
        },
        updateFieldClientMemberStore: () => {
        },
        isSettingsAdmin: false,
    };

    defaultMember = {
        [CLIENT_MEMBER_FIELD_NAME.ID]: "",
        [CLIENT_MEMBER_FIELD_NAME.EMAIL]: "",
        [CLIENT_MEMBER_FIELD_NAME.FIRST_NAME]: "",
        [CLIENT_MEMBER_FIELD_NAME.LAST_NAME]: "",
        [CLIENT_MEMBER_FIELD_NAME.PASSWORD]: "",
        [CLIENT_MEMBER_FIELD_NAME.PATRONYMIC]: "",
        [CLIENT_MEMBER_FIELD_NAME.POSITION]: "",
        [CLIENT_MEMBER_FIELD_NAME.PHONE]: "",
        [CLIENT_MEMBER_FIELD_NAME.LOGIN]: "",
        [CLIENT_MEMBER_FIELD_NAME.ROLE]: "",
        [CLIENT_MEMBER_FIELD_NAME.INN]: "",
    };

    constructor(props) {
        super(props);

        this.state = {
            errorRepeatPasswordMessage: "",
            isEditPassword: false,
            formError: {},
            passwordForm: {
                password: "",
                repeatPassword: "",
            },
            newMember: {
                ...this.defaultMember,
            },
        };
    }

    get role() {
        return ls(USER_ROLE);
    }

    get clientUserId() {
        return ls(CURRENT_CLIENT_USER_ID);
    }

    get memberPositions() {
        return dictionaryToOptions(MEMBER_POSITIONS_DICT);
    }

    get isAdmin() {
        return this.role === ADMIN;
    }

    get isAccess() {
        return [ADMIN, CLIENT_ADMIN, NM_MANAGER, NM_CONSULTANT, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role);
    }

    static getDerivedStateFromProps(props, state) {
        const {
            clientMember,
        } = props;

        const {
            newMember,
        } = state;


        if (clientMember.clientUserId !== newMember.clientUserId) {
            return {
                state,
                newMember: {
                    ...clientMember,
                },
            };
        }
        return state;
    }

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, pageName} = state;

            this.setState({
                historyData: {prevPath, pageData, pageName},
            });
        }
    };

    componentDidMount() {
        this.saveHistoryData();
        this.fetchMember();
        const {match, getClientPropertiesById} = this.props;
        const {params} = match;
        const {clientId} = params;

        if (clientId) {
            getClientPropertiesById({clientId: clientId});
        }
    }

    componentDidUpdate(prevProps) {
        const {
            progressUpdate,
            isSuccessUpdate,
        } = this.props;

        if (!progressUpdate && isSuccessUpdate && prevProps.progressUpdate !== progressUpdate) {
            this.handleOnClickBack();
        }
    }

    componentWillUnmount() {
        const {updateFieldClientMemberStore} = this.props;
        updateFieldClientMemberStore({card: {}});
    }

    fetchMember = () => {
        const {match, getClientMemberById} = this.props;
        const {params} = match;
        const {clientContractorId} = params;

        getClientMemberById(clientContractorId);
    };

    submitForm = () => {
        const {
            newMember,
            isEditPassword,
        } = this.state;

        const {
            email,
            login,
            password,
            lastName,
            firstName,
            position,
            patronymic,
            role,
            clientId,
            clientUserId,
            phone,
            snils,
            sendPassword,
        } = newMember;

        const {
            updateClientMember,
            generatePasswordClientMember,
        } = this.props;

        const _phone = phone ? removePhoneMask(phone.trim()) : null;

        const isValidForm = this.validationForm({...newMember, phone: _phone});

        if (!this.validationRepeatPassword() || !isValidForm) {
            return;
        }

        const _password = (isEditPassword && isNullOrWhitespace(password) || sendPassword) ? undefined : password;

        const requestData = {
            ...newMember,
            clientId: clientId,
            email: email.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            password: _password,
            patronymic: patronymic && patronymic.trim(),
            position: position ? position : null,
            phone: _phone,
            login: login.trim(),
            snils: isNullOrWhitespace(snils) ? null : removeSnilsMask(snils),
            role,
            [CLIENT_MEMBER_FIELD_NAME.INN]: clearSpace(handleFormString(newMember[CLIENT_MEMBER_FIELD_NAME.INN])),
            sendPassword: undefined,
        };

        if (isEditPassword && sendPassword) {
            generatePasswordClientMember({
                clientId,
                clientUserId,
            });
        }

        updateClientMember(requestData);
    };

    handleChangeFieldsMember = (e, {value, name, checked}) => {
        if (["password", "repeatPassword"].includes(name)) {
            this.setState(prevState => ({
                ...prevState,
                passwordForm: {
                    ...prevState.passwordForm,
                    [name]: value,
                },
            }));
        }

        this.setState(prevState =>
            ({
                ...prevState,
                newMember: {
                    ...prevState.newMember,
                    [name]: checked === undefined ? value : checked,
                },
            }),
        );
    };

    handleOnClickBack = () => {
        const {match, isSettingsAdmin} = this.props;
        const {historyData} = this.state;
        const {params} = match;
        const {clientId} = params;

        if (!isSettingsAdmin) {
            history.push(LINK_CLIENT_MEMBERS_LIST.replace(":clientId", clientId), historyData);

            return;
        }

        const pageNum = ls(SETTINGS_MEMBERS_LS_URL_PARAMS.LIST_PAGE) || 1;
        const pageSize = ls(SETTINGS_MEMBERS_LS_URL_PARAMS.PAGE_SIZE) || 10;
        history.push(LINK_SETTINGS_MEMBERS_LIST.replace(":pageNumber", pageNum).replace(":pageSize", pageSize), historyData);
    };

    validationRepeatPassword = () => {
        const {passwordForm: {password, repeatPassword}} = this.state;
        const {t} = this.props;

        if (password === null) {
            return true;
        }

        if (password !== repeatPassword) {
            this.setState({
                errorRepeatPasswordMessage: t("client-member-new.error-passwords-not-match"),
            });

            return false;
        }

        this.setState({errorRepeatPasswordMessage: ""});

        return true;
    };

    validationForm(member) {
        const {
            isSettingsAdmin,
            clientProperty: {
                innNecessary,
            },
        } = this.props;

        const {isEditPassword} = this.state;
        let errorMessage = {};


        let rule = isSettingsAdmin ? settingsMemberRule : clientMemberRule;

        if (isEditPassword && !member.sendPassword) {
            rule = {
                ...rule,
                ...passwordRule,
            };
        }

        if (!isSettingsAdmin && innNecessary) {
            rule = {
                ...rule,
                [CLIENT_MEMBER_FIELD_NAME.INN]: {
                    ...rule[CLIENT_MEMBER_FIELD_NAME.INN],
                    required: requiredMessage,
                },
            };
        }

        errorMessage = validate(member, rule, "");

        this.setState({formError: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    onClickEditPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            isEditPassword: !prevState.isEditPassword,
        }));
    };

    mapOptions = () => {
        const {isSettingsAdmin} = this.props;

        if (!isSettingsAdmin) {
            const optionsByRole = roleOptionsByCurrentUserRole(this.role);

            if (isUserFromNm(this.role)) {
                optionsByRole.push({
                    key: GR,
                    text: GR,
                    value: GR,
                });
            }
            return optionsByRole;
        }


        if (this.isAdmin) {
            return roleSettingsList;
        }

        return roleSettingsListByCurrentMemberRole[this.role];
    };

    render() {
        const {
            className,
            t,
            clientMember: {
                role: currentRole,
                firstName,
                lastName,
                patronymic,
            },
            progressUpdate,
            clientProperty: {
                innNecessary,
            },
        } = this.props;

        const {
            formError,
            newMember,
            passwordForm,
            isEditPassword,
            errorRepeatPasswordMessage,
        } = this.state;

        const {
            clientId,
            clientUserId,
        } = newMember;

        if (!clientId) {
            return <Dimmer active>
                <Loader content={t("loader.content")} />
            </Dimmer>;
        }

        return (
            <MemberEdit
                innNecessary={innNecessary}
                isClient
                className={`client-member-new ${className}`}
                onClose={this.handleOnClickBack}
                title={getFullName(lastName, firstName, patronymic)}
                formError={formError}
                translate={t}
                user={newMember}
                submit={this.submitForm}
                onChange={this.handleChangeFieldsMember}
                roleOptions={this.mapOptions(currentRole)}
                positionsOptions={this.memberPositions}
                toggleEditPassword={this.onClickEditPassword}
                errorRepeatPasswordMessage={errorRepeatPasswordMessage}
                passwordForm={passwordForm}
                onBlurRepeatPassword={this.validationRepeatPassword}
                disabledRole={!this.isAccess || this.clientUserId === clientUserId}
                isEditPassword={isEditPassword}
                isAccessToTimeSheet={[CLIENT_ADMIN, ADMIN, NM_MANAGER, NM_CONSULTANT].includes(this.role)}
                loading={progressUpdate}
            />
        );
    }
}

export default connect(
    state => ({
        location: state.router.location,
        progressUpdate: clientMemberProgressUpdateSelector(state),
        isSuccessUpdate: clientMemberIsSuccessUpdateSelector(state),
        clientMember: clientMemberCardSelector(state),
        clientProperty: getClientPropertiesCardSelector(state),
    }),
    {
        getClientMemberById,
        updateClientMember,
        updateFieldClientMemberStore,
        getClientPropertiesById,
        generatePasswordClientMember,
    },
)(withTranslation()(ClientMemberCard));
