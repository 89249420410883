import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import ToastMessage from "../../../../../../components/ToastMessage";

import {toastError} from "../../../../../../utils/toastHelper";

import {kedoTemplatesErrorSelector} from "../../../../../../ducks/kedo/templates/selectors";

export const useKedoTemplateError = () => {
    const error = useSelector(kedoTemplatesErrorSelector);

    useEffect(() => {
        if (!error) {
            return;
        }

        if (error.errorCode === "PARAMS_IS_EMPTY") {
            toastError(
                <ToastMessage
                    type="error"
                    message="Файл шаблона не содержит необходимых переменных, замените файл и повторите попытку загрузки. См. "
                    link="/files/Мануал для использования КЭДО шаблонизатора.docx"
                    textLink="Памятка по подготовке шаблона документа"
                />,
            );
        }

        toastError(error.errorMessage);
    }, [
        error,
    ]);

    return null;
};