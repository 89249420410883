import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../components/ActualComponents/Filter";

import {getClientBrandsByClientId, updateCommonPaymentDicts} from "../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsClientBrandsOptionsSelector} from "../ducks/bff/common/dicts/selectors";

export const useClientBrandsFilter = (params) => {
    const {
        clientId,
    } = params;

    const dispatch = useDispatch();

    const options = useSelector(bffCommonDictsClientBrandsOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateCommonPaymentDicts({
                clientBrands: [],
            }));
        };
    }, []);

    const fetchBrands = (valueFilter) => {
        dispatch(getClientBrandsByClientId({
            pageNum: 1,
            pageSize: 10,
            clientId,
            brandNameFilter: valueFilter,
        }));
    };

    return {
        clientBrandsFilter: {
            component: FILTER.DROPDOWN,
            name: "brandsIds",
            label: "Бренд объекта",
            placeholder: "Не выбран",
            onSearchChange: fetchBrands,
            options,
            multiple: true,
            isClearable: true,
            search: true,
            disabledDependsName: "isObjectsWithoutBrand",
        },
        withoutBrandFilter: {
            component: FILTER.CHECKBOX,
            label: "Без бренда",
            name: "isObjectsWithoutBrand",
            disabledDependsName: "brandsIds",
        },
    };
};