import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmPage from "../../../../components/NmPage";
import ContractorEventLogOrderHistoryItem from "./components/order-history-item";
import ContractorEventLogOrderHistoryCard from "./contractor-event-log-order-history-card";

import {usePagination} from "../../../../hooks/usePagination";
import {useContractorEventLogOrderHistoryCard} from "./hooks/useContractorEventLogOrderHistoryCard";
import {useContractorOrderHistoryFetchList} from "./hooks/useContractorOrderHistoryFetchList";

import {contractorStatusDictSelector} from "../../../../ducks/contractor";
import {
    contractorEventLogPdListSelector,
    contractorEventLogPdTotalCountSelector,
    contractorEventLogPdTotalPagesSelector,
} from "../../../../ducks/contractorEventLog";

import "./style.sass";

const ContractorEventLogOrderHistory = (props) => {
    const {
        match: {
            params: {
                contractorId: contractorIdFilter,
            },
        },
    } = props;

    const totalCount = useSelector(contractorEventLogPdTotalCountSelector);
    const totalPages = useSelector(contractorEventLogPdTotalPagesSelector);
    const contractorStatusDict = useSelector(contractorStatusDictSelector);

    const list = useSelector(contractorEventLogPdListSelector);
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {loading} = useContractorOrderHistoryFetchList({pageSize, pageNum, contractorIdFilter});
    const {
        selectedItem,
        toggleCard,
        isOpenCard,
        loadingScans,
    } = useContractorEventLogOrderHistoryCard();

    function mapData() {
        return list.map((item, index) => {
            return (
                <ContractorEventLogOrderHistoryItem
                    toggleCard={toggleCard}
                    key={index}
                    item={item}
                    contractorStatusDict={contractorStatusDict}
                    className="contractor-event-log-order-history__item"
                    defaultOpen={index === 0}
                />
            );
        });
    }

    return (
        <>
            {isOpenCard && <ContractorEventLogOrderHistoryCard
                loadingScans={loadingScans}
                item={selectedItem}
                toggleCard={toggleCard}
            />}
            <NmPage
                noPadding
                className="contractor-event-log-order-history__nm-page"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                isLoaded={loading}
            >
                {totalCount < 1 ?
                    <NmEmptyPageV2
                        title="Информация отсутствует"
                    /> : mapData()
                }
            </NmPage>
        </>
    );
};

export default ContractorEventLogOrderHistory;