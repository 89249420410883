import {isEmpty} from "lodash";

import {getNumberFromFormattedAmount} from "../../../../../../utils/stringFormat";
import {enableClientSettingsUser} from "../../../utils/enableUser";

export const getClientSettingsAdditionalModulesRequestData = (values, card) => {
    const {
        retailClientUsersIds,
        migrantLicensePaymentCommission,
        ...form
    } = values;

    return {
        ...form,
        edmTariff: getEdmTariff(values, card),
        retailClientUsers: !isEmpty(retailClientUsersIds) ?
            retailClientUsersIds.map(enableClientSettingsUser) :
            null,
        migrantLicensePaymentCommission: migrantLicensePaymentCommission ?
            getNumberFromFormattedAmount(migrantLicensePaymentCommission) :
            null,
    };
};

// необходимо для корректной работы логики обнаружения несохраненных данных
const getEdmTariff = (values, card) => {
    if (values.edmTariff) {
        return values.edmTariff;
    }

    // если edmTariff пустой === "", но был ранее сохранен, то отправляем null
    if (!values.edmTariff && card.edmTariff) {
        return null;
    }

    // если edmTariff пустой === "" и карточка пустая, то берем значение из карточки,
    // там может храниться как null так и пустая строка "",
    // если в карточке edmTariff: null, тогда в requestData.edmTariff тоже будет null, при сравнении изменений не будет
    // если в карточке edmTariff: "", тогда в requestData.edmTariff тоже будет "", при сравнении изменений не будет
    return card.edmTariff;
};