import React from "react";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";

import TemplatesContracts from "../contracts";

import {LINK_FORBIDDEN_PAGE} from "../../../../constants/links";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";

const AdditionalAgreementList = (props) => {
    const {
        clientId,
    } = props.match.params;

    const clientProperties = useSelector(getClientPropertiesCardSelector);

    if (clientId && !clientProperties.createAdditionalAgreementOnContractorPDChange) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    return (
        <TemplatesContracts
            headerText="Доп. соглашения"
            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ADDITIONAL_AGREEMENT}
            {...props}
        />
    );
};

export default AdditionalAgreementList;