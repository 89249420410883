import {useEffect, useLayoutEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getInfoGroup,
} from "../../../../../ducks/clientGroups";

export function useGetInfoCardGroup(groupId, importProgress) {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        getCard();
    }, []);

    useEffect(() => {
        if(!importProgress){
            getCard();
        }
    }, [importProgress]);

    function getCard() {
        dispatch(getInfoGroup(groupId));
    }
}