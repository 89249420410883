import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {getRequestDataFromMapFilterData} from "../../../../../utils/dto";

import {getMapContractors} from "../../../../../../../ducks/bff/map/actionCreators";

export const useContractorsMapFetchList = (params) => {
    const {
        filter = {},
        pageSize,
        pageNum,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(filter)) {
            return;
        }

        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        const {
            distanceFilter,
            locationAddressFiasIdsFilter,
            contractorsFilters,
        } = getRequestDataFromMapFilterData(filter);

        dispatch(getMapContractors({
            pageNum,
            pageSize,
            distanceFilter,
            locationAddressFiasIdsFilter,
            ...contractorsFilters,
        }));
    };

    return {
        fetchList,
    };
};