import {HEADER_MOBILE_ACTIONS} from "../utils/getOptions";

function useMobileOrderTemplatesAction({onToggleArchived, onOpenFilter, onOpenTemplateEdit}) {
    const onClickMobileAction = (option) => {
        switch (option.value) {
        case HEADER_MOBILE_ACTIONS.CREATE_TEMPLATE: {
            onOpenTemplateEdit();

            return;
        }
        case HEADER_MOBILE_ACTIONS.ARCHIVE: {
            onToggleArchived();

            return;
        }
        case HEADER_MOBILE_ACTIONS.FILTER: {
            onOpenFilter();

            return;
        }
        default:
            return;
        }
    };

    return {
        onClickMobileAction,
    };
}

export default useMobileOrderTemplatesAction;