import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/registrypaymentstatuses";

const REGISTRY_PAYMENT_STATUSES_FETCH_REQUEST = "REGISTRY_PAYMENT_STATUSES_FETCH_REQUEST";
const REGISTRY_PAYMENT_STATUSES_FETCH_SUCCESS = "REGISTRY_PAYMENT_STATUSES_FETCH_SUCCESS";
const REGISTRY_PAYMENT_STATUSES_FETCH_ERROR = "REGISTRY_PAYMENT_STATUSES_FETCH_ERROR";

const REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_REQUEST = "REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_REQUEST";
const REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_SUCCESS = "REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_SUCCESS";
const REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_ERROR = "REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_ERROR";

const REGISTRY_PAYMENT_STATUSES_UPDATE_STORE = "REGISTRY_PAYMENT_STATUSES_UPDATE_STORE";

const initialState = {
    paymentStatuses: {},
    statusesAllForFilter: {},
    error: "",
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_SUCCESS: {
        return {
            ...state,
            statusesAllForFilter: payload,
        };
    }
    case REGISTRY_PAYMENT_STATUSES_FETCH_SUCCESS: {
        return {
            ...state,
            paymentStatuses: payload,
        };
    }
    case REGISTRY_PAYMENT_STATUSES_FETCH_ERROR: {
        return {
            ...state,
            error: payload,
        };
    }
    case REGISTRY_PAYMENT_STATUSES_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};

export function getRegistryPaymentStatuses(payload) {
    return {
        type: REGISTRY_PAYMENT_STATUSES_FETCH_REQUEST,
        payload,
    };
}

export function getRegistryPaymentStatusesAllForFilters(payload) {
    return {
        type: REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_REQUEST,
        payload,
    };
}

export function updateRegistryPaymentStatusesStore(payload) {
    return {
        type: REGISTRY_PAYMENT_STATUSES_UPDATE_STORE,
        payload,
    };
}

const registryPaymentStatusesSelector = state => state.registryPaymentStatuses;

// Для отображения в списке
export const registryPaymentStatusesDictSelector = createSelector(registryPaymentStatusesSelector, ({paymentStatuses}) => paymentStatuses);
// Для фильтра в карточке реестра
export const registryPaymentStatusesAllForFiltersOptionsSelector = createSelector(registryPaymentStatusesSelector, ({statusesAllForFilter}) => {
    return [
        {
            key: "all",
            value: "all",
            text: "Все",
        },
        ...dictionaryToOptions(statusesAllForFilter),
    ];
});

export const fetchRegistryPaymentStatusesSaga = function* ({payload}) {
    const {
        isIndividual,
    } = payload;

    try {
        const result = yield request.get(`${controller}/${isIndividual ? "allForIndividual" : "all"}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_STATUSES_FETCH_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REGISTRY_PAYMENT_STATUSES_FETCH_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_STATUSES_FETCH_ERROR, payload: error});
    }
};

export const getRegistryPaymentStatusesAllForFiltersSaga = function* ({payload}) {
    const {
        isIndividual,
    } = payload;

    try {
        const result = yield request.get(`${controller}/${isIndividual ? "allForIndividualFilters" : "allForFilters"}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PAYMENT_STATUSES_FETCH_REQUEST, fetchRegistryPaymentStatusesSaga),
        takeEvery(REGISTRY_PAYMENT_STATUSES_FETCH_ALL_FOR_FILTER_REQUEST, getRegistryPaymentStatusesAllForFiltersSaga),
    ]);
}

