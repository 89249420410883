import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {useDataPreviouslyPassportInfoCardEditForm} from "./hooks/useData";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";

import {contractorAdditionalDocumentsProgressUpdateDataSelector} from "../../../../../../../ducks/contractorAdditionalDocuments";
import {fileContractorScanProgressSelector} from "../../../../../../../ducks/fileStore";

const PreviouslyPassportInfoCardEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
    } = props;

    const progress = useSelector(contractorAdditionalDocumentsProgressUpdateDataSelector);
    const progressGetScan = useSelector(fileContractorScanProgressSelector);

    const {
        files,
        errors,
        onDropScan,
        handleSubmit,
    } = useDataPreviouslyPassportInfoCardEditForm({
        contractorId,
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Сведения о ранее выданных паспортах (доп. документ)
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress || progressGetScan}
        >
            <NmForm addMargin>
                <DropzoneV2
                    isTopImagePreview
                    label="Фотография разворота паспорта со сведениями о ранее выданных паспортах"
                    isVisibleLabel
                    files={files[CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={(files) => onDropScan(files, CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN)}
                    uploadBtnText={isEmpty(files[CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]) ? "Выбрать файл" : "Заменить"}
                    error={errors[CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]}
                />
            </NmForm>
        </NmModal>
    );
};

export default PreviouslyPassportInfoCardEditForm;