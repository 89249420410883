import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {DROPDOWN_ALL_NO_YES_OPTIONS} from "../../../../../constants/dropdown";
import {REG_EXP} from "../../../../../constants/regExp";

const useRecruitmentDirectoriesObserverFilters = () => {
    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ФИО",
                        placeholder: "Введите ФИО",
                        name: "observerName",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Должность",
                        placeholder: "Введите должность",
                        name: "specialityName",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        placeholder: "Номер телефона",
                        label: "Номер телефона",
                        name: "phone",
                        mask: "+7 (999) 999 9999",
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "E-mail",
                        placeholder: "Введите e-mail",
                        name: "email",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Название вакансии",
                        placeholder: "Введите название",
                        name: "vacancyName",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер вакансии",
                        placeholder: "Введите номер",
                        pattern: REG_EXP.ONLY_NUMBERS,
                        name: "vacancyNumber",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Может перемещать кандидатов в воронке",
                        name: "canSetCandidateStatus",
                        options: DROPDOWN_ALL_NO_YES_OPTIONS,
                    },
                ],
            },
        ];
    }, []);

    return {
        filters,
    };
};

export default useRecruitmentDirectoriesObserverFilters;