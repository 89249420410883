import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import TableDiv from "../../../components/TableDiv";
import {ReactComponent as AddIcon} from "../../../images/add.svg";

import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    getFullName,
    phoneFormat,
} from "../../../utils/stringFormat";
import {toastWarning} from "../../../utils/toastHelper";

import {ORDER_STATUS} from "../../../constants/clientList";
import {headersProjectTeamList as headers} from "../../../constants/headersTable";
import {
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    ROLE_DICT,
} from "../../../constants/roles";

import {getClientCardSelector} from "../../../ducks/client";
import {
    clientCurrentMemberIdSelector,
    clientForemanListSelector,
    clientProjectManagerListSelector,
    foremanOptionsSelector,
    getClientMemberList,
    managerOptionsSelector,
    updateFieldClientMemberStore,
} from "../../../ducks/clientMember";
import {getOrderRichList} from "../../../ducks/order";
import {
    addObjectUser,
    addProjectUser,
    deleteObjectUser,
    deleteProjectUser,
    getObjectUserList,
    getObjectUserListSelector,
    getObjectUsersTotalCountSelector,
    getObjectUsersTotalPagesSelector,
    getProjectById,
    getProjectSelector,
    getProjectUserList,
    projectProgressUsersSelector,
    projectsProgressObjectUserList,
    projectUsersSelector,
    projectUsersTotalCountSelector,
    projectUsersTotalPagesSelector,
} from "../../../ducks/projects";

import "./style.sass";

class ProjectsTeamList extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];

        const {
            match: {
                params: {
                    clientId,
                    projectId,
                    objectId,
                },
            },
        } = props;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            sortType: "asc",
            headers,
            isOpenAddBlock: false,
            isOpenConfirmWindow: false,
        };

        this.projectId = projectId;
        this.clientId = clientId;
        this.objectId = objectId;
        this.isObjectUsers = !!objectId;
        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {getOrderRichList, currentMemberId} = this.props;

        this.fetchList();

        getOrderRichList({
            pageNum: 1,
            pageSize: 25,
            archivedFilter: false,
            objectIdsFilter: [this.objectId],
            projectIdsFilter: [this.projectId],
            clientId: this.clientId,
            statusFilter: [ORDER_STATUS.DRAFT, ORDER_STATUS.CLOSED, ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH],
            clientUserIdsFilter: this.role === FOREMAN && currentMemberId ? [currentMemberId] : undefined,
        });
    }

    componentWillUnmount() {
        const {updateFieldClientMemberStore} = this.props;

        updateFieldClientMemberStore({list: []});
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    onSearchClientMemberList = (fioFilter) => {
        const {getClientMemberList} = this.props;

        getClientMemberList({
            clientId: this.clientId,
            archiveFilter: false,
            fioFilter,
            projectId: this.projectId,
            objectId: this.objectId,
            roleListFilter: this.isObjectUsers ? [OBJECT_MANAGER, FOREMAN] : [OBJECT_MANAGER, PROJECT_MANAGER],
            pageNum: 1,
            pageSize: 25,
            fieldName: this.isObjectUsers ? "foremanList" : "projectManagers",
        });
    };

    getProject() {
        const {getProjectById} = this.props;
        const {
            clientId,
            projectId,
        } = this;

        getProjectById({
            clientId,
            projectId,
        });
    }

    add = () => {
        const {clientUserId} = this.state;
        if (!clientUserId) {
            toastWarning("Сотрудник не выбран");
            return;
        }

        const {
            managersList,
            foremanList,
            addProjectUser,
            addObjectUser,
        } = this.props;

        const manager = this.isObjectUsers ? foremanList.find(item => item.clientUserId === clientUserId) : managersList.find(item => item.clientUserId === clientUserId);

        if (this.isObjectUsers) {
            addObjectUser({
                projectId: this.projectId,
                objectId: this.objectId,
                ...manager,
            });
        } else {
            addProjectUser({
                projectId: this.projectId,
                ...manager,
            });
        }
        this.getProject();
        this.closeAddBlock();
    };

    delete = () => {
        const {
            deleteProjectUser,
            users,
            objectUsers,
            deleteObjectUser,
        } = this.props;
        const {clientUserIdDelete} = this.state;
        const _users = this.isObjectUsers ? objectUsers : users;
        const manager = _users.find(item => item.clientUserId === clientUserIdDelete);

        if (this.isObjectUsers) {
            deleteObjectUser(manager);

        } else {
            deleteProjectUser(manager);
        }

        this.getProject();
        this.handleCancelConfirm();
    };

    addBlock() {
        const {
            managersOptions,
            foremanOptions,
            objectUsers,
            users: addedUsers,
        } = this.props;
        const {clientUserId} = this.state;

        const {
            add,
            handleOnChange,
            isObjectUsers,
            role,
            onSearchClientMemberList,
        } = this;

        function getUsers() {
            const users = isObjectUsers ? foremanOptions : managersOptions;

            const objectUsersIds = objectUsers.map(item => {
                return item.clientUserId;
            });

            const addedUsersIds = addedUsers?.map(item => {
                return item.clientUserId;
            }) || [];

            if (role === OBJECT_MANAGER) {
                return users.filter(item => item.role !== PROJECT_MANAGER);
            }

            return users.filter(item => !objectUsersIds.includes(item.value) && !addedUsersIds.includes(item.value));
        }

        function renderIconCheck() {
            return (
                <div
                    className="material-icons notranslate project-team-list__icon project-team-list__icon_check"
                    onClick={add}
                >
                    check
                </div>
            );
        }

        function renderAddBlock() {
            return (
                <div className="project-team-list__add-row">
                    <NmDropdownV2
                        size="lg"
                        search
                        selectOnBlur={false}
                        noResultsMessage="Нет результата поиска"
                        options={getUsers()}
                        placeholder="Выберите сотрудника"
                        onChange={handleOnChange}
                        value={clientUserId}
                        onSearchChange={onSearchClientMemberList}
                    />
                    <div className="ml-15">
                        {renderIconCheck()}
                    </div>
                </div>
            );
        }

        return {
            fullName: renderAddBlock(),
            action: this.renderIconClose(this.closeAddBlock),
        };
    }

    renderIconCloseForList(item) {
        const {
            project: {
                currentUserInProjectTeam,
            },
        } = this.props;

        if (this.isClientArchived) {
            return null;
        }

        if ([FOREMAN, NM_COORDINATOR, NM_CONSULTANT, NM_CHIEF_ACCOUNTANT].includes(this.role)) {
            return null;
        }

        const {
            currentMemberId,
        } = this.props;

        if ([OBJECT_MANAGER, PROJECT_MANAGER].includes(this.role) && currentMemberId === item.clientUserId) {
            return null;
        }

        if (this.role === OBJECT_MANAGER && (item.role === PROJECT_MANAGER || !currentUserInProjectTeam)) {
            return null;
        }

        return this.renderIconClose(() => {
            this.showConfirmWindow({
                clientUserIdDelete: item.clientUserId,
            });
        });
    }

    renderIconClose(func) {
        return (
            <div
                className="material-icons notranslate project-team-list__icon project-team-list__icon_close"
                onClick={func}
            >
                close
            </div>
        );
    }

    openAddBlock = () => {
        this.setState({
            isOpenAddBlock: true,
        });
    };

    closeAddBlock = () => {
        this.setState({
            isOpenAddBlock: false,
            clientUserId: undefined,
        });
    };

    showConfirmWindow = ({clientUserIdDelete}) => {
        this.setState({
            isOpenConfirmWindow: true,
            clientUserIdDelete,
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            isOpenConfirmWindow: false,
            clientUserIdDelete: undefined,
        });
    };

    mapTableData() {
        const {
            users: projectUsers,
            objectUsers,
        } = this.props;
        const {
            headers,
            isOpenAddBlock,
        } = this.state;

        const users = this.isObjectUsers ? objectUsers : projectUsers;

        const rows = users.map(item => {
            return {
                ...item,
                phone: phoneFormat(item.phone),
                fullName: getFullName(item.lastName, item.firstName, item.patronymic),
                role: ROLE_DICT[item.role]?.TEXT,
                action: this.renderIconCloseForList(item),
            };
        });

        if (isOpenAddBlock) {
            rows.unshift(this.addBlock());
        }
        return {
            headers,
            rows,
        };
    }

    fetchList = () => {
        const {
            getProjectUserList,
            getObjectUserList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            sortType,
        } = this.state;

        if (this.isObjectUsers) {
            getObjectUserList({
                pageNum,
                pageSize,
                clientId: this.clientId,
                projectId: this.projectId,
                objectId: this.objectId,
                sortType,
            });
            return;
        }

        getProjectUserList({
            pageNum,
            pageSize,
            clientId: this.clientId,
            projectId: this.projectId,
            sortType,
        });
    };

    handleChangeSearch = (sortField, sortType) => {
        if (sortType === "asc" || sortType === "desc") {
            this.setState(prevState => {
                const headers = [...prevState.headers];

                const index = headers.findIndex(h => h.key === sortField);
                const header = {
                    ...headers[index],
                    typeSort: sortType,
                };

                headers.splice(index, 1, header);

                return {
                    ...prevState,
                    sortType,
                    headers,
                };
            },
            () => setTimeout(this.fetchList, 500),
            );
        }
    };

    changePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    changePageNum = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    renderConfirmWindow() {
        const {
            isOpenConfirmWindow,
        } = this.state;

        const {t} = this.props;

        return isOpenConfirmWindow &&
            <NmConfirmV2
                onCancel={this.handleCancelConfirm}
                onConfirm={this.delete}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                content={this.isObjectUsers ? t("projects-team-list.remove-contractor-from-object-team") : t("projects-team-list.remove-contractor-from-project-team")}
                size="sm"
            />;
    }

    handleOnChange = (e, {value}) => {
        this.setState({clientUserId: value});
    };

    getAccessAdd = () => {
        const {
            project: {
                currentUserInProjectTeam,
            },
        } = this.props;

        if ([OBJECT_MANAGER].includes(this.role)) {
            return currentUserInProjectTeam;
        }

        return !this.isClientArchived && ![FOREMAN, NM_COORDINATOR, NM_CONSULTANT, NM_CHIEF_ACCOUNTANT].includes(this.role);
    };

    render() {
        const {
            totalPages,
            progressList,
            progressObjectUser,
            totalPagesObjectUsers,
            t,
            totalCount,
            totalCountObjectUsers,
        } = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <NmPage
                paginationPaddingBottom
                heightUnset
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={this.isObjectUsers ? totalPagesObjectUsers : totalPages}
                totalCount={this.isObjectUsers ? totalCountObjectUsers : totalCount}
                onChangePageSize={this.changePageSize}
                onPaginationChange={this.changePageNum}
                typeFilter="horizontal"
                header={
                    <NmPageHeader text={t("projects-team-list.title")} />
                }
                isLoaded={progressList || progressObjectUser}
                controls={
                    this.getAccessAdd() &&
                    <NmButton
                        size="xl"
                        icon={<AddIcon />}
                        onClick={this.openAddBlock}
                    >
                        {t("projects-team-list.add-button")}
                    </NmButton>
                }
            >
                {this.renderConfirmWindow()}
                <TableDiv
                    className="project-team-list__table"
                    tableData={this.mapTableData()}
                    handleChangeSearch={this.handleChangeSearch}
                />
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        project: getProjectSelector(state),
        totalPages: projectUsersTotalPagesSelector(state),
        totalCount: projectUsersTotalCountSelector(state),
        totalPagesObjectUsers: getObjectUsersTotalPagesSelector(state),
        totalCountObjectUsers: getObjectUsersTotalCountSelector(state),
        users: projectUsersSelector(state),
        progressList: projectProgressUsersSelector(state),
        managersOptions: managerOptionsSelector(state),
        foremanOptions: foremanOptionsSelector(state),
        managersList: clientProjectManagerListSelector(state),
        foremanList: clientForemanListSelector(state),
        objectUsers: getObjectUserListSelector(state),
        progressObjectUser: projectsProgressObjectUserList(state),
        currentMemberId: clientCurrentMemberIdSelector(state),
        client: getClientCardSelector(state),
    }),
    {
        getProjectById,
        getProjectUserList,
        getClientMemberList,
        addProjectUser,
        deleteProjectUser,
        getObjectUserList,
        addObjectUser,
        deleteObjectUser,
        updateFieldClientMemberStore,
        getOrderRichList,
    },
)((withTranslation()(ProjectsTeamList)));


