import React from "react";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmButton from "../../../../../components/NmButton";
import NmTitle from "../../../../../components/NmTitle";

import "./style.sass";

function ErrorArchiveConfirmModal(props) {
    const {
        toggleVisibleModal,
        title = "Архивирование шаблона заказа",
        failedTemplateObjects,
    } = props;

    function renderObjects() {
        if (failedTemplateObjects === null) {
            return null;
        }

        return <ul>
            {failedTemplateObjects.map(({name, address}) => (
                <li className="error-archive-confirm-modal-objects__item">
                    {name}
                    {" "}
(
                    {address}
)
                </li>
            ))}
        </ul>;
    }

    return (
        <NmModal
            header={
                <NmTitle
                    size="lg"
                >
                    {title}
                </NmTitle>
            }
            onClose={() => toggleVisibleModal()}
            footer={<div className="error-archive-confirm-modal__footer">
                <NmButton onClick={() => toggleVisibleModal()}>
                    OK
                </NmButton>
            </div>}
        >
            <div className="error-archive-confirm-modal">
                <div className="error-archive-confirm-modal__description">
                    Для нижепредставленных объектов доступно создание заказов только по шаблонам. Поскольку шаблон
                    является
                    единственным для данных объектов, его архивирование невозможно.
                    Пожалуйста, создайте хотя бы один новый шаблон для всех представленных объектов или снимите с них
                    флаги,
                    ограничивающие создание свободных заказов, в параметрах компании.
                </div>
                <div className="error-archive-confirm-modal-objects">
                    {renderObjects()}
                </div>
            </div>
        </NmModal>
    );
}

export default ErrorArchiveConfirmModal;