import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getOnboardingLeadRegistrationStatusDict,
    updateCommonPaymentDicts,
} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    updateFieldFilesStore,
} from "../../../../ducks/fileStore";
import {
    getOnboardingLeadsPage,
    onboardingLeadsSelector,
    onboardingScenarioProgressActionSelector,
    updateOnboardingStore,
} from "../../../../ducks/onboarding";

export const useContractorOnboardingLeadsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
    } = params;
    const dispatch = useDispatch();

    const {
        list,
        progress,
        totalPages,
        totalCount,
    } = useSelector(onboardingLeadsSelector);
    const progressAction = useSelector(onboardingScenarioProgressActionSelector);

    useEffect(() => {
        dispatch(getOnboardingLeadRegistrationStatusDict());

        return () => {
            dispatch(updateCommonPaymentDicts({
                onBoardingLeadRegistrationStatusDict: {},
            }));
            dispatch(updateFieldFilesStore({
                avatarBase64ImagesList: [],
            }));
            dispatch(updateOnboardingStore({
                contractorsLeads: {
                    list: [],
                    totalCount: 0,
                    pageData: {},
                },
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
    ]);

    const fetchList = () => {
        dispatch(getOnboardingLeadsPage({
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
        list,
        progress,
        totalPages,
        totalCount,
        progressAction,
    };
};