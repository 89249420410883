import React, {useContext} from "react";

import HelpTooltip from "../../../../../../../components/ActualComponents/HelpTooltip";
import NmTextareaV2 from "../../../../../../../components/ActualComponents/NmTextareaV2";
import {OrderEditContext} from "../../../../context-provider";

const SUGGESTION_EXAMPLE_DESCRIPTION = [
    "«Выполнение работ по укладке тротуарной плитки, включающие: планирование и разметку площадки, устройство основания с уплотнением, кладочные и дренажные работы на объекте Заказчика»",
    " «Оказание услуг по предпродажной подготовке товара: приемка, сборка, переоценка, маркировка товара, замена ценников на товарах»",
];

const OrderEditFormGeneralDescription = (props) => {
    const {
        disabled,
        required,
        label,
        placeholder,
        suggestionContent,
        isVisibleTooltip,
        value,
        error,
        onMouseEnter,
        onFocus,
    } = props;
    const {
        setForm,
    } = useContext(OrderEditContext);

    const onChangeTextArea = (event, {name, value}) => {
        if (value.length === 5001) {
            return;
        }

        if (value.length > 5000) {
            value = value.slice(0, 5000);
        }

        setForm({
            [name]: value,
        });
    };

    return (
        <NmTextareaV2
            maxRows={3}
            minRows={2}
            label={label}
            disabled={disabled}
            error={error}
            name="description"
            required={required}
            onChange={onChangeTextArea}
            value={value}
            placeholder={placeholder}
            onFocus={onFocus}
            maxLength={5000}
            isVisibleTooltip={isVisibleTooltip}
            tooltip={
                <HelpTooltip
                    info
                    hover
                    compound
                    body={[{titleBlock: "Например:", textBlock: SUGGESTION_EXAMPLE_DESCRIPTION}]}
                    header={{title: suggestionContent}}
                    position="bottom-left"
                />
            }
            onMouseEnter={onMouseEnter}
        />
    );
};

export default React.memo(OrderEditFormGeneralDescription);