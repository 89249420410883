import React from "react";

import Tabs from "../../../../components/ActualComponents/Tabs";

import {
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
} from "../../../../constants/links";

const ClientFinancePaymentListTabs = (props) => {
    const {
        params: {
            clientId,
        },
    } = props.match;

    const getTabs = () => {
        const orderPaymentsLink = LINK_CLIENT_PAYMENTS_ORDERS_LIST
            .replace(":clientId", clientId);

        const taskPaymentsLink = LINK_CLIENT_PAYMENTS_TASKS_LIST
            .replace(":clientId", clientId)
            .replace(":paymentNumberFilter?", "");

        return [
            {
                name: "По заказам",
                link: orderPaymentsLink,
                active: orderPaymentsLink,
            },
            {
                name: "По заданиям",
                link: taskPaymentsLink,
                active: taskPaymentsLink,
            },
        ];
    };

    return (
        <Tabs
            {...props}
            className="mb-4 mb-xxl-5"
            panes={getTabs()}
        />
    );
};

export default ClientFinancePaymentListTabs;