import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import ImportFromFilePatternV2 from "../../../../components/ActualComponents/ImportFromFilePatternV2";
import {MediaButtons} from "../../../../components/ActualComponents/MediaControls";
import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import Task from "../../../../components/NmTask";
import RegistryPaymentError from "../../../../components/RegistryPaymentError";
import RejectionReason from "../../../../components/RejectionReason";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import {ReactComponent as AddBoxIcon} from "../../../../images/add_box.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../images/file_download.svg";
import {ReactComponent as InfoIcon} from "../../../../images/info_24.svg";
import CrowdTaskActRegistryDuplicatesEntryModal from "../components/duplicates-entries-modal";
import CrowdTaskActRegistryErrorsEntryModal from "../components/errors-entry-modal";
import CrowdTaskActRegistryNonActualEntryModal from "../components/non-actual-entry-modal";
import CrowdActRegistryEntryEditModal from "./components/edit-entry-modal";
import CrowdTaskSearchModal from "./components/search-task-modal";

import {usePagination} from "../../../../hooks/usePagination";
import {useSelectedList} from "../../../../hooks/useSelectedList";
import {useCrowdTaskActRegistryCardAction} from "./hooks/useAction";
import {useCrowdTaskActRegistryCardFetch} from "./hooks/useFetch";
import {useCrowdTaskActRegistryCardFilter} from "./hooks/useFilter";

import {getUserRole} from "../../../../utils/access";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {formatAmountWithNullChecking, phoneFormat} from "../../../../utils/stringFormat";
import {getSimpleText} from "../../../../utils/stringHelper";
import {getStatusTooltipText} from "../../../finance/finance-crowd-payments/utils/getCrowdPaymentStatusTooltipText";
import {getTaskActRegistryPaymentStatus} from "./utils/getTaskActRegistryPaymentStatus";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../constants/color";
import {CROWD_TASK_STATUS, CROWD_TASK_WORKING_CONTRACTOR_STATUS} from "../../../../constants/crowd/task";
import {
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CONTRACTOR_PROFILE,
} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_OPERATOR} from "../../../../constants/roles";

import {history} from "../../../../store/configureStore";

import {
    bffCrowdActRegistryCardSelector,
    bffCrowdActRegistryProgressActionSelector,
    bffCrowdActRegistryProgressCardSelector,
} from "../../../../ducks/bff/crowd/actRegistry/selectors";
import {
    bffCrowdActRegistryEntryListSelector,
    bffCrowdActRegistryEntryProgressActionSelector,
    bffCrowdActRegistryEntryProgressSelector,
    bffCrowdActRegistryEntryTotalCountSelector,
    bffCrowdActRegistryEntryTotalPagesSelector,
} from "../../../../ducks/bff/crowd/actRegistryEntry/selectors";
import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {jobTasksCrowdActRegistrySelector} from "../../../../ducks/job";

import {
    CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME,
    CROWD_ACT_REGISTRY_ENTRY_STATUS,
    CROWD_ACT_REGISTRY_ENTRY_VALIDATION_STATUS,
    CROWD_ACT_REGISTRY_STATUS,
} from "../../../../constants/crowd/act-registry";
import {SUB_PAGE_CROWD_TASK_CARD} from "../../../../constants/link-params";

const CrowdTaskActRegistryCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                registryId,
            },
        },
    } = props;

    const card = useSelector(bffCrowdActRegistryCardSelector);
    const progressCard = useSelector(bffCrowdActRegistryProgressCardSelector);
    const progressAction = useSelector(bffCrowdActRegistryProgressActionSelector);
    const list = useSelector(bffCrowdActRegistryEntryListSelector);
    const totalCount = useSelector(bffCrowdActRegistryEntryTotalCountSelector);
    const totalPages = useSelector(bffCrowdActRegistryEntryTotalPagesSelector);
    const progressList = useSelector(bffCrowdActRegistryEntryProgressSelector);
    const progressListAction = useSelector(bffCrowdActRegistryEntryProgressActionSelector);
    const {canViewContractorContacts} = useSelector(getClientPropertiesCardSelector);
    const tasks = useSelector(jobTasksCrowdActRegistrySelector);
    const currentRegistryTask = tasks.filter(item => {
        const details = JSON.parse(item.details);

        return registryId === details.registryId;
    });
    const tasksProgress = Boolean(currentRegistryTask.length);

    const {
        name = "",
        registryNumber = "",
        comment,
        needContractorApprove,
        sum = 0,
        necessaryDeposit = 0,
        countBlock,
        status,
        hasFailedEntries,
    } = card;

    const {
        [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.DONE]: doneCount = 0,
        [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.FORMING]: formingCount = 0,
        [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.REJECTED]: rejectedCount = 0,
        [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.PROCESSING]: processingCount = 0,
        [CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME.ERROR]: errorCount = 0,
    } = countBlock?.countRecords || {};

    const role = getUserRole();
    const isAccessAction = ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    } = useCrowdTaskActRegistryCardFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchCard,
        fetchList,
    } = useCrowdTaskActRegistryCardFetch({
        clientId,
        registryId,
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {
        getMediaControls,
        getListItemMediaControls,
        importEntries,
        massDeleteEntries,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
        modalData,
        onCloseModal,
        onOpenModal,
        onSubmitDuplicateEntryModal,
        onSubmitNonActualEntryModal,
    } = useCrowdTaskActRegistryCardAction({
        clientId,
        registryId,
        fetchCard,
        fetchList,
        selectedList,
        clearSelectedRows,
        isAccessAction,
    });

    const handleOnClickBack = () => {
        history.push(LINK_CLIENT_CROWD_TASK_ACT_REGISTRY.replace(":clientId", clientId));
    };

    const renderPaymentStatus = ({paymentStatus, paymentRejectReason, paymentValidationResult}) => {
        if (!paymentStatus) {
            return "-";
        }

        const {
            color,
            text,
        } = getTaskActRegistryPaymentStatus(paymentStatus);

        const error = getStatusTooltipText({
            status: paymentStatus,
            rejectReason: paymentRejectReason,
            validationResult: paymentValidationResult,
        });

        return (
            <RegistryPaymentError
                color={color}
                statusText={text}
                error={error}
            />
        );
    };

    const rows = useMemo(() => {
        return list.map(item => {
            const {
                clientId,
                taskId,
                contractorId,
                rowNumber,
                workStartDate,
                workEndDate,
                contractorName,
                contractorPhone,
                taskName,
                taskNumber,
                workActDescription,
                workFNSDescription,
                comment,
                sum,
                contractorStatus,
                validationResult,
                paymentNumber,
                status,
            } = item;

            const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                .replace(":clientId", clientId)
                .replace(":taskId", taskId)
                .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);
            const contractorLink = LINK_CONTRACTOR_PROFILE
                .replace(":contractorId", contractorId);
            const paymentsLink = LINK_CLIENT_PAYMENTS_TASKS_LIST
                .replace(":clientId", clientId)
                .replace(":paymentNumberFilter?", paymentNumber);

            const {isSelected = false} = selectedList.find(_item => (_item.rowNumber === rowNumber)) || {};
            const isErrors = validationResult
                && validationResult.summaryStatus === CROWD_ACT_REGISTRY_ENTRY_VALIDATION_STATUS.FAILED;
            const isAccessMassAction = status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE
                || (
                    status === CROWD_ACT_REGISTRY_STATUS.PROCESSING.VALUE
                    && item.status === CROWD_ACT_REGISTRY_ENTRY_STATUS.ERROR.VALUE
                );

            return {
                ...item,
                key: rowNumber,
                showCheckBox: isAccessAction,
                disabledCheckBox: !isAccessMassAction,
                isSelected,
                isErrors,
                contentRow: (
                    <NmListCard
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            status &&
                            <NmBadge
                                text={CROWD_ACT_REGISTRY_ENTRY_STATUS[status]?.TEXT || status}
                                mod={CROWD_ACT_REGISTRY_ENTRY_STATUS[status]?.BADGE_MOD || "gray"}
                            />
                        }
                        secondaryHeader={
                            workStartDate &&
                            `Период работ ${formatLocalDate(workStartDate, "dd.MM.yyyy")} - ${formatLocalDate(workEndDate, "dd.MM.yyyy")}`
                        }
                        primaryHeader={
                            taskNumber ?
                                <ExtLink
                                    pageData={{pageNum, pageSize}}
                                    filterData={filterData}
                                    historyEnabled
                                    to={taskLink}
                                >
                                    №
                                    {taskNumber}
                                    {" "}
- 
                                    {" "}
                                    {taskName}
                                </ExtLink> :
                                "Не определено"
                        }
                        classNameMainContent="col-16 col-xxl-7"
                        labels={[
                            {
                                label: "Номер строки",
                                text: rowNumber || "-",
                            },
                            {
                                label: "ФИО исполнителя",
                                text: contractorId ? <ExtLink
                                    pageData={{pageNum, pageSize}}
                                    filterData={filterData}
                                    historyEnabled
                                    to={contractorLink}
                                >
                                    {contractorName}
                                </ExtLink> : "-",
                            },
                            canViewContractorContacts && {
                                label: "Телефон исполнителя",
                                text: contractorPhone ? phoneFormat(contractorPhone) : "-",
                            },
                            {
                                label: "Описание выполненных работ для акта",
                                columnOnMobile: true,
                                fluidText: true,
                                text: <NmShowMoreText
                                    lines={1}
                                    more="Подробнее"
                                    anchor="blue"
                                >
                                    {workActDescription || "-"}
                                </NmShowMoreText>,
                            },
                            {
                                label: "Описание выполненных работ для чека ФНС",
                                columnOnMobile: true,
                                fluidText: true,
                                text: <NmShowMoreText
                                    lines={1}
                                    more="Подробнее"
                                    anchor="blue"
                                >
                                    {workFNSDescription || "-"}
                                </NmShowMoreText>,
                            },
                            {
                                label: "Комментарий",
                                columnOnMobile: true,
                                fluidText: true,
                                text: <NmShowMoreText
                                    lines={1}
                                    more="Подробнее"
                                    anchor="blue"
                                >
                                    {comment || "-"}
                                </NmShowMoreText>,
                            },
                        ]}
                        cards={
                            [
                                {
                                    title: "Статус исполнителя",
                                    value: contractorStatus ?
                                        CROWD_TASK_WORKING_CONTRACTOR_STATUS[contractorStatus]?.TEXT :
                                        "-",
                                    className: "col-16 col-md-8 col-xxl-4",
                                },
                                {
                                    title: "Сумма задания, ₽",
                                    value: formatAmountWithNullChecking(sum),
                                    className: "col-16 col-md-8 col-xxl-4",
                                },
                                {
                                    title: "Статус и номер оплаты",
                                    subTitle: renderPaymentStatus(item),
                                    value: paymentNumber ?
                                        <ExtLink
                                            pageData={{pageNum, pageSize}}
                                            filterData={filterData}
                                            historyEnabled
                                            to={paymentsLink}
                                        >
                                            {paymentNumber}
                                        </ExtLink> :
                                        "-",
                                    className: "col-16 col-md-8 col-xxl-4",
                                    wrapValue: true,
                                },
                            ]
                        }
                        cardsWithContainer={true}
                        cardsContainerClassName="col-16 col-xxl-8 align-items-end"
                        errors={isErrors && validationResult.validationResults}
                        mediaControls={getListItemMediaControls(item)}
                        actionsClassName="col-1 justify-content-end"
                    />
                ),
            };
        });
    }, [
        list,
        selectedList,
    ]);

    const renderSearchTaskModal = () => {
        return (
            modalData?.isOpenSearchModal &&
            <CrowdTaskSearchModal
                clientId={clientId}
                onClose={onCloseModal}
                onOpenEditModal={(data) => onOpenModal({
                    isOpenEditModal: true,
                    data,
                })}
            />
        );
    };

    const renderImportModal = () => {
        return (
            modalData?.isOpenImportModal &&
            <ImportFromFilePatternV2
                patternLink="/files/Шаблон_Реестр_актов_по_заданиям.xlsx"
                onSubmit={importEntries}
                onClose={onCloseModal}
                progress={progressAction}
            />
        );
    };

    const renderCrowdActRegistryEntryEditModal = () => {
        return (
            modalData?.isOpenEditModal &&
            <CrowdActRegistryEntryEditModal
                clientId={clientId}
                registryId={registryId}
                editData={modalData.data}
                onClose={onCloseModal}
                fetchList={fetchList}
            />
        );
    };

    const renderConfirmModal = () => {
        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                content={modalData.content}
                onCancel={onCloseModal}
                onConfirm={modalData.onConfirm}
                confirmButton={modalData.confirmButton}
                cancelButton="Отмена"
                disabled={progressListAction}
                isNeedClosing={false}
            />
        );
    };

    const rejectReasonModal = () => {
        return (
            rejectReasonModalData.isOpen &&
            <RejectionReason
                title="Причина отклонения"
                placeholder="Введите причину отклонения оплаты"
                close={() => setRejectReasonModal({})}
                submit={(rejectReason) => {
                    rejectPayment({
                        paymentId: rejectReasonModalData.paymentId,
                        rejectReason,
                    });
                }}
            />
        );
    };

    const renderErrorsModal = () => {
        return (
            modalData?.isOpenErrorsEntryModal &&
            <CrowdTaskActRegistryErrorsEntryModal
                registryId={registryId}
                onClose={modalData.onClose}
            />
        );
    };

    const renderNonActualEntriesModal = () => {
        return (
            modalData?.isOpenNonActualEntryModal &&
            <CrowdTaskActRegistryNonActualEntryModal
                list={modalData.nonActualEntries}
                onClose={onCloseModal}
                onSubmit={onSubmitNonActualEntryModal}
            />
        );
    };

    const renderDuplicatesEntriesModal = () => {
        return (
            modalData?.isOpenDuplicatesEntryModal &&
            <CrowdTaskActRegistryDuplicatesEntryModal
                list={modalData.duplicateEntries}
                onClose={onCloseModal}
                onSubmit={onSubmitDuplicateEntryModal}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    text={`Реестр актов №${registryNumber} - ${name}`}
                    handleOnClickBack={handleOnClickBack}
                />
            }
            subHeader={
                <>
                    <div className="col-16 col-xxl-9">
                        <NmLabelText
                            type="page"
                            label="Комментарий"
                            text={comment || "-"}
                            noWrap={false}
                            inline={true}
                        />
                        <NmLabelText
                            type="page"
                            label="Требуется подтверждение акта исполнителем"
                            text={getSimpleText(needContractorApprove)}
                        />
                    </div>
                    <NmPageInfoCardsAccordion
                        className="mt-4"
                        bootstrap={true}
                        cards={
                            [
                                {
                                    title: "Сумма реестра, ₽",
                                    value: formatAmountWithNullChecking(sum),
                                    className: "col-16 col-md-4 col-xxl-2",
                                },
                                {
                                    title: "Необходимый депозит, ₽",
                                    value: formatAmountWithNullChecking(necessaryDeposit),
                                    className: "col-16 col-md-4 col-xxl-2",
                                },
                                {
                                    title: <>
                                        Не&nbsp;сформировано/В&nbsp;процессе/Отклонено/В&nbsp;ошибке/Завершено
                                    </>,
                                    subTitle: hasFailedEntries && <div className="flex align-items-center">
                                        <Text color={COLOR.NEGATIVE_100}>
                                            Ошибка
                                        </Text>
                                        <NmAdvancedTooltip
                                            className="ms-2"
                                            children="Найдены ошибки или отклоненные оплаты"
                                            hover={true}
                                            trigger={
                                                <InfoIcon
                                                    color={COLOR.NEGATIVE_100}
                                                    width={20}
                                                    height={20}
                                                />
                                            }
                                        />
                                    </div>,
                                    values: [
                                        {text: formingCount},
                                        {text: processingCount},
                                        {text: rejectedCount},
                                        {text: errorCount},
                                        {text: doneCount},
                                    ],
                                    className: "col-16 col-md-6 col-xxl-4",
                                },
                            ]
                        }
                    />
                </>
            }
            typeFilter="vertical"
            widthByFilter={true}
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            mediaControls={getMediaControls()}
            isLoaded={progressCard || progressList}
            onPaginationChange={onPaginationChange}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
        >
            <Task />
            <div className="flex">
                <NmPageHeader
                    size="xl"
                    text="Задания"
                    totalCount={totalCount}
                />
                {
                    isAccessAction &&
                    status === CROWD_ACT_REGISTRY_STATUS.FORMING.VALUE &&
                    <MediaButtons
                        className="ms-8"
                        config={{
                            renderCount: {
                                desktop: 2,
                                tablet: 0,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.REGISTRY_BUTTON,
                                    props: {
                                        children: "Добавить задание",
                                        icon: <AddBoxIcon
                                            width={24}
                                            height={24}
                                        />,
                                        onClick: () => onOpenModal({
                                            isOpenSearchModal: true,
                                        }),
                                    },
                                },
                                {
                                    component: COMPONENT.REGISTRY_BUTTON,
                                    props: {
                                        icon: <FileDownloadIcon
                                            width={24}
                                            height={24}
                                        />,
                                        children: "Загрузить список заданий",
                                        onClick: () => onOpenModal({isOpenImportModal: true}),
                                        loaderText: "Выполняется загрузка файла...",
                                        progress: tasksProgress,
                                        disabled: tasksProgress,
                                    },
                                },
                            ],
                        }}
                    />
                }
            </div>
            {renderSearchTaskModal()}
            {renderCrowdActRegistryEntryEditModal()}
            {renderImportModal()}
            {renderConfirmModal()}
            {rejectReasonModal()}
            {renderErrorsModal()}
            {renderNonActualEntriesModal()}
            {renderDuplicatesEntriesModal()}
            {
                !isEmpty(list) ?
                    <CheckboxList
                        className="mt-4"
                        header={
                            isAccessAction &&
                            <SelectionCountWithAction
                                adaptiveLogic
                                count={countSelected}
                                buttonColor="grey"
                                buttonContent="Удалить выбранное"
                                onClick={() => onOpenModal({
                                    isOpenConfirm: true,
                                    content: "Вы подтверждаете удаление строк?",
                                    confirmButton: "Подтверждаю",
                                    onConfirm: massDeleteEntries,
                                })}
                            />
                        }
                        rows={rows}
                        onSelectedRows={isAccessAction && handleSelectedRows}
                    /> :
                    <NmEmptyPageV2
                        className="mt-4"
                        fetchProgress={progressList}
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
};

export default CrowdTaskActRegistryCard;