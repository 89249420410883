import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {ProjectsObjectsCheckboxList} from "../ProjectsObjectsCheckboxList";

import {toastSuccess} from "../../utils/toastHelper";

import {
    getObjectsTree,
    getProjectsTree,
    getSearchTree,
    saveTree,
    updateCommonTreeStore,
} from "../../ducks/bff/common/tree/actionCreators";
import {
    bffCommonTreeErrorSelector,
    bffCommonTreeObjectsSelector,
    bffCommonTreePaymentsProgressActionSelector,
    bffCommonTreePaymentsProgressSelector,
    bffCommonTreeProjectsAndObjectsSelector,
    bffCommonTreeProjectsSelector,
} from "../../ducks/bff/common/tree/selectors";

const ClientTree = (props) => {
    const {
        onClose,
        clientId,
        clientUserId,
        settingsType,
        settingsName,
        modalTitle,
        controller,
        buttonName,
    } = props;

    const dispatch = useDispatch();

    const objects = useSelector(bffCommonTreeObjectsSelector);
    const projects = useSelector(bffCommonTreeProjectsSelector);
    const progressAction = useSelector(bffCommonTreePaymentsProgressActionSelector);
    const projectsAndObjects = useSelector(bffCommonTreeProjectsAndObjectsSelector);
    const progressSearch = useSelector(bffCommonTreePaymentsProgressSelector);
    const error = useSelector(bffCommonTreeErrorSelector);

    const updateObjectsCallback = () => {
        dispatch(updateCommonTreeStore({
            objects: {
                lists: {},
                totalCount: 0,
                pageData: {},
            },
            error: null,
        }));
    };

    const clearNodesFromStore = () => {
        dispatch(updateCommonTreeStore({
            projects: {
                clientInfo: {},
                list: [],
                totalCount: 0,
                pageData: {},
            },
            objects: {
                lists: {},
                totalCount: 0,
                pageData: {},
            },
            projectsAndObjects: {},
            error: null,
        }));
    };

    const fetchObjects = ({projectId, pageNum, onSuccess, pageSize}) => {
        const data = {
            pageNum,
            clientId,
            clientUserId,
            projectIdsFilter: [projectId],
            pageSize,
            onSuccess,
        };

        dispatch(getObjectsTree({
            ...data,
            controller,
            settingsType,
        }));
    };

    const fetchProjectsAndObjects = (projectObjectNameFilter) => {
        const data = {
            clientId,
            clientUserId,
            projectObjectNameFilter,
        };

        dispatch(getSearchTree({
            ...data,
            controller,
            settingsType,
        }));
    };

    const onSubmitForm = ({requestData, onSuccess}) => {
        const data = {
            ...requestData,
            onSuccess: ({statusesChangedBeforeSaving}) => {
                // Логика закрытия окна
                onSuccess();

                if (statusesChangedBeforeSaving) {
                    toastSuccess("Изменен статус объекта, доступ в чаты заказов не будет сохранен для закрытых объектов");

                    return;
                }

                toastSuccess("Список доступных чатов по заказам успешно обновлен.");
            },
        };

        dispatch(saveTree({
            ...data,
            controller,
            clientId,
            clientUserId,
            settingsType,
        }));
    };

    const fetchProjects = ({pageNum, isSearchCleared, onSuccess}) => {
        const data = {
            pageNum,
            isSearchCleared,
            pageSize: 10,
            clientId,
            clientUserId,
            onSuccess,
        };

        dispatch(getProjectsTree({
            ...data,
            controller,
            settingsType,
        }));
    };

    return (
        <ProjectsObjectsCheckboxList
            isVisibleClient={false}
            settingsName={settingsName}
            title={modalTitle}
            onClose={onClose}
            updateObjectsCallback={updateObjectsCallback}
            clearNodesFromStore={clearNodesFromStore}
            fetchObjects={fetchObjects}
            fetchProjectsAndObjects={fetchProjectsAndObjects}
            onSubmitForm={onSubmitForm}
            fetchProjects={fetchProjects}
            clientId={clientId}
            objects={objects}
            projects={projects}
            progressAction={progressAction}
            projectsAndObjects={projectsAndObjects}
            progressSearch={progressSearch}
            error={error}
            buttonName={buttonName}
        />
    );
};

export default ClientTree;