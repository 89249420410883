import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_COMMON_TREE_LIST_ERROR,
    BFF_COMMON_TREE_LIST_REQUEST,
    BFF_COMMON_TREE_LIST_SUCCESS,
    BFF_COMMON_TREE_OBJECTS_PAGE_ERROR,
    BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST,
    BFF_COMMON_TREE_OBJECTS_PAGE_SUCCESS,
    BFF_COMMON_TREE_PROJECTS_PAGE_ERROR,
    BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST,
    BFF_COMMON_TREE_PROJECTS_PAGE_SUCCESS,
    BFF_COMMON_TREE_SAVE_ERROR,
    BFF_COMMON_TREE_SAVE_REQUEST,
    BFF_COMMON_TREE_SAVE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

// POST /bff/adm/clients/{clientId}/users/{clientUserId}/settings/tree/projects/page
// Получение древовидных настроек по типу (страница проектов)
const getProjectsTree = function* ({payload}) {
    const {
        isSearchCleared,
        controller,
        onSuccess,
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/tree/projects/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_TREE_PROJECTS_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_COMMON_TREE_PROJECTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_TREE_PROJECTS_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/{clientId}/users/{clientUserId}/settings/tree/objects/page
// Получение древовидных настроек по типу (страница объектов)
const getObjectsTree = function* ({payload}) {
    const {
        onSuccess,
        controller,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/tree/objects/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_TREE_OBJECTS_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_COMMON_TREE_OBJECTS_PAGE_SUCCESS,
            payload: {
                ...result,
                projectId: reqData.projectIdsFilter[0],
            },
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_TREE_OBJECTS_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/{clientId}/users/{clientUserId}/settings/tree/list
// Получение древовидных настроек по типу (список проектов с объектами)
const getSearchTree = function* ({payload}) {
    const {
        onSuccess,
        controller,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/tree/list`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_TREE_LIST_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_COMMON_TREE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_TREE_LIST_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/{clientId}/users/{clientUserId}/settings/tree/save
// Сохранение древовидных настроек по типу
const saveTree = function* ({payload}) {
    const {
        onSuccess,
        controller,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/tree/save`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_TREE_SAVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_COMMON_TREE_SAVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_TREE_SAVE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST, getProjectsTree),
        takeEvery(BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST, getObjectsTree),
        takeEvery(BFF_COMMON_TREE_LIST_REQUEST, getSearchTree),
        takeEvery(BFF_COMMON_TREE_SAVE_REQUEST, saveTree),
    ]);
}
