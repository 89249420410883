import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmButton from "../../components/NmButton";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmForm from "../ActualComponents/NmForm";
import Text from "../ActualComponents/Text";
import {STATUS_PASSPORT_INN} from "../NmStatusPassportInnContractor";

import bem from "../../utils/bem";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {toastSuccess} from "../../utils/toastHelper";

import {BANK_TYPE} from "../../constants/clientSettings";
import {COLOR} from "../../constants/color";
import {CONTRACTOR_REGISTRATION_STATUS} from "../../constants/contractor";
import {
    ADMIN,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../constants/roles";

import {
    getRichContractorById,
} from "../../ducks/contractor";
import {
    contractorBanksSendingProgressForciblySelector,
    getContractorBanksProgressActionSelector,
    sendingContractorToBank,
    sendToContractorBankForcibly,
} from "../../ducks/contractorBanks";

import PropTypes from "prop-types";

import "./style.sass";

const ContractorBankSender = (props) => {
    const {
        contractorId,
        registrationAllowedWithoutInn,
        registrationStatus = "",
        archived,
        contractorDataToBankState,
        className,
        innPassportDataValidStatus,
        blocked,
        bankType,
    } = props;

    const {
        canSendPersonalData,
        canSendDocumentScans,
    } = contractorDataToBankState || {};

    const role = ls(USER_ROLE);
    const isNmManager = role === NM_MANAGER;
    const isNmOperator = role === NM_OPERATOR;

    const [isOpenWarningConfirm, setOpenWarningConfirm] = useState(false);

    const [block, element] = bem("contractor-bank-sender", className);

    const dispatch = useDispatch();

    const progressSendingBank = useSelector(getContractorBanksProgressActionSelector);
    const progressForciblySendingToBank = useSelector(contractorBanksSendingProgressForciblySelector);

    useEffect(() => {
        const disabled = role === ADMIN ? false : [
            NM_COORDINATOR,
            NM_CONSULTANT,
        ].includes(role) || canSendPersonalData === false;

        const initialState = [
            {
                checked: canSendPersonalData === true,
                name: "sendPersonalData",
                label: "Персональные данные",
                disabled,
            },
            {
                checked: canSendDocumentScans === true,
                name: "sendDocumentScans",
                label: "Сканы документов",
                disabled,
            },
        ];

        setVariants(initialState);
    }, [canSendDocumentScans, canSendPersonalData]);

    const [variants, setVariants] = useState([]);

    const isSomeChecked = variants
        .reduce((acc, {checked}, i) => {
            acc[i] = checked;

            return acc;
        }, [])
        .some(item => item === true);

    const handleToggleVariants = (e, name) => {
        const newVariants = variants.map(item => {
            if (item.name === name) {
                return {
                    ...item,
                    checked: !item.checked,
                };
            }

            return item;
        });

        setVariants(newVariants);
    };

    const send = (isForcibly) => {
        const checkBoxesData = variants.reduce((acc, curr) => {
            acc[curr.name] = curr.checked;

            return acc;
        }, {});

        if (isForcibly) {
            dispatch(sendToContractorBankForcibly({
                contractorId,
                bankType,
                ...checkBoxesData,
                onSuccess: () => {
                    toastSuccess("Операция успешно выполнена");

                    dispatch(getRichContractorById(contractorId));
                },
            }));

            return;
        }

        dispatch(sendingContractorToBank({
            contractorId,
            bankType,
            ...checkBoxesData,
            onSuccess: () => {
                toastSuccess("Запущена задача на отправку в банк");
                dispatch(getRichContractorById(contractorId));
            },
        }));
    };

    const handleSend = () => {
        if (
            [
                STATUS_PASSPORT_INN.NOT_EXIST.VALUE,
                STATUS_PASSPORT_INN.NOT_EQUALS.VALUE,
                STATUS_PASSPORT_INN.NOT_CHECKED.VALUE,
                STATUS_PASSPORT_INN.FNS_ERROR.VALUE,
            ].includes(innPassportDataValidStatus)
        ) {
            setOpenWarningConfirm(true);

            return;
        }

        send();
    };

    function isDisabled() {
        if ([NM_COORDINATOR, NM_CONSULTANT].includes(role)) {
            return true;
        }

        if (role === ADMIN) {
            return !isSomeChecked || archived || progressSendingBank || blocked;
        }

        return progressSendingBank ||
            [CONTRACTOR_REGISTRATION_STATUS.BASIC_REGISTRATION].includes(registrationStatus) ||
            !isSomeChecked ||
            archived ||
            blocked;
    }

    const getTextModal = () => {
        if ((isNmManager || isNmOperator) && !registrationAllowedWithoutInn) {
            return "Согласование невозможно, поскольку не установлено соответствие ИНН и паспортных данных";
        }

        if (
            [
                STATUS_PASSPORT_INN.NOT_CHECKED.VALUE,
                STATUS_PASSPORT_INN.FNS_ERROR.VALUE,
            ].includes(innPassportDataValidStatus)) {
            return "Проверка соответствия ИНН и паспортных данных еще не проводилась либо сервис ФНС России недоступен для проверки. Вы уверены, что хотите отправить исполнителя на проверку в банк?";
        }

        return "ИНН не соответствует паспортным данным. Вы уверены, что хотите отправить исполнителя на проверку в банк?";
    };

    return (
        <div className={block()}>
            {
                isOpenWarningConfirm &&
                <NmConfirmV2
                    size="sm"
                    content={getTextModal()}
                    onCancel={() => {
                        setOpenWarningConfirm(false);
                    }}
                    onConfirm={() => {
                        !isNmManager && !isNmOperator && send();
                        setOpenWarningConfirm(false);
                    }}
                    confirmButton={isNmManager || isNmOperator ? "Ок" : "Отправить"}
                    cancelButton={isNmManager || isNmOperator ? "" : "Отменить"}
                    cancelBtnColor="red"
                />
            }
            <Text
                level="3"
                className="text-no-wrap me-xl-4"
                color={COLOR.SECONDARY_70}
            >
                Отправка в банк:
            </Text>
            <NmForm className="flex flex-wrap mt-3 mt-md-4 mt-xl-0">
                <div className={element("checkbox-group")}>
                    {
                        variants.map(({name, label, checked, disabled}) => (
                            <NmCheckboxV2
                                className={element("checkbox")}
                                key={name}
                                label={label}
                                checked={checked === true && disabled === false}
                                onChange={(e) => {
                                    handleToggleVariants(e, name);
                                }}
                                disabled={disabled}
                            />
                        ))
                    }
                </div>
                <div className={element("button-container")}>
                    <NmButton
                        size="sm"
                        color="grey"
                        disabled={isDisabled()}
                        onClick={handleSend}
                    >
                        Отправить в банк
                    </NmButton>
                </div>
                {
                    [BANK_TYPE.PLUTONIUM_W1.VALUE].includes(bankType) &&
                    <div className={element("button-container")}>
                        <NmButton
                            size="sm"
                            color="grey"
                            disabled={
                                ![ADMIN].includes(role) || progressForciblySendingToBank
                            }
                            onClick={() => {
                                send(true);
                            }}
                        >
                            Отправить в банк принудительно
                        </NmButton>
                    </div>
                }
            </NmForm>
        </div>
    );
};

ContractorBankSender.propTypes = {
    className: PropTypes.string,
};

export default ContractorBankSender;