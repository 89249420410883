import * as Yup from "yup";

import {requiredMessage} from "../../../../../constants/validationRules";

export const registryNewValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(requiredMessage)
        .max(100, "Максимальная длина - 100 символов"),
    description: Yup.string()
        .max(255, "Максимальная длина - 255 символов"),
    objectId: Yup.string()
        .required(requiredMessage),
    paymentMethod: Yup.string()
        .nullable()
        .required(requiredMessage),
});

export const registryNewAdminValidationSchema = registryNewValidationSchema.shape({
    creatorClientUserId: Yup.string()
        .required(requiredMessage),
});