import React, {useState} from "react";

import ErrorTooltip from "../ActualComponents/ErrorTooltip";
import OrderScheduleAdd from "../OrderSchedule/Add";
import OrderScheduleTimePeriod from "../OrderSchedule/TimePeriod";

import bem from "../../utils/bem";

const OrderTimePeriodPicker = (props) => {
    const {
        onChange,
        error,
        className,
        name,
        disabled,
        isFilteringEndValues,
        value: {
            from,
            to,
        },
        ...otherProps
    } = props;
    const [block] = bem("order-time-period-picker", className);
    const [open, setOpen] = useState(false);

    const onOpenTimePicker = () => {
        if (disabled) {
            return;
        }

        setOpen(!open);
    };

    const onSubmit = ({startWorkTime, endWorkTime}) => {
        onChange(null, {name, value: {from: startWorkTime, to: endWorkTime}});
    };

    const onRemoveTime = () => {
        onChange(null, {name, value: {}});
    };

    return (
        <div className={block()}>
            <OrderScheduleTimePeriod
                {...otherProps}
                disabled={disabled}
                from={from}
                to={to}
                onClick={onOpenTimePicker}
                isVisibleCloseButton={from || to}
                onRemoveTime={onRemoveTime}
            />
            {
                open &&
                    <OrderScheduleAdd
                        isFilteringEndValues={isFilteringEndValues}
                        close={onOpenTimePicker}
                        submit={onSubmit}
                        initialTime={{
                            from,
                            to,
                        }}
                    />
            }
            {
                error &&
                <ErrorTooltip
                    error={error}
                />
            }
        </div>
    );
};

export default OrderTimePeriodPicker;