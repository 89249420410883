import {useDispatch} from "react-redux";

import {refreshContractorDocuments} from "../../../../ducks/documents";

export const useAction = ({contractorId}) => {
    const dispatch = useDispatch();

    const onRefreshDocuments = () => {
        dispatch(refreshContractorDocuments({
            contractorId,
        }));
    };

    return {
        onRefreshDocuments,
    };
};