import React from "react";

import NmTitle from "../../components/NmTitle";
import Box from "../ActualComponents/Box";
import Text from "../ActualComponents/Text";

import bem from "../../utils/bem";
import {downloadStrapiFile} from "../../utils/strapiService";

import "./style.sass";

const StandartDocumentsCardApp = (props) => {
    const {
        title,
        element,
        list,
    } = props;

    return (
        <>
            <Text.Sub
                className="mb-2 mb-md-4"
                level="2"
                medium
            >
                {title}
            </Text.Sub>
            {
                list.map(({name, url}) => (
                    <Box
                        theme="list"
                        className="mb-2 mb-md-4"
                        key={url}
                    >
                        <Text
                            level="3"
                            onClick={() => {
                                downloadStrapiFile(url, name);
                            }}
                            className={element("name")}
                        >
                            {name}
                        </Text>
                    </Box>
                ))
            }
        </>
    );
};

const StandartDocuments = (props) => {
    const {
        actualList,
        archiveList,
        className,
    } = props;

    const [block, element] = bem("standart-documents", className);

    return (
        <div className={block()}>
            <NmTitle
                className="mb-4 mb-xl-5"
                size="xl"
            >
                Типовые документы Наймикс
            </NmTitle>
            {
                actualList &&
                <StandartDocumentsCardApp
                    title="Действующие редакции"
                    element={element}
                    list={actualList}
                />
            }
            {
                archiveList &&
                <StandartDocumentsCardApp
                    title="Архивные редакции"
                    element={element}
                    list={archiveList}
                />
            }
        </div>
    );
};

export default StandartDocuments;