import {useMemo} from "react";
import {isEmpty} from "lodash";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {handleNumber} from "../../../../utils/stringHelper";

import {SCORE_DICT} from "../../../../constants/reviews";

export const useClientFeedbackListFilter = () => {
    const initFilter = {
        scoresFilter: [],
        dateFromFilter: null,
        dateToFilter: null,
        addressFiasIdsFilter: null,
        specialityIdsFilter: [],
    };

    const scoreOptions = dictionaryToOptions(SCORE_DICT);

    function mapFilterDto(filter) {
        const {
            scoresFilter,
            dateFromFilter,
            dateToFilter,
            addressFiasIdsFilter,
            specialityIdsFilter,
        } = filter;

        return {
            addressFiasIdsFilter: isEmpty(addressFiasIdsFilter) ? undefined : addressFiasIdsFilter,
            specialityIdsFilter: isEmpty(specialityIdsFilter) ? undefined : specialityIdsFilter,
            dateFromFilter: getStartFilterDateWithTimeInUtc(dateFromFilter),
            dateToFilter: getEndFilterDateWithTimeInUtc(dateToFilter),
            scoresFilter: isEmpty(scoresFilter) ? undefined : scoresFilter.map(item => handleNumber(item)),
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "dateFromFilter",
                        endFieldName: "dateToFilter",
                        isClearable: true,
                        label: "Дата отзыва",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Оценка",
                        placeholder: "Выберите оценку",
                        clearable: true,
                        compact: true,
                        name: "scoresFilter",
                        options: scoreOptions,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        name: "addressFiasIdsFilter",
                        label: "Регион или город",
                        placeholder: "Выберите регион или город",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        multiple: true,
                        name: "specialityIdsFilter",
                        label: "Виды деятельности",
                        placeholder: "Выберите виды деятельности",
                        size: "lg",
                    },
                ],
            },
        ];
    }, []);

    return {
        initFilter,
        mapFilterDto,
        filters,
    };
};