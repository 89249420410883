import React from "react";

import CheckboxAccordionList from "../../../../components/ActualComponents/CheckboxAccordionList";
import NmDivider from "../../../../components/ActualComponents/NmDivider";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import Text from "../../../../components/ActualComponents/Text";
import MassConfirm from "../../../../components/MassConfirm";

import {RADIO_TYPE, useFinancePaymentListWarningCheck} from "./hooks/useForm";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";

import "./style.sass";

const FinancePaymentListWarningCheck = (props) => {
    const {
        confirmedPayments,
        confirmedDuplicatedPayments = [],
        warningPayments = [],
        onSubmit,
        onClose,
    } = props;
    const [block, element] = bem("finance-payment-list-warning-check");

    const {
        selectedList,
        selectedCount,
        confirmedCount,
        onSelectedRows,
        touched,
        values,
        errors,
        handleSubmit,
        handleBlur,
        onChange,
    } = useFinancePaymentListWarningCheck({
        confirmedPayments,
        confirmedDuplicatedPayments,
        warningPayments,
        onSubmit,
        onClose,
    });

    return (
        <MassConfirm
            className={block()}
            isWarningLabel
            successLabel="На подтверждении"
            publicationCount={confirmedCount}
            warningLabel="Ожидает принятия решения"
            impossibleCount={selectedCount}
            title="Подтверждение оплат"
            text="По некоторым исполнителям были обнаружены следующие предупреждения. Выберите оплаты, которые вы готовы подтвердить, несмотря на полученную информацию"
            publishTooltip='Счетчик "На подтверждении" показывает количество оплат, которые были выбраны Вами в разделе "Финансы", прошли первичные проверки и находятся в обработке'
            unablePublishTooltip='Счетчик "Ожидает принятия решения" показывает количество оплат, которые выбраны Вами в рамках данного окна для последующего подтверждения (передачи в обработку к имеющимся)'
            confirmButton="Подтвердить"
            cancelButton="Отменить"
            isCheckboxList
            warningText="Невыбранные в данном списке выплаты будут отклонены или возвращены в статус «На подтверждении заказчиком» в зависимости от вашего решения."
            classNameList={element("list")}
            content={
                <CheckboxAccordionList
                    onSelectedRows={onSelectedRows}
                    list={selectedList}
                />
            }
            additionalContent={
                <>
                    <NmDivider className="my-3 my-md-4" />
                    <Text.Sub
                        medium
                        className="mb-3 mb-md-4"
                    >
Что сделать с оплатами, которые Вы не выбрали в данном окне?
                    </Text.Sub>
                    <NmRadioV2
                        className="mb-2"
                        disabled={selectedCount === selectedList.length}
                        label="Отложить (Оплаты будут возвращены в статус «На подтверждении заказчиком») "
                        checked={values.solution === RADIO_TYPE.SET_ASIDE}
                        onChange={() => {
                            onChange(null, {name: "solution", value: RADIO_TYPE.SET_ASIDE});
                        }}
                        error={touched && touched.solution ?
                            errors && errors.solution :
                            undefined
                        }
                    />
                    <NmRadioV2
                        className="mb-3 mb-md-4"
                        label="Отклонить"
                        disabled={selectedCount === selectedList.length}
                        checked={values.solution === RADIO_TYPE.DECLINE}
                        onChange={() => {
                            onChange(null, {name: "solution", value: RADIO_TYPE.DECLINE});
                        }}
                        error={touched && touched.solution ?
                            errors && errors.solution :
                            undefined
                        }
                    />
                    {
                        values.solution === RADIO_TYPE.DECLINE &&
                        <NmInputV2
                            label="Название"
                            disabled={values.solution !== RADIO_TYPE.DECLINE}
                            value={values.reasonForRefusal}
                            name="reasonForRefusal"
                            size="xl"
                            onBlur={handleBlur}
                            maxLength={255}
                            onChange={onChange}
                            error={touched && touched.reasonForRefusal ?
                                errors && errors.reasonForRefusal :
                                undefined
                            }
                        />
                    }
                    {
                        selectedList.length === selectedCount &&
                        <Text
                            level="3"
                            color={COLOR.PRIMARY_100}
                        >
Все платежи выбраны для подтверждения
                        </Text>
                    }
                </>
            }
            isOnlyConfirm
            onConfirm={handleSubmit}
            onCancel={onClose}
        />
    );
};

export default FinancePaymentListWarningCheck;