import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getKedoTemplateEditData} from "./getEditData";

import {downloadDocument} from "../../../../../../ducks/documents";

export const useKedoTemplateLoadEditForm = (params) => {
    const {
        name,
        values,
        setValues,
        htmlDownloadLink,
        userReferenceParamModels,
    } = params;

    const dispatch = useDispatch();

    const [progressFile, setProgressFile] = useState(false);

    useEffect(() => {
        if (name && !values.templateName) {
            const values = getKedoTemplateEditData(name, userReferenceParamModels);

            fetchFile(values);
        }
    }, []);

    const fetchFile = (values) => {
        setProgressFile(true);

        const downloadLink = `${process.env.REACT_APP_PROXY}${htmlDownloadLink}`;

        dispatch(downloadDocument({
            isDownload: false,
            getResultFile: (file) => {
                setProgressFile(false);
                setValues({
                    ...values,
                    files: [file],
                });
            },
            downloadLink,
            fileName: `${name}.html`,
            onError: () => {
                setProgressFile(false);
            },
        }));
    };

    return {
        progressFile,
    };
};