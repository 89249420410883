import React from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import FilterSpecialities from "../../../../../components/ActualComponents/FilterSpecialities";
import NmDadataInput from "../../../../../components/ActualComponents/NmDadataInput";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import {dadataFromCityToApartmentFilter} from "../../../../../utils/dadata";

import {ADVERTISEMENT_STATUS_OPTIONS} from "../../constants";

function AdvertisementFilter(props) {
    const {
        filter: {
            workAddressFiasValue,
            name,
            specialityIds,
            publicationFromFilter,
            publicationToFilter,
            fromDateCreatedFilter,
            toDateCreatedFilter,
            status,
        },
        filter,
        onChangeFilter,
        submitFilter,
        initFilter,
    } = props;

    const handleOnSearch = () => {
        submitFilter(filter, true);
    };

    const handleOnClear = () => {
        submitFilter({...initFilter}, false);
    };

    return (
        <NmForm addMargin>
            <NmInputV2
                label="Название"
                value={name}
                name="name"
                size="lg"
                onChange={onChangeFilter}
            />
            <FilterSpecialities
                label="Вид деятельности"
                name="specialityIds"
                onChange={onChangeFilter}
                onSubmit={handleOnSearch}
                value={specialityIds}
                multiple
            />
            <NmDadataInput
                size="lg"
                fromBound="city"
                label="Город ведения деятельности"
                filter={dadataFromCityToApartmentFilter}
                name="workAddressFiasId"
                query={workAddressFiasValue}
                onChange={value => {
                    onChangeFilter(null, {value: value.value, name: "workAddressFiasValue"});
                    if (value.data && value.data.fias_id) {
                        onChangeFilter(null, {value: value.data.fias_id, name: "workAddressFiasId"});
                    }
                }}
            />
            <NmDateRangePickerV2
                size="lg"
                label="Период публикации"
                onChange={onChangeFilter}
                startFieldName="publicationFromFilter"
                endFieldName="publicationToFilter"
                value={{
                    publicationFromFilter,
                    publicationToFilter,
                }}
            />
            <NmDateRangePickerV2
                size="lg"
                label="Период создания"
                onChange={onChangeFilter}
                startFieldName="fromDateCreatedFilter"
                endFieldName="toDateCreatedFilter"
                value={{
                    fromDateCreatedFilter,
                    toDateCreatedFilter,
                }}
            />
            <NmDropdownV2
                label="Статус"
                size="lg"
                name="status"
                options={ADVERTISEMENT_STATUS_OPTIONS}
                value={status}
                onChange={onChangeFilter}
            />
            <FilterButtonsV2
                className="signing-documents-log-filter__actions"
                onClear={handleOnClear}
                onSearch={handleOnSearch}
            />
        </NmForm>
    );
}

export default AdvertisementFilter;