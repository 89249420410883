import React, {ReactNode} from "react";

import {ReactComponent as DoneIcon} from "../../../../images/done_24.svg";
import DefaultDropzone from "../../../DefaultDropzone";
import ExtLink from "../../../ExtLink";
import NmTooltip, {TNmTooltipPosition} from "../../NmTooltip";
import Text from "../../Text";

import {
    OptionType,
} from "../../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../../utils/bem";

import {ReactNodeLike} from "prop-types";

import "./style.sass";

interface IContextMenuContent {
    options: Array<OptionType> | [],
    onClickItem?: (option: OptionType) => void,
    onCloseContextMenu?: () => void,
    position?: TNmTooltipPosition,
    className?: string,
    isVisibleSelected?: boolean,
    selected?: OptionType,
    isModalSize?: boolean,
    inline?: boolean,
    setInnerOptions?: (state: Array<OptionType>) => void,
    setOpen?: (state: boolean) => void,
}

const ContextMenuContent = (props: IContextMenuContent) => {
    const {
        options,
        onClickItem,
        onCloseContextMenu,
        inline,
        isModalSize,
        className = "",
        isVisibleSelected,
        selected,
        position = "bottom-right",
        setInnerOptions,
        setOpen,
    } = props;

    const [block, element] = bem("context-menu-content", className);

    const getLink = (params: { option: OptionType, children: ReactNode }) => {
        const {
            option,
            children,
        } = params;
        if (option.useExtLink) {
            return (
                <ExtLink
                    to={option.href}
                    className={element("link")}
                >
                    {children as ReactNodeLike}
                </ExtLink>
            );
        }

        return (
            <a
                target="_blank"
                className={element("link")}
                rel="noopener noreferrer"
                href={option.href}
            >
                {children}
            </a>
        );
    };

    const getItems = () => {
        return (options || []).filter(({visible = true}) => visible).map((option: OptionType) => {
            const isLink = Boolean(option.href);

            if (option.onDropFiles) {
                return (
                    <DefaultDropzone
                        multiple={true}
                        maxFiles={5}
                        accept=".jpg, .png, .docx, .doc, .pdf, .rtf."
                        onDropFiles={option.onDropFiles}
                        disabled={option.disabled}
                    >
                        <div className={element("item", {disabled: option.disabled})}>
                            <div className={element("item-value")}>
                                <Text
                                    level="3"
                                    noWrap
                                >
                                    {option.text}
                                </Text>
                            </div>
                        </div>
                    </DefaultDropzone>
                );
            }

            return (
                <div
                    className={element("item", {link: isLink, disabled: option.disabled})}
                    key={option.key}
                    onClick={() => {
                        if (option.disabled) {
                            return;
                        }

                        if (option.options && setInnerOptions && setOpen) {
                            // setTimeout используется, чтобы после того, как отработет useClickOutside внутри компонента ContextMenu,
                            // заново открыть конктекстное меню, но уже с внутренними options
                            setTimeout(() => {
                                setInnerOptions(option.options || []);
                                setOpen(true);
                            }, 50);

                            return;
                        }

                        if (option.onClick) {
                            option.onClick();
                        }

                        if (onCloseContextMenu) {
                            onCloseContextMenu();
                        }

                        if (!isLink && onClickItem) {
                            onClickItem(option);
                        }
                    }}
                >
                    {
                        isLink
                            ? getLink({
                                option,
                                children: <>
                                    {
                                        option.icon &&
                                        <div className={element("icon")}>
                                            {option.icon}
                                        </div>
                                    }
                                    <Text
                                        level="3"
                                        noWrap
                                    >
                                        {option.text}
                                    </Text>
                                </>,
                            })
                            : <div className={element("item-value")}>
                                {
                                    option.icon &&
                                    <div className={element("icon")}>
                                        {option.icon}
                                    </div>
                                }
                                <div className={element("text-container")}>
                                    <Text
                                        level="3"
                                        noWrap
                                    >
                                        {option.text}
                                    </Text>
                                    {
                                        isVisibleSelected && selected && selected.key === option.key &&
                                        <DoneIcon className={element("selected-icon")} />
                                    }
                                </div>
                            </div>
                    }
                </div>
            );
        });
    };

    return (
        <NmTooltip
            className={block({inline, modal: isModalSize})}
            noPadding
            type="light"
            position={position}
        >
            {getItems()}
        </NmTooltip>
    );
};

export default ContextMenuContent;