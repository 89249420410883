import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Formik} from "formik";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import KedoTemplatesEditForm from "./form";
import KedoTemplatePreview from "./preview";

import {useModal} from "../../../../../hooks/useModal";
import {useKedoTemplateEditForm} from "./hooks/useForm";

import {
    kedoTemplateIsSuccessSelector,
    kedoTemplatesProgressActionSelector,
} from "../../../../../ducks/kedo/templates/selectors";

const KedoTemplateEdit = (props) => {
    const {
        id,
        onClose,
        fetchList,
        name,
        htmlDownloadLink,
        userReferenceParamModels,
    } = props;

    const progressAction = useSelector(kedoTemplatesProgressActionSelector);
    const isSuccessPreviewLoading = useSelector(kedoTemplateIsSuccessSelector);

    const {
        onOpenModal: onOpenPreview,
        onCloseModal: onClosePreview,
        isOpen: isOpenPreview,
    } = useModal();

    useEffect(() => {
        if (isSuccessPreviewLoading) {
            onOpenPreview({});
        }
    }, [
        isSuccessPreviewLoading,
    ]);

    const {
        initialValues,
        validationSchema,
        onSubmit,
    } = useKedoTemplateEditForm({
        id,
        fetchList,
        onClose,
    });

    const {
        isOpen,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const onCancel = () => {
        onOpenModal({});
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnBlur={true}
            enableReinitialize={true}
        >
            {
                (formikProps) => {
                    if (isOpenPreview) {
                        return (
                            <KedoTemplatePreview
                                onClose={onClosePreview}
                                file={formikProps.values.previewFile}
                            />
                        );
                    }

                    if (isOpen) {
                        return (
                            <NmConfirmV2
                                onCancel={onCloseModal}
                                onConfirm={onClose}
                                confirmButton="Да"
                                cancelButton="Нет"
                                content="Вы уверены, что хотите отменить создание шаблона? Все заполненные данные будут удалены."
                            />
                        );
                    }

                    return (
                        <NmModal
                            size="md"
                            onClose={onCancel}
                            header={
                                <NmTitle size="lg">
                                    Создание шаблона
                                </NmTitle>
                            }
                            footer={
                                <ApplyButtons
                                    align="right"
                                    onClose={onCancel}
                                    submitBtnContent="Создать"
                                    cancelBtnContent="Отменить"
                                    mobile="column"
                                    type="button"
                                    submit={formikProps.handleSubmit}
                                    disabled={progressAction}
                                    isHiddenCancelOnMobile
                                />
                            }
                        >
                            <KedoTemplatesEditForm
                                formikProps={formikProps}
                                name={name}
                                htmlDownloadLink={htmlDownloadLink}
                                userReferenceParamModels={userReferenceParamModels}
                            />
                        </NmModal>
                    );
                }
            }
        </Formik>
    );
};

export default KedoTemplateEdit;