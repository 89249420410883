import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {isClientUser} from "../../../../../constants/roles";

import {
    addClientsKedoDocumentType,
    deleteClientsKedoDocumentType,
    getClientsKedoDocumentTypePage,
    updateClientsKedoDocumentType,
} from "../../../../../ducks/bff/clients/kedo/directories/services";

import {
    addAdmKedoUploadDocumentType,
    deleteAdmKedoUploadDocumentType,
    getAdmKedoUploadDocumentTypesPage,
    updateAdmKedoUploadDocumentType,
} from "../../../../../ducks/bff/adm/settings/directories/kedo/upload-document-types/services";

export const useKedoDocumentTypesBff = (params) => {
    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const dispatch = useDispatch();

    const deleteDocumentType = (data) => {
        if (isClientRole) {
            dispatch(deleteClientsKedoDocumentType(data));

            return;
        }

        dispatch(deleteAdmKedoUploadDocumentType(data));
    };

    const addDocumentType = (data) => {
        if (isClientRole) {
            dispatch(addClientsKedoDocumentType(data));

            return;
        }

        dispatch(addAdmKedoUploadDocumentType(data));

    };

    const fetchDocumentTypePage = (data) => {
        if (isClientRole) {
            dispatch(getClientsKedoDocumentTypePage(data));

            return;
        }

        dispatch(getAdmKedoUploadDocumentTypesPage(data));
    };

    const updateDocumentType = (data) => {
        if (isClientRole) {
            dispatch(updateClientsKedoDocumentType(data));

            return;
        }

        dispatch(updateAdmKedoUploadDocumentType(data));
    };

    return {
        deleteDocumentType,
        addDocumentType,
        fetchDocumentTypePage,
        updateDocumentType,
    };
};