import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {useCollectionBankDetailsFilter} from "./useCollectionBankDetailsFilter";

import {usePagination} from "../../../../hooks/usePagination";

import {getEndDate, getStartDate, getUtcDateFilter} from "../../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {deleteCollectionBankDetails, getPageCollectionBankDetails} from "../../../../ducks/collectionBankDetails";

export function useCollectionBankDetailsList() {
    const [isOpenAddModal, setOpenAddModal] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [confirmData, setConfirmData] = useState({});

    const dispatch = useDispatch();
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {filter, filtersBase, clearFilter, submitFilter} = useCollectionBankDetailsFilter({setPagination, pageSize});

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter]);

    const fetchList = () => {
        const {
            leadTypeFilter,
        } = filter;

        dispatch(getPageCollectionBankDetails({
            pageSize,
            pageNum,
            ...filter,
            leadTypeFilter: isNullOrWhitespace(leadTypeFilter) || leadTypeFilter === "ALL" ? undefined : leadTypeFilter,
            addedDateTimeFromFilter: getUtcDateFilter(getStartDate(filter.addedDateTimeFromFilter)),
            addedDateTimeToFilter: getUtcDateFilter(getEndDate(filter.addedDateTimeToFilter)),
        }));
    };

    const handleDelete = (id) => {
        dispatch(deleteCollectionBankDetails({
            id,
            onSuccess: fetchList,
        }));
    };

    const toggleAddModal = () => {
        setOpenAddModal(prevState => !prevState);
    };

    const closeEditModal = () => {
        toggleAddModal();
        setCurrentItem({});
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setCurrentItem({});
    };

    return {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        filtersBase,
        submitFilter,
        clearFilter,
        toggleAddModal,
        isOpenAddModal,
        fetchList,
        currentItem,
        setCurrentItem,
        closeEditModal,
        confirmData,
        setConfirmData,
        handleCloseConfirm,
        handleDelete,
    };
}