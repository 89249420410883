import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    crmChatWithNamemixGetMessagePage,
    crowdChatMarkUnread,
    getMessageList,
    getMessageListCrowd,
    updateFieldChat,
} from "../../../../../../ducks/chat";
import {getChatUnreadCount} from "../../../../../../ducks/chatList";

export function useFetchMessages(props) {
    const {
        chatId,
        unreadMessageCount,
        isNamemixChat,
        pageData,
        setPageData,
        isCrowd,
        contractorId,
        clientId,
        markedUnread,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        setPageData({pageNum: 1});
        fetchList(1);

        if (isCrowd && markedUnread) {
            dispatch(crowdChatMarkUnread({
                chatId,
                clientId,
                markedUnread: false,
            }));
        }
    }, [chatId]);

    useEffect(() => {
        if (pageData.pageNum > 1) {
            fetchList(pageData.pageNum);
        }
    }, [pageData]);

    useEffect(() => {
        return () => {
            updateIsShowNaimixDefaultMessage(false);
        };
    }, []);

    function updateIsShowNaimixDefaultMessage(value) {
        dispatch(updateFieldChat({
            isShowNaimixDefaultMessage: value,
            messageList: [],
        }));
    }

    function getUnreadCount() {
        dispatch(getChatUnreadCount());
    }

    function fetchList(pageNum) {
        if (!chatId) {
            dispatch(updateFieldChat({
                messageList: [],
            }));

            return;
        }

        const reqData = {
            chatId,
            pageNum,
            pageSize: 25,
            isLoadMore: pageNum !== 1,
        };

        if (isCrowd) {
            dispatch(getMessageListCrowd({
                clientIdFilter: clientId,
                chatIdFilter: chatId,
                pageNum,
                pageSize: 25,
                isLoadMore: pageNum !== 1,
            }));

            return;
        }

        if (isNamemixChat) {
            dispatch(crmChatWithNamemixGetMessagePage({
                ...reqData,
                getResult: ({results}) => {
                    if (!results?.length) {
                        updateIsShowNaimixDefaultMessage(true);
                    }

                    getUnreadCount();
                },
            }));

            return;
        }

        dispatch(getMessageList({
            ...reqData,
            onSuccess: () => {
                if (unreadMessageCount) {
                    getUnreadCount();
                }
            },
        }));
    }

    return {
        fetchList,
    };
}