export const BFF_CROWD_TASKS_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_GET_PAGE_ERROR = "BFF_CROWD_TASKS_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST = "BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST";
export const BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS = "BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS";
export const BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR = "BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR";

export const BFF_CROWD_TASKS_ADD_DRAFT_REQUEST = "BFF_CROWD_TASKS_ADD_DRAFT_REQUEST";
export const BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS = "BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS";
export const BFF_CROWD_TASKS_ADD_DRAFT_ERROR = "BFF_CROWD_TASKS_ADD_DRAFT_ERROR";

export const BFF_CROWD_TASKS_UPDATE_REQUEST = "BFF_CROWD_TASKS_UPDATE_REQUEST";
export const BFF_CROWD_TASKS_UPDATE_SUCCESS = "BFF_CROWD_TASKS_UPDATE_SUCCESS";
export const BFF_CROWD_TASKS_UPDATE_ERROR = "BFF_CROWD_TASKS_UPDATE_ERROR";

export const BFF_CROWD_TASKS_PUBLISH_REQUEST = "BFF_CROWD_TASKS_PUBLISH_REQUEST";
export const BFF_CROWD_TASKS_PUBLISH_SUCCESS = "BFF_CROWD_TASKS_PUBLISH_SUCCESS";
export const BFF_CROWD_TASKS_PUBLISH_ERROR = "BFF_CROWD_TASKS_PUBLISH_ERROR";

export const BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST = "BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST";
export const BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS = "BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS";
export const BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR = "BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR";

export const BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST = "BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST";
export const BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS = "BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR = "BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR";

export const BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST = "BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST";
export const BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS = "BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS";
export const BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR = "BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR";

export const BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST = "BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST";
export const BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS = "BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR = "BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR";

export const BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST = "BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST";
export const BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS = "BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS";
export const BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR = "BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR";

export const BFF_CROWD_TASKS_CLOSE_REQUEST = "BFF_CROWD_TASKS_CLOSE_REQUEST";
export const BFF_CROWD_TASKS_CLOSE_SUCCESS = "BFF_CROWD_TASKS_CLOSE_SUCCESS";
export const BFF_CROWD_TASKS_CLOSE_ERROR = "BFF_CROWD_TASKS_CLOSE_ERROR";

export const BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST = "BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST";
export const BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS = "BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR = "BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR";

export const BFF_CROWD_TASKS_DELETE_REQUEST = "BFF_CROWD_TASKS_DELETE_REQUEST";
export const BFF_CROWD_TASKS_DELETE_SUCCESS = "BFF_CROWD_TASKS_DELETE_SUCCESS";
export const BFF_CROWD_TASKS_DELETE_ERROR = "BFF_CROWD_TASKS_DELETE_ERROR";

export const BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST = "BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST";
export const BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS = "BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR = "BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR";

export const BFF_CROWD_TASKS_GET_BY_ID_REQUEST = "BFF_CROWD_TASKS_GET_BY_ID_REQUEST";
export const BFF_CROWD_TASKS_GET_BY_ID_SUCCESS = "BFF_CROWD_TASKS_GET_BY_ID_SUCCESS";
export const BFF_CROWD_TASKS_GET_BY_ID_ERROR = "BFF_CROWD_TASKS_GET_BY_ID_ERROR";

export const BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST = "BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST";
export const BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS = "BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS";
export const BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR = "BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR";

export const BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST = "BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST";
export const BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS = "BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS";
export const BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR = "BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR";

export const BFF_CROWD_TASKS_EXTEND_REQUEST = "BFF_CROWD_TASKS_EXTEND_REQUEST";
export const BFF_CROWD_TASKS_EXTEND_SUCCESS = "BFF_CROWD_TASKS_EXTEND_SUCCESS";
export const BFF_CROWD_TASKS_EXTEND_ERROR = "BFF_CROWD_TASKS_EXTEND_ERROR";

export const BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST = "BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST";
export const BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS = "BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS";
export const BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR = "BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR";

export const UPDATE_CROWD_TASKS_FILED_STORE = "UPDATE_CROWD_TASKS_FILED_STORE";