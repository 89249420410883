import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import DropzoneV2 from "../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import ImageCrop from "../../../../../components/ImageCrop";
import NmTitle from "../../../../../components/NmTitle";

import {useCounter} from "../../../../../hooks/useCounter";
import {useModal} from "../../../../../hooks/useModal";
import {useClientBrandBff} from "../../hooks/useBff";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {clientBrandsProgressActionSelector} from "../../../../../ducks/client/brands/selectors";

const LOGO_TYPE = {
    LOGO: "LOGO",
    LOGO_THUMBNAIL: "LOGO_THUMBNAIL",
};

export const ClientBrandsEdit = (props) => {
    const {
        clientId,
        brandId,
        isLogo,
        brandName,
        onClose,
        logos,
        fetchList,
        updateLogos,
        urlLogo,
        checkError,
    } = props;

    const {
        add,
        update,
        addLogo,
        deleteLogo,
    } = useClientBrandBff();

    const progressAction = useSelector(clientBrandsProgressActionSelector);
    const [progress, setProgress] = useState(false);

    const {
        isOpen,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        setValues,
    } = useFormik({
        onSubmit,
        initialValues: {
            brandName: brandName || "",
            files: [],
        },
        validationSchema: yup.object().shape({
            brandName: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .min(3, "Введите от 3 до 250 символов")
                .matches(/^[0-9a-zA-ZА-Яа-я.,?!;:\-()"«»\s]*$/, "Цифры, кириллица и латиница, знаков .,?!;:-()\"\"«»"),
        }),
        enableReinitialize: true,
    });

    const {
        isCounterFinish: isDeletingEnd,
        increaseCounter,
    } = useCounter({
        countList: [
            LOGO_TYPE.LOGO_THUMBNAIL,
            LOGO_TYPE.LOGO,
        ],
    });

    useEffect(() => {
        if (isDeletingEnd) {
            fetchList();
            onClose();
        }
    }, [isDeletingEnd]);

    useEffect(() => {
        if (!brandId) {
            return;
        }

        if (logos[brandId]?.full) {
            return;
        }

        updateLogos({
            brandId,
            isLogo,
            isThumbnail: false,
            urlLogo,
        });
    }, []);

    useEffect(() => {
        if (!brandId || !logos[brandId]?.full) {
            return;
        }

        setFieldValue("files", [{
            name: "",
            type: "image/png",
            isLoaded: true,
            preview: logos[brandId].full,
        }]);
    }, [
        logos,
    ]);

    function onSubmit(values) {
        const isFileAdded = brandId && !logos[brandId]?.full && !isEmpty(values.files[0]);
        const isFileChanged = brandId && logos[brandId]?.full && !isEmpty(values.files[0]) && !values.files[0].isLoaded;
        const isFileDeleted = brandId && logos[brandId]?.full && isEmpty(values.files);

        if (brandId) {
            update({
                brandId,
                clientId,
                brandName: values.brandName,
                checkError,
                onSuccess: () => {
                    if (isFileDeleted) {
                        setProgress(true);

                        deleteLogo({
                            clientId,
                            brandId,
                            fileType: LOGO_TYPE.LOGO,
                            onSuccess: () => {
                                increaseCounter();
                                updateLogos({
                                    brandId,
                                    isThumbnail: false,
                                    isClearLogo: true,
                                });
                            },
                            onError: () => {
                                increaseCounter();
                            },
                        });

                        deleteLogo({
                            clientId,
                            brandId,
                            fileType: LOGO_TYPE.LOGO_THUMBNAIL,
                            onSuccess: () => {
                                increaseCounter();
                                updateLogos({
                                    brandId,
                                    isThumbnail: true,
                                    isClearLogo: true,
                                });
                            },
                            onError: () => {
                                increaseCounter();
                            },
                        });

                        return;
                    }

                    fetchList();
                    onClose();

                    if (isFileChanged || isFileAdded) {
                        uploadLogos(brandId);
                    }
                },
            });

            return;
        }

        add({
            clientId,
            brandName: values.brandName,
            checkError,
            onSuccess: ({guid}) => {
                fetchList();
                onClose();

                if (isEmpty(values.files)) {
                    fetchList();
                    onClose();

                    return;
                }

                uploadLogos(guid);
            },
        });
    }

    const uploadLogos = (brandId) => {
        uploadLogo({
            type: LOGO_TYPE.LOGO,
            file: values.files[0],
            brandId,
        });

        uploadLogo({
            type: LOGO_TYPE.LOGO_THUMBNAIL,
            file: values.croppedFile,
            brandId,
        });
    };

    const uploadLogo = ({type, brandId, file}) => {
        const formData = new FormData();

        formData.append("brandId", brandId);
        formData.append("clientId", clientId);
        formData.append("fileType", type);
        formData.append("file", file);

        addLogo({
            formData,
            onSuccess: () => {
                updateLogos({
                    brandId,
                    isThumbnail: type === LOGO_TYPE.LOGO_THUMBNAIL,
                    preview: file.preview,
                });
            },
        });
    };

    if (isOpen) {
        return (
            <ImageCrop
                file={values.files[0]}
                submit={(file) => {
                    onCloseModal();

                    const _file = Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    });

                    setFieldValue("croppedFile", _file);
                }}
                close={() => {
                    onCloseModal();

                    setValues({
                        ...values,
                        files: [],
                        croppedFile: {},
                    });
                }}
            />
        );
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {brandId ? "Редактирование бренда" : "Создание бренда"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    cancelBtnContent="Отменить"
                    submitBtnContent="Сохранить"
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progressAction || progress}
                />
            }
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    value={values.brandName}
                    onChange={(event, {value}) => {
                        setFieldValue("brandName", value);
                    }}
                    label="Название бренда"
                    placeholder="Введите название бренда"
                    maxLength={250}
                    error={
                        touched?.brandName && errors?.brandName ?
                            errors?.brandName :
                            undefined
                    }
                />
                <DropzoneV2
                    maxSize={10}
                    files={values.files}
                    label="Логотип"
                    isVisibleLabel={true}
                    isImagePreview={true}
                    isShowDropzone={isEmpty(values.files)}
                    onChange={(files) => {
                        setFieldValue("files", files);

                        if (!isEmpty(files)) {
                            onOpenModal({});
                        }
                    }}
                    multiple={false}
                    format="*.PNG, *.JPG, *.JPEG, *.GIF, *.BMP"
                    accept=".png, .jpeg, .jpg, .gif, .bmp"
                />
            </NmForm>
        </NmModal>
    );
};