import React from "react";
import {useMedia} from "react-media";

import Text from "../ActualComponents/Text";
import NmButton from "../NmButton";
import TableSelectedLabel from "../TableSelectedLabel";

import {COLOR} from "../../constants/color";

import PropTypes from "prop-types";

import "./style.sass";

const SelectionCountWithAction = props => {
    const {
        count,
        otherActions,
        onClick,
        buttonContent,
        className,
        adaptiveLogic = false,
        disabled,
        buttonColor = "grey",
        maxSelected,
        maxSelectedText,
    } = props;
    const isNotMobile = useMedia({query: {minWidth: 768}});

    const getAdaptiveContent = () => {
        return isNotMobile &&
            <>
                {
                    buttonContent &&
                    <NmButton
                        size="lg"
                        className="list-selected-with-actions__main-button"
                        color={buttonColor}
                        disabled={count === 0 || disabled}
                        onClick={onClick}
                    >
                        {buttonContent}
                    </NmButton>
                }
                {otherActions}
            </>;
    };

    return (
        <div className={`list-selected-with-actions ${className}`}>
            <TableSelectedLabel
                count={count}
            />
            {
                adaptiveLogic ?
                    getAdaptiveContent() :
                    <>
                        {
                            buttonContent &&
                            <NmButton
                                size="lg"
                                color={buttonColor}
                                disabled={count === 0}
                                onClick={onClick}
                            >
                                {buttonContent}
                            </NmButton>
                        }
                        {otherActions}
                    </>
            }
            {
                maxSelected && count >= maxSelected &&
                <Text
                    color={COLOR.NEGATIVE_100}
                >
                    {maxSelectedText}
                </Text>
            }
        </div>
    );
};

SelectionCountWithAction.propTypes = {
    count: PropTypes.number,
    otherActions: PropTypes.node,
    onClick: PropTypes.func,
    buttonContent: PropTypes.string,
    className: PropTypes.string,
};

export default SelectionCountWithAction;