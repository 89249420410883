import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getClientCardSelector} from "./client";

import {blobToFile, getBlobInParts} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess, toastWarning} from "../utils/toastHelper";

import {STORE_CONTRACTOR_DOCUMENT_INIT_STATE} from "../constants/documentType";
import {DOCUMENTS_MESSAGE} from "../constants/messages";
import {OPERATOR_REPORT_STATUSES} from "../constants/operatorReports";

export const controller = "/documents";

//*  TYPES  *//

const DOCUMENT_LIST_REQUEST = "DOCUMENT_LIST_REQUEST";
const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS";
const DOCUMENT_LIST_ERROR = "DOCUMENT_LIST_ERROR";

const DOCUMENT_CONTRACTOR_LIST_REQUEST = "DOCUMENT_CONTRACTOR_LIST_REQUEST";
const DOCUMENT_CONTRACTOR_LIST_SUCCESS = "DOCUMENT_CONTRACTOR_LIST_SUCCESS";
const DOCUMENT_CONTRACTOR_LIST_ERROR = "DOCUMENT_CONTRACTOR_LIST_ERROR";

const UPDATE_FIELD_DOCUMENT_STORE = "UPDATE_FIELD_DOCUMENT_STORE";

const DOCUMENT_TYPE_DICTS_REQUEST = "DOCUMENT_TYPE_DICTS_REQUEST";
const DOCUMENT_TYPE_DICTS_LIST_SUCCESS = "DOCUMENT_TYPE_DICTS_LIST_SUCCESS";
const DOCUMENT_TYPE_DICTS_LIST_ERROR = "DOCUMENT_TYPE_DICTS_LIST_ERROR";

const DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_REQUEST = "DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_REQUEST";
const DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_SUCCESS = "DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_SUCCESS";
const DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_ERROR = "DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_ERROR";

const DOCUMENT_DOWNLOAD_REQUEST = "DOCUMENT_DOWNLOAD_REQUEST";
const DOCUMENT_DOWNLOAD_SUCCESS = "DOCUMENT_DOWNLOAD_SUCCESS";
const DOCUMENT_DOWNLOAD_ERROR = "DOCUMENT_DOWNLOAD_ERROR";

const GET_DOCUMENT_CARD_REQUEST = "DOCUMENT_CARD_REQUEST";
const GET_DOCUMENT_CARD_SUCCESS = "DOCUMENT_CARD_SUCCESS";
const CLEAR_DOCUMENT_CARD = "CLEAR_DOCUMENT_CARD";

const DOCUMENT_CONTRACTOR_REFRESH_REQUEST = "DOCUMENT_CONTRACTOR_REFRESH_REQUEST";
const DOCUMENT_CONTRACTOR_REFRESH_SUCCESS = "DOCUMENT_CONTRACTOR_REFRESH_SUCCESS";
const DOCUMENT_CONTRACTOR_REFRESH_ERROR = "DOCUMENT_CONTRACTOR_REFRESH_ERROR";

const DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST = "DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST";
const DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS = "DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS";
const DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR = "DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR";

const USER_INSTRUCTION_TYPE_DICTS_LIST_REQUEST = "USER_INSTRUCTION_TYPE_DICTS_LIST_REQUEST";
const USER_INSTRUCTION_TYPE_DICTS_LIST_SUCCESS = "USER_INSTRUCTION_TYPE_DICTS_LIST_SUCCESS";
const USER_INSTRUCTION_TYPE_DICTS_LIST_ERROR = "USER_INSTRUCTION_TYPE_DICTS_LIST_ERROR";

const DOCUMENT_DICT_STATUS_REQUEST = "DOCUMENT_DICT_STATUS_REQUEST";
const DOCUMENT_DICT_STATUS_ERROR = "DOCUMENT_DICT_STATUS_ERROR";
const DOCUMENT_DICT_STATUS_SUCCESS = "DOCUMENT_DICT_STATUS_SUCCESS";

const OPERATOR_REPORT_STATUS_REQUEST = "OPERATOR_REPORT_STATUS_REQUEST";
const OPERATOR_REPORT_STATUS_ERROR = "OPERATOR_REPORT_STATUS_ERROR";
const OPERATOR_REPORT_STATUS_SUCCESS = "OPERATOR_REPORT_STATUS_SUCCESS";

const GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_REQUEST = "GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_REQUEST";
const GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_SUCCESS = "GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_SUCCESS";
const GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_ERROR = "GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_ERROR";

const GET_DOCUMENTS_SIGNATURE_LOG_LIST_REQUEST = "GET_DOCUMENTS_SIGNATURE_LOG_LIST_REQUEST";
const GET_DOCUMENTS_SIGNATURE_LOG_LIST_SUCCESS = "GET_DOCUMENTS_SIGNATURE_LOG_LIST_SUCCESS";
const GET_DOCUMENTS_SIGNATURE_LOG_LIST_ERROR = "GET_DOCUMENTS_SIGNATURE_LOG_LIST_ERROR";

const UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST = "UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST";
const UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS = "UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS";
const UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR = "UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR";

const SET_CONTRACTOR_CERTIFICATES = "SET_CONTRACTOR_CERTIFICATES";

const RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST = "RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST";
const RECREATE_START_DOCUMENT_NOTIFICATION_ERROR = "RECREATE_START_DOCUMENT_NOTIFICATION_ERROR";
const RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS = "RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS";

const RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST = "RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST";
const RECREATE_END_DOCUMENT_NOTIFICATION_ERROR = "RECREATE_END_DOCUMENT_NOTIFICATION_ERROR";
const RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS = "RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS";

const GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST = "GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST";
const GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS = "GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS";
const GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR = "GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR";

const CONTRACTOR_CERTIFICATES_ADD_REQUEST = "CONTRACTOR_CERTIFICATES_ADD_REQUEST";
const CONTRACTOR_CERTIFICATES_ADD_SUCCESS = "CONTRACTOR_CERTIFICATES_ADD_SUCCESS";
const CONTRACTOR_CERTIFICATES_ADD_ERROR = "CONTRACTOR_CERTIFICATES_ADD_ERROR";

const CONTRACTOR_CERTIFICATES_EDIT_REQUEST = "CONTRACTOR_CERTIFICATES_EDIT_REQUEST";
const CONTRACTOR_CERTIFICATES_EDIT_SUCCESS = "CONTRACTOR_CERTIFICATES_EDIT_SUCCESS";
const CONTRACTOR_CERTIFICATES_EDIT_ERROR = "CONTRACTOR_CERTIFICATES_EDIT_ERROR";

const CONTRACTOR_CERTIFICATES_DELETE_REQUEST = "CONTRACTOR_CERTIFICATES_DELETE_REQUEST";
const CONTRACTOR_CERTIFICATES_DELETE_SUCCESS = "CONTRACTOR_CERTIFICATES_DELETE_SUCCESS";
const CONTRACTOR_CERTIFICATES_DELETE_ERROR = "CONTRACTOR_CERTIFICATES_DELETE_ERROR";

const EXEMPLARY_REQUEST = "EXEMPLARY_ACTUAL_REQUEST";
const EXEMPLARY_ACTUAL_SUCCESS = "EXEMPLARY_ACTUAL_SUCCESS";
const EXEMPLARY_ARCHIVE_SUCCESS = "EXEMPLARY_ARCHIVE_SUCCESS";
const EXEMPLARY_ERROR = "EXEMPLARY_ACTUAL_ERROR";

const ADD_REGISTRY_FRAME_CONTRACT_REQUEST = "ADD_REGISTRY_FRAME_CONTRACT_REQUEST";
const ADD_REGISTRY_FRAME_CONTRACT_SUCCESS = "ADD_REGISTRY_FRAME_CONTRACT_SUCCESS";
const ADD_REGISTRY_FRAME_CONTRACT_ERROR = "ADD_REGISTRY_FRAME_CONTRACT_ERROR";

const UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST = "UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST";
const UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS = "UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS";
const UPDATE_REGISTRY_FRAME_CONTRACT_ERROR = "UPDATE_REGISTRY_FRAME_CONTRACT_ERROR";

const ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST = "ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST";
const ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS = "ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS";
const ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR = "ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR";

const UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST = "UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST";
const UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS = "UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS";
const UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR = "UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR";

const GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST = "GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST";
const GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS = "GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS";
const GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR = "GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR";

const DOCUMENT_CONTRACTOR_LIST_COUNTS_REQUEST = "DOCUMENT_CONTRACTOR_LIST_COUNTS_REQUEST";
const DOCUMENT_CONTRACTOR_LIST_COUNTS_SUCCESS = "DOCUMENT_CONTRACTOR_LIST_COUNTS_SUCCESS";
const DOCUMENT_CONTRACTOR_LIST_COUNTS_ERROR = "DOCUMENT_CONTRACTOR_LIST_COUNTS_ERROR";

const PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST = "PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST";
const PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS = "PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS";
const PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR = "PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR";

const EXPORT_DOCUMENT_TYPE_DICT_REQUEST = "EXPORT_DOCUMENT_TYPE_DICT_REQUEST";
const EXPORT_DOCUMENT_TYPE_DICT_SUCCESS = "EXPORT_DOCUMENT_TYPE_DICT_SUCCESS";
const EXPORT_DOCUMENT_TYPE_DICT_ERROR = "EXPORT_DOCUMENT_TYPE_DICT_ERROR";

const GET_FILE_LINK_REQUEST = "GET_FILE_LINK_REQUEST";


//*  INITIAL STATE  *//

const
    initial = {
        list: [],
        document: null,
        totalCount: 0,
        pageData: {},
        progressList: false,
        documentTypeDicts: {},
        exportDocumentTypeDict: {},
        documentTypeSignatureLogDicts: {},
        isRefreshInProgress: false,
        isAgencyContractSigned: null,
        isPersonalDataConclusionSigned: null,
        progress: false,
        progressDownload: false,
        progressUpdate: false,
        documentStatusDict: {},
        operatorReportStatusDict: {},
        userInstructionTypeDict: {},
        progressData: {},
        totalCountSignature: 0,
        pageDataSignature: {},
        signatureLogList: [],
        contractorCertificates: {
            certificates: [],
            isLoaded: false,
        },
        exemplaryActual: null,
        exemplaryArchive: null,
        registryFramecontractShortItems: [],
        identificationSheetList: [],
        contractorDocumentCounts: {},
        ...STORE_CONTRACTOR_DOCUMENT_INIT_STATE,
    };

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case UPDATE_FIELD_DOCUMENT_STORE:
        return {
            ...state,
            ...payload,
        };
    case GET_DOCUMENTS_SIGNATURE_LOG_LIST_REQUEST:
        return {
            ...state,
            pageDataSignature: payload.data,
        };
    case DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case DOCUMENT_DOWNLOAD_REQUEST:
        const {id} = payload;
        return {
            ...state,
            progressDownload: true,
            progressData: {
                id,
            },
        };
    case DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case DOCUMENT_DOWNLOAD_SUCCESS:
        return {
            ...state,
            progressDownload: false,
            progressData: {},
        };
    case DOCUMENT_DOWNLOAD_ERROR:
        return {
            ...state,
            progressDownload: false,
            progressData: {},
        };
    case DOCUMENT_CONTRACTOR_LIST_REQUEST: {
        const {field, data} = payload;

        return {
            ...state,
            [field]: {
                ...state[field],
                pageData: data,
            },
            progressList: true,
        };
    }
    case DOCUMENT_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressList: true,
        };
    case USER_INSTRUCTION_TYPE_DICTS_LIST_SUCCESS:
        return {
            ...state,
            userInstructionTypeDict: payload,
        };
    case DOCUMENT_CONTRACTOR_REFRESH_REQUEST:
        return {
            ...state,
            isRefreshInProgress: true,
        };
    case DOCUMENT_CONTRACTOR_REFRESH_SUCCESS:
        return {
            ...state,
            isRefreshInProgress: false,
        };
    case OPERATOR_REPORT_STATUS_SUCCESS:
        return {
            ...state,
            operatorReportStatusDict: payload,
        };
    case DOCUMENT_TYPE_DICTS_LIST_SUCCESS:
        return {
            ...state,
            documentTypeDicts: payload,
        };
    case DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_SUCCESS:
        return {
            ...state,
            documentTypeSignatureLogDicts: payload,
        };
    case DOCUMENT_DICT_STATUS_SUCCESS:
        return {
            ...state,
            documentStatusDict: payload,
        };
    case DOCUMENT_CONTRACTOR_LIST_SUCCESS: {
        const {
            field,
            result: {
                documents: list,
                totalCount,
            },
        } = payload;

        return {
            ...state,
            [field]: {
                ...state[field],
                list,
                totalCount,
            },
        };
    }
    case DOCUMENT_LIST_SUCCESS:
        const {documents, totalCount} = payload;
        return {
            ...state,
            list: documents,
            progressList: false,
            totalCount,
        };
    case GET_DOCUMENTS_SIGNATURE_LOG_LIST_SUCCESS:
        const {signatures: signatureLogList, totalCount: totalCountSignature} = payload;

        return {
            ...state,
            signatureLogList,
            totalCountSignature,
        };
    case DOCUMENT_CONTRACTOR_REFRESH_ERROR:
        return {
            ...state,
            isRefreshInProgress: false,
            error: payload,
        };
    case DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case DOCUMENT_TYPE_DICTS_LIST_ERROR:
    case OPERATOR_REPORT_STATUS_ERROR:
    case DOCUMENT_LIST_ERROR:
        return {
            ...state,
            progressList: false,
            error: payload,
        };
    case SET_CONTRACTOR_CERTIFICATES:
        return {
            ...state,
            contractorCertificates: {
                certificates: payload,
                isLoaded: true,
            },
        };
    case EXEMPLARY_ACTUAL_SUCCESS:
        return {
            ...state,
            exemplaryActual: payload,
        };
    case EXEMPLARY_ARCHIVE_SUCCESS:
        return {
            ...state,
            exemplaryArchive: payload,
        };
    case EXEMPLARY_ERROR:
        return {
            ...state,
            error: payload,
        };
    case RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST:
    case ADD_REGISTRY_FRAME_CONTRACT_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
        };
    case RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
        };
    case UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS:
    case ADD_REGISTRY_FRAME_CONTRACT_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
        };
    case ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
        };
    case RECREATE_START_DOCUMENT_NOTIFICATION_ERROR:
        return {
            ...state,
            progressUpdate: false,
        };
    case RECREATE_END_DOCUMENT_NOTIFICATION_ERROR:
        return {
            ...state,
            progressUpdate: false,
        };
    case UPDATE_REGISTRY_FRAME_CONTRACT_ERROR:
    case ADD_REGISTRY_FRAME_CONTRACT_ERROR:
        return {
            ...state,
            progressUpdate: false,
        };
    case ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR:
        return {
            ...state,
            progressUpdate: false,
        };
    case GET_DOCUMENT_CARD_SUCCESS:
        return {
            ...state,
            document: payload,
        };
    case CLEAR_DOCUMENT_CARD:
        return {
            ...state,
            document: undefined,
        };
    case GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS:
        const {
            results,
        } = payload;

        return {
            ...state,
            registryFramecontractShortItems: results,
            registryFramecontractShortTotalCount: payload.totalCount,
        };
    case GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST:
        return {
            ...state,
            registryFramecontractShortPageSize: payload.pageSize,
        };
    case GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS:
        return {
            ...state,
            identificationSheetList: [payload],
        };
    case DOCUMENT_CONTRACTOR_LIST_COUNTS_SUCCESS:
        return {
            ...state,
            contractorDocumentCounts: payload,
        };
    case EXPORT_DOCUMENT_TYPE_DICT_SUCCESS:
        return {
            ...state,
            exportDocumentTypeDict: payload,
        };
    case PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST:
        return {
            ...state,
            progressList: true,
        };
    case PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS:
        return {
            ...state,
            pendingContracts: {
                list: payload || [],
            },
            progressList: false,
        };
    case PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR:
        return {
            ...state,
            pendingContracts: {
                list: [],
            },
            progressList: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getDocumentList(payload) {
    return {
        type: DOCUMENT_LIST_REQUEST,
        payload,
    };
}

export function getContractorDocumentList(payload) {
    return {
        type: DOCUMENT_CONTRACTOR_LIST_REQUEST,
        payload,
    };
}

export function downloadDocument(payload) {
    return {
        type: DOCUMENT_DOWNLOAD_REQUEST,
        payload,
    };
}

export function getFileLink(payload) {
    return {
        type: GET_FILE_LINK_REQUEST,
        payload,
    };
}

export function getDocumentTypeDictsList() {
    return {
        type: DOCUMENT_TYPE_DICTS_REQUEST,
    };
}

export function getDocumentTypeSignatureLogDicts() {
    return {
        type: DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_REQUEST,
    };
}

export function getOperatorReportStatusList() {
    return {
        type: OPERATOR_REPORT_STATUS_REQUEST,
    };
}

export function getDocument(payload) {
    return {
        type: GET_DOCUMENT_CARD_REQUEST,
        payload,
    };
}

export function updateFieldDocumentStore(payload) {
    return {
        type: UPDATE_FIELD_DOCUMENT_STORE,
        payload,
    };
}

export function getDocumentStatusDict() {
    return {
        type: DOCUMENT_DICT_STATUS_REQUEST,
    };
}

export function refreshContractorDocuments(payload) {
    return {
        type: DOCUMENT_CONTRACTOR_REFRESH_REQUEST,
        payload,
    };
}

export function isFrameContractSigned(payload) {
    return {
        type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST,
        payload,
    };
}

export function getUserInstructionTypeDict(payload) {
    return {
        type: USER_INSTRUCTION_TYPE_DICTS_LIST_REQUEST,
        payload,
    };
}

export function updateWorkNotificationsArchived(payload) {
    return {
        type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST,
        payload,
    };
}

export function getDepositReplenishmentAccountBasisFile(payload) {
    return {
        type: GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_REQUEST,
        payload,
    };
}

export function clearDocument() {
    return {
        type: CLEAR_DOCUMENT_CARD,
    };
}

export function getPageDocumentsSignatureLog(payload) {
    return {
        type: GET_DOCUMENTS_SIGNATURE_LOG_LIST_REQUEST,
        payload,
    };
}

export const addContractorCertificate = (payload) => ({
    type: CONTRACTOR_CERTIFICATES_ADD_REQUEST,
    payload,
});

export const editContractorCertificate = (payload) => ({
    type: CONTRACTOR_CERTIFICATES_EDIT_REQUEST,
    payload,
});

export const deleteContractorCertificate = (payload) => ({
    type: CONTRACTOR_CERTIFICATES_DELETE_REQUEST,
    payload,
});

export function getExemplary(payload) {
    return {
        type: EXEMPLARY_REQUEST,
        payload,
    };
}

export function recreateStartWorkNotification(payload) {
    return {
        type: RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST,
        payload,
    };
}

export function recreateEndWorkNotification(payload) {
    return {
        type: RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST,
        payload,
    };
}

export function addRegistryFrameContract(payload) {
    return {
        type: ADD_REGISTRY_FRAME_CONTRACT_REQUEST,
        payload,
    };
}

export function updateRegistryFrameContract(payload) {
    return {
        type: UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST,
        payload,
    };
}

export function addRegistryFrameContractItems(payload) {
    return {
        type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
        payload,
    };
}

export function updateRegistryFrameContractItems(payload) {
    return {
        type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
        payload,
    };
}

export function getRegistryFramecontractShortItems(payload) {
    return {
        type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST,
        payload,
    };
}

export function getContractorIdentificationSheet(payload) {
    return {
        type: GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST,
        payload,
    };
}

export function getContractorDocumentListCounts(payload) {
    return {
        type: DOCUMENT_CONTRACTOR_LIST_COUNTS_REQUEST,
        payload,
    };
}

export function getContractorPendingContractsList(payload) {
    return {
        type: PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST,
        payload,
    };
}

export function getExportDocumentTypeDict() {
    return {
        type: EXPORT_DOCUMENT_TYPE_DICT_REQUEST,
    };
}

//*  SELECTORS  *//

export const documentSelector = state => state.documents;
export const getProgressDataDownloadSelector = createSelector(documentSelector, ({progressData}) => progressData);
export const getOperatorReportStatusDictSelector = createSelector(documentSelector, ({operatorReportStatusDict}) => operatorReportStatusDict);
export const getOperatorReportStatusOptionsSelector = createSelector(documentSelector, ({operatorReportStatusDict}) => dictionaryToOptions(operatorReportStatusDict));
export const getClientOperatorReportStatusOptionsSelector = createSelector(documentSelector, ({operatorReportStatusDict}) => dictionaryToOptions(operatorReportStatusDict)
    .filter((item) => [OPERATOR_REPORT_STATUSES.SIGNED, OPERATOR_REPORT_STATUSES.CANCELLED, OPERATOR_REPORT_STATUSES.SIGNING_CUSTOMER].includes(item.key)));
export const getDocumentSignatureLogListSelector = createSelector(documentSelector, ({signatureLogList}) => signatureLogList);
export const getDocumentSignatureTotalPagesSelector = createSelector(documentSelector, ({totalCountSignature, pageDataSignature: {pageSize = 0}}) => getTotalPages(totalCountSignature, pageSize));
export const getDocumentSignatureTotalCountSelector = createSelector(documentSelector, ({totalCountSignature}) => totalCountSignature);
export const getDocumentStatusDictSelector = createSelector(documentSelector, ({documentStatusDict}) => documentStatusDict);
export const getDocumentTotalPagesSelector = createSelector(documentSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getDocumentTotalCountSelector = createSelector(documentSelector, ({totalCount}) => totalCount);
export const documentPageDataSelector = createSelector(documentSelector, ({pageData}) => pageData);
export const getIsRefreshInProgressSelector = createSelector(documentSelector, ({isRefreshInProgress}) => isRefreshInProgress);
export const getDocumentListSelector = createSelector(documentSelector, ({list}) => list);
export const getDocumentListFormattedToTaskIdsSelector = createSelector(documentSelector, ({list}) =>
    list.map(value => `${value.clientId}:${value.contractorId}`),
);
export const getRegistryFramecontractShortItemsSelector = createSelector(documentSelector, ({registryFramecontractShortItems}) => registryFramecontractShortItems);
export const getRegistryFramecontractShortTotalCountSelector = createSelector(documentSelector, ({registryFramecontractShortTotalCount}) => registryFramecontractShortTotalCount);
export const getRegistryFramecontractShortTotalPagesSelector = createSelector(documentSelector, ({registryFramecontractShortTotalCount = 0, registryFramecontractShortPageSize}) => getTotalPages(registryFramecontractShortTotalCount, registryFramecontractShortPageSize));

// Договоры с заказчиками
export const getFrameContractDocumentListSelector = createSelector(documentSelector, ({frameContract: {list}}) => list);
export const getFrameContractDocumentTotalPagesSelector = createSelector(documentSelector, ({frameContract}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = frameContract;

    return getTotalPages(totalCount, pageSize);
});

export const getFrameContractDocumentTotalCountSelector = createSelector(documentSelector, ({frameContract}) => {
    const {totalCount} = frameContract;

    return totalCount;
});

// Заявки на выполнение работ (оказание услуг)
export const documentsOrderApplicationListSelector = createSelector(documentSelector, ({orderApplication: {list}}) => list);
export const documentsOrderApplicationTotalPagesSelector = createSelector(documentSelector, ({orderApplication}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = orderApplication;

    return getTotalPages(totalCount, pageSize);
});

export const documentsOrderApplicationTotalCountSelector = createSelector(documentSelector, ({orderApplication}) => {
    const {totalCount} = orderApplication;

    return totalCount;
});

// Акты выполненных работ
export const documentsActOfAcceptanceOfWorkListSelector = createSelector(documentSelector, ({actOfAcceptanceOfWork: {list}}) => list);
export const documentsActOfAcceptanceOfWorkTotalPagesSelector = createSelector(documentSelector, ({actOfAcceptanceOfWork}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = actOfAcceptanceOfWork;

    return getTotalPages(totalCount, pageSize);
});

export const documentsActOfAcceptanceOfWorkTotalCountSelector = createSelector(documentSelector, ({actOfAcceptanceOfWork}) => {
    const {totalCount} = actOfAcceptanceOfWork;

    return totalCount;
});

// Страховые полисы
export const documentsInsurancePolicyListSelector = createSelector(documentSelector, ({insurancePolicy: {list}}) => list);
export const documentsInsurancePolicyTotalPagesSelector = createSelector(documentSelector, ({insurancePolicy}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = insurancePolicy;

    return getTotalPages(totalCount, pageSize);
});

export const documentsInsurancePolicyTotalCountSelector = createSelector(documentSelector, ({insurancePolicy}) => {
    const {totalCount} = insurancePolicy;

    return totalCount;
});

// Квитанции РНКО
export const documentsReceiptsRNKOListSelector = createSelector(documentSelector, ({receiptRNKO: {list}}) => list);
export const documentsReceiptsRNKOTotalPagesSelector = createSelector(documentSelector, ({receiptRNKO}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = receiptRNKO;

    return getTotalPages(totalCount, pageSize);
});

export const documentsReceiptsRNKOTotalCountSelector = createSelector(documentSelector, ({receiptRNKO}) => {
    const {totalCount} = receiptRNKO;

    return totalCount;
});

// Агентский договор с Наймикс
export const documentAgencyContractListSelector = createSelector(documentSelector, ({agencyContract: {list}}) => list);
export const documentAgencyContractPageDataSelector = createSelector(documentSelector, ({agencyContract}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = agencyContract;

    return getTotalPages(totalCount, pageSize);
});

export const documentAgencyContractTotalCountSelector = createSelector(documentSelector, ({agencyContract}) => {
    const {totalCount} = agencyContract;

    return totalCount;
});

// Согласие на обработку ПД
export const documentConsentToPDListSelector = createSelector(documentSelector, ({consentToPersonalData: {list}}) => list);
export const documentConsentToPDPageDataSelector = createSelector(documentSelector, ({consentToPersonalData}) => {
    const {
        totalCount,
        pageData: {
            pageSize,
        },
    } = consentToPersonalData;

    return getTotalPages(totalCount, pageSize);
});

export const documentConsentToPDTotalCountSelector = createSelector(documentSelector, ({consentToPersonalData}) => {
    const {totalCount} = consentToPersonalData;

    return totalCount;
});

//Документ
export const getDocumentSelector = createSelector(documentSelector, ({document}) => document);

export const getDocumentProgressListSelector = createSelector(documentSelector, ({progressList}) => progressList);
export const getDocumentTypeDictsSelector = createSelector(documentSelector, ({documentTypeDicts}) => documentTypeDicts);
export const getDocumentTypeDictsOptionsSelector = createSelector(documentSelector, ({documentTypeDicts}) => dictionaryToOptions(documentTypeDicts));
export const getExportDocumentTypeDictSelector = createSelector(documentSelector, ({exportDocumentTypeDict}) => dictionaryToOptions(exportDocumentTypeDict));
export const documentTypeSignatureLogDictsSelector = createSelector(documentSelector, ({documentTypeSignatureLogDicts}) => documentTypeSignatureLogDicts);
export const documentTypeSignatureLogDictsOptionsSelector = createSelector(documentSelector, ({documentTypeSignatureLogDicts}) => dictionaryToOptions(documentTypeSignatureLogDicts));
export const documentTypeContractorSignatureLogDictsOptionsSelector = createSelector(documentSelector, ({documentTypeSignatureLogDicts}) => {
    return dictionaryToOptions(documentTypeSignatureLogDicts).filter(item => item.value !== "SITE_OPERATOR_REPORT");
});
export const documentNotificationProgressUpdateSelector = createSelector(documentSelector, ({progressUpdate}) => progressUpdate);
export const documentIdentificationSheetListSelector = createSelector(documentSelector, ({identificationSheetList}) => {
    return identificationSheetList.filter(item => Boolean(item.fileLink));
});
export const contractorDocumentCountsSelector = createSelector(documentSelector, ({contractorDocumentCounts}) => contractorDocumentCounts);
export const contractorPendingContractsListSelector = createSelector(documentSelector, ({pendingContracts}) => pendingContracts.list);

//*  SAGA  *//

//POST /api/documents/getPage
export const getDocumentListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DOCUMENT_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENT_LIST_ERROR, payload: error});
    }
};


//POST /api/documents/getPage
export const getContractorDocumentListSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            field,
            data,
        } = payload;

        const result = yield request.post(`${controller}/getPage`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENT_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENT_CONTRACTOR_LIST_SUCCESS, payload: {
                field,
                result,
            },
        });
    } catch (error) {
        yield put({type: DOCUMENT_CONTRACTOR_LIST_ERROR, payload: error});
    }
};

export const isFrameContractSignedSaga = function* (action) {
    try {
        const {payload: {data: params, onSuccess}} = action;

        const result = yield request.get(`${controller}/isFrameContractSigned`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENT_CONTRACTOR_REFRESH_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR, payload: error});
    }
};

//GET /api/documents/dicts/documentType
export const getDocumentTypeSignatureLogDictsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/signatureLog`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_ERROR, payload: error});
    }
};

//GET /api/documents/dicts/documentType
export const getDocumentTypeDictsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/documentType`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENT_TYPE_DICTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DOCUMENT_TYPE_DICTS_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENT_TYPE_DICTS_LIST_ERROR, payload: error});
    }
};

export const getDocumentStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/documentStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENT_DICT_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DOCUMENT_DICT_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENT_DICT_STATUS_ERROR, payload: error});
    }
};

export const getOperatorReportStatusListSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/operationReportStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OPERATOR_REPORT_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: OPERATOR_REPORT_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: OPERATOR_REPORT_STATUS_ERROR, payload: error});
    }
};

//POST /api/documents/refreshContractorDocuments
export const refreshContractorDocumentsSaga = function* (action) {
    try {
        const {
            payload: {
                contractorId,
            },
        } = action;

        const result = yield request.post(`${controller}/refreshContractorDocuments?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENT_CONTRACTOR_REFRESH_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Документы успешно обновлены");

        yield put({type: DOCUMENT_CONTRACTOR_REFRESH_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: DOCUMENT_CONTRACTOR_REFRESH_ERROR, payload: error});
    }
};

export const getDocumentSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
            fileName,
        } = payload;

        const myInit = {
            method: "get",
        };

        const response = yield request.getFile(`${window.location.origin}/api/filestore/edocuments/source/${data}`, myInit);
        response.name = response.headers.get("Content-Disposition").split("filename=")[1];

        const blob = yield response.blob();

        blob.name = fileName || response.name;

        yield put({type: GET_DOCUMENT_CARD_SUCCESS, payload: blob});

        if (meta) {
            yield call(meta, null);
        }
    } catch (error) {
        console.error("Method: getDocumentSaga: ", error);
    }
};

export const getFileLinkSaga = function* ({payload}) {
    try {
        const {
            downloadLink,
            onSuccess,
            onError,
        } = payload;

        const myInit = {
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
            },
        };

        const response = yield fetch(downloadLink, myInit);

        if (response.status === 404) {
            onError && onError();
            toastError(DOCUMENTS_MESSAGE.ERROR_404);

            return {
                done: true,
            };
        }

        // обработка ошибки
        if (response.status === 200 && response.headers.get("Content-Type") && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                onError && onError();

                if (errorMessage) {
                    toastError(errorMessage);
                } else {
                    toastError(data);
                }

                return {
                    done: true,
                };
            }
        }
        const data = yield response.text();
        yield onSuccess(data);

    } catch (error) {
        payload.onError && payload.onError();
        console.error(error.message);

        yield put({type: DOCUMENT_DOWNLOAD_ERROR, payload: error});
    }
};

export const downloadDocumentSaga = function* ({payload}) {
    try {
        const {
            downloadLink,
            documentType,
            isDownload = true,
            extension = "pdf",
            fileName,
            onSuccess,
            onError,
            getResultFile,
            isShowFileNotFoundErrorMessage = true,
        } = payload;

        const myInit = {
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
            },
        };

        const response = yield fetch(downloadLink, myInit);

        if (response.status === 404) {
            onError && onError();
            toastError(DOCUMENTS_MESSAGE.ERROR_404);

            return {
                done: true,
            };
        }

        // обработка ошибки
        if (response.status === 200 && response.headers.get("Content-Type") && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage, errorCode} = data;
                const isFileNotFoundError = errorCode === "FILE_NOT_FOUND";
                const isShowErrorMessage = isFileNotFoundError ? isShowFileNotFoundErrorMessage : true;

                onError && onError();

                if (errorMessage) {
                    isShowErrorMessage && toastError(errorMessage);
                } else {
                    toastError(data);
                }

                return {
                    done: true,
                };
            }
        }

        onSuccess && onSuccess();

        const contentLength = +response.headers.get("Content-Length");
        const fileType = response.headers.get("Content-type");

        let toastId = undefined;
        if (isDownload) {
            toastId = toastWarning("Идет скачивание файла...", false);
        }

        const blob = yield getBlobInParts(response.body, contentLength, toastId, fileType);

        if (getResultFile) {
            getResultFile(blobToFile(blob, fileName));

            return;
        }

        const state = yield select();
        const documentTypeDict = getDocumentTypeDictsSelector(state);

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        if (isDownload && fileName) {
            tempLink.setAttribute("download", fileName);
        } else if (isDownload) {
            let _fileName = yield response.headers.get("Content-Disposition").split("filename=")[1];
            const formatFileName = `${documentTypeDict[documentType] ? documentTypeDict[documentType] : documentType}.${extension}`;
            _fileName = _fileName || formatFileName;
            tempLink.setAttribute("download", _fileName);
        } else {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();
        yield put({type: DOCUMENT_DOWNLOAD_SUCCESS});

    } catch (error) {
        payload.onError && payload.onError();
        console.error(error.message);

        yield put({type: DOCUMENT_DOWNLOAD_ERROR, payload: error});
    }
};

//GET /api/documents/dicts/userInstructionType
export const getUserInstructionTypeDictsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/userInstructionType`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: USER_INSTRUCTION_TYPE_DICTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: USER_INSTRUCTION_TYPE_DICTS_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: USER_INSTRUCTION_TYPE_DICTS_LIST_ERROR, payload: error});
    }
};

//POST /api/documents/getDepositReplenishmentAccountBasisFile
export const getDepositReplenishmentAccountBasisFileSaga = function* (action) {
    try {
        const {payload} = action;

        const myInit = {
            method: "POST",
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(payload),
        };

        const result = yield fetch(`${new URL(window.location.href).origin}/api${controller}/getDepositReplenishmentAccountBasisFile`, myInit);

        if (result.status === 200 && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);
                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        const state = yield select();
        const client = getClientCardSelector(state);
        const {name} = client;

        const fileName = `Счет-основание на пополнение депозита_${name}_${result.headers.get("Content-Disposition").split("filename=")[1]}`;
        tempLink.setAttribute("download", fileName);

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

        yield put({type: GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_ERROR, payload: error});
    }
};

//POST /api/documents/signaturelog/getPage
export const getPageDocumentsSignatureLogSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                handleResponse,
            },
        } = action;

        const result = yield request.post(`${controller}/signaturelog/getPage`, data);
        const {errorMessage} = result;

        handleResponse(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_DOCUMENTS_SIGNATURE_LOG_LIST_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_DOCUMENTS_SIGNATURE_LOG_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_DOCUMENTS_SIGNATURE_LOG_LIST_ERROR, payload: error});
    }
};

//POST /api/documents/updateWorkNotificationArchived
export const updateWorkNotificationsArchivedSaga = function* ({payload}) {
    try {
        const {actuality} = payload;

        const result = yield request.bff.post("/adm/companies/company/documents/registry/actuality/update", payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess(actuality ? "Уведомления успешно восстановлены из архива" : "Уведомления успешно заархивированы");

        const state = yield select();
        yield put(getDocumentList(documentPageDataSelector(state)));

        yield put({type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR, payload: error});
    }
};

//POST /api/documents/recreateStartWorkNotification
export const recreateStartWorkNotificationSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/recreateStartWorkNotification`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: RECREATE_START_DOCUMENT_NOTIFICATION_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Уведомление ОВМ успешно изменено");

        const state = yield select();
        yield put(getDocumentList(documentPageDataSelector(state)));

        yield put({type: RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: RECREATE_START_DOCUMENT_NOTIFICATION_ERROR, payload: error});
    }
};

//POST /api/documents/recreateEndWorkNotification
export const recreateEndWorkNotificationSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/recreateEndWorkNotification`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: RECREATE_END_DOCUMENT_NOTIFICATION_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Уведомление ОВМ успешно изменено");

        const state = yield select();
        yield put(getDocumentList(documentPageDataSelector(state)));

        yield put({type: RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: RECREATE_END_DOCUMENT_NOTIFICATION_ERROR, payload: error});
    }
};

// POST /api/documents/contractor/certificates/add
export const addContractorCertificateSaga = function* ({payload}) {
    const {
        onSuccess,
        onError,
    } = payload;

    try {
        const {
            file: data,
            certificateName,
            issueDate,
            contractorId,
        } = payload;

        const params = {
            certificateName,
            issueDate,
            contractorId,
        };

        const result = yield request.post(`${controller}/contractor/certificates/add`, data, {params, ...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);
            onError();

            yield put({type: CONTRACTOR_CERTIFICATES_ADD_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Сертификат успешно добавлен");
        onSuccess();

        yield put({type: CONTRACTOR_CERTIFICATES_ADD_SUCCESS, payload: result});

    } catch (error) {
        onError();

        toastError(error.message);

        yield put({type: CONTRACTOR_CERTIFICATES_ADD_ERROR, payload: error});
    }
};

// PATCH /api/documents/contractor/certificates/{id}/update
export const editContractorCertificateSaga = function* ({payload}) {
    const {
        onSuccess,
        onError,
    } = payload;

    try {
        const {
            certificateId,
            certificateName,
            issueDate,
            file: data,
            contractorId,
        } = payload;

        const params = {
            certificateName,
            issueDate,
            contractorId,
        };

        const result = yield request.patch(`${controller}/contractor/certificates/${certificateId}/update`, data, {params, ...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);
            onError();

            yield put({type: CONTRACTOR_CERTIFICATES_EDIT_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Сертификат успешно изменён");

        onSuccess();

        yield put({type: CONTRACTOR_CERTIFICATES_EDIT_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        onError();

        yield put({type: CONTRACTOR_CERTIFICATES_EDIT_ERROR, payload: error});
    }
};

// DELETE /api/documents/contractor/certificates/{id}/delete
export const deleteContractorCertificateSaga = function* ({payload}) {
    const {
        onSuccess,
        onError,
    } = payload;

    try {
        const {
            certificateId,
        } = payload;

        const result = yield request.delete(`${controller}/contractor/certificates/${certificateId}/delete`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            onError();

            yield put({type: CONTRACTOR_CERTIFICATES_DELETE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Сертификат успешно удалён");

        onSuccess();

        yield put({type: CONTRACTOR_CERTIFICATES_DELETE_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        onError();

        yield put({type: CONTRACTOR_CERTIFICATES_DELETE_ERROR, payload: error});
    }
};

// POST /api/documents/exemplary/getPage
export const exemplarySaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const {
            exemplaryDocuments,
            errorMessage,
        } = yield request.post(`${controller}/exemplary/getPage`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: EXEMPLARY_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (payload.actual) {
            yield put({type: EXEMPLARY_ACTUAL_SUCCESS, payload: exemplaryDocuments});
        } else {
            yield put({type: EXEMPLARY_ARCHIVE_SUCCESS, payload: exemplaryDocuments});
        }

    } catch (error) {
        toastError(error);

        yield put({type: EXEMPLARY_ERROR, payload: error});
    }
};

// POST /api/documents/registry/framecontract/add
export const addRegistryFrameContractSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/registry/framecontract/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_REGISTRY_FRAME_CONTRACT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: ADD_REGISTRY_FRAME_CONTRACT_SUCCESS, payload: result});

        onSuccess && onSuccess();
    } catch (error) {
        yield put({type: ADD_REGISTRY_FRAME_CONTRACT_ERROR, payload: error});
    }
};

// POST /api/documents/registry/framecontract/update
export const updateRegistryFrameContractSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/registry/framecontract/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS, payload: result});

        onSuccess && onSuccess();
    } catch (error) {
        yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_ERROR, payload: error});
    }
};

// POST /api/documents/registry/framecontract/items/add
export const addRegistryFrameContractItemsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/registry/framecontract/items/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }
        yield put({type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS, payload: result});

        onSuccess && onSuccess();
        toastSuccess("Исполнитель успешно добавлен в реестр");
    } catch (error) {
        yield put({type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR, payload: error});
    }
};

// POST /api/documents/registry/framecontract/items/update
export const updateRegistryFrameContractItemsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/registry/framecontract/items/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS, payload: result});

        onSuccess && onSuccess();

        toastSuccess("Запись реестра успешна изменена");
    } catch (error) {
        yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR, payload: error});
    }
};

// POST /api/documents/registry/framecontract/items/short/getPage
export const getRegistryFramecontractShortItemsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/registry/framecontract/items/short/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_ERROR, payload: error});
    }
};

//GET /bff/adm/contractors-registry/contractor/documents/identification-sheet/getPage
export const getContractorIdentificationSheetSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get("/adm/contractors-registry/contractor/documents/identification-sheet/getPage", {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_CONTRACTOR_IDENTIFICATION_SHEET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_IDENTIFICATION_SHEET_ERROR, payload: error});
    }
};

//GET /api/documents/getContractorPageCounts
export const getContractorDocumentListCountsSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getContractorPageCounts`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENT_CONTRACTOR_LIST_COUNTS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENT_CONTRACTOR_LIST_COUNTS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENT_CONTRACTOR_LIST_COUNTS_ERROR, payload: error});
    }
};

//GET /api/documents/dicts/documentType
export const getExportDocumentTypeDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/export/documentType`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EXPORT_DOCUMENT_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: EXPORT_DOCUMENT_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: EXPORT_DOCUMENT_TYPE_DICT_ERROR, payload: error});
    }
};

//GET /bff/adm/contractor/{contractorId}/pending-contracts/getPage
// Получение списка документов, ожидающих подписания
export const getContractorPendingContractsListSaga = function* ({payload}) {
    try {
        const {
            contractorId,
        } = payload;

        const result = yield request.bff.get(`adm/contractor/${contractorId}/pending-contracts/getPage`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PENDING_CONTRACTS_CONTRACTOR_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PENDING_CONTRACTS_CONTRACTOR_LIST_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENT_LIST_REQUEST, getDocumentListSaga),
        takeEvery(DOCUMENT_TYPE_DICTS_REQUEST, getDocumentTypeDictsSaga),
        takeEvery(DOCUMENT_DOWNLOAD_REQUEST, downloadDocumentSaga),
        takeEvery(GET_DOCUMENT_CARD_REQUEST, getDocumentSaga),
        takeEvery(DOCUMENT_CONTRACTOR_REFRESH_REQUEST, refreshContractorDocumentsSaga),
        takeEvery(DOCUMENT_CONTRACTOR_LIST_REQUEST, getContractorDocumentListSaga),
        takeEvery(DOCUMENT_DICT_STATUS_REQUEST, getDocumentStatusDictSaga),
        takeEvery(DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST, isFrameContractSignedSaga),
        takeEvery(USER_INSTRUCTION_TYPE_DICTS_LIST_REQUEST, getUserInstructionTypeDictsSaga),
        takeEvery(OPERATOR_REPORT_STATUS_REQUEST, getOperatorReportStatusListSaga),
        takeEvery(GET_DEPOSIT_REPLENISHMENT_ACCOUNT_BASIS_FILE_REQUEST, getDepositReplenishmentAccountBasisFileSaga),
        takeEvery(GET_DOCUMENTS_SIGNATURE_LOG_LIST_REQUEST, getPageDocumentsSignatureLogSaga),
        takeEvery(DOCUMENT_TYPE_SIGNATURE_LOG_DICTS_REQUEST, getDocumentTypeSignatureLogDictsSaga),
        takeEvery(UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST, updateWorkNotificationsArchivedSaga),
        takeEvery(RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST, recreateStartWorkNotificationSaga),
        takeEvery(RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST, recreateEndWorkNotificationSaga),
        takeEvery(CONTRACTOR_CERTIFICATES_ADD_REQUEST, addContractorCertificateSaga),
        takeEvery(CONTRACTOR_CERTIFICATES_EDIT_REQUEST, editContractorCertificateSaga),
        takeEvery(CONTRACTOR_CERTIFICATES_DELETE_REQUEST, deleteContractorCertificateSaga),
        takeEvery(EXEMPLARY_REQUEST, exemplarySaga),
        takeEvery(ADD_REGISTRY_FRAME_CONTRACT_REQUEST, addRegistryFrameContractSaga),
        takeEvery(UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST, updateRegistryFrameContractSaga),
        takeEvery(ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST, addRegistryFrameContractItemsSaga),
        takeEvery(UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST, updateRegistryFrameContractItemsSaga),
        takeEvery(GET_REGISTRY_FRAMECONTRACT_SHORT_ITEMS_REQUEST, getRegistryFramecontractShortItemsSaga),
        takeEvery(GET_FILE_LINK_REQUEST, getFileLinkSaga),
        takeEvery(GET_CONTRACTOR_IDENTIFICATION_SHEET_REQUEST, getContractorIdentificationSheetSaga),
        takeEvery(DOCUMENT_CONTRACTOR_LIST_COUNTS_REQUEST, getContractorDocumentListCountsSaga),
        takeEvery(EXPORT_DOCUMENT_TYPE_DICT_REQUEST, getExportDocumentTypeDictSaga),
        takeEvery(PENDING_CONTRACTS_CONTRACTOR_LIST_REQUEST, getContractorPendingContractsListSaga),
    ]);
}
