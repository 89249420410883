import React from "react";
import JSONPretty from "react-json-pretty";

import bem from "../../utils/bem";

import "./style.sass";

const CustomJsonPretty = (props) => {
    const {
        data,
        className = "",
    } = props;

    const [block] = bem("custom-json-pretty", className);

    return (
        <JSONPretty
            themeClassName={block()}
            data={data}
        />
    );
};

export default CustomJsonPretty;