import React from "react";
import {useMedia} from "react-media";
import {useSelector} from "react-redux";
import {cloneDeep, isEmpty} from "lodash";

import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import BankIndicator from "../../../../components/BankIndicator";
import ContractorNoteForClientTooltip from "../../../../components/ContractorNoteForClientTooltip";
import ContractorPassportStatus from "../../../../components/ContractorPassportStatus";
import ExtLink from "../../../../components/ExtLink";
import LocatedOutsideRussiaTooltip from "../../../../components/LocatedOutsideRussiaTooltip";
import NmCheck from "../../../../components/NmCheck";
import NmIcon from "../../../../components/NmIcon";
import NmReadonlyRating from "../../../../components/NmReadonlyRating";
import NmSelectedList from "../../../../components/NmSelectedList";
import SelfEmployedIndicator from "../../../../components/SelfEmployedIndicator";
import {ReactComponent as ArchiveIcon} from "../../../../images/archive_24.svg";
import {ReactComponent as BlockedIcon} from "../../../../images/lock_24.svg";

import bem from "../../../../utils/bem";
import {getWorkAddressInfoArr} from "../../../../utils/dadata";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {pluralizeYears} from "../../../../utils/pluralize";
import pluralize from "../../../../utils/pluralize";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {COLOR} from "../../../../constants/color";
import {
    CONTRACTOR_STATUS,
} from "../../../../constants/contractor";
import {CONTRACTOR_LEAD_TYPE_TRANSLATE} from "../../../../constants/contractorInfo";
import {LINK_CONTRACTOR_REVIEWS_LIST} from "../../../../constants/links";
import {isUserFromNm} from "../../../../constants/roles";

import {migrationStatusDictSelector} from "../../../../ducks/contractor";

import "./style.sass";

const ContractorListInfo = (props) => {
    const {
        contractor,
        role,
        renderLinkFnc,
        t,
        contractorsPossibility,
        citizenshipList,
        specialityOptions,
        specialityDict,
        contractorStatusDict,
        classNamePrimaryHeader = "",
        classNameIndicators = "",
        classNameStatuses = "",
        educationLevelDictOptions,
        actions,
        showPaymentInfo = true,
        isMapPage = false,
        isMapListPage = false,
        isInviteModal = false,
        openFilter = false,
        isShowLeadTypeLabel = false,
        otherContent,
    } = props;

    const {
        remoteWork,
        overallRating,
        registrationStatus,
        age,
        citizenship,
        migrationStatus,
        mainSpecialityId,
        additionalSpecialityIds = [],
        workAddressInfo,
        educationLevels,
        reviewCount,
        contractorId,
        locatedOutsideRussia,
        leadTypes = [],
        online,
        lastLoginDate,
        workAddressFias,
    } = contractor;

    const migrationStatusDict = useSelector(migrationStatusDictSelector);

    const [block, element] = bem("contractor-list-info");
    const isMobile = useMedia({query: {maxWidth: 767}});

    const isShowPersonalData = ({status}) => {
        return isUserFromNm(role) || ![CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status);
    };

    const localizationData = {
        citizenship: t("contractor-list.citizenship"),
        invited: t("contractor-list.invited"),
        online: t("contractor-list.online"),
        specialtiesLabel: t("contractor-list.specialties"),
        FNS: t("contractor-list.FNS"),
        isCheckPassport: t("contractor-list.check-passport"),
        completedOrders: t("contractor-list.completed-orders"),
        completedOrdersHelp: t("contractor-list.completed-orders-help"),
        lastLoginDate: t("contractor-list.last-login-date-text"),
        firstRegistration: t("contractor-list.first-registration"),
        fullRegistration: t("contractor-list.full-registration"),
        maxOrderDate: t("contractor-list.max-order-date"),
        age: t("contractor-list.age"),
    };

    const renderBlockedInfo = ({blocked, blockedReason}) => {
        return (
            blocked && <NmAdvancedTooltip
                className="d-flex align-items-center"
                trigger={
                    <BlockedIcon
                        width={24}
                        height={24}
                        color="red"
                    />
                }
                hover
            >
                {blockedReason}
            </NmAdvancedTooltip>
        );
    };

    const renderNote = () => {
        return (
            <ContractorNoteForClientTooltip
                note={contractor.noteForClient}
            />
        );
    };

    const renderArchiveInfo = ({archived}) => {
        return (
            archived && <NmAdvancedTooltip
                className="d-flex align-items-center"
                trigger={
                    <ArchiveIcon
                        width={24}
                        height={24}
                    />
                }
                hover
            >
                Архивная учетная запись
            </NmAdvancedTooltip>
        );
    };

    const renderBlackListInfo = ({blacklist, blacklistReason}) => {
        return (
            blacklist && <NmAdvancedTooltip
                className="d-flex align-items-center"
                trigger={
                    <NmIcon name="blacklisted" />
                }
                hover={true}
            >
                {blacklistReason}
            </NmAdvancedTooltip>
        );
    };

    const renderFnsCheckInfo = ({taxStatus, citizenship}) => {
        if ([citizenshipsDict.UZ.value, citizenshipsDict.TJ.value].includes(citizenship)) {
            return null;
        }

        return (
            <div className="mb-1">
                <SelfEmployedIndicator
                    taxStatus={taxStatus}
                />
            </div>
        );
    };

    const renderPassportCheckInfo = () => {
        return (
            <ContractorPassportStatus
                className="contractor-list-info__check mb-1"
                contractor={contractor}
                textLevel="2"
            />
        );
    };

    const renderOrderCompletedCount = ({orderCompletedCount, currentClientOrderCompletedCount}) => {
        if ((orderCompletedCount || currentClientOrderCompletedCount)) {
            return (
                <NmAdvancedTooltip
                    className="contractor-list-info__check mb-1"
                    position="bottom-left"
                    children={localizationData.completedOrdersHelp}
                    trigger={
                        <NmCheck
                            isCheck={true}
                            label={`${localizationData.completedOrders} ${orderCompletedCount || 0}/${currentClientOrderCompletedCount || 0}`}
                        />
                    }
                    hover
                />
            );
        }
        return null;
    };

    const getRating = () => {
        return (
            <NmReadonlyRating
                rating={overallRating || 0}
                maxRating={1}
                isMiniRatingInfo
            />
        );
    };

    const renderFeedbackInfo = () => {
        if (!reviewCount) {
            return null;
        }
        return (
            <div>
                <ExtLink
                    to={LINK_CONTRACTOR_REVIEWS_LIST.replace(":contractorId", contractorId)}
                >
                    {pluralize(reviewCount, ["отзыв", "отзыва", "отзывов"], true)}
                </ExtLink>
            </div>
        );
    };

    const getAllStatuses = () => {
        return (
            <div className={`contractor-list-info__indicators ${classNameIndicators}`}>
                <NmLabelText
                    alignItems="center"
                    className="mb-1"
                    label="Общий рейтинг"
                    text={getRating()}
                />
                <div className="flex flex-aligned-center me-2 mb-1">
                    {renderFeedbackInfo()}
                </div>
                <div className={`flex flex-flow-wrap ${classNameStatuses}`}>
                    {renderPassportCheckInfo(contractor)}
                    {
                        showPaymentInfo &&
                        <BankIndicator
                            hideLoaderContent
                            level="2"
                            size="lg"
                            contractorsPossibility={contractorsPossibility}
                            contractorId={contractor.contractorId}
                            showLabel
                            className="me-2 mb-1"
                        />
                    }
                    {renderFnsCheckInfo(contractor)}
                    {renderOrderCompletedCount(contractor)}
                </div>
            </div>
        );
    };

    const renderLastLogin = () => {
        return (
            online ?
                <Text color={COLOR.PRIMARY_100}>
                    Онлайн
                </Text> :
                formatLocalDate(lastLoginDate, "dd.MM.yyyy HH:mm")
        );
    };
    const getLabels = () => {
        if (isMapPage || isMapListPage) {
            return [
                (isMapListPage || isInviteModal) &&
                {
                    label: "Дата последнего визита в приложение",
                    text: renderLastLogin(),
                },
                {
                    label: "Гражданство",
                    text: citizenshipList[citizenship],
                },
                {
                    label: "Основной вид деятельности",
                    text: specialityDict.get(mainSpecialityId),
                },
                Boolean(additionalSpecialityIds?.length) && {
                    label: "Дополнительные виды деятельности",
                    text: <NmSelectedList
                        classNameContent="col-7Z"
                        showedItemsCount={4}
                        modalTitle="Дополнительные виды деятельности"
                        isShowDetailModal={true}
                        showListWithoutValue={true}
                        list={additionalSpecialityIds.map(item => ({text: specialityDict.get(item)}))}
                        isLimiterComma={true}
                    />,
                    columnOnMobile: true,
                    flexWrap: true,
                    noWrap: false,
                },
                {
                    label: "Город ведения деятельности",
                    text: workAddressFias?.value || "-",
                },
            ];
        }

        const labels = [];

        const workAddressArr = cloneDeep(getWorkAddressInfoArr(workAddressInfo, [])) || [];

        if (remoteWork && !isEmpty(workAddressArr)) {
            workAddressArr[0].remoteWork = remoteWork;
        }

        additionalSpecialityIds.length && labels.push({
            text: <NmSelectedList
                showedItemsCount={isMobile ? 1 : 5}
                values={additionalSpecialityIds}
                list={specialityOptions}
                dropdownWidth={250}
                classNameContent="contractor-list-info__speciality-content"
                classNameItemContainer="contractor-list-info__speciality-item-container"
                itemClassName="contractor-list-info__selected-list-item"
                classNameContentFull="contractor-list-info__speciality-content_full"
                className="contractor-list-info__all-spec"
            />,
            noWrap: true,
            columnOnMobile: false,
        });

        labels.push({
            label: "Статус",
            text: contractorStatusDict[registrationStatus],
            noWrap: false,
            inline: true,
            columnOnMobile: false,
        });

        isShowLeadTypeLabel && labels.push({
            label: "Тип лида",
            text: leadTypes?.length ?
                leadTypes.map(item => CONTRACTOR_LEAD_TYPE_TRANSLATE[item] || item).join(", ") :
                "-",
        });

        isShowPersonalData(contractor) && !isNullOrWhitespace(age) && age !== 0 && labels.push({
            label: "Возраст",
            text: pluralizeYears(age),
            noWrap: true,
            columnOnMobile: false,
        });

        isShowPersonalData(contractor) && !isNullOrWhitespace(citizenship) && labels.push({
            label: "Гражданство",
            text: citizenshipList[citizenship],
            noWrap: true,
            columnOnMobile: false,
            classNameText: "flex align-items-center",
            textOverflowUnset: true,
            textTooltip:
                locatedOutsideRussia &&
                <LocatedOutsideRussiaTooltip />,
        });

        isShowPersonalData(contractor) && !isNullOrWhitespace(migrationStatus) &&
        ![
            citizenshipsDict.RU.value,
            citizenshipsDict.DN.value,
            citizenshipsDict.LG.value,
        ].includes(citizenship) &&
        labels.push({
            label: "Миграционный статус",
            text: migrationStatusDict[migrationStatus],
            noWrap: true,
            columnOnMobile: true,
        });

        labels.push({
            text: <NmSelectedList
                showedItemsCount={1}
                showListWithoutValue
                list={workAddressArr}
                label="Город ведения деятельности"
                dropdownWidth={250}
            />,
            noWrap: false,
            columnOnMobile: false,
        });

        educationLevels && educationLevels.length && labels.push({
            text: <NmSelectedList
                showedItemsCount={3}
                values={educationLevels}
                list={educationLevelDictOptions}
                label="Образование"
                dropdownWidth={250}
            />,
            noWrap: false,
            columnOnMobile: false,
        });

        labels.push({
            text: getAllStatuses(),
            noWrap: false,
            columnOnMobile: false,
            className: "flex-aligned-center text-no-wrap",
        });

        return labels;
    };

    const renderPrimaryHeader = () => {
        const {
            blocked,
            archived,
            blacklist,
            noteForClient,
        } = contractor;

        const className = specialityDict.get(mainSpecialityId) && !isInviteModal ?
            "contractor-list-info__pr-header_speciality" :
            "";

        return (
            <div className={`contractor-list-info__pr-header ${className}`}>
                {
                    (isMapPage && !isInviteModal) &&
                    <div className="contractor-list-info__pr-header-spec">
                        {specialityDict.get(mainSpecialityId)}
                    </div>
                }
                {
                    (!isMapPage && !isMapListPage) &&
                    <div className="contractor-list-info__grid-container">
                        <div
                            title={specialityDict.get(mainSpecialityId)}
                            className="contractor-list-info__main-speciality contractor-list-info__main-speciality_mobile"
                        >
                            {specialityDict.get(mainSpecialityId)}
                        </div>
                    </div>
                }
                <div className="contractor-list-info__pr-header-container">
                    <div className={element(isInviteModal ? "name" : "grid-container")}>
                        {renderLinkFnc(contractor)}
                    </div>
                    {(blocked || archived || blacklist || noteForClient) && <div className="flex ms-2">
                        {renderNote()}
                        {renderBlockedInfo(contractor)}
                        {renderArchiveInfo(contractor)}
                        {renderBlackListInfo(contractor)}
                    </div>}
                </div>
                {
                    (!isMapPage && !isMapListPage) &&
                    <div className="contractor-list-info__main-speciality">
                        {
                            specialityDict.get(mainSpecialityId) &&
                            <div className="contractor-list-info__delimiter" />
                        }
                        <div className={element("pr-header-spec", {openFilter})}>
                            {specialityDict.get(mainSpecialityId)}
                        </div>
                    </div>
                }
            </div>
        );
    };

    return (
        <div className="contractor-list-info">
            <NmListCard
                avatar
                checkbox
                isShowCheckboxLabel
                classNamePrimaryHeader={`contractor-list-info__primary-header ${classNamePrimaryHeader}`}
                classNamePrimaryHeaderValue="contractor-list-info__primary-header-value"
                alignItems="flex-start"
                isFixedActions
                noDivider
                secondaryHeader={
                    (isMapPage && !isInviteModal) &&
                    <NmLabelText
                        type="list"
                        label="Дата последнего визита"
                        text={renderLastLogin()}
                    />
                }
                primaryHeader={renderPrimaryHeader()}
                mediaControls={actions}
                cards={[]}
                labels={getLabels()}
                actionsClassName="col-1 flex-aligned-end flex-content-end"
                otherContent={otherContent}
            />
        </div>
    );
};

export default ContractorListInfo;