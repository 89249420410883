import React from "react";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2Content from "../../../components/ActualComponents/NmConfirmV2/Content";
import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import ExtLink from "../../../components/ExtLink";
import FullRegistration from "../../../components/FullRegistration";

import {FULL_REGISTRATION_COMPONENT_TYPE} from "../../../components/FullRegistration/constants";

import "./style.sass";

export const FULL_REGISTRATION_COMPONENT_MAP = {
    [FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE]: DropzoneV2,
    [FULL_REGISTRATION_COMPONENT_TYPE.INPUT]: NmInputV2,
    [FULL_REGISTRATION_COMPONENT_TYPE.WARNING_TEXT]: NmConfirmV2Content,
    [FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN]: NmDropdownV2,
    [FULL_REGISTRATION_COMPONENT_TYPE.DADATA_INPUT]: NmDadataInput,
    [FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX]: NmCheckboxV2,
    [FULL_REGISTRATION_COMPONENT_TYPE.DATE]: NmDatePicker,
    [FULL_REGISTRATION_COMPONENT_TYPE.LINK]: ExtLink,
};

const ContractorFullRegistration = (props) => {
    const {
        contractorId,
        onClose,
        step,
    } = props;

    return (
        <FullRegistration
            contractorId={contractorId}
            componentMap={FULL_REGISTRATION_COMPONENT_MAP}
            step={step}
            onClose={onClose}
            classNameModalBackIcon="contractor-full-registration__modal-back-icon"
            disclaimer={{
                className: "contractor-full-registration__disclaimer",
                classNameRequiredStar: "contractor-full-registration__disclaimer-required-star",
            }}
        />
    );
};

export default ContractorFullRegistration;