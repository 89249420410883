import {useState} from "react";

import {clearSpace} from "../../../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../../../utils/stringHelper";

export function useClientsFilter() {
    const [filterDto, setFilter] = useState({});

    function mapFilterDto(filter) {
        const {
            clientNameFilter,
            innFilter,
        } = filter;

        return {
            clientNameFilter: handleFormString(clientNameFilter),
            innFilter: isNullOrWhitespace(innFilter) ? null : clearSpace(innFilter),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
    };
}