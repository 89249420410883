import React from "react";
import {Route, Switch} from "react-router-dom";

import Instructions from "../../../components/Instructions";
import {InstructionsNaimix} from "../../../components/Instructions/components/naimix";
import {InstructionsKedo} from "./kedo";

import {ls, USER_ROLE} from "../../../utils/localstorage";

import {
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
} from "../../../constants/links";
import {ADMIN, NM_MANAGER} from "../../../constants/roles";

export const SettingsInstructions = () => {
    const role = ls(USER_ROLE);

    const tabs =  [
        {
            name: "Наймикс",
            link: LINK_SETTINGS_INSTRUCTION,
        },
        {
            name: "КЭДО",
            link: LINK_SETTINGS_INSTRUCTION_KEDO,
        },
    ];

    return (
        <Instructions
            noPadding={true}
            tabs={tabs}
            isVisibleTabs={[ADMIN, NM_MANAGER].includes(role)}
            pages={
                <Switch>
                    <Route
                        exact
                        path={LINK_SETTINGS_INSTRUCTION}
                        component={InstructionsNaimix}
                    />
                    <Route
                        path={LINK_SETTINGS_INSTRUCTION_KEDO}
                        component={InstructionsKedo}
                    />
                </Switch>
            }
        />
    );
};