import * as yup from "yup";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        medicalBookNumber: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .min(7, "Минимальная длина строки 7 символов")
            .max(8, "Допустимое количество символов 8"),
        files: yup.object().shape({
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });
};

export default validationSchema;