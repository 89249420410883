import {getKedoLogsIntegration1cPage} from "../../../../kedo/logs/actionCreators";

const getController = (clientId) => {
    return `/adm/clients/${clientId}/kedo/logs`;
};

export const getClientsKedoLogsIntegration1cPage = (payload) => {
    const {
        clientId,
        ...otherData
    } = payload;

    return getKedoLogsIntegration1cPage({
        url: `${getController(clientId)}/integration1c/page`,
        ...otherData,
    });
};