export const BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST = "BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_PAYMENTS_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_PAYMENTS_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR = "BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR";

export const BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST = "BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST";
export const BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_SUCCESS = "BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_SUCCESS";
export const BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR = "BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_OTHER_REQUEST = "BFF_CLIENTS_GET_SETTINGS_OTHER_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_OTHER_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_OTHER_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_OTHER_ERROR = "BFF_CLIENTS_GET_SETTINGS_OTHER_ERROR";

export const BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST = "BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST";
export const BFF_CLIENTS_PUT_SETTINGS_OTHER_SUCCESS = "BFF_CLIENTS_PUT_SETTINGS_OTHER_SUCCESS";
export const BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR = "BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_API_REQUEST = "BFF_CLIENTS_GET_SETTINGS_API_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_API_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_API_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_API_ERROR = "BFF_CLIENTS_GET_SETTINGS_API_ERROR";

export const BFF_CLIENTS_PUT_SETTINGS_API_REQUEST = "BFF_CLIENTS_PUT_SETTINGS_API_REQUEST";
export const BFF_CLIENTS_PUT_SETTINGS_API_SUCCESS = "BFF_CLIENTS_PUT_SETTINGS_API_SUCCESS";
export const BFF_CLIENTS_PUT_SETTINGS_API_ERROR = "BFF_CLIENTS_PUT_SETTINGS_API_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_REQUEST = "BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_ERROR = "BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_ERROR";

export const BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST = "BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST";
export const BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_SUCCESS = "BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_SUCCESS";
export const BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR = "BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_REQUEST = "BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_ERROR = "BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_ERROR";

export const BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST = "BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST";
export const BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_SUCCESS = "BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_SUCCESS";
export const BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_ERROR = "BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_REQUEST = "BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_ERROR = "BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_ERROR";

export const BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST = "BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST";
export const BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_SUCCESS = "BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_SUCCESS";
export const BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR = "BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR";

export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_SUCCESS = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_SUCCESS";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR";

export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_SUCCESS = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_SUCCESS";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR";

export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_SUCCESS = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_SUCCESS";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR";

export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_SUCCESS = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_SUCCESS";
export const BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR = "BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST = "BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_LOGS_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_LOGS_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR = "BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST = "BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR = "BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST = "BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR = "BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST = "BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_TREE_LIST_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_TREE_LIST_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR = "BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR";

export const BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST = "BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST";
export const BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_SUCCESS = "BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_SUCCESS";
export const BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR = "BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR";

export const BFF_CLIENTS_SETTINGS_UPDATE_STORE = "BFF_CLIENTS_SETTINGS_UPDATE_STORE";