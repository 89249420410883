import React from "react";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";

import {SimpleDropdownOptions} from "../../../../../../constants/contractorList";

import {
    EDO_DOCUMENTS_REGISTRIES_STATUS_ITEMS_OPTIONS,
    EDO_DOCUMENTS_REGISTRIES_STATUS_OPTIONS,
} from "../../../../../../constants/document-management/documents-registries";

export const EdoDocumentsRegistriesCardFilter = (props) => {
    const {
        filter: {
            seqNum = "",
            receiverFio = "",
            receiverPhone = "",
            documentName = "",
            documentDateFrom,
            documentDateTo,
            contractorRegistered,
            receiverInStaff,
            receiverWorkInStaff,
            canBeReceiverInStaff,
            status,
        },
        onChange,
        onSearch,
        onClear,
    } = props;

    return (
        <NmForm
            addMargin
        >
            <NmInputV2
                size="lg"
                label="Номер строки"
                placeholder="Введите номер строки"
                name="seqNum"
                value={seqNum}
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                label="ФИО исполнителя"
                placeholder="Введите ФИО исполнителя"
                name="receiverFio"
                value={receiverFio}
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                mask="+7 (999) 999 99 99"
                maskChar={null}
                label="Телефон исполнителя"
                placeholder="Введите телефон исполнителя"
                name="receiverPhone"
                value={receiverPhone}
                onChange={onChange}
            />
            <NmDropdownV2
                name="contractorRegistered"
                placeholder="Все"
                emptyOptionText="Все"
                options={SimpleDropdownOptions}
                onChange={onChange}
                value={contractorRegistered}
                label="Зарегистрирован на площадке"
                size="lg"
            />
            <NmInputV2
                size="lg"
                label="Наименование документа"
                placeholder="Введите наименование документа"
                name="documentName"
                value={documentName}
                onChange={onChange}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="documentDateFrom"
                endFieldName="documentDateTo"
                value={{documentDateFrom, documentDateTo}}
                label="Дата документа"
                onChange={onChange}
            />
            <NmDropdownV2
                placeholder="Все"
                emptyOptionText="Все"
                label="Статус подписания"
                name="status"
                onChange={onChange}
                value={status}
                options={EDO_DOCUMENTS_REGISTRIES_STATUS_ITEMS_OPTIONS}
                size="lg"
            />
            <NmDropdownV2
                name="receiverInStaff"
                placeholder="Все"
                emptyOptionText="Все"
                options={SimpleDropdownOptions}
                onChange={onChange}
                value={receiverInStaff}
                label="Добавлен в кадры компании"
                size="lg"
            />
            <NmDropdownV2
                name="receiverWorkInStaff"
                placeholder="Все"
                emptyOptionText="Все"
                options={SimpleDropdownOptions}
                onChange={onChange}
                value={receiverWorkInStaff}
                label="Статус в кадрах “Работает"
                size="lg"
            />
            <NmDropdownV2
                name="canBeReceiverInStaff"
                placeholder="Все"
                emptyOptionText="Все"
                options={SimpleDropdownOptions}
                onChange={onChange}
                value={canBeReceiverInStaff}
                label="Может быть выбран получателем документа в кадрах"
                size="lg"
            />
            <FilterButtonsV2
                onSearch={onSearch}
                onClear={onClear}
            />
        </NmForm>
    );
};