import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {
    getActRegistryEditPaymentInitialValues,
    getActRegistryEditPaymentRequestData,
} from "../utils/dto";
import {getValidation} from "../utils/getValidation";

import {
    actRegistryPaymentCardSelector,
    addActRegistryPayment,
    updateActRegistryPayment,
    updateActRegistryPaymentsStore,
} from "../../../../../../../ducks/actRegistryPayment";

export const useOrderActRegistryEditPaymentAction = (params) => {
    const {
        editData,
        onSetStep,
        prevStep,
        registryCard,
        fetchList,
        registryId,
        clientId,
        contractorId,
        onClose,
    } = params;
    const dispatch = useDispatch();

    const card = useSelector(actRegistryPaymentCardSelector);

    const initialValues = {
        ...getActRegistryEditPaymentInitialValues(card),
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: getValidation(registryCard),
        validateOnBlur: false,
    });

    useEffect(() => {
        return () => {
            dispatch(updateActRegistryPaymentsStore({card: {}}));
        };
    }, []);

    const onChange = (event, {name, value}) => setFieldValue(name, value);

    function onSubmit() {
        if (!isValid) {
            return;
        }

        const requestData = getActRegistryEditPaymentRequestData(values);

        if (!isEmpty(editData)) {
            dispatch(updateActRegistryPayment({
                ...card,
                ...requestData,
                contractorId,
                onSuccess: () => {
                    onClose();
                    fetchList();
                },
            }));

            return;
        }

        dispatch(addActRegistryPayment({
            ...requestData,
            contractorId,
            registryId,
            clientId,
            onSuccess: () => {
                onClose();
                fetchList();
            },
        }));
    }

    const onGoBack = () => {
        onSetStep(prevStep);
    };

    const estimates = [
        {
            rating: values.contractorWorkQualityScore,
            name: "contractorWorkQualityScore",
            label: "Оценка качества выполненных работ:",
            newError: touched.contractorWorkQualityScore && errors.contractorWorkQualityScore,
        },
        {
            rating: values.contractorEstimatedTimeScore,
            name: "contractorEstimatedTimeScore",
            label: "Оценка по срокам выполненных работ:",
            newError: touched.contractorEstimatedTimeScore && errors.contractorEstimatedTimeScore,
        },
    ];

    return {
        handleSubmit,
        values,
        errors,
        touched,
        estimates,
        onGoBack,
        onChange,
    };
};