import {useDispatch} from "react-redux";

import {
    addRecruitmentDirectoryJobRequirement,
    deleteRecruitmentDirectoryJobRequirement,
    updateRecruitmentDirectoryJobRequirement,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

import {toastSuccess} from "../../../../../utils/toastHelper";

const useRecruitmentDirectoriesJobRequirementsAction = ({clientId}) => {
    const dispatch = useDispatch();

    const onAdd = ({value, callBack = () => {}})=> {
        dispatch(addRecruitmentDirectoryJobRequirement({
            clientId,
            name: value,
            onSuccess: () => {
                toastSuccess("Доп. требование успешно добавлено");
                callBack();
            },
        }));
    };

    const onEdit = ({id, value, callBack = () => {}}) => {
        dispatch(updateRecruitmentDirectoryJobRequirement({
            id,
            name: value,
            onSuccess: () => {
                toastSuccess("Доп. требование успешно обновлено");
                callBack();
            },
        }));
    };

    const onDelete = ({id, callBack = () => {}}) => {
        dispatch(deleteRecruitmentDirectoryJobRequirement({
            id,
            onSuccess: () => {
                toastSuccess("Доп. требование успешно удалено");
                callBack();
            },
        }));
    };

    return {
        onAdd,
        onEdit,
        onDelete,
    };
};

export default useRecruitmentDirectoriesJobRequirementsAction;