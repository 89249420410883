import React, {useState} from "react";

import {CONTRACTOR_CHECK_PASSPORT_TEXT, CONTRACTOR_STATUS} from "../../../../../../constants/contractor";

export const useContractorPassportCheckingForm = ({contractor}) => {
    const [openEdit, setOpenEdit] = useState(false);

    const {
        registrationStatus,
        blocked,
        blockedReason,
    } = contractor;

    const isPassportSuccess = [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED].includes(registrationStatus) && !blocked;

    const passportSuccessText = isPassportSuccess ? "Исполнитель прошел проверку документов" : null;
    const passportErrorText = isPassportSuccess ? null : getPassportErrorText();

    function getPassportErrorText() {
        const statusError = CONTRACTOR_CHECK_PASSPORT_TEXT[registrationStatus];
        const blockedError = `Исполнитель заблокирован администратором Наймикс. Причина: ${blockedReason}`;

        if ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES, CONTRACTOR_STATUS.BASIC_REGISTRATION].includes(registrationStatus)) {
            return blocked ? <p>
                Проверка не пройдена по следующим причинам:
                <ul>
                    <li>
                        {statusError}
                    </li>
                    <li>
                        {blockedError}
                    </li>
                </ul>
            </p> : statusError;
        }

        if (blocked) {
            return blockedError;
        }

        return "";
    }

    return {
        isPassportSuccess,
        passportSuccessText,
        passportErrorText,
        setOpenEdit,
        openEdit,
    };
};