import {useFormik} from "formik";

import {formatObjectChangeDepositValues} from "../utils/dto";
import {getObjectChangeDepositValidation} from "../utils/getValidation";

import {OBJECT_CHANGE_DEPOSIT_RADIO_TYPE} from "../constants";

export const useObjectChangeDepositForm = (params) => {
    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
    } = useFormik({
        onSubmit,
        initialValues: {
            type: OBJECT_CHANGE_DEPOSIT_RADIO_TYPE.NPD,
            amount: "",
        },
        validateOnBlur: false,
        enableReinitialize: false,
        validationSchema: getObjectChangeDepositValidation(),
    });

    function onSubmit() {
        const requestData = formatObjectChangeDepositValues(values);

        params.onSubmit(requestData);
    }

    return {
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
    };
};