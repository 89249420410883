import React, {useCallback, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {MediaButtons} from "../../../../../components/ActualComponents/MediaControls";
import {NmPageCardHeader} from "../../../../../components/ActualComponents/NmPageCardHeader";
import {KedoStaffRegistryEdit} from "../edit";

import {useModal} from "../../../../../hooks/useModal";

import formatDate from "../../../../../utils/dateFormat";
import {getFullName} from "../../../../../utils/stringFormat";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_KEDO_MEMBERS} from "../../../../../constants/links";

import {
    getKedoStaffCommon,
    updateKedoStaffStore,
} from "../../../../../ducks/kedo/staff/actionCreators";
import {bffKedoStaffCommonCardSelector} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffCommonCard = (props) => {
    const {
        clientId,
        kedoStaffId,
        fetchCard,
    } = props;

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const dispatch = useDispatch();

    const fetchCommonCard = useCallback(() => {
        dispatch(getKedoStaffCommon({
            clientId,
            kedoStaffId,
        }));
    }, []);

    useEffect(() => {
        fetchCommonCard();

        return () => {
            dispatch(updateKedoStaffStore({
                commonCard: {},
            }));
        };
    }, []);

    const card = useSelector(bffKedoStaffCommonCardSelector);

    const config = useMemo(() => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isEdit: true,
                            });
                        },
                        children: "Редактировать",
                        size: "xl",
                        color: "grey",
                    },
                },
            ],
        };
    }, []);

    return (
        <>
            <NmPageCardHeader
                isAvatar={true}
                controls={
                    <MediaButtons
                        inline={true}
                        config={config}
                    />
                }
                subTitle={`Добавлен ${formatDate(card.createdAt, "dd.MM.yyyy")}`}
                to={LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId)}
                content={getFullName(
                    card.lastName,
                    card.firstName,
                    card.patronymic,
                )}
            />
            {
                modalData?.isEdit &&
                <KedoStaffRegistryEdit
                    fetchList={fetchCard}
                    onClose={onCloseModal}
                    clientId={clientId}
                    kedoStaffId={kedoStaffId}
                />
            }
        </>
    );
};