import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {useInfiniteScrollPagination} from "../../../../../hooks/useInfiniteScrollPagination";
import {useModal} from "../../../../../hooks/useModal";

import {toastSuccess} from "../../../../../utils/toastHelper";
import {getCurrentStep, getNextStep} from "../../../../order/order-edit/utils/getCurrentStep";
import {getRequestDataFromMapFilterData} from "../../../utils/dto";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_STATUS} from "../../../../../constants/contractor";
import {CLIENT_TYPE_CODE} from "../../../../../constants/dicts";
import {FC_REGISTRY_ACTION, FC_REGISTRY_ACTION_TEXT} from "../../../../../constants/fcRegistries";
import {
    MAP_CONTRACTOR_SORT_TYPE,
    MAP_ORDER_SORT_TYPE,
    MAP_TASK_SORT_TYPE,
    MARKER_TYPE,
} from "../../../constants";
import {MAP_INVITE_MATCHING_TYPE, MAP_INVITE_MODAL_STEP} from "../constants";

import {
    getMapContractors,
    getMapOrders,
    getMapTasks,
    sendInviteTaskMap, updateStoreMap,
} from "../../../../../ducks/bff/map/actionCreators";
import {initialMapEntityLists} from "../../../../../ducks/bff/map/reducer";
import {
    bffMapInviteModalContractorsTotalPagesSelector,
    bffMapInviteModalOrdersTotalPagesSelector,
    bffMapInviteModalTasksTotalPagesSelector,
} from "../../../../../ducks/bff/map/selectors";

export const useMapInviteModalForm = (props) => {
    const {
        data,
        steps,
        isInviteToOrderOrTask,
        selectedList,
        onClose,
        clearSelectedRows,
    } = props;

    const dispatch = useDispatch();

    const contractorTotalPages = useSelector(bffMapInviteModalContractorsTotalPagesSelector);
    const ordersTotalPages = useSelector(bffMapInviteModalOrdersTotalPagesSelector);
    const tasksTotalPages = useSelector(bffMapInviteModalTasksTotalPagesSelector);

    const [activeStepsItems, setActiveStepsItems] = useState([MAP_INVITE_MODAL_STEP.GENERAL.value]);
    const [activeTab, setActiveTab] = useState(MARKER_TYPE.ORDER);

    const currentStep = getCurrentStep({activeItems: activeStepsItems, tabs: steps});
    const isGeneralStep = currentStep === MAP_INVITE_MODAL_STEP.GENERAL.value;
    const isAccessInviteToOrder = data.citizenship ? [
        citizenshipsDict.RU.value,
        citizenshipsDict.AM.value,
        citizenshipsDict.BY.value,
        citizenshipsDict.KZ.value,
        citizenshipsDict.KG.value,
        citizenshipsDict.UA.value,
        citizenshipsDict.LG.value,
        citizenshipsDict.DN.value,
    ].includes(data.citizenship) :
        data.registrationStatus === CONTRACTOR_STATUS.BASIC_REGISTRATION;
    const isAccessInviteToTask = data.citizenship ?
        data.citizenship === citizenshipsDict.RU.value :
        data.registrationStatus === CONTRACTOR_STATUS.BASIC_REGISTRATION;

    const listPageData = useMemo(() => {
        if (data.type !== MARKER_TYPE.CONTRACTOR) {
            return {
                totalPages: contractorTotalPages,
            };
        }

        if (data.type === MARKER_TYPE.CONTRACTOR && activeTab === MARKER_TYPE.ORDER) {
            return {
                totalPages: ordersTotalPages,
            };
        }

        if (data.type === MARKER_TYPE.CONTRACTOR && activeTab === MARKER_TYPE.CROWD_TASK) {
            return {
                totalPages: tasksTotalPages,
            };
        }

        return {
            totalPages: 0,
        };
    }, [
        contractorTotalPages,
        ordersTotalPages,
        tasksTotalPages,
        activeTab,
    ]);

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const {
        pageData,
        infiniteScrollProps,
        setPageData,
    } = useInfiniteScrollPagination({
        ...listPageData,
    });

    const initialValues = useMemo(() => {
        const initialSpecialityId = isInviteToOrderOrTask ? data.mainSpecialityId : data.specialityId;

        return {
            distance: 5,
            specialityIdsFilter: initialSpecialityId ? [initialSpecialityId] : [],
        };
    }, [data, isInviteToOrderOrTask]);

    useEffect(() => {
        return () => {
            clearStoreInviteModalLists();
        };
    }, []);

    useEffect(() => {
        if (currentStep !== MAP_INVITE_MODAL_STEP.GENERAL.value) {
            fetchList();
        }
    }, [pageData]);

    useEffect(() => {
        setPageData({pageNum: 1});
        clearSelectedRows();
    }, [activeTab]);

    const handleChange = (e, {name, value, checked}) => {
        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const getMatchingType = () => {
        if (data.type === MARKER_TYPE.ORDER) {
            return MAP_INVITE_MATCHING_TYPE.ORDER_FOR_CONTRACTORS;
        }

        if (data.type === MARKER_TYPE.CROWD_TASK) {
            return MAP_INVITE_MATCHING_TYPE.CROWD_TASK_FOR_CONTRACTORS;
        }

        if (data.type === MARKER_TYPE.CONTRACTOR && activeTab === MARKER_TYPE.ORDER) {
            return MAP_INVITE_MATCHING_TYPE.CONTRACTOR_ON_ORDERS;
        }

        if (data.type === MARKER_TYPE.CONTRACTOR && activeTab === MARKER_TYPE.CROWD_TASK) {
            return MAP_INVITE_MATCHING_TYPE.CONTRACTOR_ON_CROWD_TASKS;
        }

        return null;
    };

    const onSubmit = () => {
        if (currentStep === MAP_INVITE_MODAL_STEP.GENERAL.value) {
            goNextStep();
            setPageData({pageNum: 1});

            return;
        }

        if (data.type === MARKER_TYPE.CROWD_TASK) {
            onOpenConfirm({
                content: `Вы действительно хотите пригласить исполнителей на задание №${data.number} ${data.name}?`,
                onConfirm: sendInvite,
            });

            return;
        }

        if (data.type === MARKER_TYPE.ORDER) {
            const generalContent = `Вы действительно хотите пригласить исполнителей на заказ №${data.orderNum} ${data.name}?`;
            const selectedContractors = selectedList.filter(({isSelected}) => isSelected);
            const isShowWarning = data.clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY
                && selectedContractors.some(item =>
                    ((item.citizenship && item.citizenship !== citizenshipsDict.RU.value) || item.locatedOutsideRussia)
                    && !item.frameContractSigned,
                );

            onOpenConfirm({
                title: isShowWarning ? generalContent : "",
                content: isShowWarning ?
                    FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS] :
                    generalContent,
                onConfirm: sendInvite,
            });

            return;
        }

        const generalContent = activeTab === MARKER_TYPE.ORDER ?
            "Вы действительно хотите пригласить исполнителя на заказы?" :
            "Вы действительно хотите пригласить исполнителя на задания?";
        const selectedOrders = selectedList.filter(({isSelected}) => isSelected);
        const isShowWarning = activeTab === MARKER_TYPE.ORDER
            && ((data.citizenship && data.citizenship !== citizenshipsDict.RU.value) || data.locatedOutsideRussia)
            && selectedOrders.some(item => !item.frameContractSigned);
        ;
        onOpenConfirm({
            title: isShowWarning ? generalContent : "",
            content: isShowWarning ?
                FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS] :
                generalContent,
            onConfirm: sendInvite,
        });
    };

    const sendInvite = () => {
        const selectedContractorIds = selectedList
            .filter(({isSelected, contractorId}) => isSelected && Boolean(contractorId))
            .map(({contractorId}) => contractorId);
        const selectedTaskIds = activeTab === MARKER_TYPE.CROWD_TASK ?
            selectedList
                .filter(({isSelected, taskId}) => isSelected && Boolean(taskId))
                .map(({clientId, taskId}) => ({clientId, taskId})) :
            undefined;
        const selectedOrderIds = activeTab === MARKER_TYPE.ORDER ?
            selectedList
                .filter(({isSelected, orderId}) => isSelected && Boolean(orderId))
                .map(({clientId, orderId}) => ({clientId, orderId})) :
            undefined;

        dispatch(sendInviteTaskMap({
            matchingType: getMatchingType(),
            contractorIds: data.type === MARKER_TYPE.CONTRACTOR ?
                [data.contractorId] :
                selectedContractorIds,
            orderIds: data.type === MARKER_TYPE.ORDER ?
                [{
                    clientId: data.clientId,
                    orderId: data.orderId,
                }] :
                selectedOrderIds,
            crowdTaskIds: data.type === MARKER_TYPE.CROWD_TASK ?
                [{
                    clientId: data.clientId,
                    taskId: data.taskId,
                }] :
                selectedTaskIds,
            onSuccess: () => {
                toastSuccess("Задача на приглашение успешно запущена");
                onOpenConfirm();
                onClose();
            },
        }));
    };

    const fetchList = () => {
        const isLoadMore = pageData.pageNum !== 1;

        const {
            distanceFilter,
            contractorsFilters,
            crowdTasksFilters,
            ordersFilters,
        } = getRequestDataFromMapFilterData({
            distanceFilter: {
                distance: values.distance,
                coordinateLon: data.geoLon,
                coordinateLat: data.geoLat,
            },
            ordersFilters: {
                workDateFrom: values.workDateFrom,
                workDateTo: values.workDateTo,
                toDateFilter: values.toDateFilter,
                specialityIdsFilter: values.specialityIdsFilter,
            },
            crowdTasksFilters: {
                workDateFrom: values.workDateFrom,
                workDateTo: values.workDateTo,
                toDateFilter: values.toDateFilter,
                specialityIdsFilter: values.specialityIdsFilter,
            },
            contractorsFilters: {
                lastLoginDateFromFilter: values.lastLoginDateFromFilter,
                specialityIdsFilter: values.specialityIdsFilter,
            },
        });

        const reqData = {
            ...pageData,
            pageSize: 25,
            distanceFilter,
            isLoadMore,
            isInviteModal: true,
        };

        if (!isInviteToOrderOrTask) {
            dispatch(getMapContractors({
                ...reqData,
                ...contractorsFilters,
                orderType: MAP_CONTRACTOR_SORT_TYPE.DISTANCE_ASC,
                citizenshipFilter: data.type === MARKER_TYPE.ORDER ? [
                    citizenshipsDict.RU.value,
                    citizenshipsDict.AM.value,
                    citizenshipsDict.BY.value,
                    citizenshipsDict.KZ.value,
                    citizenshipsDict.KG.value,
                    citizenshipsDict.UA.value,
                    citizenshipsDict.LG.value,
                    citizenshipsDict.DN.value,
                ] : [citizenshipsDict.RU.value],
                includeEmptyCitizenshipFilter: true,
                forCrowdTaskIdSearch: data.type === MARKER_TYPE.CROWD_TASK ? {
                    clientId: data.clientId,
                    taskId: data.taskId,
                } : undefined,
                forOrderIdSearch: data.type === MARKER_TYPE.ORDER ? {
                    clientId: data.clientId,
                    orderId: data.orderId,
                } : undefined,
            }));
        }

        if (isInviteToOrderOrTask && activeTab === MARKER_TYPE.ORDER && isAccessInviteToOrder) {
            dispatch(getMapOrders({
                ...reqData,
                ...ordersFilters,
                orderType: MAP_ORDER_SORT_TYPE.WORK_START_DATE_ASC,
                contractorIdFilter: data.contractorId,
                enrichFrameContractSigned: true,
            }));
        }

        if (isInviteToOrderOrTask && activeTab === MARKER_TYPE.CROWD_TASK && isAccessInviteToTask) {
            dispatch(getMapTasks({
                ...reqData,
                ...crowdTasksFilters,
                orderType: MAP_TASK_SORT_TYPE.WORK_START_DATE_ASC,
                contractorIdFilter: data.contractorId,
            }));
        }
    };

    const goNextStep = () => {
        const nextStep = getNextStep({
            activeItems: activeStepsItems,
            tabs: steps,
        });


        setActiveStepsItems(prevState => [
            ...prevState,
            nextStep,
        ]);
    };

    const goBackStep = () => {
        const _activeItems = [...activeStepsItems];

        clearStoreInviteModalLists();
        _activeItems.pop();
        clearSelectedRows();
        setActiveStepsItems(_activeItems);
        setActiveTab(MARKER_TYPE.ORDER);
    };

    const clearStoreInviteModalLists = () => {
        dispatch(updateStoreMap({
            inviteModalData: initialMapEntityLists,
        }));
    };

    const {
        values,
        setFieldValue,
        setValues,
        handleSubmit,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validateOnBlur: false,
    });

    return {
        values,
        setFieldValue,
        setValues,
        handleChange,
        activeStepsItems,
        currentStep,
        onSubmit,
        pageData,
        infiniteScrollProps,
        isGeneralStep,
        goBackStep,
        handleSubmit,
        activeTab,
        setActiveTab,
        isOpenConfirm,
        confirmData,
        onOpenConfirm,
        onCloseConfirm,
    };
};