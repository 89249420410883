import {CREATE_ACTS_TYPE} from "../../registryActsType";

import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {amountRegexDelimited, requiredMessage} from "../../../../constants/validationRules";
import {
    editPaymentRule,
    orderDescriptionFnsValidation,
} from "../constants";

export const getValidation = (registry, client) => {
    const {
        orderContractPaymentType,
        createActsOfAcceptanceOfWorkType,
        documentTablesEnabled,
    } = registry;
    const {
        adminAmountOneTransaction,
    } = client;

    let rule;

    const isCivil = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL;
    const isIndividualEntrepreneur = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    const isCreateActsNone= createActsOfAcceptanceOfWorkType !== CREATE_ACTS_TYPE.NONE;

    if (isCivil) {
        rule = {
            ...editPaymentRule,
            ...getOrderAmountValidations(adminAmountOneTransaction, isCivil),
        };
    } else {
        rule = {
            ...editPaymentRule,
            ...getOrderAmountValidations(adminAmountOneTransaction),
            orderDescriptionFns: orderDescriptionFnsValidation,
        };
    }

    if (isCreateActsNone) {
        if (isCivil) {
            rule = {
                ...rule,
                orderDescription: {
                    ...rule.orderDescription,
                    required: requiredMessage,
                },
            };
        } else {
            rule = {
                ...rule,
                orderDescription: {
                    ...rule.orderDescription,
                    required: requiredMessage,
                },
                orderDescriptionFns: {
                    ...orderDescriptionFnsValidation,
                    required: !isIndividualEntrepreneur && requiredMessage,
                },
            };
        }
    }

    if (documentTablesEnabled) {
        return ({
            orderDescription: {
                ...rule.orderDescription,
                required: requiredMessage,
            },
            orderWorkDescription: {
                ...rule.orderWorkDescription,
                required: requiredMessage,
            },
            orderDescriptionFns: !isCivil ? {
                ...orderDescriptionFnsValidation,
                required: !isIndividualEntrepreneur && requiredMessage,
            } : undefined,
        });
    }

    return rule;
};

export const getOrderAmountValidations = (adminAmountOneTransaction, isCivil = false) => {
    if (isCivil) {
        return {
            orderAmountForPayment: {
                required: requiredMessage,
                minValueNotStrong: {
                    value: 10,
                    message: "Не может быть менее 10 руб",
                },
                func: (orderAmountForPayment, civilContractAmount) => {
                    if (orderAmountForPayment > civilContractAmount) {
                        return "Не может быть более заданной суммы по договору";
                    }

                    if (orderAmountForPayment > adminAmountOneTransaction) {
                        return `Не может быть более лимита 1 транзакции, установленного в параметрах компании\n (${formatAmountWithNullChecking(adminAmountOneTransaction)} ₽)`;
                    }
                },
                secondArg: "civilContractAmount",
            },
            civilContractAmount: {
                required: requiredMessage,
            },
        };
    }

    return {
        orderAmount: {
            required: requiredMessage,
            minValue: {
                value: 9,
                message: "Не может быть менее 10 Р",
            },
            match: {
                value: amountRegexDelimited,
                message: "Неверный формат суммы. Пример: 100,12",
            },
            maxValue: {
                value: adminAmountOneTransaction,
                message: `Не может быть более лимита 1 транзакции, установленного в параметрах компании\n (${formatAmountWithNullChecking(adminAmountOneTransaction)} ₽)`,
            },
        },
    };
};