import React from "react";
import {useSelector} from "react-redux";

import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";

import {CONTRACTOR_WORK_SCHEDULE_DAY_DICT} from "../../../../constants/contractor";

import {contractorSchedulesSelector} from "../../../../ducks/contractorProfile";

export default function ContractorEmploymentSchedule(props) {
    const {
        className,
        accessEdit,
    } = props;

    const schedules = useSelector(contractorSchedulesSelector);

    function mapTimes({startWorkTime, endWorkTime}) {
        return <div className="d-flex">
            <div className="col-1">
                {startWorkTime}
-
                {endWorkTime}
            </div>
        </div>;
    }

    function mapContent() {
        if (!schedules.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return schedules.map((item) => {
            const {startWorkTime, endWorkTime, dayOfWeek} = item;
            return (
                <NmLabelText
                    key={dayOfWeek}
                    className="mb-md-2 mb-xxl-2 mb-xl-2"
                    classNameLabel="margin-none col-1"
                    label={CONTRACTOR_WORK_SCHEDULE_DAY_DICT[dayOfWeek].replace(": ", "")}
                    text={mapTimes({startWorkTime, endWorkTime})}
                />
            );
        });
    }


    return (
        <NmMiniInfoCard
            accessEdit={accessEdit}
            className={className}
            title="График занятости"
        >
            {mapContent()}
        </NmMiniInfoCard>
    );
}