import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../NmDropdownV2";

import { isNullOrWhitespace } from "../../../utils/stringHelper";

import {
    clientListOptimizedOptionsSelector,
    clientPageDataSelector,
    getClientNamesList,
    getOptimizedClientList,
} from "../../../ducks/client";

interface IFilterCustomer {
    value: [],
    name?: string,
    onChange: (event: any, params: any) => void,
    className?: string,
    isMountLoad?: boolean,
    onSubmit?: () => void,
    isBff?: boolean,
    label?: string | object
}

const FilterCustomer = (props: IFilterCustomer) => {
    const {
        isBff,
        label = "Заказчик",
    } = props;

    const dispatch = useDispatch();

    const options = useSelector(clientListOptimizedOptionsSelector);
    const {nameSubstringFilter} = useSelector(clientPageDataSelector);

    useEffect(() => {
        if (nameSubstringFilter) {
            fetchClients(nameSubstringFilter);
        }
    }, []);

    const fetchClients = (clientName: string) => {
        if (isBff) {
            dispatch(getClientNamesList({
                pageNum: 1,
                pageSize: 25,
                nameSubstringFilter: isNullOrWhitespace(clientName) ? undefined : clientName.trim(),
            }));

            return;
        }

        dispatch(getOptimizedClientList({
            pageNum: 1,
            pageSize: 25,
            nameSubstringFilter: isNullOrWhitespace(clientName) ? undefined : clientName.trim(),
        }));
    };

    const onSearchChange = (valueFilter: string) => {
        fetchClients(valueFilter);
    };

    return (
        <NmDropdownV2
            {...props}
            size="lg"
            label={label}
            isMountLoad={!nameSubstringFilter && !props.value}
            onSearchChange={onSearchChange}
            search
            options={options}
        />
    );
};

export default FilterCustomer;