import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputPassword from "../../../components/ActualComponents/NmInputPassword";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import {LoginButton} from "../button";
import LoginCard from "../card";
import LoginRecovery from "../recovery";
import LoginSuccessNotification from "../success-notification";
import LoginTitle from "../title";

import {
    ORDER_FILTER_KEY,
    setOrderPageDataToLs,
} from "../../../utils/localstorage";
import {isPromoPoiskPage} from "../../../utils/url";

import {LINK_REQUEST} from "../../../constants/links";
import {orderListDefaultFilter} from "../../../constants/order";
import {requiredMessage} from "../../../constants/validationRules";

import {
    authorizationRequest,
    authProgressSelector,
    clearLocalStorage,
    updateFieldAuthorizationStore,
} from "../../../ducks/auth";
import {clearClientStore} from "../../../ducks/client";
import {clearClientMemberStore} from "../../../ducks/clientMember";
import {updateFeedbackStore} from "../../../ducks/feedback";
import {clearOrderStore} from "../../../ducks/order";

import "./style.sass";

class LoginAuth extends Component {
    constructor() {
        super();

        this.state = {
            isOpenForgot: false,
            login: "",
            password: "",
            loginError: false,
            passwordError: false,
        };
    }

    componentDidMount() {
        const {
            clearLocalStorage,
            clearClientStore,
            clearClientMemberStore,
            clearOrderStore,
        } = this.props;

        clearLocalStorage();
        clearClientStore();
        clearClientMemberStore();
        clearOrderStore();
        setOrderPageDataToLs({
            [ORDER_FILTER_KEY]: orderListDefaultFilter(),
        });
    }

    componentDidUpdate(prevState) {
        const {login, password, loginError, passwordError} = this.state;
        const {login: _login, password: _password} = prevState;

        if (login && login !== _login && loginError || password && password !== _password && passwordError) {
            this.handleValidation();
        }
    }

    submitLogin = () => {
        const {authorizationRequest} = this.props;
        const {login, password} = this.state;

        if (this.handleValidation()) {
            return;
        }

        authorizationRequest(login, password);
    };

    handleOnChange = (event, {value, name}) => {
        this.setState({[name]: value});

        const {
            message,
            updateFieldAuthorizationStore,
        } = this.props;

        if (message) {
            updateFieldAuthorizationStore("message", null);
        }
    };

    handleValidation = () => {
        const {login, password} = this.state;

        let error = false;

        //Login validation
        if (login === "") {
            this.setState({
                loginError: true,
            });
            error = true;
        } else {
            this.setState({
                loginError: false,
            });
        }

        //Password validation
        if (password === "") {
            this.setState({
                passwordError: true,
            });
            error = true;
        } else {
            this.setState({
                passwordError: false,
            });
        }

        return error;
    };

    renderRecovery = () => {
        const {isOpenForgot} = this.state;

        return isOpenForgot &&
            <LoginRecovery
                close={this.openForgot}
            />;
    };

    openForgot = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenForgot: !prevState.isOpenForgot,
        }));
    };

    closeSuccessNotification = () => {
        this.setState({
            successNotification: null,
        });
    };

    renderSuccessNotification = () => {
        const {successNotification} = this.state;

        if (!successNotification) {
            return null;
        }

        return (
            <LoginSuccessNotification
                title={successNotification.title}
                buttonText="Понятно"
                onClose={this.closeSuccessNotification}
                onSubmit={this.closeSuccessNotification}
            />
        );
    };

    render() {
        const {
            loginError,
            passwordError,
            login,
            password,
        } = this.state;
        const {progress, t} = this.props;

        const isPromoPoisk = isPromoPoiskPage();

        return (
            <LoginCard className="login-auth">
                {this.renderSuccessNotification()}
                {this.renderRecovery()}
                <LoginTitle>
                    {t("login.login-title")}
                </LoginTitle>
                <NmForm className="login-auth__form">
                    <NmInputV2
                        openNaimix={!isPromoPoisk}
                        promoPoisk={isPromoPoisk}
                        required
                        label="Логин"
                        size="xl"
                        labelClassName="d-flex"
                        placeholder="Введите логин"
                        className="mb-3 mb-md-4"
                        value={login}
                        name="login"
                        onChange={this.handleOnChange}
                        error={loginError ? requiredMessage : null}
                    />
                    <NmInputPassword
                        size="xl"
                        required
                        label="Пароль"
                        openNaimix={!isPromoPoisk}
                        promoPoisk={isPromoPoisk}
                        className="mb-4 mb-md-5"
                        labelClassName="d-flex"
                        placeholder={t("login.password")}
                        value={password}
                        onChange={this.handleOnChange}
                        onClickForgotPassword={this.openForgot}
                        error={passwordError ? requiredMessage : null}
                    />
                    <LoginButton
                        primary
                        disabled={progress}
                        loading={progress}
                        size="xl"
                        className="login-auth__button"
                        onClick={this.submitLogin}
                    >
                        {t("login.login-button")}
                    </LoginButton>
                </NmForm>
                <div className="login-auth__join-text">
                    Не зарегистрированы? Получите доступ к Наймиксу в один клик
                </div>
                <LoginButton
                    secondary
                    isLink
                    size="xl"
                    className="login-auth__button"
                    href={LINK_REQUEST}
                >
                    Подать заявку
                </LoginButton>
            </LoginCard>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        auth: state.auth,
        progress: authProgressSelector(state),
    }),
    {
        authorizationRequest,
        clearLocalStorage,
        updateFieldAuthorizationStore,
        clearClientStore,
        clearClientMemberStore,
        clearOrderStore,
        updateFeedbackStore,
    },
)(withTranslation()(LoginAuth));