export const getOrderListMassTemplatePublicationCustomDocumentIds = (list) => {
    const ids = list
        .reduce((accum, value) => {
            return [
                ...accum,
                value.actOfAcceptanceOfWorkTemplateId,
                value.orderApplicationTemplateId,
                value.frameContractTemplateId,
            ];
        }, [])
        .filter(value => value);

    return [...new Set(ids)];
};