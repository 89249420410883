import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageCrowdPaymentLog,
    updateStoreCrowdPayment,
} from "../../../../../../ducks/bff/crowd/payment/actionCreators";

const useFinanceCrowdPaymentsLogModalFetch = ({pageNum, pageSize, clientId, paymentId, contractorId}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdPayment({
                logPageData: {},
                logList: [],
                logTotalCount: 0,
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getPageCrowdPaymentLog({
            clientId,
            paymentId,
            contractorId,
            pageNum,
            pageSize,
        }));
    };
    return {
        fetchList,
    };
};

export default useFinanceCrowdPaymentsLogModalFetch;