import {KEDO_DOCUMENT_STATUS} from "../constants";

// Получить значение фильтра "Статус документа"
export const getDocumentStatesFilter = (documentStatesFilter?: Array<keyof typeof KEDO_DOCUMENT_STATUS>) => {
    if (!documentStatesFilter) {
        return;
    }

    return documentStatesFilter.reduce<Array<keyof typeof KEDO_DOCUMENT_STATUS>>((result, status) => {
        if (status === KEDO_DOCUMENT_STATUS.ON_RECEIVER) {
            return [
                ...result,
                KEDO_DOCUMENT_STATUS.ON_RECEIVER,
                KEDO_DOCUMENT_STATUS.ON_SENDER,
            ];
        }

        if (status === KEDO_DOCUMENT_STATUS.REJECT_RECEIVER) {
            return [
                ...result,
                KEDO_DOCUMENT_STATUS.REJECT_RECEIVER,
                KEDO_DOCUMENT_STATUS.REJECT_SENDER,
            ];
        }

        return [
            ...result,
            status,
        ];
    }, []);
};