import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getRecruitmentVacancyCounters} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentIsVacancyResponsesPageActiveSelector,
    bffRecruitmentVacancyTabFilterDataSelector,
} from "../../../../../../ducks/bff/recruitment/vacancy/selectors";

export const useRecruitmentFunnelFilters = (params) => {
    const {
        vacancyId,
        clientId,
        isFetchByFilterData,
    } = params;

    const {filterData = {}} = useSelector(bffRecruitmentVacancyTabFilterDataSelector);
    const isResponsesPage = useSelector(bffRecruitmentIsVacancyResponsesPageActiveSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isFetchByFilterData) {
            fetchFunnelFilters();
        }
    }, [
        filterData,
    ]);

    const fetchFunnelFilters = () => {
        dispatch(getRecruitmentVacancyCounters({
            vacancyId,
            clientId,
            ...filterData,
            filterForResponses: isResponsesPage,
        }));
    };

    return {
        fetchFunnelFilters,
    };
};