import {useState} from "react";
import {isEmpty} from "lodash";

import {convertUtcToLocal, getEndDate, getStartDate} from "../../../../utils/dateFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";

export function useAdvertisementFilter() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(true);

    function mapFilterDto(filter) {
        const {
            name,
            specialityIds,
            workAddressFiasId,
            publicationFromFilter,
            publicationToFilter,
            fromDateCreatedFilter,
            toDateCreatedFilter,
            status,
        } = filter;

        return {
            nameFilter: handleFormString(name),
            specialityIdsFilter: isEmpty(specialityIds) ? undefined : specialityIds,
            workAddressFiasIdsFilter: isEmpty(workAddressFiasId) ? undefined : [workAddressFiasId],
            publicationFromFilter: convertUtcToLocal(getStartDate(publicationFromFilter)),
            publicationToFilter: convertUtcToLocal(getEndDate(publicationToFilter)),
            createDateFromFilter: convertUtcToLocal(getStartDate(fromDateCreatedFilter)),
            createDateToFilter: convertUtcToLocal(getEndDate(toDateCreatedFilter)),
            statusesFilter: !status || status === EMPTY_OPTION_KEY ? undefined : [status],
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        isOpenFilter,
        setIsOpenFilter,
    };
}