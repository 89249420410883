import React, {useState} from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterContractor from "../../../../components/ActualComponents/FilterContractor";
import FilterObject from "../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../components/ActualComponents/FilterProject";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../components/ActualComponents/NmRangeInput";

import {dadataValueUniqCitySettlementFilter, getDadataAddress} from "../../../../utils/dadata";
import {getDateObject} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {CHECKED_ACTS_FILTER_OPTIONS, ORDER_WORK_REPORT_TYPE, SOURCE_TYPE_OPTIONS} from "../../../../constants/finance";
import {STATUS_NAIMIX_FILTER, STATUS_NAIMIX_INDIVIDUAL_FILTER} from "../../../../constants/status";
import {regFullName} from "../../../../constants/validationRules";

import "./style.sass";

const PATTERN_NUMBER = /^\d*(,\d{0,2})?$/;

const FinanceClientPaymentListFilter = (props) => {
    const {
        onChange = () => {
        },
        clearFilter = () => {
        },
        sendFilter = () => {
        },
        dataFilter = {},
        bankStatusOptions = [],
        isClientRole,
        clientId,
        responsibleOptions,
        orderWorkReportType,
    } = props;

    const [isSearchClear, setSearchClear] = useState(false);
    const [regionsIds, setRegionsIds] = useState([]);
    const [regionsOptions, setRegionsOptions] = useState([]);

    const isIndividualEntrepreneur = orderWorkReportType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    const naimixStatusDict = isIndividualEntrepreneur ?
        STATUS_NAIMIX_INDIVIDUAL_FILTER :
        STATUS_NAIMIX_FILTER;
    const paymentStatusOptions = dictionaryToOptions(naimixStatusDict);

    const {
        reportStatusFilter,
        statusOfTheTransferFilter,
        fromDateFilter,
        toDateFilter,
        paymentNumberFilter,
        orderNumFilter,
        fromSumMoneyFilter,
        toSumMoneyFilter,
        orderNameFilter,
        contractorIdFilter,
        fromClientCommissionAmountFilter,
        toClientCommissionAmountFilter,
        projectIdsFilter,
        objectIdsFilter,
        sourceTypeFilter,
        checked,
        clientUserIdFilter,
    } = dataFilter;

    const reportStatus = (reportStatusFilter && reportStatusFilter.length) ? reportStatusFilter[0] : null;
    const statusOfTheTransfer = (statusOfTheTransferFilter && statusOfTheTransferFilter.length) ? statusOfTheTransferFilter[0] : null;

    const handleOnChangeSelect = (e, {value, name}) => {
        const _value = value === "" ? undefined : [value];
        onChange(e, {value: _value, name});
    };

    const onKeyPress = ({key}) => {
        if (key === "Enter") {
            sendFilter();
        }
    };

    const handleClearFilter = () => {
        clearFilter();
        setRegionsOptions([]);
        setRegionsIds([]);

        setSearchClear(true);
        setTimeout(() => {
            setSearchClear(false);
        }, 50);
    };

    return (
        <>
            <NmForm
                addMargin
                className="finance-client-payment-list-filter"
                onKeyPress={onKeyPress}
            >
                <FilterProject
                    clientId={clientId}
                    label="Проект"
                    name="projectIdsFilter"
                    onChange={onChange}
                    search
                    projectIdsFilter={projectIdsFilter}
                    isSearchClear={isSearchClear}
                    isActiveStyle={false}
                    onSubmit={sendFilter}
                />
                <FilterObject
                    clientId={clientId}
                    name="objectIdsFilter"
                    onChange={onChange}
                    projectIdsFilter={projectIdsFilter}
                    objectIdsFilter={objectIdsFilter}
                    isSearchClear={isSearchClear}
                    isActiveStyle={false}
                    onSubmit={sendFilter}
                    isMountLoad={true}
                />
                <NmDadata
                    label="Город"
                    multiple
                    size="lg"
                    name="addressFiasIds"
                    fromBound="region"
                    toBound="settlement"
                    onChange={onChange}
                    formatter={getDadataAddress}
                    filter={dadataValueUniqCitySettlementFilter}
                    isAllSelectionShown={false}
                    initialOptions={regionsOptions}
                    isMountLoad={false}
                    initialAddressIds={regionsIds}
                    onSubmit={sendFilter}
                />
                <FilterContractor
                    size="lg"
                    onChange={onChange}
                    name="contractorIdFilter"
                    value={contractorIdFilter}
                    relatedClientId={clientId}
                    ignoreClientGroupRelations={true}
                />
                <NmRangeInput
                    nameStart="fromSumMoneyFilter"
                    nameEnd="toSumMoneyFilter"
                    size="lg"
                    valueStart={fromSumMoneyFilter}
                    valueEnd={toSumMoneyFilter}
                    pattern={PATTERN_NUMBER}
                    onChange={onChange}
                    label="Сумма операции"
                    placeholderStart="От"
                    placeholderEnd="До"
                />
                <NmDateRangePickerV2
                    label="Дата операции"
                    onChange={onChange}
                    size="lg"
                    dateFormat="dd.MM.yy"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    isClearable
                    value={{
                        fromDateFilter: getDateObject(fromDateFilter),
                        toDateFilter: getDateObject(toDateFilter),
                    }}
                />
                <FilterButtonsV2
                    onSearch={sendFilter}
                    onClear={handleClearFilter}
                />
                <NmInputV2
                    size="lg"
                    label="Номер оплаты"
                    maskChar={null}
                    mask="999999999999"
                    name="paymentNumberFilter"
                    value={paymentNumberFilter}
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    mask="999999999999"
                    maskChar={null}
                    label="Номер заказа"
                    value={orderNumFilter}
                    name="orderNumFilter"
                    onChange={onChange}
                />
                {
                    !isIndividualEntrepreneur &&
                    <NmInputV2
                        size="lg"
                        label="Название"
                        value={orderNameFilter}
                        pattern={regFullName}
                        name="orderNameFilter"
                        onChange={onChange}
                    />
                }
                <NmDropdownV2
                    size="lg"
                    label="Статус Наймикс"
                    selectOnBlur={false}
                    name="reportStatusFilter"
                    clearable
                    compact
                    onChange={handleOnChangeSelect}
                    value={reportStatus}
                    options={paymentStatusOptions}
                />
                {
                    !isClientRole &&
                    <NmDropdownV2
                        size="lg"
                        label="Банковский статус"
                        isClearable
                        name="statusOfTheTransferFilter"
                        onChange={handleOnChangeSelect}
                        options={bankStatusOptions}
                        value={statusOfTheTransfer}
                    />
                }
                <NmRangeInput
                    nameStart="fromClientCommissionAmountFilter"
                    nameEnd="toClientCommissionAmountFilter"
                    size="lg"
                    valueStart={fromClientCommissionAmountFilter}
                    valueEnd={toClientCommissionAmountFilter}
                    pattern={PATTERN_NUMBER}
                    onChange={onChange}
                    label="Комиссия заказчика"
                    placeholderStart="От"
                    placeholderEnd="До"
                />
                {
                    !isIndividualEntrepreneur &&
                    <NmDropdownV2
                        size="lg"
                        label="Тип оплаты"
                        onChange={onChange}
                        selection
                        name="sourceTypeFilter"
                        value={sourceTypeFilter}
                        options={SOURCE_TYPE_OPTIONS}
                    />
                }
                <NmDropdownV2
                    size="lg"
                    label="Ответственный"
                    onChange={onChange}
                    selection
                    name="clientUserIdFilter"
                    value={clientUserIdFilter}
                    options={responsibleOptions}
                />
                {
                    !isIndividualEntrepreneur &&
                    <NmDropdownV2
                        size="lg"
                        label="Проверка актов"
                        onChange={onChange}
                        selection
                        name="checked"
                        value={checked}
                        options={CHECKED_ACTS_FILTER_OPTIONS}
                    />
                }
            </NmForm>
        </>
    );
};

export default FinanceClientPaymentListFilter;