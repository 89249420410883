import React from "react";
import {Link} from "react-router-dom";

import bem from "../../utils/bem";

import "./style.sass";

const ToastMessage = (props) => {
    const {
        link,
        textLink,
        type,
        message,
    } = props;

    const [block, element] = bem("toast-message");

    return (
        <div className={block()}>
            {message}
            {
                link &&
                <Link
                    download
                    className={element("link", {type})}
                    to={link}
                    target="_blank"
                >
                    {textLink}
                </Link>
            }
        </div>
    );
};

export default ToastMessage;