import {useDispatch} from "react-redux";

import {refreshSettingsDirectoriesSbpBanks} from "../../../../../../ducks/settingsDirectoriesBanks";

export const useSettingsDirectoriesBanksSbpAction = () => {
    const dispatch = useDispatch();

    const refreshSbpBanks = () => {
        dispatch(refreshSettingsDirectoriesSbpBanks());
    };

    return {
        refreshSbpBanks,
    };
};