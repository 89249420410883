import React from "react";

import NmAccordionV2 from "../../../../../../components/NmAccordionV2";
import NmCheck from "../../../../../../components/NmCheck";
import {OrderEventLogHistory} from "../order-event-log-history";

import {isNMUsers} from "../../../../../../utils/access";
import {formatLocalDate} from "../../../../../../utils/dateFormat";
import {getFormattedFullName} from "../../../../../../utils/stringFormat";

import {ROLE_DICT, USER_TYPE} from "../../../../../../constants/roles";

import "./style.sass";

export const STATUSES = {
    [null]: {
        VALUE: null,
        TEXT: "На рассмотрении администратором",
        SUCCESS: true,
        LINK_TEXT: "",
    },
    ADMINISTRATOR_CHECK: {
        VALUE: "ADMINISTRATOR_CHECK",
        TEXT: "На рассмотрении администратором",
        SUCCESS: true,
        LINK_TEXT: "",
    },
    APPROVED: {
        VALUE: "APPROVED",
        TEXT: "Данные подтверждены",
        SUCCESS: true,
        LINK_TEXT: "Проверенные данные",
    },
    BANK_CHECK_PASSED: {
        VALUE: "BANK_CHECK_PASSED",
        TEXT: "Банковская проверка пройдена",
        SUCCESS: true,
        LINK_TEXT: "",
    },
    BANK_CHECK_FAILED: {
        VALUE: "BANK_CHECK_FAILED",
        TEXT: "Банковская проверка не пройдена",
        SUCCESS: false,
        LINK_TEXT: "Причина отклонения",
    },
    BANK_CHECK: {
        VALUE: "BANK_CHECK",
        TEXT: "На проверке банком",
        SUCCESS: true,
        LINK_TEXT: "",
    },
    DECLINED: {
        VALUE: "DECLINED",
        TEXT: "Отклонено администратором",
        SUCCESS: false,
        LINK_TEXT: "Причина отклонения",
    },
    CANCELLED: {
        VALUE: "CANCELLED",
        TEXT: "Отозвана",
        SUCCESS: false,
    },
};

export const FILE_STATUSES = {
    /**
     * Подтверждение
     */
    FILE_APPROVED: {
        TEXT: "Подтверждение",
        VALUE: "FILE_APPROVED",
        SUCCESS: true,
    },
    /**
     * Наличие блика
     */
    FILE_DECLINED_IMAGE_GLARE: {
        TEXT: "Подтверждение",
        VALUE: "FILE_DECLINED_IMAGE_GLARE",
        SUCCESS: false,
    },
    /**
     * Требуется фотография, подтверждающая ИНН
     */
    NEED_INN_SCAN: {
        TEXT: "Требуется фотография, подтверждающая ИНН",
        VALUE: "NEED_INN_SCAN",
        SUCCESS: false,
    },
    /**
     * Отсутствует часть изображения
     */
    FILE_DECLINED_IMAGE_PARTIALLY_MISSING: {
        TEXT: "Отсутствует часть изображения",
        VALUE: "FILE_DECLINED_IMAGE_PARTIALLY_MISSING",
        SUCCESS: false,
    },
    /**
     * Иная причина отклонения
     */
    FILE_DECLINED_OTHER_REASON: {
        TEXT: "Иная причина отклонения",
        VALUE: "FILE_DECLINED_OTHER_REASON",
        SUCCESS: false,
    },
    /**
     * ИНН не соответствует паспортным данным
     */
    FILE_DECLINED_INN_AND_PASSPORT_DATA_NOT_EQUALS: {
        TEXT: "Иная причина отклонения",
        VALUE: "FILE_DECLINED_INN_AND_PASSPORT_DATA_NOT_EQUALS",
        SUCCESS: false,
    },
    /**
     * Отклонено автоматически
     */
    FILE_DECLINED_CONTRACTOR_ARCHIVED: {
        TEXT: "Отправка исполнителя в архив",
        VALUE: "FILE_DECLINED_CONTRACTOR_ARCHIVED",
        SUCCESS: false,
    },
};

const ContractorEventLogOrderHistoryItem = (props) => {
    const {
        defaultOpen,
        className,
        item,
        contractorStatusDict,
        toggleCard,
    } = props;
    const {
        logs = [],
        submissionDate,
        contractorPendingOrderNum,
    } = item;

    const reverseLogs = [...logs].reverse();

    const lastLog = reverseLogs[logs.length - 1] || {};

    function renderTitle() {
        return (
            <div className="order-history-item__title">
                Заявка №
                {contractorPendingOrderNum}
                {" "}
от 
                {" "}
                {formatLocalDate(submissionDate)}
            </div>
        );
    }

    function renderAdditionalTitle() {
        const {status} = lastLog;
        const {
            SUCCESS,
            TEXT,
            WARNING,
        } = STATUSES[status];

        return (
            <span className="order-history-item-additional-title">
                <NmCheck
                    isCheck={SUCCESS}
                    isWarning={WARNING}
                /> 
                {" "}
                <div className="order-history-item-additional-title__text">
                    {TEXT}
                </div>
            </span>
        );
    }

    function renderName(performer) {
        const {
            type,
            name,
            clientUserRole,
        } = performer;

        const formattedName = getFormattedFullName(name);

        if(USER_TYPE.BANK.VALUE === type){
            return formattedName;
        }

        const _role = USER_TYPE.NAIMIX_USER.VALUE === type ? ROLE_DICT[clientUserRole].TEXT : USER_TYPE[type].TEXT;

        return `${formattedName} (${_role})`;
    }

    function renderHistoryStatusChanges(logs) {
        return logs.map((log, index) => {
            const {
                dateTime,
                performer,
                pendingPersonalDataModel = {},
                status,
            } = log;

            const {
                previousStatus,
                fileInfoDecisions,
                bankCheckFailedReason,
                rejectionReasons,
            } = pendingPersonalDataModel;

            const {
                SUCCESS,
                TEXT,
                LINK_TEXT,
                WARNING,
            } = STATUSES[status];
            const isNotLastElem = (logs.length - 1) !== index;
            return (
                <div
                    className="order-history-item-personal-data"
                    key={index}
                >
                    <div className="order-history-item-status">
                        <NmCheck
                            isCheck={SUCCESS}
                            label={formatLocalDate(dateTime, "dd.MM.yyyy HH:mm")}
                            labelClass={`order-history-item-status__label ${SUCCESS ? "order-history-item-status__label_green" : ""}`}
                            isWarning={WARNING}
                        />
                        {isNotLastElem &&
                        <div className="order-history-item-status__line order-history-item-status__line_gray" />}
                    </div>
                    <div className="order-history-item-info__item order-history-item-info__item_bold">
                        Статус заявки: 
                        {" "}
                        {TEXT}
                    </div>
                    <div className="order-history-item-info__item">
                        {renderName(performer)}
                    </div>
                    <div className="order-history-item-info__item">
                        Статус: 
                        {" "}
                        {contractorStatusDict[previousStatus]}
                    </div>
                    {LINK_TEXT && <OrderEventLogHistory
                        lengthList={logs.length}
                        indexElem={index}
                        files={fileInfoDecisions}
                        decisionReason={bankCheckFailedReason}
                        status={status}
                        rejectionReasons={rejectionReasons}
                        trigger={
                            <div className="order-history-item-info__link">
                                {LINK_TEXT}
                            </div>
                        }
                    />}
                </div>
            );
        });
    }


    return (
        <NmAccordionV2
            title={renderTitle()}
            defaultOpen={defaultOpen}
            additionalTitle={renderAdditionalTitle()}
            isAdditionalTitleHiddenByOpen
            className={className}
        >
            <div className="order-history-item">
                {
                    isNMUsers() &&
                    <div
                        className="order-history-item-info__link"
                        onClick={() => {
                            toggleCard(item);
                        }}
                    >
                        Персональные данные исполнителя
                    </div>
                }
                <div className="order-history-item-info">
                    {renderHistoryStatusChanges(reverseLogs)}
                </div>
            </div>

        </NmAccordionV2>
    );
};

export default ContractorEventLogOrderHistoryItem;