export const NOTIFICATION_MESSAGE = {
    // Наличие открытых споров по заказу между Исполнителем и Заказчиком;
    CHECK_OPEN_DISPUTE: "Информируем Вас об открытом споре по заказу :order по объекту \":object\" с :contractorFio, номер телефона :contractorPhone. Спор на данный момент не урегулирован.",
    // Открытие нового спора по заказу;
    OPEN_DISPUTE: `Уважаемый :fio! Информируем Вас об открытии спора по заказу :order.\n
                   Ответственный: :foremanFio.\n
                   Самозанятый: :contractorFio.\n
                   Причина спора: :causeDispute.\n
                   Описание проблемы: :descriptionDispute.`,
    // Закрытие спора по заказу;
    CLOSE_DISPUTE: `Уважаемый :fio! Сообщаем Вам, что спор по заказу :order закрыт.\n
                    Результат спора: :disputeStatusResult :disputeResult.`,
    // Отмена спора по заказу;
    CANCEL_DISPUTE: "Уважаемый :fio! Сообщаем Вам, что спор по заказу :order\" отменен.\n",
    // При отклике исполнителя; 
    JOIN_CONTRACTOR_TO_ORDER: "Сообщаем Вам, что :contractorFio, номер телефона :contractorPhone направил отклик на заказ :order по объекту \":object\", период выполнения работ (оказания услуг): :period.",
    // Наличие "просроченных" заказов;
    ORDERS_EXPIRED: `Обращаем Ваше внимание, что у Вас есть опубликованные заказы с истекшим периодом выполнения работ (оказания услуг):\n
                     :order
                     При закрытии данных заказов на Ваш депозит будет возвращена сумма в размере :amount руб.`,
    // Автозакрытие заказов;
    ORDER_CLOSING: "Сообщаем Вам, что заказ :order был автоматически закрыт.",
    // Приглашение в спор по заказу;
    ADD_PARTIES_ON_DISPUTE: "Уважаемый :fio! Вы приглашены для участия в рассмотрении спора по заказу :order с исполнителем :contractorFio, номер телефона :contractorPhone. Спор на данный момент не урегулирован.",
    // Администратор/Менеджер Наймикс согласовал/отклонил предложенную связку.
    CLIENT_SPECIALITY_OFFER_RESULT_DECISION: `Информируем Вас о том, что по заказу № :order администрацией Наймикс было принято решение по :acceptedText предложенного вами вида деятельности.\n\n
                                              Вид деятельности: :speciality`,
    PAYMENT_NOT_CONFIRMED: "Сообщаем Вам, что у Вас есть неподтвержденные выплаты :d исполнителям по следующим заказам: :d.",
    CANCELED_CHECKS_MONTH: "Информируем Вас о том, что за текущий месяц :start - :end были обнаружены :link.",
    CANCELED_CHECKS_QUARTER: "Информируем Вас о том, что за текущий квартал :start - :end были обнаружены :link.",
    CONTRACTOR_PERSONAL_DATA_ACCESS_ABSENT: "Исполнитель :contractor, номер телефона :contractorPhone отклонил запрос на доступ к персональным данным.",
    CONTRACTOR_PERSONAL_DATA_ACCESS_GRANTED: "Исполнитель :contractor, номер телефона :contractorPhone предоставил доступ к персональным данным.",
    PUBLISHING_REVIEW: "Ваш отзыв на исполнителя :contractor прошел модерацию и был опубликован.",
    PUBLISHING_SCORE_REPLY: "Ваш ответ на отзыв исполнителя :contractorFio прошел модерацию и был опубликован. Перейдите в раздел :reviews, чтобы просмотреть.",
    PUBLISHING_SCORE_REPLY_WITH_CORRECTION: "Ваш ответ на отзыв на исполнителя :contractorFio прошел модерацию и был опубликован. Мы внесли корректировки в текст вашего ответа. Перейдите в раздел :reviews, чтобы просмотреть. Если Вы не согласны с внесенными изменениями, можете обратиться в службу поддержки.",
};

export const NOTIFICATION_TYPE = {
    ADD_DEPOSIT: "ADD_DEPOSIT", //Уведомление о событии "Добавление депозита Заказчика"
    REFUND_OF_DEPOSIT: "REFUND_OF_DEPOSIT", //Уведомление о событии "Возврат депозита Заказчика"
    PAYMENT_NOT_CONFIRMED: "PAYMENT_NOT_CONFIRMED", //Уведомление о событии "Есть выплаты со статусом "Не подтверждено заказчиком""
    DEPOSIT_IN_RED_ZONE: "DEPOSIT_IN_RED_ZONE", //Уведомление о событии "Обнуление свободного остатка"
    NOT_RESIDENT_CONTRACT_SIGNED: "NOT_RESIDENT_CONTRACT_SIGNED", //Уведомление заказчика о подписании контракта с самозанятым-нерезидентом РФ
    NOT_RESIDENT_CONTRACT_TERMINATED: "NOT_RESIDENT_CONTRACT_TERMINATED", //Уведомление заказчика о расторжении контракта с самозанятым-нерезидентом РФ
    JOIN_CONTRACTOR_TO_ORDER: "JOIN_CONTRACTOR_TO_ORDER", //При отклике Исполнителя на заказ Заказчика
    ORDER_CLOSING: "ORDER_CLOSING", //При автоматическом закрытии заказак
    CHANGE_PERSONAL_DATA_CONTRACTOR: "CHANGE_PERSONAL_DATA_CONTRACTOR", //При изменение персональных данных исполнителя
    OPEN_DISPUTE: "OPEN_DISPUTE", //Уведомление при открытие нового спора по заказу
    CLOSE_DISPUTE: "CLOSE_DISPUTE", //Уведомление при закрытии спора по заказу
    CANCEL_DISPUTE: "CANCEL_DISPUTE", //Уведомление при отмене спора по заказу
    CHECK_OPEN_DISPUTE: "CHECK_OPEN_DISPUTE", //Уведомление наличие открытых споров по заказу между Исполнителем и Заказчиком
    TAXPAYER_UNREGISTERED: "TAXPAYER_UNREGISTERED", //Уведомление о снятии с учета НПД
    ADD_PARTIES_ON_DISPUTE: "ADD_PARTIES_ON_DISPUTE", //Уведомление при добавлении админа компании в спор
    CONTRACTOR_CHECK_INCOME: "CONTRACTOR_CHECK_INCOME", //Уведомление о достижении 2,3 млн годового дохода у исполнителя
    ORDERS_EXPIRED: "ORDERS_EXPIRED", //Уведомление о незакрытых заказов
    CONTRACTOR_WORK_WITH_ONE_CLIENT_MORE_SIX_MONTH: "CONTRACTOR_WORK_WITH_ONE_CLIENT_MORE_SIX_MONTH", //Уведомление о длительной работе с исполнителем
    CLIENT_SPECIALITY_OFFER_RESULT_DECISION: "CLIENT_SPECIALITY_OFFER_RESULT_DECISION",
    CLIENT_USER_ADD_TO_STAFF: "CLIENT_USER_ADD_TO_STAFF", // Уведомление о добавлении сотрудника компании в список кадров
    CLIENT_USER_REMOVE_FROM_STAFF: "CLIENT_USER_REMOVE_FROM_STAFF", // Уведомление о удалении сотрудника компании из списка кадров
    CANCELED_CHECKS_MONTH: "CANCELED_CHECKS_MONTH", // Уведомление об аннулированных чеках за месяц
    CANCELED_CHECKS_QUARTER: "CANCELED_CHECKS_QUARTER", // Уведомление об аннулированных чеках за квартал
    CONTRACTOR_PERSONAL_DATA_ACCESS_ABSENT: "CONTRACTOR_PERSONAL_DATA_ACCESS_ABSENT", // Запрос на доступ к персональным данным отклонен
    CONTRACTOR_PERSONAL_DATA_ACCESS_GRANTED: "CONTRACTOR_PERSONAL_DATA_ACCESS_GRANTED", // Запрос на доступ к персональным данным подтвержден
    PUBLISHING_REVIEW: "PUBLISHING_REVIEW", // Публикация отзыва
    NEW_CROWD_CHAT_MESSAGE: "NEW_CROWD_CHAT_MESSAGE", // Сообщение в чат по заданию
    PUBLISHING_SCORE_REPLY: "PUBLISHING_SCORE_REPLY", // Ответ на отзыв опубликован
    PUBLISHING_SCORE_REPLY_WITH_CORRECTION: "PUBLISHING_SCORE_REPLY_WITH_CORRECTION", // Ответ на отзыв опубликован
};

export const NOTIFICATION_SOURCE = {
    ADMIN_UI: "ADMIN_UI",
    NM_API: "NM_API",
    CLIENT_ADMIN_UI: "CLIENT_ADMIN_UI",
};