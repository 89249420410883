export const CROWD_CHAT_STATUS_FILTER_TYPE = {
    READ: "READ",
    UNREAD: "UNREAD",
    ALL : "ALL",
};

export const CROWD_CHAT_STATUS_FILTER_TYPE_DICT = {
    READ: "Прочитанные",
    UNREAD: "Непрочитанные",
    ALL : "Все",
};

export const CROWD_CHAT_MESSAGE_STATE = {
    READ: "READ",
    UNREAD: "UNREAD",
    INPUT: "INPUT",
};