import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";

import NmModal from "../../ActualComponents/NmModal";
import NmTextareaV2 from "../../ActualComponents/NmTextareaV2";
import ApplyButtons from "../../ApplyButtons";
import NmTitle from "../../NmTitle";

export const ModalTextareaEdit = (props) => {
    const {
        onClose,
        title,
        progressAction,
        initialValues,
        name,
        validations,
        onSubmit,
        required,
        maxLength = 500,
    } = props;

    const {
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        setFieldTouched,
    } = useFormik({
        initialValues,
        validationSchema: validations ?
            yup.object().shape({
                [name]: validations,
            }) : null,
        onSubmit,
    });

    return (
        <NmModal
            onClose={onClose}
            size="md"
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
        >
            <NmTextareaV2
                label="Комментарий"
                required={required}
                placeholder="Введите комментарий"
                name={name}
                maxLength={maxLength}
                minRows={3}
                value={values[name]}
                error={
                    touched &&
                    touched[name] &&
                    errors &&
                    errors[name] ?
                        errors[name] :
                        undefined
                }
                onBlur={() => setFieldTouched(name, true)}
                onChange={(event, {name, value}) => {
                    setFieldValue(name, value);
                }}
            />
        </NmModal>
    );
};
