import {isEqual} from "lodash";

import {getClientSettingsDepositAndPaymentsBlockBanksValues} from "./getBlockBanksValues";

export const isClientSettingsDepositAndPaymentsChanged = (params) => {
    const {
        values,
        card,
    } = params;

    const {
        objects,
        ..._values
    } = values;

    const {
        objects: _objects,
        ..._card
    } = card;

    if (Object.keys(_values).length !== Object.keys(_card).length) {
        return false;
    }

    return Object.keys(_values).some((name) => {
        if (
            [
                "smzMarketplace",
                "smzRegistry",
                "smzApi",
                "civilMarketplace",
                "civilRegistry",
                "civilApi",
                "individualMarketplace",
                "individualRegistry",
                "individualApi",
            ].includes(name)
        ) {
            const form = getClientSettingsDepositAndPaymentsBlockBanksValues(_values[name] || {});

            return !isEqual(form, card[name]);
        }

        return !isEqual(values[name], card[name]);
    });
};