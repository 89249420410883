export const MEMBER_POSITIONS = {
    DIRECTOR: {
        TEXT: "Руководитель",
        VALUE: "DIRECTOR",
    },
    ACCOUNTANT: {
        TEXT: "Главный бухгалтер",
        VALUE: "ACCOUNTANT",
    },
    MANAGER: {
        TEXT: "Менеджер",
        VALUE: "MANAGER",
    },
};

export const MEMBER_POSITIONS_OPTIONS = [
    {
        key: MEMBER_POSITIONS.DIRECTOR.VALUE,
        value: MEMBER_POSITIONS.DIRECTOR.VALUE,
        text: MEMBER_POSITIONS.DIRECTOR.TEXT,
    },
    {
        key: MEMBER_POSITIONS.ACCOUNTANT.VALUE,
        value: MEMBER_POSITIONS.ACCOUNTANT.VALUE,
        text: MEMBER_POSITIONS.ACCOUNTANT.TEXT,
    },
    {
        key: MEMBER_POSITIONS.MANAGER.VALUE,
        value: MEMBER_POSITIONS.MANAGER.VALUE,
        text: MEMBER_POSITIONS.MANAGER.TEXT,
    },
];

export const MEMBER_POSITIONS_DICT = {
    ACCOUNTANT: "Главный бухгалтер",
    DIRECTOR: "Руководитель",
    MANAGER: "Менеджер",
};

