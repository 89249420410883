import {useState} from "react";
import {useDispatch} from "react-redux";

import {
    changeStatusContractorScoresReply,
    confirmContractorScore,
    declineContractorScore,
    deleteContractorScore,
    updateContractorScore,
} from "../../../ducks/contractorScores";
import {
    changeStatusClientFeedbackReply,
    confirmClientFeedback,
    declineClientFeedback,
    deleteClientFeedback,
    updateClientFeedback,
} from "../../../ducks/scores";

const initialStateConfirmModal = {
    isOpen: false,
};

export function useReviewsAction({fetchList, subpage}) {
    const [confirmAction, setConfirmAction] = useState(initialStateConfirmModal);
    const [editData, setEditData] = useState({});

    const dispatch = useDispatch();

    const onConfirmScore = (contractorScore, id) => {
        const onSuccess = () => {
            fetchList();
            closeConfirmModal();
        };

        if (contractorScore) {
            dispatch(confirmContractorScore({
                id,
                onSuccess,
            }));

            return;
        }

        dispatch(confirmClientFeedback({
            id,
            onSuccess,
        }));
    };

    const onDeleteScore = (contractorScore, id) => {
        const onSuccess = () => {
            fetchList();
            closeConfirmModal();
        };

        if (contractorScore) {
            dispatch(deleteContractorScore({
                id,
                onSuccess,
            }));

            return;
        }

        dispatch(deleteClientFeedback({
            id,
            onSuccess,
        }));
    };

    const onDeclineScore = (contractorScore, id) => {
        const onSuccess = () => {
            fetchList();
            closeConfirmModal();
        };

        if (contractorScore) {
            dispatch(declineContractorScore({
                id,
                onSuccess,
            }));

            return;
        }

        dispatch(declineClientFeedback({
            id,
            onSuccess,
        }));
    };

    const changeStatusScoreReply = (contractorScore, reviewId, status) => {
        const reqData = {
            reviewId,
            status,
            onSuccess: () => {
                fetchList();
                closeConfirmModal();
            },
        };

        if (contractorScore) {
            dispatch(changeStatusContractorScoresReply(reqData));

            return;
        }

        dispatch(changeStatusClientFeedbackReply(reqData));
    };

    const updateScore = ({reviewText}) => {
        const {contractorScore, id} = editData;

        const onSuccess = () => {
            fetchList();
            setEditData({});
        };

        if (contractorScore) {
            dispatch(updateContractorScore({
                reviewId: id,
                reviewText,
                onSuccess,
            }));

            return;
        }

        dispatch(updateClientFeedback({
            reviewId: id,
            reviewText,
            onSuccess,
        }));
    };

    const closeConfirmModal = () => {
        setConfirmAction(initialStateConfirmModal);
    };

    const onClickEditReview = ({baseModel, contractorScore, contractor, client}) => {
        setEditData({
            titleModal: `Отзыв ${contractorScore ? client.name : contractor.fioOrFullName}`,
            contractorScore,
            ...baseModel,
        });
    };

    const closeEditModal = () => {
        setEditData({});
    };

    return {
        confirmAction,
        setConfirmAction,
        editData,
        setEditData,
        closeConfirmModal,
        onConfirmScore,
        onDeleteScore,
        onDeclineScore,
        updateScore,
        onClickEditReview,
        closeEditModal,
        changeStatusScoreReply,
    };
}