import React from "react";
import {Link} from "react-router-dom";

import NmButton from "../../NmButton";

import bem from "../../../utils/bem";

import "./style.sass";

export type TNmEmptyPageV2 = {
    className?: string,
    isSearch?: boolean,
    title?: string,
    description?: string,
    textAction?: string,
    onClickAction?: () => void,
    textLink?: string,
    patchLink?: string,
    disabledAction?: boolean,
    classNameEmptyPage?: string,
    clearStyles?: boolean,
    isShowAction?: boolean,
    isBlocked?: boolean,
    warningText?: string,
    fetchProgress?: boolean,
    fetchError?: boolean,
}

const NmEmptyPageV2: React.FC<TNmEmptyPageV2> = (props: TNmEmptyPageV2) => {
    const {
        className = "",
        isSearch,
        title = "Данные отсутствуют",
        description,
        warningText,
        textAction,
        onClickAction,
        disabledAction,
        clearStyles,
        textLink,
        isBlocked,
        patchLink,
        classNameEmptyPage = "",
        isShowAction = true,
        fetchProgress = false,
        fetchError = false,
    } = props;

    const [block, element] = bem("nm-empty-page-v2", className);

    const renderEmptyPage = () => {
        return (
            !fetchProgress &&
            <div className={`${element("empty-page", {clearStyles})} ${classNameEmptyPage}`}>
                <div className={element("search-off", {blocked: isBlocked})} />
                <div
                    className={element("title", {error: fetchError})}
                    children={
                        fetchError ?
                            "Произошла проблема при загрузке данных. Попробуйте позднее."
                            : title
                    }
                />
                {
                    description &&
                    <div
                        className={element("description")}
                        children={description}
                    />
                }
                {
                    warningText &&
                    <div
                        className={element("description")}
                        children={warningText}
                    />
                }
                {
                    textAction && isShowAction &&
                    <NmButton
                        size="xl"
                        disabled={disabledAction}
                        onClick={onClickAction}
                        children={textAction}
                        className={element("action")}
                    />
                }
                {
                    textLink &&
                    <Link
                        children={textLink}
                        to={patchLink || ""}
                        className={element("link")}
                    />
                }
            </div>
        );
    };

    const renderEmptySearch = () => {
        return (
            <div className={element("empty-search")}>
                <div className={element("empty-search-title")}>
                    Поиск не дал результатов:
                </div>
                <ul className={element("empty-search-list")}>
                    <li>
                        Проверьте, правильно ли указаны параметры запроса, нет ли опечаток
                    </li>
                    <li>
                        Попробуйте использовать более общий запрос
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className={block()}>
            {
                isSearch ? renderEmptySearch() : renderEmptyPage()
            }
        </div>
    );
};

export default NmEmptyPageV2;