import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/orderkeywords";
//*  TYPES  *//

const GET_PAGE_KEYWORDS_REQUEST = "GET_PAGE_KEYWORDS_REQUEST";
const GET_PAGE_KEYWORDS_SUCCESS = "GET_PAGE_KEYWORDS_SUCCESS";
const GET_PAGE_KEYWORDS_ERROR = "GET_PAGE_KEYWORDS_ERROR";

const ADD_KEYWORD_REQUEST = "ADD_KEYWORD_REQUEST";
const ADD_KEYWORD_SUCCESS = "ADD_KEYWORD_SUCCESS";
const ADD_KEYWORD_ERROR = "ADD_KEYWORD_ERROR";

const UPDATE_KEYWORD_REQUEST = "UPDATE_KEYWORD_REQUEST";
const UPDATE_KEYWORD_SUCCESS = "UPDATE_KEYWORD_SUCCESS";
const UPDATE_KEYWORD_ERROR = "UPDATE_KEYWORD_ERROR";

const DELETE_KEYWORD_REQUEST = "DELETE_KEYWORD_REQUEST";
const DELETE_KEYWORD_SUCCESS = "DELETE_KEYWORD_SUCCESS";
const DELETE_KEYWORD_ERROR = "DELETE_KEYWORD_ERROR";

const GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST = "GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST";
const GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS = "GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS";
const GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR = "GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR";

const UPDATE_FIELD_KEYWORDS = "UPDATE_FIELD_KEYWORDS";


//*  INITIAL STATE  *//

const initial = {
    pageData: {},
    progressPage: false,
    totalCount: 0,
    keywordsList: [],
    autocompleteKeywords: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_PAGE_KEYWORDS_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case GET_PAGE_KEYWORDS_SUCCESS:
        const {
            results: keywordsList,
            totalCount,
        } = payload;

        return {
            ...state,
            progressPage: false,
            totalCount,
            keywordsList,
        };
    case GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS:
        const {
            keywords: autocompleteKeywords,
        } = payload;
        return {
            ...state,
            autocompleteKeywords,
        };
    case UPDATE_FIELD_KEYWORDS:
        return {
            ...state,
            ...payload,
        };
    case GET_PAGE_KEYWORDS_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getKeywordsPage(payload) {
    return {
        type: GET_PAGE_KEYWORDS_REQUEST,
        payload,
    };
}

export function getKeywordsAutocompletePage(payload) {
    return {
        type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST,
        payload,
    };
}

export function updateKeyword(payload) {
    return {
        type: UPDATE_KEYWORD_REQUEST,
        payload,
    };
}

export function addKeyword(payload) {
    return {
        type: ADD_KEYWORD_REQUEST,
        payload,
    };
}

export function deleteKeyword(payload) {
    return {
        type: DELETE_KEYWORD_REQUEST,
        payload,
    };
}

export function updateFieldKeyword(payload) {
    return {
        type: UPDATE_FIELD_KEYWORDS,
        payload,
    };
}

//*  SELECTORS  *//
export const keywordSelector = state => state.keyword;
export const getKeywordTotalPagesSelector = createSelector(keywordSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getKeywordTotalCountSelector = createSelector(keywordSelector, ({totalCount}) => totalCount);
export const getKeywordsListSelector = createSelector(keywordSelector, ({keywordsList}) => keywordsList);
export const getKeywordsOptionsSelector = createSelector(keywordSelector, ({autocompleteKeywords}) => {
    return autocompleteKeywords.map(item => ({
        key: item.keywordUuid,
        value: item.keyword,
        text: item.keyword,
    }));
});

//*  SAGA  *//

export const getKeywordsPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_KEYWORDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PAGE_KEYWORDS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAGE_KEYWORDS_ERROR, payload: error});
    }
};

export const getKeywordsAutocompletePageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/autocompletePage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS, payload: result || []});
    } catch (error) {
        yield put({type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR, payload: error});
    }
};

export const addKeywordSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_KEYWORD_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        const {keyword} = payload;
        toastSuccess(`Ключевое слово "${keyword}" успешно добавлено`);

        const state = yield select();

        yield put(getKeywordsPage(state.keyword.pageData));

        yield put({type: ADD_KEYWORD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_KEYWORD_ERROR, payload: error});
    }
};

export const updateKeywordSaga = function* (action) {
    try {
        const {
            payload: {
                keywordUuid,
                keyword,
            },
        } = action;

        const body = {
            keyword,
        };

        const result = yield request.patch(`${controller}/${keywordUuid}`, body);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: UPDATE_KEYWORD_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");

        const state = yield select();

        yield put(getKeywordsPage(state.keyword.pageData));

        yield put({type: UPDATE_KEYWORD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_KEYWORD_ERROR, payload: error});
    }
};

export const deleteKeywordSaga = function* (action) {
    try {
        const {
            payload: {
                keywordUuid,
            },
        } = action;

        const result = yield request.delete(`${controller}/${keywordUuid}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DELETE_KEYWORD_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Ключевое слово успешно удалено");

        const state = yield select();

        yield put(getKeywordsPage(state.keyword.pageData));

        yield put({type: DELETE_KEYWORD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_KEYWORD_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_KEYWORDS_REQUEST, getKeywordsPageSaga),
        takeEvery(UPDATE_KEYWORD_REQUEST, updateKeywordSaga),
        takeEvery(DELETE_KEYWORD_REQUEST, deleteKeywordSaga),
        takeEvery(ADD_KEYWORD_REQUEST, addKeywordSaga),
        takeEvery(GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST, getKeywordsAutocompletePageSaga),
    ]);
}
