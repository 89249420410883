export const PROJECT_FIELD_NAME = {
    PROJECT_ID: "projectId",
    CLIENT_ID: "clientId",
    NAME: "name",
    DESCRIPTION: "description",
    BALANCE: "balanceAmount",
    AMOUNT: "amount",
    UNLIMITED: "unlimited",
    OPTIMUM_PROJECT_ID: "optimumProjectId",
};

export const PROJECT_STATUS_DICT = {
    IN_WORK: {
        VALUE: "IN_WORK",
        TEXT: "В работе",
        COLOR: "#3C9C3B",
        BACKGROUND: "#E9F4E5",
        MOD: "light-green",
    },
    CLOSE: {
        VALUE: "CLOSE",
        TEXT: "Закрыт",
        COLOR: "#555555",
        BACKGROUND: "#F1F3F7",
        MOD: "gray",
    },
};

export const PROJECT_STATUS_OPTIONS = [
    {
        key: "ALL",
        value: "ALL",
        text: "Все",
    },
    {
        key: PROJECT_STATUS_DICT.IN_WORK.VALUE,
        value: PROJECT_STATUS_DICT.IN_WORK.VALUE,
        text: PROJECT_STATUS_DICT.IN_WORK.TEXT,
    },
    {
        key: PROJECT_STATUS_DICT.CLOSE.VALUE,
        value: PROJECT_STATUS_DICT.CLOSE.VALUE,
        text: PROJECT_STATUS_DICT.CLOSE.TEXT,
    },
];
export const PROJECT_ARCHIVED_STATUS_OPTIONS = [
    {
        key: PROJECT_STATUS_DICT.CLOSE.VALUE,
        value: PROJECT_STATUS_DICT.CLOSE.VALUE,
        text: PROJECT_STATUS_DICT.CLOSE.TEXT,
    },
];

export const PROJECT_ACTION_DICT = {
    EDIT_PROJECT: {
        VALUE: "edit",
        TEXT: "Редактировать проект",
    },
    OPEN_PROJECT: {
        VALUE: "openProject",
        TEXT: "Открыть проект",
        CONFIRM_KEY: "projects.open-confirm-text",
    },
    ADD_OBJECT: {
        VALUE: "addObject",
        TEXT: "Добавить объект",
    },
    CLOSE_PROJECT: {
        VALUE: "close",
        TEXT: "Закрыть проект",
        CONFIRM_KEY: "projects.close-confirm-text",
    },
    ARCHIVE_PROJECT: {
        VALUE: "archive",
        TEXT: "В архив",
        CONFIRM_KEY: "projects.archive-confirm-text",
    },
    FROM_ARCHIVE_PROJECT: {
        VALUE: "formArchive",
        TEXT: "Из архива",
        CONFIRM_KEY: "projects.from-archive-confirm-text",
    },
    IMPORT_DEPOSIT: {
        VALUE: "importDeposit",
        TEXT: "Пополнить депозит объектов из файла",
    },
};

export const PROJECT_ACTION_OPTIONS = {
    [PROJECT_STATUS_DICT.CLOSE.VALUE]: [
        {
            key: PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE,
            value: PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE,
            text: PROJECT_ACTION_DICT.EDIT_PROJECT.TEXT,
        },
        {
            key: PROJECT_ACTION_DICT.OPEN_PROJECT.VALUE,
            value: PROJECT_ACTION_DICT.OPEN_PROJECT.VALUE,
            text: PROJECT_ACTION_DICT.OPEN_PROJECT.TEXT,
        },
        {
            key: PROJECT_ACTION_DICT.ARCHIVE_PROJECT.VALUE,
            value: PROJECT_ACTION_DICT.ARCHIVE_PROJECT.VALUE,
            text: PROJECT_ACTION_DICT.ARCHIVE_PROJECT.TEXT,
        },
    ],
    [PROJECT_STATUS_DICT.IN_WORK.VALUE]: [
        {
            key: PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE,
            value: PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE,
            text: PROJECT_ACTION_DICT.EDIT_PROJECT.TEXT,
        },
        {
            key: PROJECT_ACTION_DICT.ADD_OBJECT.VALUE,
            value: PROJECT_ACTION_DICT.ADD_OBJECT.VALUE,
            text: PROJECT_ACTION_DICT.ADD_OBJECT.TEXT,
        },
        {
            key: PROJECT_ACTION_DICT.CLOSE_PROJECT.VALUE,
            value: PROJECT_ACTION_DICT.CLOSE_PROJECT.VALUE,
            text: PROJECT_ACTION_DICT.CLOSE_PROJECT.TEXT,
        },
    ],
};

export const PROJECT_IMPORT_FILE_TYPE = {
    IMPORT_PROJECT_LIST: "IMPORT_PROJECT_LIST",
};

export const PROJECT_ARCHIVED_OPTIONS = [
    {
        key: PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.VALUE,
        value: PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.VALUE,
        text: PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.TEXT,
    },
];