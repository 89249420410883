import * as yup from "yup";

import {removePhoneMask} from "../../../../../../utils/stringFormat";

import {emailReg} from "../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

import {
    TEMPLATE_DOCUMENT,
} from "../../../../../../constants/document-management/document-list";

const validationSchema = () => {
    return yup.object().shape({
        receiverPhone: yup.string()
            .when("isAddMissingContractor", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test("checkPhone", "Номер телефона должен состоять из 11 цифр", function (value) {
                        const mapValue = removePhoneMask(value);

                        return mapValue?.length === 11;
                    }),
            }),
        documentDatetime: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        documentName: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        params: yup.object()
            .nullable()
            .when("useTemplateFile", {
                is: true,
                then: yup.object().shape(
                    {
                        type: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                        documentNumber: yup.string()
                            .nullable()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.TRADE_SECRET_NONDISCLOSURE_OBLIGATION,
                                        TEMPLATE_DOCUMENT.CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8,
                                        TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        targets: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA,
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        personalData: yup.mixed()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA,
                                then: yup.array().min(1, VALIDATIONS_MESSAGE.REQUIRED).of(yup.string()),
                            }),
                        documentSubject: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        documentPlace: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        startWorkDate: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        contractDate: yup.string()
                            .nullable()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        contractNumber: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                        TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        endWorkDate: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        periodFrom: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        periodTo: yup.string()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                then: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        platformName: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                        TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        paymentCurrency: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        email: yup.string()
                            .when("type", {
                                is: (value) => {
                                    return [
                                        TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY,
                                    ].includes(value);
                                },
                                then: yup.string()
                                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                                    .matches(emailReg, {
                                        message: VALIDATIONS_MESSAGE.INCORRECT_EMAIL,
                                        excludeEmptyString: false,
                                    })
                                    .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                            }),
                        jobList: yup.array()
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED,
                                then: yup.array()
                                    .of(
                                        yup.object().shape({
                                            count: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                                            price: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                                            workName: yup.string()
                                                .required(VALIDATIONS_MESSAGE.REQUIRED)
                                                .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                                        }),
                                    ),
                            })
                            .when("type", {
                                is: TEMPLATE_DOCUMENT.ACT_CIVIL_DEED,
                                then: yup.array()
                                    .of(
                                        yup.object().shape({
                                            count: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                                            price: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                                            linkToResult: yup.string()
                                                .required(VALIDATIONS_MESSAGE.REQUIRED)
                                                .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                                            workName: yup.string()
                                                .required(VALIDATIONS_MESSAGE.REQUIRED)
                                                .test("checkSymbol", VALIDATIONS_MESSAGE.EDO_INCORRECT_SYMBOL_PDF, checkSymbolInput),
                                        }),
                                    ),
                            }),

                    },
                ),
            }),
    });
};

export default validationSchema;

const checkSymbolInput = (value) => {
    if (value && value.includes(">")) {
        return false;
    }

    return !(value && value.includes("<"));
};