import {
    BFF_CROWD_PAYMENT_ADD_ERROR,
    BFF_CROWD_PAYMENT_ADD_REQUEST,
    BFF_CROWD_PAYMENT_ADD_SUCCESS,
    BFF_CROWD_PAYMENT_APPROVE_ERROR,
    BFF_CROWD_PAYMENT_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_APPROVE_SUCCESS,
    BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS,
    BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
    BFF_CROWD_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
    BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
    BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS,
    BFF_CROWD_PAYMENT_REJECT_ERROR,
    BFF_CROWD_PAYMENT_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_REJECT_SUCCESS,
    BFF_CROWD_PAYMENT_REPEAT_ERROR,
    BFF_CROWD_PAYMENT_REPEAT_REQUEST,
    BFF_CROWD_PAYMENT_REPEAT_SUCCESS,
    UPDATE_CROWD_PAYMENT_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    card: {},
    totalAmount: 0,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_CROWD_PAYMENT_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_CROWD_PAYMENT_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST: {
        return {
            ...state,
            logPageData: payload,
            logProgress: true,
        };
    }
    case BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS: {
        return {
            ...state,
            logList: payload.results,
            logTotalCount: payload.totalCount,
            logProgress: false,
        };
    }
    case BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR: {
        return {
            ...state,
            logProgress: false,
        };
    }
    case BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS: {
        return {
            ...state,
            totalAmount: payload.result || 0,
        };
    }
    case BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS: {
        return {
            ...state,
            card: payload,
        };
    }
    case BFF_CROWD_PAYMENT_REPEAT_REQUEST:
    case BFF_CROWD_PAYMENT_APPROVE_REQUEST:
    case BFF_CROWD_PAYMENT_ADD_REQUEST:
    case BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST:
    case BFF_CROWD_PAYMENT_REJECT_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_CROWD_PAYMENT_REPEAT_SUCCESS:
    case BFF_CROWD_PAYMENT_REPEAT_ERROR:
    case BFF_CROWD_PAYMENT_APPROVE_SUCCESS:
    case BFF_CROWD_PAYMENT_APPROVE_ERROR:
    case BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS:
    case BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR:
    case BFF_CROWD_PAYMENT_ADD_SUCCESS:
    case BFF_CROWD_PAYMENT_ADD_ERROR:
    case BFF_CROWD_PAYMENT_REJECT_SUCCESS:
    case BFF_CROWD_PAYMENT_REJECT_ERROR:
    case BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS:
    case BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR:
    case BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS:
    case BFF_CROWD_PAYMENT_MASS_REJECT_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case UPDATE_CROWD_PAYMENT_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};