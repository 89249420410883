import React from "react";
import {withTranslation} from "react-i18next";

import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import Text from "../../../../components/ActualComponents/Text";

import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {COLOR} from "../../../../constants/color";
import {ADMIN} from "../../../../constants/roles";

import PropTypes from "prop-types";

const OrderListAmountCalculate = (props) => {
    const {
        role,
        data: {
            amount = 0,
            awaitingConfirmationTotalAmount = 0,
            notConfirmedTotalAmount = 0,
            inProgressTotalAmount = 0,
            paidSum = 0,
            paymentErrorTotalAmount = 0,
            remainderForPaymentsAmount = 0,
            limitSpentAmount = 0,
        },
        t,
    } = props;

    const getCards = () => {
        const allCards = [
            {
                title: t("order-list.sumOfSelectedOrders"),
                value: `${formatAmountWithNullChecking(amount)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("order-list.paymentsAgreeContractor"),
                value: `${formatAmountWithNullChecking(awaitingConfirmationTotalAmount)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("order-list.paymentsAgreeCustomer"),
                value: `${formatAmountWithNullChecking(notConfirmedTotalAmount)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("order-list.paymentsInProcess"),
                value: `${formatAmountWithNullChecking(inProgressTotalAmount)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("order-list.paymentPaid"),
                value: `${formatAmountWithNullChecking(paidSum)} ₽`,
                className: "col-16 col-xxl-2",
            },
        ];

        const cardError = {
            title: t("order-list.paymentsError"),
            value: `${formatAmountWithNullChecking(paymentErrorTotalAmount)} ₽`,
            className: "col-16 col-xxl-2",
        };

        const newCards = [
            {
                title: t("order-list.remainingForPayments"),
                value: `${formatAmountWithNullChecking(remainderForPaymentsAmount)} ₽`,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("order-list.limitSpent"),
                value: `${formatAmountWithNullChecking(limitSpentAmount)} ₽`,
                className: "col-16 col-xxl-2",
            },
        ];

        if (role === ADMIN) {
            return [
                ...allCards,
                cardError,
                ...newCards,
            ];
        }

        return [
            ...allCards,
            ...newCards,
        ];
    };

    const cards = getCards();

    return (
        <div className="registry-list__details-item">
            <Text
                className="mb-2"
                medium
                level="2"
                color={COLOR.SECONDARY_70}
                noWrap
            >
                Детализация по выбранным заказам
            </Text>
            <NmPageInfoCardsAccordion
                desktopViewFrom="xxl"
                bootstrap={true}
                cards={cards}
            />
        </div>
    );
};

OrderListAmountCalculate.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    role: PropTypes.string,
};

export default withTranslation()(OrderListAmountCalculate);