import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import {VerticalMovingList} from "../../../../../../components/ActualComponents/VerticalMovingList";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import {
    bffRecruitmentDirectoryProgressActionSelector,
    bffRecruitmentDirectoryStatusListProgressSelector,
} from "../../../../../../ducks/bff/recruitment/directory/selectors";

import useRecruitmentDirectoriesFunnelEditForm from "./hooks/useForm";

const RecruitmentDirectoriesFunnelEditForm = (props) => {
    const {
        onClose = () => {
        },
        fetchList,
        editData,
        clientId,
    } = props;

    const progressAction = useSelector(bffRecruitmentDirectoryProgressActionSelector);
    const progress = useSelector(bffRecruitmentDirectoryStatusListProgressSelector);

    const isEdit = !isEmpty(editData);

    const {
        handleSubmit,
        values,
        handleChange,
        touched,
        errors,
        statusOptions,
        addItemStatusList,
    } = useRecruitmentDirectoriesFunnelEditForm({
        onClose,
        fetchList,
        isEdit,
        editData,
        clientId,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование воронки" : "Добавление воронки"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                />
            }
            loading={progressAction}
        >
            <NmForm addMargin>
                <NmInputV2
                    required
                    size="xl"
                    label="Название воронки"
                    placeholder="Введите название"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    maxLength={100}
                    error={touched.name && errors.name}
                />
                <NmTitle size="md">
                    Последовательность статусов в воронке
                </NmTitle>
                <VerticalMovingList
                    list={values.statusList}
                    name="statusList"
                    onChange={handleChange}
                />
                <div className="row gx-4">
                    <div className="col-16 col-md-12">
                        <NmDropdownV2
                            size="xl"
                            disabled={progress}
                            options={statusOptions}
                            name="selectedStatus"
                            value={values.selectedStatus}
                            onChange={handleChange}
                            placeholder="Выберите из списка"
                        />
                    </div>
                    <div className="col-16 col-md-4 mt-3 mt-md-0">
                        <NmButton
                            disabled={!values.selectedStatus}
                            size="xl"
                            className="col-16"
                            color="light-green"
                            onClick={addItemStatusList}
                        >
                            Добавить
                        </NmButton>
                    </div>
                </div>
            </NmForm>
        </NmModal>
    );
};

export default RecruitmentDirectoriesFunnelEditForm;