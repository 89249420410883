import * as yup from "yup";

const validationSchema = () => {
    return yup.object().shape({
        "employmentHistoryNum": yup.string()
            .nullable()
            .max(50, "Не более 50 символов"),
    });
};

export default validationSchema;