import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import {getFullName, phoneFormat} from "../../../../utils/stringFormat";
import validate from "../../../../utils/validate";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {registryNotificationDataRule} from "../../../../constants/validationRules";

import {
    addRegistryFrameContractItems,
    updateRegistryFrameContractItems,
} from "../../../../ducks/documents";
import {registriesActionRegistryProgressSelector} from "../../../../ducks/registry";

import PropTypes from "prop-types";

import "./style.sass";


class RegistryFrameContractNotificationData extends Component {
    static propTypes = {
        notificationObj: PropTypes.object,
        contractorObj: PropTypes.object,
        registryId: PropTypes.string,
        clientId: PropTypes.string,
        isEdit: PropTypes.bool,
        close: PropTypes.func,
        onSuccess: PropTypes.func,
    };

    static defaultProps = {
        notificationObj: {},
        contractorObj: {},
        clientId: "",
        registryId: "",
        close: () => {
        },
        onSuccess: () => {
        },
    };

    constructor(props) {
        super(props);

        const {notificationObj} = props;

        this.state = {
            notificationObj: {
                ...notificationObj,
            },
            error: {},
        };
    }

    handleChange = (e, {name, value, checked}) => {
        this.setState(prevState => ({
            ...prevState,
            notificationObj: {
                ...prevState.notificationObj,
                [name]: value || checked,
            },
        }));
    };

    handleOnChangeAddress(name) {
        return ({value}) => {
            this.handleChange(null, {value, name});
        };
    };

    checkIsValid() {
        const {
            notificationObj,
        } = this.state;

        const {
            contractorObj: {
                contractorCitizenship,
                locatedOutsideRussia,
            },
            ovmRequired,
            isIndividualEntrepreneurRegistry,
        } = this.props;

        const isResident = [citizenshipsDict.RU.value].includes(contractorCitizenship) || isEmpty(contractorCitizenship);

        if (isResident || !ovmRequired || locatedOutsideRussia || isIndividualEntrepreneurRegistry) {
            return true;
        }

        const error = validate(notificationObj, registryNotificationDataRule, "");

        this.setState({error});

        return Object.values(error).length === 0;
    }

    submit = () => {
        if (!this.checkIsValid()) {
            return;
        }

        const {
            notificationObj,
        } = this.state;

        const {
            clientId,
            registryId,
            isEdit,
            contractorObj: {
                contractorId,
                seqNum,
            },
            onSuccess,
        } = this.props;

        const {
            ovmUnitName,
            speciality,
            workAddress,
        } = notificationObj;

        if (isEdit) {
            const {updateRegistryFrameContractItems} = this.props;

            updateRegistryFrameContractItems({
                data: {
                    registryId,
                    clientId,
                    contractorId,
                    ovmUnitName,
                    speciality,
                    workAddress,
                    seqNum,
                }, onSuccess: onSuccess,
            });

            return;
        }

        const {addRegistryFrameContractItems} = this.props;

        addRegistryFrameContractItems({
            data: {
                registryId,
                clientId,
                contractorId,
                ovmUnitName,
                speciality,
                workAddress,
            }, onSuccess: onSuccess,
        });
    };

    render() {
        const {
            isIndividualEntrepreneurRegistry,
            actionRegistryProgress,
            contractorObj: {
                contractorLastName,
                contractorFirstName,
                contractorPatronymic,
                fullName,
                phone,
                contractorCitizenship,
                locatedOutsideRussia,
            },
            isEdit,
            close,
            t,
        } = this.props;

        const {
            error,
            notificationObj:
                {
                    speciality,
                    workAddress,
                    ovmUnitName,
                },
        } = this.state;

        const isNotResident = ![citizenshipsDict.RU.value].includes(contractorCitizenship) && !isEmpty(contractorCitizenship);

        return (
            !isNotResident || locatedOutsideRussia || isIndividualEntrepreneurRegistry ?
                <NmConfirmV2
                    content={`Вы действительно хотите добавить исполнителя ${contractorLastName ? getFullName(contractorLastName, contractorFirstName, contractorPatronymic) : fullName} в реестр на подписание рамочных договоров?`}
                    onCancel={close}
                    onConfirm={this.submit}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                /> :
                <NmModal
                    onClose={close}
                    header={
                        <NmTitle size="lg">
                            {t("registry-frame-contract-data.title")}
                        </NmTitle>
                    }
                    className="registry-frame-contract-notification-data"
                    size="md"
                    footer={
                        <ApplyButtons
                            onClose={close}
                            submit={this.submit}
                            cancelBtnContent={t("button.cancel")}
                            loading={actionRegistryProgress}
                            disabled={actionRegistryProgress}
                            submitBtnContent={isEdit ? t("button.save") : t("button.add")}
                        />
                    }
                >
                    <NmForm>
                        <div className="registry-frame-contract-notification-data__full-name">
                            {contractorLastName ? getFullName(contractorLastName, contractorFirstName, contractorPatronymic) : fullName}
                        </div>
                        <div className="registry-frame-contract-notification-data__number">
                            {phoneFormat(phone)}
                        </div>
                        <p className="registry-frame-contract-notification-data__warning-msg">
                            <span className="registry-frame-contract-notification-data__warning-msg-attention">
                                {t("nm-confirm.attention")}
                            </span>
                            {t("registry-frame-contract-data.warning-msg")}
                        </p>
                        <div className="registry-frame-contract-notification-data__label">
                            {t("registry-frame-contract-data.ovmName")}
                        </div>
                        <NmTextareaV2
                            placeholder="Введите наименование"
                            size="xl"
                            name="ovmUnitName"
                            error={error.ovmUnitName}
                            minLength={1}
                            maxLength={100}
                            onChange={(e) => {
                                this.handleChange(e, {name: "ovmUnitName", value: e.target.value});
                            }}
                            value={ovmUnitName}
                        />
                        <div className="registry-frame-contract-notification-data__label">
                            {t("registry-frame-contract-data.specialty")}
                        </div>
                        <NmTextareaV2
                            placeholder="Введите вид деятельности"
                            size="xl"
                            name="speciality"
                            error={error.speciality}
                            minLength={1}
                            maxLength={100}
                            onChange={(e) => {
                                this.handleChange(e, {name: "speciality", value: e.target.value});
                            }}
                            value={speciality}
                        />
                        <div className="registry-frame-contract-notification-data__label">
                            {t("registry-frame-contract-data.address")}
                        </div>
                        <NmDadataInput
                            placeholder="Введите адрес"
                            query={workAddress || ""}
                            name="workAddress"
                            error={error.workAddress}
                            onChange={this.handleOnChangeAddress("workAddress")}
                        />
                    </NmForm>
                </NmModal>
        );
    }
}

export default connect(
    state => ({
        actionRegistryProgress: registriesActionRegistryProgressSelector(state),
    }),
    {
        addRegistryFrameContractItems,
        updateRegistryFrameContractItems,
    },
)(withTranslation()(RegistryFrameContractNotificationData));