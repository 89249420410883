import React from "react";
import {Route, Switch, withRouter} from "react-router";

import DocumentManagementDictionariesStructuralUnits from "../document-management-dictionaries-structural-units";
import DocumentManagementPositions from "../document-management-positions";

import {
    LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT,
} from "../../../../constants/links";

const  DocumentManagementDictionariesRoute = (props) => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT}
                component={DocumentManagementPositions}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT}
                component={DocumentManagementDictionariesStructuralUnits}
            />
        </Switch>
    );
};

export default withRouter(DocumentManagementDictionariesRoute);