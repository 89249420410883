import React from "react";
import {Route, Switch} from "react-router";

import ClientRecruitmentDirectoriesFunnels from "../funnels";
import ClientRecruitmentDirectoriesJobRequirements from "../job-requirements";
import ClientRecruitmentDirectoriesRejectionReasons from "../rejection-reasons";
import ClientRecruitmentDirectoriesStatuses from "../statuses";

import {
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES,
} from "../../../../constants/links";

const ClientRecruitmentDirectoriesRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS}
                component={ClientRecruitmentDirectoriesFunnels}
            />
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES}
                component={ClientRecruitmentDirectoriesStatuses}
            />
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS}
                component={ClientRecruitmentDirectoriesJobRequirements}
            />
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS}
                component={ClientRecruitmentDirectoriesRejectionReasons}
            />
        </Switch>
    );
};

export default ClientRecruitmentDirectoriesRoute;