import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../../../../components/ProjectsObjectsCheckboxList/constants";

export const bffCommonTreeSelector = state => state.bff.commonTree;
export const bffCommonTreeErrorSelector = createSelector(bffCommonTreeSelector, ({error}) => error);
export const bffCommonTreePaymentsProgressActionSelector = createSelector(bffCommonTreeSelector, ({progressAction}) => progressAction);
export const bffCommonTreePaymentsProgressSelector = createSelector(bffCommonTreeSelector, ({progress}) => progress);
export const bffCommonTreeProjectsSelector = createSelector(bffCommonTreeSelector, ({projects}) => {    return {
    ...projects,
    list: projects.list.map(item => {
        return {
            ...item,
            boolParam: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
            isPartial: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
        };
    }),
    totalPages: getTotalPages(projects.totalCount, projects.pageData.pageSize),
};
});
export const bffCommonTreeObjectsSelector = createSelector(bffCommonTreeSelector, ({objects}) => {
    return objects;
});
export const bffCommonTreeProjectsAndObjectsSelector = createSelector(bffCommonTreeSelector, ({projectsAndObjects}) => projectsAndObjects);