import {useCallback, useEffect, useState} from "react";
import {useMedia} from "react-media";
import {debounce} from "lodash";

// падинги, отступы, скролл - ширина, которую не учитываем в ширине пдф
const OFFSET_WIDTH = 74;

export const useDetectPdfWidth = () => {
    const isMobile = useMedia({query: {maxWidth: 767}});
    const [width, setWidth] = useState(0);

    const _setWidth = useCallback(debounce(() => {
        setWidth && setWidth(width);
    }, 300), [window.innerWidth]);

    useEffect(() => {
        window.addEventListener("resize", _setWidth);

        return () => window.removeEventListener("resize", _setWidth);
    }, []);


    if (!isMobile) {
        return {
            width: undefined,
        };
    }

    const getWidth = () => {
        const _width = width || window.innerWidth;

        return _width - OFFSET_WIDTH;
    };

    return {
        width: getWidth(),
    };
};