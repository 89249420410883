export const REVIEW_STATUS = {
    DRAFT: {
        TEXT: "На рассмотрении",
        VALUE: "DRAFT",
        MOD: "orange",
    },
    PUBLISHED: {
        TEXT: "Подтвержден",
        VALUE: "PUBLISHED",
        MOD: "green",
    },
    DECLINED: {
        TEXT: "Отклонен",
        VALUE: "DECLINED",
        MOD: "red",
    },
};

export const REVIEW_REPLY_STATUS = {
    DRAFT: "DRAFT",
    PUBLISHED: "PUBLISHED",
    DECLINED: "DECLINED",
};

export const REVIEW_REPLY_STATUS_DICT = {
    [REVIEW_REPLY_STATUS.DRAFT]: "На рассмотрении",
    [REVIEW_REPLY_STATUS.PUBLISHED]: "Подтвержден",
    [REVIEW_REPLY_STATUS.DECLINED]: "Отклонен",
};

export const REVIEW_REPLY_STATUS_BADGE_DICT = {
    [REVIEW_REPLY_STATUS.DRAFT]: "Ответ на рассмотрении",
    [REVIEW_REPLY_STATUS.PUBLISHED]: "Ответ подтвержден",
    [REVIEW_REPLY_STATUS.DECLINED]: "Ответ отклонен",
};

export const REVIEW_STATUS_OPTIONS = [
    {
        key: REVIEW_STATUS.DRAFT.VALUE,
        text: REVIEW_STATUS.DRAFT.TEXT,
        value: REVIEW_STATUS.DRAFT.VALUE,
    },
    {
        key: REVIEW_STATUS.PUBLISHED.VALUE,
        text: REVIEW_STATUS.PUBLISHED.TEXT,
        value: REVIEW_STATUS.PUBLISHED.VALUE,
    },
    {
        key: REVIEW_STATUS.DECLINED.VALUE,
        text: REVIEW_STATUS.DECLINED.TEXT,
        value: REVIEW_STATUS.DECLINED.VALUE,
    },
];

export const SCORE_DICT = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
};