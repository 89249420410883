import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {handleFormString} from "../../../../../../utils/stringHelper";

import {CROWD_TASK_STATUS} from "../../../../../../constants/crowd/task";
import {requiredMessage} from "../../../../../../constants/validationRules";

import {getByNumberCardCrowdTask} from "../../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {bffCrowdTasksCardProgressSelector} from "../../../../../../ducks/bff/crowd/taskCard/selectors";

const CrowdTaskSearchModal = (props) => {
    const {
        clientId,
        onClose = () => {
        },
        onOpenEditModal = () => {
        },
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(bffCrowdTasksCardProgressSelector);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(getByNumberCardCrowdTask({
            clientId,
            number: handleFormString(values.number),
            getResult: (result) => {
                if (isEmpty(result.task)) {
                    setFieldError("number", "Задание с указанным номером не найдено");

                    return;
                }

                if (isEmpty(result.contractor)) {
                    setFieldError("number", "На задании не найден исполнитель");

                    return;
                }

                if (
                    [
                        CROWD_TASK_STATUS.DRAFT.VALUE,
                        CROWD_TASK_STATUS.COMPLETED.VALUE,
                        CROWD_TASK_STATUS.NOT_COMPLETED.VALUE,
                    ].includes(result.task.status)
                ) {
                    setFieldError("number", "Задание недоступно для добавления: в черновике или уже закрыто");

                    return;
                }

                onOpenEditModal(result);
            },
        }));
    };

    const {
        values,
        isValid,
        handleChange,
        handleSubmit,
        touched,
        errors,
        setFieldError,
    } = useFormik({
        onSubmit,
        initialValues: {
            number: "",
        },
        initialTouched: {
            number: true,
        },
        validationSchema: yup.object().shape({
            number: yup.string()
                .nullable()
                .required(requiredMessage),
        }),
        enableReinitialize: true,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Поиск задания
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    disabled={progress}
                    submitBtnContent="Найти"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            loading={progress}
        >
            <NmInputV2
                required={true}
                size="xl"
                mask="999999999999"
                maskChar={null}
                label="Номер задания"
                placeholder="Введите номер задания"
                name="number"
                value={values.number}
                onChange={handleChange}
                error={touched.number && errors.number}
            />
        </NmModal>
    );
};

export default CrowdTaskSearchModal;