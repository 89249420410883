import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import NmSelectedList from "../../../../components/NmSelectedList";
import NmTitle from "../../../../components/NmTitle";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import {
    getBankWithPath,
} from "../../../../components/ActualComponents/ClientSettings/components/DepositAndPayments/utils/getBankWithPath";
import {
    convertUtcToLocal,
    formatLocalDate,
    getEndDate,
    getStartDate,
} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {handleFormString} from "../../../../utils/stringHelper";

import {CLIENT_SETTINGS_GROUP} from "../../../../components/ActualComponents/ClientSettings/constants";
import {
    BANK_TYPE,
    BANK_TYPE_WITH_PATH_DICT,
    CLIENT_PROPERTY_BANKS_NAME_DICT,
    CLIENT_PROPERTY_NAME_DICT,
    PAYMENT_METHOD_DICT,
} from "../../../../constants/clientSettings";

import {getClientsSettingsLogs} from "../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsLogListProgressSelector,
    bffClientsSettingsLogListSelector,
    bffClientsSettingsLogListTotalPagesSelector,
} from "../../../../ducks/bff/clients/settings/selectors";
import {getMenuDesignDict} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsClientWorkTypeDictSelector,
    bffCommonDictsMenuDesignDictSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

const ClientSettingsLogList = (props) => {
    const {
        onClose,
        clientId,
        clientName,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(bffClientsSettingsLogListSelector);
    const totalCount = useSelector(bffClientsSettingsLogListTotalPagesSelector);
    const totalPages = useSelector(bffClientsSettingsLogListTotalPagesSelector);
    const progress = useSelector(bffClientsSettingsLogListProgressSelector);
    const menuDesignDict = useSelector(bffCommonDictsMenuDesignDictSelector);
    const clientWorkTypeDict = useSelector(bffCommonDictsClientWorkTypeDictSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    function mapFilterDto(filter) {
        const {
            fromDateFilter,
            toDateFilter,
            groupsFilter,
            clientUserFioFilter,
        } = filter;

        return {
            fromDateFilter: convertUtcToLocal(getStartDate(fromDateFilter)),
            toDateFilter: convertUtcToLocal(getEndDate(toDateFilter)),
            groupsFilter: groupsFilter?.length ? groupsFilter : undefined,
            clientUserFioFilter: handleFormString(clientUserFioFilter),
        };
    }

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        initFilter: {
            fromDateFilter: null,
            toDateFilter: null,
            groupsFilter: [],
            clientUserFioFilter: "",
        },
        setPagination,
        pageSize,
        mapFilterDto,
    });

    useEffect(() => {
        dispatch(getMenuDesignDict());
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, filterData]);

    const fetchList = () => {
        dispatch(getClientsSettingsLogs({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    const getValue = (propertyName, value) => {
        if (!value) {
            return "-";
        }

        if (propertyName === "menuDesign") {
            return menuDesignDict[value] || value;
        }

        if (propertyName === "bankTypeCard") {
            return BANK_TYPE_WITH_PATH_DICT[value];
        }

        if (["bankTypeSbp", "bankTypeRequisites"].includes(propertyName)) {
            return BANK_TYPE[value]?.TEXT || value;
        }

        if (propertyName === "paymentMethod") {
            return PAYMENT_METHOD_DICT[value] || value;
        }

        if (propertyName === "clientWorkType") {
            return clientWorkTypeDict[value] || value;
        }

        return value;
    };

    const getSelectedList = (label, list) => {
        return (
            <NmSelectedList
                showListWithoutValue={true}
                showedItemsCount={3}
                highlightingFirstItem={false}
                label={label}
                list={list.map(item => ({text: item.value}))}
                isLimiterComma={true}
            />
        );
    };

    const getChangeParamText = ({propertyName, oldValue, newValue}) => {
        if (["retailClientUserIds", "availableClientUserIds"].includes(propertyName)) {
            const addedUsers = newValue?.filter(item => !oldValue.map(item => item.id).includes(item.id)) || [];
            const deletedUsers = oldValue?.filter(item => !newValue.map(item => item.id).includes(item.id)) || [];

            switch (propertyName) {
            case "retailClientUserIds":
                return (
                    <div>
                            Доступ к разделу "Ресурсы" был изменен.
                        {
                            Boolean(addedUsers.length) &&
                                getSelectedList("Добавлены новые сотрудники", addedUsers)}
                        {
                            Boolean(deletedUsers.length) &&
                                getSelectedList("Удалены сотрудники", deletedUsers)
                        }
                    </div>
                );
            case "availableClientUserIds":
                return (
                    <div>
                            Доступ к компании был изменен.
                        {
                            Boolean(addedUsers.length) &&
                                getSelectedList("Доступ к компании добавлен для новых сотрудников", addedUsers)
                        }
                        {
                            Boolean(deletedUsers.length) &&
                                getSelectedList("Доступ к компании запрещен для сотрудников", deletedUsers)
                        }
                    </div>
                );
            default:
                return "";
            }
        }

        if (propertyName === "clientWorkType") {
            return `Изменение режима работы компании с ${getValue(propertyName, oldValue)} на ${getValue(propertyName, newValue)}`;
        }

        if (propertyName === "objectIdForPaidApis") {
            return `Объект для списания с депозита был изменен с ${getValue(propertyName, oldValue?.value)} на ${getValue(propertyName, newValue?.value)}`;
        }

        return `${CLIENT_PROPERTY_NAME_DICT[propertyName]} был изменен с ${getValue(propertyName, oldValue)} на ${getValue(propertyName, newValue)}`;
    };

    const getBanksChangesLabels = ({propertyName, oldValue, newValue}) => {
        const result = [];
        const newValueFields = Object.keys(newValue);

        newValueFields.forEach((field) => {
            const _newValue = newValue[field];
            const _oldValue = oldValue[field];

            const isBankTypeField = ["bankTypeCard", "bankTypeSbp", "bankTypeRequisites"].includes(field);
            const paymentMethodText = `"${CLIENT_PROPERTY_NAME_DICT[propertyName]} → ${CLIENT_PROPERTY_BANKS_NAME_DICT[field]}"`;

            if (isBankTypeField && !_oldValue && _newValue) {
                const bankText = field === "bankTypeCard" ?
                    `банк/путь ${getValue(field, getBankWithPath(_newValue, newValue.cardDirect))}` :
                    `банк ${getValue(field, _newValue)}`;

                result.push({
                    label: "Включение способа проведения оплат",
                    text: `${CLIENT_PROPERTY_NAME_DICT[propertyName]} → ${CLIENT_PROPERTY_BANKS_NAME_DICT[field]}, ${bankText}`,
                    noWrap: false,
                });

                return;
            }

            if (isBankTypeField && _oldValue && !_newValue) {
                result.push({
                    label: "Выключение способа проведения оплат",
                    text: paymentMethodText,
                    noWrap: false,
                });

                return;
            }

            switch (field) {
            case "bankTypeCard":
                if (_newValue !== _oldValue || newValue.cardDirect !== oldValue.cardDirect) {
                    result.push({
                        label: "Изменение параметра",
                        text: `Банк/путь для способа проведения оплат ${paymentMethodText} был изменен с ${getValue(field, getBankWithPath(_oldValue, oldValue.cardDirect))} на ${getValue(field, getBankWithPath(_newValue, newValue.cardDirect))}`,
                        noWrap: false,
                    });
                }

                return;
            case "bankTypeSbp":
            case "bankTypeRequisites":
                if (_newValue !== _oldValue) {
                    result.push({
                        label: "Изменение параметра",
                        text: `Банк для способа проведения оплат ${paymentMethodText} был изменен с ${getValue(field, _oldValue)} на ${getValue(field, _newValue)}`,
                        noWrap: false,
                    });
                }

                return;
            case "paymentMethod":
                if (_newValue !== _oldValue) {
                    result.push({
                        label: "Изменение параметра",
                        text: `Способ проведения оплат по-умолчанию для "${CLIENT_PROPERTY_NAME_DICT[propertyName]}" был изменен с  ${getValue(field, _oldValue)} на ${getValue(field, _newValue)}`,
                        noWrap: false,
                    });
                }

                return;
            default:
                return;
            }
        });

        return result;
    };

    const getActivationModuleLabelText = (item, detailText) => {
        const {
            propertyName,
            newValue,
        } = item;

        return ({
            label: newValue.value ? "Включение параметра" : "Выключение параметра",
            text: newValue.value ? detailText : CLIENT_PROPERTY_NAME_DICT[propertyName],
            noWrap: false,
        });
    };

    const getActivationModuleLabel = (item) => {
        const {
            propertyName,
            newValue,
        } = item;

        if (propertyName === "warnPaymentsAmountRepeatsLastMonth") {
            return getActivationModuleLabelText(item, `${CLIENT_PROPERTY_NAME_DICT["paymentAmountWarnThreshold"]} ${newValue.paymentAmountWarnThreshold}`);
        }

        if (propertyName === "edmAvailable") {
            return getActivationModuleLabelText(item, `"Электронный документооборот", тариф ${newValue.edmTariff}`);
        }

        if (propertyName === "migrantLicensePaymentEnabled") {
            return getActivationModuleLabelText(item, `"Оплата патентов", комиссия ${newValue.migrantLicensePaymentCommission}`);
        }

        return ({
            label: newValue ? "Включение параметра" : "Выключение параметра",
            text: CLIENT_PROPERTY_NAME_DICT[propertyName],
        });
    };

    const getRows = () => {
        return list.map(item => {
            const {
                dateTime,
                clientUserFio,
                settingsGroup,
                changes,
            } = item;

            const changesLabels = changes.map((item) => {
                if (
                    typeof item.newValue === "boolean" ||
                    typeof item.newValue?.value === "boolean"
                ) {
                    return getActivationModuleLabel(item);
                }

                if ([
                    "smzMarketplace",
                    "smzRegistry",
                    "smzApi",
                    "civilMarketplace",
                    "civilRegistry",
                    "civilApi",
                ].includes(item.propertyName)) {
                    return getBanksChangesLabels(item);
                }

                return ({
                    label: "Изменение параметра",
                    text: getChangeParamText(item),
                    noWrap: false,
                });
            });

            return {
                ...item,
                key: dateTime,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        secondaryHeader={`Дата создания ${formatLocalDate(dateTime, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={CLIENT_SETTINGS_GROUP[settingsGroup]}
                        labels={[
                            {
                                label: "Инициатор",
                                text: clientUserFio,
                            },
                            ...changesLabels.flat(),
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог изменений настроек компании 
                    {" "}
                    {clientName}
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={
                    <div className="row gx-4 mb-4 align-items-end">
                        <div className="col-16 col-md-8 col-xl-5">
                            <NmDateRangePickerV2
                                size="lg"
                                startFieldName="fromDateFilter"
                                endFieldName="toDateFilter"
                                value={{fromDateFilter: filter.fromDateFilter, toDateFilter: filter.toDateFilter}}
                                isClearable
                                label="Дата операции"
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-8 col-xl-4 mt-3 mt-md-0">
                            <NmDropdownV2
                                size="lg"
                                label="Раздел"
                                placeholder="Выберите раздел"
                                onChange={onChangeFilter}
                                name="groupsFilter"
                                value={filter.groupsFilter}
                                options={dictionaryToOptions(CLIENT_SETTINGS_GROUP)}
                                multiple
                            />
                        </div>
                        <div className="col-16 col-md-8 col-xl-4 mt-3 mt-xl-0">
                            <NmInputV2
                                size="lg"
                                name="clientUserFioFilter"
                                onChange={onChangeFilter}
                                value={filter.clientUserFioFilter}
                                placeholder="Введите ФИО"
                                label="Инициатор"
                            />
                        </div>
                        <div className="col-16 col-md-8 col-xl-3 mt-3 mt-xl-0">
                            <FilterButtonsV2
                                className="col-16"
                                onClear={onClear}
                                onSearch={() => onSearch(filter)}
                            />
                        </div>
                    </div>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        />
                }
            </NmPage>
        </NmModal>
    );
};

export default ClientSettingsLogList;