import {useState} from "react";
import {useDispatch} from "react-redux";

import {toastError} from "../../../../../../../utils/toastHelper";

import {
    updateContractorDriverLicense,
} from "../../../../../../../ducks/contractorProfile";

export const defaultDriverLicenseForm = {
    licenses: [],
    ownCar: false,
    driverLicensePresent: false,
};

export default function useUpdateDriverLicense({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultDriverLicenseForm});
    const dispatch = useDispatch();

    const {
        licenses,
        ownCar,
        driverLicensePresent,
    } = form;

    const onChangeLicenses = (e, {name, checked}) => {

        const updateLicenses = (prevLicenses = [], name, checked) => {
            if (!checked) {
                return prevLicenses.filter(item => name !== item);
            }

            return [...prevLicenses, name];
        };

        setForm(prevState => ({
            ...prevState,
            licenses: updateLicenses(prevState.licenses, name, checked),
        }));
    };

    const onChangeForm = (e, {name, checked}) => {
        if (name === "driverLicensePresent" && checked) {
            setForm(prevState => ({
                ...prevState,
                driverLicensePresent: checked,
                licenses: [],
            }));
            return;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const validateForm = () => {
        if (!driverLicensePresent && !licenses.length) {
            toastError("Выберите категорий прав");
            return false;
        }

        return true;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        dispatch(updateContractorDriverLicense({
            driverLicensePresent: !driverLicensePresent,
            contractorId,
            licenses,
            ownCar,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeLicenses,
        submit,
        setForm,
        onChangeForm,
    };
}