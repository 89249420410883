import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useContractorAdditionalDocuments} from "../../../../../hooks/useAdditionalDocuments";

import {toastError, toastSuccess} from "../../../../../../../../utils/toastHelper";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../../constants/clientList";
import {FILE_ERROR} from "../../../../../../../../constants/messages";
import {maxSizeScanFile} from "../../../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../../../constants/validationsYup";

import {getContractorAdditionalDocumentAggregation} from "../../../../../../../../ducks/contractorAdditionalDocuments";
import {getContractorsAdditionalDocumentFileTypeDict} from "../../../../../../../../ducks/contractorsDicts";
import {
    addFileContractorAdditionalScanV2,
    fileContractorOriginalScanObjSelector,
    getContractorAdditionalDocumentFile,
    updateContractorScanFieldFilesStore,
    updateFieldFilesStore,
} from "../../../../../../../../ducks/fileStore";

export const useDataPreviouslyPassportInfoCardEditForm = ({contractorId, onClose}) => {
    const contractorScanObj = useSelector(fileContractorOriginalScanObjSelector);

    const {
        getAdditionalOriginalScan,
        getPrevScan,
    } = useContractorAdditionalDocuments({contractorId});

    const getInitialFiles = () => {
        return {
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN),
        };
    };

    const [files, setFiles] = useState(getInitialFiles());
    const [errors, setErrors] = useState({});
    const [isEditFile, setIsEditFile] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        getAdditionalOriginalScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN);

        return () => {
            dispatch(updateFieldFilesStore({
                contractorOriginalScanObj: {},
            }));
        };
    }, []);

    useEffect(() => {
        setFiles(getInitialFiles());
    }, [contractorScanObj]);

    const handleSubmit = () => {
        for (const scanType in files) {
            const [file] = files[scanType];

            if (!file) {
                setErrors({
                    ...errors,
                    [scanType]: VALIDATIONS_MESSAGE.REQUIRED,
                });

                return;
            }

            setErrors({
                ...errors,
                [scanType]: undefined,
            });

            if (file && isEditFile[scanType]) {
                uploadFile(file, scanType);
            }
        }

        onClose();
    };

    const onDropScan = (_files, scanType) => {
        setFiles({
            ...files,
            [scanType]: _files,
        });
        setIsEditFile({
            ...isEditFile,
            [scanType]: true,
        });
    };

    const uploadFile = (file, contractorFileType) => {
        if (!file) {
            return;
        }

        if (file.size > maxSizeScanFile) {
            toastError(FILE_ERROR.SCAN_SIZE);

            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        dispatch(getContractorsAdditionalDocumentFileTypeDict());

        dispatch(updateContractorScanFieldFilesStore(contractorFileType, []));
        dispatch(addFileContractorAdditionalScanV2({
            contractorId,
            contractorFileType,
            file: formData,
            onSuccess: () => {
                toastSuccess("Данные успешно обновлены");
                dispatch(getContractorAdditionalDocumentFile({
                    contractorId,
                    type: contractorFileType,
                    thumbnail: true,
                }));
                dispatch(getContractorAdditionalDocumentAggregation({
                    contractorId,
                }));
            },
        }));
    };

    return {
        files,
        onDropScan,
        handleSubmit,
        errors,
    };
};