import React, {useState} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {history} from "../../../store/configureStore";
import CrowdTaskGroupEditForm from "./components/edit-form";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import useCrowdTaskGroupsAction from "./hooks/useAction";
import useCrowdTaskGroupsFetch from "./hooks/useFetch";
import useCrowdTaskGroupsFilter from "./hooks/useFilter";

import {formatLocalDate} from "../../../utils/dateFormat";

import {LINK_CLIENT_CROWD_TASK_GROUPS_CARD} from "../../../constants/links";

import {
    bffCrowdTaskGroupsListSelector,
    bffCrowdTaskGroupsProgressActionSelector,
    bffCrowdTaskGroupsProgressSelector,
    bffCrowdTaskGroupsTotalCountSelector,
    bffCrowdTaskGroupsTotalPagesSelector,
} from "../../../ducks/bff/crowd/taskGroups/selectors";

const CrowdTaskGroups = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffCrowdTaskGroupsListSelector);
    const totalCount = useSelector(bffCrowdTaskGroupsTotalCountSelector);
    const totalPages = useSelector(bffCrowdTaskGroupsTotalPagesSelector);
    const progress = useSelector(bffCrowdTaskGroupsProgressSelector);
    const progressAction = useSelector(bffCrowdTaskGroupsProgressActionSelector);

    const [archivedFilter, setArchivedFilter] = useState(false);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useCrowdTaskGroupsFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useCrowdTaskGroupsFetch({
        clientId,
        filter: filterData,
        pageNum,
        pageSize,
        archivedFilter,
    });

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        getMediaControls,
        getItemMediaControls,
        editFormData,
        setEditFormData,
    } = useCrowdTaskGroupsAction({
        clientId,
        fetchList,
        archivedFilter,
        setArchivedFilter,
        onOpenConfirm,
        onCloseConfirm,
        progress,
    });

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Да"
                cancelButton="Нет"
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const onClickTaskLink = (taskGroupId) => {
        const link = LINK_CLIENT_CROWD_TASK_GROUPS_CARD
            .replace(":clientId", clientId)
            .replace(":taskGroupId", taskGroupId);

        history.push(link);
    };

    const renderEditForm = () => {
        return (
            editFormData.isOpen &&
            <CrowdTaskGroupEditForm
                clientId={clientId}
                onClose={() => setEditFormData({})}
                fetchList={fetchList}
                editData={editFormData.data}
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            const {
                taskGroupId,
                creationDateTime,
                name,
                tasksCount,
            } = item;

            return {
                ...item,
                key: taskGroupId,
                contentRow: (
                    <NmListCard
                        alignItems="flex-end"
                        secondaryHeader={
                            creationDateTime &&
                            `Создано: ${formatLocalDate(creationDateTime, "dd.MM.yyyy HH:mm")}`
                        }
                        primaryHeader={name}
                        primaryHeaderLink={true}
                        onClickLink={() => onClickTaskLink(taskGroupId)}
                        classNameMainContent="col-16 col-xxl-6"
                        cards={[
                            {
                                title: "Кол-во заданий в группе",
                                value: tasksCount || 0,
                                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-9 align-items-end"
                        mediaControls={getItemMediaControls(item)}
                        actionsClassName="col-1 align-items-end"
                        noDivider={true}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    tooltipType="light"
                    tooltipText={
                        <div>
                            Раздел предназначен:
                            {
                                archivedFilter ?
                                    <ul>
                                        <li>
для просмотра архивных групп заданий,
                                        </li>
                                        <li>
для восстановления из архива созданных групп заданий.
                                        </li>
                                    </ul> :
                                    <ul>
                                        <li>
для группировки существующих заданий,
                                        </li>
                                        <li>
для массового добавления новых заданий внутри группы,
                                        </li>
                                        <li>
для массовых операций над заданиями: удаление, публикация, закрытие,
                                            архивирование,
                                        </li>
                                        <li>
для выгрузки существующих заданий внутри группы.
                                        </li>
                                    </ul>
                            }
                        </div>
                    }
                >
                    Группы заданий
                </NmTitle>
            }
            mediaControls={getMediaControls()}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderEditForm()}
            {renderConfirm()}
            {
                list.length ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
};

export default CrowdTaskGroups;