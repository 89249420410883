import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/orderWorkReport";
//*  TYPES  *//

const ORDER_WORK_REPORT_LIST_REQUEST = "ORDER_WORK_REPORT_LIST_REQUEST";
const ORDER_WORK_REPORT_LIST_SUCCESS = "ORDER_WORK_REPORT_LIST_SUCCESS";
const ORDER_WORK_REPORT_LIST_ERROR = "ORDER_WORK_REPORT_LIST_ERROR";

const CHECK_ORDER_WORK_ACTS_REQUEST = "CHECK_ORDER_WORK_ACTS_REQUEST";
const CHECK_ORDER_WORK_ACTS_SUCCESS = "CHECK_ORDER_WORK_ACTS_SUCCESS";
const CHECK_ORDER_WORK_ACTS_ERROR = "CHECK_ORDER_WORK_ACTS_ERROR";

const ORDER_WORK_REPORT_PAID_SUM_REQUEST = "ORDER_WORK_REPORT_PAID_SUM_REQUEST";
const ORDER_WORK_REPORT_PAID_SUM_SUCCESS = "ORDER_WORK_REPORT_PAID_SUM_SUCCESS";
const ORDER_WORK_REPORT_PAID_SUM_ERROR = "ORDER_WORK_REPORT_PAID_SUM_ERROR";

const ORDER_WORK_REPORT_UPDATE_FIELD_STORE = "ORDER_WORK_REPORT_UPDATE_FIELD_STORE";

const ORDER_WORK_REPORT_LOG_REQUEST = "ORDER_WORK_REPORT_LOG_REQUEST";
const ORDER_WORK_REPORT_LOG_SUCCESS = "ORDER_WORK_REPORT_LOG_SUCCESS";
const ORDER_WORK_REPORT_LOG_ERROR = "ORDER_WORK_REPORT_LOG_ERROR";

const ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST = "ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST";
const ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS = "ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS";
const ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR = "ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR";

const ADD_VIEW_CANCELLED_CHECKS_REQUEST = "ADD_VIEW_CANCELLED_CHECKS_REQUEST";

const HAS_CANCELLED_CHECKS_REQUEST = "HAS_CANCELLED_CHECKS_REQUEST";
const HAS_CANCELLED_CHECKS_SUCCESS = "HAS_CANCELLED_CHECKS_SUCCESS";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    error: {},
    pageData: {},
    totalCount: 0,
    list: [],
    paidSumList: [],
    orderPaymentRequestParams: {},
    orderPaymentList: [],
    listLastChangePayments: [],
    orderPaymentTotalCount: 0,
    orderWorkReportLogs: [],
    totalCountLogs: 0,
    pageDataReportLog: {},
    confirmPaymentSuccess: false,
    dataPaidSum: {},
    cancelledChecks: [],
    hasCancelledChecks: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case HAS_CANCELLED_CHECKS_SUCCESS:
        return {
            ...state,
            hasCancelledChecks: payload,
        };
    case CHECK_ORDER_WORK_ACTS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CHECK_ORDER_WORK_ACTS_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS:
        return {
            ...state,
            listLastChangePayments: payload,
        };
    case ORDER_WORK_REPORT_PAID_SUM_REQUEST:
        return {
            ...state,
            dataPaidSum: payload,
            progress: true,
        };
    case ORDER_WORK_REPORT_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case ORDER_WORK_REPORT_LOG_REQUEST:
        return {
            ...state,
            pageDataReportLog: payload,
            progress: true,
            loadingData: true,
        };
    case ORDER_WORK_REPORT_LOG_SUCCESS:
        const {
            results: orderWorkReportLogs,
            totalCount: totalCountLogs,
        } = payload;

        return {
            ...state,
            orderWorkReportLogs,
            totalCountLogs,
            progress: false,
            loadingData: false,
        };
    case ORDER_WORK_REPORT_PAID_SUM_SUCCESS:
        return {
            ...state,
            paidSumList: payload,
            progress: false,
        };
    case ORDER_WORK_REPORT_LIST_SUCCESS:
        const {
            reports: list,
            totalCount,
        } = payload;

        return {
            ...state,
            totalCount,
            list,
            progress: false,
        };
    case ORDER_WORK_REPORT_UPDATE_FIELD_STORE:
        return {
            ...state,
            ...payload,
        };
    case CHECK_ORDER_WORK_ACTS_ERROR:
    case ORDER_WORK_REPORT_LOG_ERROR:
    case ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR:
    case ORDER_WORK_REPORT_PAID_SUM_ERROR:
    case ORDER_WORK_REPORT_LIST_ERROR:
        return {
            ...state,
            error: payload,
            loadingData: false,
            progress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function checkOrderWorkActs(payload) {
    return {
        type: CHECK_ORDER_WORK_ACTS_REQUEST,
        payload,
    };
}

export function getOrderWorkReportLogs(payload) {
    return {
        type: ORDER_WORK_REPORT_LOG_REQUEST,
        payload,
    };
}

export function getOrderWorkReportList(payload) {
    return {
        type: ORDER_WORK_REPORT_LIST_REQUEST,
        payload,
    };
}

export function getPaidSumList(payload) {
    return {
        type: ORDER_WORK_REPORT_PAID_SUM_REQUEST,
        payload,
    };
}

export function updateFieldOrderWorkReportStore(payload) {
    return {
        type: ORDER_WORK_REPORT_UPDATE_FIELD_STORE,
        payload,
    };
}

export function fetchWorkLogLastChangePayment(payload) {
    return {
        type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST,
        payload,
    };
}

// Добавляет просмотр пользователем аннулированных чеков
export function addViewCancelledChecks(payload) {
    return {
        type: ADD_VIEW_CANCELLED_CHECKS_REQUEST,
        payload,
    };
}

// Есть ли аннулированные чеки, индикатор
export function hasCancelledChecks(payload) {
    return {
        type: HAS_CANCELLED_CHECKS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const orderWorkReportSelector = state => state.orderWorkReport;
export const getOrderWorkConfirmPaymentSuccessSelector = createSelector(orderWorkReportSelector, ({confirmPaymentSuccess}) => confirmPaymentSuccess);
export const getOrderWorkReportListSelector = createSelector(orderWorkReportSelector, ({list}) => list);
export const getOrderWorkReportPageDataSelector = createSelector(orderWorkReportSelector, ({pageData}) => pageData);
export const getOrderWorkReportIsSearchSelector = createSelector(orderWorkReportSelector, ({pageData: {isSearchPadeData}}) => isSearchPadeData);
export const getOrderWorkReportPaidSumListSelector = createSelector(orderWorkReportSelector, ({paidSumList}) => paidSumList || []);
export const getOrderWorkReportLastChangePaymentsSelector = createSelector(orderWorkReportSelector, ({listLastChangePayments}) => listLastChangePayments);
export const getOrderWorkProgressSelector = createSelector(orderWorkReportSelector, ({progress}) => progress);
export const getOrderWorkReportLogsSelector = createSelector(orderWorkReportSelector, ({orderWorkReportLogs}) => orderWorkReportLogs);
export const getOrderWorkReportLogsTotalPagesSelector = createSelector(orderWorkReportSelector, ({totalCountLogs, pageDataReportLog: {pageSize = 0}}) => getTotalPages(totalCountLogs, pageSize));
export const getTotalCountLogSelector = createSelector(orderWorkReportSelector, ({totalCountLogs}) => totalCountLogs);
export const getOrderWorkTotalPagesSelector = createSelector(orderWorkReportSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));
export const getDataPaidSumSelector = createSelector(orderWorkReportSelector, ({dataPaidSum}) => dataPaidSum);
export const getOrderWorkReportTotalPagesSelector = createSelector(orderWorkReportSelector, ({totalCount, pageData: {pageSize = 0}}) => ({
    totalPages: getTotalPages(totalCount, pageSize),
    totalCount,
}));
export const orderWorkReportListTotalCount = createSelector(orderWorkReportSelector, ({totalCount}) => totalCount);
export const getLogLoadingDataSelector = createSelector(orderWorkReportSelector, ({loadingData}) => loadingData);
export const cancelledChecksIndicatorSelector = createSelector(orderWorkReportSelector, ({hasCancelledChecks}) => hasCancelledChecks);

//*  SAGA  *//
export const checkOrderWorkActsSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`${controller}/checkActs`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_ORDER_WORK_ACTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CHECK_ORDER_WORK_ACTS_SUCCESS, payload: result});

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: CHECK_ORDER_WORK_ACTS_ERROR, payload: error});
    }
};

export const getOrderWorkReportListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_WORK_REPORT_LIST_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: ORDER_WORK_REPORT_LIST_ERROR, payload: error});
    }
};

//POST /api/orderWorkReport/paidSum
export const getPaidSumSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/paidSum`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_PAID_SUM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_PAID_SUM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_PAID_SUM_ERROR,
            payload: error,
        });
    }
};

//POST /api/orderWorkReportLog/getPage

export const getOrdersWorkReportLogSaga = function* ({payload}) {
    try {
        const {
            isCivil = false,
            isShowClientPayments = false,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`/adm${isShowClientPayments ? "/clients" : ""}/${isCivil ? "ndfl" : "npd"}-payments/status-logs/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_LOG_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_LOG_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_LOG_ERROR,
            payload: error,
        });
    }
};

// POST /api/orderWorkReportLog/getOrderWorkReportLogLastChangeByIds

export const getOrdersWorkReportLogLastChangeByIdSaga = function* (action) {
    try {
        const {payload} = action;

        const {paymentsNumber} = payload;
        const result = yield request.post("/orderWorkReportLog/getOrderWorkReportLogLastChangeByIds", paymentsNumber);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR,
            payload: error,
        });
    }
};

// GET /api/orderWorkReport/cancelled/hasCancelledChecks
// Индикатор аннулированных чеков
export const hasCancelledChecksSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
        } = payload;
        const result = yield request.get(`${controller}/cancelled/hasCancelledChecks?clientId=${clientId}&clientUserId=${clientUserId}`);

        yield put({
            type: HAS_CANCELLED_CHECKS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error("hasCancelledChecksSaga error: ", error);
    }
};

// GET /api/orderWorkReport/cancelled/addViewCancelledChecks
// Просмотр аннулированных чеков
export const addViewCancelledChecksSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
        } = payload;

        const result = yield request.get(`${controller}/cancelled/addViewCancelledChecks?clientId=${clientId}&clientUserId=${clientUserId}`);

        yield put(hasCancelledChecks(payload));

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("addViewCancelledChecksSaga error: ", errorMessage);
        }
    } catch (error) {
        console.error("addViewCancelledChecksSaga error: ", error);
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_WORK_REPORT_LIST_REQUEST, getOrderWorkReportListSaga),
        takeEvery(CHECK_ORDER_WORK_ACTS_REQUEST, checkOrderWorkActsSaga),
        takeEvery(ORDER_WORK_REPORT_PAID_SUM_REQUEST, getPaidSumSaga),
        takeEvery(ORDER_WORK_REPORT_LOG_REQUEST, getOrdersWorkReportLogSaga),
        takeEvery(ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST, getOrdersWorkReportLogLastChangeByIdSaga),
        takeEvery(ADD_VIEW_CANCELLED_CHECKS_REQUEST, addViewCancelledChecksSaga),
        takeEvery(HAS_CANCELLED_CHECKS_REQUEST, hasCancelledChecksSaga),
    ]);
}
