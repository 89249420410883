import React, {FC, ReactNode} from "react";

import {getClassNames} from "../../../utils/classNames";

interface ListProps {
    classNameOffset?: string,
    className?: string,
    list: Array<string | ReactNode>,
}

export const List: FC<ListProps> = (props) => {
    const {
        classNameOffset = "mt-1",
        className,
        list,
    } = props;

    return (
        <div className={getClassNames(["list", className])}>
            {
                list.map((item, index) => {
                    const isFirst = index === 0;

                    return (
                        <div
                            key={index}
                            className={getClassNames([
                                "list__item",
                                !isFirst && classNameOffset,
                            ])}
                        >
                            {item}
                        </div>
                    );
                })
            }
        </div>
    );
};