import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import ContractorCourseCertificateEditModal from "./components/сontractor-course-certificate-edit-modal";

import {isNMUsers} from "../../../../utils/access";
import {formatLocalDate} from "../../../../utils/dateFormat";

import {
    contractorCourseCertificateListSelector,
    deleteContractorCourseCertificate,
} from "../../../../ducks/contractorProfile";
import {downloadDocument} from "../../../../ducks/documents";

export const COURSE_CERTIFICATE_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const COURSE_CERTIFICATE_OPTIONS = [
    {
        key: COURSE_CERTIFICATE_OPTION.EDIT,
        text: "Редактировать",
        value: COURSE_CERTIFICATE_OPTION.EDIT,
    },
    {
        key: COURSE_CERTIFICATE_OPTION.REMOVE,
        text: "Удалить",
        value: COURSE_CERTIFICATE_OPTION.REMOVE,
    },
];

export default function ContractorCertificatesAndCourses(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const contractorCourseCertificateList = useSelector(contractorCourseCertificateListSelector);

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const deleteElem = (item) => {
        const {courseCertificateId} = item;

        dispatch(deleteContractorCourseCertificate({
            contractorId,
            courseCertificateId,
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    const onClickAction = ({value}, item) => {
        if (value === COURSE_CERTIFICATE_OPTION.EDIT) {
            toggleEditModal(item);
            return;
        }

        if (value === COURSE_CERTIFICATE_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem(item);
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?" ,
            });
            setShowConfirm(true);
        }
    };

    const downloadFile = (fileName, downloadLink) => {
        const parse = require("url-parse");

        const {query, pathname} = parse(downloadLink);

        dispatch(downloadDocument({
            isDownload: true,
            fileName: fileName,
            isFillOrigin: true,
            downloadLink: `${pathname}${query}`,
        }));
    };

    function mapContent() {
        if (!contractorCourseCertificateList.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return contractorCourseCertificateList.map((item) => {
            return (
                <div
                    key={item.id}
                    className="d-flex flex-column nm-border-bottom mb-2"
                >
                    <div className="flex flex-aligned-center justify-content-between">
                        <Text type="title">
                            {item.courseName}
                        </Text>
                        {isNMUsers() && <ContextMenu
                            notBorder
                            horizontalIcon
                            transform={false}
                            disabled={false}
                            options={COURSE_CERTIFICATE_OPTIONS}
                            onClickItem={(data) => {
                                onClickAction(data, item);
                            }}
                        />}
                    </div>
                    <NmLabelText
                        className="mb-md-2 mb-xxl-2 mb-xl-2"
                        label="Организация, проводившая обучения"
                        text={item.organization}
                    />
                    <NmLabelText
                        className="mb-md-2 mb-xxl-2 mb-xl-2"
                        label="Дата окончания"
                        text={formatLocalDate(item.finishedDate)}
                    />
                    <NmLabelText
                        className="mb-md-2 mb-xxl-2 mb-xl-2"
                        classNameText={item.fileName ? "app-link" : ""}
                        onClickText={() => {
                            item.fileName && downloadFile(item.fileName, item.downloadLink);
                        }}
                        label="Файл"
                        text={item.fileName || "отсутствует"}
                    />
                </div>
            );
        });
    }

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal &&
            <ContractorCourseCertificateEditModal
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
                contractorId={contractorId}
                editData={editData}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                onClickAdd={() => toggleEditModal()}
                title="Сертификаты и курсы"
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}