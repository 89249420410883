import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmPage from "../../../../../components/NmPage";
import RejectionReason from "../../../../../components/RejectionReason";
import Steps from "../../../../../components/Steps";
import CrowdTaskActCreation from "../components/act-creation";
import CrowdTaskCardContractorRejectModal from "../components/contractor-reject-modal";
import CrowdTaskContractorWorkInfoDetail from "../components/work-detail-info";

import {useModal} from "../../../../../hooks/useModal";
import useCrowdTaskCardInWorkPageAction from "./hooks/useAction";
import useCrowdTaskCardInWorkPageFetch from "./hooks/useFetch";

import {CROWD_TASK_WORKING_CONTRACTOR_STEPS} from "../../../../../constants/crowd/task";

import {
    bffCrowdTasksCardProgressActionSelector,
    bffCrowdTasksCardRichSelector,
    bffCrowdTasksCardWorkingProgressSelector,
    bffCrowdTasksCardWorkingSelector,
} from "../../../../../ducks/bff/crowd/taskCard/selectors";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../../../constants/link-params";

const CrowdTaskCardInWorkPage = (props) => {
    const {
        match: {
            params: {
                clientId,
                taskId,
            },
            path,
        },
    } = props;

    const isCompletedPage = path.includes(SUB_PAGE_CROWD_TASK_CARD.COMPLETED.LINK);

    const card = useSelector(bffCrowdTasksCardWorkingSelector);
    const taskCard = useSelector(bffCrowdTasksCardRichSelector);
    const progress = useSelector(bffCrowdTasksCardWorkingProgressSelector);
    const progressAction = useSelector(bffCrowdTasksCardProgressActionSelector);

    const {
        workingContractor = {},
        status,
    } = card;

    const {
        fetchInfo,
    } = useCrowdTaskCardInWorkPageFetch({
        clientId,
        taskId,
        isCompletedPage,
    });

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        getContractorMediaControls,
        getTaskMediaControls,
        getOptimumMediaControls,
        getPaymentMediaControls,
        contractorRejectModalData,
        setContractorRejectModalData,
        createActModalData,
        setCreateActModalData,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
    } = useCrowdTaskCardInWorkPageAction({
        fetchInfo,
        clientId,
        taskId,
        status,
        contractor: workingContractor,
        onOpenConfirm,
        onCloseConfirm,
        isCompletedPage,
    });

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            onCancel,
            confirmButton,
            cancelButton,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onClose={onCloseConfirm}
                onCancel={onCancel ? onCancel : onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderCardContractorRejectModal = () => {
        return (
            !isEmpty(contractorRejectModalData) &&
            <CrowdTaskCardContractorRejectModal
                onClose={() => setContractorRejectModalData({})}
                fetchInfo={fetchInfo}
                {...contractorRejectModalData}
            />
        );
    };

    const renderActCreationModal = () => {
        return (
            !isEmpty(createActModalData) &&
            <CrowdTaskActCreation
                clientId={clientId}
                taskId={taskId}
                taskCard={taskCard}
                onClose={() => setCreateActModalData({})}
                fetchInfo={fetchInfo}
                {...createActModalData}
            />
        );
    };

    const rejectReasonModal = () => {
        return (
            rejectReasonModalData.isOpen &&
            <RejectionReason
                title="Причина отклонения"
                placeholder="Введите причину отклонения оплаты"
                close={() => setRejectReasonModal({})}
                submit={(rejectReason) => {
                    rejectPayment({
                        paymentId: rejectReasonModalData.paymentId,
                        rejectReason,
                    });
                }}
            />
        );
    };

    return (
        <NmPage
            isLoaded={progress}
        >
            {renderConfirm()}
            {renderCardContractorRejectModal()}
            {renderActCreationModal()}
            {rejectReasonModal()}
            {
                !isEmpty(card) ?
                    <>
                        {
                            !isCompletedPage &&
                            <Steps
                                withAdditionalParams
                                items={CROWD_TASK_WORKING_CONTRACTOR_STEPS}
                                activeItems={[status]}
                                className="mb-4 col-16 col-xl-12"
                            />
                        }
                        <>
                            <CrowdTaskContractorWorkInfoDetail
                                className="mt-4"
                                card={card}
                                workingContractor={workingContractor}
                                isCompletedPage={isCompletedPage}
                                getTaskMediaControls={getTaskMediaControls}
                                getOptimumMediaControls={getOptimumMediaControls}
                                getPaymentMediaControls={getPaymentMediaControls}
                                getContractorMediaControls={getContractorMediaControls}
                            />
                        </>
                    </> :
                    <NmEmptyPageV2
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default CrowdTaskCardInWorkPage;