
import React from "react";
import {FC} from "react";

import {List} from "../List";
import NmLabelText, {ILabelText} from "../NmLabelText";

interface LabelTextListProps extends ILabelText {
    isCardPage?: string,
    className?: string,
    list: Array<ILabelText>,
}

export const NmLabelTextList: FC<LabelTextListProps> = (props) => {
    const {
        list,
        className,
    } = props;

    return (
        <List
            className={className}
            list={
                list.map((props, index) => {
                    return (
                        <NmLabelText
                            key={index}
                            {...props}
                        />
                    );
                })
            }
        />
    );
};