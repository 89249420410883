import React, {useMemo} from "react";

import ClientNamesDropdown from "../../../ActualComponents/ClientNamesDropdown";

const UpdatingDirectoriesClientField = (props) => {
    const {
        modalData,
        ...dropdownProps
    } = props;

    const initialOption = useMemo(() => {
        if (modalData) {
            return {
                key: modalData.clientId,
                value: modalData.clientId,
                text: modalData.clientName,
            };
        }
    }, []);

    return (
        <ClientNamesDropdown
            required
            label="Компания"
            placeholder="Не выбрано"
            name="clientId"
            initialOption={initialOption}
            disabled={Boolean(modalData.clientId)}
            isMountLoad={!modalData.clientId}
            {...dropdownProps}
        />
    );
};

export default UpdatingDirectoriesClientField;