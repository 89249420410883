import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getFullName} from "../../../../../../utils/stringFormat";

import {
    addGroupMember,
    deleteGroupMember,
    getGroupMemberRelatedUsers,
    groupMemberRelatedUsersListSelector,
    setGroupMemberRelatedUsers,
    updateFieldClientStore,
} from "../../../../../../ducks/client";
import {clientGroupCardSelector} from "../../../../../../ducks/clientGroups";

const initialStateModalWindow = {
    isShow: false,
    title: "",
    content: "",
    submit: undefined,
};

export function useMembersActions(groupId) {
    const {groupName} = useSelector(clientGroupCardSelector);
    const groupMemberRelatedUsersList = useSelector(groupMemberRelatedUsersListSelector);
    const dispatch = useDispatch();

    const [dataModal, setDataModal] = useState({
        ...initialStateModalWindow,
    });

    const [enabledRelatedUsersList, setEnabledRelatedUsersList] = useState([]);

    useEffect(() => {
        const enabledRelatedUsers = groupMemberRelatedUsersList.filter(({enabled}) => enabled).map(({clientUserId}) => clientUserId);
        setEnabledRelatedUsersList(enabledRelatedUsers);
    }, [groupMemberRelatedUsersList]);

    const onCloseModal = () => {
        setDataModal(initialStateModalWindow);
    };

    const onSubmitAddMember = (data) => {
        dispatch(addGroupMember({
            requestData: data,
            clientGroupId: groupId,
            onSuccess: onCloseModal,
        }));
    };

    const onClickAddMember = () => {
        setDataModal(prevState => ({
            ...prevState,
            isShow: true,
            isAddForm: true,
            submit: onSubmitAddMember,
        }));
    };

    const onClickDeleteMember = (item) => {
        const {
            lastName,
            firstName,
            patronymic,
            clientId,
            clientUserId,
        } = item;

        setDataModal(prevState => ({
            ...prevState,
            isShow: true,
            title: `Вы уверены, что хотите удалить сотрудника ${getFullName(lastName, firstName, patronymic)} из списка сотрудников группы компаний ${groupName}?`,
            submit: () => {
                dispatch(deleteGroupMember({
                    requestData: {
                        clientId,
                        clientUserId,
                    },
                    onSuccess: onCloseModal,
                }));
            },
        }));
    };

    const submitRelatedUsers = () => {
        const {clientUserId} = dataModal;
        const clientUsers = groupMemberRelatedUsersList.map(({clientUserId}) => {
            return {
                clientUserId,
                enabled: enabledRelatedUsersList.includes(clientUserId),
            };
        });

        dispatch(setGroupMemberRelatedUsers({
            clientUserId,
            clientUsers,
            onSuccess: () => {
                setEnabledRelatedUsersList([]);
                dispatch(updateFieldClientStore("groupMemberRelatedUsersList", []));
                onCloseModal();
            },
        }));
    };

    const onClickSettingsMember = (item) => {
        const {
            clientUserId,
        } = item;

        dispatch(getGroupMemberRelatedUsers({
            clientUserId,
        }));

        setDataModal(prevState => ({
            ...prevState,
            ...item,
            isShow: true,
            isSettingsUserForm: true,
        }));
    };

    return {
        dataModal,
        onCloseModal,
        onClickAddMember,
        onClickDeleteMember,
        onClickSettingsMember,
        enabledRelatedUsersList,
        setEnabledRelatedUsersList,
        submitRelatedUsers,
    };
}