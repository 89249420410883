import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    KEDO_LOGS_INTEGRATION_1C_PAGE_ERROR,
    KEDO_LOGS_INTEGRATION_1C_PAGE_REQUEST,
    KEDO_LOGS_INTEGRATION_1C_PAGE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";

// POST /bff/adm/clients/{clientId}/logs/integration1c/page
// POST /bff/adm/clients/{clientId}/kedo/logs/integration1c/page
// Получение логов изменений
const getKedoLogsIntegration1cPage = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_LOGS_INTEGRATION_1C_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_LOGS_INTEGRATION_1C_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_LOGS_INTEGRATION_1C_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_LOGS_INTEGRATION_1C_PAGE_REQUEST, getKedoLogsIntegration1cPage),
    ]);
}