import React from "react";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import {useCrowdTaskActRegistryEditModalForm} from "./hooks/useForm";

import {bffCrowdActRegistryProgressActionSelector} from "../../../../../ducks/bff/crowd/actRegistry/selectors";

const CrowdTaskActRegistryEditModal = (props) => {
    const {
        clientId,
        onClose,
        isEdit = false,
        editData = {},
        fetchList = () => {},
    } = props;

    const progressAction = useSelector(bffCrowdActRegistryProgressActionSelector);

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    } = useCrowdTaskActRegistryEditModalForm({
        clientId,
        onClose,
        isEdit,
        editData,
        fetchList,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование реестра актов по заданиям" : "Создание реестра актов по заданиям"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submit={handleSubmit}
                    onClose={onClose}
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={progressAction}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    required={true}
                    size="xl"
                    label="Название"
                    placeholder="Введите название реестра"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && errors.name}
                />
                <NmTextareaV2
                    label="Комментарий"
                    placeholder="Оставьте комментарий"
                    maxLength={255}
                    minRows={3}
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    error={touched.comment && errors.comment}
                />
                <NmCheckboxV2
                    name="needContractorApprove"
                    checked={values.needContractorApprove}
                    onChange={handleChange}
                    label="Требуется подтверждение акта исполнителем"
                />
            </NmForm>
        </NmModal>
    );
};

export default CrowdTaskActRegistryEditModal;