import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getKedoInstructions, saveKedoInstruction} from "./actionCreators";
import {
    BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_ERROR,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_SUCCESS,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_ERROR,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_ERROR,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";


const controller = "/adm/settings/instructions/kedo";

// POST bff/adm/settings/instructions/kedo/save
// Сохранить инструкцию
const saveKedoInstructionSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/save`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Инструкция успешно сохранена");

        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_SUCCESS,
            payload: result,
        });

        yield put(getKedoInstructions());
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_ERROR,
        });
    }
};

// POST /bff/adm/settings/instructions/kedo/addFile
// Добавить файл инструкции
const addKedoInstructionFileSaga = function* ({payload}) {
    try {
        const {instructionType, file: data, info} = payload;

        const result = yield request.bff.post(`${controller}/addFile`, data, {
            params: {
                instructionType,
            },
            ...getMultipartHeaders(),
        },
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_SUCCESS,
        });

        yield put(saveKedoInstruction(info));
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_ERROR,
        });
    }
};

// GET /bff/adm/settings/instructions/kedo/getList
// Получить список инструкций
const getKedoInstructionsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getList`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_REQUEST, saveKedoInstructionSaga),
        takeEvery(BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_REQUEST, addKedoInstructionFileSaga),
        takeEvery(BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST, getKedoInstructionsSaga),
    ]);
}