import React from "react";
import {isEmpty} from "lodash";

import NmAdvancedTooltip from "../../../../../../components/ActualComponents/NmAdvancedTooltip";
import Text from "../../../../../../components/ActualComponents/Text";
import NmCheck from "../../../../../../components/NmCheck";
import {ReactComponent as NotInfoIcon} from "../../../../../../images/check_bg_v2.svg";
import {FILE_STATUSES, STATUSES} from "../order-history-item";

import {CONTRACTOR_FILE_TYPES, CONTRACTOR_FILE_TYPES_TRANSLATE} from "../../../../../../constants/clientList";
import {SCAN_DECISION_TYPE, SCAN_SOLUTION_TYPES} from "../../../../../../constants/contractor";
import {
    CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES,
    CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES_TRANSLATE,
} from "../../../../contractor-verification-data/constants";

import "./style.sass";

const DECISION_REASON_TRANSLATE = {
    [SCAN_SOLUTION_TYPES.SELFIE]: {
        FILE_DECLINED_IMAGE_GLARE: "Плохое качество фотографии",
        FILE_DECLINED_IMAGE_PARTIALLY_MISSING: "Человек, изображённый на фотографии, не совпадает с фотографией в паспорте",
        FILE_DECLINED_OTHER_REASON: "Фотография не является \"Селфи с паспортом\"",
        FILE_DECLINED_CONTRACTOR_ARCHIVED: "Отправка исполнителя в архив",
    },
    [SCAN_SOLUTION_TYPES.OTHER]: {
        FILE_DECLINED_IMAGE_GLARE: "На фотографии блик, который мешает проверить данные",
        FILE_DECLINED_IMAGE_PARTIALLY_MISSING: "Часть фотографии отсутствует. Невозможно проверить корректность данных",
        FILE_DECLINED_OTHER_REASON: "По фотографии невозможно проверить корректность данных",
        FILE_DECLINED_INN_AND_PASSPORT_DATA_NOT_EQUALS: "ИНН не соответствует паспорту",
        FILE_DECLINED_CONTRACTOR_ARCHIVED: "Отправка исполнителя в архив",
        [SCAN_DECISION_TYPE.NEED_INN_SCAN]: "Требуется фотография, подтверждающая ИНН",
    },
};

export function OrderEventLogHistory(props) {
    const {
        trigger,
        files,
        status,
        decisionReason,
        indexElem,
        lengthList,
        rejectionReasons,
    } = props;

    function renderSuccessFiles() {
        const successFiles = (files || []).filter(({decisionType}) => decisionType !== null && FILE_STATUSES[decisionType].SUCCESS);

        if (isEmpty(successFiles)) {
            return null;
        }

        return <>
            <div className="order-event-log-history-content__title">
                Одобрено
            </div>
            <div className="order-event-log-history-content-item">
                {
                    successFiles.map(item => {
                        const {type} = item;

                        const label = type === CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN ?
                            CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES_TRANSLATE.INN_SCAN :
                            CONTRACTOR_FILE_TYPES_TRANSLATE[type];

                        return (
                            <NmCheck
                                labelClass="order-event-log-history__label-check"
                                blockClassName="order-event-log-history__nm-check"
                                isCheck={true}
                                label={label}
                            />
                        );
                    })
                }
            </div>
        </>;
    }

    function renderUnknownFiles() {
        const unknownFiles = (files || []).filter(({decisionType}) => decisionType === null);

        if (isEmpty(unknownFiles)) {
            return null;
        }

        return <>
            <div className="order-event-log-history-content__title">
                Нет информации о решении
            </div>
            <div className="order-event-log-history-content-item">
                {
                    unknownFiles.map(item => {
                        const {type} = item;
                        return (
                            <div className="order-event-log-history-not-info">
                                <NotInfoIcon className="order-event-log-history-not-info__icon" />
                                <div className="order-event-log-history-not-info__label">
                                    {CONTRACTOR_FILE_TYPES_TRANSLATE[type]}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </>;
    }


    function renderFailFiles() {
        const failFiles = (files || []).filter(({decisionType}) => decisionType !== null && !FILE_STATUSES[decisionType].SUCCESS);

        if (isEmpty(failFiles)) {
            return null;
        }

        return <>
            <div className="order-event-log-history-content__title">
                Отклонено
            </div>
            <div className="order-event-log-history-content-item">
                {
                    failFiles.map(item => {
                        const {
                            decisionType,
                            type,
                        } = item;

                        const rejectionReason = rejectionReasons?.[type] || "";
                        const scanType = type === CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN ?
                            SCAN_SOLUTION_TYPES.SELFIE :
                            SCAN_SOLUTION_TYPES.OTHER;

                        return (
                            <div className="order-event-log-history__container">
                                <NmCheck
                                    blockClassName="align-items-start"
                                    isCheck={false}
                                    label={
                                        type === CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN ?
                                            CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES_TRANSLATE.INN_SCAN :
                                            CONTRACTOR_FILE_TYPES_TRANSLATE[type]
                                    }
                                    labelClass="order-event-log-history__label-check"
                                />
                                <Text
                                    level="2"
                                    className="order-event-log-history__comment"
                                >
                                    Комментарий: 
                                    {" "}
                                    {DECISION_REASON_TRANSLATE[scanType][decisionType]}
                                </Text>
                                {
                                    rejectionReason &&
                                    <Text
                                        level="2"
                                        className="order-event-log-history__comment"
                                    >
                                        Причина: 
                                        {" "}
                                        {rejectionReason}
                                    </Text>
                                }
                            </div>
                        );
                    })
                }
            </div>
        </>;
    }

    return (
        <NmAdvancedTooltip
            type="light"
            position={[lengthList - 1 , 0].includes(indexElem) ? "bottom-left" : "bottom-right"}
            classNameTooltip="order-event-log-history"
            trigger={trigger}
        >
            <div className="order-event-log-history-content">
                {status === STATUSES.BANK_CHECK_FAILED.VALUE ? <div className="order-event-log-history__label-check">
                    {decisionReason}
                </div> : <>
                    {renderSuccessFiles()}
                    {renderFailFiles()}
                    {renderUnknownFiles()}
                </>}
            </div>
        </NmAdvancedTooltip>
    );
}