import {getNumberFromFormattedAmount} from "../../../../utils/stringFormat";

export function getRequestData(formData){
    const {
        amount,
    } = formData;

    return {
        ...formData,
        amount: getNumberFromFormattedAmount(amount),
    };
}