import {toastWarning} from "../../../../utils/toastHelper";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {ORDER_OFFERED_SPECIALITY_WARNING_PUBLICATION_MESSAGE} from "../../../../constants/order";

export const checkOfferedSpecialityWarning = (params) => {
    const {
        creationStatus,
        status,
        offeredSpecialityName,
    } = params;

    if (
        creationStatus !== ORDER_STATUS.DRAFT &&
        status === ORDER_STATUS.DRAFT &&
        Boolean(offeredSpecialityName)
    ) {
        toastWarning(ORDER_OFFERED_SPECIALITY_WARNING_PUBLICATION_MESSAGE);
    }
};