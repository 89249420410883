import {toast} from "react-toastify";
import {debounce} from "lodash";

export const toastSuccess = (message) => {
    toast(message, {type: "success"});
};

export const toastError = debounce((message, autoClose = 20000) => {
    toast(message || "При выполнении произошла ошибка, попробуйте позднее.", {
        type: "error",
        autoClose,
    });
}, 250);

export const toastWarning = (message, autoClose = true) => {
    return toast(message, {type: "warning", autoClose});
};
