import React from "react";
import {withTranslation} from "react-i18next";
import {useMedia} from "react-media";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmLoader from "../../../components/NmLoader";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {formatAmount, formatNumber} from "../../../utils/stringFormat";

import PropTypes from "prop-types";

import "./style.sass";

const getTooltipPosition = (isTopPosition) => isTopPosition ? "top" : "bottom";
const isTopPosition = (
    listLength,
    currentPosition,
    rowCountWithTopPosition,
    minLengthForTopPosition,
) => ((listLength - currentPosition) < rowCountWithTopPosition) && listLength > minLengthForTopPosition;

const NmDepositHistoryWindow = props => {
    const {
        t,
        arrivalFieldName,
        dateFieldName,
        data,
        progress,
        currentPosition,
        listLength,
        onClick,
    } = props;

    const isMobile = useMedia({query: {maxWidth: 767}});

    const getPosition = () => {
        if (isMobile) {
            const minLengthForTopPosition = 10;
            const rowCountWithTopPositionInMobile = 7;
            const _isTopPosition = isTopPosition(
                listLength,
                currentPosition,
                rowCountWithTopPositionInMobile,
                minLengthForTopPosition,
            );

            return getTooltipPosition(_isTopPosition);
        }

        const minLengthForTopPosition = 5;
        const rowCountWithTopPosition = 4;
        const _isTopPosition = isTopPosition(
            listLength,
            currentPosition,
            rowCountWithTopPosition,
            minLengthForTopPosition,
        );

        return getTooltipPosition(_isTopPosition);
    };

    const getTooltipHeader = () => {
        return (
            <div className="nm-deposit-history-window__header">
                <div className="col-7 col-md-6">
                    {t("finance-pivot-table.coming")}
                </div>
                <div className="col-9 col-md-10 ms-xl-1">
                    {t("finance-pivot-table.date")}
                </div>
            </div>
        );
    };

    const getTooltipData = () => {
        if (progress) {
            return (
                <NmLoader
                    classNameLoader="nm-deposit-history-window__loader"
                    classNameDimmer="nm-deposit-history-window__dimmer"
                    content=""
                    transparentBackground
                    relativeDimmer
                    inverted
                    active
                />
            );
        }

        return data.map((item, index) => {
            return (
                <div
                    className="nm-deposit-history-window__row"
                    key={index}
                >
                    <div className="col-7 col-md-6">
                        {formatAmount(formatNumber(item[arrivalFieldName]))}
                    </div>
                    <div className="col-9 col-md-10 ps-xl-1">
                        {dateFormat(convertUtcToLocal(item[dateFieldName]))}
                    </div>
                </div>
            );
        });
    };

    return (
        <HelpTooltip
            noAdaptive={true}
            noArrowInMobile={true}
            position={getPosition()}
            classNameTooltip="nm-deposit-history-window__body"
            classNameText="nm-deposit-history-window__tooltip-text"
            info
            onClick={onClick}
            compound={true}
            type="light"
            body={
                [{
                    titleBlock: getTooltipHeader(),
                    textBlock: getTooltipData(),
                }]
            }
        />
    );
};

NmDepositHistoryWindow.propTypes = {
    arrivalFieldName: PropTypes.string,
    dateFieldName: PropTypes.string,
    data: PropTypes.array,
};

export default withTranslation()(NmDepositHistoryWindow);