export const CLIENT_BRAND_TOOLTIP_TEXT = "Раздел предназначен для добавления разных брендов на объекты. " +
    "В списке отображаются созданные компанией бренды с возможностью применения, редактирования, удаления бренда; " +
    "а так же созданные бренды группой компаний с возможностью только применения данного бренда на объекты компании.\n" +
    "Для удаления брендов у объектов выберете в контекстом меню \"Удалить объекты от брендов\". " +
    "При выполнении этой операции на объекте будет отображаться бренд компании (при наличии)";

export const CLIENT_BRAND_LOG_TYPE = {
    CREATE_BRAND: "CREATE_BRAND", // Пользователь добавил бренд
    UPDATE_BRAND: "UPDATE_BRAND", // Пользователь отредактировал бренд
    UPDATE_LOGO_BRAND: "UPDATE_LOGO_BRAND", // Пользователь отредактировал логотип бренда
    APP_BRAND: "APP_BRAND", // Пользователь применил бренд
    TAKE_OFF_BRAND: "TAKE_OFF_BRAND", // Пользователь удалил бренды из объектов
    ARCHIVE_BRAND: "ARCHIVE_BRAND", // Пользователь переместил бренд в архив
    RECOVER_BRAND: "RECOVER_BRAND", // Пользователь восстановил бренд из архива
};

export const CLIENT_BRAND_ACTION_ERROR_CODE = {
    NOT_RIGHTS_APP_BRAND: "NOT_RIGHTS_APP_BRAND", // Нет прав на применение/удаление бренда
    NOT_RIGHTS_ARCHIVE_BRAND: "NOT_RIGHTS_ARCHIVE_BRAND", // Нет прав на архивацию бренда
    NOT_RIGHTS_RECOVER_ARCHIVE_BRAND: "NOT_RIGHTS_RECOVER_ARCHIVE_BRAND", // Нет прав на восстановление бренда из архива
    NOT_RIGHTS_ADD_BRAND: "NOT_RIGHTS_ADD_BRAND", // Нет прав на создание бренда
    NOT_RIGHTS_UPDATE_BRAND: "NOT_RIGHTS_UPDATE_BRAND", // Нет прав на редактирование бренда
};