export const SMART_LINK_FILTER_BODY_TYPE = {
    NUMBERS: "NUMBERS", // По номерам
    FILTERS: "FILTERS", // по фильтрам
};
export const SMART_LINK_FORM_TYPE = {
    ORDER: "ORDER",
    TASK: "TASK",
};
export const SMART_LINK_WARNING_COUNT = 250;

export const SMART_LINK_EDIT_STEP = {
    GENERAL_FILTERS: "GENERAL_FILTERS", // Основные фильтры
    CLIENT_SETTINGS: "CLIENT_SETTINGS", // Выбор заказчика
};

export const SMART_LINK_EDIT_STEPS = [
    {
        name: "Основные фильтры",
        value: SMART_LINK_EDIT_STEP.GENERAL_FILTERS,
    },
    {
        name: "Выбор заказчика",
        value: SMART_LINK_EDIT_STEP.CLIENT_SETTINGS,
    },
];