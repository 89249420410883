import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {getInitialTouched} from "../../../../../../../../utils/objectHelper";
import {
    formatAmountWithComma,
    getNumberFromFormattedAmount,
} from "../../../../../../../../utils/stringFormat";
import {convertStringToDate, handleFormString} from "../../../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../../../utils/toastHelper";

import {
    addSettingsDirectoriesBanksBalance,
    editSettingsDirectoriesBanksBalance,
    getNewSettingsDirectoriesBanksBalance,
    settingsDirectoriesBanksBalancesBankListSelector,
} from "../../../../../../../../ducks/settingsDirectoriesBanks";

export const useSettingsDirectoriesBanksBalancesEditModalForm = (props) => {
    const {
        isEdit,
        editData,
        onClose,
        fetchList,
    } = props;

    const bankList = useSelector(settingsDirectoriesBanksBalancesBankListSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEdit) {
            dispatch(getNewSettingsDirectoriesBanksBalance());
        }
    }, []);

    const initialValues = useMemo(() => {
        const {
            balance,
            lastUpdateDatetime,
            ...otherData
        } = editData;

        return {
            bankBalanceId: null,
            bankName: "",
            balance: balance ? formatAmountWithComma(balance) : "0",
            lastUpdateDatetime: lastUpdateDatetime ? convertStringToDate(lastUpdateDatetime) : null,
            ...otherData,
        };
    }, [editData]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const reqData = {
            bankBalanceId: values.bankBalanceId || undefined,
            bankName: handleFormString(values.bankName),
            balance: getNumberFromFormattedAmount(values.balance),
            lastUpdateDatetime: values.lastUpdateDatetime.toISOString(),
            onSuccess: () => {
                toastSuccess(
                    isEdit ?
                        "Значение для банка успешно добавлено" :
                        "Значение для банка успешно изменено",
                );
                fetchList();
                onClose();
            },
        };

        if (isEdit) {
            dispatch(editSettingsDirectoriesBanksBalance(reqData));

            return;
        }

        dispatch(addSettingsDirectoriesBanksBalance(reqData));
    };

    const handleChange = (e, {name, value}) => {
        if (name === "bankBalanceId" && value) {
            const {bankName = ""} = bankList.find(item => item.bankBalanceId === value) || {};

            setValues(prevState => ({
                ...prevState,
                name: value,
                bankName,
            }));

            return;
        }

        setFieldValue(name, value);
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues: initialValues,
        initialTouched: getInitialTouched(initialValues),
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        setValues,
        touched,
        errors,
        setFieldValue,
        handleSubmit,
        handleChange,
    };
};