import {useState} from "react";
import {useDispatch} from "react-redux";

import {advertisementClose} from "../../../../ducks/advertisement";

export function useAdvertisementAction() {
    const [isInvitePopupShown, setInvitePopupShown] = useState(false);
    const [contractorId, setContractorId] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [isShowEditModal, setShowEditModal] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const onCloseEditModal = () => {
        setShowEditModal(false);
        setIsEdit(false);
        setDuplicate(false);
    };

    const closeAdvertisement = ({requestData, toastSuccess, onSuccess}) => {
        dispatch(advertisementClose({
            requestData,
            toastSuccess,
            onSuccess,
        }));
    };

    return {
        isInvitePopupShown,
        setInvitePopupShown,
        contractorId,
        setContractorId,
        isShowConfirm,
        setIsShowConfirm,
        isShowEditModal,
        setShowEditModal,
        isEdit,
        onCloseEditModal,
        duplicate,
        setDuplicate,
        setIsEdit,
        closeAdvertisement,
    };
}