export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_ERROR";

export const CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE = "CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE";

export const UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE = "UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE";