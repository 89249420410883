import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

export function useSelectedList(params = {}) {
    const {
        list = [],
        idName,
    } = params;

    const [countSelected, setCountSelected] = useState(0);
    const [selectedList, setSelectedList] = useState(list);
    const [selectedIds, setSelectedIds] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        if (!isEmpty(list)) {
            setSelectedList(list);
            clearSelectedValues();
        }
    }, [
        list,
    ]);

    const handleSelectedRows = (list, isAllSelected) => {
        setIsAllSelected(isAllSelected);
        setSelectedList([...list]);

        const selected = list.filter(value => value.isSelected);

        setCountSelected(selected.length);

        if (idName) {
            const ids = selected.map(item => item[idName]);

            setSelectedIds(ids);
        }
    };

    const clearSelectedValues = () => {
        setIsAllSelected(false);
        setCountSelected(0);

        if (idName) {
            setSelectedIds(null);
        }
    };

    const clearSelectedRows = () => {
        setSelectedList([]);

        clearSelectedValues();
    };

    return {
        selectedList,
        isAllSelected,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
        selectedIds,
    };
}