import React from "react";
import {useSelector} from "react-redux";

import ApplyButtons from "../../../../ApplyButtons";
import Portal from "../../../../Portal";

import {bffClientsSettingsPaymentsProgressActionSelector} from "../../../../../ducks/bff/clients/settings/selectors";

export const ClientSettingsSubmitButtons = (props) => {
    const {
        refFooter,
        onClose,
        onSubmit,
    } = props;

    const progressAction = useSelector(bffClientsSettingsPaymentsProgressActionSelector);

    return (
        <Portal node={refFooter.current}>
            <ApplyButtons
                disabled={progressAction}
                cancelBtnDisabled={progressAction}
                isHiddenAdditionalOnMobile={true}
                mobile="column"
                additionalBtnContent="Отменить"
                onClickAdditional={onClose}
                cancelBtnContent="Сохранить и закрыть"
                cancelBtnColor="light-green"
                onClose={() => onSubmit({isClose: true})}
                submitBtnContent="Сохранить"
                submit={() => onSubmit({isClose: false})}
            />
        </Portal>
    );
};