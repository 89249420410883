export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_SUCCESS = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_SUCCESS";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_SUCCESS = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_SUCCESS";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_SUCCESS = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_SUCCESS";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_REQUEST = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_REQUEST";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_SUCCESS = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_SUCCESS";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_ERROR = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_ERROR";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_SUCCESS = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_SUCCESS";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_SUCCESS = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_SUCCESS";
export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CLEAR_STORE = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CLEAR_STORE";

export const CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_UPDATE_STORE = "CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_UPDATE_STORE";