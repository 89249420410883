import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getUtcDateFilter} from "../../../../../utils/dateFormat";

import {
    getDictionariesTaskStatuses,
    getDictionariesTaskTypes,
    getTaskStats, updateJobFieldStore,
} from "../../../../../ducks/job";

export const useSettingsServiceTaskStatsFetch = ({
    filterData,
    pageNum,
    pageSize,
    usageCountSort,
}) => {

    const {
        creationDateTimeFrom,
        creationDateTimeTo,
        dateTimeGrouping,
        taskStatuses,
        taskTypes,
    } = filterData;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        usageCountSort,
    ]);

    useEffect(() => {
        getDictionaries();

        return () => {
            dispatch(updateJobFieldStore({
                stats: {
                    pageData: {},
                    results: [],
                    totalCount: 0,
                },
                dictionaries: {
                    taskStatuses: [],
                    taskTypes: [],
                },
            }));
        };
    }, []);

    const getDictionaries = () => {
        dispatch(getDictionariesTaskStatuses());
        dispatch(getDictionariesTaskTypes());
    };

    const fetchList = () => {
        dispatch(getTaskStats({
            dateTimeFrom: getUtcDateFilter(creationDateTimeFrom),
            dateTimeGrouping,
            dateTimeTo: getUtcDateFilter(creationDateTimeTo),
            orderType: usageCountSort,
            pageNum,
            pageSize,
            taskStatuses,
            taskTypes,
        }));
    };

    return {
        fetchList,
    };
};