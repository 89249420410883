import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastSuccess} from "../utils/toastHelper";
import {toastError} from "../utils/toastHelper";

const controller = "/clientgroups";
//*  TYPES  *//

const GET_CLIENT_GROUPS_PAGE_REQUEST = "GET_CLIENT_GROUPS_PAGE_REQUEST";
const GET_CLIENT_GROUPS_PAGE_SUCCESS = "GET_CLIENT_GROUPS_PAGE_SUCCESS";
const GET_CLIENT_GROUPS_PAGE_ERROR = "GET_CLIENT_GROUPS_PAGE_ERROR";


const ADD_CLIENT_GROUP_REQUEST = "ADD_CLIENT_GROUP_REQUEST";
const ADD_CLIENT_GROUP_SUCCESS = "ADD_CLIENT_GROUP_SUCCESS";
const ADD_CLIENT_GROUP_ERROR = "ADD_CLIENT_GROUP_ERROR";

const EDIT_CLIENT_GROUP_REQUEST = "EDIT_CLIENT_GROUP_REQUEST";
const EDIT_CLIENT_GROUP_SUCCESS = "EDIT_CLIENT_GROUP_SUCCESS";
const EDIT_CLIENT_GROUP_ERROR = "EDIT_CLIENT_GROUP_ERROR";

const DELETE_CLIENT_GROUP_REQUEST = "DELETE_CLIENT_GROUP_REQUEST";
const DELETE_CLIENT_GROUP_SUCCESS = "DELETE_CLIENT_GROUP_SUCCESS";
const DELETE_CLIENT_GROUP_ERROR = "DELETE_CLIENT_GROUP_ERROR";

const GET_CLIENTS_PAGE_REQUEST = "GET_CLIENTS_PAGE_REQUEST";
const GET_CLIENTS_PAGE_SUCCESS = "GET_CLIENTS_PAGE_SUCCESS";
const GET_CLIENTS_PAGE_ERROR = "GET_CLIENTS_PAGE_ERROR";

const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR";

const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";

const GET_CONTRACTORS_PAGE_REQUEST = "GET_CONTRACTORS_PAGE_REQUEST";
const GET_CONTRACTORS_PAGE_SUCCESS = "GET_CONTRACTORS_PAGE_SUCCESS";
const GET_CONTRACTORS_PAGE_ERROR = "GET_CONTRACTORS_PAGE_ERROR";

const ADD_CONTRACTOR_REQUEST = "ADD_CONTRACTOR_REQUEST";
const ADD_CONTRACTOR_SUCCESS = "ADD_CONTRACTOR_SUCCESS";
const ADD_CONTRACTOR_ERROR = "ADD_CONTRACTOR_ERROR";

const DELETE_CONTRACTOR_REQUEST = "DELETE_CONTRACTOR_REQUEST";
const DELETE_CONTRACTOR_SUCCESS = "DELETE_CONTRACTOR_SUCCESS";
const DELETE_CONTRACTOR_ERROR = "DELETE_CONTRACTOR_ERROR";

const GET_INFO_GROUP_REQUEST = "GET_INFO_GROUP_REQUEST";
const GET_INFO_GROUP_SUCCESS = "GET_INFO_GROUP_SUCCESS";
const GET_INFO_GROUP_ERROR = "GET_INFO_GROUP_ERROR";

const IMPORT_GROUP_CONTRACTORS_REQUEST = "IMPORT_GROUP_CONTRACTORS_REQUEST";
const IMPORT_GROUP_CONTRACTORS_SUCCESS = "IMPORT_GROUP_CONTRACTORS_SUCCESS";
const IMPORT_GROUP_CONTRACTORS_ERROR = "IMPORT_GROUP_CONTRACTORS_ERROR";

const IMPORT_CLIENTS_OR_CONTRACTORS_REQUEST = "IMPORT_CLIENTS_OR_CONTRACTORS_REQUEST";
const IMPORT_CLIENTS_OR_CONTRACTORS_SUCCESS = "IMPORT_CLIENTS_OR_CONTRACTORS_SUCCESS";
const IMPORT_CLIENTS_OR_CONTRACTORS_ERROR = "IMPORT_CLIENTS_OR_CONTRACTORS_ERROR";

//*  INITIAL STATE  *//

const initial = {
    clientGroupList: [],
    error: {},
    clientGroupTotalCount: 0,
    clientGroupPageData: {},
    clientsList: [],
    clientsTotalCount: 0,
    clientsPageData: {},
    contractorList: [],
    contractorTotalCount: 0,
    contractorPageData: {},
    clientGroupCard: {},
    actionProgress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CLIENT_GROUPS_PAGE_REQUEST:
        return {
            ...state,
            clientGroupPageData: payload,
        };
    case GET_CLIENT_GROUPS_PAGE_SUCCESS:
        const {
            results: clientGroupList,
            totalCount: clientGroupTotalCount,
        } = payload;

        return {
            ...state,
            clientGroupList,
            clientGroupTotalCount,
        };
    case GET_CLIENT_GROUPS_PAGE_ERROR:
        return {
            ...state,
            error: payload,
        };
    case GET_CLIENTS_PAGE_REQUEST:
        return {
            ...state,
            clientsPageData: payload,
        };
    case GET_CONTRACTORS_PAGE_REQUEST:
        return {
            ...state,
            contractorPageData: payload,
        };
    case ADD_CLIENT_GROUP_REQUEST:
    case EDIT_CLIENT_GROUP_REQUEST:
    case DELETE_CLIENT_GROUP_REQUEST:
    case ADD_CLIENT_REQUEST:
    case DELETE_CLIENT_REQUEST:
    case DELETE_CONTRACTOR_REQUEST:
    case ADD_CONTRACTOR_REQUEST:
    case GET_INFO_GROUP_REQUEST:
    case IMPORT_GROUP_CONTRACTORS_REQUEST:
    case IMPORT_CLIENTS_OR_CONTRACTORS_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case ADD_CLIENT_GROUP_SUCCESS:
    case EDIT_CLIENT_GROUP_SUCCESS:
    case DELETE_CLIENT_GROUP_SUCCESS:
    case ADD_CLIENT_SUCCESS:
    case DELETE_CLIENT_SUCCESS:
    case DELETE_CONTRACTOR_SUCCESS:
    case ADD_CONTRACTOR_SUCCESS:
    case IMPORT_GROUP_CONTRACTORS_SUCCESS:
    case IMPORT_CLIENTS_OR_CONTRACTORS_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case ADD_CLIENT_GROUP_ERROR:
    case EDIT_CLIENT_GROUP_ERROR:
    case DELETE_CLIENT_GROUP_ERROR:
    case GET_CLIENTS_PAGE_ERROR:
    case ADD_CLIENT_ERROR:
    case DELETE_CLIENT_ERROR:
    case DELETE_CONTRACTOR_ERROR:
    case GET_CONTRACTORS_PAGE_ERROR:
    case ADD_CONTRACTOR_ERROR:
    case GET_INFO_GROUP_ERROR:
    case IMPORT_GROUP_CONTRACTORS_ERROR:
    case IMPORT_CLIENTS_OR_CONTRACTORS_ERROR:
        return {
            ...state,
            actionProgress: false,
            error: payload,
        };
    case GET_CLIENTS_PAGE_SUCCESS:
        const {
            clients: clientsList,
            totalCount: clientsTotalCount,
        } = payload;
        return {
            ...state,
            clientsList,
            clientsTotalCount,
            actionProgress: false,
        };
    case GET_CONTRACTORS_PAGE_SUCCESS:
        const {
            contractors: contractorList,
            totalCount: contractorTotalCount,
        } = payload;
        return {
            ...state,
            contractorList: contractorList || [],
            contractorTotalCount,
            actionProgress: false,
        };
    case GET_INFO_GROUP_SUCCESS:
        return {
            ...state,
            clientGroupCard: payload,
            actionProgress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientGroups(payload) {
    return {
        type: GET_CLIENT_GROUPS_PAGE_REQUEST,
        payload,
    };
}

export function addContractorIntoClientGroup(payload) {
    return {
        type: ADD_CONTRACTOR_REQUEST,
        payload,
    };
}

export function getListClients(payload) {
    return {
        type: GET_CLIENTS_PAGE_REQUEST,
        payload,
    };
}

export function getListContractors(payload) {
    return {
        type: GET_CONTRACTORS_PAGE_REQUEST,
        payload,
    };
}

export function addClientsGroup(payload) {
    return {
        type: ADD_CLIENT_GROUP_REQUEST,
        payload,
    };
}

export function editClientsGroup(payload) {
    return {
        type: EDIT_CLIENT_GROUP_REQUEST,
        payload,
    };
}

export function deleteClientsGroup(payload) {
    return {
        type: DELETE_CLIENT_GROUP_REQUEST,
        payload,
    };
}

export function deleteContractor(payload) {
    return {
        type: DELETE_CONTRACTOR_REQUEST,
        payload,
    };
}

export function addClient(payload) {
    return {
        type: ADD_CLIENT_REQUEST,
        payload,
    };
}

export function deleteClient(payload) {
    return {
        type: DELETE_CLIENT_REQUEST,
        payload,
    };
}

export function getInfoGroup(payload) {
    return {
        type: GET_INFO_GROUP_REQUEST,
        payload,
    };
}

export function importContractorsOrClients(payload) {
    return {
        type: IMPORT_GROUP_CONTRACTORS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientGroupsSelector = ({clientGroups}) => clientGroups;
export const clientGroupsListSelector = createSelector(clientGroupsSelector, ({clientGroupList}) => clientGroupList);
export const clientGroupsListOptionsSelector = createSelector(clientGroupsSelector,
    ({clientGroupList}) => clientGroupList.map(({groupId, groupName}) => {
        return {
            key: groupId,
            text: groupName,
            value: groupId,
        };
    }));
export const clientGroupsTotalPagesSelector = createSelector(clientGroupsSelector,
    ({clientGroupTotalCount, clientGroupPageData: {pageSize = 0}}) => getTotalPages(clientGroupTotalCount, pageSize));
export const clientGroupsTotalCountSelector = createSelector(clientGroupsSelector, ({clientGroupTotalCount}) => clientGroupTotalCount);

export const clientsListSelector = createSelector(clientGroupsSelector, ({clientsList}) => clientsList);
export const clientsTotalPagesSelector = createSelector(clientGroupsSelector,
    ({clientsTotalCount, clientsPageData: {pageSize = 0}}) => getTotalPages(clientsTotalCount, pageSize));
export const clientsTotalCountSelector = createSelector(clientGroupsSelector, ({clientsTotalCount}) => clientsTotalCount);

export const contractorsListSelector = createSelector(clientGroupsSelector, ({contractorList}) => contractorList);
export const contractorsTotalPagesSelector = createSelector(clientGroupsSelector,
    ({contractorTotalCount, contractorPageData: {pageSize = 0}}) => getTotalPages(contractorTotalCount, pageSize));
export const contractorsTotalCountSelector = createSelector(clientGroupsSelector, ({contractorTotalCount}) => contractorTotalCount);

export const clientGroupCardSelector = createSelector(clientGroupsSelector, ({clientGroupCard}) => clientGroupCard);
export const clientGroupsProgress = createSelector(clientGroupsSelector, ({actionProgress}) => actionProgress);
//*  SAGA  *//
const addContractorIntoClientGroupSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            contractorId,
            groupId,
        } = data;

        const result = yield request.post(`${controller}/addContractorGroup?contractorId=${contractorId}&groupId=${groupId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: ADD_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: ADD_CONTRACTOR_SUCCESS, payload: result});

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: ADD_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientgroups/getGroupsPage
const getClientGroupsSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...requestData
        } = payload;

        const result = yield request.post(`${controller}/getGroupsPage`, requestData);

        const {errorMessage} = result;

        yield getResult(errorMessage);

        if (errorMessage) {
            console.error("getClientGroupsSaga: ", errorMessage);
            yield put({
                type: GET_CLIENT_GROUPS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_GROUPS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENT_GROUPS_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientgroups/addGroup
const addClientsGroupsSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData,
        } = payload;

        const result = yield request.post(`${controller}/addGroup`, requestData);

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;


        if (errorMessage) {
            console.error("addClientsGroupsSaga: ", errorMessage);
            yield put({
                type: ADD_CLIENT_GROUP_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {
            clientGroups: {
                clientGroupPageData,
            },
        } = yield select();

        yield put(getClientGroups(clientGroupPageData));

        yield put({type: ADD_CLIENT_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: ADD_CLIENT_GROUP_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientgroups/addClientsGroup
const addClientSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData: {
                groupId,
                clientIds,
            },
        } = payload;

        const result = yield request.post(`${controller}/addClientsGroup?groupId=${groupId}`, clientIds);

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("addClientsGroupsSaga: ", errorMessage);
            yield put({
                type: ADD_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {
            clientGroups: {
                clientsPageData,
            },
        } = yield select();

        yield put(getListClients(clientsPageData));
        yield put(getInfoGroup(groupId));

        yield put({type: ADD_CLIENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: ADD_CLIENT_ERROR,
            payload: error,
        });
    }
};

//PUT /api/clientgroups/updateGroup
const editClientsGroupsSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData,
        } = payload;

        const result = yield request.put(`${controller}/updateGroup`, requestData);

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("editClientsGroupsSaga: ", errorMessage);
            yield put({
                type: EDIT_CLIENT_GROUP_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {
            clientGroups: {
                clientGroupPageData,
            },
        } = yield select();

        yield put(getClientGroups(clientGroupPageData));

        yield put({type: EDIT_CLIENT_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: EDIT_CLIENT_GROUP_ERROR,
            payload: error,
        });
    }
};

//DELETE /api/clientgroups/deleteGroup
const deleteClientsGroupsSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData: {
                groupId,
            },
        } = payload;

        const result = yield request.delete(`${controller}/deleteGroup?groupId=${groupId}`);

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("deleteClientsGroupsSaga: ", errorMessage);
            yield put({
                type: DELETE_CLIENT_GROUP_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Группа успешно удалена");

        const {
            clientGroups: {
                clientGroupPageData,
            },
        } = yield select();

        yield put(getClientGroups(clientGroupPageData));

        yield put({type: DELETE_CLIENT_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: DELETE_CLIENT_GROUP_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientgroups/getGroupClientsPage
const getListClientsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getGroupClientsPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("editClientsGroupsSaga: ", errorMessage);
            yield put({
                type: GET_CLIENTS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENTS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENTS_PAGE_ERROR,
            payload: error,
        });
    }
};

//DELETE /api/clientgroups/deleteClientGroup
const deleteClientSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData: {
                groupId,
                clientId,
            },
        } = payload;

        const result = yield request.delete(`${controller}/deleteClientGroup?groupId=${groupId}&clientId=${clientId}`);

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("deleteClientSaga: ", errorMessage);
            yield put({
                type: DELETE_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Группа компаний успешно обновлена");

        const {
            clientGroups: {
                clientsPageData,
            },
        } = yield select();

        yield put(getListClients(clientsPageData));
        yield put(getInfoGroup(groupId));

        yield put({type: DELETE_CLIENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: DELETE_CLIENT_ERROR,
            payload: error,
        });
    }
};

//DELETE /api/clientgroups/deleteContractorGroup
const deleteContractorSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData: {
                groupId,
                contractorId,
            },
        } = payload;

        const result = yield request.delete(`${controller}/deleteContractorGroup?groupId=${groupId}&contractorId=${contractorId}`);

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("deleteClientSaga: ", errorMessage);
            yield put({
                type: DELETE_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Группа исполнителей успешно обновлена");

        const {
            clientGroups: {
                contractorPageData,
            },
        } = yield select();

        yield put(getListContractors(contractorPageData));
        yield put(getInfoGroup(groupId));

        yield put({type: DELETE_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: DELETE_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientgroups/getGroupContractorsPage
const getListContractorSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getGroupContractorsPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("getListContractorSaga: ", errorMessage);
            yield put({
                type: GET_CONTRACTORS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_CONTRACTORS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CONTRACTORS_PAGE_ERROR,
            payload: error,
        });
    }
};

// /api/clientgroups/getInfoGroup
const getInfoGroupSaga = function* ({payload}) {

    try {
        const result = yield request.get(`${controller}/getInfoGroup`, {params: {groupId: payload}});

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("editClientsGroupsSaga: ", errorMessage);
            yield put({
                type: GET_INFO_GROUP_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_INFO_GROUP_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_INFO_GROUP_ERROR,
            payload: error,
        });
    }
};

const importContractorsOrClientsSaga = function* ({payload}) {
    try {
        const {
            meta,
            requestData: {
                groupId,
                file,
                tab,
            },
        } = payload;

        const result = yield request.post(`/job/clientGroup/importDataGroup?groupId=${groupId}`, file, {...getMultipartHeaders()});

        if (meta) {
            yield call(meta, null);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            console.error("importContractorsOrClientsSaga: ", errorMessage);
            yield put({
                type: IMPORT_GROUP_CONTRACTORS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }
        const TABS_GROUP_COMPANIES = {
            CLIENTS: "clients",
            CONTRACTORS: "contractors",
        };

        yield put(getInfoGroup(groupId));

        yield put({type: IMPORT_GROUP_CONTRACTORS_SUCCESS, payload: result});

        const {
            clientGroups: {
                contractorPageData,
                clientsPageData,
            },
        } = yield select();

        if (tab === TABS_GROUP_COMPANIES.CLIENTS) {
            yield put(getListClients(clientsPageData));

            return {
                done: true,
            };
        }

        yield put(getListContractors(contractorPageData));
    } catch (error) {
        yield put({
            type: IMPORT_GROUP_CONTRACTORS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_CONTRACTOR_REQUEST, addContractorIntoClientGroupSaga),
        takeEvery(GET_CLIENT_GROUPS_PAGE_REQUEST, getClientGroupsSaga),
        takeEvery(ADD_CLIENT_GROUP_REQUEST, addClientsGroupsSaga),
        takeEvery(EDIT_CLIENT_GROUP_REQUEST, editClientsGroupsSaga),
        takeEvery(DELETE_CLIENT_GROUP_REQUEST, deleteClientsGroupsSaga),
        takeEvery(GET_CLIENTS_PAGE_REQUEST, getListClientsSaga),
        takeEvery(ADD_CLIENT_REQUEST, addClientSaga),
        takeEvery(DELETE_CLIENT_REQUEST, deleteClientSaga),
        takeEvery(GET_CONTRACTORS_PAGE_REQUEST, getListContractorSaga),
        takeEvery(GET_INFO_GROUP_REQUEST, getInfoGroupSaga),
        takeEvery(DELETE_CONTRACTOR_REQUEST, deleteContractorSaga),
        takeEvery(IMPORT_CLIENTS_OR_CONTRACTORS_REQUEST, importContractorsOrClientsSaga),
        takeEvery(IMPORT_GROUP_CONTRACTORS_REQUEST, importContractorsOrClientsSaga),
    ]);
}
