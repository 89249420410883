import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/dicts";

const DICT_UNIT_FETCH = "UNIT_FETCH";
const DICT_UNIT_SAVE = "UNIT_SAVE";

const DICT_INDUSTRIES_FETCH = "DICT_INDUSTRIES_FETCH";
const DICT_INDUSTRIES_SAVE = "DICT_INDUSTRIES_SAVE";
const DICT_INDUSTRIES_ERROR = "DICT_INDUSTRIES_ERROR";

const DICT_ARRIVAL_DOC_REQUEST = "DICT_ARRIVAL_DOC_REQUEST";
const DICT_ARRIVAL_DOC_SUCCESS = "DICT_ARRIVAL_DOC_SUCCESS";
const DICT_ARRIVAL_DOC_ERROR = "DICT_ARRIVAL_DOC_ERROR";

const DICT_CONTRACTOR_PASSPORT_TYPE_REQUEST = "DICT_CONTRACTOR_PASSPORT_TYPE_REQUEST";
const DICT_CONTRACTOR_PASSPORT_TYPE_SUCCESS = "DICT_CONTRACTOR_PASSPORT_TYPE_SUCCESS";
const DICT_CONTRACTOR_PASSPORT_TYPE_ERROR = "DICT_CONTRACTOR_PASSPORT_TYPE_ERROR";

const DICT_UNIT_ERROR = "UNIT_ERROR";

//*  INITIAL STATE  *//

const
    initial = {
        units: {},
        industries: {},
        loading: false,
        error: {},
        arrivalDocTypeDict: {},
        passportTypeDict: {},
    };

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case DICT_UNIT_FETCH:
    case DICT_INDUSTRIES_FETCH:
        return {
            ...state,
            loading: true,
        };
    case DICT_UNIT_SAVE:
        return {
            ...state,
            units: payload,
            loading: false,
        };
    case DICT_INDUSTRIES_SAVE:
        return {
            ...state,
            industries: payload,
            loading: false,
        };
    case DICT_CONTRACTOR_PASSPORT_TYPE_SUCCESS:
        return {
            ...state,
            passportTypeDict: payload,
        };
    case DICT_ARRIVAL_DOC_SUCCESS:
        return {
            ...state,
            arrivalDocTypeDict: payload,
            loading: false,
        };
    case DICT_INDUSTRIES_ERROR:
    case DICT_UNIT_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function fetchUnits() {
    return {type: DICT_UNIT_FETCH};
}

export function fetchIndustries() {
    return {type: DICT_INDUSTRIES_FETCH};
}

export function fetchContractorArrivalDocTypeDict() {
    return {
        type: DICT_ARRIVAL_DOC_REQUEST,
    };
}

export function fetchContractorPassportTypeDict() {
    return {
        type: DICT_CONTRACTOR_PASSPORT_TYPE_REQUEST,
    };
}

//*  SELECTORS  *//

export const dictSelector = state => state.dict;
export const industryListSelector = createSelector(dictSelector, ({industries}) => dictionaryToOptions(industries));
export const getContractorArrivalDocTypeDictOptionsSelector = createSelector(dictSelector, ({arrivalDocTypeDict}) => dictionaryToOptions(arrivalDocTypeDict));
export const getContractorArrivalDocTypeDictSelector = createSelector(dictSelector, ({arrivalDocTypeDict}) => arrivalDocTypeDict);
export const getPassportTypeDictSelector = createSelector(dictSelector, ({passportTypeDict}) => passportTypeDict);
export const getPassportTypeOptionsSelector = createSelector(dictSelector, ({passportTypeDict}) => dictionaryToOptions(passportTypeDict));

//*  SAGA  *//

export const fetchUnitsSaga = function* () {
    try {
        const result = yield request.post(`${controller}/units`, {});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DICT_UNIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DICT_UNIT_SAVE, payload: result});
    } catch (error) {
        yield put({
            type: DICT_UNIT_ERROR,
            payload: error,
        });
    }
};

export const fetchIndustriesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/industriesList`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DICT_INDUSTRIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DICT_INDUSTRIES_SAVE, payload: result});
    } catch (error) {
        yield put({
            type: DICT_INDUSTRIES_ERROR,
            payload: error,
        });
    }
};

///api/contractors/dicts/contractorPassportType
export const fetchContractorPassportDictTypeSaga = function* () {
    try {
        const result = yield request.get("contractors/dicts/contractorPassportType");
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DICT_INDUSTRIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DICT_INDUSTRIES_SAVE, payload: result});
    } catch (error) {
        yield put({
            type: DICT_INDUSTRIES_ERROR,
            payload: error,
        });
    }
};

// /api/contractors/dicts/contractorArrivalDocType
export const fetchContractorArrivalDocTypeDictSaga = function* () {
    try {
        const result = yield request.get("contractors/dicts/contractorArrivalDocType");
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DICT_ARRIVAL_DOC_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DICT_ARRIVAL_DOC_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: DICT_ARRIVAL_DOC_ERROR,
            payload: error,
        });
    }
};

// /api/contractors/dicts/contractorPassportType
export const fetchContractorPassportTypeDictSaga = function* () {
    try {
        const result = yield request.get("contractors/dicts/contractorPassportType");
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DICT_CONTRACTOR_PASSPORT_TYPE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DICT_CONTRACTOR_PASSPORT_TYPE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: DICT_CONTRACTOR_PASSPORT_TYPE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(DICT_UNIT_FETCH, fetchUnitsSaga),
        takeEvery(DICT_INDUSTRIES_FETCH, fetchIndustriesSaga),
        takeEvery(DICT_ARRIVAL_DOC_REQUEST, fetchContractorArrivalDocTypeDictSaga),
        takeEvery(DICT_CONTRACTOR_PASSPORT_TYPE_REQUEST, fetchContractorPassportTypeDictSaga),
    ]);
}

