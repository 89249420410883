import React, {useContext} from "react";
import {useSelector} from "react-redux";

import DictVariantsEditModal from "../../../../ActualComponents/DictVariantsEditModal";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

import {contractorDictsRecruitmentSchedulesSelector} from "../../../../../ducks/contractorsDicts";

export const RecruitmentCandidateSchedulesEdit = (props) => {
    const {
        onClose,
        initialValue,
    } = props;

    const schedulesDict = useSelector(contractorDictsRecruitmentSchedulesSelector);

    const {
        updateInformation,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);


    const onSubmit = ({value: schedules}) => {
        updateInformation({
            schedules,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    };

    return (
        <DictVariantsEditModal
            title="Подходящие графики работы"
            type="checkbox"
            multiple={true}
            initialValue={initialValue}
            progress={progressAction}
            onClose={onClose}
            onSubmit={onSubmit}
            fields={schedulesDict}
        />
    );
};
