import {useState} from "react";

import {useKedoDocumentTypesBff} from "./useBff";

import useConfirm, {closeConfirmAction, onConfirmAction, openConfirmAction} from "../../../../../hooks/useConfirm";

export const useKedoDocumentTypesAction = (params) => {
    const {
        fetchList,
        fetchCounts,
    } = params;

    const {
        deleteDocumentType,
    } = useKedoDocumentTypesBff();

    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm(true);

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData(null);
    };

    const onOpenEdit = (editData) => {
        if (editData) {
            setEditData(editData);
        }

        setOpenEdit(true);
    };

    const onDelete = (documentTypeId, clientId, onClose) => {
        deleteDocumentType({
            clientId,
            documentTypeId,
            onSuccess: () => {
                fetchCounts();
                fetchList();
                onClose();
            },
        });
    };

    const onConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    const onOpenDelete = ({documentTypeId, clientId}) => {
        dispatchConfirm(
            openConfirmAction(
                "Вы действительно хотите удалить данный тип загруженного документа из справочника?",
                ({onClose}) => onDelete(documentTypeId, clientId, onClose),
            ),
        );
    };

    const onCancelConfirm = () => {
        dispatchConfirm(closeConfirmAction());
    };

    return {
        isOpenEdit,
        editData,
        isOpenConfirm,
        contentConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    };
};