import {convertUtcToLocal} from "../../../../../../utils/dateFormat";
import {calculateRate} from "../../../../../../utils/mathHelper";
import {getNumberFromFormattedAmount} from "../../../../../../utils/stringFormat";

const getDeferredCurrentCommissionRate = (isAdditionalFields, form, name) => {
    if (!form[name].isDeferred) {
        return undefined;
    }

    if (isAdditionalFields) {
        return getNumberFromFormattedAmount(form[name].deferredMinCommission);
    }
    return +calculateRate(getNumberFromFormattedAmount(form[name].deferredRate));
};

export const getDepositRateEditRequestData = (form, fields) => {
    return fields.reduce((accum, value) => {
        const {
            name,
        } = value;

        if (name === "minimalCommissionNdflChange") {
            accum[name] = {
                currentCommissionRate: getNumberFromFormattedAmount(form[name].rate),
                currentCommissionRateDeferred: form[name].isDeferred ? getNumberFromFormattedAmount(form[name].deferredRate) : undefined,
                fromDatetime: form[name].isDeferred ? convertUtcToLocal(form[name].fromDatetime) : undefined,
                regionTime: form[name].isDeferred ? form[name].regionTime : undefined,
            };

            return accum;
        }

        const isAdditionalFields = name === "minCommissionChange";

        accum[name] = {
            currentCommissionRate: isAdditionalFields ? getNumberFromFormattedAmount(form[name].minCommission) : +calculateRate(getNumberFromFormattedAmount(form[name].rate)),
            currentCommissionRateDeferred: getDeferredCurrentCommissionRate(isAdditionalFields, form, name),
            fromDatetime: form[name].isDeferred ? convertUtcToLocal(form[name].fromDatetime) : undefined,
            regionTime: form[name].isDeferred ? form[name].regionTime : undefined,
            thresholdAmount: form[name].thresholdAmount ? getNumberFromFormattedAmount(form[name].thresholdAmount) : undefined,
            thresholdAmountDeferred: isAdditionalFields && form[name].isDeferred ? getNumberFromFormattedAmount(form[name].deferredThreshold) : undefined,
        };

        return accum;
    }, {});
};