import {NM_ITEM_STATUS} from "./status";

export const FC_REGISTRIS_STATUS_DICT = {
    CREATED: "CREATED",
    SUBMITTED_TO_SIGNING: "SUBMITTED_TO_SIGNING",
    PARTIALLY_SIGNED: "PARTIALLY_SIGNED",
    DECLINED: "DECLINED",
    FULLY_SIGNED: "FULLY_SIGNED",
};

//маппим статусы в стандартные для выделения цветом
export const FC_REGISTRIES_STATUS_TO_NM_STATUS = {
    CREATED: NM_ITEM_STATUS.WAITING,
    SUBMITTED_TO_SIGNING: NM_ITEM_STATUS.PROGRESS,
    PARTIALLY_SIGNED: NM_ITEM_STATUS.PART_SUCCESS,
    DECLINED: NM_ITEM_STATUS.ERROR,
    FULLY_SIGNED: NM_ITEM_STATUS.SUCCESS,
};

export const FC_REGISTRIES_STATUS_OBJECTS = {
    [FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED]: {
        MOD: "light-green",
    },
    [FC_REGISTRIS_STATUS_DICT.DECLINED]: {
        MOD: "red",
    },
    [FC_REGISTRIS_STATUS_DICT.CREATED]: {
        MOD: "gray",
    },
    [FC_REGISTRIS_STATUS_DICT.SUBMITTED_TO_SIGNING]: {
        MOD: "orange",
    },
    [FC_REGISTRIS_STATUS_DICT.FULLY_SIGNED]: {
        MOD: "green",
    },
};

export const FC_REGISTRY_ITEM_STATUS = {
    AWAITING_SIGNATURE: "AWAITING_SIGNATURE",
    FOR_SIGNING: "FOR_SIGNING",
    CREATED: "CREATED",
    DECLINED: "DECLINED",
    SIGNATURE_PREPARATION_ERROR: "SIGNATURE_PREPARATION_ERROR",
    SIGNED: "SIGNED",
    SIGNATURE_ERROR: "SIGNATURE_ERROR",
};

export const FC_REGISTRY_ITEM_STATUS_TO_NM_STATUS = {
    [FC_REGISTRY_ITEM_STATUS.SIGNATURE_PREPARATION_ERROR]: NM_ITEM_STATUS.ERROR,
    [FC_REGISTRY_ITEM_STATUS.CREATED]: NM_ITEM_STATUS.WAITING,
    [FC_REGISTRY_ITEM_STATUS.AWAITING_SIGNATURE]: NM_ITEM_STATUS.PROGRESS,
    [FC_REGISTRY_ITEM_STATUS.DECLINED]: NM_ITEM_STATUS.ERROR,
    [FC_REGISTRY_ITEM_STATUS.SIGNED]: NM_ITEM_STATUS.SUCCESS,
};

export const FC_REGISTRY_ITEM_STATUS_OBJECTS = {
    [FC_REGISTRY_ITEM_STATUS.DECLINED]: {
        MOD: "red",
    },
    [FC_REGISTRY_ITEM_STATUS.SIGNATURE_PREPARATION_ERROR]: {
        MOD: "red",
    },
    [FC_REGISTRY_ITEM_STATUS.CREATED]: {
        MOD: "gray",
    },
    [FC_REGISTRY_ITEM_STATUS.FOR_SIGNING]: {
        MOD: "gray",
    },
    [FC_REGISTRY_ITEM_STATUS.AWAITING_SIGNATURE]: {
        MOD: "orange",
    },
    [FC_REGISTRY_ITEM_STATUS.SIGNED]: {
        MOD: "green",
    },
    [FC_REGISTRY_ITEM_STATUS.SIGNATURE_ERROR]: {
        MOD: "red",
    },
};

export const FC_REGISTRY_ACTION = {
    ARCHIVE: "ARCHIVE",
    UNARCHIVE: "UNARCHIVE",
    SUBMIT_TO_SIGNING: "SUBMIT_TO_SIGNING",
    RESUBMIT_TO_SIGNING: "RESUBMIT_TO_SIGNING",
    DELETE_NOT_SIGNED: "DELETE_NOT_SIGNED",
    EDIT: "EDIT",
    SUBMIT_TO_SIGNING_WITH_DUPLICATES: "FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_DUPLICATES",
    SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS: "SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS",
    SUBMIT_TO_SIGNING_WITH_DUPLICATES_AND_NON_RESIDENTS: "SUBMIT_TO_SIGNING_WITH_DUPLICATES_AND_NON_RESIDENTS",
    UPLOAD: "UPLOAD",
    INVITE_TO_NM: "INVITE_TO_NM",
    INVITE_TO_REGISTRY: "INVITE_TO_REGISTRY",
    UPLOAD_LIST: "UPLOAD_LIST",
    FILTER: "FILTER",
};

export const FC_REGISTRY_ITEM_ACTION = {
    EDIT: "EDIT",
    DOWNLOAD_FRAME_CONTRACT: "DOWNLOAD_FRAME_CONTRACT",
    DOWNLOAD_OVM_NOTIFICATION: "DOWNLOAD_OVM_NOTIFICATION",
    CANCEL: "CANCEL",
};

export const FC_REGISTRY_ITEMS_MASS_ACTIONS = {
    DELETE: "DELETE",
    CANCEL: "CANCEL",
};

export const FC_REGISTRY_CARD_OPTIONS = {
    UPLOAD: {
        key: FC_REGISTRY_ACTION.UPLOAD,
        text: "Выгрузить",
        value: FC_REGISTRY_ACTION.UPLOAD,
    },
    SUBMIT_TO_SIGNING: {
        key: FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING,
        text: "Передать на подписание",
        value: FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING,
    },
    FIlTER: {
        key: FC_REGISTRY_ACTION.FILTER,
        text: "Фильтр",
        value: FC_REGISTRY_ACTION.FILTER,
    },
};

export const FC_REGISTRY_CARD_BTN_OPTIONS = {
    INVITE_TO_NM: {
        key: FC_REGISTRY_ACTION.INVITE_TO_NM,
        text: "Пригласить исполнителя в Наймикс",
        value: FC_REGISTRY_ACTION.INVITE_TO_NM,
    },
    INVITE_TO_REGISTRY: {
        key: FC_REGISTRY_ACTION.INVITE_TO_REGISTRY,
        text: "Добавить исполнителя в реестр",
        value: FC_REGISTRY_ACTION.INVITE_TO_REGISTRY,
    },
    UPLOAD_LIST: {
        key: FC_REGISTRY_ACTION.UPLOAD_LIST,
        text: "Загрузить список исполнителей",
        value: FC_REGISTRY_ACTION.UPLOAD_LIST,
    },
};

export const FC_REGISTRY_CARD_INVITE_ACTION = "FC_REGISTRY_CARD_INVITE_ACTION";

export const FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_DUPLICATES_TEXT = "В реестре найдены повторяющиеся строки на " +
    "подписание договора с одним и тем же исполнителем. Для передачи реестра на подписание их необходимо " +
    "удалить. Вы подтверждаете операцию удаления и передачи реестра на подписание?";
export const FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_DUPLICATES_AND_NON_RESIDENTS_TEXT = "Обращаем Ваше внимание, что в передаваемом на подписание реестре найдены:\n" +
    "\n- повторяющиеся строки на подписание договора с одним и тем же исполнителем. При продолжении операции дубли будут удалены автоматически.\n" +
    "\n- строки на заключение договоров с иностранными гражданами (лицами без гражданства). В соответствии " +
    "с законодательством РФ необходимо уведомить территориальное подразделение МВД России по вопросам миграции " +
    "о заключении договора с иностранным гражданином (лицом без гражданства) в течение 3 рабочих дней с даты заключения такого договора.\n" +
    "\nВы подтверждаете передачу реестра на подписание?";

export const FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS = "Обращаем внимание, что вы собираетесь " +
    "заключить рамочный договор с иностранным гражданином (лицом без гражданства). В соответствии " +
    "с законодательством РФ необходимо уведомить территориальное подразделение МВД России " +
    "по вопросам миграции о заключении договора с иностранным гражданином (лицом без гражданства) " +
    "в течение 3 рабочих дней с даты заключения такого договора.";

export const FC_REGISTRY_ACTION_TEXT = {
    [FC_REGISTRY_ACTION.ARCHIVE]: "Вы действительно хотите отправить в архив реестр \"{{name}}\"?",
    [FC_REGISTRY_ACTION.UNARCHIVE]: "Вы действительно хотите восстановить из архива реестр \"{{name}}\"?",
    [FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING]: "Вы действительно хотите передать на подписание реестр \"{{name}}\"?",
    [FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING]: "Вы действительно хотите повторить невыполненные строки реестра \"{{name}}\"?",
    [FC_REGISTRY_ACTION.DELETE_NOT_SIGNED]: "Вы действительно хотите удалить невыполненные строки реестра \"{{name}}\"?",
    [FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_DUPLICATES]: `Внимание! ${FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_DUPLICATES_TEXT}`,
    [FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS]: FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS,
    [FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_DUPLICATES_AND_NON_RESIDENTS]: FC_REGISTRY_SUBMIT_TO_SIGNING_WITH_DUPLICATES_AND_NON_RESIDENTS_TEXT,
};