import {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";

export function useDebounceFetch(params) {
    const {
        fetchCallback,
        timeout = 300,
        minSearchLength = 3,
        initialValue = "",
        nameSearchQuery,
        isMountLoad = true,
        isResetValueFilter,
        onChange,
    } = params;
    const [valueFilter, setValueFilter] = useState(initialValue);
    const [oldValue, setOldValue] = useState("");

    const delayedFetching = useCallback(debounce(() => {
        fetchCallback && fetchCallback(valueFilter);
    }, timeout), [valueFilter]);

    useEffect(() => {
        if (isResetValueFilter) {
            setValueFilter("");
            setOldValue("");
        }
    }, [isResetValueFilter]);

    useEffect(() => {
        isMountLoad && fetchCallback && fetchCallback(valueFilter);
    }, []);

    useEffect(() => {
        if (fetchCallback) {
            // На случай, если необходимо сохранять значение в родителе
            nameSearchQuery && onChange(null, {value: valueFilter, name: nameSearchQuery});

            setOldValue(valueFilter);

            if (valueFilter.length < oldValue.length && valueFilter.length < minSearchLength) {
                delayedFetching();
            }

            if (valueFilter.length < minSearchLength) {
                return;
            }

            delayedFetching();
        }

        return delayedFetching.cancel;
    }, [valueFilter, delayedFetching]);

    return {
        setValueFilter,
        valueFilter,
    };
}