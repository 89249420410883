import {useEffect, useState} from "react";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE, HIDING_PROJECT_OBJECT_TYPE} from "../constants";

export const useHidingProjectsObjectsFetch = (params) => {
    const {
        clientId,
        setNodes,
        nodes,
        fetchProjects,
        isVisibleClient,
    } = params;
    const [pageNum, setProjectsPageNum] = useState(1);

    useEffect(() => {
        getProjects({
            pageNum: 1,
        });
    }, []);

    const getProjects = (data = {}) => {
        const {
            isSearchCleared = false,
            pageNum,
        } = data;

        if (isSearchCleared && pageNum !== 1) {
            setProjectsPageNum(1);
        }

        fetchProjects({
            pageNum,
            isSearchCleared,
            pageSize: 10,
            clientId,
            onSuccess: ({results}) => {
                if (pageNum === 1) {
                    return;
                }

                const _nodes = [...nodes];

                if (isVisibleClient) {
                    const client = _nodes[0];

                    const loadedValues = results.map(item => {
                        return {
                            label: item.projectName,
                            //checked: client.partiallyChecked ? item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY : client.checked,
                            checked: typeof client.changedCheckedValue === "boolean"
                                ? client.changedCheckedValue
                                : item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                            type: HIDING_PROJECT_OBJECT_TYPE.PROJECT,
                            partiallyChecked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
                            children: [],
                            value: item.projectId,
                        };
                    });

                    client.children = isSearchCleared ?
                        loadedValues :
                        [
                            ...client.children,
                            ...loadedValues,
                        ];

                    setNodes(_nodes);

                    if (params.onChange) {
                        params.onChange(_nodes);
                    }

                    return;
                }

                const loadedValues = results.map(item => {
                    return {
                        label: item.projectName,
                        checked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                        type: HIDING_PROJECT_OBJECT_TYPE.PROJECT,
                        partiallyChecked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
                        children: [],
                        value: item.projectId,
                    };
                });

                const projects = isSearchCleared ?
                    loadedValues :
                    [
                        ..._nodes,
                        ...loadedValues,
                    ];

                setNodes(projects);

                if (params.onChange) {
                    params.onChange(projects);
                }
            },
        });
    };

    const showProjects = () => {
        setProjectsPageNum(num => num + 1);

        getProjects({
            pageNum: pageNum + 1,
        });
    };

    return {
        showProjects,
        pageNum,
        getProjects,
        setProjectsPageNum,
    };
};