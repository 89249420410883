import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {updateStoreClientsSetting} from "../../../../ducks/bff/clients/settings/actionCreators";

export const useClientSettingsForm = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreClientsSetting({
                payments: {},
                other: {},
                additionalModules: {},
                contractors: {},
                api: {},
                documents: {},
            }));
        };
    }, []);

    return {
    };
};