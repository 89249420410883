import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {dadataValueUniqCitySettlementFilter} from "../../../../utils/dadata";
import formatDate, {getEndDate, getStartDate} from "../../../../utils/dateFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

import {CROWD_TASK_STATUS_OPTIONS} from "../../../../constants/crowd/task";

const useCrowdTaskRegistryFilter = (props) => {
    const {
        clientId,
        pageSize,
        setPagination,
        projectId,
        objectId,
    } = props;

    const initFilter = {
        projectIdsFilter: [],
        objectIdsFilter: [],
        addressFiasIdsFilter: [],
        specialityIdsFilter: [],
        networkNameFilter: "",
        numberFilter: "",
        nameFilter: "",
        workDatesFromFilter: null,
        workDatesToFilter: null,
        creationDateTimeFromFilter: null,
        creationDateTimeToFilter: null,
        statusFilter: null,
    };

    function mapFilterDto(filter) {
        const {
            workDatesFromFilter,
            workDatesToFilter,
            creationDateTimeFromFilter,
            creationDateTimeToFilter,
            projectIdsFilter,
            objectIdsFilter,
            addressFiasIdsFilter,
            specialityIdsFilter,
            statusFilter,
            networkNameFilter,
            numberFilter,
            nameFilter,
        } = filter;

        return {
            workDatesFromFilter: workDatesFromFilter ? formatDate(workDatesFromFilter, "yyyy-MM-dd") : undefined,
            workDatesToFilter: workDatesToFilter ? formatDate(workDatesToFilter, "yyyy-MM-dd") : undefined,
            creationDateTimeFromFilter: getStartDate(creationDateTimeFromFilter),
            creationDateTimeToFilter: getEndDate(creationDateTimeToFilter),
            projectIdsFilter: projectIdsFilter?.length ? projectIdsFilter : undefined,
            objectIdsFilter: objectIdsFilter?.length ? objectIdsFilter : undefined,
            addressFiasIdsFilter: addressFiasIdsFilter?.length ? addressFiasIdsFilter : undefined,
            specialityIdsFilter: specialityIdsFilter?.length ? specialityIdsFilter : undefined,
            statusFilter: statusFilter ? statusFilter : undefined,
            networkNameFilter: handleFormString(networkNameFilter),
            numberFilter: handleNumber(numberFilter),
            nameFilter: handleFormString(nameFilter),
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        setFilter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            !projectId && {
                row: [
                    {
                        component: FILTER.PROJECT,
                        name: "projectIdsFilter",
                        clientId: clientId,
                        isActiveStyle: false,
                    },
                ],
            },
            !objectId && {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIdsFilter",
                        clientId: clientId,
                        isActiveStyle: false,
                        projectIdsFilter: filter.projectIdsFilter,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        name: "addressFiasIdsFilter",
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        filter: dadataValueUniqCitySettlementFilter,
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        label: "Город",
                        initialAddressIds: filter.addressFiasIdsFilter,
                        placeholder: "Выберите город",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "networkNameFilter",
                        label: "Сеть",
                        placeholder: "Введите сеть",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "workDatesFromFilter",
                        endFieldName: "workDatesToFilter",
                        isClearable: true,
                        label: "Период работ",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        clientId: clientId,
                        name: "specialityIdsFilter",
                        label: "Вид деятельности",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "numberFilter",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFromFilter",
                        endFieldName: "creationDateTimeToFilter",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус",
                        placeholder: "Выберите статус",
                        name: "statusFilter",
                        options: CROWD_TASK_STATUS_OPTIONS,
                    },
                ],
            },
        ].filter(item => Boolean(item));
    }, [filter]);

    return {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        filters,
        setFilter,
    };
};

export default useCrowdTaskRegistryFilter;