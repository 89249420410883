import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/registrypatents";

const REGISTRY_PATENT_LOG_REQUEST = "REGISTRY_PATENT_LOG_REQUEST";
const REGISTRY_PATENT_LOG_SUCCESS = "REGISTRY_PATENT_LOG_SUCCESS";
const REGISTRY_PATENT_LOG_ERROR = "REGISTRY_PATENT_LOG_ERROR";

const REGISTRY_PATENT_UPDATE_STORE = "REGISTRY_PATENT_UPDATE_STORE";

const initial = {
    log: {
        list: [],
        totalCount: 0,
        pageData: {
            pageSize: 25,
            pageNum: 1,
        },
    },
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case REGISTRY_PATENT_LOG_REQUEST:
        return {
            ...state,
            log: {
                ...state.log,
                pageData: {
                    pageSize: payload.pageSize,
                    pageNum: payload.pageNum,
                },
            },
        };
    case REGISTRY_PATENT_LOG_SUCCESS:
        return {
            ...state,
            log: {
                ...state.log,
                list: payload.patentsLogEventModels,
                totalCount: payload.totalCount,
            },
        };
    case REGISTRY_PATENT_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};

export function getRegistryPatentsLog(payload) {
    return {
        type: REGISTRY_PATENT_LOG_REQUEST,
        payload,
    };
}

export function updateRegistryPatentsStore(payload) {
    return {
        type: REGISTRY_PATENT_UPDATE_STORE,
        payload,
    };
}

const registryPatentsSelector = state => state.registryPatents;
export const registryPatentsLogTotalCountSelector = createSelector(registryPatentsSelector, ({log: {totalCount}}) => totalCount);
export const registryPatentsLogTotalPagesSelector = createSelector(registryPatentsSelector,
    ({log: {totalCount, pageData: {pageSize = 0}}}) => getTotalPages(totalCount, pageSize));
export const registryPatentsLogsListSelector = createSelector(registryPatentsSelector, ({log: {list}}) => list);

//POST registrypatents/log/getPage
const getRegistryPatentLogSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/log/getPage`, data);

        getResult(result);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: REGISTRY_PATENT_LOG_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PATENT_LOG_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: REGISTRY_PATENT_LOG_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PATENT_LOG_REQUEST, getRegistryPatentLogSaga),
    ]);
}