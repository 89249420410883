import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import UpdatingDirectories from "../../../../components/SettingsDirectoriesEdoTypes";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
} from "../../../../constants/roles";

import {
    addEdoDocumentType,
    deleteEdoDocumentType, edoDocumentsTypesTotalCountSelector,
    edoDocumentsTypesTotalPagesSelector,
    edoDocumentTypesSelector,
    getEdoDocumentTypes,
    updateEdoDocumentType,
} from "../../../../ducks/edocuments";

const ADD_BUTTON_TEXT = "Добавить тип документа";
const LABEL_FILTER = "Тип документа";
const POPUP_TITLE = {
    EDIT: "Редактирование типа документа",
    DELETE: "Вы действительно хотите удалить данный тип документа из справочника?",
    ADD: "Добавление типа документа",
};

const SettingsDirectoriesDocumentTypesContainer = () => {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter: {valueFilter: ""},
        pageSize,
        setPagination,
    });

    const dispatch = useDispatch();

    const totalPages = useSelector(edoDocumentsTypesTotalPagesSelector);
    const totalCount = useSelector(edoDocumentsTypesTotalCountSelector);
    const list = useSelector(edoDocumentTypesSelector);
    const role = ls(USER_ROLE);
    const isEditable = ![NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(role);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        const {
            valueFilter,
        } = filterData;

        dispatch(getEdoDocumentTypes({
            pageNum,
            pageSize,
            valueFilter: valueFilter ? valueFilter : undefined,
        }));
    };

    const onAdd = data => {
        dispatch(addEdoDocumentType(data));
    };

    const onDelete = data => {
        dispatch(deleteEdoDocumentType(data));
    };

    const onEdit = data => {
        dispatch(updateEdoDocumentType(data));
    };

    return (
        <UpdatingDirectories
            isSearch={isSearch}
            isEditable={isEditable}
            onAdd={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            filter={filter}
            fetchList={fetchList}
            clearFilter={onClear}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            onSubmitFilter={onSearch}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            addButtonText={ADD_BUTTON_TEXT}
            popupTitleAdd={POPUP_TITLE.ADD}
            popupTitleEdit={POPUP_TITLE.EDIT}
            popupTitleDelete={POPUP_TITLE.DELETE}
            labelFilter={LABEL_FILTER}
            inputTypePlaceholder="Тип документа"
        />
    );
};

export default SettingsDirectoriesDocumentTypesContainer;