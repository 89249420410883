import {useState} from "react";

import {useInfiniteScroll} from "./useInfiniteScroll";

interface UseInfiniteScrollPagination {
    totalPages: number,
    nextPageNum?: number,
    fetchCallback?: ({pageNum}: {pageNum: number}) => void,
}

export const useInfiniteScrollPagination = (params: UseInfiniteScrollPagination) => {
    const {
        totalPages,
        nextPageNum,
        fetchCallback,
    } = params;

    const [pageData, setPageData] = useState({
        pageNum: 1,
    });

    const onNext = () => {
        if (fetchCallback) {
            fetchCallback({pageNum: pageData.pageNum + 1});
        }

        setPageData(pageData => {
            if (typeof nextPageNum === "number" && nextPageNum !== pageData.pageNum) {
                return {
                    ...pageData,
                    pageNum: nextPageNum + 1,
                };
            }

            return {
                ...pageData,
                pageNum: pageData.pageNum + 1,
            };
        });
    };

    const infiniteScrollProps = useInfiniteScroll({
        size: 100,
        onNext,
        shouldFetchMore: pageData.pageNum < totalPages,
    });

    return {
        pageData,
        infiniteScrollProps,
        setPageData,
    };
};