import React from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmDatePickerRange from "../../../../components/NmDatePickerRange";

import {OPTIONS_DIRECTION} from "../../../../constants/bankDeposit";

import "./style.sass";

const FinanceBankFilter = (props) => {
    const {
        onClear,
        filter,
        sendFilter,
        onChangeFilter,
    } = props;

    const handleOnKeyPress = ({key}) => {
        if (key !== "Enter") {
            return;
        }

        sendFilter();
    };

    const handleOnChangeRangeDatepicker = (dateRange) => {
        const {
            endDate,
            startDate,
        } = dateRange;

        onChangeFilter(null, {name: "toDateFilter", value: endDate});
        onChangeFilter(null, {name: "fromDateFilter", value: startDate});
    };

    return (
        <NmForm
            onKeyPress={handleOnKeyPress}
            className="finance-bank-filter"
            addMargin
        >
            <div className="finance-bank-filter__form">
                <NmDatePickerRange
                    className="mb-4"
                    size="lg"
                    startDate={filter.fromDateFilter}
                    endDate={filter.toDateFilter}
                    selectDateRange={handleOnChangeRangeDatepicker}
                />
                <NmDropdownV2
                    className="mb-4"
                    size="lg"
                    selectOnBlur={false}
                    search
                    name="operationTypeFilter"
                    value={filter.operationTypeFilter}
                    options={OPTIONS_DIRECTION}
                    label="Вид операции"
                    onChange={onChangeFilter}
                />
                <FilterCustomer
                    direction="bottom"
                    strongDirection
                    className="mb-4"
                    isActiveStyle
                    name="clientIdFilter"
                    value={filter.clientIdFilter}
                    onChange={onChangeFilter}
                    search
                    isClearable
                    placeholder="Все"
                />
            </div>
            <FilterButtonsV2
                onSearch={sendFilter}
                onClear={onClear}
            />
        </NmForm>
    );
};

export default FinanceBankFilter;
