import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import MailingsList from "../../../components/MailingsList";

import {
    addContractorNotification,
    cancelContractorNotification,
    clearContractorNotificationStore,
    getContractorsNotificationsPage,
    getNotificationContractorCount,
    getNotificationSendingContractorId,
    retryContractorNotification,
    updateContractorNotificationStore,
} from "../../../ducks/bff/clients/notification-sending/to-contractor/actionCreators";
import {
    notificationSendingToContractorCardSelector,
    notificationSendingToContractorListSelector,
    notificationSendingToContractorProgressActionSelector,
    notificationSendingToContractorProgressAddSelector,
    notificationSendingToContractorProgressSelector,
    notificationSendingToContractorTotalCountSelector,
    notificationSendingToContractorTotalPagesSelector,
} from "../../../ducks/bff/clients/notification-sending/to-contractor/selectors";

export const ClientMailingsList = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(notificationSendingToContractorListSelector);
    const totalPages = useSelector(notificationSendingToContractorTotalPagesSelector);
    const totalCount = useSelector(notificationSendingToContractorTotalCountSelector);
    const progress = useSelector(notificationSendingToContractorProgressSelector);
    const progressAction = useSelector(notificationSendingToContractorProgressActionSelector);
    const progressAdd = useSelector(notificationSendingToContractorProgressAddSelector);
    const notification = useSelector(notificationSendingToContractorCardSelector);

    useEffect(() => {
        return () => {
            dispatch(clearContractorNotificationStore());
        };
    }, []);

    const retryNotification = (params) => {
        const {
            clientId,
            data: {
                id,
            },
            handleResponse,
        } = params;

        dispatch(retryContractorNotification({
            clientId,
            id,
            handleResponse,
        }));
    };

    const cancelNotification = (params) => {
        const {
            data: {
                id,
                removePosted,
            },
            handleResponse,
        } = params;

        dispatch(cancelContractorNotification({
            clientId,
            id,
            removePosted,
            handleResponse,
        }));
    };

    const fetchList = (params) => {
        const {
            pageNum,
            pageSize,
            ...filters
        } = params;

        dispatch(getContractorsNotificationsPage({
            clientId,
            pageNum,
            pageSize,
            ...filters,
        }));
    };

    const getContractorCount = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(getNotificationContractorCount({
            clientId,
            ...data,
            handleResponse,
        }));
    };

    const addNotification = (params) => {
        const {
            handleResponse,
            data,
        } = params;

        dispatch(addContractorNotification({
            clientId,
            ...data,
            handleResponse,
        }));
    };

    const updateFieldPushNotificationStore = ({pushNotification}) => {
        dispatch(updateContractorNotificationStore({card: pushNotification}));
    };

    const addContractor = ({data: {phoneFilter}, saveNumber}) => {
        dispatch(getNotificationSendingContractorId({
            phone: phoneFilter,
            clientId,
            handleResponse: (data) => {
                const {
                    success,
                    result,
                } = data;

                saveNumber({
                    isSuccess: success,
                    isNotFound: !result,
                });
            },
        }));
    };

    return (
        <MailingsList
            clientId={clientId}
            retryNotification={retryNotification}
            cancelNotification={cancelNotification}
            fetchList={fetchList}
            getContractorCount={getContractorCount}
            addNotification={addNotification}
            notification={notification}
            addContractor={addContractor}
            updateFieldPushNotificationStore={updateFieldPushNotificationStore}
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            progress={progress}
            progressAction={progressAction}
            progressAdd={progressAdd}
            {...props}
        />
    );
};