import React from "react";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import ExtLink from "../../../../components/ExtLink";

import {useContractorIncomePaymentCardAction} from "./hooks/useAction";

import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {COLOR} from "../../../../constants/color";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_ORDER_CARD,
} from "../../../../constants/links";
import {isClientUser} from "../../../../constants/roles";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../constants/link-params";

const ContractorFinanceIncomePaymentListCard = (props) => {
    const {
        isNdfl,
        isIndividual,
        isCrowd,
        payment,
        isShowActions,
    } = props;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const {
        paymentNumber,
    } = payment;

    const {
        getMediaControls,
    } = useContractorIncomePaymentCardAction({role, isShowActions});

    const getPaymentLink = () => {
        if (isIndividual) {
            return LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST;
        }

        if (isNdfl) {
            return LINK_FINANCE_NDFL_PAYMENT_LIST;
        }

        if (isCrowd) {
            return LINK_FINANCE_CROWD_PAYMENT_LIST.replace(":paymentNumberFilter?", "");
        }

        return LINK_FINANCE_PAYMENT_LIST;
    };

    const isShowPaymentLabels = !isClientRole && !isCrowd;

    const getClient = ({clientId, clientName, brand}) => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {brand ? `${clientName} (${brand})` : clientName}
            </ExtLink>
        );
    };

    const getOrder = (item) => {
        const {
            orderNumber,
            clientId,
            orderId,
        } = item;

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);


        return (
            <ExtLink
                to={orderLink}
                historyEnabled
            >
                {orderNumber}
            </ExtLink>
        );
    };

    const getCrowdTask = (item) => {
        const {
            taskNumber,
            taskName,
            clientId,
            taskId,
        } = item;

        const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":taskId", taskId)
            .replace(":clientId", clientId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        return (
            <ExtLink
                to={taskLink}
                historyEnabled
            >
                {taskNumber} 
                {" "}
                {taskName}
            </ExtLink>
        );
    };

    const getRegistry = (item) => {
        const {
            clientId,
            registryId,
            registryNumber,
            registrySeqNum,
        } = item;

        const registryLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD
            .replace(":paymentNumberFilter?", registryNumber)
            .replace(":archived", "false")
            .replace(":clientId", clientId)
            .replace(":registryId", registryId);

        return (
            <ExtLink
                to={registryLink}
                historyEnabled
            >
                Оплата по реестру № 
                {" "}
                {registryNumber}
, номер строки: 
                {" "}
                {registrySeqNum}
            </ExtLink>
        );
    };

    return (
        <NmListCard
            classNameMainContent={isNdfl || isIndividual ? "col-16 col-xxl-9" : "col-16 col-xxl-7"}
            secondaryHeader={`Дата операции ${dateFormat(convertUtcToLocal(payment.operationDate || payment.updateDate))}`}
            primaryHeader={
                <ExtLink
                    historyEnabled={true}
                    isLoadDataTarget={true}
                    filterData={{
                        paymentNumber,
                    }}
                    to={getPaymentLink()}
                    title={paymentNumber}
                >
                    {paymentNumber}
                </ExtLink>
            }
            labels={[
                {
                    text: <Text
                        level="3"
                        color={COLOR.SECONDARY_100}
                          >
                        {payment.description}
                    </Text>,
                    noWrap: false,
                },
                isShowPaymentLabels && {
                    label: "Способ оплаты",
                    text: `${payment.bankType?.description || "-"} / ${payment.paymentPath?.description || "-"}`,
                },
                isShowPaymentLabels && {
                    label: "Местонахождение платежа",
                    text: payment.paymentLocation?.description || "-",
                },
                {label: "Заказчик", text: getClient(payment)},
                payment.orderId && {label: "Заказ", text: getOrder(payment)},
                payment.taskId && {label: "Задание", text: getCrowdTask(payment)},
                payment.registryId && {label: "Реестр оплат", text: getRegistry(payment)},
            ]}
            cards={[
                {
                    title: "Сумма оплаты, ₽",
                    value: formatAmountWithNullChecking(isCrowd ? payment.amount : payment.sumOfMoney),
                    className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                },
                !isCrowd && {
                    title: isIndividual ? "Сумма на р/с, ₽" : "Сумма на карту, ₽",
                    value: formatAmountWithNullChecking(payment.smzAmount),
                    className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                },
                !isCrowd && {
                    title: "Комиссия исполнителя, ₽",
                    value: formatAmountWithNullChecking(payment.platformAmount),
                    className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                },
                !isNdfl && !isIndividual && !isCrowd && {
                    title: "Налог исполнителя, ₽",
                    value: formatAmountWithNullChecking(payment.taxAmount),
                    className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                },
                isCrowd && {
                    title: "Комиссия заказчика, ₽",
                    value: formatAmountWithNullChecking(payment.clientCommission),
                    className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                },
            ]}
            cardsWithContainer={true}
            cardsContainerClassName={`col-16 ${isNdfl || isIndividual ? "col-16 col-xxl-6" : "col-16 col-xxl-8"} align-items-end`}
            actionsClassName="col-1"
            mediaControls={getMediaControls(payment)}
        />
    );
};

export default ContractorFinanceIncomePaymentListCard;