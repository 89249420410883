import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import validate from "../../../utils/validate";

import {
    ADMIN, CLIENT_ACCOUNTANT, CLIENT_ADMIN,
    isUserFromNm, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_MANAGER, NM_PARTNER, OBJECT_MANAGER, PROJECT_MANAGER,
} from "../../../constants/roles";
import {commentRule} from "../../../constants/validationRules";

import {updateDepositComment} from "../../../ducks/deposit";

import PropTypes from "prop-types";

import "./style.sass";

class DepositCommentModal extends React.Component {
    static propTypes = {
        commentData: PropTypes.shape({
            clientDepositHistoryId: PropTypes.string.isRequired,
            comment: PropTypes.string,
            action: PropTypes.string.isRequired,
        }),
        onClose: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,
    };

    static defaultProps = {
        commentData: {},
        onClose: () => {
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            comment: undefined,
            error: {},
        };
        this.role = ls(USER_ROLE);
    }

    static getDerivedStateFromProps(props, state) {
        const {
            commentData: {
                comment: commentProp,
            },
        } = props;

        const {comment} = state;

        if (comment === undefined && commentProp) {
            return {
                ...state,
                comment: commentProp,
            };
        }

        return null;
    }

    get localizationData() {
        const {t} = this.props;

        return {
            title: t("deposit-comment-modal.title"),
            commentPlaceholder: t("deposit-comment-modal.placeholder"),
        };
    }

    get isEditable() {
        return [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role);
    }

    handleChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    isValidForm = () => {
        const {comment} = this.state;

        const error = validate({comment}, commentRule, "");
        this.setState({
            error,
        });

        return Object.keys(error).length === 0;
    };

    submit = () => {
        const {
            commentData: {
                clientDepositHistoryId,
                comment: commentProp,
            },
            onClose,
            updateDepositComment,
            onSuccess,
        } = this.props;

        const {comment} = this.state;

        if (commentProp === comment) {
            onClose();
            return;
        }

        if (!this.isValidForm()) {
            return;
        }

        updateDepositComment({
            clientDepositHistoryId,
            comment,
            onSuccess,
        });

        onClose();
    };

    renderActions() {
        const {
            onClose,
            t,
        } = this.props;

        if (!this.isEditable) {
            return;
        }

        return (
            <ApplyButtons
                onClose={onClose}
                submitBtnContent={t("button.save")}
                cancelBtnContent={t("button.cancel")}
                submit={this.submit}
            />
        );
    }

    render() {
        const {
            comment,
            error,
        } = this.state;
        const {
            onClose,
        } = this.props;
        const {
            title,
            commentPlaceholder,
        } = this.localizationData;

        return (
            <NmModal
                className="deposit-comment-modal"
                onClose={onClose}
                footer={this.renderActions()}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
            >
                <NmForm>
                    <NmTextareaV2
                        placeholder={commentPlaceholder}
                        error={error.comment}
                        disabled={!this.isEditable}
                        name="comment"
                        className="nmx-i-fluid"
                        value={comment}
                        onChange={this.handleChange}
                    />
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({}),
    {
        updateDepositComment,
    },
)(withTranslation()(DepositCommentModal));