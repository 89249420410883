import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffUrl} from "../utils/url";

import {LINK_FINANCE_PATENT_PAYMENTS} from "../constants/links";

const controller = "/patents/payment";

//*  TYPES  *//

const PATENTS_PAYMENTS_LIST_REQUEST = "PATENTS_PAYMENTS_LIST_REQUEST";
const PATENTS_PAYMENTS_LIST_SUCCESS = "PATENTS_PAYMENTS_LIST_SUCCESS";
const PATENTS_PAYMENTS_LIST_ERROR = "PATENTS_PAYMENTS_LIST_ERROR";

const PATENTS_PAYMENTS_SUMMARY_LIST_REQUEST = "PATENTS_PAYMENTS_SUMMARY_LIST_REQUEST";
const PATENTS_PAYMENTS_SUMMARY_LIST_SUCCESS = "PATENTS_PAYMENTS_SUMMARY_LIST_SUCCESS";
const PATENTS_PAYMENTS_SUMMARY_LIST_ERROR = "PATENTS_PAYMENTS_SUMMARY_LIST_ERROR";

const PATENTS_PAYMENTS_TOTAL_SUMMARY_REQUEST = "PATENTS_PAYMENTS_TOTAL_SUMMARY_REQUEST";
const PATENTS_PAYMENTS_TOTAL_SUMMARY_SUCCESS = "PATENTS_PAYMENTS_TOTAL_SUMMARY_SUCCESS";
const PATENTS_PAYMENTS_TOTAL_SUMMARY_ERROR = "PATENTS_PAYMENTS_TOTAL_SUMMARY_ERROR";

const ADD_PATENTS_PAYMENT_REQUEST = "ADD_PATENTS_PAYMENT_REQUEST";
const ADD_PATENTS_PAYMENT_SUCCESS = "ADD_PATENTS_PAYMENT_SUCCESS";
const ADD_PATENTS_PAYMENT_ERROR = "ADD_PATENTS_PAYMENT_ERROR";

const ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST = "ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST";
const ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS = "ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS";
const ADD_PATENTS_PAYMENT_FROM_FILE_ERROR = "ADD_PATENTS_PAYMENT_FROM_FILE_ERROR";

const UPDATE_PATENTS_PAYMENT_REQUEST = "UPDATE_PATENTS_PAYMENT_REQUEST";
const UPDATE_PATENTS_PAYMENT_SUCCESS = "UPDATE_PATENTS_PAYMENT_SUCCESS";
const UPDATE_PATENTS_PAYMENT_ERROR = "UPDATE_PATENTS_PAYMENT_ERROR";

const DELETE_PATENTS_PAYMENT_REQUEST = "DELETE_PATENTS_PAYMENT_REQUEST";
const DELETE_PATENTS_PAYMENT_SUCCESS = "DELETE_PATENTS_PAYMENT_SUCCESS";
const DELETE_PATENTS_PAYMENT_ERROR = "DELETE_PATENTS_PAYMENT_ERROR";

const GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST = "GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST";
const GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS = "GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS";
const GET_PATENTS_PAYMENT_STATUS_DICT_ERROR = "GET_PATENTS_PAYMENT_STATUS_DICT_ERROR";

const PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST = "PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST";
const PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS = "PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS";
const PATENTS_PAYMENTS_ADMIN_PAGE_ERROR = "PATENTS_PAYMENTS_ADMIN_PAGE_ERROR";

const PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST = "PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST";
const PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS = "PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS";
const PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR = "PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR";

const REFRESH_PATENT_PAYMENT_STATUS_REQUEST = "REFRESH_PATENT_PAYMENT_STATUS_REQUEST";
const REFRESH_PATENT_PAYMENT_STATUS_SUCCESS = "REFRESH_PATENT_PAYMENT_STATUS_SUCCESS";
const REFRESH_PATENT_PAYMENT_STATUS_ERROR = "REFRESH_PATENT_PAYMENT_STATUS_ERROR";

const DECLINE_PATENT_PAYMENT_REQUEST = "DECLINE_PATENT_PAYMENT_STATUS_REQUEST";
const DECLINE_PATENT_PAYMENT_SUCCESS = "DECLINE_PATENT_PAYMENT_STATUS_SUCCESS";
const DECLINE_PATENT_PAYMENT_ERROR = "DECLINE_PATENT_PAYMENT_STATUS_ERROR";

const PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST = "PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST";
const PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS = "PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS";
const PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR = "PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR";

const PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST = "PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST";
const PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS = "PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS";
const PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR = "PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR";

const PATENTS_PAYMENTS_UPDATE_STORE = "PATENTS_PAYMENTS_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    payments: [],
    pageData: {},
    totalCount: 0,
    card: {},
    progressCard: false,
    progressList: false,
    actionRegistryProgress: false,
    paymentStatusDict: {},
    summaryData: [],
    summaryTotalData: {},
    summaryPageData: {},
    summaryDataProgress: false,
    summaryTotalProgress: false,
    summaryTotalCount: 0,
    isSuccessAction: false,
    patentPaymentsTotalAmount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case PATENTS_PAYMENTS_TOTAL_SUMMARY_REQUEST:
        return {
            ...state,
            summaryTotalProgress: true,
        };
    case PATENTS_PAYMENTS_SUMMARY_LIST_REQUEST:
        return {
            ...state,
            summaryPageData: payload.data,
            summaryDataProgress: true,
        };
    case PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST:
    case PATENTS_PAYMENTS_LIST_REQUEST:
    case PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST:
    case PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressList: true,
        };
    case ADD_PATENTS_PAYMENT_REQUEST:
    case ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST:
    case UPDATE_PATENTS_PAYMENT_REQUEST:
    case DELETE_PATENTS_PAYMENT_REQUEST:
    case REFRESH_PATENT_PAYMENT_STATUS_REQUEST:
    case DECLINE_PATENT_PAYMENT_REQUEST:
        return {
            ...state,
            actionRegistryProgress: true,
        };
    case PATENTS_PAYMENTS_TOTAL_SUMMARY_SUCCESS:
        return {
            ...state,
            summaryTotalProgress: false,
            summaryTotalData: payload,
        };
    case PATENTS_PAYMENTS_SUMMARY_LIST_SUCCESS:
        return {
            ...state,
            summaryData: payload.payments,
            summaryDataProgress: false,
            summaryTotalCount: payload.totalCount,
        };
    case PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS: {
        const {
            results: payments,
            totalCount,
        } = payload;

        return {
            ...state,
            payments,
            progressList: false,
            totalCount,
        };
    }
    case PATENTS_PAYMENTS_LIST_SUCCESS:
    case PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS:
        const {
            payments,
            totalCount,
        } = payload;

        return {
            ...state,
            payments,
            progressList: false,
            totalCount,
        };
    case PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS:
        return {
            ...state,
            payments: payload.results,
            progressList: false,
            totalCount: payload.totalCount,
        };
    case GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS:
        return {
            ...state,
            paymentStatusDict: payload,
        };
    case PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS:
        return {
            ...state,
            patentPaymentsTotalAmount: payload,
        };
    case ADD_PATENTS_PAYMENT_SUCCESS:
    case ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS:
    case UPDATE_PATENTS_PAYMENT_SUCCESS:
    case DELETE_PATENTS_PAYMENT_SUCCESS:
    case REFRESH_PATENT_PAYMENT_STATUS_SUCCESS:
    case DECLINE_PATENT_PAYMENT_SUCCESS:
        return {
            ...state,
            actionRegistryProgress: false,
        };
    case PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR:
    case PATENTS_PAYMENTS_LIST_ERROR:
    case PATENTS_PAYMENTS_ADMIN_PAGE_ERROR:
    case PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            progressList: false,
        };
    case ADD_PATENTS_PAYMENT_ERROR:
    case ADD_PATENTS_PAYMENT_FROM_FILE_ERROR:
    case UPDATE_PATENTS_PAYMENT_ERROR:
    case DELETE_PATENTS_PAYMENT_ERROR:
    case REFRESH_PATENT_PAYMENT_STATUS_ERROR:
    case DECLINE_PATENT_PAYMENT_ERROR:
        return {
            ...state,
            error: payload,
            actionRegistryProgress: false,
        };
    case PATENTS_PAYMENTS_TOTAL_SUMMARY_ERROR:
        return {
            ...state,
            error: payload,
            summaryTotalProgress: false,
        };
    case PATENTS_PAYMENTS_SUMMARY_LIST_ERROR:
        return {
            ...state,
            error: payload,
            summaryDataProgress: false,
        };
    case PATENTS_PAYMENTS_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};


//*  ACTION CREATORS  *//
export function getPatentsPaymentsSummary(payload) {
    return {
        type: PATENTS_PAYMENTS_SUMMARY_LIST_REQUEST,
        payload,
    };
}

export function getPatentsPaymentsTotalSummary(payload) {
    return {
        type: PATENTS_PAYMENTS_TOTAL_SUMMARY_REQUEST,
        payload,
    };
}


export function getPatentsPayments(payload) {
    return {
        type: PATENTS_PAYMENTS_LIST_REQUEST,
        payload,
    };
}

export function addPatentsPayment(payload) {
    return {
        type: ADD_PATENTS_PAYMENT_REQUEST,
        payload,
    };
}

export function updatePatentsPayment(payload) {
    return {
        type: UPDATE_PATENTS_PAYMENT_REQUEST,
        payload,
    };
}

export function addPatentsPaymentsFromFile(payload) {
    return {
        type: ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST,
        payload,
    };
}

export function deletePatentsPayment(payload) {
    return {
        type: DELETE_PATENTS_PAYMENT_REQUEST,
        payload,
    };
}

export function getPaymentStatusDict() {
    return {
        type: GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST,
    };
}

export function getPatentsPaymentsAdminPage(payload) {
    return {
        type: PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST,
        payload,
    };
}

export function getPatentsPaymentsContractorPage(payload) {
    return {
        type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST,
        payload,
    };
}

export function refreshPatentPaymentStatus(payload) {
    return {
        type: REFRESH_PATENT_PAYMENT_STATUS_REQUEST,
        payload,
    };
}

export function declinePatentPayment(payload) {
    return {
        type: DECLINE_PATENT_PAYMENT_REQUEST,
        payload,
    };
}

export function getPatentPaymentsDepositPage(payload) {
    return {
        type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST,
        payload,
    };
}

export function getPatentPaymentsTotalAmount(payload) {
    return {
        type: PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST,
        payload,
    };
}

export function updatePatentPaymentsStore(payload) {
    return {
        type: PATENTS_PAYMENTS_UPDATE_STORE,
        payload,
    };
}

//*  SELECTORS  *//

const patentsPaymentsSelector = ({patentsPayments}) => patentsPayments;
export const patentsPaymentsListSelector = createSelector(patentsPaymentsSelector, ({payments}) => payments);
export const patentsPaymentsProgressListSelector = createSelector(patentsPaymentsSelector, ({progressList}) => progressList);
export const patentsPaymentsActionProgressSelector = createSelector(patentsPaymentsSelector, ({actionRegistryProgress}) => actionRegistryProgress);
export const patentsPaymentsTotalCountSelector = createSelector(patentsPaymentsSelector, ({totalCount}) => totalCount);
export const patentsPaymentsListTotalPagesSelector = createSelector(patentsPaymentsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const paymentStatusDictSelector = createSelector(patentsPaymentsSelector, ({paymentStatusDict}) => paymentStatusDict);

export const patentsPaymentsSummaryListSelector = createSelector(patentsPaymentsSelector, ({summaryData}) => summaryData);
export const patentsPaymentsSummaryProgressSelector = createSelector(patentsPaymentsSelector, ({summaryDataProgress}) => summaryDataProgress);
export const patentsPaymentsSummaryTotalCountSelector = createSelector(patentsPaymentsSelector, ({summaryTotalCount}) => summaryTotalCount);
export const patentsPaymentsSummaryListTotalPagesSelector = createSelector(patentsPaymentsSelector, ({summaryTotalCount, summaryPageData: {pageSize = 0}}) => getTotalPages(summaryTotalCount, pageSize));


export const patentsPaymentsTotalSummaryProgressSelector = createSelector(patentsPaymentsSelector, ({summaryTotalProgress}) => summaryTotalProgress);
export const patentsPaymentsTotalSummaryDataSelector = createSelector(patentsPaymentsSelector, ({summaryTotalData}) => summaryTotalData);
export const patentPaymentsTotalAmountSelector = createSelector(patentsPaymentsSelector, ({patentPaymentsTotalAmount}) => patentPaymentsTotalAmount);
//*  SAGA  *//

//POST /api/patents/payment/getPaymentPage
export const getPatentsPaymentsSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPaymentPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {payments} = result;

        if (payments?.length) {
            const contractorIds = payments.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: PATENTS_PAYMENTS_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PATENTS_PAYMENTS_LIST_ERROR, payload: error});
    }
};

export const getPatentsPaymentsTotalSummarySaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`${controller}/getTotalSummaryPaymentPage`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_TOTAL_SUMMARY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PATENTS_PAYMENTS_TOTAL_SUMMARY_SUCCESS, payload: result});

        onSuccess && onSuccess();
    } catch (error) {
        yield put({type: PATENTS_PAYMENTS_TOTAL_SUMMARY_ERROR, payload: error});
    }
};

export const getPatentsPaymentsSummarySaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.post(`${controller}/getSummaryPaymentPage`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_SUMMARY_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PATENTS_PAYMENTS_SUMMARY_LIST_SUCCESS, payload: result});

        onSuccess && onSuccess();
    } catch (error) {
        yield put({type: PATENTS_PAYMENTS_SUMMARY_LIST_ERROR, payload: error});
    }
};

//POST /api/patents/payment/addPayment
export const addPatentsPaymentSaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.post(`${controller}/addPayment`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_PATENTS_PAYMENT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Исполнитель успешно добавлен в реестр");

        if (meta) {
            yield call(meta, null);
        }

        // const {
        //     patentsPayments: {
        //         pageData
        //     }
        // } = yield select();
        //
        // yield put(getPatentsPayments(pageData));
        yield put({type: ADD_PATENTS_PAYMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_PATENTS_PAYMENT_ERROR, payload: error});
    }
};

//POST /api/patents/payment/updatePayment
export const updatePatentsPaymentSaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.post(`${controller}/updatePayment`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_PATENTS_PAYMENT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Параметры исполнителя успешно изменены");

        if (meta) {
            yield call(meta, null);
        }

        yield put({type: UPDATE_PATENTS_PAYMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_PATENTS_PAYMENT_ERROR, payload: error});
    }
};

//POST /api/patents/payment/addPaymentFromFile
export const addPatentsPaymentsFromFileSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            data,
            clientId,
            patentRegistryId,
            clientDefiningRegistryParameterType,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/addPaymentFromFile?clientId=${clientId}&patentRegistryId=${patentRegistryId}&clientDefiningRegistryParameterType=${clientDefiningRegistryParameterType}`, data, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_PATENTS_PAYMENT_FROM_FILE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Загрузка файла успешно завершена.");

        const {
            patentsPayments: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsPayments(pageData));
        yield put({type: ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_PATENTS_PAYMENT_FROM_FILE_ERROR, payload: error});
    }
};

//POST /api/patents/payment/deletePayments
export const deletePatentPaymentSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/deletePayments`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_PATENTS_PAYMENT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        const {
            onSuccess = () => {
            },
        } = payload;

        onSuccess();

        yield put({type: DELETE_PATENTS_PAYMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_PATENTS_PAYMENT_ERROR, payload: error});
    }
};

//GET api/patents/payment/getPaymentStatusDict
export const getPaymentStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getPaymentStatusDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PATENTS_PAYMENT_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PATENTS_PAYMENT_STATUS_DICT_ERROR, payload: error});
    }
};

//POST /api/patents/payment/getAdminPage
export const getPatentsPaymentsAdminPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getAdminPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_ADMIN_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PATENTS_PAYMENTS_ADMIN_PAGE_ERROR, payload: error});
    }
};

// POST /bff/adm/contractors-registry/contractor/finance/patents/payment/page
// POST /bff/adm/patent/page
// Получение страницы Патенты (в зависимости от директории разные методы)
export const getPatentsPaymentsContractorPageSaga = function* ({payload}) {
    const url = getBffUrl({
        [LINK_FINANCE_PATENT_PAYMENTS]: "/adm/patent/page",
    }, "/adm/contractors-registry/contractor/finance/patent-payments/page");

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST /api/patents/payment/refreshPayment
export const refreshPatentPaymentStatusSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/refreshPayment`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: REFRESH_PATENT_PAYMENT_STATUS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Задача на обновление статуса оплаты по патентам успешно запущена");
        yield put({type: REFRESH_PATENT_PAYMENT_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REFRESH_PATENT_PAYMENT_STATUS_ERROR, payload: error});
    }
};

//POST /api/patents/payment/declinePayment
export const declinePatentPaymentSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/declinePayment`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DECLINE_PATENT_PAYMENT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Платеж по патенту отклонен");

        const {
            patentsPayments: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsPaymentsAdminPage(pageData));
        yield put({type: DECLINE_PATENT_PAYMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DECLINE_PATENT_PAYMENT_ERROR, payload: error});
    }
};


//POST /api/patents/payment/getPatentPaymentsDepositPage
export const getPatentPaymentsDepositPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPatentPaymentsDepositPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR, payload: error});
    }
};

//POST /api/patents/payment/getPatentPaymentsTotalAmount
export const getPatentPaymentsTotalAmountSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPatentPaymentsTotalAmount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(PATENTS_PAYMENTS_LIST_REQUEST, getPatentsPaymentsSaga),
        takeEvery(PATENTS_PAYMENTS_SUMMARY_LIST_REQUEST, getPatentsPaymentsSummarySaga),
        takeEvery(PATENTS_PAYMENTS_TOTAL_SUMMARY_REQUEST, getPatentsPaymentsTotalSummarySaga),
        takeEvery(DELETE_PATENTS_PAYMENT_REQUEST, deletePatentPaymentSaga),
        takeEvery(ADD_PATENTS_PAYMENT_REQUEST, addPatentsPaymentSaga),
        takeEvery(ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST, addPatentsPaymentsFromFileSaga),
        takeEvery(UPDATE_PATENTS_PAYMENT_REQUEST, updatePatentsPaymentSaga),
        takeEvery(GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST, getPaymentStatusDictSaga),
        takeEvery(PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST, getPatentsPaymentsAdminPageSaga),
        takeEvery(PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST, getPatentsPaymentsContractorPageSaga),
        takeEvery(REFRESH_PATENT_PAYMENT_STATUS_REQUEST, refreshPatentPaymentStatusSaga),
        takeEvery(DECLINE_PATENT_PAYMENT_REQUEST, declinePatentPaymentSaga),
        takeEvery(PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST, getPatentPaymentsDepositPageSaga),
        takeEvery(PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST, getPatentPaymentsTotalAmountSaga),
    ]);
}
