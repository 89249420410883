export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_LIST_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_LIST_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_LIST_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CONTRACTOR_CANDIDATES_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CONTRACTOR_CANDIDATES_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CONTRACTOR_CANDIDATES_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_FRAME_CONTRACTOR_CANDIDATES_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_FRAME_CONTRACTOR_CANDIDATES_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_FRAME_CONTRACTOR_CANDIDATES_ERROR";

export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_SUCCESS = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_SUCCESS";
export const BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR = "BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR";

export const UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE = "UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE";

export const CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE = "CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE";

export const CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTORS = "CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTORS";