import React from "react";
import {useSelector} from "react-redux";

import FilterSpecialities from "../../../../../components/ActualComponents/FilterSpecialities";
import NmDadata from "../../../../../components/ActualComponents/NmDadata";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDivider from "../../../../../components/ActualComponents/NmDivider";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmRangeInput from "../../../../../components/ActualComponents/NmRangeInput";
import {SmartLinkCount} from "../count";

import {dadataValueCityFormatter} from "../../../../../utils/dadata";

import {COLOR} from "../../../../../constants/color";
import {SMART_LINK_FORM_TYPE} from "../../constants";

import {bffCommonDictsClientCategoriesOptionsSelector} from "../../../../../ducks/bff/common/dicts/selectors";

export const SmartLinkCreateByFilters = (props) => {
    const {
        values,
        onChange,
    } = props;

    const clientCategoriesOptions = useSelector(bffCommonDictsClientCategoriesOptionsSelector);

    return (
        <>
            <NmDivider color={COLOR.SECONDARY_5} />
            <div className="row gy-4 gx-4">
                <div className="col-16 col-md-8">
                    <NmDateRangePickerV2
                        size="xl"
                        startFieldName="fromDate"
                        endFieldName="toDate"
                        label="Период выполнения работ"
                        isClearable
                        value={{
                            fromDate: values.fromDate,
                            toDate: values.toDate,
                        }}
                        onChange={onChange}
                    />
                </div>
                <div className="col-16 col-md-8">
                    <NmRangeInput
                        isAmountInput={true}
                        className="fluid"
                        label="Стоимость заказа, ₽"
                        nameStart="fromAmount"
                        nameEnd="toAmount"
                        valueStart={values.fromAmount}
                        valueEnd={values.toAmount}
                        onChange={onChange}
                        placeholderStart="от"
                        placeholderEnd="до"
                    />
                </div>
            </div>
            <FilterSpecialities
                size="xl"
                label="Вид деятельности"
                placeholder="Выберите вид деятельности"
                name="specialityIds"
                onChange={onChange}
                value={values.specialityIds}
            />
            <NmDadata
                size="xl"
                name="regionFiasId"
                label="Регион"
                onChange={onChange}
                placeholder="Выберите регион"
                fromBound="region"
                toBound="region"
                initialAddressIds={values.regionFiasId}
                //formatter={getDadataAddress}
                isClearable
            />
            <NmDadata
                size="xl"
                label="Город"
                name="cityFiasId"
                onChange={onChange}
                formatter={dadataValueCityFormatter}
                placeholder="Выберите город"
                fromBound="city"
                toBound="city"
                isAllSelectionShown={false}
                initialAddressIds={values.cityFiasId}
            />
            <NmDropdownV2
                size="xl"
                search
                multiple
                disabled={values.type === SMART_LINK_FORM_TYPE.TASK}
                name="clientCategoryIds"
                label="Категория заказчика"
                placeholder="Выберите категорию заказчика"
                value={values.clientCategoryIds}
                options={clientCategoriesOptions}
                onChange={onChange}
            />
            <SmartLinkCount values={values} />
        </>
    );
};