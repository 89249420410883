import React, {useState} from "react";
import Media from "react-media";
import {useSelector} from "react-redux";

import Filter from "../../../components/ActualComponents/Filter";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import ButtonCalculator from "../../../components/ButtonCalculator";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import PatentsAccountBasisReplenishment from "../../patents/account-basis-replenishment";
import DepositCalculator from "../deposit-calculator";
import DepositReplenishmentAccountModal from "../deposit-replenishment-account-modal";
import DepositClientListAccountBasis from "./components/account-basis";
import DepositTabList from "./components/tabs-list";

import {usePagination} from "../../../hooks/usePagination";
import useFilter from "./hooks/useFilter";
import useGetDepositData from "./hooks/useGetlDepositData";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getTabLinks} from "./utils/getTabLinks";

import {COLOR} from "../../../constants/color";
import {
    DEPOSIT_CARD_OPTIONS,
    DEPOSIT_OPERATION,
} from "../../../constants/deposit/actions";
import {FOREMAN, HR_MANAGER, OBJECT_MANAGER} from "../../../constants/roles";

import {getClientCardSelector} from "../../../ducks/client";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";

import "./style.sass";


export const DEPOSIT_SUB_PAGE = {
    REPLENISHMENT_AND_REFUNDS: "replenishment-and-refunds",
    COMPLETED_PAYMENTS: "completed-payments",
    PAYMENTS_IN_PROCESS: "payments-in-process",
    PAYMENTS_PENDING: "payment-pending",
    SPENT_LIMIT: "spent-limit",
    // Подвкаладки для вкладки оплаты в процессе
    REGISTRY_ORDERS: "registry-orders",
    PATENTS: "patents-payments",
    PAID_APIS: "paid-apis",
    TASKS: "tasks",
    INDIVIDUAL_ENTREPRENEUR: "individual-entrepreneur",
};

const MEDIA_QUERIES = {mobile: {maxWidth: 768}};

function DepositClientList(props) {
    const {
        match: {
            params: {
                clientId,
                subPage,
                subTab,
            },
        },
        links = getTabLinks(clientId),
        header = "Депозит (НПД)",
        // НДФЛ
        isCivil,
        // Депозит по патентам
        isPatentsPage,
    } = props;

    const [isShowDepositCalc, setShowDepositCalc] = useState(false);
    const [isOpenAccountModal, setOpenAccountModal] = useState(false);
    const [filterData, setFilterData] = useState({});

    const {
        paidApisEnabled,
    } = useSelector(getClientPropertiesCardSelector);
    const {archived} = useSelector(getClientCardSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        filterDto,
        setFilterDto,
    } = useFilter(clientId, subPage);

    const {
        list,
        tabs,
        cards,
        routes,
        totalPage,
        totalCount,
        onClickOpenModal,
        onClickCloseModal,
        dataModal,
        progress,
        filters,
        clearList,
        civilAccountBank,
        fetchObjects,
        onSubmitCivil,
    } = useGetDepositData({
        clientId,
        pageSize,
        pageNum,
        filter: filterDto,
        filterData,
        subPage,
        subTab,
        isCivil,
        isPatentsPage,
    });

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = (filter = {}) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });

        fetchObjects();
    };

    const onClickHeaderContextMenuItem = (option) => {
        switch (option.value) {
        case DEPOSIT_OPERATION.GO_CALCULATOR:
            toggleShowDepositCalc();
            return;
        case DEPOSIT_OPERATION.INVOICE_OF_DEPOSIT_REPLENISHMENT:
            openAccountModal();
            return;
        default:
            return;
        }
    };

    const toggleShowDepositCalc = () => {
        setShowDepositCalc(true);
    };

    const openAccountModal = () => {
        setOpenAccountModal(true);
    };

    function renderCalcBtn() {
        if (isCivil) {
            return null;
        }

        return (
            <ButtonCalculator
                className="me-4"
                onClick={toggleShowDepositCalc}
            />
        );
    }

    function renderAccountBtn() {
        if (archived) {
            return null;
        }

        return (
            <DepositClientListAccountBasis
                isCivil={isCivil}
                clientId={clientId}
                accountNumber={civilAccountBank}
            />
        );
    }

    function getContextMenuOptions() {
        const invoiceOfDepositReplenishmentOption = archived ? [] : [DEPOSIT_CARD_OPTIONS.INVOICE_OF_DEPOSIT_REPLENISHMENT];

        return {
            mobile: [
                DEPOSIT_CARD_OPTIONS.GO_CALCULATOR,
                ...invoiceOfDepositReplenishmentOption,
                DEPOSIT_CARD_OPTIONS.FILTER,
            ],
        };
    }

    function renderDepositCalc() {
        if (isShowDepositCalc) {
            return (
                <DepositCalculator
                    handleClose={() => setShowDepositCalc(false)}
                />
            );
        }

        return null;
    }

    function renderDepositInfo() {
        const isAccessForCards = ![OBJECT_MANAGER, FOREMAN, HR_MANAGER].includes(ls(USER_ROLE));

        if (!isAccessForCards) {
            return null;
        }

        return (
            <DepositValuesAmountInfo
                isCivil={isCivil}
                clientId={clientId}
            />
        );
    }

    function getTabs() {
        const {
            REPLENISHMENT_AND_REFUNDS,
            COMPLETED_PAYMENTS,
            PAYMENTS_IN_PROCESS,
            PAYMENTS_PENDING,
            SPENT_LIMIT,
            PAID_APIS,
        } = links;

        const patentsTabs = [
            {
                active: REPLENISHMENT_AND_REFUNDS,
                link: REPLENISHMENT_AND_REFUNDS,
                name: "Пополнения и возвраты депозита",
                onClick: clearFilter,
            },
            {
                active: [
                    COMPLETED_PAYMENTS,
                ],
                link: COMPLETED_PAYMENTS,
                name: "Завершенные платежи",
                onClick: () => {
                    clearFilter();
                    clearList();
                },
            },
            {
                active: PAYMENTS_IN_PROCESS,
                link: PAYMENTS_IN_PROCESS,
                name: "В процессе оплаты",
                onClick: () => {
                    clearFilter();
                    clearList();
                },
            },
        ];

        const civilTabs = [
            ...patentsTabs,
            {
                active: PAYMENTS_PENDING,
                link: PAYMENTS_PENDING,
                name: "Оплаты на рассмотрении",
                onClick: () => {
                    clearFilter();
                    clearList();
                },
            },
        ];

        if (isPatentsPage) {
            return patentsTabs;
        }

        if (isCivil) {
            return civilTabs;
        }

        const paidApiTabs = paidApisEnabled ? [
            {
                active: PAID_APIS,
                link: PAID_APIS,
                name: "Вызовы API",
                onClick: clearFilter,
            },
        ] : [];

        return [
            ...civilTabs,
            {
                active: SPENT_LIMIT,
                link: SPENT_LIMIT,
                name: "Израсходовано лимита",
                onClick: clearFilter,
            },
            ...paidApiTabs,
        ];
    }

    function renderTabs() {
        return (
            <Tabs
                {...props}
                className="mb-4 mb-xxl-5"
                panes={getTabs()}
            />
        );
    }

    const getFilter = () => {
        return (
            <Filter
                filters={filters}
                onSubmit={submitFilter}
                clearFilter={clearFilter}
                isInitStateEqualEmpty={false}
                updateFilter={(filter) => setFilterData(filter)}
            />
        );
    };

    const renderAccountNumber = () => {
        if (!isCivil) {
            return null;
        }

        return (
            <>
                <Text
                    level="2"
                    color={COLOR.SECONDARY_70}
                >
                    Номер счета
                </Text>
                <Text.Title
                    level="4"
                    bold
                    className="mb-0 mb-md-1"
                    color={COLOR.SECONDARY_100}
                >
                    {civilAccountBank || "Отсутствует"}
                </Text.Title>
            </>
        );
    };

    const getModal = () => {
        if (!isOpenAccountModal) {
            return null;
        }

        return isCivil ?
            <PatentsAccountBasisReplenishment
                title="Счет-основание на пополнение депозита НДФЛ"
                accountNumber={civilAccountBank}
                clientId={clientId}
                onSubmit={onSubmitCivil}
                onClose={() => setOpenAccountModal(false)}
            />
            :
            <DepositReplenishmentAccountModal
                dependentData={{clientId}}
                close={() => setOpenAccountModal(false)}
            />;
    };

    return (
        <NmPage
            header={
                <NmTitle size="xl">
                    {header}
                </NmTitle>
            }
            mediaQueries={MEDIA_QUERIES}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            totalPages={totalPage}
            totalCount={totalCount}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            contextMenuOptions={getContextMenuOptions()}
            subHeader={renderDepositInfo()}
            onClickContextMenuItem={onClickHeaderContextMenuItem}
            controls={
                <Media query={{minWidth: 767}}>
                    <>
                        {renderCalcBtn()}
                        {renderAccountBtn()}
                    </>
                </Media>
            }
            typeFilter="vertical"
            filtersBase={getFilter()}
        >
            {getModal()}
            {renderAccountNumber()}
            {renderTabs()}
            <DepositTabList
                {...props}
                tabs={tabs}
                isCivil={isCivil}
                cards={cards}
                subTab={subTab}
                list={list}
                onClickOpenModal={onClickOpenModal}
                onClickCloseModal={onClickCloseModal}
                dataModal={dataModal}
                totalPages={totalPage}
                totalCount={totalPage}
                routes={routes}
                progress={progress}
            />
            {renderDepositCalc()}
        </NmPage>
    );
}

export default DepositClientList;
