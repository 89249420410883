import {
    BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_UPDATE_STORE,
} from "./actions";

export const getSettingsInstructionsKedoInitialState = () => {
    return {
        list: [],
        progress: false,
        progressAction: false,
    };
};

const initialState = getSettingsInstructionsKedoInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_SUCCESS: {
        return {
            ...state,
            list: payload,
        };
    }
    case BFF_SETTINGS_INSTRUCTIONS_KEDO_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default: return state;
    }
};