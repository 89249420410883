import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import TemplatesClientUsage from "../../../../components/TemplatesClientUsage";

import {
    documentCustomTemplateClientUsageListSelector,
    documentCustomTemplateClientUsageOrderTypesSortOptionsSelector,
    documentCustomTemplateClientUsagesActionTypeDictSelector,
    documentCustomTemplateClientUsageTotalCountSelector,
    documentCustomTemplateClientUsageTotalPagesSelector,
    documentCustomTemplateProgressActionSelector,
    getDocumentCustomTemplateClientUsageActionTypeDict,
    getDocumentCustomTemplateClientUsageOrderTypesDict,
    getDocumentCustomTemplateClientUsagePage,
    updateDocumentCustomTemplateClientUsage,
    updateDocumentCustomTemplateStore,
} from "../../../../ducks/documentsCustomTemplate";

const CustomDocumentTemplatesClientUsage = (props) => {
    const {
        fetchTemplates,
        onClose,
        customDocumentTemplateIdFilter,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(documentCustomTemplateProgressActionSelector);
    const totalCount = useSelector(documentCustomTemplateClientUsageTotalCountSelector);
    const list = useSelector(documentCustomTemplateClientUsageListSelector);
    const totalPages = useSelector(documentCustomTemplateClientUsageTotalPagesSelector);
    const actionTypeDict = useSelector(documentCustomTemplateClientUsagesActionTypeDictSelector);
    const sortOptions = useSelector(documentCustomTemplateClientUsageOrderTypesSortOptionsSelector);

    useEffect(() => {
        dispatch(getDocumentCustomTemplateClientUsageOrderTypesDict());
        dispatch(getDocumentCustomTemplateClientUsageActionTypeDict());

        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                clientUsageActionTypeDict: {},
                clientUsageList: [],
                clientUsagePageData: {},
                clientUsageTotalCount: 0,
                clientUsageOrderTypesDict: {},
            }));
        };
    }, []);

    const updateClientUsage = (data) => {
        dispatch(updateDocumentCustomTemplateClientUsage(data));
    };

    const fetchList = (data) => {
        dispatch(getDocumentCustomTemplateClientUsagePage({
            ...data,
            customDocumentTemplateIdFilter,
        }));
    };

    return (
        <TemplatesClientUsage
            isVisibleSetDefaultTemplateButton={true}
            fetchTemplates={fetchTemplates}
            onClose={onClose}
            progressAction={progressAction}
            totalCount={totalCount}
            list={list}
            totalPages={totalPages}
            actionTypeDict={actionTypeDict}
            updateClientUsage={updateClientUsage}
            fetchList={fetchList}
            sortOptions={sortOptions}
        />
    );
};

export default CustomDocumentTemplatesClientUsage;