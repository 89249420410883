import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import MassConfirm from "../../../../components/MassConfirm";
import OrderListMassTemplatePublication from "../mass-template-publication";

import {useOrderListTemplatePublication} from "./hooks/useTemplatePublication";

import {getOrderListMassTemplatePublicationCustomDocumentIds} from "./utils/getCustomDocumentIds";
import {getOrderListMassCheckingPublishData} from "./utils/getPublishOrdersData";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {ORDER_MASS_ACTION, ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE} from "../../../../constants/order";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {
    archiveOrders,
    closeOrders,
    deleteOrders,
    jobProgressPublishInWorkStatusSelector,
    ordersEndContractorSearch,
    progressOrdersMassActionSelector,
    publishOrders,
    publishOrdersInWorkStatus,
} from "../../../../ducks/job";
import {
    checkToWarningBeforePublish,
    orderClearFieldStore,
} from "../../../../ducks/order";

import "./style.sass";

const formatList = (list) => {
    return list.map(value => {
        return {
            ...value,
            name: `№ ${value.orderNum} - ${value.name}`,
            publishPossible: value.ok,
        };
    });
};

const OrderListMassChecking = (props) => {
    const {
        clientId,
        isCheckboxList,
        onSuccess,
        onCancel,
        onWarningPublication,
        actionType,
        listMassConfirmAllPossible,
    } = props;
    const dispatch = useDispatch();
    const list = useSelector(state => state.order.checkMultipleList);
    const checkMultipleSuccessCount = list.filter(value => Boolean(value.ok)).length;
    const checkMultipleWarningCount = list.filter(value => !value.ok).length;
    const progressMassAction = useSelector(progressOrdersMassActionSelector);
    const progressPublishInWorkStatus = useSelector(jobProgressPublishInWorkStatusSelector);
    const progressWarningOrdersChecking = useSelector(state => state.order.progressWarningOrders);
    const {customDocumentTemplatesAvailable} = useSelector(getClientPropertiesCardSelector);

    const {
        isOpen: isOpenTemplatePublication,
        onOpenTemplatePublication,
        onCloseTemplatePublication,
    } = useOrderListTemplatePublication();

    const isActionAllPossible = [ORDER_MASS_ACTION.COMPLETE_SELECTION, ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK].includes(actionType);

    useEffect(() => {
        return () => {
            dispatch(orderClearFieldStore({
                checkMultipleList: [],
            }));
        };
    }, []);

    const okOrders = list.filter(value => Boolean(value.ok));

    const onSubmitMassConfirm = (submitData = {}) => {
        switch (actionType) {
        case ORDER_MASS_ACTION.CLOSE_ORDERS:
            dispatch(closeOrders({
                data: okOrders,
                onSuccess,
            }));

            return;
        case ORDER_MASS_ACTION.DELETE_DRAFTS:
            dispatch(deleteOrders({
                data: okOrders,
                onSuccess,
            }));

            return;
        case ORDER_MASS_ACTION.ARCHIVE_CLOSED_ORDERS:
            dispatch(archiveOrders({
                data: okOrders,
                onSuccess,
            }));

            return;
        case ORDER_MASS_ACTION.PUBLISH_ORDERS: {
            if (isOpenTemplatePublication) {
                dispatch(publishOrders({
                    data: getOrderListMassCheckingPublishData({
                        submitData,
                        customDocumentTemplatesAvailable,
                        orders: okOrders,
                    }),
                    onSuccess,
                }));

                return;
            }


            dispatch(checkToWarningBeforePublish({
                data: okOrders,
                onSuccess: (result = {}) => {
                    const {hasWarningBeforePublish} = result;

                    if (hasWarningBeforePublish) {
                        onCancel();
                        onWarningPublication();

                        return;
                    }

                    const isAnyDraft = okOrders.find((item) => (item.status === ORDER_STATUS.DRAFT));

                    if (isAnyDraft && !isOpenTemplatePublication && customDocumentTemplatesAvailable) {
                        onOpenTemplatePublication();

                        return;
                    }

                    dispatch(publishOrders({
                        data: getOrderListMassCheckingPublishData({
                            submitData,
                            customDocumentTemplatesAvailable,
                            orders: okOrders,
                        }),
                        onSuccess,
                    }));
                },
            }));

            return;
        }
        case ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK: {
            const isAnyDraft = listMassConfirmAllPossible.find((item) => (item.status === ORDER_STATUS.DRAFT));

            if (isAnyDraft && !isOpenTemplatePublication && customDocumentTemplatesAvailable) {
                onOpenTemplatePublication();

                return;
            }

            const orders = listMassConfirmAllPossible.map(({clientId, orderId, registryId}) => ({
                clientId,
                orderId,
                registryId,
            }));

            dispatch(publishOrdersInWorkStatus({
                data: getOrderListMassCheckingPublishData({
                    orders,
                    submitData,
                    customDocumentTemplatesAvailable,
                }),
                onSuccess,
            }));

            return;
        }
        case ORDER_MASS_ACTION.COMPLETE_SELECTION:
            dispatch(ordersEndContractorSearch({
                data: listMassConfirmAllPossible.map(({clientId, orderId, registryId}) => ({
                    clientId,
                    orderId,
                    registryId,
                })),
                onSuccess,
            }));

            return;
        default: return;
        }
    };

    const getCustomTemplateIds = () => {
        if (isActionAllPossible) {
            return getOrderListMassTemplatePublicationCustomDocumentIds(listMassConfirmAllPossible);
        }

        const okOrders = list.filter(value => Boolean(value.ok));

        return getOrderListMassTemplatePublicationCustomDocumentIds(okOrders);
    };

    return (
        <>
            {
                isOpenTemplatePublication &&
                <OrderListMassTemplatePublication
                    customTemplateIds={getCustomTemplateIds()}
                    clientId={clientId}
                    progress={progressMassAction || progressPublishInWorkStatus}
                    isNpd={
                        [ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK].includes(actionType) ?
                            listMassConfirmAllPossible.some(value => (value.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ)) :
                            okOrders.some(value => (value.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ))
                    }
                    isNdfl={
                        [ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK].includes(actionType) ?
                            listMassConfirmAllPossible.some(value => (value.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL)) :
                            okOrders.some(value => (value.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL))
                    }
                    onClose={() => {
                        onCancel();
                        onCloseTemplatePublication();
                    }}
                    onSubmit={onSubmitMassConfirm}
                />
            }
            {
                !isOpenTemplatePublication &&
                <MassConfirm
                    loading={progressMassAction || progressWarningOrdersChecking}
                    list={isActionAllPossible ? listMassConfirmAllPossible : formatList(list)}
                    publicationCount={isActionAllPossible ? listMassConfirmAllPossible.length : checkMultipleSuccessCount}
                    impossibleCount={!isActionAllPossible && checkMultipleWarningCount}
                    successLabel={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].SUCCESS_LABEL}
                    warningLabel={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].WARNING_LABEL}
                    warningText={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].WARNING_TEXT}
                    title={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].TITLE}
                    text={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].TEXT}
                    unablePublishTooltip={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].TOOLTIP}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                    isCheckboxList={isCheckboxList}
                    onConfirm={onSubmitMassConfirm}
                    onCancel={onCancel}
                />
            }
        </>
    );
};

export default OrderListMassChecking;