import {useState} from "react";
import {useDispatch} from "react-redux";

import {USERS_ROLES_ACTION_TYPE} from "../../../../constants/settingsUsersRoles";

import {deleteSettingsUsersRole} from "../../../../ducks/settingsUsersRoles";

export function useActionSettingsUsersRoles({fetchList = () => {}}) {
    const [isOpenEditForm, setIsOpenEditForm] = useState(false);
    const [editModalData, setEditModalData] = useState({});
    const [confirmAction, setConfirmAction] = useState({});

    const dispatch = useDispatch();

    const toggleOpenEditForm = () => {
        if (isOpenEditForm) {
            setEditModalData({});
        }

        setIsOpenEditForm(!isOpenEditForm);
    };

    const editAction = (actionType, data = {}) => {
        setEditModalData({
            actionType,
            ...data,
            name: actionType === USERS_ROLES_ACTION_TYPE.COPY ? `Копия ${data.name}` : data.name,
        });

        toggleOpenEditForm();
    };

    const deleteAction = ({id, name}) => {
        setConfirmAction({
            isOpen: true,
            content: `Вы уверены, что хотите удалить роль \"${name}\"?`,
            onConfirm: () => {
                dispatch(deleteSettingsUsersRole({
                    id,
                    archived: true,
                    onSuccess: () => {
                        fetchList();
                        setConfirmAction({});
                    },
                }));
            },
        });
    };

    const closeConfirmModal = () => {
        setConfirmAction({});
    };

    return {
        isOpenEditForm,
        toggleOpenEditForm,
        confirmAction,
        deleteAction,
        closeConfirmModal,
        editAction,
        editModalData,
    };
}