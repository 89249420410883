import {useEffect} from "react";

import {useKedoExportsBff} from "./useBff";

export const useKedoExportsFetch = (params) => {
    const {
        pageNum,
        pageSize,
        clientId,
    } = params;

    const {
        fetchPage,
        clearStore,
    } = useKedoExportsBff();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
    ]);

    useEffect(() => {
        return () => {
            clearStore();
        };
    }, []);

    const fetchList = () => {

        fetchPage({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
        });
    };

    return {
        fetchList,
    };
};