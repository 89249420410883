import {getContractorCheckOnInfo} from "./getCheckOnInfo";

import {toastError} from "../../../../utils/toastHelper";

/***
 * Обработка ошибки при проверке ПД, если ПД в текущий момент проверяется другим юзером
 * @param result
 * @returns {boolean}
 */
export const handleContractorCheckOnError = (result) => {
    if (result.errorCode === "ADMINISTRATOR_CHECK_ALREADY_PERFORMING_BY_ANOTHER_CLIENT_USER") {
        const userInfo = getContractorCheckOnInfo(result);

        toastError(`Исполнитель уже находится на проверке у пользователя ${userInfo}`);

        return true;
    }

    return false;
};