import {useState} from "react";

export function useAdvertisementContractorsFilter() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const setFilterDto = (filter) => {
        setFilter(filter);
    };

    return {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
    };
}