import * as yup from "yup";

import {REG_EXP} from "../../../../../../../constants/regExp";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

export const getKedoDocumentTypesValidation = () => {
    return yup.object().shape({
        name: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Максимальная длина - 255 символов"),
        externalId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(50, "Максимальная длина - 50 символов")
            .test("letters", "Может содержать только латиницу, цифры и нижний регистр", (value) => {
                return new RegExp(REG_EXP.DOCUMENT_EXTERNAL_ID).test(value);
            }),
    });
};