import React from "react";

import {ContractorFinanceIncomeNpd} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
} from "../../../../../constants/links";
import {STATUS_PAYMENT_CONTRACTOR_INCOME_AWAITING} from "../../../../../constants/status";

import {getContractorAwaitingFinanceNpdPayments} from "../../../../../ducks/contractorFinanceIncomeNpd";

const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_AWAITING);

export const ContractorFinanceIncomeNpdAwaiting = (props) => {
    return (
        <ContractorFinanceIncomeNpd
            subTabsLinks={{
                orders: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
                tasks: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
            }}
            fetchList={getContractorAwaitingFinanceNpdPayments}
            isShowBankStatusFilter={false}
            isShowItemActions={false}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
