import React, {Component} from "react";

import {Rating} from "semantic-ui-react";

import bem from "../../utils/bem";
import {formatNumber} from "../../utils/stringFormat";

import PropTypes from "prop-types";

import "./style.sass";

class NmReadonlyRating extends Component {
    static propTypes = {
        className: PropTypes.string,
        rating: PropTypes.number,
        maxRating: PropTypes.number,
        fixedNumber: PropTypes.number,
        isMiniRatingInfo: PropTypes.bool,
    };

    static defaultProps = {
        rating: 0,
        maxRating: 5,
        fixedNumber: 0,
        isMiniRatingInfo: false,
    };

    constructor(props) {
        super(props);

        const [block, element] = bem("nm-readonly-rating", props.className);

        this.block = block;
        this.element = element;
    }

    getRating() {
        const {
            rating,
            isReverse,
            classNameValue = "",
            fixedNumber,
        } = this.props;

        return (
            <span className={`${this.element("value", {reverse: isReverse})} ${classNameValue}`}>
                {formatNumber(rating || 0, fixedNumber|| 0).replace(",", ".")}
            </span>
        );
    };

    render() {
        const {
            isReverse,
            rating,
            maxRating,
            onClick,
            isMiniRatingInfo,
        } = this.props;

        return (
            <div
                onClick={onClick}
                className={this.block({reverse: isReverse, mini: isMiniRatingInfo})}
            >
                {!isMiniRatingInfo && this.getRating()}
                <Rating
                    disabled={true}
                    size="small"
                    icon="star"
                    rating={isMiniRatingInfo? 1: rating}
                    maxRating={maxRating}
                />
                {isMiniRatingInfo && this.getRating()}
            </div>
        );
    };
}

export default NmReadonlyRating;
