export const getPayPopupWithRatingUndefinedValue = (params) => {
    const {
        value,
        content,
    } = params;

    if (value === "-") {
        return "-";
    }

    return content;
};