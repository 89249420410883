import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

export const useSettingsDirectoriesOrderCategoryListFilter = ({setPagination, pageSize}) => {
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            valueFilter: "",
        },
        pageSize,
        setPagination,
    });
    const {t} = useTranslation();

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "valueFilter",
                        label: t("order-categories.category"),
                        size: "lg",
                    },
                ],
            },
        ];
    }, []);

    return {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    };
};