import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {getEndDate, getStartDate} from "../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {bffCommonDictsLeadRegistrationStatusDictOptionsSelector} from "../../../../ducks/bff/common/dicts/selectors";

export const useContractorOnboardingLeadsFilter = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const registrationStatusDictOptions = useSelector(bffCommonDictsLeadRegistrationStatusDictOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter: params.filterData || {
            registrationStatusFilter: [],
        },
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        const {
            creationDateTimeFrom,
            creationDateTimeTo,
            externalLeadIdFilter,
            externalContractorInnFilter,
            externalContractorPhoneFilter,
            externalContractorNameFilter,
            registrationStatusFilter,
            ...otherFilter
        } = filter;

        return {
            ...otherFilter,
            creationDateTimeFrom: isNullOrWhitespace(creationDateTimeFrom) ? undefined : getStartDate(creationDateTimeFrom, "yyyy-MM-dd"),
            creationDateTimeTo: isNullOrWhitespace(creationDateTimeTo) ? undefined : getEndDate(creationDateTimeTo, "yyyy-MM-dd"),
            externalLeadIdFilter: handleFormString(externalLeadIdFilter),
            externalContractorInnFilter: handleFormString(externalContractorInnFilter),
            externalContractorPhoneFilter: externalContractorPhoneFilter ? removePhoneMask(externalContractorPhoneFilter) : undefined,
            externalContractorNameFilter: handleFormString(externalContractorNameFilter),
            registrationStatusFilter: registrationStatusFilter && registrationStatusFilter.length ? registrationStatusFilter : undefined,
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFrom",
                        endFieldName: "creationDateTimeTo",
                        isClearable: true,
                        label: "Дата создания лида",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalContractorNameFilter",
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "externalContractorPhoneFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalContractorInnFilter",
                        label: "ИНН",
                        placeholder: "Введите ИНН",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label: "Статус регистрации",
                        placeholder: "Выберите статус",
                        name: "registrationStatusFilter",
                        multiple: true,
                        isClearable: true,
                        search: true,
                        options: registrationStatusDictOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        label: "Заказчик",
                        placeholder: "Выберите заказчика",
                        name: "clientIdFilter",
                        isClearable: true,
                        isBff: true,
                        search: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "scenarioNameFilter",
                        label: "Название сценария",
                        placeholder: "Введите название",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "scenarioNumberFilter",
                        label: "Номер сценария",
                        placeholder: "Введите номер",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalLeadIdFilter",
                        label: "Идентификатор лида",
                        placeholder: "Введите идентификатор",
                    },
                ],
            },
        ];
    }, [
        registrationStatusDictOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};