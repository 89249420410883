import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import AmountInput from "../../../../../components/AmountInput";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {FORM_TIME_ZONE_OPTIONS} from "./consts/form";

import {useDepositRateEditForm} from "./hooks/useDepositRateEditForm";

import {compareDatesWithoutTime, today} from "../../../../../utils/dateFormat";

import {
    clientPropertiesProgressEditCommissionSelector,
} from "../../../../../ducks/clientProperties";

import PropTypes from "prop-types";

import "./style.sass";

const DepositRateEdit = (props) => {
    const {
        isCivil,
        payment,
        onClose,
        onFetchList,
    } = props;

    const {
        clientId,
        clientName,
    } = payment;

    const {t} = useTranslation();
    const progress = useSelector(clientPropertiesProgressEditCommissionSelector);

    const {
        fields,
        values,
        errors,
        isOpenConfirm,
        confirmContent,
        onChange,
        handleSubmit,
        onCloseConfirm,
        onSubmitConfirm,
    } = useDepositRateEditForm({
        fields: props.fields,
        isCivil,
        payment,
        clientId,
        onClose,
        onFetchList,
    });

    function renderDateBlock({values, errors, name, classNameRegionTime, classNameDate}) {
        return (
            <>
                <div className={classNameDate}>
                    <NmDatePicker
                        size="xl"
                        label={t("current-commission-rate.date-change")}
                        placeholderText="дд.мм.гг чч:мм"
                        showTimeSelect
                        className="deposit-rate-edit__datepicker"
                        minDate={today.iso.start()}
                        timeCaption={t("time-sheet.time")}
                        timeIntervals={1}
                        minTime={compareDatesWithoutTime(values[name].fromDatetime, today.current.now()) ? new Date() : today.iso.start()}
                        maxTime={today.iso.end()}
                        dateFormat="dd.MM.yy HH:mm"
                        dateFormatMask="99.99.99 99:99"
                        disabled={!values[name]?.isDeferred}
                        error={errors[name]?.fromDatetime}
                        name="fromDatetime"
                        selected={values[name]?.fromDatetime}
                        onChange={(event, params) => {
                            onChange(event, params, name);
                        }}
                    />
                </div>
                <div className={classNameRegionTime}>
                    <NmDropdownV2
                        size="xl"
                        label={t("current-commission-rate.timezone")}
                        name="regionTime"
                        disabled={!values[name]?.isDeferred}
                        onChange={(event, params) => {
                            onChange(event, params, name);
                        }}
                        value={values[name]?.regionTime}
                        options={FORM_TIME_ZONE_OPTIONS}
                    />
                </div>
            </>
        );
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`Процентные ставки комиссии по выплатам ${isCivil ? "НДФЛ" : "Самозанятым"} для компании ${clientName}`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    disabled={progress}
                    submit={handleSubmit}
                />
            }
        >
            {isOpenConfirm && <NmConfirmV2
                content={confirmContent}
                title={`Вы действительно хотите изменить процентные ставки комиссии для компании ${clientName}?`}
                warning
                inline={false}
                classNameContent="deposit-rate-edit__confirm-content"
                contentAlign="left"
                onCancel={onCloseConfirm}
                onConfirm={onSubmitConfirm}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />}
            <NmForm
                addMargin
                className="commission-rate"
            >
                {fields.map((item, index) => {
                    const {
                        title,
                        name,
                    } = item;

                    const isAdditionalFields = name === "minCommissionChange";
                    const isAdditionalNdflFields = name === "minimalCommissionNdflChange";

                    return (
                        <React.Fragment key={index}>
                            <Text.Sub
                                level="2"
                                bold
                            >
                                {title}
                            </Text.Sub>
                            {isAdditionalFields ?
                                <div className="row gx-4">
                                    <div className="col-16 col-md-8">
                                        <AmountInput
                                            newInput
                                            size="xl"
                                            error={errors[name]?.thresholdAmount}
                                            maskChar={null}
                                            label="Мин. сумма выплаты без доп. комиссии, ₽"
                                            className=""
                                            name="thresholdAmount"
                                            onChange={(event, params) => {
                                                onChange(event, params, name);
                                            }}
                                            value={values[name]?.thresholdAmount}
                                        />
                                    </div>
                                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                                        <AmountInput
                                            newInput
                                            size="xl"
                                            error={errors[name]?.minCommission}
                                            maskChar={null}
                                            label="Дополнительная сумма комиссии, ₽"
                                            className=""
                                            name="minCommission"
                                            onChange={(event, params) => {
                                                onChange(event, params, name);
                                            }}
                                            value={values[name]?.minCommission}
                                        />
                                    </div>
                                </div> :
                                <AmountInput
                                    newInput
                                    size="xl"
                                    error={errors[name]?.rate}
                                    maskChar={null}
                                    label={isAdditionalNdflFields && "Минимальная комиссия, ₽"}
                                    className="commission-rate__input commission-rate__input_bt-offset"
                                    name="rate"
                                    onChange={(event, params) => {
                                        onChange(event, params, name);
                                    }}
                                    value={values[name]?.rate}
                                />
                            }
                            <NmCheckboxV2
                                className="commission-rate__checkbox"
                                onChange={(event, params) => {
                                    onChange(event, params, name);
                                }}
                                name="isDeferred"
                                checked={values[name]?.isDeferred}
                                label={isAdditionalFields ? "Отсроченное изменение дополнительной комиссии компании" : t("current-commission-rate.deferred-change")}
                            />
                            {isAdditionalFields ?
                                <div className="row gx-4">
                                    <div className="col-16 col-md-8">
                                        <AmountInput
                                            newInput
                                            size="xl"
                                            error={errors[name]?.deferredThreshold}
                                            maskChar={null}
                                            disabled={!values[name]?.isDeferred}
                                            className=""
                                            label="Мин. сумма выплаты без доп. комиссии, ₽"
                                            name="deferredThreshold"
                                            onChange={(event, params) => {
                                                onChange(event, params, name);
                                            }}
                                            value={values[name]?.deferredThreshold}
                                        />
                                    </div>
                                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                                        <AmountInput
                                            newInput
                                            size="xl"
                                            error={errors[name]?.deferredMinCommission}
                                            maskChar={null}
                                            className=""
                                            disabled={!values[name]?.isDeferred}
                                            label="Дополнительная сумма комиссии, ₽"
                                            name="deferredMinCommission"
                                            onChange={(event, params) => {
                                                onChange(event, params, name);
                                            }}
                                            value={values[name]?.deferredMinCommission}
                                        />
                                    </div>
                                </div> :
                                <div className="row gx-4">
                                    <div className="col-16 col-md-4">
                                        <AmountInput
                                            newInput
                                            size="xl"
                                            label={
                                                isAdditionalNdflFields ?
                                                    "Мин. комиссия, ₽" :
                                                    t("current-commission-rate.title")
                                            }
                                            error={errors[name]?.deferredRate}
                                            disabled={!values[name]?.isDeferred}
                                            maskChar={null}
                                            name="deferredRate"
                                            onChange={(event, params) => {
                                                onChange(event, params, name);
                                            }}
                                            value={values[name].deferredRate}
                                        />
                                    </div>
                                    {
                                        renderDateBlock({
                                            values,
                                            errors,
                                            name,
                                            classNameDate: "col-16 col-md-8 mt-3 mt-md-0",
                                            classNameRegionTime: "col-16 col-md-4 mt-3 mt-md-0",
                                        })
                                    }
                                </div>}
                            {
                                isAdditionalFields &&
                                <div className="row gx-4">
                                    {
                                        renderDateBlock({
                                            values,
                                            errors,
                                            name,
                                            classNameDate: "col-16 col-md-8",
                                            classNameRegionTime: "col-16 col-md-8 mt-3 mt-md-0",
                                        })
                                    }
                                </div>
                            }
                        </React.Fragment>
                    );
                })}
            </NmForm>
        </NmModal>
    );
};

DepositRateEdit.propTypes = {
    isCivil: PropTypes.bool,
    payment: PropTypes.object,
    onClose: PropTypes.func,
    onFetchList: PropTypes.func,
};

DepositRateEdit.defaultProps = {
    isCivil: false,
    payment: {},
    onClose: () => {
    },
    onFetchList: () => {
    },
};

export default DepositRateEdit;