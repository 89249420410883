export const KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE = {
    CREATE_STAFF: {
        VALUE: "CREATE_STAFF",
        TEXT: "Добавление сотрудников",
    },
    CREATE_DOCUMENT: {
        VALUE: "CREATE_DOCUMENT",
        TEXT: "Создание документа",
    },
};

export const KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE_OPTIONS = [
    {
        key: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE.CREATE_STAFF.VALUE,
        text: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE.CREATE_STAFF.TEXT,
        value: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE.CREATE_STAFF.VALUE,
    },
    {
        key: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE.CREATE_DOCUMENT.VALUE,
        text: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE.CREATE_DOCUMENT.TEXT,
        value: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE.CREATE_DOCUMENT.VALUE,
    },
];

export const KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS = {
    OK: {
        VALUE: "OK",
        TEXT: "Успешно",
    },
    ERROR: {
        VALUE: "ERROR",
        TEXT: "Неуспешно",
    },
};

export const KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS_OPTIONS = [
    {
        key: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS.OK.VALUE,
        text: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS.OK.TEXT,
        value: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS.OK.VALUE,
    },
    {
        key: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS.ERROR.VALUE,
        text: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS.ERROR.TEXT,
        value: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS.ERROR.VALUE,
    },
];