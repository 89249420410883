import React, {Component} from "react";

import {Pagination} from "semantic-ui-react";

import PropTypes from "prop-types";

import "./style.sass";

class CustomPagination extends Component {
    static propTypes = {
        pageNum: PropTypes.number,
        totalPages: PropTypes.number,
        className: PropTypes.string,
    };

    static defaultProps = {
        pageNum: 1,
        className: "",
        totalPages: 1,
    };

    render() {
        const {
            activePage,
            onPageChange,
            totalPages,
            className,
        } = this.props;

        if (totalPages === 1) {
            return null;
        }

        return (
            <Pagination
                className={`nmx-pag ${className}`}
                prevItem={
                    {
                        content: (
                            <span className="material-icons">
                                navigate_before
                            </span>
                        ),
                        icon: true,
                    }
                }
                nextItem={
                    {
                        content: (
                            <span className="material-icons">
                                navigate_next
                            </span>
                        ),
                        icon: true,
                    }
                }
                firstItem={null}
                lastItem={null}
                activePage={Number(activePage)}
                onPageChange={onPageChange}
                totalPages={Number(totalPages)}
            />
        );
    }
}

export default CustomPagination;