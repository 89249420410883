import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";

import {IncomingCallModal} from "../../containers/crm/incoming-call-modal";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";

import useWebSocket from "../../hooks/useWebSocket";

import {openLinkByUrl} from "../../utils/downloadBlob";
import {CURRENT_CLIENT_USER_ID, ls} from "../../utils/localstorage";

import {SUB_PAGE_CRM_TICKET_CARD} from "../../constants/crm/ticket";
import {
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CRM_TICKET_CARD,
} from "../../constants/links";

const EVENT_TYPE = {
    DISCONNECTED: "DISCONNECTED",//разъединение с оператором
    CONNECTED: "CONNECTED",//установлено соединение с оператором
    APPEARED: "APPEARED", // входящий вызов оператора, попытка соединения
    TRANSFER: "TRANSFER", // входящий вызов оператора, попытка соединения
};

function WebSocketComponent(props) {
    const {
        subpage,
        clientId,
        contractorId,
    } = props.match.params;

    const {
        data: {
            eventType = "",
            ticket = {},
        },
    } = useWebSocket({});
    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);
    const [_eventType, setEventType] = useState(eventType);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    useEffect(() => {
        if (eventType === EVENT_TYPE.CONNECTED && ticket.managerId === currentClientUserId) {
            const linkCard = getCardLink()
                .replace(":subpage", subpage)
                .replace(":ticketId", ticket.ticketId)
                .replace(":_subpage", SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK);
            linkCard !== props.location.pathname && openLinkByUrl(linkCard);
        }

        if (eventType === EVENT_TYPE.TRANSFER) {
            setIsOpenConfirm(true);
        }
        setEventType(eventType);
    }, [eventType]);

    function getCardLink() {
        if (clientId) {
            return LINK_CLIENT_CARD_CRM_TICKET_CARD.replace(":clientId", clientId);
        }
        if (contractorId) {
            return LINK_CONTRACTOR_CRM_TICKET_CARD.replace(":contractorId", contractorId);
        }

        return LINK_CRM_TICKET_CARD;
    }

    const onCloseModal = () => {
        setEventType("");
    };

    const onCloseConfirm = () => {
        setEventType("");
        setIsOpenConfirm(false);
    };

    return (
        <>
            {_eventType === EVENT_TYPE.APPEARED && ticket.baseModel?.managerId === currentClientUserId &&
            <IncomingCallModal
                onClose={onCloseModal}
                ticket={ticket}
            />}
            {isOpenConfirm && <NmConfirmV2
                size="sm"
                content="Звонок переведен на другого оператора"
                onConfirm={onCloseConfirm}
                confirmButton="Продолжить"
                onCancel={onCloseConfirm}
            />}
        </>
    );
}

export default withRouter(WebSocketComponent);