import {useDispatch} from "react-redux";

import {useKedoExportsBff} from "./useBff";

import {useModal} from "../../../../hooks/useModal";

import {toastSuccess} from "../../../../utils/toastHelper";

import {downloadDocument} from "../../../../ducks/documents";

export const useKedoExportsAction = (params) => {
    const {
        fetchList,
    } = params;

    const dispatch = useDispatch();

    const {
        cancelExportById,
    } = useKedoExportsBff();

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const cancelExport = () => {
        cancelExportById({
            clientId: confirmData.exportId,
            exportId: confirmData.exportId,
            onSuccess: () => {
                fetchList();

                toastSuccess("Создание выгрузки отменено");

                onCloseConfirm();
            },
        });
    };

    const download = (document) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `${window.location.origin}${document.exportFileLink}`,
        }));
    };

    return {
        onOpenConfirm,
        confirmData,
        isOpenConfirm,
        onCloseConfirm,
        download,
        cancelExport,
    };
};
