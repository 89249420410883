import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2Content from "../../../../../../components/ActualComponents/NmConfirmV2/Content";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import CheckboxList from "../../../../../../components/CheckboxList";
import TableSelectedLabel from "../../../../../../components/TableSelectedLabel";

import {formatAmount, formatNumber} from "../../../../../../utils/stringFormat";

export default function ActDuplicateConfirm(props) {
    const {
        close,
        dataDuplicate,
        submit,
    } = props;

    const {t} = useTranslation();
    const [selectedList, setSelectedList] = useState([]);
    const [countSelected, setCountSelected] = useState(0);
    const onSubmit = () => {
        const seqNums = Array.from(new Set(selectedList.filter(item => item.isSelected).map(item => item.registrySeqNum)));
        const seqNumsToDelete = Array.from(new Set(dataDuplicate.filter(item => !seqNums.includes(item.registrySeqNum)).map(item => item.registrySeqNum)));
        submit(seqNums, seqNumsToDelete);
    };

    const onSelectedRows = (selectedList) => {
        setSelectedList(selectedList);
        setCountSelected(selectedList.filter(value => value.isSelected).length);
    };

    function renderSeq(value, tooltipText){
        return (
            <div className="d-flex">
                <div>
                    {value}
                </div>
                <HelpTooltip
                    isTypeTooltipEqualTypeIcon
                    className="ms-2"
                    width={20}
                    height={20}
                    type="light"
                    position="bottom-right"
                >
                    {tooltipText}
                </HelpTooltip>
            </div>
        );
    }

    function getRows() {
        return dataDuplicate.map((row, index) => {
            const {
                paymentsNumberDuplicate,
                registrySeqNumsDuplicate,
                registrySeqNum,
                contractorFullName,
                amount,
            } = row;

            const {isSelected = false} = selectedList.find(item => item.index === index) || {};

            return {
                ...row,
                index,
                key: index,
                showCheckBox: true,
                isSelected,
                contentRow: (
                    <NmListCard
                        onlyLabels
                        checkbox
                        classNameMainContent="col-16 col-xxl-12"
                        labels={[
                            {
                                label: "Номер подтверждаемой существующей выплаты",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: renderSeq(
                                    registrySeqNum,
                                    "Выплаты не имеют идентификационного номера, поскольку реестр еще не передан в оплату. В столбце указаны порядковые номера выплат (строк) реестра",
                                ),
                            },
                            {
                                label: "Номер аналогичной существующей выплаты",
                                noWrap: false,
                                noWrapLabel: false,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                columnOnMobile: true,
                                text: renderSeq(
                                    paymentsNumberDuplicate.length ?
                                        paymentsNumberDuplicate.join(", ") : "−",
                                    "В данном столбце указаны идентификационные номера выплат, созданных не в рамках данного реестра"),
                            },
                            {
                                label: "Номер аналогичной выплаты в реестре",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: renderSeq(
                                    registrySeqNumsDuplicate.join(", "),
                                    "В данном столбце указаны порядковые номера аналогичных выплат (строк), содержащихся в рамках данного реестра"),
                            },
                            {
                                label: "Исполнитель",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: contractorFullName,
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма ₽",
                                value: formatAmount(formatNumber(amount, 2)),
                                className: "col-16 col-md-6 col-xxl-4 mt-md-4 mt-xxl-0",
                                boldValue: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    return (
        <NmModal
            size="lg"
            onClose={close}
            footer={
                <ApplyButtons
                    mobile="column"
                    onClose={close}
                    submit={onSubmit}
                    isHiddenCancelOnMobile
                    cancelBtnContent={t("button.cancel")}
                    submitBtnContent="Подтвердить"
                />
            }
        >
            <div className="registry-duplicate-payments-list__container">
                <NmConfirmV2Content
                    warning
                    content="По некоторым выплатам найдены аналогичные выплаты, созданные исполнителю от лица Вашей компании. Выберите те, которые хотите подтвердить. Невыбранные выплаты будут удалены из реестра."
                />
                <div className="mt-3 mt-md-5">
                    <CheckboxList
                        classNameContent="registry-duplicate-payments-list__content"
                        header={<TableSelectedLabel
                            count={countSelected}
                        />}
                        onSelectedRows={onSelectedRows}
                        rows={getRows()}
                    />
                </div>
            </div>
        </NmModal>
    );

}