export const BFF_MAP_GET_CLUSTERS_REQUEST = "BFF_MAP_GET_CLUSTERS_REQUEST";
export const BFF_MAP_GET_CLUSTERS_SUCCESS = "BFF_MAP_GET_CLUSTERS_SUCCESS";
export const BFF_MAP_GET_CLUSTERS_ERROR = "BFF_MAP_GET_CLUSTERS_ERROR";

export const BFF_MAP_GET_CONTRACTORS_REQUEST = "BFF_MAP_GET_CONTRACTORS_REQUEST";
export const BFF_MAP_GET_CONTRACTORS_SUCCESS = "BFF_MAP_GET_CONTRACTORS_SUCCESS";
export const BFF_MAP_GET_CONTRACTORS_ERROR = "BFF_MAP_GET_CONTRACTORS_ERROR";

export const BFF_MAP_GET_ORDERS_REQUEST = "BFF_MAP_GET_ORDERS_REQUEST";
export const BFF_MAP_GET_ORDERS_SUCCESS = "BFF_MAP_GET_ORDERS_SUCCESS";
export const BFF_MAP_GET_ORDERS_ERROR = "BFF_MAP_GET_ORDERS_ERROR";

export const BFF_MAP_GET_ADVERTS_REQUEST = "BFF_MAP_GET_ADVERTS_REQUEST";
export const BFF_MAP_GET_ADVERTS_SUCCESS = "BFF_MAP_GET_ADVERTS_SUCCESS";
export const BFF_MAP_GET_ADVERTS_ERROR = "BFF_MAP_GET_ADVERTS_ERROR";

export const BFF_MAP_GET_TASKS_REQUEST = "BFF_MAP_GET_TASKS_REQUEST";
export const BFF_MAP_GET_TASKS_SUCCESS = "BFF_MAP_GET_TASKS_SUCCESS";
export const BFF_MAP_GET_TASKS_ERROR = "BFF_MAP_GET_TASKS_ERROR";

export const BFF_MAP_GET_LIST_PAGE_COUNTS_REQUEST = "BFF_MAP_GET_LIST_PAGE_COUNTS_REQUEST";
export const BFF_MAP_GET_LIST_PAGE_COUNTS_SUCCESS = "BFF_MAP_GET_LIST_PAGE_COUNTS_SUCCESS";
export const BFF_MAP_GET_LIST_PAGE_COUNTS_ERROR = "BFF_MAP_GET_LIST_PAGE_COUNTS_ERROR";

export const BFF_MAP_EXPORT_REQUEST = "BFF_MAP_EXPORT_REQUEST";
export const BFF_MAP_EXPORT_SUCCESS = "BFF_MAP_EXPORT_SUCCESS";
export const BFF_MAP_EXPORT_ERROR = "BFF_MAP_EXPORT_ERROR";

export const BFF_MAP_SEND_INVITE_TASK_REQUEST = "BFF_MAP_SEND_INVITE_TASK_REQUEST";
export const BFF_MAP_SEND_INVITE_TASK_SUCCESS = "BFF_MAP_SEND_INVITE_TASK_SUCCESS";
export const BFF_MAP_SEND_INVITE_TASK_ERROR = "BFF_MAP_SEND_INVITE_TASK_ERROR";

export const BFF_MAP_UPDATE_STORE = "BFF_MAP_UPDATE_STORE";