export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_UPDATE_STORE = "BFF_RECRUITMENT_VACANCY_CANDIDATES_UPDATE_STORE";