import {useEffect, useState} from "react";

import {checkVhiPolicy} from "../../controlDateReadOnly";

import {addYears} from "../../../../../utils/dateFormat";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

const errorMessageTemplate = "Срок действия Вашего полиса или договора ДМС не покрывает весь период работ. Вам будет необходимо продлить срок действия договора (полиса) ДМС по окончании действия и подгрузить новую форму в личном кабинете";
const useEditFormWarning = (values, card) => {

    const [warning, setWarning] = useState(false);

    useEffect(() => {
        const patentDate = card[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE];
        const expirationDate = values[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE];
        const checkDate = addYears(patentDate, 1);
        let errorMessage;

        errorMessage = checkDate && checkDate < new Date(expirationDate) ? errorMessageTemplate :
            _checkVhiPolicy();

        setWarning(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]: errorMessage,
        }));
    }, [values, card]);

    function _checkVhiPolicy() {
        const message = checkVhiPolicy({values, card});

        return message ? message : undefined;
    }

    return {
        warning,
    };
};

export default useEditFormWarning;