export const BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_ACTIVE_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_CONNECT_URL_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST = "BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_REQUEST";
export const BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_SUCCESS = "BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_SUCCESS";
export const BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR = "BFF_RECRUITMENT_JOB_BOARDS_AUTHORIZE_ERROR";

export const BFF_RECRUITMENT_JOB_BOARDS_UPDATE_STORE = "BFF_RECRUITMENT_JOB_BOARDS_UPDATE_STORE";