import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import bem from "../../../../../utils/bem";
import validate from "../../../../../utils/validate";

import {COLOR} from "../../../../../constants/color";
import {stopWordRule} from "../../../../../constants/validationRules";

import "./style.sass";

const StopWordEdit = (props) => {
    const {
        onClose,
        onSubmit,
        stopWordData,
    } = props;

    const {t} = useTranslation();
    const [block ] = bem("stop-word-edit-modal");
    const [error, setError] = useState({});
    const [values, setValues] = useState({
        value: stopWordData.value || "",
        stopWordForms: stopWordData.stopWordForms || "",
    });

    const handleSubmit = () => {
        isValidForm() && onSubmit && onSubmit({
            ...stopWordData,
            ...values,
        });
    };

    const handleChangeForm = (e, {name, value}) => {
        setValues(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const isValidForm = () => {
        const {value} = values;
        const error = validate({value}, stopWordRule,"");

        setError(error);

        return Object.keys(error).length === 0;
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            footer={
                <ApplyButtons
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent={t("stopWord.edit-cancel")}
                    submitBtnContent={stopWordData?.id ? "Сохранить" : t("stopWord.edit-confirm")}
                />
            }
            className={block()}
            margin="auto"
            header={
                <NmTitle size="lg">
                    {stopWordData?.id ? t("stopWord.edit-title-edit") : t("stopWord.edit-title-add")}
                </NmTitle>
            }
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    label={t("stopWord.edit-value-label")}
                    placeholder={t("stopWord.edit-value-placeholder")}
                    error={error.value}
                    name="value"
                    fieldClassName="keyword-edit__input"
                    className="nmx-i-fluid"
                    value={values.value}
                    onChange={handleChangeForm}
                    maxLength={100}
                    required
                />
                <div>
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_45}
                    >
                        {t("stopWord.edit-desk-1")}
                    </Text>
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_45}
                    >
                        {t("stopWord.edit-desk-2")}
                    </Text>
                </div>
                <NmTextareaV2
                    removeLineBreak
                    label={t("stopWord.edit-stopWordForms-label")}
                    placeholder={t("stopWord.edit-stopWordForms-label")}
                    maxLength={2000}
                    minRows={6}
                    maxRows={6}
                    value={values.stopWordForms}
                    name="stopWordForms"
                    onChange={handleChangeForm}
                />
            </NmForm>
        </NmModal>
    );
};

export default StopWordEdit;