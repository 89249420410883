import React from "react";
import {useSelector} from "react-redux";

import DepositReturn from "../../../return";

import {
    civilProgressActionSelector,
} from "../../../../../ducks/civil";
import {
    depositProgressActionSelector,
} from "../../../../../ducks/deposit";


const ClientListDepositReturnEdit = (props) => {
    const {
        close,
        dependentData,
        onEditDeposit,
    } = props;

    const progressDepositAction = useSelector(depositProgressActionSelector);
    const progressCivilAction= useSelector(civilProgressActionSelector);

    return <DepositReturn
        close={close}
        dependentData={dependentData}
        onEditDeposit={onEditDeposit}
        isLoading={progressDepositAction || progressCivilAction}
        isEdit
    />;
};

export default ClientListDepositReturnEdit;