import React, {FC, SVGProps} from "react";

import {ReactComponent as Icon} from "../../../images/chevron-down.svg";

interface ChevronIcon extends SVGProps<SVGSVGElement> {
    rotate?: number, // угол
    width?: number,
    height?: number,
}

export const ChevronIcon: FC<ChevronIcon> = (props) => {
    const {
        rotate = 0,
        ...otherProps
    } = props;

    return (
        <Icon
            {...otherProps}
            style={{
                transform: `rotate(${rotate}deg)`,
            }}
        />
    );
};