import React, {useState} from "react";
import {EditorConfig} from "@ckeditor/ckeditor5-core/src/editor/editorconfig";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {
    Bold,
    ClassicEditor,
    Editor,
    Essentials,
    EventInfo,
    Italic,
    List,
    Mention,
    Paragraph,
    Undo,
    WordCount,
} from "ckeditor5";

import ErrorTooltip from "../ErrorTooltip";
import NmLabel, {INmLabel} from "../NmLabel";

import bem from "../../../utils/bem";

import "./style.sass";

interface CKETextEditorProps extends EditorConfig {
    value?: string,
    error?: string,
    label?: string,
    required?: boolean,
    labelProps?: INmLabel,
    name?: string,
    onReady?: (data: Editor) => void,
    onChange: (event: null, {name, value}: {name: string, value: string}) => void,
    maxLength?: number,
    className?: string,
}

const CKETextEditor: React.FC<CKETextEditorProps> = (props) => {
    const {
        value,
        label,
        labelProps,
        error,
        name = "",
        onReady,
        toolbar = {
            items: [
                "bold",
                "italic",
                "numberedList",
                "bulletedList",
            ],
        },
        plugins = [
            Bold,
            Essentials,
            Italic,
            Mention,
            Paragraph,
            Undo,
            List,
            WordCount,
        ],
        placeholder,
        maxLength,
        className,
        required,
    } = props;

    const [count, setCount] = useState(value?.length || 0);

    const [focused, setFocused] = useState(false);

    const [block, element] = bem("cke-text-editor", className);

    const isLabel = label || labelProps;

    const onChange = (event: EventInfo, editor: Editor) => {
        props.onChange(null, {name, value: editor.getData()});
    };

    return (
        <div
            className={block({
                active: Boolean(value),
                error: Boolean(error),
                focused,
            })}
        >
            {
                isLabel &&
                <NmLabel
                    label={label}
                    required={required}
                    {...labelProps}
                />
            }
            <CKEditor
                onReady={onReady}
                onFocus={() => {
                    setFocused(true);
                }}
                onBlur={() => {
                    setFocused(false);
                }}
                editor={ClassicEditor}
                onChange={onChange}
                config={{
                    toolbar,
                    plugins,
                    placeholder,
                    initialData: value,
                    wordCount: {
                        onUpdate: (stats) => {
                            setCount(stats.characters);
                        },
                    },
                }}
            />
            {
                maxLength &&
                <div className={element("counter")}>
                    {`${count}/${maxLength}`}
                </div>
            }
            {
                error &&
                <ErrorTooltip error={error} />
            }
        </div>
    );
};

export default CKETextEditor;