import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContractorBlackListWarningModal from "../../../../../components/ContractorBlackListWarningModal";

import {updateStoreCrowdTaskCard} from "../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {
    bffCrowdTasksActionPageDataSelector,
    bffCrowdTasksErrorSelector,
} from "../../../../../ducks/bff/crowd/taskCard/selectors";

export const TaskCardErrors = () => {
    const dispatch = useDispatch();

    const error = useSelector(bffCrowdTasksErrorSelector);
    const actionPageData = useSelector(bffCrowdTasksActionPageDataSelector);

    const onClose = () => {
        dispatch(updateStoreCrowdTaskCard({
            error: null,
            actionPageData: null,
        }));
    };

    if (isEmpty(error?.contractorsInBlacklist)) {
        return null;
    }

    return (
        <ContractorBlackListWarningModal
            onClose={onClose}
            isMassOperation={actionPageData?.isMassOperation}
            contractorsInBlacklist={error.contractorsInBlacklist}
        />
    );
};