import {convertStringToDate} from "../../../../utils/stringHelper";

export const getContractorVerificationPersonalData = (data) => {
    return {
        ...data,
        idDocIssuedDate: data.idDocIssuedDate ? convertStringToDate(data.idDocIssuedDate) : data.idDocIssuedDate,
        birthDate: data.birthDate ? convertStringToDate(data.birthDate) : data.birthDate,
        idDocValidToDate: data.idDocValidToDate ? convertStringToDate(data.idDocValidToDate) : data.idDocValidToDate,
        migrationCardIssuedDate: data.migrationCardIssuedDate ? convertStringToDate(data.migrationCardIssuedDate) : data.migrationCardIssuedDate,
        migrationCardValidToDate: data.migrationCardValidToDate ? convertStringToDate(data.migrationCardValidToDate) : data.migrationCardValidToDate,
        registrationDate: data.registrationDate ? convertStringToDate(data.registrationDate) : data.registrationDate,
        arrivalNoticeStayEndDate: data.arrivalNoticeStayEndDate ? convertStringToDate(data.arrivalNoticeStayEndDate) : data.arrivalNoticeStayEndDate,
        residencePermitDecisionDate: data.residencePermitDecisionDate ? convertStringToDate(data.residencePermitDecisionDate) : data.residencePermitDecisionDate,
        residencePermitIssuedDate: data.residencePermitIssuedDate ? convertStringToDate(data.residencePermitIssuedDate) : data.residencePermitIssuedDate,
        residencePermitValidToDate: data.residencePermitValidToDate ? convertStringToDate(data.residencePermitValidToDate) : data.residencePermitValidToDate,
        employmentPatentIssuedDate: data.employmentPatentIssuedDate ? convertStringToDate(data.employmentPatentIssuedDate) : data.employmentPatentIssuedDate,
        vhiPolicyValidFromDate: data.vhiPolicyValidFromDate ? convertStringToDate(data.vhiPolicyValidFromDate) : data.vhiPolicyValidFromDate,
        vhiPolicyValidToDate: data.vhiPolicyValidToDate ? convertStringToDate(data.vhiPolicyValidToDate) : data.vhiPolicyValidToDate,
        mainDocumentStampValidToDate: data.mainDocumentStampValidToDate ? convertStringToDate(data.mainDocumentStampValidToDate) : data.mainDocumentStampValidToDate,
    };
};
export const getContractorVerificationFormInitialValues = (card) => {
    return {
        ...card,
        idDocIssuedBy: card.idDocIssuedBy || "",
        idDocNumber: card.idDocNumber || "",
        idDocIssuedDate: convertStringToDate(card.idDocIssuedDate) || null,
        idDocValidToDate: convertStringToDate(card.idDocValidToDate) || null,
        idDocIssuedByDepartmentCode: card.idDocIssuedByDepartmentCode || "",
        lastName: card.lastName || "",
        firstName: card.firstName || "",
        patronymic: card.patronymic || "",
        gender: card.gender || "",
        birthDate: convertStringToDate(card.birthDate) || null,
        birthPlace: card.birthPlace || "",
        address: card.address || "",
        naimixJoiningDate: convertStringToDate(card.naimixJoiningDate) || null,
        migrationCardIssuedDate: convertStringToDate(card.migrationCardIssuedDate) || null,
        migrationCardValidToDate: convertStringToDate(card.migrationCardValidToDate) || null,
        registrationDate: convertStringToDate(card.registrationDate) || null,
        arrivalNoticeStayEndDate: convertStringToDate(card.arrivalNoticeStayEndDate) || null,
        residencePermitIssuedDate: convertStringToDate(card.residencePermitIssuedDate) || null,
        residencePermitValidToDate: convertStringToDate(card.residencePermitValidToDate) || null,
        residencePermitDecisionDate: convertStringToDate(card.residencePermitDecisionDate) || null,
        vhiPolicyValidToDate: convertStringToDate(card.vhiPolicyValidToDate) || null,
        vhiPolicyValidFromDate: convertStringToDate(card.vhiPolicyValidFromDate) || null,
        employmentPatentIssuedDate: convertStringToDate(card.employmentPatentIssuedDate) || null,
        snilsNum: card.snilsNum || "",
        employmentHistoryNum: card.employmentHistoryNum || "",
        mainDocumentStampIssuedDate: convertStringToDate(card.mainDocumentStampIssuedDate) || null,
        mainDocumentStampValidToDate: convertStringToDate(card.mainDocumentStampValidToDate) || null,
        visaNum: card.visaNum || "",
        visaId: card.visaId || "",
        visaIssuedDate: convertStringToDate(card.visaIssuedDate) || null,
        visaActiveFromDate: convertStringToDate(card.visaActiveFromDate) || null,
        visaActiveUntilDate: convertStringToDate(card.visaActiveUntilDate) || null,
        refugeeCertificateBirthPlace: card.refugeeCertificateBirthPlace || "",
        refugeeCertificateNum: card.refugeeCertificateNum || "",
        refugeeCertificatePersonalFileNum: card.refugeeCertificatePersonalFileNum || "",
        refugeeCertificateIssuedBy: card.refugeeCertificateIssuedBy || "",
        refugeeCertificateIssuedDate: convertStringToDate(card.refugeeCertificateIssuedDate) || null,
        refugeeCertificateValidToDate: convertStringToDate(card.refugeeCertificateValidToDate) || null,
        temporaryResidencePermitNum: card.temporaryResidencePermitNum || "",
        temporaryResidencePermitDecisionDate: convertStringToDate(card.temporaryResidencePermitDecisionDate) || null,
        temporaryResidencePermitValidToDate: convertStringToDate(card.temporaryResidencePermitValidToDate) || null,
        temporaryResidencePermitDecisionIssuedBy: card.temporaryResidencePermitDecisionIssuedBy || "",
        statelessPersonTemporaryIdIssuedDate: convertStringToDate(card.statelessPersonTemporaryIdIssuedDate) || null,
        statelessPersonTemporaryIdValidToDate: convertStringToDate(card.statelessPersonTemporaryIdValidToDate) || null,
        statelessPersonTemporaryIdNum: card.statelessPersonTemporaryIdNum || "",
        statelessPersonTemporaryIdBirthPlace: card.statelessPersonTemporaryIdBirthPlace || "",
        statelessPersonTemporaryIdIssueReason: card.statelessPersonTemporaryIdIssueReason || "",
        statelessPersonTemporaryIdIssuedBy: card.statelessPersonTemporaryIdIssuedBy || "",
        statelessPersonResidencePermitIssuedDate: convertStringToDate(card.statelessPersonResidencePermitIssuedDate) || null,
        statelessPersonResidencePermitValidToDate: convertStringToDate(card.statelessPersonResidencePermitValidToDate) || null,
        statelessPersonResidencePermitIssuedBy: card.statelessPersonResidencePermitIssuedBy || "",
        statelessPersonResidencePermitNumber: card.statelessPersonResidencePermitNumber || "",
        temporaryRefugeeCertificateIssuedDate: convertStringToDate(card.temporaryRefugeeCertificateIssuedDate) || null,
        temporaryRefugeeCertificateValidToDate: convertStringToDate(card.temporaryRefugeeCertificateValidToDate) || null,
        temporaryRefugeeCertificateNum: card.temporaryRefugeeCertificateNum || "",
        temporaryRefugeeCertificatePersonalFileNum: card.temporaryRefugeeCertificatePersonalFileNum || "",
        temporaryRefugeeCertificateBirthPlace: card.temporaryRefugeeCertificateBirthPlace || "",
        temporaryRefugeeCertificateIssuedBy: card.temporaryRefugeeCertificateIssuedBy || "",
        employmentPatentRegistrationNumber: card.employmentPatentRegistrationNumber || "",
        ein: card.ein || "",
    };
};