import {useEffect} from "react";

export const useTemplatesClientUsageFetch = (params) => {
    const {
        pageNum,
        pageSize,
        filterData,
        orderType,
    } = params;

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        orderType,
    ]);

    const fetchList = () => {
        params.fetchList({
            pageNum,
            pageSize,
            orderType,
            clientNameFilter: filterData.clientNameFilter ? filterData.clientNameFilter : undefined,
        });
    };

    return {
        fetchList,
    };
};