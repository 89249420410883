import React, {useState} from "react";

import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmTitle from "../../../../components/NmTitle";

import "./style.sass";

const PatentsCardIFNS = ({item}) => {
    const {
        recipientName,
        recipientInn,
        recipientKpp,
        recipientKbk,
        recipientOktmo,
        recipientRs,
        recipientBik,
    } = item;
    const [open, setOpen] = useState(false);

    return (
        <>
            <div
                onClick={() => {setOpen(true);}}
                className="patents-card-ifns__more"
            >
                Подробнее
            </div>
            {
                open &&
                <NmModal
                    size="sm"
                    header={
                        <NmTitle size="lg">
                            Реквизиты получателя (ИФНС)
                        </NmTitle>
                    }
                    onClose={() => {setOpen(false);}}
                >
                    <div className="patents-card-ifns__content">
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="Наименование"
                            text={recipientName}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="ИНН"
                            text={recipientInn}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="КПП"
                            text={recipientKpp}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="КБК"
                            text={recipientKbk}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="ОКТМО"
                            text={recipientOktmo}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="Р/с"
                            text={recipientRs}
                        />
                        <NmLabelText
                            type="page"
                            className="mb-2"
                            label="БИК"
                            text={recipientBik}
                        />
                    </div>
                </NmModal>
            }
        </>
    );
};

export default PatentsCardIFNS;