import {useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {convertStringToDate} from "../../../../../utils/stringHelper";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

import {updateContractor} from "../../../../../ducks/contractor";


const useDataForm = (card) => {

    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);

    const initialValues = {
        [CONTRACTOR_FIELD.CITIZENSHIP]:  card[CONTRACTOR_FIELD.CITIZENSHIP],
        [CONTRACTOR_FIELD.VHI_POLICY_PRESENT]:  card[CONTRACTOR_FIELD.VHI_POLICY_PRESENT] || false,
        [CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]:  convertStringToDate(card[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]) || null,
        [CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]) || null,
    };

    const toggleCard = () => {
        validateForm().then(() => {
            if (!isValid && isEdit) {
                return;
            }

            if (isEdit && isValid) {
                setSubmitting(true);
                handleSubmit();
            }

            setIsEdit(prevState => !prevState);
        });
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setValues(initialValues);
    };

    const {
        handleSubmit,
        values,
        setFieldTouched,
        setFieldValue,
        setValues,
        errors,
        touched,
        isValid,
        setSubmitting,
        validateForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting})=> {
            const requestData = {
                ...card,
                ...values,
            };

            dispatch(updateContractor(requestData));

            setSubmitting(false);
        },
        validationSchema: validationSchema(),
        validateOnBlur: false,
        initialTouched: {
            [CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]: true,
            [CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]: true,
        },
    });


    return {
        values,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        isEdit,
        cancelEdit,
        toggleCard,
    };
};

export default useDataForm;