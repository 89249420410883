export const BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR = "BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR";

export const BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST = "BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST";
export const BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_SUCCESS = "BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_SUCCESS";
export const BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR = "BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR";

export const UPDATE_CROWD_TASKS_ANALYTICS_STORE = "UPDATE_CROWD_TASKS_ANALYTICS_STORE";