import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import "leaflet-draw/dist/leaflet.draw.css"
import App from './containers/app';
import {Provider} from "react-redux";
import configureStore from "./store/configureStore";
import ru from 'date-fns/locale/ru';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {fixGoogleTranslate} from "./utils/fixGoogleTranslate";
import './i18n';
import "ckeditor5/ckeditor5.css";

registerLocale('ru', ru);
setDefaultLocale('ru');

export const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);


//полифилы для методов Object
if (!Object.values) Object.values = o => Object.keys(o).map(k => o[k]);

const objectToEntriesPolyfill = function (object) {
    return Object
        .keys(object)
        .map(
            function (key) {
                return [key, object[key]];
            }
        );
};

Object.entries = Object.entries || objectToEntriesPolyfill;

fixGoogleTranslate();