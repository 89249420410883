import React from "react";
import {useMedia} from "react-media";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import copy from "copy-to-clipboard";

import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import {ReactComponent as CopyIcon} from "../../../images/copy.svg";
import {ReactComponent as PhoneIcon} from "../../../images/phone_open-naimix_24.svg";

import bem from "../../../utils/bem";
import {isPromoPoiskPage} from "../../../utils/url";

import {APP_NAIMIX_INFO_LINK, LINK_HOME, LOCAL_PROD_NAIMIX_INFO_LINK, NAIMIX_INFO_LINK} from "../../../constants/links";

import "./style.sass";

const copyPhone = () => {
    copy("8 800 500 56 54");
    toast("Скопировано 8 800 500 56 54", {type: "info"});
};

const LoginHeader = ({}) => {
    const [block, element] = bem("login-header");
    const isMobile = useMedia({query: {maxWidth: 767}});
    const isProd = [LOCAL_PROD_NAIMIX_INFO_LINK, NAIMIX_INFO_LINK, APP_NAIMIX_INFO_LINK].includes(window.location.origin);
    const isPromoPoisk = isPromoPoiskPage();

    const getPhone = () => {
        return (
            <div className={element("phone")}>
                <PhoneIcon
                    title="Скопировать"
                    onClick={() => {
                        if (isMobile) {
                            return;
                        }

                        copyPhone();
                    }}
                    className={element("phone-icon")}
                />
                <a
                    title="Позвонить"
                    className={element("phone-number")}
                    href="tel:88005005654"
                >
                    8 800 500 56 54
                </a>
            </div>
        );
    };

    const getLogo = () => {
        if (isPromoPoisk) {
            return (
                <Link
                    to={LINK_HOME}
                    className={element("link")}
                >
                    <div className={element("logo", {promoPoisk: true})} />
                </Link>
            );
        }

        return isProd ?
            <a
                className={element("link")}
                href="https://naimix.info/"
            >
                <div className={element("logo")} />
            </a> :
            <Link
                to={LINK_HOME}
                className={element("link")}
            >
                <div className={element("logo")} />
            </Link>;
    };

    const getHeaderPhoneBlock = () => {
        if (isPromoPoisk) {
            return null;
        }

        return isMobile ?
            <NmAdvancedTooltip
                hover
                type="light"
                trigger={getPhone()}
                classNameTooltip="login-header__tooltip"
                children={
                    <div className="d-flex">
                        <div className="login-header__tooltip-phone">
8 800 500 56 54
                        </div>
                        <CopyIcon
                            onClick={() => {
                                copyPhone();
                            }}
                            className="login-header__tooltip-phone-icon"
                        />
                    </div>
                }
            /> :
            getPhone();
    };

    return (
        <div
            className={block({
                promoPoisk: isPromoPoisk,
            })}
        >
            <div className={element("container")}>
                {getLogo()}
                {getHeaderPhoneBlock()}
            </div>
        </div>
    );
};

export default LoginHeader;