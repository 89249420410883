import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_CONTRACTORS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TEAM_LIST,
} from "../../../constants/links";

export function ProjectsObjectsTabs(props) {
    const {
        projectId,
        clientId,
        objectId,
        pathname,
        orderCount = 0,
        taskCount = 0,
        clientUserCount = 0,
        onboardingContractorsCount = 0,
        onboardingContractorsTotalCount = 0,
    } = props;

    const linkToTeamList = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TEAM_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId)
        .replace(":objectId", objectId);
    const linkToOrderList = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId)
        .replace(":objectId", objectId);
    const linkToContractorsList = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_CONTRACTORS_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId)
        .replace(":objectId", objectId);
    const linkToTaskList = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId)
        .replace(":objectId", objectId);

    const isActiveContractorsTab = pathname.includes("contractors");

    return [
        {
            name: "Заказы",
            count: orderCount,
            link: linkToOrderList,
            active: pathname.includes("orders"),
        },
        {
            name: "Задания",
            count: taskCount,
            link: linkToTaskList,
            active: pathname.includes("tasks"),
        },
        {
            name: "Команда",
            count: clientUserCount,
            link: linkToTeamList,
            active: pathname.includes("teams"),
        },
        {
            name: "Исполнители",
            count: isActiveContractorsTab ? onboardingContractorsTotalCount : onboardingContractorsCount,
            link: linkToContractorsList,
            active: isActiveContractorsTab,
        },
    ];
}