import React from "react";

import {getClassNames} from "../../../utils/classNames";

import "./style.sass";

export const InputWithButtonsRow = (props) => {
    const {
        input,
        buttons,
        className,
        children,
    } = props;

    return (
        <div
            className={getClassNames([
                "input-with-buttons-row d-flex flex-column flex-md-row align-items-md-start",
                className,
            ])}
        >
            <div className="flex-grow-1">
                {input}
            </div>
            <div className="input-with-buttons-row__buttons d-flex flex-column flex-md-row">
                {buttons}
                {children}
            </div>
        </div>
    );
};