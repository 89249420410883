import {useState} from "react";

export const useOrderListTemplatePublication = () => {
    const [isOpen, setOpen] = useState(false);

    const onOpenTemplatePublication = () => {
        setOpen(true);
    };

    const onCloseTemplatePublication = () => {
        setOpen(false);
    };

    return {
        isOpen,
        onOpenTemplatePublication,
        onCloseTemplatePublication,
    };
};