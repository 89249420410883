import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import { isEmpty } from "lodash";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import Tabs from "../../../components/ActualComponents/Tabs";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import ContractorDocumentsItem from "./components/item";
import ContractorDocumentsRegistry from "./components/registry";

import {usePagination} from "../../../hooks/usePagination";
import {useAction} from "./hooks/useAction";
import {useData} from "./hooks/useData";
import {useFetchDocuments} from "./hooks/useFetchDocuments";
import {useTabs} from "./hooks/useTabs";

import {TAB} from "./constants";

import {
    getIsRefreshInProgressSelector,
} from "../../../ducks/documents";

import "./style.sass";

const ContractorDocuments = (props) => {
    const {
        isEditable,
        match: {params: {contractorId}},
    } = props;

    const {t} = useTranslation();

    const isRefreshInProgress = useSelector(getIsRefreshInProgressSelector);
    const location = useSelector(state => state.router.location);

    const {
        tabs,
        tab,
    } = useTabs();
    const {
        onFetchList,
        openDocumentByDownloadLink,
    } = useFetchDocuments({contractorId, tab});
    const {
        totalPages,
        totalCount,
        list,
    } = useData({tab});
    const {
        onRefreshDocuments,
    } = useAction({contractorId});
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    useEffect(() => {
        setPagination({pageSize, pageNum: 1});
    }, [tab]);

    useEffect(() => {
        onFetchList({pageNum, pageSize});
    }, [pageNum, pageSize, tab]);

    const getContent = () => {
        switch (tab) {
        /*Агентский договор с Наймикс*/
        case TAB.AGENCY_CONTRACT:
            /*Согласие на обработку ПД*/
        case TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA:
            /*Договоры с заказчиками*/
        case TAB.FRAME_CONTRACT:
            /*Заявки на выполнение работ (оказание услуг)*/
        case TAB.ORDER_APPLICATION:
            /*Акты выполненных работ*/
        case TAB.ACT_OF_ACCEPTANCE_OF_WORK:
            /*Страховые полисы*/
        case TAB.INSURANCE_POLICY:
            /*Квитанции РНКО*/
        case TAB.RECEIPT_RNKO:
            /*Лист идентификации личности*/
        case TAB.IDENTIFICATION_SHEET:
            /*Договоры на подписании*/
        case TAB.PENDING_CONTRACTS:
            return (
                <ContractorDocumentsItem
                    tab={tab}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    list={list}
                    onFetchList={onFetchList}
                    openDocument={openDocumentByDownloadLink}
                />
            );
            /*Реестры на подписание рамочных договоров с исполнителем*/
        case TAB.DOCUMENTS_REGISTRY:
            return (
                <ContractorDocumentsRegistry
                    pageNum={pageNum}
                    pageSize={pageSize}
                    onFetchList={onFetchList}
                    location={location}
                    list={list}
                />
            );
        }
    };

    const getTabs = () => {
        return (
            <Tabs
                secondary
                panes={tabs}
            />
        );
    };

    if (isEmpty(tabs)) {
        return (
            <NmEmptyPageV2
                title={t("contractor-documents.no-documents")}
            />
        );
    }

    return (
        <NmPage
            className="contractor-documents-list"
            noPadding
            header={
                isEditable &&
                <NmButton
                    color="light-green"
                    loading={isRefreshInProgress}
                    onClick={onRefreshDocuments}
                >
                    {t("contractor-documents.refresh-documents-button")}
                </NmButton>
            }
            subHeader={getTabs()}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            cancelActionsStyles
        >
            {getContent()}
        </NmPage>
    );
};


export default ContractorDocuments;

