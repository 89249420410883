import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getAvailableRecruitersForVacancy,
    updateRecruitmentVacancyStore,
} from "../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentVacancyAvailableRecruitersOptionsSelector,
} from "../../../ducks/bff/recruitment/vacancy/selectors";
import HelpTooltip from "../../ActualComponents/HelpTooltip";
import NmDropdownV2 from "../../ActualComponents/NmDropdownV2";

export const AvailableRecruitersDropdown = (props) => {
    const {
        clientId,
        setFieldValue,
        ...dropdownProps
    } = props;

    const availableRecruitersOptions = useSelector(bffRecruitmentVacancyAvailableRecruitersOptionsSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList({
            onSuccess: ({results}) => {
                if (results.length === 1) {
                    const recruiterId = results[0].clientUserId;

                    setFieldValue("recruiterId", recruiterId);
                }
            },
        });

        return () => {
            dispatch(updateRecruitmentVacancyStore({
                availableRecruitersList: [],
            }));
        };
    }, []);

    const fetchList = (params) => {
        dispatch(getAvailableRecruitersForVacancy({
            clientId,
            pageNum: 1,
            pageSize: 1000,
            ...params,
        }));
    };

    return (
        <NmDropdownV2
            search={true}
            label="Рекрутер"
            placeholder="Выберите из списка"
            size="xl"
            options={availableRecruitersOptions}
            onChange={(event, {name, value}) => setFieldValue(name, value)}
            tooltip={
                <HelpTooltip
                    hover
                    info
                    width={20}
                    height={20}
                    text={"Для заполнения поля создайте сотрудника компании с ролью \"Рекрутер\" в разделе \"Компания → Сотрудники\""}
                />
            }
            {...dropdownProps}
        />
    );
};