import {handleCardRate} from "../../../../../utils/mathHelper";
import {formatAmountWithNullChecking} from "../../../../../utils/stringFormat";

import {CLIENT_PROPERTIES_FIELD} from "../../../../../constants/clientList";

export const transformLimitsToForm = (card) => {
    return {
        ...card,
        // Минимальная сумма выплаты без доп. комиссии компании, ₽
        [CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT]: formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT]),
        [CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT]: formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT]),
        // Дополнительная сумма комиссии компании, ₽
        [CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT]: formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT]),
        // Лимит 1 транзакции
        [CLIENT_PROPERTIES_FIELD.LIMIT]: card[CLIENT_PROPERTIES_FIELD.LIMIT] ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]),
        // Ограничение для лимита 1 транзакции, руб
        [CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]:
            card[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT] ?
                formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]) :
                formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.LIMIT]),
        // Ограничение для лимита в сутки на 1 исполнителя, руб
        [CLIENT_PROPERTIES_FIELD.DAY_LIMIT]:
            card[CLIENT_PROPERTIES_FIELD.DAY_LIMIT] ?
                formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.DAY_LIMIT]) :
                formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]),
        // Ограничение для лимита в сутки на 1 исполнителя, руб
        [CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]: card[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT] ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.DAY_LIMIT]),
        // Лимит в месяц на 1 исполнителя, руб
        [CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]: card[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT] ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]),
        // Ограничение для лимита в месяц на 1 исполнителя, руб
        [CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]: card[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT] ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]),
        [CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]: card[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT] || card[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT] === 0 ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]),
        [CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]: card[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT] || card[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT] === 0 ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]),
        [CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]: card[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT] || card[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT] === 0 ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]),
        [CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]: card[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT] || card[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT] === 0 ?
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]) :
            formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]),
        [CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION]:
            String(handleCardRate(card[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION])).replace(".", ","),
        [CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT]: formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT]),
        [CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT]: formatAmountWithNullChecking(card[CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT]),
    };
};