import {TFunction} from "i18next";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {TAX_STATUS_TRANSLATE} from "../constants";

export const getMessage = (taxStatus: keyof typeof TAX_STATUS_TRANSLATE, t: TFunction) => {
    if (isNullOrWhitespace(taxStatus)) {
        return t("contractor-list.contractor-fns-error");
    }

    return t(TAX_STATUS_TRANSLATE[taxStatus]) || t("contractor-list.unknownError");
};