import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    updateStoreCrowdTaskCard,
} from "../../../../../../../ducks/bff/crowd/taskCard/actionCreators";

const useCrowdTaskCardContractorListFetch = (props) => {
    const {
        clientId,
        taskId,
        pageNum,
        pageSize,
        fetchMethod,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdTaskCard({
                pageData: {},
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(fetchMethod({
            clientId,
            taskId,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};

export default useCrowdTaskCardContractorListFetch;