import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {useDataContractorSnilsInfoEditForm} from "./hooks/useData";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";
import {CONTRACTOR_FIELD} from "../../../../../../../constants/contractor";

import {contractorAdditionalDocumentsProgressUpdateDataSelector} from "../../../../../../../ducks/contractorAdditionalDocuments";
import {
    fileContractorScanProgressAddSelector,
    fileContractorScanProgressSelector,
} from "../../../../../../../ducks/fileStore";

const ContractorSnilsInfoEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
        data,
    } = props;

    const progress = useSelector(contractorAdditionalDocumentsProgressUpdateDataSelector);
    const progressScan = useSelector(fileContractorScanProgressAddSelector);
    const progressGetScan = useSelector(fileContractorScanProgressSelector);

    const {
        files,
        onDropScan,
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
    } = useDataContractorSnilsInfoEditForm({
        data,
        contractorId,
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    СНИЛС (доп. документ)
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress || progressScan || progressGetScan}
        >
            <NmForm addMargin>
                <NmInputV2
                    required
                    size="xl"
                    mask="999-999-999 99"
                    label="Номер"
                    onChange={handleChange}
                    value={values[CONTRACTOR_FIELD.SNILS_NUM]}
                    name={CONTRACTOR_FIELD.SNILS_NUM}
                    onBlur={() => setFieldTouched(CONTRACTOR_FIELD.SNILS_NUM, true)}
                    error={
                        touched?.[CONTRACTOR_FIELD.SNILS_NUM] &&
                        errors?.[CONTRACTOR_FIELD.SNILS_NUM]
                    }
                />
                <DropzoneV2
                    isTopImagePreview
                    label="Лицевая сторона СНИЛС"
                    isVisibleLabel
                    files={files[CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN]}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={(files) => onDropScan(files, CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN)}
                    uploadBtnText={isEmpty(files[CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN]) ? "Выбрать файл" : "Заменить"}
                    error={
                        touched?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN] &&
                        errors?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN]
                    }
                />
            </NmForm>
        </NmModal>
    );
};

export default ContractorSnilsInfoEditForm;