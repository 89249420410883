import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {clearFields, getContractHistoryFile, getHistoryList} from "../../../ducks/contractHistory";

export const useAction = ({pageNum, pageSize, clientId}) => {
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();

        return () => {
            dispatch(clearFields());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getHistoryList({
            clientId,
            orderColumn: "createDatetime",
            orderType: "DESC",
            pageNum,
            pageSize,
        }));
    };

    const onCloseAdd = () => {
        setOpen(false);
    };

    const onOpenAdd = () => {
        setOpen(true);
    };

    const getFile = (data) => {
        dispatch(getContractHistoryFile(data));
    };

    return {
        isOpenAdd: open,
        onOpenAdd,
        getFile,
        onCloseAdd,
    };
};