import React from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../components/ActualComponents/NmRangeInput";
import AdditionalDocumentFilter from "../../../../components/AdditionalDocumentFilter";
import AdditionalDocumentStatusFilter from "../../../../components/AdditionalDocumentStatusFilter";
import MigrationStatusFilter from "../../../../components/MigrationStatusFilter";
import NmWorkScheduleFilter from "../../../../components/NmWorkScheduleFilter";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {isNMUsers} from "../../../../utils/access";
import {getDadataAddress} from "../../../../utils/dadata";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {
    CONTRACTOR_FAMILY_STATUS_OPTIONS,
    CONTRACTOR_FIELD,
    CONTRACTOR_LAST_LOGIN_TYPE_DICT,
} from "../../../../constants/contractor";
import {CONTRACTORS_SORT_OPTIONS, GENDER_OPTIONS} from "../../../../constants/contractorInfo";
import {SimpleDropdownOptions} from "../../../../constants/contractorList";

import "./style.sass";

const NmBaseClientContractorListFilter = (props) => {
    const {
        onChange = () => {
        },
        clearFilter = () => {
        },
        sendFilter = () => {
        },
        t = () => {
        },
        changeSpecialities,
        specialityOptions,
        сitizenshipOptions,
        contractorStatusOptions,
        educationOptions,
        languagesOptions,
        driverLicenseOptions,
        ownCarFilter,
        filterData,
    } = props;

    const {
        addressFiasIdsFilter,
        remoteWorkFilter,
        schedulesFilter,
        fioFilter,
        phoneFilter,
        registrationStatusFilter,
        citizenshipFilter,
        ageToFilter,
        ageFromFilter,
        sortType,
        genderFilter,
        workWithClient,
        specialityIdsFilter,
        familyStatusFilter,
        educationLevelFilter,
        foreignLanguageFilter,
        driverLicenseFilter,
        lastLoginFilter,
    } = filterData;

    const onKeyPress = ({key}) => {
        if (key === "Enter") {
            sendFilter();
        }
    };

    return (
        <NmForm
            addMargin
            className="nm-contractor-list-filter__form"
            onKeyPress={onKeyPress}
        >
            <FilterButtonsV2
                className="nm-contractor-list-filter__buttons"
                onSearch={sendFilter}
                onClear={clearFilter}
            />
            <NmDadata
                size="lg"
                name={CONTRACTOR_FIELD.ADDRESS_FIAS_IDS_FILTER}
                label="Регион или город"
                onChange={onChange}
                multiple
                fromBound="region"
                toBound="settlement"
                formatter={getDadataAddress}
                initialAddressIds={addressFiasIdsFilter}
                isClearable
                onSubmit={sendFilter}
            />
            <NmCheckboxV2
                label="Работа удалённо"
                checked={remoteWorkFilter}
                name="remoteWorkFilter"
                onChange={onChange}
            />
            <NmCheckboxV2
                label={t("contractor-list.work-in-company")}
                checked={workWithClient}
                name="workWithClient"
                onChange={onChange}
            />
            <NmDropdownV2
                placeholder="Показать все"
                label={t("contractor-list.specialties")}
                name="specialityIdsFilter"
                onSubmit={sendFilter}
                options={specialityOptions}
                value={specialityIdsFilter}
                onChange={changeSpecialities}
                size="lg"
                multiple
                search
            />
            <NmInputV2
                onChange={onChange}
                name="fioFilter"
                value={fioFilter}
                placeholder="Введите имя"
                label={t("contractor-list.full-name-placeholder")}
                size="lg"
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmDropdownV2
                placeholder="Все"
                label="Статус регистрации"
                name="registrationStatusFilter"
                onChange={onChange}
                options={contractorStatusOptions}
                value={registrationStatusFilter}
                size="lg"
                multiple
            />
            <NmDropdownV2
                placeholder="Показать все"
                label={t("contractor-list.citizenship")}
                name="citizenshipFilter"
                onChange={onChange}
                onSubmit={sendFilter}
                options={сitizenshipOptions}
                value={citizenshipFilter}
                size="lg"
                multiple
                search
            />
            <MigrationStatusFilter
                onChange={onChange}
                onSubmit={sendFilter}
                value={filterData.migrationStatusesFilter}
            />
            <AdditionalDocumentFilter
                onChange={onChange}
                onSubmit={sendFilter}
                value={filterData.additionalDocumentTypesFilter}
            />
            {
                isNMUsers() &&
                <AdditionalDocumentStatusFilter
                    onChange={onChange}
                    onSubmit={sendFilter}
                    value={filterData.additionalDocumentStatusesFilter}
                />
            }
            <div className="nm-contractor-list-filter__row">
                <NmDropdownV2
                    placeholder="Показать все"
                    label={t("contractor-list.gender")}
                    name="genderFilter"
                    className="w-47"
                    onChange={onChange}
                    value={genderFilter}
                    options={GENDER_OPTIONS}
                    size="lg"
                />
                <NmRangeInput
                    label="Возраст"
                    className="w-47"
                    maskStart="9999"
                    maskEnd="9999"
                    nameStart="ageFromFilter"
                    nameEnd="ageToFilter"
                    size="lg"
                    valueStart={ageFromFilter}
                    valueEnd={ageToFilter}
                    onChange={onChange}
                    placeholderStart={t("component-labels.from")}
                    placeholderEnd={t("component-labels.to")}
                />
            </div>
            <NmDropdownV2
                placeholder="Все"
                emptyOptionText="Все"
                label="Семейное положение"
                name="familyStatusFilter"
                onChange={onChange}
                value={familyStatusFilter}
                options={CONTRACTOR_FAMILY_STATUS_OPTIONS}
                size="lg"
            />
            <NmDropdownV2
                placeholder="Все"
                label="Образование"
                name="educationLevelFilter"
                onChange={onChange}
                value={educationLevelFilter}
                options={educationOptions}
                size="lg"
                multiple
            />
            <NmDropdownV2
                placeholder="Все"
                label="Иностранный язык"
                name="foreignLanguageFilter"
                onChange={onChange}
                value={foreignLanguageFilter}
                options={languagesOptions}
                size="lg"
                multiple
            />
            <NmDropdownV2
                placeholder="Все"
                label="Водительские права"
                name="driverLicenseFilter"
                onChange={onChange}
                value={driverLicenseFilter}
                options={driverLicenseOptions}
                size="lg"
                multiple
            />
            <NmDropdownV2
                placeholder="Все"
                emptyOptionText="Все"
                label="Наличие автомобиля"
                name="ownCarFilter"
                onChange={onChange}
                value={ownCarFilter}
                options={SimpleDropdownOptions}
                size="lg"
            />
            <NmWorkScheduleFilter
                isClearable
                className="nm-contractor-list-filter__row"
                name="schedulesFilter"
                onChange={onChange}
                value={schedulesFilter}
            />
            <NmDropdownV2
                placeholder="Все"
                emptyOptionText="Все"
                label="Дата последнего визита"
                name="lastLoginFilter"
                onChange={onChange}
                value={lastLoginFilter}
                options={dictionaryToOptions(CONTRACTOR_LAST_LOGIN_TYPE_DICT)}
                size="lg"
            />
            <div className="nm-contractor-list-filter__sort">
                <div
                    className="nm-contractor-list-filter__sort-header"
                >
                    Сортировка
                </div>
                <NmDropdownV2
                    placeholder="По умолчанию"
                    name="sortType"
                    className="nm-contractor-list-filter__row"
                    onChange={onChange}
                    value={sortType}
                    options={CONTRACTORS_SORT_OPTIONS}
                    emptyOptionText="По умолчанию"
                    size="lg"
                />
            </div>
        </NmForm>
    );
};

export default NmBaseClientContractorListFilter;