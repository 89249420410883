import React from "react";
import {Route, Switch, withRouter} from "react-router";

import CallHistory from "../call-history";
import TicketEventLog from "../event-log";
import TicketParams from "../params";
import TicketHistory from "../ticket-history";

import {SUB_PAGE_CRM_TICKET_CARD} from "../../../../constants/crm/ticket";
import {
    LINK_CLIENT_CARD_CRM_TICKET_CARD_BY_ID, LINK_CONTRACTOR_CRM_TICKET_CARD_BY_ID,
    LINK_CRM_TICKET_CARD_BY_ID,
} from "../../../../constants/links";

const TicketCardRoute = (parentProps) => {
    const {clientId, contractorId} = parentProps;

    let LINK_CARD = LINK_CRM_TICKET_CARD_BY_ID;

    if (clientId) {
        LINK_CARD = LINK_CLIENT_CARD_CRM_TICKET_CARD_BY_ID;
    }

    if(contractorId){
        LINK_CARD = LINK_CONTRACTOR_CRM_TICKET_CARD_BY_ID;
    }

    return (
        <Switch>
            <Route
                exact
                path={`${LINK_CARD}${SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK}`}
                render={(routeProps) => <TicketParams
                    {...routeProps}
                    {...parentProps}
                />}
            />
            <Route
                exact
                path={`${LINK_CARD}${SUB_PAGE_CRM_TICKET_CARD.CALL_HISTORY.LINK}`}
                render={(routeProps) => <CallHistory
                    {...routeProps}
                    {...parentProps}
                />}
            />
            <Route
                exact
                path={`${LINK_CARD}${SUB_PAGE_CRM_TICKET_CARD.HISTORY.LINK}`}
                render={(routeProps) => <TicketHistory
                    {...routeProps}
                    {...parentProps}
                />}
            />
            <Route
                exact
                path={`${LINK_CARD}${SUB_PAGE_CRM_TICKET_CARD.LOG.LINK}`}
                render={(routeProps) => <TicketEventLog
                    {...routeProps}
                    {...parentProps}
                />}
            />
            <Route
                exact
                path={`${LINK_CARD}${SUB_PAGE_CRM_TICKET_CARD.UNATTACHED.LINK}`}
                render={(routeProps) => <CallHistory
                    {...routeProps}
                    {...parentProps}
                />}
            />
        </Switch>
    );
};

export default withRouter(TicketCardRoute);