import React, {Component} from "react";
import {connect} from "react-redux";

import CreateEditPatentPayment from "../../patents/create-edit-patent-payment";
import RegistryEditContractorPayment from "../registry-edit-contractor-payment";
import RegistrySearchContractor from "../registry-search-contractor";
import RegistrySearchContractorResults from "../registry-search-contractor-results";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {
    CONTRACTOR_SEARCH_TYPE,
    REGISTRY_SEARCH_STEP,
} from "../../../constants/registry";

import {
    contractorPageDataSelector,
    getContractorList,
} from "../../../ducks/contractor";

import PropTypes from "prop-types";

class RegistryStepSearchContractor extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        patentRegistryId: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            step: REGISTRY_SEARCH_STEP.STEP_1.VALUE,
            pageNum: 1,
            searchForm: {
                searchType: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
                phoneNumber: "",
                fullName: "",
            },
        };

        const {patentRegistryId} = props;
        this.isPatentsRegistry = !isNullOrWhitespace(patentRegistryId);
    }

    getContractorList = () => {
        const {
            getContractorList,
            pageData,
        } = this.props;
        const {
            pageNum,
        } = this.state;

        getContractorList({
            ...pageData,
            pageSize: 5,
            pageNum,
        });
    };

    onPageChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.getContractorList,
        );
    };

    setStep = (step, data = {}, prevStep = REGISTRY_SEARCH_STEP.STEP_1.VALUE) => {
        const {pageNum} = this.state;

        this.setState({
            step,
            pageNum: step === REGISTRY_SEARCH_STEP.STEP_1.VALUE ? 1 : pageNum,
            data,
            prevStep,
        });
    };

    onChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            searchForm: {
                ...prevState.searchForm,
                [name]: value,
            },
        }));
    };

    renderModalByAction() {
        const {
            step,
            data,
            prevStep,
            pageNum,
            searchForm,
        } = this.state;
        const {
            onClose,
            isEdit,
            paymentParams,
            clientId,
            patentRegistryId,
            fetchList,
            fetchRegistry,
            orderContractPaymentType,
        } = this.props;


        if (step === REGISTRY_SEARCH_STEP.STEP_1.VALUE) {
            return (
                <RegistrySearchContractor
                    onClose={onClose}
                    nextStep={this.setStep}
                    clientId={clientId}
                    form={searchForm}
                    onChange={this.onChange}
                    isPatentsRegistry={this.isPatentsRegistry}
                    orderContractPaymentType={orderContractPaymentType}
                />
            );
        }

        if (step === REGISTRY_SEARCH_STEP.STEP_2.VALUE) {
            return (
                <RegistrySearchContractorResults
                    nextStep={this.setStep}
                    onClose={onClose}
                    onPageChange={this.onPageChange}
                    pageNum={pageNum}
                    isPatentsRegistry={this.isPatentsRegistry}
                    clientId={clientId}
                />
            );
        }

        if (step === REGISTRY_SEARCH_STEP.STEP_3.VALUE) {
            return (
                this.isPatentsRegistry ?
                    <CreateEditPatentPayment
                        isEdit={isEdit}
                        paymentParams={paymentParams}
                        onChangePrevState={() => this.setStep(prevStep)}
                        onClose={onClose}
                        contractorData={data}
                        clientId={clientId}
                        fetchList={fetchList}
                        fetchRegistry={fetchRegistry}
                        patentRegistryId={patentRegistryId}
                    /> :
                    <RegistryEditContractorPayment
                        isEdit={isEdit}
                        paymentParams={paymentParams}
                        nextStep={this.setStep}
                        onClose={onClose}
                        contractorData={data}
                        prevStep={prevStep}
                    />
            );
        }

        return null;
    }

    render() {
        return (
            <>
                {this.renderModalByAction()}
            </>
        );
    }
}

export default connect(
    state => ({
        pageData: contractorPageDataSelector(state),
    }),
    {
        getContractorList,
    },
)(RegistryStepSearchContractor);
