import React, {useEffect, useState} from "react";

import Box from "../../../../components/ActualComponents/Box";

import bem from "../../../../utils/bem";
import {
    calculateSalaryWorker,
    handleCardRate,
    roundNumberWithPoint,
} from "../../../../utils/mathHelper";
import {
    formatAmount,
    formatAmountWithNullChecking,
    formatNumber,
} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import PropTypes from "prop-types";

import "./style.sass";

export const OrderCardInfoContractors = props => {
    const {
        hiredCount,
        contractorsCount,
        className = "",
    } = props;
    const [block, element] = bem("order-card-info", className);

    return (
        <Box className={block()}>
            <div className={element("title")}>
Исполнители на заказе
            </div>
            <div className={element("row")}>
                <div className={element("label")}>
Факт:
                </div>
                <span className={element("value")}>
                    {hiredCount}
                </span>
            </div>
            <div className={element("row")}>
                <div className={element("label")}>
План:
                </div>
                <span className={element("value")}>
                    {contractorsCount}
                </span>
            </div>
        </Box>
    );
};

export const OrderCardInfoVolume = props => {
    const {
        volume,
        subVolume,
        className = "",
    } = props;
    const [block, element] = bem("order-card-info", className);

    return (
        <Box className={block()}>
            <div className={element("title")}>
План по объему работ
            </div>
            <div className={element("plan")}>
                <span className={element("payed")}>
                    {volume}
                </span>
            </div>
            <div className={element("sub-volume")}>
                {subVolume}
            </div>
        </Box>
    );
};

const OrderCardInfoPayout = props => {
    const {
        paid,
        amount,
        neededCount,
        contractorCommission,
        awaitingConfirmationTotalAmount = "",
        notConfirmedTotalAmount = "",
        inProgressTotalAmount = "",
        className = "",
        children,
    } = props;
    const [salary, setSalary] = useState("");
    const [block, element] = bem("order-card-info", className);

    useEffect(() => {
        if (!salary && !isNullOrWhitespace(neededCount) && !isNullOrWhitespace(amount)) {
            const money = formatAmount(calculateSalaryContractor());

            setSalary(money);
        }
    });

    const calculateSalaryContractor = () => {
        const exemplaryCost = calculateSalaryWorker(amount, neededCount);

        return formatAmount(exemplaryCost);
    };

    const getRows = () => {
        const rows = [
            {
                label: "На подтверждении (исп):",
                value: `${formatAmount(awaitingConfirmationTotalAmount)} ₽`,
            },
            {
                label: "На согласовании (зак):",
                value: `${formatAmount(notConfirmedTotalAmount)} ₽`,
            },
            {
                label: "В процессе оплаты:",
                value: `${formatAmount(inProgressTotalAmount )} ₽`,
            },
        ];

        return rows.map(({label, value}, index) =>
            <div
                key={index}
                className={element("row")}
            >
                <div className={element("label")}>
                    {label}
                </div>
                <div className={element("value")}>
                    {value}
                </div>
            </div>,
        );
    };

    return (
        <Box className={block()}>
            {children}
            <div className={element("title")}>
План по выплатам
            </div>
            <div className={element("plan")}>
                <span className={element("payed")}>
                    {formatAmountWithNullChecking(paid)}
                </span>
                {" "}
/
                {formatAmount(formatNumber(roundNumberWithPoint(amount)))}
                {" "}
₽
            </div>
            <div className={element("per-one")}>
                {salary}
                {" "}
₽ на 1 исполнителя
            </div>
            <div className={element("per-one", {divider: true})}>
                {`Комиссия исполнителей ${handleCardRate(contractorCommission)}%`}
            </div>
            {getRows()}
        </Box>
    );
};

OrderCardInfoPayout.propTypes = {
    paid: PropTypes.number,
    amount: PropTypes.number,
    neededCount: PropTypes.number,
    awaitingConfirmationTotalAmount: PropTypes.number,
    notConfirmedTotalAmount: PropTypes.number,
    inProgressTotalAmount: PropTypes.number,
    className: PropTypes.string,
};

export default OrderCardInfoPayout;