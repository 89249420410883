import React from "react";

import {ReactComponent as ConfirmedIcon} from "../../images/checkV2.svg";
import {ReactComponent as RejectedIcon} from "../../images/close_24.svg";
import {ReactComponent as NotVerifiedIcon} from "../../images/remove_24.svg";

import bem from "../../utils/bem";

import {EXECUTOR_CHECK_STATUS} from "../../constants/contractor";

import "./style.sass";

export type ExecutorCheckStatusType = {
    status: "APPROVED" | "REJECTED" | "NOT_VERIFIED",
    className: string
}

const ExecutorCheckStatus = (props: ExecutorCheckStatusType) => {
    const {
        status,
        className = "",
    } = props;

    const [block, element] = bem("executor-check-status", className);

    const getElement = (value: string) => {
        switch (value) {
        case EXECUTOR_CHECK_STATUS.APPROVED.value:
            return <ConfirmedIcon
                className={element("icon-elem")}
                   />;
        case EXECUTOR_CHECK_STATUS.REJECTED.value:
            return <RejectedIcon
                className={element("icon-elem")}
                   />;
        case EXECUTOR_CHECK_STATUS.NOT_VERIFIED.value:
            return <NotVerifiedIcon
                className={element("icon-elem")}
                   />;
        default:
            return null;
        }
    };

    const renderIcon = (item: any) => {
        const {
            value,
            mod,
        } = item;

        return (
            <div
                key={value}
                className={`${element("icon")} ${status === value ? element(`icon_${mod}`) : ""}`}
            >
                {getElement(value)}
            </div>
        );
    };

    return(
        <div className={block()}>
            <div className={`${element("label")} ${element(`label_${EXECUTOR_CHECK_STATUS[status].mod}`)}`}>
                {EXECUTOR_CHECK_STATUS[status].text}
            </div>
            {
                Object.keys(EXECUTOR_CHECK_STATUS).map(key => {
                    // @ts-ignore
                    return renderIcon(EXECUTOR_CHECK_STATUS[key]);
                })
            }
        </div>
    );
};

export default ExecutorCheckStatus;