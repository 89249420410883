import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {toastSuccess} from "../../../utils/toastHelper";

import {updateContractorBankRequisitesRequired} from "../../../ducks/collectionBankDetails";

const requisitesRequiredOptions = [
    {
        key: "NOT_REQUIRED",
        value: "NOT_REQUIRED",
        text: "Не требуются",
    },
    {
        key: "REQUIRED",
        value: "REQUIRED",
        text: "Требуются",
    },
];

export default function BankRequisiteRequiredEditForm(props) {
    const {
        contractorId,
        bankRequisitesRequired,
        close = () => {},
    } = props;

    const dispatch = useDispatch();

    const [requisitesRequired, setRequisitesRequired] = useState("NOT_REQUIRED");

    useEffect(() => {
        if (bankRequisitesRequired) {
            setRequisitesRequired("REQUIRED");
        }
    }, [bankRequisitesRequired]);

    const onChange = (e, {value}) => {
        setRequisitesRequired(value);
    };

    const save = () => {
        const required = requisitesRequired === "REQUIRED";
        const successMessage = `Обязательность заполнения банковских платежных реквизитов для исполнителя ${required ? "включена" : "выключена"}`;

        dispatch(updateContractorBankRequisitesRequired({
            contractorId,
            required,
            onSuccess: () => {
                toastSuccess(successMessage);
                close();
            },
        }));
    };

    return (
        <NmModal
            onClose={close}
            size="sm"
            header={
                <NmTitle size="lg">
                    Банковские реквизиты
                </NmTitle>
            }
            classNameContent="contractor-edit-params-content"
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="colum"
                    onClose={close}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    submit={save}
                />
            }
        >
            <NmForm>
                <NmDropdownV2
                    onChange={onChange}
                    selection
                    value={requisitesRequired}
                    options={requisitesRequiredOptions}
                />
            </NmForm>
        </NmModal>
    );
}