import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

export const bffAdditionalAgreementsSelector = state => state.bff.documentsAdditionalAgreements;
export const bffAdditionalAgreementsListSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({list}) => list,
);
export const bffAdditionalAgreementsPageDataSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({pageData}) => pageData,
);
export const bffAdditionalAgreementsTotalCountSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({totalCount}) => totalCount,
);
export const bffAdditionalAgreementsTotalPagesSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffAdditionalAgreementsProgressSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({progress}) => progress,
);
export const bffAdditionalAgreementsProgressActionSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({progressAction}) => progressAction,
);
export const bffAdditionalAgreementsStatusDictOptionsSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({statusDict}) => dictionaryToOptions(statusDict),
);
export const bffAdditionalAgreementErrorSelector = createSelector(
    bffAdditionalAgreementsSelector,
    ({error}) => error,
);