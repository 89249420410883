export const styleText = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px"
};

export const styleTextArea = {
    minHeight: 126,
    maxHeight: 300
};

export const styleLargeText = {
    fontSize: "18px",
    lineHeight: "24px"
};

export const styleLink =
{
    color: "#174A97",
    cursor: "pointer",
    ...styleText
};

export const styleLargeLink =
{
    color: "#174A97",
    cursor: "pointer",
    ...styleText,
    ...styleLargeText
};

export const amountStyle = {
    fontSize: 14,
    fontWeight: "bold",
    color: "#000000"
};
