import {useEffect} from "react";

export const useBaseDirectoryFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        getItems,
        sortType,
    } = params;

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        sortType,
    ]);

    const fetchList = () => {
        getItems({
            ...filterData,
            pageNum,
            pageSize,
        });
    };

    return {
        fetchList,
    };
};