import React, {Component} from "react";

import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import EmojiPicker from "../EmojiPicker";

import PropTypes from "prop-types";

import "./style.sass";

class InputEmoji extends Component {
    static propTypes = {
        maxLength: PropTypes.number,
    };

    static defaultProps = {
        maxLength: 50,
    };

    state = {
        cursorPosition: 0,
    };

    onSelectEmoji = ({emoji}) => {
        const {
            name,
            value,
        } = this.props;

        const {cursorPosition} = this.state;

        const startSubstring = value.substring(0, cursorPosition);
        const endSubstring = value.substring(cursorPosition, value.length);

        const _value = `${startSubstring}${emoji}${endSubstring}`;

        this.onChange(null, {value: _value, name});

        this.setState({
            cursorPosition: cursorPosition + emoji.length,
        });
    };

    onBlur = (e) => {
        this.setState({
            cursorPosition: e.target.selectionStart,
        });
    };

    isValidMaxLengthValue = (value) => {
        const {maxLength} = this.props;

        return value.length <= maxLength;
    };

    onChange = (e, data) => {
        const {onChange} = this.props;

        if (!this.isValidMaxLengthValue(data.value)) {
            return;
        }

        onChange(e, data);
    };

    render() {
        const {
            className,
        } = this.props;

        return (
            <div className="textarea-emoji">
                <NmTextareaV2
                    {...this.props}
                    className={className}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                />
                <EmojiPicker
                    className="ml-2 textarea-emoji__smile"
                    onSelectEmoji={this.onSelectEmoji}
                />
            </div>
        );
    }
}

InputEmoji.propTypes = {
    className: PropTypes.string,
    classNameBlock: PropTypes.string,
};

InputEmoji.defaultProps = {
    className: "",
    classNameBlock: "",
};

export default InputEmoji;