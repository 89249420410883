import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {registrationPromocode} from "./promocode";

import {ls, USER_ROLE} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions, filterObject} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {convertStringToDate, isNullOrWhitespace} from "../utils/stringHelper";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT_CARD_FIELDS, CLIENT_PROPERTIES_FIELD} from "../constants/clientList";
import {ARCHIVE, CLIENT, LOCK} from "../constants/messages";
import {REFERRAL_CHANNEL_NAME} from "../constants/refProgram";
import {
    FOREMAN,
} from "../constants/roles";

const controller = "/clients";
//*  TYPES  *//

const CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST = "CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST";
const CHECK_PAYMENTS_CREATION_PROHIBITED_ERROR = "CHECK_PAYMENTS_CREATION_PROHIBITED_ERROR";

const CLIENT_READ_REQUEST = "CLIENT_READ_REQUEST";
const CLIENT_READ_SUCCESS = "CLIENT_READ_SUCCESS";
const CLIENT_READ_ERROR = "CLIENT_READ_ERROR";

const CLIENT_CURRENT_READ_REQUEST = "CLIENT_CURRENT_READ_REQUEST";
const CLIENT_CURRENT_READ_SUCCESS = "CLIENT_CURRENT_READ_SUCCESS";
const CLIENT_CURRENT_READ_ERROR = "CLIENT_CURRENT_READ_ERROR";

const CLIENT_ENABLED_FOREMAN_FLAG_REQUEST = "CLIENT_ENABLED_FOREMAN_FLAG_REQUEST";
const CLIENT_ENABLED_FOREMAN_FLAG_SUCCESS = "CLIENT_ENABLED_FOREMAN_FLAG_SUCCESS";
const CLIENT_ENABLED_FOREMAN_FLAG_ERROR = "CLIENT_ENABLED_FOREMAN_FLAG_ERROR";

const CLIENT_ADD_REQUEST = "CLIENT_ADD_REQUEST";
const CLIENT_ADD_SUCCESS = "CLIENT_ADD_SUCCESS";
const CLIENT_ADD_ERROR = "CLIENT_ADD_ERROR";

const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
const CLIENT_UPDATE_ERROR = "CLIENT_UPDATE_ERROR";

const CLIENT_LIST_READ_REQUEST = "CLIENT_LIST_READ_REQUEST";
const CLIENT_LIST_READ_SUCCESS = "CLIENT_LIST_READ_SUCCESS";

const CLIENT_CUSTOM_LIST_READ_SUCCESS = "CLIENT_CUSTOM_LIST_READ_SUCCESS";

const CLIENT_LIST_READ_ERROR = "CLIENT_LIST_READ_ERROR";

const CLIENT_SET_ARCHIVED_REQUEST = "CLIENT_SET_ARCHIVED_REQUEST";
const CLIENT_SET_ARCHIVED_SUCCESS = "CLIENT_SET_ARCHIVED_SUCCESS";
const CLIENT_SET_ARCHIVED_ERROR = "CLIENT_SET_ARCHIVED_ERROR";

const CLIENT_CLEAR_STORE = "CLIENT_CLEAR_STORE";

const CLIENT_UPDATE_FIELD_STORE = "CLIENT_UPDATE_FIELD_STORE";

const CLIENT_GET_COMPANIES_REQUEST = "CLIENT_GET_COMPANIES_REQUEST";
const CLIENT_GET_COMPANIES_SUCCESS = "CLIENT_GET_COMPANIES_SUCCESS";
const CLIENT_GET_COMPANIES_ERROR = "CLIENT_GET_COMPANIES_ERROR";

const CLIENT_SET_LOCKED_REQUEST = "CLIENT_SET_LOCKED_REQUEST";
const CLIENT_SET_LOCKED_SUCCESS = "CLIENT_SET_LOCKED_SUCCESS";
const CLIENT_SET_LOCKED_ERROR = "CLIENT_SET_LOCKED_ERROR";

const CLIENT_IS_ARCHIVE_REQUEST = "CLIENT_IS_ARCHIVE_REQUEST";
const CLIENT_IS_ARCHIVE_SUCCESS = "CLIENT_IS_ARCHIVE_SUCCESS";
const CLIENT_IS_ARCHIVE_ERROR = "CLIENT_IS_ARCHIVE_ERROR";

const CLIENT_UPDATE_FAVORITE_REQUEST = "CLIENT_UPDATE_FAVORITE_REQUEST";
const CLIENT_UPDATE_FAVORITE_SUCCESS = "CLIENT_UPDATE_FAVORITE_SUCCESS";
const CLIENT_UPDATE_FAVORITE_ERROR = "CLIENT_UPDATE_FAVORITE_ERROR";

const CLIENT_UPDATE_NM_CONTRACT_REQUEST = "CLIENT_UPDATE_NM_CONTRACT_REQUEST";
const CLIENT_UPDATE_NM_CONTRACT_SUCCESS = "CLIENT_UPDATE_NM_CONTRACT_SUCCESS";
const CLIENT_UPDATE_NM_CONTRACT_ERROR = "CLIENT_UPDATE_NM_CONTRACT_ERROR";

const CLIENT_REGISTRATION_BANK_REQUEST = "CLIENT_REGISTRATION_BANK_REQUEST";
const CLIENT_REGISTRATION_BANK_SUCCESS = "CLIENT_REGISTRATION_BANK_SUCCESS";
const CLIENT_REGISTRATION_BANK_ERROR = "CLIENT_REGISTRATION_BANK_ERROR";

const CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST = "CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST";
const CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_SUCCESS = "CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_SUCCESS";
const CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_ERROR = "CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_ERROR";

const CLIENT_NAME_MAP_READ_REQUEST = "CLIENT_NAME_MAP_READ_REQUEST";
const CLIENT_NAME_MAP_READ_SUCCESS = "CLIENT_NAME_MAP_READ_SUCCESS";
const CLIENT_NAME_MAP_READ_ERROR = "CLIENT_NAME_MAP_READ_ERROR";

const CLIENT_ROLE_MAP_READ_REQUEST = "CLIENT_ROLE_MAP_READ_REQUEST";
const CLIENT_ROLE_MAP_READ_SUCCESS = "CLIENT_ROLE_MAP_READ_SUCCESS";
const CLIENT_ROLE_MAP_READ_ERROR = "CLIENT_ROLE_MAP_READ_ERROR";

const GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_REQUEST = "GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_REQUEST";
const GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_SUCCESS = "GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_SUCCESS";
const GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_ERROR = "GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_ERROR";

const RETAIL_CLIENT_NAME_MAP_READ_REQUEST = "RETAIL_CLIENT_NAME_MAP_READ_REQUEST";
const RETAIL_CLIENT_NAME_MAP_READ_SUCCESS = "RETAIL_CLIENT_NAME_MAP_READ_SUCCESS";
const RETAIL_CLIENT_NAME_MAP_READ_ERROR = "RETAIL_CLIENT_NAME_MAP_READ_ERROR";

const CLIENT_TERMINATE_CONTRACT_REQUEST = "CLIENT_TERMINATE_CONTRACT_REQUEST";
const CLIENT_TERMINATE_CONTRACT_SUCCESS = "CLIENT_TERMINATE_CONTRACT_SUCCESS";
const CLIENT_TERMINATE_CONTRACT_ERROR = "CLIENT_TERMINATE_CONTRACT_ERROR";

const GET_TERMINATE_CONTRACT_INFO_REQUEST = "GET_TERMINATE_CONTRACT_INFO_REQUEST";
const GET_TERMINATE_CONTRACT_INFO_SUCCESS = "GET_TERMINATE_CONTRACT_INFO_SUCCESS";
const GET_TERMINATE_CONTRACT_INFO_ERROR = "GET_TERMINATE_CONTRACT_INFO_ERROR";

const GET_FILE_NOTICE_REQUEST = "GET_FILE_NOTICE_REQUEST";
const GET_FILE_NOTICE_ERROR = "GET_FILE_NOTICE_ERROR";

const CLIENT_GROUP_PAGE_REQUEST = "CLIENT_GROUP_PAGE_REQUEST";
const CLIENT_GROUP_PAGE_SUCCESS = "CLIENT_GROUP_PAGE_SUCCESS";
const CLIENT_GROUP_PAGE_ERROR = "CLIENT_GROUP_PAGE_ERROR";

const CLIENT_AND_PARTNERS_PAGE_REQUEST = "CLIENT_AND_PARTNERS_PAGE_REQUEST";
const CLIENT_AND_PARTNERS_PAGE_SUCCESS = "CLIENT_AND_PARTNERS_PAGE_SUCCESS";
const CLIENT_AND_PARTNERS_PAGE_ERROR = "CLIENT_AND_PARTNERS_PAGE_ERROR";

const CLIENT_LIST_BY_PARTNER_REQUEST = "CLIENT_LIST_BY_PARTNER_REQUEST";
const CLIENT_LIST_BY_PARTNER_SUCCESS = "CLIENT_LIST_BY_PARTNER_SUCCESS";
const CLIENT_LIST_BY_PARTNER_ERROR = "CLIENT_LIST_BY_PARTNER_ERROR";

const CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST = "CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST";
const CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS = "CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS";
const CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR = "CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR";

const BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST = "BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST";
const BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS = "BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS";
const BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR = "BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR";

const GET_GROUP_MEMBERS_PAGE_REQUEST = "GET_GROUP_MEMBERS_PAGE_REQUEST";
const GET_GROUP_MEMBERS_PAGE_SUCCESS = "GET_GROUP_MEMBERS_PAGE_SUCCESS";
const GET_GROUP_MEMBERS_PAGE_ERROR = "GET_GROUP_MEMBERS_PAGE_ERROR";

const GET_MEMBERS_PAGE_FOR_GROUP_REQUEST = "GET_MEMBERS_PAGE_FOR_GROUP_REQUEST";
const GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS = "GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS";
const GET_MEMBERS_PAGE_FOR_GROUP_ERROR = "GET_MEMBERS_PAGE_FOR_GROUP_ERROR";

const ADD_GROUP_MEMBER_REQUEST = "ADD_GROUP_MEMBER_REQUEST";
const ADD_GROUP_MEMBER_SUCCESS = "ADD_GROUP_MEMBER_SUCCESS";
const ADD_GROUP_MEMBER_ERROR = "ADD_GROUP_MEMBER_ERROR";

const DELETE_GROUP_MEMBER_REQUEST = "DELETE_GROUP_MEMBER_REQUEST";
const DELETE_GROUP_MEMBER_SUCCESS = "DELETE_GROUP_MEMBER_SUCCESS";
const DELETE_GROUP_MEMBER_ERROR = "DELETE_GROUP_MEMBER_ERROR";

const GET_RELATED_CLIENT_USERS_REQUEST = "GET_RELATED_CLIENT_USERS_REQUEST";
const GET_RELATED_CLIENT_USERS_SUCCESS = "GET_RELATED_CLIENT_USERS_SUCCESS";
const GET_RELATED_CLIENT_USERS_ERROR = "GET_RELATED_CLIENT_USERS_ERROR";

const GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST = "GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST";
const GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS = "GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS";
const GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR = "GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR";

const UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST = "UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST";
const UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS = "UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS";
const UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR = "UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR";

const GET_CLIENT_CIVIL_CONTRACT_REQUEST = "GET_CLIENT_CIVIL_CONTRACT_REQUEST";
const GET_CLIENT_CIVIL_CONTRACT_SUCCESS = "GET_CLIENT_CIVIL_CONTRACT_SUCCESS";
const GET_CLIENT_CIVIL_CONTRACT_ERROR = "GET_CLIENT_CIVIL_CONTRACT_ERROR";

const UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST = "UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST";
const UPDATE_CLIENT_CIVIL_CONTRACT_SUCCESS = "UPDATE_CLIENT_CIVIL_CONTRACT_SUCCESS";
const UPDATE_CLIENT_CIVIL_CONTRACT_ERROR = "UPDATE_CLIENT_CIVIL_CONTRACT_ERROR";

const GET_GROUP_MEMBER_RELATED_USERS_REQUEST = "GET_GROUP_MEMBER_RELATED_USERS_REQUEST";
const GET_GROUP_MEMBER_RELATED_USERS_SUCCESS = "GET_GROUP_MEMBER_RELATED_USERS_SUCCESS";
const GET_GROUP_MEMBER_RELATED_USERS_ERROR = "GET_GROUP_MEMBER_RELATED_USERS_ERROR";

const SET_GROUP_MEMBER_RELATED_USERS_REQUEST = "SET_GROUP_MEMBER_RELATED_USERS_REQUEST";
const SET_GROUP_MEMBER_RELATED_USERS_SUCCESS = "SET_GROUP_MEMBER_RELATED_USERS_SUCCESS";
const SET_GROUP_MEMBER_RELATED_USERS_ERROR = "SET_GROUP_MEMBER_RELATED_USERS_ERROR";

const CLIENT_GET_NAMES_LIST_REQUEST = "CLIENT_GET_NAMES_LIST_REQUEST";
const CLIENT_GET_NAMES_LIST_SUCCESS = "CLIENT_GET_NAMES_LIST_SUCCESS";
const CLIENT_GET_NAMES_LIST_ERROR = "CLIENT_GET_NAMES_LIST_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    listWithPartners: [],
    customList: [],
    totalCount: 0,
    card: {},
    pageData: {},
    error: null,
    progress: false,
    errorFetch: false,
    isSuccessAdd: false,
    archived: false,
    isSuccessArchived: false,
    isSuccessLocked: false,
    clientGroupPageProgress: false,
    newClientId: "",
    currentClient: {},
    clientListByIds: [],
    responseIsArchive: {},
    progressNmContract: false,
    enabledForemanFlag: false,
    clientNameIdMap: {},
    retailClientNameIdMap: {},
    clientGroupPage: [],
    roleMap: {},
    clientListByPartner: [],
    terminateContractList: [],
    clientListPage: [],
    optimizedClientList: [],
    groupMembersPageData: {},
    membersForGroupPageData: {},
    groupMembersTotalCount: 0,
    membersForGroupTotalCount: 0,
    groupMembersList: [],
    membersListForGroup: [],
    progressGroupMembersList: false,
    progressGroupMembersAction: false,
    progressMembersListForGroup: false,
    listRelatedClientUsers: [],
    progressPatentContract: false,
    patentContractCard: {},
    civilContract: {},
    progressSetRelatedUsers: false,
    groupMemberRelatedUsersList: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CLIENT_CIVIL_CONTRACT_SUCCESS:
        return {
            ...state,
            civilContract: payload,
        };
    case CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST:
    case BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS:
    case BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS: {
        const {
            clients = [],
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            errorFetch: false,
            optimizedClientList: clients,
            totalCount,
        };
    }
    case CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR:
    case BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR:
        return {
            ...state,
            progress: false,
            errorFetch: true,
        };
    case CLIENT_GROUP_PAGE_REQUEST:
        return {
            ...state,
            clientGroupPageProgress: true,
        };
    case CLIENT_GET_COMPANIES_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_UPDATE_NM_CONTRACT_REQUEST:
        return {
            ...state,
            progressNmContract: true,
        };
    case CLIENT_IS_ARCHIVE_REQUEST:
        return {
            ...state,
            progressIsArchive: true,
            responseIsArchive: {},
        };
    case CLIENT_LIST_READ_REQUEST:
        return {
            ...state,
            progress: true,
            isSuccessLocked: false,
            isSuccessArchived: false,
            pageData: payload,
        };
    case CLIENT_AND_PARTNERS_PAGE_REQUEST:
        return {
            ...state,
            progress: true,
            isSuccessLocked: false,
            isSuccessArchived: false,
            pageData: payload,
        };
    case GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST:
        return {
            ...state,
            progressPatentContract: true,
        };
    case UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST:
        return {
            ...state,
            progressPatentContract: true,
        };
    case CLIENT_UPDATE_FIELD_STORE:
        const {
            field,
            value,
        } = payload;
        return {
            ...state,
            progress: false,
            [field]: value,
        };
    case CLIENT_CLEAR_STORE:
        return {
            ...initial,
        };
    case CLIENT_LIST_BY_PARTNER_SUCCESS:
        return {
            ...state,
            clientListByPartner: payload,
        };
    case CLIENT_CURRENT_READ_SUCCESS:
        return {
            ...state,
            currentClient: payload,
        };
    case CLIENT_UPDATE_NM_CONTRACT_SUCCESS:
        return {
            ...state,
            progressNmContract: false,
        };
    case CLIENT_SET_LOCKED_REQUEST:
    case CLIENT_SET_ARCHIVED_REQUEST:
    case CLIENT_TERMINATE_CONTRACT_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_ENABLED_FOREMAN_FLAG_SUCCESS:
        return {
            ...state,
            enabledForemanFlag: payload,
        };
    case CLIENT_GET_COMPANIES_SUCCESS:
        return {
            ...state,
            clientListByIds: payload,
            progress: false,
        };
    case CLIENT_IS_ARCHIVE_SUCCESS:
        return {
            ...state,
            progressIsArchive: false,
            responseIsArchive: payload,
        };
    case CLIENT_SET_LOCKED_SUCCESS:
        return {
            ...state,
            progress: false,
            isSuccessLocked: true,
        };
    case CLIENT_SET_ARCHIVED_SUCCESS:
        return {
            ...state,
            progress: false,
            isSuccessArchived: true,
        };
    case CLIENT_UPDATE_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case CLIENT_ADD_REQUEST:
        return {
            ...state,
            progress: true,
            newClientId: "",
        };
    case CLIENT_READ_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_REGISTRATION_BANK_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CLIENT_ADD_SUCCESS:
        return {
            ...state,
            newClientId: payload,
            progress: false,
            isSuccessAdd: true,
        };
    case CLIENT_UPDATE_SUCCESS:
        return {
            ...state,
            progress: false,
            progressUpdate: false,
        };
    case GET_TERMINATE_CONTRACT_INFO_SUCCESS:
        return {
            ...state,
            terminateContractList: payload,
        };
    case CLIENT_GROUP_PAGE_SUCCESS:
        return {
            ...state,
            clientGroupPageProgress: false,
            clientGroupPage: payload,
        };
    case CLIENT_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            card: payload,
        };
    case CLIENT_GET_NAMES_LIST_SUCCESS: {
        const {
            results,
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            optimizedClientList: results,
            totalCount,
        };
    }
    case CLIENT_LIST_READ_SUCCESS:
        const {
            clients = [],
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            list: [...clients],
            totalCount,
        };
    case CLIENT_AND_PARTNERS_PAGE_SUCCESS:
        const {
            clients: clientsWithPartners = [],
        } = payload;
        return {
            ...state,
            progress: false,
            listWithPartners: [...clientsWithPartners],
        };
    case CLIENT_CUSTOM_LIST_READ_SUCCESS:
        const {
            clients: customClients = [],
            totalCount: customTotalcount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            customList: [...customClients],
            totalCount: customTotalcount,
        };
    case CLIENT_TERMINATE_CONTRACT_SUCCESS:
    case CLIENT_TERMINATE_CONTRACT_ERROR:
    case CLIENT_REGISTRATION_BANK_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS:
        return {
            ...state,
            progressPatentContract: false,
            patentContractCard: payload,
        };
    case UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS:
        return {
            ...state,
            progressPatentContract: false,
        };
    case CLIENT_ADD_ERROR:
        return {
            ...state,
            isSuccessAdd: false,
            progress: false,
            error: payload,
        };
    case CLIENT_IS_ARCHIVE_ERROR:
        return {
            ...state,
            progressIsArchive: false,
            responseIsArchive: {},
        };
    case CLIENT_UPDATE_NM_CONTRACT_ERROR:
        return {
            ...state,
            progressNmContract: false,
        };
    case CLIENT_GET_COMPANIES_ERROR:
    case CLIENT_LIST_READ_ERROR:
    case CLIENT_AND_PARTNERS_PAGE_ERROR:
    case CLIENT_UPDATE_ERROR:
    case CLIENT_READ_ERROR:
    case CLIENT_SET_ARCHIVED_ERROR:
    case CLIENT_CURRENT_READ_ERROR:
    case CLIENT_REGISTRATION_BANK_ERROR:
        return {
            ...state,
            progress: false,
            progressUpdate: false,
            error: payload,
        };
    case CLIENT_ENABLED_FOREMAN_FLAG_ERROR:
        return {
            ...state,
            enabledForemanFlag: false,
            error: payload,
        };
    case CLIENT_GROUP_PAGE_ERROR:
        return {
            ...state,
            clientGroupPageProgress: false,
            error: payload,
        };
    case CLIENT_NAME_MAP_READ_SUCCESS:
        return {
            ...state,
            clientNameIdMap: payload,
        };
    case RETAIL_CLIENT_NAME_MAP_READ_SUCCESS:
        return {
            ...state,
            retailClientNameIdMap: payload,
        };
    case CLIENT_ROLE_MAP_READ_SUCCESS:
        return {
            ...state,
            roleMap: payload,
        };
    case GET_RELATED_CLIENT_USERS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_RELATED_CLIENT_USERS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case GET_RELATED_CLIENT_USERS_SUCCESS:
        return {
            ...state,
            progress: false,
            listRelatedClientUsers: payload,
        };
    case GET_GROUP_MEMBERS_PAGE_REQUEST:
        return {
            ...state,
            groupMembersPageData: payload,
            progressGroupMembersList: true,
        };
    case GET_MEMBERS_PAGE_FOR_GROUP_REQUEST:
        return {
            ...state,
            membersForGroupPageData: payload,
            progressMembersListForGroup: true,
        };
    case ADD_GROUP_MEMBER_REQUEST:
    case DELETE_GROUP_MEMBER_REQUEST:
        return {
            ...state,
            progressGroupMembersAction: true,
        };
    case GET_GROUP_MEMBERS_PAGE_SUCCESS:
        const {
            clientUsers: groupMembersList,
            totalCount: groupMembersTotalCount,
        } = payload;

        return {
            ...state,
            groupMembersList,
            groupMembersTotalCount,
            progressGroupMembersList: false,
        };
    case GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS:
        const {
            clientUsers: membersListForGroup,
            totalCount: membersForGroupTotalCount,
        } = payload;

        return {
            ...state,
            membersListForGroup,
            membersForGroupTotalCount,
            progressMembersListForGroup: false,
        };
    case GET_GROUP_MEMBERS_PAGE_ERROR:
        return {
            ...state,
            progressGroupMembersList: false,
        };
    case GET_MEMBERS_PAGE_FOR_GROUP_ERROR:
        return {
            ...state,
            progressMembersListForGroup: false,
        };
    case ADD_GROUP_MEMBER_SUCCESS:
    case DELETE_GROUP_MEMBER_SUCCESS:
        return {
            ...state,
            progressGroupMembersAction: false,
        };
    case ADD_GROUP_MEMBER_ERROR:
    case DELETE_GROUP_MEMBER_ERROR:
        return {
            ...state,
            progressGroupMembersAction: false,
        };
    case GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR:
        return {
            ...state,
            progressPatentContract: false,
        };
    case UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR:
        return {
            ...state,
            progressPatentContract: false,
        };
    case SET_GROUP_MEMBER_RELATED_USERS_REQUEST:
    case GET_GROUP_MEMBER_RELATED_USERS_REQUEST:
        return {
            ...state,
            progressSetRelatedUsers: true,
        };
    case SET_GROUP_MEMBER_RELATED_USERS_SUCCESS:
    case SET_GROUP_MEMBER_RELATED_USERS_ERROR:
    case GET_GROUP_MEMBER_RELATED_USERS_ERROR:
        return {
            ...state,
            progressSetRelatedUsers: false,
        };
    case GET_GROUP_MEMBER_RELATED_USERS_SUCCESS:
        return {
            ...state,
            groupMemberRelatedUsersList: payload.list,
            progressSetRelatedUsers: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getRelatedClientUsers(payload) {
    return {
        type: GET_RELATED_CLIENT_USERS_REQUEST,
        payload,
    };
}

export function updateNmContract(payload) {
    return {
        type: CLIENT_UPDATE_NM_CONTRACT_REQUEST,
        payload,
    };
}

export function getTerminateContractInfo(payload) {
    return {
        type: GET_TERMINATE_CONTRACT_INFO_REQUEST,
        payload,
    };
}

export function getClientByIds(payload) {
    return {
        type: CLIENT_GET_COMPANIES_REQUEST,
        payload,
    };
}

export function getClientById(payload) {
    return {
        type: CLIENT_READ_REQUEST,
        payload,
    };
}

export function getClientGroupPage(payload) {
    return {
        type: CLIENT_GROUP_PAGE_REQUEST,
        payload,
    };
}

export function getCurrentClientById(payload) {
    return {
        type: CLIENT_CURRENT_READ_REQUEST,
        payload,
    };
}

export function isEnabledForemanFlag(payload) {
    return {
        type: CLIENT_ENABLED_FOREMAN_FLAG_REQUEST,
        payload,
    };
}

export function addClient(payload) {
    return {
        type: CLIENT_ADD_REQUEST,
        payload: payload,
    };
}

export function clearClientStore() {
    return {
        type: CLIENT_CLEAR_STORE,
    };
}

export function updateFieldClientStore(field, value) {
    return {
        type: CLIENT_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function updateClient(payload) {
    return {
        type: CLIENT_UPDATE_REQUEST,
        payload,
    };
}

export function setLockedClient(clientId, type) {
    return {
        type: CLIENT_SET_LOCKED_REQUEST,
        payload: {clientId, type},
    };
}

export function setArchivedClient(clientId, type) {
    return {
        type: CLIENT_SET_ARCHIVED_REQUEST,
        payload: {clientId, type},
    };
}

export function setTerminateContractClient(payload) {
    return {
        type: CLIENT_TERMINATE_CONTRACT_REQUEST,
        payload,
    };
}

export function getClientList(payload) {
    return {
        type: CLIENT_LIST_READ_REQUEST,
        payload,
    };
}

export function getIsArchive(payload) {
    return {
        type: CLIENT_IS_ARCHIVE_REQUEST,
        payload,
    };
}

export function updateClientFavorite(payload) {
    return {
        type: CLIENT_UPDATE_FAVORITE_REQUEST,
        payload,
    };
}

export function clientBankRegistration(payload) {
    return {
        type: CLIENT_REGISTRATION_BANK_REQUEST,
        payload,
    };
}

export function getClientNameMap() {
    return {
        type: CLIENT_NAME_MAP_READ_REQUEST,
    };
}

export function getClientRoleMap() {
    return {
        type: CLIENT_ROLE_MAP_READ_REQUEST,
    };
}

export function getCountOfClientsForPushNotifications(payload) {
    return {
        type: GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_REQUEST,
        payload,
    };
}

export function setProhibitionContractingNonResidents(payload) {
    return {
        type: CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST,
        payload,
    };
}

export function getRetailClientNameMap() {
    return {
        type: RETAIL_CLIENT_NAME_MAP_READ_REQUEST,
    };
}

export function getClientAndPartnersList(payload) {
    return {
        type: CLIENT_AND_PARTNERS_PAGE_REQUEST,
        payload,
    };
}

export function getClientsByPartner(payload) {
    return {
        type: CLIENT_LIST_BY_PARTNER_REQUEST,
        payload,
    };
}

export function getFileNotice(payload) {
    return {
        type: GET_FILE_NOTICE_REQUEST,
        payload,
    };
}

// оптимизированный список клиентов
export function getOptimizedClientList(payload) {
    return {
        type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST,
        payload,
    };
}

export function getBffClientListOptimized(payload) {
    return {
        type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST,
        payload,
    };
}

export function getGroupMembersPage(payload) {
    return {
        type: GET_GROUP_MEMBERS_PAGE_REQUEST,
        payload,
    };
}

export function getMembersPageForGroup(payload) {
    return {
        type: GET_MEMBERS_PAGE_FOR_GROUP_REQUEST,
        payload,
    };
}

export function addGroupMember(payload) {
    return {
        type: ADD_GROUP_MEMBER_REQUEST,
        payload,
    };
}

export function deleteGroupMember(payload) {
    return {
        type: DELETE_GROUP_MEMBER_REQUEST,
        payload,
    };
}

export function getClientContractDetails(payload) {
    return {
        type: GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST,
        payload,
    };
}

export function updateClientContractDetails(payload) {
    return {
        type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST,
        payload,
    };
}

export function checkPaymentsCreationProhibited(payload) {
    return {
        type: CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST,
        payload,
    };
}

export function getClientCivilContract(payload) {
    return {
        type: GET_CLIENT_CIVIL_CONTRACT_REQUEST,
        payload,
    };
}

export function updateClientCivilContract(payload) {
    return {
        type: UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST,
        payload,
    };
}

export function getGroupMemberRelatedUsers(payload) {
    return {
        type: GET_GROUP_MEMBER_RELATED_USERS_REQUEST,
        payload,
    };
}

export function setGroupMemberRelatedUsers(payload) {
    return {
        type: SET_GROUP_MEMBER_RELATED_USERS_REQUEST,
        payload,
    };
}

export function getClientNamesList(payload) {
    return {
        type: CLIENT_GET_NAMES_LIST_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientSelector = state => state.client;

export const terminateContractInfoSelector = createSelector(clientSelector, ({terminateContractList}) => terminateContractList);

export const clientTotalPagesSelector = createSelector(clientSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const clientListByPartnerOptionsSelector = createSelector(clientSelector, ({clientListByPartner}) => {
    return clientListByPartner.map(({clientId, name}) => ({key: clientId, value: clientId, text: name}));
});

export const clientGroupOptionsSelector = createSelector(clientSelector, ({clientGroupPage}) => clientGroupPage.map(({clientId: value, name: text}) => ({
    key: value, value, text,
})));

export const getClientCardSelector = createSelector(clientSelector, ({card}) => card);

export const clientEnabledForemanFlagSelector = createSelector(clientSelector, ({enabledForemanFlag}) => enabledForemanFlag);

export const getClientCommissionRateSelector = createSelector(clientSelector, ({card, currentClient}) => card.currentCommissionRate || currentClient.currentCommissionRate);

export const getMinCommissionAmountSelector = createSelector(clientSelector, ({card, currentClient}) => card.minCommissionAmount || currentClient.minCommissionAmount);

export const getPaymentsThresholdAmountSelector = createSelector(clientSelector, ({card, currentClient}) => card.paymentsThresholdAmount || currentClient.paymentsThresholdAmount);

export const getContractorCommissionRateSelector = createSelector(clientSelector, ({card, currentClient}) => card.contractorSmzOrderPaymentsCommission || currentClient.contractorSmzOrderPaymentsCommission);

export const getClientInfoSelector = createSelector(clientSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.CLIENT_INFO, card));

export const getClientTypeSelector = createSelector(clientSelector, ({card: {clientType}}) => clientType);

export const getBankInfoSelector = createSelector(clientSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.BANK_INFO, card));

export const getAccountantInfoSelector = createSelector(clientSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.ACCOUNTANT_INFO, card));

export const getBossInfoSelector = createSelector(clientSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.BOSS_INFO, card));

export const getSignatoryInfoSelector = createSelector(clientSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.SIGNATORY_INFO, card));

export const getRepresentativeInfoSelector = createSelector(clientSelector, ({card}) => filterObject(CLIENT_CARD_FIELDS.REPRESENTATIVE_INFO, card));

export const getCategoryInfoSelector = createSelector(clientSelector, ({card: {categoryId, okved, specialityIds}}) => {
    return {categoryId, okved, specialityIds};
});

export const getDataIsArchiveSelector = createSelector(clientSelector, ({progressIsArchive, responseIsArchive}) => {
    return {
        progressIsArchive,
        responseIsArchive,
    };
});

export const clientTotalCountSelector = createSelector(clientSelector, ({totalCount}) => totalCount);

export const getCurrentClientNameSelector = createSelector(clientSelector, ({currentClient}) => currentClient ? currentClient.name : "");

export const getCurrentClientSelector = createSelector(clientSelector, ({currentClient}) => currentClient);

export const getCurrentNmContractDateConclusionSelector = createSelector(clientSelector, ({currentClient = {}, card}) => {
    if (ls(USER_ROLE) === FOREMAN) {
        return currentClient.nmContractDateConclusion;
    }
    return card.nmContractDateConclusion;
});

export const clientPageDataSelector = createSelector(clientSelector, ({pageData}) => pageData);

export const clientArchivedSelector = createSelector(clientSelector, ({archived}) => archived);

export const clientOptionsListSelector = createSelector(clientSelector, ({list}) => list.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));

export const clientListOptimizedSelector = createSelector(clientSelector, ({optimizedClientList}) => optimizedClientList);
export const clientListOptimizedOptionsSelector = createSelector(clientSelector, ({optimizedClientList}) => optimizedClientList.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));

export const clientOptionsListWithPartnersSelector = createSelector(clientSelector, ({listWithPartners}) => listWithPartners.map(({clientId, clientPartnerId, name, brand}) => {
    const itemId = clientPartnerId ? clientPartnerId : clientId;
    const brandText = brand ? `(${brand})` : "";
    const text = clientPartnerId ? `${name} ${REFERRAL_CHANNEL_NAME.PARTNER}` : `${name}${brandText}`;
    return ({
        key: itemId,
        value: itemId,
        text,
        clientName: name,
    });
}));

export const promocodeClientOptionsSelector = createSelector(clientSelector, ({listWithPartners}) => listWithPartners
    .map(({clientId, clientPartnerId, name: text}) => {
        if (!clientPartnerId) {
            return ({key: clientId, value: clientId, text});
        }
        return null;
    })
    .filter(Boolean));

export const promocodePartnersOptionsSelector = createSelector(clientSelector, ({listWithPartners}) => listWithPartners
    .map(({clientPartnerId: value, name: text}) => {
        if (value) {
            return ({key: value, value: value, text});
        }
        return null;
    })
    .filter(Boolean));

export const clientOptionsCustomListSelector = createSelector(clientSelector, ({customList}) => customList.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));

export const clientListByIdsSelector = createSelector(clientSelector, ({clientListByIds}) => clientListByIds);

export const clientProgressSelector = createSelector(clientSelector, ({progress}) => progress);
export const clientErrorFetchSelector = createSelector(clientSelector, ({errorFetch}) => errorFetch);

export const clientIsSuccessAddSelector = createSelector(clientSelector, ({isSuccessAdd}) => isSuccessAdd);

export const clientIsSuccessArchivedSelector = createSelector(clientSelector, state => {
    return state.isSuccessArchived;
});

export const clientNewClientIdSelector = createSelector(clientSelector, ({newClientId}) => newClientId);
export const clientCurrentDataSelector = createSelector(clientSelector, ({card, currentClient}) => card.clientId ? card : currentClient);

export const clientNameIdMapSelector = createSelector(clientSelector, ({clientNameIdMap}) => dictionaryToOptions(clientNameIdMap));
export const clientRoleMapSelector = createSelector(clientSelector, ({roleMap}) => dictionaryToOptions(roleMap));
export const retailClientNameIdMapSelector = createSelector(clientSelector, ({retailClientNameIdMap}) => dictionaryToOptions(retailClientNameIdMap));

export const groupMembersListSelector = createSelector(clientSelector, ({groupMembersList}) => groupMembersList);
export const groupMembersTotalCountSelector = createSelector(clientSelector, ({groupMembersTotalCount}) => groupMembersTotalCount);
export const groupMembersTotalPagesSelector = createSelector(clientSelector, ({groupMembersTotalCount, groupMembersPageData: {pageSize = 0}}) => getTotalPages(groupMembersTotalCount, pageSize));
export const progressGroupMembersListSelector = createSelector(clientSelector, ({progressGroupMembersList}) => progressGroupMembersList);
export const progressMembersListFromGroupSelector = createSelector(clientSelector, ({progressMembersListForGroup}) => progressMembersListForGroup);
export const membersListForGroupSelector = createSelector(clientSelector, ({membersListForGroup}) => membersListForGroup);
export const membersForGroupTotalCountSelector = createSelector(clientSelector, ({membersForGroupTotalCount}) => membersForGroupTotalCount);
export const membersForGroupTotalPagesSelector = createSelector(clientSelector, ({membersForGroupTotalCount, membersForGroupPageData: {pageSize = 0}}) => getTotalPages(membersForGroupTotalCount, pageSize));
export const progressGroupMembersActionSelector = createSelector(clientSelector, ({progressGroupMembersAction}) => progressGroupMembersAction);

export const relatedClientUsersListSelector = createSelector(clientSelector, ({listRelatedClientUsers}) => listRelatedClientUsers.filter(({enabled}) => enabled));

export const patentContractCardSelector = createSelector(clientSelector, ({patentContractCard}) => patentContractCard);
export const progressPatentContractSelector = createSelector(clientSelector, ({progressPatentContract}) => progressPatentContract);
export const progressSetRelatedUsersSelector = createSelector(clientSelector, ({progressSetRelatedUsers}) => progressSetRelatedUsers);
export const groupMemberRelatedUsersListSelector = createSelector(clientSelector, ({groupMemberRelatedUsersList}) => groupMemberRelatedUsersList);
export const groupMemberRelatedUsersOptionsSelector = createSelector(clientSelector, ({groupMemberRelatedUsersList}) => {
    return groupMemberRelatedUsersList.map((item) => {
        return {
            key: item.clientUserId,
            value: item.clientUserId,
            text: item.clientFullName,
        };
    });
});
export const clientCivilContractSelector = createSelector(clientSelector, ({civilContract}) => ({
    ...civilContract,
    civilContractNumber: civilContract.civilContractNumber || "",
    civilContractDate: civilContract.civilContractDate ? convertStringToDate(civilContract.civilContractDate) : undefined,
}));
export const clientProgressNmContractSelector = createSelector(clientSelector, ({progressNmContract}) => progressNmContract);

//*  SAGA  *//

// /api/clients/users/isPaymentsCreationProhibited
export const checkPaymentsCreationProhibitedSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onClose,
        } = payload;

        const isPaymentsCreationProhibited = yield request.get(`${controller}/users/isPaymentsCreationProhibited`);

        if (isPaymentsCreationProhibited) {
            yield toastError("Возможность отправить акт исполнителю недоступна для вашей учетной записи. Обратитесь к руководству вашей компании");
            yield onClose();

            return {
                done: true,
            };
        }

        yield onSuccess();

    } catch (error) {
        yield put({
            type: CHECK_PAYMENTS_CREATION_PROHIBITED_ERROR,
            payload: error,
        });
    }
};

export const clientReadSaga = function* (action) {
    try {
        const {payload: clientId} = action;
        const result = yield request.get(`${controller}/getRichById`, {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_READ_ERROR,
            payload: error,
        });
    }
};

export const isEnabledForemanFlagSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.get(`${controller}/canEnableForemanFlag`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_ENABLED_FOREMAN_FLAG_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_ENABLED_FOREMAN_FLAG_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_ENABLED_FOREMAN_FLAG_ERROR,
            payload: error,
        });
    }
};

export const clientCurrentReadSaga = function* (action) {
    try {
        const {payload: clientId} = action;
        const result = yield request.get(`${controller}/getRichById`, {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_CURRENT_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_CURRENT_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_CURRENT_READ_ERROR,
            payload: error,
        });
    }
};


export const updateClientSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            clientId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);

        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);
        onSuccess();

        yield put({
            type: CLIENT_UPDATE_SUCCESS,
        });

        yield put(getClientById(clientId));
    } catch (error) {
        toastError(CLIENT.CLIENT_UPDATE_ERROR_TEXT);

        yield put({
            type: CLIENT_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const addClientSaga = function* (action) {
    const {
        payload: {
            data,
            promocode,
            onSuccess = () => {
            },
        },
    } = action;

    try {
        const result = yield request.post(`${controller}/add`, data);
        const {
            guid,
            errorMessage,
        } = result;

        if (!guid) {
            toastError(errorMessage ? errorMessage : CLIENT.CLIENT_ADD_ERROR_TEXT);

            yield put({
                type: CLIENT_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(guid);
        toastSuccess(CLIENT.CLIENT_ADD_SUCCESS_TEXT);

        yield put({
            type: CLIENT_ADD_SUCCESS,
            payload: guid,
        });

        if (!isNullOrWhitespace(promocode)) {
            yield put(registrationPromocode({
                data: {
                    clientId: guid,
                    promocode,
                },
            }));
        }

        const state = yield select();

        yield put(setLockedClient(guid, false));
        yield put(getOptimizedClientList(state.client.pageData));
    } catch (error) {
        toastError(CLIENT.CLIENT_ADD_ERROR_TEXT);

        yield put({
            type: CLIENT_ADD_ERROR,
            payload: error,
        });
    }
};

export const clientSetArchivedSaga = function* ({payload}) {
    const {
        clientId,
        type,
        onSuccess,
    } = payload;

    try {
        const {errorMessage} = yield request.post(`${controller}/${clientId}/archived/${type}`);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SET_ARCHIVED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CLIENT_SET_ARCHIVED_SUCCESS,
        });
        if (type) {
            toastSuccess(ARCHIVE.CLIENT_SET_ARCHIVED_SUCCESS_TEXT);
        } else {
            toastSuccess(ARCHIVE.CLIENT_SET_RESTORED_SUCCESS_TEXT);
        }
    } catch (error) {
        if (type) {
            toastError(ARCHIVE.CLIENT_SET_ARCHIVED_ERROR_TEXT);
        } else {
            toastError(ARCHIVE.CLIENT_SET_RESTORED_ERROR_TEXT);
        }
        yield put({
            type: CLIENT_SET_ARCHIVED_ERROR,
            payload: error,
        });
    }
};

export const terminateContractClientSaga = function* ({payload}) {
    const {
        clientId,
        data,
        onSuccess,
        onError,
    } = payload;

    try {
        const {errorMessage} = yield request.put(`${controller}/${clientId}/archive`, data, {...getMultipartHeaders()});

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_TERMINATE_CONTRACT_ERROR,
                payload: errorMessage,
            });

            if (onError) {
                yield call(onError, null);
            }

            return {
                done: true,
            };
        }

        if (onSuccess) {
            yield call(onSuccess, null);
        }

        yield put({
            type: CLIENT_TERMINATE_CONTRACT_SUCCESS,
        });

        // toastSuccess(ARCHIVE.CLIENT_SET_ARCHIVED_SUCCESS_TEXT);

    } catch (error) {
        // toastError(ARCHIVE.CLIENT_SET_RESTORED_ERROR_TEXT);

        yield put({
            type: CLIENT_TERMINATE_CONTRACT_ERROR,
            payload: error,
        });
    }
};

export const clientSetLockedSaga = function* (action) {
    const {clientId, type} = action.payload;
    try {
        yield request.post(`${controller}/${clientId}/locked/${type}`);

        yield put({
            type: CLIENT_SET_LOCKED_SUCCESS,
        });
        if (type) {
            toastSuccess(LOCK.CLIENT_SET_LOCKED_SUCCESS_TEXT);
        } else {
            toastSuccess(LOCK.CLIENT_SET_UNLOCKED_SUCCESS_TEXT);
        }
    } catch (error) {
        if (type) {
            toastError(LOCK.CLIENT_SET_LOCKED_ERROR_TEXT);
        } else {
            toastError(LOCK.CLIENT_SET_UNLOCKED_ERROR_TEXT);
        }
        yield put({
            type: CLIENT_SET_LOCKED_ERROR,
            payload: error,
        });
    }
};

export const getClientGroupPageSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const result = yield request.get(`${controller}/getDictionaryClientsForClientGroupFilter`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GROUP_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_GROUP_PAGE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: CLIENT_GROUP_PAGE_ERROR,
            payload: error,
        });
    }
};

export const clientListReadSaga = function* ({payload}) {
    try {
        const {
            isCustomRequest,
            onSuccess = () => {
            },
        } = payload;

        if (isCustomRequest) {
            delete payload.isCustomRequest;
        }
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        if (isCustomRequest) {
            yield put({
                type: CLIENT_CUSTOM_LIST_READ_SUCCESS,
                payload: result,
            });
        } else {
            yield put({
                type: CLIENT_LIST_READ_SUCCESS,
                payload: result,
            });
        }
    } catch (error) {
        yield put({
            type: CLIENT_LIST_READ_ERROR,
            payload: error,
        });
    }
};


export const getCompaniesSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getByIds`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_COMPANIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_GET_COMPANIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_GET_COMPANIES_ERROR,
            payload: error,
        });
    }
};

//POST /api/clients/{clientId}/isArchived

export const getIsArchivedSaga = function* (action) {
    try {
        const {
            payload: {
                clientId,
            },
        } = action;
        const result = yield request.post(`${controller}/${clientId}/isArchived`);

        const {errorMessage} = result;

        yield put({
            type: CLIENT_IS_ARCHIVE_SUCCESS,
            payload: {errorMessage, clientId},
        });
    } catch (error) {
        yield put({
            type: CLIENT_IS_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

//POST /api/clients/updateNmContract

export const updateNmContractSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;


        const result = yield request.post(`${controller}/updateNmContract`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_UPDATE_NM_CONTRACT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Информация успешно отредактирована");
        yield put({
            type: CLIENT_UPDATE_NM_CONTRACT_SUCCESS,
            payload: result,
        });
        const {clientId} = payload;

        yield put(getClientById(clientId));

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_UPDATE_NM_CONTRACT_ERROR,
            payload: error,
        });
    }
};
//POST /api/clientDeposit/updateClientDepositFavorite
export const updateClientFavoriteSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/updateClientFavorite/`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: CLIENT_UPDATE_FAVORITE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CLIENT_UPDATE_FAVORITE_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: CLIENT_UPDATE_FAVORITE_ERROR, payload: error.message});
    }
};

//POST /api/plutonium/client/addClientBank
export const clientBankRegistrationSaga = function* (action) {
    try {
        const {
            payload: {
                clientId,
            },
        } = action;
        const result = yield request.post(`/plutonium/client/addClientBank?clientId=${clientId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_REGISTRATION_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Компания успешно зарегистрирована в банке");

        yield put({
            type: CLIENT_REGISTRATION_BANK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_REGISTRATION_BANK_ERROR,
            payload: error,
        });
    }
};

//POST /api/clients/{clientId}/prohibitionContractingNonResidents/{check}
export const setProhibitionContractingNonResidentsSaga = function* ({payload}) {
    try {
        const {
            data,
            getSuccess,
        } = payload;
        const {
            clientId,
        } = data;

        const result = yield request.post(`${controller}/${clientId}/prohibitionContractingNonResidents/${data[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }
        if (getSuccess) {
            getSuccess();
        }

        yield put({
            type: CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/clientIdNameMap
export const getClientIdNameMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/users/clientIdNameMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_NAME_MAP_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CLIENT_NAME_MAP_READ_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: CLIENT_NAME_MAP_READ_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/roleMap
export const getRoleMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/users/roleMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_ROLE_MAP_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CLIENT_ROLE_MAP_READ_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: CLIENT_ROLE_MAP_READ_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/countOfClientsForPushNotifications
export const getCountOfClientsForPushNotificationsSaga = function* ({payload}) {
    try {
        const {
            data,
            handleResponse,
        } = payload;

        const result = yield request.post(`${controller}/users/countOfClientsForPushNotifications`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/retailClientIdNameMap
export const getRetailClientIdNameMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/users/retailClientIdNameMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RETAIL_CLIENT_NAME_MAP_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: RETAIL_CLIENT_NAME_MAP_READ_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: RETAIL_CLIENT_NAME_MAP_READ_ERROR,
            payload: error,
        });
    }
};

//POST /api/clients/getPageWithPartners
export const getClientAndPartnersListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPageWithPartners`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_AND_PARTNERS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_AND_PARTNERS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_AND_PARTNERS_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET/api/clients/getClientsByPartner
export const getClientListByPartnerSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPartnerClientPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            console.error("getClientListByPartnerSaga:", errorMessage);

            yield put({
                type: CLIENT_LIST_BY_PARTNER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_LIST_BY_PARTNER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_AND_PARTNERS_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET/api/clients/{clientId}/terminateContractInfo
export const getTerminateContractInfoSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.get(`${controller}/${payload}/terminateContractInfo`);
        const {errorMessage} = result;

        if (errorMessage) {
            console.error("getTerminateContractInfoSaga:", errorMessage);

            yield put({
                type: GET_TERMINATE_CONTRACT_INFO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_TERMINATE_CONTRACT_INFO_SUCCESS,
            payload: result.results,
        });
    } catch (error) {
        yield put({
            type: GET_TERMINATE_CONTRACT_INFO_ERROR,
            payload: error,
        });
    }
};

// GET/api/clients/{clientId}/fileNotice/{fileId}
export const getFileNoticeSaga = function* ({payload}) {
    try {
        const {
            clientId,
            field,
            name,
            isDownload,
        } = payload;

        const requestOptions = {
            method: "get",
        };
        const response = yield request.getFile(`${window.location.origin}/api${controller}/${clientId}/fileNotice/${field}`, requestOptions);

        const blob = yield response.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        if (isDownload) {
            tempLink.setAttribute("download", name);
        } else {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

    } catch (error) {
        yield put({
            type: GET_FILE_NOTICE_ERROR,
            payload: error,
        });
    }
};

export const getOptimizedClientListSaga = function* ({payload}) {
    try {
        const {
            isCustomRequest,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/getOptimizedPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        if (isCustomRequest) {
            yield put({
                type: CLIENT_CUSTOM_LIST_READ_SUCCESS,
                payload: result,
            });
        } else {
            yield put({
                type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS,
                payload: result,
            });
        }
    } catch (error) {
        yield put({
            type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
        });
    }
};

export const getBffClientListOptimizedSaga = function* ({payload}) {
    try {
        const {
            isCustomRequest,
            url,
            ...reqData
        } = payload;
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        if (isCustomRequest) {
            yield put({
                type: CLIENT_CUSTOM_LIST_READ_SUCCESS,
                payload: result,
            });
        } else {
            yield put({
                type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS,
                payload: result,
            });
        }
    } catch (error) {
        yield put({
            type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
        });
    }
};

//POST /api/clients/users/getRelatedClientUsers
export const getRelatedClientUsersSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/users/getRelatedClientUsers`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RELATED_CLIENT_USERS_ERROR,
            });

            return {
                done: true,
            };
        }


        yield put({
            type: GET_RELATED_CLIENT_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_RELATED_CLIENT_USERS_ERROR,
        });
    }
};

//POST  /bff/adm/client-groups/{groupId}/users/getPage
export const getGroupMembersPageSaga = function* ({payload}) {
    try {
        const {
            clientGroupId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`/adm/client-groups/${clientGroupId}/users/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_GROUP_MEMBERS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_GROUP_MEMBERS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: GET_GROUP_MEMBERS_PAGE_ERROR,
            payload: error,
        });
    }
};

//GET /bff/adm/client-groups/clientUsers/{id}/relatedUsers
export const getGroupMemberRelatedUsersSaga = function* ({payload}) {
    try {
        const {
            clientUserId,
        } = payload;

        const result = yield request.bff.get(`/adm/client-groups/clientUsers/${clientUserId}/relatedUsers`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_GROUP_MEMBER_RELATED_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_GROUP_MEMBER_RELATED_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: GET_GROUP_MEMBER_RELATED_USERS_ERROR,
            payload: error,
        });
    }
};

//GET /bff/adm/client-groups/clientUsers/{id}/relatedUsers/setEnabled
export const setGroupMemberRelatedUsersSaga = function* ({payload}) {
    try {
        const {
            clientUserId,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`/adm/client-groups/clientUsers/${clientUserId}/relatedUsers/setEnabled`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_GROUP_MEMBER_RELATED_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SET_GROUP_MEMBER_RELATED_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: SET_GROUP_MEMBER_RELATED_USERS_ERROR,
            payload: error,
        });
    }
};

//POST  /api/clients/users/getPage
export const getMembersPageForGroupSaga = function* ({payload}) {
    try {

        const result = yield request.post(`${controller}/users/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_MEMBERS_PAGE_FOR_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: GET_MEMBERS_PAGE_FOR_GROUP_ERROR,
            payload: error,
        });
    }
};

//POST  /api/clients/users/addToGroup/{clientGroupId}
export const addGroupMemberSaga = function* ({payload}) {
    try {
        const {
            clientGroupId,
            requestData,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/users/addToGroup/${clientGroupId}`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_GROUP_MEMBER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Выбранные сотрудники успешно добавлены");
        onSuccess();

        const {
            client: {
                groupMembersPageData,
            },
        } = yield select();

        yield put(getGroupMembersPage(groupMembersPageData));

        yield put({
            type: ADD_GROUP_MEMBER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_GROUP_MEMBER_ERROR,
            payload: error,
        });
    }
};

//POST  /api/clients/users/addToGroup/{clientGroupId}
export const deleteGroupMemberSaga = function* ({payload}) {
    try {

        const {
            requestData,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/users/removeFromGroup`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_GROUP_MEMBER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно удален");
        onSuccess();

        const {
            client: {
                groupMembersPageData,
            },
        } = yield select();

        yield put(getGroupMembersPage(groupMembersPageData));

        yield put({
            type: DELETE_GROUP_MEMBER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_GROUP_MEMBER_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/patentContracts/{clientId}/get
export const getClientContractDetailsSaga = function* ({payload}) {
    const {clientId} = payload;

    try {
        const result = yield request.get(`${controller}/patentContracts/${clientId}/get`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
            payload: error,
        });
    }
};

//PATCH /api/clients/patentContracts/patch
export const updateClientContractDetailsSaga = function* ({payload}) {
    try {
        const {
            clientId,
        } = payload;

        const result = yield request.patch(`${controller}/patentContracts/patch`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);

        yield put(getClientContractDetails({clientId}));

        yield put({
            type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/civilContracts/get
export const getClientCivilContractSaga = function* ({payload}) {
    const {clientId} = payload;

    try {
        const result = yield request.get(`${controller}/civilContracts/get?clientId=${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_CIVIL_CONTRACT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_CIVIL_CONTRACT_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENT_CIVIL_CONTRACT_ERROR,
            payload: error,
        });
    }
};

//PATCH /api/clients/civilContracts/patch
export const updateClientCivilContractSaga = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;
    const {
        clientId,
    } = requestData;

    try {
        const result = yield request.patch(`${controller}/civilContracts/patch`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_CIVIL_CONTRACT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);

        yield put(getClientCivilContract({clientId}));
        yield put(getClientById(clientId));

        yield put({
            type: UPDATE_CLIENT_CIVIL_CONTRACT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_CIVIL_CONTRACT_ERROR,
            payload: error,
        });
    }
};

export const getClientNameSListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`adm/common/filters${controller}/names/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_NAMES_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_GET_NAMES_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_GET_NAMES_LIST_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_READ_REQUEST, clientReadSaga),
        takeEvery(CLIENT_LIST_READ_REQUEST, clientListReadSaga),
        takeEvery(CLIENT_UPDATE_REQUEST, updateClientSaga),
        takeEvery(CLIENT_ADD_REQUEST, addClientSaga),
        takeEvery(CLIENT_SET_ARCHIVED_REQUEST, clientSetArchivedSaga),
        takeEvery(CLIENT_TERMINATE_CONTRACT_REQUEST, terminateContractClientSaga),
        takeEvery(CLIENT_CURRENT_READ_REQUEST, clientCurrentReadSaga),
        takeEvery(CLIENT_GET_COMPANIES_REQUEST, getCompaniesSaga),
        takeEvery(CLIENT_SET_LOCKED_REQUEST, clientSetLockedSaga),
        takeEvery(CLIENT_IS_ARCHIVE_REQUEST, getIsArchivedSaga),
        takeEvery(CLIENT_UPDATE_NM_CONTRACT_REQUEST, updateNmContractSaga),
        takeEvery(CLIENT_UPDATE_FAVORITE_REQUEST, updateClientFavoriteSaga),
        takeEvery(CLIENT_REGISTRATION_BANK_REQUEST, clientBankRegistrationSaga),
        takeEvery(CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST, setProhibitionContractingNonResidentsSaga),
        takeEvery(CLIENT_NAME_MAP_READ_REQUEST, getClientIdNameMapSaga),
        takeEvery(CLIENT_ROLE_MAP_READ_REQUEST, getRoleMapSaga),
        takeEvery(CLIENT_ENABLED_FOREMAN_FLAG_REQUEST, isEnabledForemanFlagSaga),
        takeEvery(CLIENT_GROUP_PAGE_REQUEST, getClientGroupPageSaga),
        takeEvery(GET_COUNT_OF_CLINTS_FOR_PUSH_NOTIFICATION_REQUEST, getCountOfClientsForPushNotificationsSaga),
        takeEvery(RETAIL_CLIENT_NAME_MAP_READ_REQUEST, getRetailClientIdNameMapSaga),
        takeEvery(CLIENT_AND_PARTNERS_PAGE_REQUEST, getClientAndPartnersListSaga),
        takeEvery(CLIENT_LIST_BY_PARTNER_REQUEST, getClientListByPartnerSaga),
        takeEvery(GET_TERMINATE_CONTRACT_INFO_REQUEST, getTerminateContractInfoSaga),
        takeEvery(GET_FILE_NOTICE_REQUEST, getFileNoticeSaga),
        takeEvery(CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST, getOptimizedClientListSaga),
        takeEvery(BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST, getBffClientListOptimizedSaga),
        takeEvery(GET_RELATED_CLIENT_USERS_REQUEST, getRelatedClientUsersSaga),
        takeEvery(GET_GROUP_MEMBERS_PAGE_REQUEST, getGroupMembersPageSaga),
        takeEvery(GET_MEMBERS_PAGE_FOR_GROUP_REQUEST, getMembersPageForGroupSaga),
        takeEvery(ADD_GROUP_MEMBER_REQUEST, addGroupMemberSaga),
        takeEvery(DELETE_GROUP_MEMBER_REQUEST, deleteGroupMemberSaga),
        takeEvery(GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST, getClientContractDetailsSaga),
        takeEvery(UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST, updateClientContractDetailsSaga),
        takeEvery(CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST, checkPaymentsCreationProhibitedSaga),
        takeEvery(GET_CLIENT_CIVIL_CONTRACT_REQUEST, getClientCivilContractSaga),
        takeEvery(UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST, updateClientCivilContractSaga),
        takeEvery(GET_GROUP_MEMBER_RELATED_USERS_REQUEST, getGroupMemberRelatedUsersSaga),
        takeEvery(SET_GROUP_MEMBER_RELATED_USERS_REQUEST, setGroupMemberRelatedUsersSaga),
        takeEvery(CLIENT_GET_NAMES_LIST_REQUEST, getClientNameSListSaga),
    ]);
}
