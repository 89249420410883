import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

export const useSettingsDirectoriesKeywordsFilter = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;
    const {t} = useTranslation();
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            valueFilter: "",
        },
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "valueFilter",
                        label: t("keyword.keyword-filter-label"),
                    },
                ],
            },
        ];
    }, []);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    };
};