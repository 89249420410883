import React, {FC, ReactNode} from "react";

import Text from "../Text";

interface IColumnLabelTextValues {
    values: Array<{
        label: string,
        text: string | ReactNode,
        isVisible?: boolean,
    }>
}

const ColumnLabelTextValues: FC<IColumnLabelTextValues> = (props) => {
    const {
        values,
    } = props;

    return (
        <div className="column-label-text-values">
            {
                values.filter(item => {
                    const {isVisible = true} = item;

                    return isVisible;
                }).map((item, index) => {
                    return (
                        <React.Fragment key={item.label}>
                            <Text
                                type="sub"
                                level="1"
                                className={`mb-2 ${index !== 0 ? "mt-3" : ""}`}
                                medium
                            >
                                {item.label}
                            </Text>
                            {
                                typeof item.text === "string" ?
                                    <Text level="2">
                                        {item.text}
                                    </Text> :
                                    item.text
                            }
                        </React.Fragment>
                    );
                })
            }
        </div>
    );
};

export default ColumnLabelTextValues;