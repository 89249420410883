export const KEDO_TEMPLATE_DATA_TYPES = {
    STRING: "STRING", // Текстовое поле ввода
    INTEGER: "INTEGER", // Поле ввода целых чисел
    DOUBLE: "DOUBLE", // Поле ввода дробных чисел
    MONEY: "MONEY", // Поле ввода денежного формата
    STRING_SELECTION: "STRING_SELECTION", // Поле выбора
    MULTIPLE_STRING_SELECTION: "MULTIPLE_STRING_SELECTION", // Поле мультивыбора
    DATE: "DATE", // Поле даты
    DATE_PERIOD: "DATE_PERIOD", // Поле периода
};

export const KEDO_TEMPLATE_DATA_TYPES_DICT = {
    STRING: "Текстовое поле ввода", // Текстовое поле ввода
    INTEGER: "Поле ввода целых чисел", // Поле ввода целых чисел
    DOUBLE: "Поле ввода дробных чисел", // Поле ввода дробных чисел
    MONEY: "Поле ввода денежного формата", // Поле ввода денежного формата
    STRING_SELECTION: "Поле выбора", // Поле выбора
    MULTIPLE_STRING_SELECTION: "Поле мультивыбора", // Поле мультивыбора
    DATE: "Поле даты", // Поле даты
    DATE_PERIOD: "Поле периода", // Поле периода
};

export const KEDO_TEMPLATE_RESTRICTION_TYPE = {
    STRING_SIZE: "STRING_SIZE", // Максимальное / минимальное количество символов
    NUMBER_SIZE: "NUMBER_SIZE", // Максимальное значение / Минимальное значение
    AVAILABLE_VALUES: "AVAILABLE_VALUES", // Доступные варианты значений
};