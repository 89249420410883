import {useState} from "react";

export const useSnilsInfoCardAction = ({}) => {
    const [isOpenEditForm, setOpenEditForm] = useState(false);

    const toggleEditModal = () => {
        setOpenEditForm(!isOpenEditForm);
    };

    return {
        isOpenEditForm,
        toggleEditModal,
    };
};