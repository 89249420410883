import React, {useMemo} from "react";

import NmButton from "../../../components/NmButton";

import {isPromoPoiskPage} from "../../../utils/url";

export const LoginButton = (props) => {
    const  {
        secondary,
        children,
        ...otherProps
    } = props;

    const isPromopoisk = isPromoPoiskPage();

    const color = useMemo(() => {
        if (secondary) {
            if (isPromopoisk) {
                return "promo-poisk-secondary";
            }

            return "open-naimix-secondary";
        }

        if (isPromopoisk) {
            return "promo-poisk-primary";
        }

        return "open-naimix-primary";
    }, [isPromopoisk]);

    return (
        <NmButton
            openNaimix={!isPromopoisk}
            promoPoisk={isPromopoisk}
            color={color}
            {...otherProps}
        >
            {children}
        </NmButton>
    );
};