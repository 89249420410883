
import {
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST,
} from "./actions";

export const updateRecruitmentAccessControlObserver = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST,
        payload,
    };
};

export const getRecruitmentAccessControlObserverList = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST,
        payload,
    };
};

export const getRecruitmentAccessControlVacancies = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST,
        payload,
    };
};

export const addRecruitmentAccessControlObserver = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST,
        payload,
    };
};

export const updateRecruitmentAccessControlObserverStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_STORE,
        payload,
    };
};