import {
    BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_ERROR, BFF_BOT_UPDATE_STORE,
} from "./actions";

const initialState = {
    progress: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case BFF_BOT_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    case BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    default: return state;
    }
};