import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import {Button} from "semantic-ui-react";

import PropTypes from "prop-types";

import "./style.sass";

class PageSizePagination extends Component {
    static propTypes = {
        handleOnChangePageSize: PropTypes.func,
        pageSizes: PropTypes.arrayOf(PropTypes.number),
        activeSize: PropTypes.number,
        floated: PropTypes.string,
        totalCount: PropTypes.number,
        className: PropTypes.string,
    };

    static defaultProps = {
        pageSizes: [25, 50, 100],
        handleOnChangePageSize: () => {
        },
        activeSize: 0,
        floated: "right",
        itemsCountTotal: 0,
        className: "",
    };

    renderButtons() {
        const {
            pageSizes,
            handleOnChangePageSize,
            activeSize,
        } = this.props;

        return pageSizes.map((item, index) => (
            <Button
                key={index}
                basic
                active={activeSize === item}
                onClick={ e => {
                    e.preventDefault();

                    handleOnChangePageSize(item);
                }}
            >
                {item}
            </Button>
        ),
        );
    }

    render() {
        const {
            floated, 
            t,
            pageSizes,
            totalCount,
            className, 
        } = this.props;

        if (totalCount <= pageSizes[0]) {
            return null;
        }

        return (
            <div
                className={`${className} nmx-size-pag`}
            >
                <div
                    className="nmx-size-pag__label-before"
                >
                    {t("pagination.data-before")}
                </div>
                <Button.Group
                    className="nmx-size-pag__buttons"
                    floated={floated}
                >
                    {this.renderButtons()}
                </Button.Group>
            </div>
        );
    }
}

export default withTranslation()(PageSizePagination);
