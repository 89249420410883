import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getSpecialitiesPage,
    updateSpecialitiesStore,
} from "../../../../../ducks/speciality";

export const useSpecialitiesFetch = ({filterData, pageNum, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(updateSpecialitiesStore({list: []}));
        };
    }, []);

    const fetchList = () => {
        dispatch(getSpecialitiesPage({
            ...filterData,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};