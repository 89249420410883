import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {
    getFileContractorScan,
    updateContractorScanFieldFilesStore,
    updateFieldFilesStore,
} from "../../../../../ducks/fileStore";

export function useContractorEventLogOrderHistoryCard() {
    const [selectedItem, setSelectedItem] = useState({});
    const [isOpenCard, setIsOpenCard] = useState(false);
    const [loadingScans, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(selectedItem)) {
            fetchScans();
        }

    }, [selectedItem]);

    useEffect(() => {
        if (!isOpenCard) {
            setSelectedItem({});
            clearScans();
        }

        return () => {
            clearScans();
        };
    }, [isOpenCard]);

    const toggleCard = (item = {}) => {
        setSelectedItem(item);
        setIsOpenCard(!isOpenCard);
    };

    const clearScans = () => {
        dispatch(updateFieldFilesStore({eventLogContractorOriginalScanObj: {}}));
    };

    function fetchScans(thumbnail = false) {
        const {logs = []} = selectedItem;

        const {pendingPersonalDataModel = {}, contractorId} = logs[logs.length - 1] || {};
        const {
            fileInfoDecisions,
        } = pendingPersonalDataModel;

        const _files = fileInfoDecisions || [];

        if (!isEmpty(_files)) {
            setLoading(true);
        }

        _files.forEach(({type, fileId, status}) => {
            const isStatusDeleted = status === "DELETED";

            dispatch(
                getFileContractorScan({
                    contractorId,
                    contractorFileType: type,
                    fileId: isStatusDeleted ? fileId : undefined,
                    isPending: !isStatusDeleted,
                    includingPending: true,
                    thumbnail,
                    field: "eventLogContractorOriginalScanObj",
                    onSuccess: () => {
                        setLoading(false);
                    },
                    onError: () => {
                        setLoading(false);
                    },
                }),
            );
        });
    }

    return {
        selectedItem,
        isOpenCard,
        toggleCard,
        loadingScans,
    };
}