import React, {ReactNode} from "react";

import NmModalCard, {INmModalCard} from "../Card";

import bem from "../../../../utils/bem";

import "./style.sass";

interface INmModalCardList {
    list: INmModalCard[],
    className?: string,
    pagination?: ReactNode,
    classNameMainContent?: string,
}

const NmModalCardList = (props: INmModalCardList) => {
    const {
        list,
        pagination,
        className = "",
        classNameMainContent,
    } = props;
    const [block, element] = bem("nm-modal-card-list", className);

    return (
        <div className={block()}>
            <div className={element("content")}>
                {
                    list.map((card, index) => {
                        const {
                            values,
                            singleValues,
                            actions,
                            additionalTitleValue,
                            className = "",
                            icon,
                            title,
                            mainContent,
                        } = card;

                        return (
                            <NmModalCard
                                key={index}
                                values={values}
                                singleValues={singleValues}
                                additionalTitleValue={additionalTitleValue}
                                classNameMainContent={classNameMainContent}
                                className={`nm-modal-card-list__card ${className}`}
                                title={title}
                                actions={actions}
                                icon={icon}
                                mainContent={mainContent}
                            />
                        );
                    })
                }
            </div>
            {
                pagination &&
                <div className={element("pagination")}>
                    {pagination}
                </div>
            }
        </div>
    );
};

export default NmModalCardList;