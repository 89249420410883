import React from "react";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";
import {KedoDocumentsHistory} from "./components/history";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useKedoDocumentsAction} from "./hooks/useAction";
import {useKedoDocumentsBff} from "./hooks/useBff";
import {useKedoDocumentsDicts} from "./hooks/useDicts";
import {useKedoDocumentsFetch} from "./hooks/useFetch";
import {useDocumentsFilters} from "./hooks/useFilters";

import {formatLocalDate} from "../../../utils/dateFormat";
import {openLinkByUrl} from "../../../utils/downloadBlob";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFormattedFullName, getFullName} from "../../../utils/stringFormat";
import {getKedoParticipantDocumentStatus} from "./utils/getParticipantStatus";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";
import {
    DOCUMENT_SOURCE_TYPES,
    DOCUMENT_UPLOAD_SOURCES,
    KEDO_DOCUMENT_STATUS,
    KEDO_DOCUMENTS_BADGE_MOD_DICT,
} from "./constants";

export const KedoDocuments = (props) => {
    const clientId = props.match.params.clientId;

    const {
        documentFileSourceTypesDict,
        documentStatesDict,
    } = useKedoDocumentsDicts();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useDocumentsFilters({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useKedoDocumentsFetch({
        pageNum,
        pageSize,
        filterData,
        clientId,
    });

    const role = ls(USER_ROLE);

    const {
        totalCount,
        progressAction,
        progress,
        list,
        totalPages,
    } = useKedoDocumentsBff();

    const {
        isOpen: isOpenHistory,
        onOpenModal: onOpenHistoryModal,
        modalData: historyModalData,
        onCloseModal: onCloseHistoryModal,
    } = useModal();

    const {
        deleteDocument,
        onCloseConfirm,
        onOpenConfirm,
        isOpenConfirm,
        download,
        exportDocuments,
        confirmData,
    } = useKedoDocumentsAction({
        fetchList,
        clientId,
    });

    const getConfirmWindow = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                onCancel={onCloseConfirm}
                disabled={progressAction}
                {...confirmData}
            />
        );
    };

    const getMediaControls = (document) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "light-green",
                        icon: <FileDownloadIcon />,
                        onlyIcon: true,
                        onClick: () => {
                            download(document);
                        },
                    },
                    asset: {
                        mobile: {children: "Скачать"},
                        tablet: {children: "Скачать"},
                    },
                    visible: [CLIENT_ADMIN].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        children: "История",
                        onClick: () => {
                            onOpenHistoryModal(document);
                        },
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, NM_OPERATOR].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        children: "Дополнительные сведения",
                        onClick: () => {
                            onOpenConfirm({
                                title: "Дополнительные сведения “Документ на подписание”",
                                content: document.description,
                                confirmButton: "Понятно",
                                contentAlign: "left",
                                titleAlign: "left",
                                buttonsAlign: "right",
                            });
                        },
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, NM_OPERATOR].includes(role)
                        && document.description,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        children: "Удалить",
                        onClick: () => {
                            onOpenConfirm({
                                isHiddenCancelOnMobile: true,
                                content: "Вы действительно хотите удалить черновик?",
                                confirmButton: "Да",
                                cancelButton: "Нет",
                                isNeedClosing: false,
                                onConfirm: () => {
                                    deleteDocument(document.documentId);
                                },
                            });
                        },
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role) &&
                        [
                            KEDO_DOCUMENT_STATUS.DRAFT,
                        ].includes(document.state),
                },
            ],
        };
    };

    const getCreator = (item) => {
        const source = item.fileUploadSourceType ? `(${documentFileSourceTypesDict[item.fileUploadSourceType]}) ` : "";
        const name = getFullName(
            item.creator?.lastName,
            item.creator?.firstName,
            item.creator?.patronymic,
        );

        return `${source}${name}`;
    };

    const getSender = (document) => {
        if (!document.sender) {
            return null;
        }

        const sender = getKedoParticipantDocumentStatus({
            status: document.sender.state,
            date: document.sender.signedAt,
            errorMessage: document.sender.gosKeyErrorMessage,
            rejectReason: document.sender.rejectReason,
            isShowOnSignGosKey: document.documentSourceType !== DOCUMENT_SOURCE_TYPES.FROM_COMPANY &&
                document.fileUploadSourceType !== DOCUMENT_UPLOAD_SOURCES.ONE_C,
            isSender: true,
        });

        return renderBlockSignStatus(sender);
    };

    const getReceiver = (document) => {
        if (!document.receiver) {
            return null;
        }

        const receiver = getKedoParticipantDocumentStatus({
            status: document.receiver.state,
            date: document.receiver.signedAt,
            errorMessage: document.receiver.gosKeyErrorMessage,
            rejectReason: document.receiver.rejectReason,
        });

        return renderBlockSignStatus(receiver);
    };

    const renderBlockSignStatus = (data) => {
        if (!data) {
            return;
        }

        return (
            <NmAdvancedTooltip
                hover
                isTriggerListCardIcon={true}
                type="light"
                className="d-flex me-1"
                children={
                    <div>
                        <Text
                            color={data.titleColor}
                            level="2"
                            type="text"
                        >
                            {data.title}
                        </Text>
                        {
                            data.date && (
                                <div className="d-flex">
                                    <Text
                                        color={COLOR.SECONDARY_70}
                                        level="2"
                                        type="text"
                                    >
                                        {"Дата подписания: "}
                                    </Text>
                                    <Text
                                        color={COLOR.SECONDARY_80}
                                        level="2"
                                        type="text"
                                    >
                                        {data.date}
                                    </Text>
                                </div>
                            )
                        }
                        <Text
                            color={COLOR.BLACK_50}
                            level="2"
                            type="regular"
                        >
                            {data.text}
                        </Text>
                    </div>
                }
                position="bottom-left"
                trigger={
                    React.createElement(data.icon, {
                        color: data.iconColor,
                        width: 18,
                        height: 18,
                    })
                }
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.documentId,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={KEDO_DOCUMENTS_BADGE_MOD_DICT[item.state].TYPE || "gray"}
                                text={documentStatesDict[item.state] || "-"}
                            />
                        }
                        secondaryHeader={`Дата создания ${formatLocalDate(item.createdAt, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={item.name}
                        labels={[
                            {
                                label: "Номер документа",
                                text: item.documentNum || "-",
                                noWrap: false,
                            },
                            {
                                label: "Тип документа",
                                text: item.documentTypeName || "-",
                                noWrap: false,
                            },
                            {
                                label: "Создан",
                                text: getCreator(item),
                                noWrap: false,
                            },
                            {
                                label: "Отправитель",
                                text: (
                                    <>
                                        {getSender(item)}
                                        {
                                            item.sender ?
                                                getFormattedFullName(getFullName(
                                                    item.sender.lastName,
                                                    item.sender.firstName,
                                                    item.sender.patronymic,
                                                )) :
                                                item.clientName
                                        }
                                    </>
                                ),
                                alignItems: "center",
                                noWrap: false,
                            },
                            {
                                label: "Получатель",
                                text: (
                                    <>
                                        {getReceiver(item)}
                                        {
                                            item.receiver ?
                                                getFormattedFullName(getFullName(
                                                    item.receiver.lastName,
                                                    item.receiver.firstName,
                                                    item.receiver.patronymic,
                                                )) :
                                                item.clientName
                                        }
                                    </>
                                ),
                                alignItems: "center",
                                noWrap: false,
                            },
                        ]}
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const getEdit = () => {
        if (!isOpenHistory) {
            return null;
        }

        return (
            <KedoDocumentsHistory
                clientId={clientId}
                documentId={historyModalData.documentId}
                onClose={onCloseHistoryModal}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Документооборот
                </NmTitle>
            }
            mediaControls={{
                renderCount: {
                    mobile: 0,
                    tablet: 2,
                    desktop: 2,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            color: "green",
                            disabled: progressAction,
                            children: `Выгрузить в файл (${totalCount})`,
                            onClick: () => {
                                exportDocuments({
                                    clientIdFilter: clientId,
                                    pageNum,
                                    pageSize,
                                    ...filterData,
                                });
                            },
                        },
                        visible: [CLIENT_ADMIN].includes(role),
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            color: "light-green",
                            children: "Войти в КЭДО",
                            onClick: () => {
                                openLinkByUrl(process.env.REACT_APP_NAIMIX_KEDO);
                            },
                        },
                        visible: [CLIENT_ADMIN].includes(role),
                    },
                ],
            }}
            openFilter
            typeFilter="vertical"
            widthByFilter
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={progress}
        >
            {getEdit()}
            {getConfirmWindow()}
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};