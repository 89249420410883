import React from "react";
import {useSelector} from "react-redux";

import ActCreation from "../../../../../components/ActCreation";

import {
    orderWorkUnitsOptionsSelector,
    unitsAbbreviationDictSelector,
} from "../../../../../ducks/order";

const OrderTemplatesEditActTemplate = (props) => {
    const {
        clientId,
        editActTemplateData,
        form,
        onSubmit,
        formError,
        onClose,
    } = props;
    const progress = useSelector(state => state.orderWorkReportTemplate.actionProgress);
    const abbreviationMap = useSelector(unitsAbbreviationDictSelector);
    const abbreviationOptions = useSelector(orderWorkUnitsOptionsSelector);

    const onChange = (event, {name, value}) => {
        props.onChange(event, {[name]: value});
    };

    return (
        <ActCreation
            isModal={false}
            isTemplateAct={true}
            clientId={clientId}
            orderId={editActTemplateData.orderId}
            handleClose={onClose}
            abbreviationMap={abbreviationMap}
            orderDescription={editActTemplateData.description}
            workUnitPrice={editActTemplateData.workUnitPrice}
            abbreviationOptions={abbreviationOptions}
            amount={editActTemplateData.amount}
            workUnit={editActTemplateData.workUnit}
            volumeOfWork={editActTemplateData.volumeOfWork}
            startOrderDate={editActTemplateData.workStartDate}
            contractorId={editActTemplateData.contractorId}
            orderKind={editActTemplateData.orderKind}
            orderContractPaymentType={editActTemplateData.orderContractPaymentType}
            orderCreatedByTemplateId={editActTemplateData.orderCreatedByTemplateId}
            paymentInfo={{
                contractorVolumePaid: "-",
                orderVolumePaid: "-",
                orderPaid: "-",
            }}
            progress={progress}
            contractorCommission={editActTemplateData.contractorCommission}
            formData={form}
            handleChange={onChange}
            handleOnRate={onChange}
            submit={onSubmit}
            formError={{
                ...formError,
                description: formError.actDescription,
            }}
            workQualityScore={form.workQualityScore}
            estimatedTimeScore={form.estimatedTimeScore}
        />
    );
};

export default OrderTemplatesEditActTemplate;