import React from "react";

import "./style.sass";

const ContractorVerificationTextFields = (props) => {
    const {
        children,
    } = props;

    return (
        <div className="contractor-verification-text-fields">
            {children}
        </div>
    );
};

export default ContractorVerificationTextFields;