import React, {FC, FunctionComponent} from "react";

import "./style.sass";

interface IconButton extends Partial<SVGElement> {
    icon: FunctionComponent<SVGElement>,
    onClick: () => void,
}

export const IconButton: FC<IconButton> = (props) => {
    const {
        icon,
        className,
        ...otherProps
    } = props;

    const elementProps = {
        className: `${className} icon-button`,
        ...otherProps,
    } as SVGElement;

    return React.createElement(icon, elementProps);
};
