import React, {useState} from "react";
import {isEqual} from "lodash";

import {ClientSettingsContextProvider} from "./components/ContextProvider";
import {ClientSettingsForm} from "./components/Form";

import {CLIENT_SETTINGS_TAB_NAME} from "./constants";

export const ClientSettings = (props) => {
    const {
        onClose,
        clientId,
        name,
    } = props;

    const [tab, setTab] = useState({
        name: "",
        text: "",
        isChangedData: false,
    });

    const updateChanged = (data) => {
        const {text, ...otherTabInfo} = tab;

        if (isEqual(data, otherTabInfo)) {
            return;
        }

        setTab({
            ...data,
            text: CLIENT_SETTINGS_TAB_NAME[data.name],
        });
    };

    return (
        <ClientSettingsContextProvider
            updateChanged={updateChanged}
            tab={tab}
        >
            <ClientSettingsForm
                onClose={onClose}
                clientId={clientId}
                name={name}
            />
        </ClientSettingsContextProvider>
    );
};