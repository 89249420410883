import React from "react";

import Text from "../../../../../components/ActualComponents/Text";

import {COLOR} from "../../../../../constants/color";

import "./style.sass";

const ContractorVerificationInfoBlockRow = (props) => {
    const {
        list,
        className = "",
    } = props;

    return (
        <div className={`contractor-verification-info-block-row ${className}`}>
            {
                list.filter(value => value).map((item, index) => {
                    const {
                        title,
                        value = "-",
                        tooltip,
                    } = item;

                    return (
                        <div
                            key={index}
                            className="contractor-verification-info-block-row__item"
                        >
                            <Text
                                noWrap
                                level="4"
                                color={COLOR.SECONDARY_70}
                                className="me-1"
                            >
                                {title}
                            </Text>
                            <Text.Title
                                level="4"
                                color={COLOR.SECONDARY_90}
                            >
                                {value}
                            </Text.Title>
                            {
                                tooltip &&
                                <div className="ms-1">
                                    {tooltip}
                                </div>
                            }
                        </div>
                    );
                })
            }
        </div>
    );
};

export default ContractorVerificationInfoBlockRow;