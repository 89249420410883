import * as yup from "yup";

import {REG_EXP} from "../../constants/regExp";
import {requiredMessage} from "../../constants/validationRules";

export const getEmailValidation = () => {
    return yup
        .string()
        .required(requiredMessage)
        .max(255, "Не более 255 символов")
        .matches(REG_EXP.EMAIL, "Введен некорректный email");
};