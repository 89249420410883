import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

export const isPartiallyCheckedProject = (params) => {
    const {
        project,
        noSearchProject,
        isSearch,
        data,
    } = params;

    const nextAllPagesSelectionType = isSearch ?
        project.nextAllPagesSelectionType :
        noSearchProject.nextAllPagesSelectionType;

    if (
        typeof project.changedCheckedValue === "boolean"
        && project.changedCheckedValue !== data.checked
        && nextAllPagesSelectionType
    ) {
        return true;
    }

    const isNotEqualObjectsInProject = project.children.some(item => item.checked !== data.checked);

    if (isNotEqualObjectsInProject) {
        return true;
    }

    if (
        data.checked
        && [
            CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
            CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY,
        ].includes(nextAllPagesSelectionType)
    ) {
        return true;
    }

    if (
        !data.checked
        && [
            CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
            CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
        ].includes(nextAllPagesSelectionType)
    ) {
        return true;
    }

    return false;
};