export const CHAT_ACTION = {
    BLOCK: "BLOCK",
    UNBLOCK: "UNBLOCK",
    INVITE: "INVITE",
    OPEN_DISPUTE_SETTINGS: "OPEN_DISPUTE_SETTINGS",
    CLOSE_DISPUTE: "CLOSE_DISPUTE",
    PHONE: "PHONE",
};

export const CHAT_OPTION = {
    BLOCK: {
        key: CHAT_ACTION.BLOCK,
        text: "Заблокировать",
        value: CHAT_ACTION.BLOCK,
    },
    UNBLOCK: {
        key: CHAT_ACTION.UNBLOCK,
        text: "Разблокировать",
        value: CHAT_ACTION.UNBLOCK,
    },
    INVITE: {
        key: CHAT_ACTION.INVITE,
        text: "Пригласить",
        value: CHAT_ACTION.INVITE,
    },
    OPEN_DISPUTE_SETTINGS: {
        key: CHAT_ACTION.OPEN_DISPUTE_SETTINGS,
        text: "Параметры спора",
        value: CHAT_ACTION.OPEN_DISPUTE_SETTINGS,
    },
    CLOSE_DISPUTE: {
        key: CHAT_ACTION.CLOSE_DISPUTE,
        text: "Закрыть спор",
        value: CHAT_ACTION.CLOSE_DISPUTE,
    },
    PHONE: {
        key: CHAT_ACTION.PHONE,
        text: "Скопировать телефон",
        value: CHAT_ACTION.PHONE,
    },
};