import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {
    closeRecruitmentVacancy,
    getVacancyJobBoards,
} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";

import {useModal} from "../../../../../hooks/useModal";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../constants";

export const useCloseVacancy = (params) => {
    const {
        clientId,
        fetchCallback,
        setConfirmData,
    } = params;

    const dispatch = useDispatch();

    const {
        isOpen: isOpenJobBoards,
        modalData,
        onOpenModal: onOpenJobBoards,
        onCloseModal,
    } = useModal();

    const closeVacancy = ({vacancy, jobBoardTypes}) => {
        const {
            vacancyId,
        } = vacancy;

        if (isOpenJobBoards) {
            onCloseModal();
        }

        dispatch(closeRecruitmentVacancy({
            clientId,
            vacancyId,
            jobBoardTypes,
            onSuccess: () => {
                toastSuccess("Вакансия успешно закрыта");
                fetchCallback();
            },
        }));
    };

    const fetchJobBoards = (vacancy) => {
        dispatch(getVacancyJobBoards({
            id: vacancy.vacancyId,
            onSuccess: ({result}) => {
                const externalJobBoards = result?.filter(type => type !== RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX);

                if (!isEmpty(externalJobBoards)) {
                    onOpenJobBoards({
                        vacancy,
                    });

                    return;
                }

                setConfirmData({
                    vacancy,
                    onConfirm: () => {
                        closeVacancy({vacancy});
                    },
                    content: "Вы действительно хотите закрыть вакансию?",
                });
            },
        }));
    };

    return {
        fetchJobBoards,
        isOpenJobBoards,
        onCloseModal,
        closeVacancy,
        modalData,
    };
};