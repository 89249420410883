import {convertUtcToLocal, getEndDate} from "../../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

export const handleFinanceBankFilter = (filter) => {
    const {
        fromDateFilter,
        toDateFilter,
        operationTypeFilter,
        clientIdFilter,
    } = filter;

    return {
        fromDateFilter: fromDateFilter ? convertUtcToLocal(new Date(fromDateFilter)) : undefined,
        toDateFilter: toDateFilter ? convertUtcToLocal(getEndDate(new Date(toDateFilter))) : undefined,
        operationTypeFilter: operationTypeFilter === "all" ? undefined : operationTypeFilter,
        clientIdFilter: isNullOrWhitespace(clientIdFilter) ? undefined : clientIdFilter,
    };
};