import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {downloadBlob} from "../utils/downloadBlob";
import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/tariff/scale";

const TARIFF_SCALE_GET_PAGE_REQUEST = "TARIFF_SCALE_GET_PAGE_REQUEST";
const TARIFF_SCALE_GET_PAGE_SUCCESS = "TARIFF_SCALE_GET_PAGE_SUCCESS";
const TARIFF_SCALE_GET_PAGE_ERROR = "TARIFF_SCALE_GET_PAGE_ERROR";

const TARIFF_SCALE_ADD_REQUEST = "TARIFF_SCALE_ADD_REQUEST";
const TARIFF_SCALE_ADD_SUCCESS = "TARIFF_SCALE_ADD_SUCCESS";
const TARIFF_SCALE_ADD_ERROR = "TARIFF_SCALE_ADD_ERROR";

const TARIFF_SCALE_DELETE_REQUEST = "TARIFF_SCALE_DELETE_REQUEST";
const TARIFF_SCALE_DELETE_SUCCESS = "TARIFF_SCALE_DELETE_SUCCESS";
const TARIFF_SCALE_DELETE_ERROR = "TARIFF_SCALE_DELETE_ERROR";

const TARIFF_SCALE_UPDATE_REQUEST = "TARIFF_SCALE_UPDATE_REQUEST";
const TARIFF_SCALE_UPDATE_SUCCESS = "TARIFF_SCALE_UPDATE_SUCCESS";
const TARIFF_SCALE_UPDATE_ERROR = "TARIFF_SCALE_UPDATE_ERROR";

const TARIFF_SCALE_CHECK_EXIST_REQUEST = "TARIFF_SCALE_CHECK_EXIST_REQUEST";
const TARIFF_SCALE_CHECK_EXIST_SUCCESS = "TARIFF_SCALE_CHECK_EXIST_SUCCESS";
const TARIFF_SCALE_CHECK_EXIST_ERROR = "TARIFF_SCALE_CHECK_EXIST_ERROR";

const TARIFF_SCALE_GET_BY_ID_REQUEST = "TARIFF_SCALE_GET_BY_ID_REQUEST";
const TARIFF_SCALE_GET_BY_ID_SUCCESS = "TARIFF_SCALE_GET_BY_ID_SUCCESS";
const TARIFF_SCALE_GET_BY_ID_ERROR = "TARIFF_SCALE_GET_BY_ID_ERROR";

const TARIFF_SCALE_IMPORT_REQUEST = "TARIFF_SCALE_IMPORT_REQUEST";
const TARIFF_SCALE_IMPORT_SUCCESS = "TARIFF_SCALE_IMPORT_SUCCESS";
const TARIFF_SCALE_IMPORT_ERROR = "TARIFF_SCALE_IMPORT_ERROR";

const TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST = "TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST";
const TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS = "TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS";
const TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR = "TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR";

const TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST = "TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST";
const TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS = "TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS";
const TARIFF_SCALE_CHECK_DOWNLOAD_ERROR = "TARIFF_SCALE_CHECK_DOWNLOAD_ERROR";

const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST";
const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS";
const TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR = "TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR";

const TARIFF_SCALE_UPDATE_STORE = "TARIFF_SCALE_UPDATE_STORE";

const initialState = {
    list: [],
    card: {},
    progress: false,
    progressCard: false,
    totalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS:
        return {
            ...state,
            scale: payload,
        };
    case TARIFF_SCALE_GET_BY_ID_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case TARIFF_SCALE_GET_BY_ID_ERROR:
        return {
            ...state,
            progressCard: false,
        };
    case TARIFF_SCALE_GET_BY_ID_SUCCESS:
        return {
            ...state,
            progressCard: false,
            card: payload,
        };
    case TARIFF_SCALE_GET_PAGE_REQUEST:
        return {
            ...payload,
            ...state,
        };
    case TARIFF_SCALE_GET_PAGE_SUCCESS:
        const {
            totalCount,
            results,
        } = payload;

        return {
            ...state,
            totalCount,
            list: results,
        };
    case TARIFF_SCALE_IMPORT_REQUEST:
    case TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST:
    case TARIFF_SCALE_CHECK_EXIST_REQUEST:
    case TARIFF_SCALE_UPDATE_REQUEST:
    case TARIFF_SCALE_DELETE_REQUEST:
    case TARIFF_SCALE_ADD_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case TARIFF_SCALE_IMPORT_SUCCESS:
    case TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS:
    case TARIFF_SCALE_CHECK_EXIST_SUCCESS:
    case TARIFF_SCALE_UPDATE_SUCCESS:
    case TARIFF_SCALE_DELETE_SUCCESS:
    case TARIFF_SCALE_ADD_SUCCESS:
    case TARIFF_SCALE_IMPORT_ERROR:
    case TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR:
    case TARIFF_SCALE_CHECK_EXIST_ERROR:
    case TARIFF_SCALE_ADD_ERROR:
    case TARIFF_SCALE_DELETE_ERROR:
    case TARIFF_SCALE_UPDATE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case TARIFF_SCALE_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};

export const getTariffScaleList = (payload) => ({
    type: TARIFF_SCALE_GET_PAGE_REQUEST,
    payload,
});

export const addTariffScale = (payload) => ({
    type: TARIFF_SCALE_ADD_REQUEST,
    payload,
});

export const deleteTariffScale = (payload) => ({
    type: TARIFF_SCALE_DELETE_REQUEST,
    payload,
});

export const updateTariffScale = (payload) => ({
    type: TARIFF_SCALE_UPDATE_REQUEST,
    payload,
});

export const checkExistTariffScale = (payload) => ({
    type: TARIFF_SCALE_CHECK_EXIST_REQUEST,
    payload,
});

export const getByIdTariffScale = (payload) => ({
    type: TARIFF_SCALE_GET_BY_ID_REQUEST,
    payload,
});

export const importTariffScale = (payload) => ({
    type: TARIFF_SCALE_IMPORT_REQUEST,
    payload,
});

export const checkExistByUpdateTariffScale = (payload) => ({
    type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST,
    payload,
});

export const updateTariffScaleStore = (payload) => ({
    type: TARIFF_SCALE_UPDATE_STORE,
    payload,
});

// Выгрузить
export const downloadTariffScale = () => ({
    type: TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST,
});

// Получить ставку по городу, адресу, еденицам измерения
export const getTariffScaleRateByObjectAddress = (payload) => ({
    type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST,
    payload,
});

const tariffSelector = state => state.tariff;
export const tariffScaleCardSelector = createSelector(tariffSelector, ({card}) => card);
export const tariffScaleProgressCardSelector = createSelector(tariffSelector, ({progressCard}) => progressCard);
export const tariffScaleListSelector = createSelector(tariffSelector, ({list}) => list);
export const tariffScaleTotalCountSelector = createSelector(tariffSelector, ({totalCount}) => totalCount);
export const tariffScaleProgressSelector = createSelector(tariffSelector, ({progress}) => progress);
export const tariffScaleTotalPagesSelector = createSelector(tariffSelector, ({totalCount, pageSize = 0}) => getTotalPages(totalCount, pageSize));

//POST /api/tariff/scale/page
export const getTariffScaleListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: TARIFF_SCALE_GET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_GET_PAGE_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/add
export const addTariffScaleListSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...requestData
        } = payload;

        const result = yield request.post(`${controller}/add`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: TARIFF_SCALE_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_ADD_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_ADD_ERROR, payload: error});
    }
};

//DELETE /api/tariff/scale/{id}
export const deleteTariffScaleListSaga = function* ({payload}) {
    const {
        onSuccess,
        tariffScaleId,
    } = payload;

    try {
        const result = yield request.delete(`${controller}/${tariffScaleId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_DELETE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_DELETE_ERROR, payload: error});
    }
};

//PATCH /api/tariff/scale/{id}
export const updateTariffScaleSaga = function* ({payload}) {
    try {
        const {
            tariffScaleId,
            onError,
            onSuccess,
            ...requestData
        } = payload;

        const result = yield request.patch(`${controller}/${tariffScaleId}`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError(result);

            yield put({
                type: TARIFF_SCALE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_UPDATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_UPDATE_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/exist
export const checkExistTariffScaleSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...requestData
        } = payload;

        const result = yield request.post(`${controller}/exist`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_CHECK_EXIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: TARIFF_SCALE_CHECK_EXIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_CHECK_EXIST_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/getById/{id}
export const getByIdTariffScaleSaga = function* ({payload}) {
    try {
        const {
            tariffScaleId,
        } = payload;

        const result = yield request.get(`${controller}/getById?id=${tariffScaleId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_GET_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: TARIFF_SCALE_GET_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_GET_BY_ID_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/import
export const importTariffScaleSaga = function* ({payload}) {
    const {
        formData,
        onSuccess,
        onError,
    } = payload;

    try {
        const result = yield request.post(`${controller}/import`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_IMPORT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: TARIFF_SCALE_IMPORT_SUCCESS, payload: result});
    } catch (error) {
        onError();
        yield put({type: TARIFF_SCALE_IMPORT_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/existByUpdate
export const checkExistByUpdateTariffScaleSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/existByUpdate`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/export/download
export const downloadTariffScaleSaga = function* () {
    try {
        const result = yield request.getFile(`${new URL(window.location.href).origin}/api${controller}/export/download`, {method: "POST"});

        if (result.status === 200 && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);
                return {
                    done: true,
                };
            }
        }

        const fileName = result.headers.get("Content-Disposition").split("filename=")[1];
        const blob = yield result.blob();

        yield downloadBlob(blob, result.headers, fileName);

        yield put({type: TARIFF_SCALE_CHECK_DOWNLOAD_SUCCESS});
    } catch (error) {
        yield put({type: TARIFF_SCALE_CHECK_DOWNLOAD_ERROR, payload: error});
    }
};

//POST /api/tariff/scale/getRate
export const getTariffScaleRateSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/getRateByObjectAddress`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(TARIFF_SCALE_GET_PAGE_REQUEST, getTariffScaleListSaga),
        takeEvery(TARIFF_SCALE_ADD_REQUEST, addTariffScaleListSaga),
        takeEvery(TARIFF_SCALE_DELETE_REQUEST, deleteTariffScaleListSaga),
        takeEvery(TARIFF_SCALE_UPDATE_REQUEST, updateTariffScaleSaga),
        takeEvery(TARIFF_SCALE_CHECK_EXIST_REQUEST, checkExistTariffScaleSaga),
        takeEvery(TARIFF_SCALE_GET_BY_ID_REQUEST, getByIdTariffScaleSaga),
        takeEvery(TARIFF_SCALE_IMPORT_REQUEST, importTariffScaleSaga),
        takeEvery(TARIFF_SCALE_CHECK_EXIST_BY_UPDATE_REQUEST, checkExistByUpdateTariffScaleSaga),
        takeEvery(TARIFF_SCALE_CHECK_DOWNLOAD_REQUEST, downloadTariffScaleSaga),
        takeEvery(TARIFF_SCALE_GET_RATE_BY_OBJECT_ADDRESS_REQUEST, getTariffScaleRateSaga),
    ]);
}