import React from "react";
import {useTranslation} from "react-i18next";
import { useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../../../components/ActualComponents/FilterCustomer";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";

import {
    documentTypeContractorSignatureLogDictsOptionsSelector,
} from "../../../../../../ducks/documents";

import "./style.sass";

function ContractorEventDocumentLogFilter(props) {
    const {
        filter: {
            fromDateFilter,
            toDateFilter,
            documentIdFilter,
            transactionIdFilter,
            documentTypeFilter,
            clientIdFilter,
        },
        filter,
        submitFilter,
        setFilter,
        initFilter,
        onChangeFilter,
    } = props;

    const documentTypeOptions = useSelector(documentTypeContractorSignatureLogDictsOptionsSelector);
    const {t} = useTranslation();

    const submit = () => {
        submitFilter(filter, true);
    };

    const clear = () => {
        submitFilter({
            ...initFilter,
        }, false);

        setFilter({
            ...initFilter,
        });
    };

    return (
        <NmForm addMargin>
            <FilterCustomer
                size="lg"
                onChange={onChangeFilter}
                search
                name="clientIdFilter"
                value={clientIdFilter}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="fromDateFilter"
                endFieldName="toDateFilter"
                value={{fromDateFilter, toDateFilter}}
                isClearable
                label="Период"
                onChange={onChangeFilter}
            />
            <NmInputV2
                size="lg"
                label="Идентификатор документа"
                name="documentIdFilter"
                onChange={onChangeFilter}
                value={documentIdFilter}
            />
            <NmInputV2
                size="lg"
                label="№ лога транзакции"
                name="transactionIdFilter"
                onChange={onChangeFilter}
                value={transactionIdFilter}
            />
            <NmDropdownV2
                size="lg"
                search
                label="Тип документа"
                name="documentTypeFilter"
                onChange={onChangeFilter}
                noResultsMessage={t("no-search-result.content")}
                options={documentTypeOptions}
                value={documentTypeFilter}
            />
            <FilterButtonsV2
                onClear={clear}
                onSearch={submit}
            />
        </NmForm>
    );
}

export default ContractorEventDocumentLogFilter;