import {BANK_PATH} from "../../../../../../constants/clientSettings";

export const getClientSettingsDepositAndPaymentsBlockBanksValues = (blockValues) => {
    const {
        useBankTypeCard,
        useBankTypeRequisites,
        useBankTypeSbp,
        ...otherBlockValues
    } = blockValues;

    if (!blockValues.bankTypeCard) {
        return otherBlockValues;
    }

    const bankTypeCardValues = blockValues.bankTypeCard.split("+");

    return {
        ...otherBlockValues,
        bankTypeCard: bankTypeCardValues[0],
        cardDirect: bankTypeCardValues[1] === BANK_PATH.DIRECT,
    };
};