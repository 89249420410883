import {useState} from "react";
import {useDispatch} from "react-redux";

import {ONBOARDING_SCENARIO_STATUS} from "../../../../../constants/onboarding";

import {downloadDocument} from "../../../../../ducks/documents";
import {updateStatusOnboardingScenario} from "../../../../../ducks/onboarding";

export function useActionOnboardingScenarioCard({clientId, scenarioId, fetchCard}) {
    const dispatch = useDispatch();

    const [modalData, setModalData] = useState({});

    const updateStatus = ({status, replacementScenarioId}) => {
        dispatch(updateStatusOnboardingScenario({
            clientId,
            scenarioId,
            status,
            replacementScenarioId,
            onSuccess: fetchCard,
        }));
    };

    const deactivationScenario = () => {
        setModalData({
            onConfirm: (replacementScenarioId) => {
                updateStatus({
                    status: ONBOARDING_SCENARIO_STATUS.INACTIVE.VALUE,
                    replacementScenarioId,
                });
            },
        });
    };

    const downloadFile = (fileName, downloadLink) => {
        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink,
        }));
    };

    return {
        updateStatus,
        deactivationScenario,
        downloadFile,
    };
}