import React, {useContext} from "react";

import NmConfirmV2 from "../../../../ActualComponents/NmConfirmV2";
import {RecruitmentCandidateInformationContext} from "../../../InformationCard";

export const RecruitmentCandidateInformationDeletingConfirm = (props) => {
    const {
        onCancel,
        content = "Вы уверены, что хотите удалить сведения?",
        onConfirm,
    } = props;

    const {
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    return (
        <NmConfirmV2
            content={content}
            onCancel={onCancel}
            disabled={progressAction}
            isNeedClosing={false}
            onConfirm={onConfirm}
            confirmButton="Подтвердить"
            cancelButton="Отменить"
        />
    );
};
