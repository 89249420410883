import React, {ReactNode} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

export interface INmLabel {
    required?: Boolean
    disabled?: Boolean
    label?: string | object
    isVisibleTooltip?: boolean,
    tooltip?: ReactNode,
    signalTooltip?: ReactNode,
    className?: string
}

const NmLabel = (props: INmLabel) => {
    const {
        required,
        disabled,
        label,
        isVisibleTooltip = true,
        tooltip,
        className = "",
        signalTooltip,
    } = props;
    const [block, element] = bem("nm-label", className);

    return (
        <div className={block()}>
            <label className={element("label", {disabled})}>
                {label}
            </label>
            {
                required &&
                <div className={element("required")}>
*
                </div>
            }
            {
                signalTooltip &&
                <div className={element("label-tooltip")}>
                    {signalTooltip}
                </div>
            }
            {
                tooltip && isVisibleTooltip &&
                <div className={element("label-tooltip")}>
                    {tooltip}
                </div>
            }
        </div>
    );
};

export default NmLabel;