import React, {useState} from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import NmBadge from "../../../../components/NmBadge";
import ContractorMainWorkTypeEditModal from "./components/contractor-main-work-type-edit-modal";

import {contractorMainSpecialitySelector} from "../../../../ducks/contractorProfile";
import {specialitiesAllListV2Selector} from "../../../../ducks/speciality";

export const MAIN_SPECIALITY_OPTION = {
    EDIT: "EDIT",
};

const OPTIONS = [
    {
        key: MAIN_SPECIALITY_OPTION.EDIT,
        text: "Редактировать",
        value: MAIN_SPECIALITY_OPTION.EDIT,
    },
];

export default function ContractorMainWorkType(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [editData, setEditData] = useState({});

    const specialitiesList = useSelector(specialitiesAllListV2Selector);
    const mainSpeciality = useSelector(contractorMainSpecialitySelector);

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const onClickAction = ({value}) => {
        if (value === MAIN_SPECIALITY_OPTION.EDIT) {
            toggleEditModal({mainSpecialityId: specialitiesList.find(it => it.value === mainSpeciality)?.id || ""});
        }
    };

    function mapContent() {
        if (!mainSpeciality) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return (
            <NmBadge
                maxContent
                mod="gray"
                text={mainSpeciality}
            />
        );
    }

    return (
        <>
            {isShowEditModal && <ContractorMainWorkTypeEditModal
                getProfile={getProfile}
                contractorId={contractorId}
                editData={editData}
                onClose={() => toggleEditModal()}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Основной вид деятельности"
                options={OPTIONS}
                onClickItem={onClickAction}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}