import React from "react";
import {useSelector} from "react-redux";

import ContractorInfoNoAccessPersonalData from "../contractor_info/no-access-personal-data";
import ContractorInfoPersonalNew from "../contractor-info-new";

import {ls, USER_ROLE} from "../../../utils/localstorage";

import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";

import {contractorCardSelector} from "../../../ducks/contractor";

const ContractorPersonalData = (props) => {
    const {match: {params: {contractorId}}} = props;

    const contractor = useSelector(contractorCardSelector);
    const {personalDataAccessGranted} = contractor;

    const role = ls(USER_ROLE);
    const isLoaded = Boolean(contractor.contractorId);

    if (!isLoaded) {
        return null;
    }

    if ([CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, HR_MANAGER, RECRUITER].includes(role) && !personalDataAccessGranted) {
        return (
            <ContractorInfoNoAccessPersonalData
                contractorId={contractorId}
            />
        );
    }

    return (
        <ContractorInfoPersonalNew
            {...props}
        />
    );
};

export default ContractorPersonalData;