import React from "react";

import FinancePaymentList from "../finance-payment-list";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";

const FinanceAdminNdflPayments = (props) => {
    return (
        <FinancePaymentList
            {...props}
            orderWorkReportType={ORDER_WORK_REPORT_TYPE.CIVIL}
            header="Оплаты (НДФЛ)"
        />
    );
};

export default FinanceAdminNdflPayments;