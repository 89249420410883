import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useInterval} from "../../../../../hooks/useInterval";

import {toastError} from "../../../../../utils/toastHelper";

import {PHONE_CODE_TYPE, RUSSIA_PHONE_CODE_ID} from "../../../../../constants/phone";

import {getClientPropertiesCardSelector} from "../../../../../ducks/clientProperties";
import {downloadDocument} from "../../../../../ducks/documents";
import {
    checkContractorsExistsDocumentsEdoRegistries,
    checkDuplicatesDocumentsEdoRegistries, checkDuplicatesRegistry,
    confirmDuplicatesDocumentsEdoRegistries, confirmDuplicatesRegistry,
    exportDocumentsEdoRegistries,
    sendSmsDocumentsEdoRegistries,
    signDocumentsEdoRegistries,
} from "../../../../../ducks/documentsEdoRegistries";
import {
    checkImportTaskExistsDocumentsEdoRegistriesItems,
    deleteDocumentsEdoRegistriesItems,
    deleteNotCompletedDocumentsEdoRegistriesItems,
    importDocumentsEdoRegistriesItems,
    rejectDocumentsEdoRegistriesItems,
    updateReceiversDocumentsEdoRegistriesItems,
} from "../../../../../ducks/documentsEdoRegistriesItems";

const DEFAULT_TIME = 60;

export const useEdoDocumentsRegistriesCardAction = ({clientId, registryId, selectedList, documentType, useTemplateFile, fetchCard, fetchList, fetchTasks, clearSelectedRows}) => {
    const {edmSignWithoutSmsCodeEnable} = useSelector(getClientPropertiesCardSelector);

    const [confirmData, setConfirmData] = useState({});
    const [isInviteViaEmailOpen, setIsInviteViaEmailOpen] = useState(false);
    const [isEditFormOpen, setEditFormOpen] = useState(false);
    const [isCodeConfirmOpen, setCodeConfirmOpen] = useState(false);
    const [codeConfirmType, setCodeConfirmType] = useState(PHONE_CODE_TYPE.SMS);
    const [isImportModalOpen, setImportModalOpen] = useState(false);
    const [editData, setEditData] = useState(false);
    const [isHideStaffInfo, setHideStaffInfo] = useState(false);
    const [dataDuplicate, setDataDuplicate] = useState({});
    const [smsCode, setSmsCode] = useState("");
    const [timer, setTimer] = useState(DEFAULT_TIME);

    const dispatch = useDispatch();

    useInterval(() => {
        if (timer > 0) {
            setTimer(timer - 1);
        }
    }, 1000);

    const onChangeCode = (value) => {
        setSmsCode(value);
    };

    const onCloseEditForm = () => {
        setEditFormOpen(false);
        setEditData({});
    };

    const openDeleteConfirm = () => {
        setConfirmData({
            content: "Вы действительно хотите удалить выбранные строки реестра?",
            onConfirm: onClickDeleteRegistryItems,
        });
    };

    const openSignConfirm = () => {
        setConfirmData({
            content: "Вы действительно хотите подписать все документы по реестру? Внимание! При подтверждении операции все созданные в рамках текущего реестра документы будут созданы и подписаны от Вашего имени",
            onConfirm: checkImportTaskExists,
        });
    };

    const checkImportTaskExists = () => {
        dispatch(checkImportTaskExistsDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            getResult: ({result}) => {
                if (result) {
                    toastError("Задача на загрузку списка исполнителей не завершена. Пожалуйста, повторите попытку позже");

                    return;
                }

                checkDuplicatesItems();
            },
        }));
    };

    const checkDuplicatesItems = () => {
        dispatch(checkDuplicatesRegistry({
            clientId,
            registryId,
            getResult: ({result}) => {
                if(result){
                    setConfirmData({
                        content: "Внимание! В реестре найдены повторяющиеся строки с одним и тем же исполнителем (получателем). После передачи реестра на подписание дубли строк будут удалены автоматически. Вы подтверждаете операцию удаления дублей строк и передачи реестра на подписание?",
                        onConfirm: confirmDuplicatesRegistryItems,
                    });

                    return;
                }
                checkDuplicatesDocumentsEdoItems();
            },
        }));
    };

    const confirmDuplicatesRegistryItems = () => {
        dispatch(confirmDuplicatesRegistry({
            clientId,
            registryId,
            onSuccess: checkDuplicatesDocumentsEdoItems,
        }));
    };

    const checkDuplicatesDocumentsEdoItems = () => {
        dispatch(checkDuplicatesDocumentsEdoRegistries({
            clientId,
            registryId,
            getResult: ({duplicates, duplicatesInRegistryExists}) => {
                if (!duplicatesInRegistryExists && !duplicates?.length) {
                    checkContractorsExists();

                    return;
                }

                if (duplicatesInRegistryExists && !duplicates?.length) {
                    setConfirmData({
                        content: "Внимание! В реестре найдены повторяющиеся строки с одним и тем же исполнителем (получателем). После передачи реестра на подписание дубли строк будут удалены автоматически. Вы подтверждаете операцию удаления дублей строк и передачи реестра на подписание?",
                        onConfirm: confirmDuplicatesItems,
                    });

                    return;
                }

                setDataDuplicate({
                    duplicatesInRegistryExists,
                    duplicates,
                });
            },
        }));
    };

    const confirmDuplicatesItems = (seqNums) => {
        dispatch(confirmDuplicatesDocumentsEdoRegistries({
            clientId,
            registryId,
            seqNums,
            onSuccess: () => {
                setDataDuplicate({});
                fetchList();
                checkContractorsExists();
            },
        }));
    };

    const checkContractorsExists = () => {
        dispatch(checkContractorsExistsDocumentsEdoRegistries({
            clientId,
            registryId,
            getResult: ({success}) => {
                if (!success) {
                    setConfirmData({
                        content: "Внимание! В реестре найдены строки с отсутствующими на площадке исполнителями. В случае подтверждения операции этим исполнителям будет предложено зарегистрироваться на площадке для использования функционала электронного документооборота с Вашей компанией. Если Вы не хотите этого делать, отмените данную операцию, удалите строки с отсутствующими на площадке исполнителями из реестра и повторите операцию.",
                        onConfirm: () => {
                            if (edmSignWithoutSmsCodeEnable) {
                                signRegistry();

                                return;
                            }

                            sendSms();
                        },
                    });

                    return;
                }

                if (edmSignWithoutSmsCodeEnable) {
                    signRegistry();

                    return;
                }

                sendSms();
            },
        }));
    };

    const sendSms = (codeType) => {
        dispatch(sendSmsDocumentsEdoRegistries({
            clientId,
            registryId,
            phoneCodeId: RUSSIA_PHONE_CODE_ID,
            codeType,
            getResult: ({codeType}) => {
                setTimer(DEFAULT_TIME);
                setCodeConfirmOpen(true);
                setCodeConfirmType(codeType);
            },
        }));
    };

    const signRegistry = () => {
        dispatch(signDocumentsEdoRegistries({
            clientId,
            registryId,
            smsCode: smsCode ? smsCode : undefined,
            onSuccess: () => {
                setCodeConfirmOpen(false);
                fetchCard();
                fetchList();
            },
        }));
    };

    const onClickDeleteRegistryItems = () => {
        const seqNums = selectedList.filter(({isSelected}) => isSelected).map(({seqNum}) => seqNum);

        dispatch(deleteDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            seqNums,
            onSuccess: () => {
                fetchList();
                clearSelectedRows();
            },
        }));
    };

    const importItems = ({file}) => {
        const formData = new FormData();
        formData.append("file", file);

        dispatch(importDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            formData,
            onSuccess: () => {
                fetchTasks();
                setImportModalOpen(false);
            },
        }));
    };

    const handleDownload = (documentId) => {
        dispatch(downloadDocument({
            downloadLink: `${window.location.origin}/api/filestore/edocuments/signed/${documentId}`,
            extension: "pdf",
            fileName: `${documentId}_signed.pdf`,
        }));
    };

    const rejectSendItem = (seqNum) => {
        dispatch(rejectDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            seqNum,
            onSuccess: fetchList,
        }));
    };

    const deleteNotCompletedItems = () => {
        dispatch(deleteNotCompletedDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            onSuccess: () => {
                fetchCard();
                fetchList();
            },
        }));
    };

    const handleDownloadEmptyTemplate = () => {
        if (useTemplateFile && !documentType) {
            return;
        }

        const downloadLink = useTemplateFile ?
            `api/edocuments/signing/template/empty?type=${documentType}` :
            `api/filestore/edocuments/file?clientId=${clientId}&registryId=${registryId}`;

        dispatch(downloadDocument({
            downloadLink: `${window.location.origin}/${downloadLink}`,
            extension: "pdf",
            isDownload: false,
            fileName: "_signed.pdf",
        }));
    };

    const exportRegistry = () => {
        dispatch(exportDocumentsEdoRegistries({
            clientId,
            registryId,
        }));
    };

    const updateReceiversData = () => {
        dispatch(updateReceiversDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            onSuccess: () => {
                fetchTasks();
            },
        }));
    };

    return {
        confirmData,
        setConfirmData,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        isEditFormOpen,
        setEditFormOpen,
        editData,
        setEditData,
        dataDuplicate,
        setDataDuplicate,
        timer,
        isCodeConfirmOpen,
        setCodeConfirmOpen,
        isImportModalOpen,
        setImportModalOpen,
        isHideStaffInfo,
        setHideStaffInfo,
        openDeleteConfirm,
        openSignConfirm,
        onCloseEditForm,
        confirmDuplicatesItems,
        sendSms,
        signRegistry,
        onChangeCode,
        importItems,
        handleDownload,
        rejectSendItem,
        deleteNotCompletedItems,
        checkDuplicatesItems,
        handleDownloadEmptyTemplate,
        exportRegistry,
        updateReceiversData,
        codeConfirmType,
    };
};