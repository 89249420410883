import {
    BFF_KEDO_STAFF_ADD_REQUEST, BFF_KEDO_STAFF_COMMON_REQUEST,
    BFF_KEDO_STAFF_GET_BY_ID_REQUEST,
    BFF_KEDO_STAFF_GET_PAGE_REQUEST,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST,
    BFF_KEDO_STAFF_UPDATE_REQUEST,
    BFF_KEDO_STAFF_UPDATE_STORE,
} from "./actions";

export const updateKedoStaff = (payload) => {
    return {
        type: BFF_KEDO_STAFF_UPDATE_REQUEST,
        payload,
    };
};

export const updateKedoArchiveStatusStaff = (payload) => {
    return {
        type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST,
        payload,
    };
};

export const importKedoStaffFromFile = (payload) => {
    return {
        type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST,
        payload,
    };
};

export const getKedoStaffPage = (payload) => {
    return {
        type: BFF_KEDO_STAFF_GET_PAGE_REQUEST,
        payload,
    };
};

export const addKedoStaff = (payload) => {
    return {
        type: BFF_KEDO_STAFF_ADD_REQUEST,
        payload,
    };
};

export const getKedoStaffById = (payload) => {
    return {
        type: BFF_KEDO_STAFF_GET_BY_ID_REQUEST,
        payload,
    };
};

export const getKedoStaffSignatureTransactions = (payload) => {
    return {
        type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST,
        payload,
    };
};

export const getKedoStaffCommon = (payload) => {
    return {
        type: BFF_KEDO_STAFF_COMMON_REQUEST,
        payload,
    };
};

export const updateKedoStaffStore = (payload) => {
    return {
        type: BFF_KEDO_STAFF_UPDATE_STORE,
        payload,
    };
};