import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_MIGRATION_STATUS} from "../../../constants/contractor";

export const isOnlyMigrationCardPresent = (params) => {
    const {
        migrationStatus,
        citizenship,
    } = params;

    return !(
        [
            CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
            CONTRACTOR_MIGRATION_STATUS.REFUGEE,
        ].includes(migrationStatus) || [
            citizenshipsDict.LG.value,
            citizenshipsDict.DN.value,
        ].includes(citizenship)
    );
};