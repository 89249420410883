import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";

import PropTypes from "prop-types";

import "./style.sass";

class DepositFilter extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        sendFilter: PropTypes.func,
        clearFilter: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {
        },
        sendFilter: () => {

        },
        clearFilter: () => {

        },
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: {
                nameSubstringFilter: "",
            },
            prevFilter: {
                nameSubstringFilter: "",
            },
        };
    }

    handleOnChange = (e, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    };

    handleClearFilter = () => {
        const {sendFilter} = this.props;

        this.setState(
            {
                filter: {
                    nameSubstringFilter: "",
                },
            }, () => {
                sendFilter(this.state.filter);
            });
    };

    handleSendFilter = () => {
        const {sendFilter} = this.props;
        const {
            filter,
        } = this.state;

        sendFilter(filter);
    };

    render() {
        const {
            filter: {
                nameSubstringFilter,
            },
        } = this.state;

        return (
            <NmForm
                horizontal
                className="deposit-filter"
            >
                <NmInputV2
                    size="lg"
                    autoComplete="off"
                    label="Заказчик"
                    onChange={this.handleOnChange}
                    className="deposit-filter__item"
                    name="nameSubstringFilter"
                    value={nameSubstringFilter}
                />
                <FilterButtonsV2
                    className="deposit-filter__actions"
                    onSearch={this.handleSendFilter}
                    onClear={this.handleClearFilter}
                />
            </NmForm>
        );
    }
}

export default withTranslation()(DepositFilter);


