import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmLabel from "../../../components/ActualComponents/NmLabel";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import {NmPageCardHeader} from "../../../components/ActualComponents/NmPageCardHeader";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";
import AddModelFileForm from "../add-model-file-form";
import AddMLModelForm from "../add-model-form";
import TestPredictAmountForm from "../test-predict-amount-form";

import {downloadFile} from "../../../utils/file";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {downloadDocument} from "../../../ducks/documents";
import {
    addFileToMlModel,
    addMlModel, deleteMlModel, deleteMLModelFile,
    getMlModelList, mlModelActionProgressSelector,
    mlModelListProgressSelector,
    mlModelListSelector, setMainModel, updateMlModel, updateModelFile,
} from "../../../ducks/machineLearning";
import {mlmodelsPredictAmountSelector, predictMlmodelsAmount} from "../../../ducks/mlmodels";
import {specialitiesAllV2OptionsSelector} from "../../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

class MLModelsList extends Component {
    static propTypes = {
        getContractorList: PropTypes.func,
    };

    static defaultProps = {
        getContractorList: () => {

        },
    };

    constructor(props) {
        super(props);

        this.state = {
            formFilter: {
                textFilter: "",
                fromDateFilter: null,
                toDateFilter: null,
                nameFilter: "",
            },
            pageSize: 25,
            pageNum: 1,
            isOpenEditCard: false,
            isOpenDropDown: false,
            confirmData: {},
            error: {},
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList = () => {
        const {
            getMlModelList,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        const reqData = {
            pageNum,
            pageSize,
        };

        getMlModelList(reqData);
    };

    addModel = (reqData) => {
        const {
            addMlModel,
        } = this.props;

        addMlModel({
            data: reqData,
            onSuccess: () => {
                this.fetchList();
                this.closeAddModelWindow();
            },
        });
    };

    updateModel = (reqData) => {
        const {
            updateMlModel,
        } = this.props;

        updateMlModel({
            data: reqData,
            onSuccess: () => {
                this.fetchList();
                this.closeAddModelWindow();
            },
        });
    };

    updateModelFile = (reqData) => {
        const {
            updateModelFile,
        } = this.props;

        updateModelFile({
            data: reqData,
            onSuccess: () => {
                this.fetchList();
                this.closeAddingFileWindow();
            },
        });
    };

    setMainModel = ({modelId}) => {
        const {
            setMainModel,
        } = this.props;

        return () => {
            setMainModel({
                data: {modelId},
                onSuccess: () => {
                    this.fetchList();
                    this.closeWindowConfirm();
                },
            });
        };
    };

    deleteModel = (reqData) => {
        const {
            deleteMlModel,
        } = this.props;

        return () => {
            deleteMlModel({
                data: reqData,
                onSuccess: () => {
                    this.fetchList();
                    this.closeWindowConfirm();
                },
            });
        };
    };

    deleteMLModelFile = (reqData) => {
        const {
            deleteMLModelFile,
        } = this.props;

        return () => {
            deleteMLModelFile({
                data: reqData,
                onSuccess: () => {
                    this.fetchList();
                    this.closeWindowConfirm();
                },
            });
        };
    };

    downloadFile = ({name, fileId}) => {
        const {
            downloadDocument,
        } = this.props;
        return () => {
            downloadDocument({
                isDownload: true,
                fileName: name,
                downloadLink: `${window.location.origin}/api/mlmodels/files/download?fileId=${fileId}`,
            });
        };

    };

    addFile = (data) => {
        const {
            addFileToMlModel,
        } = this.props;

        addFileToMlModel({
            data,
            onSuccess: () => {
                this.fetchList();
                this.closeAddingFileWindow();
            },
        });
    };

    getAction = (item) => {
        return {
            DELETE_MODEL: {
                title: "Удаление модели",
                text: "Вы действительно хотите удалить модель?",
                actionMethod: this.deleteModel(item),
            },
            DELETE_MODEL_FILE: {
                title: "Удаление файла модели",
                text: "Вы действительно хотите удалить файл модели?",
                actionMethod: this.deleteMLModelFile(item),
            },
            SET_MAIN_MODEL: {
                title: "Установка модели основной",
                text: "Вы действительно хотите установить модель основной?",
                actionMethod: this.setMainModel(item),
            },
        };
    };

    onDeleteClick = (item) => {
        return () => {
            this.setState({action: this.getAction(item).DELETE_MODEL});
        };
    };

    onSetMainClick = (item) => {
        return () => {
            this.setState({action: this.getAction(item).SET_MAIN_MODEL});
        };
    };

    onDeleteFileClick = (item) => {
        return () => {
            this.setState({action: this.getAction(item).DELETE_MODEL_FILE});
        };
    };

    handleState = (updatedState) => {
        return () => {
            this.setState(updatedState);
        };
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    closeAddModelWindow = () => {
        this.setState({isShowMLModelForm: false, model: null});
    };

    closeAddingFileWindow = () => {
        this.setState({isShowAddingFileForm: false, model: null});
    };

    closeWindowConfirm = () => {
        this.setState({action: null});
    };

    editModel = (model) => {
        return () => {
            this.handleState({model, isShowMLModelForm: true})();
        };
    };

    editModelFile = (model) => {
        return () => {
            this.handleState({model, isShowAddingFileForm: true})();
        };
    };


    addFileToModel = (model) => {
        return () => {
            this.handleState({model, isShowAddingFileForm: true})();
        };
    };

    renderConfirm = () => {
        const {
            action,
        } = this.state;

        return (action &&
            <NmConfirmV2
                isOnlyConfirm
                title={action.title}
                content={action.text}
                onCancel={this.closeWindowConfirm}
                onConfirm={action.actionMethod}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    renderAddingFileForm = () => {
        const {
            t,
            actionProgress,
        } = this.props;

        const {
            isShowAddingFileForm,
            model,
        } = this.state;

        return (isShowAddingFileForm &&
            <AddModelFileForm
                t={t}
                model={model}
                onClose={this.closeAddingFileWindow}
                onSubmit={model.fileId ? this.updateModelFile : this.addFile}
                progress={actionProgress}
            />
        );
    };

    renderAddMLModelForm() {
        const {
            t,
            actionProgress,
        } = this.props;

        const {
            isShowMLModelForm,
            model,
        } = this.state;

        return (isShowMLModelForm &&
            <AddMLModelForm
                t={t}
                model={model}
                onClose={this.closeAddModelWindow}
                onSubmit={isNullOrWhitespace(model) ? this.addModel : this.updateModel}
                progress={actionProgress}
            />
        );
    };

    renderControls = () => {
        return (
            <div>
                <NmButton
                    onClick={this.handleState({isShowMLModelForm: true})}
                >
Добавить модель
                </NmButton>
            </div>
        );
    };

    renderList() {
        const {
            list,
        } = this.props;

        return (list.map((item) => {
            return (
                <div
                    key={item.modelId}
                    className={`flex ml-model-list__row ${item.mainModel ? "flex ml-model-list__row_main" : ""}`}
                >
                    <div className="column-25">
                        <NmLabelText
                            label="Наименование"
                            text={item.name}
                        />
                        <NmLabelText
                            label="Описание"
                            text={item.description}
                        />
                        <NmLabelText
                            label="Метод"
                            text={item.method}
                        />
                        {item.taskStatus &&<NmLabelText
                            label="Статус"
                            text={item.taskStatus}
                        />}
                        {item.mainModel && <NmLabelText
                            text="Модель установлена основной"
                        />}
                    </div>
                    <div className="column-50">
                        <NmButton
                            color="light-green"
                            className="mr-15 mb-15"
                            onClick={this.addFileToModel(item)}
                        >
Добавить файл
                        </NmButton>

                        {item.files.map((file) => {
                            return (
                                <div
                                    className="ml-model-list__row flex"
                                    key={item.fileId}
                                >
                                    <div className="column-50">
                                        <NmLabelText
                                            label="Наименование"
                                            text={file.name}
                                        />
                                        <NmLabelText
                                            label="Описание"
                                            text={file.description}
                                        />
                                    </div>
                                    <div className="column-50">
                                        <NmButton
                                            color="light-green"
                                            className="mb-15"
                                            onClick={this.editModelFile(file)}
                                        >
                                            Редактировать файл
                                        </NmButton>
                                        {/*<NmButton
                                            color="light-green"
                                            className="mb-15"
                                            onClick={this.downloadFile(file)}>
                                            Скачать файл
                                        </NmButton>*/}
                                        <NmButton
                                            color="light-green"
                                            className="mb-15"
                                            onClick={this.onDeleteFileClick(file)}
                                        >
                                            Удалить файл
                                        </NmButton>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="column-25 ml-15">
                        <NmButton
                            color="light-green"
                            className="mb-15"
                            onClick={this.editModel(item)}
                        >
Редактировать
                        </NmButton>
                        <NmButton
                            color="light-green"
                            className="mb-15"
                            onClick={this.onSetMainClick(item)}
                        >
Установить основной моделью
                        </NmButton>
                        <NmButton
                            color="light-green"
                            className="mb-15"
                            onClick={this.onDeleteClick(item)}
                        >
Удалить
                        </NmButton>
                    </div>
                </div>
            );
        }));
    };

    renderTestPredictAmountForm() {
        const {
            specialityOptions,
            predictMlmodelsAmount,
            predictedAmount,
        } = this.props;

        return (
            <TestPredictAmountForm
                specialityOptions={specialityOptions}
                predictAmount={predictMlmodelsAmount}
                predictedAmount={predictedAmount}
            />
        );
    };

    render() {
        const {
            listProgress,
            totalPages,
            t,
            totalCount,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <NmPage
                isLoaded={listProgress}
                className="ml-model-list"
                header={
                    <NmPageHeader
                        text="Машинное обучение"
                        totalCount={totalCount}
                    />
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                controls={this.renderControls()}
            >
                {this.renderTestPredictAmountForm()}
                {<NmTitle
                    className="mt-15 mb-15"
                    size="md"
                >
                    Список моделей предсказания рыночной цены за час
                </NmTitle>}
                {this.renderAddMLModelForm()}
                {this.renderAddingFileForm()}
                {this.renderConfirm()}
                {this.renderList()}
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        list: mlModelListSelector(state),
        listProgress: mlModelListProgressSelector(state),
        actionProgress: mlModelActionProgressSelector(state),
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        predictedAmount: mlmodelsPredictAmountSelector(state),
    }),
    {
        getMlModelList,
        downloadDocument,
        addMlModel,
        updateMlModel,
        deleteMlModel,
        addFileToMlModel,
        predictMlmodelsAmount,
        updateModelFile,
        setMainModel,
        deleteMLModelFile,
    },
)(withTranslation()(MLModelsList)));
