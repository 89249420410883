import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import ActCreation from "../../../components/ActCreation";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import {OrderPayPopupErrors} from "./errors";
import {OrderPayPopupWarnings} from "./warnings";

import {formatDateWithoutTime, getDateObject, getDateWithoutTime} from "../../../utils/dateFormat";
import {
    ls, USER_LOGIN,
    USER_ROLE,
} from "../../../utils/localstorage";
import {replacer} from "../../../utils/replacer";
import {formatAmount, formatNumber, getNumberFromFormattedAmount} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {getEditActTemplateDataFromCard} from "../order-templates/components/edit-act-template/utils/dto";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";
import {ORDER_WORK_REPORT} from "../../../constants/messages";
import {ORDER_KIND, ORDER_WORK_REPORT_ERROR_CODE} from "../../../constants/order";
import {ADMIN, FOREMAN} from "../../../constants/roles";

import {
    getClientsSettingsPayments,
    updateStoreClientsSetting,
} from "../../../ducks/bff/clients/settings/actionCreators";
import {bffClientsSettingsPaymentsSelector} from "../../../ducks/bff/clients/settings/selectors";
import {checkPaymentsCreationProhibited} from "../../../ducks/client";
import {clientCurrentMemberIdSelector, getClientCurrentMemberByLogin} from "../../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {getContractorsByOrder} from "../../../ducks/contractor";
import {
    financeReportDuplicatedPaymentsSelector,
    financeReportProcessDuplicatedPaymentsSelector,
    getPaymentInfo,
    initPayment,
    paymentInfoSelector,
    updateFinanceReportStore,
} from "../../../ducks/financeReport";
import {getOrderCardSelector, orderWorkUnitsOptionsSelector, unitsAbbreviationDictSelector} from "../../../ducks/order";
import {
    getOrderWorkReportTemplateById,
    orderWorkReportTemplateCardSelector,
    updateOrderWorkReportTemplateStore,
} from "../../../ducks/orderWorkReportTemplate";
import {
    getActualScoreContractor,
} from "../../../ducks/scores";

import {orderType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class OrderPayPopup extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired,
        contractorId: PropTypes.string.isRequired,
        fullName: PropTypes.string,
        handleClose: PropTypes.func,
        orderCard: orderType,
        currentMemberId: PropTypes.string,
        clientId: PropTypes.string,
    };

    static defaultProps = {
        fullName: "",
        handleClose: () => {
        },
        orderCard: {},
        currentMemberId: "",
        clientId: null,
    };

    constructor(props) {
        super(props);

        const {
            clientsSettingsPayments: {
                smzMarketplace,
            },
            orderCard: {
                workUnit,
                workStartDate,
            },
            clientInfo: {
                documentDatesByWorkDates,
            },
        } = this.props;

        // Если дата начала позже текущей, то дата "ДО" НЕ предзаполняется
        const workEndDate = getDateWithoutTime(new Date(workStartDate)) > getDateWithoutTime(new Date())
            ? null
            : formatDateWithoutTime(new Date(), "yyyy-MM-dd");

        this.state = {
            form: {
                description: "",
                descriptionFns: "",
                comment: "",
                volume: "",
                workEndDate,
                actOfAcceptanceOfWorkTemplateId: props.actOfAcceptanceOfWorkTemplateId,
                volumeOfWork: workUnit === "SERVICE" ? "1" : "",
                paymentMethod: smzMarketplace?.paymentMethod,
                actDate: documentDatesByWorkDates ? getDateObject(workEndDate) : null,
            },
            confirmData: {
                confirmText: "",
                isOpenConfirm: false,
            },
            workQualityScore: null,
            estimatedTimeScore: null,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            contractorId,
            getActualScoreContractor,
            getPaymentInfo,
            orderWorkReportTemplateId,
            clientId,
            orderId,
            getOrderWorkReportTemplateById,
            getClientsSettingsPayments,
        } = this.props;

        if (orderWorkReportTemplateId) {
            getOrderWorkReportTemplateById({orderWorkReportTemplateId});
        }

        if (!orderWorkReportTemplateId) {
            getActualScoreContractor({
                data: {
                    clientId,
                    contractorId,
                    orderId,
                },
                handleResponse: this.handleResponseActualScoreContractor,
            });

            return;
        }

        getPaymentInfo({
            clientId,
            contractorIdFilter: contractorId,
            orderId,
        });

        getClientsSettingsPayments({clientId});
    }

    componentWillUnmount() {
        const {
            updateOrderWorkReportTemplateStore,
            updateFinanceReportStore,
            updateStoreClientsSetting,
        } = this.props;

        updateOrderWorkReportTemplateStore({card: {}});
        updateFinanceReportStore({
            duplicatedPaymentsInfo: {
                paymentsNumberDuplicated: [],
                sumOfMoney: 0,
            },
        });
        updateStoreClientsSetting({payments: {}});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            processInitPayment,
            duplicatedPaymentsInfo: {
                paymentsNumberDuplicated,
                warnings,
                errors,
            },
            orderId,
            orderWorkReportTemplateCard,
            clientsSettingsPayments,
            orderCard,
        } = this.props;
        const {
            processInitPayment: _processInitPayment,
            clientsSettingsPayments: _clientsSettingsPayments,
        } = prevProps;

        if (!isEmpty(orderWorkReportTemplateCard) && !isEqual(orderWorkReportTemplateCard, prevProps.orderWorkReportTemplateCard)) {
            const {
                estimatedTimeScore,
                workQualityScore,
                ...formData
            } = getEditActTemplateDataFromCard({
                card: orderWorkReportTemplateCard,
                orderId,
            });

            this.setState(prevState => ({
                ...prevState,
                estimatedTimeScore,
                workQualityScore,
                form: {
                    ...prevState.form,
                    ...formData,
                },
            }));
        }

        if (!isEmpty(clientsSettingsPayments) && !isEqual(clientsSettingsPayments, _clientsSettingsPayments)) {
            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    paymentMethod:
                        orderCard.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ ?
                            clientsSettingsPayments.smzMarketplace?.paymentMethod :
                            clientsSettingsPayments.civilMarketplace?.paymentMethod,
                },
            }));
        }

        if (processInitPayment !== _processInitPayment && !processInitPayment) {
            const isFnsConnectTimeOut = errors?.map(item => item.errorCode)
                .includes(ORDER_WORK_REPORT_ERROR_CODE.FNS_CONNECT_TIME_OUT);

            if (isFnsConnectTimeOut) {
                this.setState({
                    confirmData: {
                        isOpenConfirm: true,
                        confirmText: "ФНС временно недоступно. Невозможно провести проверки статуса Самозанятого и превышение лимитов дохода.",
                        additionalBtnContent: "Выставить акт без проверки",
                        confirmButton: "Повторить запрос",
                        checkingDuplicates: true,
                        checkWarningPayment: true,
                        onConfirm: () => this.initPayment(),
                        onClickAdditional: () => this.initPayment(true),
                    },
                });

                return;
            }

            if (!isEmpty(errors)) {
                this.setState({
                    errors,
                });

                return;
            }

            if (!isEmpty(paymentsNumberDuplicated)) {
                this.openDuplicatedConfirm(paymentsNumberDuplicated);

                return;
            }

            if (!isEmpty(warnings)) {
                this.onOpenWarningsConfirm();
            }
        }
    }

    onOpenWarningsConfirm = () => {
        this.setState({
            confirmData: {
                isOpenWarnings: true,
                checkWarningPayment: false,
                checkingDuplicates: false,
            },
        });
    };

    openDuplicatedConfirm = paymentsNumberDuplicated => {
        const {
            fullName,
            duplicatedPaymentsInfo: {
                sumOfMoney,
                warnings,
            },
            t,
        } = this.props;

        const confirmText = ORDER_WORK_REPORT.FOUNDED_DUPLICATED_PAYMENTS.replace(":name", fullName)
            .replace(":payments", paymentsNumberDuplicated.join(", "))
            .replace(":sum", formatAmount(formatNumber(sumOfMoney)));

        this.setState({
            confirmData: {
                isNeedWarningsConfirm: !isEmpty(warnings),
                confirmText: confirmText,
                isOpenConfirm: true,
                checkWarningPayment: false,
                checkingDuplicates: false,
                cancelButton: t("button.cancel"),
                confirmButton: t("button.confirm"),
                classNameContent: "order-pay-popup__confirm-content",
            },
        });
    };

    handleResponseActualScoreContractor = ({workQualityScore, estimatedTimeScore}) => {
        this.setState({
            workQualityScore,
            estimatedTimeScore,
        });
    };

    handleClickCancel = () => {
        this.setState({
            confirmData: {
                confirmText: "",
                isOpenConfirm: false,
                isOpenWarnings: false,
            },
        });
    };

    handleChangeFields = (event, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value,
            },
        }));
    };

    checkFormForeman = () => {
        const {
            fullName,
            t,
            checkPaymentsCreationProhibited,
            handleClose,
            getClientCurrentMemberByLogin,
        } = this.props;

        checkPaymentsCreationProhibited({
            onSuccess: () => this.setState({
                confirmData: {
                    isOpenConfirm: true,
                    confirmText: replacer(":name", ORDER_WORK_REPORT.ADD_CONFIRM_TEXT, fullName),
                    checkingDuplicates: true,
                    checkWarningPayment: true,
                    cancelButton: t("button.no"),
                    confirmButton: t("button.yes"),
                },
            }),
            onClose: () => {
                getClientCurrentMemberByLogin(ls(USER_LOGIN));
                handleClose();
            },
        });
    };

    initConfirm = () => {
        const {
            fullName,
            t,
        } = this.props;

        if (this.role === FOREMAN) {
            this.checkFormForeman();

            return;
        }

        this.setState({
            confirmData: {
                isOpenConfirm: true,
                confirmText: replacer(":name", ORDER_WORK_REPORT.ADD_CONFIRM_TEXT, fullName),
                checkingDuplicates: true,
                checkWarningPayment: true,
                cancelButton: t("button.no"),
                confirmButton: t("button.yes"),
            },
        });
    };

    initPayment = (skipCheckFns = false) => {
        this.handleClickCancel();

        const {
            orderId,
            clientId,
            contractorId,
            currentMemberId,
            orderCard: {
                orderKind,
                workStartDate: orderWorkStartDate,
            },
            initPayment,
            orderWorkReportTemplateCard,
            handleClose,
            clientInfo,
        } = this.props;

        const {
            form: {
                comment,
                description,
                descriptionFns,
                sumOfMoney,
                workEndDate,
                workStartDate,
                volumeOfWork,
                reviewText,
                actDate,
                actOfAcceptanceOfWorkTemplateId,
                paymentMethod,
                descriptionParams,
                amountForReport,
            },
            estimatedTimeScore,
            workQualityScore,
            confirmData: {
                checkingDuplicates,
                checkWarningPayment,
            },
        } = this.state;

        const requestData = {
            orderId,
            clientId: this.role === ADMIN ? clientId : undefined,
            contractorId,
            description,
            descriptionFns,
            clientUserId: currentMemberId,
            actOfAcceptanceOfWorkTemplateId,
            sumOfMoney: (orderKind === ORDER_KIND.AMOUNT_BASED_ORDER || isEmpty(orderKind)) ? getNumberFromFormattedAmount(sumOfMoney) : null,
            amountForReport: amountForReport && clientInfo.amountForReport ? getNumberFromFormattedAmount(amountForReport) : null,
            contractorEstimatedTimeScore: estimatedTimeScore,
            contractorWorkQualityScore: workQualityScore,
            workEndDate,
            workStartDate: workStartDate || orderWorkStartDate,
            volumeOfWork: orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER ? getNumberFromFormattedAmount(volumeOfWork) : null,
            checkingDuplicates,
            checkWarningPayment,
            comment,
            reviewText,
            lastUpdateIdTemplate: orderWorkReportTemplateCard.lastUpdateId,
            actDate: actDate && formatDateWithoutTime(actDate, "yyyy-MM-dd"),
            descriptionParams: descriptionParams ? descriptionParams.map(item => item.content) : undefined,
            paymentMethod,
            skipCheckFns,
        };

        initPayment({
            onSuccess: () => {
                if (!isNullOrWhitespace(reviewText)) {
                    this.setState({
                        confirmData: {
                            confirmText: "Ваш отзыв направлен на модерацию администратором. После успешного прохождения модерации отзыв будет опубликован на форме просмотра сведений об исполнителе",
                            isOpenConfirm: true,
                            checkWarningPayment: false,
                            checkingDuplicates: false,
                            confirmButton: "Ок",
                            onConfirm: handleClose,
                        },
                    });

                    return;
                }

                handleClose();
            },
            ...requestData,
        });
    };

    onConfirm = () => {
        const {
            confirmData: {
                isNeedWarningsConfirm,
                onConfirm,
            },
        } = this.state;

        if (onConfirm) {
            onConfirm();

            return;
        }

        if (isNeedWarningsConfirm) {
            this.onOpenWarningsConfirm();

            return;
        }

        this.initPayment();
    };

    renderWarnings = () => {
        const {
            duplicatedPaymentsInfo: {
                warnings,
            },
        } = this.props;
        const {confirmData: {isOpenWarnings}} = this.state;

        if (!isOpenWarnings) {
            return null;
        }

        return (
            <OrderPayPopupWarnings
                onCancel={this.handleClickCancel}
                onConfirm={this.initPayment}
                warnings={warnings}
            />
        );
    };

    renderConfirmWindow = () => {
        const {
            confirmData: {
                isOpenConfirm,
                confirmText,
                cancelButton,
                confirmButton,
                additionalBtnContent,
                onClickAdditional,
            },
        } = this.state;

        return isOpenConfirm &&
            <NmConfirmV2
                isOnlyConfirm
                onCancel={this.handleClickCancel}
                onConfirm={this.onConfirm}
                onClickAdditional={onClickAdditional}
                content={confirmText}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                additionalBtnContent={additionalBtnContent}
            />;
    };

    handleOnRate = (e, {name, value}) => {
        this.setState({[name]: value});
    };

    renderErrors() {
        const {errors} = this.state;

        if (isEmpty(errors)) {
            return null;
        }

        return (
            <OrderPayPopupErrors
                errors={errors}
                onClose={() => {
                    this.setState({errors: null});
                }}
            />
        );
    }

    render() {
        const {
            handleClose,
            orderCard: {
                workUnit,
                workUnitPrice,
                description,
                amount,
                workStartDate,
                volumeOfWork,
                contractorCommission,
            },
            orderContractPaymentType,
            orderKind,
            clientId,
            orderId,
            processInitPayment,
            paymentInfo,
            abbreviationMap,
            abbreviationOptions,
            orderCreatedByTemplateId,
            orderWorkReportTemplateCard,
            contractorId,
        } = this.props;
        const {
            form,
            workQualityScore,
            estimatedTimeScore,
            confirmData: {
                isOpenConfirm,
            },
        } = this.state;

        if (isOpenConfirm) {
            return this.renderConfirmWindow();
        }

        return (
            <>
                <ActCreation
                    zIndex={998}
                    isShowDescriptionParams={true}
                    orderWorkTemplate={orderWorkReportTemplateCard}
                    isComment={true}
                    clientId={clientId}
                    orderId={orderId}
                    handleClose={handleClose}
                    orderDescription={description}
                    workUnitPrice={workUnitPrice}
                    contractorCommission={contractorCommission}
                    abbreviationMap={abbreviationMap}
                    abbreviationOptions={abbreviationOptions}
                    amount={amount}
                    workUnit={workUnit}
                    volumeOfWork={volumeOfWork}
                    startOrderDate={workStartDate}
                    contractorId={contractorId}
                    orderKind={orderKind}
                    orderContractPaymentType={orderContractPaymentType}
                    orderCreatedByTemplateId={orderCreatedByTemplateId}
                    formData={{
                        ...form,
                    }}
                    progress={processInitPayment}
                    paymentInfo={paymentInfo}
                    workQualityScore={workQualityScore}
                    estimatedTimeScore={estimatedTimeScore}
                    handleChange={this.handleChangeFields}
                    handleOnRate={this.handleOnRate}
                    submit={this.initConfirm}
                />
                {this.renderErrors()}
                {this.renderWarnings()}
            </>
        );
    }
}

export default withTranslation()(connect(
    state => ({
        orderWorkReportTemplateCard: orderWorkReportTemplateCardSelector(state),
        orderCard: getOrderCardSelector(state),
        currentMemberId: clientCurrentMemberIdSelector(state),
        duplicatedPaymentsInfo: financeReportDuplicatedPaymentsSelector(state),
        abbreviationMap: unitsAbbreviationDictSelector(state),
        abbreviationOptions: orderWorkUnitsOptionsSelector(state),
        paymentInfo: paymentInfoSelector(state),
        processInitPayment: financeReportProcessDuplicatedPaymentsSelector(state),
        clientInfo: getClientPropertiesCardSelector(state),
        clientsSettingsPayments: bffClientsSettingsPaymentsSelector(state),
    }), {
        getActualScoreContractor,
        getPaymentInfo,
        initPayment,
        checkPaymentsCreationProhibited,
        getOrderWorkReportTemplateById,
        updateOrderWorkReportTemplateStore,
        getContractorsByOrder,
        getClientCurrentMemberByLogin,
        updateFinanceReportStore,
        getClientsSettingsPayments,
        updateStoreClientsSetting,
    },
)(OrderPayPopup));