import React from "react";
import parse from "react-html-parser";
import sanitizeHtml from "sanitize-html";

import ViewTextButton from "../ViewTextButton";

export const ViewParsedHtmlTextButton = (props) => {
    const {
        children,
        ...otherProps
    } = props;

    const getContent = () => {
        const clean = sanitizeHtml(children);

        return parse(clean);
    };

    return (
        <ViewTextButton
            {...otherProps}
            children={getContent()}
        />
    );
};

export default ViewParsedHtmlTextButton;