import React from "react";

import Text from "../ActualComponents/Text";
import BankIndicator from "../BankIndicator";
import SelfEmployedIndicator from "../SelfEmployedIndicator";

import bem from "../../utils/bem";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {getFullName, innFormat, phoneFormat} from "../../utils/stringFormat";

import {isClientUser} from "../../constants/roles";

import "./style.sass";

const ContractorEditPaymentInfo = (props) => {
    const {
        className = "",
        contractor,
        contractorId,
        contractorsPossibility,
        isVisiblePhone = true,
        isVisibleInn = true,
        isVisibleBankIndicator = true,
        isVisibleSelfEmployedIndicator = false,
    } = props;

    const {
        lastName,
        firstName,
        inn,
        fullName,
        patronymic,
        phone,
        taxStatus,
    } = contractor || {};

    const [block, element] = bem("contractor-edit-payment-info", className);

    const role = ls(USER_ROLE);

    return (
        <div className={block()}>
            <div className={element("name-container")}>
                <Text.Sub
                    className={element("name")}
                    level="2"
                    medium
                >
                    {lastName ? getFullName(lastName, firstName, patronymic) : fullName}
                </Text.Sub>
                {
                    isVisibleBankIndicator &&
                    !isClientUser(role) &&
                    <BankIndicator
                        size="lg"
                        showLabel
                        className={element("payment-status")}
                        contractorId={contractorId}
                        contractorsPossibility={contractorsPossibility}
                    />
                }
                {
                    isVisibleSelfEmployedIndicator &&
                    <SelfEmployedIndicator
                        taxStatus={taxStatus}
                        className={element("payment-status")}
                    />
                }
            </div>
            <Text level="3">
                {
                    [
                        inn && isVisibleInn && innFormat(inn),
                        phone && isVisiblePhone && phoneFormat(phone),
                    ].filter(item => Boolean(item)).join(", ")
                }
            </Text>
        </div>
    );
};

export default ContractorEditPaymentInfo;