import L from "leaflet";

export const blueIcon = new L.Icon({
    iconUrl: require("../images/map-marker-blue.svg").default,
    iconSize: [25, 35],
    iconAnchor: [12, 35],
});

export const darkBlueIcon = new L.Icon({
    iconUrl: require("../images/map-marker-dark-blue.svg").default,
    iconSize: [25, 35],
    iconAnchor: [12, 35],
});

export const redIcon = new L.Icon({
    iconUrl: require("../images/map-marker-red.svg").default,
    iconSize: [25, 35],
    iconAnchor: [12, 35],
});

export const MAP_OBJECT_TYPE_DICT = {
    CONTRACTOR: "Исполнители",
    ORDER: "Заказы",
    ADVERT: "Объявления",
    CROWD_TASK: "Задания",
};

export const METERS_IN_KM = 1000;