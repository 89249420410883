import {handleFieldFilter, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {formatFileDict, UPLOAD_TYPE} from "../../../../constants/financeExport";

export const getFinanceExportRequestData = (data) => {
    const {
        clientId,
        uploadType,
        toDateFilter,
        fromDateFilter,
        orderContractPaymentType,
        fromClientId,
        formatType,
        exportEventType,
        period,
        promocodeFilter,
    } = data;

    return {
        clientId,
        uploadType,
        toDate: toDateFilter,
        fromDate: fromDateFilter,
        orderContractPaymentType: [UPLOAD_TYPE.OBJECTS_DEPOSIT.value].includes(uploadType) ?
            orderContractPaymentType :
            undefined,
        fromClientId: isNullOrWhitespace(clientId) ? handleFieldFilter(fromClientId) : clientId,
        formatType: getFormatType({uploadType, formatType}),
        exportEventType: uploadType === UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value ? exportEventType : undefined,
        period: isNullOrWhitespace(period) ? undefined : period,
        promocodeFilter: isNullOrWhitespace(promocodeFilter) ? undefined : promocodeFilter,
    };
};

const getFormatType = ({uploadType, formatType}) => {
    if (
        [
            UPLOAD_TYPE.CHECK_IN_OUT_EXPORT.value,
            UPLOAD_TYPE.CHECK_IN_OUT_EXPORT_CROWD_TASK.value,
        ].includes(uploadType)
    ) {
        return;
    }

    if (uploadType === UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value) {
        return formatFileDict.ZIP;
    }

    return formatType;
};