import React, {useCallback, useEffect, useRef, useState} from "react";
import ReactCrop from "react-image-crop";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    addRecruitmentCandidateAvatar,
    deleteRecruitmentCandidateAvatar,
    getRecruitmentCandidateAvatar,
    updateFileStoreRecruitmentCandidateStore,
} from "../../../ducks/fileStoreRecruitment";
import {ReactComponent as IconCrop} from "../../../images/crop_24.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import ApplyButtons from "../../ApplyButtons";
import NmButton from "../../NmButton";
import NmTitle from "../../NmTitle";
import DropzoneV2 from "../DropzoneV2";
import NmModal from "../NmModal";

import bem from "../../../utils/bem";
import {b64toBlob} from "../../../utils/file";

import {
    addUserAvatar,
    avatarActionProgressSelector,
    deleteUserAvatar,
    getAvatarProgressSelector,
    getUserAvatar,
    getUserAvatarThumbnail,
} from "../../../ducks/fileStore";

import "./style.sass";

const UserAvatarEditForm = (props) => {
    const {
        nameField,
        contractorId,
        candidateId,
        base64,
        onClose,
    } = props;

    const [block, element] = bem("user-avatar-edit-form");
    const [file, setFile] = useState({});
    const [cropMode, setCropMode] = useState(false);

    const progressAction = useSelector(avatarActionProgressSelector);
    const progress = useSelector(getAvatarProgressSelector);

    const dispatch = useDispatch();

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({unit: "%", width: 128, aspect: 1});
    const [completedCrop, setCompletedCrop] = useState(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, [file]);

    useEffect(() => {
        getOriginalAvatar();
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
    }, [completedCrop]);

    function getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, "image/png");
        });
    }

    const getThumbnailAvatar = () => {
        if (candidateId) {
            dispatch(getRecruitmentCandidateAvatar({
                candidateId,
                nameField,
            }));

            return;
        }

        dispatch(getUserAvatarThumbnail({
            contractorId,
        }));
    };

    const handleBase64 = ({base64str}) => {
        if (!base64str) {
            return;
        }

        onChangeFile([b64toBlob(base64str, "image/png")], false);
    };

    const getOriginalAvatar = () => {
        if (candidateId) {
            // Если миниатюра не подгружена, значит аватара нет и нет смысла его загружать
            if (!base64) {
                return;
            }

            dispatch(getRecruitmentCandidateAvatar({
                candidateId,
                thumbnail: false,
                getResult: handleBase64,
            }));

            return;
        }

        dispatch(getUserAvatar({
            contractorId,
            getResult: handleBase64,
        }));
    };

    const saveCroppenImg = async () => {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, file.name);

        const resultFile = new File([croppedImg], file.name, {
            lastModified: file.lastModified,
            type: file.type,
            path: file.path,
            lastModifiedDate: file.lastModifiedDate,
        });

        onChangeFile([resultFile], false);
        setCropMode(false);
    };

    const onChangeFile = (files, isSetCropMode = false) => {
        if (!files && !files.length) {
            return;
        }

        const file = files[0];

        const fileWithPreview = Object.assign(file, {
            preview: URL.createObjectURL(file),
        },
        );

        setFile(fileWithPreview);

        if (isSetCropMode) {
            setCropMode(true);
        }
    };

    const renderImgCropMod = () => {
        return (
            <div className={element("image-zone")}>
                <ReactCrop
                    src={file.preview}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                    minWidth={50}
                    minHeight={50}
                />
            </div>
        );
    };

    const onSubmit = () => {
        if (cropMode) {
            saveCroppenImg();

            return;
        }

        onSave();
    };

    const onSave = () => {
        if (!isEmpty(file)) {
            const formData = new FormData();
            formData.append("file", file);

            if (candidateId) {
                dispatch(addRecruitmentCandidateAvatar({
                    file: formData,
                    candidateId,
                    onSuccess: () => {
                        getThumbnailAvatar();
                        onClose();
                    },
                }));

                return;
            }

            dispatch(addUserAvatar({
                contractorId,
                file: formData,
                onSuccess: () => {
                    getThumbnailAvatar();
                    onClose();
                },
            }));

            return;
        }

        if (candidateId) {
            dispatch(deleteRecruitmentCandidateAvatar({
                candidateId,
                onSuccess: () => {
                    dispatch(updateFileStoreRecruitmentCandidateStore({
                        avatarBase64Str: null,
                    }));
                    onClose();
                },
            }));

            return;
        }

        dispatch(deleteUserAvatar({
            contractorId,
            onSuccess: () => {
                getThumbnailAvatar();
                onClose();
            },
        }));
    };

    return (
        <NmModal
            size="sm"
            classNameContent={block()}
            className={element("modal", {cropMode})}
            header={
                !cropMode &&
                <NmTitle size="lg">
                    Фото профиля
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className={element("footer", {cropMode})}
                    submit={onSubmit}
                    submitBtnContent="Сохранить"
                    onClose={onClose}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={
                props.progress ||
                progressAction ||
                progress
            }
        >
            {
                cropMode ?
                    renderImgCropMod() :
                    <>
                        {
                            file.preview &&
                            <div className={element("image-zone")}>
                                <div className={element("image-zone-buttons")}>
                                    <NmButton
                                        onlyIcon
                                        icon={<IconCrop />}
                                        size="sm"
                                        color="grey"
                                        onClick={() => {
                                            setCropMode(true);
                                        }}
                                    />
                                    <NmButton
                                        className="ms-2"
                                        onlyIcon
                                        icon={<DeleteIcon />}
                                        size="sm"
                                        color="grey"
                                        onClick={() => {
                                            setFile({});
                                        }}
                                    />
                                </div>
                                <img
                                    src={file.preview}
                                    alt={""}
                                />
                            </div>
                        }
                        <DropzoneV2
                            onChange={(files) => onChangeFile(files, true)}
                            multiple={false}
                            maxSize={10}
                            accept=".jpg, .jpeg, .gif, .png, .bmp"
                            format="*.jpg, *.jpeg, *.gif, *.png, *.bmp"
                        />
                    </>
            }

        </NmModal>
    );
};

export default UserAvatarEditForm;