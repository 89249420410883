import * as yup from "yup";

import pluralize from "../../../utils/pluralize";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

export const getBaseDirectoryValidation = (params) => {
    const {
        isClientField,
        inputTypeMinLength,
    } = params;

    const value = getValueValidation({
        inputTypeMinLength,
    });

    if (isClientField) {
        const clientId = yup.string().required(VALIDATIONS_MESSAGE.REQUIRED);

        return yup.object().shape({
            value,
            clientId,
        });
    }

    return yup.object().shape({
        value,
    });
};

const getValueValidation = (params) => {
    const {
        inputTypeMinLength,
    } = params;
    const validation = yup.string().required(VALIDATIONS_MESSAGE.REQUIRED);

    if (inputTypeMinLength) {
        return validation.min(
            inputTypeMinLength,
            `Минимальная длина - ${pluralize(inputTypeMinLength, ["символ", "символа", "символов"])}`,
        );
    }

    return validation;
};