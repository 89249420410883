import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";

import {contractorCardSelector} from "../../../../ducks/contractor";
import {requestAccessToPD} from "../../../../ducks/contractorsPersonalDataAccess";

const ContractorInfoNoAccessPersonalData = ({contractorId}) => {
    const dispatch = useDispatch();
    const [isRequested, setRequested] = useState(null);

    const card = useSelector(contractorCardSelector);

    useEffect(() => {
        if (!isEmpty(card)) {
            const {personalDataAccessRequested} = card;

            setRequested(Boolean(personalDataAccessRequested));
        }
    }, [card]);

    const progress = useSelector(({contractorsPersonalDataAccess}) => contractorsPersonalDataAccess.progress);

    const onClickAction = () => {
        dispatch(requestAccessToPD({
            contractorId,
            onSuccess: ({success}) => {
                if (success) {
                    setRequested(success);
                }
            },
        }));
    };

    if (typeof isRequested !== "boolean") {
        return null;
    }

    return (
        <NmEmptyPageV2
            disabledAction={progress}
            title="Доступ к данным ограничен"
            description={
                isRequested ?
                    "Запрос на доступ к персональным данным отправлен. Пожалуйста, дождитесь решения исполнителя." :
                    "Вы можете запросить у пользователя доступ к персональным данным."
            }
            textAction="Запросить доступ к ПД"
            onClickAction={onClickAction}
            isShowAction={!isRequested}
        />
    );
};

export default ContractorInfoNoAccessPersonalData;