import React, {useState} from "react";

import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";

import dateFormat from "../../../utils/dateFormat";

import "./style.sass";


const TestPredictAmountForm = (props) => {
    const {
        specialityOptions,
        predictAmount,
        predictedAmount,
    } = props;

    const initFormData = {"specialityId":"57242a98-93ba-4e00-9d86-0cb28951998d","description":"укладка товара","createDate": new Date(),"latitude":"42", "longitude": "43"};

    const [form, setForm] = useState({...initFormData});

    const handleChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const predict = () => {
        predictAmount({...form, createDate: dateFormat(form.createDate, "yyyy-MM-dd HH:mm:ss")});
    };

    return (
        <div>
            <NmTitle
                size="md"
            >
                Тестирование выбранной модели предсказания рыночной цены за час
            </NmTitle>
            <NmForm
                className="test-predict-amount-form"
                horizontal
            >

                <NmDropdownV2
                    placeholder="Все"
                    label="Вид деятельности"
                    name="specialityId"
                    onChange={handleChange}
                    value={form.specialityId}
                    options={specialityOptions}
                    size="sm"
                />
                <NmInputV2
                    label="Описание"
                    value={form.description}
                    name="description"
                    onChange={handleChange}
                />
                <NmDatePicker
                    size="sm"
                    label="Дата начала работ"
                    placeholderText="Выберите дату"
                    dateFormat="dd.MM.yy"
                    selected={form.createDate}
                    name="createDate"
                    onChange={handleChange}
                />
                <NmInputV2
                    label="Широта"
                    value={form.latitude}
                    name="latitude"
                    onChange={handleChange}
                />
                <NmInputV2
                    label="Долгота"
                    value={form.longitude}
                    name="longitude"
                    onChange={handleChange}
                />
            </NmForm>
            <div className="flex mb-15 flex-aligned-center">
                <NmButton
                    className="mr-15"
                    onClick={predict}
                >
Получить рекомендацию
                </NmButton>

                <NmLabelText
                    label="Результат предсказания"
                    text={predictedAmount}
                />
            </div>
        </div>
    );
};

export default TestPredictAmountForm;