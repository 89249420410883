import React, {ReactNode} from "react";
import {useSelector} from "react-redux";

import {ReactDadata} from "../../ReactDadata";
import ErrorTooltip from "../ErrorTooltip";
import NmLabel from "../NmLabel";

import bem from "../../../utils/bem";

import {strapiDktnSelector} from "../../../ducks/strapi";

import "./style.sass";

interface IDadataInput {
    size?: "lg" | "xl",
    className?: string,
    error?: string | null,
    disabled?: boolean,
    required?: boolean,
    label?: string,
    query?: string,
    onChange?: (event?: any, params?: any) => void,
    onBlur?: () => void,
    onFocus?: () => void,
    changed?: boolean,
    suggestion?: ReactNode,
    onMouseEnter?: (event: any) => void,
    tooltip?: ReactNode,
    placeholder?: string,
    address?: ReactDadata.DadataSuggestion,
    toBound?: ReactDadata.BoundsType,
    fromBound?: ReactDadata.BoundsType,
    filter?: (suggestion: ReactDadata) => void,
    isVisibleTooltip?: boolean
}

const NmDadataInput = (props: IDadataInput) => {
    const {
        size = "xl",
        className = "",
        error,
        disabled,
        required,
        label,
        query,
        changed,
        suggestion,
        onMouseEnter,
        tooltip,
        isVisibleTooltip,
        ...otherProps
    } = props;
    const dtkn = useSelector(strapiDktnSelector);
    const [block, element] = bem("nm-dadata-input", className);

    return (
        <div
            onMouseEnter={onMouseEnter}
            className={block()}
        >
            {
                label &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                    tooltip={tooltip}
                    isVisibleTooltip={isVisibleTooltip}
                />
            }
            <ReactDadata
                className={element("control", {
                    size,
                    error: Boolean(error),
                    disabled,
                    active: Boolean(query),
                    changed,
                })}
                disabled={disabled}
                query={query}
                token={dtkn}
                {...otherProps}
            />
            {
                error &&
                <ErrorTooltip error={error} />
            }
            {
                suggestion &&
                <div className={element("suggestion")}>
                    {suggestion}
                </div>
            }
        </div>
    );
};

export default NmDadataInput;