import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import CheckboxList from "../../../../components/CheckboxList";
import {CreateTkbPayment} from "../../../../components/CreateTkbPayment";
import NmButton from "../../../../components/NmButton";
import NmLoader from "../../../../components/NmLoader";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";

import {useModal} from "../../../../hooks/useModal";
import {usePagination} from "../../../../hooks/usePagination";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {
    getTkbPaymentsLogs,
    getTkbPaymentsStatusBatch,
} from "../../../../ducks/internalPayments/actionCreators";
import {
    internalPaymentsListSelector,
    internalPaymentsProgressSelector,
    internalPaymentsTkbStatusesBatchSelector,
    internalPaymentsTotalCountSelector,
    internalPaymentsTotalPagesSelector,
} from "../../../../ducks/internalPayments/selectors";

export const SettingsServiceTkbTransfers = () => {
    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useModal();

    const list = useSelector(internalPaymentsListSelector);
    const totalPages = useSelector(internalPaymentsTotalPagesSelector);
    const totalCount = useSelector(internalPaymentsTotalCountSelector);
    const progress = useSelector(internalPaymentsProgressSelector);
    const statuses = useSelector(internalPaymentsTkbStatusesBatchSelector);

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        if  (!isEmpty(list)) {
            const orderIds = list.map(item => item.orderId);

            fetchStatuses(orderIds);
        }
    }, [list]);

    const fetchList = () => {
        dispatch(getTkbPaymentsLogs({
            pageNum,
            pageSize,
        }));
    };

    const fetchStatuses = (orderIds) => {
        dispatch(getTkbPaymentsStatusBatch(orderIds));
    };

    const getCreatePaymentModal = () => {
        if (!modalData?.isOpenCreatePayment) {
            return null;
        }

        return (
            <CreateTkbPayment
                onClose={onCloseModal}
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            const statusText = statuses[item.orderId];

            return {
                key: item.dateTime,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={item.bankType}
                        secondaryHeader={`Дата создания ${formatLocalDate(item.dateTime, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            {
                                label: "Описание",
                                text: item.description
                                    ? (
                                        <NmShowMoreText
                                            lines={1}
                                            more="Подробнее"
                                            anchor="blue"
                                        >
                                            {item.description}
                                        </NmShowMoreText>
                                    )
                                    : "-",
                            },
                            {label: "paymentType", text: item.paymentType},
                            {label: "orderId", text: item.orderId},
                            {label: "clientUserId", text: item.clientUserId},
                            {
                                label: "Статус перевода",
                                text: statusText
                                    ? (
                                        <NmShowMoreText
                                            lines={1}
                                            more="Подробнее"
                                            anchor={"blue"}
                                        >
                                            {statusText}
                                        </NmShowMoreText>
                                    )
                                    : "-",
                            },
                        ]}
                        classNameMainContent="col-16 col-xxl-7"
                        cards={[
                            {
                                title: "Сумма, ₽",
                                value: formatAmountWithNullChecking(item.amount),
                                className: "col-16 col-md-4 col-xl-2 col-xxl-2 mt-xl-4 mt-md-4",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            noPadding
            controls={
                <NmButton
                    onClick={() => {
                        onOpenModal({
                            isOpenCreatePayment: true,
                        });
                    }}
                    icon={<AddIcon />}
                    color="green"
                    size="xl"
                    disabled={progress}
                    children="Создать внутренний перевод средств между счетами в ТКБ"
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            classNameContent="relative"
        >
            {
                progress &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            {
                isEmpty(list)
                    ? <NmEmptyPageV2 />
                    : (
                        <CheckboxList
                            rows={getRows()}
                        />
                    )
            }
            {getCreatePaymentModal()}
        </NmPage>
    );
};