import React, {FC, SVGProps} from "react";

import AmountInput from "../../AmountInput";

import {getClassNames} from "../../../utils/classNames";

import "./style.sass";

interface InputWithIcon {
    className?: string,
    icon: React.FunctionComponent<SVGProps<SVGElement>>,
    iconColor?: string,
    [key: string]: any,
}

export const InputWithIcon: FC<InputWithIcon> = (props) => {
    const {
        className,
        icon,
        iconColor,
        ...otherProps
    } = props;

    return (
        <div
            className={getClassNames([
                "input-with-icon",
                className,
            ])}
        >
            <AmountInput {...otherProps} />
            {
                React.createElement(icon, {
                    className: "input-with-icon__icon",
                    color: iconColor,
                })
            }
        </div>
    );
};

export default InputWithIcon;