import React from "react";
import {useSelector} from "react-redux";

import DropzoneV2 from "../../../../../components/ActualComponents/DropzoneV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {RecruitmentAccountDropdownWithTooltip} from "../../../../../components/Recruitment/AccountDropdownWithTooltip";
import {
    bffRecruitmentVacancyLogoProgressSelector,
    bffRecruitmentVacancyProgressActionSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {RecruitmentVacancyDescriptionEditor} from "../description";

import useCreateVacancyOnJobBoardForm from "./hooks/useForm";

import {RECRUITMENT_SOURCE_JOB_BOARD, RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../constants";

const CreateVacancyOnJobBoardForm = (props) => {
    const {
        clientId,
        vacancyId,
        jobBoardType,
        data,
        isUpdate = false,
        onClose,
        fetchInfo,
    } = props;

    const progress = useSelector(bffRecruitmentVacancyProgressActionSelector);
    const progressLogo = useSelector(bffRecruitmentVacancyLogoProgressSelector);

    const {
        handleSubmit,
        values,
        touched,
        errors,
        setFieldValue,
        formConfig,
    } = useCreateVacancyOnJobBoardForm({
        clientId,
        vacancyId,
        jobBoardType,
        data,
        isUpdate,
        onClose,
        fetchInfo,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Дозаполнение полей для джоб-борда
                    {" "}
                    {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[jobBoardType]}
                </NmTitle>
            }
            loading={progress}
            footer={
                <ApplyButtons
                    mobile="column"
                    cancelBtnContent="Отменить"
                    submitBtnContent={isUpdate ? "Обновить" : "Создать"}
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progress || progressLogo}
                />
            }
        >
            <NmForm addMargin>
                {
                    !isUpdate &&
                    <RecruitmentAccountDropdownWithTooltip
                        required={true}
                        clientId={clientId}
                        jobBoardType={jobBoardType}
                        label="Аккаунт"
                        placeholder="Выберите"
                        name="jobBoardAccountId"
                        onChange={(event, {name, value}) => setFieldValue(name, value)}
                        value={values.jobBoardAccountId}
                        error={touched.jobBoardAccountId && errors.jobBoardAccountId}
                    />
                }
                {
                    formConfig.fields.map(item => {
                        const {
                            isOptionInState,
                        } = item;

                        const dropdownValue = isOptionInState ? values[item.name]?.value : values[item.name];

                        return (
                            <NmDropdownV2
                                key={item.name}
                                required={item.required}
                                size="xl"
                                search={true}
                                disabled={item.disabled}
                                name={item.name}
                                label={item.label}
                                placeholder="Выберите"
                                value={dropdownValue}
                                options={item.options}
                                onChange={
                                    (event, option) => {
                                        setFieldValue(
                                            option.name,
                                            isOptionInState
                                                ? option
                                                : option.value,
                                        );
                                    }
                                }
                                error={touched[item.name] && errors[item.name]}
                                tooltip={item.tooltip}
                                isVisibleTooltip={item.isVisibleTooltip}
                            />
                        );
                    })
                }
                {
                    [
                        RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER,
                        RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU,
                        RECRUITMENT_SOURCE_JOB_BOARD.AVITO,
                    ].includes(jobBoardType) &&
                    <RecruitmentVacancyDescriptionEditor
                        value={values.description}
                        onChange={(_event, {value}) => {
                            setFieldValue("description", value);
                        }}
                        error={touched.description && errors.description}
                    />
                }
                {
                    [
                        RECRUITMENT_SOURCE_JOB_BOARD.AVITO,
                    ].includes(jobBoardType) &&
                    <DropzoneV2
                        label="Логотип"
                        isVisibleLabel={true}
                        maxSize={24}
                        files={values.logo}
                        onChange={(files) => {
                            setFieldValue("logo", files);
                        }}
                        multiple={false}
                        format="*.JPEG, *.PNG, *.GIF"
                        accept={[".jpeg", ".png", ".gif"]}
                    />
                }
            </NmForm>
        </NmModal>
    );
};

export default CreateVacancyOnJobBoardForm;