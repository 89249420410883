import {
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_SUCCESS,
    CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTORS,
    CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE,
    UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE,
} from "./actions";

const contractorsInitialState = {
    list: [],
    totalCount: 0,
    progress: false,
    pageData: {},
};

const initialState = {
    list: [],
    pageData: {},
    totalCount: 0,
    progress: false,
    progressAction: false,
    card: {},
    error: null,
    statusDict: {},
    warningsPageData: null,
    contractors: contractorsInitialState,
    frameContracts: [],
    warnings: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTORS:
            return {
                ...state,
                contractors: contractorsInitialState,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_SUCCESS:
            return {
                ...state,
                frameContracts: payload,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST:
            return {
                ...state,
                contractors: {
                    ...state.contractors,
                    pageData: payload,
                    progress: true,
                },
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_SUCCESS:
            return {
                ...state,
                contractors: {
                    ...state.contractors,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_ERROR:
            return {
                ...state,
                contractors: {
                    ...state.contractors,
                    progress: false,
                },
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST:
            return {
                ...state,
                warningsPageData: payload,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_SUCCESS:
            return {
                ...state,
                warnings: payload.result,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_ERROR:
            return {
                ...state,
                warningsPageData: null,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_SUCCESS:
            return {
                ...state,
                warningsPageData: null,
                progressAction: false,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST: {
            return {
                ...state,
                error: null,
                progressAction: true,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_SUCCESS:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_SUCCESS:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_SUCCESS:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_SUCCESS:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_SUCCESS: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_ERROR: {
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        }
        case UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_SUCCESS:
            return {
                ...state,
                statusDict: payload,
            };
        case CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};