import React, {useState} from "react";
import DatePicker from "react-datepicker";

import ErrorTooltip from "../ErrorTooltip";
import NmLabel from "../NmLabel";
import NmDatePickerInput from "./Input";

import bem from "./../../../utils/bem";

import "./style.sass";

const NmDatePicker = (props) => {
    const {
        className = "",
        classNameField,
        placeholderText = "дд.мм.гг",
        dateFormatMask = "99.99.99",
        size = "xl",
        disabled,
        required,
        error,
        label,
        readOnlyInput = true,
        value,
        onCalendarOpen,
        onCalendarClose,
        onMouseDownInput,
        isErrorStyle,
        isClearable,
        onChange,
        onBlur,
        onFocus,
        name,
        changed,
        suggestion,
        onMouseEnter,
        tooltip,
        isVisibleTooltip,
        warning,
        isRelative = true,
        ...otherProps
    } = props;

    const [block, element] = bem("nm-date-picker", className);
    const [focused, setFocused] = useState(false);

    const _onCalendarOpen = () => {
        if (onCalendarOpen) {
            onCalendarOpen();
        }
        onFocus();
        setFocused(true);
    };
    const _onCalendarClose = () => {
        if (onCalendarClose) {
            onCalendarClose();
        }
        onBlur();
        setFocused(false);
    };

    const _onChange = (data) => {
        onChange(null, {name, value: data});
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            className={block({
                size,
                active: Boolean(value),
                error: Boolean(isErrorStyle),
                time: props.showTimeSelect,
                relative: isRelative,
            })}
        >
            {
                label &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                    tooltip={tooltip}
                    isVisibleTooltip={isVisibleTooltip}
                />
            }
            <DatePicker
                {...otherProps}
                onChange={_onChange}
                isClearable={!disabled && isClearable}
                onCalendarClose={_onCalendarClose}
                onCalendarOpen={_onCalendarOpen}
                value={value}
                disabled={disabled}
                customInput={
                    <NmDatePickerInput
                        placeholderText={placeholderText}
                        dateFormatMask={dateFormatMask}
                        changed={changed}
                        className={`${classNameField} ${element("custom-input")}`}
                        size={size}
                        error={error}
                        focused={focused}
                        onMouseDownInput={onMouseDownInput}
                        readOnlyInput={readOnlyInput}
                        disabled={disabled}
                        warning={warning}
                    />
                }
            />
            {
                (error || warning) &&
                <ErrorTooltip
                    error={error}
                    warning={warning}
                />
            }
            {
                suggestion &&
                <div className={element("suggestion")}>
                    {suggestion}
                </div>
            }
        </div>

    );
};

NmDatePicker.defaultProps = {
    locale: "ru",
    showMonthDropdown: true,
    useShortMonthInDropdown: true,
    showYearDropdown: true,
    scrollableYearDropdown: true,
    yearDropdownItemNumber: 100,
    dateFormat: "dd.MM.yyyy",
    timeFormat: "HH:mm",
    onBlur: () => {},
    onFocus: () => {},
};

export default NmDatePicker;