import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const selector = state => state.kedoTemplates;
export const kedoTemplatesListSelector = createSelector(
    selector,
    ({list}) => list,
);
export const kedoTemplatesTotalCountSelector = createSelector(
    selector,
    ({totalCount}) => totalCount,
);
export const kedoTemplatesTotalPageSelector = createSelector(
    selector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const kedoTemplatesProgressSelector = createSelector(
    selector,
    ({progress}) => progress,
);
export const kedoTemplatesProgressActionSelector = createSelector(
    selector,
    ({progressAction}) => progressAction,
);
export const kedoTemplatesUsersFieldNamesSelector = createSelector(
    selector,
    ({usersFieldNames}) => usersFieldNames,
);

export const kedoTemplatesProgressUsersFieldNamesSelector = createSelector(
    selector,
    ({progressUsersFieldNames}) => progressUsersFieldNames,
);
export const kedoTemplatesErrorSelector = createSelector(
    selector,
    ({error}) => error,
);
export const kedoTemplatesProgressPreviewSelector = createSelector(
    selector,
    ({progressPreview}) => progressPreview,
);
export const kedoTemplateIsSuccessSelector = createSelector(
    selector,
    ({isSuccess}) => isSuccess,
);
export const kedoTemplateLogsListSelector = createSelector(
    selector,
    ({logsList}) => logsList,
);