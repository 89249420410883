import {useEffect} from "react";

import {useKedoDocumentsBff} from "./useBff";

export const useKedoDocumentsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
    } = params;

    const {
        fetchPage,
        clearStore,
    } = useKedoDocumentsBff();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
    ]);

    useEffect(() => {
        return () => {
            clearStore();
        };
    }, []);

    const fetchList = () => {
        fetchPage({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            ...filterData,
        });
    };

    return {
        fetchList,
    };
};