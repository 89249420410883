export const REG_EXP = {
    LOGIN: /^[0-9a-zA-Z.\-_@]+$/,
    ID_DOC_NUMBER: /^[0-9a-zA-Z]+$/,
    FORBIDDEN_WIN_SYMBOLS: /[/\\:*?"<>]/,
    ONLY_NUMBERS: /^[0-9]+$/,
    STRING_PARAM: /\{(\d+)}/,
    NUMBERS_LETTERS_PUNCTUATION: /^[а-яА-Яa-zA-Z,\s0-9]*$/,
    EMAIL: /^(([^а-яА-ЯёЁ<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    URL: /(https?:\/\/[^\s]+)/,
    LINK_WITH_TAG: /<a href="http(.*?)">(.*?)<\/a>/,
    NAME: /^[а-яёЁА-Я\-\s]+$/,
    DOCUMENT_EXTERNAL_ID: /^[0-9a-zA-Z_]+$/,
    NUMBERS_LETTERS_SPACE: /[^a-zA-Z0-9 ]/g,
};