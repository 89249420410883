import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmPage from "../../../../../../components/NmPage";
import MapTaskListCard from "../../../task-list-card";

import {usePagination} from "../../../../../../hooks/usePagination";
import {useTasksFetchList} from "./hooks/useFetchList";

import {
    bffMapProgressListSelector,
    bffMapTasksSelector,
    bffMapTasksTotalCountSelector,
    bffMapTasksTotalPagesSelector,
} from "../../../../../../ducks/bff/map/selectors";

function MapTasksList(props) {
    const {
        filter,
        isSearch,
        onOpenInviteModal,
    } = props;

    const list = useSelector(bffMapTasksSelector);
    const totalCount = useSelector(bffMapTasksTotalCountSelector);
    const totalPages = useSelector(bffMapTasksTotalPagesSelector);
    const progress = useSelector(bffMapProgressListSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    useTasksFetchList({
        pageNum,
        pageSize,
        filter,
    });

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.taskId,
                contentRow: <MapTaskListCard
                    task={item}
                    isShowInviteButton={true}
                    onOpenInviteModal={onOpenInviteModal}
                />,
            };
        });
    };

    return (
        <NmPage
            noPadding
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {
                list?.length ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
}

export default MapTasksList;