import React from "react";
import {useSelector} from "react-redux";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import LabelTextApp from "../../../../../components/LabelTextApp";
import NmButton from "../../../../../components/NmButton";
import NmTitle from "../../../../../components/NmTitle";
import {Loader} from "semantic-ui-react";

import {getPersonalDataStep} from "../../../../../components/FullRegistration/utils/getPersonalDataStep";
import {getFullRegistrationSteps} from "../../../../../components/FullRegistration/utils/getSteps";
import {isNMUsers} from "../../../../../utils/access";
import dateFormat, {formatDateWithoutTime, formatLocalDate} from "../../../../../utils/dateFormat";
import {phoneFormat} from "../../../../../utils/stringFormat";

import {FULL_REGISTRATION_STEP} from "../../../../../components/FullRegistration/constants";
import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES, CONTRACTOR_FILE_TYPES_TRANSLATE} from "../../../../../constants/clientList";
import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    CONTRACTOR_ARRIVAL_DOC_TYPE_DICT,
    CONTRACTOR_FIELD,
    CONTRACTOR_MIGRATION_STATUS,
} from "../../../../../constants/contractor";
import {GENDER} from "../../../../../constants/contractorInfo";
import {CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES_TRANSLATE} from "../../../contractor-verification-data/constants";

import {
    contractorVhiPolicyFormatsDictSelector,
    contractorVhiPolicyTypesDictSelector,
    getCitizenshipSelector,
} from "../../../../../ducks/contractor";

import "./style.sass";

export default function ContractorEventLogOrderHistoryCard(props) {
    const {
        toggleCard,
        item,
        loadingScans,
    } = props;
    const {
        logs,
        submissionDateTime,
        contractorPendingOrderNum,
        pendingPersonalDataType,
    } = item;
    const scans = useSelector(state => state.fileStore.eventLogContractorOriginalScanObj);
    const citizenshipDict = useSelector(getCitizenshipSelector);
    const vhiPolicyTypesDict = useSelector(contractorVhiPolicyTypesDictSelector);
    const vhiPolicyFormatsDict = useSelector(contractorVhiPolicyFormatsDictSelector);

    const reverseLogs = [...logs].reverse();

    const {
        pendingPersonalDataModel = {},
        phone,
    } = reverseLogs[logs.length - 1] || {};
    const {personalData = {}} = pendingPersonalDataModel;

    const {
        citizenship,
        migrationStatus,
        arrivalDocType,
    } = personalData;
    const isRu = citizenship === citizenshipsDict.RU.value;

    const {
        num,
        issuedDate,
        validToDate,
        issuedBy,
        personalFileNum,
        birthPlace,
        issueReason,
    } = getPersonalDataStep({
        citizenship,
        migrationStatus,
        isCheckPersonalData: true,
    });

    const steps = getFullRegistrationSteps({
        citizenship,
        migrationStatus,
    });

    function onOpenScan(base64str) {
        const image = new Image();

        const styles = {
            width: "50%",
            display: "block",
            margin: "0 auto",
        };

        image.src = `data:image/jpeg;base64,${base64str}`;

        if (styles) {
            Object
                .entries(styles)
                .forEach(([name, value]) => {
                    image.style[name] = value;
                });
        }

        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    function renderPersonalData() {
        return (
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Персональные данные
                </div>
                <LabelTextApp
                    label="Фамилия"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.LAST_NAME]}
                />
                <LabelTextApp
                    label="Имя"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.FIRST_NAME]}
                />
                {
                    personalData[CONTRACTOR_FIELD.PATRONYMIC] &&
                    <LabelTextApp
                        label="Отчество"
                        className="contractor-event-log-order-history-card__item"
                        text={personalData[CONTRACTOR_FIELD.PATRONYMIC]}
                    />
                }
                <LabelTextApp
                    label="Дата рождения"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData[CONTRACTOR_FIELD.BIRTH_DATE])}
                />
                <LabelTextApp
                    label="Пол"
                    className="contractor-event-log-order-history-card__item"
                    text={GENDER[personalData[CONTRACTOR_FIELD.GENDER]]}
                />
                {
                    citizenship === citizenshipsDict.RU.value &&
                    <LabelTextApp
                        label="Фактический адрес проживания"
                        className="contractor-event-log-order-history-card__item"
                        text={personalData[CONTRACTOR_FIELD.ADDRESS]}
                    />
                }
                {
                    citizenship === citizenshipsDict.KG.value &&
                    <LabelTextApp
                        label="Место жительства"
                        className="contractor-event-log-order-history-card__item"
                        text={personalData[CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS]}
                    />
                }
                {
                    personalData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS] &&
                    <LabelTextApp
                        label="Адрес места жительства"
                        className="contractor-event-log-order-history-card__item"
                        text={personalData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS]}
                    />
                }
                {personalData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE] && <LabelTextApp
                    className="contractor-event-log-order-history-card__item"
                    label="Дата окончания срока действия"
                    text={dateFormat(personalData[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE], "dd.MM.yyyy")}
                />}
                {phone && <LabelTextApp
                    label="Номер телефона"
                    className="contractor-event-log-order-history-card__item"
                    text={phoneFormat(phone)}
                />}
                <LabelTextApp
                    label="ИНН"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.INN]}
                />
            </div>
        );
    }

    const getInfoPassportData = () => {
        function getNumLabel() {
            if (
                [
                    CONTRACTOR_MIGRATION_STATUS.REFUGEE,
                    CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
                ].includes(migrationStatus)
            ) {
                return "Серия и номер";
            }

            if (citizenship === citizenshipsDict.NOT_SPECIFIED.value) {
                return "Номер";
            }

            return isRu ? "Серия и номер паспорта" : "Номер паспорта";
        }

        const baseLabels = [
            {label: getNumLabel(), text: personalData[num]},
            citizenship === citizenshipsDict.NOT_SPECIFIED.value && {
                label: "Причина выдачи", text: personalData[issueReason],
            },
            {label: "Кем выдано", text: personalData[issuedBy]},
            [
                CONTRACTOR_MIGRATION_STATUS.REFUGEE,
                CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
            ].includes(migrationStatus) &&
            {label: "Номер личного дела", text: personalData[personalFileNum]},
            {
                label: "Дата выдачи",
                text: dateFormat(personalData[issuedDate], "dd.MM.yyyy"),
                className: "col-16 col-md-4",
            },
            isRu ?
                {
                    label: "Код подразделения",
                    text: personalData.idDocIssuedByDepartmentCode,
                    className: "col-16 col-md-4",
                } :
                {
                    label: "Дата окончания срока действия",
                    text: dateFormat(personalData[validToDate], "dd.MM.yyyy"),
                },
            {label: "Место рождения", text: personalData[birthPlace]},
        ];

        if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.REFUGEE) {
            return {
                title: "Данные из удостоверения беженца",
                labels: baseLabels,
            };
        }

        if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE) {
            return {
                title: "Данные из свидетельства о предоставлении временного убежища",
                labels: baseLabels,
            };
        }

        if (citizenship === citizenshipsDict.NOT_SPECIFIED.value) {
            return {
                title: "Данные из временного удостоверения личности лица без гражданства",
                labels: baseLabels,
            };
        }

        return {
            title: "Паспортные данные",
            labels: [
                ...baseLabels,
                ![citizenshipsDict.RU.value, citizenshipsDict.AM.value].includes(citizenship) &&
                {label: "Идентификационный номер", text: personalData.ein},
            ],
        };
    };

    function renderPassportData() {
        return (
            <div className="contractor-event-log-order-history-card-block">
                <LabelTextApp
                    className="contractor-event-log-order-history-card__item"
                    label="Гражданство"
                    text={citizenshipDict[personalData[CONTRACTOR_FIELD.CITIZENSHIP]]}
                />
                <div className="contractor-event-log-order-history-card__title">
                    {getInfoPassportData().title}
                </div>
                {getInfoPassportData().labels.filter(item => Boolean(item)).map(({label, text}) => (
                    <LabelTextApp
                        className="contractor-event-log-order-history-card__item"
                        label={label}
                        text={text}
                    />
                ))}
            </div>
        );
    }

    function renderMigrationCardData() {
        return (
            ![citizenshipsDict.RU.value, citizenshipsDict.BY.value].includes(citizenship) &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Миграционная карта
                </div>
                <LabelTextApp
                    label="Серия и номер карты"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]}
                />
                <LabelTextApp
                    label="Дата выдачи"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE])}
                />
                <LabelTextApp
                    label="Дата окончания срока действия"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE])}
                />
            </div>
        );
    }

    const getInfoResidencePlaceNotResidentLabels = () => {
        if (arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT) {
            return [
                {label: "Адрес", text: personalData.address || personalData.mainDocumentStampAddress},
                {
                    label: "Дата выдачи в штампе",
                    text: dateFormat(personalData.registrationDate || personalData.mainDocumentStampIssuedDate, "dd.MM.yyyy"),
                },
                {
                    label: "Дата окончания срока действия в штампе",
                    text: dateFormat(personalData.arrivalNoticeStayEndDate || personalData.mainDocumentStampValidToDate, "dd.MM.yyyy"),
                },
            ];
        }

        if (arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION) {
            return [
                {label: "Адрес", text: personalData.address},
                {label: "Дата постановки на учет", text: dateFormat(personalData.registrationDate, "dd.MM.yyyy")},
                {
                    label: "Дата окончания срока пребывания",
                    text: dateFormat(personalData.arrivalNoticeStayEndDate, "dd.MM.yyyy"),
                },
            ];
        }

        return [];
    };

    function renderNotificationOfArrivalData() {
        return (
            citizenship !== citizenshipsDict.RU.value &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Регистрация иностранного гражданина по месту жительства
                </div>
                <LabelTextApp
                    label="Регистрация"
                    className="contractor-event-log-order-history-card__item"
                    text={CONTRACTOR_ARRIVAL_DOC_TYPE_DICT[personalData.arrivalDocType]}
                />
                {
                    getInfoResidencePlaceNotResidentLabels().map(({label, text}) => (
                        <LabelTextApp
                            label={label}
                            className="contractor-event-log-order-history-card__item"
                            text={text}
                        />
                    ))
                }
            </div>
        );
    }

    function renderEmploymentHistoryData() {
        return (
            [citizenshipsDict.TJ.value, citizenshipsDict.UZ.value, citizenshipsDict.UA.value].includes(citizenship) &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Трудовая книжка
                </div>
                {
                    personalData[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT] && personalData[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM] ?
                        <LabelTextApp
                            label="Номер"
                            className="contractor-event-log-order-history-card__item"
                            text={personalData[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM]}
                        /> :
                        "Не указан"
                }
            </div>
        );
    }

    function renderSnilsData() {
        return (
            ["CONTRACTOR_AND_EDM", "EDM"].includes(pendingPersonalDataType) &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    СНИЛС
                </div>
                {
                    personalData[CONTRACTOR_FIELD.SNILS_PRESENT] && personalData[CONTRACTOR_FIELD.SNILS_NUM] ?
                        <LabelTextApp
                            label="Номер"
                            className="contractor-event-log-order-history-card__item"
                            text={personalData[CONTRACTOR_FIELD.SNILS_NUM]}
                        /> :
                        "Не указан"
                }
            </div>
        );
    }

    function renderPatentData() {
        return (
            [citizenshipsDict.TJ.value, citizenshipsDict.UZ.value, citizenshipsDict.UA.value].includes(citizenship) &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Патент
                </div>
                <LabelTextApp
                    label="Серия"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES]}
                />
                <LabelTextApp
                    label="Номер"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM]}
                />
                <LabelTextApp
                    label="Регистрационный номер"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER]}
                />
                <LabelTextApp
                    label="Дата выдачи"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE])}
                />
            </div>
        );
    }

    function renderVhiPolicyData() {
        return (
            steps.includes(FULL_REGISTRATION_STEP.VHI_POLICY_SCAN) &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Страховой полис
                </div>
                <LabelTextApp
                    label="Полис"
                    className="contractor-event-log-order-history-card__item"
                    text={vhiPolicyTypesDict[personalData.vhiPolicyType]}
                />
                <LabelTextApp
                    label="Тип полиса"
                    className="contractor-event-log-order-history-card__item"
                    text={vhiPolicyFormatsDict[personalData.vhiPolicyFormat]}
                />
                <LabelTextApp
                    label="Дата начала действия договора"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData[CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE])}
                />
                <LabelTextApp
                    label="Дата окончания действия договора"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData[CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE])}
                />
            </div>
        );
    }

    function renderResidencePermitData() {
        return (
            steps.includes(FULL_REGISTRATION_STEP.RESIDENCE_PERMIT) &&
            <div className="contractor-event-log-order-history-card-block">
                <div className="contractor-event-log-order-history-card__title">
                    Вид на жительство
                </div>
                <LabelTextApp
                    label="Серия и номер"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData.residencePermitNumber}
                />
                <LabelTextApp
                    label="Номер принятия решения"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData.residencePermitDecisionNumber}
                />
                <LabelTextApp
                    label="Дата принятия решения"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData.residencePermitDecisionDate)}
                />
                <LabelTextApp
                    label="Дата выдачи"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData.residencePermitIssuedDate)}
                />
                <LabelTextApp
                    label="Дата окончания срока действия"
                    className="contractor-event-log-order-history-card__item"
                    text={formatDateWithoutTime(personalData.residencePermitValidToDate)}
                />
                <LabelTextApp
                    label="Кем выдан"
                    className="contractor-event-log-order-history-card__item"
                    text={personalData.residencePermitIssuedBy}
                />
            </div>
        );
    }

    function renderScans() {
        if (loadingScans) {
            return <div className="contractor-event-log-order-history-card-loader-wrapper">
                <Loader
                    // className={element('loader')}
                    active
                    inline
                    content="Загрузка"
                />
            </div>;
        }

        return Object.keys(scans).map(key => {
            const {base64str} = scans[key];

            if (!personalData[CONTRACTOR_FIELD.SNILS_PRESENT] && key === CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN) {
                return null;
            }

            if (!personalData[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT] && key === CONTRACTOR_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN) {
                return null;
            }

            return (
                <div className="contractor-event-log-order-history-card-scans-block">
                    <div className="contractor-event-log-order-history-card__title">
                        {CONTRACTOR_FILE_TYPES_TRANSLATE[key] || CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES_TRANSLATE[key]}
                    </div>
                    <div className="contractor-event-log-order-history-card-scans-block-img-block">
                        <img
                            src={`data:image/jpeg;charset=utf-8;base64, ${base64str}`}
                            onClick={() => {
                                onOpenScan(base64str);
                            }}
                        />
                    </div>
                </div>
            );
        });
    }

    function renderFooter() {
        return (
            <div className="flex justify-content-end">
                <NmButton
                    color="white"
                    onClick={() => {
                        toggleCard();
                    }}
                >
                    Закрыть
                </NmButton>
            </div>
        );
    }

    return (
        <NmModal
            contentAlign="left"
            size="lg"
            onClose={() => {
                toggleCard();
            }}
            footer={renderFooter()}
            header={
                <NmTitle
                    align="left"
                    size="lg"
                >
                    Заявка № 
                    {" "}
                    {contractorPendingOrderNum}
                    {" "}
от 
                    {" "}
                    {formatLocalDate(submissionDateTime)}
                </NmTitle>
            }
        >
            <div className="contractor-event-log-order-history-card row">
                <div className="col-md-8">
                    {renderPersonalData()}
                    {renderPassportData()}
                    {renderNotificationOfArrivalData()}
                    {renderMigrationCardData()}
                    {/*{personalData[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT] && renderEmploymentHistoryData()}*/}
                    {/*{personalData[CONTRACTOR_FIELD.SNILS_PRESENT] && renderSnilsData()}*/}
                    {renderPatentData()}
                    {renderVhiPolicyData()}
                    {renderResidencePermitData()}
                </div>
                {isNMUsers() && <div className="col-md-7 contractor-event-log-order-history-card-offset">
                    {renderScans()}
                </div>}
            </div>
        </NmModal>
    );
}