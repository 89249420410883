export const CROWD_ACT_REGISTRY_STATUS = {
    FORMING: {
        VALUE: "FORMING",
        TEXT: "Формируется",
        MOD: "orange",
    },
    PROCESSING: {
        VALUE: "PROCESSING",
        TEXT: "В процессе",
        MOD: "light-green",
    },
    DONE: {
        VALUE: "DONE",
        TEXT: "Завершен",
        MOD: "gray",
    },
};

export const CROWD_ACT_REGISTRY_ENTRY_STATUS = {
    FORMING: {
        VALUE: "FORMING",
        TEXT: "Формируется",
        BADGE_MOD: "orange",
    },
    ERROR: {
        VALUE: "ERROR",
        TEXT: "Ошибка",
        BADGE_MOD: "red",
    },
    FORMED: {
        VALUE: "FORMED",
    },
    PROCESSING: {
        VALUE: "PROCESSING",
        TEXT: "В процессе",
        BADGE_MOD: "light-green",
    },
    REJECTED: {
        VALUE: "REJECTED",
    },
    DONE: {
        VALUE: "DONE",
        TEXT: "Завершено",
        BADGE_MOD: "gray",
    },
};

export const CROWD_ACT_REGISTRY_ENTRY_VALIDATION_STATUS = {
    FAILED: "FAILED",
};

export const CROWD_ACT_REGISTRY_COUNT_BLOCK_NAME = {
    DONE: "DONE",
    FORMED: "FORMED",
    FORMING: "FORMING",
    REJECTED: "REJECTED",
    PROCESSING: "PROCESSING",
    ERROR: "ERROR",
};

export const CROWD_ACT_REGISTRY_LOG_TYPE_DICT = {
    REGISTRY_CREATED: "Создание реестра",
    REGISTRY_EDITED: "Редактирование реестра",
    REGISTRY_DELETED: "Удаление реестра",
    REGISTRY_MOVED_TO_ARCHIVE: "Перемещение реестра в архив",
    REGISTRY_MOVED_FROM_ARCHIVE: "Восстановление реестра из архива",
    ENTRY_ADDED_MANUALLY: "Добавление строк в реестр вручную",
    ENTRY_IMPORTED: "Импорт строк реестра из файла",
    ENTRY_EDITED: "Редактирование строки в реестре",
    ENTRY_DELETED: "Удаление строк в реестре",
    REGISTRY_RUN: "Запуск реестра",
    ENTRY_REPEATED: "Повтор строк реестра",
};
