import React from "react";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";

function OrderGroupsFilter(props) {
    const {
        filter,
        submitFilter,
        onClear,
        onChangeFilter,
    } = props;

    const {
        groupNameFilter,
        fromCreateDateFilter,
        toCreateDateFilter,
    } = filter;

    const submit = () => {
        submitFilter(filter);
    };

    return (
        <NmForm
            addMargin
        >
            <FilterButtonsV2
                className="order-templates-filter__filter-buttons"
                onClear={onClear}
                onSearch={submit}
            />
            <NmInputV2
                size="lg"
                label="Наименование группы заказов"
                name="groupNameFilter"
                value={groupNameFilter}
                onChange={onChangeFilter}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="fromCreateDateFilter"
                endFieldName="toCreateDateFilter"
                value={{fromCreateDateFilter, toCreateDateFilter}}
                label="Дата создания"
                onChange={onChangeFilter}
            />
        </NmForm>
    );
}

export default OrderGroupsFilter;