import React from "react";

import TemplatesContracts from "../contracts";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

const OtherDocumentsList = (props) => {
    return (
        <TemplatesContracts
            headerText="Иные документы"
            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS}
            {...props}
        />
    );
};

export default OtherDocumentsList;