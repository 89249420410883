import {useState} from "react";

import {useModal} from "../../../../hooks/useModal";

export const useContractorFullRegistration = ({}) => {
    const {
        modalData: fullRegistrationData,
        onOpenModal: onOpenFullRegistration,
        onCloseModal: onCloseFullRegistration,
    } = useModal();

    const [isOpenEditForm, setOpenEditForm] = useState(false);

    const toggleEditModal = () => {
        setOpenEditForm(!isOpenEditForm);
    };

    return {
        onOpenFullRegistration,
        fullRegistrationData,
        onCloseFullRegistration,
        isOpenEditForm,
        toggleEditModal,
    };
};