import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import CardApp from "../../../../components/CardApp";
import NmTitle from "../../../../components/NmTitle";
import TableDiv from "../../../../components/TableDiv";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as CloseIcon} from "../../../../images/close_24.svg";
import {ReactComponent as EditIcon} from "../../../../images/create.svg";
import ModulesAndTariffsEditModal from "./components/edit-modal";

import {useModal} from "../../../../hooks/useModal";

import {getUserRole} from "../../../../utils/access";
import bem from "../../../../utils/bem";
import {handleFormString} from "../../../../utils/stringHelper";
import {toastSuccess} from "../../../../utils/toastHelper";

import {CLIENT_WORK_TYPE} from "../../../../constants/clientSettings";
import {COLOR} from "../../../../constants/color";
import {clientModulesAndTariffsHeaders} from "../../../../constants/headersTable";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

import {deleteClientModuleTariff} from "../../../../ducks/bff/clients/module-tariffs/actionCreators";
import {clientModuleTariffsProgressActionSelector} from "../../../../ducks/bff/clients/module-tariffs/selectors";
import {getClientCardSelector} from "../../../../ducks/client";
import {
    clientPropertiesProgressActionSelector,
    getClientPropertiesById,
    getClientPropertiesCardSelector,
    getProgressClientProperties,
    updateClientProperties} from "../../../../ducks/clientProperties";

import "./style.sass";

function ModulesAndTariffs(props) {
    const {
        clientId,
    } = props;

    const clientProperties = useSelector(getClientPropertiesCardSelector);
    const {archived} = useSelector(getClientCardSelector);
    const progress = useSelector(getProgressClientProperties);
    const progressAction = useSelector(clientPropertiesProgressActionSelector);
    const clientModuleTariffsProgressAction = useSelector(clientModuleTariffsProgressActionSelector);

    const role = getUserRole();
    const isEditable = [
        ADMIN,
        NM_MANAGER,
    ].includes(role);

    const {
        edoTariff,
        recruitmentTariff,
        moduleRecruitmentTariff,
        clientWorkTypeTariff,
        clientWorkType,
        clientModuleTariffs,
    } = clientProperties;

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const [block, element] = bem("modules-and-tariffs-block");

    const dispatch = useDispatch();

    const initialValuesEditMadal = {
        recruitmentTariff,
        moduleRecruitmentTariff,
    };

    const {
        handleSubmit,
        values,
        setValues,
        handleChange,
        errors,
        touched,
        isValid,
    } = useFormik({
        initialValues: initialValuesEditMadal,
        onSubmit: () => {
            if (!isValid) {
                return;
            }

            dispatch(updateClientProperties({
                ...clientProperties,
                recruitmentTariff: handleFormString(values.recruitmentTariff),
                moduleRecruitmentTariff: handleFormString(values.moduleRecruitmentTariff),
                onSuccess: () => {
                    onCloseEditModal();
                    fetchClientProperties();
                },
            }));
        },
        validationSchema: () => {
            return yup.object().shape({
                recruitmentTariff:
                    modalData?.editData?.field === "recruitmentTariff" && yup.string()
                        .nullable()
                        .required(VALIDATIONS_MESSAGE.REQUIRED),
                moduleRecruitmentTariff:
                    modalData?.editData?.field === "moduleRecruitmentTariff" && yup.string()
                        .nullable()
                        .required(VALIDATIONS_MESSAGE.REQUIRED),
            });
        },
        validateOnBlur: false,
        validateOnMount: false,
    });

    useEffect(() => {
        setValues({...clientProperties});
    }, [clientProperties]);

    const fetchClientProperties = () => {
        dispatch(getClientPropertiesById({clientId}));
    };

    const deleteClientModule = (id) => {
        dispatch(deleteClientModuleTariff({
            id,
            onSuccess: () => {
                fetchClientProperties();
                onCloseModal();
                toastSuccess("Компания успешно отредактирована");
            },
        }));
    };

    const getEditableTariff = (field) => {
        return (
            <div className="flex col-16 align-items-center">
                <div className={element("recruitment-tariff-text")}>
                    {clientProperties[field]}
                </div>
                {
                    isEditable && !archived &&
                    <EditIcon
                        className={element("edit-icon")}
                        color={COLOR.SECONDARY_45}
                        width={20}
                        height={20}
                        onClick={() => {
                            openEditModalField(field);
                        }}
                    />
                }
            </div>
        );
    };

    const rows = useMemo(() => {
        const clientModuleTariffsRows = clientModuleTariffs?.map(item => {
            const {
                id,
                tariffDescription,
                tariffName,
                tariffValue,
            } = item;

            return {
                module: <div className="flex col-16">
                    {tariffName}
                    {
                        tariffDescription &&
                        <HelpTooltip
                            className="ms-2"
                            info
                            width={18}
                            height={18}
                            children={tariffDescription}
                            position="bottom-left"
                        />
                    }
                </div>,
                tariff: <div className="flex col-16 align-items-center">
                    <div className={element("recruitment-tariff-text")}>
                        {tariffValue || "-"}
                    </div>
                    {
                        isEditable &&
                        <div className="flex align-items-start ms-auto">
                            <CloseIcon
                                cursor="pointer"
                                color={COLOR.SECONDARY_45}
                                width={20}
                                height={20}
                                onClick={() => onOpenModal({
                                    isOpenConfirm: true,
                                    content: `Вы действительно хотите удалить “${tariffName}”?`,
                                    confirmButton: "Удалить",
                                    onConfirm: () => deleteClientModule(id),
                                })}
                            />
                            <EditIcon
                                cursor="pointer"
                                className="ms-4"
                                color={COLOR.SECONDARY_45}
                                width={20}
                                height={20}
                                onClick={() => onOpenModal({
                                    isOpenClientModulesAndTariffEditModal: true,
                                    editData: item,
                                })}
                            />
                        </div>
                    }
                </div>,
                description: tariffDescription,
            };
        }) || [];

        return [
            {
                module: "ЭДО",
                tariff: edoTariff,
            },
            {
                module: "Рекрутмент",
                tariff: getEditableTariff("moduleRecruitmentTariff"),
            },
            [
                CLIENT_WORK_TYPE.ONLY_KEDO,
                CLIENT_WORK_TYPE.NAIMIX_KEDO,
            ].includes(clientWorkType) && {
                module: "КЭДО",
                tariff: clientWorkTypeTariff,
            },
            ...clientModuleTariffsRows,
        ].filter(Boolean);
    }, [clientProperties]);

    const mapTableData = () => {
        return {
            rows,
            headers: clientModulesAndTariffsHeaders,
        };
    };

    const openEditModalField = (field) => {
        let modalData = {field};

        if (field === "recruitmentTariff") {
            modalData = {
                ...modalData,
                title: "Редактирование модуля “Подбор исполнителей”",
            };
        }

        if (field === "moduleRecruitmentTariff") {
            modalData = {
                ...modalData,
                title: "Редактирование модуля “Рекрутмент”",
            };
        }

        onOpenModal({
            isOpenRecruitmentEditModal: true,
            editData: modalData,
        });
    };

    const onCloseEditModal = () => {
        onCloseModal();
        setValues(initialValuesEditMadal);
    };

    const renderRecruitmentTariffEditModal = () => {
        const {
            field,
            title,
        } = modalData?.editData || {};

        return (
            modalData?.isOpenRecruitmentEditModal &&
            <NmModal
                size="md"
                onClose={onCloseEditModal}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        className="col-16"
                        classNameMainButtons="col-16 justify-content-between"
                        submit={handleSubmit}
                        onClose={onCloseEditModal}
                        submitBtnContent="Сохранить"
                        cancelBtnContent="Отменить"
                        mobile="column"
                        isHiddenCancelOnMobile
                        disabled={progressAction}
                    />
                }
            >
                <NmForm addMargin>
                    <NmInputV2
                        required
                        size="xl"
                        label="Тариф"
                        onChange={handleChange}
                        value={values[field]}
                        name={field}
                        error={touched?.[field] && errors?.[field]}
                    />
                </NmForm>
            </NmModal>
        );
    };

    const renderClientModulesAndTariffEditModal = () => {
        return (
            modalData?.isOpenClientModulesAndTariffEditModal &&
            <ModulesAndTariffsEditModal
                clientId={clientId}
                editData={modalData?.editData}
                onClose={onCloseModal}
                fetch={fetchClientProperties}
            />
        );
    };

    const renderConfirmModal = () => {
        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                content={modalData.content}
                onCancel={onCloseModal}
                onConfirm={modalData.onConfirm}
                confirmButton={modalData.confirmButton}
                cancelButton="Отмена"
                disabled={progressAction || clientModuleTariffsProgressAction}
                isNeedClosing={false}
            />
        );
    };

    return (
        <CardApp
            className={block()}
            title="Модули и тарифы"
            loading={progress}
            controls={
                isEditable &&
                <AddIcon
                    width={24}
                    height={24}
                    onClick={() => onOpenModal({isOpenClientModulesAndTariffEditModal: true})}
                    color={COLOR.SECONDARY_45}
                />
            }
        >
            {renderRecruitmentTariffEditModal()}
            {renderClientModulesAndTariffEditModal()}
            {renderConfirmModal()}
            {
                rows.length ?
                    <TableDiv tableData={mapTableData()} /> :
                    "Данные отсутствуют"
            }
        </CardApp>
    );
}

export default ModulesAndTariffs;