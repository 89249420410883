import React from "react";

import bem from "./../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmPopup = (props) => {
    const {
        children,
        className,
    } = props;

    const [block, element] = bem("nm-popup", className);

    return (
        <div className={block()}>
            <div className={element("content")}>
                {children}
            </div>
        </div>
    );
};

NmPopup.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

export default NmPopup;