import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_MIGRATION_STATUS} from "../../../constants/contractor";
import {FULL_REGISTRATION_STEP} from "../constants";

export const getFullRegistrationSteps = (params) => {
    const {
        citizenship,
        migrationStatus,
        locatedOutsideRussia,
    } = params;

    const generalStepsStart = [
        FULL_REGISTRATION_STEP.CITIZENSHIP_AND_MIGRATION_STATUS,
    ];

    const generalStepsEnd= [
        FULL_REGISTRATION_STEP.INN,
    ];

    if (
        [
            citizenshipsDict.RU.value,
        ].includes(citizenship)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (locatedOutsideRussia) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            citizenshipsDict.LG.value,
            citizenshipsDict.DN.value,
        ].includes(citizenship)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    const passportTranslationStep = [
        citizenshipsDict.AM.value,
    ].includes(citizenship) && FULL_REGISTRATION_STEP.PASSPORT_TRANSLATION;

    if (
        ![
            citizenshipsDict.NOT_SPECIFIED.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            passportTranslationStep,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.RESIDENCE_PERMIT,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        ![
            citizenshipsDict.NOT_SPECIFIED.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            passportTranslationStep,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            CONTRACTOR_MIGRATION_STATUS.STUDENT,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            ![
                citizenshipsDict.RU.value,
                citizenshipsDict.DN.value,
                citizenshipsDict.LG.value,
                citizenshipsDict.BY.value,
                citizenshipsDict.NOT_SPECIFIED.value,
            ].includes(citizenship) && FULL_REGISTRATION_STEP.PASSPORT_TRANSLATION,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.VISA_PERSONAL_DATA_SPREAD,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.UNIVERSITY_LICENSE,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            CONTRACTOR_MIGRATION_STATUS.PATENT,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            passportTranslationStep,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.EMPLOYMENT_PATENT,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            citizenshipsDict.AM.value,
            citizenshipsDict.KG.value,
            citizenshipsDict.KZ.value,
            citizenshipsDict.UA.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.NONE,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            passportTranslationStep,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            citizenshipsDict.BY.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.NONE,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            CONTRACTOR_MIGRATION_STATUS.REFUGEE,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            CONTRACTOR_MIGRATION_STATUS.VISA,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            passportTranslationStep,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.VISA_PERSONAL_DATA_SPREAD,
            FULL_REGISTRATION_STEP.MIGRATION_CARD,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            citizenshipsDict.NOT_SPECIFIED.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    if (
        [
            citizenshipsDict.NOT_SPECIFIED.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
        ].includes(migrationStatus)
    ) {
        return [
            ...generalStepsStart,
            FULL_REGISTRATION_STEP.PERSONAL_DATA,
            FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT,
            FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
            FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            ...generalStepsEnd,
        ];
    }

    return [
        ...generalStepsStart,
        ...generalStepsEnd,
    ];
};