import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getContractorEventLogPage} from "../../../../../ducks/contractorEventLog";

export function useContractorEventLogFetchList({filter = {}, pageSize, pageNum, contractorIdFilter}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        setLoading(true);
        const requestData = {
            pageNum,
            pageSize,
            contractorIdFilter,
            ...filter,
            getResult: () => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            },
        };

        dispatch(getContractorEventLogPage(requestData));
    };

    return {
        loading,
        fetchList,
    };
}