import {useEffect} from "react";

import {useGetPrevValue} from "./useGetPrevValue";

export const useSuccessActionCallback = (params) => {
    const {
        progressAction,
        error,
        callback,
    } = params;

    const prevProgressAction = useGetPrevValue(progressAction);
    const isActionEnd = !progressAction
        && typeof prevProgressAction === "boolean"
        && progressAction !== prevProgressAction
        && !error;

    useEffect(() => {
        if (isActionEnd) {
            callback();
        }
    }, [
        isActionEnd,
    ]);

    return null;
};