export const CLIENT_SETTINGS_TAB = {
    ADDITIONAL_MODULES: "ADDITIONAL_MODULES", // Дополнительные модули
    DEPOSIT_AND_PAYMENTS: "DEPOSIT_AND_PAYMENTS", // Депозит и проведение оплат
    DOCUMENTS: "DOCUMENTS", // Документы
    WORK_WITH_CONTRACTORS: "WORK_WITH_CONTRACTORS", // Работа с исполнителями
    EXTERNAL_API_METHODS: "EXTERNAL_API_METHODS", // Методы внешнего API
    OTHER: "OTHER", // Другое
};

export const CLIENT_SETTINGS_TAB_NAME = {
    [CLIENT_SETTINGS_TAB.ADDITIONAL_MODULES]: "Дополнительные модули",
    [CLIENT_SETTINGS_TAB.DEPOSIT_AND_PAYMENTS]: "Депозит и проведение оплат",
    [CLIENT_SETTINGS_TAB.DOCUMENTS]: "Документы",
    [CLIENT_SETTINGS_TAB.WORK_WITH_CONTRACTORS]: "Работа с исполнителями",
    [CLIENT_SETTINGS_TAB.EXTERNAL_API_METHODS]: "Методы внешнего API",
    [CLIENT_SETTINGS_TAB.OTHER]: "Другое",
};

export const CLIENT_SETTINGS_GROUP = {
    ADDITIONAL_MODULES: "Дополнительные модули",
    DEPOSIT_AND_PAYMENTS: "Депозит и проведение оплат",
    DOCUMENTS: "Документы",
    CONTRACTORS: "Работа с исполнителями",
    API: "Методы внешнего API",
    MISC: "Другое",
};

export const CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE = "Настройки компании успешно обновлены";