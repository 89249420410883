import React from "react";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";

import dateFormat from "../../../../../utils/dateFormat";

const ContractorVerificationPreviousValueTooltip = (props) => {
    const {
        previousPersonalData = {},
        name,
        options,
        getChanged,
    } = props;

    const value = previousPersonalData[name];
    const isChanged = getChanged(name);

    if (!(isChanged && value)) {
        return null;
    }

    const _value = value instanceof Date ? dateFormat(value, "dd.MM.yyyy") : value;

    return (
        <HelpTooltip
            hover
            attention
            position="top"
            text={`До изменений: ${options ? options.find((item) => (item.value === value))?.text : _value}`}
        />
    );
};

export default ContractorVerificationPreviousValueTooltip;