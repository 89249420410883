import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import ContractorFingerprintingInfoEditForm from "./components/edit-form";

import {useContractorAdditionalDocuments} from "../../hooks/useAdditionalDocuments";
import {useFingerprintingInfoCardAction} from "./hooks/useAction";

import dateFormat from "../../../../../utils/dateFormat";
import {getCardModeByStatusSegment} from "../../utils/statusSegment";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../constants/clientList";
import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../../constants/contractor";

import {contractorCardSelector} from "../../../../../ducks/contractor";

const FingerprintingInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const {contractorId} = useSelector(contractorCardSelector);

    const {
        isOpenEditForm,
        toggleEditModal,
    } = useFingerprintingInfoCardAction({});

    const {
        requestDocumentButton,
        documentInfo,
        renderDeleteConfirm,
        toggleShowDeleteConfirm,
    } = useContractorAdditionalDocuments({
        contractorId,
        documentType: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.FINGERPRINTING,
    });

    const data = documentInfo.data || {};

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <ContractorFingerprintingInfoEditForm
                contractorId={contractorId}
                data={data}
                onClose={toggleEditModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Дактилоскопия (доп. документ)"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            onClickDelete={toggleShowDeleteConfirm}
            isVisibleDeleteButton={isEditable && documentInfo.canBeDeleted}
            accessEdit={isEditable}
            mode={getCardModeByStatusSegment(documentInfo.status)}
            lastUpdate={documentInfo.updateDateTime}
            mediaControlsRenderCount={{
                mobile: 0,
                tablet: 3,
                desktop: 3,
            }}
            mediaControlsButtons={[requestDocumentButton]}
        >
            {renderDeleteConfirm()}
            {renderEditForm()}
            {
                documentInfo.status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT ?
                    <Text
                        level="2"
                        color={COLOR.SECONDARY_45}
                    >
                        Сведения пока не добавлены
                    </Text> :
                    <ContractorInfoCardContent
                        labels={[
                            {label: "Номер документа о прохождении дактилоскопии (лицевая сторона)", text: data.fingerprintingDocumentNum},
                            {label: "Дата выдачи документа о прохождении дактилоскопии", text: dateFormat(data.fingerprintingDocumentIssuedDate, "dd.MM.yyyy")},
                            {label: "Кем выдан документ о прохождении дактилоскопии", text: data.fingerprintingDocumentIssuedBy},
                        ]}
                        scans={[
                            {
                                label: "Фотография лицевой стороны документа о прохождении дактилоскопии",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN,
                            },
                            {
                                label: "Фотография обратной стороны документа о прохождении дактилоскопии",
                                type: CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN,
                            },
                        ]}
                    />
            }
        </NmMiniInfoCard>
    );
};

export default FingerprintingInfoCard;