import {useState} from "react";

export const useNewClient = () => {
    const [isOpenNewClient, setOpenNewClient] = useState(false);

    const onOpenNewClient = () => {
        setOpenNewClient(true);
    };

    const onCloseNewClient = () => {
        setOpenNewClient(false);
    };

    return {
        isOpenNewClient,
        onOpenNewClient,
        onCloseNewClient,
    };
};