import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ExtLink from "../../../../components/ExtLink";
import NmButton from "../../../../components/NmButton";
import {styleLargeLink} from "../../../../styles/inline";
import ContractorListInfo from "../../../contractor/nm-contractor-list/contractor-list-info";

import {isNMUsers} from "../../../../utils/access";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getFullName} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CONTRACTOR_STATUS} from "../../../../constants/contractor";
import {
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
} from "../../../../constants/links";
import {HR_MANAGER, RNKO} from "../../../../constants/roles";
import {MARKER_TYPE} from "../../constants";

import {getCitizenshipSelector} from "../../../../ducks/contractor";
import {specialitiesAllV2DictSelector} from "../../../../ducks/speciality";

const MapContractorListCard = (props) => {
    const {
        contractor,
        isModalCard = false,
        isInviteModal = false,
        isShowInviteButton = false,
        onOpenInviteModal,
    } = props;

    const citizenshipList = useSelector(getCitizenshipSelector);
    const specialityDict = useSelector(specialitiesAllV2DictSelector);

    const role = ls(USER_ROLE);
    const {t} = useTranslation();

    const {
        contractorId,
        registrationStatus,
        lastName,
        firstName,
        patronymic,
        fullName,
    } = contractor;

    const openInviteModal = () => {
        onOpenInviteModal({
            ...contractor,
            type: MARKER_TYPE.CONTRACTOR,
        });
    };

    const renderLink = () => {
        let link = LINK_CONTRACTOR_PROFILE;

        if (role === HR_MANAGER) {
            link = LINK_CONTRACTOR_REVIEWS_LIST;
        }

        if (RNKO === role || ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(registrationStatus) && isNMUsers())) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        link = link.replace(":contractorId", contractorId);
        const name = lastName ? getFullName(lastName, firstName, patronymic) : fullName;

        return (
            <ExtLink
                historyEnabled
                style={styleLargeLink}
                to={link}
            >
                {name || "-"}
            </ExtLink>
        );
    };

    return (
        <ContractorListInfo
            t={t}
            contractor={contractor}
            role={role}
            renderLinkFnc={renderLink}
            citizenshipList={citizenshipList}
            specialityDict={specialityDict}
            showPaymentInfo={false}
            isMapListPage={!isModalCard}
            isMapPage={isModalCard}
            isInviteModal={isInviteModal}
            actions={
                !isModalCard && isShowInviteButton && {
                    renderCount: {
                        desktop: 1,
                        tablet: 0,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                onClick: openInviteModal,
                                children: "Пригласить на заказ/задание",
                                color: "green",
                            },
                        },
                    ],
                }
            }
            isFixedActions={false}
            otherContent={
                isModalCard && isShowInviteButton &&
                <div className="col-16 mt-4">
                    <NmButton
                        className="col-16"
                        onClick={openInviteModal}
                    >
                        Пригласить
                    </NmButton>
                </div>
            }
        />
    );
};

export default MapContractorListCard;