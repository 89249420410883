import {useMemo} from "react";

import {FILTER} from "../../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../../hooks/useFilter";

import {handleFormString} from "../../../../../../utils/stringHelper";

export const useSettingsDirectoriesBanksSbpFilter = ({pageSize, setPagination}) => {
    const initFilter = {
        idFilter: "",
        nameFilter: "",
        displayNameFilter: "",
        bik: "",
    };

    function mapFilterDto(filter) {
        const {
            nameFilter,
            displayNameFilter,
            bik,
            idFilter,
        } = filter;

        return {
            ...filter,
            nameFilter: handleFormString(nameFilter),
            displayNameFilter: handleFormString(displayNameFilter),
            bik: handleFormString(bik),
            idFilter: handleFormString(idFilter),
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "idFilter",
                        label: "Идентификатор",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Название (англ.)",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "displayNameFilter",
                        label: "Название (рус.)",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "bik",
                        label: "БИК",
                    },
                ],
            },
        ];
    }, []);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        filters,
    };
};