import {useState} from "react";
import {useDispatch} from "react-redux";

import useConfirm, {closeConfirmAction, onConfirmAction, openConfirmAction} from "../../../../../hooks/useConfirm";
import {useModal} from "../../../../../hooks/useModal";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";

import {deleteAdmKedoTemplateDocumentType} from "../../../../../ducks/bff/adm/settings/directories/kedo/template-document-types/services";

export const useKedoTemplateDocumentTypesAction = (params) => {
    const {
        fetchList,
        fetchCounts,
    } = params;

    const dispatch = useDispatch();

    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm(true);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData(null);
    };

    const onOpenEdit = (editData) => {
        if (editData) {
            setEditData(editData);
        }

        setOpenEdit(true);
    };

    const onDelete = (documentTypeId, onClose) => {
        dispatch(deleteAdmKedoTemplateDocumentType({
            documentTypeId,
            onSuccess: () => {
                fetchCounts();
                fetchList();
                onClose();
            },
        }));
    };

    const onConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    const onOpenDelete = ({documentTypeId}) => {
        dispatchConfirm(
            openConfirmAction(
                "Вы действительно хотите удалить данный тип шаблонного документа из справочника?",
                ({onClose}) => onDelete(documentTypeId, onClose),
            ),
        );
    };

    const onCancelConfirm = () => {
        dispatchConfirm(closeConfirmAction());
    };

    const getListMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEdit(item);
                        },
                        color: "grey",
                        onlyIcon: true,
                        children: "Редактировать",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenClientUsage: true,
                                documentTypeId: item.documentTypeId,
                            });
                        },
                        color: "grey",
                        onlyIcon: true,
                        children: "Редактировать список компаний",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenDelete(item);
                        },
                        color: "grey",
                        onlyIcon: true,
                        children: "Удалить",
                    },
                },
            ],
        };
    };

    return {
        isOpenEdit,
        editData,
        isOpenConfirm,
        contentConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onCancelConfirm,
        modalData,
        onCloseModal,
        getListMediaControls,
    };
};