import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import {addOnboardingScenarioRecruiter} from "../../../../../../../../../ducks/onboardingRecruiters";
import validationSchema from "../validation";

export const useOnboardingScenarioRecruiterEditFormData = (props) => {
    const {
        card,
        clientId,
        scenarioId,
        fetchList,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const initialValues = {
        fullName: "",
    };

    const handleChange = (e, {name, value, checked}) => {
        setFieldValue(name, value || checked);
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(addOnboardingScenarioRecruiter({
            ...values,
            clientId,
            scenarioId,
            onSuccess: () => {
                fetchList();
                onClose();
            },
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        touched,
    };
};