import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import TableDiv from "../../../../components/TableDiv";
import {withPageData} from "../../../../components/withPageData";
import {Dimmer, Form, Loader} from "semantic-ui-react";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {CONTRACTOR_RECOMMENDATION_TYPE} from "../../../../constants/contractor";
import {headersOrderListForInviting} from "../../../../constants/headersTable";
import {REG_EXP} from "../../../../constants/regExp";
import {ORDER_STATUS_DICT} from "../../../../constants/status";

import {advertisementOrderInvite} from "../../../../ducks/advertisement";
import {
    contractorListWithFullNameSelector,
    getContractorByIds,
    recommendationModelTypeSelector,
} from "../../../../ducks/contractor";
import {
    getOrderListForInvitation,
    getOrderListProgressSelector,
    orderListSelector,
    orderTotalCountSelector,
    orderTotalPagesSelector,
} from "../../../../ducks/order";
import {addSeveralOrderContractors, orderProgressListSelector} from "../../../../ducks/orderContractors";

import PropTypes from "prop-types";

import "./style.sass";

class InviteContractorToOrderForm extends Component {
    static propTypes = {
        close: PropTypes.func,
        add: PropTypes.func,
        contractorIds: PropTypes.array,
        clientId: PropTypes.string,
        advertId: PropTypes.string,
        isShowInviteContractorsWarning: PropTypes.bool,
        onSuccess: PropTypes.func,
        isContractorPage: PropTypes.bool,
        searchCriteria: PropTypes.object,
        isInvitingContractorsPage: PropTypes.bool,
        isFilterEmpty: PropTypes.bool,
        isMassOperation: PropTypes.bool,
    };

    static defaultProps = {
        close: () => {
        },
        add: () => {
        },
        onSuccess: () => {
        },
        isShowInviteContractorsWarning: false,
        contractorList: {
            fullName: null,
        },
        isContractorPage: false,
        isFilterEmpty: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                pageNum: 1,
                pageSize: 5,
            },
            filter: {
                orderNum: "",
                orderName: "",
            },
            selectedOrdersIds: [],
            isShowInviteContractorsConfirm: false,
        };

        this.role = ls(USER_ROLE);
    }

    get contractorsFullName() {
        const {t, contractorList} = this.props;

        const contractorsFullName = (contractorList && contractorList[0].fullName) && contractorList.map(contractor => contractor.fullName).join(", ");

        return contractorList.length > 1 ?
            t("contractor-list.invite-contractors-to-order-confirm", {names: contractorsFullName}) :
            t("contractor-list.invite-contractor-to-order-confirm", {name: contractorList[0].fullName && contractorList[0].fullName});
    }

    componentDidMount() {
        const {
            getContractorByIds,
            contractorIds,
        } = this.props;

        this.fetchList();
        getContractorByIds(contractorIds);
    }


    static getDerivedStateFromProps(props, state) {
        const {list: oldList} = state;
        const {list} = props;

        if (!isEqual(list,oldList)) {
            return {
                ...state,
                selectedList: list,
                list,
            };
        }

        return state;
    }

    fetchList = () => {
        const {
            getOrderListForInvitation,
            contractorId,
            clientId,
        } = this.props;

        const {
            pageData,
            filter,
        } = this.state;

        getOrderListForInvitation({
            ...pageData,
            contractorId,
            clientId,
            orderNum: handleFormString(filter.orderNum),
            orderName: handleFormString(filter.orderName),
        });
    };

    onSelectedRows = (list) => {
        const selectedOrdersIds = list.filter(item => item.isSelected).map(order => order.orderId);

        this.setState({
            selectedList: list,
            selectedOrdersIds,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum,
            },
        }), this.fetchList);
    };

    renderOrderStatus = ({status}) => {
        if (isNullOrWhitespace(status)) {
            return;
        }

        return (
            <div
                className={status === ORDER_STATUS.CONTRACTOR_SEARCH ? "invite-contractor-to-order-form__status-field_gray" : "invite-contractor-to-order-form__status-field_green"}
            >
                {ORDER_STATUS_DICT[status].TEXT}
            </div>
        );
    };

    renderOrderNumField = ({orderNum, createDate}) => {
        return (
            <div className="invite-contractor-to-order-form__order-num-field">
                <div>
                    {orderNum}
                </div>
                <div
                    className="invite-contractor-to-order-form__order-num-field-date"
                >
                    {formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}
                </div>
            </div>
        );
    };

    mapTableData = () => {
        const {
            selectedList,
        } = this.state;

        const rows = selectedList.map(item => {
            return {
                ...item,
                orderNumField: this.renderOrderNumField(item),
                showCheckBox: true,
                orderStatus: this.renderOrderStatus(item),
                isSelected: item.isSelected,
            };
        });

        return {
            headers: headersOrderListForInviting,
            rows,
        };
    };

    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    handleChangeFilter = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    };

    onClearFilter = () => {
        this.setState({
            filter: {
                orderNum: "",
                orderName: "",
            },
        }, this.fetchList);
    };

    submitFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum: 1,
            },
        }), this.fetchList);
    };

    getRecommendationModelType = () => {
        const {
            recommendationModelType,
            searchCriteria,
            isInvitingContractorsPage,
            isFilterEmpty,
            isContractorPage,
        } = this.props;

        if (isContractorPage) {
            return CONTRACTOR_RECOMMENDATION_TYPE.CONTRACTOR_PAGE;
        }

        if (!isInvitingContractorsPage) {
            return CONTRACTOR_RECOMMENDATION_TYPE.CONTRACTOR_LIST;
        }

        const {
            fioFilter,
            innFilter,
            phoneFilter,
        } = searchCriteria;

        if ((fioFilter || innFilter || phoneFilter) && recommendationModelType === CONTRACTOR_RECOMMENDATION_TYPE.DEFAULT) {
            return CONTRACTOR_RECOMMENDATION_TYPE.FILTER;
        }

        if (!isFilterEmpty && recommendationModelType === CONTRACTOR_RECOMMENDATION_TYPE.DEFAULT) {
            return CONTRACTOR_RECOMMENDATION_TYPE.OTHER_FILTER;
        }

        return recommendationModelType;
    };

    sendInvite = () => {
        const {
            addSeveralOrderContractors,
            advertisementOrderInvite,
            isContractorPage,
            contractorIds,
            clientId,
            advertId,
            close,
            onSuccess,
            searchCriteria,
            t,
            isMassOperation,
        } = this.props;

        const {
            selectedOrdersIds,
        } = this.state;

        if (advertId) {
            advertisementOrderInvite({
                advertId,
                clientId,
                isMassOperation,
                orderIds: selectedOrdersIds,
                contractorIds,
                onSuccess: () => {
                    onSuccess();
                    close();
                },
                onError: close,
            });

            return;
        }

        addSeveralOrderContractors({
            contractorIds,
            orderIds: selectedOrdersIds,
            clientId,
            recommendationType: this.getRecommendationModelType(),
            searchCriteria: isContractorPage ? {} : searchCriteria,
        }, {
            isMassOperation,
            onSuccess: close,
            toastMessage: t("contractor-list.invite-contractors-task-success"),
        });
    };

    renderPagination() {
        const {totalPages} = this.props;
        const {pageData} = this.state;
        const {pageNum} = pageData;

        return (
            <NmPagination
                className="invite-contractor-to-order-form__pagination"
                pageNum={pageNum}
                totalPages={totalPages}
                onChangePagination={this.handlePaginationChange}
            />
        );
    }

    renderLoader() {
        const {
            progress,
            t,
        } = this.props;

        return (
            <Dimmer
                active={progress}
                inverted
            >
                <Loader content={t("loader.content")} />
            </Dimmer>
        );
    }

    onInviteContractorsConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            isShowInviteContractorsConfirm: !prevState.isShowInviteContractorsConfirm,
        }));
    };

    renderInviteContractorsConfirm = () => {
        const {
            t,
            isShowInviteContractorsWarning,
        } = this.props;

        const {
            isShowInviteContractorsConfirm,
            selectedOrdersIds,
        } = this.state;

        return (
            (isShowInviteContractorsWarning && isShowInviteContractorsConfirm) &&
            <NmConfirmV2
                title={this.contractorsFullName}
                content={t("order-micro-job-seekers.migration-warning-msg")}
                onCancel={this.onInviteContractorsConfirm}
                onConfirm={this.sendInvite}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                disabled={!selectedOrdersIds.length}
            />
        );
    };

    render() {
        const {
            t,
            close,
            isShowInviteContractorsWarning,
            progressAction,
            loading,
        } = this.props;
        const {
            selectedList,
            selectedOrdersIds,
            filter,
        } = this.state;

        return (
            <NmModal
                size="md"
                onClose={close}
                className="invite-contractor-to-order-form"
                classNameContent="flex flex-column"
                header={
                    <NmTitle size="lg">
                        {t("contractor-list.change-order")}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        mobile="column"
                        cancelBtnContent={t("button.cancel")}
                        submitBtnContent={t("button.send-invite")}
                        disabled={!selectedOrdersIds.length || progressAction || loading}
                        submit={isShowInviteContractorsWarning ? this.onInviteContractorsConfirm : this.sendInvite}
                        onClose={close}
                    />
                }
            >
                <NmForm
                    horizontal
                    className="flex align-items-end"
                >
                    <div className="col-16 col-md-7 col-xl-5">
                        <NmInputV2
                            size="lg"
                            label="Номер заказа"
                            placeholder="Введите номер"
                            name="orderNum"
                            value={filter.orderNum}
                            onChange={this.handleChangeFilter}
                            pattern={REG_EXP.ONLY_NUMBERS}
                        />
                    </div>
                    <div className="col-16 col-md-4 col-xl-5">
                        <NmInputV2
                            size="lg"
                            label="Название заказа"
                            placeholder="Введите название"
                            name="orderName"
                            value={filter.orderName}
                            onChange={this.handleChangeFilter}
                        />
                    </div>
                    <div className="col-16 col-md-5">
                        <FilterButtonsV2
                            onClear={this.onClearFilter}
                            onSearch={this.submitFilter}
                        />
                    </div>
                </NmForm>
                <Form>
                    {this.renderLoader()}
                    {
                        selectedList?.length ?
                            <>
                                <TableDiv
                                    isOverflowX={true}
                                    isCheckBox={true}
                                    className="invite-contractor-to-order-form__table"
                                    tableData={this.mapTableData()}
                                    onSelectedRows={this.onSelectedRows}
                                />
                                {this.renderPagination()}
                            </> :
                            <NmEmptyPageV2
                                clearStyles={true}
                                title="Нет доступных заказов"
                            />
                    }
                </Form>
                {this.renderInviteContractorsConfirm()}
            </NmModal>
        );
    }
}

export default withPageData(connect(
    state => ({
        list: orderListSelector(state),
        contractorList: contractorListWithFullNameSelector(state),
        totalCount: orderTotalCountSelector(state),
        totalPages: orderTotalPagesSelector(state),
        progress: getOrderListProgressSelector(state),
        progressAction: orderProgressListSelector(state),
        recommendationModelType: recommendationModelTypeSelector(state),
    }),
    {
        getOrderListForInvitation,
        addSeveralOrderContractors,
        getContractorByIds,
        advertisementOrderInvite,
    },
)(withTranslation()(InviteContractorToOrderForm)));