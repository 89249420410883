import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {citizenshipsDict} from "../../../../../../constants/citizenships";

import {
    getCardCrowdTask,
    getPageCrowdTaskContractorsInviteCandidates,
    updateStoreCrowdTaskCard,
} from "../../../../../../ducks/bff/crowd/taskCard/actionCreators";
import {getContractorStatusDict} from "../../../../../../ducks/contractor";
import {
    getContractorEducationLevelDict,
    getContractorLanguagesDict,
    getDriverLicenseDictionary,
} from "../../../../../../ducks/contractorProfile";

const useCrowdTaskCardInviteCandidatesListFetch = (props) => {
    const {
        clientId,
        taskId,
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchCard();
        dispatch(getContractorStatusDict());
        dispatch(getContractorEducationLevelDict());
        dispatch(getContractorLanguagesDict());
        dispatch(getDriverLicenseDictionary());

        return () => {
            dispatch(updateStoreCrowdTaskCard({
                pageData: {},
                list: [],
                totalCount: 0,
                card: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter]);

    const fetchCard = () => {
        dispatch(getCardCrowdTask({
            clientId,
            taskId,
        }));
    };

    const fetchList = () => {
        dispatch(getPageCrowdTaskContractorsInviteCandidates({
            clientId,
            taskId,
            pageNum,
            pageSize,
            citizenshipFilter: [citizenshipsDict.RU.value],
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};

export default useCrowdTaskCardInviteCandidatesListFetch;