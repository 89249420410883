import React from "react";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import ClientCardNotificationsRoute from "./Route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
} from "../../../../constants/links";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER} from "../../../../constants/roles";

import "./style.sass";

const ClientCardNotifications = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;
    const role = ls(USER_ROLE);

    const getLinks = () => {
        const notifications = LINK_CLIENT_NOTIFICATIONS_LIST.replace(":clientId", clientId);
        const mailing = LINK_CLIENT_SETTING_NOTIFICATIONS_LIST.replace(":clientId", clientId);

        return {
            notifications,
            mailing,
        };
    };

    const getTabLinks = () => {
        const {
            notifications,
            mailing,
        } = getLinks();

        return [
            {
                active: notifications,
                link: notifications,
                name: "Уведомления",
            },
            {
                active: mailing,
                link: mailing,
                name: "Рассылки на почту",
            },
        ];
    };

    return (
        <div className="client-card-notifications">
            {
                ![CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, HR_MANAGER].includes(role) &&
                    <>
                        <NmTitle size="xl">
                            Уведомления
                        </NmTitle>
                        <Tabs
                            {...props}
                            className="client-card-notifications__tabs"
                            panes={getTabLinks()}
                            secondary
                        />
                    </>
            }
            <ClientCardNotificationsRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardNotifications;
