import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {MAP_INIT_FILTER_DATA, MAP_MOSCOW_CENTER_COORDS} from "../constants";

import {updateFieldsObjectsStore} from "../../../ducks/objects";
import {updateFieldsStoreProject} from "../../../ducks/projects";

export const useMapFilter = ({onSubmitFilter}) => {
    const [filter, setFilter] = useState({...MAP_INIT_FILTER_DATA});

    const dispatch = useDispatch();

    useEffect(() => {
        onSubmitFilter(filter);
    }, [
        filter.locationAddressFiasIdsFilter,
        filter.distance,
        filter.types,
    ]);

    const onChangeFilter = (event, {name, value, checked, geoInfo}) => {
        if (name === "distance") {
            setFilter(prevFilter => ({
                ...prevFilter,
                distanceFilter: {
                    ...prevFilter.distanceFilter,
                    [name]: value,
                },
            }));
        }

        if (name === "locationAddressFiasIdsFilter") {
            setFilter(prevFilter => {
                if (!geoInfo) {
                    return {
                        ...prevFilter,
                        [name]: value,
                        distanceFilter: {
                            ...prevFilter.distanceFilter,
                            coordinateLat: MAP_MOSCOW_CENTER_COORDS.LATITUDE,
                            coordinateLon: MAP_MOSCOW_CENTER_COORDS.LONGITUDE,
                        },
                    };
                }

                const {
                    geoLat,
                    geoLon,
                } = geoInfo;

                return {
                    ...prevFilter,
                    [name]: value,
                    distanceFilter: {
                        ...prevFilter.distanceFilter,
                        coordinateLat: geoLat,
                        coordinateLon: geoLon,
                    },
                };
            });

            return;
        }

        if (name === "types" && typeof value === "string") {
            const types = filter.types || [];

            setFilter(prevFilter => {
                return {
                    ...prevFilter,
                    types: !types.includes(value) ?
                        [
                            ...types,
                            value,
                        ] : types.filter(item => item !== value),
                };
            });

            return;
        }

        const [_name, blockName] = name.split(".").reverse();

        if (blockName) {
            setFilter(prevFilter => ({
                ...prevFilter,
                [blockName]: {
                    ...prevFilter[blockName],
                    [_name]: typeof checked === "boolean" ? checked : value,
                },
            }));

            return;
        }

        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: value,
        }));
    };

    const onClearFilter = () => {
        setFilter({
            ...MAP_INIT_FILTER_DATA,
            locationAddressFiasIdsFilter: filter.locationAddressFiasIdsFilter,
            distanceFilter: {
                ...filter.distanceFilter,
                distance: filter.distanceFilter.distance,
            },
            types: filter.types,
        });
        dispatch(updateFieldsObjectsStore({optimizedObjects: []}));
        dispatch(updateFieldsStoreProject({projectsList: []}));
    };

    return {
        onChangeFilter,
        filter,
        onClearFilter,
    };
};