import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {getEndDate} from "../../../utils/dateFormat";
import {handleDateFieldFilter} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";

import {requiredMessage} from "../../../constants/validationRules";

import {addExportDocument as addExportDocumentAction} from "../../../ducks/exportDocument";

function useAddExportDocument(fetchList) {
    const dispatch = useDispatch();
    const [exportDocumentForm, setExportDocumentForm] = useState({});
    const [loadingForm, setLoadingForm] = useState(false);

    const clearForm = () => {
        setExportDocumentForm({});
    };

    const onChangeForm = (e, {value, name}) => {
        setExportDocumentForm({
            ...exportDocumentForm,
            [name]: value,
        });
    };

    const addExportDocument = () => {
        const {
            clientId,
            periodStartDateTime,
            periodEndDateTime,
        } = exportDocumentForm;

        if (!(clientId && periodStartDateTime && periodEndDateTime)) {
            toastError("Выберите заказчика и заполните период, за который необходимо сформировать архив документов");
            return;
        }
        setLoadingForm(true);

        dispatch(addExportDocumentAction({
            clientId,
            periodStartDateTime: handleDateFieldFilter(periodStartDateTime),
            periodEndDateTime: handleDateFieldFilter(getEndDate(periodEndDateTime)),
            getResult: ({errorMessage}) => {
                setTimeout(() => {
                    setLoadingForm(false);
                }, 300);

                if (errorMessage) {
                    toastError(errorMessage);
                    return;
                }

                clearForm();
                fetchList();
            },
        }));
    };

    return {
        exportDocumentForm,
        onChangeForm,
        addExportDocument,
        loadingForm,
    };
}

export default useAddExportDocument;