import {useDispatch, useSelector} from "react-redux";

import {getKedoDocumentTypes} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsDocumentTypesOptionsSelector,
} from "../../../ducks/bff/common/dicts/selectors";

export const useKedoDocumentTypes = () => {
    const dispatch = useDispatch();
    const options = useSelector(bffCommonDictsDocumentTypesOptionsSelector);

    const onSearchChange = (nameFilter: string) => {
        dispatch(getKedoDocumentTypes({
            pageNum: 1,
            pageSize: 25,
            nameFilter: nameFilter ? nameFilter : undefined,
        }));
    };

    return {
        onSearchChange,
        options,
        label: "Тип документа",
        placeholder: "Выберите тип документа",
    };
};
