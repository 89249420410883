import React from "react";

import HelpTooltip from "../ActualComponents/HelpTooltip";
import {NmPageCardHeader} from "../ActualComponents/NmPageCardHeader";
import ButtonBack from "../ButtonBack";
import NmTitle from "../NmTitle";
import {Loader} from "semantic-ui-react";

import bem from "../../utils/bem";

import "./style.sass";

export const NmPageHeader = (props) => {
    const {
        text,
        totalCount,
        badge,
        subTitle,
        status,
        handleOnClickBack,
        className = "",
        size = "xl",
        buttonBackSize,
        isShowCount = true,
        addingTitle = null,
        progressText = "Идет обновление...",
        statusLoading = false,
        buttonBackIsMobile = false,
        tooltipText,
        noWrap = true,
        noWrapTitle,
        backLink,
    } = props;
    const [block, element] = bem("nm-page-header", className);

    return (
        <div className={block({noWrap})}>
            {
                (handleOnClickBack || backLink) &&
                <ButtonBack
                    pathname={backLink}
                    size={buttonBackSize}
                    className={element("button-back")}
                    onClick={handleOnClickBack}
                    isMobile={buttonBackIsMobile}
                />
            }
            <NmTitle
                size={size}
                count={totalCount}
                status={status}
                subTitle={subTitle}
                noWrap={noWrapTitle}
                isShowCount={isShowCount}
                className={element("title", {hidden: noWrap})}
            >
                {text}
            </NmTitle>
            {
                tooltipText &&
                <HelpTooltip
                    info
                    hover
                    className={element("tooltip")}
                    classNameIcon={element("tooltip-icon")}
                    text={tooltipText}
                    position="bottom-left"
                />
            }
            {
                badge &&
                <div className={element("status")}>
                    {badge}
                </div>
            }
            {
                statusLoading ?
                    <div className={element("loading")}>
                        <Loader
                            className={element("loader")}
                            active
                            inline
                            size="tiny"
                        />
                        {progressText}
                    </div> :
                    null
            }
            {
                addingTitle && <div className={element("addingTitle")}>
                    {addingTitle}
                </div>
            }
        </div>
    );
};