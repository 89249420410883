import {useDispatch} from "react-redux";

import {getPeriodString} from "../../../utils/dateFormat";

import {downloadExportDocument} from "../../../ducks/exportDocument";

function useDownloadDocument() {
    const dispatch = useDispatch();

    const downloadDocument = (item) => {
        const {
            exportId,
            clientId,
            adminExportName,
        } = item;

        dispatch(downloadExportDocument({
            exportId,
            clientId,
            adminExportName,
        }));
    };

    return {
        downloadDocument,
    };
}

export default useDownloadDocument;