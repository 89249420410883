import {useSelector} from "react-redux";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST,
} from "../../../../../constants/links";

export const useContractorFinanceTabs = ({contractorId}) => {
    const pathname = useSelector(state => state.router.location.pathname);

    const getLinks = () => {
        const balanceLink = LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST.replace(":contractorId", contractorId);
        const incomeNpdLink = LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS.replace(":contractorId", contractorId);
        const incomeNdflLink = LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST.replace(":contractorId", contractorId);
        const incomeIndividualLink = LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST.replace(":contractorId", contractorId);
        const patentsLink = LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST.replace(":contractorId", contractorId);

        return [
            {
                active: pathname.includes(balanceLink),
                link: balanceLink,
                name: "Баланс",
            },
            {
                active: [
                    incomeNpdLink,
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS.replace(":contractorId", contractorId),
                ].includes(pathname),
                link: incomeNpdLink,
                name: "Доходы НПД",
            },
            {
                active: [
                    incomeNdflLink,
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST.replace(":contractorId", contractorId),
                ].includes(pathname),
                link: incomeNdflLink,
                name: "Доходы НДФЛ",
            },
            {
                active: [
                    incomeIndividualLink,
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST.replace(":contractorId", contractorId),
                ].includes(pathname),
                link: incomeIndividualLink,
                name: "Доходы ИП",
            },
            {
                active: pathname.includes(patentsLink),
                link: patentsLink,
                name: "Патенты",
            },
        ];
    };

    return {
        getLinks,
    };
};