import React, {useState} from "react";
import ReactCodeInput from "react-verification-code-input";

import ErrorTooltip from "../ActualComponents/ErrorTooltip";

import {useClickOutside} from "../../hooks/useClickOutside";

import bem from "../../utils/bem";

import "./style.sass";

const NmReactCodeInput = (props) => {
    const {
        className,
        fields = 4,
        errorMessage,
        value,
        ...otherProps
    } = props;
    const [focused, setFocused] = useState(false);
    const [block, element] = bem("nm-react-code-input", className);

    const ref = useClickOutside(() => {
        setFocused(false);
    }, focused);

    return (
        <div
            ref={ref}
            onFocus={() => {setFocused(true);}}
            className={block()}
        >
            <ReactCodeInput
                className={element("input", {active: Boolean(value), focused, error: Boolean(errorMessage)})}
                fields={fields}
                fieldHeight={44}
                fieldWidth={44}
                {...otherProps}
            />
            {
                errorMessage &&
                <ErrorTooltip error={errorMessage === "SMS_CODE_ERROR" ? "Неверный код" : errorMessage} />
            }
        </div>
    );
};

export default NmReactCodeInput;