import React, {useState} from "react";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";

const DocumentManagementStructuralUtilsFilter = (props) => {
    const {
        submitFilter,
        onChangeFilter,
        initFilter,
        setFilter,
        filter,
    } = props;

    const {
        valueFilter,
    } = filter;

    const submit = () => {
        submitFilter(filter, true);
    };

    const clear = () => {
        submitFilter({
            ...initFilter,
        }, false);

        setFilter({
            ...initFilter,
        });
    };

    return (
        <NmForm>
            <NmInputV2
                size="lg"
                label="Подразделение"
                name="valueFilter"
                value={valueFilter}
                onChange={onChangeFilter}
            />
            <FilterButtonsV2
                onSearch={submit}
                onClear={clear}
            />
        </NmForm>
    );
};

export default DocumentManagementStructuralUtilsFilter;