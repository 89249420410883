import {useState} from "react";

import dateFormat from "../../../../../utils/dateFormat";
import {clearSpace, removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../../utils/stringHelper";

export function useRegistryInvitationsCardFilterDto() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            fioFilter,
            contractFrameStatusFilter,
            paymentAbility,
            phoneFilter,
            orderNumFilter,
            serialNumber,
        } = filter;

        return {
            ...filter,
            fioFilter: handleFormString(fioFilter),
            orderNumFilter: handleFormString(orderNumFilter),
            serialNumber: handleFormString(serialNumber),
            phoneFilter: phoneFilter ? removePhoneMask(phoneFilter) : undefined,
            contractFrameStatusFilter: contractFrameStatusFilter?.length ? contractFrameStatusFilter : undefined,
            paymentAbility: paymentAbility && paymentAbility !== "ALL" ? paymentAbility === "YES" : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };


    return {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}