import * as yup from "yup";

import {removePhoneMask} from "../../../../utils/stringFormat";

import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        name: yup.string()
            .nullable()
            .min(5, "Введено менее 5-ти символов. Пожалуйста, введите полное название сценария")
            .max(150, "Допустимое количество символов 150")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        objectId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        contractType: yup.string()
            .nullable()
            .when("offerContractToSign", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required("Выберите тип договора"),
            }),
        welcomeString: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        supportPhone: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .transform((value) => {
                return removePhoneMask(value).trim();
            })
            .length(11, "Номер телефона должен состоять из 11 цифр"),
        files: yup.array()
            .nullable()
            .when("customDesignEnabled", {
                is: true,
                then: yup.array()
                    .min(1, VALIDATIONS_MESSAGE.REQUIRED),
            }),
        projectParams: yup.string()
            .nullable()
            .when("applyProjectParams", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        promoCode: yup.string()
            .nullable()
            .when("applyPromoCode", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        removeFromClientGroupAfterContractTerminationDaysOffset: yup.string()
            .nullable()
            .when("removeFromClientGroupAfterContractTermination", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};

export default validationSchema;