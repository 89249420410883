import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {history} from "../../../../store/configureStore";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CROWD_TASK_STATUS} from "../../../../constants/crowd/task";
import {
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD_SEARCH_PAGE,
} from "../../../../constants/links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_MANAGER, NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";
import {
    TASK_PUBLISH_ERROR_CODE,
    TASK_PUBLISH_ERROR_MESSAGE,
} from "../components/edit-form/constants";

import {
    addCrowdTasksToGroup,
    archiveMultipleCrowdTaskGroup,
    closeMultipleCrowdTaskGroup,
    deleteMultipleCrowdTaskGroup,
    exportCrowdTaskGroup,
    importCrowdTaskGroup,
    publishMultipleCrowdTaskGroup,
    removeCrowdTaskFromGroup,
} from "../../../../ducks/bff/crowd/taskGroup/actionCreators";
import {
    archiveCrowdTask,
    archiveMultipleCrowdTask,
    closeCrowdTask,
    closeMultipleCrowdTask,
    deleteCrowdTask,
    deleteMultipleCrowdTask,
    publishCrowdTask,
    publishMultipleCrowdTask,
    unarchiveCrowdTask,
} from "../../../../ducks/bff/crowd/tasks/actionCreators";

const useCrowdTaskRegistryAction = (props) => {
    const {
        clientId,
        fetchList,
        progress,
        archivedFilter,
        setArchivedFilter,
        selectedList,
        clearSelectedRows,
        onOpenConfirm,
        onCloseConfirm,
        isGroupCardPage,
        isGroupCardSearchPage,
        taskGroupId,
        taskGroupCard,
    } = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [editFormData, setEditFormData] = useState({});
    const [logFormData, setLogFormData] = useState({});
    const [successPublishModalData, setSuccessPublishModalData] = useState({});
    const [extendModalData, setExtendModalData] = useState({});
    const [isOpenImportModal, setOpenImportModal] = useState(false);

    const role = ls(USER_ROLE);
    const isAccessGroupPageAction = [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        OBJECT_MANAGER,
        PROJECT_MANAGER,
    ].includes(role);

    const selectedTasks = selectedList.filter(item => item.isSelected);
    const selectedTasksIds = selectedTasks.map(item => item.taskId);
    const selectedPublishOrForPaymentTaskIds = selectedTasks
        .filter(item => [CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE, CROWD_TASK_STATUS.PAYMENT_IN_PROGRESS.VALUE].includes(item.status))
        .map(item => item.taskId);
    const selectedCompletedOrNotCompletedTaskIds = selectedTasks
        .filter(item => [CROWD_TASK_STATUS.COMPLETED.VALUE, CROWD_TASK_STATUS.NOT_COMPLETED.VALUE].includes(item.status))
        .map(item => item.taskId);
    const selectedDraftTaskIds = selectedTasks
        .filter(item => item.status === CROWD_TASK_STATUS.DRAFT.VALUE)
        .map(item => item.taskId);
    const selectedPublishTaskIds = selectedTasks
        .filter(item => [CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE].includes(item.status))
        .map(item => item.taskId);

    const getMediaControls = (isShowControls) => {
        if (isGroupCardSearchPage) {
            return null;
        }

        if (isGroupCardPage) {
            return {
                renderCount: {
                    desktop: 2,
                    tablet: 1,
                    mobile: 0,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            onClick: () => setOpenImportModal(true),
                            children: "Загрузить список",
                        },
                        visible: isAccessGroupPageAction && !taskGroupCard.archived,
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            onClick: exportGroupTasks,
                            children: "Выгрузить",
                            color: "grey",
                        },
                        visible: isShowControls && isAccessGroupPageAction && !taskGroupCard.archived,
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {
                                const link = LINK_CLIENT_CROWD_TASK_GROUPS_CARD_SEARCH_PAGE
                                    .replace(":clientId", clientId)
                                    .replace(":taskGroupId", taskGroupId);

                                history.push(link);
                            },
                            children: "Добавить задание",
                        },
                        visible: isAccessGroupPageAction && !taskGroupCard.archived,
                    },
                ],
            };
        }

        const archive = getArchiveButton(t, archivedFilter, {mobile: true});

        return {
            renderCount: {
                desktop: 2,
                tablet: 1,
                mobile: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => setEditFormData({
                            isOpen: true,
                        }),
                        icon: <AddIcon />,
                        children: "Создать задание",
                    },
                    visible: isShowControls && !archivedFilter && ![
                        NM_CHIEF_ACCOUNTANT,
                        NM_OPERATOR,
                    ].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        disabled: progress,
                        onClick: () => setArchivedFilter(value => !value),
                    },
                },
            ],
        };
    };

    const getListMediaControls = (item) => {
        const {
            taskId,
            status,
            name,
            workEndDate,
        } = item;

        if (isGroupCardSearchPage) {
            return {
                renderCount: {
                    desktop: 1,
                    tablet: 1,
                    mobile: 0,
                },
                size: "lg",
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {
                                addTasksToGroup({taskId});
                            },
                            children: "Добавить в группу",
                            color: "light-green",
                        },
                        visible: true,
                    },
                ],
            };
        }

        const generalButtons = [
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => onOpenConfirm({
                        content: `Вы действительно хотите завершить задание "${name}"?"`,
                        onConfirm: () => closeTask(taskId),
                    }),
                    children: "Завершить",
                },
                visible: [
                    CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE,
                    CROWD_TASK_STATUS.PAYMENT_IN_PROGRESS.VALUE,
                ].includes(status) && ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role),
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        if (archivedFilter) {
                            onOpenConfirm({
                                content: `Вы действительно хотите восстановить из архива задание "${name}"?"`,
                                onConfirm: () => unarchiveTask(taskId),
                            });

                            return;
                        }

                        onOpenConfirm({
                            content: `Вы действительно хотите перенести в архив задание "${name}"?"`,
                            onConfirm: () => archiveTask(taskId),
                        });
                    },
                    children: archivedFilter ? "Из архива" : "В архив",
                },
                visible: [
                    CROWD_TASK_STATUS.COMPLETED.VALUE,
                    CROWD_TASK_STATUS.NOT_COMPLETED.VALUE,
                ].includes(status),
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => onOpenConfirm({
                        content: `Вы действительно хотите удалить задание "${name}"?"`,
                        onConfirm: () => deleteTask(taskId),
                    }),
                    children: "Удалить",
                },
                visible: status === CROWD_TASK_STATUS.DRAFT.VALUE,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => onOpenConfirm({
                        content: `Вы действительно хотите опубликовать задание "${name}"?"`,
                        onConfirm: () => publishTask(taskId),
                    }),
                    children: "Опубликовать",
                },
                visible: status === CROWD_TASK_STATUS.DRAFT.VALUE,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => setEditFormData({
                        isOpen: true,
                        editData: item,
                    }),
                    children: "Редактировать",
                },
                visible: status === CROWD_TASK_STATUS.DRAFT.VALUE
                    && ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role),
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => setExtendModalData({
                        clientId,
                        taskId,
                        workEndDate,
                        onSuccess: () => {
                            fetchList();
                            toastSuccess("Период выполнения задания продлён");
                            setExtendModalData({});
                        },
                    }),
                    children: "Продлить период выполнения задания",
                },
                visible: status === CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE
                    && ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role),
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => setLogFormData(item),
                    children: "Лог задания",
                },
            },
        ];

        if (isGroupCardPage) {
            return {
                renderCount: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                size: "lg",
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {
                                removeTasksFromGroup({taskId});
                            },
                            children: "Удалить из группы заданий",
                        },
                        visible: isAccessGroupPageAction && !taskGroupCard.archived,
                    },
                    ...generalButtons,
                ],
            };
        }

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            size: "lg",
            buttons: [
                ...generalButtons,
            ],
        };
    };

    const getMassActionContextMenuOptions = () => {
        if (isGroupCardSearchPage || [NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role)) {
            return [];
        }

        return [
            selectedPublishOrForPaymentTaskIds.length && {
                text: "Завершить задания",
                onClick: () => {
                    onOpenConfirm({
                        content: "Вы действительно хотите выполнить закрытие выбранных заданий?",
                        onConfirm: closeTasks,
                    });
                },
            },
            selectedCompletedOrNotCompletedTaskIds.length && {
                text: "Архивировать завершенные задания",
                onClick: () => {
                    onOpenConfirm({
                        content: "Вы действительно хотите переместить в архив выбранные задания?",
                        onConfirm: archiveTasks,
                    });
                },
            },
            selectedDraftTaskIds.length && {
                text: "Удалить черновики",
                onClick: () => {
                    onOpenConfirm({
                        content: "Вы действительно хотите удалить выбранные черновики заданий?",
                        onConfirm: deleteTasks,
                    });
                },
            },
            selectedDraftTaskIds.length && {
                text: "Опубликовать задания",
                onClick: () => {
                    onOpenConfirm({
                        content: "Вы действительно хотите опубликовать выбранные черновики заданий?",
                        onConfirm: publishTasks,
                    });
                },
            },
            selectedPublishTaskIds.length && {
                text: "Продлить период выполнения заданий",
                onClick: () => {
                    setExtendModalData({
                        clientId,
                        isMultiple: true,
                        taskIds: selectedTasksIds,
                        onSuccess: () => {
                            onSuccessMassAction("Задача по продлению периода выполнения заданий запущена.");
                            setExtendModalData({});
                        },
                    });
                },
            },
        ].filter(item => Boolean(item));
    };

    const onSuccessMassAction = (textSuccess) => {
        clearSelectedRows();
        toastSuccess(`${textSuccess} Перейдите в "Список задач" для проверки результата`);
        fetchList();
        onCloseConfirm();
    };

    const closeTask = (taskId) => {
        dispatch(closeCrowdTask({
            clientId,
            taskId,
            onSuccess: () => {
                fetchList();
                onCloseConfirm();
            },
        }));
    };

    const closeTasks = () => {
        const reqData = {
            clientId,
            taskGroupId,
            taskIds: selectedPublishOrForPaymentTaskIds,
            onSuccess: () => onSuccessMassAction("Задача по закрытию заданий запущена."),
        };

        if (isGroupCardPage) {
            dispatch(closeMultipleCrowdTaskGroup(reqData));

            return;
        }

        dispatch(closeMultipleCrowdTask(reqData));
    };

    const archiveTasks = () => {
        const reqData = {
            clientId,
            taskGroupId,
            taskIds: selectedCompletedOrNotCompletedTaskIds,
            onSuccess: () => onSuccessMassAction("Задача по перемещению в архив заданий запущена."),
        };

        if (isGroupCardPage) {
            dispatch(archiveMultipleCrowdTaskGroup(reqData));

            return;
        }

        dispatch(archiveMultipleCrowdTask(reqData));
    };

    const archiveTask = (taskId) => {
        dispatch(archiveCrowdTask({
            clientId,
            taskId,
            onSuccess: () => {
                fetchList();
                onCloseConfirm();
            },
        }));
    };

    const unarchiveTask = (taskId) => {
        dispatch(unarchiveCrowdTask({
            clientId,
            taskId,
            onSuccess: () => {
                fetchList();
                onCloseConfirm();
            },
        }));
    };

    const deleteTask = (taskId) => {
        dispatch(deleteCrowdTask({
            clientId,
            taskId,
            onSuccess: () => {
                fetchList();
                onCloseConfirm();
            },
        }));
    };

    const deleteTasks = () => {
        const reqData = {
            clientId,
            taskGroupId,
            taskIds: selectedDraftTaskIds,
            onSuccess: () => onSuccessMassAction("Задача по удалению черновиков заданий запущена."),
        };

        if (isGroupCardPage) {
            dispatch(deleteMultipleCrowdTaskGroup(reqData));

            return;
        }

        dispatch(deleteMultipleCrowdTask(reqData));
    };

    const publishTask = (taskId) => {
        dispatch(publishCrowdTask({
            clientId,
            taskId,
            getError: ({errorMessage, errorCode}) => {
                if (errorCode === TASK_PUBLISH_ERROR_CODE.CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR) {
                    onOpenConfirm({
                        content: TASK_PUBLISH_ERROR_MESSAGE.CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR,
                        onConfirm: () => {
                            onCloseConfirm();
                        },
                        confirmButton: "Закрыть",
                        isOnlyConfirm: true,
                    });

                    return;
                }

                toastError(errorMessage);
            },
            onSuccess: () => {
                fetchList();
                onCloseConfirm();
            },
        }));
    };

    const publishTasks = () => {
        const reqData = {
            clientId,
            taskGroupId,
            taskIds: selectedDraftTaskIds,
            onSuccess: () => onSuccessMassAction("Задача по публикации черновиков заданий запущена."),
        };

        if (isGroupCardPage) {
            dispatch(publishMultipleCrowdTaskGroup(reqData));

            return;
        }

        dispatch(publishMultipleCrowdTask(reqData));
    };

    const exportGroupTasks = () => {
        dispatch(exportCrowdTaskGroup({
            clientId,
            taskGroupId,
            onSuccess: () => {
                toastSuccess("Выгрузка успешно запущена. Скачать файл по готовности можно в разделе \"Экспорт отчетов\"");
            },
        }));
    };

    const importGroupTasks = ({file}) => {
        const formData = new FormData();
        formData.append("file", file);

        dispatch(importCrowdTaskGroup({
            clientId,
            taskGroupId,
            formData,
            onSuccess: () => {
                toastSuccess("Запущена задача по импорту заданий. Состояние задачи можно посмотреть в разделе \"Список задач \"");
                setOpenImportModal(false);
            },
        }));
    };

    const addTasksToGroup = ({isMassOperation = false, taskId}) => {
        dispatch(addCrowdTasksToGroup({
            clientId,
            taskGroupId,
            taskIds: isMassOperation ? selectedTasks.map(item => item.taskId) : [taskId],
            onSuccess: () => {
                toastSuccess(
                    isMassOperation ?
                        "Задания успешно добавлены в группу" :
                        "Задание успешно добавлено в группу",
                );
                clearSelectedRows();
            },
        }));
    };

    const removeTasksFromGroup = ({taskId}) => {
        dispatch(removeCrowdTaskFromGroup({
            clientId,
            taskGroupId,
            taskIds: [taskId],
            onSuccess: () => {
                toastSuccess("Задание успешно удалено из группы");
                fetchList();
                clearSelectedRows();
            },
        }));
    };

    return {
        archivedFilter,
        getMediaControls,
        getMassActionContextMenuOptions,
        editFormData,
        setEditFormData,
        isOpenImportModal,
        setOpenImportModal,
        importGroupTasks,
        getListMediaControls,
        addTasksToGroup,
        successPublishModalData,
        setSuccessPublishModalData,
        logFormData,
        setLogFormData,
        extendModalData,
        setExtendModalData,
    };
};

export default useCrowdTaskRegistryAction;