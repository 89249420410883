import React from "react";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {CONTRACTORS_TABS_TYPES} from "../../../constants/contractor";
import {
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_INVITING_CONTRACTORS_LIST,
} from "../../../constants/links";
import {ADMIN, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";

import "./style.sass";

import {ATTENTION_CONTRACTORS, AVAILABLE_CONTRACTORS, MY_CONTRACTORS} from "../../../constants/link-params";

export function NmContractorsTabs(props) {
    const {
        clientId,
        orderId,
        role,
        retailClient,
    } = props;
    
    const getTabLinks = (resources) => {
        if ([ADMIN, NM_MANAGER].includes(role) && retailClient) {
            const linkMyContractors = LINK_CLIENT_CONTRACTORS_RESOURCES
                .replace(":clientId", clientId)
                .replace(":type", MY_CONTRACTORS);
            const linkAvailableContractors = LINK_CLIENT_CONTRACTORS_RESOURCES
                .replace(":clientId", clientId)
                .replace(":type", AVAILABLE_CONTRACTORS);
            const linkAttentionContractors = LINK_CLIENT_CONTRACTORS_RESOURCES
                .replace(":clientId", clientId)
                .replace(":type", ATTENTION_CONTRACTORS);

            return [
                {
                    active: [
                        linkMyContractors,
                        linkAvailableContractors,
                        linkAttentionContractors,
                    ],
                    isSearchActiveInAllPath: true,
                    link: resources,
                    name: "Ресурсы",
                },
            ];
        }

        return [];
    };

    const isFromOrder = !isNullOrWhitespace(orderId);
    const isClientPage = !isNullOrWhitespace(clientId);

    const link = isFromOrder ? LINK_CLIENT_INVITING_CONTRACTORS_LIST : LINK_CLIENT_CONTRACTORS_LIST;

    const linkToAllContractorList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.ALL)
        .replace(":clientId", clientId)
        .replace(":orderId", orderId);
    const linkToFavoriteContractorList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.FAVORITE)
        .replace(":clientId", clientId)
        .replace(":orderId", orderId);
    const linkToBlackContractorList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.BLACK_LIST)
        .replace(":clientId", clientId);
    const linkToStopList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.STOP_LIST)
        .replace(":clientId", clientId);
    const linkToMyContractorList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.MY_CONTRACTORS)
        .replace(":clientId", clientId);
    const linkToByOrderContractorList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.BY_ORDER)
        .replace(":clientId", clientId)
        .replace(":orderId", orderId);
    const linkToMlSearchContractorList = link
        .replace(":type", CONTRACTORS_TABS_TYPES.ML_SEARCH)
        .replace(":clientId", clientId)
        .replace(":orderId", orderId);
    const resourcesLink = LINK_CLIENT_CONTRACTORS_RESOURCES.replace(":clientId", clientId).replace(":type", MY_CONTRACTORS);
    const onboardingLink = link
        .replace(":type", CONTRACTORS_TABS_TYPES.ONBOARDING)
        .replace(":clientId", clientId);


    //разные ссылки и вкладки для списка исполнителей и подбора на заказ
    const all = {
        name: "Все",
        link: linkToAllContractorList,
        active: linkToAllContractorList,
    };

    const favorite = {
        name: "Избранные",
        link: linkToFavoriteContractorList,
        active: linkToFavoriteContractorList,
    };

    const blackList = {
        name: "Чёрный список",
        link: linkToBlackContractorList,
        active: linkToBlackContractorList,
    };

    const stopList = {
        name: "Стоп-лист",
        link: linkToStopList,
        active: linkToStopList,
    };

    const myContractors = {
        name: "Мои исполнители",
        link: linkToMyContractorList,
        active: linkToMyContractorList,
    };

    const byOrder = {
        name: "По заказу",
        link: linkToByOrderContractorList,
        active: linkToByOrderContractorList,
    };

    const mlSearch = {
        name: "Умный подбор",
        link: linkToMlSearchContractorList,
        active: linkToMlSearchContractorList,
    };

    const onboarding = {
        name: "Онбординг",
        link: onboardingLink,
        active: onboardingLink,
    };

    if (isFromOrder) {
        return [
            byOrder,
            mlSearch,
            favorite,
            all,
        ];
    }

    if (isClientPage) {
        if ([ADMIN, NM_MANAGER, NM_OPERATOR].includes(role)) {
            return [
                favorite,
                blackList,
                stopList,
                myContractors,
                ...getTabLinks(resourcesLink),
                onboarding,
            ];
        }

        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return [
                favorite,
                blackList,
                onboarding,
            ];
        }
        return [
            all,
            favorite,
            blackList,
            myContractors,
            onboarding,
        ];
    }
    return [
        all,
        favorite,
        blackList,
    ];
}