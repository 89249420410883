import {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {getEndDate, getStartDate} from "../../../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {contractorEventDictOptionsSelector} from "../../../../../ducks/contractorEventLog";

export function useEventLogFilter() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const contractorEventDictOptions = useSelector(contractorEventDictOptionsSelector);

    function mapFilterDto(filter) {
        const {
            groupTypesFilter,
            fromDateFilter,
            toDateFilter,
        } = filter;

        return {
            groupTypesFilter: isEmpty(groupTypesFilter) ? undefined : groupTypesFilter,
            fromDateTimeFilter: isNullOrWhitespace(fromDateFilter) ? undefined : getStartDate(new Date(fromDateFilter)),
            toDateTimeFilter: isNullOrWhitespace(toDateFilter) ? undefined : getEndDate(new Date(toDateFilter)),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
        contractorEventDictOptions,
        setIsSearch,
        isSearch,
    };
}