import React, { Component } from "react";

import PropTypes from "prop-types";

import "./style.sass";


class IconTextApp extends Component {
    static propTypes = {
        text: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        colorText: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        icon: "",
        label: "",
        iconColor: "#B99736",
        colorText: "",
        className: "",
    };


    render() {
        const {
            icon,
            text,
            iconColor,
            colorText,
            className,
        } = this.props;

        return (
            <div className={`icon-text-app ${className}`}>
                <div className="icon-text-app_icon">
                    {icon && <i
                        aria-hidden="true"
                        style={{color: iconColor}}
                        className={`${icon} at icon`}
                    />}
                </div>
                <div
                    className="icon-text-app_text"
                    style={{color: colorText}}
                >
                    {text}
                </div>
            </div>
        );
    }
}

export default IconTextApp;
