import React, {useState} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import {JobBoardsConnect} from "../../../../components/JobBoardsConnect";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import RecruitmentResponseSource from "../../../../components/Recruitment/ResponseSource";
import {RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT} from "../../../../constants/recruitment";
import {
    bffRecruitmentAccessControlJobBoardsListLoadingSelector,
    bffRecruitmentAccessControlJobBoardsListSelector,
    bffRecruitmentAccessControlJobBoardsListTotalCountSelector,
    bffRecruitmentAccessControlJobBoardsListTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/access-control/job-boards/selectors";
import {RecruitmentJobBoardsSubscriptions} from "./components/subscriptions";

import {usePagination} from "../../../../hooks/usePagination";
import {useRecruitmentAccessControlJobBoardsAction} from "./hooks/useAction";
import {useRecruitmentAccessControlJobBoardsFetchList} from "./hooks/useFetchList";
import {useRecruitmentAccessControlRecruitersGroupsFilter} from "./hooks/useFilter";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {phoneFormat} from "../../../../utils/stringFormat";

export const ClientRecruitmentAccessControlJobBoards = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const [archived, setArchived] = useState(false);

    const list = useSelector(bffRecruitmentAccessControlJobBoardsListSelector);
    const progress = useSelector(bffRecruitmentAccessControlJobBoardsListLoadingSelector);
    const totalPages = useSelector(bffRecruitmentAccessControlJobBoardsListTotalPagesSelector);
    const totalCount = useSelector(bffRecruitmentAccessControlJobBoardsListTotalCountSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useRecruitmentAccessControlRecruitersGroupsFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentAccessControlJobBoardsFetchList({
        filterData,
        pageNum,
        pageSize,
        clientId,
        archived,
    });

    const {
        modalData,
        headerMediaControls,
        getListMediaControls,
        onCloseModal,
    } = useRecruitmentAccessControlJobBoardsAction({
        clientId,
        fetchList,
        archived,
        setArchived,
    });

    const getRows = () => {
        return list.map(item => {
            const {
                createDate,
                clientUserName,
                phone,
                email,
                jobBoardType,
                login,
                status,
            } = item;

            return {
                ...item,
                key: item.jobBoardAccountId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-md-12 col-xxl-14"
                        labels={[
                            {
                                label: "ФИО владельца аккаунта в системе Наймикс",
                                text: clientUserName,
                            },
                            {
                                label: "Телефон",
                                text: phoneFormat(phone),
                            },
                            {
                                label: "E-mail",
                                text: email,
                            },
                            {
                                label: "Джоб-борд",
                                alignItems: "center",
                                text: (
                                    <RecruitmentResponseSource
                                        isVisibleOnlyJobBoardName={true}
                                        source={{
                                            responseSource: jobBoardType,
                                        }}
                                    />
                                ),
                            },
                            {
                                label: "Логин от джоб-борда",
                                text: login,
                            },
                            {
                                label: "Статус",
                                text: RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT[status],
                            },
                            {
                                label: "Дата создания",
                                text: createDate ? formatLocalDate(createDate, "dd.MM.yyyy") : "-",
                            },
                        ]}
                        isFixedActions={true}
                        noDivider={true}
                        mediaControls={getListMediaControls(item)}
                    />
                ),
            };
        });
    };

    const getSubscriptions = () => {
        if (!modalData?.isOpenSubscriptions) {
            return null;
        }

        return (
            <RecruitmentJobBoardsSubscriptions
                onClose={onCloseModal}
                jobBoardAccountId={modalData.jobBoardAccountId}
            />
        );
    };

    const getConfirm = () => {
        if (!modalData?.isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                content={modalData.content}
                onConfirm={modalData.onConfirm}
                confirmButton={modalData.confirmButton}
                cancelButton={modalData.cancelButton}
                onCancel={onCloseModal}
            />
        );
    };

    const getAddModal = () => {
        if (!modalData?.isOpenAdd) {
            return null;
        }

        return (
            <JobBoardsConnect
                onClose={onCloseModal}
                clientId={clientId}
            />
        );
    };

    return (
        <NmPage
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            header={
                <>
                    {getAddModal()}
                    <NmPageHeader text="Джоб-борды" />
                </>
            }
            mediaControls={headerMediaControls}
            totalCount={totalCount}
            isLoaded={progress}
            isEmptyPage={list.length === 0}
            emptyPageProps={{
                isSearch,
            }}
        >
            {getConfirm()}
            {getSubscriptions()}
            <CheckboxList rows={getRows()} />
        </NmPage>
    );
};