import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    KEDO_DIRECTORIES_ADD_POSITION_ERROR,
    KEDO_DIRECTORIES_ADD_POSITION_REQUEST,
    KEDO_DIRECTORIES_ADD_POSITION_SUCCESS,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_DELETE_POSITION_ERROR,
    KEDO_DIRECTORIES_DELETE_POSITION_REQUEST,
    KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_GET_PAGE_COUNT_ERROR,
    KEDO_DIRECTORIES_GET_PAGE_COUNT_REQUEST,
    KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_POSITION_ERROR,
    KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";

// POST /bff/kedo/client/directories/getPositionsPage
// POST /bff/adm/settings/directories/kedo/positions/getPage
// Получение должностей
const getKedoDirectoryPositionsSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/kedo/client/directories/updatePosition
// POST /bff/adm/settings/directories/kedo/positions/update
// Обновление должности
const updateKedoDirectoryPositionSaga = function* ({payload}) {
    const {
        url,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPDATE_POSITION_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Должность успешно сохранена");

        yield put({
            type: KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPDATE_POSITION_ERROR,
        });
    }
};

// DELETE /bff/kedo/client/directories/deletePosition
// DELETE /bff/adm/settings/directories/kedo/positions/delete
// Удаление должности
const deleteKedoDirectoryPositionSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.delete(url, {data: requestData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_DELETE_POSITION_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Должность успешно удалена");

        yield put({
            type: KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_DELETE_POSITION_ERROR,
        });
    }
};

// POST /bff/kedo/client/directories/addPosition
// POST /bff/adm/settings/directories/kedo/positions/add
// Добавление должности
const addKedoDirectoryPositionSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_ADD_POSITION_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Должность успешно сохранена");

        yield put({
            type: KEDO_DIRECTORIES_ADD_POSITION_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_ADD_POSITION_ERROR,
            payload: error,
        });
    }
};

// POST /bff/kedo/client/directories/getPositionsPage
// POST /bff/adm/settings/directories/kedo/subdivisions/getPage
// Получение страницы подразделений
const getKedoDirectorySubdivisionsSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/kedo/client/directories/updatePosition
// POST /bff/adm/settings/directories/kedo/subdivisions/update
// Обновление подразделения
const updateKedoDirectorySubdivisionSaga = function* ({payload}) {
    const {
        url,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Структурное подразделение успешно сохранено");

        yield put({
            type: KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR,
        });
    }
};

// DELETE /bff/kedo/client/directories/deletePosition
// DELETE /bff/adm/settings/directories/kedo/subdivisions/delete
// Удаление подразделения
const deleteKedoDirectorySubdivisionSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.delete(url, {data: requestData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Структурное подразделение успешно удалено");

        yield put({
            type: KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR,
        });
    }
};

// POST /bff/kedo/client/directories/addPosition
// POST /bff/adm/settings/directories/kedo/subdivisions/add
// Добавление подразделения
const addKedoDirectorySubdivisionSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Структурное подразделение успешно сохранено");

        yield put({
            type: KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR,
            payload: error,
        });
    }
};

// POST /bff/kedo/client/directories/getPageCount
// POST /bff/adm/settings/directories/kedo/getPageCounters
// Получение счетчиков документов, должностей и подразделений
const getKedoDirectoryPageCountSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_GET_PAGE_COUNT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_GET_PAGE_COUNT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/upload-document-types/getPage
// POST /bff/adm/clients/kedo/directories/getDocumentTypePage
// Получение страницы
const getKedoUploadDocumentTypesPageSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/upload-document-types/update
// POST /bff/adm/clients/kedo/directories/updateDocumentType
// Обновление
const updateKedoUploadDocumentTypeSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Тип загруженного документа успешно сохранен");

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/upload-document-types/add
// POST /bff/adm/clients/kedo/directories/addDocumentType
// Добавление
const addKedoUploadDocumentTypeSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Тип загруженного документа успешно сохранен");

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/upload-document-types/update
// POST /bff/adm/clients/kedo/directories/updateDocumentType
// Обновление
const deleteKedoUploadDocumentTypeSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        documentTypeId,
        clientId,
    } = payload;

    try {
        const result = yield request.bff.delete(url, {params: {documentTypeId, clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Тип загруженного документа успешно удален");

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/template-document-types/getPage
// Получение страницы
const getKedoTemplateDocumentTypesPageSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/template-document-types/add
// Добавление
const addKedoTemplateDocumentTypeSaga = function* ({payload}) {
    const {
        url,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Тип шаблонного документа успешно сохранен");

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/template-document-types/update
// Обновление
const updateKedoTemplateDocumentTypeSaga = function* ({payload}) {
    const {
        url,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Тип шаблонного документа успешно сохранен");

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/settings/directories/kedo/upload-document-types/delete
// Удаление
const deleteKedoTemplateDocumentTypeSaga = function* ({payload}) {
    const {
        url,
        onSuccess,
        documentTypeId,
    } = payload;

    try {
        const result = yield request.bff.delete(url, {params: {documentTypeId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Тип шаблонного документа успешно удален");

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/template-document-types/updateClientUsage
// Обновление списка доступа компаний к конкретному типу документов
const getKedoTemplateDocumentTypesClientUsagePageSaga = function* ({payload}) {
    const {
        url,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(url, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/kedo/template-document-types/updateClientUsage
// Обновление списка доступа компаний к конкретному типу документов
const updateKedoTemplateDocumentTypesClientUsageSaga = function* ({payload}) {
    const {
        url,
        onSuccess,
        changed,
    } = payload;

    try {
        const result = yield request.bff.post(url, changed);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST, getKedoDirectoryPositionsSaga),
        takeEvery(KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST, updateKedoDirectoryPositionSaga),
        takeEvery(KEDO_DIRECTORIES_DELETE_POSITION_REQUEST, deleteKedoDirectoryPositionSaga),
        takeEvery(KEDO_DIRECTORIES_ADD_POSITION_REQUEST, addKedoDirectoryPositionSaga),
        takeEvery(KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST, getKedoDirectorySubdivisionsSaga),
        takeEvery(KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST, updateKedoDirectorySubdivisionSaga),
        takeEvery(KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST, deleteKedoDirectorySubdivisionSaga),
        takeEvery(KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST, addKedoDirectorySubdivisionSaga),
        takeEvery(KEDO_DIRECTORIES_GET_PAGE_COUNT_REQUEST, getKedoDirectoryPageCountSaga),
        takeEvery(KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST, updateKedoUploadDocumentTypeSaga),
        takeEvery(KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST, getKedoUploadDocumentTypesPageSaga),
        takeEvery(KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST, addKedoUploadDocumentTypeSaga),
        takeEvery(KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST, deleteKedoUploadDocumentTypeSaga),
        takeEvery(KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST, getKedoTemplateDocumentTypesPageSaga),
        takeEvery(KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST, addKedoTemplateDocumentTypeSaga),
        takeEvery(KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST, updateKedoTemplateDocumentTypeSaga),
        takeEvery(KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST, deleteKedoTemplateDocumentTypeSaga),
        takeEvery(KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST, getKedoTemplateDocumentTypesClientUsagePageSaga),
        takeEvery(KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST, updateKedoTemplateDocumentTypesClientUsageSaga),
    ]);
}