import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";

const controller = "/adm/recruitment/access-control/recruiters-group";

//POST /bff/adm/recruitment/access-control/recruiters-group/search
// Получить список групп рекрутеров
const getPageRecruitmentAccessControlRecruitersGroups = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_ERROR,
        });
    }
};

//DELETE /bff/adm/recruitment/access-control/recruiters-group/{id}
// Удалить группу рекрутеров
const deleteRecruitmentAccessControlRecruitersGroups = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/${payload?.id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/access-control/recruiters-group
// Добавить группу рекрутеров
const addRecruitmentAccessControlRecruitersGroups = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/access-control/recruiters-group/{id}/recruiters
//Получить список участников группы
const getPageRecruitmentAccessControlRecruitersGroupsCard = function* ({payload}) {
    const {
        id,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${id}/recruiters`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_SUCCESS,
        });
    }
};

//DELETE /bff/adm/recruitment/access-control/recruiters-group/{id}/recruiters/{recruiterId}
//Удалить рекрутера из группы
const deleteRecruiterRecruitmentAccessControlRecruitersGroupsCard = function* ({payload}) {
    const {
        id,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/${id}/recruiters/${reqData.clientUserId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/access-control/recruiters-group/{id}/get-available-recruiters
//Получить список пользователей для добавления в группу рекрутеров
const getAvailableRecruitersPageRecruitmentAccessControlRecruitersGroupsCard = function* ({payload}) {
    const {
        id,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${id}/get-available-recruiters`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/access-control/recruiters-group/{id}/add-recruiters
//Добавить рекрутеров в группу
const addRecruitersRecruitmentAccessControlRecruitersGroupsCard = function* ({payload}) {
    const {
        id,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${id}/add-recruiters`, reqData.selectedIds);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_GET_PAGE_REQUEST, getPageRecruitmentAccessControlRecruitersGroups),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_DELETE_REQUEST, deleteRecruitmentAccessControlRecruitersGroups),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_ADD_REQUEST, addRecruitmentAccessControlRecruitersGroups),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_GET_PAGE_REQUEST, getPageRecruitmentAccessControlRecruitersGroupsCard),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_DELETE_RECRUITER_REQUEST, deleteRecruiterRecruitmentAccessControlRecruitersGroupsCard),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_ADD_RECRUITERS_REQUEST, addRecruitersRecruitmentAccessControlRecruitersGroupsCard),
        takeEvery(
            BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD_AVAILABLE_RECRUITERS_GET_PAGE_REQUEST,
            getAvailableRecruitersPageRecruitmentAccessControlRecruitersGroupsCard,
        ),
    ]);
}