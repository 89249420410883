import React from "react";

import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {formatAmountWithNullChecking} from "../../../../../../utils/stringFormat";

import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../../../constants/finance";

const SystemSettingsCorrectDepositResults = ({onClose, data, clientName}) => {
    const {
        newAvailableForOrdersAmount,
        newAvailableForPaymentsAmount,
        newDepositAmount,
        depositType,
        updatedFromRecord = {},
    } = data;
    const {
        availableForOrdersAmount,
        availableForPaymentsAmount,
        depositAmount,
    } = updatedFromRecord;
    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`Результаты корректировки депозита (${ORDER_WORK_REPORT_TYPE_TRANSLATE[depositType]}) для компании: \n${clientName}`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submitBtnContent="Ок"
                    submit={onClose}
                />
            }
            classNameContent="flex-column"
        >
            <div className="row mb-4">
                <div className="col-6">

                </div>
                <div
                    style={{fontWeight: 600}}
                    className="col-5"
                >
                    Старое значение
                </div>
                <div
                    style={{fontWeight: 600}}
                    className="col-5"
                >
                    Новое значение
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-6">
                    Доступно для заказов:
                </div>
                <div className="col-5">
                    {formatAmountWithNullChecking(availableForOrdersAmount)}
                </div>
                <div className="col-5">
                    {formatAmountWithNullChecking(newAvailableForOrdersAmount)}
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-6">
                    Доступно для выплат:
                </div>
                <div className="col-5">
                    {formatAmountWithNullChecking(availableForPaymentsAmount)}
                </div>
                <div className="col-5">
                    {formatAmountWithNullChecking(newAvailableForPaymentsAmount)}
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    Депозит:
                </div>
                <div className="col-5">
                    {formatAmountWithNullChecking(depositAmount)}
                </div>
                <div className="col-5">
                    {formatAmountWithNullChecking(newDepositAmount)}
                </div>
            </div>
        </NmModal>
    );
};

export default SystemSettingsCorrectDepositResults;