import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../components/CheckboxList";
import CodeConfirm from "../../../../components/CodeConfirm";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import DocumentManagementStatementFilter from "../filter";
import RejectionSigningStatementModal from "./components/RejectionSigningStatementModal";
import DocumentManagementStatementItem from "./item";
import {useStatementItem} from "./item/hocks/useStatementItem";
import DocumentManagementStatementLoadingForm from "./loading-form";

import {useFetchDictionary} from "../../../../hooks/useFetchDictionary";
import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useSelectedList} from "../../../../hooks/useSelectedList";
import {useStatementFilter} from "../filter/hooks/useStatementFilter";
import {useFetchList} from "./hooks/useFetchList";
import {useHeaderActions} from "./hooks/useHeaderActions";
import {useRejectSigningStatement} from "./hooks/useRejectSigningStatement";
import useCodeConfirm from "./loading-form/hooks/useCodeConfirm";
import {STATEMENT_ACTION_TYPE, useLoadingForm} from "./loading-form/hooks/useLoadingForm";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {mapStatementFilter} from "../filter/utils/mapStatementFilter";
import {getIsSelectedById, getShowCheckBox} from "./utils/statementHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";

import {clientCurrentMemberIdSelector} from "../../../../ducks/clientMember";
import {
    getStatementTypesPage,
    statementsListSelector,
    statementsTotalCountSelector,
    statementsTotalPagesSelector,
} from "../../../../ducks/edmStatements";
import {
    edoAccessListSelector,
    edoApproversOptionsSelector,
    edoReceiversOptionsSelector,
} from "../../../../ducks/edocuments";

import "./style.sass";

import {EDO_ACCESS_RIGHTS} from "../../../../constants/document-management/document-list";

const initFilterForm = {
    approverFio: "",
    createdFrom: null,
    createdTo: null,
    dateFrom: null,
    dateTo: null,
    name: "",
    receiverFio: "",
    senderFio: "",
    stmtState: "all",
    stmtTypeIds: [],
};

const DocumentManagementStatementList = (props) => {
    const {
        match: {
            params: {
                clientId,
                staffId: currentStaffId,
            },
        },
    } = props;

    const {filterDto, setFilterDto} = useStatementFilter();

    const statementsList = useSelector(statementsListSelector);

    const totalPages = useSelector(statementsTotalPagesSelector);
    const totalCount = useSelector(statementsTotalCountSelector);

    const edoReceiversOptions = useSelector(edoReceiversOptionsSelector);
    const edoApproversOptions = useSelector(edoApproversOptionsSelector);
    const currentClientUserId = useSelector(clientCurrentMemberIdSelector);
    const [isSearch, setIsSearch] = useState(false);

    const edoAccessList = useSelector(edoAccessListSelector);

    const role = ls(USER_ROLE);
    const isEmptyList = isEmpty(statementsList);

    const {setValueFilter: setValueTypeFilter} = useFetchDictionary({fetchCallback: getStatementTypesPage});

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        setFilter,
        filter,
        onChangeFilter,
    } = useFilter({initFilter: initFilterForm});
    const {
        archived,
        loading,
        setArchived,
        fetchList,
    } = useFetchList({clientId, filter: filterDto, pageNum, pageSize, currentStaffId});
    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();
    const {
        form,
        onChangeForm,
        setFile,
        setVisibleConfirm,
        errorForm,
        checkForm,
        isVisibleConfirm,
        statementsTypesOptions,
        submitForm,
        isVisibleEditForm,
        setVisibleEditForm,
        setForm,
        setAction,
        action,
        setErrorForm,
        previewFile,
        currentStep,
        setStep,
        switchAction,
        getStatementTemplateEmpty,
        onChangeAdditionalParams,
        additionalParams,
        setAdditionalParams,
        fetchApprovers,
        fetchReceivers,
    } = useLoadingForm({
        clientId,
        fetchList,
        selectedList,
        clearSelectedRows,
        edoReceiversOptions,
        edoApproversOptions,
    });
    const {
        onCloseRejectForm,
        rejectForm,
        errorRejectForm,
        onChangeRejectForm,
        submitRejectForm,
        openRejectForm,
        isVisibleRejectForm,
    } = useRejectSigningStatement(fetchList, clearSelectedRows);

    const {
        onClickActionItem,
        downloadFile,
    } = useStatementItem({
        archived,
        fetchListCallback: fetchList,
        clientId,
        setVisibleEditForm,
        setForm,
        setAction,
        openRejectForm,
        selectedList,
        setAdditionalParams,
    });
    const {
        isVisibleCodeConfirm,
        setVisibleCodeConfirm,
        userPhone,
        sendConfirmationCodeToUser,
        loadingCode,
        checkCode,
        onChangeCode,
        timer,
        code,
        errorMessageCode,
        setLoadingCode,
        codeConfirmType,
    } = useCodeConfirm(submitForm);

    const {
        onUnload,
    } = useHeaderActions({archived, clientId, filter: filterDto, currentStaffId});

    const getRows = useMemo(() => {
        return statementsList.map((item) => {
            const isSelected = getIsSelectedById(selectedList, item.id);
            const showCheckBox = getShowCheckBox(item, archived, currentClientUserId);

            return {
                ...item,
                isSelected,
                showCheckBox,
                contentRow: <DocumentManagementStatementItem
                    currentClientUserId={currentClientUserId}
                    edoAccessList={edoAccessList}
                    statementItem={item}
                    role={role}
                    archived={archived}
                    onClickActionItem={onClickActionItem}
                />,
            };
        });
    }, [archived, currentClientUserId, edoAccessList, role, selectedList, statementsList]);

    const submitFilter = (filter, isSearch) => {
        setFilterDto(mapStatementFilter(filter));
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(isSearch);
    };

    const reject = useCallback(() => {
        openRejectForm(selectedList.filter(item => item.isSelected).map(item => item.id));
    }, [selectedList]);

    const toggleArchived = useCallback(() => {
        setArchived(!archived);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    }, [archived, pageSize, pageNum]);

    const rows = getRows;

    const renderList = useMemo(() => {
        const hasShowCheckBox = rows.find(item => item.showCheckBox);

        return (
            <CheckboxList
                count={countSelected}
                mediaControls={{
                    renderCount: {
                        desktop: 2,
                        tablet: 2,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                onClick: () => {
                                    setAction(STATEMENT_ACTION_TYPE.SIGN);
                                    setVisibleCodeConfirm(true);
                                },
                                disabled: countSelected === 0,
                                children: "Подписать",
                            },
                        },
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                onClick: reject,
                                disabled: countSelected === 0,
                                children: "Отклонить",
                            },
                        },
                    ],
                }}
                checkBoxClassName="document-management-statements-list-table__checkbox"
                onSelectedRows={archived || !hasShowCheckBox ? null : handleSelectedRows}
                classNameContent="document-management-statements-list-table"
                rows={rows}
            />
        );
    }, [rows, archived, countSelected]);

    const isShowControls = !isEmptyList || isSearch;

    const {t} = useTranslation();

    const archive = getArchiveButton(t, archived, {mobile: true});
    return (
        <NmPage
            header={
                <NmPageHeader
                    totalCount={loading || (isShowControls && totalCount)}
                    text="Заявления"
                />
            }
            isLoaded={loading}
            mediaControls={{
                renderCount: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            onClick: () => {
                                setVisibleEditForm();
                                setAction(STATEMENT_ACTION_TYPE.CREATE);
                            },
                            icon: <AddIcon />,
                            children: "Подать заявление",
                        },
                        visible: !currentStaffId && edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_ADD) && isShowControls && !archived,
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            color: "light-green",
                            children: "Выгрузить",
                            onClick: onUnload,
                        },
                        visible: !archived && isShowControls,
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            ...archive.props,
                            disabled: loading,
                            onClick: toggleArchived,
                        },
                    },
                ],
            }}
            typeFilter="vertical"
            className="document-management-statements-list"
            classNameContentContainer="document-management-document-list__content-container"
            currentPageSize={totalCount < 25 ? 0 : pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            filtersBase={
                <DocumentManagementStatementFilter
                    initForm={initFilterForm}
                    clientId={clientId}
                    submitFilter={submitFilter}
                    setFilter={setFilter}
                    onChangeFilter={onChangeFilter}
                    filter={filter}
                    filterDto={filterDto}
                    role={role}
                    setValueTypeFilter={setValueTypeFilter}
                />
            }
            totalCount={totalCount}
        >
            {isVisibleRejectForm && <RejectionSigningStatementModal
                submit={submitRejectForm}
                onClose={onCloseRejectForm}
                form={rejectForm}
                onChange={onChangeRejectForm}
                errorForm={errorRejectForm}
            />}
            {isVisibleEditForm && <DocumentManagementStatementLoadingForm
                setStep={setStep}
                additionalParams={additionalParams}
                onChangeAdditionalParams={onChangeAdditionalParams}
                previewFile={previewFile}
                handleDownloadEmptyTemplate={getStatementTemplateEmpty}
                switchAction={switchAction}
                currentStep={currentStep}
                action={action}
                handleDownload={downloadFile}
                errorForm={errorForm}
                setFile={setFile}
                form={form}
                edoApproversOptions={edoApproversOptions}
                handleOnSearchApproved={fetchApprovers}
                handleOnSearchReceiver={fetchReceivers}
                statementTypeOptions={statementsTypesOptions}
                edoReceiversOptions={edoReceiversOptions}
                handleClose={() => {
                    setVisibleEditForm();
                    setAction("");
                    setErrorForm({});
                }}
                onChangeForm={onChangeForm}
                checkForm={checkForm}
                setVisibleConfirm={setVisibleConfirm}
                setVisibleCodeConfirm={setVisibleCodeConfirm}
                isOpenConfirmWindow={isVisibleConfirm}
            />
            }
            {isVisibleCodeConfirm && <CodeConfirm
                onClose={(flag) => {
                    setVisibleCodeConfirm(flag);
                    setLoadingCode(false);
                }}
                code={code}
                errorMessage={errorMessageCode}
                onChange={onChangeCode}
                phone={userPhone}
                timer={timer}
                loading={loadingCode}
                submitCode={checkCode}
                sendConfirmationCodeToUser={(codeType) => sendConfirmationCodeToUser(codeType)}
                setLoadingCode={setLoadingCode}
                codeConfirmType={codeConfirmType}
            />}
            {
                isEmptyList &&
                <NmEmptyPageV2
                    isShowAction={!currentStaffId && edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_ADD) && !archived}
                    textAction="Подать заявление"
                    onClickAction={() => {
                        setVisibleEditForm();
                        setAction(STATEMENT_ACTION_TYPE.CREATE);
                    }}
                    isSearch={isSearch}
                />
            }
            {!isEmptyList && renderList}
        </NmPage>
    );
};

export default React.memo(DocumentManagementStatementList);

