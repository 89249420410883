import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import DropzoneV2 from "../../../../components/ActualComponents/DropzoneV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import {toastWarning} from "../../../../utils/toastHelper";

import {FILE_ERROR} from "../../../../constants/messages";
import {
    agencyContractExtensions,
} from "../../../../constants/validationRules";

import {
    addActualVersion,
    getActualFileNamesSelector,
    getAddProgressSelector,
    updateActualVersion,
} from "../../../../ducks/contractHistory";

import PropTypes from "prop-types";

import "./style.sass";

class AgencyContractEdit extends Component {
    static propTypes = {
        onCloseModel: PropTypes.func,
        files: PropTypes.array,
        contractHistoryId: PropTypes.string,
        clientId: PropTypes.string,
    };

    static defaultProps = {
        onCloseModel: () => {
        },
        files: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            formError: {},
            fileError: "",
            isOpenConfirm: false,
            fileName: "",
        };
    }

    componentDidMount() {
        const {isEdit, actualFileNames} = this.props;

        if (isEdit) {
            this.setState({
                files: actualFileNames.map(item => ({
                    name: item,
                })),
            });
        }
    }

    submitForm = () => {
        const {
            addActualVersion,
            clientId,
            updateActualVersion,
            contractHistoryId,
            actualFileNames,
            onCloseModel,
            t,
        } = this.props;

        const {files} = this.state;

        if (!files.length) {
            toastWarning(t("agency-contract-new.warning-not-files-selected"));
            return;
        }

        const data = this.getFormData(files);

        const {isEdit} = this.props;

        if (isEdit) {
            const deleteFileNames = actualFileNames.filter(
                fileName => !files.find(f => f.name === fileName),
            );
            updateActualVersion({
                params: {
                    contractHistoryId,
                    deleteFileNames: deleteFileNames.length
                        ? deleteFileNames.join(",")
                        : undefined,
                },
                data,
            });
        } else {
            addActualVersion({
                params: {
                    clientId,
                },
                data,
            });
        }

        onCloseModel();
    };

    getFormData(files) {
        const {isEdit} = this.props;

        const formData = new FormData();

        if (!isEdit) {
            files.forEach(file => {
                formData.append("files", file);
            });

            return formData;
        }

        files.filter(item => item.size).forEach(file => {
            formData.append("files", file);
        });

        return formData;
    }

    checkFiles = () => {
        const {files} = this.state;

        if (files.length > 10) {
            this.setState({fileError: FILE_ERROR.MAX_LENGTH});
        }
    };

    onDrop = files => {
        this.setState({fileError: "", files});

        if (files.length > 10) {
            this.setState({fileError: FILE_ERROR.MAX_LENGTH});
        }

        this.setState(prevState => {
            return {
                ...prevState,
                files,
            };
        });
    };

    removeFile = fileName => {
        this.setState(prevState => {
            return {
                ...prevState,
                files: prevState.files.filter(file => file.name !== fileName),
                fileError: "",
            };
        }, this.checkFiles);
        this.closeConfirm();
    };

    closeConfirm = () => {
        this.setState({
            isOpenConfirm: false,
            fileName: "",
        });
    };

    renderConfirmWindow = () => {
        const {isOpenConfirm, fileName} = this.state;
        const {t} = this.props;

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={`${t("edit.confirm-text-delete")} ${fileName}?`}
                onCancel={this.closeConfirm}
                onConfirm={() => {this.removeFile(fileName);}}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    };

    renderContent() {
        const {fileError, files} = this.state;
        return (

            <NmForm className="agency-contract-new__form">
                <DropzoneV2
                    files={files}
                    isEqualCheck
                    isShowConfirmForDeleteFile
                    format={agencyContractExtensions.map(ext => `.*${ext.toUpperCase()}`).join(", ")}
                    accept={agencyContractExtensions.map(ext => `.${ext}`)}
                    onChange={this.onDrop}
                    error={fileError}
                    maxSize={15}
                    // maxFiles={10}
                    multiple
                />
            </NmForm>
        );
    }

    render() {
        const {fileError} = this.state;
        const {onCloseModel, addProgress, t} = this.props;

        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        {t("agency-contract-actual.title")}
                    </NmTitle>
                }
                onClose={onCloseModel}
                footer={
                    <ApplyButtons
                        mobile="column"
                        submitBtnContent={t("button.save")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={onCloseModel}
                        submit={this.submitForm}
                        isHiddenCancelOnMobile
                        loading={addProgress}
                        disabled={addProgress || Boolean(fileError)}
                    />
                }
            >
                {this.renderConfirmWindow()}
                <div className="agency-contract-new fluid">
                    {this.renderContent()}
                </div>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        addProgress: getAddProgressSelector(state),
        actualFileNames: getActualFileNamesSelector(state),
    }),
    {
        addActualVersion,
        updateActualVersion,
    },
)(withTranslation()(AgencyContractEdit));
