import React from "react";

import Text from "../../../../../components/ActualComponents/Text";

import {getFullName} from "../../../../../utils/stringFormat";

export const formatFinancePaymentWarningCheckList = (list) => {
    const getWarningsConfirmContent = (warnings) => {
        if (!warnings) {
            return null;
        }

        if (warnings.length > 1) {
            return (
                <ul style={{listStyle: "disc"}}>
                    {warnings.map(value => (
                        <li>
                            <Text level="2">
                                {`${value.errorMessage} Рекомендуется избежать данной ситуации`}
                            </Text>
                        </li>
                    ))}
                </ul>
            );
        }

        return (
            <ul style={{listStyle: "disc"}}>
                <li>
                    <Text level="2">
                        {`${warnings[0].errorMessage} Рекомендуется избежать данной ситуации.`}
;
                    </Text>
                </li>
            </ul>
        );
    };

    return list.map(value => {
        const {
            lastName,
            firstName,
            patronymic,
            paymentNumber,
            warnings,
            orderNum,
            orderName,
        } = value;

        return {
            ...value,
            showCheckBox: true,
            name: `${getFullName(lastName, firstName, patronymic)} - Оплата №${paymentNumber} по заказу №${orderNum} ${orderName}`,
            content: getWarningsConfirmContent(warnings),
        };
    });
};