import React, {useState} from "react";
import {useSelector} from "react-redux";

import {
    bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector,
    bffRecruitmentVacancyProgressActionSelector,
} from "../../../ducks/bff/recruitment/vacancy/selectors";
import {
    bffRecruitmentVacancyCandidatesProgressActionSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {
    bffRecruitmentVacancyResponsesProgressActionSelector,
} from "../../../ducks/bff/recruitment/vacancyResponses/selectors";
import NmConfirmV2 from "../../ActualComponents/NmConfirmV2";
import NmModal from "../../ActualComponents/NmModal";
import NmRadioV2 from "../../ActualComponents/NmRadioV2";
import ApplyButtons from "../../ApplyButtons";
import NmTitle from "../../NmTitle";
import {LinkingResponseToCandidateRecruitmentFromList} from "./FromList";
import {LinkingResponseToCandidateRecruitmentNew} from "./New";

import {useModal} from "../../../hooks/useModal";

import {LINKING_RESPONSE_TO_CANDIDATE_RECRUITMENT_FORM_ID} from "./constants";

const SOURCE_TYPE = {
    FROM_LIST: "FROM_LIST",
    NEW: "NEW",
};

const RecruitmentLinkingResponseToCandidate = (props) => {
    const {
        onClose,
        onSuccess = () => {},
        title = "Привязка отклика к кандидату",
        clientId,
        vacancyId,
        responseId,
        currentCandidateId,
        isAddCandidate = false,
        card,
        addSuccessText,
    } = props;

    const vacancyResponsesProgressAction = useSelector(bffRecruitmentVacancyResponsesProgressActionSelector);
    const vacancyProgressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);
    const vacancyCandidatesProgressAction = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);
    const funnelStatusOptions = useSelector(bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector);

    const {
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const [sourceType, setSourceType] = useState(SOURCE_TYPE.FROM_LIST);

    const getConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                onCancel={onCloseConfirm}
                onConfirm={() => {
                    setSourceType(SOURCE_TYPE.FROM_LIST);
                }}
                confirmButton="Да"
                cancelButton="Нет"
                content="Введенные данные будут очищены, перейти к поиску?"
            />
        );
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    formId={LINKING_RESPONSE_TO_CANDIDATE_RECRUITMENT_FORM_ID}
                    submitBtnContent={isAddCandidate ? "Добавить" : "Сохранить"}
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    disabled={
                        vacancyResponsesProgressAction
                        || vacancyProgressAction
                        || vacancyCandidatesProgressAction
                    }
                />
            }
            classNameContent="d-flex flex-column"
            onClose={onClose}
        >
            {getConfirm()}
            <div className="d-flex flex-column flex-md-row align-items-md-center mb-3 mb-md-4">
                <NmRadioV2
                    label="Из списка кандидатов"
                    checked={sourceType === SOURCE_TYPE.FROM_LIST}
                    name="sourceType"
                    value={SOURCE_TYPE.FROM_LIST}
                    className="me-md-4 mb-2 mb-md-0"
                    onChange={() => {
                        onOpenConfirm({});
                    }}
                />
                <NmRadioV2
                    label="Создать нового кандидата"
                    checked={sourceType === SOURCE_TYPE.NEW}
                    name="sourceType"
                    value={SOURCE_TYPE.NEW}
                    onChange={() => {
                        setSourceType(SOURCE_TYPE.NEW);
                    }}
                />
            </div>
            {
                sourceType === SOURCE_TYPE.NEW &&
                <LinkingResponseToCandidateRecruitmentNew
                    clientId={clientId}
                    vacancyId={vacancyId}
                    responseId={responseId}
                    onClose={onClose}
                    fetchData={onSuccess}
                    editData={card}
                    addSuccessText={addSuccessText}
                    funnelStatusOptions={funnelStatusOptions}
                />
            }
            {
                sourceType === SOURCE_TYPE.FROM_LIST &&
                <LinkingResponseToCandidateRecruitmentFromList
                    isAddCandidate={isAddCandidate}
                    clientId={clientId}
                    vacancyId={vacancyId}
                    responseId={responseId}
                    defaultSelectedId={currentCandidateId}
                    onClose={onClose}
                    onSuccess={onSuccess}
                />
            }
        </NmModal>
    );
};

export default RecruitmentLinkingResponseToCandidate;