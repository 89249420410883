export const RU = "Российская Федерация";
export const ARM = "Республика Армения";
export const BLR = "Республика Беларусь";
export const KAZ = "Республика Казахстан";
export const KGZ = "Киргизская Республика";
export const TJ = "Республика Таджикистан";
export const UZ = "Республика Узбекистан";
export const UA = "Республика Украина";

export const citizenshipsTranscript = {
    RU,
    ARM,
    BLR,
    KAZ,
    KGZ,
    UA,
};
export const citizenshipsDict = {
    RU: {
        value: "RU",
        text: RU,
        flagSemanticUi: "ru",
    },
    BLR: {
        value: "BLR",
        text: BLR,
        flagSemanticUi: "by",
    },
    AM: {
        value: "AM",
        text: ARM,
        flagSemanticUi: "am",
    },
    BY: {
        value: "BY",
        text: BLR,
        flagSemanticUi: "by",
    },
    KZ: {
        value: "KZ",
        text: KAZ,
        flagSemanticUi: "kz",
    },
    KG: {
        value: "KG",
        text: KGZ,
        flagSemanticUi: "kg",
    },
    TJ: {
        value: "TJ",
        text: TJ,
        flagSemanticUi: "tj",
    },
    UZ: {
        value: "UZ",
        text: UZ,
        flagSemanticUi: "uz",
    },
    UA: {
        value: "UA",
        text: UA,
        flagSemanticUi: "ua",
    },
    NOT_SPECIFIED: {
        value: "NOT_SPECIFIED",
    },
    LG: {
        value: "LG",
        text: "Луганская Народная Республика (ЛНР)",
    },
    DN: {
        value: "DN",
        text: "Донецкая Народная Республика (ДНР)",
    },
};
