import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/adm/npd-deposit";
const civilController = "/adm/ndfl-deposit";

const MIN_COMMISSION_HISTORY_REQUEST = "MIN_COMMISSION_HISTORY_REQUEST";
const MIN_COMMISSION_HISTORY_SUCCESS = "MIN_COMMISSION_HISTORY_SUCCESS";
const MIN_COMMISSION_HISTORY_ERROR = "MIN_COMMISSION_HISTORY_ERROR";

const initial = {
    list: [],
    listProgress: false,
    pageData: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case MIN_COMMISSION_HISTORY_REQUEST:
        return {
            ...state,
            pageData: payload,
            listProgress: true,
        };
    case MIN_COMMISSION_HISTORY_SUCCESS:
        const {results, totalCount} = payload;

        return {
            ...state,
            list: results,
            totalCount,
            listProgress: false,
        };
    case MIN_COMMISSION_HISTORY_ERROR:
        return {
            ...state,
            error: payload,
            listProgress: false,
        };
    default: return state;
    }
};

export const getMinCommissionHistory = (payload) => ({
    type: MIN_COMMISSION_HISTORY_REQUEST,
    payload,
});

export const minCommissionSelector = state => state.minCommission;

export const minCommissionHistoryListSelector = createSelector(minCommissionSelector, ({list}) => list);
export const minCommissionHistoryTotalPagesSelector = createSelector(minCommissionSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const minCommissionHistoryTotalCountSelector = createSelector(minCommissionSelector, ({totalCount}) => totalCount);

export const minCommissionHistoryListProgressSelector = createSelector(minCommissionSelector, ({listProgress}) => listProgress);

export const getMinCommissionHistorySaga = function* ({payload}) {
    try {
        const {
            isCivil,
            ...reqData
        } = payload;
        const result = yield request.bff.post(isCivil ?
            `${civilController}/getClientDepositMinimalCommissionHistoryRichPage` :
            `${controller}/getClientMinCommissionHistoryRichPage`,
        reqData,
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MIN_COMMISSION_HISTORY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: MIN_COMMISSION_HISTORY_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: MIN_COMMISSION_HISTORY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(MIN_COMMISSION_HISTORY_REQUEST, getMinCommissionHistorySaga),
    ]);
}
