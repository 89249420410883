import {REGISTRY_OPERATION} from "../registry";

export const DEPOSIT_HISTORY_ACTION = {
    ADD_DEPOSIT: {
        text: "Пополнение депозита",
        value: "ADD_DEPOSIT",
        textAmount: "",
        COLOR: "#75ce9d",
    },

    RETURN_DEPOSIT: {
        text: "Возврат денежных средств",
        value: "RETURN_DEPOSIT",
        COLOR: "#FD550C",
    },
    REGISTRY_PAYMENT: {
        text: "Оплата по реестру",
        value: "REGISTRY_PAYMENT",
    },
    CANCELLED_REGISTRY_PAYMENT: {
        text: "Отклонение оплаты по реестру",
        value: "CANCELLED_REGISTRY_PAYMENT",
    },
    CLOSE_ORDER: {
        text: "Закрытие заказа № :orderNum",
        value: "CLOSE_ORDER",
        textAmount: "В заказах - ",
    },
    PUBLISHED_ORDER: {
        text: "Публикация заказа № :orderNum",
        value: "PUBLISHED_ORDER",
        textAmount: "В заказах - ",
    },
    ADD_INSURANCE: {
        text: "Списание суммы страхования по заказу №:orderNum",
        value: "ADD_INSURANCE",
        // textAmount: "В заказах - "
    },
    CLOSE_INSURANCE: {
        text: "Возврат суммы страхования по заказу №:orderNum",
        value: "CLOSE_INSURANCE",
        // textAmount: "В заказах - "
    },
    PAYMENT: {
        text: "Оплата №:paymentNumber по заказу №:orderNum ",
        value: "PAYMENT",
        textAmount: "В заказах - ",
    },
    CANCELLED_PAYMENT: {
        text: "Платеж отклонен",
        value: "CANCELLED_PAYMENT",
        textAmount: "",
    },
    PAYMENT_COMPLETED_ORDER_UNSECURED: {
        text:  "Завершение платежа по заказу без обеспечения",
        value: "PAYMENT_COMPLETED_ORDER_UNSECURED",
    },
    CLOSE_ORDER_UNSECURED: {
        text:  "Закрытие заказа без обеспечения ",
        value: "CLOSE_ORDER_UNSECURED",
    },
    PUBLISHED_ORDER_UNSECURED: {
        text:  "Публикация заказа без обеспечения ",
        value: "PAYMENT_COMPLETED_ORDER_UNSECURED",
    },
    CREATE_REGISTRY_ORDER: {
        text:  "Публикация заказа в рамках реестра",
        value: "CREATE_REGISTRY_ORDER",
    },
    CLOSE_REGISTRY_ORDER: {
        text:  "Закрытие заказа в рамках реестра",
        value: "CLOSE_REGISTRY_ORDER",
    },
};

export const DEPOSIT_OPERATION = {
    //Калькулятор
    GO_CALCULATOR: "GO_CALCULATOR",
    //Сформировать счет-основание
    INVOICE_OF_DEPOSIT_REPLENISHMENT: "INVOICE_OF_DEPOSIT_REPLENISHMENT",
    FILTER: "FILTER",
};

export const DEPOSIT_CARD_OPTIONS = {
    FILTER: {
        key: DEPOSIT_OPERATION.FILTER,
        text: "Фильтр",
        value: DEPOSIT_OPERATION.FILTER,
    },
    GO_CALCULATOR: {
        key: DEPOSIT_OPERATION.GO_CALCULATOR,
        text: "Калькулятор",
        value: DEPOSIT_OPERATION.GO_CALCULATOR,
    },
    INVOICE_OF_DEPOSIT_REPLENISHMENT: {
        key: DEPOSIT_OPERATION.INVOICE_OF_DEPOSIT_REPLENISHMENT,
        text: "Сформировать счет-основание",
        value: DEPOSIT_OPERATION.INVOICE_OF_DEPOSIT_REPLENISHMENT,
    },
};

