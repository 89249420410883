import React from "react";
import {debounce} from "lodash";

import {METERS_IN_KM} from "../../../../constants/map";
import {MAP_INIT_FILTER_DATA, MAP_MOSCOW_CENTER_COORDS} from "../../constants";

class MapWheelControl extends React.Component {
    componentDidMount() {
        this.debounce = debounce(this.fetchMarkers, 800);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isSearch && !this.props.isSearch) {

            this.fetchMarkers(MAP_INIT_FILTER_DATA);
        }

        if (!prevProps.map && this.props.map) {
            const {map} = this.props;

            this.fetchMarkers();

            map.on("moveend", () => {
                this.debounce();
            });

            map.on("zoomend", () => {
                this.debounce();
            });
        }
    }

    componentWillUnmount() {
        const {map} = this.props;

        this.debounce.cancel();

        map.off("moveend");
        map.off("zoomend");
    }

    fetchMarkers = (data) => {
        const {map, filterData} = this.props;
        const centerCoords = map.getCenter(); // координаты центра
        const latLngBound = map.getBounds(); // координаты видимой части
        const coordsNorthEast = latLngBound.getNorthEast(); // координаты верхней левой точки видимой части (северо-запад)
        const coordsSouthWest = latLngBound.getSouthWest(); // координаты нижней правкой точки видимой чатси (юго-восток)
        const coordsDistance = coordsNorthEast.distanceTo(coordsSouthWest); // получаем расстояние между точками

        const squareCoordinates = {
            leftLonSquare: coordsNorthEast.lng,
            rightLonSquare: coordsSouthWest.lng,
            upLatSquare: coordsNorthEast.lat,
            downLatSquare: coordsSouthWest.lat,
        };

        const radius = coordsDistance / METERS_IN_KM / 2; // получаем радиус круга в км для поиска меток
        const distance = filterData.locationAddressFiasIdsFilter ?
            filterData.distanceFilter.distance :
            radius;
        const zoom = this.getZoom();

        if (data) {
            this.props.fetchMarkers({
                ...MAP_INIT_FILTER_DATA,
                distanceFilter: {
                    coordinateLon: MAP_MOSCOW_CENTER_COORDS.LONGITUDE,
                    coordinateLat: MAP_MOSCOW_CENTER_COORDS.LATITUDE,
                    distance,
                    zoom,
                    ...squareCoordinates,
                },
            });

            return;
        }

        this.props.fetchMarkers({
            ...filterData,
            distanceFilter: {
                coordinateLon: filterData.locationAddressFiasIdsFilter ?
                    +filterData.distanceFilter.coordinateLon :
                    +centerCoords.lng,
                coordinateLat: filterData.locationAddressFiasIdsFilter ?
                    +filterData.distanceFilter.coordinateLat :
                    +centerCoords.lat,
                distance,
                zoom,
                ...squareCoordinates,
            },
        });
    };


    getZoom = () => {
        const {map} = this.props;

        if (map._zoom > 17) {
            return 4;
        }

        if (map._zoom > 14) {
            return 3;
        }

        if (map._zoom > 11) {
            return 2;
        }

        if (map._zoom > 8) {
            return 1;
        }

        if (map._zoom > 5) {
            return 0;
        }

        if (map._zoom > 3) {
            return -1;
        }

        return -2;
    }

    render() {
        return null;
    }
}

export default MapWheelControl;