import {
    KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST,
    KEDO_DOCUMENTS_EXPORT_REQUEST,
    KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST,
    KEDO_DOCUMENTS_GET_PAGE_REQUEST,
    KEDO_DOCUMENTS_UPDATE_STORE,
} from "./actions";

export const getKedoDocumentsPage = (payload) => {
    return {
        type: KEDO_DOCUMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getKedoDocumentsHistoryPage = (payload) => {
    return {
        type: KEDO_DOCUMENTS_GET_HISTORY_PAGE_REQUEST,
        payload,
    };
};

export const exportKedoDocuments = (payload) => {
    return {
        type: KEDO_DOCUMENTS_EXPORT_REQUEST,
        payload,
    };
};

export const deleteKedoDocumentsDraft = (payload) => {
    return {
        type: KEDO_DOCUMENTS_DELETE_DRAFT_REQUEST,
        payload,
    };
};

export const updateKedoDocumentsStore = (payload) => {
    return {
        type: KEDO_DOCUMENTS_UPDATE_STORE,
        payload,
    };
};