/**
 * Собираем объекты из дерева
 * @param params
 * @returns {*}
 */
export const getObjectsByProjectsTree = (params) => {
    const {
        projects,
    } = params;

    return projects
        .filter(item => {
            if (!item.changed) {
                return false;
            }

            if (item.checked) {
                return false;
            }

            const objects = item.children;

            if (objects) {
                const isAllObjectsEqualParam = objects.every((item, index, array) => {
                    return item.checked === array[0].checked;
                });

                if (isAllObjectsEqualParam && item.partiallyChecked) {
                    return true;
                }

                return !isAllObjectsEqualParam;
            }

            return false;
        })
        .reduce((result, item) => {
            if (item.children) {
                const objects = item.children
                    .filter(item => item.changed)
                    .map(item => {
                        return {
                            id: item.value,
                            boolParam: item.checked,
                        };
                    });

                return [
                    ...result,
                    ...objects,
                ];
            }

            return result;
        }, []);
};