import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const bffSystemTransactionsSelector = state => state.bff.systemTransactions;
export const bffSystemTransactionsListSelector  = createSelector(bffSystemTransactionsSelector, ({list}) => list);
export const bffSystemTransactionsTotalCountSelector  = createSelector(bffSystemTransactionsSelector, ({totalCount}) => totalCount);
export const bffSystemTransactionsTotalPagesSelector  = createSelector(bffSystemTransactionsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffSystemTransactionsProgressSelector  = createSelector(bffSystemTransactionsSelector, ({progress}) => progress);
export const bffSystemTransactionsLogListSelector  = createSelector(bffSystemTransactionsSelector, ({logList}) => logList);
export const bffSystemTransactionsLogTotalCountSelector  = createSelector(bffSystemTransactionsSelector, ({logTotalCount}) => logTotalCount);
export const bffSystemTransactionsLogTotalPagesSelector  = createSelector(bffSystemTransactionsSelector, ({logTotalCount, logPageData: {pageSize = 0}}) => getTotalPages(logTotalCount, pageSize));
export const bffSystemTransactionsLogProgressSelector  = createSelector(bffSystemTransactionsSelector, ({logProgress}) => logProgress);