import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import OSM from "../osm";

import {bffMapPageDataSelector} from "../../../../ducks/bff/map/selectors";

import "./style.sass";

const MapComponent = (props) => {
    const {
        mapClusters = {},
        onMarkerClick,
        isSearch,
        modalInfoData,
        filterData,
        fetchMarkers,
        onOpenModal,
        onChangeFilter,
    } = props;

    const mapPageData = useSelector(bffMapPageDataSelector);

    useEffect(() => {
        onChangeFilter(null, {name: "types", value: mapPageData.types || []});
    }, []);

    return (
        <div className="map-component">
            <OSM
                isSearch={isSearch}
                filterData={filterData}
                onMarkerClick={onMarkerClick}
                modalInfoData={modalInfoData}
                mapClusters={mapClusters}
                fetchMarkers={fetchMarkers}
                scrollWheelZoom={true}
                onOpenModal={onOpenModal}
            />
        </div>
    );
};

export default MapComponent;
