import React, { FC } from "react";

import {ReactComponent as InfoIcon} from "../../../../../../images/info_24.svg";
import NmBadge from "../../../../../NmBadge";
import NmAdvancedTooltip from "../../../../NmAdvancedTooltip";

import bem from "../../../../../../utils/bem";

import {TNmListCardError} from "../../types";

interface INmListCardErrors {
    className?: string,
    errors: Array<TNmListCardError>
}

const NmListCardErrors: FC<INmListCardErrors> = (props) => {
    const {
        errors,
        className,
    } = props;
    const [block] = bem("nm-list-card-errors", className);

    const getErrorsText = () => {
        return errors.map(({errorMessage}) => {
            return (
                <ul style={{paddingLeft: 20, margin: 0}}>
                    <li>
                        {errorMessage}
                    </li>
                </ul>
            );
        });
    };

    return (
        <div className={block()}>
            <NmBadge
                rightIcon={
                    <NmAdvancedTooltip
                        type="light"
                        position="top-right"
                        classNameTooltip="nm-list-card__errors-tooltip"
                        children={getErrorsText()}
                        trigger={<InfoIcon />}
                    />
                }
                tooltipPosition="bottom-left"
                mod="red"
                text="Ошибка"
            />
        </div>
    );
};

export default NmListCardErrors;