import {
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_ERROR,
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_REQUEST,
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_SUCCESS,
    UPDATE_PLUTONIUM_TRANSACTION_LOG_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.logs,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case UPDATE_PLUTONIUM_TRANSACTION_LOG_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};