import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {DocumentsAdditionalAgreementEdit} from "./components/edit";
import {DocumentsAdditionalAgreementsWarnings} from "./components/warnings";

import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import {useAdditionalAgreementsAction} from "./hooks/useAction";
import {useAdditionalAgreementsFetch} from "./hooks/useFetch";
import {useAdditionalAgreementsFilters} from "./hooks/useFilters";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {formatLocalDate} from "../../../utils/dateFormat";
import {getFullName} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS,
    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD,
} from "../../../constants/documentType";
import {LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD} from "../../../constants/links";

import {
    bffAdditionalAgreementsListSelector,
    bffAdditionalAgreementsProgressSelector,
    bffAdditionalAgreementsTotalCountSelector,
    bffAdditionalAgreementsTotalPagesSelector,
} from "../../../ducks/bff/documents/additional-agreements/selectors";
import {downloadDocument} from "../../../ducks/documents";

export const DocumentsAdditionalAgreements = (props) => {
    const clientId = props.match.params.clientId;

    const [archivedFilter, setArchivedFilter] = useState(false);
    const [setOpenFilter, openFilter] = useState(true);

    const {t} = useTranslation();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useAdditionalAgreementsFilters({
        pageSize,
        setPagination,
        clientId,
    });

    useAdditionalAgreementsFetch({
        pageNum,
        pageSize,
        filterData,
        archivedFilter,
        clientId,
    });

    const dispatch = useDispatch();

    const list = useSelector(bffAdditionalAgreementsListSelector);
    const totalCount = useSelector(bffAdditionalAgreementsTotalCountSelector);
    const totalPages = useSelector(bffAdditionalAgreementsTotalPagesSelector);
    const isLoading = useSelector(bffAdditionalAgreementsProgressSelector);

    const isVisibleMassActions = !archivedFilter;

    const {
        selectedIds,
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useSelectedList({
        list,
        idName: "id",
    });

    const {
        onOpenModal,
        modalData,
        onCloseModal,
        getListMediaControls,
        onClickSignRows,
    } = useAdditionalAgreementsAction({
        isList: true,
        selectedIds,
    });

    const getConfirmWindow = () => {
        if (!modalData?.confirmProps) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                content={modalData.confirmProps.content}
                onCancel={onCloseModal}
                onConfirm={modalData.confirmProps.onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getPrimaryHeader = (item) => {
        const link = LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD
            .replace(":clientId", clientId)
            .replace(":id", item.id);

        return (
            <ExtLink
                historyEnabled
                title="Перейти в карточку"
                to={link}
            >
                {item.name}
            </ExtLink>
        );
    };

    const getEditModal = () => {
        if (!modalData?.isOpenEdit) {
            return null;
        }

        return (
            <DocumentsAdditionalAgreementEdit
                id={modalData.id}
                clientId={clientId}
                onClose={onCloseModal}
            />
        );
    };

    const getRows = () => {
        return selectedList.map(item => {
            return {
                ...item,
                key: item.kedoStaffId,
                showCheckBox: isVisibleMassActions,
                disabledCheckBox: ![
                    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.CREATED,
                ].includes(item.status?.value),
                error: item.status?.value === DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.DECLINED,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-8"
                        primaryHeaderLink
                        primaryHeader={getPrimaryHeader(item)}
                        secondaryHeader={`Дата создания ${formatLocalDate(item.creationDateTime, "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD[item.status?.value]}
                                text={item.status?.description}
                            />
                        }
                        labels={[
                            {
                                label: "Ответственный",
                                text: getFullName(
                                    item.creatorLastName,
                                    item.creatorFirstName,
                                    item.creatorPatronymic,
                                ),
                            },
                            {
                                label: "Шаблон доп. соглашения",
                                text: item.documentTemplateName,
                                color: "blue",
                                columnOnMobile: true,
                                cursorPointer: true,
                                onClickText: () => {
                                    dispatch(downloadDocument({
                                        isDownload: true,
                                        downloadLink: item.documentTemplateDownloadLink,
                                        fileName: `${item.documentTemplateName}.pdf`,
                                    }));
                                },
                                noWrap: false,
                            },
                        ]}
                        cards={[
                            {
                                title: "Всего / Подписано / В процессе / Отклонено",
                                values: [
                                    {
                                        bold: true,
                                        text: item.totalItemsCount,
                                    },
                                    {
                                        text: item.signedItemsCount,
                                    },
                                    {
                                        text: item.submittedItemsCount,
                                    },
                                    {
                                        text: item.declinedItemsCount,
                                    },
                                ],
                                width: 278,
                                className: "d-flex justify-content-start justify-content-xxl-end mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-7 align-items-end"
                        mediaControls={getListMediaControls(item)}
                        actionsClassName="col-1"
                        primaryHeaderWordBreak
                    />
                ),
            };
        });
    };

    const mediaControls = useMemo(() => {
        const archive = getArchiveButton(t, archivedFilter, {mobile: true, tablet: true});

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Добавить реестр",
                        icon: <AddIcon />,
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                            });
                        },
                    },
                    visible: !archivedFilter,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: () => {
                            setArchivedFilter(!archivedFilter);
                        },
                    },
                },
            ],
        };
    }, [
        archivedFilter,
    ]);

    return (
        <NmPage
            header={
                <>
                    <NmTitle
                        count={totalCount}
                        size="xl"
                    >
                    Реестры на подписание доп. соглашений
                    </NmTitle>
                    {getConfirmWindow()}
                    {getEditModal()}
                </>
            }
            mediaControls={mediaControls}
            openFilter={openFilter}
            onOpenFilter={setOpenFilter}
            typeFilter="vertical"
            widthByFilter
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={isLoading}
            isEmptyPage={isEmpty(list)}
            emptyPageProps={{
                isSearch,
            }}
        >
            <DocumentsAdditionalAgreementsWarnings />
            <CheckboxList
                rows={getRows()}
                header={
                    isVisibleMassActions &&
                    <SelectionCountWithAction
                        adaptiveLogic
                        count={countSelected}
                        buttonColor="grey"
                        buttonContent="Передать на подписание"
                        onClick={onClickSignRows}
                    />
                }
                actionOptions={{
                    key: "sign",
                    value: "sign",
                    text: "Удалить строку",
                    onClick: onClickSignRows,
                    disabled: countSelected === 0,
                }}
                onSelectedRows={isVisibleMassActions && handleSelectedRows}
            />
        </NmPage>
    );
};