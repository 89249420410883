import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {RecruitmentCandidateInformationCard} from "../../../../components/Recruitment/InformationCard";
import {
    deleteRecruitmentVacancyResponsesFile,
    getRecruitmentVacancyResponseCardById,
    updateRecruitmentVacancyResponse,
    uploadRecruitmentVacancyResponsesFile,
} from "../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import {
    bffRecruitmentVacancyResponsesCardSelector,
    bffRecruitmentVacancyResponsesProgressActionSelector,
} from "../../../../ducks/bff/recruitment/vacancyResponses/selectors";

export const RecruitmentResponseCandidateCard = (props) => {
    const {
        match: {
            params: {
                vacancyId,
                id: responseId,
                clientId,
            },
        },
    } = props;

    const card = useSelector(bffRecruitmentVacancyResponsesCardSelector);
    const progressAction = useSelector(bffRecruitmentVacancyResponsesProgressActionSelector);

    const dispatch = useDispatch();

    const fetchInformation = () => {
        dispatch(getRecruitmentVacancyResponseCardById({
            vacancyId: props.match.params.vacancyId,
            responseId: props.match.params.id,
        }));
    };

    const updateInformation = (updateData) => {
        const {
            onSuccess,
            ...data
        } = updateData;

        dispatch(updateRecruitmentVacancyResponse({
            id: responseId,
            vacancyId,
            responseId,
            clientId,
            ...card,
            ...data,
            onSuccess,
        }));
    };

    const uploadFile = (data) => {
        dispatch(uploadRecruitmentVacancyResponsesFile({
            responseId: card.responseId,
            ...data,
        }));
    };

    const deleteFile = (data) => {
        dispatch(deleteRecruitmentVacancyResponsesFile({
            responseId: card.responseId,
            ...data,
        }));
    };

    return (
        <RecruitmentCandidateInformationCard
            card={card}
            vacancyId={props.match.params.vacancyId}
            responseId={props.match.params.id}
            fetchInformation={fetchInformation}
            updateInformation={updateInformation}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            progressAction={progressAction}
            {...props}
        />
    );
};