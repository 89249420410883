import axios from "axios";
import {push} from "connected-react-router";
import qs from "qs";
import {call, put} from "redux-saga/effects";

import {ACCESS_TOKEN_KEY, ls} from "./localstorage";
import {toastError} from "./toastHelper";

import {LINK_LOGIN} from "../constants/links";
import {MESSAGE_ERROR_403} from "../constants/messages";

import {refreshToken} from "../ducks/auth";

let isRefreshing = false;

export const postman = axios.create({
    baseURL: "/api",
    paramsSerializer: params => qs.stringify(params, {indices: false}),
});

export const bffPostman = axios.create({
    baseURL: "/bff",
    paramsSerializer: params => qs.stringify(params, {indices: false}),
});

postman.interceptors.response.use(
    resp => resp.data,
    error => Promise.reject(error),
);

bffPostman.interceptors.response.use(
    resp => resp.data,
    error => Promise.reject(error),
);

export function getMultipartHeaders() {
    return {
        headers: {
            ...postman.defaults.headers,
            "Content-Type": "multipart/form-data",
        },
    };
}

export const setAccessToken = creds => {
    if (creds !== null) {
        const {accessToken} = creds;

        postman.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        bffPostman.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        delete postman.defaults.headers.common.Cookie;
        delete bffPostman.defaults.headers.common.Cookie;
    }
};

export default function* request(callee, ...opts) {
    let done = false;
    let countRequest = 0;
    let result = null;

    while (!done) {
        try {
            done = true;
            result = yield call(callee, ...opts);
            isRefreshing = false;
        } catch (err) {
            console.log("err", err);
            const response = err.response || {};

            switch (response.status) {
            case 401:
                if (!isRefreshing && countRequest === 0) {
                    isRefreshing = true;
                    yield call(refreshToken);
                }

                done = countRequest > 3;
                console.log("_________________refresh");
                countRequest += 1;
                if (done) {
                    console.log("p_error", err);
                    isRefreshing = false;
                    yield put(push(LINK_LOGIN));
                }
                result = response.data;
                break;
            case 400:
                result = response.data;
                done = true;
                break;
            case 403:
                const message = response?.data?.errorMessage || MESSAGE_ERROR_403;

                toastError(message);
                done = true;

                throw new Error(message);
            default:
                done = true;
                throw new Error(response.data.message);
            }
        }
    }

    return result;
}

function getInit(opts) {
    return {
        mode: "cors",
        cache: "default",
        headers: {
            Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
            "Content-Type": "application/json",
        },
        ...opts,
    };
}

export function* getFile(url, opts = {}) {
    let done = false;
    let countRequest = 0;
    let result = null;

    while (!done) {
        try {
            done = true;
            result = yield fetch(url, getInit(opts));
            if (!result.ok) {
                throw result;
            }
            isRefreshing = false;
        } catch (err) {
            const response = err;
            switch (response.status) {
            case 401:
                if (!isRefreshing && countRequest === 0) {
                    isRefreshing = true;
                    yield call(refreshToken);
                }

                done = countRequest > 3;
                console.log("_________________refresh");
                countRequest += 1;
                if (done) {
                    isRefreshing = false;
                }
                result = response;
                break;
            case 403:
                const message = response?.data?.errorMessage || MESSAGE_ERROR_403;

                toastError(message);
                done = true;

                throw new Error(message);
            case 404:
                done = true;
                toastError("Нет файла");
                throw new Error(response.statusText);
            default:
                done = true;
                throw new Error(response.statusText);
            }
        }
    }

    return result;
}

request.getFile = function* GET_FILE(url, opts) {
    return yield getFile(url, opts);
};

request.axios = function* AXI(...args) {
    return yield request(postman, ...args);
};
request.get = function* GET(...args) {
    return yield request(postman.get, ...args);
};
request.put = function* PUT(...args) {
    return yield request(postman.put, ...args);
};
request.post = function* POST(...args) {
    return yield request(postman.post, ...args);
};
request.delete = function* DELETE(...args) {
    return yield request(postman.delete, ...args);
};
request.patch = function* PATCH(...args) {
    return yield request(postman.patch, ...args);
};
request.bff = {
    axios: function* AXI(...args) {
        return yield request(bffPostman, ...args);
    },
    get: function* GET(...args) {
        return yield request(bffPostman.get, ...args);
    },
    put: function* PUT(...args) {
        return yield request(bffPostman.put, ...args);
    },
    post: function* POST(...args) {
        return yield request(bffPostman.post, ...args);
    },
    delete: function* DELETE(...args) {
        return yield request(bffPostman.delete, ...args);
    },
    patch: function* PATCH(...args) {
        return yield request(bffPostman.patch, ...args);
    },
    getFile: function* GET_FILE(url, opts) {
        return yield getFile(`/bff${url}`, opts);
    },
};

export const isResponseSuccessOfJsonType = (response) => {
    return response.status === 200
        && response.headers.get("Content-Type")
        && response.headers.get("Content-Type").includes("json");
};