import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmModal from "../../../components/ActualComponents/NmModal";
import NmPagination from "../../../components/ActualComponents/NmPagination";
import NmTitle from "../../../components/NmTitle";
import DepositOfFund from "../deposit-of-funds";
import DepositReturn from "../return";
import {Icon} from "semantic-ui-react";

import dateFormat, {compareDatesWithTime, convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {formatAmount, formatNumber, phoneFormat} from "../../../utils/stringFormat";

import {DEPOSIT_HISTORY_ACTION} from "../../../constants/deposit/actions";
import {PAGE_SIZES_DEFAULT} from "../../../constants/page";
import {ADMIN} from "../../../constants/roles";

import {
    clientMemberFullNameClientSelector, getClientMemberByIds,
    updateFieldClientMemberStore,
} from "../../../ducks/clientMember";
import {
    getDepositHistoryListSelector, getDepositHistoryTotalCountSelector, getDepositHistoryTotalPagesSelector,
    getHistoryDepositList, updateFieldDepositStore,
} from "../../../ducks/deposit";

import {depositHistoryDependedType, depositHistoryType, depositManagerType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class DepositHistory extends Component {
    static propTypes = {
        close: PropTypes.func,
        dependentData: depositHistoryDependedType,
        getHistoryDepositList: PropTypes.func,
        getClientMemberByIds: PropTypes.func,
        updateFieldClientMemberStore: PropTypes.func,
        updateFieldDepositStore: PropTypes.func,
        totalPages: PropTypes.number,
        depositHistoryList: PropTypes.arrayOf(depositHistoryType),
        managerList: PropTypes.arrayOf(depositManagerType),
    };

    static defaultProps = {
        close: () => {
        },
        dependentData: PropTypes.object,
        getHistoryDepositList: () => {
        },
        getClientMemberByIds: () => {
        },
        updateFieldClientMemberStore: () => {
        },
        updateFieldDepositStore: () => {
        },
        totalPages: 0,
        depositHistoryList: [],
        managerList: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            pageNum: 1,
            pageSize: PAGE_SIZES_DEFAULT[0],
            isOpenDepositEdit: false,
            clientDepositHistoryId: undefined,
        };

        this.role = ls(USER_ROLE);
        this.pageSizes = [25, 50, 100];
    }

    componentDidMount() {
        this.fetchHistoryList();
    }

    componentDidUpdate(prevProps) {
        const {depositHistoryList: oldList} = prevProps;
        const {depositHistoryList} = this.props;

        if (depositHistoryList.length && JSON.stringify(depositHistoryList) !== JSON.stringify(oldList)) {
            this.fetchManagers();
        }
    }

    componentWillUnmount() {
        const {
            updateFieldClientMemberStore,
            updateFieldDepositStore,
        } = this.props;

        updateFieldClientMemberStore({list: []});
        updateFieldDepositStore({depositHistoryList: []});
    }

    fetchHistoryList = () => {
        const {
            getHistoryDepositList,
            dependentData: {
                clientId,
            },
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        getHistoryDepositList({
            sortType: "desc",
            clientId,
            pageNum,
            pageSize,
        });
    };

    fetchManagers = () => {
        const {getClientMemberByIds, depositHistoryList} = this.props;

        const clientUserIdsSet = new Set(depositHistoryList.map(value => value.clientUserId));

        getClientMemberByIds(
            Array.from(clientUserIdsSet),
        );
    };

    renderAmount(amount) {
        const {t} = this.props;

        if (amount === null) {
            return null;
        }

        const fixedNumber = 2;
        return (
            <div className="flex flex-aligned-center">
                <div>
                    {formatAmount(formatNumber(amount, fixedNumber))} 
                    {" "}
                    {t("rub")}
                </div>
            </div>
        );
    }

    renderUser(manager, {phone}) {
        const {t} = this.props;

        return (
            <div
                data-name={t("deposit-history.header-column-user")}
                className="deposit-history_text-content column-10"
            >
                <div>
                    <div>
                        {manager.fullName}
                    </div>
                    <div>
                        {phoneFormat(phone)}
                    </div>
                </div>
            </div>
        );
    }

    renderDepositOfFunds() {
        const {
            isOpenDepositEdit,
            clientDepositHistoryId,
            isOfFund,
        } = this.state;

        const {dependentData} = this.props;

        if (!isOpenDepositEdit) {
            return null;
        }
        
        const props = {
            isEdit: true,
            dependentData: {
                ...dependentData,
                clientDepositHistoryId,
            },
            close: this.closeDepositEditModal,
        };

        return isOfFund ? <DepositOfFund {...props} /> : <DepositReturn {...props} />;
    }

    renderDepositReturn() {
        const {
            isOpenDepositReturn,
            clientDepositHistoryId,
        } = this.state;

        const {dependentData} = this.props;

        return isOpenDepositReturn ? (
            <DepositReturn
                dependentData={{
                    ...dependentData,
                    clientDepositHistoryId,
                }}
                close={this.closeDepositReturnModal}
            />
        ) : null;
    }

    openDepositEditModal = (clientDepositHistoryId, action) => {
        this.setState({
            isOpenDepositEdit: true,
            clientDepositHistoryId,
            isOfFund: action === DEPOSIT_HISTORY_ACTION.ADD_DEPOSIT.value,
        });
    };

    closeDepositEditModal = () => {
        this.setState({
            isOpenDepositEdit: false,
            clientDepositHistoryId: "",
            isOfFund: false,
        });
    };

    renderAction({action, clientDepositHistoryId, datetime}) {
        const {t} = this.props;

        const isCanEditByActions = action === DEPOSIT_HISTORY_ACTION.ADD_DEPOSIT.value || action === DEPOSIT_HISTORY_ACTION.RETURN_DEPOSIT.value;

        if (this.role === ADMIN && (isCanEditByActions && compareDatesWithTime(datetime, 48))) {
            return (
                <div className="ml-15">
                    <Icon
                        title={action === DEPOSIT_HISTORY_ACTION.ADD_DEPOSIT.value ? t("deposit-modal.deposit-funds-edit-title") : t("deposit-modal.deposit-return-edit-title")}
                        onClick={() => {
                            this.openDepositEditModal(clientDepositHistoryId, action);
                        }}
                        name="pencil alternate cell-edit"
                        className="pointer"
                    />
                </div>
            );
        }
        return null;
    }

    renderList(list) {
        const {managerList, t} = this.props;

        return list.map(({
            datetime,
            description,
            clientUserId,
            action,
            amount,
            orderNum,
            paymentNumber,
            phone,
            commissionReserveAmount,
            orderReserveAmount,
            commissionMinAmount,
            depositAmount,
            commissionAmount,
            clientDepositHistoryId,
            edit,
            transactionDateBank,
        },
        index) => {
            const manager = managerList.find(itemM => itemM.clientUserId === clientUserId) || {};

            return (
                <Fragment key={index}>
                    <div className="deposit-history_list deposit-history-row">
                        <div
                            data-name={t("deposit-history.header-column-date")}
                            className="deposit-history_text-content column-15"
                        >
                            <div>
                                <div>
                                    {dateFormat(convertUtcToLocal(datetime), "dd.MM.yyyy")}
                                </div>
                                <div>
                                    {dateFormat(convertUtcToLocal(datetime), "HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                        <div
                            data-name={t("deposit-history.header-column-operation")}
                            className="deposit-history_text-content deposit-history_text column-20"
                        >
                            {DEPOSIT_HISTORY_ACTION[action] ? DEPOSIT_HISTORY_ACTION[action].text.replace(":orderNum", orderNum || t("deposit-history.indefined")).replace(":paymentNumber", paymentNumber) : "Оперрация не определена"}
                        </div>
                        <div
                            data-name={t("deposit-history.header-column-sum")}
                            className="deposit-history_text column-10 flex flex-aligned-center"
                        >
                            <div style={{color: DEPOSIT_HISTORY_ACTION[action] ? DEPOSIT_HISTORY_ACTION[action].COLOR : ""}}>
                                {this.renderAmount(amount, edit)}
                            </div>
                            {edit ? <div className="ml-15">
                                <i
                                    title={t("client-deposit-history.hint-edit-amount")}
                                    aria-hidden="true"
                                    className="pointer pencil alternate at icon"
                                />
                            </div> : null}
                        </div>
                        <div
                            data-name={t("deposit-history.header-column-commission")}
                            className="deposit-history_text-content deposit-history_text column-10"
                        >
                            {this.renderAmount(commissionAmount)}
                        </div>
                        <div
                            data-name={t("deposit-history.header-column-commission-min")}
                            className="deposit-history_text-content deposit-history_text column-10"
                        >
                            {this.renderAmount(commissionMinAmount)}
                        </div>
                        <div
                            data-name={t("deposit-history.header-column-deposit")}
                            className="deposit-history_text-content deposit-history_text column-10"
                        >
                            {this.renderAmount(depositAmount)}
                        </div>
                        <div
                            data-name={t("deposit-history.header-column-reserve")}
                            className="deposit-history_text-content deposit-history_text column-10"
                        >
                            {this.renderAmount(orderReserveAmount)}
                        </div>
                        {/*<div*/}
                        {/*data-name="Резерв по комиссиям"*/}
                        {/*className="deposit-history_text-content deposit-history_text column-10"*/}
                        {/*>*/}
                        {/*{commissionReserveAmount !== null ? `${formatAmount(formatNumber(commissionReserveAmount))} руб.` : ""}*/}
                        {/*</div>*/}
                        {this.renderUser(manager, {phone, action})}
                        <div
                            data-name={t("deposit-history.header-column-reserve")}
                            className="deposit-history_text-content column-5"
                        >
                            {
                                transactionDateBank === null &&
                                this.renderAction({action, clientDepositHistoryId, datetime})
                            }
                        </div>
                    </div>
                    <div className="deposit-history-line" />
                </Fragment>
            );
        });
    }

    changePageNum = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchHistoryList,
        );
    };

    changePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchHistoryList,
        );
    };

    render() {
        const {
            close,
            depositHistoryList,
            totalPages,
            t,
            dependentData: {
                clientName,
            },
            totalCount,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <>
                <NmModal
                    onClose={close}
                    className="deposit-history"
                    classNameContent="deposit-history__content"
                    header={
                        <NmTitle size="lg">
                            {`${t("deposit-history.deposit-history-title")} ${clientName}`}
                        </NmTitle>
                    }
                >
                    <div className="deposit-history-table">
                        <div className="deposit-history_list mg-top-20 deposit-history-row">
                            <div className="deposit-history_text-header deposit-history_header-col column-15">
                                {t("deposit-history.header-column-date")}
                            </div>
                            {/*<div className="deposit-history_text">{payment ? "Да" : "Нет"}</div>*/}
                            <div className="deposit-history_text-header deposit-history_header-col column-20">
                                {t("deposit-history.header-column-operation")}
                            </div>
                            <div className="deposit-history_text-header deposit-history_header-col column-10">
                                {t("deposit-history.header-column-sum")}
                            </div>
                            <div className="deposit-history_text-header deposit-history_header-col column-10">
                                {t("deposit-history.header-column-commission")}
                            </div>
                            <div className="deposit-history_text-header deposit-history_header-col column-10">
                                {t("deposit-history.header-column-commission-min")}
                            </div>
                            <div className="deposit-history_text-header deposit-history_header-col column-10">
                                {t("deposit-history.header-column-deposit")}
                            </div>
                            <div className="deposit-history_text-header deposit-history_header-col column-10">
                                {t("deposit-history.header-column-reserve")}
                            </div>
                            <div className="deposit-history_text-header deposit-history_header-col column-10">
                                {t("deposit-history.header-column-user")}
                            </div>
                            <div className="deposit-history_header-col column-5">
                            </div>
                        </div>
                        <div className="deposit-history-line" />
                        {this.renderList(depositHistoryList)}
                        {this.renderDepositOfFunds()}
                    </div>
                    <NmPagination
                        className="deposit-history__pagination"
                        totalCount={totalCount}
                        pageNum={pageNum}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        onChangePageSize={this.changePageSize}
                        onChangePagination={this.changePageNum}
                    />
                </NmModal>
            </>
        );
    }
}

export default connect(
    state => ({
        totalPages: getDepositHistoryTotalPagesSelector(state),
        totalCount: getDepositHistoryTotalCountSelector(state),
        depositHistoryList: getDepositHistoryListSelector(state),
        managerList: clientMemberFullNameClientSelector(state),
    }),
    {
        getHistoryDepositList,
        getClientMemberByIds,
        updateFieldClientMemberStore,
        updateFieldDepositStore,
    })(withTranslation()(DepositHistory));
