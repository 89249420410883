import React from "react";
import {useSelector} from "react-redux";

import FilterSpecialities from "../../../../components/ActualComponents/FilterSpecialities";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import RecruitmentCandidateError from "../../../../components/Recruitment/CandidateError";

import {useRecruitmentCandidatesEditForm} from "./hooks/useForm";

import {dadataValueCityFormatter, getOptionsUniqCity} from "../../../../utils/dadata";

import {GENDER_OPTIONS} from "../../../../constants/contractorInfo";
import {PHONE_MASK} from "../../../../constants/phone";

import {getCitizenshipOptionsSelector} from "../../../../ducks/contractor";

export const RecruitmentCandidatesEditForm = (props) => {
    const {
        formId,
        onClose,
        editData,
        fetchData,
        clientId,
        vacancyId,
        responseId,
        isRedirectToCard = true,
        addSuccessText,
        isVisibleFunnelStage,
        funnelStatusOptions,
    } = props;

    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);

    const {
        onChange,
        errors,
        values,
        handleSubmit,
        handleSubmitOutOfForm,
        touched,
        setFieldTouched,
    } = useRecruitmentCandidatesEditForm({
        onClose,
        editData,
        fetchData,
        clientId,
        vacancyId,
        responseId,
        isRedirectToCard,
        addSuccessText,
        isVisibleFunnelStage,
    });

    return (
        <NmForm
            id={formId}
            onSubmit={handleSubmit}
            addMargin
        >
            <RecruitmentCandidateError
                onSubmit={handleSubmitOutOfForm}
            />
            <NmInputV2
                required
                size="xl"
                onChange={onChange}
                name="name"
                value={values.name}
                placeholder="Введите ФИО"
                label="ФИО"
                maxLength={150}
                onBlur={() => {setFieldTouched("name", true);}}
                error={touched && touched.name ?
                    errors && errors.name :
                    undefined
                }
            />
            <NmDropdownV2
                search
                size="xl"
                placeholder="Выберите гражданство"
                label="Гражданство"
                onChange={onChange}
                name="citizenship"
                value={values.citizenship}
                options={citizenshipOptions}
            />
            <div className="row gx-4">
                <div className="col-16 col-md-8">
                    <NmDatePicker
                        size="xl"
                        dateFormatMask="99.99.9999"
                        label="Дата рождения"
                        maxDate={new Date()}
                        name="birthDate"
                        selected={values.birthDate}
                        onChange={onChange}
                        isClearable
                    />
                </div>
                <div className="col-16 col-md-8 mt-3 mt-md-0">
                    <NmDropdownV2
                        size="xl"
                        label="Пол"
                        name="gender"
                        placeholder="Выберите пол"
                        options={GENDER_OPTIONS}
                        value={values.gender}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="row gx-4">
                <div className="col-16 col-md-8">
                    <NmInputV2
                        size="xl"
                        mask={PHONE_MASK.FOREIGN}
                        maskChar={null}
                        label="Номер телефона"
                        name="phone"
                        value={values.phone}
                        placeholder="Введите номер телефона"
                        onChange={onChange}
                    />
                </div>
                <div className="col-16 col-md-8 mt-3 mt-md-0">
                    <NmInputV2
                        size="xl"
                        label="Email"
                        labelClassName="d-flex"
                        placeholder="Введите email"
                        name="email"
                        onChange={onChange}
                        value={values.email}
                        onBlur={() => {setFieldTouched("email", true);}}
                        error={touched && touched.email ?
                            errors && errors.email :
                            undefined
                        }
                    />
                </div>
            </div>
            <FilterSpecialities
                size="xl"
                multiple={true}
                name="specialityIds"
                label="Вид деятельности"
                placeholder="Выберите вид деятельности"
                value={values.specialityIds}
                onBlur={() => {setFieldTouched("specialityIds", true);}}
                onChange={onChange}
                search={true}
            />
            <NmDadata
                size="xl"
                isClearSearchOnBlur
                name="addressFiasIds"
                label="Город ведения деятельности"
                onChange={onChange}
                placeholder="Выберите город"
                formatter={dadataValueCityFormatter}
                optionsFormatter={getOptionsUniqCity}
                multiple={true}
                isLoadInitialFromDadata={false}
                initialOptions={editData?.citiesOptions}
                initialAddressIds={values.addressFiasIds}
                fromBound="city"
                toBound="settlement"
            />
            {
                isVisibleFunnelStage &&
                <NmDropdownV2
                    size="xl"
                    required={true}
                    label="Этап воронки"
                    name="statusId"
                    placeholder="Добавьте на этап"
                    options={funnelStatusOptions}
                    value={values.statusId}
                    onChange={onChange}
                    onBlur={() => {setFieldTouched("statusId", true);}}
                    error={touched && touched.statusId ?
                        errors && errors.statusId :
                        undefined
                    }
                />
            }
        </NmForm>
    );
};
