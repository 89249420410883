import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageCrowdActRegistry,
    updateStoreCrowdActRegistry,
} from "../../../../ducks/bff/crowd/actRegistry/actionCreators";

export const useCrowdTaskActRegistryFetch = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        archivedFilter,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdActRegistry({
                pageData: {},
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, archivedFilter, filter]);

    const fetchList = () => {
        dispatch(getPageCrowdActRegistry({
            clientIds: [clientId],
            pageNum,
            pageSize,
            archivedFilter,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};