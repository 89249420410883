import React from "react";
import {Switch} from "react-router";

import ContractorRoute from "../../components/ContractorRoute";
import TicketCard from "../crm/ticket/card";
import CrmTicketList from "../crm/ticket/list";
import ContractorBanksList from "./banks";
import DeviceLog from "./contractor-device-list";
import ContractorEventLogCommon from "./contractor-event-log/contractor-event-log-common";
import ContractorOrderList from "./contractor-order-list";
import ContractorProfile from "./contractor-profile";
import ContractorDocuments from "./documents";
import ContractorCardFinances from "./finance";
import ContractorPersonalData from "./personal-data";
import ContractorReviewsPage from "./reviews";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {
    LINK_CONTRACTOR_BANKS_LIST,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CARD_EVENT_LOG,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CONTRACTOR_DEVICE_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_TASK_LIST,
} from "../../constants/links";
import {
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    RNKO,
} from "../../constants/roles";

export function ContractorRoutes() {
    const role = ls(USER_ROLE);
    const isEditable = ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, RNKO].includes(role) && !isClientUser(role);

    return (
        <Switch>
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_PROFILE}
                component={ContractorProfile}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_DOCUMENT_LIST}
                render={props => (
                    <ContractorDocuments
                        {...props}
                        isEditable={isEditable}
                    />
                )}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_PERSONAL_INFO}
                component={ContractorPersonalData}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_ORDER_LIST}
                component={ContractorOrderList}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_TASK_LIST}
                component={ContractorOrderList}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_REVIEWS_LIST}
                component={ContractorReviewsPage}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_DEVICE_LIST}
                component={DeviceLog}
            />
            <ContractorRoute
                path={LINK_CONTRACTOR_CARD_EVENT_LOG}
                component={ContractorEventLogCommon}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_CRM_TICKET_LIST}
                component={CrmTicketList}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CRM_TICKET_CARD}
                component={TicketCard}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST}
                component={ContractorCardFinances}
            />
            <ContractorRoute
                exact
                path={LINK_CONTRACTOR_BANKS_LIST}
                component={ContractorBanksList}
            />
        </Switch>
    );
}