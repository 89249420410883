import React, {useState} from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";

import bem from "../../../../utils/bem";

import {foremanContractorsOptionsSelector} from "../../../../ducks/foremanPayments";

import "./style.sass";

const BrigadePaymentsFilter = ({submitFilter}) => {
    const [block, element] = bem("brigade-payments-filter");
    const [value, setValue] = useState("");
    const foremanContractorsOptions = useSelector(foremanContractorsOptionsSelector);

    const onChange = (event, {value}) => {
        setValue(value);
    };

    const clearFilter = () => {
        setValue("");

        submitFilter();
    };

    const submit = () => {
        submitFilter(value);
    };

    return (
        <NmForm
            horizontal
            className={block()}
        >
            <div className={element("input")}>
                <NmDropdownV2
                    size="lg"
                    label="ФИО бригадира"
                    value={value}
                    options={foremanContractorsOptions}
                    onChange={onChange}
                />
            </div>
            <FilterButtonsV2
                onSearch={submit}
                onClear={clearFilter}
            />
        </NmForm>
    );
};

export default BrigadePaymentsFilter;