import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import {RECRUITMENT_FUNNEL_STATUS_FILTER} from "../../../../../../constants/recruitment";
import {
    bffRecruitmentVacancyTabFilterDataSelector,
} from "../../../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    getRecruitmentVacancyCandidates,
    updateRecruitmentVacancyCandidatesStore,
} from "../../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {bffRecruitmentVacancyCandidateIdsSelector} from "../../../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {
    fileStoreRecruitmentPageDataSelector,
    getRecruitmentCandidateSmallThumbnails,
} from "../../../../../../ducks/fileStoreRecruitment";

const useRecruitmentVacancyCandidateListFetch = (props) => {
    const {
        clientId,
        pageData,
        vacancyId,
        activeFunnelStatusFilter,
    } = props;

    const candidateIds = useSelector(bffRecruitmentVacancyCandidateIdsSelector);
    const fileStorePageData = useSelector(fileStoreRecruitmentPageDataSelector);
    const {filterData = {}} = useSelector(bffRecruitmentVacancyTabFilterDataSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        pageData,
        activeFunnelStatusFilter,
        filterData,
    ]);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyCandidatesStore({
                list: [],
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(candidateIds) || isEqual(fileStorePageData.ids, candidateIds)) {
            return;
        }

        dispatch(getRecruitmentCandidateSmallThumbnails({
            ids: candidateIds,
        }));
    }, [candidateIds]);

    const fetchList = (params = {}) => {
        const {
            onSuccess,
        } = params;
        const {
            pageNum,
        } = pageData;

        dispatch(getRecruitmentVacancyCandidates({
            clientId,
            vacancyId,
            funnelStatuses: activeFunnelStatusFilter !== RECRUITMENT_FUNNEL_STATUS_FILTER.ALL_CANDIDATES ? [activeFunnelStatusFilter] : undefined,
            pageNum: pageData.pageNum,
            pageSize: 25,
            isLoadMore: pageNum !== 1,
            ...filterData,
            onSuccess,
        }));
    };

    return {
        fetchList,
    };
};

export default useRecruitmentVacancyCandidateListFetch;