export const HIDING_PROJECT_OBJECT_TYPE = {
    CLIENT: "CLIENT",
    PROJECT: "PROJECT",
    OBJECT: "OBJECT",
};

export const CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE = {
    FULLY: "FULLY",
    PARTIALLY: "PARTIALLY",
    EMPTY: "EMPTY",
};