import React from "react";

import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import ExtLink from "../../../../../components/ExtLink";

import dateFormat, {convertUtcToLocal} from "../../../../../utils/dateFormat";
import {formatNumberWithComma} from "../../../../../utils/stringFormat";

import {LINK_CLIENT_REGISTRY_PAYMENTS_CARD, LINK_ORDER_CARD} from "../../../../../constants/links";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../../../constants/link-params";

const DepositClientListRegistryOrders = ({list, renderProjectInfo, renderObjectInfo}) => {
    const getRegistryLabel = (item) => {
        const {
            clientId,
            registryPaymentInfo: {
                registryId,
                ordinalNumber,
                name,
                registrySeqNum,
            },
            projectName,
            projectId,
            objectName,
            objectId,
        } = item;

        const registryLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD.replace(":clientId", clientId)
            .replace(":paymentNumberFilter?/", "")
            .replace(":archived", "false")
            .replace(":registryId", registryId);

        const seqNum = registrySeqNum ? ` (строка №${registrySeqNum})` : "";

        return [
            registryId && {
                label: "Реестр оплат",
                text: (
                    <ExtLink
                        to={registryLink}
                    >
                        {`№${ordinalNumber} ${name}${seqNum}`}
                    </ExtLink>
                ),
            },
            {label: "Проект", text: renderProjectInfo(projectName, projectId)},
            {label: "Объект", text: renderObjectInfo(objectName, projectId, objectId)},
        ];
    };

    const getRows = () => {
        return list.map((item, index) => {
            const {
                createDate,
                orderNum,
                clientId,
                orderId,
                amount,
                commission,
                orderName,
            } = item;

            const orderLink = LINK_ORDER_CARD
                .replace(":orderId", orderId)
                .replace(":clientId", clientId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

            return {
                key: index,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={`Заказ от ${dateFormat(convertUtcToLocal(createDate))}`}
                        primaryHeader={
                            <ExtLink to={orderLink}>
                                {`№${orderNum} - ${orderName}`}
                            </ExtLink>
                        }
                        labels={getRegistryLabel(item)}
                        cards={[
                            {
                                title: "Сумма заказа, ₽",
                                value: formatNumberWithComma(amount),
                                className: "col-16 col-md-4 col-xxl-2",
                                subValue: formatNumberWithComma(commission),
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <CheckboxList rows={getRows()} />
    );
};

export default DepositClientListRegistryOrders;