import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import Filter, {FILTER} from "../../../../components/ActualComponents/Filter";
import NmPage from "../../../../components/NmPage";
import SpecialtyOffer from "../../../../components/SpecialtyOffer";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import bem from "../../../../utils/bem";
import {getTotalPages} from "../../../../utils/mathHelper";

import {
    getSpecialityOffers,
    getSpecialityOffersDictionary,
} from "../../../../ducks/clientSpecialityOffer";

export const SpecialtyOfferContainer = () => {
    const offers = useSelector(state => state.clientSpecialityOffer.offers.pagedResult);
    const totalCount =  useSelector(state => state.clientSpecialityOffer.offers.totalResults);
    const dictionary = useSelector(state => state.clientSpecialityOffer.dictionary);
    const clients = dictionary.map(({clientId, name}) => ({key: clientId, value: clientId, text: name}));

    const dispatch = useDispatch();

    const [block ] = bem("specialty-offer-container");

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const totalPages = getTotalPages(totalCount, pageSize);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {valueFilter: ""},
        pageSize,
        setPagination,
    });

    useEffect(() => {
        dispatch(getSpecialityOffersDictionary());
    }, []);

    useEffect(() => {
        dispatch(getSpecialityOffers({
            clientIdFilter: filterData.clientIdFilter,
            pageNum,
            pageSize,
        }));
    }, [pageNum, pageSize, filterData]);

    const filtersBase = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "clientIdFilter",
                        label: "Заказчик",
                        options: clients,
                        placeholder: "Выберите заказчика",
                    },
                ],
            },
        ];
    }, [clients]);

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            typeFilter="vertical"
            className={block()}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            showHeaderBlock={false}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filtersBase}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            onPaginationChange={onPaginationChange}
        >
            <SpecialtyOffer
                isSearch={isSearch}
                list={offers}
                totalCount={totalCount}
                clients={clients}
            />
        </NmPage>
    );
};
