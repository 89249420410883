import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect/lib/index";

import {getContractorById} from "./contractor";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CONTRACTOR_SCAN_SEND} from "../constants/messages";

const controller = "/plutonium";
//*  TYPES  *//

const CONTRACTOR_SCAN_SEND_REQUEST = "CONTRACTOR_SCAN_SEND_REQUEST";
const CONTRACTOR_SCAN_SEND_SUCCESS = "CONTRACTOR_SCAN_SEND_SUCCESS";
const CONTRACTOR_SCAN_SEND_ERROR = "CONTRACTOR_SCAN_SEND_ERROR";

const CONTRACTOR_PLUTONIUM_UPDATE_FIELD_STORE = "CONTRACTOR_PLUTONIUM_UPDATE_FIELD_STORE";

//*  INITIAL STATE  *//

const initial = {
    error: null,
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_SCAN_SEND_REQUEST:
    case CONTRACTOR_SCAN_SEND_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case CONTRACTOR_SCAN_SEND_ERROR:
    case CONTRACTOR_PLUTONIUM_UPDATE_FIELD_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//


export function sendContractorScans(payload) {
    return {
        type: CONTRACTOR_SCAN_SEND_REQUEST,
        payload,
    };
}

export function updateContractorPlutoniumStore(payload) {
    return {
        type: CONTRACTOR_PLUTONIUM_UPDATE_FIELD_STORE,
        payload,
    };
}

export const contractorScanSelector = state => state.contractorScan;
export const contractorScanProgressSendSelector = createSelector(contractorScanSelector, ({progress}) => progress);

//*  SAGA  *//

export const sendContractorScansSaga = function* (action) {
    try {
        const {payload} = action;
        const {contractorId} = payload;

        const result = yield request.get(`${controller}/individual/sendArchiveIndividual/${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SCAN_SEND_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CONTRACTOR_SCAN_SEND.SUCCESS);

        yield put(getContractorById(contractorId));

        yield put({
            type: CONTRACTOR_SCAN_SEND_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SCAN_SEND_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_SCAN_SEND_REQUEST, sendContractorScansSaga),
    ]);
}
