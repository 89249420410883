import {
    handleFormString,
    handleNumber,
} from "../../../../utils/stringHelper";

export const mapFinancePatentPaymentsFilter = (filter) => {
    const {
        clientIdFilter,
        contractorNameFilter,
        patentRegistryNameFilter,
        statusFilter,
        bankStatusFilter,
        fromDateFilter,
        toDateFilter,
        fromSumFilter,
        toSumFilter,
        fromPaymentCommissionFilter,
        toPaymentCommissionFilter,
        paymentNumberFilter,
    } = filter;

    return {
        clientIdFilter: handleFormString(clientIdFilter),
        contractorNameFilter: handleFormString(contractorNameFilter),
        patentRegistryNameFilter: handleFormString(patentRegistryNameFilter),
        statusFilter: handleFormString(statusFilter),
        bankStatusFilter: handleFormString(bankStatusFilter),
        fromOperationDateTime: handleFormString(fromDateFilter),
        toOperationDateTime: handleFormString(toDateFilter),
        fromSumFilter: handleNumber(fromSumFilter),
        toSumFilter: handleNumber(toSumFilter),
        fromPaymentCommissionFilter: handleNumber(fromPaymentCommissionFilter),
        toPaymentCommissionFilter: handleNumber(toPaymentCommissionFilter),
        paymentNumberFilter: handleNumber(paymentNumberFilter),
    };
};