import {
    cancelKedoExportById,
    getKedoExportsPage,
} from "../../../../../kedo/exports/actionCreators";

const controller = "/adm/companies/company/kedo/exports";

export const getCompaniesKedoExportsPage = (payload) => {
    return getKedoExportsPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const cancelCompaniesKedoExportById = (payload) => {
    return cancelKedoExportById({
        url: `${controller}/cancelById`,
        ...payload,
    });
};