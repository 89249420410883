import React from "react";

import NmButton from "../../../../components/NmButton";
import {history} from "../../../../store/configureStore";

import bem from "../../../../utils/bem";

import "./style.sass";

interface IOrderCardFindPerformers {
    isShow: boolean,
    orderData: {name: string, orderNum: number},
    invitingContractorsLink: string,
    className?: string
}

const OrderCardFindPerformers = (props: IOrderCardFindPerformers) => {
    const {
        invitingContractorsLink,
        isShow,
        orderData,
        className = "",
    } = props;
    const [block] = bem("order-card-find-performers", className);

    const goSearchContractor = () => {
        const state = {
            extData: {
                ...orderData,
                specialityIdsFilter: [],
            },
        };

        history.push(invitingContractorsLink, state);
    };

    return isShow &&
        <NmButton
            size="lg"
            color="light-green"
            onClick={goSearchContractor}
            className={block()}
        >
            Найти исполнителей
        </NmButton>;
};

export default OrderCardFindPerformers;