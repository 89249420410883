import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/disputeFirebase";

const DISPUTE_ADD_FIREBASE_TOKEN_REQUEST = "DISPUTE_ADD_FIREBASE_TOKEN_REQUEST";
const DISPUTE_ADD_FIREBASE_TOKEN_SUCCESS = "DISPUTE_ADD_FIREBASE_TOKEN_SUCCESS";
const DISPUTE_ADD_FIREBASE_TOKEN_ERROR = "DISPUTE_ADD_FIREBASE_TOKEN_ERROR";

const DISPUTE_INIT_FIREBASE_REQUEST = "DISPUTE_INIT_FIREBASE_REQUEST";
const DISPUTE_INIT_FIREBASE_SUCCESS = "DISPUTE_INIT_FIREBASE_SUCCESS";
const DISPUTE_INIT_FIREBASE_ERROR = "DISPUTE_INIT_FIREBASE_ERROR";

const DISPUTE_NEED_CHAT_REFRESH = "DISPUTE_NEED_CHAT_REFRESH";


//*  INITIAL STATE  *//

const initial = {
    loading: false,
    token: "",
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case DISPUTE_INIT_FIREBASE_REQUEST:
    case DISPUTE_ADD_FIREBASE_TOKEN_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case DISPUTE_ADD_FIREBASE_TOKEN_SUCCESS:
        return {
            ...state,
            loading: false,
        };
    case DISPUTE_INIT_FIREBASE_SUCCESS:
        return {
            ...state,
            token: payload,
        };
    case DISPUTE_NEED_CHAT_REFRESH:
        return {
            ...state,
            needChatRefresh: true,
        };
    case DISPUTE_INIT_FIREBASE_ERROR:
    case DISPUTE_ADD_FIREBASE_TOKEN_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function addFireBaseToken(payload) {
    return {type: DISPUTE_ADD_FIREBASE_TOKEN_REQUEST, payload};
}

export function initFirebase() {
    return {type: DISPUTE_INIT_FIREBASE_REQUEST};
}

//*  SELECTORS  *//

export const disputeFirebaseSelector = state => state.disputeToken;

export const disputeFirebaseTokenSelector = createSelector(disputeFirebaseSelector, ({token}) => token);


//*  SAGA  *//
export const addFireBaseTokenSaga = function* (action) {
    try {
        const {payload: token} = action;
        const result = yield request.get(`${controller}/addFirebaseToken?firebaseToken=${token}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_ADD_FIREBASE_TOKEN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_ADD_FIREBASE_TOKEN_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISPUTE_ADD_FIREBASE_TOKEN_ERROR, payload: error});
    }
};

export const initFirebaseSaga = function* (action) {
    try {
        //
        const messaging = window.firebase.messaging();
        //messaging.deleteToken
        const result = yield messaging.getToken();
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_ADD_FIREBASE_TOKEN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({type: DISPUTE_INIT_FIREBASE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISPUTE_ADD_FIREBASE_TOKEN_ERROR, payload: error});
    }
};


export function* saga() {
    yield all([
        takeEvery(DISPUTE_ADD_FIREBASE_TOKEN_REQUEST, addFireBaseTokenSaga),
        takeEvery(DISPUTE_INIT_FIREBASE_REQUEST, initFirebaseSaga),
    ]);
}

