import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST,
} from "./actions";

export const editRecruitmentVacancyCandidatesComment = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancyCandidatesComment = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST,
        payload,
    };
};

export const addRecruitmentVacancyCandidatesComment = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyCandidatesComments = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST,
        payload,
    };
};

export const uploadFileRecruitmentVacancyCandidatesComment = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST,
        payload,
    };
};

export const deleteFileRecruitmentVacancyCandidatesComment = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancyCandidatesCommentsStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPDATE_STORE,
        payload,
    };
};