import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import DocumentManagementDictionariesRoute from "../document-management-dictionaries-route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT,
} from "../../../../constants/links";

import {
    edmPositionCommonCountSelector,
    edmPositionListPageDataSelector,
    getEdmPositionCommonCount,
} from "../../../../ducks/edmPositions";
import {
    edmSubdivisionsCommonCountSelector,
    edmSubdivisionsPageDataSelector,
    getEdmSubdivisionCommonCount,
} from "../../../../ducks/edmSubDivision";

import "./style.sass";

class DocumentManagementDictionariesCommon extends Component {
    constructor(props) {
        super(props);

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        this.getEdmSubdivisionCommonCount();
        this.getEdmPositionCommonCount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            edmSubdivisionsPageData,
            edmPositionPageData,
        } = this.props;
        const {
            edmSubdivisionsPageData: prevEdmSubdivisionsPageData,
            edmPositionPageData: prevEdmPositionPageData,
        } = prevProps;

        if(!isEqual(edmSubdivisionsPageData, prevEdmSubdivisionsPageData)){
            this.getEdmSubdivisionCommonCount();
        }

        if(!isEqual(edmPositionPageData, prevEdmPositionPageData)){
            this.getEdmPositionCommonCount();
        }
    }

    getEdmSubdivisionCommonCount(){
        const {getEdmSubdivisionCommonCount} = this.props;
        getEdmSubdivisionCommonCount({clientId: this.clientId});
    }

    getEdmPositionCommonCount(){
        const {getEdmPositionCommonCount} = this.props;
        getEdmPositionCommonCount({clientId: this.clientId});
    }

    get clientId() {
        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = this.props;
        return clientId;
    }

    get link() {
        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = this.props;

        const positionsLink = LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT.replace(":clientId", clientId);
        const structuralUnitsLink = LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT.replace(":clientId", clientId);

        return {
            positionsLink,
            structuralUnitsLink,
        };
    }

    getTabLinks() {
        const {positionsLink, structuralUnitsLink} = this.link;
        const {
            edmSubdivisionsCommonCount,
            edmPositionCommonCount,
        } = this.props;

        return [
            {
                active: positionsLink,
                link: positionsLink,
                name: "Должности",
                count: edmPositionCommonCount,
            },
            {
                active: structuralUnitsLink,
                link: structuralUnitsLink,
                name: "Структурные подразделения",
                count: edmSubdivisionsCommonCount,
            },
        ];
    }

    render() {
        return (
            <div className="edf-staff-card-common">
                <NmTitle size="xl">
                    Справочники
                </NmTitle>
                <Tabs
                    {...this.props}
                    className="my-4 my-md-5"
                    panes={this.getTabLinks()}
                />
                <DocumentManagementDictionariesRoute
                    {...this.props}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        edmSubdivisionsCommonCount: edmSubdivisionsCommonCountSelector(state),
        edmSubdivisionsPageData: edmSubdivisionsPageDataSelector(state),
        edmPositionCommonCount: edmPositionCommonCountSelector(state),
        edmPositionPageData: edmPositionListPageDataSelector(state),
    }),
    {
        getEdmSubdivisionCommonCount,
        getEdmPositionCommonCount,
    },
)(withTranslation()(DocumentManagementDictionariesCommon));
