import {useDispatch} from "react-redux";

import {
    addRecruitmentDirectoryStatus,
    deleteRecruitmentDirectoryStatus,
    updateRecruitmentDirectoryStatus,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

import {toastSuccess} from "../../../../../utils/toastHelper";

const useRecruitmentDirectoriesStatusesAction = ({clientId}) => {
    const dispatch = useDispatch();

    const onAdd = ({value, callBack = () => {}})=> {
        dispatch(addRecruitmentDirectoryStatus({
            clientId,
            name: value,
            onSuccess: () => {
                toastSuccess("Статус успешно добавлен");
                callBack();
            },
        }));
    };

    const onEdit = ({id, value, callBack = () => {}}) => {
        dispatch(updateRecruitmentDirectoryStatus({
            id,
            name: value,
            onSuccess: () => {
                toastSuccess("Статус успешно обновлен");
                callBack();
            },
        }));
    };

    const onDelete = ({id, callBack = () => {}}) => {
        dispatch(deleteRecruitmentDirectoryStatus({
            id,
            onSuccess: () => {
                toastSuccess("Статус успешно удален");
                callBack();
            },
        }));
    };

    return {
        onAdd,
        onEdit,
        onDelete,
    };
};

export default useRecruitmentDirectoriesStatusesAction;