import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {filterEmptyValues} from "../../../../utils/objectHelper";

import {getKedoStaffSignatureTransactions} from "../../../../ducks/kedo/staff/actionCreators";

export const useKedoStaffSignatureTransactionsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        kedoStaffId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);

    const fetchList = () => {
        const requestData = {
            ...filterEmptyValues(filterData),
            kedoStaffIds: [kedoStaffId],
        };

        dispatch(getKedoStaffSignatureTransactions({
            pageNum,
            pageSize,
            ...requestData,
        }));
    };

    return {
        fetchList,
    };
};