import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";
import {useSelectedList} from "../../../../../hooks/useSelectedList";
import {useTicketFilter} from "../../filter/hooks/useTicketFilter";

import {CURRENT_CLIENT_USER_ID, ls} from "../../../../../utils/localstorage";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {NM_ADMIN_INFO} from "../../../../../constants/contractorInfo";
import {CALL_TYPE_DICT, SUB_PAGE_CRM_TICKET_CARD, TICKET_SORT_TYPE} from "../../../../../constants/crm/ticket";
import {LINK_CRM_TICKET_CARD} from "../../../../../constants/links";
import {ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../../../constants/roles";

import {history} from "../../../../../store/configureStore";

import {getClientMemberList} from "../../../../../ducks/clientMember";
import {getClientUserRestrictionsList} from "../../../../../ducks/clientUserRestrictions";
import {
    addContractorTicket,
    checkTicketsBeforeClose,
    closeTickets,
    getTicketCallTypeDict,
    getTicketCategoryDict,
    getTicketCounts,
    getTicketRichPage,
    getTicketStatusDict,
    getTicketTypeDict,
    hasContractorTicketInWork,
    multipleChangeTicketManager,
    ticketTypeDictSelector,
    updateTicketStore,
} from "../../../../../ducks/crm/ticket";
import {updateFieldFilesStore} from "../../../../../ducks/fileStore";

import {FILTER_TYPE} from "../../../../../constants/ mailings/segmentationType";
import {SUB_PAGE_CRM_TICKET} from "../../../../../constants/link-params";

export const initTicketFilter = {
    callTypeFilter: FILTER_TYPE.ALL,
    categoryFilter: "",
    clientNameOrFioFilter: "",
    closeDateFrom: null,
    closeDateTo: null,
    createDateFrom: null,
    createDateTo: null,
    managerClientUserIdsFilter: [],
    numberFilter: "",
    onlyMyTickets: false,
    phoneFilter: "",
    typeFilter: "",
    addressFiasIdsFilter: [],
    timeZonesFilter: [],
};

export default function useTicketListAction({subpage, clientId, contractorId}) {
    const dispatch = useDispatch();
    const [confirmData, setConfirmData] = useState({});
    const [isOpenFilter, setOpenFilter] = useState(false);
    const [isOpenTransferTicketModal, setOpenTransferTicketModal] = useState(false);
    const ticketTypeDict = useSelector(ticketTypeDictSelector);

    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

    const isChatsSubPage = [
        SUB_PAGE_CRM_TICKET.CHATS.LINK,
    ].includes(subpage);

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({initFilter: initTicketFilter});

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useTicketFilter({
        subpage,
    });

    useEffect(() => {
        dispatch(getTicketCallTypeDict());
        dispatch(getTicketCategoryDict());
        dispatch(getTicketTypeDict());
        dispatch(getTicketStatusDict());
        fetchClientMembers();
        dispatch(getClientUserRestrictionsList({
            restrictions: [CLIENT_USER_RESTRICTIONS_VARIABLE.closeRequestCRM],
        }));
    }, []);

    useEffect(() => {
        clearSelectedRows();
        setPagination({pageSize, pageNum: 1});

        if (contractorId && subpage === SUB_PAGE_CRM_TICKET.CHATS.LINK) {
            getHasContractorTicketInWork();
        }
    }, [subpage]);

    useEffect(() => {
        clearSelectedRows();
    }, [pageNum, pageSize]);

    useEffect(() => {
        if (subpage !== SUB_PAGE_CRM_TICKET.SETTINGS.LINK) {
            fetchList();
        }

        return () => {
            dispatch(updateTicketStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));

            dispatch(updateFieldFilesStore({
                avatarBase64ImagesList: {},
            }));
        };
    }, [pageNum, pageSize, filterDto, subpage]);

    const _closeTickets = () => {
        const ticketsSelected = selectedList.filter(({isSelected}) => isSelected);

        dispatch(checkTicketsBeforeClose({
            ticketIds: ticketsSelected.map(({ticketId}) => ticketId),

            getResult: ({errorTickets, successTickets}) => {
                confirm(successTickets, errorTickets, ticketsSelected);
            },
        }));
    };

    const addTicket = () => {
        dispatch(addContractorTicket({
            contractorId,
            managerId: currentClientUserId,
            getResult: ({guid}) => {
                if (guid) {
                    history.push(
                        LINK_CRM_TICKET_CARD
                            .replace(":subpage", SUB_PAGE_CRM_TICKET.IN_WORK.LINK)
                            .replace(":_subpage", SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK)
                            .replace(":ticketId", guid),
                    );

                    return;
                }

                getHasContractorTicketInWork();
                fetchList();
            },
        }));
    };

    const getHasContractorTicketInWork = () => {
        dispatch(hasContractorTicketInWork({contractorId}));
    };

    const confirm = (successTickets, errorTickets, ticketsSelected) => {
        const isExistSuccessTickets = !!successTickets.length;
        const confirm = () => {
            if (!isExistSuccessTickets) {
                cancelConfirm();
                return;
            }
            dispatch(closeTickets({
                ticketIds: successTickets.map(({ticketId}) => ticketId),
                getResult: (errorMessage) => {
                    cancelConfirm();
                    clearSelectedRows();
                    if (!errorMessage) {
                        fetchList();
                    }
                },
            }));
        };

        const _successTickets = successTickets.map(({ticketId, orderNumber}) => {
            return {
                ticketId,
                orderNumber,
                publishPossible: true,
            };
        });

        const _errorTickets = errorTickets.map(({ticketId, orderNumber}) => {
            return {
                ticketId,
                orderNumber,
                publishPossible: false,
            };
        });

        const concatenatedList = [..._successTickets, ..._errorTickets]
            .filter(item => ticketsSelected.find(_item => _item.ticketId === item.ticketId))
            .map(({
                orderNumber,
                ticketId,
                ...item
            }) => {
                const {
                    baseModel: {
                        type,
                    },
                } = ticketsSelected.find(_item => _item.ticketId === ticketId);

                return {
                    ...item,
                    ticketId,
                    name: `Обращение №${orderNumber} - ${type ? ticketTypeDict[type] : "Не определено"}`,
                };
            });

        setConfirmData({
            content: concatenatedList,
            confirm,
            publicationCount: _successTickets.length,
            impossibleCount: errorTickets.length,
        });
    };

    const fetchClientMembers = () => {
        dispatch(getClientMemberList({
            clientId: NM_ADMIN_INFO.ID,
            archiveFilter: false,
            roleListFilter: [ADMIN, NM_MANAGER, NM_OPERATOR],
            pageNum: 1,
            pageSize: 1000,
        }));
    };

    const cancelConfirm = () => {
        setConfirmData({});
    };

    const getStatusFilter = () => {
        return Object.values(SUB_PAGE_CRM_TICKET).find(item => item.LINK === subpage)?.STATUS;
    };

    const getCallTypesFilter = () => {
        if (isChatsSubPage) {
            return [CALL_TYPE_DICT.CHAT.VALUE];
        }

        if (
            [
                SUB_PAGE_CRM_TICKET.KEDO.LINK,
            ].includes(subpage)
        ) {
            return [CALL_TYPE_DICT.KEDO_CHAT.VALUE];
        }

        if (!filterDto.callTypesFilter?.length && ![SUB_PAGE_CRM_TICKET.CLOSED.LINK].includes(subpage)) {
            return [
                CALL_TYPE_DICT.INCOMING.VALUE,
                CALL_TYPE_DICT.OUTGOING.VALUE,
                CALL_TYPE_DICT.SYSTEM.VALUE,
                CALL_TYPE_DICT.NM_STAFF.VALUE,
            ];
        }

        return filterDto.callTypesFilter;
    };

    const fetchList = () => {
        const reqData = {
            ...filterDto,
            contractorIdFilter: contractorId ? contractorId : undefined,
            clientIdsFilter: clientId ? [clientId] : undefined,
            callTypesFilter: getCallTypesFilter(),
        };

        dispatch(getTicketRichPage({
            isFetchAvatars: isChatsSubPage,
            pageSize,
            pageNum,
            statusesFilter: [
                SUB_PAGE_CRM_TICKET.CHATS.LINK,
                SUB_PAGE_CRM_TICKET.KEDO.LINK,
            ].includes(subpage) ? [
                    SUB_PAGE_CRM_TICKET.IN_WORK.STATUS,
                    SUB_PAGE_CRM_TICKET.RECALL.STATUS,
                    SUB_PAGE_CRM_TICKET.MISSED.STATUS,
                ] : [
                    getStatusFilter(),
                ],
            orderType: isChatsSubPage ? TICKET_SORT_TYPE.MISSED_RECALL_IN_WORK : undefined,
            ...reqData,
            getResult: (errorMessage) => {
                if (errorMessage) {
                    console.log("error getTicketRichPage: ", errorMessage);
                }
            },
        }));

        dispatch(getTicketCounts({
            ...reqData,
            callTypesFilter: filterDto.callTypesFilter?.length ? filterDto.callTypesFilter : undefined,
        }));
    };

    const changeOperator = (form) => {
        const ticketIds = selectedList.filter(({isSelected}) => isSelected).map(({ticketId}) => ticketId);

        dispatch(multipleChangeTicketManager({
            ticketIds,
            ...form,
            managerClientId: NM_ADMIN_INFO.ID,
            onSuccess: () => {
                setOpenTransferTicketModal(false);
                clearSelectedRows();
                fetchList();
            },
        }));
    };

    return {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
        closeTickets: _closeTickets,
        getStatusFilter,
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
        onChangeFilter,
        filter,
        setFilter,
        setFilterDto,
        isSearch,
        setIsSearch,
        confirmData,
        cancelConfirm,
        isOpenFilter,
        setOpenFilter,
        changeOperator,
        isOpenTransferTicketModal,
        setOpenTransferTicketModal,
        addTicket,
    };
}