import React from "react";

import DropzoneV2 from "../../../../components/ActualComponents/DropzoneV2";
import NmLabel from "../../../../components/ActualComponents/NmLabel";
import Text from "../../../../components/ActualComponents/Text";
import NmBadge from "../../../../components/NmBadge";

const OnboardingEditFormLogo = (props) => {
    const {
        values,
        handleChange,
        touched,
        errors,
    } = props;

    return values.customDesignEnabled ?
        <DropzoneV2
            disabled={!values.customDesignEnabled}
            required={values.customDesignEnabled}
            label="Логотип"
            isVisibleLabel
            files={values.files}
            onChange={(files) => handleChange(null, {name: "files", value: files})}
            multiple={false}
            maxSize={10}
            accept=".png, .bmp, .jpeg, .gif, .jpg"
            format="*.png, *.bmp, *.jpeg, *.gif, *.jpg"
            error={touched?.files && errors?.files}
        /> :
        <div>
            <NmLabel
                label="Логотип"
                required={true}
                disabled={true}
            />
            <div className="d-flex align-items-center">
                <Text
                    disabled={true}
                    level="3"
                >
                    Файл:
                </Text>
                <NmBadge
                    disabled={true}
                    mod="gray"
                    className="ms-2"
                    text="Логотип Наймикс"
                />
            </div>
        </div>;
};

export default OnboardingEditFormLogo;