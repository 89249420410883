import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useGetPrevValue} from "../../hooks/useGetPrevValue";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {citizenshipsDict} from "../../constants/citizenships";
import {RUSSIA_PHONE_CODE_ID} from "../../constants/phone";
import {ADMIN} from "../../constants/roles";

import {bffCommonDictsPhoneCodeOptionsSelector} from "../../ducks/bff/common/dicts/selectors";

const SelectPhoneCodeForm = (props) => {
    const {
        values,
        setFieldValue,
        errors,
        phoneFieldName = "phone",
    } = props;

    const phoneCodeOptions = useSelector(bffCommonDictsPhoneCodeOptionsSelector);

    const prevPhoneCodeId = useGetPrevValue(values.phoneCodeId);
    const _phoneCodeOptions = values.locatedOutsideRussia ?
        phoneCodeOptions.filter(item => item.domain !== citizenshipsDict.RU.value) :
        [phoneCodeOptions.find(item => item.domain === citizenshipsDict.RU.value)];

    const role = ls(USER_ROLE);

    function handleChange(e, {checked, name: field, value}) {
        if (field === "locatedOutsideRussia") {
            setFieldValue("phoneCodeId", !checked ? RUSSIA_PHONE_CODE_ID : null);
            setFieldValue("phone", "");
        }

        setFieldValue(field, typeof checked === "boolean" ? checked : value);
    }

    useEffect(() => {
        if (values.locatedOutsideRussia && Boolean(prevPhoneCodeId)) {
            handleChange(null, {name: phoneFieldName, value: ""});
        }
    }, [values.phoneCodeId]);

    return (
        <div className="row align-items-center">
            <div className="col-16 col-md-8">
                <NmCheckboxV2
                    disabled={![ADMIN].includes(role)}
                    label="Исполнитель за пределами России"
                    checked={values.locatedOutsideRussia}
                    onChange={handleChange}
                    name="locatedOutsideRussia"
                />
            </div>
            <div className="col-16 col-md-8 mt-2 mt-md-0">
                <NmDropdownV2
                    search
                    required={values.locatedOutsideRussia}
                    disabled={!values.locatedOutsideRussia}
                    label="Страна"
                    name="phoneCodeId"
                    options={_phoneCodeOptions}
                    value={values.phoneCodeId}
                    onChange={handleChange}
                    error={errors.phoneCodeId}
                />
            </div>
        </div>
    );
};

export default SelectPhoneCodeForm;