import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    bankCheckCancelToken,
    contractorsPaymentPossibilityListSelector,
    getContractorsPaymentPossibility,
    refreshContractorsPaymentPossibility,
    updateFieldsContractorPaymentPossibilityState,
} from "../../../../ducks/contractorBankCheck";

const TIME_INTERVAL = 2000;

export const usePaymentPossibleCheck = ({startTimer, clientId, contractorId}) => {
    const contractorsPossibility = useSelector(contractorsPaymentPossibilityListSelector);
    const dispatch = useDispatch();
    const timer = useRef(null);

    useEffect(() => {
        refreshPaymentPossibility();

        return () => {
            dispatch(updateFieldsContractorPaymentPossibilityState({
                contractorsPossibility: [],
            }));

            bankCheckCancelToken.cancel();
            clearTimeout(timer.current);
        };
    }, []);

    const refreshPaymentPossibility = () => {
        dispatch(refreshContractorsPaymentPossibility({
            contractorIds: [contractorId],
            useFnsThreshold: false,
            handleResponse: ({errorMessage}) => {
                if (errorMessage) {
                    return;
                }

                getPaymentPossibility();
            },
        }));
    };

    const getPaymentPossibility = () => {
        dispatch(getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(timer.current);
            },
            handleResponse: (result) => {
                handleResponsePaymentPossibility(result);
            },
            clientId,
            contractorIds: [contractorId],
        }));
    };

    const handleResponsePaymentPossibility = (result) => {
        const {
            items,
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            const [{secToNextRefresh}] = items;

            startTimer(secToNextRefresh);

            return;
        }

        clearTimeout(timer.current);

        timer.current = setTimeout(getPaymentPossibility, TIME_INTERVAL);
    };

    return {
        refreshPaymentPossibility,
        contractorsPossibility,
    };
};