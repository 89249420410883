import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEqual} from "lodash";

import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {useGetPrevValue} from "../../../../hooks/useGetPrevValue";

import {
    convertUtcToLocal,
    formatDateWithoutTime,
} from "../../../../utils/dateFormat";
import {handleDateFieldFilter} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {requiredMessage} from "../../../../constants/validationRules";

import {
    clientCivilContractSelector,
    getClientCivilContract,
    updateClientCivilContract,
} from "../../../../ducks/client";

const validationRule = {
    civilContractNumber: {
        required: requiredMessage,
    },
    civilContractDate: {
        required: requiredMessage,
    },
    civilAccountBank: {
        required: requiredMessage,
        length: {
            value: 20,
            message: "Номер счёта пополнения должен состоять из 20 цифр",
        },
    },
};

const ClientCivilContract = (props) => {
    const {
        clientId,
        isEditable,
    } = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const initialValues = useSelector(clientCivilContractSelector);

    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState({
        civilContractNumber: "",
        civilAccountBank: "",
        civilContractDate: null,
    });
    const [error, setError] = useState({});

    const _oldValues = useGetPrevValue(initialValues);

    useEffect(() => {
        dispatch(getClientCivilContract({clientId}));
    }, []);

    useEffect(() => {
        if (!isEqual(_oldValues, initialValues)) {
            setData(initialValues);
        }
    }, [initialValues]);

    const isValid = () => {
        const error = validate(data, validationRule, "");

        setError({...error});

        return Object.values(error).length === 0;
    };

    const toggleCard = () => {
        if (isEdit && !isValid()) {
            return;
        }

        if (isEdit) {
            handleSubmit();
        }

        setIsEdit(prevState => !prevState);
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setError({});
        setData(initialValues);
    };

    const handleChange = (e, { value, name }) => {
        setData(state => ({
            ...state,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        if (isEqual(data, initialValues)) {
            return;
        }

        dispatch(updateClientCivilContract({
            ...data,
            civilContractDate: handleDateFieldFilter(data.civilContractDate),
        }));
    };

    const renderCardReadOnly = () => {
        return (
            <>
                <LabelTextApp
                    label="Номер договора для выплат по НДФЛ"
                    className="client-card-block__label mt-15"
                    text={
                        data.civilContractNumber ||
                        t("is-not-specified.content")
                    }
                />
                <LabelTextApp
                    label="Дата договора для выплат по НДФЛ"
                    className="client-card-block__label"
                    text={
                        formatDateWithoutTime(convertUtcToLocal(data.civilContractDate)) ||
                        t("is-not-specified.content")
                    }
                />
                <LabelTextApp
                    label="Счёт пополнения"
                    className="client-card-block__label"
                    text={
                        data.civilAccountBank ||
                        t("is-not-specified.content")
                    }
                />
            </>
        );
    };

    const renderCardEditMode = () => {
        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label="Номер договора для выплат по НДФЛ"
                            placeholder="Номер договора для выплат по НДФЛ"
                            required
                            maxLength={255}
                            name="civilContractNumber"
                            value={data.civilContractNumber || ""}
                            onChange={handleChange}
                            error={error.civilContractNumber}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmDatePicker
                            size="lg"
                            label="Дата договора для выплат по НДФЛ"
                            placeholder="Дата договора для выплат по НДФЛ"
                            required
                            dateFormatMask="99.99.9999"
                            name="civilContractDate"
                            selected={data.civilContractDate || null}
                            onChange={handleChange}
                            error={error.civilContractDate}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label="Счёт пополнения"
                            placeholder="Счёт пополнения"
                            required
                            maxLength={20}
                            maskChar={null}
                            mask="99999999999999999999"
                            name="civilAccountBank"
                            value={data.civilAccountBank || ""}
                            onChange={handleChange}
                            error={error.civilAccountBank}
                        />
                    </div>
                </div>
            </NmForm>
        );
    };

    return (
        <CardApp
            title="Договор для выплат с НДФЛ"
            className="client-card-block"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
        >
            {
                !isEdit ? renderCardReadOnly() : renderCardEditMode()
            }
        </CardApp>
    );
};

export default ClientCivilContract;