export const OPERATOR_REPORT_STATUSES = {
    DRAFT: "DRAFT",
    SIGNING_CUSTOMER: "SIGNING_CUSTOMER",
    SIGNED: "SIGNED",
    CANCELLED: "CANCELLED",
    PERIOD_ADJUSTMENT_REQUIRED: "PERIOD_ADJUSTMENT_REQUIRED",
};

export const OPERATOR_REPORT_STATUSES_COLOR_TRANSCRIPT = {
    DRAFT: "gray",
    SIGNING_CUSTOMER: "light-green",
    SIGNED: "green",
    CANCELLED: "red",
};