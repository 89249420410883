import React from "react";
import {useSelector} from "react-redux";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {history} from "../../../store/configureStore";
import ClientGroupFilter from "./components/filter";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import {useClientGroupActions} from "./hooks/useClientGroupActions";
import {useClientGroupFetchList} from "./hooks/useClientGroupFetchList";
import {useClientGroupFilter} from "./hooks/useClientGroupFilter";

import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";

import {LINK_SETTINGS_CLIENT_GROUP_CARD} from "../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_MANAGER} from "../../../constants/roles";

import {
    clientGroupsListSelector,
    clientGroupsTotalCountSelector,
    clientGroupsTotalPagesSelector,
} from "../../../ducks/clientGroups";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";

import "./style.sass";

const initFilter = {
    clientInnFilter: "",
    clientNameFilter: "",
    contractorFioFilter: "",
    contractorInnFilter: "",
    contractorPhoneFilter: "",
    fromCreateDateFilter: null,
    groupNameFilter: "",
    toCreateDateFilter: null,
    clientUserFioFilter: "",
    clientUserEmailFilter: "",
    clientUserPhoneFilter: "",
};

function SettingsClientGroup() {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");


    const {
        options,
        onClickActionItem,
        dataModal,
        onClickAddNewGroup,
        onCloseModal,
    } = useClientGroupActions();

    const {
        setFilter,
        filter,
        isSearch,
        setIsSearch,
        onChangeFilter,
    } = useFilter({initFilter});

    const {filterDto, setFilterDto} = useClientGroupFilter();

    const {
        loading,
    } = useClientGroupFetchList({filter: filterDto, pageSize, pageNum});

    const list = useSelector(clientGroupsListSelector);
    const totalPages = useSelector(clientGroupsTotalPagesSelector);
    const totalCount = useSelector(clientGroupsTotalCountSelector);
    const role = ls(USER_ROLE);
    const {canManageAllClients} = useSelector(clientCurrentMemberSelector);

    const submitFilter = (filter, search = true) => {
        setFilterDto(filter);
        setIsSearch(search);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    function renderCard(label, value) {
        return (
            <div className="settings-client-group-list-row-content-cards-item">
                <div className="settings-client-group-list-row-content-cards-item__label">
                    {label}
                </div>
                <div className="settings-client-group-list-row-content-cards-item__value">
                    {value}
                </div>
            </div>
        );
    }

    function renderRows() {
        return list.map(item => {
            const {
                countClients,
                countContractors,
                countClientUsers,
                description,
                groupName,
                groupId,
                createDate,
            } = item;
            return (
                <div
                    key={groupId}
                    className="settings-client-group-list-row"
                >
                    <div className="settings-client-group-list-row-content">
                        <div className="settings-client-group-list-row-content__date">
                            Группа компаний от 
                            {" "}
                            {formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}
                        </div>
                        <div
                            className="settings-client-group-list-row-content__name"
                            onClick={() => history.push(LINK_SETTINGS_CLIENT_GROUP_CARD
                                .replace(":groupId", groupId)
                                .replace(":tab", "clients"),
                            )}
                        >
                            {groupName}
                        </div>
                        {description && <div className="settings-client-group-list-row-content__description">
                            {description}
                        </div>}
                        <div className="settings-client-group-list-row-content-cards">
                            {renderCard("Исполнителей", countContractors)}
                            {renderCard("Заказчиков", countClients)}
                            {renderCard("Сотрудников", countClientUsers)}
                        </div>
                    </div>
                    <div className="settings-client-group-list-row-actions">
                        {![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) && <ContextMenu
                            className="settings-client-group-list-row-actions__item"
                            onClickItem={(option) => {
                                onClickActionItem(option, item);
                            }}
                            options={options}
                        />}
                    </div>
                </div>
            );
        });
    }

    const renderModalWindow = () => {
        const {
            isShow,
            title,
            submit,
            formId,
            content,
            submitContent,
            isConfirm,
        } = dataModal;

        if (!isShow) {
            return null;
        }

        if (isConfirm) {
            return (
                <NmConfirmV2
                    content={content}
                    confirmButton={submitContent || "Подтвердить"}
                    cancelButton="Отменить"
                    onCancel={onCloseModal}
                    onConfirm={!formId && submit}
                    formId={formId}
                />
            );
        }

        return (
            <NmModal
                onClose={onCloseModal}
                header={
                    title && <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        formId={formId}
                        submitBtnContent={submitContent || "Подтвердить"}
                        submit={!formId && submit}
                        onClose={onCloseModal}
                        cancelBtnContent="Отменить"
                    />
                }
            >
                {content}
            </NmModal>
        );
    };

    function getAccessAdd() {
        if (role === NM_MANAGER) {
            return canManageAllClients;
        }
        return ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role);
    }

    return (
        <NmPage
            noPadding
            header={<NmPageHeader
                text="Группы компаний"
                totalCount={totalCount}
            />}
            controls={
                <>
                    {getAccessAdd() && <NmButton
                        icon={<AddIcon />}
                        size="xl"
                        onClick={onClickAddNewGroup}
                    >
                        Добавить группу компаний
                    </NmButton>}
                </>
            }
            totalCount={totalCount}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={loading}
            filtersBase={<ClientGroupFilter
                initForm={initFilter}
                submitFilter={submitFilter}
                filter={filter}
                setFilter={setFilter}
                onChangeFilter={onChangeFilter}
            />}
            typeFilter="vertical"
        >
            {renderModalWindow()}
            {
                list.length ?
                    renderRows() :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={loading}
                    />
            }
        </NmPage>
    );
}

export default SettingsClientGroup;