import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";

import {regFullName} from "../../../../constants/validationRules";

import PropTypes from "prop-types";

import "./style.sass";

class InsuranceHistoryFilter extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        sendFilter: PropTypes.func.isRequired,
        clearFilter: PropTypes.func.isRequired,
        filter: PropTypes.object.isRequired,
    };

    static defaultProps = {
        filter: {},
    };

    componentDidMount() {
        document.addEventListener("keypress", this.onKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener("keypress", this.onKeyPress);
    }

    onKeyPress = event => {
        const {sendFilter} = this.props;

        if (event.keyCode === 13) {
            sendFilter();
        }
    };


    handleOnChangeDatepicker = (value, name) => {
        const {onChange} = this.props;

        onChange(null, {value, name});
    };

    render() {
        const {
            filter: {
                fromDate,
                toDate,
                orderNum,
                contractorFullName,
                orderNameFilter,
            },
            onChange,
            sendFilter,
            clearFilter,
            t,
        } = this.props;

        return (
            <NmForm
                horizontal
                className="insurance-history-filter"
            >
                <NmDateRangePickerV2
                    size="lg"
                    className="insurance-history-filter__period"
                    label={t("client-insurance.filter-period")}
                    onChange={onChange}
                    startFieldName="fromDate"
                    endFieldName="toDate"
                    maxDate={{
                        start: toDate || new Date(),
                    }}
                    value={{
                        fromDate,
                        toDate,
                    }}
                />
                <NmInputV2
                    size="lg"
                    mask="999999999999"
                    maskChar={null}
                    className="insurance-history-filter__input"
                    label={t("client-insurance.filter-order-num")}
                    value={orderNum}
                    name="orderNum"
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    label={t("client-insurance.filter-order-name")}
                    value={orderNameFilter}
                    pattern={regFullName}
                    name="orderNameFilter"
                    className="insurance-history-filter__input"
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    label={t("client-insurance.filter-contractor-full-name")}
                    value={contractorFullName}
                    pattern={regFullName}
                    className="insurance-history-filter__input"
                    name="contractorFullName"
                    onChange={onChange}
                />
                <FilterButtonsV2
                    onSearch={sendFilter}
                    onClear={clearFilter}
                />
            </NmForm>
        );
    }
}


export default connect(
    state => ({}),
    {},
)(withTranslation()(InsuranceHistoryFilter));
