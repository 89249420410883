import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import CardApp from "../../../../../components/CardApp";
import NmDropzoneApiImages from "../../../../../components/ImagesBlock";

import {
    addOtherContractorFile,
    contractorOtherFileListSelector,
    contractorOtherFilesDocMapSelector,
    contractorOtherFilesProgressSelector,
    deleteOtherContractorFile,
    getOtherContractorFile,
} from "../../../../../ducks/contractorOtherFiles";

const ContractorScanListOtherDocuments = (props) => {
    const {
        contractorId,
        isEditable,
        className,
    } = props;
    const [isEdit, setEdit] = useState(false);
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();

    const fileNameList = useSelector(contractorOtherFileListSelector);
    const docMap = useSelector(contractorOtherFilesDocMapSelector);
    const progress = useSelector(contractorOtherFilesProgressSelector);

    const onUploadFile = (files) => {
        const [file] = files;

        const formData = new FormData();

        formData.append("file", file);

        dispatch(addOtherContractorFile({
            contractorId,
            file: formData,
        }));

        if (!isEmpty(files)) {
            setFiles([]);
        }
    };

    const onDownloadFile = ({fileName}) => {
        dispatch(getOtherContractorFile({
            contractorOtherName: fileName,
            contractorId,
            thumbnail: false,
            isNeedDownload: true,
        }));
    };

    const onGetFile = ({fileName, thumbnail = true}) => {
        dispatch(getOtherContractorFile({
            contractorOtherName: fileName,
            contractorId,
            thumbnail,
        }));
    };

    const onDeleteFile = ({fileName}) => {
        dispatch(deleteOtherContractorFile({
            fileName,
            contractorId,
        }));
    };

    const onChange = (files) => {
        setFiles(files);
    };

    const onToggleCard = () => {
        if (isEdit && !isEmpty(files)) {
            onUploadFile(files);
        }

        setEdit(!isEdit);
    };

    const onClickCancelIcon = () => {
        setEdit(false);

        if (!isEmpty(files)) {
            setFiles([]);
        }
    };

    return (
        <CardApp
            className={className}
            title="Другие документы"
            onClickIcon={onToggleCard}
            onClickCancelIcon={onClickCancelIcon}
            isEditable={isEditable}
            isEdit={isEdit}
        >
            <NmDropzoneApiImages
                progress={progress}
                isShowDropzone={isEditable && isEdit}
                isShowDeleteButton={isEditable && isEdit}
                fileNameList={fileNameList}
                isClearFilesTrigger={!isEdit}
                docMap={docMap}
                onChange={onChange}
                onGetFile={onGetFile}
                onUploadFile={onUploadFile}
                onDownloadFile={onDownloadFile}
                onDeleteFile={onDeleteFile}
                isFetchFullImage
            />
        </CardApp>
    );
};

export default ContractorScanListOtherDocuments;