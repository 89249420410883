import React, {useEffect, useState} from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterContractor from "../../../../components/ActualComponents/FilterContractor";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../components/ActualComponents/NmRangeInput";

import {getDateObject} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {validateUUID} from "../../../../utils/validate";

import {ORDER_WORK_REPORT_TYPE, SOURCE_TYPE_OPTIONS} from "../../../../constants/finance";
import {STATUS_NAIMIX_FILTER, STATUS_NAIMIX_INDIVIDUAL_FILTER} from "../../../../constants/status";
import {regFullName} from "../../../../constants/validationRules";

import "./style.sass";

const patternNumber = /^\d*(,\d{0,2})?$/;

const FinancePaymentListFilter = (props) => {
    const {
        onChange = () => {
        },
        clearFilter = () => {
        },
        sendFilter = () => {
        },
        dataFilter = {},
        bankStatusOptions = [],
        orderWorkReportType,
    } = props;

    const {
        clientId,
        reportStatusFilter,
        statusOfTheTransferFilter,
        fromDateFilter,
        toDateFilter,
        paymentNumberFilter,
        registryNumberFilter,
        invoiceIdFilter,
        orderNumFilter,
        fromSumMoneyFilter,
        toSumMoneyFilter,
        orderNameFilter,
        fromClientCommissionAmountFilter,
        toClientCommissionAmountFilter,
        fromPlatformAmountFilter,
        toPlatformAmountFilter,
        sourceTypeFilter,
        contractorIdFilter,
    } = dataFilter;

    const [errors, setErrors] = useState({});
    const [isNeedClearShowedText, setIsNeedClearShowedText] = useState(false);

    const reportStatus = reportStatusFilter && reportStatusFilter.length ? reportStatusFilter[0] : null;
    const statusOfTheTransfer = statusOfTheTransferFilter && statusOfTheTransferFilter.length ? statusOfTheTransferFilter[0] : null;
    const _fromDateFilter = isNullOrWhitespace(fromDateFilter) ? null : new Date(fromDateFilter);
    const _toDateFilter = isNullOrWhitespace(toDateFilter) ? null : new Date(toDateFilter);
    const _sourceTypeFilter = isNullOrWhitespace(sourceTypeFilter) || sourceTypeFilter === "ALL" ? "ALL" : sourceTypeFilter;

    const isIndividualEntrepreneur = orderWorkReportType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    const naimixStatusDict = isIndividualEntrepreneur ?
        STATUS_NAIMIX_INDIVIDUAL_FILTER :
        STATUS_NAIMIX_FILTER;
    const paymentStatusOptions = dictionaryToOptions(naimixStatusDict);

    useEffect(() => {
        if (isNeedClearShowedText) {
            setIsNeedClearShowedText(false);
        }
    }, [isNeedClearShowedText]);

    const handleOnChangeSelect = (e, {value, name}) => {
        const _value = value === "" ? undefined : [value];
        onChange(e, {value: _value, name});
    };

    const onKeyPress = ({key}) => {
        if(key === "Enter"){
            onSearch();
        }
    };

    const onSearch = () => {
        if (invoiceIdFilter && !validateUUID(invoiceIdFilter)) {
            setErrors({
                invoiceIdFilter: "Некорректный UUID",
            });

            return;
        }

        setErrors({});
        sendFilter();
    };

    const onClear = () => {
        setErrors({});
        setIsNeedClearShowedText(true);
        clearFilter();
    };

    return (
        <>
            <NmForm
                horizontal
                onKeyPress={onKeyPress}
                className="finance-payment-list-filter"
            >
                <FilterCustomer
                    size="lg"
                    label="Заказчик"
                    isClearable
                    onChange={onChange}
                    search
                    name="clientId"
                    value={clientId}
                    isNeedClearShowedText={isNeedClearShowedText}
                />
                <NmDropdownV2
                    size="lg"
                    label="Тип оплаты"
                    selectOnBlur={false}
                    onChange={onChange}
                    selection
                    name="sourceTypeFilter"
                    value={_sourceTypeFilter}
                    options={SOURCE_TYPE_OPTIONS}
                />
                <NmInputV2
                    size="lg"
                    label="Номер оплаты"
                    maskChar={null}
                    mask="999999999999"
                    name="paymentNumberFilter"
                    value={paymentNumberFilter}
                    onChange={onChange}
                />
                {
                    isIndividualEntrepreneur &&
                    <NmInputV2
                        size="lg"
                        label="Номер реестра оплат"
                        placeholder="Введите номер реестра оплат"
                        maskChar={null}
                        mask="999999999999"
                        name="registryNumberFilter"
                        value={registryNumberFilter}
                        onChange={onChange}
                    />
                }
                <NmInputV2
                    size="lg"
                    label="ГУИД транзакции"
                    name="invoiceIdFilter"
                    value={invoiceIdFilter}
                    onChange={onChange}
                    error={errors.invoiceIdFilter}
                />
                <FilterContractor
                    size="lg"
                    onChange={onChange}
                    name="contractorIdFilter"
                    value={contractorIdFilter}
                />
                <NmInputV2
                    size="lg"
                    mask="999999999999"
                    maskChar={null}
                    label="Номер заказа"
                    value={orderNumFilter}
                    name="orderNumFilter"
                    onChange={onChange}
                />
                <NmInputV2
                    size="lg"
                    label="Название заказа"
                    value={orderNameFilter}
                    pattern={regFullName}
                    name="orderNameFilter"
                    onChange={onChange}
                />
                <NmDropdownV2
                    size="lg"
                    label="Статус Наймикс"
                    name="reportStatusFilter"
                    clearable
                    compact
                    onChange={handleOnChangeSelect}
                    value={reportStatus}
                    options={paymentStatusOptions}
                />
                <NmDropdownV2
                    size="lg"
                    label="Банковский статус"
                    clearable
                    compact
                    name="statusOfTheTransferFilter"
                    onChange={handleOnChangeSelect}
                    options={bankStatusOptions}
                    value={statusOfTheTransfer}
                />
                <NmDateRangePickerV2
                    label="Дата операции"
                    onChange={onChange}
                    isClearable
                    size="lg"
                    dateFormat="dd.MM.yy"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{
                        fromDateFilter: getDateObject(_fromDateFilter),
                        toDateFilter: getDateObject(_toDateFilter),
                    }}
                />
                <NmRangeInput
                    nameStart="fromSumMoneyFilter"
                    nameEnd="toSumMoneyFilter"
                    size="lg"
                    valueStart={fromSumMoneyFilter}
                    valueEnd={toSumMoneyFilter}
                    pattern={patternNumber}
                    onChange={onChange}
                    label="Сумма операции"
                    placeholderStart="От"
                    placeholderEnd="До"
                />
                <NmRangeInput
                    nameStart="fromClientCommissionAmountFilter"
                    nameEnd="toClientCommissionAmountFilter"
                    size="lg"
                    valueStart={fromClientCommissionAmountFilter}
                    valueEnd={toClientCommissionAmountFilter}
                    pattern={patternNumber}
                    onChange={onChange}
                    label="Комиссия заказчика"
                    placeholderStart="От"
                    placeholderEnd="До"
                />
                <NmRangeInput
                    nameStart="fromPlatformAmountFilter"
                    nameEnd="toPlatformAmountFilter"
                    size="lg"
                    valueStart={fromPlatformAmountFilter}
                    valueEnd={toPlatformAmountFilter}
                    pattern={patternNumber}
                    onChange={onChange}
                    label="Комиссия исполнителя"
                    placeholderStart="От"
                    placeholderEnd="До"
                />
                <FilterButtonsV2
                    onSearch={onSearch}
                    onClear={onClear}
                />
            </NmForm>
        </>
    );
};

export default FinancePaymentListFilter;