import {
    BFF_CROWD_PAYMENT_ADD_REQUEST,
    BFF_CROWD_PAYMENT_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST,
    BFF_CROWD_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST,
    BFF_CROWD_PAYMENT_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_REPEAT_REQUEST,
    UPDATE_CROWD_PAYMENT_STORE,
} from "./actions";

export const getPageCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_GET_PAGE_REQUEST,
        payload,
    };
};

export const getTotalAmountCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST,
        payload,
    };
};

export const repeatCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_REPEAT_REQUEST,
        payload,
    };
};

export const rejectCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_REJECT_REQUEST,
        payload,
    };
};

export const massRejectCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST,
        payload,
    };
};

export const approveCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_APPROVE_REQUEST,
        payload,
    };
};

export const massApproveCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST,
        payload,
    };
};

export const addCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_ADD_REQUEST,
        payload,
    };
};

export const getByIdCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST,
        payload,
    };
};

export const getPageCrowdPaymentLog = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST,
        payload,
    };
};

export const massRevalidateCrowdPayment = (payload) => {
    return {
        type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST,
        payload,
    };
};

export const updateStoreCrowdPayment = (payload) => {
    return {
        type: UPDATE_CROWD_PAYMENT_STORE,
        payload,
    };
};