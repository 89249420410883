import React from "react";
import {Route, Switch} from "react-router";

import ContractorFinanceBalance from "./balance";
import {ContractorFinanceIncomeIndividualRoute} from "./income-individual/route";
import {ContractorFinanceIncomeNdflRoute} from "./income-ndfl/route";
import {ContractorFinanceIncomeNpdRoute} from "./income-npd/route";
import ContractorCardFinancesPatents from "./patents";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST,
} from "../../../constants/links";

const ContractorCardFinances = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST}
                component={ContractorFinanceBalance}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS}
                component={ContractorFinanceIncomeNpdRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS}
                component={ContractorFinanceIncomeNpdRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS}
                component={ContractorFinanceIncomeNpdRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST}
                component={ContractorFinanceIncomeNdflRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST}
                component={ContractorFinanceIncomeNdflRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST}
                component={ContractorFinanceIncomeNdflRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST}
                component={ContractorFinanceIncomeIndividualRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST}
                component={ContractorFinanceIncomeIndividualRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST}
                component={ContractorFinanceIncomeIndividualRoute}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST}
                component={ContractorCardFinancesPatents}
            />
        </Switch>
    );
};

export default ContractorCardFinances;