import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import {ReactComponent as AddIcon} from "../../images/add.svg";
import ContextMenu from "../ActualComponents/ContextMenu";
import FilterButtonsV2 from "../ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmDateRangePickerV2 from "../ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import NmRangeInput from "../ActualComponents/NmRangeInput";
import ApplyButtons from "../ApplyButtons";
import CheckboxList from "../CheckboxList";
import NmButton from "../NmButton";
import NmPage from "../NmPage";
import {NmPageHeader} from "../NmPageHeader";
import Task from "../NmTask";
import NmTitle from "../NmTitle";
import PhoneWithCodeFilter from "../PhoneWithCodeFilter";
import {withPageData} from "../withPageData";
import MailingsEditForm from "./EditCard";
import MailingsListCard from "./ListCard";

import {PATTERN_NUMBER} from "../../containers/deposit/client-list/hooks/useGetlDepositData";

import dateFormat from "../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../utils/localstorage";
import {removePhoneMask} from "../../utils/stringFormat";
import {
    handleFormString,
    handleNumber,
    isNullOrWhitespace,
} from "../../utils/stringHelper";
import {
    toastError,
    toastSuccess,
} from "../../utils/toastHelper";

import {NOTIFICATION_SOURCE} from "../../constants/notifications";
import {
    ADMIN, CLIENT_ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../constants/roles";

import {
    getClientNameMap,
    getClientRoleMap,
} from "../../ducks/client";
import {clientCurrentMemberSelector} from "../../ducks/clientMember";
import {getWorkRegionsOptionsSelector} from "../../ducks/contractor";
import {downloadDocument} from "../../ducks/documents";
import {
    getAllTasks,
    jobTasksCancelNotificationSelector,
} from "../../ducks/job";
import {
    getOrderCategoriesAll,
    getOrderCategoriesAllOptionsSelector,
} from "../../ducks/orderCategories";
import {
    getAllSubCategories,
    subCategoriesAllOptionsSelector,
} from "../../ducks/orderSubcategories";
import {
    specialitiesAllV2OptionsSelector,
} from "../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

import {
    MAILINGS_STATUS,
} from "../../constants/ mailings/segmentationType";

const ACTION_TYPE = {
    CANCEL: "CANCEL",
    RETRY: "RETRY",
    COPY: "COPY",
};

const CANCEL_ERROR_CODE = {
    PUSH_NOTIFICATION_CANNOT_BE_CANCELLED: "PUSH_NOTIFICATION_CANNOT_BE_CANCELLED",
    REMOVE_NOTIFICATIONS_ERROR: "REMOVE_NOTIFICATIONS_ERROR",
};

class MailingsList extends Component {
    static propTypes = {
        getContractorList: PropTypes.func,
    };

    static defaultProps = {
        getContractorList: () => {

        },
    };

    constructor(props) {
        super(props);

        this.state = {
            formFilter: {
                textFilter: "",
                fromDateFilter: null,
                toDateFilter: null,
                nameFilter: "",
            },
            isSearch: false,
            pageSize: 25,
            pageNum: 1,
            isOpenEditCard: false,
            isOpenDropDown: false,
            confirmData: {},
            error: {},
            isOpenEditModal: false,
            removePosted: true,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getOrderCategoriesAll,
            getAllSubCategories,
            getClientNameMap,
            getClientRoleMap,
        } = this.props;
        this.fetchList();
        getOrderCategoriesAll();
        getAllSubCategories();
        getClientNameMap();
        getClientRoleMap();
    }

    componentDidUpdate(prevProps) {
        const {
            progressTasks,
        } = this.props;

        const {
            progressTasks: prevProgressTasks,
        } = prevProps;

        if (progressTasks.length < prevProgressTasks.length) {
            this.fetchList();
        }
    }

    getTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    fetchList = () => {
        const {fetchList} = this.props;
        const {
            pageNum,
            pageSize,
            formFilter: {
                textFilter: searchText,
                fromDateFilter,
                toDateFilter,
                nameFilter,
                authorFilter,
                fromCountContractorFilter,
                toCountContractorFilter,
                phoneFilter,
            },
        } = this.state;

        fetchList(
            {
                pageNum,
                pageSize,
                phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
                toCountContractorFilter: handleNumber(toCountContractorFilter),
                fromCountContractorFilter: handleNumber(fromCountContractorFilter),
                searchText: handleFormString(searchText),
                authorFilter: handleFormString(authorFilter),
                nameFilter: handleFormString(nameFilter),
                fromDate: fromDateFilter ? dateFormat(fromDateFilter, "yyyy-MM-dd") : undefined,
                toDate: toDateFilter ? dateFormat(toDateFilter, "yyyy-MM-dd") : undefined,
            },
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    getOptions(item) {
        const {
            t,
            clientId,
        } = this.props;
        const {
            status,
            notificationSource,
        } = item;

        if (![ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(this.role)) {
            return null;
        }

        const option = {
            cancel: {
                key: "cancel",
                text: t("button.cancel"),
                value: "cancel",
            },
            retry: {
                key: "retry",
                text: t("button.retry"),
                value: "retry",
            },
            copy: {
                key: "copy",
                text: t("button.copy"),
                value: "copy",
            },
        };

        const options = [];

        if (
            clientId ||
            notificationSource !== NOTIFICATION_SOURCE.CLIENT_ADMIN_UI
        ) {
            options.push(option["copy"]);
        }

        if (
            ![
                MAILINGS_STATUS.ERROR.VALUE,
                MAILINGS_STATUS.PLANNED.VALUE,
                MAILINGS_STATUS.IN_PROGRESS.VALUE,
            ].includes(status)
        ) {
            return options;
        }

        if (MAILINGS_STATUS.ERROR.VALUE === status) {
            return [
                ...options,
                option["retry"],
            ];
        }

        return [
            ...options,
            option["cancel"],
        ];
    }

    toggleRemovePosted = () => {
        this.setState(prevState => ({
            ...prevState,
            removePosted: !prevState.removePosted,
        }));
    };

    onClickActionItem(option, confirmData) {
        const {t} = this.props;
        const {removePosted} = this.state;
        const {value} = option;

        switch (value) {
        //Отменить
        case "cancel": {
            this.openConfirm({
                ...confirmData,
                removePosted,
                actionType: ACTION_TYPE.CANCEL,
                confirmText: t("mailings-list.cancel-mailing-confirm"),
                onConfirm: this.cancel,
            });
            return;
        }
        case "retry": {
            this.openConfirm({
                ...confirmData,
                actionType: ACTION_TYPE.RETRY,
                confirmText: t("mailings-list.retry-mailing-confirm"),
                onConfirm: this.retry,
            });
            return;
        }
        case "copy": {
            this.copyMailing(confirmData);
            return;
        }
        default:
            return;
        }
    }

    copyMailing(mailing) {
        const {updateFieldPushNotificationStore} = this.props;

        updateFieldPushNotificationStore({pushNotification: mailing});
        this.toggleEditCard();
    }

    renderActionCell(item) {
        const options = this.getOptions(item);

        if (isEmpty(options)) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    this.onClickActionItem(option, item);
                }}
            />
        );
    }

    closeConfirm = () => {
        this.setState({
            confirmData: {},
            isShowConfirm: false,
            removePosted: true,
        });
    };

    handleResponseCancel = (result) => {
        const {errorMessage, errorCode} = result;

        if (
            [
                CANCEL_ERROR_CODE.PUSH_NOTIFICATION_CANNOT_BE_CANCELLED,
                CANCEL_ERROR_CODE.REMOVE_NOTIFICATIONS_ERROR,
            ].includes(errorCode)
        ) {
            toastError(errorMessage);
            this.fetchList();

            return;
        }

        if (errorMessage) {
            toastError(errorMessage);

            return;
        }

        this.getTasks();
        toastSuccess("Задача по отмене рассылки запущена");
    };

    handleResponseRetry = (result) => {
        const {t} = this.props;
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});
            return;
        }
        this.fetchList();
        toastSuccess(t("mailings-list.retry-mailing-success"));
    };

    cancel = () => {
        const {cancelNotification} = this.props;

        const {
            confirmData: {
                id,
            },
            removePosted,
        } = this.state;

        cancelNotification({
            data: {
                id,
                removePosted,
            },
            handleResponse: this.handleResponseCancel,
        });

        this.closeConfirm();
    };

    retry = () => {
        const {
            retryNotification,
        } = this.props;

        const {
            confirmData: {
                id,
            },
        } = this.state;

        retryNotification({
            data: {
                id,
            },
            handleResponse: this.handleResponseRetry,
        });
        this.closeConfirm();
    };

    openConfirm = (confirmData) => {
        this.setState({
            isShowConfirm: true,
            confirmData,
        });
    };

    renderConfirmWindow = () => {
        const {
            isShowConfirm,
            confirmData: {
                actionType,
                confirmText,
                onConfirm,
                status,
            },
            removePosted,
        } = this.state;

        if (!isShowConfirm) {
            return null;
        }

        return (
            <NmModal
                onClose={this.closeConfirm}
                header={
                    <NmTitle size="lg">
                        {confirmText}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submit={onConfirm}
                        submitBtnContent="Да"
                        onClose={this.closeConfirm}
                        cancelBtnContent="Нет"
                    />
                }
                children={
                    (actionType === ACTION_TYPE.CANCEL && status === MAILINGS_STATUS.IN_PROGRESS.VALUE) &&
                    <NmForm addMargin>
                        <NmCheckboxV2
                            label="Удалить уже отправленные"
                            checked={removePosted}
                            onChange={this.toggleRemovePosted}
                        />
                    </NmForm>
                }
            />
        );
    };

    getRows = () => {
        const {
            list = [],
            clientId,
        } = this.props;

        return list.map(item => {
            return {
                ...item,
                key: item.id,
                contentRow: (
                    <MailingsListCard
                        clientId={clientId}
                        mailing={item}
                        actions={this.renderActionCell(item)}
                    />
                ),
            };
        });
    };

    toggleEditCard = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenEditModal: !prevState.isOpenEditModal,
        }));
    };

    handleFilter = (e, {name, value}) => {
        let _value = value;

        if (["fromCountContractorFilter", "toCountContractorFilter"].includes(name) && value === "0") {
            _value = "1";
        }

        this.setState(prevState => ({
            ...prevState,
            formFilter: {
                ...prevState.formFilter,
                [name]: _value,
            },
        }));
    };

    submitFilter = () => {
        const {toCountContractorFilter, fromCountContractorFilter} = this.state.formFilter;

        if (isNullOrWhitespace(fromCountContractorFilter) && !isNullOrWhitespace(toCountContractorFilter)) {
            this.setState({
                error: {fromCountContractorFilter: "Обязательное поле"},
            });
            return;
        }

        if (!isNullOrWhitespace(fromCountContractorFilter) && !isNullOrWhitespace(toCountContractorFilter) && Number(fromCountContractorFilter) > Number(toCountContractorFilter)) {
            this.setState({
                error: {fromCountContractorFilter: "Значение \"От\" должно быть меньше или равно значению \"До\""},
            });
            return;
        }

        this.setState({
            error: {fromCountContractorFilter: ""},
        });

        this.setState({
            pageNum: 1,
            isSearch: true,
        }, this.fetchList);
    };

    clearFilter = () => {
        this.setState({
            formFilter: {
                textFilter: "",
                nameFilter: "",
                authorFilter: "",
                phoneFilter: "",
                fromCountContractorFilter: "",
                toCountContractorFilter: "",
                fromDateFilter: null,
                toDateFilter: null,
                isSearch: false,
            },
            error: {},
        }, this.fetchList);
    };

    renderFilter() {
        const {
            formFilter,
            error,
        } = this.state;

        return (
            <NmForm
                className="client-deposit-history__filter"
                addMargin
            >
                <NmInputV2
                    size="lg"
                    label="Наименование рассылки"
                    maskChar={null}
                    onChange={this.handleFilter}
                    name="nameFilter"
                    value={formFilter.nameFilter}
                    placeholder="Введите наименование рассылки"
                />
                <NmInputV2
                    size="lg"
                    label="Автор рассылки"
                    maskChar={null}
                    onChange={this.handleFilter}
                    name="authorFilter"
                    value={formFilter.authorFilter}
                    placeholder="Введите автора рассылки"
                />
                <NmInputV2
                    size="lg"
                    label="Текст уведомления"
                    maskChar={null}
                    onChange={this.handleFilter}
                    name="textFilter"
                    placeholder="Введите текст уведомления"
                    value={formFilter.textFilter}
                />
                <PhoneWithCodeFilter
                    name="phoneFilter"
                    value={formFilter.phoneFilter}
                    onChange={this.handleFilter}
                />
                <NmDateRangePickerV2
                    size="lg"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{
                        fromDateFilter: formFilter.fromDateFilter,
                        toDateFilter: formFilter.toDateFilter,
                    }}
                    label="Период"
                    onChange={this.handleFilter}
                    isCurrentDateMax
                />
                <NmRangeInput
                    error={error.fromCountContractorFilter}
                    nameStart="fromCountContractorFilter"
                    nameEnd="toCountContractorFilter"
                    size="lg"
                    valueStart={formFilter.fromCountContractorFilter}
                    valueEnd={formFilter.toCountContractorFilter}
                    pattern={PATTERN_NUMBER}
                    onChange={this.handleFilter}
                    label="Кол-во получателей"
                    placeholderStart="От"
                    placeholderEnd="До"
                />
                <FilterButtonsV2
                    className="mailings-list__filter-actions"
                    onSearch={this.submitFilter}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    renderEditModal = () => {
        const {isOpenEditModal} = this.state;
        const {
            addNotification,
            updateFieldPushNotificationStore,
            progressAdd,
            progressAction,
            getContractorCount,
            notification,
            addContractor,
            clientId,
        } = this.props;

        return (
            isOpenEditModal &&
            <MailingsEditForm
                clientId={clientId}
                notification={notification}
                getContractorCount={getContractorCount}
                addNotification={addNotification}
                addContractor={addContractor}
                progressAdd={progressAdd}
                progressAction={progressAction}
                updateFieldPushNotificationStore={updateFieldPushNotificationStore}
                onClose={this.toggleEditCard}
            />
        );
    };

    render() {
        const {
            list,
            progress,
            totalPages,
            t,
            totalCount,
            clientId,
        } = this.props;

        const {
            pageNum,
            pageSize,
            isSearch,
        } = this.state;

        return (
            <NmPage
                isLoaded={progress}
                noPadding
                titleHiddenInMobile={false}
                header={
                    <NmPageHeader
                        text={t("mailings-list.title")}
                        totalCount={
                            clientId
                                ? undefined
                                : totalCount
                        }
                        noWrap={false}
                        tooltipText={
                            clientId
                                ? "Здесь вы можете настроить уведомления для исполнителей с отправкой их сразу или по заданному времени"
                                : undefined
                        }
                    />
                }
                typeFilter="vertical"
                filtersBase={this.renderFilter()}
                controls={
                    [ADMIN, CLIENT_ADMIN, NM_MANAGER, NM_OPERATOR].includes(this.role) &&
                    <NmButton
                        icon={<AddIcon />}
                        size="xl"
                        onClick={this.toggleEditCard}
                    >
                        {t("mailings-list.add-button")}
                    </NmButton>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
            >
                <Task />
                {this.renderEditModal()}
                {this.renderConfirmWindow()}
                {
                    list.length ?
                        <CheckboxList
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2
                            isSearch={isSearch}
                            fetchProgress={progress}
                        />
                }
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        workRegionsOptions: getWorkRegionsOptionsSelector(state),
        categoriesAllOptions: getOrderCategoriesAllOptionsSelector(state),
        subCategoriesAllOptions: subCategoriesAllOptionsSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        progressTasks: jobTasksCancelNotificationSelector(state),
    }),
    {
        downloadDocument,
        getOrderCategoriesAll,
        getAllSubCategories,
        getClientNameMap,
        getClientRoleMap,
        getAllTasks,
    },
)(withTranslation()(MailingsList)));
