import {useMemo} from "react";

import {
    isFullRegistrationStepProgressBarVisible,
} from "../utils/isStepWithoutProgressBar";

export const useFullRegistrationStepsProgress = (params) => {
    const {
        steps,
        currentStep,
    } = params;

    const {
        countOfStepsWithoutGeneralSteps,
        currentStepNumber,
        isVisibleStepWithoutProgressBar,
    } = useMemo(() => {
        const isStepWithoutProgressBar = isFullRegistrationStepProgressBarVisible(currentStep.value);
        const isVisibleStepWithoutProgressBar = currentStep.value && isStepWithoutProgressBar;

        if (!steps) {
            return {
                isVisibleStepWithoutProgressBar,
            };
        }

        const countOfStepsWithoutGeneralSteps = steps.filter((item) => {
            return isFullRegistrationStepProgressBarVisible(item.VALUE);
        }).length;

        const countOfSteps = steps.length;
        const diff = countOfSteps - countOfStepsWithoutGeneralSteps;
        const currentStepNumber = currentStep.position - diff + 1;

        return {
            isVisibleStepWithoutProgressBar,
            countOfStepsWithoutGeneralSteps,
            currentStepNumber,
        };
    }, [
        steps,
        currentStep,
    ]);

    return {
        countOfStepsWithoutGeneralSteps,
        currentStepNumber,
        isVisibleStepWithoutProgressBar,
    };
};