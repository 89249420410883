import React, {FC, ForwardedRef, ReactNode, useEffect} from "react";
import {useMedia} from "react-media";

import {history} from "../../../store/configureStore";
import ScrollableContainer from "../../ActualComponents/ScrollableContainer";

import "./style.sass";

interface CheckboxListLeftRightContent {
    className?: string,
    leftContent?: ReactNode,
    rightContent?: ReactNode,
    isShowRightContent?: boolean,
    rightContentLink?: string, // должна содержать параметр :id
    rightContentId?: string,
    rightContentInitialId?: string,
    rightContentClassName?: string,
    leftContentRef?: ForwardedRef<HTMLDivElement | null>,
}

export const CheckboxListExtendedContent: FC<CheckboxListLeftRightContent> = (props) => {
    const {
        className,
        leftContent,
        rightContent,
        isShowRightContent,
        rightContentLink,
        rightContentInitialId,
        rightContentId,
        rightContentClassName = "",
        leftContentRef,
    } = props;

    useEffect(() => {
        if (rightContentId) {
            return;
        }

        if (rightContentInitialId && rightContentLink) {
            // добавляем id в url без замены в истории браузера, чтобы для пользователя переход был незаметен
            // и не приходилось нажимать 2 раза для перехода назад
            history.replace(rightContentLink?.replace(":id", rightContentInitialId));
        }
    }, [rightContentId]);

    const isMobile = useMedia({query: {maxWidth: 767}});

    const getLeftContent = () => {
        if (isMobile && isShowRightContent) {
            return null;
        }

        return (
            <div className="checkbox-list-extended-content__left-content col-16 col-md-6 col-xxl-4">
                <ScrollableContainer
                    fullHeight={true}
                    ref={leftContentRef}
                    className="checkbox-list-extended-content__scrollable-container"
                >
                    <div className="checkbox-list-extended-content__inner-container">
                        {leftContent}
                    </div>
                </ScrollableContainer>
            </div>
        );
    };

    const getRightContent = () => {
        if (!rightContent) {
            return null;
        }

        if (isMobile && !isShowRightContent) {
            return null;
        }

        return (
            <div className="checkbox-list-extended-content__right-content-container col-16 col-md-10 col-xxl-12">
                <div className={`checkbox-list-extended-content__right-content ${rightContentClassName}`}>
                    {rightContent}
                </div>
            </div>
        );
    };

    return (
        <div className={`checkbox-list-extended-content ${className} row`}>
            {getLeftContent()}
            {getRightContent()}
        </div>
    );
};
