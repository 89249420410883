// Для задания компонента в конфиге и для определения нужного компонента для рендера
export const COMPONENT = {
    ICON_BUTTON: "ICON_BUTTON",
    //NmButton
    BUTTON: "BUTTON",
    //NmHorizontalToggleV2
    HORIZONTAL_TOGGLE: "HORIZONTAL_TOGGLE",
    //ButtonWithTooltip
    BUTTON_WITH_TOOLTIP: "BUTTON_WITH_TOOLTIP",
    //ButtonWithContextMenu
    BUTTON_WITH_CONTEXT_MENU: "BUTTON_WITH_CONTEXT_MENU",
    //Text
    TEXT: "TEXT",
    // Кнопки из реестров
    REGISTRY_BUTTON: "REGISTRY_BUTTON",
    BUTTON_CONTACT: "BUTTON_CONTACT",
    DEFAULT_DROPZONE: "DEFAULT_DROPZONE",
};

