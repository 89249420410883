import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import dateFormat, {getChecksDatepickerMinTime, today} from "../../../utils/dateFormat";
import {
    CURRENT_CLIENT_ID,
    CURRENT_CLIENT_USER_ID,
    ls,
} from "../../../utils/localstorage";
import {
    getFormattedFullName,
    getFullName,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    addRangeWorkLog,
    updateRangeWorkLog,
} from "../../../ducks/contractorWorkLog";

import PropTypes from "prop-types";

import "./style.sass";

class TimeSheetNewModal extends Component {
    static propTypes = {
        onClose: PropTypes.func,
    };

    static defaultProps = {
        onClose: () => {
        },
    };

    constructor(props) {
        super(props);
        const {
            data: {
                inLocalDatetime,
                outLocalDatetime,
                contractorWorkLogId,
            },
            checkinData: {
                checkOutRequired,
                checkInRequired,
            },
        } = this.props;

        this.state = {
            newFormWorkLog: {
                inLocalDatetime: inLocalDatetime ? new Date(inLocalDatetime) : null,
                outLocalDatetime: outLocalDatetime ? new Date(outLocalDatetime) : null,
            },
        };
        this.checkOutRequired = checkOutRequired;
        this.checkInRequired = checkInRequired;
        this.isEdit = !!contractorWorkLogId;
    }

    handleOnChangeForm = (event, {name, value: date}) => {
        if (name === "outLocalDatetime") {
            this.setState(prevState => {
                const {inLocalDatetime} = prevState.newFormWorkLog;

                return {
                    ...prevState,
                    newFormWorkLog: {
                        ...prevState.newFormWorkLog,
                        [name]: date < inLocalDatetime ? inLocalDatetime : date,
                    },
                };
            });

            return;
        }

        this.setState(prevState => {
            const {outLocalDatetime} = prevState.newFormWorkLog;

            return {
                ...prevState,
                newFormWorkLog: {
                    ...prevState.newFormWorkLog,
                    [name]: outLocalDatetime && date > outLocalDatetime ? outLocalDatetime : date,
                },
            };
        });
    };

    save = () => {
        const {
            addRangeWorkLog,
            data,
            updateRangeWorkLog,
            onClose,
            currentMember: {
                lastName,
                firstName,
                patronymic,
            },
        } = this.props;

        const {
            newFormWorkLog: {
                inLocalDatetime,
                outLocalDatetime,
            },
        } = this.state;

        const requestData = {
            regClientUserId: ls(CURRENT_CLIENT_USER_ID),
            regClientUserClientId: ls(CURRENT_CLIENT_ID),
            regClientUserName: getFormattedFullName(getFullName(lastName, firstName, patronymic)),
            ...data,
            inLocalDatetime: inLocalDatetime? dateFormat(inLocalDatetime, "yyyy-MM-dd'T'HH:mm:ss.SSS"): null,
            outLocalDatetime: outLocalDatetime? dateFormat(outLocalDatetime, "yyyy-MM-dd'T'HH:mm:ss.SSS"): null,
        };

        if (this.isEdit) {
            updateRangeWorkLog(requestData);
        } else {
            addRangeWorkLog(requestData);
        }
        onClose();
    };

    clearForm = () => {
        this.setState({
            newFormWorkLog: {
                inLocalDatetime: null,
                outLocalDatetime: null,
            },
        });
    };

    get minTime() {
        const {
            newFormWorkLog: {
                inLocalDatetime,
                outLocalDatetime,
            },
        } = this.state;

        return getChecksDatepickerMinTime(inLocalDatetime, outLocalDatetime);
    }

    get maxTime() {
        const {
            newFormWorkLog: {
                inLocalDatetime,
                outLocalDatetime,
            },
        } = this.state;

        if (isNullOrWhitespace(outLocalDatetime)) {
            return today.iso.end();
        }

        if (isNullOrWhitespace(inLocalDatetime)) {
            return outLocalDatetime;
        }

        const outDate = dateFormat(outLocalDatetime, "dd.MM.yyyy");
        const inDate = dateFormat(inLocalDatetime, "dd.MM.yyyy");

        return inDate === outDate ? outLocalDatetime : today.iso.end();
    }

    render() {
        const {
            newFormWorkLog: {
                inLocalDatetime,
                outLocalDatetime,
            },
        } = this.state;

        const {onClose, t} = this.props;

        return (
            <NmModal
                onClose={onClose}
                zIndex={1100}
                size="lg"
                className="time-sheet-new-modal"
                header={
                    <NmTitle size="lg">
                        {this.isEdit ? t("time-sheet-new.edit-title") : t("time-sheet-new.new-title")}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent={t("button.save")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={onClose}
                        submit={this.save}
                    />
                }
            >
                <NmForm className="flex-vertical-end flex">
                    {this.checkInRequired ?
                        <div className="time-sheet-new-modal__date mr-15">
                            <NmDatePicker
                                label={t("time-sheet-new.date-in")}
                                className="time-sheet-date"
                                placeholderText={t("time-sheet-new.beginning-period")}
                                showTimeSelect
                                timeCaption={t("time-sheet-new.time")}
                                timeIntervals={1}
                                maxDate={outLocalDatetime || today.iso.now()}
                                dateFormat="dd.MM.yy HH:mm"
                                dateFormatMask="99.99.99 99:99"
                                selected={inLocalDatetime}
                                minTime={today.iso.start()}
                                maxTime={this.maxTime}
                                name="inLocalDatetime"
                                onChange={this.handleOnChangeForm}
                            />
                        </div> : ""
                    }
                    {this.checkOutRequired ?
                        <div className="time-sheet-new-modal__date">
                            <NmDatePicker
                                label={t("time-sheet-new.date-out")}
                                className="time-sheet-date"
                                placeholderText={t("time-sheet-new.end-period")}
                                minDate={inLocalDatetime}
                                dateFormat="dd.MM.yy HH:mm"
                                dateFormatMask="99.99.99 99:99"
                                name="outLocalDatetime"
                                selected={outLocalDatetime}
                                onChange={this.handleOnChangeForm}
                                maxDate={today.iso.now()}
                                minTime={this.minTime}
                                maxTime={today.iso.end()}
                                showTimeSelect
                                timeCaption={t("time-sheet-new.time")}
                                timeIntervals={1}
                            />
                        </div> : ""
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        currentMember: clientCurrentMemberSelector(state),
    }),
    {
        updateRangeWorkLog,
        addRangeWorkLog,
    },
)(withTranslation()(TimeSheetNewModal));
