import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import DepartmentFederalMigrationCard from "../depatment-federal-migration-card";

import bem from "../../../utils/bem";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    handleFormString,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, NM_MANAGER} from "../../../constants/roles";
import {federalRegionsOptions} from "../../../constants/selectOptions";

import {
    deleteFederalMigration,
    getFederalMigrationListSelector,
    getFederalMigrationTotalCountSelector,
    getFederalMigrationTotalPagesSelector,
    getFederalRegionDictSelector,
    getFederalRegionOptionsSelector,
    getListMigrations,
} from "../../../ducks/federalMigration";

import PropTypes from "prop-types";

import "./style.sass";

class DepartmentFederalMigrationList extends Component {
    static propTypes = {
        totalPages: PropTypes.number,
        selectedClientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        totalPages: 0,
    };

    pageSizes = [25, 50, 100];

    constructor() {
        super();

        this.state = {
            pageNum: 1,
            pageSize: 25,
            isOpenCard: false,
            federalId: "",
            region: federalRegionsOptions()[0].key,
        };

        this.role = ls(USER_ROLE);
        this.isEditable = [ADMIN, NM_MANAGER].includes(this.role);
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList = () => {
        const {getListMigrations} = this.props;

        const {
            nameDepartmentFilter,
            pageNum,
            pageSize,
            region,
        } = this.state;

        getListMigrations({
            pageNum,
            pageSize,
            nameDepartmentFilter,
            region: handleFormString(region),
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    handleChangeSearch = (e, {value, name}) => {
        this.setState(prevState => (
            {
                ...prevState,
                [name]: value,
                pageNum: 1,
            }),
        () => setTimeout(this.fetchList, 500),
        );
    };

    handleAdd = () => {
        this.setState({isOpenCard: true});
    };

    handleEdit = (federalId) => {
        this.setState({
            isOpenCard: true,
            federalId,
        });
    };

    handleCloseNewModal = () => {
        this.setState({
            isOpenCard: false,
            federalId: "",
        });
    };

    handleOnSelectRegion = (e, {value: region}) => {
        if (isNullOrWhitespace(region)) {
            this.setState({
                region,
            });

            return;
        }

        this.setState({
            region,
            pageNum: 1,
        }, this.fetchList);
    };

    deleteFederalMigration = () => {
        const {
            unitData: {
                federalId,
            },
        } = this.state;

        const {deleteFederalMigration} = this.props;

        deleteFederalMigration({federalId});

        this.handleCancel();
    };

    openConfirm(unitData) {
        this.setState({
            openConfirmWindow: true,
            unitData,
        });
    }

    handleCancel = () => {
        this.setState({
            openConfirmWindow: false,
            unitData: {},
        });
    };

    renderConfirmWindow() {
        const {openConfirmWindow} = this.state;
        const {t} = this.props;

        return (
            openConfirmWindow &&
            <NmConfirmV2
                content={t("department-federal-migration-list.confirm-delete")}
                onCancel={this.handleCancel}
                onConfirm={this.deleteFederalMigration}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    }

    getMediaControls = ({federalId}) => {
        if (!this.isEditable) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => this.handleEdit(federalId),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => this.openConfirm({federalId}),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    getRows = () => {
        const {
            list = [],
            federalRegionDict,
        } = this.props;

        return list.map(item => {
            const {
                nameDepartment,
                region,
                address,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={nameDepartment}
                        labels={[
                            {label: "Регион", text: federalRegionDict[region] || "-"},
                            {label: "Адрес", text: address || "-"},
                        ]}
                        classNameMainContent="col-16"
                        isFixedActions
                        mediaControls={this.getMediaControls(item)}
                    />
                ),
            };
        });
    };

    renderFederalMigrationCard() {
        const {federalId, isOpenCard} = this.state;

        return isOpenCard &&
            <DepartmentFederalMigrationCard
                federalId={federalId}
                handleClose={this.handleCloseNewModal}
            />;
    }

    renderFilter() {
        const {federalRegionsOptions} = this.props;
        const {region} = this.state;

        return (
            <NmForm addMargin>
                <NmDropdownV2
                    size="lg"
                    className="federal-migration-list__dropdown"
                    isClearable
                    placeholder="Выберите из списка"
                    label="Регион"
                    search
                    onChange={this.handleOnSelectRegion}
                    value={region}
                    options={federalRegionsOptions}
                />
            </NmForm>
        );
    }

    render() {
        const {
            openConfirmWindow,
            pageNum,
            pageSize,
        } = this.state;

        const {
            list = [],
            t,
            totalPages,
            totalCount,
        } = this.props;

        const [block, element] = bem("federal-migration-list");

        return (
            <NmPage
                headerClassName="justify-content-end"
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                currentPageSize={pageSize}
                typeFilter="vertical"
                totalCount={totalCount}
                className={block()}
                filterClassName={element("filter-wrapper")}
                filtersBase={this.renderFilter()}
                controls={
                    this.isEditable && <NmButton
                        size="xl"
                        onClick={this.handleAdd}
                        icon={<AddIcon />}
                    >
                        {t("department-federal-migration-list.add-unit-button")}
                    </NmButton>
                }
            >
                {
                    openConfirmWindow && this.renderConfirmWindow()
                }
                {this.renderFederalMigrationCard()}
                <div className="federal-migration-list__table">
                    {
                        list?.length ?
                            <CheckboxList
                                rows={this.getRows()}
                            /> :
                            <NmEmptyPageV2 />
                    }
                </div>
            </NmPage>
        );
    }
}


export default connect(
    state => ({
        list: getFederalMigrationListSelector(state),
        totalPages: getFederalMigrationTotalPagesSelector(state),
        totalCount: getFederalMigrationTotalCountSelector(state),
        federalRegionsOptions: getFederalRegionOptionsSelector(state),
        federalRegionDict: getFederalRegionDictSelector(state),
    }),
    {
        getListMigrations,
        deleteFederalMigration,
    },
)(withTranslation()(DepartmentFederalMigrationList));

