import React, {ReactElement} from "react";
import {MediaQueryObject, useMedia} from "react-media";

export type TMediaQueriesRendererConfig = {[name: string]: {render: () => ReactElement | null}};

interface IMediaQueriesRenderer {
    mediaQueries: {[id: string]: MediaQueryObject},
    config: TMediaQueriesRendererConfig
}

const MediaQueriesRenderer = (props: IMediaQueriesRenderer) => {
    const {
        mediaQueries,
        config,
    } = props;
    const matches = useMedia({queries: mediaQueries});

    const keys = Object.keys(matches);
    const values = Object.values(matches);
    const currentQueryIndex = values.findIndex(value => (value === true));

    if (config[keys[currentQueryIndex]]) {
        return config[keys[currentQueryIndex]].render() || null;
    }

    if (config.other) {
        return config.other.render() || null;
    }

    return null;
};

export default MediaQueriesRenderer;