import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmPageInfoCardsAccordion from "../../../../../components/ActualComponents/NmPageInfoCardsAccordion";

import {formatAmountWithNullChecking} from "../../../../../utils/stringFormat";

import {
    getDepositStateCrowdTasks,
    updateStoreCrowdTasks,
} from "../../../../../ducks/bff/crowd/tasks/actionCreators";
import {
    bffCrowdTasksDepositStateProgressSelector,
    bffCrowdTasksDepositStateSelector,
} from "../../../../../ducks/bff/crowd/tasks/selectors";

const CrowdDepositValuesAmountInfo = (props) => {
    const {
        clientId,
        className = "",
    } = props;

    const dispatch = useDispatch();

    const depositState = useSelector(bffCrowdTasksDepositStateSelector);
    const isLoading = useSelector(bffCrowdTasksDepositStateProgressSelector);

    const {
        depositAmount = 0,
        nonDistributedDeposit = 0,
        tasksSpentAmount = 0,
        availableForOrdersAmount = 0,
        depositDistributed = false,
    } = depositState || {};

    useEffect(() => {
        dispatch(getDepositStateCrowdTasks({clientId}));

        return () => {
            dispatch(updateStoreCrowdTasks({depositState: {}}));
        };
    }, []);

    const cards = useMemo(() => [
        {
            isLoading,
            title: depositDistributed ? "Распред. депозит" : "Депозит",
            value: `${formatAmountWithNullChecking(depositAmount)} ₽`,
            className: "col-16 col-xxl-2",
        },
        depositDistributed && {
            isLoading,
            title: "Нераспред. депозит",
            value: `${formatAmountWithNullChecking(nonDistributedDeposit )} ₽`,
            className: "col-16 col-xxl-2",
        },
        {
            isLoading,
            title: "Сумма опубликованных заданий",
            value: `${formatAmountWithNullChecking(tasksSpentAmount)} ₽`,
            helpTooltip: {
                text: depositDistributed ?
                    "Сумма опубликованных и неоплаченных заданий по объекту с учётом комиссии" :
                    "Сумма опубликованных и неоплаченных заданий с учётом комиссии",
                type: "light",
                position: "bottom-left",
            },
            className: "col-16 col-xxl-2",
        },
        {
            isLoading,
            title: "Сумма свободных ср-в для создания заданий",
            value: `${formatAmountWithNullChecking(availableForOrdersAmount)} ₽`,
            helpTooltip: {
                text: "Остаток суммы лимита для публикации новых заказов для Самозанятых. Рассчитывается как разница между лимитом заказов и израсходованным лимитом",
                type: "light",
                position: "bottom-left",
            },
            className: "col-16 col-xxl-2",
        },
    ].filter(item=> Boolean(item)), [
        depositState,
        isLoading,
    ]);

    return (
        <NmPageInfoCardsAccordion
            bootstrap={true}
            className={className}
            cards={cards}
            desktopViewFrom="xxl"
        />
    );
};

export default CrowdDepositValuesAmountInfo;