import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FieldArray, setNestedObjectValues} from "formik";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmButton from "../../../../../components/NmButton";
import NmLoader from "../../../../../components/NmLoader";
import KedoTemplatesEditFieldForm from "./field-form";

import {useMount} from "../../../../../hooks/useMount";

import {getKedoTemplateRequestData} from "./utils/getRequestData";
import {useKedoTemplateLoadEditForm} from "./utils/useLoadEditForm";

import {
    getAdmKedoTemplateUsersFieldNames,
    validateAdmKedoTemplateFields,
    validateAdmKedoTemplatePdf,
} from "../../../../../ducks/bff/adm/document-templates/kedo/templates/services";
import {
    kedoTemplatesProgressPreviewSelector,
    kedoTemplatesProgressUsersFieldNamesSelector,
    kedoTemplatesUsersFieldNamesSelector,
} from "../../../../../ducks/kedo/templates/selectors";

const KedoTemplatesEditForm = (props) => {
    const {
        formikProps,
        name,
        userReferenceParamModels,
        htmlDownloadLink,
    } = props;
    const {
        touched,
        setFieldTouched,
        errors,
        setFieldValue,
        values,
        setValues,
        validateForm,
        setErrors,
        setTouched,
    } = formikProps;

    const dispatch = useDispatch();

    const usersFieldNames = useSelector(kedoTemplatesUsersFieldNamesSelector);
    const progressUsersFieldNames = useSelector(kedoTemplatesProgressUsersFieldNamesSelector);
    const progressPreview = useSelector(kedoTemplatesProgressPreviewSelector);

    const {isMounted} = useMount();

    const {
        progressFile,
    } = useKedoTemplateLoadEditForm({
        name,
        values,
        setValues,
        htmlDownloadLink,
        userReferenceParamModels,
    });

    const fetchFieldNames = (files) => {
        const formData = new FormData();

        formData.append("templateFile", files[0]);

        dispatch(getAdmKedoTemplateUsersFieldNames({
            formData,
        }));
    };

    useEffect(() => {
        if (!isEmpty(usersFieldNames) && isMounted) {
            const userReferenceParamModels = usersFieldNames.map(field => {
                return {
                    field,
                    type: "",
                    name: "",
                    minValue: "",
                    maxValue: "",
                    maxLength: "",
                    availableValues: [],
                };
            });

            setValues({
                ...values,
                userReferenceParamModels,
            });
        }
    }, [
        usersFieldNames,
    ]);

    const onChangeFile = (files) => {
        if (isEmpty(files)) {
            setValues({
                ...values,
                files: [],
                userReferenceParamModels: [],
            });

            return;
        }

        fetchFieldNames(files);
        setFieldValue("files", files);
    };

    const getFields = () => {
        if (isEmpty(values.userReferenceParamModels)) {
            return null;
        }

        return (
            <div className="mt-6">
                <FieldArray name="userReferenceParamModels">
                    {
                        () => {
                            return values.userReferenceParamModels.map((item, index) => {
                                return (
                                    <KedoTemplatesEditFieldForm
                                        key={index}
                                        index={index}
                                        fetchFieldNames={fetchFieldNames}
                                        touched={
                                            touched.userReferenceParamModels &&
                                            touched.userReferenceParamModels[index]
                                        }
                                        values={
                                            values.userReferenceParamModels[index]
                                        }
                                        errors={
                                            errors.userReferenceParamModels &&
                                            errors.userReferenceParamModels[index]
                                        }
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        className="mb-4"
                                    />
                                );
                            });
                        }
                    }
                </FieldArray>
            </div>
        );
    };

    const getLoader = (content) => {
        return (
            <div className="fluid">
                <NmLoader
                    inline
                    transparentBackground
                    inverted={true}
                    active={true}
                    relativeDimmer
                    content={content}
                />
            </div>
        );
    };

    const onClickPreview = async () => {
        const errors = await validateForm();

        setErrors(errors);
        setTouched(setNestedObjectValues(values, true));

        if (!isEmpty(errors)) {
            return;
        }

        const formData = getKedoTemplateRequestData(values);

        dispatch(validateAdmKedoTemplateFields({
            formData,
            onSuccess: () => {
                dispatch(validateAdmKedoTemplatePdf({
                    formData,
                    onSuccess: (blob) => {
                        const file = new File([blob], `${values.templateName}.pdf`, {type: "application/pdf"});

                        setFieldValue("previewFile", file);
                    },
                }));
            },
        }));
    };

    const getPreviewButton = () => {
        return (
            <NmButton
                size="xl"
                color="light-green"
                disabled={progressPreview}
                type="button"
                onClick={onClickPreview}
            >
                Предпросмотр
            </NmButton>
        );
    };

    const getDropzone = () => {
        if (progressFile) {
            return getLoader("Загрузка файла");
        }

        return (
            <DropzoneV2
                accept={[".html"]}
                format="*.html"
                files={values.files}
                onChange={onChangeFile}
                maxSize={10}
                error={
                    touched.files &&
                    errors.files
                }
            />
        );
    };

    return (
        <NmForm>
            <NmInputV2
                required
                size="xl"
                maxLength={255}
                label="Наименование шаблона"
                placeholder="Введите наименование"
                error={
                    touched.templateName &&
                    errors.templateName
                }
                value={values.templateName}
                onChange={(event, {value}) => {
                    setFieldValue("templateName", value);
                }}
            />
            <div className="d-flex align-items-start mt-6 justify-content-between">
                {
                    isEmpty(values.files) ?
                        getDropzone() :
                        <>
                            <div>
                                {getDropzone()}
                            </div>
                            {getPreviewButton()}
                        </>
                }
            </div>
            {
                progressUsersFieldNames &&
                getLoader("Загрузка полей")
            }
            {getFields()}
        </NmForm>
    );
};

export default KedoTemplatesEditForm;