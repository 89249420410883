import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import OrderGroupEditForm from "./components/edit-form";
import OrderGroupsFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import useOrderGroupsAction from "./hooks/useAction";
import {useOrderGroupsFetchList} from "./hooks/useFetchList";
import {useOrderGroupsFilterDto} from "./hooks/useOrderGroupsFilterDto";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_ORDER_GROUPS_CARD} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR} from "../../../../constants/roles";

import {getClientCardSelector} from "../../../../ducks/client";
import {
    getOrderGroupsActionProgressSelector,
    getOrderGroupsListSelector,
    getOrderGroupsProgressSelector,
    getOrderGroupsTotalCountSelector,
    getOrderGroupsTotalPagesSelector,
} from "../../../../ducks/orderGroup";

const initFilterForm = {
    groupNameFilter: "",
    fromCreateDateFilter: null,
    toCreateDateFilter: null,
};

function OrderGroups(props) {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(getOrderGroupsListSelector);
    const totalCount = useSelector(getOrderGroupsTotalCountSelector);
    const totalPages = useSelector(getOrderGroupsTotalPagesSelector);
    const progress = useSelector(getOrderGroupsProgressSelector);
    const actionProgress = useSelector(getOrderGroupsActionProgressSelector);
    const {archived: clientArchived} = useSelector(getClientCardSelector);

    const role = ls(USER_ROLE);
    const isAccessAction = ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);
    const {t} = useTranslation();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        filter,
        onChangeFilter,
        setFilter,
    } = useFilter({initFilter: initFilterForm});

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useOrderGroupsFilterDto();

    const {
        setArchived,
        archived,
        fetchList,
    } = useOrderGroupsFetchList({
        clientId,
        pageNum,
        pageSize,
        filter: filterDto,
    });

    const {
        onCloseModal,
        modalData,
        onOpenModal,
        archiveGroup,
        deleteGroup,
    } = useOrderGroupsAction({clientId, fetchList});

    const toggleArchived = useCallback(() => {
        setArchived(!archived);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    }, [archived, pageSize]);

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setFilter({...initFilterForm});
        setFilterDto({...initFilterForm});
        setIsSearch(false);
    };

    const getHeaderMediaControls = () => {
        const archive = getArchiveButton(t, archived, {mobile: true});

        return {
            renderCount: {
                mobile: 1,
                tablet: 1,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: () => onOpenModal({isOpenEdit: true, isEdit: false}),
                        icon: <AddIcon />,
                        children: "Добавить группу",
                    },
                    visible: !clientArchived && !archived && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        disabled: progress,
                        onClick: toggleArchived,
                    },
                },
            ],
        };
    };

    const getMediaControls = (item) => {
        if (!isAccessAction) {
            return null;
        }

        const {
            archived,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({isOpenEdit: true, isEdit: true, groupData: item}),
                        children: "Редактировать",
                    },
                    visible: !archived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => archiveGroup(item),
                        children: archived ? "Из архива" : "В архив",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => deleteGroup(item),
                        children: "Удалить",
                    },
                    visible: !archived,
                },
            ],
        };
    };

    const renderNameLink = ({orderGroupName, orderGroupId}) => {
        const link = LINK_CLIENT_ORDER_GROUPS_CARD
            .replace(":clientId", clientId)
            .replace(":orderGroupId", orderGroupId);

        return (
            <ExtLink
                pageData={{pageNum, pageSize}}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {orderGroupName}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            const {
                createDateTime,
            } = item;

            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        fluidPrimaryHeader
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-14 col-xxl-8"
                        primaryHeader={renderNameLink(item)}
                        actionsClassName="col-1 col-xxl-8 justify-content-end"
                        secondaryHeader={`Создано ${formatLocalDate(createDateTime, "dd.MM.yyyy HH:mm")}`}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderEditModal = () => {
        return modalData?.isOpenEdit &&
            <OrderGroupEditForm
                isEdit={modalData.isEdit}
                onClose={onCloseModal}
                card={modalData.groupData}
                clientId={clientId}
                fetchList={fetchList}
            />;
    };

    const renderConfirmWindow = () => {
        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                content={modalData.content}
                onConfirm={modalData.onConfirm}
                onCancel={onCloseModal}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                disabled={actionProgress}
                loading={actionProgress}
                isOnlyConfirm={true}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Группы заказов
                </NmTitle>
            }
            mediaControls={getHeaderMediaControls()}
            typeFilter="vertical"
            filtersBase={
                <OrderGroupsFilter
                    filter={filter}
                    submitFilter={submitFilter}
                    onClear={clearFilter}
                    onChangeFilter={onChangeFilter}
                />
            }
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {renderEditModal()}
            {renderConfirmWindow()}
            {
                list?.length ?
                    <CheckboxList rows={getRows()} /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }

        </NmPage>
    );

}

export default OrderGroups;