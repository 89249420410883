import React, {useState} from "react";
import {withTranslation} from "react-i18next";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterObject from "../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../components/ActualComponents/FilterProject";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";

import bem from "../../../../utils/bem";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {
    PATENTS_REGISTRY_LIST_STATUS_FILTER_DICT,
} from "../../../../constants/patentsRegistry";

import PropTypes from "prop-types";

import "./style.sass";

const PatentsListFilter = (props) => {
    const {
        search,
        clear = () => {},
        handleChange,
        nameFilter = "",
        statusFilter = "",
        fromDateFilter = "",
        toDateFilter = "",
        creatorFioFilter = "",
        className = "",
        projectIds,
        objectIds,
        clientId,
    } = props;

    const [block, element] = bem("patents-list-filter", className);
    const statusOptions = dictionaryToOptions(PATENTS_REGISTRY_LIST_STATUS_FILTER_DICT);

    const [isSearchClear, setSearchClear] = useState(false);

    const handleClearFilter = () => {
        clear();

        setSearchClear(true);
        setTimeout(() => {
            setSearchClear(false);
        }, 50);
    };

    return (
        <NmForm
            addMargin
            type="filter"
            className={block()}
        >
            <FilterButtonsV2
                className={element("patents-list-filter__buttons")}
                onSearch={search}
                onClear={handleClearFilter}
            />
            <NmDropdownV2
                size="lg"
                className={`${element("name")} ${element("custom-dropdown")}`}
                options={statusOptions}
                label="Статус"
                value={statusFilter}
                name="statusFilter"
                placeholder="Показать все"
                onChange={handleChange}
            />
            <FilterProject
                clientId={clientId}
                label="Проект"
                placeholder="Выберите из списка"
                name="projectIds"
                onChange={handleChange}
                search
                projectIdsFilter={projectIds}
                isSearchClear={isSearchClear}
                isActiveStyle={false}
                onSubmit={search}
            />
            <FilterObject
                clientId={clientId}
                name="objectIds"
                placeholder="Выберите из списка"
                onChange={handleChange}
                projectIdsFilter={projectIds}
                objectIdsFilter={objectIds}
                isSearchClear={isSearchClear}
                isActiveStyle={false}
                onSubmit={search}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="fromDateFilter"
                endFieldName="toDateFilter"
                className={element("period")}
                value={{fromDateFilter, toDateFilter}}
                label="Дата создания"
                onChange={handleChange}
            />
            <NmInputV2
                size="lg"
                value={nameFilter}
                name="registryNameFilter"
                className={element("name")}
                label="Название реестра"
                onChange={handleChange}
            />
            <NmInputV2
                size="lg"
                value={creatorFioFilter}
                name="creatorFioFilter"
                className={element("name")}
                label="Ответственный"
                onChange={handleChange}
            />
        </NmForm>
    );
};

PatentsListFilter.propTypes = {
    search: PropTypes.func,
    clear: PropTypes.func,
    handleChange: PropTypes.func,
    nameFilter: PropTypes.string,
    statusFilter: PropTypes.string,
    fromDateCreateFilter: PropTypes.string,
    toDateCreateFilter: PropTypes.string,
};
export default withTranslation()(PatentsListFilter);