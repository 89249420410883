import React from "react";
import {isEmpty, uniqWith} from "lodash";

import Text from "../components/ActualComponents/Text";

import {COLOR} from "../constants/color";
import {CONTRACTOR_FIELD} from "../constants/contractor";
import {DADATA_LEVELS} from "../constants/dadata";

const DADATA_FIAS_LEVEL = {
    COUNTRY: "0", // страна
    REGION: "1", // регион
    DISTRICT: "3", // район
    CITY: "4", // город
    CITY_DISTRICT: "5", // район города
    SETTLEMENT: "6", // населенный пункт
    STREET: "7", // улица
    HOUSE: "8", // квартира
    APARTMENT: "9", // квартира
    PLANNING_STRUCTURE: "65", // планировочная структура
    FOREIGN_OR_EMPTY: "-1", // иностранный или пустой
};

// соотношение addressType с полем выбора фиас id(для адресов с бэка)
const ID_BY_ADDRESS_TYPE = {
    REGION: "regionId",
    AREA: "areaId",
    CITY: "cityId",
    SETTLEMENT: "settlementId",
    PLANNING_STRUCTURE: "planningStructureId",
};

/***
 * По заданной схеме фильтрует результаты по уровню детализации
 * https://dadata.ru/api/clean/address/
 * fias_level - Уровень детализации, до которого адрес найден в ФИАС
 * 0 — страна
 * 1 — регион
 * 3 — район
 * 4 — город
 * 5 — район города
 * 6 — населенный пункт
 * 7 — улица
 * 8 — дом
 * 9 - квартира
 * 65 — планировочная структура
 * 90 — доп. территория
 * 91 — улица в доп. территории
 * -1 — иностранный или пустой
 * @param suggestions
 * @param schema
 */
export const removeAddressByFiasLevel = (suggestions, schema = []) => (
    suggestions.filter(({data}) => !schema.includes(data.fias_level))
);

// для перевода кода фиаса (уровня детализации) в удобочитаемый вид, для использования в конфигах схем адресов
const FIAS_LEVEL_TRANSLATE = {
    [DADATA_FIAS_LEVEL.REGION]: "region",
    [DADATA_FIAS_LEVEL.DISTRICT]: "area",
    [DADATA_FIAS_LEVEL.CITY]: "city",
    // 5: "cityDistrict",
    [DADATA_FIAS_LEVEL.SETTLEMENT]: "settlement",
    [DADATA_FIAS_LEVEL.STREET]: "street",
    [DADATA_FIAS_LEVEL.HOUSE]: "house",
    [DADATA_FIAS_LEVEL.PLANNING_STRUCTURE]: "planningStructure",
};

// дефолтные настройки
const DEFAULT_SETTINGS = {
    house: ["house", "street", "city"],
    street: ["street", "city"],
    region: ["area", "region"],
    area: ["area", "region"],
    city: ["city", "area", "region"],
    settlement: ["settlement", "city", "area", "region"],
    planningStructure: ["settlement", "city", "area", "region"],
};
// фиас id городов федерального значения
const FEDERAL_CITIES_IDS = [
    "0c5b2444-70a0-4932-980c-b4dc0d3f02b5", // Москва
    "c2deb16a-0330-4f05-821f-1d09c93331e6", // Санкт-Петербург
    "6fdecb78-893a-4e3f-a5ba-aa062459463b", // Севастополь
];

// хелпер для работы с данными для dadata приходящими с бэка
export const getFiasIdByAddressType = (data) => {
    const {addressType} = data;

    return data[ID_BY_ADDRESS_TYPE[addressType]];
};

/***
 * по схеме адреса собирает адрес в строку
 * @param schema - схема адреса
 * @param data - данные с полями для адреса
 * @returns {*}
 */
const getAddressBySchema = (schema = [], data) => {
    const addressArray = schema
        .map(key => {
            return data[key];
        })
        .filter(Boolean);

    const filtered = addressArray
        .filter((addressStringInArray) => {
            return addressStringInArray && addressStringInArray.length !== 0;
        });

    return filtered.join(", ");
};

export const getDadataAddress = (item, schema = DEFAULT_SETTINGS) => {
    const {
        // Регион
        region_fias_id,
        region_with_type,
        // Район в регионе
        area_fias_id,
        area_with_type,
        // Город
        city_fias_id,
        city_with_type,
        // Район города
        //city_district_fias_id,
        //city_district_with_type,
        // Населенный пункт
        settlement_fias_id,
        settlement_with_type,
        // Улица
        street_fias_id,
        street_with_type,
        // Дом
        house_fias_id,
        house_type,
        house,
    } = item;

    const address = {
        region: isEmpty(region_fias_id) ? [] : [region_with_type],
        area: isEmpty(area_fias_id) ? [] : [area_with_type],
        city: isEmpty(city_fias_id) ? [] : [city_with_type],
        settlement: isEmpty(settlement_fias_id) ? [] : [settlement_with_type],
        //cityDistrict:  isEmpty(city_district_fias_id) ? [] : [city_district_with_type],
        street: isEmpty(street_fias_id) ? [] : [street_with_type],
        house: isEmpty(house_fias_id) ? [] : [`${house_type} ${house}`],
    };

    if (item.fias_level === DADATA_FIAS_LEVEL.REGION && FEDERAL_CITIES_IDS.includes(item.region_fias_id)) {
        return region_with_type;
    }

    const levelSchema = schema[FIAS_LEVEL_TRANSLATE[item.fias_level]];

    if (levelSchema) {
        return getAddressBySchema(levelSchema, address);
    }

    return "";
};

// TODO скомпоновать getDadataAddressObj и getDadataAddress один метод
export const getDadataAddressObj = (item, reverseAddress = false) => {
    const {
        regionId,
        regionName,
        regionType,
        addressType,
        areaId,
        areaName,
        areaType,
        cityId,
        cityName,
        cityType,
        settlementId,
        settlementName,
        settlementType,
        planningStructureId,
        planningStructureName,
        planningStructureType,
        houseId,
        houseName,
        houseType,
        flatId,
        flatName,
        flatType,
        streetId,
        streetName,
        streetType,
    } = item;

    const _region = FEDERAL_CITIES_IDS.includes(regionId) ? [`${regionType} ${regionName}`] : [`${regionName} ${regionType}`];
    const region = isEmpty(regionId) ? [] : _region;
    const area = isEmpty(areaId) ? [] : [`${areaName} ${areaType}`];
    const city = isEmpty(cityId) ? [] : [`${cityType} ${cityName}`];
    const settlement = isEmpty(settlementId) ? [] : [`${settlementType} ${settlementName}`];
    const planningStructure = isEmpty(planningStructureId) ? [] : [`${planningStructureType} ${planningStructureName}`];
    const house = isEmpty(houseId) ? [] : [`${houseType} ${houseName}`];
    const flat = isEmpty(flatId) ? [] : [`${flatType} ${flatName}`];
    const street = isEmpty(streetId) ? [] : [`${streetType} ${streetName}`];

    if (addressType === "REGION" && FEDERAL_CITIES_IDS.includes(item.regionId)) {
        return {
            text: `${regionType} ${regionName}`,
            key: regionId,
        };
    }

    const getAddress = (...items) => {
        return reverseAddress ? items.reverse().filter(({length}) => length !== 0).join(", ") :
            items.filter(({length}) => length !== 0).join(", ");
    };

    switch (item.addressType) {
    case DADATA_LEVELS.REGION:
        return {
            text: getAddress(region),
            key: regionId,
        };
    case DADATA_LEVELS.AREA:
        return {
            text: getAddress(area, region),
            key: areaId,
        };
    case DADATA_LEVELS.CITY:
        return {
            text: getAddress(city, region),
            key: cityId,
        };
    case DADATA_LEVELS.SETTLEMENT:
        return {
            text: getAddress(settlement, area, region),
            key: settlementId,
        };
    case DADATA_LEVELS.PLANNING_STRUCTURE:
        return {
            text: getAddress(planningStructure, settlement, city, area, region),
            key: settlementId,
        };
    case DADATA_LEVELS.STREET:
        return {
            text: getAddress(street, planningStructure, settlement, city, area, region),
            key: streetId,
        };
    case DADATA_LEVELS.HOUSE:
        return {
            text: getAddress(house, street, planningStructure, settlement, city, area, region),
            key: houseId,
        };
    case DADATA_LEVELS.FLAT:
        return {
            text: getAddress(flat, house, street, planningStructure, settlement, city, area, region),
            key: flatId,
        };
    default:
        return {};
    }
};

export const getWorkAddressInfoArr = (workAddressInfo, departureAddressesInfo) => {
    if (isEmpty(workAddressInfo)) {
        return null;
    }
    let result = [];

    result.push(getDadataAddressObj(workAddressInfo));

    if (!isEmpty(departureAddressesInfo)) {
        result = result.concat(Object.entries(departureAddressesInfo)
            .map(([, value]) => {
                return getDadataAddressObj(value);
            })
            .sort((a, b) => a.text > b.text ? 1 : -1),
        );
    }
    return result;
};

// хелпер для формирования значения в фильтре "Город"
export const dadataValueCityFormatter = (data) => {
    const scheme = {
        city: ["city", "region"],
        settlement: ["settlement", "area", "region"],
        area: ["area", "region"],
    };

    return getDadataAddress(data, scheme);
};

// хелпер для фильтра значений дадаты от города до квартиры
// NmDadataInput filter={dadataFromCityToApartmentFilter}
export const dadataFromCityToApartmentFilter = (suggestion = {}) => {
    return (
        suggestion.data.fias_id !== null &&
            ![
                DADATA_FIAS_LEVEL.COUNTRY,
                DADATA_FIAS_LEVEL.REGION,
                //убрал условие, т.к в возвращаемой выборке встречаются города с fias_level = "3"
                //(пример - Московская обл, г Ногинск)
                //DADATA_FIAS_LEVEL.DISTRICT,
                DADATA_FIAS_LEVEL.PLANNING_STRUCTURE,
                DADATA_FIAS_LEVEL.FOREIGN_OR_EMPTY,
            ].includes(suggestion.data.fias_level)
    ) ||
        (
            suggestion.data.fias_level === DADATA_FIAS_LEVEL.REGION &&
            FEDERAL_CITIES_IDS.includes(suggestion.data.fias_id)
        );
};

/***
 * Хелпер для фильтрации значений дадаты
 * @param suggestion
 * @returns {boolean|boolean}
 */
export const dadataValueCityFilter = (suggestion = {}) =>
    suggestion.data.fias_id !== null &&
    suggestion.data.fias_level !== "5" &&
    // Планировачные структуры не нужны
    suggestion.data.fias_level !== "65";

export const dadataValueUniqCitySettlementFilter = (suggestion = {}) =>
    suggestion.data.fias_id !== null &&
    (
        [
            // добавлено условие, т.к в возвращаемой выборке встречаются города с fias_level = "3"
            DADATA_FIAS_LEVEL.DISTRICT,
            DADATA_FIAS_LEVEL.CITY,
            DADATA_FIAS_LEVEL.SETTLEMENT,
        ].includes(suggestion.data.fias_level)
        || (
            suggestion.data.fias_level === DADATA_FIAS_LEVEL.REGION
            && FEDERAL_CITIES_IDS.includes(suggestion.data.fias_id)
        )
    );

// хелпер для формирования options для DadataMultiselect из ответа dadata
export const getOptions = (params) => {
    const {
        suggestions,
        formatter,
        optionsFormatter,
        filter,
    } = params;

    const filtered = suggestions.filter(suggestionsItem => {
        // Общий фильтр
        const isFiltered = suggestionsItem.data.fias_id !== null &&
            // Планировачные структуры не нужны
            suggestionsItem.data.fias_level !== "65";

        if (filter) {
            return isFiltered
                && filter(suggestionsItem);
        }

        return isFiltered;
    });

    const formatted = optionsFormatter ?
        optionsFormatter(filtered, formatter) :
        filtered.map(({data, value}) => ({
            text: formatter ? formatter(data) : value,
            value: data.fias_id,
            key: data.fias_id,
            geoInfo: {geoLat: data.geo_lat, geoLon: data.geo_lon},
        }));

    return uniqWith(formatted, (arrVal, othVal) => {
        return arrVal.key === othVal.key;
    });
};

//toDo: объединить с dadataValueUniqCitySettlementFilter

// хелпер для формирования options с уникальным списком городов и населенных пунктов
export const getOptionsUniqCity = (suggestions, formatter) => {
    return suggestions
        .filter(dadataValueUniqCitySettlementFilter)
        .map(({data}) => {
            const id = data.city_fias_id ? data.city_fias_id : data.settlement_fias_id;
            const defaultText = data.city_with_type ? data.city_with_type : data.settlement_with_type;

            return {
                text: formatter ? formatter(data) : defaultText,
                value: id,
                key: id,
                geoInfo: {geoLat: data.geo_lat, geoLon: data.geo_lon},
            };
        });
};
export const getOptionsFromCityToApartment = (suggestions, formatter) => {
    const options = suggestions.filter(dadataFromCityToApartmentFilter)
        .map(({data, value}) => {
            return {
                text: formatter ? formatter(data) : value,
                value: data.fias_id,
                key: data.fias_id,
                geoInfo: {geoLat: data.geo_lat, geoLon: data.geo_lon},
            };
        });

    return [...new Set(options)];
};

export const getDadataBankFormatter = (item) => {
    const {
        name: {
            payment,
            short,
        },
        address,
        inn,
        bic,
        correspondent_account,
    } = item;

    return <div>
        {payment || short}
        <Text
            level="2"
            color={COLOR.SECONDARY_45}
        >
            {address?.value}
        </Text>
        {
            inn &&
            <Text
                level="2"
                color={COLOR.SECONDARY_45}
            >
                ИНН
                {" "}
                {inn}
            </Text>
        }
        {
            bic &&
            <Text
                level="2"
                color={COLOR.SECONDARY_45}
            >
                БИК
                {" "}
                {bic}
            </Text>
        }
        {
            correspondent_account &&
            <Text
                level="2"
                color={COLOR.SECONDARY_45}
            >
                Корр. счет
                {" "}
                {correspondent_account}
            </Text>
        }
    </div>;
};

// хелпер для формирования options для NmDadataBank из ответа dadata
export const getBankOptions = (suggestions, formatter) => {
    return suggestions.map(({data, value}) => ({
        text: formatter ? formatter(data) : value,
        customContent: `${data.name.payment}, ${data.address.value}, ИНН: ${data.inn}, БИК: ${data.bic}`,
        value: data.fias_id,
        key: data.fias_id,
        bankInfo: {
            [CONTRACTOR_FIELD.BANK_NAME]: value,
            [CONTRACTOR_FIELD.BANK_ADDRESS]: data.address.value,
            [CONTRACTOR_FIELD.BIC]: data.bic,
            [CONTRACTOR_FIELD.INN]: data.inn,
            [CONTRACTOR_FIELD.KS]: data.correspondent_account,
        },
    }));
};