import {createSelector} from "reselect";

export const clientSystemPropertiesSelector = state => state.bff.clients.systemProperties;

export const clientSystemPropertiesDataSelector = createSelector(
    clientSystemPropertiesSelector,
    ({systemProperties}) => systemProperties,
);
export const clientSystemPropertiesProgressSelector = createSelector(
    clientSystemPropertiesSelector,
    ({progress}) => progress,
);
export const clientSystemPropertiesProgressActionSelector = createSelector(
    clientSystemPropertiesSelector,
    ({progressAction}) => progressAction,
);