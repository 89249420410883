import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST,
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS,
    BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
    BFF_CROWD_TASKS_ADD_DRAFT_REQUEST,
    BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS,
    BFF_CROWD_TASKS_CLOSE_ERROR,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_CLOSE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_SUCCESS,
    BFF_CROWD_TASKS_DELETE_ERROR,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_DELETE_REQUEST,
    BFF_CROWD_TASKS_DELETE_SUCCESS,
    BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
    BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST,
    BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS,
    BFF_CROWD_TASKS_EXTEND_ERROR,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_EXTEND_REQUEST,
    BFF_CROWD_TASKS_EXTEND_SUCCESS,
    BFF_CROWD_TASKS_GET_BY_ID_ERROR,
    BFF_CROWD_TASKS_GET_BY_ID_REQUEST,
    BFF_CROWD_TASKS_GET_BY_ID_SUCCESS,
    BFF_CROWD_TASKS_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_PUBLISH_ERROR,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_SUCCESS,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS,
    BFF_CROWD_TASKS_UPDATE_ERROR,
    BFF_CROWD_TASKS_UPDATE_REQUEST,
    BFF_CROWD_TASKS_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/crowd/tasks";

const getPageCrowdTasks = function* ({payload}) {
    try {
        const {
            url,
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/admin/page
const getPageAdminCrowdTasks = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/admin/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/add/contractor-search
const addContractorSearchCrowdTask = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            getError = () => {},
            isDuplicate,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add${isDuplicate ? "/duplicate" : ""}/contractor-search`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            getError(result);

            yield put({
                type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/add/draft
const addDraftCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            isDuplicate,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/add${isDuplicate ? "/duplicate" : ""}/draft`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/params/update
const updateCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/params/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_UPDATE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/params/update/and-publish
const updateAndPublishCrowdTask = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/params/update/and-publish`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/publish
const publishCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            getError = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/publish`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            getError(result);

            yield put({
                type: BFF_CROWD_TASKS_PUBLISH_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/publish/multiple
const publishMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/publish/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/archive/add
const archiveCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/archive/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/archive/add/multiple
const archiveMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/archive/add/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/archive/remove
const unarchiveCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/archive/remove`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/close
const closeCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/close`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_CLOSE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_CLOSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_CLOSE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/close/multiple
const closeMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/close/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/delete
const deleteCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/delete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/delete/multiple
const deleteMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/delete/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/get
// Получение страницы заданий
const getByIdCrowdTask = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_GET_BY_ID_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/totalAmount
// Получение сумм по заданиям
const getTotalAmountCrowdTasks = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/totalAmount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR,
        });
    }
};

// GET /bff/adm/crowd/tasks/deposit-state
// Детализация по счету для выплат исполнителям с типом налогообложения НПД
const getDepositStateCrowdTasks = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/deposit-state`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/task/extend-work-end-date
// Продление периода выполнения задания
const extendCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/task/extend-work-end-date`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_EXTEND_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_EXTEND_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_EXTEND_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/extend-work-end-date/multiple
// Массовое продление периода выполнения заданий
const extendMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/extend-work-end-date/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASKS_GET_PAGE_REQUEST, getPageCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST, addContractorSearchCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ADD_DRAFT_REQUEST, addDraftCrowdTask),
        takeEvery(BFF_CROWD_TASKS_UPDATE_REQUEST, updateCrowdTask),
        takeEvery(BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST, updateAndPublishCrowdTask),
        takeEvery(BFF_CROWD_TASKS_PUBLISH_REQUEST, publishCrowdTask),
        takeEvery(BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST, publishMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST, archiveCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST, archiveMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST, unarchiveCrowdTask),
        takeEvery(BFF_CROWD_TASKS_CLOSE_REQUEST, closeCrowdTask),
        takeEvery(BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST, closeMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_DELETE_REQUEST, deleteCrowdTask),
        takeEvery(BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST, deleteMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_GET_BY_ID_REQUEST, getByIdCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST, getPageAdminCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST, getTotalAmountCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST, getDepositStateCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_EXTEND_REQUEST, extendCrowdTask),
        takeEvery(BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST, extendMultipleCrowdTask),
    ]);
}