import React, {createContext} from "react";

export const CrowdTaskEditContext = createContext({});

const CrowdTaskEditContextProvider = (props) => {
    const {
        clientId,
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        calculatorData,
        children,
    } = props;

    return (
        <CrowdTaskEditContext.Provider
            value={{
                clientId,
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                calculatorData,
            }}
        >
            {children}
        </CrowdTaskEditContext.Provider>
    );
};

export default CrowdTaskEditContextProvider;