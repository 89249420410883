import {ORDER_CREATION_MODE} from "../../../../constants/order";

export const getOrderEditTemplateId = (
    formData,
    order,
    creatingMode,
) => {
    if (creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE) {
        return order.orderId;
    }

    if (formData.isTemplateType && formData.templateId) {
        return formData.templateId;
    }

    return null;
};