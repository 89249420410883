import {isEmpty} from "lodash";

import {getFileNameWithoutExtension} from "../../../../../utils/file";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../../constants";

const appendFile = (params) => {
    const {
        formData,
        templateFile,
    } = params;

    if (!isEmpty(templateFile)) {
        const [file] = templateFile;

        formData.append("templateFile", file);
    }
};

const appendFileName = (params) => {
    const {
        form,
        templateFile,
    } = params;

    if (!isEmpty(templateFile)) {
        const [file] = templateFile;
        const {name} = file;

        return {
            ...form,
            name: form.documentType === TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS ?
                form.name :
                getFileNameWithoutExtension(name),
        };
    }

    return form;
};

export const getTemplatesCreateRequestData = ({form}) => {
    const {
        documentType,
    } = form;
    const {
        templateFile,
        ...otherData
    } = form;

    const formData = new FormData();

    appendFile({
        formData,
        templateFile,
    });

    const resultData = appendFileName({
        form: otherData,
        templateFile,
    });

    if (documentType === TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION) {
        const {
            templateFile,
            externalDocument,
            ...reqData
        } = resultData;

        formData.append("metadata", new Blob([JSON.stringify(reqData)], {
            type: "application/json",
        }));

        return formData;
    }

    if (documentType === TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK) {
        const {
            externalDocument,
            ...reqData
        } = resultData;

        formData.append("metadata", new Blob([JSON.stringify(reqData)], {
            type: "application/json",
        }));

        return formData;
    }

    if (documentType === TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS) {
        const {
            orderContractPaymentType,
            templateFile,
            orderKind,
            externalDocument,
            ...reqData
        } = resultData;

        formData.append("metadata", new Blob([JSON.stringify(reqData)], {
            type: "application/json",
        }));

        return formData;
    }

    if (documentType === TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT) {
        const {
            orderKind,
            ...reqData
        } = resultData;

        formData.append("metadata", new Blob([JSON.stringify(reqData)], {
            type: "application/json",
        }));

        return formData;
    }

    if (documentType === TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION) {
        const {
            orderKind,
            externalDocument,
            templateFile,
            orderContractPaymentType,
            ...reqData
        } = resultData;

        formData.append("metadata", new Blob([JSON.stringify(reqData)], {
            type: "application/json",
        }));

        return formData;
    }

    const {
        orderKind,
        externalDocument,
        ...reqData
    } = resultData;

    formData.append("metadata", new Blob([JSON.stringify(reqData)], {
        type: "application/json",
    }));

    return formData;
};