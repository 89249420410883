import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useDepositValuesData} from "./useDepositValuesData";

import {depositValuesProgressSelector, getClientDepositValuesV2} from "../../../ducks/deposit";

export const useDepositValuesFetch = ({isCivil, clientId, projectId, objectId, isFetchValues = true}) => {
    const {depositValues} = useDepositValuesData({isCivil});
    const isLoading = useSelector(depositValuesProgressSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isFetchValues) {
            fetchValues();
        }
    }, []);

    const fetchValues = () => {
        dispatch(getClientDepositValuesV2({
            isCivil,
            clientId,
            projectId,
            objectId,
        }));
    };

    return {
        depositValues,
        isLoading,
    };
};