export const STATUS_TASK_PAYMENT = {
    CREATED: {
        VALUE: "CREATED",
    },
    VALIDATION: {
        VALUE: "VALIDATION",
        TEXT: "Проверка",
        COLOR: "light-green",
    },
    VALIDATION_FAILED: {
        VALUE: "VALIDATION_FAILED",
        TEXT: "Проверка не пройдена",
        COLOR: "red",
    },
    FOR_CONTRACTOR_APPROVE: {
        VALUE: "FOR_CONTRACTOR_APPROVE",
        TEXT: "На подтверждении исполнителем",
        COLOR: "gray",
    },
    CONTRACTOR_APPROVED: {
        VALUE: "CONTRACTOR_APPROVED",
    },
    FOR_NAIMIX_APPROVE: {
        VALUE: "FOR_NAIMIX_APPROVE",
        TEXT: "На подтверждении Наймикс",
        COLOR: "gray",
    },
    NAIMIX_APPROVED: {
        VALUE: "NAIMIX_APPROVED",
    },
    APPROVE_ERROR: {
        VALUE: "APPROVE_ERROR",
        TEXT: "Ошибка подтверждения",
        COLOR: "red",
    },
    FNS_DECLARATION: {
        VALUE: "FNS_DECLARATION",
        TEXT: "Декларирование дохода НПД в ФНС",
        COLOR: "gray",
    },
    FNS_DECLARATION_ERROR: {
        VALUE: "FNS_DECLARATION_ERROR",
        TEXT: "Ошибка выполнения",
        COLOR: "red",
    },
    CANCELLED: {
        VALUE: "CANCELLED",
        TEXT: "Отменен",
        COLOR: "gray",
    },
    CLIENT_REJECTED: {
        VALUE: "CLIENT_REJECTED",
        TEXT: "Отклонено заказчиком",
        COLOR: "red",
    },
    CONTRACTOR_REJECTED: {
        VALUE: "CONTRACTOR_REJECTED",
        TEXT: "Отклонено исполнителем",
        COLOR: "red",
    },
    SUCCESSFUL: {
        VALUE: "SUCCESSFUL",
        TEXT: "Выполнен",
        COLOR: "green",
    },
};

export const STATUS_TASK_PAYMENT_OPTIONS = [
    {
        key: STATUS_TASK_PAYMENT.VALIDATION.VALUE,
        text: STATUS_TASK_PAYMENT.VALIDATION.TEXT,
        value: STATUS_TASK_PAYMENT.VALIDATION.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
        text: STATUS_TASK_PAYMENT.VALIDATION_FAILED.TEXT,
        value: STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
        text: STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.TEXT,
        value: STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE,
        text: STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.TEXT,
        value: STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
        text: STATUS_TASK_PAYMENT.APPROVE_ERROR.TEXT,
        value: STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.FNS_DECLARATION.VALUE,
        text: STATUS_TASK_PAYMENT.FNS_DECLARATION.TEXT,
        value: STATUS_TASK_PAYMENT.FNS_DECLARATION.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
        text: STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.TEXT,
        value: STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.CANCELLED.VALUE,
        text: STATUS_TASK_PAYMENT.CANCELLED.TEXT,
        value: STATUS_TASK_PAYMENT.CANCELLED.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
        text: STATUS_TASK_PAYMENT.CLIENT_REJECTED.TEXT,
        value: STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
        text: STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.TEXT,
        value: STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
    },
    {
        key: STATUS_TASK_PAYMENT.SUCCESSFUL.VALUE,
        text: STATUS_TASK_PAYMENT.SUCCESSFUL.TEXT,
        value: STATUS_TASK_PAYMENT.SUCCESSFUL.VALUE,
    },
];

export const TASK_PAYMENTS_SORT_TYPE = {
    CONTRACTOR_NPD_PAYMENTS: "CONTRACTOR_NPD_PAYMENTS",
};

export const STATUS_TASKS_PAYMENT_CONTRACTOR_INCOME_AWAITING = {
    [STATUS_TASK_PAYMENT.VALIDATION.VALUE]: STATUS_TASK_PAYMENT.VALIDATION.TEXT,
    [STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE]: STATUS_TASK_PAYMENT.VALIDATION_FAILED.TEXT,
};

export const STATUS_TASKS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS = {
    [STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE]: STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.TEXT,
    [STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE]: STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.TEXT,
    [STATUS_TASK_PAYMENT.FNS_DECLARATION.VALUE]: STATUS_TASK_PAYMENT.FNS_DECLARATION.TEXT,
    [STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE]: STATUS_TASK_PAYMENT.APPROVE_ERROR.TEXT,
    [STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE]: STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.TEXT,
};

export const STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS = {
    FORMING: {
        VALUE: "FORMING",
        TEXT: "Формируется",
    },
    ERROR: {
        VALUE: "ERROR",
        TEXT: "Ошибка",
    },
    PROCESSING: {
        VALUE: "PROCESSING",
        TEXT: "В процессе",
    },
    DONE: {
        VALUE: "DONE",
        TEXT: "Завершено",
    },
};

export const STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS_OPTIONS = [
    {
        key: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.FORMING.VALUE,
        text: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.FORMING.TEXT,
        value: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.FORMING.VALUE,
    },
    {
        key: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.ERROR.VALUE,
        text: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.ERROR.TEXT,
        value: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.ERROR.VALUE,
    },
    {
        key: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.PROCESSING.VALUE,
        text: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.PROCESSING.TEXT,
        value: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.PROCESSING.VALUE,
    },
    {
        key: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.DONE.VALUE,
        text: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.DONE.TEXT,
        value: STATUS_CROWD_REGISTRY_ACT_ENTRY_STATUS.DONE.VALUE,
    },
];