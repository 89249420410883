import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_UPDATE_STORE,
} from "./actions";

export const getRecruitmentVacancyCandidatesResponses = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancyCandidatesResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyCandidatesResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancyCandidatesResponsesStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_UPDATE_STORE,
        payload,
    };
};