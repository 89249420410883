import {CSSProperties, useEffect, useLayoutEffect, useRef, useState} from "react";

import useLatest from "./useLatest";

export type UseInfiniteScrollProps = {
    size: number;
    shouldFetchMore?: boolean;
    onNext?: () => void;
};

export type UseInfiniteScrollResult<E extends HTMLElement> = {
    ref: (element: E | null) => void;
    style: CSSProperties;
};

export const useInfiniteScroll = <E extends HTMLElement>({
    size,
    shouldFetchMore,
    onNext,
}: UseInfiniteScrollProps): UseInfiniteScrollResult<E> => {
    const [element, setElement] = useState<E | null>(null);

    const latestShouldFetchMore = useLatest(shouldFetchMore);
    const latestOnNext = useLatest(onNext);

    const observer = useRef<IntersectionObserver>();

    useEffect(() => {
        observer.current = new IntersectionObserver(
            entries => {
                const entry = entries[entries.length - 1];

                if (entry?.isIntersecting && latestShouldFetchMore.current) {
                    return latestOnNext.current?.();
                }
            },
            {threshold: 0},
        );
    }, [latestOnNext, latestShouldFetchMore]);

    useLayoutEffect(() => {
        if (element) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            observer.current?.observe(element);

            return () => observer.current?.unobserve(element);
        }
    }, [element, observer]);

    useEffect(() => () => observer.current?.disconnect(), [observer]);

    return {
        ref: setElement,
        style: {
            height: size,
            width: "100%",
            position: "relative",
            marginTop: -size,
            zIndex: -1,
        },
    };
};