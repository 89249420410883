import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";

import {getPageSystemTransactions} from "../../../ducks/bff/system-transactions/actionCreators";
import {getClientUserRestrictionsList} from "../../../ducks/clientUserRestrictions";

export const useSystemTransactionsFetch = ({pageNum, pageSize, filter}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessSystemTransactions,
            ],
        }));
    }, []);

    useEffect(() => {
        onFetchList();
    }, [pageNum, pageSize, filter]);

    const onFetchList = () => {
        dispatch(getPageSystemTransactions(
            {
                pageNum,
                pageSize,
                ...filter,
            },
        ));
    };

    return {
        onFetchList,
    };
};