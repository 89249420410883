import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink/index";
import NmPage from "../../../../components/NmPage/index";
import {NmPageHeader} from "../../../../components/NmPageHeader/index";
import {withPageData} from "../../../../components/withPageData/index";
import {styleLargeLink} from "../../../../styles/inline";
import ContractorListInfo from "../contractor-list-info";

import {isNMUsers} from "../../../../utils/access";
import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {CONTRACTOR_STATUS} from "../../../../constants/contractor";
import {headersNmContractorList} from "../../../../constants/headersTable";
import {LINK_CONTRACTOR_PERSONAL_INFO, LINK_CONTRACTOR_PROFILE} from "../../../../constants/links";
import {isUserFromNm, RNKO} from "../../../../constants/roles";

import {
    contractorEdmStatusesSelector,
    contractorStatusDictSelector,
    contractorStatusOptionsSelector,
    getCitizenshipOptionsSelector,
    getCitizenshipSelector,
    getContractorStatusDict,
} from "../../../../ducks/contractor";
import {
    specialitiesAllV2DictSelector,
    specialitiesAllV2OptionsSelector,
} from "../../../../ducks/speciality";

import PropTypes from "prop-types";

import "./style.sass";

class AbstractInviteContractorsPage extends Component {
    static propTypes = {
        emptyData: PropTypes.element,
        filter: PropTypes.element,
        renderActions: PropTypes.func,
        pageHeader: PropTypes.string,
        onSelectedRows: PropTypes.func,
    };

    static defaultProps = {
        emptyData: null,
        renderActions: () => {
        },
        pageHeader: "",
        filter: null,
    };

    constructor(props) {
        super(props);

        const {
            pageNum,
            pageSize,
        } = props;

        this.state = {
            pageSize: pageSize || 25,
            pageNum: pageNum || 1,
            pageSizes: [25, 50, 100],
            headers: headersNmContractorList,
            contractorsPossibility: [],
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getContractorStatusDict,
        } = this.props;

        this.fetchList();
        getContractorStatusDict();
    }

    fetchList = () => {
        const {
            fetchListFn,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        fetchListFn(pageNum, pageSize);

    };

    updateState = (newStateFields, cbFn = () => {
    }) => {
        return () => {
            this.setState({
                ...newStateFields,
            }, cbFn);
        };
    };

    handleChange = (updatedState) => {
        return () => {
            this.setState({
                ...updatedState,
            });
        };
    };

    submitFilter = () => {
        this.setState({pageNum: 1}, this.fetchList);
    };

    getContractorFullName = (contractor) => {
        const {
            fullName,
            firstName,
            lastName,
            patronymic,
            status,
        } = contractor;

        if (!isEmpty(firstName) && (isUserFromNm(this.role) || ![CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status))) {
            return `${lastName} ${firstName} ${patronymic || ""}`;
        }
        return fullName;
    };

    mapTableData = () => {
        const {
            list,
            actions,
        } = this.props;

        return list.map(item => {
            return {
                ...item,
                contentRow: this.renderContractorInfo(item),
                isSelected: item.isSelected,
                avatar: <Avatar
                    addedToMyClientGroup={item.addedToMyClientGroup}
                />,
            };
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    renderLink = (contractor) => {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            filterData,
        } = this.props;

        const {
            contractorId,
            status,
        } = contractor;

        let link = LINK_CONTRACTOR_PROFILE;

        if (RNKO === this.role || ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status) && isNMUsers())) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        link = link.replace(":contractorId", contractorId);

        const contractorName = this.getContractorFullName(contractor);

        return (
            <ExtLink
                extData={
                    {}
                }
                pageData={{pageNum, pageSize}}
                filterData={
                    filterData
                }
                historyEnabled
                style={styleLargeLink}
                to={link}
            >
                {contractorName}
            </ExtLink>
        );
    };

    renderContractorInfo = (contractor) => {
        const {
            t,
            citizenshipList,
            contractorStatusDict,
            contractorEdmStatusDict,
            specialityOptions,
            specialityDict,
            actions,
        } = this.props;

        const {
            contractorsPossibility,
        } = this.state;

        return (
            <ContractorListInfo
                contractor={contractor}
                role={this.role}
                renderLinkFnc={this.renderLink}
                t={t}
                contractorsPossibility={contractorsPossibility}
                citizenshipList={citizenshipList}
                specialityDict={specialityDict}
                contractorStatusDict={contractorStatusDict}
                contractorEdmStatusDict={contractorEdmStatusDict}
                specialityOptions={specialityOptions}
                actions={actions(contractor)}
                showPaymentInfo={false}
            />
        );
    };

    renderTable() {
        const {
            list,
            progress,
            emptyData,
            onSelectedRows,
        } = this.props;

        if (isEmpty(list) && !progress) {
            return (emptyData);
        }

        return (
            <CheckboxList
                rows={this.mapTableData()}
                onSelectedRows={onSelectedRows}
            />
        );
    };

    render() {
        const {
            pageSize,
            pageNum,
            pageSizes,
        } = this.state;

        const {
            totalCount,
            progress,
            totalPages,
            pageHeader,
            actionBlock,
            handleOnClickBack,
            filter,
        } = this.props;

        return (
            <div
                className="abstract-invite-contractors-page"
            >
                <NmPage
                    className="fluid-flex-grow"
                    header={
                        <NmPageHeader
                            text={pageHeader}
                            totalCount={totalCount}
                            handleOnClickBack={handleOnClickBack}
                        />
                    }

                    controls={
                        actionBlock
                    }
                    filtersBase={
                        filter
                    }
                    typeFilter="vertical"
                    isLoaded={progress}
                    currentPageSize={pageSize}
                    currentPageNum={pageNum}
                    pageSizes={pageSizes}
                    totalPages={totalPages}
                    onChangePageSize={this.handleChangePageSize}
                    onPaginationChange={this.handlePaginationChange}
                    totalCount={totalCount}
                >
                    {this.renderTable()}
                </NmPage>
            </div>
        );
    };
}

export default withPageData(connect(
    state => ({
        contractorStatusDict: contractorStatusDictSelector(state),
        specialityDict: specialitiesAllV2DictSelector(state),
        contractorStatusOptions: contractorStatusOptionsSelector(state),
        contractorEdmStatusDict: contractorEdmStatusesSelector(state),
        citizenshipOptions: getCitizenshipOptionsSelector(state),
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        citizenshipList: getCitizenshipSelector(state),
    }),
    {
        getContractorStatusDict,
    },
)(withTranslation()(AbstractInviteContractorsPage)));