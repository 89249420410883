import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {MediaButtons} from "../../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import {NmPageCardHeader} from "../../../../components/ActualComponents/NmPageCardHeader";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../../components/ActualComponents/Tabs";
import Text from "../../../../components/ActualComponents/Text";
import ExtLink from "../../../../components/ExtLink";
import NmBadge from "../../../../components/NmBadge";
import NmHint from "../../../../components/NmHint";
import NmPageCard from "../../../../components/NmPageCard";
import ViewsCount from "../../../../components/ViewsCount";
import {history} from "../../../../store/configureStore";
import CrowdTaskEditForm from "../components/edit-form";
import CrowdTaskExtendModal from "../components/extend-modal";
import TaskPublishSuccessModal from "../components/task-publish-success-modal";
import CrowdTaskCardRoute from "./route";

import {useModal} from "../../../../hooks/useModal";
import useCrowdTaskCardAction from "./hooks/useAction";
import useCrowdTaskCardFetch from "./hooks/useFetch";

import bem from "../../../../utils/bem";
import dateFormat from "../../../../utils/dateFormat";
import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getTimeFromMins} from "../../../../utils/mathHelper";
import {formatAmount, formatNumber} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CROWD_TASK_STATUS} from "../../../../constants/crowd/task";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_OPERATOR} from "../../../../constants/roles";

import {
    bffCrowdTasksCardProgressSelector,
    bffCrowdTasksCardRichSelector,
    bffCrowdTasksContractorsCountsSelector,
} from "../../../../ducks/bff/crowd/taskCard/selectors";
import {bffCrowdTasksProgressActionSelector} from "../../../../ducks/bff/crowd/tasks/selectors";
import {specialitiesAllV2DictSelector} from "../../../../ducks/speciality";

import "./style.sass";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../../constants/link-params";

const CrowdTaskCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                taskId,
                subpage,
            },
        },
    } = props;

    const card = useSelector(bffCrowdTasksCardRichSelector);
    const progress = useSelector(bffCrowdTasksCardProgressSelector);
    const specialityDict = useSelector(specialitiesAllV2DictSelector);
    const progressAction = useSelector(bffCrowdTasksProgressActionSelector);
    const contractorsCounts = useSelector(bffCrowdTasksContractorsCountsSelector);

    const [block, element] = bem("crowd-task-card");

    const [hideDetails, setHideDetails] = useState(true);

    const registryLink = LINK_CLIENT_CROWD_TASK_REGISTRY.replace(":clientId", clientId);

    const {
        number,
        status,
        viewsCount,
        specialityId,
        workStartDate,
        workEndDate,
        timeForExecutionMinutes,
        projectData,
        objectData,
        applicationTemplateData = {},
        actTemplateData = {},
        contractTemplateData = {},
        networkName,
        productCategory,
        medicalBookRequired,
        driverLicenseRequired,
        driverLicenseRequiredCategories,
        personalVehicleRequired,
        paymentAmount,
        sfaLink,
        name,
        servicesDescription,
        keywordsData,
    } = card;

    const role = ls(USER_ROLE);
    const isVisibleControls = subpage === SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK && ![
        CROWD_TASK_STATUS.COMPLETED.VALUE,
        CROWD_TASK_STATUS.NOT_COMPLETED.VALUE,
    ].includes(status) && ![
        NM_CHIEF_ACCOUNTANT,
        NM_OPERATOR,
    ].includes(role);

    const {
        fetchCard,
    } = useCrowdTaskCardFetch({
        clientId,
        taskId,
        subpage,
    });

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        downloadFile,
        getMediaButtons,
        editFormData,
        setEditFormData,
        successPublishModalData,
        setSuccessPublishModalData,
        extendModalData,
        setExtendModalData,
    } = useCrowdTaskCardAction({
        clientId,
        taskId,
        onOpenConfirm,
        onCloseConfirm,
        fetchCard,
        card,
    });

    const getProjectLink = () => {
        const {
            id,
            value,
        } = projectData || {};

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", id);

        return (
            <ExtLink
                title={value}
                historyEnabled
                to={link}
            >
                {value}
            </ExtLink>
        );
    };

    const getObjectLink = () => {
        const {
            id,
            value,
        } = objectData || {};

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectData?.id)
            .replace(":objectId", id);

        return (
            <ExtLink
                title={value}
                historyEnabled
                to={link}
            >
                {value}
            </ExtLink>
        );
    };

    const openInviteCandidatesPage = () => {
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES
            .replace(":clientId", clientId)
            .replace(":taskId", taskId);

        history.push(link);
    };

    const getTabs = () => {
        return Object.values(SUB_PAGE_CROWD_TASK_CARD)
            .map((item) => {
                const {TEXT, LINK, COUNT_NAME} = item;

                return {
                    name: TEXT,
                    link: LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                        .replace(":clientId", clientId)
                        .replace(":taskId", taskId)
                        .replace(":subpage", LINK),
                    active: subpage === LINK,
                    count: contractorsCounts[COUNT_NAME] || 0,
                };
            });
    };

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={onCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Да"
                cancelButton="Нет"
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderPublishSuccessModal = () => {
        return (
            !isEmpty(successPublishModalData) &&
            <TaskPublishSuccessModal
                data={successPublishModalData}
                onClose={() => setSuccessPublishModalData({})}
            />
        );
    };

    const renderEditForm = () => {
        return (
            editFormData.isOpen &&
            <CrowdTaskEditForm
                clientId={clientId}
                onClose={() => setEditFormData({})}
                editData={editFormData.editData}
                isDuplicate={editFormData.isDuplicate}
                openSuccessPublishModalData={(data) => setSuccessPublishModalData({
                    clientId,
                    ...data,
                })}
            />
        );
    };

    const renderExtendModal = () => {
        return (
            !isEmpty(extendModalData) &&
            <CrowdTaskExtendModal
                data={extendModalData}
                onClose={() => setExtendModalData({})}
                progress={progressAction}
            />
        );
    };

    return (
        <NmPageCard
            header={
                <NmPageCardHeader
                    to={registryLink}
                    content={`Задание №${number || ""}`}
                    status={
                        <div className="flex">
                            <NmBadge
                                className="ms-4"
                                text={CROWD_TASK_STATUS[status]?.TEXT || status}
                                mod={CROWD_TASK_STATUS[status]?.MOD || "gray"}
                            />
                            <ViewsCount
                                className="ms-4"
                                count={viewsCount}
                            />
                        </div>
                    }
                    controls={
                        <MediaButtons
                            size="xl"
                            config={getMediaButtons()}
                        />
                    }
                />
            }
            description={
                <div className="flex flex-wrap col-16">
                    <div className="col-16 col-xl-6">
                        <NmLabelText
                            type="page"
                            label="Вид деятельности"
                            text={specialityDict.get(specialityId)}
                        />
                        <NmLabelText
                            type="page"
                            label="Период выполнения работ"
                            text={`${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`}
                            noWrap={false}
                        />
                        <NmLabelText
                            type="page"
                            label="Время выполнения"
                            text={timeForExecutionMinutes ? getTimeFromMins(timeForExecutionMinutes) : "-"}
                            noWrap={false}
                        />
                        <NmLabelText
                            type="page"
                            label="Сумма задания"
                            text={paymentAmount ? formatAmount(formatNumber(paymentAmount, 2), true) : "-"}
                            noWrap={false}
                        />
                        <NmLabelText
                            type="page"
                            label="Ссылка на анкету задания"
                            text={sfaLink}
                            cursorPointer={true}
                            color="blue"
                            noWrap={false}
                            wrapped={true}
                            inline={true}
                            onClickText={() => openLinkByUrl(sfaLink)}
                        />
                        <NmHint
                            onClick={() => setHideDetails(prevState => !prevState)}
                            isShown={!hideDetails}
                            closeButtonText="Cкрыть детали"
                            openButtonText="Показать детали"
                            buttonClassName={element("hint-text")}
                        >
                            <NmLabelText
                                type="page"
                                label="Проект"
                                text={getProjectLink()}
                                noWrap={false}
                            />
                            <NmLabelText
                                type="page"
                                label="Объект"
                                text={getObjectLink()}
                                noWrap={false}
                            />
                            <NmLabelText
                                type="page"
                                label="Сеть"
                                text={networkName || "-"}
                                noWrap={false}
                            />
                            <NmLabelText
                                type="page"
                                label="Категории продукции"
                                text={productCategory || "-"}
                                inline={true}
                                noWrap={false}
                            />
                            {
                                medicalBookRequired &&
                                <NmLabelText
                                    type="page"
                                    label="Наличие медицинской книжки у исполнителя"
                                    text="Требуется наличие медицинской книжки у исполнителя"
                                    noWrap={false}
                                />
                            }
                            {
                                driverLicenseRequired &&
                                <NmLabelText
                                    type="page"
                                    label="Наличие водительских прав у исполнителя"
                                    text={`Требуется наличие водительских прав у исполнителя категории ${driverLicenseRequiredCategories?.join(", ")}`}
                                    noWrap={false}
                                />
                            }
                            {
                                personalVehicleRequired &&
                                <NmLabelText
                                    type="page"
                                    label="Наличие автомобиля у исполнителя"
                                    text="Требуется наличие автомобиля у исполнителя"
                                    noWrap={false}
                                />
                            }
                            <NmLabelText
                                label="Шаблон договора"
                                type="page"
                                cursorPointer
                                text={contractTemplateData.name || "-"}
                                color="blue"
                                noWrap={false}
                                onClickText={() => {
                                    downloadFile(contractTemplateData.name, contractTemplateData.downloadLink);
                                }}
                            />
                            <NmLabelText
                                type="page"
                                label="Шаблон заявки"
                                cursorPointer
                                text={applicationTemplateData.name || "-"}
                                color="blue"
                                noWrap={false}
                                onClickText={() => {
                                    downloadFile(applicationTemplateData.name, applicationTemplateData.downloadLink);
                                }}
                            />
                            <NmLabelText
                                type="page"
                                label="Шаблон акта"
                                cursorPointer
                                text={actTemplateData.name || "-"}
                                color="blue"
                                noWrap={false}
                                onClickText={() => {
                                    downloadFile(actTemplateData.name, actTemplateData.downloadLink);
                                }}
                            />
                        </NmHint>
                    </div>
                    <div className="col-16 col-xl-6">
                        <Text
                            medium={true}
                            level="4"
                            className="mb-2"
                        >
                            {name}
                        </Text>
                        <Text
                            level="3"
                            className="mb-4"
                        >
                            <NmShowMoreText
                                anchor="green"
                                lines={3}
                                children={servicesDescription}
                                more="Читать полностью"
                            />
                        </Text>
                        <div className="flex flex-wrap col-16">
                            {
                                keywordsData?.map(item => {
                                    return (
                                        <NmBadge
                                            className="me-2 mt-2"
                                            text={item.value}
                                            mod="gray"
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            isLoaded={progress}
        >
            {renderPublishSuccessModal()}
            {renderEditForm()}
            {renderConfirm()}
            {renderExtendModal()}
            <div className="flex flex-content-spaced align-items-end col-16 mb-4 mb-xxl-5">
                <Tabs
                    {...props}
                    className={isVisibleControls ? "col-12" : "col-16"}
                    panes={getTabs()}
                />
                {
                    isVisibleControls &&
                    <MediaButtons
                        config={{
                            renderCount: {
                                mobile: 0,
                                tablet: 1,
                                desktop: 1,
                            },
                            size: "lg",
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        onClick: openInviteCandidatesPage,
                                        children: "Пригласить исполнителей",
                                        color: "light-green",
                                        size: "xl",
                                    },
                                    visible: status === CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE,
                                },
                            ],
                        }}
                    />
                }
            </div>
            <CrowdTaskCardRoute
                clientId={clientId}
                taskId={taskId}
            />
        </NmPageCard>
    );
};

export default CrowdTaskCard;