import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {MediaButtons} from "../../../../../../components/ActualComponents/MediaControls";
import NmAdvancedTooltip from "../../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmLabelText from "../../../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../../../components/ActualComponents/Text";
import Avatar from "../../../../../../components/Avatar";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmHint from "../../../../../../components/NmHint";
import {ReactComponent as InfoIcon} from "../../../../../../images/alert_24.svg";
import {ReactComponent as CheckIcon} from "../../../../../../images/check_bg_v2.svg";
import {ReactComponent as ErrorIcon} from "../../../../../../images/fault-bgV2.svg";
import {ReactComponent as HistoryIcon} from "../../../../../../images/history_24.svg";
import CrowdTaskContractorCard from "../contractor-card";
import CrowdTaskContractorImageBlock from "../image-block";
import {Divider} from "semantic-ui-react";

import {
    addDays,
    convertUtcToLocal,
    formatLocalDate,
    getStartDate,
} from "../../../../../../utils/dateFormat";
import {getTimeFromMins} from "../../../../../../utils/mathHelper";
import {getTimeColor} from "../../../utils/getTimeColor";

import {COLOR} from "../../../../../../constants/color";
import {
    CROWD_TASK_WORKING_CONTRACTOR_STATUS,
    TASK_CONTRACTOR_STATUS_OPTIMUM_FORM,
} from "../../../../../../constants/crowd/task";
import {CROWD_TASK_SFA_TYPE} from "../../../components/edit-form/constants";

import {bffCrowdTasksCardRichSelector} from "../../../../../../ducks/bff/crowd/taskCard/selectors";
import {avatarBase64ImagesListSelector} from "../../../../../../ducks/fileStore";

import {STATUS_TASK_PAYMENT} from "../../../../../../constants/crowd/task-payment";

const CrowdTaskContractorWorkInfoDetail = (props) => {
    const {
        className = "",
        card,
        isCompletedPage = false,
        isRejectedPage = false,
        getTaskMediaControls,
        getOptimumMediaControls,
        getPaymentMediaControls,
        getContractorMediaControls,
        workingContractor,
    } = props;

    const taskCard = useSelector(bffCrowdTasksCardRichSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const [hideOtherPayments, setHideOtherPayments] = useState(true);

    const {
        status,
        contractSigningProposedAtDateTime = "2022-01-23T12:01:08.949671",
        contractSigningDateTime,
        taskWorkStartedDateTime,
        taskWorkFinishedDateTime,
        taskWorkStartedDistanceToObject,
        taskWorkFinishedDistanceToObject,
        taskWorkStartedCheckInPhotoLink,
        taskWorkFinishedCheckOutPhotoLink,
        sfaOptimumIntegrationData = {},
        payments,
    } = card;

    const activePayment = payments?.length ? payments[0] : {};
    const otherPayments = payments?.length ? payments.slice(1) : [];

    const isContractSigningMoreDay = getStartDate(addDays(new Date(contractSigningProposedAtDateTime), 1)).getTime() < getStartDate(new Date()).getTime();

    const renderCheckIcon = ({isCheck, isError, isActive, label, labelLevel = "4", iconSize = 24}) => {
        return (
            <div className="flex align-items-center">
                {
                    isError ?
                        <ErrorIcon
                            color={COLOR.NEGATIVE_100}
                            width={iconSize}
                            height={iconSize}
                        /> :
                        <CheckIcon
                            color={isCheck ? COLOR.PRIMARY_100 : COLOR.SECONDARY_10}
                            width={iconSize}
                            height={iconSize}
                        />

                }
                <Text
                    className="ms-2"
                    level={labelLevel}
                    color={
                        !isCheck &&
                        !isActive &&
                        COLOR.SECONDARY_45
                    }
                >
                    {label}
                </Text>
            </div>
        );
    };

    const renderSigningContractDate = () => {
        if (!contractSigningDateTime && !contractSigningProposedAtDateTime) {
            return null;
        }

        if (contractSigningDateTime) {
            return (
                <NmLabelText
                    className="mt-2"
                    type="list"
                    label="Дата подписания"
                    text={formatLocalDate(contractSigningDateTime, "dd.MM.yyyy HH:mm")}
                />
            );
        }

        return (
            <div className="flex align-items-center mt-2">
                <Text
                    level="2"
                    color={
                        isContractSigningMoreDay ?
                            COLOR.NEGATIVE_100 :
                            COLOR.PRIMARY_100
                    }
                >
                    Подписывает договор
                    с 
                    {" "}
                    {formatLocalDate(new Date(contractSigningProposedAtDateTime), "dd.MM.yyyy HH:mm")}
                </Text>
                {
                    isContractSigningMoreDay &&
                    <NmAdvancedTooltip
                        className="ms-1"
                        trigger={
                            <InfoIcon
                                color={COLOR.NEGATIVE_100}
                                height={16}
                                width={16}
                            />
                        }
                        position="bottom-left"
                        hover
                    >
                        С момента отправки договора прошло более суток
                    </NmAdvancedTooltip>
                }
            </div>
        );
    };

    const renderContractBlock = () => {
        return (
            <div>
                {
                    renderCheckIcon({
                        label: "Договор",
                        isCheck: Boolean(contractSigningDateTime),
                        isActive: true,
                        labelLevel: "5",
                        iconSize: 26,
                    })
                }
                {renderSigningContractDate()}
            </div>
        );
    };

    const renderOptimumBlock = () => {
        if (taskCard.sfaType !== CROWD_TASK_SFA_TYPE.OPTIMUM.VALUE) {
            return null;
        }

        const {
            contractorIntegrated,
            taskContractorIntegrated,
            taskContractorIntegrationErrorMessage,
        } = sfaOptimumIntegrationData || {};

        return (
            <div>
                <div className="flex flex-content-spaced">
                    {
                        renderCheckIcon({
                            label: "Интеграция с Оптимум",
                            isCheck: contractorIntegrated && taskContractorIntegrated,
                            labelLevel: "5",
                            iconSize: 26,
                            isActive: true,
                        })
                    }
                    {
                        getOptimumMediaControls &&
                        <MediaButtons
                            config={getOptimumMediaControls(sfaOptimumIntegrationData)}
                        />
                    }
                </div>
                <Divider className="mb-2 mt-2" />
                {
                    renderCheckIcon({
                        label: "Пользователь зарегистрирован в Оптимум",
                        isCheck: contractorIntegrated,
                        isActive: contractorIntegrated,
                    })
                }
                <Divider className="mb-2 mt-2" />
                {
                    renderCheckIcon({
                        label: "Анкета в Оптимум назначена",
                        isCheck: taskContractorIntegrated,
                        isError: contractorIntegrated && taskContractorIntegrationErrorMessage,
                        isActive: contractorIntegrated,
                    })
                }
                {
                    taskContractorIntegrationErrorMessage &&
                    <Text
                        className="mt-2"
                        level="2"
                        color={COLOR.NEGATIVE_100}
                    >
                        {taskContractorIntegrationErrorMessage}
                    </Text>
                }
            </div>
        );
    };

    const renderHintBlock = ({button, children}) => {
        return (
            <NmHint
                reverse={true}
                buttonColor="black"
                customButton={button}
                chevronSize={20}
                fullWidth={true}
                defaultOpen={
                    [
                        CROWD_TASK_WORKING_CONTRACTOR_STATUS.AT_WORK.VALUE,
                        CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
                        CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE,
                    ].includes(status)
                }
            >
                {children}
            </NmHint>
        );
    };

    const renderTaskBlock = () => {
        const isActiveBlock = status !== CROWD_TASK_WORKING_CONTRACTOR_STATUS.SIGNING_CONTRACT.VALUE || isCompletedPage || isRejectedPage;
        const isTodayWorkStartDate = getStartDate(taskCard.workStartDate) < new Date();
        const workTimeNowMinutes = Math.floor((new Date() - convertUtcToLocal(new Date(taskWorkStartedDateTime))) / 60000);
        const workTimeEndMinutes = Math.floor((new Date(taskWorkFinishedDateTime) - new Date(taskWorkStartedDateTime)) / 60000);
        const timeColor = getTimeColor(workTimeNowMinutes, taskWorkFinishedDateTime, taskCard.timeForExecutionMinutes);
        const isStart = [
            CROWD_TASK_WORKING_CONTRACTOR_STATUS.AT_WORK.VALUE,
            CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
            CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE,
        ].includes(status) || isCompletedPage || taskWorkStartedDateTime;
        const isCompletion = [
            CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
            CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE,
        ].includes(status) || isCompletedPage || taskWorkFinishedDateTime;

        return (
            <div>
                <div className="flex flex-content-spaced">
                    {
                        renderCheckIcon({
                            label: "Задание",
                            isCheck: [
                                CROWD_TASK_WORKING_CONTRACTOR_STATUS.ON_VERIFICATION.VALUE,
                                CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE,
                            ].includes(status) || isCompletedPage || taskWorkStartedDateTime,
                            labelLevel: "5",
                            iconSize: 26,
                            isActive: isActiveBlock,
                        })
                    }
                    {
                        getTaskMediaControls &&
                        <MediaButtons
                            config={getTaskMediaControls()}
                        />
                    }
                </div>
                {
                    status === CROWD_TASK_WORKING_CONTRACTOR_STATUS.HIRED.VALUE &&
                    <Text
                        className="mt-2"
                        level="2"
                        color={isTodayWorkStartDate && COLOR.PRIMARY_100}
                    >
                        {
                            isTodayWorkStartDate ?
                                "Задание ждет выполнения" :
                                `Начнет выполнение с ${formatLocalDate(taskCard.workStartDate, "dd.MM.yyyy")}`
                        }
                    </Text>
                }
                {
                    (isStart || isCompletion) &&
                    <Text
                        className="mt-2"
                        level="2"
                        color={timeColor}
                    >
                        {
                            isCompletion ?
                                `Выполнил за ${getTimeFromMins(workTimeEndMinutes)}` :
                                `Выполняет ${getTimeFromMins(workTimeNowMinutes)}`
                        }
                        <HistoryIcon
                            className="ms-1"
                            width={16}
                            height={16}
                            color={timeColor}
                        />
                    </Text>
                }
                <Divider className="mb-2 mt-2" />
                {
                    renderHintBlock({
                        button: renderCheckIcon({
                            label: "Начало выполнения",
                            isCheck: isStart,
                            isActive: isStart,
                        }),
                        children: <>
                            {
                                isStart && taskWorkStartedDateTime &&
                                <NmLabelText
                                    className="mt-2"
                                    type="list"
                                    label="Приступил"
                                    text={`${formatLocalDate(taskWorkStartedDateTime, "dd.MM.yyyy HH:mm")} по МСК`}
                                />
                            }
                            {
                                isStart && taskWorkStartedDistanceToObject &&
                                <NmLabelText
                                    className="mt-2"
                                    type="list"
                                    label="Геолокация"
                                    text={`до объекта ${Math.round(taskWorkStartedDistanceToObject)} м`}
                                />
                            }
                            {
                                taskWorkStartedCheckInPhotoLink &&
                                <CrowdTaskContractorImageBlock
                                    className="mt-2"
                                    link={taskWorkStartedCheckInPhotoLink}
                                />
                            }
                        </>,
                    })
                }
                <Divider className="mb-2 mt-2" />
                {
                    renderCheckIcon({
                        label: "Анкета",
                        isCheck: isCompletion,
                        isActive: isStart,
                    })
                }
                {
                    taskCard.sfaType === CROWD_TASK_SFA_TYPE.OPTIMUM.VALUE &&
                    <NmLabelText
                        className="mt-2"
                        type="list"
                        label="По данным Оптимум"
                        text={TASK_CONTRACTOR_STATUS_OPTIMUM_FORM[sfaOptimumIntegrationData?.formStatusCode] || "-"}
                    />
                }
                <Divider className="mb-2 mt-2" />
                {
                    renderHintBlock({
                        button: renderCheckIcon({
                            label: "Завершение выполнения",
                            isCheck: isCompletion,
                            isActive: isStart,
                        }),
                        children: <>
                            {
                                isCompletion && taskWorkFinishedDateTime &&
                                <NmLabelText
                                    className="mt-2"
                                    type="list"
                                    label="Завершил"
                                    text={`${formatLocalDate(taskWorkFinishedDateTime, "dd.MM.yyyy HH:mm")} по МСК`}
                                />
                            }
                            {
                                isCompletion && taskWorkFinishedDistanceToObject &&
                                <NmLabelText
                                    className="mt-2"
                                    type="list"
                                    label="Геолокация"
                                    text={`до объекта ${Math.round(taskWorkFinishedDistanceToObject)} м`}
                                />
                            }
                            {
                                taskWorkFinishedCheckOutPhotoLink &&
                                <CrowdTaskContractorImageBlock
                                    className="mt-2"
                                    link={taskWorkFinishedCheckOutPhotoLink}
                                />
                            }
                        </>,
                    })
                }
            </div>
        );
    };

    const renderPayment = (payment, isAccessAction = false) => {
        const {
            actFormingDate,
            status,
            updateDate,
        } = payment;

        return (
            <NmListCard
                noDivider={true}
                primaryHeader={
                    renderCheckIcon({
                        label: `Оплата по заданию ${taskCard.number || ""}`,
                        isCheck: status === STATUS_TASK_PAYMENT.SUCCESSFUL.VALUE,
                        labelLevel: "5",
                        iconSize: 26,
                        isError: [
                            STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
                            STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
                            STATUS_TASK_PAYMENT.CANCELLED.VALUE,
                            STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
                            STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
                            STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                        ].includes(status),
                    })
                }
                labels={[
                    {
                        label: "Дата отправки акта",
                        text: actFormingDate ? `${formatLocalDate(actFormingDate, "dd.MM.yyyy HH:mm")} по МСК` : "-",
                    },
                    {
                        label: "Статус платежа",
                        text: `${STATUS_TASK_PAYMENT[status]?.TEXT} ${
                            ![STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE].includes(status) ?
                                formatLocalDate(updateDate, "dd.MM.yyyy HH:mm") :
                                ""
                        }`,
                        color: STATUS_TASK_PAYMENT[status]?.COLOR,
                    },
                ]}
                isFixedActions={true}
                mediaControls={
                    isAccessAction &&
                    getPaymentMediaControls &&
                    getPaymentMediaControls(payment)
                }
            />
        );
    };

    const renderActBlock = () => {
        return (
            <div>
                {
                    (card.status !== CROWD_TASK_WORKING_CONTRACTOR_STATUS.IN_PROCESS_OF_PAYMENT.VALUE && !isCompletedPage) || isEmpty(activePayment) ?
                        renderCheckIcon({
                            label: "Оплата по заданию",
                            isCheck: false,
                            labelLevel: "5",
                            iconSize: 26,
                        }) :
                        renderPayment(activePayment, true)
                }
            </div>
        );
    };

    const renderOtherPayments = () => {
        return (
            Boolean(otherPayments.length) &&
            <NmHint
                className="mt-3"
                onClick={() => setHideOtherPayments(prevState => !prevState)}
                isShown={!hideOtherPayments}
                closeButtonText="Скрыть платежи"
                openButtonText="Ещё платежи"
                buttonColor="blue"
            >
                <CheckboxList
                    rows={
                        otherPayments.map(item => {
                            return {
                                ...item,
                                key: item.paymentId,
                                contentRow: renderPayment(item),
                            };
                        })
                    }
                />
            </NmHint>
        );
    };

    const rows = useMemo(() => {
        return [
            workingContractor,
            renderContractBlock(),
            renderOptimumBlock(),
            renderTaskBlock(),
            renderActBlock(),
        ].filter(item => Boolean(item)).map((item) => {
            const {
                contractorId,
            } = item;

            return {
                avatar: contractorId && <Avatar
                    contractorId={contractorId}
                    base64={userAvatarDict[contractorId]}
                />,
                contentRow: (
                    contractorId ?
                        <CrowdTaskContractorCard
                            contractor={item}
                            mediaControls={getContractorMediaControls(item)}
                        /> :
                        item
                ),
            };
        });
    }, [card, taskCard]);

    return (
        <>
            <CheckboxList
                className={className}
                rows={rows}
                isFullMinHeight={false}
            />
            {renderOtherPayments()}
        </>

    );
};

export default CrowdTaskContractorWorkInfoDetail;