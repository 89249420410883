export const FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE = {
    PHONE_NUMBER: {
        VALUE: "PHONE_NUMBER",
        TEXT: "Номер телефона",
    },
    INN: {
        VALUE: "INN",
        TEXT: "ИНН",
    },

};

export const FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE_OPTIONS = [
    {
        key: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
        text: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.TEXT,
        value: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
    },
    {
        key: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.INN.VALUE,
        text: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.INN.TEXT,
        value: FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.INN.VALUE,
    },
];