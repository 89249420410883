import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    contractorResourcesClientStatusFilterOptions,
    contractorResourcesStatusFilterOptions,
} from "../../../../constants/resources";

import {retailClientNameIdMapSelector} from "../../../../ducks/client";
import {
    retailGetObjectList,
    retailGetProjectList,
    retailObjectNameIdMapSelector,
    retailProjectNameIdMapSelector,
} from "../../../../ducks/contractor";

import PropTypes from "prop-types";

import "./style.sass";

class ContractorResourcesFilter extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        sendFilter: PropTypes.func,
        clearFilter: PropTypes.func,
        defaultFilter: PropTypes.object,
        isShowClientColumn: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {
        },
        sendFilter: () => {
        },
        clearFilter: () => {
        },
        defaultFilter: {},
    };

    constructor(props) {
        super(props);

        const {defaultFilter} = props;

        this.state = {
            filter: {
                ...defaultFilter,
            },
            prevFilter: {
                ...defaultFilter,
            },
            nameSubstringFilter: "",
            projectIdsFilter: [],
            clearSearchAddress: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            retailGetProjectList,
            retailGetObjectList,
        } = this.props;

        retailGetProjectList();
        retailGetObjectList();
    }

    handleOnChange = (e, {name, value, checked}) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [name]: typeof checked === "boolean" ? checked : value,
            },
        }));
    };

    handleSendFilter = () => {
        const {sendFilter} = this.props;
        const {
            prevFilter,
            filter,
        } = this.state;

        if (JSON.stringify(prevFilter) === JSON.stringify(filter)) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            prevFilter: prevState.filter,
        }), () => {
            sendFilter(filter);
        });
    };

    handleClearFilter = () => {
        const {sendFilter, defaultFilter} = this.props;

        this.setState(prevState => (
            {
                filter: {
                    ...defaultFilter,
                },
                prevFilter: {
                    ...defaultFilter,
                },
                clearSearchAddress: !prevState.clearSearchAddress,
            }), () => {
            sendFilter(this.state.filter);
        });
    };

    render() {
        const {
            clientAllOptions,
            isShowClientColumn,
            projectOptions,
            objectOptions,
            clientId,
            t,
        } = this.props;
        const {
            filter: {
                contractorFioFilter,
                contractorPhoneFilter,
                addressFilter,
                lastRequestTimeFromFilter,
                lastRequestTimeToFilter,
                lastPaymentTimeFromFilter,
                lastPaymentTimeToFilter,
                clientInterestedIdsFilter,
                clientIdFilter,
                statusFilter,
                clientStatusFilter,
                onOrderFilter,
                addressFiasId,
                projectIdsFilter,
                objectIdsFilter,
            },
            clearSearchAddress,
        } = this.state;

        return (
            <NmForm className="contractor-resources-filter">
                <FilterButtonsV2
                    onSearch={this.handleSendFilter}
                    onClear={this.handleClearFilter}
                />
                <NmInputV2
                    size="lg"
                    label="ФИО исполнителя"
                    onChange={this.handleOnChange}
                    name="contractorFioFilter"
                    value={contractorFioFilter}
                    className="contractor-resources-filter__row"
                />
                <PhoneWithCodeFilter
                    name="contractorPhoneFilter"
                    value={contractorPhoneFilter}
                    onChange={this.handleOnChange}
                    className="contractor-resources-filter__row"
                />
                <NmDadata
                    size="lg"
                    label="Адрес"
                    name="addressFiasId"
                    onChange={this.handleOnChange}
                    isAllSelectionShown={false}
                    fromBound="city"
                    toBound="settlement"
                    initialAddressIds={addressFiasId}
                    className="contractor-resources-filter__row"
                />
                <div className="contractor-resources-filter__row">
                    <NmDateRangePickerV2
                        size="lg"
                        label="Дата последней заявки"
                        onChange={this.handleOnChange}
                        startFieldName="lastRequestTimeFromFilter"
                        endFieldName="lastRequestTimeToFilter"
                        value={{
                            lastRequestTimeFromFilter,
                            lastRequestTimeToFilter,
                        }}
                        isCurrentDateMax
                    />
                </div>
                <div className="contractor-resources-filter__row">
                    <NmDateRangePickerV2
                        size="lg"
                        label="Дата последней оплаты"
                        onChange={this.handleOnChange}
                        startFieldName="lastPaymentTimeFromFilter"
                        endFieldName="lastPaymentTimeToFilter"
                        value={{
                            lastPaymentTimeFromFilter,
                            lastPaymentTimeToFilter,
                        }}
                        isCurrentDateMax
                    />
                </div>
                <NmDropdownV2
                    label="Заинтересованные компании"
                    size="lg"
                    multiple
                    search
                    name="clientInterestedIdsFilter"
                    options={clientAllOptions}
                    value={clientInterestedIdsFilter}
                    onChange={this.handleOnChange}
                    onSubmit={this.handleSendFilter}
                    className="contractor-resources-filter__row"
                />
                {
                    isShowClientColumn &&
                    <NmDropdownV2
                        size="lg"
                        multiple
                        label="Юр. лицо"
                        search
                        name="clientIdFilter"
                        options={clientAllOptions}
                        value={clientIdFilter}
                        onChange={this.handleOnChange}
                        onSubmit={this.handleSendFilter}
                        className="contractor-resources-filter__row"
                    />
                }
                <NmDropdownV2
                    size="lg"
                    label="Статус риска"
                    noResultsMessage={t("orders-filter.no-search-result")}
                    name="statusFilter"
                    multiple
                    isAllSelectionShown={false}
                    onChange={this.handleOnChange}
                    className="contractor-resources-filter__row"
                    value={statusFilter}
                    options={contractorResourcesStatusFilterOptions}
                    placeholder={t("orders-filter.show-all")}
                    onSubmit={this.handleSendFilter}
                />
                <NmDropdownV2
                    size="lg"
                    label="Статус исполнителя"
                    isAllSelectionShown={false}
                    noResultsMessage={t("orders-filter.no-search-result")}
                    name="clientStatusFilter"
                    multiple
                    onChange={this.handleOnChange}
                    value={clientStatusFilter}
                    options={contractorResourcesClientStatusFilterOptions}
                    placeholder={t("orders-filter.show-all")}
                    onSubmit={this.handleSendFilter}
                    className="contractor-resources-filter__row"
                />
                <NmDropdownV2
                    label="Проект"
                    size="lg"
                    noResultsMessage={t("orders-filter.no-search-result")}
                    name="projectIdsFilter"
                    multiple
                    onChange={this.handleOnChange}
                    search
                    value={projectIdsFilter}
                    options={projectOptions}
                    placeholder={t("orders-filter.show-all")}
                    onSubmit={this.handleSendFilter}
                    className="contractor-resources-filter__row"
                />
                <NmDropdownV2
                    size="lg"
                    label="Объект"
                    dropdownWidth={300}
                    noResultsMessage={t("orders-filter.no-search-result")}
                    name="objectIdsFilter"
                    multiple
                    onChange={this.handleOnChange}
                    search
                    value={objectIdsFilter}
                    options={objectOptions}
                    placeholder={t("orders-filter.show-all")}
                    onSubmit={this.handleSendFilter}
                    className="contractor-resources-filter__row"
                />
                {
                    clientId &&
                    <NmCheckboxV2
                        label="На заказе"
                        checked={onOrderFilter}
                        className="contractor-resources-filter__row"
                        name="onOrderFilter"
                        onChange={this.handleOnChange}
                    />
                }
            </NmForm>
        );
    }
}

export default connect(
    state => ({
        clientAllOptions: retailClientNameIdMapSelector(state),
        projectOptions: retailProjectNameIdMapSelector(state),
        objectOptions: retailObjectNameIdMapSelector(state),
    }), {
        retailGetProjectList,
        retailGetObjectList,
    },
)(withTranslation()(ContractorResourcesFilter));


