import {isEmpty} from "lodash";

import {getDadataAddressObj} from "../../../../utils/dadata";

import {NM_CLIENT_INFO} from "../../../../constants/clientList";
import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";

export const getInitialAdvertValuesFromCard = (initialValues, card, duplicate) => {
    const {baseModel} = card;

    if (isEmpty(baseModel)) {
        return initialValues;
    }

    const form = Object.keys(initialValues).reduce((acc, current) => {
        if (current === "file" && baseModel.fileName) {
            acc.file = [{
                name: baseModel.fileName,
                filePath: card.filePath,
                isRemote: true,
            }];

            return acc;
        }

        if (["publicationDateFrom", "publicationDateTo"].includes(current) && duplicate) {
            acc.publicationDateFrom = null;
            acc.publicationDateTo = null;

            return acc;
        }

        if (current === "workAddressFiasValue" && baseModel.workAddressInfo) {
            const {key, text = ""} = getDadataAddressObj(baseModel.workAddressInfo, true);

            acc.workAddressFiasValue = text;
            acc.workAddressFiasId = key;

            return acc;
        }

        if (current === "managerId" && baseModel.managerClientId === NM_CLIENT_INFO.ID) {
            acc.managerId = null;

            return acc;
        }

        acc[current] = baseModel[current] || initialValues[current];

        return acc;
    }, {});

    return form;
};

export const getAdvertFormDataValuesFromForm = (values, clientId) => {
    const {
        type,
        gender,
        file,
        fileValue,
        ...otherValues
    } = values;

    const formData = new FormData();

    Object.keys(otherValues).forEach((name) => {
        formData.append(name, otherValues[name]);
    });

    if (!isEmpty(file)) {
        if (file[0].isRemote && fileValue) {
            formData.append("file", fileValue);
        }

        if (!file[0].isRemote) {
            formData.append("file", file[0]);
        }
    }

    if (gender !== EMPTY_OPTION_KEY) {
        formData.append("gender", values.gender);
    }

    formData.append("clientId", clientId);

    return formData;
};