import {
    BFF_CLIENTS_USERS_SETTINGS_UPDATE_STORE,
    BFF_COMMON_TREE_LIST_REQUEST,
    BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST,
    BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST,
    BFF_COMMON_TREE_SAVE_REQUEST,
} from "./actions";

export const getProjectsTree = (payload) => {
    return {
        type: BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getObjectsTree = (payload) => {
    return {
        type: BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getSearchTree = (payload) => {
    return {
        type: BFF_COMMON_TREE_LIST_REQUEST,
        payload,
    };
};

export const saveTree = (payload) => {
    return {
        type: BFF_COMMON_TREE_SAVE_REQUEST,
        payload,
    };
};

export const updateCommonTreeStore = (payload) => {
    return {
        type: BFF_CLIENTS_USERS_SETTINGS_UPDATE_STORE,
        payload,
    };
};