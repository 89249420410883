import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";

import AddClientsForm from "../components/add-clients-form";

import {addClient, deleteClient} from "../../../../../../ducks/clientGroups";

const INITIAL_STATE_MODAL_WINDOW = {
    isShow: false,
    title: "",
    formId: undefined,
    content: "",
    submit: undefined,
};

const INITIAL_STATE_CONFIRM_WINDOW = {
    confirmButton: "Подтвердить",
    cancelButton: "Отменить",
    isOnlyConfirm: true,
    onConfirm: undefined,
    onCancel: undefined,
    content: "",
    isShow: false,
};

export function useClientsActions(groupId) {
    const dispatch = useDispatch();

    const [dataModal, setDataModal] = useState({
        ...INITIAL_STATE_MODAL_WINDOW,
    });

    const [dataConfirm, setDataConfirm] = useState(INITIAL_STATE_CONFIRM_WINDOW);

    const onCloseModal = () => {
        setDataModal(INITIAL_STATE_MODAL_WINDOW);
    };

    const onCloseConfirm = () => {
        setDataConfirm(INITIAL_STATE_CONFIRM_WINDOW);
    };

    const onSubmitAddClient = (data) => {
        const requestData = {
            ...data,
            groupId,
        };

        dispatch(addClient({
            requestData,
            meta: onCloseModal,
        }));
    };

    const onClickAddClient = () => {
        setDataModal(prevState => ({
            ...prevState,
            isShow: true,
            title: "Добавление заказчика в группу компаний",
            formId: "AddClientForm",
            content: <AddClientsForm
                submit={onSubmitAddClient}
            />,
        }));
    };

    const onClickDeleteClient = useCallback((item, groupName) => {
        const {
            clientName,
            clientId,
            groupId,
        } = item;
        setDataConfirm(prevState => ({
            ...prevState,
            isShow: true,
            content: `Удалить заказчика "${clientName}" из группы "${groupName}"?`,
            onConfirm: () => dispatch(deleteClient({
                requestData: {clientId, groupId},
                meta: onCloseConfirm,
            })),
            onCancel: onCloseConfirm,
        }));
    }, []);

    return {
        dataModal,
        dataConfirm,
        onCloseModal,
        onClickAddClient,
        onClickDeleteClient,
    };
}