export const BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST = "BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST";
export const BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS = "BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR = "BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR";

export const BFF_CROWD_TASK_GROUPS_ADD_REQUEST = "BFF_CROWD_TASK_GROUPS_ADD_REQUEST";
export const BFF_CROWD_TASK_GROUPS_ADD_SUCCESS = "BFF_CROWD_TASK_GROUPS_ADD_SUCCESS";
export const BFF_CROWD_TASK_GROUPS_ADD_ERROR = "BFF_CROWD_TASK_GROUPS_ADD_ERROR";

export const BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST = "BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST";
export const BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS = "BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS";
export const BFF_CROWD_TASK_GROUPS_UPDATE_ERROR = "BFF_CROWD_TASK_GROUPS_UPDATE_ERROR";

export const BFF_CROWD_TASK_GROUPS_DELETE_REQUEST = "BFF_CROWD_TASK_GROUPS_DELETE_REQUEST";
export const BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS = "BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS";
export const BFF_CROWD_TASK_GROUPS_DELETE_ERROR = "BFF_CROWD_TASK_GROUPS_DELETE_ERROR";

export const BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST = "BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST";
export const BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS = "BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS";
export const BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR = "BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR";

export const BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST = "BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST";
export const BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS = "BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS";
export const BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR = "BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR";

export const UPDATE_CROWD_TASK_GROUPS_FILED_STORE = "UPDATE_CROWD_TASK_GROUPS_FILED_STORE";