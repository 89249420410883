import React, {useEffect} from "react";
import Media from "react-media";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import LogoThumbnail from "../../../components/LogoThumbnail";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {STATUS_COLOR_TRANSCRIPT} from "../../../components/OrderContractors/Card";
import {ReactComponent as LogoIcon} from "../../../images/company-logo.svg";

import {usePagination} from "../../../hooks/usePagination";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_ID, ls, USER_ROLE} from "../../../utils/localstorage";

import {CROWD_TASK_WORKING_CONTRACTOR_STATUS} from "../../../constants/crowd/task";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {
    isUserFromNm,
} from "../../../constants/roles";

import {
    getPageContractorTaskHistory,
    updateStoreContractorTaskHistory,
} from "../../../ducks/bff/contractor/taskHistory/actionCreators";
import {
    bffContractorTaskHistoryListSelector,
    bffContractorTaskHistoryProgressSelector,
    bffContractorTaskHistoryTotalCountSelector,
    bffContractorTaskHistoryTotalPagesSelector,
} from "../../../ducks/bff/contractor/taskHistory/selectors";
import {
    getContractorOrderLogs,
    getContractorOrderLogsProgressSelector,
    getContractorOrderLogsSelector,
    getContractorOrderLogsTotalCountSelector,
    getContractorOrderLogsTotalPagesSelector,
    updateFieldContractorOrderLog,
} from "../../../ducks/order";
import {
    contractorStatusSelector,
} from "../../../ducks/order";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../constants/link-params";

function ContractorOrderList(props) {
    const {
        match: {
            params: {
                contractorId,
                historyData,
            },
            path,
        },
    } = props;

    const isCrowd = path.includes("task-list");

    const orderList = useSelector(getContractorOrderLogsSelector);
    const orderTotalPages = useSelector(getContractorOrderLogsTotalPagesSelector);
    const orderTotalCount = useSelector(getContractorOrderLogsTotalCountSelector);
    const orderProgress = useSelector(getContractorOrderLogsProgressSelector);
    const taskList = useSelector(bffContractorTaskHistoryListSelector);
    const taskTotalPages = useSelector(bffContractorTaskHistoryTotalPagesSelector);
    const taskTotalCount = useSelector(bffContractorTaskHistoryTotalCountSelector);
    const taskProgress = useSelector(bffContractorTaskHistoryProgressSelector);
    const contractorStatusDict = useSelector(contractorStatusSelector);

    const getData = () => {
        if (isCrowd) {
            return {
                list: taskList,
                totalPages: taskTotalPages,
                totalCount: taskTotalCount,
                progress: taskProgress,
            };
        }

        return {
            list: orderList,
            totalPages: orderTotalPages,
            totalCount: orderTotalCount,
            progress: orderProgress,
        };
    };

    const {
        list,
        totalPages,
        totalCount,
        progress,
    } = getData();

    const currentClientId = ls(CURRENT_CLIENT_ID);
    const role = ls(USER_ROLE);
    const isNmUser = isUserFromNm(role);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateFieldContractorOrderLog({
                contractorOrderLogs: [],
            }));
            dispatch(updateStoreContractorTaskHistory({
                contractorOrderLogs: [],
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, isCrowd]);

    const fetchList = () => {
        const reqData = {
            pageNum,
            pageSize,
            contractorId,
        };

        if (isCrowd) {
            dispatch(getPageContractorTaskHistory(reqData));

            return;
        }

        dispatch(getContractorOrderLogs(reqData));
    };

    const renderLink = (text, link) => {
        return (
            <ExtLink
                extData={historyData}
                pageData={{
                    pageNum, pageSize,
                }}
                to={link}
                historyEnabled
            >
                {text}
            </ExtLink>
        );
    };

    const renderCardPrimaryHeader = (params) => {
        const {
            clientName,
            brand,
            clientId,
            orderNum,
            orderName,
            orderId,
            taskNum,
            taskName,
            taskId,
        } = params;

        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);
        const orderLink = LINK_ORDER_CARD
            .replace(":orderId", orderId)
            .replace(":clientId", clientId || currentClientId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
        const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":taskId", taskId)
            .replace(":clientId", clientId || currentClientId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        const clientText = `${clientName}${brand ? `(${brand})` : ""}`;
        const orderText = `№${orderNum} ${orderName}`;
        const taskText = `№${taskNum} ${taskName}`;
        const text = isCrowd ? taskText : orderText;

        return (
            <Media query={{maxWidth: 767}}>
                {
                    mobile =>
                        <div className={mobile ? "contractor-order-list__primary-header_mobile" : "flex"}>
                            <div>
                                {
                                    isNmUser && clientId ?
                                        renderLink(clientText, clientLink) :
                                        clientText
                                }
                            </div>
                            {!mobile && <div className="contractor-order-list__delimiter" />}
                            <div>
                                {
                                    (isNmUser || currentClientId === clientId) && (orderId || taskId) ?
                                        renderLink(text, isCrowd ? taskLink : orderLink) :
                                        text
                                }
                            </div>
                        </div>
                }
            </Media>
        );
    };

    const renderStatus = status => {
        const badgeProps = {
            text: isCrowd ?
                CROWD_TASK_WORKING_CONTRACTOR_STATUS[status]?.TEXT :
                contractorStatusDict[status],
            mod: isCrowd ?
                CROWD_TASK_WORKING_CONTRACTOR_STATUS[status]?.COLOR :
                STATUS_COLOR_TRANSCRIPT[status],
        };

        return (
            <NmBadge
                noWrap
                condition
                mod={badgeProps.mod || "gray"}
                text={badgeProps.text || status}
            />
        );
    };

    const getRows = () => {
        return list.map((item) => {
            const {
                status,
                workStartDate,
                workEndDate,
                clientLogoImageUrl,
                speciality,
                orderId,
            } = item;

            return {
                ...item,
                key: orderId,
                avatar:
                    clientLogoImageUrl ?
                        <LogoThumbnail
                            className="contractor-order-list__logo"
                            size="sm"
                            src={clientLogoImageUrl}
                        /> :
                        <LogoIcon className="contractor-order-list__logo contractor-order-list__logo_empty" />
                ,
                contentRow: (
                    <NmListCard
                        alignItems="flex-start"
                        secondaryHeaderStatus={renderStatus(status)}
                        secondaryHeader={`Период работ ${dateFormat(convertUtcToLocal(workStartDate), "dd.MM.yyyy")} - ${dateFormat(convertUtcToLocal(workEndDate), "dd.MM.yyyy")}`}
                        primaryHeader={renderCardPrimaryHeader(item)}
                        primarySubHeader={speciality}
                        noDivider
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            onPaginationChange={onPaginationChange}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            showHeaderBlock={false}
            isLoaded={progress}
            noPadding
        >
            {
                list?.length ?
                    <CheckboxList
                        rowClassName="contractor-order-list__row"
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
}

export default ContractorOrderList;
