import React from "react";

import {ReactComponent as PlaceIcon} from "../../../images/place_24.svg";
import NmRangeInput, { INmRangeInput } from "../NmRangeInput";

import bem from "../../../utils/bem";

import "./style.sass";

const CoordsInput = (props: INmRangeInput) => {
    const {
        className = "",
        ...otherProps
    } = props;
    const [block, element] = bem("coords-input", className);

    return (
        <div className={block()}>
            <NmRangeInput
                iconStart={
                    <PlaceIcon className={element("icon")} />
                }
                iconEnd={
                    <PlaceIcon className={element("icon")} />
                }
                className={element("coords")}
                {...otherProps}
            />
        </div>
    );
};

export default CoordsInput;