import React, {Component, Suspense} from "react";
import {Helmet} from "react-helmet";
import {ToastContainer} from "react-toastify";
import {ConnectedRouter} from "connected-react-router";

import {AppContextProvider} from "../components/AppContext";
import {history} from "../store/configureStore";
import {MainRoute} from "./routes";
import {Dimmer, Loader} from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/main.sass";

class App extends Component {

    render() {
        return (
            <ConnectedRouter history={history}>
                <Helmet>
                    <title>
Naimix
                    </title>
                </Helmet>
                <Suspense
                    fallback={(<Dimmer
                        active
                        inverted
                    >
                        <Loader>
                        </Loader>
                    </Dimmer>)}
                >
                    <ToastContainer
                        draggable
                    />
                    <AppContextProvider>
                        <MainRoute />
                    </AppContextProvider>
                </Suspense>
            </ConnectedRouter>
        );
    }
}

export default App;
