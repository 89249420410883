import {useDispatch, useSelector} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {isClientUser} from "../../../../constants/roles";

import {
    deleteClientKedoDocumentsDraft,
    exportClientKedoDocuments,
    getClientsKedoDocumentsHistoryPage,
    getClientsKedoDocumentsPage,
} from "../../../../ducks/bff/clients/kedo/documents/services";
import {
    deleteCompaniesKedoDocumentsDraft,
    exportCompaniesKedoDocuments,
    getCompaniesKedoDocumentsHistoryPage,
    getCompaniesKedoDocumentsPage,
} from "../../../../ducks/bff/companies/company/kedo/documents/services";
import {
    updateKedoDocumentsStore,
} from "../../../../ducks/kedo/documents/actionCreators";
import {getKedoDocumentsInitialState} from "../../../../ducks/kedo/documents/reducer";
import {
    bffCompaniesCompanyKedoDocumentsListSelector,
    bffCompaniesCompanyKedoDocumentsProgressActionSelector,
    bffCompaniesCompanyKedoDocumentsProgressSelector,
    bffCompaniesCompanyKedoDocumentsTotalCountSelector,
    bffCompaniesCompanyKedoDocumentsTotalPageSelector,
} from "../../../../ducks/kedo/documents/selectors";

export const useKedoDocumentsBff = () => {
    const dispatch = useDispatch();

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const list = useSelector(bffCompaniesCompanyKedoDocumentsListSelector);
    const totalCount = useSelector(bffCompaniesCompanyKedoDocumentsTotalCountSelector);
    const totalPages = useSelector(bffCompaniesCompanyKedoDocumentsTotalPageSelector);
    const progress = useSelector(bffCompaniesCompanyKedoDocumentsProgressSelector);
    const progressAction = useSelector(bffCompaniesCompanyKedoDocumentsProgressActionSelector);

    const fetchPage = (data) => {
        if (isClientRole) {
            dispatch(getClientsKedoDocumentsPage(data));

            return;
        }

        dispatch(getCompaniesKedoDocumentsPage(data));
    };

    const getHistoryPage = (data) => {
        if (isClientRole) {
            dispatch(getClientsKedoDocumentsHistoryPage(data));

            return;
        }

        dispatch(getCompaniesKedoDocumentsHistoryPage(data));
    };

    const exportDocuments = (data) => {
        if (isClientRole) {
            dispatch(exportClientKedoDocuments(data));

            return;
        }

        dispatch(exportCompaniesKedoDocuments(data));
    };

    const deleteDraft = (data) => {
        if (isClientRole) {
            dispatch(deleteClientKedoDocumentsDraft(data));

            return;
        }

        dispatch(deleteCompaniesKedoDocumentsDraft(data));
    };

    const clearStore = () => {
        const initialState = getKedoDocumentsInitialState();

        dispatch(updateKedoDocumentsStore(initialState));
    };

    const updateStore = (data) => {
        dispatch(updateKedoDocumentsStore(data));
    };

    return {
        list,
        totalCount,
        totalPages,
        progress,
        progressAction,
        fetchPage,
        getHistoryPage,
        exportDocuments,
        deleteDraft,
        clearStore,
        updateStore,
    };
};