import React from "react";

import HelpTooltip from "../../../HelpTooltip";
import NmCheckboxV2 from "../../../NmCheckboxV2";
import {ClientSettingsChangedDataModal} from "../ChangedDataModal";
import {ClientSettingsSubmitButtons} from "../SubmitButtons";
import {useClientSettingsDocumentsForm} from "./hook/useForm";

import {ADMIN, NM_MANAGER} from "../../../../../constants/roles";

export const ClientSettingsDocuments = (props) => {
    const {
        role,
        isAccess,
        clientId,
        onClose,
        refFooter,
    } = props;

    const {
        onChange,
        values,
        onSubmit,
    } = useClientSettingsDocumentsForm({
        clientId,
        onClose,
    });

    return (
        <>
            <ClientSettingsChangedDataModal onSubmit={onSubmit} />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="customDocumentTemplatesAvailable"
                onChange={onChange}
                checked={values.customDocumentTemplatesAvailable}
                label="Свои шаблоны документов"
                isVisibleTooltip={true}
                tooltip={
                    <HelpTooltip
                        hover
                        info
                        children='При установке флага администрации Наймикс становится доступным для работы раздел
                        "Шаблоны документов". Заказчики получают возможность использовать созданные администрацией
                        Наймикс шаблоны документов'
                    />
                }
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="actDateAvailable"
                onChange={onChange}
                checked={values.actDateAvailable}
                label="Настройка даты акта"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="documentDatesCheckRequired"
                onChange={onChange}
                checked={values.documentDatesCheckRequired}
                label="Контроль дат в документах"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="documentDatesByWorkDates"
                onChange={onChange}
                checked={values.documentDatesByWorkDates}
                label="Даты заявки и акта по датам работ"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="smzDocumentTablesEnabled"
                onChange={onChange}
                checked={values.smzDocumentTablesEnabled}
                label="Таблицы в актах и заявках НПД"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="civilDocumentTablesEnabled"
                onChange={onChange}
                checked={values.civilDocumentTablesEnabled}
                label="Таблицы в актах и заявках НДФЛ"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="edmSignWithoutSmsCodeEnabled"
                onChange={onChange}
                checked={values.edmSignWithoutSmsCodeEnabled}
                label="Подписание документов в ЭДО без СМС"
            />
            <NmCheckboxV2
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="createAdditionalAgreementOnContractorPDChange"
                onChange={onChange}
                checked={values.createAdditionalAgreementOnContractorPDChange}
                label="Формировать ДС при изменении ПДн исполнителем"
            />
            <ClientSettingsSubmitButtons
                refFooter={refFooter}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
