import React from "react";

import {ContractorFinanceIncomeIndividual} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED} from "../../../../../constants/status";

import {getContractorCompletedFinanceIndividualPayments} from "../../../../../ducks/contractorFinanceIncomeIndividual";

const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED);

export const ContractorFinanceIncomeIndividualCompleted = (props) => {
    return (
        <ContractorFinanceIncomeIndividual
            fetchList={getContractorCompletedFinanceIndividualPayments}
            isShowNaimixStatusFilter={false}
            isShowBankStatusFilter={false}
            isShowRegistryNumberFilter={true}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
