export const STOP_LIST_IMPORT_FILE_TYPE = {
    IMPORT_STOP_LIST: "IMPORT_STOP_LIST",
};

export const STOP_LIST_EXPORT_ERROR_TYPE = {
    NO_INN_CLIENT_FOUND_SPECIFIED_FILTERS: "NO_INN_CLIENT_FOUND_SPECIFIED_FILTERS",
};

export const STOP_LIST_ADD_ERROR_TYPE = {
    INN_ALREADY_ADDED: "INN_ALREADY_ADDED",
};

export const STOP_LIST_IMPORT_ERROR_TYPE = {
    NO_DATA_FILE_TO_IMPORT: "NO_DATA_FILE_TO_IMPORT",
};