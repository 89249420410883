import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ApplyButtons from "../../../../../components/ApplyButtons";
import Portal from "../../../../../components/Portal";

import {bffKedoStaffCardSelector, bffKedoStaffProgressActionSelector} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffRegistryEditSubmitButtons = (props) => {
    const {
        refFooter,
        onClose,
        onSubmit,
        disabled,
    } = props;

    const card = useSelector(bffKedoStaffCardSelector);
    const progressAction = useSelector(bffKedoStaffProgressActionSelector);

    const isEdit = !isEmpty(card);

    return (
        <Portal node={refFooter}>
            <ApplyButtons
                disabled={disabled || progressAction}
                mobile="column"
                isHiddenAdditionalOnMobile={true}
                onClickAdditional={onClose}
                cancelBtnContent="Отменить"
                onClose={onClose}
                submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                submit={onSubmit}
            />
        </Portal>
    );
};