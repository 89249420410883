import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CROWD_TASK_GROUPS_ADD_ERROR,
    BFF_CROWD_TASK_GROUPS_ADD_REQUEST,
    BFF_CROWD_TASK_GROUPS_ADD_SUCCESS,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
    BFF_CROWD_TASK_GROUPS_DELETE_REQUEST,
    BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
    BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/crowd/task-groups";

// POST /bff/adm/crowd/task-groups/page
const getPageCrowdTaskGroups = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/task-groups/add
const addCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_ADD_ERROR,
        });
    }
};

// POST /bff/adm/crowd/task-groups/update
const updateCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/task-groups/delete
const deleteCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/delete`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/task-groups/archive/add
const archiveCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/archive/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/task-groups/archive/remove
const unarchiveCrowdTaskGroup = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/archive/remove`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST, getPageCrowdTaskGroups),
        takeEvery(BFF_CROWD_TASK_GROUPS_ADD_REQUEST, addCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST, updateCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_DELETE_REQUEST, deleteCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST, archiveCrowdTaskGroup),
        takeEvery(BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST, unarchiveCrowdTaskGroup),
    ]);
}