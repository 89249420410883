import React from "react";

import {ReactComponent as IconChevron} from "../../../../../../../images/chevron-down.svg";
import OrderEditFormPublishLogo from "../logo";

import bem from "../../../../../../../utils/bem";

import "./style.sass";

const OrderEditFormPublishClientInfo = (props) => {
    const {
        base64Logo,
        brand,
        clientName,
        contactPersonLabel,
        className,
        managerFirstName,
        objectInfo = {},
        objectBrandLogo,
    } = props;

    const [block, element] = bem("order-edit-publish-client-info", className);

    const getInfoClient = () => {
        if (objectInfo.brandId && objectInfo.brandName) {
            return (
                <div className={element("client-name")}>
                    {objectInfo.brandName}
                </div>
            );
        }

        return (
            brand ?
                <div className={element("client-name")}>
                    {brand} 
                    {" "}
                    <span className={element("client-name", {thin: true})}>
                        {`(${clientName})`}
                    </span>
                </div> :
                <div className={element("client-name")}>
                    {clientName}
                </div>
        );
    };

    return (
        <div className={block()}>
            <div className="order-edit-publish-client-info__main-content">
                <div className="order-edit-publish-client-info__customer">
                    <div className="flex flex-vertical-center">
                        <OrderEditFormPublishLogo
                            className="me-2"
                            base64Logo={base64Logo}
                            brandId={objectInfo.brandId}
                            objectBrandLogo={objectBrandLogo}
                        />
                        <div className="order-edit-publish-client-info__client">
                            {getInfoClient()}
                        </div>
                    </div>
                </div>
                {
                    contactPersonLabel &&
                    <div className="order-edit-publish-client-info__contact-person">
                        <div className="order-edit-publish-client-info__contact-person-label">
                            {`${contactPersonLabel}:`}
                        </div>
                        <div className="order-edit-publish-client-info__contact-person-text">
                            {managerFirstName}
                        </div>
                    </div>
                }
            </div>
            <IconChevron
                className="order-edit-publish-client-info__icon"
            />
        </div>
    );
};

export default OrderEditFormPublishClientInfo;