import React from "react";

import LogoThumbnail from "../../../../../../../components/LogoThumbnail";

import "./style.sass";

const OrderEditFormPublishLogo = (props) => {
    const {
        base64Logo,
        className = "",
        brandId,
        objectBrandLogo,
    } = props;

    return (base64Logo || (brandId && objectBrandLogo)) ?
        <LogoThumbnail
            className={className}
            size="md"
            src={
                brandId ?
                    objectBrandLogo?.preview :
                    `data:image/jpeg;charset=utf-8;base64, ${base64Logo}`
            }
        /> :
        <div className={`order-edit-form-publish-logo__empty-image ${className}`} />;
};

export default OrderEditFormPublishLogo;