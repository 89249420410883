import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {getRequestDataFromMapFilterData} from "../../../../../utils/dto";

import {MAP_TASK_SORT_TYPE} from "../../../../../constants";

import {getMapTasks} from "../../../../../../../ducks/bff/map/actionCreators";

export function useTasksFetchList(params) {
    const {
        filter = {},
        pageSize,
        pageNum,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(filter)) {
            return;
        }

        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        const {
            distanceFilter,
            locationAddressFiasIdsFilter,
            crowdTasksFilters,
        } = getRequestDataFromMapFilterData(filter);

        dispatch(getMapTasks({
            pageNum,
            pageSize,
            distanceFilter,
            locationAddressFiasIdsFilter,
            orderType: MAP_TASK_SORT_TYPE.WORK_START_DATE_ASC,
            ...crowdTasksFilters,
        }));
    };

    return {
        fetchList,
    };
}