import React, {useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";

import StandartDocuments from "../../components/StandartDocuments";

import {ls, USER_ROLE} from "../../utils/localstorage";
import {
    downloadStrapiFile,
    getDocumentsPlatformCustomerSds,
    mapActivesDocumentsPlatformCustomerSds,
} from "../../utils/strapiService";

import {
    ADMIN,
    NM_COORDINATOR,
    NM_MANAGER,
} from "../../constants/roles";

import {
    downloadDocument,
    getExemplary, 
} from "../../ducks/documents";

const EXEMPLARY_PARAMS = {
    pageNum: 1,
    pageSize: 1000,
};

const StandartDocumentsContainer = () => {
    const {location} = useSelector(({router}) => router);
    const {clientId} = useSelector(({client: {card}}) => card);
    const [exemplaryActual, setExemplaryActual] = useState([]);
    const [exemplaryArchive, setExemplaryArchive] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const list = await getDocumentsPlatformCustomerSds();

            const exemplaryActualStrapi = await mapActivesDocumentsPlatformCustomerSds(list);
            const exemplaryArchiveStrapi = await mapActivesDocumentsPlatformCustomerSds(list,"archive");

            setExemplaryActual(exemplaryActualStrapi);
            setExemplaryArchive(exemplaryArchiveStrapi);
        }
        fetchData();
    }, []);

    const roleCurrent = ls(USER_ROLE);

    const isCanSeeTabs = [
        ADMIN,
        NM_MANAGER,
        NM_COORDINATOR,
    ].includes(roleCurrent);

    const {pathname} = location;

    return (
        <StandartDocuments
            pathname={pathname}
            clientId={clientId}
            actualList={exemplaryActual}
            archiveList={exemplaryArchive}
        />
    );
};

export default StandartDocumentsContainer;