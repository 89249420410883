import {
    all,
    put,
    takeEvery,
} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controllerContractor = "/ratings/contractor";

const clientController = "/ratings/client";

//*  TYPES  *//

const GET_CONTRACTOR_RATING = "GET_CONTRACTOR_RATING";
const SET_CONTRACTOR_RATING = "SET_CONTRACTOR_RATING";
const GET_CONTRACTOR_RATING_ERROR = "GET_CONTRACTOR_RATING_ERROR";

const GET_CLIENT_RATING_REQUEST = "GET_CLIENT_RATING_REQUEST";
const GET_CLIENT_RATING_SUCCESS = "GET_CLIENT_RATING_SUCCESS";
const GET_CLIENT_RATING_ERROR = "GET_CLIENT_RATING_ERROR";

const UPDATE_RATING_STORE = "UPDATE_RATING_STORE";

//*  INITIAL STATE  *//

const initialState = {
    ratingsContractors: {
        contractorId: null,
        estimatedTimeRating: 0,
        rating: 0,
        workQualityRating: 0,
        scoreCount: 0,
        isLoaded: false,
    },
    progress: false,
    clientRating: {},
};

//*  REDUCER  *//

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case GET_CLIENT_RATING_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_CLIENT_RATING_SUCCESS:
        return {
            ...state,
            progress: false,
            clientRating: payload,
        };
    case SET_CONTRACTOR_RATING:
        return {
            ...state,
            ratingsContractors: {
                ...payload,
                isLoaded: true,
            },
        };
    case GET_CLIENT_RATING_ERROR:
        return {
            ...state,
            progress: false,
        };
    case UPDATE_RATING_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export const getRatingsByContractorId = (payload) => ({
    type: GET_CONTRACTOR_RATING,
    payload,
});

export const getClientRatingById = (payload) => ({
    type: GET_CLIENT_RATING_REQUEST,
    payload,
});

export const updateRatingStore = (payload) => ({
    type: UPDATE_RATING_STORE,
    payload,
});

//*  SELECTORS  *//

export const ratingsSelector = ({ratings}) => ratings;
export const ratingsContractorSelector = createSelector(ratingsSelector, ({ratingsContractors}) => ratingsContractors);

export const ratingProgressSelector = createSelector(ratingsSelector, ({progress}) => progress);

export const clientRatingSelector = createSelector(ratingsSelector, ({clientRating}) => clientRating);

//*  SAGA  *//

//GET /api/ratings/contractor/findByContractorId?contractorId=
export const getRatingsByContractorIdSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controllerContractor}/findByContractorId?contractorId=${payload}`);

        yield put({type: SET_CONTRACTOR_RATING, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_RATING_ERROR, payload: error});
    }
};

export const getClientRatingByIdSaga = function* ({payload}) {
    try {
        const {
            clientId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.get(`${clientController}/findByClientId/?clientId=${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_RATING_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: GET_CLIENT_RATING_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CLIENT_RATING_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CONTRACTOR_RATING, getRatingsByContractorIdSaga),
        takeEvery(GET_CLIENT_RATING_REQUEST, getClientRatingByIdSaga),
    ]);
}





