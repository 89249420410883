import React from "react";

import {useRecruitmentCandidateInformationValues} from "./hooks/useValues";

import {isLastElementInArray} from "../../../utils/objectHelper";

const RecruitmentCandidateInformation = (props) => {
    const {
        fields,
        card = {},
        isVisibleResumes,
    } = props;

    const {
        values,
    } = useRecruitmentCandidateInformationValues({
        card,
        fields,
        isVisibleResumes,
    });

    if (!values) {
        return null;
    }

    return (
        <div>
            {
                values.map((item, index) => {
                    const isLast = isLastElementInArray(values, index);

                    return (
                        <div
                            key={`${item.label}${index}`}
                            className={!isLast ? "mb-3 mb-xl-4" : undefined}
                        >
                            {item.content}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default RecruitmentCandidateInformation;
