import {all, put, select, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {BRIGADE_TOAST_MESSAGE} from "../containers/brigade/foreman-requests/foremanRequests";
import {getNonDecisionCount} from "./clientSpecialityOffer";

import {ls, USER_ROLE} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {ORDER_ERROR_CODE} from "../constants/order";
import {
    ADMIN,
    CLIENT_ADMIN,
    FOREMAN,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../constants/roles";

const controller = "/foreman/requests";

const GET_FOREMAN_REQUESTS_RICH_PAGE_QUERY = "GET_FOREMAN_REQUESTS_RICH_PAGE_QUERY";
const GET_FOREMAN_REQUESTS_RICH_PAGE_SUCCESS = "GET_FOREMAN_REQUESTS_RICH_PAGE_SUCCESS";
const GET_FOREMAN_REQUESTS_RICH_PAGE_ERROR= "GET_FOREMAN_REQUESTS_RICH_PAGE_ERROR";

const UPDATE_FOREMAN_REQUEST_QUERY = "UPDATE_FOREMAN_REQUEST_QUERY";
const UPDATE_FOREMAN_REQUEST_SUCCESS = "UPDATE_FOREMAN_REQUEST_SUCCESS";
const UPDATE_FOREMAN_REQUEST_ERROR = "UPDATE_FOREMAN_REQUEST_ERROR";

const GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_QUERY = "GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_QUERY";
const GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS = "GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS";
const GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR = "GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR";

const initialState = {
    pageData: {},
    list: [],
    progressList: false,
    progressUpdate: false,
    totalCount: 0,
    newRequestsAndPaymentsCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS:
        return {
            ...state,
            newRequestsAndPaymentsCount: payload,
        };
    case GET_FOREMAN_REQUESTS_RICH_PAGE_QUERY:
        return {
            ...state,
            progressList: true,
            pageData: payload,
        };
    case UPDATE_FOREMAN_REQUEST_QUERY:
        return {
            ...state,
            progressUpdate: true,
            progressList: true,
        };
    case GET_FOREMAN_REQUESTS_RICH_PAGE_SUCCESS:
        const {foremanRequests, totalCount} = payload;

        return {
            ...state,
            list: foremanRequests,
            progressList: false,
            totalCount,
        };
    case GET_FOREMAN_REQUESTS_RICH_PAGE_ERROR:
        return {
            ...state,
            progressList: false,
        };
    case UPDATE_FOREMAN_REQUEST_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
        };
    case UPDATE_FOREMAN_REQUEST_ERROR:
        return {
            ...state,
            progressUpdate: false,
            progressList: false,
        };
    case GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR:
        return {
            ...state,
            error: payload,
        };
    default:
        return state;
    }
};

export function getNewRequestsAndPaymentsCount() {
    return {
        type: GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_QUERY,
    };
}

export function getForemanRequestRichPage(payload) {
    return {
        type: GET_FOREMAN_REQUESTS_RICH_PAGE_QUERY,
        payload,
    };
}

export function updateForemanRequest(payload) {
    return {
        type: UPDATE_FOREMAN_REQUEST_QUERY,
        payload,
    };
}

export const foremanRequestsSelector = state => state.foremanRequests;
export const foremanRequestsListSelector = createSelector(foremanRequestsSelector, ({list}) => list);
export const foremanRequestsTotalPagesSelector = createSelector(foremanRequestsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const foremanProgressListSelector = createSelector(foremanRequestsSelector, ({progressList}) => progressList);
export const foremanNewRequestsAndPaymentsCountSelector = createSelector(foremanRequestsSelector, ({newRequestsAndPaymentsCount}) => newRequestsAndPaymentsCount);
export const foremanRequestsProgressUpdateSelector = createSelector(foremanRequestsSelector, ({progressUpdate}) => progressUpdate);
export const foremanRequestsTotalCountSelector = createSelector(foremanRequestsSelector, ({totalCount}) => totalCount);

function* getRichForemanRequestsSaga({payload}) {
    try {
        const result = yield request.post(`${controller}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_REQUESTS_RICH_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_REQUESTS_RICH_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_REQUESTS_RICH_PAGE_ERROR,
            payload: error,
        });
    }
}

function* updateForemanRequestSaga({payload}) {
    try {
        const {
            close,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/update`, reqData);

        const {errorMessage, errorCode} = result;

        if (close && ![ORDER_ERROR_CODE.OBJECT_AMOUNT_SMALL, ORDER_ERROR_CODE.INVALID_ORDER_AMOUNT_PER_CONTRACTOR].includes(errorCode)) {
            close();
        }

        if (errorMessage) {
            yield put({
                type: UPDATE_FOREMAN_REQUEST_ERROR,
                payload: errorMessage,
            });

            toastError(errorMessage);

            return {
                done: true,
            };
        }

        toastSuccess(BRIGADE_TOAST_MESSAGE.SUCCESS_UPDATE);

        const state = yield select();

        yield put(getForemanRequestRichPage(state.foremanRequests.pageData));

        yield put({
            type: UPDATE_FOREMAN_REQUEST_SUCCESS,
            payload: result,
        });

        const userRole = ls(USER_ROLE);

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(userRole)) {
            yield put(getNewRequestsAndPaymentsCount());
        }

        const {offeredSpecialityName} = reqData;

        // Обновляем счетчик справочника ВД, если предложен свой ВД
        if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
            yield put(getNonDecisionCount());
        }
    } catch (error) {
        toastError(BRIGADE_TOAST_MESSAGE.ERROR_UPDATE);

        yield put({
            type: UPDATE_FOREMAN_REQUEST_ERROR,
            payload: error,
        });
    }
}

function* getNewRequestsAndPaymentsCountSaga() {
    try {
        const result = yield request.post(`${controller}/getNewRequestsAndPaymentsCount`);

        yield put({
            type: GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_FOREMAN_REQUESTS_RICH_PAGE_QUERY, getRichForemanRequestsSaga),
        takeEvery(UPDATE_FOREMAN_REQUEST_QUERY, updateForemanRequestSaga),
        takeEvery(GET_NEW_REQUESTS_AND_PAYMENTS_COUNT_QUERY, getNewRequestsAndPaymentsCountSaga),
    ]);
}

