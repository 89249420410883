import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {dictionaryToOptions} from "../../../utils/objectHelper";
import {getSameBankTypeCard} from "../../../utils/paymentMethodHelper";
import {handleNumber} from "../../../utils/stringHelper";
import validate from "../../../utils/validate";

import {BANK_TYPE} from "../../../constants/clientSettings";
import {DEPOSIT_REPLENISHMENT_ACCOUNT} from "../../../constants/deposit/fields";
import {PAYMENT_TYPE_TRANSLATE} from "../../../constants/settings";
import {depositReplenishmentAccountRule, requiredMessage} from "../../../constants/validationRules";

import {
    getClientsSettingsPayments,
    updateStoreClientsSetting,
} from "../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsPaymentsProgressSelector,
    bffClientsSettingsPaymentsSelector,
} from "../../../ducks/bff/clients/settings/selectors";
import {clientObjectOptionsSelector, getClientObjectList} from "../../../ducks/clientObject";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {getDepositReplenishmentAccountBasisFile} from "../../../ducks/documents";

import {depositFundsDependedType} from "../../../types";
import PropTypes from "prop-types";

class DepositReplenishmentAccountModal extends Component {
    static propTypes = {
        close: PropTypes.func,
        dependentData: depositFundsDependedType,
    };

    static defaultProps = {
        close: () => {
        },
        dependentData: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {
                [DEPOSIT_REPLENISHMENT_ACCOUNT.TOTAL_AMOUNT]: null,
                [DEPOSIT_REPLENISHMENT_ACCOUNT.BANK_TYPE]: BANK_TYPE.PLUTONIUM_W1.VALUE,
            },
            errorForm: {},
            isOpenConfirm: false,
            sameBankTypeCard: null,
        };
    }

    get localizationData() {
        const {t} = this.props;
        return {
            title: t("deposit-generate-account-modal.title"),
            toFormBtn: t("button.to-form"),
            cancelBtn: t("button.cancel"),
            totalAmountLabel: t("deposit-generate-account-modal.total-amount-label"),
        };
    }

    componentDidMount() {
        const {
            getClientsSettingsPayments,
            dependentData: {
                clientId,
            },
        } = this.props;

        this.fetchObjects();

        getClientsSettingsPayments({clientId});
    };

    componentDidUpdate(prevProps) {
        const {clientSettingsPayments: _clientSettingsPayments} = prevProps;
        const {clientSettingsPayments} = this.props;

        if (!isEmpty(clientSettingsPayments) && !isEqual(clientSettingsPayments, _clientSettingsPayments)) {
            this.setState(prevState => ({
                ...prevState,
                sameBankTypeCard: getSameBankTypeCard({
                    paymentsSettings: clientSettingsPayments,
                }),
            }));
        }
    }

    componentWillUnmount() {
        const {
            updateStoreClientsSetting,
        } = this.props;

        updateStoreClientsSetting({payments: {}});
    };

    fetchObjects = () => {
        const {
            getClientObjectList,
            dependentData: {
                clientId,
            },
        } = this.props;
        const {valueObjectFilter} = this.state;

        getClientObjectList({
            clientId,
            clientUserId: this.clientUserId,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            status: "IN_WORK",
            nameSubstringFilter: valueObjectFilter,
        });
    };

    onSearchChangeObject = (valueObjectFilter) => {
        this.setState(prevState => ({
            ...prevState,
            valueObjectFilter,
        }),this.fetchObjects);
    };

    handleOnChange = (e, {value, name, checked}) => {
        const {objectOptions} = this.props;

        const _state = {};

        if (name === "isAutoDeposit" && !checked) {
            _state["objectId"] = null;
        }

        if (name === "isAutoDeposit" && checked && objectOptions.length === 1) {
            _state["objectId"] = objectOptions[0]?.value;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ..._state,
                [name]: value || checked,
            },
        }));
    };

    send = () => {
        const {
            dependentData: {
                clientId,
            },
            close,
            getDepositReplenishmentAccountBasisFile,
        } = this.props;

        const {
            form,
            sameBankTypeCard,
        } = this.state;

        const isValid = this.validateForm(form);

        if (!isValid) {
            return;
        }

        const {
            totalAmount,
            objectId,
            orderSource,
        } = form;

        const data = {
            clientId,
            totalAmount: handleNumber(totalAmount),
            objectId: objectId ?  objectId : undefined,
            orderSource,
            bankType: sameBankTypeCard ? sameBankTypeCard : undefined,
        };

        getDepositReplenishmentAccountBasisFile(data);

        setTimeout(close, 300);
    };

    validateForm(formData) {
        const {
            form: {
                isAutoDeposit,
            },
            sameBankTypeCard,
        } = this.state;

        const orderSourceRule = !sameBankTypeCard ? {
            orderSource: {
                required: requiredMessage,
            },
        } : {};
        const objectRule = isAutoDeposit ? {
            objectId: {
                required: requiredMessage,
            },
        } : {};

        const rule = {
            ...depositReplenishmentAccountRule,
            ...orderSourceRule,
            ...objectRule,
        };

        const errorForm = validate(formData, rule, "");

        this.setState({errorForm: {...errorForm}});

        return Object.values(errorForm).length === 0;
    }

    renderActions() {
        const {
            close,
        } = this.props;
        const {
            toFormBtn,
            cancelBtn,
        } = this.localizationData;

        return (
            <ApplyButtons
                onClose={close}
                submitBtnContent={toFormBtn}
                cancelBtnContent={cancelBtn}
                submit={this.send}
            />
        );
    }

    render() {
        const {
            form,
            errorForm,
            sameBankTypeCard,
        } = this.state;

        const {
            close,
            objectOptions,
            clientProperties: {
                depositDistributedByObjects,
            },
            clientSettingsPaymentsProgress,
            clientSettingsPayments,
        } = this.props;

        const {
            title,
            totalAmountLabel,
        } = this.localizationData;

        return (
            <NmModal
                size="md"
                className="deposit-generate-account-modal"
                onClose={close}
                footer={this.renderActions()}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                loading={clientSettingsPaymentsProgress}
            >
                {
                    !clientSettingsPaymentsProgress &&
                    !isEmpty(clientSettingsPayments) &&
                    <NmForm addMargin>
                        {
                            !sameBankTypeCard &&
                            <NmDropdownV2
                                required
                                label="Проведение оплаты"
                                placeholder="Выберите способ проведения оплаты"
                                name="orderSource"
                                options={dictionaryToOptions(PAYMENT_TYPE_TRANSLATE)}
                                value={form.orderSource}
                                onChange={this.handleOnChange}
                                error={errorForm.orderSource}
                            />
                        }
                        <NmInputV2
                            size="xl"
                            label={totalAmountLabel}
                            type="number"
                            error={errorForm[DEPOSIT_REPLENISHMENT_ACCOUNT.TOTAL_AMOUNT]}
                            required
                            name={DEPOSIT_REPLENISHMENT_ACCOUNT.TOTAL_AMOUNT}
                            value={form[DEPOSIT_REPLENISHMENT_ACCOUNT.TOTAL_AMOUNT]}
                            onChange={this.handleOnChange}
                        />
                        {
                            depositDistributedByObjects &&
                            <>
                                <NmCheckboxV2
                                    className="mt-2 mb-2"
                                    label="Автоматически пополнять депозит объекта"
                                    checked={form.isAutoDeposit}
                                    name="isAutoDeposit"
                                    onChange={this.handleOnChange}
                                    isVisibleTooltip
                                    tooltip={
                                        <HelpTooltip
                                            info
                                            text="При поступлении указанной суммы средств на депозит компании в течение 72 часов с момента создания счет-основания произойдет автоматическое пополнение депозита выбранного объекта. Иначе денежные средства останутся в нераспределенном депозите компании."
                                            position="right"
                                        />
                                    }
                                />
                                <NmDropdownV2
                                    disabled={!form.isAutoDeposit}
                                    search
                                    placeholder="Выберите объект"
                                    label="Объект"
                                    name="objectId"
                                    onChange={this.handleOnChange}
                                    onSearchChange={this.onSearchChangeObject}
                                    value={form.objectId}
                                    options={objectOptions}
                                    error={errorForm.objectId}
                                />
                            </>
                        }
                    </NmForm>
                }
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        objectOptions: clientObjectOptionsSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
        clientSettingsPayments: bffClientsSettingsPaymentsSelector(state),
        clientSettingsPaymentsProgress: bffClientsSettingsPaymentsProgressSelector(state),
    }),
    {
        getDepositReplenishmentAccountBasisFile,
        getClientObjectList,
        getClientsSettingsPayments,
        updateStoreClientsSetting,
    },
)(withTranslation()(DepositReplenishmentAccountModal));
