import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getRichContractorById} from "../../../../../../ducks/contractor";
import {
    getContractorAdditionalDocumentAggregation,
    updateContractorAdditionalDocumentStore,
} from "../../../../../../ducks/contractorAdditionalDocuments";
import {
    getContractorsAdditionalDocumentTypeDict,
} from "../../../../../../ducks/contractorsDicts";

export const useContractorAdditionalDocumentsVerificationFetch = (params) => {
    const {
        contractorId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContractorsAdditionalDocumentTypeDict());
        dispatch(getContractorAdditionalDocumentAggregation({
            contractorId,
        }));
        dispatch(getRichContractorById(contractorId));

        return () => {
            dispatch(updateContractorAdditionalDocumentStore({
                documentsAggregation: {},
            }));
            dispatch(updateContractorAdditionalDocumentStore({
                document: {
                    data: {},
                },
            }));
        };
    }, []);

    return null;
};