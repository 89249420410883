import {all, put, takeEvery} from "@redux-saga/core/effects";
import {isEmpty} from "lodash";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../constants/contractor";

const controller = "/contractor/additional-documents";

const CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST";
const CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_SUCCESS";
const CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR";

const CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE = "CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE";

const initial = {
    document: {
        data: {},
    },
    documentsAggregation: {},
    progressDecide: false,
    progressAggregation: false,
    progressRequest: false,
    progressUpdateData: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST:
        return {
            ...state,
            progressUpdateData: true,
        };
    case CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS:
    case CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR:
        return {
            ...state,
            progressUpdateData: false,
        };
    case CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS:
        return {
            ...state,
            document: payload.result,
        };
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST:
        return {
            ...state,
            progressAggregation: true,
        };
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS:
        return {
            ...state,
            progressAggregation: false,
            documentsAggregation: payload,
        };
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR:
        return {
            ...state,
            progressAggregation: false,
        };
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST: {
        return {
            ...state,
            progressDecide: true,
        };
    }
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS: {
        return {
            ...state,
            progressDecide: false,
        };
    }
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR: {
        return {
            ...state,
            progressDecide: false,
        };
    }
    case CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default: return state;
    }
};

export const decideContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST,
        payload,
    };
};

export const getContractorAdditionalDocumentAggregation = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST,
        payload,
    };
};

export const updateContractorAdditionalDocumentStore = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENTS_UPDATE_STORE,
        payload,
    };
};

export const getContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST,
        payload,
    };
};

export const requestContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST,
        payload,
    };
};

export const updateDataContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST,
        payload,
    };
};

export const deleteContractorAdditionalDocument = (payload) => {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST,
        payload,
    };
};

const contractorAdditionalDocumentsSelector = ({contractorAdditionalDocuments}) => contractorAdditionalDocuments;
export const contractorAdditionalDocumentsProgressDecideSelector = createSelector(contractorAdditionalDocumentsSelector, ({progressDecide}) => progressDecide);
export const contractorAdditionalDocumentsProgressAggregationSelector = createSelector(contractorAdditionalDocumentsSelector, ({progressAggregation}) => progressAggregation);
export const contractorAdditionalDocumentsProgressRequestSelector = createSelector(contractorAdditionalDocumentsSelector, ({progressRequest}) => progressRequest);
export const contractorAdditionalDocumentsProgressUpdateDataSelector = createSelector(contractorAdditionalDocumentsSelector, ({progressUpdateData}) => progressUpdateData);
export const contractorAdditionalDocumentsFileSelector = createSelector(contractorAdditionalDocumentsSelector, ({document}) => document);
export const contractorAdditionalDocumentsAggregationSelector = createSelector(contractorAdditionalDocumentsSelector, ({documentsAggregation}) => documentsAggregation);
export const contractorIsAdditionalDocumentsAdministratorCheckSelector = createSelector(contractorAdditionalDocumentsSelector, ({documentsAggregation}) => {
    if (isEmpty(documentsAggregation)) {
        return false;
    }

    return Object.keys(documentsAggregation).some((fileType) => {
        return documentsAggregation[fileType].status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK;
    });
});
// Типы сканов доп. документов для проверки
export const contractorAdditionalDocumentsAdministratorCheckListSelector = createSelector(contractorAdditionalDocumentsSelector, ({documentsAggregation}) => {
    return Object.keys(documentsAggregation).filter((fileType) => {
        return documentsAggregation[fileType].status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK;
    });
});
export const contractorAdditionalDocumentsAdministratorCheckFileTypesSelector = createSelector(contractorAdditionalDocumentsSelector, ({documentsAggregation}) => {
    return Object.keys(documentsAggregation).filter((fileType) => {
        return documentsAggregation[fileType].status === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK;
    }).reduce((result, fileType) => {
        const filesInfo = documentsAggregation[fileType]?.filesInfo;

        if (!filesInfo) {
            return result;
        }

        const data = Object.keys(filesInfo).reduce((accum, scanFileType) => {
            const fileInfo = filesInfo[scanFileType];

            if (!fileInfo) {
                return accum;
            }

            const {
                fileId,
                contractorId,
            } = fileInfo;

            return {
                ...accum,
                [scanFileType]: {
                    fileId,
                    fileType: scanFileType,
                    changeId: documentsAggregation[fileType].changeId,
                    contractorId,
                },
            };
        }, {});

        return {
            ...result,
            ...data,
        };
    }, {});
});

function* decideContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/decide`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError(result);

            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_ERROR,
            payload: error,
        });
    }
}

function* getContractorAdditionalDocumentAggregationSaga({payload}) {
    try {
        const {
            contractorId,
            getResult = () => {},
        } = payload;
        const result = yield request.get(`${controller}/get/aggregation`, {params: {contractorId}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR,
            });

            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR,
        });
    }
}


function* getContractorAdditionalDocumentsSaga({payload}) {
    try {
        const {
            contractorId,
            changeId,
        } = payload;
        const result = yield request.get(`${controller}/get`, {params: {contractorId, changeId}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_GET_ERROR,
        });
    }
}

function* requestContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/request`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Запрос документа успешно выполнен");

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST_ERROR,
            payload: error,
        });
    }
}

function* updateDataContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Данные успешно обновлены");
        onSuccess();

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR,
            payload: error,
        });
    }
}

function* deleteContractorAdditionalDocumentSaga({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/delete`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Документ успешно удален");

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENTS_DECIDE_REQUEST, decideContractorAdditionalDocumentSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST, getContractorAdditionalDocumentAggregationSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_GET_REQUEST, getContractorAdditionalDocumentsSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_REQUEST, requestContractorAdditionalDocumentSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST, updateDataContractorAdditionalDocumentSaga),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_DELETE_REQUEST, deleteContractorAdditionalDocumentSaga),
    ]);
}