export const HEADER_MOBILE_ACTIONS = {
    ARCHIVE: "ARCHIVE",
    CREATE_TEMPLATE: "CREATE_TEMPLATE",
    FILTER: "FILTER",
};

export function getHeaderMobileOrderTemplatesOptions(archived) {
    if (archived) {
        return [
            {
                key: HEADER_MOBILE_ACTIONS.ARCHIVE,
                text: "Список",
                value: HEADER_MOBILE_ACTIONS.ARCHIVE,
            },
            {
                key: HEADER_MOBILE_ACTIONS.FILTER,
                text: "Фильтр",
                value: HEADER_MOBILE_ACTIONS.FILTER,
            },
        ];
    }

    return [
        {
            key: HEADER_MOBILE_ACTIONS.CREATE_TEMPLATE,
            text: "Создать шаблон",
            value: HEADER_MOBILE_ACTIONS.CREATE_TEMPLATE,
        },
        {
            key: HEADER_MOBILE_ACTIONS.ARCHIVE,
            text: "Архив",
            value: HEADER_MOBILE_ACTIONS.ARCHIVE,
        },
        {
            key: HEADER_MOBILE_ACTIONS.FILTER,
            text: "Фильтр",
            value: HEADER_MOBILE_ACTIONS.FILTER,
        },

    ];
}