import React, {useEffect} from "react";

import {LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS} from "../../constants/links";

import {history} from "../../store/configureStore";

const BoardConnect = (props) => {
    const {
        location,
    } = props;

    useEffect(() => {
        if (!location.search) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        const state = searchParams.get("state");

        if (!state) {
            return;
        }

        const [clientId] = state.split(",");

        if (!clientId) {
            return;
        }

        history.push(`${LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS.replace(":clientId", clientId)}${location.search}`);
    }, []);

    return (
        <>
            Идет авторизация, пожалуйста подождите...
        </>
    );
};

export default BoardConnect;