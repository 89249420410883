import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";
import {Divider} from "semantic-ui-react";

import validate from "../../../../utils/validate";

import {CARD_TYPE, CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {clientCardRule} from "../../../../constants/validationRules";

import {getBankInfoSelector, getClientCardSelector, updateClient} from "../../../../ducks/client";

import PropTypes from "prop-types";

class BankCardBlock extends Component {
    static propTypes = {
        clientType: PropTypes.object,
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        clientType: {},
        isEditable: true,
    };

    constructor() {
        super();

        this.state = {
            isEdit: false,
            errorForm: {},
        };
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            bankInfo: _bankInfo,
            isEdit,
        } = state;
        const {bankInfo} = newProps;

        if (
            JSON.stringify(bankInfo) !== JSON.stringify(_bankInfo) &&
            !isEdit
        ) {
            return {
                ...state,
                editBankInfo: {
                    ...bankInfo,
                },
                bankInfo,
            };
        }

        return null;
    }

    submitBankInfo = () => {
        const {updateClient, bankInfo, client} = this.props;

        const {editBankInfo} = this.state;

        if (JSON.stringify(editBankInfo) === JSON.stringify(bankInfo)) {
            return;
        }

        const requestData = {
            ...client,
            ...editBankInfo,
        };

        updateClient(requestData);
    };

    isValidEditClient(editClient) {
        const {clientType: {isForeignClient}} = this.props;
        const nameRule = !isForeignClient ? CARD_TYPE.BANK_INFO : CARD_TYPE.BANK_FOREIGN_INFO;
        const rule = clientCardRule[nameRule];
        const errorForm = validate(editClient, rule);

        this.setState(prevState => ({
            ...prevState,
            errorForm,
        }));

        return Object.values(errorForm).length === 0;
    }

    cancelEdit = () => {
        const {editBankInfoCopy} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            errorForm: {},
            editBankInfo: editBankInfoCopy,
        }));
    };

    toggleCard = () => {
        const {isEdit, editBankInfo} = this.state;

        if (isEdit) {
            if (!this.isValidEditClient(editBankInfo)) {
                return;
            }
        } else {
            this.setState({
                editBankInfoCopy: editBankInfo,
            });
        }

        this.setState(
            prevState => ({isEdit: !prevState.isEdit}),
            () => {
                if (isEdit) {this.submitBankInfo();}
            },
        );
    };

    handleOnChangeAddress(name) {
        return data => this.handleChange(data.event, {value: data.value, name});
    }

    handleChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            editBankInfo: {
                ...prevState.editBankInfo,
                [name]: value,
            },
        }));
    };

    renderCardEditMode(client) {
        const {errorForm} = this.state;

        const {
            clientType: {isForeignClient},
            t,
        } = this.props;

        return (
            <NmForm addMargin>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={t("client-bank-details-info.bank")}
                            required
                            className="client-form__control"
                            placeholder={t("client-bank-details-info.bank")}
                            name={CLIENT_FIELD_NAME.BANK_NAME}
                            value={client[CLIENT_FIELD_NAME.BANK_NAME] || ""}
                            onChange={this.handleChange}
                            error={errorForm[`${CLIENT_FIELD_NAME.BANK_NAME}ErrorMessage`]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmDadataInput
                            required
                            size="lg"
                            label={t("client-bank-details-info.bank-address")}
                            query={client[CLIENT_FIELD_NAME.BANK_ADDRESS]}
                            error={
                                errorForm[`${CLIENT_FIELD_NAME.BANK_ADDRESS}ErrorMessage`]
                            }
                            name={CLIENT_FIELD_NAME.BANK_ADDRESS}
                            onChange={this.handleOnChangeAddress(CLIENT_FIELD_NAME.BANK_ADDRESS)}
                            placeholder="Адрес банка"
                        />
                    </div>
                </div>
                <Divider className="divider-fluid" />
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={!isForeignClient ? "БИК" : "SWIFT-код"}
                            required
                            className="client-form__control"
                            name={CLIENT_FIELD_NAME.BIC}
                            placeholder={!isForeignClient ? "БИК" : "SWIFT-код"}
                            value={client[CLIENT_FIELD_NAME.BIC] || ""}
                            onChange={this.handleChange}
                            error={errorForm[`${CLIENT_FIELD_NAME.BIC}ErrorMessage`]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={!isForeignClient ? "р/c" : "Номер счета (Account number)"}
                            required
                            className="client-form__control"
                            name={CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT}
                            placeholder={!isForeignClient ? "р/c" : "Номер счета (Account number)"}
                            value={client[CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT] || ""}
                            onChange={this.handleChange}
                            error={
                                errorForm[
                                    `${CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT}ErrorMessage`
                                ]
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <NmInputV2
                            size="lg"
                            label={!isForeignClient ? "к/с" : "Банковский код (ACH Routing number)"}
                            required
                            className="client-form__control"
                            name={CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT}
                            placeholder={!isForeignClient ? "к/с" : "Банковский код (ACH Routing number)"}
                            value={client[CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT] || ""}
                            onChange={this.handleChange}
                            error={
                                errorForm[
                                    `${CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT}ErrorMessage`
                                ]
                            }
                        />
                    </div>
                </div>
            </NmForm>
        );
    }

    renderCardReadOnly(client) {
        const {
            clientType: {isForeignClient},
            t,
        } = this.props;

        return (
            <>
                <LabelTextApp
                    label={t("client-bank-details-info.bank")}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.BANK_NAME] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    className="client-card-block__label client-card-block__label_add-margin client-card-block__bank-address"
                    text={client[CLIENT_FIELD_NAME.BANK_ADDRESS] || t("is-not-specified.content")}
                />
                <Divider className="divider-fluid" />
                <LabelTextApp
                    label={!isForeignClient ? "БИК" : "SWIFT-код"}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.BIC] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={!isForeignClient ? "р/c" : "Номер счета (Account number)"}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT] || t("is-not-specified.content")}
                />
                <LabelTextApp
                    label={!isForeignClient ? "к/с" : "Банковский код (ACH Routing number)"}
                    className="client-card-block__label"
                    text={client[CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT] || t("is-not-specified.content")}
                />
            </>
        );
    }

    render() {
        const {
            isEdit,
            editBankInfo,
        } = this.state;

        const {
            isEditable,
            t,
        } = this.props;

        return (
            <CardApp
                title={t("client-bank-details-info.client-bank-details-title")}
                isEditable={isEditable}
                onClickIcon={this.toggleCard}
                onClickCancelIcon={this.cancelEdit}
                isEdit={isEdit}
            >
                {isEdit
                    ? this.renderCardEditMode(editBankInfo)
                    : this.renderCardReadOnly(editBankInfo)}
            </CardApp>
        );
    }
}

export default connect(state => ({
    bankInfo: getBankInfoSelector(state),
    client: getClientCardSelector(state),
}), {
    updateClient,
})(withTranslation()(BankCardBlock));