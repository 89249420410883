import {useCallback, useState} from "react";

import {useSaveScrollNavigation} from "./useSaveScrollNavigation";

export function usePagination(classScroll = "client-card", defaultPageSize =  25, defaultPageNumber = 1) {
    const {
        state,
    } = useSaveScrollNavigation();

    const [paginationData, setPagination] = useState(state.paginationData || {
        pageNum: defaultPageNumber,
        pageSize: defaultPageSize,
    });

    const {pageNum, pageSize} = paginationData;

    const onChangePageSize = useCallback(pageSize => {
        const paginationData = {pageSize, pageNum: 1};

        setPagination(paginationData);
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onPaginationChange = useCallback((e, {activePage}) => {
        if (activePage === pageNum) {
            return;
        }

        const paginationData = {pageNum: activePage, pageSize};

        setPagination(paginationData);

        document.querySelector(`.${classScroll}`).scrollTo(0, 0);
    }, [classScroll, pageNum, pageSize]);

    return {
        paginationData,
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    };
}