import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {FILTER} from "../../ActualComponents/Filter";

import {getEndDate, getStartDate} from "../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {handleFormString, handleNumber, isNullOrWhitespace} from "../../../utils/stringHelper";

import {SYSTEM_TRANSACTION_TYPE} from "../../../constants/finance";
import {STATUS_OF_TRANSFER_TRANSCRIPTION} from "../../../constants/status";

import {FILTER_TYPE} from "../../../constants/ mailings/segmentationType";

export const useSystemTransactionsFilter = ({transactionNumFilter}) => {
    const statusOptions = useMemo(() => dictionaryToOptions(STATUS_OF_TRANSFER_TRANSCRIPTION, true), []);
    const typeOptions = useMemo(() => dictionaryToOptions(SYSTEM_TRANSACTION_TYPE), []);

    const {t} = useTranslation();

    const initFilter = {
        transactionNumberFilter: transactionNumFilter || "",
        fromDateFilter: null,
        toDateFilter: null,
        typeFilter: null,
        statusFilter: null,
        fioFilter: "",
    };

    function mapFilterDto(filter) {
        const {
            fioFilter,
            fromDateFilter,
            toDateFilter,
            statusFilter,
            typeFilter,
            transactionNumberFilter,
        } = filter;

        return {
            ...filter,
            fioFilter: handleFormString(fioFilter),
            transactionNumberFilter: handleNumber(transactionNumberFilter),
            statusFilter: statusFilter === FILTER_TYPE.ALL ? undefined : statusFilter,
            typeFilter: typeFilter === FILTER_TYPE.ALL ? undefined : typeFilter,
            fromDateFilter: isNullOrWhitespace(fromDateFilter) ? undefined : getStartDate(fromDateFilter),
            toDateFilter: isNullOrWhitespace(toDateFilter) ? undefined : getEndDate(toDateFilter),
        };
    }

    const filters = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    size: "lg",
                    label: t("system-transactions.contractor-filter-text"),
                    name: "fioFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.INPUT,
                    size: "lg",
                    label: t("system-transactions.transaction-num-filter-text"),
                    name: "transactionNumberFilter",
                    type: "number",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    size: "lg",
                    label: t("system-transactions.status-filter-text"),
                    name: "statusFilter",
                    options: statusOptions,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    size: "lg",
                    label: t("system-transactions.type-filter-text"),
                    name: "typeFilter",
                    options: typeOptions,
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    dateFormat: "dd.MM.yy",
                    startFieldName: "fromDateFilter",
                    endFieldName: "toDateFilter",
                    label: "Период транзакции",
                    isClearable: true,
                    value: {
                        fromDateFilter: null,
                        toDateFilter: null,
                    },
                },
            ],
        },
    ];

    return {
        initFilter,
        mapFilterDto,
        filters,
    };
};