import {
    BFF_CROWD_TASK_GROUP_ADD_REQUEST,
    BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_EXPORT_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST,
    BFF_CROWD_TASK_GROUP_GET_REQUEST,
    BFF_CROWD_TASK_GROUP_IMPORT_REQUEST,
    BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASK_GROUP_REMOVE_REQUEST,
    UPDATE_CROWD_TASK_GROUP_FILED_STORE,
} from "./actions";

export const getCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_GET_REQUEST,
        payload,
    };
};

export const getCrowdTaskGroupInGroupPage = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_GET_PAGE_IN_GROUP_REQUEST,
        payload,
    };
};

export const getCrowdTaskGroupOutGroupPage = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_GET_PAGE_OUT_GROUP_REQUEST,
        payload,
    };
};

export const exportCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_EXPORT_REQUEST,
        payload,
    };
};

export const importCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_IMPORT_REQUEST,
        payload,
    };
};

export const closeMultipleCrowdTaskGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_CLOSE_MULTIPLE_REQUEST,
        payload,
    };
};

export const archiveMultipleCrowdTaskGroup  = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_ARCHIVE_ADD_MULTIPLE_REQUEST,
        payload,
    };
};

export const publishMultipleCrowdTaskGroup  = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_PUBLISH_MULTIPLE_REQUEST,
        payload,
    };
};

export const deleteMultipleCrowdTaskGroup  = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_DELETE_MULTIPLE_REQUEST,
        payload,
    };
};

export const addCrowdTasksToGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_ADD_REQUEST,
        payload,
    };
};

export const removeCrowdTaskFromGroup = (payload) => {
    return {
        type: BFF_CROWD_TASK_GROUP_REMOVE_REQUEST,
        payload,
    };
};

export const updateStoreCrowdTaskGroup = (payload) => {
    return {
        type: UPDATE_CROWD_TASK_GROUP_FILED_STORE,
        payload,
    };
};