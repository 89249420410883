import React from "react";

import BadgeAlert from "../../../../../components/ActualComponents/BadgeAlert";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {history} from "../../../../../store/configureStore";

import {LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES} from "../../../../../constants/links";

const TaskPublishSuccessModal = (props) => {
    const {
        data: {
            clientId,
            taskId,
            name = "",
            suitableContractorsCount = 0,
        },
        onClose,
    } = props;

    const goSearchPerformers = () => {
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES
            .replace(":clientId", clientId)
            .replace(":taskId", taskId);

        history.push(link);
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    align="center"
                    submitBtnContent="Перейти к поиску исполнителей"
                    cancelBtnContent="Закрыть"
                    onClose={onClose}
                    submit={goSearchPerformers}
                />
            }
            header={
                <div className="flex flex-column flex-aligned-center">
                    <BadgeAlert
                        success={true}
                    />
                    <NmTitle
                        className="mt-4"
                        size="lg"
                    >
                        Задание опубликовано!
                    </NmTitle>
                </div>
            }
        >
            <Text
                className="text-center"
                level="4"
                preWrap={true}
            >
                {`По вашему заданию «${name}» найдено ${suitableContractorsCount} исполнителей.\nПосмотрите профили кандидатов и предложите задание тем, кто понравился.`}
            </Text>
        </NmModal>
    );
};

export default TaskPublishSuccessModal;