import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearRegistryOfAdditionalAgreementStore,
    getRegistryOfAdditionalAgreement,
    getRegistryOfAdditionalAgreementItemsPage,
    getRegistryOfAdditionalAgreementStatusDict,
} from "../../../../../../ducks/bff/documents/additional-agreements/registry/actionCreators";

export const useAdditionalAgreementCardFetch = (params) => {
    const {
        paginationData,
        filterData,
        id,
    } = params;

    const dispatch = useDispatch();

    const fetchList = () => {
        dispatch(getRegistryOfAdditionalAgreementItemsPage({
            registryIdFilter: id,
            ...paginationData,
            ...filterData,
        }));
    };

    const fetchCard = () => {
        dispatch(getRegistryOfAdditionalAgreement({
            id,
        }));
    };

    useEffect(() => {
        dispatch(getRegistryOfAdditionalAgreementStatusDict());
        fetchCard();

        return () => {
            dispatch(clearRegistryOfAdditionalAgreementStore());
        };
    }, []);


    useEffect(() => {
        fetchList();
    }, [
        paginationData,
        filterData,
    ]);
};