import * as Yup from "yup";

import {transformYupFormattedAmountToNumber} from "../../../../utils/yupTransforms";

import {requiredMessage} from "../../../../constants/validationRules";

export const depositOfFundValidationSchema = Yup.object().shape({
    amount: Yup.number()
        .transform(transformYupFormattedAmountToNumber)
        .required(requiredMessage)
        .moreThan(0, "Введите значение больше 0")
        .lessThan(1000000000, "Сумма должна быть меньше 1\u200a000\u200a000\u200a000 руб."),
    bankType: Yup.string()
        .nullable()
        .required(requiredMessage),
});