import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import NmConfirmV2Content from "../../../components/ActualComponents/NmConfirmV2/Content";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {STOP_LIST_IMPORT_FILE_TYPE} from "../../../constants/stopList";

import PropTypes from "prop-types";

import "./style.sass";

class StopListLoadingFileModal extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
    };

    state = {
        fileObj: {
            [STOP_LIST_IMPORT_FILE_TYPE.IMPORT_STOP_LIST]: [],
        },
    };

    submit = (block) => {
        return () => {
            const {
                onSubmit,
                onCancel,
            } = this.props;
            const {fileObj} = this.state;

            const [file] = fileObj[block];

            const formData = new FormData();

            formData.append("file", file);

            onSubmit(formData);

            onCancel();
        };
    };

    handleOnChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    onDropFile = (files) => {
        const {fileObj} = this.state;

        fileObj[STOP_LIST_IMPORT_FILE_TYPE.IMPORT_STOP_LIST] = files;

        this.setState(prevState => ({
            ...prevState,
            fileObj,
        }));
    };

    render() {
        const {fileObj: {[STOP_LIST_IMPORT_FILE_TYPE.IMPORT_STOP_LIST]: files}} = this.state;
        const {
            onCancel,
            t,
        } = this.props;

        return (
            <NmModal
                size="md"
                className="stop-list-loading-file__modal-upload"
                classNameContent="stop-list-loading-file__modal-upload-content"
                onClose={onCancel}
                header={
                    <NmTitle size="lg">
                        Загрузка стоп-листа из файла
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent="Загрузить"
                        cancelBtnContent="Отменить"
                        onClose={onCancel}
                        submit={this.submit(STOP_LIST_IMPORT_FILE_TYPE.IMPORT_STOP_LIST)}
                        disabled={!files.length}
                    />
                }
            >
                <NmConfirmV2Content
                    warning
                    content={t("stop-list-loading-file.warning-message")}
                />
                <DropzoneV2
                    files={files}
                    multiple={false}
                    maxSize={20}
                    className="mt-4"
                    onChange={this.onDropFile}
                    accept=".xlsx, .csv"
                    format="*.XLSX, *.CSV"
                />
            </NmModal>
        );
    }
}

export default withTranslation()(StopListLoadingFileModal);