import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import Tabs from "../../../components/ActualComponents/Tabs";
import NmTitle from "../../../components/NmTitle";
import SettingsLogRoute from "../routes/settings-log-route";

import {ls, USER_ROLE} from "../../../utils/localstorage";

import {LINK_SETTINGS_EVENT_LOG, LINK_SETTINGS_SIGNING_DOCUMENT_LOG} from "../../../constants/links";

import "./style.sass";

class SettingsLogCommon extends Component {
    constructor(props) {
        super(props);

        this.role = ls(USER_ROLE);
    }

    get link() {
        const eventLog = LINK_SETTINGS_EVENT_LOG;
        const signingDocumentLog = LINK_SETTINGS_SIGNING_DOCUMENT_LOG;

        return {
            eventLog,
            signingDocumentLog,
        };
    }

    get links() {
        const {t} = this.props;
        const {
            eventLog,
            signingDocumentLog,
        } = this.link;

        return [
            {
                active: eventLog,
                link: eventLog,
                name: t("settings.event-log"),
            },
            {
                active: signingDocumentLog,
                link: signingDocumentLog,
                name: t("settings.documents-log"),
            },
        ];
    }

    renderTabs = () => {
        return (
            <Tabs
                {...this.props}
                secondary
                className="settings-logs__tabs"
                panes={this.links}
            />
        );
    }

    render() {
        return (
            <div className="settings-logs">
                <SettingsLogRoute
                    {...this.props}
                    header={
                        <NmTitle size="xl">
                            Логирование
                        </NmTitle>
                    }
                    tabs={this.renderTabs()}
                />
            </div>
        );
    }
}

export default withTranslation()(SettingsLogCommon);
