export const FILTER_SENDER = {
    BY_CONTRACTOR: "BY_CONTRACTOR",
    BY_CLIENT: "BY_CLIENT",
};

export const FILTER_TYPE = {
    ALL: "ALL",
    BY_PARAMS: "BY_PARAMETERS",
    BY_PHONE_NUMBERS: "BY_PHONES",
};

export const MAILINGS_STATUS = {
    PLANNED: {
        TEXT: "Ожидание запуска",
        VALUE: "PLANNED",
        BADGE_MOD: "light-green",
    }, // Ожидание запуска
    IN_PROGRESS: {
        TEXT: "Запущена",
        VALUE: "IN_PROGRESS",
        BADGE_MOD: "green",
    }, // Запущена
    ERROR: {
        TEXT: "Ошибка",
        VALUE: "ERROR",
        BADGE_MOD: "red",
    }, // Ошибка
    COMPLETED: {
        TEXT: "Завершена",
        VALUE: "COMPLETED",
        BADGE_MOD: "gray",
    }, // Завершена
    CANCELLED: {
        TEXT: "Отменена",
        VALUE: "CANCELLED",
        BADGE_MOD: "white",
    }, // Отменена
};

export const SEND_TYPE = {
    IMMEDIATE: {
        VALUE: "IMMEDIATE",
    },
    DELAYED: {
        VALUE: "DELAYED",
    },
};