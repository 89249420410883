import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_UPDATE_STORE,
} from "./actions";

export const getRecruitmentVacancyCandidatesResponsesInitialState = () => {
    return {
        progressAction: false,
        progressCard: false,
        progress: false,
        pageData: {},
        card: {},
        totalCount: 0,
        list: [],
        error: null,
    };
};

const initialState = getRecruitmentVacancyCandidatesResponsesInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR:
        return {
            ...state,
            progressCard: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS:
        return {
            ...state,
            card: payload.result,
            progressCard: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS:
        return {
            ...state,
            progress: false,
            list: state.pageData.isLoadMore ? [...state.list, ...payload.results] : payload.results,
            totalCount: payload.totalCount,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};