import React, {FC} from "react";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import ApplyButtons from "../../ApplyButtons";
import NmTitle from "../../NmTitle";
import NmCheckboxV2 from "../NmCheckboxV2";
import NmForm from "../NmForm";
import NmModal from "../NmModal";
import NmRadioV2 from "../NmRadioV2";

interface IDictEditModal {
    initialValue?: string | Array<string>,
    fields: Record<string, string>,
    type: "checkbox" | "radio",
    title: string,
    progress: boolean,
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void,
    onSubmit: (values: {value: string | Array<string>}) => void,
    multiple?: boolean,
}

const DictVariantsEditModal: FC<IDictEditModal> = (props) => {
    const {
        multiple,
        title,
        type,
        initialValue = "",
        progress,
        onClose,
        onSubmit,
        fields,
    } = props;

    const getInitialValue = () => {
        if (multiple) {
            return initialValue || [];
        }

        return initialValue || "";
    };

    const {
        values,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues: {
            value: getInitialValue(),
        },
        onSubmit,
    });

    const onChange = (value: string) => {
        if (!multiple) {
            setFieldValue("value", value);

            return;
        }

        if (values.value.includes(value) && typeof values.value !== "string") {
            const newValues = values.value.filter(stateValue => stateValue !== value);

            setFieldValue("value", newValues);

            return;
        }


        setFieldValue("value", [...values.value, value]);
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    disabled={isEmpty(values.value) || progress}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin={true}>
                {
                    Object.keys(fields).map(value => {
                        const checked = multiple ?
                            values.value.includes(value) :
                            values.value === value;

                        if (type === "checkbox") {
                            return (
                                <NmCheckboxV2
                                    key={value}
                                    label={fields[value]}
                                    checked={checked}
                                    onChange={() => {
                                        onChange(value);
                                    }}
                                />
                            );
                        }

                        return (
                            <NmRadioV2
                                key={value}
                                label={fields[value]}
                                checked={checked}
                                value={value}
                                onChange={() => {
                                    onChange(value);
                                }}
                            />
                        );
                    })
                }
            </NmForm>
        </NmModal>
    );
};

export default DictVariantsEditModal;