import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

/**
 * Собираем проекты из дерева
 * @param params
 * @returns {*|null}
 */
export const getProjectsFromTree = (params) => {
    const {
        projects,
        clientPropertiesParamSelectorType,
        isVisibleClient,
    } = params;

    if (
        isVisibleClient
        && clientPropertiesParamSelectorType !== CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY
    ) {
        return null;
    }

    return projects.filter(item => {
        if (typeof item.changedCheckedValue === "boolean") {
            return true;
        }

        if (item.partiallyChecked) {
            return false;
        }

        if (!item.changed) {
            return false;
        }

        if (item.checked) {
            return true;
        }

        const objects = item.children;

        if (objects) {
            const isAllObjectsEqualParam = objects.every((item, index, array) => {
                return item.checked === array[0].checked;
            });

            return isAllObjectsEqualParam;
        }

        return true;
    }).map(item => {
        return {
            id: item.value,
            boolParam: typeof item.changedCheckedValue === "boolean" ? item.changedCheckedValue : item.checked,
        };
    });
};