import {useState} from "react";
import {useDispatch} from "react-redux";

import {history} from "../../../../../../store/configureStore";

import {LINK_ADDING_CLIENT_REMOTE_CONTRACTORS} from "../../../../../../constants/links";

import {deleteContractor} from "../../../../../../ducks/clientGroups";

const INITIAL_STATE_MODAL_WINDOW = {
    isShow: false,
    title: "",
    submit: undefined,
};

export function useContractorsActions() {
    const dispatch = useDispatch();

    const [dataModal, setDataModal] = useState({
        ...INITIAL_STATE_MODAL_WINDOW,
    });

    const onCloseModal = () => {
        setDataModal(INITIAL_STATE_MODAL_WINDOW);
    };

    const onClickAddContractor = (groupId) => {
        history.push(LINK_ADDING_CLIENT_REMOTE_CONTRACTORS.replace(":groupId", groupId));
    };

    const onClickDeleteContractor = (item, groupName) => {
        const {
            fio,
            contractorId,
            groupId,
        } = item;
        setDataModal(prevState => ({
            ...prevState,
            isShow: true,
            title: `Удалить исполнителя ${fio} из группы "${groupName}"?`,
            submit: () => dispatch(deleteContractor({
                requestData: {contractorId, groupId},
                meta: onCloseModal,
            })),
        }));
    };

    return {
        dataModal,
        onCloseModal,
        onClickDeleteContractor,
        onClickAddContractor,
    };
}