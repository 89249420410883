import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {
    updateCommonPaymentDicts,
    updateDictionaryFlashCall,
} from "../../../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsFlashCallListSelector} from "../../../../../ducks/bff/common/dicts/selectors";

export const useSettingsDirectoriesFlashCallAction = () => {
    const list = useSelector(bffCommonDictsFlashCallListSelector);

    const [progressUpdateItems, setProgressUpdateItems] = useState([]);

    const dispatch = useDispatch();

    const updateCallsSupported = (id, callsSupported) => {
        setProgressUpdateItems(value => [
            ...value,
            id,
        ]);

        dispatch(updateDictionaryFlashCall({
            id,
            callsSupported,
            onError: () => {
                removeProgressUpdateItem(id);
            },
            onSuccess: () => {
                toastSuccess("Возможность звонков на номера данной страны успешно изменена");
                updateListItem(id, callsSupported);
                removeProgressUpdateItem(id);
            },
        }));
    };

    const removeProgressUpdateItem = (id) => {
        const _progressUpdateItems = [...progressUpdateItems];

        delete _progressUpdateItems[id];

        setProgressUpdateItems(_progressUpdateItems);
    };

    const updateListItem = (id, callsSupported) => {
        const _list = list.map(item => item.codeId === id ? {
            ...item,
            callsSupported,
        } : item);

        dispatch(updateCommonPaymentDicts({flashCallList: _list}));
    };

    return {
        updateCallsSupported,
        progressUpdateItems,
    };
};