import React from "react";

import AmountInput from "../../AmountInput";
import NmInputV2 from "../NmInputV2";
import NmTooltip from "../NmTooltip";

import "./style.sass";

export default function InputWithTooltip(props) {
    const {
        amount,
        typeTooltip = "light",
        positionTooltip = "bottom-left",
        tooltip = "",
        isShowTooltip = false,
        ...otherProps
    } = props;

    function renderSuggestion() {
        if (!isShowTooltip) {
            return null;
        }
        return (
            <NmTooltip
                type={typeTooltip}
                className="input-with-tooltip__tooltip"
                children={tooltip}
                position={positionTooltip}
            />
        );
    }

    return amount ? <AmountInput
        suggestion={renderSuggestion()}
        {...otherProps}
    /> :
        <NmInputV2
            suggestion={renderSuggestion()}
            {...otherProps}
        />;
}