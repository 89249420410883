import {useState} from "react";

export default function useClientListTemplateModal() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [clientUsageData, setClientUsageData] = useState({});

    const openModal = (data = {}) => {
        setIsOpenModal(prevState => !prevState);
        setClientUsageData(data);
    };

    const closeModal = () => {
        setIsOpenModal(prevState => !prevState);
        setClientUsageData({});
    };

    return {
        isOpenModal,
        openModal,
        closeModal,
        clientUsageData,
    };
}