import * as yup from "yup";

import {addDays, addYears, calculateAge, calculateAgeToDate, getStartDate} from "../../../../utils/dateFormat";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTOR_FIELD, EDM_PASSPORT_TYPE} from "../../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../../constants/contractorInfo";

const AGES_FOR_EDM_STATUS = {
    FOUR: 4,
    TEN: 10,
};

const BIRTH_DATE_LIMIT_TO_KG = 55;

const USER_AGE_LIMIT_UA = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 16,
    THIRD_LIMIT: 18,
};

const USER_AGE_LIMIT_UZ = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 16,
};

const YEARS_FOR_CHECK_PASSPORT_DATE_END = {
    UA: 10,
    BY: 10,
    TJ: 10,
    AM: {
        FIRST_CHECK: 10,
        SECOND_CHECK: 15,
    },
    KZ: 10,
    KG: 10,
    UZ: {
        FIRST_CHECK: 5,
        SECOND_CHECK: 10,
    },
};

function dateComparison({passportDate, passportDateEnd, numberYears}) {
    const result = addYears(passportDate, numberYears);
    const leftPartEquation = getStartDate(result);
    const rightPartEquation = getStartDate(new Date(passportDateEnd));

    return leftPartEquation.getTime() === rightPartEquation.getTime();
}

const passportDateLimitationBY = new Date(2012, 6, 19); //Дата выдачи новых образцов паспортов в Беларуси

export const getFullRegistrationValidToDateValidation = () => {
    return yup.string()
        .nullable()
        /*.when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.UA.value,
            then: yup.string()
                .nullable()
                .test('check', "Некорректная дата окончания срока действия паспорта", function (value) {
                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
                    const birthDate = this.parent[CONTRACTOR_FIELD.BIRTH_DATE];
                    const edmPassportType = this.parent[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE];

                    if (!value || !passportDate || !birthDate || !edmPassportType) {
                        return true;
                    }

                    if (getStartDate(value) < getStartDate(new Date())) {
                        return this.createError({
                            message: "Срок действия документа истек",
                            path: CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE
                        })
                    }

                    const userAgeTimeIssue = calculateAgeToDate(birthDate, passportDate);

                    if (userAgeTimeIssue < USER_AGE_LIMIT_UA.FIRST_LIMIT) {
                        return true;
                    }

                    const getNumberYearsByUserAgeTimeIssueNew = (firstLimit, secondLimit) => {
                        if (userAgeTimeIssue >= firstLimit && userAgeTimeIssue < secondLimit) {
                            return AGES_FOR_EDM_STATUS.FOUR;
                        }

                        if (userAgeTimeIssue >= secondLimit) {
                            return AGES_FOR_EDM_STATUS.TEN;
                        }
                    };

                    return dateComparison({
                        passportDate,
                        passportDateEnd: value,
                        numberYears: getNumberYearsByUserAgeTimeIssueNew(
                            USER_AGE_LIMIT_UA.FIRST_LIMIT,
                            edmPassportType === EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE ?
                                USER_AGE_LIMIT_UA.SECOND_LIMIT :
                                USER_AGE_LIMIT_UA.THIRD_LIMIT
                        )
                    })
                })
        })*/
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.BY.value,
            then: yup.string()
                .nullable()
                .test("check", "Дата окончания срока действия документа не может быть меньше или больше 10 лет с даты выдачи документа", function (value) {
                    if (!value) {
                        return true;
                    }

                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

                    if (new Date(passportDate) > passportDateLimitationBY) {
                        const result = addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.BY);
                        const leftPartEquation = getStartDate(result);
                        const rightPartEquation = getStartDate(new Date(value));

                        return leftPartEquation.getTime() === rightPartEquation.getTime();
                    }

                    return true;
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.TJ.value,
            then: yup.string()
                .nullable()
                .test("check", "Дата окончания срока действия документа не может быть меньше или больше 10 лет с даты выдачи документа", function (value) {
                    if (!value) {
                        return true;
                    }

                    if (getStartDate(value) < getStartDate(new Date())) {
                        return this.createError({
                            message: "Срок действия документа истек",
                            path: CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
                        });
                    }

                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

                    const result = addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.TJ);
                    const leftPartEquationOne = getStartDate(addDays(result, -1));
                    const leftPartEquationTwo = getStartDate(result);
                    const rightPartEquation = getStartDate(new Date(value));

                    return [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime());
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.AM.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата окончания срока действия паспорта", function (value) {
                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

                    if (!passportDate || !value) {
                        return true;
                    }

                    if (getStartDate(value) < getStartDate(new Date())) {
                        return this.createError({
                            message: "Срок действия документа истек",
                            path: CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
                        });
                    }

                    const result1 = getStartDate(addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.AM.FIRST_CHECK));
                    const result2 = getStartDate(addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.AM.SECOND_CHECK));
                    const _value = getStartDate(value);

                    return [result1.getTime(), result2.getTime()].includes(new Date(_value).getTime());
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.KZ.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата окончания срока действия паспорта", function (value) {
                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

                    if (!passportDate || !value) {
                        return true;
                    }

                    if (getStartDate(value) < getStartDate(new Date())) {
                        return this.createError({
                            message: "Срок действия документа истек",
                            path: CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
                        });
                    }

                    const result = addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.KZ);
                    const leftPartEquationOne = getStartDate(addDays(result, -1));
                    const leftPartEquationTwo = getStartDate(result);
                    const rightPartEquation = getStartDate(new Date(value));

                    return [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime());
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.KG.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата окончания срока действия паспорта", function (value) {
                    console.log("value: ", value);

                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

                    if (!passportDate || !value) {
                        return true;
                    }

                    if (getStartDate(value) < getStartDate(new Date())) {
                        return this.createError({
                            message: "Срок действия документа истек",
                            path: CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
                        });
                    }

                    const passportType = this.parent[CONTRACTOR_FIELD.PASSPORT_TYPE];
                    const birthDate = this.parent[CONTRACTOR_FIELD.BIRTH_DATE];

                    if (passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT && calculateAge(birthDate) > BIRTH_DATE_LIMIT_TO_KG) {
                        return true;
                    }

                    const result = addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.KG);
                    const leftPartEquationOne = getStartDate(addDays(result, -1));
                    const leftPartEquationTwo = getStartDate(result);
                    const rightPartEquation = getStartDate(new Date(value));

                    return [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime());
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.UZ.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата окончания срока действия паспорта", function (value) {
                    const passportDate = this.parent[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
                    const birthDate = this.parent[CONTRACTOR_FIELD.BIRTH_DATE];
                    const userAges = calculateAge(birthDate);

                    if (!passportDate || !value || !userAges) {
                        return true;
                    }

                    if (getStartDate(value) < getStartDate(new Date())) {
                        return this.createError({
                            message: "Срок действия документа истек",
                            path: CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
                        });
                    }

                    if ((userAges >= USER_AGE_LIMIT_UZ.FIRST_LIMIT) && (userAges < USER_AGE_LIMIT_UZ.SECOND_LIMIT)) {
                        const checkDate = addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.UZ.FIRST_CHECK);
                        const leftPartEquationOne = getStartDate(addDays(checkDate, -1));
                        const leftPartEquationTwo = getStartDate(checkDate);
                        const rightPartEquation = getStartDate(new Date(value));

                        return [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime());
                    }

                    if (userAges >= USER_AGE_LIMIT_UZ.SECOND_LIMIT) {
                        const checkDate = addYears(passportDate, YEARS_FOR_CHECK_PASSPORT_DATE_END.UZ.SECOND_CHECK);
                        const leftPartEquationOne = getStartDate(addDays(checkDate, -1));
                        const leftPartEquationTwo = getStartDate(checkDate);
                        const rightPartEquation = getStartDate(new Date(value));

                        return [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime());
                    }

                    return false;
                }),
        });
};