import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useModal} from "../../../../../../../hooks/useModal";

import {ls, USER_ROLE} from "../../../../../../../utils/localstorage";
import {toastSuccess} from "../../../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../../../components/ActualComponents/MediaControls/constants";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR} from "../../../../../../../constants/roles";

import {
    deleteOnboardingScenarioLead,
    exportOnboardingScenarioLeads,
    importOnboardingScenarioLeads,
    onboardingScenarioProgressActionSelector,
} from "../../../../../../../ducks/onboarding";
import {refreshStatusesOnboardingScenarioContactors} from "../../../../../../../ducks/onboardingContractors";

export function useActionOnboardingLeadsList(params) {
    const {
        fetchList,
        clientId,
        scenarioId,
    } = params;

    const role = ls(USER_ROLE);

    const progressAction = useSelector(onboardingScenarioProgressActionSelector);

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const dispatch = useDispatch();

    const onSubmitImportList = ({formData}) => {
        dispatch(importOnboardingScenarioLeads({
            formData,
            clientId,
            scenarioId,
            onSuccess: () => {
                toastSuccess("Задача на добавление лидов создана");
                setIsImportModalOpen(false);
            },
        }));
    };

    const exportList = () => {
        dispatch(exportOnboardingScenarioLeads({
            clientId,
            scenarioId,
        }));
    };

    const getMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm(item);
                        },
                        children: "Удалить",
                    },
                    visible: ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role),
                },
            ],
        };
    };

    const onSubmitDeleteLead = () => {
        const {
            scenarioId,
            leadId,
        } = confirmData;

        dispatch(deleteOnboardingScenarioLead({
            scenarioId,
            leadId,
            onSuccess: () => {
                toastSuccess("Лид успешно удален");
                onCloseConfirm();
                fetchList();
            },
        }));
    };

    const refreshContactorsStatusesList = () => {
        dispatch(refreshStatusesOnboardingScenarioContactors({
            clientId,
            scenarioId,
        }));
    };

    return {
        isImportModalOpen,
        setIsImportModalOpen,
        onSubmitImportList,
        getMediaControls,
        onSubmitDeleteLead,
        isOpenConfirm,
        onCloseConfirm,
        progressAction,
        refreshContactorsStatusesList,
        exportList,
    };
}