function getIsShowDetails(props) {
    const {
        projectId,
        hideDetailsProjectCard,
        hideDetails,
    } = props;

    // отображается, если открыта карточка проекта и не скрыты детали
    if (projectId) {
        return !hideDetailsProjectCard;
    }

    return !hideDetails;
}

export default getIsShowDetails;