import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmPagination from "../../../components/ActualComponents/NmPagination";
import TableDiv from "../../../components/TableDiv";
import {Dimmer, Loader} from "semantic-ui-react";

import {formatLocalDate} from "../../../utils/dateFormat";

import {headersDeviceLogList} from "../../../constants/headersTable";

import {
    deviceLogProgressSelector,
    deviceLogSelector,
    deviceLogTotalCountSelector,
    deviceLogTotalPagesSelector,
    getDeviceLog} from "../../../ducks/contractorDevice";

import PropTypes from "prop-types";

import "./style.sass";

class DeviceLog extends Component {
    static propTypes = {
        totalPages: PropTypes.number,
        logList: PropTypes.array,
        getLogList: PropTypes.func,
        list: PropTypes.object,
        totalCount: PropTypes.number,
        progress: PropTypes.bool,
        getDeviceLog: PropTypes.func,
    };

    static defaultProps = {
        totalPages: 0,
        logList: [],
        getLogist: () => {
        },
        list: {},
        totalCount: 0,
        progress: false,
        getDeviceLog: () => {},
    };

    constructor(props) {
        super(props);
        const {match} = props;
        const {params} = match;
        const {contractorId} = params;
        this.state = {
            pageNum: 1,
            pageSize: 25,
            pageSizes: [25, 50, 100],
            selectedList: [],
            filterBySubstring: "",
            isLoading: true,
            financeLog: {},
        };
        this.contractorId = contractorId;
    }


    componentDidMount() {
        this.setState(prevState => ({
            ...prevState,
        }), this.fetchList);
    }

    componentDidUpdate(prevProps, state) {
        const prevProgressState = prevProps.progress;
        const {progress} = this.props;
        const {isLoading} = this.state;

        if (progress !== isLoading && prevProgressState !== progress) {
            this.setState({
                isLoading: false,
            });
        }
    };

    componentWillUnmount(){

    }

    fetchList = () => {
        const {getDeviceLog} = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        const requestData = {
            contractorId: this.contractorId,
            pageNum,
            pageSize,
        };

        getDeviceLog(requestData);
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageNum,
        }), this.fetchList);
    };

    mapTableData = () => {
        const {list} = this.props;

        const rows = list.deviceLog.map(item => {
            return {
                ...item,
                date: formatLocalDate(item.date, "dd.MM.yyyy HH:mm"),
                model: item.deviceJson.model,
                name: item.deviceJson.name,
                ram: item.deviceJson.ram,
                screenResolution: item.deviceJson.screenResolution,
                version: item.deviceJson.version,
                versionApp: item.deviceJson.buildNumber,
            };
        });
        return {
            headers: headersDeviceLogList,
            rows,
        };
    };

    render() {
        const {pageSize, pageNum, isLoading} = this.state;
        const {totalPages, t, totalCount} = this.props;

        if (isLoading) {
            return (
                <Dimmer active>
                    <Loader content={t("loader.content")} />
                </Dimmer>
            );
        }

        return (
            <div className="device-log">
                <div className="device-log__table">
                    <TableDiv
                        tableData={this.mapTableData()}
                    />
                </div>
                <NmPagination
                    className="device-log__pagination"
                    totalCount={totalCount}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    onChangePageSize={this.handleChangePageSize}
                    onChangePagination={this.handlePaginationChange}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        list: deviceLogSelector(state),
        totalPages: deviceLogTotalPagesSelector(state),
        totalCount: deviceLogTotalCountSelector(state),
        progress: deviceLogProgressSelector(state),
    }),
    {
        getDeviceLog,
    },
)(withTranslation()(DeviceLog));
