import React, {useState} from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../components/ActualComponents/NmRangeInput";

import bem from "../../../../utils/bem";
import {getDateObject} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {FINANCE_PATENTS_PAYMENTS_STATUS_DICT} from "../../../../constants/patentsPayments";
import {STATUS_OF_TRANSFER_TRANSCRIPTION} from "../../../../constants/status";
import {regFullName} from "../../../../constants/validationRules";

import "./style.sass";

const patternNumber = /^\d*(,\d{0,2})?$/;

const FinancePatentPaymentsFilter = (props) => {
    const {
        clearFilter = () => {
        },
        sendFilter = () => {
        },
        isBff,
        initPaymentNumberFilter,
        contractorId,
    } = props;

    const initForm = {
        clientIdFilter: "",
        contractorNameFilter: "",
        patentRegistryNameFilter: "",
        statusFilter: "",
        bankStatusFilter: "",
        fromDateFilter: "",
        toDateFilter: "",
        fromSumFilter: "",
        toSumFilter: "",
        fromPaymentCommissionFilter: "",
        toPaymentCommissionFilter: "",
        paymentNumberFilter: initPaymentNumberFilter || "",
    };

    const [filter, setFilter] = useState({...initForm});
    const [block, element] = bem("finance-payment-patents-filter");

    const {
        clientIdFilter,
        contractorNameFilter,
        patentRegistryNameFilter,
        statusFilter,
        bankStatusFilter,
        fromDateFilter,
        toDateFilter,
        fromSumFilter,
        toSumFilter,
        fromPaymentCommissionFilter,
        toPaymentCommissionFilter,
        paymentNumberFilter,
    } = filter;

    const reportStatus = statusFilter && statusFilter.length ? statusFilter[0] : null;
    const statusOfTheTransfer = bankStatusFilter && bankStatusFilter.length ? bankStatusFilter[0] : null;
    const _fromDateFilter = isNullOrWhitespace(fromDateFilter) ? null : new Date(fromDateFilter);
    const _toDateFilter = isNullOrWhitespace(toDateFilter) ? null : new Date(toDateFilter);
    const paymentStatusOptions = dictionaryToOptions(FINANCE_PATENTS_PAYMENTS_STATUS_DICT);
    const bankStatusOptions = dictionaryToOptions(STATUS_OF_TRANSFER_TRANSCRIPTION);

    const handleOnChangeSelect = (e, {value, name}) => {
        const _value = value === "" ? undefined : [value];
        onChange(e, {value: _value, name});
    };

    const onChange = (e, {value, name}) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const submit = () => {
        sendFilter(filter);
    };

    const clear = () => {
        setFilter(initForm);
        clearFilter();
    };

    return (
        <NmForm
            addMargin
            className={block()}
        >
            <FilterCustomer
                isBff={isBff}
                size="lg"
                label="Заказчик"
                isClearable
                onChange={onChange}
                search
                name="clientIdFilter"
                value={clientIdFilter}
            />
            {
                !contractorId &&
                <NmInputV2
                    size="lg"
                    label="ФИО исполнителя"
                    pattern={regFullName}
                    value={contractorNameFilter}
                    name="contractorNameFilter"
                    onChange={onChange}
                />
            }
            <NmInputV2
                size="lg"
                label="Номер платежа"
                value={paymentNumberFilter}
                pattern={/^\d+$/}
                name="paymentNumberFilter"
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                label="Название реестра"
                value={patentRegistryNameFilter}
                pattern={regFullName}
                name="patentRegistryNameFilter"
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                label="Статус Наймикс"
                name="statusFilter"
                clearable
                compact
                onChange={handleOnChangeSelect}
                value={reportStatus}
                options={paymentStatusOptions}
            />
            <NmDropdownV2
                size="lg"
                label="Банковский статус"
                clearable
                compact
                name="bankStatusFilter"
                onChange={handleOnChangeSelect}
                options={bankStatusOptions}
                value={statusOfTheTransfer}
            />
            <NmDateRangePickerV2
                label="Дата операции"
                onChange={onChange}
                isClearable
                size="lg"
                dateFormat="dd.MM.yy"
                startFieldName="fromDateFilter"
                endFieldName="toDateFilter"
                value={{
                    fromDateFilter: getDateObject(_fromDateFilter),
                    toDateFilter: getDateObject(_toDateFilter),
                }}
            />
            <NmRangeInput
                nameStart="fromSumFilter"
                nameEnd="toSumFilter"
                size="lg"
                valueStart={fromSumFilter}
                valueEnd={toSumFilter}
                pattern={patternNumber}
                onChange={onChange}
                label="Сумма операции"
                placeholderStart="От"
                placeholderEnd="До"
            />
            <NmRangeInput
                nameStart="fromPaymentCommissionFilter"
                nameEnd="toPaymentCommissionFilter"
                size="lg"
                valueStart={fromPaymentCommissionFilter}
                valueEnd={toPaymentCommissionFilter}
                pattern={patternNumber}
                onChange={onChange}
                label="Комиссия заказчика"
                placeholderStart="От"
                placeholderEnd="До"
            />
            <FilterButtonsV2
                onSearch={submit}
                onClear={clear}
            />
        </NmForm>
    );
};

export default FinancePatentPaymentsFilter;