import React, {createContext} from "react";

import {useOrderEditFetchTemplates} from "./hooks/useFetchTemplates";

export const OrderEditContext = createContext({});

const OrderEditContextProvider = (props) => {
    const {
        children,
        clientId,
        setForm,
        formData,
        editOrderId,
        onChangeActCreation,
        actCreationForm,
        isForemanRequestForAddition,
        tabs,
        isTemplate,
    } = props;
    const {
    } = useOrderEditFetchTemplates({
        clientId,
        formData,
        setForm,
        editOrderId,
        isForemanRequestForAddition,
    });

    return (
        <OrderEditContext.Provider
            value={{
                clientId,
                setForm,
                editOrderId,
                tabs,
                onChangeActCreation,
                actCreationForm,
                isTemplate,
            }}
        >
            {children}
        </OrderEditContext.Provider>
    );
};

export default OrderEditContextProvider;