import React, {createContext} from "react";

import {useAppContextPageData} from "./hooks/usePageData";

export const AppContext = createContext({});

export const AppContextProvider = (props) => {
    const {
        children,
    } = props;

    const {
        containerRef,
        scrollPosition,
        setScrollPosition,
        pathname,
    } = useAppContextPageData();

    return (
        <AppContext.Provider
            value={{
                containerRef,
                scrollPosition,
                setScrollPosition,
                pathname,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};