import {useState} from "react";

import {useFilter} from "../../../hooks/useFilter";

export function useTemplatesClientUsageFilter(params) {
    const {
        setPagination,
        pageSize,
    } = params;
    const {
        filter,
        onChangeFilter,
    } = useFilter({clientNameFilter: ""});
    const [filterData, setFilterData] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const onSearch = () => {
        setFilterData(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClear = () => {
        setFilterData({});
        setIsSearch(false);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    return {
        filterData,
        isSearch,
        onClear,
        onSearch,
        filter,
        onChangeFilter,
    };
}