import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {getFullName} from "../../utils/stringFormat";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import PropTypes from "prop-types";

import "./style.sass";

class RejectionReason extends Component {
    static propTypes = {
        close: PropTypes.func,
        submit: PropTypes.func,
        status: PropTypes.string,
        person: PropTypes.object,
        title: PropTypes.string,
    };

    static defaultProps = {
        close: () => {
        },
        submit: () => {
        },
        status: "",
        person: {},
    };

    constructor() {
        super();
        this.state = {
            text: "",
            textErrorMessage: false,
            isSubmit: false,
        };

        this.textErrorMessage = "rejection-reason.error-message-required";
    }


    onChange = (e, {value: text}) => {
        this.setState({text});

        if (isNullOrWhitespace(text)) {
            this.setState({
                isSubmit: true,
            });
            return;
        }
        this.setState({
            textErrorMessage: false,
            isSubmit: true,
        });
    };


    submit = () => {
        const {submit, close, t} = this.props;
        const {text} = this.state;
        if (isNullOrWhitespace(text)) {
            this.setState({
                textErrorMessage: t(this.textErrorMessage),
                isSubmit: false,
            });
            return;
        }
        if (text.length>255) {
            this.setState({
                textErrorMessage: "Максимальная длина - 255 символов",
            });
            return;
        }
        submit(text);
        close();
    };

    render() {
        const {
            close,
            person,
            title,
            placeholder,
            t,
        } = this.props;
        const {
            text,
            textErrorMessage,
            isSubmit,
        } = this.state;
        const {
            lastName,
            firstName,
            patronymic,
        } = person;

        return (
            <NmModal
                size="md"
                className="rejection-reason-form"
                onClose={close}
                footer={
                    <ApplyButtons
                        submitBtnContent={t("button.confirm")}
                        cancelBtnContent={t("button.cancel")}
                        mobile="column"
                        isHiddenCancelOnMobile
                        onClose={close}
                        disabled={!isSubmit}
                        submit={this.submit}
                    />
                }
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
            >
                <NmForm addMargin>
                    {lastName &&
                    <div className="rejection-reason-form__title">
                        {getFullName(lastName, firstName, patronymic)}
                    </div>
                    }
                    <NmTextareaV2
                        maxLength={255}
                        className="rejection-reason-form__textarea"
                        error={textErrorMessage}
                        placeholder={placeholder || t("rejection-reason.text-area-placeholder")}
                        value={text}
                        onChange={this.onChange}
                    />
                </NmForm>
            </NmModal>
        );
    }
}

export default withTranslation()(RejectionReason);
