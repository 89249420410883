import {useMemo} from "react";
import {isEmpty} from "lodash";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import formatDate from "../../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";
import {handleNumber, isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {
    OPERATION_CONTRACTOR_FINANCE_STATUS_DICT,
    OPERATION_TYPES_CONTRACTOR_FINANCE_DICT,
    PAYMENT_TYPES_CONTRACTOR_FINANCE_DICT,
} from "../../../../../constants/contractorFinance";
import {REG_EXP} from "../../../../../constants/regExp";

export function useContractorFinanceBalanceFilter() {
    const initFilter = {
        operationTypesFilter: [],
        fromDateOperationFilter: null,
        toDateOperationFilter: null,
        paymentTypeFilter: null,
        statusFilter: null,
        paymentNumber: null,
    };

    function mapFilterDto(filter) {
        const {
            operationTypesFilter,
            paymentTypeFilter,
            statusFilter,
            paymentNumber,
            fromDateOperationFilter,
            toDateOperationFilter,
        } = filter;

        return {
            operationTypesFilter: isEmpty(operationTypesFilter) ? undefined : operationTypesFilter,
            paymentTypeFilter: paymentTypeFilter ? paymentTypeFilter : undefined,
            statusFilter: statusFilter ? statusFilter : undefined,
            paymentNumber: handleNumber(paymentNumber),
            fromDateOperationFilter: isNullOrWhitespace(fromDateOperationFilter) ? undefined : formatDate(fromDateOperationFilter, "yyyy-MM-dd"),
            toDateOperationFilter: isNullOrWhitespace(toDateOperationFilter) ? undefined : formatDate(toDateOperationFilter, "yyyy-MM-dd"),
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "fromDateOperationFilter",
                        endFieldName: "toDateOperationFilter",
                        isClearable: true,
                        label: "Дата операции",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,

                        size: "lg",
                        label:"Тип операции",
                        placeholder: "Выберите тип операции",
                        name: "operationTypesFilter",
                        options: dictionaryToOptions(OPERATION_TYPES_CONTRACTOR_FINANCE_DICT),
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label:"Тип платежа",
                        placeholder: "Выберите тип платежа",
                        name: "paymentTypeFilter",
                        options: dictionaryToOptions(PAYMENT_TYPES_CONTRACTOR_FINANCE_DICT),
                        isClearable: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        size: "lg",
                        label:"Статус",
                        placeholder: "Выберите статус",
                        name: "statusFilter",
                        options: dictionaryToOptions(OPERATION_CONTRACTOR_FINANCE_STATUS_DICT),
                        isClearable: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "paymentNumber",
                        label: "Номер платежа",
                        placeholder: "Введите номер платежа",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
        ];
    }, []);

    return {
        initFilter,
        mapFilterDto,
        filters,
    };
}