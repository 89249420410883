export const BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST = "BFF_COMMON_TREE_PROJECTS_PAGE_REQUEST";
export const BFF_COMMON_TREE_PROJECTS_PAGE_SUCCESS = "BFF_COMMON_TREE_PROJECTS_PAGE_SUCCESS";
export const BFF_COMMON_TREE_PROJECTS_PAGE_ERROR = "BFF_COMMON_TREE_PROJECTS_PAGE_ERROR";

export const BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST = "BFF_COMMON_TREE_OBJECTS_PAGE_REQUEST";
export const BFF_COMMON_TREE_OBJECTS_PAGE_SUCCESS = "BFF_COMMON_TREE_OBJECTS_PAGE_SUCCESS";
export const BFF_COMMON_TREE_OBJECTS_PAGE_ERROR = "BFF_COMMON_TREE_OBJECTS_PAGE_ERROR";

export const BFF_COMMON_TREE_LIST_REQUEST = "BFF_COMMON_TREE_LIST_REQUEST";
export const BFF_COMMON_TREE_LIST_SUCCESS = "BFF_COMMON_TREE_LIST_SUCCESS";
export const BFF_COMMON_TREE_LIST_ERROR = "BFF_COMMON_TREE_LIST_ERROR";

export const BFF_COMMON_TREE_SAVE_REQUEST = "BFF_COMMON_TREE_SAVE_REQUEST";
export const BFF_COMMON_TREE_SAVE_SUCCESS = "BFF_COMMON_TREE_SAVE_SUCCESS";
export const BFF_COMMON_TREE_SAVE_ERROR = "BFF_COMMON_TREE_SAVE_ERROR";

export const BFF_CLIENTS_USERS_SETTINGS_UPDATE_STORE = "BFF_CLIENTS_USERS_SETTINGS_UPDATE_STORE";