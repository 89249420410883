import {useContext} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {AppContext} from "../components/AppContext";
import {history} from "../store/configureStore";

export const useSaveScrollNavigation = (params = {}) => {
    const {
        containerRef,
    } = useContext(AppContext);

    const location = useSelector((state) => state.router.location);

    const {
        state = {},
    } = location;

    const navigate = (params) => {
        const {
            pathname,
            filterData,
            paginationData,
        } = params;

        history.push({
            pathname,
            state: {
                filterData,
                paginationData,
                scrollTop: params.scrollTop ? params.scrollTop : containerRef.current.scrollTop,
                pathname,
            },
        });
    };

    return {
        navigate,
        state,
        isState: !isEmpty(state),
    };
};