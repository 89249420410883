export const BANK_TYPE = {
    PLUTONIUM_W1: {
        VALUE: "PLUTONIUM_W1",
        TEXT: "W1Plutonium",
    },
    HERMES_W1: {
        VALUE: "HERMES_W1",
        TEXT: "W1Hermes",
    },
    TKB: {
        VALUE: "TKB",
        TEXT: "ТКБ",
    },
};

export const BANK_PATH = {
    THROUGH_WALLET: "THROUGH_WALLET",
    DIRECT: "DIRECT",
};

export const BANK_TYPE_WITH_PATH = {
    "PLUTONIUM_W1+THROUGH_WALLET": "PLUTONIUM_W1+THROUGH_WALLET",
    "PLUTONIUM_W1+DIRECT": "PLUTONIUM_W1+DIRECT",
    "HERMES_W1+THROUGH_WALLET": "HERMES_W1+THROUGH_WALLET",
    "HERMES_W1+DIRECT": "HERMES_W1+DIRECT",
    "TKB+THROUGH_WALLET": "TKB+THROUGH_WALLET",
    "TKB+DIRECT": "TKB+DIRECT",
};

export const BANK_TYPE_WITH_PATH_DICT = {
    [BANK_TYPE_WITH_PATH["PLUTONIUM_W1+THROUGH_WALLET"]]: "W1Plutonium / Через кошелек",
    [BANK_TYPE_WITH_PATH["PLUTONIUM_W1+DIRECT"]]: "W1Plutonium / Напрямую",
    [BANK_TYPE_WITH_PATH["HERMES_W1+THROUGH_WALLET"]]: "W1Hermes / Через кошелек",
    [BANK_TYPE_WITH_PATH["HERMES_W1+DIRECT"]]: "W1Hermes / Напрямую",
    [BANK_TYPE_WITH_PATH["TKB+THROUGH_WALLET"]]: "ТКБ / Через кошелек",
    [BANK_TYPE_WITH_PATH["TKB+DIRECT"]]: "ТКБ / Напрямую",
};

export const BANK_TYPE_CARD_OPTIONS = [
    {
        key: BANK_TYPE.PLUTONIUM_W1.VALUE,
        text: BANK_TYPE.PLUTONIUM_W1.TEXT,
        value: BANK_TYPE.PLUTONIUM_W1.VALUE,
    },
    {
        key: BANK_TYPE.HERMES_W1.VALUE,
        text: BANK_TYPE.HERMES_W1.TEXT,
        value: BANK_TYPE.HERMES_W1.VALUE,
    },
    {
        key: BANK_TYPE.TKB.VALUE,
        text: BANK_TYPE.TKB.TEXT,
        value: BANK_TYPE.TKB.VALUE,
    },
];

export const PAYMENT_METHOD = {
    CARD_LOCAL: "CARD_LOCAL",
    SBP: "SBP",
    BANK_REQUISITES : "BANK_REQUISITES",
};

export const PAYMENT_METHOD_DICT = {
    [PAYMENT_METHOD.CARD_LOCAL]: "На карты РФ",
    [PAYMENT_METHOD.SBP]: "Через СБП",
    [PAYMENT_METHOD.BANK_REQUISITES]: "По банковским платежным реквизитам",
};

export const PAYMENT_METHOD_OPTION_DICT = {
    CARD_LOCAL: {
        key: PAYMENT_METHOD.CARD_LOCAL,
        text: PAYMENT_METHOD_DICT[PAYMENT_METHOD.CARD_LOCAL],
        value: PAYMENT_METHOD.CARD_LOCAL,
    },
    SBP: {
        key: PAYMENT_METHOD.SBP,
        text: PAYMENT_METHOD_DICT[PAYMENT_METHOD.SBP],
        value: PAYMENT_METHOD.SBP,
    },
    BANK_REQUISITES: {
        key: PAYMENT_METHOD.BANK_REQUISITES,
        text: PAYMENT_METHOD_DICT[PAYMENT_METHOD.BANK_REQUISITES],
        value: PAYMENT_METHOD.BANK_REQUISITES,
    },
};

export const PAYMENT_METHOD_OPTIONS = [
    {
        key: PAYMENT_METHOD.CARD_LOCAL,
        text: PAYMENT_METHOD_DICT[PAYMENT_METHOD.CARD_LOCAL],
        value: PAYMENT_METHOD.CARD_LOCAL,
    },
    {
        key: PAYMENT_METHOD.SBP,
        text: PAYMENT_METHOD_DICT[PAYMENT_METHOD.SBP],
        value: PAYMENT_METHOD.SBP,
    },
    {
        key: PAYMENT_METHOD.BANK_REQUISITES,
        text: PAYMENT_METHOD_DICT[PAYMENT_METHOD.BANK_REQUISITES],
        value: PAYMENT_METHOD.BANK_REQUISITES,
    },
];

export const CLIENT_PROPERTY_NAME_DICT = {
    // Дополнительные модули
    edmAvailable: "Электронный документооборот",
    edmTariff: "Тариф за модуль \"Электронный документооборот\"",
    withoutContract: "Без договора",
    migrantLicensePaymentEnabled: "Оплата патентов мигрантов",
    migrantLicensePaymentCommission: "Комиссия за модуль \"Оплата патентов\"",
    insuranceAvailable: "Страхование",
    smzRegistryPaymentsAvailable: "Оплаты реестрами (НПД)",
    civilRegistryPaymentsAvailable: "Оплаты реестрами (НДФЛ)",
    individualRegistryPaymentsAvailable: "Оплаты реестрами (ИП)",
    publishOrdersOnWebsite: "Публиковать заказы на сайте",
    retailClient: "Доступ к разделу «Ресурсы»",
    isRecruitmentAvailable: "Рекрутмент",
    recruitmentHHPolitenessIndex: "Индекс вежливости hh",
    crowdTasksAvailable: "Задания",
    registryPaymentsClientInnCheckRequired: "Проверка ИНН компании в реестрах на оплату",
    clientWorkTypeTariff: "Тариф за модуль \"КЭДО\"",
    // Депозит и проведение оплат
    smzPaymentsWithoutConfirmNmAdmin: "Ведение оплат без согласования администратором Наймикс (НПД)",
    civilPaymentsWithoutConfirmNmAdmin: "Ведение оплат без согласования администратором Наймикс (НДФЛ)",
    individualPaymentsWithoutConfirmNmAdmin: "Ведение оплат без согласования администратором Наймикс (ИП)",
    distributeDepositByObjects: "Распределение депозита по объектам",
    objectIdForPaidApis: "Объект для списания с депозита",
    smzMarketplace: "Оплаты НПД → По заказам",
    smzRegistry: "Оплаты НПД → По реестрам",
    smzApi: "Оплаты НПД → По API",
    civilMarketplace: "Оплаты НДФЛ → По заказам",
    civilRegistry: "Оплаты НДФЛ → По реестрам",
    civilApi: "Оплаты НДФЛ → По API",
    // Документы
    customDocumentTemplatesAvailable: "Свои шаблоны документов",
    actDateAvailable: "Настройка даты акта",
    documentDatesCheckRequired: "Контроль дат в документах",
    documentDatesByWorkDates: "Даты заявки и акта по датам работ",
    smzDocumentTablesEnabled: "Таблицы в актах и заявках НПД",
    civilDocumentTablesEnabled: "Таблицы в актах и заявках НДФЛ",
    edmSignWithoutSmsCodeEnabled: "Подписание документов в ЭДО без СМС",
    // Работа с исполнителями
    prohibitNonResidents: "Запрет на заключение договоров с нерезидентами",
    hideContractorContacts: "Скрыть номер телефона и email исполнителя",
    allowSigningFrameContractWithoutTaxpayerAndPartner: "Разрешить подписывать РД при отсутствии статуса НПД и партнерства с Наймикс",
    allowSigningDocumentWithoutSms: "Разрешить подписывать документы без кода",
    allowRegistrationWithoutInn: "Разрешить регистрацию без ИНН",
    allowCreatingRequestsForAnyContractors: "Разрешить создавать заявки от не своих исполнителей",
    grantPersonalDataAccess: "Доступ к персональным данным исполнителя",
    grantDocumentScansAccess: "Доступ к сканам документов исполнителя",
    canViewContractorEventLog: "Доступ к журналу событий",
    warnPaymentsCountRepeatsLastMonth: "Предупреждать о совпадении количества актов в текущем и предыдущем месяцах",
    warnPaymentsAmountRepeatsLastMonth: "Предупреждать, если сумма оплат в текущем месяце не отличается на заданный процент от суммы оплат предыдущего месяца",
    paymentAmountWarnThreshold: "Предупреждать, если сумма оплат в текущем месяце не отличается на заданный процент от суммы оплат предыдущего месяца. Процент от выплат",
    // Методы внешнего API
    apiAccessEnabled: "Доступ к доп методам в API",
    paidApisEnabled: "Использование платных методов API",
    // Другое
    availableClientUserIds: "Доступ к компании",
    innRequired: "Обязательность ввода ИНН для сотрудников",
    foremanEnabled: "Бригадир",
    ordersVolumeOfWorkRequired: "Создание заказов только с заполнением ТС и объема работ",
    objectManagerHasAccessToAllPaymentRegistries: "Просмотр всех реестров оплат для менеджера объекта",
    foremanHasUnrestrictedReadAccessToDocumentsRegistry: "Просмотр всего реестра документов для координатора компании",
    menuDesign: "Дизайн меню",
    marketplaceClient: "Маркетплейсовый заказчик",
    addBrandNameToObject: "Добавлять в объект название бренда",
    amountForReport: "Сумма для отчёта",
};

export const CLIENT_PROPERTY_BANKS_NAME_DICT = {
    bankTypeCard: "На карты",
    bankTypeSbp: "СБП",
    bankTypeRequisites: "По банковским платежным реквизитам",
};

export const CLIENT_WORK_TYPE = {
    NAIMIX_KEDO: "NAIMIX_KEDO",
    ONLY_KEDO: "ONLY_KEDO",
    ONLY_NAIMIX: "ONLY_NAIMIX",
};

export const CLIENT_SETTING_TYPE = {
    // Скрытие заказов компании
    HIDE_ORDERS_AFTER_END_CONTRACTORS_SEARCH: "HIDE_ORDERS_AFTER_END_CONTRACTORS_SEARCH",
    // Заказы только по шаблонам
    ORDER_BY_TEMPLATE: "ORDER_BY_TEMPLATE",
    // Настроить доступ в чаты заказов для сотрудников компании
    ACCESS_TO_ORDER_CHATS: "ACCESS_TO_ORDER_CHATS",
};