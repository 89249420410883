import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    getKedoExportsStateTypes,
    getKedoExportsTypes,
} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsKedoExportStateTypesSelector,
    bffCommonDictsKedoExportTypesSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoExportsDicts = () => {
    const dispatch = useDispatch();

    const exportStateTypesDict = useSelector(bffCommonDictsKedoExportStateTypesSelector);
    const exportTypesDict = useSelector(bffCommonDictsKedoExportTypesSelector);

    useEffect(() => {
        if (isEmpty(exportStateTypesDict)) {
            dispatch(getKedoExportsStateTypes());
        }

        if (isEmpty(exportTypesDict)) {
            dispatch(getKedoExportsTypes());
        }
    }, []);

    return {
        exportStateTypesDict,
        exportTypesDict,
    };
};