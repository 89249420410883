import React from "react";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import {ClientRecruitmentAccessControlJobBoards} from "../job-boards";
import {ClientRecruitmentAccessControlObservers} from "../observers";
import {
    ClientRecruitmentAccessControlRecruitersGroups,
} from "../recruiters-groups";

import {
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
} from "../../../../constants/links";

export const ClientRecruitmentAccessControlRoute = () => {
    return (
        <Switch>
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS}
                component={ClientRecruitmentAccessControlRecruitersGroups}
            />
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS}
                component={ClientRecruitmentAccessControlJobBoards}
            />
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS}
                component={ClientRecruitmentAccessControlObservers}
            />
        </Switch>
    );
};