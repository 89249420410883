import React from "react";
import {isEmpty} from "lodash";

import {LinkMarkdownTag} from "./components/Tag";

import {findAllMatches} from "../../utils/stringHelper";
import {getTextWithNoTagLinks} from "./utils/getTextWithNoTagLinks";

import {REG_EXP} from "../../constants/regExp";

interface LinkMarkdownProps {
    children?: string | null,
}

const LinkMarkdown = (props: LinkMarkdownProps) => {
    const {
        children: message,
    } = props;

    if (!message) {
        return null;
    }

    const urlRegex = new RegExp(REG_EXP.LINK_WITH_TAG, "g");
    const linkTags = findAllMatches(urlRegex, message);

    if (isEmpty(linkTags)) {
        return getTextWithNoTagLinks(message);
    }

    let deletedSymbolsCount = 0;
    let notificationText = message;
    const output: Array<React.ReactNode> = [];

    linkTags.forEach((aTag, index) => {
        const [tagContent] = aTag;
        const tagIndex = aTag.index - deletedSymbolsCount;
        const tagLink = tagContent.match(/href="(.*?)"/g)[0].match(/"(.*?)"/)[1];
        const tagLinkText = tagContent.match(/>(.*?)</g)[0].replace(/[><]/g, "");

        const leftText = notificationText.substring(0, tagIndex);
        const rightText =  index === linkTags.length - 1
            ? notificationText.substring(tagIndex + tagContent.length)
            : "";

        const leftTextContent = getTextWithNoTagLinks(leftText);
        const rightTextContent = getTextWithNoTagLinks(rightText);

        output.push(
            <>
                {leftTextContent}
                <LinkMarkdownTag
                    href={tagLink}
                    children={tagLinkText}
                />
                {rightTextContent}
            </>,
        );
        notificationText = notificationText.substring(tagIndex + tagContent.length);
        deletedSymbolsCount += tagIndex + tagContent.length;
    });

    return output.map((item) => {
        return item;
    });
};

export default LinkMarkdown;