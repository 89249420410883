import React, {Component} from "react";
import {connect} from "react-redux";

import ScrollableContainer from "../../../components/ActualComponents/ScrollableContainer";
import Tabs from "../../../components/ActualComponents/Tabs";
import ButtonBack from "../../../components/ButtonBack";
import NmTitle from "../../../components/NmTitle";
import {ClientCardTabs} from "../client-card-tabs";
import ClientRoute from "../client-route";

import {isNMUsers} from "../../../utils/access";
import {getHistoryBackLink} from "../../../utils/historyNavigationhelper";
import {ls, USER_ROLE} from "../../../utils/localstorage";

import {LINK_CLIENT_LIST} from "../../../constants/links";
import {
    ADMIN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {
    clientIsSuccessArchivedSelector,
    clientPageDataSelector,
    getClientById,
    getClientCardSelector,
    setArchivedClient,
    updateClient,
    updateFieldClientStore,
} from "../../../ducks/client";
import {
    getClientPropertiesById,
    getClientPropertiesCardSelector,
    updateClientPropertiesStore,
} from "../../../ducks/clientProperties";
import {financeBackLinkSelector, updateFinanceStore} from "../../../ducks/financeExport";
import {getUserCanNotCreateOrderByTemplate, updateFieldsObjectsStore} from "../../../ducks/objects";
import {getOrderCardOpenSelector} from "../../../ducks/order";
import {updateFieldsStoreProject} from "../../../ducks/projects";
import {findSpecialitiesByClientId} from "../../../ducks/speciality";

import {clientType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class ClientCard extends Component {
    static propTypes = {
        pathname: PropTypes.string,
        client: clientType,
        pageData: PropTypes.object,
        isSuccessArchived: PropTypes.bool,
        updateFieldClientStore: PropTypes.func,
        setArchivedClient: PropTypes.func,
        getClientById: PropTypes.func,
    };

    static defaultProps = {
        pathname: "",
        client: PropTypes.object,
        pageData: PropTypes.object,
        isSuccessArchived: PropTypes.bool,
        updateFieldClientStore: () => {
        },
        setArchivedClient: () => {
        },
        getClientById: () => {
        },
    };

    constructor(props) {
        super(props);
        const {match} = props;

        const {
            params: {
                clientId,
            },
        } = match;

        this.clientId = clientId;
        this.role = ls(USER_ROLE);

        this.state = {
            historyData: "",
        };
    }

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, filterData} = state;

            this.setState({
                historyData: {prevPath, pageData, filterData},
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {pathname, match} = prevProps;
        const {pathname: _pathname, match: _match} = this.props;

        if (pathname !== _pathname || !this.historyInit) {
            this.historyInit = true;
            this.saveHistoryData();
        }

        if (match.params.clientId !== _match.params.clientId) {
            this.fetchClient(_match.params.clientId);
        }
    }

    componentDidMount() {
        const {
            getClientPropertiesById,
            getUserCanNotCreateOrderByTemplate,
        } = this.props;

        if (![RECRUITER].includes(this.role)) {
            getUserCanNotCreateOrderByTemplate({
                clientId: this.clientId,
                pageNum: 1,
                pageSize: 5000,
                status: "IN_WORK",
            });
        }

        this.fetchClient(this.clientId);

        if ([ADMIN, NM_COORDINATOR, NM_MANAGER, NM_CHIEF_ACCOUNTANT].includes(this.role)) {
            getClientPropertiesById({clientId: this.clientId});
        }
    }

    componentWillUnmount() {
        const {
            updateFieldClientStore,
            updateClientPropertiesStore,
            updateFieldsObjectsStore,
            updateFieldsStoreProject,
        } = this.props;

        updateFieldsObjectsStore({
            progressUserCanNotCreateOrderByTemplate: null,
            optimizedObjects: [],
        });
        updateFieldClientStore("card", {});
        updateClientPropertiesStore({card: {}});
        updateFieldsStoreProject({projectsList: []});
    }

    handleOnClickBack = () => {
        const {
            pageData,
            backLink,
            updateFinanceStore,
            location,
            pathname,
        } = this.props;
        const {historyData} = this.state;
        const {prevPath = ""} = historyData;

        // Если по текущей логике в prevPath оказался url, принадлежащий карточке клиента /client-card, пропускаем логику
        const isPrevPathFromClientCard = prevPath.indexOf("/client-card") !== -1;

        if (historyData && prevPath && prevPath !== pathname && !isPrevPathFromClientCard) {
            history.push(prevPath, historyData);

            return;
        }

        const historyBackLink = getHistoryBackLink(location);

        if (historyBackLink && historyBackLink !== pathname && !isPrevPathFromClientCard) {
            history.push(historyBackLink);

            return;
        }

        const {archivedFilter} = pageData;

        if (archivedFilter) {
            history.push({
                pathname: LINK_CLIENT_LIST,
                search: "?active=false",
            });

            return;
        }

        if (backLink) {
            history.push(backLink);

            updateFinanceStore({backLink: ""});

            return;
        }

        history.push(LINK_CLIENT_LIST);
    };

    renderClientName() {
        const {
            client: {
                name,
                brand,
            },
        } = this.props;

        return (
            <NmTitle
                className="overflow-hidden"
                size="xl"
            >
                {brand ? `${name} (${brand})` : name}
            </NmTitle>
        );
    }

    renderHeader() {
        if (isClientUser(this.role)) {
            return null;
        }

        return (
            <div className="client-card__header">
                {
                    ![PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role) &&
                        <ButtonBack
                            className="me-3"
                            onClick={this.handleOnClickBack}
                        />
                }
                {this.renderClientName()}
            </div>
        );
    }

    fetchClient = (clientId) => {
        const {getClientById} = this.props;

        getClientById(clientId);
    };

    get classNames() {
        const {
            isOpenOrderCard,
            pathname,
        } = this.props;

        const classNames = {
            container: ["client-card"],
            content: ["client-card__content"],
        };

        if (isOpenOrderCard) {
            classNames.container.push("client-card_not-overflow");
        }

        if (pathname.includes("chat-list") || pathname.includes("task-chats")) {
            !isNMUsers() && classNames.container.push("client-card_without-padding");
            classNames.content.push(
                isNMUsers() ?
                    "client-card__content_full-admin" :
                    "client-card__content_full",
            );
        }

        if (isClientUser(this.role)) {
            classNames.content.push("client-card__content_no-offset");
        }

        return {
            container: classNames.container.join(" "),
            content: classNames.content.join(" "),
        };
    }

    renderTabs() {
        const {
            pathname,
            client,
            clientProperties,
        } = this.props;

        const isArchived = client.archived;
        const tabs = ClientCardTabs({
            clientId: this.clientId,
            pathname,
            role: this.role,
            clientProperties,
        });

        return (
            <>
                {this.renderHeader(this.clientId)}
                {
                    !isClientUser(this.role) &&
                    <Tabs
                        className="base-tabset client-card__tabs"
                        panes={tabs}
                    />
                }
                <div className={this.classNames.content}>
                    <ClientRoute
                        clientId={this.clientId}
                        isArchived={isArchived}
                    />
                </div>
            </>
        );
    }

    render() {
        return (
            <ScrollableContainer
                saveScrollPosition={true}
                className={this.classNames.container}
            >
                {this.renderTabs()}
            </ScrollableContainer>
        );
    }
}

export default connect(
    state => ({
        location: state.router.location,
        client: getClientCardSelector(state),
        pathname: state.router.location.pathname,
        isOpenOrderCard: getOrderCardOpenSelector(state),
        pageData: clientPageDataSelector(state),
        isSuccessArchived: clientIsSuccessArchivedSelector(state),
        backLink: financeBackLinkSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
    }),
    {
        updateFieldClientStore,
        setArchivedClient,
        getClientById,
        updateFinanceStore,
        updateClient,
        updateClientPropertiesStore,
        findSpecialitiesByClientId,
        getUserCanNotCreateOrderByTemplate,
        updateFieldsObjectsStore,
        getClientPropertiesById,
        updateFieldsStoreProject,
    },
)(ClientCard);
