import {isEmpty} from "lodash";

import {extractButtonProps} from "./extractButtonProps";

import {PartialRecord} from "../../../../types/common";
import {MediaButtonsBreakpointNames, TDevice, TMediaButton} from "../types";

type TTransformOptionsByMedia = {
    device: TDevice,
    buttons: Array<TMediaButton>,
    renderCount: PartialRecord<MediaButtonsBreakpointNames, number | undefined>
}

const filterVisible = (buttons: Array<TMediaButton>) => {
    return [...buttons].filter(({visible = true}) => visible);
};

/***
 * Формирует значения для контекстного меню
 * @param device - текущие брейкпоинты из react-media (desktop, mobile, tablet)
 * @param buttons - список кнопок
 * @param renderCount - объект с настройками кол-ва рендера кнопок для брейкопоинтов
 */
export const transformOptions = ({device, buttons, renderCount}: TTransformOptionsByMedia) => {
    buttons = buttons.filter((item: TMediaButton) => !item.settings?.exception);

    if (
        device.mobile
        && typeof renderCount.mobile === "number"
        && buttons.length > renderCount.mobile
    ) {
        const list = filterOptions(buttons, renderCount.mobile);

        return formatToContextMenuOptions(list, device);
    }

    buttons = buttons.filter((item: TMediaButton) => !item.settings?.filter);

    if (
        device.tablet
        && typeof renderCount.tablet === "number"
        && buttons.length > renderCount.tablet
    ) {
        const list = filterOptions(buttons, renderCount.tablet);

        return formatToContextMenuOptions(list, device);
    }

    if (
        device.preDesktop
        && typeof renderCount.preDesktop === "number"
        && buttons.length > renderCount.preDesktop
    ) {
        const list = filterOptions(buttons, renderCount.preDesktop);

        return formatToContextMenuOptions(list, device);
    }

    if (
        device.desktop
        && typeof renderCount.desktop === "number"
        && buttons.length > renderCount.desktop
    ) {
        const list = filterOptions(buttons, renderCount.desktop);

        return formatToContextMenuOptions(list, device);
    }

    return [];
};

const filterOptions = (buttons: Array<TMediaButton>, renderCount: number) => {
    return filterVisible(buttons.slice(renderCount));
};

const formatToContextMenuOptions = (buttons: Array<TMediaButton>, device: TDevice) => {
    return buttons.map(((item: TMediaButton, index) => {
        const {
            props,
            asset = {},
        } = item;

        if (!isEmpty(asset)) {
            const config = extractButtonProps(asset, device);

            return {
                key: index,
                text: config ? config.children : props.children,
                onClick: props.onClick || props.onChange,
                onDropFiles: props.onDropFiles,
                disabled: props.disabled,
                options: props.options,
                href: props.href,
                useExtLink: props.useExtLink,
                value: index,
            };
        }

        return {
            key: index,
            text: props.children,
            onClick: props.onClick || props.onChange,
            onDropFiles: props.onDropFiles,
            disabled: props.disabled,
            options: props.options,
            value: index,
            href: props.href,
            useExtLink: props.useExtLink,
        };
    }));
};