import {BadgeModType} from "../../../../components/NmBadge";

export const DOCUMENT_UPLOAD_SOURCES = {
    ONE_C: "ONE_C",
    UI: "UI",
    API: "API",
};

export const DOCUMENT_SOURCE_TYPES = {
    FROM_COMPANY: "FROM_COMPANY",
    FROM_STAFF: "FROM_STAFF",
    FROM_COMPANY_FROM_STAFF: "FROM_COMPANY_FROM_STAFF",
};

export const KEDO_DOCUMENT_STATUS = {
    DRAFT: "DRAFT",
    ON_SENDER: "ON_SENDER",
    ON_RECEIVER: "ON_RECEIVER",
    SIGNED_ALL: "SIGNED_ALL",
    REJECT_RECEIVER: "REJECT_RECEIVER",
    REJECT_SENDER: "REJECT_SENDER",
} as const;

export enum KedoParticipantDocumentStatus {
    GOS_KEY_SEND_WAITING = "GOS_KEY_SEND_WAITING",
    GOS_KEY_SEND_ERROR = "GOS_KEY_SEND_ERROR",
    IN_LINE_FOR_SIGNING = "IN_LINE_FOR_SIGNING",
    ON_SIGNING = "ON_SIGNING",
    SIGNED = "SIGNED",
    REJECTED = "REJECTED",
}

export const KEDO_DOCUMENT_STATES_FILTER_OPTIONS = [
    {
        key: KEDO_DOCUMENT_STATUS.DRAFT,
        value: KEDO_DOCUMENT_STATUS.DRAFT,
        text: "Черновик",
    },
    {
        key: KEDO_DOCUMENT_STATUS.ON_RECEIVER,
        value: KEDO_DOCUMENT_STATUS.ON_RECEIVER,
        text: "На подписании",
    },
    {
        key: KEDO_DOCUMENT_STATUS.REJECT_RECEIVER,
        value: KEDO_DOCUMENT_STATUS.REJECT_RECEIVER,
        text: "Отклонен",
    },
    {
        key: KEDO_DOCUMENT_STATUS.SIGNED_ALL,
        value: KEDO_DOCUMENT_STATUS.SIGNED_ALL,
        text: "Подписан",
    },
];

export const KEDO_DOCUMENTS_BADGE_MOD_DICT: Record<keyof typeof KEDO_DOCUMENT_STATUS, {
    VALUE: keyof typeof KEDO_DOCUMENT_STATUS,
    TEXT: string,
    TYPE: BadgeModType,
}> = {
    DRAFT: {
        VALUE: KEDO_DOCUMENT_STATUS.DRAFT,
        TYPE: "gray",
        TEXT: "Черновик",
    },
    ON_SENDER: {
        VALUE: KEDO_DOCUMENT_STATUS.ON_SENDER,
        TYPE: "orange",
        TEXT: "На подписании",
    },
    ON_RECEIVER: {
        VALUE: KEDO_DOCUMENT_STATUS.ON_RECEIVER,
        TYPE: "orange",
        TEXT: "На подписании",
    },
    SIGNED_ALL: {
        VALUE: KEDO_DOCUMENT_STATUS.SIGNED_ALL,
        TYPE: "green",
        TEXT: "Подписан",
    },
    REJECT_RECEIVER: {
        VALUE: KEDO_DOCUMENT_STATUS.REJECT_RECEIVER,
        TYPE: "red",
        TEXT: "Отклонен",
    },
    REJECT_SENDER: {
        VALUE: KEDO_DOCUMENT_STATUS.REJECT_SENDER,
        TYPE: "red",
        TEXT: "Отклонен",
    },
};

export const DOCUMENT_HISTORY_EVENT_TYPE = {
    DOCUMENT_CREATED: "DOCUMENT_CREATED",
    DOCUMENT_EDITED: "DOCUMENT_EDITED",
    DOCUMENT_SENT_GOS_KEY: "DOCUMENT_SENT_GOS_KEY",
    DOCUMENT_UNSENT_GOS_KEY: "DOCUMENT_UNSENT_GOS_KEY",
    DOCUMENT_SIGNED_SENDER: "DOCUMENT_SIGNED_SENDER",
    DOCUMENT_REJECTED_SENDER: "DOCUMENT_REJECTED_SENDER",
    DOCUMENT_SIGNED_RECEIVER: "DOCUMENT_SIGNED_RECEIVER",
    DOCUMENT_REJECTED_RECEIVER: "DOCUMENT_REJECTED_RECEIVER",
};

export const DOCUMENT_HISTORY_EVENT_TYPE_TEXT = {
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_CREATED]: "Создание документа",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_EDITED]: "Редактирование документа",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_SENT_GOS_KEY]: "Документ отправлен в Госключ для подписания",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_UNSENT_GOS_KEY]: "Документ не удалось отправить в Госключ для подписания",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_SIGNED_SENDER]: "Подписание документа отправителем",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_REJECTED_SENDER]: "Отклонение документа отправителем",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_SIGNED_RECEIVER]: "Подписание документа получателем",
    [DOCUMENT_HISTORY_EVENT_TYPE.DOCUMENT_REJECTED_RECEIVER]: "Отклонение документа получателем",
};