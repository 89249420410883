import React from "react";

import RefreshButton from "../../RefreshButton";
import Text from "../Text";

import bem from "../../../utils/bem";

import {COLOR} from "../../../constants/color";

import "./style.sass";

const CountRefreshButton = (props) => {
    const {
        label,
        count,
        loading,
        className,
    } = props;

    const [block] = bem("count-refresh-button", className);

    const onClick = () => {
        if (loading) {
            return;
        }

        props.onClick();
    };

    return (
        <div className={block()}>
            <Text
                level="3"
                color={COLOR.SECONDARY_70}
                className="count-refresh-button__label"
            >
                {`${label}:`}
            </Text>
            <div className="count-refresh-button__button-container">
                <Text.Sub
                    medium
                    level="1"
                    color={COLOR.SECONDARY_90}
                    className="count-refresh-button__count"
                >
                    {count}
                </Text.Sub>
                <RefreshButton
                    containerIcon
                    animate={loading}
                    className="count-refresh-button__refresh"
                    onClick={onClick}
                />
            </div>
        </div>
    );
};

export default CountRefreshButton;