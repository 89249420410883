import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import FilterObject from "../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../components/ActualComponents/FilterProject";
import FilterSpecialities from "../../../../components/ActualComponents/FilterSpecialities";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import {OrderManagersDropdown} from "../../../../components/ActualComponents/OrderManagersDropdown";

import {
    dadataValueUniqCitySettlementFilter,
    getDadataAddress,
    removeAddressByFiasLevel,
} from "../../../../utils/dadata";

import {DROPDOWN_ALL_NO_YES_OPTIONS} from "../../../../constants/dropdown";
import {ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS} from "../../../../constants/finance";
import {
    ACCESS_TO_RESPONSES_OPTIONS,
    ORDER_SOURCE_TYPE_OPTIONS,
} from "../../../../constants/order";

import {
    getObjects,
} from "../../../../ducks/objects";
import {
    getProjectsList,
} from "../../../../ducks/projects";

import PropTypes from "prop-types";

import "./style.sass";

class OrdersFilter extends Component {
    static propTypes = {
        isFinanceOrders: PropTypes.bool,
        pageData: PropTypes.object,
        submitFilter: PropTypes.func,
        handleChange: PropTypes.func,
        clearFilter: PropTypes.func,
        statusOptions: PropTypes.array,
        searchData: PropTypes.object,
        clientIdsFilter: PropTypes.string,
        isProjectForm: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        isFinanceOrders: false,
        searchData: {},
        clientIdsFilter: "",
        className: "",
        classNameFields: "",
    };

    constructor(props) {
        super(props);
        const {
            searchData: {
                projectIdsFilter = [],
            },
        } = props;

        this.state = {
            nameSubstringFilter: "",
            projectIdsFilter,
        };
    }

    get translate() {
        const {t} = this.props;

        return {
            labelOrderCustomer: t("orders-filter.order-customer"),
            labelProject: t("orders-filter.project"),
            placeholderShowAll: t("orders-filter.show-all"),
            labelObject: t("orders-filter.object"),
            labelRegion: t("orders-filter.region"),
            labelSpeciality: t("orders-filter.speciality"),
            labelOrderWorkPeriod: t("orders-filter.order-work-period"),
            labelOrderNumber: t("orders-filter.order-number"),
            labelOrderName: t("orders-filter.order-name"),
            labelOrderDateCreation: t("orders-filter.order-date-creation"),
            labelOrderStatus: t("orders-filter.order-status"),
            labelOrderAccess: t("orders-filter.order-access"),
            noResultMessage: t("orders-filter.no-search-result"),
            placeholderOrderStatus: t("orders-filter.order-status"),
        };
    }

    handleKeyPressFilter = ({key}) => {
        const {submitFilter} = this.props;

        if (key === "Enter") {
            submitFilter();
        }
    };

    onClearFilter = () => {
        const {clearFilter} = this.props;

        clearFilter();
    };

    clear = () => {
        this.setState({
            projectIdsFilter: [],
        }, this.onClearFilter);
    };

    get classNamesFilterFields() {
        const {
            isProjectForm,
            isFinanceOrders,
        } = this.props;

        if (isProjectForm || isFinanceOrders) {
            return "orders-filter__fields-container_project-page-style";
        }

        return "orders-filter__fields-container_default-style";
    }

    filterSearchedItems = (suggestions) => {
        // https://dadata.ru/api/clean/address/
        // fias_level - Уровень детализации, до которого адрес найден в ФИАС
        //  0 — страна
        //  1 — регион
        //  3 — район
        //  4 — город
        //  5 — район города
        //  6 — населенный пункт
        //  7 — улица
        //  8 — дом
        //  65 — планировочная структура
        //  90 — доп. территория
        //  91 — улица в доп. территории
        //  -1 — иностранный или пустой
        return removeAddressByFiasLevel(suggestions, ["5", "65", "90", "91", "7", "8"]);
    };

    renderFilters() {
        const {
            searchData: {
                nameSubstringFilter,
                dateWorkStartFilter,
                dateWorkEndFilter,
                orderNumFilter,
                toDateCreateFilter,
                statusFilter,
                fromDateCreateFilter,
                projectIdsFilter,
                objectIdsFilter,
                visibleToContractor,
                orderSourceTypes,
                orderContractPaymentTypeFilter,
                contractorRecruitmentRequiredFilter,
                specialityIds,
                addressFiasIds,
                orderManagerFilter,
            },
            clientIdsFilter,
            isFinanceOrders,
            onChangeFilter,
            handleChange,
            submitFilter,
            statusOptions,
            isProjectForm,
            clientId,
        } = this.props;

        const {
            placeholderShowAll,
            labelRegion,
            labelOrderWorkPeriod,
            labelOrderNumber,
            labelOrderName,
            labelOrderDateCreation,
            labelOrderStatus,
            labelOrderAccess,
            placeholderOrderStatus,
            noResultMessage,
            labelSpeciality,
        } = this.translate;

        const isOrdersFilter = !isProjectForm && !isFinanceOrders;

        return (
            <>
                {
                    isFinanceOrders &&
                    <FilterCustomer
                        name="clientIdFilter"
                        value={clientIdsFilter}
                        onChange={onChangeFilter}
                        isMountLoad={false}
                    />
                }
                {
                    isOrdersFilter &&
                    <>
                        <FilterProject
                            clientId={clientId}
                            onChange={handleChange}
                            projectIdsFilter={projectIdsFilter}
                            name="projectIdsFilter"
                            onSubmit={submitFilter}
                            placeholder={placeholderShowAll}
                        />
                        <FilterObject
                            clientId={clientId}
                            projectIdsFilter={projectIdsFilter}
                            name="objectIdsFilter"
                            onChange={handleChange}
                            objectIdsFilter={objectIdsFilter}
                            onSubmit={submitFilter}
                            placeholder={placeholderShowAll}
                        />
                        <NmDadata
                            size="lg"
                            label={labelRegion}
                            multiple
                            name="addressFiasIds"
                            onChange={handleChange}
                            isAllSelectionShown={false}
                            formatter={getDadataAddress}
                            filter={dadataValueUniqCitySettlementFilter}
                            fromBound="region"
                            toBound="settlement"
                            initialAddressIds={addressFiasIds}
                            onSubmit={submitFilter}
                        />
                    </>
                }
                <NmDateRangePickerV2
                    size="lg"
                    label={labelOrderWorkPeriod}
                    isClearable
                    startFieldName="dateWorkStartFilter"
                    endFieldName="dateWorkEndFilter"
                    value={{
                        dateWorkStartFilter: dateWorkStartFilter,
                        dateWorkEndFilter: dateWorkEndFilter,
                    }}
                    onChange={handleChange}
                    dateFormat="dd.MM.yy"
                    returnString={false}
                />
                {
                    <div className="orders-filter__with-buttons">
                        {
                            !isFinanceOrders &&
                            <FilterSpecialities
                                name="specialityIds"
                                label={labelSpeciality}
                                onChange={handleChange}
                                noResultMessage={noResultMessage}
                                onFilterSearchChange={submitFilter}
                                value={specialityIds}
                                placeholder={placeholderShowAll}
                                isActiveStyle={specialityIds.length === 0}
                                onSubmit={submitFilter}
                            />
                        }
                        {
                            isOrdersFilter &&
                            <FilterButtonsV2
                                className="orders-filter__actions"
                                onSearch={submitFilter}
                                onClear={this.clear}
                            />
                        }
                    </div>
                }
                <NmInputV2
                    size="lg"
                    label={labelOrderNumber}
                    mask="99999999999999"
                    maskChar={null}
                    onChange={handleChange}
                    name="orderNumFilter"
                    value={orderNumFilter || ""}
                />
                <div className="orders-filter__with-buttons">
                    <NmInputV2
                        size="lg"
                        label={labelOrderName}
                        onChange={handleChange}
                        name="nameSubstringFilter"
                        value={nameSubstringFilter || ""}
                    />
                    {
                        !isOrdersFilter &&
                        <FilterButtonsV2
                            className="orders-filter__actions"
                            onSearch={submitFilter}
                            onClear={this.clear}
                        />
                    }
                </div>
                <NmDateRangePickerV2
                    size="lg"
                    label={labelOrderDateCreation}
                    isClearable
                    startFieldName="fromDateCreateFilter"
                    endFieldName="toDateCreateFilter"
                    value={{
                        fromDateCreateFilter: fromDateCreateFilter,
                        toDateCreateFilter: toDateCreateFilter,
                    }}
                    onChange={handleChange}
                    dateFormat="dd.MM.yy"
                    returnString={false}
                />
                <NmDropdownV2
                    size="lg"
                    label={labelOrderStatus}
                    options={statusOptions}
                    name="statusFilter"
                    onChange={handleChange}
                    value={statusFilter}
                    placeholder={placeholderOrderStatus}
                />
                <NmDropdownV2
                    size="lg"
                    label={labelOrderAccess}
                    options={ACCESS_TO_RESPONSES_OPTIONS}
                    name="visibleToContractor"
                    onChange={handleChange}
                    value={visibleToContractor}
                    //exceptionActiveValues={simpleEmptyOption}
                />
                <NmDropdownV2
                    size="lg"
                    label="Тип заказа"
                    options={ORDER_SOURCE_TYPE_OPTIONS}
                    name="orderSourceTypes"
                    onChange={handleChange}
                    value={orderSourceTypes}
                    multiple
                    placeholder="Показать все"
                />
                <NmDropdownV2
                    size="lg"
                    label="Договор"
                    options={ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS}
                    name="orderContractPaymentTypeFilter"
                    onChange={handleChange}
                    value={orderContractPaymentTypeFilter}
                />
                {
                    isOrdersFilter &&
                    <NmDropdownV2
                        size="lg"
                        label="Требуется подбор"
                        name="contractorRecruitmentRequiredFilter"
                        placeholder="Не выбрано"
                        options={DROPDOWN_ALL_NO_YES_OPTIONS}
                        value={contractorRecruitmentRequiredFilter}
                        onChange={handleChange}
                    />
                }
                {
                    isOrdersFilter &&
                    <OrderManagersDropdown
                        clientId={clientId}
                        name="orderManagerFilter"
                        value={orderManagerFilter}
                        onChange={handleChange}
                    />
                }
                {
                    isFinanceOrders &&
                    <NmCheckboxV2
                        name="contractorRecruitmentRequiredFilter"
                        checked={contractorRecruitmentRequiredFilter}
                        onChange={handleChange}
                        label="Требуется подбор исполнителя"
                    />
                }
            </>
        );
    }

    render() {
        const {className} = this.props;

        return (
            <NmForm
                addMargin
                onKeyPress={this.handleKeyPressFilter}
                className={`orders-filter ${className}`}
            >
                {this.renderFilters()}
            </NmForm>
        );
    }
}

export default connect(
    state => ({
    }),
    {
        getProjectsList,
        getObjects,
    },
)(withTranslation()(OrdersFilter));