import {requiredMessage} from "../../../../../../constants/validationRules";

import {EDO_STATEMENT_LOADING_FIELD} from "../../../../../../constants/document-management/statements";

export const DOCUMENT_MANAGEMENT_LOADING_STATEMENT_RULE = {
    [EDO_STATEMENT_LOADING_FIELD.STATEMENT_NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 500,
            message: "Максимальная длина строки 500 символов",
        },
    },
    [EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE]: {
        required: requiredMessage,
    },
    [EDO_STATEMENT_LOADING_FIELD.STATEMENT_DATE_TIME]: {
        required: requiredMessage,
    },
    [EDO_STATEMENT_LOADING_FIELD.DESCRIPTION]: {
        maxLength: {
            value: 255,
            message: "Максимальная длина строки 255 символов",
        },
    },
};

export const REJECTION_SIGN_STATEMENT_RULE = {
    rejectionReason: {
        required: requiredMessage,
    },
};