import {isEmpty} from "lodash";

import {SMART_LINK_EDIT_STEP, SMART_LINK_FILTER_BODY_TYPE, SMART_LINK_FORM_TYPE} from "../constants";

export const getSmartLinkInitialValues = (params = {}) => {
    const {
        smartLinkId,
        card = {},
        filterType,
    } = params;

    return {
        smartLinkId,
        activeStep: SMART_LINK_EDIT_STEP.GENERAL_FILTERS,
        filterType: filterType || card.filterType || SMART_LINK_FILTER_BODY_TYPE.FILTERS,
        type: card.type || SMART_LINK_FORM_TYPE.ORDER,
        name: card.name || "",
        fromDate: card.fromDate || null,
        toDate: card.toDate || null,
        fromAmount: card.fromAmount ? +card.fromAmount : "",
        toAmount: card.toAmount ? +card.toAmount : "",
        specialityIds: isEmpty(card.specialityIds) ? [] : card.specialityIds,
        cityFiasId: card.city?.fiasId || "",
        regionFiasId: card.region?.fiasId || "",
        nums: isEmpty(card.numbers)
            ? []
            : card.numbers.map(item => {
                return item.number;
            }),
        clientCategoryIds: isEmpty(card.clientCategoryIds) ? [] : card.clientCategoryIds,
    };
};