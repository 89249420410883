import {PartialRecord} from "../../../../types/common";
import {MediaButtonsBreakpointNames} from "../types";

const GLOBAL_QUERIES = {
    mobile: {maxWidth: 767},
    tablet: {minWidth: 768, maxWidth: 1919},
    desktop: {minWidth: 1920},
};

export const getMediaControlsQueries = (renderCount: PartialRecord<MediaButtonsBreakpointNames, number>) => {
    if (typeof renderCount.preDesktop === "number") {
        return {
            ...GLOBAL_QUERIES,
            tablet: {minWidth: 768, maxWidth: 1199},
            preDesktop: {minWidth: 1200, maxWidth: 1919},
        };
    }

    return GLOBAL_QUERIES;
};