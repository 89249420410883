import * as yup from "yup";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        "files": yup.object().shape({
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });
};

export default validationSchema;