import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmModalCardList from "../../../components/ActualComponents/NmModal/CardList";
import NmPagination from "../../../components/ActualComponents/NmPagination";
import ApplyButtons from "../../../components/ApplyButtons";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as BlockedIcon} from "../../../images/lock_24.svg";

import {
    getFullName,
    phoneFormat,
} from "../../../utils/stringFormat";

import {
    REGISTRY_SEARCH_STEP,
} from "../../../constants/registry";

import {
    contractorProgressApplicationsSelector,
    contractorSearchListSelector,
    contractorTotalPagesSelector,
} from "../../../ducks/contractor";
import {
    checkPatentRegistryContractorPresentPayments,
    patentsRegistriesActionRegistryProgressSelector,
    patentsRegistryCardSelector,
} from "../../../ducks/patentsRegistry";

import PropTypes from "prop-types";

import "./style.sass";

class RegistrySearchContractorResults extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        onClose: PropTypes.func,
        isPatentsRegistry: PropTypes.bool,
    };

    static defaultProps = {
        objectBudgetData: {},
        onClose: () => {
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            isContractorPresent: {},
        };
    }

    componentDidMount() {
        const {isPatentsRegistry} = this.props;

        if (isPatentsRegistry) {
            this.checkPatentRegistryContractorPresentPayments();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            list,
            isPatentsRegistry,
        } = this.props;
        const {
            list: _list,
        } = prevProps;

        if (isPatentsRegistry && !isEmpty(list) && !isEqual(list, _list)) {
            this.setState({
                isContractorPresent: {},
            }, this.checkPatentRegistryContractorPresentPayments());
        }
    }

    componentWillUnmount() {
    }

    static getDerivedStateFromProps(props, state) {
    }

    get localizationData() {
        return {};
    }

    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    isValidForm = () => {
    };

    submit = () => {
    };

    checkPatentRegistryContractorPresentPayments = () => {
        const {
            checkPatentRegistryContractorPresentPayments,
            clientId,
            patentCard: {
                patentRegistryId,
            },
            list,
        } = this.props;

        list.forEach(({contractorId, phone}) => {
            checkPatentRegistryContractorPresentPayments({
                data: {
                    clientId,
                    contractorId,
                    contractorPhone: phone,
                    patentRegistryId,
                },
                onSuccess: ({result}) => {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            isContractorPresent: {
                                ...prevState.isContractorPresent,
                                [contractorId]: result,
                            },
                        };
                    });
                },
            });
        });
    };

    pick = (item) => {
        const {nextStep} = this.props;

        return () => nextStep(
            REGISTRY_SEARCH_STEP.STEP_3.VALUE,
            {
                ...item,
            },
            REGISTRY_SEARCH_STEP.STEP_2.VALUE,
        );
    };

    renderAction(item) {
        const {
            isPatentsRegistry,
            checkProgress,
        } = this.props;
        const {isContractorPresent} = this.state;

        if (checkProgress) {
            return;
        }

        if (isPatentsRegistry && isContractorPresent[item.contractorId]) {
            return (
                <NmButton disabled>
                    Добавлен
                </NmButton>
            );
        }

        return (
            <NmButton
                color="light-green"
                className="registry-found-contractors__pick"
                onClick={this.pick(item)}
            >
                Выбрать
            </NmButton>
        );
    }

    back = () => {
        const {nextStep} = this.props;

        nextStep(REGISTRY_SEARCH_STEP.STEP_1.VALUE);
    };

    renderActions() {
        const {
            onClose,
        } = this.props;

        return (
            <ApplyButtons
                mobile="column"
                isHiddenCancelOnMobile
                additionalBtnContent="Назад"
                cancelBtnContent="Отменить"
                onClickAdditional={this.back}
                onClose={onClose}
            />
        );
    }

    renderBlockedInfo = ({blocked, blockedReason}) => {
        return (
            blocked && <NmAdvancedTooltip
                className="d-flex align-items-center pointer ms-2"
                trigger={
                    <BlockedIcon
                        width={18}
                        height={18}
                        color="red"
                    />
                }
                hover
            >
                {blockedReason}
            </NmAdvancedTooltip>
        );
    };

    renderNameLabel = (contractor) => {
        const {
            lastName,
            firstName,
            patronymic,
            fullName,
        } = contractor;

        return (
            <div className="flex">
                {lastName ? getFullName(lastName, firstName, patronymic) : fullName}
                {this.renderBlockedInfo(contractor)}
            </div>
        );
    };


    getCards = () => {
        const {list} = this.props;

        return list.map(item => {

            return {
                ...item,
                singleValues: [
                    {label: "ФИО", value: this.renderNameLabel(item)},
                    {label: "Телефон", value: phoneFormat(item.phone)},
                ],
                actions: this.renderAction(item),
            };
        });
    };

    render() {
        const {
            onClose,
            totalPages,
            onPageChange,
            pageNum,
        } = this.props;

        return (
            <NmModal
                size="md"
                className="registry-found-contractors__popup"
                header={
                    <NmTitle size="lg">
                        Добавление исполнителя
                    </NmTitle>
                }
                footer={this.renderActions()}
                onClose={onClose}
            >
                <NmForm>
                    <NmModalCardList
                        list={this.getCards()}
                        pagination={
                            <NmPagination
                                pageNum={pageNum}
                                totalPages={totalPages}
                                onChangePagination={onPageChange}
                            />
                        }
                    />
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        list: contractorSearchListSelector(state),
        progress: contractorProgressApplicationsSelector(state),
        totalPages: contractorTotalPagesSelector(state),
        patentCard: patentsRegistryCardSelector(state),
        checkProgress: patentsRegistriesActionRegistryProgressSelector(state),
    }),
    {
        checkPatentRegistryContractorPresentPayments,
    },
)(withTranslation()(RegistrySearchContractorResults));