import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const selector = state => state.kedoDocuments;
export const bffCompaniesCompanyKedoDocumentsListSelector = createSelector(
    selector,
    ({list}) => list,
);
export const bffCompaniesCompanyKedoDocumentsTotalCountSelector = createSelector(
    selector,
    ({totalCount}) => totalCount,
);
export const bffCompaniesCompanyKedoDocumentsTotalPageSelector = createSelector(
    selector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffCompaniesCompanyKedoDocumentsProgressSelector = createSelector(
    selector,
    ({progress}) => progress,
);
export const bffCompaniesCompanyKedoDocumentsProgressActionSelector = createSelector(
    selector,
    ({progressAction}) => progressAction,
);
export const bffCompaniesCompanyKedoDocumentsHistorySelector = createSelector(
    selector,
    ({history}) => {
        return {
            ...history,
            totalPages: getTotalPages(history.totalCount, history.pageData.pageSize),
        };
    },
);