import {
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_CLEAR_STORE,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    listTotalCount: 0,
    listPageData: {},
    availableUsers: [],
    availableUsersTotalCount: 0,
    availableUsersPageData: {},
    progress: false,
    progressAction: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST:
        return {
            ...state,
            availableUsersPageData: payload,
            progress: true,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_SUCCESS:
        return {
            ...state,
            availableUsers: payload.results,
            availableUsersTotalCount: payload.totalCount,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST:
        return {
            ...state,
            listPageData: payload,
            progress: true,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_SUCCESS:
        return {
            ...state,
            list: payload.results,
            listTotalCount: payload.totalCount,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_SUCCESS:
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_CLEAR_STORE: {
        return initial;
    }
    default:
        return state;
    }
};