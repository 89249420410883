import React from "react";

import bem from "../../../utils/bem";

import "./style.sass";

const Ellipse = ({className = ""}) => {
    const [block] = bem("nmx-ellipse", className);

    return (
        <div className={block()} />
    );
};

export default Ellipse;