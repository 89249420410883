import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContractorBlackListWarningModal from "../../../components/ContractorBlackListWarningModal";

import {
    orderContractorActionPageDataSelector,
    orderContractorErrorSelector,
    updateOrderContractorsStore,
} from "../../../ducks/orderContractors";

export const OrderContractorsErrors = () => {
    const dispatch = useDispatch();

    const error = useSelector(orderContractorErrorSelector);
    const actionPageData = useSelector(orderContractorActionPageDataSelector);

    const onClose = () => {
        dispatch(updateOrderContractorsStore({
            error: null,
            actionPageData: null,
        }));
    };

    if (isEmpty(error?.contractorsInBlacklist)) {
        return null;
    }

    return (
        <ContractorBlackListWarningModal
            onClose={onClose}
            isMassOperation={actionPageData?.params.isMassOperation}
            contractorsInBlacklist={error.contractorsInBlacklist}
        />
    );
};