import React from "react";
import {useSelector} from "react-redux";

import {
    bffRecruitmentVacancyCardRecruitersWithAccessSelector,
    bffRecruitmentVacancyProgressSelector,
} from "../../../ducks/bff/recruitment/vacancy/selectors";
import NmModal from "../../ActualComponents/NmModal";
import NmPagination from "../../ActualComponents/NmPagination";
import Text from "../../ActualComponents/Text";
import NmButton from "../../NmButton";
import NmTitle from "../../NmTitle";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useRecruitmentVacanciesCardRecruitersWithAccessFetch} from "./hooks/useFetch";

import {COLOR} from "../../../constants/color";
import {RECRUITMENT_VACANCY_DESCRIPTION_ACCESS_DICTS} from "../../../containers/recruitment/vacancies/constants";

const RecruitersWithAccessModal = (props) => {
    const {
        modalTitle,
        buttonText = "Посмотреть",
        textLevel,
        totalCount,
        vacancy,
        clientId,
    } = props;

    const recruitersWithAccess = useSelector(bffRecruitmentVacancyCardRecruitersWithAccessSelector);
    const loading = useSelector(bffRecruitmentVacancyProgressSelector);

    const {
        isOpen,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        pageNum,
        pageSize,
        onPaginationChange,
    } = usePagination();

    useRecruitmentVacanciesCardRecruitersWithAccessFetch({
        vacancyId: vacancy.vacancyId,
        clientId,
        pageNum,
        pageSize,
    });

    const getPagination = () => {
        return (
            <NmPagination
                className="mt-2 mt-md-6 mt-xxl-8"
                totalCount={totalCount}
                onChangePagination={onPaginationChange}
                pageNum={pageNum}
                pageSize={pageSize}
            />
        );
    };

    const getContent = () => {
        return recruitersWithAccess.list.map(item => {
            return (
                <div key={item.clientUserId}>
                    <div className="flex-column mb-4">
                        <Text
                            level="4"
                            color={COLOR.SECONDARY_100}
                            className="mb-1"
                        >
                            {item.clientUserName}
                        </Text>
                        <Text
                            level="3"
                            color={COLOR.SECONDARY_45}
                        >
                            {RECRUITMENT_VACANCY_DESCRIPTION_ACCESS_DICTS[item.accessType]}
                        </Text>
                    </div>
                    {getPagination()}
                </div>
            );
        });
    };


    const getFooter = () => {
        return (
            <NmButton
                size="xl"
                onClick={onCloseModal}
            >
                Понятно
            </NmButton>
        );
    };

    return (
        <>
            <Text
                color={COLOR.PASSIVE_100}
                level={textLevel}
                onClick={() => {
                    onOpenModal({});
                }}
            >
                {buttonText}
            </Text>
            {
                isOpen &&
                <NmModal
                    size="md"
                    header={
                        modalTitle &&
                            <NmTitle size="lg">
                                {modalTitle}
                            </NmTitle>
                    }
                    footer={getFooter()}
                    classNameContent="flex-column"
                    children={getContent()}
                    onClose={onCloseModal}
                    classNameFooter="flex justify-content-end"
                    loading={loading}
                />
            }
        </>
    );

};

export default RecruitersWithAccessModal;