import React from "react";

import NmIcon from "../../NmIcon";

import bem from "../../../utils/bem";

import "./style.sass";

const OrderScheduleTimePeriod = (props) => {
    const {
        onRemoveTime,
        className = "",
        from,
        disabled,
        to ,
        onClick,
    } = props;
    const [block, element] = bem("order-schedule-time-period", className);

    const isEmpty = !from && !to;

    return (
        <div className={block()}>
            <div
                onClick={onClick}
                className={element("time", {empty: isEmpty})}
            >
                <div className="order-schedule-time-period__time-start">
                    {from || "c"}
                </div>
                <div className="order-schedule-time-period__time-end">
                    {to || "по"}
                </div>
            </div>
            {
                !isEmpty && !disabled &&
                <NmIcon
                    name="close"
                    className="order-schedule-time-period__close"
                    onClick={onRemoveTime}
                />
            }
        </div>
    );
};

export default OrderScheduleTimePeriod;