import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {
    contractorDictsAdditionalDocumentStatusDictOptionsSelector,
} from "../../ducks/contractorsDicts";

const AdditionalDocumentStatusFilter = (props) => {
    const options = useSelector(contractorDictsAdditionalDocumentStatusDictOptionsSelector);

    return (
        <NmDropdownV2
            size="lg"
            placeholder="Показать все"
            label="Статус доп. документов"
            name="additionalDocumentStatusesFilter"
            multiple
            options={options}
            {...props}
        />
    );
};

export default AdditionalDocumentStatusFilter;