import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/objects";
//*  TYPES  *//

const OBJECTS_LIST_REQUEST = "OBJECTS_LIST_REQUEST";
const OBJECTS_LIST_SUCCESS = "OBJECTS_LIST_SUCCESS";
const OBJECTS_LIST_ERROR = "OBJECTS_LIST_ERROR";

const OBJECTS_CLOSE_REQUEST = "OBJECTS_CLOSE_REQUEST";
const OBJECTS_CLOSE_SUCCESS = "OBJECTS_CLOSE_SUCCESS";
const OBJECTS_CLOSE_ERROR = "OBJECTS_CLOSE_ERROR";

const OBJECTS_UPDATE_STATUS_REQUEST = "OBJECTS_UPDATE_STATUS_REQUEST";
const OBJECTS_UPDATE_STATUS_SUCCESS = "OBJECTS_UPDATE_STATUS_SUCCESS";
const OBJECTS_UPDATE_STATUS_ERROR = "OBJECTS_UPDATE_STATUS_ERROR";

const GET_OBJECT_REQUEST = "GET_OBJECT_REQUEST";
const GET_OBJECT_SUCCESS = "GET_OBJECT_SUCCESS";
const GET_OBJECT_ERROR = "GET_OBJECT_ERROR";

const GET_ENRICHED_OBJECT_REQUEST = "GET_ENRICHED_OBJECT_REQUEST";

const IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_REQUEST = "IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_REQUEST";
const IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_SUCCESS = "IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_SUCCESS";
const IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_ERROR = "IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_ERROR";

const GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST = "GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST";
const GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS = "GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS";
const GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR = "GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR";

const GET_PAGE_FOR_OBJECTS_REQUEST = "GET_PAGE_FOR_OBJECTS_REQUEST";
const GET_PAGE_FOR_OBJECTS_SUCCESS = "GET_PAGE_FOR_OBJECTS_SUCCESS";
const GET_PAGE_FOR_OBJECTS_ERROR = "GET_PAGE_FOR_OBJECTS_ERROR";

const GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST = "GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST";
const GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS = "GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS";
const GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR = "GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR";

const GET_RICH_PAGE_FOR_OBJECTS_REQUEST = "GET_RICH_PAGE_FOR_OBJECTS_REQUEST";
const GET_RICH_PAGE_FOR_OBJECTS_SUCCESS = "GET_RICH_PAGE_FOR_OBJECTS_SUCCESS";
const GET_RICH_PAGE_FOR_OBJECTS_ERROR = "GET_RICH_PAGE_FOR_OBJECTS_ERROR";

const ADD_OBJECT_DEPOSIT_REQUEST = "ADD_OBJECT_DEPOSIT_REQUEST";
const ADD_OBJECT_DEPOSIT_SUCCESS = "ADD_OBJECT_DEPOSIT_SUCCESS";
const ADD_OBJECT_DEPOSIT_ERROR = "ADD_OBJECT_DEPOSIT_ERROR";

const RETURN_OBJECT_DEPOSIT_REQUEST = "RETURN_OBJECT_DEPOSIT_REQUEST";
const RETURN_OBJECT_DEPOSIT_SUCCESS = "RETURN_OBJECT_DEPOSIT_SUCCESS";
const RETURN_OBJECT_DEPOSIT_ERROR = "RETURN_OBJECT_DEPOSIT_ERROR";

const OBJECTS_CHANGE_ARCHIVE_REQUEST = "OBJECTS_CHANGE_ARCHIVE_REQUEST";
const OBJECTS_CHANGE_ARCHIVE_SUCCESS = "OBJECTS_CHANGE_ARCHIVE_SUCCESS";
const OBJECTS_CHANGE_ARCHIVE_ERROR = "OBJECTS_CHANGE_ARCHIVE_ERROR";

const OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST = "OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST";
const OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS = "OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS";
const OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR = "OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR";

const UPDATE_OBJECTS_FIELD_STORE = "UPDATE_OBJECTS_FIELD_STORE";

//*  INITIAL STATE  *//

const initial = {
    objects: [],
    pageData: {},
    totalCount: 0,
    card: {},
    objectsUserResponsibleFor: [],
    progressCheck: null,
    progressCard: false,
    progressChangeDeposit: false,
    userCanCreateOrderOnlyByTemplate: false,
    userCanNotCreateOrderByTemplate: false,
    progressUserCanNotCreateOrderByTemplate: null,
    progressUserCanCreateOrderOnlyByTemplate: null,
    optimizedObjects: [],
    objectsDepositHistory: [],
    objectsDepositHistoryTotalCount: 0,
    objectsDepositHistoryPageData: {},
    objectsForOrders: [],
    objectsForTasks: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case RETURN_OBJECT_DEPOSIT_ERROR:
    case RETURN_OBJECT_DEPOSIT_SUCCESS:
    case ADD_OBJECT_DEPOSIT_ERROR:
    case ADD_OBJECT_DEPOSIT_SUCCESS:
        return {
            ...state,
            progressChangeDeposit: false,
        };
    case RETURN_OBJECT_DEPOSIT_REQUEST:
    case ADD_OBJECT_DEPOSIT_REQUEST:
        return {
            ...state,
            progressChangeDeposit: true,
        };
    case GET_PAGE_FOR_OBJECTS_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressList: true,
        };
    case GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST:
        return {
            ...state,
            objectsDepositHistoryPageData: {
                pageNum: payload.pageNum,
                pageSize: payload.pageSize,
            },
        };
    case GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS:
        return {
            ...state,
            objectsDepositHistory: payload.results,
            objectsDepositHistoryTotalCount: payload.totalCount,
        };
    case GET_PAGE_FOR_OBJECTS_ERROR:
        return {
            ...state,
            progressList: false,
        };
    case GET_PAGE_FOR_OBJECTS_SUCCESS: {
        const {
            objects,
            totalCount,
            fieldName = "optimizedObjects",
        } = payload;

        return {
            ...state,
            [fieldName]: objects,
            progressList: false,
            totalCount,
        };
    }
    case GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST:
        return {
            ...state,
            progressUserCanNotCreateOrderByTemplate: true,
        };
    case GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS:
        return {
            ...state,
            progressUserCanNotCreateOrderByTemplate: false,
            userCanNotCreateOrderByTemplate: payload,
        };
    case GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR:
        return {
            ...state,
            progressUserCanNotCreateOrderByTemplate: false,
        };
    case IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_REQUEST:
        return {
            ...state,
            progressCheck: true,
        };
    case IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_SUCCESS:
        return {
            ...state,
            userCanCreateOrderOnlyByTemplate: payload,
            progressCheck: false,
        };
    case IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_ERROR:
        return {
            ...state,
            progressCheck: false,
        };
    case UPDATE_OBJECTS_FIELD_STORE:
        return {
            ...state,
            ...payload,
        };
    case GET_RICH_PAGE_FOR_OBJECTS_REQUEST:
    case OBJECTS_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressList: true,
        };
    case GET_RICH_PAGE_FOR_OBJECTS_SUCCESS:
    case OBJECTS_LIST_SUCCESS:
        const {
            objects,
            totalCount,
            fieldName = "objects",
        } = payload;

        return {
            ...state,
            [fieldName]: objects,
            progressList: false,
            totalCount,
        };
    case GET_ENRICHED_OBJECT_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case GET_OBJECT_SUCCESS:
        return {
            ...state,
            progressCard: false,
            card: payload,
        };
    case GET_RICH_PAGE_FOR_OBJECTS_ERROR:
    case GET_OBJECT_ERROR:
        return {
            ...state,
            progressCard: false,
            error: payload,
        };
    case OBJECTS_LIST_ERROR:
        return {
            ...state,
            error: payload,
            progressList: false,
        };
    default:
        return state;
    }
};


//*  ACTION CREATORS  *//

export const updateFieldsObjectsStore = payload => ({
    type: UPDATE_OBJECTS_FIELD_STORE,
    payload,
});

export function getObjects(payload) {
    return {
        type: OBJECTS_LIST_REQUEST,
        payload,
    };
}

export function closeObject(payload) {
    return {
        type: OBJECTS_CLOSE_REQUEST,
        payload,
    };
}

export function updateObjectStatus(payload) {
    return {
        type: OBJECTS_UPDATE_STATUS_REQUEST,
        payload,
    };
}

export function getEnrichedObjectById(payload) {
    return {
        type: GET_ENRICHED_OBJECT_REQUEST,
        payload,
    };
}

export function getObjectById(payload) {
    return {
        type: GET_OBJECT_REQUEST,
        payload,
    };
}

export function getUserCanCreateOrderOnlyByTemplate(payload) {
    return {
        type: IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_REQUEST,
        payload,
    };
}

export function getUserCanNotCreateOrderByTemplate(payload) {
    return {
        type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST,
        payload,
    };
}

// оптимизированный список объектов
export function getOptimizedObjects(payload) {
    return {
        type: GET_PAGE_FOR_OBJECTS_REQUEST,
        payload,
    };
}

export function getObjectsDepositHistoryPage(payload){
    return {
        type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST,
        payload,
    };
}

// обогащенный список объектов
export function getRichObjects(payload) {
    return {
        type: GET_RICH_PAGE_FOR_OBJECTS_REQUEST,
        payload,
    };
}

export function addObjectDeposit(payload) {
    return {
        type: ADD_OBJECT_DEPOSIT_REQUEST,
        payload,
    };
}

export function returnObjectDeposit(payload) {
    return {
        type: RETURN_OBJECT_DEPOSIT_REQUEST,
        payload,
    };
}

export function changeArchiveObject(payload) {
    return {
        type: OBJECTS_CHANGE_ARCHIVE_REQUEST,
        payload,
    };
}

export function multipleChangeArchiveObject(payload) {
    return {
        type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

const objectsSelector = state => state.objects;
export const objectsListSelector = createSelector(objectsSelector, ({objects}) => objects);
export const objectsListOptionsSelector = createSelector(objectsSelector, ({objects}) => {
    return objects.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});

export const cardProgressSelector  = createSelector(objectsSelector, ({progressCard}) => progressCard);
export const objectsProgressListSelector = createSelector(objectsSelector, ({progressList}) => progressList);
export const objectsTotalPagesSelector = createSelector(objectsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const objectsCardSelector = createSelector(objectsSelector, ({card}) => card);
export const objectsTotalCountSelector = createSelector(objectsSelector, ({totalCount}) => totalCount);
export const objectsProgressChangeDepositSelector = createSelector(objectsSelector, ({progressChangeDeposit}) => progressChangeDeposit);
export const objectsUserCanCreateOrderOnlyByTemplateSelector = createSelector(objectsSelector, ({userCanCreateOrderOnlyByTemplate}) => userCanCreateOrderOnlyByTemplate);
export const objectsUserCanNotCreateOrderByTemplate = createSelector(objectsSelector, ({userCanNotCreateOrderByTemplate}) => userCanNotCreateOrderByTemplate);
export const objectsProgressUserCanCreateOrderOnlyByTemplateSelector = createSelector(objectsSelector, ({progressUserCanCreateOrderOnlyByTemplate}) => progressUserCanCreateOrderOnlyByTemplate);
export const objectsProgressUserCanNotCreateOrderByTemplateSelector = createSelector(objectsSelector, ({progressUserCanNotCreateOrderByTemplate}) => progressUserCanNotCreateOrderByTemplate);

export const objectsOptimizedSelector = createSelector(objectsSelector, ({optimizedObjects}) => optimizedObjects);
export const objectsOptimizedOptionsSelector = createSelector(objectsSelector, ({optimizedObjects}) => {
    return optimizedObjects.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});
export const objectsForOrdersOptionsSelector = createSelector(objectsSelector, ({objectsForOrders}) => {
    return objectsForOrders.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});
export const objectsForTasksOptionsSelector = createSelector(objectsSelector, ({objectsForTasks}) => {
    return objectsForTasks.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});

export const objectsDepositHistoryTotalCountSelector = createSelector(objectsSelector, ({objectsDepositHistoryTotalCount}) => objectsDepositHistoryTotalCount);
export const objectsDepositHistoryTotalPagesSelector = createSelector(objectsSelector, ({objectsDepositHistoryTotalCount: totalCount, objectsDepositHistoryPageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const objectsDepositHistoryPageSelector = createSelector(objectsSelector, ({objectsDepositHistory}) => objectsDepositHistory);

//*  SAGA  *//

export const getObjectsSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.post(`${controller}/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: OBJECTS_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: OBJECTS_LIST_ERROR, payload: error});
    }
};

export const getEnrichedObjectSaga = function* ({payload}) {
    try {
        const {onSuccess, ...params} = payload;
        const result = yield request.get(`${controller}/getRichById`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_OBJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess(result);

        yield put({type: GET_OBJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_OBJECT_ERROR, payload: error});
    }
};

//updateObjectStatus

export const updateObjectStatusSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/updateObjectStatus`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_UPDATE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Статус объекта успешно изменен");

        const {
            objects: {
                pageData,
            },
        } = yield select();

        yield put(getRichObjects(pageData));
        yield put({type: OBJECTS_UPDATE_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: OBJECTS_UPDATE_STATUS_ERROR, payload: error});
    }
};

export const closeObjectSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/close`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_CLOSE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Объект успешно закрыт");

        const {
            objects: {
                pageData,
            },
        } = yield select();

        yield put(getRichObjects(pageData));
        yield put({type: OBJECTS_CLOSE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: OBJECTS_CLOSE_ERROR, payload: error});
    }
};

//GET /api/objects/getById

export const getObjectByIdSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/getById`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_OBJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_OBJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_OBJECT_ERROR, payload: error});
    }
};

// POST /api/objects/userCanCreateOrderOnlyByTemplate
export const userCanCreateOrderOnlyByTemplateSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/userCanCreateOrderOnlyByTemplate`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_ERROR,
            payload: error,
        });
    }
};

// POST /api/objects/userCanNotCreateOrderByTemplate
export const getUserCanNotCreateOrderByTemplateSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/userCanNotCreateOrderByTemplate`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR,
            payload: error,
        });
    }
};

// оптимизированный список объектов
export const getOptimizedObjectsSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.post(`${controller}/getOptimizedPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_FOR_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_FOR_OBJECTS_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_PAGE_FOR_OBJECTS_ERROR, payload: error});
    }
};

///api/objects/deposit/history/page
//POST
///api/civil/objects/deposit/history/page
export const getObjectsDepositHistoryPageSaga = function* ({payload}) {
    try {
        const {
            getResult,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.post(`${isCivil ? "/civil" : ""}${controller}/deposit/history/page`, reqData);
        const {errorMessage} = result;
        getResult();
        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR, payload: error});
    }
};

// пополнение депозита объекта
export const addObjectDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            isCivil = false,
            ...reqData
        } = payload;

        const result = yield request.post(`${isCivil ? "/civil" : ""}${controller}/deposit/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_OBJECT_DEPOSIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ADD_OBJECT_DEPOSIT_SUCCESS,
        });
    } catch (error) {
        yield put({type: ADD_OBJECT_DEPOSIT_ERROR, payload: error});
    }
};


// возврат депозита объекта
export const returnObjectDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            isCivil = false,
            ...reqData
        } = payload;

        const result = yield request.post(`${isCivil ? "/civil" : ""}${controller}/deposit/return`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RETURN_OBJECT_DEPOSIT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: RETURN_OBJECT_DEPOSIT_SUCCESS,
        });
    } catch (error) {
        yield put({type: RETURN_OBJECT_DEPOSIT_ERROR, payload: error});
    }
};

// обогащенный список объектов
export const getRichObjectsSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.post(`${controller}/getRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RICH_PAGE_FOR_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_RICH_PAGE_FOR_OBJECTS_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_RICH_PAGE_FOR_OBJECTS_ERROR, payload: error});
    }
};

//POST /api/objects/change-archived
export const changeArchiveObjectSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/change-archived`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_CHANGE_ARCHIVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const {
            objects: {
                pageData,
            },
        } = yield select();

        yield put(getRichObjects(pageData));

        yield put({
            type: OBJECTS_CHANGE_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: OBJECTS_CHANGE_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

//POST /api/job/objects/multiple-change-archived
export const multipleChangeArchiveObjectSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post("/job/objects/multiple-change-archived", reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const {
            objects: {
                pageData,
            },
        } = yield select();

        yield put(getRichObjects(pageData));

        yield put({
            type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(OBJECTS_LIST_REQUEST, getObjectsSaga),
        takeEvery(OBJECTS_UPDATE_STATUS_REQUEST, updateObjectStatusSaga),
        takeEvery(OBJECTS_CLOSE_REQUEST, closeObjectSaga),
        takeEvery(GET_OBJECT_REQUEST, getObjectByIdSaga),
        takeEvery(GET_ENRICHED_OBJECT_REQUEST, getEnrichedObjectSaga),
        takeEvery(IS_USER_CAN_CREATE_ORDER_ONLY_BY_TEMPLATE_REQUEST, userCanCreateOrderOnlyByTemplateSaga),
        takeEvery(GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST, getUserCanNotCreateOrderByTemplateSaga),
        takeEvery(GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST, getObjectsDepositHistoryPageSaga),
        takeEvery(GET_PAGE_FOR_OBJECTS_REQUEST, getOptimizedObjectsSaga),
        takeEvery(ADD_OBJECT_DEPOSIT_REQUEST, addObjectDepositSaga),
        takeEvery(RETURN_OBJECT_DEPOSIT_REQUEST, returnObjectDepositSaga),
        takeEvery(GET_RICH_PAGE_FOR_OBJECTS_REQUEST, getRichObjectsSaga),
        takeEvery(OBJECTS_CHANGE_ARCHIVE_REQUEST, changeArchiveObjectSaga),
        takeEvery(OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST, multipleChangeArchiveObjectSaga),
    ]);
}
