import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import MapAdvertListCard from "../../containers/map/components/advert-list-card";
import MapContractorListCard from "../../containers/map/components/contractor-list-card";
import MapOrderListCard from "../../containers/map/components/order-list-card";
import MapTaskListCard from "../../containers/map/components/task-list-card";
import {ReactComponent as CloseIcon} from "../../images/close_24.svg";
import Avatar from "../Avatar";
import CheckboxList from "../CheckboxList";
import NmTitle from "../NmTitle";
import {Dimmer, Loader} from "semantic-ui-react";

import {useClearMapModalData} from "../../containers/map/hooks/useClearModalData";

import bem from "../../utils/bem";

import {
    MAP_ADVERT_SORT_TYPE,
    MAP_ORDER_SORT_TYPE,
    MAP_TASK_SORT_TYPE,
} from "../../containers/map/constants";

import {
    getMapAdverts,
    getMapContractors,
    getMapOrders,
    getMapTasks,
} from "../../ducks/bff/map/actionCreators";
import {
    bffMapAdvertsSelector,
    bffMapAdvertsTotalCountSelector,
    bffMapContractorsSelector,
    bffMapContractorsTotalCountSelector,
    bffMapOrdersSelector,
    bffMapOrdersTotalCountSelector,
    bffMapPageDataSelector,
    bffMapProgressListSelector,
    bffMapTasksSelector,
    bffMapTasksTotalCountSelector,
} from "../../ducks/bff/map/selectors";
import {
    bankCheckCancelToken,
    updateFieldsContractorPaymentPossibilityState,
} from "../../ducks/contractorBankCheck";
import {avatarBase64ImagesListSelector} from "../../ducks/fileStore";

import "./style.sass";

function MapObjectsListModal(props) {
    const {
        isOpen,
        onClose,
        className,
        contractorCoordinatesFilter = [],
        orderCoordinatesFilter = [],
        advertCoordinatesFilter = [],
        taskCoordinatesFilter = [],
        onOpenInviteModal = () => {},
    } = props;

    const contractorList = useSelector(bffMapContractorsSelector);
    const contractorsCount = useSelector(bffMapContractorsTotalCountSelector);
    const advertList = useSelector(bffMapAdvertsSelector);
    const advertsCount = useSelector(bffMapAdvertsTotalCountSelector);
    const orderList = useSelector(bffMapOrdersSelector);
    const ordersCount = useSelector(bffMapOrdersTotalCountSelector);
    const taskList = useSelector(bffMapTasksSelector);
    const tasksCount = useSelector(bffMapTasksTotalCountSelector);
    const progressList = useSelector(bffMapProgressListSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const filterData = useSelector(bffMapPageDataSelector);

    const [block, element] = bem("map-objects-list-modal", className);
    const dispatch = useDispatch();
    const checkBankPossibleTimer = useRef(null);

    useEffect(() => {
        const {
            ordersFilters,
            crowdTasksFilters,
            contractorsFilters,
            advertsFilters,
            distanceFilter,
        } = filterData;
        const {
            coordinateLon,
            coordinateLat,
            distance,
            ...otherDistanceFilter
        } = distanceFilter;

        if (contractorCoordinatesFilter.length) {
            dispatch(getMapContractors({
                pageNum: 1,
                pageSize: 1000,
                ...contractorsFilters,
                distanceFilter: {
                    ...otherDistanceFilter,
                    coordinatesFilter: contractorCoordinatesFilter,
                },
            }));
        }

        if (orderCoordinatesFilter.length) {
            dispatch(getMapOrders({
                pageNum: 1,
                pageSize: 1000,
                ...ordersFilters,
                distanceFilter: {
                    ...otherDistanceFilter,
                    coordinatesFilter: orderCoordinatesFilter,
                },
                orderType: MAP_ORDER_SORT_TYPE.WORK_START_DATE_ASC,
            }));
        }

        if (advertCoordinatesFilter.length) {
            dispatch(getMapAdverts({
                pageNum: 1,
                pageSize: 1000,
                ...advertsFilters,
                orderType: MAP_ADVERT_SORT_TYPE.PUBLICATION_DATE_FROM_ASC,
                distanceFilter: {
                    ...otherDistanceFilter,
                    coordinatesFilter: advertCoordinatesFilter,
                },
            }));
        }

        if (taskCoordinatesFilter.length) {
            dispatch(getMapTasks({
                pageNum: 1,
                pageSize: 1000,
                ...crowdTasksFilters,
                distanceFilter: {
                    ...otherDistanceFilter,
                    coordinatesFilter: taskCoordinatesFilter,
                },
                orderType: MAP_TASK_SORT_TYPE.WORK_START_DATE_ASC,
            }));
        }
    }, [
        contractorCoordinatesFilter,
        orderCoordinatesFilter,
        advertCoordinatesFilter,
        taskCoordinatesFilter,
    ]);

    useClearMapModalData({
        isClearUnMount: true,
    });

    useEffect(() => {
        return () => {
            bankCheckCancelToken.cancel();
            clearTimeout(checkBankPossibleTimer.current);
            dispatch(updateFieldsContractorPaymentPossibilityState({
                contractorsPossibility: [],
            }));
        };
    }, []);

    const getTitle = () => {
        if (
            contractorCoordinatesFilter.length
            && !advertCoordinatesFilter.length
            && !orderCoordinatesFilter.length
            && !taskCoordinatesFilter.length
        ) {
            return "Исполнители";
        }

        if (
            orderCoordinatesFilter.length
            && !advertCoordinatesFilter.length
            && !contractorCoordinatesFilter.length
            && !taskCoordinatesFilter.length
        ) {
            return "Заказы";
        }

        if (
            taskCoordinatesFilter.length
            && !advertCoordinatesFilter.length
            && !contractorCoordinatesFilter.length
            && !orderCoordinatesFilter.length
        ) {
            return "Задания";
        }

        if (
            advertCoordinatesFilter.length
            && !orderCoordinatesFilter.length
            && !contractorCoordinatesFilter.length
            && !taskCoordinatesFilter.length
        ) {
            return "Объявления";
        }

        return "Объекты";
    };

    const renderContractorList = () => {
        if (!contractorCoordinatesFilter.length || !contractorList.length || progressList) {
            return null;
        }

        return (
            <>
                {
                    (
                        Boolean(advertCoordinatesFilter.length)
                        || Boolean(orderCoordinatesFilter.length)
                        || Boolean(taskCoordinatesFilter.length)
                    ) &&
                    <NmTitle
                        className={element("list-title")}
                        size="lg"
                    >
                        Исполнители
                    </NmTitle>
                }
                <CheckboxList
                    rows={
                        contractorList.map(item => {
                            return {
                                ...item,
                                key: item.contractorId,
                                avatar: <Avatar
                                    addedToMyClientGroup={item.addedToMyClientGroup}
                                    contractorId={item.contractorId}
                                    base64={userAvatarDict[item.contractorId]}
                                />,
                                contentRow: <MapContractorListCard
                                    contractor={item}
                                    isModalCard={true}
                                    isShowInviteButton={true}
                                    onOpenInviteModal={onOpenInviteModal}
                                />,
                            };
                        })
                    }
                />
            </>
        );
    };

    const renderOrderList = () => {
        if (!orderCoordinatesFilter.length || !orderList.length) {
            return null;
        }

        return (
            <>
                {
                    (
                        Boolean(contractorCoordinatesFilter.length)
                        || Boolean(taskCoordinatesFilter.length)
                        || Boolean(advertCoordinatesFilter.length)
                    ) &&
                    <NmTitle
                        className={element("list-title")}
                        size="lg"
                    >
                        Заказы
                    </NmTitle>
                }
                <CheckboxList
                    rows={
                        orderList.map(item => {
                            return {
                                ...item,
                                key: item.orderId,
                                contentRow: <MapOrderListCard
                                    order={item}
                                    isModalCard={true}
                                    isShowInviteButton={true}
                                    onOpenInviteModal={onOpenInviteModal}
                                />,
                            };
                        })
                    }
                />
            </>
        );
    };

    const renderTaskList = () => {
        if (!taskCoordinatesFilter.length || !taskList.length) {
            return null;
        }

        return (
            <>
                {
                    (
                        Boolean(contractorCoordinatesFilter.length)
                        || Boolean(orderCoordinatesFilter.length)
                        || Boolean(advertCoordinatesFilter.length)
                    ) &&
                    <NmTitle
                        className={element("list-title")}
                        size="lg"
                    >
                        Задания
                    </NmTitle>
                }
                <CheckboxList
                    rows={
                        taskList.map(item => {
                            return {
                                ...item,
                                key: item.taskId,
                                contentRow: <MapTaskListCard
                                    task={item}
                                    isModalCard={true}
                                    isShowInviteButton={true}
                                    onOpenInviteModal={onOpenInviteModal}
                                />,
                            };
                        })
                    }
                />
            </>
        );
    };

    function renderAdvertisementList() {
        if (!advertCoordinatesFilter.length || !advertList.length) {
            return null;
        }

        return (
            <>
                {
                    (
                        Boolean(contractorCoordinatesFilter.length)
                        || Boolean(taskCoordinatesFilter.length)
                        || Boolean(orderCoordinatesFilter.length)
                    ) &&
                    <NmTitle
                        className={element("list-title")}
                        size="lg"
                    >
                        Объявления
                    </NmTitle>
                }
                <CheckboxList
                    rows={
                        advertList.map(item => {
                            return {
                                ...item,
                                key: item.advertId,
                                contentRow: <MapAdvertListCard
                                    advert={item}
                                    isModalCard={true}
                                />,
                            };
                        })
                    }
                />
            </>
        );
    }

    const getTitleCount = () => {
        if (
            contractorCoordinatesFilter.length
            && !advertCoordinatesFilter.length
            && !orderCoordinatesFilter.length
            && !taskCoordinatesFilter.length
        ) {
            return contractorsCount;
        }

        if (
            orderCoordinatesFilter.length
            && !advertCoordinatesFilter.length
            && !contractorCoordinatesFilter.length
            && !taskCoordinatesFilter.length
        ) {
            return ordersCount;
        }

        if (
            taskCoordinatesFilter.length
            && !advertCoordinatesFilter.length
            && !contractorCoordinatesFilter.length
            && !orderCoordinatesFilter.length
        ) {
            return tasksCount;
        }

        if (
            advertCoordinatesFilter.length
            && !orderCoordinatesFilter.length
            && !contractorCoordinatesFilter.length
            && !taskCoordinatesFilter.length
        ) {
            return advertsCount;
        }

        return ordersCount + contractorsCount + advertsCount + tasksCount;
    };

    return (
        isOpen &&
        <div className={block()}>
            <div className={element("body")}>
                <div className={element("title")}>
                    <NmTitle
                        size="md"
                        count={getTitleCount()}
                    >
                        {getTitle()}
                    </NmTitle>
                    <CloseIcon
                        className={element("hide")}
                        onClick={onClose}
                    >
                        Свернуть
                    </CloseIcon>
                </div>
                {
                    progressList &&
                    <Dimmer
                        active
                        inverted
                    >
                        <Loader />
                    </Dimmer>
                }
                <div className={element("list")}>
                    {renderContractorList()}
                    {renderOrderList()}
                    {renderTaskList()}
                    {renderAdvertisementList()}
                </div>
            </div>
        </div>
    );
};

export default MapObjectsListModal;