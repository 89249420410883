import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useCreateOrUpdateWorkExperience, {defaultWorkExperienceForm} from "./hooks/useCreateOrUpdateWorkExperience";

import {regClientName} from "../../../../../../constants/validationRules";

export default function ContractorWorkExperienceEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultWorkExperienceForm},
    } = props;
    const {contractorId} = props;

    const {t} = useTranslation();
    const {
        onChangeForm,
        submit,
        form,
        setForm,
        isShowConfirm,
        confirmData,
        handleCloseConfirm,
        error,
    } = useCreateOrUpdateWorkExperience({contractorId, onClose, getProfile});

    const {onConfirm, confirmContent} = confirmData;

    useEffect(() => {
        setForm({...editData});
    }, [editData.workExperienceId]);

    const {
        employerName,
        workExperience,
        startWorkDate,
        endWorkDate,
        position,
        responsibilities,
    } = form;

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            <NmModal
                footer={
                    <ApplyButtons
                        submitBtnContent={t("button.save")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={onClose}
                        submit={submit}
                        additionalBtnContent={!workExperience && editData.workExperienceId ? "Удалить" : null}
                        additionalBtnColor={!workExperience ? "red" : null}
                    />
                }
                header={
                    <NmTitle size="lg">
                        Опыт работы
                    </NmTitle>
                }
                onClose={onClose}
            >
                <NmForm
                    addMargin
                >
                    <NmCheckboxV2
                        label="Опыт работы отсутствует"
                        checked={workExperience}
                        onChange={onChangeForm}
                        name="workExperience"
                    />
                    {!workExperience && <>
                        <NmInputV2
                            required
                            size="lg"
                            label="Название организации"
                            pattern={regClientName}
                            value={employerName}
                            name="employerName"
                            onChange={onChangeForm}
                            error={error.employerName}
                        />
                        <NmDateRangePickerV2
                            error={error.startWorkDate || error.endWorkDate}
                            required
                            label="Период работы"
                            onChange={onChangeForm}
                            isClearable
                            size="lg"
                            isCurrentDateMax
                            dateFormat="dd.MM.yy"
                            startFieldName="startWorkDate"
                            endFieldName="endWorkDate"
                            value={{
                                startWorkDate,
                                endWorkDate,
                            }}
                        />
                        <NmInputV2
                            required
                            size="lg"
                            label="Должность"
                            value={position}
                            name="position"
                            onChange={onChangeForm}
                            error={error.position}
                        />
                        <NmTextareaV2
                            label="Обязанности"
                            minRows={3}
                            maxRows={7}
                            value={responsibilities}
                            name="responsibilities"
                            onChange={onChangeForm}
                            maxLength="255"
                            error={error.responsibilities}
                        />
                    </>}
                </NmForm>
            </NmModal>
        </>
    );
}