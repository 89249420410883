import {ls, USER_ROLE} from "./localstorage";

import {CLIENT_WORK_TYPE} from "../constants/clientSettings";
import {CLIENT_ADMIN, isUserFromNm} from "../constants/roles";

/**
 * для отображения вкладок в зависимости от режима работы компании
 * @param clientWorkType
 * @returns {boolean|*}
 */
export const isVisibleClientWorkTypeTabs = (clientWorkType) => {
    const role = ls(USER_ROLE);
    const isAdminRole = isUserFromNm(role);

    return [
        CLIENT_WORK_TYPE.NAIMIX_KEDO,
        CLIENT_WORK_TYPE.ONLY_KEDO,
    ].includes(clientWorkType)
        && [
            CLIENT_ADMIN,
        ].includes(role)
        || isAdminRole;
};