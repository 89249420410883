import React, {useContext} from "react";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../ActualComponents/DropzoneV2";
import NmModal from "../../../../ActualComponents/NmModal";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

export const RecruitmentCandidateFilesEdit = (props) => {
    const {
        onClose,
    } = props;

    const {
        uploadFile,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        handleSubmit,
        values,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues: {
            files: [],
        },
    });

    function onSubmit({files}) {
        const formData = new FormData();

        formData.append("file", files[0]);

        uploadFile({
            formData,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    }

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    Прикрепленные файлы
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile={true}
                    disabled={progressAction || isEmpty(values.files)}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <DropzoneV2
                files={values.files}
                multiple={false}
                maxSize={25}
                accept=".jpg, .png, .docx, .doc, .pdf, .rtf"
                format="*.jpg, *.png, *.docx, *.doc, *.pdf, *.rtf"
                onChange={(files) => {
                    setFieldValue("files", files);
                }}
            />
        </NmModal>
    );
};
