import React, {Component} from "react";
import ReactDOM from "react-dom";
import {isEmpty} from "lodash";

import {
    Icon,
    Input,
} from "semantic-ui-react";

import {isNullOrWhitespace} from "../../utils/stringHelper";

import PropTypes from "prop-types";

import "./style.sass";

class MultiSelect extends Component {
    static propTypes = {
        options: PropTypes.array,
        label: PropTypes.string,
        className: PropTypes.string,
        classNameLabel: PropTypes.string,
        classNameContent: PropTypes.string,
        classNameText: PropTypes.string,
        classNameInfo: PropTypes.string,
        classNameOption: PropTypes.string,
        classNameContainer: PropTypes.string,
        value: PropTypes.array,
        onChange: PropTypes.func,
        name: PropTypes.string,
        readOnly: PropTypes.bool,
        topSelected: PropTypes.bool,
        search: PropTypes.bool,
        isShowContent: PropTypes.bool,
        placeholder: PropTypes.string,
        widthReadonly: PropTypes.number,
        isOpenByHover: PropTypes.bool,
        isShowTitle: PropTypes.bool,
        wrap: PropTypes.bool,
        onSearchChange: PropTypes.func,
        isSearchClear: PropTypes.bool,
    };

    static defaultProps = {
        classNameText: "",
        options: [],
        label: "",
        className: "",
        classNameLabel: "",
        classNameContent: "",
        classNameOption: "",
        classNameInfo: "",
        classNameContainer: "",
        value: [],
        onChange: () => {
        },
        name: "dropdown",
        search: false,
        isShowContent: true,
        placeholder: "",
        isOpenByHover: false,
        isShowTitle: true,
        wrap: false,
        onSearchChange: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            openDropdown: false,
            selectedValues: [],
            text: "",
            dropdownSearch: "",
        };
    }

    componentDidMount() {
        const {value: selectedValues} = this.props;

        if (selectedValues.length) {
            this.setState({
                selectedValues,
            });
        }

        document.addEventListener("click", this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSearchClear !== this.props.isSearchClear) {
            this.setState({
                dropdownSearch: "",
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {value, options} = props;
        const {value: oldValue} = state;

        if (JSON.stringify(value) !== JSON.stringify(oldValue) && options.length) {

            const text = options
                .filter(item => value.includes(item.value))
                .map(item => item.text)
                .join(", ");

            return {
                ...state,
                selectedValues: value,
                value,
                text,
            };
        }

        return null;
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, false);
    }

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);

        // Проверяем, что элемент присутствует в переменной,
        // а так же, является ли "domNode" узел потомком "event.target" узла.
        // Если не является, то скрываем элемент.
        if ((!domNode || !domNode.contains(event.target))) {
            this.setState({
                openDropdown : false,
            });
        }
    };

    select = (value) => {
        return () => {
            const {selectedValues} = this.state;
            const {options, onChange, name} = this.props;

            const newSelectedValues = [...selectedValues];

            const idx = newSelectedValues.indexOf(value);

            if (idx !== -1) {
                newSelectedValues.splice(idx, 1);
            } else {
                newSelectedValues.push(value);
            }
            const text = options.filter(item => newSelectedValues.includes(item.value))
                .map(item => item.text)
                .join(", ");

            this.setState({
                selectedValues: newSelectedValues,
                text,
            });

            onChange(null, {name, value: newSelectedValues});
        };
    };

    handleBlur = () => {
        const {search} = this.props;
        if (search) {
            return;
        }
        this.setState({openDropdown: false});
    };

    renderReadOnlyOptions = () => {
        const {selectedValues} = this.state;
        const {
            options,
            classNameOption,
        } = this.props;

        return options
            .filter(({value}) => selectedValues.includes(value))
            .map(({value, text}) => (
                <div
                    key={value}
                    className={`${classNameOption} multiselect__option multiselect__option-empty`}
                >
                    <label />
                    {text}
                </div>
            ));
    };

    onChange = (e, {value, name}) => {
        const {onSearchChange} = this.props;

        this.setState({
            [name]: value,
        });

        onSearchChange(e, {searchQuery: value});
    };

    renderSearchBox() {
        const {dropdownSearch} = this.state;
        const {search} = this.props;

        if (search) {
            return (
                <div className="multiselect__search">
                    <Input
                        icon="search"
                        iconPosition="left"
                        name="dropdownSearch"
                        value={dropdownSearch}
                        onChange={this.onChange}
                    />
                </div>
            );
        }
        return null;
    }

    renderSelectedItems = () => {
        const {selectedValues} = this.state;
        const {
            options,
        } = this.props;

        return options.filter(item => selectedValues.includes(item.value)).map(item => {
            return (
                <div
                    key={item.value}
                    className="multiselect__value"
                >
                    {item.text}
                    <Icon
                        name="close"
                        onClick={this.select(item.value)}
                    />
                </div>
            );
        });
    };

    renderTopSelectedOptions = () => {
        const {
            search,
        } = this.props;


        if (search) {
            return (
                <div className={"multiselect__values"}>
                    {this.renderSelectedItems()}
                </div>
            );
        }
        return null;
    };

    renderDropDownOptions = () => {
        const {selectedValues, dropdownSearch} = this.state;
        const {
            options,
            readOnly,
            search,
            classNameOption,
        } = this.props;

        if (readOnly) {
            return this.renderReadOnlyOptions();
        }

        return options.map(item => {
            if (search && !isNullOrWhitespace(dropdownSearch)) {
                if (item.text.toLowerCase().includes(dropdownSearch.toLowerCase())) {
                    return (
                        <div
                            key={item.value}
                            className={`multiselect__option ${classNameOption} ${selectedValues.includes(item.value) ? "multiselect__option-selected" : ""}`}
                            onClick={this.select(item.value)}
                        >
                            <label />
                            {item.text}
                        </div>
                    );
                }
                return null;
            }
            return (
                <div
                    key={item.value}
                    className={`multiselect__option ${selectedValues.includes(item.value) ? "multiselect__option-selected" : ""}`}
                    onClick={this.select(item.value)}
                >
                    <label />
                    {item.text}
                </div>
            );
        });
    };

    handleDropdownClick = () => {
        const {openDropdown} = this.state;

        this.setState({
            openDropdown: !openDropdown,
        });
    };

    handleMouseEnter = () => {
        const {isOpenByHover, value} = this.props;

        if (!isOpenByHover || isEmpty(value)) {
            return;
        }

        const {openDropdown} = this.state;

        if (openDropdown) {
            return;
        }

        this.setState({
            openDropdown: true,
        });
    };

    handleMouseLeave = () => {
        const {isOpenByHover} = this.props;

        if (!isOpenByHover) {
            return;
        }

        const {openDropdown} = this.state;

        if (!openDropdown) {
            return;
        }

        this.setState({
            openDropdown: false,
        });
    };

    renderDropdown = () => {
        const {openDropdown} = this.state;
        const {readOnly, widthReadonly} = this.props;

        if (openDropdown) {
            return (
                <div
                    style={{width: widthReadonly}}
                    className={`multiselect__dropdown ${readOnly ? "multiselect__dropdown_readonly" : ""}`}
                >
                    {this.renderTopSelectedOptions()}
                    {this.renderSearchBox()}
                    <div className="multiselect__options">
                        {this.renderDropDownOptions()}
                    </div>
                </div>
            );
        }
        return null;
    };

    renderText = (text) => {
        const {placeholder} = this.props;

        if (isNullOrWhitespace(text)) {
            return placeholder;
        }

        return text;
    };

    renderBaseElement = () => {
        const {
            text,
            selectedValues,
        } = this.state;

        const {
            label,
            classNameLabel,
            classNameContent,
            classNameText,
            classNameInfo,
            classNameContainer,
            readOnly,
            isShowContent,
            isShowTitle,
            wrap,
            infoCount,
        } = this.props;

        return (
            <>
                {label ? <label className={`multiselect__label ${classNameLabel}`}>
                    {label}
                </label> : null}
                <div
                    className={`multiselect__container ${readOnly ? "multiselect__container_readonly" : ""} ${classNameContainer}`}
                    title={isShowTitle ? text : undefined}
                >
                    <div
                        className={`multiselect__content ${classNameText}`}
                        onClick={this.handleDropdownClick}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <div className={wrap ? "multiselect__text multiselect__text-wrap" : `multiselect__text ${classNameContent}`}>
                            {isShowContent ? this.renderText(text) : null}
                        </div>
                        {readOnly ?
                            <div className={`info ${classNameInfo}`}>
                                {
                                    infoCount ?
                                        infoCount :
                                        selectedValues && selectedValues.length
                                }
                            </div>
                            :
                            <i
                                aria-hidden="true"
                                className="dropdown icon"
                            />
                        }
                    </div>
                </div>
            </>
        );
    };

    render() {
        const {
            className,
            value,
        } = this.props;

        const isNotEmpty = value.length > 0;

        return (
            <div
                className={`field multiselect ${isNotEmpty ? "multiselect--not-empty" : ""} ${className}`}
                onBlur={this.handleBlur}
                tabIndex="0"
                ref={c => this.dropDownList = c}
            >
                <div className="multiselect__element">
                    {this.renderBaseElement()}
                    {this.renderDropdown()}
                </div>
            </div>
        );
    }
}

export default MultiSelect;
