import React, {useEffect, useRef, useState} from "react";
import {isEmpty} from "lodash";

import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import Tree from "../ActualComponents/Tree";
import ApplyButtons from "../ApplyButtons";
import NmButton from "../NmButton";
import NmLoader from "../NmLoader";
import NmTitle from "../NmTitle";
import {Loader} from "semantic-ui-react";

import {useDebounceFetch} from "../../hooks/useDebounceFetch";
import {useGetPrevValue} from "../../hooks/useGetPrevValue";
import {useModal} from "../../hooks/useModal";
import {useHidingOrderError} from "./hooks/useError";
import {useHidingProjectsObjectsFetch} from "./hooks/useFetch";

import {isLastElementInArray} from "../../utils/objectHelper";
import {getClientPropertiesBoolParam} from "./utils/getClientPropertiesBoolParam";
import {getHidingProjectsObjectsNodes} from "./utils/getNodes";
import {getHidingProjectsObjectRequestData} from "./utils/getRequestData";
import {getHidingProjectsObjectsSearchNodes} from "./utils/getSearchNodes";
import {isCheckedFetchedObject} from "./utils/isCheckedFetchedObject";
import {isPartiallyCheckedClient} from "./utils/isPartiallyCheckedClient";
import {isPartiallyCheckedProject} from "./utils/isPartiallyCheckedProject";

import {COLOR} from "../../constants/color";
import {HIDING_PROJECT_OBJECT_TYPE} from "./constants";

export const ProjectsObjectsCheckboxList = (props) => {
    const {
        title,
        onClose,
        clientId,
        isVisibleClient = true,
        updateObjectsCallback,
        objects,
        projects,
        progressAction,
        clientProperties,
        projectsAndObjects,
        progressSearch,
        clearNodesFromStore,
        fetchObjects,
        onSubmitForm,
        fetchProjectsAndObjects,
        fetchProjects,
        buttonName = "Сохранить",
        suggestion,
        isModal = true,
        isCheckbox = true,
        isForm = true,
        submitConfirmContent,
        isClientPropertiesBoolParam,
        formId,
        checkboxLabel,
        settingsName,
    } = props;

    const {
        error,
        clearError,
    } = useHidingOrderError({
        error: props.error,
    });

    const [clientPropertiesBoolParam, setClientPropertiesBoolParam] = useState(() => {
        if (!isVisibleClient) {
            return false;
        }

        return getClientPropertiesBoolParam(clientProperties[settingsName]);
    });

    const [_nodes, setNodes] = useState(props.nodes ? props.nodes : []);

    const $input = useRef(null);

    const isAnyFieldChecked = useRef(false);

    // const {
    //     //checked,
    //     //isEmptyChecked,
    //     setCheckedClient,
    //     setCheckedProjects,
    //     setCheckedObjects,
    //     setCheckedObjectsList,
    //     setCheckedProjectsList,
    //     clearChecked,
    // } = useHidingProjectsObjectsCheckedRecording();

    const isEmptyProjects = isEmpty(projects.list);
    const isEmptyNodes = !_nodes || !_nodes[0]?.value;

    useEffect(() => {
        return () => {
            clearNodesFromStore();
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(clientProperties)) {
            const value = getClientPropertiesBoolParam(clientProperties[settingsName]);

            setClientPropertiesBoolParam(value);
        }
    }, [clientProperties]);

    const {
        setValueFilter: setProjectObjectNameFilter,
        valueFilter: projectObjectNameFilter,
    } = useDebounceFetch({
        fetchCallback: (projectObjectNameFilter) => {
            if (!projectObjectNameFilter) {
                return;
            }

            if (!isEmpty(objects.lists)) {
                updateObjectsCallback();
            }

            fetchProjectsAndObjects(projectObjectNameFilter);
        },
        minSearchLength: 1,
    });

    const _projectObjectNameFilter = useGetPrevValue(projectObjectNameFilter);
    const isSearch = Boolean(projectObjectNameFilter);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        showProjects,
        getProjects,
        setProjectsPageNum,
    } = useHidingProjectsObjectsFetch({
        clientId,
        setNodes,
        onChange: props.onChange,
        nodes: _nodes,
        isVisibleClient,
        fetchProjects,
    });

    useEffect(() => {
        const isInputHasBeenEnteredValue = Boolean(!_projectObjectNameFilter && projectObjectNameFilter);

        if (isInputHasBeenEnteredValue) {
            setProjectsPageNum(1);

            return;
        }

        const isInputHasBeenCleared = Boolean(_projectObjectNameFilter && !projectObjectNameFilter);

        if (isInputHasBeenCleared) {
            clearNodesFromStore();
            setNodes([]);

            if (props.onChange) {
                props.onChange([]);
            }

            getProjects({
                isSearchCleared: true,
                pageNum: 1,
            });
        }
    }, [projectObjectNameFilter]);

    useEffect(() => {
        if (!isEmpty(projectsAndObjects) && isSearch) {
            updateSearchNodes();
        }
    }, [projectsAndObjects]);

    useEffect(() => {
        const isEmptyProjects = isEmpty(projects.list);

        if (isEmptyNodes && !isEmptyProjects && !isSearch) {
            updateNodes();
        }
    }, [
        projects,
        objects,
        projectsAndObjects,
    ]);

    const updateSearchNodes = () => {
        const _nodes = getHidingProjectsObjectsSearchNodes({
            projectsAndObjects,
            isVisibleClient,
        });

        setNodes(_nodes);

        if (props.onChange) {
            props.onChange(_nodes);
        }
    };

    const updateNodes = (isFirstChecked) => {
        const _nodes = getHidingProjectsObjectsNodes({
            projects,
            isFirstChecked,
            isVisibleClient,
        });

        setNodes(_nodes);

        if (props.onChange) {
            props.onChange(_nodes);
        }
    };

    const getObjects = ({projectId, pageNum, index}) => {
        fetchObjects({
            projectId,
            pageNum,
            pageSize: 10,
            onSuccess: ({results}) => {
                const project = isVisibleClient
                    ? _nodes[0].children[index]
                    : _nodes[index];

                const res = results ? results.map(item => {
                    return {
                        label: item.objectName,
                        // checked: project.partiallyChecked ? item.boolParam : project.checked,
                        checked: isCheckedFetchedObject({
                            isVisibleClient,
                            client: _nodes[0],
                            project,
                            fetchedObject: item,
                        }),
                        value: item.objectId,
                        type: HIDING_PROJECT_OBJECT_TYPE.OBJECT,
                    };
                }) : [];

                project.children = [
                    ...project.children,
                    ...res,
                ];

                setNodes(_nodes);

                if (props.onChange) {
                    props.onChange(_nodes);
                }
            },
        });
    };

    const openSubmitConfirm = ({requestData, onConfirm, onCancel, cancelButton, isVisibleCloseButton}) => {
        onOpenModal({
            isVisibleCloseButton,
            isOpenConfirm: true,
            content: submitConfirmContent,
            mobile: "column",
            isHiddenCancelOnMobile: true,
            confirmButton: "Подтвердить",
            cancelButton: cancelButton || "Отмена",
            onCancel: () => {
                if (onCancel) {
                    onCancel();
                }

                onCloseModal();
            },
            onConfirm: () => {
                if (onConfirm) {
                    onConfirm();

                    return;
                }

                onSubmitForm({
                    requestData,
                    onSuccess: () => {
                        onClose();
                    },
                });
            },
        });
    };

    const onSubmit = (isFormClosing = true) => {
        const requestData = getHidingProjectsObjectRequestData({
            nodes: _nodes,
            clientId,
            clientPropertiesBoolParam,
            isVisibleClient,
        });

        if (submitConfirmContent) {
            openSubmitConfirm({requestData});

            return;
        }

        onSubmitForm({
            requestData,
            onSuccess: () => {
                if (isFormClosing) {
                    onClose();
                } else {
                    onCloseModal();
                }
            },
        });
    };

    const onChangeFilter = (_event, {value}) => {
        if (isAnyFieldChecked.current && !modalData?.isOpenConfirm) {
            $input.current.blur();

            onOpenModal({
                isOpenConfirm: true,
                warning: true,
                content: "Вы изменили список выбранных проектов и объектов. При поиске по названию измененный выбор проектов и объектов не сохранится",
                isVisibleCloseButton: false,
                onCancel:() => {
                    initSearch(value);
                    onCloseModal();
                },
                onConfirm: () => {
                    if (submitConfirmContent) {
                        openSubmitConfirm({
                            onConfirm: () => {
                                initSearch(value);
                                onSubmit(false);
                            },
                            onCancel: () => {
                                initSearch(value);
                                onCloseModal();
                            },
                            cancelButton: "Продолжить без сохранения",
                            isVisibleCloseButton: false,
                        });

                        return;
                    }

                    initSearch(value);
                    onSubmit(false);
                },
                isNeedClosing: false,
                confirmButton: "Сохранить данные",
                cancelButton: "Продолжить без сохранения",
            });

            return;
        }

        setProjectObjectNameFilter(value);
    };

    const onClear = () => {
        isAnyFieldChecked.current = false;
        setProjectObjectNameFilter("");
    };

    const onChange = (event, data) => {
        isAnyFieldChecked.current = true;

        if (data.item.type === HIDING_PROJECT_OBJECT_TYPE.CLIENT) {
            const nodes = [..._nodes];

            const checked = nodes[0].partiallyChecked ? true : data.checked;

            // setCheckedClient({
            //     isClientChecked: checked,
            //     isClientPartiallyChecked: false,
            // });

            nodes[0] = {
                ...nodes[0],
                partiallyChecked: false,
                checked,
                changedCheckedValue: checked,
                changed: true,
                children: nodes[0].children?.map(item => {
                    if (item.children) {
                        const objects = item.children.map(object => {
                            return {
                                ...object,
                                partiallyChecked: false,
                                changed: true,
                                checked,
                            };
                        });

                        // setCheckedObjectsList({
                        //     list: objects,
                        //     checked,
                        // });

                        return {
                            ...item,
                            partiallyChecked: false,
                            checked,
                            changed: true,
                            children: objects,
                        };
                    }

                    return {
                        ...item,
                        partiallyChecked: false,
                        changed: true,
                        checked,
                    };
                }) || [],
            };

            // setCheckedProjectsList({
            //     list: _nodes[0].children,
            //     checked,
            // });

            setNodes(nodes);

            if (props.onChange) {
                props.onChange(nodes);
            }
        }

        if (data.item.type === HIDING_PROJECT_OBJECT_TYPE.PROJECT) {
            onChangeProjectCheckbox(data);
        }

        if (data.item.type === HIDING_PROJECT_OBJECT_TYPE.OBJECT) {
            onChangeObjectCheckbox(data);
        }

        if (error) {
            clearError();
        }
    };

    const onChangeProjectCheckbox = (data) => {
        const nodes = [..._nodes];

        if (isVisibleClient) {
            const [client] = nodes;

            const {
                children: projects,
            } = client;

            if (!projects) {
                return;
            }

            const _projects = [...projects];
            const project = _projects[data.index];
            const objects = project.children;

            const checked = _projects[data.index].partiallyChecked ? true : data.checked;

            _projects[data.index].checked = checked;
            _projects[data.index].changed = true;
            _projects[data.index].partiallyChecked = false;
            _projects[data.index].changedCheckedValue = checked;

            // setCheckedProjects({
            //     ...data,
            //     checked,
            //     item: _projects[data.index],
            // });

            updateClientCheckboxByOthers({
                client,
                projects: _projects,
                nextAllPagesSelectionType: isSearch ?
                    client.nextAllPagesSelectionType :
                    props.projects.clientInfo.nextAllPagesSelectionType,
                checked: data.checked,
            });

            if (!isEmpty(objects)) {
                _projects[data.index].children = objects?.map(item => {
                    return {
                        ...item,
                        checked,
                        changed: true,
                    };
                });

                // setCheckedObjectsList({
                //     list: _projects[data.index].children,
                //     checked,
                // });
            }

            nodes[0].children = _projects;

            setNodes(nodes);

            if (props.onChange) {
                props.onChange(nodes);
            }

            return;
        }

        const project = nodes[data.index];
        const objects = project.children;

        const checked = nodes[data.index].partiallyChecked ? true : data.checked;

        nodes[data.index].checked = checked;
        nodes[data.index].changed = true;
        nodes[data.index].partiallyChecked = false;
        nodes[data.index].changedCheckedValue = checked;

        // setCheckedProjects({
        //     ...data,
        //     checked,
        //     item: _projects[data.index],
        // });

        if (!isEmpty(objects)) {
            nodes[data.index].children = objects?.map(item => {
                return {
                    ...item,
                    checked,
                    changed: true,
                };
            });

            // setCheckedObjectsList({
            //     list: _projects[data.index].children,
            //     checked,
            // });
        }

        setNodes(nodes);

        if (props.onChange) {
            props.onChange(nodes);
        }
    };

    const onChangeObjectCheckbox = (data) => {
        const nodes = [..._nodes];

        if (isVisibleClient) {
            const [client] = nodes;
            const _projects = client.children;

            if (!_projects) {
                return;
            }

            const project = _projects[data.parent.index];

            if (
                !project.children ||
                !client.children ||
                !project.children[data.index] ||
                typeof data.parent.index !== "number"
            ) {
                return;
            }

            project.children[data.index] = {
                ...project.children[data.index],
                checked: data.checked,
                changed: true,
            };

            // setCheckedObjects({
            //     checked: data.checked,
            //     ...data,
            //     item: project.children[data.index],
            // });

            updateProjectCheckboxByOthers({
                project,
                data,
            });

            client.children[data.parent.index].children = project.children;

            updateClientCheckboxByOthers({
                client,
                projects: client.children,
                nextAllPagesSelectionType: isSearch ?
                    client.nextAllPagesSelectionType :
                    projects.clientInfo.nextAllPagesSelectionType,
                checked: data.checked,
            });

            setNodes(nodes);

            if (props.onChange) {
                props.onChange(nodes);
            }

            return;
        }

        const _projects = nodes;

        if (!_projects) {
            return;
        }

        const project = _projects[data.parent.index];

        if (
            !project.children ||
            !project.children[data.index] ||
            typeof data.parent.index !== "number"
        ) {
            return;
        }

        project.children[data.index] = {
            ...project.children[data.index],
            checked: data.checked,
            changed: true,
        };

        // setCheckedObjects({
        //     checked: data.checked,
        //     ...data,
        //     item: project.children[data.index],
        // });

        updateProjectCheckboxByOthers({
            project,
            data,
        });

        setNodes(nodes);

        if (props.onChange) {
            props.onChange(nodes);
        }
    };

    const updateProjectCheckboxByOthers = (params) => {
        const {
            project,
            data,
        } = params;

        if (
            isPartiallyCheckedProject({
                project,
                noSearchProject: objects.lists[data.parent.value],
                isSearch,
                data,
            })
        ) {
            project.checked = false;
            project.changed = true;
            project.partiallyChecked = true;

            // setCheckedProjects({
            //     ...data,
            //     checked: false,
            //     item: project,
            // });

            return;
        }

        project.checked = data.checked;
        project.changed = true;
        project.partiallyChecked = false;
        project.changedCheckedValue = null;

        // setCheckedProjects({
        //     ...data,
        //     checked: data.checked,
        //     item: project,
        // });
    };

    const updateClientCheckboxByOthers = (params) => {
        const {
            client,
            projects,
            nextAllPagesSelectionType,
            checked,
        } = params;

        const _isPartiallyCheckedClient = isPartiallyCheckedClient({
            client,
            projects,
            nextAllPagesSelectionType,
            checked,
        });

        if (_isPartiallyCheckedClient) {
            client.checked = false;
            client.partiallyChecked = true;

            // setCheckedClient({
            //     isClientChecked: false,
            //     isClientPartiallyChecked: true,
            // });

            return;
        }

        client.checked = checked;
        client.partiallyChecked = false;
        client.changedCheckedValue = null;

        // setCheckedClient({
        //     isClientChecked: checked,
        //     isClientPartiallyChecked: false,
        // });
    };

    const onClickChevron = (params) => {
        const {
            item,
        } = params;

        if (
            item.type === HIDING_PROJECT_OBJECT_TYPE.PROJECT &&
            !objects.lists[item.value]?.pageData &&
            !isSearch
        ) {
            getObjects({
                projectId: item.value,
                pageNum: 1,
                ...params,
            });
        }
    };

    const getLoader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center fluid">
                <Loader
                    size="small"
                    active={true}
                    inline={true}
                />
            </div>
        );
    };

    const getShowMoreProjectsButton = (index) => {
        const isShowMoreButton = projects.totalPages > 0
            && index === projects.list.length - 1
            && projects.pageData.pageNum !== projects.totalPages;

        if (
            projects.totalPages > 0
            && index === projects.list.length - 1
            && projects.progress
        ) {
            return getLoader();
        }

        if (isShowMoreButton) {
            return (
                <Text
                    style={{minHeight: 24}}
                    className="d-flex align-items-center justify-content-center fluid"
                    isCursorPointer={true}
                    level="2"
                    color={COLOR.PASSIVE_100}
                    onClick={showProjects}
                >
                    Показать еще проекты
                </Text>
            );
        }
    };

    const getProjectLevelChildren = (nodeProps, open) => {
        const {value, index} = nodeProps;

        const isLoadingObjects = objects.lists[nodeProps.value]?.isLoading;

        if (
            isLoadingObjects &&
            isEmpty(objects.lists[nodeProps.value]?.list)
        ) {
            return getLoader();
        }

        const totalCount = objects.lists[value]?.totalCount;

        if (
            open &&
            projects.totalPages > 0
            && totalCount === 0
        ) {
            return (
                <>
                    <div
                        style={{minHeight: 24}}
                        className="d-flex align-items-center justify-content-center fluid"
                    >
                        <Text level="2">
                            Нет объектов
                        </Text>
                    </div>
                    {getShowMoreProjectsButton(index)}
                </>
            );
        }

        return getShowMoreProjectsButton(index);
    };

    const getObjectLevelChildren = (nodeProps) => {
        const {index} = nodeProps;

        const isLoading = objects.lists[nodeProps.parent.value]?.isLoading;

        if (
            isLoading
            && objects.lists[nodeProps.parent.value]?.totalPages > 0
            && isLastElementInArray(objects.lists[nodeProps.parent.value]?.list, index)
        ) {
            return getLoader();
        }

        if (
            !isLoading
            && objects.lists[nodeProps.parent.value]?.totalPages > 0
            && objects.lists[nodeProps.parent.value]?.pageData?.pageNum !== objects.lists[nodeProps.parent.value]?.totalPages
            && isLastElementInArray(objects.lists[nodeProps.parent.value]?.list, index)
        ) {
            return (
                <Text
                    style={{minHeight: 24}}
                    className="d-flex align-items-center justify-content-center fluid"
                    isCursorPointer={true}
                    level="2"
                    color={COLOR.PASSIVE_100}
                    onClick={() => {
                        getObjects({
                            projectId: nodeProps.parent.value,
                            pageNum: objects.lists[nodeProps.parent.value].pageData.pageNum + 1,
                            index: nodeProps.parent.index,
                        });
                    }}
                >
                    Показать еще объекты
                </Text>
            );
        }
    };

    const getChildren = (nodeProps, open) => {
        const {type} = nodeProps;

        if (type === HIDING_PROJECT_OBJECT_TYPE.PROJECT) {
            return getProjectLevelChildren(nodeProps, open);
        }

        if (type === HIDING_PROJECT_OBJECT_TYPE.OBJECT) {
            return getObjectLevelChildren(nodeProps);
        }
    };

    const getError = () => {
        if (!error) {
            return null;
        }

        return (
            <Text
                level="3"
                color={COLOR.NEGATIVE_100}
                className="mb-3 mb-md-4"
            >
                {error.errorMessage}
            </Text>
        );
    };

    // const isChecked = (params) => {
    //     const {type, value: id} = params;
    //
    //     if (type === HIDING_PROJECT_OBJECT_TYPE.CLIENT) {
    //         return typeof checked.isClientChecked === "boolean" ? checked.isClientChecked : undefined;
    //     }
    //
    //     if (type === HIDING_PROJECT_OBJECT_TYPE.PROJECT) {
    //         const isChecked = checked.projects.trueIds.includes(id);
    //
    //         if (isChecked) {
    //             return true;
    //         }
    //
    //         const isFalseChecked = checked.projects.falseIds.includes(id);
    //
    //         if (isFalseChecked) {
    //             return false;
    //         }
    //     }
    //
    //     if (type === HIDING_PROJECT_OBJECT_TYPE.OBJECT) {
    //         const isChecked = checked.objects.trueIds.includes(id);
    //
    //         if (isChecked) {
    //             return true;
    //         }
    //
    //         const isFalseChecked = checked.objects.falseIds.includes(id);
    //
    //         if (isFalseChecked) {
    //             return false;
    //         }
    //     }
    // }

    const initSearch = (value) => {
        isAnyFieldChecked.current = false;
        setProjectObjectNameFilter(value);
    };

    const onChangeClientBoolParam = () => {
        const isFirstChecked = clientPropertiesBoolParam === null &&
            !clientProperties[settingsName];

        if (isFirstChecked) {
            updateNodes(isFirstChecked);
        }

        setProjectObjectNameFilter("");

        setClientPropertiesBoolParam(!clientPropertiesBoolParam);
    };

    const getDisabledTree = () => {
        if (isVisibleClient && isCheckbox) {
            return !clientPropertiesBoolParam;
        }

        return false;
    };

    const getContent = () => {
        if (
            isSearch
            && !isEmpty(projectsAndObjects)
            && !projectsAndObjects.projects
            && !progressSearch
        ) {
            return (
                <Text
                    color={COLOR.SECONDARY_100}
                    level="2"
                >
                    Проекты и объекты не найдены
                </Text>
            );
        }

        if (isEmptyProjects && !projects.progress) {
            return (
                <Text
                    color={COLOR.SECONDARY_100}
                    level="2"
                >
                    У компании нет проектов
                </Text>
            );
        }

        if (isEmptyNodes) {
            return getLoader();
        }

        return (
            <div className="relative">
                {
                    progressSearch &&
                    <NmLoader
                        size="small"
                        inverted={true}
                        active={true}
                        content=""
                    />
                }
                {
                    _nodes && _nodes[0]?.value &&
                    <Tree
                        disabled={getDisabledTree()}
                        nodes={_nodes}
                        onChange={onChange}
                        onClickChevron={onClickChevron}
                        getChildren={getChildren}
                        //isChecked={isChecked}
                        excludeChevronIconType={HIDING_PROJECT_OBJECT_TYPE.OBJECT}
                    />
                }
            </div>
        );
    };

    const getConfirm = () => {
        if (!modalData?.isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                warning={modalData.warning}
                content={modalData.content}
                isVisibleCloseButton={modalData.isVisibleCloseButton}
                onCancel={modalData.onCancel}
                onConfirm={modalData.onConfirm}
                isNeedClosing={modalData.isNeedClosing}
                confirmButton={modalData.confirmButton}
                cancelButton={modalData.cancelButton}
                disabled={progressAction}
            />
        );
    };

    const getFormChildren = () => {
        return (
            <>
                {getConfirm()}
                {
                    isVisibleClient && isCheckbox &&
                    <NmCheckboxV2
                        onChange={onChangeClientBoolParam}
                        label={checkboxLabel}
                        name="clientPropertiesBoolParam"
                        checked={Boolean(clientPropertiesBoolParam)}
                    />
                }
                {getError()}
                <div className="d-flex align-items-md-end flex-column flex-md-row">
                    <NmInputV2
                        ref={$input}
                        size="xl"
                        disabled={getDisabledTree()}
                        label="Проект или объект"
                        placeholder="Введите наименование проекта или объекта"
                        name="projectObjectNameFilter"
                        value={projectObjectNameFilter}
                        onChange={onChangeFilter}
                    />
                    <NmButton
                        size="xl"
                        className="mt-2 mt-md-0 ms-md-4"
                        onClick={onClear}
                        color="grey"
                        type="button"
                    >
                        Очистить
                    </NmButton>
                </div>
                {
                    suggestion &&
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_70}
                    >
                        {suggestion}
                    </Text>
                }
                {getContent()}
            </>
        );
    };

    const getModalChildren = () => {
        if (isForm) {
            const onSubmit = () => {
                const requestData = getHidingProjectsObjectRequestData({
                    nodes: _nodes,
                    clientId,
                    clientPropertiesBoolParam,
                    isVisibleClient,
                    isClientPropertiesBoolParam,
                });

                onSubmitForm(requestData);
            };

            return (
                <NmForm
                    addMargin={true}
                    onSubmit={formId ? onSubmit : undefined}
                    id={formId}
                >
                    {getFormChildren()}
                </NmForm>
            );
        }

        return getFormChildren();
    };

    if (isModal) {
        return (
            <NmModal
                size="md"
                className="rejection-reason-form"
                onClose={onClose}
                footer={
                    <ApplyButtons
                        submitBtnContent={buttonName}
                        cancelBtnContent="Отменить"
                        mobile="column"
                        isHiddenCancelOnMobile
                        onClose={onClose}
                        disabled={progressAction}
                        submit={onSubmit}
                    />
                }
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
            >
                {getModalChildren()}
            </NmModal>
        );
    }

    return getModalChildren();
};
