import {useMemo} from "react";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
} from "../../../../../constants/links";

export const useContractorFinanceIncomeTabs = (props) => {
    const {
        tabsLinks,
        subTabsLinks,
        contractorId,
    } = props;

    const tabs = useMemo(() => {
        return [
            {
                link: tabsLinks.completed.replace(":contractorId", contractorId),
                name: "Завершенные платежи",
                active: [
                    tabsLinks.completed.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS.replace(":contractorId", contractorId),
                ],
            },
            {
                link: tabsLinks.inProcess.replace(":contractorId", contractorId),
                name: "В процессе оплаты",
                active: [
                    tabsLinks.inProcess.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS.replace(":contractorId", contractorId),
                ],
            },
            {
                link: tabsLinks.awaiting.replace(":contractorId", contractorId),
                name: "Оплаты на рассмотрении",
                active: [
                    tabsLinks.awaiting.replace(":contractorId", contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS.replace(":contractorId", contractorId),
                ],
            },
        ];
    }, []);

    const subTabs = useMemo(() => {
        if (!subTabsLinks) {
            return [];
        }

        const orderPaymentsLink = subTabsLinks.orders.replace(":contractorId", contractorId);
        const taskPaymentsLink = subTabsLinks.tasks.replace(":contractorId", contractorId);

        return [
            {
                link: orderPaymentsLink,
                active: orderPaymentsLink,
                name: "Оплаты по заказам",
            },
            {
                link: taskPaymentsLink,
                active: taskPaymentsLink,
                name: "Оплаты по заданиям",
            },
        ];
    }, [subTabsLinks]);

    return {
        tabs,
        subTabs,
    };
};