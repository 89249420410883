import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {addOrdersToGroup} from "../../../../../ducks/orderGroup";

function useAddOrderToGroupAction({clientId, orderGroupId, fetchList, clearSelectedRows}) {
    const dispatch = useDispatch();

    const addOrdersToGroupAction = (orderList) => {
        dispatch(addOrdersToGroup({
            clientId,
            orderGroupId,
            orderList,
            onSuccess: () => {
                toastSuccess(
                    orderList?.length > 1 ? "Заказы успешно добавлены в группу" : "Заказ успешно добавлен в группу",
                );
                fetchList();
                clearSelectedRows();
            },
        }));
    };

    return {
        addOrdersToGroupAction,
    };
}

export default useAddOrderToGroupAction;