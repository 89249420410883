import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import NmButton from "../../../components/NmButton";
import {history} from "../../../store/configureStore";
import {Button, Icon} from "semantic-ui-react";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {phoneFormat} from "../../../utils/stringFormat";
import validate from "../../../utils/validate";

import {SUPPORT_FIELD_NAME} from "../../../constants/clientList";
import {LINK_SETTINGS_TECHSUPPORT_LIST} from "../../../constants/links";
import {ADMIN} from "../../../constants/roles";
import {supportRule} from "../../../constants/validationRules";

import {getClientById, getClientCardSelector} from "../../../ducks/client";
import {
    getCallTypeStatusDictSelector,
    getCardSupportSelector,
    getCaseStatusDictSelector,
    getCaseStatusOptionsSelector,
    getProgressCardSelector,
    getProgressSupportUpdateSelector,
    getSupportByCaseNumber,
    updateFieldContractorStore,
    updateSupport,
} from "../../../ducks/contractor";
import {getOrderById, getOrderCardSelector, updateFieldOrderStore} from "../../../ducks/order";

import {clientType, orderType, supportType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class TechsupportCard extends Component {
    static propTypes = {
        support: supportType,
        caseStatus: PropTypes.object,
        caseStatusOptions: PropTypes.array,
        callTypeStatusDict: PropTypes.object,
        progressSupportUpdate: PropTypes.bool,
        progressCard: PropTypes.bool,
        order: orderType,
        client: clientType,
        getSupportByCaseNumber: PropTypes.func,
        updateSupport: PropTypes.func,
        updateFieldContractorStore: PropTypes.func,
        getOrderById: PropTypes.func,
        getClientById: PropTypes.func,
        updateFieldOrderStore: PropTypes.func,
    };

    static defaultProps = {
        support: {},
        caseStatus: {},
        caseStatusOptions: [],
        callTypeStatusDict: {},
        progressSupportUpdate: false,
        progressCard: false,
        order: {},
        client: {},
        getSupportByCaseNumber: () => {
        },
        updateSupport: () => {
        },
        updateFieldContractorStore: () => {
        },
        getOrderById: () => {
        },
        getClientById: () => {
        },
        updateFieldOrderStore: () => {
        },
    };


    constructor() {
        super();
        this.state = {
            supportCard: {},
            errorForm: {},
        };

        this.role = ls(USER_ROLE);
        this.isEditable = this.role === ADMIN;
    }


    componentDidMount() {
        const {
            match: {params: {caseNumber}},
            getSupportByCaseNumber,
        } = this.props;

        getSupportByCaseNumber({caseNumber});
    }

    static getDerivedStateFromProps(props, state) {
        const {support} = props;
        const {caseNumber} = support;

        const {supportCard} = state;

        if (!supportCard.caseNumber && caseNumber) {
            return {
                ...state,
                supportCard: {...support},
            };
        }

        return state;
    }

    componentDidUpdate(prevProps) {
        const {getOrderById, support, progressCard, getClientById} = this.props;
        const {
            caseNumber,
            orderId,
            clientId,
        } = support;

        const {support: oldSupport} = prevProps;

        if (!progressCard && caseNumber && caseNumber !== oldSupport.caseNumber) {
            getOrderById({
                orderId,
                clientId,
            });
            getClientById(clientId);
        }
    }

    componentWillUnmount() {
        const {
            updateFieldContractorStore,
            updateFieldOrderStore,
        } = this.props;

        updateFieldContractorStore({cardSupport: {}});
        updateFieldOrderStore("card", {});
    }

    save = () => {
        const {updateSupport} = this.props;
        const {supportCard} = this.state;

        if (!this.validationForm()) {
            return;
        }

        updateSupport(supportCard);
    };

    handleOnClickBack = () => {
        history.push(LINK_SETTINGS_TECHSUPPORT_LIST);
    };

    validationForm() {
        const {supportCard} = this.state;

        const errorMessage = validate(supportCard, supportRule);

        this.setState({errorForm: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    handleChangeFieldsSupport = (e, {value, name}) => {
        this.setState(prevState =>
            ({
                ...prevState,
                supportCard: {
                    ...prevState.supportCard,
                    [name]: value,
                },
            }),
        );
    };

    renderHeader = () => {
        const {
            support: {
                lastName,
                firstName,
                patronymic,
                caseNumber,
                phone,
            },
            t,
        } = this.props;

        return (
            <div className="support-card-header">
                <Button
                    className="support-card-window-close"
                    icon
                    onClick={this.handleOnClickBack}
                >
                    <Icon name="close" />
                </Button>
                <div className="support-card_full-name">
                    <span className="support-card_subtitle">
                        {t("techsupport-card.appeal-number")}
                        {" "}
№ 
                        {" "}
                        {caseNumber}
                        {" "}
                    </span>
                    <span className="support-card_subtitle">
                        {lastName} 
                        {" "}
                        {firstName} 
                        {" "}
                        {patronymic || ""}
                        {" "}
                    </span>
                    <span className="support-card_subtitle">
                        {t("techsupport-card.phone-number")}
: 
                        {" "}
                        {phoneFormat(phone)}
                    </span>
                </div>
            </div>
        );
    };

    render() {
        const {
            caseStatusOptions,
            callTypeStatusDict,
            progressSupportUpdate,
            progressCard,
            client: {
                name: clientName,
            },
            t,
        } = this.props;
        const {
            supportCard,
            errorForm,
        } = this.state;

        return (
            <div
                className="support-card"
            >
                {this.renderHeader()}
                <div className="support-card-body row">
                    <div className="col-md-offset-3 col-md-4">
                        <NmForm
                            addMargin
                            loading={progressSupportUpdate || progressCard}
                        >
                            <NmInputV2
                                size="lg"
                                disabled
                                label={t("techsupport-card.heading")}
                                placeholder={t("techsupport-card.heading")}
                                value={supportCard[SUPPORT_FIELD_NAME.TOPIC_OF_APPEAL] || ""}
                                onChange={this.handleChangeFieldsSupport}
                            />
                            <NmInputV2
                                size="lg"
                                disabled
                                label={t("techsupport-card.appeal-type")}
                                placeholder={t("techsupport-card.appeal-type")}
                                value={callTypeStatusDict[supportCard[SUPPORT_FIELD_NAME.TYPE_OF_APPEAL]] || ""}
                            />
                            <NmInputV2
                                size="lg"
                                disabled
                                label={t("techsupport-card.order-number")}
                                placeholder={t("techsupport-card.order-number")}
                                value={`№ ${supportCard[SUPPORT_FIELD_NAME.ORDER_NUM]}`}
                            />
                            <NmInputV2
                                size="lg"
                                disabled
                                label={t("techsupport-card.payment-number")}
                                placeholder={t("techsupport-card.payment-number")}
                                value={`№ ${supportCard[SUPPORT_FIELD_NAME.PAYMENT_NUMBER]}`}
                            />
                            <NmInputV2
                                size="lg"
                                disabled
                                label={t("techsupport-card.client-name")}
                                placeholder={t("techsupport-card.client-name")}
                                value={clientName || ""}
                            />
                            <NmDropdownV2
                                size="lg"
                                disabled={!this.isEditable}
                                label={t("techsupport-card.appeal-status")}
                                name={SUPPORT_FIELD_NAME.CASE_STATUS}
                                required
                                onChange={this.handleChangeFieldsSupport}
                                value={supportCard[SUPPORT_FIELD_NAME.CASE_STATUS] || ""}
                                error={errorForm[`${SUPPORT_FIELD_NAME.CASE_STATUS}ErrorMessage`]}
                                options={caseStatusOptions}
                                placeholder={t("techsupport-card.appeal-status")}
                            />
                            <NmTextareaV2
                                label={t("techsupport-card.message-text")}
                                disabled
                                value={supportCard[SUPPORT_FIELD_NAME.DESCRIPTION] || ""}
                                name={SUPPORT_FIELD_NAME.DESCRIPTION}
                                placeholder={t("techsupport-card.message-text")}
                            />
                            <NmTextareaV2
                                label={t("techsupport-card.comment")}
                                disabled={!this.isEditable}
                                value={supportCard[SUPPORT_FIELD_NAME.ANSWER] || ""}
                                error={errorForm[`${SUPPORT_FIELD_NAME.ANSWER}ErrorMessage`]}
                                name={SUPPORT_FIELD_NAME.ANSWER}
                                placeholder={t("techsupport-card.comment")}
                                required
                                onChange={this.handleChangeFieldsSupport}
                            />
                        </NmForm>
                    </div>
                </div>
                {
                    this.isEditable ?
                        <div className="support-card-footer flex-content-end">
                            <NmButton
                                size="xl"
                                color="grey"
                                onClick={this.handleOnClickBack}
                            >
                                {t("button.cancel")}
                            </NmButton>
                            <NmButton
                                size="xl"
                                type="submit"
                                onClick={this.save}
                                className="support-card__submit"
                                loading={progressSupportUpdate || progressCard}
                            >
                                {t("button.save")}
                            </NmButton>
                        </div> :
                        null
                }
            </div>
        );
    }
}

export default connect(
    state => ({
        support: getCardSupportSelector(state),
        caseStatus: getCaseStatusDictSelector(state),
        caseStatusOptions: getCaseStatusOptionsSelector(state),
        callTypeStatusDict: getCallTypeStatusDictSelector(state),
        progressSupportUpdate: getProgressSupportUpdateSelector(state),
        progressCard: getProgressCardSelector(state),
        order: getOrderCardSelector(state),
        client: getClientCardSelector(state),
    }),
    {
        getSupportByCaseNumber,
        updateSupport,
        updateFieldContractorStore,
        getOrderById,
        getClientById,
        updateFieldOrderStore,
    },
)(withTranslation()(TechsupportCard));
