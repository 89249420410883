export const DEVICE_TYPE_TRANSLATE = {
    MOBILE: "Mobile",
    BROWSER: "Browser",
    API : "Api",
};

export const NETWORK_TYPE_TRANSLATE = {
    UNKNOWN: "Unknown",
    CELL: "Cell",
    WIFI: "WiFi",
    LAND: "Land",
};