import {all, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";
import {createSelector} from "reselect";

import {call} from "../../node_modules/redux-saga/effects";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/system/setting";
const bffController = "/adm/system/setting";

const SYSTEM_SETTING_GET_REQUEST = "SYSTEM_SETTING_GET_REQUEST";
const SYSTEM_SETTING_GET_SUCCESS = "SYSTEM_SETTING_GET_SUCCESS";
const SYSTEM_SETTING_GET_ERROR = "SYSTEM_SETTING_GET_ERROR";

const SYSTEM_SETTING_SET_REQUEST = "SYSTEM_SETTING_SET_REQUEST";
const SYSTEM_SETTING_SET_SUCCESS = "SYSTEM_SETTING_SET_SUCCESS";
const SYSTEM_SETTING_SET_ERROR = "SYSTEM_SETTING_SET_ERROR";

const SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_REQUEST = "SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_REQUEST";
const SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_SUCCESS = "SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_SUCCESS";
const SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_ERROR = "SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_ERROR";

const SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST = "SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST";
const SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS = "SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS";
const SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR = "SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR";

const SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST = "SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS";
const SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS = "SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS";
const SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR = "SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR";

const MAINTENANCE_NOTICE_PUBLISHED_REQUEST = "MAINTENANCE_NOTICE_PUBLISHED_REQUEST";
const MAINTENANCE_NOTICE_PUBLISHED_SUCCESS = "MAINTENANCE_NOTICE_PUBLISHED_SUCCESS";
const MAINTENANCE_NOTICE_PUBLISHED_ERROR = "MAINTENANCE_NOTICE_PUBLISHED_ERROR";

const SYSTEM_SETTING_GET_TICKET_REQUEST = "SYSTEM_SETTING_GET_SYSTEM_TICKET_REQUEST";
const SYSTEM_SETTING_GET_TICKET_SUCCESS = "SYSTEM_SETTING_GET_SYSTEM_TICKET_SUCCESS";
const SYSTEM_SETTING_GET_TICKET_ERROR = "SYSTEM_SETTING_GET_SYSTEM_TICKET_ERROR";

const SYSTEM_SETTING_SET_ALL_REQUEST = "SYSTEM_SETTING_SET_ALL_REQUEST";
const SYSTEM_SETTING_SET_ALL_SUCCESS = "SYSTEM_SETTING_SET_ALL_SUCCESS";
const SYSTEM_SETTING_SET_ALL_ERROR = "SYSTEM_SETTING_SET_ALL_ERROR";

const initialState = {
    progress: false,
    isMaintenanceNoticePublished: false,
    ticketSettings: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case SYSTEM_SETTING_SET_REQUEST:
    case SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_REQUEST:
    case SYSTEM_SETTING_SET_ALL_REQUEST:
    case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case SYSTEM_SETTING_SET_SUCCESS:
    case SYSTEM_SETTING_SET_ALL_SUCCESS:
    case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case SYSTEM_SETTING_SET_ERROR:
    case SYSTEM_SETTING_SET_ALL_ERROR:
    case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR:
        return {
            ...state,
            progress: false,
        };
    case MAINTENANCE_NOTICE_PUBLISHED_SUCCESS:
        return {
            ...state,
            isMaintenanceNoticePublished: payload.result,
        };
    case SYSTEM_SETTING_GET_TICKET_SUCCESS:
        return {
            ...state,
            ticketSettings: payload,
        };
    default: return state;
    }
};

export const getSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_GET_REQUEST,
    payload,
});

export const setSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_SET_REQUEST,
    payload,
});

export const setSystemTickedEnabledSetting = (payload) => ({
    type: SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_REQUEST,
    payload,
});

export const getMaintenanceNoticeSettings = (payload) => ({
    type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    payload,
});

export const setMaintenanceNoticeSettings = (payload) => ({
    type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    payload,
});

export const getMaintenanceNoticePublished = () => ({
    type: MAINTENANCE_NOTICE_PUBLISHED_REQUEST,
});

export const getSystemSettingTicket = () => ({
    type: SYSTEM_SETTING_GET_TICKET_REQUEST,
});

export const setAllSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_SET_ALL_REQUEST,
    payload,
});

export const systemSettingSelector = state => state.systemSetting;
export const systemSettingTicketSelector = createSelector(systemSettingSelector, ({ticketSettings}) => ticketSettings);
export const systemSettingProgressSelector = createSelector(systemSettingSelector, ({progress}) => progress);

//POST /bff/adm/system/setting/set
// Установить настройку кроме "Системные тикеты"
const setSystemSettingSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${bffController}/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SYSTEM_SETTING_SET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_SET_ERROR, payload: error});
    }
};

//GET /bff/adm/system/setting/get
// Получить настройку
const getSystemSettingSaga = function* ({payload}) {
    try {
        const {onSuccess, key} = payload;

        const result = yield request.bff.get(`${bffController}/get?key=${key}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: SYSTEM_SETTING_GET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_GET_ERROR, payload: error});
    }
};

const setMaintenanceNoticeSettingsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/maintenanceNotice/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Параметры публикации уведомления успешно обновлены");

        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS});
        yield put({type: MAINTENANCE_NOTICE_PUBLISHED_REQUEST});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR, payload: error});
    }
};

const getMaintenanceNoticeSettingsSaga = function* ({payload}) {
    try {
        const {onSuccess} = payload;

        const result = yield request.get(`${controller}/maintenanceNotice/get`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR, payload: error});
    }
};

const getMaintenanceNoticePublishedSaga = function* () {
    try {
        const result = yield call(axios, {
            url: "/nmapi/public/isMaintenanceNoticePublished",
            method: "get",
        });

        const {errorMessage} = result.data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: MAINTENANCE_NOTICE_PUBLISHED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: MAINTENANCE_NOTICE_PUBLISHED_SUCCESS, payload: result.data});
    } catch (error) {
        yield put({type: MAINTENANCE_NOTICE_PUBLISHED_ERROR, payload: error});
    }
};

//POST /bff/adm/system/setting/set-all
// Установить множество настроек
const setAllSystemSettingSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${bffController}/set-all`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_SET_ALL_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SYSTEM_SETTING_SET_ALL_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_SET_ALL_ERROR, payload: error});
    }
};

//GET /bff/adm/system/setting/get/system-ticket
// Получить настройки системных обращений
const getSystemSettingTickedSaga = function* () {
    try {
        const result = yield request.bff.get(`${bffController}/get/system-ticket`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_GET_TICKET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: SYSTEM_SETTING_GET_TICKET_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_GET_TICKET_ERROR, payload: error});
    }
};

//PUT /bff/adm/system/setting/set/system-ticket-enabled
// Установить настройку "Системные тикеты"
const setSystemTickedEnabledSettingSaga = function* ({payload}) {
    try {
        const {
            value,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.put(`${bffController}/set/system-ticket-enabled?value=${value}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(SYSTEM_SETTING_GET_REQUEST, getSystemSettingSaga),
        takeEvery(SYSTEM_SETTING_SET_REQUEST, setSystemSettingSaga),
        takeEvery(SYSTEM_SETTING_SET_SYSTEM_TICKET_ENABLED_REQUEST, setSystemTickedEnabledSettingSaga),
        takeEvery(SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST, getMaintenanceNoticeSettingsSaga),
        takeEvery(SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST, setMaintenanceNoticeSettingsSaga),
        takeEvery(MAINTENANCE_NOTICE_PUBLISHED_REQUEST, getMaintenanceNoticePublishedSaga),
        takeEvery(SYSTEM_SETTING_GET_TICKET_REQUEST, getSystemSettingTickedSaga),
        takeEvery(SYSTEM_SETTING_SET_ALL_REQUEST, setAllSystemSettingSaga),
    ]);
}