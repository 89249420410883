import {DEPOSIT_SUB_PAGE} from "../index";

import {LINK_CLIENT_DEPOSIT_LIST} from "../../../../constants/links";

export const getTabLinks = (clientId) => {
    const REPLENISHMENT_AND_REFUNDS = LINK_CLIENT_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);
    const COMPLETED_PAYMENTS = LINK_CLIENT_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS);
    const PAYMENTS_IN_PROCESS = LINK_CLIENT_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS);
    const PAYMENTS_PENDING = LINK_CLIENT_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING);
    const SPENT_LIMIT = LINK_CLIENT_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.SPENT_LIMIT);
    const PAID_APIS = LINK_CLIENT_DEPOSIT_LIST
        .replace(":clientId", clientId)
        .replace(":subPage", DEPOSIT_SUB_PAGE.PAID_APIS);

    return {
        REPLENISHMENT_AND_REFUNDS,
        COMPLETED_PAYMENTS,
        PAYMENTS_IN_PROCESS,
        PAYMENTS_PENDING,
        SPENT_LIMIT,
        PAID_APIS,
    };
};