import * as yup from "yup";

import {removeSnilsMask} from "../stringFormat";

import {requiredMessage} from "../../constants/validationRules";

export const getSnilsValidation = () => {
    return yup.string()
        .nullable()
        .required(requiredMessage)
        .transform((value) => {
            return removeSnilsMask(value);
        })
        .min(11, "СНИЛС может состоять только из 11 цифр")
        .test("snilsNum", "Вы ввели неверный СНИЛС", function (value) {
            if (!value) {
                return true;
            }

            if (value.length !== 11) {
                return this.createError({
                    message: "СНИЛС может состоять только из 11 цифр",
                    path: "snilsNum",
                });
            }

            let sum = 0;

            for (let i = 0; i < 9; i++) {
                sum += parseInt(value[i]) * (9 - i);
            }

            let checkDigit = 0;

            if (sum < 100) {
                checkDigit = sum;
            } else if (sum > 101) {
                checkDigit = parseInt(String(sum % 101));
                if (checkDigit === 100) {
                    checkDigit = 0;
                }
            }

            return checkDigit === parseInt(value.slice(-2));
        });
};