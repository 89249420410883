import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getListContractors} from "../../../../../../ducks/clientGroups";

export function useGetListContractors({filter, pageSize, pageNum, importProgress}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!importProgress) {
            fetchList();
        }
    }, [filter, pageSize, pageNum]);

    function fetchList() {
        const requestData = {
            pageSize,
            pageNum,
            ...filter,

        };
        dispatch(getListContractors(requestData));
    }
}