import React from "react";

import "./style.sass";

const icon = (
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            className="icon-dispute__path"
            y="18.1016"
            width="9.49254"
            height="1.89851"
            fill="#999999"
        />
        <rect
            className="icon-dispute__path"
            width="18.9851"
            height="3.79702"
            transform="matrix(0.708723 0.705487 -0.708723 0.705487 6.93811 3.27881)"
            fill="#999999"
        />
        <rect
            className="icon-dispute__path"
            width="7.2643"
            height="3.79702"
            transform="matrix(0.708723 0.705487 -0.708723 0.705487 3.64014 7.50635)"
            fill="#999999"
        />
        <rect
            className="icon-dispute__path"
            width="7.2643"
            height="3.79702"
            transform="matrix(0.708723 0.705487 -0.708723 0.705487 11.1852 0)"
            fill="#999999"
        />
    </svg>
);

const IconDispute = () => {
    return icon;
};

export default IconDispute;