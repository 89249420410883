import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import dateFormat, {addYears} from "../../../../../utils/dateFormat";
import {getInitialTouched} from "../../../../../utils/objectHelper";
import {convertStringToDate} from "../../../../../utils/stringHelper";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {
    extendCrowdTask,
    extendMultipleCrowdTask,
} from "../../../../../ducks/bff/crowd/tasks/actionCreators";

const CrowdTaskExtendModal = (props) => {
    const {
        data = {},
        onClose = () => {},
        progress,
    } = props;

    const dispatch = useDispatch();

    const {
        clientId,
        isMultiple,
        workEndDate,
        taskId,
        taskIds,
        onSuccess = () => {},
    } = data;

    const minDate = isMultiple ? new Date() : new Date(workEndDate);
    const maxDate = addYears(isMultiple ? new Date() : workEndDate, 1);
    const initialValues = {
        exactWorkEndDate: convertStringToDate(workEndDate || dateFormat(new Date(), "yyyy-MM-dd")),
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const reqData = {
            clientId,
            exactWorkEndDate: dateFormat(values.exactWorkEndDate, "yyyy-MM-dd"),
            onSuccess,
        };

        if (isMultiple) {
            dispatch(extendMultipleCrowdTask({
                taskIds,
                ...reqData,
            }));

            return;
        }

        dispatch(extendCrowdTask({
            taskId,
            ...reqData,
        }));
    };

    const {
        values,
        setFieldValue,
        errors,
        touched,
        isValid,
        handleSubmit,
    } = useFormik({
        initialValues,
        onSubmit,
        initialTouched: getInitialTouched(initialValues),
        validationSchema: yup.object().shape({
            exactWorkEndDate: yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    return (
        <NmModal
            size="sm"
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Подтвердить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progress}
                />
            }
            header={
                <NmTitle size="lg">
                    {
                        isMultiple ?
                            "Вы действительно хотите продлить период выполнения выбранных заданий?" :
                            "Продление периода выполнения задания"
                    }
                </NmTitle>
            }
        >
            <NmForm addMargin={true}>
                <Text
                    level="2"
                    type="sub"
                    medium={true}
                >
                    Укажите новую дату окончания 
                    {" "}
                    {isMultiple ? "заданий" : "задания"}
                </Text>
                <NmDatePicker
                    required={true}
                    isClearable={true}
                    size="xl"
                    name="exactWorkEndDate"
                    selected={values.exactWorkEndDate}
                    error={touched.exactWorkEndDate && errors.exactWorkEndDate}
                    dateFormatMask="99.99.9999"
                    label="Дата окончания задания"
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(e, {name, value}) => {
                        setFieldValue(name, value);
                    }}
                />
            </NmForm>
        </NmModal>
    );
};

export default CrowdTaskExtendModal;