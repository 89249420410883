import {createSelector} from "reselect";

import {handleTaskList} from "../../utils/jobHelper";
import {getTotalPages} from "../../utils/mathHelper";

export const taskSelector = state => state.task;
export const taskListSelector = createSelector(
    taskSelector,
    ({list}) => {
        try {
            return handleTaskList(list || []);
        } catch (e) {
            console.error(e);
            return [];
        }
    },
);
export const taskTotalCountSelector = createSelector(
    taskSelector,
    ({totalCount}) => totalCount,
);
export const taskTotalPagesSelector = createSelector(
    taskSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const taskProgressSelector = createSelector(
    taskSelector,
    ({progress}) => progress,
);