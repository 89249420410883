import React, {useState} from "react";

import {MediaButtons} from "../../../../components/ActualComponents/MediaControls";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import Text from "../../../../components/ActualComponents/Text";
import RangeStepInput from "../../../../components/RangeStepInput";
import {ReactComponent as FilterIcon} from "../../../../images/filter_outline_24.svg";
import {Loader} from "semantic-ui-react";

import bem from "../../../../utils/bem";
import {getDadataAddress, getOptionsFromCityToApartment} from "../../../../utils/dadata";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {DADATA_FIAS_LEVEL} from "../../../../constants/dadata";
import {MAP_OBJECT_TYPE_DICT} from "../../../../constants/map";
import {MARKER_TYPE} from "../../constants";

import "./style.sass";

const MAP_MARKERS_TYPES_LIST = [
    MARKER_TYPE.ORDER,
    MARKER_TYPE.CROWD_TASK,
    MARKER_TYPE.CONTRACTOR,
    MARKER_TYPE.ADVERT,
];

const MapToolbar = (props) => {
    const {
        className = "",
        onSubmitFilter = () => {
        },
        onChangeFilter = () => {
        },
        filter = {},
        showMap = false,
        mediaControls,
        openFilter = false,
        toggleFilter,
        progressMarkers = false,
    } = props;

    const {
        distanceFilter: {
            distance,
        },
        locationAddressFiasIdsFilter,
        types = [],
    } = filter;

    const [block, element] = bem("map-toolbar", className);

    return (
        <div className={block({showMap})}>
            <div className={element("filter")}>
                <div className="col-4">
                    <NmDadata
                        size="lg"
                        name="locationAddressFiasIdsFilter"
                        label="Адрес"
                        placeholder="Введите адрес"
                        maxFiasLevel={DADATA_FIAS_LEVEL.SETTLEMENT}
                        onChange={onChangeFilter}
                        className="fluid"
                        fromBound="region"
                        toBound="house"
                        formatter={getDadataAddress}
                        optionsFormatter={getOptionsFromCityToApartment}
                        onSubmit={() => onSubmitFilter(filter)}
                        initialAddressIds={locationAddressFiasIdsFilter}
                        isClearSearchOnBlur={true}
                        isClearable={true}
                    />
                </div>
                <div className="col-4 ms-8">
                    <RangeStepInput
                        step={1}
                        min={1}
                        max={15}
                        label="Радиус поиска"
                        className="fluid"
                        name="distance"
                        value={distance}
                        onDistanceChange={onChangeFilter}
                        isUpdateValueAfterMouseUp={true}
                    />
                </div>
                {
                    showMap &&
                    <div className="col-4 ms-8">
                        <Text>
                            Показывать только
                        </Text>
                        <div className="flex mt-2">
                            {
                                MAP_MARKERS_TYPES_LIST.map(item => {
                                    return (
                                        <NmCheckboxV2
                                            className="me-4"
                                            name="types"
                                            onChange={onChangeFilter}
                                            checked={types.includes(item)}
                                            label={MAP_OBJECT_TYPE_DICT[item]}
                                            value={item}
                                        />

                                    );
                                })
                            }
                        </div>
                    </div>
                }
            </div>
            <div
                style={{width: 48, marginRight: 8}}
                className="relative"
            >
                {
                    progressMarkers &&
                    <Loader
                        className={element("loader")}
                        active={true}
                        inverted={false}
                        size="medium"
                    />
                }
            </div>
            {
                mediaControls &&
                <MediaButtons
                    className={element("controls")}
                    config={{
                        ...mediaControls,
                        size: "xl",
                        buttons: [
                            ...mediaControls.buttons,
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    onClick: toggleFilter,
                                    onlyIcon: true,
                                    color: "grey",
                                    icon: <FilterIcon />,
                                },
                                settings: {
                                    filter: true,
                                },
                                asset: {
                                    mobile: {
                                        children: openFilter ? "Закрыть фильтр" : "Открыть фильтр",
                                    },
                                },
                            },
                        ],
                    }}
                />
            }
        </div>
    );
};

export default MapToolbar;