import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../../../../utils/stringHelper";

import {
    getOptimizedObjects,
    objectsOptimizedSelector,
    objectsProgressListSelector,
    objectsTotalCountSelector,
    objectsTotalPagesSelector,
    updateFieldsObjectsStore,
} from "../../../../../../ducks/objects";
import {
    getProjectsList,
    getProjectsListSelector,
    getProjectsTotalCountSelector,
    getProjectsTotalPagesSelector,
    projectProgressListSelector,
    updateFieldsStoreProject,
} from "../../../../../../ducks/projects";

export function useFetchOrderTemplatesObjectList(props) {
    const {
        clientId,
        isProjectList,
        pageNum,
        pageSize,
        nameSubstringFilter,
        projectIdsFilter,
        objectIdsFilter,
    } = props;

    const projectList = useSelector(getProjectsListSelector);
    const projectTotalCount = useSelector(getProjectsTotalCountSelector);
    const projectTotalPages = useSelector(getProjectsTotalPagesSelector);
    const progressProjectList = useSelector(projectProgressListSelector);

    const objectList = useSelector(objectsOptimizedSelector);
    const objectTotalCount = useSelector(objectsTotalCountSelector);
    const objectTotalPages = useSelector(objectsTotalPagesSelector);
    const progressObjectList = useSelector(objectsProgressListSelector);

    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (isProjectList) {
                dispatch(updateFieldsStoreProject({projectsList: []}));

                return;
            }

            dispatch(updateFieldsObjectsStore({optimizedObjects: []}));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filterDto]);

    const fetchList = () => {
        const reqData = {
            clientId,
            pageNum,
            pageSize,
            nameSubstringFilter,
            objectIds: objectIdsFilter,
            projectIds: projectIdsFilter,
            ...filterDto,
        };

        if (isProjectList) {
            dispatch(getProjectsList({
                ...reqData,
            }));

            return;
        }

        dispatch(getOptimizedObjects({
            ...reqData,
        }));
    };

    const getData = () => {
        if (isProjectList) {
            return {
                list: projectList,
                totalCount: projectTotalCount,
                totalPages: projectTotalPages,
                progressList: progressProjectList,
            };
        }

        return {
            list: objectList,
            totalCount: objectTotalCount,
            totalPages: objectTotalPages,
            progressList: progressObjectList,
        };
    };

    function mapFilterDto(filter) {
        const {
            nameSubstringFilter,
        } = filter;

        return {
            ...filter,
            nameSubstringFilter: handleFormString(nameSubstringFilter),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        ...getData(),
        fetchList,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}