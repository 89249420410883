import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import TemplatesDropdown from "../../../../../../../components/TemplatesDropdown";
import {CrowdTaskEditContext} from "../context-provider";

import {CURRENT_CLIENT_USER_ID, ls} from "../../../../../../../utils/localstorage";

import {OBJECT_STATUS_DICT} from "../../../../../../../constants/objects";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../../../templates/constants";
import {TASK_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES} from "../../constants";

import {
    clientObjectOptionsSelector,
    getClientObjectList,
} from "../../../../../../../ducks/clientObject";
import {getClientPropertiesCardSelector} from "../../../../../../../ducks/clientProperties";
import {
    documentCustomTemplateAggregationShortTemplatesSelector,
    getDocumentCustomTemplateShortActual,
    getDocumentCustomTemplateShortDefault,
} from "../../../../../../../ducks/documentsCustomTemplate";

const CrowdTaskEditPreConditionsForm = () => {
    const {
        clientId,
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
    } = useContext(CrowdTaskEditContext);

    const clientInfo = useSelector(getClientPropertiesCardSelector);
    const objectOptions = useSelector(clientObjectOptionsSelector);
    const aggregationShortTemplates = useSelector(documentCustomTemplateAggregationShortTemplatesSelector);

    const clientUserId = ls(CURRENT_CLIENT_USER_ID);

    const dispatch = useDispatch();

    const {
        applicationTemplateId: applicationTemplateOptions,
        actTemplateId: actTemplatesOptions,
    } = aggregationShortTemplates;

    useEffect(() => {
        fetchTemplates();
    }, []);

    useEffect(() => {
        fetchObjects();
    }, [values.valueObjectFilter]);

    const fetchObjects = () => {
        dispatch(getClientObjectList({
            clientId,
            clientUserId,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
            nameSubstringFilter: values.valueObjectFilter,
        }));
    };

    const fetchTemplates = () => {
        TASK_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES.forEach(item => {
            const {
                documentTypeFilter,
                fieldName,
            } = item;

            fetchTemplateList({
                documentTypeFilter,
                fieldName,
            });

            fetchDefaultTemplate({
                documentTypeFilter,
                fieldName,
            });
        });
    };

    const fetchTemplateList = ({documentTypeFilter, fieldName}) => {
        const {
            orderContractPaymentType,
        } = values;

        dispatch(getDocumentCustomTemplateShortActual({
            isAggregation: true,
            aggregationFieldName: fieldName,
            orderContractPaymentTypeFilter: orderContractPaymentType,
            clientIdFilter: clientId,
            documentTypeFilter,
            pageNum: 1,
            pageSize: 300,
            fieldName: "aggregationShortTemplates",
        }));
    };

    const fetchDefaultTemplate = ({documentTypeFilter, fieldName}) => {
        const {
            orderContractPaymentType,
        } = values;

        dispatch(getDocumentCustomTemplateShortDefault({
            clientIdFilter: clientId,
            orderContractPaymentTypeFilter: orderContractPaymentType,
            documentTypeFilter,
            onSuccess: ({id}) => {
                setFieldValue(fieldName, id);
            },
        }));
    };

    const onSearchChangeObject = (valueObjectFilter) => {
        setFieldValue("valueObjectFilter", valueObjectFilter);
    };

    return (
        <NmForm addMargin={true}>
            <NmDropdownV2
                search
                required
                placeholder="Выберите из списка"
                label="Объект"
                name="objectId"
                onChange={handleChange}
                onSearchChange={onSearchChangeObject}
                value={values.objectId}
                searchQuery={values.valueObjectFilter}
                options={objectOptions}
                error={touched.objectId && errors.objectId}
            />
            <TemplatesDropdown
                required
                disabled={!clientInfo.customDocumentTemplatesAvailable}
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION}
                buttonText="Скачать заявку"
                placeholder="Выберите шаблон заявки"
                label="Шаблон заявки"
                name="applicationTemplateId"
                onChange={handleChange}
                options={applicationTemplateOptions}
                value={values.applicationTemplateId}
                size="xl"
                error={touched.applicationTemplateId && errors.applicationTemplateId}
            />
            <TemplatesDropdown
                required
                disabled={!clientInfo.customDocumentTemplatesAvailable}
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK}
                buttonText="Скачать акт"
                placeholder="Выберите шаблон акта"
                label="Шаблон акта"
                name="actTemplateId"
                onChange={handleChange}
                options={actTemplatesOptions}
                value={values.actTemplateId}
                size="xl"
                error={touched.actTemplateId && errors.actTemplateId}
            />
        </NmForm>
    );
};

export default CrowdTaskEditPreConditionsForm;