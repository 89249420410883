import {useEffect, useState} from "react";

export const useHidingOrderError = (params) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        if (params.error) {
            setError(params.error);
        }
    }, [params.error]);

    return {
        error,
        clearError: () => {
            setError(null);
        },
    };
};