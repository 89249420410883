import {useState} from "react";
import {useDispatch} from "react-redux";

import {isNullOrWhitespace} from "../../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../../constants/validationRules";

import {
    updateContractorAdditionalSpecialities,
    updateContractorFamilyStatus,
    updateContractorForeignLanguage, updateContractorMainSpeciality,
} from "../../../../../../../ducks/contractorProfile";

export const defaultMainWorkTypeForm = {
    mainSpecialityId: "",
    hasAdditionalSpecialities: false,
};

export default function useUpdateMainWorkType({contractorId, onClose, getProfile}) {
    const [form, setForm] = useState({...defaultMainWorkTypeForm});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const {
        mainSpecialityId,
    } = form;

    const onChangeForm = (e, {value, name, checked}) => {
        setForm(prevState => ({
            ...prevState,
            [name]: checked === undefined ? value : checked,
        }));
    };

    const validateForm = () => {
        if (isNullOrWhitespace(mainSpecialityId)) {
            setError({mainSpecialityId: requiredMessage});
            return false;
        }
        setError({});
        return true;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        dispatch(updateContractorMainSpeciality({
            contractorId,
            mainSpecialityId,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    return {
        form,
        onChangeForm,
        submit,
        setForm,
        error,
    };
}