import {useState} from "react";
import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../utils/toastHelper";

import {
    refreshContractorBankStatus,
    refreshContractorIdentificationStatus,
} from "../../../../ducks/contractorBanks";

export function useContractorBanksAction({contractorId, fetchList = () => {}}) {
    const dispatch = useDispatch();

    const [progressRefreshBankStatus, setProgressRefreshBankStatus] = useState([]);
    const [progressRefreshIdentificationStatus, setProgressRefreshIdentificationStatus] = useState([]);

    const refreshIdentificationStatus = (bankType) => {
        setProgressRefreshIdentificationStatus([
            ...progressRefreshIdentificationStatus,
            bankType,
        ]);

        dispatch(refreshContractorIdentificationStatus({
            contractorId,
            bankType,
            getResult: ({statusChange}) => {
                if (statusChange) {
                    toastSuccess("Статус успешно обновлен");
                    fetchList();
                }

                setProgressRefreshIdentificationStatus(progressRefreshIdentificationStatus.filter(item => item !== bankType));
            },
        }));
    };

    const refreshBankStatus = (bankType) => {
        setProgressRefreshBankStatus([
            ...progressRefreshBankStatus,
            bankType,
        ]);

        dispatch(refreshContractorBankStatus({
            contractorId,
            bankType,
            getResult: ({statusChange}) => {
                if (statusChange) {
                    toastSuccess("Статус успешно обновлен");
                    fetchList();
                }

                setProgressRefreshBankStatus(progressRefreshBankStatus.filter(item => item !== bankType));
            },
        }));
    };

    return {
        refreshIdentificationStatus,
        refreshBankStatus,
        progressRefreshIdentificationStatus,
        progressRefreshBankStatus,
    };
}