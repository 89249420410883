import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty, isEqual} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import ContextMenuMobile from "../../../components/ActualComponents/ContextMenuMobile";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmAdvancedTooltip from "../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmHorizontalToggleV2 from "../../../components/ActualComponents/NmHorizontalToggleV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import {NmPageCardHeader} from "../../../components/ActualComponents/NmPageCardHeader";
import NmPageInfoCardsAccordion from "../../../components/ActualComponents/NmPageInfoCardsAccordion";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import ButtonCalculator from "../../../components/ButtonCalculator";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import CheckboxList from "../../../components/CheckboxList";
import ContractorNoteForClientTooltip from "../../../components/ContractorNoteForClientTooltip";
import ContractorPassportStatus from "../../../components/ContractorPassportStatus";
import ExtLink from "../../../components/ExtLink";
import InstrumentPaymentIndicator from "../../../components/InstrumentPaymentIndicator";
import InvitePerformerToNaimix from "../../../components/InvitePerformerToNaimix";
import LocatedOutsideRussiaTooltip from "../../../components/LocatedOutsideRussiaTooltip";
import NmButton from "../../../components/NmButton";
import NmPage, {FILTER_OPTION} from "../../../components/NmPage";
import NmPageCard from "../../../components/NmPageCard";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import RegistryCardButton from "../../../components/RegistryCardButton";
import RegistryPaymentError from "../../../components/RegistryPaymentError";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import UnavailableTemplates from "../../../components/UnavailableTemplates";
import {ReactComponent as AddBoxIcon} from "../../../images/add_box.svg";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";
import {ReactComponent as BlockedIcon} from "../../../images/lock_24.svg";
import {ReactComponent as NoOnIcon} from "../../../images/no_on.svg";
import {ReactComponent as UserIcon} from "../../../images/user_24.svg";
import {ReactComponent as YesOnIcon} from "../../../images/yes_on.svg";
import DepositCalculator from "../../deposit/deposit-calculator";
import OrderTemplateInfo from "../../order/template-info";
import RegistryEditContractorPayment from "../registry-edit-contractor-payment";
import RegistryDuplicatePaymentsList from "../registry-list/registry-duplicate-payments-list";
import RegistryStepSearchContractor from "../registry-step-contractor-search";
import {CREATE_ACTS_TYPE, getCreateTypeDescription} from "../registryActsType";
import RegistryPaymentDocumentTablesModal from "./document-tables-modal";
import RegistryCardFilter from "./filter";
import {Loader} from "semantic-ui-react";

import {getUnavailableTemplatesFlags} from "../../../components/UnavailableTemplates/utils";
import dateFormat from "../../../utils/dateFormat";
import {openLinkByUrl} from "../../../utils/downloadBlob";
import {getFcStatusForRegistryItem} from "../../../utils/fcRegistries";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {replacer} from "../../../utils/replacer";
import {
    formatAmountWithNullChecking,
    getFullName,
    phoneFormat,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";
import {getRegistryContractorCommission} from "./utils/getContractorCommission";
import {getRegistryPaymentStatus} from "./utils/getStatus";

import {UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE} from "../../../components/UnavailableTemplates/constants";
import {citizenshipsDict} from "../../../constants/citizenships";
import {DEFINING_REGISTRY_PARAMETER_TYPE} from "../../../constants/clientList";
import {PAYMENT_METHOD_DICT} from "../../../constants/clientSettings";
import {COLOR} from "../../../constants/color";
import {TIME_INTERVAL_PAYMENT_POSSIBILITY} from "../../../constants/contractor";
import {CLIENT_TYPE_CODE} from "../../../constants/dicts";
import {
    ORDER_AMOUNT_CALCULATION_METHOD_DICT,
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../constants/finance";
import {UPLOAD_TYPE} from "../../../constants/financeExport";
import {
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
} from "../../../constants/links";
import {
    REGISTRY_CARD_OPTIONS,
    REGISTRY_CONFIRM_TEXT,
    REGISTRY_OPERATION,
    REGISTRY_PAYMENT_ERRORS,
    REGISTRY_PAYMENTS_STATUS_DICT,
    REGISTRY_STATUS_DICT,
} from "../../../constants/registry";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";
import {phoneRegWithForeign} from "../../../constants/validationRules";

import {getClientCardSelector, getClientCommissionRateSelector, getClientTypeSelector} from "../../../ducks/client";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {getContractorByIds, updateFieldContractorStore} from "../../../ducks/contractor";
import {
    bankCheckCancelToken,
    getContractorsPaymentPossibility,
    refreshContractorsPaymentPossibility,
    updateFieldsContractorPaymentPossibilityState,
} from "../../../ducks/contractorBankCheck";
import {registryFilterByTicketSelector} from "../../../ducks/crm/ticket";
import {availableForPaymentsSelector, getAvailableForPayments} from "../../../ducks/deposit";
import {downloadDocument, isFrameContractSigned} from "../../../ducks/documents";
import {getDocumentsCustomTemplateReplacementsForUnavailableList} from "../../../ducks/documentsCustomTemplate";
import {addRegistryPaymentsDocumentsExport} from "../../../ducks/documentsExport";
import {avatarBase64ImagesListSelector} from "../../../ducks/fileStore";
import {addFinanceExportTask} from "../../../ducks/financeExport";
import {
    getAllTasks,
    jobTasksRegistryDiscardPaymentsSelector,
    jobTasksRegistryPaymentsImportSelector,
} from "../../../ducks/job";
import {
    discardOutstandingPayments,
    getEnrichedRegistryById,
    getIsFrameContractSignedForContractorsOnRegistry,
    registriesCardSelector,
    registryPayProcessSelector,
    registryProgressCardSelector,
    transferRegistryToPay,
    updateRegistryStoreField,
} from "../../../ducks/registry";
import {
    cancelRegistryPayment,
    deleteOrCancelRegistryPayments,
    getRegistryPayments,
    importFromFileRegistryPayments,
    refreshPerformerActionProcessSelector,
    registryPaymentHasInProgress,
    registryPaymentPay,
    registryPaymentRefreshPerformer,
    registryPaymentsContractDateConclusionSelector,
    registryPaymentsHasInProgressSelector,
    registryPaymentsListSelector,
    registryPaymentsProgressSelector,
    registryPaymentsTotalCountSelector,
    registryPaymentsTotalPagesSelector,
    registryProgressImportFileSelector,
    updateRegistryPaymentsStore,
} from "../../../ducks/registryPayments";
import {
    getRegistryPaymentStatuses,
    getRegistryPaymentStatusesAllForFilters,
    registryPaymentStatusesDictSelector,
    updateRegistryPaymentStatusesStore,
} from "../../../ducks/registryPaymentStatuses";

import "./style.sass";

const isForPaymentPayments = payments => payments.filter(value => value.status === REGISTRY_STATUS_DICT.FOR_PAYMENT.VALUE).length;

const getContractorName = (lastName, firstName, patronymic, contractorFio) => {
    if (!isNullOrWhitespace(lastName)) {
        return getFullName(lastName, firstName, patronymic);
    }
    if (!isNullOrWhitespace(contractorFio)) {
        return contractorFio;
    }
    return "Не определено";
};

const MAX_QUERY_COUNT = 20;
const QUERY_DELAY_MS = 5000;

const HEADER_MEDIA_QUERIES = {mobile: {maxWidth: 767}, tablet: {minWidth: 768, maxWidth: 1919}};

class RegistryCard extends Component {
    constructor(props) {
        super(props);

        const {match: {params: {registryId, clientId}}} = props;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            filter: {},
            numberSelected: 0,
            selectedList: [],
            typeOperation: "",
            data: "",
            isOpenSearchContractor: false,
            isOpenContractorParamsEdit: false,
            isInviteViaEmailOpen: false,
            isAnyPayments: false,
            countCardGetQuery: 0,
            countListGetQuery: 0,
            isRefresh: false,
            payModel: [],
            selectedPaymentsSum: 0,
            confirmData: {},
            isHideDescriptionInList: false,
            isInnTypeImportContractors: false,
            tablesModalData: {},
        };

        this.registryId = registryId;
        this.clientId = clientId;
        this.jobId = `${clientId}:${registryId}`;
        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {registryFilterByTicket} = this.props;

        this.fetchRegistryCard();
        this.fetchRegistryPayments(registryFilterByTicket);
        this.registryPaymentHasInProgress();
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    getTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            registryPayments,
            card,
            card: {status},
            progressCard,
            progressList,
            payProcess,
            progressImport,
        } = this.props;

        const {
            selectedList,
            processUpdateCard,
            processUpdateList,
            selectedPaymentsSum,
        } = this.state;
        const {
            registryPayments: _registryPayments,
            card: _card,
            card: {status: _status},
            progressList: _progressList,
            progressCard: _progressCard,
            payProcess: _payProcess,
            progressImport: _progressImport,
        } = prevProps;

        if (!isEqual(registryPayments, _registryPayments)) {
            // При изменении списка оплат обнуляем счетчик, при условии что он не равен 0
            if (selectedPaymentsSum !== 0) {
                this.setState({
                    selectedPaymentsSum: 0,
                });
            }
            const selectedList = registryPayments.map((value, index) => ({
                ...value,
                number: index + 1,
                showCheckBox: true,
                disabledCheckBox: !this.getIsEditableRows(value),
                isSelected: false,
            }));

            this.setState({
                selectedList,
            });
        }

        if (payProcess !== _payProcess && !payProcess) {
            this.fetchRegistryCard();
            this.fetchRegistryPayments();
        }

        // если реестр в статусе К оплате после обновления
        if (processUpdateCard &&
            progressCard !== _progressCard && !progressCard &&
            status === REGISTRY_STATUS_DICT.FOR_PAYMENT.VALUE
        ) {
            this.updateRegistryCardSoon();
        }

        if (processUpdateList &&
            progressList !== _progressList && !progressList &&
            isForPaymentPayments(registryPayments)
        ) {
            this.updateRegistryPayments();
        }

        if (processUpdateCard && !payProcess &&
            progressCard !== _progressCard &&
            ![REGISTRY_STATUS_DICT.NOT_PAID.VALUE, REGISTRY_STATUS_DICT.FULLY_PAID.VALUE, REGISTRY_STATUS_DICT.PARTIALLY_PAID.VALUE].includes(_status) &&
            [REGISTRY_STATUS_DICT.NOT_PAID.VALUE, REGISTRY_STATUS_DICT.FULLY_PAID.VALUE, REGISTRY_STATUS_DICT.PARTIALLY_PAID.VALUE].includes(status)
        ) {
            this.stopUpdateCard();
        }

        if (processUpdateList && !payProcess &&
            progressList !== _progressList &&
            !progressList &&
            !isForPaymentPayments(registryPayments)
        ) {
            this.setState({
                processUpdateList: false,
            });
        }

        if (Object.keys(card) && JSON.stringify(card) !== JSON.stringify(_card)) {
            const list = selectedList.map(value => ({
                ...value,
                disabledCheckBox: !this.getIsEditableRows(value),
            }));

            this.setState({
                selectedList: list,
            });
        }

        if (!processUpdateCard && !processUpdateList && registryPayments.length && JSON.stringify(registryPayments) !== JSON.stringify(_registryPayments)) {
            const selectedList = registryPayments.map((value, index) => ({
                ...value,
                number: index + 1,
                showCheckBox: true,
                disabledCheckBox: !this.getIsEditableRows(value),
                isSelected: false,
            }));

            this.getContractorsPaymentPossibility();

            this.setState({
                selectedList,
                numberSelected: 0,
            });
        }

        if (progressImport) {
            setTimeout(this.registryPaymentHasInProgress, 3000);
        }

        if (!progressImport && _progressImport) {
            this.fetchRegistryCard();
            this.fetchRegistryPayments();
        }
    }

    componentWillUnmount() {
        const {
            updateRegistryPaymentsStore,
            updateFieldContractorStore,
            updateRegistryStoreField,
            updateFieldsContractorPaymentPossibilityState,
            updateRegistryPaymentStatusesStore,
        } = this.props;

        updateRegistryPaymentsStore({registryPayments: []});
        updateFieldContractorStore({card: {}});
        updateRegistryStoreField({card: {}});

        updateFieldsContractorPaymentPossibilityState({
            contractorsPossibility: [],
        });
        updateRegistryPaymentStatusesStore({
            statusesAllForFilter: {},
            paymentStatuses: {},
        });

        bankCheckCancelToken.cancel();
        clearTimeout(this.timerUpdatePayments);
        clearTimeout(this.timerUpdateCard);
        clearTimeout(this.checkBankPossibleTimer);
    }

    get isIndividualEntrepreneurRegistry() {
        const {card: {orderContractPaymentType}} = this.props;

        return orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    };

    fetchRegistryCard = () => {
        const {
            getEnrichedRegistryById,
            getAvailableForPayments,
            getRegistryPaymentStatusesAllForFilters,
            getRegistryPaymentStatuses,
        } = this.props;

        getEnrichedRegistryById({
            clientId: this.clientId,
            registryId: this.registryId,
            getResult: ({objectId, orderContractPaymentType}) => {
                if (objectId) {
                    getAvailableForPayments({
                        isCivil: orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL,
                        clientId: this.clientId,
                        objectId,
                    });
                }

                const isIndividual = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

                getRegistryPaymentStatuses({
                    isIndividual,
                });

                getRegistryPaymentStatusesAllForFilters({
                    isIndividual,
                });
            },
        });
    };

    registryPaymentHasInProgress = () => {
        const {registryPaymentHasInProgress} = this.props;

        registryPaymentHasInProgress({
            registryId: this.registryId,
        });
    };

    getContractorsPaymentPossibility = () => {
        const {
            getContractorsPaymentPossibility,
            registryPayments,
        } = this.props;

        const contractorIds = [...new Set(registryPayments.filter(item => Boolean(item.contractorId)).map(item => item.contractorId))];

        if (isEmpty(contractorIds)) {
            return;
        }

        getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(this.checkBankPossibleTimer);
            },
            handleResponse: this.handleResponsePaymentPossibility,
            clientId: this.clientId,
            contractorIds,
        });
    };

    handleResponsePaymentPossibility = (result) => {
        const {
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            return;
        }

        this.checkBankPossibleTimer = setTimeout(this.getContractorsPaymentPossibility, TIME_INTERVAL_PAYMENT_POSSIBILITY);
    };

    refreshPaymentPossibility = () => {
        const {registryPayments} = this.props;
        const {isRefresh} = this.state;

        if (isRefresh || registryPayments.length === 0 || this.isUpdate) {
            return;
        }

        const {
            refreshContractorsPaymentPossibility,
        } = this.props;

        this.setState({
            isRefresh: true,
        });

        const contractorIds = [...new Set(registryPayments.filter(item => Boolean(item.contractorId)).map(item => item.contractorId))];

        refreshContractorsPaymentPossibility({
            contractorIds,
            useFnsThreshold: true,
            handleResponse: this.handleResponseRefreshContractorsPaymentPossibility,
        });
    };

    refreshPerformer = () => {
        const {
            registryPayments,
            refreshPerformerActionProcess,
        } = this.props;

        if (isEmpty(registryPayments) || refreshPerformerActionProcess) {
            return;
        }

        const {
            registryPaymentRefreshPerformer,
        } = this.props;

        registryPaymentRefreshPerformer({
            registryId: this.registryId,
            clientId: this.clientId,
        });
    };

    handleResponseRefreshContractorsPaymentPossibility = ({errorMessage}) => {
        bankCheckCancelToken.cancel();
        clearTimeout(this.checkBankPossibleTimer);

        this.setState({
            isRefresh: false,
        });

        if (errorMessage) {
            return;
        }

        this.getContractorsPaymentPossibility();
    };

    stopUpdateCard = () => {
        this.setState({
            processUpdateCard: false,
        });
    };

    updateRegistryCardSoon = () => {
        this.timerUpdateCard = setTimeout(() => {
            const {processUpdateCard, countCardGetQuery} = this.state;

            this.setState(prevState => ({
                ...prevState,
                countCardGetQuery: ++prevState.countCardGetQuery,
            }));

            if (countCardGetQuery > MAX_QUERY_COUNT) {
                this.setState({
                    processUpdateCard: false,
                    countCardGetQuery: 0,
                });

                return;
            }

            if (processUpdateCard) {
                this.fetchRegistryCard();
            }
        }, QUERY_DELAY_MS);
    };

    updateRegistryPayments = () => {
        this.timerUpdatePayments = setTimeout(() => {
            const {processUpdateList, countListGetQuery} = this.state;

            this.setState(prevState => ({
                ...prevState,
                countListGetQuery: ++prevState.countListGetQuery,
            }));

            if (countListGetQuery > MAX_QUERY_COUNT) {
                this.setState({
                    processUpdateList: false,
                    countListGetQuery: 0,
                });

                return;
            }

            if (processUpdateList) {
                this.fetchRegistryPayments();
            }
        }, QUERY_DELAY_MS);
    };

    get localization() {
        const {t} = this.props;

        return {
            title: t("registry-card.title"),
            downloadRegistry: t("registry-card.downloadRegistry"),
            notDataOfDownloadRegistry: t("registry-card.notDataOfDownloadRegistry"),
            goRegistryPay: t("registry-card.goRegistryPay"),
            invitePerformer: t("registry-card.invitePerformer"),
            addPerformer: t("registry-card.addPerformer"),
            loadPerformersFromFile: t("registry-card.loadPerformersFromFile"),
            inviteViaEmail: t("registry-card.inviteViaEmail"),
            deleteRow: t("registry-card.deleteRow"),
            performers: t("registry-card.performers"),
            payNotCompleted: t("registry-card.payNotCompleted"),
            cancelNotCompleted: t("registry-card.cancelNotCompleted"),
            registrySum: t("registry-card.registrySum"),
            neededDeposit: t("registry-card.neededDeposit"),
            payAgain: t("registry-card.payAgain"),
            edit: t("registry-card.edit"),
            checkFNS: t("registry-card.checkFNS"),
            cancelPayment: "Отменить",
            sumSelectedPaymentsLabel: t("registry-card.sumSelectedPaymentsLabel"),
        };
    }

    getIsEditableRows = ({status: statusPayment, deletionStarted}) => {
        const {card: {status, archived}} = this.props;

        if (archived || deletionStarted) {
            return false;
        }

        return status === REGISTRY_STATUS_DICT.DRAFT.VALUE ||
            [
                REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_SIGNATURE.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_CONTRACTOR_CONFIRMATION.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE,
            ].includes(statusPayment);
    };

    fetchRegistryPayments = (registryFilterByTicket = {}) => {
        const {getRegistryPayments} = this.props;
        const {pageNum, pageSize, filter} = this.state;

        getRegistryPayments({
            clientId: this.clientId,
            registryId: this.registryId,
            pageNum,
            pageSize,
            ...filter,
            ...registryFilterByTicket,
            // Проверки для подсветки красным
            dataCheckFilter: true,
            registryPaymentFilterStatuses:
                filter.registryPaymentFilterStatuses?.length && filter.registryPaymentFilterStatuses[0] !== "all"
                    ? filter.registryPaymentFilterStatuses
                    : undefined,
        });
    };

    showConfirmWindow = (confirmData, typeOperation, data, isOnlyConfirm = false) => {
        this.setState({
            ...confirmData,
            typeOperation,
            data,
            isOpenConfirm: true,
            isOnlyConfirm,
        });
    };

    handleResponsePay = (payModel) => {
        this.setState({
            payModel,
        });
    };

    closeDuplicatePaymentsList = () => {
        this.setState({
            payModel: [],
        });
    };

    transferRegistryToPay = (data) => {
        const {transferRegistryToPay} = this.props;

        transferRegistryToPay({
            data: {
                clientId: this.clientId,
                registryId: this.registryId,
                ...data,
            },
            handleResponse: this.handleResponsePay,
            handleError: this.handleErrorPay,
            onSuccess: () => {
                this.setState({
                    processUpdateCard: true,
                    processUpdateList: true,
                });
            },
        });
    };

    discardOutstandingPayments() {
        const {discardOutstandingPayments} = this.props;

        discardOutstandingPayments({
            clientId: this.clientId,
            registryId: this.registryId,
            onSuccess: this.getTasks,
        });
    }

    deletePayments = () => {
        const {deleteOrCancelRegistryPayments} = this.props;
        const {selectedList} = this.state;

        const registryPaymentSeqNumbers = selectedList.filter(value => value.isSelected).map(value => value.registrySeqNum);

        deleteOrCancelRegistryPayments({
            clientId: this.clientId,
            registryId: this.registryId,
            registryPaymentSeqNumbers,
            onSuccess: this.getTasks,
        });
    };

    cancelRegistryPayment = (params = {}) => {
        const {
            cancelRegistryPayment,
        } = this.props;

        const {
            data: {
                registrySeqNum,
            },
        } = this.state;

        cancelRegistryPayment({
            data: {
                registrySeqNum,
                clientId: this.clientId,
                registryId: this.registryId,
                ...params,
            },
        });
    };

    registryPaymentPay = (params = {}) => {
        const {registryPaymentPay} = this.props;
        const {data: {registrySeqNum}} = this.state;

        registryPaymentPay({
            data: {
                registrySeqNum,
                clientId: this.clientId,
                registryId: this.registryId,
                ...params,
            },
            handleError: this.handlePaymentErrorPay,
        });
    };

    registryPay = () => {
        this.transferRegistryToPay({
            checkDuplicate: true,
            invalidApplicationDateConfirmed: false,
        });
        this.onCloseUnavailableTemplates();
        this.handleCancelConfirm();
    };

    confirmOperationSwitcher = () => {
        const {
            typeOperation,
            data,
        } = this.state;

        switch (typeOperation) {
        case REGISTRY_OPERATION.GO_REGISTRY_PAY: {
            this.checkUnavailableTemplates();

            return;
        }
        case REGISTRY_OPERATION.PAY_NOT_COMPLETED_PAYMENTS: {
            this.registryPay();

            return;
        }
        case REGISTRY_OPERATION.CANCEL_NOT_COMPLETED_PAYMENTS: {
            this.discardOutstandingPayments();
            this.handleCancelConfirm();

            return;
        }
        case REGISTRY_OPERATION.DELETE_ROW: {
            this.deletePayments();
            this.handleCancelConfirm();

            return;
        }
        case REGISTRY_OPERATION.PAY: {
            this.registryPaymentPay();
            this.handleCancelConfirm(data);
            return;
        }
        case REGISTRY_OPERATION.CANCEL: {
            this.cancelRegistryPayment();
            this.handleCancelConfirm(data);
            return;
        }

        case REGISTRY_OPERATION.PAY_REGISTRY_WITH_ACT_DATE_CONFIRM: {
            this.transferRegistryToPay({
                invalidApplicationDateConfirmed: true,
                checkDuplicate: false,
            });
            this.handleCancelConfirm();
            return;
        }

        case REGISTRY_OPERATION.PAY_REGISTRY_PAYMENT_WITH_ACT_DATE_CONFIRM: {
            this.registryPaymentPay({
                invalidApplicationDateConfirmed: true,
            });
            this.handleCancelConfirm();
            return;
        }
        default:
            return;
        }
    };

    openSearchContractor = () => {
        this.setState({
            isOpenSearchContractor: true,
        });
    };

    get mainOptions() {
        const {
            card: {
                status,
                archived,
                paymentsDeclineCount,
                paymentsErrorCount,
                paymentsTotalCount,
                paymentsContractorDeclinedCount,
                canceledCount,
                hasStartDeletingPayments,
                orderContractPaymentType,
            },
            registryPayments,
            progressImport,
        } = this.props;

        const calculatorOptions = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ ?
            [REGISTRY_CARD_OPTIONS.CALCULATOR] : [];

        if ([NM_OPERATOR].includes(this.role)) {
            return {
                tablet: [
                    REGISTRY_CARD_OPTIONS.DOWNLOAD_REGISTRY,
                    ...calculatorOptions,
                ],
                mobile: [
                    REGISTRY_CARD_OPTIONS.DOWNLOAD_REGISTRY,
                    ...calculatorOptions,
                ],
            };
        }

        const payActionsDisabled = ![
            REGISTRY_STATUS_DICT.FOR_PAYMENT.VALUE,
            REGISTRY_STATUS_DICT.NOT_PAID.VALUE,
            REGISTRY_STATUS_DICT.PARTIALLY_PAID.VALUE,
        ].includes(status) || archived;

        const option = {
            payNotCompleted: {
                key: REGISTRY_OPERATION.PAY_NOT_COMPLETED_PAYMENTS,
                text: this.localization.payNotCompleted,
                value: REGISTRY_OPERATION.PAY_NOT_COMPLETED_PAYMENTS,
                disabled: payActionsDisabled,
            },
            cancelNotCompleted: {
                key: REGISTRY_OPERATION.CANCEL_NOT_COMPLETED_PAYMENTS,
                text: this.localization.cancelNotCompleted,
                value: REGISTRY_OPERATION.CANCEL_NOT_COMPLETED_PAYMENTS,
                disabled: payActionsDisabled,
            },
            pay: {
                disabled: archived || progressImport,
            },
        };

        const payNotCompleted = (
            !this.isClientArchived &&
            [ADMIN, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role) &&
            [REGISTRY_STATUS_DICT.PARTIALLY_PAID.VALUE,
                REGISTRY_STATUS_DICT.NOT_PAID.VALUE,
                REGISTRY_STATUS_DICT.FOR_PAYMENT.VALUE,
            ].includes(status)) && (
            paymentsDeclineCount
            || paymentsErrorCount
            || paymentsContractorDeclinedCount
        ) &&
        !hasStartDeletingPayments &&
        paymentsTotalCount !== canceledCount &&
        ![NM_COORDINATOR, NM_OPERATOR].includes(this.role)
            ? [option.payNotCompleted] :
            [];

        const cancelNotCompleted = !hasStartDeletingPayments && (
            paymentsDeclineCount
            || paymentsErrorCount
            || paymentsContractorDeclinedCount
        ) &&
        [
            ADMIN,
            NM_MANAGER,
            CLIENT_ADMIN,
            PROJECT_MANAGER,
        ].includes(this.role) ?
            [option.cancelNotCompleted] :
            [];

        const pay = status === REGISTRY_STATUS_DICT.DRAFT.VALUE &&
        registryPayments.length && ![NM_COORDINATOR, NM_MANAGER, NM_CHIEF_ACCOUNTANT, CLIENT_ACCOUNTANT, OBJECT_MANAGER, FOREMAN, NM_OPERATOR].includes(this.role) ?
            [REGISTRY_CARD_OPTIONS.GO_REGISTRY_PAY] : [];

        return {
            other: [
                ...payNotCompleted,
                ...cancelNotCompleted,
            ],
            tablet: [
                REGISTRY_CARD_OPTIONS.DOWNLOAD_REGISTRY,
                REGISTRY_CARD_OPTIONS.ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT,
                ...calculatorOptions,
                ...payNotCompleted,
                ...cancelNotCompleted,
            ],
            mobile: [
                ...pay,
                REGISTRY_CARD_OPTIONS.DOWNLOAD_REGISTRY,
                REGISTRY_CARD_OPTIONS.ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT,
                ...calculatorOptions,
                ...payNotCompleted,
                ...cancelNotCompleted,
            ],
        };
    }

    renderDropdown = () => {
        return (
            <ContextMenuMobile
                size="xl"
                mediaQueries={HEADER_MEDIA_QUERIES}
                configOptions={this.mainOptions}
                onClickItem={(option) => {
                    this.onClickHeaderContextMenuItem(option);
                }}
            />
        );
    };

    registryPaymentExport = () => {
        const {
            registryPayments,
            addFinanceExportTask,
            card: {
                documentTablesEnabled,
            },
        } = this.props;

        const {notDataOfDownloadRegistry} = this.localization;

        if (!registryPayments.length) {
            return toastError(notDataOfDownloadRegistry);
        }

        addFinanceExportTask({
            uploadType: documentTablesEnabled ? UPLOAD_TYPE.REGISTRY_EXPORT_WITH_TABLE.value : UPLOAD_TYPE.REGISTRY_EXPORT.value,
            fromClientId: this.clientId,
            registryId: this.registryId,
        });
    };

    addRegistryPaymentsDocumentsExport = () => {
        const {addRegistryPaymentsDocumentsExport, registryPayments} = this.props;

        if (registryPayments.length === 0) {
            toastError("В реестре отсутствуют документы для выгрузки");

            return;
        }

        addRegistryPaymentsDocumentsExport({
            clientId: this.clientId,
            registryId: this.registryId,
        });
    };

    renderAddRegistryPaymentsExportButton = () => {
        if ([NM_OPERATOR].includes(this.role)) {
            return null;
        }

        return (
            <NmButton
                onClick={this.addRegistryPaymentsDocumentsExport}
                color="light-green"
                size="xl"
                children="Выгрузить документы"
            />
        );
    };

    renderDownloadBtn = () => {
        const {downloadRegistry} = this.localization;

        return (
            <NmButton
                onClick={this.registryPaymentExport}
                size="xl"
                color="light-green"
                children={downloadRegistry}
            />
        );
    };

    checkUnavailableTemplates = () => {
        const {getDocumentsCustomTemplateReplacementsForUnavailableList, card} = this.props;

        // Проверяем на недоступные шаблоны
        getDocumentsCustomTemplateReplacementsForUnavailableList({
            clientId: this.clientId,
            paymentsRegistryId: this.registryId,
            paymentType: card.orderContractPaymentType,
            customDocumentIds: [
                card.frameContractTemplateId,
                card.orderApplicationTemplateId,
                card.actOfAcceptanceOfWorkTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем при отключенной опции "Свои шаблоны"
                if (isDisabledTemplateClientSetting) {
                    this.onOpenUnavailableTemplates();

                    return;
                }

                this.registryPay();
            },
        });
    };

    goPay = () => {
        const {card: {name}} = this.props;
        const confirmText = replacer(":name", REGISTRY_CONFIRM_TEXT.GO_REGISTRY_PAY, name);

        this.showConfirmWindow({confirmText}, REGISTRY_OPERATION.GO_REGISTRY_PAY);
    };

    renderPayButton() {
        const {
            card: {status, archived},
            registryPayments,
            progressImport,
        } = this.props;
        const {goRegistryPay} = this.localization;

        return status === REGISTRY_STATUS_DICT.DRAFT.VALUE &&
            registryPayments.length !== 0 &&
            ![NM_COORDINATOR, NM_MANAGER, NM_CHIEF_ACCOUNTANT, CLIENT_ACCOUNTANT, OBJECT_MANAGER, FOREMAN, NM_OPERATOR].includes(this.role) &&
            <NmButton
                size="xl"
                disabled={archived || progressImport}
                onClick={this.goPay}
            >
                {goRegistryPay}
            </NmButton>;
    }

    renderCalcBtn() {
        const {card: {orderContractPaymentType}} = this.props;

        if (orderContractPaymentType !== ORDER_WORK_REPORT_TYPE.SMZ) {
            return null;
        }

        return (
            <ButtonCalculator onClick={this.toggleCalc} />
        );
    }

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchRegistryPayments,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchRegistryPayments,
        );
    };

    renderStatus = ({status, confirmationErrorDetails, message}) => {
        if (!status) {
            return "-";
        }

        if (!message && status === REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE) {
            message = REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.MESSAGE;
        }

        if (message && status === REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_SIGNATURE.VALUE) {
            message = "";
        }

        const {paymentStatuses} = this.props;

        const statusText = getRegistryPaymentStatus(paymentStatuses, status);

        return (
            <RegistryPaymentError
                color={REGISTRY_PAYMENTS_STATUS_DICT[status] && REGISTRY_PAYMENTS_STATUS_DICT[status].COLOR}
                statusText={statusText}
                error={confirmationErrorDetails || message}
            />
        );
    };

    getOptions = (data) => {
        const {status, deletionStart} = data;

        const {
            payAgain: _payAgain,
            edit: _edit,
            checkFNS: _checkFNS,
            cancelPayment: _cancelPayment,
        } = this.localization;

        const {
            createActsOfAcceptanceOfWorkType,
            orderContractPaymentType,
            modificationAvailable,
        } = this.props.card;

        const option = {
            edit: {
                key: REGISTRY_OPERATION.EDIT,
                text: _edit,
                value: REGISTRY_OPERATION.EDIT,
            },
            payAgain: {
                key: REGISTRY_OPERATION.PAY,
                text: _payAgain,
                value: REGISTRY_OPERATION.PAY,
            },
            checkFNS: {
                key: REGISTRY_OPERATION.CHECK_FNS,
                text: <div className="app-link">
                    {_checkFNS}
                </div>,
                value: REGISTRY_OPERATION.CHECK_FNS,
            },
            cancelPayment: {
                key: REGISTRY_OPERATION.CANCEL,
                text: _cancelPayment,
                value: REGISTRY_OPERATION.CANCEL,
            },
            downloadAct: {
                key: REGISTRY_OPERATION.DOWNLOAD_ACT,
                text: "Акт выполненных работ",
                value: REGISTRY_OPERATION.DOWNLOAD_ACT,
            },
        };

        const edit = status === REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE || !status ?
            [option.edit] : [];

        const payAgain = [
            REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.DECLINED.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE,
        ].includes(status) &&
        ![NM_MANAGER, CLIENT_ACCOUNTANT, OBJECT_MANAGER, FOREMAN, NM_OPERATOR].includes(this.role) &&
        !this.isClientArchived &&
        !deletionStart ? [option.payAgain] : [];

        const checkFNS = status === REGISTRY_PAYMENTS_STATUS_DICT.PAID.VALUE &&
        ![
            ORDER_WORK_REPORT_TYPE.CIVIL,
            ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
        ].includes(orderContractPaymentType) ? [option.checkFNS] : [];

        const isActsOfAcceptanceOfWork = [
            CREATE_ACTS_TYPE.AUTOMATICALLY,
            CREATE_ACTS_TYPE.WITH_CONFIRMATION,
        ].includes(createActsOfAcceptanceOfWorkType);

        const downloadAct = (status === REGISTRY_PAYMENTS_STATUS_DICT.PAID.VALUE && isActsOfAcceptanceOfWork) ? [option.downloadAct] : [];

        const cancelPayment = ([
            REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_SIGNATURE.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_CONTRACTOR_CONFIRMATION.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE,
        ].includes(status)
            && [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role)) ? [option.cancelPayment] : [];

        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role)) {
            return [];
        }

        if (!modificationAvailable) {
            return [
                ...checkFNS,
                ...downloadAct,
            ];
        }

        return [
            ...edit,
            ...payAgain,
            ...checkFNS,
            ...cancelPayment,
            ...downloadAct,
        ];
    };

    submitFilter = filter => {
        this.setState({
            pageNum: 1,
            filter,
        }, this.fetchRegistryPayments);
    };

    clearFilter = () => {
        this.setState({
            pageNum: 1,
            filter: {},
        }, this.fetchRegistryPayments);
    };

    onClickHeaderContextMenuItem = (option) => {
        switch (option.value) {
        case REGISTRY_OPERATION.GO_REGISTRY_PAY:
            this.goPay();

            return;
        case REGISTRY_OPERATION.ADD_REGISTRY_PAYMENTS_DOCUMENTS_EXPORT:
            this.addRegistryPaymentsDocumentsExport();

            return;
        case REGISTRY_OPERATION.DOWNLOAD_REGISTRY:
            this.registryPaymentExport();

            return;
        case REGISTRY_OPERATION.PAY_NOT_COMPLETED_PAYMENTS: {
            this.payOutstandingRegistryPayments(this.clientId, this.registryId);

            return;
        }
        case REGISTRY_OPERATION.CANCEL_NOT_COMPLETED_PAYMENTS: {
            const {card: {name}} = this.props;
            const confirmText = replacer(":name", REGISTRY_CONFIRM_TEXT.CANCEL_NOT_COMPLETED_PAYMENTS, name);

            this.showConfirmWindow({confirmText}, REGISTRY_OPERATION.CANCEL_NOT_COMPLETED_PAYMENTS);

            return;
        }
        case REGISTRY_OPERATION.CALCULATOR: {
            this.toggleCalc();

            return;
        }
        default:
            return;
        }
    };

    onClickActionItem = (option, item = {}) => {
        const {
            registrySeqNum,
            clientId,
            contractorId,
            citizenship,
            checkLink,
        } = item;
        const {value: action} = option;
        const {downloadDocument} = this.props;

        switch (action) {
        case REGISTRY_OPERATION.EDIT: {
            const data = {
                contractorData: {contractorId},
                paymentParams: {...item},
            };

            if (item.contractorId && item.contractorPhone && phoneRegWithForeign.test(item.contractorPhone)) {
                this.setState({
                    isOpenContractorParamsEdit: true,
                    isEditRealContractor: Boolean(item.contractorId),
                    ...data,
                });
                return;
            }

            this.setState({
                isOpenSearchContractor: true,
                ...data,
            });
            return;
        }
        case REGISTRY_OPERATION.PAY: {
            this.payRegistryPayment(clientId, contractorId, citizenship, registrySeqNum);

            return;
        }
        case REGISTRY_OPERATION.CANCEL: {
            this.showCancelRegistryPaymentConfirm(clientId, contractorId, registrySeqNum);

            return;
        }
        case REGISTRY_OPERATION.CHECK_FNS: {
            openLinkByUrl(checkLink);

            return;
        }
        case REGISTRY_OPERATION.DOWNLOAD_ACT: {
            const link = `${window.location.origin}/api/documents/getActOfAcceptanceOfWorkFile/?paymentNumber=${item?.paymentNumber}`;

            return downloadDocument({
                downloadLink: link,
                isDownload: false,
            });
        }
        default:
            return;
        }
    };

    onClickContextMenuItem = ({value: action}) => {
        switch (action) {
        case REGISTRY_OPERATION.ADD_PERFORMER:
            this.openSearchContractor();

            return;
        case REGISTRY_OPERATION.INVITE_TO_NAIMIX:
            this.openInviteViaEmail();

            return;
        case REGISTRY_OPERATION.LOAD_PERFORMERS_FROM_FILE:
            this.showImportContractorsForm(true)();

            return;
        case REGISTRY_OPERATION.REFRESH_PAYMENT_POSSIBILITY:
            this.refreshPaymentPossibility();

            return;
        case REGISTRY_OPERATION.REFRESH_PERFORMERS:
            this.refreshPerformer();

            return;
        default:
            console.error("action doesn't exists");
        }
    };

    showCancelRegistryPaymentConfirm = (clientId, contractorId, registrySeqNum) => {
        const confirmData = {
            confirmText: REGISTRY_CONFIRM_TEXT.CANCEL,
        };

        this.showConfirmWindow(confirmData, REGISTRY_OPERATION.CANCEL, {registrySeqNum});
    };

    payRegistryPayment = (clientId, contractorId, citizenship, registrySeqNum) => {
        const {
            isFrameContractSigned,
            clientType,
            t,
        } = this.props;

        const onSuccess = (frameContractSigned = {}) => {
            const isShowWarning = citizenship !== citizenshipsDict.RU.value &&
                clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY &&
                !isNullOrWhitespace() &&
                !frameContractSigned.result;

            const confirmData = {
                confirmTitle: isShowWarning ? REGISTRY_CONFIRM_TEXT.PAY : "",
                confirmText: isShowWarning ? t("order-micro-job-seekers.migration-warning-msg") : REGISTRY_CONFIRM_TEXT.PAY,
            };

            this.showConfirmWindow(confirmData, REGISTRY_OPERATION.PAY, {registrySeqNum});
        };

        isFrameContractSigned({data: {contractorId, clientId}, onSuccess});
    };

    payOutstandingRegistryPayments = (clientId, registryId) => {
        const {
            getIsFrameContractSignedForContractorsOnRegistry,
            clientType,
            t,
        } = this.props;

        const onSuccess = (frameContractSigned = {}) => {
            const isShowWarning = clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY
                && frameContractSigned.result
                && !this.isIndividualEntrepreneurRegistry;

            const confirmData = {
                confirmTitle: isShowWarning ? REGISTRY_CONFIRM_TEXT.PAY_NOT_COMPLETED_PAYMENTS : "",
                confirmText: isShowWarning ? t("order-micro-job-seekers.migration-warning-msg") : REGISTRY_CONFIRM_TEXT.PAY_NOT_COMPLETED_PAYMENTS,
            };

            this.showConfirmWindow(confirmData, REGISTRY_OPERATION.PAY_NOT_COMPLETED_PAYMENTS);
        };

        getIsFrameContractSignedForContractorsOnRegistry({
            clientId,
            registryId,
            onSuccess,
        });
    };

    renderAction = (item) => {
        const {card: {archived}} = this.props;
        const options = this.getOptions(item);
        const {status} = item;

        if (archived && status !== REGISTRY_PAYMENTS_STATUS_DICT.PAID.VALUE) {
            return null;
        }

        if (!options.length) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                className="ms-md-4 ms-xxl-0"
                onClickItem={(option) => {
                    this.onClickActionItem(option, item);
                }}
            />
        );
    };

    renderRating(item) {
        const {contractorWorkQualityScore, contractorEstimatedTimeScore} = item;

        if (!(contractorWorkQualityScore && contractorEstimatedTimeScore)) {
            return "";
        }

        function checkValue(value) {
            return isNullOrWhitespace(value) ? "-" : value;
        }

        return `Рейтинг ${checkValue(contractorWorkQualityScore)}/${checkValue(contractorEstimatedTimeScore)}`;
    }

    getPaymentLink = (orderContractPaymentType) => {
        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL) {
            return LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", this.clientId);
        }

        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            return LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", this.clientId);
        }

        return LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", this.clientId);
    };

    getPaymentNumber = ({paymentNumber, orderContractPaymentType}) => {
        if (!paymentNumber) {
            return null;
        }

        const link = this.getPaymentLink(orderContractPaymentType);

        return (
            <ExtLink
                filterData={{
                    paymentNumber,
                }}
                isLoadDataTarget
                historyEnabled
                to={link}
            >
                {paymentNumber}
            </ExtLink>
        );
    };

    getRDContent = (item) => {
        const {
            contractFrameDate,
            actualFrameContract,
            contractNumber,
            frameSignDate,
            expirationDatetime,
        } = item;

        const msg = getFcStatusForRegistryItem(item);
        const isExternalDocument = Boolean(contractNumber);

        return (
            <div className="d-flex align-items-center">
                <NmAdvancedTooltip
                    trigger={
                        <div className="registry-card__fc-status">
                            {
                                actualFrameContract ?
                                    <YesOnIcon className="registry-card__fc-status-done" /> :
                                    <NoOnIcon className="registry-card__fc-status-error" />
                            }
                        </div>
                    }
                    hover
                >
                    {msg}
                </NmAdvancedTooltip>
                {
                    (contractFrameDate && !isExternalDocument) &&
                    <div className="ms-1">
                        {dateFormat(contractFrameDate, "dd.MM.yyyy")}
                    </div>
                }
                {
                    isExternalDocument &&
                    <div className="d-flex align-items-center ms-1">
                        <Text>
                            Внешний договор
                        </Text>
                        <HelpTooltip
                            className="ms-1"
                            hover={false}
                            height={18}
                            width={18}
                            text={
                                <div className="mt-1 mb-1">
                                    <div>
                                        Номер договора:
                                        {" "}
                                        {contractNumber}
                                    </div>
                                    <div className="mt-1">
                                        Дата договора:
                                        {" "}
                                        {dateFormat(expirationDatetime ? expirationDatetime : frameSignDate, "dd.MM.yyyy")}
                                    </div>
                                </div>
                            }
                            position="bottom-left"
                        />
                    </div>
                }
            </div>
        );
    };

    getShowMore = (content, title = "") => {
        return (
            <NmShowMoreText
                lines={1}
                more="Подробнее"
                anchor="blue"
                title={title}
            >
                {content || "-"}
            </NmShowMoreText>
        );
    };

    getLabels = ({item}) => {
        const {
            card: {
                orderContractPaymentType,
                documentTablesEnabled,
                ordinalNumber,
            },
        } = this.props;
        const {
            orderDescriptionFns,
            comment,
            commentForContractor,
            contractorPhone,
            workExecutionAddress,
            orderWorkDescription,
            registrySeqNum,
            orderDescription,
            contractorInn,
            smzTaxOffer,
            hasPaymentMethodCard,
            individualEntrepreneurBankRequisitesPresent,
            locatedOutsideRussia,
        } = item;
        const {isHideDescriptionInList} = this.state;

        const labelAddress = !isNullOrWhitespace(workExecutionAddress) ?
            [{label: "Адрес выполнения работ", columnOnMobile: true, text: this.getShowMore(workExecutionAddress)}] :
            [];
        const labelRD = `РД ${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]}`;
        const descriptionFNS = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ ? [
            {
                label: "Описание выполненных работ для чека ФНС России",
                columnOnMobile: true,
                fluidText: true,
                text: this.getShowMore(orderDescriptionFns),
            },
        ] : [];
        const taxOfferLabel = {
            label: "Автоматическая уплата налогов",
            text: (
                smzTaxOffer ?
                    <YesOnIcon
                        width={18}
                        height={18}
                        color={COLOR.PRIMARY_100}
                    /> :
                    <NoOnIcon
                        width={18}
                        height={18}
                        color={COLOR.NEGATIVE_100}
                    />
            ),
            alignItems: "center",
            noWrap: false,
        };

        const generalLabels = [
            {label: "Номер строки", text: registrySeqNum},
            {
                label: "Телефон",
                text: phoneFormat(contractorPhone, locatedOutsideRussia),
                classNameText: "flex align-items-center",
                textOverflowUnset: true,
                textTooltip: locatedOutsideRussia && <LocatedOutsideRussiaTooltip />,
            },
            contractorInn && {label: "ИНН", text: contractorInn},
            {label: labelRD, text: this.getRDContent(item), alignItems: "center", noWrap: false},
            {
                label: "Паспорт проверен",
                text: (
                    <ContractorPassportStatus
                        contractor={item}
                        size="md"
                        isOnlyIcon
                    />
                ),
                alignItems: "center",
                noWrap: false,
            },
            !this.isIndividualEntrepreneurRegistry && {
                label: "Средство платежа",
                text: (
                    <InstrumentPaymentIndicator
                        value={hasPaymentMethodCard}
                        size="md"
                        showLabel={false}
                    />
                ),
                alignItems: "center",
                noWrap: false,
            },
            this.isIndividualEntrepreneurRegistry && {
                label: "Платежные данные",
                text: individualEntrepreneurBankRequisitesPresent ? "р/с ИП" : "-",
            },
            !this.isIndividualEntrepreneurRegistry && taxOfferLabel,
        ];

        if (isHideDescriptionInList) {
            return generalLabels;
        }

        return [
            ...generalLabels,
            ...labelAddress,
            {
                label: "Описание работ",
                columnOnMobile: true,
                fluidText: true,
                text: this.getShowMore(orderWorkDescription),
            },
            {
                label: "Описание выполненных работ для акта",
                columnOnMobile: true,
                fluidText: true,
                text: this.getShowMore(orderDescription),
            },
            ...descriptionFNS,
            {label: "Комментарий", fluidText: true, text: this.getShowMore(comment)},
            {
                label: "Уведомление для исполнителя",
                fluidText: true,
                text: this.getShowMore(commentForContractor, "Уведомление для исполнителя"),
            },
            documentTablesEnabled && {
                label: "Таблицы работ",
                fluidText: true,
                text: (
                    <Link
                        className="registry-card__link-table-modal"
                        onClick={() => {
                            this.setState({
                                tablesModalData: {
                                    registryNum: ordinalNumber,
                                    orderContractPaymentType,
                                    payment: item,
                                },
                            });
                        }}
                    >
                        Посмотреть таблицу
                    </Link>
                ),
            },
        ];
    };

    getCards(payment, actDate, date) {
        const {
            civilContractAmount,
            orderAmountForPayment,
            orderAmount,
        } = payment;
        const {
            card: {orderContractPaymentType},
            clientProperties: {
                contractorIndividualRegistryPaymentsCommission,
            },
        } = this.props;

        const amount = orderAmount || 0;
        const contractorCommission = amount * contractorIndividualRegistryPaymentsCommission;
        const rsAmount = amount - contractorCommission;

        // НДФЛ реестр
        const isCivil = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL;

        const classNameCivilCards = isCivil ? "col-16 col-md-5 mb-xxl-5" : "col-16 col-md-4 mb-xxl-5";
        const classNameCards = isCivil || this.isIndividualEntrepreneurRegistry ?
            "col-16 col-md-5" :
            "col-16 col-md-4";
        const dateClassNameCards = `${classNameCards} ${this.isIndividualEntrepreneurRegistry ? "mb-md-5" : ""}`;
        const cardSum = isCivil ?
            [] : [
                {
                    title: this.isIndividualEntrepreneurRegistry ?
                        "Сумма по договору, ₽" :
                        "Сумма на карту, Р",
                    value: formatAmountWithNullChecking(orderAmount, "-"),
                    className: classNameCards,
                },
                this.isIndividualEntrepreneurRegistry && {
                    title: "Сумма на р/с, Р",
                    value: orderAmount ? formatAmountWithNullChecking(rsAmount) : "-",
                    className: classNameCards,
                },
            ];
        const dateCards = [
            {title: "Дата заявки", value: date, className: dateClassNameCards},
            {title: "Дата акта", value: actDate, className: dateClassNameCards},
        ];
        const statusCards = [
            {
                title: "Статус и номер оплаты",
                subTitle: this.renderStatus(payment),
                value: this.getPaymentNumber(payment),
                className: classNameCards,
                wrapValue: true,
            },
        ];
        const civilCards = isCivil ?
            [
                {
                    title: "Сумма по договору, ₽",
                    value: formatAmountWithNullChecking(civilContractAmount, "-"),
                    boldValue: true,
                    className: classNameCivilCards,
                },
                {
                    title: "На руки исполнителю, ₽",
                    value: formatAmountWithNullChecking(orderAmountForPayment, "-"),
                    boldValue: true,
                    className: classNameCivilCards,
                },
                {
                    title: "На карту исполнителю, ₽",
                    value: formatAmountWithNullChecking(orderAmount, "-"),
                    boldValue: true,
                    className: classNameCivilCards,
                },
            ] : [];

        if (this.isIndividualEntrepreneurRegistry) {
            return [
                ...cardSum,
                ...statusCards,
                ...dateCards,
            ];
        }

        return [
            ...civilCards,
            ...dateCards,
            ...cardSum,
            ...statusCards,
        ];
    }

    renderBlockedInfo = ({blocked, blockedReason, contractorNoteForClient}) => {
        return (
            <div className="d-flex align-items-center">
                {
                    blocked &&
                    <NmAdvancedTooltip
                        className="d-flex align-items-center pointer"
                        trigger={
                            <BlockedIcon
                                width={24}
                                height={24}
                                color="red"
                            />
                        }
                        hover
                    >
                        {blockedReason}
                    </NmAdvancedTooltip>
                }
                <ContractorNoteForClientTooltip
                    className="ms-2"
                    note={contractorNoteForClient}
                />
            </div>
        );
    };

    getRows() {
        const {
            registryPayments,
            userAvatarDict,
        } = this.props;
        const {selectedList} = this.state;

        return registryPayments.map((item) => {
            const registryPayment = selectedList.find(value => (value.registrySeqNum === item.registrySeqNum)) || {};
            const {
                number,
                showCheckBox,
                disabledCheckBox,
                isSelected,
            } = registryPayment;

            const {
                contractorId,
                contractorLastName,
                contractorFirstName,
                contractorPatronymic,
                contractorFio,
                orderWorkStartDate,
                orderWorkEndDate,
                applicationDate,
                errors,
                validData,
            } = item;

            const date = !isNullOrWhitespace(applicationDate) ? dateFormat(applicationDate, "dd.MM.yyyy") : "-";
            const actDate = !isNullOrWhitespace(item.actDate) ? dateFormat(item.actDate, "dd.MM.yyyy") : "-";

            return {
                ...item,
                key: item.registrySeqNum,
                number: item.registrySeqNum,
                showCheckBox,
                disabledCheckBox,
                isSelected,
                isErrors: !validData,
                avatar: (
                    <Avatar
                        addedToMyClientGroup={item.addedToMyClientGroup}
                        contractorId={contractorId}
                        base64={userAvatarDict[contractorId]}
                    />
                ),
                contentRow: (
                    <NmListCard
                        avatar={true}
                        checkbox={true}
                        alignItems="flex-end"
                        errors={errors}
                        classNameMainContent="col-16 col-xxl-7"
                        secondaryHeader={`Период работ ${dateFormat(orderWorkStartDate, "dd.MM.yyyy")}–${dateFormat(orderWorkEndDate, "dd.MM.yyyy")}`}
                        primaryHeader={getContractorName(contractorLastName, contractorFirstName, contractorPatronymic, contractorFio)}
                        primaryHeaderTooltip={this.renderBlockedInfo(item)}
                        primaryHeaderNoWrap
                        labels={this.getLabels({item, actDate, date, number})}
                        cards={this.getCards(item, actDate, date, number)}
                        cardsWithContainer={true}
                        cardsContainerClassName="col-16 col-md-16 col-xl-16 col-xxl-8 mt-md-4 mt-xxl-0"
                        cardsWrapReverse={this.isIndividualEntrepreneurRegistry}
                        actionsClassName="col-1"
                        actions={this.renderAction(item)}
                    />
                ),
            };
        });
    }

    onSelectedRows = (selectedList, isAllSelected) => {
        const {card: {orderContractPaymentType}} = this.props;

        let selectedPaymentsSum;

        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL) {
            selectedPaymentsSum = selectedList
                .filter(item => item.isSelected)
                .reduce((accum, {orderAmountForPayment}) => {
                    return accum + orderAmountForPayment;
                }, 0);
        } else {
            selectedPaymentsSum = selectedList
                .filter(item => item.isSelected)
                .reduce((accum, {orderAmount}) => {
                    return accum + orderAmount;
                }, 0);
        }

        this.setState({
            isAllSelected,
            selectedPaymentsSum,
            selectedList,
            numberSelected: selectedList.filter(item => item.isSelected).length,
        });
    };

    deleteRow = () => {
        const {card: {status}} = this.props;
        const {numberSelected} = this.state;

        if (numberSelected === 0) {
            return;
        }

        const confirmText = status === REGISTRY_STATUS_DICT.DRAFT.VALUE ?
            REGISTRY_CONFIRM_TEXT.DELETE_PAYMENTS :
            REGISTRY_CONFIRM_TEXT.CANCEL_SELECTED;

        this.showConfirmWindow({confirmText}, REGISTRY_OPERATION.DELETE_ROW);
    };

    toggleCalc = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenCalculator: !prevState.isOpenCalculator,
        }));
    };

    renderCalculator() {
        const {
            card: {
                registryPaymentSum,
                orderContractPaymentType,
            },
        } = this.props;
        const {isOpenCalculator} = this.state;

        return isOpenCalculator ?
            <DepositCalculator
                amount={registryPaymentSum}
                handleClose={this.toggleCalc}
                orderContractPaymentType={orderContractPaymentType}
            /> :
            null;
    }

    handleCancelConfirm = (data) => {
        this.setState(prevState => ({
            ...prevState,
            isOpenConfirm: false,
            confirmTitle: "",
            confirmText: "",
            data: data || prevState.data,
            typeOperation: "",
            isOnlyConfirm: false,
        }));
    };

    renderConfirmWindow() {
        const {
            isOpenConfirm,
            confirmText,
            confirmTitle,
        } = this.state;
        const {t} = this.props;

        return isOpenConfirm &&
            <NmConfirmV2
                title={confirmTitle}
                content={confirmText}
                contentAlign={confirmTitle ? "left" : "center"}
                onCancel={this.handleCancelConfirm}
                onConfirm={this.confirmOperationSwitcher}
                confirmButton={t("button.confirm")}
                cancelButton={t("button.cancel")}
            />;
    }

    showImportContractorsForm = (isShow) => {
        const {
            progressImport,
            client,
        } = this.props;

        if (progressImport) {
            return;
        }

        return () => {
            this.setState({
                openImportContractorsForm: isShow,
                isInnTypeImportContractors: client.definingRegistryParameterType === DEFINING_REGISTRY_PARAMETER_TYPE.INN,
            });
        };
    };

    handleErrorPay = (errorCode, warningMsg, errorMessage) => {
        switch (errorCode) {
        case REGISTRY_PAYMENT_ERRORS.APPLICATION_DATE_IS_BEFORE_FRAME_CONTRACT_DATE_OR_IS_AFTER_WORK_START_DATE:
            this.showConfirmWindow({
                confirmText: warningMsg,
            }, REGISTRY_OPERATION.PAY_REGISTRY_WITH_ACT_DATE_CONFIRM);
            return;
        default: {
            toastError(errorMessage);
            return;
        }
        }
    };

    handlePaymentErrorPay = (errorCode, warningMsg, errorMessage) => {
        const {
            data,
        } = this.state;

        switch (errorCode) {
        case REGISTRY_PAYMENT_ERRORS.APPLICATION_DATE_IS_BEFORE_FRAME_CONTRACT_DATE_OR_IS_AFTER_WORK_START_DATE:
            this.showConfirmWindow({
                confirmText: warningMsg,
            }, REGISTRY_OPERATION.PAY_REGISTRY_PAYMENT_WITH_ACT_DATE_CONFIRM, data, true);
            return;
        default: {
            toastError(errorMessage);
            return;
        }
        }
    };

    toggleTypeImportContractors = (event, {checked}) => {
        this.setState(prevState => {
            return {
                ...prevState,
                isInnTypeImportContractors: checked,
            };
        });
    };

    uploadFile = ({file}) => {
        const {
            importFromFileRegistryPayments,
            card: {
                registryId,
                documentTablesEnabled,
            },
        } = this.props;

        const {isInnTypeImportContractors} = this.state;

        const formData = new FormData();

        formData.append("mFile", file);

        importFromFileRegistryPayments({
            registryId,
            clientId: this.clientId,
            documentTablesEnabled,
            definingRegistryParameterType: isInnTypeImportContractors ? DEFINING_REGISTRY_PARAMETER_TYPE.INN : DEFINING_REGISTRY_PARAMETER_TYPE.PHONE,
            file: formData,
            onSuccess: () => {
                this.showImportContractorsForm(false)();
                this.getTasks();
            },
            onError: () => {
                this.showImportContractorsForm(false)();
            },
        });
    };


    getImportFilePatterLink = () => {
        const {
            card: {
                orderContractPaymentType,
                documentTablesEnabled,
            },
        } = this.props;
        const {isInnTypeImportContractors} = this.state;

        if (documentTablesEnabled && orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр оплат_по ИНН_Таблица.xlsx"
                : "/files/Шаблон_Реестр оплат по номеру телефона_Таблица.xlsx";
        }

        if (documentTablesEnabled && orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр оплат по ИНН_Таблица (НДФЛ).xlsx"
                : "/files/Шаблон_Реестр оплат по номеру телефона_Таблица (НДФЛ).xlsx";
        }

        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр оплат_по ИНН.xlsx"
                : "/files/Шаблон_Реестр оплат по номеру телефона.xlsx";
        }

        if (this.isIndividualEntrepreneurRegistry) {
            return isInnTypeImportContractors
                ? "/files/Шаблон_Реестр оплат ИП_по ИНН.xlsx"
                : "/files/Шаблон_Реестр оплат ИП_ по номеру телефона.xlsx";
        }

        return isInnTypeImportContractors
            ? "/files/Шаблон_Реестр оплат НДФЛ_по ИНН.xlsx"
            : "/files/Шаблон_Реестр оплат НДФЛ_ по номеру телефона.xlsx";
    };

    renderImportContractorsForm() {
        const {progressImportUpload} = this.props;
        const {
            openImportContractorsForm,
            isInnTypeImportContractors,
        } = this.state;

        return openImportContractorsForm &&
            <ImportFromFilePatternV2
                patternLink={this.getImportFilePatterLink()}
                isDefiningParam={true}
                definingParamChecked={isInnTypeImportContractors}
                onChangeDefiningParam={this.toggleTypeImportContractors}
                onSubmit={this.uploadFile}
                onClose={this.showImportContractorsForm(false)}
                progress={progressImportUpload}
                templateText={
                    isInnTypeImportContractors
                        ? "предложенному шаблону по ИНН"
                        : "предложенному шаблону по номерам телефона"
                }
            />;
    }

    get cardInfo() {
        const {
            card: {
                registryPaymentSum,
                registryPaymentTotalSum,
            },
            clientProperties: {
                depositDistributedByObjects,
            },
            availableForPayments,
        } = this.props;
        const {
            selectedPaymentsSum,
        } = this.state;
        const {
            registrySum,
            neededDeposit,
            sumSelectedPaymentsLabel,
        } = this.localization;

        return [
            {
                title: sumSelectedPaymentsLabel,
                value: `${formatAmountWithNullChecking(selectedPaymentsSum)} ₽`,
                className: "col-xl-4 col-xxl-2",
            },
            {
                title: registrySum,
                value: `${formatAmountWithNullChecking(registryPaymentSum)} ₽`,
                className: "col-xl-4 col-xxl-2",
            },
            {
                title: neededDeposit,
                value: `${formatAmountWithNullChecking(registryPaymentTotalSum)} ₽`,
                className: "col-xl-4 col-xxl-2",
            },
            {
                title: "Доступно для выплат по объекту",
                value: `${formatAmountWithNullChecking(availableForPayments)} ₽`,
                className: "col-xl-4 col-xxl-2",
                isVisible: depositDistributedByObjects,
            },
        ];
    }

    renderSearchPerformer() {
        const {card: {orderContractPaymentType}} = this.props;
        const {isOpenSearchContractor, paymentParams = {}} = this.state;

        return isOpenSearchContractor &&
            <RegistryStepSearchContractor
                isEdit={paymentParams.registrySeqNum}
                paymentParams={paymentParams}
                onClose={this.closeContractorParamsEdit}
                clientId={this.clientId}
                orderContractPaymentType={orderContractPaymentType}
            />;
    }

    openInviteViaEmail = () => {
        this.setState(prevState => ({
            ...prevState,
            isInviteViaEmailOpen: !prevState.isInviteViaEmailOpen,
        }));
    };

    renderInvitePerformerToNaimix() {
        const {isInviteViaEmailOpen} = this.state;

        return isInviteViaEmailOpen &&
            <InvitePerformerToNaimix
                isRegistryPayments={true}
                close={this.openInviteViaEmail}
                clientId={this.clientId}
            />;
    }

    closeContractorParamsEdit = () => {
        this.setState({
            isOpenContractorParamsEdit: false,
            isOpenSearchContractor: false,
            contractorData: {},
            paymentParams: {},
            isEditRealContractor: false,
        });
    };

    onHideDescriptionInList = () => {
        this.setState(prevState => ({
            ...prevState,
            isHideDescriptionInList: !prevState.isHideDescriptionInList,
        }));
    };

    getAccessForPerformers = () => ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role);

    renderEditPopup() {
        const {
            isOpenContractorParamsEdit,
            contractorData,
            paymentParams,
            isEditRealContractor,
        } = this.state;

        return isOpenContractorParamsEdit &&
            <RegistryEditContractorPayment
                isEditRealContractor={isEditRealContractor}
                contractorData={contractorData}
                paymentParams={paymentParams}
                onClose={this.closeContractorParamsEdit}
                isEdit
            />;
    }

    renderDuplicatePaymentsList() {
        const {
            payModel,
        } = this.state;

        if (payModel.length === 0) {
            return null;
        }

        return (
            <RegistryDuplicatePaymentsList
                clientId={this.clientId}
                registryId={this.registryId}
                data={payModel}
                close={this.closeDuplicatePaymentsList}
                isCard
                handleError={this.handleErrorPay}
            />
        );
    }

    renderProjectInfo = ({projectName, projectId, clientId, projectAvailable}) => {
        if (!projectId) {
            return "-";
        }

        if (!projectAvailable) {
            return projectName;
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId || this.clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    renderObjectInfo = ({objectName, projectId, objectId, clientId, objectAvailable}) => {
        if (!objectId) {
            return "-";
        }

        if (!objectAvailable) {
            return objectName;
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    getSubContextMenuOptions = () => {
        if ([NM_OPERATOR].includes(this.role)) {
            return null;
        }

        const {
            card: {
                status,
                archived,
                documentTablesEnabled,
            },
            progressImport,
        } = this.props;
        const isShowButton = !archived && !this.isClientArchived && this.getAccessForPerformers();

        const addPerformer = isShowButton && !documentTablesEnabled &&
        status === REGISTRY_STATUS_DICT.DRAFT.VALUE ?
            [REGISTRY_CARD_OPTIONS.ADD_PERFORMER] :
            [];

        const loadPerformersFromFile = isShowButton &&
        status === REGISTRY_STATUS_DICT.DRAFT.VALUE ?
            [{...REGISTRY_CARD_OPTIONS.LOAD_PERFORMERS_FROM_FILE, disabled: progressImport}] : [];

        const inviteViaEmail = isShowButton && !documentTablesEnabled ?
            [REGISTRY_CARD_OPTIONS.INVITE_TO_NAIMIX] : [];

        return {
            desktop: [
                REGISTRY_CARD_OPTIONS.REFRESH_PAYMENT_POSSIBILITY,
                REGISTRY_CARD_OPTIONS.REFRESH_PERFORMERS,
            ],
            mobile: [
                FILTER_OPTION,
                ...addPerformer,
                ...loadPerformersFromFile,
                ...inviteViaEmail,
                REGISTRY_CARD_OPTIONS.REFRESH_PAYMENT_POSSIBILITY,
                REGISTRY_CARD_OPTIONS.REFRESH_PERFORMERS,
            ],
            other: [
                ...addPerformer,
                ...loadPerformersFromFile,
                ...inviteViaEmail,
                REGISTRY_CARD_OPTIONS.REFRESH_PAYMENT_POSSIBILITY,
                REGISTRY_CARD_OPTIONS.REFRESH_PERFORMERS,
            ],
        };
    };

    onClickMobileDropdownItem = ({value: action}) => {
        switch (action) {
        case REGISTRY_OPERATION.DELETE_ROW:
            this.deleteRow();

            return;
        case REGISTRY_OPERATION.HIDE_DESCRIPTION:
            this.onHideDescriptionInList();

            return;
        default:
            return;
        }
    };

    getMassAction = () => {
        const {card} = this.props;
        const {numberSelected} = this.state;

        if (card.status === REGISTRY_STATUS_DICT.DRAFT.VALUE) {
            return {
                key: REGISTRY_OPERATION.DELETE_ROW,
                text: "Удалить строку",
                value: REGISTRY_OPERATION.DELETE_ROW,
                disabled: numberSelected === 0,
            };
        }

        if (
            card.status !== REGISTRY_STATUS_DICT.DRAFT.VALUE &&
            [
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                PROJECT_MANAGER,
            ].includes(this.role)
        ) {
            return {
                key: REGISTRY_OPERATION.DELETE_ROW,
                text: "Отменить выплаты",
                value: REGISTRY_OPERATION.DELETE_ROW,
                disabled: numberSelected === 0,
            };
        }

        return {
            text: "",
            key: "",
            value: "",
        };
    };

    getMobileDropdownMassActionOptions = () => {
        if ([NM_OPERATOR].includes(this.role)) {
            return null;
        }

        const {isHideDescriptionInList} = this.state;

        const actions = [
            {
                key: REGISTRY_OPERATION.HIDE_DESCRIPTION,
                text: isHideDescriptionInList ? "Показать описание работ" : "Скрыть описание работ",
                value: REGISTRY_OPERATION.HIDE_DESCRIPTION,
            },
        ];

        const massAction = this.getMassAction();

        if (massAction.text) {
            actions.push(massAction);
        }

        return actions;
    };

    getHeaderControlsByMediaQueries = () => {
        return {
            mobile: {
                render: () => this.renderDropdown(),
            },
            tablet: {
                render: () => {
                    return (
                        <>
                            {this.renderPayButton()}
                            {this.renderDropdown()}
                        </>
                    );
                },
            },
            other: {
                render: () => {
                    return (
                        <>
                            {this.renderDownloadBtn()}
                            {this.renderAddRegistryPaymentsExportButton()}
                            {this.renderCalcBtn()}
                            {this.renderPayButton()}
                            {this.renderDropdown()}
                        </>
                    );
                },
            },
        };
    };

    onCloseUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: false,
        });
    };

    onOpenUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: true,
        });
    };

    renderUnavailableTemplates = () => {
        const {isOpenUnavailableTemplates} = this.state;

        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_PAYMENTS}
                onClose={this.onCloseUnavailableTemplates}
                onSubmit={this.registryPay}
                header="Передача реестра в оплату"
                submitBtnContent="Передать в оплату"
            />
        );
    };

    renderPaymentDocumentTables = () => {
        const {tablesModalData} = this.state;

        if (isEmpty(tablesModalData)) {
            return null;
        }

        return (
            <RegistryPaymentDocumentTablesModal
                data={tablesModalData}
                onClose={() => {
                    this.setState({
                        tablesModalData: {},
                    });
                }}
            />
        );
    };

    render() {
        const {
            card: {
                ordinalNumber,
                status,
                archived = "false",
                description,
                creatorLastName,
                creatorFirstName,
                creatorPatronymic,
                name,
                createActsOfAcceptanceOfWorkType,
                orderContractPaymentType,
                orderAmountCalculationMethod,
                actOfAcceptanceOfWorkTemplateId,
                actOfAcceptanceOfWorkTemplateName,
                actOfAcceptanceOfWorkTemplatePdfDownloadLink,
                frameContractTemplateId,
                frameContractTemplateName,
                frameContractTemplatePdfDownloadLink,
                orderApplicationTemplateId,
                orderApplicationTemplateName,
                orderApplicationTemplatePdfDownloadLink,
                documentTablesEnabled,
                frameContractByObjectTemplate,
                paymentMethod,
                modificationAvailable,
            },
            card,
            clientProperties,
            totalPages,
            totalCount,
            discardPaymentsTasks,
            progressImport,
            progressCard,
            progressList,
            registryPayments,
        } = this.props;
        const {
            processUpdateCard,
            processUpdateList,
            pageNum,
            pageSize,
            numberSelected,
            isHideDescriptionInList,
        } = this.state;
        const {title} = this.localization;

        const link = LINK_CLIENT_REGISTRY_PAYMENTS_LIST.replace(":clientId", this.clientId).replace(":archived", archived).replace("/:paymentNumberFilter?", "");
        const contractTypeText = ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType];

        return (
            <NmPageCard
                header={
                    <NmPageCardHeader
                        mediaQueries={HEADER_MEDIA_QUERIES}
                        to={link}
                        status={status}
                        statusLoading={
                            (processUpdateCard || processUpdateList || progressImport) ?
                                <div className="registry-card__updating">
                                    <Loader
                                        className="registry-card__updating-loader"
                                        active
                                        inline
                                        size="tiny"
                                    />
                                    {progressImport ? "Выполняется загрузка файла..." : "Идет обновление..."}
                                </div> :
                                null
                        }
                        statusDict={REGISTRY_STATUS_DICT}
                        content={`${title} №${ordinalNumber || ""}`}
                        controlsByMediaQueries={modificationAvailable && this.getHeaderControlsByMediaQueries()}
                    />
                }
                isLoaded={progressCard && !processUpdateCard}
                description={
                    <div className="row">
                        <div className="col-16 col-xxl-9">
                            <Text.Title
                                level="4"
                                className="registry-card__name"
                            >
                                {name}
                            </Text.Title>
                            {
                                !isNullOrWhitespace(getFullName(creatorLastName, creatorFirstName, creatorPatronymic)) &&
                                <NmLabelText
                                    type="page"
                                    className="registry-card__name"
                                    label="Ответственный"
                                    text={getFullName(creatorLastName, creatorFirstName, creatorPatronymic)}
                                />
                            }
                            <NmLabelText
                                type="page"
                                label="Проект"
                                text={this.renderProjectInfo(card)}
                            />
                            <NmLabelText
                                type="page"
                                label="Объект"
                                text={this.renderObjectInfo(card)}
                            />
                            {
                                contractTypeText &&
                                <NmLabelText
                                    type="page"
                                    label={`Заключать отдельный договор ${contractTypeText} на объекте`}
                                    text={frameContractByObjectTemplate ? "Да" : "Нет"}
                                />
                            }
                            <Text
                                level="3"
                                className="registry-card__description"
                            >
                                {description}
                            </Text>
                            <NmLabelText
                                type="page"
                                label="Формирование актов"
                                className="registry-card__act"
                                text={getCreateTypeDescription(createActsOfAcceptanceOfWorkType)}
                            />
                            <NmLabelText
                                type="page"
                                label="Шаблон договора"
                                className="registry-card__act"
                                textTitle={frameContractTemplateName}
                                text={
                                    <OrderTemplateInfo
                                        id={frameContractTemplateId}
                                        linkName={frameContractTemplateName}
                                        link={frameContractTemplatePdfDownloadLink}
                                    />
                                }
                            />
                            <NmLabelText
                                type="page"
                                label="Шаблон заявки"
                                className="registry-card__act"
                                textTitle={orderApplicationTemplateName}
                                text={
                                    <OrderTemplateInfo
                                        id={orderApplicationTemplateId}
                                        linkName={orderApplicationTemplateName}
                                        link={orderApplicationTemplatePdfDownloadLink}
                                    />
                                }
                            />
                            <NmLabelText
                                type="page"
                                label="Шаблон акта"
                                className="registry-card__act"
                                textTitle={actOfAcceptanceOfWorkTemplateName}
                                text={
                                    <OrderTemplateInfo
                                        id={actOfAcceptanceOfWorkTemplateId}
                                        linkName={actOfAcceptanceOfWorkTemplateName}
                                        link={actOfAcceptanceOfWorkTemplatePdfDownloadLink}
                                    />
                                }
                            />
                            <NmLabelText
                                type="page"
                                label="Договор"
                                text={contractTypeText}
                            />
                            {
                                orderContractPaymentType !== ORDER_WORK_REPORT_TYPE.INDIVIDUAL &&
                                <NmLabelText
                                    type="page"
                                    label="Способ проведения оплат"
                                    text={PAYMENT_METHOD_DICT[paymentMethod]}
                                />

                            }
                            <NmLabelText
                                type="page"
                                label="Сумма на карту"
                                text={ORDER_AMOUNT_CALCULATION_METHOD_DICT[orderAmountCalculationMethod]}
                            />
                            <NmLabelText
                                columnOnMobile
                                columnOnTablet
                                noWrap={false}
                                type="page"
                                label="Комиссия исполнителей"
                                text={getRegistryContractorCommission(card, clientProperties)}
                                textTooltip={
                                    <HelpTooltip
                                        info
                                        children="Ставка комиссии исполнителей за выплаты по реестру фиксируется
                                        при передаче реестра в оплатусогласно установленному в параметрах компании
                                        проценту и не подлежит изменению."
                                    />
                                }
                            />
                        </div>
                    </div>
                }
                info={
                    <NmPageInfoCardsAccordion
                        bootstrap={true}
                        cards={this.cardInfo}
                    />
                }
            >
                <NmPage
                    mediaQueries={{desktop: {minWidth: 1200}, mobile: {maxWidth: 767}}}
                    isLoaded={progressList && !progressCard && !processUpdateCard && !processUpdateList}
                    header={
                        <NmTitle
                            count={totalCount}
                            size="lg"
                        >
                            Исполнители
                        </NmTitle>
                    }
                    typeFilter="vertical"
                    filtersBase={
                        <RegistryCardFilter
                            orderContractPaymentType={orderContractPaymentType}
                            submitFilter={this.submitFilter}
                            clearFilter={this.clearFilter}
                        />
                    }
                    controlsClassName="registry-card__page-controls"
                    controlsByMediaQueries={
                        modificationAvailable &&
                        {
                            desktop: {
                                render: () => (
                                    <div className="registry-card__controls-container">
                                        {
                                            !this.isClientArchived &&
                                            status === REGISTRY_STATUS_DICT.DRAFT.VALUE &&
                                            !archived && this.getAccessForPerformers() &&
                                            <>
                                                {
                                                    !documentTablesEnabled &&
                                                    <RegistryCardButton
                                                        onClick={this.openSearchContractor}
                                                        className="registry-card__page-button"
                                                        icon={
                                                            <AddBoxIcon
                                                                width={24}
                                                                height={24}
                                                            />
                                                        }
                                                    >
                                                        Добавить исполнителя
                                                    </RegistryCardButton>
                                                }
                                                <RegistryCardButton
                                                    disabled={progressImport}
                                                    onClick={this.showImportContractorsForm(true)}
                                                    className="registry-card__page-button"
                                                    icon={
                                                        <FileDownloadIcon
                                                            width={24}
                                                            height={24}
                                                        />
                                                    }
                                                >
                                                    Загрузить список исполнителей
                                                </RegistryCardButton>
                                            </>
                                        }
                                        {
                                            !archived && !this.isClientArchived && this.getAccessForPerformers() &&
                                            !documentTablesEnabled &&
                                            <RegistryCardButton
                                                onClick={this.openInviteViaEmail}
                                                className="registry-card__page-button"
                                                icon={
                                                    <UserIcon
                                                        width={24}
                                                        height={24}
                                                    />
                                                }
                                            >
                                                Пригласить исполнителя в Наймикс
                                            </RegistryCardButton>
                                        }
                                    </div>
                                ),
                            },
                        }
                    }
                    contextMenuOptions={this.getSubContextMenuOptions()}
                    onClickContextMenuItem={this.onClickContextMenuItem}
                    onPaginationChange={this.handlePaginationChange}
                    currentPageNum={pageNum}
                    totalPages={totalPages}
                    totalCount={totalCount}
                    currentPageSize={pageSize}
                    onChangePageSize={this.handleChangePageSize}
                    className="registry-card"
                >
                    <Task />
                    <ByTaskUpdater
                        fetch={() => {
                            this.fetchRegistryCard();
                            this.fetchRegistryPayments();
                        }}
                        taskIds={discardPaymentsTasks}
                        dataIds={[this.jobId]}
                    />
                    {this.renderPaymentDocumentTables()}
                    {this.renderUnavailableTemplates()}
                    {this.renderImportContractorsForm()}
                    {this.renderDuplicatePaymentsList()}
                    {this.renderEditPopup()}
                    {this.renderInvitePerformerToNaimix()}
                    {this.renderSearchPerformer()}
                    {this.renderConfirmWindow()}
                    {this.renderCalculator()}
                    {
                        !registryPayments.length ?
                            <div className="registry-card__zero">
                                Реестр пуст
                            </div> :
                            <CheckboxList
                                header={
                                    (!this.isClientArchived && this.getAccessForPerformers() && modificationAvailable) &&
                                    <SelectionCountWithAction
                                        adaptiveLogic
                                        count={numberSelected}
                                        onClick={this.deleteRow}
                                        buttonContent={this.getMassAction().text}
                                    />
                                }
                                additionalActions={
                                    <NmHorizontalToggleV2
                                        noWrapLabel
                                        leftLabel="Скрыть описание работ"
                                        single
                                        onChange={this.onHideDescriptionInList}
                                        checked={isHideDescriptionInList}
                                        duplex={false}
                                    />
                                }
                                actionOptions={this.getMobileDropdownMassActionOptions()}
                                onClickActionItem={this.onClickMobileDropdownItem}
                                onSelectedRows={![NM_OPERATOR].includes(this.role) && this.onSelectedRows}
                                rows={this.getRows()}
                            />
                    }
                </NmPage>
            </NmPageCard>
        );
    }
}

export default connect(
    state => ({
        card: registriesCardSelector(state),
        totalPages: registryPaymentsTotalPagesSelector(state),
        registryPayments: registryPaymentsListSelector(state),
        progressCard: registryProgressCardSelector(state),
        progressList: registryPaymentsProgressSelector(state),
        clientCommission: getClientCommissionRateSelector(state),
        totalCount: registryPaymentsTotalCountSelector(state),
        paymentStatuses: registryPaymentStatusesDictSelector(state),
        payProcess: registryPayProcessSelector(state),
        refreshPerformerActionProcess: refreshPerformerActionProcessSelector(state),
        clientType: getClientTypeSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        discardPaymentsTasks: jobTasksRegistryDiscardPaymentsSelector(state),
        importPaymentsTasks: jobTasksRegistryPaymentsImportSelector(state),
        client: getClientCardSelector(state),
        progressImport: registryPaymentsHasInProgressSelector(state),
        agContractDate: registryPaymentsContractDateConclusionSelector(state),
        progressImportUpload: registryProgressImportFileSelector(state),
        userAvatarDict: avatarBase64ImagesListSelector(state),
        availableForPayments: availableForPaymentsSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
        registryFilterByTicket: registryFilterByTicketSelector(state),
    }),
    {
        getEnrichedRegistryById,
        getRegistryPayments,
        getContractorByIds,
        transferRegistryToPay,
        updateRegistryPaymentsStore,
        updateFieldContractorStore,
        deleteOrCancelRegistryPayments,
        registryPaymentPay,
        discardOutstandingPayments,
        updateRegistryStoreField,
        getRegistryPaymentStatusesAllForFilters,
        updateFieldsContractorPaymentPossibilityState,
        getContractorsPaymentPossibility,
        refreshContractorsPaymentPossibility,
        getRegistryPaymentStatuses,
        getIsFrameContractSignedForContractorsOnRegistry,
        isFrameContractSigned,
        registryPaymentRefreshPerformer,
        cancelRegistryPayment,
        getAllTasks,
        addFinanceExportTask,
        downloadDocument,
        addRegistryPaymentsDocumentsExport,
        registryPaymentHasInProgress,
        getDocumentsCustomTemplateReplacementsForUnavailableList,
        importFromFileRegistryPayments,
        getAvailableForPayments,
        updateRegistryPaymentStatusesStore,
    },
)(withTranslation()(RegistryCard));