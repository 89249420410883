import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import TemplatesVersions from "../../../versions";

import {usePagination} from "../../../../../../hooks/usePagination";

import {
    documentCustomTemplateLogsListSelector,
    documentCustomTemplateLogsProgressSelector,
    documentCustomTemplateLogsTotalCountSelector,
    documentCustomTemplateLogsTotalPagesSelector,
    getDocumentCustomTemplateLogs,
    updateDocumentCustomTemplateStore,
} from "../../../../../../ducks/documentsCustomTemplate";

const TemplateContractsVersions = (props) => {
    const {
        onClose,
        templateIdFilter,
    } = props;
    const dispatch = useDispatch();
    const totalPages = useSelector(documentCustomTemplateLogsTotalPagesSelector);
    const totalCount = useSelector(documentCustomTemplateLogsTotalCountSelector);
    const list = useSelector(documentCustomTemplateLogsListSelector);
    const progress = useSelector(documentCustomTemplateLogsProgressSelector);
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("bg-modal");

    useEffect(() => {
        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                templateLogs: [],
                templateLogsPageData: {},
                templateLogsTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        dispatch(getDocumentCustomTemplateLogs({
            templateIdFilter,
            pageNum,
            pageSize,
        }));
    }, [pageNum, pageSize]);


    return (
        <TemplatesVersions
            pageNum={pageNum}
            pageSize={pageSize}
            onClose={onClose}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            list={list}
            progress={progress}
        />
    );
};

export default TemplateContractsVersions;