import {useMemo, useState} from "react";
import {useDispatch} from "react-redux";

import {deleteKeyword} from "../../../../../ducks/keyword";

export const useSettingsDirectoriesKeywordsAction = () => {
    const [editData, setEditData] = useState(null);
    const [confirmData, setConfirmData] = useState(null);
    const [usageCountSort, setUsageCountSort] = useState("DESC");

    const dispatch = useDispatch();

    const onClickSort = ({sortType}) => {
        setUsageCountSort(sortType);
    };

    const onOpenEdit = (keywordData = {}, isEdit = false) => {
        setEditData({
            isOpenEditModal: true,
            keywordData,
            isEdit,
        });
    };

    const sortOptions = useMemo(() => {
        return [
            {
                key: "fio",
                value: "fio",
                sortType: usageCountSort,
                text: "По количеству применений",
                asc: "ASC",
                desc: "DESC",
            },
        ];
    }, [usageCountSort]);

    const onCloseEdit = () => {
        setEditData(null);
    };

    const onOpenConfirm = (keywordData) => {
        setConfirmData({
            openConfirmWindow: true,
            keywordData,
        });
    };

    const onClickCancelConfirm = () => {
        setConfirmData(null);
    };

    const onClickDeleteKeyword = () => {
        dispatch(deleteKeyword({
            keywordUuid: confirmData.keywordData.keywordUuid,
        }));

        onClickCancelConfirm();
    };

    return {
        editData,
        onClickSort,
        confirmData,
        onOpenEdit,
        onCloseEdit,
        onOpenConfirm,
        usageCountSort,
        sortOptions,
        onClickCancelConfirm,
        onClickDeleteKeyword,
    };
};