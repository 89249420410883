import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/category-specialities";

const CATEGORY_SPECIALITIES_PAGE_REQUEST = "CATEGORY_SPECIALITIES_PAGE_REQUEST";
const CATEGORY_SPECIALITIES_PAGE_SUCCESS = "CATEGORY_SPECIALITIES_PAGE_SUCCESS";
const CATEGORY_SPECIALITIES_PAGE_ERROR = "CATEGORY_SPECIALITIES_PAGE_ERROR";

const CATEGORY_SPECIALITIES_DELETE_REQUEST = "CATEGORY_SPECIALITIES_DELETE_REQUEST";
const CATEGORY_SPECIALITIES_DELETE_SUCCESS = "CATEGORY_SPECIALITIES_DELETE_SUCCESS";
const CATEGORY_SPECIALITIES_DELETE_ERROR = "CATEGORY_SPECIALITIES_DELETE_ERROR";

const CATEGORY_SPECIALITIES_UPDATE_REQUEST = "CATEGORY_SPECIALITIES_UPDATE_REQUEST";
const CATEGORY_SPECIALITIES_UPDATE_SUCCESS = "CATEGORY_SPECIALITIES_UPDATE_SUCCESS";
const CATEGORY_SPECIALITIES_UPDATE_ERROR = "CATEGORY_SPECIALITIES_UPDATE_ERROR";

const CATEGORY_SPECIALITIES_ADD_REQUEST = "CATEGORY_SPECIALITIES_ADD_REQUEST";
const CATEGORY_SPECIALITIES_ADD_SUCCESS = "CATEGORY_SPECIALITIES_ADD_SUCCESS";
const CATEGORY_SPECIALITIES_ADD_ERROR = "CATEGORY_SPECIALITIES_ADD_ERROR";

const CATEGORY_SPECIALITIES_FIND_BY_ID_REQUEST = "CATEGORY_SPECIALITIES_FIND_BY_ID_REQUEST";
const CATEGORY_SPECIALITIES_FIND_BY_ID_SUCCESS = "CATEGORY_SPECIALITIES_FIND_BY_ID_SUCCESS";
const CATEGORY_SPECIALITIES_FIND_BY_ID_ERROR = "CATEGORY_SPECIALITIES_FIND_BY_ID_ERROR";

const CATEGORY_SPECIALITIES_UPDATE_STORE = "CATEGORY_SPECIALITIES_UPDATE_STORE";

const initialState = {
    list: [],
    totalCount: 0,
    filterList: [],
    card: {},
    progress: false,
    progressCard: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case CATEGORY_SPECIALITIES_PAGE_REQUEST:
        const {pageNum, pageSize} = payload;

        return {
            ...state,
            pageNum,
            pageSize,
        };
    case CATEGORY_SPECIALITIES_PAGE_SUCCESS:
        const {totalCount, results, nameField = "list"} = payload;

        return {
            ...state,
            totalCount,
            [nameField]: results,
        };
    case CATEGORY_SPECIALITIES_FIND_BY_ID_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case CATEGORY_SPECIALITIES_FIND_BY_ID_ERROR:
        return {
            ...state,
            card: payload,
            progressCard: false,
        };
    case CATEGORY_SPECIALITIES_FIND_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progressCard: false,
        };
    case CATEGORY_SPECIALITIES_DELETE_REQUEST:
    case CATEGORY_SPECIALITIES_UPDATE_REQUEST:
    case CATEGORY_SPECIALITIES_ADD_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case CATEGORY_SPECIALITIES_DELETE_SUCCESS:
    case CATEGORY_SPECIALITIES_UPDATE_SUCCESS:
    case CATEGORY_SPECIALITIES_ADD_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case CATEGORY_SPECIALITIES_DELETE_ERROR:
    case CATEGORY_SPECIALITIES_UPDATE_ERROR:
    case CATEGORY_SPECIALITIES_ADD_ERROR:
        return {
            ...state,
            progress: false,
        };
    case CATEGORY_SPECIALITIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export const updateCategorySpecialitiesStore = (payload) => ({
    type: CATEGORY_SPECIALITIES_UPDATE_STORE,
    payload,
});

export const getCategorySpecialities = (payload) => ({
    type: CATEGORY_SPECIALITIES_PAGE_REQUEST,
    payload,
});

export const deleteCategorySpecialities = (payload) => ({
    type: CATEGORY_SPECIALITIES_DELETE_REQUEST,
    payload,
});

export const updateCategorySpecialities = (payload) => ({
    type: CATEGORY_SPECIALITIES_UPDATE_REQUEST,
    payload,
});

export const addCategorySpecialities = (payload) => ({
    type: CATEGORY_SPECIALITIES_ADD_REQUEST,
    payload,
});

export const findByIdCategorySpecialities = (payload) => ({
    type: CATEGORY_SPECIALITIES_FIND_BY_ID_REQUEST,
    payload,
});

export const categorySpecialitiesSelector = ({categorySpecialities}) => categorySpecialities;
export const categorySpecialitiesListSelector = createSelector(categorySpecialitiesSelector, ({list}) => list);
export const categorySpecialitiesFilterListSelector = createSelector(categorySpecialitiesSelector, ({filterList}) => filterList.map((item) => {
    return {
        key: item.id,
        value: item.id,
        text: item.value,
    };
}));
export const categorySpecialitiesCardSelector = createSelector(categorySpecialitiesSelector, ({card}) => card);
export const categorySpecialitiesTotalCountSelector = createSelector(categorySpecialitiesSelector, ({totalCount}) => totalCount);
export const categorySpecialitiesProgressSelector = createSelector(categorySpecialitiesSelector, ({progress}) => progress);
export const categorySpecialitiesProgressCardSelector = createSelector(categorySpecialitiesSelector, ({progressCard}) => progressCard);
export const categorySpecialitiesTotalPagesSelector = createSelector(categorySpecialitiesSelector, ({totalCount, pageSize = 0}) => getTotalPages(totalCount, pageSize));

function* getCategorySpecialitiesSaga({payload}) {
    try {
        const {
            nameField,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/page`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: CATEGORY_SPECIALITIES_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: CATEGORY_SPECIALITIES_PAGE_SUCCESS, payload: {...result, nameField}});
    } catch (e) {
        yield put({type: CATEGORY_SPECIALITIES_PAGE_ERROR, payload: e});
    }
}

function* deleteCategorySpecialitiesSaga({payload}) {
    try {
        const {
            onSuccess,
            id,
        } = payload;

        const result = yield request.delete(`${controller}/${id}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: CATEGORY_SPECIALITIES_DELETE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CATEGORY_SPECIALITIES_DELETE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: CATEGORY_SPECIALITIES_DELETE_ERROR, payload: e});
    }
}

function* updateCategorySpecialitiesSaga({payload}) {
    try {
        const {
            id,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.patch(`${controller}/${id}`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: CATEGORY_SPECIALITIES_UPDATE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CATEGORY_SPECIALITIES_UPDATE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: CATEGORY_SPECIALITIES_UPDATE_ERROR, payload: e});
    }
}

function* addCategorySpecialitiesSaga({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/add`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: CATEGORY_SPECIALITIES_ADD_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CATEGORY_SPECIALITIES_ADD_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: CATEGORY_SPECIALITIES_ADD_ERROR, payload: e});
    }
}

function* findByIdCategorySpecialitiesSaga({payload: {id}}) {
    try {
        const result = yield request.get(`${controller}/findById?id=${id}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: CATEGORY_SPECIALITIES_FIND_BY_ID_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: CATEGORY_SPECIALITIES_FIND_BY_ID_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: CATEGORY_SPECIALITIES_FIND_BY_ID_ERROR, payload: e});
    }
}

export function* saga() {
    yield all([
        takeEvery(CATEGORY_SPECIALITIES_PAGE_REQUEST, getCategorySpecialitiesSaga),
        takeEvery(CATEGORY_SPECIALITIES_DELETE_REQUEST, deleteCategorySpecialitiesSaga),
        takeEvery(CATEGORY_SPECIALITIES_UPDATE_REQUEST, updateCategorySpecialitiesSaga),
        takeEvery(CATEGORY_SPECIALITIES_ADD_REQUEST, addCategorySpecialitiesSaga),
        takeEvery(CATEGORY_SPECIALITIES_FIND_BY_ID_REQUEST, findByIdCategorySpecialitiesSaga),
    ]);
}