import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {ORDER_STATUS} from "../../../../constants/clientList";

import {
    FIELD_NAME_ORDER_LIST_ENUM,
    orderTemplatesRichList,
} from "../../../../ducks/order";

export function useOrderTemplatesFetchList({clientId, pageSize, pageNum, filter}) {
    const dispatch = useDispatch();

    const [archived, setArchived] = useState(false);

    useEffect(() => {
        fetchList();
    }, [filter, archived, pageSize, pageNum]);

    const fetchList = () => {
        dispatch(orderTemplatesRichList({
            ...filter,
            clientId,
            pageNum,
            pageSize,
            archivedFilter: archived,
            statusFilter: [ORDER_STATUS.TEMPLATE],
            nameField: FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.FIELD_LIST,
            nameFieldTotalCount : FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.TOTAL_COUNT,
        }));
    };

    return {
        archived,
        setArchived,
        fetchList,
    };
}