import React from "react";
import {withTranslation} from "react-i18next";

import {ReactComponent as CalculatorIcon} from "../../images/calculate.svg";
import NmButton from "../NmButton";

import PropTypes from "prop-types";

const ButtonCalculator = props => {
    const {
        t,
        onClick,
        className = "",
        size = "xl",
    } = props;

    return (
        <NmButton
            onClick={onClick}
            type="button"
            size={size}
            className={`button-calculator ${className}`}
            icon={<CalculatorIcon />}
            color="grey"
        >
            {t("order-list.calculator")}
        </NmButton>
    );
};

ButtonCalculator.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default withTranslation()(ButtonCalculator);