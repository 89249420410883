import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../components/ActualComponents/Text";
import {ReactComponent as MaintenanceIcon} from "../../../images/maintenance.svg";

import bem from "../../../utils/bem";

import {getMaintenanceNoticePublished} from "../../../ducks/systemSetting";

import "./style.sass";

const MaintenanceBanner = () => {
    const isPublished = useSelector(({systemSetting}) => systemSetting.isMaintenanceNoticePublished);

    const dispatch = useDispatch();

    const [block, element] = bem("maintenance-banner");

    useEffect(() => {
        dispatch(getMaintenanceNoticePublished());
    }, []);

    return (
        isPublished &&
        <div className={block()}>
            <MaintenanceIcon className="me-4 flex-shrink-0" />
            <Text level="3">
                На платформе в данный момент ведутся технические работы, возможно замедление загрузки страниц
            </Text>
        </div>
    );
};

export default MaintenanceBanner;