import {
    addKedoDirectoryPosition,
    deleteKedoDirectoryPosition,
    getKedoDirectoryPositionsPage,
    updateKedoDirectoryPosition,
} from "../../../../../../kedo/directories/actionCreators";

const controller = "/adm/settings/directories/kedo/positions";

export const getSettingsKedoPositionsPage = (payload) => {
    return getKedoDirectoryPositionsPage({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const updateSettingsKedoPosition = (payload) => {
    return updateKedoDirectoryPosition({
        url: `${controller}/update`,
        ...payload,
    });
};

export const addSettingsKedoPosition = (payload) => {
    return addKedoDirectoryPosition({
        url: `${controller}/add`,
        ...payload,
    });
};

export const deleteSettingsKedoPosition = (payload) => {
    return deleteKedoDirectoryPosition({
        url: `${controller}/delete`,
        ...payload,
    });
};