import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router";

import Tabs from "../../../../../components/ActualComponents/Tabs";
import {RecruitmentVacancyDetails} from "../../../../../components/Recruitment/VacancyDetails";
import {RECRUITMENT_FUNNEL_STATUS_FILTER} from "../../../../../constants/recruitment";
import {
    bffRecruitmentVacancyCandidatesCountersSelector,
    bffRecruitmentVacancyCandidatesFunnelFiltersSelector,
    bffRecruitmentVacancyCardSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    updateRecruitmentVacancyCandidatesStore,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    updateRecruitmentVacancyResponsesStore,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import RecruitmentVacancyResponseList from "../response-list";
import RecruitmentVacancyCandidateList from "../сandidate-list";

import {useRecruitmentVacancyDescriptionFetch} from "./hooks/useFetch";

import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD_INFO,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD_INFO,
} from "../../../../../constants/links";

export const RecruitmentVacanciesDescription = (props) => {
    const {
        match: {
            params: {
                clientId,
                vacancyId,
            },
        },
    } = props;
    const card = useSelector(bffRecruitmentVacancyCardSelector);
    const funnelFilters = useSelector(bffRecruitmentVacancyCandidatesFunnelFiltersSelector);
    const counters = useSelector(bffRecruitmentVacancyCandidatesCountersSelector);

    const dispatch = useDispatch();

    const {
    } = useRecruitmentVacancyDescriptionFetch({
        clientId,
        vacancyId,
    });

    const clearCandidatesListData = () => {
        dispatch(updateRecruitmentVacancyCandidatesStore({
            list: [],
            pageData: {},
        }));
    };

    const clearResponsesListData = () => {
        dispatch(updateRecruitmentVacancyResponsesStore({
            pageData: {},
            list: [],
        }));
    };

    const getTabs = () => {
        const statusTabs = funnelFilters.map(item => {
            return {
                count: item.count,
                name: item.name,
                onClick: () => {
                    clearCandidatesListData();
                },
                link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL
                    .replace(":vacancyId", vacancyId)
                    .replace(":clientId", clientId)
                    .replace(":activeFunnelStatusFilter", item.statusId),
            };

        });

        return [
            {
                name: "Все отклики",
                onClick: () => {
                    clearResponsesListData();
                },
                link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES
                    .replace(":vacancyId", vacancyId)
                    .replace(":clientId", clientId),
                count: counters.totalResponses,
            },
            {
                name: "Новые отклики",
                onClick: () => {
                    clearResponsesListData();
                },
                link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES
                    .replace(":vacancyId", vacancyId)
                    .replace(":clientId", clientId),
                count: counters.totalNewResponses,
            },
            {
                count: counters.totalCandidates,
                name: "Все кандидаты",
                onClick: () => {
                    clearCandidatesListData();
                },
                link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL
                    .replace(":vacancyId", vacancyId)
                    .replace(":clientId", clientId)
                    .replace(":activeFunnelStatusFilter", RECRUITMENT_FUNNEL_STATUS_FILTER.ALL_CANDIDATES),
            },
            ...statusTabs,
        ];
    };

    return (
        <>
            <RecruitmentVacancyDetails
                className="mb-4 mb-md-6"
                vacancy={card}
                clientId={clientId}
            />
            <Tabs
                className="mb-4 mb-xxl-5"
                secondary
                panes={getTabs()}
            />
            <Switch>
                <Route
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD}
                    render={(props) =>
                        <RecruitmentVacancyResponseList
                            cardLink={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD_INFO}
                            {...props}
                        />
                    }
                />
                <Route
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES}
                    render={(props) =>
                        <RecruitmentVacancyResponseList
                            cardLink={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD_INFO}
                            {...props}
                        />
                    }
                />
                <Route
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD}
                    render={(props) =>
                        <RecruitmentVacancyResponseList
                            isNew={true}
                            cardLink={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD_INFO}
                            {...props}
                        />
                    }
                />
                <Route
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES}
                    render={(props) =>
                        <RecruitmentVacancyResponseList
                            isNew={true}
                            cardLink={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD_INFO}
                            {...props}
                        />
                    }
                />
                <Route
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD}
                    component={RecruitmentVacancyCandidateList}
                />
                <Route
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL}
                    component={RecruitmentVacancyCandidateList}
                />
            </Switch>
        </>
    );
};