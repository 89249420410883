import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import NmConfirmV2Content from "../../../../../components/ActualComponents/NmConfirmV2/Content";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import CheckboxList from "../../../../../components/CheckboxList";
import NmBadge from "../../../../../components/NmBadge";
import TableSelectedLabel from "../../../../../components/TableSelectedLabel";

import {getFormattedFullName} from "../../../../../utils/stringFormat";

import {EDO_DOCUMENT_STATUS} from "../../../../../constants/document-management/document-list";

export default function EdoDocumentDuplicateList(props) {
    const {
        close,
        dataDuplicate,
        submit,
        edoDocumentTypesDict,
        isRegistry = false,
        duplicatesInRegistryExists = false,
    } = props;

    const {t} = useTranslation();
    const [selectedList, setSelectedList] = useState([]);
    const [countSelected, setCountSelected] = useState(0);
    const onSubmit = () => {
        if (isRegistry && !selectedList.length) {
            submit(dataDuplicate.map(item => item.seqNum));

            return;
        }

        submit(Array.from(new Set(selectedList.filter(item => isRegistry ? !item.isSelected : item.isSelected).map(item => isRegistry ? item.seqNum : item.staff.staffId))));
    };

    const onSelectedRows = (selectedList) => {
        setSelectedList(selectedList);
        setCountSelected(selectedList.filter(value => value.isSelected).length);
    };

    function renderStatus(status) {
        const {
            MOD,
            TEXT,
        } = EDO_DOCUMENT_STATUS[status];

        return (
            <NmBadge
                mod={MOD}
                text={TEXT}
            />
        );
    }

    function getRows() {
        return dataDuplicate.map((row, index) => {
            const {
                existingDocumentName,
                createdDocumentType,
                existingDocumentState,
                createdDocumentName,
                existingDocumentType,
                staff = {},
                seqNum,
            } = row;

            const {isSelected = false} = selectedList.find(item => {
                if (isRegistry) {
                    return item.seqNum === seqNum;
                }
                return item.index === index;
            }) || {};
            const {value: createdDocumentTypeName} = edoDocumentTypesDict.find(item => item.id === createdDocumentType) || {};
            const {value: existingDocumentTypeName} = edoDocumentTypesDict.find(item => item.id === existingDocumentType) || {};

            return {
                ...row,
                index,
                key: createdDocumentType,
                showCheckBox: true,
                isSelected,
                contentRow: (
                    <NmListCard
                        onlyLabels
                        checkbox
                        classNameMainContent="col-16 col-xxl-12"
                        labels={[
                            {
                                label: "Название аналогичного существующего документа",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: existingDocumentName,
                            },
                            {
                                label: "Тип аналогичного существующего документа",
                                noWrap: false,
                                noWrapLabel: false,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                columnOnMobile: true,
                                text: existingDocumentTypeName,
                            },
                            {
                                label: "Статус аналогичного существующего документа",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: renderStatus(existingDocumentState),
                            },
                            {
                                label: "Название аналогичного формируемого документа",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: createdDocumentName,
                            },
                            {
                                label: "Тип аналогичного формируемого документа",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: createdDocumentTypeName,
                            },
                            isRegistry && {
                                label: "Номер строки реестра по формируемому документу",
                                noWrap: false,
                                noWrapLabel: false,
                                columnOnMobile: true,
                                classNameLabel: "align-items-start",
                                className: "flex-wrap align-items-start",
                                text: seqNum,
                            },
                        ]}
                        cards={[
                            {
                                title: "Получатель",
                                value: getFormattedFullName(staff.staffName),
                                className: "col-16 col-md-6 col-xxl-4 mt-md-4 mt-xxl-0",
                                boldValue: true,
                                overflowEllipseText: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }

    const getContent = () => {
        if (isRegistry) {
            return duplicatesInRegistryExists ?
                "В реестре найдены:" :
                "В реестре найдены Исполнители (получатели), которым за последний календарный месяц от лица Вашей компании уже направлялся документ с выбранным типом. Выберите из представленного перечня документы, которые необходимо направить на подпись повторно. Невыбранные документы будут удалены из реестра автоматически.";
        }

        return "Найдены документы, направленные аналогичному исполнителю в течении одного месяца. Выберите те документы, которые вы хотите направить на подпись. Невыбранные документы будут удалены.";
    };

    return (
        <NmModal
            size="lg"
            onClose={close}
            footer={
                <ApplyButtons
                    mobile="column"
                    onClose={close}
                    submit={onSubmit}
                    isHiddenCancelOnMobile
                    cancelBtnContent={t("button.cancel")}
                    submitBtnContent={isRegistry ? "Подтвердить" : "Отправить на подписание"}
                />
            }
        >
            <div className="registry-duplicate-payments-list__container">
                <NmConfirmV2Content
                    warning
                    content={getContent()}
                />
                {
                    isRegistry && duplicatesInRegistryExists &&
                    <ul>
                        <li>
                            Повторяющиеся строки с одним и тем же исполнителем (получателем). После передачи реестра на
                            подписание дубли строк будут удалены автоматически.
                        </li>
                        <li>
                            Исполнители (получатели), которым за последний календарный месяц от лица Вашей компании уже
                            направлялся документ с выбранным типом. Выберите из представленного перечня документы,
                            которые необходимо направить на подпись повторно. Невыбранные документы будут удалены из
                            реестра автоматически.
                        </li>
                    </ul>
                }
                <div className="mt-3 mt-md-5">
                    <CheckboxList
                        classNameContent="registry-duplicate-payments-list__content"
                        header={<TableSelectedLabel
                            count={countSelected}
                        />}
                        onSelectedRows={onSelectedRows}
                        rows={getRows()}
                    />
                </div>
            </div>
        </NmModal>
    );

}