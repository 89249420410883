import React from "react";

import {RECRUITMENT_CONTACTS} from "../../../constants/recruitment";
import {ReactComponent as TelegramIcon} from "../../../images/telegram.svg";
import {ReactComponent as ViberIcon} from "../../../images/viber.svg";
import {ReactComponent as WhatsappIcon} from "../../../images/whatsapp.svg";
import DictIcon from "../../ActualComponents/DictIcon";
import Text from "../../ActualComponents/Text";

import {getClassNames} from "../../../utils/classNames";
import {openLinkByUrl} from "../../../utils/downloadBlob";
import {isLastElementInArray} from "../../../utils/objectHelper";

import {COLOR} from "../../../constants/color";;

const ICONS = {
    WHATSAPP: WhatsappIcon,
    TELEGRAM: TelegramIcon,
    VIBER: ViberIcon,
};

export const RecruitmentContacts = (props) => {
    const {
        contacts,
    } = props;

    return (
        <div className="d-flex align-items-center">
            {
                contacts.map((item, index) => {
                    const isLast = isLastElementInArray(contacts, index);

                    return (
                        <div
                            style={{cursor: "pointer"}}
                            key={item.id}
                            title="Открыть в новой вкладке"
                            className={
                                getClassNames([
                                    "d-flex align-items-center",
                                    !isLast && "me-1",
                                ])
                            }
                            onClick={() => {
                                if (!item.url) {
                                    return;
                                }

                                openLinkByUrl(item.url);
                            }}
                        >
                            <DictIcon
                                dict={ICONS}
                                name={item.type}
                                {...props}
                            />
                            <Text
                                color={COLOR.PASSIVE_100}
                                level="3"
                                className="ms-1"
                            >
                                {RECRUITMENT_CONTACTS[item.type]}
                            </Text>
                        </div>

                    );
                })
            }
        </div>
    );
};