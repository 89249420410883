import React, {useState} from "react";

import LoginCookie from "../../containers/login/cookie";
import LoginFooter from "../../containers/login/footer";
import LoginHeader from "../../containers/login/header";
import {history} from "../../store/configureStore";
import NmButton from "../NmButton";

import {ls} from "../../utils/localstorage";

import {LINK_HOME} from "../../constants/links";

import "./style.sass";

const NotFoundPage = () => {
    const [isAgreeCookieSave, setAgreeCookieSave] = useState(ls("isAgreeCookieSave") === "true");

    const goLogin = () => {
        history.push(LINK_HOME);
    };

    const cookieAgree = () => {
        setAgreeCookieSave(true);

        ls("isAgreeCookieSave", true);
    };

    return (
        <div className="not-found">
            <LoginHeader />
            <div className="not-found__content">
                <div className="not-found__main-content">
                    <div className="not-found__status">
                        404
                    </div>
                    <div className="not-found__value">
Упс! Страница не найдена!
                    </div>
                    <div className="not-found__sub-value">
                        Данной страницы не существует.
                        Возможно, она была перемещена, удалена или переименована.
                        А, может быть, она просто временно недоступна. Попробуйте зайти позже.
                    </div>
                    <NmButton
                        openNaimix
                        color="open-naimix-primary"
                        size="xl"
                        onClick={goLogin}
                    >
                        Перейти на главную
                    </NmButton>
                </div>
            </div>
            <LoginFooter />
            {
                !isAgreeCookieSave &&
                <LoginCookie handleClose={cookieAgree} />
            }
        </div>
    );
};

export default NotFoundPage;