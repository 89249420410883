import {useEffect,useState} from "react";
import {useDispatch} from "react-redux";

import {handleFormString} from "../../../../../utils/stringHelper";

import {getEdmSubdivisionsList} from "../../../../../ducks/edmSubDivision";

export function useStructuralUtilsFetchList({pageNum, pageSize, filterDto, clientId}) {
    const [loadingList, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterDto]);

    const fetchList = () => {
        setLoading(true);

        const {valueFilter} = filterDto;

        dispatch(getEdmSubdivisionsList({
            clientId,
            pageNum,
            pageSize,
            valueFilter: handleFormString(valueFilter),
            getResult: () => {
                setLoading(false);
            },
        }));
    };

    return {
        loadingList,
        fetchList,
    };
}