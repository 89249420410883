export const OBJECTS_FILTERS = [
    {
        row: [
            {
                component: "input",
                placeholder: "Введите название",
                label: "Название",
                name: "nameSubstringFilter",
            },
        ],
    },
    {
        row: [
            {
                component: "input",
                placeholder: "Введите ID объекта",
                label: "ID объекта",
                name: "extIdFilter",
            },
        ],
    },
];