import React from "react";
import {useTranslation} from "react-i18next";

import NmButton, {TNmButtonSize} from "../../NmButton";

import bem from "../../../utils/bem";

import "./style.sass";

interface IFilterButtons {
    onSearch?: () => void,
    onClear: () => void,
    className?: string,
    size?: TNmButtonSize,
    searchText?: string,
    clearText?: string,
    formId?: string
}

const FilterButtonsV2 = (props: IFilterButtons) => {
    const {
        searchText,
        clearText,
        onSearch,
        onClear,
        size,
        className,
    } = props;
    const {t} = useTranslation();
    const [block, element] = bem("filter-buttons-v2", className);

    const isSearchButton = Boolean(onSearch);

    return (
        <div className={block()}>
            {
                isSearchButton &&
                <NmButton
                    size={size}
                    color="light-green"
                    onClick={onSearch}
                    className={element("submit")}
                >
                    {searchText || t("button.find")}
                </NmButton>
            }
            <NmButton
                size={size}
                color="grey"
                onClick={onClear}
                className={element("clear", {onlyClear: !isSearchButton})}
            >
                {clearText || t("button.clear")}
            </NmButton>
        </div>
    );
};

export default FilterButtonsV2;