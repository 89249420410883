import {all, call, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";
import {createSelector} from "reselect";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/mlmodels";

const GET_ML_MODEL_LIST_REQUEST = "GET_ML_MODEL_LIST_REQUEST";
const GET_ML_MODEL_LIST_SUCCESS = "GET_ML_MODEL_LIST_SUCCESS";
const GET_ML_MODEL_LIST_ERROR = "GET_ML_MODEL_LIST_ERROR";

const ADD_ML_MODEL_REQUEST = "ADD_ML_MODEL_REQUEST";
const ADD_ML_MODEL_SUCCESS = "ADD_ML_MODEL_SUCCESS";
const ADD_ML_MODEL_ERROR = "ADD_ML_MODEL_ERROR";

const ADD_FILE_TO_MODEL_REQUEST = "ADD_FILE_TO_MODEL_REQUEST";
const ADD_FILE_TO_MODEL_SUCCESS = "ADD_FILE_TO_MODEL_SUCCESS";
const ADD_FILE_TO_MODEL_ERROR = "ADD_FILE_TO_MODEL_ERROR";

const UPDATE_MODEL_FILE_REQUEST = "UPDATE_MODEL_FILE_REQUEST";
const UPDATE_MODEL_FILE_SUCCESS = "UPDATE_MODEL_FILE_SUCCESS";
const UPDATE_MODEL_FILE_ERROR = "UPDATE_MODEL_FILE_ERROR";

const DELETE_ML_MODEL_REQUEST = "DELETE_ML_MODEL_REQUEST";
const DELETE_ML_MODEL_SUCCESS = "DELETE_ML_MODEL_SUCCESS";
const DELETE_ML_MODEL_ERROR = "DELETE_ML_MODEL_ERROR";

const DELETE_MODEL_FILE_REQUEST = "DELETE_MODEL_FILE_REQUEST";
const DELETE_MODEL_FILE_SUCCESS = "DELETE_MODEL_FILE_SUCCESS";
const DELETE_MODEL_FILE_ERROR = "DELETE_MODEL_FILE_ERROR";

const SET_MAIN_MODEL_REQUEST = "SET_MAIN_MODEL_REQUEST";
const SET_MAIN_MODEL_SUCCESS = "SET_MAIN_MODEL_SUCCESS";
const SET_MAIN_MODEL_ERROR = "SET_MAIN_MODEL_ERROR";

const UPDATE_ML_MODEL_REQUEST = "UPDATE_ML_MODEL_REQUEST";
const UPDATE_ML_MODEL_SUCCESS = "UPDATE_ML_MODEL_SUCCESS";
const UPDATE_ML_MODEL_ERROR = "UPDATE_ML_MODEL_ERROR";

const DOWNLOAD_MODEL_FILE_REQUEST = "DOWNLOAD_MODEL_FILE_REQUEST";
const DOWNLOAD_MODEL_FILE_SUCCESS = "DOWNLOAD_MODEL_FILE_SUCCESS";
const DOWNLOAD_MODEL_FILE_ERROR = "DOWNLOAD_MODEL_FILE_ERROR";

const GET_ML_MODEL_FILE_LIST_REQUEST = "GET_ML_MODEL_FILE_LIST_REQUEST";
const GET_ML_MODEL_FILE_LIST_SUCCESS = "GET_ML_MODEL_FILE_LIST_SUCCESS";
const GET_ML_MODEL_FILE_LIST_ERROR = "GET_ML_MODEL_FILE_LIST_ERROR";

const initial = {
    listProgress: false,
    fileListProgress: false,
    listPageData: {},
    modelList: [],
    modelFileList: [],
    actionProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case DOWNLOAD_MODEL_FILE_REQUEST:
    case DELETE_MODEL_FILE_REQUEST:
    case ADD_FILE_TO_MODEL_REQUEST:
    case DELETE_ML_MODEL_REQUEST:
    case SET_MAIN_MODEL_REQUEST:
    case UPDATE_ML_MODEL_REQUEST:
    case UPDATE_MODEL_FILE_REQUEST:
    case ADD_ML_MODEL_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case GET_ML_MODEL_LIST_REQUEST:
        return {
            ...state,
            listPageData: payload,
            listProgress: true,
        };
    case GET_ML_MODEL_FILE_LIST_REQUEST:
        return {
            ...state,
            fileListProgress: false,
        };
    case DOWNLOAD_MODEL_FILE_SUCCESS:
    case DELETE_MODEL_FILE_SUCCESS:
    case UPDATE_MODEL_FILE_SUCCESS:
    case ADD_FILE_TO_MODEL_SUCCESS:
    case DELETE_ML_MODEL_SUCCESS:
    case SET_MAIN_MODEL_SUCCESS:
    case UPDATE_ML_MODEL_SUCCESS:
    case ADD_ML_MODEL_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case GET_ML_MODEL_LIST_SUCCESS:
        return {
            ...state,
            modelList: payload.results,
            listProgress: false,
        };
    case GET_ML_MODEL_FILE_LIST_SUCCESS:
        return {
            ...state,
            fileModelList: payload.result,
            fileListProgress: false,
        };
    case GET_ML_MODEL_FILE_LIST_ERROR:
        return {
            ...state,
            fileListProgress: false,
            error: payload,
        };
    case GET_ML_MODEL_LIST_ERROR:
        return {
            ...state,
            listProgress: false,
            error: payload,
        };
    case DOWNLOAD_MODEL_FILE_ERROR:
    case DELETE_MODEL_FILE_ERROR:
    case UPDATE_MODEL_FILE_ERROR:
    case ADD_FILE_TO_MODEL_ERROR:
    case SET_MAIN_MODEL_ERROR:
    case DELETE_ML_MODEL_ERROR:
    case UPDATE_ML_MODEL_ERROR:
    case ADD_ML_MODEL_ERROR:
        return {
            ...state,
            actionProgress: false,
            error: payload,
        };
    default:
        return state;
    }
};

export const getMlModelList = payload => {
    return {
        type: GET_ML_MODEL_LIST_REQUEST,
        payload,
    };
};

export const addMlModel = payload => {
    return {
        type: ADD_ML_MODEL_REQUEST,
        payload,
    };
};

export const downloadModelFile = payload => {
    return {
        type: DOWNLOAD_MODEL_FILE_REQUEST,
        payload,
    };
};

export const updateMlModel = payload => {
    return {
        type: UPDATE_ML_MODEL_REQUEST,
        payload,
    };
};

export const setMainModel = payload => {
    return {
        type: SET_MAIN_MODEL_REQUEST,
        payload,
    };
};

export const deleteMLModelFile = payload => {
    return {
        type: DELETE_MODEL_FILE_REQUEST,
        payload,
    };
};

export const deleteMlModel = payload => {
    return {
        type: DELETE_ML_MODEL_REQUEST,
        payload,
    };
};

export const addFileToMlModel = payload => {
    return {
        type: ADD_FILE_TO_MODEL_REQUEST,
        payload,
    };
};

export const getMlModelFileList = payload => {
    return {
        type: GET_ML_MODEL_FILE_LIST_REQUEST,
        payload,
    };
};

export const updateModelFile = payload => {
    return {
        type: UPDATE_MODEL_FILE_REQUEST,
        payload,
    };
};

export const mlSelector = state => state.ml;
export const mlModelListSelector = createSelector(mlSelector, ({modelList}) => modelList);
export const mlModelListProgressSelector = createSelector(mlSelector, ({actionProgress}) => actionProgress);
export const mlModelActionProgressSelector = createSelector(mlSelector, ({actionProgress}) => actionProgress);


const addFileToMlModelSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data: {
                description,
                modelId,
                name,
                file,
            },
        } = payload;

        const formData = new FormData();

        formData.append("file", file);

        const result = yield call(axios, {
            url: `/api${controller}/files/add`,
            method: "post",
            params: {
                description,
                modelId,
                name,
            },
            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        },
        );

        const {
            data: {
                errorMessage,
            },
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_FILE_TO_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_FILE_TO_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: ADD_FILE_TO_MODEL_ERROR, payload: error});
    }
};

const deleteMLModelFileSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data: {
                fileId,
            },
        } = payload;

        const result = yield request.post(`${controller}/files/delete?fileId=${fileId}`);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_MODEL_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_MODEL_FILE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: DELETE_MODEL_FILE_ERROR, payload: error});
    }
};

const deleteMlModelSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data: {
                modelId,
            },
        } = payload;

        const result = yield request.post(`${controller}/delete?modelId=${modelId}`);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ML_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_ML_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: DELETE_ML_MODEL_ERROR, payload: error});
    }
};

const downloadModelFileSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.post(`${controller}/setMainModel`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_MAIN_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SET_MAIN_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: SET_MAIN_MODEL_ERROR, payload: error});
    }
};

const setMainModelSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.post(`${controller}/setMainModel`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_MAIN_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SET_MAIN_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: SET_MAIN_MODEL_ERROR, payload: error});
    }
};

const updateModelFileSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.post(`${controller}/files/update`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_MODEL_FILE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: UPDATE_MODEL_FILE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: UPDATE_MODEL_FILE_ERROR, payload: error});
    }
};

const updateMlModelSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.post(`${controller}/update`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_ML_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: UPDATE_ML_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: UPDATE_ML_MODEL_ERROR, payload: error});
    }
};

const addMlModelSaga = function* ({payload}) {
    try {

        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.post(`${controller}/add`, data);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ML_MODEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_ML_MODEL_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

    } catch (error) {
        yield put({type: ADD_ML_MODEL_ERROR, payload: error});
    }
};

const getMlModelListSaga = function* ({payload}) {
    try {

        const result = yield request.post(`${controller}/getPage`, payload);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ML_MODEL_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ML_MODEL_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: GET_ML_MODEL_LIST_ERROR, payload: error});
    }
};


// POST /api/email/complaintEmail/send
const getMlModelFileListSaga = function* ({payload}) {

    try {
        const result = yield request.post(`${controller}/files/getList`, payload);

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ML_MODEL_FILE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ML_MODEL_FILE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: GET_ML_MODEL_FILE_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ML_MODEL_LIST_REQUEST, getMlModelListSaga),
        takeEvery(GET_ML_MODEL_FILE_LIST_REQUEST, getMlModelFileListSaga),
        takeEvery(ADD_ML_MODEL_REQUEST, addMlModelSaga),
        takeEvery(UPDATE_ML_MODEL_REQUEST, updateMlModelSaga),
        takeEvery(DELETE_ML_MODEL_REQUEST, deleteMlModelSaga),
        takeEvery(ADD_FILE_TO_MODEL_REQUEST, addFileToMlModelSaga),
        takeEvery(UPDATE_MODEL_FILE_REQUEST, updateModelFileSaga),
        takeEvery(SET_MAIN_MODEL_REQUEST, setMainModelSaga),
        takeEvery(DOWNLOAD_MODEL_FILE_REQUEST, downloadModelFileSaga),
        takeEvery(DELETE_MODEL_FILE_REQUEST, deleteMLModelFileSaga),
    ]);
}