import React from "react";

import Tabs from "../ActualComponents/Tabs";
import NmTitle from "../NmTitle";

import {getUserRole} from "../../utils/access";

import {
    isClientUser,
} from "../../constants/roles";

import "./style.sass";

const ClientCardPage = (props) => {
    const {
        title,
        tabs,
        count,
        children,
    } = props;

    const role = getUserRole();

    return (
        <div className="client-card-page">
            {
                !isClientUser(role) &&
                <>
                    <NmTitle
                        size="xl"
                        count={count}
                    >
                        {title}
                    </NmTitle>
                    <Tabs
                        {...props}
                        className="client-card-page__tabs"
                        panes={tabs}
                        secondary
                    />
                </>
            }
            {children}
        </div>
    );
};

export default ClientCardPage;