import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";
import {RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT} from "../../../../../constants/recruitment";

import {useFilter} from "../../../../../hooks/useFilter";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../../utils/dateFormat";
import {dictionaryToOptions, filterEmptyValues} from "../../../../../utils/objectHelper";
import {removePhoneMask} from "../../../../../utils/stringFormat";

import {COMMON_ALL_OPTION} from "../../../../../constants/common";

export const useRecruitmentAccessControlRecruitersGroupsFilter = (params) => {
    const {
        setPagination,
        pageSize,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            name: "",
        },
        pageSize,
        setPagination,
        mapFilterDto: (filters) => {
            return filterEmptyValues({
                ...filters,
                phone: filters.phone ? removePhoneMask(filters.phone) : undefined,
                createDateFrom: getStartFilterDateWithTimeInUtc(filters.createDateFrom),
                createDateTo: getEndFilterDateWithTimeInUtc(filters.createDateTo),
            });
        },
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ФИО владельца",
                        placeholder: "Введите ФИО",
                        name: "name",
                        maxLength: 100,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        mask: "+7 (999) 999 9999",
                        maskChar: null,
                        name: "phone",
                        label: "Телефон владельца",
                        placeholder: "Введите телефон",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "E-mail владельца",
                        placeholder: "Введите e-mail",
                        name: "email",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Джоб-борд",
                        placeholder: "Все",
                        name: "jobBoardType",
                        options: [
                            COMMON_ALL_OPTION,
                            ...dictionaryToOptions(COMMON_ALL_OPTION),
                        ],
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус",
                        placeholder: "Не выбран",
                        options: dictionaryToOptions(RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT),
                        name: "status",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        label: "Дата создания",
                        startFieldName: "createDateFrom",
                        endFieldName: "createDateTo",
                        isClearable: true,
                    },
                ],
            },
        ];
    }, []);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    };
};