import React from "react";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../components/ActualComponents/NmDadata";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import AdditionalDocumentFilter from "../../../../components/AdditionalDocumentFilter";
import AdditionalDocumentStatusFilter from "../../../../components/AdditionalDocumentStatusFilter";
import MigrationStatusFilter from "../../../../components/MigrationStatusFilter";
import NmNumberRangePicker from "../../../../components/NmNumberRangePicker";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {isNMUsers} from "../../../../utils/access";
import {getDadataAddress} from "../../../../utils/dadata";
import {getDateObject} from "../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {
    CONTRACTOR_FAMILY_STATUS_OPTIONS,
    CONTRACTOR_FIELD,
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_FILTER_DICT,
    CONTRACTOR_INN_CHECK_STATUS_DICT,
    CONTRACTOR_LAST_LOGIN_TYPE_DICT,
    CONTRACTOR_TYPE_BLOCKED_FILTER_DICT,
} from "../../../../constants/contractor";
import {GENDER_OPTIONS} from "../../../../constants/contractorInfo";
import {SimpleDropdownOptions} from "../../../../constants/contractorList";
import {DADATA_FIAS_LEVEL} from "../../../../constants/dadata";

import "./style.sass";

const NmBaseContractorListFilter = (props) => {
    const {
        onChange = () => {
        },
        clearFilter = () => {
        },
        sendFilter = () => {
        },
        t = () => {
        },
        specialityOptions,
        filterData,
        сitizenshipOptions,
        clientGroupOptions,
        projectParamsOptions,
        contractorStatusOptions,
        languagesOptions,
        contractorRegistrationStatesOptions,
        contractorOrderStatusOptions,
        educationOptions,
        driverLicenseOptions,
    } = props;

    const {
        remoteWorkFilter,
        specialityIdsFilter,
        citizenshipFilter,
        genderFilter,
        phoneFilter,
        ageFromFilter,
        ageToFilter,
        joinDateFromFilter,
        joinDateToFilter,
        fullRegistrationDateFromFilter,
        fullRegistrationDateToFilter,
        registrationStatusFilter,
        fioFilter,
        familyStatusFilter,
        educationLevelFilter,
        clientGroupClientIds,
        driverLicenseFilter,
        ownCarFilter,
        innFilter,
        orderStatusFilter,
        foreignLanguageFilter,
        addressFiasIdsFilter,
        projectParamFilter,
        blockedCauseFilter,
        registrationStateFilter,
        innPassportDataValidStatusFilter,
        individualEntrepreneurStatusFilter,
        lastLoginFilter,
    } = filterData;

    const onKeyPress = ({key}) => {
        if (key === "Enter") {
            sendFilter();
        }
    };

    return (
        <div className="nm-contractor-list-filter">
            <NmForm
                addMargin
                onKeyPress={onKeyPress}
                className="nm-contractor-list-filter__form"
            >
                <FilterButtonsV2
                    className="nm-contractor-list-filter__buttons"
                    onSearch={sendFilter}
                    onClear={() => {
                        clearFilter();
                    }}
                />
                <NmInputV2
                    onChange={onChange}
                    name="fioFilter"
                    size="lg"
                    value={fioFilter}
                    label={t("contractor-list.full-name-placeholder")}
                />
                <NmDropdownV2
                    placeholder="Выберите из списка"
                    label="Заказчик"
                    name="clientGroupClientIds"
                    onChange={onChange}
                    onSubmit={sendFilter}
                    value={clientGroupClientIds}
                    options={clientGroupOptions}
                    size="lg"
                    search
                    multiple
                />
                <NmDropdownV2
                    placeholder="Выберите из списка"
                    label="Проект"
                    name="projectParamFilter"
                    onChange={onChange}
                    onSubmit={sendFilter}
                    value={projectParamFilter}
                    options={projectParamsOptions}
                    size="lg"
                    search
                />
                <PhoneWithCodeFilter
                    name="phoneFilter"
                    value={phoneFilter}
                    onChange={onChange}
                />
                <NmDropdownV2
                    placeholder="Все"
                    label="На заказе"
                    emptyOptionText="Все"
                    name="orderStatusFilter"
                    onChange={onChange}
                    value={orderStatusFilter}
                    options={contractorOrderStatusOptions}
                    size="lg"
                />
                <NmDadata
                    size="lg"
                    name={CONTRACTOR_FIELD.ADDRESS_FIAS_IDS_FILTER}
                    label="Регион или город"
                    maxFiasLevel={DADATA_FIAS_LEVEL.REGION}
                    onChange={onChange}
                    multiple
                    fromBound="region"
                    toBound="settlement"
                    initialAddressIds={addressFiasIdsFilter}
                    formatter={getDadataAddress}
                    isClearable
                    onSubmit={sendFilter}
                />
                <NmCheckboxV2
                    label="Работа удалённо"
                    checked={remoteWorkFilter}
                    name="remoteWorkFilter"
                    onChange={onChange}
                />
                <NmDropdownV2
                    placeholder="Все"
                    label="Статус регистрации"
                    name="registrationStatusFilter"
                    onChange={onChange}
                    value={registrationStatusFilter}
                    options={contractorStatusOptions}
                    size="lg"
                    multiple
                />
                <NmDropdownV2
                    label={t("contractor-list.citizenship")}
                    name="citizenshipFilter"
                    onChange={onChange}
                    onSubmit={sendFilter}
                    options={сitizenshipOptions}
                    value={citizenshipFilter}
                    placeholder="Показать все"
                    size="lg"
                    search
                    multiple
                />
                <MigrationStatusFilter
                    onChange={onChange}
                    onSubmit={sendFilter}
                    value={filterData.migrationStatusesFilter}
                />
                <AdditionalDocumentFilter
                    onChange={onChange}
                    onSubmit={sendFilter}
                    value={filterData.additionalDocumentTypesFilter}
                />
                {
                    isNMUsers() &&
                    <AdditionalDocumentStatusFilter
                        onChange={onChange}
                        onSubmit={sendFilter}
                        value={filterData.additionalDocumentStatusesFilter}
                    />
                }
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    label="Основание работы"
                    name="registrationStateFilter"
                    onChange={onChange}
                    value={registrationStateFilter}
                    options={contractorRegistrationStatesOptions}
                    size="lg"
                />
                <NmInputV2
                    label={t("contractor-full-personal-data.inn")}
                    mask="999999999999"
                    maskChar={null}
                    name="innFilter"
                    value={innFilter}
                    onChange={onChange}
                    size="lg"
                />
                <NmDropdownV2
                    label={t("contractor-list.specialties")}
                    name="specialityIdsFilter"
                    onChange={onChange}
                    onSubmit={sendFilter}
                    options={specialityOptions}
                    value={specialityIdsFilter}
                    placeholder="Показать все"
                    size="lg"
                    search
                    multiple
                />
                <div className="nm-contractor-list-filter__row">
                    <NmDropdownV2
                        placeholder="Все"
                        emptyOptionText="Все"
                        label={t("contractor-list.gender")}
                        name="genderFilter"
                        className="w-47"
                        onChange={onChange}
                        value={genderFilter}
                        options={GENDER_OPTIONS}
                        size="lg"
                    />
                    <NmNumberRangePicker
                        label="Возраст"
                        className="w-47"
                        startFieldPlaceholder={t("component-labels.from")}
                        endFieldPlaceholder={t("component-labels.to")}
                        value={{ageFromFilter, ageToFilter}}
                        onEndFieldChange={onChange}
                        onStartFieldChange={onChange}
                        startFieldName="ageFromFilter"
                        endFieldName="ageToFilter"
                    />
                </div>
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    label="Семейное положение"
                    name="familyStatusFilter"
                    onChange={onChange}
                    value={familyStatusFilter}
                    options={CONTRACTOR_FAMILY_STATUS_OPTIONS}
                    size="lg"
                />
                <NmDropdownV2
                    placeholder="Все"
                    label="Образование"
                    name="educationLevelFilter"
                    onChange={onChange}
                    value={educationLevelFilter}
                    options={educationOptions}
                    size="lg"
                    multiple
                />
                <NmDropdownV2
                    placeholder="Все"
                    label="Иностранный язык"
                    name="foreignLanguageFilter"
                    onChange={onChange}
                    value={foreignLanguageFilter}
                    options={languagesOptions}
                    size="lg"
                    multiple
                />
                <NmDropdownV2
                    placeholder="Все"
                    label="Водительские права"
                    name="driverLicenseFilter"
                    onChange={onChange}
                    value={driverLicenseFilter}
                    options={driverLicenseOptions}
                    size="lg"
                    multiple
                />
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    label="Наличие автомобиля"
                    name="ownCarFilter"
                    onChange={onChange}
                    value={ownCarFilter}
                    options={SimpleDropdownOptions}
                    size="lg"
                />
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    label={t("contractor-list.blocking-existing")}
                    name="blockedCauseFilter"
                    onChange={onChange}
                    value={blockedCauseFilter}
                    options={dictionaryToOptions(CONTRACTOR_TYPE_BLOCKED_FILTER_DICT)}
                    size="lg"
                />
                <NmDateRangePickerV2
                    label={t("contractor-list.simple-registration-period")}
                    onChange={onChange}
                    size="lg"
                    startFieldName="joinDateFromFilter"
                    endFieldName="joinDateToFilter"
                    value={{
                        joinDateFromFilter: getDateObject(joinDateFromFilter),
                        joinDateToFilter: getDateObject(joinDateToFilter),
                    }}
                    isClearable
                />
                <NmDateRangePickerV2
                    label={t("contractor-list.full-registration-period")}
                    onChange={onChange}
                    size="lg"
                    startFieldName="fullRegistrationDateFromFilter"
                    endFieldName="fullRegistrationDateToFilter"
                    value={{
                        fullRegistrationDateFromFilter: getDateObject(fullRegistrationDateFromFilter),
                        fullRegistrationDateToFilter: getDateObject(fullRegistrationDateToFilter),
                    }}
                    isClearable
                />
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    label="Дата последнего визита"
                    name="lastLoginFilter"
                    onChange={onChange}
                    value={lastLoginFilter}
                    options={dictionaryToOptions(CONTRACTOR_LAST_LOGIN_TYPE_DICT)}
                    size="lg"
                />
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    label="Мэтчинг ИНН"
                    name="innPassportDataValidStatusFilter"
                    onChange={onChange}
                    value={innPassportDataValidStatusFilter}
                    options={dictionaryToOptions(CONTRACTOR_INN_CHECK_STATUS_DICT)}
                    size="lg"
                />
                <NmDropdownV2
                    placeholder="Все"
                    emptyOptionText="Все"
                    name="individualEntrepreneurStatusFilter"
                    options={dictionaryToOptions(CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_FILTER_DICT)}
                    onChange={onChange}
                    value={individualEntrepreneurStatusFilter}
                    label="Статус ИП"
                    size="lg"
                />
                <FilterButtonsV2
                    className="nm-contractor-list-filter__buttons"
                    onSearch={sendFilter}
                    onClear={clearFilter}
                />
            </NmForm>
        </div>
    );
};

export default NmBaseContractorListFilter;