import {
    CLIENT_SYSTEM_PROPERTIES_UPDATE_STORE,
    GET_CLIENT_SYSTEM_PROPERTIES_ERROR,
    GET_CLIENT_SYSTEM_PROPERTIES_REQUEST,
    GET_CLIENT_SYSTEM_PROPERTIES_SUCCESS, UPDATE_CLIENT_SYSTEM_PROPERTIES_ERROR,
    UPDATE_CLIENT_SYSTEM_PROPERTIES_REQUEST,
    UPDATE_CLIENT_SYSTEM_PROPERTIES_SUCCESS,
} from "./actions";

const initialState = {
    systemProperties: {},
    progress: false,
    progressAction: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case GET_CLIENT_SYSTEM_PROPERTIES_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_CLIENT_SYSTEM_PROPERTIES_SUCCESS:
        return {
            ...state,
            systemProperties: payload,
            progress: false,
        };
    case GET_CLIENT_SYSTEM_PROPERTIES_ERROR:
        return {
            ...state,
            progress: false,
        };
    case UPDATE_CLIENT_SYSTEM_PROPERTIES_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case UPDATE_CLIENT_SYSTEM_PROPERTIES_SUCCESS:
    case UPDATE_CLIENT_SYSTEM_PROPERTIES_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case CLIENT_SYSTEM_PROPERTIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};