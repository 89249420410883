import {
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST,
    UPDATE_CROWD_TASKS_ANALYTICS_STORE,
} from "./actions";

export const getPageCrowdTasksAnalyticsOnVerification = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageCrowdTasksAnalyticsOnContractSigning = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageCrowdTasksAnalyticNoResponses = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageCrowdTasksAnalyticInProcessPayment = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageCrowdTasksAnalyticHired = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageCrowdTasksAnalyticAtWork = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST,
        payload,
    };
};

export const getCountAggregationCrowdTasksAnalytic = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST,
        payload,
    };
};

export const updateStoreCrowdTasksAnalytics = (payload) => {
    return {
        type: UPDATE_CROWD_TASKS_ANALYTICS_STORE,
        payload,
    };
};