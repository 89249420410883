import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {updateStoreMap} from "../../../ducks/bff/map/actionCreators";
import {initialMapEntityLists} from "../../../ducks/bff/map/reducer";

export const useClearMapModalData = (params) => {
    const {
        isClearUnMount,
    } = params;

    const dispatch = useDispatch();

    const clearModalData = () => {
        dispatch(updateStoreMap({
            ...initialMapEntityLists,
        }));
    };

    useEffect(() => {
        return () => {
            if (isClearUnMount) {
                clearModalData();
            }
        };
    }, []);

    return {
        clearModalData,
    };
};