import React from "react";

import ImportFromFilePatternV2 from "../../../ActualComponents/ImportFromFilePatternV2";

export const SmartLinkImportOrderNumbersFromXlsx = (props) => {
    const {
        onClose,
        progress,
        importFromFile,
        patternLink,
    } = props;

    const importNumbers = ({file}) => {
        importFromFile({
            file,
            onSuccess: () => {
                onClose();
            },
        });
    };

    return (
        <ImportFromFilePatternV2
            maxSize={10}
            patternLink={patternLink}
            onSubmit={importNumbers}
            onClose={onClose}
            progress={progress}
        />
    );
};