import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getPageContractorFinanceBalance} from "../../../../../ducks/contractorFinanceBalance";

export function useContractorFinanceBalanceFetch({filter = {}, pageSize, pageNum, contractorId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        const requestData = {
            pageNum,
            pageSize,
            contractorId,
            ...filter,
        };

        dispatch(getPageContractorFinanceBalance(requestData));
    };

    return {
        fetchList,
    };
}