import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_SUCCESS,
} from "./actions";

export const getRecruitmentVacancyCandidatesCommentsInitialState = () => {
    return {
        progressAction: false,
        progress: false,
        list: [],
        pageData: {},
        totalCount: 0,
        error: null,
    };
};

const initialState = getRecruitmentVacancyCandidatesCommentsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_SUCCESS:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_SUCCESS:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_SUCCESS:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_SUCCESS:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR:
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR:
        return {
            ...state,
            progressAction: false,
            error: payload,
        };
    case BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};