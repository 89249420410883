import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getStatementsPage} from "../../../../../ducks/edmStatements";

export function useFetchList({filter = {}, clientId, pageSize, pageNum, currentStaffId}) {
    const dispatch = useDispatch();

    const [archived, setArchived] = useState(false);

    useEffect(() => {
        fetchList();
    }, [archived, pageSize, pageNum, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        setLoading(true);
        const requestData = {
            pageNum,
            pageSize,
            clientId,
            archived,
            currentStaffId,
            ...filter,
            getResult: () => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            },
        };

        dispatch(getStatementsPage(requestData));
    };

    return {
        archived,
        loading,
        pageNum,
        pageSize,
        setArchived,
        fetchList,
    };
}