import React, {useContext} from "react";

import ContextMenu from "../../../../ActualComponents/ContextMenu";
import NmDivider from "../../../../ActualComponents/NmDivider";
import NmMiniInfoCard from "../../../../ActualComponents/NmMiniInfoCard";
import Text from "../../../../ActualComponents/Text";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";
import {RecruitmentCandidateInformationDeletingConfirm} from "../DeletingConfirm";
import {RecruitmentCandidateExperienceEdit} from "./Edit";

import {useModal} from "../../../../../hooks/useModal";

import {isLastElementInArray} from "../../../../../utils/objectHelper";
import {getCandidateInformationEditAction} from "../../utils/getEditAction";
import {getRecruitmentCandidatesExperiencePeriod} from "../../utils/getExperiencePeriod";

import {COLOR} from "../../../../../constants/color";

export const RecruitmentCandidateExperience = (props) => {
    const {
        isAccessActions,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        card,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        modalData: editData,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const deleteExperience = () => {
        const experiences = card.experiences.filter((item, index) => index !== confirmData.index);

        updateInformation({
            experiences,
            onSuccess: () => {
                onCloseConfirm();
                fetchInformation();
            },
        });
    };

    return (
        <>
            {
                isOpenEdit &&
                <RecruitmentCandidateExperienceEdit
                    onClose={onCloseEdit}
                    editData={editData}
                />
            }
            {
                isOpenConfirm &&
                <RecruitmentCandidateInformationDeletingConfirm
                    onCancel={onCloseConfirm}
                    onConfirm={deleteExperience}
                />
            }
            <NmMiniInfoCard
                title="Опыт работы"
                titleLevel="4"
                onClickAdd={() => {
                    onOpenEdit({});
                }}
                accessEdit={props.isAccessActions}
            >
                {
                    typeof props.value === "string" ?
                        props.value :
                        props.value.map((item, index) => {
                            const isLast = isLastElementInArray(props.value, index);
                            const period = getRecruitmentCandidatesExperiencePeriod(item.periodFrom, item.periodTo);

                            return (
                                <React.Fragment key={period}>
                                    <div className="d-flex justify-content-between align-items-start align-items-xxl-center mb-2">
                                        <Text
                                            level="3"
                                            color={COLOR.SECONDARY_90}
                                        >
                                            {`${period} ${item.organisation}`}
                                        </Text>
                                        {
                                            isAccessActions &&
                                            <ContextMenu
                                                notBorder
                                                horizontalIcon
                                                disabled={false}
                                                clearWidth
                                                className="ms-1"
                                                options={getCandidateInformationEditAction({
                                                    onClickEdit: () => {
                                                        onOpenEdit({...item, index});
                                                    },
                                                    onClickDelete: () => {
                                                        onOpenConfirm({index});
                                                    },
                                                })}
                                            />
                                        }
                                    </div>
                                    <Text.Sub
                                        medium={true}
                                        color={COLOR.SECONDARY_90}
                                    >
                                        {item.position}
                                    </Text.Sub>
                                    {
                                        !isLast &&
                                        <NmDivider
                                            color={COLOR.SECONDARY_5}
                                            className="mt-2 mb-3 mb-md-4"
                                        />
                                    }
                                </React.Fragment>
                            );
                        })
                }
            </NmMiniInfoCard>
        </>
    );
};
