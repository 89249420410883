import {FC_REGISTRIS_STATUS_DICT} from "../../constants/fcRegistries";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../constants/roles";

export const isAcceptedShowFcRegistryListControls = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role);
};

export const isAcceptedShowArchivedFcRegistryListControls = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role);
};

export const isAcceptedAddingContractorsToFcRegistry = (role, {archived, status}) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role) && !archived && [
        FC_REGISTRIS_STATUS_DICT.CREATED,
    ].includes(status);
};

export const isAcceptedUploadingContactorListToFcRegistry = (role, {archived, status}) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role) && !archived && [FC_REGISTRIS_STATUS_DICT.CREATED].includes(status);
};

export const isAcceptedSubmittingToSigningFcRegistry = (role, {archived, status}) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role) && !archived && [FC_REGISTRIS_STATUS_DICT.CREATED].includes(status);
};

export const isAcceptedFcRegistryMassActions = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role);
};

export const isAcceptedFcRegistryListActions = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role);
};

export const isAcceptedFcRegistryActions = (role, {archived}) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role) && !archived;
};

export const isAcceptedEditingFcRegistryActions = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role);
};

export const isAcceptedCancelingFcRegistryItem = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        PROJECT_MANAGER,
    ].includes(role);
};

export const isAcceptedFcRegistryExporting = (role) => {
    return [
        ADMIN,
        NM_MANAGER,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
        CLIENT_ADMIN,
        FOREMAN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        CLIENT_ACCOUNTANT,
        HR_MANAGER,
    ].includes(role);
};

