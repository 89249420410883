import React, {useContext} from "react";
import {isEmpty} from "lodash";

import NmMiniInfoCard from "../../../../ActualComponents/NmMiniInfoCard";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";
import {RecruitmentCandidateInformationDeletingConfirm} from "../DeletingConfirm";
import {RecruitmentCandidateAboutYourselfEdit} from "./Edit";

import {useModal} from "../../../../../hooks/useModal";

import {getCandidateInformationEditAction} from "../../utils/getEditAction";

export const RecruitmentCandidateAboutYourself = (props) => {
    const {
        isAccessActions,
        aboutYourself,
    } = props;

    const {
        updateInformation,
        fetchInformation,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        isOpen: isOpenConfirm,
        onOpenModal: onOpenDeleteConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const deleteExperience = () => {
        updateInformation({
            aboutYourself: null,
            onSuccess: () => {
                onCloseConfirm();
                fetchInformation();
            },
        });
    };

    return (
        <>
            {
                isOpenEdit &&
                <RecruitmentCandidateAboutYourselfEdit
                    onClose={onCloseEdit}
                    aboutYourself={aboutYourself}
                />
            }
            {
                isOpenConfirm &&
                <RecruitmentCandidateInformationDeletingConfirm
                    onCancel={onCloseConfirm}
                    onConfirm={deleteExperience}
                />
            }
            <NmMiniInfoCard
                title="О себе"
                titleLevel="4"
                options={getCandidateInformationEditAction({
                    isVisibleDelete: !isEmpty(aboutYourself),
                    onClickDelete: () => {
                        onOpenDeleteConfirm({});
                    },
                    onClickEdit: () => {
                        onOpenEdit({});
                    },
                })}
                accessEdit={isAccessActions}
            >
                {aboutYourself || "-"}
            </NmMiniInfoCard>
        </>
    );
};
