import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Tabs from "../../../components/ActualComponents/Tabs";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {
    getCountsRecruitmentDirectory,
    updateRecruitmentDirectoryStore,
} from "../../../ducks/bff/recruitment/directory/actionCreators";
import {
    bffRecruitmentDirectoryCountsSelector,
    bffRecruitmentDirectoryFunnelTotalCountSelector,
    bffRecruitmentDirectoryJobRequirementTotalCountSelector,
    bffRecruitmentDirectoryRejectionReasonTotalCountSelector,
    bffRecruitmentDirectoryStatusTotalCountSelector,
} from "../../../ducks/bff/recruitment/directory/selectors";
import ClientRecruitmentDirectoriesRoute from "./route";

import {
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES,
} from "../../../constants/links";

const ClientRecruitmentDirectories = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();
    const recruitmentDirectoriesCounts = useSelector(bffRecruitmentDirectoryCountsSelector);
    const _statusTotalCount = useSelector(bffRecruitmentDirectoryStatusTotalCountSelector);
    const _additionalRequirementTotalCount = useSelector(bffRecruitmentDirectoryJobRequirementTotalCountSelector);
    const _funnelTotalCount = useSelector(bffRecruitmentDirectoryFunnelTotalCountSelector);
    const _rejectionReasonsTotalCount = useSelector(bffRecruitmentDirectoryRejectionReasonTotalCountSelector);

    useEffect(() => {
        dispatch(getCountsRecruitmentDirectory({clientId}));

        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                funnelTotalCount: 0,
                jobRequirementTotalCount: 0,
                statusTotalCount: 0,
                counts: {},
            }));
        };
    }, []);

    const getTabs = () => {
        const funnels = LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS.replace(":clientId", clientId);
        const statuses = LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES.replace(":clientId", clientId);
        const jobRequirements = LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS.replace(":clientId", clientId);
        const rejectionReasons = LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS.replace(":clientId", clientId);

        const {
            statusTotalCount = 0,
            funnelTotalCount = 0,
            additionalRequirementTotalCount = 0,
            rejectionReasonsTotalCount = 0,
        } = recruitmentDirectoriesCounts || {};

        return [
            {
                active: funnels,
                link: funnels,
                name: "Воронки",
                count: _funnelTotalCount || funnelTotalCount,
            },
            {
                active: statuses,
                link: statuses,
                name: "Статусы",
                count: _statusTotalCount || statusTotalCount,
            },
            {
                active: jobRequirements,
                link: jobRequirements,
                name: "Дополнительные требования по вакансиям",
                count: _additionalRequirementTotalCount || additionalRequirementTotalCount,
            },
            {
                active: rejectionReasons,
                link: rejectionReasons,
                name: "Причины отказа",
                count: _rejectionReasonsTotalCount || rejectionReasonsTotalCount,
            },
        ];
    };

    return (
        <NmPage
            header={
                <NmTitle size="xl">
                    Справочники
                </NmTitle>
            }
        >
            <Tabs
                panes={getTabs()}
                className="mb-3 mb-md-4"
            />
            <ClientRecruitmentDirectoriesRoute
                {...props}
            />
        </NmPage>
    );
};

export default ClientRecruitmentDirectories;