import React from "react";
import {nanoid} from "nanoid";

import NmTitle from "../../../../../components/NmTitle";

import bem from "../../../../../utils/bem";

import "./style.sass";

const ContractorVerificationInfoBlock = (props) => {
    const {
        list,
    } = props;
    const [block, element] = bem("contractor-verification-info-block");

    return (
        <div className={block()}>
            {
                list.filter(Boolean).map((item) => {
                    const {
                        title,
                        value,
                        isTitle,
                    } = item;

                    if (isTitle) {
                        return (
                            <NmTitle
                                key={nanoid(3)}
                                className={element("title")}
                                size="md"
                            >
                                {title}
                            </NmTitle>
                        );
                    }

                    return (
                        <div
                            key={nanoid(3)}
                            className={element("field")}
                        >
                            {
                                title &&
                                <div className={element("field", {name: true})}>
                                    {title}
                                </div>
                            }
                            <div
                                className={element("field", {value: true})}
                                title={value}
                            >
                                {value}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default ContractorVerificationInfoBlock;