import React from "react";

import "./style.sass";

const LoginCardContent = ({children}) => {
    return (
        <div className="login-content">
            {children}
        </div>
    );
};

export default LoginCardContent;