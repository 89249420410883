import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../utils/objectHelper";
import {handleFormString} from "../../../../utils/stringHelper";

import {
    clearAdditionalAgreementsStore,
    getAdditionalAgreements,
    getAdditionalAgreementStatusDict,
} from "../../../../ducks/bff/documents/additional-agreements/actionCreators";

export const useAdditionalAgreementsFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        archivedFilter,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {

        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        archivedFilter,
    ]);

    useEffect(() => {
        dispatch(getAdditionalAgreementStatusDict());

        return () => {
            dispatch(clearAdditionalAgreementsStore());
        };
    }, []);

    const fetchList = () => {
        const requestData = filterEmptyValues({
            statusFilter: handleFormString(filterData.statusFilter),
            nameSubstringFilter: handleFormString(filterData.nameSubstringFilter),
            creationDateTimeFromFilter: getStartFilterDateWithTimeInUtc(filterData.creationDateTimeFromFilter),
            creationDateTimeToFilter: getEndFilterDateWithTimeInUtc(filterData.creationDateTimeToFilter),
        });

        dispatch(getAdditionalAgreements({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            archivedFilter,
            ...requestData,
        }));
    };

    return null;
};