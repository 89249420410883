export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_CREATE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_EDIT_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_BY_ID_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_DELETE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_MASS_DELETE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_CHECK_DUPLICATE_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_GET_PAGE_ERRORS_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_VALIDATION_ERROR";

export const BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST = "BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_REQUEST";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_SUCCESS = "BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_SUCCESS";
export const BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR = "BFF_CROWD_ACT_REGISTRY_ENTRY_REPEAT_ERROR";

export const UPDATE_CROWD_ACT_REGISTRY_ENTRY_STORE = "UPDATE_CROWD_ACT_REGISTRY_ENTRY_STORE";