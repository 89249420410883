import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../../../components/ProjectsObjectsCheckboxList/constants";

export const clientBrandsSelector = state => state.clientBrands;

export const clientBrandsListSelector = createSelector(
    clientBrandsSelector,
    ({list}) => list,
);

export const clientBrandsProgressActionSelector = createSelector(
    clientBrandsSelector,
    ({progressAction}) => progressAction,
);

export const clientBrandsProgressSelector = createSelector(
    clientBrandsSelector,
    ({progress}) => progress,
);

export const clientBrandsTotalCountSelector = createSelector(
    clientBrandsSelector,
    ({totalCount}) => totalCount,
);

export const clientBrandsLogsListSelector = createSelector(
    clientBrandsSelector,
    ({logsList}) => logsList,
);

export const clientBrandsLogsTotalCountSelector = createSelector(
    clientBrandsSelector,
    ({logsTotalCount}) => logsTotalCount,
);

export const clientBrandsLogsTotalPagesSelector = createSelector(
    clientBrandsSelector,
    ({logsTotalCount, logsPageData: {pageSize}}) => getTotalPages(logsTotalCount, pageSize),
);

export const clientBrandsLogsProgressSelector = createSelector(
    clientBrandsSelector,
    ({logsProgress}) => logsProgress,
);

export const clientBrandsTotalPagesSelector = createSelector(
    clientBrandsSelector,
    ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize),
);

export const clientBrandsProjectsSelector = createSelector(
    clientBrandsSelector,
    ({projects}) => {
        return {
            ...projects,
            list: projects.list.map(item => {
                return {
                    ...item,
                    boolParam: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                    isPartial: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
                };
            }),
            totalPages: getTotalPages(projects.totalCount, projects.pageData.pageSize),
        };
    });

export const clientBrandsObjectsSelector = createSelector(
    clientBrandsSelector,
    ({objects}) => objects,
);

export const clientBrandsProjectsAndObjectsSelector = createSelector(
    clientBrandsSelector,
    ({projectsAndObjects}) => projectsAndObjects,
);

export const clientBrandsProjectsAndObjectsProgressSelector = createSelector(
    clientBrandsSelector,
    ({progressSearch}) => progressSearch,
);