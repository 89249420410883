import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getOnboardingObjectContactorsList,
    updateOnboardingContactorsStore,
} from "../../../../ducks/onboardingContractors";

export const useObjectContractorsListFetch = ({clientId, objectId, pageSize, pageNum, filter}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateOnboardingContactorsStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getOnboardingObjectContactorsList({
            clientIdFilter: clientId,
            objectIdFilter: objectId,
            pageSize,
            pageNum,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};