import React, {FC} from "react";

import NmTitle from "../../NmTitle";
import {MarkerList, MarkerListType} from "../MarkerList";
import NmModal from "../NmModal";

import "./style.sass";

interface IMarkerListModal {
    list: Array<MarkerListType>,
    header?: string,
    onClose: () => void,
}

export const MarkerListModal: FC<IMarkerListModal> = (props) => {
    const {
        list,
        header,
        onClose,
    } = props;

    const getChildren = () => {
        return (
            <MarkerList list={list} />
        );
    };

    return (
        <NmModal
            zIndex={9999}
            size="md"
            header={
                header &&
                <NmTitle size="lg">
                    {header}
                </NmTitle>
            }
            classNameContent="marker-list-modal__content"
            className="marker-list-modal"
            children={getChildren()}
            onClose={onClose}
        />
    );
};
