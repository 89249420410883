import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getAdmKedoTemplatesLogsPage} from "../../../../ducks/bff/adm/document-templates/kedo/templates/services";
import {getCustomDocumentTemplateLogEventTypes} from "../../../../ducks/bff/common/dicts/actionCreators";
import {updateKedoTemplatesStore} from "../../../../ducks/kedo/templates/actionCreators";

export const useKedoTemplateLogsFetch = (params) => {
    const {
        pageNum,
        pageSize,
        filterData,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomDocumentTemplateLogEventTypes({}));
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    useEffect(() => {
        return () => {
            dispatch(updateKedoTemplatesStore({
                logsList: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getAdmKedoTemplatesLogsPage({
            pageNum,
            pageSize,
            ...filterData,
        }));
    };
};