import * as yup from "yup";

import {removeSnilsMask} from "../../../../../../../utils/stringFormat";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";
import {CONTRACTOR_FIELD} from "../../../../../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        [CONTRACTOR_FIELD.SNILS_NUM]: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("snilsNum", "Вы ввели неверный СНИЛС", function (value) {
                const snils = removeSnilsMask(value);

                if (!snils) {
                    return true;
                }

                if (snils.length !== 11) {
                    return this.createError({
                        message: "СНИЛС может состоять только из 11 цифр",
                        path: "snilsNum",
                    });
                }

                let sum = 0;

                for (let i = 0; i < 9; i++) {
                    sum += parseInt(snils[i]) * (9 - i);
                }

                let checkDigit = 0;

                if (sum < 100) {
                    checkDigit = sum;
                } else if (sum > 101) {
                    checkDigit = parseInt(sum % 101);
                    if (checkDigit === 100) {
                        checkDigit = 0;
                    }
                }

                return checkDigit === parseInt(snils.slice(-2));
            }),
        "files": yup.object().shape({
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });
};

export default validationSchema;