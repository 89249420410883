import {removePhoneMaskWithParentheses} from "../../../utils/stringFormat";
import {handleFormString} from "../../../utils/stringHelper";

export const getClientListFilterRequestData = (filter) => {
    const {
        nameSubstringFilter,
        clientUserFioFilter,
        clientUserPhoneFilter,
        clientUserEmailFilter,
        innFilter,
    } = filter;

    return {
        nameSubstringFilter: handleFormString(nameSubstringFilter),
        clientUserFioFilter: handleFormString(clientUserFioFilter),
        clientUserPhoneFilter: handleFormString(removePhoneMaskWithParentheses(clientUserPhoneFilter)),
        clientUserEmailFilter: handleFormString(clientUserEmailFilter),
        innFilter: handleFormString(innFilter),
    };
};