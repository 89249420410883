import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdTaskGroupSelector = state => state.bff.crowd.taskGroup;
export const bffCrowdTaskGroupCardSelector = createSelector(bffCrowdTaskGroupSelector, ({card}) => card);
export const bffCrowdTaskGroupListSelector = createSelector(bffCrowdTaskGroupSelector, ({list}) => list);
export const bffCrowdTaskGroupTotalCountSelector = createSelector(bffCrowdTaskGroupSelector, ({totalCount}) => totalCount);
export const bffCrowdTaskGroupTotalPagesSelector = createSelector(bffCrowdTaskGroupSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdTaskGroupProgressSelector = createSelector(bffCrowdTaskGroupSelector, ({progress}) => progress);
export const bffCrowdTaskGroupProgressActionSelector = createSelector(bffCrowdTaskGroupSelector, ({progressAction}) => progressAction);