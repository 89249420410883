import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateForeignLanguages, {defaultFamilyStatusForm} from "./hooks/useUpdateFamilyStatus";

import {CONTRACTOR_FAMILY_STATUS_OPTIONS} from "../../../../../../constants/contractor";

export default function ContractorFamilyStatusEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultFamilyStatusForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const {
        onChangeForm,
        submit,
        form,
        setForm,
    } = useUpdateForeignLanguages({contractorId, onClose, getProfile});

    useEffect(() => {
        setForm({...editData});
    }, []);

    const {
        status,
    } = form;

    function renderRadioButtons() {
        return CONTRACTOR_FAMILY_STATUS_OPTIONS.map(({value, text}) => {
            return (
                <NmRadioV2
                    key={value}
                    label={text}
                    checked={status === value}
                    onChange={(e) => {
                        onChangeForm(e, {value, name: "status"});
                    }}
                />
            );
        });
    }

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Семейное положение
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                {renderRadioButtons()}
            </NmForm>
        </NmModal>
    );
}