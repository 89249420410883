import React from "react";

import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import NmTitle from "../NmTitle";

import {useModal} from "../../hooks/useModal";

import {COLOR} from "../../constants/color";

const ViewTextButton = (props) => {
    const {
        buttonText = "Посмотреть",
        modalTitle,
        children,
        textLevel = "2",
    } = props;

    const {
        isOpen,
        onOpenModal,
        onCloseModal,
    } = useModal();

    return (
        <>
            <Text
                color={COLOR.PASSIVE_100}
                level={textLevel}
                onClick={() => {
                    onOpenModal({});
                }}
            >
                {buttonText}
            </Text>
            {
                isOpen &&
                <NmModal
                    size="md"
                    header={
                        modalTitle &&
                        <NmTitle size="lg">
                            {modalTitle}
                        </NmTitle>
                    }
                    classNameContent="flex-column"
                    children={children}
                    onClose={onCloseModal}
                />
            }
        </>
    );
};

export default ViewTextButton;