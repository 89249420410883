import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

export const getTariffGridEditValidation = () => {
    return yup.object().shape({
        specialityId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        addressId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        rate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        unit: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};