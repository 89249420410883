import React, {useEffect,useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {CLIENT_FIELD_NAME} from "../../../../constants/clientList";

import {getClientCardSelector, updateClient} from "../../../../ducks/client";

function ClientInfoAbout(props) {
    const {isEditable} = props;

    const [isEdit, setIsEdit] = useState(false);
    const [about, setAbout] = useState("");

    const {t} = useTranslation();
    const client = useSelector(getClientCardSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        setAbout(client[CLIENT_FIELD_NAME.ABOUT]);
    }, [client[CLIENT_FIELD_NAME.ABOUT], isEdit]);

    function submit() {
        dispatch(updateClient({
            ...client,
            [CLIENT_FIELD_NAME.ABOUT]: isNullOrWhitespace(about) ? "" : about.trim(),
        }));
        cancelEdit();
    }

    function renderCardReadOnly() {
        return (
            <LabelTextApp
                label="О компании"
                className="client-card-block__label"
                text={client[CLIENT_FIELD_NAME.ABOUT] || t("is-not-specified.content")}
            />
        );
    }

    const onChangeAbout = (event, {value}) => {
        setAbout(value);
    };

    function renderCardEdit() {
        return (
            <div className="row">
                <div className="col-md-16">
                    <NmTextareaV2
                        label={t("client-info-about.about")}
                        maxLength={1_000}
                        value={about}
                        onChange={onChangeAbout}
                    />
                </div>
            </div>
        );
    }

    const toggleCard = () => {
        if (!isEdit) {
            setIsEdit(true);
            return;
        }
        submit();
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setAbout("");
    };
    return (
        <CardApp
            title={t("client-info-about.title")}
            className="client-card-block"
            isEditable={isEditable && !client[CLIENT_FIELD_NAME.ARCHIVED]}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
        >
            {isEdit ?
                renderCardEdit() :
                renderCardReadOnly()
            }
        </CardApp>
    );
}

export default ClientInfoAbout;