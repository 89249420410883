import * as yup from "yup";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES} from "../../../../../../../constants/clientList";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        fingerprintingDocumentNum: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(50, "Не более 50 символов"),
        fingerprintingDocumentIssuedBy: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов"),
        fingerprintingDocumentIssuedDate: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        files: yup.object().shape({
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN]: yup.array()
                .min(1, VALIDATIONS_MESSAGE.REQUIRED),
        }),
    });
};

export default validationSchema;