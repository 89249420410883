import React from "react";

import TemplatesDropdown from "../../../../../../components/TemplatesDropdown";

import {ORDER_WORK_REPORT_TYPE} from "../../../../../../constants/finance";
import {ORDER_KIND} from "../../../../../../constants/order";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../../templates/constants";

const OrderListMassTemplatePublicationNPD = (props) => {
    const {
        clientId,
        onChange,
        form,
        allOptionsInfo,
    } = props;

    return (
        <>
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.SMZ}
                buttonText="Скачать договор"
                placeholder="Введите наименование шаблона договора"
                label="Шаблон договора НПД"
                name="frameContractSmzId"
                onChange={onChange}
                options={allOptionsInfo.frameContractSmzId}
                value={form.frameContractSmzId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.AMOUNT_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.SMZ}
                buttonText="Скачать заявку"
                placeholder="Введите наименование шаблона заявки"
                label="Шаблон заявки типа заказа 1"
                name="orderApplicationSmzAmountBasedId"
                onChange={onChange}
                options={allOptionsInfo.orderApplicationSmzAmountBasedId}
                value={form.orderApplicationSmzAmountBasedId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.SMZ}
                buttonText="Скачать заявку"
                placeholder="Введите наименование шаблона заявки"
                label="Шаблон заявки типа заказа 2"
                name="orderApplicationSmzVolumeOfWorkBasedId"
                onChange={onChange}
                options={allOptionsInfo.orderApplicationSmzVolumeOfWorkBasedId}
                value={form.orderApplicationSmzVolumeOfWorkBasedId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                isFetch={false}
                clientIdFilter={clientId}
                orderKindFilter={ORDER_KIND.AMOUNT_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.SMZ}
                buttonText="Скачать акт"
                placeholder="Введите наименование шаблона акта"
                label="Шаблон акта НПД типа заказа 1"
                name="actOfAcceptanceOfWorkSmzAmountBasedId"
                onChange={onChange}
                options={allOptionsInfo.actOfAcceptanceOfWorkSmzAmountBasedId}
                value={form.actOfAcceptanceOfWorkSmzAmountBasedId}
                size="xl"
            />
            <TemplatesDropdown
                className="mb-3 mb-md-4"
                clientIdFilter={clientId}
                isFetch={false}
                orderKindFilter={ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER}
                documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.SMZ}
                buttonText="Скачать акт"
                placeholder="Введите наименование шаблона акта"
                label="Шаблон акта НПД типа заказа 2"
                name="actOfAcceptanceOfWorkSmzVolumeOfWorkBasedId"
                onChange={onChange}
                options={allOptionsInfo.actOfAcceptanceOfWorkSmzVolumeOfWorkBasedId}
                value={form.actOfAcceptanceOfWorkSmzVolumeOfWorkBasedId}
                size="xl"
            />
        </>
    );
};

export default OrderListMassTemplatePublicationNPD;