import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {downloadBlob, downloadByUrl} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/documents/export/partitioned";
//*  TYPES  *//

const GET_EXPORT_DOCUMENTS_LIST_REQUEST = "GET_EXPORT_DOCUMENTS_LIST_REQUEST";
const GET_EXPORT_DOCUMENTS_LIST_SUCCESS = "GET_EXPORT_DOCUMENTS_LIST_SUCCESS";

const GET_EXPORT_DOCUMENTS_DICT_STATUS_REQUEST = "GET_EXPORT_DOCUMENTS_DICT_STATUS_REQUEST";
const GET_EXPORT_DOCUMENTS_DICT_STATUS_SUCCESS = "GET_EXPORT_DOCUMENTS_DICT_STATUS_SUCCESS";

const ADD_EXPORT_DOCUMENTS_REQUEST = "ADD_EXPORT_DOCUMENTS_REQUEST";
const ADD_EXPORT_DOCUMENTS_SUCCESS = "ADD_EXPORT_DOCUMENTS_SUCCESS";

const DOWNLOAD_EXPORT_DOCUMENTS_REQUEST = "DOWNLOAD_EXPORT_DOCUMENTS_REQUEST";
const DOWNLOAD_EXPORT_DOCUMENTS_SUCCESS = "DOWNLOAD_EXPORT_DOCUMENTS_SUCCESS";

const GET_EXPORT_DOCUMENTS_PROGRESS_LIST_REQUEST = "GET_EXPORT_DOCUMENTS_PROGRESS_LIST_REQUEST";
const GET_EXPORT_DOCUMENTS_PROGRESS_LIST_SUCCESS = "GET_EXPORT_DOCUMENTS_PROGRESS_LIST_SUCCESS";

const EXPORT_MAP_OBJECTS_DOCUMENT_REQUEST = "EXPORT_MAP_OBJECTS_DOCUMENT_REQUEST";
const EXPORT_MAP_OBJECTS_DOCUMENT_SUCCESS = "EXPORT_MAP_OBJECTS_DOCUMENT_SUCCESS";
const EXPORT_MAP_OBJECTS_DOCUMENT_ERROR = "EXPORT_MAP_OBJECTS_DOCUMENT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    exportDocumentList: [],
    pageData: {},
    totalCount: 0,
    statusDict: {},
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_EXPORT_DOCUMENTS_LIST_REQUEST:
        const {pageSize, pageNum} = payload;

        return {
            ...state,
            pageData: {
                pageSize,
                pageNum,
            },
        };
    case EXPORT_MAP_OBJECTS_DOCUMENT_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_EXPORT_DOCUMENTS_LIST_SUCCESS:
        const {results, totalCount} = payload;

        return {
            ...state,
            exportDocumentList: results,
            totalCount,
        };
    case GET_EXPORT_DOCUMENTS_DICT_STATUS_SUCCESS:
        return {
            ...state,
            statusDict: payload,
        };
    case EXPORT_MAP_OBJECTS_DOCUMENT_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case EXPORT_MAP_OBJECTS_DOCUMENT_ERROR:
        return {
            ...state,
            progress: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getExportDocumentPage(payload) {
    return {
        type: GET_EXPORT_DOCUMENTS_LIST_REQUEST,
        payload,
    };
}

export function getExportDocumentStatusDict() {
    return {
        type: GET_EXPORT_DOCUMENTS_DICT_STATUS_REQUEST,
    };
}

export function addExportDocument(payload) {
    return {
        type: ADD_EXPORT_DOCUMENTS_REQUEST,
        payload,
    };
}

export function downloadExportDocument(payload) {
    return {
        type: DOWNLOAD_EXPORT_DOCUMENTS_REQUEST,
        payload,
    };
}

export function getProgressListExportDocument(payload) {
    return {
        type: GET_EXPORT_DOCUMENTS_PROGRESS_LIST_REQUEST,
        payload,
    };
}

export function exportMapObjectsDocument(payload) {
    return {
        type: EXPORT_MAP_OBJECTS_DOCUMENT_REQUEST,
        payload,
    };
}
//*  SELECTORS  *//

export const adminExportDocumentsSelector = state => state.adminExportDocuments;
export const adminExportDocumentsListSelector = createSelector(adminExportDocumentsSelector, ({exportDocumentList}) => exportDocumentList);
export const adminExportDocumentsTotalPagesSelector = createSelector(adminExportDocumentsSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));
export const adminExportDocumentsTotalCountSelector = createSelector(adminExportDocumentsSelector, ({totalCount}) => totalCount);
export const adminExportDocumentsStatusDictSelector = createSelector(adminExportDocumentsSelector, ({statusDict}) => statusDict);
export const progressExportDocumentsSelector = createSelector(adminExportDocumentsSelector, ({progress}) => progress);

//*  SAGA  *//

//POST /api/documents/export/partitioned/page/rich
const getExportDocumentPageSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/page/rich`, data);

        yield getResult(result);

        yield put({type: GET_EXPORT_DOCUMENTS_LIST_SUCCESS, payload: result});
    } catch (error) {
        console.error("error getExportDocumentPageSaga: ", error.message);
    }
};

///api/documents/export/partitioned/dict/status

const getExportDocumentStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dict/status`);

        yield put({type: GET_EXPORT_DOCUMENTS_DICT_STATUS_SUCCESS, payload: result});
    } catch (error) {
        console.error("error getExportDocumentDictStatusSaga: ", error.message);
    }
};

//POST /api/documents/export/partitioned/add
const addExportDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/add`, data);

        yield getResult(result);

        yield put({type: ADD_EXPORT_DOCUMENTS_SUCCESS, payload: result});
    } catch (error) {
        console.error("error getExportDocumentPageSaga: ", error.message);
    }
};

//POST /api/filestore/export/partitioned/partition/get
const downloadExportDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };
        const result = yield request.getFile(`${window.location.origin}/api/filestore/export/partitioned/getLink`, requestHeaders);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                return {
                    done: true,
                };
            }
        }

        const url = yield result.text();

        yield downloadByUrl(url, data.adminExportName);

        yield put({type: DOWNLOAD_EXPORT_DOCUMENTS_SUCCESS, payload: result});
    } catch (error) {
        console.error("error downloadExportDocumentSaga: ", error.message);
    }
};

//POST
// /api/documents/export/partitioned/progress/list
// getProgressList
const getProgressListExportDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            list,
        } = payload;

        const result = yield request.post(`${controller}/progress/list`, list);

        yield getResult(result);

        yield put({type: GET_EXPORT_DOCUMENTS_PROGRESS_LIST_SUCCESS, payload: result});
    } catch (error) {
        console.error("error getProgressListExportDocumentSaga: ", error.message);
    }
};

//POST /api/export/getContractorOrderAdvertExport
const exportMapObjectsDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            fileName,
            ...data
        } = payload;

        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };
        const result = yield request.getFile(`${window.location.origin}/api/export/getContractorOrderAdvertExport`, requestHeaders);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);

                yield put({
                    type: EXPORT_MAP_OBJECTS_DOCUMENT_ERROR,
                    payload: errorMessage,
                });
                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();

        yield downloadBlob(blob, headers, fileName);

        yield put({type: EXPORT_MAP_OBJECTS_DOCUMENT_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: EXPORT_MAP_OBJECTS_DOCUMENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_EXPORT_DOCUMENTS_LIST_REQUEST, getExportDocumentPageSaga),
        takeEvery(GET_EXPORT_DOCUMENTS_DICT_STATUS_REQUEST, getExportDocumentStatusDictSaga),
        takeEvery(ADD_EXPORT_DOCUMENTS_REQUEST, addExportDocumentSaga),
        takeEvery(DOWNLOAD_EXPORT_DOCUMENTS_REQUEST, downloadExportDocumentSaga),
        takeEvery(GET_EXPORT_DOCUMENTS_PROGRESS_LIST_REQUEST, getProgressListExportDocumentSaga),
        takeEvery(EXPORT_MAP_OBJECTS_DOCUMENT_REQUEST, exportMapObjectsDocumentSaga),
    ]);
}
