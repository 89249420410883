import React from "react";

import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import NmCheck from "../NmCheck";

import {
    useContractorPassportCheckingForm,
} from "../../containers/contractor/contractor-general-info/components/passport-checking/hooks/useForm";

import bem from "../../utils/bem";

import "./style.sass";

const ContractorPassportStatus = (props) => {
    const {
        contractor,
        className = "",
        size = "lg",
        isOnlyIcon = false,
    } = props;

    const [block, element] = bem("contractor-passport-status", className);

    const {
        isPassportSuccess,
        passportSuccessText,
        passportErrorText,
    } = useContractorPassportCheckingForm({contractor});

    return (
        <NmAdvancedTooltip
            hover
            className={block()}
            position="bottom-left"
            trigger={
                <NmCheck
                    classNameIcon={element("icon", {size})}
                    isCheck={isPassportSuccess}
                    label={!isOnlyIcon && "Паспорт проверен"}
                />
            }
            children={isPassportSuccess ? passportSuccessText : passportErrorText}
        />
    );
};

export default ContractorPassportStatus;