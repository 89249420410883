import React, {Component} from "react";
import ReactDOM from "react-dom";
import Picker, {SkinTonePickerLocation,SkinTones} from "emoji-picker-react";

import {ReactComponent as EmojiIcon} from "../../images/smile_24.svg";

import "./style.sass";

class EmojiPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenPicker: false,
        };
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, false);
    }

    setIsOpenPicker = (isOpenPicker) => {
        this.setState({isOpenPicker});
    };

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);

        if ((!domNode || !domNode.contains(event.target))) {
            this.setIsOpenPicker(false);
        }
    };

    onEmojiClick = (emojiObject) => {
        const {onSelectEmoji} = this.props;

        if (emojiObject.unified === "263a-fe0f") {
            onSelectEmoji({...emojiObject, emoji: String.fromCodePoint(0x0001F60A)});
        } else {
            onSelectEmoji(emojiObject);
        }
    };

    onClickIcon = () => {
        this.setIsOpenPicker(!this.state.isOpenPicker);
    };

    render() {
        const {className} = this.props;
        const {isOpenPicker} = this.state;

        return (
            <div className={`${className} emoji-picker`}>
                <EmojiIcon
                    className="ml-2 emoji-picker__icon"
                    onSelectEmoji={this.onSelectEmoji}
                    onClick={this.onClickIcon}
                />
                {isOpenPicker && <div className="emoji-picker__picker">
                    <Picker
                        disableSearchBar
                        onEmojiClick={this.onEmojiClick}
                        disableAutoFocus={true}
                        skinTone={SkinTones.NEUTRAL}
                        skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
                        emojiVersion="12.0"
                        searchDisabled
                    />
                </div>}
            </div>
        );
    }
};

export default EmojiPicker;
