import * as yup from "yup";

import {REG_EXP} from "../../../../../../constants/regExp";
import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

export const getRecruitmentExperienceValidation = (card) => {
    return yup.object().shape({
        organisation: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .matches(REG_EXP.NUMBERS_LETTERS_PUNCTUATION, {
                message: "Доступен ввод только цифр, букв, запятых",
                excludeEmptyString: false,
            })
            .max(50, "Максимальная длина 50 символов"),
        position: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .matches(REG_EXP.NUMBERS_LETTERS_PUNCTUATION, {
                message: "Доступен ввод только цифр, букв, запятых",
                excludeEmptyString: false,
            })
            .max(50, "Максимальная длина 50 символов"),
        yearStart: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("checkCurrentYear", "Год начала работы не может быть больше текущего года.", function (value) {
                if (!value) {
                    return true;
                }

                const currentYear = new Date().getFullYear();

                return +value <= currentYear;
            })
            .test("checkCandidateAge", "Год начала работы должен быть больше года рождения кандидата.", function (value) {
                if (!value || !card.age) {
                    return true;
                }

                const currentYear = new Date().getFullYear();

                const birthYear = currentYear - card.age;

                return +value > birthYear;
            })
            .test("checkYearEnd", "Год начала работы не может быть больше года окончания работы.", function (value) {
                if (
                    !this.parent.yearEnd ||
                    !value
                ) {
                    return true;
                }

                if (+value > +this.parent.yearEnd) {
                    return false;
                }

                return true;
            }),
        monthStart: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("checkMaxYearMonth", "Месяц начала работы не может быть позже текущего месяца.", function (value) {
                if (
                    !this.parent.yearStart ||
                    !value
                ) {
                    return true;
                }

                const currentYear = new Date().getFullYear();
                const currentMonth = new Date().getMonth() + 1;

                if (
                    +this.parent.yearStart === currentYear &&
                    +value > currentMonth
                ) {
                    return false;
                }

                return true;
            })
            .test("checkMonthStartNotOlderThanMonthEnd", "Месяц начала работы не может быть позже месяца окончания работ.", function (value) {
                if (
                    !this.parent.yearStart ||
                    !this.parent.yearEnd ||
                    !this.parent.monthEnd ||
                    !value
                ) {
                    return true;
                }

                if (
                    +this.parent.yearStart === +this.parent.yearEnd &&
                    +value > +this.parent.monthEnd
                ) {
                    return false;
                }

                return true;
            }),
        yearEnd: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("checkCurrentYear", "Год окончания работы не может быть больше текущего года.", function (value) {
                if (!value) {
                    return true;
                }

                const currentYear = new Date().getFullYear();

                return +value <= currentYear;
            })
            .test("checkYearEndMustBeLessThanYearStart", "Год окончания работы не может быть меньше года начала работы.", function (value) {
                if (
                    !this.parent.yearStart ||
                    !value
                ) {
                    return true;
                }

                if (+value < +this.parent.yearStart) {
                    return false;
                }

                return true;
            })
            .test("checkCandidateAge", "Год окончания работы должен быть больше года рождения кандидата.", function (value) {
                if (!value || !card.age) {
                    return true;
                }

                const currentYear = new Date().getFullYear();

                const birthYear = currentYear - card.age;

                return +value > birthYear;
            }),
        monthEnd: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("checkMaxYearMonth", "Месяц окончания работы не может быть позже текущего месяца.", function (value) {
                if (
                    !this.parent.yearEnd ||
                    !value
                ) {
                    return true;
                }

                const currentYear = new Date().getFullYear();
                const currentMonth = new Date().getMonth() + 1;

                if (
                    +this.parent.yearEnd === currentYear &&
                    +value > currentMonth
                ) {
                    return false;
                }

                return true;
            }),
    });
};