import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {
    bffCommonDictsKedoDocumentsCreatedViaTypesOptionsSelector,
    bffCommonDictsKedoOfficeTypesOptionsSelector,
    bffCommonDictsKedoSignatureTransactionsActionTypesOptionsSelector,
    bffCommonDictsKedoSignatureTransactionsDecideTypesOptionsSelector,
    bffCommonDictsKedoSignatureTypesOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoStaffSignatureTransactionsFilter = () => {
    const signaturesTypes = useSelector(bffCommonDictsKedoSignatureTypesOptionsSelector);
    const officeTypes = useSelector(bffCommonDictsKedoOfficeTypesOptionsSelector);
    const createdViaTypes = useSelector(bffCommonDictsKedoDocumentsCreatedViaTypesOptionsSelector);
    const actionTypes = useSelector(bffCommonDictsKedoSignatureTransactionsActionTypesOptionsSelector);
    const decideTypes = useSelector(bffCommonDictsKedoSignatureTransactionsDecideTypesOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({});

    const filters = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "Наименование или номер документа",
                    placeholder: "Введите наименование",
                    name: "documentNameOrNum",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    multiple: true,
                    search: true,
                    options: actionTypes,
                    name: "actionTypes",
                    label: "Действие подписанта",
                    placeholder: "Выберите действие",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    multiple: true,
                    search: true,
                    options: signaturesTypes,
                    name: "signatureTypes",
                    label: "Тип подписи",
                    placeholder: "Выберите тип подписи",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    multiple: true,
                    search: true,
                    options: createdViaTypes,
                    name: "documentFileCreatedViaTypes",
                    label: "Тип загрузки документов",
                    placeholder: "Выберите тип загрузки документов",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    multiple: true,
                    search: true,
                    options: officeTypes,
                    name: "officeTypes",
                    label: "Тип кабинета",
                    placeholder: "Выберите тип",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    multiple: true,
                    search: true,
                    options: decideTypes,
                    name: "decideTypes",
                    label: "Статус",
                    placeholder: "Выберите статус",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    dateFormat: "dd.MM.yy",
                    startFieldName: "createdAtDateFrom",
                    endFieldName: "createdAtDateTo",
                    label: "Дата события",
                    isClearable: true,
                    value: {
                        fromDateFilter: null,
                        toDateFilter: null,
                    },
                },
            ],
        },
    ];

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};