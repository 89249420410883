import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_ERROR,
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/contractors-registry/contractor/taskHistory/";

// POST /bff/adm/contractors-registry/contractor/taskHistory/getPage
// Получение страницы истории заданий для исполнителя
const getPageContractorTaskHistory = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CONTRACTOR_TASK_HISTORY_GET_PAGE_REQUEST, getPageContractorTaskHistory),
    ]);
}