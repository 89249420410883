import {
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST,
    CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE,
    UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE,
} from "./actions";

export const getAdditionalAgreements = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST,
        payload,
    };
};

export const submitAdditionalAgreements = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST,
        payload,
    };
};

export const getAdditionalAgreementStatusDict = () => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_REQUEST,
    };
};

export const addAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST,
        payload,
    };
};

export const updateAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST,
        payload,
    };
};

export const updateAdditionalAgreementsStore = (payload) => {
    return {
        type: UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE,
        payload,
    };
};

export const clearAdditionalAgreementsStore = (payload) => {
    return {
        type: CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE,
        payload,
    };
};