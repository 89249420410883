import {useState} from "react";

import {useTemplatesClientUsageFetch} from "./useFetch";
import {useTemplatesClientUsageFilter} from "./useFilter";

import useConfirm, {openConfirmAction} from "../../../hooks/useConfirm";
import {usePagination} from "../../../hooks/usePagination";

import {TEMPLATES_CLIENT_USAGE_CONFIRM_MESSAGE} from "../../../containers/templates/constants";

export default function useTemplatesClientUsageForm(params) {
    const {
        orderType,
        onClose,
        fetchTemplates,
        updateClientUsage,
        fetchList,
    } = params;

    const [selectedList, setSelectedList] = useState([]);
    const [changed, setChanged] = useState([]);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm();

    const updateChanged = ({item, updatedFieldName}) => {
        const _changed = [...changed];
        const existsItemIndex = _changed.findIndex(value => (value.clientId === item.clientId));

        if (existsItemIndex !== -1) {
            const existsItem = _changed[existsItemIndex];

            _changed[existsItemIndex] = {
                ...existsItem,
                [updatedFieldName]: !existsItem[updatedFieldName],
            };

            setChanged(_changed);

            return;
        }

        const {
            customDocumentTemplateId,
            documentTypeId,
            clientId,
            defaultTemplate,
            enabled,
        } = item;

        const result = {
            documentTypeId,
            customDocumentTemplateId,
            clientId,
            defaultTemplate,
            enabled,
            [updatedFieldName]: !item[updatedFieldName],
        };

        _changed.push(result);

        setChanged(_changed);
    };

    const updateSelectedList = ({list, updatedFieldName, index}) => {
        const _list = [...list];

        _list[index][updatedFieldName] = !_list[index][updatedFieldName];

        setSelectedList(_list);
    };

    const onSelectRow = (list, index) => {
        updateChanged({item: list[index], updatedFieldName: "enabled"});
        updateSelectedList({list, index, updatedFieldName: "enabled"});
    };

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        filterData,
        isSearch,
        onSearch,
        filter,
        onChangeFilter,
    } = useTemplatesClientUsageFilter({
        pageSize,
        setPagination,
    });

    const {
    } = useTemplatesClientUsageFetch({
        filterData,
        pageNum,
        pageSize,
        orderType,
        fetchList,
    });

    const onClickAddToDefault = (item, index) => {
        const {
            defaultTemplate,
            clientName,
            customDocumentTemplateName,
            defaultCustomDocumentTemplateName,
            customDocumentTemplateId,
            defaultCustomDocumentTemplateId,
            isSelected,
        } = item;

        if (!isSelected) {
            return;
        }

        if (defaultTemplate) {
            const message = TEMPLATES_CLIENT_USAGE_CONFIRM_MESSAGE.DELETE_FROM_DEFAULT
                .replace(":clientName", clientName)
                .replace(":customDocumentTemplateName", customDocumentTemplateName);

            dispatchConfirm(
                openConfirmAction(message,
                    () => {
                        updateChanged({item, updatedFieldName: "defaultTemplate"});
                        updateSelectedList({list: selectedList, index, updatedFieldName: "defaultTemplate"});
                    },
                ),
            );

            return;
        }

        const updateDefaultTemplate = () => {
            updateChanged({item, updatedFieldName: "defaultTemplate"});
            updateSelectedList({list: selectedList, index, updatedFieldName: "defaultTemplate"});
        };

        // Если нет выбранного шаблона по умолчанию, не нужно отображать конфирм
        if (!defaultCustomDocumentTemplateName || defaultCustomDocumentTemplateId === customDocumentTemplateId) {
            updateDefaultTemplate();

            return;
        }

        const message = TEMPLATES_CLIENT_USAGE_CONFIRM_MESSAGE.ADD_TO_DEFAULT
            .replace(":clientName", clientName)
            .replace(":defaultCustomDocumentTemplateName", defaultCustomDocumentTemplateName)
            .replace(":customDocumentTemplateName", customDocumentTemplateName);

        dispatchConfirm(
            openConfirmAction(message,
                () => {
                    updateDefaultTemplate();
                },
            ),
        );
    };

    const onSubmit = () => {
        updateClientUsage({
            changed,
            onSuccess: () => {
                fetchTemplates();
                onClose();
            },
        });
    };

    return {
        pageSize,
        pageNum,
        onChangePageSize,
        onPaginationChange,
        isSearch,
        onSearch,
        filter,
        onSubmit,
        onClickAddToDefault,
        onChangeFilter,
        selectedList,
        setSelectedList,
        onSelectRow,
        contentConfirm,
        dispatchConfirm,
        isOpenConfirm,
    };
}
