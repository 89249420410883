import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../../../components/ActualComponents/DropzoneV2";
import NmDatePicker from "../../../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {useDataContractorFingerprintingInfoEditForm} from "./hooks/useData";

import {
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../../../constants/clientList";

import {
    contractorAdditionalDocumentsProgressUpdateDataSelector,
} from "../../../../../../../ducks/contractorAdditionalDocuments";
import {fileContractorScanProgressAddSelector} from "../../../../../../../ducks/fileStore";

const ContractorFingerprintingInfoEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
        data,
    } = props;

    const progress = useSelector(contractorAdditionalDocumentsProgressUpdateDataSelector);
    const progressScan = useSelector(fileContractorScanProgressAddSelector);

    const {
        files,
        onDropScan,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        handleSubmit,
        handleChange,
    } = useDataContractorFingerprintingInfoEditForm({
        contractorId,
        data,
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Дактилоскопия (доп. документ)
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress || progressScan}
        >
            <NmForm addMargin>
                <NmInputV2
                    required
                    size="xl"
                    label="Номер документа о прохождении дактилоскопии (лицевая сторона)"
                    value={values.fingerprintingDocumentNum}
                    name="fingerprintingDocumentNum"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("fingerprintingDocumentNum", true)}
                    error={
                        touched?.fingerprintingDocumentNum &&
                        errors?.fingerprintingDocumentNum
                    }
                />
                <NmDatePicker
                    size="xl"
                    dateFormatMask="99.99.9999"
                    name="fingerprintingDocumentIssuedDate"
                    required
                    maxDate={new Date()}
                    label="Дата выдачи документа о прохождении дактилоскопии"
                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                    onBlur={() => setFieldTouched("fingerprintingDocumentIssuedDate", true)}
                    error={
                        touched?.fingerprintingDocumentIssuedDate &&
                        errors?.fingerprintingDocumentIssuedDate
                    }
                    selected={values.fingerprintingDocumentIssuedDate}
                />
                <NmInputV2
                    required
                    size="xl"
                    label="Кем выдан документ о прохождении дактилоскопии"
                    value={values.fingerprintingDocumentIssuedBy}
                    name="fingerprintingDocumentIssuedBy"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("fingerprintingDocumentIssuedBy", true)}
                    error={
                        touched?.fingerprintingDocumentIssuedBy &&
                        errors?.fingerprintingDocumentIssuedBy
                    }
                />
                <DropzoneV2
                    isTopImagePreview
                    label="Фотография лицевой стороны документа о прохождении дактилоскопии"
                    isVisibleLabel
                    files={files[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN]}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={(files) => onDropScan(files, CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN)}
                    uploadBtnText={isEmpty(files[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN]) ? "Выбрать файл" : "Заменить"}
                    error={
                        touched?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN] &&
                        errors?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN]
                    }
                />
                <DropzoneV2
                    isTopImagePreview
                    label="Фотография обратной стороны документа о прохождении дактилоскопии"
                    isVisibleLabel
                    files={files[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN]}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={(files) => onDropScan(files, CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN)}
                    uploadBtnText={isEmpty(files[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN]) ? "Выбрать файл" : "Заменить"}
                    error={
                        touched?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN] &&
                        errors?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN]
                    }
                />
            </NmForm>
        </NmModal>
    );
};

export default ContractorFingerprintingInfoEditForm;