import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";

import {orderTemplatesOptionsSelector} from "../../../../../../../ducks/order";

const OrderFormPreConditionsTemplate = (props) => {
    const {
        isDisabled,
        formData,
        formError,
        className,
        onChange,
        isTemplate,
    } = props;
    const templateOptions = useSelector(orderTemplatesOptionsSelector);

    return (
        <NmDropdownV2
            required={!isTemplate}
            disabled={isDisabled || !formData.objectId}
            placeholder="Выберите из списка"
            label="Шаблон заказа"
            name="templateId"
            onChange={onChange}
            className={className}
            options={templateOptions}
            value={formData.templateId}
            error={formError.templateId}
        />
    );
};

export default OrderFormPreConditionsTemplate;