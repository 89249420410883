import React from "react";
import {Route, Switch, withRouter} from "react-router";

import DocumentManagementDocumentList from "../../document-management-document-list";
import DocumentManagementStatementList from "../../document-management-statement/list";
import EdmStaffCardPersonalData from "../edm-staff-card-personal-data";

import {
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_APPS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_PERS_DATA,
} from "../../../../constants/links";

const  EdfStaffCardRoute = (props) => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS}
                component={DocumentManagementDocumentList}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_APPS}
                component={DocumentManagementStatementList}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_PERS_DATA}
                component={EdmStaffCardPersonalData}
            />
        </Switch>
    );
};

export default withRouter(EdfStaffCardRoute);