import React from "react";
import {useSelector} from "react-redux";

import {orderStatusSelector} from "../../../../ducks/order";

import "./style.sass";

const CLASSNAME_BY_ORDER_STATUS = {
    DRAFT: "order-list-status_draft",
    PUBLISHED: "order-list-status_published",
    CLOSED: "order-list-status_closed",
    CONTRACTOR_SEARCH: "order-list-status_contractor-search",
    AWAIT: "order-list-status_contractor-search",
    AWAIT_PUBLISH: "order-list-status_contractor-search",
};

const ADVERTISEMENT_BY_ORDER_STATUS = {
    DRAFT: "Черновик",
    PUBLISHED: "Опубликовано",
    CLOSED: "Закрыто",
    AWAIT_PUBLISH: "Ожидание запуска",
};

const OrderListStatus = ({status, isAdvertisement}) => {
    const statusDict = useSelector(orderStatusSelector);

    return (
        <div className={`order-list-status ${CLASSNAME_BY_ORDER_STATUS[status]}`}>
            {isAdvertisement ? ADVERTISEMENT_BY_ORDER_STATUS[status] : statusDict[status]}
        </div>
    );
};

export default OrderListStatus;