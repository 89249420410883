import {getPersonalDataStep} from "../../../../../../components/FullRegistration/utils/getPersonalDataStep";
import {formatDateWithoutTime} from "../../../../../../utils/dateFormat";

export const getFullRegistrationPassportFields = (params) => {
    const {
        values,
        citizenship,
        migrationStatus,
        localizationData,
        fields = [
            "num",
            "issuedBy",
            "issuedDate",
            "validToDate",
        ],
    } = params;

    const {
        num,
        issuedDate,
        validToDate,
        issuedBy,
    } = getPersonalDataStep({
        citizenship,
        migrationStatus,
        isCheckPersonalData: true,
    });

    const resultFields = [
        {
            title: localizationData.passportNumber,
            value: values[num],
            name: "num",
        },
        {
            title: localizationData.idDocIssuedBy,
            value: values[issuedBy],
            name: "issuedBy",
        },
        {
            title: localizationData.idDocIssuedDate,
            value: formatDateWithoutTime(values[issuedDate]),
            name: "issuedDate",
        },
        {
            title: localizationData.idDocValidToDate,
            value: formatDateWithoutTime(values[validToDate]),
            name: "validToDate",
        },
    ];

    return resultFields
        .filter(item => fields.includes(item.name))
        .map(item => {
            return {
                title: item.title,
                value: item.value,
            };
        });
};