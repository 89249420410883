export const PATENTS_EXPORT_DOCUMENTS_STATUS = {
    IN_PROGRESS: {
        TEXT: "Инициализация",
        VALUE: "IN_PROGRESS",
        COLOR: "gray",
    },
    FINISHED: {
        TEXT: "Успешно",
        VALUE: "FINISHED",
        COLOR: "light-green",
    },
    FAILED: {
        TEXT: "Неуспешно",
        VALUE: "FAILED",
        COLOR: "red",
    },
    CANCELLED: {
        TEXT: "Отменено",
        VALUE: "CANCELLED",
        COLOR: "red",
    },
};