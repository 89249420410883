import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {REVIEW_STATUS} from "../index";

import {isNMUsers} from "../../../../utils/access";

import {getContractorScoresPage} from "../../../../ducks/contractorScores";

export function useContractorReviewsFetchList({contractorId, pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getContractorScoresPage({
            contractorIdFilter: contractorId,
            pageNum,
            pageSize,
            statusesFilter: isNMUsers() ? [REVIEW_STATUS.PUBLISHED, REVIEW_STATUS.DRAFT] : [REVIEW_STATUS.PUBLISHED],
        }));
    };

    return {
        fetchList,
    };
}