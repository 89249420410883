import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import ProfileBox from "../../../../../components/ActualComponents/ProfileBox";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";

import {isLastElementInArray} from "../../../../../utils/objectHelper";
import {getProfileValues} from "../../utils/getProfileValues";

import {bffCommonDictsKedoRoleDictSelector} from "../../../../../ducks/bff/common/dicts/selectors";
import {bffKedoStaffCardSelector} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffProfile = () => {
    const card = useSelector(bffKedoStaffCardSelector);

    const roleDict = useSelector(bffCommonDictsKedoRoleDictSelector);

    const fields = getProfileValues({
        card,
        roleDict,
    });

    const getInfo = () => {
        return fields.map((item, index) => {
            const isLast = isLastElementInArray(index, fields);

            return (
                <NmMiniInfoCard
                    key={item.title}
                    className={isLast ? "" : "mb-3 mb-md-4"}
                    title={item.title}
                    titleLevel="4"
                >
                    <ContractorInfoCardContent
                        labels={item.labels}
                        scans={item.scans}
                    />
                </NmMiniInfoCard>
            );
        });
    };

    return (
        <ProfileBox>
            {getInfo()}
        </ProfileBox>
    );
};