import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    exportOnboardingScenarioRecruitersList,
    getOnboardingScenarioRecruitersList,
    updateOnboardingRecruitersStore,
} from "../../../../../../../ducks/onboardingRecruiters";

import {downloadDocument} from "../../../../../../../ducks/documents";

export function useFetchOnboardingRecruitersList({filter = {}, pageSize, pageNum, clientId, scenarioId, sortType}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateOnboardingRecruitersStore({
                list: [],
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter, sortType, scenarioId]);

    const downloadQRCode = (fileName, link) => {
        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink: link,
        }));
    };

    const exportRecruitersList = () => {
        dispatch(exportOnboardingScenarioRecruitersList({
            clientId,
            scenarioId,
        }));
    };

    const fetchList = () => {
        dispatch(getOnboardingScenarioRecruitersList({
            pageNum,
            pageSize,
            sortType: sortType ? sortType.toUpperCase() : undefined,
            clientIdFilter: clientId,
            scenarioIdFilter: scenarioId,
            ...filter,
        }));
    };

    return {
        fetchList,
        downloadQRCode,
        exportRecruitersList,
    };
}