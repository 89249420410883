import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../../components/NmTitle";
import {
    onboardingRecruitersListProgressSelector,
    onboardingRecruitersProgressActionSelector,
} from "../../../../../../../../ducks/onboardingRecruiters";

import {useOnboardingScenarioEditFormData} from "../../../../../edit-form/hooks/useData";
import {useOnboardingScenarioRecruiterEditFormData} from "./hooks/useData";

const OnboardingScenarioRecruiterEditForm = (props) => {
    const {
        card,
        clientId,
        scenarioId,
        onClose = () => {
        },
        fetchList,
    } = props;

    const progress = useSelector(onboardingRecruitersProgressActionSelector);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
    } = useOnboardingScenarioRecruiterEditFormData({card, clientId, scenarioId, fetchList, onClose});

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Добавление рекрутера
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={"Добавить"}
                    cancelBtnContent="Отменить"
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                <NmTextareaV2
                    size="xl"
                    required
                    value={values.fullName}
                    name="fullName"
                    label="Рекрутер"
                    placeholder="Введите ФИО рекрутера"
                    maxLength={50}
                    onChange={handleChange}
                    error={touched?.fullName && errors?.fullName}
                />
            </NmForm>
        </NmModal>
    );
};

export default OnboardingScenarioRecruiterEditForm;