import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdTasksSelector = state => state.bff.crowd.tasks;
export const bffCrowdTasksListSelector = createSelector(bffCrowdTasksSelector, ({list}) => list);
export const bffCrowdTasksTotalCountSelector = createSelector(bffCrowdTasksSelector, ({totalCount}) => totalCount);
export const bffCrowdTasksTotalPagesSelector = createSelector(bffCrowdTasksSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdTasksProgressSelector = createSelector(bffCrowdTasksSelector, ({progress}) => progress);
export const bffCrowdTasksProgressActionSelector = createSelector(bffCrowdTasksSelector, ({progressAction}) => progressAction);
export const bffCrowdTasksCardSelector = createSelector(bffCrowdTasksSelector, ({card}) => card);
export const bffCrowdTasksProgressCardSelector = createSelector(bffCrowdTasksSelector, ({progressCard}) => progressCard);
export const bffCrowdTasksTotalAmountSelector = createSelector(bffCrowdTasksSelector, ({totalAmount}) => totalAmount);
export const bffCrowdTasksDepositStateSelector = createSelector(bffCrowdTasksSelector, ({depositState}) => depositState);
export const bffCrowdTasksDepositStateProgressSelector = createSelector(bffCrowdTasksSelector, ({depositStateProgress}) => depositStateProgress);
export const bffCrowdTasksTotalPaymentAmountSelector = createSelector(bffCrowdTasksSelector, ({totalTasksPaymentAmount}) => totalTasksPaymentAmount);