import React from "react";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import ExtLink from "../../../../components/ExtLink";
import NmButton from "../../../../components/NmButton";
import {Divider} from "semantic-ui-react";

import dateFormat, {formatLocalDate} from "../../../../utils/dateFormat";
import {getTimeFromMins} from "../../../../utils/mathHelper";
import {formatAmount, formatNumber, getFullName} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CONTRACTOR_PROFILE,
} from "../../../../constants/links";
import {MARKER_TYPE} from "../../constants";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../../constants/link-params";

const MapTaskListCard = (props) => {
    const {
        task,
        isModalCard = false,
        isShowInviteButton = false,
        onOpenInviteModal,
    } = props;

    const {
        clientId,
        taskId,
        publicationDateTime,
        name,
        number,
        speciality,
        timeForExecutionMinutes,
        paymentAmount,
        workStartDate,
        workEndDate,
        invitedContractorsCount,
        inQueueContractorsCount,
        workingContractorsCount,
        workingContractorId,
        workContractorFirstName,
        workContractorLastName,
        workContractorPatronymic,
        workContractorFullName,
        projectId,
        projectName,
        objectId,
        objectName,
    } = task;

    const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
        .replace(":clientId", clientId)
        .replace(":taskId", taskId)
        .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);
    const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", workingContractorId);
    const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
        .replace(":clientId", clientId)
        .replace(":projectId", projectId);
    const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST
        .replace(":clientId", clientId)
        .replace(":projectId", projectId)
        .replace(":objectId", objectId);

    const openInviteModal = () => {
        onOpenInviteModal({
            ...task,
            type: MARKER_TYPE.CROWD_TASK,
        });
    };

    const getLink = (content, link) => {
        return (
            <ExtLink
                title={content}
                historyEnabled
                to={link}
            >
                {content}
            </ExtLink>
        );
    };

    const modalCardLabels = isModalCard ? [
        {
            label: "Сумма задания, ₽",
            text: formatAmount(paymentAmount, true),
        },
        {
            label: "Количество исполнителей на задании",
            text: `Приглашенные / В очереди / В работе: ${invitedContractorsCount || "-"} / ${inQueueContractorsCount || "-"} / ${workingContractorsCount || "-"}`,
            noWrap: false,
            flexWrap: true,
        },
        workingContractorId && {
            text: <Divider />,
        },
    ] : [];

    return (
        <NmListCard
            alignItems="flex-end"
            secondaryHeader={
                publicationDateTime &&
                `Задание от: ${formatLocalDate(publicationDateTime, "dd.MM.yyyy HH:mm")}`
            }
            primaryHeader={getLink(`№${number} - ${name}`, taskLink)}
            classNameMainContent={`col-16 ${!isModalCard ? "col-xxl-5" : ""}`}
            labels={[
                {
                    label: "Период выполнения задания",
                    text: `${dateFormat(workStartDate, "dd.MM.yyyy")} - ${dateFormat(workEndDate, "dd.MM.yyyy")}`,
                },
                {
                    label: "Проект",
                    text: projectId ? getLink(projectName, projectLink) : "-",
                },
                {
                    label: "Объект",
                    text: objectId ? getLink(objectName, objectLink) : "-",
                },
                {
                    label: "Вид деятельности",
                    text: speciality?.value || "-",
                    noWrap: false,
                },
                {
                    label: "Примерное время выполнения задания",
                    text: timeForExecutionMinutes ? getTimeFromMins(timeForExecutionMinutes) : "-",
                },
                ...modalCardLabels,
                workingContractorId && {
                    label: "Исполнитель",
                    text: getLink(
                        workContractorLastName ? getFullName(workContractorLastName, workContractorFirstName, workContractorPatronymic) : workContractorFullName,
                        contractorLink,
                    ),
                },
            ]}
            cards={
                !isModalCard &&
                [
                    {
                        title: "Стоимость задания, ₽",
                        value: paymentAmount ? formatAmount(formatNumber(paymentAmount, 2)) : "-",
                        className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                    },
                    {
                        title: "Приглашенные / В очереди / В работе",
                        value: `${invitedContractorsCount || "-"} / ${inQueueContractorsCount || "-"} / ${workingContractorsCount || "-"}`,
                        className: "col-16 col-md-4 col-xxl-6 mt-md-4 mt-xxl-0",
                    },
                ]
            }
            cardsWithContainer
            cardsContainerClassName="col-16 col-xxl-8 align-items-end"
            mediaControls={
                !isModalCard && isShowInviteButton && {
                    renderCount: {
                        desktop: 1,
                        tablet: 0,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                onClick: openInviteModal,
                                children: "Пригласить исполнителей",
                                color: "green",
                            },
                        },
                    ],
                }
            }
            actionsClassName="col-1 col-xxl-3 justify-content-end"
            otherContent={
                isModalCard && isShowInviteButton &&
                <div className="col-16 mt-4">
                    <NmButton
                        className="col-16"
                        onClick={openInviteModal}
                    >
                        Пригласить исполнителей
                    </NmButton>
                </div>
            }
        />
    );
};

export default MapTaskListCard;