import React from "react";
import {useSelector} from "react-redux";

import NmReadonlyRating from "../../../../../../../components/NmReadonlyRating";

import {clientRatingSelector} from "../../../../../../../ducks/ratings";

const OrderEditFormPublishRating = (props) => {
    const {
        isReverse,
        classNameValue,
        className,
    } = props;
    const {rating = 0} = useSelector(clientRatingSelector);

    return (
        <NmReadonlyRating
            size="large"
            icon="star"
            isReverse={isReverse}
            rating={rating || 0}
            maxRating={5}
            classNameValue={classNameValue}
            className={className}
        />
    );
};

export default OrderEditFormPublishRating;