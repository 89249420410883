import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {CURRENT_CLIENT_USER_ID, ls} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {getSameBankTypeCard} from "../../../utils/paymentMethodHelper";
import {getFormAmount} from "../../../utils/stringFormat";
import validate from "../../../utils/validate";

import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";
import {patternNumber} from "../../../constants/order";
import {PAYMENT_TYPE_TRANSLATE} from "../../../constants/settings";
import {
    invoiceOfDepositReplenishmentRule,
    requiredMessage,
} from "../../../constants/validationRules";

import {
    getClientsSettingsPayments,
    updateStoreClientsSetting,
} from "../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsPaymentsProgressSelector,
    bffClientsSettingsPaymentsSelector,
} from "../../../ducks/bff/clients/settings/selectors";
import {getClientCardSelector} from "../../../ducks/client";
import {clientObjectOptionsSelector, getClientObjectList} from "../../../ducks/clientObject";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";

const PatentsAccountBasisReplenishment = (props) => {
    const {
        onClose,
        onSubmit,
        accountNumber,
        clientId,
        title,
    } = props;

    const {name} = useSelector(getClientCardSelector);
    const objectOptions = useSelector(clientObjectOptionsSelector);
    const {depositDistributedByObjects} = useSelector(getClientPropertiesCardSelector);
    const clientSettingsPayments = useSelector(bffClientsSettingsPaymentsSelector);
    const clientSettingsPaymentsProgress = useSelector(bffClientsSettingsPaymentsProgressSelector);
    const {t} = useTranslation();

    const [form, setForm] = useState({
        account: accountNumber,
        sum: "",
        isAutoDeposit: false,
    });
    const [error, setError] = useState({});
    const [progress, setProgress] = useState(false);
    const [valueObjectFilter, setValueObjectFilter] = useState("");
    const [sameBankTypeCard, setSameBankTypeCard] = useState(null);

    const clientUserId = ls(CURRENT_CLIENT_USER_ID);

    const dispatch = useDispatch();

    useEffect(() => {
        setForm((prevState) => {
            return {
                ...prevState,
                account: accountNumber,
            };
        });
    }, [accountNumber]);

    useEffect(() => {
        dispatch(getClientsSettingsPayments({clientId}));

        return () => {
            dispatch(updateStoreClientsSetting({payments: {}}));
        };
    }, []);

    useEffect(() => {
        fetchObjects();
    }, [valueObjectFilter]);

    useEffect(() => {
        setSameBankTypeCard(getSameBankTypeCard({
            paymentsSettings: clientSettingsPayments,
            contractType: ORDER_WORK_REPORT_TYPE.CIVIL,
        }));
    }, [clientSettingsPayments]);

    const fetchObjects = () => {
        dispatch(getClientObjectList({
            clientId,
            clientUserId,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            status: "IN_WORK",
            nameSubstringFilter: valueObjectFilter,
        }));
    };

    const validateForm = () => {
        const rule = !form.isAutoDeposit ? invoiceOfDepositReplenishmentRule : {
            ...invoiceOfDepositReplenishmentRule,
            objectId: {
                required: requiredMessage,
            },
        };

        const errorForm = validate(form, rule, "");

        setError(errorForm);

        return Object.values(errorForm).length === 0;
    };

    const onChange = (event, {name, value, checked}) => {
        const _state = {};

        if (name === "isAutoDeposit" && !checked) {
            _state["objectId"] = null;
        }

        if (name === "isAutoDeposit" && checked && objectOptions.length === 1) {
            _state["objectId"] = objectOptions[0]?.value;
        }

        setForm((prevState) => {
            return {
                ...prevState,
                ..._state,
                [name]: value || checked,
            };
        });
    };

    const _onSubmit = () => {
        const isValid = validateForm();

        if (!isValid) {
            return;
        }

        setProgress(true);

        const {
            account: depositAccountBank,
            sum,
            objectId,
            orderSource,
        } = form;

        onSubmit({
            clientId,
            sum: getFormAmount(sum),
            depositAccountBank,
            name,
            objectId: objectId ? objectId : undefined,
            orderSource,
            bankType: sameBankTypeCard ? sameBankTypeCard : undefined,
            onSuccess: () => {
                setProgress(false);
                onClose();
            },
            onError: () => {
                setProgress(false);
            },
        });
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progress}
                    submit={_onSubmit}
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent={t("button.to-form")}
                    onClose={onClose}
                    cancelBtnContent={t("button.cancel")}
                />
            }
            children={
                !clientSettingsPaymentsProgress &&
                !isEmpty(clientSettingsPayments) &&
                <NmForm>
                    {
                        !sameBankTypeCard &&
                        <NmDropdownV2
                            required
                            className="mb-3 mb-md-4"
                            label="Проведение оплаты"
                            placeholder="Выберите способ проведения оплаты"
                            name="orderSource"
                            options={dictionaryToOptions(PAYMENT_TYPE_TRANSLATE)}
                            value={form.orderSource}
                            onChange={onChange}
                            error={error.orderSource}
                        />
                    }
                    <NmInputV2
                        className="mb-3 mb-md-4"
                        size="xl"
                        label={t("patents.accountNumber")}
                        error={error.account}
                        name="account"
                        value={form.account}
                        mask="99999999999999999999"
                        maskChar={null}
                        onChange={onChange}
                        disabled={accountNumber}
                        required
                    />
                    <NmInputV2
                        size="xl"
                        label={t("patents.sum")}
                        error={error.sum}
                        name="sum"
                        value={form.sum}
                        pattern={patternNumber}
                        onChange={onChange}
                        required
                    />
                    {
                        depositDistributedByObjects &&
                        <>
                            <NmCheckboxV2
                                className="mt-2 mb-2"
                                label="Автоматически пополнять депозит объекта"
                                checked={form.isAutoDeposit}
                                name="isAutoDeposit"
                                onChange={onChange}
                                isVisibleTooltip
                                tooltip={
                                    <HelpTooltip
                                        info
                                        text="При поступлении указанной суммы средств на депозит компании в течение 72 часов с момента создания счет-основания произойдет автоматическое пополнение депозита выбранного объекта. Иначе денежные средства останутся в нераспределенном депозите компании."
                                        position="right"
                                    />
                                }
                            />
                            <NmDropdownV2
                                disabled={!form.isAutoDeposit}
                                search
                                placeholder="Выберите объект"
                                label="Объект"
                                name="objectId"
                                onChange={onChange}
                                onSearchChange={(value) => setValueObjectFilter(value)}
                                value={form.objectId}
                                options={objectOptions}
                                error={error.objectId}
                            />
                        </>
                    }
                </NmForm>
            }
            onClose={onClose}
            loading={clientSettingsPaymentsProgress}
        />
    );
};

export default PatentsAccountBasisReplenishment;