import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ObjectChangeDeposit from "../../../../components/ObjectChangeDeposit";

import {toastSuccess} from "../../../../utils/toastHelper";

import {OBJECT_CHANGE_DEPOSIT_RADIO_TYPE} from "../../../../components/ObjectChangeDeposit/constants";

import {
    addObjectDeposit,
    objectsProgressChangeDepositSelector,
} from "../../../../ducks/objects";

const ObjectsAddDeposit = (props) => {
    const {
        name,
        onClose,
        clientId,
        objectId,
        projectId,
        fetchData = () => {},
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(objectsProgressChangeDepositSelector);

    const onSubmit = (requestData) => {
        const {
            amount,
        } = requestData;
        dispatch(addObjectDeposit({
            amount,
            clientId,
            objectId,
            projectId,
            isCivil: requestData.type === OBJECT_CHANGE_DEPOSIT_RADIO_TYPE.NDFL,
            onSuccess: () => {
                toastSuccess("Депозит объекта успешно изменен");
                fetchData();
                onClose();
            },
        }));
    };

    return (
        <ObjectChangeDeposit
            onClose={onClose}
            header={`Пополнение депозита объекта ${name}`}
            text="Пополнить депозит объекта для выплат по счету"
            confirmContent={`Вы действительно хотите пополнить депозит объекта ${name}?`}
            onSubmit={onSubmit}
            progress={progress}
        />
    );
};

export default ObjectsAddDeposit;