import React from "react";

import Tabs from "../../../components/ActualComponents/Tabs";

import {
    LINK_CLIENT_BRIGADE_LIST,
    LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST,
    LINK_CLIENT_BRIGADE_PAYMENTS,
    LINK_CLIENT_BRIGADE_TIMESHEET} from "../../../constants/links";

import {
    BRIGADE_LIST,
    BRIGADE_ORDER_OBJECT_OBJECT,
    BRIGADE_PAYMENTS,
    BRIGADE_TIME_SHEET,
} from "../../../constants/link-params";

export const BrigadeTabs = (props) => {
    const {clientId, tab} = props;

    const getPanes = () => {
        const linkOrders = LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST.replace(":clientId", clientId);
        const linkTimesheet = LINK_CLIENT_BRIGADE_TIMESHEET.replace(":clientId", clientId);
        const linkPayments = LINK_CLIENT_BRIGADE_PAYMENTS.replace(":clientId", clientId);
        const linkList = LINK_CLIENT_BRIGADE_LIST.replace(":clientId", clientId);

        return [
            {
                text: "Заявки с объектов",
                link: linkOrders,
                active: tab === BRIGADE_ORDER_OBJECT_OBJECT,
            },
            {
                text: "Табели",
                link: linkTimesheet,
                active: tab === BRIGADE_TIME_SHEET,
            },
            {
                text: "Выплаты бригадирам",
                link: linkPayments,
                active: tab === BRIGADE_PAYMENTS,
            },
            {
                text: "Бригадиры",
                link: linkList,
                active: tab === BRIGADE_LIST,
            },
        ];
    };

    const getTabs = () => {
        return getPanes().map(({

            text,
            link,
            active,
        }) => ({
            name: (
                <div className="menu-item">
                    <div
                        className="menu-item-name"
                    >
                        {text}
                    </div>
                </div>
            ),
            link,
            active,
        }));
    };

    return (
        <Tabs
            panes={getTabs()}
        />
    );
};