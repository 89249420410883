import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListAccordionCard from "../../../../../components/ActualComponents/NmList/AccordionCard";
import Text from "../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import {
    getFeedListRecruitmentVacancyCandidates, updateRecruitmentVacancyCandidatesStore,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentCandidatesFeedListSelector,
    bffRecruitmentCandidatesFeedProgressSelector,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {RecruitmentCandidatesFeedOperationList} from "../components/operation-list";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL} from "../../../../../constants/links";

import {history} from "../../../../../store/configureStore";

export const RecruitmentCandidatesFeedList = (props) => {
    const {
        match: {
            params: {
                clientId,
                id: candidateId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentCandidatesFeedListSelector);
    const progress = useSelector(bffRecruitmentCandidatesFeedProgressSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFeedListRecruitmentVacancyCandidates({
            clientId,
            candidateId,
        }));

        return () => {
            dispatch(updateRecruitmentVacancyCandidatesStore({feedList: []}));
        };
    }, []);

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                vacancyId,
                vacancyNumber,
                title,
                candidateStatus,
                items,
            } = item;

            const vacancyLink = LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL
                .replace(":vacancyId", vacancyId)
                .replace(":clientId", clientId)
                .replace(":activeFunnelStatusFilter", "all");

            const hasVacancyAccess = Boolean(item.hasVacancyAccess);

            return {
                ...item,
                key: vacancyId,
                contentRow: <NmListAccordionCard
                    disabled={hasVacancyAccess}
                    headerText={`№${vacancyNumber} ${title}`}
                    labels={[{label: "Этап отбора", text: candidateStatus?.name || "-"}]}
                    isFixedActions={true}
                    mediaControls={{
                        renderCount: {
                            desktop: 1,
                            tablet: 1,
                            mobile: 0,
                        },
                        buttons: [
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    color: "grey",
                                    size: "lg",
                                    onClick: () => history.push(vacancyLink),
                                    children: "Перейти в вакансию",
                                },
                                visible: hasVacancyAccess,
                            },
                        ],
                    }}
                    children={
                        <RecruitmentCandidatesFeedOperationList
                            clientId={clientId}
                            candidateId={candidateId}
                            list={items}
                        />
                    }
                />,
            };
        });
    }, [list]);

    return (
        <NmPage
            header={
                <Text
                    type="title"
                    level="2"
                    children="Лента событий"
                />
            }
            isLoaded={progress}
        >
            {
                list.length ?
                    <CheckboxList
                        rows={rows}
                    /> :
                    <NmEmptyPageV2 />
            }
        </NmPage>
    );
};