import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/client-users-roles/settings";

//*  TYPES  *//

const SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST = "SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST";
const SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS = "SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS";
const SETTINGS_USERS_ROLES_RICH_PAGE_ERROR = "SETTINGS_USERS_ROLES_RICH_PAGE_ERROR";

const SETTINGS_USERS_ROLES_BY_ID_REQUEST = "SETTINGS_USERS_ROLES_BY_ID_REQUEST";
const SETTINGS_USERS_ROLES_BY_ID_SUCCESS = "SETTINGS_USERS_ROLES_BY_ID_SUCCESS";
const SETTINGS_USERS_ROLES_BY_ID_ERROR = "SETTINGS_USERS_ROLES_BY_ID_ERROR";

const SETTINGS_USERS_ROLES_ADD_REQUEST = "SETTINGS_USERS_ROLES_ADD_REQUEST";
const SETTINGS_USERS_ROLES_ADD_SUCCESS = "SETTINGS_USERS_ROLES_ADD_SUCCESS";
const SETTINGS_USERS_ROLES_ADD_ERROR = "SETTINGS_USERS_ROLES_ADD_ERROR";

const SETTINGS_USERS_ROLES_UPDATE_REQUEST = "SETTINGS_USERS_ROLES_UPDATE_REQUEST";
const SETTINGS_USERS_ROLES_UPDATE_SUCCESS = "SETTINGS_USERS_ROLES_UPDATE_SUCCESS";
const SETTINGS_USERS_ROLES_UPDATE_ERROR = "SETTINGS_USERS_ROLES_UPDATE_ERROR";

const SETTINGS_USERS_ROLES_DELETE_REQUEST = "SETTINGS_USERS_ROLES_DELETE_REQUEST";
const SETTINGS_USERS_ROLES_DELETE_SUCCESS = "SETTINGS_USERS_ROLES_DELETE_SUCCESS";
const SETTINGS_USERS_ROLES_DELETE_ERROR = "SETTINGS_USERS_ROLES_DELETE_ERROR";

const UPDATE_SETTINGS_USERS_ROLES_FIELD = "UPDATE_SETTINGS_USERS_ROLES_FIELD";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    restrictions: [],
    progress: false,
    progressAction: false,
    counts: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case SETTINGS_USERS_ROLES_ADD_REQUEST:
    case SETTINGS_USERS_ROLES_UPDATE_REQUEST:
    case SETTINGS_USERS_ROLES_DELETE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS:
        const {
            results,
            totalCount,
            counts,
        } = payload;

        return {
            ...state,
            totalCount,
            list: results,
            progress: false,
            counts,
        };
    case SETTINGS_USERS_ROLES_ADD_SUCCESS:
    case SETTINGS_USERS_ROLES_UPDATE_SUCCESS:
    case SETTINGS_USERS_ROLES_DELETE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case SETTINGS_USERS_ROLES_RICH_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case SETTINGS_USERS_ROLES_ADD_ERROR:
    case SETTINGS_USERS_ROLES_UPDATE_ERROR:
    case SETTINGS_USERS_ROLES_DELETE_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case UPDATE_SETTINGS_USERS_ROLES_FIELD:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getSettingsUsersRolesRichPage(payload) {
    return {
        type: SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsUsersRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_ADD_REQUEST,
        payload,
    };
}

export function updateSettingsUsersRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_UPDATE_REQUEST,
        payload,
    };
}

export function deleteSettingsUsersRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_DELETE_REQUEST,
        payload,
    };
}

export function updateFieldSettingsUsersRoles(payload) {
    return {
        type: UPDATE_SETTINGS_USERS_ROLES_FIELD,
    };
}

const settingsUsersRolesSelector = state => state.settingsUsersRoles;
export const settingsUsersRolesTotalPagesSelector = createSelector(settingsUsersRolesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const settingsUsersRolesListSelector = createSelector(settingsUsersRolesSelector, ({list}) => list);
export const settingsUsersRolesOptionsSelector = createSelector(settingsUsersRolesSelector, ({list}) => {
    return list.map(({id, name}) => {
        return {
            key: id,
            text: name,
            value: id,
        };
    },
    );
},
);
export const settingsUsersRolesTotalCountSelector = createSelector(settingsUsersRolesSelector, ({totalCount}) => totalCount);
export const settingsUsersRolesProgressSelector = createSelector(settingsUsersRolesSelector, ({progress}) => progress);
export const settingsUsersRolesProgressActionSelector = createSelector(settingsUsersRolesSelector, ({progressAction}) => progressAction);
export const settingsUsersRolesCountsSelector = createSelector(settingsUsersRolesSelector, ({counts}) => counts);

//*  SAGA  *//

//POST /api/orders/contractor-event/page
const getSettingsUsersRolesRichPageSaga = function* ({payload}) {
    try {
        const {errorMessage, ...result} = yield request.bff.post(`${controller}/richPage`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_RICH_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_RICH_PAGE_ERROR, payload: error});
    }
};

//POST /api/orders/contractor-event/add
const addSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const {errorMessage, ...result} = yield request.bff.post(`${controller}/add`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SETTINGS_USERS_ROLES_ADD_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_ADD_ERROR, payload: error});
    }
};

//POST /api/orders/contractor-event/update
const updateSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const {errorMessage, ...result} = yield request.bff.post(`${controller}/update`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SETTINGS_USERS_ROLES_UPDATE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_UPDATE_ERROR, payload: error});
    }
};

//POST /bff/adm/client-users-roles/settings/delete/{id}
// Удаление роли
const deleteSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            id,
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.bff.post(`${controller}/delete/${id}`);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SETTINGS_USERS_ROLES_DELETE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_DELETE_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST, getSettingsUsersRolesRichPageSaga),
        takeEvery(SETTINGS_USERS_ROLES_ADD_REQUEST, addSettingsUsersRoleSaga),
        takeEvery(SETTINGS_USERS_ROLES_UPDATE_REQUEST, updateSettingsUsersRoleSaga),
        takeEvery(SETTINGS_USERS_ROLES_DELETE_REQUEST, deleteSettingsUsersRoleSaga),
    ]);
}