import * as yup from "yup";

import {transformYupFormattedAmountToNumber} from "../../../../../../../utils/yupTransforms";

import {requiredMessage} from "../../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        bankName: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        balance: yup.number()
            .nullable()
            .required(requiredMessage)
            .transform(transformYupFormattedAmountToNumber)
            .min(0, "Минимально допустимое значение равно 0")
            .max(99999999999.99, "Максимальное допустимое значение равно 99 999 999 999.99"),
        lastUpdateDatetime: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};

export default validationSchema;