import React from "react";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import FileViewerPdf from "../../../../../components/FileVIewerPdf";
import NmButton from "../../../../../components/NmButton";
import NmTitle from "../../../../../components/NmTitle";

const KedoTemplatePreview = (props) => {
    const {
        onClose,
        file,
    } = props;

    return (
        <NmModal
            onClose={onClose}
            size="md"
            header={
                <NmTitle size="lg">
                    Предпросмотр шаблона
                </NmTitle>
            }
            footer={
                <div className="d-flex justify-content-end">
                    <NmButton
                        size="xl"
                        color="green"
                        onClick={onClose}
                        children="Закрыть"
                    />
                </div>
            }
        >
            <FileViewerPdf
                file={file}
            />
        </NmModal>
    );
};

export default KedoTemplatePreview;