import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router";

import Tabs from "../../../../../components/ActualComponents/Tabs";
import NmPage from "../../../../../components/NmPage";
import NmPageCard from "../../../../../components/NmPageCard";
import {KedoStaffCommonCard} from "../common-card";
import {KedoStaffProfile} from "../profile";
import {KedoStaffSignatureTransactions} from "../signature-transactions";

import {
    LINK_CLIENT_KEDO_PROFILE,
    LINK_CLIENT_KEDO_SIGNATURE_TRANSACTIONS,
} from "../../../../../constants/links";

import {getKedoStaffById, updateKedoStaffStore} from "../../../../../ducks/kedo/staff/actionCreators";
import {
    bffKedoStaffProgressCardSelector,
    bffKedoStaffProgressCommonCardSelector,
} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffRegistryCard = (props) => {
    const {
        match,
    } = props;

    const dispatch = useDispatch();

    const progressCard = useSelector(bffKedoStaffProgressCardSelector);
    const progressCommonCard = useSelector(bffKedoStaffProgressCommonCardSelector);

    const {
        clientId,
        kedoStaffId,
    } = match.params;

    const pathname = useSelector(state => state.router.location.pathname);

    const fetchCard = useCallback(() => {
        dispatch(getKedoStaffById({
            clientId,
            kedoStaffId,
            pathname,
        }));
    }, []);

    useEffect(() => {
        fetchCard();

        return () => {
            dispatch(updateKedoStaffStore({
                card: {},
            }));
        };
    }, []);

    return (
        <NmPageCard
            header={
                <KedoStaffCommonCard
                    clientId={clientId}
                    kedoStaffId={kedoStaffId}
                    fetchCard={fetchCard}
                />
            }
            description={
                <NmPage
                    isLoaded={progressCard || progressCommonCard}
                    header={
                        <Tabs
                            secondary
                            className="flex-grow-1"
                            panes={[
                                {
                                    name: "Профиль",
                                    link: LINK_CLIENT_KEDO_PROFILE
                                        .replace(":clientId", clientId)
                                        .replace(":kedoStaffId", kedoStaffId),
                                    active: pathname === LINK_CLIENT_KEDO_PROFILE
                                        .replace(":clientId", clientId)
                                        .replace(":kedoStaffId", kedoStaffId),
                                },
                                {
                                    name: "Журнал подписания документов",
                                    link: LINK_CLIENT_KEDO_SIGNATURE_TRANSACTIONS
                                        .replace(":clientId", clientId)
                                        .replace(":kedoStaffId", kedoStaffId),
                                    active: pathname === LINK_CLIENT_KEDO_SIGNATURE_TRANSACTIONS
                                        .replace(":clientId", clientId)
                                        .replace(":kedoStaffId", kedoStaffId),
                                },
                            ]}
                        />
                    }
                    showHeaderBlock={true}
                >
                    <Switch>
                        <Route
                            exact
                            path={LINK_CLIENT_KEDO_PROFILE}
                            component={KedoStaffProfile}
                        />
                        <Route
                            exact
                            path={LINK_CLIENT_KEDO_SIGNATURE_TRANSACTIONS}
                            component={KedoStaffSignatureTransactions}
                        />
                    </Switch>
                </NmPage>
            }
        />
    );
};