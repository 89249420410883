import React from "react";
import {useSelector} from "react-redux";

import LoginCardContent from "../card-content";

import bem from "../../../utils/bem";
import {isPromoPoiskPage} from "../../../utils/url";

import {LINK_LOGIN, LINK_REQUEST} from "../../../constants/links";

import "./style.sass";

const LoginCard = ({children, className = ""}) => {
    const [block] = bem("login-card", className);
    const pathname = useSelector(state => state.router.location.pathname);

    const isPromoPoisk = isPromoPoiskPage();

    if (pathname === LINK_LOGIN) {
        return (
            <div
                className={block({
                    login: true,
                    loginPromoPoisk: isPromoPoisk,
                })}
            >
                <LoginCardContent>
                    {children}
                </LoginCardContent>
            </div>
        );
    }

    return (
        <div
            className={block({
                join: true,
                joinPromoPoisk: isPromoPoisk,
            })}
        >
            <LoginCardContent>
                {children}
            </LoginCardContent>
        </div>
    );
};

export default LoginCard;