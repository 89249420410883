import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

/**
 * nextAllObjectsParams - обновление списка объектов ВНЕ текущей страницы внутри проекта.
 * Список, где id - идентификатор проекта. Автоматически исключаются обновляемые в
 * objectsParams объекты (она же текущая страница), но можно также использовать excludeObjectsIds
 * @param params
 */
export const getNextAllObjectsParams = (params) => {
    const {
        nextAllProjectsParams,
        projects,
        isVisibleClient,
        clientPropertiesParamSelectorType,
    } = params;

    if (
        isVisibleClient
        && clientPropertiesParamSelectorType !== CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY
    ) {
        return null;
    }

    const changedProjects = projects.filter(item => {
        return item.changed;
    }).map(item => {
        return {
            id: item.value,
            boolParam: item.changedCheckedValue,
        };
    });

    // Если исключаем проекты, значит их значение изменено и объекты внутри имеют такой же селектор и
    // нет смысла отправлять их в метод
    if (nextAllProjectsParams) {
        return changedProjects.filter(item => {
            return !nextAllProjectsParams.excludeProjectIds.includes(item.id);
        });
    }

    return changedProjects;
};