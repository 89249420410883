import {useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {convertStringToDate} from "../../../../../utils/stringHelper";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

import {updateContractor} from "../../../../../ducks/contractor";


const useDataForm = (card) => {
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);

    const initialValues = {
        [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: card[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER] || "",
        [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]) || null,
        [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]) || null,
    };

    const toggleCard = () => {
        validateForm().then(() => {
            if (!isValid && isEdit) {
                return;
            }

            if (isEdit) {
                handleSubmit();
            }

            setIsEdit(prevState => !prevState);
        });
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setValues(initialValues);
    };

    const {
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        setValues,
        errors,
        touched,
        isValid,
        validateForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values,{setSubmitting}) => {
            const requestData = {
                ...card,
                ...values,
            };

            dispatch(updateContractor(requestData));

            setSubmitting(false);
        },
        validationSchema: validationSchema(),
        validateOnBlur: false,
        initialTouched: {
            [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: true,
            [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: true,
            [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: true,
        },
    });


    return {
        values,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        isEdit,
        cancelEdit,
        toggleCard,
        errors,
        touched,
    };

};

export default useDataForm;