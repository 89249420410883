import React, {useContext} from "react";

import OrderTemplatesEditActTemplate from "../../../order-templates/components/edit-act-template";
import {OrderEditContext} from "../../context-provider";

const OrderEditActTemplate = (props) => {
    const {
        formError,
        formData,
        clientId,
    } = props;
    const {
        onChangeActCreation,
        actCreationForm,
    } = useContext(OrderEditContext);

    return (
        <OrderTemplatesEditActTemplate
            clientId={clientId}
            onChange={onChangeActCreation}
            editActTemplateData={formData}
            form={actCreationForm}
            formError={formError}
        />
    );
};

export default OrderEditActTemplate;