import React from "react";
import {useSelector} from "react-redux";

import NmLoader from "../../../../NmLoader";
import NmCheckboxV2 from "../../../NmCheckboxV2";
import NmConfirmV2 from "../../../NmConfirmV2";
import NmDropdownV2 from "../../../NmDropdownV2";
import {ClientSettingsAccessToCompany} from "../AccessToCompany";
import {ClientSettingsChangedDataModal} from "../ChangedDataModal";
import {ClientSettingsSubmitButtons} from "../SubmitButtons";

import {useClientSettingsOtherForm} from "./hooks/useForm";

import {ADMIN, NM_MANAGER} from "../../../../../constants/roles";

import {bffCommonDictsMenuDesignDictOptionsSelector} from "../../../../../ducks/bff/common/dicts/selectors";

export const ClientSettingsOther = (props) => {
    const {
        role,
        isAccess,
        clientId,
        refFooter,
        onClose,
    } = props;

    const {
        isLoading,
        onChange,
        values,
        onSubmit,
        confirmData,
        isOpenConfirm,
        onCloseConfirm,
    } = useClientSettingsOtherForm({
        clientId,
        onClose,
    });

    const menuDesignOptions = useSelector(bffCommonDictsMenuDesignDictOptionsSelector);

    const getConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile
                content={confirmData.confirmText}
                onCancel={onCloseConfirm}
                onConfirm={confirmData.onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    return (
        <>
            <ClientSettingsChangedDataModal onSubmit={onSubmit} />
            {
                isLoading &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            {getConfirm()}
            {
                role === ADMIN &&
                <ClientSettingsAccessToCompany
                    className="mb-3 mb-md-4"
                    label="Доступ к компании"
                    onChange={onChange}
                    name="availableClientManagersIds"
                    value={values.availableClientManagersIds}
                />
            }
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="innRequired"
                onChange={onChange}
                checked={values.innRequired}
                label="Обязательность ввода ИНН для сотрудников"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="foremanEnabled"
                onChange={onChange}
                checked={values.foremanEnabled}
                label="Бригадир"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess || values.foremanEnabled}
                name="ordersVolumeOfWorkRequired"
                onChange={onChange}
                checked={values.ordersVolumeOfWorkRequired}
                label="Создание заказов только с заполнением ТС и объема работ"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="objectManagerHasAccessToAllPaymentRegistries"
                onChange={onChange}
                checked={values.objectManagerHasAccessToAllPaymentRegistries}
                label="Просмотр всех реестров оплат для менеджера объекта"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="foremanHasUnrestrictedReadAccessToDocumentsRegistry"
                onChange={onChange}
                checked={values.foremanHasUnrestrictedReadAccessToDocumentsRegistry}
                label="Просмотр всего реестра документов для координатора компании"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN, NM_MANAGER].includes(role)}
                name="addBrandNameToObject"
                onChange={onChange}
                checked={values.addBrandNameToObject}
                label="Добавлять в объект название бренда"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="amountForReport"
                onChange={onChange}
                checked={values.amountForReport}
                label="Сумма для отчёта"
                isVisibleTooltip={true}
                tooltipOutline={false}
                tooltipText='При активации настройки при формировании оплаты по заказу или строки реестра актов дополнительно отобразится новое поле "Сумма для отчёта"'
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={![ADMIN].includes(role)}
                name="orderPublishingProhibitedToObjectManager"
                onChange={onChange}
                checked={values.orderPublishingProhibitedToObjectManager}
                label="Менеджер объекта не может завершать подбор"
            />
            <NmDropdownV2
                label="Дизайн меню"
                options={menuDesignOptions}
                value={values.menuDesign}
                name="menuDesign"
                onChange={onChange}
            />
            <ClientSettingsSubmitButtons
                refFooter={refFooter}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
