import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/scores";
//*  TYPES  *//

const GET_SCORE_ACTUAL_CONTRACTOR_REQUEST = "GET_SCORE_ACTUAL_CONTRACTOR_REQUEST";
const GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS = "GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS";
const GET_SCORE_ACTUAL_CONTRACTOR_ERROR = "GET_SCORE_ACTUAL_CONTRACTOR_ERROR";

const GET_SCORES_PAGE_REQUEST = "GET_SCORES_PAGE_REQUEST";
const GET_SCORES_PAGE_SUCCESS = "GET_SCORES_PAGE_SUCCESS";
const GET_SCORES_PAGE_ERROR = "GET_SCORES_PAGE_ERROR";

const GET_CLIENT_FEEDBACK_PAGE_REQUEST = "GET_CLIENT_FEEDBACK_PAGE_REQUEST";
const GET_CLIENT_FEEDBACK_PAGE_SUCCESS = "GET_CLIENT_FEEDBACK_PAGE_SUCCESS";
const GET_CLIENT_FEEDBACK_PAGE_ERROR = "GET_CLIENT_FEEDBACK_PAGE_ERROR";

const CONFIRM_CLIENT_FEEDBACK_REQUEST = "CONFIRM_CLIENT_FEEDBACK_REQUEST";
const CONFIRM_CLIENT_FEEDBACK_SUCCESS = "CONFIRM_CLIENT_FEEDBACK_SUCCESS";
const CONFIRM_CLIENT_FEEDBACK_ERROR = "CONFIRM_CLIENT_FEEDBACK_ERROR";

const DELETE_CLIENT_FEEDBACK_REQUEST = "DELETE_CLIENT_FEEDBACK_REQUEST";
const DELETE_CLIENT_FEEDBACK_SUCCESS = "DELETE_CLIENT_FEEDBACK_SUCCESS";
const DELETE_CLIENT_FEEDBACK_ERROR = "DELETE_CLIENT_FEEDBACK_ERROR";

const DECLINE_CLIENT_FEEDBACK_REQUEST = "DECLINE_CLIENT_FEEDBACK_REQUEST";
const DECLINE_CLIENT_FEEDBACK_SUCCESS = "DECLINE_CLIENT_FEEDBACK_SUCCESS";
const DECLINE_CLIENT_FEEDBACK_ERROR = "DECLINE_CLIENT_FEEDBACK_ERROR";

const UPDATE_CLIENT_FEEDBACK_REQUEST = "UPDATE_CLIENT_FEEDBACK_REQUEST";
const UPDATE_CLIENT_FEEDBACK_SUCCESS = "UPDATE_CLIENT_FEEDBACK_SUCCESS";
const UPDATE_CLIENT_FEEDBACK_ERROR = "UPDATE_CLIENT_FEEDBACK_ERROR";

const SEND_FEEDBACK_COMPLAINT_REQUEST = "SEND_FEEDBACK_COMPLAINT_REQUEST";
const SEND_FEEDBACK_COMPLAINT_SUCCESS = "SEND_FEEDBACK_COMPLAINT_SUCCESS";
const SEND_FEEDBACK_COMPLAINT_ERROR = "SEND_FEEDBACK_COMPLAINT_ERROR";

const GET_CLIENT_FEEDBACK_COUNT_REQUEST = "GET_CLIENT_FEEDBACK_COUNT_REQUEST";
const GET_CLIENT_FEEDBACK_COUNT_SUCCESS = "GET_CLIENT_FEEDBACK_COUNT_SUCCESS";
const GET_CLIENT_FEEDBACK_COUNT_ERROR = "GET_CLIENT_FEEDBACK_COUNT_ERROR";

const ADD_CLIENT_FEEDBACK_REPLY_REQUEST = "ADD_CLIENT_FEEDBACK_REPLY_REQUEST";
const ADD_CLIENT_FEEDBACK_REPLY_SUCCESS = "ADD_CLIENT_FEEDBACK_REPLY_SUCCESS";
const ADD_CLIENT_FEEDBACK_REPLY_ERROR = "ADD_CLIENT_FEEDBACK_REPLY_ERROR";

const EDIT_CLIENT_FEEDBACK_REPLY_REQUEST = "EDIT_CLIENT_FEEDBACK_REPLY_REQUEST";
const EDIT_CLIENT_FEEDBACK_REPLY_SUCCESS = "EDIT_CLIENT_FEEDBACK_REPLY_SUCCESS";
const EDIT_CLIENT_FEEDBACK_REPLY_ERROR = "EDIT_CLIENT_FEEDBACK_REPLY_ERROR";

const DELETE_CLIENT_FEEDBACK_REPLY_REQUEST = "DELETE_CLIENT_FEEDBACK_REPLY_REQUEST";
const DELETE_CLIENT_FEEDBACK_REPLY_SUCCESS = "DELETE_CLIENT_FEEDBACK_REPLY_SUCCESS";
const DELETE_CLIENT_FEEDBACK_REPLY_ERROR = "DELETE_STATUS_CLIENT_FEEDBACK_REPLY_ERROR";

const CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_REQUEST = "CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_REQUEST";
const CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_SUCCESS = "CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_SUCCESS";
const CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_ERROR = "CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_ERROR";

//*  INITIAL STATE  *//

const initial = {
    contractorScore: {},
    pageData: {},
    list: [],
    totalCount: 0,
    progressPage: false,
    progress: false,
    clientFeedbackListProgress: false,
    clientFeedbackListTotalCount: 0,
    clientFeedbackList: [],
    clientFeedbackListPageData: {},
    actionProgress: false,
};

//*  REDUCER  *//


export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CLIENT_FEEDBACK_PAGE_REQUEST:
        return {
            ...state,
            clientFeedbackListProgress: true,
            clientFeedbackListPageData: payload,
        };
    case GET_SCORE_ACTUAL_CONTRACTOR_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_SCORES_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case SEND_FEEDBACK_COMPLAINT_REQUEST:
    case CONFIRM_CLIENT_FEEDBACK_REQUEST:
    case DELETE_CLIENT_FEEDBACK_REQUEST:
    case DECLINE_CLIENT_FEEDBACK_REQUEST:
    case UPDATE_CLIENT_FEEDBACK_REQUEST:
    case ADD_CLIENT_FEEDBACK_REPLY_REQUEST:
    case EDIT_CLIENT_FEEDBACK_REPLY_REQUEST:
    case DELETE_CLIENT_FEEDBACK_REPLY_REQUEST:
    case CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case GET_CLIENT_FEEDBACK_PAGE_SUCCESS:
        return {
            ...state,
            clientFeedbackList: payload.results,
            clientFeedbackListProgress: false,
            clientFeedbackListTotalCount: payload.totalCount,
        };
    case GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS:
        return {
            ...state,
            contractorScore: payload,
            progress: false,
        };
    case GET_SCORES_PAGE_SUCCESS:
        const {
            results: list,
            totalCount,
        } = payload;

        return {
            ...state,
            progressPage: false,
            totalCount,
            list,
        };
    case SEND_FEEDBACK_COMPLAINT_SUCCESS:
    case CONFIRM_CLIENT_FEEDBACK_SUCCESS:
    case DELETE_CLIENT_FEEDBACK_SUCCESS:
    case DECLINE_CLIENT_FEEDBACK_SUCCESS:
    case UPDATE_CLIENT_FEEDBACK_SUCCESS:
    case ADD_CLIENT_FEEDBACK_REPLY_SUCCESS:
    case EDIT_CLIENT_FEEDBACK_REPLY_SUCCESS:
    case DELETE_CLIENT_FEEDBACK_REPLY_SUCCESS:
    case CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_SUCCESS:
    case SEND_FEEDBACK_COMPLAINT_ERROR:
    case CONFIRM_CLIENT_FEEDBACK_ERROR:
    case DELETE_CLIENT_FEEDBACK_ERROR:
    case DECLINE_CLIENT_FEEDBACK_ERROR:
    case UPDATE_CLIENT_FEEDBACK_ERROR:
    case ADD_CLIENT_FEEDBACK_REPLY_ERROR:
    case EDIT_CLIENT_FEEDBACK_REPLY_ERROR:
    case DELETE_CLIENT_FEEDBACK_REPLY_ERROR:
    case CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_ERROR:
        return {
            ...state,
            actionProgress: false,
        };
    case GET_CLIENT_FEEDBACK_COUNT_SUCCESS:
        return {
            ...state,
            clientFeedbackListTotalCount: payload,
        };
    case GET_SCORE_ACTUAL_CONTRACTOR_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    case GET_CLIENT_FEEDBACK_PAGE_ERROR:
        return {
            ...state,
            clientFeedbackListProgress: false,
            error: payload,
        };
    case GET_SCORES_PAGE_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function sendFeedbackComplaint(payload) {
    return {
        type: SEND_FEEDBACK_COMPLAINT_REQUEST,
        payload,
    };
}

export function getActualScoreContractor(payload) {
    return {
        type: GET_SCORE_ACTUAL_CONTRACTOR_REQUEST,
        payload,
    };
}

export function getScoresPage(payload) {
    return {
        type: GET_SCORES_PAGE_REQUEST,
        payload,
    };
}

export function getClientFeedbackPage(payload) {
    return {
        type: GET_CLIENT_FEEDBACK_PAGE_REQUEST,
        payload,
    };
}

export function confirmClientFeedback(payload) {
    return {
        type: CONFIRM_CLIENT_FEEDBACK_REQUEST,
        payload,
    };
}

export function deleteClientFeedback(payload) {
    return {
        type: DELETE_CLIENT_FEEDBACK_REQUEST,
        payload,
    };
}

export function declineClientFeedback(payload) {
    return {
        type: DECLINE_CLIENT_FEEDBACK_REQUEST,
        payload,
    };
}

export function updateClientFeedback(payload) {
    return {
        type: UPDATE_CLIENT_FEEDBACK_REQUEST,
        payload,
    };
}

export function getClientFeedbackCount(payload) {
    return {
        type: GET_CLIENT_FEEDBACK_COUNT_REQUEST,
        payload,
    };
}

export function addClientFeedbackReply(payload) {
    return {
        type: ADD_CLIENT_FEEDBACK_REPLY_REQUEST,
        payload,
    };
}

export function editClientFeedbackReply(payload) {
    return {
        type: EDIT_CLIENT_FEEDBACK_REPLY_REQUEST,
        payload,
    };
}

export function deleteClientFeedbackReply(payload) {
    return {
        type: DELETE_CLIENT_FEEDBACK_REPLY_REQUEST,
        payload,
    };
}

export function changeStatusClientFeedbackReply(payload) {
    return {
        type: CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const scoresSelector = state => state.scores;
export const getScoresTotalPagesSelector = createSelector(scoresSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getScoresTotalCountSelector = createSelector(scoresSelector, ({totalCount}) => totalCount);
export const getScoresListSelector = createSelector(scoresSelector, ({list}) => list);
export const getScoresProgressPageSelector = createSelector(scoresSelector, ({progressPage}) => progressPage);
export const getProgressClientFeedbackActionSelector = createSelector(scoresSelector, ({actionProgress}) => actionProgress);

export const clientFeedbackListTotalCountSelector = createSelector(scoresSelector, ({clientFeedbackListTotalCount}) => clientFeedbackListTotalCount);
export const clientFeedbackListTotalPagesSelector = createSelector(scoresSelector, ({clientFeedbackListTotalCount, clientFeedbackListPageData: {pageSize = 0}}) => getTotalPages(clientFeedbackListTotalCount, pageSize));
export const clientFeedbackListSelector = createSelector(scoresSelector, ({clientFeedbackList}) => clientFeedbackList);
export const clientFeedbackListProgressSelector = createSelector(scoresSelector, ({clientFeedbackListProgress}) => clientFeedbackListProgress);
//*  SAGA  *//

//api/scores/contractor/actual
export const getActualScoreContractorSaga = function* (action) {
    try {
        const {payload} = action;
        const {data: params, handleResponse} = payload;

        const result = yield request.get(`${controller}/contractor/actual`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SCORE_ACTUAL_CONTRACTOR_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({type: GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SCORE_ACTUAL_CONTRACTOR_ERROR, payload: error});
    }
};

//POST /api/scores/page/rich
export const getScoresPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/page/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SCORES_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_SCORES_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SCORES_PAGE_ERROR, payload: error});
    }
};

// /api/scores/client/page/rich
export const getClientFeedbackPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/client/page/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_FEEDBACK_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({contractor: {contractorId}}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: GET_CLIENT_FEEDBACK_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CLIENT_FEEDBACK_PAGE_ERROR, payload: error});
    }
};

export const sendFeedbackComplaintSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/contractor/sendComplaintOnContractorReview/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SEND_FEEDBACK_COMPLAINT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SEND_FEEDBACK_COMPLAINT_SUCCESS, payload: result});
        toastSuccess("Жалоба успешно подана и будет рассмотрена в ближайшее время.");
    } catch (error) {
        yield put({type: SEND_FEEDBACK_COMPLAINT_ERROR, payload: error});
    }
};
//POST /api/scores/contractor/confirmReviewOnContractor/{id}
export const confirmClientFeedbackSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/client/confirmReviewOnClient/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONFIRM_CLIENT_FEEDBACK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: CONFIRM_CLIENT_FEEDBACK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONFIRM_CLIENT_FEEDBACK_ERROR, payload: error});
    }
};

//DELETE /api/scores/contractor/deleteReviewOnContractor/{id}
export const deleteClientFeedbackSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.delete(`${controller}/client/deleteReviewOnClient/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CLIENT_FEEDBACK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DELETE_CLIENT_FEEDBACK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CLIENT_FEEDBACK_ERROR, payload: error});
    }
};

//POST /api/scores/client/declineReviewOnClient/{id}
export const declineClientFeedbackSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/client/declineReviewOnClient/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DECLINE_CLIENT_FEEDBACK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DECLINE_CLIENT_FEEDBACK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DECLINE_CLIENT_FEEDBACK_ERROR, payload: error});
    }
};

//POST /api/scores/client/updateReviewOnClient
export const updateClientFeedbackSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/client/updateReviewOnClient`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_FEEDBACK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: UPDATE_CLIENT_FEEDBACK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CLIENT_FEEDBACK_ERROR, payload: error});
    }
};


//POST /api/scores/client/count
export const getClientFeedbackCountSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/client/count`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_FEEDBACK_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_FEEDBACK_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CLIENT_FEEDBACK_COUNT_ERROR, payload: error});
    }
};

//POST /api/scores/client/{reviewId}/reply/add
// Добавить ответ на отзыв о компании
export const addClientFeedbackReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/client/${reviewId}/reply/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CLIENT_FEEDBACK_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: ADD_CLIENT_FEEDBACK_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CLIENT_FEEDBACK_REPLY_ERROR, payload: error});
    }
};

//POST //api/scores/client/{reviewId}/reply/update
export const editClientFeedbackReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/client/${reviewId}/reply/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDIT_CLIENT_FEEDBACK_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: EDIT_CLIENT_FEEDBACK_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: EDIT_CLIENT_FEEDBACK_REPLY_ERROR, payload: error});
    }
};

//DELETE /api/scores/client/{id}/reply/delete
// Удалить ответ на отзыв о компании
export const deleteClientFeedbackReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
        } = payload;

        const result = yield request.delete(`${controller}/client/${reviewId}/reply/delete`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CLIENT_FEEDBACK_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: DELETE_CLIENT_FEEDBACK_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CLIENT_FEEDBACK_REPLY_ERROR, payload: error});
    }
};

//POST /api/scores/client/{id}/reply/change-status
// Подтвердить отзыв компании о исполнители
export const changeStatusClientFeedbackReplySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            reviewId,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/client/${reviewId}/reply/change-status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_SCORE_ACTUAL_CONTRACTOR_REQUEST, getActualScoreContractorSaga),
        takeEvery(GET_SCORES_PAGE_REQUEST, getScoresPageSaga),
        takeEvery(GET_CLIENT_FEEDBACK_PAGE_REQUEST, getClientFeedbackPageSaga),
        takeEvery(CONFIRM_CLIENT_FEEDBACK_REQUEST, confirmClientFeedbackSaga),
        takeEvery(SEND_FEEDBACK_COMPLAINT_REQUEST, sendFeedbackComplaintSaga),
        takeEvery(DELETE_CLIENT_FEEDBACK_REQUEST, deleteClientFeedbackSaga),
        takeEvery(UPDATE_CLIENT_FEEDBACK_REQUEST, updateClientFeedbackSaga),
        takeEvery(DECLINE_CLIENT_FEEDBACK_REQUEST, declineClientFeedbackSaga),
        takeEvery(GET_CLIENT_FEEDBACK_COUNT_REQUEST, getClientFeedbackCountSaga),
        takeEvery(ADD_CLIENT_FEEDBACK_REPLY_REQUEST, addClientFeedbackReplySaga),
        takeEvery(EDIT_CLIENT_FEEDBACK_REPLY_REQUEST, editClientFeedbackReplySaga),
        takeEvery(DELETE_CLIENT_FEEDBACK_REPLY_REQUEST, deleteClientFeedbackReplySaga),
        takeEvery(CHANGE_STATUS_CLIENT_FEEDBACK_REPLY_REQUEST, changeStatusClientFeedbackReplySaga),
    ]);
}
