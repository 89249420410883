import React, {useContext} from "react";
import {useFormik} from "formik";

import NmModal from "../../../../ActualComponents/NmModal";
import NmTextareaV2 from "../../../../ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

export const RecruitmentCandidateAboutYourselfEdit = (props) => {
    const {
        onClose,
        aboutYourself,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        values,
        setFieldValue,
        handleSubmit,
        setFieldTouched,
        touched,
        errors,
    } = useFormik({
        onSubmit,
        initialValues: {
            aboutYourself: aboutYourself || "",
        },
    });

    function onSubmit(values) {
        updateInformation({
            aboutYourself: values.aboutYourself ? values.aboutYourself : null,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    }

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    О себе
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            classNameContent="flex-column"
            onClose={onClose}
        >
            <NmTextareaV2
                size="xl"
                required
                value={values.aboutYourself}
                minRows={4}
                label="Описание"
                placeholder="Введите описание"
                maxLength={500}
                name="aboutYourself"
                onBlur={() => setFieldTouched("aboutYourself", true)}
                error={touched?.aboutYourself && errors?.aboutYourself}
                onChange={onChange}
            />
        </NmModal>
    );
};
