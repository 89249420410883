import React from "react";

import Tabs from "../../../components/ActualComponents/Tabs";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {TemplatesKedoRoute} from "./route";

import {LINK_TEMPLATES_KEDO_LIST, LINK_TEMPLATES_KEDO_LOG} from "../../../constants/links";

const KedoTemplates = (props) => {
    const getTabs = () => {
        return [
            {
                name: "Шаблоны",
                link: LINK_TEMPLATES_KEDO_LIST,
            },
            {
                name: "Журнал событий",
                link: LINK_TEMPLATES_KEDO_LOG,
            },
        ];
    };

    return (
        <NmPage
            header={
                <NmTitle size="xl">
                    Шаблоны документов КЭДО
                </NmTitle>
            }
            subHeader={
                <Tabs panes={getTabs()} />
            }
        >
            <TemplatesKedoRoute
                {...props}
            />
        </NmPage>
    );
};

export default KedoTemplates;