import React from "react";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import AmountInput from "../../../../../../components/AmountInput";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../../../../images/add.svg";
import {Divider} from "semantic-ui-react";

import useDataForm from "./hooks/useDataForm";

import {getDateObject} from "../../../../../../utils/dateFormat";
import {getFullName, phoneFormat} from "../../../../../../utils/stringFormat";

import {
    EDO_DOCUMENT_FIELD, personalDataOptions,
    TEMPLATE_DOCUMENT,
} from "../../../../../../constants/document-management/document-list";

const EdoDocumentsRegistriesItemEditForm = (props) => {
    const {
        contractorData,
        clientId,
        registryId,
        documentType,
        onClose,
        editData,
        fetchList,
        isAddMissingContractor = false,
        loading,
        useTemplateFile,
        templateType,
    } = props;

    const edoPersonalDataOptions = personalDataOptions();
    const isEdit = !isEmpty(editData);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        addJobListItem,
        deleteJobListItem,
    } = useDataForm({
        fetchList,
        isAddMissingContractor,
        onClose,
        clientId,
        registryId,
        documentType,
        isEdit,
        editData,
        contractorData,
        useTemplateFile,
    });

    const getTitle = () => {
        if (!isAddMissingContractor) {
            return "Параметры документа";
        }

        return isEdit ? "Редактирование исполнителя (получателя документа)" : "Добавление исполнителя (получателя документа)";
    };

    const getDocumentNumber = () => {
        return (
            <NmInputV2
                label="Номер документа"
                required
                size="xl"
                maxLength={50}
                placeholder="Укажите номер документа"
                name={EDO_DOCUMENT_FIELD.PARAMS.DOCUMENT_NUMBER}
                value={values.params.documentNumber}
                onChange={handleChange}
                error={touched.params && touched.params.documentNumber ?
                    errors.params && errors.params.documentNumber :
                    false
                }
            />
        );
    };
    const getPersonalData = () => {
        return (
            <NmDropdownV2
                size="xl"
                fullWidth
                placeholder="Выберите перечнь ПД"
                label="Перечень ПД для обработки"
                required
                search
                multiple
                name={EDO_DOCUMENT_FIELD.PARAMS.PERSONAL_DATA}
                onChange={handleChange}
                value={values.params.personalData}
                options={edoPersonalDataOptions}
                error={touched.params && touched.params.personalData ?
                    errors.params && errors.params.personalData :
                    false
                }
            />
        );
    };
    const getDateDocument = () => {
        return (
            <NmDatePicker
                required
                size="xl"
                label="Дата документа"
                placeholderText="дд.мм.гг"
                dateFormat="dd.MM.yy"
                selected={values.documentDatetime}
                name="documentDatetime"
                onChange={handleChange}
                error={touched.documentDatetime && errors.documentDatetime}
            />
        );
    };

    const getNameDocument = () => {
        return (
            <NmInputV2
                required
                className="mt-2"
                label="Наименование документа"
                size="xl"
                maxLength={500}
                placeholder="Например: Трудовой договор с Иванченко"
                name="documentName"
                value={values.documentName}
                onChange={handleChange}
                error={touched.documentName && errors.documentName}
            />
        );
    };

    const getAdditionalContractorsDetail = () => {
        return (
            <NmInputV2
                tooltip={
                    <HelpTooltip
                        hover
                        info
                        position="top"
                        text="Вы можете указать дополнительные реквизиты по исполнителю, например, платежные данные"
                    />
                }
                label="Доп. реквизиты исполнителя"
                size="xl"
                maxLength={1000}
                placeholder="Введите доп. реквизиты исполнителя"
                name={EDO_DOCUMENT_FIELD.PARAMS.ADDITIONAL_CONTRACTORS_DETAIL}
                value={values.params.additionalContractorsDetail}
                onChange={handleChange}
                error={touched[EDO_DOCUMENT_FIELD.PARAMS.ADDITIONAL_CONTRACTORS_DETAIL] ?
                    errors && errors[EDO_DOCUMENT_FIELD.PARAMS.ADDITIONAL_CONTRACTORS_DETAIL] :
                    false
                }
            />
        );
    };

    const getDescription = () => {
        return (
            <NmInputV2
                label="Дополнительные сведения"
                size="xl"
                maxLength={255}
                placeholder="Введите дополнительные сведения"
                name="documentInformation"
                value={values.documentInformation}
                onChange={handleChange}
                error={touched.documentInformation && errors.documentInformation}
            />
        );
    };

    const getDocumentSubject = () => {
        return (
            <NmInputV2
                className="mt-2"
                label="Предмет договора"
                required
                size="xl"
                maxLength={255}
                placeholder="Введите предмет договора"
                name={EDO_DOCUMENT_FIELD.PARAMS.DOCUMENT_SUBJECT}
                value={values.params.documentSubject}
                onChange={handleChange}
                error={touched.params && touched.params.documentSubject ?
                    errors.params && errors.params.documentSubject :
                    false
                }
            />
        );
    };

    const getPaymentCurrency = () => {
        return (
            <NmInputV2
                label="Валюта оплаты"
                required
                size="xl"
                maxLength={255}
                placeholder="Укажите валюту оплаты"
                name={EDO_DOCUMENT_FIELD.PARAMS.PAYMENT_CURRENCY}
                value={values.params.paymentCurrency}
                onChange={handleChange}
                error={touched.params && touched.params.paymentCurrency ?
                    errors.params && errors.params.paymentCurrency :
                    false
                }
            />
        );
    };

    const getPlatformName = () => {
        return (
            <NmInputV2
                label="Наименование платформы"
                required
                size="xl"
                maxLength={1000}
                placeholder="Укажите наименование платформы"
                name={EDO_DOCUMENT_FIELD.PARAMS.PLATFORM_NAME}
                value={values.params.platformName}
                onChange={handleChange}
                error={touched.params && touched.params.platformName ?
                    errors.params && errors.params.platformName :
                    false
                }
            />
        );
    };

    const getStartOrEndWorkDate = () => {
        return (
            <NmDateRangePickerV2
                required
                label="Период выполнения работ"
                isClearable
                startFieldName={EDO_DOCUMENT_FIELD.PARAMS.START_WORK_DATE}
                endFieldName={EDO_DOCUMENT_FIELD.PARAMS.END_WORK_DATE}
                value={{
                    "params.startWorkDate": getDateObject(values.params.startWorkDate),
                    "params.endWorkDate": getDateObject(values.params.endWorkDate),
                }}
                error={touched.params && (touched.params.startWorkDate || touched.params.endWorkDate) ?
                    errors.params && (errors.params.startWorkDate || errors.params.endWorkDate) :
                    false
                }
                onChange={handleChange}
                size="xl"
            />
        );
    };

    const getFromOrToPeriod = () => {
        return (
            <NmDateRangePickerV2
                required
                label="Период оказываемых услуг"
                isClearable
                startFieldName={EDO_DOCUMENT_FIELD.PARAMS.PERIOD_FROM}
                endFieldName={EDO_DOCUMENT_FIELD.PARAMS.PERIOD_TO}
                value={{
                    "params.periodFrom": getDateObject(values.params.periodFrom),
                    "params.periodTo": getDateObject(values.params.periodTo),
                }}
                error={touched.params && (touched.params.periodFrom || touched.params.periodTo) ?
                    errors.params && (errors.params.periodFrom || errors.params.periodTo) :
                    false
                }
                onChange={handleChange}
                size="xl"
            />
        );
    };

    const getDocumentPlace = () => {
        return (
            <NmInputV2
                label="Место составления документа"
                required
                size="xl"
                maxLength={100}
                placeholder="Укажите место составления документа"
                name={EDO_DOCUMENT_FIELD.PARAMS.DOCUMENT_PLACE}
                value={values.params.documentPlace}
                onChange={handleChange}
                error={touched.params && touched.params.documentPlace ?
                    errors.params && errors.params.documentPlace :
                    false
                }
            />
        );
    };

    const getEmail = () => {
        return (
            <NmInputV2
                label="Эл. почта для отправки документов"
                required
                size="xl"
                maxLength={255}
                placeholder="Укажите e-mail"
                name={EDO_DOCUMENT_FIELD.PARAMS.EMAIL}
                value={values.params.email}
                onChange={handleChange}
                error={touched.params && touched.params.email ?
                    errors.params && errors.params.email :
                    false
                }
            />
        );
    };

    const getTargets = () => {
        return (
            <NmInputV2
                className="mt-2"
                label="Обработка персональных данных в целях"
                size="xl"
                maxLength={255}
                required
                placeholder="Введите цели обработки персональных данных"
                name={EDO_DOCUMENT_FIELD.PARAMS.TARGETS}
                value={values.params.targets}
                onChange={handleChange}
                error={touched.params && touched.params.targets ?
                    errors.params && errors.params.targets :
                    false
                }
            />
        );
    };

    const getContractNumber = () => {
        return (
            <NmInputV2
                label="Номер договора"
                required
                size="xl"
                maxLength={50}
                placeholder="Укажите номер договора"
                name={EDO_DOCUMENT_FIELD.PARAMS.CONTRACT_NUMBER}
                value={values.params.contractNumber}
                onChange={handleChange}
                error={touched.params && touched.params.contractNumber ?
                    errors.params && errors.params.contractNumber :
                    false
                }
            />
        );
    };

    const getContractDate = () => {
        return (
            <NmDatePicker
                required
                placeholderText="ДД.ММ.ГГ"
                dateFormat="dd.MM.yy"
                name={EDO_DOCUMENT_FIELD.PARAMS.CONTRACT_DATE}
                selected={values.params.contractDate}
                onChange={handleChange}
                error={touched?.params?.contractDate ?
                    errors.params?.contractDate :
                    false
                }
                isClearable
                label="Дата договора"
            />
        );
    };

    const getJobListAttachment = () => {
        return (
            <div className="mt-2">
                <Text.Sub
                    medium
                    className="mb-2"
                >
                    Перечень и стоимость оказанных услуг (работ)
                </Text.Sub>
                {values?.params?.jobList.map((job, index) => {
                    return (
                        <div
                            className="row gx-4 mb-3 mb-md-4"
                            key={index}
                        >
                            <div className="col-16 col-md-8">
                                <NmInputV2
                                    label="Наименование услуг"
                                    required
                                    size="xl"
                                    maxLength={255}
                                    placeholder="Наименований услуг"
                                    className="mb-3 mb-md-4"
                                    name={job.workName}
                                    value={job.workName}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].workName`, e.target.value);
                                    }}
                                    error={(touched?.params?.jobList && touched?.params?.jobList[index]?.workName) ?
                                        (errors?.params?.jobList && errors?.params?.jobList[index]?.workName) :
                                        false
                                    }
                                />
                            </div>
                            <div className="col-16 col-md-8">
                                <AmountInput
                                    label="Количество"
                                    required
                                    size="xl"
                                    placeholder="Количество"
                                    className="mb-3 mb-md-4"
                                    name={job.count}
                                    value={job.count}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].count`, e.target.value);
                                    }}
                                    error={(touched?.params?.jobList && touched?.params?.jobList[index]?.count) ?
                                        (errors?.params?.jobList && errors?.params?.jobList[index]?.count) :
                                        false
                                    }
                                    newInput
                                />
                            </div>
                            <div className="col-16 col-md-8">
                                <AmountInput
                                    label="Стоимость, ₽"
                                    required
                                    size="xl"
                                    placeholder="Стоимость"
                                    name={job.price}
                                    value={job.price}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].price`, e.target.value);
                                    }}
                                    error={touched?.params?.jobList && touched?.params?.jobList[index]?.price ?
                                        errors?.params?.jobList && errors?.params?.jobList[index]?.price :
                                        false
                                    }
                                    newInput
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                {
                                    (index >= 1) &&
                                    <NmButton
                                        size="lg"
                                        color="grey"
                                        className="mt-3 mt-md-4"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            deleteJobListItem(index);
                                        }}
                                    >
                                        Удалить
                                    </NmButton>
                                }
                                {
                                    (values.params?.jobList?.length < 20) && index === values.params?.jobList?.length - 1 &&
                                    <NmButton
                                        size="lg"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            addJobListItem();
                                        }}
                                        className="ms-4 mt-3 mt-md-4"
                                        color="light-green"
                                        icon={<AddIcon />}
                                    >
                                        Добавить еще
                                    </NmButton>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    const getJobListAct = () => {
        return (
            <div className="mt-2">
                <Text.Sub
                    medium
                    className="mb-2"
                >
                    Перечень и стоимость оказанных услуг (работ)
                </Text.Sub>
                {values?.params?.jobList.map((job, index) => {
                    return (
                        <div
                            className="row gx-4 mb-3 mb-md-4"
                            key={index}
                        >
                            <div className="col-16">
                                <NmInputV2
                                    label="Наименование услуг"
                                    required
                                    size="xl"
                                    maxLength={255}
                                    placeholder="Наименований услуг"
                                    className="mb-3 mb-md-4"
                                    name={job.workName}
                                    value={job.workName}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].workName`, e.target.value);
                                    }}
                                    error={(touched?.params?.jobList && touched?.params?.jobList[index]?.workName) ?
                                        (errors?.params?.jobList && errors?.params?.jobList[index]?.workName) :
                                        false
                                    }
                                />
                            </div>
                            <div className="col-16 col-md-8">
                                <AmountInput
                                    label="Количество"
                                    required
                                    size="xl"
                                    placeholder="Количество"
                                    className="mb-3 mb-md-4"
                                    name={job.count}
                                    value={job.count}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].count`, e.target.value);
                                    }}
                                    error={(touched?.params?.jobList && touched?.params?.jobList[index]?.count) ?
                                        (errors?.params?.jobList && errors?.params?.jobList[index]?.count) :
                                        false
                                    }
                                    newInput
                                />
                            </div>
                            <div className="col-16 col-md-8">
                                <AmountInput
                                    label="Стоимость, ₽"
                                    required
                                    size="xl"
                                    placeholder="Стоимость"
                                    className="mb-3 mb-md-4"
                                    name={job.price}
                                    value={job.price}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].price`, e.target.value);
                                    }}
                                    error={touched?.params?.jobList && touched?.params?.jobList[index]?.price ?
                                        errors?.params?.jobList && errors?.params?.jobList[index]?.price :
                                        false
                                    }
                                    newInput
                                />
                            </div>
                            <div className="col-16">
                                <NmInputV2
                                    label="Ссылка на результат"
                                    required
                                    size="xl"
                                    maxLength={255}
                                    placeholder="Результат"
                                    name={job.linkToResult}
                                    value={job.linkToResult}
                                    onChange={e => {
                                        setFieldValue(`params.jobList[${index}].linkToResult`, e.target.value);
                                    }}
                                    error={touched?.params?.jobList && touched?.params?.jobList[index]?.linkToResult ?
                                        errors?.params?.jobList && errors?.params?.jobList[index]?.linkToResult :
                                        false
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                {
                                    (index >= 1) &&
                                    <NmButton
                                        size="lg"
                                        color="grey"
                                        className="mt-3 mt-md-4"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            deleteJobListItem(index);
                                        }}
                                    >
                                        Удалить
                                    </NmButton>
                                }
                                {
                                    (values.params?.jobList?.length < 20) && index === values.params?.jobList?.length - 1 &&
                                    <NmButton
                                        size="lg"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            addJobListItem();
                                        }}
                                        className="ms-4 mt-3 mt-md-4"
                                        color="light-green"
                                        icon={<AddIcon />}
                                    >
                                        Добавить еще
                                    </NmButton>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    // наборы полей в зависимости от типа шаблона
    const defaultDataForm = () => {
        return (
            <>
                {getDateDocument()}
                {getNameDocument()}
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    };
    const tradeSecretDataForm = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    }; //данные для форм: доп. соглашениек рамочному договору об оказании невозмездных услуг №1,2 и обязательство о неразглошении коммерческой тайны
    const additionalAgreementNumber5 = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                <div className="mt-2">
                    {getPlatformName()}
                </div>
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );

    }; // Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №5
    const processingOfPersonalDataForm = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getPersonalData()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getTargets()}
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    }; // данные для формы согласие на обработку персональных данных
    const civilDeedDataForm = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getDocumentSubject()}
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getStartOrEndWorkDate()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDocumentPlace()}
                    </div>
                </div>
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    }; // Договор гражданско-правового характера (НДФЛ)
    const civilDeedWithIntellectualProperty = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getPaymentCurrency()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getPlatformName()}
                    </div>
                </div>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getEmail()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDocumentPlace()}
                    </div>
                </div>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getAdditionalContractorsDetail()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDescription()}
                    </div>
                </div>
            </>);
    }; // Договор гражданско-правового характера с условиями передачи интеллектуальных прав
    const attachmentToCivilDeedDataForm = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getContractNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getContractDate()}
                    </div>
                </div>
                {getNameDocument()}
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getFromOrToPeriod()}
                    </div>
                </div>
                {getJobListAttachment()}
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    };

    const actToCivilDeedDataForm = () => {
        return (
            <>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                <div className="row gx-4 mt-2">
                    <div className="col-16 col-md-8">
                        {getContractNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getContractDate()}
                    </div>
                </div>
                {getNameDocument()}
                {getJobListAct()}
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    };

    //журнал регистрация вводного инструктожа
    const registrationLogIntroductoryInstructionForm = () => {
        return (
            <>
                {getDateDocument()}
                {getNameDocument()}
                <div className="mt-2">
                    {getDescription()}
                </div>
            </>
        );
    };

    const getBodyFormByTypeDocument = () => {
        if (!useTemplateFile) {
            return defaultDataForm();
        }

        switch (templateType) {
        case TEMPLATE_DOCUMENT.TRADE_SECRET_NONDISCLOSURE_OBLIGATION:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8:
            return tradeSecretDataForm();
        case TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA:
            return processingOfPersonalDataForm();
        case TEMPLATE_DOCUMENT.CIVIL_DEED:
            return civilDeedDataForm();
        case TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY:
            return civilDeedWithIntellectualProperty();
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5:
            return additionalAgreementNumber5();
        case TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED:
            return attachmentToCivilDeedDataForm();
        case TEMPLATE_DOCUMENT.ACT_CIVIL_DEED:
            return actToCivilDeedDataForm();
        case TEMPLATE_DOCUMENT.REGISTRATION_LOG_INTRODUCTORY_INSTRUCTION:
        case TEMPLATE_DOCUMENT.REGISTRATION_LOG_PRIMARY_INSTRUCTION:
            return registrationLogIntroductoryInstructionForm();
        default:
            return defaultDataForm();
        }
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {getTitle()}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={loading}
        >
            <NmForm>
                {
                    !isAddMissingContractor ?
                        <>
                            <Text.Sub
                                className="col-16"
                                level="2"
                            >
                                {contractorData ? getFullName(contractorData.lastName, contractorData.firstName, contractorData.patronymic) : editData.receiverFio}
                            </Text.Sub>
                            <Text
                                level="3"
                                className="mb-3"
                            >
                                {phoneFormat(contractorData ? contractorData.phone : editData.receiverPhone)}
                            </Text>
                            <Divider className="mb-3" />
                        </> :
                        <>
                            <Text
                                level="3"
                                className="mb-3"
                            >
                                Введите номер телефона отсутствующего на площадке исполнителя, которому после передачи
                                реестра на подписание будет предложено зарегистрироваться на площадке для возможности
                                использования электронного документооборота с Вашей компанией и последующего подписания
                                документа.
                            </Text>
                            <NmInputV2
                                required
                                className="mt-2"
                                placeholder="Введите телефон исполнителя"
                                mask="+7 (999) 999 99 99"
                                maskChar={null}
                                size="lg"
                                label="Номер телефона исполнителя"
                                name="receiverPhone"
                                value={values.receiverPhone}
                                onChange={handleChange}
                                error={touched.receiverPhone && errors.receiverPhone}
                            />
                            <Text.Sub
                                className="col-16 mt-4 mb-2"
                                level="2"
                                bold
                            >
                                Параметры документа
                            </Text.Sub>
                        </>
                }
                {getBodyFormByTypeDocument()}
            </NmForm>
        </NmModal>
    );
};

export default EdoDocumentsRegistriesItemEditForm;