import {useState} from "react";

export const useKedoDirectoriesSort = () => {
    const [sortType, setSortType] = useState("BY_CREATE_UPDATE_AT_DESC");

    const sortOptions = [
        {
            key: "name",
            value: "name",
            sortType,
            text: "По наименованию",
            asc: "BY_NAME_ASC",
            desc: "BY_NAME_DESC",
        },
        {
            key: "date",
            value: "date",
            sortType,
            isDefaultSort: true,
            text: "По дате обновления",
            asc: "BY_CREATE_UPDATE_AT_ASC",
            desc: "BY_CREATE_UPDATE_AT_DESC",
        },
    ];

    const onClickSort = ({sortType}) => {
        setSortType(sortType);
    };

    return {
        onClickSort,
        sortType,
        sortOptions,
    };
};