import React from "react";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

export default function EdmStructuralUnitEditModal(props) {
    const {
        data: {
            value,
            subdivisionId,
        },
        onCancel,
        submit,
        onChange,
        formError,
    } = props;

    const isEdit = Boolean(subdivisionId);

    return (
        <NmModal
            size="md"
            onClose={onCancel}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование структурного подразделения" : "Добавление структурного подразделения"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={onCancel}
                    submit={submit}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                />
            }
        >
            <NmForm>
                <NmInputV2
                    size="xl"
                    label="Подразделение"
                    placeholder="Введите наименование подразделения"
                    error={formError["value"]}
                    name="value"
                    value={value}
                    onChange={onChange}
                />
            </NmForm>
        </NmModal>
    );
}