import axios from "axios";
import {all, call,put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getClientMemberList, orderAdminMembersSelector} from "./clientMember";
import {getEnrichedObjectById} from "./objects";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {compareManagers} from "../utils/objectHelper";
import request from "../utils/postman";
import {formatNumberAmount, getFormattedFullName, getFullName} from "../utils/stringFormat";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/project";
//*  TYPES  *//

const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";

const GET_PROJECT_LIST_REQUEST = "GET_PROJECT_LIST_REQUEST";
const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
const GET_PROJECT_LIST_ERROR = "GET_PROJECT_LIST_ERROR";

const GET_RICH_PROJECT_LIST_REQUEST = "GET_RICH_PROJECT_LIST_REQUEST";
const GET_RICH_PROJECT_LIST_SUCCESS = "GET_RICH_PROJECT_LIST_SUCCESS";
const GET_RICH_PROJECT_LIST_ERROR = "GET_RICH_PROJECT_LIST_ERROR";

const GET_PROJECTS_LIST_FOR_OBJECT_REQUEST = "GET_PROJECTS_LIST_FOR_OBJECT_REQUEST";

const GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST = "GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST";
const GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS = "GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS";
const GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR = "GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR";

const GET_PROJECT_OBJECT_USER_LIST_REQUEST = "GET_PROJECT_OBJECT_USER_LIST_REQUEST";
const GET_PROJECT_OBJECT_USER_LIST_SUCCESS = "GET_PROJECT_OBJECT_USER_LIST_SUCCESS";
const GET_PROJECT_OBJECT_USER_LIST_ERROR = "GET_PROJECT_OBJECT_USER_LIST_ERROR";

const GET_PROJECT_USER_LIST_REQUEST = "GET_PROJECT_USER_LIST_REQUEST";
const GET_PROJECT_USER_LIST_SUCCESS = "GET_PROJECT_USER_LIST_SUCCESS";
const GET_PROJECT_USER_LIST_ERROR = "GET_PROJECT_USER_LIST_ERROR";

const ADD_OBJECT_USER_REQUEST = "ADD_OBJECT_USER_REQUEST";
const ADD_OBJECT_USER_SUCCESS = "ADD_OBJECT_USER_SUCCESS";
const ADD_OBJECT_USER_ERROR = "ADD_OBJECT_USER_ERROR";

const DELETE_OBJECT_USER_REQUEST = "DELETE_OBJECT_USER_REQUEST";
const DELETE_OBJECT_USER_SUCCESS = "DELETE_OBJECT_USER_SUCCESS";
const DELETE_OBJECT_USER_ERROR = "DELETE_OBJECT_USER_ERROR";

const DELETE_ALL_OBJECT_USERS_REQUEST = "DELETE_ALL_OBJECT_USERS_REQUEST";
const DELETE_ALL_OBJECT_USERS_SUCCESS = "DELETE_ALL_OBJECT_USERS_SUCCESS";
const DELETE_ALL_OBJECT_USERS_ERROR = "DELETE_ALL_OBJECT_USERS_ERROR";

const CHANGE_STATUS_PROJECT_REQUEST = "CHANGE_STATUS_PROJECT_REQUEST";
const CHANGE_STATUS_PROJECT_SUCCESS = "CHANGE_STATUS_PROJECT_SUCCESS";
const CHANGE_STATUS_PROJECT_ERROR = "CHANGE_STATUS_PROJECT_ERROR";

const CHANGE_ARCHIVE_PROJECT_REQUEST = "CHANGE_ARCHIVE_PROJECT_REQUEST";
const CHANGE_ARCHIVE_PROJECT_SUCCESS = "CHANGE_ARCHIVE_PROJECT_SUCCESS";
const CHANGE_ARCHIVE_PROJECT_ERROR = "CHANGE_ARCHIVE_PROJECT_ERROR";

const GET_PROJECT_BY_ID_REQUEST = "GET_PROJECT_BY_ID_REQUEST";
const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
const GET_PROJECT_BY_ID_ERROR = "GET_PROJECT_BY_ID_ERROR";

const GET_PROJECT_FINANCES_REQUEST = "GET_PROJECT_FINANCES_REQUEST";
const GET_PROJECT_FINANCES_SUCCESS = "GET_PROJECT_FINANCES_SUCCESS";
const GET_PROJECT_FINANCES_ERROR = "GET_PROJECT_FINANCES_ERROR";

const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";

const UPDATE_FIELDS_STORE = "UPDATE_FIELDS_STORE";

const ADD_PROJECT_USER_REQUEST = "ADD_PROJECT_USER_REQUEST";
const ADD_PROJECT_USER_SUCCESS = "ADD_PROJECT_USER_SUCCESS";
const ADD_PROJECT_USER_ERROR = "ADD_PROJECT_USER_ERROR";

const DELETE_PROJECT_USER_REQUEST = "DELETE_PROJECT_USER_REQUEST";
const DELETE_PROJECT_USER_SUCCESS = "DELETE_PROJECT_USER_SUCCESS";
const DELETE_PROJECT_USER_ERROR = "DELETE_PROJECT_USER_ERROR";

const IMPORT_PROJECTS_REQUEST = "IMPORT_PROJECTS_REQUEST";
const IMPORT_PROJECTS_SUCCESS = "IMPORT_PROJECTS_SUCCESS";
const IMPORT_PROJECTS_ERROR = "IMPORT_PROJECTS_ERROR";

const GET_USER_ALL_REQUEST = "GET_USER_ALL_REQUEST";
const GET_USER_ALL_SUCCESS = "GET_USER_ALL_SUCCESS";
const GET_USER_ALL_ERROR = "GET_USER_ALL_ERROR";

const GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST = "GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST";
const GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS = "GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS";
const GET_USER_TEAM_BY_CLIENT_USER_ID_ERROR = "GET_USER_TEAM_BY_CLIENT_USER_ID_ERROR";

const GET_USERS_ON_PROJECTS_AND_ADMINS_REQUEST = "GET_USERS_ON_PROJECTS_AND_ADMINS_REQUEST";
const GET_USERS_ON_PROJECTS_AND_ADMINS_SUCCESS = "GET_USERS_ON_PROJECTS_AND_ADMINS_SUCCESS";
const GET_USERS_ON_PROJECTS_AND_ADMINS_ERROR = "GET_USERS_ON_PROJECTS_AND_ADMINS_ERROR";

//*  INITIAL STATE  *//

const initial = {
    progressList: false,
    error: {},
    pageData: {},
    projectsList: [],
    totalCount: 0,
    project: {},
    projectInfo: {},
    progressCard: false,
    objectUsersPageData: {},
    progressObjectUserList: false,
    objectUserList: [],
    projectUsersPageData: {},
    usersTotalCount: 0,
    projectUsers: [],
    projectUsersTotalCount: 0,
    progressUsers: false,
    progress: false,
    objectAndProjectMembers: [],
    projectsUserResponsibleFor: [],
    objectsAndProjectsUserResponsibleFor: [],
    managerListByUser: [],
    getUsersAllProgress: false,
    projectsForObjectEdit: [],
    projectUsersAndAdmins: [],
    projectsForOrders: [],
    projectsForTasks: [],
    progressProjectUsersAndAdmins: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_USERS_ON_PROJECTS_AND_ADMINS_REQUEST:
        return {
            ...state,
            progressProjectUsersAndAdmins: true,
        };
    case GET_USERS_ON_PROJECTS_AND_ADMINS_SUCCESS:
        return {
            ...state,
            projectUsersAndAdmins: payload,
            progressProjectUsersAndAdmins: false,
        };
    case GET_USERS_ON_PROJECTS_AND_ADMINS_ERROR:
        return {
            ...state,
            progressProjectUsersAndAdmins: false,
        };
    case GET_PROJECTS_LIST_FOR_OBJECT_REQUEST:
    case GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST:
        return {
            ...state,
            progressList: true,
        };
    case GET_USER_ALL_REQUEST:
        return {
            ...state,
            getUsersAllProgress: true,
        };
    case GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS:
        return {
            ...state,
            managerListByUser: payload,
        };
    case GET_USER_ALL_SUCCESS:
        return {
            ...state,
            objectAndProjectMembers: payload,
            getUsersAllProgress: false,
        };
    case IMPORT_PROJECTS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_PROJECT_BY_ID_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case GET_PROJECT_LIST_REQUEST:
    case GET_RICH_PROJECT_LIST_REQUEST:
        return {
            ...state,
            progressList: true,
            pageData: payload,
        };
    case GET_PROJECT_USER_LIST_REQUEST:
        return {
            ...state,
            projectUsersPageData: payload,
            progressUsers: true,
        };
    case GET_PROJECT_OBJECT_USER_LIST_REQUEST:
        return {
            ...state,
            progressObjectUserList: true,
            objectUsersPageData: payload,
        };
    case GET_PROJECT_OBJECT_USER_LIST_SUCCESS: {
        const {
            projectUserObjectModels,
            totalCount: usersTotalCount,
            fieldName = "objectUserList",
        } = payload;

        return {
            ...state,
            progressObjectUserList: false,
            usersTotalCount,
            [fieldName]: projectUserObjectModels,
        };
    }
    case IMPORT_PROJECTS_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case GET_PROJECT_LIST_SUCCESS:
    case GET_RICH_PROJECT_LIST_SUCCESS:
        const {
            projectModels,
            totalCount,
            fieldName = "projectsList",
        } = payload;

        return {
            ...state,
            progressList: false,
            [fieldName]: [...projectModels],
            totalCount: fieldName === "projectsList" ? totalCount : state.totalCount,
        };
    case GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS:
        return {
            ...state,
            progressList: false,
            projectsForObjectEdit: payload,
        };
    case GET_PROJECT_FINANCES_SUCCESS:
        return {
            ...state,
            projectInfo: payload,
        };
    case GET_PROJECT_BY_ID_SUCCESS:
        return {
            ...state,
            project: payload,
            progressCard: false,
        };
    case GET_PROJECT_USER_LIST_SUCCESS: {
        const {
            results,
            totalCount: projectUsersTotalCount,
            fieldName = "projectUsers",
        } = payload;
        return {
            ...state,
            progressUsers: false,
            projectUsersTotalCount,
            [fieldName]: results,
        };
    }
    case GET_PROJECT_USER_LIST_ERROR:
        return {
            ...state,
            progressUsers: false,
        };
    case GET_PROJECT_OBJECT_USER_LIST_ERROR:
        return {
            ...state,
            progressObjectUserList: false,
        };
    case GET_PROJECT_BY_ID_ERROR:
        return {
            ...state,
            progressCard: false,
            project: {},
        };
    case GET_PROJECT_LIST_ERROR:
    case GET_RICH_PROJECT_LIST_ERROR:
    case GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR:
        return {
            ...state,
            progressList: false,
        };
    case IMPORT_PROJECTS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case UPDATE_FIELDS_STORE:
        return {
            ...state,
            ...payload,
        };
    case GET_USER_ALL_ERROR:
        return {
            ...state,
            getUsersAllProgress: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function addProject(payload) {
    return {
        type: ADD_PROJECT_REQUEST,
        payload,
    };
}

export function getProjectsList(payload) {
    return {
        type: GET_PROJECT_LIST_REQUEST,
        payload,
    };
}

export function getRichProjectsList(payload) {
    return {
        type: GET_RICH_PROJECT_LIST_REQUEST,
        payload,
    };
}

export function getProjectsListForObject(payload) {
    return {
        type: GET_PROJECTS_LIST_FOR_OBJECT_REQUEST,
        payload,
    };
}

export function getObjectUserList(payload) {
    return {
        type: GET_PROJECT_OBJECT_USER_LIST_REQUEST,
        payload,
    };
}

export function getProjectUserList(payload) {
    return {
        type: GET_PROJECT_USER_LIST_REQUEST,
        payload,
    };
}

export function addObjectUser(payload) {
    return {
        type: ADD_OBJECT_USER_REQUEST,
        payload,
    };
}

export function addProjectUser(payload) {
    return {
        type: ADD_PROJECT_USER_REQUEST,
        payload,
    };
}

export function deleteObjectUser(payload) {
    return {
        type: DELETE_OBJECT_USER_REQUEST,
        payload,
    };
}

export function deleteProjectUser(payload) {
    return {
        type: DELETE_PROJECT_USER_REQUEST,
        payload,
    };
}

export function changeProjectStatus(payload) {
    return {
        type: CHANGE_STATUS_PROJECT_REQUEST,
        payload,
    };
}

export function changeProjectArchived(payload) {
    return {
        type: CHANGE_ARCHIVE_PROJECT_REQUEST,
        payload,
    };
}

export function getProjectById(payload) {
    return {
        type: GET_PROJECT_BY_ID_REQUEST,
        payload,
    };
}

export function updateProject(payload) {
    return {
        type: UPDATE_PROJECT_REQUEST,
        payload,
    };
}

export function updateFieldsStoreProject(payload) {
    return {
        type: UPDATE_FIELDS_STORE,
        payload,
    };
}

export function getProjectsFinances(payload) {
    return {
        type: GET_PROJECT_FINANCES_REQUEST,
        payload,
    };
}

export function importProjectsFromFile(payload) {
    return {
        type: IMPORT_PROJECTS_REQUEST,
        payload,
    };
}

export function getObjectAndProjectMembers(payload) {
    return {
        type: GET_USER_ALL_REQUEST,
        payload,
    };
}

export function getUserTeamByClientUserId(payload) {
    return {
        type: GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST,
        payload,
    };
}

export function getOrderTemplateProjectsList(payload) {
    return {
        type: GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST,
        payload,
    };
}

// Метод для получения ответственных на форме способа создания заказа
// возвращает адиминов компании + сотрудников привязанных к проекту, объекту
export function getUsersOnProjectsAndAdmins(payload) {
    return {
        type: GET_USERS_ON_PROJECTS_AND_ADMINS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const projectsSelector = state => state.projects;
export const projectProgressSelector = createSelector(projectsSelector, ({progress}) => progress);
export const projectGetUsersAllProgressSelector = createSelector(projectsSelector, ({getUsersAllProgress}) => getUsersAllProgress);
export const getProjectsTotalPagesSelector = createSelector(projectsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getObjectUsersTotalPagesSelector = createSelector(projectsSelector, ({usersTotalCount, objectUsersPageData: {pageSize = 0}}) => getTotalPages(usersTotalCount, pageSize));
export const getObjectUsersTotalCountSelector = createSelector(projectsSelector, ({usersTotalCount}) => usersTotalCount);
export const projectsProgressObjectUserList = createSelector(projectsSelector, ({progressObjectUserList}) => progressObjectUserList);
export const getObjectUserListSelector = createSelector(projectsSelector, ({objectUserList}) => objectUserList);
export const getProjectsListSelector = createSelector(projectsSelector, ({projectsList}) => projectsList);
export const projectPageDataSelector = createSelector(projectsSelector, ({pageData}) => pageData);
export const getProjectsOptionsSelector = createSelector(projectsSelector, ({projectsList}) => {
    return projectsList.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});
export const getProjectsForOrdersOptionsSelector = createSelector(projectsSelector, ({projectsForOrders}) => {
    return projectsForOrders.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});
export const getProjectsForTasksOptionsSelector = createSelector(projectsSelector, ({projectsForTasks}) => {
    return projectsForTasks.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});

export const getProjectsOptionsForObjectEditSelector = createSelector(projectsSelector, ({projectsForObjectEdit}) => {
    return projectsForObjectEdit.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});

export const orderManagerOptionsWithObjectSelector = createSelector(state => state, (state) => {
    const {projects: {objectAndProjectMembers: _objectAndProjectMembers}} = state;

    const _adminMembers = orderAdminMembersSelector(state);

    const adminMembers = [..._adminMembers];
    const objectAndProjectMembers = [..._objectAndProjectMembers];

    const joined = objectAndProjectMembers.concat(adminMembers);

    const options = joined.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: getFormattedFullName(getFullName(item.lastName, item.firstName, item.patronymic)),
    }));

    const ids = joined.map(value => value.clientUserId);
    const filteredIds = ids.filter((value, index) => ids.indexOf(value) === index);

    return filteredIds.map(key => {
        const optionValue = options.find(option => option.key === key) || {};

        return {
            ...optionValue,
        };
    });
});

export const managersObjectSelectedSelector = createSelector(state => state, (state) => {
    const {projects: {objectAndProjectMembers}} = state;

    const adminMembers = orderAdminMembersSelector(state);

    return [...objectAndProjectMembers, ...adminMembers];
});

export const getProjectsTotalCountSelector = createSelector(projectsSelector, ({totalCount}) => totalCount);
export const getProjectSelector = createSelector(projectsSelector, ({project}) => project);
export const getProjectInfoForCardSelector = createSelector(projectsSelector, ({projectInfo, pageData}) => {
    const {
        projectUnlimited,
        projectBalance,
        availableForPaymentsAmount,
        orderReserveAmount,
        orderReserveCommissionAmount,
    } = projectInfo;
    const {
        archived,
    } = pageData;

    let budget = projectBalance === 0 && projectUnlimited ? null : projectBalance;

    //Бюджет - 0,00 Р (так как все проекты закрыты)
    if (archived) {
        budget = 0;
    }

    return [
        {
            label: "Бюджет",
            infinity: projectUnlimited,
            value: budget,
            subValue: "С учетом комиссии, ₽",
        },
        {
            label: "Доступно для выплат",
            value: availableForPaymentsAmount,
        },
        {
            label: "Резерв по заказам",
            value: archived ? 0 : orderReserveAmount,
            subValue: archived ? "0,00 Р" : `+${formatNumberAmount(orderReserveCommissionAmount)}`,
        },
    ];
});
export const projectListForObjectEditSelector = createSelector(projectsSelector, ({projectsForObjectEdit}) => projectsForObjectEdit);
export const projectProgressListSelector = createSelector(projectsSelector, ({progressList}) => progressList);
export const projectProgressCardSelector = createSelector(projectsSelector, ({progressCard}) => progressCard);
export const projectProgressUsersSelector = createSelector(projectsSelector, ({progressUsers}) => progressUsers);
export const projectUsersSelector = createSelector(projectsSelector, ({projectUsers}) => projectUsers);
export const projectUsersTotalPagesSelector = createSelector(projectsSelector, ({projectUsersTotalCount, projectUsersPageData: {pageSize = 0}}) => getTotalPages(projectUsersTotalCount, pageSize));
export const projectUsersTotalCountSelector = createSelector(projectsSelector, ({projectUsersTotalCount}) => projectUsersTotalCount);
export const projectStatusSelector = createSelector(projectsSelector, ({project: {status}}) => status);
export const managerListByUserSelector = createSelector(projectsSelector, ({managerListByUser}) => managerListByUser);
export const managerListByUserOptionsSelector = createSelector(projectsSelector, ({managerListByUser}) =>
    managerListByUser.map(manager => ({
        key: manager.clientUserId,
        value: manager.clientUserId,
        text: getFormattedFullName(getFullName(manager.lastName, manager.firstName, manager.patronymic)),
    })).sort(compareManagers),
);
export const projectUsersAndAdminsSelector = createSelector(projectsSelector, ({projectUsersAndAdmins}) => projectUsersAndAdmins);
export const projectUsersAndAdminsOptionsSelector = createSelector(projectsSelector, ({projectUsersAndAdmins}) => projectUsersAndAdmins.map(item => ({
    key: item.clientUserId,
    value: item.clientUserId,
    text: getFormattedFullName(getFullName(item.lastName, item.firstName, item.patronymic)),
})));
export const projectUsersAndAdminsProgressSelector = createSelector(projectsSelector, ({progressProjectUsersAndAdmins}) => progressProjectUsersAndAdmins);

//*  SAGA  *//

export const addProjectSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_PROJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Проект успешно добавлен");

        yield put(getProjectsFinances({
            clientId: payload.clientId,
        }));

        const {
            projects: {
                pageData,
            },
        } = yield select();

        yield put(getProjectsList(pageData));
        yield put({type: ADD_PROJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_PROJECT_ERROR, payload: error});
    }
};

///api/project/getProjectPage
export const getProjectsListSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;
        const result = yield request.post(`${controller}/getProjectPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PROJECT_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_PROJECT_LIST_ERROR, payload: error});
    }
};

//POST /api/project/getProjectOrderTemplateModels
export const getOrderTemplateProjectsListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getProjectOrderTemplateModels`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR, payload: error});
    }
};

// /api/project/getRichPage
export const getRichProjectsListSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;
        const result = yield request.post(`${controller}/getRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RICH_PROJECT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_RICH_PROJECT_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_RICH_PROJECT_LIST_ERROR, payload: error});
    }
};

export const getObjectUserListSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;
        const result = yield request.post(`${controller}/getProjectObjectUserPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_OBJECT_USER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PROJECT_OBJECT_USER_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_PROJECT_OBJECT_USER_LIST_ERROR, payload: error});
    }
};

//POST/api/project/getPageProjectUser

export const getProjectUserListSaga = function* ({payload}) {
    try {
        const {fieldName, callBack, ...reqData} = payload;
        const result = yield request.post(`${controller}/v2/getProjectUserPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_USER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (callBack) {
            const {projectUserModels} = result;
            const projectIds = projectUserModels.map(value => value.projectIdsList).flat();

            callBack(projectIds);
        }

        yield put({
            type: GET_PROJECT_USER_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_PROJECT_USER_LIST_ERROR, payload: error});
    }
};

export const addObjectUserSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/addProjectObjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_OBJECT_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно добавлен");

        const {
            projects: {
                objectUsersPageData,
            },
            clientMember: {
                pageData,
            },
        } = yield select();
        const {clientId, objectId} = objectUsersPageData;

        yield put(getEnrichedObjectById({clientId, objectId}));
        yield put(getObjectUserList(objectUsersPageData));
        yield put(getClientMemberList(pageData));

        yield put({type: ADD_OBJECT_USER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_OBJECT_USER_ERROR, payload: error});
    }
};

export const deleteObjectUserSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/deleteProjectObjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_OBJECT_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно удалён");

        const {
            projects: {
                objectUsersPageData,
            },
            clientMember: {
                pageData,
            },
        } = yield select();
        const {clientId, objectId} = objectUsersPageData;

        yield put(getEnrichedObjectById({clientId, objectId}));
        yield put(getObjectUserList(objectUsersPageData));
        yield put(getClientMemberList(pageData));

        yield put({type: DELETE_OBJECT_USER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_OBJECT_USER_ERROR, payload: error});
    }
};

export const deleteAllObjectUsersSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/deleteAllProjectObjectUser`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ALL_OBJECT_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Все сотрудники успешно удалены");

        const {
            projects: {
                objectUsersPageData,
            },
        } = yield select();

        yield put(getObjectUserList(objectUsersPageData));

        yield put({type: DELETE_ALL_OBJECT_USERS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_ALL_OBJECT_USERS_ERROR, payload: error});
    }
};

//api/project/changeProjectStatus

export const changeStatusProjectSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/changeProjectStatus`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_STATUS_PROJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Статус успешно обновлен");

        const {
            projects: {
                pageData,
            },
        } = yield select();

        yield put(getProjectsFinances({
            clientId: pageData.clientId,
        }));
        yield put(getProjectsList(pageData));
        yield put({type: CHANGE_STATUS_PROJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_STATUS_PROJECT_ERROR, payload: error});
    }
};

//api/project/changeProjectArchived

export const changeProjectArchivedSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/changeProjectArchived`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHANGE_ARCHIVE_PROJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        const {archived} = payload;
        toastSuccess(`Проект успешно перенесён ${archived ? "в архив" : "из архива"}`);

        const {
            projects: {
                pageData,
            },
        } = yield select();

        yield put(getProjectsList(pageData));
        yield put({type: CHANGE_ARCHIVE_PROJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CHANGE_ARCHIVE_PROJECT_ERROR, payload: error});
    }
};

export const importProjectsFromFileSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            clientId,
            data,
            onSuccess,
        } = payload;

        const result = yield call(axios, {
            url: `/api/job/import/objects/import/${clientId}/file`,
            method: "post",

            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "multipart/form-data",
            },
            data,
        },
        );

        const {
            data: {
                errorMessage,
            },
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: IMPORT_PROJECTS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Загрузка файла успешно завершена. Новые проекты и объекты станут доступны через некоторое время.");

        const state = yield select();
        yield put(getProjectsList({...projectPageDataSelector(state)}));

        yield put({type: IMPORT_PROJECTS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: IMPORT_PROJECTS_ERROR, payload: error});
    }
};
//GET /api/project/getProjectById

export const getProjectByIdSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/getProjectById`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PROJECT_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PROJECT_BY_ID_ERROR, payload: error});
    }
};

//GET /api/project/getProjectById

export const updateProjectSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                isGetList,
                isGetCard,
            },
        } = action;
        const result = yield request.post(`${controller}/update`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_PROJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Проект успешно изменен");

        const {
            projects: {
                pageData,
            },
        } = yield select();

        if (isGetList) {
            yield put(getProjectsFinances({
                clientId: data.clientId,
            }));
            yield put(getProjectsList(pageData));
        }

        const {
            projectId,
            clientId,
        } = data;

        if (isGetCard) {
            yield put(getProjectById({
                projectId,
                clientId,
            }));
        }

        yield put({type: UPDATE_PROJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_PROJECT_ERROR, payload: error});
    }
};

//POST /api/project/addProjectUser

export const addProjectUserSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/addProjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_PROJECT_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно добавлен");

        const {
            projects: {
                projectUsersPageData,
            },
            clientMember: {
                pageData,
            },
        } = yield select();

        yield put(getClientMemberList(pageData));
        yield put(getProjectUserList(projectUsersPageData));

        yield put({type: ADD_PROJECT_USER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_PROJECT_USER_ERROR, payload: error});
    }
};


///api/project/deleteProjectUser

export const deleteProjectUserSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/deleteProjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_PROJECT_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно удален из проекта");

        const {
            projects: {
                projectUsersPageData,
            },
            clientMember: {
                pageData,
            },
        } = yield select();

        yield put(getClientMemberList(pageData));
        yield put(getProjectUserList(projectUsersPageData));

        yield put({type: DELETE_PROJECT_USER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_PROJECT_USER_ERROR, payload: error});
    }
};

//GET /api/project/getProjectById

export const getProjectsFinancesSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/getProjectsFinances`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_FINANCES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({type: GET_PROJECT_FINANCES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PROJECT_FINANCES_ERROR, payload: error});
    }
};

///api/project/getUserAll

// Получаем сотрудников, которые есть на объекте и проекте

export const getObjectAndProjectMembersSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getUserAll`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USER_ALL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_USER_ALL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_USER_ALL_ERROR, payload: error});
    }
};

export const getUserTeamByUserIdSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
            roleFilters,
        } = payload;

        const result = yield request.get(
            `${controller}/getUserTeamByClientUserId?clientId=${clientId}&clientUserId=${clientUserId}&roleFilters=${roleFilters}`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USER_ALL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_USER_TEAM_BY_CLIENT_USER_ID_ERROR, payload: error});
    }
};

// Для получения ответственных на форме способа создания заказа
// возвращает адиминов компании + сотрудников привязанных к проекту, объекту
export const getUsersOnProjectsAndAdminsSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getUsersOnProjectsAndAdmins`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USERS_ON_PROJECTS_AND_ADMINS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_USERS_ON_PROJECTS_AND_ADMINS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_USERS_ON_PROJECTS_AND_ADMINS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_PROJECT_REQUEST, addProjectSaga),
        takeEvery(GET_PROJECT_LIST_REQUEST, getProjectsListSaga),
        takeEvery(CHANGE_STATUS_PROJECT_REQUEST, changeStatusProjectSaga),
        takeEvery(CHANGE_ARCHIVE_PROJECT_REQUEST, changeProjectArchivedSaga),
        takeEvery(GET_PROJECT_BY_ID_REQUEST, getProjectByIdSaga),
        takeEvery(GET_PROJECT_OBJECT_USER_LIST_REQUEST, getObjectUserListSaga),
        takeEvery(ADD_OBJECT_USER_REQUEST, addObjectUserSaga),
        takeEvery(DELETE_OBJECT_USER_REQUEST, deleteObjectUserSaga),
        takeEvery(DELETE_ALL_OBJECT_USERS_REQUEST, deleteAllObjectUsersSaga),
        takeEvery(UPDATE_PROJECT_REQUEST, updateProjectSaga),
        takeEvery(GET_PROJECT_USER_LIST_REQUEST, getProjectUserListSaga),
        takeEvery(ADD_PROJECT_USER_REQUEST, addProjectUserSaga),
        takeEvery(DELETE_PROJECT_USER_REQUEST, deleteProjectUserSaga),
        takeEvery(IMPORT_PROJECTS_REQUEST, importProjectsFromFileSaga),
        takeEvery(GET_PROJECT_FINANCES_REQUEST, getProjectsFinancesSaga),
        takeEvery(GET_USER_ALL_REQUEST, getObjectAndProjectMembersSaga),
        takeEvery(GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST, getUserTeamByUserIdSaga),
        takeEvery(GET_PROJECTS_LIST_FOR_OBJECT_REQUEST, getProjectsListSaga),
        takeEvery(GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST, getOrderTemplateProjectsListSaga),
        takeEvery(GET_USERS_ON_PROJECTS_AND_ADMINS_REQUEST, getUsersOnProjectsAndAdminsSaga),
        takeEvery(GET_RICH_PROJECT_LIST_REQUEST, getRichProjectsListSaga),
    ]);
}
