import React, {FC} from "react";

import Text from "../Text";

import bem from "../../../utils/bem";

import "./style.sass";

interface IStepsNumberProgress {
    totalCount: number,
    className?: string,
    currentStepNumber: number,
}

const StepsNumberProgress: FC<IStepsNumberProgress> = (props) => {
    const {
        className = "",
        totalCount,
        currentStepNumber,
    } = props;

    const [block] = bem("steps-number-progress", className);

    return (
        <Text
            level="4"
            className={block()}
        >
            {`Шаг ${currentStepNumber} из ${totalCount}`}
        </Text>
    );
};

export default StepsNumberProgress;