import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import UnavailableTemplates from "../../../../components/UnavailableTemplates";

import {getUnavailableTemplatesFlags} from "../../../../components/UnavailableTemplates/utils";
import validate from "../../../../utils/validate";

import {UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE} from "../../../../components/UnavailableTemplates/constants";
import {FC_REGISTRIS_STATUS_DICT} from "../../../../constants/fcRegistries";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {requiredMessage} from "../../../../constants/validationRules";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../templates/constants";

import {
    clientObjectOptionsSelector,
    getClientObjectList,
} from "../../../../ducks/clientObject";
import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {
    addRegistryFrameContract,
    documentNotificationProgressUpdateSelector,
    downloadDocument,
    updateRegistryFrameContract,
} from "../../../../ducks/documents";
import {
    documentCustomTemplateProgressReplacementsForUnavailableSelector,
    frameContractTemplatesListSelector,
    getDocumentCustomTemplateShortDefault,
    getDocumentsCustomTemplateReplacementsForUnavailableList,
    updateDocumentCustomTemplateStore,
} from "../../../../ducks/documentsCustomTemplate";

import PropTypes from "prop-types";

import "./style.sass";

class RegistryFrameContractEdit extends Component {
    static propTypes = {
        registryObj: PropTypes.object,
        clientId: PropTypes.string,
        close: PropTypes.func,
        onAddOrEditCbk: PropTypes.func,
    };

    static defaultProps = {
        registryObj: {},
        clientId: "",
        close: () => {
        },
        onAddOrEditCbk: () => {
        },
    };

    constructor(props) {
        super(props);

        const {registryObj, t} = props;

        this.state = {
            registryObj: {
                contractType: ORDER_WORK_REPORT_TYPE.SMZ,
                ...registryObj,
            },
            error: {},
        };

        this.title = registryObj.registryId ?
            t("registry-frame-contract-edit.edit-form-header") :
            t("registry-frame-contract-edit.add-form-header");
    }

    componentDidMount() {
        const {getDocumentCustomTemplateShortDefault, clientId} = this.props;
        const {registryObj: {contractType, registryId}} = this.state;

        getDocumentCustomTemplateShortDefault({
            clientIdFilter: clientId,
            frameContractsRegistryIdFilter: registryId,
            orderContractPaymentTypeFilter: contractType,
            documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
            onSuccess: ({id}) => {
                this.handleChange(null, {
                    name: "frameContractTemplateId",
                    value: id,
                });
            },
        });

        this.fetchObjects();
    }

    componentWillUnmount() {
        const {updateDocumentCustomTemplateStore} = this.props;

        updateDocumentCustomTemplateStore({frameContractTemplatesList: []});
    }

    handleChange = (e, {name, value, checked}) => {
        if (name === "commentary" && value.length > 255) {
            value = value.slice(0, 255);
        }

        const callback = () => {
            if (name === "contractType") {
                this.fetchObjects();
            }
        };

        if (name === "contractType" && value === ORDER_WORK_REPORT_TYPE.CIVIL) {
            this.setState(prevState => ({
                ...prevState,
                registryObj: {
                    ...prevState.registryObj,
                    [name]: value || checked,
                    signingWithoutTaxpayerAndPartner: false,
                    signingOnlyWithSmzTaxOffer: false,
                },
            }), callback);

            return;
        }

        if (name === "contractType" && value === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            this.setState(prevState => ({
                ...prevState,
                registryObj: {
                    ...prevState.registryObj,
                    [name]: value || checked,
                    signingBeforeCheckPersonalData: false,
                    signingWithoutTaxpayerAndPartner: false,
                    signingOnlyWithSmzTaxOffer: false,
                },
            }), callback);

            return;
        }

        if (["signingBeforeCheckPersonalData", "signingWithoutTaxpayerAndPartner", "signingOnlyWithSmzTaxOffer"].includes(name) && checked) {
            const _name = ["signingBeforeCheckPersonalData", "signingWithoutTaxpayerAndPartner"].includes(name) ? "signingOnlyWithSmzTaxOffer" : "signingWithoutTaxpayerAndPartner";

            this.setState(prevState => ({
                ...prevState,
                registryObj: {
                    ...prevState.registryObj,
                    [name]: value || checked,
                    [_name]: false,
                },
            }), callback);

            return;
        }

        if (name === "frameContractByObjectTemplate" && !checked) {
            this.setState(prevState => ({
                ...prevState,
                registryObj: {
                    ...prevState.registryObj,
                    [name]: value || checked,
                    objectId: null,
                },
            }), callback);

            return;
        }

        this.setState(prevState => ({
            ...prevState,
            registryObj: {
                ...prevState.registryObj,
                [name]: value || checked,
            },
        }), callback);
    };

    checkIsValid() {
        const {registryObj} = this.state;

        const objectRule = registryObj.frameContractByObjectTemplate ? {
            objectId: {
                required: requiredMessage,
            },
        } : {};

        const rule = {
            name: {
                required: requiredMessage,
                maxLength: {
                    value: 100,
                    message: "Максимальная длина - 100 символов",
                },
            },
            ...objectRule,
        };
        const error = validate(registryObj, rule, "");

        this.setState({error});

        return Object.values(error).length === 0;
    }

    submit = (registryId) => {
        const {registryObj} = this.state;

        const {
            clientId,
            onAddOrEditCbk,
        } = this.props;

        const {
            name,
            commentary,
            contractType,
            frameContractTemplateId,
            signingBeforeCheckPersonalData,
            signingWithoutTaxpayerAndPartner,
            signingOnlyWithSmzTaxOffer,
            frameContractByObjectTemplate,
            objectId,
        } = registryObj;

        if (registryId) {
            const {updateRegistryFrameContract} = this.props;

            updateRegistryFrameContract({
                data: {
                    registryId,
                    name,
                    commentary,
                    contractType,
                    clientId,
                    frameContractTemplateId,
                    signingBeforeCheckPersonalData,
                    signingWithoutTaxpayerAndPartner,
                    signingOnlyWithSmzTaxOffer,
                    frameContractByObjectTemplate,
                    objectId: frameContractByObjectTemplate ? objectId : null,
                },
                onSuccess: onAddOrEditCbk,
            });

            return;
        }

        const {addRegistryFrameContract} = this.props;

        addRegistryFrameContract({
            data: {
                name,
                commentary,
                contractType,
                clientId,
                frameContractTemplateId,
                signingBeforeCheckPersonalData,
                signingWithoutTaxpayerAndPartner,
                signingOnlyWithSmzTaxOffer,
                frameContractByObjectTemplate,
                objectId: frameContractByObjectTemplate ? objectId : undefined,
            },
            onSuccess: onAddOrEditCbk,
        });
    };

    checkUnavailableTemplates = () => {
        if (!this.checkIsValid()) {
            return;
        }

        const {registryObj} = this.state;
        const {getDocumentsCustomTemplateReplacementsForUnavailableList, clientId} = this.props;

        // Проверяем на недоступные шаблоны
        getDocumentsCustomTemplateReplacementsForUnavailableList({
            clientId,
            frameContractsRegistryId: registryObj.registryId,
            frameContractByObjectTemplate: registryObj.frameContractByObjectTemplate,
            customDocumentIds: [
                registryObj.frameContractTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isTemplatesDeleted,
                    isChangedDefaultTemplateRD,
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем для удаленных шаблонов,
                // При изменении дефолтного шаблона РД у клиента,
                // При отключенной опции "Свои шаблоны"
                if (isTemplatesDeleted || isChangedDefaultTemplateRD || isDisabledTemplateClientSetting) {
                    this.onOpenUnavailableTemplates();

                    return;
                }

                this.submit(registryObj.registryId);
            },
        });
    };

    downloadTemplate = () => {
        const {
            downloadDocument,
            frameContractTemplatesList,
        } = this.props;

        const {
            registryObj: {
                frameContractTemplateId,
            },
        } = this.state;

        const {
            name,
            pdfDownloadLink,
        } = frameContractTemplatesList.find(item => item.id === frameContractTemplateId) || {};

        downloadDocument({
            downloadLink: pdfDownloadLink,
            documentType: name,
            extension: "pdf",
        });
    };

    onCloseUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: false,
        });
    };

    onOpenUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: true,
        });
    };

    fetchObjects = () => {
        const {
            getClientObjectList,
            clientId,
        } = this.props;
        const {
            registryObj:
                {
                    contractType,
                },
        } = this.state;

        getClientObjectList({
            clientId,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            status: "IN_WORK",
            objectFrameContractTypeFilter: contractType,
        });
    };

    renderUnavailableTemplates = () => {
        const {actionRegistryProgress} = this.props;
        const {
            isOpenUnavailableTemplates,
            registryObj,
        } = this.state;

        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_DOCUMENTS_RD}
                progress={actionRegistryProgress}
                onClose={this.onCloseUnavailableTemplates}
                onSubmit={() => {
                    this.submit(registryObj.registryId);
                }}
                header={this.title}
            />
        );
    };

    render() {
        const {
            close,
            actionRegistryProgress,
            registryObj,
            clientProperties: {
                customDocumentTemplatesAvailable,
                individualRegistryPaymentsAvailable,
            },
            objectOptions,
            t,
        } = this.props;
        const {
            error,
            registryObj:
                {
                    name,
                    commentary,
                    registryId,
                    contractType,
                    signingBeforeCheckPersonalData,
                    signingWithoutTaxpayerAndPartner,
                    signingOnlyWithSmzTaxOffer,
                    status,
                    frameContractByObjectTemplate,
                    objectId,
                },
        } = this.state;

        return (
            <NmModal
                size="md"
                onClose={close}
                className="registry-frame-contract-edit"
                header={
                    <NmTitle size="lg">
                        {this.title}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        onClose={close}
                        submit={this.checkUnavailableTemplates}
                        cancelBtnContent={t("button.cancel")}
                        loading={actionRegistryProgress}
                        disabled={actionRegistryProgress}
                        submitBtnContent={registryId ? t("button.save") : t("button.add")}
                    />
                }
            >
                <NmForm addMargin>
                    <NmInputV2
                        size="xl"
                        onChange={this.handleChange}
                        name="name"
                        error={error.name}
                        value={name}
                        required
                        className="registry-frame-contract-edit__input"
                        placeholder={t("registries.name-placeholder")}
                        label={t("registries.name")}
                        maxLength={100}
                    />
                    <NmTextareaV2
                        label={t("registries.comment")}
                        placeholder="Оставьте комментарий"
                        name="commentary"
                        minRows={1}
                        maxRows={7}
                        onChange={this.handleChange}
                        value={commentary}
                        maxLength={255}
                    />
                    <Text.Sub
                        level="1"
                        medium={true}
                        className="mt-3 mb-2"
                    >
                        Договор
                    </Text.Sub>
                    {this.renderUnavailableTemplates()}
                    <div className="flex flex-column mb-2">
                        <NmRadioV2
                            label="НПД"
                            checked={contractType === ORDER_WORK_REPORT_TYPE.SMZ}
                            name="contractType"
                            value={ORDER_WORK_REPORT_TYPE.SMZ}
                            disabled={registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED}
                            onChange={this.handleChange}
                        />
                        <NmRadioV2
                            className="mt-2"
                            label="НДФЛ"
                            checked={contractType === ORDER_WORK_REPORT_TYPE.CIVIL}
                            disabled={registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED}
                            name="contractType"
                            value={ORDER_WORK_REPORT_TYPE.CIVIL}
                            onChange={this.handleChange}
                        />
                        {
                            individualRegistryPaymentsAvailable &&
                            <NmRadioV2
                                className="mt-2"
                                label="ИП"
                                checked={contractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                                disabled={registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED}
                                name="contractType"
                                value={ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                                onChange={this.handleChange}
                            />
                        }
                    </div>
                    {
                        customDocumentTemplatesAvailable &&
                        <>
                            <NmCheckboxV2
                                disabled={registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED}
                                label="Заключать договоры по заданному объекту"
                                tooltipText="При активации чек-бокса подписание договоров в рамках реестра будет производиться по выбранному объекту. Список доступных объектов содержит только те объекты, у которых в параметрах активирован признак заключения отдельного договора по объекту"
                                info
                                tooltipOutline={false}
                                checked={frameContractByObjectTemplate}
                                name="frameContractByObjectTemplate"
                                onChange={this.handleChange}
                            />
                            <NmDropdownV2
                                required={frameContractByObjectTemplate}
                                disabled={
                                    (registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED) ||
                                    !frameContractByObjectTemplate
                                }
                                search
                                placeholder="Выберите объект"
                                label="Объект"
                                name="objectId"
                                onChange={this.handleChange}
                                value={objectId}
                                options={objectOptions}
                                error={error.objectId}
                            />
                        </>
                    }
                    {
                        contractType !== ORDER_WORK_REPORT_TYPE.INDIVIDUAL &&
                        <NmCheckboxV2
                            disabled={(registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED) || signingOnlyWithSmzTaxOffer}
                            label="Разрешить подписывать РД до проверки персональных данных Администрацией Наймикс"
                            checked={signingBeforeCheckPersonalData}
                            name="signingBeforeCheckPersonalData"
                            onChange={this.handleChange}
                        />
                    }
                    {
                        contractType === ORDER_WORK_REPORT_TYPE.SMZ &&
                        <>
                            <NmCheckboxV2
                                disabled={(registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED) || signingOnlyWithSmzTaxOffer}
                                label="Разрешить подписывать РД при отсутствии статуса НПД и партнерства с Наймикс"
                                tooltipText="Исполнители смогут подписывать договоры при отсутствии статуса НПД и подключенного партнерства с Наймикс"
                                info
                                tooltipOutline={false}
                                checked={signingWithoutTaxpayerAndPartner}
                                name="signingWithoutTaxpayerAndPartner"
                                onChange={this.handleChange}
                            />
                            <NmCheckboxV2
                                disabled={(registryId && registryObj.status !== FC_REGISTRIS_STATUS_DICT.CREATED) || signingBeforeCheckPersonalData || signingWithoutTaxpayerAndPartner}
                                label="Разрешить подписывать РД при наличии подключенной автоуплаты налога"
                                tooltipText="Исполнители смогут подписывать договоры только при наличии подключенной автоматической уплаты налога"
                                info
                                tooltipOutline={false}
                                checked={signingOnlyWithSmzTaxOffer}
                                name="signingOnlyWithSmzTaxOffer"
                                onChange={this.handleChange}
                            />
                        </>
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        actionRegistryProgress: documentNotificationProgressUpdateSelector(state),
        frameContractTemplatesList: frameContractTemplatesListSelector(state),
        progressReplacementsForUnavailable: documentCustomTemplateProgressReplacementsForUnavailableSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
        objectOptions: clientObjectOptionsSelector(state),
    }),
    {
        addRegistryFrameContract,
        updateRegistryFrameContract,
        getDocumentCustomTemplateShortDefault,
        getDocumentsCustomTemplateReplacementsForUnavailableList,
        downloadDocument,
        updateDocumentCustomTemplateStore,
        getClientObjectList,
    },
)(withTranslation()(RegistryFrameContractEdit));