import React, {FC, ReactNode} from "react";

import NmButton, {TNmButtonColor} from "../NmButton";

import bem from "../../utils/bem";

import "./style.sass";

type TApplyButtonsSize = "md";
export type TApplyButtonsAlign = "left" | "center" | "right";
export type TApplyButtonsMobile = "row" | "column";

interface IApplyButtons {
    onClose?: (event?: any) => void,
    submitBtnContent?: string,
    submit?: (event?: any) => void,
    cancelBtnContent?: string,
    className?: string,
    loading?: boolean,
    disabled?: boolean,
    cancelBtnContentColor?: string,
    cancelBtnColor?: TNmButtonColor,
    formId?: string,
    size?: TApplyButtonsSize,
    align?: TApplyButtonsAlign,
    mobile?: TApplyButtonsMobile,
    isHiddenCancelOnMobile?: boolean,
    additionalBtnColor?: TNmButtonColor,
    additionalBtnContent?: string,
    additionalBtnDisabled?: boolean,
    onClickAdditional?: (event?: any) => void,
    isHiddenAdditionalOnMobile?: boolean,
    classNameMainButtons?: string,
    cancelBtnDisabled?: boolean,
    isSubmitCancelButton?: boolean
    cancelBtnIcon?: ReactNode,
    submitBtnIcon?: ReactNode,
}

const ApplyButtons: FC<IApplyButtons> = (props) => {
    const {
        submitBtnContent = "",
        cancelBtnContent = "",
        onClose,
        submit,
        className = "",
        classNameMainButtons = "",
        formId = "",
        loading,
        disabled,
        size = "xl",
        align = "right",
        mobile = "row",
        cancelBtnDisabled,
        cancelBtnColor = "white",
        additionalBtnColor = "grey",
        onClickAdditional,
        additionalBtnContent,
        additionalBtnDisabled,
        isSubmitCancelButton,
        isHiddenAdditionalOnMobile,
        isHiddenCancelOnMobile,
        cancelBtnIcon,
        submitBtnIcon,
    } = props;
    const [block, element] = bem("apply-buttons", className);

    return (
        <div className={block({align, mobile, spaced: Boolean(additionalBtnContent)})}>
            {
                additionalBtnContent &&
                <NmButton
                    size={size}
                    color={additionalBtnColor}
                    onClick={onClickAdditional}
                    loading={loading}
                    disabled={additionalBtnDisabled}
                    className={element("additional", {hiddenOnMobile: isHiddenAdditionalOnMobile})}
                >
                    {additionalBtnContent}
                </NmButton>
            }
            <div className={`${element("main-buttons", {mobile})} ${classNameMainButtons}`}>
                {
                    cancelBtnContent &&
                    <NmButton
                        size={size}
                        icon={cancelBtnIcon}
                        color={cancelBtnColor}
                        type={isSubmitCancelButton ? "submit" : "button"}
                        form={isSubmitCancelButton ? formId : undefined}
                        loading={isSubmitCancelButton ? loading : false}
                        disabled={isSubmitCancelButton ? disabled : cancelBtnDisabled}
                        onClick={onClose}
                        className={element("cancel", {hiddenOnMobile: isHiddenCancelOnMobile})}
                    >
                        {cancelBtnContent}
                    </NmButton>
                }
                {
                    submitBtnContent &&
                    <NmButton
                        size={size}
                        icon={submitBtnIcon}
                        onClick={submit}
                        type="submit"
                        className={element("submit")}
                        loading={loading}
                        form={formId && formId}
                        disabled={disabled}
                    >
                        {submitBtnContent}
                    </NmButton>
                }
            </div>
        </div>
    );
};

export default ApplyButtons;