import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import Tabs from "../../components/ActualComponents/Tabs";
import NmPage from "../../components/NmPage";
import NmTitle from "../../components/NmTitle";
import {SettingsRoute} from "./routes/setting-route";

import bem from "../../utils/bem";
import {ls, USER_ROLE} from "../../utils/localstorage";

import {
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_JOINING_REQUESTS,
    LINK_SETTINGS_KEYWORDS,
    LINK_SETTINGS_MEMBERS_LIST,
    LINK_SETTINGS_SERVICE_SETTINGS,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TKB_TRANSFERS,
    LINK_SETTINGS_USERS_ROLES_LIST,
    LinkNavigator,
} from "../../constants/links";
import {ADMIN, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_MANAGER, NM_OPERATOR} from "../../constants/roles";

import {
    clientUserCountSelector,
    getClientUserAndPartnerPage,
} from "../../ducks/clientMember";

import PropTypes from "prop-types";

import "./style.sass";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../constants/link-params";

class Settings extends Component {
    static propTypes = {
        pathname: PropTypes.string,
    };

    static defaultProps = {
        pathname: "",
    };

    getTabs() {
        const {
            pathname,
            t,
            specialityNonDecisionCount,
            userTotalCount,
        } = this.props;

        const role = ls(USER_ROLE);

        const fnsServiceTab = {
            name: "ФНС России сервис",
            link: LINK_SETTINGS_FNS_SERVICE,
            active: LINK_SETTINGS_FNS_SERVICE,
        };

        if (role === NM_OPERATOR) {
            return [
                fnsServiceTab,
                {
                    name: t("settings.directories"),
                    link: LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
                    active: LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
                },
            ];
        }

        const tabs = [
            {
                name: t("settings.support-messages"),
                link: LINK_SETTINGS_TECHSUPPORT_LIST,
                active: pathname.includes("settings/techsupport"),
            },
            {
                name: t("settings.directories"),
                link: LINK_SETTINGS_KEYWORDS,
                active: pathname.includes(LINK_SETTINGS_DIRECTORIES),
                count: [ADMIN, NM_MANAGER].includes(role) ? specialityNonDecisionCount : null,
            },
        ];

        if ([ADMIN].includes(role)) {
            tabs.push({
                name: "Ролевая модель",
                link: LINK_SETTINGS_USERS_ROLES_LIST,
                active: pathname.includes(LINK_SETTINGS_USERS_ROLES_LIST),
            });
        }

        tabs.push({
            name: t("settings.requisites"),
            link: LINK_SETTINGS_INFO,
            active: pathname.includes(LINK_SETTINGS_INFO),
        });

        tabs.push({
            name: t("settings.instruction"),
            link: LINK_SETTINGS_INSTRUCTION,
            active: [
                LINK_SETTINGS_INSTRUCTION,
                LINK_SETTINGS_INSTRUCTION_KEDO,
            ],
        });

        if ([ADMIN, NM_MANAGER].includes(role)) {
            tabs.push({
                name: t("settings.joining-requests"),
                link: LINK_SETTINGS_JOINING_REQUESTS,
                active: pathname.includes(LINK_SETTINGS_JOINING_REQUESTS),
            });
        }

        if (role === ADMIN) {
            // tabs.push({
            //     name: "Логирование",
            //     link: LINK_SETTINGS_EVENT_LOG,
            //     active: pathname.includes(LINK_SETTINGS_LOG)
            // });
            tabs.push({
                name: t("settings.staff"),
                link: LINK_SETTINGS_MEMBERS_LIST.replace(":pageNumber", 1).replace(":pageSize", 25).replace(":subpage", SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK),
                active: pathname.includes("settings/members-list/"),
                count: userTotalCount,
            });
        }

        if ([ADMIN, NM_MANAGER].includes(role)) {
            tabs.push(
                fnsServiceTab,
                {
                    name: "Исправление документов",
                    link: LinkNavigator.admin.settings.documentsEdit,
                    active: pathname.includes(LinkNavigator.admin.settings.documentsEdit),
                },
            );
        }

        if ([ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            tabs.push({
                name: "Группы компаний",
                link: LINK_SETTINGS_CLIENT_GROUP,
                active: pathname.includes(LINK_SETTINGS_CLIENT_GROUP),
            });
        }

        if ([ADMIN, NM_MANAGER].includes(role)) {
            tabs.push({
                name: "Сервис",
                link: LINK_SETTINGS_SERVICE_SETTINGS,
                active: [
                    LINK_SETTINGS_SERVICE_SETTINGS,
                    LINK_SETTINGS_TKB_TRANSFERS,
                    LINK_SETTINGS_SERVICE_TASKS_STATS,
                ],
            });
        }

        if ([ADMIN, NM_COORDINATOR].includes(role)) {
            tabs.push({
                name: "Машинное обучение",
                link: LinkNavigator.admin.settings.ml,
                active: pathname.includes(LinkNavigator.admin.settings.ml),
            });
        }

        if ([ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            tabs.push({
                name: "Сбор банковских реквизитов",
                link: LinkNavigator.admin.settings.collectionBankDetails,
                active: pathname.includes(LinkNavigator.admin.settings.collectionBankDetails),
            });
        }

        return tabs;
    }

    render() {
        const {t} = this.props;

        const [block] = bem("settings");

        return (
            <NmPage
                className={block()}
                header={
                    <NmTitle>
                        {t("settings.settings-title")}
                    </NmTitle>
                }
                subHeader={
                    <Tabs
                        panes={this.getTabs()}
                    />
                }
            >
                <SettingsRoute />
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        specialityNonDecisionCount: state.clientSpecialityOffer.nonDecisionCount,
        userTotalCount: clientUserCountSelector(state),
    }),
    {
        getClientUserAndPartnerPage,
    },
)(withTranslation()(Settings));
