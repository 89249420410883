import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getRichContractorById} from "./contractor";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "contractors/bankRequisites/collectionConditions";

const GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST = "GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST";
const GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS = "GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS";
const GET_PAGE_COLLECTION_BANK_DETAILS_ERROR = "GET_PAGE_COLLECTION_BANK_DETAILS_ERROR";

const ADD_COLLECTION_BANK_DETAILS_REQUEST = "ADD_COLLECTION_BANK_DETAILS_REQUEST";
const ADD_COLLECTION_BANK_DETAILS_SUCCESS = "ADD_COLLECTION_BANK_DETAILS_SUCCESS";
const ADD_COLLECTION_BANK_DETAILS_ERROR = "ADD_COLLECTION_BANK_DETAILS_ERROR";

const UPDATE_COLLECTION_BANK_DETAILS_REQUEST = "UPDATE_COLLECTION_BANK_DETAILS_REQUEST";
const UPDATE_COLLECTION_BANK_DETAILS_SUCCESS = "UPDATE_COLLECTION_BANK_DETAILS_SUCCESS";
const UPDATE_COLLECTION_BANK_DETAILS_ERROR = "UPDATE_COLLECTION_BANK_DETAILS_ERROR";

const DELETE_COLLECTION_BANK_DETAILS_REQUEST = "DELETE_COLLECTION_BANK_DETAILS_REQUEST";
const DELETE_COLLECTION_BANK_DETAILS_SUCCESS = "DELETE_COLLECTION_BANK_DETAILS_SUCCESS";
const DELETE_COLLECTION_BANK_DETAILS_ERROR = "DELETE_COLLECTION_BANK_DETAILS_ERROR";

const UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST = "UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST";
const UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS = "UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS";
const UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR = "UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR";

const UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_REQUEST = "UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_REQUEST";
const UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_SUCCESS = "UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_SUCCESS";
const UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_ERROR = "UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_ERROR";

const initial = {
    progress: null,
    errorMessage: "",
    progressGetPage: false,
    pageData: {},
    totalCount: null,
    list: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progressGetPage: true,
        };
    }
    case ADD_COLLECTION_BANK_DETAILS_REQUEST:
    case UPDATE_COLLECTION_BANK_DETAILS_REQUEST:
    case DELETE_COLLECTION_BANK_DETAILS_REQUEST:{
        return {
            ...state,
            progress: true,
        };
    }
    case GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS: {
        const {results, totalCount} = payload;

        return {
            ...state,
            list: results,
            totalCount,
            progressGetPage: false,
        };
    }
    case ADD_COLLECTION_BANK_DETAILS_SUCCESS:
    case UPDATE_COLLECTION_BANK_DETAILS_SUCCESS:
    case DELETE_COLLECTION_BANK_DETAILS_SUCCESS:{
        return {
            ...state,
            progress: false,
        };
    }
    case GET_PAGE_COLLECTION_BANK_DETAILS_ERROR: {
        return {
            ...state,
            progressGetPage: false,
            errorMessage: payload,
        };
    }
    case ADD_COLLECTION_BANK_DETAILS_ERROR:
    case UPDATE_COLLECTION_BANK_DETAILS_ERROR:
    case DELETE_COLLECTION_BANK_DETAILS_ERROR:{
        return {
            ...state,
            progress: false,
        };
    }
    default:
        return state;
    }
};

export function getPageCollectionBankDetails(payload) {
    return {
        type: GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function addCollectionBankDetails(payload) {
    return {
        type: ADD_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function updateCollectionBankDetails(payload) {
    return {
        type: UPDATE_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function deleteCollectionBankDetails(payload) {
    return {
        type: DELETE_COLLECTION_BANK_DETAILS_REQUEST,
        payload,
    };
}

export function updateContractorBankRequisitesRequired(payload) {
    return {
        type: UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_REQUEST,
        payload,
    };
}

export function updateContractorBankRequisites(payload) {
    return {
        type: UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST,
        payload,
    };
}

export const collectionBankDetailsSelector = state => state.collectionBankDetails;
export const collectionBankDetailsProgressSelector = createSelector(collectionBankDetailsSelector, ({progress}) => progress);
export const collectionBankDetailsTotalPagesSelector = createSelector(collectionBankDetailsSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const collectionBankDetailsTotalCountSelector = createSelector(collectionBankDetailsSelector, ({totalCount}) => totalCount);
export const collectionBankDetailsPageSelector = createSelector(collectionBankDetailsSelector, ({list}) => list);
export const collectionBankDetailsPageProgressSelector = createSelector(collectionBankDetailsSelector, ({progressGetPage}) => progressGetPage);

//POST /api/contractors/bankRequisites/collectionConditions/page/rich
export const getPageCollectionBankDetailsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/page/rich`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/bankRequisites/collectionConditions/add
export const addCollectionBankDetailsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Условие успешно добавлено");
        onSuccess();

        yield put({
            type: ADD_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/bankRequisites/collectionConditions/update
export const updateCollectionBankDetailsSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Условие успешно обновлено");
        onSuccess();

        yield put({
            type: UPDATE_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

//POST  /api/contractors/bankRequisites/collectionConditions/{id}/delete
export const deleteCollectionBankDetailsSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/${id}/delete`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_COLLECTION_BANK_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Условие успешно удалено");
        onSuccess();

        yield put({
            type: DELETE_COLLECTION_BANK_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_COLLECTION_BANK_DETAILS_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/bankRequisites/{contractorId}/required/{required}
export const updateContractorBankRequisitesRequiredSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            required,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`contractors/bankRequisites/${contractorId}/required/${required}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put(getRichContractorById(contractorId));

        yield put({
            type: UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/bankRequisites/{contractorId}/update
export const updateContractorBankRequisitesSaga = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.post(`contractors/bankRequisites/${contractorId}/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put(getRichContractorById(contractorId));

        yield put({
            type: UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(GET_PAGE_COLLECTION_BANK_DETAILS_REQUEST, getPageCollectionBankDetailsSaga),
        takeEvery(ADD_COLLECTION_BANK_DETAILS_REQUEST, addCollectionBankDetailsSaga),
        takeEvery(UPDATE_COLLECTION_BANK_DETAILS_REQUEST, updateCollectionBankDetailsSaga),
        takeEvery(DELETE_COLLECTION_BANK_DETAILS_REQUEST, deleteCollectionBankDetailsSaga),
        takeEvery(UPDATE_CONTRACTOR_BANK_REQUISITES_REQUIRED_REQUEST, updateContractorBankRequisitesRequiredSaga),
        takeEvery(UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST, updateContractorBankRequisitesSaga),
    ]);
}