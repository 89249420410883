import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    updateKedoDirectoriesStore,
} from "../../../../../ducks/kedo/directories/actionCreators";

import {getAdmTemplateDocumentTypesPage} from "../../../../../ducks/bff/adm/settings/directories/kedo/template-document-types/services";

export const useKedoTemplateDocumentTypesFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        sortType,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        sortType,
    ]);

    useEffect(() => {
        return () => {
            dispatch(updateKedoDirectoriesStore({
                templateDocumentTypes: {},
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getAdmTemplateDocumentTypesPage({
            ...filterData,
            orderType: sortType,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};