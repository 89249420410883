export const ADD_CLIENT_MODULE_TARIFFS_REQUEST = "ADD_CLIENT_MODULE_TARIFFS_REQUEST";
export const ADD_CLIENT_MODULE_TARIFFS_SUCCESS = "ADD_CLIENT_MODULE_TARIFFS_SUCCESS";
export const ADD_CLIENT_MODULE_TARIFFS_ERROR = "ADD_CLIENT_MODULE_TARIFFS_ERROR";

export const UPDATE_CLIENT_MODULE_TARIFFS_REQUEST = "UPDATE_CLIENT_MODULE_TARIFFS_REQUEST";
export const UPDATE_CLIENT_MODULE_TARIFFS_SUCCESS = "UPDATE_CLIENT_MODULE_TARIFFS_SUCCESS";
export const UPDATE_CLIENT_MODULE_TARIFFS_ERROR = "UPDATE_CLIENT_MODULE_TARIFFS_ERROR";

export const DELETE_CLIENT_MODULE_TARIFFS_REQUEST = "DELETE_CLIENT_MODULE_TARIFFS_REQUEST";
export const DELETE_CLIENT_MODULE_TARIFFS_SUCCESS = "DELETE_CLIENT_MODULE_TARIFFS_SUCCESS";
export const DELETE_CLIENT_MODULE_TARIFFS_ERROR = "DELETE_CLIENT_MODULE_TARIFFS_ERROR";

export const CLIENT_MODULE_TARIFFS_UPDATE_STORE = "CLIENT_SYSTEM_PROPERTIES_UPDATE_STORE";