import React, {useState} from "react";
import {isEmpty} from "lodash";

import FilterClientUser from "../../../../components/ActualComponents/FilterClientUser";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import {TICKET_STATUS_DICT} from "../../../../constants/crm/ticket";
import {ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";
import {requiredMessage} from "../../../../constants/validationRules";

import PropTypes from "prop-types";

// TODO: replace with real data
const statuses = [
    {
        key: TICKET_STATUS_DICT.RECALL.VALUE,
        text: TICKET_STATUS_DICT.RECALL.TEXT,
        value: TICKET_STATUS_DICT.RECALL.VALUE,
    },
    {
        key: TICKET_STATUS_DICT.IN_WORK.VALUE,
        text: TICKET_STATUS_DICT.IN_WORK.TEXT,
        value: TICKET_STATUS_DICT.IN_WORK.VALUE,
    },
];
const defaultForm = {
    managerId: "",
    status: "",
};

const TransferTicketModal = ({isOpened = true, onClose, onSubmit, loading = false}) => {
    const [form, setForm] = useState(defaultForm);
    const [errorForm, setErrorForm] = useState({});
    const {managerId, status} = form;

    const onChange = (_, {value, name}) => {
        setForm({
            ...form,
            [name]: value,
        });
        setErrorForm({
            ...errorForm,
            [name]: undefined,
        });
    };


    const validateForm = () => {
        let _errorForm = {};
        if (!managerId) {
            _errorForm = {
                ..._errorForm,
                managerId: requiredMessage,
            };
        }

        if (!status) {
            _errorForm = {
                ..._errorForm,
                status: requiredMessage,
            };
        }

        setErrorForm(_errorForm);
        return isEmpty(_errorForm);
    };

    const handleSubmit = () => {
        if (!validateForm()) {
            return;
        }

        onSubmit(form);
        setForm(defaultForm);
    };
    const handleClose = () => {
        setForm(defaultForm);
        setErrorForm({});
        onClose();
    };

    return (
        <NmModal
            size="md"
            isHiddenModal={!isOpened}
            onClose={handleClose}
            loading={loading}
            header={
                <NmTitle
                    size="lg"
                >
                    Перевод обращения
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submitBtnContent="Продолжить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                    onClose={handleClose}
                    disabled={loading}
                />
            }
        >

            <NmForm addMargin>
                <FilterClientUser
                    required
                    roleListFilter={[NM_OPERATOR, ADMIN, NM_MANAGER]}
                    label="ФИО оператора"
                    name="managerId"
                    placeholder="Выберите оператора"
                    value={managerId}
                    onChange={onChange}
                    error={errorForm.managerId}
                />

                <NmDropdownV2
                    required
                    label="Статус обращения"
                    name="status"
                    placeholder="Выберите статус"
                    options={statuses}
                    value={status}
                    onChange={onChange}
                    error={errorForm.status}
                />
            </NmForm>
        </NmModal>
    );
};

TransferTicketModal.propTypes = {
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default TransferTicketModal;
