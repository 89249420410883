import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../../hooks/useFilter";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../../../utils/dateFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";

import {bffAdditionalAgreementsRegistryStatusDictOptionsSelector} from "../../../../../../ducks/bff/documents/additional-agreements/registry/selectors";

export const useAdditionalAgreementsCardFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const statusDictOptions = useSelector(bffAdditionalAgreementsRegistryStatusDictOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        pageSize,
        setPagination,
        mapFilterDto: (filters) => {
            const {
                contractorNameFilter,
                contractorInnFilter,
                contractorPhoneFilter,
                relatedFrameContractNumFilter,
                relatedFrameContractSignedDateTimeFromFilter,
                relatedFrameContractSignedDateTimeToFilter,
                personalDataVersionDateTimeFromFilter,
                personalDataVersionDateTimeToFilter,
            } = filters;

            return {
                ...filters,
                contractorNameFilter: handleFormString(contractorNameFilter),
                contractorInnFilter: handleFormString(contractorInnFilter),
                contractorPhoneFilter: handleFormString(contractorPhoneFilter),
                relatedFrameContractNumFilter: handleFormString(relatedFrameContractNumFilter),
                relatedFrameContractSignedDateTimeFromFilter: getStartFilterDateWithTimeInUtc(relatedFrameContractSignedDateTimeFromFilter),
                relatedFrameContractSignedDateTimeToFilter: getEndFilterDateWithTimeInUtc(relatedFrameContractSignedDateTimeToFilter),
                personalDataVersionDateTimeFromFilter: getStartFilterDateWithTimeInUtc(personalDataVersionDateTimeFromFilter),
                personalDataVersionDateTimeToFilter: getEndFilterDateWithTimeInUtc(personalDataVersionDateTimeToFilter),
            };
        },
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО исполнителя",
                        name: "contractorNameFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorInnFilter",
                        label: "ИНН",
                        placeholder: "Введите ИНН",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "contractorPhoneFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: statusDictOptions,
                        label: "Статус",
                        placeholder: "Выберите статус",
                        name: "statusFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер рамочного договора",
                        placeholder: "Введите номер договора",
                        name: "relatedFrameContractNumFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        label: "Период подписания рамочного договора",
                        startFieldName: "relatedFrameContractSignedDateTimeFromFilter",
                        endFieldName: "relatedFrameContractSignedDateTimeToFilter",
                        isClearable: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        label: "Версия персональных данных",
                        startFieldName: "personalDataVersionDateTimeFromFilter",
                        endFieldName: "personalDataVersionDateTimeToFilter",
                        isClearable: true,
                    },
                ],
            },
        ];
    }, [
        statusDictOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};