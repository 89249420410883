import {
    BFF_CROWD_TASK_GROUPS_ADD_ERROR,
    BFF_CROWD_TASK_GROUPS_ADD_REQUEST,
    BFF_CROWD_TASK_GROUPS_ADD_SUCCESS,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_DELETE_ERROR,
    BFF_CROWD_TASK_GROUPS_DELETE_REQUEST,
    BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST,
    BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS,
    BFF_CROWD_TASK_GROUPS_UPDATE_ERROR,
    BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST,
    BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS,
    UPDATE_CROWD_TASK_GROUPS_FILED_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_CROWD_TASK_GROUPS_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_CROWD_TASK_GROUPS_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_CROWD_TASK_GROUPS_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_CROWD_TASK_GROUPS_ADD_REQUEST:
    case BFF_CROWD_TASK_GROUPS_UPDATE_REQUEST:
    case BFF_CROWD_TASK_GROUPS_DELETE_REQUEST:
    case BFF_CROWD_TASK_GROUPS_ARCHIVE_REQUEST:
    case BFF_CROWD_TASK_GROUPS_UNARCHIVE_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_CROWD_TASK_GROUPS_ADD_SUCCESS:
    case BFF_CROWD_TASK_GROUPS_ADD_ERROR:
    case BFF_CROWD_TASK_GROUPS_UPDATE_SUCCESS:
    case BFF_CROWD_TASK_GROUPS_UPDATE_ERROR:
    case BFF_CROWD_TASK_GROUPS_DELETE_SUCCESS:
    case BFF_CROWD_TASK_GROUPS_DELETE_ERROR:
    case BFF_CROWD_TASK_GROUPS_ARCHIVE_SUCCESS:
    case BFF_CROWD_TASK_GROUPS_ARCHIVE_ERROR:
    case BFF_CROWD_TASK_GROUPS_UNARCHIVE_SUCCESS:
    case BFF_CROWD_TASK_GROUPS_UNARCHIVE_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case UPDATE_CROWD_TASK_GROUPS_FILED_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};