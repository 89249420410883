import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFinanceBankFilter} from "../utils/dto";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {getPageBankTkbHistory} from "../../../../ducks/bff/bank/tkb/actionCreators";
import {
    bffBankTkbDataSelector,
    bffBankTkbListSelector,
    bffBankTkbProgressSelector,
    bffBankTkbTotalCountSelector,
    bffBankTkbTotalPagesSelector,
} from "../../../../ducks/bff/bank/tkb/selectors";
import {getPageBankW1History} from "../../../../ducks/bff/bank/w1/actionCreators";
import {
    bffBankW1DataSelector,
    bffBankW1ListSelector, bffBankW1ProgressSelector,
    bffBankW1TotalCountSelector,
    bffBankW1TotalPagesSelector,
} from "../../../../ducks/bff/bank/w1/selectors";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";

export const useFinanceBankFetch = ({filterData, pageNum, pageSize, sortType, isTkbPage}) => {
    const w1TotalPages = useSelector(bffBankW1TotalPagesSelector);
    const w1List = useSelector(bffBankW1ListSelector);
    const w1Data = useSelector(bffBankW1DataSelector);
    const w1TotalCount = useSelector(bffBankW1TotalCountSelector);
    const w1Progress = useSelector(bffBankW1ProgressSelector);

    const tkbTotalPages = useSelector(bffBankTkbTotalPagesSelector);
    const tkbList = useSelector(bffBankTkbListSelector);
    const tkbData = useSelector(bffBankTkbDataSelector);
    const tkbTotalCount = useSelector(bffBankTkbTotalCountSelector);
    const tkbProgress = useSelector(bffBankTkbProgressSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [CLIENT_USER_RESTRICTIONS_VARIABLE.accessBank],
        }));
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        sortType,
        isTkbPage,
    ]);

    const fetchList = () => {
        const filter = handleFinanceBankFilter(filterData);

        const reqData = {
            orderType: sortType,
            orderName: isTkbPage ? "operationDate" : "transactionDate",
            pageNum,
            pageSize,
            ...filter,
        };

        if (isTkbPage) {
            dispatch(getPageBankTkbHistory(reqData));

            return;
        }

        dispatch(getPageBankW1History(reqData));
    };

    const getDataBySubPage = () => {
        if (isTkbPage) {
            return {
                list: tkbList,
                data: tkbData,
                totalCount: tkbTotalCount,
                totalPages: tkbTotalPages,
                progress: tkbProgress,
            };
        }

        return {
            list: w1List,
            data: w1Data,
            totalCount: w1TotalCount,
            totalPages: w1TotalPages,
            progress: w1Progress,
        };
    };

    return {
        fetchList,
        ...getDataBySubPage(),
    };
};