export const FULL_REGISTRATION_COMPONENT_TYPE = {
    DROPZONE: "DROPZONE",
    DATE: "DATE",
    INPUT: "INPUT",
    DADATA_INPUT: "DADATA_INPUT",
    WARNING_TEXT: "WARNING_TEXT",
    DROPDOWN: "DROPDOWN",
    CHECKBOX: "TOGGLE",
    LINK: "LINK",
};

export const FULL_REGISTRATION_STEP = {
    INN: "INN",
    CITIZENSHIP_AND_MIGRATION_STATUS: "CITIZENSHIP_AND_MIGRATION_STATUS",
    PERSONAL_DATA: "PERSONAL_DATA",
    SELFIE_WITH_PASSPORT: "SELFIE_WITH_PASSPORT",
    RESIDENCE: "RESIDENCE",
    RESIDENCE_PLACE_NOT_RESIDENT: "RESIDENCE_PLACE_NOT_RESIDENT",
    MIGRATION_CARD: "MIGRATION_CARD",
    TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA: "TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA",
    VHI_POLICY_SCAN: "VHI_POLICY_SCAN",
    PASSPORT_TRANSLATION: "PASSPORT_TRANSLATION",
    VISA_PERSONAL_DATA_SPREAD: "VISA_PERSONAL_DATA_SPREAD",
    UNIVERSITY_LICENSE: "UNIVERSITY_LICENSE",
    STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA: "STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA",
    EMPLOYMENT_PATENT: "EMPLOYMENT_PATENT",
    SELFIE_MAIN_DOCUMENT: "SELFIE_MAIN_DOCUMENT",
    RESIDENCE_PERMIT: "RESIDENCE_PERMIT",
    PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR: "PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR",
};

export const FULL_REGISTRATION_COMPONENT_DEFAULT_PROPS = {
    [FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE]: {
        isVisibleLabel: true,
        size: "xl",
        accept: ".bmp, .png, .jpeg, .jpg",
        format: "*.bmp, *.png, *.jpeg, *.jpg",
        required: true,
        isShowDeleteButton: false,
        maxSize: 10,
    },
    [FULL_REGISTRATION_COMPONENT_TYPE.INPUT]: {
        size: "xl",
    },
    [FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX]: {
        toggle: true,
    },
    [FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN]: {
        size: "xl",
    },
    [FULL_REGISTRATION_COMPONENT_TYPE.DATE]: {
        dateFormat: "dd.MM.yyyy",
        dateFormatMask: "99.99.9999",
    },
};

export const FULL_REGISTRATION_GENDER_DICT = {
    M: "M",
    W: "W",
};

export const FULL_REGISTRATION_GENDER_DICT_OPTIONS = [
    {
        key: FULL_REGISTRATION_GENDER_DICT.M,
        text: "Мужской",
        value: FULL_REGISTRATION_GENDER_DICT.M,
    },
    {
        key: FULL_REGISTRATION_GENDER_DICT.W,
        text: "Женский",
        value: FULL_REGISTRATION_GENDER_DICT.W,
    },
];

export const FULL_REGISTRATION_UPDATE_CONTRACTOR_ERROR = {
    PASSED_PERSONAL_DATA_IS_INVALID: "PASSED_PERSONAL_DATA_IS_INVALID",
    PENDING_PERSONAL_DATA_DRAFT_CANT_BE_COMPLETED: "PENDING_PERSONAL_DATA_DRAFT_CANT_BE_COMPLETED",
};