import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {getFullName} from "../../../../utils/stringFormat";

/***
 * Получить информацию о юзере, проверяющем ПД исполнителя, используется для отображения информации
 * в карточке исполнителя + тосте с ошибкой в ответах методов
 * @param data
 * @returns {`${string} с ${*}`}
 */
export const getContractorCheckOnInfo = (data) => {
    const {
        checkPerformingByClientUserLastName,
        checkPerformingByClientUserFirstName,
        checkPerformingByClientUserPatronymic,
        checkStartedAtDateTime,
    } = data;

    const fio = getFullName(
        checkPerformingByClientUserLastName,
        checkPerformingByClientUserFirstName,
        checkPerformingByClientUserPatronymic,
    );
    const date = dateFormat(convertUtcToLocal(checkStartedAtDateTime), "HH:mm");

    return `${fio} с ${date}`;
};