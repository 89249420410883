
import dateFormat, {convertLocalTimeToUtcTime, getTimeFromHoursAndMinutes} from "../../../../utils/dateFormat";
import {roundNumberWithPoint} from "../../../../utils/mathHelper";
import {getNumberFromFormattedAmount} from "../../../../utils/stringFormat";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {ORDER_KIND} from "../../../../constants/order";

const getKeywordsData = (creationStatus, keywords) => {
    return creationStatus === ORDER_STATUS.DRAFT ?
        keywords.map(({content: keyword}) => ({keyword})) :
        keywords.map(({content: keyword}) => ({
            keyword,
            publicAvailable: true,
        }));
};

export const getOrderEditRequestDataAmount = ({formData: {amount}, calculatorData, isSecondType}) => {
    return isSecondType ?
        +roundNumberWithPoint(getNumberFromFormattedAmount(calculatorData.amount)) :
        +roundNumberWithPoint(getNumberFromFormattedAmount(amount));
};

export const getOrderEditRequestDataContractorsNeededCount = (params) => {
    const {
        isSecondType,
        formData: {
            contractorsNeededCountSecondType,
            contractorsNeededCount,
        },
    } = params;

    return isSecondType ? +contractorsNeededCountSecondType : +contractorsNeededCount;
};

export const getOrderEditRequestData = (params) => {
    const {
        order,
        isDuplicateOrder,
        creationStatus,
        formData,
        isSecondType,
        calculatorData,
        offeredSpecialityName,
        editOrderId,
        clientId,
        orderGroupId,
        clientPropertiesCard,
    } = params;
    const {
        workStartDate,
        workEndDate,
        keywords,
        volumeOfWork,
        workUnit,
        workUnitPrice,
        orderId,
    } = formData;
    const {
        checkOutTimePeriod,
        checkInTimePeriod,
        isTemplateType,
        templateId,
        // не используем промежуточные поля для отправки
        outTimeHours,
        outTimeMinutes,
        timeForExecutionHours,
        timeForExecutionMinutes,
        ...reqFormData
    } = formData;

    const isDraft = creationStatus === ORDER_STATUS.DRAFT || Boolean(offeredSpecialityName);
    const status = isDraft ? ORDER_STATUS.DRAFT : creationStatus;
    const isCheckOutTimePeriod = formData.checkOutRequired && formData.isRequiredCheckOutPeriod;
    const isCheckInTimePeriod = formData.checkInRequired && formData.isRequiredCheckInPeriod;

    return {
        ...reqFormData,
        clientId,
        orderGroupId,
        orderCategoryId: isDuplicateOrder || order.status === ORDER_STATUS.DRAFT ? null : formData.orderCategoryId,
        orderCategoryName: isDuplicateOrder || order.status === ORDER_STATUS.DRAFT ? null : formData.orderCategoryName,
        orderSubcategoryId: isDuplicateOrder || order.status === ORDER_STATUS.DRAFT ? null : formData.orderSubcategoryId,
        orderSubcategoryName: isDuplicateOrder || order.status === ORDER_STATUS.DRAFT ? null : formData.orderSubcategoryName,
        specialityId: !offeredSpecialityName ? formData.specialityId : undefined,
        offeredSpecialityName: offeredSpecialityName ? offeredSpecialityName : undefined,
        orderId: editOrderId && !isDuplicateOrder ? orderId : undefined,
        insurance: isDuplicateOrder && !clientPropertiesCard.insurance ? false : formData.insurance,
        amount: getOrderEditRequestDataAmount({
            isSecondType,
            calculatorData,
            formData,
        }),
        workStartDate: dateFormat(workStartDate, "yyyy-MM-dd"),
        workEndDate: dateFormat(workEndDate, "yyyy-MM-dd"),
        contractorsNeededCount: getOrderEditRequestDataContractorsNeededCount({
            isSecondType,
            formData,
        }),
        contractorsFound: isDuplicateOrder ? false : formData.contractorsFound,
        keywords: getKeywordsData(creationStatus, keywords),
        status,
        orderManagerPhoneVisible: Boolean(formData.orderManagerPhoneVisible),
        volumeOfWork: status === ORDER_STATUS.DRAFT || isSecondType ? getNumberFromFormattedAmount(volumeOfWork) : undefined,
        workUnit: status === ORDER_STATUS.DRAFT || isSecondType ? workUnit : undefined,
        workUnitPrice: status === ORDER_STATUS.DRAFT || isSecondType ? getNumberFromFormattedAmount(workUnitPrice) : undefined,
        orderKind: isSecondType ? ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER : ORDER_KIND.AMOUNT_BASED_ORDER,
        checkOutFrom: isCheckOutTimePeriod && checkOutTimePeriod.from ? convertLocalTimeToUtcTime(checkOutTimePeriod.from) : null,
        checkOutTo: isCheckOutTimePeriod && checkOutTimePeriod.to ? convertLocalTimeToUtcTime(checkOutTimePeriod.to) : null,
        checkInFrom: isCheckInTimePeriod && checkInTimePeriod.from ? convertLocalTimeToUtcTime(checkInTimePeriod.from) : null,
        checkInTo: isCheckInTimePeriod && checkInTimePeriod.to ? convertLocalTimeToUtcTime(checkInTimePeriod.to) : null,
        outTime: getTimeFromHoursAndMinutes({
            timeMinutes: formData.outTimeMinutes,
            timeHours: formData.outTimeHours,
        }),
        timeForExecutionHours: getTimeFromHoursAndMinutes({
            timeMinutes: formData.timeForExecutionMinutes,
            timeHours: formData.timeForExecutionHours,
        }),
    };
};