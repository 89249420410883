import {EDM_STATEMENT_PARTICIPANTS_TYPE} from "./statements";

export const EDO_ACCESS_RIGHTS = {
    /**
     * Отображение раздела "ЭДО" компании
     */
    EDM_GENERAL: "EDM_GENERAL",

    /**
     * Отображение подраздела "Документы" со списком документов компании
     */
    EDM_DOCUMENTS_SHOW: "EDM_DOCUMENTS_SHOW",

    /**
     * Подача документов
     */
    EDM_DOCUMENTS_ADD: "EDM_DOCUMENTS_ADD",

    /**
     * Редактирование документов
     */
    EDM_DOCUMENTS_EDIT: "EDM_DOCUMENTS_EDIT",

    /**
     * Архивирование документов
     */
    EDM_DOCUMENTS_ARCHIVE: "EDM_DOCUMENTS_ARCHIVE",

    /**
     * Восстановление документов из архива
     */
    EDM_DOCUMENTS_RESTORE_ARCHIVE: "EDM_DOCUMENTS_RESTORE_ARCHIVE",

    /**
     * Отображение подраздела "Заявления" со списком заявлений компании
     */
    EDM_STATEMENTS_SHOW: "EDM_STATEMENTS_SHOW",

    /**
     * Подача заявлений
     */
    EDM_STATEMENTS_ADD: "EDM_STATEMENTS_ADD",

    /**
     * Редактирование заявлений
     */
    EDM_STATEMENTS_EDIT: "EDM_STATEMENTS_EDIT",

    /**
     * Рассмотрение заявлений для установки согласующего и получателя (со стороны HR)
     */
    EDM_STATEMENTS_RECIPIENT_APPROVER_INSTALLATION: "EDM_STATEMENTS_RECIPIENT_APPROVER_INSTALLATION",

    /**
     * Архивирование заявлений
     */
    EDM_STATEMENTS_ARCHIVE: "EDM_STATEMENTS_ARCHIVE",

    /**
     * Восстановление заявлений из архива
     */
    EDM_STATEMENTS_RESTORE_ARCHIVE: "EDM_STATEMENTS_RESTORE_ARCHIVE",


    /**
     * Отображение подраздела "Кадры" со списком кадров (исполнителей) компании
     */
    EDM_STAFF_SHOW: "EDM_STAFF_SHOW",

    /**
     * Отображение персональных данных сотрудника
     */
    EDM_STAFF_SHOW_PERSONAL_DATA: "EDM_STAFF_SHOW_PERSONAL_DATA",

    /**
     * Отображение списка документов и заявлений на карточке сотрудника
     */
    EDM_STAFF_SHOW_DOCUMENTS_STATEMENTS: "EDM_STAFF_SHOW_DOCUMENTS_STATEMENTS",

    /**
     * Добавление кадров
     */
    EDM_STAFF_ADD: "EDM_STAFF_ADD",

    /**
     * Редактирование сотрудников
     */
    EDM_STAFF_EDIT: "EDM_STAFF_EDIT",

    /**
     * Архивирование кадров
     */
    EDM_STAFF_ARCHIVE: "EDM_STAFF_ARCHIVE",

    /**
     * Разархивирование кадров
     */
    EDM_STAFF_RESTORE_ARCHIVE: "EDM_STAFF_RESTORE_ARCHIVE",

    /**
     * Отображение подраздела "Справочники"
     */
    EDM_DICTIONARY_SHOW: "EDM_DICTIONARY_SHOW",

    /**
     * Управление справочником должностей компании (добавление, редактирование, удаление)
     */
    EDM_DICTIONARY_MANAGE: "EDM_DICTIONARY_MANAGE",
    /**
     * Подписание документов как отправляющий
     */
    EDM_DOCUMENTS_SIGN_SENDER: "EDM_DOCUMENTS_SIGN_SENDER",
    /**
     * Подписание документов как принимающий
     */
    EDM_DOCUMENTS_SIGN_RECEIVER: "EDM_DOCUMENTS_SIGN_RECEIVER",
    /**
     * Импорт исполнителей в кадры ЭДО из файла
     */
    EDM_STAFF_IMPORT: "EDM_STAFF_IMPORT",
    /**
     * Выгрузка "Кадры ЭДО"
     */
    EDO_REPORT_VIEW: "EDO_REPORT_VIEW",
    /**
     * Отображение раздела "Реестры документов"
     */
    EDM_REGISTRY_SHOW: "EDM_REGISTRY_SHOW",
    /**
     * Управление реестрами документов ЭДО
     */
    EDM_REGISTRY_MANAGE: "EDM_REGISTRY_MANAGE",
    /**
     * Передача на подписание, подписание реестров ЭДО
     */
    EDM_REGISTRY_SIGN: "EDM_REGISTRY_SIGN",
};

export const EDO_DOCUMENT_FIELD = {
    RECEIVERS_IDS: "receiverIds",
    RECEIVER_ID: "receiverId",
    DESCRIPTION: "description",
    DOCUMENT_NAME: "documentName",
    SENDER_ID: "senderId",
    DOCUMENT_DATE_TIME: "documentDateTime",
    TYPE: "type",
    FILE: "file",
    DOCUMENT_ID: "documentId",
    PARAMS: {
        DOCUMENT_NUMBER: "params.documentNumber",
        TYPE: "params.type",
        PERSONAL_DATA: "params.personalData",
        TARGETS: "params.targets",
        DOCUMENT_SUBJECT: "params.documentSubject",
        DOCUMENT_PLACE: "params.documentPlace",
        START_WORK_DATE: "params.startWorkDate",
        PERIOD_FROM: "params.periodFrom",
        PERIOD_TO: "params.periodTo",
        END_WORK_DATE: "params.endWorkDate",
        EMAIL: "params.email",
        PAYMENT_CURRENCY: "params.paymentCurrency",
        PLATFORM_NAME: "params.platformName",
        CONTRACT_NUMBER: "params.contractNumber",
        CONTRACT_DATE: "params.contractDate",
        ADDITIONAL_CONTRACTORS_DETAIL: "params.additionalContractorsDetail",
    },
};

export const EDO_DOCUMENT_STATUS = {
    ON_SIGNING: {
        VALUE: "ON_SIGNING",
        MOD: "orange",
        TEXT: "На подписании",
    },
    SIGNED: {
        VALUE: "SIGNED",
        MOD: "green",
        TEXT: "Подписан",
    },
    REJECTED: {
        VALUE: "REJECTED",
        MOD: "red",
        TEXT: "Отклонен",
    },
};

export const EDO_RECEIVER_STATUS = {
    IN_LINE_FOR_SIGNING: {
        VALUE: "IN_LINE_FOR_SIGNING",
        MOD: "in-progress",
        TEXT: "В очереди получателя",
    },
    ON_SIGNED: {
        VALUE: "ON_SIGNED",
        MOD: "on-signing",
        TEXT: "Получатель подписывает",
    },
    SIGNED: {
        VALUE: "SIGNED",
        MOD: "signed",
        TEXT: "Получатель подписал",
    },
    REJECTED: {
        VALUE: "REJECTED",
        MOD: "rejected",
        TEXT: "Получатель отклонил",
    },
};

export const EDO_SENDER_STATUS = {
    IN_LINE_FOR_SIGNING: {
        VALUE: "IN_LINE_FOR_SIGNING",
        MOD: "in-progress",
        TEXT: "В очереди отправителя",
    },
    ON_SIGNED: {
        VALUE: "ON_SIGNED",
        MOD: "on-signing",
        TEXT: "Отправитель подписывает",
    },
    SIGNED: {
        VALUE: "SIGNED",
        MOD: "signed",
        TEXT: "Отправитель подписал",
    },
    REJECTED: {
        VALUE: "REJECTED",
        MOD: "rejected",
        TEXT: "Отправитель отклонил",
    },
    ON_SIGNING: {
        TEXT: `${EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.TEXT} подписывает`,
        VALUE: "ON_SIGNING",
        MOD: "orange",
    },
};
export const EDO_DOCUMENT_ACTION = {
    IN_ARCHIVE: {
        VALUE: "IN_ARCHIVE",
        TEXT: "В архив",
    },
    FROM_ARCHIVE: {
        VALUE: "FROM_ARCHIVE",
        TEXT: "Из архива",
    },
    EDIT: {
        VALUE: "EDIT",
        TEXT: "Редактировать",
    },
};

export const TEMPLATE_DOCUMENT = {
    CIVIL_DEED: "EDM_DOCUMENT_TEMPLATE_2021_000001", // Договор гражданско-правового характера (НДФЛ)
    TRADE_SECRET_NONDISCLOSURE_OBLIGATION: "EDM_DOCUMENT_TEMPLATE_2021_000002", // Обязательство о неразглашении коммерческой тайны
    CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA: "EDM_DOCUMENT_TEMPLATE_2021_000003", // Согласие на обработку персональных данных
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT: "EDM_DOCUMENT_TEMPLATE_2021_000008", // Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ)
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2: "EDM_DOCUMENT_TEMPLATE_2021_000009", // Дополнительное соглашение к рамочному договору об оказании возмездных услуг №2
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5: "EDM_DOCUMENT_TEMPLATE_2021_000011", //Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №5
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6: "EDM_DOCUMENT_TEMPLATE_2021_000016",//Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №6
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7: "EDM_DOCUMENT_TEMPLATE_2021_000018",//Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №7
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8: "EDM_DOCUMENT_TEMPLATE_2021_000017",//Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №8
    CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY: "EDM_DOCUMENT_TEMPLATE_2021_000010", // Договор гражданско-правового характера с условиями передачи интеллектуальных прав
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3: "EDM_DOCUMENT_TEMPLATE_2021_000013", // Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №3
    ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4: "EDM_DOCUMENT_TEMPLATE_2021_000012", // Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №4 (для ООО "Ипсос Комкон")
    ATTACHMENT_CIVIL_DEED: "EDM_DOCUMENT_TEMPLATE_2021_000014", //приложение к договору гражданско-правового характера
    ACT_CIVIL_DEED: "EDM_DOCUMENT_TEMPLATE_2021_000015", //Акт к договору гражданско-правового характера (для ООО "Гикбрейнс")
    REGISTRATION_LOG_PRIMARY_INSTRUCTION: "EDM_DOCUMENT_TEMPLATE_2021_000007", //Журнал регистрации первичного инструктажа,
    REGISTRATION_LOG_INTRODUCTORY_INSTRUCTION: "EDM_DOCUMENT_TEMPLATE_2021_000019", //Журнал регистрации вводного инстурктажа
};

export const UPLOAD_EDO_STEP = {
    FIRST: [0],
    SECOND: [0, 1],
};

export const PERSONAL_DATA_OPTIONS = [
    "ФИО",
    "Дата рождения",
    "Место рождения",
    "Пол",
    "Гражданство",
    "Семейное положение",
    "Паспортные данные",
    "Стаж работы",
    "Сведения о воинском учете",
    "Сведения о начисленных страховых взносах на обязательное пенсионное страхование",
    "Размер дохода по должности",
    "Данные свидетельств о регистрации брака и рождении детей",
    "ИНН",
    "СНИЛС",
];

export const TYPE_LOADING_EDO_DOCUMENT = {
    TEMPLATE: "template",
    COMPLETED_DOCUMENT: "completed-document",
};

export const personalDataOptions = () => {
    return  PERSONAL_DATA_OPTIONS.map(item => ({
        key: item,
        value: item,
        text: item,
    }));
};

export const EDM_DOCUMENT_LIST_HEADER_ACTIONS = {
    EXPORT: {
        VALUE: "export",
        TEXT: "Выгрузить",
    },
};
