export const CLIENTS_APPLICATION_STATUS = {
    INIT: {
        value: "INIT",
        text: "Заявка отправлена",
        mod: "gray",
    },
    PROCESSING: {
        value: "PROCESSING",
        text: "Обрабатывается",
        mod: "gray",
    },
    FINISHED: {
        value: "FINISHED",
        text: "Подключено",
        mod: "green",
    },
    DISMISSED: {
        value: "DISMISSED",
        text: "Отклонено",
        mod: "red",
    },
};

export const CLIENTS_APPLICATION_STATUS_OPTIONS = [
    {
        key: CLIENTS_APPLICATION_STATUS.INIT.value,
        text: CLIENTS_APPLICATION_STATUS.INIT.text,
        value: CLIENTS_APPLICATION_STATUS.INIT.value,
    },
    {
        key: CLIENTS_APPLICATION_STATUS.PROCESSING.value,
        text: CLIENTS_APPLICATION_STATUS.PROCESSING.text,
        value: CLIENTS_APPLICATION_STATUS.PROCESSING.value,
    },
    {
        key: CLIENTS_APPLICATION_STATUS.FINISHED.value,
        text: CLIENTS_APPLICATION_STATUS.FINISHED.text,
        value: CLIENTS_APPLICATION_STATUS.FINISHED.value,
    },
    {
        key: CLIENTS_APPLICATION_STATUS.DISMISSED.value,
        text: CLIENTS_APPLICATION_STATUS.DISMISSED.text,
        value: CLIENTS_APPLICATION_STATUS.DISMISSED.value,
    },
];