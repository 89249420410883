import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import copy from "copy-to-clipboard";

import Tabs from "../../../components/ActualComponents/Tabs";
import ButtonBack from "../../../components/ButtonBack";
import ContractorHeader from "../../../components/ContractorHeader";
import ContractorHeaderNoteForClient from "../../../components/ContractorHeaderNoteForClient";
import ContractorNoteForClientEditModal from "../../../components/ContractorNoteForClientEditModal";
import NmLoader from "../../../components/NmLoader";
import NmPage from "../../../components/NmPage";
import ContractorGeneralInfo from "../contractor-general-info";
import {ContractorRoutes} from "../contractor-routes";

import {formatLocalDate} from "../../../utils/dateFormat";
import {getHistoryBackLink} from "../../../utils/historyNavigationhelper";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {phoneFormat} from "../../../utils/stringFormat";
import {toastSuccess} from "../../../utils/toastHelper";

import {
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CONTRACTOR_BANKS_LIST,
    LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST,
    LINK_CONTRACTOR_CRM_TICKET_LIST_IN_WORK,
    LINK_CONTRACTOR_DEVICE_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CRM_TICKET,
} from "../../../constants/links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN, GR,
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
    RNKO,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    getClientPropertiesById,
    getClientPropertiesCardSelector,
} from "../../../ducks/clientProperties";
import {
    contractorCardSelector,
    contractorStatusDictSelector,
    getContractorStatusDict,
    getNote,
    getRichContractorById,
    updateFieldContractorStore,
} from "../../../ducks/contractor";
import {
    getTicketCounts,
    ticketCountsSelector,
} from "../../../ducks/crm/ticket";
import {
    getRatingsByContractorId,
    ratingsContractorSelector,
} from "../../../ducks/ratings";

import {contractorType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

const getPhoneWithoutCode = (phone) => {
    const [phoneWithoutCode] = phone.split("(").reverse();
    return `(${phoneWithoutCode}`;
};

class ContractorCard extends Component {
    static propTypes = {
        contractor: contractorType,
        getRichContractorById: PropTypes.func,
        updateFieldContractorStore: PropTypes.func,
        listPageNum: PropTypes.number,
    };

    static defaultProps = {
        contractor: {},
        getRichContractorById: () => {
        },
        updateFieldContractorStore: () => {
        },
    };

    constructor(props) {
        super(props);
        const {
            match: {
                params: {contractorId},
            },
        } = props;

        this.state = {
            historyData: "",
            isLoaded: false,
            isOpenContractorNoteForClientEditModal: false,
        };
        this.contractorId = contractorId;
        this.role = ls(USER_ROLE);
        this.isCustomer = [
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            FOREMAN,
            HR_MANAGER,
            GR,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
        ].includes(this.role);
        this.isNmEmployees = [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            NM_OPERATOR,
        ].includes(this.role);
        this.isVisibleContractorTickets = [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_OPERATOR,
        ].includes(this.role);
    }

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, pageName, filterData, extData} = state;

            this.setState({
                historyData: {prevPath, pageData, pageName, filterData, extData},
            });
        }
    };

    componentDidMount() {
        const {
            getRichContractorById,
            getRatingsByContractorId,
            getNote,
            clientId,
            getContractorStatusDict,
            getClientPropertiesById,
            getTicketCounts,
        } = this.props;

        this.saveHistoryData();

        getRichContractorById(this.contractorId);
        getRatingsByContractorId(this.contractorId);
        getContractorStatusDict();
        getClientPropertiesById({clientId});

        if (this.isVisibleContractorTickets) {
            getTicketCounts({
                contractorIdFilter: this.contractorId,
            });
        }

        if (
            [
                CLIENT_ADMIN,
                PROJECT_MANAGER,
                OBJECT_MANAGER,
                FOREMAN,
                CLIENT_ACCOUNTANT,
                ADMIN,
                NM_MANAGER,
                NM_OPERATOR,
                NM_COORDINATOR,
                NM_CHIEF_ACCOUNTANT,
            ].includes(this.role)) {
            getNote({clientId, contractorId: this.contractorId});
        }
    }

    componentWillUnmount() {
        this.props.updateFieldContractorStore({card: {}});
    }

    handleOnClickBack = () => {
        const {backLink, location, pathname} = this.props;
        const {historyData} = this.state;
        const {prevPath} = historyData;

        if (historyData && prevPath !== pathname) {
            const {prevPath} = historyData;

            this.setState({
                historyData: "",
            });

            if (prevPath) {
                history.push(prevPath, historyData);

                return;
            }
        }

        const historyBackLink = getHistoryBackLink(location);

        if (historyBackLink && historyBackLink !== pathname) {
            history.push(historyBackLink);
            return;
        }

        if (backLink) {
            history.push(backLink);

            return;
        }

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT, FOREMAN].includes(this.role)) {
            const {clientId} = this.props;

            history.push(LINK_CLIENT_CONTRACTORS_LIST.replace(":clientId", clientId).replace(":type", "all"));

            return;
        }

        history.push(LINK_CONTRACTOR_LIST);
    };

    handleCopyContact = (value, type) => {
        const isPhone = type === "phone";
        const toastText = `Скопировано ${isPhone ? getPhoneWithoutCode(value) : value}`;

        copy(isPhone ? getPhoneWithoutCode(value) : value);
        toastSuccess(toastText);
    };

    toggleOpenOpenContractorNoteForClientEditModal = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenContractorNoteForClientEditModal: !prevState.isOpenContractorNoteForClientEditModal,
        }));
    };

    getTabs() {
        const {
            pathname,
            t,
            contractor: {
                citizenship,
            },
            clientProperties: {
                personalDataAccessGranted,
                canViewContractorEventLogClientAdmin,
            },
            ticketCounts,
        } = this.props;

        const linkInfo = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", this.contractorId);
        const linkToDocumentList = LINK_CONTRACTOR_DOCUMENT_LIST.replace(":contractorId", this.contractorId);
        const linkToDeviceList = LINK_CONTRACTOR_DEVICE_LIST.replace(":contractorId", this.contractorId);
        const linkToOrderList = LINK_CONTRACTOR_ORDER_LIST.replace(":contractorId", this.contractorId);
        const linkToTasksList = LINK_CONTRACTOR_TASK_LIST.replace(":contractorId", this.contractorId);
        const linkToFinanceList = LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST.replace(":contractorId", this.contractorId);
        const linkToEventLog = LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY.replace(":contractorId", this.contractorId);
        const linkToProfile = LINK_CONTRACTOR_PROFILE.replace(":contractorId", this.contractorId);
        const linkToReviewsList = LINK_CONTRACTOR_REVIEWS_LIST.replace(":contractorId", this.contractorId);
        const linkToTickets = LINK_CONTRACTOR_CRM_TICKET_LIST_IN_WORK.replace(":contractorId", this.contractorId);
        const linkToBanks = LINK_CONTRACTOR_BANKS_LIST.replace(":contractorId", this.contractorId);

        const LINKS = {
            //Профиль
            PROFILE: {
                name: "Профиль",
                link: linkToProfile,
                active: linkToProfile === pathname,
            },
            //История заказаов
            ORDERS: {
                name: t("contractor-card.section-orders"),
                link: linkToOrderList,
                active: [linkToOrderList].includes(pathname),
            },
            //История заданий
            TASKS: {
                name: "История заданий",
                link: linkToTasksList,
                active: [linkToTasksList].includes(pathname),
            },
            //ПД
            INFO: {
                name: t("contractor-card.section-personal-information"),
                link: linkInfo,
                active: linkInfo === pathname,
            },
            //Документы
            DOCUMENTS: {
                name: t("contractor-card.section-documents"),
                link: linkToDocumentList,
                active: linkToDocumentList === pathname,
            },
            //Финансы
            FINANCE: {
                name: "Финансы",
                link: linkToFinanceList,
                active: [
                    linkToFinanceList,
                    LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST.replace(":contractorId", this.contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS.replace(":contractorId", this.contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS.replace(":contractorId", this.contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS.replace(":contractorId", this.contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST.replace(":contractorId", this.contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST.replace(":contractorId", this.contractorId),
                    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST.replace(":contractorId", this.contractorId),
                ].includes(pathname),
            },
            //Устройства
            DEVICES: {
                name: t("contractor-card.section-devices"),
                link: linkToDeviceList,
                active: linkToDeviceList === pathname,
            },
            //Отзывы
            REVIEWS: {
                name: "Отзывы",
                link: linkToReviewsList,
                active: linkToReviewsList === pathname,
            },
            //Журнал событий
            EVENT_LOG: {
                name: "Журнал событий",
                link: linkToEventLog,
                active: pathname.includes("event-log"),
            },
            //Обращения
            TICKETS: {
                name: "Обращения",
                link: linkToTickets,
                active: pathname.includes(LINK_CRM_TICKET),
                count: ticketCounts.totalCount,
            },
            //Банки
            BANKS: {
                name: "Банки",
                link: linkToBanks,
                active: pathname.includes(linkToBanks),
            },
        };

        if (this.role === RNKO) {
            return [
                LINKS.INFO,
                LINKS.REVIEWS,
                LINKS.BANKS,
            ];
        }

        const clientEventLog = canViewContractorEventLogClientAdmin ? LINKS.EVENT_LOG : null;
        const PD = personalDataAccessGranted ? LINKS.INFO : null;

        if (this.role === HR_MANAGER) {
            return [
                LINKS.REVIEWS,
                LINKS.INFO,
                clientEventLog,
            ].filter(link => link);
        }

        if (this.isCustomer) {
            return [
                LINKS.PROFILE,
                LINKS.ORDERS,
                LINKS.TASKS,
                PD,
                LINKS.DOCUMENTS,
                LINKS.REVIEWS,
                clientEventLog,
            ].filter(link => link);
        }

        if (this.role === RECRUITER) {
            return [
                LINKS.PROFILE,
                LINKS.TASKS,
                PD,
                clientEventLog,
            ].filter(link => link);
        }


        let tabs = [
            LINKS.PROFILE,
            LINKS.ORDERS,
            LINKS.TASKS,
            LINKS.INFO,
            LINKS.DOCUMENTS,
            LINKS.FINANCE,
            LINKS.REVIEWS,
            LINKS.DEVICES,
            LINKS.EVENT_LOG,
        ];

        if (this.isVisibleContractorTickets) {
            tabs = [
                ...tabs,
                LINKS.TICKETS,
                LINKS.BANKS,
            ];
        }

        if ([NM_CHIEF_ACCOUNTANT, RNKO].includes(this.role)) {
            tabs.push(LINKS.BANKS);
        }

        return tabs;
    }

    renderContractorNoteForClientEditModal = () => {
        const {contractor} = this.props;
        const {isOpenContractorNoteForClientEditModal} = this.state;

        return (
            isOpenContractorNoteForClientEditModal &&
            <ContractorNoteForClientEditModal
                contractor={contractor}
                onClose={this.toggleOpenOpenContractorNoteForClientEditModal}
            />
        );
    };

    render() {
        const {
            contractor: {
                lastName,
                firstName,
                patronymic,
                fullName,
                phone,
                email,
                lastLoginDate,
                online,
                blocked,
                maxHiredOrderWorkEndDate,
                favourite,
                blacklist,
                blacklistReason,
                archived,
                registrationStatus,
                noteForClient,
            },
            contractorRatings: {
                rating,
            },
            t,
            clientId,
            clientUserId,
            contractorStatusDict,
            contractor,
            match: {params},
            currentMember,
        } = this.props;

        const {
            isLoaded,
        } = this.state;

        if (!isLoaded) {
            setTimeout(() => this.setState({isLoaded: true}), 2000);

            return (
                <NmLoader
                    active
                    content={t("loader.content")}
                />
            );
        }

        const formattedFullName = lastName ? `${lastName} ${firstName} ${patronymic || ""}` : fullName;
        const statusText = contractorStatusDict[registrationStatus];

        return (
            <NmPage
                header={
                    <Media query={{maxWidth: 1199}}>
                        {
                            mobile =>
                                <div className="flex contractor-card__header">
                                    {
                                        !mobile &&
                                        <ButtonBack
                                            className="contractor-card__button-back"
                                            onClick={this.handleOnClickBack}
                                        />
                                    }
                                    <ContractorHeader
                                        onClickContacts={this.handleCopyContact}
                                        contractorId={this.contractorId}
                                        clientId={clientId}
                                        clientUserId={clientUserId}
                                        name={formattedFullName}
                                        phone={phoneFormat(phone)}
                                        email={email}
                                        isOnline={online}
                                        lastLoginDate={formatLocalDate(lastLoginDate, "dd.MM.yyyy HH:mm")}
                                        isUserBlocked={blocked}
                                        lastOrderDate={maxHiredOrderWorkEndDate}
                                        isFavorite={favourite}
                                        isInBlackList={blacklist}
                                        blackListReason={blacklistReason}
                                        archived={archived}
                                        noteForClient={noteForClient}
                                        contractorInvitationProhibited={currentMember.contractorInvitationProhibited}
                                        openNoteForClientEditModal={this.toggleOpenOpenContractorNoteForClientEditModal}
                                    />
                                </div>
                        }
                    </Media>
                }
                subHeader={
                    <ContractorHeaderNoteForClient
                        contractor={contractor}
                        openEditModal={this.toggleOpenOpenContractorNoteForClientEditModal}
                    />
                }
                titleClassName="contractor-card__title"
            >
                {this.renderContractorNoteForClientEditModal()}
                <ContractorGeneralInfo
                    statusText={statusText}
                    clientId={clientId}
                    role={this.role}
                    className="mb-4 mb-xxl-5"
                    contractor={contractor}
                    isNmEmployees={this.isNmEmployees}
                    rating={rating}
                    params={params}
                />
                <Tabs
                    className="mb-4 mb-xxl-5"
                    panes={this.getTabs()}
                />
                <ContractorRoutes />
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        contractor: contractorCardSelector(state),
        pathname: state.router.location.pathname,
        contractorRatings: ratingsContractorSelector(state),
        clientId: state.clientMember.currentMember.clientId,
        clientUserId: state.clientMember.currentMember.clientUserId,
        clientProperties: getClientPropertiesCardSelector(state),
        note: state.contractor.note,
        contractorStatusDict: contractorStatusDictSelector(state),
        ticketCounts: ticketCountsSelector(state),
        currentMember: clientCurrentMemberSelector(state),
    }),
    {
        getRichContractorById,
        updateFieldContractorStore,
        getRatingsByContractorId,
        getNote,
        getContractorStatusDict,
        getClientPropertiesById,
        getTicketCounts,
    },
)(withTranslation()(ContractorCard));