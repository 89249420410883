import {
    BFF_MAP_EXPORT_REQUEST,
    BFF_MAP_GET_ADVERTS_REQUEST,
    BFF_MAP_GET_CLUSTERS_REQUEST,
    BFF_MAP_GET_CONTRACTORS_REQUEST,
    BFF_MAP_GET_LIST_PAGE_COUNTS_REQUEST,
    BFF_MAP_GET_ORDERS_REQUEST,
    BFF_MAP_GET_TASKS_REQUEST,
    BFF_MAP_SEND_INVITE_TASK_REQUEST,
    BFF_MAP_UPDATE_STORE,
} from "./actions";

export const getMapClusters = (payload) => {
    return {
        type: BFF_MAP_GET_CLUSTERS_REQUEST,
        payload,
    };
};

export const getMapContractors = (payload) => {
    return {
        type: BFF_MAP_GET_CONTRACTORS_REQUEST,
        payload,
    };
};

export const getMapOrders = (payload) => {
    return {
        type: BFF_MAP_GET_ORDERS_REQUEST,
        payload,
    };
};

export const getMapAdverts = (payload) => {
    return {
        type: BFF_MAP_GET_ADVERTS_REQUEST,
        payload,
    };
};

export const getMapTasks = (payload) => {
    return {
        type: BFF_MAP_GET_TASKS_REQUEST,
        payload,
    };
};

export const getMapListPageCounts = (payload) => {
    return {
        type: BFF_MAP_GET_LIST_PAGE_COUNTS_REQUEST,
        payload,
    };
};

export const exportMap = (payload) => {
    return {
        type: BFF_MAP_EXPORT_REQUEST,
        payload,
    };
};

export const sendInviteTaskMap = (payload) => {
    return {
        type: BFF_MAP_SEND_INVITE_TASK_REQUEST,
        payload,
    };
};

export const updateStoreMap = (payload) => {
    return {
        type: BFF_MAP_UPDATE_STORE,
        payload,
    };
};