import {useState} from "react";

export default function useVersionsTemplateModal() {
    const [isOpenVersionsTemplateModal, setIsOpenVersionsTemplateModal] = useState(false);
    const [versionData, setVersionData] = useState({});

    const openModal = (data = {}) => {
        setIsOpenVersionsTemplateModal(true);
        setVersionData(data);
    };

    const closeModal = () => {
        setIsOpenVersionsTemplateModal(false);
        setVersionData({});
    };

    return {
        isOpenVersionsTemplateModal,
        openModal,
        closeModal,
        versionData,
    };
}