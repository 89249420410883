import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import NmListCard from "../ActualComponents/NmList/Card";
import NmModal from "../ActualComponents/NmModal";
import CheckboxList from "../CheckboxList";
import NmTitle from "../NmTitle";

import {monthOption} from "../../utils/dateFormat";

import PropTypes from "prop-types";

import "./style.sass";

class HistoryPaymentRetailModal extends Component {
    static propTypes = {
        close: PropTypes.func,
        histories: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        close: () => {
        },
        histories: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            sortType: "desc",
        };
    }

    getMonth(month) {
        return `${monthOption[month.slice(0,2)]}, ${month.slice(3)}`;
    };

    sortList = (a, b) => {
        const {
            sortType,
        } = this.state;

        const date1 = new Date(`01.${a.month}`),
            date2 = new Date(`01.${b.month}`);

        if (sortType === "desc") {
            return date2 - date1;
        }

        return date1 - date2;
    };

    getRows() {
        const {histories} = this.props;

        return histories.sort(this.sortList).map(row => {
            return {
                ...row,
                key: row.itemId,
                contentRow: (
                    <NmListCard
                        noDivider
                        onlyLabels
                        labels={[
                            {label: "Месяц выплаты", columnOnMobile: true, text: this.getMonth(row.month)},
                            {label: "Наименование юр.лица", columnOnMobile: true, text: row.clientNames.join(", ")},
                        ]}
                    />
                ),
            };
        });
    }

    onClickSort = ({sortType}) => {
        this.setState({
            sortType,
        });
    };

    render() {
        const {
            close,
        } = this.props;
        const {sortType} = this.state;

        return (
            <NmModal
                size="md"
                onClose={close}
                className="history-payment-retail-modal"
                header={
                    <NmTitle size="lg">
                        История выплат
                    </NmTitle>
                }
                classNameContent="history-payment-retail-modal__content"
            >
                <CheckboxList
                    sort
                    sortOptions={[
                        {
                            key: "month",
                            text: "По месяцу выплаты",
                            sortType,
                            value: "month",
                            asc: "asc",
                            desc: "desc",
                        },
                    ]}
                    onClickSort={this.onClickSort}
                    rows={this.getRows()}
                />
            </NmModal>
        );
    }
}

export default withTranslation()(HistoryPaymentRetailModal);
