import {useMemo} from "react";
import {useDispatch} from "react-redux";
import {setNestedObjectValues, useFormik} from "formik";
import * as yup from "yup";

import {handleFormString} from "../../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../../utils/toastHelper";

import {requiredMessage} from "../../../../../../../constants/validationRules";

import {
    addClientModuleTariff,
    updateClientModuleTariff,
} from "../../../../../../../ducks/bff/clients/module-tariffs/actionCreators";

export const useModulesAndTariffsEditModalForm = (props) => {
    const {
        clientId,
        isEdit,
        editData,
        onClose,
        fetch,
    } = props;

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        return {
            tariffName: editData.tariffName || "",
            tariffValue: editData.tariffValue || "",
            tariffDescription: editData.tariffDescription || "",
        };
    }, [editData]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const reqData = {
            clientId,
            tariffName: handleFormString(values.tariffName),
            tariffValue: handleFormString(values.tariffValue),
            tariffDescription: handleFormString(values.tariffDescription),
            onSuccess: () => {
                if (fetch) {
                    fetch();
                }

                if (onClose) {
                    onClose();
                }
                
                toastSuccess(
                    isEdit ?
                        "Настройки компании успешно обновлены" :
                        "Модуль успешно добавлен",
                );
            },
        };

        if (isEdit) {
            dispatch(updateClientModuleTariff({
                id: editData.id,
                ...reqData,
            }));

            return;
        }

        dispatch(addClientModuleTariff(reqData));
    };

    const {
        values,
        touched,
        errors,
        isValid,
        handleSubmit,
        handleChange,
    } = useFormik({
        onSubmit,
        initialValues,
        initialTouched: setNestedObjectValues(initialValues, false),
        validationSchema: yup.object().shape({
            tariffName: yup.string()
                .required(requiredMessage)
                .max(60, "Максимальная длина - 60 символов"),
            tariffValue: yup.string()
                .required(requiredMessage)
                .max(25, "Максимальная длина - 25 символов"),
            tariffDescription: yup.string()
                .max(500, "Максимальная длина - 500 символов"),
        }),
        enableReinitialize: true,
    });

    return {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
    };
};