import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../../../../../components/ActualComponents/ImportFromFilePatternV2";
import {MediaButtons} from "../../../../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import ExtLink from "../../../../../../components/ExtLink";
import NmPage from "../../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../../components/NmPageHeader";
import TableDiv from "../../../../../../components/TableDiv";
import {ReactComponent as ResetIcon} from "../../../../../../images/reset_24.svg";

import {useFilter} from "../../../../../../hooks/useFilter";
import {usePagination} from "../../../../../../hooks/usePagination";
import useSortList, {sortAction} from "../../../../../../hooks/useSortList";
import {useActionOnboardingLeadsList} from "./hooks/useAction";
import {useFetchOnboardingLeadsList} from "./hooks/useFetch";
import {useOnboardingLeadsListFilter} from "./hooks/useFilter";

import bem from "../../../../../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../../utils/localstorage";
import {phoneFormat} from "../../../../../../utils/stringFormat";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {onboardingLeadsHeaders} from "../../../../../../constants/headersTable";
import {LINK_CONTRACTOR_PROFILE} from "../../../../../../constants/links";
import {
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
} from "../../../../../../constants/roles";

import {onboardingScenarioLeadsSelector} from "../../../../../../ducks/onboarding";

import "./style.sass";

const TOOLTIP_TOP_POSITION_ROW_COUNT = 1;

const OnboardingScenarioLeadList = (props) => {
    const {
        clientId,
        scenarioId,
    } = props;

    const [, sortType, headers, sortDispatch] = useSortList(onboardingLeadsHeaders);

    const {
        list,
        totalCount,
        totalPages,
        progress,
    } = useSelector(onboardingScenarioLeadsSelector);

    const [block, element] = bem("onboarding-scenario-lead-list");

    const role = ls(USER_ROLE);

    const {
        filters,
        mapFilterDto,
        initFilter,
    } = useOnboardingLeadsListFilter({});

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const {
        fetchList,
    } = useFetchOnboardingLeadsList({
        filter: filterData,
        sortType,
        pageSize,
        pageNum,
        clientId,
        scenarioId,
    });

    const {
        isImportModalOpen,
        setIsImportModalOpen,
        onSubmitImportList,
        getMediaControls,
        onSubmitDeleteLead,
        refreshContactorsStatusesList,
        onCloseConfirm,
        isOpenConfirm,
        progressAction,
        exportList,
    } = useActionOnboardingLeadsList({
        fetchList,
        clientId,
        scenarioId,
    });

    const handleChangeSearch = (field, sortType) => {
        if (sortType !== "asc" && sortType !== "desc") {
            return;
        }

        sortDispatch(sortAction([field, sortType]));
    };

    const getHeaderMediaControls = () => {
        return {
            renderCount: {
                desktop: 3,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => setIsImportModalOpen(true),
                        children: "Загрузить лиды",
                    },
                    visible: ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "xl",
                        onClick: exportList,
                        children: "Выгрузить список",
                        disabled: totalCount < 1 || progressAction,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: refreshContactorsStatusesList,
                        children: "Обновить статусы исполнителей",
                        color: "grey",
                        icon: <ResetIcon />,
                        disabled: totalCount < 1 || progressAction,
                    },
                },
            ],
        };
    };

    const getName = (item) => {
        const {
            externalContractorName,
            contractorId,
        } = item;

        if (contractorId) {
            const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

            return (
                <ExtLink
                    to={link}
                    historyEnabled
                >
                    {externalContractorName}
                </ExtLink>
            );
        }

        return externalContractorName;
    };

    const getInn = (item, index) => {
        if (!item.externalContractorInn) {
            return "-";
        }

        const isTopPosition = list.length - index <= TOOLTIP_TOP_POSITION_ROW_COUNT;

        return (
            <div className="d-flex align-items-center justify-content-between fluid">
                {item.externalContractorInn}
                {
                    item.actualContractorInnNotMatchExternalContractorInn &&
                    <HelpTooltip
                        hover
                        attention
                        position={isTopPosition ? "top-left" : "bottom-left"}
                        text="Обратите внимание, что переданный в лиде ИНН не совпадает с ИНН в персональных данных исполнителя"
                    />
                }
            </div>
        );
    };

    const mapTableData = () => {
        const rows = list.map((item, index) => {
            const firstPersonalDataSubmissionDateTime = formatDate(convertUtcToLocal(item.firstPersonalDataSubmissionDateTime), "dd.MM.yyyy HH:mm");
            const lastPersonalDataSubmissionDateTime = formatDate(convertUtcToLocal(item.lastPersonalDataSubmissionDateTime), "dd.MM.yyyy HH:mm");

            return {
                ...item,
                externalContractorName: getName(item),
                creationDateTime: formatDate(convertUtcToLocal(item.creationDateTime), "dd.MM.yyyy HH:mm"),
                externalContractorPhone: phoneFormat(item.externalContractorPhone),
                externalContractorInn: getInn(item, index),
                registrationStatus: item.registrationStatus.description,
                basicRegistrationDateTime: item.basicRegistrationDateTime ?
                    formatDate(convertUtcToLocal(item.basicRegistrationDateTime), "dd.MM.yyyy HH:mm") :
                    "-",
                personalDataSubmissionDateTimes: `${firstPersonalDataSubmissionDateTime} - ${lastPersonalDataSubmissionDateTime}`,
                externalLeadId: item.externalLeadId || "-",
                action: (
                    <MediaButtons
                        inline={true}
                        config={getMediaControls(item)}
                    />
                ),
            };
        });

        return {
            rows,
            headers,
        };
    };

    const renderImportForm = () => {
        return (
            isImportModalOpen &&
            <ImportFromFilePatternV2
                returnFormData={true}
                patternLink="/files/Шаблон_Список_лидов.xlsx"
                onSubmit={onSubmitImportList}
                onClose={() => setIsImportModalOpen(false)}
                progress={progressAction}
                headerTitle="Загрузка лидов"
            />
        );
    };

    const renderConfirm = () => {
        if (!isOpenConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                size="sm"
                onCancel={onCloseConfirm}
                onConfirm={onSubmitDeleteLead}
                isNeedClosing={false}
                disabled={progressAction}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                content="Вы действительно хотите удалить лид?"
            />
        );
    };

    return (
        <NmPage
            noPadding
            className={block()}
            header={
                <NmPageHeader
                    text="Лиды"
                />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            mediaControls={getHeaderMediaControls()}
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderConfirm()}
            {renderImportForm()}
            {
                totalCount > 0 ?
                    <TableDiv
                        isOverflowX={true}
                        className={element("table")}
                        handleChangeSearch={handleChangeSearch}
                        tableData={mapTableData()}
                    /> :
                    <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default OnboardingScenarioLeadList;