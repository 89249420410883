import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import AmountInput from "../../../../components/AmountInput";
import CardApp from "../../../../components/CardApp";
import RefHistoryBlock from "../ref-history-block";
import {Button, Dimmer, Form, Loader} from "semantic-ui-react";

import {convertUtcToLocal} from "../../../../utils/dateFormat";
import dateFormat from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getNumberFromFormattedAmount} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {
    isCommissionRateEditable,
    isPromoCodesEditable, isShowClientPromoCodesBlock,
    isShowContractorsPromoCodesBlock,
} from "../../../../utils/user-rights/promoCodesAndLimites";
import validate from "../../../../utils/validate";

import {POSTFIX_PROMOTION} from "../../../../constants/promocode/dict";
import {REF_TYPE} from "../../../../constants/refProgram";
import {NM_CONSULTANT, NM_COORDINATOR} from "../../../../constants/roles";
import {promocodeRule, referralRateRule} from "../../../../constants/validationRules";

import {
    addRefProgram,
    clientReferralDataSelector,
    clientReferralHistoryProgressSelector,
    clientReferralHistorySelector,
    clientRefProgramProgressSelector,
    clientRefTotalPagesSelector, contractorsReferralHistoryProgressSelector,
    contractorsReferralHistorySelector,
    contractorsRefTotalPagesSelector,
    getClientRefProgram,
    getClientRefProgramHistory,
} from "../../../../ducks/clientRefProgram";
import {
    checkPromocode,
    getResponseFromCheckSelector,
    registrationPromocode,
    updateFieldPromocodeStore,
} from "../../../../ducks/promocode";

import PropTypes from "prop-types";

import "./style.sass";

class PromocodeCardBlock extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor() {
        super();

        this.state = {
            isClientRefBlockEdit: false,
            promocode: undefined,
            clientHistoryPageNum: 1,
            contractorsHistoryPageNum: 1,
            error: {},
            editableReferralRate: 1,
            hasRegistration: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        if (isShowClientPromoCodesBlock) {
            this.getClientRefProgramHistory(REF_TYPE.CLIENTS);
        }
        this.getClientRefProgramHistory(REF_TYPE.CONTRACTORS);
        this.getClientRefProgram();
    }

    componentDidUpdate(prevProps) {
        const {
            updateFieldPromocodeStore,
            responseFromCheck,
        } = this.props;

        const {
            responseFromCheck: {
                checkProgress: prevCheckProgress,
            },
        } = prevProps;

        const {errorMessageCheck, checkProgress, isCheckSuccess} = responseFromCheck;

        if (prevProps.refData.promocodeForClient.promocode !== this.props.refData.promocodeForClient.promocode) {
            this.setState({
                promocode: this.props.refData.promocodeForClient.promocode,
            });
        }

        if (isCheckSuccess && !errorMessageCheck && !checkProgress && prevCheckProgress) {
            this.addRefProgram();
            updateFieldPromocodeStore({
                errorMessageCheck: undefined,
                isSuccessAdd: false,
            });
        }
    };

    componentWillUnmount() {
        this.clearStore();
    };

    clearStore() {
        const {updateFieldPromocodeStore} = this.props;

        updateFieldPromocodeStore({
            errorMessageCheck: undefined,
            isSuccessAdd: false,
            clientPromocode: undefined,
        });
    }

    addRefProgram = () => {
        const {
            addRefProgram,
            clientId,
        } = this.props;

        const {
            editableReferralRate,
            promocode,
        } = this.state;

        if (!this.isValid()) {
            return;
        }

        addRefProgram({
            clientId,
            referralRate: getNumberFromFormattedAmount(editableReferralRate),
            actionType: "UPDATE",
            promocode,
            onSuccess: () => {
                this.setState({
                    isClientRefBlockEdit: false,
                });

                this.clearStore();
            },
        });
    };

    getClientRefProgramHistory = (type) => {
        const {
            getClientRefProgramHistory,
            clientId,
        } = this.props;

        const {
            contractorsHistoryPageNum,
            clientHistoryPageNum,
        } = this.state;

        getClientRefProgramHistory({
            clientId,
            pageNum: type === REF_TYPE.CLIENTS ? clientHistoryPageNum : contractorsHistoryPageNum,
            pageSize: 5,
            typeFilter: type,
        });
    };

    getClientRefProgram = () => {
        const {
            getClientRefProgram,
            clientId,
        } = this.props;

        getClientRefProgram({clientId});
    };

    get isAccessEdit() {
        return this.role !== NM_CONSULTANT && this.role !== NM_COORDINATOR;
    }

    get contractorRefBlockProps() {
        const {
            t,
        } = this.props;

        return {
            title: "Промо-код для исполнителей",
            subTitle: t("promocode-card-block.promocode-card-msg"),
            className: "client-card-block promocode-card-block__contractor-ref-block",
        };
    }

    get clientRefBlockProps() {
        const {
            isClientRefBlockEdit,
        } = this.state;

        const {
            t,
        } = this.props;

        return {
            title: "Промо-код для компании",
            subTitle: t("promocode-card-block.promocode-card-msg"),
            className: `"client-card-block" ${isClientRefBlockEdit ? "client-card-block-requisites" : ""} promocode-card-block__client-ref-block`,
        };
    }

    isValid = () => {
        const {
            editableReferralRate,
            promocode,
        } = this.state;

        const {
            responseFromCheck,
        } = this.props;

        const {
            errorMessageCheck,
        } = responseFromCheck;

        if (errorMessageCheck) {
            return false;
        }

        const error = validate({referralRate: editableReferralRate, promocode}, referralRateRule, "");

        const isValid = Object.values(error).length === 0;

        this.setState({error});

        return isValid;
    };

    toggleEdit = () => {
        const {
            isClientRefBlockEdit,
        } = this.state;

        const {
            refData: {
                promocodeForClient: {
                    referralRate,
                },
            },
        } = this.props;

        if (isClientRefBlockEdit) {
            return this.checkPromocode();
        }

        this.setState(
            prevState => ({
                ...prevState,
                isClientRefBlockEdit: !prevState.isClientRefBlockEdit,
                editableReferralRate: referralRate == "0" ? "1" : referralRate,
            }),
        );
    };

    registrationPromocode() {
        const {
            registrationPromocode,
            clientId,
        } = this.props;

        const {
            promocode,
        } = this.state;

        registrationPromocode({
            data: {
                clientId,
                promocode,
            },
            onError: (error) => {
                this.setState(prevState => ({error: {...prevState.error, promocode: error}}));
            },
            onSuccess: () => {
                this.setState({hasRegistration: true});
                this.getClientRefProgram();
            },
        });
    };

    handleOnChange = (e, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    handleClientPaginationChange = (e, {activePage: pageNum}) => {
        const {clientHistoryPageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                clientHistoryPageNum: pageNum,
            },
            () => {
                this.getClientRefProgramHistory(REF_TYPE.CLIENTS);
            },
        );
    };

    handleContractorsPaginationChange = (e, {activePage: pageNum}) => {
        const {contractorsHistoryPageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                contractorsHistoryPageNum: pageNum,
            },
            () => {
                this.getClientRefProgramHistory(REF_TYPE.CONTRACTORS);
            },
        );
    };

    isPromocodeValid = () => {
        const {
            promocode,
        } = this.state;
        const error = validate({promocode}, promocodeRule, "");

        this.setState({
            error,
        });

        return Object.values(error).length === 0;
    };

    checkPromocode = () => {
        const {
            checkPromocode,
        } = this.props;

        const {
            promocode,
        } = this.state;

        if (!this.isPromocodeValid()) {
            return;
        }

        checkPromocode({data: {promocode}});
    };

    cancelPromocodeInfoEdit = () => {
        const {
            refData: {
                promocodeForClient: {
                    promocode,
                },
            },
            updateFieldPromocodeStore,
        } = this.props;
        this.setState({
            isClientRefBlockEdit: false,
            promocode,
            error: {},
        });
        updateFieldPromocodeStore({
            errorMessageCheck: undefined,
            isSuccessAdd: false,
        });
    };

    renderContractorsRefHistory() {
        const {
            contractorRefHistory,
            contractorsRefHistoryTotalPages,
        } = this.props;

        const {
            contractorsHistoryPageNum,
        } = this.state;

        return (
            <RefHistoryBlock
                list={contractorRefHistory}
                totalPages={contractorsRefHistoryTotalPages}
                pageNum={contractorsHistoryPageNum}
                handlePaginationChange={this.handleContractorsPaginationChange}
            />
        );
    };

    renderClientRefHistory() {
        const {
            clientRefHistory,
            clientRefHistoryTotalPages,
        } = this.props;

        const {
            clientHistoryPageNum,
        } = this.state;

        return (
            <RefHistoryBlock
                list={clientRefHistory}
                totalPages={clientRefHistoryTotalPages}
                pageNum={clientHistoryPageNum}
                handlePaginationChange={this.handleClientPaginationChange}
            />
        );
    };

    renderCommissionRateSendBlock() {
        const {
            editableReferralRate,
            error,
            promocode,
        } = this.state;

        const {
            refData: {
                promocodeForClient: {
                    archived,
                },
            },
            responseFromCheck,
        } = this.props;

        const {
            errorMessageCheck,
        } = responseFromCheck;


        return (
            isCommissionRateEditable(this.role) &&
            <NmForm addMargin>
                <NmInputV2
                    size="lg"
                    label="Промо-код"
                    name="promocode"
                    value={promocode}
                    onChange={this.handleOnChange}
                    className="promocode-card-block__edit-input-promocode"
                    error={error.promocode || errorMessageCheck}
                />
                <AmountInput
                    newInput
                    size="lg"
                    maskChar={null}
                    label="Размер вознаграждения Партнёра, %"
                    maxValue={100}
                    className="promocode-card-block__edit-ref-input"
                    error={error.referralRate}
                    value={editableReferralRate}
                    placeholder="0,00"
                    disabled={archived}
                    name="editableReferralRate"
                    onChange={this.handleOnChange}
                />
            </NmForm>
        );
    };

    renderPromocodeInfoEdit() {
        const {
            promocode,
            error,
        } = this.state;

        const {
            responseFromCheck,
            t,
        } = this.props;

        const {
            errorMessageCheck,
        } = responseFromCheck;

        const {
            subTitle,
        } = this.clientRefBlockProps;

        return (
            <>
                <label>
                    {subTitle}
                </label>
                <Form className="promocode-card-block">
                    <NmInputV2
                        size="lg"
                        className="promocode-card-block__input"
                        errorMessage={error.promocode || errorMessageCheck}
                        name="promocode"
                        placeholder={t("promocode-card-block.enter-promocode")}
                        onChange={this.handleOnChange}
                        value={promocode}
                    />
                    <Button
                        className="promocode-card-block__button"
                        onClick={this.checkPromocode}
                    >
                        Активировать
                    </Button>
                </Form>
            </>
        );
    };

    renderReqInfo(requisites, isPromocodeExist = true) {
        return (
            <div className="promocode-card-block__ref flex mt-2">
                Реквизиты договора:
                <div
                    className="promocode-card-block__ref-rate"
                >
                    {isPromocodeExist ? requisites : "Нет информации"}
                </div>
            </div>
        );
    };

    renderRefInfo({referralRate, requisites, promocode}) {
        const isPromocodeExist = !isNullOrWhitespace(promocode);

        return (
            <>
                <div className="promocode-card-block__ref flex">
                    Размер вознаграждения Партнёра, %:
                    <div
                        className="promocode-card-block__ref-rate"
                    >
                        {isPromocodeExist ? referralRate : "Нет информации"}
                    </div>
                </div>
                {this.renderReqInfo(requisites, isPromocodeExist)}
            </>
        );
    };

    renderContractorsRefInfo() {
        const {
            refData: {
                promocodeForContractor,
            },
            t,
        } = this.props;

        const {
            promocode,
            postfixPromotion,
        } = promocodeForContractor || {};

        return (
            <div>
                <div className="promocode-card-block__promocode">
                    {promocode || t("is-not-specified.content")}
                    {[POSTFIX_PROMOTION.POSTFIX.VALUE, POSTFIX_PROMOTION.RANDOM_POSTFIX.VALUE].includes(postfixPromotion) && "(+ постфикс)"}
                </div>
                {this.renderRefInfo(promocodeForContractor)}
                {this.renderContractorsRefHistory()}
            </div>
        );
    };

    renderClientPromocodeInfoReadOnly() {
        const {
            refData: {
                promocodeForClient,
            },
            t,
        } = this.props;

        const {
            promocode = "",
        } = promocodeForClient || {};

        return (
            <div className="promocode-card-block__promocode">
                {promocode || t("is-not-specified.content")}
            </div>
        );
    };

    renderClientRefInfoReadOnly() {
        const {
            refData: {
                promocodeForClient,
            },
            t,
        } = this.props;

        return (
            <div>
                {this.renderClientPromocodeInfoReadOnly()}
                {this.renderRefInfo(promocodeForClient)}
                {this.renderClientRefHistory()}
            </div>
        );
    };

    renderClientInfoEditBlock() {
        const {
            refData: {
                promocodeForClient,
            },
        } = this.props;

        const {
            requisites,
            promocode,
        } = promocodeForClient || {};

        const isPromocodeExist = !isNullOrWhitespace(promocode);

        return (
            <>
                {this.renderCommissionRateSendBlock()}
                {this.renderReqInfo(requisites, isPromocodeExist)}
                {this.renderClientRefHistory()}
            </>
        );
    };

    renderContent() {
        const {
            isClientRefBlockEdit,
        } = this.state;

        const isRefDataEditable = isPromoCodesEditable(this.role) && isClientRefBlockEdit;

        return isRefDataEditable ? this.renderClientInfoEditBlock() : this.renderClientRefInfoReadOnly();
    };

    renderPreloader() {
        const {
            t,
            clientRefHistoryProgress,
            contractorsRefHistoryProgress,
        } = this.props;

        return (
            <Dimmer
                active={clientRefHistoryProgress || contractorsRefHistoryProgress}
                inverted
            >
                <Loader content={t("loader.content")} />
            </Dimmer>
        );
    };

    renderLastUpdateDate(lastUpdateDate) {
        return (lastUpdateDate &&
            <div className="promocode-card-block__updated-time">
                Изменено: 
                {" "}
                {dateFormat(convertUtcToLocal(lastUpdateDate))}
            </div>
        );
    };

    renderContractorReferralBlock() {
        const {
            title,
            className,
        } = this.contractorRefBlockProps;

        const {
            refData: {
                promocodeForContractor,
            },
        } = this.props;

        const {
            datetime,
            promocode,
        } = promocodeForContractor || {};

        //отображаем блок только если промокод для исполнителей заполнен

        return (isShowContractorsPromoCodesBlock(this.role) && !isEmpty(promocode) &&
            <CardApp
                title={title}
                className={className}
                isEditable={false}
            >
                {this.renderLastUpdateDate(datetime)}
                {this.renderContractorsRefInfo()}
                {this.renderPreloader()}
            </CardApp>
        );
    };

    renderClientRefferalBlock() {
        const {
            isClientRefBlockEdit,
        } = this.state;

        const {
            isEditable,
        } = this.props;

        const {
            title,
            className,
        } = this.clientRefBlockProps;

        const {
            refData: {
                promocodeForClient,
            },
        } = this.props;

        const {
            datetime,
        } = promocodeForClient;

        return (isShowClientPromoCodesBlock(this.role) &&
            <CardApp
                title={title}
                className={className}
                isEditable={isEditable}
                isShowCheck={true}
                onClickIcon={this.toggleEdit}
                onClickCancelIcon={this.cancelPromocodeInfoEdit}
                isEdit={isClientRefBlockEdit}
            >
                {!isClientRefBlockEdit && this.renderLastUpdateDate(datetime)}
                {this.renderContent()}
                {this.renderPreloader()}
            </CardApp>
        );
    };

    render() {
        return (
            <>
                {this.renderContractorReferralBlock()}
                {this.renderClientRefferalBlock()}
            </>
        );
    }

}

export default connect(state => ({
    responseFromCheck: getResponseFromCheckSelector(state),
    clientRefHistory: clientReferralHistorySelector(state),
    contractorRefHistory: contractorsReferralHistorySelector(state),
    clientRefHistoryTotalPages: clientRefTotalPagesSelector(state),
    contractorsRefHistoryTotalPages: contractorsRefTotalPagesSelector(state),
    refData: clientReferralDataSelector(state),
    clientRefHistoryProgress: clientReferralHistoryProgressSelector(state),
    contractorsRefHistoryProgress: contractorsReferralHistoryProgressSelector(state),
    refActionProgress: clientRefProgramProgressSelector(state),
}),
{
    checkPromocode,
    registrationPromocode,
    addRefProgram,
    getClientRefProgram,
    getClientRefProgramHistory,
    updateFieldPromocodeStore,
},
)(withTranslation()(PromocodeCardBlock));
