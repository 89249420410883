import {
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE,
} from "./actions";

export const archiveRecruitmentAccessControlJobBoardAccount  = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST,
        payload,
    };
};

export const searchRecruitmentAccessControlJobBoardAccounts = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST,
        payload,
    };
};

export const clearRecruitmentAccessControlJobBoardsStore = () => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE,
    };
};