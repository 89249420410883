import {
    BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_API_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR,
    BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_LOGS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_OTHER_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_API_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_API_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_API_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_SUCCESS,
    BFF_CLIENTS_SETTINGS_UPDATE_STORE,
} from "./actions";

import {getTotalPages} from "../../../../utils/mathHelper";

const initial = {
    payments: {},
    other: {},
    api: {},
    contractors: {},
    additionalModules: {},
    documents: {},
    hidingOrders: {
        projects: {
            clientInfo: {},
            list: [],
            totalCount: 0,
            pageData: {},
        },
        objects: {
            lists: {},
        },
        projectsAndObjects: {},
    },
    logPageData: {},
    logList: [],
    logListTotalCount: 0,
    logListProgress: false,
    error: null,
    progress: false,
    progressAction: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_LIST_SUCCESS:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_SUCCESS:
        return {
            ...state,
            progress: false,
            hidingOrders: {
                ...state.hidingOrders,
                projectsAndObjects: payload,
            },
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_SUCCESS:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR:
        return {
            ...state,
            progressAction: false,
            error: payload,
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST: {
        const [projectId] = payload.projectIdsFilter;

        const {
            [projectId]: objects = {},
        } = state.hidingOrders.objects.lists;

        return {
            ...state,
            hidingOrders: {
                ...state.hidingOrders,
                objects: {
                    ...state.hidingOrders.objects,
                    lists: {
                        ...state.hidingOrders.objects.lists,
                        [projectId]: {
                            ...objects,
                            isLoading: true,
                            pageData: payload,
                        },
                    },
                },
            },
        };
    }
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR:
    case BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR:
        return {
            ...state,
            hidingOrders: {
                ...state.hidingOrders,
                objects: {
                    ...state.objects,
                    progress: false,
                },
            },
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_SUCCESS:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_SUCCESS: {
        const {
            [payload.projectId]: objects = {},
        } = state.hidingOrders.objects.lists;
        const {list = []} = objects;
        const {
            totalCount,
            results,
            nextAllPagesSelectionType,
            ...otherPayload
        } = payload;

        return {
            ...state,
            hidingOrders: {
                ...state.hidingOrders,
                objects: {
                    ...state.hidingOrders.objects,
                    ...otherPayload,
                    lists: {
                        ...state.hidingOrders.objects.lists,
                        [payload.projectId]: {
                            ...objects,
                            list: [
                                ...list,
                                ...results,
                            ],
                            nextAllPagesSelectionType,
                            isLoading: false,
                            totalCount: totalCount,
                            totalPages: getTotalPages(totalCount, objects.pageData.pageSize),
                        },
                    },
                },
            },
        };
    }
    case BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST:
        return {
            ...state,
            hidingOrders: {
                ...state.hidingOrders,
                projects: {
                    ...state.hidingOrders.projects,
                    pageData: payload,
                    progress: true,
                },
            },
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR:
        return {
            ...state,
            progress: false,
            hidingOrders: {
                ...state.hidingOrders,
                projects: {
                    ...state.hidingOrders.projects,
                    progress: false,
                },
            },
        };
    case BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_SUCCESS:
    case BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_SUCCESS:
        const {
            results,
            totalCount,
            ...otherPayload
        } = payload;

        return {
            ...state,
            hidingOrders: {
                ...state.hidingOrders,
                projects: {
                    ...state.hidingOrders.projects,
                    clientInfo: otherPayload,
                    list: state.hidingOrders.projects.pageData.isSearchCleared ?
                        results :
                        [
                            ...state.hidingOrders.projects.list,
                            ...results,
                        ],
                    totalCount,
                    progress: false,
                },
            },
        };
    case BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_SUCCESS:
        return {
            ...state,
            contractors: payload.result,
        };
    case BFF_CLIENTS_GET_SETTINGS_API_SUCCESS:
        return {
            ...state,
            api: payload.result,
        };
    case BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_CLIENTS_GET_SETTINGS_PAYMENTS_SUCCESS:
        return {
            ...state,
            payments: payload.result,
            progress: false,
        };
    case BFF_CLIENTS_GET_SETTINGS_OTHER_SUCCESS:
        return {
            ...state,
            other: payload.result,
        };
    case BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_SUCCESS:
        return {
            ...state,
            additionalModules: payload.result,
        };
    case BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_SUCCESS:
        return {
            ...state,
            documents: payload.result,
        };
    case BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST:
    case BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST:
    case BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST:
    case BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST:
    case BFF_CLIENTS_PUT_SETTINGS_API_REQUEST:
    case BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_SUCCESS:
    case BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR:
    case BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_SUCCESS:
    case BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_ERROR:
    case BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_SUCCESS:
    case BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR:
    case BFF_CLIENTS_PUT_SETTINGS_OTHER_SUCCESS:
    case BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR:
    case BFF_CLIENTS_PUT_SETTINGS_API_ERROR:
    case BFF_CLIENTS_PUT_SETTINGS_API_SUCCESS:
    case BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_SUCCESS:
    case BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST:
        return {
            ...state,
            logPageData: payload,
            logListProgress: true,
        };
    case BFF_CLIENTS_GET_SETTINGS_LOGS_SUCCESS:
        return {
            ...state,
            logList: payload.results,
            logListTotalCount: payload.totalCount,
            logListProgress: false,
        };
    case BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR:
        return {
            ...state,
            logListProgress: false,
        };
    case BFF_CLIENTS_SETTINGS_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default: return state;
    }
};