import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../../templates/constants";

export const CROWD_TASK_EDIT_STEP = {
    PRE_CONDITIONS: {
        NAME: "Предусловия",
        VALUE: "PRE_CONDITIONS",
    },
    GENERAL_INFORMATION: {
        NAME: "Основная информация",
        VALUE: "GENERAL_INFORMATION",
    },
    ADDITIONAL_TERMS: {
        NAME: "Дополнительные условия",
        VALUE: "ADDITIONAL_TERMS",
    },
    PUBLISHING: {
        NAME: "Публикация",
        VALUE: "PUBLISHING",
    },
};

export const CROWD_TASK_EDIT_STEPS = [
    {
        value: CROWD_TASK_EDIT_STEP.PRE_CONDITIONS.VALUE,
        name: "Предусловия задания",
    },
    {
        value: CROWD_TASK_EDIT_STEP.GENERAL_INFORMATION.VALUE,
        name: "Основная информация",
    },
    {
        value: CROWD_TASK_EDIT_STEP.ADDITIONAL_TERMS.VALUE,
        name: "Дополнительные условия",
    },
    {
        value: CROWD_TASK_EDIT_STEP.PUBLISHING.VALUE,
        name: "Публикация",
    },
];

export const ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES = [
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT,
        fieldName: "frameContractTemplateId",
        nameDocumentField: "frameContractTemplateName",
    },
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION,
        fieldName: "orderApplicationTemplateId",
    },
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK,
        fieldName: "actOfAcceptanceOfWorkTemplateId",
    },
];

export const CROWD_TASK_SFA_TYPE = {
    USER_LINK: {
        VALUE: "USER_LINK",
        TEXT: "Пользовательская ссылка",
    },
    OPTIMUM: {
        VALUE: "OPTIMUM",
        TEXT: "Оптимум",
    },
};

export const CROWD_TASK_SFA_TYPE_OPTIONS = [
    {
        key: CROWD_TASK_SFA_TYPE.USER_LINK.VALUE,
        text: CROWD_TASK_SFA_TYPE.USER_LINK.TEXT,
        value: CROWD_TASK_SFA_TYPE.USER_LINK.VALUE,
    },
    {
        key: CROWD_TASK_SFA_TYPE.OPTIMUM.VALUE,
        text: CROWD_TASK_SFA_TYPE.OPTIMUM.TEXT,
        value: CROWD_TASK_SFA_TYPE.OPTIMUM.VALUE,
    },
];

export const TASK_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES = [
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.TASK_APPLICATION,
        fieldName: "applicationTemplateId",
    },
    {
        documentTypeFilter: TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK,
        fieldName: "actTemplateId",
    },
];

export const TASK_PUBLISH_ERROR_CODE = {
    CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR: "CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR",
    CROWD_TASK_CONTRACTOR_SEARCH_ADDITION_VALIDATION_ERROR: "CROWD_TASK_CONTRACTOR_SEARCH_ADDITION_VALIDATION_ERROR",
};

export const TASK_PUBLISH_ERROR_MESSAGE = {
    CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR: "Публикация невозможна. В параметрах проекта необходимо указать идентификатор проекта в системе Оптимум. Для этого получите идентификатор проекта в системе Оптимум и укажите его в карточке проекта. А так же выполните передачу объектов проекта в Оптимум через инструмент экспорта в карточке проекта",
};

export const TASK_CHAT_SEARCH_ERROR_CODE = {
    WRONG_AMOUNT_OF_SYMBOLS: "WRONG_AMOUNT_OF_SYMBOLS",
};
