import React, {createContext} from "react";

import {useModal} from "../../../../../hooks/useModal";

export const ClientSettingsContext = createContext({});

export const ClientSettingsContextProvider = (props) => {
    const {
        tab,
        children,
        updateChanged,
    } = props;

    const {
        modalData: unsavedConfirmData,
        isOpen: isOpenUnsavedDataConfirm,
        onOpenModal: onOpenUnsavedDataConfirm,
        onCloseModal: onCloseUnsavedDataConfirm,
    } = useModal();

    const onCancelUnsavedDataConfirm = () => {
        updateChanged({
            name: unsavedConfirmData.tabName,
            isChangedData: false,
        });
        onCloseUnsavedDataConfirm();
        unsavedConfirmData.onSuccess();
    };

    return (
        <ClientSettingsContext.Provider
            value={{
                isOpenUnsavedDataConfirm,
                onOpenUnsavedDataConfirm,
                onCancelUnsavedDataConfirm,
                onCloseUnsavedDataConfirm,
                updateChanged,
                tab,
            }}
        >
            {children}
        </ClientSettingsContext.Provider>
    );
};