import {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {
    createRecruitmentVacancyCandidate,
    editRecruitmentVacancyCandidate,
    getRecruitmentVacancyCandidatesCard,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {history} from "../../../../../store/configureStore";

import {toastSuccess} from "../../../../../utils/toastHelper";
import {getRecruitmentCandidatesEditForm, getRecruitmentCandidatesEditRequestData} from "../utils/dto";

import {
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO,
} from "../../../../../constants/links";
import {REG_EXP} from "../../../../../constants/regExp";
import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

export const useRecruitmentCandidatesEditForm = (params) => {
    const {
        onClose,
        editData,
        fetchData,
        clientId,
        vacancyId,
        responseId,
        isRedirectToCard,
        addSuccessText = "Кандидат успешно создан",
        isVisibleFunnelStage,
    } = params;

    const dispatch = useDispatch();

    const initialValues = useMemo(() => {
        return getRecruitmentCandidatesEditForm({
            editData,
            isVisibleFunnelStage,
        });
    }, [editData]);

    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        setFieldTouched,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            name: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .min(3, "Не менее 3 символов")
                .max(150, "Не более 150 символов"),
            email: yup.string()
                .max(255, "Не более 255 символов")
                .matches(REG_EXP.EMAIL, {
                    message: "Введен некорректный email",
                }),
            statusId: isVisibleFunnelStage ? yup.string().required(VALIDATIONS_MESSAGE.REQUIRED) : null,
        }),
    });

    useEffect(() => {
        if (editData?.candidateId) {
            dispatch(getRecruitmentVacancyCandidatesCard({
                candidateId: editData.candidateId,
            }));
        }
    }, []);

    function onSubmit() {
        handleSubmitOutOfForm();
    }

    const handleSubmitOutOfForm = (params = {}) => {
        const {
            ignoreDuplicates,
        } = params;
        const requestData = getRecruitmentCandidatesEditRequestData(values, editData);

        if (
            editData
            && !responseId
        ) {
            dispatch(editRecruitmentVacancyCandidate({
                ...values,
                ignoreDuplicates,
                id: editData.candidateId,
                ...requestData,
                onSuccess: () => {
                    onClose();

                    if (params.onSuccess) {
                        params.onSuccess();
                    }

                    dispatch(getRecruitmentVacancyCandidatesCard({
                        candidateId: editData.candidateId,
                    }));

                    toastSuccess("Кандидат успешно обновлен");

                    if (fetchData) {
                        fetchData({isLoadMore: false});
                    }
                },
            }));

            return;
        }

        dispatch(createRecruitmentVacancyCandidate({
            ...requestData,
            ignoreDuplicates,
            clientId,
            vacancyId,
            responseId,
            onSuccess: ({id}) => {
                onClose();

                if (params.onSuccess) {
                    params.onSuccess();
                }

                toastSuccess(addSuccessText);

                if (isRedirectToCard) {
                    const link = LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO
                        .replace(":clientId", clientId)
                        .replace(":id", id);

                    history.push(link);
                }

                if (fetchData) {
                    fetchData({isLoadMore: false});
                }
            },
        }));
    };

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return {
        onChange,
        errors,
        values,
        handleSubmit,
        touched,
        setFieldTouched,
        handleSubmitOutOfForm,
    };
};