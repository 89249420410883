import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getCardCrowdTask,
    getCountsCrowdTaskContractors,
    updateStoreCrowdTaskCard,
} from "../../../../../ducks/bff/crowd/taskCard/actionCreators";

const useCrowdTaskCardFetch = (props) => {
    const {
        clientId,
        taskId,
        subpage,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchCard();

        return () => {
            dispatch(updateStoreCrowdTaskCard({card: {}}));
        };
    }, []);

    useEffect(() => {
        fetchCounts();
    }, [subpage]);

    const fetchCard = () => {
        dispatch(getCardCrowdTask({
            clientId,
            taskId,
        }));
    };

    const fetchCounts = () => {
        dispatch(getCountsCrowdTaskContractors({
            clientId,
            taskId,
        }));
    };

    return {
        fetchCard,
        fetchCounts,
    };
};

export default useCrowdTaskCardFetch;