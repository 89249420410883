import React, {FC, ReactNode, useEffect, useState} from "react";

import Text from "../Text";

import bem from "../../../utils/bem";

import {COLOR} from "../../../constants/color";

import "./style.sass";

interface IVerticalTabs {
    tabs: Array<TabInfoType & {
        content: ReactNode,
    }>,
    setTab?: (tabInfo: TabInfoType) => void,
    isShowContentName?: boolean,
    isActiveGoToTab?: boolean,
    onClickTab?: (params: Omit<TabInfoType, "content">) => void,
    tabName?: string,
    isShowTabs?: boolean,
    isShowContent?: boolean,
    isDisabledClickToCurrentTab?: boolean,
}

type TabInfoType = {
    name: string,
    text?: string,
}

export const VerticalTabs: FC<IVerticalTabs> = (props) => {
    const {
        tabs,
        setTab,
        isShowContentName,
        isActiveGoToTab,
        onClickTab,
        tabName,
        isShowTabs = true,
        isShowContent = true,
        isDisabledClickToCurrentTab = true,
    } = props;

    useEffect(() => {
        if (tabName) {
            const tabIndex = tabs.findIndex(item => item.name === tabName);

            setCurrentTabIndex(tabIndex);
        }
    }, [tabName]);

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [block, element] = bem("vertical-tabs");

    return (
        <div className={block()}>
            {
                isShowTabs &&
                <div className={element("tabs-container")}>
                    <div className={element("tabs")}>
                        {
                            tabs.map((item, index) => {
                                return (
                                    <Text
                                        key={item.name}
                                        level="3"
                                        onClick={() => {
                                            if (
                                                isDisabledClickToCurrentTab &&
                                                index === currentTabIndex
                                            ) {
                                                return;
                                            }

                                            if (onClickTab) {
                                                const {
                                                    content,
                                                    ...tab
                                                } = item;

                                                onClickTab(tab);
                                            }

                                            if (typeof isActiveGoToTab === "boolean" && !isActiveGoToTab) {
                                                return;
                                            }

                                            setCurrentTabIndex(index);

                                            if (setTab) {
                                                const {
                                                    content,
                                                    ...tabInfo
                                                } = item;

                                                setTab(tabInfo);
                                            }
                                        }}
                                        className={element("tab", {active: index === currentTabIndex})}
                                    >
                                        {item.text}
                                    </Text>
                                );
                            })
                        }
                    </div>
                </div>
            }
            {
                isShowContent &&
                <div className={element("content")}>
                    {
                        isShowContentName &&
                        <Text
                            level="4"
                            type="title"
                            color={COLOR.SECONDARY_75}
                            className="mb-3 mb-md-4"
                        >
                            {tabs[currentTabIndex].text}
                        </Text>
                    }
                    {tabs[currentTabIndex].content}
                </div>
            }
        </div>
    );
};
