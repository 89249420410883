import React, {useMemo} from "react";
import {useMedia} from "react-media";
import {useDispatch} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import {ReactComponent as FileDownloadIcon} from "../../../../images/file_download.svg";

import {convertUtcToLocal, getEndDate} from "../../../../utils/dateFormat";
import {handleFormString} from "../../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {STATUS_BANK_DEPOSIT} from "../../../../constants/bankDeposit";

import {repeatingDeposit} from "../../../../ducks/bankDeposit";
import {addBankDepositExport} from "../../../../ducks/job";

export const useFinanceBankAction = (params) => {
    const {
        fetchList,
        filter,
    } = params;
    const dispatch = useDispatch();
    const isMobile = useMedia({query: {maxWidth: 767}});

    const handleResponse = (result) => {
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            return;
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт\"");
    };

    const onClickExport = () => {
        const {
            operationTypeFilter,
            fromDateFilter,
            toDateFilter,
            clientIdFilter,
        } = filter;

        dispatch(addBankDepositExport({
            operationTypeFilter: operationTypeFilter && operationTypeFilter !== "all" ? operationTypeFilter : undefined,
            fromDateFilter: convertUtcToLocal(fromDateFilter),
            toDateFilter: convertUtcToLocal(getEndDate(toDateFilter)),
            clientIdFilter: handleFormString(clientIdFilter),
            handleResponse,
        }));
    };

    const repeatDeposit = (item) => {
        const {depositId} = item;

        dispatch(repeatingDeposit({
            depositId,
            getSuccess: fetchList,
        }));
    };

    const getMediaControls = (item) => {
        if ([STATUS_BANK_DEPOSIT.SUCCESSFULLY.VALUE].includes(item.status)) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Обновить",
                        onClick: () => {
                            repeatDeposit({depositId: item.depositId});
                        },
                    },
                },
            ],
        };
    };

    const mediaControls = useMemo(() => {
        return {
            renderCount: {
                mobile: 2,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON_WITH_TOOLTIP,
                    props: {
                        children: "Выгрузить",
                        onClick: onClickExport,
                        tooltip: (
                            <HelpTooltip
                                type="light"
                                info
                                width={24}
                                height={24}
                                position="bottom-right"
                            >
                                Выгрузка выписки будет выполнена в соответствии с заданными фильтрами
                            </HelpTooltip>
                        ),
                    },
                    asset: {
                        mobile: {
                            icon: <FileDownloadIcon />,
                            onlyIcon: true,
                        },
                    },
                },
            ],
        };
    }, [isMobile, filter]);

    return {
        getMediaControls,
        mediaControls,
    };
};