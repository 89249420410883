import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/orderWorkReportTemplate";

const initState = {
    card: {},
    progress: false,
    actionProgress: false,
};

const ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_REQUEST = "ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_REQUEST";
const ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_SUCCESS = "ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_SUCCESS";
const ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_ERROR = "ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_ERROR";

const ORDER_WORK_REPORT_TEMPLATE_DELETE_REQUEST = "ORDER_WORK_REPORT_TEMPLATE_DELETE_REQUEST";
const ORDER_WORK_REPORT_TEMPLATE_DELETE_SUCCESS = "ORDER_WORK_REPORT_TEMPLATE_DELETE_SUCCESS";
const ORDER_WORK_REPORT_TEMPLATE_DELETE_ERROR = "ORDER_WORK_REPORT_TEMPLATE_DELETE_ERROR";

const ORDER_WORK_REPORT_TEMPLATE_ADD_REQUEST = "ORDER_WORK_REPORT_TEMPLATE_ADD_REQUEST";
const ORDER_WORK_REPORT_TEMPLATE_ADD_SUCCESS = "ORDER_WORK_REPORT_TEMPLATE_ADD_SUCCESS";
const ORDER_WORK_REPORT_TEMPLATE_ADD_ERROR = "ORDER_WORK_REPORT_TEMPLATE_ADD_ERROR";

const ORDER_WORK_REPORT_TEMPLATE_UPDATE_REQUEST = "ORDER_WORK_REPORT_TEMPLATE_UPDATE_REQUEST";
const ORDER_WORK_REPORT_TEMPLATE_UPDATE_SUCCESS = "ORDER_WORK_REPORT_TEMPLATE_UPDATE_SUCCESS";
const ORDER_WORK_REPORT_TEMPLATE_UPDATE_ERROR = "ORDER_WORK_REPORT_TEMPLATE_UPDATE_ERROR";

const ORDER_WORK_REPORT_TEMPLATE_UPDATE_STORE = "ORDER_WORK_REPORT_TEMPLATE_UPDATE_STORE";

export default (state = initState, {type, payload}) => {
    switch (type) {
    case ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_ERROR:
        return {
            ...state,
            progress: false,
        };
    case ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progress: false,
        };
    case ORDER_WORK_REPORT_TEMPLATE_ADD_REQUEST:
    case ORDER_WORK_REPORT_TEMPLATE_DELETE_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case ORDER_WORK_REPORT_TEMPLATE_ADD_SUCCESS:
    case ORDER_WORK_REPORT_TEMPLATE_DELETE_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case ORDER_WORK_REPORT_TEMPLATE_ADD_ERROR:
    case ORDER_WORK_REPORT_TEMPLATE_DELETE_ERROR:
        return {
            ...state,
            actionProgress: false,
        };
    case ORDER_WORK_REPORT_TEMPLATE_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};

export const getOrderWorkReportTemplateById = (payload) => {
    return {
        type: ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_REQUEST,
        payload,
    };
};

export const deleteOrderWorkReportTemplate = (payload) => {
    return {
        type: ORDER_WORK_REPORT_TEMPLATE_DELETE_REQUEST,
        payload,
    };
};

export const addOrderWorkReportTemplate = (payload) => {
    return {
        type: ORDER_WORK_REPORT_TEMPLATE_ADD_REQUEST,
        payload,
    };
};

export const updateOrderWorkReportTemplate = (payload) => {
    return {
        type: ORDER_WORK_REPORT_TEMPLATE_UPDATE_REQUEST,
        payload,
    };
};

export const updateOrderWorkReportTemplateStore = (payload) => {
    return {
        type: ORDER_WORK_REPORT_TEMPLATE_UPDATE_STORE,
        payload,
    };
};

export const orderWorkReportTemplateSelector = ({orderWorkReportTemplate}) => orderWorkReportTemplate;
export const orderWorkReportTemplateCardSelector = createSelector(orderWorkReportTemplateSelector, ({card}) => card);

// POST /api/orderWorkReportTemplate/add
// Создание шаблона акта
const getOrderWorkReportTemplateByIdSaga = function* ({payload}) {
    try {
        const {
            orderWorkReportTemplateId,
        } = payload;
        const result = yield request.get(`${controller}/getById`, {params: {orderWorkReportTemplateId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_ERROR,
            payload: error,
        });
    }
};

// POST /api/orderWorkReportTemplate/add
// Создание шаблона акта
const deleteOrderWorkReportTemplateSaga = function* ({payload}) {
    try {
        const {
            orderWorkReportTemplateId,
            onSuccess,
        } = payload;
        const result = yield request.delete(`${controller}/delete`, {params: {orderWorkReportTemplateId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_TEMPLATE_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /api/orderWorkReportTemplate/add
// Создание шаблона акта
const addOrderWorkReportTemplateSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_TEMPLATE_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /api/orderWorkReportTemplate/add
// Создание шаблона акта
const updateOrderWorkReportTemplateSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.put(`${controller}/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_TEMPLATE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_TEMPLATE_UPDATE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_WORK_REPORT_TEMPLATE_GET_BY_ID_REQUEST, getOrderWorkReportTemplateByIdSaga),
        takeEvery(ORDER_WORK_REPORT_TEMPLATE_DELETE_REQUEST, deleteOrderWorkReportTemplateSaga),
        takeEvery(ORDER_WORK_REPORT_TEMPLATE_ADD_REQUEST, addOrderWorkReportTemplateSaga),
        takeEvery(ORDER_WORK_REPORT_TEMPLATE_UPDATE_REQUEST, updateOrderWorkReportTemplateSaga),
    ]);
}