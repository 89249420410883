import {isEmpty} from "lodash";

import {STATEMENT_LOADING_TYPE} from "../loading-form/hooks/useLoadingForm";

import dateFormat from "../../../../../utils/dateFormat";
import {getNumberFromFormattedAmount} from "../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../utils/stringHelper";

import {EDO_STATEMENT_LOADING_FIELD} from "../../../../../constants/document-management/statements";

export function mapOrderTemplateDataToRequestData(form, code, params, templateType) {
    const requestData = {
        [EDO_STATEMENT_LOADING_FIELD.STATEMENT_NAME]: handleFormString(form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_NAME]),
        [EDO_STATEMENT_LOADING_FIELD.CLIENT_ID]: form[EDO_STATEMENT_LOADING_FIELD.CLIENT_ID],
    };
    if (form[EDO_STATEMENT_LOADING_FIELD.RECEIVER_ID]) {
        requestData[EDO_STATEMENT_LOADING_FIELD.RECEIVER_ID] = form[EDO_STATEMENT_LOADING_FIELD.RECEIVER_ID];
    }

    if (form[EDO_STATEMENT_LOADING_FIELD.DESCRIPTION]) {
        requestData[EDO_STATEMENT_LOADING_FIELD.DESCRIPTION] = handleFormString(form[EDO_STATEMENT_LOADING_FIELD.DESCRIPTION]);
    }

    if (form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_DATE_TIME]) {
        requestData[EDO_STATEMENT_LOADING_FIELD.STATEMENT_DATE_TIME] = form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_DATE_TIME].toJSON();
    }

    if (form.typeLoading === STATEMENT_LOADING_TYPE.SIMPLE && !isEmpty(form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE])) {
        requestData[EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE] = form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE][0];
    }

    if (form[EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE] && !isEmpty(form[EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE])) {
        requestData[EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE] = form[EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE][0];
    }

    if (form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE] && !isEmpty(form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE])) {
        requestData[EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE] = form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE];
    }

    if (form[EDO_STATEMENT_LOADING_FIELD.APPROVED_IDS]) {
        requestData[EDO_STATEMENT_LOADING_FIELD.APPROVED_IDS] = form.approverIds;
    }

    if (form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_ID]) {
        requestData[EDO_STATEMENT_LOADING_FIELD.STATEMENT_ID] = form.statementId;
    }

    if (code) {
        requestData.smsCode = code;
    }

    if (!isEmpty(params)) {
        const _params = {
            ...params,
            statementDate: dateFormat(form.statementDateTime, "yyyy-MM-dd"),
            period: params.period ? dateFormat(params.period, "yyyy-MM-dd") : undefined,
            advanceAmount: params.advanceAmount ? getNumberFromFormattedAmount(params.advanceAmount) : undefined,
            loanAmount: params.advanceAmount ? getNumberFromFormattedAmount(params.loanAmount) : undefined,
            statementNumber: params.statementNumber ? params.statementNumber : undefined,
            subjectRU: params.subjectRU ? params.subjectRU : undefined,
        };
        if (_params.amountOfIncome) {
            _params.amountOfIncome = getNumberFromFormattedAmount(_params.amountOfIncome);
        }

        if (templateType) {
            _params.type = templateType;
        }

        requestData.params = _params;
    }

    return requestData;
}

export function mapOrderTemplateDataToFormData(form) {
    const data = {
        ...form,
        [EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE]: undefined,
        [EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE]: undefined,
    };

    const formData = new FormData();

    formData.append("uploadModel", new Blob([JSON.stringify(data)], {
        type: "application/json",
    }));

    if (!isEmpty(form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE])) {
        formData.append(EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE, form[EDO_STATEMENT_LOADING_FIELD.STATEMENT_FILE]);
    }

    if (!isEmpty(form[EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE])) {
        formData.append(EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE, form[EDO_STATEMENT_LOADING_FIELD.ATTACHMENT_FILE]);
    }

    return formData;
}

export function mapTemplateParams(params, templateType, statementDateTime) {
    return {
        ...params,
        type: templateType,
        statementDate: dateFormat(statementDateTime, "yyyy-MM-dd"),
        period: params.period ? dateFormat(params.period, "yyyy-MM-dd") : undefined,
        amountOfIncome: params.amountOfIncome ? getNumberFromFormattedAmount(params.amountOfIncome) : undefined,
        advanceAmount: params.advanceAmount ? getNumberFromFormattedAmount(params.advanceAmount) : undefined,
        loanAmount: params.loanAmount ? getNumberFromFormattedAmount(params.loanAmount) : undefined,
        statementNumber: params.statementNumber ? params.statementNumber : undefined,
        subjectRU: params.subjectRU ? params.subjectRU : undefined,
    };
}
