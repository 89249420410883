import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../utils/toastHelper";

import {BANK_TYPES_CONTRACTOR_FINANCE} from "../../../constants/contractorFinance";
import {ADMIN, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_MANAGER} from "../../../constants/roles";
import {STATUS_OF_TRANSFER} from "../../../constants/status";
import {COMPONENT} from "../../ActualComponents/MediaControls/constants";

import {
    cancelContractorSmzTaxOffer,
    changeOpenWithdrawalMoneyOfWalletInBank,
    setContractorSmzTaxOffer,
} from "../../../ducks/contractorFinanceBalance";
import {downloadDocument} from "../../../ducks/documents";

export const useSystemTransactionsAction = (props) => {
    const {
        contractorId,
        taxPaymentInfo,
        role,
        withdrawalMoneyOfWalletInBank,
        fetchInfo = () => {},
        clientId,
    } = props;

    const [confirmData, setConfirmData] = useState({});
    const [logModalData, setLogModalData] = useState({});
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onOpenConfirm = (confirmData) => {
        setConfirmData(confirmData);
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    const onCancelAutoTax = () => {
        dispatch(cancelContractorSmzTaxOffer({
            contractorId,
            onSuccess: () => {
                fetchInfo();
                toastSuccess(`${taxPaymentInfo.smzTaxOffer ? "Выключение" : "Включение"} автоматическаой уплаты налогов успешно отменено `);
            },
        }));
    };

    const onChangeAutoTax = (isAutoChecked) => {
        dispatch(setContractorSmzTaxOffer({
            contractorId,
            smzTaxOffer: isAutoChecked,
            onSuccess: () => {
                onCloseConfirm();
                fetchInfo();
            },
        }));
    };

    const onChangeOpenWithdrawalMoneyOfWalletInBank = () => {
        dispatch(changeOpenWithdrawalMoneyOfWalletInBank({
            contractorId,
            onSuccess: () => {
                onCloseConfirm();
                fetchInfo();
                toastSuccess(
                    withdrawalMoneyOfWalletInBank ?
                        "Возможность вывода средств с кошелька на период с 9 по 20 число месяца успешно заблокирована" :
                        "Возможность вывода средств с кошелька на период с 9 по 20 число месяца успешно активирована",
                );
            },
        }));
    };

    const onDownload = ({downloadLink, isDownload, contractorName}) => {
        dispatch(downloadDocument({
            isDownload,
            downloadLink,
            fileName: contractorName && `${t("system-transactions.transfer-document-name")} ${contractorName}`,
        }));
    };

    const onClickActionItem = (option, transaction) => {
        const {
            value: action,
        } = option;

        const {
            transactionId,
            fio,
        } = transaction;

        switch (action) {
        case "download-check": {
            onDownload({
                isDownload: true,
                downloadLink: `/api/documents/receipt/paymentOrTransactionReceipt?transactionId=${transactionId}`,
                contractorName: fio,
            });

            return;
        }
        default:
            return;
        }
    };

    const getOptions = (status) => {
        const option = {
            downloadCheck: {
                key: "download-check",
                text: t("system-transactions.transfer-document-name"),
                value: "download-check",
            },
        };

        if ([
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
        ].includes(role) && status === STATUS_OF_TRANSFER.FINISHED) {
            return [
                option["downloadCheck"],
            ];
        }

        return null;
    };

    const getMediaControls = (param) => {
        const {
            atolUrl,
            systemTransactionReceiptUrl,
            bankType,
            transactionId,
            commissionAmount = 0,
            naimixRewardReceiptUrl,
        } = param || {};

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: systemTransactionReceiptUrl,
                                isDownload: true,
                            });
                        },
                        children: "Квитанция о перечислении денежных средств",
                    },
                    visible: Boolean(systemTransactionReceiptUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: naimixRewardReceiptUrl,
                                isDownload: true,
                            });
                        },
                        children: "Квитанция о вознаграждении Наймикс",
                    },
                    visible: Boolean(naimixRewardReceiptUrl) && commissionAmount !== 0,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onDownload({
                                downloadLink: atolUrl,
                                isDownload: true,
                            });
                        },
                        children: "Чек АТОЛ",
                    },
                    visible: Boolean(atolUrl),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => setLogModalData({
                            clientId,
                            transactionId,
                        }),
                        children: "Лог взаимодействия с банком",
                    },
                    visible: bankType !== BANK_TYPES_CONTRACTOR_FINANCE.PLUTONIUM_W1,
                },
            ],
        };
    };

    return {
        confirmData,
        getOptions,
        onOpenConfirm,
        onCloseConfirm,
        onCancelAutoTax,
        onChangeAutoTax,
        onClickActionItem,
        onChangeOpenWithdrawalMoneyOfWalletInBank,
        onDownload,
        getMediaControls,
        logModalData,
        setLogModalData,
    };
};