import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {ReactComponent as IconCheckbox} from "../../../../../images/check_box_24.svg";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {CROWD_TASK_STATUS} from "../../../../../constants/crowd/task";
import {NM_CHIEF_ACCOUNTANT, NM_OPERATOR} from "../../../../../constants/roles";

import {closeCrowdTask} from "../../../../../ducks/bff/crowd/tasks/actionCreators";
import {downloadDocument} from "../../../../../ducks/documents";

const useCrowdTaskCardAction = (props) => {
    const {
        clientId,
        taskId,
        onOpenConfirm,
        onCloseConfirm,
        fetchCard,
        card,
    } = props;

    const dispatch = useDispatch();

    const [editFormData, setEditFormData] = useState({});
    const [successPublishModalData, setSuccessPublishModalData] = useState({});
    const [extendModalData, setExtendModalData] = useState({});

    const role = ls(USER_ROLE);
    const isAccessAction = ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);

    const getMediaButtons = () => {
        return ({
            renderCount: {
                desktop: 1,
                tablet: 1,
                mobile: 0,
            },
            size: "xl",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenConfirm({
                            content: `Вы действительно хотите завершить задание "${card?.name || ""}"?"`,
                            onConfirm: closeTask,
                        }),
                        children: "Завершить задание",
                        color: "green",
                        icon: <IconCheckbox />,
                    },
                    visible: card.status === CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE && !card.workingContractorsCount && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => setEditFormData({
                            isOpen: true,
                            isDuplicate: true,
                            editData: card,
                        }),
                        children: "Создать дубликат",
                    },
                    visible: isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => setExtendModalData({
                            clientId,
                            taskId,
                            workEndDate: card.workEndDate,
                            onSuccess: () => {
                                fetchCard();
                                toastSuccess("Период выполнения задания продлён");
                                setExtendModalData({});
                            },
                        }),
                        children: "Продлить период выполнения задания",
                    },
                    visible: card.status === CROWD_TASK_STATUS.CONTRACTOR_SEARCH.VALUE && isAccessAction,
                },
            ],
        });
    };

    const downloadFile = (fileName, downloadLink) => {
        dispatch(downloadDocument({
            isDownload: true,
            fileName: `${fileName}.pdf`,
            downloadLink,
        }));
    };

    const closeTask = () => {
        dispatch(closeCrowdTask({
            clientId,
            taskId,
            onSuccess: () => {
                fetchCard();
                onCloseConfirm();
            },
        }));
    };

    return {
        downloadFile,
        getMediaButtons,
        editFormData,
        setEditFormData,
        successPublishModalData,
        setSuccessPublishModalData,
        extendModalData,
        setExtendModalData,
    };
};

export default useCrowdTaskCardAction;