import React from "react";
import {useDispatch, useSelector} from "react-redux";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";

import {
    submitRegistryOfAdditionalAgreement,
    updateRegistryOfAdditionalAgreementStore,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/actionCreators";
import {
    bffAdditionalAgreementRegistryWarningsDataSelector,
    bffAdditionalAgreementRegistryWarningsPageDataSelector,
} from "../../../../../ducks/bff/documents/additional-agreements/registry/selectors";

export const DocumentsAdditionalAgreementsWarnings = () => {
    const dispatch = useDispatch();

    const warnings = useSelector(bffAdditionalAgreementRegistryWarningsDataSelector);
    const signPageData = useSelector(bffAdditionalAgreementRegistryWarningsPageDataSelector);

    if (!warnings) {
        return null;
    }

    const {
        content,
        warning,
    } = warnings;

    return (
        <NmConfirmV2
            content={content}
            warning={warning}
            onCancel={() => {
                dispatch(updateRegistryOfAdditionalAgreementStore({
                    warnings: null,
                    warningsPageData: null,
                }));
            }}
            onConfirm={() => {
                const {
                    id,
                    isFetchCard,
                    isFetchList,
                } = signPageData;

                dispatch(submitRegistryOfAdditionalAgreement({
                    id,
                    isFetchCard,
                    isFetchList,
                }));
            }}
            cancelButton="Отменить"
            confirmButton="Подтвердить"
        />
    );
};