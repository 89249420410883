import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {bffAdditionalAgreementsStatusDictOptionsSelector} from "../../../../ducks/bff/documents/additional-agreements/selectors";

export const useAdditionalAgreementsFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const statusDictOptions = useSelector(bffAdditionalAgreementsStatusDictOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Название",
                        placeholder: "Введите название",
                        name: "nameSubstringFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "statusFilter",
                        options: statusDictOptions,
                        label: "Статус",
                        placeholder: "Выберите статус",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFromFilter",
                        endFieldName: "creationDateTimeToFilter",
                        label: "Период создания",
                        isClearable: true,
                    },
                ],
            },
        ];
    }, [
        statusDictOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};