import {useState} from "react";

import {ls} from "../../../../../../../utils/localstorage";

export const useOrderActRegistryEditPaymentPrompt = ({key}) => {
    const [isPromptOn, setPromptOn] = useState(false);

    const onOpenPrompt = () => {
        ls(key, !isPromptOn);

        setPromptOn(prevState => !prevState);
    };

    return {
        isPromptOn,
        onOpenPrompt,
    };
};