import React from "react";

import NmModal from "../../../components/ActualComponents/NmModal";
import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";
import {LoginButton} from "../button";

import "./style.sass";

const LoginSuccessNotification = (props) => {
    const {
        title,
        buttonText,
        description,
        onSubmit,
        onClose,
    } = props;

    return (
        <NmModal
            openNaimix
            onClose={onClose}
            className="login-success-notification"
        >
            <div className="login-success-notification__content">
                <div className="login-success-notification__icon-container mb-4 mb-md-5">
                    <DoneIcon className="login-success-notification__icon" />
                </div>
                <div className="login-success-notification__title mb-2 mb-md-3">
                    {title}
                </div>
                {
                    description &&
                    <div className="login-success-notification__message mb-4 mb-md-5">
                        {description}
                    </div>
                }
                <LoginButton
                    primary
                    size="xl"
                    className="login-success-notification__button"
                    onClick={onSubmit}
                >
                    {buttonText}
                </LoginButton>
            </div>
        </NmModal>
    );
};

export default LoginSuccessNotification;