import {createSelector} from "reselect";

import {getTotalPages} from "../../utils/mathHelper";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../../components/ProjectsObjectsCheckboxList/constants";

export const smartLinkSelector = state => state.smartLink;
export const smartLinkListSelector = createSelector(
    smartLinkSelector,
    ({list}) => list,
);
export const smartLinkTotalCountSelector = createSelector(
    smartLinkSelector,
    ({totalCount}) => totalCount,
);
export const smartLinkTotalPagesSelector = createSelector(
    smartLinkSelector,
    ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize),
);
export const smartLinkProgressSelector = createSelector(
    smartLinkSelector,
    ({progress}) => progress,
);
export const smartLinkProgressActionSelector = createSelector(
    smartLinkSelector,
    ({progressAction}) => progressAction,
);
export const smartLinkCardSelector = createSelector(
    smartLinkSelector,
    ({card}) => card,
);
export const smartLinkProgressCardSelector = createSelector(
    smartLinkSelector,
    ({progressCard}) => progressCard,
);
export const smartLinkProgressCheckSelector = createSelector(
    smartLinkSelector,
    ({progressCheck}) => progressCheck,
);
export const smartLinkErrorNumberExistsSelector = createSelector(
    smartLinkSelector,
    ({errorOrderExists}) => errorOrderExists,
);
export const smartLinkErrorNumbersSelector = createSelector(
    smartLinkSelector,
    ({numbers}) => numbers.filter(item => item.errorMessage),
);
export const smartLinkSuccessNumbersListSelector = createSelector(
    smartLinkSelector,
    ({numbers}) => numbers.filter(item => !item.errorMessage).map(item => item.num),
);
export const smartLinkCountSelector = createSelector(
    smartLinkSelector,
    ({count}) => count,
);
export const smartLinkProgressCountSelector = createSelector(
    smartLinkSelector,
    ({progressCount}) => progressCount,
);

export const smartLinkProjectsSelector = createSelector(
    smartLinkSelector,
    ({projects}) => {
        return {
            ...projects,
            list: projects.list.map(item => {
                return {
                    ...item,
                    boolParam: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                    isPartial: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
                };
            }),
            totalPages: getTotalPages(projects.totalCount, projects.pageData.pageSize),
        };
    });

export const smartLinkObjectsSelector = createSelector(
    smartLinkSelector,
    ({objects}) => objects,
);

export const smartLinkProjectsAndObjectsSelector = createSelector(
    smartLinkSelector,
    ({projectsAndObjects}) => projectsAndObjects,
);

export const smartLinkProgressSearchSelector = createSelector(
    smartLinkSelector,
    ({progressSearch}) => progressSearch,
);

export const smartLinkDetailsTreeSelector = createSelector(
    smartLinkSelector,
    ({detailsTree}) => detailsTree,
);

export const smartLinkDetailsTreeProgressSelector = createSelector(
    smartLinkSelector,
    ({progressClientSettings}) => progressClientSettings,
);

export const smartLinkListOptionsSelector = createSelector(
    smartLinkSelector,
    ({list}) => {
        return list.map(item => {
            return {
                key: item.smartLinkId,
                value: item.smartLinkId,
                text: item.name,
            };
        });
    },
);

export const smartLinkErrorSelector = createSelector(
    smartLinkSelector,
    ({error}) => error,
);

export const smartLinkCachedClientsSelector = createSelector(
    smartLinkSelector,
    ({clients}) => clients,
);

export const smartLinkCachedClientIdsSelector = createSelector(
    smartLinkSelector,
    ({clients}) => clients.map(item => item.clientId),
);

export const smartLinkProgressClientIdSelector = createSelector(
    smartLinkSelector,
    ({progressClientId}) => progressClientId,
);

export const smartLinkProgressClearAllSelector = createSelector(
    smartLinkSelector,
    ({progressClearAll}) => progressClearAll,
);