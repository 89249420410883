import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const GET_DADATA_COORDS_BY_ADDRESS_REQUEST = "GET_DADATA_COORDS_BY_ADDRESS_REQUEST";
const GET_DADATA_COORDS_BY_ADDRESS_SUCCESS = "GET_DADATA_COORDS_BY_ADDRESS_SUCCESS";
const GET_DADATA_COORDS_BY_ADDRESS_ERROR = "GET_DADATA_COORDS_BY_ADDRESS_ERROR";

const GET_DADATA_ADDRESS_BY_COORDS_REQUEST = "GET_DADATA_ADDRESS_BY_COORDS_REQUEST";
const GET_DADATA_ADDRESS_BY_COORDS_SUCCESS = "GET_DADATA_ADDRESS_BY_COORDS_SUCCESS";
const GET_DADATA_ADDRESS_BY_COORDS_ERROR = "GET_DADATA_ADDRESS_BY_COORDS_ERROR";

const UPDATE_GEO_DATA_STORE = "UPDATE_GEO_DATA_STORE";

const initial = {
    dadataGeoData: [{}],
    nominatimGeoData: {},
    progress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case UPDATE_GEO_DATA_STORE:
        return {
            ...state,
            ...payload,
        };
    case GET_DADATA_ADDRESS_BY_COORDS_SUCCESS:
        return {
            ...state,
            nominatimGeoData: payload,
            progress: false,
        };
    case GET_DADATA_ADDRESS_BY_COORDS_REQUEST:
    case GET_DADATA_COORDS_BY_ADDRESS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_DADATA_COORDS_BY_ADDRESS_SUCCESS:
        return {
            ...state,
            dadataGeoData: payload,
            progress: false,
        };
    case GET_DADATA_ADDRESS_BY_COORDS_ERROR:
    case GET_DADATA_COORDS_BY_ADDRESS_ERROR:
        return {
            ...state,
            progress: false,
        };
    default: return state;
    }
};

export const updateGeoDataStore = payload => ({
    type: UPDATE_GEO_DATA_STORE,
    payload,
});

export const getAddressByCoords = payload => ({
    type: GET_DADATA_ADDRESS_BY_COORDS_REQUEST,
    payload,
});

export const getCoordsByAddress = payload => ({
    type: GET_DADATA_COORDS_BY_ADDRESS_REQUEST,
    payload,
});

const dadataSelector = ({dadata}) => dadata;

export const dadataGeoDataSelector = createSelector(dadataSelector, ({dadataGeoData}) => dadataGeoData);
export const nominatimGeoDataSelector = createSelector(dadataSelector, ({nominatimGeoData}) => nominatimGeoData);

export const getCoordsByAddressSaga = function* ({payload}) {
    try {
        const result = yield request.post("dadata/clean/address", payload, {mode: "cors"});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DADATA_COORDS_BY_ADDRESS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_DADATA_COORDS_BY_ADDRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DADATA_COORDS_BY_ADDRESS_ERROR,
            payload: error,
        });
    }
};

export const getAddressByCoordsSaga = function* ({payload}) {
    try {
        const {
            lat,
            lng,
            onSuccess,
        } = payload;

        const url = `https://nominatim.openstreetmap.org/reverse?accept-language=ru-RU,ru&lat=${lat}&lon=${lng}&format=json`;

        const options = {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        };

        yield fetch(url, options)
            .then(response => response.json())
            .then(result => {
                const {error} = result;

                if (!error) {
                    if (onSuccess) {
                        onSuccess(result);
                    }

                    put({
                        type: GET_DADATA_ADDRESS_BY_COORDS_SUCCESS,
                        payload: result,
                    });
                } else {
                    put({
                        type: GET_DADATA_ADDRESS_BY_COORDS_ERROR,
                    });
                }
            })
            .catch(error => {
                put({
                    type: GET_DADATA_ADDRESS_BY_COORDS_ERROR,
                });
            });
    } catch (error) {
        yield put({
            type: GET_DADATA_ADDRESS_BY_COORDS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_DADATA_COORDS_BY_ADDRESS_REQUEST, getCoordsByAddressSaga),
        takeEvery(GET_DADATA_ADDRESS_BY_COORDS_REQUEST, getAddressByCoordsSaga),
    ]);
}