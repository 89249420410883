import React from "react";

import Tabs from "../../../../components/ActualComponents/Tabs";

import {useContractorFinanceTabs} from "./hooks/useTabs";

const ContractorCardFinancesTabs = (props) => {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const {
        getLinks,
    } = useContractorFinanceTabs({contractorId});

    return (
        <Tabs
            {...props}
            secondary
            panes={getLinks()}
        />
    );
};

export default ContractorCardFinancesTabs;