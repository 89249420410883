import {STATUS_PAYMENT} from "../../../../constants/status";

export const getPaymentStatusChecksFailed = (paymentStatus) => {
    if (
        [
            STATUS_PAYMENT.CONFIRMATION_ERROR,
            STATUS_PAYMENT.NOT_CONFIRMED_ERROR,
        ].includes(paymentStatus)
    ) {
        return "Проверки не пройдены";
    }
};