import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {CURRENT_CLIENT_ID, ls} from "../../../../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../utils/toastHelper";

import {addNote, updateNote} from "../../../../../../ducks/contractor";

export const useNoteAction = ({contractorId, noteInitial = ""}) => {
    const [note, setNote] = useState(noteInitial);
    const [isEditNote, setEditNote] = useState(false);

    const currentClientId = ls(CURRENT_CLIENT_ID);

    const dispatch = useDispatch();

    useEffect(() => {
        setNote(noteInitial);
    }, [noteInitial]);

    const handleSubmitNote = () => {
        const data = {
            contractorId,
            clientId: currentClientId,
            text: note,
            onSuccess: () => {
                setEditNote(false);
                !isNullOrWhitespace(noteInitial) && toastSuccess("Заметка успешно изменена");
            },
        };

        isNullOrWhitespace(noteInitial) ? dispatch(addNote(data)) : dispatch(updateNote(data));
    };

    const deleteNote = () => {
        setNote("");
        dispatch(updateNote({
            contractorId,
            clientId: currentClientId,
            text: "",
            onSuccess: () => {
                setEditNote(false);
                toastSuccess("Заметка успешно удалена");
            },
        }));
    };

    return {
        note,
        setNote,
        isEditNote,
        setEditNote,
        handleSubmitNote,
        deleteNote,
    };
};