import React from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import ExtLink from "../../../../../components/ExtLink";
import NmTitle from "../../../../../components/NmTitle";
import {styleLink} from "../../../../../styles/inline";

import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";
import {useFetchOrderTemplatesObjectList} from "./hooks/useFetchList";

import bem from "../../../../../utils/bem";

import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../../../../constants/links";

import "./style.sass";

const initFilter = {
    nameSubstringFilter: "",
};

const OrderTemplatesObjectList = (props) => {
    const {
        className = "",
        onClose,
        isProjectList = false,
        clientId,
        projectIdsFilter,
        objectIdsFilter,
    } = props;

    const [block, element] = bem("order-templates-object-list", className);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-modal-v2");

    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({initFilter});

    const {
        list = [],
        totalCount,
        totalPages,
        progressList,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useFetchOrderTemplatesObjectList({
        clientId,
        isProjectList,
        projectIdsFilter,
        objectIdsFilter,
        pageNum,
        pageSize,
    });

    const submitFilter = () => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setFilter({...initFilter});
        setFilterDto({...initFilter});
        setIsSearch(false);
    };

    function renderLink(link, content) {
        return (
            <ExtLink
                className={element("object-list-item")}
                historyEnabled
                to={link}
                style={styleLink}
            >
                {content}
            </ExtLink>
        );
    }

    function getObjectName(item) {
        if (isProjectList) {
            const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
                .replace(":clientId", item.clientId)
                .replace(":projectId", item.projectId);

            return renderLink(projectLink, item.name);
        }

        const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":projectId", item.projectId)
            .replace(":objectId", item.objectId)
            .replace(":clientId", item.clientId);

        return renderLink(objectLink, item.name);
    }

    function renderFilter() {
        const objectTypeText = isProjectList ? "проекта" : "объекта";

        return (
            <NmForm
                horizontal
                className={element("filter")}
            >
                <div className={element("filter-input")}>
                    <NmInputV2
                        size="lg"

                        label={`Наименование ${objectTypeText}`}
                        placeholder={`Введите наименование ${objectTypeText}`}
                        onChange={onChangeFilter}
                        name="nameSubstringFilter"
                        value={filter.nameSubstringFilter}
                    />
                </div>
                <FilterButtonsV2
                    onSearch={submitFilter}
                    onClear={clearFilter}
                />
            </NmForm>
        );
    }

    return (
        <NmModal
            size="md"
            header={
                <NmTitle
                    className={element("title")}
                    size="md"
                >
                    {isProjectList ? "Проект" : "Объект"}
                </NmTitle>
            }
            className={block()}
            onClose={onClose}
            loading={progressList}
        >
            <div className={element("content")}>
                {renderFilter()}
                {
                    list.length ?
                        <div className="flex flex-column flex-aligned-start">
                            {list.map(item => getObjectName(item))}
                        </div> :
                        <NmEmptyPageV2
                            isSearch={isSearch}
                        />
                }
                <NmPagination
                    className={element("pagination")}
                    totalCount={totalCount}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    onChangePageSize={onChangePageSize}
                    onChangePagination={onPaginationChange}
                    isHasPageSize={false}
                />
            </div>
        </NmModal>
    );
};

export default OrderTemplatesObjectList;