import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../components/ActualComponents/NmRadioV2";
import NmTitle from "../../../../../components/NmTitle";
import {KedoStaffRegistryAddFromExcel} from "../add-from-excel";
import {KedoStaffRegistryAddManual} from "../add-manual";

import {getFullName} from "../../../../../utils/stringFormat";

import {getKedoStaffById, updateKedoStaffStore} from "../../../../../ducks/kedo/staff/actionCreators";
import {bffKedoStaffCardSelector, bffKedoStaffProgressCardSelector} from "../../../../../ducks/kedo/staff/selectors";

const SOURCE = {
    MANUAL: "MANUAL",
    EXCEL: "EXCEL",
};

export const KedoStaffRegistryEdit = (props) => {
    const {
        onClose,
        clientId,
        kedoStaffId,
        fetchList,
        isViewMode,
    } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(bffKedoStaffProgressCardSelector);
    const card = useSelector(bffKedoStaffCardSelector);

    const isEdit = Boolean(kedoStaffId);

    useEffect(() => {
        if (isEdit) {
            dispatch(getKedoStaffById({
                clientId,
                kedoStaffId,
            }));
        }

        return () => {
            dispatch(updateKedoStaffStore({
                card: {},
            }));
        };
    }, []);

    const [source, setSource] = useState(isEdit ? SOURCE.MANUAL : SOURCE.EXCEL);

    const [refFooter, setRef] = useState(null);

    const getFooter = () => {
        return (
            <div ref={setRef} />
        );
    };

    const getHeader = () => {
        if (isViewMode) {
            return getFullName(
                card.lastName,
                card.firstName,
                card.patronymic,
            );
        }

        if (isEdit) {
            return "Редактирование сотрудника";
        }

        return "Добавление сотрудника";
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            className="member-edit"
            header={
                <NmTitle size="lg">
                    {getHeader()}
                </NmTitle>
            }
            footer={
                getFooter()
            }
            loading={isLoading}
            classNameContent="flex-column"
        >
            {
                !isEdit &&
                <div className="d-flex mb-3 mb-md-4">
                    <NmRadioV2
                        label="Загрузить из Excel-файла"
                        checked={source === SOURCE.EXCEL}
                        className="me-4"
                        value={SOURCE.EXCEL}
                        onChange={() => {
                            setSource(SOURCE.EXCEL);
                        }}
                    />
                    <NmRadioV2
                        label="Ввести данные"
                        checked={source === SOURCE.MANUAL}
                        value={SOURCE.MANUAL}
                        onChange={() => {
                            setSource(SOURCE.MANUAL);
                        }}
                    />
                </div>
            }
            {
                source === SOURCE.EXCEL &&
                <KedoStaffRegistryAddFromExcel
                    clientId={clientId}
                    refFooter={refFooter}
                    onClose={onClose}
                    fetchList={fetchList}
                />
            }
            {
                source === SOURCE.MANUAL &&
                <KedoStaffRegistryAddManual
                    isViewMode={isViewMode}
                    isEdit={isEdit}
                    kedoStaffId={kedoStaffId}
                    onClose={onClose}
                    card={card}
                    fetchList={fetchList}
                    refFooter={refFooter}
                    clientId={clientId}
                />
            }
        </NmModal>
    );
};