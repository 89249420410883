import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import useCrowdTaskGroupEditForm from "./hooks/useForm";

import {bffCrowdTaskGroupsProgressActionSelector} from "../../../../../ducks/bff/crowd/taskGroups/selectors";

const CrowdTaskGroupEditForm = (props) => {
    const {
        clientId,
        onClose,
        fetchList,
        editData = {},
    } = props;

    const actionProgress = useSelector(bffCrowdTaskGroupsProgressActionSelector);
    const isEdit = !isEmpty(editData);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
    } = useCrowdTaskGroupEditForm({
        clientId,
        onClose,
        fetchList,
        isEdit,
        editData,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование группы заданий" : "Создание группы заданий"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={isEdit ? "Сохранить" : "Создать"}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={actionProgress}
        >
            <NmForm addMargin={true}>
                <NmTextareaV2
                    required
                    value={values.name}
                    name="name"
                    label="Название"
                    placeholder="Введите название группы заданий"
                    maxLength={100}
                    onChange={handleChange}
                    error={touched?.name && errors?.name}
                />
                <NmTextareaV2
                    value={values.comment}
                    name="comment"
                    label="Комментарий"
                    placeholder="Введите комментарий"
                    maxLength={255}
                    onChange={handleChange}
                />
            </NmForm>
        </NmModal>
    );
};

export default CrowdTaskGroupEditForm;

