import React from "react";

import bem from "../../../../utils/bem";

import "./style.sass";

const OrderCardHeaderLabelText = (props) => {
    const {
        className = "",
        noWrap,
        name,
        customValue,
        value,
    } = props;

    const [block, element] = bem("order-card-header-label-text", className);

    return (
        <div className={block({noWrap})}>
            <span className={element("name")}>
                {name}
            </span>
            {
                customValue ?
                    customValue :
                    <span className={element("value", {noWrap})}>
                        {value}
                    </span>
            }
        </div>
    );
};

export default OrderCardHeaderLabelText;