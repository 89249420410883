import {useEffect, useRef} from "react";

export const useGetPrevValue = (value) => {
    const prevValueRef = useRef();

    useEffect(() => {
        prevValueRef.current = value;
    });

    return prevValueRef.current;
};