export const TARIFF_GRID_ERROR_CODE = {
    // найден дубликат
    TARIFF_DUPLICATE_EXIST: "TARIFF_DUPLICATE_EXIST",
};

export const tarrifGridHeaders = [
    {
        key: "lastUpdateDate",
        content: "Дата обновления",
    },
    {
        key: "addressInfo",
        content: "Город",
    },
    {
        key: "specialityName",
        content: "Вид деятельности",
    },
    {
        key: "rate",
        content: "Ставка",
    },
    {
        key: "unit",
        content: "Единица измерения",
    },
    {
        key: "action",
        content: "",
    },
];