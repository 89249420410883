import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {history} from "../../../store/configureStore";
import PromoCodeChannelNew from "../promocode-channel-new";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import dateFormat from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {headersPromoCodeChannelList as headers} from "../../../constants/headersTable";
import {LINK_PROMOCODE_CHANNEL_CARD, LINK_PROMOCODE_CHANNEL_LIST} from "../../../constants/links";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
} from "../../../constants/roles";

import {
    getPromocodeChannelList,
    promocodeChannelListSelector, 
    promocodeChannelPageDataSelector, 
    promocodeChannelTotalCountSelector,
    promocodeChannelTotalPagesSelector, 
    promocodeGetPageProgressSelector, 
    updatePromocodeChannel,
} from "../../../ducks/promocode";

import PropTypes from "prop-types";

import "./style.sass";

class PromoCodeChannelList extends Component {
    static propTypes = {
        channelList: PropTypes.arrayOf(),
        getPromocodeChannelList: PropTypes.func,
    };

    static defaultProps = {
        channelTotalPages: 1,
        getPromocodeChannelList: () => {
        },
        progress: true,
    };

    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];

        this.role = ls(USER_ROLE);
        this.isEditable = ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role);

        this.state = {
            pageNum: 1,
            pageSize: this.pageSizes[0],
            formFilter: {
                nameFilter: "",
                promocodeFilter: "",
            },
            isOpenChannelNew: false,
            channel: {},
            archived: false,
        };
    }

    componentDidMount() {
        const {pageData} = this.props;
        const {pageNum = 1, pageSize = 25} = pageData;

        const {location: {search, state}} = this.props;


        if (search.indexOf("?archived=true") !== -1 && state) {
            this.setState(prevState => ({
                ...prevState,
                pageNum: state.pageNum,
                pageSize: state.pageSize,
                archived: true,
            }), this.fetchPromocodeChannelList);
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageNum,
            pageSize,
        }), this.fetchPromocodeChannelList);
    }

    componentDidUpdate(prevProps) {
    }

    fetchPromocodeChannelList = () => {
        const {getPromocodeChannelList} = this.props;

        const {
            pageNum,
            pageSize,
            formFilter:{
                nameFilter,
                promocodeFilter,
            },
            archived,
        } = this.state;

        getPromocodeChannelList({
            archived,
            pageNum,
            pageSize,
            nameFilter: !isNullOrWhitespace(nameFilter) ? nameFilter : undefined,
            promocodeFilter: !isNullOrWhitespace(promocodeFilter) ? promocodeFilter : undefined,
        });
    };

    handleOnChangePageSize = pageSize => {
        this.setState({
            pageSize,
            pageNum: 1,
        }, this.fetchPromocodeChannelList);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;
        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageNum,
        }), this.fetchPromocodeChannelList);
    };

    handleOpenChannelNew = () => {
        this.setState({
            isOpenChannelNew: true,
        });
    };

    changeArchive = () => {
        const {channel} = this.state;
        const {updatePromocodeChannel} = this.props;

        updatePromocodeChannel({
            data: {
                ...channel,
                archive: !channel.archive,
            },
            isFetchList: true,
        });

        this.closeConfirm();
    };

    handleCloseChannelNew = () => {
        this.setState({
            isOpenChannelNew: false,
        });
    };

    closeConfirm = () => {
        this.setState({
            isOpenConfirm: false,
            channel: {},
        });
    };

    showConfirm = (channel) => {
        this.setState({
            isOpenConfirm: true,
            channel,
        });
    };

    handleFilter = (e, { name, value }) => {
        this.setState(prevState => ({
            ...prevState,
            formFilter: {
                ...prevState.formFilter,
                [name]: value,
            },
        }));
    };

    submitFilter = () => {
        this.setState({
            pageNum: 1,
        }, this.fetchPromocodeChannelList);
    };

    clearFilter = () => {
        this.setState({
            formFilter: {
                nameFilter: "",
                promocodeFilter: "",
            },
        }, this.fetchPromocodeChannelList);
    };

    renderArchiveButton(item) {
        const {t} = this.props;

        return (
            <NmButton
                color="grey"
                size="lg"
                onClick={() => {
                    this.showConfirm(item);
                }}
            >
                {item.archive ? t("promocode-channel-list.reestablish") : t("promocode-channel-list.to-archive")}
            </NmButton>
        );
    }

    renderConfirmWindow = () => {
        const {
            isOpenConfirm,
            channel,
        } = this.state;
        const {t} = this.props;

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={`${t("promocode-channel-list.really-want")} ${channel.archive ? t("promocode-channel-list.restore-from-archive") : t("promocode-channel-list.add-to-archive")} ${t("promocode-channel-list.channel")} "${channel.name}"?`}
                onCancel={this.closeConfirm}
                onConfirm={this.changeArchive}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    };

    mapListToTableData() {
        const {channelList} = this.props;

        const rows = channelList.map(item => {
            const link = LINK_PROMOCODE_CHANNEL_CARD.replace(":promotionId", item.promotionId);

            return {
                ...item,
                name: <Link to={link}>
                    {item.name}
                </Link>,
                period: `${dateFormat(item.fromDate, "dd.MM.yyyy")} - ${dateFormat(item.toDate, "dd.MM.yyyy")}`,
                active: `${item.activeCount}/${item.count}`,
                action: this.isEditable ?
                    <div className="td-container flex-content-end">
                        {this.renderArchiveButton(item)}
                    </div> : null,
            };
        });

        return {
            headers,
            rows,
        };
    };

    getMediaControls = (item) => {
        const {t} = this.props;

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.showConfirm(item);
                        },
                        color: "grey",
                        children: item.archive ? t("promocode-channel-list.reestablish") : t("promocode-channel-list.to-archive"),
                    },
                    visible: [ADMIN, NM_MANAGER].includes(this.role),
                },
            ],
        };
    };

    getRows() {
        const {channelList} = this.props;

        return channelList.map(item => {
            const {
                promotionId,
                name,
                count,
                activeCount,
                applicationCount,
                fromDate,
                toDate,
            } = item;

            const link = LINK_PROMOCODE_CHANNEL_CARD.replace(":promotionId", promotionId);

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        primaryHeader={name}
                        primaryHeaderLink
                        onClickLink={() => history.push(link)}
                        labels={[
                            {label: "Количество промокодов", text: count},
                            {
                                label: "Период активности",
                                text: `${dateFormat(fromDate, "dd.MM.yyyy")} - ${dateFormat(toDate, "dd.MM.yyyy")}`,
                            },
                            {
                                label: "Активность",
                                text: `${activeCount}/${count}`,
                            },
                            {label: "Количество применений", text: applicationCount},
                        ]}
                        isFixedActions
                        mediaControls={this.getMediaControls(item)}
                    />
                ),
            };
        });
    }

    toggleArchived = () => {
        this.setState(prevState => ({
            ...prevState,
            archived: !prevState.archived,
            pageNum: 1,
        }), () => {
            const {archived, pageNum, pageSize} = this.state;
            if (this.state.archived) {
                history.push({
                    pathname: LINK_PROMOCODE_CHANNEL_LIST,
                    search: "?archived=true",
                    state: {
                        archived,
                        pageNum,
                        pageSize,
                    },
                });
            } else {
                history.push(LINK_PROMOCODE_CHANNEL_LIST);
            }
            this.fetchPromocodeChannelList();
        });
    };

    renderFilter() {
        const {
            formFilter,
        } = this.state;

        return (
            <NmForm addMargin>
                <NmInputV2
                    size="lg"
                    label="Поиск по наименованию канала"
                    onChange={this.handleFilter}
                    name="nameFilter"
                    value={formFilter.nameFilter}
                />
                <NmInputV2
                    size="lg"
                    label="Поиск по промокоду"
                    onChange={this.handleFilter}
                    name="promocodeFilter"
                    value={formFilter.promocodeFilter}
                />
                <FilterButtonsV2
                    onSearch={this.submitFilter}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    getHeaderMediaControls() {
        const {
            progress,
            t,
        } = this.props;

        const {archived} = this.state;

        const archive = getArchiveButton(t, archived, {mobile: true});

        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: this.handleOpenChannelNew,
                        size: "xl",
                        children: t("promocode-channel-list.add-channel"),
                        color: "green",
                        icon: <AddIcon />,
                    },
                    visible: this.isEditable,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: this.toggleArchived,
                        disabled: progress,
                    },
                },
            ],
        };
    }

    render() {
        const {
            channelList, 
            channelTotalPages, 
            t,
            channelTotalCount,
            progress,
        } = this.props;
        const {
            pageNum,
            pageSize,
            isOpenChannelNew,
        } = this.state;

        return (
            <NmPage
                className="promocode-list"
                header={
                    <NmTitle size="xl">
                        {t("promocode-channel-list.promocode-channel-list-title")}
                    </NmTitle>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={channelTotalPages}
                totalCount={channelTotalCount}
                onChangePageSize={this.handleOnChangePageSize}
                typeFilter="vertical"
                filtersBase={this.renderFilter()}
                onPaginationChange={this.handlePaginationChange}
                isLoaded={progress}
                mediaControls={this.getHeaderMediaControls()}
            >
                {isOpenChannelNew ? <PromoCodeChannelNew
                    close={this.handleCloseChannelNew}
                /> : null}
                {this.renderConfirmWindow()}
                {
                    channelList.length !== 0 ?
                        <CheckboxList
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2
                            fetchProgress={progress}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(state => ({
    channelList: promocodeChannelListSelector(state),
    channelTotalPages: promocodeChannelTotalPagesSelector(state),
    channelTotalCount: promocodeChannelTotalCountSelector(state),
    pageData: promocodeChannelPageDataSelector(state),
    progress: promocodeGetPageProgressSelector(state),
    location: state.router.location,
}),
{
    getPromocodeChannelList,
    updatePromocodeChannel,
},
)(withTranslation()(PromoCodeChannelList));
