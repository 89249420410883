import {useMemo, useState} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {getUtcDateFilter} from "../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

export const useObjectContractorsListFilter = ({}) => {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const initFilter = {
        contractorNameFilter: "",
        contractorPhoneFilter: "",
        contractorInnFilter: "",
        creationDateTimeFrom: null,
        creationDateTimeTo: null,
    };

    function mapFilterDto(filter) {
        const {
            contractorNameFilter,
            contractorInnFilter,
            contractorPhoneFilter,
            creationDateTimeFrom,
            creationDateTimeTo,
            statusFilter,
        } = filter;

        return {
            ...filter,
            contractorNameFilter: handleFormString(contractorNameFilter),
            contractorInnFilter: handleFormString(contractorInnFilter),
            contractorPhoneFilter: handleFormString(removePhoneMask(contractorPhoneFilter)),
            creationDateTimeFrom: creationDateTimeFrom ? getUtcDateFilter(creationDateTimeFrom) : undefined,
            creationDateTimeTo: creationDateTimeTo ? getUtcDateFilter(creationDateTimeTo) : undefined,
            statusFilter: statusFilter ? statusFilter : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorNameFilter",
                        label: "ФИО",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFrom",
                        endFieldName: "creationDateTimeTo",
                        isClearable: true,
                        label: "Дата приглашения",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "contractorPhoneFilter",
                        label: "Телефон",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorInnFilter",
                        label: "ИНН",
                    },
                ],
            },
        ];
    }, []);

    return {
        initFilter,
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    };
};