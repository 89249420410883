import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import {ORDER_FIELDS_NAME} from "../../../../../constants/clientList";
import {amountRegexDelimitedOrderType} from "../../../../../constants/validationRules";

import {unitsAbbreviationDictSelector} from "../../../../../ducks/order";

const formatValue = (value) => {
    value = value ? value : "";
    value = typeof value === "number" ? String(value) : value;
    value = value.replace(".", ",");

    return value;
};

const OrderEditFieldVolume = (props) => {
    const {
        value,
        unitValue,
        onChange,
        onFocus,
        isVisibleTooltip,
        ...otherProps
    } = props;
    const [formValue, setFormValue] = useState(formatValue(value || ""));
    const abbreviationMap = useSelector(unitsAbbreviationDictSelector);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (
            !focused
            && value
        ) {
            setFormValue(`${formatValue(value)} ${abbreviationMap.get(unitValue) || ""}`);
        }
    }, [value]);

    useEffect(() => {
        if (
            formValue !== value
            && value === ""
        ) {
            setFormValue("");
        }
    }, [value]);

    useEffect(() => {
        if (!value) {
            return;
        }

        setFormValue(`${formatValue(value)} ${abbreviationMap.get(unitValue) || ""}`);
    }, [unitValue]);

    const isValidAmount = (value) => {
        value = value.replace(/\s+/g, "");

        return amountRegexDelimitedOrderType.test(value);
    };

    const setValue = (event, value) => {
        const element = event.target;
        const caretPosition = event.target.selectionStart;

        setFormValue(value);

        element.selectionStart = caretPosition;
        element.selectionEnd = caretPosition;
    };

    const handleChange = (event, {name, value}) => {
        if (!isValidAmount(value) && value !== "") {
            return;
        }

        setValue(event, value);

        onChange(event, {name, value});
    };

    const onBlur = () => {
        setFocused(false);

        if (value) {
            const value = `${formValue} ${abbreviationMap.get(unitValue) || ""}`;

            setFormValue(value);
        }
    };

    const handleFocus = () => {
        setFocused(true);

        const str = formValue.split(" ");

        if (str.length > 1) {
            setFormValue(str[0]);
        }

        onFocus();
    };

    return (
        <NmInputV2
            {...otherProps}
            size="xl"
            label="Общий объем работ"
            placeholder="0,00"
            name={ORDER_FIELDS_NAME.VOLUME_OF_WORK}
            value={formValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={onBlur}
            tooltip={
                <HelpTooltip
                    hover
                    info
                    text="Укажите объем работ на всех исполнителей"
                    position="bottom"
                />
            }
            isVisibleTooltip={isVisibleTooltip}
        />
    );
};

export default OrderEditFieldVolume;