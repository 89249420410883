import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {downloadBlob} from "../utils/downloadBlob";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/documents/registry/framecontract";
const dictController = "/documents/dicts";

const GET_FC_REGISTRIES_PAGE_REQUEST = "GET_FC_REGISTRIES_PAGE_REQUEST";
const GET_FC_REGISTRIES_PAGE_SUCCESS = "GET_FC_REGISTRIES_PAGE_SUCCESS";
const GET_FC_REGISTRIES_PAGE_ERROR = "GET_FC_REGISTRIES_PAGE_ERROR";

const GET_FC_REGISTRY_BY_ID_REQUEST = "GET_FC_REGISTRY_BY_ID_REQUEST";
const GET_FC_REGISTRY_BY_ID_SUCCESS = "GET_FC_REGISTRY_BY_ID_SUCCESS";
const GET_FC_REGISTRY_BY_ID_ERROR = "GET_FC_REGISTRY_BY_ID_ERROR";

const GET_FC_REGISTRY_ITEMS_PAGE_REQUEST = "GET_FC_REGISTRY_ITEMS_PAGE_REQUEST";
const GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS = "GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS";
const GET_FC_REGISTRY_ITEMS_PAGE_ERROR = "GET_FC_REGISTRY_ITEMS_PAGE_ERROR";

const GET_FC_REGISTRY_STATUS_DICT_REQUEST = "GET_FC_REGISTRY_STATUS_DICT_REQUEST";
const GET_FC_REGISTRY_STATUS_DICT_SUCCESS = "GET_FC_REGISTRY_STATUS_DICT_SUCCESS";
const GET_FC_REGISTRY_STATUS_DICT_ERROR = "GET_FC_REGISTRY_STATUS_DICT_ERROR";

const GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST = "GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST";
const GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS = "GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS";
const GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR = "GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR";

const ARCHIVE_FC_REGISTRY_REQUEST = "ARCHIVE_FC_REGISTRY_REQUEST";
const ARCHIVE_FC_REGISTRY_SUCCESS = "ARCHIVE_FC_REGISTRY_SUCCESS";
const ARCHIVE_FC_REGISTRY_ERROR = "ARCHIVE_FC_REGISTRY_ERROR";

const SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST = "SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST";
const SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS = "SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS";
const SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR = "SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR";

const RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST = "RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST";
const RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS = "RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS";
const RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR = "RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR";

const DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST = "DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST";
const DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS = "DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS";
const DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR = "DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR";

const GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST = "GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST";
const GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS = "GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS";
const GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR = "GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR";

const ADD_ITEM_INTO_FC_REGISTRY_REQUEST = "ADD_ITEM_INTO_FC_REGISTRY_REQUEST";
const ADD_ITEM_INTO_FC_REGISTRY_SUCCESS = "ADD_ITEM_INTO_FC_REGISTRY_SUCCESS";
const ADD_ITEM_INTO_FC_REGISTRY_ERROR = "ADD_ITEM_INTO_FC_REGISTRY_ERROR";

const CANCEL_FC_REGISTRY_ITEM_REQUEST = "CANCEL_FC_REGISTRY_ITEM_REQUEST";
const CANCEL_FC_REGISTRY_ITEM_SUCCESS = "CANCEL_FC_REGISTRY_ITEM_SUCCESS";
const CANCEL_FC_REGISTRY_ITEM_ERROR = "CANCEL_FC_REGISTRY_ITEM_ERROR";

const IMPORT_ITEMS_FC_REGISTRY_REQUEST = "IMPORT_ITEMS_FC_REGISTRY_REQUEST";
const IMPORT_ITEMS_FC_REGISTRY_SUCCESS = "IMPORT_ITEMS_FC_REGISTRY_SUCCESS";
const IMPORT_ITEMS_FC_REGISTRY_ERROR = "IMPORT_ITEMS_FC_REGISTRY_ERROR";

const EXPORT_FC_REGISTRY_REQUEST = "EXPORT_FC_REGISTRY_REQUEST";
const EXPORT_FC_REGISTRY_SUCCESS = "EXPORT_FC_REGISTRY_SUCCESS";
const EXPORT_FC_REGISTRY_ERROR = "EXPORT_FC_REGISTRY_ERROR";

const DELETE_FC_REGISTRY_ITEMS_REQUEST = "DELETE_FC_REGISTRY_ITEMS_REQUEST";
const DELETE_FC_REGISTRY_ITEMS_SUCCESS = "DELETE_FC_REGISTRY_ITEMS_SUCCESS";
const DELETE_FC_REGISTRY_ITEMS_ERROR = "DELETE_FC_REGISTRY_ITEMS_ERROR";

const GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST = "GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST";
const GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS = "GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS";
const GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR = "GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    listProgress: false,
    statusDict: {},
    itemStatusDict: {},
    statusDictProgress: false,
    itemStatusDictProgress: false,
    actionProgress: false,
    registryItemsPageData: {},
    registryItemsProgress: false,
    registryItemsImportProgress: false,
    registryItemsList: [],
    registryItemsTotalCount: 0,
    registryProgress: false,
    card: {},
    fcRegistrySubmittingFlags: {},
    contractorList: [],
    contractorListProgress: false,
    contractorListPageData: {},
    contractorListTotalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST:
        return {
            ...state,
            contractorListPageData: payload.data,
            contractorListProgress: true,
        };
    case GET_FC_REGISTRY_ITEMS_PAGE_REQUEST:
        return {
            ...state,
            registryItemsPageData: payload.data,
            registryItemsProgress: true,
        };
    case GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST:
    case DELETE_FC_REGISTRY_ITEMS_REQUEST:
    case EXPORT_FC_REGISTRY_REQUEST:
    case CANCEL_FC_REGISTRY_ITEM_REQUEST:
    case ADD_ITEM_INTO_FC_REGISTRY_REQUEST:
    case DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST:
    case RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST:
    case SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case GET_FC_REGISTRY_BY_ID_REQUEST:
        return {
            ...state,
            registryProgress: true,
        };
    case GET_FC_REGISTRY_ITEMS_PAGE_REQUEST:
        return {
            ...state,
            registryItemsPageData: payload.data,
            registryItemsProgress: true,
        };
    case ARCHIVE_FC_REGISTRY_REQUEST:
        return {
            ...state,
            actionProgress: true,
        };
    case GET_FC_REGISTRIES_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload.data,
            listProgress: true,
        };
    case GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST:
        return {
            ...state,
            itemStatusDictProgress: true,
        };
    case GET_FC_REGISTRY_STATUS_DICT_REQUEST:
        return {
            ...state,
            statusDictProgress: true,
        };
    case IMPORT_ITEMS_FC_REGISTRY_REQUEST:
        return {
            ...state,
            registryItemsImportProgress: true,
        };
    case GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS:
        return {
            ...state,
            actionProgress: false,
            fcRegistrySubmittingFlags: payload,
        };
    case DELETE_FC_REGISTRY_ITEMS_SUCCESS:
    case EXPORT_FC_REGISTRY_SUCCESS:
    case CANCEL_FC_REGISTRY_ITEM_SUCCESS:
    case ADD_ITEM_INTO_FC_REGISTRY_SUCCESS:
    case DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS:
    case RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS:
    case SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case GET_FC_REGISTRY_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            registryProgress: false,
        };
    case GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS:
        return {
            ...state,
            contractorList: payload.contractors || [],
            contractorListTotalCount: payload.totalCount,
            contractorListProgress: false,
        };
    case GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS:
        return {
            ...state,
            registryItemsList: payload.items || [],
            registryItemsTotalCount: payload.totalCount,
            registryItemsProgress: false,
        };
    case ARCHIVE_FC_REGISTRY_SUCCESS:
        return {
            ...state,
            actionProgress: false,
        };
    case GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS:
        return {
            ...state,
            itemStatusDict: payload,
            itemStatusDictProgress: false,
        };
    case GET_FC_REGISTRY_STATUS_DICT_SUCCESS:
        return {
            ...state,
            statusDict: payload,
            statusDictProgress: false,
        };
    case GET_FC_REGISTRIES_PAGE_SUCCESS:
        return {
            ...state,
            list: payload.registries || [],
            totalCount: payload.totalCount,
            listProgress: false,
        };
    case GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR:
        return {
            ...state,
            error: payload,
            itemStatusDictProgress: false,
        };
    case IMPORT_ITEMS_FC_REGISTRY_SUCCESS:
        return {
            ...state,
            registryItemsImportProgress: false,
        };
    case GET_FC_REGISTRY_STATUS_DICT_ERROR:
        return {
            ...state,
            error: payload,
            statusDictProgress: false,
        };
    case GET_FC_REGISTRIES_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            listProgress: false,
        };
    case GET_FC_REGISTRY_BY_ID_ERROR:
        return {
            ...state,
            error: payload,
            registryProgress: false,
        };
    case GET_FC_REGISTRY_ITEMS_PAGE_ERROR:
        return {
            ...state,
            error: payload,
            registryItemsProgress: false,
        };
    case GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR:
        return {
            ...state,
            error: payload,
            contractorListProgress: false,
        };
    case GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR:
    case DELETE_FC_REGISTRY_ITEMS_ERROR:
    case ADD_ITEM_INTO_FC_REGISTRY_ERROR:
    case DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR:
    case RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR:
    case CANCEL_FC_REGISTRY_ITEM_ERROR:
    case SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR:
    case EXPORT_FC_REGISTRY_ERROR:
    case ARCHIVE_FC_REGISTRY_ERROR:
        return {
            ...state,
            error: payload,
            actionProgress: false,
        };
    case IMPORT_ITEMS_FC_REGISTRY_ERROR:
        return {
            ...state,
            registryItemsImportProgress: false,
        };
    default:
        return state;
    }
};

export const getFcRegistrySubmittingFlags = payload => ({
    type: GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST,
    payload,
});

export const deleteFcRegistryItems = payload => ({
    type: DELETE_FC_REGISTRY_ITEMS_REQUEST,
    payload,
});

export const cancelFcRegistryItem = payload => ({
    type: CANCEL_FC_REGISTRY_ITEM_REQUEST,
    payload,
});

export const exportFcRegistry = payload => ({
    type: EXPORT_FC_REGISTRY_REQUEST,
    payload,
});

export const addItemIntoFcRegistry = payload => ({
    type: ADD_ITEM_INTO_FC_REGISTRY_REQUEST,
    payload,
});

export const getContractorsPageForFcRegistry = payload => ({
    type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST,
    payload,
});

export const deleteNotSignedItemsFcRegistry = payload => ({
    type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST,
    payload,
});

export const submitFcRegistryToSigning = payload => ({
    type: SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    payload,
});

export const resubmitFcRegistryToSigning = payload => ({
    type: RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    payload,
});

export const getFcRegistryPage = payload => ({
    type: GET_FC_REGISTRIES_PAGE_REQUEST,
    payload,
});

export const getFcRegistryById = payload => ({
    type: GET_FC_REGISTRY_BY_ID_REQUEST,
    payload,
});

export const getFcRegistryItemsPage = payload => ({
    type: GET_FC_REGISTRY_ITEMS_PAGE_REQUEST,
    payload,
});

export const getFcRegistryStatusDict = payload => ({
    type: GET_FC_REGISTRY_STATUS_DICT_REQUEST,
    payload,
});

export const getFcRegistryItemStatusDict = payload => ({
    type: GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST,
    payload,
});

export const archiveFcRegistry = payload => ({
    type: ARCHIVE_FC_REGISTRY_REQUEST,
    payload,
});

export const importItemsFcRegistry = payload => ({
    type: IMPORT_ITEMS_FC_REGISTRY_REQUEST,
    payload,
});

const fcRegistrySelector = ({fcRegistries}) => fcRegistries;

export const fcRegistryListSelector = createSelector(fcRegistrySelector, ({list}) => list);
export const fcRegistryListProgressSelector = createSelector(fcRegistrySelector, ({listProgress}) => listProgress);
export const fcRegistryListTotalCountSelector = createSelector(fcRegistrySelector, ({totalCount}) => totalCount);
export const fcRegistryListTotalPagesSelector = createSelector(fcRegistrySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));

export const fcRegistryItemListSelector = createSelector(fcRegistrySelector, ({registryItemsList}) => registryItemsList);
export const fcRegistryItemListProgressSelector = createSelector(fcRegistrySelector, ({registryItemsProgress}) => registryItemsProgress);
export const fcRegistryItemsImportProgressSelector = createSelector(fcRegistrySelector, ({registryItemsImportProgress}) => registryItemsImportProgress);
export const fcRegistryItemListTotalCountSelector = createSelector(fcRegistrySelector, ({registryItemsTotalCount}) => registryItemsTotalCount);
export const fcRegistryItemListTotalPagesSelector = createSelector(fcRegistrySelector,
    ({registryItemsTotalCount, registryItemsPageData: {pageSize = 0}}) => getTotalPages(registryItemsTotalCount, pageSize));

export const contractorListForFcRegistrySelector = createSelector(fcRegistrySelector, ({contractorList}) => contractorList);
export const contractorListProgressForFcRegistrySelector = createSelector(fcRegistrySelector, ({contractorListProgress}) => contractorListProgress);
export const contractorListForFcRegistryTotalCountSelector = createSelector(fcRegistrySelector, ({contractorListTotalCount}) => contractorListTotalCount);
export const contractorListForFcRegistryTotalPagesSelector = createSelector(fcRegistrySelector,
    ({contractorListTotalCount, contractorListPageData: {pageSize = 0}}) => getTotalPages(contractorListTotalCount, pageSize));

export const fcRegistryCardSelector = createSelector(fcRegistrySelector, ({card}) => card);
export const fcRegistryProgressSelector = createSelector(fcRegistrySelector, ({registryProgress}) => registryProgress);

export const fcRegistryActionSelector = createSelector(fcRegistrySelector, ({actionProgress}) => actionProgress);

export const fcRegistryStatusDictSelector = createSelector(fcRegistrySelector, ({statusDict}) => statusDict);
export const fcRegistryStatusOptionsSelector = createSelector(fcRegistrySelector, ({statusDict}) => dictionaryToOptions(statusDict));

export const fcRegistryItemStatusDictSelector = createSelector(fcRegistrySelector, ({itemStatusDict}) => itemStatusDict);
export const fcRegistryItemStatusOptionsSelector = createSelector(fcRegistrySelector, ({itemStatusDict}) => dictionaryToOptions(itemStatusDict));
export const fcRegistrySubmittingFlagsSelector = createSelector(fcRegistrySelector, ({fcRegistrySubmittingFlags}) => fcRegistrySubmittingFlags);

function* getFcRegistryByIdSaga({payload}) {
    try {
        const {
            data: params,
            onSuccess,
        } = payload;

        const result = yield request.get(`${controller}/getRich`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRY_BY_ID_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_BY_ID_ERROR,
        });
    }
}

function* getContractorsPageForFcRegistrySaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/contractors/getRichPage`, data);

        const {
            errorMessage,
            contractors,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (contractors?.length) {
            const contractorIds = contractors.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR,
        });
    }
}

function* getFcRegistryItemsPageSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/items/getRichPage`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_ITEMS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {items} = result;

        if (items?.length) {
            const contractorIds = items.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_ITEMS_PAGE_ERROR,
        });
    }
}

function* cancelFcRegistryItemSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/items/cancel`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CANCEL_FC_REGISTRY_ITEM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CANCEL_FC_REGISTRY_ITEM_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

        toastSuccess("Позиция реестра успешно отменена");
    } catch (error) {
        yield put({
            type: CANCEL_FC_REGISTRY_ITEM_ERROR,
        });
    }
}

function* addItemIntoFcRegistrySaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/items/add`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ITEM_INTO_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_ITEM_INTO_FC_REGISTRY_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();

        toastSuccess("Исполнитель успешно добавлен в реестр");
    } catch (error) {
        yield put({
            type: ADD_ITEM_INTO_FC_REGISTRY_ERROR,
        });
    }
}

function* getFcRegistrySubmittingFlagsSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/submittingToSigningFlags`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR,
        });
    }
}

function* deleteFcRegistryItemsSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/items/deleteList`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_FC_REGISTRY_ITEMS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_FC_REGISTRY_ITEMS_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: DELETE_FC_REGISTRY_ITEMS_ERROR,
        });
    }
}

function* deleteNotSignedItemsFcRegistrySaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/items/deleteNotSigned`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess(result);
    } catch (error) {
        yield put({
            type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR,
        });
    }
}

function* resubmitFcRegistryToSigningSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/resubmitToSigning`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess(result);
    } catch (error) {
        yield put({
            type: RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
        });
    }
}

function* submitFcRegistryToSigningSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/submitToSigning`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess(result);
    } catch (error) {
        yield put({
            type: SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
        });
    }
}

function* getFcRegistryPageSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/getRichPage`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRIES_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRIES_PAGE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRIES_PAGE_ERROR,
        });
    }
}

function* getFcRegistryItemStatusDictSaga({payload={}}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.get(`${dictController}/frameContractRegistryItemStatus`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR,
        });
    }
}

function* getFcRegistryStatusDictSaga({payload={}}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.get(`${dictController}/frameContractRegistryStatus`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRY_STATUS_DICT_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_STATUS_DICT_ERROR,
        });
    }
}

function* exportFcRegistrySaga({payload}) {
    try {
        const {
            onSuccess,
            data,
            fileName,
        } = payload;

        const reqData = {
            method: "POST",
            body: JSON.stringify(data),
        };
        const urlRequest = `${new URL(window.location.href).origin}/api/${controller}/export`;
        const response = yield request.getFile(urlRequest, reqData);

        if (response.status === 200 && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                toastError(data === "FILE_NOT_FOUND" ? "Файл не существует" : errorMessage);

                yield put({
                    type: EXPORT_FC_REGISTRY_ERROR,
                    payload: errorMessage,
                });

                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        downloadBlob(blob, response.headers, fileName);

        yield put({
            type: ARCHIVE_FC_REGISTRY_SUCCESS,
            payload: response,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: EXPORT_FC_REGISTRY_ERROR,
        });
    }
}

function* archiveFcRegistrySaga({payload}) {
    const {
        onSuccess,
        onError,
        data,
        archive,
    } = payload;

    try {
        const result = yield request.post(`${controller}/${archive?"archive":"unarchive"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ARCHIVE_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ARCHIVE_FC_REGISTRY_SUCCESS,
            payload: result,
        });

        toastSuccess(`Реестр успешно ${archive? "архивирован": "восстановлен из архива"}`);

        onSuccess && onSuccess(result);
    } catch (error) {
        onError();
        yield put({
            type: ARCHIVE_FC_REGISTRY_ERROR,
        });
    }
}

//POST
// /api/documents/registry/framecontract/items/import
export const importItemsFcRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/items/import`, data, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: IMPORT_ITEMS_FC_REGISTRY_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Загрузка файла успешно завершена. Новые исполнители станут доступны через некоторое время.");

        yield put({type: IMPORT_ITEMS_FC_REGISTRY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: IMPORT_ITEMS_FC_REGISTRY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_FC_REGISTRIES_PAGE_REQUEST, getFcRegistryPageSaga),
        takeEvery(EXPORT_FC_REGISTRY_REQUEST, exportFcRegistrySaga),
        takeEvery(GET_FC_REGISTRY_STATUS_DICT_REQUEST, getFcRegistryStatusDictSaga),
        takeEvery(ARCHIVE_FC_REGISTRY_REQUEST, archiveFcRegistrySaga),
        takeEvery(GET_FC_REGISTRY_ITEMS_PAGE_REQUEST, getFcRegistryItemsPageSaga),
        takeEvery(SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,submitFcRegistryToSigningSaga),
        takeEvery(RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,resubmitFcRegistryToSigningSaga),
        takeEvery(GET_FC_REGISTRY_BY_ID_REQUEST, getFcRegistryByIdSaga),
        takeEvery(GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST, getFcRegistryItemStatusDictSaga),
        takeEvery(ADD_ITEM_INTO_FC_REGISTRY_REQUEST, addItemIntoFcRegistrySaga),
        takeEvery(CANCEL_FC_REGISTRY_ITEM_REQUEST, cancelFcRegistryItemSaga),
        takeEvery(GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST, getContractorsPageForFcRegistrySaga),
        takeEvery(DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST, deleteNotSignedItemsFcRegistrySaga),
        takeEvery(DELETE_FC_REGISTRY_ITEMS_REQUEST, deleteFcRegistryItemsSaga),
        takeEvery(GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST,getFcRegistrySubmittingFlagsSaga),
        takeEvery(IMPORT_ITEMS_FC_REGISTRY_REQUEST, importItemsFcRegistrySaga),
    ]);
}