import React from "react";
import {useMemo} from "react";
import {useMedia} from "react-media";
import {nanoid} from "nanoid";

import {INmButton} from "../../NmButton";
import NmTitle from "../../NmTitle";
import RegistryCardButton from "../../RegistryCardButton";
import ContextMenu from "../ContextMenu";

import bem from "../../../utils/bem";
import {getClassNames} from "../../../utils/classNames";

import "./style.sass";

interface RegistryHeaderWithActionsProps {
    className?: string,
    count?: number,
    header?: string | React.ReactNode,
    buttons: Array<INmButton & {visible?: boolean}>,
}

export const RegistryHeaderWithActions: React.FC<RegistryHeaderWithActionsProps> = (props) => {
    const {
        count,
        buttons,
        className = "mb-4 mb-xl-6",
        header,
    } = props;

    const isRenderContextMenu = useMedia({query: {maxWidth: 1199}});

    const [block, element] = bem("registry-header-with-actions", className);

    const options = useMemo(() => {
        if (!isRenderContextMenu) {
            return;
        }

        return buttons
            .filter(({visible = true}) => visible)
            .map((item) => {
                return {
                    key: nanoid(3),
                    value: nanoid(3),
                    text: item.children,
                    onClick: item.onClick,
                };
            });
    }, [isRenderContextMenu]);

    const getButtons = () => {
        if (isRenderContextMenu) {
            return (
                <ContextMenu
                    inline={false}
                    options={options}
                />
            );
        }

        return buttons
            .filter(({visible = true}) => visible)
            .map(item => {
                return (
                    <RegistryCardButton
                        key={item.children}
                        onClick={item.onClick}
                        icon={item.icon}
                        className={getClassNames([
                            "registry-header-with-actions__button",
                            item.className,
                        ])}
                    >
                        {item.children}
                    </RegistryCardButton>
                );
            });
    };

    return (
        <div className={block({contextMenu: isRenderContextMenu})}>
            <NmTitle
                count={count}
                size="lg"
                className="registry-header-with-actions__title"
            >
                {header}
            </NmTitle>
            <div className={element("buttons", {contextMenu: isRenderContextMenu})}>
                {getButtons()}
            </div>
        </div>
    );
};