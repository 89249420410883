import {citizenshipsDict} from "./citizenships";
import {CONTRACTOR_FIELD} from "./contractor";
import {EMPTY_OPTION_KEY} from "./dropdown";

export const GENDER = {
    "M": "Мужской",
    "М": "Мужской",
    "W": "Женский",
    "Ж": "Женский",
};

export const NM_ADMIN_INFO = {
    ID: "00000000-0000-0000-0000-000000000000",
    NAME: "Администратор Наймикс",
};

export const CONTRACTOR_LS_URL_PARAMS = {
    CONTRACTOR_LIST_PAGE: "contractorListPageNumber",
    CONTRACTOR_LIST_PAGE_SIZE: "contractorListPageSize",
};

export const SETTINGS_MEMBERS_LS_URL_PARAMS = {
    LIST_PAGE: "settingsMembersListPageNumber",
    PAGE_SIZE: "settingsMembersListPageSize",
};

export const GENDER_DICT = {
    M: "Мужской",
    W: "Женский",
};

export const GENDER_OPTIONS = [{
    key: "M",
    value: "M",
    text: "Мужской",
}, {
    key: "W",
    value: "W",
    text: "Женский",
}];

export const CONTRACTORS_SORT_OPTIONS = [{
    key: "RATING_ASC",
    value: "RATING_ASC",
    text: "По возрастанию рейтинга",
}, {
    key: "RATING_DESC",
    value: "RATING_DESC",
    text: "По убыванию рейтинга",
}];

export const GENDER_OPTIONS_WITH_ALL_OPTION = [
    {
        key: EMPTY_OPTION_KEY,
        text: "Все",
        value: EMPTY_OPTION_KEY,
    },
    ...GENDER_OPTIONS,
];

export const GENDER_OPTIONS_WITH_EMPTY_OPTION = [{
    key: "M",
    value: "M",
    text: "Мужской",
}, {
    key: "W",
    value: "W",
    text: "Женский",
}, {
    key: EMPTY_OPTION_KEY,
    text: "Показать все",
    value: EMPTY_OPTION_KEY,
},
];

export const EMPTY_OPTION = {
    key: EMPTY_OPTION_KEY,
    text: "Показать все",
    value: EMPTY_OPTION_KEY,
};

export const BLOCK_DATA_NAME = {
    FINANCE: "financeInfo",
    PASSPORT: "passportInfo",
    CONTACT: "contactInfo",
    INDUSTRY: "industryInfo",
};

export const FINANCE_BLOCK_FIELDS = [
    CONTRACTOR_FIELD.BANK_CARD_NUMBER,
    CONTRACTOR_FIELD.INN,
    CONTRACTOR_FIELD.CARD_ID_THE_BANK,
    CONTRACTOR_FIELD.CONTRACTOR_ID_THE_BANK,
];
export const CONTACT_BLOCK_FIELDS = [
    CONTRACTOR_FIELD.PHONE,
    CONTRACTOR_FIELD.EMAIL,
    CONTRACTOR_FIELD.ADDRESS,
    CONTRACTOR_FIELD.LENGTH_OF_STAY_EXPIRATION_DATE,
];

export const CONTACT_BLOCK_RUSSIAN_FIELDS = [
    CONTRACTOR_FIELD.PHONE,
    CONTRACTOR_FIELD.EMAIL,
    CONTRACTOR_FIELD.ADDRESS,
];

export const PASSPORT_BLOCK_FIELDS = [
    CONTRACTOR_FIELD.CITIZENSHIP,
    CONTRACTOR_FIELD.GENDER,
    CONTRACTOR_FIELD.BIRTH_DATE,
    CONTRACTOR_FIELD.BIRTH_PLACE,
    CONTRACTOR_FIELD.ID_DOC_NUMBER,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE,
    CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
    CONTRACTOR_FIELD.REGISTRATION_DATE,
    CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER,
    CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE,
    CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE,
];


export const PASSPORT_BLOCK_FIELDS_RU = [
    CONTRACTOR_FIELD.CITIZENSHIP,
    CONTRACTOR_FIELD.GENDER,
    CONTRACTOR_FIELD.BIRTH_DATE,
    CONTRACTOR_FIELD.BIRTH_PLACE,
    CONTRACTOR_FIELD.ID_DOC_NUMBER,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE,
];

export const PASSPORT_BLOCK_FIELDS_BY = [
    CONTRACTOR_FIELD.CITIZENSHIP,
    CONTRACTOR_FIELD.GENDER,
    CONTRACTOR_FIELD.BIRTH_DATE,
    CONTRACTOR_FIELD.BIRTH_PLACE,
    CONTRACTOR_FIELD.ID_DOC_NUMBER,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
    CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
    CONTRACTOR_FIELD.REGISTRATION_DATE,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_VALID_TO_DATE,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_BY,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_DATE,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_NUMBER,
];

export const PASSPORT_BLOCK_FIELDS_AM_KZ_KG = [
    CONTRACTOR_FIELD.CITIZENSHIP,
    CONTRACTOR_FIELD.GENDER,
    CONTRACTOR_FIELD.BIRTH_DATE,
    CONTRACTOR_FIELD.BIRTH_PLACE,
    CONTRACTOR_FIELD.ID_DOC_NUMBER,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
    CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
    CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
    CONTRACTOR_FIELD.REGISTRATION_DATE,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_VALID_TO_DATE,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_BY,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_DATE,
    CONTRACTOR_FIELD.TEMP_RESID_PERM_NUMBER,
    CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER,
    CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE,
    CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE,
];

export const INDUSTRY_BLOCK_FIELDS = [
    CONTRACTOR_FIELD.SPECIALTY,
    CONTRACTOR_FIELD.WORK_REGION,
    CONTRACTOR_FIELD.WORK_REGION_LIST,
    CONTRACTOR_FIELD.REMOTE_WORK,
];

export const WORK_LOG_TYPE = {
    OUT: "OUT",
    IN: "IN",
};

/* Поля из формы карточки CЗ*/
export const CONTRACTOR_CARD_FIELDS = {
    INITIAL_PERSONAL_DATA: [
        CONTRACTOR_FIELD.FULL_NAME,
        CONTRACTOR_FIELD.PHONE,
        CONTRACTOR_FIELD.EMAIL,
    ],
    WORK_DATA: [
        CONTRACTOR_FIELD.SPECIALTY,
        CONTRACTOR_FIELD.SPECIALTY_IDS,
        CONTRACTOR_FIELD.SPECIALITIES,
    ],
    WORK_REGION_DATA: [
        CONTRACTOR_FIELD.WORK_REGION_LIST,
        CONTRACTOR_FIELD.REMOTE_WORK,
    ],
    FULL_PERSONAL_DATA: [
        CONTRACTOR_FIELD.LAST_NAME,
        CONTRACTOR_FIELD.FIRST_NAME,
        CONTRACTOR_FIELD.PATRONYMIC,
        CONTRACTOR_FIELD.INN,
        CONTRACTOR_FIELD.EMAIL,
        CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS,
        CONTRACTOR_FIELD.PHONE,
        CONTRACTOR_FIELD.BIRTH_DATE,
        CONTRACTOR_FIELD.GENDER,
        CONTRACTOR_FIELD.ADDRESS,
    ],
    PASSPORT_DATA: [
        CONTRACTOR_FIELD.CITIZENSHIP,
        CONTRACTOR_FIELD.BIRTH_PLACE,
        CONTRACTOR_FIELD.ID_DOC_NUMBER,
        CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
        CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE,
        CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
        CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        CONTRACTOR_FIELD.EIN,
        CONTRACTOR_FIELD.NAIMIX_JOINING_DATE,
        CONTRACTOR_FIELD.PASSPORT_TYPE,
        CONTRACTOR_FIELD.EDM_PASSPORT_TYPE,
    ],
    PAYMENT_DATA: [
        CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        CONTRACTOR_FIELD.CONTRACTOR_ID_THE_BANK,
        CONTRACTOR_FIELD.CARD_ID_THE_BANK,
        CONTRACTOR_FIELD.CONTRACTOR_DOCUMENT_ID_BANK,
    ],
    MIGRATION_DATA: [
        CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE,
        CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE,
        CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER,
    ],
    ARRIVAL_NOTICE_DATA: [
        CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE,
        CONTRACTOR_FIELD.ADDRESS,
        CONTRACTOR_FIELD.REGISTRATION_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE,
        CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS,
        CONTRACTOR_FIELD.MAIN_DOCUMENT_STAMP_ADDRESS,
        CONTRACTOR_FIELD.MAIN_DOCUMENT_STAMP_ISSUED_DATE,
        CONTRACTOR_FIELD.MAIN_DOCUMENT_STAMP_VALID_TO_DATE,
    ],
    EMPLOYMENT_HISTORY: [
        CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT,
        CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM,
    ],
    SNILS_INFO: [
        CONTRACTOR_FIELD.SNILS_PRESENT,
        CONTRACTOR_FIELD.SNILS_NUM,
    ],
    VHI_POLICY_INFO: [
        CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE,
        CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE,
    ],
    EMPLOYMENT_PATENT_INFO: [
        CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER,
        CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE,
        CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM,
        CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES,
    ],
};
export const CONTRACTOR_SUB_BLOCK_NAME = {
    GENERAL: "GENERAL",
    CONTRACT_INFO: "CONTRACT_INFO",
    TAX_DATA: "TAX_DATA",
    PASSPORT_INFO: "PASSPORT_INFO",
    NUMBER_INFO: "NUMBER_INFO",
    ADDITIONAL_PASSPORT_INFO: "ADDITIONAL_PASSPORT_INFO",
    PAYMENT_DATA: "PAYMENT_DATA",
    ARRIVAL_NOTICE_DATA_DOC_TYPE: "ARRIVAL_NOTICE_DATA_DOC_TYPE",
    ARRIVAL_NOTICE_DATA_CONTACT_INFO: "ARRIVAL_NOTICE_DATA_CONTACT_INFO",
    RESIDENCE_PERMIT_FIRST_BLOCK: "RESIDENCE_PERMIT_FIRST_BLOCK",
    RESIDENCE_PERMIT_SECOND_BLOCK: "RESIDENCE_PERMIT_SECOND_BLOCK",
    CARD_SERIES_NUMBER: "CARD_SERIES_NUMBER",
    DATES: "DATES",
    RESIDENCE_PERMIT: "RESIDENCE_PERMIT",
};
export const CONTRACTOR_CARD_SUB_BLOCK_FIELDS = {
    [citizenshipsDict.RU.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.ADDRESS,
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    [citizenshipsDict.AM.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    [citizenshipsDict.KG.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.PASSPORT_TYPE,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
            CONTRACTOR_FIELD.EIN,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    [citizenshipsDict.KZ.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.PASSPORT_TYPE,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
            CONTRACTOR_FIELD.EIN,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    [citizenshipsDict.BY.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
            CONTRACTOR_FIELD.EIN,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    MIGRATION_DATA: {
        CARD_SERIES_NUMBER: [
            CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER,
        ],
        DATES: [
            CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE,
            CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE,
        ],
    },
    ARRIVAL_NOTICE_DATA_DOC_TYPE: [
        CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE,
    ],
    RESIDENCE_PERMIT_FIRST_BLOCK: [
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER,
    ],
    RESIDENCE_PERMIT_SECOND_BLOCK: [
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE,
    ],
    RESIDENCE_PERMIT: [
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE,
        CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE,
    ],
    ARRIVAL_NOTICE_DATA_CONTACT_INFO: [
        CONTRACTOR_FIELD.ADDRESS,
        CONTRACTOR_FIELD.REGISTRATION_DATE,
        CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE,
    ],
    [citizenshipsDict.TJ.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.PASSPORT_TYPE,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
            CONTRACTOR_FIELD.EIN,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    [citizenshipsDict.UA.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.PASSPORT_TYPE,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
            CONTRACTOR_FIELD.EIN,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
    [citizenshipsDict.UZ.value]: {
        GENERAL: [
            CONTRACTOR_FIELD.LAST_NAME,
            CONTRACTOR_FIELD.FIRST_NAME,
            CONTRACTOR_FIELD.PATRONYMIC,
            CONTRACTOR_FIELD.BIRTH_DATE,
            CONTRACTOR_FIELD.GENDER,
        ],
        CONTRACT_INFO: [
            CONTRACTOR_FIELD.EMAIL,
            CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS,
            CONTRACTOR_FIELD.PHONE,
        ],
        TAX_DATA: [
            CONTRACTOR_FIELD.INN,
        ],
        PASSPORT_INFO: [
            CONTRACTOR_FIELD.CITIZENSHIP,
            CONTRACTOR_FIELD.PASSPORT_TYPE,
            CONTRACTOR_FIELD.BIRTH_PLACE,
        ],
        ADDITIONAL_PASSPORT_INFO: [
            CONTRACTOR_FIELD.ID_DOC_ISSUED_BY,
            CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE,
            CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE,
        ],
        NUMBER_INFO: [
            CONTRACTOR_FIELD.ID_DOC_NUMBER,
            CONTRACTOR_FIELD.EIN,
        ],
        PAYMENT_DATA: [
            CONTRACTOR_FIELD.BANK_CARD_NUMBER,
        ],
    },
};

const russianPhoneMask = "+7 999 999 9999";

export const contractorMask = {
    PHONE: {
        RUS: russianPhoneMask,
    },
    MIGRATION_CARD: {
        NUMBER: "9999 9999999",
    },
};

export const FNS_SUCCESS_TEXT = "Исполнитель зарегистрирован в качестве НП НПД";

export const FNS_ERRORS = {
    TAXPAYER_UNBOUND: {
        TEXT: "Исполнитель не привязан к платформе",
    },
    PARTNER_DENY: {
        TEXT: "Наймиксу отказано в получении детальной информации о налогоплательщике НПД",
    },
    TAXPAYER_UNREGISTERED: {
        TEXT: "Исполнитель снят с учета НП НПД",
    },
};

export const CONTRACTOR_PASSPORT_TYPE = {
    INTERNATIONAL_PASSPORT: "INTERNATIONAL_PASSPORT",
    INTERNAL_PASSPORT: "INTERNAL_PASSPORT",
};

export const CONTRACTOR_SEGMENT_INFO_TYPE = {
    INN: "INN", //ИНН
    PASSPORT_DATA: "PASSPORT_DATA", //Паспортные данные
    RESIDENCE_PLACE_RESIDENT: "RESIDENCE_PLACE_RESIDENT", //Регистрация гражданина РФ по месту жительства
    RESIDENCE_PLACE_NOT_RESIDENT: "RESIDENCE_PLACE_NOT_RESIDENT", //Регистрация иностранного гражданина по месту жительства
    RESIDENCE_PERMIT: "RESIDENCE_PERMIT", //Вид на жительство
    TEMPORARY_RESIDENCE_PERMIT: "TEMPORARY_RESIDENCE_PERMIT", //Разрешение на временное проживание
    INSURANCE_POLICY: "INSURANCE_POLICY", //Страховой полис
    PASSPORT_TRANSLATION: "PASSPORT_TRANSLATION", //Перевод паспорта
    VISA: "VISA", //Виза
    COLLEGE: "COLLEGE", //Высшее учебное заведение
    LABOR_PATENT: "LABOR_PATENT", //Патент на осуществление трудовой деятельности в России
    PARENTS_CONSENT_TO_WORK_BY_MINOR: "PARENTS_CONSENT_TO_WORK_BY_MINOR", //Согласие родителей на работу несовершеннолетних исполнителей (только для исполнителей младше 18 лет)
    MIGRATION_CARD: "MIGRATION_CARD", //Миграционная карта
    CITIZENSHIP_AND_MIGRATION_STATUS: "CITIZENSHIP_AND_MIGRATION_STATUS",
};

export const CONTRACTOR_LEAD_TYPE_TRANSLATE = {
    CIVIL: "ГПХ",
    SMZ: "СМЗ",
};