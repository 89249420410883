import React, {useState} from "react";
import {isEmpty} from "lodash";

import {ReactComponent as CancelIcon} from "../../images/cancel_24.svg";
import {ReactComponent as TimeLapseIcon} from "../../images/timelapse_24.svg";
import NmLabel from "../ActualComponents/NmLabel";
import NmModal from "../ActualComponents/NmModal";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";
import OrderSchedule from "../OrderSchedule";

import {deepClone} from "../../utils/objectHelper";
import bem from "./../../utils/bem";

import {ORDER_SCHEDULE_DAYS} from "../../constants/order";

import PropTypes from "prop-types";

import "./style.sass";

const NmWorkScheduleFilter = (props) => {
    const {
        className,
        value,
        onChange,
        name,
        disabled,
        required,
        size = "lg",
        isClearable = true,
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [lastValue, setLastValue] = useState({});
    const [tempValue, setTempValue] = useState({});
    const [schedules, setShedules] = useState({});
    const [activeAction, setActiveAction] = useState(false);

    const [block, element] = bem("nm-work-schedule-filter", className);

    const mapSchedules = () => {
        if (isEmpty[schedules]) {
            return null;
        }
        const result = [];
        schedules.forEach((schedule) => {
            schedule.checked && schedule.times.forEach((time) => {
                result.push({
                    dayOfWeek: schedule.name,
                    startWorkTime: time.startWorkTime,
                    endWorkTime: time.endWorkTime,
                });
            });
        });
        return result;
    };

    const changeSchedules = () => {
        onChange(null, {name, value: mapSchedules()});
        setLastValue(deepClone(schedules));
        setShowModal(false);
    };

    const showModalPicker = () => {
        setTempValue(isEmpty(value) ? deepClone(ORDER_SCHEDULE_DAYS) : deepClone(lastValue));
        setShowModal(true);
    };

    const onChangeSchedules = (value) => {
        setShedules(value);
        const isNotEmpty = value.some((schedule) => {
            return schedule.times.length;
        });
        setActiveAction(isNotEmpty);
    };

    const renderFooter = () => {
        return (
            <ApplyButtons
                submitBtnContent="Ок"
                cancelBtnContent="Отмена"
                onClose={() => {
                    setShowModal(false);
                }}
                disabled={!activeAction}
                submit={changeSchedules}
            />
        );
    };

    const renderModalPicker = () => {
        return (
            showModal && <NmModal
                zIndex={1400}
                className="nm-work-schedule-filter__modal"
                onClose={() => setShowModal(false)}
                header={
                    <NmTitle size="lg">
                        Задайте график занятости
                    </NmTitle>
                }
                footer={renderFooter()}
            >
                <OrderSchedule
                    formDataSchedules={tempValue}
                    handleChange={(e, {value}) => {
                        onChangeSchedules(value);
                    }}
                />
            </NmModal>
        );
    };

    const onClear = (event) => {
        event.stopPropagation();

        onChange(null, {name, value: null});
    };

    return (
        <div className={block()}>
            {renderModalPicker()}
            <div
                className="fluid"
                onClick={showModalPicker}
            >
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label="График занятости"
                />
                <div
                    className={element("elem", {
                        size,
                        disabled,
                        active: !isEmpty(value),
                    },
                    )}
                >
                    <TimeLapseIcon
                        className={element("icon")}
                        width={20}
                        height={20}
                    />
                    <span className={element("text")}>
                        {
                            isEmpty(value) ?
                                "Условие не задано" :
                                "Задано условие"
                        }
                    </span>
                    {
                        isClearable && !isEmpty(value) && !disabled &&
                        <CancelIcon
                            width={20}
                            height={20}
                            onClick={onClear}
                            className={element("clear")}
                        />
                    }
                </div>
            </div>

        </div>
    );
};

NmWorkScheduleFilter.propTypes = {
    className: PropTypes.string,
};

export default NmWorkScheduleFilter;