import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        name: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(100, "Не более 100 символов"),
    });
};

export default validationSchema;