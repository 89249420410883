import React from "react";
import {Route, Switch, withRouter} from "react-router";

import DocumentManagementDictionariesCommon
    from "../../../document-management/document-management-dictionaries/document-management-dictionaries-common";
import DocumentManagementDocumentList from "../../../document-management/document-management-document-list";
import DocumentManagementExport from "../../../document-management/document-management-export";
import DocumentManagementPersonnel from "../../../document-management/document-management-personnel";
import DocumentManagementStatementList from "../../../document-management/document-management-statement/list";
import EdoDocumentsRegistries from "../../../document-management/documents-registries";
import EdoDocumentsRegistriesCard from "../../../document-management/documents-registries/card";
import EdoDocumentsRegistriesAddItemsList
    from "../../../document-management/documents-registries/card/components/add-items-list";

import {
    LINK_CLIENT_DOCUMENT_MANAGEMENT_DICTIONARIES,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS,
    LinkNavigator,
} from "../../../../constants/links";

const ClientCardEdoRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST}
                component={DocumentManagementDocumentList}
            />
            <Route
                exact
                path={LinkNavigator.client.edo.documentsRegistries}
                component={EdoDocumentsRegistries}
            />
            <Route
                exact
                path={LinkNavigator.client.edo.documentsRegistriesCard}
                component={EdoDocumentsRegistriesCard}
            />
            <Route
                exact
                path={LinkNavigator.client.edo.documentsRegistriesCardAddItem}
                component={EdoDocumentsRegistriesAddItemsList}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS}
                component={DocumentManagementStatementList}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL}
                component={DocumentManagementPersonnel}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT}
                component={DocumentManagementExport}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_DICTIONARIES}
                component={DocumentManagementDictionariesCommon}
            />
        </Switch>
    );
};

export default withRouter(ClientCardEdoRoute);