import React, {useState} from "react";

import NmTooltip, {TNmTooltip} from "../NmTooltip";

import {useClickOutside} from "../../../hooks/useClickOutside";

import bem from "../../../utils/bem";

import "./style.sass";

export type INmAdvancedTooltip = TNmTooltip & {
    trigger: React.ReactNode,
    triggerAction?(): void,
    classNameTooltip?: string,
    noRelative?: boolean,
    noAdaptive?: boolean,
    disabled?: boolean,
    noArrowInMobile?: boolean,
    noPointerEvents?: boolean,
    isTriggerListCardIcon?: boolean,
    isFilterTooltip?: boolean,
}

const NmAdvancedTooltip: React.FC<INmAdvancedTooltip> = props => {
    const {
        className = "",
        trigger,
        triggerAction,
        hover = false,
        classNameTooltip,
        children,
        body,
        noRelative,
        noAdaptive,
        disabled,
        noArrowInMobile,
        noPointerEvents,
        ...tooltipProps
    } = props;

    const [block, element] = bem("nm-advanced-tooltip", className);
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useClickOutside<HTMLDivElement>(() => setOpen(false), isOpen);
    const isContent = children || body;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (hover || disabled) {
            return;
        }

        setOpen(true);
        triggerAction && triggerAction();
    };

    const handleClickClose = (e: any) => {
        setOpen(false);
        e.stopPropagation();
    };

    return (
        <div
            className={block({
                hover,
                noRelative,
                noAdaptive,
                filterTooltip: props.isFilterTooltip,
            })}
            ref={ref}
            onClick={handleClick}
        >
            {trigger}
            {
                isContent &&
                <NmTooltip
                    {...tooltipProps}
                    body={body}
                    children={children}
                    hover={hover}
                    onClose={handleClickClose}
                    className={
                        `${element(
                            "tooltip", {
                                isOpen,
                                noAdaptive,
                                noArrowInMobile,
                                noPointerEvents,
                            },
                        )} 
                        ${classNameTooltip}`
                    }
                />
            }
        </div>
    );
};

export default NmAdvancedTooltip;