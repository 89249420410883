import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import CheckboxList from "../../../../../components/CheckboxList";
import NmEmptyPage from "../../../../../components/NmEmptyPage";
import NmTitle from "../../../../../components/NmTitle";

import {usePagination} from "../../../../../hooks/usePagination";

import {formatLocalDate} from "../../../../../utils/dateFormat";

import {
    addTicketComment,
    getTicketCommentsRich,
    ticketCommentsCountSelector,
    ticketCommentsSelector,
    ticketCommentsTotalPagesSelector,
} from "../../../../../ducks/crm/ticket";

import "./style.sass";

const MIN_LENGTH_COMMENT = 3;

function TicketParamsComments(props) {
    const {
        className,
        classNameContentList,
        ticketId,
        isEditable,
    } = props;
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page", 10);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const list = useSelector(ticketCommentsSelector);
    const totalCount = useSelector(ticketCommentsCountSelector);
    const totalPages = useSelector(ticketCommentsTotalPagesSelector);

    const [comment, setComment] = useState("");

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getTicketCommentsRich({
            pageNum,
            pageSize,
            ticketId,
            getResult: () => {

            },
        }));
    };

    const onChange = (event, {name, value, checked}) => {
        setComment(value);
    };

    const close = () => {
        setComment("");
    };

    const submit = () => {
        dispatch(addTicketComment({
            ticketId,
            comment,
            getResult: () => {
                fetchList();
                close();
            },
        }));
    };

    function getRows() {
        return list.map((item) => {
            const {
                baseModel: {
                    comment,
                    createDate,
                },
                managerModel: {
                    fio,
                },
            } = item;
            return {
                ...item,
                contentRow: (
                    <NmListCard
                        primaryHeader={`${fio} ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        otherContent={
                            <Text>
                                {comment}
                            </Text>
                        }
                    />
                ),
            };
        });
    }

    return (
        <div className={className}>
            <NmTitle
                size="xl"
                className="ticket-comments__title"
            >
                Комментарии
            </NmTitle>
            {isEditable && <>
                <NmTextareaV2
                    label="Комментарий"
                    placeholder="Введите комментарий"
                    minRows={7}
                    maxRows={7}
                    value={comment}
                    onChange={onChange}
                    maxLength={255}
                />
                <ApplyButtons
                    className="mt-5"
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={close}
                    disabled={comment.length < MIN_LENGTH_COMMENT}
                    submit={submit}
                />
            </>
            }
            {!!list.length && <CheckboxList
                className="ticket-comments__checkbox-list mt-5"
                classNameContent={classNameContentList}
                rows={getRows()}
            />}
            <NmPagination
                className="mt-5"
                pageSizes={[10, 15, 20]}
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onChangePagination={onPaginationChange}
            />
            {!isEditable && list.length === 0 &&
            <NmEmptyPage
                title="Информация отсутствует"
                className="mt-5"
            />}
        </div>
    );
}

export default TicketParamsComments;