import React, {FC, useEffect,useState} from "react";
import {useDispatch,useSelector} from "react-redux";

import NmModal from "../../components/ActualComponents/NmModal";
import CardApp from "../../components/CardApp";
import DropzoneAvatar from "../../components/DropzoneAppMini";
import NmTitle from "../../components/NmTitle";
import {ReactComponent as CheckIcon} from "../../images/checkV2.svg";
import {ReactComponent as LogoIcon} from "../../images/company-logo-big.svg";
import {ReactComponent as EditIcon} from "../../images/pen.svg";
import {ReactComponent as DelIcon} from "../../images/trashbox.svg";
import {ReactComponent as DownloadIcon} from "../../images/upload.svg";
import ImageCrop from "../ImageCrop";
import LogoThumbnail from "../LogoThumbnail";

import bem from "../../utils/bem";
import {downloadLocalFile} from "../../utils/downloadBlob";
import {b64toBlob} from "../../utils/file";

import {CLIENT_FILE_TYPES} from "../../constants/clientList";

import {getClientCardSelector} from "../../ducks/client";
import {
    addClientFileToFileStore,
    deleteClientFile,
    fileClientLogoSelector,
    getFileClientLogo,
} from "../../ducks/fileStore";

import "./style.sass";

const getPreview = (file: File) => URL.createObjectURL(file);

interface ILogoCardBlock {
    isEditable: boolean,
    className?: string;
    children?: never;
}

const LogoCardBlock: FC<ILogoCardBlock> = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const [block, element] = bem("logo-card-block", className);

    const {
        clientId,
        name,
        base64Logo: previewFromStore,
    } = useSelector(getClientCardSelector);

    const dispatch = useDispatch();

    const [file, setFile] = useState<File | null>(null);
    const [croppedFile, setCroppedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [isEdit, setEdit] = useState(false);
    const [method, setMethod] = useState<"delete" | "add" | null>(null);
    const [isModalShown, setModalShown] = useState<boolean>(false);
    const [isImageCropShown, setImageCropShown] = useState<boolean>(false);
    const [previewOriginal, setPreviewOriginal] = useState<string | null>(null);

    useEffect(() => {
        if (previewFromStore) {
            setPreview(previewFromStore);

            return;
        }

        setPreview(null);
    }, [previewFromStore, clientId]);

    const handleUploadFile = (file: File, croppedFile: File) => {
        const formDataFull = new FormData();
        formDataFull.append("file", file);

        dispatch(addClientFileToFileStore({
            clientId,
            clientFileType: CLIENT_FILE_TYPES.LOGO,
            file: formDataFull,
        }));

        const formDataCropped = new FormData();
        formDataCropped.append("file", croppedFile);

        dispatch(addClientFileToFileStore({
            clientId,
            clientFileType: CLIENT_FILE_TYPES.LOGO_THUMBNAIL,
            file: formDataCropped,
            isHiddenToaster: true,
        }));

        setFile(null);
        setCroppedFile(null);
    };

    const hanldeDeleteFile = () => {
        [CLIENT_FILE_TYPES.LOGO_THUMBNAIL, CLIENT_FILE_TYPES.LOGO].forEach(type => {
            dispatch(deleteClientFile({
                clientId,
                clientFileType: type,
                isShowToast: type !== CLIENT_FILE_TYPES.LOGO,
            }));
        });
    };

    const handleDropFile = ([file]: Array<File>) => {
        if (!file) {
            return;
        }
        const importedFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });

        setFile(importedFile);
        setImageCropShown(true);
        setMethod("add");
        setPreviewOriginal(null);
    };

    const handleToggle = () => {
        if (method) {
            (method === "add" && file && croppedFile) ? handleUploadFile(file, croppedFile) : hanldeDeleteFile();
        }
        setMethod(null);
        setEdit(!isEdit);
    };

    const handleResetFile = () => {
        setFile(null);
        setCroppedFile(null);
        setPreview(null);
        setMethod("delete");
    };

    const handleShowOriginalSize = (download = false) => {
        if (!previewOriginal) {
            dispatch(getFileClientLogo({
                clientId,
                clientFileType: CLIENT_FILE_TYPES.LOGO,
                onSuccess: (result: any) => {
                    if (download && result.base64str) {
                        downloadLocalFile(b64toBlob(result.base64str, "image/png"), `logo_${name}`);

                        return;
                    }

                    if (result.base64str) {
                        setPreviewOriginal(result.base64str);
                    }
                },
            }));
        }
        setModalShown(true);
    };

    const handleDownloadFile = () => {
        if (file) {
            downloadLocalFile(file, `logo_${name}`);

            return;
        }

        handleShowOriginalSize(true);
    };

    const saveImageCropForm = (file: any) => {
        setCroppedFile(file);
        setImageCropShown(false);
    };

    const closeImageCropForm = () => {
        setImageCropShown(false);
        setFile(null);
        setCroppedFile(null);
    };

    const renderHeader = () => (
        <header className={element("header")}>
            <NmTitle
                className={element("title")}
                size="lg"
            >
                Логотип
            </NmTitle>
            {isEditable && <div className={element("controls")}>
                {
                    isEdit &&
                    <>
                        <DelIcon
                            className={element("icon")}
                            onClick={handleResetFile}
                        />
                        <DownloadIcon
                            className={element("icon")}
                            onClick={handleDownloadFile}
                        />
                    </>
                }
                {isEdit ? <CheckIcon
                    onClick={handleToggle}
                    className="logo-card-block__icon_check"
                /> :
                    <EditIcon
                        className={element("icon", {edit: true})}
                        width={20}
                        height={20}
                        onClick={handleToggle}
                    />
                }
            </div>}
        </header>
    );

    const renderPreview = (file: File | null, preview: string | null) => (
        <LogoThumbnail
            size="xl"
            src={file ? getPreview(file) : `data:image/jpeg;charset=utf-8;base64, ${preview}`}
            showOriginal={handleShowOriginalSize}
        />
    );

    const renderContentEdit = () => {
        return (
            (croppedFile || preview) ? (
                renderPreview(croppedFile, preview)
            ) : (
                <DropzoneAvatar
                    accept={["image/png", "image/bmp", "image/jpeg", "image/gif"]}
                    maxSizeMb={10}
                    onDrop={handleDropFile}
                />
            )
        );
    };

    const renderImageCropForm = () => {
        return (
            isImageCropShown &&
            <ImageCrop
                file={file}
                submit={saveImageCropForm}
                close={closeImageCropForm}
            />
        );
    };

    const renderContentReadOnly = () => {
        return (
            (croppedFile || preview) ? (
                renderPreview(croppedFile, preview)
            ) : (
                <LogoIcon />
            )
        );
    };

    return (
        <CardApp
            className={block()}
            isHeaderShown={false}
            isEdit={isEdit}

        >
            {renderImageCropForm()}
            {renderHeader()}
            {isEdit ? renderContentEdit() : renderContentReadOnly()}
            {
                isModalShown &&
                previewOriginal &&
                <NmModal
                    className={element("modal")}
                    onClose={() => setModalShown(false)}
                >
                    <img
                        className={element("original-img")}
                        src={`data:image/jpeg;charset=utf-8;base64, ${previewOriginal}`}
                    />
                </NmModal>
            }
        </CardApp>
    );
};

export default LogoCardBlock;