import React from "react";
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import {ReactComponent as IconChevron} from "../../images/chevron-down.svg";
import MenuCounter from "../Menu/Counter";
import MenuIcon from "../Menu/Icon";
import NmIcon from "../NmIcon";
import Ellipse from "./Ellipse";

import {isNMUsers} from "../../utils/access";
import bem from "../../utils/bem";
import {linkActiveChecker} from "../../utils/gueryStringHelper";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import {MENU_DESIGN_TYPE} from "../../constants/menu";

import {statementsInfoCountSelector} from "../../ducks/edmStatements";
import {edoDocumentInfoCountSelector} from "../../ducks/edocuments";
import {foremanNewRequestsAndPaymentsCountSelector} from "../../ducks/foremanRequests";

import PropTypes from "prop-types";

import "./style.sass";

const MENU_WIDTH = 72;

export const MenuItem = (props) => {
    const {
        className = "",
        content = "",
        icon,
        childMenu,
        isActiveLink,
        onLinkClick = () => {},
        innerContent,
        onClick,
        openChild,
        hasCancelledChecksIndicator,
        to,
        statementsInfoCount,
        documentInfoCount,
        setChildMenuPosition,
        childMenuPosition,
        newRequestsAndPaymentsCount,
        designMenuType,
        preDesktop,
    } = props;

    const [block, element] = bem("menu-item", className);
    const pathname = useSelector(state => state.router.location.pathname);
    const isMtsMenu = designMenuType === MENU_DESIGN_TYPE.MTS_BANK_MENU && !isNMUsers();
    const isRosKapStroiMenu = designMenuType === MENU_DESIGN_TYPE.ROS_KAP_STROI_MENU && !isNMUsers();
    const isHelpResourceMenu = designMenuType === MENU_DESIGN_TYPE.HELP_RESOURCE_MENU && !isNMUsers();
    const isPromopoiskMenu = designMenuType === MENU_DESIGN_TYPE.PROMOPOISK_MENU && !isNMUsers();
    const isHoReCaMenu = designMenuType === MENU_DESIGN_TYPE.HO_RE_CA_MENU && !isNMUsers();

    const renderChildCounter = (count) => {
        if (isNullOrWhitespace(count) || count === 0) {
            return null;
        }

        return (
            <MenuCounter
                count={count}
                children
            />
        );
    };

    const getChildInnerContent = (item) => {
        const {
            id,
        } = item;

        if (hasCancelledChecksIndicator && id === "canceledChecks") {
            return (
                <NmIcon
                    className="menu-item__canceled-checks-indicator"
                    name="canceled-checks-indicator"
                />
            );
        }

        if (id === "brigade") {
            return renderChildCounter(newRequestsAndPaymentsCount);
        }

        if (id === "STATEMENTS") {
            return renderChildCounter(statementsInfoCount);
        }

        if (id === "DOCUMENTS") {
            return renderChildCounter(documentInfoCount);
        }
    };

    const _onClick = () => {
        if (childMenu) {
            onClick();

            return;
        }

        onLinkClick();
    };

    const getLinkContent = () => {
        return (
            <>
                <MenuIcon
                    className={element("icon")}
                    name={icon}
                />
                <div className={element("link-content")}>
                    {content}
                </div>
                {
                    childMenu &&
                    <IconChevron
                        className={element("arrow", {open: openChild})}
                    />
                }
                {innerContent}
            </>
        );
    };

    const getLink = () => {
        const renderedComponent = childMenu ? "div" : Link;

        return React.createElement(renderedComponent, {
            onClick: _onClick,
            to: childMenu ? undefined : to,
            className: element("link", {
                active: isActiveLink,
                mts: isMtsMenu,
                rosKapStroi: isRosKapStroiMenu,
                helpResource: isHelpResourceMenu,
                promopoisk: isPromopoiskMenu,
                hoReCa: isHoReCaMenu,
                filledActive: isActiveLink && !childMenu,
            }),
            children: getLinkContent(),
        });
    };

    const onMouseEnterMenuItem = (event) => {
        event.persist();

        const elem = document.elementFromPoint(event.clientX, event.clientY);

        if (elem?.className?.includes("menu-item__link")) {
            const coords = elem.getBoundingClientRect();

            setChildMenuPosition({top: coords.top});
        } else {
            setChildMenuPosition({top: 0});
        }

        if (childMenu) {
            onClick();
        }
    };

    const onMouseLeaveMenuItem = () => {
        if (childMenu) {
            onClick();
        }

        if (!isEmpty(childMenuPosition) ){
            setChildMenuPosition({});
        }
    };

    const getChildMenu = () => {
        if (preDesktop && isEmpty(childMenuPosition)) {
            return null;
        }

        if (childMenu === undefined || !openChild) {
            return null;
        }

        return (
            <div
                style={preDesktop ? {top: childMenuPosition.top, left: MENU_WIDTH} : undefined}
                onScroll={(event) => {
                    // для остановки всплытия события скролла у контейнера с пунктами меню
                    event.stopPropagation();
                }}
                className={element("child-menu")}
            >
                <div className={element("child-menu-header")}>
                    {content}
                </div>
                <div className={element("child-menu-container")}>
                    {
                        childMenu.map((value, index) => {
                            const isActive = linkActiveChecker(pathname, {
                                activePath: value.activePath,
                                to: value.to,
                                activeExceptions: value.activeExceptions,
                            });

                            return (
                                <Link
                                    className={element("child-menu-item", {
                                        active: isActive,
                                        mts: isMtsMenu,
                                        rosKapStroiActive: isRosKapStroiMenu,
                                        helpResourceActive: isHelpResourceMenu,
                                        promopoiskActive: isPromopoiskMenu,
                                        hoReCaActive: isHoReCaMenu,
                                    })}
                                    key={index}
                                    to={value.to}
                                    onClick={onLinkClick}
                                >
                                    <Ellipse className={element("ellipse")} />
                                    <div className={element("child-menu-item-content")}>
                                        {value.content}
                                    </div>
                                    {getChildInnerContent(value)}
                                </Link>
                            );
                        })
                    }
                </div>
            </div>
        );
    };
    return (
        <div
            className={block({
                rosKapStroi: isRosKapStroiMenu,
                helpResource: isHelpResourceMenu,
                mts: isMtsMenu,
                promopoisk: isPromopoiskMenu,
                hoReCa: isHoReCaMenu,
                opened: isActiveLink && openChild,
                activeWithHiddenChild: isActiveLink && !openChild && childMenu !== undefined,
                activeWithoutChild: isActiveLink && !childMenu,
            })}
            onMouseEnter={preDesktop ? onMouseEnterMenuItem : undefined}
            onMouseLeave={preDesktop ? onMouseLeaveMenuItem : undefined}
        >
            {getLink()}
            {getChildMenu()}
        </div>
    );
};

MenuItem.propTypes = {
    to: PropTypes.string,
    icon: PropTypes.string,
    textColor: PropTypes.string,
    iconSize: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
    content: PropTypes.string,
    innerContent: PropTypes.object,
};

export default connect(
    state => ({
        statementsInfoCount: statementsInfoCountSelector(state),
        documentInfoCount: edoDocumentInfoCountSelector(state),
        newRequestsAndPaymentsCount: foremanNewRequestsAndPaymentsCountSelector(state),
    }),
)(MenuItem);