import React, {ReactNode} from "react";

import HelpTooltip from "../HelpTooltip";
import NmTooltip, {TNmTooltipBody, TNmTooltipPosition, TNmTooltipType} from "../NmTooltip";

import bem from "../../../utils/bem";

import "./style.sass";

export type TNmCheckboxParams = {name: string, value: boolean, checked: boolean};

interface INmCheckboxV2 {
    checked?: boolean,
    className?: string,
    disabled?: boolean,
    onChange(event: React.ChangeEvent<HTMLInputElement>, params: any): void,
    name?: string,
    label?: string | ReactNode,
    toggle?: boolean,
    duplex?: boolean,
    error?: string,
    classNameLabel?: string,
    value?: string,
    errorStyle?: any,
    help?: boolean,
    text?: any,
    children?: any,
    info?: boolean,
    attention?: boolean,
    position?: TNmTooltipPosition,
    body?: TNmTooltipBody,
    tooltipType?: TNmTooltipType,
    tooltipText?: any,
    suggestion?: ReactNode,
    onMouseEnter?: (event: any) => void,
    tooltip?: ReactNode,
    classNameBox?: string,
    isVisibleTooltip?: boolean,
    isVisibleIcon?: boolean,
    tooltipOutline?: boolean,
    additionalLabelContent?: ReactNode,
    isAlignItemsCenter?: boolean,
    indeterminate?: boolean,
}

const NmCheckboxV2: React.FC<INmCheckboxV2> = (props) => {
    const {
        checked,
        className,
        disabled,
        onChange,
        name,
        label,
        additionalLabelContent,
        toggle,
        duplex,
        error,
        errorStyle,
        classNameLabel,
        value,
        info,
        help,
        tooltipOutline = true,
        attention,
        position,
        tooltipType,
        tooltipText,
        onMouseEnter,
        suggestion,
        isVisibleTooltip,
        classNameBox = "",
        tooltip,
        isAlignItemsCenter = false,
        indeterminate,
    } = props;
    const [block, element] = bem("nm-checkbox-v2", className);

    const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        !disabled && onChange && onChange(event, {name, checked: !checked, value});
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            className={block({itemsCenter: isAlignItemsCenter})}
        >
            <label
                className={`${element("box", {
                    checked, 
                    disabled, 
                    toggle, 
                    duplex,
                    indeterminate,
                    error: !!error || Boolean(errorStyle),
                })} ${classNameBox}`}
            >
                <input
                    type="checkbox"
                    className={`${element("checkbox")}`}
                    checked={checked}
                    onChange={handleChangeChecked}
                />
                {
                    label &&
                    <span className={`${element("label")} ${classNameLabel}`}>
                        {label}
                    </span>
                }
                {
                    Boolean(tooltipText) &&
                    <HelpTooltip
                        text={tooltipText}
                        info={info}
                        help={help}
                        type={tooltipType}
                        attention={attention}
                        position={position}
                        className={element("help")}
                        outline={tooltipOutline}
                        hover
                    />
                }
            </label>
            {
                additionalLabelContent &&
                <div className={element("additional-label-content")}>
                    {additionalLabelContent}
                </div>
            }
            {
                tooltip && isVisibleTooltip &&
                <div className={element("help")}>
                    {tooltip}
                </div>
            }
            {
                typeof error === "string" &&
                <NmTooltip
                    position="bottom-left"
                    children={error}
                    type="error"
                />
            }
            {
                suggestion &&
                <div className={element("suggestion")}>
                    {suggestion}
                </div>
            }
        </div>
    );
};

export default NmCheckboxV2;