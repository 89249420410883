import {getPaymentStatusChecksFailed} from "./getStatusChecksFailed";

import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    STATUS_PAYMENT_COLOR_TRANSCRIPTION, STATUS_PAYMENT_INDIVIDUAL_TRANSCRIPTION,
    STATUS_PAYMENT_TRANSCRIPTION,
} from "../../../../constants/status";

export const getPaymentStatus = ({isClientRole, paymentStatus: _paymentStatus, orderWorkReportType}) => {
    const {
        value: paymentStatus,
    } = _paymentStatus || {};

    const statusChecksFailed = getPaymentStatusChecksFailed(paymentStatus);

    if (statusChecksFailed) {
        return {
            status: statusChecksFailed,
            color: "gray",
        };
    }

    if (orderWorkReportType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
        return {
            status: STATUS_PAYMENT_INDIVIDUAL_TRANSCRIPTION[paymentStatus],
            color: STATUS_PAYMENT_COLOR_TRANSCRIPTION[paymentStatus] || "gray",
        };
    }

    return {
        status: STATUS_PAYMENT_TRANSCRIPTION[paymentStatus],
        color: STATUS_PAYMENT_COLOR_TRANSCRIPTION[paymentStatus] || "gray",
    };
};