import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/recruitment/vacancyResponses";

//POST /bff/adm/recruitment/vacancyResponses/getPage
const getPageRecruitmentVacancyResponses = function* ({payload}) {
    const {
        isLoadMore,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_SUCCESS,
            payload: {
                ...result,
                isLoadMore,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR,
        });
    }
};

//DELETE /bff/adm/recruitment/vacancyResponses/{id}
const deleteRecruitmentVacancyResponses = function* ({payload}) {
    try {
        const {
            responseId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/${responseId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_ERROR,
        });
    }
};

//POST /bff/adm/recruitment/vacancyResponses/{id}/setCandidate
// Установить связь отклика и кандидата
const setCandidateRecruitmentVacancyResponse = function* ({payload}) {
    const {
        onSuccess,
        responseId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${responseId}/setCandidate`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyResponses/{id}/jobBoards/headHunter/setStatus
// Установить статус на HeadHunter (повторная отправка)
const setRecruitmentVacancyResponsesHeadHunterStatusSaga = function* ({payload}) {
    const {
        responseId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${responseId}/jobBoards/headHunter/setStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyResponses/{id}/card
// Получить карточку отклика
const getRecruitmentVacancyResponseCardByIdSaga = function* ({payload}) {
    const {
        responseId,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${responseId}/card`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR,
        });
    }
};

// PUT /bff/adm/recruitment/vacancyResponse/{id}
// Редактирование отклика
const updateRecruitmentResponseSaga = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyResponses/${id}/uploadFile
// Добавить файл
const uploadRecruitmentVacancyResponsesFileSaga = function* ({payload}) {
    const {
        formData,
        responseId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${responseId}/uploadFile`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/recruitment/vacancyResponses/${id}/deleteFile
// Удалить файл
const deleteRecruitmentVacancyResponsesFileSaga = function* ({payload}) {
    const {
        onSuccess,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${candidateId}/deleteFile`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyResponses/{id}/reject
// Установить статус отказ отклику
const rejectRecruitmentResponseSaga = function* ({payload}) {
    const {
        responseId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${responseId}/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyResponses/{id}/jobBoards/headHunter/setStatus
// Установить статус на HeadHunter (повторная отправка)
const setRecruitmentVacancyResponseStatusSaga = function* ({payload}) {
    const {
        responseId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${responseId}/setStatus`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST, getPageRecruitmentVacancyResponses),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_REQUEST, deleteRecruitmentVacancyResponses),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST, setCandidateRecruitmentVacancyResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST, setRecruitmentVacancyResponsesHeadHunterStatusSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST, getRecruitmentVacancyResponseCardByIdSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST, updateRecruitmentResponseSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST, uploadRecruitmentVacancyResponsesFileSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST, deleteRecruitmentVacancyResponsesFileSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST, rejectRecruitmentResponseSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST, setRecruitmentVacancyResponseStatusSaga),
    ]);
}