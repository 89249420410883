import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const vacancyCandidatesCommentsSelector = state => state.bff.recruitment.vacancyCandidatesComments;
export const bffRecruitmentVacancyCandidatesCommentsProgressActionSelector = createSelector(vacancyCandidatesCommentsSelector, ({progressAction}) => progressAction);
export const bffRecruitmentVacancyCandidatesCommentsProgressSelector = createSelector(vacancyCandidatesCommentsSelector, ({progress}) => progress);
export const bffRecruitmentVacancyCandidatesCommentsErrorSelector = createSelector(vacancyCandidatesCommentsSelector, ({error}) => error);
export const bffRecruitmentVacancyCandidatesCommentsListSelector = createSelector(vacancyCandidatesCommentsSelector, ({list}) => list);
export const bffRecruitmentVacancyCandidatesCommentsTotalPagesSelector = createSelector(
    vacancyCandidatesCommentsSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffRecruitmentVacancyCandidatesCommentsPageDataSelector = createSelector(vacancyCandidatesCommentsSelector, ({pageData}) => pageData);