export const REQUISITES_FIELD_NAME = {
    OPERATOR: "operator",
    REPRESENTATIVE_FIRST_NAME: "representativeFirstName",
    REPRESENTATIVE_LAST_NAME: "representativeLastName",
    REPRESENTATIVE_PATRONYMIC: "representativePatronymic",
    NAMEMIX_REPRESENTATIVE_FIO: "namemixRepresentativeFio",
    REPRESENTATIVE_DOCUMENT: "representativeDocument",
    WEB_SITE: "webSite",
    INTEGRATED_SOFT: "integratedSoft",
    NAME: "name",
    ADDRESS: "address",
    MAIL_ADDRESS: "mailAddress",
    PHONE: "phone",
    EMAIL: "email",
    OGRN: "orgn",
    INN: "inn",
    KPP: "kpp",
    RS: "rs",
    BANK_NAME: "bankName",
    KS: "ks",
    BIK: "bik",
    POSITION: "position",
    DOCUMENT: "document",
    FAX: "fax",
};