import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import CodeConfirm from "../../../../../components/CodeConfirm";

import {useInterval} from "../../../../../hooks/useInterval";

import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {PHONE_CODE_TYPE, RUSSIA_PHONE_CODE_ID} from "../../../../../constants/phone";

import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {sendCodeVerification, signEdoDocument} from "../../../../../ducks/edocuments";

const DocumentManagementDocumentListCodeConfirm = (props) => {
    const {
        onClose = () => {
        },
        documentIds,
        fetchList,
    } = props;

    const [code, setCode] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [codeConfirmType, setCodeConfirmType] = useState(PHONE_CODE_TYPE.SMS);

    const DEFAULT_TIME = 60;

    const {phone} = useSelector(clientCurrentMemberSelector);

    const [timer, setTimer] = useState(DEFAULT_TIME);

    const dispatch = useDispatch();

    useEffect(() => {
        sendCode();
    }, []);

    const sendCode = (codeType) => {
        dispatch(sendCodeVerification({
            codeType,
            phoneCodeId: RUSSIA_PHONE_CODE_ID,
            getResult: ({codeType}) => {
                setCodeConfirmType(codeType);
                setTimer(DEFAULT_TIME);
            },
        }));
    };

    useInterval(() => {
        if (timer > 0) {
            setTimer(timer - 1);
        }
    }, 1000);

    const onChange = (value) => {
        errorMessage && setErrorMessage("");
        setCode(value);
    };

    const submit = () => {
        if (errorMessage) {
            return;
        }

        if (isNullOrWhitespace(code)) {
            setErrorMessage("Обязательное поле");
            return;
        }

        setLoading(true);

        const payload = {
            smsCode: code,
            documentIds,
            getResult: (errorMessage) => {
                setLoading(false);
                if (isNullOrWhitespace(errorMessage)) {
                    fetchList();
                    onClose();
                    return;
                }
                setErrorMessage(errorMessage);
            },
        };

        dispatch(signEdoDocument(payload));
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <CodeConfirm
            code={code}
            title="Подписание документа"
            onClose={handleClose}
            sendConfirmationCodeToUser={sendCode}
            submitCode={submit}
            onChange={onChange}
            phone={phone}
            timer={timer}
            errorMessage={errorMessage}
            loading={loading}
            codeConfirmType={codeConfirmType}
        />
    );
};

export default DocumentManagementDocumentListCodeConfirm;