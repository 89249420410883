import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import RejectionReason from "../../../components/RejectionReason";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import ClientFinancePaymentListTabs from "../finance-payment-list/client-tabs";
import FinanceCrowdPaymentsLogModal from "./components/log-modal";

import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import useFinanceCrowdPaymentsAction from "./hooks/useAction";
import useFinanceCrowdPaymentsFetch from "./hooks/useFetch";
import useFinanceCrowdPaymentsFilter from "./hooks/useFilter";

import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {floatToPercent} from "../../../utils/mathHelper";
import {formatAmount, formatAmountWithNullChecking, formatNumber} from "../../../utils/stringFormat";
import {getClientNameWithBrand} from "../../../utils/stringHelper";
import {getStatusTooltipText} from "./utils/getCrowdPaymentStatusTooltipText";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CONTRACTOR_PROFILE,
} from "../../../constants/links";
import {ADMIN, CLIENT_ACCOUNTANT, CLIENT_ADMIN, NM_MANAGER, PROJECT_MANAGER} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {
    bffCrowdPaymentListSelector,
    bffCrowdPaymentProgressActionSelector,
    bffCrowdPaymentProgressSelector,
    bffCrowdPaymentTotalCountSelector,
    bffCrowdPaymentTotalPagesSelector,
} from "../../../ducks/bff/crowd/payment/selectors";

import {STATUS_TASK_PAYMENT} from "../../../constants/crowd/task-payment";
import {SUB_PAGE_CROWD_TASK_CARD} from "../../../constants/link-params";

const FinanceCrowdPayments = (props) => {
    const {
        match: {
            params: {
                clientId,
                paymentNumberFilter,
            },
        },
    } = props;

    const list = useSelector(bffCrowdPaymentListSelector);
    const totalCount = useSelector(bffCrowdPaymentTotalCountSelector);
    const totalPages = useSelector(bffCrowdPaymentTotalPagesSelector);
    const progress = useSelector(bffCrowdPaymentProgressSelector);
    const progressAction = useSelector(bffCrowdPaymentProgressActionSelector);

    const role = ls(USER_ROLE);
    const isAccessMassAction = [ADMIN].includes(role);
    const validationFailedPaymentIds = list
        .filter(({status}) => status === STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE)
        .map(({paymentId}) => paymentId);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const paymentsForNaimixApproveSelected = selectedList
        .filter(({isSelected, status}) => isSelected && [
            STATUS_TASK_PAYMENT.FOR_NAIMIX_APPROVE.VALUE,
            STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
        ].includes(status));

    const {
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
        filters,
        setFilter,
    } = useFinanceCrowdPaymentsFilter({
        pageSize,
        setPagination,
        clientId,
        paymentNumberFilter,
    });

    const {
        fetchList,
    } = useFinanceCrowdPaymentsFetch({
        pageNum,
        pageSize,
        filter: filterData,
        clientId,
        paymentNumberFilter,
    });

    const {
        getListMediaControls,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
        massRejectPayments,
        massApprovePayments,
        logModalData,
        setLogModalData,
        revalidatePayments,
    } = useFinanceCrowdPaymentsAction({
        selectedList,
        clearSelectedRows,
        fetchList,
        validationFailedPaymentIds,
    });

    const getClientLink = ({clientName, clientId, brand}) => {
        const link = LINK_CLIENT_INFO.replace(":clientId", clientId);
        const nameWithBrand = getClientNameWithBrand(clientName, brand);

        return (<ExtLink
            title={nameWithBrand}
            pageData={{
                pageNum,
                pageSize,
            }}
            historyEnabled
            to={link}
        >
            {nameWithBrand}
        </ExtLink>);
    };

    const getProjectLink = ({clientId, projectId, projectName}) => {
        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId);

        return (
            <ExtLink
                title={projectName}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {projectName}
            </ExtLink>
        );
    };

    const getObjectLink = ({clientId, projectId, objectId, objectName}) => {
        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                title={objectName}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {objectName}
            </ExtLink>
        );
    };

    const getTaskLink = ({clientId, taskId, taskNumber, taskName}) => {
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK)
            .replace(":clientId", clientId)
            .replace(":taskId", taskId);

        const text = `${taskNumber} ${taskName}`;

        return (
            <ExtLink
                title={text}
                pageData={{
                    pageSize,
                    pageNum,
                    isSearch,
                }}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {text}
            </ExtLink>
        );
    };

    const onClickContractorLink = ({contractorId}) => {
        const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

        history.push(link);
    };

    const getRows = () => {
        return list.map(item => {
            const {
                paymentId,
                status,
                createDate,
                updateDate,
                description,
                contractorName,
                amount,
                clientCommission,
                clientCommissionRate,
                paymentNumber,
            } = item;

            const {isSelected = false} = selectedList.find(_item => (_item.paymentId === paymentId)) || {};
            const disabledCheckBox = [
                STATUS_TASK_PAYMENT.SUCCESSFUL.VALUE,
                STATUS_TASK_PAYMENT.CANCELLED.VALUE,
                STATUS_TASK_PAYMENT.FNS_DECLARATION.VALUE,
                STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
                STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
            ].includes(status);
            const statusTooltipText = getStatusTooltipText(item);

            return {
                ...item,
                key: paymentId,
                showCheckBox: !clientId && isAccessMassAction,
                disabledCheckBox,
                isSelected,
                contentRow: (
                    <NmListCard
                        checkbox={!clientId}
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={STATUS_TASK_PAYMENT[status]?.TEXT || status}
                                mod={STATUS_TASK_PAYMENT[status]?.COLOR || "gray"}
                                rightTooltipText={statusTooltipText}
                                tooltipPosition="bottom"
                                rightTooltipProps={
                                    statusTooltipText && {
                                        hover: true,
                                    }
                                }
                            />
                        }
                        secondaryHeader={
                            `Создано: ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")} Обновлено: ${formatLocalDate(updateDate, "dd.MM.yyyy HH:mm")}`
                        }
                        primaryHeader={contractorName}
                        primaryHeaderLink={true}
                        onClickLink={() => onClickContractorLink(item)}
                        classNameMainContent="col-16 col-xxl-5"
                        labels={[
                            !clientId && {
                                label: "Заказчик",
                                text: getClientLink(item),
                            },
                            {
                                label: "Номер оплаты",
                                text: paymentNumber || "-",
                            },
                            {
                                label: "Номер и наим. задания",
                                text: getTaskLink(item),
                            },
                            {
                                label: "Описание платежа",
                                text: description || "-",
                            },
                            {
                                label: "Проект",
                                text: getProjectLink(item),
                            },
                            {
                                label: "Объект",
                                text: getObjectLink(item),
                            },
                        ]}
                        cards={
                            [
                                {
                                    title: "Сумма операции, ₽",
                                    value: amount ? formatAmount(formatNumber(amount, 2)) : "-",
                                    className: "col-16 col-md-4 col-xxl-3 mt-md-4 mt-xxl-0",
                                },
                                {
                                    title: "Комиссия заказчика, ₽",
                                    value: `${formatAmountWithNullChecking(clientCommission)} (${floatToPercent(clientCommissionRate)}%)`,
                                    className: "col-16 col-md-4 col-xxl-5 mt-md-4 mt-xxl-0",
                                },
                            ]
                        }
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-10 align-items-end"
                        mediaControls={getListMediaControls(item)}
                        actionsClassName="col-1 justify-content-end"
                    />
                ),
            };
        });
    };

    const rejectReasonModal = () => {
        return (
            rejectReasonModalData.isOpen &&
            <RejectionReason
                title="Причина отклонения"
                placeholder="Введите причину отклонения оплаты"
                close={() => setRejectReasonModal({})}
                submit={(rejectReason) => {
                    if (rejectReasonModalData.paymentId) {
                        rejectPayment({
                            paymentId: rejectReasonModalData.paymentId,
                            rejectReason,
                        });

                        return;
                    }

                    massRejectPayments(rejectReason);
                }}
            />
        );
    };

    const renderLogModal = () => {
        return (
            !isEmpty(logModalData) &&
            <FinanceCrowdPaymentsLogModal
                paymentData={logModalData}
                onClose={() => setLogModalData({})}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    children={
                        clientId ?
                            "Выплаты исполнителям (НПД)" :
                            "Оплаты по заданиям"
                    }
                    count={clientId && totalCount}
                />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                    updateFilter={(filter) => setFilter(filter)}
                />
            }
            mediaControls={{
                renderCount: {
                    mobile: 0,
                    tablet: 1,
                    desktop: 1,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON_WITH_TOOLTIP,
                        props: {
                            onClick: revalidatePayments,
                            size: "xl",
                            children: "Проверить повторно",
                            color: "grey",
                            disabled: progressAction,
                            tooltip: (
                                <HelpTooltip
                                    width={24}
                                    height={24}
                                    info={true}
                                    text={"По нажатию запускается проверка всех оплат по заданиям в статусе “Проверка не пройдена”. Оплаты с успешным завершением проверки будут отправлены исполнителям"}
                                    position="bottom-right"
                                />
                            ),
                        },
                        visible: clientId
                            && Boolean(validationFailedPaymentIds.length)
                            && [
                                ADMIN,
                                CLIENT_ADMIN,
                                CLIENT_ACCOUNTANT,
                                PROJECT_MANAGER,
                            ].includes(role),
                    },
                ],
            }}
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {
                clientId &&
                <ClientFinancePaymentListTabs
                    {...props}
                />
            }
            {rejectReasonModal()}
            {renderLogModal()}
            {
                list.length ?
                    <CheckboxList
                        header={
                            !clientId && isAccessMassAction &&
                            <SelectionCountWithAction
                                adaptiveLogic={true}
                                count={countSelected}
                                disabled={!paymentsForNaimixApproveSelected.length}
                                buttonColor="grey"
                                buttonContent="Подтвердить"
                                onClick={massApprovePayments}
                                otherActions={
                                    <NmButton
                                        disabled={countSelected === 0}
                                        size="lg"
                                        color="grey"
                                        onClick={() => setRejectReasonModal({isOpen: true})}
                                    >
                                        Отменить
                                    </NmButton>
                                }
                            />
                        }
                        actionOptions={[
                            {
                                text: "Подтвердить",
                                onClick: massApprovePayments,
                                disabled: !paymentsForNaimixApproveSelected.length,
                            },
                            {
                                text: "Отменить",
                                onClick: () => setRejectReasonModal({isOpen: true}),
                                disabled: countSelected === 0,
                            },
                        ]}
                        rows={getRows()}
                        onSelectedRows={!clientId && isAccessMassAction && handleSelectedRows}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default FinanceCrowdPayments;