import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_ERROR,
    BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_REQUEST,
    BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_GET_ERROR,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_GET_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_WEBHOOKS_GET_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "adm/recruitment/directory";

// POST /bff/recruitment/directory/status/search
// Получение списка статусов
const getPageRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/status/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/recruitment/directory/status
// Добавление статуса
const addRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
        });
    }
};

// PUT /bff/recruitment/directory/status/{id}
// Редактирование статуса
const updateRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/status/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
        });
    }
};

// DELETE /bff/recruitment/directory/status/{id}
// Удаление статуса
const deleteRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/status/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
        });
    }
};

// POST /bff/recruitment/directory/vacancyRequirement/search
// Получение списка дополнительных требований
const getPageRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/vacancyRequirement/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/recruitment/directory/vacancyRequirement
// Добавление дополнительного требования
const addRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/vacancyRequirement`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
        });
    }
};

// PUT /bff/recruitment/directory/vacancyRequirement/{id}
// Редактирование дополнительного требования
const updateRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/vacancyRequirement/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
        });
    }
};

// DELETE /bff/recruitment/directory/vacancyRequirement/{id}
// Удаление дополнительного требования
const deleteRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/vacancyRequirement/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
        });
    }
};

// POST /bff/recruitment/directory/funnel/search
// Получение списка воронок
const getPageRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/funnel/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/recruitment/directory/funnel
// Добавление воронки
const addRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/funnel`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
        });
    }
};

// PUT /bff/recruitment/directory/funnel/{id}
// Редактирование воронки
const updateRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/funnel/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
        });
    }
};

// DELETE /bff/recruitment/directory/funnel/{id}
// Удаление воронки
const deleteRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${controller}/funnel/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
        });
    }
};

// POST /bff/recruitment/directory/totalCounts
const getCountsRecruitmentDirectory = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/totalCounts`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/directory/avito/professions
// Получить список профессий Авито
const getAvitoProfessionsSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/avito/professions`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/directory/avito/schedules
// Получить справочник График работы Авито
const getAvitoSchedulesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/avito/schedules`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/directory/avito/experiences
// Получить справочник Опыт работы Авито
const getAvitoExperiencesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/avito/experiences`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/directory/avito/billingTypes
// Получить справочник Тип размещения Авито
const getAvitoBillingTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/avito/billingTypes`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/directory/avito/business-areas
// Получить весь справочник Виды деятельности Авито, включая устаревшие
const getAvitoBusinessAreasSaga = function* (payload) {
    const {
        isActual,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/avito/business-areas`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR,
        });
    }
};

// /bff/adm/recruitment/directory/avito/new-business-areas
// Получить новый справочник Виды деятельности Авито
const getAvitoActualBusinessAreas = function* () {
    try {
        const result = yield request.bff.get(`${controller}/avito/new-business-areas`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/directory/rejection-reason/search
// Получить список причин отказа
const searchRecruitmentRejectionReason = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/rejection-reason/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/directory/rejection-reason
// Добавить причину отказа
const addRecruitmentRejectionReason = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/rejection-reason`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
        });
    }
};

// PUT /bff/adm/recruitment/directory/rejection-reason/{id}
// Редактировать причину отказа
const editRecruitmentRejectionReason = function* ({payload}) {
    const {
        id,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/rejection-reason/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
        });
    }
};

// DELETE /bff/adm/recruitment/directory/rejection-reason/{id}
// Удалить причину отказа
const deleteRecruitmentRejectionReason = function* ({payload}) {
    const {
        id,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/rejection-reason/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/directory/vacancy-branded-templates
// Получить список брендированных шаблонов с HH
export const getHHVacancyBrandedTemplatesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/vacancy-branded-templates`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST, getPageRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST, addRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST, updateRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST, deleteRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST, getPageRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST, addRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST, updateRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST, deleteRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST, getPageRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST, addRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST, updateRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST, deleteRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST, getCountsRecruitmentDirectory),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST, getAvitoSchedulesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST, getAvitoExperiencesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST, getAvitoBillingTypesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST, getAvitoBusinessAreasSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST, getAvitoActualBusinessAreas),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST, getAvitoProfessionsSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST, searchRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST, addRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST, editRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST, deleteRecruitmentRejectionReason),
        takeEvery(BFF_GET_HH_VACANCY_BRANDED_TEMPLATES_REQUEST, getHHVacancyBrandedTemplatesSaga),
    ]);
}