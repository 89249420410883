import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import {useSaveScrollNavigation} from "../../../hooks/useSaveScrollNavigation";

export const useBackPrevPage = () => {
    const {
        isState,
        state,
        navigate,
    } = useSaveScrollNavigation();

    const [prevPageInfo, setPrevPageInfo] = useState({});

    useEffect(() => {
        if (!isState) {
            return;
        }

        setPrevPageInfo({
            filterData: state.filterData,
            scrollTop: state.scrollTop,
            paginationData: state.paginationData,
        });
    }, []);

    const navigateBack = ({pathname}) => {
        if (isEmpty(prevPageInfo)) {
            navigate({
                pathname,
            });

            return;
        }

        navigate({
            pathname,
            filterData: prevPageInfo.filterData,
            paginationData: prevPageInfo.paginationData,
            scrollTop: prevPageInfo.scrollTop,
        });
    };

    return {
        navigateBack,
    };
};