import {useSelector} from "react-redux";

import {bffRecruitmentVacancyCandidatesCardSelector} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {history} from "../../../../store/configureStore";

import {useModal} from "../../../../hooks/useModal";

import {isVisibleRecruitmentActions} from "../../../../utils/access";

import {LINK_CLIENT_RECRUITMENT_CANDIDATES} from "../../../../constants/links";

export const useRecruitmentCandidatesCardHeaderActions = (params) => {
    const {
        clientId,
    } = params;

    const card = useSelector(bffRecruitmentVacancyCandidatesCardSelector);

    const {
        isOpen: isOpenEditPersonalData,
        modalData: editPersonalData,
        onOpenModal: onOpenEditPersonalData,
        onCloseModal: onCloseEditPersonalData,
    } = useModal();

    const onClickEditPersonalData = () => {
        onOpenEditPersonalData(card);
    };

    const onClickBack = () => {
        history.push(LINK_CLIENT_RECRUITMENT_CANDIDATES.replace(":clientId", clientId));
    };

    return {
        onClickEditPersonalData,
        onClickBack,
        isOpenEditPersonalData,
        onCloseEditPersonalData,
        editPersonalData,
    };
};