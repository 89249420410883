import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {handleFormString} from "../../../../utils/stringHelper";

import {regBrand} from "../../../../constants/validationRules";

import {
    getClientCardSelector,
    updateClient,
} from "../../../../ducks/client";

const AnalyticsBrandBlock = props => {
    const {
        isEditable,
    } = props;

    const dispatch = useDispatch();

    const clientCardData = useSelector(getClientCardSelector);

    const initialValues = {
        isEdit: false,
        brandForAnalytics: clientCardData.brandForAnalytics,
    };

    const onSubmit = () => {
        dispatch(updateClient({
            ...clientCardData,
            brandForAnalytics: handleFormString(values.brandForAnalytics),
            onSuccess: () => setFieldValue("isEdit",false),
        }));
    };

    const {
        values,
        setValues,
        setFieldValue,
        handleChange,
        handleSubmit,
        errors,
        touched,
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema: yup.object().shape({
            brandForAnalytics: yup.string()
                .nullable()
                .transform(handleFormString)
                .max(255, "Максимальная длина - 255 символов")
                .matches(regBrand, {
                    message: "Допускается ввод цифр, кириллицы и латиницы, знаков . , ? ! ; : - ( ) \" \" « »",
                }),
        }),
        enableReinitialize: true,
    });

    const {
        isEdit,
        brandForAnalytics,
    } = values;

    const cancelEditMode = () => {
        setFieldValue("isEdit",false);
        setValues(initialValues);
    };

    const toggleCard = () => {
        if (isEdit) {
            handleSubmit();

            return;
        }

        setValues(initialValues);
        setFieldValue("isEdit", !isEdit);
    };

    const renderReadOnlyCard = () => {
        return (
            <LabelTextApp
                label="Название бренда"
                className="client-card-block__label"
                text={clientCardData.brandForAnalytics || "-"}
            />
        );
    };

    const renderEditModeCard = () => {
        return (
            <NmForm addMargin>
                <NmInputV2
                    size="lg"
                    label="Название бренда"
                    placeholder="Введите название бренда"
                    value={brandForAnalytics}
                    name="brandForAnalytics"
                    onChange={handleChange}
                    error={touched.brandForAnalytics && errors.brandForAnalytics}
                    maxLength={255}
                />
            </NmForm>
        );
    };


    return (
        <CardApp
            title="Бренд для аналитики"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEditMode}
            isEdit={isEdit}
        >
            {
                isEdit ?
                    renderEditModeCard() :
                    renderReadOnlyCard()
            }
        </CardApp>
    );
};

export default AnalyticsBrandBlock;