import React, {ReactNode} from "react";

import {ReactComponent as ModeIcon} from "../../../images/mode.svg";

import bem from "../../../utils/bem";

import "./style.sass";

interface IBox {
    align?: "flex-start" | "flex-end" | "center",
    alignItems?: "baseline" | "center" | "flex-start" | "flex-end",
    children: ReactNode,
    className?: string,
    theme?: "default" | "list" | "page",
    isEdit?: boolean,
    title?: ReactNode | string,
    onClickEdit?: () => void,
    onClick?: () => void,
    noBorder?: boolean,
    noRadius?: boolean,
    backgroundColor?: string,
    borderColor?: string,
}

const Box = (props: IBox) => {
    const {
        children,
        className = "",
        isEdit,
        onClickEdit,
        title,
        theme = "default",
        noBorder,
        noRadius,
        onClick,
        backgroundColor,
        borderColor,
    } = props;

    const [block, element] = bem("box", className);

    return (
        <div
            style={{
                cursor: onClick ? "pointer" : undefined,
                backgroundColor,
                borderColor,
            }}
            className={block({
                theme,
                noBorder,
                noRadius,
            })}
            onClick={onClick}
        >
            {
                title &&
                    <div className={element("header")}>
                        <div className={element("name")}>
                            {title}
                        </div>
                        {
                            isEdit &&
                            <ModeIcon
                                className="box__edit"
                                onClick={onClickEdit}
                            />
                        }
                    </div>
            }
            {children}
        </div>
    );
};

export default Box;