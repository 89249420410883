import {isEmpty} from "lodash";

import {StatementLoadingFormType} from "../../loading-form";

import {STATEMENT_LOADING_TYPE} from "../../loading-form/hooks/useLoadingForm";

import {convertUtcToLocal} from "../../../../../../utils/dateFormat";

import {EDM_STATEMENT_PARTICIPANTS_TYPE} from "../../../../../../constants/document-management/statements";

export type StatementParticipantType = {
    name: string,
    clientUserId: string,
    type: string,
    rejectReason?: string,
    state: string,
    staffId: string
}

export type StatementTypeType = {
    description?: string,
    id: string,
    value: string
}

export type StatementListItemType = {
    id: string
    name: string,
    clientId: string,
    created: string,
    date: string,
    statementFile: Array<File>,
    attachmentFile: Array<File>,
    description?: string,
    participants?: StatementParticipantType[],
    type: StatementTypeType,
    statementFileName?: string,
    attachmentFileName?: string,
    param: {}
};

export default function mapItemListToForm(statementListItem: StatementListItemType): StatementLoadingFormType {
    const {
        clientId,
        id,
        date,
        name,
        participants,
        description,
        type: {
            id: statementType,
        },
        statementFileName,
        attachmentFileName,
        param,
    } = statementListItem;

    const receiverId = participants?.find(p => p.type === EDM_STATEMENT_PARTICIPANTS_TYPE.RECEIVER.VALUE)?.staffId;
    const approverIds = participants?.filter(p => p.type === EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.VALUE).map(item => item.staffId);

    return {
        receiverId,
        clientId,
        statementId: id,
        statementDateTime: convertUtcToLocal(date),
        statementName: name,
        approverIds,
        description,
        statementType,
        statementFile: statementFileName ? [{
            name: statementFileName,
            isRemote: true,
        }]: [],
        attachmentFile: attachmentFileName ? [{
            name: attachmentFileName,
            isRemote: true,
        }] : [],
        statementFileName,
        attachmentFileName,
        typeLoading: isEmpty(param) ? STATEMENT_LOADING_TYPE.SIMPLE : STATEMENT_LOADING_TYPE.TEMPLATE,
    };
}