import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../../components/ActualComponents/FilterCustomer";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import {documentTypeSignatureLogDictsOptionsSelector} from "../../../../../ducks/documents";

import PropTypes from "prop-types";

import "./style.sass";

class SigningDocumentsLogFilter extends Component {
    static propTypes = {
        sendFilter: PropTypes.func.isRequired,
        defaultFilter: PropTypes.shape({
            transactionIdFilter: PropTypes.string,
            documentIdFilter: PropTypes.string,
            fromDateFilter: PropTypes.object,
            toDateFilter: PropTypes.object,
        }),
    };

    static defaultProps = {
        sendFilter: () => {
        },
        defaultFilter: {},
    };

    constructor(props) {
        super(props);

        const {defaultFilter} = props;

        this.state = {
            filter: {...defaultFilter},
        };
    }

    handleOnChangeFilter = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    };

    sendFilter = () => {
        const {sendFilter} = this.props;

        this.setState(prevState => ({
            ...prevState,
        }), () => {
            sendFilter(this.state.filter);
        });
    };

    clearFilter = () => {
        const {
            defaultFilter,
            sendFilter,
        } = this.props;

        this.setState({
            filter: {
                ...defaultFilter,
            },
        }, () => {
            sendFilter(this.state.filter);
        });
    };

    render() {
        const {
            filter: {
                fromDateFilter,
                toDateFilter,
                documentIdFilter,
                transactionIdFilter,
                documentTypeFilter,
                clientIdFilter,
            },
        } = this.state;

        const {
            documentTypeOptions,
            t,
        } = this.props;

        return (
            <NmForm addMargin>
                <FilterCustomer
                    size="lg"
                    onChange={this.handleOnChangeFilter}
                    search
                    name="clientIdFilter"
                    value={clientIdFilter}
                />
                <NmDateRangePickerV2
                    size="lg"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{fromDateFilter, toDateFilter}}
                    isClearable
                    label="Период"
                    onChange={this.handleOnChangeFilter}
                />
                <NmInputV2
                    size="lg"
                    label="Идентификатор документа"
                    name="documentIdFilter"
                    onChange={this.handleOnChangeFilter}
                    value={documentIdFilter}
                />
                <NmInputV2
                    size="lg"
                    label="№ лога транзакции"
                    name="transactionIdFilter"
                    onChange={this.handleOnChangeFilter}
                    value={transactionIdFilter}
                />
                <NmDropdownV2
                    size="lg"
                    search
                    label="Тип документа"
                    name="documentTypeFilter"
                    onChange={this.handleOnChangeFilter}
                    noResultsMessage={t("no-search-result.content")}
                    options={documentTypeOptions}
                    value={documentTypeFilter}
                />
                <FilterButtonsV2
                    onClear={this.clearFilter}
                    onSearch={this.sendFilter}
                />
            </NmForm>
        );
    }
}

export default connect(
    state => ({
        documentTypeOptions: documentTypeSignatureLogDictsOptionsSelector(state),
    }),
    {
    },
)(withTranslation()(SigningDocumentsLogFilter));