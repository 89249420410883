export const getEditActTemplateRequestData = (params) => {
    const {
        form,
    } = params;
    const {
        estimatedTimeScore,
        workQualityScore,
        ...requestData
    } = form;

    return {
        ...requestData,
        clientId: form.clientId,
        contractorEstimatedTimeScore: estimatedTimeScore,
        contractorWorkQualityScore: workQualityScore,
        description: form.description ? form.description : null,
        descriptionFns: form.descriptionFns ? form.descriptionFns : null,
        orderId: form.orderId,
        orderWorkReportTemplateId: form.orderWorkReportTemplateId,
        reviewText: form.reviewText ? form.reviewText : null,
        actOfAcceptanceOfWorkTemplateId: null,
        workUnit: null,
        sumOfMoney: null,
        volumeOfWork: null,
        workEndDate: null,
        workStartDate: null,
        name: null,
    };
};

export const getEditActTemplateDataFromCard = (params) => {
    const {
        card,
        orderId,
    } = params;

    const {
        volumeOfWork, // не нужно заполнять
        workStartDate, // не нужно заполнять
        workEndDate, // не нужно заполнять
        sumOfMoney, // не нужно заполнять
        name,
        contractorEstimatedTimeScore,
        contractorWorkQualityScore,
        ...otherCard
    } = card;

    return {
        ...otherCard,
        estimatedTimeScore: contractorEstimatedTimeScore,
        workQualityScore: contractorWorkQualityScore,
        // берем из модели заказа
        orderId,
    };
};