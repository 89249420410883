import React, {FC, ReactNode} from "react";
import {isEmpty} from "lodash";

import {DropzoneUploadedFile} from "../uploaded-file";
import {DropzoneUploadedImagePreview} from "../uploaded-image-preview";

import bem from "../../../../../utils/bem";

import {TFileWithPreview, TLocalErrorFile} from "../../types";

import "./style.sass";

interface IDropzoneUploadedArea {
    files: Array<any>,
    className?: string,
    isFixedTooltip?: boolean,
    multiple: boolean,
    format?: string,
    maxSize?: number,
    minSize?: number,
    isDownload?: boolean,
    onDownloadFile: (file: any) => void,
    onRemoveFile: ({name, isError, isRemote}: {name: string, isError: boolean, isRemote?: boolean}) => void,
    isImagePreview?: boolean,
    isTopImagePreview?: boolean,
    errors?: Array<TLocalErrorFile>,
    isShowDeleteButton?: boolean,
    uploadedFileLabel?: string,
    onOpenFullImage?: (file: TFileWithPreview) => void,
    onCloseFullImage?: (file: TFileWithPreview) => void,
    additionalFiles?: Array<TFileWithPreview>,
    replaceButton?: ReactNode,
    isShowUploadedFileNameTitle?: boolean,
}

export const DropzoneUploadedArea: FC<IDropzoneUploadedArea> = (props) => {
    const {
        files,
        isFixedTooltip,
        uploadedFileLabel,
        isShowUploadedFileNameTitle,
        additionalFiles,
        isImagePreview,
        isTopImagePreview,
        multiple,
        isDownload,
        isShowDeleteButton,
        onDownloadFile,
        onOpenFullImage,
        onRemoveFile,
        onCloseFullImage,
        maxSize,
        minSize,
        format,
        errors,
        className = "",
        replaceButton,
    } = props;
    const [, element] = bem("dropzone-uploaded-area", className);

    const getUploadedFile = (file: any, isError: boolean, key: number) => {
        if ((isImagePreview || isTopImagePreview) && file.type?.includes("image/")) {
            return (
                <DropzoneUploadedImagePreview
                    key={key}
                    file={file}
                    isError={isError}
                    isShowDeleteButton={isShowDeleteButton}
                    isDownload={isDownload}
                    onDownloadFile={onDownloadFile}
                    onRemoveFile={onRemoveFile}
                    format={format}
                    maxSize={maxSize}
                    minSize={minSize}
                    onOpenFullImage={onOpenFullImage}
                    onCloseFullImage={onCloseFullImage}
                    isTopImagePreview={isTopImagePreview}
                    replaceButton={replaceButton}
                />
            );
        }

        return (
            <DropzoneUploadedFile
                key={key}
                file={file}
                multiple={multiple}
                isError={isError}
                isShowDeleteButton={isShowDeleteButton}
                isDownload={isDownload}
                uploadedFileLabel={uploadedFileLabel}
                isShowUploadedFileNameTitle={isShowUploadedFileNameTitle}
                onDownloadFile={onDownloadFile}
                onRemoveFile={onRemoveFile}
                format={format}
                maxSize={maxSize}
                minSize={minSize}
                isImagePreview={isImagePreview}
            />
        );
    };

    const getErrorFiles = () => {
        if (isEmpty(errors)) {
            return null;
        }

        // @ts-ignore
        return errors.map((error: TLocalErrorFile, index: number) => {
            return getUploadedFile(error, true, index);
        },
        );
    };

    const getUploadedFiles = () => {
        if (isEmpty(files)) {
            return null;
        }

        return files.map((file, index) => {
            return getUploadedFile(file, false, index);
        });
    };

    const getAdditionalFiles = () => {
        if (isEmpty(additionalFiles)) {
            return null;
        }

        return additionalFiles && additionalFiles.map((file, index) => {
            return getUploadedFile(file, false, index);
        });
    };

    return (
        <div className={element("uploaded-container", {error: isFixedTooltip})}>
            <div className={element("uploaded-files")}>
                {getErrorFiles()}
                {getUploadedFiles()}
                {getAdditionalFiles()}
            </div>
        </div>
    );
};