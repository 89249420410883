import {useMemo, useState} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {PATTERN_NUMBER} from "../../../../deposit/client-list/hooks/useGetlDepositData";

import formatDate from "../../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";
import {handleNumber, isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {SOURCE_TYPE_OPTIONS} from "../../../../../constants/finance";
import {REG_EXP} from "../../../../../constants/regExp";
import {
    STATUS_OF_TRANSFER_TRANSCRIPTION,
} from "../../../../../constants/status";

const bankStatusOptions = dictionaryToOptions(STATUS_OF_TRANSFER_TRANSCRIPTION);

export const useContractorFinanceIncomeFilter = (params) => {
    const {
        setPagination,
        pageSize,
        isShowBankStatusFilter = true,
        isShowNaimixStatusFilter = true,
        isShowRegistryNumberFilter = false,
        naimixStatusFilterOptions,
        isCrowd,
    } = params;

    const [filter, setFilter] = useState({});
    const [filterAmountBlocks, setFilterAmountBlocks] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const filtersBase = useMemo(() => {
        const customerFilter = {
            row: [
                {
                    component: FILTER.CUSTOMER,
                    isBff: true,
                    name: "clientId",
                    search: true,
                    placeholder: "Не выбран",
                },
            ],
        };

        if (isCrowd) {
            return [
                customerFilter,
                {
                    row: [
                        {
                            component: FILTER.INPUT,
                            label: "Номер оплаты по заданию",
                            placeholder: "Введите номер оплаты по заданию",
                            maskChar: null,
                            mask: "999999999999",
                            name: "paymentNumber",
                        },
                    ],
                },
                {
                    row: [
                        {
                            component: FILTER.INPUT,
                            name: "taskNumber",
                            label: "Номер задания",
                            placeholder: "Введите номер задания",
                            pattern: REG_EXP.ONLY_NUMBERS,
                        },
                    ],
                },
                isShowNaimixStatusFilter && {
                    row: [
                        {
                            component: FILTER.DROPDOWN,
                            label: "Статус Наймикс",
                            clearable: true,
                            compact: true,
                            name: "statusFilter",
                            options: naimixStatusFilterOptions,
                        },
                    ],
                },
                {
                    row: [
                        {
                            component: FILTER.DATE_RANGE,
                            startFieldName: "operationDateFrom",
                            endFieldName: "operationDateTo",
                            isClearable: true,
                            label: "Дата операции",
                        },
                    ],
                },
                {
                    row: [
                        {
                            component: FILTER.RANGE_INPUT,
                            nameStart: "amountFrom",
                            nameEnd: "amountTo",
                            valueStart: "",
                            valueEnd: "",
                            label: "Сумма операции",
                            placeholderStart: "От",
                            placeholderEnd: "До",
                            pattern: PATTERN_NUMBER,
                        },
                    ],
                },
                {
                    row: [
                        {
                            component: FILTER.RANGE_INPUT,
                            nameStart: "commissionFrom",
                            nameEnd: "commissionTo",
                            valueStart: "",
                            valueEnd: "",
                            label: "Комиссия заказчика",
                            placeholderStart: "От",
                            placeholderEnd: "До",
                            pattern: PATTERN_NUMBER,
                        },
                    ],
                },
            ].filter(Boolean);
        }

        return [
            customerFilter,
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "sourceTypeFilter",
                        label: "Тип оплаты",
                        selection: true,
                        options: SOURCE_TYPE_OPTIONS,
                        placeholder: "Не выбран",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер оплаты",
                        maskChar: null,
                        mask: "999999999999",
                        name: "paymentNumberFilter",
                        placeholder: "Введите номер оплаты",
                    },
                ],
            },
            isShowRegistryNumberFilter && {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер реестра оплат",
                        maskChar: null,
                        mask: "999999999999",
                        name: "registryNumberFilter",
                        placeholder: "Введите номер реестра оплат",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Номер заказа",
                        maskChar: null,
                        mask: "999999999999",
                        name: "orderNumberFilter",
                        placeholder: "Введите номер заказа",
                    },
                ],
            },
            isShowNaimixStatusFilter && {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус Наймикс",
                        clearable: true,
                        compact: true,
                        name: "paymentStatusFilter",
                        options: naimixStatusFilterOptions,
                        placeholder: "Не выбран",
                    },
                ],
            },
            isShowBankStatusFilter && {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Банковский статус",
                        clearable: true,
                        compact: true,
                        name: "statusOfTheTransferFilter",
                        options: bankStatusOptions,
                        placeholder: "Не выбран",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        dateFormat: "dd.MM.yy",
                        startFieldName: "operationDateFilterFrom",
                        endFieldName: "operationDateFilterTo",
                        label: "Дата операции",
                        isClearable: true,
                        value: {
                            operationDateFilterFrom: null,
                            operationDateFilterTo: null,
                        },
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "sumOfMoneyFilterFrom",
                        nameEnd: "sumOfMoneyFilterTo",
                        valueStart: "",
                        valueEnd: "",
                        label: "Сумма операции",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "fromClientCommissionAmountFilter",
                        nameEnd: "toClientCommissionAmountFilter",
                        valueStart: "",
                        valueEnd: "",
                        label: "Комиссия заказчика",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "fromPlatformAmountFilter",
                        nameEnd: "toPlatformAmountFilter",
                        valueStart: "",
                        valueEnd: "",
                        label: "Комиссия исполнителя",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
        ].filter(Boolean);
    }, []);

    const submitFilter = (data) => {
        setIsSearch(true);

        const {
            sumOfMoneyFilterFrom,
            sumOfMoneyFilterTo,
            fromClientCommissionAmountFilter,
            toClientCommissionAmountFilter,
            fromPlatformAmountFilter,
            toPlatformAmountFilter,
            orderNumberFilter,
            registryNumberFilter,
            operationDateFilterFrom,
            operationDateFilterTo,
            sourceTypeFilter,
            statusOfTheTransferFilter,
            ...filter
        } = data;

        const filterData = {
            ...filter,
            sourceTypeFilter: sourceTypeFilter === "ALL" ? null : sourceTypeFilter,
            statusOfTheTransferFilter: statusOfTheTransferFilter === "ALL" ? undefined : statusOfTheTransferFilter,
            sumOfMoneyFilter: {
                from: handleNumber(sumOfMoneyFilterFrom),
                to: handleNumber(sumOfMoneyFilterTo),
            },
            clientCommissionAmountFilter: {
                from: handleNumber(fromClientCommissionAmountFilter),
                to: handleNumber(toClientCommissionAmountFilter),
            },
            platformAmountFilter: {
                from: handleNumber(fromPlatformAmountFilter),
                to: handleNumber(toPlatformAmountFilter),
            },
            operationDateFilter: {
                from: operationDateFilterFrom,
                to: operationDateFilterTo,
            },
            orderNumberFilter: handleNumber(orderNumberFilter),
            registryNumberFilter: handleNumber(registryNumberFilter),
        };

        setFilter(filterData);
        setFilterAmountBlocks({
            fromDateOperationFilter: isNullOrWhitespace(operationDateFilterFrom) ? undefined : formatDate(operationDateFilterFrom, "yyyy-MM-dd"),
            toDateOperationFilter: isNullOrWhitespace(operationDateFilterTo) ? undefined : formatDate(operationDateFilterTo, "yyyy-MM-dd"),
        });
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setIsSearch(false);
        setFilter({});
        setFilterAmountBlocks({});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    return {
        filter,
        submitFilter,
        clearFilter,
        filtersBase,
        isSearch,
        filterAmountBlocks,
    };
};