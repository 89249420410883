import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useBankIndicator} from "../../../../../../../components/BankIndicator/hooks/useBankIndicator";

import {ls, USER_ROLE} from "../../../../../../../utils/localstorage";

import {isClientUser} from "../../../../../../../constants/roles";

import {getByIdActRegistryPayment} from "../../../../../../../ducks/actRegistryPayment";
import {getContractorById} from "../../../../../../../ducks/contractor";
import {
    contractorRegistryPaymentPossibilityListSelector,
    contractorsPaymentPossibilityListSelector,
} from "../../../../../../../ducks/contractorBankCheck";

export const useOrderActRegistryEditPaymentFetch = ({isEditRealContractor, contractorId, editData}) => {
    const dispatch = useDispatch();

    const registryPaymentPossibility = useSelector(contractorRegistryPaymentPossibilityListSelector);
    const contractorsPaymentPossibilityList = useSelector(contractorsPaymentPossibilityListSelector);

    const role = ls(USER_ROLE);

    const {
        getBankChecks,
    } = useBankIndicator({nameField: "registryPaymentsPossibility"});

    useEffect(() => {
        getContractor();


        !isClientUser(role) && getBankChecks([contractorId]);

        if (isEditRealContractor && editData) {
            const {
                clientId,
                registryId,
                registrySeqNum,
            } = editData;

            dispatch(getByIdActRegistryPayment({
                clientId,
                registryId,
                registrySeqNum,
            }));
        }
    }, []);

    const getContractor = () => {
        dispatch(getContractorById(contractorId));
    };

    return {
        contractorsPossibility: isEditRealContractor ? contractorsPaymentPossibilityList : registryPaymentPossibility,
    };
};