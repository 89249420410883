import {FULL_REGISTRATION_COMPONENT_TYPE} from "../../constants";

export const getFullRegistrationFields = ({step, values, onlyFiles = false}) => {
    if (!onlyFiles) {
        return step.fields(values)
            .filter(item => ![FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE].includes(item.type))
            .map(item => item.name);
    }

    return step.fields(values)
        .filter(item => [FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE].includes(item.type))
        .map(item => item.name);
};