import React from "react";

import Text from "../../../../../../components/ActualComponents/Text";
import {ReactComponent as FileDownloadIcon} from "../../../../../../images/file_download.svg";

import {COLOR} from "../../../../../../constants/color";

const OrderListMassTemplatePublicationDownloadBtn = (props) => {
    const {
        text,
        onClick,
    } = props;

    return (
        <div
            onClick={onClick}
            style={{cursor: "pointer"}}
            className="d-flex align-items-center"
        >
            <FileDownloadIcon
                width={20}
                height={20}
                color={COLOR.PASSIVE_100}
            />
            <Text
                style={{marginLeft: "2px"}}
                color={COLOR.PASSIVE_100}
                level="3"
            >
                {text}
            </Text>
        </div>
    );
};

export default OrderListMassTemplatePublicationDownloadBtn;