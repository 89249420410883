import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../../../../utils/toastHelper";

import {downloadDocument} from "../../../../../../../ducks/documents";
import {
    exportOnboardingScenarioContactorsList,
    getOnboardingScenarioContactorsList,
    refreshStatusesOnboardingScenarioContactors,
    resubmitContractOnboardingContactorsList,
    updateOnboardingContactorsStore,
} from "../../../../../../../ducks/onboardingContractors";

export function useFetchOnboardingContractorsList({filter = {}, pageSize, pageNum, clientId, scenarioId, sortType}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateOnboardingContactorsStore({
                list: [],
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter, sortType, scenarioId]);

    const exportContactorsList = () => {
        dispatch(exportOnboardingScenarioContactorsList({
            clientId,
            scenarioId,
        }));
    };

    const refreshContactorsStatusesList = () => {
        dispatch(refreshStatusesOnboardingScenarioContactors({
            clientId,
            scenarioId,
        }));
    };

    const fetchList = () => {
        dispatch(getOnboardingScenarioContactorsList({
            pageNum,
            pageSize,
            sortType: sortType ? sortType.toUpperCase() : undefined,
            clientIdFilter: clientId,
            scenarioIdFilter: scenarioId,
            ...filter,
        }));
    };

    const downloadFile = (downloadLink, fileName = "") => {
        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink,
        }));
    };

    const resubmitFrameContract = ({clientId,  scenarioId, contractorId}) => {
        dispatch(resubmitContractOnboardingContactorsList({
            clientId,
            scenarioId,
            contractorId,
            onSuccess: () => {
                toastSuccess("Договор отправлен");
                fetchList();
            },
        }));
    };

    return {
        fetchList,
        exportContactorsList,
        refreshContactorsStatusesList,
        downloadFile,
        resubmitFrameContract,
    };
}