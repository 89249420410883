import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import ExtLink from "../../../../components/ExtLink";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as EditIcon} from "../../../../images/mode.svg";
import ChatArea from "../../../chats/chat-client-list/components/area";
import ClientChatSendMessage from "../../../chats/chat-client-list/components/send-message";
import TicketParamsComments from "./comments";

import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../../utils/localstorage";
import {phoneFormat} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {
    CALL_TYPE_DICT,
    SUB_PAGE_CRM_TICKET_CARD,
    TICKET_STATUS_DICT,
    TICKET_TYPE,
    TYPE_TICKET,
} from "../../../../constants/crm/ticket";
import {
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_REGISTRY_FC_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CRM_TICKET_CARD,
    LinkNavigator} from "../../../../constants/links";
import {ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";
import {ticketParamsDataRule} from "../../../../constants/validationRules";

import {
    crmChatWithNamemixGetMessagePage,
    updateFieldChat,
} from "../../../../ducks/chat";
import {
    ticketCategoryOptionsSelector,
    ticketTypeOptionsSelector,
    updateTicket,
    updateTicketStore,
} from "../../../../ducks/crm/ticket";

import "./style.sass";

import {SUB_PAGE_CRM_TICKET} from "../../../../constants/link-params";

function TicketParams(props) {
    const {
        ticketId,
        card,
        card: {
            baseModel,
            clientModel,
            contractorModel,
            managerModel,
        },
        getTicket,
        ticketCategoryDict,
        ticketTypeDict,
        isEditable,
    } = props;

    const role = ls(USER_ROLE) || "";
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);

    const [isSubmit, setIsSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form, setForm] = useState({});
    const {description, category, type, systemTicketType, manualCallerName, callType} = form;
    const [errorForm, setErrorForm] = useState({});

    const ticketCategoryOptions = useSelector(ticketCategoryOptionsSelector);
    const ticketTypeOptions = useSelector(ticketTypeOptionsSelector);

    const [isOpenCategory, setOpenCategory] = useState(false);
    const [isOpenType, setOpenType] = useState(false);

    const [focusCategory, setFocusCategory] = useState(false);
    const [focusType, setFocusType] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            setCurrentChat({});
        };
    }, []);

    useEffect(() => {
        setForm({...baseModel});
    }, [card]);

    useEffect(() => {
        if (!isEmpty(card) && baseModel?.chatId) {
            setCurrentChat({
                chatId: baseModel.chatId,
                isNamemixChat: true,
            });
            fetchMessages();
        }
    }, [card]);

    function setCurrentChat(currentChat) {
        dispatch(updateFieldChat({
            currentChat,
        }));
    }

    const {t} = useTranslation();

    const fetchMessages = () => {
        dispatch(crmChatWithNamemixGetMessagePage({
            chatId: baseModel.chatId,
            pageNum: 1,
            pageSize: 25,
        }));

    };

    function getValidateRule() {
        let rule = ticketParamsDataRule;

        if (clientModel?.name || contractorModel?.fio) {
            rule = {...rule, manualCallerName: undefined};
        }

        if (
            [
                TYPE_TICKET.LONG_SIGNING_CONTRACT,
                TYPE_TICKET.FAILED_REGISTRY_PAYMENTS,
                TYPE_TICKET.CONTRACTOR_NOT_EXIST_FROM_EDO,
            ].includes(systemTicketType) || [
                CALL_TYPE_DICT.CHAT.VALUE,
                CALL_TYPE_DICT.KEDO_CHAT.VALUE,
            ].includes(callType)
        ) {
            rule = {...rule, description: undefined};
        }

        return rule;
    }

    const validateForm = () => {
        const error = validate(form, getValidateRule(), "");

        setErrorForm({...error});

        return Object.values(error).length === 0;
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        setIsSubmit(true);
        dispatch(updateTicket({
            ticketId,
            description: handleFormString(description),
            manualCallerName: handleFormString(manualCallerName),
            status: baseModel.status,
            type,
            category,
            createDate: baseModel.createDate,
            getResult: () => {
                getTicket();
                openEditModal();
                setIsSubmit(false);
            },
        }));
    };

    function isEditableParams() {
        return [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role);
    }

    const onChange = (event, {name, value}) => {
        if (name === "type" && value === TICKET_TYPE.CALL_FAILED.VALUE) {
            setForm({
                ...form,
                [name]: value,
                description: TICKET_TYPE.CALL_FAILED.TEXT,
            });

            return;
        }

        setForm({
            ...form,
            [name]: value,
        });

        Object.values(errorForm).length !== 0 && setErrorForm(error => ({
            ...error,
            [name]: undefined,
        }));
    };

    const openEditModal = () => {
        setIsEdit(!isEdit);
    };

    const closeModal = () => {
        setForm({...baseModel});
        setIsEdit(!isEdit);
    };

    const handleKeyDown = (e, callBackState) => {
        if (e.key === "Enter") {
            callBackState(prevState => !prevState);
        }
        if (e.key === "Tab") {
            callBackState(false);
        }
    };

    function renderRegistryLink(registryName, registryCardLink) {
        return (
            <ExtLink
                historyEnabled
                to={registryCardLink}
            >
                {registryName}
            </ExtLink>
        );
    }

    function renderTicketLink(num, ticketId) {
        let LINK_CARD = LINK_CRM_TICKET_CARD;

        if (clientModel) {
            LINK_CARD = LINK_CLIENT_CARD_CRM_TICKET_CARD.replace(":clientId", clientModel.clientId);
        }
        if (contractorModel) {
            LINK_CARD = LINK_CONTRACTOR_CRM_TICKET_CARD.replace(":contractorId", contractorModel.contractorId);
        }

        return (
            <ExtLink
                historyEnabled
                to={
                    LINK_CARD
                        .replace(":subpage", SUB_PAGE_CRM_TICKET.CLOSED.LINK)
                        .replace(":_subpage", SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK)
                        .replace(":ticketId", ticketId)
                }
            >
                {num}
            </ExtLink>
        );
    }

    function setRegistryFilters(filter) {
        dispatch(updateTicketStore({
            ticketRegistryFilter: filter,
        }));
    }

    function renderDescription() {
        if (isEmpty(description)) {
            return "-";
        }

        const {
            clientId,
            clientName,
            clientBrand,
        } = JSON.parse(description);

        if (systemTicketType === TYPE_TICKET.LONG_SIGNING_CONTRACT) {
            const {
                registryId,
                registryName,
                seqNum,
                contractorFio,
                contractorPhone,
            } = JSON.parse(description);

            const link = LINK_CLIENT_REGISTRY_FC_CARD
                .replace(":clientId", clientId)
                .replace(":registryId", registryId);

            return (
                <div className="ticket-params__form-description">
                    <Text level="2">
                        Создано автоматически в результате обнаружения исполнителя в статусе "Ожидает подписание
                        договора" в течение более чем 24 часов:
                    </Text>
                    <NmLabelText
                        label="Компания"
                        text={clientBrand ? `${clientName}(${clientBrand})` : clientName}
                    />
                    <NmLabelText
                        label="Реестр"
                        onClickText={() => setRegistryFilters({
                            fioFilter: contractorFio,
                            phoneFilter: contractorPhone,
                        })}
                        text={renderRegistryLink(registryName, link)}
                    />
                    <NmLabelText
                        label="Исполнитель"
                        text={contractorFio || "Не определено"}
                    />
                    <NmLabelText
                        label="Телефон"
                        text={phoneFormat(contractorPhone)}
                    />
                    <NmLabelText
                        label="Строка"
                        text={seqNum}
                    />
                </div>
            );
        }

        if (systemTicketType === TYPE_TICKET.FAILED_REGISTRY_PAYMENTS) {
            const {
                registryId,
                registryName,
                seqNums,
                contractorFio,
                contractorPhone,
                errorMessage,
                orderContractPaymentType,
            } = JSON.parse(description);

            const link = LINK_CLIENT_REGISTRY_PAYMENTS_CARD
                .replace(":clientId", clientId)
                .replace(":paymentNumberFilter?/", "")
                .replace(":archived", "false")
                .replace(":registryId", registryId);

            return (
                <div className="ticket-params__form-description">
                    <Text level="2">
                        Создано автоматически по причине ошибки платежа:
                    </Text>
                    <NmLabelText
                        label="Реестр"
                        onClickText={() => setRegistryFilters({
                            fioFilter: contractorFio,
                            phoneFilter: contractorPhone,
                        })}
                        text={renderRegistryLink(registryName, link)}
                    />
                    <NmLabelText
                        label="Компания"
                        text={clientBrand ? `${clientName}(${clientBrand})` : clientName}
                    />
                    <NmLabelText
                        label="Строки"
                        text={seqNums.join(", ")}
                    />
                    <NmLabelText
                        label="ФИО исполнителя"
                        text={contractorFio || "Не определено"}
                    />
                    <NmLabelText
                        label="Телефон"
                        text={phoneFormat(contractorPhone)}
                    />
                    <NmLabelText
                        label="Статус"
                        text="Ошибка"
                    />
                    <NmLabelText
                        label="Причина ошибки платежа"
                        text={errorMessage}
                        flexWrap
                        wrapped
                    />
                    <NmLabelText
                        label="Тип платежа"
                        text={orderContractPaymentType}
                    />
                </div>
            );
        }

        if (systemTicketType === TYPE_TICKET.CONTRACTOR_NOT_EXIST_FROM_EDO) {
            const {
                registries,
            } = JSON.parse(description);

            return (
                <div className="ticket-params__form-description">
                    <Text level="2">
                        Создано автоматически в результате выбора отсутствующего на площадке исполнителя в качестве
                        получателя документа в рамках ЭДО:
                    </Text>
                    <NmLabelText
                        label="Компания"
                        text={clientBrand ? `${clientName}(${clientBrand})` : clientName}
                    />
                    {
                        registries.map(item => {
                            const link = LinkNavigator.client.edo.documentsRegistriesCard
                                .replace(":clientId", clientId)
                                .replace(":registryId", item.registryId);

                            return (
                                <>
                                    <NmLabelText
                                        label="Реестр"
                                        onClickText={() => setRegistryFilters({seqNum: item.seqNum})}
                                        text={renderRegistryLink(item.registryName, link)}
                                    />
                                    <NmLabelText
                                        label="Строка"
                                        text={item.seqNum}
                                    />
                                </>
                            );
                        })
                    }
                </div>
            );
        }

        if (callType === CALL_TYPE_DICT.NM_STAFF.VALUE) {
            const {
                orderNumber,
                ticketId,
            } = JSON.parse(description);

            return (
                orderNumber && ticketId &&
                <div className="ticket-params__form-description">
                    <Text level="2">
                        Тикет №
                        {renderTicketLink(orderNumber, ticketId)}
                    </Text>
                </div>
            );
        }

        return description;
    }

    function renderReadOnlyCard() {
        const ticketDescription =  ![
            TYPE_TICKET.LONG_SIGNING_CONTRACT,
            TYPE_TICKET.FAILED_REGISTRY_PAYMENTS,
            TYPE_TICKET.CONTRACTOR_NOT_EXIST_FROM_EDO,
        ].includes(systemTicketType) &&
        callType !== CALL_TYPE_DICT.NM_STAFF.VALUE ?
            description :
            renderDescription();

        return (
            <div className="d-flex ticket-params__form">
                <div className="col-14">
                    <NmLabelText
                        className="mb-2"
                        label="ФИО / Наименование компании"
                        text={clientModel?.name || contractorModel?.fio || manualCallerName}
                    />
                    <NmLabelText
                        className="mb-2"
                        label="Категория"
                        text={ticketCategoryDict[category]}
                    />
                    <NmLabelText
                        className="mb-2"
                        label="Тип обращения"
                        text={ticketTypeDict[type]}
                    />
                    {
                        ![
                            CALL_TYPE_DICT.CHAT.VALUE,
                            CALL_TYPE_DICT.KEDO_CHAT.VALUE,
                        ].includes(callType) &&
                        <NmLabelText
                            className="ticket-params__form-description"
                            label="Описание обращения"
                            text={ticketDescription || "-"}
                            flexWrap
                            wrapped
                        />
                    }
                </div>
                {isEditableParams() && <NmButton
                    color="grey"
                    onlyIcon
                    icon={<EditIcon />}
                    onClick={() => openEditModal()}
                />}
            </div>
        );
    }

    function renderDescriptionEdit() {
        if ([
            CALL_TYPE_DICT.CHAT.VALUE,
            CALL_TYPE_DICT.KEDO_CHAT.VALUE,
        ].includes(callType)) {
            return null;
        }

        if (
            [
                TYPE_TICKET.LONG_SIGNING_CONTRACT,
                TYPE_TICKET.FAILED_REGISTRY_PAYMENTS,
                TYPE_TICKET.CONTRACTOR_NOT_EXIST_FROM_EDO,
            ].includes(systemTicketType) ||
            [CALL_TYPE_DICT.NM_STAFF.VALUE].includes(callType)
        ) {
            return renderDescription();
        }

        return (
            <NmTextareaV2
                label="Описание обращения"
                placeholder="Введите описание обращения"
                minRows={7}
                maxRows={8}
                name="description"
                value={description}
                onChange={onChange}
                maxLength={500}
                error={errorForm.description}
            />
        );
    }

    function renderEditCard() {
        return (
            <NmForm addMargin>
                <NmInputV2
                    required
                    label="ФИО / Наименование компании"
                    value={clientModel?.name || contractorModel?.fio || manualCallerName}
                    name="manualCallerName"
                    size="lg"
                    disabled={!!clientModel?.name || !!contractorModel?.fio}
                    onChange={onChange}
                    placeholder="Введите ФИО / Наименование компании"
                    error={errorForm.manualCallerName}
                />
                <NmDropdownV2
                    isOpen={isOpenCategory}
                    onKeyDown={(e) => {
                        handleKeyDown(e, setOpenCategory);
                    }}
                    active={focusCategory}
                    onFocus={() => setFocusCategory(true)}
                    onBlur={() => {
                        setOpenCategory(false);
                        setFocusCategory(false);
                    }}
                    required
                    placeholder="Не выбрана"
                    size="lg"
                    disabled={!!clientModel?.name || !!contractorModel?.fio}
                    selectOnBlur={false}
                    search
                    name="category"
                    value={category}
                    options={ticketCategoryOptions}
                    label="Категория обращения"
                    onChange={onChange}
                    error={errorForm.category}
                />
                <NmDropdownV2
                    isOpen={isOpenType}
                    onKeyDown={(e) => {
                        handleKeyDown(e, setOpenType);
                    }}
                    required
                    placeholder="Не выбран"
                    size="lg"
                    selectOnBlur={false}
                    onBlur={() => {
                        setOpenType(false);
                        setFocusType(false);
                    }}
                    disabled={!!clientModel?.name && !!baseModel.type}
                    search
                    name="type"
                    value={type}
                    options={ticketTypeOptions}
                    label="Тип обращения"
                    onChange={onChange}
                    error={errorForm.type}
                />
                {renderDescriptionEdit()}
                <ApplyButtons
                    disabled={isSubmit}
                    loading={isSubmit}
                    className="mt-5"
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={closeModal}
                    submit={submit}
                />
            </NmForm>
        );
    }

    return (
        <div className="ticket-params">
            <NmPage
                className="ticket-params__edit-block"
                noPadding
                header={
                    <NmTitle size="xl">
                        Параметры обращения
                    </NmTitle>
                }
            >
                {!isEdit && renderReadOnlyCard()}
                {isEdit && renderEditCard()}
            </NmPage>
            {
                [
                    CALL_TYPE_DICT.CHAT.VALUE,
                    CALL_TYPE_DICT.KEDO_CHAT.VALUE,
                ].includes(baseModel?.callType) ?
                    <div className="ticket-params__chat">
                        <ChatArea
                            classNameMessages="ticket-params__chat-messages"
                            isTicketCard={true}
                            withoutHeader={true}
                        />
                        {
                            baseModel?.status === TICKET_STATUS_DICT.IN_WORK.VALUE &&
                            (
                                [ADMIN, NM_MANAGER].includes(role) ||
                                (role === NM_OPERATOR && currentUserId === managerModel.clientUserId)
                            ) &&
                            <ClientChatSendMessage />
                        }
                    </div> :
                    <TicketParamsComments
                        isEditable={isEditable}
                        ticketId={ticketId}
                        className="ticket-params__comments"
                        classNameContentList="ticket-params__comments-list"
                    />
            }
        </div>
    );
}

export default TicketParams;