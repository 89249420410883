import {useState} from "react";

import {clearSpace, removePhoneMask} from "../../../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../../../utils/stringHelper";

export function useContractorsFilter(groupId) {
    const [filterDto, setFilter] = useState({groupId});

    function mapFilterDto(filter) {
        const {
            fioFilter,
            innFilter,
            phoneFilter,
        } = filter;

        return {
            groupId,
            fioFilter: handleFormString(fioFilter),
            innFilter: isNullOrWhitespace(innFilter) ? undefined : clearSpace(innFilter),
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        setFilterDto,
    };
}