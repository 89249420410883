import React from "react";

import NmRadioV2 from "../../../../../../../components/ActualComponents/NmRadioV2";
import Text from "../../../../../../../components/ActualComponents/Text";

import {COLOR} from "../../../../../../../constants/color";

const OrderEditPreConditionsResponsibleShowInfo = (props) => {
    const {
        isDisabled,
        formData,
        onChange,
        className,
    } = props;

    return (
        <div className={`d-flex flex-column ${className}`}>
            <Text
                color={COLOR.SECONDARY_70}
                level="3"
                className="mb-2"
            >
                Отображать номер телефона ответственного по заказу
            </Text>
            <div className="d-flex flex-column flex-md-row align-items-md-center">
                <NmRadioV2
                    disabled={isDisabled}
                    className="mb-2 mb-md-0"
                    label="Выбирать при создании заказа"
                    checked={formData.orderManagerPhoneVisible === false}
                    name="orderManagerPhoneVisible"
                    value={false}
                    onChange={onChange}
                />
                <NmRadioV2
                    disabled={isDisabled}
                    label="Всегда отображать"
                    className="ms-0 ms-md-5"
                    checked={formData.orderManagerPhoneVisible === true}
                    name="orderManagerPhoneVisible"
                    value={true}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default OrderEditPreConditionsResponsibleShowInfo;