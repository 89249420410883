import React from "react";

import UpdatingDirectories from "../SettingsDirectoriesEdoTypes";

import {useFilter} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";
import {useBaseDirectoryFetch} from "./hooks/useFetch";

export const BaseDirectoryPage = (props) => {
    const {
        getItems,
        setIsSearch,
        sortType,
        ...otherProps
    } = props;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
    } = useFilter({
        initFilter: {
            valueFilter: "",
        },
        setIsSearch,
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useBaseDirectoryFetch({
        filterData,
        pageNum,
        pageSize,
        getItems,
        sortType,
    });

    return (
        <UpdatingDirectories
            isSearch={isSearch}
            filter={filter}
            fetchList={fetchList}
            clearFilter={onClear}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            onSubmitFilter={onSearch}
            {...otherProps}
        />
    );
};