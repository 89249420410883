import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import CodeConfirm from "../../../components/CodeConfirm";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {history} from "../../../store/configureStore";
import EdoDocumentDuplicateList from "../document-managment-loading-document-v2/components/edo-document-duplicate-list";
import EdoDocumentsRegistriesEditForm from "./components/edit-form";
import {EdoDocumentsRegistriesFilter} from "./components/filter";

import {usePagination} from "../../../hooks/usePagination";
import {useEdoDocumentsRegistriesAction} from "./hooks/useAction";
import {useEdoDocumentsRegistriesFetch} from "./hooks/useFetch";
import {useEdoDocumentsRegistriesFilter} from "./hooks/useFilter";

import {formatLocalDate} from "../../../utils/dateFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {LinkNavigator} from "../../../constants/links";

import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {
    documentsEdoRegistriesListSelector,
    documentsEdoRegistriesProgressActionSelector,
    documentsEdoRegistriesProgressListSelector,
    documentsEdoRegistriesTotalCountSelector,
    documentsEdoRegistriesTotalPagesSelector,
} from "../../../ducks/documentsEdoRegistries";
import {edoAccessListSelector, edoDocumentTypesSelector} from "../../../ducks/edocuments";

import {EDO_ACCESS_RIGHTS} from "../../../constants/document-management/document-list";
import {EDO_DOCUMENTS_REGISTRIES_STATUS} from "../../../constants/document-management/documents-registries";

const EdoDocumentsRegistries = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const totalPages = useSelector(documentsEdoRegistriesTotalPagesSelector);
    const list = useSelector(documentsEdoRegistriesListSelector);
    const totalCount = useSelector(documentsEdoRegistriesTotalCountSelector);
    const progressList = useSelector(documentsEdoRegistriesProgressListSelector);
    const progressAction = useSelector(documentsEdoRegistriesProgressActionSelector);
    const edoAccessList = useSelector(edoAccessListSelector);
    const edoDocumentTypesDict = useSelector(edoDocumentTypesSelector);
    const {phone: currentUserPhone} = useSelector(clientCurrentMemberSelector);

    const isAccessRegistryManage = edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_MANAGE);

    const {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    } = useEdoDocumentsRegistriesFilter({setPagination, pageSize});

    const {
        headerMediaControls,
        archiveRegistries,
        archived,
        confirmData,
        setConfirmData,
        onCloseEditForm,
        isOpenEditForm,
        setIsOpenEditForm,
        editRegistryData,
        setEditRegistryData,
        dataDuplicate,
        setDataDuplicate,
        confirmDuplicatesItems,
        timer,
        sendSms,
        signRegistry,
        isCodeConfirmOpen,
        codeConfirmType,
        setCodeConfirmOpen,
        onChangeCode,
        openSignConfirm,
        deleteNotCompletedItems,
    } = useEdoDocumentsRegistriesAction({clientId, edoAccessList});

    const {
        fetchList,
    } = useEdoDocumentsRegistriesFetch({filterData, archived, pageNum, pageSize, clientId, list, setPagination});

    function getMediaControls(item) {
        const {
            registryId,
            name,
            status,
            notCompletedItemsExists = false,
            totalCount: itemTotalCount,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        children: "Подписать",
                        onClick: () => openSignConfirm({
                            registryId,
                            fetchList,
                            content: "Вы действительно хотите подписать все документы по реестру? Внимание! При подтверждении операции все созданные в рамках текущего реестра документы будут созданы и подписаны от Вашего имени",
                        }),
                    },
                    visible: !archived && edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_SIGN) && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status) && Boolean(itemTotalCount),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        children: "Повторить невыполненные",
                        onClick: () =>
                            setConfirmData({
                                content: `Вы действительно хотите повторить отправку документов по невыполненным строкам реестра "${name}"? В случае подтверждения на подписание будут повторно переданы документы, которые находятся в статусах подписания "Ошибка при передаче на подписание", "Отправка отменена"`,
                                onConfirm: () => signRegistry({registryId, fetchList}),
                            }),
                    },
                    visible: !archived && edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_SIGN) && ![EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE, EDO_DOCUMENTS_REGISTRIES_STATUS.SIGNED.VALUE].includes(status) && notCompletedItemsExists,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        children: "Удалить невыполненные",
                        onClick: () =>
                            setConfirmData({
                                content: `Вы действительно хотите удалить невыполненные строки реестра "${name}"?`,
                                onConfirm: () => deleteNotCompletedItems({registryId, fetchList}),
                            }),
                    },
                    visible: !archived && isAccessRegistryManage && ![EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE, EDO_DOCUMENTS_REGISTRIES_STATUS.SIGNED.VALUE].includes(status) && notCompletedItemsExists,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onlyIcon: true,
                        children: "Редактировать",
                        onClick: () => {
                            setIsOpenEditForm(true);
                            setEditRegistryData(item);
                        },
                    },
                    visible: !archived && isAccessRegistryManage && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        children: archived ? "Из архива" : "В архив",
                        onClick: () => {
                            setConfirmData({
                                content: !archived ? `Вы действительно хотите добавить в архив реестр \"${name}\"?` : `Вы действительно хотите восстановить из архива реестр \"${name}\"?`,
                                onConfirm: () => archiveRegistries({
                                    registryId,
                                    archive: !archived,
                                    onSuccess: fetchList,
                                }),
                            });
                        },
                    },
                    visible: isAccessRegistryManage && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE, EDO_DOCUMENTS_REGISTRIES_STATUS.SIGNED.VALUE, EDO_DOCUMENTS_REGISTRIES_STATUS.DECLINED.VALUE].includes(status),
                },
            ],
        };
    }

    const getRows = () => {
        return list.map(item => {
            const {
                createDatetime,
                description,
                useTemplateFile,
                senderFio,
                documentName,
                ownerFio,
                totalCount,
                declinedCount,
                signedCount,
                inProgressCount,
                status,
                name,
                registryId,
            } = item;

            return {
                ...item,
                key: item.registryId,
                contentRow: (
                    <NmListCard
                        secondaryHeader={`Реестр от ${formatLocalDate(createDatetime, "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={EDO_DOCUMENTS_REGISTRIES_STATUS[status]?.MOD}
                                text={EDO_DOCUMENTS_REGISTRIES_STATUS[status]?.TEXT}
                            />
                        }
                        primaryHeader={name}
                        primaryHeaderLink
                        onClickLink={() => {
                            history.push(LinkNavigator.client.edo.documentsRegistriesCard
                                .replace(":registryId", registryId)
                                .replace(":clientId", clientId),
                            );
                        }}
                        labels={[
                            {text: description},
                            {label: "Ответственный за реестр", text: ownerFio},
                            {label: "Отправитель документов", text: senderFio || "-"},
                            {label: "Тип документа", text: documentName},
                            {label: "Подача с использованием шаблона", text: useTemplateFile ? "Да" : "Нет"},
                        ]}
                        cards={[
                            {
                                title: "Всего",
                                value: totalCount,
                                className: "col-16 col-md-4",
                            },
                            {
                                title: "Подписано",
                                value: signedCount,
                                className: "col-16 col-md-4",
                            },
                            {
                                title: "В процессе",
                                value: inProgressCount,
                                className: "col-16 col-md-4",
                            },
                            {
                                title: "Отклонено",
                                value: declinedCount,
                                className: "col-16 col-md-4",
                            },
                        ]}
                        cardsWithContainer
                        classNameMainContent="col-16 col-xxl-6"
                        cardsContainerClassName="col-16 col-md-14 col-xl-14 col-xxl-9 mt-md-4 mt-xxl-0"
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <EdoDocumentsRegistriesEditForm
                editData={editRegistryData}
                clientId={clientId}
                fetchList={fetchList}
                onClose={onCloseEditForm}
            />
        );
    };

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                loading={progressAction}
            />
        );
    };

    const renderCodeConfirm = () => {
        return (
            isCodeConfirmOpen &&
            <CodeConfirm
                title="Подписание реестра документов"
                phone={currentUserPhone}
                timer={timer}
                sendConfirmationCodeToUser={(codeType) => sendSms({codeType})}
                submitCode={() => signRegistry({fetchList})}
                onClose={() => {
                    setCodeConfirmOpen(false);
                }}
                loading={progressAction}
                onChange={onChangeCode}
                codeConfirmType={codeConfirmType}
            />
        );
    };

    const renderDuplicateList = () => {
        const {
            duplicates,
            duplicatesInRegistryExists,
        } = dataDuplicate;

        return (
            duplicates?.length &&
            <EdoDocumentDuplicateList
                isRegistry
                edoDocumentTypesDict={edoDocumentTypesDict}
                submit={confirmDuplicatesItems}
                dataDuplicate={duplicates}
                duplicatesInRegistryExists={duplicatesInRegistryExists}
                close={() => {
                    setDataDuplicate({});
                }}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    count={totalCount}
                >
                    Реестры на подписание документов
                </NmTitle>
            }
            typeFilter="vertical"
            mediaControls={headerMediaControls}
            filtersBase={
                <EdoDocumentsRegistriesFilter
                    onChange={onChangeFilter}
                    filter={filter}
                    onSearch={onSearch}
                    onClear={onClear}
                />
            }
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressList}
        >
            {renderEditForm()}
            {renderConfirmModal()}
            {renderCodeConfirm()}
            {renderDuplicateList()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        className="settings-directories-tariff-grid__table"
                        rows={getRows()}
                    />
            }
        </NmPage>
    );
};

export default EdoDocumentsRegistries;