import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "adm/recruitment/vacancyCandidateComments";

// PUT /bff/adm/recruitment/vacancyCandidateComments/{id}
// Редактирование комментария
const editRecruitmentVacancyCandidatesComment = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR,
        });
    }
};

// DELETE /bff/adm/recruitment/vacancyCandidateComments/{id}
// Удаление комментария
const deleteRecruitmentVacancyCandidatesComment = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidateComments
// Создание комментария
const addRecruitmentVacancyCandidatesComment = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(controller, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Комментарий успешно добавлен");

        onSuccess(result);

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidateComments/getPage
// Получить список комментариев по кандидату
const getRecruitmentVacancyCandidatesComments = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidateComments/{id}/uploadFile
// Добавить файл
const uploadFileRecruitmentVacancyCandidatesComment = function* ({payload}) {
    try {
        const {
            id,
            formData,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/${id}/uploadFile`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/vacancyCandidateComments/{id}/deleteFile
// Удалить файл
const deleteFileRecruitmentVacancyCandidatesComment = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...params
        } = payload;

        const result = yield request.bff.delete(`${controller}/${id}/deleteFile`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST, editRecruitmentVacancyCandidatesComment),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST, deleteRecruitmentVacancyCandidatesComment),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST, addRecruitmentVacancyCandidatesComment),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST, getRecruitmentVacancyCandidatesComments),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST, uploadFileRecruitmentVacancyCandidatesComment),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST, deleteFileRecruitmentVacancyCandidatesComment),
    ]);
}