export const TEMPLATES_TYPE_DOCUMENTS = {
    FRAME_CONTRACT: "FRAME_CONTRACT", // Договор
    ORDER_APPLICATION: "ORDER_APPLICATION", // Заявка по заказу
    TASK_APPLICATION: "TASK_APPLICATION", // Заявка по заданию
    ACT_OF_ACCEPTANCE_OF_WORK: "ACT_OF_ACCEPTANCE_OF_WORK", // Акт по заказу
    ACT_OF_ACCEPTANCE_OF_TASK: "ACT_OF_ACCEPTANCE_OF_TASK", // Акт по заданию
    OTHER_DOCUMENTS: "OTHER_DOCUMENTS", // Иной документ
    KEDO_DOCUMENT: "KEDO_DOCUMENT", // КЭДО
    ADDITIONAL_AGREEMENT: "ADDITIONAL_AGREEMENT", // Доп. соглашения
};

export const TEMPLATES_TYPE_DOCUMENTS_OPTIONS = [
    {
        key: "FRAME_CONTRACT",
        text: "Договоры",
        value: "FRAME_CONTRACT",
    },
    {
        key: "ORDER_APPLICATION",
        text: "Заявки по заказам",
        value: "ORDER_APPLICATION",
    },
    {
        key: "TASK_APPLICATION",
        text: "Заявки по заданиям",
        value: "TASK_APPLICATION",
    },
    {
        key: "ACT_OF_ACCEPTANCE_OF_WORK",
        text: "Акты по заказам",
        value: "ACT_OF_ACCEPTANCE_OF_WORK",
    },
    {
        key: "ACT_OF_ACCEPTANCE_OF_TASK",
        text: "Акты по заданиям",
        value: "ACT_OF_ACCEPTANCE_OF_TASK",
    },
    {
        key: "ADDITIONAL_AGREEMENT",
        text: "Доп. соглашения",
        value: "ADDITIONAL_AGREEMENT",
    },
    {
        key: "OTHER_DOCUMENTS",
        text: "Иные документы",
        value: "OTHER_DOCUMENTS",
    },
];

export const TEMPLATES_CLIENT_USAGE_CONFIRM_MESSAGE = {
    ADD_TO_DEFAULT: "У компании :clientName уже выбран другой шаблон по умолчанию соответствующего типа документа :defaultCustomDocumentTemplateName. Сделать текущий шаблон :customDocumentTemplateName шаблоном по умолчанию?",
    DELETE_FROM_DEFAULT: "Для соответствующего типа документа :customDocumentTemplateName компании будет выставлен шаблон по умолчанию от Наймикс. Продолжить?",
};

export const TEMPLATES_TAB = {
    // Договоры
    CONTRACTS: {
        LINK: "contracts",
        VALUE: "CONTRACTS",
        TEXT: "Договоры",
    },
    // Заявки
    APPLICATIONS: {
        LINK: "applications",
        VALUE: "APPLICATIONS",
        TEXT: "Заявки",
    },
    // Акты
    ACTS: {
        LINK: "acts",
        VALUE: "ACTS",
        TEXT: "Акты",
    },
    // Доп. соглашения
    ADDITIONAL_AGREEMENT: {
        LINK: "additional-agreement",
        VALUE: "ADDITIONAL_AGREEMENT",
        TEXT: "Доп. соглашения",
    },
    // Иные документы
    OTHER_DOCUMENTS: {
        LINK: "other-documents",
        VALUE: "OTHER_DOCUMENTS",
        TEXT: "Иные документы",
    },
    // Журнал событий
    LOGS: {
        LINK: "logs",
        VALUE: "LOGS",
        TEXT: "Журнал событий",
    },
};

export const TEMPLATES_SUB_TAB = {
    // Договоры
    ORDERS: {
        LINK: "orders",
        VALUE: "ORDERS",
        TEXT: "по заказам",
    },
    // Заявки
    TASKS: {
        LINK: "tasks",
        VALUE: "TASKS",
        TEXT: "по заданиям",
    },
};