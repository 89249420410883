import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";

const controller = "/systemProperties";
//*  TYPES  *//


const GET_SYSTEM_PROPERTIES_HISTORY_PAGE_REQUEST = "GET_SYSTEM_PROPERTIES_HISTORY_PAGE_REQUEST";
const GET_SYSTEM_PROPERTIES_HISTORY_PAGE_SUCCESS = "GET_SYSTEM_PROPERTIES_HISTORY_PAGE_SUCCESS";
const GET_SYSTEM_PROPERTIES_HISTORY_PAGE_ERROR = "GET_SYSTEM_PROPERTIES_HISTORY_PAGE_ERROR";

//*  INITIAL STATE  *//
const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    progress: false,
    error: {},
};

//*  REDUCER  *//
export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_SYSTEM_PROPERTIES_HISTORY_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case GET_SYSTEM_PROPERTIES_HISTORY_PAGE_SUCCESS:
        const {
            history: list,
            totalCount,
        } = payload;
        return {
            ...state,
            list,
            totalCount,
            progress: false,
        };
    case GET_SYSTEM_PROPERTIES_HISTORY_PAGE_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getSystemPropertiesHistory(payload) {
    return {
        type: GET_SYSTEM_PROPERTIES_HISTORY_PAGE_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//
export const systemPropertyHistorySelector = ({systemPropertyHistory}) => systemPropertyHistory;

export const listSelector = createSelector(systemPropertyHistorySelector, ({list}) => list);
export const totalPagesSelector = createSelector(systemPropertyHistorySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const totalCountSelector = createSelector(systemPropertyHistorySelector, ({totalCount}) => totalCount);

//*  SAGA  *//
//POST  /api/systemProperties/history/getRichPage  systemProperties
const getSystemPropertiesHistorySaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...requestData
        } = payload;

        const result = yield request.post(`${controller}/history/getRichPage`, requestData);

        const {errorMessage} = result;

        yield getResult(errorMessage);

        if (errorMessage) {
            console.error("getSystemPropertiesHistorySaga: ", errorMessage);
            yield put({
                type: GET_SYSTEM_PROPERTIES_HISTORY_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_SYSTEM_PROPERTIES_HISTORY_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_SYSTEM_PROPERTIES_HISTORY_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_SYSTEM_PROPERTIES_HISTORY_PAGE_REQUEST, getSystemPropertiesHistorySaga),
    ]);
}