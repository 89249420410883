import {useEffect, useReducer} from "react";
import {useDispatch} from "react-redux";

import {NM_ADMIN_INFO} from "../../../../constants/contractorInfo";

import {archiveMember} from "../../../../ducks/clientMember";

type State = {
    action: string,
    userId: string,
    userFullName: string,
    isOpenConfirm: boolean,
    confirmText: string
}
export const initialUserListState = {
    userId: "",
    action: "",
    userFullName: "",
    isOpenConfirm: false,
    confirmText: "",
};

type UserArchiveType = { userId: string }

export enum UserListActionType {
    IN_ARCHIVE = "IN_ARCHIVE",
    FROM_ARCHIVE = "FROM_ARCHIVE",
    RESET = "RESET",
    OPEN_CONFIRM = "OPEN_CONFIRM",
    CLOSE_CONFIRM = "CLOSE_CONFIRM"
}

type UserInArchiveActionType = {
    type: UserListActionType.IN_ARCHIVE,
    payload: UserArchiveType
}

type UserFromArchiveActionType = {
    type: UserListActionType.FROM_ARCHIVE,
    payload: UserArchiveType
}


type ResetStateActionType = {
    type: UserListActionType.RESET,
    payload: State
}

type closeConfirmType = {
    type: UserListActionType.RESET,
    payload: State
}

type Action = UserInArchiveActionType | UserFromArchiveActionType | ResetStateActionType

export function resetUserListStateAction(): ResetStateActionType {
    return {
        type: UserListActionType.RESET,
        payload: initialUserListState,
    };
}

export function archiveUser(payload: UserArchiveType): UserInArchiveActionType {
    return {
        type: UserListActionType.IN_ARCHIVE,
        payload,
    };
}

export function fromArchiveUser(payload: UserArchiveType): UserFromArchiveActionType {
    return {
        type: UserListActionType.FROM_ARCHIVE,
        payload,
    };
}

function reducerUserList(state: State, action: Action): State {
    const {type, payload} = action;
    const {
        userId,
    } = payload;
    switch (type) {
    case UserListActionType.IN_ARCHIVE:
    case UserListActionType.FROM_ARCHIVE:
        return {
            ...state,
            action: type,
            userId,
        };
    case UserListActionType.RESET:
        return initialUserListState;
    default:
        return state;

    }
}

export default function useUserList(fetchList: Function, clientId: string = NM_ADMIN_INFO.ID): [Function] {
    const [state, dispatch] = useReducer(reducerUserList, initialUserListState);
    const {userId, action} = state;

    const dispatchRedux = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetUserListStateAction());
        };
    }, []);

    useEffect(() => {
        if (action === UserListActionType.FROM_ARCHIVE) {
            archiveUser(false);
        }
        if (action === UserListActionType.IN_ARCHIVE) {
            archiveUser(true);
        }
    }, [userId, action, fetchList]);


    const archiveUser = (archive: boolean) => {
        dispatchRedux(archiveMember({
            clientUserId: userId,
            clientId,
            archive,
            canFetchList: false,
        }));
        setTimeout(fetchList, 200);
        dispatch(resetUserListStateAction());
    };

    return [
        dispatch,
    ];
}