import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import ContractorForeignLanguagesEditModal from "./components/сontractor-foreign-languages-edit-modal";

import {isNMUsers} from "../../../../utils/access";

import {
    contractorLanguageLevelsDictSelector,
    contractorLanguagesDictSelector,
    contractorLanguageSelector,
    deleteContractorForeignLanguage,
    getContractorLanguagesDict,
    getContractorLanguagesLevelDict,
} from "../../../../ducks/contractorProfile";

export const CONTRACTOR_FOREIGN_LANGUAGE_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const OPTIONS = [
    {
        key: CONTRACTOR_FOREIGN_LANGUAGE_OPTION.EDIT,
        text: "Редактировать",
        value: CONTRACTOR_FOREIGN_LANGUAGE_OPTION.EDIT,
    },
    {
        key: CONTRACTOR_FOREIGN_LANGUAGE_OPTION.REMOVE,
        text: "Удалить",
        value: CONTRACTOR_FOREIGN_LANGUAGE_OPTION.REMOVE,
    },
];

export default function ContractorForeignLanguages(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const contractorLanguageLevelsDict = useSelector(contractorLanguageLevelsDictSelector);
    const contractorLanguageDict = useSelector(contractorLanguagesDictSelector);
    const {foreignLanguages, foreignLanguagePresent} = useSelector(contractorLanguageSelector);
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    useEffect(() => {
        dispatch(getContractorLanguagesLevelDict());
        dispatch(getContractorLanguagesDict());
    }, []);

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const onClickAction = ({value}, item) => {
        if (value === CONTRACTOR_FOREIGN_LANGUAGE_OPTION.EDIT) {
            toggleEditModal({
                ...item,
                foreignLanguagePresent: !foreignLanguagePresent,
            });
            return;
        }

        if (value === CONTRACTOR_FOREIGN_LANGUAGE_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem(item);
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?" ,
            });
            setShowConfirm(true);
        }
    };

    const deleteElem = (item) => {
        const {language} = item;
        dispatch(deleteContractorForeignLanguage({
            contractorId,
            language,
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    function mapContent() {
        if (!foreignLanguagePresent) {
            return <Text level="1">
                Не владею иностранным языком
            </Text>;
        }

        if (!foreignLanguages.length) {
            return <div className="flex flex-aligned-center justify-content-between mb-md-2 mb-xxl-2 mb-xl-2">
                <Text level="1">
                Сведения пока не добавлены
                </Text>
                {isNMUsers() && !foreignLanguagePresent && <ContextMenu
                    notBorder
                    horizontalIcon
                    transform={false}
                    disabled={false}
                    options={OPTIONS}
                    onClickItem={(data) => {
                        onClickAction(data, {});
                    }}
                />}
            </div>;
        }

        return foreignLanguages.map((item) => {
            const {language, languageLevel} = item;
            return (

                <div
                    className="flex flex-aligned-center justify-content-between mb-md-2 mb-xxl-2 mb-xl-2"
                    key={language}
                >
                    <Text bold>
                        {contractorLanguageDict[language]}
                        {" "}
-
                        {contractorLanguageLevelsDict[languageLevel]}
                    </Text>
                    {isNMUsers() && <ContextMenu
                        notBorder
                        horizontalIcon
                        transform={false}
                        disabled={false}
                        options={OPTIONS}
                        onClickItem={(data) => {
                            onClickAction(data, item);
                        }}
                    />}
                </div>
            );
        });
    }

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal && <ContractorForeignLanguagesEditModal
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
                editData={editData}
                contractorId={contractorId}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Иностранные языки"
                onClickAdd={toggleEditModal}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}