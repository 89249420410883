import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/documents/edit/manual";

const DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_REQUEST = "DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_REQUEST";
const DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_SUCCESS = "DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_SUCCESS";
const DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_ERROR = "DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_ERROR";

const DOCUMENTS_EDIT_MANUAL_PERFORM_REQUEST = "DOCUMENTS_EDIT_MANUAL_PERFORM_REQUEST";
const DOCUMENTS_EDIT_MANUAL_PERFORM_SUCCESS = "DOCUMENTS_EDIT_MANUAL_PERFORM_SUCCESS";
const DOCUMENTS_EDIT_MANUAL_PERFORM_ERROR = "DOCUMENTS_EDIT_MANUAL_PERFORM_ERROR";

const DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_REQUEST = "DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_REQUEST";
const DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_SUCCESS = "DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_SUCCESS";
const DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_ERROR = "DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_ERROR";

const initialState = {
    dictFieldName: [],
    progress: false,
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case DOCUMENTS_EDIT_MANUAL_PERFORM_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case DOCUMENTS_EDIT_MANUAL_PERFORM_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case DOCUMENTS_EDIT_MANUAL_PERFORM_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_SUCCESS:
        return {
            ...state,
            dictFieldName: payload,
            progress: false,
        };
    case DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_ERROR:
        return {
            ...state,
            progress: false,
        };
    default:
        return state;
    }
};

// список имен параметров
export const getDocumentsEditManualFieldNames = (payload) => {
    return {
        type: DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_REQUEST,
        payload,
    };
};

// редактирование
export const performDocumentsEditManual = (payload) => {
    return {
        type: DOCUMENTS_EDIT_MANUAL_PERFORM_REQUEST,
        payload,
    };
};

// получаем requestId, чтобы получить демо pdf
export const requestOrderContractDemoFile = (payload) => {
    return {
        type: DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_REQUEST,
        payload,
    };
};

export const documentsEditManual = state => state.documentsEditManual;
export const documentsEditManualDictFieldNameSelector = createSelector(documentsEditManual, ({dictFieldName}) => dictFieldName);
export const documentsEditManualProgressActionSelector = createSelector(documentsEditManual, ({progressAction}) => progressAction);
export const documentsEditManualFieldNamesOptionsSelector = createSelector(documentsEditManual, ({dictFieldName}) => {
    return dictFieldName.map((item) => {
        return {
            key: item.fieldName,
            value: item.fieldName,
            text: item.fieldNameRus,
        };
    });
});

export const getDocumentsEditManualDictFieldNameSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/fieldNames/get`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({
            type: DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_ERROR,
        });
    }
};


export const performDocumentsEditManualSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/perform`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: DOCUMENTS_EDIT_MANUAL_PERFORM_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Имя параметра успешно изменено");
        onSuccess();

        yield put({
            type: DOCUMENTS_EDIT_MANUAL_PERFORM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        payload.onError();
        toastError(error.message);
        yield put({
            type: DOCUMENTS_EDIT_MANUAL_PERFORM_ERROR,
        });
    }
};

export const getDocumentsEditManualDemoIdSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/perform/demo`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        const {guid} = result;

        onSuccess(guid);

        yield put({
            type: DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_SUCCESS,
        });
    } catch (error) {
        payload.onError();
        toastError(error.message);
        yield put({
            type: DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_EDIT_MANUAL_DICT_FIELD_NAME_REQUEST, getDocumentsEditManualDictFieldNameSaga),
        takeEvery(DOCUMENTS_EDIT_MANUAL_PERFORM_REQUEST, performDocumentsEditManualSaga),
        takeEvery(DOCUMENTS_EDIT_MANUAL_GET_DEMO_ID_REQUEST, getDocumentsEditManualDemoIdSaga),
    ]);
}