import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {StatementLoadingErrorFormType, StatementLoadingFormType} from "..";
import {DOCUMENT_MANAGEMENT_LOADING_STATEMENT_RULE} from "../validation-rule/validationRule";

import {DataChange} from "../../../filter/hooks/useStatementFilter";

import {handleFormString, isNullOrWhitespace} from "../../../../../../utils/stringHelper";
import validate from "../../../../../../utils/validate";
import {OptionType} from "../../item/utils/getOptions";
import {
    mapOrderTemplateDataToFormData,
    mapOrderTemplateDataToRequestData,
    mapTemplateParams,
} from "../../utils/mapOrderTemplateData";
import {SelectStatement} from "../../utils/statementHelper";

import {EDM_STAFF_PRIVILEGES, STAFF_STATUS} from "../../../../../../constants/edmStaff";
import {requiredMessage} from "../../../../../../constants/validationRules";

import {
    checkStatementFile, getStatementsSourceFile, getStatementsTemplatePreFilledFile, getStatementTemplateEmptyFile,
    replaceEdoStatement,
    reviewStatement,
    signStatement, STATEMENT_FILE_TYPE,
    statementsTypesSelector,
    uploadEdoStatement,
} from "../../../../../../ducks/edmStatements";
import {EDO_STAFF_FIELD_LIST, getPageEdoStaff} from "../../../../../../ducks/edocuments";

import {UPLOAD_EDO_STEP} from "../../../../../../constants/document-management/document-list";
import {
    ADMIN_TEMPLATE_STATEMENT,
    EDO_STATEMENT_LOADING_FIELD,
    STATEMENT_PARAMS,
} from "../../../../../../constants/document-management/statements";

export type UseLoadingFormType = {
    clientId: string,
    fetchList: () => void,
    selectedList: SelectStatement[],
    clearSelectedRows: () => void,
    edoReceiversOptions: OptionType[],
    edoApproversOptions: OptionType[]
}

export enum STATEMENT_LOADING_TYPE {
    TEMPLATE = "TEMPLATE",
    SIMPLE = "SIMPLE"
}

export type AdditionalParamsStatementTemplateType = {
    amountOfIncome?: string,
    period?: Date,
    advanceAmount?: string,
    loanAmount?: string,
    statementNumber?: string,
    subjectRU?: string
}

export type UseLoadingFormReturnType = {
    form?: StatementLoadingFormType,
    onChangeForm: (e: any, {name, value}: DataChange) => void,
    submitForm: (code: string) => Promise<string>,
    setAction: (action: string) => void,
    initForm?: StatementLoadingFormType,
    checkForm: () => void,
    setFile: (field: string, files?: File[]) => void,
    errorForm: StatementLoadingErrorFormType | {},
    isVisibleConfirm: boolean,
    loadingForm: boolean,
    setVisibleConfirm: (flag: boolean) => void,
    statementsTypesOptions: OptionType[],
    isVisibleEditForm: boolean,
    setVisibleEditForm: () => void,
    setForm: (form: StatementLoadingFormType) => void,
    action: string,
    setErrorForm: (error: StatementLoadingErrorFormType | {}) => void,
    currentStep: number[],
    setStep: (step: number[]) => void,
    switchAction: Function,
    getStatementTemplateEmpty: Function,
    previewFile: File | {},
    additionalParams: AdditionalParamsStatementTemplateType,
    onChangeAdditionalParams: (e: any, {name, value}: DataChange) => void,
    setAdditionalParams: Function,
    fetchReceivers: (name: string) => void,
    fetchApprovers: (name: string) => void,
};

export const STATEMENT_ACTION_TYPE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    SIGN: "SIGN",
    REVIEW: "REVIEW",
    REJECT: "REJECT",
};

export function useLoadingForm({clientId, fetchList, selectedList, clearSelectedRows, edoReceiversOptions, edoApproversOptions}: UseLoadingFormType): UseLoadingFormReturnType {
    const dispatch = useDispatch();

    const initForm = {
        statementFile: [],
        attachmentFile: [],
        approverIds: [],
        statementType: "",
        statementName: "",
        clientId,
        receiverId: "",
        description: "",
        statementDateTime: new Date(),
        statementFileName: undefined,
        attachmentFileName: undefined,
        typeLoading: STATEMENT_LOADING_TYPE.SIMPLE,
    };
    const [form, setForm] = useState<StatementLoadingFormType>({...initForm});
    const [errorForm, setErrorForm] = useState<StatementLoadingErrorFormType | {}>({});
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [isVisibleConfirm, setVisibleConfirm] = useState<boolean>(false);
    const [isVisibleEditForm, setVisibleEditForm] = useState<boolean>(false);
    const [action, setAction] = useState<string>("");
    const [receiverFio, setReceiverFio] = useState("");
    const [approverFio, setApproverFio] = useState("");
    const [currentStep, setStep] = useState<number[]>(UPLOAD_EDO_STEP.FIRST);
    const [previewFile, setPreviewFile] = useState<File | {}>({});
    const [additionalParams, setAdditionalParams] = useState({});

    const statementsTypesOptions: OptionType[] = useSelector<any, OptionType[]>(statementsTypesSelector);

    useEffect(() => {
        if (!form.receiverId && edoReceiversOptions.length === 1 && isNullOrWhitespace(receiverFio)) {
            const [receiverOpt] = edoReceiversOptions;
            const {value: receiverId} = receiverOpt;

            setForm({
                ...form,
                receiverId,
            });
        }
    }, [edoReceiversOptions, form, form.receiverId, receiverFio]);

    useEffect(() => {
        if (!form.statementType) {
            return;
        }

        const templateType = statementsTypesOptions.find(item => item.value === form.statementType)?.templateType ?? "";
        let typeLoading = Object.values(ADMIN_TEMPLATE_STATEMENT).includes(templateType) ?
            STATEMENT_LOADING_TYPE.TEMPLATE
            :
            STATEMENT_LOADING_TYPE.SIMPLE;

        if (form.statementId) {
            // @ts-ignore
            typeLoading = form.typeLoading;
        }

        setForm({
            ...form,
            typeLoading,
            statementFile: typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE ? [] : form.statementFile,
        });

        if (typeLoading === STATEMENT_LOADING_TYPE.SIMPLE) {
            setAdditionalParams({});
            setErrorForm({});
        }
    }, [form.statementType]);

    useEffect(() => {
        if (form.typeLoading === STATEMENT_LOADING_TYPE.SIMPLE && form.statementId && currentStep === UPLOAD_EDO_STEP.SECOND && form.statementFile[0]?.isRemote) {
            dispatch(getStatementsSourceFile({
                statementId: form.statementId,
                fileType: STATEMENT_FILE_TYPE.SIGNED_FILE,
                clientId,
                hasDownload: false,
                getResult: (blob: Blob) => {
                    setPreviewFile(blob);
                    setForm({
                        ...form,
                        statementFile: [new File([blob], form.statementFile[0].name, {type: blob.type})],
                    });
                },
            }));

            return;
        }

        if (currentStep === UPLOAD_EDO_STEP.SECOND && form.typeLoading === STATEMENT_LOADING_TYPE.SIMPLE) {
            const previewFile = !isEmpty(form.statementFile) ? form.statementFile[0] : new Blob();

            setPreviewFile(previewFile);

            return;
        }

        if (currentStep === UPLOAD_EDO_STEP.SECOND && form.typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE) {
            const templateType = statementsTypesOptions.find(item => item.value === form.statementType)?.templateType ?? "";
            dispatch(getStatementsTemplatePreFilledFile({
                clientId,
                statementId: STATEMENT_ACTION_TYPE.REVIEW === action ? form.statementId : undefined,
                description: handleFormString(form.description),
                receiverId: form.receiverId,
                statementName: form.statementName,
                statementType: form.statementType,
                statementDateTime: form.statementDateTime?.toJSON(),
                params: mapTemplateParams(additionalParams, templateType, form.statementDateTime),
                getResult: (blob: Blob) => {
                    setPreviewFile(blob);
                },
            }));
            return;
        }
    }, [currentStep, form.typeLoading]);

    useEffect(() => {
        if (isEmpty(form.approverIds) && edoApproversOptions.length === 1 && isNullOrWhitespace(approverFio)) {
            const [approverOpt] = edoApproversOptions;
            const {value} = approverOpt;

            setForm({
                ...form,
                approverIds: [value],
            });
        }
    }, [edoApproversOptions, approverFio, form.approverIds, form]);

    useEffect(() => {
        if (!isVisibleEditForm) {
            setForm({...initForm});
            setStep(UPLOAD_EDO_STEP.FIRST);
            setPreviewFile({});
            setAdditionalParams({});
        }

    }, [isVisibleEditForm]);

    const getStaffPayload = (field: string, fio = "", privileges: string[] = [], callbackSuccess = () => {
    }) => {
        return {
            clientId,
            pageNum: 1,
            pageSize: 100,
            fio: handleFormString(fio),
            field,
            privileges,
            status: STAFF_STATUS.WORKING,
            getSuccess: callbackSuccess,
        };
    };

    const getFormDataFileForCheck = (statementFile: File): FormData => {
        const formData = new FormData();

        formData.append("multipartFile", statementFile);

        return formData;
    };

    const onChangeAdditionalParams = (e: any, {name, value}: DataChange) => {
        setAdditionalParams({
            ...additionalParams,
            [name]: value,
        });
    };

    const validateForm = () => {
        let _errorForm = validate(form, DOCUMENT_MANAGEMENT_LOADING_STATEMENT_RULE, "");
        if (!form.statementId && isEmpty(form.statementFile) && form.typeLoading === STATEMENT_LOADING_TYPE.SIMPLE) {
            _errorForm = {
                ..._errorForm,
                statementFile: "Выберите документ",
            };
        }

        if (form.approverIds && !isEmpty(form.approverIds) && form.approverIds.length > 5) {
            _errorForm = {
                ..._errorForm,
                [EDO_STATEMENT_LOADING_FIELD.APPROVED_IDS]: "Не более пяти согласующих",
            };
        }

        if (STATEMENT_ACTION_TYPE.REVIEW === action && isNullOrWhitespace(form.receiverId)) {
            _errorForm = {
                ..._errorForm,
                [EDO_STATEMENT_LOADING_FIELD.RECEIVER_ID]: requiredMessage,
            };
        }

        if (form.typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE) {
            const templateType = statementsTypesOptions.find(item => item.value === form.statementType)?.templateType ?? "";
            const params = STATEMENT_PARAMS[templateType] || [];

            params.forEach(field => {
                // @ts-ignore
                if (isNullOrWhitespace(additionalParams[field])) {
                    _errorForm = {
                        ..._errorForm,
                        [field]: requiredMessage,
                    };
                }
            });

        }

        setErrorForm({..._errorForm});

        return Object.values(_errorForm).length === 0;
    };

    const checkForm = (): void => {
        if (!validateForm()) {
            return;
        }
        setStep(UPLOAD_EDO_STEP.SECOND);
    };

    const switchAction = () => {
        setLoadingForm(true);
        if (![STATEMENT_ACTION_TYPE.CREATE, STATEMENT_ACTION_TYPE.EDIT].includes(action)) {
            const method = getMethodByType(action);

            method("").finally(() => {
                setLoadingForm(false);
            });
            return;
        }

        if (!isEmpty(form.statementFile)) {
            dispatch(checkStatementFile({
                formData: getFormDataFileForCheck(form.statementFile[0]),
                getResult: (errorMessage: any) => {
                    setLoadingForm(false);
                    if (errorMessage) {
                        setErrorForm({statementFile: errorMessage});
                        return;
                    }
                    setVisibleConfirm(true);
                },
            },
            ));
            return;
        }

        setVisibleConfirm(true);
    };

    const getStatementTemplateEmpty = () => {
        if (!form.statementType) {
            setErrorForm({
                ...errorForm,
                statementType: "Выберите тип заявления",
            });
            return;
        }
        setErrorForm({
            ...errorForm,
            statementType: undefined,
        });
        dispatch(getStatementTemplateEmptyFile({
            type: form.statementType,
        }));
    };

    const getMethodByType = (action: string) => {
        return {
            [STATEMENT_ACTION_TYPE.CREATE]: submitForm,
            [STATEMENT_ACTION_TYPE.EDIT]: submitForm,
            [STATEMENT_ACTION_TYPE.SIGN]: sign,
            [STATEMENT_ACTION_TYPE.REVIEW]: review,
        }[action];
    };

    const sign = (smsCode: string): Promise<string> => {
        const call = (resolve: any, reject: any) => {
            const data = {
                smsCode,
                statementIds: selectedList.filter(i => i.isSelected).map(item => item.id),
            };
            dispatch(signStatement({
                data,
                getResult: (errorMessage: string) => {
                    if (errorMessage) {
                        reject(errorMessage);
                    } else {
                        setAction("");
                        setVisibleConfirm(false);
                        setVisibleEditForm(false);
                        fetchList();
                        resolve("");
                        clearSelectedRows();
                    }
                },
            }));
        };

        return new Promise(call);
    };

    const review = (code?: string): Promise<string> => {
        const call = (resolve: any, reject: any) => {
            const data = {
                receiverId: form.receiverId,
                approverIds: form.approverIds,
                description: form.description ?? undefined,
                statementDate: form.statementDateTime?.toJSON(),
                statementId: form.statementId,
                statementName: form.statementName,
                statementType: form.statementType,
            };

            dispatch(reviewStatement({
                data,
                getResult: (errorMessage: string) => {
                    if (errorMessage) {
                        reject(errorMessage);
                    } else {
                        setVisibleConfirm(false);
                        setVisibleEditForm(false);
                        fetchList();
                        resolve("");
                    }
                },
            }));
        };

        return new Promise(call);
    };

    const submitForm = (code: string): Promise<string> => {
        const call = (resolve: any, reject: any) => {
            const templateType = form.typeLoading === STATEMENT_LOADING_TYPE.TEMPLATE ? statementsTypesOptions.find(item => item.value === form.statementType)?.templateType ?? "" : null;
            const formData = mapOrderTemplateDataToFormData(mapOrderTemplateDataToRequestData(form, code, additionalParams, templateType));

            const method = form.statementId ? replaceEdoStatement : uploadEdoStatement;

            dispatch(method({
                formData,
                hasTemplate: Boolean(templateType),
                getResult: (errorMessage: string) => {
                    if (errorMessage) {
                        reject(errorMessage);
                    } else {
                        setVisibleConfirm(false);
                        setVisibleEditForm(false);
                        fetchList();
                        resolve("");
                    }
                },
            }));
        };

        return new Promise(call);
    };


    const fetchReceivers = (fio: string) => {
        const payload = getStaffPayload(
            EDO_STAFF_FIELD_LIST.RECEIVERS,
            fio,
            [
                EDM_STAFF_PRIVILEGES.RECEIVE_STATEMENT,
            ],
        );

        setReceiverFio(fio);

        dispatch(getPageEdoStaff(payload));
    };

    const fetchApprovers = (fio: string) => {
        const payload = getStaffPayload(
            EDO_STAFF_FIELD_LIST.APPROVER,
            fio,
            [
                EDM_STAFF_PRIVILEGES.APPROVE_STATEMENT,
            ],
        );

        setApproverFio(fio);

        dispatch(getPageEdoStaff(payload));
    };

    const setFile = (field: string, files?: File[]) => {
        const [file] = files ?? [];

        setForm({
            ...form,
            [`${field}Name`]: file?.name,
            [field]: files,
        });
        setErrorForm({[field]: undefined});
    };

    const onChangeForm = (e: any, {name, value}: DataChange) => {
        let data = {
            ...form,
            [name]: value,
        };

        if (name === EDO_STATEMENT_LOADING_FIELD.STATEMENT_TYPE) {
            const {text} = statementsTypesOptions.find(i => i.value === value) || {};
            data = {
                ...data,
                [EDO_STATEMENT_LOADING_FIELD.STATEMENT_NAME]: text,
            };
        }

        setForm(data);
    };

    const handleVisibleEditForm = (): void => {
        setVisibleEditForm(!isVisibleEditForm);
    };

    return {
        form,
        onChangeForm,
        initForm,
        setFile,
        fetchReceivers,
        fetchApprovers,
        errorForm,
        isVisibleConfirm,
        loadingForm,
        checkForm,
        submitForm: getMethodByType(action),
        setVisibleConfirm,
        statementsTypesOptions,
        setVisibleEditForm: handleVisibleEditForm,
        isVisibleEditForm,
        setForm,
        setAction,
        action,
        setErrorForm,
        setStep,
        currentStep,
        switchAction,
        getStatementTemplateEmpty,
        previewFile,
        additionalParams,
        onChangeAdditionalParams,
        setAdditionalParams,
    };
}