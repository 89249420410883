import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmDropdownV2 from "../../../../ActualComponents/NmDropdownV2";
import NmInputV2 from "../../../../ActualComponents/NmInputV2";
import NmModal from "../../../../ActualComponents/NmModal";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {contractorDictsRecruitmentCurrencyDictOptionsSelector} from "../../../../../ducks/contractorsDicts";

export const RECRUITMENT_CANDIDATE_CURRENCY = {
    RUB: "RUB",
    USD: "USD",
    EUR: "EUR",
};

export const RecruitmentCandidateSalaryEdit = (props) => {
    const {
        onClose,
        salary,
    } = props;

    const options = useSelector(contractorDictsRecruitmentCurrencyDictOptionsSelector);

    const {
        updateInformation,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    const {
        values,
        setFieldValue,
        handleSubmit,
        setFieldTouched,
        touched,
        errors,
    } = useFormik({
        onSubmit,
        validationSchema: yup.object().shape({
            amount: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        initialValues: {
            currency: salary?.currency || RECRUITMENT_CANDIDATE_CURRENCY.RUB,
            amount: salary?.amount || "",
        },
    });

    function onSubmit(values) {
        updateInformation({
            salary: {
                amount: values.amount ? +values.amount : undefined,
                currency: values.currency,
            },
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    }

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    Зарплата
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            classNameContent="flex-column"
            onClose={onClose}
        >
            <div className="row gx-4">
                <div className="col-16 col-md-8">
                    <NmInputV2
                        required={true}
                        size="xl"
                        mask="999999999"
                        label="Сумма"
                        placeholder="Введите сумму"
                        maskChar={null}
                        onChange={onChange}
                        name="amount"
                        value={values.amount}
                        onBlur={() => {setFieldTouched("amount", true);}}
                        error={touched?.amount && errors?.amount ? errors?.amount : undefined}
                    />
                </div>
                <div className="col-16 col-md-8 mt-3 mt-md-0">
                    <NmDropdownV2
                        required={true}
                        size="xl"
                        label="Валюта"
                        placeholder="Выберите валюту"
                        name="currency"
                        options={options}
                        value={values.currency}
                        onChange={onChange}
                    />
                </div>
            </div>
        </NmModal>
    );
};
