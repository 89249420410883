import React, {useState} from "react";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import "./style.sass";


const AddModelFileForm = (props) => {
    const {
        onSubmit,
        progress,
        onClose,
        model,
        t,
    } = props;

    const [form, setForm] = useState(model.fileId? model: {modelId: model.modelId});

    const handleChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const onDropStamp = (files) => {
        setForm({
            ...form,
            file: files[0],
        });
    };

    const getForm = () => {
        return(
            <div>
                {!isNullOrWhitespace(form.fileId) && <NmInputV2
                    label="Наименование"
                    value={form.name}
                    name="name"
                    onChange={handleChange}
                />}
                <NmInputV2
                    label="Описание"
                    value={form.description}
                    name="description"
                    onChange={handleChange}
                />
                {isNullOrWhitespace(form.fileId) && <DropzoneV2
                    maxSize={750}
                    accept=".pkl"
                    format="*.pkl"
                    files={form.file? [form.file]: []}
                    onChange={onDropStamp}
                />}
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            className="ad-ml-model-form"
            header={
                <NmTitle size="lg">
                    {isNullOrWhitespace(form.fileId)?"Добавление файла":"Редактирование файла"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    disabled={progress}
                    submit={() => {
                        onSubmit(form);
                    }}
                />
            }
        >
            <NmForm
                addMargin
                className="add-model-file-form"
            >
                {getForm()}
            </NmForm>
        </NmModal>
    );
};

export default AddModelFileForm;