export const getHistoryBackLink = (location) => {
    const {state} = location;

    if (state && state.prevPath) {
        return state.prevPath;
    }
    return null;
};

export const extractExtData = ({state}) => {
    if (!state) {
        return {};
    }

    const {extData} = state;

    if (!extData) {
        return {};
    }

    return {...extData};
};