import {isEmpty} from "lodash";

import {handleFormString} from "../../../../utils/stringHelper";

import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";


export const mapOrderTemplateFilter = (filter) => {
    const {
        name,
        objectIdsFilter,
        projectIdsFilter,
        addressFiasIds,
        specialityIds,
        templateObjectId,
    } = filter;

    return {
        templateNameSubstringFilter: handleFormString(name),
        templateObjectExtIdFilter: handleFormString(templateObjectId),
        templateProjectIds: isEmpty(projectIdsFilter) ? undefined : projectIdsFilter,
        templateObjectIds: isEmpty(objectIdsFilter) ? undefined : objectIdsFilter,
        addressFiasIds: isEmpty(addressFiasIds) ? undefined : addressFiasIds,
        hasOrderWorkReportTemplateFilter: filter.hasOrderWorkReportTemplateFilter !== EMPTY_OPTION_KEY ?
            filter.hasOrderWorkReportTemplateFilter :
            undefined,
        specialityIds: isEmpty(specialityIds) ? undefined : specialityIds,
    };
};

export function mapParamToAdditionalParams(param) {
    if (!param) {
        return {};
    }

    const {
        amountOfIncome,
        advanceAmount,
        period,
        loanAmount,
        statementNumber,
        subjectRU,
    } = param;

    return {
        amountOfIncome: amountOfIncome,
        period: period ? new Date(period) : undefined,
        advanceAmount: advanceAmount,
        loanAmount,
        statementNumber,
        subjectRU,
    };
}