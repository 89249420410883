import {REMOTE_WORK_OPTION} from "./contractor";
import {EMPTY_OPTION_KEY} from "./dropdown";

export const SimpleDropdownOptions = [
    {
        key: true,
        text: "Да",
        value: true,
    },
    {
        key: false,
        text: "Нет",
        value: false,
    },
];



export const SimpleWithEmptyDropdownOptions = [
    {
        key: true,
        text: "Да",
        value: true,
    },
    {
        key: false,
        text: "Нет",
        value: false,
    },
    {
        key: EMPTY_OPTION_KEY,
        text: "Показать все",
        value: EMPTY_OPTION_KEY,
    },
];

export const BlockedContractorsOptions = [
    {
        key: true,
        text: "Заблокированные",
        value: true,
    },
    {
        key: false,
        text: "Не заблокированные",
        value: false,
    },
];

export const BlockedContractorsWithEmptyDropdownOptions = [
    {
        key: true,
        text: "Заблокированные",
        value: true,
    },
    {
        key: false,
        text: "Не заблокированные",
        value: false,
    },
    {
        key: EMPTY_OPTION_KEY,
        text: "Показать все",
        value: EMPTY_OPTION_KEY,
    },
];

export const CONTRACTOR_LIST_FILTER_TYPE = {
    ADMIN_FILTER: "ADMIN_FILTER",
    CLIENT_FILTER: "CLIENT_FILTER",
    INVITE_FILTER: "INVITE_FILTER",
};

export const SORT_TYPE = {
    CONFIRMED_FIRST: "CONFIRMED_FIRST",
    NONE: "NONE",
    FULL_REGISTRATION_JOIN_DATE_DESC: "FULL_REGISTRATION_JOIN_DATE_DESC",
    PENDING_PERSONAL_DATA_FIRST_FULL_REGISTRATION_JOIN_DATE_DESC: "PENDING_PERSONAL_DATA_FIRST_FULL_REGISTRATION_JOIN_DATE_DESC",
};

export const INVITING_SORT_TYPE = {
    WORK_ADDRESS_MATCH_CONFIRMED_FIRST: "WORK_ADDRESS_MATCH_CONFIRMED_FIRST",
    WORK_ADDRESS_SPECIALITY_MATCH_CONFIRMED_FIRST: "WORK_ADDRESS_SPECIALITY_MATCH_CONFIRMED_FIRST",
};

export const INVITATION_STATUSES = {
    SUCCESS: "success",
    ERROR: "error",
};

export const getRemoteWorkFilter = (workRegionFilter = []) => {
    /* Инструкция от бэка
    если ничего не выбрано в фильтре "регион или город" то "workRegionFilter" пустой и "remoteWorkFilter" не заполняется
    если выбраны регионы и не выбрано "Работа удаленно" то "workRegionFilter" заполнен и "remoteWorkFilter"=false
    если выбраны регионы и с ними вместие выбран "Работа удаленно" то "workRegionFilter" заполнен "remoteWorkFilter" не заполняется
    если выбран только "Работа удаленно" то "workRegionFilter" пустой и "remoteWorkFilter"=true
     */
    const isRemoteWorkSelected = workRegionFilter && workRegionFilter.includes(REMOTE_WORK_OPTION.value);

    const isWorkRegionsSelected = workRegionFilter && workRegionFilter.filter(item => item !== REMOTE_WORK_OPTION.value).length;

    if (isWorkRegionsSelected) {
        if (!isRemoteWorkSelected) {
            return false;
        }
        return null;
    } else {
        if (isRemoteWorkSelected) {
            return true;
        }
        return null;
    }
};

export const CONTRACTOR_ON_CHECK_LEAD_TYPE = {
    ALL: "ALL",
    WITHOUT_LEADS: "WITHOUT_LEADS",
    CIVIL: "CIVIL",
    SMZ: "SMZ",
};

export const CONTRACTOR_ON_CHECK_LEAD_TYPES_FILTER = [
    {
        value: CONTRACTOR_ON_CHECK_LEAD_TYPE.ALL,
        label: "Все",
    },
    {
        value: CONTRACTOR_ON_CHECK_LEAD_TYPE.WITHOUT_LEADS,
        label: "Без лидов",
    },
    {
        value: CONTRACTOR_ON_CHECK_LEAD_TYPE.CIVIL,
        label: "Лиды ГПХ",
    },
    {
        value: CONTRACTOR_ON_CHECK_LEAD_TYPE.SMZ,
        label: "Лиды СМЗ",
    },
];