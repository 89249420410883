import React, {ReactNode} from "react";

import ErrorTooltip from "../ErrorTooltip";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmRadioV2 {
    checked: boolean,
    className?: string,
    disabled?: boolean,
    value?: string | boolean,
    onChange(event: any, params: object): void,
    name?: string,
    label?: string,
    error?: string | null,
    additionalLabelContent?: ReactNode
}

const NmRadioV2: React.FC<INmRadioV2> = (props) => {
    const {
        checked,
        className = "",
        disabled,
        onChange,
        name,
        label,
        additionalLabelContent,
        value,
        error,
    } = props;
    const [block, element] = bem("nm-radio-v2", className);
    
    const handleChangeChecked = () => {
        !disabled && !checked && onChange && onChange(null, {name, value});
    };
    
    return (
        <div className={block()}>
            <label className={element("control", {checked, disabled})}>
                <input
                    type="radio"
                    className={`${element("radio")}`}
                    checked={checked}
                    onChange={handleChangeChecked}
                />
                {
                    label &&
                    <span className={element("label")}>
                        {label}
                    </span>
                }
            </label>
            {
                additionalLabelContent &&
                <div className={element("additional-label-content")}>
                    {additionalLabelContent}
                </div>
            }
            {
                error &&
                <ErrorTooltip
                    error={error}
                />
            }
        </div>
    );
};

export default NmRadioV2;