import React, {useState} from "react";
import {isEmpty} from "lodash";

import {useFilter} from "../../../../../../../hooks/useFilter";

import {removePhoneMask} from "../../../../../../../utils/stringFormat";

const initFilter = {
    ageFromFilter: "",
    ageToFilter: "",
    fioFilter: "",
    phoneFilter: "",
};

export const useEdoDocumentsRegistriesFilter = ({setPagination, pageSize}) => {
    const {
        onChangeFilter,
        filter,
        setFilter,
    } = useFilter({});
    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState({});

    const onSearch = () => {
        if (isEmpty(filter)) {
            return;
        }

        setFilterData({
            ...filter,
            phoneFilter: removePhoneMask(filter.phoneFilter),
        });
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClear = () => {
        setFilterData({});
        setIsSearch(false);
        setFilter(initFilter);
    };

    return {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    };
};