import {useDispatch} from "react-redux";

import {getUserRole} from "../../../../utils/access";

import {isClientUser} from "../../../../constants/roles";

import {
    addClientCompanyBrand,
    addClientCompanyBrandLogo,
    deleteClientCompanyBrandLogo,
    getClientAssignBrandsObjectsPage,
    getClientAssignBrandsProjectsObjectsList,
    getClientAssignBrandsProjectsPage,
    getClientLogsBrandChange,
    getClientsCompanyBrands,
    saveClientAssignBrandsObjects,
    updateClientCompanyArchiveStatus,
    updateClientCompanyBrand,
} from "../../../../ducks/bff/clients/brands/service";
import {
    addCompaniesBrand,
    addCompaniesBrandLogo,
    deleteCompaniesBrandLogo,
    getAdmAssignBrandsObjectsPage,
    getAdmAssignBrandsProjectsObjectsList,
    getAdmAssignBrandsProjectsPage,
    getAdmLogsBrandChange,
    getCompaniesBrandsClient,
    saveAdmAssignBrandsObjects,
    updateCompaniesArchiveStatus,
    updateCompaniesBrand,
} from "../../../../ducks/bff/companies/company/brands/services";
import {downloadDocument} from "../../../../ducks/documents";

export const useClientBrandBff = () => {
    const dispatch = useDispatch();

    const role = getUserRole();
    const isClientRole = isClientUser(role);

    const update = (data) => {
        if (isClientRole) {
            dispatch(updateClientCompanyBrand(data));

            return;
        }

        dispatch(updateCompaniesBrand(data));
    };

    const updateArchiveStatus = (data) => {
        if (isClientRole) {
            dispatch(updateClientCompanyArchiveStatus(data));

            return;
        }

        dispatch(updateCompaniesArchiveStatus(data));
    };

    const fetchList = (data) => {
        if (isClientRole) {
            dispatch(getClientsCompanyBrands(data));

            return;
        }

        dispatch(getCompaniesBrandsClient(data));
    };

    const deleteLogo = (data) => {
        if (isClientRole) {
            dispatch(deleteClientCompanyBrandLogo(data));

            return;
        }

        dispatch(deleteCompaniesBrandLogo(data));
    };

    const add = (data) => {
        if (isClientRole) {
            dispatch(addClientCompanyBrand(data));

            return;
        }

        dispatch(addCompaniesBrand(data));
    };

    const addLogo = (data) => {
        if (isClientRole) {
            dispatch(addClientCompanyBrandLogo(data));

            return;
        }

        dispatch(addCompaniesBrandLogo(data));
    };

    const saveAssignBrandsObjects = (data) => {
        if (isClientRole) {
            dispatch(saveClientAssignBrandsObjects(data));

            return;
        }

        dispatch(saveAdmAssignBrandsObjects(data));
    };

    const getAssignBrandsProjectsPage = (data) => {
        if (isClientRole) {
            dispatch(getClientAssignBrandsProjectsPage(data));

            return;
        }

        dispatch(getAdmAssignBrandsProjectsPage(data));
    };

    const getAssignBrandsObjectsPage = (data) => {
        if (isClientRole) {
            dispatch(getClientAssignBrandsObjectsPage(data));

            return;
        }

        dispatch(getAdmAssignBrandsObjectsPage(data));
    };

    const getAssignBrandsProjectsObjectsList = (data) => {
        if (isClientRole) {
            dispatch(getClientAssignBrandsProjectsObjectsList(data));

            return;
        }

        dispatch(getAdmAssignBrandsProjectsObjectsList(data));
    };

    const download = (data) => {
        const {
            getResultFile,
            isLogo,
            urlLogo,
            type,
        } = data;

        if (!isLogo) {
            return;
        }

        dispatch(downloadDocument({
            downloadLink: urlLogo,
            isDownload: false,
            getResultFile,
            type,
            isShowFileNotFoundErrorMessage: false,
        }));
    };

    const getLogs = (data) => {
        if (isClientRole) {
            dispatch(getClientLogsBrandChange(data));

            return;
        }

        dispatch(getAdmLogsBrandChange(data));
    };

    return {
        fetchList,
        add,
        addLogo,
        updateArchiveStatus,
        update,
        deleteLogo,
        download,
        getAssignBrandsObjectsPage,
        getAssignBrandsProjectsPage,
        saveAssignBrandsObjects,
        getLogs,
        getAssignBrandsProjectsObjectsList,
    };
};