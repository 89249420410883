import { useState } from "react";

export default function useTableItemList(): [boolean, Function] {
    const [isVisibleModal, setVisibleModal] = useState(false);

    const toggleVisibleModal = () => {
        setVisibleModal(!isVisibleModal);
    };

    return [isVisibleModal, toggleVisibleModal];
}