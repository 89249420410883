import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {addResponseByResumeUrlRecruitmentVacancy} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentIsVacancyResponsesPageActiveSelector,
    bffRecruitmentVacancyProgressActionSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {getPageRecruitmentVacancyResponses} from "../../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import {
    bffRecruitmentVacancyResponsesListPageDataSelector,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/selectors";

import {handleFormString} from "../../../../../utils/stringHelper";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

const RecruitmentColdSearchModal = (props) => {
    const {
        clientId,
        vacancyId,
        onClose = () => {},
        onSuccess = () => {},
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);
    const isResponsesPage = useSelector(bffRecruitmentIsVacancyResponsesPageActiveSelector);
    const responsesPageData = useSelector(bffRecruitmentVacancyResponsesListPageDataSelector);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(addResponseByResumeUrlRecruitmentVacancy({
            clientId,
            vacancyId,
            url: handleFormString(values.url),
            jobBoardAccountId: values.jobBoardAccountId ? values.jobBoardAccountId : undefined,
            isResponsesPage,
            onSuccess: () => {
                if (isResponsesPage) {
                    dispatch(getPageRecruitmentVacancyResponses({
                        pageNum: 1,
                        isLoadMore: false,
                        ...responsesPageData,
                    }));
                }

                onClose();
                onSuccess();
            },
        }));
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        isValid,
        handleSubmit,
    } = useFormik({
        onSubmit,
        initialValues: {
            url: "",
        },
        initialTouched: {
            url: true,
        },
        validationSchema: yup.object().shape({
            url: yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        enableReinitialize: true,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Холодный поиск
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Добавить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                    onClose={onClose}
                    disabled={progressAction}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    required={true}
                    size="xl"
                    label="Ссылка на резюме"
                    placeholder="Вставьте ссылку"
                    name="url"
                    onChange={handleChange}
                    value={values.url}
                    error={touched.url && errors.url}
                />
            </NmForm>
        </NmModal>
    );
};

export default RecruitmentColdSearchModal;