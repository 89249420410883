import React from "react";
import {useSelector} from "react-redux";

import UnavailableTemplates from "../../../../components/UnavailableTemplates";

import {
    UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE,
} from "../../../../components/UnavailableTemplates/constants";

import {
    orderProgressCheckWarningBeforePublishSelector,
    orderProgressUpdateSelector,
} from "../../../../ducks/order";

const OrderEditUnavailableTemplates = (props) => {
    const {
        isTemplate,
        header,
        onSubmit,
        onClose,
    } = props;

    const progressUpdating = useSelector(orderProgressUpdateSelector);
    const progressCheckWarningBeforePublish = useSelector(orderProgressCheckWarningBeforePublishSelector);

    return (
        <UnavailableTemplates
            disabledSettingContentType={
                isTemplate ?
                    UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.TEMPLATE_ORDER :
                    UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.ORDER
            }
            header={header}
            onSubmit={onSubmit}
            onClose={onClose}
            progress={
                progressCheckWarningBeforePublish
                || progressUpdating
            }
        />
    );
};

export default OrderEditUnavailableTemplates;