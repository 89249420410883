import React from "react";

import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import PropTypes from "prop-types";

import "./style.sass";

const OrderListNotificationIcon = (props) => {
    const {
        onClick = () => {},
        count,
        isVisibleIndicator = false,
        children,
        className = "",
    } = props;

    const getIndicator = () => {
        if (!isVisibleIndicator || isNullOrWhitespace(count) || count === 0) {
            return null;
        }

        return (
            <div className="order-list-notification-icon__counter">
                {count}
            </div>
        );
    };

    return (
        <div
            onClick={onClick}
            className={`order-list-notification-icon ${className}`}
        >
            {getIndicator()}
            {children}
        </div>
    );
};

OrderListNotificationIcon.propTypes = {
    onClick: PropTypes.func,
    count: PropTypes.number,
    children: PropTypes.node,
};

export default OrderListNotificationIcon;