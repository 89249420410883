import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import ProfileBox from "../../../components/ActualComponents/ProfileBox";
import ContractorCertificatesAndCourses from "./contractor-certificates-and-courses";
import ContractorContraindicationsWork from "./contractor-contraindications-work";
import ContractorDrivingLicense from "./contractor-driving-license";
import ContractorEducation from "./contractor-education";
import ContractorEmploymentSchedule from "./contractor-employment-schedule";
import ContractorFamilyStatus from "./contractor-family-status";
import ContractorForeignLanguages from "./contractor-foreign-languages";
import ContractorMainWorkCity from "./contractor-main-work-city";
import ContractorMainWorkType from "./contractor-main-work-type";
import ContractorPortfolio from "./contractor-portfolio";
import ContractorProfessionalSkills from "./contractor-professional-skills";
import ContractorWorkCities from "./contractor-work-cities";
import ContractorWorkExperience from "./contractor-work-experience";
import ContractorWorkType from "./contractor-work-type";

import {isAccessByRoles} from "../../../utils/access";

import {ADMIN, NM_MANAGER} from "../../../constants/roles";

import {getOrderStatistics} from "../../../ducks/contractor";
import {clearContractorProfileStore, getContractorProfile} from "../../../ducks/contractorProfile";

import "./style.sass";

export default function ContractorProfile(props) {
    const dispatch = useDispatch();
    const {
        params: {
            contractorId,
        },
    } = props.match;

    useEffect(() => {
        getProfile();
        return () => {
            dispatch(clearContractorProfileStore());
        };
    }, []);

    const getProfile = () => {
        dispatch(getOrderStatistics(contractorId));
        dispatch(getContractorProfile(
            {
                contractorId,
                getResult: () => {
                },
            },
        ));
    };

    const accessEdit = isAccessByRoles([ADMIN, NM_MANAGER]);

    return (
        <ProfileBox className="d-flex flex-column contractor-profile">
            <ContractorWorkExperience
                accessEdit={accessEdit}
                getProfile={getProfile}
                contractorId={contractorId}
                className="col-16 mb-4"
            />
            <ContractorEducation
                accessEdit={accessEdit}
                className="col-16 mb-4"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorMainWorkType
                accessEdit={accessEdit}
                className="col-16 mb-4 contractor-profile__work-type"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorWorkType
                accessEdit={accessEdit}
                className="col-16 mb-4 contractor-profile__work-type"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorMainWorkCity
                accessEdit={accessEdit}
                getProfile={getProfile}
                contractorId={contractorId}
                className="col-16 mb-4"
            />
            <ContractorWorkCities
                accessEdit={accessEdit}
                getProfile={getProfile}
                contractorId={contractorId}
                className="col-16 mb-4"
            />
            <ContractorProfessionalSkills
                accessEdit={accessEdit}
                getProfile={getProfile}
                contractorId={contractorId}
                className="col-16 mb-4"
            />
            <ContractorDrivingLicense
                accessEdit={accessEdit}
                className="col-16 mb-4"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorForeignLanguages
                accessEdit={accessEdit}
                className="col-16 mb-4"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorPortfolio
                accessEdit={accessEdit}
                getProfile={getProfile}
                contractorId={contractorId}
                className="col-16 mb-4"
            />
            <ContractorContraindicationsWork
                accessEdit={accessEdit}
                className="col-16 mb-4"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorFamilyStatus
                accessEdit={accessEdit}
                className="col-16 mb-4"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorCertificatesAndCourses
                accessEdit={accessEdit}
                className="col-16 mb-4"
                contractorId={contractorId}
                getProfile={getProfile}
            />
            <ContractorEmploymentSchedule
                accessEdit={accessEdit}
                className="col-16 mb-4"
            />
        </ProfileBox>
    );
}