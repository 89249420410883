import {formatFinanceListAmount} from "./formatAmount";

export const getFinanceListCommission = (payment) => {
    const {
        legalEntityCommissionAmount,
        legalEntityCommissionMinAmount,
        currentCommissionRate,
    } = payment;

    const commissionClient = `${formatFinanceListAmount(legalEntityCommissionAmount)}(${formatFinanceListAmount(currentCommissionRate * 100)}%)`;
    const commissionMinAmount = formatFinanceListAmount(legalEntityCommissionMinAmount);

    return [
        {
            bold: true,
            text: commissionClient,
        },
        {
            text: `+${commissionMinAmount}`,
        },
    ];
};