import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {uniqWith} from "lodash";

import NmDropdownV2 from "../NmDropdownV2";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {getCustomDocumentTemplateNames, updateCommonPaymentDicts} from "../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsCustomDocumentTemplateNamesOptionsSelector} from "../../../ducks/bff/common/dicts/selectors";

const CustomDocumentTemplateNamesDropdown = (props) => {
    const {
        nameFilter,
        label = "Заказчик",
        initialOption,
        documentTypeFilter,
        ...otherProps
    } = props;

    const dispatch = useDispatch();

    const customDocumentTemplateNamesOptions = useSelector(bffCommonDictsCustomDocumentTemplateNamesOptionsSelector);

    const options = useMemo(() => {
        if (initialOption) {
            return uniqWith(
                [
                    ...customDocumentTemplateNamesOptions,
                    initialOption,
                ],
                (arrVal, othVal) => {
                    return arrVal.key === othVal.key;
                });
        }

        return customDocumentTemplateNamesOptions;
    }, [
        customDocumentTemplateNamesOptions,
        initialOption,
    ]);

    useEffect(() => {
        if (nameFilter) {
            fetchClients(nameFilter);
        }

        return () => {
            dispatch(updateCommonPaymentDicts({
                customDocumentTemplateNames: [],
            }));
        };
    }, []);

    const fetchClients = (clientName) => {
        dispatch(getCustomDocumentTemplateNames({
            pageNum: 1,
            pageSize: 25,
            documentTypeFilter,
            nameFilter: isNullOrWhitespace(clientName) ? undefined : clientName.trim(),
        }));
    };

    const onSearchChange = (valueFilter) => {
        fetchClients(valueFilter);
    };

    return (
        <NmDropdownV2
            {...otherProps}
            size="xl"
            label={label}
            onSearchChange={onSearchChange}
            search
            options={options}
        />
    );
};

export default CustomDocumentTemplateNamesDropdown;