export const CLIENT_LIST_OPERATION = {
    LOCK: "lock",
    ARCHIVE: "archive",
    TERMINATE_CONTRACT: "terminate_contract",
    SET_PAYMENT_WITHOUT_ADMIN: "OPERATION_SET_PAYMENT_WITHOUT_ADMIN",
    SET_PAYMENT_WITH_ADMIN: "OPERATION_SET_PAYMENT_WITH_ADMIN",
    GET_LOG_EDITING_OPTIONS: "GET_LOG_EDITING_OPTIONS",
    CIVIL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE: "CIVIL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE",
    INDIVIDUAL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE: "INDIVIDUAL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE",
};

export const CLIENT_LIST_CONFIRM_MESSAGE = {
    ARCHIVED: "Вы действительно хотите перенести компанию :name в архив?",
    RESTORED: "Вы действительно хотите восстановить компанию, :name?",
    LOCK: "Вы действительно хотите отключить компанию, :name?",
    UNLOCK: "Вы действительно хотите включить компанию, :name?",
    SET_PAYMENT_WITH_ADMIN: "Вы уверены, что хотите выключить возможность автоотправки платежей в банк (все оплаты будут идти с согласованием администратором Наймикс)?",
    SET_PAYMENT_WITHOUT_ADMIN: "Вы уверены, что хотите вернуть возможность автоотправки платежей в банк (оплаты будут идти с/без согласования администратором Наймикс в зависимости от настроек компании)?",
};