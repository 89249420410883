import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    getKedoDocumentFileSourceTypes,
    getKedoDocumentHistoryEventTypes,
    getKedoDocumentStates,
    getKedoParticipantStates,
} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    bffCommonDictsDocumentHistoryEventTypesOptionsSelector,
    bffCommonDictsKedoDocumentFileSourceTypesSelector,
    bffCommonDictsKedoDocumentStatesSelector, bffCommonDictsKedoParticipantStatesSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoDocumentsDicts = () => {
    const dispatch = useDispatch();

    const documentFileSourceTypesDict = useSelector(bffCommonDictsKedoDocumentFileSourceTypesSelector);
    const documentStatesDict = useSelector(bffCommonDictsKedoDocumentStatesSelector);
    const participantStatesDict = useSelector(bffCommonDictsKedoParticipantStatesSelector);
    const documentHistoryEventTypesOptions = useSelector(bffCommonDictsDocumentHistoryEventTypesOptionsSelector);

    useEffect(() => {
        if (isEmpty(documentFileSourceTypesDict)) {
            dispatch(getKedoDocumentFileSourceTypes());
        }

        if (isEmpty(documentStatesDict)) {
            dispatch(getKedoDocumentStates());
        }

        if (isEmpty(participantStatesDict)) {
            dispatch(getKedoParticipantStates());
        }

        if (isEmpty(documentHistoryEventTypesOptions)) {
            dispatch(getKedoDocumentHistoryEventTypes());
        }
    }, []);

    return {
        documentFileSourceTypesDict,
        documentStatesDict,
        participantStatesDict,
    };
};