import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {Buffer} from "buffer";
import EventSource from "eventsource";
import {throttle} from "lodash";

import {TIMEOUT_REFRESH_TOKEN} from "../../../hooks/useWebSocket";

import {ACCESS_TOKEN_KEY, ls} from "../../../utils/localstorage";

import {refreshTokenAction} from "../../../ducks/auth";
import {updateSseEventData} from "../../../ducks/serverSentEvents";

export default function useServerSentEvents() {
    window.Buffer = Buffer;

    const domain = window.location.origin.includes("localhost") ?
        process.env.REACT_APP_PROXY :
        window.location.origin;
    const url = "/api/crm/sse/subscribe";

    const dispatch = useDispatch();
    const refreshingToken = useRef(throttle(refreshToken, TIMEOUT_REFRESH_TOKEN));
    const connectionInstance = useRef(null);

    useEffect(() => {
        createConnection();

        return () => {
            connectionInstance.current.close();
            console.log("sse close");
        };
    }, []);

    const createConnection = () => {
        const eventSourceInitDict = {
            headers: {
                "Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
            },
        };

        connectionInstance.current = new EventSource(`${domain}${url}`, eventSourceInitDict);

        connectionInstance.current.onmessage = (event) => {
            const data = JSON.parse(event.data) || {};

            dispatch(updateSseEventData(data));
        };
        connectionInstance.current.onopen = (event) => {
            console.log("sse open");
        };
        connectionInstance.current.onerror = function (event) {
            console.log("sse error", event.message);
            if (event && (event.status === 401 || [event.message].includes("Unauthorized"))) {
                refreshingToken.current();
            }
        };
    };

    function refreshToken() {
        dispatch(refreshTokenAction({
            onSuccess: () => {
                createConnection();
            },
        }));
    }
}