import React, {useMemo} from "react";

import CustomDocumentTemplateNamesDropdown from "../../../../../../components/ActualComponents/CustomDocumentTemplateNamesDropdown";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {useKedoTemplateDocumentTypesEdit} from "./hooks/useEdit";

import {COLOR} from "../../../../../../constants/color";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../../../templates/constants";

export const KedoTemplateDocumentTypesEdit = (props) => {
    const {
        editData,
        fetchList,
        onClose,
        fetchCounts,
    } = props;

    const {
        errors,
        handleSubmit,
        progressAction,
        onChange,
        values,
        touched,
    } = useKedoTemplateDocumentTypesEdit({
        editData,
        fetchList,
        onClose,
        fetchCounts,
    });

    const initialOption = useMemo(() => {
        if (editData) {
            return {
                key: editData?.templateId,
                value: editData?.templateId,
                text: editData?.customDocumentTemplateName,
            };
        }
    }, []);

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        editData ?
                            "Редактирование типа шаблонных документов" :
                            "Добавление типа шаблонных документов"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submitBtnContent={editData ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    name="name"
                    value={values.name}
                    onChange={onChange}
                    label="Наименование"
                    placeholder="Введите наименование"
                    maxLength={255}
                    error={
                        touched?.name && errors?.name ?
                            errors?.name :
                            undefined
                    }
                />
                <NmInputV2
                    size="xl"
                    label="Наименование для компании"
                    placeholder="Введите наименование"
                    name="nameForClient"
                    value={values.nameForClient}
                    maxLength={255}
                    required={true}
                    onChange={onChange}
                    error={
                        touched?.nameForClient && errors?.nameForClient ?
                            errors?.nameForClient :
                            undefined
                    }
                />
                <CustomDocumentTemplateNamesDropdown
                    required
                    label="Наименование шаблона"
                    placeholder="Не выбрано"
                    name="templateId"
                    value={values.templateId}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.KEDO_DOCUMENT}
                    initialOption={initialOption}
                    onChange={onChange}
                    error={
                        touched?.templateId && errors?.templateId ?
                            errors?.templateId :
                            undefined
                    }
                />
                <div>
                    <Text
                        color={COLOR.SECONDARY_90}
                        level="3"
                        className="mb-2 mb-md-3"
                    >
                        Требуется УКЭП компании
                    </Text>
                    <NmRadioV2
                        name="onlyUkep"
                        checked={values.onlyUkep === true}
                        value={true}
                        className="mb-2 mb-md-3"
                        label="Да"
                        onChange={onChange}
                    />
                    <NmRadioV2
                        name="onlyUkep"
                        checked={values.onlyUkep === false}
                        disabled={true}
                        value={false}
                        label="Нет"
                        onChange={onChange}
                    />
                </div>
                <div>
                    <Text
                        color={COLOR.SECONDARY_90}
                        level="3"
                        className="mb-2 mb-md-3"
                    >
                        Первый подписант
                    </Text>
                    <NmRadioV2
                        name="availableClientUsers"
                        value={true}
                        checked={values.availableClientUsers === true}
                        className="mb-2 mb-md-3"
                        label="Сотрудник"
                        onChange={onChange}
                    />
                    <NmRadioV2
                        name="availableClientUsers"
                        checked={values.availableClientUsers === false}
                        value={false}
                        label="Компания"
                        onChange={onChange}
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};
