import {getUserRole} from "./access";

import {APP_NAIMIX_INFO_LINK, LOCAL_PROD_NAIMIX_INFO_LINK, NAIMIX_INFO_LINK} from "../constants/links";
import {isClientUser} from "../constants/roles";

export const isPromoPoiskPage = () => {
    return [process.env.REACT_APP_PROMO_POISK].includes(window.location.origin);
};

export const getBffUrl = (urlsAssociation, defaultUrl) => {
    const pageUrl = Object.keys(urlsAssociation).find(url => {
        return [url].includes(window.location.pathname);
    });

    if (!pageUrl && urlsAssociation.isClientCard) {
        const role = getUserRole();
        const isClientRole = isClientUser(role);

        const {
            clientRolesUrl,
            adminRolesUrl,
        } = urlsAssociation;

        if (isClientRole) {
            return clientRolesUrl;
        }

        return adminRolesUrl;
    }

    if (!pageUrl) {
        return defaultUrl;
    }

    if (urlsAssociation[pageUrl]) {
        return urlsAssociation[pageUrl];
    }

    return defaultUrl;
};

export const isProd = () => {
    return [
        LOCAL_PROD_NAIMIX_INFO_LINK,
        NAIMIX_INFO_LINK,
        APP_NAIMIX_INFO_LINK,
        process.env.REACT_APP_PROMO_POISK,
    ].includes(window.location.origin);
};