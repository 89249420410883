import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../../../components/ActualComponents/Text";
import NmPage from "../../../../../components/NmPage";
import {
    getFeedListRecruitmentVacancy,
    updateRecruitmentVacancyStore,
} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentVacancyCandidateFeedListSelector,
    bffRecruitmentVacancyCandidateFeedProgressSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {RecruitmentCandidatesFeedOperationList} from "../components/operation-list";

export const RecruitmentVacancyCandidatesFeedList = (props) => {
    const {
        match: {
            params: {
                clientId,
                vacancyId,
                id: candidateId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentVacancyCandidateFeedListSelector);
    const progress = useSelector(bffRecruitmentVacancyCandidateFeedProgressSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();

        return () => {
            dispatch(updateRecruitmentVacancyStore({feedList: []}));
        };
    }, []);

    const fetchList = () => {
        dispatch(getFeedListRecruitmentVacancy({
            clientId,
            vacancyId,
            candidateId,
        }));
    };

    return (
        <NmPage
            header={
                <Text
                    type="title"
                    level="2"
                    children="Лента событий"
                />
            }
            isLoaded={progress}
        >
            <RecruitmentCandidatesFeedOperationList
                clientId={clientId}
                vacancyId={vacancyId}
                candidateId={candidateId}
                list={list}
                fetchList={fetchList}
            />
        </NmPage>
    );
};