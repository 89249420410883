import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import {AddComment} from "../../../../components/AddComment";
import CheckboxList from "../../../../components/CheckboxList";
import RecruitmentCommentFiles from "../../../../components/CommentFiles";
import NmButton from "../../../../components/NmButton";
import {RecruitmentCandidateCommentsEdit} from "../../../../components/Recruitment/CandidateCommentsEdit";
import {
    deleteRecruitmentVacancyCandidatesComment,
    getRecruitmentVacancyCandidatesComments,
    updateRecruitmentVacancyCandidatesCommentsStore,
} from "../../../../ducks/bff/recruitment/vacancyCandidatesComments/actionCreators";
import {
    getRecruitmentVacancyCandidatesCommentsInitialState,
} from "../../../../ducks/bff/recruitment/vacancyCandidatesComments/reducer";
import {
    bffRecruitmentVacancyCandidatesCommentsListSelector,
    bffRecruitmentVacancyCandidatesCommentsProgressActionSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidatesComments/selectors";
import {ReactComponent as AttachFileIcon} from "../../../../images/attach_file_24.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {ReactComponent as UserIcon} from "../../../../images/user_24.svg";
import {history} from "../../../../store/configureStore";

import {useModal} from "../../../../hooks/useModal";
import useUploadCommentFiles from "./hooks/useUploadCommentFiles";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../constants/color";
import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED,
} from "../../../../constants/links";
import {ADMIN, isClientUser, NM_MANAGER} from "../../../../constants/roles";

import "./style.sass";

export const RecruitmentCandidatesComments = (props) => {
    const {
        clientId,
        candidateId,
        vacancyId,
        activeFunnelStatusFilter,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(bffRecruitmentVacancyCandidatesCommentsListSelector);

    const progressAction = useSelector(bffRecruitmentVacancyCandidatesCommentsProgressActionSelector);

    const role = ls(USER_ROLE);
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);

    const {
        isOpen: isOpenEdit,
        modalData: editData,
        onOpenModal: onOpenEditModal,
        onCloseModal: onCloseEditModal,
    } = useModal();

    const {
        isOpen: isOpenDeleting,
        modalData: deletingModalData,
        onOpenModal: onOpenDeletingConfirm,
        onCloseModal: onCloseDeletingConfirm,
    } = useModal();

    const fetchList = () => {
        dispatch(getRecruitmentVacancyCandidatesComments({
            clientId,
            candidateId,
            pageNum: 1,
            pageSize: 1,
        }));
    };

    const {
        uploadFiles,
    } = useUploadCommentFiles({
        clientId,
        fetchList,
    });

    const deleteComment = () => {
        dispatch(deleteRecruitmentVacancyCandidatesComment({
            id: deletingModalData.id,
            onSuccess: () => {
                onCloseDeletingConfirm();
                fetchList();
            },
        }));
    };

    useEffect(() => {
        fetchList();
    }, [candidateId]);

    useEffect(() => {
        return () => {
            const initialState = getRecruitmentVacancyCandidatesCommentsInitialState();

            dispatch(updateRecruitmentVacancyCandidatesCommentsStore(initialState));
        };
    }, []);

    const getIsAccessActions = ({createdBy}) => {
        return [
            ADMIN,
            NM_MANAGER,
        ].includes(role) ||
            (
                isClientUser(role) &&
                currentUserId === createdBy
            );
    };

    const getRowMediaControls = (commentInfo) => {
        const isAccessActions = getIsAccessActions(commentInfo);
        const fileNames = commentInfo.fileNames || [];
        const disabledAttachFiles = fileNames.length > 4;

        return {
            renderCount: {
                desktop: 3,
                tablet: 0,
                mobile: 0,
            },
            size: "lg",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        icon: <ModeIcon />,
                        onlyIcon: true,
                        onClick: () => {
                            onOpenEditModal(commentInfo);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                        tablet: {
                            children: "Редактировать",
                        },
                    },
                    visible: isAccessActions && commentInfo.editable,
                },
                {
                    component: COMPONENT.DEFAULT_DROPZONE,
                    props: {
                        disabled: disabledAttachFiles,
                        multiple: true,
                        files: fileNames,
                        maxFiles: 5,
                        onDropFiles: (files) => uploadFiles({
                            files: files.filter(item => !fileNames.includes(item)),
                            commentId: commentInfo.id,
                        }),
                        children: <NmButton
                            size="lg"
                            onlyIcon={true}
                            color="grey"
                            title="Прикрепить файл"
                            icon={<AttachFileIcon />}
                            disabled={disabledAttachFiles}
                        />,
                    },
                    asset: {
                        mobile: {
                            children: "Прикрепить файл",
                        },
                        tablet: {
                            children: "Прикрепить файл",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        icon: <DeleteIcon />,
                        onlyIcon: true,
                        onClick: () => {
                            onOpenDeletingConfirm(commentInfo);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                        tablet: {
                            children: "Удалить",
                        },
                    },
                    visible: isAccessActions && commentInfo.deletable,
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            return {
                key: item.id,
                contentRow: (
                    <NmListCard
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-14"
                        secondaryHeader={formatDate(convertUtcToLocal(item.createDate), "dd.MM.yyyy HH:mm")}
                        secondaryHeaderStatus={
                            <div className="d-flex align-items-center">
                                <UserIcon
                                    color={COLOR.SECONDARY_70}
                                    className="me-1"
                                    width={18}
                                    height={18}
                                />
                                <Text
                                    noWrap={true}
                                    level="2"
                                    color={COLOR.SECONDARY_70}
                                >
                                    {item.authorName}
                                </Text>
                            </div>
                        }
                        labels={[
                            {
                                text: (
                                    <Text
                                        level="3"
                                        className="pe-10 pe-xxl-0"
                                    >
                                        {item.comment}
                                    </Text>
                                ),
                                noWrap: false,
                            },
                            item.fileNames?.length && {
                                text: <RecruitmentCommentFiles
                                    clientId={clientId}
                                    commentId={item.id}
                                    fileNames={item.fileNames}
                                    fetchInfo={fetchList}
                                    initiatorId={item.createdBy}
                                />,
                            },
                        ]}
                        actionsClassName="col-2 justify-content-end"
                        mediaControls={getRowMediaControls(item)}
                    />
                ),
            };
        });
    };

    return (
        <div className="relative mt-3 mt-md-4 mt-xxl-6">
            {
                isOpenEdit &&
                <RecruitmentCandidateCommentsEdit
                    candidateId={candidateId}
                    vacancyId={vacancyId}
                    editData={editData}
                    fetchList={fetchList}
                    onClose={onCloseEditModal}
                />
            }
            {
                isOpenDeleting &&
                <NmConfirmV2
                    disabled={progressAction}
                    onCancel={onCloseDeletingConfirm}
                    onConfirm={deleteComment}
                    isNeedClosing={false}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                    content="Вы действительно хотите удалить комментарий по кандидату?"
                />
            }
            <AddComment
                clientId={clientId}
                candidateId={candidateId}
                vacancyId={vacancyId}
                fetchList={fetchList}
            />
            {
                list.length ?
                    <>
                        <CheckboxList
                            isFullMinHeight={false}
                            className="mt-3 mt-md-4"
                            rows={getRows()}
                        />
                        <Text
                            className="mt-2"
                            level="3"
                            color={COLOR.PASSIVE_100}
                            onClick={() => {
                                const link = LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED
                                    .replace(":clientId", clientId)
                                    .replace(":vacancyId", vacancyId)
                                    .replace(":id", candidateId)
                                    .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter);

                                history.push(link);
                            }}
                        >
                            Перейти ко всем комментариям (Лента событий)
                        </Text>
                    </> :
                    null
            }
        </div>
    );
};
