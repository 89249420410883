import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_ERROR,
    BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_REQUEST,
    BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";

const controller = "/adm/bot";

// POST /bff/adm/bot/deleteAllSubscriptions/{clientUserId}
// Удаление всех подписок
const deleteAllBotSubscriptions = function* ({payload}) {
    const {
        clientUserId,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/deleteAllSubscriptions/${clientUserId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(BFF_BOT_DELETE_ALL_SUBSCRIPTIONS_REQUEST, deleteAllBotSubscriptions),
    ]);
}