import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import MailingsList from "../../../components/MailingsList";

import {
    contractorActionProgressSelector,
    getContractorByPhone,
    getCountOfContractorsForPushNotifications,
} from "../../../ducks/contractor";
import {
    addContractorPushNotification,
    cancelContractorPushNotification,
    clearContractorPushNotificationStore,
    contractorPushNotificationListSelector,
    contractorPushNotificationProgressSelector,
    contractorPushNotificationTotalCountSelector,
    contractorPushNotificationTotalPagesSelector,
    getContractorPushNotificationList,
    getPushNotificationSuccessSelector,
    retryContractorPushNotification,
    updateFieldPushNotificationStore,
} from "../../../ducks/contractorPushNotification";

export const AdminMailingsList = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(contractorPushNotificationListSelector);
    const totalPages = useSelector(contractorPushNotificationTotalPagesSelector);
    const totalCount = useSelector(contractorPushNotificationTotalCountSelector);
    const progress = useSelector(contractorPushNotificationProgressSelector);
    const notification = useSelector(getPushNotificationSuccessSelector);
    const progressAction = useSelector(contractorActionProgressSelector);

    useEffect(() => {
        return () => {
            dispatch(clearContractorPushNotificationStore());
        };
    }, []);

    const retryNotification = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(retryContractorPushNotification({
            data,
            handleResponse,
        }));
    };

    const cancelNotification = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(cancelContractorPushNotification({
            data,
            handleResponse,
        }));
    };

    const fetchList = (params) => {
        const {
            pageNum,
            pageSize,
            ...filters
        } = params;

        dispatch(getContractorPushNotificationList({
            pageNum,
            pageSize,
            ...filters,
        }));
    };

    const getContractorCount = (params) => {
        const {
            data,
            handleResponse,
        } = params;

        dispatch(getCountOfContractorsForPushNotifications({
            data,
            handleResponse,
        }));
    };

    const addNotification = (params) => {
        const {
            formData,
            handleResponse,
        } = params;

        dispatch(addContractorPushNotification({
            formData,
            handleResponse,
        }));
    };

    const addContractor = ({data, saveNumber}) => {
        dispatch(getContractorByPhone({
            data,
            handleResponse: (response) => {
                const isSuccess = Boolean(response.length);

                saveNumber({
                    isSuccess,
                    isNotFound: !isSuccess,
                });
            },
        }));
    };

    return (
        <MailingsList
            retryNotification={retryNotification}
            cancelNotification={cancelNotification}
            fetchList={fetchList}
            getContractorCount={getContractorCount}
            addNotification={addNotification}
            addContractor={addContractor}
            updateFieldPushNotificationStore={(data) => {
                dispatch(updateFieldPushNotificationStore(data));
            }}
            notification={notification}
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            progress={progress}
            progressAction={progressAction}
            {...props}
        />
    );
};