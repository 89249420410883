import React, { Component } from "react";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";

import {
    PROJECT_ARCHIVED_STATUS_OPTIONS, PROJECT_STATUS_DICT,
    PROJECT_STATUS_OPTIONS,
} from "../../../constants/projects";

import PropTypes from "prop-types";

import "./style.sass";

class ProjectsFilter extends Component {
    static propTypes = {
        archived: PropTypes.bool,
    };

    static defaultProps = {
        archived: false,
    };

    constructor(props) {
        super(props);

        const {
            filter,
        } = props;

        this.state = {
            name: filter.name || undefined,
            objectNameFilter: filter.objectNameFilter || undefined,
            status: filter.status || "ALL",
            archived: false,
        };
    }

    handleOnChange = (e, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    handleClearFilter = () => {
        const {sendFilter} = this.props;

        this.setState({
            name: "",
            objectNameFilter: "",
            status: "ALL",
        }, () => {
            sendFilter({}, false);
        });
    };

    handleSendFilter = () => {
        const {sendFilter} = this.props;
        const {
            name,
            objectNameFilter,
            status,
        } = this.state;

        sendFilter({
            name,
            objectNameFilter,
            status: status === "ALL" ? undefined : status,
        }, true);
    };

    render() {
        const {
            name,
            objectNameFilter,
            status,
        } = this.state;

        const {archived} = this.props;

        return (
            <NmForm addMargin>
                <NmInputV2
                    size="lg"
                    onChange={this.handleOnChange}
                    name="name"
                    value={name}
                    placeholder="Введите название проекта"
                    label="Название проекта"
                />
                <NmInputV2
                    size="lg"
                    onChange={this.handleOnChange}
                    name="objectNameFilter"
                    value={objectNameFilter}
                    placeholder="Введите название объекта"
                    label="Название объекта"
                />
                <NmDropdownV2
                    size="lg"
                    label="Статус проекта"
                    selectOnBlur={false}
                    searchQuery={name}
                    onChange={this.handleOnChange}
                    selection
                    name="status"
                    value={archived ? PROJECT_STATUS_DICT.CLOSE.VALUE : status}
                    options={archived ? PROJECT_ARCHIVED_STATUS_OPTIONS : PROJECT_STATUS_OPTIONS}
                />
                <FilterButtonsV2
                    onSearch={this.handleSendFilter}
                    onClear={this.handleClearFilter}
                    className="projects-filter__actions"
                />
            </NmForm>
        );
    }
}

export default ProjectsFilter;
