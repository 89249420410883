export const CREATE_ACTS_TYPE = {
    NONE: "NONE",
    AUTOMATICALLY: "AUTOMATICALLY",
    WITH_CONFIRMATION: "WITH_CONFIRMATION",
};

export const CREATE_ACTS_TYPE_OPTIONS = [
    {
        key: "ALL",
        text: "Все",
        value: undefined,
    },
    {
        key: "NONE",
        text: "Без актов",
        value: "NONE",
    },
    {
        key: "AUTOMATICALLY",
        text: "Автоматически",
        value: "AUTOMATICALLY",
    },
    {
        key: "WITH_CONFIRMATION",
        text: "С подтверждением",
        value: "WITH_CONFIRMATION",
    },
];

export const getCreateTypeDescription = type => {
    if (type === CREATE_ACTS_TYPE.AUTOMATICALLY) {
        return " Да, автоматически";
    }

    if (type === CREATE_ACTS_TYPE.WITH_CONFIRMATION) {
        return " Да, с подтверждением";
    }

    return " Без актов";
};