import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmSelectedList from "../../../../components/NmSelectedList";
import TableItemList from "../../../../components/TableItemList";
import {ReactComponent as PenIcon} from "../../../../images/pen.svg";

import {openConfirmAction} from "../../../../hooks/useConfirm";
import {changeVisibleForm, setFullFormAction} from "../hooks/useUserFormEdit";
import {archiveUser, fromArchiveUser} from "../hooks/useUserList";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {isAccessEditMemberByRole, isAccessEditMemberByUserId} from "../../../../utils/access";
import {replacer} from "../../../../utils/replacer";
import {phoneFormat} from "../../../../utils/stringFormat";
import mapUserListItemToUserForm from "../utils/mapUserListToUserForm";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_MEMBER_ARCHIVE} from "../../../../constants/messages";
import {NM_COORDINATOR, NM_MANAGER, ROLE_DICT} from "../../../../constants/roles";

import {clientsForSelectionOptionsSelector} from "../../../../ducks/clientMember";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../../../constants/link-params";

function SettingsUsersList(props) {
    const {
        list,
        isEditable,
        sortDispatch,
        dispatchConfirm,
        dispatchList,
        dispatchForm,
        archived,
        onClickSort,
        sortData,
        subpage,
    } = props;
    const {t} = useTranslation();

    const handleArchiveButton = (item) => {
        const confirmText = item.archived ? CLIENT_MEMBER_ARCHIVE.FROM : CLIENT_MEMBER_ARCHIVE.TO;
        const fullNameMember = `${item.lastName} ${item.firstName} ${item.patronymic || ""}`.trim();
        const content = replacer(":name", confirmText, fullNameMember);
        const payload = {userId: item.clientUserId};
        const callBackOnConfirm = () => dispatchList(item.archived ? fromArchiveUser(payload) : archiveUser(payload));
        dispatchConfirm(openConfirmAction(content, callBackOnConfirm));
    };
    const clientsForSelectionOptions = useSelector(clientsForSelectionOptionsSelector);
    const handleOnClickEdit = (useListItem) => {
        dispatchForm(changeVisibleForm());
        dispatchForm(setFullFormAction(mapUserListItemToUserForm(useListItem)));
    };

    function getSortOptions() {
        return [
            {
                key: "date",
                value: "date",
                text: "По дате добавления",
                isDefaultSort: true,
            },
            {
                key: "fio",
                value: "fio",
                sortType: sortData,
                text: "По ФИО",
                asc: "ASC",
                desc: "DESC",
            },
        ];
    }

    function getRows() {
        return list.map(item => {
            const fullName = `${item.lastName} ${item.firstName} ${item.patronymic || ""}`;
            const partnerPromotionClientList = item.partnerPromotionClientList?.map((p) => `${p.name} (${p.requisites})`) || [];
            const partnerPromotionContractorList = item.partnerPromotionContractorList?.map((p) => `${p.name} (${p.requisites})`) || [];
            const isAccessEdit = isAccessEditMemberByRole(item.role);
            const archive = getArchiveButton(t, item.archived, {mobile: true}, true);

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={fullName}
                        otherContent={<div>
                            {item.position && <NmLabelText
                                className="mb-2"
                                label="Должность"
                                text={item.position}
                            />}
                            <NmLabelText
                                className="mb-2"
                                label="Номер телефона"
                                text={phoneFormat(item.phone)}
                            />
                            <NmLabelText
                                className="mb-2"
                                label="Email"
                                text={item.email}
                            />
                            <NmLabelText
                                className={!partnerPromotionClientList.length && !partnerPromotionContractorList.length ? "" : "mb-2"}
                                label="Роль"
                                text={ROLE_DICT[item.role]?.TEXT}
                            />
                            {
                                !!partnerPromotionClientList.length && <NmLabelText
                                    className="mb-2"
                                    label="Каналы привлечения для компаний"
                                    text={
                                        <TableItemList
                                            maxShowElements={3}
                                            isDisplayIndex={true}
                                            key={item.clientUserId}
                                            list={partnerPromotionClientList}
                                            title="Каналы привлечения для компаний"
                                        />
                                    }
                                />
                            }
                            {
                                !!partnerPromotionContractorList.length && <NmLabelText
                                    label="Каналы привлечения для исполнителей"
                                    text={
                                        <TableItemList
                                            maxShowElements={3}
                                            isDisplayIndex={true}
                                            key={item.clientUserId}
                                            list={partnerPromotionContractorList}
                                            title="Каналы привлечения для исполнителей"
                                        />
                                    }
                                />
                            }
                            {subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK && [NM_MANAGER, NM_COORDINATOR].includes(item.role) && !item.canManageAllClients &&
                            <NmSelectedList
                                className="mt-2"
                                showedItemsCount={3}
                                values={item.availableClients}
                                list={clientsForSelectionOptions}
                                label="Компании"
                                dropdownWidth={250}
                            />}
                            {subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK && [NM_MANAGER, NM_COORDINATOR].includes(item.role) && item.canManageAllClients &&
                            <NmLabelText
                                className="mt-2"
                                label="Компании"
                                text="Все"
                            />
                            }
                        </div>}
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                desktop: 2,
                                tablet: 2,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    visible: !item.archived && isAccessEdit,
                                    props: {
                                        icon: <PenIcon />,
                                        size: "lg",
                                        color: "grey",
                                        onlyIcon: true,
                                        onClick: () => handleOnClickEdit(item),
                                    },
                                    asset: {
                                        mobile: {children: "Редактровать"},
                                    },
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    visible: isAccessEdit && isAccessEditMemberByUserId(item.clientUserId),
                                    props: {
                                        ...archive.props,
                                        onlyIcon: true,
                                        children: "",
                                        size: "lg",
                                        onClick: () => handleArchiveButton(item),
                                    },
                                    asset: archive.asset,
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    }


    return (
        <CheckboxList
            onClickSort={onClickSort}
            sortOptions={getSortOptions()}
            sort
            rows={getRows()}
        />
    );
}

export default SettingsUsersList;