import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import OrderEdit from "../../../order/order-edit";
import {REQUEST_TYPE} from "../foremanRequests";

import {updateFieldOrderStore} from "../../../../ducks/order";

const ForemanRequestsOrderEditContainer = (props) => {
    const {
        requestType,
        ...otherProps
    } = props;
    const dispatch = useDispatch();

    const isForemanRequestForAddition = requestType === REQUEST_TYPE.ADDITIONAL_WORKS_REQUEST;
    const isForemanRequestDuplicate = requestType === REQUEST_TYPE.DUPLICATE_ORDER_REQUEST;

    useEffect(() => {
        return () => {
            dispatch(updateFieldOrderStore("card", {}));
        };
    }, []);

    return (
        <OrderEdit
            {...otherProps}
            isForemans={true}
            isForemanRequestForAddition={isForemanRequestForAddition}
            isForemanRequestDuplicate={isForemanRequestDuplicate}
        />
    );
};

export default ForemanRequestsOrderEditContainer;