import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {
    contractorDictsAdditionalDocumentTypeDictOptionsSelector,
} from "../../ducks/contractorsDicts";

const AdditionalDocumentFilter = (props) => {
    const options = useSelector(contractorDictsAdditionalDocumentTypeDictOptionsSelector);

    return (
        <NmDropdownV2
            size="lg"
            placeholder="Показать все"
            label="Доп. документы"
            name="additionalDocumentTypesFilter"
            multiple
            options={options}
            {...props}
        />
    );
};

export default AdditionalDocumentFilter;