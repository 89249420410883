export const getOrderListMassCheckingPublishData = (params) => {
    const {
        orders,
        submitData,
        customDocumentTemplatesAvailable, // Включен признак Свои шаблоны документов
    } = params;
    const {
        useActualTemplates,
        ...customDocumentTemplatesInfo
    } = submitData;

    if (!customDocumentTemplatesAvailable || useActualTemplates) {
        return orders;
    }

    return orders.map(item => ({...item, customDocumentTemplatesInfo}));
};