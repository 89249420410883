import React, {Component} from "react";
import {connect} from "react-redux";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmPage from "../../../components/NmPage";
import TableDiv from "../../../components/TableDiv";
import ForemanRequestsOrderEditContainer from "./order-edit-container";

import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {formatAmount, getFormattedFullName} from "../../../utils/stringFormat";

import {ORDER_STATUS} from "../../../constants/clientList";
import {headersForemanRequests as headers} from "../../../constants/headersTable";
import {NM_OPERATOR} from "../../../constants/roles";

import {getClientCardSelector} from "../../../ducks/client";
import {
    foremanProgressListSelector,
    foremanRequestsListSelector,
    foremanRequestsTotalCountSelector,
    foremanRequestsTotalPagesSelector,
} from "../../../ducks/foremanRequests";
import {foremanRequestStatusesDictSelector} from "../../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

const COLOR_STATUS = {
    CONFIRMED: {
        BORDER: "#5AB730",
        TEXT: "#128A10",
    },
    REJECTED: {
        BORDER: "#EE1616",
        TEXT: "#EE1616",
    },
    NEW: {
        BORDER: "#D2D2D2",
        TEXT: "#999999",
    },
};

const STATUS = {
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED",
    NEW: "NEW",
};

const REQUEST_ACTION = {
    CONFIRM: "CONFIRM",
    REJECT: "REJECT",
};

export const REQUEST_TYPE = {
    ADDITIONAL_WORKS_REQUEST: "ADDITIONAL_WORKS_REQUEST",
    DUPLICATE_ORDER_REQUEST: "DUPLICATE_ORDER_REQUEST",
    MATERIALS_REQUEST: "MATERIALS_REQUEST",
    OTK_REQUEST: "OTK_REQUEST",
};

export const BRIGADE_TOAST_MESSAGE = {
    ERROR_UPDATE: "Не удалось обновить заявку",
    SUCCESS_UPDATE: "Заявка успешно обновлена",
};

class ForemanRequests extends Component {
    static propTypes = {
        pageNum: PropTypes.number,
        pageSize: PropTypes.number,
        handlePaginationChange: PropTypes.func,
        handleChangePageSize: PropTypes.func,
        updateRequest: PropTypes.func,
        translate: PropTypes.object,
    };

    static defaultProps = {
        translate: {},
    };

    constructor(props) {
        super(props);

        this.role = ls(USER_ROLE);

        this.state = {
            pageSize: 25,
            pageNum: 1,
            isOpenForm: false,
            requestNumber: "",
            orderData: {},
            confirmMessage: "",
            confirmSubmitData: {},
            isOpenConfirm: false,
        };
    }

    get options() {
        const {translate} = this.props;
        const {
            buttonConfirm,
            buttonReject,
        } = translate;

        return [
            {
                key: REQUEST_ACTION.CONFIRM,
                value: REQUEST_ACTION.CONFIRM,
                text: buttonConfirm,
            },
            {
                key: REQUEST_ACTION.REJECT,
                value: REQUEST_ACTION.REJECT,
                text: buttonReject,
            },
        ];
    };

    handleCloseConfirm = () => {
        this.setState({
            confirmSubmitData: {},
            requestNumber: "",
            confirmMessage: "",
            isOpenConfirm: false,
        });
    };

    handleOpenConfirm = (confirmSubmitData, requestNumber, confirmMessage) => {
        this.setState({
            confirmSubmitData,
            requestNumber,
            confirmMessage,
            isOpenConfirm: true,
        });
    };

    handleSubmitConfirm = () => {
        const {updateRequest} = this.props;
        const {confirmSubmitData} = this.state;

        updateRequest({
            ...confirmSubmitData,
        });

        this.handleCloseConfirm();
    };

    onClickActionItem = (option, request) => {
        const {value: action} = option;
        const {
            foremanRequestId,
            clientId,
            orderId,
            type: requestType,
            data = {},
            objectId,
            orderName: name,
            requestNumber,
        } = request;
        const {
            translate: {
                confirmApplyMessage,
                confirmRejectMessage,
            },
        } = this.props;

        switch (action) {
        case REQUEST_ACTION.REJECT:
            this.handleOpenConfirm({
                foremanRequestId,
                clientId,
                orderId,
                status: STATUS.REJECTED,
            }, requestNumber, confirmRejectMessage);

            return;
        case REQUEST_ACTION.CONFIRM:
            if (requestType === REQUEST_TYPE.ADDITIONAL_WORKS_REQUEST) {
                const {
                    contractorsNeededCount,
                    description,
                    amount,
                    workStartDate,
                    workEndDate,
                } = data;

                this.setState({
                    isOpenForm: true,
                    orderData: {
                        requestType,
                        clientId,
                        orderId,
                        foremanRequestId,
                        status: STATUS.CONFIRMED,
                        formData: {
                            workStartDate: new Date(workStartDate),
                            workEndDate: new Date(workEndDate),
                            contractorsNeededCount,
                            description,
                            amount: formatAmount(amount),
                            objectId,
                            name,
                        },
                    },
                });

                return;
            }

            if (requestType === REQUEST_TYPE.DUPLICATE_ORDER_REQUEST) {
                const {
                    workStartDate,
                    workEndDate,
                } = data;

                this.setState({
                    isOpenForm: true,
                    requestType,
                    orderData: {
                        requestType,
                        clientId,
                        orderId,
                        foremanRequestId,
                        status: STATUS.CONFIRMED,
                        formData: {
                            workStartDate: new Date(workStartDate),
                            workEndDate: new Date(workEndDate),
                        },
                    },
                });

                return;
            }

            this.handleOpenConfirm({
                foremanRequestId,
                clientId,
                orderId,
                status: STATUS.CONFIRMED,
            }, requestNumber, confirmApplyMessage);

            return;
            // no default
        }
    };

    handleClose = () => {
        this.setState({
            isOpenForm: false,
            orderData: {},
        });
    };

    updateRequest = requestData => {
        const {updateRequest} = this.props;
        const {
            close,
            ...reqData
        } = requestData;
        const {
            orderData: {
                clientId,
                orderId,
                foremanRequestId,
                status,
            },
        } = this.state;

        updateRequest({
            clientId,
            orderId,
            foremanRequestId,
            close,
            status: reqData.status === ORDER_STATUS.DRAFT ? STATUS.NEW : status,
            data: {
                ...reqData,
            },
        });
    };

    mapTableData() {
        const {list} = this.props;

        const rows = list.map((request) => {
            const {
                creationDate,
                objectName,
                objectAddress,
                coordinatorLastName,
                coordinatorFirstName,
                coordinatorPatronymic,
                foremanContractorLastName,
                foremanContractorPatronymic,
                foremanContractorFirstName,
                orderNum,
                orderName,
            } = request;
            const foremanFullName = `${foremanContractorLastName || ""} ${foremanContractorFirstName || ""} ${foremanContractorPatronymic || ""}`;
            const coordinatorFullName = `${coordinatorLastName || ""} ${coordinatorFirstName || ""} ${coordinatorPatronymic || ""}`;

            return {
                ...request,
                creationDate: formatDate(convertUtcToLocal(creationDate)),
                object: `${objectName || ""} - ${objectAddress || ""}`,
                foremanFullName: this.renderName(getFormattedFullName(foremanFullName)),
                coordinatorFullName: this.renderName(getFormattedFullName(coordinatorFullName)),
                orderInfo: `${orderNum} - ${orderName}`,
                status: this.renderStatus(request),
                action: this.renderAction(request),
            };
        });

        return {
            rows,
            headers,
        };
    }

    renderName = name => {
        return (
            <div className="foreman-requests__name">
                {name}
            </div>
        );
    };

    renderAction = (request) => {
        if([NM_OPERATOR].includes(this.role)){
            return null;
        }
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;
        const {status} = request;

        if (isClientArchived || (status !== STATUS.NEW)) {
            return null;
        }

        return (
            <div className="fluid flex flex-content-end">
                <ContextMenu
                    table
                    options={this.options}
                    onClickItem={(option) => {
                        this.onClickActionItem(option, request);
                    }}
                />
            </div>
        );
    };

    renderStatus = ({status}) => {
        const {foremanRequestStatuses} = this.props;

        return (
            <div
                style={{
                    color: COLOR_STATUS[status].TEXT,
                    borderColor: COLOR_STATUS[status].BORDER,
                }}
                className="foreman-requests__status"
            >
                {foremanRequestStatuses[status]}
            </div>
        );
    };

    renderConfirm() {
        const {translate} = this.props;
        const {
            isOpenConfirm,
            requestNumber,
            confirmMessage,
        } = this.state;
        const {
            buttonYes,
            buttonNo,
        } = translate;

        return isOpenConfirm &&
            <NmConfirmV2
                onCancel={this.handleCloseConfirm}
                onConfirm={this.handleSubmitConfirm}
                confirmButton={buttonYes}
                cancelButton={buttonNo}
                content={`${confirmMessage} №${requestNumber}?`}
                size="sm"
            />;
    }

    renderOrderCreate() {
        const {
            isOpenForm,
            orderData: {
                requestType,
                clientId,
                orderId,
                formData = {},
            },
        } = this.state;

        return isOpenForm &&
            <ForemanRequestsOrderEditContainer
                role={this.role}
                handleClose={this.handleClose}
                clientId={clientId}
                editOrderId={orderId}
                duplicate={requestType === REQUEST_TYPE.DUPLICATE_ORDER_REQUEST}
                requestType={requestType}
                updateRequest={this.updateRequest}
                formData={formData}
            />;
    }

    render() {
        const {
            pageNum,
            pageSize,
            handleChangePageSize,
            handlePaginationChange,
            totalPages,
            totalCount,
            progressList,
        } = this.props;

        return (
            <NmPage
                className="foreman-requests"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={handleChangePageSize}
                onPaginationChange={handlePaginationChange}
                totalCount={totalCount}
                showHeaderBlock={false}
                isLoaded={progressList}
            >
                {this.renderConfirm()}
                {this.renderOrderCreate()}
                {
                    !(totalCount || progressList) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                        /> :
                        <TableDiv
                            className="foreman-requests__table"
                            tableData={this.mapTableData()}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: foremanRequestsListSelector(state),
        totalPages: foremanRequestsTotalPagesSelector(state),
        foremanRequestStatuses: foremanRequestStatusesDictSelector(state),
        progressList: foremanProgressListSelector(state),
        totalCount: foremanRequestsTotalCountSelector(state),
        client: getClientCardSelector(state),
    }),
    null,
)(ForemanRequests);
