export const STATUS_BANK_DEPOSIT = {
    ERROR: {
        TEXT: "Ошибка",
        VALUE: "ERROR",
        BADGE_MOD: "red",
    },
    OTHER_INCOME: {
        TEXT: "Прочий приход",
        VALUE: "OTHER_INCOME",
        BADGE_MOD: "orange",
    },
    SUCCESSFULLY: {
        TEXT: "Успешно",
        VALUE: "SUCCESSFULLY",
        BADGE_MOD: "green",
    },
    OTHER_CONSUMPTION: {
        TEXT: "Прочий расход",
        VALUE: "OTHER_CONSUMPTION",
        BADGE_MOD: "orange",
    },
};

export const OPTIONS_DIRECTION = [
    {
        key: "all",
        value: "all",
        text: "Все",
    },
    {
        key: "INCOME",
        value: "INCOME",
        text: "Поступления",
    },
    {
        key: "CONSUMPTION",
        value: "CONSUMPTION",
        text: "Списания",
    },
    {
        key: "OTHER_INCOME",
        value: "OTHER_INCOME",
        text: "Прочий приход",
    },
];