import React from "react";

import NmSvg from "../../NmSvg";
import NmAdvancedTooltip from "../NmAdvancedTooltip";

import "./style.sass";


const NM_ITEM_STATUS_TO_CLASS_DICT = {
    SUCCESS: "nm-item-status_success",
    PART_SUCCESS: "nm-item-status_part-success",
    PROGRESS: "nm-item-status_progress",
    WAITING: "nm-item-status_waiting",
    ERROR: "nm-item-status_error",
};

const NmItemStatus = ({status, text, info}) => {
    return (
        <div className={`nm-item-status flex ${NM_ITEM_STATUS_TO_CLASS_DICT[status]}`}>
            {text}

            {info &&
            <div className="nm-item-status__info">
                <NmAdvancedTooltip
                    children={info}
                    noRelative
                    //className="nm-item-status__info-tooltip"
                    trigger={
                        <NmSvg
                            className="nm-item-status__info-icon"
                            name="info"
                        />
                    }
                />
            </div>}
        </div>
    );
};

export default NmItemStatus;