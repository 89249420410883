import React from "react";

export const getOrderRecommendationIndicatorText = (predictAmount, unitText) => {
    return (
        <>
            <>
                Мы подключили рекомендательную систему, которая автоматически анализирует ранее размещенные аналогичные заказы на площадке и формирует наилучшую стоимость, которая для данного заказа составляет:&nbsp;
            </>
            <span className="order-edit-tariff-scale-indicator__amount">
                {`${String(Math.ceil(predictAmount))}₽${unitText}`}
            </span>
            <>
                {"\n\nВы можете установить сумму из предложенной рекомендации или выше, чтобы получить больше откликов"}
            </>
        </>
    );
};