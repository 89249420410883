import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmSelectedList from "../../../../../components/NmSelectedList";

import {contractorCardSelector} from "../../../../../ducks/contractor";

export const ContractorVerificationDataProjectParams = (props) => {
    const {
        className,
    } = props;
    const {
        projectParams,
    } = useSelector(contractorCardSelector);

    const projectParamsList = useMemo(() => {
        if (isEmpty(projectParams)) {
            return [{text: "-"}];
        }

        return projectParams.map(text => {
            return {text};
        });
    }, [projectParams]);

    return (
        <div className={className}>
            <NmSelectedList
                label="ProjectParams"
                list={projectParamsList}
                showedItemsCount={3}
                showListWithoutValue
            />
        </div>
    );
};