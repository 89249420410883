import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {TIME_INTERVAL_PAYMENT_POSSIBILITY} from "../../../constants/contractor";

import {
    bankCheckCancelToken,
    getContractorsPaymentPossibility,
    refreshContractorsPaymentPossibility,
    updateFieldsContractorPaymentPossibilityState,
} from "../../../ducks/contractorBankCheck";

export const useBankIndicator = (params) => {
    const {
        clientId,
        useFnsThreshold = true,
        nameField = "contractorsPossibility",
        contractorIds,
    } = params;

    const dispatch = useDispatch();
    const timer = useRef(null);

    useEffect(() => {
        return () => {
            bankCheckCancelToken.cancel();
            clearTimeout(timer.current);
            dispatch(updateFieldsContractorPaymentPossibilityState({
                [nameField]: [],
            }));
        };
    }, []);

    const getContractorIds = (ids) => {
        return contractorIds ? contractorIds : ids;
    };

    const getBankChecks = (ids) => {
        const contractorIds = getContractorIds(ids);

        if (isEmpty(contractorIds)) {
            return;
        }

        dispatch(getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(timer.current);
            },
            handleResponse: onSuccessGetBankChecks,
            clientId,
            nameField,
            contractorIds,
        }));
    };

    const onSuccessGetBankChecks = (result = {}) => {
        const {
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            return;
        }

        timer.current = setTimeout(getBankChecks, TIME_INTERVAL_PAYMENT_POSSIBILITY);
    };

    const refreshBankChecks = (ids) => {
        bankCheckCancelToken.cancel();
        clearTimeout(timer.current);

        const contractorIds = getContractorIds(ids);

        if (isEmpty(contractorIds)) {
            return;
        }

        dispatch(refreshContractorsPaymentPossibility({
            contractorIds,
            useFnsThreshold,
            handleResponse: onSuccessRefreshBankChecks,
        }));
    };

    const onSuccessRefreshBankChecks = ({errorMessage}) => {
        if (errorMessage) {
            return;
        }

        this.getBankChecks();
    };

    return {
        getBankChecks,
        refreshBankChecks,
    };
};