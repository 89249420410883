import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

const vacancyPersonalAccessSelector = state => state.bff.recruitment.accessControl.vacancyPersonalAccess;

export const bffRecruitmentVacancyPersonalAccessProgressSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({progress}) => progress,
);
export const bffRecruitmentVacancyPersonalAccessProgressActionSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({progressAction}) => progressAction,
);
export const bffRecruitmentVacancyPersonalAccessListSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({list}) => list,
);
export const bffRecruitmentVacancyPersonalAccessTotalPagesSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({totalCount, listPageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffRecruitmentVacancyPersonalAccessTotalCountSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({totalCount}) => totalCount,
);
export const bffRecruitmentVacancyPersonalAccessAvailableUsersListSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({availableUsers}) => availableUsers,
);
export const bffRecruitmentVacancyPersonalAccessAvailableUsersTotalPagesSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({availableUsersTotalCount, availableUsersPageData: {pageSize}}) => getTotalPages(availableUsersTotalCount, pageSize),
);
export const bffRecruitmentVacancyPersonalAccessAvailableUsersTotalCountSelector = createSelector(
    vacancyPersonalAccessSelector,
    ({availableUsersTotalCount}) => availableUsersTotalCount,
);