import React, {useContext} from "react";

import NmCheckboxV2 from "../../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmTextareaV2 from "../../../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../../../components/ActualComponents/Text";
import {CrowdTaskEditContext} from "../context-provider";

import bem from "../../../../../../../utils/bem";

import {CROWD_TASK_SFA_TYPE, CROWD_TASK_SFA_TYPE_OPTIONS} from "../../constants";

import "./style.sass";

const CrowdTaskEditAdditionalTermForm = () => {
    const {
        clientId,
        values,
        touched,
        errors,
        handleChange,
    } = useContext(CrowdTaskEditContext);

    const [block, element] = bem("crowd-task-edit-additional-terms-form");

    return (
        <div className={block()}>
            <NmForm
                addMargin={true}
                className={element("block")}
            >
                <Text
                    level="4"
                    medium={true}
                >
Контроль присутствия по адресу выполнения работ
                </Text>
                <NmDropdownV2
                    required
                    search={true}
                    size="xl"
                    label="SFA"
                    name="sfaType"
                    options={CROWD_TASK_SFA_TYPE_OPTIONS}
                    value={values.sfaType}
                    onChange={handleChange}
                    error={touched.sfaType && errors.sfaType}
                />
                {
                    ![CROWD_TASK_SFA_TYPE.OPTIMUM.VALUE].includes(values.sfaType) &&
                    <NmInputV2
                        required
                        size="xl"
                        label="Ссылка на SFA"
                        placeholder="Ссылка на форму для заполнения"
                        value={values.sfaLink}
                        name="sfaLink"
                        onChange={handleChange}
                        error={touched.sfaLink && errors.sfaLink}
                    />
                }
                <NmCheckboxV2
                    name="checkInRequired"
                    onChange={handleChange}
                    checked={values.checkInRequired}
                    label="Требовать отметку о прибытии"
                    classNameLabel={element("main-checkbox")}
                />
                <NmCheckboxV2
                    className="ms-6"
                    disabled={!values.checkInRequired}
                    name="checkInPhotoRequired"
                    onChange={handleChange}
                    checked={values.checkInPhotoRequired}
                    label="Требовать фото при старте выполнения"
                />
                <NmCheckboxV2
                    name="checkOutRequired"
                    onChange={handleChange}
                    checked={values.checkOutRequired}
                    label="Требовать отметку об уходе"
                    classNameLabel={element("main-checkbox")}
                />
                <NmCheckboxV2
                    className="ms-6"
                    disabled={!values.checkOutRequired}
                    name="checkOutPhotoRequired"
                    onChange={handleChange}
                    checked={values.checkOutPhotoRequired}
                    label="Требовать фото при завершении выполнения"
                />
            </NmForm>
            <NmForm
                addMargin={true}
                className={element("block")}
            >
                <Text
                    level="4"
                    medium={true}
                >
Требования к самозанятому
                </Text>
                <NmCheckboxV2
                    name="medicalBookRequired"
                    onChange={handleChange}
                    checked={values.medicalBookRequired}
                    label="Медицинская книжка"
                />
                <NmCheckboxV2
                    name="driverLicenseRequired"
                    onChange={handleChange}
                    checked={values.driverLicenseRequired}
                    label="Водительские права"
                />
                <div className="flex ms-6">
                    {
                        ["A", "B", "C", "D", "E"].map(item => {
                            return (
                                <NmCheckboxV2
                                    disabled={!values.driverLicenseRequired}
                                    className="me-2"
                                    name="driverLicenseRequiredCategories"
                                    checked={values.driverLicenseRequiredCategories?.includes(item)}
                                    onChange={handleChange}
                                    value={item}
                                    label={item}
                                />
                            );
                        })
                    }
                </div>
                <NmCheckboxV2
                    className="ms-6"
                    disabled={!values.driverLicenseRequired}
                    name="personalVehicleRequired"
                    onChange={handleChange}
                    checked={values.personalVehicleRequired}
                    label="Есть личный автомобиль"
                />
                <Text
                    level="4"
                    medium={true}
                >
Категория продукции
                </Text>
                <NmTextareaV2
                    required
                    minRows={6}
                    maxRows={6}
                    placeholder="Введите категорию"
                    name="productCategory"
                    maxLength={1500}
                    value={values.productCategory}
                    onChange={handleChange}
                    error={touched.productCategory && errors.productCategory}
                />
            </NmForm>
        </div>
    );
};

export default CrowdTaskEditAdditionalTermForm;