import {useState} from "react";
import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../utils/toastHelper";

import {importAdvertisementList} from "../../../../ducks/advertisement";

export function useAdvertisementImportList({fetchList, clientId}) {
    const [isShowImportFileModal, setIsShowImportFileModal] = useState(false);
    const [progressImport, setProgressImport] = useState(false);
    const dispatch = useDispatch();

    const submitImportForm = ({file}) => {
        setProgressImport(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientId", clientId);

        dispatch(importAdvertisementList({
            clientId,
            formData,
            getResult: ({errorMessage}) => {
                setProgressImport(false);
                if (!errorMessage) {
                    toastSuccess("Загрузка файла успешно завершена. Новые объявления станут доступны через некоторое время.");
                    setIsShowImportFileModal(false);
                    setTimeout(fetchList, 5000);
                }
            },
        }));
    };

    return {
        isShowImportFileModal,
        setIsShowImportFileModal,
        submitImportForm,
        progressImport,
    };
}