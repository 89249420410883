import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/clients/emailnotifications";
//*  TYPES  *//

const GET_EMAIL_TYPE_MAP_REQUEST = "GET_EMAIL_TYPE_MAP_REQUEST";
const GET_EMAIL_TYPE_MAP_SUCCESS = "GET_EMAIL_TYPE_MAP_SUCCESS";
const GET_EMAIL_TYPE_MAP_ERROR = "GET_EMAIL_TYPE_MAP_ERROR";

const ADD_EMAIL_NOTIFICATION_REQUEST = "ADD_EMAIL_NOTIFICATION_REQUEST";
const ADD_EMAIL_NOTIFICATION_SUCCESS = "ADD_EMAIL_NOTIFICATION_SUCCESS";
const ADD_EMAIL_NOTIFICATION_ERROR = "ADD_EMAIL_NOTIFICATION_ERROR";

const GET_EMAIL_NOTIFICATION_LIST_REQUEST = "GET_EMAIL_NOTIFICATION_LIST_REQUEST";
const GET_EMAIL_NOTIFICATION_LIST_SUCCESS = "GET_EMAIL_NOTIFICATION_LIST_SUCCESS";
const GET_EMAIL_NOTIFICATION_LIST_ERROR = "GET_EMAIL_NOTIFICATION_LIST_ERROR";

const DELETE_EMAIL_NOTIFICATION_REQUEST = "DELETE_EMAIL_NOTIFICATION_REQUEST";
const DELETE_EMAIL_NOTIFICATION_SUCCESS = "DELETE_EMAIL_NOTIFICATION_SUCCESS";
const DELETE_EMAIL_NOTIFICATION_ERROR = "DELETE_EMAIL_NOTIFICATION_ERROR";

const GET_SETTING_NOTIFICATION_LIST_REQUEST = "GET_SETTING_NOTIFICATION_LIST_REQUEST";
const GET_SETTING_NOTIFICATION_LIST_SUCCESS = "GET_SETTING_NOTIFICATION_LIST_SUCCESS";
const GET_SETTING_NOTIFICATION_LIST_ERROR = "GET_SETTING_NOTIFICATION_LIST_ERROR";

const DELETE_SETTING_NOTIFICATION_LIST_REQUEST = "DELETE_SETTING_NOTIFICATION_LIST_REQUEST";
const DELETE_SETTING_NOTIFICATION_LIST_SUCCESS = "DELETE_SETTING_NOTIFICATION_LIST_SUCCESS";
const DELETE_SETTING_NOTIFICATION_LIST_ERROR = "DELETE_SETTING_NOTIFICATION_LIST_ERROR";

const ADD_SETTING_NOTIFICATION_LIST_REQUEST = "ADD_SETTING_NOTIFICATION_LIST_REQUEST";
const ADD_SETTING_NOTIFICATION_LIST_SUCCESS = "ADD_SETTING_NOTIFICATION_LIST_SUCCESS";
const ADD_SETTING_NOTIFICATION_LIST_ERROR = "ADD_SETTING_NOTIFICATION_LIST_ERROR";

const UPDATE_SETTING_NOTIFICATION_RUQUEST = "UPDATE_SETTING_NOTIFICATION_RUQUEST";
const UPDATE_SETTING_NOTIFICATION_SUCCESS = "UPDATE_SETTING_NOTIFICATION_SUCCESS";
const UPDATE_SETTING_NOTIFICATION_ERROR = "UPDATE_SETTING_NOTIFICATION_ERROR";

const GET_SETTING_NOTIFICATION_REQUEST = "GET_SETTING_NOTIFICATION_REQUEST";
const GET_SETTING_NOTIFICATION_SUCCESS = "GET_SETTING_NOTIFICATION_SUCCESS";
const GET_SETTING_NOTIFICATION_ERROR = "GET_SETTING_NOTIFICATION_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    subscribeList: {},
    emailTypeMap: {},
    progressList: false,
    progressAdd: false,
    progressDelete: false,
    progress: false,
    error: {},
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_EMAIL_TYPE_MAP_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_EMAIL_TYPE_MAP_SUCCESS:
        return {
            ...state,
            progress: false,
            emailTypeMap: payload,
        };
    case GET_EMAIL_TYPE_MAP_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case GET_EMAIL_NOTIFICATION_LIST_REQUEST:
        return {
            ...state,
            progressList: true,
            pageData: payload,
        };
    case GET_EMAIL_NOTIFICATION_LIST_SUCCESS:
        return {
            ...state,
            progressList: false,
            list: payload,
        };
    case GET_EMAIL_NOTIFICATION_LIST_ERROR:
        return {
            ...state,
            progressList: false,
            error: payload,
        };
    case ADD_EMAIL_NOTIFICATION_REQUEST:
        return {
            ...state,
            progressAdd: true,
        };
    case ADD_EMAIL_NOTIFICATION_SUCCESS:
        return {
            ...state,
            progressAdd: false,
        };
    case ADD_EMAIL_NOTIFICATION_ERROR:
        return {
            ...state,
            progressAdd: false,
            error: payload,
        };
    case DELETE_EMAIL_NOTIFICATION_REQUEST:
        return {
            ...state,
            progressDelete: true,
        };
    case DELETE_EMAIL_NOTIFICATION_SUCCESS:
        return {
            ...state,
            progressDelete: false,
        };
    case DELETE_EMAIL_NOTIFICATION_ERROR:
        return {
            ...state,
            progressDelete: false,
            error: payload,
        };
    case GET_SETTING_NOTIFICATION_LIST_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case GET_SETTING_NOTIFICATION_LIST_SUCCESS:
        return {
            ...state,
            progress: false,
            subscribeList: payload,
        };
    case GET_SETTING_NOTIFICATION_LIST_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case DELETE_SETTING_NOTIFICATION_LIST_REQUEST:
        return {
            ...state,
            progressDelete: true,
        };
    case DELETE_SETTING_NOTIFICATION_LIST_SUCCESS:
        return {
            ...state,
            progressDelete: false,
        };
    case DELETE_SETTING_NOTIFICATION_LIST_ERROR:
        return {
            ...state,
            progressDelete: false,
            error: payload,
        };
    case ADD_SETTING_NOTIFICATION_LIST_REQUEST:
        return {
            ...state,
            progressAdd: true,
        };
    case ADD_SETTING_NOTIFICATION_LIST_SUCCESS:
        return {
            ...state,
            progressAdd: false,
        };
    case ADD_SETTING_NOTIFICATION_LIST_ERROR:
        return {
            ...state,
            progressAdd: false,
            error: payload,
        };
    case UPDATE_SETTING_NOTIFICATION_RUQUEST:
        return {
            ...state,
            progresUpdate: true,
        };
    case UPDATE_SETTING_NOTIFICATION_SUCCESS:
        return {
            ...state,
            progresUpdate: false,
        };
    case UPDATE_SETTING_NOTIFICATION_ERROR:
        return {
            ...state,
            progresUpdate: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getEmailTypeMap() {
    return {
        type: GET_EMAIL_TYPE_MAP_REQUEST,
    };
}

export function addEmailNotification(payload) {
    return {
        type: ADD_EMAIL_NOTIFICATION_REQUEST,
        payload,
    };
}

export function deleteEmailNotification(payload) {
    return {
        type: DELETE_EMAIL_NOTIFICATION_REQUEST,
        payload,
    };
}

export function getPageSettingNotification(payload) {
    return {
        type: GET_SETTING_NOTIFICATION_LIST_REQUEST,
        payload,
    };
}

export function deleteEmailNotificationList(payload) {
    return {
        type: DELETE_SETTING_NOTIFICATION_LIST_REQUEST,
        payload,
    };
}

export function updateEmailNotification(payload) {
    return {
        type: UPDATE_SETTING_NOTIFICATION_RUQUEST,
        payload,
    };
}

export function getEmailNotificationById(payload) {
    return {
        type: GET_SETTING_NOTIFICATION_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const emailNotificationSelector = state => state.emailNotification;

export const getEmailTypeMapSelector = createSelector(emailNotificationSelector, ({emailTypeMap}) => emailTypeMap);
export const getEmailTypeMapOptionsSelector = createSelector(emailNotificationSelector, ({emailTypeMap}) => dictionaryToOptions(emailTypeMap));

export const getListSettingNotificationSelector = createSelector(emailNotificationSelector, ({subscribeList}) => subscribeList.results && subscribeList.results);
export const getTotalPagesSettingNotificationSelector = createSelector(emailNotificationSelector, ({subscribeList: {totalCount}, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getTotalCountSettingNotificationSelector = createSelector(emailNotificationSelector, ({subscribeList: {totalCount}}) => totalCount);

//*  SAGA  *//

//GET /api/clients/emailnotifications/getEmailTypeMap
export const getEmailTypeMapSaga = function* () {
    try {

        const result = yield request.get(`${controller}/getEmailTypeMap`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: GET_EMAIL_TYPE_MAP_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_EMAIL_TYPE_MAP_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: GET_EMAIL_TYPE_MAP_ERROR, payload: error});
    }
};

//POST /api/clients/emailnotifications/add
export const addEmailNotificationSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;
        const result = yield request.post(`${controller}/add`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_EMAIL_NOTIFICATION_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getSuccess("Рассылка на почту успешно сохранена");

        yield put({type: ADD_EMAIL_NOTIFICATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_EMAIL_NOTIFICATION_ERROR, payload: error});
    }
};

//DELETE /api/clients/emailnotifications/delete
export const deleteEmailNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                notificationEmailId,
                clientId,
                getSuccess,
            },
        } = action;

        const result = yield request.delete(`${controller}/delete?notificationEmailId=${notificationEmailId}&clientId=${clientId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_EMAIL_NOTIFICATION_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getSuccess("Рассылка на почту успешно удалена");

        yield put({type: DELETE_EMAIL_NOTIFICATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_EMAIL_NOTIFICATION_ERROR, payload: error});
    }
};

//POST /api/clients/emailnotifications/find
export const getEmailNotificationListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/find`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_EMAIL_NOTIFICATION_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_EMAIL_NOTIFICATION_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_EMAIL_NOTIFICATION_LIST_ERROR, payload: error});
    }
};

//POST /api/clients/emailnotifications/page
export const getPageSettingNotificationSaga = function* ({payload}) {
    try {
        const {getResult, ...data} = payload;
        const result = yield request.post(`${controller}/page`, data);

        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: GET_SETTING_NOTIFICATION_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_SETTING_NOTIFICATION_LIST_ERROR,
            payload: error,
        });
    }
};

//DELETE /api/clients/emailnotifications/deleteAll
export const deleteEmailSettingNotificationListSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            getSuccess,
            notificationEmailIds,
            clientId,
        } = payload;

        let query = `clientId=${clientId}&`;

        notificationEmailIds.forEach((item, index) => {
            query += `notificationEmailIds=${item}${index === notificationEmailIds.length - 1 ? "" : "&"}`;
        });

        const result = yield request.delete(`${controller}/deleteAll?${query}`, notificationEmailIds);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DELETE_SETTING_NOTIFICATION_LIST_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        getSuccess(notificationEmailIds.length > 1 ? "Рассылки на почту успешно удалены" : "Рассылка на почту успешно удалена");

        yield put({type: DELETE_SETTING_NOTIFICATION_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: DELETE_SETTING_NOTIFICATION_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/clients/emailnotifications/update
export const updateEmailNotificationSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/update`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        getSuccess("Рассылка на почту успешно сохранена");

        yield put({
            type: UPDATE_SETTING_NOTIFICATION_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: UPDATE_SETTING_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/emailnotifications/findById

export const getEmailNotificationByIdSaga = function* (action) {
    try {
        const {
            payload: {
                getResult,
                ...params
            },
        } = action;

        const result = yield request.get(`${controller}/findById`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SETTING_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({type: GET_SETTING_NOTIFICATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_SETTING_NOTIFICATION_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_EMAIL_TYPE_MAP_REQUEST, getEmailTypeMapSaga),
        takeEvery(ADD_EMAIL_NOTIFICATION_REQUEST, addEmailNotificationSaga),
        takeEvery(GET_EMAIL_NOTIFICATION_LIST_REQUEST, getEmailNotificationListSaga),
        takeEvery(DELETE_EMAIL_NOTIFICATION_REQUEST, deleteEmailNotificationSaga),
        takeEvery(DELETE_SETTING_NOTIFICATION_LIST_REQUEST, deleteEmailSettingNotificationListSaga),
        takeEvery(GET_SETTING_NOTIFICATION_LIST_REQUEST, getPageSettingNotificationSaga),
        takeEvery(UPDATE_SETTING_NOTIFICATION_RUQUEST, updateEmailNotificationSaga),
        takeEvery(GET_SETTING_NOTIFICATION_REQUEST, getEmailNotificationByIdSaga),
    ]);
}
