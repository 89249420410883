import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdActRegistrySelector = state => state.bff.crowd.actRegistry;
export const bffCrowdActRegistryListSelector = createSelector(bffCrowdActRegistrySelector, ({list}) => list);
export const bffCrowdActRegistryTotalCountSelector = createSelector(bffCrowdActRegistrySelector, ({totalCount}) => totalCount);
export const bffCrowdActRegistryTotalPagesSelector = createSelector(bffCrowdActRegistrySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdActRegistryProgressSelector = createSelector(bffCrowdActRegistrySelector, ({progress}) => progress);
export const bffCrowdActRegistryProgressActionSelector = createSelector(bffCrowdActRegistrySelector, ({progressAction}) => progressAction);
export const bffCrowdActRegistryCardSelector = createSelector(bffCrowdActRegistrySelector, ({card}) => card);
export const bffCrowdActRegistryProgressCardSelector = createSelector(bffCrowdActRegistrySelector, ({progressCard}) => progressCard);
export const bffCrowdActRegistryLogListSelector = createSelector(bffCrowdActRegistrySelector, ({logList}) => logList);
export const bffCrowdActRegistryLogTotalCountSelector = createSelector(bffCrowdActRegistrySelector, ({logTotalCount}) => logTotalCount);
export const bffCrowdActRegistryLogTotalPagesSelector = createSelector(bffCrowdActRegistrySelector, ({logTotalCount, logPageData: {pageSize = 0}}) => getTotalPages(logTotalCount, pageSize));
export const bffCrowdActRegistryLogProgressSelector = createSelector(bffCrowdActRegistrySelector, ({logProgress}) => logProgress);
