import {useEffect, useState} from "react";
import {isEqual} from "lodash";

import {useGetPrevValue} from "./useGetPrevValue";

export const useCounter = ({countList = []}) => {
    const [count, setCount] = useState(0);
    const prevCountList = useGetPrevValue(countList);

    useEffect(() => {
        if (!isEqual(prevCountList, countList)){
            setCount(0);
        }
    }, [countList]);

    const increaseCounter = () => setCount(value => value + 1);
    
    return {
        isCounterFinish: Boolean(countList.length) && count === countList.length,
        increaseCounter,
    };
};