import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_MIGRATION_STATUS, CONTRACTOR_VHI_POLICE_TYPE} from "../../../constants/contractor";

export const getFullRegistrationVhiPolicyType = (params) => {
    const {
        citizenship,
        migrationStatus,
    } = params;

    const validations = {
        [CONTRACTOR_VHI_POLICE_TYPE.COMPULSORY]: // омс
        [
            CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
        ].includes(migrationStatus) ||
        [
            citizenshipsDict.AM.value,
            citizenshipsDict.KZ.value,
            citizenshipsDict.KG.value,
            citizenshipsDict.BY.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.NONE,
        ].includes(migrationStatus) ||
        [
            CONTRACTOR_MIGRATION_STATUS.REFUGEE,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
        ].includes(migrationStatus) ||
        [
            CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT,
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
        ].includes(migrationStatus) &&
        [
            citizenshipsDict.NOT_SPECIFIED.value,
        ].includes(citizenship),
        [CONTRACTOR_VHI_POLICE_TYPE.VOLUNTARY]: // дмс
        [
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
            CONTRACTOR_MIGRATION_STATUS.STUDENT,
            CONTRACTOR_MIGRATION_STATUS.PATENT,
            CONTRACTOR_MIGRATION_STATUS.VISA,
        ].includes(migrationStatus) ||
        [
            citizenshipsDict.AM.value,
            citizenshipsDict.KZ.value,
            citizenshipsDict.KG.value,
        ].includes(citizenship) &&
        [
            CONTRACTOR_MIGRATION_STATUS.NONE,
        ].includes(migrationStatus) ||
        [
            CONTRACTOR_MIGRATION_STATUS.TEMPORARY_RESIDENCE_PERMIT,
        ].includes(migrationStatus) &&
        [
            citizenshipsDict.NOT_SPECIFIED.value,
        ].includes(citizenship),
    };

    const variants = [
        CONTRACTOR_VHI_POLICE_TYPE.COMPULSORY,
        CONTRACTOR_VHI_POLICE_TYPE.VOLUNTARY,
    ].filter(policyType => validations[policyType]);


    if (variants.length === 1) {
        const [vhiPolicyType] = variants;

        return {
            value: vhiPolicyType,
            disabled: true,
        };
    }

    return {
        value: CONTRACTOR_VHI_POLICE_TYPE.VOLUNTARY,
        disabled: false,
    };
};