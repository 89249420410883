import {isEmpty} from "lodash";

import {formatDateWithoutTime} from "../../../../../utils/dateFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../../utils/stringHelper";

export const mapStatementFilter = (filter) => {
    const format = "yyyy-MM-dd";
    const {
        name,
        receiverFio,
        senderFio,
        createdFrom,
        createdTo,
        dateFrom,
        dateTo,
        approverFio,
        stmtState,
        stmtTypeIds,
    } = filter;

    return {
        name: handleFormString(name),
        receiverFio: handleFormString(receiverFio),
        senderFio: handleFormString(senderFio),
        approverFio: handleFormString(approverFio),
        createdFrom: isNullOrWhitespace(createdFrom) ? undefined : formatDateWithoutTime(createdFrom, format),
        createdTo: isNullOrWhitespace(createdTo) ? undefined : formatDateWithoutTime(createdTo, format),
        dateFrom: isNullOrWhitespace(dateFrom) ? undefined : formatDateWithoutTime(dateFrom, format),
        dateTo: isNullOrWhitespace(dateTo) ? undefined : formatDateWithoutTime(dateTo, format),
        stmtState: stmtState === "all" ? undefined : handleFormString(stmtState),
        stmtTypeIds: isEmpty(stmtTypeIds) ? undefined : stmtTypeIds,
    };
};