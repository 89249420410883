import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ADD_CLIENT_MODULE_TARIFFS_ERROR,
    ADD_CLIENT_MODULE_TARIFFS_REQUEST,
    ADD_CLIENT_MODULE_TARIFFS_SUCCESS,
    DELETE_CLIENT_MODULE_TARIFFS_ERROR,
    DELETE_CLIENT_MODULE_TARIFFS_REQUEST,
    DELETE_CLIENT_MODULE_TARIFFS_SUCCESS,
    UPDATE_CLIENT_MODULE_TARIFFS_ERROR,
    UPDATE_CLIENT_MODULE_TARIFFS_REQUEST,
    UPDATE_CLIENT_MODULE_TARIFFS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/clients/module-tariffs";

// POST /bff/adm/clients/module-tariffs/add
const addClientModuleTariff = function* ({payload}) {
    const {
        onSuccess = () => {},
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CLIENT_MODULE_TARIFFS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ADD_CLIENT_MODULE_TARIFFS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_CLIENT_MODULE_TARIFFS_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/module-tariffs/update
const updateClientModuleTariff = function* ({payload}) {
    const {
        onSuccess = () => {},
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_MODULE_TARIFFS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: UPDATE_CLIENT_MODULE_TARIFFS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_MODULE_TARIFFS_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/clients/module-tariffs/{id}/delete
const deleteClientModuleTariff = function* ({payload}) {
    const {
        onSuccess = () => {},
        id,
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/${id}/delete`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CLIENT_MODULE_TARIFFS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: DELETE_CLIENT_MODULE_TARIFFS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CLIENT_MODULE_TARIFFS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_CLIENT_MODULE_TARIFFS_REQUEST, addClientModuleTariff),
        takeEvery(UPDATE_CLIENT_MODULE_TARIFFS_REQUEST, updateClientModuleTariff),
        takeEvery(DELETE_CLIENT_MODULE_TARIFFS_REQUEST, deleteClientModuleTariff),
    ]);
}