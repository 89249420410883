import React from "react";

import NmAdvancedTooltip from "../../../ActualComponents/NmAdvancedTooltip";
import RefreshButton from "../../../RefreshButton";

import "./style.sass";

const BankIndicatorRefresh = ({progress, timeoutForCheckPossible, isRefresh, refreshPaymentPossibility}) => {
    const isShowCheckTimeoutHint = timeoutForCheckPossible > 0;
    const suggestion = isShowCheckTimeoutHint ? `Следующая проверка будет доступна через: ${timeoutForCheckPossible} секунд` : null;

    return (
        <NmAdvancedTooltip
            hover
            className="bank-indicator-refresh"
            classNameTooltip="bank-indicator-refresh__tooltip"
            position="bottom-left"
            children={suggestion}
            trigger={
                !progress &&
                <div className="bank-indicator-refresh__container">
                    <RefreshButton
                        containerIcon
                        disabled={timeoutForCheckPossible > 0}
                        animate={isRefresh}
                        className="bank-indicator-refresh__button"
                        onClick={refreshPaymentPossibility}
                    />
                </div>
            }
        />
    );
};

export default BankIndicatorRefresh;