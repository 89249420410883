import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {RecruitmentAccountDropdownWithTooltip} from "../../../../../components/Recruitment/AccountDropdownWithTooltip";
import {bffRecruitmentVacancyProgressActionSelector} from "../../../../../ducks/bff/recruitment/vacancy/selectors";

import {useRecruitmentVacancyExportJobBoardsForm} from "./hooks/useForm";

import {
    EXTERNAL_JOB_BOARDS_LIST,
    RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE,
} from "../../constants";

const RecruitmentVacancyExportJobBoardsForm = (props) => {
    const {
        clientId,
        onClose,
    } = props;

    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);

    const {
        onChange,
        values,
        errors,
        touched,
        handleSubmit,
    } = useRecruitmentVacancyExportJobBoardsForm({
        clientId,
        onClose,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Выгрузить вакансии из джоб-борда
                </NmTitle>
            }
            classNameHeader="flex mx-auto"
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent="Выгрузить вакансии"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progressAction || !values.jobBoardType}
                />
            }
        >
            <NmForm addMargin>
                {
                    EXTERNAL_JOB_BOARDS_LIST.map(type => {
                        return (
                            <NmRadioV2
                                key={type}
                                checked={type === values.jobBoardType}
                                value={type}
                                name="jobBoardType"
                                label={RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[type]}
                                onChange={onChange}
                            />
                        );
                    })
                }
                <RecruitmentAccountDropdownWithTooltip
                    required={true}
                    clientId={clientId}
                    jobBoardType={values.jobBoardType}
                    label="Аккаунт"
                    placeholder="Выберите"
                    name="jobBoardAccountId"
                    onChange={onChange}
                    value={values.jobBoardAccountId}
                    error={touched.jobBoardAccountId && errors.jobBoardAccountId}
                />
            </NmForm>
        </NmModal>
    );
};

export default RecruitmentVacancyExportJobBoardsForm;