import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {getSpecialitiesEditValidation} from "../utils/getValidation";

import {
    addSpecialities,
    specialitiesProgressActionSelector,
    updateSpecialities,
} from "../../../../../../../ducks/speciality";

export const useSpecialitiesEdit = ({fetchList, editData, onClose}) => {
    const dispatch = useDispatch();

    const progressAction = useSelector(specialitiesProgressActionSelector);

    useEffect(() => {
        if (editData) {
            setValues({
                ...values,
                ...editData,
            });
        }
    }, []);

    const {
        handleSubmit,
        values,
        setValues,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            value: "",
        },
        validationSchema: getSpecialitiesEditValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        if (editData) {
            const {id} = editData;

            dispatch(updateSpecialities({
                ...values,
                id,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(addSpecialities({
            ...values,
            onSuccess: () => {
                fetchList();
                onClose();
            },
        }));
    }

    return {
        errors,
        handleSubmit,
        values,
        progressAction,
        onChange,
    };
};