import {CLIENT_FIELD_NAME, CLIENT_PROPERTIES_FIELD} from "../../../../../constants/clientList";
import {commissionRate, requiredMessage} from "../../../../../constants/validationRules";

const paymentsCommission = {
    required: requiredMessage,
    minValueNotStrong: {
        value: 0,
        message: "Минимальное допустимое значение равно 0",
    },
    maxValue: {
        value: 49.99,
        message: "Максимальное допустимое значение равно 49.99",
    },
};

export const limitPaymentsCommissionValidations = {
    [CLIENT_FIELD_NAME.CURRENT_COMMISSION_RATE]: commissionRate,
    [CLIENT_FIELD_NAME.CIVIL_PAYMENT_COMMISSION]: commissionRate,
    [CLIENT_FIELD_NAME.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.MIN_COMMISSION_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    [CLIENT_FIELD_NAME.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
        maxValue: {
            value: 999999.99,
            message: "Максимальное допустимое значение равно 999 999.99",
        },
    },
    [CLIENT_FIELD_NAME.PAYMENTS_THRESHOLD_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    [CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    [CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT]: commissionRate,
    [CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION]: commissionRate,
    [CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION]: commissionRate,
    [CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
        maxValue: {
            value: 999999.99,
            message: "Максимальное допустимое значение равно 999 999.99",
        },
    },
    [CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
        maxValue: {
            value: 999999.99,
            message: "Максимальное допустимое значение равно 999 999.99",
        },
    },
};