import {useState} from "react";
import {isEmpty} from "lodash";

export const useObjectsChangeDeposit = () => {
    const [changeDepositData, setChangeDepositData] = useState({});

    const onOpenAddDeposit = (data = {}) => {
        setChangeDepositData({
            ...data,
            isOpenAddDeposit: !isEmpty(data),
        });
    };

    const onOpenReturnDeposit = (data = {}) => {
        setChangeDepositData({
            ...data,
            isOpenReturnDeposit: !isEmpty(data),
        });
    };

    return {
        changeDepositData,
        onOpenAddDeposit,
        onOpenReturnDeposit,
    };
};