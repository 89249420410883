import React, {useMemo} from "react";

import ClientNamesDropdown from "../../../../../../components/ActualComponents/ClientNamesDropdown";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {useKedoDocumentTypesEdit} from "./hooks/useEdit";

import {COLOR} from "../../../../../../constants/color";

export const KedoDirectoriesDocumentTypesEdit = (props) => {
    const {
        editData,
        fetchList,
        onClose,
        clientId,
        fetchCounts,
    } = props;

    const {
        errors,
        handleSubmit,
        progressAction,
        onChange,
        values,
        touched,
    } = useKedoDocumentTypesEdit({
        editData,
        fetchList,
        onClose,
        clientId,
        fetchCounts,
    });

    const initialOption = useMemo(() => {
        if (editData) {
            return {
                key: editData.clientId,
                value: editData.clientId,
                text: editData.clientName,
            };
        }
    }, []);

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        editData ?
                            "Редактирование типа загруженных документов" :
                            "Добавление типа загруженных документов"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submitBtnContent={editData ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    name="name"
                    value={values.name}
                    onChange={onChange}
                    label="Наименование"
                    placeholder="Введите наименование"
                    maxLength={255}
                    error={
                        touched?.name && errors?.name ?
                            errors?.name :
                            undefined
                    }
                />
                <NmInputV2
                    size="xl"
                    label="Внешний идентификатор"
                    placeholder="Введите внешний идентификатор типа документа"
                    name="externalId"
                    value={values.externalId}
                    maxLength={50}
                    required={true}
                    onChange={onChange}
                    error={
                        touched?.externalId && errors?.externalId ?
                            errors?.externalId :
                            undefined
                    }
                />
                {
                    !clientId &&
                    <ClientNamesDropdown
                        label="Компания"
                        placeholder="Не выбрано"
                        onChange={onChange}
                        name="clientId"
                        value={values.clientId}
                        initialOption={initialOption}
                        error={
                            touched?.clientId && errors?.clientId ?
                                errors?.clientId :
                                undefined
                        }
                    />
                }
                <div>
                    <Text
                        color={COLOR.SECONDARY_90}
                        level="3"
                        className="mb-2 mb-md-3"
                    >
                        Требуется УКЭП компании
                    </Text>
                    <NmRadioV2
                        name="onlyUkep"
                        checked={values.onlyUkep === true}
                        value={true}
                        className="mb-2 mb-md-3"
                        label="Да"
                        onChange={onChange}
                    />
                    <NmRadioV2
                        name="onlyUkep"
                        checked={values.onlyUkep === false}
                        disabled={true}
                        value={false}
                        label="Нет"
                        onChange={onChange}
                    />
                </div>
                <div>
                    <Text
                        color={COLOR.SECONDARY_90}
                        level="3"
                        className="mb-2 mb-md-3"
                    >
                        Первый подписант
                    </Text>
                    <NmRadioV2
                        name="availableClientUsers"
                        value={true}
                        checked={values.availableClientUsers === true}
                        className="mb-2 mb-md-3"
                        label="Сотрудник"
                        onChange={onChange}
                    />
                    <NmRadioV2
                        name="availableClientUsers"
                        checked={values.availableClientUsers === false}
                        value={false}
                        label="Компания"
                        onChange={onChange}
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};
