export const DADATA = {
    DADATA_ADDRESS_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    DADATA_ADDRESS_URL_BY_ID: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address",
    DADATA_BANK_URL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank",
};

export const DADATA_FIAS_LEVEL = {
    REGION: "region",
    CITY: "city",
    SETTLEMENT: "settlement",
};

export const DADATA_LEVELS = {
    REGION: "REGION",
    AREA: "AREA",
    CITY: "CITY",
    SETTLEMENT: "SETTLEMENT",
    PLANNING_STRUCTURE: "PLANNING_STRUCTURE",
    HOUSE: "HOUSE",
    STREET: "STREET",
    FLAT: "FLAT",
};

