import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

export const getSpecialitiesEditValidation = () => {
    return yup.object().shape({
        value: yup.string()
            .max(100, "Не более 100 символов")
            .min(3, "Минимальная длина строки 3 символа")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};