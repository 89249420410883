import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getKedoRoleDict} from "../../../../ducks/bff/common/dicts/actionCreators";

export const useKedoStaffRegistryDicts = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getKedoRoleDict());
    }, []);

    return null;
};