import React from "react";

import {ReactComponent as IconSee} from "../../images/see_24.svg";

import PropTypes from "prop-types";

import "./style.sass";

const ViewsCount = ({count = 0, className = ""}) => {
    return (
        <div className={`views-count ${className}`}>
            <IconSee
                width={20}
                height={20}
            />
            <div className="views-count__value">
                {count}
            </div>
        </div>
    );
};

ViewsCount.propTypes = {
    count: PropTypes.number,
};

export default ViewsCount;