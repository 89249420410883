import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getAdmKedoTemplatesPage} from "../../../../ducks/bff/adm/document-templates/kedo/templates/services";
import {updateKedoTemplatesStore} from "../../../../ducks/kedo/templates/actionCreators";
import {getKedoTemplatesInitialState} from "../../../../ducks/kedo/templates/reducer";

export const useKedoTemplateFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    useEffect(() => {
        return () => {
            const initialState = getKedoTemplatesInitialState();

            dispatch(updateKedoTemplatesStore(initialState));
        };
    }, []);

    const fetchList = () => {
        dispatch(getAdmKedoTemplatesPage({
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};