import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../components/CheckboxList";
import NmBadge from "../../../../components/NmBadge";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {SettingsDirectoriesCategorySpecialitiesEdit} from "./components/edit";

import {usePagination} from "../../../../hooks/usePagination";
import {useCategorySpecialitiesAction} from "./hooks/useAction";
import {useCategorySpecialitiesFetch} from "./hooks/useFetch";
import {useCategorySpecialitiesFilter} from "./hooks/useFilter";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../constants/roles";

import {
    categorySpecialitiesListSelector,
    categorySpecialitiesTotalCountSelector,
    categorySpecialitiesTotalPagesSelector,
} from "../../../../ducks/categorySpecialities";

import "./style.sass";

const SettingsDirectoriesCategorySpecialities = () => {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const totalPages = useSelector(categorySpecialitiesTotalPagesSelector);
    const list = useSelector(categorySpecialitiesListSelector);
    const totalCount = useSelector(categorySpecialitiesTotalCountSelector);
    const role = ls(USER_ROLE);

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    } = useCategorySpecialitiesFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useCategorySpecialitiesFetch({
        filterData,
        pageNum,
        pageSize,
    });

    const {
        progress,
        contentConfirm,
        editData,
        isOpenEdit,
        isOpenConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onOpenDelete,
        onCancelConfirm,
    } = useCategorySpecialitiesAction({
        fetchList,
    });

    const getSpecialities = ({specialityModels}) => {
        return specialityModels.map((item) => {
            return (
                <NmBadge
                    key={item.id}
                    text={item.value}
                    mod="light-green"
                />
            );
        });
    };

    const getMediaControls = (item) => {
        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {onOpenEdit(item);},
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenDelete(item),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        primaryHeader={item.value}
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        labels={[
                            {
                                label: "Код для иконки",
                                text: item.fieldCode,
                            },
                            {
                                wrapped: true,
                                classNameText: "settings-directories-category-specialities__text",
                                text: (
                                    <>
                                        <Text
                                            level="2"
                                            className="settings-directories-category-specialities__label"
                                        >
                                                Виды деятельности:
                                        </Text>
                                        {getSpecialities(item)}
                                    </>
                                ),
                            },
                        ]}
                        isFixedActions
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            className="settings-directories-category-specialities"
            controls={
                ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                <NmButton
                    size="xl"
                    onClick={() => {onOpenEdit();}}
                    icon={<AddIcon />}
                >
                    Добавить
                </NmButton>
            }
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            typeFilter="vertical"
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
        >
            {
                isOpenEdit &&
                <SettingsDirectoriesCategorySpecialitiesEdit
                    fetchList={fetchList}
                    onClose={onCloseEdit}
                    editData={editData}
                />
            }
            {
                isOpenConfirm &&
                <NmConfirmV2
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                    onCancel={onCancelConfirm}
                    onConfirm={onConfirm}
                    content={contentConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    isNeedClosing={false}
                />
            }
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        rows={rows}
                    />
            }
        </NmPage>
    );
};

export default SettingsDirectoriesCategorySpecialities;