import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {throttle} from "lodash";
import Stomp from "stompjs";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";

import {refreshTokenAction} from "../ducks/auth";

const replaceHttpFromUrlString = (str) => str.replace("https", "wss");

export const TIMEOUT_REFRESH_TOKEN = 5000;

export default function useWebSocket(params) {
    const {
        url = "/api/crm/websocket/connect",
        topic = "/call/incoming/event",
    } = params;
    const domain = window.location.origin.includes("localhost") ?
        replaceHttpFromUrlString(process.env.REACT_APP_PROXY) :
        replaceHttpFromUrlString(window.location.origin);
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const refreshingToken = useRef(throttle(refreshToken, TIMEOUT_REFRESH_TOKEN));

    const headers = {"Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`};

    useEffect(() => {
        const socket = new WebSocket(`${domain}${url}`);
        const stomp = Stomp.over(socket);

        connect(stomp, headers);
        return () => {
            console.log("Websocket close!!!");
            stomp.disconnect();
        };
    }, []);

    const connect = (stomp, headers) => {
        try {
            stomp.connect(
                headers,
                subscribe(stomp),
                handleError(stomp),
            );
        } catch (e) {
            console.error(e);
        }
    };

    const subscribe = (stomp) => (frame) => {
        stomp.subscribe("/user/" + frame.headers["user-name"] + topic, (frame) => {
            console.log("WebSocket topic data", frame);
            const _data = JSON.parse(frame.body);
            setData(_data);
        },
        );
    };

    const reConnectOnError = () => {
        const headers = {"Authorization": `Bearer ${ls(ACCESS_TOKEN_KEY)}`};
        const socket = new WebSocket(`${domain}${url}`);
        const stomp = Stomp.over(socket);

        connect(stomp, headers);
    };

    function refreshToken() {
        dispatch(refreshTokenAction({
            onSuccess: () => {
                reConnectOnError();
            },
        }));
    }

    const handleError = (_stomp) => async (message) => {
        console.log("Message!!!", message);
        if (message.headers && message.headers.message) {
            if (message.headers.message.includes("ExpiredJwtException")) {
                console.log("ExpiredJwtException", message);
                await _stomp.disconnect();

                refreshingToken.current();

                return;
                //do 401
            } else if (message.headers.message.includes("AccessDeniedException")) {
                console.log("AccessDeniedException", message);
                await _stomp.disconnect();

                refreshingToken.current();

                return;
            }

            if (message.headers.message.includes("close")) {
                await _stomp.disconnect();

                reConnectOnError();

                return;
            }
        }

        await _stomp.disconnect();

        reConnectOnError();
    };

    return {
        data,
    };
}