import React, {CSSProperties, FC, ReactNode} from "react";

import NmIcon from "../NmIcon";
import {Loader, SemanticSIZES} from "semantic-ui-react";

import bem from "../../utils/bem";
import {getClassNames} from "../../utils/classNames";

import "./style.sass";

export type TNmButtonColor =
    "green"
    | "grey"
    | "light-green"
    | "red"
    | "white"
    | "horeca"
    | "open-naimix-primary"
    | "open-naimix-secondary"
    | "promo-poisk-primary"
    | "promo-poisk-secondary";

export type TNmButtonSize = "sm" | "md" | "lg" | "xl";

export interface INmButton {
    style?: CSSProperties,
    onlyIcon?: boolean,
    icon?: string | ReactNode,
    disabled?: boolean,
    size?: TNmButtonSize,
    color?: TNmButtonColor,
    reactMaterialIcon?: string,
    classNameText?: string,
    loading?: boolean,
    onClick?: (event?: any) => void,
    loaderInverted?: boolean,
    loaderSize?: SemanticSIZES,
    className?: string,
    children?: ReactNode | null,
    form?: string,
    type?: "submit" | "reset" | "button",
    isLink?: boolean,
    href?: string,
    openNaimix?: boolean,
    target?: string,
    count?: number,
    rel?: string,
    promoPoisk?: boolean,
    clearWidth?: boolean,
    isContentFullWidth?: boolean,
    classNameTextWithIconContainer?: string,
    noBorder?: boolean
    isIconPositionEnd?: boolean
}

const NmButton: FC<INmButton> = (props) => {
    const {
        className,
        children,
        style,
        openNaimix,
        onlyIcon = false,
        onClick = () => {},
        icon,
        disabled = false,
        size = "lg",
        color = "green",
        reactMaterialIcon,
        classNameText = "",
        loading,
        loaderSize = "tiny",
        loaderInverted = true,
        type,
        form,
        isLink = false,
        href,
        count,
        target,
        rel,
        promoPoisk,
        clearWidth,
        noBorder = false,
        isIconPositionEnd = false,
        isContentFullWidth,
        classNameTextWithIconContainer,
    } = props;

    const [block, element] = bem("nm-button", className);

    const _onClick = (event: any) => {
        if (disabled) {
            return;
        }

        onClick && onClick(event);
    };

    const getContent = () => {
        return (
            <>
                {
                    loading &&
                    <Loader
                        className={element("loader")}
                        active={loading}
                        inverted={loaderInverted}
                        size={loaderSize}
                    />
                }
                <div className={element("content", {hidden: loading, fullWidth: isContentFullWidth})}>
                    {
                        reactMaterialIcon &&
                        <span className="material-icons">
                            {reactMaterialIcon}
                        </span>
                    }
                    {
                        typeof icon === "string" &&
                        <div className={element("icon-wrapper")}>
                            <NmIcon
                                className={element("icon")}
                                name={icon}
                            />
                        </div>
                    }
                    <div
                        className={getClassNames([
                            element("text-with-icon-container", {iconPositionEnd: isIconPositionEnd}),
                            classNameTextWithIconContainer,
                        ])}
                    >
                        {
                            typeof icon === "object" &&
                            <div
                                className={element("icon-container", {
                                    onlyIcon,
                                    iconPositionEnd: isIconPositionEnd,
                                })}
                            >
                                {icon}
                            </div>
                        }
                        {
                            children &&
                            <div className={`${element("text", {openNaimix, promoPoisk})} ${classNameText}`}>
                                {children}
                            </div>
                        }
                    </div>
                    {
                        !!count &&
                        <div className={element("count")}>
                            {count}
                        </div>
                    }
                </div>
            </>
        );
    };

    if (isLink) {
        return (
            <a
                className={block({
                    size,
                    disabled,
                    icon: Boolean(icon),
                    color,
                    clearWidth,
                    onlyIcon,
                })}
                href={href}
                target={target}
                rel={rel}
            >
                {getContent()}
            </a>
        );
    }


    return (
        <button
            style={style}
            className={block({
                size,
                disabled,
                icon: Boolean(icon),
                color,
                clearWidth,
                openNaimix,
                promoPoisk,
                onlyIcon,
                noBorder,
            })}
            onClick={_onClick}
            disabled={disabled}
            type={type}
            form={form}
        >
            {getContent()}
        </button>
    );
};

export default NmButton;