import React, {FC} from "react";

import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../../images/file_download.svg";
import HelpTooltip from "../../../HelpTooltip";
import Text from "../../../Text";

import bem from "../../../../../utils/bem";
import {handleDropzoneValidations} from "../../utils/handleValidations";

import {TDropzoneOnRemoveFile} from "../../types";

import "./style.sass";

interface IDropzoneUploadedFile {
    file: any,
    multiple?: boolean,
    uploadedFileLabel?: string,
    isError: boolean,
    format?: string,
    maxSize?: number,
    minSize?: number,
    isDownload?: boolean,
    onDownloadFile: (file: any) => void,
    onRemoveFile: ({name, isError}: TDropzoneOnRemoveFile) => void,
    isShowDeleteButton?: boolean,
    isImagePreview?: boolean,
    isShowUploadedFileNameTitle?: boolean,
}

export const DropzoneUploadedFile: FC<IDropzoneUploadedFile> = (props) => {
    const {
        file,
        uploadedFileLabel,
        isShowUploadedFileNameTitle,
        isError,
        isShowDeleteButton = true,
        multiple,
        format,
        maxSize,
        minSize,
        isDownload,
        isImagePreview,
        onDownloadFile,
        onRemoveFile,
    } = props;
    const {
        errors,
    } = file;
    const [, element] = bem("dropzone-uploaded-file");

    return (
        <React.Fragment>
            {
                !multiple &&
                <Text
                    level="2"
                    className={element("single-file", {mt: isImagePreview})}
                >
                    {uploadedFileLabel}
                </Text>
            }
            <div className={element("file", {error: isError, mt: multiple || isImagePreview})}>
                <Text
                    level="2"
                    className={element("file-name")}
                    title={isShowUploadedFileNameTitle && file.name}
                >
                    {file.name}
                </Text>
                {
                    isError &&
                    <HelpTooltip
                        info
                        hover
                        className="ms-1"
                        type="error"
                        compound
                        body={handleDropzoneValidations({
                            errors,
                            format,
                            maxSize,
                            minSize,
                        })}
                        isTypeTooltipEqualTypeIcon={false}
                        classNameTooltip={element("tooltip")}
                        width={16}
                        position="bottom"
                        height={16}
                    />
                }
                {
                    isDownload &&
                    <FileDownloadIcon
                        className={element("file-action")}
                        onClick={() => onDownloadFile(file)}
                    />
                }
                {
                    (isShowDeleteButton || isError) &&
                    <CloseIcon
                        className={element("file-action")}
                        onClick={() => onRemoveFile({
                            name: file.name,
                            isError,
                            isRemote: file.isRemote,
                        })}
                    />
                }
            </div>
        </React.Fragment>
    );
};