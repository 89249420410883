export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PUT_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_ADD_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_PAGE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPLOAD_FILE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_DELETE_FILE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPDATE_STORE = "BFF_RECRUITMENT_VACANCY_CANDIDATES_COMMENTS_UPDATE_STORE";