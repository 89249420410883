import React from "react";
import {Route, Switch} from "react-router";

import BrigadeList from "../brigade-list";
import BrigadePaymentsContainer from "../brigade-payments/brigadePaymentsContainer";
import BrigadeTimesheet from "../brigade-timesheet";
import ForemanRequestsContainer from "../foreman-requests/foremanRequestsContainer";

import {
    LINK_CLIENT_BRIGADE_LIST,
    LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST,
    LINK_CLIENT_BRIGADE_PAYMENTS,
    LINK_CLIENT_BRIGADE_TIMESHEET,
} from "../../../constants/links";

export const BrigadeRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST}
                component={ForemanRequestsContainer}
            />
            <Route
                exact
                path={LINK_CLIENT_BRIGADE_TIMESHEET}
                component={BrigadeTimesheet}
            />
            <Route
                exact
                path={LINK_CLIENT_BRIGADE_LIST}
                component={BrigadeList}
            />
            <Route
                exact
                path={LINK_CLIENT_BRIGADE_PAYMENTS}
                component={BrigadePaymentsContainer}
            />
        </Switch>
    );
};