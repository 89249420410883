import React from "react";
import {useSelector} from "react-redux";

import DepositOfFund from "../../../deposit-of-funds";

import {civilProgressActionSelector} from "../../../../../ducks/civil";
import {depositProgressActionSelector} from "../../../../../ducks/deposit";

const ClientListDepositOfFundEdit = (props) => {
    const {
        close,
        dependentData,
        onEditDeposit,
    } = props;

    const progressDepositAction = useSelector(depositProgressActionSelector);
    const progressCivilAction= useSelector(civilProgressActionSelector);

    return <DepositOfFund
        close={close}
        dependentData={dependentData}
        onEditDeposit={onEditDeposit}
        isLoading={progressDepositAction || progressCivilAction}
        isEdit
    />;
};

export default ClientListDepositOfFundEdit;