import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {dictionaryToOptions} from "../utils/objectHelper";

import {getMigrationStatusDict, updateFieldContractorStore} from "../ducks/contractor";

export const useMigrationStatusDict = (params) => {
    const {
        citizenship,
        nameField = "contractorMigrationStatusDict",
    } = params;
    const dispatch = useDispatch();
    const migrationStatusDict = useSelector(({contractor}) => contractor[nameField]);

    const migrationStatusOptions = useMemo(() => {
        if (!migrationStatusDict) {
            return [];
        }

        return dictionaryToOptions(migrationStatusDict);
    }, [migrationStatusDict]);

    useEffect(() => {
        if (citizenship) {
            dispatch(getMigrationStatusDict({
                citizenship,
                nameField,
            }));
        }
    }, [citizenship]);

    useEffect(() => {
        return () => {
            dispatch(updateFieldContractorStore({
                [nameField]: {},
            }));
        };
    }, []);

    return {
        migrationStatusOptions,
    };
};