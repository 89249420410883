import React from "react";

import {ContractorFinanceIncomeCrowd} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
} from "../../../../../constants/links";

import {getPageCrowdPaymentContractorAwaiting} from "../../../../../ducks/bff/contractor/crowd-payments/services";

import {STATUS_TASKS_PAYMENT_CONTRACTOR_INCOME_AWAITING} from "../../../../../constants/crowd/task-payment";

const options = dictionaryToOptions(STATUS_TASKS_PAYMENT_CONTRACTOR_INCOME_AWAITING);

export const ContractorFinanceIncomeCrowdAwaiting = (props) => {
    return (
        <ContractorFinanceIncomeCrowd
            subTabsLinks={{
                orders: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
                tasks: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
            }}
            fetchList={getPageCrowdPaymentContractorAwaiting}
            isShowBankStatusFilter={false}
            isShowItemActions={false}
            isShowNaimixStatusFilter={true}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
