import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request, {getMultipartHeaders} from "../utils/postman";
import {toastError} from "../utils/toastHelper";

import {MEDICAL_BOOK_TYPE} from "../constants/medicalBook";

const controller = "/filestore/contractorFiles/medicalBook";
//*  TYPES  *//

const GET_MEDICAL_BOOK_THUMBNAIL_BASE64_REQUEST = "GET_MEDICAL_BOOK_THUMBNAIL_BASE64_REQUEST";
const GET_MEDICAL_BOOK_THUMBNAIL_BASE64_SUCCESS = "GET_MEDICAL_BOOK_THUMBNAIL_BASE64_SUCCESS";
const GET_MEDICAL_BOOK_THUMBNAIL_BASE64_ERROR = "GET_MEDICAL_BOOK_THUMBNAIL_BASE64_ERROR";

const SEND_COMPLAINT_MEDICAL_BOOK_REQUEST = "SEND_COMPLAINT_MEDICAL_BOOK_REQUEST";
const SEND_COMPLAINT_MEDICAL_BOOK_SUCCESS = "SEND_COMPLAINT_MEDICAL_BOOK_SUCCESS";
const SEND_COMPLAINT_MEDICAL_BOOK_ERROR = "SEND_COMPLAINT_MEDICAL_BOOK_ERROR";

const UPDATE_MEDICAL_BOOK_REQUEST = "UPDATE_MEDICAL_BOOK_REQUEST";
const UPDATE_MEDICAL_BOOK_SUCCESS = "UPDATE_MEDICAL_BOOK_SUCCESS";
const UPDATE_MEDICAL_BOOK_ERROR = "UPDATE_MEDICAL_BOOK_ERROR";

const DELETE_FILE_MEDICAL_BOOK_REQUEST = "DELETE_FILE_MEDICAL_BOOK_REQUEST";
const DELETE_FILE_MEDICAL_BOOK_SUCCESS = "DELETE_FILE_MEDICAL_BOOK_SUCCESS";
const DELETE_FILE_MEDICAL_BOOK_ERROR = "DELETE_FILE_MEDICAL_BOOK_ERROR";

const ADD_MEDICAL_BOOK_REQUEST = "ADD_MEDICAL_BOOK_REQUEST";
const ADD_MEDICAL_BOOK_SUCCESS = "ADD_MEDICAL_BOOK_SUCCESS";
const ADD_MEDICAL_BOOK_ERROR = "ADD_MEDICAL_BOOK_ERROR";

const CLEAR_MEDICAL_BOOK_BASE64_REQUEST = "CLEAR_MEDICAL_BOOK_BASE64_REQUEST";


//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    pageData: {},
    thumbnailBase64: {
        [MEDICAL_BOOK_TYPE.MAIN_PAGE.VALUE]: "",
        [MEDICAL_BOOK_TYPE.QR_CODE.VALUE]: "",
    },
    originalBase64: {
        [MEDICAL_BOOK_TYPE.MAIN_PAGE.VALUE]: "",
        [MEDICAL_BOOK_TYPE.QR_CODE.VALUE]: "",
    },
    originalFile: {
        [MEDICAL_BOOK_TYPE.MAIN_PAGE.VALUE]: "",
        [MEDICAL_BOOK_TYPE.QR_CODE.VALUE]: "",
    },
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_MEDICAL_BOOK_THUMBNAIL_BASE64_SUCCESS:
        return {
            ...state,
            thumbnailBase64: {
                ...state.thumbnailBase64,
                [payload.type]: payload.base64str,
            },
        };
    case CLEAR_MEDICAL_BOOK_BASE64_REQUEST:
        return {
            ...state,
            thumbnailBase64: {
                [MEDICAL_BOOK_TYPE.MAIN_PAGE.VALUE]: "",
                [MEDICAL_BOOK_TYPE.QR_CODE.VALUE]: "",
            },
        };
    case GET_MEDICAL_BOOK_THUMBNAIL_BASE64_ERROR:
        return {
            ...state,
            thumbnailBase64: {
                ...state.thumbnailBase64,
                [payload]: "",
            },
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//


export function getMedicalBookThumbnailBase64(payload) {
    return {
        type: GET_MEDICAL_BOOK_THUMBNAIL_BASE64_REQUEST,
        payload,
    };
}

export function deleteMedicalBookFile(payload) {
    return {
        type: DELETE_FILE_MEDICAL_BOOK_REQUEST,
        payload,
    };
}

export function updateMedicalBookNumber(payload) {
    return {
        type: UPDATE_MEDICAL_BOOK_REQUEST,
        payload,
    };
}

export function addMedicalBookFile(payload) {
    return {
        type: ADD_MEDICAL_BOOK_REQUEST,
        payload,
    };
}

export function sendComplaintOnContractorMedicalBook(payload) {
    return {
        type: SEND_COMPLAINT_MEDICAL_BOOK_REQUEST,
        payload,
    };
}

export function clearMedicalBookBase64() {
    return {
        type: CLEAR_MEDICAL_BOOK_BASE64_REQUEST,
    };
}


//*  SELECTORS  *//

export const medicalBookSelector = state => state.medicalBook;

export const getMedicalBookThumbnailBase64Selector = createSelector(medicalBookSelector, ({thumbnailBase64}) => thumbnailBase64);


//*  SAGA  *//

//GET /api/filestore/contractorFiles/medicalBook/thumbnail/getBase64
const getMedicalBookThumbnailBase64Saga = function* (action) {
    const {
        payload: params,
    } = action;

    const {type} = params;

    try {
        const result = yield request.get(`${controller}/thumbnail/getBase64`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_MEDICAL_BOOK_THUMBNAIL_BASE64_ERROR,
                payload: type,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_MEDICAL_BOOK_THUMBNAIL_BASE64_SUCCESS,
            payload: {
                ...result,
                type,
            },
        });
    } catch (error) {
        yield put({
            type: GET_MEDICAL_BOOK_THUMBNAIL_BASE64_ERROR,
            payload: type,
        });
    }
};

const addMedicalBookSaga = function* (action) {
    try {
        const {payload} = action;

        const {
            getResult,
            formData,
        } = payload;

        const result = yield request.post(`${controller}/add`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_MEDICAL_BOOK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_MEDICAL_BOOK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_MEDICAL_BOOK_ERROR,
            payload: error,
        });
    }
};

///api/contractors/updateMedicalBookNumber

const updateMedicalBookNumberSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess,
                ...data
            },
        } = action;

        const result = yield request.post("/contractors/updateMedicalBookNumber", data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_MEDICAL_BOOK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: UPDATE_MEDICAL_BOOK_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        yield put({
            type: UPDATE_MEDICAL_BOOK_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/sendComplaintOnContractorMedicalBook
const sendComplaintOnContractorMedicalBookSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess,
                ...params
            },
        } = action;

        const result = yield request.get("/clients/users/sendComplaintOnContractorMedicalBook", {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SEND_COMPLAINT_MEDICAL_BOOK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SEND_COMPLAINT_MEDICAL_BOOK_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            yield onSuccess();
        }
    } catch (error) {
        yield put({
            type: SEND_COMPLAINT_MEDICAL_BOOK_ERROR,
            payload: error,
        });
    }
};

//POST /api/filestore/contractorFiles/medicalBook/delete
const deleteMedicalBookFileSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess,
                contractorId,
                type,
            },
        } = action;

        const result = yield request.post(`${controller}/delete?contractorId=${contractorId}&type=${type}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_FILE_MEDICAL_BOOK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_FILE_MEDICAL_BOOK_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        yield put({
            type: DELETE_FILE_MEDICAL_BOOK_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(ADD_MEDICAL_BOOK_REQUEST, addMedicalBookSaga),
        takeEvery(GET_MEDICAL_BOOK_THUMBNAIL_BASE64_REQUEST, getMedicalBookThumbnailBase64Saga),
        takeEvery(UPDATE_MEDICAL_BOOK_REQUEST, updateMedicalBookNumberSaga),
        takeEvery(DELETE_FILE_MEDICAL_BOOK_REQUEST, deleteMedicalBookFileSaga),
        takeEvery(SEND_COMPLAINT_MEDICAL_BOOK_REQUEST, sendComplaintOnContractorMedicalBookSaga),
    ]);
}
