import {
    ADD_CLIENT_MODULE_TARIFFS_REQUEST,
    CLIENT_MODULE_TARIFFS_UPDATE_STORE,
    DELETE_CLIENT_MODULE_TARIFFS_REQUEST,
    UPDATE_CLIENT_MODULE_TARIFFS_REQUEST,
} from "./actions";

export const addClientModuleTariff = (payload) => {
    return {
        type: ADD_CLIENT_MODULE_TARIFFS_REQUEST,
        payload,
    };
};

export const updateClientModuleTariff = (payload) => {
    return {
        type: UPDATE_CLIENT_MODULE_TARIFFS_REQUEST,
        payload,
    };
};

export const deleteClientModuleTariff = (payload) => {
    return {
        type: DELETE_CLIENT_MODULE_TARIFFS_REQUEST,
        payload,
    };
};

export const updateClientModuleTariffsStore = (payload) => {
    return {
        type: CLIENT_MODULE_TARIFFS_UPDATE_STORE,
        payload,
    };
};