import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../../../components/CheckboxList";
import NmPage from "../../../../../../../components/NmPage";
import NmTitle from "../../../../../../../components/NmTitle";

import {useFilter} from "../../../../../../../hooks/useFilter";
import {usePagination} from "../../../../../../../hooks/usePagination";

import {formatLocalDate, getEndDate, getStartDate} from "../../../../../../../utils/dateFormat";
import {formatAmount} from "../../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../../utils/stringHelper";

import {
    getSettingsDirectoriesBanksBalanceLogPage,
    settingsDirectoriesBanksBalancesLogListSelector,
    settingsDirectoriesBanksBalancesLogProgressSelector,
    settingsDirectoriesBanksBalancesLogTotalCountSelector,
    settingsDirectoriesBanksBalancesLogTotalPagesSelector,
} from "../../../../../../../ducks/settingsDirectoriesBanks";

export const SettingsDirectoriesBanksBalancesLogModal = (props) => {
    const {
        data,
        onClose = () => {},
    } = props;

    const list = useSelector(settingsDirectoriesBanksBalancesLogListSelector);
    const totalPages = useSelector(settingsDirectoriesBanksBalancesLogTotalPagesSelector);
    const totalCount = useSelector(settingsDirectoriesBanksBalancesLogTotalCountSelector);
    const progress = useSelector(settingsDirectoriesBanksBalancesLogProgressSelector);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        initFilter: {
            dateFrom: null,
            dateTo: null,
            editorFio: "",
        },
        setPagination,
        pageSize,
    });

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        const {
            dateFrom,
            dateTo,
            editorFio,
        } = filterData;

        dispatch(getSettingsDirectoriesBanksBalanceLogPage({
            pageSize,
            pageNum,
            bankBalanceId: data.bankBalanceId,
            dateFrom: getStartDate(dateFrom),
            dateTo: getEndDate(dateTo),
            editorFio: handleFormString(editorFio),
        }));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                info,
                createdAtDatetime,
                editorFio,
                newBalance,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        secondaryHeader={`Дата изменения: ${formatLocalDate(createdAtDatetime, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader="Изменение по балансу"
                        labels={[
                            {
                                label: "Баланс, ₽",
                                text: newBalance ? formatAmount(newBalance) : "-",
                            },
                            {
                                label: "ФИО",
                                text: editorFio || "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог изменений баланса банка 
                    {" "}
                    {data.bankName}
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={
                    <NmForm
                        horizontal
                        className="flex align-items-end"
                    >
                        <div className="col-16 col-md-7 col-xl-5">
                            <NmDateRangePickerV2
                                size="lg"
                                startFieldName="dateFrom"
                                endFieldName="dateTo"
                                value={{
                                    dateFrom: filter.dateFrom,
                                    dateTo: filter.dateTo,
                                }}
                                isClearable
                                label="Дата операции"
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-4">
                            <NmInputV2
                                size="lg"
                                label="ФИО пользователя"
                                placeholder="Введите ФИО"
                                name="editorFio"
                                value={filter.editorFio}
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-4">
                            <FilterButtonsV2
                                onClear={onClear}
                                onSearch={() => onSearch(filter)}
                            />
                        </div>
                    </NmForm>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        />
                }
            </NmPage>
        </NmModal>
    );
};