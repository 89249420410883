import React from "react";
import {toast} from "react-toastify";

export function downloadBlob(blob, headers, customFileName) {
    const fileName = customFileName || getFileNameByHeaders(headers);
    downloadLocalFile(blob, fileName);
}

export function downloadLocalFile(file, fileName = "Не передано название файла") {
    const url = window.URL.createObjectURL(file);
    downloadByUrl(url, fileName);
}

export function downloadByUrl(url, fileName = "Не передано название файла") {
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    a.click();
    a.remove();
}

export function openLinkByUrl(url) {
    const tempLink = document.createElement("a");

    tempLink.setAttribute("href", url);

    tempLink.setAttribute("target", "_blank");

    document.body.appendChild(tempLink);

    tempLink.click();
    tempLink.remove();
}

export async function getBlobInParts(body, contentLength, toastId, type) {
    const reader = body.getReader();
    const options = {};

    let receivedLength = 0; // количество байт, полученных на данный момент
    const chunks = []; // массив полученных двоичных фрагментов (составляющих тело ответа)
    while (true) {
        const {done, value} = await reader.read();

        if (done) {
            break;
        }

        chunks.push(value);
        receivedLength += value.length;

        toastId && toast.update(toastId, {
            type: toast.TYPE.SUCCESS,
            render:
                <span>
                    {`Идет скачивание файла.. ${(receivedLength / 1024 / 1024).toFixed(1)}Мб из ${(contentLength / 1024 / 1024).toFixed(1)}Мб`}
                </span>,
        });
    }

    const chunksAll = new Uint8Array(receivedLength);

    let position = 0;
    for (const chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
    }

    if (toastId) {
        toast.dismiss(toastId);
    }

    if (type) {
        options.type = type;
    }

    return new Blob(chunks, options);
}

export function dataURLtoFile(dataurl, filename) {

    let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

export function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {lastModified: new Date().getMilliseconds()});
}

export function getFileNameByHeaders(headers){
    let filename = "";
    const disposition = headers.get("Content-Disposition");
    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    }
    return filename || headers.get("Content-Disposition").split("filename=")[1];
}