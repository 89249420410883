import React, {useRef} from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../../components/CheckboxList";
import NmLoader from "../../../../../components/NmLoader";
import {
    bffRecruitmentVacancyTabFilterDataSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    bffRecruitmentVacancyCandidatesListSelector,
    bffRecruitmentVacancyCandidatesPageDataSelector,
    bffRecruitmentVacancyCandidatesProgressSelector,
    bffRecruitmentVacancyCandidatesTotalPagesSelector,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {fileStoreRecruitmentAvatarBase64ImagesSelector} from "../../../../../ducks/fileStoreRecruitment";
import {history} from "../../../../../store/configureStore";
import {RecruitmentCandidatesCard} from "../../../candidates/card";
import {RecruitmentVacancyResponseListCard} from "../../components/list-card";

import {useInfiniteScrollPagination} from "../../../../../hooks/useInfiniteScrollPagination";
import useRecruitmentVacancyCandidateListFetch from "./hooks/useFetch";

import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO,
} from "../../../../../constants/links";

const RecruitmentVacancyCandidateList = (props) => {
    const {
        match: {
            params: {
                clientId,
                vacancyId,
                id: candidateId,
                activeFunnelStatusFilter,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentVacancyCandidatesListSelector);
    const {isLoadMore} = useSelector(bffRecruitmentVacancyCandidatesPageDataSelector);
    const totalPages = useSelector(bffRecruitmentVacancyCandidatesTotalPagesSelector);
    const progress = useSelector(bffRecruitmentVacancyCandidatesProgressSelector);
    const userAvatarDict = useSelector(fileStoreRecruitmentAvatarBase64ImagesSelector);
    const {isSearch} = useSelector(bffRecruitmentVacancyTabFilterDataSelector);

    const isShowRightContent = Boolean(candidateId);
    const $contentRef = useRef(null);

    const {
        pageData,
        infiniteScrollProps,
        setPageData,
    } = useInfiniteScrollPagination({
        totalPages,
    });

    const {
        fetchList,
    } = useRecruitmentVacancyCandidateListFetch({
        clientId,
        vacancyId,
        pageData,
        setPageData,
        activeFunnelStatusFilter,
    });

    const getRows = () => {
        return list.map(item => {
            const {
                name,
                age,
                lastOrganisation,
                lastPosition,
            } = item;

            const isActive = item.candidateId === candidateId;

            return {
                key: item.candidateId,
                isActive,
                contentRow: (
                    <RecruitmentVacancyResponseListCard
                        isActive={isActive}
                        candidateId={item.candidateId}
                        base64={userAvatarDict[item.candidateId]}
                        onClickCard={() => {
                            const link =
                                LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO
                                    .replace(":clientId", clientId)
                                    .replace(":vacancyId", vacancyId)
                                    .replace(":id", item.candidateId)
                                    .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter);

                            history.push(link);
                        }}
                        name={name}
                        age={age}
                        lastOrganisation={lastOrganisation}
                        lastPosition={lastPosition}
                    />
                ),
            };
        });
    };

    const getRightContent = () => {
        return (
            <RecruitmentCandidatesCard
                fetchList={fetchList}
                activeFunnelStatusFilter={activeFunnelStatusFilter}
                isVacancyCandidate={true}
                vacancyId={vacancyId}
                candidateId={candidateId}
                clientId={clientId}
            />
        );
    };

    const getContent = () => {
        if (!list.length && progress) {
            return (
                <NmLoader
                    inverted={true}
                    active={true}
                />
            );
        }

        if (!list.length) {
            return (
                <NmEmptyPageV2
                    isSearch={isSearch}
                />
            );
        }

        return (
            <>
                {
                    !isLoadMore && progress &&
                    <NmLoader
                        inverted={true}
                        active={true}
                    />
                }
                <CheckboxList
                    rows={getRows()}
                    contentRef={$contentRef}
                    isShowRightContent={isShowRightContent}
                    rightContentLink={
                        LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO
                            .replace(":clientId", clientId)
                            .replace(":vacancyId", vacancyId)
                            .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter)
                    }
                    rightContent={getRightContent()}
                    rightContentId={candidateId}
                    infiniteScrollProps={infiniteScrollProps}
                    infiniteProgress={progress && isLoadMore}
                />
            </>
        );
    };

    return (
        <div className="relative flex-grow-1 mb-6">
            {getContent()}
        </div>
    );
};

export default RecruitmentVacancyCandidateList;
