import {
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    vacancies: [],
    vacanciesTotalCount: 0,
    vacanciesPageData: {},
    observersProgress: false,
    progressAction: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST:
        return {
            ...state,
            vacanciesPageData: payload,
            observersProgress: true,
        };
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_SUCCESS: {
        return {
            ...state,
            vacancies: payload.results,
            vacanciesTotalCount: payload.totalCount,
            observersProgress: false,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR: {
        return {
            ...state,
            observersProgress: false,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_SUCCESS: {
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST:
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_SUCCESS:
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR:
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_SUCCESS:
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};