import {useState} from "react";
import {useDispatch} from "react-redux";

import {getRichContractorById} from "../../../../ducks/contractor";
import {
    changeStatusContractorScoresReply,
    confirmContractorScore,
    declineContractorScore,
    deleteContractorScore,
} from "../../../../ducks/contractorScores";

const initialStateConfirmModal = {
    isOpen: false,
};

export function useContractorReviewsAction({fetchList, contractorId}) {
    const [isOpenAddForm, setIsOpenAddForm] = useState(false);
    const [confirmAction, setConfirmAction] = useState(initialStateConfirmModal);

    const dispatch = useDispatch();

    const fetchContractorInfo = () => {
        dispatch(getRichContractorById(contractorId));
    };

    const onConfirmContractorScore = (id) => {
        dispatch(confirmContractorScore({
            id,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const onDeleteContractorScore = (id) => {
        dispatch(deleteContractorScore({
            id,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const onDeclineContractorScore = (id) => {
        dispatch(declineContractorScore({
            id,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const changeStatusScoreReply = (reviewId, status) => {
        return dispatch(changeStatusContractorScoresReply({
            reviewId,
            status,
            onSuccess: () => {
                fetchContractorInfo();
                fetchList();
                closeConfirmModal();
            },
        }));
    };

    const closeConfirmModal = () => {
        setConfirmAction(initialStateConfirmModal);
    };

    return {
        confirmAction,
        setConfirmAction,
        closeConfirmModal,
        isOpenAddForm,
        setIsOpenAddForm,
        onConfirmContractorScore,
        onDeleteContractorScore,
        onDeclineContractorScore,
        changeStatusScoreReply,
    };
}