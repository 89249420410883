import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getRecruitersWithAccessVacancy,
    updateRecruitmentVacancyStore,
} from "../../../../ducks/bff/recruitment/vacancy/actionCreators";

export const useRecruitmentVacanciesCardRecruitersWithAccessFetch = (params) => {
    const {
        vacancyId,
        clientId,
        pageSize,
        pageNum,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchRecruitersWithAccess();

        return () => {
            dispatch(updateRecruitmentVacancyStore({
                recruitersWithAccess: {
                    list: [],
                    totalCount: 0,
                },
            }));
        };
    }, []);

    const fetchRecruitersWithAccess = () => {
        dispatch(getRecruitersWithAccessVacancy({
            vacancyId,
            clientId,
            pageSize,
            pageNum,
        }));
    };

    return {
        fetchRecruitersWithAccess,
    };
};