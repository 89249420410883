import React from "react";

import CustomPagination from "../../CustomPagination";
import PageSizePagination from "../../PageSizePagination";

import bem from "../../../utils/bem";

import {PAGE_SIZES_DEFAULT} from "../../../constants/page";

import "./style.sass";

interface INmPagination {
    pageSizes?: number[],
    totalCount?: number,
    pageNum?: number,
    totalPages?: number,
    pageSize?: number,
    onChangePageSize?: (size: number) => void,
    onChangePagination?: (e: any, {activePage}: {activePage: number}) => void,
    isHasPageSize?: boolean,
    className?: string
}

const NmPagination = (props: INmPagination) => {
    const {
        pageSizes = PAGE_SIZES_DEFAULT,
        totalCount = 0,
        pageNum,
        totalPages,
        pageSize,
        onChangePageSize,
        onChangePagination,
        className,
    } = props;
    const [block, element] = bem("nm-pagination", className);
    const isHasPageSize = getHasPageSize();
    const isHasPagination = Boolean(pageNum && totalPages) && totalPages && totalPages > 1;

    function getHasPageSize() {
        const {
            isHasPageSize = true,
        } = props;

        return (totalCount > pageSizes[0]) && isHasPageSize;
    }

    return (isHasPagination || isHasPageSize) ?
        <div className={block()}>
            {
                isHasPageSize &&
                <PageSizePagination
                    className={element("page-size")}
                    activeSize={pageSize}
                    pageSizes={pageSizes}
                    handleOnChangePageSize={onChangePageSize}
                />
            }
            {
                isHasPagination &&
                <CustomPagination
                    activePage={pageNum}
                    onPageChange={onChangePagination}
                    totalPages={totalPages}
                />
            }
        </div> :
        null;
};

export default NmPagination;