import * as yup from "yup";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        [CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: value => ([citizenshipsDict.UZ.value, citizenshipsDict.TJ.value, citizenshipsDict.UA.value].includes(value)),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            })
            .when(CONTRACTOR_FIELD.VHI_POLICY_PRESENT, {
                is: value => (Boolean(value)),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            })
        ,
        [CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: value => ([citizenshipsDict.UZ.value, citizenshipsDict.TJ.value, citizenshipsDict.UA.value].includes(value)),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            })
            .when(CONTRACTOR_FIELD.VHI_POLICY_PRESENT, {
                is: value => (Boolean(value)),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};

export default validationSchema;