import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getContractorsNotificationsPage} from "./actionCreators";
import {
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_SUCCESS,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST,
    CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_SUCCESS,
} from "./actions";
import {notificationSendingToContractorPageDataSelector} from "./selectors";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";


const controller = "/adm/clients/notification-sending/to-contractor";

// PUT /bff/adm/clients/notification-sending/to-contractor/{clientId}/{id}/retry
// Повторить рассылку
const retryContractorNotification = function* ({payload}) {
    const {
        clientId,
        id,
        handleResponse,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/retry`, {
            clientId,
            id,
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = notificationSendingToContractorPageDataSelector(state);

        yield put(getContractorsNotificationsPage(pageData));
    } catch (error) {
        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/notification-sending/to-contractor/{clientId}/{id}/cancel
// Отменить рассылку
const cancelContractorNotification = function* ({payload}) {
    const {
        handleResponse,
        clientId,
        id,
        removePosted,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/cancel`, {
            clientId,
            id,
            removePosted,
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = notificationSendingToContractorPageDataSelector(state);

        yield put(getContractorsNotificationsPage(pageData));
    } catch (error) {
        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/notification-sending/to-contractor/page
// Получить страницу
const getNotificationsPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/notification-sending/to-contractor/contractors/count
// Количество исполнителей по параметрам
const getNotificationContractorCount = function* ({payload}) {
    const {
        handleResponse,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/contractors/count`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/notification-sending/to-contractor/add
// Создать рассылку
const addContractorNotification = function* ({payload}) {
    const {
        handleResponse,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = notificationSendingToContractorPageDataSelector(state);

        yield put(getContractorsNotificationsPage(pageData));
    } catch (error) {
        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/notification-sending/to-contractor/contractors/id-by-phone/get
// Получение идентификатора исполнителя по номеру телефона
const getNotificationSendingContractorId = function* ({payload}) {
    const {
        handleResponse,
        phone,
        clientId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/contractors/id-by-phone/get`, {
            params: {
                phone,
                clientId,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_ERROR,
            payload: error,
        });
    }
};;

export function* saga() {
    yield all([
        takeEvery(CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_RETRY_REQUEST, retryContractorNotification),
        takeEvery(CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CANCEL_REQUEST, cancelContractorNotification),
        takeEvery(CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_PAGE_REQUEST, getNotificationsPage),
        takeEvery(CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_COUNT_REQUEST, getNotificationContractorCount),
        takeEvery(CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_ADD_REQUEST, addContractorNotification),
        takeEvery(CLIENTS_NOTIFICATION_SENDING_TO_CONTRACTOR_CONTRACTORS_ID_BY_PHONE_REQUEST, getNotificationSendingContractorId),
    ]);
}