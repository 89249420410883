import React from "react";

import TemplatesContracts from "../contracts";

import {TEMPLATES_TYPE_DOCUMENTS} from "../../constants";

const TemplatesApplicationsOrders = (props) => {
    return (
        <TemplatesContracts
            headerText="Заявки по заказам"
            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
            {...props}
        />
    );
};

export default TemplatesApplicationsOrders;