import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {handleCardRate} from "../../../../utils/mathHelper";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";

export const getRegistryContractorCommission = (registry, client) => {
    const {
        contractorCommission,
        orderContractPaymentType,
        paymentDatetime,
    } = registry;
    const {
        contractorSmzRegistryPaymentsCommission,
        contractorCivilRegistryPaymentsCommission,
        contractorIndividualRegistryPaymentsCommission,
    } = client;

    if (!isNullOrWhitespace(contractorCommission)) {
        return `${handleCardRate(contractorCommission)}% (передача в оплату ${formatDate(convertUtcToLocal(paymentDatetime), "dd.MM.yyyy HH:mm")})`;
    }

    if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ) {
        return `${handleCardRate(contractorSmzRegistryPaymentsCommission)}%`;
    }

    if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
        return `${handleCardRate(contractorIndividualRegistryPaymentsCommission)}%`;
    }

    return `${handleCardRate(contractorCivilRegistryPaymentsCommission)}%`;
};