import {
    BFF_PATENT_PAYMENT_DECLINE_REQUEST,
    BFF_PATENT_PAYMENT_REFRESH_REQUEST,
    BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST,
    UPDATE_PATENT_STORE,
} from "./actions";

export const getPagePatentPayments = (payload) => {
    return {
        type: BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const refreshPatentPayment = (payload) => {
    return {
        type: BFF_PATENT_PAYMENT_REFRESH_REQUEST,
        payload,
    };
};

export const declinePatentPayment = (payload) => {
    return {
        type: BFF_PATENT_PAYMENT_DECLINE_REQUEST,
        payload,
    };
};

export const updateStorePatent = (payload) => {
    return {
        type: UPDATE_PATENT_STORE,
        payload,
    };
};