import {useEffect} from "react";


export const useLeafletHideLogo = (isHideLogo = true) => {
    useEffect(() => {
        if (!isHideLogo) {
            return;
        }

        const element = document.getElementsByClassName( "leaflet-control-attribution" )[0] as HTMLElement;

        element.style.display = "none";
    }, [isHideLogo]);

    return null;
};