export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR";

export const BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_UPDATE_STORE = "BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_UPDATE_STORE";