import React from "react";
import {isEmpty} from "lodash";

import {STATUS_TASK_PAYMENT} from "../../../../constants/crowd/task-payment";

export const getStatusTooltipText = ({status, rejectReason, validationResult}) => {
    if (
        [
            STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
            STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
            STATUS_TASK_PAYMENT.CANCELLED.VALUE,
        ].includes(status) && rejectReason
    ) {
        return rejectReason;
    }

    if (
        [
            STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
            STATUS_TASK_PAYMENT.APPROVE_ERROR.VALUE,
            STATUS_TASK_PAYMENT.FNS_DECLARATION_ERROR.VALUE,
            STATUS_TASK_PAYMENT.CANCELLED.VALUE,
        ].includes(status) && !isEmpty(validationResult)
    ) {
        return (
            <ul>
                {validationResult.validationResults?.map(item => <li>
                    {item.errorMessage}
                </li>)}
            </ul>
        );
    }

    return null;
};