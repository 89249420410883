import React from "react";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";

export const OrderPayPopupWarnings = (props) => {
    const {
        onCancel,
        onConfirm,
        warnings,
    } = props;

    const getWarningsConfirmContent = () => {
        if (!warnings) {
            return null;
        }

        if (warnings.length > 1) {
            return (
                <>
                    <div className="mb-2">
При формировании акта обнаружены следующие предупреждения
                    </div>
                    {
                        <ul>
                            {warnings.map(value => (
                                <li>
                                    {value.errorMessage}
                                </li>
                            ))}
                        </ul>
                    }
                    <div className="mt-2">
Рекомендуется избежать данной ситуации. Все равно продолжить?
                    </div>
                </>
            );
        }

        return `${warnings[0].errorMessage} Рекомендуется избежать данной ситуации. Все равно продолжить?`;
    };

    return (
        <NmConfirmV2
            title="Формирование акта"
            titleAlign="left"
            onCancel={onCancel}
            onConfirm={onConfirm}
            contentTextLevel="3"
            contentAlign="left"
            content={getWarningsConfirmContent()}
            buttonsAlign="right"
            confirmButton="Сформировать акт исполнителю"
            cancelButton="Отменить"
        />
    );
};