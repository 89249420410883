import React, {useContext, useMemo} from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../../../components/CheckboxList";
import SelectionCountWithAction from "../../../../../../components/SelectionCountWithAction";
import MapOrderListCard from "../../../order-list-card";
import {MapInviteModalContext} from "../context-provider";

import {
    bffMapInviteModalOrdersSelector,
    bffMapInviteModalProgressListSelector,
} from "../../../../../../ducks/bff/map/selectors";

const MapInviteModalOrderList = () => {
    const list = useSelector(bffMapInviteModalOrdersSelector);
    const progress = useSelector(bffMapInviteModalProgressListSelector);

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useContext(MapInviteModalContext);

    const rows = useMemo(() => {
        return list.map((item) => {
            const {isSelected = false} = selectedList.find(_item => (_item.orderId === item.orderId)) || {};

            return {
                ...item,
                key: item.orderId,
                showCheckBox: true,
                disabledCheckBox: !isSelected && countSelected > 24,
                isSelected,
                contentRow: <MapOrderListCard
                    order={item}
                    isModalCard={true}
                    isShowInviteButton={false}
                />,
            };
        });
    }, [
        list,
        selectedList,
    ]);

    return (
        list.length ?
            <CheckboxList
                isShowCheckboxLabel={true}
                header={
                    <SelectionCountWithAction
                        adaptiveLogic={true}
                        count={countSelected}
                        maxSelected={25}
                        maxSelectedText="Вы можете пригласить исполнителя на не более 25 заказов или заданий"
                    />
                }
                rows={rows}
                onSelectedRows={handleSelectedRows}
                maxSelected={25}
            /> :
            <NmEmptyPageV2 fetchProgress={progress} />
    );
};

export default MapInviteModalOrderList;