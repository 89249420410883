import {
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST,
    BFF_CROWD_TASKS_ADD_DRAFT_REQUEST,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_REQUEST,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_DELETE_REQUEST,
    BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_EXTEND_REQUEST,
    BFF_CROWD_TASKS_GET_BY_ID_REQUEST,
    BFF_CROWD_TASKS_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_UPDATE_REQUEST,
    UPDATE_CROWD_TASKS_FILED_STORE,
} from "./actions";

export const getPageCrowdTasks = (payload) => {
    return {
        type: BFF_CROWD_TASKS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getPageAdminCrowdTasks = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST,
        payload,
    };
};

export const addContractorSearchCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST,
        payload,
    };
};

export const addDraftCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ADD_DRAFT_REQUEST,
        payload,
    };
};

export const updateCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_UPDATE_REQUEST,
        payload,
    };
};

export const updateAndPublishCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST,
        payload,
    };
};

export const publishCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_PUBLISH_REQUEST,
        payload,
    };
};

export const publishMultipleCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST,
        payload,
    };
};

export const archiveCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST,
        payload,
    };
};

export const archiveMultipleCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST,
        payload,
    };
};

export const unarchiveCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST,
        payload,
    };
};

export const closeCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_CLOSE_REQUEST,
        payload,
    };
};

export const closeMultipleCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST,
        payload,
    };
};

export const deleteCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_DELETE_REQUEST,
        payload,
    };
};

export const deleteMultipleCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST,
        payload,
    };
};

export const getByIdCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_GET_BY_ID_REQUEST,
        payload,
    };
};

export const getTotalAmountCrowdTasks = (payload) => {
    return {
        type: BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST,
        payload,
    };
};

export const getDepositStateCrowdTasks = (payload) => {
    return {
        type: BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST,
        payload,
    };
};

export const extendCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_EXTEND_REQUEST,
        payload,
    };
};

export const extendMultipleCrowdTask = (payload) => {
    return {
        type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST,
        payload,
    };
};

export const updateStoreCrowdTasks = (payload) => {
    return {
        type: UPDATE_CROWD_TASKS_FILED_STORE,
        payload,
    };
};