import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import ApplyButtons from "../ApplyButtons";
import NmButton from "../NmButton";
import NmTitle from "../NmTitle";
import FullRegistrationDisclaimer from "./components/disclaimer";
import FullRegistrationForm from "./components/form";
import FullRegistrationValidationError from "./components/validation-error";

import useWarnings from "../../containers/contractor/contractor-verification-data/components/form/hooks/useWarnings";
import {useFullRegistrationFetchContractor} from "./hooks/useFetchContrtactor";
import {useFullRegistrationForm} from "./hooks/useForm";
import {useFullRegistrationNavigation} from "./hooks/useNavigation";
import {useFullRegistrationStepsProgress} from "./hooks/useStepsProgress";

import {getFullRegistrationInitialValues} from "./utils/getInitialValues";

import {CONTRACTOR_STATUS} from "../../constants/contractor";
import {
    FULL_REGISTRATION_COMPONENT_TYPE,
    FULL_REGISTRATION_STEP,
} from "./constants";

import {
    checkInnProgressSelector,
    contractorCardSelector,
} from "../../ducks/contractor";

const FullRegistration = (props) => {
    const {
        contractorId,
        onClose,
        disclaimer,
        componentMap,
        classNameModalBackIcon,
        step,
    } = props;

    const contractor = useSelector(contractorCardSelector);
    const progressCheckInn = useSelector(checkInnProgressSelector);

    const initialValues = useMemo(() => {
        return getFullRegistrationInitialValues(contractor);
    }, [contractor]);

    const {
    } = useFullRegistrationFetchContractor({contractorId});

    const {
        currentStep,
        goNext,
        goBack,
        steps,
        setSteps,
    } = useFullRegistrationNavigation({
        step,
    });

    const {
        countOfStepsWithoutGeneralSteps,
        currentStepNumber,
        isVisibleStepWithoutProgressBar,
    } = useFullRegistrationStepsProgress({
        steps,
        currentStep,
    });

    const {
        saveWithoutCreatingRequest,
        handleSubmit,
        values,
        errors,
        onChange,
        progress,
        isVisibleValidationError,
        scans,
        onSubmitWithoutInn,
        hideSubmitWithoutInn,
        filesErrors,
        setFilesErrors,
        touched,
    } = useFullRegistrationForm({
        goNext,
        setSteps,
        currentStep,
        initialValues,
        contractor,
        onClose,
        steps,
    });

    const {
        fieldWarnings,
    } = useWarnings({
        values,
        isRegistrationForm: true,
    });

    const getButtons = () => {
        if (currentStep.isLastStep) {
            return (
                <>
                    {
                        contractor.registrationAllowedWithoutInn &&
                        contractor.registrationStatus === CONTRACTOR_STATUS.BASIC_REGISTRATION &&
                        !hideSubmitWithoutInn &&
                        <NmButton
                            size="xl"
                            color="grey"
                            className="fluid mb-3"
                            disabled={progress}
                            onClick={onSubmitWithoutInn}
                        >
                            Продолжить без ИНН
                        </NmButton>
                    }
                    <NmButton
                        size="xl"
                        color="green"
                        className="fluid mb-3"
                        disabled={progress}
                        onClick={handleSubmit}
                    >
                        Сохранить и отправить заявку на проверку
                    </NmButton>
                    <NmButton
                        size="xl"
                        color="grey"
                        className="fluid"
                        disabled={progress}
                        onClick={saveWithoutCreatingRequest}
                    >
                        Сохранить данные без создания заявки
                    </NmButton>
                </>
            );
        }

        return (
            <ApplyButtons
                mobile="column"
                isHiddenCancelOnMobile
                submitBtnContent="Продолжить"
                disabled={!isEmpty(filesErrors)}
                onClose={onClose}
                submit={handleSubmit}
            />
        );
    };

    const getInfos = () => {
        if (!isVisibleStepWithoutProgressBar) {
            return null;
        }

        const Component = componentMap[FULL_REGISTRATION_COMPONENT_TYPE.WARNING_TEXT];

        return (
            <>
                <Component
                    warning={true}
                    content="Документ должен полностью попадать в кадр. Все данные документа должны быть четко видны на фотографии. В противном случае фото будут отклонены администратором."
                />
                {
                    [
                        FULL_REGISTRATION_STEP.PERSONAL_DATA,
                    ].includes(currentStep.value) &&
                    <FullRegistrationDisclaimer
                        className={disclaimer.className}
                        classNameRequiredStar={disclaimer.classNameRequiredStar}
                    />
                }
            </>
        );
    };

    return (
        <NmModal
            size="md"
            isShowBackButton={currentStep.position > 0}
            goBack={goBack}
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {currentStep.title}
                </NmTitle>
            }
            footer={getButtons()}
            isShowStepsNumberProgress={isVisibleStepWithoutProgressBar}
            currentStepNumber={currentStepNumber}
            totalCountSteps={countOfStepsWithoutGeneralSteps}
            classNameBackIcon={classNameModalBackIcon}
            loading={progressCheckInn}
        >
            <NmForm addMargin>
                {getInfos()}
                <FullRegistrationForm
                    componentMap={componentMap}
                    step={currentStep}
                    contractor={contractor}
                    onChange={onChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    warnings={fieldWarnings}
                    scans={scans}
                    setFilesErrors={setFilesErrors}
                />
                {
                    currentStep.isLastStep && isVisibleValidationError &&
                    <FullRegistrationValidationError />
                }
            </NmForm>
        </NmModal>
    );
};

export default FullRegistration;