import React from "react";
import {DateRangePicker} from "react-date-range";
import {useMedia} from "react-media";

const NM_DATE_PICKER_RANGE_MONTH_COUNT_BY_DEVICE = {
    MOBILE: 1,
    DESKTOP: 2,
};

export const NmDatePickerRangeView = (props) => {
    const isMobile = useMedia({query: {maxWidth: 1199}});
    const months = isMobile ?
        NM_DATE_PICKER_RANGE_MONTH_COUNT_BY_DEVICE.MOBILE :
        NM_DATE_PICKER_RANGE_MONTH_COUNT_BY_DEVICE.DESKTOP;

    return (
        <DateRangePicker
            {...props}
            months={months}
        />
    );
};