import React from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import {ReactComponent as ScanMissingImg} from "../../../../../images/photo-default.svg";
import ContractorVerificationDataCheckImage from "../check-image";
import {Loader, Tab} from "semantic-ui-react";

import bem from "../../../../../utils/bem";

import {CONTRACTOR_STATUS} from "../../../../../constants/contractor";

import {contractorCardSelector} from "../../../../../ducks/contractor";
import {
    fileContractorOriginalPreviousScanObjSelector,
    fileContractorOriginalScanObjSelector,
} from "../../../../../ducks/fileStore";

import "./style.sass";

const ContractorVerificationLayout = (props) => {
    const {
        scanType,
        isLoadScan,
        scanWarnings = {},
        content,
    } = props;
    const [, element] = bem("contractor-verification-layout");
    const scans = useSelector(fileContractorOriginalScanObjSelector);
    const previousScans = useSelector(fileContractorOriginalPreviousScanObjSelector);
    const {
        edmStatus,
        status,
    } = useSelector(contractorCardSelector);

    function onOpenScan(base64str) {
        const image = new Image();

        const styles = {
            width: "50%",
            display: "block",
            margin: "0 auto",
        };

        image.src = `data:image/jpeg;base64,${base64str}`;

        if (styles) {
            Object
                .entries(styles)
                .forEach(([name, value]) => {
                    image.style[name] = value;
                });
        }

        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    const renderScanBlock = () => {
        if (!isLoadScan(scanType)) {
            return (
                <div className={element("loader-wrapper")}>
                    <Loader
                        className={element("loader")}
                        active
                        inline
                        content="Загрузка"
                    />
                </div>
            );
        }

        return (
            (isLoadScan(scanType) && scans[scanType] && scans[scanType].base64str) ?
                <>
                    {
                        previousScans[scanType]?.base64str && [status, edmStatus].includes(CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES) ?
                            <Tab
                                menu={{secondary: true, pointing: true}}
                                defaultActiveIndex={1}
                                panes={[
                                    {
                                        menuItem: "До изменений",
                                        render: () => {
                                            return (
                                                <ContractorVerificationDataCheckImage
                                                    src={`data:image/jpeg;charset=utf-8;base64, ${previousScans[scanType].base64str}`}
                                                    onClick={() => {
                                                        onOpenScan(previousScans[scanType].base64str);
                                                    }}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        menuItem: "После изменений",
                                        render: () => {
                                            return (
                                                <ContractorVerificationDataCheckImage
                                                    src={`data:image/jpeg;charset=utf-8;base64, ${scans[scanType].base64str}`}
                                                    onClick={() => {
                                                        onOpenScan(scans[scanType].base64str);
                                                    }}
                                                />
                                            );
                                        },
                                    },
                                ]}
                            /> :
                            <>
                                <ContractorVerificationDataCheckImage
                                    warning={Boolean(scanWarnings[scanType])}
                                    src={`data:image/jpeg;charset=utf-8;base64, ${scans[scanType].base64str}`}
                                    onClick={() => {
                                        onOpenScan(scans[scanType].base64str);
                                    }}
                                />
                                {Boolean(scanWarnings[scanType]) &&
                                    <div className={element("scan-list-warning")}>
                                        <HelpTooltip
                                            width={24}
                                            height={24}
                                            position="right"
                                            type="warning"
                                            className="card-app__header-title-tooltip"
                                        />
                                        <div className={element("scan-list-warning-text")}>
                                            {scanWarnings[scanType]}
                                        </div>
                                    </div>
                                }
                            </>
                    }
                </> :
                <div className={element("photo-default")}>
                    <ScanMissingImg />
                </div>
        );
    };

    return (
        <div className="contractor-verification-layout">
            <div className="col-16 col-xxl-6 contractor-verification-layout__scan">
                {renderScanBlock()}
            </div>
            <div className="col-16 col-xxl-10 contractor-verification-layout__data">
                {content}
            </div>
        </div>
    );
};

export default ContractorVerificationLayout;