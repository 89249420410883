export const BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST = "BFF_CROWD_TASK_CARD_GET_BY_ID_REQUEST";
export const BFF_CROWD_TASK_CARD_GET_BY_ID_SUCCESS = "BFF_CROWD_TASK_CARD_GET_BY_ID_SUCCESS";
export const BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR = "BFF_CROWD_TASK_CARD_GET_BY_ID_ERROR";

export const BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST = "BFF_CROWD_TASK_CARD_GET_BY_NUMBER_REQUEST";
export const BFF_CROWD_TASK_CARD_GET_BY_NUMBER_SUCCESS = "BFF_CROWD_TASK_CARD_GET_BY_NUMBER_SUCCESS";
export const BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR = "BFF_CROWD_TASK_CARD_GET_BY_NUMBER_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITED_GET_PAGE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_QUEUE_GET_PAGE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_GET_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_REJECT_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_RESULT_APPROVE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_WORKING_FORCE_FINISH_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTOR_REJECT_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_COMPLETED_GET_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTOR_INVITE_WITHDRAW_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_WITHDRAW_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTOR_INVITE_WITHDRAW_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_REJECTED_GET_PAGE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTOR_INVITE_CANDIDATES_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_INVITE_CANDIDATES_GET_PAGE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_SCHEDULE_OPTIMUM_INTEGRATION_ERROR";

export const BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST = "BFF_CROWD_TASK_LOG_GET_PAGE_REQUEST";
export const BFF_CROWD_TASK_LOG_GET_PAGE_SUCCESS = "BFF_CROWD_TASK_LOG_GET_PAGE_SUCCESS";
export const BFF_CROWD_TASK_LOG_GET_PAGE_ERROR = "BFF_CROWD_TASK_LOG_GET_PAGE_ERROR";

export const BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_REQUEST = "BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_REQUEST";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_SUCCESS = "BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_SUCCESS";
export const BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_ERROR = "BFF_CROWD_TASK_CARD_CONTRACTORS_COUNTS_ERROR";

export const UPDATE_CROWD_TASK_CARD_STORE = "UPDATE_CROWD_CHAT_MESSAGE_STORE";