import React from "react";

import {ContractorFinanceIncomeNdfl} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {STATUS_PAYMENT_CONTRACTOR_INCOME_AWAITING} from "../../../../../constants/status";

import {
    getContractorAwaitingFinanceNdflPayments,
} from "../../../../../ducks/contractorFinanceIncomeNdfl";

const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_AWAITING);

export const ContractorFinanceIncomeNdflAwaiting = (props) => {
    return (
        <ContractorFinanceIncomeNdfl
            fetchList={getContractorAwaitingFinanceNdflPayments}
            isShowBankStatusFilter={false}
            isShowItemActions={false}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
