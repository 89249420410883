import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/contractors-registry/contractor/banks";

//*  TYPES  *//

const GET_LIST_CONTRACTOR_BANKS_REQUEST = "GET_LIST_CONTRACTOR_BANKS_REQUEST";
const GET_LIST_CONTRACTOR_BANKS_SUCCESS = "GET_LIST_CONTRACTOR_BANKS_SUCCESS";
const GET_LIST_CONTRACTOR_BANKS_ERROR = "GET_LIST_CONTRACTOR_BANKS_ERROR";

const SENDING_CONTRACTOR_TO_BANK_REQUEST = "SENDING_CONTRACTOR_TO_BANK_REQUEST";
const SENDING_CONTRACTOR_TO_BANK_SUCCESS = "SENDING_CONTRACTOR_TO_BANK_SUCCESS";
const SENDING_CONTRACTOR_TO_BANK_ERROR = "SENDING_CONTRACTOR_TO_BANK_ERROR";

const REFRESH_CONTRACTOR_BANK_STATUS_REQUEST = "REFRESH_CONTRACTOR_BANK_STATUS_REQUEST";
const REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS = "REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS";
const REFRESH_CONTRACTOR_BANK_STATUS_ERROR = "REFRESH_CONTRACTOR_BANK_STATUS_ERROR";

const REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST = "REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST";
const REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS = "REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS";
const REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR = "REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR";

const CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST = "CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST";
const CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS = "CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS";
const CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR = "CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR";

const CONTRACTOR_BANKS_UPDATE_STORE = "CONTRACTOR_BANKS_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    progress: false,
    progressAction: false,
    progressForciblySendingToBank: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS:
        return {
            ...state,
            progressForciblySendingToBank: false,
        };
    case CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR:
        return {
            ...state,
            progressForciblySendingToBank: false,
        };
    case CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST:
        return {
            ...state,
            progressForciblySendingToBank: true,
        };
    case GET_LIST_CONTRACTOR_BANKS_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_LIST_CONTRACTOR_BANKS_SUCCESS:
        return {
            ...state,
            progress: false,
            list: payload,
        };
    case GET_LIST_CONTRACTOR_BANKS_ERROR:
        return {
            ...state,
            progress: false,
        };
    case SENDING_CONTRACTOR_TO_BANK_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case SENDING_CONTRACTOR_TO_BANK_SUCCESS:
    case SENDING_CONTRACTOR_TO_BANK_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case CONTRACTOR_BANKS_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getListContractorBanks(payload) {
    return {
        type: GET_LIST_CONTRACTOR_BANKS_REQUEST,
        payload,
    };
}

export function sendingContractorToBank(payload) {
    return {
        type: SENDING_CONTRACTOR_TO_BANK_REQUEST,
        payload,
    };
}

export function refreshContractorBankStatus(payload) {
    return {
        type: REFRESH_CONTRACTOR_BANK_STATUS_REQUEST,
        payload,
    };
}

export function refreshContractorIdentificationStatus(payload) {
    return {
        type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST,
        payload,
    };
}

export function updateContractorBanksBalanceStore(payload) {
    return {
        type: CONTRACTOR_BANKS_UPDATE_STORE,
        payload,
    };
}

export function sendToContractorBankForcibly(payload) {
    return {
        type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const contractorBalanceSelector = state => state.contractorBanks;
export const getContractorBanksListSelector = createSelector(contractorBalanceSelector, ({list}) => list);
export const getContractorBanksProgressSelector = createSelector(contractorBalanceSelector, ({progress}) => progress);
export const getContractorBanksProgressActionSelector = createSelector(contractorBalanceSelector, ({progressAction}) => progressAction);
export const contractorBanksSendingProgressForciblySelector = createSelector(contractorBalanceSelector, ({progressForciblySendingToBank}) => progressForciblySendingToBank);

//*  SAGA  *//

//GET /bff/adm/contractors-registry/contractor/banks/getList
export const getListContractorBanksSaga = function* ({payload} ) {
    try {
        const result = yield request.bff.get(`${controller}/getList`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_LIST_CONTRACTOR_BANKS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_LIST_CONTRACTOR_BANKS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_LIST_CONTRACTOR_BANKS_ERROR, payload: error});
    }
};

//POST /bff/adm/contractors-registry/contractor/banks/sendingContractorToBank
export const sendingContractorToBankSaga = function* ({payload} ) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/sendingContractorToBank`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SENDING_CONTRACTOR_TO_BANK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SENDING_CONTRACTOR_TO_BANK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: SENDING_CONTRACTOR_TO_BANK_ERROR, payload: error});
    }
};

//POST /bff/adm/contractors-registry/contractor/banks/refreshContractorStatus
export const refreshContractorBankStatusSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            bankType,
            getResult = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/refreshContractorStatus?contractorId=${contractorId}&bankType=${bankType}`);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_CONTRACTOR_BANK_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REFRESH_CONTRACTOR_BANK_STATUS_ERROR, payload: error});
    }
};

//POST /bff/adm/contractors-registry/contractor/banks/refreshIdentificationStatus
export const refreshContractorIdentificationStatusSaga = function* ({payload} ) {
    try {
        const {
            contractorId,
            bankType,
            getResult = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/refreshIdentificationStatus?contractorId=${contractorId}&bankType=${bankType}`);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR, payload: error});
    }
};

//POST /bff/adm/contractors-registry/contractor/banks/sendingContractorToBank
export const sendToContractorBankForciblySaga = function* ({payload} ) {
    const {
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/sendingContractorToBankForcibly`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_LIST_CONTRACTOR_BANKS_REQUEST, getListContractorBanksSaga),
        takeEvery(SENDING_CONTRACTOR_TO_BANK_REQUEST, sendingContractorToBankSaga),
        takeEvery(REFRESH_CONTRACTOR_BANK_STATUS_REQUEST, refreshContractorBankStatusSaga),
        takeEvery(REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST, refreshContractorIdentificationStatusSaga),
        takeEvery(CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST, sendToContractorBankForciblySaga),
    ]);
}