import React, {createContext} from "react";

import {ReactComponent as AddIcon} from "../../../images/add.svg";
import ProfileBox from "../../ActualComponents/ProfileBox";
import Text from "../../ActualComponents/Text";
import NmButton from "../../NmButton";
import NmLoader from "../../NmLoader";
import RecruitmentCandidateInformation from "../CandidateInformation";
import {
    RecruitmentCandidateAdditionalInfoEdit,
} from "../CandidateInformation/components/Edit";

import {useModal} from "../../../hooks/useModal";

import {isVisibleRecruitmentActions} from "../../../utils/access";

import {COLOR} from "../../../constants/color";

export const RecruitmentCandidateInformationContext = createContext({});

export const RecruitmentCandidateInformationCard = (props) => {
    const {
        match,
        card,
        header = "Информация из отклика",
        isVisibleAddButton,
        progressAction,
        updateInformation,
        fetchInformation,
        uploadFile,
        deleteFile,
        isVisibleResumes,
    } = props;

    const id = match.params.id;

    const isAccessActions = isVisibleRecruitmentActions();

    const progress = false;

    const {
        isOpen: isOpenAdd,
        onOpenModal: onOpenAdd,
        onCloseModal: onCloseAdd,
    } = useModal();

    const onClickAdd = () => {
        onOpenAdd({});
    };

    return (
        <div className="relative">
            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-4">
                <Text.Title
                    color={COLOR.SECONDARY_100}
                    level="3"
                    className="mt-2 mt-md-4 mb-3 mb-md-4"
                >
                    {header}
                </Text.Title>
                {
                    isAccessActions && isVisibleAddButton &&
                    <NmButton
                        size="lg"
                        color="grey"
                        onClick={onClickAdd}
                        icon={<AddIcon />}
                        className="ms-4"
                    >
                        Добавить информацию
                    </NmButton>
                }
            </div>
            {
                progress &&
                <NmLoader
                    active={true}
                    inverted={true}
                />
            }
            <ProfileBox>
                <RecruitmentCandidateInformationContext.Provider
                    value={{
                        updateInformation,
                        fetchInformation,
                        candidateId: id,
                        progressAction,
                        card,
                        uploadFile,
                        deleteFile,
                    }}
                >
                    {
                        isOpenAdd &&
                        <RecruitmentCandidateAdditionalInfoEdit
                            onClose={onCloseAdd}
                        />
                    }
                    <RecruitmentCandidateInformation
                        card={card}
                        isVisibleResumes={isVisibleResumes}
                    />
                </RecruitmentCandidateInformationContext.Provider>
            </ProfileBox>
        </div>
    );
};
