import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useKedoLogsBff} from "./useBff";

import {updateKedoLogsStore} from "../../../../ducks/kedo/logs/actionCreators";
import {getKedoLogsInitialState} from "../../../../ducks/kedo/logs/reducer";

export const useKedoLogsFetch = (params) => {
    const {
        pageNum,
        pageSize,
        clientId,
        filter,
    } = params;

    const dispatch = useDispatch();

    const {
        fetchPage,
    } = useKedoLogsBff();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filter,
    ]);

    useEffect(() => {
        return () => {
            dispatch(updateKedoLogsStore(getKedoLogsInitialState()));
        };
    }, []);

    const fetchList = () => {
        fetchPage({
            clientId,
            pageNum,
            pageSize,
            ...filter,
        });
    };

    return {
        fetchList,
    };
};