import React from "react";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import AmountInput from "../../../../../../components/AmountInput";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {useSettingsDirectoriesBankEditFormData} from "./hooks/useData";


const SettingsDirectoriesBankEditForm = (props) => {
    const {
        onClose = () => {
        },
        fetchList,
        editData,
    } = props;

    const {
        handleSubmit,
        values,
        handleChange,
        touched,
        errors,
        limitFields,
        onChange,
    } = useSettingsDirectoriesBankEditFormData({
        onClose,
        editData,
        fetchList,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Редактирование лимитов и реквизитов банка 
                    {" "}
                    {editData.bankName}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            loading={false}
        >
            <NmForm addMargin>
                <NmTitle size="md">
                    Реквизиты банка
                </NmTitle>
                <NmInputV2
                    required
                    size="xl"
                    maxLength={255}
                    label="Наименование банка"
                    placeholder="Введите наименование банка"
                    name="bankSecondName"
                    value={values.bankSecondName}
                    onChange={handleChange}
                    error={touched.bankSecondName && errors.bankSecondName}
                />
                <NmInputV2
                    required
                    size="xl"
                    label="БИК"
                    placeholder="БИК"
                    name="bic"
                    mask="999999999"
                    value={values.bic}
                    onChange={handleChange}
                    error={touched.bic && errors.bic}
                />
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required
                            size="xl"
                            label="Р/сч. №"
                            placeholder="Р/сч. №"
                            name="checkingAccount"
                            mask="99999999999999999999"
                            value={values.checkingAccount }
                            onChange={handleChange}
                            error={touched.checkingAccount && errors.checkingAccount}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required
                            size="xl"
                            label="К/сч. №"
                            placeholder="К/сч. №"
                            name="correspondentAccount"
                            mask="99999999999999999999"
                            value={values.correspondentAccount}
                            onChange={handleChange}
                            error={touched.correspondentAccount && errors.correspondentAccount}
                        />
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required
                            size="xl"
                            label="ИНН"
                            placeholder="ИНН"
                            name="inn"
                            mask="9999999999"
                            value={values.inn}
                            onChange={handleChange}
                            error={touched.inn && errors.inn}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required
                            size="xl"
                            label="КПП"
                            placeholder="КПП"
                            name="kpp"
                            mask="999999999"
                            value={values.kpp}
                            onChange={handleChange}
                            error={touched.kpp && errors.kpp}
                        />
                    </div>
                </div>
                {limitFields.map((item, index) => {
                    const {
                        title,
                        subTitle,
                        blockName,
                        bankIdentificationStatus,
                    } = item;

                    return (
                        <React.Fragment key={index}>
                            <NmTitle size="md">
                                {title}
                            </NmTitle>
                            <Text level="3">
                                {subTitle}
                            </Text>
                            <AmountInput
                                newInput
                                size="xl"
                                label="Разовый лимит, ₽"
                                required
                                error={
                                    bankIdentificationStatus ?
                                        touched[blockName]?.[bankIdentificationStatus]?.singleLimit &&
                                        errors[blockName]?.[bankIdentificationStatus]?.singleLimit :
                                        touched[blockName]?.singleLimit &&
                                        errors[blockName]?.singleLimit
                                }
                                value={
                                    bankIdentificationStatus ?
                                        values[blockName]?.[bankIdentificationStatus]?.singleLimit :
                                        values[blockName]?.singleLimit
                                }
                                name="singleLimit"
                                onChange={(event, params) => {
                                    onChange(event, params, blockName, bankIdentificationStatus);
                                }}
                            />
                            <AmountInput
                                newInput
                                size="xl"
                                label="Суточный лимит, ₽"
                                required
                                error={
                                    bankIdentificationStatus ?
                                        touched[blockName]?.[bankIdentificationStatus]?.dayLimit &&
                                        errors[blockName]?.[bankIdentificationStatus]?.dayLimit :
                                        touched[blockName]?.dayLimit &&
                                        errors[blockName]?.dayLimit
                                }
                                value={
                                    bankIdentificationStatus ?
                                        values[blockName]?.[bankIdentificationStatus]?.dayLimit :
                                        values[blockName]?.dayLimit
                                }
                                name="dayLimit"
                                onChange={(event, params) => {
                                    onChange(event, params, blockName, bankIdentificationStatus);
                                }}
                            />
                            <AmountInput
                                newInput
                                size="xl"
                                label="Месячный лимит, ₽"
                                required
                                error={
                                    bankIdentificationStatus ?
                                        touched[blockName]?.[bankIdentificationStatus]?.monthLimit &&
                                        errors[blockName]?.[bankIdentificationStatus]?.monthLimit :
                                        touched[blockName]?.monthLimit &&
                                        errors[blockName]?.monthLimit
                                }
                                value={
                                    bankIdentificationStatus ?
                                        values[blockName]?.[bankIdentificationStatus]?.monthLimit :
                                        values[blockName]?.monthLimit
                                }
                                name="monthLimit"
                                onChange={(event, params) => {
                                    onChange(event, params, blockName, bankIdentificationStatus);
                                }}
                            />
                        </React.Fragment>
                    );
                })}
            </NmForm>
        </NmModal>
    );
};

export default SettingsDirectoriesBankEditForm;