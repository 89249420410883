import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";
import {
    getLogPageRecruitmentVacancy,
    updateRecruitmentVacancyStore,
} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentVacancyLogListProgressSelector,
    bffRecruitmentVacancyLogListSelector,
    bffRecruitmentVacancyLogListTotalCountSelector,
    bffRecruitmentVacancyLogListTotalPageSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";

import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";

import dateFormat, {formatLocalDate} from "../../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../constants";
import {
    RECRUITMENT_VACANCY_LOG_FIELD_DICT,
    RECRUITMENT_VACANCY_LOG_TYPE,
    RECRUITMENT_VACANCY_LOG_TYPE_DICT,
} from "./constants";

const VacancyLogs = (props) => {
    const {
        vacancy,
        onClose,
    } = props;

    const list = useSelector(bffRecruitmentVacancyLogListSelector);
    const totalPages = useSelector(bffRecruitmentVacancyLogListTotalPageSelector);
    const totalCount = useSelector(bffRecruitmentVacancyLogListTotalCountSelector);
    const progress = useSelector(bffRecruitmentVacancyLogListProgressSelector);

    const typesOptions = dictionaryToOptions(RECRUITMENT_VACANCY_LOG_TYPE_DICT);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        initFilter: {
            dateFrom: null,
            dateTo: null,
            types: [],
        },
        setPagination,
        pageSize,
    });

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyStore({
                logPageData: {},
                logList: [],
                logTotalCount: 0,
                logProgress: false,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        const {
            dateFrom,
            dateTo,
            types,
        } = filterData;

        dispatch(getLogPageRecruitmentVacancy({
            pageSize,
            pageNum,
            vacancyId: vacancy.vacancyId,
            dateFrom: !isNullOrWhitespace(dateFrom) ? dateFormat(dateFrom, "yyyy-MM-dd") : undefined,
            dateTo: !isNullOrWhitespace(dateTo) ? dateFormat(dateTo, "yyyy-MM-dd") : undefined,
            types: types?.length ? types : undefined,
        }));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                createDate,
                operation,
                initiatorName,
                jobBoardType,
                editedFields,
            } = item;

            return {
                ...item,
                key: createDate,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        primaryHeader={RECRUITMENT_VACANCY_LOG_TYPE_DICT[operation]}
                        labels={[
                            {
                                label: "Дата и время операции",
                                text: formatLocalDate(createDate, "dd.MM.yyyy HH:mm:ss"),
                            },
                            jobBoardType && {
                                label: "Джоб-борд",
                                text: RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[jobBoardType],
                            },
                            operation === RECRUITMENT_VACANCY_LOG_TYPE.UPDATE_VACANCY_PARAM && {
                                label: "Отредактированные поля",
                                text: editedFields?.length ?
                                    editedFields
                                        .map(item => RECRUITMENT_VACANCY_LOG_FIELD_DICT[item] || item)
                                        .join(", ") :
                                    "-",
                                noWrap: false,
                                inline: true,
                            },
                            {
                                label: "Инициатор",
                                text: initiatorName || "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог по вакансии "№
                    {vacancy.vacancyNumber}
                    {" "}
- 
                    {" "}
                    {vacancy.title}
"
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={
                    <NmForm
                        horizontal
                        className="flex align-items-end"
                    >
                        <div className="col-16 col-md-7 col-xl-5">
                            <NmDateRangePickerV2
                                size="lg"
                                startFieldName="dateFrom"
                                endFieldName="dateTo"
                                value={{dateFrom: filter.dateFrom, dateTo: filter.dateTo}}
                                isClearable
                                label="Дата операции"
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-4">
                            <NmDropdownV2
                                size="lg"
                                label="Тип операции"
                                placeholder="Выберите тип"
                                onChange={onChangeFilter}
                                name="types"
                                value={filter.types}
                                options={typesOptions}
                                multiple={true}
                            />
                        </div>
                        <div className="col-16 col-md-4">
                            <FilterButtonsV2
                                onClear={onClear}
                                onSearch={() => onSearch(filter)}
                            />
                        </div>
                    </NmForm>
                }
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        />
                }
            </NmPage>
        </NmModal>
    );
};

export default VacancyLogs;