import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/objects";
//*  TYPES  *//

const CLIENT_OBJECT_READ_REQUEST = "CLIENT_OBJECT_READ_REQUEST";
const CLIENT_OBJECT_READ_SUCCESS = "CLIENT_OBJECT_READ_SUCCESS";
const CLIENT_OBJECT_READ_ERROR = "CLIENT_OBJECT_READ_ERROR";

const CLIENT_OBJECT_ADD_REQUEST = "CLIENT_OBJECT_ADD_REQUEST";
const CLIENT_OBJECT_ADD_SUCCESS = "CLIENT_OBJECT_ADD_SUCCESS";
const CLIENT_OBJECT_ADD_ERROR = "CLIENT_OBJECT_ADD_ERROR";

const CLIENT_OBJECT_UPDATE_REQUEST = "CLIENT_OBJECT_UPDATE_REQUEST";
const CLIENT_OBJECT_UPDATE_SUCCESS = "CLIENT_OBJECT_UPDATE_SUCCESS";
const CLIENT_OBJECT_UPDATE_ERROR = "CLIENT_OBJECT_UPDATE_ERROR";

const CLIENT_OBJECT_LIST_READ_REQUEST = "CLIENT_OBJECT_LIST_READ_REQUEST";
const CLIENT_OBJECT_LIST_READ_SUCCESS = "CLIENT_OBJECT_LIST_READ_SUCCESS";
const CLIENT_OBJECT_LIST_READ_ERROR = "CLIENT_OBJECT_LIST_READ_ERROR";

const CLIENT_OBJECT_CLEAR_STORE = "CLIENT_OBJECT_CLEAR_STORE";

const CLIENT_OBJECT_UPDATE_FIELD_STORE = "CLIENT_OBJECT_UPDATE_FIELD_STORE";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    totalCount: 0,
    card: {},
    pageData: {},
    error: null,
    progress: false,
    progressAdd: false,
    isSuccessAdd: false,
    progressUpdate: false,
    isSuccessUpdate: false,
    newObjectId: "",
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CLIENT_OBJECT_LIST_READ_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case CLIENT_OBJECT_UPDATE_FIELD_STORE:
        const {
            field,
            value,
        } = payload;
        return {
            ...state,
            progress: false,
            [field]: value,
        };
    case CLIENT_OBJECT_CLEAR_STORE:
        return {
            ...initial,
        };
    case CLIENT_OBJECT_UPDATE_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case CLIENT_OBJECT_ADD_REQUEST:
        return {
            ...state,
            progressAdd: true,
        };
    case CLIENT_OBJECT_READ_REQUEST:
        return {
            ...state,
            card: {},
            progress: true,
        };
    case CLIENT_OBJECT_ADD_SUCCESS:
        return {
            ...state,
            newObjectId: payload,
            progressAdd: false,
            isSuccessAdd: true,
        };
    case CLIENT_OBJECT_UPDATE_SUCCESS:
        return {
            ...state,
            progressUpdate: false,
            isSuccessUpdate: true,
        };
    case CLIENT_OBJECT_READ_SUCCESS:
        return {
            ...state,
            progress: false,
            card: payload,
        };
    case CLIENT_OBJECT_LIST_READ_SUCCESS:
        const {
            objects = [],
            totalCount = 0,
        } = payload;

        return {
            ...state,
            progress: false,
            list: objects,
            totalCount,
        };
    case CLIENT_OBJECT_ADD_ERROR:
        return {
            ...state,
            progressAdd: false,
            isSuccessAdd: false,
            error: payload,
        };
    case CLIENT_OBJECT_LIST_READ_ERROR:
        return {
            ...state,
            progress: false,
            progressUpdate: false,
            isSuccessUpdate: false,
            error: payload,
        };
    case CLIENT_OBJECT_UPDATE_ERROR:
        return {
            ...state,
            progressUpdate: false,
            isSuccessUpdate: false,
            error: payload,
        };
    case CLIENT_OBJECT_READ_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientObjectById(payload) {
    return {
        type: CLIENT_OBJECT_READ_REQUEST,
        payload,
    };
}

export function addClientObject(payload) {
    return {
        type: CLIENT_OBJECT_ADD_REQUEST,
        payload: payload,
    };
}

export function updateFieldClientObjectStore(field, value) {
    return {
        type: CLIENT_OBJECT_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function updateClientObject(payload) {
    return {
        type: CLIENT_OBJECT_UPDATE_REQUEST,
        payload,
    };
}

export function getClientObjectList(payload) {
    return {
        type: CLIENT_OBJECT_LIST_READ_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientObjectSelector = state => state.clientObject;

export const clientObjectListSelector = createSelector(clientObjectSelector, ({list}) => list);

export const clientObjectCardSelector = createSelector(clientObjectSelector, ({card}) => card);

export const clientObjectProgressSelector = createSelector(clientObjectSelector, ({progress}) => progress);

export const clientObjectOptionsSelector = createSelector(clientObjectSelector, ({list}) => {
    return list.map((item) => ({
        key: item.objectId,
        value: item.objectId,
        text: `${item.name}; ${item.address}`,
    }));
});

export const clientObjectProgressAddSelector = createSelector(clientObjectSelector, ({progressAdd}) => progressAdd);

export const clientObjectIsSuccessAddSelector = createSelector(clientObjectSelector, ({isSuccessAdd}) => isSuccessAdd);

export const clientObjectProgressUpdateSelector = createSelector(clientObjectSelector, ({progressUpdate}) => progressUpdate);
//*  SAGA  *//

export const clientObjectReadSaga = function* (action) {
    try {
        const {payload: params} = action;

        const result = yield request.get(`${controller}/getById`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECT_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_OBJECT_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECT_READ_ERROR,
            payload: error,
        });
    }
};

export const updateClientObjectSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
            handleResponse,
        } = payload;

        const {errorMessage, errorCode} = yield request.post(`${controller}/update`, data);

        if (errorCode === "PROJECT_USER_MISMATCH") {
            yield put({
                type: CLIENT_OBJECT_UPDATE_ERROR,
                payload: errorMessage,
            });

            handleResponse();

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECT_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Объект успешно отредактирован");

        yield put({
            type: CLIENT_OBJECT_UPDATE_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: CLIENT_OBJECT_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const addClientObjectSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/add`, data);

        const {guid, errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECT_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Объект успешно добавлен");

        yield put({
            type: CLIENT_OBJECT_ADD_SUCCESS,
            payload: guid,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECT_ADD_ERROR,
            payload: error,
        });
    }
};

export const clientObjectListReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECT_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_OBJECT_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECT_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_OBJECT_READ_REQUEST, clientObjectReadSaga),
        takeEvery(CLIENT_OBJECT_LIST_READ_REQUEST, clientObjectListReadSaga),
        takeEvery(CLIENT_OBJECT_UPDATE_REQUEST, updateClientObjectSaga),
        takeEvery(CLIENT_OBJECT_ADD_REQUEST, addClientObjectSaga),
    ]);
}
