import React from "react";

import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmButton from "../../../../components/NmButton";

import "./style.sass";

function ExportDocumentFilter(props) {
    const {
        filter = {},
        onChangeFilter,
        submit,
        loadingForm,
    } = props;
    const {clientId = "", periodStartDateTime = null, periodEndDateTime = null} = filter;

    return (
        <NmForm
            horizontal
            className="export-document-filter"
        >
            <FilterCustomer
                isClearable
                classNameError="export-document-filter__error"
                className="export-document-filter__item export-document-filter__item_pos-relative"
                value={clientId}
                name="clientId"
                onChange={onChangeFilter}
            />
            <NmDateRangePickerV2
                classNameError="export-document-filter__error"
                className="export-document-filter__item export-document-filter__item_pos-relative"
                size="lg"
                label="Период"
                isClearable
                startFieldName="periodStartDateTime"
                endFieldName="periodEndDateTime"
                value={{
                    periodStartDateTime,
                    periodEndDateTime,
                }}
                onChange={onChangeFilter}
                dateFormat="dd.MM.yy"
                returnString={false}
            />
            <div>
                <NmButton
                    onClick={submit}
                    loading={loadingForm}
                    disabled={loadingForm}
                >
                    Выгрузить
                </NmButton>
            </div>
        </NmForm>
    );
}

export default ExportDocumentFilter;