import React, { useCallback, useEffect,useRef, useState } from "react";
import ReactCrop from "react-image-crop";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import bem from "../../utils/bem";

import "./style.sass";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = (props) => {
    const {
        file,
        close,
        modalSize = "sm",
        cropUnit = "%",
        maxCropHeight,
        maxCropWidth,
        submit,
    } = props;

    const [block, element] = bem("image-crop-form");
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: cropUnit, width: 100, height: 100 });
    const [completedCrop, setCompletedCrop] = useState(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
    }, [completedCrop]);

    function getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve) => {
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, "image/png");
        });
    }

    const onSubmit = async () => {
        const croppedImg = await getCroppedImg(imgRef.current, completedCrop, file.name);
        const resultFile = new File([croppedImg], file.name, {lastModified: file.lastModified, type: file.type, path: file.path, lastModifiedDate: file.lastModifiedDate});

        submit(resultFile);
    };

    return (
        <NmModal
            size={modalSize}
            onClose={close}
            header={
                <NmTitle size="lg">
                    Область для миниатюры
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    submit={onSubmit}
                    onClose={close}
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
        >
            <NmForm className={block()}>
                <div className={element("image-zone")}>
                    <ReactCrop
                        src={file.preview}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                        minWidth={50}
                        minHeight={50}
                        maxHeight={maxCropHeight}
                        maxWidth={maxCropWidth}
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};

export default ImageCrop;