import {useEffect, useState} from "react";

import {getRightsStatus} from "../utils/strapiService";

export function useRightsStatusStrapi() {
    const [text, setText] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const _text = await getRightsStatus();
            setText(_text);
        };
        fetchData();
    }, []);
    return {
        text,
    };
}