import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

export const useJobBoardsCheckboxForm = (params) => {
    const {
        jobBoardsTypes = [],
    } = params;

    const [selectedJobBoards, setSelectedJobBoards] = useState(jobBoardsTypes);

    useEffect(() => {
        if (isEmpty(jobBoardsTypes)) {
            return;
        }

        setSelectedJobBoards(jobBoardsTypes);
    }, [jobBoardsTypes]);

    const onChange = (event, {name, checked}) => {
        if (!checked) {
            setSelectedJobBoards(selectedJobBoards.filter(item => item !== name));

            return;
        }

        setSelectedJobBoards([
            ...selectedJobBoards,
            name,
        ]);
    };

    return {
        onChange,
        selectedJobBoards,
    };
};