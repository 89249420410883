import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2/index";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer/index";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2/index";
import NmForm from "../../../../components/ActualComponents/NmForm/index";

import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import PropTypes from "prop-types";

const PivotFilter = props => {
    const {
        search,
        filterData,
    } = props;

    const {t} = useTranslation();

    useEffect(() => {
        setForm(filterData);
    }, []);

    const [form, setForm] = useState({});

    const {
        clientId,
        fromDate,
        toDate,
    } = form;

    const onKeyDown = (e) => {
        const {
            clientId,
            fromDate,
            toDate,
        } = form;

        if (e.keyCode === 13 &&
            (!isNullOrWhitespace(clientId) ||
                !isNullOrWhitespace(fromDate) ||
                !isNullOrWhitespace(toDate))
        ) {
            search(form);
        }
    };

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const onSearch = () => {
        search(form);
    };

    const onClearFilter = () => {
        setForm({});
        search({});
    };

    return (
        <NmForm
            horizontal
            className="pivot-filter"
            onKeyDown={onKeyDown}
        >
            <FilterCustomer
                label={t("finance-pivot-table.customer")}
                isClearable
                noResultsMessage={t("no-search-result.content")}
                onChange={onChange}
                name="clientId"
                value={clientId}
            />
            <NmDateRangePickerV2
                size="lg"
                label="Период"
                startFieldName="fromDate"
                endFieldName="toDate"
                value={{fromDate, toDate}}
                isClearable={true}
                onChange={onChange}
            />
            <FilterButtonsV2
                onSearch={onSearch}
                onClear={onClearFilter}
            />
        </NmForm>
    );
};

PivotFilter.propTypes = {
    search: PropTypes.func,
    filterData: PropTypes.object,
};

export default PivotFilter;