import React from "react";

import {ITreeList, TreeNode} from "./Node";

import {ITreeItem, TreeConfigType} from "./types";

interface TreeProps {
    nodes: TreeConfigType,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, data: ITreeList) => void,
    getChildren: (nodes: ITreeList, open: boolean) => void,
    onClickChevron: (data: ITreeList) => void,
    disabled?: boolean,
    excludeChevronIconType?: string,
    isChecked?: (data?: ITreeItem) => boolean | undefined,
    isIndeterminate?: (data?: ITreeItem) => boolean | undefined,
}

const Tree = (props: TreeProps) => {
    const {
        nodes,
        onClickChevron,
        getChildren,
        disabled,
        excludeChevronIconType,
        isChecked,
        isIndeterminate,
        onChange,
    } = props;

    return nodes.map((item, index) => {
        return (
            <TreeNode
                index={index}
                key={item.value}
                disabled={disabled}
                onChange={onChange}
                item={item}
                type={item.type}
                checked={item.checked}
                value={item.value}
                onClickChevron={onClickChevron}
                getChildren={getChildren}
                excludeChevronIconType={excludeChevronIconType}
                isChecked={isChecked}
                isIndeterminate={isIndeterminate}
            />
        );
    });
};

export default Tree;