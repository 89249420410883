import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../../../components/CheckboxList";
import NmTitle from "../../../../../components/NmTitle";
import {CLIENT_BRAND_LOG_TYPE} from "../../contants";

import {usePagination} from "../../../../../hooks/usePagination";
import {useClientBrandBff} from "../../hooks/useBff";

import {formatLocalDate} from "../../../../../utils/dateFormat";

import {
    clientBrandsLogsListSelector,
    clientBrandsLogsProgressSelector,
    clientBrandsLogsTotalCountSelector,
    clientBrandsLogsTotalPagesSelector,
} from "../../../../../ducks/client/brands/selectors";

export const ClientBrandLogs = (props) => {
    const {
        onClose,
        brandId,
        clientId,
        brandName,
    } = props;

    const {
        getLogs,
    } = useClientBrandBff();

    const totalCount = useSelector(clientBrandsLogsTotalCountSelector);
    const totalPages = useSelector(clientBrandsLogsTotalPagesSelector);
    const list = useSelector(clientBrandsLogsListSelector);
    const progress = useSelector(clientBrandsLogsProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    useEffect(() => {
        getLogs({
            clientId,
            brandId,
            pageNum,
            pageSize,
        });
    }, [
        pageNum,
    ]);

    const getDescription = (item) => {
        if (item.typeLog === CLIENT_BRAND_LOG_TYPE.APP_BRAND) {
            return `Применение бренда к объектам: ${item.namesObjects}`;
        }

        if (item.typeLog === CLIENT_BRAND_LOG_TYPE.TAKE_OFF_BRAND) {
            return `Удаление бренда из объектов: ${item.namesObjects}`;
        }

        return item.description;
    };

    const getRows = () => {
        return list.map(item => {
            return {
                key: item.logId,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={getDescription(item)}
                        labels={[
                            {
                                label: "Дата",
                                text: item.changeDate
                                    ? formatLocalDate(item.changeDate, "dd.MM.yyyy HH:mm:ss")
                                    : "-",
                            },
                            {
                                label: "Инициатор",
                                text: item.userName || "-",
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {`История изменения бренда "${brandName}"`}
                </NmTitle>
            }
            footer={
                <NmPagination
                    isHasPageSize={false}
                    className="mt-5"
                    totalCount={totalCount}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    onChangePageSize={onChangePageSize}
                    onChangePagination={onPaginationChange}
                />
            }
            onClose={onClose}
            loading={progress}
            classNameContent="flex-column"
        >
            {
                isEmpty(list) ?
                    <NmEmptyPageV2 title="Данные отсутствуют" /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmModal>
    );
};