import React from "react";

import NmListCard from "../../../ActualComponents/NmList/Card";
import NmModal from "../../../ActualComponents/NmModal";
import Text from "../../../ActualComponents/Text";
import CheckboxList from "../../../CheckboxList";
import NmTitle from "../../../NmTitle";

import {COLOR} from "../../../../constants/color";

import "./style.sass";

export const SmartLinkNotLoadedNumbers = (props) => {
    const {
        onClose,
        errorNumbers,
        label,
    } = props;

    const getRows = () => {
        return errorNumbers.map(item => {
            return {
                ...item,
                key: item.num,
                contentRow: (
                    <NmListCard
                        primaryHeader={item.value}
                        noDivider
                        labels={[
                            {
                                label,
                                text: item.num,
                            },
                            {
                                labelColor: COLOR.NEGATIVE_100,
                                label: "Причина ошибки",
                                noWrap: false,
                                inline: true,
                                text: item.errorMessage,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            isShowBackButton={true}
            isBackIconAlwaysVisible={true}
            goBack={onClose}
            header={
                <NmTitle size="lg">
                    Незагруженные номера
                </NmTitle>
            }
            onClose={onClose}
            classNameContent="flex-column"
        >
            <Text
                level="3"
                className="mb-3 mb-md-4"
            >
                В некоторых строках файла содержались ошибки. Строки с такими номерами заданий не были загружены
            </Text>
            <CheckboxList
                rows={getRows()}
                className="not-loaded-numbers__list"
            />
        </NmModal>
    );
};