import {useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {handleFormString} from "../../../../../../utils/stringHelper";

import {
    addCrowdTaskGroup,
    updateCrowdTaskGroup,
} from "../../../../../../ducks/bff/crowd/taskGroups/actionCreators";

const useCrowdTaskGroupEditForm = (props) => {
    const {
        clientId,
        onClose,
        fetchList,
        isEdit,
        editData,
    } = props;

    const initialValues = useMemo(() => {
        return {
            name: "",
            comment: "",
            ...editData,
        };
    }, [editData]);


    const dispatch = useDispatch();

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            name,
            comment,
        } = values;

        const reqData = {
            clientId,
            name: handleFormString(name),
            comment: handleFormString(comment),
            onSuccess: () => {
                fetchList();
                onClose();
            },
        };

        if (isEdit) {
            dispatch(updateCrowdTaskGroup({
                taskGroupId: editData.taskGroupId,
                ...reqData,
            }));

            return;
        }

        dispatch(addCrowdTaskGroup({
            ...reqData,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
        handleChange,
        touched,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        touched,
    };
};

export default useCrowdTaskGroupEditForm;