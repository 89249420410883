import React, {useContext} from "react";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../NmConfirmV2";
import {ClientSettingsContext} from "../ContextProvider";

import {bffClientsSettingsPaymentsProgressActionSelector} from "../../../../../ducks/bff/clients/settings/selectors";

const CONTENT = "Сохранить данные на вкладке \":tab\"";

export const ClientSettingsChangedDataModal = (props) => {
    const {
        onSubmit,
    } = props;

    const {
        isOpenUnsavedDataConfirm,
        onCancelUnsavedDataConfirm,
        onCloseUnsavedDataConfirm,
        tab,
    } = useContext(ClientSettingsContext);

    const progressAction = useSelector(bffClientsSettingsPaymentsProgressActionSelector);

    if (!isOpenUnsavedDataConfirm) {
        return null;
    }

    return (
        <NmConfirmV2
            disabled={progressAction}
            onCancel={onCancelUnsavedDataConfirm}
            onClose={onCloseUnsavedDataConfirm}
            onConfirm={() => {onSubmit({isClose: false});}}
            confirmButton="Сохранить"
            cancelButton="Не сохранять"
            isNeedClosing={false}
            content={CONTENT.replace(":tab", tab.text)}
        />
    );
};
