import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmInputV2 from "../ActualComponents/NmInputV2";

import bem from "../../utils/bem";
import {getPhoneMask} from "../../utils/stringFormat";

import {RUSSIA_PHONE_CODE_ID} from "../../constants/phone";

import {
    bffCommonDictsPhoneCodeListSelector,
    bffCommonDictsPhoneCodeOptionsSelector,
} from "../../ducks/bff/common/dicts/selectors";

import "./style.sass";

const PhoneWithCodeFilter = (props) => {
    const {
        name = "phoneFilter",
        value = "",
        onChange,
        label = "Номер телефона",
        className = "",
        isVisibleCountry = true,
    } = props;

    const [phoneCodeId, setPhoneCodeId] = useState(RUSSIA_PHONE_CODE_ID);
    const phoneCodeOptions = useSelector(bffCommonDictsPhoneCodeOptionsSelector);
    const phoneCodeList = useSelector(bffCommonDictsPhoneCodeListSelector);

    const locatedOutsideRussia = phoneCodeId !== RUSSIA_PHONE_CODE_ID;

    const [block] = bem("phone-with-code-filter", className);

    useEffect(() => {
        if (!isVisibleCountry && phoneCodeId !== RUSSIA_PHONE_CODE_ID) {
            setPhoneCodeId(RUSSIA_PHONE_CODE_ID);
        }
    }, [isVisibleCountry]);

    const handleChangePhoneCode = (value) => {
        setPhoneCodeId(value);
        onChange(null, {name, value: ""});
    };

    return (
        <div className={block()}>
            {
                isVisibleCountry &&
                <div className="w-47">
                    <NmDropdownV2
                        size="lg"
                        label="Страна"
                        options={phoneCodeOptions}
                        value={phoneCodeId}
                        onChange={(event, {value}) => handleChangePhoneCode(value)}
                        search
                    />
                </div>
            }
            <div className={isVisibleCountry ? "w-47" : "fluid"}>
                <NmInputV2
                    size="lg"
                    mask={getPhoneMask(phoneCodeId, locatedOutsideRussia, phoneCodeList)}
                    maskChar={null}
                    placeholder="Введите номер"
                    name={name}
                    value={value}
                    label={label}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default PhoneWithCodeFilter;