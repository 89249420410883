import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {filterEmptyValues} from "../../../../../utils/objectHelper";

import {isClientUser} from "../../../../../constants/roles";

import {
    addSettingsKedoPosition,
    deleteSettingsKedoPosition,
    getSettingsKedoPositionsPage,
    updateSettingsKedoPosition,
} from "../../../../../ducks/bff/adm/settings/directories/kedo/positions/services";
import {
    addClientsKedoPosition,
    deleteClientsKedoPosition,
    getClientsKedoPositionsPage,
    updateClientsKedoPosition,
} from "../../../../../ducks/bff/clients/kedo/directories/services";

export const useKedoPositionsBff = (params) => {
    const {
        clientId,
        fetchCounts,
        sortType,
    } = params;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const dispatch = useDispatch();

    const deletePosition = (data) => {
        const requestData = {
            clientId,
            positionId: data.id,
            name: data.value,
            onSuccess: () => {
                fetchCounts();

                data.callBack();
            },
        };

        if (isClientRole) {
            dispatch(deleteClientsKedoPosition(requestData));

            return;
        }

        dispatch(deleteSettingsKedoPosition(requestData));
    };

    const addPosition = (data) => {
        const requestData = {
            clientId: clientId ? clientId : data.clientId,
            name: data.value,
            onSuccess: () => {
                fetchCounts();

                data.callBack();
            },
        };

        if (isClientRole) {
            dispatch(addClientsKedoPosition(requestData));

            return;
        }

        dispatch(addSettingsKedoPosition(requestData));

    };

    const fetchPositionsPage = (data) => {
        const {
            valueFilter: nameFilter,
            ...otherData
        } = data;

        const requestData = filterEmptyValues({
            clientIdFilter: clientId,
            nameFilter,
            orderType: sortType,
            ...otherData,
            onSuccess: () => {
                data.callBack();
            },
        });

        if (isClientRole) {
            dispatch(getClientsKedoPositionsPage(requestData));

            return;
        }

        dispatch(getSettingsKedoPositionsPage(requestData));
    };

    const updatePosition = (data) => {
        const requestData = {
            clientId: clientId ? clientId : data.clientId,
            positionId: data.id,
            name: data.value,
            onSuccess: () => {
                data.callBack();
            },
        };

        if (isClientRole) {
            dispatch(updateClientsKedoPosition(requestData));

            return;
        }

        dispatch(updateSettingsKedoPosition(requestData));
    };

    return {
        deletePosition,
        addPosition,
        fetchPositionsPage,
        updatePosition,
    };
};