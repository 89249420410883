import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    authorizeRecruitmentJobBoards,
} from "../../../../../ducks/bff/recruitment/jobBoards/actionCreators";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {history} from "../../../../../store/configureStore";

export const useRecruitmentJobBoardAuth = (props) => {
    const {
        fetchList,
    } = props;

    const dispatch = useDispatch();

    const location = useSelector((state) => state.router.location);

    useEffect(() => {
        if (!location.search) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);

        // https://github.com/hhru/api/blob/master/docs/authorization_for_user.md#get-auth
        const state = searchParams.get("state");

        if (!state) {
            return;
        }

        const code = searchParams.get("code");
        const [clientId, clientUserId, platformType] = state.split(",");

        dispatch(authorizeRecruitmentJobBoards({
            code,
            clientId,
            ownerId: clientUserId,
            platformType,
            onSuccess: () => {
                fetchList();

                history.replace(props.location.pathname);

                toastSuccess("Джоб-борд успешно обновлен");
            },
        }));
    }, []);

    return null;
};