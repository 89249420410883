import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import CommentsRetailModal from "../../../components/CommentsRetailModal";
import HistoryPaymentRetailModal from "../../../components/HistoryPaymentRetailModal";
import NmPage from "../../../components/NmPage";
import NmSelectedList from "../../../components/NmSelectedList";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as ChatIcon} from "../../../images/chat_24.svg";
import {NmContractorsTabs} from "../nm-contractors-tabs";
import ContractorResourcesFilter from "./contractor-resources-filter";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {phoneFormat, removePhoneMask} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_CONTRACTORS_RESOURCES} from "../../../constants/links";
import {CONTRACTOR_RETAIL_TYPE, RESOURCES_TYPE_SUGGESTION} from "../../../constants/resources";
import {
    ADMIN,
    CLIENT_ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    RNKO,
} from "../../../constants/roles";

import {clientNameIdMapSelector, getRetailClientNameMap} from "../../../ducks/client";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {
    contractorActionProgressSelector,
    contractorsRetailPageSelector,
    contractorsRetailProgressSelector,
    contractorsRetailTotalCountSelector,
    contractorsRetailTotalPagesSelector,
    contractorStatusDictSelector,
    getContractorsRetailPage,
    retailExport,
    retailStartJob,
    updateContractorRetail,
} from "../../../ducks/contractor";
import {contractorStatusSelector} from "../../../ducks/order";

import "./style.sass";

import {ATTENTION_CONTRACTORS, AVAILABLE_CONTRACTORS, MY_CONTRACTORS} from "../../../constants/link-params";

class ContractorResources extends Component {
    constructor(props) {
        super(props);

        const {match} = props;
        const {
            params: {
                type: tab,
                clientId,
            },
        } = match;

        this.state = {
            pageSize: 50,
            pageNum: 1,
            isOpenCommentsRetailModal: false,
            isOpenHistoryPaymentRetailModal: false,
            retail: {},
            isOpenFilter: true,
            contractorId: "",
            sortType: "LAST_PAYMENT_DESC",
            filter: {
                ...this.defaultFilter,
            },
            contractorRetailType: tab || "",
        };

        this.clientId = clientId || ls(CURRENT_CLIENT_ID);
        this.role = ls(USER_ROLE);
        this.isClientCard = !isNullOrWhitespace(clientId);
    }

    get defaultFilter() {
        return {
            contractorFioFilter: "",
            addressFilter: "",
            addressFiasId: "",
            contractorPhoneFilter: "",
            lastRequestTimeFromFilter: null,
            lastRequestTimeToFilter: null,
            lastPaymentTimeFromFilter: null,
            lastPaymentTimeToFilter: null,
            clientIdFilter: [],
            clientInterestedIdsFilter: [],
            statusFilter: [],
            clientStatusFilter: [],
            onOrderFilter: false,
            projectIdsFilter: [],
            objectIdsFilter: [],
        };
    }

    componentDidMount() {
        const {
            getRetailClientNameMap,
        } = this.props;

        getRetailClientNameMap();

        this.fetchList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            match: {
                params: {
                    type: prevTab,
                },
            },
        } = prevProps;
        const {
            match: {
                params: {
                    type: newTab,
                },
            },
        } = this.props;

        if (prevTab !== newTab) {
            this.fetchList();
        }
    }

    fetchList = () => {
        const {getContractorsRetailPage} = this.props;
        const {
            pageNum,
            pageSize,
            filter,
            sortType,
        } = this.state;

        const {
            contractorFioFilter,
            addressFiasId,
            contractorPhoneFilter,
            lastRequestTimeFromFilter,
            lastRequestTimeToFilter,
            lastPaymentTimeFromFilter,
            lastPaymentTimeToFilter,
            statusFilter,
            clientInterestedIdsFilter,
            clientIdFilter,
            clientStatusFilter,
            onOrderFilter,
            projectIdsFilter,
            objectIdsFilter,
        } = filter;

        const attentionContractors = this.contractorRetailType() === CONTRACTOR_RETAIL_TYPE.ATTENTION_CONTRACTORS;

        getContractorsRetailPage({
            pageNum,
            pageSize,
            sortType,
            contractorFioFilter,
            addressFiasIds: !isNullOrWhitespace(addressFiasId) ? [addressFiasId] : undefined,
            contractorPhoneFilter: removePhoneMask(contractorPhoneFilter),
            lastRequestTimeFromFilter: lastRequestTimeFromFilter ? convertUtcToLocal(lastRequestTimeFromFilter) : null,
            lastRequestTimeToFilter: lastRequestTimeToFilter ? convertUtcToLocal(lastRequestTimeToFilter) : null,
            lastPaymentTimeFromFilter: lastPaymentTimeFromFilter ? convertUtcToLocal(lastPaymentTimeFromFilter) : null,
            lastPaymentTimeToFilter: lastPaymentTimeToFilter ? convertUtcToLocal(lastPaymentTimeToFilter) : null,
            statusFilter,
            clientStatusFilter,
            clientInterestedIdsFilter,
            clientIdFilter: this.isShowClientColumn ? clientIdFilter : [this.clientId],
            contractorRetailType: (this.isClientCard && !attentionContractors) ? this.contractorRetailType() : undefined,
            attentionContractors: this.isClientCard && attentionContractors ? true : undefined,
            onOrderFilter,
            projectIdsFilter,
            objectIdsFilter,
            currentClientIdFilter: this.isClientCard ? this.clientId : undefined,
        });
    };

    get isNmUsers() {
        return [ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CONSULTANT,
            RNKO,
        ].includes(this.role);
    }

    contractorRetailType = () => {
        const {match} = this.props;
        const {params: {type: tab}} = match;

        if (tab === ATTENTION_CONTRACTORS) {
            return CONTRACTOR_RETAIL_TYPE.ATTENTION_CONTRACTORS;
        }

        if (tab === AVAILABLE_CONTRACTORS) {
            return CONTRACTOR_RETAIL_TYPE.AVAILABLE_CONTRACTORS;
        }

        return CONTRACTOR_RETAIL_TYPE.MY_CONTRACTORS;
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    mapPaymentHistory(item) {
        const {clientMonthHistoryList} = item;

        if (isEmpty(clientMonthHistoryList)) {
            return "-";
        }

        return (
            <div
                className="contractor-resources__history mr-2"
                onClick={() => {
                    this.toggleHistoryPaymentRetailModal(clientMonthHistoryList, true);
                }}
            >
                Просмотр
            </div>
        );
    }

    mapClientInterestedNames(item) {
        const {clientInterestedNames} = item;

        if (isEmpty(clientInterestedNames)) {
            return "-";
        }

        return (
            <NmSelectedList
                showedItemsCount={1}
                list={clientInterestedNames}
                showListWithoutValue
                dropdownWidth={250}
            />
        );
    }

    toggleCommentsRetailModal = (retail = {}, isOpenCommentsRetailModal = false) => {
        this.setState({
            retail,
            isOpenCommentsRetailModal,
        });
    };

    toggleHistoryPaymentRetailModal = (histories = [], isOpenHistoryPaymentRetailModal = false) => {
        this.setState({
            histories,
            isOpenHistoryPaymentRetailModal,
        });
    };

    handleChangeRetailVisibility = ({contractorId, hideForClient}) => {
        const {
            updateContractorRetail,
        } = this.props;

        return () => {
            updateContractorRetail({
                contractorId,
                hideForClient: !hideForClient,
                getSuccess: () => {
                    this.fetchList();
                },
            });
        };
    };

    get isAccessPage() {
        if ([ADMIN,
            NM_MANAGER,
            NM_CHIEF_ACCOUNTANT,
            NM_COORDINATOR,
            NM_CONSULTANT,
            RNKO,
            CLIENT_ADMIN,
        ].includes(this.role)) {
            return true;
        }

        const {
            clientSettings: {
                retailClientUserIds = [],
            },
            currentMember: {
                clientUserId,
            },
        } = this.props;

        return retailClientUserIds.includes(clientUserId);
    }

    renderClientStatusesList(clientStatusesList) {
        const {contractorStatusDict, contractorOrderStatus} = this.props;

        return clientStatusesList.map(item => {
            const {
                clientName,
                status,
                id,
            } = item;

            return (
                <div key={id}>
                    {`${contractorStatusDict[status] || contractorOrderStatus[status] || "Не определено"} ${clientName ? `- ${clientName}` : ""}`}
                </div>
            );
        });
    }

    renderStatuses(clientStatusesList) {
        if (isEmpty(clientStatusesList)) {
            return null;
        }

        return (
            <HelpTooltip
                type="light"
                position="bottom-left"
                info
                width={24}
                height={24}
            >
                {this.renderClientStatusesList(clientStatusesList)}
            </HelpTooltip>
        );
    }

    revoke(retail) {
        const {contractorId, clientInterestedIds} = retail;
        const {updateContractorRetail} = this.props;
        this.setLoadingByContractorId(contractorId);

        updateContractorRetail({
            ...retail,
            clientInterestedIds: clientInterestedIds.filter(_clientId => _clientId !== this.clientId),
            getSuccess: () => {
                this.setLoadingByContractorId();
                this.fetchList();
            },
        });
    }

    renderContentButton(retail) {
        const {
            clientInterestedIds = [],
        } = retail;

        if (clientInterestedIds.includes(this.clientId)) {
            return {
                text: "Отозвать",
                func: () => {
                    this.revoke(retail);
                },
            };
        }

        return {
            text: "Отметить",
            func: () => {
                this.makeMark(retail);
            },
        };
    }

    getListMediaControls(retail) {
        const {
            contractorId,
            hideForClient,
        } = retail;
        const {
            contractorId: stateContractorId,
        } = this.state;

        const {
            text,
            func,
        } = this.renderContentButton(retail);

        const contractorRetailType = this.contractorRetailType();

        return {
            renderCount: {
                desktop: 3,
                tablet: 3,
                mobile: 0,
            },
            size: "lg",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: func,
                        disabled: contractorId === stateContractorId,
                        color: "grey",
                        children: text,
                    },
                    visible: !this.isNmUsers && ![CONTRACTOR_RETAIL_TYPE.MY_CONTRACTORS, CONTRACTOR_RETAIL_TYPE.ATTENTION_CONTRACTORS].includes(contractorRetailType),
                },
                {
                    component: COMPONENT.HORIZONTAL_TOGGLE,
                    props: {
                        checked: hideForClient,
                        single: true,
                        duplex: false,
                        onChange: this.handleChangeRetailVisibility(retail),
                        name: "orderMatchOnly",
                    },
                    visible: !this.isNmUsers && [CONTRACTOR_RETAIL_TYPE.MY_CONTRACTORS].includes(contractorRetailType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onClick: () => {
                            this.toggleCommentsRetailModal(retail, true);
                        },
                        onlyIcon: true,
                        icon: <ChatIcon />,
                    },
                    asset: {
                        mobile: "Посмотреть комментарии",
                    },
                },
            ],
        };
    }

    get isShowClientColumn() {
        return ([CONTRACTOR_RETAIL_TYPE.AVAILABLE_CONTRACTORS].includes(this.contractorRetailType()) || (this.isNmUsers && !this.isClientCard));
    }

    getRows() {
        const {list} = this.props;

        return list.map(item => {
            const {
                contractorFio,
                contractorPhone,
                orderAddress,
                lastRequestTime,
                lastPaymentTime,
                projectName,
                objectName,
                subcategoryName,
                clientStatusesList,
                status,
                clientName,
                brand,
            } = item;

            const isShowBacklight = ![CONTRACTOR_RETAIL_TYPE.ATTENTION_CONTRACTORS, CONTRACTOR_RETAIL_TYPE.AVAILABLE_CONTRACTORS].includes(this.contractorRetailType());
            const _clientName = brand ? `${clientName} (${brand})` : clientName;
            const labelUR = this.isShowClientColumn ? [{label: "Юр. лицо", text: _clientName}] : [];

            return {
                ...item,
                key: item.contractorId,
                // категорию(блок) проименовали на бэке как status и значения у него 1,2,3
                error: status === 1 && isShowBacklight,
                warning: status === 2 && isShowBacklight,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16 col-xxl-12"
                        primaryHeader={contractorFio}
                        primaryHeaderNoWrap
                        primaryHeaderTooltip={this.renderStatuses(clientStatusesList)}
                        labels={[
                            ...labelUR,
                            {label: "Номер телефона", text: phoneFormat(contractorPhone) || "-"},
                            {label: "Адрес", text: orderAddress || "-"},
                            {label: "Проект", text: projectName || "-"},
                            {label: "Объект", text: objectName || "-"},
                            {label: "Дата последней заявки", text: dateFormat(lastRequestTime, "dd.MM.yyyy") || "-"},
                            {label: "Дата последней оплаты", text: dateFormat(lastPaymentTime, "dd.MM.yyyy") || "-"},
                            {label: "История выплат", text: this.mapPaymentHistory(item)},
                            {
                                label: "Вид деятельности последнего заказа",
                                columnOnMobile: true,
                                text: subcategoryName || "-",
                            },
                            {
                                label: "Заинтересованные компании",
                                columnOnMobile: true,
                                text: this.mapClientInterestedNames(item),
                            },
                        ]}
                        isFixedActions
                        mediaControls={this.getListMediaControls(item)}
                    />
                ),
            };
        });
    }

    setLoadingByContractorId(contractorId = "") {
        this.setState({contractorId});
    }

    makeMark(retail) {
        const {updateContractorRetail} = this.props;
        this.setLoadingByContractorId(retail.contractorId);

        updateContractorRetail({
            ...retail,
            clientInterestedIds: [
                ...retail.clientInterestedIds,
                this.clientId,
            ],
            getSuccess: () => {
                this.setLoadingByContractorId();
                this.fetchList();
            },
        });
    }

    updateComments = (text) => {
        const {updateContractorRetail} = this.props;
        const {retail} = this.state;

        updateContractorRetail({
            ...retail,
            commentList: [
                ...retail.commentList,
                {
                    clientId: this.clientId,
                    comment: text,
                },
            ],
            getSuccess: () => {
                this.toggleCommentsRetailModal();
                this.fetchList();
            },
        });
    };

    handleChangeFilter = () => {
        const {
            isOpenFilter,
        } = this.state;

        this.setState({
            isOpenFilter: !isOpenFilter,
        });
    };

    sendFilter = (filter) => {
        this.setState({
            pageNum: 1,
            filter: {
                ...filter,
            },
        }, () => {
            this.fetchList();
        });
    };

    exportDocuments = () => {
        const {retailExport} = this.props;

        const {
            filter,
            sortType,
        } = this.state;

        const {
            contractorFioFilter,
            addressFiasId,
            contractorPhoneFilter,
            lastRequestTimeFromFilter,
            lastRequestTimeToFilter,
            lastPaymentTimeFromFilter,
            lastPaymentTimeToFilter,
            statusFilter,
            clientInterestedIdsFilter,
            clientIdFilter,
            clientStatusFilter,
            onOrderFilter,
            projectIdsFilter,
            objectIdsFilter,
        } = filter;

        const attentionContractors = this.contractorRetailType() === CONTRACTOR_RETAIL_TYPE.ATTENTION_CONTRACTORS;

        retailExport({
            sortType,
            contractorFioFilter,
            addressFiasIds: !isNullOrWhitespace(addressFiasId) ? [addressFiasId] : undefined,
            contractorPhoneFilter: removePhoneMask(contractorPhoneFilter),
            lastRequestTimeFromFilter: lastRequestTimeFromFilter ? convertUtcToLocal(lastRequestTimeFromFilter) : null,
            lastRequestTimeToFilter: lastRequestTimeToFilter ? convertUtcToLocal(lastRequestTimeToFilter) : null,
            lastPaymentTimeFromFilter: lastPaymentTimeFromFilter ? convertUtcToLocal(lastPaymentTimeFromFilter) : null,
            lastPaymentTimeToFilter: lastPaymentTimeToFilter ? convertUtcToLocal(lastPaymentTimeToFilter) : null,
            statusFilter,
            clientStatusFilter,
            clientInterestedIdsFilter,
            clientIdFilter: this.isShowClientColumn ? clientIdFilter : [this.clientId],
            contractorRetailType: (this.isClientCard && !attentionContractors) ? this.contractorRetailType() : undefined,
            attentionContractors: this.isClientCard && attentionContractors ? true : undefined,
            onOrderFilter,
            naimixUser: this.isNmUsers,
            projectIdsFilter,
            objectIdsFilter,
            currentClientIdFilter: this.isClientCard ? this.clientId : undefined,
        });
    };

    retailStartJob = () => {
        const {retailStartJob} = this.props;

        retailStartJob();
    };

    getTabs = () => {
        const {match} = this.props;
        const {params: {type: tab}} = match;

        const link = LINK_CLIENT_CONTRACTORS_RESOURCES;

        const linkMyContractors = link.replace(":clientId", this.clientId).replace(":type", MY_CONTRACTORS);
        const linkAvailableContractors = link.replace(":clientId", this.clientId).replace(":type", AVAILABLE_CONTRACTORS);
        const linkAttentionContractors = link.replace(":clientId", this.clientId).replace(":type", ATTENTION_CONTRACTORS);

        return [
            {
                name: "Мои исполнители",
                link: linkMyContractors,
                active: tab === MY_CONTRACTORS,
            },
            {
                name: "Обратите внимание",
                link: linkAttentionContractors,
                active: tab === ATTENTION_CONTRACTORS,
            },
            {
                name: "Исполнители для найма",
                link: linkAvailableContractors,
                active: tab === AVAILABLE_CONTRACTORS,
            },
        ];
    };

    getMediaControls() {
        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            size: "xl",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "green",
                        children: "Запустить анализ",
                        onClick: this.retailStartJob,
                    },
                    visible: [ADMIN].includes(this.role),
                },
                {
                    component: COMPONENT.BUTTON_WITH_TOOLTIP,
                    props: {
                        color: "green",
                        children: "Выгрузить",
                        onClick: this.exportDocuments,
                        tooltip: (
                            <HelpTooltip
                                type="light"
                                width={24}
                                height={24}
                                info
                                text="Выгрузка документов будет выполнена в соответствии с заданными фильтрами"
                                position="bottom-right"
                            />
                        ),
                    },
                },
            ],
        };
    }

    renderSecondaryTabs() {
        if (!this.isClientCard) {
            return null;
        }

        return (
            <Tabs
                secondary
                className="mb-4"
                panes={this.getTabs()}
                //handleRefreshCounters={this.contractorRetailTypeChange}
            />
        );
    }

    renderPrimaryTabs() {
        const {
            pathname,
            clientSettings: {
                retailClient,
            },
        } = this.props;

        if (!this.isClientCard) {
            return null;
        }

        const panes = NmContractorsTabs({
            clientId: this.clientId,
            pathname,
            role: this.role,
            retailClient,
        });

        return (
            <Tabs
                {...this.props}
                className="mb-4 mb-xxl-5"
                panes={panes}
            />
        );
    };

    onClickSort = ({sortType}) => {
        this.setState({
            sortType,
        }, this.fetchList);
    };

    getSortOptions() {
        const {sortType} = this.state;

        return [
            {
                key: "MONTH_HISTORY",
                value: "MONTH_HISTORY",
                text: "По количеству месяцев с платежами",
                sortType,
                asc: "MONTH_HISTORY_ASC",
                desc: "MONTH_HISTORY_DESC",
            },
            {
                key: "FIO",
                value: "FIO",
                text: "По ФИО",
                sortType,
                asc: "FIO_ASC",
                desc: "FIO_DESC",
            },
            {
                key: "LAST_REQUEST",
                value: "LAST_REQUEST",
                text: "По дате последней заявки",
                sortType,
                asc: "LAST_REQUEST_ASC",
                desc: "LAST_REQUEST_DESC",
            },
            {
                key: "LAST_PAYMENT",
                value: "LAST_PAYMENT",
                text: "По дате последней оплаты",
                sortType,
                asc: "LAST_PAYMENT_ASC",
                desc: "LAST_PAYMENT_DESC",
                isDefaultSort: true,
            },
        ];
    }

    render() {
        const {
            pageSize,
            pageNum,
            retail,
            isOpenCommentsRetailModal,
            isOpenHistoryPaymentRetailModal,
            isOpenFilter,
            histories,
        } = this.state;

        const {
            totalPages,
            list,
            updatingProgress,
            progress,
        } = this.props;

        if (!this.isAccessPage) {
            return (
                <div className="access-message ml-15">
                    Раздел недоступен для данного пользователя
                </div>
            );
        }

        return (
            <NmPage
                header={
                    <NmTitle
                        size="xl"
                        children="Ритэйл"
                    />
                }
                mediaControls={this.getMediaControls()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                isLoaded={updatingProgress || progress}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                widthByFilter
                openFilter={isOpenFilter}
                onOpenFilter={this.handleChangeFilter}
                filtersBase={
                    <ContractorResourcesFilter
                        clientId={this.isClientCard ? this.clientId : undefined}
                        sendFilter={this.sendFilter}
                        defaultFilter={{...this.defaultFilter}}
                        isShowClientColumn={this.isShowClientColumn}
                    />
                }
                typeFilter="vertical"
            >
                {isOpenHistoryPaymentRetailModal && (
                    <HistoryPaymentRetailModal
                        close={this.toggleHistoryPaymentRetailModal}
                        histories={histories}
                    />
                )}
                {isOpenCommentsRetailModal && (
                    <CommentsRetailModal
                        submit={this.updateComments}
                        retail={retail}
                        close={this.toggleCommentsRetailModal}
                    />
                )}
                {this.renderSecondaryTabs()}
                {
                    <Text
                        className="mb-4"
                        level="2"
                    >
                        <NmShowMoreText
                            isLoaded={!updatingProgress}
                            anchor="green"
                            lines={3}
                            children={RESOURCES_TYPE_SUGGESTION[this.contractorRetailType()]}
                            more="Читать полностью"
                        />
                    </Text>
                }
                {
                    list?.length
                        ? (
                            <CheckboxList
                                sort
                                sortOptions={this.getSortOptions()}
                                onClickSort={this.onClickSort}
                                rows={this.getRows()}
                            />
                        )
                        : (
                            <NmEmptyPageV2
                                fetchProgress={progress}
                            />
                        )
                }
            </NmPage>
        );
    }
}

export default connect(state => ({
    totalPages: contractorsRetailTotalPagesSelector(state),
    list: contractorsRetailPageSelector(state),
    progress: contractorsRetailProgressSelector(state),
    contractorStatusDict: contractorStatusDictSelector(state),
    contractorOrderStatus: contractorStatusSelector(state),
    currentMember: clientCurrentMemberSelector(state),
    clientSettings: getClientPropertiesCardSelector(state),
    clientAllOptions: clientNameIdMapSelector(state),
    updatingProgress: contractorActionProgressSelector(state),
    totalCount: contractorsRetailTotalCountSelector(state),
}),
{
    getContractorsRetailPage,
    updateContractorRetail,
    getRetailClientNameMap,
    retailExport,
    retailStartJob,
},
)(withTranslation()(ContractorResources));

