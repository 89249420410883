import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import {
    clearEventsContractorAggregatedParamsBySmartLink,
    getEventsContractorAggregatedParamsBySmartLink,
} from "../../../../../ducks/events/contractor/actionCreators";
import {
    eventsContractorAggregatedParamsBySmartLink,
    eventsContractorAggregatedParamsBySmartLinkProgress,
} from "../../../../../ducks/events/contractor/selectors";

interface IProps {
    onCloseModal: () => void;
    smartLinkId: string;
    smartLinkName: string;
}

export const SmartLinkAnalytics: FC<IProps> = (props) => {
    const {
        onCloseModal,
        smartLinkId,
        smartLinkName,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(eventsContractorAggregatedParamsBySmartLinkProgress);
    const params = useSelector(eventsContractorAggregatedParamsBySmartLink);

    useEffect(() => {
        dispatch(getEventsContractorAggregatedParamsBySmartLink({smartLinkId}));

        return () => {
            dispatch(clearEventsContractorAggregatedParamsBySmartLink());
        };
    }, []);

    return (
        <NmModal
            loading={progress}
            size="md"
            onClose={onCloseModal}
            header={
                <NmTitle size="lg">
                    {`Аналитика по ссылке «${smartLinkName}»`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Понятно"
                    submit={onCloseModal}
                />
            }
            classNameContent="flex-column"
        >
            <NmLabelText
                label="Просмотрено из рассылки"
                text={params?.notificationViewsMailing || 0}
                className="mb-1 mb-md-2"
            />
            <NmLabelText
                label="Просмотрено по прямой ссылке"
                text={params?.notificationViewsDirect || 0}
                className="mb-1 mb-md-2"
            />
            <NmLabelText
                label="Просмотрено анонимно"
                text={params?.notificationViewsAnonymous || 0}
                className="mb-1 mb-md-2"
            />
            <NmLabelText
                label="Переход на заказ"
                text={params?.orderClicks || 0}
                className="mb-1 mb-md-2"
            />
            <NmLabelText
                label="Переход на заказ анонимно"
                text={params?.orderClicksAnonymous || 0}
                className="mb-1 mb-md-2"
            />
            <NmLabelText
                label="Отклик"
                text={params?.orderResponses || 0}
            />
        </NmModal>
    );
};