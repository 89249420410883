import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {FILTER} from "../../ActualComponents/Filter";

import {useFilter} from "../../../hooks/useFilter";

export const useFinancePivotFilter = (params) => {
    const {
        pageSize,
        setPagination,
        from,
        to,
        clientId,
    } = params;
    const {t} = useTranslation();
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter: {
            clientIdFilter: clientId || null,
            toDateFilter: to || null,
            fromDateFilter: from || null,
        },
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        name: "clientIdFilter",
                        isClearable: true,
                        label: t("finance-pivot-table.customer"),
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "fromDateFilter",
                        endFieldName: "toDateFilter",
                        isClearable: true,
                        label: "Период",
                    },
                ],
            },
        ];
    }, []);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};