import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_ERROR,
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_REQUEST,
    BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

//POST /bff/adm/plutonium/transaction-log/page
const getPagePlutoniumTransactionLog = function* ({payload}) {
    try {
        const {
            url,
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_PLUTONIUM_TRANSACTION_LOG_GET_PAGE_REQUEST, getPagePlutoniumTransactionLog),
    ]);
}