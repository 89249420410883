import React, {CSSProperties, FC, ReactNode} from "react";

import {ReactComponent as BackIcon} from "../../../images/arrow_back_24.svg";
import {ReactComponent as CloseIcon} from "../../../images/close_24.svg";
import NmLoader from "../../NmLoader";
import NmModalPrompt from "../../NmModalPrompt";
import Portal from "../../Portal";
import StepsNumberProgress from "../StepsNumberProgess";
import Text from "../Text";

import bem from "../../../utils/bem";

import {COLOR} from "../../../constants/color";

import "./style.sass";

interface ILayer {
    children: ReactNode,
    zIndex: number,
    isHiddenModal?: boolean,
    className?: string,
    onClick?: () => void,
}

export const Layer: FC<ILayer> = (props) => {
    const {
        children,
        zIndex,
        onClick,
        isHiddenModal,
        className = "",
    } = props;
    const parent = document.body;
    const [block] = bem("bg-modal", className);

    return (
        <Portal node={parent}>
            <div
                onClick={onClick}
                style={{zIndex}}
                className={block({hidden: isHiddenModal})}
            >
                {children}
            </div>
        </Portal>
    );
};

export type TModalContentAlign = "left" | "center" | "right";
export type TModalSize = "sm" | "md" | "lg";

interface INmModal {
    header?: ReactNode,
    footer?: ReactNode,
    children?: ReactNode,
    onClose: (event?: any) => void,
    className?: string,
    classNameContent?: string,
    additionElement?: ReactNode,
    lsKeyEditPrompt?: string,
    isHiddenModal?: boolean,
    zIndex?: number,
    onChangePrompt?: () => void,
    classNameLayer?: string,
    loaderContent?: string,
    loaderInverted?: boolean,
    dimmerClassName?: string,
    loading?: boolean,
    classNameHeader?: string,
    classNameFooter?: string,
    align?: string,
    contentAlign?: TModalContentAlign,
    size?: TModalSize,
    goBack?: () => void,
    openNaimix?: boolean,
    contentBreakSpaces?: boolean,
    totalCountSteps?: number,
    currentStepNumber?: number,
    classNameBackIcon?: string,
    isShowStepsNumberProgress?: boolean,
    isShowBackButton?: boolean,
    isVisibleCloseButton?: boolean,
    style?: CSSProperties,
    warningText?: string,
    fixedHeight?: boolean,
    isBackIconAlwaysVisible?: boolean,
    isAutoSize?: boolean,
    searchContent?: ReactNode,
}

const NmModal: FC<INmModal> = (props) => {
    const {
        style,
        header,
        footer,
        openNaimix,
        children,
        onClose,
        className,
        zIndex = 1000,
        lsKeyEditPrompt,
        onChangePrompt,
        classNameContent = "",
        classNameLayer,
        loaderContent = "",
        loaderInverted = true,
        dimmerClassName,
        loading,
        classNameHeader = "",
        classNameFooter = "",
        isHiddenModal,
        align = "center",
        additionElement,
        size,
        contentAlign,
        contentBreakSpaces,
        goBack,
        totalCountSteps,
        currentStepNumber,
        classNameBackIcon = "",
        isShowStepsNumberProgress,
        isShowBackButton,
        isBackIconAlwaysVisible,
        isVisibleCloseButton = true,
        warningText,
        fixedHeight = false,
        isAutoSize,
        searchContent,
    } = props;

    const [block, element] = bem("nm-modal-v2", className);

    return (
        <Layer
            className={classNameLayer}
            zIndex={zIndex}
            isHiddenModal={isHiddenModal}
        >
            <div
                className={block({loading, align, size, openNaimix})}
                style={style}
            >
                {
                    isShowStepsNumberProgress &&
                    typeof totalCountSteps === "number" &&
                    typeof currentStepNumber === "number" &&
                    <div className={element("steps-progress")}>
                        <StepsNumberProgress
                            currentStepNumber={currentStepNumber}
                            totalCount={totalCountSteps}
                            className="mb-5"
                        />
                    </div>
                }
                {
                    goBack && isShowBackButton &&
                    <BackIcon
                        className={`${element("back-icon", {visible: isBackIconAlwaysVisible})} ${classNameBackIcon}`}
                        onClick={goBack}
                    />
                }
                {
                    lsKeyEditPrompt &&
                    <NmModalPrompt
                        lsKeyEditPrompt={lsKeyEditPrompt}
                        className={element("prompt")}
                        onChange={onChangePrompt}
                    />
                }
                {
                    isVisibleCloseButton &&
                    <CloseIcon
                        className={element("close", {openNaimix})}
                        onClick={onClose}
                    />
                }
                {
                    header &&
                    <div className={`${element("header", {noBody: !children, openNaimix})} ${classNameHeader}`}>
                        {header}
                    </div>
                }
                {
                    searchContent &&
                    <div className={element("search-content")}>
                        {searchContent}
                    </div>
                }
                {
                    warningText &&
                    <Text
                        level="4"
                        className={element("warning")}
                    >
                        <Text
                            inline
                            level="4"
                            color={COLOR.INERT_100}
                        >
                            Внимание!&nbsp;
                        </Text>
                        {warningText}
                    </Text>
                }
                <div
                    className={`${element("content", {
                        align: contentAlign, 
                        breakSpaces: contentBreakSpaces,
                        fixedHeight,
                        autoSize: isAutoSize,
                        search: Boolean(searchContent),
                        footer: Boolean(footer),
                    })} ${classNameContent}`}
                >
                    {children}
                </div>
                {
                    footer &&
                    <div className={`${element("footer")} ${classNameFooter}`}>
                        {footer}
                    </div>
                }
                {
                    <NmLoader
                        zIndex={zIndex + 1}
                        classNameDimmer={`${element("dimmer")} ${dimmerClassName}`}
                        content={loaderContent}
                        active={loading}
                        inverted={loaderInverted}
                    />
                }
            </div>
            {
                additionElement &&
                <div className={element("additionElement")}>
                    {additionElement}
                </div>
            }
        </Layer>
    );
};

export default NmModal;