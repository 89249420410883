import React, {ReactNode, useMemo, useState} from "react";

import {ReactComponent as IconChevron} from "../../../images/chevron-down.svg";
import SortElement from "../../SortElement";
import NmCheckboxV2, {TNmCheckboxParams} from "../NmCheckboxV2";
import TagSelect, {DeleteParamsType} from "../TagSelect";
import Text from "../Text";

import {OptionType} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";
import {getClassNames} from "../../../utils/classNames";

import "./style.sass";

export interface TListItem {
    isSelected?: boolean,
    isChanged?: boolean,
    isShowChevronButton?: boolean,
    value?: string,
    name?: string | ReactNode,
    content?: string | ReactNode,
    additionalHeaderContent?: string | ReactNode,
    showCheckBox?: boolean,
    disabledCheckBox?: boolean,
    [key: string]: any,
}

interface ICheckboxAccordionList<T> {
    list: Array<T>,
    onSelectedRows?: (list: T[], index?: number) => void,
    isShowAllCheckbox?: boolean,
    isModalSortSize?: boolean,
    sort?: boolean
    sortOptions?: Array<OptionType> | [],
    onClickSort?: (option: OptionType) => void,
    classNameHeaderName?: string,
    allCheckboxLabel?: string | ReactNode,
    className?: string,
    classNameContentContainer?: string,
    isRowPositionRelative?: boolean,
    isTagSelect?: boolean,
    selectedList?: Array<T>,
    setSelectedList?: (selectedList: Array<T>) => void,
    onChange?: (selectedList: Array<T>) => void,
}

interface OnSelectRowParams<T> {
    row: T,
    checked: boolean,
    index: number,
}

const CheckboxAccordionList = <T extends TListItem>(props: ICheckboxAccordionList<T>) => {
    const {
        list,
        isModalSortSize,
        onSelectedRows,
        isShowAllCheckbox = true,
        sort = false,
        sortOptions = [],
        onClickSort = () => {},
        classNameHeaderName = "",
        allCheckboxLabel = "Выбрать все",
        className,
        classNameContentContainer,
        isRowPositionRelative = false,
        isTagSelect,
        setSelectedList,
        selectedList,
    } = props;
    const [block, element] = bem("checkbox-accordion-list", className);
    const [openIndex, setOpenIndex] = useState(-1);

    const selectedIds = useMemo(() => {
        if (selectedList) {
            return selectedList.filter(item => item.isSelected).map(item => item.value);
        }

        return null;
    }, [
        selectedList,
    ]);

    const onSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>, {checked}: TNmCheckboxParams) => {
        event.stopPropagation();

        const _rows = list.map(item => {
            const isSelected = item.showCheckBox !== undefined && item.showCheckBox && checked && !item.disabledCheckBox;

            return {
                ...item,
                isSelected,
                isChanged: true,
            };
        });

        if (setSelectedList) {
            setSelectedList(_rows);

            return;
        }

        if (onSelectedRows) {
            return onSelectedRows(_rows);
        }
    };

    const onSelectRow = (_event: React.ChangeEvent<HTMLInputElement>, params: OnSelectRowParams<T>) => {
        const {
            row,
            checked,
            index,
        } = params;

        if (
            setSelectedList
            && selectedList
        ) {
            if (checked) {
                const list = [
                    ...selectedList,
                    {
                        ...row,
                        isSelected: true,
                        isChanged: true,
                    },
                ];

                setSelectedList(list as Array<T>);

                return;
            }

            const list = selectedList
                .map(item => {
                    if (item.value === row.value) {
                        return {
                            ...item,
                            isSelected: false,
                            isChanged: true,
                        };
                    }

                    return item;
                });

            setSelectedList(list as Array<T>);

            return;
        }

        const _list = [...list];

        _list[index]["isSelected"] = checked;

        if (onSelectedRows) {
            return onSelectedRows(_list, index);
        }
    };

    const getDisabledHeaderCheckbox = () => {
        const length = list.filter(value => value.disabledCheckBox).length;

        return length === list.length;
    };

    const getAllCheckboxValue = () => {
        const shownCheckboxCount = list.filter(value => value.showCheckBox && !value.disabledCheckBox).length;
        const selectedCheckBoxCount = list.filter(value => value.isSelected).length;

        return shownCheckboxCount !== 0 && shownCheckboxCount === selectedCheckBoxCount;
    };

    const toggleChevron = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(-1);

            return;
        }

        setOpenIndex(index);
    };

    return (
        <div className={block()}>
            {
                sort &&
                <SortElement
                    isModalSortSize={isModalSortSize}
                    className="checkbox-list__sort"
                    options={sortOptions}
                    onClickItem={onClickSort}
                />
            }
            {
                isShowAllCheckbox &&
                <div className={element("row")}>
                    <NmCheckboxV2
                        label={allCheckboxLabel}
                        disabled={getDisabledHeaderCheckbox()}
                        checked={getAllCheckboxValue()}
                        onChange={onSelectAllRows}
                    />
                </div>
            }
            {
                isTagSelect && selectedList && setSelectedList &&
                <TagSelect
                    list={selectedList}
                    className="checkbox-accordion-list__tag-select"
                    onClickDelete={(data: DeleteParamsType) => {
                        if (data.list) {
                            setSelectedList(data.list as Array<T>);
                        }
                    }}
                />
            }
            <div
                className={getClassNames([
                    "checkbox-accordion-list__content-container",
                    classNameContentContainer,
                ])}
            >
                {
                    list.map((row, index) => {
                        const {
                            isSelected,
                            name,
                            content,
                            additionalHeaderContent,
                            isShowChevronButton = true,
                            showCheckBox,
                            disabledCheckBox,
                        } = row;

                        const isOpen = openIndex === index;

                        return (
                            <div
                                key={index}
                                className={element("row", {active: isOpen, positionRelative: isRowPositionRelative})}
                            >
                                <div className={element("header")}>
                                    {
                                        showCheckBox &&
                                        <NmCheckboxV2
                                            onChange={(event, {checked}) => {
                                                onSelectRow(
                                                    event,
                                                    {
                                                        row,
                                                        checked,
                                                        index,
                                                    },
                                                );
                                            }}
                                            disabled={disabledCheckBox}
                                            className={element("checkbox")}
                                            checked={
                                                selectedIds
                                                    ? selectedIds.includes(row.value)
                                                    : isSelected
                                            }
                                        />
                                    }
                                    <Text
                                        level="3"
                                        className={`${element("name", {cropped: !isOpen})} ${classNameHeaderName}`}
                                    >
                                        {name}
                                    </Text>
                                    {additionalHeaderContent}
                                    {
                                        isShowChevronButton &&
                                        <IconChevron
                                            onClick={() => {
                                                toggleChevron(index);
                                            }}
                                            className={element("chevron", {open: isOpen})}
                                        />
                                    }
                                </div>
                                {
                                    isOpen &&
                                    <Text
                                        level="2"
                                        className={element("content")}
                                    >
                                        {content}
                                    </Text>
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default CheckboxAccordionList;