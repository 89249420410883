import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import Text from "../../../../../components/ActualComponents/Text";
import ContractorVerificationDataGeneralFields from "../general-fields";
import ContractorVerificationInputContainer from "../input-container";
import ContractorVerificationRowContainer from "../row-container";
import ContractorVerificationTextFields from "../text-fields";

import {COLOR} from "../../../../../constants/color";
import {PERSONAL_DATA_BASED_ID_DOC_STATUS} from "../../../../../constants/status";

import {getCitizenshipSelector, getPassportStatus} from "../../../../../ducks/contractor";

const ContractorVerificationScanPersonalInfo = (props) => {
    const {
        localizationData,
        values,
        handleFocus,
        handleBlur,
        getChanged,
        getPreviousValue,
        setInnStatus,
        setFieldValue,
        errors,
        fieldWarnings,
    } = props;
    const [passportNumberError, setPassportNumberError] = useState("");

    const dispatch = useDispatch();
    const citizenshipDict = useSelector(getCitizenshipSelector);

    const handleChange = (event, {value, name}) => {
        if (["idDocNumber"].includes(name)) {
            setPassportNumberError("");
        }

        props.handleChange(event, {value, name});
    };

    useEffect(() => {
        handleCheckPassport();
    }, []);

    const handleCheckPassport = () => {
        dispatch(
            getPassportStatus({
                ...values,
                onSuccess: status => {
                    if (status && status === PERSONAL_DATA_BASED_ID_DOC_STATUS.NOT_CONFIRMED) {
                        setPassportNumberError("Паспорт недействительный в соответствии с открытыми данными МВД России");

                        return;
                    }

                    setPassportNumberError("");
                },
            }),
        );
    };

    return (
        <>
            <div className="d-flex flex-column flex-md-row mb-4">
                <ContractorVerificationTextFields>
                    <Text
                        level="4"
                        color={COLOR.SECONDARY_70}
                        className="me-1"
                    >
                        {localizationData.citizenship}
:
                    </Text>
                    <Text.Title
                        level="4"
                        color={COLOR.SECONDARY_90}
                    >
                        {citizenshipDict[values.citizenship]}
                    </Text.Title>
                </ContractorVerificationTextFields>
                <ContractorVerificationTextFields>
                    <Text
                        level="4"
                        color={COLOR.SECONDARY_70}
                        className="me-1"
                    >
                        {localizationData.inn}
:
                    </Text>
                    <Text.Title
                        level="4"
                        color={COLOR.SECONDARY_90}
                    >
                        {values.inn}
                    </Text.Title>
                </ContractorVerificationTextFields>
            </div>
            <ContractorVerificationRowContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("idDocNumber")}
                        mask="9999 999999"
                        maskChar={null}
                        name="idDocNumber"
                        label="Серия и номер паспорта"
                        value={values.idDocNumber}
                        onBlur={() => {
                            handleBlur();
                            handleCheckPassport();
                        }}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        required
                        tooltip={getPreviousValue("idDocNumber")}
                        error={errors && errors.idDocNumber || passportNumberError}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmDatePicker
                        size="xl"
                        dateFormatMask="99.99.9999"
                        changed={getChanged("idDocIssuedDate")}
                        name="idDocIssuedDate"
                        label={localizationData.idDocIssuedDate}
                        maxDate={new Date()}
                        required
                        selected={values.idDocIssuedDate}
                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        error={errors && errors.idDocIssuedDate}
                        warning={fieldWarnings.idDocIssuedDate}
                        tooltip={getPreviousValue("idDocIssuedDate")}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("idDocIssuedByDepartmentCode")}
                        name="idDocIssuedByDepartmentCode"
                        label={localizationData.idDocIssuedDepartmentCode}
                        value={values.idDocIssuedByDepartmentCode}
                        maskChar={null}
                        mask="999-999"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        required
                        error={errors && errors.idDocIssuedByDepartmentCode}
                        tooltip={getPreviousValue("idDocIssuedByDepartmentCode")}
                    />
                </ContractorVerificationInputContainer>
            </ContractorVerificationRowContainer>
            <ContractorVerificationRowContainer>
                <ContractorVerificationInputContainer full>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("idDocIssuedBy")}
                        name="idDocIssuedBy"
                        required
                        label={localizationData.idDocIssuedBy}
                        value={values.idDocIssuedBy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        tooltip={getPreviousValue("idDocIssuedBy")}
                        error={errors && errors.idDocIssuedBy}
                    />
                </ContractorVerificationInputContainer>
            </ContractorVerificationRowContainer>
            <ContractorVerificationDataGeneralFields
                localizationData={localizationData}
                getChanged={getChanged}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                getPreviousValue={getPreviousValue}
                errors={errors}
                setInnStatus={setInnStatus}
                setFieldValue={setFieldValue}
                isBirthPlace
            />
        </>
    );
};

export default ContractorVerificationScanPersonalInfo;