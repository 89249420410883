import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        name: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};

export default validationSchema;