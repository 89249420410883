import React from "react";

import {LinkMarkdownTag} from "../components/Tag";

import {REG_EXP} from "../../../constants/regExp";

export function urlify(text: string) {
    const urlRegex = new RegExp(REG_EXP.URL, "g");

    return text
        .split(urlRegex)
        .map(part => {
            if (part.match(urlRegex)) {
                return (
                    <LinkMarkdownTag
                        href={part}
                        children={part}
                    />
                );
            }

            return part;
        });
}