import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../components/ActualComponents/Filter";
import {MediaButtons} from "../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import Text from "../../../components/ActualComponents/Text";
import Avatar from "../../../components/Avatar";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {history} from "../../../store/configureStore";
import {ClientAssignBrands} from "./components/assign-brands";
import {ClientBrandsEdit} from "./components/edit";
import {ClientBrandLogs} from "./components/logs";
import {CLIENT_BRAND_ACTION_ERROR_CODE, CLIENT_BRAND_TOOLTIP_TEXT} from "./contants";

import {useFilter} from "../../../hooks/useFilter";
import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useClientBrandsFetch} from "./hooks/useFetch";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {getUserRole} from "../../../utils/access";
import {handleFormString} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_INFO} from "../../../constants/links";
import {ADMIN, CLIENT_ACCOUNTANT, CLIENT_ADMIN, NM_MANAGER} from "../../../constants/roles";

import {
    clientBrandsListSelector,
    clientBrandsProgressSelector,
    clientBrandsTotalCountSelector,
    clientBrandsTotalPagesSelector,
} from "../../../ducks/client/brands/selectors";
import {getClientPropertiesById} from "../../../ducks/clientProperties";

export const ClientBrands = (props) => {
    const clientId = props.match.params.clientId;
    const list = useSelector(clientBrandsListSelector);
    const totalCount = useSelector(clientBrandsTotalCountSelector);
    const totalPages = useSelector(clientBrandsTotalPagesSelector);
    const progress = useSelector(clientBrandsProgressSelector);

    const [archived, setArchived] = useState(false);
    const [logos, setLogos] = useState({});

    const role = getUserRole();

    const dispatch = useDispatch();

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination(
        "nm-page",
    );

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
        mapFilterDto: (filters) => {
            return {
                ...filters,
                brandNameFilter: handleFormString(filters.brandNameFilter),
            };
        },
    });

    const {
        fetchList,
        methods,
    } = useClientBrandsFetch({
        pageNum,
        pageSize,
        filterData,
        clientId,
        archived,
    });

    useEffect(() => {
        if (isEmpty(list)) {
            return;
        }

        list.forEach(item => {
            const {
                isLogo,
                urlLogoThumbnail,
                brandId,
            } = item;

            if (logos[brandId]?.thumbnail) {
                return;
            }

            updateLogos({
                brandId,
                isLogo,
                urlLogoThumbnail,
                isThumbnail: true,
            });
        });
    }, [list]);

    const checkError = ({errorCode}) => {
        if (
            [
                CLIENT_BRAND_ACTION_ERROR_CODE.NOT_RIGHTS_APP_BRAND,
                CLIENT_BRAND_ACTION_ERROR_CODE.NOT_RIGHTS_ARCHIVE_BRAND,
                CLIENT_BRAND_ACTION_ERROR_CODE.NOT_RIGHTS_RECOVER_ARCHIVE_BRAND,
                CLIENT_BRAND_ACTION_ERROR_CODE.NOT_RIGHTS_RECOVER_ARCHIVE_BRAND,
                CLIENT_BRAND_ACTION_ERROR_CODE.NOT_RIGHTS_ADD_BRAND,
                CLIENT_BRAND_ACTION_ERROR_CODE.NOT_RIGHTS_UPDATE_BRAND,
            ].includes(errorCode)
        ) {
            onCloseModal();
            history.push(LINK_CLIENT_INFO.replace(":clientId", clientId));
            dispatch(getClientPropertiesById({clientId}));
        }
    };

    const updateLogos = (item) => {
        const {
            isClearLogo,
            isThumbnail,
            preview,
            isLogo,
            urlLogo,
            brandId,
            urlLogoThumbnail,
        } = item;

        const updateLogoObject = ({logos, preview, isThumbnail}) => {
            const currentBrand = logos[brandId] || {};

            if (isThumbnail) {
                return {
                    ...logos,
                    [item.brandId]: {
                        ...currentBrand,
                        thumbnail: preview,
                    },
                };
            }

            return {
                ...logos,
                [item.brandId]: {
                    ...currentBrand,
                    full: preview,
                },
            };
        };

        if (preview) {
            setLogos(prev => {
                return updateLogoObject({
                    logos: prev,
                    isThumbnail,
                    preview,
                });
            });

            return;
        }

        if (isClearLogo) {
            if (isThumbnail) {
                setLogos(prev => {
                    return {
                        ...prev,
                        [item.brandId]: {
                            ...prev[item.brandId],
                            thumbnail: null,
                        },
                    };
                });

                return;
            }

            setLogos(prev => {
                return {
                    ...prev,
                    [item.brandId]: {
                        ...prev[item.brandId],
                        full: null,
                    },
                };
            });

            return;
        }

        methods.download({
            brandId,
            isLogo,
            urlLogo: isThumbnail ? urlLogoThumbnail : urlLogo,
            getResultFile: (file) => {
                setLogos(prev => {
                    return updateLogoObject({
                        logos: prev,
                        preview: URL.createObjectURL(file),
                        isThumbnail,
                    });
                });
            },
        });
    };

    const filtersBase = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    name: "brandNameFilter",
                    placeholder: "Введите название",
                    label: "Название бренда",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.CHECKBOX,
                    name: "isClientGroup",
                    label: "Только мои бренды",
                },
            ],
        },
    ];

    const {t} = useTranslation();

    const archiveButton = getArchiveButton(t, archived, {mobile: true});

    const archiveButtonAsset = {
        children: archived ? "Открыть список брендов" : "Открыть архивные бренды",
    };

    const mediaControls = {
        renderCount: {
            mobile: 0,
            tablet: 1,
            desktop: 2,
        },
        buttons: [
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            isOpenEdit: true,
                        });
                    },
                    children: "Добавить бренд",
                    color: "green",
                    icon: (
                        <AddIcon />
                    ),
                },
                visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role),
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    ...archiveButton.props,
                    onClick: () => {
                        setArchived(!archived);
                    },
                    disabled: progress,
                },
                asset: {
                    mobile: archiveButtonAsset,
                    tablet: archiveButtonAsset,
                },
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            isOpenObjectsAssign: true,
                            isRemoving: true,
                            title: "Удаление брендов из объектов",
                            buttonName: "Удалить",
                        });
                    },
                    children: "Удалить объекты от брендов",
                },
                visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role),
            },
        ],
    };

    const getListMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenObjectsAssign: true,
                                brandId: item.brandId,
                                brandName: item.brandName,
                                title: `Применение бренда "${item.brandName}" к объектам`,
                                buttonName: "Применить",
                            });
                        },
                        color: "grey",
                        children: "Применить к объектам",
                    },
                    visible: !archived && [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                brandName: item.brandName,
                                brandId: item.brandId,
                                urlLogo: item.urlLogo,
                                isLogo: item.isLogo,
                            });
                        },
                        children: "Редактировать бренд",
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role)
                        && !item.isArchive
                        && clientId === item.clientId,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenConfirm: true,
                                brandName: item.brandName,
                                brandId: item.brandId,
                                isArchive: item.isArchive,
                            });
                        },
                        children: item.isArchive ? "Из архива" : "В архив",
                    },
                    visible: [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenLogs: true,
                                brandName: item.brandName,
                                brandId: item.brandId,
                            });
                        },
                        children: "История изменений",
                    },
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            return {
                key: item.brandId,
                contentRow: (
                    <div className="d-flex align-item-center justify-content-between">
                        <div className="d-flex align-item-center overflow-hidden">
                            <Avatar
                                size="lg"
                                className="me-2 me-md-4"
                                preview={logos[item.brandId]?.thumbnail}
                                originalImageSrc={logos[item.brandId]?.full}
                                isOriginalImageBase64={false}
                                isAccessOpenOriginal={true}
                                progress={!logos[item.brandId]?.full}
                                onOpenOriginal={() => {
                                    if (logos[item.brandId]?.full) {
                                        return;
                                    }

                                    updateLogos({
                                        isThumbnail: false,
                                        isLogo: item.isLogo,
                                        urlLogo: item.urlLogo,
                                        brandId: item.brandId,
                                    });
                                }}
                            />
                            <div className="d-flex align-items-center overflow-hidden">
                                <Text
                                    level="5"
                                    noWrap={true}
                                >
                                    {item.brandName}
                                </Text>
                            </div>
                        </div>
                        <MediaButtons
                            inline
                            className="ms-2 ms-md-6"
                            config={getListMediaControls(item)}
                        />
                    </div>
                ),
            };
        });
    };

    const getEdit = () => {
        if (!modalData?.isOpenEdit) {
            return null;
        }

        return (
            <ClientBrandsEdit
                logos={logos}
                clientId={clientId}
                brandId={modalData.brandId}
                brandName={modalData.brandName}
                isLogo={modalData.isLogo}
                urlLogo={modalData.urlLogo}
                onClose={onCloseModal}
                fetchList={fetchList}
                updateLogos={updateLogos}
                checkError={checkError}
            />
        );
    };

    const getConfirm = () => {
        if (!modalData?.isOpenConfirm) {
            return null;
        }

        const content = modalData.isArchive
            ? `Вы действительно хотите восстановить бренд "${modalData.brandName}" из архива?`
            : `Вы действительно хотите добавить бренд "${modalData.brandName}" в архив?`;

        return (
            <NmConfirmV2
                content={content}
                onCancel={onCloseModal}
                onConfirm={() => {
                    methods.updateArchiveStatus({
                        clientId,
                        brandId: modalData.brandId,
                        archive: !modalData.isArchive,
                        onSuccess: () => {
                            fetchList();
                        },
                        checkError,
                    });
                }}
                confirmButton="Да"
                cancelButton="Нет"
            />
        );
    };

    const getLogs = () => {
        if (!modalData?.isOpenLogs) {
            return null;
        }

        return (
            <ClientBrandLogs
                brandId={modalData.brandId}
                clientId={clientId}
                brandName={modalData.brandName}
                onClose={onCloseModal}
            />
        );
    };

    const getObjectsAssign = () => {
        if (!modalData?.isOpenObjectsAssign) {
            return null;
        }

        return (
            <ClientAssignBrands
                title={modalData.title}
                clientId={clientId}
                brandId={modalData.brandId}
                brandName={modalData.brandName}
                buttonName={modalData.buttonName}
                isRemoving={modalData.isRemoving}
                onClose={onCloseModal}
                checkError={checkError}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    noWrap={false}
                    text="Бренды компании"
                    tooltipText={CLIENT_BRAND_TOOLTIP_TEXT}
                />
            }
            titleHiddenInMobile={false}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filtersBase}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            widthByFilter
            mediaControls={mediaControls}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            typeFilter="vertical"
            totalPages={totalPages}
            isLoaded={progress}
        >
            {getEdit()}
            {getConfirm()}
            {getLogs()}
            {getObjectsAssign()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2 isSearch={isSearch} /> :
                    <CheckboxList
                        rows={getRows()}
                    />
            }
        </NmPage>
    );
};