import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useCreateOrUpdateEducation, {defaultEducationForm} from "./hooks/useCreateOrUpdateEducation";

import {getDateObject} from "../../../../../../utils/dateFormat";

import {EDU_LEVEL} from "../../constants";

import {
    contractorEducationDictOptionsSelector,
    contractorEducationLevelDictOptionsSelector,
    deleteContractorEducation,
} from "../../../../../../ducks/contractorProfile";

export default function ContractorEducationEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultEducationForm},
        contractorId,
    } = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const educationLevelDictOptions = useSelector(contractorEducationLevelDictOptionsSelector);
    const educationFormDictOptions = useSelector(contractorEducationDictOptionsSelector);

    const {
        onChangeForm,
        submit,
        form,
        setForm,
        error,
    } = useCreateOrUpdateEducation({contractorId, onClose, getProfile});

    useEffect(() => {
        if (editData.educationId) {
            setForm({
                ...editData,
                graduationYear: getDateObject(editData.graduationYear),
            });
        }
    }, [editData.educationId]);

    const deleteElem = () => {
        dispatch(deleteContractorEducation({
            contractorId,
            educationId: editData.educationId,
            getResult: () => {
                onClose();
                getProfile();
            },
        }));
    };

    const {
        educationForm,
        educationInstitutionName,
        educationLevel,
        faculty,
        graduationYear,
        speciality,
    } = form;

    console.log("educationLevel", educationLevel);

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                    additionalBtnContent={editData.educationId ? "Удалить" : null}
                    additionalBtnColor="red"
                    onClickAdditional={editData.educationId ? deleteElem : null}
                />
            }
            header={
                <NmTitle size="lg">
                    Образование
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmDropdownV2
                    required
                    size="xl"
                    label="Уровень образования"
                    name="educationLevel"
                    onChange={onChangeForm}
                    value={educationLevel}
                    options={educationLevelDictOptions}
                    error={error.educationLevel}
                />
                {[EDU_LEVEL.HIGHER_EDUCATION.VALUE, EDU_LEVEL.INCOMPLETE_HIGHER_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_SPECIAL_EDUCATION.VALUE].includes(educationLevel) && <NmDropdownV2
                    required
                    size="xl"
                    label="Форма обучения"
                    name="educationForm"
                    onChange={onChangeForm}
                    value={educationForm}
                    options={educationFormDictOptions}
                    error={error.educationForm}
                />}
                <NmInputV2
                    required
                    size="xl"
                    label="Наименование учебного заведения"
                    value={educationInstitutionName}
                    name="educationInstitutionName"
                    onChange={onChangeForm}
                    error={error.educationInstitutionName}
                />
                {[EDU_LEVEL.HIGHER_EDUCATION.VALUE, EDU_LEVEL.INCOMPLETE_HIGHER_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_SPECIAL_EDUCATION.VALUE].includes(educationLevel) && <NmInputV2
                    required
                    size="xl"
                    label="Факультет"
                    value={faculty}
                    name="faculty"
                    onChange={onChangeForm}
                    error={error.faculty}
                />}
                {[EDU_LEVEL.HIGHER_EDUCATION.VALUE, EDU_LEVEL.INCOMPLETE_HIGHER_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_SPECIAL_EDUCATION.VALUE].includes(educationLevel) && <NmInputV2
                    required
                    size="xl"
                    label="Специальность"
                    value={speciality}
                    name="speciality"
                    onChange={onChangeForm}
                    error={error.faculty}
                />}
                {[EDU_LEVEL.HIGHER_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_SPECIAL_EDUCATION.VALUE, EDU_LEVEL.SECONDARY_EDUCATION.VALUE].includes(educationLevel) && <NmDatePicker
                    size="xl"
                    label="Год окончания"
                    placeholderText="Выберите год окончания"
                    dateFormatMask="9999"
                    dateFormat="yyyy"
                    selected={graduationYear}
                    minDate={new Date("1950-01-01")}
                    maxDate={new Date()}
                    name="graduationYear"
                    onChange={onChangeForm}
                    error={error.graduationYear}
                    showYearPicker
                />}
            </NmForm>
        </NmModal>
    );
}