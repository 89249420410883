import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useDataForm from "./hooks/useFormData";

import {getOrderGroupsActionProgressSelector} from "../../../../../../ducks/orderGroup";

const initialForm = {
    title: "",
    comment: "",
};

function RegistryInvitationsEditForm(props) {
    const {
        className,
        isEdit,
        onClose,
        card,
        clientId,
        fetchList,
    } = props;

    const actionProgress = useSelector(getOrderGroupsActionProgressSelector);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useDataForm({card, clientId, isEdit, fetchList, onClose});

    const {
        title,
        comment,
    } = values;

    return (
        <NmModal
            className={className}
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование реестра приглашений" : "Создание реестра приглашений"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent={isEdit ? "Сохранить" : "Создать"}
                    cancelBtnContent="Отменить"
                />
            }
            onClose={onClose}
            loading={actionProgress}

        >
            <NmForm>
                <NmTextareaV2
                    value={title}
                    name="title"
                    label="Название"
                    placeholder="Введите название реестра"
                    maxLength={100}
                    onChange={handleChange}
                    className="mb-4"
                    error={errors?.title}
                />
                <NmTextareaV2
                    value={comment}
                    name="comment"
                    label="Комментарий"
                    placeholder="Введите комментарий"
                    maxLength={255}
                    onChange={handleChange}
                />
            </NmForm>
        </NmModal>
    );
}

export default RegistryInvitationsEditForm;