export const MEDICAL_BOOK_TYPE = {
    MAIN_PAGE: {
        VALUE: "MAIN_PAGE",
        TEXT: "Фотография разворота главной страницы медицинской книжки",
        FIELD_NAME_FILE_TYPE: "medicalBookFileType",
        CONFIRM_DELETE_TEXT: "Вы действительно хотите удалить фото разворота главной страницы медицинской книжки?",
    },
    QR_CODE: {
        VALUE: "QR_CODE",
        TEXT: "Фотография QR-кода для проверки медицинской книжки",
        FIELD_NAME_FILE_TYPE: "medicalBookQRFileType",
        CONFIRM_DELETE_TEXT: "Вы действительно хотите удалить фото QR-кода для проверки медицинской книжки?",
    },
};
