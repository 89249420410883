import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {RecruitmentCandidatesCardPage} from "../../../../components/Recruitment/CandidateCard";
import {
    getRecruitmentVacancyCandidatesCard,
    rejectRecruitmentCandidate,
    updateRecruitmentVacancyCandidatesStore,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentVacancyCandidatesCardSelector,
    bffRecruitmentVacancyCandidatesIsEmptyCardSelector,
    bffRecruitmentVacancyCandidatesProgressActionSelector,
    bffRecruitmentVacancyCandidatesProgressCardSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";

export const RecruitmentCandidatesCard = (props) => {
    const {
        candidateId,
        fetchList,
        clientId,
        vacancyId,
        isVacancyCandidate,
        activeFunnelStatusFilter,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);
    const progress = useSelector(bffRecruitmentVacancyCandidatesProgressCardSelector);
    const isEmptyCard = useSelector(bffRecruitmentVacancyCandidatesIsEmptyCardSelector);
    const card = useSelector(bffRecruitmentVacancyCandidatesCardSelector);

    useEffect(() => {
        if (candidateId) {
            fetchCard();
        }
    }, [candidateId]);
    const fetchCard = () => {
        dispatch(getRecruitmentVacancyCandidatesCard({
            candidateId,
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyCandidatesStore({
                card: {},
            }));
        };
    }, []);

    const buttonFunnelMoveName = card.statusName ? card.statusName : "Переместить в воронке";

    const reject = (params) => {
        dispatch(rejectRecruitmentCandidate(params));
    };

    return (
        <RecruitmentCandidatesCardPage
            progress={progress}
            progressAction={progressAction}
            fetchList={fetchList}
            fetchCard={fetchCard}
            candidateId={candidateId}
            clientId={clientId}
            vacancyId={vacancyId}
            isEmptyCard={isEmptyCard}
            card={card}
            isVacancyCandidate={isVacancyCandidate}
            activeFunnelStatusFilter={activeFunnelStatusFilter}
            buttonFunnelMoveName={buttonFunnelMoveName}
            reject={reject}
        />
    );
};