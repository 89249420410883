import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateMainWorkType, {defaultMainWorkTypeForm} from "./hooks/useUpdateMainWorkType";

import {
    specialitiesAllV2OptionsSelector,
} from "../../../../../../ducks/speciality";


export default function ContractorMainWorkTypeEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultMainWorkTypeForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const specialitiesOptions = useSelector(specialitiesAllV2OptionsSelector);

    const {
        onChangeForm,
        submit,
        form,
        setForm,
        error,
    } = useUpdateMainWorkType({contractorId, onClose, getProfile});

    useEffect(() => {
        setForm({...editData});
    }, []);

    const {
        mainSpecialityId,
    } = form;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Основной вид деятельности
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmDropdownV2
                    options={specialitiesOptions}
                    value={mainSpecialityId}
                    name="mainSpecialityId"
                    fullWidth={true}
                    onChange={onChangeForm}
                    search
                    error={error.mainSpecialityId}
                    placeholder="Выберите из списка"
                    label="Основной вид деятельности"
                />
            </NmForm>
        </NmModal>
    );
}