import React from "react";
import {useFormik} from "formik";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import validationAddGroupCompanies from "./validatations-rule";

import bem from "../../../../../utils/bem";

import "./style.sass";

const AddGroupForm = (props) => {
    const {
        data: {
            groupName,
            description,
            groupId,
        },
        className,
        onSubmit,
    } = props;

    const [block, element] = bem("add-group-form", className);

    const {
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            name: groupName || "",
            description: description || "",
        },
        onSubmit:(values) => {
            if (groupId) {
                const {name, description} = values;
                const requestData = {
                    description,
                    groupName: name,
                    groupId,
                };

                onSubmit(requestData);

                return;
            }

            onSubmit(values);
        },
        enableReinitialize: true,
        validationSchema: validationAddGroupCompanies(),
    });

    return (
        <NmForm
            id="AddGroupCompaniesForm"
            noValidate
            onSubmit={handleSubmit}
            className={block()}
        >
            <NmInputV2
                label="Название"
                required
                size="xl"
                maxLength={100}
                placeholder="Название группы компаний"
                className={element("control")}
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched && touched.name ?
                    errors && errors.name :
                    undefined
                }
            />
            <NmTextareaV2
                label="Комментарий"
                maxLength={255}
                size="xl"
                autoSize={false}
                rows={5}
                placeholder="При необходимости укажите комментарий"
                className={element("control")}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                error={touched && touched.description ?
                    errors && errors.description :
                    undefined
                }
            />
        </NmForm>
    );
};

AddGroupForm.defaultProps = {
    data: {
        name: "",
        description: "",
    },
};

export default AddGroupForm;