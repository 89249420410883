export const BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST = "BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST";
export const BFF_PATENT_PAYMENTS_GET_PAGE_SUCCESS = "BFF_PATENT_PAYMENTS_GET_PAGE_SUCCESS";
export const BFF_PATENT_PAYMENTS_GET_PAGE_ERROR = "BFF_PATENT_PAYMENTS_GET_PAGE_ERROR";

export const BFF_PATENT_PAYMENT_REFRESH_REQUEST = "BFF_PATENT_PAYMENT_REFRESH_REQUEST";
export const BFF_PATENT_PAYMENT_REFRESH_SUCCESS = "BFF_PATENT_PAYMENT_REFRESH_SUCCESS";
export const BFF_PATENT_PAYMENT_REFRESH_ERROR = "BFF_PATENT_PAYMENT_REFRESH_ERROR";

export const BFF_PATENT_PAYMENT_DECLINE_REQUEST = "BFF_PATENT_PAYMENT_DECLINE_REQUEST";
export const BFF_PATENT_PAYMENT_DECLINE_SUCCESS = "BFF_PATENT_PAYMENT_DECLINE_SUCCESS";
export const BFF_PATENT_PAYMENT_DECLINE_ERROR = "BFF_PATENT_PAYMENT_DECLINE_ERROR";

export const UPDATE_PATENT_STORE = "UPDATE_PATENT_STORE";