import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import InputWithTooltip from "../../../../components/ActualComponents/InputWithTooltip";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import AmountInput from "../../../../components/AmountInput";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {
    calculateRate,
} from "../../../../utils/mathHelper";
import {
    clearSpace,
    formatAmount,
    formatAmountWithComma,
    getNumberFromFormattedAmount,
} from "../../../../utils/stringFormat";
import {isValidFractionalNumber} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";
import {transformLimitsToForm} from "./utils/transformLimitsToForm";

import {CLIENT_PROPERTIES_FIELD} from "../../../../constants/clientList";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_MANAGER,
} from "../../../../constants/roles";
import {
    limitsRule,
    ordersUnsecuredLimitRule,
} from "../../../../constants/validationRules";
import {limitPaymentsCommissionValidations} from "./constants/validation";

import {
    getClientCardSelector,
    updateClient,
} from "../../../../ducks/client";
import {
    getClientPropertiesById,
    getClientPropertiesCardSelector,
    updateClientProperties,
} from "../../../../ducks/clientProperties";

import PropTypes from "prop-types";

class LimitsCardBlock extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            cardData: {},
            form: {},
            errorLimit: {
                amountOneTransaction: null,
                amountPerDayOneContractor: null,
            },
            error: {},
        };

        this.role = ls(USER_ROLE);
        this.isEditable = [ADMIN, CLIENT_ADMIN].includes(this.role);
        this.isEditableAdminLimit = [ADMIN].includes(this.role);
    }

    static getDerivedStateFromProps(props, state) {
        const {card} = props;

        const {
            cardData,
            isEdit,
        } = state;

        if (card.clientId && JSON.stringify(card) !== JSON.stringify(cardData) && !isEdit) {
            return {
                ...state,
                form: transformLimitsToForm(card),
                cardData: card,
            };
        }

        return null;
    }

    get translate() {
        const {t} = this.props;

        return {
            titleBlock: t("client-settings-modal.commission-and-limits"),
            limitContent: t("client-settings-modal.limit"),
            dayLimitContent: t("client-settings-modal.day-limit"),
            monthLimitContent: t("client-settings-modal.month-limit"),
            notSpecifiedContent: t("is-not-specified.content"),
            noProvisionLimitContent: t("client-settings-modal.limit-for-orders-without-provision"),
            currentCommissionRate: t("client-settings-modal.commission-rates"),
        };
    }

    cancelEditMode = () => {
        const {formCopy} = this.state;

        this.setState(prevState => ({
            ...prevState,
            isEdit: false,
            error: {},
            errorLimit: null,
            form: formCopy,
        }));
    };

    isValidEditClient(editClient) {
        const {card} = this.props;

        const validationRules = card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ?
            {
                ...limitsRule,
                ...ordersUnsecuredLimitRule,
                ...limitPaymentsCommissionValidations,
            } :
            {
                ...limitsRule,
                ...limitPaymentsCommissionValidations,
            };

        const error = validate(editClient, validationRules, "");

        this.setState(prevState => ({
            ...prevState,
            error,
        }));

        return Object.values(error).length === 0;
    }

    toggleCard = () => {
        const {isEdit, form} = this.state;

        if (isEdit) {
            if (!this.isValidEditClient(form)) {
                return;
            }

            if (!this.isValidLimits()) {
                return;
            }
        } else {
            this.setState({
                formCopy: form,
            });
        }

        this.setState(
            prevState => ({isEdit: !prevState.isEdit}),
            () => {
                if (isEdit) {this.submit();}
            },
        );
    };

    submit = () => {
        const {
            updateClientProperties,
            clientId,
            getClientPropertiesById,
            card,
        } = this.props;

        const {form} = this.state;

        updateClientProperties({
            [CLIENT_PROPERTIES_FIELD.LIMIT]: getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.LIMIT]),
            [CLIENT_PROPERTIES_FIELD.DAY_LIMIT]: getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT]),
            [CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]: getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]),
            [CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]: getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]),
            [CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]: getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]),
            [CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]: getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]),
            [CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED]: card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] : undefined,
            [CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]: form[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]) : undefined,
            [CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]: form[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]) : undefined,
            [CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]: form[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]) : undefined,
            [CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]: form[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]) : undefined,
            [CLIENT_PROPERTIES_FIELD.FOREMAN_ENABLED]: form[CLIENT_PROPERTIES_FIELD.FOREMAN_ENABLED],
            [CLIENT_PROPERTIES_FIELD.CAN_CREATE_ONLY_VOLUME_OF_WORK_BASED_ORDERS]: form[CLIENT_PROPERTIES_FIELD.CAN_CREATE_ONLY_VOLUME_OF_WORK_BASED_ORDERS],
            [CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE]: +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE])),
            [CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION]: +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION])),
            [CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT]: form[CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT] ?
                getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT]) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT]: form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT] ?
                getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT]) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT]: form[CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT] ?
                getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT]) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT]: form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION]: form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION] ?
                +calculateRate(getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION])) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT]: form[CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT] ?
                getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT]) :
                undefined,
            [CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT]: form[CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT] ?
                getNumberFromFormattedAmount(form[CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT]) :
                undefined,
            clientId: clientId,
            onSuccess: () => {
                getClientPropertiesById({clientId});
            },
        });

        this.setState({
            cardData: form,
        });
    };

    handleChange = (e, {value, name}) => {
        if (isValidFractionalNumber(value)) {
            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [name]: formatAmount(clearSpace(value)),
                },
            }));
        }

        if (value === "") {
            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [name]: value,
                },
            }));
        }
    };

    isValidLimits = () => {
        const {form} = this.state;

        const {card} = this.props;

        const toNumber = (amount) => getNumberFromFormattedAmount(amount);

        const getMessage = (amount) => `Значение в поле не должно превышать ${formatAmountWithComma(amount)} руб`;

        const checkLimit = () => {
            if (toNumber(form[CLIENT_PROPERTIES_FIELD.LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT])) {
                return getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]);
            }

            if (toNumber(form[CLIENT_PROPERTIES_FIELD.LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT])) {
                return getMessage(form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT]);
            }

            return "";
        };

        const checkAdminLimit = () => {
            if (toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT])) {
                return getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]);
            }

            return "";
        };

        const checkAdminLimitDay = () => {
            if (toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT])) {
                return getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]);
            }

            return "";
        };

        const checkLimitDay = () => {
            if (toNumber(form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT])) {
                return getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT]);
            }

            if (toNumber(form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT])) {
                return getMessage(form[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]);
            }

            return "";
        };

        this.setState({
            errorLimit: {
                amountOneTransaction: null,
                amountPerDayOneContractor: null,
            },
        });

        const conditions = [
            {
                FIELD: CLIENT_PROPERTIES_FIELD.LIMIT,
                CONDITION: Boolean(checkLimit()),
                ERROR_MESSAGE: checkLimit(),
            },
            {
                FIELD: CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT,
                CONDITION: Boolean(checkAdminLimit()),
                ERROR_MESSAGE: checkAdminLimit(),
            },
            {
                FIELD: CLIENT_PROPERTIES_FIELD.DAY_LIMIT,
                CONDITION: Boolean(checkLimitDay()),
                ERROR_MESSAGE: checkLimitDay(),
            },
            {
                FIELD: CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT,
                CONDITION: Boolean(checkAdminLimitDay()),
                ERROR_MESSAGE: checkAdminLimitDay(),
            },
            {
                FIELD: CLIENT_PROPERTIES_FIELD.MONTH_LIMIT,
                CONDITION: toNumber(form[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]),
                ERROR_MESSAGE: getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT]),
            },
            {
                FIELD: CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT,
                CONDITION: !card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? false : toNumber(form[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]),
                ERROR_MESSAGE: getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT]),
            },
            {
                FIELD: CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT,
                CONDITION: !card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ? false : toNumber(form[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]) > toNumber(form[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]),
                ERROR_MESSAGE: getMessage(form[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT]),
            },
        ];

        const errors = conditions.filter(({CONDITION}) => CONDITION);

        errors.forEach(({FIELD, ERROR_MESSAGE}) => {
            this.setState(prevState => ({
                ...prevState,
                errorLimit: {
                    ...prevState.errorLimit,
                    [FIELD]: ERROR_MESSAGE,
                },
            }));
        });

        return errors.length === 0;
    };

    renderCommissionRateReadOnly() {
        const {form} = this.state;
        const {
            notSpecifiedContent,
            currentCommissionRate,
        } = this.translate;

        return (
            <>
                <LabelTextApp
                    label={currentCommissionRate}
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия компании за выплаты (НДФЛ), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Минимальная сумма выплаты без доп. комиссии компании (НПД), ₽"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Дополнительная сумма комиссии компании за выплаты (НПД), ₽"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Минимальная комиссия компании за выплаты (НДФЛ), ₽"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия исполнителей за выплаты по заказам (НПД), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия исполнителей за выплаты по заказам (НДФЛ), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия исполнителей за реестровые выплаты (НПД), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия исполнителей за реестровые выплаты (НДФЛ), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия компании за оплату по заданию, %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия компании за выплаты (ИП), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Минимальная сумма выплаты без доп. комиссии компании (ИП), ₽"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Дополнительная сумма комиссии компании за выплаты (ИП), ₽"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия исполнителей за выплаты по заказам (ИП), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
                <LabelTextApp
                    label="Комиссия исполнителей за реестровые выплаты (ИП), %"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION] || notSpecifiedContent}
                />
            </>
        );
    }

    renderLimitsReadOnly() {
        const {card} = this.props;
        const {form} = this.state;
        const {
            limitContent,
            dayLimitContent,
            monthLimitContent,
            notSpecifiedContent,
            noProvisionLimitContent,
        } = this.translate;

        return (
            <>
                <LabelTextApp
                    label={limitContent}
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.LIMIT] || notSpecifiedContent}
                />
                {this.isEditableAdminLimit && <LabelTextApp
                    label="Ограничение для лимита 1 транзакции, руб"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT] || notSpecifiedContent}
                />}
                <LabelTextApp
                    label={dayLimitContent}
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT] || notSpecifiedContent}
                />
                {this.isEditableAdminLimit && <LabelTextApp
                    label="Ограничение для лимита в сутки на 1 исполнителя, руб"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT] || notSpecifiedContent}
                />}
                <LabelTextApp
                    label={monthLimitContent}
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT] || notSpecifiedContent}
                />
                {this.isEditableAdminLimit && <LabelTextApp
                    label="Ограничение для лимита в месяц на 1 исполнителя, руб"
                    className="client-card-block__label"
                    text={form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT] || notSpecifiedContent}
                />}
                {card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED] ?
                    <>
                        <LabelTextApp
                            label={noProvisionLimitContent}
                            className="client-card-block__label"
                            text={form[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT] || notSpecifiedContent}
                        />
                        {
                            this.isEditableAdminLimit && <LabelTextApp
                                label="Ограничение для лимита для заказов без обеспечения (НПД), руб"
                                className="client-card-block__label"
                                text={form[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT] || notSpecifiedContent}
                            />
                        }
                        <LabelTextApp
                            label="Лимит для заказов без обеспечения (НДФЛ), руб"
                            className="client-card-block__label"
                            text={form[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT] || notSpecifiedContent}
                        />
                        {
                            this.isEditableAdminLimit && <LabelTextApp
                                label="Ограничение для лимита для заказов без обеспечения (НДФЛ), руб"
                                className="client-card-block__label"
                                text={form[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT] || notSpecifiedContent}
                            />
                        }
                    </> :
                    null
                }
            </>
        );
    }

    renderCardReadOnly() {
        return (
            <>
                {this.renderCommissionRateReadOnly()}
                {
                    [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role) &&
                    this.renderLimitsReadOnly()
                }
            </>
        );
    }

    renderCommissionRateEditMode() {
        const {
            form,
            error,
        } = this.state;
        const {currentCommissionRate} = this.translate;

        return (
            <>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label={currentCommissionRate}
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE]}
                            name={CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия компании за выплаты (НДФЛ), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CIVIL_PAYMENT_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Минимальная сумма выплаты без доп. комиссии компании (НПД), ₽"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT]}
                            name={CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.PAYMENTS_THRESHOLD_AMOUNT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Дополнительная сумма комиссии компании за выплаты (НПД), ₽"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT]}
                            name={CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.MIN_COMMISSION_AMOUNT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Минимальная комиссия компании за выплаты (НДФЛ), ₽"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT]}
                            name={CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_NDFL_PAYMENTS_AMOUNT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия исполнителей за выплаты по заказам (НПД), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия исполнителей за выплаты по заказам (НДФЛ), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия исполнителей за реестровые выплаты (НПД), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия исполнителей за реестровые выплаты (НДФЛ), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия компании за оплату по заданию, %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CROWD_TASK_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия компании за выплаты (ИП), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT]}
                            name={CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.MINIMAL_COMMISSION_INDIVIDUAL_PAYMENTS_AMOUNT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Минимальная сумма выплаты без доп. комиссии компании (ИП), ₽"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT]}
                            name={CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.INDIVIDUAL_PAYMENTS_THRESHOLD_AMOUNT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Дополнительная сумма комиссии компании за выплаты (ИП), ₽"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT]}
                            name={CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.MIN_INDIVIDUAL_COMMISSION_AMOUNT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия исполнителей за выплаты по заказам (ИП), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_ORDER_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-16">
                        <AmountInput
                            newInput
                            size="lg"
                            label="Комиссия исполнителей за реестровые выплаты (ИП), %"
                            disabled={this.role === CLIENT_ADMIN}
                            error={error[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION]}
                            name={CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CONTRACTOR_INDIVIDUAL_REGISTRY_PAYMENTS_COMMISSION] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </>
        );
    }

    renderOrdersUnsecuredLimits = () => {
        const {card} = this.props;
        const {form, error, errorLimit} = this.state;
        const {
            noProvisionLimitContent,
        } = this.translate;

        if (!card[CLIENT_PROPERTIES_FIELD.ORDERS_UNSECURED]) {
            return null;
        }

        return (
            <>
                {/*Лимит для заказов без обеспечения*/}
                <div className="row">
                    <div className="col-md-16">
                        <InputWithTooltip
                            isShowTooltip={[CLIENT_ADMIN, NM_MANAGER].includes(this.role)}
                            size="lg"
                            label={noProvisionLimitContent}
                            tooltip={`Значение в поле не должно превышать ${formatAmountWithComma(card[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT])}`}
                            error={error[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT])}
                            name={CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                {
                    this.isEditableAdminLimit &&
                    <div className="row">
                        <div className="col-md-16">
                            {/*Ограничение для лимита для заказов без обеспечения, руб*/}
                            <NmInputV2
                                size="lg"
                                label="Ограничение для лимита для заказов без обеспечения (НПД), руб"
                                error={error[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT])}
                                name={CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT}
                                placeholder={0}
                                value={form[CLIENT_PROPERTIES_FIELD.ADMIN_ORDERS_LIMIT] || ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-16">
                        <InputWithTooltip
                            isShowTooltip={[CLIENT_ADMIN, NM_MANAGER].includes(this.role)}
                            size="lg"
                            label="Лимит для заказов без обеспечения (НДФЛ), руб"
                            tooltip={`Значение в поле не должно превышать ${formatAmountWithComma(card[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT])}`}
                            error={error[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT])}
                            name={CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                {
                    this.isEditableAdminLimit &&
                    <div className="row">
                        <div className="col-md-16">
                            <NmInputV2
                                size="lg"
                                label="Ограничение для лимита для заказов без обеспечения (НДФЛ), руб"
                                error={error[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT])}
                                name={CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT}
                                placeholder={0}
                                value={form[CLIENT_PROPERTIES_FIELD.ADMIN_CIVIL_ORDERS_LIMIT] || ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                }
            </>
        );
    };

    renderLimitsEditMode() {
        const {card} = this.props;
        const {form, error, errorLimit} = this.state;
        const {
            limitContent,
            dayLimitContent,
            monthLimitContent,
        } = this.translate;

        return (
            <>
                <div className="row">
                    <div className="col-md-16">
                        {/*Лимит 1 транзакции, руб*/}
                        <InputWithTooltip
                            size="lg"
                            isShowTooltip={[CLIENT_ADMIN, NM_MANAGER].includes(this.role)}
                            tooltip={`Значение в поле не должно превышать ${formatAmountWithComma(card[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT])}`}
                            label={limitContent}
                            error={error[CLIENT_PROPERTIES_FIELD.LIMIT] || (errorLimit && errorLimit["amountOneTransaction"])}
                            name={CLIENT_PROPERTIES_FIELD.LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                {this.isEditableAdminLimit && <div className="row">
                    <div className="col-md-16">
                        {/*Ограничение для лимита 1 транзации, руб*/}
                        <NmInputV2
                            size="lg"
                            label="Ограничение для лимита 1 транзакции, руб"
                            error={error[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT])}
                            name={CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.ADMIN_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>}
                {/*Лимит в сутки на 1 исполнителя, руб*/}
                <div className="row">
                    <div className="col-md-16">
                        <InputWithTooltip
                            size="lg"
                            isShowTooltip={[CLIENT_ADMIN, NM_MANAGER].includes(this.role)}
                            tooltip={`Значение в поле не должно превышать ${formatAmountWithComma(card[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT])}`}
                            label={dayLimitContent}
                            error={error[CLIENT_PROPERTIES_FIELD.DAY_LIMIT] || (errorLimit && errorLimit["amountPerDayOneContractor"])}
                            name={CLIENT_PROPERTIES_FIELD.DAY_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.DAY_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                {this.isEditableAdminLimit && <div className="row">
                    <div className="col-md-16">
                        {/*Ограничение для лимита в сутки на 1 исполнителя, руб*/}
                        <NmInputV2
                            size="lg"
                            label="Ограничение для лимита в сутки на 1 исполнителя, руб"
                            error={error[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT])}
                            name={CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.ADMIN_DAY_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>}
                {/*Лимит в месяц на 1 исполнителя, руб*/}
                <div className="row">
                    <div className="col-md-16">
                        <InputWithTooltip
                            size="lg"
                            isShowTooltip={[CLIENT_ADMIN, NM_MANAGER].includes(this.role)}
                            tooltip={`Значение в поле не должно превышать ${formatAmountWithComma(card[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT])}`}
                            label={monthLimitContent}
                            error={error[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT])}
                            name={CLIENT_PROPERTIES_FIELD.MONTH_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.MONTH_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                {this.isEditableAdminLimit && <div className="row">
                    <div className="col-md-16">
                        {/*Ограничение для лимита в месяц на 1 исполнителя, руб*/}
                        <NmInputV2
                            size="lg"
                            label="Ограничение для лимита в месяц на 1 исполнителя, руб"
                            error={error[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT] || (errorLimit && errorLimit[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT])}
                            name={CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT}
                            placeholder={0}
                            value={form[CLIENT_PROPERTIES_FIELD.ADMIN_MONTH_LIMIT] || ""}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>}
                {this.renderOrdersUnsecuredLimits()}
            </>
        );
    }

    renderCardEditMode() {
        return (
            <NmForm addMargin>
                {this.renderCommissionRateEditMode()}
                {
                    this.isEditable ? this.renderLimitsEditMode() : this.renderLimitsReadOnly()
                }
            </NmForm>
        );
    }

    render() {
        const {
            client: {
                archived,
            },
        } = this.props;
        const {
            isEdit,
        } = this.state;

        const {titleBlock} = this.translate;

        return (
            <CardApp
                title={titleBlock}
                isEditable={this.isEditable && !archived}
                onClickIcon={this.toggleCard}
                onClickCancelIcon={this.cancelEditMode}
                isEdit={isEdit}
            >
                {isEdit
                    ? this.renderCardEditMode()
                    : this.renderCardReadOnly()}
            </CardApp>
        );
    }
}

export default connect(state => ({
    client: getClientCardSelector(state),
    card: getClientPropertiesCardSelector(state),
}), {
    updateClient,
    getClientPropertiesById,
    updateClientProperties,
})(withTranslation()(LimitsCardBlock));