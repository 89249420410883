import {isNullOrWhitespace} from "./stringHelper";
import {isAcceptedCancelingFcRegistryItem, isAcceptedEditingFcRegistryActions} from "./user-rights/fcRegistries";

import {citizenshipsDict} from "../constants/citizenships";
import {
    FC_REGISTRIS_STATUS_DICT,
    FC_REGISTRY_ACTION, FC_REGISTRY_ACTION_TEXT,
    FC_REGISTRY_ITEM_ACTION,
    FC_REGISTRY_ITEM_STATUS,
} from "../constants/fcRegistries";

export const REGISTRY_OPTIONS = {
    SUBMIT_TO_SIGNING: {
        key: FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING,
        text: "Передать на подписание",
        value: FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING,
    },
    EDIT_REGISTRY: {
        key: FC_REGISTRY_ACTION.EDIT,
        text: "Редактировать",
        value: FC_REGISTRY_ACTION.EDIT,
    },
    RESUBMIT_TO_SIGNING: {
        key: FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING,
        text: "Повторить невыполненные",
        value: FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING,
    },
    DELETE_NOT_SIGNED: {
        key: FC_REGISTRY_ACTION.DELETE_NOT_SIGNED,
        text: "Отменить невыполненные",
        value: FC_REGISTRY_ACTION.DELETE_NOT_SIGNED,
    },
    ARCHIVE: {
        key: FC_REGISTRY_ACTION.ARCHIVE,
        text: "Архивировать",
        value: FC_REGISTRY_ACTION.ARCHIVE,
    },
    UNARCHIVE: {
        key: FC_REGISTRY_ACTION.UNARCHIVE,
        text: "Из архива",
        value: FC_REGISTRY_ACTION.UNARCHIVE,
    },
};

export const getFcRegistryListActionOptions = (registry) => {
    const {
        archived,
        status,
        itemsDeclinedCount,
        itemsTotalCount,
        isClientArchived,
    } = registry;

    if (archived && !isClientArchived) {
        return [REGISTRY_OPTIONS.UNARCHIVE];
    }

    const resultOptions = isClientArchived ? [] : [REGISTRY_OPTIONS.EDIT_REGISTRY, REGISTRY_OPTIONS.ARCHIVE];
    /*
    доступно при статусе реестра "Формируется" и наличии хотя бы 1 записи в реестре, инициирует передачу реестра на подписание
     */
    if ([FC_REGISTRIS_STATUS_DICT.CREATED].includes(status) && itemsTotalCount > 0 && !archived && !isClientArchived) {
        resultOptions.push(REGISTRY_OPTIONS.SUBMIT_TO_SIGNING);
    }
    /*
    Повторить невыполненные - кнопка, доступна при статусе реестра "Передан на подписание", "Отклонен", "Подписан частично"
    при наличии строк реестра со статусами "Ошибка при передаче на подписание", "Отклонен", инициирует повторный запуск передачи строк реестра с данными статусами на подписание
     */
    if (
        [
            FC_REGISTRIS_STATUS_DICT.SUBMITTED_TO_SIGNING,
            FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED,
            FC_REGISTRIS_STATUS_DICT.DECLINED,
        ].includes(status) && itemsDeclinedCount > 0 && !isClientArchived) {
        resultOptions.push(REGISTRY_OPTIONS.RESUBMIT_TO_SIGNING);
    }

    /*
    кнопка, доступна при статусе реестра "Передан на подписание", "Отклонен", "Подписан частично" при наличии строк реестра со статусами "Ошибка при передаче на подписание", "Отклонен"
     */
    if ([FC_REGISTRIS_STATUS_DICT.SUBMITTED_TO_SIGNING,
        FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED,
        FC_REGISTRIS_STATUS_DICT.DECLINED].includes(status) && itemsDeclinedCount > 0) {
        resultOptions.push(REGISTRY_OPTIONS.DELETE_NOT_SIGNED);
    }

    return resultOptions;
};

export const getFcRegistryCardActionOptions = (registry) => {
    const {
        status,
        itemsDeclinedCount,
        isClientArchived,
    } = registry;

    const resultOptions = [];

    /*
    Повторить невыполненные - кнопка, доступна при статусе реестра "Передан на подписание", "Отклонен", "Подписан частично"
    при наличии строк реестра со статусами "Ошибка при передаче на подписание", "Отклонен", инициирует повторный запуск передачи строк реестра с данными статусами на подписание
     */
    if ([FC_REGISTRIS_STATUS_DICT.SUBMITTED_TO_SIGNING,
        FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED,
        FC_REGISTRIS_STATUS_DICT.DECLINED].includes(status) && itemsDeclinedCount > 0 && !isClientArchived) {
        resultOptions.push(REGISTRY_OPTIONS.RESUBMIT_TO_SIGNING);
    }

    /*
    кнопка, доступна при статусе реестра "Передан на подписание", "Отклонен", "Подписан частично" при наличии строк реестра со статусами "Ошибка при передаче на подписание", "Отклонен"
     */
    if ([FC_REGISTRIS_STATUS_DICT.SUBMITTED_TO_SIGNING,
        FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED,
        FC_REGISTRIS_STATUS_DICT.DECLINED].includes(status) && itemsDeclinedCount > 0) {
        resultOptions.push(REGISTRY_OPTIONS.DELETE_NOT_SIGNED);
    }

    return resultOptions;
};

export const getFcRegistryItemActionOptions = (item, role) => {
    const {
        status,
        contractorCitizenship,
        ovmNotificationId,
        externalDocument,
        isClientArchived,
        locatedOutsideRussia,
        isIndividualEntrepreneurRegistry,
    } = item;

    const options = {
        EDIT: {
            key: FC_REGISTRY_ITEM_ACTION.EDIT,
            text: "Редактировать",
            value: FC_REGISTRY_ITEM_ACTION.EDIT,
        },
        DOWNLOAD_FRAME_CONTRACT: {
            key: FC_REGISTRY_ITEM_ACTION.DOWNLOAD_FRAME_CONTRACT,
            text: "Договор",
            value: FC_REGISTRY_ITEM_ACTION.DOWNLOAD_FRAME_CONTRACT,
        },
        DOWNLOAD_OVM_NOTIFICATION: {
            key: FC_REGISTRY_ITEM_ACTION.DOWNLOAD_OVM_NOTIFICATION,
            text: "Уведомление в ОВМ",
            value: FC_REGISTRY_ITEM_ACTION.DOWNLOAD_OVM_NOTIFICATION,
        },
        CANCEL: {
            key: FC_REGISTRY_ITEM_ACTION.CANCEL,
            text: "Отменить",
            value: FC_REGISTRY_ITEM_ACTION.CANCEL,
        },
    };

    const resultOptions = [];

    if (
        !externalDocument
        && isAcceptedEditingFcRegistryActions(role)
        && (
            isNullOrWhitespace(status) ||
            [
                FC_REGISTRY_ITEM_STATUS.CREATED,
                FC_REGISTRY_ITEM_STATUS.DECLINED,
                FC_REGISTRY_ITEM_STATUS.SIGNATURE_ERROR,
                FC_REGISTRY_ITEM_STATUS.SIGNATURE_PREPARATION_ERROR,
            ].includes(status)
        )
        && !isClientArchived
        && !isNullOrWhitespace(contractorCitizenship)
        && contractorCitizenship !== citizenshipsDict.RU.value
        && !locatedOutsideRussia
        && !isIndividualEntrepreneurRegistry
    )  {
        resultOptions.push(options.EDIT);
    }
    // кнопка, доступна только при статусе строки "Подписан" 
    if ([FC_REGISTRY_ITEM_STATUS.SIGNED].includes(status)) {
        resultOptions.push(options.DOWNLOAD_FRAME_CONTRACT);
    }

    // кнопка, доступна только при статусе строки "Подписан" И если после подписания РД было сформировано уведомление ОВМ (исполнитель-нерезидент), при нажатии открывается уведомление в ОВМ о заключении договора с нерезидентом
    if ([FC_REGISTRY_ITEM_STATUS.SIGNED].includes(status) && !isNullOrWhitespace(ovmNotificationId)) {
        resultOptions.push(options.DOWNLOAD_OVM_NOTIFICATION);
    }

    if (isAcceptedCancelingFcRegistryItem(role) && [FC_REGISTRY_ITEM_STATUS.AWAITING_SIGNATURE].includes(status)) {
        resultOptions.push(options.CANCEL);
    }

    return resultOptions;
};


const getSubmiitingConfirmByFlags = (defaultText, flags, isIndividualEntrepreneurRegistry) => {
    const {
        hasContractorDuplicates,
        hasNonResidentContractors,
    } = flags;

    if (hasContractorDuplicates && hasNonResidentContractors && !isIndividualEntrepreneurRegistry) {
        return FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_DUPLICATES_AND_NON_RESIDENTS];
    }

    if (hasContractorDuplicates) {
        return FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_DUPLICATES];
    }

    if (hasNonResidentContractors && !isIndividualEntrepreneurRegistry) {
        return FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING_WITH_NON_RESIDENTS];
    }
    return defaultText;
};

export const getSubmittingConfirmText = (flags, isIndividualEntrepreneurRegistry) => {
    return getSubmiitingConfirmByFlags(FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING], flags, isIndividualEntrepreneurRegistry);
};

export const getResubmittingConfirmText = (flags, isIndividualEntrepreneurRegistry) => {
    return getSubmiitingConfirmByFlags(FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING], flags, isIndividualEntrepreneurRegistry);
};

export const getFcStatusForRegistryItem = ({actualFrameContract}) => {
    if (isNullOrWhitespace(actualFrameContract)) {
        return "Договор с исполнителем отсутствует";
    }

    if (actualFrameContract) {
        return "Договор с исполнителем заключен";
    }

    return "Договор с исполнителем расторгнут";
};