import React, {useMemo} from "react";
import {Redirect, Route} from "react-router";

import {getContractorCardRouteAccess} from "./utils/getAccess";

import {LINK_FORBIDDEN_PAGE} from "../../constants/links";

const ContractorRoute = (props) => {
    const {
        path,
    } = props;

    const isAccess = useMemo(() => {
        return getContractorCardRouteAccess({
            path,
        });
    }, []);

    if (!isAccess) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    return (
        <Route {...props} />
    );
};

export default ContractorRoute;