import React, { FC, useState } from "react";
import {useTranslation} from "react-i18next";

import {ReactComponent as IconNotSee} from "../../../images/notsee_24.svg";
import {ReactComponent as IconSee} from "../../../images/see_24.svg";
import NmInputV2 from "../NmInputV2";
import NmLabel from "../NmLabel";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmInputPassword {
    error?: string,
    name?: string,
    value: string,
    onChange: (e: any, params: any) => void,
    onClickForgotPassword?: () => void,
    placeholder?: string,
    required?: boolean,
    openNaimix?: boolean,
    promoPoisk?: boolean,
    className?: string,
    labelClassName?: string,
    size?: "md" | "xl" | "lg",
    autoComplete?: string,
    onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void,
    label?: string
}

const NmInputPassword: FC<INmInputPassword> = (props) => {
    const {
        error,
        required,
        className = "",
        openNaimix,
        promoPoisk,
        name = "password",
        placeholder,
        value,
        onChange,
        labelClassName,
        onClickForgotPassword,
        label,
        autoComplete,
        onBlur,
        size,
    } = props;
    const {t} = useTranslation();
    const [block, element] = bem("nm-input-password", className);
    const [visible, setVisible] = useState(false);

    const getInputType = () => {
        if (visible) {
            return "text";
        }

        return "password";
    };

    const onTogglePassword = () => {
        setVisible(!visible);
    };

    return (
        <div className={block({openNaimix})}>
            <div className={element("label-container")}>
                {
                    label &&
                    <NmLabel
                        className={labelClassName}
                        required={required}
                        label={label}
                    />
                }
                {
                    onClickForgotPassword &&
                    <div
                        onClick={onClickForgotPassword}
                        className={element("forgot", {promoPoisk})}
                    >
                        {t("login.forgot-password")}
?
                    </div>
                }
            </div>
            <div className={element("container")}>
                <NmInputV2
                    openNaimix={openNaimix}
                    promoPoisk={promoPoisk}
                    size={size}
                    error={error}
                    name={name}
                    type={getInputType()}
                    placeholder={placeholder}
                    className={element("password")}
                    value={value}
                    onChange={onChange}
                    isVisibleDefaultTitle={false}
                    autoComplete={autoComplete}
                    onBlur={onBlur}
                />
                {
                    visible ?
                        <IconNotSee
                            onClick={onTogglePassword}
                            title="Скрыть текст пароля"
                            className={element("icon-see", {openNaimix})}
                        /> :
                        <IconSee
                            onClick={onTogglePassword}
                            title="Показать текст пароля"
                            className={element("icon-see", {openNaimix})}
                        />
                }
            </div>
        </div>
    );
};

export default NmInputPassword;