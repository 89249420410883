import {useEffect, useState} from "react";

import {CONTRACTOR_FIELD} from "../../../constants/contractor";
import {FULL_REGISTRATION_UPDATE_CONTRACTOR_ERROR} from "../constants";

export const useFullRegistrationValidationError = (props) => {
    const {
        values,
        currentStep,
        setErrors,
    } = props;

    const [isVisibleValidationError, setVisible] = useState(false);

    const onErrorUpdateContractor = ({errorCode, invalidFieldNames}) => {
        if (errorCode === FULL_REGISTRATION_UPDATE_CONTRACTOR_ERROR.PENDING_PERSONAL_DATA_DRAFT_CANT_BE_COMPLETED) {
            setVisible(true);

            if (invalidFieldNames.length) {
                const invalidFields = {};

                invalidFieldNames.forEach(item => {
                    invalidFields[CONTRACTOR_FIELD[item]] = "Обязательное поле";
                });

                setErrors({
                    ...invalidFields,
                });
            }
        }
    };

    const setErrorsContractorFiles = (list) => {
        if (list.length) {
            const invalidFields = {};

            list.forEach(item => {
                invalidFields[item] = "Обязательное поле";
            });

            setErrors({
                ...invalidFields,
            });
            setVisible(true);
        }
    };

    useEffect(() => {
        if (!isVisibleValidationError) {
            return;
        }

        setVisible(false);
    }, [values, currentStep]);

    return {
        isVisibleValidationError,
        onErrorUpdateContractor,
        setErrorsContractorFiles,
    };
};