import {
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../utils/objectHelper";
import {handleFormString} from "../../../../utils/stringHelper";

export const getKedoLogsFiltersData = (filterData) => {
    const {
        logIdFilter,
        requestIdFilter,
        integration1CRequestTypeLogFilter,
        statusFilter,
        fromDateFilter,
        toDateFilter,
        ...otherData
    } = filterData;

    return filterEmptyValues({
        integration1CRequestTypeLogFilter: integration1CRequestTypeLogFilter ?
            [integration1CRequestTypeLogFilter] :
            undefined,
        statusFilter: statusFilter ? [statusFilter] : undefined,
        fromDateFilter: getStartFilterDateWithTimeInUtc(fromDateFilter),
        toDateFilter: getEndFilterDateWithTimeInUtc(toDateFilter),
        logIdFilter: handleFormString(logIdFilter),
        requestIdFilter: handleFormString(requestIdFilter),
        ...otherData,
    });
};