import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import {
    convertDateToDateWithoutTimeString,
    convertStringToDate,
    handleFormString,
} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {
    clientProgressNmContractSelector,
    getClientCardSelector,
    updateNmContract,
} from "../../../../ducks/client";

const VALIDATION_RULES = {
    nmContractNumber: {
        required: "Обязательное поле",
        minLength: {
            value: 1,
            message: "Минимальная длина строки 1 символ",
        },
        maxLength: {
            value: 255,
            message: "Максимальная длина строки 255 символов",
        },
    },
    nmContractDateConclusion: {
        required: "Обязательное поле",
    },
};

const AgencyContractInfoEdit = (props) => {
    const {
        onClose,
    } = props;
    const {t} = useTranslation();
    const card = useSelector(getClientCardSelector);
    const progress = useSelector(clientProgressNmContractSelector);
    const dispatch = useDispatch();

    const [error, setError] = useState({});
    const [editInfoCard, setEditInfoCard] = useState({});
    const {
        nmContractNumber,
        nmContractDateConclusion,
    } =  editInfoCard;

    useEffect(() => {
        if (!isEmpty(card)) {
            setEditInfoCard({
                nmContractNumber: card.nmContractNumber,
                nmContractDateConclusion: convertStringToDate(card.nmContractDateConclusion),
            });
        }
    }, [card]);

    const onChange = (event, {name, value}) => {
        setEditInfoCard({
            ...editInfoCard,
            [name]: value,
        });
    };

    const onSubmit = () => {
        const errorsFields = validate({nmContractNumber, nmContractDateConclusion}, VALIDATION_RULES, "");

        setError({
            ...errorsFields,
        });

        if (!isEmpty(errorsFields)) {
            return;
        }

        dispatch(updateNmContract({
            clientId: card.clientId,
            nmContractNumber: handleFormString(nmContractNumber),
            nmContractDateConclusion: convertDateToDateWithoutTimeString(nmContractDateConclusion),
            onSuccess: onClose,
        }));
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Информация
                </NmTitle>
            }
            onClose={onClose}
            footer={
                <ApplyButtons
                    disabled={progress}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    submit={onSubmit}
                />
            }
        >
            <NmForm addMargin>
                <NmInputV2
                    label={t("agency-contract-info.nmContractNumber")}
                    size="xl"
                    className="client-form__control"
                    name="nmContractNumber"
                    onChange={onChange}
                    error={error.nmContractNumber}
                    value={nmContractNumber}
                    required
                />
                <NmDatePicker
                    size="xl"
                    dateFormatMask="99.99.9999"
                    label={t("agency-contract-info.nmContractDateConclusion")}
                    isClearable
                    error={error.nmContractDateConclusion}
                    name="nmContractDateConclusion"
                    onChange={onChange}
                    selected={nmContractDateConclusion}
                    required
                />
            </NmForm>
        </NmModal>
    );
};

export default AgencyContractInfoEdit;