import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import {
    bffRecruitmentAccessControlRecruitersGroupsListLoadingSelector,
    bffRecruitmentAccessControlRecruitersGroupsListSelector,
    bffRecruitmentAccessControlRecruitersGroupsListTotalCountSelector,
    bffRecruitmentAccessControlRecruitersGroupsListTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/access-control/recruiters-groups/selectors";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {RecruitmentAccessControlRecruitersGroupsAddGroupForm} from "./components/add-form";

import {usePagination} from "../../../../hooks/usePagination";
import {useRecruitmentAccessControlRecruitersGroupsAction} from "./hooks/useAction";
import {useRecruitmentAccessControlRecruitersGroupsFetchList} from "./hooks/useFetchList";
import {useRecruitmentAccessControlRecruitersGroupsFilter} from "./hooks/useFilter";

import {getUserRole} from "../../../../utils/access";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD} from "../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, isClientUser, NM_MANAGER, NM_OPERATOR, RECRUITER} from "../../../../constants/roles";

export const ClientRecruitmentAccessControlRecruitersGroups = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentAccessControlRecruitersGroupsListSelector);
    const listLoading = useSelector(bffRecruitmentAccessControlRecruitersGroupsListLoadingSelector);
    const listTotalCount = useSelector(bffRecruitmentAccessControlRecruitersGroupsListTotalCountSelector);
    const listTotalPages = useSelector(bffRecruitmentAccessControlRecruitersGroupsListTotalPagesSelector);

    const role = getUserRole();
    const isAccessAction = [ADMIN, RECRUITER, NM_MANAGER].includes(role) || isClientUser(role);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useRecruitmentAccessControlRecruitersGroupsFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentAccessControlRecruitersGroupsFetchList({
        clientId,
        filterData,
        pageSize,
        pageNum,
    });

    const {
        onDelete,
        onAdd,
        onOpenModal,
        onCloseModal,
        modalData,
    } = useRecruitmentAccessControlRecruitersGroupsAction({
        clientId,
        fetchList,
    });

    const getRows = () => {
        return list.map((item) => {
            return {
                key: item.id,
                contentRow: (
                    <NmListCard
                        primaryHeader={item.name}
                        primaryHeaderLink
                        link={
                            LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD
                                .replace(":id", item.id)
                                .replace(":clientId", clientId)
                        }
                        isFixedActions
                        noDivider
                        mediaControls={{
                            renderCount: {
                                desktop: 1,
                                tablet: 1,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        size: "lg",
                                        onClick: () => onOpenModal({
                                            isOpenDeleteModal: true,
                                            content: `Вы действительно хотите удалить группу ${item.name}?`,
                                            id: item.id,
                                        }),
                                        children: "Удалить группу",
                                    },
                                    visible: [
                                        ADMIN,
                                        CLIENT_ADMIN,
                                        RECRUITER,
                                    ].includes(role),
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    const renderDeleteModal = () => {
        if (!modalData?.isOpenDeleteModal) {
            return null;
        }

        return (
            <NmConfirmV2
                content={modalData.content}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onConfirm={onDelete}
                onCancel={onCloseModal}
            />
        );
    };

    const renderAddModal = () => {
        if (!modalData?.isOpenAddModal) {
            return null;
        }

        return (
            <RecruitmentAccessControlRecruitersGroupsAddGroupForm
                onClose={onCloseModal}
                onAdd={onAdd}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    noWrap={false}
                    size="xl"
                    text="Группы рекрутеров"
                    tooltipText="Страница предназначена для управления группами рекрутеров компании"
                />
            }
            isLoaded={listLoading}
            titleHiddenInMobile={false}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            controls={
                isAccessAction &&
                <NmButton
                    size="xl"
                    icon={<AddIcon />}
                    onClick={() => onOpenModal({
                        isOpenAddModal: true,
                    })}
                >
                    Добавить группу
                </NmButton>
            }
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalCount={listTotalCount}
            totalPages={listTotalPages}
        >
            {renderDeleteModal()}
            {renderAddModal()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2 isSearch={isSearch} /> :
                    <CheckboxList rows={getRows()} />
            }

        </NmPage>
    );
};