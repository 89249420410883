import React, {useState} from "react";

import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";

import bem from "../../../utils/bem";
import {isPromoPoiskPage} from "../../../utils/url";

import "./style.sass";

const TERMS = <>
Электронная площадка «Наймикс» оказывает услуги по&nbsp;представлению технических, организационных, информационных и иных возможностей с&nbsp;применением информационных
    технологий и&nbsp;систем для установления контактов и заключения сделок по&nbsp;реализации товаров (работ, услуг, имущественных прав) между продавцами (исполнителями) и покупателями (заказчиками).
    Услуги оказываются уполномоченным оператором электронной площадки ООО «Наймикс» на основании федерального закона от 27.11.2018 № 422-ФЗ
    «О&nbsp;проведении эксперимента по&nbsp;установлению специального налогового режима «Налог на&nbsp;профессиональный доход». Информация, размещенная на&nbsp;настоящем сайте, не является
    предложением о&nbsp;заключении трудовых договоров и/или&nbsp;приеме на работу в&nbsp;соответствии с&nbsp;трудовым законодательством Российской Федерации.
    Нажимая на кнопку «Согласиться», пользователь подтверждает свое согласие с&nbsp;указанными выше условиями.
</>;

const LoginCookie = ({handleClose}) => {
    const [open, setOpen] = useState(false);
    const [, element] = bem("login-cookie");

    const onOpenTerms = () => {
        setOpen(true);

        if (open) {
            setOpen(false);
            handleClose();
        }
    };

    return (
        <div className="login-cookie">
            {
                open &&
                    <NmModal
                        onClose={onOpenTerms}
                        children={TERMS}
                        classNameContent="login-cookie__terms-content"
                        contentAlign="center"
                        footer={
                            <ApplyButtons
                                mobile="column"
                                submit={onOpenTerms}
                                submitBtnContent="Согласиться"
                            />
                        }
                    />
            }
            <div className="login-cookie__container">
                <div
                    onClick={handleClose}
                    className={element("button", {promoPoisk: isPromoPoiskPage()})}
                >
                    Согласиться и&nbsp;закрыть
                </div>
                <div className="login-cookie__message">
                    <div className="login-cookie__message_desktop">
                        {TERMS}
                    </div>
                    <div className="login-cookie__message_mobile">
                        Подтвердите свое согласие с&nbsp;
                        <span
                            onClick={onOpenTerms}
                            className="login-cookie__message-terms"
                        >
                            условиями Наймикс
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginCookie;