import {useMemo, useState} from "react";

export const useFinanceBankSort = () => {
    const [sortType, setSortType] = useState("DESC");

    const onClickSort = ({sortType}) => {
        setSortType(sortType);
    };

    const sortOptions = useMemo(() => {
        return [
            {
                key: "fio",
                value: "fio",
                sortType: sortType,
                text: "По дате в банке",
                asc: "ASC",
                desc: "DESC",
            },
        ];
    }, [sortType]);

    return {
        sortType,
        sortOptions,
        onClickSort,
    };
};