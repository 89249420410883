import React from "react";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../components/ActualComponents/Text";
import ExtLink from "../../../../components/ExtLink";
import NmButton from "../../../../components/NmButton";
import ViewsCount from "../../../../components/ViewsCount";
import OrderListStatus from "../../../order/order-list/order-list-status";
import {Divider} from "semantic-ui-react";

import dateFormat, {formatLocalDate} from "../../../../utils/dateFormat";
import {roundNumberWithPoint} from "../../../../utils/mathHelper";
import {formatAmount, formatNumber} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../constants/finance";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_ORDER_CARD,
} from "../../../../constants/links";
import {ORDER_SOURCE_TYPES} from "../../../../constants/order";
import {MARKER_TYPE} from "../../constants";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../../constants/link-params";

const MapOrderListCard = (props) => {
    const {
        order,
        isModalCard = false,
        isShowInviteButton = false,
        onOpenInviteModal,
    } = props;

    const {
        orderId,
        status,
        publicationDate,
        amount,
        workStartDate,
        workEndDate,
        contractorsNeededCount,
        orderViewCount,
        orderNum,
        name,
        projectName,
        objectId,
        objectName,
        projectId,
        registryId,
        registryOrdinalNumber,
        registryName,
        registrySeqNum,
        clientId,
        hiredCount,
        awaitingCount,
        paidSum,
        speciality,
        orderSource,
        orderContractPaymentType,
    } = order || {};

    const totalAmount = amount ? formatNumber(formatAmount(roundNumberWithPoint(amount))) : "Не указана";
    const payout = formatNumber(formatAmount(roundNumberWithPoint(paidSum || 0)));
    const isNotRegistryOrApiOrder = ![ORDER_SOURCE_TYPES.REGISTRY, ORDER_SOURCE_TYPES.API].includes(orderSource);
    const linkOrder = LINK_ORDER_CARD.replace(":clientId", clientId)
        .replace(":orderId", orderId)
        .replace(":page", SELF_CONTRACTOR)
        .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
    const registryLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD.replace(":clientId", clientId)
        .replace(":paymentNumberFilter?/", "")
        .replace(":archived", "false")
        .replace(":registryId", registryId);
    const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
        .replace(":clientId", clientId)
        .replace(":projectId", projectId);
    const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
        .replace(":clientId", clientId)
        .replace(":projectId", projectId)
        .replace(":objectId", objectId);

    const openInviteModal = () => {
        onOpenInviteModal({
            ...order,
            type: MARKER_TYPE.ORDER,
        });
    };

    const getLink = (content, link) => {
        return (
            <ExtLink
                title={content}
                historyEnabled
                to={link}
            >
                {content}
            </ExtLink>
        );
    };

    const getInviteButton = () => {
        return (
            <NmButton
                className="col-16"
                onClick={openInviteModal}
            >
                Пригласить исполнителей
            </NmButton>
        );
    };

    const modalCardLabels = isModalCard ? [
        {
            text: <Divider />,
        },
        {
            label: "План по выплатам, ₽",
            text: <div className="flex">
                <Text bold>
                    {payout}
                </Text>
                / 
                {" "}
                {totalAmount}
            </div>,
        },
        {
            label: "План по исполнителям",
            text: <div className="flex">
                <Text bold>
                    {contractorsNeededCount}
                </Text>
                / 
                {" "}
                {hiredCount}
            </div>,
        },
        {
            label: "Ожидают",
            text: awaitingCount,
            boldText: true,
        },
        {
            label: "Период работ",
            text: `${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`,
        },
    ] : [];

    return (
        <NmListCard
            alignItems="flex-end"
            secondaryHeaderStatus={
                <OrderListStatus
                    status={status}
                />
            }
            secondaryHeader={
                <div className="flex flex-aligned-center">
                    {`Заказ от ${formatLocalDate(publicationDate, "dd.MM.yyyy HH:mm")}`}
                    {
                        isNotRegistryOrApiOrder &&
                        <ViewsCount
                            className="ms-2"
                            count={orderViewCount}
                        />
                    }
                </div>
            }
            primaryHeader={getLink(`№${orderNum} - ${name}`, linkOrder)}
            primaryHeaderTwoLines
            labels={[
                !isNullOrWhitespace(registryId) && {
                    label: "Реестр оплат",
                    text: getLink(`№${registryOrdinalNumber} - ${registryName} (строка ${registrySeqNum})`, registryLink),
                },
                {
                    label: "Проект",
                    text: getLink(projectName, projectLink),
                },
                {
                    label: "Объект",
                    text: getLink(objectName, objectLink),
                },
                {
                    label: "Вид деятельности",
                    text: speciality?.value || "-",
                    noWrap: false,
                },
                {
                    label: "Договор",
                    text: ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType],
                },
                ...modalCardLabels,
            ]}
            cards={
                !isModalCard && [
                    {
                        title: "План по выплатам, ₽",
                        className: "col-16 col-md-5",
                        values: [{bold: true, text: payout}, {text: totalAmount}],
                    },
                    {
                        title: "План по исполнителям",
                        className: "col-16 col-md-4",
                        values: [{bold: true, text: hiredCount}, {text: contractorsNeededCount}],
                        overflowEllipseText: true,
                    },
                    {
                        title: "Ожидают",
                        className: "col-16 col-md-3",
                        value: awaitingCount,
                    },
                    {
                        title: "Период работ",
                        className: "col-16 col-md-4",
                        value: `${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`,
                        wrapValue: true,
                    },
                ]
            }
            cardsWithContainer={!isModalCard}
            cardsContainerClassName="col-16 col-md-16 col-xxl-8 mt-md-4 mt-xxl-0 align-items-end"
            classNameMainContent={`col-16 ${!isModalCard ? "col-xxl-5" : ""}`}
            mediaControls={
                !isModalCard && isShowInviteButton && {
                    renderCount: {
                        desktop: 1,
                        tablet: 0,
                        mobile: 0,
                    },
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                onClick: openInviteModal,
                                children: "Пригласить исполнителей",
                                color: "green",
                            },
                        },
                    ],
                }
            }
            actionsClassName="col-1 col-xxl-3 justify-content-end"
            otherContent={
                isModalCard && isShowInviteButton &&
                <div className="col-16 mt-4">
                    {getInviteButton()}
                </div>
            }
        />
    );
};

export default MapOrderListCard;