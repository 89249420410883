import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {withPageData} from "../../../components/withPageData";
import EdmStaffNewFindingForm from "./edm-staff-new-finding-form";
import EdmStaffNewFinishingForm from "./edm-staff-new-finishing-form";
import EdmStaffNewSelectingForm from "./edm-staff-new-selecting-form";
import {Dimmer, Loader} from "semantic-ui-react";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {removePhoneMask} from "../../../utils/stringFormat";

import {NEW_STAFF_FORM_STEPS, NEW_STAFF_FORM_TYPE} from "../../../constants/edmStaff";

import {
    edmStaffFindingContractorListSelector,
    edmStaffFindingUserProgressSelector,
    findContractorsForEdmStaff,
} from "../../../ducks/edmStaff";

import PropTypes from "prop-types";

import "./style.sass";

class DocumentManagementPersonnelNew extends Component {
    static propTypes = {
        onAdd: PropTypes.func,
        onCancel: PropTypes.func,
        clientId: PropTypes.string,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            pageNum: 1,
            pageSize: 25,
            step: NEW_STAFF_FORM_STEPS.FINDING,
            type: NEW_STAFF_FORM_TYPE.CONTRACTOR,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, {isLoading}) {
    }

    componentWillUnmount() {
    }

    onTypeChange = (value) => {
        this.setState({
            type: value,
        });
    };

    setStep = (step) => {
        this.setState({
            step,
        });
    };

    getBackStepForFinishingStep = () => {
        const {
            type,
        } = this.state;

        if (type === NEW_STAFF_FORM_TYPE.USER) {
            return NEW_STAFF_FORM_STEPS.FINDING;
        }

        return NEW_STAFF_FORM_STEPS.SELECTING;
    };

    getStepObj = () => {
        const {
            step,
        } = this.state;

        switch (step) {
        case NEW_STAFF_FORM_STEPS.FINDING:
            return {
                next: NEW_STAFF_FORM_STEPS.SELECTING,
                prev: null,
            };
        case NEW_STAFF_FORM_STEPS.SELECTING:
            return {
                next: NEW_STAFF_FORM_STEPS.FINISHING,
                prev: NEW_STAFF_FORM_STEPS.FINDING,
            };
        case NEW_STAFF_FORM_STEPS.FINISHING:
            return {
                next: null,
                prev: this.getBackStepForFinishingStep(),
            };
        default:
            return {};
        }
    };

    onContractorsFinded = () => {
        const {
            contractorList,
        } = this.props;

        if (contractorList.length == 1) {
            this.setState({
                step: NEW_STAFF_FORM_STEPS.FINISHING,
                user: contractorList[0],
            });
            return;
        }
        this.setStep(NEW_STAFF_FORM_STEPS.SELECTING);
    };

    onSearchContractors = (filterData) => {
        const {
            findContractorsForEdmStaff,
            clientId,
        } = this.props;

        const contractorsFilter = {
            ...filterData,
            phone: removePhoneMask(filterData.phone),
        };

        this.setState({
            contractorsFilter,
        });

        findContractorsForEdmStaff({
            data: {
                clientId,
                pageNum: 1,
                pageSize: 5,
                ...contractorsFilter,
            },
            onSuccess: this.onContractorsFinded,
        });
    };

    backStepClick = () => {
        this.setStep(this.getStepObj().prev);
    };

    onSelectUserOrContractor = (user) => {
        return () => {
            this.setState({
                user,
            });
            this.setStep(NEW_STAFF_FORM_STEPS.FINISHING);
        };
    };

    renderStep = () => {
        const {
            step,
            type,
            user,
            contractorsFilter,
        } = this.state;

        const {
            clientId,
            onCancel,
        } = this.props;

        switch (step) {
        case NEW_STAFF_FORM_STEPS.FINDING:
            return (
                <EdmStaffNewFindingForm
                    type={type}
                    clientId={clientId}
                    preloader={this.renderPreloader()}
                    onCancel={onCancel}
                    onSelectUser={this.onSelectUserOrContractor}
                    onTypeChange={this.onTypeChange}
                    onSearchContractors={this.onSearchContractors}
                />
            );
        case NEW_STAFF_FORM_STEPS.SELECTING:
            return (
                <EdmStaffNewSelectingForm
                    clientId={clientId}
                    preloader={this.renderPreloader()}
                    filter={contractorsFilter}
                    onCancel={onCancel}
                    onBackStepClick={this.backStepClick}
                    onSelectContractor={this.onSelectUserOrContractor}
                />
            );
        case NEW_STAFF_FORM_STEPS.FINISHING:
            return (
                <EdmStaffNewFinishingForm
                    type={type}
                    clientId={clientId}
                    onCancel={onCancel}
                    preloader={this.renderPreloader()}
                    user={user}
                    onBackStepClick={this.backStepClick}
                />
            );
        }
    };

    renderPreloader() {
        const {
            t,
            findingUsersProgress,
        } = this.props;

        return (
            <Dimmer
                active={findingUsersProgress}
                inverted
            >
                <Loader content={t("loader.content")} />
            </Dimmer>
        );
    };

    render() {
        return (
            <div
                className="document-management-personnel-new"
            >
                {this.renderStep()}
            </div>
        );
    }
}

export default withPageData(connect(
    state => ({
        findingUsersProgress: edmStaffFindingUserProgressSelector(state),
        contractorList: edmStaffFindingContractorListSelector(state),
    }),
    {
        findContractorsForEdmStaff,
    },
)(withTranslation()(DocumentManagementPersonnelNew)));
