import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import drawLocales from "leaflet-draw-locales";
import {isEmpty} from "lodash";

import "leaflet.markercluster";
import AdvertForMapIcon from "../../../../images/advert_for_map.svg";
import ContractorForMapIcon from "../../../../images/contractor_for_map.svg";
import CrowdTaskForMapIcon from "../../../../images/crowd-task-for-map.svg";
import OrderForMapIcon from "../../../../images/order_for_map.svg";
import SelectedAdvertForMapIcon from "../../../../images/selectedAdvertForMap.svg";
import SelectedCntrForMapIcon from "../../../../images/selectedCntrForMap.svg";
import SelectedOrderForMapIcon from "../../../../images/selectedOrderForMap.svg";
import SelectedTaskForMapIcon from "../../../../images/selectedTaskForMap.svg";
import MapWheelControl from "../wheel-control";

import {useLeafletHideLogo} from "../../../../components/OpenStreetMap/hooks/useHideLogo";
import {useGetPrevValue} from "../../../../hooks/useGetPrevValue";

import bem from "../../../../utils/bem";
import {getMapDrawSelectedMarkers} from "../../utils/getDrawSelectedMarkers";

import {
    METERS_IN_KM,
} from "../../../../constants/map";
import {
    MAP_FILTER_RADIUS_CIRCLE_OPTIONS,
    MAP_INIT_ZOOM,
    MAP_MOSCOW_CENTER_COORDS,
    MARKER_TYPE,
} from "../../constants";

import "./style.sass";

import "leaflet-draw";

drawLocales("ru");

const locale = drawLocales("ru");
locale.draw.toolbar.buttons.polygon = "Выделить область";
L.drawLocal = locale;

const OSM = props => {
    const {
        isSearch,
        onOpenModal,
        scrollWheelZoom = true,
        mapClusters,
        fetchMarkers,
        filterData,
        modalInfoData,
    } = props;

    const [block, element] = bem("map-osm");

    const filterAddressLatitude = filterData.distanceFilter.coordinateLat;
    const filterAddressLongitude = filterData.distanceFilter.coordinateLon;

    const [map, setMap] = useState(null);
    const [markersLayer, setMarkersLayer] = useState(null);

    const [selectedMarkers, setSelectedMarkersInfo] = useState({
        orders: {},
        contractors: {},
        adverts: {},
        crowdTasks: {},
    });

    const selectedMarker = useRef(null);
    const circleLayer = useRef(null);
    const selectedLayer = useRef(null);
    const featureGroup = useRef(null);
    const drawControl = useRef(null);

    const _modalInfoData = useGetPrevValue(modalInfoData);

    useLeafletHideLogo(Boolean(map));

    useEffect(() => {
        // произошло закрытие окна просмотра
        if (!isEmpty(_modalInfoData) && isEmpty(modalInfoData)) {
            if (selectedMarker.current) {
                selectedMarker.current.target.setIcon(selectedMarker.current.icon);
            }

            map.options.selectedGroupLatlng = null;
            selectedMarker.current = null;
        }
    }, [modalInfoData]);

    useEffect(() => {
        if (map) {
            const coords = getCoordsFromFilterData();

            map.setView(coords, MAP_INIT_ZOOM);
        }
    }, [filterAddressLatitude, filterAddressLongitude]);

    useEffect(() => {
        // При поиске надо затирать нарисованую область выделения при наличии
        if (isSearch) {
            if (selectedLayer && selectedLayer.current) {
                featureGroup.current.removeLayer(selectedLayer.current);
                selectedLayer.current = null;
            }
        }
    }, [isSearch]);

    useEffect(() => {
        if (isEmpty(map)) {
            return;
        }

        setControls({map});

        const markersLayer = L.layerGroup();

        setMarkersLayer(markersLayer);

        map.addLayer(markersLayer);
    }, [map]);

    const [geos, setGeos] = useState([]);

    useEffect(() => {
        const polygonInfo = geos[0];

        if (!polygonInfo) {
            return;
        }

        const polygonCoords = polygonInfo.coordinates[0];

        const polygon = L.polygon(polygonCoords);

        polygon.addTo(map);

        const selectedInfo = getMapDrawSelectedMarkers(mapClusters, polygonCoords);

        setSelectedMarkersInfo(selectedInfo);

        if (isEmpty(selectedInfo.itemMarkerList)) {
            return;
        }

        onOpenModal(selectedInfo.itemMarkerList);
    }, [geos]);

    const onDrawCreated = (event, featureGroup) => {
        if (selectedLayer && selectedLayer.current) {
            featureGroup.removeLayer(selectedLayer.current);
        }

        selectedLayer.current = null;

        map.addLayer(event.layer);

        // автозум
        map.fitBounds(event.layer.getBounds());

        //const type = event.layerType;
        const layer = event.layer;

        setDrawControls({
            edit: true,
            remove: true,
            featureGroup,
        });

        // Сохраняем слой для последующего удаления
        selectedLayer.current = layer;

        featureGroup.addLayer(layer);

        setGeos([event.layer.toGeoJSON().geometry]);
    };

    const onDrawEdited = (event) => {
        //const layers = evt.layers.getLayers();

        event.layers.eachLayer(function (layer) {
            if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
                console.log(layer.getLatLngs());

                setGeos([layer.toGeoJSON().geometry]);
            }
        });
    };

    const onDrawDeleted = (event, featureGroup) => {
        // const layers = evt.layers.getLayers();

        setDrawControls({
            edit: false,
            remove: false,
            featureGroup,
        });

        setGeos([]);
        setSelectedMarkersInfo({
            orders: {},
            contractors: {},
            adverts: {},
        });
    };

    const setDrawControls = (params) => {
        const {
            edit,
            remove,
            featureGroup,
        } = params;

        if (drawControl.current) {
            map.removeControl(drawControl.current);
        }

        drawControl.current = new L.Control.Draw({
            edit: {
                featureGroup,
                edit,
                remove,
            },
            draw: {
                polyline: false,
                polygon: true,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
            },
        });

        map.addControl(drawControl.current);
    };

    const addEvents = (params) => {
        const {
            featureGroup,
        } = params;

        map.on(L.Draw.Event.CREATED, (event) => {
            onDrawCreated(event, featureGroup);
        });
        map.on(L.Draw.Event.EDITED, onDrawEdited);
        map.on(L.Draw.Event.DELETED, (event) => {
            onDrawDeleted(event, featureGroup);
        });
    };

    const setControls = ({map}) => {
        const featureGroup = new L.FeatureGroup();

        featureGroup.current = featureGroup;
        map.addLayer(featureGroup);

        setDrawControls({
            edit: false,
            remove: false,
            featureGroup,
        });

        addEvents({
            featureGroup,
        });
    };

    useEffect(() => {
        if (map) {
            return;
        }

        const _map = L.map("map", {
            center: filterAddressLatitude && filterAddressLongitude ?
                [filterAddressLatitude, filterAddressLongitude] :
                [MAP_MOSCOW_CENTER_COORDS.LATITUDE, MAP_MOSCOW_CENTER_COORDS.LONGITUDE],
            zoom: MAP_INIT_ZOOM,
            scrollWheelZoom,
            zoomControl: true,
            //drawControl: true,
        });

        setMap(_map);

        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19,
            attribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
        }).addTo(_map);
    }, []);

    useEffect(() => {
        if (isEmpty(mapClusters) || isEmpty(map)) {
            return;
        }

        markersLayer.clearLayers();

        mapClusters.contractors && renderMarkers({
            markers: mapClusters.contractors,
            mapSelected: selectedMarkers.contractors.mapSelected,
            type: MARKER_TYPE.CONTRACTOR,
            iconSrc: ContractorForMapIcon,
            selectedIconSrc: SelectedCntrForMapIcon,
        });
        mapClusters.orders && renderMarkers({
            markers: mapClusters.orders,
            mapSelected: selectedMarkers.orders.mapSelected,
            type: MARKER_TYPE.ORDER,
            iconSrc: OrderForMapIcon,
            selectedIconSrc: SelectedOrderForMapIcon,
        });
        mapClusters.adverts && renderMarkers({
            markers: mapClusters.adverts,
            mapSelected: selectedMarkers.adverts.mapSelected,
            type: MARKER_TYPE.ADVERT,
            iconSrc: AdvertForMapIcon,
            selectedIconSrc: SelectedAdvertForMapIcon,
        });
        mapClusters.crowdTasks && renderMarkers({
            markers: mapClusters.crowdTasks,
            mapSelected: selectedMarkers.crowdTasks.mapSelected,
            type: MARKER_TYPE.CROWD_TASK,
            iconSrc: CrowdTaskForMapIcon,
            selectedIconSrc: SelectedTaskForMapIcon,
        });
    }, [mapClusters, selectedMarkers]);

    useEffect(() => {
        if (isEmpty(map)) {
            return;
        }

        if (circleLayer.current) {
            map.removeLayer(circleLayer.current);

            // нажата кнопка очистить
            // или не указан адрес
            if (!isSearch || !filterData.locationAddressFiasIdsFilter) {
                return;
            }
        }

        if (filterData.locationAddressFiasIdsFilter) {
            const coords = getCoordsFromFilterData();
            const radiusInMeter = filterData.distanceFilter.distance * METERS_IN_KM;

            circleLayer.current = L.circle(coords, radiusInMeter, MAP_FILTER_RADIUS_CIRCLE_OPTIONS);

            circleLayer.current.addTo(map);
        }
    }, [
        isSearch,
        filterData.distanceFilter,
        filterData.locationAddressFiasIdsFilter,
        filterAddressLatitude,
        filterAddressLongitude,
        map,
    ]);

    const getIcon = ({type, iconSrc, count, isSelected}) => {
        if (count > 1) {
            const className = element("counter", {
                selected: isSelected,
                gray: type === MARKER_TYPE.CONTRACTOR,
                blue: type === MARKER_TYPE.CROWD_TASK,
            });
            const html = `
                <div>
                    <img 
                        alt="" 
                        src=${iconSrc} 
                        width="32"
                        height="32" 
                     />
                    <div class="${className}">${count}</div>
                </div>
            `;

            return L.divIcon({
                html,
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                popupAnchor:  [-0, -0],
            });
        }

        return new L.Icon({
            iconUrl: iconSrc,
            iconSize: [32, 32],
            iconAnchor: [16, 16],
        });
    };

    const onMarkerClick = (params) => {
        const {
            data,
        } = params;

        props.onMarkerClick({data});
        //setLastCoords(coords);
        //map.setView(coords);
    };

    const renderMarkers = (params) => {
        const {
            markers,
            mapSelected,
            type,
            iconSrc,
            selectedIconSrc,
        } = params;

        markers.forEach(item => {
            const lat = item.clusterAvgGeoLat;
            const lon = item.clusterAvgGeoLon;
            const latLng = new L.LatLng(lat, lon);
            const icon = getIcon({
                type,
                iconSrc,
                count: item.count,
            });
            const selectedIcon = getIcon({
                type,
                iconSrc: selectedIconSrc,
                count: item.count,
                isSelected: true,
            });

            const options = {
                icon: mapSelected && mapSelected[`${lat}${lon}`] ? selectedIcon : icon,
                noActiveIcon: icon,
                style: {background: "transparent", border: "unset"},
                item: {
                    id: item.id,
                    type,
                    isDrawSelected: mapSelected && mapSelected[`${lat}${lon}`],
                },
            };

            const marker = L.marker(latLng, options).on("click", (event) => {
                //при выборе обычного маркера обнуляем выделение группы
                event.sourceTarget._map.options.selectedGroupLatlng = null;
                event.sourceTarget.options.icon = selectedIcon;
                event.target.setIcon(selectedIcon);

                if (selectedMarker.current) {
                    selectedMarker.current.target.setIcon(selectedMarker.current.target.options.noActiveIcon);
                }

                selectedMarker.current = {
                    target: event.target,
                    icon,
                };

                onMarkerClick({
                    data: [{
                        type,
                        ...item,
                    }],
                });
            });

            markersLayer.addLayer(marker);
        });
    };

    const getCoordsFromFilterData = () => {
        return filterAddressLatitude && filterAddressLongitude ?
            [filterAddressLatitude, filterAddressLongitude] :
            [MAP_MOSCOW_CENTER_COORDS.LATITUDE, MAP_MOSCOW_CENTER_COORDS.LONGITUDE];
    };

    return (
        <>
            <div
                id="map"
                className={block()}
            />
            <MapWheelControl
                map={map}
                isSearch={isSearch}
                fetchMarkers={fetchMarkers}
                filterData={filterData}
            />
        </>
    );
};

export default OSM;