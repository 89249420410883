import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_TRANSLATE} from "./finance";

export const UPLOAD_TYPE = {
    OPERATOR_REPORTS: {
        text: "Отчеты оператора",
        key: "OPERATOR_REPORTS",
        value: "OPERATOR_REPORTS",
    },
    PAYMENTS: {
        text: "Оплаты Самозанятым",
        key: "PAYMENTS",
        value: "PAYMENTS",
    },
    PAYMENTS_CIVIL: {
        text: "Оплаты по договорам НДФЛ",
        key: "PAYMENTS_CIVIL",
        value: "PAYMENTS_CIVIL",
    },
    PAYMENTS_INDIVIDUAL: {
        text: "Оплаты по договорам ИП",
        key: "PAYMENTS_INDIVIDUAL",
        value: "PAYMENTS_INDIVIDUAL",
    },
    PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING: {
        text: "1C Мультибанкинг Оплаты по договорам ИП",
        key: "PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING",
        value: "PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING",
    },
    SUMMARY: {
        text: "Сводная (НПД)",
        key: "SUMMARY",
        value: "SUMMARY",
    },
    SUMMARY_CIVIL: {
        text: "Сводная (НДФЛ)",
        key: "SUMMARY_CIVIL",
        value: "SUMMARY_CIVIL",
    },
    CONTRACTORS: {
        text: "Исполнители",
        key: "CONTRACTORS",
        value: "CONTRACTORS",
    },
    CONTRACTORS_PAYMENTS: {
        text: "Оплаты по исполнителям",
        key: "CONTRACTORS_PAYMENTS",
        value: "CONTRACTORS_PAYMENTS",
    },
    PAYMENT_CONFIRMATION: {
        text: "Подтверждение оплат",
        key: "PAYMENT_CONFIRMATION",
        value: "PAYMENT_CONFIRMATION",
    },
    DEPOSIT: {
        text: "Депозит",
        key: "SUMMARY",
        value: "SUMMARY",
    },
    CLIENT_DEPOSIT: {
        text: "История депозита (НПД)",
        key: "CLIENT_DEPOSIT",
        value: "CLIENT_DEPOSIT",
    },
    CLIENT_DEPOSIT_CIVIL: {
        text: "История депозита (НДФЛ)",
        key: "CLIENT_DEPOSIT_CIVIL",
        value: "CLIENT_DEPOSIT_CIVIL",
    },
    ORDERS_INFORMATION: {
        text: "Заказы",
        key: "ORDERS_INFORMATION",
        value: "ORDERS_INFORMATION",
    },
    LEADS_FROM_CLIENTS: {
        text: "Лиды от клиентов",
        key: "LEADS_FROM_CLIENTS",
        value: "LEADS_FROM_CLIENTS",
    },
    CONTRACTORS_ON_ORDERS: {
        text: "Исполнители на заказах",
        key: "CONTRACTORS_ON_ORDERS",
        value: "CONTRACTORS_ON_ORDERS",
    },
    RESPONSE_ANALYTICS: {
        text: "Аналитика по откликам",
        key: "RESPONSE_ANALYTICS",
        value: "RESPONSE_ANALYTICS",
    },
    ORDERS_ANALYTICS: {
        text: "Аналитика по заказам",
        key: "ORDERS_ANALYTICS",
        value: "ORDERS_ANALYTICS",
    },
    OBJECT_ORDER_WORK_REPORT: {
        text: "Оплаты по объектам",
        key: "OBJECT_ORDER_WORK_REPORT",
        value: "OBJECT_ORDER_WORK_REPORT",
    },
    ALL_PAYMENTS: {
        text: "Все выплаты",
        key: "ALL_PAYMENTS",
        value: "ALL_PAYMENTS",
    },
    MY_CONTRACTORS: {
        text: "Мои исполнители (самозанятые)",
        key: "MY_CONTRACTORS",
        value: "MY_CONTRACTORS",
    },
    ACCEPTED_CONTRACTORS_FOR_ORDERS: {
        text: "Принятые исполнители на заказы ",
        key: "ACCEPTED_CONTRACTORS_FOR_ORDERS",
        value: "ACCEPTED_CONTRACTORS_FOR_ORDERS",
    },
    DECLINED_CONTRACTORS_FOR_ORDERS: {
        text: "Снятые исполнители с заказов",
        key: "DECLINED_CONTRACTORS_FOR_ORDERS",
        value: "DECLINED_CONTRACTORS_FOR_ORDERS",
    },
    BANK_DEPOSIT_HISTORY_EXPORT: {
        text: "Банковская выписка",
        key: "BANK_DEPOSIT_HISTORY_EXPORT",
        value: "BANK_DEPOSIT_HISTORY_EXPORT",
    },
    CLIENT_STOP_LIST_EXPORT: {
        text: "Стоп-лист компании",
        key: "CLIENT_STOP_LIST_EXPORT",
        value: "CLIENT_STOP_LIST_EXPORT",
    },
    PROMOCODES_REFERRAL_CLIENTS: {
        text: "Промо-коды компаний",
        key: "PROMOCODES_REFERRAL_CLIENTS",
        value: "PROMOCODES_REFERRAL_CLIENTS",
    },
    PROMOCODES_REFERRAL_CONTRACTORS: {
        text: "Промо-коды исполнителей",
        key: "PROMOCODES_REFERRAL_CONTRACTORS",
        value: "PROMOCODES_REFERRAL_CONTRACTORS",
    },
    CONTRACTOR_RETAIL_EXPORT: {
        text: "Ресурсы",
        key: "CONTRACTOR_RETAIL_EXPORT",
        value: "CONTRACTOR_RETAIL_EXPORT",
    },
    ORDER_WORK_REPORT_CANCELLED_EXPORT: {
        text: "Аннулированные чеки ФНС России",
        key: "ORDER_WORK_REPORT_CANCELLED_EXPORT",
        value: "ORDER_WORK_REPORT_CANCELLED_EXPORT",
    },
    CLIENT_COMMISSION_HISTORY_EXPORT: {
        text: "История процентных ставок заказчиков",
        key: "CLIENT_COMMISSION_HISTORY_EXPORT",
        value: "CLIENT_COMMISSION_HISTORY_EXPORT",
    },
    CHECK_IN_OUT_EXPORT: {
        text: "Фото-отчет по отметкам о прибытии/уходе на заказах",
        key: "CHECK_IN_OUT_EXPORT",
        value: "CHECK_IN_OUT_EXPORT",
    },
    CHECK_IN_OUT_EXPORT_CROWD_TASK: {
        text: "Фото-отчет по отметкам о прибытии/уходе на заданиях",
        key: "CHECK_IN_OUT_EXPORT_CROWD_TASK",
        value: "CHECK_IN_OUT_EXPORT_CROWD_TASK",
    },
    CHECK_IN_OUT_LIST_EXPORT: {
        text: "Отчёт по отметкам о прибытии/уходе на заказах",
        key: "CHECK_IN_OUT_LIST_EXPORT",
        value: "CHECK_IN_OUT_LIST_EXPORT",
    },
    CROWD_CHECK_IN_OUT_LIST_EXPORT: {
        text: "Отчёт по отметкам о прибытии/уходе на заданиях",
        key: "CROWD_CHECK_IN_OUT_LIST_EXPORT",
        value: "CROWD_CHECK_IN_OUT_LIST_EXPORT",
    },
    AGREEMENTS_EDO: {
        text: "Документы ЭДО",
        key: "AGREEMENTS_EDO",
        value: "AGREEMENTS_EDO",
    },
    AGREEMENTS_EDO_WITH_PAYMENT: {
        text: "Расчет стоимости ЭДО",
        key: "AGREEMENTS_EDO_WITH_PAYMENT",
        value: "AGREEMENTS_EDO_WITH_PAYMENT",
    },
    STAFF_EDO: {
        text: "Кадры ЭДО",
        key: "STAFF_EDO",
        value: "STAFF_EDO",
    },
    NPD_PAYMENTS_EXPORT: {
        text: "Аналитика по оплатам НПД",
        key: "NPD_PAYMENTS_EXPORT",
        value: "NPD_PAYMENTS_EXPORT",
    },
    FRAME_CONTRACTS_EXPORT: {
        text: "Договоры с исполнителями",
        key: "FRAME_CONTRACTS_EXPORT",
        value: "FRAME_CONTRACTS_EXPORT",
    },
    ANALYTICS_INVITE_CONTRACTORS: {
        text: "Аналитика по приглашениям",
        key: "ANALYTICS_INVITE_CONTRACTORS",
        value: "ANALYTICS_INVITE_CONTRACTORS",
    },
    PATENT_PAYMENTS: {
        text: "Оплаты по патентам",
        key: "PATENT_PAYMENTS",
        value: "PATENT_PAYMENTS",
    },
    REGISTRY_EXPORT : {
        text: "Реестр платежей",
        key: "REGISTRY_EXPORT",
        value: "REGISTRY_EXPORT",
    },
    REGISTRY_EXPORT_WITH_TABLE : {
        text: "Реестр платежей",
        key: "REGISTRY_EXPORT_WITH_TABLE",
        value: "REGISTRY_EXPORT_WITH_TABLE",
    },
    REGISTRY_INVITATION_EXPORT : {
        text: "Реестр приглашений",
        key: "REGISTRY_INVITATION_EXPORT",
        value: "REGISTRY_INVITATION_EXPORT",
    },
    ACT_REGISTRY_EXPORT : {
        text: "Реестр актов",
        key: "ACT_REGISTRY_EXPORT",
        value: "ACT_REGISTRY_EXPORT",
    },
    CONTRACTOR_COMMISSION_HISTORY: {
        text: "История процентных ставок исполнителей",
        key: "CONTRACTOR_COMMISSION_HISTORY",
        value: "CONTRACTOR_COMMISSION_HISTORY",
    },
    PROJECTS_AND_OBJECTS: {
        text: "Проекты и объекты",
        key: "PROJECTS_AND_OBJECTS",
        value: "PROJECTS_AND_OBJECTS",
    },
    PUBLIC_CLIENT_APPLICATION_EXPORT: {
        text: "Заявки на подключение",
        key: "PUBLIC_CLIENT_APPLICATION_EXPORT",
        value: "PUBLIC_CLIENT_APPLICATION_EXPORT",
    },
    EDO_DOCUMENTS_REGISTRY_EXPORT: {
        text: "Реестр на подписание документов в ЭДО",
        key: "EDO_DOCUMENTS_REGISTRY_EXPORT",
        value: "EDO_DOCUMENTS_REGISTRY_EXPORT",
    },
    TICKET_EXPORT: {
        text: "Обращения",
        key: "TICKET_EXPORT",
        value: "TICKET_EXPORT",
    },
    OBJECTS_DEPOSIT: {
        text: "Остаток депозитов по объектам",
        key: "OBJECTS_DEPOSIT",
        value: "OBJECTS_DEPOSIT",
    },
    ATOL_RECEIPTS_EXPORT: {
        text: "Чеки Атол",
        key: "ATOL_RECEIPTS_EXPORT",
        value: "ATOL_RECEIPTS_EXPORT",
    },
    OVM_NOTIFICATIONS_EXPORT: {
        text: "Уведомления ОВМ для ГосУслуг",
        key: "OVM_NOTIFICATIONS_EXPORT",
        value: "OVM_NOTIFICATIONS_EXPORT",
    },
    PAID_APIS: {
        text: "Тарифицируемые вызовы API",
        key: "PAID_APIS",
        value: "PAID_APIS",
    },
    PAYMENTS_1C: {
        text: "1C Оплаты Самозанятым",
        key: "PAYMENTS_1C",
        value: "PAYMENTS_1C",
    },
    PAYMENTS_CIVIL_1C: {
        text: "1C Оплаты по договорам НДФЛ",
        key: "PAYMENTS_CIVIL_1C",
        value: "PAYMENTS_CIVIL_1C",
    },
    RESPONSES_TO_ORDERS: {
        text: "Отклики по заказам",
        key: "RESPONSES_TO_ORDERS",
        value: "RESPONSES_TO_ORDERS",
    },
    MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT: {
        text: "Акт сверки взаиморасчетов",
        key: "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT",
        value: "MUTUAL_SETTLEMENTS_RECONCILIATION_ACT_EXPORT",
    },
    PAYMENTS_1C_MULTI_BANKING: {
        text: "1C Мультибанкинг Оплаты Самозанятым",
        key: "PAYMENTS_1C_MULTI_BANKING",
        value: "PAYMENTS_1C_MULTI_BANKING",
    },
    PAYMENTS_CIVIL_1C_MULTI_BANKING: {
        text: "1C Мультибанкинг Оплаты по договорам НДФЛ",
        key: "PAYMENTS_CIVIL_1C_MULTI_BANKING",
        value: "PAYMENTS_CIVIL_1C_MULTI_BANKING",
    },
    TAX_PAYMENTS_NPD: {
        text: "Отчет о списании системных транзакций с 302 счета Наймикс",
        key: "TAX_PAYMENTS_NPD",
        value: "TAX_PAYMENTS_NPD",
    },
    SYSTEM_TRANSACTION_TKB_EXPORT : {
        text: "Отчет о списании системных транзакций с 302 счета Наймикс ТКБ",
        key: "SYSTEM_TRANSACTION_TKB_EXPORT",
        value: "SYSTEM_TRANSACTION_TKB_EXPORT",
    },
    REFERRAL_PROMO_CODE_CLIENTS_REWARDS: {
        text: "Вознаграждения партнёра",
        key: "REFERRAL_PROMO_CODE_CLIENTS_REWARDS",
        value: "REFERRAL_PROMO_CODE_CLIENTS_REWARDS",
    },
    RECRUITMENT_RESPONSES_CANDIDATES_EXPORT: {
        text: "Отклики и кандидаты по вакансии",
        key: "RECRUITMENT_RESPONSES_CANDIDATES_EXPORT",
        value: "RECRUITMENT_RESPONSES_CANDIDATES_EXPORT",
    },
    CROWD_TASK_EXPORT: {
        text: "Задания",
        key: "CROWD_TASK_EXPORT",
        value: "CROWD_TASK_EXPORT",
    },
    CROWD_TASK_GROUP_EXPORT: {
        text: "Группа заданий",
        key: "CROWD_TASK_GROUP_EXPORT",
        value: "CROWD_TASK_GROUP_EXPORT",
    },
    PROJECT_EMBEDDED_OBJECTS_EXPORT_FOR_OPTIMUM: {
        text: "Объекты",
        key: "PROJECT_EMBEDDED_OBJECTS_EXPORT_FOR_OPTIMUM",
        value: "PROJECT_EMBEDDED_OBJECTS_EXPORT_FOR_OPTIMUM",
    },
    CROWD_TASK_ANALYTICS_EXPORT: {
        text: "Аналитика по заданиям",
        key: "CROWD_TASK_ANALYTICS_EXPORT",
        value: "CROWD_TASK_ANALYTICS_EXPORT",
    },
    MAP_ENTRIES_EXPORT: {
        text: "Элементы карты",
        key: "MAP_ENTRIES_EXPORT",
        value: "MAP_ENTRIES_EXPORT",
    },
    CROWD_ACT_REGISTRY_EXPORT: {
        text: "Реестр актов по заданиям",
        key: "CROWD_ACT_REGISTRY_EXPORT",
        value: "CROWD_ACT_REGISTRY_EXPORT",
    },
    ORDER_TEMPLATES_EXPORT: {
        text: "Шаблоны заказов",
        key: "ORDER_TEMPLATES_EXPORT",
        value: "ORDER_TEMPLATES_EXPORT",
    },
};

export const PERIOD_OPTION_DICT = {
    THREE_MONTH: {
        text: "За последние 3 мес",
        key: "THREE_MONTH",
        value: "THREE_MONTH",
    },
    SIX_MONTH: {
        text: "За последние 6 мес",
        key: "SIX_MONTH",
        value: "SIX_MONTH",
    },
    NINE_MONTH: {
        text: "За последние 9 мес",
        key: "NINE_MONTH",
        value: "NINE_MONTH",
    },
    YEAR: {
        text: "За последние 12 мес",
        key: "YEAR",
        value: "YEAR",
    },
};

export const PERIOD_OPTIONS_OPTIONS = [
    PERIOD_OPTION_DICT.THREE_MONTH,
    PERIOD_OPTION_DICT.SIX_MONTH,
    PERIOD_OPTION_DICT.NINE_MONTH,
    PERIOD_OPTION_DICT.YEAR,
];

export const UPLOAD_STATUS = {
    INITIALIZATION: {
        text: "В работе",
        key: "INITIALIZATION",
        mod: "light-green",
    },
    SUCCESSFULLY: {
        text: "Успешно",
        key: "SUCCESSFULLY",
        mod: "green",
    },
    UNSUCCESSFUL: {
        text: "Неуспешно",
        key: "UNSUCCESSFUL",
        mod: "red",
    },
    CANCELLED: {
        text: "Отменено",
        key: "CANCELLED",
        mod: "red",
    },
};

export const formatFileDict = {
    XLSX: "XLSX",
    CSV: "CSV",
    XML: "XML",
    ZIP: "ZIP",
    PDF: "PDF",
};

export const FINANCE_EXPORT_OPTION_XML = {
    key: formatFileDict.XML,
    text : formatFileDict.XML,
    value: formatFileDict.XML,
};

export const formatFileOptions = [
    {
        key: formatFileDict.XLSX,
        text : formatFileDict.XLSX,
        value: formatFileDict.XLSX,
    },
    {
        key: formatFileDict.CSV,
        text : formatFileDict.CSV,
        value: formatFileDict.CSV,
    },
];

export const FINANCE_EXPORT_UPLOAD_TYPE = {
    ORDER_WORK_REPORT_CANCELLED_EXPORT: "ORDER_WORK_REPORT_CANCELLED_EXPORT",
};

export const FINANCE_EXPORT_DEPOSIT_TYPE_FILTER_OPTIONS = [
    {
        key: ORDER_WORK_REPORT_TYPE.SMZ,
        text: ORDER_WORK_REPORT_TYPE_TRANSLATE[ORDER_WORK_REPORT_TYPE.SMZ],
        value: ORDER_WORK_REPORT_TYPE.SMZ,
    },
    {
        key: ORDER_WORK_REPORT_TYPE.CIVIL,
        text: ORDER_WORK_REPORT_TYPE_TRANSLATE[ORDER_WORK_REPORT_TYPE.CIVIL],
        value: ORDER_WORK_REPORT_TYPE.CIVIL,
    },
];

export const FINANCE_EXPORT_OVM_NOTIFICATION_TYPE = {
    CONCLUSION_CONTRACT: "CONCLUSION_CONTRACT",
    EXPIRATION_CONTRACT: "EXPIRATION_CONTRACT",
};

export const FINANCE_EXPORT_OVM_NOTIFICATION_TYPE_OPTIONS = [
    {
        key: FINANCE_EXPORT_OVM_NOTIFICATION_TYPE.CONCLUSION_CONTRACT,
        text: "Заключение договора",
        value: FINANCE_EXPORT_OVM_NOTIFICATION_TYPE.CONCLUSION_CONTRACT,

    },
    {
        key: FINANCE_EXPORT_OVM_NOTIFICATION_TYPE.EXPIRATION_CONTRACT,
        text: "Расторжение договора",
        value: FINANCE_EXPORT_OVM_NOTIFICATION_TYPE.EXPIRATION_CONTRACT,
    },
];

export const FINANCE_EXPORT_PAGE_TYPE = {
    COMMON : "COMMON",
    CLIENT_1C: "CLIENT_1C",
};