import {getFullRegistrationInitialSeriesAndNumber} from "./getInitialSeriesAndNumber";
import {getFullRegistrationVhiPolicyType} from "./getVhiPolicyType";

import {convertStringToDate} from "../../../utils/stringHelper";

import {citizenshipsDict} from "../../../constants/citizenships";
import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    CONTRACTOR_MIGRATION_STATUS,
    CONTRACTOR_VHI_POLICE_FORMAT,
    EDM_PASSPORT_TYPE,
} from "../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../constants/contractorInfo";
import {FULL_REGISTRATION_GENDER_DICT} from "../constants";

export const getFullRegistrationInitialValues = (contractor) => {
    const initialCitizenship = contractor.locatedOutsideRussia ? citizenshipsDict.AM.value : citizenshipsDict.RU.value;

    return {
        migrationStatus: contractor.migrationStatus || CONTRACTOR_MIGRATION_STATUS.NONE,
        lastName: contractor.lastName || "",
        firstName: contractor.firstName || "",
        patronymic: contractor.patronymic || "",
        citizenship: contractor.citizenship || initialCitizenship,
        refugeeCertificateNum: contractor.refugeeCertificateNum || "",
        refugeeCertificateIssuedBy: contractor.refugeeCertificateIssuedBy || "",
        refugeeCertificatePersonalFileNum: contractor.refugeeCertificatePersonalFileNum || "",
        refugeeCertificateBirthPlace: contractor.refugeeCertificateBirthPlace || "",
        refugeeCertificateValidToDate: convertStringToDate(contractor.refugeeCertificateValidToDate) || null,
        temporaryRefugeeCertificateNum: contractor.temporaryRefugeeCertificateNum || "",
        temporaryRefugeeCertificateValidToDate: convertStringToDate(contractor.temporaryRefugeeCertificateValidToDate) || null,
        temporaryRefugeeCertificatePersonalFileNum: contractor.temporaryRefugeeCertificatePersonalFileNum || "",
        temporaryRefugeeCertificateBirthPlace: contractor.temporaryRefugeeCertificateBirthPlace || "",
        temporaryRefugeeCertificateIssuedBy: contractor.temporaryRefugeeCertificateIssuedBy || "",
        statelessPersonTemporaryIdNum: contractor.statelessPersonTemporaryIdNum || "",
        statelessPersonTemporaryIdIssuedDate: convertStringToDate(contractor.statelessPersonTemporaryIdIssuedDate) || null,
        statelessPersonTemporaryIdValidToDate: convertStringToDate(contractor.statelessPersonTemporaryIdValidToDate) || null,
        statelessPersonTemporaryIdBirthPlace: contractor.statelessPersonTemporaryIdBirthPlace || "",
        statelessPersonTemporaryIdIssueReason: contractor.statelessPersonTemporaryIdIssueReason || "",
        statelessPersonTemporaryIdIssuedBy: contractor.statelessPersonTemporaryIdIssuedBy || "",
        idDocNumber: contractor.idDocNumber || "",
        birthPlace: contractor.birthPlace || "",
        idDocIssuedBy: contractor.idDocIssuedBy || "",
        idDocIssuedByDepartmentCode: contractor.idDocIssuedByDepartmentCode || "",
        ein: contractor.ein || "",
        address: contractor.address || "",
        mainDocumentStampAddress: contractor.mainDocumentStampAddress || "",
        residencePermitNumber: getFullRegistrationInitialSeriesAndNumber(contractor.residencePermitNumber).number,
        residencePermitSeries: getFullRegistrationInitialSeriesAndNumber(contractor.residencePermitNumber).series,
        mainDocumentStampValidToDate: convertStringToDate(contractor.mainDocumentStampValidToDate) || null,
        residencePermitDecisionNumber: contractor.residencePermitDecisionNumber || "",
        residencePermitDecisionDate: convertStringToDate(contractor.residencePermitDecisionDate) || null,
        residencePermitIssuedDate: convertStringToDate(contractor.residencePermitIssuedDate) || null,
        residencePermitValidToDate: convertStringToDate(contractor.residencePermitValidToDate) || null,
        residencePermitIssuedBy: contractor.residencePermitIssuedBy || "",
        temporaryResidencePermitNum: contractor.temporaryResidencePermitNum || "",
        temporaryResidencePermitDecisionDate: convertStringToDate(contractor.temporaryResidencePermitDecisionDate) || null,
        temporaryResidencePermitValidToDate: convertStringToDate(contractor.temporaryResidencePermitValidToDate) || null,
        temporaryResidencePermitDecisionIssuedBy: contractor.temporaryResidencePermitDecisionIssuedBy || "",
        vhiPolicyValidFromDate: convertStringToDate(contractor.vhiPolicyValidFromDate) || null,
        vhiPolicyValidToDate: convertStringToDate(contractor.vhiPolicyValidToDate) || null,
        visaNum: contractor.visaNum || "",
        visaId: contractor.visaId || "",
        statelessPersonResidencePermitNumber: contractor.statelessPersonResidencePermitNumber || "",
        statelessPersonResidencePermitIssuedBy: contractor.statelessPersonResidencePermitIssuedBy || "",
        statelessPersonResidencePermitIssuedDate: convertStringToDate(contractor.statelessPersonResidencePermitIssuedDate) || null,
        statelessPersonResidencePermitValidToDate: convertStringToDate(contractor.statelessPersonResidencePermitValidToDate) || null,
        employmentPatentSeries: contractor.employmentPatentSeries || "",
        employmentPatentNum: contractor.employmentPatentNum || "",
        employmentPatentRegistrationNumber: contractor.employmentPatentRegistrationNumber || "",
        migrationCardSeries: getFullRegistrationInitialSeriesAndNumber(contractor.migrationCardNumber).series,
        migrationCardNumber: getFullRegistrationInitialSeriesAndNumber(contractor.migrationCardNumber).number,
        arrivalNoticeStayEndDate: convertStringToDate(contractor.arrivalNoticeStayEndDate) || null,
        registrationDate: convertStringToDate(contractor.registrationDate) || null,
        migrationCardPresent: typeof contractor.migrationCardPresent === "boolean" ?
            contractor.migrationCardPresent : true, // Миграционная карта присутствует
        vhiPolicyPresent: typeof contractor.vhiPolicyPresent === "boolean" ?
            contractor.vhiPolicyPresent : true, // Полис ДМС или ОМС присутствует
        gender: contractor.gender || FULL_REGISTRATION_GENDER_DICT.M,
        birthDate: convertStringToDate(contractor.birthDate) || null,
        idDocIssuedDate: convertStringToDate(contractor.idDocIssuedDate) || null,
        idDocValidToDate: convertStringToDate(contractor.idDocValidToDate) || null,
        passportType: contractor.passportType || CONTRACTOR_PASSPORT_TYPE.INTERNATIONAL_PASSPORT,
        edmPassportType: contractor.edmPassportType || EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
        vhiPolicyType: contractor.vhiPolicyType || getFullRegistrationVhiPolicyType(contractor).value,
        vhiPolicyFormat: contractor.vhiPolicyFormat || CONTRACTOR_VHI_POLICE_FORMAT.PAPER, // по умолчанию выбран бумажный
        migrationCardIssuedDate: convertStringToDate(contractor.migrationCardIssuedDate) || null,
        migrationCardValidToDate: convertStringToDate(contractor.migrationCardValidToDate) || null,
        visaIssuedDate: convertStringToDate(contractor.visaIssuedDate) || null,
        visaActiveFromDate: convertStringToDate(contractor.visaActiveFromDate) || null,
        visaActiveUntilDate: convertStringToDate(contractor.visaActiveUntilDate) || null,
        employmentPatentIssuedDate: convertStringToDate(contractor.employmentPatentIssuedDate) || null,
        refugeeCertificateIssuedDate: convertStringToDate(contractor.refugeeCertificateIssuedDate) || null,
        temporaryRefugeeCertificateIssuedDate: convertStringToDate(contractor.temporaryRefugeeCertificateIssuedDate) || null,
        mainDocumentStampIssuedDate: convertStringToDate(contractor.mainDocumentStampIssuedDate) || null,
        arrivalDocType: contractor.arrivalDocType || CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
        hasInn: typeof contractor.needInnScan === "boolean" ? contractor.needInnScan : true, // Перекючатель "Лист с ИНН присутствует",
        inn: contractor.inn || "",
        locatedOutsideRussia: Boolean(contractor.locatedOutsideRussia),
    };
};