import React, {useEffect, useRef,useState} from "react";
import {withTranslation} from "react-i18next";

import NmModal from "../../ActualComponents/NmModal";
import ApplyButtons from "../../ApplyButtons";

import {toastError} from "../../../utils/toastHelper";

import PropTypes from "prop-types";

import "./style.sass";

const TIME_ITEM_HEIGHT = 38;

const getTimeStartValues = () => {
    const hoursInDay = 24;
    const arr = [];

    for (let i = 0; i < hoursInDay; i++) {
        const hours = String(i).length !== 2 ? `0${i}` : i;

        arr.push(`${hours}:00`);
        arr.push(`${hours}:30`);
    }

    return arr;
};

const getTimeEndValues = () => {
    const hoursInDay = 24;
    const arr = [];

    for (let i = 0; i < hoursInDay; i++) {
        const hours = String(i).length !== 2 ? `0${i}` : i;

        if (i !== 0) {
            arr.push(`${hours}:00`);
        }

        arr.push(`${hours}:30`);
    }

    arr.push("00:00");

    return arr;
};

const isMoreStartTime = (startTime, endTime) => {
    const numStartTime = +startTime.replace(":", "");
    const numEndTime = +endTime.replace(":", "");

    return numStartTime >= numEndTime;
};

const startValues = getTimeStartValues();
const endValues = getTimeEndValues();

const OrderScheduleAdd = (props) => {
    const {
        close,
        submit,
        // фильтрация конечных значений взавимости от выбранного начального
        isFilteringEndValues = true,
        times = [],
        initialTime,
        t,
    } = props;
    const [time, setTime] = useState({
        start: initialTime?.from || "09:00",
        end: initialTime?.to || "18:00",
    });
    const [timeValues, setTimeValues] = useState([]);
    const $startTime = useRef(null);
    const $endTime = useRef(null);
    const [loadedEffects, setLoadedEffects] = useState(false);

    useEffect(() => {
        const {start} = time;

        if (isFilteringEndValues) {
            updateTimeEndValues(start);
        } else {
            setTimeValues(endValues);
        }

    }, []);

    useEffect(() => {
        if ($startTime && $startTime.current) {
            const scrollTop = startValues.findIndex((item) => item === time.start);

            $startTime.current.scrollTop = scrollTop * TIME_ITEM_HEIGHT - TIME_ITEM_HEIGHT * 2;
        }
    }, [$startTime.current]);

    useEffect(() => {
        if (!loadedEffects && $endTime && $endTime.current && timeValues.length !== 0) {
            setLoadedEffects(true);

            const scrollTop = timeValues.findIndex((item) => item === time.end);

            $endTime.current.scrollTop = scrollTop * TIME_ITEM_HEIGHT - TIME_ITEM_HEIGHT * 2;
        }
    }, [timeValues, $endTime.current]);

    const updateTimeEndValues = (startTime) => {
        const filtered = endValues.filter(endTime => endTime === "00:00" || !isMoreStartTime(startTime, endTime));

        setTimeValues(filtered);
    };

    const selectTime = ({name, selectedTime}) => {
        if (isFilteringEndValues) {
            const {end} = time;

            if (name === "start") {
                updateTimeEndValues(selectedTime);
            }

            if (isMoreStartTime(selectedTime, end) && name === "start") {
                const i = endValues.findIndex(time => (time === selectedTime));

                setTime({
                    end: endValues[i + 1],
                    start: selectedTime,
                });

                return;
            }
        }

        setTime({
            ...time,
            [name]: selectedTime,
        });
    };

    const submitForm = () => {
        const {start: startWorkTime, end: endWorkTime} = time;

        let errorCount = 0;

        const selectedStart = +startWorkTime.replace(":", "");
        const selectedEnd = +endWorkTime.replace(":", "");

        times.forEach(value => {
            let {startWorkTime: start, endWorkTime: end} = value;
            start = +start.replace(":", "");
            end = +end.replace(":", "");

            if (start === 0 && end === 0) {
                errorCount++;
            }

            const isStartTimeInside = selectedStart >= start && selectedStart < end;
            const isEndTimeInside = selectedEnd > start && selectedEnd <= end;
            const isStartTimeOutside = selectedStart < start;
            const isEndTimeOutside = selectedEnd > end && end !== 0;

            if (isStartTimeInside || isEndTimeInside ||
                selectedStart === start && selectedEnd === end ||
                selectedStart <= start && isEndTimeInside ||
                isStartTimeOutside && isEndTimeOutside
            ) {
                errorCount++;
            }
        });

        if (errorCount > 0) {
            toastError("Выбранный интервал времени не добавлен, так как полностью или частично пересекается с уже имеющимся интервалом в рамках данного дня");
            close();

            return;
        }

        const start = startWorkTime.split(":");
        const end = endWorkTime.split(":");

        if ((new Date()).setHours(start[0], start[1], 0) > (new Date()).setHours(end[0], end[1], 0)) {
            toastError("\"Значение \"с\" должно быть меньше или равно, чем значение \"по\"");
            return;
        }

        close();
        submit({startWorkTime, endWorkTime});
    };

    const getStartActiveClass = (mapTime) => {
        const {start} = time;

        if (start === mapTime) {
            return "order-schedule-add__time order-schedule-add__time_selected";
        }

        return "order-schedule-add__time";
    };

    const getEndActiveClass = (mapTime) => {
        const {end} = time;

        if (end === mapTime) {
            return "order-schedule-add__time order-schedule-add__time_selected";
        }

        return "order-schedule-add__time";
    };

    const getCols = () => {
        return (
            <div className="order-schedule-add__form">
                <div className="order-schedule-add__col order-schedule-add__col_start">
                    <div
                        ref={$startTime}
                        className="order-schedule-add__container"
                    >
                        {
                            startValues.map((time, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={getStartActiveClass(time)}
                                        onClick={() => {selectTime({name: "start", selectedTime: time});}}
                                    >
                                        {time}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="order-schedule-add__col order-schedule-add__col_end">
                    <div
                        ref={$endTime}
                        className="order-schedule-add__container"
                    >
                        {
                            timeValues.map((time, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={getEndActiveClass(time)}
                                        onClick={() => {selectTime({name: "end", selectedTime: time});}}
                                    >
                                        {time}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    };

    return (
        <NmModal
            zIndex={1500}
            onClose={close}
            className="order-schedule-add"
            footer={
                <ApplyButtons
                    submitBtnContent="Ок"
                    cancelBtnContent={t("button.cancel")}
                    onClose={close}
                    submit={submitForm}
                />
            }
        >
            {getCols()}
        </NmModal>
    );
};

OrderScheduleAdd.propTypes = {
    close: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
};

export default withTranslation()(OrderScheduleAdd);