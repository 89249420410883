import React, {ReactNode} from "react";

import {ReactComponent as CheckIcon} from "../../images/checkV2.svg";
import {ReactComponent as RemoveIcon} from "../../images/remove_24.svg";
import Delimiter from "../ActualComponents/Delimiter";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import bem from "../../utils/bem";

import "./style.sass";

type Item = {
    name: string,
    description?: string,
    publishPossible?: string
};

interface IMassConfirm {
    className?: string,
    title?: string,
    text: string,
    unablePublishTooltip?: string,
    onCancel: (event?: any) => void,
    onConfirm: (event?: any) => void,
    confirmButton: string,
    cancelButton: string,
    list?: Array<Item>,
    isOnlyConfirm?: boolean,
    disabled?: boolean,
    loading?: boolean,
    publicationCount?: number
    impossibleCount?: number,
    warningText?: string,
    successLabel?: string,
    warningLabel?: string,
    content?: ReactNode,
    publishTooltip?: string,
    isShowIconItems?: boolean,
    isShowCounters?: boolean,
    isWarningLabel?: boolean,
    classNameList?: string,
    additionalContent?: ReactNode,
    isShowDelimiter?: boolean,
}

const MassConfirm = (props: IMassConfirm) => {
    const {
        className = "",
        title,
        onCancel,
        onConfirm,
        isOnlyConfirm = true,
        confirmButton,
        cancelButton,
        list = [],
        unablePublishTooltip = "",
        text,
        publicationCount = 0,
        impossibleCount = 0,
        successLabel = "К публикации",
        warningLabel = "Невозможно закрыть",
        loading,
        disabled,
        warningText,
        publishTooltip,
        content,
        isShowIconItems = true,
        isShowCounters = true,
        isShowDelimiter = false,
        classNameList = "",
        additionalContent,
    } = props;

    const [block, element] = bem("mass-confirm", className);

    const isNoList = list.length === 0 && !content;
    const isWarningLabel = props.isWarningLabel || impossibleCount > 0;

    const submit = () => {
        if (isOnlyConfirm) {
            return onConfirm();
        }

        onConfirm();
        onCancel();
    };

    const getContent = () => {
        return list.map(({name, publishPossible}, index) => (
            isShowIconItems ?
                <div
                    key={index}
                    className={element("list-item")}
                >
                    <div className={element("list-item-icon", {color: publishPossible ? "primary" : "inert"})}>
                        {
                            publishPossible ?
                                <CheckIcon className={element("list-item-icon-elem")} /> :
                                <RemoveIcon className={element("list-item-icon-elem")} />
                        }
                    </div>
                    <Text
                        className={element("list-text")}
                        level="3"
                    >
                        {name}
                    </Text>
                </div> :
                <li className={element("list-item")}>
                    {
                        isShowDelimiter &&
                        <Delimiter className="ms-2 me-2" />
                    }
                    {name}
                </li>
        ));
    };

    return (
        <NmModal
            classNameContent={block()}
            size="md"
            contentAlign="left"
            onClose={onCancel}
            header={
                title &&
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submit={submit}
                    submitBtnContent={confirmButton}
                    onClose={onCancel}
                    cancelBtnContent={cancelButton}
                    disabled={loading || disabled}
                />
            }
        >
            {
                !isNoList && isShowCounters &&
                <div className={element("counters")}>
                    <div className={element("counters-container")}>
                        <Text
                            className={element("counters-item", {color: "primary"})}
                            level="2"
                        >
                            {`${successLabel}: ${publicationCount}`}
                        </Text>
                        {
                            publishTooltip &&
                            <HelpTooltip
                                className={element("counters-tooltip")}
                                hover
                                width={18}
                                height={18}
                                text={publishTooltip}
                                type="light"
                                position="bottom-left"
                            />
                        }
                    </div>
                    {
                        isWarningLabel &&
                        <div className={element("counters-container")}>
                            <Text
                                className={element("counters-item", {color: "inert"})}
                                level="2"
                            >
                                {`${warningLabel}: ${impossibleCount}`}
                            </Text>
                            {
                                unablePublishTooltip && isWarningLabel &&
                                <HelpTooltip
                                    className={element("counters-tooltip")}
                                    hover
                                    width={18}
                                    height={18}
                                    text={unablePublishTooltip}
                                    type="light"
                                    position="bottom-right"
                                />
                            }
                        </div>
                    }
                </div>
            }
            <Text
                level="3"
                className={element("text", {mt: true})}
            >
                {text}
            </Text>
            {
                warningText &&
                <Text
                    level="3"
                    className={element("text")}
                >
                    <Text
                        inline
                        className={element("attention")}
                        level="3"
                    >
                        Внимание!&nbsp;
                    </Text>
                    {warningText}
                </Text>
            }
            {
                !isNoList &&
                <ul className={`${element("list")} ${classNameList}`}>
                    {
                        content ?
                            content :
                            getContent()
                    }
                </ul>
            }
            {additionalContent}
        </NmModal>
    );
};

export default MassConfirm;