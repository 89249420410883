import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import UnavailableTemplates from "../../../../components/UnavailableTemplates";
import {withPageData} from "../../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as ArchiveIcon} from "../../../../images/archive_24.svg";
import {ReactComponent as ListIcon} from "../../../../images/list.svg";
import OrderTemplateInfo from "../../../order/template-info";
import FrameContractAddExternal from "../add-external-contract";
import RegistryFrameContractEdit from "../edit-form";
import FcRegistryListFilter from "../filter/index";

import {getUnavailableTemplatesFlags} from "../../../../components/UnavailableTemplates/utils";
import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {
    getFcRegistryListActionOptions,
    getResubmittingConfirmText,
    getSubmittingConfirmText,
} from "../../../../utils/fcRegistries";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";
import {toastError, toastWarning} from "../../../../utils/toastHelper";
import {
    isAcceptedFcRegistryListActions,
    isAcceptedShowArchivedFcRegistryListControls,
    isAcceptedShowFcRegistryListControls,
} from "../../../../utils/user-rights/fcRegistries";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE} from "../../../../components/UnavailableTemplates/constants";
import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";
import {
    FC_REGISTRIES_STATUS_OBJECTS,
    FC_REGISTRIS_STATUS_DICT,
    FC_REGISTRY_ACTION,
    FC_REGISTRY_ACTION_TEXT,
} from "../../../../constants/fcRegistries";
import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../constants/finance";
import {LINK_CLIENT_REGISTRY_FC_CARD} from "../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, isUserFromNm, NM_MANAGER} from "../../../../constants/roles";

import {getClientCardSelector} from "../../../../ducks/client";
import {getDocumentsCustomTemplateReplacementsForUnavailableList} from "../../../../ducks/documentsCustomTemplate";
import {
    archiveFcRegistry,
    deleteNotSignedItemsFcRegistry,
    fcRegistryActionSelector,
    fcRegistryListProgressSelector,
    fcRegistryListSelector,
    fcRegistryListTotalCountSelector,
    fcRegistryListTotalPagesSelector,
    fcRegistryStatusDictSelector,
    fcRegistryStatusOptionsSelector,
    fcRegistrySubmittingFlagsSelector,
    getFcRegistryPage,
    getFcRegistryStatusDict,
    getFcRegistrySubmittingFlags,
    resubmitFcRegistryToSigning,
    submitFcRegistryToSigning,
} from "../../../../ducks/fcRegistries";

import "./style.sass";

class FcRegistryList extends Component {
    static propTypes = {};

    static defaultProps = {};

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    archived,
                },
            },
        } = props;

        this.clientId = clientId;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            archived,
            modalData: {},
            filterData: {},
            isShowEditRegistryForm: false,
            editableRegistry: {},
            isOpenFrameContractExternalEditForm: false,
            isSearch: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getFcRegistryStatusDict,
        } = this.props;

        this.fetchList();
        getFcRegistryStatusDict();
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        if (this.fetchTimeout) {
            clearTimeout(this.fetchTimeout);
        }
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    fetchList = () => {
        const {
            pageNum,
            pageSize,
            archived,
            filterData,
        } = this.state;

        const {
            getFcRegistryPage,
        } = this.props;

        const {
            contractTypeFilter,
            creatorClientUserNameFilter,
            contractorNameFilter,
            externalDocumentFilter,
            contractorPhoneFilter,
            registryNameFilter,
        } = filterData;

        getFcRegistryPage({
            data: {
                pageNum,
                pageSize,
                archived,
                clientIdFilter: this.clientId,
                ...filterData,
                contractTypeFilter: handleFormString(contractTypeFilter),
                creatorClientUserNameFilter: handleFormString(creatorClientUserNameFilter),
                registryNameFilter: handleFormString(registryNameFilter),
                contractorNameFilter: handleFormString(contractorNameFilter),
                contractorPhoneFilter: contractorPhoneFilter ? removePhoneMask(contractorPhoneFilter) : undefined,
                externalDocumentFilter: externalDocumentFilter !== EMPTY_OPTION_KEY ?
                    externalDocumentFilter :
                    null,
            },
        });
    };

    archiveRegistry = (registryId, isNeedArchive) => {
        const {
            archiveFcRegistry,
        } = this.props;

        return () => {
            archiveFcRegistry({
                data: {
                    registryId,
                    clientId: this.clientId,
                },
                archive: isNeedArchive,
                onError: this.closeActionConfirm,
                onSuccess: this.onActionSuccess,
            });
        };
    };

    getFcRegistrySubmittingFlags = ({item, type, onSuccess}) => {
        const {
            getFcRegistrySubmittingFlags,
        } = this.props;

        const {
            registryId,
        } = item;

        getFcRegistrySubmittingFlags({
            data: {
                registryId,
                clientId: this.clientId,
            },
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess();
                }

                this.setState({
                    dropdownAction: this.getActionObj(item, type),
                });
            },
        });
    };

    resubmitFcRegistryToSigning = (registryId) => {
        const {
            resubmitFcRegistryToSigning,
        } = this.props;

        return () => {
            resubmitFcRegistryToSigning({
                data: {
                    registryId,
                    clientId: this.clientId,
                },
                onSuccess: this.onActionSuccess,
            });
        };
    };

    submitFcRegistryToSigning = (registryId) => {
        const {
            submitFcRegistryToSigning,
        } = this.props;

        return () => {
            submitFcRegistryToSigning({
                data: {
                    registryId,
                    clientId: this.clientId,
                },
                onSuccess: this.onActionSuccess,
            });
        };
    };

    deleteNotSignedItemsFcRegistry = (registryId) => {
        const {
            deleteNotSignedItemsFcRegistry,
        } = this.props;

        return () => {
            deleteNotSignedItemsFcRegistry({
                data: {
                    registryId,
                    clientId: this.clientId,
                },
                onSuccess: this.onActionSuccess,
            });
        };
    };

    submitFilter = (filterData, isSearch) => {
        this.setState({
            isSearch,
            pageNum: 1,
            filterData,
        }, () => {
            this.fetchList();
        },
        );
    };

    onActionSuccess = ({hasWarning, warningMessage}) => {
        this.closeActionConfirm();
        if (hasWarning) {
            toastWarning(warningMessage);
            return;
        }
        this.fetchTimeout = setTimeout(() => {
            this.fetchList();
        }, 1000);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                dropdownPaymentNumber: "",
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    updateState = (newStateFields, cbFn = () => {
    }) => {
        return () => {
            this.setState({
                ...newStateFields,
            }, cbFn);
        };
    };

    toggleArchived = () => {
        this.setState(({archived}) => ({
            archived: !archived,
            pageNum: 1,
        }), this.fetchList);
    };

    renderListCard = (item) => {
        const {
            itemsTotalCount,
            itemsSignedCount,
            itemsSubmittedToSigningCount,
            itemsDeclinedCount,
            creatorClientUserLastName,
            creationDateTime,
            creatorClientUserFirstName,
            creatorClientUserPatronymic,
            contractType,
            externalDocumentSource,
            commentary,
        } = item;

        return (
            <NmListCard
                checkbox
                alignItems="flex-end"
                classNameMainContent="col-16 col-md-16 col-xl-7 col-xxl-7"
                secondaryHeaderStatus={this.renderRegistryStatus(item)}
                secondaryHeader={`Реестр от ${dateFormat(convertUtcToLocal(creationDateTime), "dd.MM.yyyy HH:mm")}`}
                primaryHeader={this.renderRegistryLink(item)}
                mediaControls={this.renderItemMediaControls(item)}
                cards={[
                    {
                        title: "Всего",
                        value: itemsTotalCount,
                        className: "col-16 col-md-4 col-xl-4 col-xxl-4",
                    },
                    {
                        title: "Подписано",
                        value: itemsSignedCount,
                        className: "col-16 col-md-4 col-xl-4 col-xxl-4",
                    },
                    {
                        title: "В процессе",
                        value: itemsSubmittedToSigningCount,
                        className: "col-16 col-md-4 col-xl-4 col-xxl-4",
                    },
                    {
                        title: "Отклонено",
                        value: itemsDeclinedCount,
                        className: "col-16 col-md-4 col-xl-4 col-xxl-4",
                    },
                ]}
                labels={[
                    externalDocumentSource?.description && {text: externalDocumentSource?.description},
                    commentary && {text: commentary, noWrap: false, columnOnMobile: true},
                    {
                        label: "Ответственный",
                        text: ` ${creatorClientUserLastName} ${creatorClientUserFirstName} ${creatorClientUserPatronymic || ""}`,
                        noWrap: true,
                        columnOnMobile: false,
                    },
                    {
                        label: "Договор",
                        text: ORDER_WORK_REPORT_TYPE_TRANSLATE[contractType],
                    },
                    {
                        label: "Шаблон договора",
                        inline: true,
                        noWrap: false,
                        textTitle: item.frameContractTemplateName,
                        text: (
                            <OrderTemplateInfo
                                id={item.frameContractTemplateId}
                                linkName={item.frameContractTemplateName}
                                link={item.frameContractTemplatePdfDownloadLink}
                            />
                        ),
                    },
                ]}
                cardsWithContainer
                cardsContainerClassName="col-16 col-md-16 col-xl-8 col-xxl-8 mt-md-4 mt-xxl-0"
                actionsClassName="col-1 flex-aligned-end flex-content-end"
            />
        );
    };

    getRows() {
        const {
            list,
        } = this.props;

        return list.map((item) => {
            return {
                ...item,
                isSelected: false,
                showCheckBox: false,
                error: [FC_REGISTRIS_STATUS_DICT.DECLINED, FC_REGISTRIS_STATUS_DICT.PARTIALLY_SIGNED].includes(item.status),
                contentRow: this.renderListCard(item),
            };
        });
    }

    getActionObj = (registry, type) => {
        const {
            submittingFlags,
        } = this.props;

        const {
            registryId,
            name,
        } = registry;


        let resultObj = null;

        switch (type) {
        case FC_REGISTRY_ACTION.ARCHIVE:
            resultObj = {
                text: FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.ARCHIVE],
                action: this.archiveRegistry(registryId, true),
            };
            break;
        case FC_REGISTRY_ACTION.UNARCHIVE:
            resultObj = {
                text: FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.UNARCHIVE],
                action: this.archiveRegistry(registryId, false),
            };
            break;
        case FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING:
            resultObj = {
                text: getSubmittingConfirmText(submittingFlags),
                action: this.submitFcRegistryToSigning(registryId),
            };
            break;
        case FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING:
            resultObj = {
                text: getResubmittingConfirmText(submittingFlags),
                action: this.resubmitFcRegistryToSigning(registryId),
            };
            break;
        case FC_REGISTRY_ACTION.DELETE_NOT_SIGNED:
            resultObj = {
                text: FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.DELETE_NOT_SIGNED],
                action: this.deleteNotSignedItemsFcRegistry(registryId),
            };
            break;
        }

        return {
            ...resultObj,
            text: resultObj.text.replace("{{name}}", name),
        };
    };

    showActionConfirm = (type, item) => {
        const {
            hasOvmRequiredToFill,
        } = item;


        switch (type) {
        case FC_REGISTRY_ACTION.EDIT:
            this.setState({
                isShowEditRegistryForm: true,
                editableRegistry: item,
            });
            return;
        case FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING:
            if (hasOvmRequiredToFill) {
                toastError("Необходимо заполнить данные ОВМ для всех нерезидентов (красные строки)");
                return;
            }
            this.getFcRegistrySubmittingFlags({item, type});
            return;
        case FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING:
            this.checkUnavailableTemplates(item, type);
            return;
        default:
            this.setState({
                dropdownAction: this.getActionObj(item, type),
            });
        }
    };

    checkUnavailableTemplates = (item, type) => {
        const {getDocumentsCustomTemplateReplacementsForUnavailableList} = this.props;

        // Проверяем на недоступные шаблоны
        getDocumentsCustomTemplateReplacementsForUnavailableList({
            clientId: this.clientId,
            frameContractsRegistryId: item.registryId,
            customDocumentIds: [
                item.frameContractTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем при отключенной опции "Свои шаблоны"
                if (isDisabledTemplateClientSetting) {
                    this.onOpenUnavailableTemplates(item, type);

                    return;
                }

                this.getFcRegistrySubmittingFlags({item, type});
            },
        });
    };

    onCloseUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: false,
            modalData: {},
        });
    };

    onOpenUnavailableTemplates = (registry, type) => {
        this.setState({
            modalData: {
                registry,
                type,
            },
            isOpenUnavailableTemplates: true,
        });
    };

    renderUnavailableTemplates = () => {
        const {actionProgress} = this.props;
        const {
            isOpenUnavailableTemplates,
            modalData: {
                registry,
                type,
            },
        } = this.state;

        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_DOCUMENTS_RD}
                progress={actionProgress}
                onClose={this.onCloseUnavailableTemplates}
                onSubmit={() => {
                    this.getFcRegistrySubmittingFlags({
                        item: registry,
                        type,
                        onSuccess: this.onCloseUnavailableTemplates,
                    });
                }}
                header="Передача реестра на подписание"
                submitBtnContent="Передать на подписание"
            />
        );
    };

    closeEditRegistryWindow = () => {
        this.setState({
            isShowEditRegistryForm: false,
            editableRegistry: {},
        });
    };

    closeActionConfirm = () => {
        this.setState({
            dropdownAction: null,
        });
    };

    renderActionConfirm() {
        const {
            dropdownAction,
        } = this.state;

        if (isEmpty(dropdownAction)) {
            return null;
        }

        const {
            action,
            text,
        } = dropdownAction;

        return (
            <NmConfirmV2
                size="md"
                content={text}
                onCancel={this.closeActionConfirm}
                onConfirm={action}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    renderItemMediaControls = (item) => {
        const options = getFcRegistryListActionOptions({
            ...item,
            isClientArchived: this.isClientArchived,
        });

        if (isEmpty(options) || !isAcceptedFcRegistryListActions(this.role)) {
            return null;
        }

        const buttons = [];

        options.forEach((option) => {
            buttons.push(
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.showActionConfirm(option.value, item);
                        },
                        children: option.text,
                    },
                },
            );
        });

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons,
        };
    };

    toggleOpenFrameContractExternalEditForm = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenFrameContractExternalEditForm: !prevState.isOpenFrameContractExternalEditForm,
        }));
    };

    renderMediaControls = () => {
        const {
            archived,
            isSearch,
        } = this.state;

        const {
            totalCount,
            progress,
            t,
        } = this.props;

        const archiveRegistryBtn = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.toggleArchived,
                children: archived ? t("order-list.list") : t("order-list.archive"),
                icon: archived ? <ListIcon /> : <ArchiveIcon />,
                color: "grey",
            },
            disabled: progress,
            visible: isAcceptedShowArchivedFcRegistryListControls(this.role),
        };

        const addRegistryBtn = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.updateState({isShowEditRegistryForm: true}),
                children: "Добавить реестр",
                icon: <AddIcon />,
            },
            disabled: progress,
            visible: isAcceptedShowFcRegistryListControls(this.role) && (totalCount || isSearch) && !(this.isClientArchived || archived),
        };

        const addExternalRegistryBtn = {
            component: COMPONENT.BUTTON,
            props: {
                color: "light-green",
                onClick: this.toggleOpenFrameContractExternalEditForm,
                icon: <AddIcon />,
                children: "Добавить внешний договор",
            },
            visible: this.clientId && !this.isClientArchived && [ADMIN, CLIENT_ADMIN, NM_MANAGER].includes(this.role),
        };

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 3,
            },
            buttons: [
                addRegistryBtn,
                addExternalRegistryBtn,
                archiveRegistryBtn,
            ],
        };
    };

    renderRegistryStatus({status, hasOvmRequiredToFill}) {
        const {
            statusDict,
        } = this.props;

        return (
            <NmBadge
                mod={FC_REGISTRIES_STATUS_OBJECTS[status].MOD}
                tooltipPosition="right-top"
                rightTooltipText={hasOvmRequiredToFill ? "Необходимо заполнить данные ОВМ для всех нерезидентов" : ""}
                text={statusDict[status]}
            />
        );
    };

    renderActionCell(item) {
        const options = getFcRegistryListActionOptions({
            ...item,
            isClientArchived: this.isClientArchived,
        });

        if (isEmpty(options) || !isAcceptedFcRegistryListActions(this.role)) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    this.showActionConfirm(option.value, item);
                }}
            />
        );
    };

    renderRegistryLink = ({name, registryId}) => {
        const link = LINK_CLIENT_REGISTRY_FC_CARD.replace(":clientId", this.clientId).replace(":registryId", registryId);

        return (
            <ExtLink
                className="fc-registry-list__registry-link"
                to={link}
            >
                {name}
            </ExtLink>
        );
    };

    renderFrameContractExternalEditForm = () => {
        const {
            isOpenFrameContractExternalEditForm,
        } = this.state;

        return (
            isOpenFrameContractExternalEditForm &&
            <FrameContractAddExternal
                clientId={this.clientId}
                fetchList={this.fetchList}
                onClose={this.toggleOpenFrameContractExternalEditForm}
            />
        );
    };

    renderList() {
        const {listProgress} = this.props;
        const {
            isSearch,
            archived,
        } = this.state;

        const rows = this.getRows();

        if (isEmpty(rows)) {
            return (
                <NmEmptyPageV2
                    title="Данные отсутствуют"
                    isShowAction={!(this.isClientArchived || archived)}
                    textAction="Добавить реестр"
                    onClickAction={!this.isClientArchived && this.updateState({isShowEditRegistryForm: true})}
                    isSearch={isSearch}
                    fetchProgress={listProgress}
                />
            );
        }

        return (
            <CheckboxList
                rowClassName="fc-registry-list__table-row"
                rows={rows}
            />
        );
    };

    renderEditFcRegistryForm = () => {
        const {
            isShowEditRegistryForm,
            editableRegistry,
        } = this.state;

        return (isShowEditRegistryForm &&
            <RegistryFrameContractEdit
                registryObj={editableRegistry}
                clientId={this.clientId}
                close={this.closeEditRegistryWindow}
                onAddOrEditCbk={() => {
                    this.closeEditRegistryWindow();
                    this.fetchList();
                }}
            />
        );
    };

    render() {
        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        const {
            totalPages = 0,
            totalCount,
            listProgress,
            actionProgress,
            statusOptions,
        } = this.props;

        const isAdminClientCard = isUserFromNm(this.role);

        return (
            <NmPage
                openFilter
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                className={`fc-registry-list ${isAdminClientCard ? "mt-15" : ""}`}
                typeFilter="vertical"
                isLoaded={listProgress || actionProgress}
                header={
                    !isAdminClientCard &&
                    <NmTitle
                        size="xl"
                        children="Реестры на подписание рамочных договоров с исполнителями"
                        count={totalCount ? totalCount : null}
                    />
                }
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                mediaControls={this.renderMediaControls()}
                filtersBase={
                    <FcRegistryListFilter
                        submitFilter={this.submitFilter}
                        clientId={this.clientId}
                        statusOptions={statusOptions}
                        filterData={filterData}
                    />
                }
                totalCount={totalCount}
            >
                {this.renderFrameContractExternalEditForm()}
                {this.renderUnavailableTemplates()}
                {this.renderActionConfirm()}
                {this.renderEditFcRegistryForm()}
                {this.renderList()}
            </NmPage>
        );
    }
}


export default withPageData(connect(
    state => ({
        list: fcRegistryListSelector(state),
        totalCount: fcRegistryListTotalCountSelector(state),
        totalPages: fcRegistryListTotalPagesSelector(state),
        listProgress: fcRegistryListProgressSelector(state),
        statusDict: fcRegistryStatusDictSelector(state),
        statusOptions: fcRegistryStatusOptionsSelector(state),
        submittingFlags: fcRegistrySubmittingFlagsSelector(state),
        actionProgress: fcRegistryActionSelector(state),
        client: getClientCardSelector(state),
    }),
    {
        getFcRegistryPage,
        getFcRegistryStatusDict,
        archiveFcRegistry,
        submitFcRegistryToSigning,
        resubmitFcRegistryToSigning,
        deleteNotSignedItemsFcRegistry,
        getDocumentsCustomTemplateReplacementsForUnavailableList,
        getFcRegistrySubmittingFlags,
    },
)(withTranslation()(FcRegistryList)));
