import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/smz/reports/summary";

//*  TYPES  *//

const SMZ_REPORTS_SUMMARY_REQUEST = "SMZ_REPORTS_SUMMARY_REQUEST";
const SMZ_REPORTS_SUMMARY_SUCCESS = "SMZ_REPORTS_SUMMARY_SUCCESS";

const SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_REQUEST = "SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_REQUEST";
const SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_SUCCESS = "SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_SUCCESS";
const SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_ERROR = "SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_ERROR";

const SMZ_REPORTS_SUMMARY_UPDATE_STORE = "SMZ_REPORTS_SUMMARY_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    reports: [],
    totalSummary: {},
    totalCount: 0,
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_ERROR:
        return {
            ...state,
            progress: false,
        };
    case SMZ_REPORTS_SUMMARY_REQUEST:
        const {pageNum, pageSize} = payload;
        return {
            ...state,
            pageData: {
                pageNum,
                pageSize,
            },
        };
    case SMZ_REPORTS_SUMMARY_SUCCESS:
        const {
            results: reports,
            totalCount,
            totals: totalSummary,
        } = payload;

        return {
            ...state,
            reports,
            totalSummary,
            totalCount,
        };
    case SMZ_REPORTS_SUMMARY_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getSmzReportsSummary(payload) {
    return {
        type: SMZ_REPORTS_SUMMARY_REQUEST,
        payload,
    };
}

export function getSmzReportsSummaryDepositHistory(payload) {
    return {
        type: SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_REQUEST,
        payload,
    };
}

export function updateSmzReportsSummaryStore(payload) {
    return {
        type: SMZ_REPORTS_SUMMARY_UPDATE_STORE,
        payload,
    };
}

//*  SELECTORS  *//

export const smzReportsSummarySelector = ({smzReportsSummary}) => smzReportsSummary;
export const smzReportsSummaryListSelector = createSelector(smzReportsSummarySelector, ({reports}) => reports);
export const smzReportsSummaryProgressSelector = createSelector(smzReportsSummarySelector, ({progress}) => progress);
export const smzReportsSummaryTotalSummarySelector = createSelector(smzReportsSummarySelector, ({totalSummary}) => totalSummary);
export const smzReportsSummaryTotalCountSelector = createSelector(smzReportsSummarySelector, ({totalCount}) => totalCount);
export const smzReportsSummaryTotalPagesSelector = createSelector(smzReportsSummarySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));

//*  SAGA  *//

//POST api/smz/reports/summary
// Метод получения данных для сводной ведомости
export const getSmzReportsSummarySaga = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.post(controller, reqData);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("getSmzReportsSummary Error");

            return {
                done: true,
            };
        }

        yield getResult();

        yield put({
            type: SMZ_REPORTS_SUMMARY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error("getSmzReportsSummary Error:", error);

        yield getResult();
    }
};

//POST api/smz/reports/summary/depositHistory
// Метод получения истории пополнений для сводной
export const getSmzReportsSummaryDepositHistorySaga = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.post(`${controller}/depositHistory`, reqData);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_SUCCESS,
            payload: result,
        });
        yield getResult(result);
    } catch (error) {
        yield put({
            type: SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_ERROR,
            payload: error,
        });

        yield getResult();
    }
};

export function* saga() {
    yield all([
        takeEvery(SMZ_REPORTS_SUMMARY_REQUEST, getSmzReportsSummarySaga),
        takeEvery(SMZ_REPORTS_SUMMARY_DEPOSIT_HISTORY_REQUEST, getSmzReportsSummaryDepositHistorySaga),
    ]);
}