import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../utils/dateFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {ADMIN} from "../../../../constants/roles";

import {
    bffCommonDictsCustomDocumentTemplateLogEventTypesOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useKedoTemplateLogsFilter = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const eventTypeDictOptions = useSelector(bffCommonDictsCustomDocumentTemplateLogEventTypesOptionsSelector);

    const initFilter = {
        typeFilter: "",
        fromDateTimeFilter: null,
        toDateTimeFilter: null,
        performerIdsFilter: [],
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        const {
            typeFilter,
            fromDateTimeFilter,
            toDateTimeFilter,
            performerIdsFilter,
        } = filter;

        return {
            typeFilter: handleFormString(typeFilter),
            fromDateTimeFilter: getStartFilterDateWithTimeInUtc(fromDateTimeFilter),
            toDateTimeFilter: getEndFilterDateWithTimeInUtc(toDateTimeFilter),
            performersIds: !isEmpty(performerIdsFilter) ? performerIdsFilter : undefined,
        };
    }

    const filters = [
        {
            row: [
                {
                    component: FILTER.DROPDOWN,
                    options: eventTypeDictOptions,
                    size: "lg",
                    label: "Тип события",
                    placeholder: "Не выбран",
                    name: "typeFilter",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.DATE_RANGE,
                    startFieldName: "fromDateTimeFilter",
                    endFieldName: "toDateTimeFilter",
                    isClearable: true,
                    label: "Период события",
                },
            ],
        },
        {
            row: [
                {
                    component: FILTER.CLIENT_USERS_FIO,
                    multiple: true,
                    search: true,
                    roleListFilter: [
                        ADMIN,
                    ],
                    name: "performerIdsFilter",
                    label: "ФИО ответственного",
                    placeholder: "Не выбрано",
                    size: "lg",
                },
            ],
        },
    ];

    return {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    };
};