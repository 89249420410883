import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {
    useKedoDocumentTypes,
} from "../../../../components/KedoDocumentTypesDropdown/hooks/useDocumentTypes";
import {useFilter} from "../../../../hooks/useFilter";

import {getFiltersData} from "../utils/getFiltersData";

import {KEDO_DOCUMENT_STATES_FILTER_OPTIONS} from "../constants";

import {
    bffCommonDictsKedoDocumentFileSourceTypesOptionsSelector,
    bffCommonDictsKedoRoleDictOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";

export const useDocumentsFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const documentTypes = useKedoDocumentTypes();
    const roleOptions = useSelector(bffCommonDictsKedoRoleDictOptionsSelector);
    const fileSourceTypeOptions = useSelector(bffCommonDictsKedoDocumentFileSourceTypesOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        mapFilterDto: getFiltersData,
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Название или номер документа",
                        placeholder: "Введите название или номер документа",
                        name: "documentNameOrNumFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "createdFromFilter",
                        endFieldName: "createdToFilter",
                        isClearable: true,
                        label: "Дата создания документа",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "creatorFioFilter",
                        label: "ФИО создателя",
                        placeholder: "Введите ФИО создателя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: fileSourceTypeOptions,
                        name: "fileUploadSourceTypesFilter",
                        label: "Источник загрузки",
                        placeholder: "Выберите источник загрузки",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "senderFioFilter",
                        label: "ФИО отправителя",
                        placeholder: "Введите ФИО отправителя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "receiverFioFilter",
                        label: "ФИО получателя",
                        placeholder: "Введите ФИО получателя",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: KEDO_DOCUMENT_STATES_FILTER_OPTIONS,
                        name: "documentTypeIdsFilter",
                        label: "Тип документа",
                        placeholder: "Выберите тип документа",
                        ...documentTypes,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        search: true,
                        options: KEDO_DOCUMENT_STATES_FILTER_OPTIONS,
                        name: "documentStatesFilter",
                        label: "Статус документа",
                        placeholder: "Выберите статус",
                    },
                ],
            },
        ];
    }, [
        roleOptions,
        documentTypes.options,
        fileSourceTypeOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};