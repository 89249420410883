import * as yup from "yup";

import {getFullRegistrationValidations} from "../../../../../../components/FullRegistration/utils/getValidations";
import {
    addYears,
    getStartDate,
} from "../../../../../../utils/dateFormat";

import {citizenshipsDict} from "../../../../../../constants/citizenships";
import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    EDM_PASSPORT_TYPE,
} from "../../../../../../constants/contractor";
import {
    departamentCodeReg,
    einReg,
} from "../../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

const validationSchema = () => {
    const numbersRegExp = /[^0-9\s]+/;
    const migrationCardRegExp = /^[0-9]{4}\s{1}[0-9]{1,}$/;

    const validations = getFullRegistrationValidations({
        isRequiredFields: true,
        fields: [
            "statelessPersonResidencePermitNumber",
            "statelessPersonResidencePermitIssuedBy",
            "statelessPersonResidencePermitIssuedDate",
            "statelessPersonResidencePermitValidToDate",
            "statelessPersonTemporaryIdNum",
            "statelessPersonTemporaryIdIssuedBy",
            "statelessPersonTemporaryIdIssuedDate",
            "statelessPersonTemporaryIdValidToDate",
            "statelessPersonTemporaryIdBirthPlace",
            "statelessPersonTemporaryIdIssueReason",
            "temporaryRefugeeCertificateNum",
            "temporaryRefugeeCertificateIssuedBy",
            "temporaryRefugeeCertificatePersonalFileNum",
            "temporaryRefugeeCertificateIssuedDate",
            "temporaryRefugeeCertificateValidToDate",
            "temporaryRefugeeCertificateBirthPlace",
            "refugeeCertificateNum",
            "refugeeCertificateIssuedBy",
            "refugeeCertificatePersonalFileNum",
            "refugeeCertificateIssuedDate",
            "refugeeCertificateValidToDate",
            "refugeeCertificateBirthPlace",
            "temporaryResidencePermitNum",
            "temporaryResidencePermitDecisionDate",
            "temporaryResidencePermitValidToDate",
            "temporaryResidencePermitDecisionIssuedBy",
            "idDocNumber",
            "birthPlace",
            "idDocIssuedBy",
            "idDocIssuedDate",
            "idDocValidToDate",
            "firstName",
            "lastName",
            "patronymic",
            "gender",
            "birthDate",
        ],
    });

    return yup.object().shape({
        idDocIssuedDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        idDocValidToDate: yup.string()
            .nullable()
            .when("citizenship", {
                is: value => ![
                    citizenshipsDict.DN.value,
                    citizenshipsDict.LG.value,
                ].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        idDocIssuedByDepartmentCode: yup.string()
            .when("citizenship", {
                is: citizenshipsDict.RU.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .matches(departamentCodeReg, "Неверный формат кода подразделения")
                    .length(7, "Допустимое количество символов 6"),

            })
            .when("citizenship", {
                is: value => ![citizenshipsDict.RU.value].includes(value),
                then: yup.string()
                    .max(50, "Не более 50 символов"),

            }),
        address: yup.string()
            .max(255, "Не более 255 символов")
            .when("citizenship", {
                is: citizenshipsDict.RU.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(3, "Минимальная длина строки 3 символа")
                    .max(255, "Не более 255 символов"),
            })
            .when("arrivalDocType", {
                is: CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(3, "Минимальная длина строки 3 символа")
                    .max(255, "Не более 255 символов"),
            }),
        ein: yup.string()
            .nullable()
            .matches(einReg, "Ввод цифр и латинских букв")
            .when("citizenship", {
                is: citizenshipsDict.TJ.value,
                then: yup.string()
                    .nullable()
                    .when("edmPassportType", {
                        is: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
                        then: yup.string()
                            .nullable()
                            .matches(einReg, { message: "Ввод цифр и латинских букв", excludeEmptyString: false })
                            .length(13, "Допустимое количество символов 13"),
                    }),
            })
            .when("citizenship", {
                is: value =>  [
                    citizenshipsDict.BY.value,
                    citizenshipsDict.BLR.value,
                ].includes(value),
                then: yup.string()
                    .nullable()
                    .length(14, "Допустимое количество символов 14"),
            })
            .when("citizenship", {
                is: value =>  value === citizenshipsDict.KG.value,
                then: yup.string()
                    .nullable()
                    .test("ein", "Допустимое количество символов от 12 до 14", function (value) {
                        if (!value) {
                            return true;
                        }

                        return [12, 13, 14].includes(value?.length);
                    }),
            })
            .when("citizenship", {
                is: citizenshipsDict.KZ.value,
                then: yup.string()
                    .nullable()
                    .test("ein", "Допустимое количество символов 12 или 14", function (value) {
                        if (!value) {
                            return true;
                        }

                        return [12, 14].includes(value?.length);
                    }),
            })
            .when("citizenship", {
                is: citizenshipsDict.UA.value,
                then: yup.string()
                    .nullable()
                    .length(13, "Допустимое количество символов 13"),
            }),
        migrationCardNumber: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов")
            .test("checkSymbol", "Недопустимые символы", function (value) {
                if (!value) {
                    return true;
                }
                return !numbersRegExp.test(value);
            })
            .matches(migrationCardRegExp, { message: "Формат для ввода: 1234 1234567", excludeEmptyString: false }),
        employmentPatentSeries: yup.string()
            .nullable()
            .max(5, "Допустимое количество символов 5")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        employmentPatentNum: yup.string()
            .nullable()
            .max(10, "Допустимое количество символов 10")
            .matches(/^[0-9]+$/, "Только цифры")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        employmentPatentRegistrationNumber: yup.string()
            .nullable()
            .max(50, "Допустимое количество символов 50"),
        employmentPatentIssuedDate: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        vhiPolicyValidFromDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        vhiPolicyValidToDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        mainDocumentStampAddress: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов")
            .nullable(),
        residencePermitValidToDate: yup.string()
            .nullable()
            .when("arrivalDocType", {
                is: value => ([
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                ].includes(value)),
                then: yup.string()
                    .test("check", "Некорректная дата окончания срока действия документа", function (value) {
                        const startDate = this.parent.residencePermitIssuedDate;
                        const citizenship = this.parent.citizenship;

                        if (!startDate || !value || citizenship === citizenshipsDict.UA.value) { //проверка заполенено ли поле даты выдачи
                            return true;
                        }

                        const isOldDateOfReceiving = new Date(startDate) < new Date(2019,10,1); // это дата выдачи до 01.11.2019 года

                        const registrationDate = addYears(startDate, YEAR_FOR_CHECK_RESIDENCE_PERMIT_VALID_TO_DATE);
                        const leftPartEquation = getStartDate(registrationDate);
                        const rightParEquation = getStartDate(new Date(value));

                        return !(isOldDateOfReceiving && !(leftPartEquation.getTime() === rightParEquation.getTime()));

                    })
                    .nullable(),
            }),
        residencePermitNumber: yup.string()
            .nullable()
            .when("arrivalDocType", {
                is: value => ([
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                ].includes(value)),
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .length(10, "Допустимое количество символов 9")
                    .nullable(),
            }),
        residencePermitIssuedDate: yup.string()
            .nullable()
            .when("arrivalDocType", {
                is: value => ([
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                ].includes(value)),
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .nullable(),
            }),
        residencePermitIssuedBy: yup.string()
            .nullable()
            .when("arrivalDocType", {
                is: value => ([
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                ].includes(value)),
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(255, "Максимальная длина - 255 символов")
                    .nullable(),
            }),
        residencePermitDecisionNumber: yup.string()
            .nullable()
            .when("arrivalDocType", {
                is: value => ([
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT,
                    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION,
                ].includes(value)),
                then: yup.string()
                    .max(255, "Максимальная длина - 255 символов")
                    .nullable(),
            }),
        mainDocumentStampIssuedDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        visaNum: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable()
            .max(50, "Не более 50 символов"),
        visaId: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable()
            .max(50, "Не более 50 символов"),
        visaIssuedDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        visaActiveFromDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        visaActiveUntilDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        ...validations,
    });
};

const YEAR_FOR_CHECK_RESIDENCE_PERMIT_VALID_TO_DATE = 5;

export default validationSchema;