import React from "react";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

export const getSpecialityView = ({speciality, clientSpecialityOffer}) => {
    if (speciality) {
        const {
            value,
        } = speciality;

        return {
            value,
            title: null,
        };
    }

    if (!clientSpecialityOffer) {
        return {
            value: "",
        };
    }

    const {
        accepted,
        offeredSpecialityName,
        clientOfferedSpecialityName,
    } = clientSpecialityOffer;

    // Не принято решение
    if (accepted === null) {
        return {
            value: clientOfferedSpecialityName,
            title: "На рассмотрении",
            active: false,
        };
    }

    // Отклонено
    if (!accepted) {
        return {
            value: clientOfferedSpecialityName,
            active: false,
        };
    }

    // Одобрено
    return {
        value: offeredSpecialityName,
    };
};

const OrderSpecialityParagraph = props => {
    const {
        speciality,
        className,
        isValueTitle = true,
        croppedValue = true,
        show = false,
    } = props;

    const {
        value,
        title,
        active = true,
    } = getSpecialityView(speciality);

    const [block, element] = bem("order-speciality-paragraph", className);

    if (!show) {
        return null;
    }

    return (
        <div className={block()}>
            <span
                title={title}
                className={element("label", {active, activeCardStyle: active && !isValueTitle})}
            >
                Вид деятельности:
            </span>
            <span
                className={element("value", {active, croppedValue, activeCardStyle: active && !isValueTitle})}
                title={isValueTitle ? value : null}
            >
                <div
                    title={value}
                    className={element("wrapped")}
                >
                    {value}
                </div>
            </span>
        </div>
    );
};

OrderSpecialityParagraph.propTypes = {
    speciality: PropTypes.object,
    className: PropTypes.string,
    isValueTitle: PropTypes.bool,
    croppedValue: PropTypes.bool,
};

export default OrderSpecialityParagraph;