import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Media from "react-media";

import {ReactComponent as LightBulbOff} from "../../images/lightbulb_off.svg";
import {ReactComponent as LightBulbOn} from "../../images/lightbulb_on.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";

import bem from "../../utils/bem";
import {ls} from "../../utils/localstorage";

import "./style.sass";

const NmModalPrompt = (props) => {
    const {
        lsKeyEditPrompt,
        className,
        onChange,
    } = props;

    const {t} = useTranslation();
    const [active, setActive] = useState(undefined);
    const [block, element] = bem("nm-modal-prompt", className);

    const handleClickPrompt = () => {
        if (active === undefined) {
            return;
        }

        ls(lsKeyEditPrompt, !active);
        setActive(prevValue => !prevValue);
        onChange && onChange(!active);
    };

    const setLsValue = (key, value) => {
        ls(key, value);

        return JSON.parse(ls(key));
    };

    useEffect(() => {
        if (lsKeyEditPrompt && typeof lsKeyEditPrompt === "string") {
            const active = ls(lsKeyEditPrompt);

            setActive(active !== undefined ? JSON.parse(active) : setLsValue(lsKeyEditPrompt, false));
        }
    }, [lsKeyEditPrompt]);

    const Icon = active ? LightBulbOn : LightBulbOff;

    return (
        <Media
            query={{minWidth: 1920}}
        >
            {
                (desktop) =>
                    desktop ?
                        <NmAdvancedTooltip
                            children={t("modal.suggestionModalPrompt")}
                            classNameTooltip={element("tooltip")}
                            hover
                            className={block()}
                            position="bottom-right"
                            trigger={
                                <Icon
                                    onClick={handleClickPrompt}
                                    className={element("icon", {on: active})}
                                />
                            }
                        /> :
                        <div className={block()}>
                            <Icon
                                onClick={handleClickPrompt}
                                className={element("icon", {on: active})}
                            />
                        </div>
            }
        </Media>
    );
};

export default NmModalPrompt;