import * as Yup from "yup";

import {transformYupFormattedAmountToNumber} from "../../../../../../utils/yupTransforms";

import {requiredMessage} from "../../../../../../constants/validationRules";

function isDefRateNotEqualCommissionRate(value, testContext) {
    return Number(value) !== Number(testContext.parent.deferredRate);
}

export const depositRateEditValidationScheme = Yup.object().shape({
    clientCommissionChange: Yup.object({
        rate: Yup.number()
            .transform(transformYupFormattedAmountToNumber)
            .required(requiredMessage)
            .min(0, "Минимальное допустимое значение равно 0")
            .max(100, "Максимальное допустимое значение равно 100")
            .test("isDefRateNotEqualCommissionRate", "Текущая и отсроченная процентная ставка не могут быть равны",
                isDefRateNotEqualCommissionRate),
        isDeferred: Yup.boolean(),
        deferredRate: Yup.number()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(0, "Минимальное допустимое значение равно 0")
                    .max(100, "Максимальное допустимое значение равно 100"),
            }),
        fromDatetime: Yup.date()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.date()
                    .nullable()
                    .required(requiredMessage),
            }),
    }),
    contractorOrderCommissionChange: Yup.object({
        rate: Yup.number()
            .transform(transformYupFormattedAmountToNumber)
            .required(requiredMessage)
            .min(0, "Минимальное допустимое значение равно 0")
            .max(49.99, "Максимальное допустимое значение равно 49.99")
            .test("isDefRateNotEqualCommissionRate", "Текущая и отсроченная процентная ставка не могут быть равны",
                isDefRateNotEqualCommissionRate),
        isDeferred: Yup.boolean(),
        deferredRate: Yup.number()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(0, "Минимальное допустимое значение равно 0")
                    .max(49.99, "Максимальное допустимое значение равно 49.99"),
            }),
        fromDatetime: Yup.date()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.date()
                    .nullable()
                    .required(requiredMessage),
            }),
    }),
    contractorRegistryCommissionChange: Yup.object({
        rate: Yup.number()
            .transform(transformYupFormattedAmountToNumber)
            .required(requiredMessage)
            .min(0, "Минимальное допустимое значение равно 0")
            .max(49.99, "Максимальное допустимое значение равно 49.99")
            .test("isDefRateNotEqualCommissionRate", "Текущая и отсроченная процентная ставка не могут быть равны",
                isDefRateNotEqualCommissionRate),
        isDeferred: Yup.boolean(),
        deferredRate: Yup.number()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(0, "Минимальное допустимое значение равно 0")
                    .max(49.99, "Максимальное допустимое значение равно 49.99"),
            }),
        fromDatetime: Yup.date()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.date()
                    .nullable()
                    .required(requiredMessage),
            }),
    }),
    minCommissionChange: Yup.object({
        thresholdAmount: Yup.number()
            .required(requiredMessage)
            .transform(transformYupFormattedAmountToNumber)
            .min(0, "Минимальное допустимое значение равно 0"),
        minCommission: Yup.number()
            .required(requiredMessage)
            .transform(transformYupFormattedAmountToNumber)
            .min(0, "Минимальное допустимое значение равно 0"),
        deferredThreshold: Yup.number()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(0, "Минимальное допустимое значение равно 0"),
            }),
        deferredMinCommission: Yup.number()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(0, "Минимальное допустимое значение равно 0"),
            }),
        fromDatetime: Yup.date()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.date()
                    .nullable()
                    .required(requiredMessage),
            }),
    }),
    minimalCommissionNdflChange: Yup.object({
        rate: Yup.number()
            .required(requiredMessage)
            .transform(transformYupFormattedAmountToNumber)
            .min(0, "Минимальное допустимое значение равно 0")
            .max(999999.99, "Максимальное допустимое значение равно 999 999,99")
            .test("isDefRateNotEqualCommissionRate", "Текущая и отсроченная процентная ставка не могут быть равны",
                isDefRateNotEqualCommissionRate),
        isDeferred: Yup.boolean(),
        deferredRate: Yup.number()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.number()
                    .transform(transformYupFormattedAmountToNumber)
                    .required(requiredMessage)
                    .min(0, "Минимальное допустимое значение равно 0")
                    .max(999999.99, "Максимальное допустимое значение равно 999 999,99"),
            }),
        fromDatetime: Yup.date()
            .nullable()
            .when("isDeferred", {
                is: (isDeferred) => isDeferred,
                then: Yup.date()
                    .nullable()
                    .required(requiredMessage),
            }),
    }),
});

