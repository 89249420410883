import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {
    updateStorePatent,
} from "../../../../ducks/bff/patent/actionCreators";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";
import {getPatentsPaymentsContractorPage} from "../../../../ducks/patentsPayments";

export function useFinancePatentPaymentsFetchList(params) {
    const {
        pageSize,
        pageNum,
        filter,
        contractorId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientUserRestrictionsList({
            restrictions: [CLIENT_USER_RESTRICTIONS_VARIABLE.paymentPatentCancel],
        }));
    }, []);

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getPatentsPaymentsContractorPage({
            contractorId,
            ...filter,
            bankStatusFilter: filter.bankStatusFilter === "ALL" ? undefined : filter.bankStatusFilter,
            pageNum,
            pageSize,
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(updateStorePatent({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    return {
        fetchList,
    };
}