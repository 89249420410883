import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/ordersubcategories";

const ORDER_SUB_CATEGORIES_FIND_BY_ID_REQUEST = "ORDER_SUB_CATEGORIES_FIND_BY_ID_REQUEST";
const ORDER_SUB_CATEGORIES_FIND_BY_ID_SUCCESS = "ORDER_SUB_CATEGORIES_FIND_BY_ID_SUCCESS";
const ORDER_SUB_CATEGORIES_FIND_BY_ID_ERROR = "ORDER_SUB_CATEGORIES_FIND_BY_ID_ERROR";

const ORDER_SUB_CATEGORIES_ALL_REQUEST = "ORDER_SUB_CATEGORIES_ALL_REQUEST";
const ORDER_SUB_CATEGORIES_ALL_SUCCESS = "ORDER_SUB_CATEGORIES_ALL_SUCCESS";
const ORDER_SUB_CATEGORIES_ALL_ERROR = "ORDER_SUB_CATEGORIES_ALL_ERROR";

const ORDER_SUB_CATEGORIES_UPDATE_STORE = "ORDER_SUB_CATEGORIES_UPDATE_STORE";

const initial = {
    progressPage: false,
    error: "",
    pageData: {},
    list: [],
    totalCount: 0,
    subCategoryCard: {},
    subCategoriesAll: [],
    orderSubcategoryByCategoryId: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case ORDER_SUB_CATEGORIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    case ORDER_SUB_CATEGORIES_FIND_BY_ID_SUCCESS:
        return {
            ...state,
            subCategoryCard: payload,
        };
    case ORDER_SUB_CATEGORIES_ALL_SUCCESS:
        return {
            ...state,
            subCategoriesAll: payload,
        };
    case ORDER_SUB_CATEGORIES_FIND_BY_ID_ERROR:
        return {
            ...state,
            error: payload,
            progressPage: false,
        };
    default:
        return state;
    }
};

export function getAllSubCategories(payload) {
    return {
        type: ORDER_SUB_CATEGORIES_ALL_REQUEST,
        payload,
    };
}

export function getSubCategoryById(payload) {
    return {
        type: ORDER_SUB_CATEGORIES_FIND_BY_ID_REQUEST,
        payload,
    };
}

export function clearSubCategoriesStore(payload) {
    return {
        type: ORDER_SUB_CATEGORIES_UPDATE_STORE,
        payload,
    };
}

export const subCategoriesSelector = state => state.orderSubCategories;
export const subCategoriesAllSelector = createSelector(subCategoriesSelector, ({subCategoriesAll}) => subCategoriesAll);
export const subCategoriesAllOptionsSelector = createSelector(subCategoriesSelector, ({subCategoriesAll}) => subCategoriesAll.map(item => ({
    key: item.id,
    value: item.id,
    text: item.value,
})));
export const subCategoriesCardSelector = createSelector(subCategoriesSelector, ({subCategoryCard}) => subCategoryCard);

export const getOrderSubCategoryByIdSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.get(`${controller}/findById?id=${payload}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_SUB_CATEGORIES_FIND_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_SUB_CATEGORIES_FIND_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_SUB_CATEGORIES_FIND_BY_ID_ERROR, payload: error});
    }
};

export const getOrderSubCategoryAllSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/all`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_SUB_CATEGORIES_ALL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ORDER_SUB_CATEGORIES_ALL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ORDER_SUB_CATEGORIES_ALL_ERROR, payload: error});
    }
};


export function* saga() {
    yield all([
        takeEvery(ORDER_SUB_CATEGORIES_FIND_BY_ID_REQUEST, getOrderSubCategoryByIdSaga),
        takeEvery(ORDER_SUB_CATEGORIES_ALL_REQUEST, getOrderSubCategoryAllSaga),
    ]);
}