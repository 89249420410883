import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {
    RECRUITMENT_SOURCE_JOB_BOARD,
    RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE,
} from "../../../../containers/recruitment/vacancies/constants";

export const getRecruitmentResponseSourceText = (source, params) => {
    const {
        responseSource,
        jobBoardResponseId,
        createDate,
    } = source;
    const {
        isShowBoardNameText,
        isVisibleOnlyJobBoardName,
    } = params;

    const jobBoardName = RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[responseSource];

    if (isVisibleOnlyJobBoardName) {
        return jobBoardName;
    }

    const date = formatDate(convertUtcToLocal(createDate), "dd.MM.yyyy HH:mm");
    const id = responseSource !== RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX ? ` id: ${jobBoardResponseId}` : "";
    const jobBoard = `(${jobBoardName}${id})`;

    if (isShowBoardNameText) {
        return `${jobBoard} ${date}`;
    }

    return date;
};