import React from "react";

import {ReactComponent as IconChevron} from "../../images/chevron-down.svg";
import NmButton from "../NmButton";

import bem from "../../utils/bem";

import "./style.sass";

export type ButtonForwardProps = {
    className?: string,
    onClick: () => void,
}

const ButtonForward = (props: ButtonForwardProps) => {
    const {
        onClick,
        className = "",
    } = props;
    const [block, element] = bem("nm-button-forward", className);

    return (
        <NmButton
            {...props}
            size="xl"
            color="grey"
            className={block()}
            onlyIcon
            icon={
                <IconChevron
                    className={element("icon")}
                />
            }
            onClick={onClick}
        />
    );
};

export default ButtonForward;