export const RECRUITMENT_SOCIAL_NETWORKS = {
    WHATSAPP: "WHATSAPP",
    TELEGRAM:  "TELEGRAM",
    VK: "VK",
    OK: "OK",
    SKYPE: "SKYPE",
    INSTAGRAM: "INSTAGRAM",
    FACEBOOK: "FACEBOOK",
    LINKEDIN: "LINKEDIN",
    YANDEX_ZEN: "YANDEX_ZEN",
    BEHANCE: "BEHANCE",
    TEN_CHAT: "TEN_CHAT",
};

export const RECRUITMENT_CONTACTS = {
    WHATSAPP: "WhatsApp",
    TELEGRAM: "Telegram",
    VIBER: "Viber",
};

export const RECRUITMENT_FUNNEL_STATUS_FILTER = {
    ALL_CANDIDATES: "all",
};

export const RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT = {
    ACTIVE: "Активный",
    NOT_ACTIVE: "Неактивный",
};

export const RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS = {
    ACTIVE: "ACTIVE",
    NOT_ACTIVE: "NOT_ACTIVE",
};