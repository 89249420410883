import React from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../../../components/ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../../../../components/ActualComponents/NmDadata";
import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../../../../components/ActualComponents/NmRangeInput";
import AdditionalDocumentFilter from "../../../../../../../components/AdditionalDocumentFilter";
import AdditionalDocumentStatusFilter from "../../../../../../../components/AdditionalDocumentStatusFilter";
import MigrationStatusFilter from "../../../../../../../components/MigrationStatusFilter";

import {isNMUsers} from "../../../../../../../utils/access";
import {getDadataAddress} from "../../../../../../../utils/dadata";

import {CONTRACTOR_FIELD} from "../../../../../../../constants/contractor";
import {GENDER_OPTIONS} from "../../../../../../../constants/contractorInfo";

import {contractorStatusOptionsSelector, getCitizenshipOptionsSelector} from "../../../../../../../ducks/contractor";
import {specialitiesAllV2OptionsSelector} from "../../../../../../../ducks/speciality";

export const EdoDocumentsRegistriesCardAddItemsListFilter = (props) => {
    const {
        filter: {
            addressFiasIdsFilter,
            specialityIdsFilter,
            citizenshipFilter,
            genderFilter,
            ageFromFilter,
            ageToFilter,
            fioFilter,
            phoneFilter,
            registrationStatusFilter,
            migrationStatusesFilter,
            additionalDocumentStatusesFilter,
            additionalDocumentTypesFilter,
            remoteWorkFilter,
        },
        onChange,
        onSearch,
        onClear,
    } = props;

    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const contractorStatusOptions = useSelector(contractorStatusOptionsSelector);

    return (
        <NmForm
            addMargin
        >
            <NmDadata
                size="lg"
                name={CONTRACTOR_FIELD.ADDRESS_FIAS_IDS_FILTER}
                label="Регион или город"
                onChange={onChange}
                multiple
                fromBound="region"
                toBound="settlement"
                formatter={getDadataAddress}
                isAllSelectionShown={false}
                initialAddressIds={addressFiasIdsFilter}
                isClearable
                onSubmit={onSearch}
            />
            <NmCheckboxV2
                label="Работа удалённо"
                checked={remoteWorkFilter}
                name="remoteWorkFilter"
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                search
                multiple
                label="Виды деятельности"
                name="specialityIdsFilter"
                className="fluid mb-15"
                placeholder="Показать всё"
                options={specialityOptions}
                value={specialityIdsFilter}
                onChange={onChange}
                onSubmit={onSearch}
            />
            <NmDropdownV2
                size="lg"
                label="Гражданство"
                name="citizenshipFilter"
                className="fluid mb-15"
                search
                multiple
                placeholder="Показать всё"
                options={citizenshipOptions}
                value={citizenshipFilter}
                onChange={onChange}
            />
            <MigrationStatusFilter
                onChange={onChange}
                onSubmit={onSearch}
                value={migrationStatusesFilter}
            />
            <AdditionalDocumentFilter
                onChange={onChange}
                onSubmit={onSearch}
                value={additionalDocumentTypesFilter}
            />
            {
                isNMUsers() &&
                <AdditionalDocumentStatusFilter
                    onChange={onChange}
                    onSubmit={onSearch}
                    value={additionalDocumentStatusesFilter}
                />
            }
            <div className="invite-contractor-to-fc-registry-filter__row">
                <NmDropdownV2
                    size="lg"
                    label="Пол"
                    onChange={onChange}
                    name="genderFilter"
                    placeholder="Показать всё"
                    options={GENDER_OPTIONS}
                    value={genderFilter}
                />
                <NmRangeInput
                    label="Возраст"
                    nameStart="ageFromFilter"
                    nameEnd="ageToFilter"
                    size="lg"
                    valueStart={ageFromFilter}
                    valueEnd={ageToFilter}
                    onChange={onChange}
                    placeholderStart="От"
                    placeholderEnd="До"
                />
            </div>
            <div className="invite-contractor-to-fc-registry-filter__row">
                <NmInputV2
                    size="lg"
                    onChange={onChange}
                    name="fioFilter"
                    value={fioFilter}
                    label="ФИО исполнителя"
                />
                <NmInputV2
                    size="lg"
                    mask="+7 (999) 999 99 99"
                    onChange={onChange}
                    maskChar={null}
                    name="phoneFilter"
                    label="Номер телефона"
                    value={phoneFilter}
                />
            </div>
            <NmDropdownV2
                size="lg"
                label="Статус регистрации"
                name="registrationStatusFilter"
                className="fluid"
                search
                multiple
                placeholder="Показать всё"
                options={contractorStatusOptions}
                value={registrationStatusFilter}
                onChange={onChange}
            />
            <FilterButtonsV2
                onSearch={onSearch}
                onClear={onClear}
            />
        </NmForm>
    );
};