import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import validationSchema from "../validation";

import {isNMUsers} from "../../../../../../utils/access";
import {CURRENT_CLIENT_USER_ID, ls} from "../../../../../../utils/localstorage";
import {handleFormString} from "../../../../../../utils/stringHelper";

import {STAFF_STATUS, STAFF_TYPE} from "../../../../../../constants/edmStaff";

import {downloadDocument} from "../../../../../../ducks/documents";
import {
    addDocumentsEdoRegistries,
    getDocumentsEdoRegistriesAttachmentsFile,
    updateDocumentsEdoRegistries,
} from "../../../../../../ducks/documentsEdoRegistries";
import {getEdmStaffList} from "../../../../../../ducks/edmStaff";
import {getEdoDocumentTypes} from "../../../../../../ducks/edocuments";

import {
    EDO_DOCUMENT_FIELD,
    TYPE_LOADING_EDO_DOCUMENT,
} from "../../../../../../constants/document-management/document-list";


const useDataForm = ({fetchList, onClose, clientId, isEdit, editData, ownerOptions}) => {
    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

    const [isShowTypeLoading, setIsShowTypeLoading] = useState(editData?.useTemplateFile || false);

    const dispatch = useDispatch();

    const initialValues = {
        params: {},
        name: "",
        description: "",
        templateType: TYPE_LOADING_EDO_DOCUMENT.TEMPLATE,
        ownerId: !isNMUsers() ? ownerOptions.find(value => value.clientUserId === currentClientUserId)?.value : undefined,
        documentType: undefined,
        file: undefined,
    };

    useEffect(() => {
        setFieldValue("isShowTypeLoading", isShowTypeLoading);
    }, [isShowTypeLoading]);

    useEffect(() => {
        if (isEdit) {
            setValues({
                ...initialValues,
                ...editData,
                params: {
                    type: editData.templateType,
                },
                templateType: editData.useTemplateFile ? TYPE_LOADING_EDO_DOCUMENT.TEMPLATE : TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT,
            });
        }

        if (isEdit && !editData.useTemplateFile) {
            dispatch(getDocumentsEdoRegistriesAttachmentsFile({
                clientId,
                registryId: editData.registryId,
                fileName: "Прикрепленный документ.pdf",
                getResult: (file) => handleDrop([file]),
            }));
        }

        fetchStaffList();
    }, []);

    const handleChange = (e, {name, value, checked}) => {
        setFieldValue(name, typeof value === "string" ? value : checked);
        setFieldTouched(name, true);
    };

    const handleChangeTypeDocument = (e, data) => {
        const {name, value, templateType, text} = data;

        if (templateType) {
            setFieldValue(EDO_DOCUMENT_FIELD.PARAMS.TYPE, templateType);
            setIsShowTypeLoading(true);
        } else {
            setFieldValue("typeLoading", TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT);
            setFieldValue(EDO_DOCUMENT_FIELD.PARAMS.TYPE, "");
            setIsShowTypeLoading(false);
        }

        setFieldValue(EDO_DOCUMENT_FIELD.DOCUMENT_NAME, text);
        setFieldTouched(name, true);
        setFieldValue(name, value);
    };

    const handleDrop = (files) => {
        const [file] = files;

        setFieldValue(EDO_DOCUMENT_FIELD.FILE, file);
        setFieldTouched(EDO_DOCUMENT_FIELD.FILE, true);
    };

    const handleTypeLoading = (e, {name}) => {
        if (values.templateType && !values.params.type) {
            setFieldValue(EDO_DOCUMENT_FIELD.PARAMS.TYPE, values.templateType);
        }

        setFieldValue("typeLoading", name);
    };

    const handleOnSearchDocumentType = (valueFilter) => {
        fetchEdoDocumentStates(valueFilter);
    };

    const fetchStaffList = () => {
        dispatch(getEdmStaffList({
            clientId,
            type: STAFF_TYPE.CLIENT_USER,
            status: STAFF_STATUS.WORKING,
            pageNum: 1,
            pageSize: 100,
        }));
    };

    const fetchEdoDocumentStates = (valueFilter) => {
        const payload = {
            clientId,
            pageNum: 1,
            pageSize: 100,
            valueFilter: handleFormString(valueFilter),
        };

        dispatch(getEdoDocumentTypes(payload));
    };

    const handleDownloadEmptyTemplate = () => {
        if (!values.params.type) {
            return;
        }

        dispatch(downloadDocument({
            downloadLink: `${window.location.origin}/api/edocuments/signing/template/empty?type=${values.documentType}`,
            extension: "pdf",
            isDownload: false,
            fileName: "_signed.pdf",
        }));
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            registryId,
            name,
            description,
            ownerId,
            documentType,
            templateType,
            file,
        } = values;


        const formData = new FormData();

        if (!isEmpty(file)) {
            formData.append("file", file);
        }

        formData.append("model",  new Blob([JSON.stringify({
            registryId,
            clientId,
            name,
            description,
            ownerId,
            documentType,
            useTemplateFile: templateType === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE && isEmpty(file),
        })], {
            type: "application/json",
        }));

        const onSuccess = () => {
            fetchList();
            onClose();
        };

        if (isEdit) {
            dispatch(updateDocumentsEdoRegistries({
                formData,
                onSuccess,
            }));

            return;
        }

        dispatch(addDocumentsEdoRegistries({
            formData,
            onSuccess,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        errors,
        isValid,
        touched,
        setFieldTouched,
        handleBlur,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        touched,
        errors,
        handleChange,
        handleChangeTypeDocument,
        handleTypeLoading,
        handleSubmit,
        handleBlur,
        handleOnSearchDocumentType,
        handleDownloadEmptyTemplate,
        isShowTypeLoading,
        setIsShowTypeLoading,
        handleDrop,
    };
};

export default useDataForm;