import React from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import ContractorVerificationDataGeneralFields from "../general-fields";
import ContractorVerificationInfoBlockRow from "../info-block-row";
import ContractorVerificationInputContainer from "../input-container";
import ContractorVerificationRowContainer from "../row-container";

import {
    contractorMigrationStatusDictSelector,
    getCitizenshipSelector,
} from "../../../../../ducks/contractor";

const ContractorVerificationTemporaryRefugeeCertificateContent = (props) => {
    const {
        setFieldValue,
        localizationData,
        values,
        getChanged,
        handleFocus,
        handleBlur,
        getPreviousValue,
        fieldNameObject,
        handleChange,
        migrationStatus,
        fieldWarnings,
        errors,
        setInnStatus,
    } = props;
    const migrationStatusDict = useSelector(contractorMigrationStatusDictSelector);
    const citizenshipDict = useSelector(getCitizenshipSelector);

    return (
        <>
            <ContractorVerificationInfoBlockRow
                className="mb-2 mb-xl-4"
                list={[
                    {
                        title: `${localizationData.citizenship}:`,
                        value: citizenshipDict[values.citizenship],
                    },
                    {
                        title: "Миграционный статус:",
                        value: migrationStatusDict[migrationStatus],
                    },
                    {
                        title: `${localizationData.inn}:`,
                        value: values.inn,
                    },
                ]}
            />
            <ContractorVerificationDataGeneralFields
                localizationData={localizationData}
                getChanged={getChanged}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                getPreviousValue={getPreviousValue}
                errors={errors}
                setInnStatus={setInnStatus}
                setFieldValue={setFieldValue}
            />
            <ContractorVerificationRowContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged(fieldNameObject.num)}
                        label="Серия и номер"
                        name={fieldNameObject.num}
                        value={values[fieldNameObject.num]}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        required
                        tooltip={getPreviousValue(fieldNameObject.num)}
                        error={errors && errors[fieldNameObject.num]}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmDatePicker
                        size="xl"
                        dateFormatMask="99.99.9999"
                        changed={getChanged(fieldNameObject.issuedDate)}
                        name={fieldNameObject.issuedDate}
                        label="Дата выдачи"
                        onFocus={handleFocus}
                        required
                        selected={values[fieldNameObject.issuedDate]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors && errors[fieldNameObject.issuedDate]}
                        warning={fieldWarnings[fieldNameObject.issuedDate]}
                        tooltip={getPreviousValue(fieldNameObject.issuedDate)}
                    />
                </ContractorVerificationInputContainer>
                <ContractorVerificationInputContainer>
                    <NmDatePicker
                        size="xl"
                        dateFormatMask="99.99.9999"
                        changed={getChanged(fieldNameObject.validToDate)}
                        name={fieldNameObject.validToDate}
                        label="Дата окончания срока действия"
                        onFocus={handleFocus}
                        required
                        selected={values[fieldNameObject.validToDate]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors && errors[fieldNameObject.validToDate]}
                        warning={fieldWarnings[fieldNameObject.validToDate]}
                        tooltip={getPreviousValue(fieldNameObject.validToDate)}
                    />
                </ContractorVerificationInputContainer>
            </ContractorVerificationRowContainer>
            <ContractorVerificationRowContainer>
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged(fieldNameObject.birthPlace)}
                        name={fieldNameObject.birthPlace}
                        label="Место рождения"
                        value={values[fieldNameObject.birthPlace]}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        required
                        tooltip={getPreviousValue(fieldNameObject.birthPlace)}
                        error={errors && errors[fieldNameObject.birthPlace]}
                    />
                </ContractorVerificationInputContainer>
                {
                    fieldNameObject.issueReason &&
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged(fieldNameObject.issueReason)}
                            name={fieldNameObject.issueReason}
                            label="Причина выдачи"
                            value={values[fieldNameObject.issueReason]}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onChange={handleChange}
                            tooltip={getPreviousValue(fieldNameObject.issueReason)}
                            error={errors && errors[fieldNameObject.issueReason]}
                        />
                    </ContractorVerificationInputContainer>
                }
                <ContractorVerificationInputContainer>
                    <NmInputV2
                        size="xl"
                        changed={getChanged(fieldNameObject.issuedBy)}
                        name={fieldNameObject.issuedBy}
                        label="Кем выдано"
                        value={values[fieldNameObject.issuedBy]}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        required
                        tooltip={getPreviousValue(fieldNameObject.issuedBy)}
                        error={errors && errors[fieldNameObject.issuedBy]}
                    />
                </ContractorVerificationInputContainer>
                {
                    fieldNameObject.personalFileNum &&
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged(fieldNameObject.personalFileNum)}
                            label="Номер личного дела"
                            name={fieldNameObject.personalFileNum}
                            value={values[fieldNameObject.personalFileNum]}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onChange={handleChange}
                            required
                            tooltip={getPreviousValue(fieldNameObject.personalFileNum)}
                            error={errors && errors[fieldNameObject.personalFileNum]}
                        />
                    </ContractorVerificationInputContainer>
                }
            </ContractorVerificationRowContainer>
        </>
    );
};

export default ContractorVerificationTemporaryRefugeeCertificateContent;