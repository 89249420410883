import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/adm/settings/directories/banks";

const SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST = "SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR = "SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR";

const SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST = "SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST";
const SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS";
const SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR = "SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR";

const REFRESH_DIRECTORIES_SBP_BANKS_REQUEST = "REFRESH_DIRECTORIES_SBP_BANKS_REQUEST";
const REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS = "REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS";
const REFRESH_DIRECTORIES_SBP_BANKS_ERROR = "REFRESH_DIRECTORIES_SBP_BANKS_ERROR";

const SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST = "SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST";
const SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS = "SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS";
const SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR = "SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR";

const DIRECTORIES_BANKS_GET_SETTINGS_REQUEST = "DIRECTORIES_BANKS_GET_SETTINGS_REQUEST";
const DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS = "DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS";
const DIRECTORIES_BANKS_GET_SETTINGS_ERROR = "DIRECTORIES_BANKS_GET_SETTINGS_ERROR";

const DIRECTORIES_BANKS_SET_SETTINGS_REQUEST = "DIRECTORIES_BANKS_SET_SETTINGS_REQUEST";
const DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS = "DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS";
const DIRECTORIES_BANKS_SET_SETTINGS_ERROR = "DIRECTORIES_BANKS_SET_SETTINGS_ERROR";

const SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST = "SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR = "SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR";

const SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR";

const SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR";

const SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR";

const SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR";

const SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR";

const SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST = "SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS = "SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS";
const SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR = "SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR";

const SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE = "SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE";

export const initialSettingsBanksBalancesPage = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    bankList: [],
    accessPage: false,
    error: null,
};

const initialState = {
    pageData: {},
    sbpPageData: {},
    list: [],
    sbpList: [],
    totalCount: 0,
    sbpTotalCount: 0,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    card: {},
    progress: false,
    progressAction: false,
    contractorWalletWithdrawAvailable: false,
    contractorCommissionWithdrawal: {},
    balancesPage: initialSettingsBanksBalancesPage,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST:
        return {
            ...state,
            sbpPageData: payload,
            progress: true,
        };
    case SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST:
        return {
            ...state,
            logPageData: payload,
            logProgress: true,
        };
    case SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS:
        const {totalCount, results} = payload;

        return {
            ...state,
            totalCount,
            list: results,
            progress: false,
        };
    case SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS:
        return {
            ...state,
            sbpTotalCount: payload.totalCount,
            sbpList: payload.results,
            progress: false,
        };
    case SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS:
        return {
            ...state,
            logTotalCount: payload.totalCount,
            logList: payload.results,
            logProgress: false,
        };
    case SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR:
    case SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST:
    case DIRECTORIES_BANKS_GET_SETTINGS_REQUEST:
    case DIRECTORIES_BANKS_SET_SETTINGS_REQUEST:
    case REFRESH_DIRECTORIES_SBP_BANKS_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS:
    case DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS:
    case REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS:
    case SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR:
    case DIRECTORIES_BANKS_GET_SETTINGS_ERROR:
    case DIRECTORIES_BANKS_SET_SETTINGS_ERROR:
    case REFRESH_DIRECTORIES_SBP_BANKS_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS:
        return {
            ...state,
            progressAction: false,
            [payload.fieldName]: payload.value,
        };
    case SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR:
        return {
            ...state,
            logProgress: false,
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                accessPage: payload,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                pageData: payload,
                progress: true,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                progress: false,
                list: payload.results || [],
                totalCount: payload.totalCount || 0,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                progress: false,
                error: payload,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                logPageData: payload,
                logProgress: true,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                logProgress: false,
                logList: payload.results || [],
                logTotalCount: payload.totalCount || 0,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                logProgress: false,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                bankList: payload || [],
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST:
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                progressAction: true,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS:
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR:
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS:
    case SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR:
        return {
            ...state,
            balancesPage: {
                ...state.balancesPage,
                progressAction: false,
            },
        };
    case SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export const getSettingsDirectoriesBanksPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST,
    payload,
});

export const getSettingsDirectoriesSbpBanksPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST,
    payload,
});

export const refreshSettingsDirectoriesSbpBanks = () => ({
    type: REFRESH_DIRECTORIES_SBP_BANKS_REQUEST,
});

export const updateSettingsDirectoriesBank = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST,
    payload,
});

export const getSettingsDirectoriesBank = (payload) => ({
    type: DIRECTORIES_BANKS_GET_SETTINGS_REQUEST,
    payload,
});

export const setSettingsDirectoriesBank = (payload) => ({
    type: DIRECTORIES_BANKS_SET_SETTINGS_REQUEST,
    payload,
});

export const getSettingsDirectoriesBanksLogsPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST,
    payload,
});

export const getSettingsDirectoriesBanksBalancesPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST,
    payload,
});

export const addSettingsDirectoriesBanksBalance = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST,
    payload,
});

export const editSettingsDirectoriesBanksBalance = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST,
    payload,
});

export const getNewSettingsDirectoriesBanksBalance = () => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST,
});

export const getSettingsDirectoriesBanksBalanceLogPage = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST,
    payload,
});

export const checkAccessSettingsDirectoriesBanksBalance = () => ({
    type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST,
});

export const updateStoreSettingsDirectoriesBanks = (payload) => ({
    type: SETTINGS_DIRECTORIES_BANKS_UPDATE_STORE,
    payload,
});

export const settingsDirectoriesBanksSelector = ({settingsDirectoriesBanks}) => settingsDirectoriesBanks;
export const settingsDirectoriesBanksListSelector = createSelector(settingsDirectoriesBanksSelector, ({list}) => {
    const paymentInfo = {
        CARD_LOCAL: {
            DIRECT: false,
            THROUGH_WALLET: false,
        },
        SBP: {
            DIRECT: false,
            THROUGH_WALLET: false,
        },
        BANK_REQUISITES: {
            DIRECT: false,
            THROUGH_WALLET: false,
        },
    };

    return list.map(item => {
        return {
            ...item,
            paymentsPathsInfo: item.paymentPaths.reduce((result, value) => {
                return {
                    ...result,
                    [value.paymentType]: {
                        ...result[value.paymentType],
                        [value.paymentMethod]: {
                            ...result[value.paymentType][value.paymentMethod],
                            [value.paymentPathType]: true,
                        },
                    },
                };
            }, {
                SMZ: paymentInfo,
                CIVIL: paymentInfo,
                INDIVIDUAL: paymentInfo,
            }),
        };
    });
});
export const settingsDirectoriesSbpBanksListSelector = createSelector(settingsDirectoriesBanksSelector, ({sbpList}) => sbpList);
export const settingsDirectoriesBanksTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({totalCount}) => totalCount);
export const settingsDirectoriesSbpBanksTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({sbpTotalCount}) => sbpTotalCount);
export const settingsDirectoriesBanksProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({progress}) => progress);
export const settingsDirectoriesBanksProgressActionSelector = createSelector(settingsDirectoriesBanksSelector, ({progressAction}) => progressAction);
export const contractorWalletWithdrawAvailableSelector = createSelector(settingsDirectoriesBanksSelector, ({contractorWalletWithdrawAvailable}) => contractorWalletWithdrawAvailable);
export const contractorCommissionWithdrawalSelector = createSelector(settingsDirectoriesBanksSelector, ({contractorCommissionWithdrawal}) => contractorCommissionWithdrawal);
export const settingsDirectoriesBanksTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesSbpBanksTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    sbpTotalCount,
    sbpPageData: {pageSize = 0},
}) => getTotalPages(sbpTotalCount, pageSize));
export const settingsDirectoriesBanksLogListSelector = createSelector(settingsDirectoriesBanksSelector, ({logList}) => logList);
export const settingsDirectoriesBanksLogTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({logTotalCount}) => logTotalCount);
export const settingsDirectoriesBanksLogProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({logProgress}) => logProgress);
export const settingsDirectoriesBanksLogTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    logTotalCount,
    logPageData: {pageSize = 0},
}) => getTotalPages(logTotalCount, pageSize));
export const settingsDirectoriesBanksBalancesAccessPageSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {accessPage}}) => accessPage);
export const settingsDirectoriesBanksBalancesListSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {list}}) => list);
export const settingsDirectoriesBanksBalancesTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {totalCount}}) => totalCount);
export const settingsDirectoriesBanksBalancesProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {progress}}) => progress);
export const settingsDirectoriesBanksBalancesProgressActionSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {progressAction}}) => progressAction);
export const settingsDirectoriesBanksBalancesTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    balancesPage: {
        totalCount,
        pageData: {pageSize = 0},
    },
}) => getTotalPages(totalCount, pageSize));
export const settingsDirectoriesBanksBalancesLogListSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {logList}}) => logList);
export const settingsDirectoriesBanksBalancesLogTotalCountSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {logTotalCount}}) => logTotalCount);
export const settingsDirectoriesBanksBalancesLogProgressSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {logProgress}}) => logProgress);
export const settingsDirectoriesBanksBalancesLogTotalPagesSelector = createSelector(settingsDirectoriesBanksSelector, ({
    balancesPage: {
        logTotalCount,
        logPageData: {pageSize = 0},
    },
}) => getTotalPages(logTotalCount, pageSize));
export const settingsDirectoriesBanksBalancesBankListSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {bankList}}) => bankList);
export const settingsDirectoriesBanksBalancesBanksOptionsSelector = createSelector(settingsDirectoriesBanksSelector, ({balancesPage: {bankList}}) => {
    return bankList.map(({bankBalanceId, bankName}) => {
        return {
            key: bankBalanceId,
            text: bankName,
            value: bankBalanceId,
        };
    },
    );
},
);

function* getSettingsDirectoriesBanksPageSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getPage`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_BANKS_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_PAGE_ERROR, payload: e});
    }
}

function* getSettingsDirectoriesSbpBanksPageSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/common/dicts/getSbpParticipants", reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_ERROR, payload: e});
    }
}

function* refreshSettingsDirectoriesSbpBanksSaga() {
    try {
        const result = yield request.bff.post("/adm/common/dicts/refreshSbpParticipants");

        if (result.errorMessage) {
            toastError(result.errorMessage || "Не удалось обновить справочник");

            yield put({type: REFRESH_DIRECTORIES_SBP_BANKS_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Справочник успешно обновлен");

        yield put({type: REFRESH_DIRECTORIES_SBP_BANKS_SUCCESS, payload: result});
    } catch (e) {
        toastError("Не удалось обновить справочник");
        yield put({type: REFRESH_DIRECTORIES_SBP_BANKS_ERROR, payload: e});
    }
}

function* updateSettingsDirectoriesBankSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/updateBank`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_BANK_UPDATE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANK_UPDATE_ERROR, payload: e});
    }
}

function* getSettingsDirectoriesBankSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            key,
            ...reqData
        } = payload;

        const result = yield request.bff.get(`${controller}/getSystemSettings?key=${key}`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: DIRECTORIES_BANKS_GET_SETTINGS_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: DIRECTORIES_BANKS_GET_SETTINGS_SUCCESS, payload: {
                fieldName: key,
                value: result,
            },
        });
    } catch (e) {
        yield put({type: DIRECTORIES_BANKS_GET_SETTINGS_ERROR, payload: e});
    }
}

function* setSettingsDirectoriesBankSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/setSystemSettings`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: DIRECTORIES_BANKS_SET_SETTINGS_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: DIRECTORIES_BANKS_SET_SETTINGS_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: DIRECTORIES_BANKS_SET_SETTINGS_ERROR, payload: e});
    }
}

function* getSettingsDirectoriesBanksLogsPageSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/getSystemSettingsLogPage`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR, payload: result.errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_ERROR, payload: e});
    }
}

//POST /bff/adm/settings/directories/banks/balances
// Баланс на р/с - Получить баланс на р/с
function* getSettingsDirectoriesBanksBalancesPageSaga({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/balances`, payload);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_SUCCESS, payload: result});
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_ERROR, payload: e});
    }
}

//POST /bff/adm/settings/directories/banks/balances/add
// Баланс на р/с - Добавить банк
function* addSettingsDirectoriesBanksBalanceSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/balances/add`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_ERROR, payload: e});
    }
}

//POST PUT /bff/adm/settings/directories/banks/balances/edit
// Баланс на р/с - Редактировать банк
function* editSettingsDirectoriesBanksBalanceSaga({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${controller}/balances/edit`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_ERROR, payload: e});
    }
}

//GET /bff/adm/settings/directories/banks/balances/new
// Баланс на р/с - Получить незаполненные модели банков для списка названий банков
function* getNewSettingsDirectoriesBanksBalanceSaga() {
    try {
        const result = yield request.bff.get(`${controller}/balances/new`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_ERROR, payload: e});
    }
}

//POST /bff/adm/settings/directories/banks/balances/changes
// Баланс на р/с - Получить историю изменений
function* getSettingsDirectoriesBanksBalanceLogPageSaga({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/balances/changes`, payload);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_ERROR, payload: e});
    }
}

//GET /bff/adm/settings/directories/banks/balances/rights/preCheck
// Баланс на р/с - проверить доступ ко вкладке
function* checkAccessSettingsDirectoriesBanksBalanceSaga() {
    try {
        const result = yield request.bff.get(`${controller}/balances/rights/preCheck`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR,
                payload: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({type: SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_ERROR, payload: e});
    }
}

export function* saga() {
    yield all([
        takeEvery(SETTINGS_DIRECTORIES_BANKS_PAGE_REQUEST, getSettingsDirectoriesBanksPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_SBP_BANKS_PAGE_REQUEST, getSettingsDirectoriesSbpBanksPageSaga),
        takeEvery(REFRESH_DIRECTORIES_SBP_BANKS_REQUEST, refreshSettingsDirectoriesSbpBanksSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANK_UPDATE_REQUEST, updateSettingsDirectoriesBankSaga),
        takeEvery(DIRECTORIES_BANKS_GET_SETTINGS_REQUEST, getSettingsDirectoriesBankSaga),
        takeEvery(DIRECTORIES_BANKS_SET_SETTINGS_REQUEST, setSettingsDirectoriesBankSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_LOGS_PAGE_REQUEST, getSettingsDirectoriesBanksLogsPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCES_PAGE_REQUEST, getSettingsDirectoriesBanksBalancesPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_ADD_REQUEST, addSettingsDirectoriesBanksBalanceSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_EDIT_REQUEST, editSettingsDirectoriesBanksBalanceSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_NEW_REQUEST, getNewSettingsDirectoriesBanksBalanceSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_LOG_REQUEST, getSettingsDirectoriesBanksBalanceLogPageSaga),
        takeEvery(SETTINGS_DIRECTORIES_BANKS_BALANCE_CHECK_ACCESS_REQUEST, checkAccessSettingsDirectoriesBanksBalanceSaga),
    ]);
}