import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmPage from "../../../../components/NmPage";
import TableDiv from "../../../../components/TableDiv";

import {usePagination} from "../../../../hooks/usePagination";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {_delay} from "../../../../utils/delay";
import {getFullName} from "../../../../utils/stringFormat";

import {HEADER_TICKET_EVENT_LOG as headers} from "../../../../constants/crm/ticket";

import {
    getTicketEventLogRichPage,
    ticketEventLogListSelector,
    ticketEventLogTotalCountSelector,
    ticketEventLogTotalPagesSelector,
} from "../../../../ducks/crm/ticket";

import "./style.sass";

function TicketEventLog(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const list = useSelector(ticketEventLogListSelector);
    const totalCount = useSelector(ticketEventLogTotalCountSelector);
    const totalPages = useSelector(ticketEventLogTotalPagesSelector);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum]);

    const fetchList = () => {
        setLoading(true);
        dispatch(getTicketEventLogRichPage({
            pageSize,
            pageNum,
            ticketId: props.ticketId,
            getResult: _delay(() => setLoading(false)),
        }));
    };

    const getTableData = () => {
        const rows = list.map(value => {
            const {
                baseModel: {
                    createDate,
                    description,
                },
                managerModel,
            } = value;
            const {
                lastName,
                firstName,
                patronymic,
                fio,
            } = managerModel || {};

            const _fio = lastName ? getFullName(lastName, firstName, patronymic) : fio;

            return {
                ...value,
                createDate: formatLocalDate(createDate, "dd.MM.yyyy HH:mm"),
                operatorFullName: _fio || "Не определен",
                description,
            };
        });

        return {
            headers,
            rows,
        };
    };


    return (
        <NmPage
            noPadding
            isLoaded={loading}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
        >
            {totalCount === 0 && <NmEmptyPageV2
                title="Информация отсутствует"
            />}
            {totalCount > 0 && <TableDiv
                className="ticket-list__table"
                tableData={getTableData()}
            />}
        </NmPage>
    );
}

export default TicketEventLog;