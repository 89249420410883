import React, {useMemo, useState} from "react";

import {ReactComponent as SortIcon} from "../../images/sort.svg";
import {IContextMenu} from "../ActualComponents/ContextMenu";
import ContextMenuContent from "../ActualComponents/ContextMenu/Content";
import {TNmTooltipPosition} from "../ActualComponents/NmTooltip";
import Text from "../ActualComponents/Text";

import {useClickOutside} from "../../hooks/useClickOutside";

import {
    OptionType,
} from "../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../utils/bem";

import {COLOR} from "../../constants/color";

import "./style.sass";

const SortElement = (props: IContextMenu) => {
    const {
        className = "",
        onClickItem,
        isModalSortSize,
        options = [],
        inline = true,
    } = props;

    const initialActiveSort = useMemo(() => {
        if (options.length === 1) {
            return options[0];
        }

        const _defaultSort = options.find(value => value.isDefaultSort);

        if (_defaultSort) {
            return _defaultSort;
        }

        return {
            key: "",
            text: "",
            value: "",
        };
    }, [options]);

    const [block, element] = bem("nm-sort", className);

    const [position, setPosition] = useState<TNmTooltipPosition>("bottom-left");
    const [open, setOpen] = useState(false);
    const [activeSortOption, setActiveSortOption] = useState(initialActiveSort);

    const ref = useClickOutside<HTMLDivElement>(() => {
        setOpen(false);
    }, open);

    const dropdownClick = (event: any) => {
        const difference = document.documentElement.clientHeight - event.clientY;

        const ITEM_HEIGHT = 40;
        // Хвостик
        const ICON_HEIGHT = 8;

        if (
            options &&
            options.length !== 0
        ) {
            const dropdownHeight = options.length * ITEM_HEIGHT + ICON_HEIGHT;

            if (difference < dropdownHeight) {
                setPosition("top-right");
            }

            if (difference >= dropdownHeight && position === "top-right") {
                setPosition("bottom-right");
            }
        }

        setOpen(!open);
    };

    const getSortType = (option: OptionType) => {
        if (option.asc && !option.desc) {
            return option.asc;
        }

        if (
            option.desc &&
            option.key !== activeSortOption.key
        ) {
            return option.desc;
        }

        if (option.desc && !option.asc) {
            return option.desc;
        }

        if (
            option.asc &&
            option.desc &&
            option.sortType
        ) {
            return option.sortType === option.asc ? option.desc : option.asc;
        }

        if (
            option.asc &&
            option.desc &&
            !option.sortType
        ) {
            return option.desc;
        }

        return option.sortType;
    };

    const _onClickItem = (option: OptionType) => {
        if (option.key === activeSortOption.key) {
            return;
        }

        const sortType = getSortType(option);

        if (onClickItem) {
            onClickItem({...option, sortType});
        }

        setActiveSortOption({...option, sortType});
    };

    const _onClickSort = () => {
        const sortType = getSortType(activeSortOption);

        if (onClickItem) {
            onClickItem({...activeSortOption, sortType});
        }

        setActiveSortOption({...activeSortOption, sortType});
    };

    return (
        <div
            ref={ref}
            className={block()}
        >
            <Text
                level="3"
                className={element("text")}
            >
                Сортировать
            </Text>
            <div
                className={element("icon-container")}
            >
                <SortIcon
                    className={element("icon", {
                        asc: activeSortOption.asc && activeSortOption.sortType === activeSortOption.asc,
                    })}
                    onClick={_onClickSort}
                />
                {
                    activeSortOption.text &&
                    <Text
                        color={COLOR.PASSIVE_100}
                        level="3"
                        className={element("active-sort")}
                        onClick={dropdownClick}
                    >
                        {activeSortOption.text}
                    </Text>
                }
                {
                    open &&
                    <ContextMenuContent
                        inline={inline}
                        isModalSize={isModalSortSize}
                        className={element("context-menu-content")}
                        onClickItem={_onClickItem}
                        onCloseContextMenu={() => setOpen(false)}
                        options={options}
                        position={position}
                        isVisibleSelected={true}
                        selected={activeSortOption}
                    />
                }
            </div>
        </div>
    );
};

export default React.memo(SortElement);
