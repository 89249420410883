import {citizenshipsDict} from "../constants/citizenships";
import {CONTRACTOR_STATUS} from "../constants/contractor";

export const getContractorStatusesFlags = (contractor, role) => {
    const {
        status,
        edmStatus,
        citizenship,
    } = contractor;

    /*
    статус исполнителя ЭДО = "Пройдена простая регистрация"
    И статус исполнителя НПД = "Пройдена простая регистрация"
    */
    if (status === CONTRACTOR_STATUS.BASIC_REGISTRATION
        && edmStatus === CONTRACTOR_STATUS.BASIC_REGISTRATION
        && [citizenshipsDict.TJ.value, citizenshipsDict.UA.value, citizenshipsDict.UZ.value].includes(citizenship)) {
        /*
        На списковой форме необходимо:
        Отображать статус ЭДО исполнителя;
        Не отображать индикацию проверки ФНС;
        Не отображать индикацию "Возможность оплаты"
        */
        return {
            isShowSmzStatus: true,
            isShowEdmStatus: true,
            isShowPaymentStatus: false,
            isShowFnsStatus: false,
        };
    }
    /*
    статус исполнителя ЭДО = "На проверке администратором" или "Проверка пройдена" или "Изменения на проверке администратором"
    И статус исполнителя НПД = "Пройдена простая регистрация"
     */
    if ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK,
        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED].includes(edmStatus)
        && status === CONTRACTOR_STATUS.BASIC_REGISTRATION
        && [citizenshipsDict.TJ.value, citizenshipsDict.UA.value, citizenshipsDict.UZ.value].includes(citizenship)) {
        /*
            На списковой форме необходимо:
            Отображать статус ЭДО исполнителя;
            Не отображать индикацию проверки ФНС;
            Не отображать индикацию "Возможность оплаты"
             */
        return {
            isShowSmzStatus: true,
            isShowEdmStatus: true,
            isShowFnsStatus: false,
            isShowPaymentStatus: false,
        };
    }
    /*
    статус исполнителя ЭДО = "Проверка пройдена";
    И статус исполнителя НПД = "Проверка пройдена", "Банковская проверка не пройдена
     */
    if ([CONTRACTOR_STATUS.BANK_CHECK_PASSED,
        CONTRACTOR_STATUS.BANK_CHECK_FAILED,
    ].includes(status)
        && edmStatus === CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED) {
        // в соответствии с текущей реализацией
        return {
            isShowSmzStatus: true,
            isShowEdmStatus: true,
            isShowFnsStatus: true,
            isShowPaymentStatus: true,
        };
    }
    /*
    статус исполнителя ЭДО = "Пройдена простая регистрация";
    И статус исполнителя НПД = "На проверке администратором" или "На проверке банком" или "Проверка пройдена" или "Банковская проверка не пройдена" или "Изменения на проверке администратором";
     */
    if ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK,
        CONTRACTOR_STATUS.BANK_CHECK,
        CONTRACTOR_STATUS.BANK_CHECK_PASSED,
        CONTRACTOR_STATUS.BANK_CHECK_FAILED,
        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
    ].includes(status)
        && edmStatus === CONTRACTOR_STATUS.BASIC_REGISTRATION) {
        // в соответствии с текущей реализацией
        return {
            isShowSmzStatus: true,
            isShowEdmStatus: true,
            isShowFnsStatus: true,
            isShowPaymentStatus: true,
        };
    }
    return {
        isShowSmzStatus: true,
        isShowEdmStatus: true,
        isShowFnsStatus: true,
        isShowPaymentStatus: true,
    };
};