import {CONTRACTOR_MIGRATION_STATUS} from "../../../../../../constants/contractor";

export const getContractorAddress = (params) => {
    const {
        values,
    } = params;

    if ([CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT].includes(values.migrationStatus)) {
        return values.address || values.mainDocumentStampAddress;
    }

    return values.address;
};