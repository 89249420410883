import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/civil";
//*  TYPES  *//

const GET_FINANCE_CIVIL_PIVOT_REQUEST = "GET_FINANCE_CIVIL_PIVOT_REQUEST";
const GET_FINANCE_CIVIL_PIVOT_SUCCESS = "GET_FINANCE_CIVIL_PIVOT_SUCCESS";

const GET_FINANCE_CIVIL_HISTORY_REQUEST = "GET_FINANCE_CIVIL_HISTORY_REQUEST";
const GET_FINANCE_CIVIL_HISTORY_SUCCESS = "GET_FINANCE_CIVIL_HISTORY_SUCCESS";
const GET_FINANCE_CIVIL_HISTORY_ERROR = "GET_FINANCE_CIVIL_HISTORY_ERROR";

const UPDATE_FINANCE_CIVIL_STORE = "UPDATE_FINANCE_CIVIL_STORE";

//*  INITIAL STATE  *//

const initial = {
    reports: [],
    progress: false,
    totalSummary: {},
    totalCount: 0,
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_FINANCE_CIVIL_HISTORY_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_FINANCE_CIVIL_HISTORY_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case GET_FINANCE_CIVIL_HISTORY_ERROR:
        return {
            ...state,
            progress: false,
        };
    case GET_FINANCE_CIVIL_PIVOT_REQUEST:
        const {pageNum, pageSize} = payload;
        return {
            ...state,
            pageData: {
                pageNum,
                pageSize,
            },
        };
    case GET_FINANCE_CIVIL_PIVOT_SUCCESS:
        const {
            results: reports,
            totalCount,
            totals: totalSummary,
        } = payload;

        return {
            ...state,
            reports,
            totalSummary,
            totalCount,
        };
    case UPDATE_FINANCE_CIVIL_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getFinanceCivilReportsSummary(payload) {
    return {
        type: GET_FINANCE_CIVIL_PIVOT_REQUEST,
        payload,
    };
}

export function getFinanceCivilHistorySummary(payload) {
    return {
        type: GET_FINANCE_CIVIL_HISTORY_REQUEST,
        payload,
    };
}

export function updateFinanceCivilStore(payload) {
    return {
        type: UPDATE_FINANCE_CIVIL_STORE,
        payload,
    };
}

//*  SELECTORS  *//

export const financeCivilSelector = ({financeCivil}) => financeCivil;
export const financeCivilPivotReportsSelector = createSelector(financeCivilSelector, ({reports}) => reports);
export const financeCivilPivotReportsProgressSelector = createSelector(financeCivilSelector, ({progress}) => progress);
export const financeCivilPivotTotalSummarySelector = createSelector(financeCivilSelector, ({totalSummary}) => totalSummary);
export const financeCivilTotalCountSelector = createSelector(financeCivilSelector, ({totalCount}) => totalCount);
export const financeCivilTotalPagesSelector = createSelector(financeCivilSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));

//*  SAGA  *//

//POST
// /api/civil/reports/summary
// Метод получения данных для сводной ведомости
export const getFinanceCivilReportsSummarySaga = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.post(`${controller}/reports/summary`, reqData);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("getFinanceCivilReportsSummarySaga Error");

            return {
                done: true,
            };
        }

        yield getResult();

        yield put({
            type: GET_FINANCE_CIVIL_PIVOT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error("getFinanceCivilReportsSummarySaga Error:", error);
        
        yield getResult();
    }
};

//POST
// /api/civil/reports/summary/depositHistory

export const getFinanceCivilHistorySummarySaga = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.post(`${controller}/reports/summary/depositHistory`, reqData);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FINANCE_CIVIL_HISTORY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_FINANCE_CIVIL_HISTORY_SUCCESS,
            payload: result,
        });

        yield getResult(result);
    } catch (error) {
        yield put({
            type: GET_FINANCE_CIVIL_HISTORY_ERROR,
            payload: error,
        });

        yield getResult();
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_FINANCE_CIVIL_PIVOT_REQUEST, getFinanceCivilReportsSummarySaga),
        takeEvery(GET_FINANCE_CIVIL_HISTORY_REQUEST, getFinanceCivilHistorySummarySaga),
    ]);
}
