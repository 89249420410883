import React from "react";

import DepositClientList from "../client-list";

import {getCivilDepositLinks} from "./utils/getTabLinks";

const DepositCivilClientList = (props) => {
    const {
        match: {params: {clientId}},
    } = props;

    return (
        <DepositClientList
            isCivil
            links={getCivilDepositLinks(clientId)}
            header="Депозит (НДФЛ)"
            {...props}
        />
    );
};

export default DepositCivilClientList;