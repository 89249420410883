import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    KEDO_EXPORTS_CANCEL_BY_ID_ERROR,
    KEDO_EXPORTS_CANCEL_BY_ID_REQUEST,
    KEDO_EXPORTS_CANCEL_BY_ID_SUCCESS,
    KEDO_EXPORTS_GET_PAGE_ERROR,
    KEDO_EXPORTS_GET_PAGE_REQUEST,
    KEDO_EXPORTS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";

// POST /bff/kedo/client/exports/getPage
// POST /bff/adm/companies/company/kedo/exports/getPage
// Получение страницы экспортов
const getKedoDocumentsExportPageSaga = function* ({payload}) {
    const {
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: KEDO_EXPORTS_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_EXPORTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: KEDO_EXPORTS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/kedo/client/exports/cancelById
// POST /bff/adm/companies/company/kedo/exports/cancelById
// Получение истории документа
const cancelKedoDocumentsExportByIdSaga = function* ({payload}) {
    const {
        onSuccess,
        url,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: KEDO_EXPORTS_CANCEL_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: KEDO_EXPORTS_CANCEL_BY_ID_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: KEDO_EXPORTS_CANCEL_BY_ID_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(KEDO_EXPORTS_GET_PAGE_REQUEST, getKedoDocumentsExportPageSaga),
        takeEvery(KEDO_EXPORTS_CANCEL_BY_ID_REQUEST, cancelKedoDocumentsExportByIdSaga),
    ]);
}