import React, {FC} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, RouteProps} from "react-router";

import {getAccessLink} from "../../utils/access";
import {ls, USER_ROLE} from "../../utils/localstorage";

import {LINK_FORBIDDEN_PAGE} from "../../constants/links";

import {clientCurrentMemberSelector} from "../../ducks/clientMember";

type CheckRoleRouteProps = RouteProps

export const CheckRoleRoute: FC<CheckRoleRouteProps> = (props) => {
    const {
        path,
        component,
        ...otherProps
    } = props;

    const currentMember = useSelector(clientCurrentMemberSelector);

    const role = ls(USER_ROLE);

    const accessLink = getAccessLink({role, currentMember}) || "";
    const isAccess = accessLink.indexOf(path) !== -1;

    if (!isAccess) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    return (
        <Route
            exact
            path={path}
            component={component}
            {...otherProps}
        />
    );
};