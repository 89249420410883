import {
    CLIENT_BRANDS_ADD_LOGO_REQUEST,
    CLIENT_BRANDS_ADD_REQUEST,
    CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST,
    CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_DELETE_LOGO_REQUEST,
    CLIENT_BRANDS_GET_LOGS_REQUEST,
    CLIENT_BRANDS_GET_PAGE_REQUEST,
    CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST,
    CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST,
    CLIENT_BRANDS_UPDATE_REQUEST,
    CLIENT_BRANDS_UPDATE_STORE,
} from "./actions";

export const updateClientBrand = (payload) => {
    return {
        type: CLIENT_BRANDS_UPDATE_REQUEST,
        payload,
    };
};

export const updateClientBrandArchiveStatus = (payload) => {
    return {
        type: CLIENT_BRANDS_UPDATE_ARCHIVE_STATUS_REQUEST,
        payload,
    };
};

export const getClientBrands = (payload) => {
    return {
        type: CLIENT_BRANDS_GET_PAGE_REQUEST,
        payload,
    };
};

export const deleteClientBrandLogo = (payload) => {
    return {
        type: CLIENT_BRANDS_DELETE_LOGO_REQUEST,
        payload,
    };
};

export const addClientBrand = (payload) => {
    return {
        type: CLIENT_BRANDS_ADD_REQUEST,
        payload,
    };
};

export const addClientBrandLogo = (payload) => {
    return {
        type: CLIENT_BRANDS_ADD_LOGO_REQUEST,
        payload,
    };
};

export const getAssignBrandsProjectsPage = (payload) => {
    return {
        type: CLIENT_BRANDS_ASSIGN_PROJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getAssignBrandsObjectsPage = (payload) => {
    return {
        type: CLIENT_BRANDS_ASSIGN_OBJECTS_PAGE_REQUEST,
        payload,
    };
};

export const saveAssignBrandsObjects = (payload) => {
    return {
        type: CLIENT_BRANDS_SAVE_ASSIGN_OBJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getLogsBrandChange = (payload) => {
    return {
        type: CLIENT_BRANDS_GET_LOGS_REQUEST,
        payload,
    };
};

export const getAssignBrandsProjectsObjectsList = (payload) => {
    return {
        type: CLIENT_BRANDS_ASSIGN_PROJECTS_OBJECTS_LIST_REQUEST,
        payload,
    };
};

export const updateClientBrandsStore = (payload) => {
    return {
        type: CLIENT_BRANDS_UPDATE_STORE,
        payload,
    };
};