import React from "react";

import NmLoader from "../../../../NmLoader";
import NmCheckboxV2 from "../../../NmCheckboxV2";
import {ClientSettingsChangedDataModal} from "../ChangedDataModal";
import {ClientSettingsSubmitButtons} from "../SubmitButtons";

import {useClientSettingsExternalApiMethodsForm} from "./hooks/useForm";

export const ClientSettingsExternalApiMethods = (props) => {
    const {
        isAccess,
        refFooter,
        onClose,
        clientId,
    } = props;

    const {
        isLoading,
        onChange,
        values,
        onSubmit,
    } = useClientSettingsExternalApiMethodsForm({
        clientId,
        onClose,
    });

    return (
        <>
            <ClientSettingsChangedDataModal onSubmit={onSubmit} />
            {
                isLoading &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="apiAccessEnabled"
                onChange={onChange}
                checked={values.apiAccessEnabled}
                label="Доступ к доп методам в API"
            />
            <NmCheckboxV2
                className="mb-3 mb-md-4"
                disabled={!isAccess}
                name="paidApisEnabled"
                onChange={onChange}
                checked={values.paidApisEnabled}
                label="Использование платных методов API"
            />
            <ClientSettingsSubmitButtons
                refFooter={refFooter}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
