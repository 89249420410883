import React, {useState} from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import "./style.sass";

const initForm = {
    valueFilter: "",
};

const DocumentManagementPositionsFilter = (props) => {
    const {
        submitFilter,
    } = props;

    const [form, setForm] = useState({
        ...initForm,
    });

    const {
        valueFilter,
    } = form;

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const submit = () => {
        submitFilter(form, true);
    };

    const clear = () => {
        submitFilter({
            ...initForm,
        }, false);

        setForm({
            ...initForm,
        });
    };

    return (
        <NmForm className="document-management-position-filter">
            <FilterButtonsV2
                className="document-management-position-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
            <NmInputV2
                size="lg"
                label="Должность"
                className="document-management-position-filter__input"
                name="valueFilter"
                value={valueFilter}
                onChange={onChange}
            />
        </NmForm>
    );
};

export default DocumentManagementPositionsFilter;