import React, {useEffect, useState} from "react";
import Media from "react-media";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2/index";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmPage from "../../../components/NmPage/index";
import NmReadonlyRating from "../../../components/NmReadonlyRating";
import NmTitle from "../../../components/NmTitle/index";
import {REVIEW_STATUS} from "../../contractor/reviews";
import ReviewsList from "../../reviews/components/reviews-list/index";
import ClientReviewReplyEditModal from "./components/review-reply-edit-modal";

import {useFilter} from "../../../hooks/useFilter";
import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useClientFeedbackListFilter} from "./hooks/useClientFeedbackListFilter";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import pluralize from "../../../utils/pluralize";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants/index";
import {REVIEW_REPLY_STATUS} from "../../../constants/reviews";
import {ADMIN, isClientUser, NM_MANAGER} from "../../../constants/roles";

import {
    clientRatingSelector,
    getClientRatingById,
    ratingProgressSelector,
} from "../../../ducks/ratings";
import {
    changeStatusClientFeedbackReply,
    clientFeedbackListProgressSelector,
    clientFeedbackListSelector,
    clientFeedbackListTotalCountSelector,
    clientFeedbackListTotalPagesSelector,
    confirmClientFeedback,
    declineClientFeedback,
    deleteClientFeedback,
    getClientFeedbackPage,
    getProgressClientFeedbackActionSelector,
    sendFeedbackComplaint,
} from "../../../ducks/scores";

import "./style.sass";

function ClientFeedbackList(props) {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const role = ls(USER_ROLE);

    const [action, setAction] = useState(null);

    const dispatch = useDispatch();

    const totalPages = useSelector(clientFeedbackListTotalPagesSelector);
    const totalCount = useSelector(clientFeedbackListTotalCountSelector);
    const progress = useSelector(clientFeedbackListProgressSelector);
    const progressAction = useSelector(getProgressClientFeedbackActionSelector);

    const list = useSelector(clientFeedbackListSelector);

    const ratingData = useSelector(clientRatingSelector);

    const ratingProgress = useSelector(ratingProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        initFilter,
        mapFilterDto,
        filters,
    } = useClientFeedbackListFilter();

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        setFilter,
    } = useFilter({
        initFilter,
        mapFilterDto,
        setPagination,
        pageSize,
    });

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        dispatch(getClientFeedbackPage({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
        dispatch(getClientRatingById({clientId}));
    };

    const deleteFeedback = (id) => {
        return dispatch(deleteClientFeedback({id, onSuccess: fetchList}));
    };

    const declineFeedback = (id) => {
        return dispatch(declineClientFeedback({id, onSuccess: fetchList}));
    };

    const sendComplaint = (id) => {
        return dispatch(sendFeedbackComplaint({id, onSuccess: fetchList}));
    };

    const confirmFeedback = (id) => {
        return dispatch(confirmClientFeedback({id, onSuccess: fetchList}));
    };

    const changeStatusFeedbackReply = (reviewId, status) => {
        return dispatch(changeStatusClientFeedbackReply({
            reviewId,
            status,
            onSuccess: fetchList,
        }));
    };

    const getActions = (id, isReplyPresent) => {
        return {
            DELETE_FEEDBACK: {
                title: `Вы уверены, что хотите удалить отзыв${isReplyPresent ? " и ответ на него" : ""}?`,
                actionFnc: () => {
                    deleteFeedback(id);
                },
            },
            CANCEL_FEEDBACK: {
                title: "Вы уверены, что хотите отклонить публикацию отзыва?",
                actionFnc: () => {
                    declineFeedback(id);
                },
            },
            CONFIRM_FEEDBACK: {
                title: "Вы уверены, что хотите опубликовать отзыв?",
                actionFnc: () => {
                    confirmFeedback(id);
                },
            },
            SEND_COMPLAINT: {
                title: "Вы действительно хотите подать жалобу на отзыв?",
                actionFnc: () => {
                    sendComplaint(id);
                },
            },
            REPLY: {
                title: "Вы уверены, что хотите отклонить публикацию ответа на отзыв?",
                actionFnc: () => {
                    sendComplaint(id);
                },
            },
            REJECT_REPLY: {
                title: "Вы уверены, что хотите отклонить публикацию ответа на отзыв?",
                actionFnc: () => {
                    changeStatusFeedbackReply(id, REVIEW_REPLY_STATUS.DECLINED);
                },
            },
            CONFIRM_REPLY: {
                title: "Вы уверены, что хотите опубликовать ответ на отзыв?",
                actionFnc: () => {
                    changeStatusFeedbackReply(id, REVIEW_REPLY_STATUS.PUBLISHED);
                },
            },
        };
    };

    const getMediaActions = (item) => {
        const {
            id,
            status,
            reply,
            reviewText,
        } = item.baseModel || {};

        const isAccessEdit = [ADMIN, NM_MANAGER].includes(role);
        const isDraft = status === REVIEW_STATUS.DRAFT;
        const isReplyPresent = !isEmpty(reply);

        return {
            renderCount: {
                desktop: 7,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Удалить",
                        color: "white",
                        onClick: () => {
                            setAction(getActions(id, isReplyPresent).DELETE_FEEDBACK);
                        },
                    },
                    visible: isAccessEdit && !isDraft,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Подтвердить отзыв",
                        color: "green",
                        onClick: () => {
                            setAction(getActions(id).CONFIRM_FEEDBACK);
                        },
                    },
                    visible: isAccessEdit && isDraft,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Отклонить отзыв",
                        color: "white",
                        onClick: () => {
                            setAction(getActions(id).CANCEL_FEEDBACK);
                        },
                    },
                    visible: isAccessEdit && isDraft,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Отклонить ответ",
                        color: "white",
                        onClick: () => {
                            setAction(getActions(id).REJECT_REPLY);
                        },
                    },
                    visible: isAccessEdit && isReplyPresent && reply?.status === REVIEW_REPLY_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Подтвердить ответ",
                        color: "green",
                        onClick: () => {
                            setAction(getActions(id).CONFIRM_REPLY);
                        },
                    },
                    visible: isAccessEdit && isReplyPresent && reply?.status === REVIEW_REPLY_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Ответить",
                        color: "light-green",
                        onClick: () => {
                            onOpenModal({
                                isOpenReplyAddModal: true,
                                review: item,
                            });
                        },
                    },
                    visible: isClientUser(role) && !isEmpty(reviewText) && !isReplyPresent,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        disabled: progressAction,
                        children: "Пожаловаться",
                        color: "white",
                        onClick: () => {
                            setAction(getActions(id).SEND_COMPLAINT);
                        },
                    },
                    visible: isClientUser(role),
                },
            ],
        };
    };

    const openSuccessReplySubmitConfirm = () => {
        setAction({
            title: "Ваш ответ направлен на модерацию администратором. После успешного прохождения модерации ответ будет опубликован на форме просмотра отзывов",
            actionFnc: () => setAction(null),
            isHiddenCancel: true,
            confirmButton: "Ок",
        });
    };

    const renderConfirm = () => {
        return (action &&
            <NmConfirmV2
                size="sm"
                content={action.title}
                onCancel={() => {
                    setAction(null);
                }}
                onConfirm={action.actionFnc}
                confirmButton={action.confirmButton || "Подтвердить"}
                cancelButton={!action.isHiddenCancel && "Отменить"}
                loading={false}
            />
        );
    };

    const renderInfoBlock = () => {
        const {
            rating,
            scoreCount,
        } = ratingData;

        return (
            <Media query={{maxWidth: 767}}>
                {
                    mobile =>
                        <div className="client-feedbacks__info">
                            <div>
                                <NmLabelText
                                    type="page"
                                    label="Общий рейтинг"
                                    classNameLabel="client-feedbacks__info-label mb-2"
                                    text={<NmReadonlyRating
                                        rating={rating}
                                        maxRating={5}
                                    />}
                                />
                                <NmLabelText
                                    type="page"
                                    label="Всего"
                                    classNameLabel="client-feedbacks__info-label"
                                    text={pluralize(scoreCount || 0, ["оценка", "оценки", "оценок"])}
                                />
                            </div>
                        </div>
                }
            </Media>
        );
    };

    const renderReplyAddModal = () => {
        return (
            modalData?.isOpenReplyAddModal &&
            <ClientReviewReplyEditModal
                title="Ответ на отзыв исполнителя"
                review={modalData.review}
                onClose={onCloseModal}
                openSuccessConfirm={openSuccessReplySubmitConfirm}
            />
        );
    };

    return (
        <NmPage
            header={
                isClientUser(role) && <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Отзывы
                </NmTitle>
            }
            controlsClassName={!isClientUser(role) && "ms-auto"}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                    updateFilter={setFilter}
                />
            }
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progress || ratingProgress}
            className="client-feedbacks"
        >
            {renderReplyAddModal()}
            {renderInfoBlock()}
            {
                list?.length ?
                    <ReviewsList
                        role={role}
                        list={list}
                        isClientPage
                        getMediaActions={(item) => getMediaActions(item)}
                    /> :
                    <NmEmptyPageV2
                        title="Отзывов пока нет"
                        fetchProgress={progress}
                        isSearch={isSearch}
                    />
            }
            {renderConfirm()}
        </NmPage>
    );
}

export default ClientFeedbackList;