import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import AddItemsForm from "../../../components/AddItemsForm";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {dictionaryToOptions} from "../../../utils/objectHelper";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";

import {COLOR} from "../../../constants/color";
import {emailReg} from "../../../constants/validationRules";

import {
    addEmailNotification,
    getEmailNotificationById,
    getEmailTypeMapSelector,
    updateEmailNotification,
} from "../../../ducks/emailNotification";

import "./style.sass";

class NotificationMailingListEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorEmail: undefined,
            emails: [],
            email: "",
            notificationTypes: [],
            isCheckedTypesAll: false,
        };
    }

    componentDidMount() {
        const {
            getEmailNotificationById,
            isEdit,
            notificationEmailId,
            clientId,
        } = this.props;

        if (isEdit) {
            getEmailNotificationById({
                notificationEmailId,
                clientId,
                getResult: this.getResult,
            });
        }
    }

    getResult = (result) => {
        const {
            email,
            notificationTypes,
        } = result;

        const {emailTypeMap} = this.props;

        this.setState({
            emails: [email],
            notificationTypes,
            isCheckedTypesAll: !Object.keys(emailTypeMap).filter(item => !notificationTypes.includes(item)).length,
        });
    };

    saveSettingNotification = () => {
        const {
            addEmailNotification,
            close,
            handleSuccess,
            clientId,
            notificationEmailId,
        } = this.props;

        const {emails, notificationTypes} = this.state;

        if (isEmpty(emails) || isEmpty(notificationTypes)) {
            toastError("Для сохранения рассылки на почту необходимо указать email адреса и типы уведомлений.");
            return;
        }

        addEmailNotification({
            notificationEmailId,
            getSuccess: handleSuccess,
            emails: emails.reverse(),
            notificationTypes,
            clientId,
        });

        close();
    };

    onAddEmail = () => {
        const {email, emails} = this.state;

        if (isNullOrWhitespace(email)) {
            this.setState({errorEmail: "Заполните email"});
            return;
        }

        if (!emailReg.test(email)) {
            this.setState({errorEmail: "Введен некорректный email"});
            return;
        }

        if(emails.find(el => el === email)){
            this.setState({errorEmail: "Email уже существует"});
            return;
        }

        if(email.length >= 255){
            this.setState({errorEmail: "Введен некорректный email. Максимальное число символов 254"});
            return;
        }

        if(emails.length >= 30){
            this.setState({errorEmail: "Максимальное количество адресов 30"});
            return;
        }

        this.setState({errorEmail: undefined});

        this.setState(prevState => {
            return {
                emails: [
                    prevState.email,
                    ...prevState.emails,
                ],
                email: "",
            };
        });
    };

    handleChangeNotificationTypes = (e, {checked, name}) => {
        if (name === "ALL_NOTIFICATIONS") {
            const {emailTypeMap} = this.props;

            let notificationTypes = Object.keys(emailTypeMap);

            notificationTypes = Array.from(new Set(notificationTypes));

            this.setState(prevState => ({
                notificationTypes: checked ? notificationTypes : [],
                isCheckedTypesAll: !prevState.isCheckedTypesAll,
            }));

            return;
        }

        const handleState = (prevState) => {
            const {notificationTypes: prevNotificationTypes} = prevState;

            let newNotificationTypes = [...prevNotificationTypes];

            const {emailTypeMap} = this.props;


            if (checked) {
                newNotificationTypes.push(name);
            } else {
                newNotificationTypes = newNotificationTypes.filter(tp => tp !== name);
            }

            return {
                ...prevState,
                isCheckedTypesAll: !Object.keys(emailTypeMap).filter(item => !newNotificationTypes.includes(item)).length,
                notificationTypes: [...new Set(newNotificationTypes)],
            };
        };

        this.setState(handleState);
    };

    handleChangeAddEmail = (e, {value}) => {
        this.setState({
            email: value,
            errorEmail: undefined,
        });
    };

    deleteEmail = (email) => {
        this.setState(prevState => ({
            emails: prevState.emails.filter(item => item !== email),
        }));
    };

    renderCheckbox() {
        const {emailTypeMap} = this.props;
        const {notificationTypes} = this.state;

        const _emailTypeMap = dictionaryToOptions(emailTypeMap);

        return _emailTypeMap.map(type => {
            const stateType = notificationTypes.find(item => item === type.value);

            return (
                <NmCheckboxV2
                    label={type.text}
                    name={type.value}
                    className="notifications-mailing-edit__checkbox"
                    onChange={this.handleChangeNotificationTypes}
                    checked={stateType === type.value}
                />
            );
        });
    }

    toggleConfirmWindow = (deletedEmail) => {
        this.setState(prevState => ({
            ...prevState,
            deletedEmail,
            errorEmail: !deletedEmail ? null : prevState.errorEmail,
            openConfirmWindow: !prevState.openConfirmWindow,
        }));
    };

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            deletedEmail,
        } = this.state;
        const {t} = this.props;

        if (!openConfirmWindow) {
            return null;
        }

        return (
            <NmConfirmV2
                content={`Вы действительно хотите удалить ${deletedEmail} из рассылки?`}
                onCancel={this.toggleConfirmWindow}
                onConfirm={() => this.deleteEmail(deletedEmail)}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                isNeedClosing
            />
        );
    }

    render() {
        const {
            t,
            close,
            isEdit,
        } = this.props;
        const {
            emails,
            email,
            errorEmail,
            isCheckedTypesAll,
        } = this.state;

        return (
            <NmModal
                size="md"
                className="notifications-mailing-edit"
                header={
                    <NmTitle size="lg">
                        {isEdit ? "Редактирование рассылки" : "Рассылка на почту"}
                    </NmTitle>
                }
                onClose={close}
                classNameContent="flex-column"
                footer={
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        submitBtnContent={t("button.save")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={close}
                        submit={this.saveSettingNotification}
                    />
                }
            >
                {this.renderConfirmWindow()}
                <AddItemsForm
                    inputLabel="Введите e-mail для рассылки"
                    error={errorEmail}
                    isVisibleAddBlock={isEdit}
                    inputValue={email}
                    className="mb-4"
                    onClickDelete={this.toggleConfirmWindow}
                    onAddItem={this.onAddEmail}
                    onChangeInput={this.handleChangeAddEmail}
                    items={emails}
                />
                <Text
                    level="3"
                    color={COLOR.SECONDARY_100}
                    className="mb-2"
                >
                    Категории
                </Text>
                <NmCheckboxV2
                    label="Все уведомления"
                    name="ALL_NOTIFICATIONS"
                    className="notifications-mailing-edit__checkbox"
                    onChange={this.handleChangeNotificationTypes}
                    checked={isCheckedTypesAll}
                />
                {this.renderCheckbox()}
            </NmModal>
        );
    }
}

export default connect(state => ({
    emailTypeMap: getEmailTypeMapSelector(state),
}),
{
    addEmailNotification,
    updateEmailNotification,
    getEmailNotificationById,
},
)(withTranslation()(NotificationMailingListEdit));
