import React from "react";
import {useSelector} from "react-redux";

import NmStatus from "../../../../../components/NmStatus";
import {ReactComponent as EditIcon} from "../../../../../images/mode.svg";
import ContractorGeneralInfoRegistrationStatusChanging from "../registration-status-changing";

import {useContractorPassportCheckingForm} from "./hooks/useForm";

import bem from "../../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {COLOR} from "../../../../../constants/color";
import {ADMIN} from "../../../../../constants/roles";

import {contractorCardSelector} from "../../../../../ducks/contractor";

import "./style.sass";

const ContractorGeneralInfoPassportChecking = (props) => {
    const {
        className,
    } = props;
    const role = ls(USER_ROLE);
    const [block] = bem("contractor-general-info-passport-checking", className);

    const contractor = useSelector(contractorCardSelector);

    const {
        isPassportSuccess,
        passportSuccessText,
        passportErrorText,
        setOpenEdit,
        openEdit,
    } = useContractorPassportCheckingForm({contractor});

    return (
        <div className={block()}>
            {
                openEdit &&
                <ContractorGeneralInfoRegistrationStatusChanging
                    onClose={() => {
                        setOpenEdit(false);
                    }}
                />
            }
            <NmStatus
                isSuccess={isPassportSuccess}
                name="Паспорт проверен"
                errorText={passportErrorText}
                successText={passportSuccessText}
            />
            {
                [ADMIN].includes(role) &&
                <EditIcon
                    className="ms-2"
                    style={{
                        cursor: "pointer",
                        color: COLOR.SECONDARY_45,
                    }}
                    onClick={() => {
                        setOpenEdit(true);
                    }}
                />
            }
        </div>
    );
};

export default ContractorGeneralInfoPassportChecking;