import React, {useContext, useMemo} from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import Avatar from "../../../../../../components/Avatar";
import CheckboxList from "../../../../../../components/CheckboxList";
import SelectionCountWithAction from "../../../../../../components/SelectionCountWithAction";
import MapContractorListCard from "../../../contractor-list-card";
import {MapInviteModalContext} from "../context-provider";

import {
    bffMapInviteModalContractorsSelector,
    bffMapInviteModalProgressListSelector,
} from "../../../../../../ducks/bff/map/selectors";
import {avatarBase64ImagesListSelector} from "../../../../../../ducks/fileStore";

const MapInviteModalContractorList = () => {
    const list = useSelector(bffMapInviteModalContractorsSelector);
    const progress = useSelector(bffMapInviteModalProgressListSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useContext(MapInviteModalContext);

    const rows = useMemo(() => {
        return list.map(item => {
            const {isSelected = false} = selectedList.find(_item => (_item.contractorId === item.contractorId)) || {};

            return {
                ...item,
                key: item.contractorId,
                showCheckBox: true,
                disabledCheckBox: !isSelected && countSelected > 24,
                isSelected,
                avatar: <Avatar
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
                contentRow: <MapContractorListCard
                    contractor={item}
                    isModalCard={true}
                    isInviteModal={true}
                    isShowInviteButton={false}
                />,
            };
        });
    }, [
        list,
        selectedList,
        userAvatarDict,
    ]);

    return (
        list.length ?
            <CheckboxList
                isShowCheckboxLabel={true}
                header={
                    <SelectionCountWithAction
                        adaptiveLogic={true}
                        count={countSelected}
                        maxSelected={25}
                        maxSelectedText="Вы можете пригласить не более 25 исполнителей"
                    />
                }
                rows={rows}
                onSelectedRows={handleSelectedRows}
                maxSelected={25}
            /> :
            <NmEmptyPageV2 fetchProgress={progress} />
    );
};

export default MapInviteModalContractorList;