import React from "react";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../../../components/PhoneWithCodeFilter";

import {dictionaryToOptions} from "../../../../../../utils/objectHelper";

import {
    ACT_REGISTRY_CONTRACTOR_STATUS_DICT,
    ACT_REGISTRY_ITEM_STATUS_DICT,
    ACT_REGISTRY_PAYMENT_STATUS_DICT,
} from "../../../../../../constants/actRegistry";
import {
    REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS,
    REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS,
} from "../../../../../../constants/registry";

const ActRegistryCardFilter = props => {
    const {
        submitFilter,
        clearFilter,
        onChange,
        filter,
    } = props;

    const {
        fioFilter,
        phoneFilter,
        paymentNumberFilter,
        contractFrameStatusFilter,
        paymentAbility,
        orderNumFilter,
        contractorStatusFilter,
        orderWorkReportStatuses,
        actRegistryPaymentStatus,
    } = filter;

    const contractorStatusFilterOptions = dictionaryToOptions(ACT_REGISTRY_CONTRACTOR_STATUS_DICT);
    const itemStatusFilterOptions = dictionaryToOptions(ACT_REGISTRY_ITEM_STATUS_DICT);
    const paymentStatusFilterOptions = dictionaryToOptions(ACT_REGISTRY_PAYMENT_STATUS_DICT);

    return (
        <NmForm addMargin>
            <NmInputV2
                name="fioFilter"
                onChange={onChange}
                value={fioFilter || ""}
                label="ФИО"
                size="lg"
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmInputV2
                name="paymentNumberFilter"
                onChange={onChange}
                value={paymentNumberFilter || ""}
                label="Номер оплаты"
                maskChar={null}
                mask="999999999999"
                size="lg"
            />
            <NmDropdownV2
                name="paymentAbility"
                options={REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS}
                onChange={onChange}
                value={paymentAbility || ""}
                label="Проверено банком"
                size="lg"
            />
            <NmDropdownV2
                name="contractFrameStatusFilter"
                options={REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS}
                onChange={onChange}
                value={contractFrameStatusFilter || ""}
                label="Рамочный договор"
                size="lg"
                multiple
            />
            <NmInputV2
                name="orderNumFilter"
                onChange={onChange}
                value={orderNumFilter || ""}
                mask="999999999999999999"
                maskChar={null}
                label="Номер заказа"
                size="lg"
            />
            <NmDropdownV2
                name="contractorStatusFilter"
                options={contractorStatusFilterOptions}
                onChange={onChange}
                value={contractorStatusFilter || ""}
                label="Статус исполнителя"
                size="lg"
                multiple
                isClearable
            />
            <NmDropdownV2
                name="orderWorkReportStatuses"
                options={paymentStatusFilterOptions}
                onChange={onChange}
                value={orderWorkReportStatuses || ""}
                label="Статус оплаты"
                size="lg"
                multiple
                isClearable
            />
            <NmDropdownV2
                name="actRegistryPaymentStatus"
                options={itemStatusFilterOptions}
                onChange={onChange}
                value={actRegistryPaymentStatus || ""}
                label="Статус строки"
                size="lg"
                multiple
                isClearable
            />
            <FilterButtonsV2
                className="registry-card-filter__buttons"
                onSearch={() => submitFilter(filter)}
                onClear={clearFilter}
            />
        </NmForm>
    );
};

export default ActRegistryCardFilter;