import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {getCategorySpecialitiesInitialValues} from "../utils/getInitialValues";
import {getCategorySpecialitiesEditValidation} from "../utils/getValidation";

import {
    addCategorySpecialities,
    categorySpecialitiesCardSelector,
    categorySpecialitiesProgressCardSelector,
    categorySpecialitiesProgressSelector,
    findByIdCategorySpecialities,
    updateCategorySpecialities,
    updateCategorySpecialitiesStore,
} from "../../../../../../../ducks/categorySpecialities";

export const useCategorySpecialitiesEdit = ({fetchList, editData, onClose}) => {
    const dispatch = useDispatch();

    const initialValues = useSelector(categorySpecialitiesCardSelector);
    const progressCard = useSelector(categorySpecialitiesProgressCardSelector);
    const progressAction = useSelector(categorySpecialitiesProgressSelector);

    useEffect(() => {
        if (editData) {
            const {id} = editData;

            dispatch(findByIdCategorySpecialities({id}));
        }

        return () => {
            dispatch(updateCategorySpecialitiesStore({card: {}}));
        };
    }, []);

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: getCategorySpecialitiesInitialValues(initialValues),
        validationSchema: getCategorySpecialitiesEditValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        if (
            name === "fieldCode" &&
            !/^[0-9a-zA-Z]+$/.test(value) &&
            value !== ""
        ) {
            return;
        }

        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        if (editData) {
            const {id} = editData;

            dispatch(updateCategorySpecialities({
                ...values,
                iconCode: initialValues.iconCode,
                id,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(addCategorySpecialities({
            ...values,
            onSuccess: () => {
                fetchList();
                onClose();
            },
        }));
    }

    return {
        errors,
        handleSubmit,
        values,
        progressCard,
        progressAction,
        onChange,
        touched,
    };
};