import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getContractorScanListFiles} from "../../contractor_scan_list/utils/getFiles";

import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../constants/contractor";
import {
    ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../../../constants/roles";
import {CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS} from "../components/additional-documents-verification/constants";

import {
    contractorAdditionalDocumentsAggregationSelector,
    contractorAdditionalDocumentsProgressRequestSelector,
    contractorIsAdditionalDocumentsAdministratorCheckSelector,
    deleteContractorAdditionalDocument,
    getContractorAdditionalDocumentAggregation,
    requestContractorAdditionalDocument,
} from "../../../../ducks/contractorAdditionalDocuments";
import {
    fileContractorOriginalScanObjSelector,
    fileContractorScanObjSelector,
    getContractorAdditionalDocumentFile,
    getFileContractorScan,
    updateContractorScanFieldFilesStore,
} from "../../../../ducks/fileStore";

export const useContractorAdditionalDocuments = (params) => {
    const {
        contractorId,
        documentType,
        isFetchAggregation = false,
    } = params;

    const isAdditionalDocuments = useSelector(contractorIsAdditionalDocumentsAdministratorCheckSelector);
    const documentsAggregation = useSelector(contractorAdditionalDocumentsAggregationSelector);
    const progressRequest = useSelector(contractorAdditionalDocumentsProgressRequestSelector);
    const contractorScanObj = useSelector(fileContractorScanObjSelector);
    const contractorOriginalScanObj = useSelector(fileContractorOriginalScanObjSelector);

    const role = ls(USER_ROLE);

    const documentInfo = documentsAggregation[documentType] || {};

    const [isShowConfirm, setIsShowConfirm] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isFetchAggregation) {
            getAdditionalDocumentAggregation();
        }
    }, []);

    const getAdditionalDocumentAggregation = () => {
        dispatch(getContractorAdditionalDocumentAggregation({
            contractorId,
            getResult: (result) => {
                fetchScans(result);
            },
        }));
    };

    const getAdditionalOriginalScan = (contractorFileType) => {
        dispatch(getContractorAdditionalDocumentFile({
            contractorId,
            type: contractorFileType,
            thumbnail: false,
            field: "contractorOriginalScanObj",
        }));
    };

    const getPrevScan = (scanType) => {
        return getContractorScanListFiles(contractorOriginalScanObj)[scanType] || [];
    };

    const fetchScans = (aggregationData) => {
        const docInfoList = Object.values(aggregationData);
        const filesInfoList = docInfoList.map(({filesInfo}) => Object.values(filesInfo)).flat();

        filesInfoList.forEach(item => {
            if (!contractorScanObj[item.fileType]) {
                dispatch(getContractorAdditionalDocumentFile({
                    contractorId,
                    type: item.fileType,
                    thumbnail: true,
                }));
            }
        });
    };

    const requestAdditionalDocument = () => {
        dispatch(requestContractorAdditionalDocument({
            contractorId,
            documentType,
            onSuccess: getAdditionalDocumentAggregation,
        }));
    };

    const deleteAdditionalDocument = () => {
        dispatch(deleteContractorAdditionalDocument({
            contractorId,
            documentType,
            onSuccess: () => {
                getAdditionalDocumentAggregation();
                CONTRACTOR_ADDITIONAL_DOCUMENTS_VERIFICATION_SCANS[documentType].scans.forEach(contractorFileType => {
                    dispatch(updateContractorScanFieldFilesStore(contractorFileType, []));
                });
            },
        }));
    };

    const toggleShowDeleteConfirm = () => {
        setIsShowConfirm(!isShowConfirm);
    };

    const renderDeleteConfirm = () => {
        return (
            isShowConfirm &&
            <NmConfirmV2
                content="Вы действительно хотите удалить дополнительный документ у исполнителя? Все данные и сканы документа будут удалены"
                onCancel={toggleShowDeleteConfirm}
                onConfirm={deleteAdditionalDocument}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const requestDocumentButton = {
        onClick: requestAdditionalDocument,
        size: "sm",
        children: "Запросить документ",
        color: "grey",
        disabled: progressRequest || 
            documentInfo.requested ||
            documentInfo.status !== CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT,
        visible: [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role),
    };

    return {
        isAdditionalDocuments,
        requestDocumentButton,
        documentInfo,
        getAdditionalDocumentAggregation,
        renderDeleteConfirm,
        toggleShowDeleteConfirm,
        getAdditionalOriginalScan,
        getPrevScan,
    };
};