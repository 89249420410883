import {all, takeEvery} from "redux-saga/effects";

import {downloadBlob} from "../utils/downloadBlob";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_CIVIL_REQUEST = "PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_CIVIL_REQUEST";

const PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_PATENT_REQUEST = "PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_PATENT_REQUEST";

export const generateInvoiceOfDepositReplenishmentByCivil = (payload) => ({
    type: PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_CIVIL_REQUEST,
    payload,
});

export const generateInvoiceOfDepositReplenishmentByPatent = (payload) => ({
    type: PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_PATENT_REQUEST,
    payload,
});

const isError = (result) => result.status === 200 && result.headers.get("Content-Type").includes("json");

// GET /api/print/generate/invoiceOfDepositReplenishmentByCivil
export const generateInvoiceOfDepositReplenishmentByCivilSaga = function* ({payload}) {
    const {
        onError,
        name,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const settings = {
            method: "post",
            body: JSON.stringify(reqData),
        };

        const result = yield request.getFile(
            `${new URL(window.location.href).origin}/api/print/generate/invoiceOfDepositReplenishmentByCivil`,
            settings,
        );

        if (isError(result)) {
            const data = yield result.json();

            onError();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);
                return {
                    done: true,
                };
            }
        }

        const fileName = `Счет-основание на пополнение депозита для выплат по НДФЛ_${name}_${result.headers.get("Content-Disposition").split("filename=")[1]}`;
        const blob = yield result.blob();

        yield downloadBlob(blob, result.headers, fileName);

        onSuccess();
    } catch (error) {
        onError();
    }
};

// GET /api/print/generate/invoiceOfDepositReplenishmentByPatent
export const generateInvoiceOfDepositReplenishmentByPatentSaga = function* ({payload}) {
    const {
        onError,
        name,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const settings = {
            method: "post",
            body: JSON.stringify(reqData),
        };

        const result = yield request.getFile(
            `${new URL(window.location.href).origin}/api/print/generate/invoiceOfDepositReplenishmentByPatent`,
            settings,
        );

        if (isError(result)) {
            const data = yield result.json();

            onError();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);

                return {
                    done: true,
                };
            }
        }

        const fileName = `Счет-основание для оплаты патентов_${name}_${result.headers.get("Content-Disposition").split("filename=")[1]}`;
        const blob = yield result.blob();

        yield downloadBlob(blob, result.headers, fileName);

        onSuccess();
    } catch (error) {
        onError();
    }
};

export function* saga() {
    yield all([
        takeEvery(PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_CIVIL_REQUEST, generateInvoiceOfDepositReplenishmentByCivilSaga),
        takeEvery(PRINT_GENERATE_INVOICE_OF_DEPOSIT_REPLENISHMENT_BY_PATENT_REQUEST, generateInvoiceOfDepositReplenishmentByPatentSaga),
    ]);
}