import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/contractors/promotion";

const PROMOTION_TYPE = {
    CONTRACTORS: "contractors",
    CLIENTS: "clients",
};
//*  TYPES  *//

const PROMOCODE_CHANNEL_LIST_REQUEST = "PROMOCODE_CHANNEL_LIST_REQUEST";
const PROMOCODE_CHANNEL_LIST_SUCCESS = "PROMOCODE_CHANNEL_LIST_SUCCESS";
const PROMOCODE_CHANNEL_LIST_ERROR = "PROMOCODE_CHANNEL_LIST_ERROR";

const PROMOCODE_LIST_REQUEST = "PROMOCODE_LIST_REQUEST";
const PROMOCODE_LIST_SUCCESS = "PROMOCODE_LIST_SUCCESS";
const PROMOCODE_LIST_ERROR = "PROMOCODE_LIST_ERROR";

const PROMOCODE_CHANNEL_ADD_REQUEST = "PROMOCODE_CHANNEL_ADD_REQUEST";
const PROMOCODE_CHANNEL_ADD_SUCCESS = "PROMOCODE_CHANNEL_ADD_SUCCESS";
const PROMOCODE_CHANNEL_ADD_ERROR = "PROMOCODE_CHANNEL_ADD_ERROR";

const PROMOCODE_CHANNEL_CARD_REQUEST = "PROMOCODE_CHANNEL_CARD_REQUEST";
const PROMOCODE_CHANNEL_CARD_SUCCESS = "PROMOCODE_CHANNEL_CARD_SUCCESS";
const PROMOCODE_CHANNEL_CARD_ERROR = "PROMOCODE_CHANNEL_CARD_ERROR";

const PROMOCODE_CHANNEL_UPDATE_REQUEST = "PROMOCODE_CHANNEL_UPDATE_REQUEST";
const PROMOCODE_CHANNEL_UPDATE_SUCCESS = "PROMOCODE_CHANNEL_UPDATE_SUCCESS";
const PROMOCODE_CHANNEL_UPDATE_ERROR = "PROMOCODE_CHANNEL_UPDATE_ERROR";

const PROMOCODE_CHECK_ACTIVE_REQUEST = "PROMOCODE_CHECK_ACTIVE_REQUEST";
const PROMOCODE_CHECK_ACTIVE_SUCCESS = "PROMOCODE_CHECK_ACTIVE_SUCCESS";
const PROMOCODE_CHECK_ACTIVE_ERROR = "PROMOCODE_CHECK_ACTIVE_ERROR";

const PROMOCODE_CONTRACOR_LIST_REQUEST = " PROMOCODE_CONTRACOR_LIST_REQUEST";
const PROMOCODE_CONTRACOR_LIST_SUCCESS = "PROMOCODE_CONTRACOR_LIST_SUCCESS";
const PROMOCODE_CONTRACOR_LIST_ERROR = "PROMOCODE_CONTRACOR_LIST_ERROR";

const PROMOCODE_CLIENTS_LIST_REQUEST = " PROMOCODE_CLIENTS_LIST_REQUEST";
const PROMOCODE_CLIENTS_LIST_SUCCESS = "PROMOCODE_CLIENTS_LIST_SUCCESS";
const PROMOCODE_CLIENTS_LIST_ERROR = "PROMOCODE_CLIENTS_LIST_ERROR";

const PROMOCODE_UPDATE_REQUEST = "PROMOCODE_UPDATE_REQUEST";
const PROMOCODE_UPDATE_SUCCESS = "PROMOCODE_UPDATE_SUCCESS";
const PROMOCODE_UPDATE_ERROR = "PROMOCODE_UPDATE_ERROR";

const PROMOCODE_CHECK_REQUEST = "PROMOCODE_CHECK_REQUEST";
const PROMOCODE_CHECK_SUCCESS = "PROMOCODE_CHECK_SUCCESS";
const PROMOCODE_CHECK_ERROR = "PROMOCODE_CHECK_ERROR";

const PROMOCODE_REGISTRATION_REQUEST = "PROMOCODE_REGISTRATION_REQUEST";
const PROMOCODE_REGISTRATION_SUCCESS = "PROMOCODE_REGISTRATION_SUCCESS";
const PROMOCODE_REGISTRATION_ERROR = "PROMOCODE_REGISTRATION_ERROR";

const PROMOCODE_BY_CLIENT_ID_REQUEST = "PROMOCODE_BY_CLIENT_ID_REQUEST";
const PROMOCODE_BY_CLIENT_ID_SUCCESS = "PROMOCODE_BY_CLIENT_ID_SUCCESS";
const PROMOCODE_BY_CLIENT_ID_ERROR = "PROMOCODE_BY_CLIENT_ID_ERROR";

const GET_PROMOCODE_FOR_PARTNER_REQUEST = "GET_PROMOCODE_FOR_PARTNER_REQUEST";
const GET_PROMOCODE_FOR_PARTNER_SUCCESS = "GET_PROMOCODE_FOR_PARTNER_SUCCESS";

const GET_HAS_PROMOTION_BY_CLIENT_NAME_REQUEST = "GET_HAS_PROMOTION_BY_CLIENT_NAME_REQUEST";
const GET_HAS_PROMOTION_BY_CLIENT_NAME_SUCCESS = "GET_HAS_PROMOTION_BY_CLIENT_NAME_SUCCESS";
const GET_HAS_PROMOTION_BY_CLIENT_NAME_ERROR = "GET_HAS_PROMOTION_BY_CLIENT_NAME_ERROR";

const UPDATE_FIELD_PROMOCODE_STORE = "UPDATE_FIELD_PROMOCODE_STORE";


//*  INITIAL STATE  *//

const initial = {
    contractorPromotions: {
        totalCount: 0,
        list: [],
        pageData: {},
    },
    existingPromocodeChannels: {
        totalCount: 0,
    },
    channelCard: {},
    contractorPageData: {},
    clientPageData: {},
    channelTotalCount: 0,
    promocodeList: [],
    promocodePageData: {},
    promocodeTotalCount: 0,
    isExistActive: null,
    contractorList: [],
    contractorTotalCount: 0,
    checkProgress: false,
    clientPromocode: undefined,
    isCheckSuccess: false,
    clientList: [],
    clientTotalCount: 0,
    progressCard: false,
    progress: false,
    progressAdd: false,
    promotionsForPartner: {
        [PROMOTION_TYPE.CONTRACTORS]: [],
        [PROMOTION_TYPE.CLIENTS]: [],
    },
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case PROMOCODE_CHANNEL_LIST_REQUEST: {
        const {
            nameField = "contractorPromotions",
            ...pageData
        } = payload;

        return {
            ...state,
            [nameField]: {
                ...state[nameField],
                pageData,
            },
            progress: true,
        };
    }
    case PROMOCODE_LIST_REQUEST:
        return {
            ...state,
            promocodePageData: payload,
            progress: true,
        };
    case PROMOCODE_CONTRACOR_LIST_REQUEST:
        return {
            ...state,
            contractorPageData: payload,
            progress: true,
        };
    case PROMOCODE_CLIENTS_LIST_REQUEST:
        return {
            ...state,
            clientPageData: payload,
            progress: true,
        };
    case PROMOCODE_CHANNEL_ADD_REQUEST:
    case PROMOCODE_CHANNEL_UPDATE_REQUEST:
        return {
            ...state,
            progressAdd: true,
        };
    case GET_PROMOCODE_FOR_PARTNER_SUCCESS:
        const {type, list} = payload;
        return {
            ...state,
            promotionsForPartner: {
                ...state.promotionsForPartner,
                [PROMOTION_TYPE[type]]: list,
            },
        };
    case PROMOCODE_CHECK_REQUEST: {
        return {
            ...state,
            checkProgress: true,
        };
    }
    case PROMOCODE_CHECK_ACTIVE_SUCCESS:
        return {
            ...state,
            isExistActive: payload,
        };

    case PROMOCODE_CONTRACOR_LIST_SUCCESS:
        const {
            contractorModels: contractorList,
            totalCount: contractorTotalCount,
        } = payload;

        return {
            ...state,
            contractorList,
            contractorTotalCount,
            progress: false,
        };
    case PROMOCODE_CLIENTS_LIST_SUCCESS:
        const {
            clientModels: clientList,
            totalCount: clientTotalCount,
        } = payload;

        return {
            ...state,
            clientList,
            clientTotalCount,
            progress: false,
        };
    case PROMOCODE_BY_CLIENT_ID_SUCCESS:
        return {
            ...state,
            clientPromocode: payload,
        };
    case PROMOCODE_CHECK_SUCCESS: {
        return {
            ...state,
            checkProgress: false,
            errorMessageCheck: payload.errorMessage,
            isCheckSuccess: payload.success,
        };
    }
    case PROMOCODE_CHANNEL_LIST_SUCCESS:
        const {
            nameField = "contractorPromotions",
            contractorPromotions,
            totalCount,
        } = payload;

        return {
            ...state,
            [nameField]: {
                ...state[nameField],
                list: contractorPromotions,
                totalCount,
            },
            progress: false,
        };
    case UPDATE_FIELD_PROMOCODE_STORE:
        return {
            ...state,
            ...payload,
        };
    case PROMOCODE_LIST_SUCCESS:
        const {
            contractorPromotionPromoCodes: promocodeList,
            totalCount: promocodeTotalCount,
        } = payload;

        return {
            ...state,
            promocodeList,
            promocodeTotalCount,
            progress: false,
        };
    case PROMOCODE_CHANNEL_CARD_REQUEST: {
        return {
            ...state,
            progressCard: true,
        };
    }
    case PROMOCODE_CHANNEL_CARD_ERROR:
        return {
            ...state,
            progressCard: false,
        };
    case PROMOCODE_CHANNEL_CARD_SUCCESS:
        return {
            ...state,
            channelCard: payload,
            progressCard: false,
        };
    case PROMOCODE_CHANNEL_ADD_SUCCESS:
    case PROMOCODE_CHANNEL_UPDATE_SUCCESS:
        return {
            ...state,
            progressAdd: false,
        };
    case PROMOCODE_CHANNEL_LIST_ERROR:
    case PROMOCODE_CONTRACOR_LIST_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    case PROMOCODE_CHANNEL_ADD_ERROR:
    case PROMOCODE_CHANNEL_UPDATE_ERROR:
        return {
            ...state,
            progressAdd: false,
            error: payload,
        };
    case PROMOCODE_CHECK_ACTIVE_ERROR:
        return {
            ...state,
            error: payload,
            isExistActive: null,
        };
    case PROMOCODE_CHECK_ERROR:
        return {
            ...state,
            checkProgress: false,
            errorMessageCheck: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getPromocodeList(payload) {
    return {
        type: PROMOCODE_LIST_REQUEST,
        payload,
    };
}

export function getHasPromotionByClientName(payload) {
    return {
        type: GET_HAS_PROMOTION_BY_CLIENT_NAME_REQUEST,
        payload,
    };
}

export function getPromocodeChannelList(payload) {
    return {
        type: PROMOCODE_CHANNEL_LIST_REQUEST,
        payload,
    };
}

export function addPromocodeChannel(payload) {
    return {
        type: PROMOCODE_CHANNEL_ADD_REQUEST,
        payload,
    };
}

export function getPromocodeChannelByPromotionId(payload) {
    return {
        type: PROMOCODE_CHANNEL_CARD_REQUEST,
        payload,
    };
}

export function updatePromocodeChannel(payload) {
    return {
        type: PROMOCODE_CHANNEL_UPDATE_REQUEST,
        payload,
    };
}

export function updateFieldPromocodeStore(payload) {
    return {
        type: UPDATE_FIELD_PROMOCODE_STORE,
        payload,
    };
}

export function getCheckNoActivePromocode(payload) {
    return {
        type: PROMOCODE_CHECK_ACTIVE_REQUEST,
        payload,
    };
}

export function getClientsByPromocode(payload) {
    return {
        type: PROMOCODE_CLIENTS_LIST_REQUEST,
        payload,
    };
}

export function getContractorsByPromocode(payload) {
    return {
        type: PROMOCODE_CONTRACOR_LIST_REQUEST,
        payload,
    };
}

export function updatePromocode(payload) {
    return {
        type: PROMOCODE_UPDATE_REQUEST,
        payload,
    };
}

export function checkPromocode(payload) {
    return {
        type: PROMOCODE_CHECK_REQUEST,
        payload,
    };
}

export function registrationPromocode(payload) {
    return {
        type: PROMOCODE_REGISTRATION_REQUEST,
        payload,
    };
}

export function getPromocodeByClientId(payload) {
    return {
        type: PROMOCODE_BY_CLIENT_ID_REQUEST,
        payload,
    };
}

export function getPromotionForPartner(payload) {
    return {
        type: GET_PROMOCODE_FOR_PARTNER_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const promocodeSelector = state => state.promocode;

export const promocodeGetPageProgressSelector = createSelector(promocodeSelector, ({progress}) => progress);
export const promocodeCardProgressSelector = createSelector(promocodeSelector, ({progressCard}) => progressCard);
export const promocodeAddProgressSelector = createSelector(promocodeSelector, ({progressAdd}) => progressAdd);
export const promocodeChannelPageDataSelector = createSelector(promocodeSelector, ({contractorPromotions: {pageData}}) => pageData);
export const promocodePageDataSelector = createSelector(promocodeSelector, ({promocodePageData}) => promocodePageData);
export const promocodeChannelTotalPagesSelector = createSelector(promocodeSelector, ({contractorPromotions: {pageData: {pageSize}, totalCount}}) => getTotalPages(totalCount, pageSize));
export const promocodeChannelTotalCountSelector = createSelector(promocodeSelector, ({contractorPromotions: {totalCount}}) => totalCount);
export const promocodeChannelListSelector = createSelector(promocodeSelector, ({contractorPromotions: {list}}) => list);
export const promocodeIsExistChannelSelector = createSelector(promocodeSelector, ({existingPromocodeChannels: {totalCount}}) => totalCount > 0);
export const getPromotionChannelCardSelector = createSelector(promocodeSelector, ({channelCard}) => channelCard);
export const getPromotionListSelector = createSelector(promocodeSelector, ({promocodeList}) => promocodeList);
export const getPromocodeContractorListSelector = createSelector(promocodeSelector, ({contractorList}) => contractorList);
export const promocodeContractorTotalPagesSelector = createSelector(promocodeSelector, ({contractorPageData: {pageSize}, contractorTotalCount}) => getTotalPages(contractorTotalCount, pageSize));
export const promocodeContractorTotalCountSelector = createSelector(promocodeSelector, ({contractorTotalCount}) => contractorTotalCount);
export const promocodeClientTotalPagesSelector = createSelector(promocodeSelector, ({clientPageData: {pageSize}, clientTotalCount}) => getTotalPages(clientTotalCount, pageSize));
export const promocodeClientTotalCountSelector = createSelector(promocodeSelector, ({clientTotalCount}) => clientTotalCount);
export const getPromocodeisExistActiveSelector = createSelector(promocodeSelector, ({isExistActive}) => isExistActive);
export const getPromotionTotalPagesSelector = createSelector(promocodeSelector, ({promocodePageData: {pageSize}, promocodeTotalCount}) => getTotalPages(promocodeTotalCount, pageSize));
export const getPromotionTotalCountSelector = createSelector(promocodeSelector, ({promocodeTotalCount}) => promocodeTotalCount);
export const getResponseFromCheckSelector = createSelector(promocodeSelector, ({checkProgress, errorMessageCheck, isCheckSuccess}) => ({
    checkProgress,
    errorMessageCheck,
    isCheckSuccess,
}));
export const getClientPromocodeSelector = createSelector(promocodeSelector, ({clientPromocode}) => clientPromocode);
export const getClientListByPromocodeSelector = createSelector(promocodeSelector, ({clientList}) => clientList);
export const getClientListByPromocodeOptionSelector = createSelector(promocodeSelector, ({clientList}) => clientList.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));
export const promocodePartnerClientsOptionsSelector = createSelector(promocodeSelector, ({promotionsForPartner}) => promotionsForPartner[PROMOTION_TYPE.CLIENTS].map(({promotionId: value, name: text, requisites}) => ({
    key: value, value, text: `${text} (${requisites})`,
})));
export const promocodePartnerContractorsOptionsSelector = createSelector(promocodeSelector, ({promotionsForPartner}) => promotionsForPartner[PROMOTION_TYPE.CONTRACTORS].map(({promotionId: value, name: text, requisites}) => ({
    key: value, value, text: `${text} (${requisites})`,
})));

//*  SAGA  *//


//POST /api/contractors/promotion/getPage
export const getPromocodeChannelListSaga = function* ({payload}) {
    try {
        const {
            nameField,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CHANNEL_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_CHANNEL_LIST_SUCCESS,
            payload: {
                nameField,
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: PROMOCODE_CHANNEL_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/promotion/getPagePromocode
export const getPromocodeListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPagePromocode`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PROMOCODE_LIST_ERROR,
            payload: error,
        });
    }
};

export const addPromocodeChannelSaga = function* ({payload}) {
    try {
        const {
            requestData,
            onSuccess = () => {
            },
            onError,
        } = payload;

        const result = yield request.post(`${controller}/add`, requestData);

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);
            yield put({type: PROMOCODE_CHANNEL_ADD_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Канал привлечения успешно добавлен");
        onSuccess();

        yield put({type: PROMOCODE_CHANNEL_ADD_SUCCESS, payload: result});

        const state = yield select();
        yield put(getPromocodeChannelList(promocodeChannelPageDataSelector(state)));
    } catch (error) {
        toastError(error.message);
        yield put({type: PROMOCODE_CHANNEL_ADD_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getById
export const getPromocodeChannelByPromotionIdSaga = function* (action) {
    try {
        const {
            payload: params,
        } = action;

        const result = yield request.get(`${controller}/getById`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CHANNEL_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_CHANNEL_CARD_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CHANNEL_CARD_ERROR, payload: error});
    }
};

//POST /api/contractors/promotion/update
export const updatePromocodeChannelSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                isFetchCard,
                isFetchList,
                isFetchPromocodeList,
                onSuccess = () => {
                },
            },
        } = action;

        const result = yield request.post(`${controller}/update`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CHANNEL_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Канал успешно обновлен");
        onSuccess();

        const state = yield select();

        if (isFetchList) {
            yield put(getPromocodeChannelList(promocodeChannelPageDataSelector(state)));
        }

        if (isFetchCard) {
            yield put(getPromocodeChannelByPromotionId({
                promotionId: data.promotionId,
            }));
        }

        if (isFetchPromocodeList) {
            yield put(getPromocodeList(promocodePageDataSelector(state)));
        }

        yield put({
            type: PROMOCODE_CHANNEL_UPDATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: PROMOCODE_CHANNEL_UPDATE_ERROR,
            payload: error,
        });
    }
};


export const getCheckNoActivePromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                promotionId,
            },
        } = action;

        const result = yield request.get(`${controller}/getCheckNoActivePromocode`, {
            params: {
                promotionId,
            },
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CHECK_ACTIVE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        //
        // if (result === false) {
        //     yield put(downloadDocument({
        //         downloadLink: `/api/documents/getPromotionPromocodesFile?promotionId=${promotionId}&active=${result}`,
        //         documentType: name,
        //         extension: "xlsx"
        //     }));
        // }

        yield put({
            type: PROMOCODE_CHECK_ACTIVE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CHECK_ACTIVE_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getContractorsPage
export const getContractorsByPromocodeSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getContractorsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CONTRACOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_CONTRACOR_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CONTRACOR_LIST_ERROR, payload: error});
    }
};

//POST /api/contractors/promotion/updatePromocode
export const updatePromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                isFetchPromocodeList,
            },
        } = action;

        const result = yield request.post(`${controller}/updatePromocode`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }


        if (isFetchPromocodeList) {
            const state = yield select();
            yield put(getPromocodeList(promocodePageDataSelector(state)));
        }

        toastSuccess("Промокод успешно обновлен");

        yield put({
            type: PROMOCODE_UPDATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_UPDATE_ERROR, payload: error});
    }
};

//POST /api/contractors/promotion/check
export const checkPromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                data,
            },
        } = action;

        const result = yield request.post(`${controller}/check`, data);

        if (result === null) {
            yield put({type: PROMOCODE_CHECK_ERROR, payload: "Не удалось проверить промокод"});
            return {
                done: true,
            };
        }

        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            const error = errorCode === "INVALID_PROMOCODE" ? "Введен неверный промо-код" : errorMessage;
            yield put({type: PROMOCODE_CHECK_ERROR, payload: error});
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_CHECK_SUCCESS,
            payload: result,
        });

    } catch (error) {
        console.log("error", error.message);

        yield put({type: PROMOCODE_CHECK_ERROR, payload: error});
    }
};

//POST /api/contractors/promotion/registration
export const registrationPromocodeSaga = function* (action) {
    try {
        const {
            payload: {
                isFetchClientPromocode,
                data,
                onSuccess,
                onError = () => {
                },
            },
        } = action;

        const result = yield request.post(`${controller}/registration`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            // toastError(errorMessage);
            onError(errorMessage);
            yield put({type: PROMOCODE_REGISTRATION_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Промо-код успешно применен");

        yield put({
            type: PROMOCODE_REGISTRATION_SUCCESS,
            payload: result,
        });

        if (isFetchClientPromocode) {
            yield put(getPromocodeByClientId({
                data: {
                    clientId: data.clientId,
                },
            }));
        }

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        yield put({type: PROMOCODE_REGISTRATION_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getPromocodeByClientId

export const getPromocodeByClientIdSaga = function* (action) {
    try {
        const {
            payload: {
                data: params,
            },
        } = action;

        const result = yield request.get(`${controller}/getPromocodeByClientId`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_BY_CLIENT_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PROMOCODE_BY_CLIENT_ID_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_BY_CLIENT_ID_ERROR, payload: error});
    }
};

//GET /api/contractors/promotion/getClientsPage
export const getClientsByPromocodeSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getClientsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CLIENTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({
            type: PROMOCODE_CLIENTS_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: PROMOCODE_CLIENTS_LIST_ERROR, payload: error});
    }
};
//GET /api/contractors/promotion/getPromotionForPartner
export const getPromotionForPartnerSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            getResult,
            type,
        } = payload;

        const result = yield request.get(`${controller}/getPromotionForPartner`, {params: {type}});
        const {errorMessage} = result;

        getResult && getResult(result);


        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PROMOCODE_CLIENTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({
            type: GET_PROMOCODE_FOR_PARTNER_SUCCESS,
            payload: {
                type,
                list: result,
            },
        });

    } catch (error) {
        yield put({type: PROMOCODE_CLIENTS_LIST_ERROR, payload: error});
    }
};

//promotion/hasPromotionByClientName

export const getHasPromotionByClientNameSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            getResult,
            clientName,
        } = payload;

        const result = yield request.get(`${controller}/hasPromotionByClientName`, {params: {clientName}});
        const {errorMessage} = result;

        getResult && getResult(result);


        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_HAS_PROMOTION_BY_CLIENT_NAME_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({
            type: GET_HAS_PROMOTION_BY_CLIENT_NAME_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: GET_HAS_PROMOTION_BY_CLIENT_NAME_ERROR, payload: error});
    }
};


export function* saga() {
    yield all([
        takeEvery(PROMOCODE_CHANNEL_LIST_REQUEST, getPromocodeChannelListSaga),
        takeEvery(PROMOCODE_LIST_REQUEST, getPromocodeListSaga),
        takeEvery(PROMOCODE_CHANNEL_ADD_REQUEST, addPromocodeChannelSaga),
        takeEvery(PROMOCODE_CHANNEL_CARD_REQUEST, getPromocodeChannelByPromotionIdSaga),
        takeEvery(PROMOCODE_CHANNEL_UPDATE_REQUEST, updatePromocodeChannelSaga),
        takeEvery(PROMOCODE_CHECK_ACTIVE_REQUEST, getCheckNoActivePromocodeSaga),
        takeEvery(PROMOCODE_CONTRACOR_LIST_REQUEST, getContractorsByPromocodeSaga),
        takeEvery(PROMOCODE_UPDATE_REQUEST, updatePromocodeSaga),
        takeEvery(PROMOCODE_CHECK_REQUEST, checkPromocodeSaga),
        takeEvery(PROMOCODE_REGISTRATION_REQUEST, registrationPromocodeSaga),
        takeEvery(PROMOCODE_BY_CLIENT_ID_REQUEST, getPromocodeByClientIdSaga),
        takeEvery(PROMOCODE_CLIENTS_LIST_REQUEST, getClientsByPromocodeSaga),
        takeEvery(GET_PROMOCODE_FOR_PARTNER_REQUEST, getPromotionForPartnerSaga),
        takeEvery(GET_HAS_PROMOTION_BY_CLIENT_NAME_REQUEST, getHasPromotionByClientNameSaga),
    ]);
}
