import React from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDatePicker from "../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmButton from "../../../../components/NmButton";
import NmTitle from "../../../../components/NmTitle";

import {useFrameContractExternalEditFormData} from "./hooks/useData";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE,
    FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE_OPTIONS,
} from "../../../../constants/frameContractExternal";
import {PHONE_MASK} from "../../../../constants/phone";

import {clientObjectOptionsSelector} from "../../../../ducks/clientObject";
import {
    companyDocumentsRegistrySelectorProgressSelector,
    companyDocumentsRegistrySelectorSearchProgressSelector,
} from "../../../../ducks/companyDocumentsRegistry";

const FrameContractAddExternal = (props) => {
    const {
        clientId,
        fetchList = () => {
        },
        onClose = () => {
        },
    } = props;

    const objectOptions = useSelector(clientObjectOptionsSelector);
    const progress = useSelector(companyDocumentsRegistrySelectorProgressSelector);
    const searchProgress = useSelector(companyDocumentsRegistrySelectorSearchProgressSelector);

    const [block, element] = bem("frame-contract-external-edit-form");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        onSearchChangeObject,
        searchContractor,
        searchBlockInfo,
        validateField,
        setFieldTouched,
    } = useFrameContractExternalEditFormData({
        clientId,
        fetchList,
        onClose,
    });

    return (
        <NmModal
            className={block()}
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Добавление внешнего РД с исполнителем
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progress || !searchBlockInfo.isSearch}
                    submitBtnContent="Добавить"
                    cancelBtnContent="Отменить"
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                <Text level="3">
                    На данной форме вы можете добавить реквизиты внешнего рамочного договора, который заключен с
                    исполнителем вне площадки Наймикс. При добавлении внешнего договора повторное подписание договора с
                    исполнителем на площадке не требуется, а выплаты будут проводиться по этому договору.
                </Text>
                <Text.Sub
                    level="1"
                    medium
                >
                    Тип РД
                </Text.Sub>
                <NmHorizontalToggleV2
                    noWrapLabel
                    rightLabel="Договор НДФЛ"
                    leftLabel="Договор НПД"
                    onChange={(e, {name, checked}) => handleChange(e, {
                        name,
                        value: checked ? ORDER_WORK_REPORT_TYPE.CIVIL : ORDER_WORK_REPORT_TYPE.SMZ,
                    })}
                    name="contractType"
                    checked={values.contractType === ORDER_WORK_REPORT_TYPE.CIVIL}
                    duplex={false}
                />
                <NmTextareaV2
                    size="xl"
                    required
                    value={values.contractNumber}
                    name="contractNumber"
                    label="Номер договора"
                    placeholder="Введите номер"
                    maxLength={100}
                    onChange={handleChange}
                    error={touched?.contractNumber && errors?.contractNumber}
                />
                <NmDatePicker
                    size="xl"
                    required
                    label="Дата подписания договора"
                    placeholderText="дд.мм.гг"
                    dateFormat="dd.MM.yy"
                    selected={values.contractSignDate}
                    error={touched?.contractSignDate && errors?.contractSignDate}
                    name="contractSignDate"
                    onChange={handleChange}
                />
                <NmTitle size="md">
                    Исполнитель с которым подписан РД
                </NmTitle>
                <div className="row gx-4 align-items-start">
                    <div className="col-md-7">
                        <NmDropdownV2
                            size="xl"
                            required
                            label="Фильтр поиска"
                            name="searchType"
                            onChange={handleChange}
                            value={values.searchType}
                            options={FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE_OPTIONS}
                            error={touched?.searchType && errors?.searchType}
                        />
                    </div>
                    <div className="col-md-6 mt-4 mt-md-0">
                        {
                            values.searchType === FRAME_CONTRACT_CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ?
                                <NmInputV2
                                    required
                                    size="xl"
                                    mask={PHONE_MASK.FOREIGN}
                                    onChange={handleChange}
                                    maskChar={null}
                                    name="phone"
                                    placeholder="Введите номер телефона"
                                    label="Номер телефона"
                                    value={values.phone}
                                    onBlur={() => {
                                        validateField("phone");
                                        setFieldTouched("phone");
                                    }}
                                    error={touched?.phone && errors?.phone}
                                    errorClassName={element("search-field-error")}
                                /> :
                                <NmInputV2
                                    required
                                    size="xl"
                                    label="ИНН"
                                    placeholder="Введите ИНН"
                                    maskChar={null}
                                    mask="999999999999"
                                    onChange={handleChange}
                                    name="inn"
                                    value={values.inn}
                                    onBlur={() => {
                                        validateField("inn");
                                        setFieldTouched("inn");
                                    }}
                                    error={touched?.inn && errors?.inn}
                                    errorClassName={element("search-field-error")}
                                />
                        }
                    </div>
                    <div className="col-16 col-md-3 mt-4 mt-md-5">
                        <NmButton
                            className="col-16"
                            size="xl"
                            color="light-green"
                            disabled={searchProgress}
                            loading={searchProgress}
                            onClick={searchContractor}
                        >
                            Поиск
                        </NmButton>
                    </div>
                </div>
                {
                    searchBlockInfo.isSearch &&
                    <div className="flex flex-wrap">
                        <Text
                            level="3"
                            className="me-1"
                            color={values.contractorId ? COLOR.PRIMARY_100 : COLOR.NEGATIVE_100}
                        >
                            {
                                values.contractorId ?
                                    "Исполнитель найден на площадке:" :
                                    searchBlockInfo.error
                            }
                        </Text>
                        {
                            searchBlockInfo.fio &&
                            <Text level="3">
                                {searchBlockInfo.fio}
                            </Text>
                        }
                    </div>
                }
                <NmTitle size="md">
                    Уровень подписания РД
                </NmTitle>
                <NmCheckboxV2
                    label="РД заключен по объекту"
                    checked={values.useObjectId}
                    name="useObjectId"
                    onChange={handleChange}
                />
                <NmDropdownV2
                    disabled={!values.useObjectId}
                    size="xl"
                    required={values.useObjectId}
                    search
                    placeholder="Выберите объект"
                    label="Объект"
                    name="objectId"
                    onChange={handleChange}
                    onSearchChange={onSearchChangeObject}
                    searchQuery={values.valueObjectFilter}
                    value={values.objectId}
                    options={objectOptions}
                    error={touched?.objectId && errors?.objectId}
                />
                <NmCheckboxV2
                    disabled={values.signingOnlyWithSmzTaxOffer}
                    label="Разрешить подписывать доп. соглашение к внешнему договору до проверки персональных данных Администрацией Наймикс"
                    checked={values.signingBeforeCheckPersonalData}
                    name="signingBeforeCheckPersonalData"
                    onChange={handleChange}
                />
                {
                    values.contractType === ORDER_WORK_REPORT_TYPE.SMZ &&
                    <>
                        <NmCheckboxV2
                            disabled={values.signingOnlyWithSmzTaxOffer}
                            label="Разрешить подписывать доп. соглашение к внешнему договору при отсутствии статуса НПД и партнерства с Наймикс"
                            checked={values.signingWithoutTaxpayerAndPartner}
                            name="signingWithoutTaxpayerAndPartner"
                            onChange={handleChange}
                            isVisibleTooltip={true}
                            tooltip={
                                <HelpTooltip
                                    info
                                    hover
                                    text="Исполнитель сможет подписать доп. соглашение к внешнему договору при отсутствии статуса НПД и подключенного партнерства с Наймикс"
                                    position="bottom-right"
                                />
                            }
                        />
                        <NmCheckboxV2
                            disabled={values.signingBeforeCheckPersonalData || values.signingWithoutTaxpayerAndPartner}
                            label="Разрешить подписывать доп. соглашение к внешнему договору при наличии подключенной автоуплаты налога"
                            checked={values.signingOnlyWithSmzTaxOffer}
                            name="signingOnlyWithSmzTaxOffer"
                            onChange={handleChange}
                            isVisibleTooltip={true}
                            tooltip={
                                <HelpTooltip
                                    info
                                    hover
                                    text="Исполнитель сможет подписать доп. соглашение к внешнему договору только при наличии подключенной автоматической уплаты налога"
                                    position="bottom-right"
                                />
                            }
                        />
                    </>
                }
            </NmForm>
        </NmModal>
    );
};

export default FrameContractAddExternal;