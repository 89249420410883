import React, {useMemo} from "react";

import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {MediaButtons} from "../../../ActualComponents/MediaControls";

import {COMPONENT} from "../../../ActualComponents/MediaControls/constants";

export const BaseDirectoryControls = (props) => {
    const {
        onClickEdit,
        onClickDelete,
        isVisibleControls,
    } = props;

    const mediaControls = useMemo(() => {
        if (!isVisibleControls) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: onClickEdit,
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: onClickDelete,
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                    visible: Boolean(onClickDelete),
                },
            ],
        };
    }, [
        onClickEdit,
        onClickDelete,
    ]);

    if (!mediaControls) {
        return null;
    }

    return (
        <MediaButtons
            inline={true}
            config={mediaControls}
        />
    );
};