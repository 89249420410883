import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import Avatar from "../../../../../../components/Avatar";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmPage from "../../../../../../components/NmPage";
import MapContractorListCard from "../../../contractor-list-card";

import {usePagination} from "../../../../../../hooks/usePagination";
import {useContractorsMapFetchList} from "./hooks/useFetchList";

import {
    bffMapContractorsSelector,
    bffMapContractorsTotalCountSelector,
    bffMapContractorsTotalPagesSelector,
    bffMapProgressListSelector,
} from "../../../../../../ducks/bff/map/selectors";
import {avatarBase64ImagesListSelector} from "../../../../../../ducks/fileStore";

function MapContractorsList(props) {
    const {
        filter,
        isSearch,
        onOpenInviteModal,
    } = props;

    const list = useSelector(bffMapContractorsSelector);
    const totalCount = useSelector(bffMapContractorsTotalCountSelector);
    const totalPages = useSelector(bffMapContractorsTotalPagesSelector);
    const progress = useSelector(bffMapProgressListSelector);

    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    useContractorsMapFetchList({
        pageSize,
        pageNum,
        filter,
    });

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.contractorId,
                avatar: <Avatar
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
                zIndexAvatar: 1,
                contentRow: <MapContractorListCard
                    contractor={item}
                    isShowInviteButton={true}
                    onOpenInviteModal={onOpenInviteModal}
                />,
            };
        });
    };

    return (
        <NmPage
            noPadding
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {
                list.length ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
}

export default MapContractorsList;