import React from "react";
import {useTranslation} from "react-i18next";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import AmountInput from "../../../components/AmountInput";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import useDepositOfFundForm from "./hooks/useDepositOfFundForm";

import {
    BANK_TYPE,
    BANK_TYPE_CARD_OPTIONS,
} from "../../../constants/clientSettings";

import {depositFundsDependedType} from "../../../types";
import PropTypes from "prop-types";

const DepositOfFund = (props) => {
    const {
        close,
        dependentData,
        isEdit,
        onAddDeposit,
        onEditDeposit,
        isLoading,
        isCivil,
    } = props;

    const key = isEdit ? "deposit-funds-edit-title" : "deposit-funds-title";
    const bankOptions = isCivil ?
        BANK_TYPE_CARD_OPTIONS.filter(({value}) => value !== BANK_TYPE.TKB.VALUE) :
        BANK_TYPE_CARD_OPTIONS;

    const {
        t,
    } = useTranslation();

    const {
        isOpenConfirm,
        values,
        errors,
        handleSubmit,
        handleOnChange,
        closeConfirm,
        onSave,
    } = useDepositOfFundForm(
        isEdit,
        dependentData,
        close,
        onEditDeposit,
        onAddDeposit,
    );

    return (
        <NmModal
            size="md"
            onClose={close}
            zIndex={1000}
            header={
                <NmTitle size="lg">
                    {t(`deposit-modal.${key}`, {
                        clientName: dependentData.clientName,
                    })}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent={isEdit ? t("button.save") : t("button.add")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={close}
                    submit={handleSubmit}
                    disabled={isLoading}
                />
            }
            className="deposit-of-funds"
        >
            {isOpenConfirm &&
                <NmConfirmV2
                    size="sm"
                    content={`${t("deposit-modal.deposit-funds-title")}?`}
                    onCancel={closeConfirm}
                    onConfirm={onSave}
                    confirmButton={t("button.yes")}
                    cancelButton={t("button.no")}
                />
            }
            <NmForm addMargin>
                <AmountInput
                    required
                    newInput
                    size="xl"
                    name="amount"
                    label={`${t("deposit-modal.sum")}, ₽`}
                    error={errors.amount}
                    placeholder={t("deposit-modal.sum")}
                    value={values.amount}
                    onChange={handleOnChange}
                />
                <NmDropdownV2
                    required
                    disabled={isEdit}
                    label="Банк"
                    placeholder="Выберите банк"
                    name="bankType"
                    options={bankOptions}
                    value={values.bankType}
                    onChange={handleOnChange}
                    error={errors.bankType}
                />
            </NmForm>
        </NmModal>
    );
};

DepositOfFund.propTypes = {
    close: PropTypes.func,
    dependentData: depositFundsDependedType,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
};

DepositOfFund.defaultProps = {
    close: () => {
    },
    dependentData: PropTypes.object,
    isEdit: false,
    isLoading: false,
};

export default DepositOfFund;