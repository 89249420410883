import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/foreman/foremancontractors";
//*  TYPES  *//

const GET_PAGE_BRIGADES_REQUEST = "GET_PAGE_BRIGADES_REQUEST";
const GET_PAGE_BRIGADES_SUCCESS = "GET_PAGE_BRIGADES_SUCCESS";
const GET_PAGE_BRIGADES_ERROR = "GET_PAGE_BRIGADES_ERROR";

const ADD_FOREMAN_REQUEST = "ADD_FOREMAN_REQUEST";
const ADD_FOREMAN_SUCCESS = "ADD_FOREMAN_SUCCESS";
const ADD_FOREMAN_ERROR = "ADD_FOREMAN_ERROR";

const REJECT_FOREMAN_REQUEST = "REJECT_FOREMAN_REQUEST";
const REJECT_FOREMAN_SUCCESS = "REJECT_FOREMAN_SUCCESS";
const REJECT_FOREMAN_ERROR = "REJECT_FOREMAN_ERROR";

const REJECT_ALL_FOREMAN_REQUEST = "REJECT_ALL_FOREMAN_REQUEST";
const REJECT_ALL_FOREMAN_SUCCESS = "REJECT_ALL_FOREMAN_SUCCESS";
const REJECT_ALL_FOREMAN_ERROR = "REJECT_ALL_FOREMAN_ERROR";

//*  INITIAL STATE  *//

const initial = {
    currentRole: "",
    foremanContractors: [],
    totalCount: 0,
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_PAGE_BRIGADES_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: {...payload},
        };
    case GET_PAGE_BRIGADES_SUCCESS:
        const {
            foremanContractors,
            totalCount,
        } = payload;

        return {
            ...state,
            progress: false,
            foremanContractors,
            totalCount,
        };
    case GET_PAGE_BRIGADES_ERROR:
        return {
            ...state,
            progress: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getPageBrigades(payload) {
    return {
        type: GET_PAGE_BRIGADES_REQUEST,
        payload,
    };
}

export function addForeman(payload) {
    return {
        type: ADD_FOREMAN_REQUEST,
        payload,
    };
}

export function rejectForeman(payload) {
    return {
        type: REJECT_FOREMAN_REQUEST,
        payload,
    };
}

export function rejectAllForeman(payload) {
    return {
        type: REJECT_ALL_FOREMAN_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const foremanContractorsSelector = ({foremanContractors}) => foremanContractors;
export const foremanContractorsPageSelector = createSelector(foremanContractorsSelector, ({foremanContractors}) => foremanContractors);
export const foremanContractorsTotalPagesSelector = createSelector(foremanContractorsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const foremanContractorsTotalCountSelector = createSelector(foremanContractorsSelector, ({totalCount}) => totalCount);
export const foremanContractorsProgressSelector = createSelector(foremanContractorsSelector, ({progress}) => progress);

//*  SAGA  *//

///api/foreman/foremancontractors/getPage
export const getPageBrigadesSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_BRIGADES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_BRIGADES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_BRIGADES_ERROR,
            payload: error,
        });
    }
};

///api/foreman/foremancontractors/add
export const addForemanSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                handleResponse,
                onError = () => {},
            },
        } = action;
        const result = yield request.post(`${controller}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ADD_FOREMAN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);
        yield put({
            type: ADD_FOREMAN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_FOREMAN_ERROR,
            payload: error,
        });
    }
};

//POST /api/foreman/foremancontractors/delete
export const rejectForemanSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                handleResponse,
            },
        } = action;

        const result = yield request.post(`${controller}/delete`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REJECT_FOREMAN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);
        yield put({
            type: REJECT_FOREMAN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REJECT_FOREMAN_ERROR,
            payload: error,
        });
    }
};

//POST /api/foreman/foremancontractors/deleteAll
export const rejectAllForemanSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/deleteAll`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REJECT_ALL_FOREMAN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: REJECT_ALL_FOREMAN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REJECT_ALL_FOREMAN_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_BRIGADES_REQUEST, getPageBrigadesSaga),
        takeEvery(ADD_FOREMAN_REQUEST, addForemanSaga),
        takeEvery(REJECT_FOREMAN_REQUEST, rejectForemanSaga),
        takeEvery(REJECT_ALL_FOREMAN_REQUEST, rejectAllForemanSaga),
    ]);
}
