import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmLoader from "../../../../components/NmLoader";
import RecruitmentCandidateResponseDataTransfer from "../../../../components/Recruitment/CandidateResponseDataTransfer";
import RecruitmentResponseSource from "../../../../components/Recruitment/ResponseSource";
import {
    deleteRecruitmentVacancyCandidatesResponse,
    getRecruitmentVacancyCandidatesResponses,
    updateRecruitmentVacancyCandidatesResponsesStore,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/responses/actionCreators";
import {
    getRecruitmentVacancyCandidatesResponsesInitialState,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/responses/reducer";
import {
    bffRecruitmentVacancyCandidatesResponsesListSelector,
    bffRecruitmentVacancyCandidatesResponsesPageDataSelector,
    bffRecruitmentVacancyCandidatesResponsesProgressActionSelector,
    bffRecruitmentVacancyCandidatesResponsesProgressSelector,
    bffRecruitmentVacancyCandidatesResponsesTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/responses/selectors";

import {useInfiniteScrollPagination} from "../../../../hooks/useInfiniteScrollPagination";
import {useModal} from "../../../../hooks/useModal";
import {useRecruitmentVacancyDownloadResume} from "../../vacancies/hooks/useDownloadResume";

import {isVisibleRecruitmentActions} from "../../../../utils/access";
import {toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES,
} from "../../../../constants/links";
import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../vacancies/constants";

export const RecruitmentCandidatesResponses = (props) => {
    const {
        match: {
            params: {
                id: candidateId,
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(bffRecruitmentVacancyCandidatesResponsesProgressSelector);
    const list = useSelector(bffRecruitmentVacancyCandidatesResponsesListSelector);
    const progressAction = useSelector(bffRecruitmentVacancyCandidatesResponsesProgressActionSelector);
    const totalPages = useSelector(bffRecruitmentVacancyCandidatesResponsesTotalPagesSelector);
    const {isLoadMore} = useSelector(bffRecruitmentVacancyCandidatesResponsesPageDataSelector);

    const isAccessActions = isVisibleRecruitmentActions();

    const {
        isOpen: isOpenViewData,
        modalData: responseModalData,
        onOpenModal: onOpenViewData,
        onCloseModal: onCloseViewData,
    } = useModal();

    const {
        isOpen: isOpenDeleting,
        modalData: deletingModalData,
        onOpenModal: onOpenDeletingConfirm,
        onCloseModal: onCloseDeletingConfirm,
    } = useModal();

    const {
        pageData,
        infiniteScrollProps,
        setPageData,
    } = useInfiniteScrollPagination({
        totalPages,
        fetchCallback: ({pageNum}) => {
            fetchList({
                pageNum,
            });
        },
    });

    const {
        openResume,
    } = useRecruitmentVacancyDownloadResume();

    const fetchList = (params = {}) => {
        const {
            isLoadMore = true,
            pageNum = 1,
        } = params;

        if (!isLoadMore && pageData.pageNum !== 1) {
            setPageData({pageNum: 1});
        }

        dispatch(getRecruitmentVacancyCandidatesResponses({
            isLoadMore,
            clientId,
            candidateId,
            pageNum,
            pageSize: 25,
        }));
    };

    useEffect(() => {
        fetchList();

        return () => {
            const initialState = getRecruitmentVacancyCandidatesResponsesInitialState();

            dispatch(updateRecruitmentVacancyCandidatesResponsesStore(initialState));
        };
    }, []);

    const deleteResponse = () => {
        dispatch(deleteRecruitmentVacancyCandidatesResponse({
            candidateId,
            responseId: deletingModalData.responseId,
            onSuccess: () => {
                toastSuccess("Связь с откликом успешно удалена");
                onCloseDeletingConfirm();
                fetchList({isLoadMore: false});
            },
        }));
    };

    const getRowMediaControls = (item) => {
        const {
            responseId,
            hasVacancyAccess,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenViewData({responseId});
                        },
                        children: "Просмотр данных",
                    },
                    visible: isAccessActions && Boolean(hasVacancyAccess),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenDeletingConfirm({responseId});
                        },
                        color: "grey",
                        children: "Удалить связь",
                    },
                    visible: isAccessActions && Boolean(hasVacancyAccess),
                },
            ],
        };
    };

    const getSource = (source) => {
        if (!source) {
            return "-";
        }

        return (
            <RecruitmentResponseSource
                isShowBoardNameText={false}
                source={source}
            />
        );
    };

    const getPrimaryHeader = (item) => {
        const {
            vacancyId,
            vacancyNumber,
            vacancyName,
            hasVacancyAccess,
        } = item;

        const link = LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES
            .replace(":clientId", clientId)
            .replace(":vacancyId", vacancyId);

        const text = `№${vacancyNumber} - ${vacancyName}`;

        if (hasVacancyAccess) {
            return (
                <ExtLink
                    to={link}
                >
                    {text}
                </ExtLink>
            );
        }

        return text;
    };

    const getRows = () => {
        return list.map(item => {
            const responseSource = item.source?.responseSource;
            const isNaimixResume = item.contractorId && responseSource === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX;
            const isResume = item.resume || isNaimixResume;

            return {
                key: item.responseId,
                contentRow: (
                    <NmListCard
                        noDivider={true}
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-xxl-10"
                        classNamePrimaryHeaderValue="fluid me-0"
                        primaryHeader={getPrimaryHeader(item)}
                        labels={[
                            {
                                label: "Этап отбора",
                                text: item.statusName,
                            },
                            {
                                label: "Источник",
                                text: getSource(item.source),
                                className: "align-items-center",
                            },
                            {
                                label: "Резюме",
                                text: isResume ? "Скачать" : "-",
                                title: isResume ? "Скачать на устройство" : undefined,
                                cursorPointer: true,
                                color: "blue",
                                onClickText: () => {
                                    if (!isResume) {
                                        return;
                                    }

                                    const responseSource = item.source?.responseSource;
                                    const isNaimixResume = item.contractorId && responseSource === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX;

                                    openResume({
                                        isDownload: true,
                                        isNaimixResume,
                                        responseId: item.responseId,
                                        fileName: item.resume?.fileName,
                                        contractorId: item.contractorId,
                                    });
                                },
                            },
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={getRowMediaControls(item)}
                    />
                ),
            };
        });
    };

    return (
        <div className="relative flex-grow-1">
            {
                progress &&
                <NmLoader
                    active={true}
                    inverted={true}
                />
            }
            {
                isOpenViewData &&
                <RecruitmentCandidateResponseDataTransfer
                    onClose={onCloseViewData}
                    responseId={responseModalData?.responseId}
                    candidateId={candidateId}
                    fetchList={fetchList}
                />
            }
            {
                isOpenDeleting &&
                <NmConfirmV2
                    disabled={progressAction}
                    onCancel={onCloseDeletingConfirm}
                    onConfirm={deleteResponse}
                    isNeedClosing={false}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                    title="Вы действительно хотите удалить связь кандидата с откликом?"
                    warning={true}
                    content="В случае удаления связи этот отклик останется без связей с кандидатом во всех вакансиях"
                />
            }
            {
                !list.length ?
                    <NmEmptyPageV2
                        title="Список откликов пуст"
                    /> :
                    <CheckboxList
                        infiniteScrollProps={infiniteScrollProps}
                        rows={getRows()}
                        infiniteProgress={progress && isLoadMore}
                    />
            }
        </div>
    );
};
