import {getClientPropertiesParamSelectorType} from "./getClientPropertiesParamSelectorType";
import {getNextAllObjectsParams} from "./getNextAllObjectsParams";
import {getNextAllProjectsParams} from "./getNextAllProjectsParams";
import {getObjectsByProjectsTree} from "./getObjectByProjects";
import {getProjectsFromTree} from "./getProjectsFromTree";

import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

export const getHidingProjectsObjectRequestData = (params) => {
    const {
        nodes,
        clientId,
        clientPropertiesBoolParam,
        isVisibleClient,
        isClientPropertiesBoolParam = true,
    } = params;

    if (isVisibleClient) {
        const [client] = nodes;

        if (isClientPropertiesBoolParam && !clientPropertiesBoolParam) {
            return {
                clientId,
                clientPropertiesBoolParam: false,
            };
        }

        const projects = client.children;
        const clientPropertiesParamSelectorType = getClientPropertiesParamSelectorType(client);

        if (
            [
                CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.EMPTY,
            ].includes(clientPropertiesParamSelectorType)
        ) {
            return {
                clientId,
                clientPropertiesBoolParam,
                clientPropertiesParamSelectorType,
            };
        }

        const projectsParams = getProjectsFromTree({
            projects,
            clientPropertiesParamSelectorType,
            isVisibleClient,
        });

        const nextAllProjectsParams = getNextAllProjectsParams({
            client,
            projectsParams,
        });

        const nextAllObjectsParams = getNextAllObjectsParams({
            nextAllProjectsParams,
            projects,
            isVisibleClient,
            clientPropertiesParamSelectorType,
        });

        return {
            clientId,
            clientPropertiesParamSelectorType,
            objectsParams: getObjectsByProjectsTree({projects}),
            nextAllProjectsParams,
            nextAllObjectsParams,
            projectsParams,
            clientPropertiesBoolParam,
        };
    }

    const projects = nodes;

    const projectsParams = getProjectsFromTree({
        projects,
        isVisibleClient,
    });

    return {
        clientId,
        objectsParams: getObjectsByProjectsTree({projects}),
        projectsParams,
        clientPropertiesBoolParam,
    };
};