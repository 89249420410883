import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import ContractorContraindicationsWorkEditModal from "./components/contractor-contraindications-work-edit-modal";

import {
    contractorWorkContraindicationSelector,
    updateContractorWorkContraindication,
} from "../../../../ducks/contractorProfile";

const WORK_CONTRAINDICATION_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const OPTIONS = [
    {
        key: WORK_CONTRAINDICATION_OPTION.EDIT,
        text: "Редактировать",
        value: WORK_CONTRAINDICATION_OPTION.EDIT,
    },
    {
        key: WORK_CONTRAINDICATION_OPTION.REMOVE,
        text: "Удалить",
        value: WORK_CONTRAINDICATION_OPTION.REMOVE,
    },
];

export default function ContractorContraindicationsWork(props) {
    const {
        className,
        contractorId,
        getProfile,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const {workContraindications, workContraindicationsPresent} = useSelector(contractorWorkContraindicationSelector);

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [confirmData, setConfirmData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const onClickAction = ({value}) => {
        if (value === WORK_CONTRAINDICATION_OPTION.EDIT) {
            toggleEditModal({
                workContraindications,
                hasWorkContraindications: !workContraindicationsPresent,
            });
        }

        if (value === WORK_CONTRAINDICATION_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: handleDelete,
                confirmContent: "Вы уверены что хотите удалить сведения?",
            });
        }
    };

    const handleDelete = () => {
        dispatch(updateContractorWorkContraindication({
            contractorId,
            workContraindications: "",
            hasWorkContraindications: true,
            getResult: () => {
                getProfile();
                handleCloseConfirm();
            },
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
    };
    function mapContent() {
        if (!workContraindicationsPresent) {
            return <Text level="1">
                Противопоказания к работе отсутствуют
            </Text>;
        }
        if (!workContraindications) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return (
            <div className="d-flex flex-column">
                <Text>
                    {workContraindications}
                </Text>
            </div>
        );
    }

    return (
        <>
            {isShowEditModal &&
            <ContractorContraindicationsWorkEditModal
                contractorId={contractorId}
                getProfile={getProfile}
                editData={editData}
                onClose={() => toggleEditModal()}
            />
            }
            {!isEmpty(confirmData) && <NmConfirmV2
                onCancel={handleCloseConfirm}
                onConfirm={onConfirm}
                confirmButton="Продолжить"
                cancelButton="Отменить"
                content={confirmContent}
                size="sm"
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Противопоказания к работе"
                onClickAdd={!workContraindications ? () => onClickAction({value: WORK_CONTRAINDICATION_OPTION.EDIT}) : null}
                options={workContraindications ? OPTIONS : null}
                onClickItem={onClickAction}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}