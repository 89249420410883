import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import DropzoneV2 from "../../../../../../components/ActualComponents/DropzoneV2";
import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useCreateOrUpdateCourseCertificate, {defaultCourseCertificateForm} from "./hooks/useCreateOrUpdateCourseCertificate";

import {getDateObject} from "../../../../../../utils/dateFormat";

import {
    deleteContractorCourseCertificate,
    getContractorCourseCertificateFile,
} from "../../../../../../ducks/contractorProfile";

export default function ContractorCourseCertificateEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultCourseCertificateForm},
        contractorId,
    } = props;

    const dispatch = useDispatch();

    const {t} = useTranslation();
    const {
        onChangeForm,
        submit,
        form,
        setForm,
        handleDropFile,
        files,
        setFiles,
        error,
    } = useCreateOrUpdateCourseCertificate({contractorId, onClose, getProfile});

    useEffect(() => {
        if (editData.courseCertificateId) {
            setForm({
                ...editData,
                finishedDate: getDateObject(editData.finishedDate),
            });
            editData.fileName && getFile();
        }
    }, [editData.courseCertificateId]);

    const getFile = () => {
        dispatch(getContractorCourseCertificateFile({
            certificateId: editData.courseCertificateId,
            fileName: editData.fileName,
            contractorId,
            getResult: (file) => {
                if (file) {
                    setFiles([file]);
                }
            },
        }));
    };

    const deleteElem = () => {
        dispatch(deleteContractorCourseCertificate({
            contractorId,
            courseCertificateId: editData.courseCertificateId,
            getResult: () => {
                getProfile();
                onClose();
            },
        }));
    };

    const {
        courseName,
        finishedDate,
        organization,
    } = form;

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                    additionalBtnContent={editData.courseCertificateId ? "Удалить" : null}
                    additionalBtnColor={editData.courseCertificateId ? "red" : null}
                    onClickAdditional={editData.courseCertificateId ? deleteElem : null}
                />
            }
            header={
                <NmTitle size="lg">
                    Сертификаты и курсы
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmInputV2
                    required
                    size="xl"
                    label="Наименование курса"
                    value={courseName}
                    name="courseName"
                    onChange={onChangeForm}
                    error={error.courseName}
                />
                <NmDatePicker
                    required
                    placeholderText="дд.мм.гггг"
                    dateFormatMask="99.99.9999"
                    size="xl"
                    label="Дата выдачи"
                    selected={finishedDate}
                    name="finishedDate"
                    onChange={onChangeForm}
                    maxDate={Date.now()}
                    autoComplete="off"
                    error={error.finishedDate}
                />
                <NmInputV2
                    required
                    size="xl"
                    label="Организация, проводившая обучение"
                    value={organization}
                    name="organization"
                    error={error.organization}
                    onChange={onChangeForm}
                />
                <DropzoneV2
                    onChange={handleDropFile}
                    name="file"
                    // error={errors['file']}
                    validFormats="png, jpeg, jpg, doc, docx, pdf"
                    accept=".png, .jpeg, .jpg, .doc, .docx, .pdf"
                    maxSize="25"
                    files={files}
                />
            </NmForm>
        </NmModal>
    );
}