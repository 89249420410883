import React from "react";

import bem from "../../utils/bem";

import "./style.sass";

export type LogoThumbnailProps = {
    src: any,
    className?: string;
    size?: "xl" | "md" | "sm" | "md-list-card",
    showOriginal?: () => void,
    width?: number,
    height?: number,
    minWidth?: number,
    noBorder?: boolean
}

const LogoThumbnail = (props: LogoThumbnailProps) => {
    const {
        src,
        showOriginal,
        width,
        height,
        minWidth,
        size = "xl",
        noBorder = false,
        className,
    } = props;

    const [block, element] = bem("logo-thumbnail", className);

    return (
        <div
            style={{width, height, minWidth}}
            className={block({size, "clickable": !!showOriginal, noBorder})}
            onClick={showOriginal}
        >
            <img src={src} />
        </div>
    );
};

export default LogoThumbnail;