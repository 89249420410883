import React from "react";
import {useSelector} from "react-redux";

import ActCreation from "../../../../../../components/ActCreation";

import useCrowdTaskActCreationForm from "./hooks/useForm";

import {bffCrowdPaymentProgressActionSelector} from "../../../../../../ducks/bff/crowd/payment/selectors";

const CrowdTaskActCreation = (props) => {
    const {
        clientId,
        taskId,
        contractorId,
        onClose = () => {},
        taskCard = {},
        fetchInfo,
        contractorName,
        contractSigningDateTime,
        applicationSigningDateTime,
        taskWorkFinishedDateTime,
    } = props;

    const progress= useSelector(bffCrowdPaymentProgressActionSelector);

    const {
        values,
        handleChange,
        handleSubmit,
    } = useCrowdTaskActCreationForm({
        clientId,
        taskId,
        contractorId,
        taskCard,
        onClose,
        fetchInfo,
        taskWorkFinishedDateTime,
    });

    return (
        <ActCreation
            isTaskAct={true}
            clientId={clientId}
            taskId={taskId}
            header="Формирование акта выполненных работ (оказанных услуг)"
            submitBtnContent="Сформировать акт"
            progress={progress}
            handleClose={onClose}
            formData={values}
            handleChange={handleChange}
            handleOnRate={handleChange}
            submit={handleSubmit}
            paymentInfo={{
                taskAmount: taskCard.paymentAmount,
            }}
            taskDescription={taskCard.servicesDescription}
            workQualityScore={values.workQualityScore}
            estimatedTimeScore={values.estimatedTimeScore}
            taskContractorInfo={{
                contractorName,
                contractSigningDateTime,
                applicationSigningDateTime,
            }}
        />
    );
};

export default CrowdTaskActCreation;