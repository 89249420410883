import {useEffect, useState} from "react";

import {
    checkArrivalNoticeCard,
    checkMigrationCard,
    checkPassportDataBy,
    checkPassportDataForeigner,
    checkPatentCard,
    checkVhiPolicy,
} from "../../../../contractor_info/controlDateReadOnly";
import useRuWarnings from "./useRuWarnings";

import {addDays, addYears, calculateAge, getDay, getStartDate} from "../../../../../../utils/dateFormat";
import {validatePassportDate} from "../../../../../../utils/validate";

import {citizenshipsDict} from "../../../../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../../../../constants/clientList";
import {CONTRACTOR_ARRIVAL_DOC_TYPE_CODE, CONTRACTOR_FIELD} from "../../../../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../../../../constants/contractorInfo";

const useWarnings = ({values, isRegistrationForm = false}) => {
    const [scanWarnings, setScanWarnings] = useState({});
    const [fieldWarnings, setFieldWarnings] = useState({});

    useRuWarnings({
        values,
        setScanWarnings,
        setFieldWarnings,
    });

    const checkPassportAm = () => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];
        let errorMessage = "Некорректная дата окончания срока действия паспорта";

        if (!passportDate || !passportDateEnd) {
            return undefined;
        }

        const result1 = getStartDate(addYears(passportDate, 10));
        const result2 = getStartDate(addYears(passportDate, 15));


        if ([result1.getTime(), result2.getTime()].includes(new Date(getStartDate(passportDateEnd)).getTime())) {
            errorMessage = undefined;
        }

        return errorMessage;
    };
    const checkPassportKz = () => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];
        let errorMessage = "Некорректная дата окончания срока действия паспорта";

        if (!passportDate || !passportDateEnd) {
            return undefined;
        }

        const result = addYears(passportDate, 10);
        const leftPartEquationOne = getStartDate(addDays(result, -1));
        const leftPartEquationTwo = getStartDate(result);
        const rightPartEquation = getStartDate(new Date(passportDateEnd));

        errorMessage = [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime()) ? undefined : errorMessage;

        return errorMessage;
    };
    const checkPassportKg = () => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];
        let errorMessage = "Некорректная дата окончания срока действия паспорта";

        if (!passportDate || !passportDateEnd) {
            return undefined;
        }

        const passportType = values[CONTRACTOR_FIELD.PASSPORT_TYPE];
        const birthDate = values[CONTRACTOR_FIELD.BIRTH_DATE];

        if (passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT && calculateAge(birthDate) > 55) {
            return undefined;
        }

        const result = addYears(passportDate, 10);
        const leftPartEquationOne = getStartDate(addDays(result, -1));
        const leftPartEquationTwo = getStartDate(result);
        const rightPartEquation = getStartDate(new Date(passportDateEnd));

        errorMessage = [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime()) ? undefined : errorMessage;

        return errorMessage;
    };
    const checkPassportUz = () => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];
        const birthDate = values[CONTRACTOR_FIELD.BIRTH_DATE];
        const userAges = calculateAge(birthDate);
        let errorMessage = "Некорректная дата окончания срока действия паспорта";

        if (!passportDate || !passportDateEnd || !userAges) {
            return undefined;
        }

        if ((userAges >= 14) && (userAges < 16)) {
            const checkDate = addYears(passportDate, 5);
            const leftPartEquationOne = getStartDate(addDays(checkDate, -1));
            const leftPartEquationTwo = getStartDate(checkDate);
            const rightPartEquation = getStartDate(new Date(passportDateEnd));

            errorMessage = [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime()) ? undefined : errorMessage;

            return errorMessage;
        }

        if (userAges >= 16) {
            const checkDate = addYears(passportDate, 10);
            const leftPartEquationOne = getStartDate(addDays(checkDate, -1));
            const leftPartEquationTwo = getStartDate(checkDate);
            const rightPartEquation = getStartDate(new Date(passportDateEnd));

            errorMessage = [leftPartEquationOne.getTime(), leftPartEquationTwo.getTime()].includes(rightPartEquation.getTime()) ? undefined : errorMessage;

            return errorMessage;
        }

        return errorMessage;


    };
    const checkPassportBy = () => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const passportDateEnd = values[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];
        let errorMessage = "Некорректная дата окончания срока действия паспорта";

        if (!passportDate || !passportDateEnd) {
            return undefined;
        }

        const result = addYears(passportDate, 10);
        const leftPartEquation = getStartDate(result);
        const rightPartEquation = getStartDate(new Date(passportDateEnd));

        errorMessage = leftPartEquation.getTime() === rightPartEquation.getTime() ? undefined : errorMessage;

        return errorMessage;
    };
    //проверка даты выдачи Миграционной карты
    const checkMigrationCardIssuedDate = () => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const migrationCardDate = values[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE];

        if (!migrationCardDate) {
            return;
        }

        let errorMessage = "Возможно, Ваша миграционная карта устарела. Игнорируйте это сообщение, если у Вас есть основания не продлевать миграционную карту";

        errorMessage = new Date(migrationCardDate) < new Date(passportDate) ? errorMessage : undefined;

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: errorMessage,
        }));
    };
    //проверка даты окночания срока Миграционной карты
    const checkMigrationCardValidToDate = () => {
        const migrationCardDate = values[CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE];
        const migrationCardDateEnd = values[CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE];
        const milliseconds = (getStartDate(new Date(migrationCardDateEnd)) - getStartDate(new Date(migrationCardDate)));
        let errorMessage = "Возможно, некорректная дата окончания срока действия документа";


        errorMessage = getDay(milliseconds) > 90 ? errorMessage : undefined;

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: errorMessage,
        }));
    };

    //проверка даты окончания срока "Уведомление о прибытии"
    const checkArrivalNoticeCardNext = () => {
        if (values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.UA.value) {
            return;
        }

        const registrationDate = values[CONTRACTOR_FIELD.REGISTRATION_DATE];
        const arrivalNoticeStayEndDate = values[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE];
        const milliseconds = (new Date(arrivalNoticeStayEndDate) - new Date(registrationDate));
        let errorMessage = "Возможно, некорректная дата окончания срока действия документа";

        errorMessage = getDay(milliseconds) > 90 ? errorMessage : checkPermitValidToDate();

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: undefined,
            [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: errorMessage,
        }));
    };
    const checkPermitValidToDate = () => {

        if (values[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION) {
            return;
        }

        const startDate = values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE];
        const endDate = values[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE];
        let errorMessage = "Некорректная дата окончания срока действия документа";

        if (!startDate || !endDate) { //проверка заполенено ли поле даты выдачи
            return;
        }

        const isOldDateOfReceiving = new Date(startDate) < new Date(2019,10,1); // это дата выдачи до 01.11.2019 года

        const registrationDate = addYears(startDate, 5);
        const leftPartEquation = getStartDate(registrationDate);
        const rightParEquation = getStartDate(new Date(endDate));

        errorMessage = (isOldDateOfReceiving && !(leftPartEquation.getTime() === rightParEquation.getTime())) ?
            errorMessage : undefined;

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: errorMessage,
        }));
    };

    //проверка даты выдачи паспорта
    useEffect(() => {
        const passportDate = values[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];

        if (!passportDate) {
            return;
        }

        let errorMessage;

        // Беларусь && с датой выдачи после 2012 года
        if (
            values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.BY.value &&
            new Date(passportDate) < passportDateLimitationBY
        ) {
            const isValid = validatePassportDate({
                passportDate: new Date(passportDate),
                birthDate: values[CONTRACTOR_FIELD.BIRTH_DATE],
                intervalAges: {
                    first: 14,
                    second: 25,
                    third: 45,
                },
                isBy: true,
            });

            errorMessage = isValid ? undefined : "Некорректная дата выдачи паспорта";
        }

        //Казахстан && внутренний паспорт
        if (
            values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.KZ.value &&
            values[CONTRACTOR_FIELD.PASSPORT_TYPE] === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT
        ) {
            const birthDate = values[CONTRACTOR_FIELD.BIRTH_DATE];
            const result = addYears(birthDate, 16);

            errorMessage = new Date(passportDate) >= result ? undefined : "Некорректная дата выдачи паспорта";
        }

        //Киргизия
        if (values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.KG.value) {
            const birthDate = values[CONTRACTOR_FIELD.BIRTH_DATE];
            const result = addYears(birthDate, 16);

            errorMessage = new Date(passportDate) >= result ? undefined : "Некорректная дата выдачи паспорта";
        }

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: errorMessage,
        }));
    }, [values]);

    //проверка даты окончания срока действия паспорта
    useEffect(() => {
        if (values.citizenship === citizenshipsDict.UA.value) {
            return;
        }

        //для иностранцев: (!RU && !BY)
        if (
            ![
                citizenshipsDict.RU.value,
            ].includes(values[CONTRACTOR_FIELD.CITIZENSHIP])
        ) {
            const error = checkPassportDataForeigner({
                values: values,
                card: values,
                isVerification: true,
                isRegistrationForm,
            });

            const errorMessage = !error ? undefined : error;

            //если нет ошибки в проверке режима просмотра, то будем выполнять проверку на корректность режима редактирования
            //проверка режима редактирования только для полей(сканы не подсвечиваем)
            if (!errorMessage) {
                const errorMessage2 = getCorrectlyPassportEndDate(values[CONTRACTOR_FIELD.CITIZENSHIP]);

                setFieldWarnings(prevState => ({
                    ...prevState,
                    [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: errorMessage2,
                }));

                return;
            }

            setFieldWarnings(prevState => ({
                ...prevState,
                [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: errorMessage,
            }));

            setScanWarnings(prevState => ({
                ...prevState,
                [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: errorMessage,
                [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE]: errorMessage,
                [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE]: errorMessage,
            }));
            return;
        }
        //Беларусь
        if (values[CONTRACTOR_FIELD.CITIZENSHIP] === citizenshipsDict.BY.value) {
            const error = checkPassportDataBy({
                values: values,
                card: values,
                isVerification: true,
            });

            const errorMessage = !error ? undefined : error;

            setFieldWarnings(prevState => ({
                ...prevState,
                [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: errorMessage,
            }));

            setScanWarnings(prevState => ({
                ...prevState,
                [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: errorMessage,
                [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE]: errorMessage,
                [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE]: errorMessage,
            }));
        }

    }, [values]);

    //проверка срока действия документа миграционной карты
    useEffect(() => {
        if (values.citizenship === citizenshipsDict.UA.value) {
            return;
        }

        const error = checkMigrationCard({
            values,
            card: values,
            isVerification: true,
        });

        const errorMessage = !error ? undefined : error;

        if (!errorMessage) {
            checkMigrationCardIssuedDate();
            checkMigrationCardValidToDate();

            return;
        }

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: errorMessage,
        }));

        setScanWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD]: errorMessage,
            [CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD]: errorMessage,
        }));
    }, [values]);
    //проверка срока действия уведомления о прибытии
    useEffect(() => {
        if (values.citizenship === citizenshipsDict.UA.value) {
            return;
        }

        const error = checkArrivalNoticeCard({
            values,
            card: values,
            isVerification: true,
        });

        const errorMessage = !error ? undefined : error;

        if (!errorMessage) {
            checkArrivalNoticeCardNext();

            return;
        }

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: errorMessage,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: errorMessage,
        }));

        setScanWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST]: errorMessage,
            [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND]: errorMessage,
            [CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT]: errorMessage,
        }));

    }, [values]);
    //проверка срока действия документа патента
    useEffect(() => {
        if (values.citizenship === citizenshipsDict.UA.value) {
            return;
        }

        const error = checkPatentCard({
            values,
            card: values,
            isVerification: true,
        });

        const errorMessage = !error ? undefined : error;

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]: errorMessage,
        }));

        setScanWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: errorMessage,
            [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: errorMessage,
            [CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN]: errorMessage,

        }));

    }, [values]);

    //првоерка срока действия ДМС
    useEffect(() => {
        const error = checkVhiPolicy({
            values,
            card: values,
            isVerification: true,
        });

        const errorMessage = !error ? undefined : error;

        setFieldWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]: errorMessage,
        }));

        setScanWarnings(prevState => ({
            ...prevState,
            [CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN]: errorMessage,
        }));

    }, [values]);

    const getCorrectlyPassportEndDate = (citizenship) => {
        switch (citizenship) {
        case citizenshipsDict.AM.value:
            return checkPassportAm();
        case citizenshipsDict.KZ.value:
            return checkPassportKz();
        case citizenshipsDict.KG.value:
            return checkPassportKg();
        case citizenshipsDict.UZ.value:
            return checkPassportUz();
        case citizenshipsDict.BY.value:
            return checkPassportBy();
        default:
            return;
        }
    };

    return {
        scanWarnings,
        fieldWarnings,
    };
};

export default useWarnings;

const passportDateLimitationBY = new Date(2012,7,19); //Дата выдачи новых образцов паспортов в Беларуси


