import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_ERROR,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_REQUEST,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_SUCCESS,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST,
    EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";

const controller = "/events/contractor";

// GET /api/int/events/contractor/aggregated/by-mailing/{mailingId}
// Получение показателей конверсии для рассылки
const getEventsContractorAggregatedParamsByMailing = function* ({payload}) {
    const {
        mailingId,
    } = payload;

    try {
        const result = yield request.get(`${controller}/aggregated/by-mailing/${mailingId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_ERROR,
            payload: error,
        });
    }
};

// /api/events/contractor/aggregated/by-smart-link/{smartLinkId}
// Получение показателей для аналитики по ссылке
const getEventsContractorAggregatedParamsBySmartLink = function* ({payload}) {
    const {
        smartLinkId,
    } = payload;

    try {
        const result = yield request.get(`${controller}/aggregated/by-smart-link/${smartLinkId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_MAILING_REQUEST, getEventsContractorAggregatedParamsByMailing),
        takeEvery(EVENTS_CONTRACTOR_GET_AGGREGATED_PARAMS_BY_SMART_LINK_REQUEST, getEventsContractorAggregatedParamsBySmartLink),
    ]);
}