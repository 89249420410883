import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmBadge from "../../../../../../components/NmBadge";
import NmPage from "../../../../../../components/NmPage";
import NmTitle from "../../../../../../components/NmTitle";

import {usePagination} from "../../../../../../hooks/usePagination";
import {useFetchContractorWalletWithdrawAvailableLog} from "./hooks/useFetch";

import {formatLocalDate} from "../../../../../../utils/dateFormat";

import {
    settingsDirectoriesBanksLogListSelector,
    settingsDirectoriesBanksLogProgressSelector,
    settingsDirectoriesBanksLogTotalCountSelector,
    settingsDirectoriesBanksLogTotalPagesSelector,
} from "../../../../../../ducks/settingsDirectoriesBanks";

const ContractorWalletWithdrawAvailableLog = (props) => {
    const {
        onClose = () => {},
    } = props;

    const totalPages = useSelector(settingsDirectoriesBanksLogTotalPagesSelector);
    const list = useSelector(settingsDirectoriesBanksLogListSelector);
    const totalCount = useSelector(settingsDirectoriesBanksLogTotalCountSelector);
    const progress = useSelector(settingsDirectoriesBanksLogProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const {

    } = useFetchContractorWalletWithdrawAvailableLog({pageNum, pageSize});

    const getRows = () => {
        return list.map(item => {
            const {
                logId,
                updateDateTime,
                clientUserFio,
                value,
            } = item;

            const _value = value === "true";

            return {
                ...item,
                key: logId,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16"
                        secondaryHeader={formatLocalDate(updateDateTime, "dd.MM.yyyy HH:mm")}
                        secondaryHeaderStatus={
                            <NmBadge
                                noWrap
                                mod={_value ? "green" : "red"}
                                text={_value ? "Включено" : "Выключено"}
                            />
                        }
                        labels={[
                            {
                                label: "Инициатор",
                                text: clientUserFio,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог изменений опции вывода средств с кошельков
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2 />
                }
            </NmPage>
        </NmModal>
    );
};

export default ContractorWalletWithdrawAvailableLog;