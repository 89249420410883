import React, {FC, ReactNode, useState} from "react";

import {ReactComponent as CloseIcon} from "../../../../../images/close_24.svg";
import {ReactComponent as DeleteIcon} from "../../../../../images/delete_24.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../../images/file_download.svg";
import NmButton from "../../../../NmButton";
import NmLoader from "../../../../NmLoader";
import HelpTooltip from "../../../HelpTooltip";
import { Layer } from "../../../NmModal";

import bem from "../../../../../utils/bem";
import {handleDropzoneValidations} from "../../utils/handleValidations";

import {TDropzoneOnRemoveFile} from "../../types";

import "./style.sass";

interface IDropzoneUploadedImagePreview {
    file: any,
    isError: boolean,
    format?: string,
    maxSize?: number,
    minSize?: number,
    isDownload?: boolean,
    onDownloadFile: (file: any) => void,
    onRemoveFile: ({name, isError}: TDropzoneOnRemoveFile) => void,
    isShowDeleteButton?: boolean,
    onOpenFullImage?: (file: any) => void,
    onCloseFullImage?: (file: any) => void,
    isTopImagePreview?: boolean,
    replaceButton?: ReactNode,
}

export const DropzoneUploadedImagePreview: FC<IDropzoneUploadedImagePreview> = (props) => {
    const {
        file,
        isError,
        isDownload,
        isShowDeleteButton = true,
        format,
        maxSize,
        minSize,
        onDownloadFile,
        onRemoveFile,
        isTopImagePreview,
        replaceButton,
    } = props;
    const {
        errors,
        isProgress = false,
    } = file;
    const [block, element] = bem("dropzone-uploaded-image-preview");
    const [isOpenFullImage, setOpenFullImage] = useState(false);

    const onCloseFullImage = () => {
        if (props.onCloseFullImage) {
            props.onCloseFullImage(file);
        }

        setOpenFullImage(false);
    };

    const onClickImage = () => {
        if (isOpenFullImage) {
            onCloseFullImage();

            return;
        }

        if (props.onOpenFullImage) {
            props.onOpenFullImage(file);
        }

        setOpenFullImage(true);
    };

    const getDeleteButton = () => {
        if (isTopImagePreview) {
            return (
                <NmButton
                    className={element("action", {topImage: true})}
                    onlyIcon
                    icon={<DeleteIcon />}
                    size="sm"
                    color="grey"
                    onClick={() => onRemoveFile({
                        name: file.name,
                        isError,
                        isRemote: file.isRemote,
                    })}
                />
            );
        }

        return (
            <CloseIcon
                className={element("action")}
                width={16}
                height={16}
                onClick={() => onRemoveFile({
                    name: file.name,
                    isError,
                    isRemote: file.isRemote,
                })}
            />
        );
    };

    const getDownloadButton = () => {
        if (isTopImagePreview) {
            return (
                <NmButton
                    className={element("action", {topImage: true})}
                    onlyIcon
                    icon={
                        <FileDownloadIcon />
                    }
                    size="sm"
                    color="grey"
                    onClick={() => onDownloadFile(file)}
                />
            );
        }

        return (
            <FileDownloadIcon
                className={element("action")}
                width={16}
                height={16}
                onClick={() => onDownloadFile(file)}
            />
        );
    };

    const getControls = () => {
        return (
            <div className={element("controls", {topImage: isTopImagePreview})}>
                {
                    isError &&
                    <HelpTooltip
                        info
                        hover
                        className="ms-1"
                        type="error"
                        compound
                        body={handleDropzoneValidations({
                            errors,
                            format,
                            maxSize,
                            minSize,
                        })}
                        isTypeTooltipEqualTypeIcon={false}
                        classNameTooltip={element("tooltip")}
                        width={14}
                        height={14}
                        position="bottom"
                    />
                }
                {
                    isDownload && !isError && getDownloadButton()
                }
                {
                    isShowDeleteButton && getDeleteButton()
                }
            </div>
        );
    };

    const getLoader = ({type}: {type: "small" | "full"}) => {
        if (!isProgress) {
            return null;
        }

        return (
            <NmLoader
                classNameDimmer={element("loader", {type})}
                active={isProgress}
                content=""
                inverted
            />
        );
    };

    const getFullImage = (image: ReactNode) => {
        if (!isOpenFullImage) {
            return null;
        }

        return (
            <Layer
                zIndex={1000}
                className={element("layer")}
            >
                <div className={element("container")}>
                    {getLoader({type: "full"})}
                    {image}
                </div>
            </Layer>
        );
    };

    const image = file.base64str ? (
        <img
            onClick={onClickImage}
            alt=""
            src={` data:image/jpeg;charset=utf-8;base64, ${file.base64str}`}
            className={element("image", {full: isOpenFullImage,  topImage: isTopImagePreview})}
        />
    ) : (
        <img
            onClick={onClickImage}
            src={file.preview}
            alt={""}
            className={element("image", {full: isOpenFullImage,  topImage: isTopImagePreview})}
        />
    );

    return (
        <div className={block({error: isError, mt: !isTopImagePreview, topImage: isTopImagePreview})}>
            {getLoader({type: "small"})}
            {
                !isProgress && image
            }
            {Boolean(replaceButton) && replaceButton}
            {getControls()}
            {getFullImage(image)}
        </div>
    );
};