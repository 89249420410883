import {useState} from "react";

export type DataChange = {
    name: string,
    value: string | string[] | number | Date
};

export type FilterStatementType = {
    approverFio?: string,
    createdFrom?: string,
    createdTo?: string,
    dateFrom?: string,
    dateTo?: string,
    name?: string,
    receiverFio?: string,
    senderFio?: string,
    stmtState?: string,
    stmtTypeIds?: string[]
};

export type DataReturnChange = {
    filterDto: FilterStatementType,
    onChangeFilter: (e: any, value: DataChange) => void,
    setFilterDto: (prevState: {}) => void
};

export const useStatementFilter: () => DataReturnChange = () => {
    const [filterDto, setFilterDto] = useState<FilterStatementType>({});

    const handleChangeFilter = (event: any, {name, value}: DataChange) => {
        setFilterDto({
            ...filterDto,
            [name]: value,
        });
    };

    return {
        filterDto,
        onChangeFilter: handleChangeFilter,
        setFilterDto,
    };
};