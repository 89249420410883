import {
    CLIENT_FIELD_NAME,
    CLIENT_MEMBER_FIELD_NAME,
    CLIENT_PROPERTIES_FIELD,
    FEDERAL_MIGRATION_FIELD,
    OBJECT_FIELD_NAME,
} from "../constants/clientList";
import {CONTRACTOR_FIELD, CONTRACTOR_SCAN_FIELD} from "../constants/contractor";
import {DEPOSIT_FIELD, DEPOSIT_HISTORY_FIELD} from "../constants/deposit/fields";
import {DISPUTE_FIELD} from "../constants/disputes";
import {DOCUMENT_FIELD_NAME} from "../constants/documentType";
import {FINANCE_FIELD_NAME} from "../constants/financeLogType";
import {
    ORDER_CONTRACTOR_COUNTS_FIELD_NAME,
    ORDER_CONTRACTOR_FIELD,
    ORDER_CONTRACTOR_STATUS_FIELD_NAME,
    ORDER_FIELD,
} from "../constants/order";
import {ORDER_HISTORY_FIELD_NAME} from "../constants/order";
import {SETTINGS_NAIMIX_INFO_FIELD_NAME, SETTINGS_SUPPORT_FIELD_NAME} from "../constants/settings";

import PropTypes from "prop-types";

export const clientType = PropTypes.shape({
    [CLIENT_FIELD_NAME.INSURANCE_AVAILABLE]: PropTypes.bool,
    [CLIENT_FIELD_NAME.CURRENT_COMMISSION_RATE]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    [CLIENT_FIELD_NAME.ACCOUNTANT_NAME]: PropTypes.string,
    [CLIENT_FIELD_NAME.ACCOUNTANT_PHONE]: PropTypes.string,
    [CLIENT_FIELD_NAME.ACTUAL_ADDRESS]: PropTypes.string,
    [CLIENT_FIELD_NAME.ADDRESS]: PropTypes.string,
    [CLIENT_FIELD_NAME.ARCHIVED]: PropTypes.bool,
    [CLIENT_FIELD_NAME.BANK_ADDRESS]: PropTypes.string,
    [CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT]: PropTypes.string,
    [CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT]: PropTypes.string,
    [CLIENT_FIELD_NAME.BANK_NAME]: PropTypes.string,
    [CLIENT_FIELD_NAME.BIC]: PropTypes.string,
    [CLIENT_FIELD_NAME.ID]: PropTypes.string,
    [CLIENT_FIELD_NAME.DELETED]: PropTypes.string,
    [CLIENT_FIELD_NAME.EMAIL]: PropTypes.string,
    [CLIENT_FIELD_NAME.INN]: PropTypes.string,
    [CLIENT_FIELD_NAME.LOCKED]: PropTypes.bool,
    [CLIENT_FIELD_NAME.MANAGER_NAME]: PropTypes.string,
    [CLIENT_FIELD_NAME.MANAGER_PHONE]: PropTypes.string,
    [CLIENT_FIELD_NAME.NAME]: PropTypes.string,
    [CLIENT_FIELD_NAME.CONTRACT_NUMBER]: PropTypes.string,
    [CLIENT_FIELD_NAME.OGRN]: PropTypes.string,
    [CLIENT_FIELD_NAME.PHONE]: PropTypes.string,
    [CLIENT_FIELD_NAME.REGISTERED_ADDRESS]: PropTypes.string,
    [CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE]: PropTypes.number,
    [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]: PropTypes.string,
    [CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS]: PropTypes.string,
    [CLIENT_FIELD_NAME.FULL_NAME]: PropTypes.string,
    [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE]: PropTypes.string,
});

export const clientObjectType = PropTypes.shape({
    [OBJECT_FIELD_NAME.ADDRESS]: PropTypes.string,
    [OBJECT_FIELD_NAME.CLIENT_ID]: PropTypes.string,
    [OBJECT_FIELD_NAME.CONTRACTOR_NUMBER]: PropTypes.string,
    [OBJECT_FIELD_NAME.DESCRIPTION]: PropTypes.string,
    [OBJECT_FIELD_NAME.FEDERAL_ID]: PropTypes.string,
    [OBJECT_FIELD_NAME.LATITUDE]: PropTypes.number,
    [OBJECT_FIELD_NAME.LONGITUDE]: PropTypes.number,
    [OBJECT_FIELD_NAME.MANAGER_ID]: PropTypes.string,
    [OBJECT_FIELD_NAME.NAME]: PropTypes.string,
    [OBJECT_FIELD_NAME.OBJECT_ID]: PropTypes.string,
});

export const contractorType = PropTypes.shape({
    [CONTRACTOR_FIELD.ADDRESS]: PropTypes.string,
    [CONTRACTOR_FIELD.BANK_CARD_NUMBER]: PropTypes.string,
    [CONTRACTOR_FIELD.BIRTH_DATE]: PropTypes.string,
    [CLIENT_FIELD_NAME.NAIMIX_JOINING_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.BIRTH_PLACE]: PropTypes.string,
    [CONTRACTOR_FIELD.CARD_ID_THE_BANK]: PropTypes.string,
    [CONTRACTOR_FIELD.CITIZENSHIP]: PropTypes.string,
    [CONTRACTOR_FIELD.CONTRACTOR_ID]: PropTypes.string,
    [CONTRACTOR_FIELD.CONTRACTOR_ID_THE_BANK]: PropTypes.string,
    [CONTRACTOR_FIELD.DELETED]: PropTypes.string,
    [CONTRACTOR_FIELD.EMAIL]: PropTypes.string,
    [CONTRACTOR_FIELD.FIRST_NAME]: PropTypes.string,
    [CONTRACTOR_FIELD.FULL_NAME]: PropTypes.string,
    [CONTRACTOR_FIELD.GENDER]: PropTypes.string,
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: PropTypes.string,
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]: PropTypes.string,
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.ID_DOC_NUMBER]: PropTypes.string,
    [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.INDUSTRY]: PropTypes.string,
    [CONTRACTOR_FIELD.INN]: PropTypes.string,
    [CONTRACTOR_FIELD.IS_SELECTED]: PropTypes.bool,
    [CONTRACTOR_FIELD.LAST_NAME]: PropTypes.string,
    [CONTRACTOR_FIELD.LOCKED]: PropTypes.string,
    [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: PropTypes.string,
    [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.PASSWORD]: PropTypes.string,
    [CONTRACTOR_FIELD.PATRONYMIC]: PropTypes.string,
    [CONTRACTOR_FIELD.PHONE]: PropTypes.string,
    [CONTRACTOR_FIELD.REALUSER]: PropTypes.bool,
    [CONTRACTOR_FIELD.REGISTRATION_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.SPECIALTY]: PropTypes.array,
    [CONTRACTOR_FIELD.TAX_STATUS]: PropTypes.string,
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_BY]: PropTypes.string,
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_NUMBER]: PropTypes.string,
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_VALID_TO_DATE]: PropTypes.string,
    [CONTRACTOR_FIELD.WORK_REGION]: PropTypes.string,
});

export const menuType = PropTypes.shape({
    to: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
});

export const tableDataType = PropTypes.shape({
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            content: PropTypes.any,
        }),
    ),
    rows: PropTypes.arrayOf(
        PropTypes.object,
    ),
});

export const landindSliderType = PropTypes.arrayOf(PropTypes.shape({
    num: PropTypes.number,
    img: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]),
    title: PropTypes.string,
    description: PropTypes.string,
}));

const memberTypes = {
    [CLIENT_MEMBER_FIELD_NAME.ACTIVE]: PropTypes.bool,
    [CLIENT_MEMBER_FIELD_NAME.CLIENT_ID]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.ID]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.DELETED]: PropTypes.bool,
    [CLIENT_MEMBER_FIELD_NAME.LOCKED]: PropTypes.bool,
    [CLIENT_MEMBER_FIELD_NAME.SNILS]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.FIRST_NAME]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.LAST_NAME]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.PASSWORD]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.PATRONYMIC]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.POSITION]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.PHONE]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.LOGIN]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.ROLE]: PropTypes.string,
    [CLIENT_MEMBER_FIELD_NAME.EMAIL]: PropTypes.string,
};

export const memberType = PropTypes.shape({
    ...memberTypes,
});

export const memberFormattedType = PropTypes.shape({
    ...memberTypes,
    [CLIENT_MEMBER_FIELD_NAME.FULL_NAME]: PropTypes.string,
});

export const depositType = PropTypes.shape({
    [DEPOSIT_FIELD.CLIENT_ID]: PropTypes.string,
    [DEPOSIT_FIELD.CLIENT_NAME]: PropTypes.string,
    [DEPOSIT_FIELD.DEPOSIT_AMOUNT]: PropTypes.number,
    [DEPOSIT_FIELD.ORDER_AMOUNT]: PropTypes.number,
    [DEPOSIT_FIELD.PAY_AMOUNT]: PropTypes.number,
});

export const depositHistoryDependedType = PropTypes.shape({
    [DEPOSIT_FIELD.CLIENT_ID]: PropTypes.string.isRequired,
});

export const depositFundsDependedType = PropTypes.shape({
    [DEPOSIT_FIELD.CLIENT_NAME]: PropTypes.string.isRequired,
    [DEPOSIT_FIELD.CLIENT_ID]: PropTypes.string.isRequired,
});

export const depositHistoryType = PropTypes.shape({
    [DEPOSIT_HISTORY_FIELD.ACTION]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.AMOUNT]: PropTypes.number,
    [DEPOSIT_HISTORY_FIELD.CLIENT_USER_ID]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.DATE_TIME]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.DESCRIPTION]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.FIRST_NAME]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.LAST_NAME]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.PATRONYMIC]: PropTypes.string,
});

export const depositManagerType = PropTypes.shape({
    [DEPOSIT_HISTORY_FIELD.CLIENT_USER_ID]: PropTypes.string,
    [DEPOSIT_HISTORY_FIELD.FULLNAME]: PropTypes.string,
});

export const documentType = PropTypes.shape({
    [DOCUMENT_FIELD_NAME.CLIENT_ID]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.CLIENT_NAME]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.CONTRACTOR_ID]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.CONTRACTOR_NAME]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.CONTRACTOR_PHONE]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.DATE_TIME]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.DOCUMENT_TYPE]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.DOWNLOAD_LINK]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.EXT_DOWNLOAD_LINK]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.ORDER_ID]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.ORDER_NAME]: PropTypes.string,
    [DOCUMENT_FIELD_NAME.ORDER_NUM]: PropTypes.number,
});

export const fileType = PropTypes.shape({
    [CONTRACTOR_SCAN_FIELD.BASE_STR]: PropTypes.string,
    [CONTRACTOR_SCAN_FIELD.ERROR_CODE]: PropTypes.string,
    [CONTRACTOR_SCAN_FIELD.ERROR_MESSAGE]: PropTypes.string,
    [CONTRACTOR_SCAN_FIELD.FILE_NAME]: PropTypes.string,
});

export const orderType = PropTypes.shape({
    [ORDER_FIELD.CREATOR_CLIENT_USER_ID]: PropTypes.string,
    [ORDER_FIELD.CREATOR_CLIENT_ID]: PropTypes.string,
    [ORDER_FIELD.CREATE_DATE]: PropTypes.string,
    [ORDER_FIELD.INSURANCE]: PropTypes.bool,
    [ORDER_FIELD.CHECK_IN_REQUIRED]: PropTypes.bool,
    [ORDER_FIELD.CHECK_OUT_REQUIRED]: PropTypes.bool,
    [ORDER_FIELD.CHECK_IN_PHOTO_REQUIRED]: PropTypes.bool,
    [ORDER_FIELD.CHECK_OUT_PHOTO_REQUIRED]: PropTypes.bool,
    [ORDER_FIELD.AMOUNT_FOR_CONTRACTOR]: PropTypes.number,
    [ORDER_FIELD.ACCOMODATION]: PropTypes.bool,
    [ORDER_FIELD.ADDRESS]: PropTypes.string,
    [ORDER_FIELD.AMOUNT]: PropTypes.number,
    [ORDER_FIELD.ARCHIVED]: PropTypes.bool,
    [ORDER_FIELD.CLIENT_ID]: PropTypes.string,
    [ORDER_FIELD.CLIENT_NAME]: PropTypes.string,
    [ORDER_FIELD.CONFIRMED]: PropTypes.bool,
    [ORDER_FIELD.CONTRACT_SUBJECT]: PropTypes.string,
    [ORDER_FIELD.CONTRACT_TYPE]: PropTypes.string,
    [ORDER_FIELD.CONTRACTORS_FOUND]: PropTypes.bool,
    [ORDER_FIELD.CONTRACTORS_HIRED_COUNT]: PropTypes.string,
    [ORDER_FIELD.CONTRACTORS_NEEDED_COUNT]: PropTypes.number,
    [ORDER_FIELD.DESCRIPTION]: PropTypes.string,
    [ORDER_FIELD.HAS_REJECT_PAYMENT]: PropTypes.bool,
    [ORDER_FIELD.INDUSTRY]: PropTypes.string,
    [ORDER_FIELD.LAST_UPDATE_OF_PAYMENT]: PropTypes.string,
    [ORDER_FIELD.NAME]: PropTypes.string,
    [ORDER_FIELD.NOT_CONFIRMED]: PropTypes.bool,
    [ORDER_FIELD.OBJECT_ID]: PropTypes.string,
    [ORDER_FIELD.ORDER_ID]: PropTypes.string,
    [ORDER_FIELD.ORDER_MANAGER]: PropTypes.string,
    [ORDER_FIELD.ORDER_MANAGER_ID]: PropTypes.string,
    [ORDER_FIELD.ORDER_MANAGER_PHONE]: PropTypes.string,
    [ORDER_FIELD.ORDER_NUM]: PropTypes.number,
    [ORDER_FIELD.PAID_MEAL]: PropTypes.bool,
    [ORDER_FIELD.PUBLIC_AVAILABLE]: PropTypes.bool,
    [ORDER_FIELD.STATUS]: PropTypes.string,
    [ORDER_FIELD.VOLUME_OF_WORK]: PropTypes.number,
    [ORDER_FIELD.WORK_END_DATE]: PropTypes.string,
    [ORDER_FIELD.WORK_START_DATE]: PropTypes.string,
    [ORDER_FIELD.WORK_UNIT]: PropTypes.string,
    [ORDER_FIELD.WORK_UNIT_PRICE]: PropTypes.number,
    [ORDER_FIELD.SPECIALTY_LIST]: PropTypes.arrayOf(PropTypes.string),
});

export const paidSumType = PropTypes.shape({
    latestPaymentDatetime: PropTypes.string,
    orderId: PropTypes.string,
    sum: PropTypes.number,
});

export const reportType = PropTypes.shape({
    [FINANCE_FIELD_NAME.ACCEPT_DATE]: PropTypes.string,
    [FINANCE_FIELD_NAME.ARCHIVED]: PropTypes.bool,
    [FINANCE_FIELD_NAME.BANK_CARD_NUMBER]: PropTypes.string,
    [FINANCE_FIELD_NAME.CLIENT_ID]: PropTypes.string,
    [FINANCE_FIELD_NAME.CLIENT_NAME]: PropTypes.string,
    [FINANCE_FIELD_NAME.CLIENT_USER_ID]: PropTypes.string,
    [FINANCE_FIELD_NAME.CONTRACTOR_ID]: PropTypes.string,
    [FINANCE_FIELD_NAME.DATE]: PropTypes.string,
    [FINANCE_FIELD_NAME.DESCRIPTION]: PropTypes.string,
    [FINANCE_FIELD_NAME.LAST_UPDATE]: PropTypes.string,
    [FINANCE_FIELD_NAME.LINK]: PropTypes.string,
    [FINANCE_FIELD_NAME.ORDER_ID]: PropTypes.string,
    [FINANCE_FIELD_NAME.ORDER_NUM]: PropTypes.number,
    [FINANCE_FIELD_NAME.ORDER_WORK_REPORT_ID]: PropTypes.string,
    [FINANCE_FIELD_NAME.PAYMENT_NUMBER]: PropTypes.number,
    [FINANCE_FIELD_NAME.PAYMENT_STATUS]: PropTypes.string,
    [FINANCE_FIELD_NAME.PLATFORM_AMOUNT]: PropTypes.number,
    [FINANCE_FIELD_NAME.REASON_FOR_REFUSAL]: PropTypes.string,
    [FINANCE_FIELD_NAME.RECEIPT_ID]: PropTypes.string,
    [FINANCE_FIELD_NAME.SMZ_AMOUNT]: PropTypes.number,
    [FINANCE_FIELD_NAME.STATUS_OF_THE_TRANSFER]: PropTypes.string,
    [FINANCE_FIELD_NAME.SUM_OF_MONEY]: PropTypes.number,
    [FINANCE_FIELD_NAME.TAX_AMOUNT]: PropTypes.number,
});


export const logDataType = PropTypes.shape({
    paymentNumber: PropTypes.number,
    clientId: PropTypes.string,
    orderId: PropTypes.string,
    description: PropTypes.string,
});

export const orderContractorType = PropTypes.shape({
    [ORDER_CONTRACTOR_FIELD.CLIENT_ID]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.CONTRACT_DATE]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.CONTRACTOR_ID]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.CONTRACTOR_STATUS]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.FIRST_NAME]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.LAST_NAME]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.ORDER_ID]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.PATRONYMIC]: PropTypes.string,
    [ORDER_CONTRACTOR_FIELD.PHONE]: PropTypes.string,
});

export const supportType = PropTypes.shape({
    [SETTINGS_SUPPORT_FIELD_NAME.ANSWER]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.CASE_NUMBER]: PropTypes.number,
    [SETTINGS_SUPPORT_FIELD_NAME.CASE_STATUS]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.CLIENT_ID]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.CONTRACTOR_ID]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.DATE_TIME]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.DESCRIPTION]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.FIRST_NAME]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.LAST_NAME]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.ORDER_ID]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.ORDER_NUM]: PropTypes.number,
    [SETTINGS_SUPPORT_FIELD_NAME.PATRONYMIC]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.PAYMENT_NUMBER]: PropTypes.number,
    [SETTINGS_SUPPORT_FIELD_NAME.PHONE]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.TOPIC_OF_APPEAL]: PropTypes.string,
    [SETTINGS_SUPPORT_FIELD_NAME.TYPE_OF_APPEAL]: PropTypes.string,
});

export const naimixInfoType = PropTypes.shape({
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.ADDRESS]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.BANK_NAME]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.BIK]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.DOCUMENT]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.EMAIL]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.INN]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.INTEGRATED_SOFT]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.KPP]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.KS]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.MAIL_ADDRESS]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.NAME]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.OPERATOR]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.OGRN]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.PHONE]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.POSITION]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.REPRESENTATIVE_DOCUMENT]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.REPRESENTATIVE_FIRST_NAME]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.REPRESENTATIVE_LAST_NAME]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.REPRESENTATIVE_PATRONYMIC]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.RS]: PropTypes.string,
    [SETTINGS_NAIMIX_INFO_FIELD_NAME.WEB_SITE]: PropTypes.string,
});

export const orderEventType = PropTypes.shape({
    [ORDER_HISTORY_FIELD_NAME.CLIENT_ID]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.CONTRACTOR_FIO]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.CONTRACTOR_ID]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.CONTRACTOR_PHONE]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.DATE_TIME]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.EVENT_TYPE]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.FOREMAN_FIO]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.ORDER_ID]: PropTypes.string,
    [ORDER_HISTORY_FIELD_NAME.STATUS]: PropTypes.string,
});

export const departmentFederalMigrationType = PropTypes.shape({
    [FEDERAL_MIGRATION_FIELD.ADDRESS]: PropTypes.string,
    [FEDERAL_MIGRATION_FIELD.DISTRICT]: PropTypes.string,
    [FEDERAL_MIGRATION_FIELD.DISTRICT_ID]: PropTypes.string,
    [FEDERAL_MIGRATION_FIELD.FEDERAL_ID]: PropTypes.string,
    [FEDERAL_MIGRATION_FIELD.NAME_DEPARTMENT]: PropTypes.string,
    [FEDERAL_MIGRATION_FIELD.REGION]: PropTypes.string,
});

export const disputeType = PropTypes.shape({
    [DISPUTE_FIELD.CAUSE_DISPUTE]: PropTypes.string,
    [DISPUTE_FIELD.CLIENT_ID]: PropTypes.string,
    [DISPUTE_FIELD.CONTRACTOR_ID]: PropTypes.string,
    [DISPUTE_FIELD.CREATION_DATE]: PropTypes.string,
    [DISPUTE_FIELD.DESCRIPTION]: PropTypes.string,
    [DISPUTE_FIELD.DISPUTE_ID]: PropTypes.string,
    [DISPUTE_FIELD.RESULT]: PropTypes.string,
    [DISPUTE_FIELD.ORDER_ID]: PropTypes.string,
    [DISPUTE_FIELD.STATUS]: PropTypes.string,
    [DISPUTE_FIELD.TREATMENT_CHANNEL]: PropTypes.string,
    [DISPUTE_FIELD.CLIENT_NAME]: PropTypes.string,
    [DISPUTE_FIELD.CONTRACTOR_FIRST_NAME]: PropTypes.string,
    [DISPUTE_FIELD.CONTRACTOR_LAST_NAME]: PropTypes.string,
    [DISPUTE_FIELD.CONTRACTOR_PATRONYMIC]: PropTypes.string,
    [DISPUTE_FIELD.ORDER_NAME]: PropTypes.string,
    [DISPUTE_FIELD.DISPUTE_STATUS_RESULT]: PropTypes.string,
    [DISPUTE_FIELD.MANAGER_FIRST_NAME]: PropTypes.string,
    [DISPUTE_FIELD.MANAGER_LAST_NAME]: PropTypes.string,
    [DISPUTE_FIELD.MANAGER_PATRONYMIC]: PropTypes.string,
    [DISPUTE_FIELD.ORDER_MANAGER_ID]: PropTypes.string,
    [DISPUTE_FIELD.PHONE]: PropTypes.string,
    [DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT]: PropTypes.number,
    [DISPUTE_FIELD.AMOUNT]: PropTypes.number,
    [DISPUTE_FIELD.ACTIVE]: PropTypes.bool,
});

export const clientPropertiesType = PropTypes.shape({
    [CLIENT_PROPERTIES_FIELD.CURRENT_COMMISSION_RATE]: PropTypes.number,
    [CLIENT_PROPERTIES_FIELD.INSURANCE_AVAILABLE]: PropTypes.bool,
});

export const reasonCompletionOptionType = PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
    option: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            text: PropTypes.string,
        }),
    ),
});