import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {getEndDate, getStartDate} from "../../../../utils/dateFormat";
import {handleFormString} from "../../../../utils/stringHelper";

const useCrowdTaskGroupsFilter = (props) => {
    const {
        pageSize,
        setPagination,
    } = props;

    const initFilter = {
        nameFilter: "",
        creationDateTimeFromFilter: null,
        creationDateTimeToFilter: null,
        taskNumberFilter: "",
        taskNameFilter: "",
    };

    function mapFilterDto(filter) {
        const {
            nameFilter,
            taskNumberFilter,
            taskNameFilter,
            creationDateTimeFromFilter,
            creationDateTimeToFilter,
        } = filter;

        return {
            nameFilter: handleFormString(nameFilter),
            taskNumberFilter: handleFormString(taskNumberFilter),
            taskNameFilter: handleFormString(taskNameFilter),
            creationDateTimeFromFilter: getStartDate(creationDateTimeFromFilter),
            creationDateTimeToFilter: getEndDate(creationDateTimeToFilter),
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Название группы заданий",
                        placeholder: "Введите название группы заданий",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFromFilter",
                        endFieldName: "creationDateTimeToFilter",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNumberFilter",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNameFilter",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
        ];
    }, []);

    return {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        filters,
    };
};

export default useCrowdTaskGroupsFilter;