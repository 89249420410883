import React from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../HelpTooltip";
import NmDropdownV2 from "../../../NmDropdownV2";

import {
    bffClientsSettingsOtherAvailableClientManagersOptionsSelector,
} from "../../../../../ducks/bff/clients/settings/selectors";

export const ClientSettingsAccessToCompany = (props) => {
    const {
        label,
        value,
        onChange,
        className = "",
        name,
    } = props;

    const availableClientManagersOptions = useSelector(bffClientsSettingsOtherAvailableClientManagersOptionsSelector);

    return (
        <NmDropdownV2
            className={className}
            search
            multiple
            tooltip={
                <HelpTooltip
                    hover
                    info
                    className="ms-1"
                    children={"Выберите сотрудников Наймикс с ролями \"Менеджер Наймикс\", \"Координатор Наймикс\", которым будет открыт доступ к данной компании"}
                />
            }
            placeholder="Выберите пользователей"
            label={label}
            name={name}
            options={availableClientManagersOptions}
            value={value}
            onChange={onChange}
        />
    );
};
