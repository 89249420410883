import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_ERROR,
    BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_REQUEST,
    BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
    BFF_FINANCES_NPD_PAYMENTS_CONFIRM_ERROR,
    BFF_FINANCES_NPD_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_NPD_PAYMENTS_CONFIRM_SUCCESS,
    BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_ERROR,
    BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_REQUEST,
    BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_SUCCESS,
    BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_NPD_PAYMENTS_REJECT_ERROR,
    BFF_FINANCES_NPD_PAYMENTS_REJECT_REQUEST,
    BFF_FINANCES_NPD_PAYMENTS_REJECT_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const getPageFinancesNpdPayments = function* ({payload}) {
    try {
        const {
            url,
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_ERROR,
        });
    }
};

const confirmFinancesNpdPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_NPD_PAYMENTS_CONFIRM_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_CONFIRM_ERROR,
        });
    }
};

const rejectFinancesNpdPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_NPD_PAYMENTS_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_REJECT_ERROR,
        });
    }
};

const createReceiptFinancesNpdPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_ERROR,
        });
    }
};

const cancelReceiptFinancesNpdPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_NPD_PAYMENTS_GET_PAGE_REQUEST, getPageFinancesNpdPayments),
        takeEvery(BFF_FINANCES_NPD_PAYMENTS_CONFIRM_REQUEST, confirmFinancesNpdPayments),
        takeEvery(BFF_FINANCES_NPD_PAYMENTS_REJECT_REQUEST, rejectFinancesNpdPayments),
        takeEvery(BFF_FINANCES_NPD_PAYMENTS_CREATE_RECEIPT_REQUEST, createReceiptFinancesNpdPayments),
        takeEvery(BFF_FINANCES_NPD_PAYMENTS_CANCEL_RECEIPT_REQUEST, cancelReceiptFinancesNpdPayments),
    ]);
}