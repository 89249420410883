import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import {ClientSettingsContext} from "../../ContextProvider";

import {getInitialTouched} from "../../../../../../utils/objectHelper";
import {formatAmountWithComma} from "../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../../utils/toastHelper";
import {transformYupFormattedAmountToNumber} from "../../../../../../utils/yupTransforms";
import {getClientWorkTariffValidation} from "../../../utils/getClientWorkTariffValidation";
import {getClientSettingsAdditionalModulesRequestData} from "../utils/getRequestData";
import {isClientSettingsAdditionalModulesChanged} from "../utils/isChangedData";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";
import {CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE, CLIENT_SETTINGS_TAB} from "../../../constants";

import {
    getClientsSettingsAdditionalModules,
    updateClientsSettingsAdditionalModules,
} from "../../../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsAdditionalModulesRetailClientUsersSelector,
    bffClientsSettingsAdditionalModulesSelector,
} from "../../../../../../ducks/bff/clients/settings/selectors";

export const useClientSettingsAdditionalModulesForm = (params) => {
    const {
        clientId,
        onClose,
    } = params;

    const {
        isOpenUnsavedDataConfirm,
        onCancelUnsavedDataConfirm,
        updateChanged,
    } = useContext(ClientSettingsContext);

    const dispatch = useDispatch();
    const retailClientUsersIds = useSelector(bffClientsSettingsAdditionalModulesRetailClientUsersSelector);
    const card = useSelector(bffClientsSettingsAdditionalModulesSelector);
    const {
        retailClientUsers,
        migrantLicensePaymentCommission,
        ...initialValues
    } = card;

    useEffect(() => {
        dispatch(getClientsSettingsAdditionalModules({clientId}));
    }, []);

    const {
        values,
        setFieldValue,
        errors,
        touched,
        setTouched,
        setValues,
        validateForm,
    } = useFormik({
        initialValues: {
            migrantLicensePaymentCommission: typeof migrantLicensePaymentCommission === "number" ?
                formatAmountWithComma(migrantLicensePaymentCommission) :
                "",
            retailClientUsersIds: retailClientUsersIds ? retailClientUsersIds : [],
            ...initialValues,
            edmTariff: card.edmTariff || "",
            clientWorkType: card.clientWorkType || "",
        },
        validationSchema: yup.object().shape({
            edmTariff: yup.string().when("edmAvailable", {
                is: true,
                then: yup.string()
                    .transform(handleFormString)
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
            clientWorkTypeTariff: getClientWorkTariffValidation(),
            clientWorkType: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            migrantLicensePaymentCommission: yup.string().when("migrantLicensePaymentEnabled", {
                is: true,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .transform(transformYupFormattedAmountToNumber)
                    .min(0, "Минимальное значение - 0"),
            }),
        }),
        enableReinitialize: true,
    });

    useEffect(() => {
        const isChangedData = isClientSettingsAdditionalModulesChanged({
            values,
            card,
            retailClientUsersIds,
        });

        updateChanged({
            name: CLIENT_SETTINGS_TAB.ADDITIONAL_MODULES,
            isChangedData,
        });
    }, [card, values]);

    const onSubmit = async ({isClose}) => {
        const errors = await validateForm();

        if (!isEmpty(errors)) {
            await setTouched(getInitialTouched(values, true));

            return;
        }

        const requestData = getClientSettingsAdditionalModulesRequestData(values, card);

        dispatch(updateClientsSettingsAdditionalModules({
            clientId,
            ...requestData,
            onSuccess: () => {
                toastSuccess(CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE);

                if (isOpenUnsavedDataConfirm) {
                    onCancelUnsavedDataConfirm();
                }

                if (isClose) {
                    onClose();

                    return;
                }

                dispatch(getClientsSettingsAdditionalModules({clientId}));
            },
        }));
    };

    const onChange = (event, {name, checked, value}) => {
        if (name === "retailClient") {
            setValues({
                ...values,
                retailClientUsersIds:  checked ? values.retailClientUsersIds : [],
                [name]: checked,
            });

            return;
        }

        if (name === "migrantLicensePaymentEnabled" && !checked) {
            setValues({
                ...values,
                [name]: checked,
                migrantLicensePaymentCommission: "",
            });

            return;
        }

        if (name === "edmAvailable" && !checked) {
            setValues({
                ...values,
                migrantLicensePaymentEnabled: false,
                withoutContract: false,
                [name]: checked,
            });

            return;
        }

        if (name === "withoutContract" && checked) {
            setValues({
                ...values,
                publishOrdersOnWebsite: false,
                smzRegistryPaymentsAvailable: false,
                [name]: checked,
            });

            return;
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    return {
        values,
        errors,
        touched,
        onChange,
        onSubmit,
        isLoading: isEmpty(card),
    };
};