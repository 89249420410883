import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {blueIcon, darkBlueIcon} from "../constants/map";
import {MAP_MARKER_COLOR} from "../constants/objects";

const controller = "/federalMigration";
//*  TYPES  *//

const FEDERAL_MIGRATION_LIST_REQUEST = "FEDERAL_MIGRATION_LIST_REQUEST";
const FEDERAL_MIGRATION_LIST_SUCCESS = "FEDERAL_MIGRATION_LIST_SUCCESS";
const FEDERAL_MIGRATION_LIST_ERROR = "FEDERAL_MIGRATION_LIST_ERROR";

const FEDERAL_MIGRATION_ADD_REQUEST = "FEDERAL_MIGRATION_ADD_REQUEST";
const FEDERAL_MIGRATION_ADD_SUCCESS = "FEDERAL_MIGRATION_ADD_SUCCESS";
const FEDERAL_MIGRATION_ADD_ERROR = "FEDERAL_MIGRATION_ADD_ERROR";

const FEDERAL_MIGRATION_UPDATE_REQUEST = "FEDERAL_MIGRATION_UPDATE_REQUEST";
const FEDERAL_MIGRATION_UPDATE_SUCCESS = "FEDERAL_MIGRATION_UPDATE_SUCCESS";
const FEDERAL_MIGRATION_UPDATE_ERROR = "FEDERAL_MIGRATION_UPDATE_ERROR";

const FEDERAL_MIGRATION_GET_BY_ID_REQUEST = "FEDERAL_MIGRATION_GET_BY_ID_REQUEST";
const FEDERAL_MIGRATION_GET_BY_ID_SUCCESS = "FEDERAL_MIGRATION_GET_BY_ID_SUCCESS";
const FEDERAL_MIGRATION_GET_BY_ID_ERROR = "FEDERAL_MIGRATION_GET_BY_ID_ERROR";

const FEDERAL_MIGRATION_DELETE_REQUEST = "FEDERAL_MIGRATION_DELETE_REQUEST";
const FEDERAL_MIGRATION_DELETE_SUCCESS = "FEDERAL_MIGRATION_DELETE_SUCCESS";
const FEDERAL_MIGRATION_DELETE_ERROR = "FEDERAL_MIGRATION_DELETE_ERROR";

const FEDERAL_MIGRATION_DICT_REQUEST = "FEDERAL_MIGRATION_DICT_REQUEST";
const FEDERAL_MIGRATION_DICT_SUCCESS = "FEDERAL_MIGRATION_DICT_SUCCESS";
const FEDERAL_MIGRATION_DICT_ERROR = "FEDERAL_MIGRATION_DICT_ERROR";

const CLEAR_MIGRATION_STORE = "CLEAR_MIGRATION_STORE";

const FEDERAL_MIGRATION_GET_BY_ORDER_ID_REQUEST = "FEDERAL_MIGRATION_GET_BY_ORDER_ID_REQUEST";
const FEDERAL_MIGRATION_GET_BY_ORDER_ID_SUCCESS = "FEDERAL_MIGRATION_GET_BY_ORDER_ID_SUCCESS";
const FEDERAL_MIGRATION_GET_BY_ORDER_ID_ERROR = "FEDERAL_MIGRATION_GET_BY_ORDER_ID_ERROR";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    progressAction: false,
    progressById: false,
    federalMigrationList: {
        totalCount: 0,
        pageData: {
            pageSize: 25,
            pageNum: 1,
        },
        list: [],
    },
    card: {},
    federalRegionDict: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case FEDERAL_MIGRATION_GET_BY_ID_REQUEST:
        return {
            ...state,
            progressById: true,
        };
    case FEDERAL_MIGRATION_ADD_REQUEST:
    case FEDERAL_MIGRATION_UPDATE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case FEDERAL_MIGRATION_UPDATE_SUCCESS:
    case FEDERAL_MIGRATION_ADD_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case CLEAR_MIGRATION_STORE:
        return {
            ...state,
            ...payload,
        };
    case FEDERAL_MIGRATION_LIST_REQUEST:
        return {
            ...state,
            federalMigrationList: {
                ...state.federalMigrationList,
                pageData: payload,
            },
            progress: true,
        };
    case FEDERAL_MIGRATION_GET_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progressById: false,
        };
    case FEDERAL_MIGRATION_LIST_SUCCESS:
        const {
            totalCount,
            migrations: list,
            fetchedByCoords,
        } = payload;

        return {
            ...state,
            fetchedByCoords,
            federalMigrationList: {
                ...state.federalMigrationList,
                totalCount,
                list,
            },
            progress: false,
        };
    case FEDERAL_MIGRATION_DICT_SUCCESS:
        return {
            ...state,
            federalRegionDict: payload,
        };
    case FEDERAL_MIGRATION_UPDATE_ERROR:
    case FEDERAL_MIGRATION_LIST_ERROR:
        return {
            ...state,
            error: payload,
        };
    case FEDERAL_MIGRATION_GET_BY_ORDER_ID_REQUEST:
        return {
            ...state,
            progressById: true,
        };
    case FEDERAL_MIGRATION_GET_BY_ORDER_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progressById: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function clearStoreField(payload) {
    return {
        type: CLEAR_MIGRATION_STORE,
        payload,
    };
}

export function getListMigrations(payload) {
    return {
        type: FEDERAL_MIGRATION_LIST_REQUEST,
        payload,
    };
}

export function updateFederalMigration(payload) {
    return {
        type: FEDERAL_MIGRATION_UPDATE_REQUEST,
        payload,
    };
}

export function deleteFederalMigration(payload) {
    return {
        type: FEDERAL_MIGRATION_DELETE_REQUEST,
        payload,
    };
}

export function addFederalMigration(payload) {
    return {
        type: FEDERAL_MIGRATION_ADD_REQUEST,
        payload,
    };
}

export function getByIdMigration(payload) {
    return {
        type: FEDERAL_MIGRATION_GET_BY_ID_REQUEST,
        payload,
    };
}

export function getFederalMigrationDict() {
    return {
        type: FEDERAL_MIGRATION_DICT_REQUEST,
    };
}

export function getFederalMigrationByOrderId(payload) {
    return {
        type: FEDERAL_MIGRATION_GET_BY_ORDER_ID_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const federalMigrationSelector = state => state.federalMigration;
export const getFederalMigrationOptionsSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {list}}) => {
    return list.map(item => {
        return {
            key: item.federalId,
            text: item.nameDepartment,
            value: item.federalId,
        };
    });
});

// Для того чтобы определять что метки ОВМ получены именно для метки на карте
export const federalMigrationFetchedByCoordsSelector = createSelector(federalMigrationSelector, ({fetchedByCoords}) => fetchedByCoords);

export const federalMigrationMarkersOVMSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {list}}) => {
    const nearestMigrations = list.slice(0, 10);

    return nearestMigrations.map((value, index) => {
        return {
            nameDepartment: value.nameDepartment,
            icon: index === 0 ? darkBlueIcon : blueIcon,
            federalId: value.federalId,
            lat: value.latitude,
            lng: value.longitude,
        };
    });
});

export const nearestMigrationOVMSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {list}}) => {
    if (!list.length || !list[0]) {
        return null;
    }

    return list[0].federalId;
});

export const nearestMigrationOVMCardSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {list}}) => {
    if (!list.length || !list[0]) {
        return {};
    }

    return list[0];
});

export const federalMigrationListProgressSelector = createSelector(federalMigrationSelector, ({progress}) => progress);

export const getFederalMigrationListSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {list}}) => {
    return list;
});

export const getFederalMigrationCardSelector = createSelector(federalMigrationSelector, ({card}) => {
    return card;
});

export const federalMigrationProgressActionSelector = createSelector(federalMigrationSelector, ({progressAction}) => {
    return progressAction;
});

export const federalMigrationProgressByIdSelector = createSelector(federalMigrationSelector, ({progressById}) => {
    return progressById;
});

export const getFederalMigrationTotalPagesSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {totalCount, pageData}}) => {
    return getTotalPages(totalCount, pageData.pageSize);
});

export const getFederalMigrationTotalCountSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {totalCount}}) => {
    return totalCount;
});

export const getFederalMigrationPgeDataSelector = createSelector(federalMigrationSelector, ({federalMigrationList: {pageData}}) => {
    return pageData;
});

export const getFederalRegionDictSelector = createSelector(federalMigrationSelector, ({federalRegionDict}) => federalRegionDict);

export const getFederalRegionOptionsSelector = createSelector(federalMigrationSelector, ({federalRegionDict}) => {
    return dictionaryToOptions(federalRegionDict);
});


//*  SAGA  *//

//POST /api/federalMigration/getListMigrations
export const getListMigrationsSaga = function* ({payload}) {
    try {
        const {
            longitudeFilter,
            latitudeFilter,
        } = payload;

        const result = yield request.post(`${controller}/getListMigrations/`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FEDERAL_MIGRATION_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FEDERAL_MIGRATION_LIST_SUCCESS,
            payload: {
                ...result,
                fetchedByCoords: {
                    lng: longitudeFilter,
                    lat: latitudeFilter,
                },
            },
        });
    } catch (error) {
        yield put({
            type: FEDERAL_MIGRATION_LIST_ERROR,
            payload: error.message,
        });
    }
};

//POST /api/federalMigration/add

export const addSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FEDERAL_MIGRATION_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Подразделение успешно добавлено");

        yield put({
            type: FEDERAL_MIGRATION_ADD_SUCCESS,
            payload: result,
        });

        const state = yield select();
        yield put(getListMigrations(getFederalMigrationPgeDataSelector(state)));
    } catch (error) {
        yield put({
            type: FEDERAL_MIGRATION_ADD_ERROR,
            payload: error.message,
        });
    }
};

//POST /api/federalMigration/update

export const updateSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FEDERAL_MIGRATION_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Подразделение успешно изменено");

        yield put({
            type: FEDERAL_MIGRATION_UPDATE_SUCCESS,
            payload: result,
        });

        const state = yield select();
        yield put(getListMigrations(getFederalMigrationPgeDataSelector(state)));
    } catch (error) {
        yield put({
            type: FEDERAL_MIGRATION_UPDATE_ERROR,
            payload: error.message,
        });
    }
};

//POST /api/federalMigration/getById

export const getByIdSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            federalId,
            onSuccess = () => {},
        } = payload;

        const result = yield request.get(`${controller}/getById?federalId=${federalId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FEDERAL_MIGRATION_GET_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: FEDERAL_MIGRATION_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FEDERAL_MIGRATION_GET_BY_ID_ERROR,
            payload: error.message,
        });
    }
};

//DELETE /api/federalMigration/delete

export const deleteSaga = function* (action) {
    try {
        const {payload} = action;
        const {federalId} = payload;

        const result = yield request.delete(`${controller}/delete?federalId=${federalId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: FEDERAL_MIGRATION_DELETE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Подразделение ФМС успешно удалено");

        const state = yield select();

        yield put(getListMigrations(getFederalMigrationPgeDataSelector(state)));

        yield put({type: FEDERAL_MIGRATION_DELETE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: FEDERAL_MIGRATION_DELETE_ERROR, payload: error});
    }
};


//GET /api/orders/dicts/federalRegion
export const getFederalMigrationDictSaga = function* (action) {
    try {
        const result = yield request.get("orders/dicts/federalRegion");
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FEDERAL_MIGRATION_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FEDERAL_MIGRATION_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FEDERAL_MIGRATION_DICT_ERROR,
            payload: error.message,
        });
    }
};

//GET /api/federalMigration/getByOrderId
export const getFederalMigrationByOrderIdSaga = function* ({payload}) {
    try {
        const {
            clientId,
            orderId,
        } = payload;

        const result = yield request.get(`${controller}/getByOrderId?clientId=${clientId}&orderId=${orderId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FEDERAL_MIGRATION_GET_BY_ORDER_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FEDERAL_MIGRATION_GET_BY_ORDER_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FEDERAL_MIGRATION_GET_BY_ORDER_ID_ERROR,
            payload: error.message,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FEDERAL_MIGRATION_ADD_REQUEST, addSaga),
        takeEvery(FEDERAL_MIGRATION_LIST_REQUEST, getListMigrationsSaga),
        takeEvery(FEDERAL_MIGRATION_UPDATE_REQUEST, updateSaga),
        takeEvery(FEDERAL_MIGRATION_DELETE_REQUEST, deleteSaga),
        takeEvery(FEDERAL_MIGRATION_GET_BY_ID_REQUEST, getByIdSaga),
        takeEvery(FEDERAL_MIGRATION_DICT_REQUEST, getFederalMigrationDictSaga),
        takeEvery(FEDERAL_MIGRATION_GET_BY_ORDER_ID_REQUEST, getFederalMigrationByOrderIdSaga),
    ]);
}
