import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {FILTER} from "../../../../../components/ActualComponents/Filter";
import {updateRecruitmentVacancyStore} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {bffRecruitmentIsVacancyResponsesPageActiveSelector} from "../../../../../ducks/bff/recruitment/vacancy/selectors";

import {useFilter} from "../../../../../hooks/useFilter";
import {PATTERN_NUMBER} from "../../../../deposit/client-list/hooks/useGetlDepositData";

import {dadataValueUniqCitySettlementFilter} from "../../../../../utils/dadata";
import {
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../../utils/dateFormat";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";
import {removePhoneMask} from "../../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../../utils/stringHelper";

import {GENDER_OPTIONS_WITH_ALL_OPTION} from "../../../../../constants/contractorInfo";
import {EMPTY_OPTION_KEY} from "../../../../../constants/dropdown";
import {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../constants";

import {getCitizenshipOptionsSelector} from "../../../../../ducks/contractor";

const useRecruitmentCardFilter = () => {
    const dispatch = useDispatch();

    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);

    const isResponsesPage = useSelector(bffRecruitmentIsVacancyResponsesPageActiveSelector);

    const initFilter = useMemo(() => {
        if (isResponsesPage) {
            return {
                dateFrom: null,
                dateTo: null,
                name: "",
                phone: "",
                email: "",
                gender: EMPTY_OPTION_KEY,
                ageFrom: "",
                ageTo: "",
                citizenships: [],
                addressFiasIds: [],
                specialityIds: [],
                responseSources: [],
            };
        }

        return {
            dateFrom: null,
            dateTo: null,
            name: "",
            phone: "",
            email: "",
            gender: EMPTY_OPTION_KEY,
            ageFrom: "",
            ageTo: "",
            citizenships: [],
            specialityIds: [],
            addressFiasIds: [],
        };
    }, [
        isResponsesPage,
    ]);

    const filters = useMemo(() => {
        const generalFilters = [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "name",
                        label: "ФИО",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "phone",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "email",
                        label: "E-mail",
                        placeholder: "Введите e-mail",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: GENDER_OPTIONS_WITH_ALL_OPTION,
                        label: "Пол",
                        name: "gender",
                        placeholder: "Все",
                    },
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "ageFrom",
                        nameEnd: "ageTo",
                        label: "Возраст",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: PATTERN_NUMBER,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "citizenships",
                        label: "Гражданство",
                        placeholder: "Выберите гражданство",
                        search: true,
                        multiple: true,
                        options: citizenshipOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        name: "addressFiasIds",
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        filter: dadataValueUniqCitySettlementFilter,
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        label: "Город",
                        placeholder: "Выберите город",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        search: true,
                        name: "specialityIds",
                        multiple: true,
                        label: "Вид деятельности",
                        placeholder: "Введите вид деятельности",
                    },
                ],
            },
        ];

        if (isResponsesPage) {
            return [
                {
                    row: [
                        {
                            component: FILTER.DATE_RANGE,
                            startFieldName: "dateFrom",
                            endFieldName: "dateTo",
                            isClearable: true,
                            label: "Дата отклика",
                        },
                    ],
                },
                ...generalFilters,
                {
                    row: [
                        {
                            component: FILTER.DROPDOWN,
                            size: "lg",
                            label: "Источник отклика",
                            placeholder: "Показать все",
                            name: "responseSources",
                            multiple: true,
                            isClearable: true,
                            search: true,
                            options: dictionaryToOptions(RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE),
                        },
                    ],
                },
                // {
                //     row: [
                //         {
                //             component: FILTER.INPUT,
                //             name: "responseId",
                //             label: "Идентификатор отклика",
                //             placeholder: 'Введите идентификатор отклика',
                //         },
                //     ],
                // },
            ];
        }

        return [
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "dateFrom",
                        endFieldName: "dateTo",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
            ...generalFilters,
        ];
    }, [
        citizenshipOptions,
        isResponsesPage,
    ]);

    function mapFilterDto(filter) {
        const generalFilters = {
            name: handleFormString(filter.name),
            phone: filter.phone ? removePhoneMask(filter.phone) : undefined,
            email: handleFormString(filter.email),
            ageFrom: handleNumber(filter.ageFrom),
            ageTo: handleNumber(filter.ageTo),
            gender: filter.gender === EMPTY_OPTION_KEY ? undefined : filter.gender,
            specialityIds: !isEmpty(filter.specialityIds) ? filter.specialityIds : undefined,
            citizenships: !isEmpty(filter.citizenships) ? filter.citizenships : undefined,
            addressFiasIds: !isEmpty(filter.addressFiasIds) ? filter.addressFiasIds : undefined,
        };

        if (isResponsesPage) {
            return {
                dateFrom: getStartFilterDateWithTimeInUtc(filter.dateFrom),
                dateTo: getEndFilterDateWithTimeInUtc(filter.dateTo),
                ...generalFilters,
                responseSources: filter.responseSources?.length ? filter.responseSources : undefined,
            };
        }

        return {
            dateFrom: getStartFilterDateWithTimeInUtc(filter.dateFrom),
            dateTo: getEndFilterDateWithTimeInUtc(filter.dateTo),
            ...generalFilters,
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        setFilter,
    } = useFilter({
        initFilter,
        pageSize: 25,
        mapFilterDto,
        setFilterData: (filterData) => {
            dispatch(updateRecruitmentVacancyStore({
                tabFilterData: {filterData, isSearch},
            }));
        },
    });

    return {
        onClear,
        onSearch,
        filter,
        filterData,
        filters,
        setFilter,
    };
};

export default useRecruitmentCardFilter;