import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/documentsexport";
//*  TYPES  *//

const GET_DOCUMENTS_EXPORT_PAGE_REQUEST = "GET_DOCUMENTS_EXPORT_PAGE_REQUEST";
const GET_DOCUMENTS_EXPORT_PAGE_SUCCESS = "GET_DOCUMENTS_EXPORT_PAGE_SUCCESS";
const GET_DOCUMENTS_EXPORT_PAGE_ERROR = "GET_DOCUMENTS_EXPORT_PAGE_ERROR";

const ADD_DOCUMENTS_EXPORT_REQUEST = "ADD_DOCUMENTS_EXPORT_REQUEST";
const ADD_DOCUMENTS_EXPORT_SUCCESS = "ADD_DOCUMENTS_EXPORT_SUCCESS";
const ADD_DOCUMENTS_EXPORT_ERROR = "ADD_DOCUMENTS_EXPORT_ERROR";

const ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST = "ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST";
const ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS = "ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS";
const ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR = "ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR";

const ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST = "ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST";
const ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS = "ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS";
const ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR = "ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR";

//*  INITIAL STATE  *//

const initial = {
    documentsExportPage: [],
    progressPage: false,
    totalCount: 0,
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_DOCUMENTS_EXPORT_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case GET_DOCUMENTS_EXPORT_PAGE_SUCCESS:
        const {
            documentsExportModels,
            totalCount,
            fileName,
        } = payload;
        return {
            ...state,
            progressPage: false,
            totalCount,
            documentsExportPage: documentsExportModels,
            fileName,
        };
    case GET_DOCUMENTS_EXPORT_PAGE_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getDocumentsExportPage(payload) {
    return {
        type: GET_DOCUMENTS_EXPORT_PAGE_REQUEST,
        payload,
    };
}

export function addDocumentsExport(payload) {
    return {
        type: ADD_DOCUMENTS_EXPORT_REQUEST,
        payload,
    };
}

export function addRegistryPaymentsDocumentsExport(payload) {
    return {
        type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function addPatentsRegistryPaymentsDocumentsExport(payload) {
    return {
        type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const documentsExport = state => state.documentsExport;
export const getDocumentExportPageSelector = createSelector(documentsExport, ({documentsExportPage}) => documentsExportPage);
export const getDocumentExportTotalPagesSelector = createSelector(documentsExport, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getDocumentExportTotalCountSelector = createSelector(documentsExport, ({totalCount}) => totalCount);

//*  SAGA  *//

//POST /api/documentsexport/getFinancePage
export const getDocumentsExportPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DOCUMENTS_EXPORT_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_DOCUMENTS_EXPORT_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: GET_DOCUMENTS_EXPORT_PAGE_ERROR, payload: error});
    }
};

export const addDocumentsExportSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post("job/export/addDocExport", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_DOCUMENTS_EXPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: ADD_DOCUMENTS_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_DOCUMENTS_EXPORT_ERROR, payload: error.message});
    }
};

export const addRegistryPaymentsDocumentsExportSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/registry/payments/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR, payload: error.message});
    }
};

export const addPatentsRegistryPaymentsDocumentsExportSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/patents/registry/payments/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_DOCUMENTS_EXPORT_PAGE_REQUEST, getDocumentsExportPageSaga),
        takeEvery(ADD_DOCUMENTS_EXPORT_REQUEST, addDocumentsExportSaga),
        takeEvery(ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST, addRegistryPaymentsDocumentsExportSaga),
        takeEvery(ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST, addPatentsRegistryPaymentsDocumentsExportSaga),
    ]);
}
