import {useRef, useState} from "react";
import {useSelector} from "react-redux";

export const useAppContextPageData = () => {
    const containerRef = useRef(null);

    const pathname = useSelector((state) => state.router.location.pathname);

    const [scrollPosition, setScrollPosition] = useState(null);

    return {
        containerRef,
        scrollPosition,
        setScrollPosition,
        pathname,
    };
};