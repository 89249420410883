
import {useDispatch} from "react-redux";

import {useModal} from "../../../../hooks/useModal";

import {toastSuccess} from "../../../../utils/toastHelper";

import {updateKedoArchiveStatusStaff} from "../../../../ducks/kedo/staff/actionCreators";

export const useKedoStaffRegistryAction = (params) => {
    const {
        fetchList,
        archiveFilter,
    } = params;

    const dispatch = useDispatch();

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const updateArchiveStatus = () => {
        const {
            kedoStaffId,
            clientId,
        } = confirmData;

        dispatch(updateKedoArchiveStatusStaff({
            kedoStaffId,
            clientId,
            archive: !archiveFilter,
            onSuccess: () => {
                fetchList();
                onCloseConfirm();

                if (archiveFilter) {
                    toastSuccess("Сотрудник успешно перенесен в архив");

                    return;
                }

                toastSuccess("Сотрудник успешно перенесен в архив");
            },
        }));
    };

    return {
        updateArchiveStatus,
        onOpenConfirm,
        confirmData,
        isOpenConfirm,
        onCloseConfirm,
    };
};
