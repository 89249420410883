import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

export const useRecruitmentAccessControlRecruitersGroupsFilter = (params) => {
    const {
        setPagination,
        pageSize,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            name: "",
        },
        pageSize,
        setPagination,
    });

    const filters = [
        {
            row: [
                {
                    component: FILTER.INPUT,
                    label: "Название группы",
                    placeholder: "Введите название группы",
                    name: "name",
                    maxLength: 100,
                },
            ],
        },
    ];

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    };
};