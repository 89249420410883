import {useDispatch, useSelector} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getDefaultPageInitialState} from "../../../../utils/store";

import {isClientUser} from "../../../../constants/roles";

import {
    cancelClientsKedoExportById,
    getClientsKedoExportsPage,
} from "../../../../ducks/bff/clients/kedo/exports/services";
import {
    cancelCompaniesKedoExportById,
    getCompaniesKedoExportsPage,
} from "../../../../ducks/bff/companies/company/kedo/exports/services";
import {updateExportsStore} from "../../../../ducks/kedo/exports/actionCreators";
import {
    kedoExportsListSelector,
    kedoExportsProgressActionSelector,
    kedoExportsProgressSelector,
    kedoExportsTotalCountSelector,
    kedoExportsTotalPagesSelector,
} from "../../../../ducks/kedo/exports/selectors";

export const useKedoExportsBff = () => {
    const dispatch = useDispatch();

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const list = useSelector(kedoExportsListSelector);
    const totalCount = useSelector(kedoExportsTotalCountSelector);
    const totalPages = useSelector(kedoExportsTotalPagesSelector);
    const progress = useSelector(kedoExportsProgressSelector);
    const progressAction = useSelector(kedoExportsProgressActionSelector);

    const fetchPage = (data) => {
        if (isClientRole) {
            dispatch(getClientsKedoExportsPage(data));

            return;
        }

        dispatch(getCompaniesKedoExportsPage(data));
    };

    const cancelExportById = (data) => {
        if (isClientRole) {
            dispatch(cancelClientsKedoExportById(data));

            return;
        }

        dispatch(cancelCompaniesKedoExportById(data));
    };

    const updateStore = (data) => {
        dispatch(updateExportsStore(data));
    };

    const clearStore = () => {
        const initialState = getDefaultPageInitialState();

        dispatch(updateExportsStore(initialState));
    };

    return {
        list,
        totalCount,
        totalPages,
        progress,
        progressAction,
        fetchPage,
        cancelExportById,
        updateStore,
        clearStore,
    };
};