import React, {Component} from "react";

import {getCount} from "../../../utils/mathHelper";

import "./style.sass";

class MenuCounter extends Component {

    get classNames() {
        const {
            count,
            children,
        } = this.props;

        const num = typeof count === "number" ? String(count) : count;
        
        let className = "menu-counter";
        
        if (children) {
            className = `${className} menu-counter_child`;
        }

        if (num.length > 2) {
            return `${className} menu-counter_big`;
        }

        return `${className} menu-counter_small`;
    }

    render() {
        const {
            className = "",
            count,
        } = this.props;

        return (
            <div className={`${this.classNames} ${className}`}>
                {getCount(count)}
            </div>
        );
    }
};

export default MenuCounter;