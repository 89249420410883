import React from "react";
import Media, {MediaQueryObject} from "react-media";
import {isEmpty} from "lodash";

import ContextMenu, { IContextMenu } from "../ContextMenu";

import {OptionType} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";

interface IContextMenuMobile extends IContextMenu {
    configOptions?: { [name: string]: OptionType[] },
    mediaQueries: { [id: string]: MediaQueryObject }
}

const ContextMenuMobile = (props: IContextMenuMobile) => {
    const {
        mediaQueries,
        onClickItem,
        configOptions,
        className,
    } = props;

    return (
        <Media queries={mediaQueries}>
            {
                (matches) => {
                    const keys = Object.keys(matches);
                    const values = Object.values(matches);
                    const currentQueryIndex = values.findIndex(value => (value === true));

                    return configOptions && (!isEmpty(configOptions[keys[currentQueryIndex]]) || !isEmpty(configOptions.other)) ?
                        <ContextMenu
                            size="xl"
                            inline={false}
                            onClickItem={onClickItem}
                            options={configOptions[keys[currentQueryIndex]] || configOptions.other}
                            className={className}
                        /> :
                        null;
                }
            }
        </Media>
    );
};

export default ContextMenuMobile;