import * as Yup from "yup";

import {removePhoneMask} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {validatePhone} from "../../../../utils/validate";

import {emailReg, requiredMessage} from "../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

function isNotNullOrWhitespace(value) {
    return !isNullOrWhitespace(value);
}

export const simpleContractorValidationScheme = Yup.object().shape({
    phone: Yup.string()
        .required(requiredMessage)
        .when("locatedOutsideRussia", {
            is: false,
            then: Yup.string()
                .transform((value) => {
                    return removePhoneMask(value).trim();
                })
                .length(11, "Номер телефона должен состоять из 11 цифр"),
        })
        .when("locatedOutsideRussia", {
            is: true,
            then: Yup.string()
                .transform(removePhoneMask)
                .test("checkPhone", "Номер телефона должен состоять из 10-15 цифр", validatePhone),
        }),
    lastName: Yup.string()
        .required(requiredMessage)
        .max(50, "Не более 50 символов")
        .test("isNotNullOrWhitespace", requiredMessage, isNotNullOrWhitespace)
        .matches(/^[-а-яА-ЯёЁa-zA-Z\s]+$/, "Недопустимые символы в фамилии"),
    firstName: Yup.string()
        .required(requiredMessage)
        .max(50, "Не более 50 символов")
        .test("isNotNullOrWhitespace", requiredMessage, isNotNullOrWhitespace)
        .matches(/^[-а-яА-ЯёЁa-zA-Z\s]+$/, "Недопустимые символы в имени"),
    patronymic: Yup.string()
        .when("patronymic", {
            is: (value) => value?.length >= 1,
            then: Yup.string()
                .max(50, "Не более 50 символов")
                .test("isNotNullOrWhitespace", requiredMessage, isNotNullOrWhitespace)
                .matches(/^[-а-яА-ЯёЁa-zA-Z\s]+$/, "Недопустимые символы в отчестве"),
        }),
    email: Yup.string()
        .max(255, "Не более 255 символов")
        .matches(emailReg, "Введен некорректный email"),
    workAddressFiasId: Yup.string()
        .required(requiredMessage),
    mainSpecialityId: Yup.string()
        .required(requiredMessage),
    phoneCodeId: Yup.string()
        .nullable()
        .when("locatedOutsideRussia", {
            is: true,
            then: Yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
}, [
    ["patronymic", "patronymic"], // Циклическая зависимость, т. к. поле требуется для проверки (.when) самого себя
]);

export const blockContractorValidationScheme = Yup.object({...simpleContractorValidationScheme}).shape({
    blockedReason: Yup.string()
        .required(requiredMessage),
});