import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {ProjectsObjectsCheckboxList} from "../../../../../components/ProjectsObjectsCheckboxList";

import {useClientBrandBff} from "../../hooks/useBff";

import {updateClientBrandsStore} from "../../../../../ducks/client/brands/actionCreators";
import {
    clientBrandsObjectsSelector,
    clientBrandsProgressActionSelector,
    clientBrandsProjectsAndObjectsProgressSelector,
    clientBrandsProjectsAndObjectsSelector,
    clientBrandsProjectsSelector,
} from "../../../../../ducks/client/brands/selectors";

export const ClientAssignBrands = (props) => {
    const {
        onClose,
        clientId,
        brandId,
        brandName,
        title,
        buttonName,
        isRemoving,
        checkError,
    } = props;

    const {
        getAssignBrandsObjectsPage,
        getAssignBrandsProjectsPage,
        saveAssignBrandsObjects,
        getAssignBrandsProjectsObjectsList,
    } = useClientBrandBff();

    const objects = useSelector(clientBrandsObjectsSelector);
    const projects = useSelector(clientBrandsProjectsSelector);
    const progressAction = useSelector(clientBrandsProgressActionSelector);
    const projectsAndObjects = useSelector(clientBrandsProjectsAndObjectsSelector);
    const progressSearch = useSelector(clientBrandsProjectsAndObjectsProgressSelector);

    const dispatch = useDispatch();

    const updateObjectsCallback = () => {
        dispatch(updateClientBrandsStore({
            objects: {
                lists: {},
                totalCount: 0,
                pageData: {},
            },
            error: null,
        }));
    };

    const clearNodesFromStore = () => {
        dispatch(updateClientBrandsStore({
            projects: {
                clientInfo: {},
                list: [],
                totalCount: 0,
                pageData: {},
            },
            objects: {
                lists: {},
                totalCount: 0,
                pageData: {},
            },
            projectsAndObjects: {},
        }));
    };

    const fetchProjects = ({pageNum, isSearchCleared, onSuccess, pageSize}) => {
        getAssignBrandsProjectsPage({
            pageNum,
            brandId,
            isSearchCleared,
            pageSize,
            clientId,
            onSuccess,
        });
    };

    const fetchObjects = ({projectId, pageNum, onSuccess, pageSize}) => {
        getAssignBrandsObjectsPage({
            clientId,
            brandId,
            projectId,
            pageSize,
            pageNum,
            onSuccess,
        });
    };

    const onSubmitForm = ({requestData, onSuccess}) => {
        const {
            objectsParams,
            projectsParams,
        } = requestData;

        if (isRemoving) {
            const removeCallback = (item) => {
                return {
                    ...item,
                    boolParam: false,
                };
            };

            saveAssignBrandsObjects({
                clientId,
                brandId,
                isRemoving,
                objectsParams: objectsParams.map(removeCallback),
                projectsParams: projectsParams.map(removeCallback),
                onSuccess,
                checkError,
            });

            return;
        }

        saveAssignBrandsObjects({
            clientId,
            brandId,
            brandName,
            objectsParams: objectsParams.filter(({boolParam}) => boolParam),
            projectsParams: projectsParams.filter(({boolParam}) => boolParam),
            onSuccess,
            checkError,
        });
    };

    const fetchProjectsAndObjects = (projectObjectNameFilter) => {
        getAssignBrandsProjectsObjectsList({
            clientId,
            brandId,
            projectObjectNameFilter,
        });
    };

    return (
        <ProjectsObjectsCheckboxList
            isVisibleClient={false}
            title={title}
            onClose={onClose}
            updateObjectsCallback={updateObjectsCallback}
            clearNodesFromStore={clearNodesFromStore}
            fetchObjects={fetchObjects}
            fetchProjectsAndObjects={fetchProjectsAndObjects}
            onSubmitForm={onSubmitForm}
            fetchProjects={fetchProjects}
            clientId={clientId}
            objects={objects}
            projects={projects}
            progressAction={progressAction}
            projectsAndObjects={projectsAndObjects}
            progressSearch={progressSearch}
            buttonName={buttonName}
            submitConfirmContent={
                isRemoving
                    ? "Вы подтверждаете удаление брендов в выбранных объектах?"
                    : null
            }
            suggestion={
                isRemoving
                    ? "Выберете проекты или объекты для удаления в них бренда. После удаления на объекте будет отображаться бренд компании"
                    : null
            }
        />
    );
};