import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {
    migrationStatusOptionsSelector,
} from "../../ducks/contractor";

const MigrationStatusFilter = (props) => {
    const options = useSelector(migrationStatusOptionsSelector);

    return (
        <NmDropdownV2
            size="lg"
            placeholder="Показать все"
            label="Миграционный статус"
            multiple
            name="migrationStatusesFilter"
            options={options}
            {...props}
        />
    );
};

export default MigrationStatusFilter;