import React from "react";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";

import {getContractorSkipInnPassportConfirmContent} from "./utils/getContent";

const SkipCheckInnPassportConfirm = (props) => {
    const {
        innPassportDataValidStatus,
        submit,
        onClose,
    } = props;

    const {
        text,
        submitBtnContent,
        cancelBtnContent,
        cancelBtnColor,
    } = getContractorSkipInnPassportConfirmContent({
        innPassportDataValidStatus,
    });

    return (
        <NmModal
            size="md"
            contentAlign="center"
            contentBreakSpaces
            footer={
                <ApplyButtons
                    align="center"
                    submitBtnContent={submitBtnContent}
                    cancelBtnContent={cancelBtnContent}
                    cancelBtnColor={cancelBtnColor}
                    onClose={onClose}
                    submit={submit}
                />
            }
            onClose={onClose}
        >
            {text}
        </NmModal>
    );
};

export default SkipCheckInnPassportConfirm;