import React from "react";

import NmRadioV2 from "../../../../../../../components/ActualComponents/NmRadioV2";
import Text from "../../../../../../../components/ActualComponents/Text";

import {COLOR} from "../../../../../../../constants/color";
import {ORDER_WORK_REPORT_TYPE} from "../../../../../../../constants/finance";

const OrderEditPreConditionsPaymentType = (props) => {
    const {
        onChange,
        className,
        formData,
    } = props;

    return (
        <div className={`d-flex flex-column ${className}`}>
            <Text
                color={COLOR.SECONDARY_70}
                level="3"
                className="mb-2"
            >
                Выплаты по заказу будут осуществляться исполнителям
            </Text>
            <div className="d-flex align-items-center">
                <NmRadioV2
                    disabled={true}
                    label="НПД"
                    checked={formData.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ}
                    name="orderContractPaymentType"
                    value={ORDER_WORK_REPORT_TYPE.SMZ}
                    onChange={onChange}
                />
                <NmRadioV2
                    disabled={true}
                    label="НДФЛ"
                    className="ms-4 ms-md-5"
                    checked={formData.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL}
                    name="orderContractPaymentType"
                    value={ORDER_WORK_REPORT_TYPE.CIVIL}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default OrderEditPreConditionsPaymentType;