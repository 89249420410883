import * as Yup from "yup";

import {getClientWorkTariffValidation} from "../../../../../components/ActualComponents/ClientSettings/utils/getClientWorkTariffValidation";
import {removeEinMask, removePhoneMask} from "../../../../../utils/stringFormat";
import {
    validateIndividualInn,
    validateInn,
} from "../../../../../utils/validate";
import {transformYupFormattedAmountToNumber} from "../../../../../utils/yupTransforms";

import {CLIENT_TYPE_CODE} from "../../../../../constants/dicts";
import {
    emailReg,
    nameWithLatinReg,
    regBrand,
    regClientName,
    regFullName,
    requiredMessage,
} from "../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

function isValidInn(value, context) {
    const clientType = context.parent.clientType;
    let message = null;

    if (clientType === CLIENT_TYPE_CODE.RUSSIAN_LEGAL_ENTITY) {
        message = validateInn(value);
    } else if (clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR) {
        message = validateIndividualInn(value);
    }

    if (message) {
        return context.createError({message});
    }

    return true;
}

export const newClientValidationScheme = Yup.object().shape({
    clientWorkType: Yup.string().required(requiredMessage),
    clientWorkTypeTariff: getClientWorkTariffValidation(),
    clientType: Yup.string().required(requiredMessage),
    inn: Yup.string()
        .when("clientType", {
            is: (clientType) => clientType === CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY,
            then: Yup.string()
                .required(requiredMessage)
                .transform(removeEinMask)
                .length(9, "Количество введеных знаков должно быть 9"),
            otherwise: Yup.string()
                .test({
                    name: "isValidInn",
                    test: isValidInn,
                }),
        }),
    fullName: Yup.string()
        .required(requiredMessage)
        .min(5, "Минимальная длина строки 5 символов")
        .max(250, "Максимальная длина - 250 символов")
        .matches(regFullName, {
            message: "Допускается ввод цифр, спецсимволов, кириллицы и латиницы",
        }),
    name: Yup.string()
        .when("clientType", {
            is: (clientType) => clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR,
            then: Yup.string()
                .notRequired(),
            otherwise: Yup.string()
                .required(requiredMessage)
                .min(4, "Минимальная длина строки - 4 символа")
                .max(250, "Максимальная длина - 250 символов")
                .matches(regClientName, {
                    message: "Допускается ввод цифр, спецсимволов, кириллицы и латиницы",
                }),
        }),
    brand: Yup.string()
        .min(3, "Минимальная длина строки - 3 символа")
        .max(250, "Максимальная длина - 250 символов")
        .matches(regBrand, {
            message: "Допускается ввод цифр, кириллицы и латиницы, знаков . , ? ! ; : - ( ) \" \" « »",
        }),
    registeredAddress: Yup.string()
        .required(requiredMessage)
        .min(5, "Минимальная длина строки 5 символов")
        .max(250, "Максимальная длина - 250 символов"),
    representativeName: Yup.string()
        .min(5, "Минимальная длина строки 5 символов")
        .max(150, "Максимальная длина - 150 символов")
        .matches(nameWithLatinReg, {
            message: "Только латиница, кириллица и знак \"-\"",
        }),
    representativePhone: Yup.string()
        .transform(removePhoneMask)
        .length(11, "Номер телефона должен состоять из 10 цифр"),
    representativeEmail: Yup.string()
        .max(255, "Максимальная длина - 255 символов")
        .matches(emailReg, {
            message: "Введен некорректный email",
        }),
    categoryId: Yup.string()
        .nullable()
        .required(requiredMessage),
    ordersLimit: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимальное значение - 0"),
    civilOrdersLimit: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимальное значение - 0"),
    currentCommissionRate: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимально допустимое значение равно 0")
        .max(100, "Максимальное допустимое значение равно 100"),
    civilPaymentCommission: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимально допустимое значение равно 0")
        .max(100, "Максимальное допустимое значение равно 100"),
    contractorCivilOrderPaymentsCommission: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимально допустимое значение равно 0")
        .max(49.99, "Максимальное допустимое значение равно 49.99"),
    contractorCivilRegistryPaymentsCommission: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимально допустимое значение равно 0")
        .max(49.99, "Максимальное допустимое значение равно 49.99"),
    contractorSmzOrderPaymentsCommission: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимально допустимое значение равно 0")
        .max(49.99, "Максимальное допустимое значение равно 49.99"),
    contractorSmzRegistryPaymentsCommission: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимально допустимое значение равно 0")
        .max(49.99, "Максимальное допустимое значение равно 49.99"),
    minCommissionAmount: Yup.string()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимальное значение - 0"),
    minimalCommissionNdflPaymentsAmount: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимальное значение - 0")
        .max(999999.99, "Максимальное значение - 999 999,99"),
    paymentsThresholdAmount: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимальное значение - 0"),
    migrantLicensePaymentCommission: Yup.string()
        .notRequired()
        .when("migrantLicensePaymentEnabled", {
            is: true,
            then: Yup.string()
                .required(requiredMessage)
                .transform(transformYupFormattedAmountToNumber)
                .min(0, "Минимальное значение - 0"),
        }),
    edmTariff: Yup.string()
        .when("edmAvailable", {
            is: true,
            then: Yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
});