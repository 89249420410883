import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FinancePivot from "../../../components/FinancePivot";

import {getFinancePivotTableHeaders} from "../../../components/FinancePivot/utils/getTableHeaders";

import {
    financeCivilPivotReportsProgressSelector,
    financeCivilPivotReportsSelector,
    financeCivilPivotTotalSummarySelector,
    financeCivilTotalCountSelector,
    financeCivilTotalPagesSelector,
    getFinanceCivilHistorySummary,
    getFinanceCivilReportsSummary,
    updateFinanceCivilStore,
} from "../../../ducks/financeCivil";

const headers = getFinancePivotTableHeaders({defaultDateSortType: "asc", isCivil: true});

const FinancePivotGph = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(financeCivilPivotReportsSelector);
    const summary = useSelector(financeCivilPivotTotalSummarySelector);
    const totalCount = useSelector(financeCivilTotalCountSelector);
    const totalPages = useSelector(financeCivilTotalPagesSelector);
    const progressDepositHistory = useSelector(financeCivilPivotReportsProgressSelector);

    useEffect(() => {
        return () => {
            dispatch(updateFinanceCivilStore({
                reports: [],
                totalSummary: {},
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const fetchList = (data) => {
        dispatch(getFinanceCivilReportsSummary(data));
    };

    const onClickHistory = (data) => {
        dispatch(getFinanceCivilHistorySummary(data));
    };

    return (
        <FinancePivot
            header="Сводная (НДФЛ)"
            defaultDateSortType="ASC"
            list={list}
            headers={headers}
            summary={summary}
            totalCount={totalCount}
            totalPages={totalPages}
            fetchList={fetchList}
            onClickHistory={onClickHistory}
            progressDepositHistory={progressDepositHistory}
            {...props}
        />
    );
};

export default FinancePivotGph;