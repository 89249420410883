import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../utils/dateFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

export const useCrowdTaskActRegistryFilter = (props) => {
    const {
        pageSize,
        setPagination,
    } = props;

    const initFilter = {
        name: "",
        taskNumber: "",
        taskName: "",
        createDateFrom: null,
        createDateTo: null,
    };

    function mapFilterDto(filter) {
        const {
            name,
            createDateFrom,
            createDateTo,
            taskNumber,
            taskName,
        } = filter;

        return {
            createDateFrom: getStartFilterDateWithTimeInUtc(createDateFrom),
            createDateTo: getEndFilterDateWithTimeInUtc(createDateTo),
            taskNumber: handleNumber(taskNumber),
            taskName: handleFormString(taskName),
            name: handleFormString(name),
        };
    }

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        setFilter,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "name",
                        label: "Наименование реестра актов",
                        placeholder: "Введите наименование реестра актов",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "createDateFrom",
                        endFieldName: "createDateTo",
                        isClearable: true,
                        label: "Дата создания",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskNumber",
                        label: "Номер задания",
                        placeholder: "Введите номер задания",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "taskName",
                        label: "Название задания",
                        placeholder: "Введите название задания",
                    },
                ],
            },
        ];
    }, []);

    return {
        initFilter,
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
        filters,
        setFilter,
    };
};