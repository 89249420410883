export const isCheckedFetchedObject = (params) => {
    const {
        isVisibleClient,
        client,
        project,
        fetchedObject,
    } = params;

    if (typeof project.changedCheckedValue === "boolean") {
        return project.changedCheckedValue;
    }

    if (isVisibleClient && typeof client.changedCheckedValue === "boolean") {
        return client.changedCheckedValue;
    }

    return fetchedObject.boolParam;
};