export const getFullRegistrationInitialSeriesAndNumber = (value) => {
    if (!value) {
        return "";
    }

    const seriesAndNumber = value.split(" ");

    if (seriesAndNumber.length === 2) {
        const [series, number] = seriesAndNumber;
        return {
            series,
            number,
        };
    }

    const [series] = seriesAndNumber;

    return {
        series,
        number: "",
    };
};