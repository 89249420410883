import React from "react";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import NmTitle from "../../../../components/NmTitle";
import TableDiv from "../../../../components/TableDiv";

import bem from "../../../../utils/bem";
import dateFormat, {formatLocalDate} from "../../../../utils/dateFormat";
import {
    formatAmountWithNullChecking, 
    getFullName,
    phoneFormat,
} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    registryCivilPaymentDocumentHeaders,
    registryPaymentDocumentHeaders,
} from "../../../../constants/headersTable";

import "./style.sass";

const RegistryPaymentDocumentTablesModal = (props) => {
    const {
        data: {
            registryNum,
            orderContractPaymentType,
            payment,
        },
        onClose,
    } = props;

    const [block, element] = bem("document-tables-modal");

    const isSmzPaymentType = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ;
    const {
        contractorLastName,
        contractorFirstName,
        contractorPatronymic,
        contractorFio,
        registrySeqNum,
        contractorPhone,
        orderWorkStartDate,
        orderWorkEndDate,
        orderAmount,
        civilContractAmount,
        orderAmountForPayment,
        workReports = [],
    } = payment;

    const getContractorName = (lastName, firstName, patronymic, contractorFio) => {
        if (!isNullOrWhitespace(lastName)) {
            return getFullName(lastName, firstName, patronymic);
        }
        if (!isNullOrWhitespace(contractorFio)) {
            return contractorFio;
        }
        return "Не определено";
    };

    function mapTableData() {
        const rows = workReports.map(item => {
            return {
                ...item,
                workStartDate: formatLocalDate(item.workStartDate, "dd.MM.yyyy"),
                workEndDate: formatLocalDate(item.workEndDate, "dd.MM.yyyy"),
                amount: formatAmountWithNullChecking(item.amount),
                orderAmount: formatAmountWithNullChecking(item.orderAmount),
                workExecutionAddresses: item.workExecutionAddresses.length && item.workExecutionAddresses.join("; "),
            };
        });

        return {
            headers: isSmzPaymentType ? registryPaymentDocumentHeaders : registryCivilPaymentDocumentHeaders,
            rows,
        };
    }

    return (
        <NmModal
            className={block()}
            size="lg"
            header={
                <NmTitle size="lg">
                    Таблица работ - Реестр №
                    {registryNum}
                    {" "}
- Строка №
                    {registrySeqNum}
                </NmTitle>
            }
            onClose={onClose}
        >
            <div className="flex flex-column">
                <Text
                    className="mb-2"
                    level="5"
                >
                    {getContractorName(contractorLastName, contractorFirstName, contractorPatronymic, contractorFio)}
                </Text>
                <NmLabelText
                    type="page"
                    label="Номер строки"
                    text={registrySeqNum}
                />
                <NmLabelText
                    type="page"
                    label="Телефон"
                    text={phoneFormat(contractorPhone)}
                />
                <NmLabelText
                    type="page"
                    label="Период работ"
                    text={`${dateFormat(orderWorkStartDate, "dd.MM.yyyy")} - ${dateFormat(orderWorkEndDate, "dd.MM.yyyy")}`}
                />
                {
                    isSmzPaymentType ?
                        <>
                            <NmLabelText
                                type="page"
                                label="Сумма Net, ₽"
                                text={formatAmountWithNullChecking(orderAmount)}
                            />
                            <NmLabelText
                                type="page"
                                label="Сумма Gross, ₽"
                                text={formatAmountWithNullChecking(orderAmountForPayment)}
                            />
                        </> :
                        <>
                            <NmLabelText
                                type="page"
                                label="Сумма по договору, ₽"
                                text={formatAmountWithNullChecking(civilContractAmount)}
                            />
                            <NmLabelText
                                type="page"
                                label="Сумма на руки, ₽"
                                text={formatAmountWithNullChecking(orderAmountForPayment)}
                            />
                        </>
                }
                {
                    !workReports?.length ?
                        <NmEmptyPageV2
                            className="mt-2"
                            title="Данные отсутствуют"
                        /> :
                        <TableDiv
                            className={element("table", {civil: !isSmzPaymentType})}
                            tableData={mapTableData()}
                        />
                }
            </div>
        </NmModal>
    );
};

export default RegistryPaymentDocumentTablesModal;