import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    updateRecruitmentVacancyStore,
} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    getFileStoreRecruitmentInitialState,
    updateFileStoreRecruitmentCandidateStore,
} from "../../../../../../ducks/fileStoreRecruitment";
import {useRecruitmentFunnelFilters} from "./useFunnelFilters";

export const useRecruitmentVacancyDescriptionFetch = (props) => {
    const {
        clientId,
        vacancyId,
    } = props;

    const dispatch = useDispatch();

    const {
        fetchFunnelFilters,
    } = useRecruitmentFunnelFilters({
        clientId,
        vacancyId,
        isFetchByFilterData: true,
    });

    useEffect(() => {
        return () => {
            const initialState = getFileStoreRecruitmentInitialState();

            dispatch(updateFileStoreRecruitmentCandidateStore(initialState));

            dispatch(updateRecruitmentVacancyStore({
                tabFilterData: {filterData: {}, isSearch: false},
            }));
        };
    }, []);

    return {
        fetchFunnelFilters,
    };
};