import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";

import Text from "../ActualComponents/Text";
import {Dimmer, Loader} from "semantic-ui-react";

import "./style.sass";

interface NmPageCard {
    header: ReactNode | string,
    description?: ReactNode,
    info?: ReactNode,
    children: ReactNode,
    isLoaded?: boolean,
    textLoading?: string
}

const NmPageCard = (props: NmPageCard) => {
    const {
        header,
        description,
        info,
        children,
        isLoaded = false,
        textLoading,
    } = props;

    const {t} = useTranslation();

    return (
        <div className="nm-page-card">
            <div className="nm-page-card__header">
                {typeof header === "string" ? <Text.Title
                    level="1"
                    className="nm-page-card-header__title"
                >
                    {header}
                </Text.Title> : header}
            </div>
            {
                isLoaded &&
                <Dimmer
                    active
                    inverted
                >
                    <Loader content={textLoading || t("loader.content")} />
                </Dimmer>
            }
            {
                description &&
                    <div className="nm-page-card__description">
                        {description}
                    </div>
            }
            {
                info &&
                    <div className="nm-page-card__info">
                        {info}
                    </div>
            }
            <div className="nm-page-card__content">
                {children}
            </div>
        </div>
    );
};

export default NmPageCard;