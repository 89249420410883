import {
    KEDO_DIRECTORIES_ADD_POSITION_ERROR,
    KEDO_DIRECTORIES_ADD_POSITION_REQUEST,
    KEDO_DIRECTORIES_ADD_POSITION_SUCCESS,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_DELETE_POSITION_ERROR,
    KEDO_DIRECTORIES_DELETE_POSITION_REQUEST,
    KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST,
    KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_POSITION_ERROR,
    KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_STORE,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST,
    KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST,
    KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST,
    KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS,
} from "./actions";

export const getKedoDocumentsInitialState = () => {
    return {
        uploadDocumentTypes: {},
        templateDocumentTypes: {},
        positions: {},
        subdivisions: {},
        progressAction: false,
        counts: {},
        clientUsage: {},
    };
};

const initialState = getKedoDocumentsInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_REQUEST:
        return {
            ...state,
            clientUsage: {
                ...state.clientUsage,
                progress: false,
                pageData: payload,
            },
        };
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_ERROR:
        return {
            ...state,
            clientUsage: {
                ...state.clientUsage,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_CLIENT_USAGE_PAGE_SUCCESS:
        return {
            ...state,
            clientUsage: {
                ...state.clientUsage,
                list: payload.results,
                totalCount: payload.totalCount,
            },
        };
    case KEDO_DIRECTORIES_GET_PAGE_COUNT_SUCCESS:
        return {
            ...state,
            counts: payload,
        };
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_ERROR:
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_SUCCESS:
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_ERROR:
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_SUCCESS:
    case KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_ERROR:
    case KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_SUCCESS:
    case KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_ERROR:
    case KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_SUCCESS:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_ERROR:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_SUCCESS:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_ERROR:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_SUCCESS:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_ERROR:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_SUCCESS:
    case KEDO_DIRECTORIES_DELETE_SUBDIVISION_ERROR:
    case KEDO_DIRECTORIES_DELETE_SUBDIVISION_SUCCESS:
    case KEDO_DIRECTORIES_UPDATE_SUBDIVISION_ERROR:
    case KEDO_DIRECTORIES_UPDATE_SUBDIVISION_SUCCESS:
    case KEDO_DIRECTORIES_ADD_SUBDIVISION_ERROR:
    case KEDO_DIRECTORIES_ADD_SUBDIVISION_SUCCESS:
    case KEDO_DIRECTORIES_DELETE_POSITION_ERROR:
    case KEDO_DIRECTORIES_DELETE_POSITION_SUCCESS:
    case KEDO_DIRECTORIES_UPDATE_POSITION_ERROR:
    case KEDO_DIRECTORIES_UPDATE_POSITION_SUCCESS:
    case KEDO_DIRECTORIES_ADD_POSITION_ERROR:
    case KEDO_DIRECTORIES_ADD_POSITION_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };

    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_UPDATE_REQUEST:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_ADD_REQUEST:
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_DELETE_REQUEST:
    case KEDO_DIRECTORIES_UPDATE_TEMPLATE_DOCUMENT_TYPES_REQUEST:
    case KEDO_DIRECTORIES_UPDATE_CLIENT_USAGE_TEMPLATE_DOCUMENT_TYPES_REQUEST:
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_ADD_REQUEST:
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_DELETE_REQUEST:
    case KEDO_DIRECTORIES_DELETE_SUBDIVISION_REQUEST:
    case KEDO_DIRECTORIES_UPDATE_SUBDIVISION_REQUEST:
    case KEDO_DIRECTORIES_ADD_SUBDIVISION_REQUEST:
    case KEDO_DIRECTORIES_DELETE_POSITION_REQUEST:
    case KEDO_DIRECTORIES_UPDATE_POSITION_REQUEST:
    case KEDO_DIRECTORIES_ADD_POSITION_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case KEDO_DIRECTORIES_GET_POSITIONS_PAGE_REQUEST:
        return {
            ...state,
            positions: {
                ...state.positions,
                progress: true,
                pageData: payload,
            },
        };
    case KEDO_DIRECTORIES_GET_POSITIONS_PAGE_SUCCESS:
        return {
            ...state,
            positions: {
                ...state.positions,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_ERROR:
        return {
            ...state,
            templateDocumentTypes: {
                ...state.templateDocumentTypes,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_ERROR:
        return {
            ...state,
            uploadDocumentTypes: {
                ...state.uploadDocumentTypes,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_GET_POSITIONS_PAGE_ERROR:
        return {
            ...state,
            positions: {
                ...state.positions,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_REQUEST:
        return {
            ...state,
            subdivisions: {
                ...state.subdivisions,
                progress: true,
                pageData: payload,
            },
        };
    case KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_SUCCESS:
        return {
            ...state,
            subdivisions: {
                ...state.subdivisions,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_GET_SUBDIVISIONS_PAGE_ERROR:
        return {
            ...state,
            subdivisions: {
                ...state.subdivisions,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_REQUEST:
        return {
            ...state,
            templateDocumentTypes: {
                ...state.templateDocumentTypes,
                progress: true,
                pageData: payload,
            },
        };
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_REQUEST:
        return {
            ...state,
            uploadDocumentTypes: {
                ...state.uploadDocumentTypes,
                progress: true,
                pageData: payload,
            },
        };
    case KEDO_DIRECTORIES_TEMPLATE_DOCUMENT_TYPES_PAGE_SUCCESS:
        return {
            ...state,
            templateDocumentTypes: {
                ...state.templateDocumentTypes,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_UPLOAD_DOCUMENT_TYPES_PAGE_SUCCESS:
        return {
            ...state,
            uploadDocumentTypes: {
                ...state.uploadDocumentTypes,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            },
        };
    case KEDO_DIRECTORIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default: return state;
    }
};