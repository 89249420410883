import React, {ReactNode} from "react";

import {ReactComponent as InfoIcon} from "../../../images/alert_24.svg";
import {ReactComponent as HelpIcon} from "../../../images/help_24.svg";
import {ReactComponent as HelpAttentionIcon} from "../../../images/help_attention_24.svg";
import {ReactComponent as HelpOutlineIcon} from "../../../images/help_outline.svg";
import {ReactComponent as InfoAttentionIcon} from "../../../images/info_attention_24.svg";
import {ReactComponent as InfoOutline} from "../../../images/info_outline.svg";
import NmAdvancedTooltip from "../NmAdvancedTooltip";
import {TNmTooltipBody, TNmTooltipPosition, TNmTooltipType} from "../NmTooltip";

import bem from "../../../utils/bem";

import "./style.sass";

export interface IHelpTooltip {
    // ? или i в иконке
    outline?: boolean,
    info?: boolean,
    attention?: boolean,
    type?: TNmTooltipType,
    text?: string | ReactNode,
    className?: string,
    body?: TNmTooltipBody,
    compound?: boolean,
    width?: number,
    height?: number,
    position?: TNmTooltipPosition,
    classNameTooltip?: string,
    hover?: boolean,
    help?: boolean,
    children?: ReactNode,
    classNameIcon?: string,
    onClick?: () => {},
    error?: boolean,
    inheritColor?: boolean,
    noPointerEvents?: boolean,
    isTypeTooltipEqualTypeIcon?: boolean,
    isFilterTooltip?: boolean,
}

const HelpTooltip = (props: IHelpTooltip) => {
    const {
        className = "",
        text,
        info = true,
        help,
        attention = false,
        width = 20,
        height = 20,
        position,
        children,
        type,
        outline,
        classNameTooltip = "",
        classNameIcon = "",
        isTypeTooltipEqualTypeIcon = true,
        inheritColor,
        onClick = () => {},
        ...otherProps
    } = props;
    const [block, element] = bem("help-tooltip", className);

    const isSize = Boolean(width || height);

    const getIcon = () => {
        if (help) {
            if (outline) {
                return (
                    <HelpOutlineIcon
                        onClick={onClick}
                        className={element("icon")}
                        height={height}
                        width={width}
                    />
                );
            }

            return attention ?
                <HelpAttentionIcon
                    onClick={onClick}
                    className={element("icon")}
                    height={height}
                    width={width}
                /> :
                <HelpIcon
                    onClick={onClick}
                    className={element("icon", {type: isTypeTooltipEqualTypeIcon && type})}
                    height={height}
                    width={width}
                />;
        }

        if (outline) {
            return (
                <InfoOutline
                    onClick={onClick}
                    className={`${element("icon", {attention})} ${classNameIcon}`}
                    height={height}
                    width={width}
                />
            );
        }

        return attention ?
            <InfoAttentionIcon
                onClick={onClick}
                className={element("icon")}
                height={height}
                width={width}
            /> :
            <InfoIcon
                onClick={onClick}
                className={`${element("icon", {
                    type: isTypeTooltipEqualTypeIcon && type,
                    inheritColor,
                })} ${classNameIcon}`}
                height={height}
                width={width}
            />;
    };

    return (
        <NmAdvancedTooltip
            {...otherProps}
            className={block({defaultSize: !isSize})}
            type={type}
            position={position}
            classNameTooltip={`${element("tooltip", {
                bottomRight: position === "bottom-right",
                bottomLeft: position === "bottom-left",
                topRight: position === "top-right",
                topLeft: position === "top-left",
            })} ${classNameTooltip}`}
            trigger={getIcon()}
        >
            {text || children}
        </NmAdvancedTooltip>
    );
};

export default HelpTooltip;