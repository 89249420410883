import {useState} from "react";

import {removePhoneMask} from "../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";

export function useMembersFilter({clientGroupId, excludeClientGroupId}) {
    const [filterDto, setFilter] = useState({
        clientGroupId,
        excludeClientGroupId,
    });
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            fioFilter,
            phoneFilter,
            emailFilter,
        } = filter;

        return {
            ...filterDto,
            fioFilter: handleFormString(fioFilter),
            phoneFilter: handleFormString(removePhoneMask(phoneFilter)),
            emailFilter: handleFormString(emailFilter),
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };

    return {
        filterDto,
        isSearch,
        setFilterDto,
        setIsSearch,
    };
}