export const DEPOSIT_OPERATION = {
    ADD_DEPOSIT: "ADD_DEPOSIT",
    RETURN_DEPOSIT: "RETURN_DEPOSIT",
};

export const DEPOSIT_OPERATION_TRANSCRIPTION = {
    [DEPOSIT_OPERATION.ADD_DEPOSIT]: "Пополнение депозита",
    [DEPOSIT_OPERATION.RETURN_DEPOSIT]: "Возврат денежных средств",
};

export const COMMISSION_TYPE = {
    SMZ_ORDER: "SMZ_ORDER",
    SMZ_REGISTRY : "SMZ_REGISTRY",
    CIVIL_ORDER : "CIVIL_ORDER",
    CIVIL_REGISTRY : "CIVIL_REGISTRY",
};

export const HIDE_DEPOSIT_DETAILS_PAGE = {
    LIST_OBJECTS: "ListObjects",
    LIST_PROJECTS: "ListProjects",
    OBJECT: "Object",
    ORDERS: "Orders",
    PROJECT: "Project",
};