import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import validate from "../../../utils/validate";

import {CLIENT_FIELD_NAME} from "../../../constants/clientList";
import {stopListAddInnRule} from "../../../constants/validationRules";

import PropTypes from "prop-types";

import "./style.sass";

class StopListAddInnModal extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
    };

    static defaultProps = {};

    state = {
        inn: "",
        errorForm: "",
    };

    submit = () => {
        if (!this.validationForm()) {
            return;
        }

        const {
            onSubmit,
            onCancel,
        } = this.props;
        const {inn} = this.state;

        onSubmit(inn);

        onCancel();
    };

    validationForm() {
        const {inn} = this.state;

        const errorForm = validate({inn}, stopListAddInnRule);

        this.setState({errorForm});

        return Object.values(errorForm).length === 0;
    };

    handleOnChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    render() {
        const {onCancel} = this.props;
        const {
            inn,
            errorForm,
        } = this.state;

        return (
            <NmModal
                size="md"
                className="stop-list-add"
                header={
                    <NmTitle size="lg">
                        Добавление ИНН
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent="Добавить"
                        cancelBtnContent="Отменить"
                        onClose={onCancel}
                        submit={this.submit}
                    />
                }
                onClose={onCancel}
            >
                <NmForm className="stop-list-add__input">
                    <NmInputV2
                        mask="999999999999"
                        label="ИНН"
                        maskChar={null}
                        size="xl"
                        onChange={this.handleOnChange}
                        name="inn"
                        value={inn}
                        error={errorForm[`${CLIENT_FIELD_NAME.INN}ErrorMessage`]}
                        required
                    />
                </NmForm>
            </NmModal>
        );
    }
}

export default withTranslation()(StopListAddInnModal);
