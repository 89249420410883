import {
    KEDO_EXPORTS_CANCEL_BY_ID_REQUEST,
    KEDO_EXPORTS_GET_PAGE_REQUEST,
    KEDO_EXPORTS_UPDATE_STORE,
} from "./actions";

export const getKedoExportsPage = (payload) => {
    return {
        type: KEDO_EXPORTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const cancelKedoExportById = (payload) => {
    return {
        type: KEDO_EXPORTS_CANCEL_BY_ID_REQUEST,
        payload,
    };
};

export const updateExportsStore = (payload) => {
    return {
        type: KEDO_EXPORTS_UPDATE_STORE,
        payload,
    };
};