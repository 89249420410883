import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import {applyMiddleware, compose,createStore} from "redux";
import {createLogger} from "redux-logger";
import createSagaMiddleware from "redux-saga";

import createRootReducer from "./reducer";
import rootSaga from "./saga";

export const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export const logger = createLogger({
    level: "log",
    collapsed: true,
    predicate: () => process.env.NODE_ENV !== "production",
});

export const initialState = undefined;

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        window.__REDUX_DEVTOOLS_EXTENSION__ ?
            compose(
                applyMiddleware(
                    routerMiddleware(history),
                    logger,
                    sagaMiddleware,
                ),
                window.__REDUX_DEVTOOLS_EXTENSION__(),
            ) :
            compose(
                applyMiddleware(
                    routerMiddleware(history),
                    logger,
                    sagaMiddleware,
                ),
            ),
    );
    sagaMiddleware.run(rootSaga);
    return store;
}

