import {useEffect, useReducer} from "react";

type State = {
    content: string,
    isOpen: boolean,
    callBackFunc?: Function,
    type: string
};
const initialState = {
    content: "",
    isOpen: false,
    callBackFunc: undefined,
    type: "",
};

export enum ConfirmActionType {
    OPEN = "OPEN",
    CLOSE = "CLOSE",
    RESET = "RESET",
    ON_CONFIRM = "ON_CONFIRM"
}

type OpenConfirmActionType = {
    type: ConfirmActionType.OPEN,
    content: string,
    callBackFunc?: Function
}

type OnConfirmActionType = {
    type: ConfirmActionType.ON_CONFIRM,
}

type CloseActionType = {
    type: ConfirmActionType.CLOSE,
}

type ResetActionType = {
    type: ConfirmActionType.RESET,
}

type Action = OpenConfirmActionType | CloseActionType | ResetActionType | OnConfirmActionType;

export function openConfirmAction(content: string, callBackFunc?: Function): OpenConfirmActionType {
    return {
        type: ConfirmActionType.OPEN,
        content,
        callBackFunc,
    };
}

export function onConfirmAction(): OnConfirmActionType {
    return {
        type: ConfirmActionType.ON_CONFIRM,
    };
}

export function closeConfirmAction(): CloseActionType {
    return {
        type: ConfirmActionType.CLOSE,
    };
}

export function resetConfirmState(): ResetActionType {
    return {
        type: ConfirmActionType.RESET,
    };
}

function reducer(state: State, action: Action): State {
    const {
        type,
        // @ts-ignore
        content,
        // @ts-ignore
        callBackFunc,
    } = action;

    switch (type) {
    case ConfirmActionType.OPEN:
        return {
            ...state,
            isOpen: !state.isOpen,
            type,
            content,
            callBackFunc,
        };
    case ConfirmActionType.CLOSE:
        return {
            ...initialState,
            type,
        };
    case ConfirmActionType.ON_CONFIRM:
        return {
            ...state,
            type,
        };
    case ConfirmActionType.RESET:
        return {
            ...initialState,
        };
    default:
        return state;

    }
}

export default function useConfirm(isDelayedClosing?: boolean): [Function, boolean, string] {
    const [{isOpen, content, type, callBackFunc}, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (
            isOpen &&
            type === ConfirmActionType.ON_CONFIRM &&
            callBackFunc
        ) {
            if (isDelayedClosing) {
                callBackFunc({
                    onClose: () => dispatch(resetConfirmState()),
                });

                return;
            }

            callBackFunc();
            dispatch(resetConfirmState());
        }

    }, [type, callBackFunc, isOpen]);


    return [dispatch, isOpen, content];
}