import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {identity,isEmpty, pickBy} from "lodash";

import validationSchema from "../validation";

import {formatDateWithoutTime} from "../../../../../../../utils/dateFormat";
import {getNumberFromFormattedAmount, removePhoneMask} from "../../../../../../../utils/stringFormat";
import {convertStringToDate} from "../../../../../../../utils/stringHelper";

import {
    addDocumentsEdoRegistriesItems,
    updateDocumentsEdoRegistriesItems,
} from "../../../../../../../ducks/documentsEdoRegistriesItems";
import {
    edoDocumentTypesSelector,
    getEdoDocumentTypes,
} from "../../../../../../../ducks/edocuments";


const useDataForm = ({fetchList, onClose, clientId, registryId, documentType, isEdit, editData, contractorData, isAddMissingContractor, useTemplateFile}) => {
    const edoDocumentTypes = useSelector(edoDocumentTypesSelector);

    const dispatch = useDispatch();

    const initialJobList = [{
        count: "",
        price: "",
        workName: "",
        linkToResult: "",
    }];

    const initialParams = {
        documentNumber: "",
        contractNumber: "",
        contractDate: null,
        documentSubject: "",
        startWorkDate: "",
        endWorkDate: "",
        documentPlace: "",
        paymentCurrency: "",
        platformName: "",
        email: "",
        personalData: [],
        targets: "",
        periodFrom: "",
        periodTo: "",
        jobList: initialJobList,
    };

    const initialValues = {
        isAddMissingContractor,
        useTemplateFile,
        params: initialParams,
        receiverPhone: contractorData?.phone || "",
        documentName: "",
        documentInformation: "",
        documentDatetime: null,
    };

    useEffect(() => {
        if (!edoDocumentTypes.length) {
            dispatch(getEdoDocumentTypes({
                clientId,
                pageNum: 1,
                pageSize: 200,
            }));
        }

        if (isEdit) {
            const _editData = pickBy(editData, identity);

            setValues({
                ...initialValues,
                ..._editData,
                documentDatetime: _editData.documentDatetime ? convertStringToDate(_editData.documentDatetime) : null,
                params: useTemplateFile ? {
                    ...initialParams,
                    ..._editData.params,
                    contractDate: editData.params?.contractDate ? convertStringToDate(editData.params.contractDate) : null,
                    jobList: editData.params?.jobList || initialJobList,
                } : {},
            });
        }
    }, []);

    useEffect(() => {
        if (edoDocumentTypes.length && documentType && !isEdit) {
            const document = edoDocumentTypes.find(value => value.id === documentType);

            setValues({
                ...values,
                documentName: document?.value || "",
                documentDatetime: new Date(),
                params: useTemplateFile ? {
                    ...values.params,
                    type: document?.templateType,
                } : {},
            });
        }
    }, [edoDocumentTypes, documentType]);

    const handleChange = (e, {name, value}) => {
        setFieldValue(name, value);
        setFieldTouched(name, true);
    };

    const addJobListItem = () => {
        setValues({
            ...values,
            params: {
                ...values.params,
                jobList: [
                    ...values.params.jobList,
                    {
                        count: "",
                        price: "",
                        workName: "",
                        linkToResult: "",
                    },
                ],
            },
        });
    };

    const deleteJobListItem = (_index) => {
        setValues({
            ...values,
            params: {
                ...values.params,
                jobList: [
                    ...values.params.jobList.filter((element, index) => index !== _index ),
                ],
            },
        });
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const {
            receiverPhone,
            documentDatetime,
            documentInformation,
            documentName,
            params,
        } = values;

        const requestData = {
            clientId,
            registryId,
            receiverPhone: removePhoneMask(receiverPhone),
            documentInformation,
            documentName,
            documentDatetime,
            params: (useTemplateFile && !isEmpty(params)) ? {
                ...params,
                contractDate: formatDateWithoutTime(values.params.contractDate, "yyyy-MM-dd"),
                documentDate: documentDatetime && formatDateWithoutTime(documentDatetime, "yyyy-MM-dd"),
                jobList: values.params.jobList && values.params.jobList.map((value, index) => {
                    value.price = getNumberFromFormattedAmount(value.price);
                    value.count = getNumberFromFormattedAmount(value.count);
                    value.rowNumber = Number(index + 1);

                    return value;
                }),
            } : undefined,
        };

        const onSuccess = () => {
            fetchList();
            onClose();
        };

        if (isEdit) {
            dispatch(updateDocumentsEdoRegistriesItems({
                ...editData,
                ...requestData,
                onSuccess,
            }));

            return;
        }

        dispatch(addDocumentsEdoRegistriesItems({
            ...requestData,
            onSuccess,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        errors,
        isValid,
        touched,
        setFieldTouched,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        addJobListItem,
        deleteJobListItem,
    };
};

export default useDataForm;