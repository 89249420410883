import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageRecruitmentDirectoryFunnel,
    updateRecruitmentDirectoryStore,
} from "../../../../../ducks/bff/recruitment/directory/actionCreators";

const useRecruitmentDirectoriesFunnelsFetchList = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                funnelPageData: {},
                funnelList: [],
                funnelTotalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);


    const fetchList = () => {
        dispatch(getPageRecruitmentDirectoryFunnel({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};

export default useRecruitmentDirectoriesFunnelsFetchList;