import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

import {
    categorySpecialitiesFilterListSelector,
    getCategorySpecialities,
} from "../../../../../ducks/categorySpecialities";

export const useCategorySpecialitiesFilter = ({setPagination, pageSize}) => {
    const dispatch = useDispatch();
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            categorySpecialityIds: [],
            specialityIds: [],
        },
        pageSize,
        setPagination,
    });
    const options = useSelector(categorySpecialitiesFilterListSelector);

    const onSearchChange = (valueFilter) => {
        dispatch(getCategorySpecialities({
            valueFilter,
            nameField: "filterList",
            pageNum: 1,
            pageSize: 10,
        }));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "categorySpecialityIds",
                        onSearchChange,
                        options,
                        label: "Категория вида деятельности",
                        search: true,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        name: "specialityIds",
                        label: "Вид деятельности",
                        multiple: true,
                        placeholder: "Введите тип заявления",
                    },
                ],
            },
        ];
    }, [options]);

    return {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    };
};