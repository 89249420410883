import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";

import {usePagination} from "../../../../hooks/usePagination";
import {useFetchListNotificationLog} from "./hooks/useFetchListNotificationLog";
import {useListNotificationEventFilter} from "./hooks/useListNotificationEventFilter";

import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {getFullName} from "../../../../utils/stringFormat";

import {
    contractorNotificationLogEventDictSelector,
    contractorNotificationLogListSelector,
    contractorNotificationLogProgressSelector,
    contractorNotificationLogTotalCountSelector,
    contractorNotificationLogTotalPagesSelector,
} from "../../../../ducks/contractorNotificationLog";

const initFilter = {
    logStartDateFilter: null,
    logEndDateFilter: null,
    eventTypesFilter: [],
};

function ContractorEventNotificationLogList(props) {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const totalPages = useSelector(contractorNotificationLogTotalPagesSelector);
    const totalCount = useSelector(contractorNotificationLogTotalCountSelector);
    const eventTypeDict = useSelector(contractorNotificationLogEventDictSelector);
    const list = useSelector(contractorNotificationLogListSelector);
    const progress = useSelector(contractorNotificationLogProgressSelector);

    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    } = useListNotificationEventFilter({eventTypeDict});

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("contractor-event-log-common");

    useFetchListNotificationLog({
        filter: filterDto,
        pageSize,
        pageNum,
        contractorId,
    });

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    const getRows = () => {
        return list.map(item => {
            const {
                dateTime,
                description,
                eventType,
                initiatedByFirstName,
                initiatedByLastName,
                initiatedByPatronymic,
                read,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        secondaryHeader={formatDate(convertUtcToLocal(dateTime), "dd.MM.yyyy HH:mm")}
                        secondaryHeaderStatus={
                            <NmBadge
                                text={read ? "Прочитано" : "Не прочитано"}
                                mod={read ? "green" : "red"}
                                className="me-2"
                            />
                        }
                        primaryHeader={eventTypeDict[eventType]}
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Инициатор",
                                text: getFullName(initiatedByLastName, initiatedByFirstName, initiatedByPatronymic),
                            },
                            {
                                label: "Описание события",
                                text: description || "-",
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            noPadding
            heightUnset
            header={
                <NmPageHeader
                    text="Лог уведомлений"
                />
            }
            isLoaded={progress}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={(form) => submitFilter(form, true)}
                    clearFilter={() => submitFilter(initFilter, true)}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {totalCount > 0 ? <CheckboxList
                rows={getRows()}
            /> : <NmEmptyPageV2
                title="Информация отсутствует"
                isSearch={isSearch}
                fetchProgress={progress}
                                   />
            }
        </NmPage>
    );
}

export default ContractorEventNotificationLogList;