export const EDO_DOCUMENTS_REGISTRIES_STATUS = {
    PARTIALLY_SIGNED: {
        VALUE: "PARTIALLY_SIGNED",
        TEXT: "Подписан частично",
        MOD: "light-green",
        COLOR: "#3C9C3B",
        BACKGROUND: "#E9F4E5",
    },
    SIGNED: {
        VALUE: "SIGNED",
        TEXT: "Подписан полностью",
        MOD: "green",
        COLOR: "#FFFFFF",
        BACKGROUND: "#128A10",
    },
    DRAFT: {
        VALUE: "DRAFT",
        TEXT: "Формируется",
        MOD: "gray",
        COLOR: "#666666",
        BACKGROUND: "#F1F3F7",
    },
    FOR_SIGNING: {
        VALUE: "FOR_SIGNING",
        TEXT: "Передан на подписание",
        MOD: "orange",
        COLOR: "#FFFFFF",
        BACKGROUND: "#FFB713",
    },
    DECLINED: {
        VALUE: "DECLINED",
        TEXT: "Отклонен",
        MOD: "red",
        COLOR: "#FFFFFF",
        BACKGROUND: "#EE1616",
    },
};

export const EDO_DOCUMENTS_REGISTRIES_STATUS_OPTIONS = [
    {
        key: EDO_DOCUMENTS_REGISTRIES_STATUS.PARTIALLY_SIGNED.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_STATUS.PARTIALLY_SIGNED.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_STATUS.PARTIALLY_SIGNED.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_STATUS.SIGNED.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_STATUS.SIGNED.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_STATUS.SIGNED.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_STATUS.FOR_SIGNING.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_STATUS.FOR_SIGNING.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_STATUS.FOR_SIGNING.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_STATUS.DECLINED.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_STATUS.DECLINED.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_STATUS.DECLINED.VALUE,
    },
];

export const EDO_DOCUMENTS_REGISTRIES_OPERATION = {
    // Добавить исполнителя из списка
    ADD_CONTRACTOR: "ADD_CONTRACTOR",
    // Добавить отсутствующего на площадке исполнителя
    ADD_MISSING_CONTRACTOR: "ADD_MISSING_CONTRACTOR",
    // Загрузить список исполнителей
    IMPORT_CONTRACTORS: "IMPORT_CONTRACTORS",
    // Пригласить исполнителя в Наймикс
    INVITE_TO_NAIMIX: "INVITE_TO_NAIMIX",
    // Подписать реестр
    SIGN_REGISTRY: "SIGN_REGISTRY",
    // Выгрузка реестра
    EXPORT_REGISTRY: "EXPORT_REGISTRY",
    // Удалить строку  (массовое действие над списком)
    DELETE_ROW: "DELETE_ROW",
    // Отмена отправки документа на подписание
    REJECT_ROW: "REJECT_ROW",
    // Скрыть права в кадрах
    HIDE_STAFF_INFO: "HIDE_STAFF_INFO",
    // Повторить невыполненные
    SIGN_NOT_COMPLETED: "SIGN_NOT_COMPLETED",
    // Удалить невыполненные строки реестра
    DELETE_NOT_COMPLETED: "DELETE_NOT_COMPLETED",
    // Обновить параметры исполнителей
    UPDATE_RECEIVERS_DATA: "UPDATE_RECEIVERS_DATA",
};

export const EDO_DOCUMENTS_REGISTRIES_CARD_OPTIONS = {
    ADD_CONTRACTOR: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.ADD_CONTRACTOR,
        text: "Добавить исполнителя из списка",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.ADD_CONTRACTOR,
    },
    ADD_MISSING_CONTRACTOR: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.ADD_MISSING_CONTRACTOR,
        text: "Добавить отсутствующего на площадке исполнителя\n",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.ADD_MISSING_CONTRACTOR,
    },
    IMPORT_CONTRACTORS: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.IMPORT_CONTRACTORS,
        text: "Загрузить список исполнителей",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.IMPORT_CONTRACTORS,
    },
    INVITE_TO_NAIMIX: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.INVITE_TO_NAIMIX,
        text: "Пригласить исполнителя в Наймикс",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.INVITE_TO_NAIMIX,
    },
    SIGN_REGISTRY: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.SIGN_REGISTRY,
        text: "Подписать",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.SIGN_REGISTRY,
    },
    EXPORT_REGISTRY: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.EXPORT_REGISTRY,
        text: "Выгрузить",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.EXPORT_REGISTRY,
    },
    SIGN_NOT_COMPLETED: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.SIGN_NOT_COMPLETED,
        text: "Повторить невыполненные",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.SIGN_NOT_COMPLETED,
    },
    DELETE_NOT_COMPLETED: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.DELETE_NOT_COMPLETED,
        text: "Удалить невыполненные",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.DELETE_NOT_COMPLETED,
    },
    UPDATE_RECEIVERS_DATA: {
        key: EDO_DOCUMENTS_REGISTRIES_OPERATION.UPDATE_RECEIVERS_DATA,
        text: "Обновить параметры исполнителей",
        value: EDO_DOCUMENTS_REGISTRIES_OPERATION.UPDATE_RECEIVERS_DATA,
    },
};

export const EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS = {
    AWAITING_RECEIVER_REGISTRATION: {
        VALUE: "AWAITING_RECEIVER_REGISTRATION",
        TEXT: "Ожидание регистрации получателя на площадке",
        MOD: "orange",
    },
    SIGNED: {
        VALUE: "SIGNED",
        TEXT: "Подписан",
        MOD: "green",
    },
    ERROR: {
        VALUE: "ERROR",
        TEXT: "Ошибка при передаче на подписание",
        MOD: "red",
    },
    AWAITING_RECEIVER_SIGNING: {
        VALUE: "AWAITING_RECEIVER_SIGNING",
        TEXT: "Получатель подписывает",
        MOD: "orange",
    },
    FOR_SIGNING: {
        VALUE: "FOR_SIGNING",
        TEXT: "В процессе",
        MOD: "light-green",
    },
    DECLINED: {
        VALUE: "DECLINED",
        TEXT: "Получатель отклонил",
        MOD: "red",
    },
    AWAITING_RECEIVER_FULL_REGISTRATION: {
        VALUE: "AWAITING_RECEIVER_FULL_REGISTRATION",
        TEXT: "Ожидание заполнения профиля получателя",
        MOD: "orange",
    },
    REJECTED: {
        VALUE: "REJECTED",
        TEXT: "Отправка отменена",
        MOD: "red",
    },
};

export const EDO_DOCUMENTS_REGISTRIES_STATUS_ITEMS_OPTIONS = [
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_REGISTRATION.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_REGISTRATION.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_REGISTRATION.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.SIGNED.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.SIGNED.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.SIGNED.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.ERROR.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.ERROR.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.ERROR.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_SIGNING.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_SIGNING.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_SIGNING.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.FOR_SIGNING.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.FOR_SIGNING.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.FOR_SIGNING.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.DECLINED.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.DECLINED.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.DECLINED.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_FULL_REGISTRATION.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_FULL_REGISTRATION.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_FULL_REGISTRATION.VALUE,
    },
    {
        key: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.REJECTED.VALUE,
        text: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.REJECTED.TEXT,
        value: EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.REJECTED.VALUE,
    },
];