import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {DISPUTE_MESSAGES} from "../constants/messages";

const controller = "/dispute";

const DISPUTE_EMPTY_CREATE_REQUEST = "DISPUTE_EMPTY_CREATE_REQUEST";
const DISPUTE_EMPTY_CREATE_SUCCESS = "DISPUTE_EMPTY_CREATE_SUCCESS";
const DISPUTE_EMPTY_CREATE_ERROR = "DISPUTE_EMPTY_CREATE_ERROR";

const DISPUTE_UPDATE_REQUEST = "DISPUTE_UPDATE_REQUEST";
const DISPUTE_UPDATE_SUCCESS = "DISPUTE_UPDATE_SUCCESS";
const DISPUTE_UPDATE_ERROR = "DISPUTE_UPDATE_ERROR";

const DISPUTE_LIST_GET_REQUEST = "DISPUTE_LIST_GET_REQUEST";
const DISPUTE_LIST_GET_SUCCESS = "DISPUTE_LIST_GET_SUCCESS";
const DISPUTE_LIST_GET_ERROR = "DISPUTE_LIST_GET_ERROR";

const DISPUTE_UPDATE_FIELD_STORE = "DISPUTE_UPDATE_FIELD_STORE";

const DISPUTE_GET_REQUEST = "DISPUTE_GET_REQUEST";
const DISPUTE_GET_SUCCESS = "DISPUTE_GET_SUCCESS";
const DISPUTE_GET_ERROR = "DISPUTE_GET_ERROR";

const DISPUTE_UPDATE_STATUS_REQUEST = "DISPUTE_UPDATE_STATUS_REQUEST";
const DISPUTE_UPDATE_STATUS_SUCCESS = "DISPUTE_UPDATE_STATUS_SUCCESS";
const DISPUTE_UPDATE_STATUS_ERROR = "DISPUTE_UPDATE_STATUS_ERROR";

const DISPUTE_GET_TREATMENT_CHANNEL_REQUEST = "DISPUTE_GET_TREATMENT_CHANNEL_REQUEST";
const DISPUTE_GET_TREATMENT_CHANNEL_SUCCESS = "DISPUTE_GET_TREATMENT_CHANNEL_SUCCESS";
const DISPUTE_GET_TREATMENT_CHANNEL_ERROR = "DISPUTE_GET_TREATMENT_CHANNEL_ERROR";

const DISPUTE_GET_CAUSE_DISPUTE_REQUEST = "DISPUTE_GET_CAUSE_DISPUTE_REQUEST";
const DISPUTE_GET_CAUSE_DISPUTE_SUCCESS = "DISPUTE_GET_CAUSE_DISPUTE_SUCCESS";
const DISPUTE_GET_CAUSE_DISPUTE_ERROR = "DISPUTE_GET_CAUSE_DISPUTE_ERROR";

const DISPUTE_GET_DISPUTE_STATUS_LIST_REQUEST = "DISPUTE_GET_DISPUTE_STATUS_LIST_REQUEST";
const DISPUTE_GET_DISPUTE_STATUS_LIST_SUCCESS = "DISPUTE_GET_DISPUTE_STATUS_LIST_SUCCESS";
const DISPUTE_GET_DISPUTE_STATUS_LIST_ERROR = "DISPUTE_GET_DISPUTE_STATUS_LIST_ERROR";

const DISPUTE_GET_CHAT_LIST_REQUEST = "DISPUTE_GET_CHAT_LIST_REQUEST";
const DISPUTE_GET_CHAT_LIST_SUCCESS = "DISPUTE_GET_CHAT_LIST_SUCCESS";
const DISPUTE_GET_CHAT_LIST_ERROR = "DISPUTE_GET_CHAT_LIST_ERROR";

const DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST = "DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST";
const DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS = "DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS";
const DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR = "DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR";


//*  INITIAL STATE  *//

const initial = {
    disputesList: [],
    guid: "",
    loading: false,
    isDisputeSaveSuccess: false,
    isDisputeChangeStatusSuccess: false,
    error: {},
    pageData: {},
    totalCount: 0,
    totalCountDisputesOpen: 0,
    dispute: {},
    listCounter: [{}],
    treatmentChannels: [],
    disputeCauses: [],
    disputeStatusList: [],
    chatList: [],
    listCounts: [],
    isAddToChatSuccess: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case DISPUTE_UPDATE_FIELD_STORE:
        const {
            field,
            value,
        } = payload;
        return {
            ...state,
            [field]: value,
        };
    case DISPUTE_EMPTY_CREATE_REQUEST:
        return {
            ...state,
            guid: "",
            isDisputeSaveSuccess: false,
            loading: true,
            dispute: {},
        };
    case DISPUTE_GET_CHAT_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            isAddToChatSuccess: false,
            loading: true,
        };
    case DISPUTE_LIST_GET_REQUEST:
        return {
            ...state,
            pageData: payload,
            isDisputeSaveSuccess: false,
            loading: true,
        };
    case DISPUTE_UPDATE_STATUS_REQUEST:
        return {
            ...state,
            isDisputeChangeStatusSuccess: false,
            loading: true,
        };
    case DISPUTE_GET_CHAT_LIST_SUCCESS:
        return {
            ...state,
            chatList: payload.disputes,
            totalCount: payload.totalCount,
            totalCountOpen: payload.totalCountOpen,
            loading: false,
        };
    case DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS:
        return {
            ...state,
            totalCountDisputesOpen: payload,
        };
    case DISPUTE_LIST_GET_SUCCESS:
        return {
            ...state,
            disputesList: payload.disputes,
            totalCount: payload.totalCount,
            totalCountOpen: payload.totalCountOpen,
            totalCountCloseCancel: payload.totalCountCloseCancel,
            loading: false,
        };
    case DISPUTE_GET_TREATMENT_CHANNEL_SUCCESS:
        return {
            ...state,
            treatmentChannels: payload,
        };
    case DISPUTE_GET_CAUSE_DISPUTE_SUCCESS:
        return {
            ...state,
            disputeCauses: payload,
        };
    case DISPUTE_GET_DISPUTE_STATUS_LIST_SUCCESS:
        return {
            ...state,
            disputeStatusList: payload,
        };
    case DISPUTE_GET_SUCCESS:
        return {
            ...state,
            disputeLoading: false,
            dispute: payload,
        };
    case DISPUTE_EMPTY_CREATE_SUCCESS:
        return {
            ...state,
            guid: payload.guid,
            loading: false,
        };
    case DISPUTE_UPDATE_REQUEST:
        return {
            ...state,
            loading: false,
        };
    case DISPUTE_GET_REQUEST:
        return {
            ...state,
            disputeLoading: true,
        };
    case DISPUTE_UPDATE_STATUS_SUCCESS:
        return {
            ...state,
            isDisputeChangeStatusSuccess: true,
            loading: false,
        };
    case DISPUTE_UPDATE_SUCCESS:
        return {
            ...state,
            isDisputeSaveSuccess: true,
            loading: false,
        };
    case DISPUTE_GET_ERROR:
        return {
            ...state,
            disputeLoading: false,
            error: payload,
        };
    case DISPUTE_EMPTY_CREATE_ERROR:
    case DISPUTE_LIST_GET_ERROR:
    case DISPUTE_UPDATE_STATUS_ERROR:
    case DISPUTE_GET_CAUSE_DISPUTE_ERROR:
    case DISPUTE_GET_DISPUTE_STATUS_LIST_ERROR:
    case DISPUTE_GET_CHAT_LIST_ERROR:
    case DISPUTE_UPDATE_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function getTreatmentChannelDict() {
    return {type: DISPUTE_GET_TREATMENT_CHANNEL_REQUEST};
}

export function getDisputeCausesDict() {
    return {type: DISPUTE_GET_CAUSE_DISPUTE_REQUEST};
}

export function getDisputeStatusDict() {
    return {type: DISPUTE_GET_DISPUTE_STATUS_LIST_REQUEST};
}

export function createEmptyDispute() {
    return {type: DISPUTE_EMPTY_CREATE_REQUEST};
}

export function updateStatusDispute(payload) {
    return {type: DISPUTE_UPDATE_STATUS_REQUEST, payload};
}

export function updateDispute(payload) {
    return {type: DISPUTE_UPDATE_REQUEST, payload};
}

export function getDisputesList(payload) {
    return {type: DISPUTE_LIST_GET_REQUEST, payload};
}

export function getChatList(payload) {
    return {type: DISPUTE_GET_CHAT_LIST_REQUEST, payload};
}

export function getDisputeById(payload) {
    return {type: DISPUTE_GET_REQUEST, payload};
}

export function getOpenDisputesTotalCount() {
    return {type: DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST};
}

export function updateFieldDisputeStore(field, value) {
    return {
        type: DISPUTE_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

//*  SELECTORS  *//

export const disputesSelector = state => state.disputesList;

export const emptyDisputeGuidSelector = createSelector(disputesSelector, ({guid}) => guid);

export const disputeSelector = createSelector(disputesSelector, ({dispute}) => dispute);

export const disputesListSelector = createSelector(disputesSelector, ({disputesList}) => disputesList);

export const disputeListLoadingSelector = createSelector(disputesSelector, ({loading}) => loading);

export const disputeLoadingSelector = createSelector(disputesSelector, ({disputeLoading}) => disputeLoading);

export const disputesChatListSelector = createSelector(disputesSelector, ({chatList}) => chatList);

export const disputesTotalCountOpenSelector = createSelector(disputesSelector, ({totalCountDisputesOpen}) => totalCountDisputesOpen);

export const disputesSelfTotalCountOpenSelector = createSelector(disputesSelector, ({totalCountOpen}) => totalCountOpen);

export const disputesChatsTotalCountSelector = createSelector(disputesSelector, ({totalCountOpen}) => totalCountOpen);

export const disputesTotalCountCloseSelector = createSelector(disputesSelector, ({totalCountCloseCancel}) => totalCountCloseCancel);

export const disputeSaveSuccessSelector = createSelector(disputesSelector, ({isDisputeSaveSuccess}) => isDisputeSaveSuccess);

export const disputeChangeStatusSuccessSelector = createSelector(disputesSelector, ({isDisputeChangeStatusSuccess}) => isDisputeChangeStatusSuccess);

export const disputeTotalCountForOrderCardSelector = createSelector(disputesSelector, ({totalCount}) => totalCount);

export const disputeTotalPagesSelector = createSelector(disputesSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const treatmentChannelsDictsOptionsSelector = createSelector(disputesSelector, ({treatmentChannels}) => {
    const result = Object.assign({}, treatmentChannels);
    delete result.MOBILE_APP;
    return dictionaryToOptions(result);
});

export const treatmentChannelsDictsOptionsWithMobileSelector = createSelector(disputesSelector, ({treatmentChannels}) => dictionaryToOptions(treatmentChannels));

export const causesDisputeDictsOptionsSelector = createSelector(disputesSelector, ({disputeCauses}) => dictionaryToOptions(disputeCauses));
export const causesDisputeDictSelector = createSelector(disputesSelector, ({disputeCauses}) => disputeCauses);

export const disputeStatusSelector = createSelector(disputesSelector, ({disputeStatusList}) => disputeStatusList);


//*  SAGA  *//
export const getOpenDisputesTotalCountSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getDisputeOpenCount`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR, payload: error});
    }
};

export const getDisputeStatusDictsSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/dicts/disputeStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_GET_DISPUTE_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_GET_DISPUTE_STATUS_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISPUTE_GET_DISPUTE_STATUS_LIST_ERROR, payload: error});
    }
};

export const getTreatmentChannelDictsSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/dicts/treatmentChannel`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_GET_TREATMENT_CHANNEL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_GET_TREATMENT_CHANNEL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISPUTE_GET_TREATMENT_CHANNEL_ERROR, payload: error});
    }
};

export const getCausesDisputeDictsSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/dicts/disputeCause`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_GET_CAUSE_DISPUTE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_GET_CAUSE_DISPUTE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DISPUTE_GET_CAUSE_DISPUTE_ERROR, payload: error});
    }
};

export const createEmptyDisputeSaga = function* () {
    try {
        const result = yield request.post(`${controller}/add`, {});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_EMPTY_CREATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_EMPTY_CREATE_SUCCESS, payload: result});
    } catch (error) {
        toastError(DISPUTE_MESSAGES.EMPTY_DISPUTE_CREATE_ERROR);

        yield put({
            type: DISPUTE_EMPTY_CREATE_ERROR,
            payload: error,
        });
    }
};

export const updateDisputeSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({type: DISPUTE_UPDATE_SUCCESS, payload: result});

        toastSuccess(DISPUTE_MESSAGES.DISPUTE_CREATE_SUCCESS);
    } catch (error) {
        toastError(DISPUTE_MESSAGES.EMPTY_DISPUTE_CREATE_ERROR);

        yield put({
            type: DISPUTE_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const getDisputesListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DISPUTE_LIST_GET_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }
        yield put({type: DISPUTE_LIST_GET_SUCCESS, payload: result});
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_GET_LIST_ERROR);
        yield put({
            type: DISPUTE_LIST_GET_ERROR,
            payload: error,
        });
    }
};

export const getDisputesChatListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPageByDisputeId`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: DISPUTE_GET_CHAT_LIST_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_GET_CHAT_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_GET_LIST_ERROR);

        yield put({
            type: DISPUTE_GET_CHAT_LIST_ERROR,
            payload: error,
        });
    }
};

export const updateStatusDisputeSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/changeStatusDispute`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_UPDATE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        toastSuccess(DISPUTE_MESSAGES.DISPUTE_CHANGE_STATUS_SUCCESS);

        yield put({type: DISPUTE_UPDATE_STATUS_SUCCESS, payload: result});
        yield put(getOpenDisputesTotalCount());
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_CHANGE_STATUS_ERROR);
        yield put({
            type: DISPUTE_UPDATE_STATUS_ERROR,
            payload: error,
        });
    }
};

export const getDisputeByIdSaga = function* (action) {
    try {
        const {payload: disputeId} = action;
        const result = yield request.get(`${controller}/getById/${disputeId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISPUTE_GET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: DISPUTE_GET_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: DISPUTE_GET_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(DISPUTE_GET_CHAT_LIST_REQUEST, getDisputesChatListSaga),
        takeEvery(DISPUTE_EMPTY_CREATE_REQUEST, createEmptyDisputeSaga),
        takeEvery(DISPUTE_UPDATE_REQUEST, updateDisputeSaga),
        takeEvery(DISPUTE_LIST_GET_REQUEST, getDisputesListSaga),
        takeEvery(DISPUTE_GET_REQUEST, getDisputeByIdSaga),
        takeEvery(DISPUTE_UPDATE_STATUS_REQUEST, updateStatusDisputeSaga),
        takeEvery(DISPUTE_GET_TREATMENT_CHANNEL_REQUEST, getTreatmentChannelDictsSaga),
        takeEvery(DISPUTE_GET_CAUSE_DISPUTE_REQUEST, getCausesDisputeDictsSaga),
        takeEvery(DISPUTE_GET_DISPUTE_STATUS_LIST_REQUEST, getDisputeStatusDictsSaga),
        takeEvery(DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST, getOpenDisputesTotalCountSaga),
    ]);
}

