import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import ExtLink from "../../../../components/ExtLink";
import {SUB_PAGE_ADVERTISEMENT} from "../../../advertisement/card";
import OrderListStatus from "../../../order/order-list/order-list-status";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {getTargetAge} from "../../../advertisement/card/utils";

import {GENDER_DICT} from "../../../../constants/contractorInfo";
import {LINK_CLIENT_CARD_ADVERTISEMENT} from "../../../../constants/links";

import {getCitizenshipSelector} from "../../../../ducks/contractor";

const MapAdvertListCard = (props) => {
    const {
        advert,
        isModalCard = false,
    } = props;

    const citizenshipDict = useSelector(getCitizenshipSelector);

    const [isShowMore, setIsShowMore] = useState(false);

    const {
        publicationDateFrom,
        publicationDateTo,
        dateFrom,
        dateTo,
        status,
        advertId,
        createDate,
        name,
        gender,
        countContractors,
        citizenships,
        ageFrom,
        ageTo,
        description,
        clientId,
        fioManager,
        countContractorInterest,
        countContractorNotInterest,
        countContractorOrderHired,
        countContractorOrderPayment,
        countContractorViews,
        speciality,
        workAddressFias,
    } = advert;

    const cardLink = LINK_CLIENT_CARD_ADVERTISEMENT
        .replace(":clientId", clientId)
        .replace(":advertisementId", advertId)
        .replace(":subPage", SUB_PAGE_ADVERTISEMENT.INTERESTED.LINK);

    const getShowMore = (content, title) => {
        return (
            <div className="advertisement-list__show-more-container">
                <NmShowMoreText
                    anchor="blue"
                    title={title}
                    lines={2}
                    children={content}
                    more="Подробнее"
                />
            </div>
        );
    };

    const renderCitizenship = () => {
        if (isEmpty(citizenships)) {
            return "Все";
        }

        const citizenshipList = citizenships.map(citizenship => citizenshipDict[citizenship]);

        if (citizenshipList.length <= 2) {
            return citizenshipList.join(", ");
        }

        const citizenshipListString = (isShowMore ? citizenshipList : citizenshipList.slice(0, 2)).join(", ");

        return <>
            {citizenshipListString}
            <span
                className="advertisement-list__more"
                onClick={() => setIsShowMore(prev => !prev)}
            >
                {isShowMore ? " Cкрыть" : " Ещё"}
            </span>
        </>;
    };

    const modalCardLabels = isModalCard ? [
        {
            label: "Охват",
            text: countContractors || 0,
        },
        {
            label: "Просмотры",
            text: countContractorViews || 0,
        },
        {
            label: "Не интересно",
            text: countContractorNotInterest || 0,
        },
        {
            label: "Интересно",
            text: countContractorInterest || 0,
        },
        {
            label: "Нанято",
            text: countContractorOrderHired || 0,
        },
        {
            label: "Получили выплату",
            text: countContractorOrderPayment || 0,
        },
    ] : [];

    return (
        <NmListCard
            alignItems="flex-end"
            secondaryHeaderStatus={
                <OrderListStatus
                    status={status}
                    isAdvertisement
                />
            }
            secondaryHeader={`Объявление от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
            primaryHeader={
                <ExtLink
                    title={name}
                    historyEnabled={true}
                    to={cardLink}
                >
                    {name}
                </ExtLink>
            }
            primarySubHeader={getShowMore(description, name)}
            primaryHeaderTwoLines={true}
            labels={[
                {label: "Ответственный", text: fioManager},
                {
                    label: "Период публикации",
                    text: `${formatLocalDate(publicationDateFrom)} - ${formatLocalDate(publicationDateTo)}`,
                    noWrap: false,
                },
                {
                    label: "Период в объявлении",
                    text: `${formatLocalDate(dateFrom)} - ${formatLocalDate(dateTo)}`,
                    noWrap: false,
                },
                {
                    label: "Вид деятельности",
                    text: speciality?.value || "-",
                    noWrap: false,
                },
                {
                    label: "Город ведения деятельности",
                    text: workAddressFias?.value || "-",
                },
                {
                    label: "Гражданство",
                    text: renderCitizenship(),
                    noWrap: false,
                    inline: true,
                },
                {
                    label: "Пол",
                    text: isNullOrWhitespace(gender) ? "Все" : GENDER_DICT[gender],
                    noWrap: false,
                },
                {
                    label: "Возраст",
                    text: getTargetAge(ageFrom, ageTo),
                    noWrap: false,
                },
                ...modalCardLabels,
            ]}
            cards={
                !isModalCard && [
                    {
                        title: "Охват",
                        value: countContractors,
                        className: "col-16 col-md-4 col-xl-2 col-xxl-2 mt-xl-4 mt-md-4",
                    },
                    {
                        title: "Просмотры",
                        value: countContractorViews || 0,
                        className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                    },
                    {
                        title: "Не интересно",
                        value: countContractorNotInterest || 0,
                        className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                    },
                    {
                        title: "Интересно",
                        value: countContractorInterest || 0,
                        className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                    },
                    {
                        title: "Нанято",
                        value: countContractorOrderHired || 0,
                        className: "col-16 col-md-4 col-xl-2 col-xxl-2 mt-xl-4 mt-md-4",
                    },
                    {
                        title: "Получили выплату",
                        value: countContractorOrderPayment || 0,
                        className: "col-16 col-md-4 col-xl-2 col-xxl-3 mt-xl-4 mt-md-4",
                    },
                ]}
            cardsWithContainer={true}
            classNameMainContent={`col-16 ${!isModalCard ? "col-xxl-6" : ""}`}
            cardsContainerClassName="col-16 col-md-14 col-xl-14 col-xxl-9 mt-md-4 mt-xxl-0"
        />
    );
};

export default MapAdvertListCard;