import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getOnboardingLeadRegistrationStatusDict,
    updateCommonPaymentDicts,
} from "../../../../../../../ducks/bff/common/dicts/actionCreators";
import {getOnboardingScenarioLeadsPage, updateOnboardingStore} from "../../../../../../../ducks/onboarding";

export function useFetchOnboardingLeadsList(params) {
    const {
        filter = {},
        pageSize,
        pageNum,
        sortType,
        scenarioId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOnboardingLeadRegistrationStatusDict());

        return () => {
            dispatch(updateCommonPaymentDicts({
                onBoardingLeadRegistrationStatusDict: {},
            }));
            dispatch(updateOnboardingStore({
                leads: {
                    list: [],
                    pageData: {},
                },
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter, sortType]);

    const fetchList = () => {
        dispatch(getOnboardingScenarioLeadsPage({
            ...filter,
            scenarioIdFilter: scenarioId,
            sortType: sortType ? sortType.toUpperCase() : undefined,
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
}