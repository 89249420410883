import * as Yup from "yup";

import {transformYupFormattedAmountToNumber} from "../../../../utils/yupTransforms";

import {requiredMessage} from "../../../../constants/validationRules";

export const depositReturnValidationScheme = Yup.object().shape({
    amount: Yup.number()
        .required(requiredMessage)
        .transform(transformYupFormattedAmountToNumber)
        .lessThan(1000000000, "Сумма должна быть меньше 1\u200a000\u200a000\u200a000 руб."),
    comment: Yup.string()
        .required(requiredMessage)
        .min(5, "Минимальная длина строки 5 символов")
        .max(255, "Максимальная длина - 250 символов"),
    bankType: Yup.string()
        .nullable()
        .required(requiredMessage),
});