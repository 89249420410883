import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        smzMarketplace: getBlockValidation(),
        smzRegistry: getBlockValidation(),
        smzApi: getBlockValidation(),
        civilMarketplace: getBlockValidation(),
        civilRegistry: getBlockValidation(),
        civilApi: getBlockValidation(),
        objectIdForPaidApis: yup.string()
            .nullable()
            .when("distributeDepositByObjects", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};

function getBlockValidation() {
    return yup.object({
        bankTypeCard: yup.string()
            .nullable()
            .when("useBankTypeCard", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        bankTypeSbp: yup.string()
            .nullable()
            .when("useBankTypeSbp", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        bankTypeRequisites: yup.string()
            .nullable()
            .when("useBankTypeRequisites", {
                is: true,
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        paymentMethod: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    }).nullable();
}

export default validationSchema;