import * as yup from "yup";

import {calculateAge} from "../../../../utils/dateFormat";

export const getFullRegistrationBirthDateValidation = () => {
    return yup.string()
        .nullable()
        .test("checkAge", "Данный сервис доступен для лиц старше 14 лет", function (value) {
            if (!value) {
                return true;
            }

            const userAges = calculateAge(value);

            return userAges >= 14;
        });
};