import React, {Component} from "react";
import {connect} from "react-redux";

import {ProjectsObjectsCheckboxList} from "../ProjectsObjectsCheckboxList";

import {getProjectsList, getProjectsOptionsSelector} from "../../ducks/projects";

import "./style.sass";

class Test extends Component {
    state = {
        checked: ["client-1-project-1", "client-1-project-3-object-5", "client-1-project-3-object-7"],
        expanded: [],
    };

    addObjects() {
        const {tree} = this.state;

        const loadedObjects = [
            {objectId: "client-1-project-3-object-4", label: "Объект 4 (Loaded)"},
            {objectId: "client-1-project-3-object-5", label: "Объект 5 (Loaded)"},
            {objectId: "client-1-project-3-object-6", label: "Объект 6 (Loaded)"},
            {objectId: "client-1-project-3-object-7", label: "Объект 7 (Loaded)"},
        ].map(item => {
            return {
                value: item.objectId,
                label: item.label,
            };
        });
        const projectId = "client-1-project-3";

        const newTree = tree.map(item => {
            return {
                ...item,
                children: item.children.map(item => {
                    if (item.value === projectId) {
                        return {
                            ...item,
                            children: [
                                ...item.children,
                                ...loadedObjects,
                            ],
                        };
                    }

                    return item;
                }),
            };
        });

        this.setState({
            tree: newTree,
        });
    }

    render() {
        return (
            <div className="test">
                <ProjectsObjectsCheckboxList />
                <div>
---------------------------------
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        projectOptions: getProjectsOptionsSelector(state),
    }),
    {
        getProjectsList,
    },
)(Test);
