import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";
import {OrderEditContext} from "../../../../context-provider";

import {getProjectsOptionsForObjectEditSelector} from "../../../../../../../ducks/projects";

const OrderEditPreConditionsProject = (props) => {
    const {
        disabled,
        error,
        formData,
        onFocus,
        className,
    } = props;
    const {
        setForm,
    } = useContext(OrderEditContext);

    const onChange = (event, {name, value}) => {
        if (!isEmpty(formData.templateObjectIds)) {
            setForm({
                [name]: value,
                templateObjectIds: [],
            });

            return;
        }

        setForm({
            [name]: value,
        });
    };

    const projectOptions = useSelector(getProjectsOptionsForObjectEditSelector);

    return (
        <NmDropdownV2
            isAllSelectionShown
            className={className}
            size="xl"
            label="Проект"
            disabled={disabled}
            search
            required
            multiple
            name="templateProjectIds"
            options={projectOptions}
            error={error}
            value={formData.templateProjectIds}
            placeholder="Выберите из списка"
            onChange={onChange}
            onFocus={onFocus}
        />
    );
};

export default OrderEditPreConditionsProject;