import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import Text from "../../../../components/ActualComponents/Text";
import NmButton from "../../../../components/NmButton";
import {ReactComponent as SettingsIcon} from "../../../../images/settings_24.svg";
import {ReactComponent as UserIcon} from "../../../../images/user_24.svg";
import ChatMobileHeader from "../mobile-header";

import bem from "../../../../utils/bem";
import {getFullName} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {COLOR} from "../../../../constants/color";
import {CHAT_LINK_PARAMS, DISPUTE_FIELD} from "../../../../constants/disputes";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";
import {CHAT_ACTION, CHAT_OPTION} from "../constants";

import {causesDisputeDictsOptionsSelector} from "../../../../ducks/dispute";

import "./style.sass";

const ChatDisputeHeader = (props) => {
    const {
        showDisputeInfo,
        dispute,
        currentClientUserId,
        role,
        fullName,
        isOpenChat,
        mobile,
        onClickBackToChatList,
        desktop,
        handleOnChangeDisputeChat,
        showApprovedBlock,
    } = props;
    const disputeCauses = useSelector(causesDisputeDictsOptionsSelector);
    const [, element] = bem("chat-dispute-header");
    const {t} = useTranslation();

    const disputeCauseObj = disputeCauses.find(item => item.value === dispute[DISPUTE_FIELD.CAUSE_DISPUTE]);
    const disputeCause = disputeCauseObj ? disputeCauseObj.text : "";

    const contractorFullName = getFullName(dispute[DISPUTE_FIELD.CONTRACTOR_LAST_NAME], dispute[DISPUTE_FIELD.CONTRACTOR_FIRST_NAME], dispute[DISPUTE_FIELD.CONTRACTOR_PATRONYMIC]);
    const managerFullName = getFullName(dispute[DISPUTE_FIELD.MANAGER_LAST_NAME], dispute[DISPUTE_FIELD.MANAGER_FIRST_NAME], dispute[DISPUTE_FIELD.MANAGER_PATRONYMIC]);

    const getContextMenuOptions = () => {
        const closeDispute = [ADMIN, NM_MANAGER].includes(role) && isNullOrWhitespace(dispute[DISPUTE_FIELD.DISPUTE_STATUS_RESULT]) ?
            [CHAT_OPTION.CLOSE_DISPUTE] : [];
        const invite = dispute[DISPUTE_FIELD.STATUS] === CHAT_LINK_PARAMS.OPEN.toUpperCase() &&
        (
            [ADMIN, NM_MANAGER].includes(role) ||
            currentClientUserId === dispute[DISPUTE_FIELD.ORDER_MANAGER_ID]
        ) ?
            [CHAT_OPTION.INVITE] : [];

        return [
            ...closeDispute,
            ...invite,
            CHAT_OPTION.OPEN_DISPUTE_SETTINGS,
        ];
    };

    const onClickContextMenu = ({value: action}) => {
        switch (action) {
        case CHAT_ACTION.CLOSE_DISPUTE:
            showApprovedBlock(true);

            return;
        case CHAT_ACTION.OPEN_DISPUTE_SETTINGS:
            showDisputeInfo();

            return;
        case CHAT_ACTION.INVITE:
            handleOnChangeDisputeChat(null, {value: true, name: "isShowAddBlock"});

            return;
        default: return;
        }
    };

    const getControls = () => {
        if (!desktop) {
            return (
                <>
                    {
                        isOpenChat && mobile &&
                        <ChatMobileHeader
                            onClickBackToChatList={onClickBackToChatList}
                            fullName={fullName}
                        />
                    }
                    <ContextMenu
                        className="ms-2 ms-md-5"
                        inline={false}
                        options={getContextMenuOptions()}
                        onClickItem={onClickContextMenu}
                    />
                </>
            );
        }

        return (
            <>
                {
                    [ADMIN, NM_MANAGER].includes(role) && isNullOrWhitespace(dispute[DISPUTE_FIELD.DISPUTE_STATUS_RESULT]) &&
                    <NmButton
                        size="lg"
                        color="green"
                        className="chat-dispute-header__close"
                        onClick={() => {showApprovedBlock(true);}}
                    >
                        {t("disputes-list.close-dispute")}
                    </NmButton>
                }
                {
                    <NmButton
                        color="grey"
                        size="lg"
                        icon={<SettingsIcon />}
                        className="me-2"
                        onClick={showDisputeInfo}
                    >
                        {t("chat.dispute-parameters")}
                    </NmButton>
                }
                {
                    dispute[DISPUTE_FIELD.STATUS] === CHAT_LINK_PARAMS.OPEN.toUpperCase() &&
                    (
                        [ADMIN, NM_MANAGER].includes(role) ||
                        currentClientUserId === dispute[DISPUTE_FIELD.ORDER_MANAGER_ID]
                    ) &&
                    <NmButton
                        size="lg"
                        color="grey"
                        icon={<UserIcon />}
                        onClick={(e) => {
                            handleOnChangeDisputeChat(e, {value: true, name: "isShowAddBlock"});
                        }}
                    >
                        {t("chat.invite-button")}
                    </NmButton>
                }
            </>
        );
    };

    return (
        <>
            {
                isOpenChat && mobile ?
                    null :
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_70}
                        className={element("text")}
                    >
                        {`${t("chat.header-msg-contractor")} ${contractorFullName} ${t("chat.header-msg-with-him")} ${managerFullName}, ${t("chat.order")} "${dispute[DISPUTE_FIELD.ORDER_NAME]}" ${t("chat.reason")} "${disputeCause}".`}
                    </Text>
            }
            {getControls()}
        </>
    );
};

export default ChatDisputeHeader;