import React, {Component, ReactNode} from "react";

import Truncate from "./Trunc";

export interface IShowMoreTextProps {
    anchorClass?: string;
    children: string;
    className?: string;
    expanded?: boolean;
    keepNewLines?: boolean;
    less?: React.ReactNode;
    lines?: number;
    more?: React.ReactNode;
    onClick?: ((expanded: boolean) => void) | undefined;
    truncatedEndingComponent?: string | ReactNode,
    expandByClick?: boolean,
    width?: number;
}

interface IShowMoreTextState {
    expanded: boolean,
    truncated: boolean
}

class ShowMoreText extends Component<IShowMoreTextProps, IShowMoreTextState> {
    static defaultProps = {
        lines: 3,
        more: "Show more",
        less: "Show less",
        anchorClass: "",
        onClick: undefined,
        expanded: false,
        width: 0,
        keepNewLines: false,
        truncatedEndingComponent: "... ",
    };

    truncateRef: Truncate | null = null;

    constructor(props: IShowMoreTextProps) {
        super(props);

        this.state = {
            expanded: false,
            truncated: false,
        };
    }

    componentDidMount() {
        this.setState({
            expanded: Boolean(this.props.expanded),
        });
    }

    handleTruncate = (truncated: boolean) => {
        if (truncated !== this.state.truncated) {
            this.setState({
                truncated,
            });

            if (truncated && this.truncateRef) {
                this.truncateRef.onResize();
            }
        }
    };

    toggleLines = (event: React.MouseEvent<HTMLAnchorElement>) => {
        const {
            expandByClick = false,
            onClick,
        } = this.props;
        event.preventDefault();

        if (!expandByClick) {
            if (onClick) {
                onClick(this.state.expanded);
            }

            return;
        }

        this.setState(
            {
                expanded: !this.state.expanded,
            },
            () => {
                if (onClick) {
                    onClick(this.state.expanded);
                }
            },
        );
    };

    render() {
        const {
            children,
            more,
            less,
            lines,
            anchorClass,
            className,
            width,
            keepNewLines,
            truncatedEndingComponent,
        } = this.props;

        const {
            expanded,
            truncated,
        } = this.state;

        return (
            <div className={className}>
                <Truncate
                    width={width}
                    lines={!expanded && lines}
                    ellipsis={
                        <span>
                            {truncatedEndingComponent}
                            <a
                                href=""
                                className={anchorClass}
                                onClick={this.toggleLines}
                            >
                                {more}
                            </a>
                        </span>
                    }
                    onTruncate={this.handleTruncate}
                    ref={(ref) => (this.truncateRef = ref)}
                >
                    {
                        keepNewLines ?
                            children.split("\n").map((line, i, arr) => {
                                const _line = <span key={i}>
                                    {line}
                                </span>;

                                if (i === arr.length - 1) {
                                    return _line;
                                } else {
                                    return [_line, <br key={i + "br"} />];
                                }
                            },
                            ) : children
                    }
                </Truncate>
                {
                    !truncated && expanded && (
                        <span>
                            {" "}
                            <a
                                href=""
                                className={anchorClass}
                                onClick={this.toggleLines}
                            >
                                {less}
                            </a>
                        </span>
                    )
                }
            </div>
        );
    }
}

export default ShowMoreText;