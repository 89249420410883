import React from "react";
import {useSelector} from "react-redux";

import NmMiniInfoCard from "../../../../../components/ActualComponents/NmMiniInfoCard";
import ContractorInfoCardContent from "../../../../../components/ContractorInfoCardContent";
import LocatedOutsideRussiaTooltip from "../../../../../components/LocatedOutsideRussiaTooltip";
import ContractorContactInfoEditForm from "./components/edit-form";

import {useInnInfoCardAction} from "./hooks/useAction";

import {phoneFormat} from "../../../../../utils/stringFormat";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

import {contractorCardSelector} from "../../../../../ducks/contractor";

const ContactInfoCard = (props) => {
    const {
        className,
        isEditable,
    } = props;

    const card = useSelector(contractorCardSelector);
    const {
        contractorId,
        contactDetailsUpdatedDateTime,
    } = card;

    const {
        isOpenEditForm,
        toggleEditModal,
    } = useInnInfoCardAction({});

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <ContractorContactInfoEditForm
                contractorId={contractorId}
                onClose={toggleEditModal}
            />
        );
    };

    return (
        <NmMiniInfoCard
            className={className}
            title="Контактные данные"
            titleLevel="4"
            onClickEdit={toggleEditModal}
            accessEdit={isEditable}
            lastUpdate={contactDetailsUpdatedDateTime}
        >
            {renderEditForm()}
            <ContractorInfoCardContent
                labels={[
                    {
                        label: "Телефон",
                        text: phoneFormat(card[CONTRACTOR_FIELD.PHONE], card.locatedOutsideRussia),
                        textTooltip:
                            card.locatedOutsideRussia &&
                            <LocatedOutsideRussiaTooltip />,
                    },
                    {label: "Email", text: card[CONTRACTOR_FIELD.EMAIL]},
                ]}
            />
        </NmMiniInfoCard>
    );
};

export default ContactInfoCard;