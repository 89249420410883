import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Box from "../../../components/ActualComponents/Box";
import Filter from "../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import CheckboxList from "../../../components/CheckboxList";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import CustomJsonPretty from "../../../components/СustomJsonPretty";

import {useModal} from "../../../hooks/useModal";
import {usePagination} from "../../../hooks/usePagination";
import {useKedoLogsFetch} from "./hooks/useFetch";
import {useKedoLogsFilters} from "./hooks/useFilters";

import {formatLocalDate} from "../../../utils/dateFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS,
    KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE,
} from "./constants";

import {
    bffKedoLogsListSelector,
    bffKedoLogsProgressSelector,
    bffKedoLogsTotalCountSelector,
    bffKedoLogsTotalPagesSelector,
} from "../../../ducks/kedo/logs/selectors";

export const KedoLogs = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffKedoLogsListSelector);
    const totalCount = useSelector(bffKedoLogsTotalCountSelector);
    const totalPages = useSelector(bffKedoLogsTotalPagesSelector);
    const progress = useSelector(bffKedoLogsProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {
        modalData: detailsModalData,
        isOpen: isOpenDetailsModal,
        onOpenModal: onOpenDetailsModal,
        onCloseModal: onCloseDetailsModal,
    } = useModal();

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useKedoLogsFilters({
        pageSize,
        setPagination,
    });

    useKedoLogsFetch({
        pageNum,
        pageSize,
        clientId,
        filter: filterData,
    });

    const getMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "lg",
                        color: "grey",
                        children: "Посмотреть детали",
                        onClick: () => onOpenDetailsModal(item),
                    },
                    visible: !isEmpty(item.details),
                },
            ],
        };
    };

    const getRows = () => {
        return list.map(item => {
            const {
                requestDate,
                requestType,
                status,
                logId,
                requestId,
            } = item;

            return {
                ...item,
                key: logId,
                contentRow: (
                    <NmListCard
                        noDivider={true}
                        classNameMainContent="col-16 col-xxl-14"
                        secondaryHeader={`Дата запроса: ${formatLocalDate(requestDate, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE[requestType]?.TEXT || requestType}
                        labels={[
                            {
                                label: "Статус",
                                text: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS[status]?.TEXT || status,
                            },
                            {
                                label: "id лога",
                                text: logId || "-",
                            },
                            {
                                label: "id запроса",
                                text: requestId || "-",
                            },
                        ]}
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderDetailsModal = () => {
        return (
            isOpenDetailsModal &&
            <NmModal
                size="md"
                onClose={onCloseDetailsModal}
                header={
                    <NmTitle size="lg">
                        Детали
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submit={onCloseDetailsModal}
                        submitBtnContent="Закрыть"
                    />
                }
            >
                <Box className="col-16">
                    <CustomJsonPretty
                        data={detailsModalData.details}
                    />
                </Box>
            </NmModal>
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Лог интеграции с 1С
                </NmTitle>
            }
            openFilter={true}
            typeFilter="vertical"
            widthByFilter={true}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={progress}
        >
            {renderDetailsModal()}
            {
                isEmpty(list) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};