import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/address";

const GET_ADDRESS_BY_ID_REQUEST = "GET_ADDRESS_BY_ID_REQUEST";
const GET_ADDRESS_BY_ID_SUCCESS = "GET_ADDRESS_BY_ID_SUCCESS";
const GET_ADDRESS_BY_ID_ERROR = "GET_ADDRESS_BY_ID_ERROR";


const initial = {
    progress: false,
    address: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_ADDRESS_BY_ID_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case GET_ADDRESS_BY_ID_SUCCESS:
        return {
            ...state,
            progress: false,
            address: payload,
        };
    case GET_ADDRESS_BY_ID_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    default:
        return state;
    }
};

export function getAddressById(payload) {
    return {
        type: GET_ADDRESS_BY_ID_REQUEST,
        payload,
    };
}


export const addressSelector = state => state.address;

export const adressByIdSelector = createSelector(addressSelector, ({address}) => address);

export const adressByIdProgressSelector = createSelector(addressSelector, ({progress}) => progress);

export const getAddressByIdSaga = function* (action) {
    try {
        const {
            payload: {
                data: params,
                onSuccess,
            },
        } = action;
        const result = yield request.get(`${controller}/getAddressById`, {params});

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }
        yield put({
            type: GET_ADDRESS_BY_ID_SUCCESS, payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {

        yield put({
            type: GET_ADDRESS_BY_ID_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ADDRESS_BY_ID_REQUEST, getAddressByIdSaga),
    ]);
}