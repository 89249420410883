import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {getRegistryInvitationsPage} from "../../../../../ducks/registryInvitations";

export function useRegistryInvitationsFetchList({clientId, pageSize, pageNum, filter}) {
    const dispatch = useDispatch();

    const [archived, setArchived] = useState(false);

    useEffect(() => {
        fetchList();
    }, [filter, archived, pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getRegistryInvitationsPage({
            archived,
            clientId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        archived,
        setArchived,
        fetchList,
    };
}