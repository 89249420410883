import {useEffect, useState} from "react";
import {isEmpty} from "lodash";

export const useFullRegistrationNavigation = (params) => {
    const {
        step,
    } = params;

    const [currentStep, setStep] = useState({});
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        if (steps.length === 0) {
            return;
        }

        if (!isEmpty(currentStep)) {
            const step = steps.find((item) => (item.value === currentStep.value));

            updateStep({
                isLastStep: currentStep.isLastStep,
                position: currentStep.position,
                step,
            });

            return;
        }

        if (step) {
            const newStepIndex = steps.findIndex((item) => (item.value === step));
            const newStep = steps[newStepIndex];

            updateStep({
                isLastStep: !steps[newStepIndex + 1],
                position: newStepIndex,
                step: newStep,
            });

            return;
        }

        goToFirstStep();
    }, [steps]);

    const updateStep = (data) => {
        const {
            isLastStep,
            position,
            step,
        } = data;

        setStep({
            title: step.modalTitle,
            value: step.value,
            fields: step.fields,
            validationSchema: step.validation,
            isLastStep,
            position,
        });
    };

    const goToFirstStep = () => {
        const [firstStep] = steps;

        updateStep({
            isLastStep: false,
            position: 0,
            step: firstStep,
        });
    };

    const goNext = () => {
        const position = currentStep.position + 1;
        const nextStep = steps[position];

        if (!nextStep) {
            return;
        }

        updateStep({
            isLastStep: !steps[position + 1],
            position,
            step: nextStep,
        });
    };

    const goBack = () => {
        const position = currentStep.position - 1;
        const prevStep = steps[position];

        if (!prevStep) {
            return;
        }

        updateStep({
            isLastStep: false,
            position,
            step: prevStep,
        });
    };

    return {
        steps,
        currentStep,
        goNext,
        goBack,
        setStep,
        setSteps,
    };
};