import React from "react";

import ClientCardContractorsRoute from "./Route";

import "./style.sass";

const ClientCardContractors = (props) => {
    return (
        <div className="client-card-contractors">
            <ClientCardContractorsRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardContractors;
