import {useState} from "react";

import dateFormat from "../../../../../utils/dateFormat";
import {handleFormString} from "../../../../../utils/stringHelper";

export function useRegistryInvitationsFilterDto() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            titleFilter,
            fromCreateDateFilter,
            toCreateDateFilter,
        } = filter;

        return {
            ...filterDto,
            titleFilter: handleFormString(titleFilter),
            fromDateFilter: fromCreateDateFilter ? dateFormat(fromCreateDateFilter, "yyyy-MM-dd") : undefined,
            toDateFilter: toCreateDateFilter ? dateFormat(toCreateDateFilter, "yyyy-MM-dd") : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };


    return {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}