import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {getKedoLogsFiltersData} from "../utils/getFiltersData";

import {
    KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS_OPTIONS,
    KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE_OPTIONS,
} from "../constants";

export const useKedoLogsFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        mapFilterDto: getKedoLogsFiltersData,
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: KEDO_LOGS_INTEGRATION_1C_REQUEST_TYPE_OPTIONS,
                        name: "integration1CRequestTypeLogFilter",
                        label: "Тип метода запроса",
                        placeholder: "Выберите тип метода запроса",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "fromDateFilter",
                        endFieldName: "toDateFilter",
                        isClearable: true,
                        label: "Дата",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        options: KEDO_LOGS_INTEGRATION_1C_REQUEST_STATUS_OPTIONS,
                        name: "statusFilter",
                        label: "Статус",
                        placeholder: "Выберите статус",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "requestIdFilter",
                        label: "id запроса",
                        placeholder: "Введите id запроса",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "logIdFilter",
                        label: "id лога",
                        placeholder: "Введите id лога",
                    },
                ],
            },
        ];
    }, []);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};