import React from "react";
import {useSelector} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import {ClientCardReportsRoute} from "./Route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {FINANCE_PIVOT_LINK_PARAMS} from "../../../../constants/financePivot";
import {
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
} from "../../../../constants/links";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    OBJECT_MANAGER,
    PROJECT_MANAGER} from "../../../../constants/roles";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";

import "./style.sass";

const ClientCardReports = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const {withoutContract} = useSelector(getClientPropertiesCardSelector);

    const role = ls(USER_ROLE);

    const getLinks = () => {
        const reportsExport = LINK_CLIENT_FINANCE_EXPORT_FULL
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, 1)
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, 50)
            .replace(":clientId", clientId);
        const upload1C = LINK_CLIENT_FINANCE_UPLOAD.replace(":clientId", clientId);
        const orderAnalytic = LINK_CLIENT_ORDERS_ANALYTICS.replace(":clientId", clientId);

        return {
            reportsExport,
            upload1C,
            orderAnalytic,
        };
    };

    const getTabLinks = () => {
        const {
            reportsExport,
            upload1C,
            orderAnalytic,
        } = getLinks();

        return [
            {
                active: reportsExport,
                link: reportsExport,
                name: "Экспорт отчетов",
            },
            !withoutContract && {
                active: upload1C,
                link: upload1C,
                name: "1С",
            },
            !withoutContract && {
                active: orderAnalytic,
                link: orderAnalytic,
                name: "Аналитика по заказам",
            },
        ].filter(item => Boolean(item));
    };


    return (
        <div className="client-card-reports">
            {
                ![CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(role) &&
                    <>
                        <NmTitle size="xl">
                            Отчеты
                        </NmTitle>
                        <Tabs
                            secondary
                            className="client-card-reports__tabs"
                            panes={getTabLinks()}
                        />
                    </>
            }
            <ClientCardReportsRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardReports;
