export const LANDING_FIELDS_NAME = {
    NAME: "organizationName",
    PHONE: "phone",
    EMAIL: "email",
    FIO: "fio",
    PERSON_TYPE: "personType",
    AGREEMENT_POLICY: "accept",
    QUESTION: "question",
    FILE: "file",
    CAPTCHA: "captcha",
    FEEDBACK_TYPE: "feedbackType",
    TIME_ZONE_OFFSET: "timezoneOffsetToMinutes",
};

export const FEEDBACK_TYPE = {
    CONTRACTOR: "CONTRACTOR",
    CLIENT: "CLIENT",
};