import React from "react";

import {ReactComponent as ArchiveIcon} from "../../../../images/archive.svg";
import {ReactComponent as ListIcon} from "../../../../images/list.svg";

import {COMPONENT} from "../constants";

import {MediaButtonsBreakpointNames, TAsset} from "../types";

export const getArchiveButton = (t: any, archivedFilter: boolean, asset?: TAsset, isOnlyArchiveIcon = false) => {
    const getAsset = () => {
        if (!asset) {
            return undefined;
        }

        const keys: Array<MediaButtonsBreakpointNames> = Object.keys(asset) as Array<keyof typeof asset>;

        const filtered = keys.filter(breakPoint => asset[breakPoint]);

        return filtered.reduce((accum: any, breakPoint) => {
            if (asset[breakPoint]) {
                accum[breakPoint] = {
                    children:  archivedFilter ?
                        t("order-list.list") :
                        t("order-list.archive"),
                };
                return accum;
            }
        }, {});
    };

    const getIcon = () => {
        if (archivedFilter) {
            if (isOnlyArchiveIcon) {
                return <ArchiveIcon className="media-buttons__button-icon_rotated" />;
            }

            return <ListIcon />;
        }

        return <ArchiveIcon />;
    };

    return {
        component: COMPONENT.BUTTON,
        props: {
            size: "xl",
            color: "grey",
            icon: getIcon(),
            children:  archivedFilter ?
                t("order-list.list") :
                t("order-list.archive"),
        },
        asset: getAsset(),
    };
};