import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {uniqWith} from "lodash";

import NmDropdownV2 from "../NmDropdownV2";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {getClientNames, updateCommonPaymentDicts} from "../../../ducks/bff/common/dicts/actionCreators";
import {bffCommonDictsClientNamesSelector} from "../../../ducks/bff/common/dicts/selectors";

const ClientNamesDropdown = (props) => {
    const {
        label = "Заказчик",
        initialOption,
        nameSubstringFilter,
        ...otherProps
    } = props;

    const dispatch = useDispatch();

    const clientNamesDictOptions = useSelector(bffCommonDictsClientNamesSelector);

    const options = useMemo(() => {
        if (initialOption) {
            return uniqWith(
                [
                    ...clientNamesDictOptions,
                    initialOption,
                ],
                (arrVal, othVal) => {
                    return arrVal.key === othVal.key;
                });
        }

        return clientNamesDictOptions;
    }, [
        clientNamesDictOptions,
        initialOption,
    ]);

    useEffect(() => {
        if (nameSubstringFilter) {
            fetchClients(nameSubstringFilter);
        }

        return () => {
            dispatch(updateCommonPaymentDicts({
                clientNames: [],
            }));
        };
    }, []);

    const fetchClients = (clientName) => {
        const requestData = {
            pageNum: 1,
            pageSize: 25,
            nameSubstringFilter: isNullOrWhitespace(clientName) ? undefined : clientName.trim(),
        };

        dispatch(getClientNames(requestData));
    };

    const onSearchChange = (valueFilter) => {
        fetchClients(valueFilter);
    };

    return (
        <NmDropdownV2
            {...otherProps}
            size="xl"
            label={label}
            onSearchChange={onSearchChange}
            search
            options={options}
        />
    );
};

export default ClientNamesDropdown;