import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";
import {KEDO_TEMPLATE_DATA_TYPES} from "../constants";

export const getKedoTemplateValidation = () => {
    return yup.object().shape({
        files: yup.array().min(1, VALIDATIONS_MESSAGE.REQUIRED),
        templateName: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        userReferenceParamModels: yup.array().of(
            yup.object().shape({
                type: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                name: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                availableValues: yup.array()
                    .when("type", {
                        is: (type) => [
                            KEDO_TEMPLATE_DATA_TYPES.STRING_SELECTION,
                            KEDO_TEMPLATE_DATA_TYPES.MULTIPLE_STRING_SELECTION,
                        ].includes(type),
                        then: yup.array()
                            .nullable()
                            .min(1, VALIDATIONS_MESSAGE.REQUIRED),
                    }),
            }),
        ),
    });
};