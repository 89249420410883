export const KEDO_EXPORTS_STATUS = {
    SUCCESS: "SUCCESS",
    IN_PROGRESS: "IN_PROGRESS",
    CANCELLED: "CANCELLED",
    ERROR: "ERROR",
};

export const KEDO_EXPORTS_STATUS_BADGE_MOD = {
    SUCCESS: "green",
    IN_PROGRESS: "orange",
    CANCELLED: "gray",
    ERROR: "red",
};