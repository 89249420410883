export const transformIndustryProfessions = (industryProfessions, isPostfix = true) => {
    let result = {};
    const _industryProfessions = {...industryProfessions};

    Object.keys(industryProfessions).forEach(type => {
        const newObj = {
            ..._industryProfessions[type],
        };

        if (isPostfix) {
            const professions = {};

            Object.keys(newObj).forEach(key => {
                professions[key] = `${newObj[key]} (${type})`;
            });

            result = {
                ...result,
                ...professions,
            };
        } else {
            Object.keys(_industryProfessions[type]).forEach(key => {
                if (key.indexOf("_ETC") !== -1) {
                    newObj[key] = `${newObj[key]} (${type})`;
                }
            });

            result = {
                ...result,
                ...newObj,
            };
        }
    });

    return result;
};

