import {COLOR} from "../../../../constants/color";

export const getTimeColor = (workTimeNowMinutes, taskWorkFinishedDateTime, timeForExecutionMinutes) => {
    if (taskWorkFinishedDateTime) {
        return null;
    }

    return workTimeNowMinutes > timeForExecutionMinutes ?
        COLOR.NEGATIVE_100 :
        COLOR.PRIMARY_100;
};