import React from "react";

import {styleLargeLink} from "../../styles/inline";
import ExtLink from "../ExtLink";

import {isNMUsers} from "../../utils/access";

import {CONTRACTOR_STATUS} from "../../constants/contractor";
import {
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
} from "../../constants/links";
import {HR_MANAGER, RNKO} from "../../constants/roles";

const ContractorLink = (props) => {
    const {
        contractor,
        role,
        pageData,
    } = props;

    const {
        contractorId,
        registrationStatus,
        name,
    } = contractor;

    const getLink = () => {
        let link = LINK_CONTRACTOR_PROFILE;

        if (role === HR_MANAGER) {
            link = LINK_CONTRACTOR_REVIEWS_LIST;
        }

        if (RNKO === role || ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(registrationStatus) && isNMUsers())) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        return link.replace(":contractorId", contractorId);
    };

    return (
        <ExtLink
            pageData={pageData}
            historyEnabled
            style={styleLargeLink}
            to={getLink()}
        >
            {name}
        </ExtLink>
    );
};

export default ContractorLink;
