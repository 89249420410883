export const EMPTY_OPTION_KEY = "all";

export const DROPDOWN_ALL_NO_YES_OPTIONS = [
    {
        key: true,
        text: "Да",
        value: true,
    },
    {
        key: false,
        text: "Нет",
        value: false,
    },
    {
        key: EMPTY_OPTION_KEY,
        text: "Все",
        value: EMPTY_OPTION_KEY,
    },
];