import React, {useEffect, useLayoutEffect, useState} from "react";
import {isEmpty, isEqual} from "lodash";

import ContractorVerificationDataDocumentChecking from "../../containers/contractor/contractor-verification-data/components/document-checking";
import ContractorVerificationLayout from "../../containers/contractor/contractor-verification-data/components/layout";
import {
    ContractorVerificationDataProjectParams,
} from "../../containers/contractor/contractor-verification-data/components/projectParams";
import SkipCheckInnPassportConfirm from "../../containers/contractor/contractor-verification-data/components/skip-check-inn-passport-confirm";
import {MediaButtons} from "../ActualComponents/MediaControls";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import ButtonBack from "../ButtonBack";
import ButtonForward from "../ButtonForward";
import ExecutorCheckStatus from "../ExecutorCheckStatus";
import NmPage from "../NmPage";
import {STATUS_PASSPORT_INN} from "../NmStatusPassportInnContractor";
import NmTitle from "../NmTitle";
import ScanSolution from "../ScanSolution";
import ScanCheckConfirmWindow from "./components/ScanCheckConfirmWindow";

import {useGetPrevValue} from "../../hooks/useGetPrevValue";
import {useContractorVerificationLoadingScans} from "./hooks/useLoadedScans";
import {useContractorVerificationFormNavigation} from "./hooks/useNavigation";
import {useVerificationDocumentFormValidate} from "./hooks/useValidate";

import bem from "../../utils/bem";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {getFullName} from "../../utils/stringFormat";

import {CONTRACTOR_FILE_TYPES} from "../../constants/clientList";
import {
    EXECUTOR_CHECK_STATUS,
    SCAN_DECISION_TYPE,
} from "../../constants/contractor";
import {ADMIN} from "../../constants/roles";
import {CONTRACTOR_VERIFICATION_DATA_FILE_TYPES_WITH_INN_CHECK} from "../../containers/contractor/contractor-verification-data/components/form/constants";
import {
    CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES,
} from "../../containers/contractor/contractor-verification-data/constants";
import {COMPONENT} from "../ActualComponents/MediaControls/constants";

import "./style.sass";

const VerificationDocumentForm = (props) => {
    const {
        isAdditionalDocuments = false,
        progressCompletionChecking,
        isCheckingStatusInnOnSave = true,
        contractorId,
        savedFiles,
        onChangeRequestInnList,
        isRequestInnList,
        contractor,
        pendingFileTypes,
        previousFiles,
        isFocus,
        dirty,
        setInnStatus,
        innStatus,
        handleSave,
        scanWarnings,
        stepsConfig,
        loading,
        validateForm,
        getFileId,
        values,
        scans,
        handleClose,
        comparisonData,
        fetchScansFunc,
        checkedDocumentName,
        errors,
        isVisibleSaveButton = true,
    } = props;

    const [block, element] = bem("contractor-verification-date-form");

    const {
        innFileName,
        edmStatus,
        status,
        registrationStatus,
    } = contractor;

    const [statusSolution, setStatusSolution] = useState(EXECUTOR_CHECK_STATUS.NOT_VERIFIED.value);
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [openSaveConfirmWindow, setOpenSaveConfirmWindow] = useState(false);
    const [openSkipCheckInnPassportConfirm, setOpenSkipCheckInnPassportConfirm] = useState(false);
    const [openExitConfirmWindow, setOpenExitConfirmWindow] = useState(false);
    const [isRejectSelfie, setIsRejectSelfie] = useState(false);
    const [solutionBlockError, setSolutionBlockError] = useState("");
    const [fileDecisions, setFileDecisions] = useState({});
    const prevFileDecisions = useGetPrevValue(fileDecisions);

    const debugStepName = Object.keys(stepsConfig).find(key => stepsConfig[key].isDebug);

    useVerificationDocumentFormValidate({
        validateForm,
        comparisonData,
    });

    const {
        currentScanType,
        disabledNavigation,
        lastStep,
        step,
        setStep,
        steps,
    } = useContractorVerificationFormNavigation({
        stepsConfig,
        pendingFileTypes,
        debugStepName,
        errors,
    });

    const title = stepsConfig[currentScanType]?.title;

    const {
        fetchScans,
        isLoadScan,
    } = useContractorVerificationLoadingScans({
        fetchScansFunc,
        contractorId,
        previousFiles,
        status,
        edmStatus,
        fetchScans: props.fetchScans,
        isAdditionalDocuments,
    });

    const isFieldsChange = [status, edmStatus, registrationStatus].includes("ADMINISTRATOR_CHECK_CHANGES"); // внесение изменений -> только страницы с изменёнными полями

    const role = ls(USER_ROLE);

    useEffect(() => {
        // для подгрузки сохраненного решения
        if (savedFiles.length && steps.length) {
            savedFiles.forEach((item) => {
                if (!steps.includes(Object.keys(item)[0])) {
                    return;
                }

                setFileDecisions(prevState => ({
                    ...prevState,
                    ...item,
                }));
            });
        }
    }, [savedFiles]);

    useEffect(() => {
        _fetchScans();
    }, [step, steps, previousFiles]);

    const getCheckEnd = () => {
        if (dirty) {
            return !disabledNavigation && !isFocus;
        }

        return !disabledNavigation && !isFocus && !isEqual(fileDecisions, prevFileDecisions);
    };

    useLayoutEffect(() => {
        if (!Object.values(fileDecisions).filter(item => !isEmpty(item)).length) {
            setStatusSolution(EXECUTOR_CHECK_STATUS.NOT_VERIFIED.value);

            return;
        }

        Object.values(fileDecisions).filter(({decisionType}) =>
            [
                SCAN_DECISION_TYPE.FILE_DECLINED_INN_AND_PASSPORT_DATA_NOT_EQUALS,
                SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON,
                SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING,
                SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE,
            ].includes(decisionType),
        ).length ? setStatusSolution(EXECUTOR_CHECK_STATUS.REJECTED.value) : setStatusSolution(EXECUTOR_CHECK_STATUS.APPROVED.value);

        if (steps.length && checkDecisions() && getCheckEnd()) {
            setOpenConfirmWindow(true);
        }
    }, [fileDecisions, isFocus, disabledNavigation]);

    function checkDecisions() {
        const fileTypeDecisions = Object.keys(fileDecisions).filter((fileType) => {
            return !isEmpty(fileDecisions[fileType]) && fileDecisions[fileType].decisionType;
        });

        return fileTypeDecisions.length >= steps.length;
    }

    function _fetchScans() {
        const stepInfo = stepsConfig[currentScanType];

        if (!stepInfo) {
            return;
        }

        fetchScans(currentScanType);
    }

    function handleOnChangeFileDecisions(fileType, decisionType) {
        const fileId = getFileId({fileType});

        const optionalScans = [
            CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
            CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD,
            CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN,
        ];

        if ((decisionType === SCAN_DECISION_TYPE.FILE_APPROVED && !fileId) && !isFieldsChange && !scans[fileType] && !optionalScans.includes(fileType)) {
            setSolutionBlockError("Подтверждение данных без фотографии не допускается.");

            return;
        }

        // Если выбранно решение "Требуется лист с ИНН" (с включенным чекбоксом "Запросить лист ИНН") и
        // находимся на страницах принятия решения, где может быть чекбокс,
        // необходимо выключить чекбокс для корректной работы других решений при выборе
        if (
            decisionType !== SCAN_DECISION_TYPE.FILE_APPROVED &&
            CONTRACTOR_VERIFICATION_DATA_FILE_TYPES_WITH_INN_CHECK.includes(fileType) &&
            isRequestInnList
        ) {
            onChangeRequestInnList();
        }

        setFileDecisions(prevState => ({
            ...prevState,
            [fileType]: {
                fileType,
                decisionType,
                fileId,
                rejectionReason: prevState[fileType]?.rejectionReason || "",
            },
        }));

        if (fileType === CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN && [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING].includes(decisionType)) {
            setIsRejectSelfie(true);
            setOpenConfirmWindow(true);

            return;
        }

        setSolutionBlockError("");

        if (!disabledNavigation && step !== lastStep && steps.length !== 0 && !checkDecisions()) {
            handleStepForward();
        }
    }

    function handleStepForward() {
        setStep(step + 1);
        setSolutionBlockError("");
    }

    function handleStepBackward() {
        setStep(step - 1);
        setSolutionBlockError("");
    }

    function handleSaveDecisions({finishCheck = false, skipCheckInnPassport = false}) {
        if (
            isCheckingStatusInnOnSave &&
            finishCheck &&
            !skipCheckInnPassport &&
            role === ADMIN &&
            ![STATUS_PASSPORT_INN.OK.VALUE].includes(innStatus) &&
            statusSolution === EXECUTOR_CHECK_STATUS.APPROVED.value &&
            !isRequestInnList
        ) {
            setOpenSkipCheckInnPassportConfirm(true);

            return;
        }

        const _fileDecisions = Object.values(fileDecisions).map(item => ({...item}));

        handleSave({
            updatedPersonalData: values,
            fileDecisions: _fileDecisions,
            finishCheck: finishCheck,
            setOpenConfirmWindow,
        });
    }

    function handleCloseConfirm() {
        if (openSaveConfirmWindow) {
            setOpenSaveConfirmWindow(false);

            return;
        }

        setOpenExitConfirmWindow(false);
    }

    function setFileDecisionsWithoutSelfie() {
        setFileDecisions(prevState => ({
            ...prevState,
            [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: {},
        }));

        setIsRejectSelfie(false);
        setOpenConfirmWindow(false);
    }

    function renderHeader() {
        return (
            <div className={element("header")}>
                <ExecutorCheckStatus
                    className={element("header-status", {desktop: true})}
                    status={statusSolution}
                />
                <MediaButtons
                    className={element("header-media-controls")}
                    config={{
                        renderCount: {
                            desktop: 2,
                            tablet: 0,
                            mobile: 0,
                        },
                        size: "xl",
                        buttons: [
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    onClick: () => {
                                        validateForm().then(() => {
                                            setOpenSaveConfirmWindow(true);
                                        });
                                    },
                                    color: "green",
                                    children: "Сохранить",
                                    disabled: disabledNavigation || statusSolution === "NOT_VERIFIED",
                                },
                                visible: isVisibleSaveButton,
                            },
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    onClick: () => {
                                        setOpenExitConfirmWindow(true);
                                    },
                                    color: "grey",
                                    children: "Выйти",
                                },
                            },
                        ],
                    }}
                />
                <ButtonForward
                    className="ms-2 ms-md-4"
                    onClick={handleStepForward}
                    disabled={disabledNavigation || step === lastStep || steps.length === 0}
                />
            </div>
        );
    }

    function renderModal() {
        if (openSkipCheckInnPassportConfirm) {
            return (
                <SkipCheckInnPassportConfirm
                    innPassportDataValidStatus={innStatus}
                    submit={() => handleSaveDecisions({finishCheck: true, skipCheckInnPassport: true})}
                    onClose={() => {
                        setOpenSkipCheckInnPassportConfirm(false);
                        setOpenConfirmWindow(false);
                    }}
                />
            );
        }

        if (openSaveConfirmWindow || openExitConfirmWindow) {
            return (
                <NmConfirmV2
                    isHiddenCancelOnMobile
                    mobile="column"
                    onCancel={handleCloseConfirm}
                    onConfirm={openSaveConfirmWindow ? () => handleSaveDecisions({finishCheck: false}) : handleClose}
                    content={
                        openSaveConfirmWindow ?
                            "Вы действительно хотите продолжить? Все внесенные изменения будут сохранены." :
                            "Вы уверены, что хотите выйти? Все внесенные изменения не будут сохранены."
                    }
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                />
            );
        }

        const {
            firstName,
            lastName,
            patronymic,
            fullName,
        } = contractor;

        const contractorFullName = lastName ? getFullName(lastName, firstName, patronymic) : fullName;

        return (
            openConfirmWindow &&
            <ScanCheckConfirmWindow
                progressCompletionChecking={progressCompletionChecking}
                isRequestInnList={isRequestInnList}
                statusSolution={statusSolution}
                contractorFullName={contractorFullName}
                setOpenConfirmWindow={setOpenConfirmWindow}
                checkedDocumentName={checkedDocumentName}
                handleSaveDecisions={() => handleSaveDecisions({
                    finishCheck: true,
                })}
                documentsText={isRejectSelfie ? "селфи с паспортом" : "все или часть документов"}
                setFileDecisionsWithoutSelfie={setFileDecisionsWithoutSelfie}
                isRejectSelfie={isRejectSelfie}
            />
        );
    }

    function renderScanSolution(scanType, scanSolutionsType) {
        const isInnCheck = CONTRACTOR_VERIFICATION_DATA_FILE_TYPES_WITH_INN_CHECK.includes(scanType) && isRequestInnList;

        return (
            <div className={element("scan-solution")}>
                <ScanSolution
                    id={scanType}
                    isInnCheck={isInnCheck}
                    value={fileDecisions[scanType]?.decisionType}
                    onChange={(decisionType) => handleOnChangeFileDecisions(scanType, decisionType)}
                    solutionError={solutionBlockError}
                    scanSolutionsType={scanSolutionsType}
                />
                {
                    !isAdditionalDocuments &&
                    <NmInputV2
                        size="xl"
                        className="mt-4"
                        maxLength={255}
                        onChange={(event, {value}) => {
                            setFileDecisions(prevState => ({
                                ...prevState,
                                [scanType]: {
                                    ...prevState[scanType],
                                    rejectionReason: value,
                                },
                            }));
                        }}
                        value={fileDecisions[scanType]?.rejectionReason || ""}
                        placeholder="Введите описание"
                        label="Опишите причину отклонения"
                    />
                }
            </div>
        );
    }

    function renderStatusPassportInn(params) {
        const {
            isVisibleInnScanLoadingInfo,
        } = params;

        return (
            <ContractorVerificationDataDocumentChecking
                isVisibleInnScanLoadingInfo={isVisibleInnScanLoadingInfo}
                pendingFileTypes={pendingFileTypes}
                className={element("status-inn-wrapper")}
                setInnStatus={setInnStatus}
                onChangeRequestInnList={onChangeRequestInnList}
                isRequestInnList={isRequestInnList}
                isShowRequestInnList={!innFileName}
                contractor={{
                    ...values,
                    innPassportDataValidStatus: innStatus,
                    contractorId,
                }}
            />
        );
    }

    const getFormLayoutComponent = ({scanType, form}) => {
        return (
            <div className="col">
                <ContractorVerificationDataProjectParams className="col-16 col-xxl-6" />
                <ContractorVerificationLayout
                    isLoadScan={isLoadScan}
                    scanWarnings={scanWarnings}
                    scanType={scanType}
                    content={
                        <>
                            {
                                form.render &&
                                form.render(scanType).content
                            }
                            {
                                form.isVisibleStatusPassportInn &&
                                renderStatusPassportInn({
                                    isVisibleInnScanLoadingInfo: form.isVisibleInnScanLoadingInfo,
                                })
                            }
                            {renderScanSolution(scanType, form.scanSolutionType)}
                            {
                                form.render &&
                                form.render(scanType).bottomContent
                            }
                        </>
                    }
                />
            </div>

        );
    };

    const getTitle = () => {
        if (debugStepName) {
            const form = stepsConfig[debugStepName];

            return form.title;
        }

        return title;
    };

    const getFormLayout = () => {
        if (!currentScanType) {
            return null;
        }

        if (debugStepName) {
            const form = stepsConfig[debugStepName];

            return getFormLayoutComponent({
                form,
                scanType: debugStepName,
            });
        }

        const form = stepsConfig[currentScanType];

        if (!form) {
            return null;
        }

        return getFormLayoutComponent({
            form,
            scanType: currentScanType,
        });
    };

    return (
        <NmPage
            isLoaded={loading}
            className={block()}
            header={
                <div className={element("header")}>
                    <ButtonBack
                        className={element("button-back")}
                        onClick={handleStepBackward}
                        disabled={disabledNavigation || step === 1}
                    />
                    <NmTitle
                        className={element("header-title")}
                        size="xl"
                    >
                        {getTitle()}
                    </NmTitle>
                </div>
            }
            titleClassName={element("title-container")}
            controls={renderHeader()}
            headerClassName={element("header-container")}
        >
            <ExecutorCheckStatus
                className={element("header-status")}
                status={statusSolution}
            />
            <NmForm className="d-flex fluid-flex-grow">
                {getFormLayout()}
            </NmForm>
            {renderModal()}
        </NmPage>
    );
};

export default VerificationDocumentForm;