import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {updateRecruitmentVacancyStore} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {
    bffRecruitmentVacancyJobBoardsTypesSelector,
} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {JobBoardsCheckboxForm} from "../job-boards-checkbox-form";

import {useJobBoardsCheckboxForm} from "../../hooks/useJobBoardsCheckboxForm";

const CloseVacancyJobBoards = (props) => {
    const {
        onClose,
        onSubmit,
    } = props;

    const dispatch = useDispatch();

    const jobBoardsTypes = useSelector(bffRecruitmentVacancyJobBoardsTypesSelector);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyStore({
                jobBoards: [],
            }));
        };
    }, []);

    const {
        selectedJobBoards,
        onChange,
    } = useJobBoardsCheckboxForm({
        jobBoardsTypes,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    На какой из площадок вы хотите закрыть вакансию?
                </NmTitle>
            }
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent="Закрыть вакансию"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={() => {
                        onSubmit(selectedJobBoards);
                    }}
                />
            }
        >
            <JobBoardsCheckboxForm
                onChange={onChange}
                selectedJobBoards={selectedJobBoards}
                activeJobBoardsTypes={jobBoardsTypes}
            />
        </NmModal>
    );
};

export default CloseVacancyJobBoards;