import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearRecruitmentAccessControlJobBoardsStore,
    searchRecruitmentAccessControlJobBoardAccounts,
} from "../../../../../ducks/bff/recruitment/access-control/job-boards/actionsCreators";

export const useRecruitmentAccessControlJobBoardsFetchList = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
        archived,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        archived,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentAccessControlJobBoardsStore());
        };
    }, []);

    const fetchList = () => {
        dispatch(searchRecruitmentAccessControlJobBoardAccounts({
            clientId,
            pageNum,
            pageSize,
            archived,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};