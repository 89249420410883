import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {importFromBoardsRecruitmentVacancy} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";

import {toastSuccess} from "../../../../../../utils/toastHelper";

import {requiredMessage} from "../../../../../../constants/validationRules";

export const useRecruitmentVacancyExportJobBoardsForm = ({clientId, onClose}) => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        setValues,
    } = useFormik({
        onSubmit,
        initialValues: {
            jobBoardType: "",
            jobBoardAccountId: "",
        },
        validationSchema: yup.object({
            jobBoardAccountId: yup.string().required(requiredMessage),
        }),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        if (name === "jobBoardType") {
            setValues({
                jobBoardType: value,
                jobBoardAccountId: "",
            });

            return;
        }

        setFieldValue(name, value);
    };

    function onSubmit() {
        dispatch(importFromBoardsRecruitmentVacancy({
            clientId,
            jobBoardType: values.jobBoardType,
            jobBoardAccountId: values.jobBoardAccountId,
            onSuccess: () => {
                toastSuccess("Задача на выгрузку вакансии из джоб-бордов успешно запущена");
                onClose();
            },
        }));
    }

    return {
        onChange,
        values,
        errors,
        touched,
        handleSubmit,
    };
};