import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as LogoIcon} from "../../../images/company-logo.svg";
import {ReactComponent as MoreHorizIcon} from "../../../images/more_horiz__24.svg";
import NmAdvancedTooltip from "../../ActualComponents/NmAdvancedTooltip";
import LogoThumbnail from "../../LogoThumbnail";
import NmButton from "../../NmButton";
import UserInfo from "../UserInfo";

import bem from "../../../utils/bem";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getFormattedFullName} from "../../../utils/stringFormat";

import {CLIENT_FILE_TYPES} from "../../../constants/clientList";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    RNKO,
} from "../../../constants/roles";

import {changeAccount} from "../../../ducks/auth";
import {fileClientLogoSelector, getFileClientLogo} from "../../../ducks/fileStore";

import "./style.sass";

const MenuProfile = (props) => {
    const {
        listRelatedClientUsers,
        pathname,
        specialityNonDecisionCount,
        currentMember,
        currentMember: {
            fio,
            clientName,
        },
        clientId,
        media: {
            mobileTablet,
            preDesktop,
        },
        handleLogout,
        open,
    } = props;
    const [block, element] = bem("menu-profile");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFileClientLogo({
            clientId,
            clientFileType: CLIENT_FILE_TYPES.LOGO_THUMBNAIL,
        }));
    }, []);

    const handleClickChangeAccount = (data) => {
        dispatch(changeAccount(data));
    };

    const logo = useSelector(fileClientLogoSelector);

    const base64Logo = logo[clientId]?.base64str;

    const isAdminUser = [
        ADMIN,
        NM_MANAGER,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
        NM_CONSULTANT,
        RNKO,
        NM_OPERATOR,
    ].includes(ls(USER_ROLE));

    const isLogoTrigger = (preDesktop || (mobileTablet && !open));
    const isNotDesktop = mobileTablet || preDesktop;
    const Component = isLogoTrigger ? NmAdvancedTooltip : "div";
    const trigger = base64Logo ?
        <LogoThumbnail
            className="menu-profile__icon"
            size="xl"
            src={`data:image/jpeg;charset=utf-8;base64, ${base64Logo}`}
        /> :
        <LogoIcon className="menu-profile__icon" />;

    const tooltipProps = {
        className: "menu-profile__tooltip",
        classNameTooltip: element("suggestion", {fixed: !open}),
        noPadding: true,
        noAdaptive: true,
        type: "light",
        position: mobileTablet ? "bottom-right" : "bottom-left",
        trigger: getTrigger(),
        children: <UserInfo
            listRelatedClientUsers={listRelatedClientUsers}
            data={currentMember}
            handleClickChangeAccount={handleClickChangeAccount}
            handleClickLogout={handleLogout}
            pathname={pathname}
            specialityNonDecisionCount={specialityNonDecisionCount}
            base64Logo={base64Logo}
        />,
    };

    function getTrigger() {
        return isAdminUser ? <div className="menu-profile__icon menu-profile__icon_admin" /> : trigger;
    }

    const _props = isLogoTrigger ?
        tooltipProps :
        {
            className: "menu-profile__container",
            children: getTrigger(),
        };

    return (
        <div className={block({open})}>
            <Component {..._props} />
            <div className="menu-profile__info">
                <div className="menu-profile__title">
                    <div className="menu-profile__title-user">
                        {getFormattedFullName(fio)}
                    </div>
                    <div className="menu-profile__title-client">
                        {clientName}
                    </div>
                </div>
                <div className="menu-profile__container-button">
                    {
                        (isNotDesktop && open || !isNotDesktop) &&
                        <NmAdvancedTooltip
                            {...tooltipProps}
                            classNameTooltip="menu-profile__suggestion"
                            trigger={<NmButton
                                className="menu-profile__button"
                                onlyIcon
                                icon={<MoreHorizIcon />}
                            />}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default MenuProfile;