import React from "react";

import "./style.sass";


const Receipt = (props) => {
    const {
        text,
        className = "",
        onClick = () => {},
        children,
    } = props;

    return (
        <div
            className={`receipt ${className}`}
            onClick={onClick}
        >
            <div className="receipt__icon">
                <i className="material-icons notranslate">
                    receipt
                </i>
            </div>
            <div className="receipt__text">
                {children ? children : null}
                {text ? text : null}
            </div>
        </div>
    );
};

export default Receipt;