import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

const validationAddClient = () => {
    return yup.object().shape({
        clientIds: yup.array().min(1, VALIDATIONS_MESSAGE.REQUIRED).of(yup.string()),
    });
};

export default validationAddClient;