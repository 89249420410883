import React, {useState} from "react";
import {useDispatch} from "react-redux";

import NmModal from "../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import Receipt from "../../../../components/Receipt";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import bem from "../../../../utils/bem";

import {downloadDocument} from "../../../../ducks/documents";

import "./style.sass";

export type Duplicate = {
    clientId: string,
    contractorId: string,
    externalId: string,
    fn: string,
    fpd: number,
    orderId: string,
    orderWorkReportId: string,
    paymentNumber: number
};

export type ReceiptDuplicateListProps = {
    duplicates: Array<Duplicate>,
    submit: (selectedExternalId: any) => void,
    close: () => void
}

const ReceiptDuplicateList = (props: ReceiptDuplicateListProps) => {
    const {
        duplicates,
        close,
        submit,
    } = props;

    const [selectedExternalId, setReceipt] = useState("");
    const [block, element] = bem("receipt-duplicate-list");
    const dispatch = useDispatch();

    const submitForm = () => {
        submit(selectedExternalId);
    };

    const handleDownload = (paymentNumber: number) => {
        const downloadLink = `/api/documents/getAtolIncomeCheckFile?paymentNumber=${paymentNumber}`;
        const documentType = `Чек_Атол_${paymentNumber}`;

        const data = {
            isDownload: false,
            downloadLink,
            documentType,
        };

        dispatch(downloadDocument(data));
    };

    return (
        <NmModal
            onClose={close}
            header={
                <NmTitle
                    className={element("title-modal")}
                    size="md"
                >
                    Возврат прихода
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className={element("apply-buttons")}
                    disabled={!selectedExternalId}
                    onClose={close}
                    cancelBtnContent="Отмена"
                    submitBtnContent="Сохранить"
                    submit={submitForm}
                />
            }
        >
            <Form className={block()}>
                Выберите чек, по которому требуется выполнить возврат прихода. После операции возврата прихода он будет
                удален.
                <div className={element("checkbox-group")}>
                    {
                        duplicates?.map(({externalId, paymentNumber, fn, fpd}) => (
                            <div className={element("row")}>
                                <NmRadioV2
                                    key={externalId}
                                    onChange={() => {
                                        setReceipt(externalId);
                                    }}
                                    checked={selectedExternalId === externalId}
                                />
                                <Receipt
                                    className={element("receipt")}
                                    text="Чек Атол"
                                    onClick={() => handleDownload(paymentNumber)}
                                />
                                <div className={element("requisites")}>
                                    <div className={element("requisites-item")}>
                                        № ФН 
                                        {" "}
                                        {fn}
                                    </div>
                                    <div className={element("requisites-item")}>
                                        ФДП 
                                        {" "}
                                        {fpd}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Form>
        </NmModal>
    );
};

export default ReceiptDuplicateList;