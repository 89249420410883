import React from "react";
import {useDispatch} from "react-redux";

import {ReactComponent as InfoIcon} from "../../images/alert_24.svg";
import {ReactComponent as DeleteIcon} from "../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../images/mode.svg";
import {MediaButtons} from "../ActualComponents/MediaControls";
import Text from "../ActualComponents/Text";

import {getUserRole} from "../../utils/access";
import {toastSuccess} from "../../utils/toastHelper";

import {COLOR} from "../../constants/color";
import {ADMIN, NM_CONSULTANT, NM_MANAGER, NM_PARTNER, RECRUITMENT_OBSERVER, RNKO} from "../../constants/roles";
import {COMPONENT} from "../ActualComponents/MediaControls/constants";

import {getRichContractorById} from "../../ducks/contractor";
import {deleteContractorNoteForClient} from "../../ducks/contractorNoteForClient/actionCreators";

const ContractorHeaderNoteForClient = (props) => {
    const {
        contractor,
        openEditModal,
    } = props;

    const dispatch = useDispatch();

    const role = getUserRole();
    const isVisible = ![
        NM_CONSULTANT,
        RNKO,
        NM_PARTNER,
        RECRUITMENT_OBSERVER,
    ].includes(role);
    const isAccessEdit = [ADMIN, NM_MANAGER].includes(role);

    const {
        contractorId,
        noteForClient,
    } = contractor;

    const deleteNote = () => {
        dispatch(deleteContractorNoteForClient({
            contractorId,
            onSuccess: () => {
                toastSuccess("Примечание успешно удалено");
                dispatch(getRichContractorById(contractorId));
            },
        }));
    };

    return (
        Boolean(noteForClient) &&
        isVisible &&
        <div className="d-flex col-16">
            <div className="d-flex flex-column flex-md-row flex-aligned-center">
                <div className="flex align-items-center me-2">
                    <InfoIcon
                        className="me-1"
                        color={COLOR.NEGATIVE_100}
                        width={20}
                        height={20}
                    />
                    <Text
                        level="3"
                        color={COLOR.NEGATIVE_100}
                    >
                        Примечание:
                    </Text>
                </div>
                <Text
                    level="3"
                >
                    {noteForClient}
                </Text>
            </div>
            {
                isAccessEdit &&
                <MediaButtons
                    className="ms-auto ms-md-2"
                    notBorder={true}
                    inline={true}
                    buttonOffset="sm"
                    config={{
                        size: "sm",
                        renderCount: {
                            desktop: 2,
                            tablet: 2,
                            mobile: 0,
                        },
                        buttons: [
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    onClick: openEditModal,
                                    onlyIcon: true,
                                    noBorder: true,
                                    clearWidth: true,
                                    color: "grey",
                                    icon: <ModeIcon />,
                                },
                                asset: {
                                    mobile: {
                                        children: "Редактировать",
                                    },
                                },
                            },
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    onClick: deleteNote,
                                    onlyIcon: true,
                                    noBorder: true,
                                    clearWidth: true,
                                    color: "grey",
                                    icon: <DeleteIcon />,
                                },
                                asset: {
                                    mobile: {
                                        children: "Удалить",
                                    },
                                },
                            },
                        ],
                    }}
                />
            }
        </div>
    );
};

export default ContractorHeaderNoteForClient;