import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../../../../../components/ActualComponents/Text";
import ExtLink from "../../../../../../../components/ExtLink";
import {OrderEditContext} from "../../../../context-provider";
import OrderEditFormPublishRating from "../rating";

import bem from "../../../../../../../utils/bem";
import pluralize from "../../../../../../../utils/pluralize";

import {LINK_CLIENT_FEEDBACKS} from "../../../../../../../constants/links";

import {clientRatingSelector, getClientRatingById, updateRatingStore} from "../../../../../../../ducks/ratings";

import "./style.sass";

const OrderEditFormPublishClientInfoRating = (props) => {
    const {
        label,
        className,
        clientId,
    } = props;
    const [block] = bem("order-edit-form-publish-client-info-rating", className);

    const dispatch = useDispatch();
    const {reviewCount = 0} = useSelector(clientRatingSelector);

    useEffect(() => {
        dispatch(getClientRatingById({clientId}));

        return () => {
            dispatch(updateRatingStore({clientRating: {}}));
        };
    }, []);

    return (
        <div className={block()}>
            <Text.Sub level="2">
                {label}
            </Text.Sub>
            <div className="order-edit-form-publish-client-info-rating__container">
                <OrderEditFormPublishRating
                    isReverse
                    className="order-edit-form-publish-client-info-rating__block"
                    classNameValue="order-edit-form-publish-client-info-rating__value"
                />
                <ExtLink
                    title="Открыть раздел с отзывами о компании в новой вкладке"
                    target="_blank"
                    to={LINK_CLIENT_FEEDBACKS.replace(":clientId", clientId)}
                    className="order-edit-form-publish-client-info-rating__reviews"
                >
                    {
                        reviewCount ?
                            pluralize(reviewCount, ["отзыв", "отзыва", "отзывов"], true) :
                            null
                    }
                </ExtLink>
            </div>
        </div>
    );
};

export default OrderEditFormPublishClientInfoRating;