import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    fileStoreRecruitmentAvatarBase64Selector,
    fileStoreRecruitmentProgressActionSelector,
    fileStoreRecruitmentProgressSelector,
    getRecruitmentCandidateAvatar,
    getRecruitmentResponseAvatar,
    updateFileStoreRecruitmentCandidateStore,
} from "../../../ducks/fileStoreRecruitment";
import Avatar from "../../Avatar";

export const RecruitmentCandidateAvatar = (props) => {
    const {
        nameField = "avatarBase64Str",
        size = "lg",
        isList,
        candidateId,
        isFetch = true,
        isEditable = true,
        className,
        style,
        responseId,
        isClearOnClose = true,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(fileStoreRecruitmentProgressActionSelector);
    const progress = useSelector(fileStoreRecruitmentProgressSelector);
    const base64str = useSelector(
        (state) => fileStoreRecruitmentAvatarBase64Selector(state, nameField),
    );

    useEffect(() => {
        if (isFetch && candidateId) {
            updateBase64Str();

            dispatch(getRecruitmentCandidateAvatar({
                candidateId,
                nameField,
                thumbnail: true,
            }));

            return;
        }

        if (isFetch && responseId) {
            updateBase64Str();

            dispatch(getRecruitmentResponseAvatar({
                responseId,
                nameField,
                thumbnail: true,
            }));
        }

    }, [
        candidateId,
        responseId,
    ]);

    useEffect(() => {
        updateBase64Str();
    }, [
        candidateId,
        responseId,
    ]);

    const updateBase64Str = () => {
        if (base64str) {
            dispatch(updateFileStoreRecruitmentCandidateStore({
                [nameField]: null,
            }));
        }
    };

    const onCloseOriginalAvatar = () => {
        if (isClearOnClose) {
            updateBase64Str();
        }
    };

    return (
        <Avatar
            size={size}
            nameField={nameField}
            base64={isList ? props.base64 : base64str}
            originalImageSrc={isList ? base64str : undefined}
            onCloseOriginalAvatar={onCloseOriginalAvatar}
            candidateId={candidateId}
            isEditable={isEditable}
            responseId={responseId}
            isEditIconTopRight={true}
            className={className}
            style={style}
            progress={progress || progressAction}
        />
    );
};
