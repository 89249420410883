import React, {RefObject, useEffect, useRef, useState} from "react";
import TextareaAutosize from "react-textarea-autosize";

import ErrorTooltip from "../ErrorTooltip";
import NmLabel from "../NmLabel";

import bem from "../../../utils/bem";

import "./style.sass";

const NmTextareaV2 = (props: any) => {
    const {
        label,
        error,
        disabled,
        className = "",
        autoSize = true,
        fluidHeight,
        required,
        maxLength,
        value,
        onChange,
        maxRows,
        onMouseEnter,
        suggestion,
        removeLineBreak,
        isVisibleTooltip,
        tooltip,
    } = props;
    const [block, element] = bem("nm-textarea-v2", className);
    const [isShowScroll, setIsShowScroll] = useState(false);
    const [backgroundCounter, setBackgroundCounter] = useState(false);

    const refEmulation: any = useRef(); //реф нужен для вычисления и правильного отображения счетчика при появлении/скрытии скролла
    const refTextarea: RefObject<HTMLTextAreaElement> = useRef(null);

    useEffect(() => {
        refTextarea?.current?.scrollHeight &&
        updateCounterOffset(refTextarea?.current?.scrollHeight);
    }, [refEmulation, refTextarea]);

    const getCount = () => {
        let _value = value;

        if (removeLineBreak) {
            _value = value ? value.replace(/\r?\n/g, "") : "";
        }

        const _valueLength = maxLength && _value ? _value.length : 0;

        return maxLength &&
            <div
                className={element("counter", {
                    "right": isShowScroll,
                    "background-white": backgroundCounter,
                })}
            >
                {`${_valueLength}/${maxLength}`}
            </div>;
    };

    const updateCounterOffset = (scrollHeight: number) => {
        if (refEmulation?.current?.clientHeight < scrollHeight) {
            setIsShowScroll(true);
            setBackgroundCounter(true);
        }

        if (refEmulation?.current?.clientHeight >= scrollHeight) {
            setIsShowScroll(false);
            setBackgroundCounter(false);
        }
    };

    const _onChange = (e: any) => {
        const {
            target: {
                name,
                value,
                scrollHeight,
            },
        } = e;

        onChange(e, {value, name});

        updateCounterOffset(scrollHeight);
    };

    const getTextarea = () => {
        return autoSize ?
            <TextareaAutosize
                {...props}
                ref={refTextarea}
                required={null}
                maxRows={maxRows}
                onChange={_onChange}
                className={element("control", {
                    active: Boolean(value),
                })}
            /> :
            <textarea
                {...props}
                ref={refTextarea}
                className={element("control", {fluidHeight, active: Boolean(value)})}
                onChange={_onChange}
            />;
    };

    return (
        <div
            className={block({
                fluidHeight,
                disabled,
                error: Boolean(error),
            })}
        >
            {
                label &&
                <NmLabel
                    isVisibleTooltip={isVisibleTooltip}
                    required={required}
                    disabled={disabled}
                    label={label}
                    tooltip={tooltip}
                />
            }
            <div
                onMouseEnter={onMouseEnter}
                className={element("container")}
            >
                {getTextarea()}
                {getCount()}
                <div ref={refEmulation} />
                {
                    suggestion &&
                    <div className={element("suggestion")}>
                        {suggestion}
                    </div>
                }
            </div>
            {
                error &&
                <ErrorTooltip error={error} />
            }
        </div>
    );
};

export default NmTextareaV2;

