import React from "react";

import bem from "../../../utils/bem";

import "./style.sass";

const LoginTitle = ({children, align = "left", margin = "auto", className = ""}) => {
    const [block] = bem("login-title", className);

    return (
        <div className={block({align, margin})}>
            {children}
        </div>
    );
};

export default LoginTitle;