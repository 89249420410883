import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import Avatar from "../../../../../../components/Avatar";
import CheckboxList from "../../../../../../components/CheckboxList";
import ExtLink from "../../../../../../components/ExtLink";
import InvitePerformerToNaimix from "../../../../../../components/InvitePerformerToNaimix";
import NmPage from "../../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../../components/NmPageHeader";
import {history} from "../../../../../../store/configureStore";
import {styleLargeLink} from "../../../../../../styles/inline";
import ContractorListInfo from "../../../../../contractor/nm-contractor-list/contractor-list-info";
import EdoDocumentsRegistriesItemEditForm from "../edit-form";
import {EdoDocumentsRegistriesCardAddItemsListFilter} from "./filter";

import {usePagination} from "../../../../../../hooks/usePagination";
import {useEdoDocumentsRegistriesAction} from "./hooks/useAction";
import {useEdoDocumentsRegistriesFetch} from "./hooks/useFetch";
import {useEdoDocumentsRegistriesFilter} from "./hooks/useFilter";

import {isNMUsers} from "../../../../../../utils/access";
import bem from "../../../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../../../utils/localstorage";
import {getFullName} from "../../../../../../utils/stringFormat";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {CONTRACTOR_STATUS} from "../../../../../../constants/contractor";
import {
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST, LinkNavigator,
} from "../../../../../../constants/links";
import {HR_MANAGER, RNKO} from "../../../../../../constants/roles";

import {
    contractorProgressSelector,
    contractorSearchListSelector,
    contractorStatusDictSelector,
    contractorTotalCountSelector,
    contractorTotalPagesSelector,
    getCitizenshipSelector,
} from "../../../../../../ducks/contractor";
import {documentsEdoRegistriesCardSelector} from "../../../../../../ducks/documentsEdoRegistries";
import {documentsEdoRegistriesItemsProgressActionSelector} from "../../../../../../ducks/documentsEdoRegistriesItems";
import {avatarBase64ImagesListSelector} from "../../../../../../ducks/fileStore";
import {
    specialitiesAllV2DictSelector,
    specialitiesAllV2OptionsSelector,
} from "../../../../../../ducks/speciality";

import "./style.sass";

const EdoDocumentsRegistriesAddItemsList = (props) => {
    const {
        match: {
            params: {
                clientId,
                registryId,
            },
        },
    } = props;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        documentType,
        useTemplateFile,
        templateType,
    } = useSelector(documentsEdoRegistriesCardSelector);
    const totalPages = useSelector(contractorTotalPagesSelector);
    const list = useSelector(contractorSearchListSelector);
    const totalCount = useSelector(contractorTotalCountSelector);
    const progressList = useSelector(contractorProgressSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const citizenshipList = useSelector(getCitizenshipSelector);
    const specialityDict = useSelector(specialitiesAllV2DictSelector);
    const contractorStatusDict = useSelector(contractorStatusDictSelector);
    const progressAction = useSelector(documentsEdoRegistriesItemsProgressActionSelector);

    const {t} = useTranslation();
    const role = ls(USER_ROLE);
    const [block, element] = bem("edo-documents-registries-add-items-list");

    const {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    } = useEdoDocumentsRegistriesFilter({setPagination, pageSize});

    const {
        headerMediaControls,
        confirmData,
        setConfirmData,
        isOpenEditForm,
        setIsOpenEditForm,
        setEditFormData,
        editFormData,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
    } = useEdoDocumentsRegistriesAction();

    const {
        fetchList,
    } = useEdoDocumentsRegistriesFetch({filterData, clientId, registryId, pageNum, pageSize});

    const getMediaActions = (contractor) => {
        const {
            contractorExistsOnCurrentEdoRegistry,
        } = contractor;

        const addToRegistry = contractorExistsOnCurrentEdoRegistry ?
            {
                component: COMPONENT.TEXT,
                props: {
                    onClick: () => {
                    },
                    children: "Добавлен в реестр",
                },
                disabled: true,
            } :
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        setEditFormData(contractor);
                        setIsOpenEditForm(true);
                    },
                    children: "Добавить в реестр",
                    color: "light-green",
                },
            };

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                addToRegistry,
            ],
        };
    };

    const handleOnClickBack = () => {
        history.push(LinkNavigator.client.edo.documentsRegistriesCard.replace(":clientId", clientId).replace(":registryId", registryId));
    };

    const renderContractorsLink = (contractor) => {
        const {
            contractorId,
            registrationStatus,
            lastName,
            firstName,
            patronymic,
            fullName,
        } = contractor;

        let link = LINK_CONTRACTOR_PROFILE;

        if (role === HR_MANAGER) {
            link = LINK_CONTRACTOR_REVIEWS_LIST;
        }

        if (RNKO === role || ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(registrationStatus) && isNMUsers())) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        return (
            <ExtLink
                historyEnabled
                style={styleLargeLink}
                to={link.replace(":contractorId", contractorId)}
            >
                {lastName ? getFullName(lastName, firstName, patronymic) : fullName}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                avatar: <Avatar
                    addedToMyClientGroup={item.addedToMyClientGroup}
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
                contentRow: <ContractorListInfo
                    contractor={item}
                    role={role}
                    renderLinkFnc={renderContractorsLink}
                    t={t}
                    citizenshipList={citizenshipList}
                    contractorStatusDict={contractorStatusDict}
                    specialityOptions={specialityOptions}
                    specialityDict={specialityDict}
                    showPaymentInfo={false}
                    actions={getMediaActions(item)}
                />,
            };
        });
    };

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const renderInvitePerformerToNaimix = () => {
        return (
            isInviteViaEmailOpen &&
            <InvitePerformerToNaimix
                close={() => setIsInviteViaEmailOpen(false)}
                clientId={clientId}
            />
        );
    };

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <EdoDocumentsRegistriesItemEditForm
                contractorData={editFormData}
                clientId={clientId}
                registryId={registryId}
                documentType={documentType}
                fetchList={fetchList}
                onClose={() => setIsOpenEditForm(false)}
                loading={progressAction}
                useTemplateFile={useTemplateFile}
                templateType={templateType}
            />
        );
    };

    return (
        <NmPage
            className={block()}
            header={
                <NmPageHeader
                    text="Поиск исполнителей для добавления в реестр на подписание документов в ЭДО"
                    totalCount={totalCount}
                    handleOnClickBack={handleOnClickBack}
                />
            }
            headerClassName={element("header")}
            typeFilter="vertical"
            mediaControls={headerMediaControls}
            filtersBase={
                <EdoDocumentsRegistriesCardAddItemsListFilter
                    onChange={onChangeFilter}
                    filter={filter}
                    onSearch={onSearch}
                    onClear={onClear}
                />
            }
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressList}
        >
            {renderEditForm()}
            {renderConfirmModal()}
            {renderInvitePerformerToNaimix()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        className="settings-directories-tariff-grid__table"
                        rows={getRows()}
                    />
            }
        </NmPage>
    );
};

export default EdoDocumentsRegistriesAddItemsList;