import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/adm/companies/company/documents/registry";

const ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST = "ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST";
const ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS = "ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS";
const ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR = "ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR";

const SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST = "SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST";
const SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS = "SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS";
const SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR = "SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR";

const initial = {
    progress: false,
    searchProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST:
        return {
            ...state,
            progress: true,
        };
    case SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST:
        return {
            ...state,
            searchProgress: true,
        };
    case ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS:
    case ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR:
        return {
            ...state,
            progress: false,
        };
    case SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS:
    case SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR:
        return {
            ...state,
            searchProgress: false,
        };
    default:
        return state;
    }
};

export function addClientFrameContractExternal(payload) {
    return {
        type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST,
        payload,
    };
}

export function searchContractorByInnOrPhone(payload) {
    return {
        type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST,
        payload,
    };
}

export const companyDocumentsRegistrySelector = state => state.companyDocumentsRegistry;
export const companyDocumentsRegistrySelectorProgressSelector = createSelector(companyDocumentsRegistrySelector, ({progress}) => progress);
export const companyDocumentsRegistrySelectorSearchProgressSelector = createSelector(companyDocumentsRegistrySelector, ({searchProgress}) => searchProgress);

//POST /bff/adm/companies/company/documents/registry/frame-contracts/external/add
export const addClientFrameContractExternalSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.post(`${controller}/frame-contracts/external/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
            payload: error,
        });
    }
};

//GET /bff/adm/companies/company/documents/registry/contractors/find/by-inn-or-phone
export const searchContractorByInnOrPhoneSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.get(`${controller}/contractors/find/by-inn-or-phone`, {params: reqData});

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            //toastError(errorMessage);

            yield put({
                type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST, addClientFrameContractExternalSaga),
        takeEvery(SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST, searchContractorByInnOrPhoneSaga),
    ]);
}