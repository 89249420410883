import React from "react";

const FullRegistrationDisclaimer = (props) => {
    const {
        className,
        classNameRequiredStar,
    } = props;

    return (
        <div className={className}>
            Поля помеченные “
            <span className={classNameRequiredStar}>
*
            </span>
” обязательные только при отправке заявки на проверку.
        </div>
    );
};

export default FullRegistrationDisclaimer;