import * as yup from "yup";

import {addYears} from "../../../../utils/dateFormat";
import {validatePassportDate, validatePassportDateRu} from "../../../../utils/validate";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTOR_FIELD, EDM_PASSPORT_TYPE} from "../../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../../constants/contractorInfo";

const USER_AGE_LIMIT_BY = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 25,
    THIRD_LIMIT: 45,
};

const passportDateLimitationBY = new Date(2012, 6, 19); //Дата выдачи новых образцов паспортов в Беларуси

const USER_AGE_LIMIT_KZ = 16;
const USER_AGE_LIMIT_KG = 16;
const USER_AGE_LIMIT_TJ = 16;

export const getFullRegistrationIssuedDateValidation = () => {
    return yup.string()
        .nullable()
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.RU.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата выдачи паспорта", function (value) {
                    return validatePassportDateRu({
                        passportDate: value ? new Date(value) : null,
                        birthDate: this.parent[CONTRACTOR_FIELD.BIRTH_DATE],
                        isForEditForm: true,
                    });
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.BY.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата выдачи паспорта", function (value) {
                    if (!value || !this.parent[CONTRACTOR_FIELD.BIRTH_DATE]) {
                        return true;
                    }

                    if (new Date(value) < passportDateLimitationBY) {
                        return validatePassportDate({
                            passportDate: new Date(value),
                            birthDate: this.parent[CONTRACTOR_FIELD.BIRTH_DATE],
                            intervalAges: {
                                first: USER_AGE_LIMIT_BY.FIRST_LIMIT,
                                second: USER_AGE_LIMIT_BY.SECOND_LIMIT,
                                third: USER_AGE_LIMIT_BY.THIRD_LIMIT,
                            },
                            isBy: true,
                        });
                    }

                    return true;
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.KZ.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата выдачи паспорта", function (value) {
                    if (!value) {
                        return true;
                    }

                    if (this.parent[CONTRACTOR_FIELD.PASSPORT_TYPE] === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) {
                        const birthDate = this.parent[CONTRACTOR_FIELD.BIRTH_DATE];
                        const result = addYears(birthDate, USER_AGE_LIMIT_KZ);

                        return new Date(value) >= result;
                    }

                    return true;
                }),
        })
        .when(CONTRACTOR_FIELD.CITIZENSHIP, {
            is: citizenshipsDict.TJ.value,
            then: yup.string()
                .nullable()
                .test("check", "Некорректная дата выдачи паспорта", function (value) {
                    if (!value) {
                        return true;
                    }

                    if (this.parent[CONTRACTOR_FIELD.EDM_PASSPORT_TYPE] === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE) {
                        const birthDate = this.parent[CONTRACTOR_FIELD.BIRTH_DATE];
                        const result = addYears(birthDate, USER_AGE_LIMIT_TJ);

                        return new Date(value) >= result;
                    }

                    return true;
                }),
        });
};