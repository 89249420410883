import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2, { TOptions } from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../components/PhoneWithCodeFilter";

import {FC_REGISTRY_ITEM_STATUS} from "../../../../constants/fcRegistries";

import {registryFilterByTicketSelector, updateTicketStore} from "../../../../ducks/crm/ticket";

import "./style.sass";

const initForm = {
    statusFilter: "",
    fioFilter: "",
    phoneFilter: "",
};

type FcRegistryCardFilterProps = {
    submitFilter: Function,
    statusOptions: TOptions,
};

const FcRegistryCardFilter: FC<FcRegistryCardFilterProps> = (props: FcRegistryCardFilterProps): JSX.Element => {
    const {
        submitFilter,
        statusOptions,
    } = props;

    const registryFilterByTicket = useSelector(registryFilterByTicketSelector);

    const _statusOptions = statusOptions.filter(({value}) => ![FC_REGISTRY_ITEM_STATUS.FOR_SIGNING].includes(value));

    const [form, setForm] = useState({
        ...initForm,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(registryFilterByTicket)) {
            setForm({
                ...form,
                ...registryFilterByTicket,
            });
        }

        return () => {
            dispatch(updateTicketStore({
                ticketRegistryFilter: {},
            }));
        };
    }, [registryFilterByTicket]);

    const {
        statusFilter,
        fioFilter,
        phoneFilter,
    } = form;

    const onChange = (event: object, {name, value}: any): void => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const submit = (): void => {
        submitFilter(form);
    };

    const clear = (): void => {
        submitFilter({
            ...initForm,
        });

        setForm({
            ...initForm,
        });
    };

    return (
        <NmForm
            horizontal
            className="fc-registry-card-filter"
        >
            <NmInputV2
                size="lg"
                className="fluid"
                placeholder="Введите ФИО"
                label="ФИО"
                name="fioFilter"
                value={fioFilter}
                onChange={onChange}
            />
            <PhoneWithCodeFilter
                label="Телефон"
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                placeholder="Показать все"
                className="fluid"
                label="Статус"
                name="statusFilter"
                onChange={onChange}
                value={statusFilter}
                options={_statusOptions}
            />
            <FilterButtonsV2
                className="fc-registry-card-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
        </NmForm>
    );
};

export default FcRegistryCardFilter;