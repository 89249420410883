import {all, put, select, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {clientSelector} from "./client";
import {getDepositList, getDepositPageDataSelector} from "./deposit";

import request from "../utils/postman";
import {isNullOrWhitespace} from "../utils/stringHelper";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT_PROPERTIES_MESSAGE, SYSTEM_PROPERTIES_MESSAGE} from "../constants/clientList";
import {CLIENT} from "../constants/messages";
import {ORDER_TEMPLATES_UPDATE_PARAMETERS} from "../constants/order";

const controller = "/clientProperties";

const CLIENT_PROPERTIES_BY_ID_REQUEST = "CLIENT_PROPERTIES_BY_ID_REQUEST";
const CLIENT_PROPERTIES_BY_ID_SUCCESS = "CLIENT_PROPERTIES_BY_ID_SUCCESS";
const CLIENT_PROPERTIES_BY_ID_ERROR = "CLIENT_PROPERTIES_BY_ID_ERROR";
const CLIENT_PROPERTIES_UPDATE_REQUEST = "CLIENT_PROPERTIES_UPDATE_REQUEST";
const CLIENT_PROPERTIES_UPDATE_SUCCESS = "CLIENT_PROPERTIES_UPDATE_SUCCESS";
const CLIENT_PROPERTIES_UPDATE_ERROR = "CLIENT_PROPERTIES_UPDATE_ERROR";
const CLIENT_PROPERTIES_UPDATE_STORE = "CLIENT_PROPERTIES_UPDATE_STORE";

const UPDATE_FOR_CLIENT_REQUEST = "UPDATE_FOR_CLIENT_REQUEST";
const UPDATE_FOR_CLIENT_SUCCESS = "UPDATE_FOR_CLIENT_SUCCESS";
const UPDATE_FOR_CLIENT_ERROR = "UPDATE_FOR_CLIENT_ERROR";

const CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_REQUEST = "CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_REQUEST";
const CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_SUCCESS = "CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_SUCCESS";
const CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_ERROR = "CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_ERROR";

const CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_REQUEST = "CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_REQUEST";
const CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_SUCCESS = "CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_SUCCESS";
const CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_ERROR = "CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_ERROR";

const CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST = "CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST";
const CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS = "CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS";
const CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR = "CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR";

const CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST = "CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST";
const CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS = "CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS";
const CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR = "CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR";

const initial = {
    card: {},
    error: "",
    progressAction: false,
    progressClientProperties: false,
    progressEditCommission: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CLIENT_PROPERTIES_UPDATE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case CLIENT_PROPERTIES_UPDATE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST:
    case CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST:
        return {
            ...state,
            progressEditCommission: true,
        };
    case CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS:
    case CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS:
    case CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR:
    case CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR:
        return {
            ...state,
            progressEditCommission: false,
        };
    case CLIENT_PROPERTIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    case CLIENT_PROPERTIES_BY_ID_REQUEST:
        return {
            ...state,
            progressClientProperties: true,
        };
    case UPDATE_FOR_CLIENT_REQUEST:
        return {
            ...state,
            progressUpdate: true,
        };
    case UPDATE_FOR_CLIENT_SUCCESS:
    case UPDATE_FOR_CLIENT_ERROR:
        return {
            ...state,
            progressUpdate: false,
        };
    case CLIENT_PROPERTIES_BY_ID_SUCCESS:
        return {
            ...state,
            card: payload,
            progressClientProperties: false,
            migrantLicensePaymentEnabled: payload.migrantLicensePaymentEnabled,
        };
    case CLIENT_PROPERTIES_BY_ID_ERROR:
        return {
            ...state,
            progressClientProperties: false,
            error: payload,
        };
    case CLIENT_PROPERTIES_UPDATE_ERROR:
        return {
            ...state,
            progressAction: false,
            error: payload,
        };
    default:
        return state;
    }
};

export function getClientPropertiesById(payload) {
    return {
        type: CLIENT_PROPERTIES_BY_ID_REQUEST,
        payload,
    };
}

export function updateClientProperties(payload) {
    return {
        type: CLIENT_PROPERTIES_UPDATE_REQUEST,
        payload,
    };
}

export function updateClientPropertiesStore(payload) {
    return {
        type: CLIENT_PROPERTIES_UPDATE_STORE,
        payload,
    };
}

export function updateForClient(payload) {
    return {
        type: UPDATE_FOR_CLIENT_REQUEST,
        payload,
    };
}

export function changeCommissionRate(payload) {
    return {
        type: CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_REQUEST,
        payload,
    };
}

export function changeContractorCommission(payload) {
    return {
        type: CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_REQUEST,
        payload,
    };
}

export function changeClientPropertiesSmzCommissions(payload) {
    return {
        type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST,
        payload,
    };
}

export function changeClientPropertiesGphCommissions(payload) {
    return {
        type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST,
        payload,
    };
}

export const clientPropertiesSelector = state => state.clientProperties;
export const getClientPropertiesCardSelector = createSelector(clientPropertiesSelector, ({card}) => card);

export const clientPropertiesDesignMenuTypeSelector = createSelector(clientSelector, ({card: {designMenuType}}) => designMenuType);
export const clientPropertiesCanViewContractorsContactsSelector = createSelector(clientPropertiesSelector, ({card: {canViewContractorContacts}}) => canViewContractorContacts);
export const getProgressClientProperties = createSelector(clientPropertiesSelector, ({progressClientProperties}) => progressClientProperties);
export const getMigrantLicensePaymentProperties = createSelector(clientPropertiesSelector, ({migrantLicensePaymentEnabled}) => migrantLicensePaymentEnabled);


export const clientPropertiesProgressEditCommissionSelector = createSelector(clientPropertiesSelector, ({progressEditCommission}) => progressEditCommission);
export const clientPropertiesProgressActionSelector = createSelector(clientPropertiesSelector, ({progressAction}) => progressAction);

export const getClientsPropertiesByIdSaga = function* (action) {
    try {
        const {payload} = action;
        const {clientId} = payload;

        const result = yield request.get(`${controller}/getRichByClientId?clientId=${clientId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CLIENT_PROPERTIES_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_PROPERTIES_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROPERTIES_BY_ID_ERROR,
            payload: error,
        });
    }
};

export const updateClientsPropertiesSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            isFetchDepositList,
            onSuccess,
        } = payload;

        payload.onSuccess = undefined;

        const result = yield request.post(`${controller}/update`, payload);

        if (isFetchDepositList) {
            const state = yield select();

            yield put(getDepositList(getDepositPageDataSelector(state)));
        }

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(CLIENT_PROPERTIES_MESSAGE.SUCCESS_UPDATE);

        yield put({
            type: CLIENT_PROPERTIES_UPDATE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: CLIENT_PROPERTIES_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const updateForClientSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            data,
            onSuccess = () => {
            },
            onError = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/updateForClient`, data);

        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorCode === ORDER_TEMPLATES_UPDATE_PARAMETERS.TEMPLATE_NOT_FOUND_IN_OBJECTS) {
            const {failedIds} = result;

            onError(failedIds);

            yield put({
                type: UPDATE_FOR_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_FOR_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: UPDATE_FOR_CLIENT_SUCCESS,
        });
    } catch (error) {
        toastError(CLIENT.CLIENT_UPDATE_ERROR_TEXT);

        yield put({
            type: UPDATE_FOR_CLIENT_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientProperties/changeCommissionRate
export const changeCommissionRateSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/changeCommissionRate`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {civil: {pageData}} = yield select();

        onSuccess(pageData);

        toastSuccess("Процентная ставка изменена");

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_ERROR,
        });
    }
};

//POST /api/clientProperties/changeContractorCommission
export const changeContractorCommissionSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/changeContractorCommission`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {civil: {pageData}} = yield select();

        onSuccess(pageData);

        toastSuccess("Процентная ставка изменена");

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_ERROR,
        });
    }
};


//POST /api/clientProperties/changeSmzCommissions
export const changeClientPropertiesSmzCommissionsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/npd-deposit/changeCommissions", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Процентные ставки по компании успешно изменены");
        onSuccess();

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {

        toastError("Процентные ставки по компании не были изменены, попробуйте позднее.");

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_ERROR,
            payload: error,
        });
    }
};

//POST /api/clientProperties/changeGphCommissions
export const changeClientPropertiesGphCommissionsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/ndfl-deposit/changeCommissions", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Процентные ставки по компании успешно изменены");
        onSuccess();

        yield put({
            type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_PROPERTIES_BY_ID_REQUEST, getClientsPropertiesByIdSaga),
        takeEvery(CLIENT_PROPERTIES_UPDATE_REQUEST, updateClientsPropertiesSaga),
        takeEvery(UPDATE_FOR_CLIENT_REQUEST, updateForClientSaga),
        takeEvery(CLIENT_PROPERTIES_CHANGE_COMMISSION_RATE_REQUEST, changeCommissionRateSaga),
        takeEvery(CLIENT_PROPERTIES_CHANGE_CONTRACTOR_COMMISSION_RATE_REQUEST, changeContractorCommissionSaga),
        takeEvery(CLIENT_PROPERTIES_CHANGE_SMZ_COMMISSIONS_REQUEST, changeClientPropertiesSmzCommissionsSaga),
        takeEvery(CLIENT_PROPERTIES_CHANGE_GPH_COMMISSIONS_REQUEST, changeClientPropertiesGphCommissionsSaga),
    ]);
}