import React from "react";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmRatingFieldsForm from "../../../../../components/NmRatingFieldsForm";
import NmTitle from "../../../../../components/NmTitle";

import useDataForm from "./hooks/useFormData";

function ReviewEditForm(props) {
    const {
        className,
        onClose,
        contractorId,
        editData,
        fetchList,
        progress,
        submit,
    } = props;

    const isEdit = !isEmpty(editData);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        confirmAction,
        closeConfirmModal,
        onCancel,
    } = useDataForm({
        contractorId,
        fetchList,
        onClose,
        submit,
        editData,
        isEdit,
    });

    const {
        workQualityScore,
        reviewText,
    } = values;

    const {
        isOpen: isOpenConfirm,
    } = confirmAction;

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
            confirmButton,
            cancelButton,
        } = confirmAction;

        return (
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile
                content={content}
                onCancel={closeConfirmModal}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                isOnlyConfirm
            />
        );
    };

    return (
        isOpenConfirm ?
            renderConfirmModal() :
            <NmModal
                className={className}
                size="md"
                header={
                    <NmTitle size="lg">
                        {isEdit ? editData.titleModal : "Новый отзыв"}
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        align="right"
                        onClose={onCancel}
                        submit={handleSubmit}
                        submitBtnContent="Сохранить"
                        cancelBtnContent="Отменить"
                        disabled={progress}
                    />
                }
                onClose={onCancel}
            >
                <NmForm>
                    <NmRatingFieldsForm
                        handleOnRate={handleChange}
                        children={
                            [
                                {
                                    rating: workQualityScore,
                                    name: "workQualityScore",
                                    label: "Оценка:",
                                    controlClassName: "justify-content-start",
                                    disabled: !isEmpty(editData),
                                    newError: touched?.workQualityScore ? errors?.workQualityScore : null,
                                },
                            ]
                        }
                    />
                    <NmTextareaV2
                        value={reviewText}
                        name="reviewText"
                        label={!isEdit && "Напишите свой отзыв об исполнителе"}
                        placeholder="Введите отзыв"
                        maxLength={1000}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mt-4"
                        error={touched?.reviewText && errors?.reviewText}
                    />
                </NmForm>
            </NmModal>
    );
}

export default ReviewEditForm;