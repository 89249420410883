import React, {FC} from "react";

import "./style.sass";

interface LinkMarkdownTag {
    href?: string,
    children?: string,
}

export const LinkMarkdownTag: FC<LinkMarkdownTag> = (props) => {
    const {
        href,
        children,
    } = props;

    return (
        <a
            title="Открыть в новой вкладке"
            target="_blank"
            href={href}
            className="link-markdown-tag"
            rel="noreferrer"
        >
            {children}
        </a>
    );
};
