export const getOrderEditRequestDataForUnavailableTemplates = (params) => {
    const {
        clientId,
        orderId,
        formData,
        order,
    } = params;

    return {
        clientId,
        orderId: orderId ? orderId : undefined,
        paymentType: order.orderContractPaymentType,
        frameContractByObjectTemplate: order.frameContractByObjectTemplate,
        customDocumentIds: [
            formData.frameContractTemplateId,
            formData.orderApplicationTemplateId,
            formData.actOfAcceptanceOfWorkTemplateId,
        ].filter(value => value),
    };
};