import {
    addDays,
    addYears,
    getStartDate,
} from "../../../utils/dateFormat";

const YEARS_FOR_CHECK_RESIDENCE_PERMIT_DATE_END = 3;

export function checkResidencePermitDecisionDate(params) {
    const {
        date,
        checkedDate,
    } = params;

    if (
        !checkedDate ||
        !date
    ) {
        return true;
    }

    const validToDate = new Date(checkedDate);
    const decisionDate = new Date(date);

    const result = addYears(decisionDate, YEARS_FOR_CHECK_RESIDENCE_PERMIT_DATE_END);
    const leftPartEquation = getStartDate(validToDate);
    const rightPartEquation = getStartDate(addDays(result, 1));

    return leftPartEquation.getTime() <= rightPartEquation.getTime();
}