import React, {FC} from "react";

import {DropdownSelectedValue} from "../../NmDropdownV2/SelectedValue";

import {DropdownWithInputDeleteParams, DropdownWithInputValueType} from "../types";

import "./style.sass";

interface IFunc {
    values: Array<DropdownWithInputValueType>,
    onClickDelete: (data: DropdownWithInputDeleteParams) => void,
    disabled?: boolean,
}

export const DropdownWithInputSelectedContainer: FC<IFunc> = React.memo((props) => {
    const {
        values,
        onClickDelete,
        disabled,
    } = props;

    return (
        <div className="dropdown-with-input-selected-container">
            {
                values.map((item, index) => {
                    return (
                        <DropdownSelectedValue
                            classNames={{
                                block: "dropdown-with-input-selected-container__value",
                                text: "dropdown-with-input-selected-container__value-text",
                                icon: "dropdown-with-input-selected-container__value-icon",
                            }}
                            key={item.content}
                            text={item.content}
                            disabled={disabled}
                            onClickDelete={() => {
                                onClickDelete({...item, index});
                            }}
                        />
                    );
                })
            }
        </div>
    );
});
