import React, {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {debounce} from "lodash";

import dateFormat, {convertUtcToLocal} from "../../../../../utils/dateFormat";

import {predictMlmodelsAmount, updateMlmodelsStore} from "../../../../../ducks/mlmodels";

export const useOrderEditRecommendation = (params) => {
    const {
        description,
        latitude,
        longitude,
        workUnit,
        specialityId,
        name,
        workStartDate,
    } = params;

    const dispatch = useDispatch();

    const predictRecommendation = (data) => {
        dispatch(predictMlmodelsAmount(data));
    };

    const delayedFetching = useCallback(debounce((data) => {
        predictRecommendation(data);
    }, 350), []);

    useEffect(() => {
        return () => {
            dispatch(updateMlmodelsStore({predictAmount: 0}));
        };
    }, []);

    useEffect(() => {
        if (
            name &&
            description &&
            latitude &&
            longitude &&
            specialityId &&
            workUnit === "HOUR"
        ) {
            delayedFetching({
                createDate: workStartDate ?
                    dateFormat(convertUtcToLocal(workStartDate), "yyyy-MM-dd HH:mm:ss") :
                    dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
                description,
                name,
                latitude,
                longitude,
                specialityId,
            });
        }
    }, [
        name,
        description,
        latitude,
        longitude,
        specialityId,
        workUnit,
        workStartDate,
    ]);

    return {};
};