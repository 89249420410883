import {RefObject,useEffect, useRef} from "react";

/**
 * Хук для реакции на клик за пределами DOM-эллемента
 *
 * @example
 * const ref = useClickOutside(useCallback(() => doSomething(a, b)), [a, b]);
 * <div ref={ref}>DOM-эллемент, клик за пределами которого вызовет обработчик</div>
 *
 * @param {Function} callback мемоизированный обработчик вызываемый при клике за пределами DOM-эллемента
 *
 * @return {Object} ref объект со свойством содержащим ссылку на DOM-эллемент
 */

export function useClickOutside<T extends Element>(callback: Function, isOpen?: boolean): RefObject<T> {
    const ref = useRef<T>(null);

    const handleClick = (e: DocumentEventMap["click"]): void => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            callback(e);
        }
    };

    useEffect(() => {
        isOpen && document.addEventListener("click", handleClick);
    },[isOpen]);

    useEffect(() => {
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    return ref;
}