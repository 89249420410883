import {useState} from "react";

export const useOrderTemplatesConfirm = () => {
    const [confirmData, setConfirmData] = useState(null);

    const onOpenConfirm = (data) => {
        setConfirmData(data);
    };

    const onCloseConfirm = () => {
        setConfirmData(null);
    };

    return {
        confirmData,
        onOpenConfirm,
        onCloseConfirm,
    };
};