import React from "react";

import bem from "../../utils/bem";
import pluralize from "../../utils/pluralize";

import PropTypes from "prop-types";

import "./style.sass";

const getPluralizedCount = (count) => pluralize(count, ["заказ", "заказа", "заказов"]);

const ContractorStatistics = (props) => {
    const {
        list,
        className,
    } = props;

    const [block, element] = bem("contractor-statistics", className);

    return (
        <div className={block()}>
            {
                list.map(({category, subcategoryStatistics}) => (
                    <div 
                        className={element("category")} 
                        key={category.id}
                    >
                        <div className={element("category-name")}>
                            {category.value}
                        </div>
                        <ul className={element("subcategories")}>
                            {
                                subcategoryStatistics.map(({subcategory, completedOrderCount}) => (
                                    <li 
                                        className={element("subcategories-item")} 
                                        key={subcategory.id}
                                    >
                                        <div className={element("subcategory-name")}>
                                            {subcategory.value}
                                        </div>
                                        <div className={element("subcategory-decoration")} />
                                        <div className={element("subcategory-value")}>
                                            {getPluralizedCount(completedOrderCount)}
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                ))
            }
        </div>
    );
};

ContractorStatistics.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.shape({
                id: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
            subcategoryStatistics: PropTypes.shape({
                subcategory: PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                }),
                completedOrderCount: PropTypes.number.isRequired,
            }),
        }),
    ),
    className: PropTypes.string,
};

export default ContractorStatistics;