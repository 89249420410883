import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import Avatar from "../../../../../components/Avatar";
import CheckboxList from "../../../../../components/CheckboxList";
import ContractorLink from "../../../../../components/ContractorLink";
import InvitePerformerToNaimix from "../../../../../components/InvitePerformerToNaimix";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import ContractorListInfo from "../../../../contractor/nm-contractor-list/contractor-list-info";
import NmBaseInviteContractorListFilter
    from "../../../../contractor/nm-contractor-list/nm-base-invite-contractor-list-filter";
import {TaskCardErrors} from "../errors";

import {useModal} from "../../../../../hooks/useModal";
import {usePagination} from "../../../../../hooks/usePagination";
import {useSelectedList} from "../../../../../hooks/useSelectedList";
import useCrowdTaskCardInviteCandidatesListAction from "./hooks/useAction";
import useCrowdTaskCardInviteCandidatesListFetch from "./hooks/useFetch";
import useCrowdTaskCardInviteCandidatesListFilter from "./hooks/useFilter";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
} from "../../../../../constants/links";

import {history} from "../../../../../store/configureStore";

import {adressByIdProgressSelector} from "../../../../../ducks/address";
import {
    bffCrowdTasksCardContractorsListSelector,
    bffCrowdTasksCardContractorsTotalCountSelector,
    bffCrowdTasksCardContractorsTotalPagesSelector,
    bffCrowdTasksCardProgressActionSelector,
    bffCrowdTasksCardProgressListSelector,
    bffCrowdTasksCardRichSelector,
} from "../../../../../ducks/bff/crowd/taskCard/selectors";
import {
    contractorStatusDictSelector,
    contractorStatusOptionsSelector,
    getCitizenshipSelector,
    getCitizenshipSmzOnlyOptionsSelector,
    getWorkRegionsWithRemoteWorkOptionsSelector,
} from "../../../../../ducks/contractor";
import {
    contractorDrivingLicenseDictOptionsSelector,
    contractorEducationLevelDictOptionsSelector,
    contractorLanguagesDictOptionsSelector,
} from "../../../../../ducks/contractorProfile";
import {avatarBase64ImagesListSelector} from "../../../../../ducks/fileStore";
import {
    specialitiesAllV2DictSelector,
    specialitiesAllV2OptionsSelector,
} from "../../../../../ducks/speciality";

import {SUB_PAGE_CROWD_TASK_CARD} from "../../../../../constants/link-params";

const CrowdTaskCardInviteCandidatesList = (props) => {
    const {
        match: {
            params: {
                clientId,
                taskId,
            },
        },
    } = props;

    const list = useSelector(bffCrowdTasksCardContractorsListSelector);
    const totalPages = useSelector(bffCrowdTasksCardContractorsTotalPagesSelector);
    const totalCount = useSelector(bffCrowdTasksCardContractorsTotalCountSelector);
    const progress = useSelector(bffCrowdTasksCardProgressListSelector);
    const progressAction = useSelector(bffCrowdTasksCardProgressActionSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const taskCard = useSelector(bffCrowdTasksCardRichSelector);
    const citizenshipList = useSelector(getCitizenshipSelector);
    const contractorStatusDict = useSelector(contractorStatusDictSelector);
    const specialityOptions = useSelector(specialitiesAllV2OptionsSelector);
    const specialityDict = useSelector(specialitiesAllV2DictSelector);
    const educationLevelDictOptions = useSelector(contractorEducationLevelDictOptionsSelector);
    const workRegions = useSelector(getWorkRegionsWithRemoteWorkOptionsSelector);
    const citizenshipOptionsSmzOnly = useSelector(getCitizenshipSmzOnlyOptionsSelector);
    const educationOptions = useSelector(contractorEducationLevelDictOptionsSelector);
    const languagesOptions = useSelector(contractorLanguagesDictOptionsSelector);
    const driverLicenseOptions = useSelector(contractorDrivingLicenseDictOptionsSelector);
    const contractorStatusOptions = useSelector(contractorStatusOptionsSelector);
    const orderAddressProgress = useSelector(adressByIdProgressSelector);

    const role = ls(USER_ROLE);

    const {t} = useTranslation();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        onClear,
        onSearch,
        filterData,
        filter,
        onChangeFilter,
        isSearch,
    } = useCrowdTaskCardInviteCandidatesListFilter({
        pageSize,
        setPagination,
        taskCard,
    });

    const {
        fetchList,
    } = useCrowdTaskCardInviteCandidatesListFetch({
        clientId,
        taskId,
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {
        getMediaControls,
        openMassInviteConfirm,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        getHeaderMediaControls,
        blackListReason,
        setBlackListReason,
        openMassFavoriteConfirm,
    } = useCrowdTaskCardInviteCandidatesListAction({
        clientId,
        taskId,
        fetchList,
        selectedList,
        clearSelectedRows,
        onOpenConfirm,
        onCloseConfirm,
    });

    const handleOnClickBack = () => {
        const link = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":clientId", clientId)
            .replace(":taskId", taskId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        history.push(link);
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                contractorId,
                blocked,
            } = item;

            const {isSelected = false} = selectedList.find(_item => (_item.contractorId === contractorId)) || {};

            return {
                ...item,
                key: contractorId,
                showCheckBox: !blocked,
                isSelected,
                avatar: <Avatar
                    contractorId={contractorId}
                    base64={userAvatarDict[contractorId]}
                />,
                contentRow: <ContractorListInfo
                    contractor={{
                        ...item,
                        mainSpecialityId: item.mainSpeciality?.id,
                        additionalSpecialityIds: item.additionalSpecialities?.map(item => item.id),
                    }}
                    role={role}
                    renderLinkFnc={(contractor) => {
                        return <ContractorLink
                            contractor={contractor}
                            role={role}
                            pageData={{
                                pageSize,
                                pageNum,
                            }}
                        />;
                    }}
                    t={t}
                    citizenshipList={citizenshipList}
                    contractorStatusDict={contractorStatusDict}
                    specialityOptions={specialityOptions}
                    specialityDict={specialityDict}
                    educationLevelDictOptions={educationLevelDictOptions}
                    actions={getMediaControls(item)}
                    showPaymentInfo={false}
                />,
            };
        });
    }, [
        list,
        selectedList,
        citizenshipList,
        contractorStatusDict,
        specialityOptions,
        specialityDict,
        educationLevelDictOptions,
    ]);

    const renderConfirm = () => {
        const {
            content,
            onConfirm,
            showReason,
        } = confirmData || {};

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={
                    <>
                        {content}
                        {
                            showReason &&
                            <NmTextareaV2
                                className="mt-4"
                                placeholder="Укажите причину"
                                maxLength={255}
                                error={blackListReason.error}
                                value={blackListReason.value}
                                onChange={(event, {value}) => {
                                    setBlackListReason({
                                        value,
                                    });
                                }}
                            />
                        }
                    </>
                }
                onCancel={onCloseConfirm}
                onConfirm={() => onConfirm({
                    reason: blackListReason.value,
                })}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderInvitePerformerToNaimix = () => {
        return (
            isInviteViaEmailOpen &&
            <InvitePerformerToNaimix
                close={() => setIsInviteViaEmailOpen(false)}
                clientId={clientId}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    text={`Поиск исполнителей по заданию №${taskCard.number || ""} ${taskCard.name || ""}`}
                    handleOnClickBack={handleOnClickBack}
                />
            }
            typeFilter="vertical"
            filtersBase={
                <NmBaseInviteContractorListFilter
                    t={t}
                    filterData={filter}
                    sendFilter={() => onSearch(filter)}
                    clearFilter={onClear}
                    onChange={onChangeFilter}
                    workRegions={workRegions}
                    сitizenshipOptions={citizenshipOptionsSmzOnly}
                    contractorStatusOptions={contractorStatusOptions}
                    specialityOptions={specialityOptions}
                    orderAddressProgress={orderAddressProgress}
                    educationOptions={educationOptions}
                    languagesOptions={languagesOptions}
                    driverLicenseOptions={driverLicenseOptions}
                    isCrowd={true}
                />
            }
            mediaControls={getHeaderMediaControls()}
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
            isEmptyPage={list.length === 0}
            emptyPageProps={{
                fetchProgress: progress,
                isSearch: isSearch,
            }}
        >
            {renderInvitePerformerToNaimix()}
            {renderConfirm()}
            <TaskCardErrors />
            <CheckboxList
                isShowCheckboxLabel={true}
                rows={rows}
                onSelectedRows={handleSelectedRows}
                count={countSelected}
                mediaControls={
                    {
                        renderCount: {
                            desktop: 2,
                            tablet: 0,
                            mobile: 0,
                        },
                        buttons: [
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    color: "grey",
                                    onClick: openMassFavoriteConfirm,
                                    children: "Добавить в избранное",
                                    disabled: !countSelected,
                                },
                            },
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    color: "grey",
                                    onClick: openMassInviteConfirm,
                                    children: "Пригласить на задание",
                                    disabled: !countSelected,
                                },
                            },
                        ],
                    }
                }
            />
        </NmPage>
    );
};

export default CrowdTaskCardInviteCandidatesList;