import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../../../../../components/ActualComponents/HelpTooltip";
import InputWithDropdownV2 from "../../../../../../../components/ActualComponents/InputWithDropdownV2";
import AmountInput from "../../../../../../../components/AmountInput";
import {OrderEditContext} from "../../../../context-provider";
import {OrderEditTariffScaleIndicator} from "../../../../tariff-scale-indicator";

import {ORDER_FIELDS_NAME} from "../../../../../../../constants/clientList";
import {ORDER_CREATION_MODE, ORDER_MIN_SERVICE_VALUE} from "../../../../../../../constants/order";

import {orderCreationDataSelector, orderWorkUnitsOptionsSelector} from "../../../../../../../ducks/order";

export const OrderEditFormWorkUnit = (props) => {
    const {
        isSecondType,
        isDisabled,
        creatingMode,
        onFocus,
        onMouseEnter,
        isVisibleTooltip,
        scale,
        formData,
        formError,
        objectLatitude,
        objectLongitude,
    } = props;

    const {t} = useTranslation();

    const order = useSelector(orderCreationDataSelector);
    const unitOptions =  useSelector(orderWorkUnitsOptionsSelector);

    const {
        clientId,
        setForm,
        isTemplate,
    } = useContext(OrderEditContext);

    const _onChange = (event, {name, value}) => {
        if (
            isTemplate
            && isSecondType
            && name === "workUnit"
            && formData.workUnit === "SERVICE"
            && formData.workUnit !== value
            && formData.volumeOfWork
        ) {
            setForm({
                [name]: value,
                volumeOfWork: "",
            });

            return;
        }

        if (
            isTemplate
            && isSecondType
            && name === "workUnit"
            && value === "SERVICE"
            && !formData.volumeOfWork
        ) {
            setForm({
                [name]: value,
                volumeOfWork: ORDER_MIN_SERVICE_VALUE,
            });

            return;
        }

        setForm({
            [name]: value,
        });
    };

    return (
        <InputWithDropdownV2
            required={creatingMode !== ORDER_CREATION_MODE.TEMPLATE}
            size="xl"
            label={t("order-new.unitPriceLabel")}
            direction="top"
            strongDirection
            disabled={
                isDisabled ||
                (
                    creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                    Boolean(order.workUnitPrice)
                )
            }
            classNameDivider="order-edit__work-init-price-divider"
            selectName="workUnit"
            classNameDropdown="order-edit__work-unit-price"
            valueSelect={formData.workUnit}
            error={formError.workUnitPrice || formError.workUnit}
            options={unitOptions}
            onChange={_onChange}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            isVisibleTooltip={isVisibleTooltip}
            tooltip={
                <HelpTooltip
                    info
                    hover
                    text={t("order-new.netSuggestion")}
                    position="bottom-left"
                />
            }
            signalTooltip={
                isSecondType &&
                formData.workUnit &&
                <OrderEditTariffScaleIndicator
                    scale={scale}
                    isSecondType={isSecondType}
                    workUnitPrice={formData[ORDER_FIELDS_NAME.WORK_UNIT_PRICE]}
                    workUnit={formData[ORDER_FIELDS_NAME.WORK_UNIT]}
                    objectId={formData[ORDER_FIELDS_NAME.OBJECT_ID]}
                    orderId={order[ORDER_FIELDS_NAME.ORDER_ID]}
                    specialityId={formData.specialityId}
                    name={formData.name}
                    description={formData.description}
                    longitude={objectLongitude}
                    latitude={objectLatitude}
                    clientId={clientId}
                    workStartDate={formData.workStartDate}
                    workEndDate={formData.workEndDate}
                />
            }
            input={
                <AmountInput
                    newInput
                    required={
                        creatingMode !== ORDER_CREATION_MODE.TEMPLATE
                    }
                    addZeroAfterDivider
                    value={formData.workUnitPrice}
                    placeholder="0,00"
                    name="workUnitPrice"
                    disabled={
                        isDisabled ||
                        (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                            Boolean(order.workUnitPrice)
                        )
                    }
                    size="xl"
                    onChange={_onChange}
                    onFocus={onFocus}
                />
            }
        />
    );
};