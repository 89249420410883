import React from "react";
import {useSelector} from "react-redux";
import {Route, Switch, withRouter} from "react-router";

import DepositCivilClientList from "../../../deposit/civil-client-list";
import DepositClientList from "../../../deposit/client-list";
import FinanceCanceledChecks from "../../../finance/calcelled-checks";
import FinanceClientCivilPayments from "../../../finance/client-civil-payments";
import FinanceAdminIndividualPayments from "../../../finance/client-individual-payments";
import FinanceClientNpdPayments from "../../../finance/client-npd-payments";
import FinanceCrowdPayments from "../../../finance/finance-crowd-payments";
import InsuranceHistory from "../../../insurance/insurance-history";
import RegistryList from "../../../registries/registry-list";

import {
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LinkNavigator,
} from "../../../../constants/links";

import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";

const ClientCardFinancesRoute = () => {
    const {
        individualRegistryPaymentsAvailable,
    } = useSelector(getClientPropertiesCardSelector);

    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_PAYMENTS_ORDERS_LIST}
                component={FinanceClientNpdPayments}
            />
            <Route
                exact
                path={LINK_CLIENT_PAYMENTS_TASKS_LIST}
                component={FinanceCrowdPayments}
            />
            <Route
                exact
                path={LINK_CLIENT_NDFL_PAYMENTS_LIST}
                component={FinanceClientCivilPayments}
            />
            {
                individualRegistryPaymentsAvailable &&
                <Route
                    exact
                    path={LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST}
                    component={FinanceAdminIndividualPayments}
                />
            }
            <Route
                exact
                path={LINK_CLIENT_REGISTRY_PAYMENTS_LIST}
                component={RegistryList}
            />
            <Route
                exact
                path={LINK_CLIENT_DEPOSIT_LIST}
                component={DepositClientList}
            />
            <Route
                exact
                path={LinkNavigator.client.finance.depositRegistryOrders}
                component={DepositClientList}
            />
            <Route
                exact
                path={LinkNavigator.client.finance.civilDeposit}
                component={DepositCivilClientList}
            />
            <Route
                exact
                path={LinkNavigator.client.finance.civilDepositRegistryOrders}
                component={DepositCivilClientList}
            />
            <Route
                exact
                path={LINK_CLIENT_INSURANCE_HISTORY}
                component={InsuranceHistory}
            />
            <Route
                exact
                path={LINK_CLIENT_CANCELLED_CHECKS}
                component={FinanceCanceledChecks}
            />
        </Switch>
    );
};

export default withRouter(ClientCardFinancesRoute);