import React, {FC, useState} from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../../components/ActualComponents/NmDadata";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmRangeInput from "../../../../../components/ActualComponents/NmRangeInput";
import AdditionalDocumentFilter from "../../../../../components/AdditionalDocumentFilter";
import AdditionalDocumentStatusFilter from "../../../../../components/AdditionalDocumentStatusFilter";
import MigrationStatusFilter from "../../../../../components/MigrationStatusFilter";
import PhoneWithCodeFilter from "../../../../../components/PhoneWithCodeFilter";

import {isNMUsers} from "../../../../../utils/access";
import {getDadataAddress} from "../../../../../utils/dadata";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";
import {GENDER_OPTIONS} from "../../../../../constants/contractorInfo";

import "./style.sass";

const initForm = {
    addressFiasIdsFilter: null,
    medicalBookFilter: null,
    vaccinationCertificateFilter: null,
    remoteWorkFilter: false,
    specialityIdsFilter: [],
    citizenshipFilter: [],
    ageFromFilter: "",
    ageToFilter: "",
    genderFilter: "",
    fioFilter: "",
    phoneFilter: "",
    registrationStatusFilter: [],
};

const InviteContractorToFcRegistryFilter = (props) => {
    const {
        submitFilter,
        specialityOptions,
        сitizenshipOptions,
        contractorStatusOptions,
        t,
    } = props;

    const [form, setForm] = useState({
        ...initForm,
    });

    const {
        addressFiasIdsFilter,
        remoteWorkFilter,
        specialityIdsFilter,
        citizenshipFilter,
        ageFromFilter,
        ageToFilter,
        genderFilter,
        fioFilter,
        phoneFilter,
        registrationStatusFilter,
    } = form;

    const onChange = (event, {name, value, checked}) => {
        setForm({
            ...form,
            [name]: checked || value,
        });
    };

    const submit = () => {
        submitFilter(form);
    };

    const clear = () => {
        submitFilter({
            ...initForm,
        });

        setForm({
            ...initForm,
        });
    };

    return (
        <NmForm
            addMargin
            className="invite-contractor-to-fc-registry-filter"
        >
            <FilterButtonsV2
                className="invite-contractor-to-fc-registry-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
            <NmDadata
                size="lg"
                name={CONTRACTOR_FIELD.ADDRESS_FIAS_IDS_FILTER}
                label="Регион или город"
                onChange={onChange}
                multiple
                fromBound="region"
                toBound="settlement"
                formatter={getDadataAddress}
                isAllSelectionShown={false}
                initialAddressIds={addressFiasIdsFilter}
                isClearable
                onSubmit={submit}
            />
            <NmCheckboxV2
                label="Работа удалённо"
                checked={remoteWorkFilter}
                name="remoteWorkFilter"
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                search
                multiple
                label={t("contractor-list.specialties")}
                name="specialityIdsFilter"
                placeholder="Показать всё"
                options={specialityOptions}
                value={specialityIdsFilter}
                onChange={onChange}
                onSubmit={submit}
            />
            <NmDropdownV2
                size="lg"
                label={t("contractor-list.citizenship")}
                name="citizenshipFilter"
                search
                multiple
                placeholder="Показать всё"
                options={сitizenshipOptions}
                value={citizenshipFilter}
                onChange={onChange}
            />
            <MigrationStatusFilter
                onChange={onChange}
                onSubmit={submit}
                value={form.migrationStatusesFilter}
            />
            <AdditionalDocumentFilter
                onChange={onChange}
                onSubmit={submit}
                value={form.additionalDocumentTypesFilter}
            />
            {
                isNMUsers() &&
                <AdditionalDocumentStatusFilter
                    onChange={onChange}
                    onSubmit={submit}
                    value={form.additionalDocumentStatusesFilter}
                />
            }
            <div className="invite-contractor-to-fc-registry-filter__row">
                <NmDropdownV2
                    size="lg"
                    label={t("contractor-list.gender")}
                    onChange={onChange}
                    name="genderFilter"
                    placeholder="Показать всё"
                    options={GENDER_OPTIONS}
                    value={genderFilter}
                />
                <NmRangeInput
                    label="Возраст"
                    nameStart="ageFromFilter"
                    nameEnd="ageToFilter"
                    size="lg"
                    valueStart={ageFromFilter}
                    valueEnd={ageToFilter}
                    onChange={onChange}
                    placeholderStart={t("component-labels.from")}
                    placeholderEnd={t("component-labels.to")}
                />
            </div>
            <NmInputV2
                size="lg"
                onChange={onChange}
                name="fioFilter"
                value={fioFilter}
                label={t("contractor-list.full-name-placeholder")}
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmDropdownV2
                size="lg"
                label="Статус регистрации"
                name="registrationStatusFilter"
                search
                multiple
                placeholder="Показать всё"
                options={contractorStatusOptions}
                value={registrationStatusFilter}
                onChange={onChange}
            />
        </NmForm>
    );
};

export default InviteContractorToFcRegistryFilter;