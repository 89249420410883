import {getPaymentStatusChecksFailed} from "../../../../finance/finance-payment-list/utils/getStatusChecksFailed";

import {ACT_REGISTRY_PAYMENTS_STATUS_DICT} from "../../../../../constants/actRegistry";

export const getOrderActRegistryPaymentStatus = (status) => {
    const statusChecksFailed = getPaymentStatusChecksFailed(status);

    if (statusChecksFailed) {
        return {
            color: "gray",
            text: statusChecksFailed,
        };
    }

    return {
        color: ACT_REGISTRY_PAYMENTS_STATUS_DICT[status] && ACT_REGISTRY_PAYMENTS_STATUS_DICT[status].COLOR,
        text: ACT_REGISTRY_PAYMENTS_STATUS_DICT[status]?.TEXT,
    };
};