import {useState} from "react";

export const useObjectsEdit = () => {
    const [editData, setEditData] = useState({});

    const onOpenEdit = ({open, object, projectUnlimited, projectId}) => {
        setEditData({
            projectUnlimited,
            projectId,
            isOpenEditObjectWindow: open,
            editObject: object,
        });
    };

    return {
        editData,
        onOpenEdit,
    };
};