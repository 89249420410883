import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import CheckboxList from "../../../../../components/CheckboxList";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as InfoIcon} from "../../../../../images/alert_24.svg";

import {COLOR} from "../../../../../constants/color";

import {
    bffCrowdActRegistryEntryProgressActionSelector,
} from "../../../../../ducks/bff/crowd/actRegistryEntry/selectors";

const CrowdTaskActRegistryNonActualEntryModal = (props) => {
    const {
        onClose = () => {
        },
        onSubmit = () => {
        },
        list = [],
    } = props;

    const progressAction = useSelector(bffCrowdActRegistryEntryProgressActionSelector);

    const getRows = () => {
        return list.map(item => {
            const {
                rowNumber,
                taskNumber,
                registryNumbers,
            } = item;

            return {
                ...item,
                key: rowNumber,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        primaryHeader={`Задание №${taskNumber}`}
                        labels={[
                            {
                                label: "Строка в текущем реестре",
                                text: rowNumber,
                            },
                            {
                                label: "Реестры с аналогичным заданием",
                                colorLabel: "red",
                                text: registryNumbers?.join(", ") || "-",
                                noWrap: false,
                                inline: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            isAutoSize={true}
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Обнаружены неактуальные задания
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    submitBtnContent="Продолжить запуск"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={onSubmit}
                />
            }
        >
            <div className="col-16">
                <div className="flex align-items-start">
                    <InfoIcon
                        className="me-2"
                        color={COLOR.INERT_100}
                        width={24}
                        height={24}
                    />
                    <Text>
                        Найдены задания, по которым уже произведены выплаты в других реестрах. Такие задания неактуальны
                        для оплаты, поэтому будут удалены из данного реестра при его запуске
                    </Text>
                </div>
                {
                    list.length ?
                        <CheckboxList
                            rows={getRows()}
                        /> :
                        <NmEmptyPageV2 />
                }
            </div>
        </NmModal>
    );
};

export default CrowdTaskActRegistryNonActualEntryModal;