import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import dateFormat, {getMinutes} from "../../../../../../utils/dateFormat";
import {getInitialTouched} from "../../../../../../utils/objectHelper";
import {clearSpace} from "../../../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../../../utils/stringHelper";
import {toastError} from "../../../../../../utils/toastHelper";
import {transformYupFormattedAmountToNumber} from "../../../../../../utils/yupTransforms";
import {getCurrentStep, getNextStep} from "../../../../../order/order-edit/utils/getCurrentStep";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";
import {
    CROWD_TASK_EDIT_STEP,
    CROWD_TASK_SFA_TYPE,
    TASK_PUBLISH_ERROR_CODE,
    TASK_PUBLISH_ERROR_MESSAGE,
} from "../constants";

import {
    addContractorSearchCrowdTask,
    addDraftCrowdTask,
    getByIdCrowdTask,
    updateAndPublishCrowdTask,
    updateCrowdTask,
    updateStoreCrowdTasks,
} from "../../../../../../ducks/bff/crowd/tasks/actionCreators";
import {bffCrowdTasksCardSelector} from "../../../../../../ducks/bff/crowd/tasks/selectors";
import {clientObjectListSelector} from "../../../../../../ducks/clientObject";
import {findSpecialitiesByClientId} from "../../../../../../ducks/speciality";
import {checkStopWords} from "../../../../../../ducks/stopWords";

const useCrowdTaskEditForm = (props) => {
    const {
        clientId,
        onClose,
        isEdit,
        editData,
        fetchList,
        steps,
        openSuccessPublishModalData,
        onOpenConfirm,
        onCloseConfirm,
        isDuplicate,
    } = props;

    const objectList = useSelector(clientObjectListSelector);
    const card = useSelector(bffCrowdTasksCardSelector);

    const dispatch = useDispatch();

    const [activeStepsItems, setActiveStepsItems] = useState([CROWD_TASK_EDIT_STEP.PRE_CONDITIONS.VALUE]);

    const currentStep = getCurrentStep({activeItems: activeStepsItems, tabs: steps});
    const isFirstStep = currentStep === CROWD_TASK_EDIT_STEP.PRE_CONDITIONS.VALUE;
    const isLastStep = currentStep === CROWD_TASK_EDIT_STEP.PUBLISHING.VALUE;

    const initialTimeValues = useMemo(() => {
        const {timeForExecutionMinutes} = card;

        if (!timeForExecutionMinutes) {
            return {
                timeHours: 0,
                timeMinutes: 0,
            };
        }

        return {
            timeHours: Math.floor(timeForExecutionMinutes / 60),
            timeMinutes: Math.abs(timeForExecutionMinutes % 60),
        };
    }, [card]);

    const initialValues = useMemo(() => {
        const {
            workStartDate,
            workEndDate,
            objectName,
            keywordsData,
            objectAddress,
            ...otherValues
        } = card;

        return {
            objectId: null,
            applicationTemplateId: null,
            actTemplateId: null,
            specialityId: null,
            workStartDate: isEdit && !isDuplicate ? workStartDate : null,
            workEndDate: isEdit && !isDuplicate ? workEndDate : null,
            paymentAmount: "",
            name: "",
            servicesDescription: "",
            networkName: "",
            driverLicenseRequiredCategories: [],
            sfaType: CROWD_TASK_SFA_TYPE.USER_LINK.VALUE,
            sfaLink: "",
            checkInRequired: true,
            checkInPhotoRequired: true,
            checkOutRequired: true,
            checkOutPhotoRequired: true,
            valueObjectFilter: objectName || "",
            keywords: keywordsData?.length ? keywordsData.map(({id, value}) => ({
                id,
                content: value,
            })) : [],
            address: objectAddress || "",
            ...otherValues,
            ...initialTimeValues,
        };
    }, [card, initialTimeValues]);

    useEffect(() => {
        dispatch(findSpecialitiesByClientId({
            clientId,
            filterBySetting: true,
        }));

        if (editData.taskId) {
            dispatch(getByIdCrowdTask({
                clientId,
                taskId: editData.taskId,
            }));
        }

        return () => {
            dispatch(updateStoreCrowdTasks({
                card: {},
            }));
        };
    }, []);

    const handleChange = (e, {name, value, checked}) => {
        if (name === "checkInRequired" && !checked) {
            setValues(prevState => ({
                ...prevState,
                checkInRequired: false,
                checkInPhotoRequired: false,
            }));

            return;
        }

        if (name === "checkOutRequired" && !checked) {
            setValues(prevState => ({
                ...prevState,
                checkOutRequired: false,
                checkOutPhotoRequired: false,
            }));

            return;
        }

        if (name === "driverLicenseRequired" && !checked) {
            setValues(prevState => ({
                ...prevState,
                driverLicenseRequired: false,
                driverLicenseRequiredCategories: [],
                personalVehicleRequired: false,
            }));

            return;
        }

        if (name === "driverLicenseRequiredCategories") {
            const driverLicenseRequiredCategories = values.driverLicenseRequiredCategories;

            setValues(prevState => ({
                ...prevState,
                driverLicenseRequiredCategories: !driverLicenseRequiredCategories.includes(value) ?
                    [
                        ...driverLicenseRequiredCategories,
                        value,
                    ] : driverLicenseRequiredCategories.filter(item => item !== value),
            }));

            return;
        }


        if (name === "objectId") {
            const object = objectList.find(item => (item.objectId === value)) || {};
            const {address = ""} = object;

            setValues(prevState => ({
                ...prevState,
                objectId: value,
                address,
            }));

            return;
        }

        if (name === "sfaType") {
            const isOptimumValue = value === CROWD_TASK_SFA_TYPE.OPTIMUM.VALUE;

            setValues(prevState => ({
                ...prevState,
                sfaType: value,
                checkInRequired: !isOptimumValue,
                checkInPhotoRequired: !isOptimumValue,
                checkOutRequired: !isOptimumValue,
                checkOutPhotoRequired: !isOptimumValue,
            }));

            return;
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const goNextStep = () => {
        const nextStep = getNextStep({
            activeItems: activeStepsItems,
            tabs: steps,
        });


        setActiveStepsItems(prevState => [
            ...prevState,
            nextStep,
        ]);
    };

    const goBackStep = () => {
        const _activeItems = [...activeStepsItems];
        _activeItems.pop();

        setActiveStepsItems(_activeItems);
    };

    const validateStopWords = () => {
        const {
            name,
            servicesDescription,
        } = values;

        if (!name && !servicesDescription) {
            goNextStep();

            return;
        }

        const queries = [handleFormString(name), handleFormString(servicesDescription)].filter(value => value);

        if (!isEmpty(queries)) {
            dispatch(checkStopWords({
                data: {
                    errorMessageType: "CROWD_TASK_PUBLICATION",
                    queries,
                },
                onSuccess: goNextStep,
            }));

            return;
        }

        goNextStep();
    };

    const onSubmit = async ({isDraft, isConfirmPublish}) => {
        if (isDraft && !values.name) {
            setFieldError("name", VALIDATIONS_MESSAGE.REQUIRED);
            setFieldTouched("name", true);

            return;
        }

        let errors = {};

        if (!isDraft) {
            errors = await validateForm();
        }

        if (!isEmpty(errors)) {
            await setTouched(getInitialTouched(values, true));

            return;
        }

        if (!isDraft && currentStep === CROWD_TASK_EDIT_STEP.GENERAL_INFORMATION.VALUE) {
            validateStopWords();

            return;
        }

        if (!isDraft && !isLastStep) {
            goNextStep();

            return;
        }

        const onSuccess = () => {
            fetchList && fetchList();
            onClose();
        };

        const {
            objectId,
            applicationTemplateId,
            actTemplateId,
            specialityId,
            workStartDate,
            workEndDate,
            name,
            servicesDescription,
            keywords,
            networkName,
            paymentAmount,
            timeHours,
            timeMinutes,
            checkInRequired,
            checkInPhotoRequired,
            checkOutRequired,
            checkOutPhotoRequired,
            medicalBookRequired,
            driverLicenseRequired,
            driverLicenseRequiredCategories,
            personalVehicleRequired,
            productCategory,
            sfaType,
            sfaLink,
        } = values;

        const reqData = {
            clientId,
            taskId: isEdit ? editData.taskId : undefined,
            objectId,
            applicationTemplateId,
            actTemplateId,
            specialityId,
            workStartDate: dateFormat(workStartDate, "yyyy-MM-dd"),
            workEndDate: dateFormat(workEndDate, "yyyy-MM-dd"),
            name: handleFormString(name),
            servicesDescription: handleFormString(servicesDescription),
            keywordIds: keywords.length ? keywords.map(item => item.id).filter(item => Boolean(item)) : undefined,
            keywords : keywords.length ? keywords.map(item => item.content) : undefined,
            paymentAmount: handleNumber(clearSpace(paymentAmount)),
            networkName: handleFormString(networkName),
            timeForExecutionMinutes: timeHours || timeMinutes ? getMinutes(timeHours, timeMinutes) : null,
            checkInRequired,
            checkInPhotoRequired,
            checkOutRequired,
            checkOutPhotoRequired,
            medicalBookRequired,
            driverLicenseRequired,
            driverLicenseRequiredCategories,
            personalVehicleRequired,
            productCategory: handleFormString(productCategory),
            sfaType,
            sfaLink: handleFormString(sfaLink),
        };

        if (isEdit && isDraft && !isDuplicate) {
            dispatch(updateCrowdTask({
                ...reqData,
                onSuccess,
            }));

            return;
        }

        if (isDraft) {
            dispatch(addDraftCrowdTask({
                ...reqData,
                isDuplicate,
                onSuccess,
            }));

            return;
        }

        if (!isConfirmPublish) {
            onOpenConfirm({
                content: "После того как задание будет опубликовано, изменить его параметры будет нельзя. Опубликовать задание?",
                onConfirm: () => {
                    onCloseConfirm();
                    onSubmit({isConfirmPublish: true});
                },
                warning: true,
            });

            return;
        }

        if (isEdit && !isDuplicate) {
            dispatch(updateAndPublishCrowdTask({
                ...reqData,
                getResult: (result) => {
                    openSuccessPublishModalData({
                        ...result,
                        name: values.name,
                    });
                    onSuccess();
                },
            }));

            return;
        }

        dispatch(addContractorSearchCrowdTask({
            ...reqData,
            isDuplicate,
            getError: ({errorMessage, errorCode}) => {
                if (errorCode === TASK_PUBLISH_ERROR_CODE.CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR) {
                    onOpenConfirm({
                        content: TASK_PUBLISH_ERROR_MESSAGE.CROWD_TASK_OPTIMUM_PROJECT_ID_NOT_PRESENT_ERROR,
                        onConfirm: () => {
                            onCloseConfirm();
                        },
                        confirmButton: "Закрыть",
                        isOnlyConfirm: true,
                    });

                    return;
                }

                if (errorCode === TASK_PUBLISH_ERROR_CODE.CROWD_TASK_CONTRACTOR_SEARCH_ADDITION_VALIDATION_ERROR) {
                    toastError(errorMessage);
                    onClose();

                    return;
                }

                toastError(errorMessage);
            },
            getResult: ({result}) => {
                openSuccessPublishModalData({
                    ...result,
                    name: values.name,
                });
                onSuccess();
            },
        }));
    };

    const getValidation = () => {
        if (currentStep === CROWD_TASK_EDIT_STEP.PRE_CONDITIONS.VALUE) {
            return yup.object().shape({
                objectId: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                applicationTemplateId: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                actTemplateId: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            });
        }

        if (currentStep === CROWD_TASK_EDIT_STEP.GENERAL_INFORMATION.VALUE) {
            return yup.object().shape({
                specialityId: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                workStartDate: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                workEndDate: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                name: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(100, "Не более 100 символов"),
                servicesDescription: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(5, "Минимальная длина 5 символов"),
                keywords: yup.array()
                    .max(30, "Максимальное количество ключевых слов для одного задания - 30"),
                networkName: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(3, "Не менее 3 символов")
                    .max(100, "Не более 100 символов"),
                paymentAmount: yup.number()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .transform(transformYupFormattedAmountToNumber)
                    .min(10, "Минимальное значение - 10,00")
                    .max(600000, "Максимальное значение - 600 000,00"),
                timeHours: yup.string()
                    .nullable()
                    .test("check", VALIDATIONS_MESSAGE.REQUIRED, function (value) {
                        return Number(value) || Number(this.parent.timeMinutes);
                    }),
            });
        }

        if (currentStep === CROWD_TASK_EDIT_STEP.ADDITIONAL_TERMS.VALUE) {
            return yup.object().shape({
                sfaType: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
                sfaLink: yup.string()
                    .nullable()
                    .when("sfaType", {
                        is: CROWD_TASK_SFA_TYPE.USER_LINK.VALUE,
                        then: yup.string()
                            .nullable()
                            .required(VALIDATIONS_MESSAGE.REQUIRED),
                    }),
            });
        }

        return yup.object().shape({});
    };

    const {
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
        validateForm,
        setTouched,
        setFieldError,
        setFieldTouched,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: getValidation(),
        validateOnBlur: false,
    });

    return {
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
        handleChange,
        activeStepsItems,
        currentStep,
        isFirstStep,
        isLastStep,
        goBackStep,
        onSubmit,
    };
};

export default useCrowdTaskEditForm;