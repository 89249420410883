import React from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import {CitizenshipWithFlag} from "../../../../../components/CitizenshipWithFlag";
import LocatedOutsideRussiaTooltip from "../../../../../components/LocatedOutsideRussiaTooltip";
import {STATUS_PASSPORT_INN} from "../../../../../components/NmStatusPassportInnContractor";
import VerificationDocumentForm from "../../../../../components/VerificationDocumentForm";
import ContractorVerificationDataGeneralFields from "../general-fields";
import ContractorVerificationInfoBlock from "../info-block";
import ContractorVerificationInfoBlockRow from "../info-block-row";
import ContractorVerificationInputContainer from "../input-container";
import ContractorVerificationPreviousValueTooltip from "../previous-value-tooltip";
import ContractorVerificationRowContainer from "../row-container";
import ContractorVerificationScanPersonalInfo from "../scan-personal-info";
import ContractorVerificationSelfieInfo from "../selfie-info";
import {ContractorVerificationDataStampBottomContent} from "../stamp-bottom-content";
import ContractorVerificationTemporaryRefugeeCertificateContent from "../temporary-refugee-certificate";
import validationSchema from "./validation/validationSchema";

import useContractorVerificationDataForm from "../../../../../components/VerificationDocumentForm/hooks/useDataForm";
import {useContractorVerificationPersonalData} from "../../hooks/usePersonalData";
import {useContractorVerificationDataRequestInnList} from "../../hooks/useRequestInnList";
import useWarnings from "./hooks/useWarnings";

import {getPersonalDataStep} from "../../../../../components/FullRegistration/utils/getPersonalDataStep";
import {formatDateWithoutTime} from "../../../../../utils/dateFormat";
import {getContractorVerificationFormInitialValues} from "../../utils/dto";
import {getNotificationOfArrivalFirstValidation} from "../../utils/getNotificationOfArrivalFirstValidation";
import {getFullRegistrationPassportFields} from "./utils/getPassportFields";
import {getContractorPassportTitle} from "./utils/getPassportTitle";
import {getContractorPassportType} from "./utils/getPassportType";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../../../constants/clientList";
import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    EDM_PASSPORT_TYPE,
    SCAN_SOLUTION_TYPES,
} from "../../../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE,contractorMask, GENDER_DICT, GENDER_OPTIONS} from "../../../../../constants/contractorInfo";
import {CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES} from "../../constants";
import {CONTRACTOR_VHI_POLICE_TYPE_DICT} from "./constants";

import {
    contractorActionProgressCheckOnEndSelector,
    contractorCardSelector,
    contractorMigrationStatusDictSelector,
    getCitizenshipSelector,
    progressPendingDataConfirmProgressSelector,
} from "../../../../../ducks/contractor";
import {getContractorArrivalDocTypeDictSelector, getPassportTypeDictSelector} from "../../../../../ducks/dict";

import "../../style.sass";

const ContractorVerificationDataForm = (props) => {
    const {
        contractorId,
        localizationData,
        migrationStatus,
    } = props;
    const migrationStatusDict = useSelector(contractorMigrationStatusDictSelector);
    const citizenshipDict = useSelector(getCitizenshipSelector);
    const passportTypeOptions = useSelector(getPassportTypeDictSelector);
    const arrivalDocTypeDict = useSelector(getContractorArrivalDocTypeDictSelector);
    const contractor = useSelector(contractorCardSelector);
    const progressCompletionChecking = useSelector(progressPendingDataConfirmProgressSelector);
    const progressEndCheck = useSelector(contractorActionProgressCheckOnEndSelector);

    const {
        citizenship,
        locatedOutsideRussia,
    } = contractor;

    const {
        isRequestInnList,
        onChangeRequestInnList,
    } = useContractorVerificationDataRequestInnList();

    const {
        handleSave,
        scans,
        personalData,
        previousPersonalData,
        previousFiles,
        savedFiles,
        pendingPersonalDataFields,
        pendingFileTypes,
        getFileId,
        handleClose,
        loading,
        comparisonData,
        stepNames,
    } = useContractorVerificationPersonalData({
        isRequestInnList,
        contractorId,
    });

    const {
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        handleFocus,
        isFocus,
        dirty,
        validateForm,
        setInnStatus,
        innStatus,
        getChanged,
    } = useContractorVerificationDataForm({
        validationSchema: validationSchema(),
        initialValues: getContractorVerificationFormInitialValues(personalData),
        handleClose,
        pendingPersonalDataFields,
    });

    const {
        scanWarnings,
        fieldWarnings,
    } = useWarnings({values});

    const isBelarusian = citizenship === citizenshipsDict.BY.value;
    const isTj = citizenship === citizenshipsDict.TJ.value;
    const isKg = citizenship === citizenshipsDict.KG.value;
    const isKz = citizenship === citizenshipsDict.KZ.value;
    const isUA = citizenship === citizenshipsDict.UA.value;
    const citizenshipUzTjUa = [
        citizenshipsDict.TJ.value,
        citizenshipsDict.UZ.value,
        citizenshipsDict.UA.value,
    ].includes(citizenship);
    const isCheckBirthPlaceFirstPage = isKg || !(isBelarusian || ((isTj || isUA) && values.edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE));
    const {
        isVisiblePassportType,
        passportType,
    } = getContractorPassportType({
        passportTypeOptions,
        passportType: values.passportType,
        citizenship,
        migrationStatus,
        edmPassportType: values.edmPassportType,
    });
    const {
        birthPlace: birthPlaceField,
    } = getPersonalDataStep({
        citizenship,
        migrationStatus,
        isCheckPersonalData: true,
    });

    const stepsConfig = {
        [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN], // Селфи с паспортом
            scanSolutionType: SCAN_SOLUTION_TYPES.SELFIE,
            render: renderSelfieCheckScan,
        },
        [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE], // Селфи с удостоверением беженца
            scanSolutionType: SCAN_SOLUTION_TYPES.SELFIE,
            render: renderSelfieCheckScan,
        },
        [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE]: {
            // Селфи со свидетельством о предоставлении временного убежища
            title: stepNames[CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE],
            scanSolutionType: SCAN_SOLUTION_TYPES.SELFIE,
            render: renderSelfieCheckScan,
        },
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE]: {
            // Селфи с временным удостоверением личности лица без гражданства
            title: stepNames[CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE],
            scanSolutionType: SCAN_SOLUTION_TYPES.SELFIE,
            render: renderSelfieCheckScan,
        },
        [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA], // Паспорт
            render: renderInternalPersonalDataFrontSide,
            fields: [
                "idDocNumber",
                "idDocIssuedDate",
                ![citizenshipsDict.LG.value, citizenshipsDict.DN.value].includes(citizenship) && "idDocValidToDate",
                "idDocIssuedBy",
                "lastName",
                "firstName",
                "patronymic",
                "gender",
                "birthDate",
                "ein",
                isCheckBirthPlaceFirstPage && "birthPlace",
            ],
            isVisibleStatusPassportInn: [
                citizenshipsDict.LG.value,
                citizenshipsDict.DN.value,
                citizenshipsDict.AM.value,
                citizenshipsDict.KZ.value,
                citizenshipsDict.KG.value,
                citizenshipsDict.UZ.value,
            ].includes(citizenship),
        },
        [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE], // Паспорт
            render: renderInternalPersonalDataFrontSide,
            isVisibleStatusPassportInn: true,
            fields: citizenshipUzTjUa ? [
                "idDocNumber",
                "idDocIssuedDate",
                "idDocValidToDate",
                "idDocIssuedBy",
                "lastName",
                "firstName",
                "patronymic",
                "gender",
                "ein",
                isCheckBirthPlaceFirstPage && "birthPlace",
            ] : [
                "idDocNumber",
                "lastName",
                "firstName",
                "patronymic",
                "gender",
                "birthDate",
                "ein",
                isCheckBirthPlaceFirstPage && "birthPlace",
            ],
        },
        [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE], // Обратная сторона паспорта
            render: renderPageInternalPersonalDataBack,
            isVisibleStatusPassportInn: true,
            fields: citizenshipUzTjUa ? [
                "idDocIssuedBy",
                "birthPlace",
            ] : [
                "birthPlace",
                "idDocIssuedDate",
                "idDocValidToDate",
                "idDocIssuedBy",
                "ein",
            ],
        },
        [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE]: {
            // Разворот заграничного паспорта с местом рождения
            title: stepNames[CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE],
            render: renderPagePassportBackSideBy,
            isVisibleStatusPassportInn: true,
            fields: [
                "birthPlace",
            ],
        },
        [CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT], // Разворот вида на жительство
            render: renderResidencePermit,
            fields: getNotificationOfArrivalFirstValidation(values),
        },
        [CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION], // Страница с пропиской
            isVisibleStatusPassportInn: true,
            fields: [
                "address",
            ],
            render: renderScanRegistration,
        },
        [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST], // Уведомление о прибытии, 1 сторона
            render: renderNotificationOfArrivalFirst,
            fields: getNotificationOfArrivalFirstValidation(values),
        },
        [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND]: {
            // Уведомление о прибытии, 2 сторона
            title: stepNames[CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND],
            render: renderNotificationOfArrivalSecond,
        },
        [CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD]: {
            // Миграционная карта, лицевая сторона
            title: stepNames[CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD],
            render: renderPageSecond,
            fields: [
                "migrationCardNumber",
                "migrationCardIssuedDate",
                "migrationCardValidToDate",
            ],
        },
        [CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD]: {
            // Миграционная карта, обратная сторона
            title: stepNames[CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD],
            render: renderPageScan,
        },
        [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: {
            // Патент на осуществление трудовой деятельности в России, лицевая сторона
            title: stepNames[CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN],
            fields: [
                "employmentPatentSeries",
                "employmentPatentNum",
            ],
            render: renderPageEmploymentPatentFrontSide,
        },
        [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: {
            // Патент на осуществление трудовой деятельности в России, обратная сторона
            title: stepNames[CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN],
            fields: [
                "employmentPatentRegistrationNumber",
                "employmentPatentIssuedDate",
            ],
            render: renderPageEmploymentPatentBackSide,
        },
        [CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN]: {
            // Чек об оплате патента
            title: stepNames[CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN], // Уведомление о прибытии, 1 сторона
            render: renderPageScan,
        },
        [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE]: {
            // Лицевая сторона пластикового полиса
            title: stepNames[CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE],
            render: () => ({content: null}),
        },
        [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE]: {
            // Обратная сторона пластикового полиса
            title: stepNames[CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE],
            fields: [
                "vhiPolicyValidFromDate",
                "vhiPolicyValidToDate",
            ],
            render: renderPageVhiPolicy,
        },
        [CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN]: {
            // Бумажный страховой полис
            title: stepNames[CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN],
            fields: [
                "vhiPolicyValidFromDate",
                "vhiPolicyValidToDate",
            ],
            render: renderPageVhiPolicy,
        },
        [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR]: {
            // Согласие на совершение сделок несовершеннолетними
            title: stepNames[CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR],
            render: renderPageScan,
        },
        [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA]: {
            // Согласие на обработку персональных данных родителя
            title: stepNames[CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA],
            render: renderPageScan,
        },
        [CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD]: {
            // Разворот визы с персональной информацией
            title: stepNames[CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD],
            render: renderVisaPersonalDataSpread,
            fields: [
                "visaId",
                "visaNum",
                "visaIssuedDate",
                "visaActiveFromDate",
                "visaActiveUntilDate",
            ],
        },
        [CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN]: {
            // Свидетельство о постановке на учет физического лица в налоговом органе (ИНН)
            title: stepNames[CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN],
            render: renderInnScan,
            isVisibleStatusPassportInn: true,
            isVisibleInnScanLoadingInfo: false,
        },
        [CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION]: {
            // Нотариальное заверение перевода паспорта
            title: stepNames[CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION],
        },
        [CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY], // Справка из ВУЗа
            render: renderCertificateFromUniversity,
        },
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER]: {
            // Расписание занятий на семестр
            title: stepNames[CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER],
        },
        [CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS], // Штамп в документе
            fields: [
                "mainDocumentStampIssuedDate",
            ],
            render: renderMainStampAddress,
        },
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE]: {
            // Лицевая сторона лицензии ВУЗа
            title: stepNames[CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE],
        },
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE]: {
            // Обратная сторона лицензии ВУЗа
            title: stepNames[CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE],
        },
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE]: {
            // Лицевая сторона аккредитации ВУЗа
            title: stepNames[CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE],
        },
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE]: {
            // Обратная сторона аккредитации ВУЗа
            title: stepNames[CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE],
        },
        [CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION], // Перевод паспорта
            render: renderPassportTranslation,
        },
        [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD], // Разворот удостоверения беженца
            render: renderRefugeeCertificateSpread,
            fields: [
                "firstName",
                "lastName",
                "patronymic",
                "birthDate",
                "gender",
                "refugeeCertificateBirthPlace",
                "refugeeCertificateNum",
                "refugeeCertificatePersonalFileNum",
                "refugeeCertificateIssuedBy",
                "refugeeCertificateIssuedDate",
                "refugeeCertificateValidToDate",
            ],
        },
        [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK]: {
            // Разворот удостоверения беженца с отметкой о продлении срока действия
            title: stepNames[CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK],
            render: renderRefugeeCertificateExtensionMark,
        },
        [CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA]: {
            // Разворот РВП с персональной информацией
            title: stepNames[CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA],
            render: renderTemporaryResidencePermitPersonalData,
            fields: [
                "temporaryResidencePermitNum",
                "temporaryResidencePermitDecisionDate",
                "temporaryResidencePermitValidToDate",
                "temporaryResidencePermitDecisionIssuedBy",
            ],
        },
        [CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO]: {
            title: stepNames[CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO], // Разворот паспорта
            isVisibleStatusPassportInn: true,
            fields: [
                "idDocNumber",
                "idDocIssuedDate",
                "idDocIssuedByDepartmentCode",
                "idDocIssuedBy",
                "gender",
                "birthDate",
                "birthPlace",
            ],
            render: renderScanPersonalInfo,
        },
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA]: {
            // Разворот вида на жительство лица без гражданства
            title: stepNames[CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA],
            fields: [
                "statelessPersonResidencePermitNumber",
                "statelessPersonResidencePermitIssuedDate",
                "statelessPersonResidencePermitValidToDate",
                "statelessPersonResidencePermitIssuedBy",
            ],
            render: renderStatelessPersonResidencePermitPersonalData,
        },
        [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA]: {
            // Свидетельство о предоставлении временного убежища с персональной информацией
            title: stepNames[CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA],
            fields: [
                "firstName",
                "lastName",
                "patronymic",
                "birthDate",
                "gender",
                "temporaryRefugeeCertificateNum",
                "temporaryRefugeeCertificatePersonalFileNum",
                "temporaryRefugeeCertificateBirthPlace",
                "temporaryRefugeeCertificateIssuedBy",
                "temporaryRefugeeCertificateIssuedDate",
                "temporaryRefugeeCertificateValidToDate",
            ],
            render: renderTemporaryRefugeeCertificatePersonalData,
        },
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD]: {
            // Разворот временного удостоверения личности лица без гражданства с персональной информацией
            title: stepNames[CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD],
            fields: [
                "firstName",
                "lastName",
                "patronymic",
                "birthDate",
                "gender",
                "statelessPersonTemporaryIdNum",
                "statelessPersonTemporaryIdBirthPlace",
                "statelessPersonTemporaryIdIssueReason",
                "statelessPersonTemporaryIdIssuedBy",
                "statelessPersonTemporaryIdIssuedDate",
                "statelessPersonTemporaryIdValidToDate",
            ],
            render: renderStatelessPersonTemporaryIdPersonalDataSpread,
        },
    };

    function renderScanRegistration() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("address")}
                                label={localizationData.actualAddress}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.address}
                                tooltip={getPreviousValue("address")}
                                required
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
        };
    }

    const getPreviousValue = (name, options) => {
        return (
            <ContractorVerificationPreviousValueTooltip
                getChanged={getChanged}
                name={name}
                options={options}
                previousPersonalData={previousPersonalData}
            />
        );
    };

    function renderStatelessPersonTemporaryIdPersonalDataSpread() {
        return {
            content: (
                <ContractorVerificationTemporaryRefugeeCertificateContent
                    setInnStatus={setInnStatus}
                    localizationData={localizationData}
                    values={values}
                    migrationStatus={migrationStatus}
                    getChanged={getChanged}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    getPreviousValue={getPreviousValue}
                    handleChange={handleChange}
                    fieldWarnings={fieldWarnings}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    fieldNameObject={{
                        num: "statelessPersonTemporaryIdNum",
                        issuedDate: "statelessPersonTemporaryIdIssuedDate",
                        validToDate: "statelessPersonTemporaryIdValidToDate",
                        birthPlace: "statelessPersonTemporaryIdBirthPlace",
                        issueReason: "statelessPersonTemporaryIdIssueReason",
                        issuedBy: "statelessPersonTemporaryIdIssuedBy",
                    }}
                />
            ),
        };
    }

    function renderTemporaryRefugeeCertificatePersonalData() {
        return {
            content: (
                <ContractorVerificationTemporaryRefugeeCertificateContent
                    setInnStatus={setInnStatus}
                    localizationData={localizationData}
                    values={values}
                    migrationStatus={migrationStatus}
                    getChanged={getChanged}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    getPreviousValue={getPreviousValue}
                    handleChange={handleChange}
                    fieldWarnings={fieldWarnings}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    fieldNameObject={{
                        num: "temporaryRefugeeCertificateNum",
                        personalFileNum: "temporaryRefugeeCertificatePersonalFileNum",
                        issuedDate: "temporaryRefugeeCertificateIssuedDate",
                        validToDate: "temporaryRefugeeCertificateValidToDate",
                        birthPlace: "temporaryRefugeeCertificateBirthPlace",
                        issuedBy: "temporaryRefugeeCertificateIssuedBy",
                    }}
                />
            ),
        };
    }

    const getPersonalData = () => {
        return (
            <ContractorVerificationInfoBlock
                list={[
                    {
                        title: localizationData.titlePersonalData,
                        isTitle: true,
                    },
                    {
                        title: localizationData.lastName,
                        value: values.lastName,
                    },
                    {
                        title: localizationData.firstName,
                        value: values.firstName,
                    },
                    {
                        title: localizationData.patronymic,
                        value: values.patronymic,
                    },
                    {
                        title: localizationData.birthDate,
                        value: formatDateWithoutTime(values.birthDate),
                    },
                    {
                        title: localizationData.placeBirth,
                        value: values[birthPlaceField],
                    },
                    {
                        title: localizationData.gender,
                        value: GENDER_DICT[values.gender],
                    },
                ]}
            />
        );
    };

    function renderStatelessPersonResidencePermitPersonalData() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("statelessPersonResidencePermitNumber")}
                                name="statelessPersonResidencePermitNumber"
                                label="Номер"
                                value={values.statelessPersonResidencePermitNumber}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                required
                                tooltip={getPreviousValue("statelessPersonResidencePermitNumber")}
                                error={errors && errors.statelessPersonResidencePermitNumber}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("statelessPersonResidencePermitIssuedDate")}
                                name="statelessPersonResidencePermitIssuedDate"
                                label="Дата выдачи"
                                onFocus={handleFocus}
                                required
                                selected={values.statelessPersonResidencePermitIssuedDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                error={errors && errors.statelessPersonResidencePermitIssuedDate}
                                warning={fieldWarnings.statelessPersonResidencePermitIssuedDate}
                                tooltip={getPreviousValue("statelessPersonResidencePermitIssuedDate")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("statelessPersonResidencePermitValidToDate")}
                                name="statelessPersonResidencePermitValidToDate"
                                label="Дата окончания срока действия"
                                onFocus={handleFocus}
                                required
                                selected={values.statelessPersonResidencePermitValidToDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                error={errors && errors.statelessPersonResidencePermitValidToDate}
                                warning={fieldWarnings.statelessPersonResidencePermitValidToDate}
                                tooltip={getPreviousValue("statelessPersonResidencePermitValidToDate")}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("statelessPersonResidencePermitIssuedBy")}
                                name="statelessPersonResidencePermitIssuedBy"
                                label="Кем выдано"
                                value={values.statelessPersonResidencePermitIssuedBy}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                required
                                tooltip={getPreviousValue("statelessPersonResidencePermitIssuedBy")}
                                error={errors && errors.statelessPersonResidencePermitIssuedBy}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: getPersonalData(),
        };
    }

    function renderTemporaryResidencePermitPersonalData() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("temporaryResidencePermitNum")}
                                name="temporaryResidencePermitNum"
                                label="Номер"
                                value={values.temporaryResidencePermitNum}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                required
                                tooltip={getPreviousValue("temporaryResidencePermitNum")}
                                error={errors && errors.temporaryResidencePermitNum}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("temporaryResidencePermitDecisionDate")}
                                name="temporaryResidencePermitDecisionDate"
                                label="Дата принятия решения"
                                onFocus={handleFocus}
                                required
                                selected={values.temporaryResidencePermitDecisionDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                error={errors && errors.temporaryResidencePermitDecisionDate}
                                warning={fieldWarnings.temporaryResidencePermitDecisionDate}
                                tooltip={getPreviousValue("temporaryResidencePermitDecisionDate")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("temporaryResidencePermitValidToDate")}
                                name="temporaryResidencePermitValidToDate"
                                label="Срок действия"
                                onFocus={handleFocus}
                                required
                                selected={values.temporaryResidencePermitValidToDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                error={errors && errors.temporaryResidencePermitValidToDate}
                                warning={fieldWarnings.temporaryResidencePermitValidToDate}
                                tooltip={getPreviousValue("temporaryResidencePermitValidToDate")}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("temporaryResidencePermitDecisionIssuedBy")}
                                name="temporaryResidencePermitDecisionIssuedBy"
                                label="Кем выдан"
                                value={values.temporaryResidencePermitDecisionIssuedBy}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                tooltip={getPreviousValue("temporaryResidencePermitDecisionIssuedBy")}
                                required
                                error={errors && errors.temporaryResidencePermitDecisionIssuedBy}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: getPersonalData(),
        };
    }

    function renderScanPersonalInfo() {
        return {
            content: (
                <ContractorVerificationScanPersonalInfo
                    handleChange={handleChange}
                    localizationData={localizationData}
                    values={values}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    getChanged={getChanged}
                    getPreviousValue={getPreviousValue}
                    setInnStatus={setInnStatus}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    fieldWarnings={fieldWarnings}
                />
            ),
        };
    }

    function renderPassportTranslation() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            citizenship,
            migrationStatus,
            localizationData,
        });

        return {
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: getContractorPassportTitle({
                                migrationStatus,
                                citizenship,
                            }),
                            isTitle: true,
                        },
                        {
                            title: localizationData.citizenship,
                            value: <CitizenshipWithFlag
                                textLevel="4"
                                citizenship={values.citizenship}
                                flagPositionEnd
                            />,
                        },
                        ...passportFields,
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                    ]}
                />
            ),
        };
    }

    function renderRefugeeCertificateSpread() {
        return {
            content: (
                <ContractorVerificationTemporaryRefugeeCertificateContent
                    setInnStatus={setInnStatus}
                    localizationData={localizationData}
                    values={values}
                    migrationStatus={migrationStatus}
                    getChanged={getChanged}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    getPreviousValue={getPreviousValue}
                    handleChange={handleChange}
                    fieldWarnings={fieldWarnings}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    fieldNameObject={{
                        num: "refugeeCertificateNum",
                        personalFileNum: "refugeeCertificatePersonalFileNum",
                        issuedDate: "refugeeCertificateIssuedDate",
                        validToDate: "refugeeCertificateValidToDate",
                        birthPlace: "refugeeCertificateBirthPlace",
                        issuedBy: "refugeeCertificateIssuedBy",
                    }}
                />
            ),
        };
    }

    function renderRefugeeCertificateExtensionMark() {
        return {
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: "Серия и номер удостоверения беженца",
                            isTitle: true,
                        },
                        {
                            value: values.refugeeCertificateNum,
                        },
                    ]}
                />
            ),
        };
    }

    function renderMainStampAddress() {
        return {
            content: (
                <ContractorVerificationRowContainer>
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("mainDocumentStampAddress")}
                            name="mainDocumentStampAddress"
                            label="Адрес"
                            value={values.mainDocumentStampAddress}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            tooltip={getPreviousValue("mainDocumentStampAddress")}
                            required
                            error={errors && errors.mainDocumentStampAddress}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationInputContainer>
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            changed={getChanged("mainDocumentStampIssuedDate")}
                            name="mainDocumentStampIssuedDate"
                            label="Дата выдачи в штампе"
                            onFocus={handleFocus}
                            maxDate={new Date()}
                            required
                            selected={values.mainDocumentStampIssuedDate}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={handleBlur}
                            error={errors && errors.mainDocumentStampIssuedDate}
                            warning={fieldWarnings.mainDocumentStampIssuedDate}
                            tooltip={getPreviousValue("mainDocumentStampIssuedDate")}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationInputContainer>
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            changed={getChanged("mainDocumentStampValidToDate")}
                            name="mainDocumentStampValidToDate"
                            label="Дата окончания срока действия в штампе"
                            onFocus={handleFocus}
                            selected={values.mainDocumentStampValidToDate}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onBlur={handleBlur}
                            error={errors && errors.mainDocumentStampValidToDate}
                            warning={fieldWarnings.mainDocumentStampValidToDate}
                            tooltip={getPreviousValue("mainDocumentStampValidToDate")}
                        />
                    </ContractorVerificationInputContainer>
                </ContractorVerificationRowContainer>
            ),
            bottomContent: (
                <ContractorVerificationDataStampBottomContent
                    localizationData={localizationData}
                    migrationStatus={migrationStatus}
                    citizenship={citizenship}
                    values={values}
                />
            ),
        };
    }

    function renderCertificateFromUniversity() {
        return {
            bottomContent: getPersonalData(),
        };
    }

    function renderInternalPersonalDataFrontSide() {
        if (values.passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT && (isKg || isKz)) {
            return renderInternalPassport();
        }

        return {
            content: (
                <>
                    <ContractorVerificationInfoBlockRow
                        className="mb-2 mb-xl-4"
                        list={[
                            {
                                title: `${localizationData.citizenship}:`,
                                value: citizenshipDict[values.citizenship],
                                tooltip: locatedOutsideRussia &&
                                    <LocatedOutsideRussiaTooltip
                                        width={24}
                                        height={24}
                                    />,
                            },
                            ![
                                citizenshipsDict.DN.value,
                                citizenshipsDict.LG.value,
                            ].includes(citizenship) && {
                                title: "Мигр. статус:",
                                value: migrationStatusDict[values.migrationStatus],
                            },
                            {
                                title: `${localizationData.inn}:`,
                                value: values.inn,
                            },
                            isVisiblePassportType &&
                            {
                                title: `${localizationData.passportType}:`,
                                value: passportType,
                            },
                        ]}
                    />
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("idDocNumber")}
                                name="idDocNumber"
                                label={localizationData.passportNumber}
                                value={values.idDocNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                required
                                tooltip={getPreviousValue("idDocNumber")}
                                error={errors && errors.idDocNumber}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("idDocIssuedDate")}
                                name="idDocIssuedDate"
                                label={localizationData.idDocIssuedDate}
                                onFocus={handleFocus}
                                maxDate={new Date()}
                                required
                                selected={values.idDocIssuedDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                error={errors && errors.idDocIssuedDate}
                                warning={fieldWarnings.idDocIssuedDate}
                                tooltip={getPreviousValue("idDocIssuedDate")}
                            />
                        </ContractorVerificationInputContainer>
                        {
                            ![
                                citizenshipsDict.RU.value,
                                citizenshipsDict.LG.value,
                                citizenshipsDict.DN.value,
                            ].includes(citizenship) &&
                            <ContractorVerificationInputContainer>
                                <NmDatePicker
                                    size="xl"
                                    dateFormatMask="99.99.9999"
                                    changed={getChanged("idDocValidToDate")}
                                    name="idDocValidToDate"
                                    label={localizationData.idDocValidToDate}
                                    minDate={new Date()}
                                    required
                                    selected={values.idDocValidToDate}
                                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    error={errors && errors.idDocValidToDate}
                                    warning={fieldWarnings.idDocValidToDate}
                                    tooltip={getPreviousValue("idDocValidToDate")}
                                />
                            </ContractorVerificationInputContainer>
                        }
                        {
                            [
                                citizenshipsDict.RU.value,
                                citizenshipsDict.DN.value,
                            ].includes(citizenship) &&
                            <ContractorVerificationInputContainer>
                                <NmInputV2
                                    size="xl"
                                    changed={getChanged("idDocIssuedByDepartmentCode")}
                                    name="idDocIssuedByDepartmentCode"
                                    label={localizationData.idDocIssuedDepartmentCode}
                                    value={values.idDocIssuedByDepartmentCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    tooltip={getPreviousValue("idDocIssuedByDepartmentCode")}
                                    error={errors && errors.idDocIssuedByDepartmentCode}
                                />
                            </ContractorVerificationInputContainer>
                        }
                    </ContractorVerificationRowContainer>
                    {
                        !((isTj || isUA) && values.edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE) &&
                        <ContractorVerificationRowContainer>
                            <ContractorVerificationInputContainer full>
                                <NmInputV2
                                    size="xl"
                                    changed={getChanged("idDocIssuedBy")}
                                    name="idDocIssuedBy"
                                    required
                                    label={localizationData.idDocIssuedBy}
                                    value={values.idDocIssuedBy}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    error={errors && errors.idDocIssuedBy}
                                    tooltip={getPreviousValue("idDocIssuedBy")}
                                />
                            </ContractorVerificationInputContainer>
                        </ContractorVerificationRowContainer>
                    }
                    <ContractorVerificationDataGeneralFields
                        localizationData={localizationData}
                        getChanged={getChanged}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleFocus={handleFocus}
                        getPreviousValue={getPreviousValue}
                        errors={errors}
                        setInnStatus={setInnStatus}
                        setFieldValue={setFieldValue}
                        isBirthPlace
                    />
                    <ContractorVerificationRowContainer>
                        {
                            (
                                ![
                                    citizenshipsDict.AM.value,
                                    citizenshipsDict.LG.value,
                                    citizenshipsDict.DN.value,
                                    citizenshipsDict.UZ.value,
                                ].includes(citizenship) ||
                                [
                                    citizenshipsDict.UA.value,
                                ].includes(citizenship) &&
                                values.edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE
                            ) &&
                            <ContractorVerificationInputContainer>
                                <NmInputV2
                                    size="xl"
                                    changed={getChanged("ein")}
                                    name="ein"
                                    label={localizationData.ein}
                                    value={values.ein}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    tooltip={getPreviousValue("ein")}
                                    error={errors && errors.ein}
                                />
                            </ContractorVerificationInputContainer>
                        }
                    </ContractorVerificationRowContainer>
                </>
            ),
        };
    }

    function renderInternalPassport() {
        return {
            content: (
                <>
                    <ContractorVerificationInfoBlockRow
                        className="mb-2 mb-xl-4"
                        list={[
                            {
                                title: `${localizationData.citizenship}:`,
                                value: citizenshipDict[values.citizenship],
                                tooltip: locatedOutsideRussia &&
                                    <LocatedOutsideRussiaTooltip
                                        width={24}
                                        height={24}
                                    />,
                            },
                            {
                                title: "Мигр. статус:",
                                value: migrationStatusDict[values.migrationStatus],
                            },
                            {
                                title: `${localizationData.inn}:`,
                                value: values.inn,
                            },
                            isVisiblePassportType &&
                            {
                                title: `${localizationData.passportType}:`,
                                value: passportType,
                            },
                        ]}
                    />
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("idDocNumber")}
                                name="idDocNumber"
                                label={localizationData.passportNumber}
                                value={values.idDocNumber}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                required
                                tooltip={getPreviousValue("idDocNumber")}
                                error={errors && errors.idDocNumber}
                            />
                        </ContractorVerificationInputContainer>
                        {
                            isKz &&
                            <>
                                <ContractorVerificationInputContainer>
                                    <NmInputV2
                                        size="xl"
                                        changed={getChanged("ein")}
                                        name="ein"
                                        label={localizationData.ein}
                                        value={values.ein}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        onChange={handleChange}
                                        tooltip={getPreviousValue("ein")}
                                        error={errors && errors.ein}
                                    />
                                </ContractorVerificationInputContainer>
                                <ContractorVerificationInputContainer>
                                    <NmDropdownV2
                                        size="xl"
                                        changed={getChanged("gender")}
                                        name="gender"
                                        label={localizationData.gender}
                                        required
                                        value={values.gender}
                                        options={GENDER_OPTIONS}
                                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        error={errors && errors.gender}
                                        tooltip={getPreviousValue("gender", GENDER_OPTIONS)}
                                    />
                                </ContractorVerificationInputContainer>
                            </>
                        }
                        {
                            isKg &&
                            <ContractorVerificationInputContainer>
                                <NmDatePicker
                                    size="xl"
                                    dateFormatMask="99.99.9999"
                                    changed={getChanged("idDocValidToDate")}
                                    name="idDocValidToDate"
                                    label={localizationData.idDocValidToDate}
                                    minDate={new Date()}
                                    required
                                    selected={values.idDocValidToDate}
                                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    error={errors && errors.idDocValidToDate}
                                    warning={fieldWarnings.idDocValidToDate}
                                    tooltip={getPreviousValue("idDocValidToDate")}
                                />
                            </ContractorVerificationInputContainer>
                        }
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("lastName")}
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                type="text"
                                label={localizationData.lastName}
                                tooltip={getPreviousValue("lastName")}
                                required
                                error={errors && errors.lastName}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("firstName")}
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                type="text"
                                label={localizationData.firstName}
                                tooltip={getPreviousValue("firstName")}
                                required
                                error={errors && errors.firstName}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("patronymic")}
                                name="patronymic"
                                value={values.patronymic}
                                onChange={handleChange}
                                type="text"
                                label={localizationData.patronymic}
                                tooltip={getPreviousValue("patronymic")}
                                error={errors && errors.patronymic}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        {
                            isKg &&
                            <ContractorVerificationInputContainer>
                                <NmDropdownV2
                                    size="xl"
                                    changed={getChanged("gender")}
                                    name="gender"
                                    label={localizationData.gender}
                                    required
                                    value={values.gender}
                                    options={GENDER_OPTIONS}
                                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    error={errors && errors.gender}
                                    tooltip={getPreviousValue("gender", GENDER_OPTIONS)}
                                />
                            </ContractorVerificationInputContainer>
                        }
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("birthDate")}
                                name="birthDate"
                                label={localizationData.birthDate}
                                maxDate={new Date()}
                                required
                                selected={values.birthDate}
                                onChange={(e, {name, value}) => {
                                    setInnStatus(STATUS_PASSPORT_INN.NOT_CHECKED.VALUE);
                                    setFieldValue(name, value);
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.birthDate}
                                tooltip={getPreviousValue("birthDate")}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
        };
    }

    function renderPageInternalPersonalDataBack() {
        if (citizenshipUzTjUa) {
            return {
                content: (
                    <>
                        <ContractorVerificationRowContainer>
                            <ContractorVerificationInputContainer full>
                                <NmInputV2
                                    size="xl"
                                    changed={getChanged("idDocIssuedBy")}
                                    name="idDocIssuedBy"
                                    required
                                    label={localizationData.idDocIssuedBy}
                                    value={values.idDocIssuedBy}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    error={errors && errors.idDocIssuedBy}
                                    tooltip={getPreviousValue("idDocIssuedBy")}
                                />
                            </ContractorVerificationInputContainer>
                        </ContractorVerificationRowContainer>
                        <ContractorVerificationRowContainer>
                            <ContractorVerificationInputContainer full>
                                <NmInputV2
                                    size="xl"
                                    changed={getChanged("birthPlace")}
                                    name="birthPlace"
                                    label={localizationData.placeBirth}
                                    value={values.birthPlace}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    tooltip={getPreviousValue("birthPlace")}
                                    required
                                    error={errors && errors.birthPlace}
                                />
                            </ContractorVerificationInputContainer>
                        </ContractorVerificationRowContainer>
                    </>
                ),
            };
        }

        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("birthPlace")}
                                name="birthPlace"
                                label={localizationData.placeBirth}
                                value={values.birthPlace}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                tooltip={getPreviousValue("birthPlace")}
                                required
                                error={errors && errors.birthPlace}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer full>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("idDocIssuedDate")}
                                name="idDocIssuedDate"
                                label={localizationData.idDocIssuedDate}
                                required
                                selected={values.idDocIssuedDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.idDocIssuedDate}
                                warning={fieldWarnings.idDocIssuedDate}
                                tooltip={getPreviousValue("idDocIssuedDate")}
                            />
                        </ContractorVerificationInputContainer>
                        {
                            isKz &&
                            <ContractorVerificationInputContainer full>
                                <NmDatePicker
                                    size="xl"
                                    dateFormatMask="99.99.9999"
                                    changed={getChanged("idDocValidToDate")}
                                    name="idDocValidToDate"
                                    label={localizationData.idDocValidToDate}
                                    required
                                    selected={values.idDocValidToDate}
                                    onChange={(e, {name, value}) => setFieldValue(name, value)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    error={errors && errors.idDocValidToDate}
                                    warning={fieldWarnings.idDocValidToDate}
                                    tooltip={getPreviousValue("idDocValidToDate")}
                                />
                            </ContractorVerificationInputContainer>
                        }
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationInputContainer full>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("idDocIssuedBy")}
                            name="idDocIssuedBy"
                            required
                            label={localizationData.idDocIssuedBy}
                            value={values.idDocIssuedBy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            error={errors && errors.idDocIssuedBy}
                            tooltip={getPreviousValue("idDocIssuedBy")}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationRowContainer>
                        {
                            isKg &&
                            <ContractorVerificationInputContainer>
                                <NmInputV2
                                    size="xl"
                                    changed={getChanged("ein")}
                                    name="ein"
                                    label={localizationData.ein}
                                    value={values.ein}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    error={errors && errors.ein}
                                    tooltip={getPreviousValue("ein")}
                                />
                            </ContractorVerificationInputContainer>
                        }
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: citizenship === citizenshipsDict.BY.value &&
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: "Паспортные данные",
                            isTitle: true,
                        },
                        {
                            title: localizationData.citizenship,
                            value: <CitizenshipWithFlag
                                textLevel="4"
                                citizenship={values.citizenship}
                                flagPositionEnd
                            />,
                        },
                        {
                            title: localizationData.passportNumber,
                            value: values.idDocNumber,
                        },
                        {
                            title: localizationData.ein,
                            value: values.ein,
                        },
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                    ]}
                />,
        };
    }

    function renderPagePassportBackSideBy() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("birthPlace")}
                                name="birthPlace"
                                label={localizationData.placeBirth}
                                value={values.birthPlace}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                tooltip={getPreviousValue("birthPlace")}
                                error={errors && errors.birthPlace}
                                required
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
        };
    }

    function renderSelfieCheckScan() {
        return {
            content: (
                <ContractorVerificationSelfieInfo
                    className="mb-4"
                />
            ),
        };
    }

    function renderVisaPersonalDataSpread() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("visaNum")}
                                name="visaNum"
                                required
                                label="Номер"
                                value={values.visaNum}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                tooltip={getPreviousValue("visaNum")}
                                error={errors && errors.visaNum}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("visaIssuedDate")}
                                name="visaIssuedDate"
                                label="Дата выдачи"
                                required
                                selected={values.visaIssuedDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.visaIssuedDate}
                                warning={fieldWarnings.visaIssuedDate}
                                tooltip={getPreviousValue("visaIssuedDate")}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("visaActiveFromDate")}
                                name="visaActiveFromDate"
                                label="Дата начала действия"
                                required
                                selected={values.visaActiveFromDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.visaActiveFromDate}
                                warning={fieldWarnings.visaActiveFromDate}
                                tooltip={getPreviousValue("visaActiveFromDate")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("visaActiveUntilDate")}
                                name="visaActiveUntilDate"
                                label="Дата окончания действия"
                                required
                                selected={values.visaActiveUntilDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.visaActiveUntilDate}
                                warning={fieldWarnings.visaActiveUntilDate}
                                tooltip={getPreviousValue("visaActiveUntilDate")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("visaId")}
                                name="visaId"
                                required
                                label="ID визы"
                                value={values.visaId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                tooltip={getPreviousValue("visaId")}
                                error={errors && errors.visaId}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                    ]}
                />
            ),
        };
    }

    function renderNotificationOfArrivalSecond() {
        return {
            content: (
                <ContractorVerificationInfoBlockRow
                    className="mb-2 mb-xl-4"
                    list={[
                        {
                            title: `${localizationData.arrivalDocType}:`,
                            value: arrivalDocTypeDict[values.arrivalDocType] || "-",
                        },
                    ]}
                />
            ),
        };
    }

    function renderPageScan() {
        return {
            content: null,
        };
    }

    function renderPageSecond() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            citizenship,
            migrationStatus,
            localizationData,
            fields: ["num"],
        });

        return {
            content: (
                <ContractorVerificationRowContainer>
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("migrationCardNumber")}
                            name="migrationCardNumber"
                            required
                            label={localizationData.migrationCardSeriesNumber}
                            value={values.migrationCardNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            tooltip={getPreviousValue("migrationCardNumber")}
                            error={errors && errors.migrationCardNumber}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationInputContainer>
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            changed={getChanged("migrationCardIssuedDate")}
                            name="migrationCardIssuedDate"
                            label={localizationData.migrationCardIssuedDate}
                            maxDate={new Date()}
                            required
                            selected={values.migrationCardIssuedDate}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            error={errors && errors.migrationCardIssuedDate}
                            warning={fieldWarnings.migrationCardIssuedDate}
                            tooltip={getPreviousValue("migrationCardIssuedDate")}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationInputContainer>
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            changed={getChanged("migrationCardValidToDate")}
                            name="migrationCardValidToDate"
                            label={localizationData.migrationCardExpirationDate}
                            minDate={new Date()}
                            selected={values.migrationCardValidToDate}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            error={errors && errors.migrationCardValidToDate}
                            warning={fieldWarnings.migrationCardValidToDate}
                            tooltip={getPreviousValue("migrationCardValidToDate")}
                        />
                    </ContractorVerificationInputContainer>
                </ContractorVerificationRowContainer>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: getContractorPassportTitle({
                                migrationStatus,
                                citizenship,
                            }),
                            isTitle: true,
                        },
                        ...passportFields,
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                    ]}
                />
            ),
        };
    }

    function renderResidencePermit() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("residencePermitNumber")}
                                name="residencePermitNumber"
                                required
                                label="Серия и номер"
                                mask="99 9999999"
                                maskChar={null}
                                value={values.residencePermitNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                error={errors && errors.residencePermitNumber}
                                tooltip={getPreviousValue("residencePermitNumber")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("residencePermitIssuedDate")}
                                name="residencePermitIssuedDate"
                                label={localizationData.residencePermitIssuedDate}
                                maxDate={new Date()}
                                required
                                selected={values.residencePermitIssuedDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.residencePermitIssuedDate}
                                tooltip={getPreviousValue("residencePermitIssuedDate")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("residencePermitValidToDate")}
                                name="residencePermitValidToDate"
                                label={localizationData.residencePermitValidToDate}
                                selected={values.residencePermitValidToDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                error={errors && errors.residencePermitValidToDate}
                                warning={fieldWarnings.residencePermitValidToDate}
                                tooltip={getPreviousValue("residencePermitValidToDate")}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("residencePermitIssuedBy")}
                                name="residencePermitIssuedBy"
                                required
                                label={localizationData.residencePermitIssuedBy}
                                value={values.residencePermitIssuedBy}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                tooltip={getPreviousValue("residencePermitIssuedBy")}
                                error={errors && errors.residencePermitIssuedBy}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("residencePermitDecisionNumber")}
                                name="residencePermitDecisionNumber"
                                label={localizationData.residencePermitDecisionNumber}
                                value={values.residencePermitDecisionNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                tooltip={getPreviousValue("residencePermitDecisionNumber")}
                                error={errors && errors.residencePermitDecisionNumber}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("residencePermitDecisionDate")}
                                name="residencePermitDecisionDate"
                                label={localizationData.residencePermitDecisionDate}
                                maxDate={new Date()}
                                selected={values.residencePermitDecisionDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors && errors.residencePermitDecisionDate}
                                tooltip={getPreviousValue("residencePermitDecisionDate")}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: renderArrivalDocInfoBlock(),
        };
    }

    function renderNotificationOfArrivalFirst() {
        return {
            content: (
                <>
                    <ContractorVerificationInfoBlockRow
                        className="mb-2 mb-xl-4"
                        list={[
                            {
                                title: `${localizationData.arrivalDocType}:`,
                                value: arrivalDocTypeDict[values.arrivalDocType] || "-",
                            },
                        ]}
                    />
                    {
                        values.arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION &&
                        <>
                            <ContractorVerificationRowContainer>
                                <ContractorVerificationInputContainer full>
                                    <NmInputV2
                                        size="xl"
                                        changed={getChanged("address")}
                                        name="address"
                                        label={localizationData.arrivalAddress}
                                        value={values.address}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        tooltip={getPreviousValue("address")}
                                        error={errors && errors.address}
                                        required
                                    />
                                </ContractorVerificationInputContainer>
                            </ContractorVerificationRowContainer>
                            <ContractorVerificationRowContainer>
                                <ContractorVerificationInputContainer>
                                    <NmDatePicker
                                        size="xl"
                                        dateFormatMask="99.99.9999"
                                        changed={getChanged("registrationDate")}
                                        name="registrationDate"
                                        required
                                        maxDate={new Date()}
                                        label={localizationData.registrationDate}
                                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        error={errors && errors.registrationDate}
                                        selected={values.registrationDate}
                                        tooltip={getPreviousValue("registrationDate")}
                                    />
                                </ContractorVerificationInputContainer>
                                <ContractorVerificationInputContainer>
                                    <NmDatePicker
                                        size="xl"
                                        dateFormatMask="99.99.9999"
                                        changed={getChanged("arrivalNoticeStayEndDate")}
                                        name="arrivalNoticeStayEndDate"
                                        required
                                        minDate={new Date()}
                                        label={localizationData.arrivalNoticeStayEndDate}
                                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        error={errors && errors.arrivalNoticeStayEndDate}
                                        selected={values.arrivalNoticeStayEndDate}
                                        warning={fieldWarnings.arrivalNoticeStayEndDate}
                                        tooltip={getPreviousValue("arrivalNoticeStayEndDate")}
                                    />
                                </ContractorVerificationInputContainer>
                            </ContractorVerificationRowContainer>
                        </>
                    }
                    {
                        values.arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT &&
                        <>
                            <ContractorVerificationRowContainer>
                                <ContractorVerificationInputContainer>
                                    <NmInputV2
                                        size="xl"
                                        changed={getChanged("residencePermitNumber")}
                                        name="residencePermitNumber"
                                        required
                                        label={localizationData.residencePermitNumber}
                                        mask="99 9999999"
                                        maskChar={null}
                                        value={values.residencePermitNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        error={errors && errors.residencePermitNumber}
                                        tooltip={getPreviousValue("residencePermitNumber")}
                                    />
                                </ContractorVerificationInputContainer>
                                <ContractorVerificationInputContainer>
                                    <NmDatePicker
                                        size="xl"
                                        dateFormatMask="99.99.9999"
                                        changed={getChanged("residencePermitIssuedDate")}
                                        name="residencePermitIssuedDate"
                                        label={localizationData.residencePermitIssuedDate}
                                        maxDate={new Date()}
                                        required
                                        selected={values.residencePermitIssuedDate}
                                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        error={errors && errors.residencePermitIssuedDate}
                                        tooltip={getPreviousValue("residencePermitIssuedDate")}
                                    />
                                </ContractorVerificationInputContainer>
                                <ContractorVerificationInputContainer>
                                    <NmDatePicker
                                        size="xl"
                                        dateFormatMask="99.99.9999"
                                        changed={getChanged("residencePermitValidToDate")}
                                        name="residencePermitValidToDate"
                                        label={localizationData.residencePermitValidToDate}
                                        selected={values.residencePermitValidToDate}
                                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        error={errors && errors.residencePermitValidToDate}
                                        warning={fieldWarnings.residencePermitValidToDate}
                                        tooltip={getPreviousValue("residencePermitValidToDate")}
                                    />
                                </ContractorVerificationInputContainer>
                            </ContractorVerificationRowContainer>
                            <ContractorVerificationRowContainer>
                                <ContractorVerificationInputContainer full>
                                    <NmInputV2
                                        size="xl"
                                        changed={getChanged("residencePermitIssuedBy")}
                                        name="residencePermitIssuedBy"
                                        required
                                        label={localizationData.residencePermitIssuedBy}
                                        value={values.residencePermitIssuedBy}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        tooltip={getPreviousValue("residencePermitIssuedBy")}
                                        error={errors && errors.residencePermitIssuedBy}
                                    />
                                </ContractorVerificationInputContainer>
                            </ContractorVerificationRowContainer>
                            <ContractorVerificationRowContainer>
                                <ContractorVerificationInputContainer>
                                    <NmInputV2
                                        size="xl"
                                        changed={getChanged("residencePermitDecisionNumber")}
                                        name="residencePermitDecisionNumber"
                                        label={localizationData.residencePermitDecisionNumber}
                                        value={values.residencePermitDecisionNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        tooltip={getPreviousValue("residencePermitDecisionNumber")}
                                        error={errors && errors.residencePermitDecisionNumber}
                                    />
                                </ContractorVerificationInputContainer>
                                <ContractorVerificationInputContainer>
                                    <NmDatePicker
                                        size="xl"
                                        dateFormatMask="99.99.9999"
                                        changed={getChanged("residencePermitDecisionDate")}
                                        name="residencePermitDecisionDate"
                                        label={localizationData.residencePermitDecisionDate}
                                        maxDate={new Date()}
                                        required
                                        selected={values.residencePermitDecisionDate}
                                        onChange={(e, {name, value}) => setFieldValue(name, value)}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        error={errors && errors.residencePermitDecisionDate}
                                        tooltip={getPreviousValue("residencePermitDecisionDate")}
                                    />
                                </ContractorVerificationInputContainer>
                            </ContractorVerificationRowContainer>
                        </>
                    }
                </>
            ),
            bottomContent: renderArrivalDocInfoBlock(),
        };
    }

    function renderArrivalDocInfoBlock() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            citizenship,
            migrationStatus,
            localizationData,
            fields: ["num", "issuedDate", "validToDate"],
        });

        const generalFields = [
            {
                title: getContractorPassportTitle({
                    migrationStatus,
                    citizenship,
                }),
                isTitle: true,
            },
            {
                title: localizationData.citizenship,
                value: <CitizenshipWithFlag
                    textLevel="4"
                    citizenship={values.citizenship}
                    flagPositionEnd
                />,
            },
            isVisiblePassportType && {
                title: localizationData.passportType,
                value: passportType,
            },
            ...passportFields,
            {
                title: localizationData.titlePersonalData,
                isTitle: true,
            },
            {
                title: localizationData.lastName,
                value: values.lastName,
            },
            {
                title: localizationData.firstName,
                value: values.firstName,
            },
            {
                title: localizationData.patronymic,
                value: values.patronymic,
            },
            {
                title: localizationData.birthDate,
                value: formatDateWithoutTime(values.birthDate),
            },
            {
                title: localizationData.placeBirth,
                value: values[birthPlaceField],
            },
            {
                title: localizationData.gender,
                value: GENDER_DICT[values.gender],
            },
        ];

        if (!isBelarusian) {
            const migrationFields = [
                {
                    title: localizationData.titleMigrationCard,
                    isTitle: true,
                },
                {
                    title: localizationData.migrationCardSeriesNumber,
                    value: values.migrationCardNumber,
                },
                {
                    title: localizationData.migrationCardIssuedDate,
                    value: formatDateWithoutTime(values.migrationCardIssuedDate),
                },
                {
                    title: localizationData.migrationCardExpirationDate,
                    value: formatDateWithoutTime(values.migrationCardValidToDate),
                },
            ];

            return (
                <ContractorVerificationInfoBlock
                    list={[
                        ...generalFields,
                        ...migrationFields,
                    ]}
                />
            );
        }

        return (
            <ContractorVerificationInfoBlock
                list={generalFields}
            />
        );
    }

    function renderInnScan() {
        return {
            content: (
                <ContractorVerificationInfoBlockRow
                    className="mb-2 mb-xl-4"
                    list={[{
                        title: "ИНН:",
                        value: values.inn,
                    }]}
                />
            ),
        };
    }

    function renderPageEmploymentPatentFrontSide() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            citizenship,
            migrationStatus,
            localizationData,
            fields: ["num"],
        });

        return {
            content: (
                <ContractorVerificationRowContainer>
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("employmentPatentSeries")}
                            name="employmentPatentSeries"
                            value={values.employmentPatentSeries}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            label="Серия"
                            tooltip={getPreviousValue("employmentPatentSeries")}
                            required
                            error={errors && errors.employmentPatentSeries}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("employmentPatentNum")}
                            name="employmentPatentNum"
                            mask="9999999999"
                            value={values.employmentPatentNum}
                            onFocus={handleFocus}
                            onBLur={handleBlur}
                            onChange={handleChange}
                            label="Номер"
                            tooltip={getPreviousValue("employmentPatentNum")}
                            required
                            error={errors && errors.employmentPatentNum}
                        />
                    </ContractorVerificationInputContainer>
                </ContractorVerificationRowContainer>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: getContractorPassportTitle({
                                migrationStatus,
                                citizenship,
                            }),
                            isTitle: true,
                        },
                        {
                            title: localizationData.citizenship,
                            value: <CitizenshipWithFlag
                                textLevel="4"
                                citizenship={values.citizenship}
                                flagPositionEnd
                            />,
                        },
                        ...passportFields,
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                    ]}
                />
            ),
        };
    }

    function renderPageEmploymentPatentBackSide() {
        return {
            content: (
                <ContractorVerificationRowContainer>
                    <ContractorVerificationInputContainer>
                        <NmInputV2
                            size="xl"
                            changed={getChanged("employmentPatentRegistrationNumber")}
                            name="employmentPatentRegistrationNumber"
                            value={values.employmentPatentRegistrationNumber}
                            type="text"
                            label="Регистрационный номер"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            tooltip={getPreviousValue("employmentPatentRegistrationNumber")}
                            error={errors && errors.employmentPatentRegistrationNumber}
                        />
                    </ContractorVerificationInputContainer>
                    <ContractorVerificationInputContainer>
                        <NmDatePicker
                            size="xl"
                            dateFormatMask="99.99.9999"
                            changed={getChanged("employmentPatentIssuedDate")}
                            name="employmentPatentIssuedDate"
                            label="Дата выдачи"
                            selected={values.employmentPatentIssuedDate}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            error={errors && errors.employmentPatentIssuedDate}
                            warning={fieldWarnings.employmentPatentIssuedDate}
                            tooltip={getPreviousValue("employmentPatentIssuedDate")}
                            required
                        />
                    </ContractorVerificationInputContainer>
                </ContractorVerificationRowContainer>
            ),
        };
    }

    function renderPageVhiPolicy() {
        return {
            content: (
                <>
                    <ContractorVerificationInfoBlockRow
                        className="mb-2 mb-xl-4"
                        list={[{
                            title: "Тип полиса:",
                            value: CONTRACTOR_VHI_POLICE_TYPE_DICT[values.vhiPolicyType],
                        }]}
                    />
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                dateFormatMask="99.99.9999"
                                size="xl"
                                changed={getChanged("vhiPolicyValidFromDate")}
                                name="vhiPolicyValidFromDate"
                                label="Дата начала действия"
                                selected={values.vhiPolicyValidFromDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                tooltip={getPreviousValue("vhiPolicyValidFromDate")}
                                error={errors && errors.vhiPolicyValidFromDate}
                                required
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("vhiPolicyValidToDate")}
                                name="vhiPolicyValidToDate"
                                label="Дата окончания действия"
                                selected={values.vhiPolicyValidToDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                warning={fieldWarnings.vhiPolicyValidToDate}
                                tooltip={getPreviousValue("vhiPolicyValidToDate")}
                                error={errors && errors.vhiPolicyValidToDate}
                                required
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: getPersonalData(),
        };
    }

    return (
        <VerificationDocumentForm
            progressCompletionChecking={
                progressCompletionChecking ||
                progressEndCheck
            }
            loading={loading}
            contractorId={contractorId}
            savedFiles={savedFiles}
            onChangeRequestInnList={onChangeRequestInnList}
            isRequestInnList={isRequestInnList}
            contractor={contractor}
            personalData={personalData}
            pendingFileTypes={pendingFileTypes}
            previousFiles={previousFiles}
            isFocus={isFocus}
            dirty={dirty}
            setInnStatus={setInnStatus}
            innStatus={innStatus}
            handleSave={handleSave}
            scanWarnings={scanWarnings}
            stepsConfig={stepsConfig}
            validateForm={validateForm}
            comparisonData={comparisonData}
            getFileId={getFileId}
            values={values}
            scans={scans}
            handleClose={handleClose}
            errors={errors}
        />
    );
};

export default ContractorVerificationDataForm;