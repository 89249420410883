import {
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST,
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS,
    BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
    BFF_CROWD_TASKS_ADD_DRAFT_REQUEST,
    BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS,
    BFF_CROWD_TASKS_CLOSE_ERROR,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_CLOSE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_SUCCESS,
    BFF_CROWD_TASKS_DELETE_ERROR,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_DELETE_REQUEST,
    BFF_CROWD_TASKS_DELETE_SUCCESS,
    BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
    BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST,
    BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS,
    BFF_CROWD_TASKS_EXTEND_ERROR,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_EXTEND_REQUEST,
    BFF_CROWD_TASKS_EXTEND_SUCCESS,
    BFF_CROWD_TASKS_GET_BY_ID_ERROR,
    BFF_CROWD_TASKS_GET_BY_ID_REQUEST,
    BFF_CROWD_TASKS_GET_BY_ID_SUCCESS,
    BFF_CROWD_TASKS_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_PUBLISH_ERROR,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_SUCCESS,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS,
    BFF_CROWD_TASKS_UPDATE_ERROR,
    BFF_CROWD_TASKS_UPDATE_REQUEST,
    BFF_CROWD_TASKS_UPDATE_SUCCESS,
    UPDATE_CROWD_TASKS_FILED_STORE,
} from "./actions";

const initial = {
    pageData: {},
    card: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressCard: false,
    progressAction: false,
    totalAmount: 0,
    depositState: {},
    depositStateProgress: false,
    totalTasksPaymentAmount: 0,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_CROWD_TASKS_GET_PAGE_REQUEST:
    case BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_CROWD_TASKS_GET_PAGE_SUCCESS:
    case BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS: {
        return {
            ...state,
            list: payload.results,
            totalCount: payload.totalCount,
            totalTasksPaymentAmount: payload.totalTasksPaymentAmount || 0,
            progress: false,
        };
    }
    case BFF_CROWD_TASKS_GET_PAGE_ERROR:
    case BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case BFF_CROWD_TASKS_GET_BY_ID_REQUEST: {
        return {
            ...state,
            progressCard: true,
        };
    }
    case BFF_CROWD_TASKS_GET_BY_ID_SUCCESS: {
        return {
            ...state,
            card: payload.result,
            progressCard: false,
        };
    }
    case BFF_CROWD_TASKS_GET_BY_ID_ERROR: {
        return {
            ...state,
            progressCard: false,
        };
    }
    case BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST:
    case BFF_CROWD_TASKS_ADD_DRAFT_REQUEST:
    case BFF_CROWD_TASKS_UPDATE_REQUEST:
    case BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST:
    case BFF_CROWD_TASKS_PUBLISH_REQUEST:
    case BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST:
    case BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST:
    case BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST:
    case BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST:
    case BFF_CROWD_TASKS_CLOSE_REQUEST:
    case BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST:
    case BFF_CROWD_TASKS_EXTEND_REQUEST:
    case BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST:
    case BFF_CROWD_TASKS_DELETE_REQUEST:
    case BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS:
    case BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR:
    case BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS:
    case BFF_CROWD_TASKS_ADD_DRAFT_ERROR:
    case BFF_CROWD_TASKS_UPDATE_SUCCESS:
    case BFF_CROWD_TASKS_UPDATE_ERROR:
    case BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS:
    case BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR:
    case BFF_CROWD_TASKS_PUBLISH_SUCCESS:
    case BFF_CROWD_TASKS_PUBLISH_ERROR:
    case BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS:
    case BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR:
    case BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS:
    case BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR:
    case BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS:
    case BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR:
    case BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS:
    case BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR:
    case BFF_CROWD_TASKS_CLOSE_SUCCESS:
    case BFF_CROWD_TASKS_CLOSE_ERROR:
    case BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS:
    case BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR:
    case BFF_CROWD_TASKS_EXTEND_SUCCESS:
    case BFF_CROWD_TASKS_EXTEND_ERROR:
    case BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS:
    case BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR:
    case BFF_CROWD_TASKS_DELETE_SUCCESS:
    case BFF_CROWD_TASKS_DELETE_ERROR:
    case BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS:
    case BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS: {
        return {
            ...state,
            totalAmount: payload || 0,
        };
    }
    case BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST: {
        return {
            ...state,
            depositStateProgress: true,
        };
    }
    case BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS: {
        return {
            ...state,
            depositState: payload,
            depositStateProgress: false,
        };
    }
    case BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR: {
        return {
            ...state,
            depositStateProgress: false,
        };
    }
    case UPDATE_CROWD_TASKS_FILED_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};