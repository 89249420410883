import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEqual} from "lodash";

import formatDate from "../../../../../../utils/dateFormat";
import {convertStringToDate} from "../../../../../../utils/stringHelper";
import {getContractorOldPersonalDataValidationSchema} from "../utils/validation";

import {contractorCardSelector, updatePreviousPassport} from "../../../../../../ducks/contractor";

export const useContractorOldPersonalData = (params) => {
    const {
        onClose,
    } = params;
    const card = useSelector(contractorCardSelector);
    const {
        previousPassportFirstName,
        previousPassportLastName,
        previousPassportPatronymic,
        previousPassportBirthDate,
        previousPassportIdDocNumber,
        contractorId,
        inn,
        citizenship,
    } = card;

    const dispatch = useDispatch();

    const initialValues = {
        previousPassportBirthDate: convertStringToDate(previousPassportBirthDate) || null,
        previousPassportFirstName,
        previousPassportLastName,
        previousPassportPatronymic,
        previousPassportIdDocNumber,
        contractorId,
        inn,
        citizenship,
    };

    const onSubmit = () => {
        validateForm().then(() => {
            if (!isValid) {
                return;
            }

            setSubmitting(true);
            handleSubmit();
            onClose();
        });
    };

    const handleChange = (e, {name, value}) => setFieldValue(name, value);

    const {
        handleSubmit,
        values,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        isValid,
        setSubmitting,
        validateForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting})=> {
            if(!isEqual(initialValues, values)) {
                const requestData = {
                    contractorId,
                    previousPassportBirthDate: values.previousPassportBirthDate ? formatDate(values.previousPassportBirthDate, "yyyy-MM-dd") : undefined,
                    previousPassportIdDocNumber: values.previousPassportIdDocNumber  || undefined,
                    previousPassportFirstName: values.previousPassportFirstName || undefined,
                    previousPassportLastName: values.previousPassportLastName || undefined,
                    previousPassportPatronymic: values.previousPassportPatronymic || undefined,
                };

                dispatch(updatePreviousPassport(requestData));
            }

            setSubmitting(false);
        },
        validationSchema: getContractorOldPersonalDataValidationSchema(),
        validateOnBlur: false,
    });

    return {
        onSubmit,
        values,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        handleChange,
    };
};