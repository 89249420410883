import React, {useEffect, useState} from "react";
import InputMask from "react-input-mask";

import {ReactComponent as IconCalendar} from "../../../../images/calendar_today.svg";

import {useClickOutside} from "../../../../hooks/useClickOutside";

import bem from "../../../../utils/bem";

import "./style.sass";

const NmDatePickerInput = (props) => {
    const {
        value,
        className = "",
        onChange,
        name,
        error,
        onBlur,
        onClick,
        dateFormatMask,
        placeholderText,
        readOnlyInput,
        focused,
        onMouseDownInput,
        disabled,
        size = "xl",
        changed,
        warning,
    } = props;

    const [localFocused, setLocalFocused] = useState(false);
    const [block, element] = bem("nm-datepicker-input", `${className}`);

    const rootRef = useClickOutside((e) => {
        setLocalFocused(false);
    }, localFocused);

    const isEmpty = Boolean(value);

    useEffect(() => {
        if (!focused) {
            setLocalFocused(false);
        }
    }, [focused]);

    const onMouseDown = () => {
        setLocalFocused(true);

        if (onMouseDownInput) {
            onMouseDownInput();
        }
    };

    const onBlurContainer = () => {
        setLocalFocused(false);
    };

    const onChangeMask = (e) => {
        const {target} = e;
        const {value = "", name} = target;

        onChange(e, {value, name});
    };

    return (
        <div
            ref={rootRef}
            onMouseDown={onMouseDown}
            onClick={onClick}
            onBlur={onBlurContainer}
            className={block({
                active: isEmpty,
                size,
                focused: focused || localFocused,
                error: Boolean(error),
                warning: error ? false : Boolean(warning),
                disabled,
                changed,
            })}
        >
            <InputMask
                className={block()}
                alwaysShowMask={false}
                disabled={disabled}
                mask={dateFormatMask}
                onBlur={onBlur}
                onMouseDown={event => {
                    if (readOnlyInput) {
                        event.preventDefault();
                    }
                }}
                onChange={onChangeMask}
                name={name}
                value={value}
                placeholder={placeholderText}
            >
                {
                    (inputProps) => {
                        return (
                            <input
                                {...inputProps}
                                placeholder={placeholderText}
                                disabled={disabled}
                                className={`${element("input", {clearStyles: true})}`}
                            />
                        );
                    }

                }
            </InputMask>
            <IconCalendar
                className={element("icon", {size})}
            />
        </div>
    );
};

export default NmDatePickerInput;