import React from "react";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import FilterSpecialities from "../../../../../../components/ActualComponents/FilterSpecialities";
import NmDadata from "../../../../../../components/ActualComponents/NmDadata";
import NmForm from "../../../../../../components/ActualComponents/NmForm";

export const SettingsDirectoriesTarrifGridFilter = (props) => {
    const {
        filter: {
            specialityIdListFilter = "",
            addressIdListFilter = [],
        },
        onChange,
        onSearch,
        onClear,
    } = props;

    return (
        <NmForm addMargin>
            <FilterSpecialities
                size="lg"
                name="specialityIdListFilter"
                label="Вид деятельности"
                placeholder="Введите вид деятельности"
                value={specialityIdListFilter}
                onChange={onChange}
                onSubmit={onSearch}
            />
            <NmDadata
                multiple
                size="lg"
                label="Город"
                name="addressIdListFilter"
                minSearchLength={2}
                onChange={onChange}
                isAllSelectionShown={false}
                onSubmit={onSearch}
                placeholder="Введите город"
                fromBound="city"
                toBound="city"
                initialAddressIds={addressIdListFilter}
            />
            <FilterButtonsV2
                onSearch={onSearch}
                onClear={onClear}
            />
        </NmForm>
    );
};