import {requiredMessage} from "../../../constants/validationRules";
import {
    ACT_CREATE_AMOUNT_BASED_VALIDATION_RULE, ACT_CREATE_TASK_RULE,
    ACT_CREATE_VOLUME_BASED_VALIDATION_RULE,
    ACT_CREATION_DESCRIPTION_PARAMS_VALIDATION,
    ACT_CREATION_REVIEW_VALIDATION,
} from "../constants";

export const getActCreationValidationRule = (params) => {
    const {
        isVolumeOrderType,
        isShowDescriptionParams,
        isTaskAct,
        workQualityScore,
        estimatedTimeScore,
    } = params;

    if (isTaskAct) {
        return ACT_CREATE_TASK_RULE;
    }

    const rule = isVolumeOrderType ?
        ACT_CREATE_VOLUME_BASED_VALIDATION_RULE :
        ACT_CREATE_AMOUNT_BASED_VALIDATION_RULE;

    if (isShowDescriptionParams) {
        return {
            descriptionParams: ACT_CREATION_DESCRIPTION_PARAMS_VALIDATION,
            ...rule,
        };
    }

    if (
        (workQualityScore && workQualityScore < 4)
        || (estimatedTimeScore && estimatedTimeScore < 4)
    ) {
        return {
            reviewText: ACT_CREATION_REVIEW_VALIDATION,
            ...rule,
        };
    }

    return rule;
};

export function validateDescriptionParams(descriptionParams, descriptionParamsCount) {
    if (!descriptionParams || descriptionParams.length === 0) {
        return requiredMessage;
    }

    if (descriptionParams.length < descriptionParamsCount) {
        return `Необходимо указать ${descriptionParamsCount} параметра`;
    }
}