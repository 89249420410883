import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/logs";


const LOGS_GET_PAGE_REQUEST = "LOGS_GET_PAGE_REQUEST";
const LOGS_GET_PAGE_SUCCESS = "LOGS_GET_PAGE_SUCCESS";
const LOGS_GET_PAGE_ERROR = "LOGS_GET_PAGE_ERROR";

const LOGS_GET_ACTION_SECTION_TYPE_DICT_REQUEST = "LOGS_GET_ACTION_SECTION_TYPE_DICT_REQUEST";
const LOGS_GET_ACTION_SECTION_TYPE_DICT_SUCCESS = "LOGS_GET_ACTION_SECTION_TYPE_DICT_SUCCESS";
const LOGS_GET_ACTION_SECTION_TYPE_DICT_ERROR = "LOGS_GET_ACTION_SECTION_TYPE_DICT_ERROR";

const LOGS_GET_ACTION_TYPE_DICT_REQUEST = "LOGS_GET_ACTION_TYPE_DICT_REQUEST";
const LOGS_GET_ACTION_TYPE_DICT_SUCCESS = "LOGS_GET_ACTION_TYPE_DICT_SUCCESS";
const LOGS_GET_ACTION_TYPE_DICT_ERROR = "LOGS_GET_ACTION_TYPE_DICT_ERROR";

const LOGS_UPDATE_FIELD_STORE = "LOGS_UPDATE_FIELD_STORE";

const initial = {
    events: [],
    dict: {},
    progress: true,
    error: "",
    pageData: {},
    actionTypeList: {},
};

export default function (state = initial, {type, payload}) {
    switch (type) {
    case LOGS_UPDATE_FIELD_STORE:
        return {
            ...state,
            ...payload,
        };
    case LOGS_GET_PAGE_REQUEST:
        return {
            ...state,
            progress: true,
            pageData: payload,
        };
    case LOGS_GET_ACTION_SECTION_TYPE_DICT_SUCCESS:
        return {
            ...state,
            dict: payload,
        };
    case LOGS_GET_ACTION_TYPE_DICT_SUCCESS:
        return {
            ...state,
            actionTypeList: payload,
        };
    case LOGS_GET_PAGE_SUCCESS:
        const {totalCount, objects} = payload;

        return {
            ...state,
            progress: false,
            totalCount,
            events: objects,
        };
    case LOGS_GET_ACTION_TYPE_DICT_ERROR:
    case LOGS_GET_PAGE_ERROR:
        return {
            ...state,
            error: payload,
        };
    default:
        return state;
    }
}

export function getEvents(payload) {
    return {
        type: LOGS_GET_PAGE_REQUEST,
        payload,
    };
}

export function getActionSectionTypeDict() {
    return {
        type: LOGS_GET_ACTION_SECTION_TYPE_DICT_REQUEST,
    };
}

export function getActionTypeDict() {
    return {
        type: LOGS_GET_ACTION_TYPE_DICT_REQUEST,
    };
}

export function updateFieldStore(payload) {
    return {
        type: LOGS_UPDATE_FIELD_STORE,
        payload,
    };
}

export const logsSelector = state => state.logs;
export const logsEventsSelector = createSelector(logsSelector, ({events}) => events);
export const logsGetPageProgressSelector = createSelector(logsSelector, ({progress}) => progress);
export const logsDictValueSelector = createSelector(logsSelector, ({dict}) => Object.keys(dict));
export const logsDictOptionsSelector = createSelector(logsSelector, ({dict}) => dictionaryToOptions(dict));
export const logsEventTypeOptionsSelector = createSelector(logsSelector, ({actionTypeList}) => dictionaryToOptions(actionTypeList));
export const logsTotalPagesSelector = createSelector(logsSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});
export const logsTotalCountSelector = createSelector(logsSelector, ({totalCount}) => {
    return totalCount;
});

export const logsGetPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: LOGS_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: LOGS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: LOGS_GET_PAGE_ERROR,
            payload: e,
        });
    }
};

export const getActionSectionTypeDictSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/dicts/actionSectionType`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: LOGS_GET_ACTION_SECTION_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: LOGS_GET_ACTION_SECTION_TYPE_DICT_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: LOGS_GET_ACTION_SECTION_TYPE_DICT_ERROR,
            payload: e,
        });
    }
};

export const getActionTypeDictSaga = function* (action) {
    try {
        const result = yield request.get(`${controller}/dicts/actionType`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: LOGS_GET_ACTION_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: LOGS_GET_ACTION_TYPE_DICT_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: LOGS_GET_ACTION_TYPE_DICT_ERROR,
            payload: e,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(LOGS_GET_PAGE_REQUEST, logsGetPageSaga),
        takeEvery(LOGS_GET_ACTION_SECTION_TYPE_DICT_REQUEST, getActionSectionTypeDictSaga),
        takeEvery(LOGS_GET_ACTION_TYPE_DICT_REQUEST, getActionTypeDictSaga),
    ]);
}
