export const ORDER_ANALYTIC_FIELDS = {
    AWAITING_SIGNATURE_COUNT_FOR_PUBLISHED_ORDER:"awaitingSignatureCountForPublishedOrder",
    AWAITING_SIGNATURE_COUNT_FOR_PUBLISHED_ORDER_RATIO:"awaitingSignatureCountForPublishedOrderRatio",
    CONTRACTORS_HIRED_COUNT: "contractorsHiredCount",
    CONTRACTORS_NEEDED_COUNT:"contractorsNeededCount",
    ORDER_PROGRESS_COUNT:"orderProgressCount",
    ORDER_PUBLISHED_COUNT:"orderPublishedCount",
    VACANCIES_COUNT_FOR_PUBLISHED_ORDER: "vacanciesCountForPublishedOrder",
    VACANCIES_COUNT_FOR_PUBLISHED_ORDER_RATIO: "vacanciesCountForPublishedOrderRatio",
    ORDERS_NOT_AWAITING_COUNT:"ordersNotAwaitingCount",

};

export const ORDER_ANALYTIC_TYPES = {
    AWAITING_SIGNATURE: "AWAITING_SIGNATURE",
    VACANCIES: "VACANCIES",
    ORDER_PROGRESS: "ORDER_PROGRESS",
    PUBLISHED_ORDERS: "PUBLISHED_ORDERS",
    ORDER_CONTRACTOR_HIRED: "ORDER_CONTRACTOR_HIRED",
    ORDERS_NOT_AWAITING: "ORDERS_NOT_AWAITING",
};