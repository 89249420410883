import React from "react";
import {useTranslation} from "react-i18next";

import NmButton from "../../../../components/NmButton";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import {OPERATION} from "../index";

import {useDepositValuesData} from "../../../../components/DepositValuesAmountInfo/hooks/useDepositValuesData";

import {ADMIN, CLIENT_ACCOUNTANT, CLIENT_ADMIN, PROJECT_MANAGER} from "../../../../constants/roles";

const FinancePaymentListClientMassActions = (props) => {
    const {
        numberSelected,
        onOpenConfirm,
        role,
        selectedSum,
        onCheckOrderWorkActs,
        isCivil,
    } = props;
    const {t} = useTranslation();
    const {depositValues} = useDepositValuesData({isCivil});
    const {availableForPaymentsAmount} = depositValues;

    return (
        <>
            {[ADMIN, CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT].includes(role) &&
            <SelectionCountWithAction
                className="finance-payment-list__mass-actions"
                adaptiveLogic
                count={numberSelected}
                disabled={(availableForPaymentsAmount - selectedSum() < 0)}
                onClick={() => {
                    onOpenConfirm({
                        operation: OPERATION.CONFIRM,
                        checkingDuplicates: true,
                        checkWarning: true,
                    });
                }}
                buttonColor="green"
                buttonContent={![CLIENT_ACCOUNTANT].includes(role) && t("finance-payment-list.confirm")}
                otherActions={
                    ![CLIENT_ACCOUNTANT].includes(role) &&
                    <NmButton
                        disabled={numberSelected === 0}
                        size="lg"
                        color="grey"
                        onClick={() => {
                            onOpenConfirm({
                                operation: OPERATION.REJECT,
                            });
                        }}
                    >
                        {t("button.reject")}
                    </NmButton>}
            />
            }
            {
                [ADMIN, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(role) &&
                <NmButton
                    className="ms-2"
                    disabled={numberSelected === 0}
                    size="lg"
                    color="light-green"
                    onClick={onCheckOrderWorkActs}
                >
                    Проверить
                </NmButton>
            }
        </>
    );
};

export default FinancePaymentListClientMassActions;