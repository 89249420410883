import React from "react";
import {useTranslation} from "react-i18next";

import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmButton from "../../../../../components/NmButton";

import {useDadataInnCheck} from "./hooks/useDadataInnCheck";

import {CLIENT_FIELD_NAME} from "../../../../../constants/clientList";
import {CLIENT_TYPE_CODE} from "../../../../../constants/dicts";

import "./style.sass";

export const ClientNewInn = (props) => {
    const {
        error,
        onChange,
        inn,
        clientType,
        onSuccess,
    } = props;

    const {t} = useTranslation();

    const {
        onCheck,
        progress,
    } = useDadataInnCheck({
        inn,
        clientType,
        onSuccess,
        autoCompleteFields: [
            // Официальное название компании
            "fullName",
            // Сокращенное название компании
            "name",
            // Адрес регистрации - ИП,
            // Адрес места нахождения - ООО, ин. организации
            "registeredAddress",
        ],
    });

    return (
        <div className="d-flex align-items-start flex-column flex-md-row">
            <div className="client-list-new-inn__input">
                <NmInputV2
                    size="xl"
                    label={t("client-new.itn")}
                    required
                    error={error}
                    type="text"
                    mask={clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ? "999999999999" : "9999999999"}
                    maskChar={null}
                    name={CLIENT_FIELD_NAME.INN}
                    placeholder={t("client-new.itn-placeholder")}
                    value={inn || ""}
                    onChange={onChange}
                />
            </div>
            <NmButton
                size="xl"
                type="button"
                disabled={progress}
                onClick={onCheck}
                className="client-list-new-inn__button mt-2 mt-md-5 ms-0 ms-md-4"
            >
                Получить данные
            </NmButton>
        </div>
    );
};