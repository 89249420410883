import React from "react";

import Text from "../../../../../components/ActualComponents/Text";

import {getClassNames} from "../../../../../utils/classNames";
import {isLastElementInArray} from "../../../../../utils/objectHelper";

import {COLOR} from "../../../../../constants/color";

export const KedoStaffRegistryCardDataBlock = (props) => {
    const {
        header,
        className,
        items,
    } = props;

    return (
        <div className={className}>
            <Text
                level="2"
                type="sub"
                medium={true}
                color={COLOR.BLACK_80}
            >
                {header}
            </Text>
            <div className="row">
                {
                    items.map((item, index) => {
                        const isLast = isLastElementInArray(index, items);

                        return (
                            <div
                                key={`${index}${item.name}`}
                                className={getClassNames([
                                    !isLast && "mt-3 mt-md-4",
                                    item.className,
                                ])}
                            >
                                <Text
                                    color={COLOR.BLACK_50}
                                    inline={true}
                                    level="2"
                                    className="me-2"
                                >
                                    {`${item.label}:`}
                                </Text>
                                <div className="d-flex align-items-center">
                                    <Text
                                        level="2"
                                        color={COLOR.BLACK_80}
                                    >

                                        {item.text || "Сведения пока не добавлены"}
                                    </Text>
                                    {
                                        item.additionalContent &&
                                        <div className="ms-1">
                                            {item.additionalContent}
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};
