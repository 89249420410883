import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getOnboardingScenarioList, updateOnboardingStore} from "../../../ducks/onboarding";

export function useFetchOnboardingList({filter = {}, pageSize, pageNum, clientId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateOnboardingStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getOnboardingScenarioList({
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}