import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getUserAvatarListThumbnails} from "../../../fileStore";
import {
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/crowd/tasks/analytics";

// POST /bff/adm/crowd/tasks/analytics/on-verification/get/page
// Получение страницы На проверке
const getPageCrowdTasksAnalyticsOnVerification = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/on-verification/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/analytics/on-contract-signing/get/page
// Получение страницы Подписывают договор
const getPageCrowdTasksAnalyticsOnContractSigning = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/on-contract-signing/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/analytics/no-responses/get/page
// Получение страницы Без откликов
const getPageCrowdTasksAnalyticNoResponses = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/no-responses/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/analytics/in-process-of-payment/get/page
// Получение страницы В оплате
const getPageCrowdTasksAnalyticInProcessPayment = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/in-process-of-payment/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/analytics/hired/get/page
// Получение страницы Нанятые
const getPageCrowdTasksAnalyticHired = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/hired/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/analytics/at-work/get/page
// Получение страницы В работе
const getPageCrowdTasksAnalyticAtWork = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/at-work/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/crowd/tasks/analytics/count-aggregation/get
// Получение счетчиков
const getCountAggregationCrowdTasksAnalytic = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/count-aggregation/get`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_ON_VERIFICATION_GET_PAGE_REQUEST, getPageCrowdTasksAnalyticsOnVerification),
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_ON_CONTRACT_SIGNING_GET_PAGE_REQUEST, getPageCrowdTasksAnalyticsOnContractSigning),
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_NO_RESPONSES_GET_PAGE_REQUEST, getPageCrowdTasksAnalyticNoResponses),
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_IN_PROCESS_PAYMENT_GET_PAGE_REQUEST, getPageCrowdTasksAnalyticInProcessPayment),
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_HIRED_GET_PAGE_REQUEST, getPageCrowdTasksAnalyticHired),
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_AT_WORK_GET_PAGE_REQUEST, getPageCrowdTasksAnalyticAtWork),
        takeEvery(BFF_CROWD_TASKS_ANALYTICS_GET_COUNT_AGGREGATION_REQUEST, getCountAggregationCrowdTasksAnalytic),
    ]);
}