import {useTranslation} from "react-i18next";

export const useContractorVerificationLocalization = () => {
    const {t} = useTranslation();

    return {
        localizationData: {
            citizenship: t("contractor-passport-data.citizenship"),
            titlePersonalData: t("contractor-full-personal-data.title"),
            titlePayment: t("contractor-payment-data.title"),
            titleSpecialty: t("contractor-work-data.specialty"),
            titleWorkRegion: t("contractor-work-data.work-region"),
            titleRemoteWork: t("contractor-work-data.remote-work-label"),
            titlePromocode: t("promocode-card-block.promocode-card-title"),
            titleMigrationCard: t("contractor-migration-card.title"),
            titleArrival: t("arrival-notice-card.title"),
            placeBirth: t("contractor-passport-data.place-birth"),
            idDocNum: t("contractor-passport-data.passport-series-number"),
            idDocIssuedBy: t("contractor-passport-data.passport-issue-by"),
            idDocIssuedDate: t("contractor-passport-data.migration-card-date-issue"),
            idDocIssuedDepartmentCode: t("contractor-passport-data.passport-unit-code"),
            idDocValidToDate: t("contractor-passport-data.id-doc-valid-to-date"),
            passportNumber: t("contractor-passport-data.passport-number"),
            passportType: t("contractor-passport-data.passport-type"),
            ein: t("contractor-passport-data.ein"),
            notSpecified: t("is-not-specified.content"),
            lastName: t("contractor-full-personal-data.last-name"),
            firstName: t("contractor-full-personal-data.first-name"),
            patronymic: t("contractor-full-personal-data.patronymic"),
            birthDate: t("contractor-full-personal-data.birth-date"),
            gender: t("contractor-full-personal-data.gender"),
            actualAddress: t("contractor-full-personal-data.actual-address"),
            bankcardNumber: t("contractor-payment-data.bankcard-number"),
            email: "E-mail",
            inn: t("contractor-full-personal-data.inn"),
            phoneNumber: t("contractor-full-personal-data.phone-number"),
            noResultMessage: t("no-search-result.content"),
            promocodePlaceholder: t("promocode-card-block.enter-promocode"),
            arrivalDocType: t("arrival-notice-card.arrival-doc-type"),
            registrationDate: t("arrival-notice-card.registration-date"),
            arrivalNoticeStayEndDate: t("arrival-notice-card.arrival-notice-stay-end-date"),
            arrivalAddress: t("arrival-notice-card.address"),
            migrationCardSeriesNumber: t("contractor-migration-card.card-series-number"),
            migrationCardIssuedDate: t("contractor-migration-card.issue-date"),
            migrationCardExpirationDate: t("contractor-migration-card.expiration-date"),
            residencePermitDecisionNumber: t("arrival-notice-card.residence-permit-decision-number"),
            residencePermitDecisionDate: t("arrival-notice-card.residence-permit-decision-date"),
            residencePermitNumber: t("arrival-notice-card.residence-permit-number"),
            residencePermitIssuedBy: t("arrival-notice-card.residence-permit-issued-by"),
            residencePermitIssuedDate: t("arrival-notice-card.residence-permit-issued-date"),
            residencePermitValidToDate: t("arrival-notice-card.residence-permit-valid-to-date"),
            placeResidence: t("contractor-full-personal-data.place-residence"),
        },
    };
};