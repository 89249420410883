import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDadata from "../../../../../../components/ActualComponents/NmDadata";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useUpdateWorkCities, {defaultWorkCitiesForm} from "./hooks/useUpdateWorkCities";

import {dadataValueCityFormatter, getOptionsUniqCity} from "../../../../../../utils/dadata";

export default function ContractorWorkCitiesEditModal(props) {
    const {
        onClose,
        getProfile,
        editData = {...defaultWorkCitiesForm},
        contractorId,
    } = props;

    const {t} = useTranslation();

    const {
        onChangeForm,
        submit,
        form,
        setForm,
    } = useUpdateWorkCities({contractorId, onClose, getProfile});

    useEffect(() => {
        setForm({...editData});
    }, []);

    const {
        departureAddressesFiasIds,
        hasDepartureAddresses,
        initialOptions,
    } = form;

    const handleChangeAddressAdditional = (e, {value, name}) => {
        const ids = value.filter(item => typeof item === "string");

        onChangeForm(e, {name, value: ids});
    };

    return (
        <NmModal
            size="md"
            footer={
                <ApplyButtons
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={submit}
                />
            }
            header={
                <NmTitle size="lg">
                    Города для выезда
                </NmTitle>
            }
            onClose={onClose}
        >
            <NmForm
                addMargin
            >
                <NmCheckboxV2
                    checked={hasDepartureAddresses}
                    name="hasDepartureAddresses"
                    label="Города для выезда отсутствуют"
                    onChange={onChangeForm}
                />
                {!hasDepartureAddresses &&
                <>
                    <div className="nm-line" />
                    <NmDadata
                        size="xl"
                        label="Города для выезда"
                        name="departureAddressesFiasIds"
                        onChange={handleChangeAddressAdditional}
                        toBound="settlement"
                        fromBound="city"
                        multiple
                        isLoadInitialFromDadata={false}
                        initialOptions={initialOptions}
                        formatter={dadataValueCityFormatter}
                        optionsFormatter={getOptionsUniqCity}
                        isMountLoad={false}
                        initialAddressIds={departureAddressesFiasIds}
                    />
                </>
                }
            </NmForm>
        </NmModal>
    );
}