import React from "react";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import ClientRecruitmentAccessControl from "../../../recruitment/access-control";
import {
    ClientRecruitmentAccessControlRecruitersGroupsCard,
} from "../../../recruitment/access-control/recruiters-groups/card";
import RecruitmentCandidates from "../../../recruitment/candidates/list";
import ClientRecruitmentDirectories from "../../../recruitment/directories";
import RecruitmentVacancies from "../../../recruitment/vacancies";
import RecruitmentVacancyCard from "../../../recruitment/vacancies/card";

import {
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
    LINK_CLIENT_RECRUITMENT_CANDIDATES,
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_VACANCIES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD,
} from "../../../../constants/links";

export const ClientCardRecruitmentRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_RECRUITMENT_VACANCIES}
                component={RecruitmentVacancies}
            />
            <Route
                path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD}
                component={RecruitmentVacancyCard}
            />
            <Route
                path={LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD}
                component={RecruitmentCandidates}
            />
            <Route
                path={LINK_CLIENT_RECRUITMENT_CANDIDATES}
                component={RecruitmentCandidates}
            />
            <Route
                path={LINK_CLIENT_RECRUITMENT_DIRECTORIES}
                component={ClientRecruitmentDirectories}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD}
                component={ClientRecruitmentAccessControlRecruitersGroupsCard}
            />
            <Route
                path={LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL}
                component={ClientRecruitmentAccessControl}
            />
        </Switch>
    );
};