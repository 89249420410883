import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../../../../components/ActualComponents/HelpTooltip";
import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";

import {ORDER_FIELDS_NAME} from "../../../../../../../constants/clientList";
import {ORDER_CREATION_MODE} from "../../../../../../../constants/order";

import {clientObjectOptionsSelector} from "../../../../../../../ducks/clientObject";

const OrderFormPreConditionsObject = (props) => {
    const {
        onChange,
        isDisabled,
        isPromptOn,
        formData,
        onSearchObject,
        handleFocus,
        preloadedData,
        formError,
        creatingMode,
        valueObjectFilter,
    } = props;
    const {t} = useTranslation();
    const objectOptions = useSelector(clientObjectOptionsSelector);

    const isCreatingModeTemplate = creatingMode === ORDER_CREATION_MODE.TEMPLATE;

    return (
        <NmDropdownV2
            size="xl"
            multiple={isCreatingModeTemplate}
            label="Объект"
            disabled={
                isDisabled ||
                (
                    isCreatingModeTemplate &&
                    isEmpty(formData.templateProjectIds)
                ) ||
                (
                    [
                        ORDER_CREATION_MODE.FROM_TEMPLATE,
                    ].includes(creatingMode) &&
                    Boolean(preloadedData.objectId)
                )
            }
            search
            searchQuery={valueObjectFilter}
            onSearchChange={onSearchObject}
            options={objectOptions}
            name={!isCreatingModeTemplate ? ORDER_FIELDS_NAME.OBJECT_ID : "templateObjectIds"}
            error={!isCreatingModeTemplate ? formError[ORDER_FIELDS_NAME.OBJECT_ID] : formError.templateObjectIds}
            value={!isCreatingModeTemplate ? formData[ORDER_FIELDS_NAME.OBJECT_ID] : formData.templateObjectIds}
            required
            isVisibleTooltip={isPromptOn}
            tooltip={
                <HelpTooltip
                    info
                    hover
                    text={t("order-new.objectSuggestionContent")}
                    position="bottom-left"
                />
            }
            onMouseEnter={handleFocus}
            placeholder={t("order-new.selectFromList")}
            onChange={onChange}
            onFocus={handleFocus}
        />
    );
};

export default OrderFormPreConditionsObject;