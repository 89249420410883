import React from "react";

import {ContractorFinanceIncomeCrowd} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
} from "../../../../../constants/links";
import {STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED} from "../../../../../constants/status";

import {getPageCrowdPaymentContractorCompleted} from "../../../../../ducks/bff/contractor/crowd-payments/services";
const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_COMPLETED);

export const ContractorFinanceIncomeCrowdCompleted = (props) => {
    return (
        <ContractorFinanceIncomeCrowd
            subTabsLinks={{
                orders: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
                tasks: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
            }}
            fetchList={getPageCrowdPaymentContractorCompleted}
            isShowNaimixStatusFilter={false}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
