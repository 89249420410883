import React, {useMemo} from "react";

import ClientCardPage from "../../../../components/ClientCardPage";
import ClientCardKedoRoute from "./Route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_KEDO_MEMBERS,
} from "../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER} from "../../../../constants/roles";

const ClientCardKedo = (props) => {
    const clientId = props.match.params.clientId;

    const role = ls(USER_ROLE);

    const tabs = useMemo(() => {
        return [
            {
                active: LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                name: "Сотрудники",
                isVisible: [ADMIN, NM_MANAGER].includes(role),
            },
            {
                active: LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                name: "Документооборот",
            },
            {
                active: LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                name: "Экспорт документов",
                isVisible: [CLIENT_ADMIN].includes(role),
            },
            {
                active: LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
                name: "Лог интеграции с 1С",
                isVisible: [ADMIN].includes(role),
            },
        ];
    }, []);

    return (
        <ClientCardPage
            title="КЭДО"
            tabs={tabs}
        >
            <ClientCardKedoRoute {...props} />
        </ClientCardPage>
    );
};

export default ClientCardKedo;
