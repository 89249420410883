import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import {contractorSelector} from "./contractor";
import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/contractors/clientremote";

const GET_CLIENT_REMOTE_CONTRACTORS_REQUEST = "GET_CLIENT_REMOTE_CONTRACTORS_REQUEST";
const GET_CLIENT_REMOTE_CONTRACTORS_SUCCESS = "GET_CLIENT_REMOTE_CONTRACTORS_SUCCESS";
const GET_CLIENT_REMOTE_CONTRACTORS_ERROR = "GET_CLIENT_REMOTE_CONTRACTORS_ERROR";

const DELETE_CLIENT_REMOTE_CONTRACTOR_REQUEST = "DELETE_CLIENT_REMOTE_CONTRACTOR_REQUEST";
const DELETE_CLIENT_REMOTE_CONTRACTOR_SUCCESS = "DELETE_CLIENT_REMOTE_CONTRACTOR_SUCCESS";
const DELETE_CLIENT_REMOTE_CONTRACTOR_ERROR = "DELETE_CLIENT_REMOTE_CONTRACTOR_ERROR";

const CLIENT_REMOTE_CONTRACTORS_UPDATE_STORE = "CLIENT_REMOTE_CONTRACTORS_UPDATE_STORE";

const initialState = {
    listProgress: false,
    progressAction: false,
    list: [],
    pageData: {},
    totalCount: 0,
    totalPages: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case DELETE_CLIENT_REMOTE_CONTRACTOR_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case DELETE_CLIENT_REMOTE_CONTRACTOR_SUCCESS:
    case DELETE_CLIENT_REMOTE_CONTRACTOR_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case GET_CLIENT_REMOTE_CONTRACTORS_REQUEST:
        return {
            ...state,
            pageData: payload.data,
            listProgress: true,
        };
    case GET_CLIENT_REMOTE_CONTRACTORS_SUCCESS:
        return {
            ...state,
            list: payload.contractors,
            totalCount: payload.totalCount,
            listProgress: false,
        };
    case GET_CLIENT_REMOTE_CONTRACTORS_ERROR:
        return {
            ...state,
            listProgress: false,
        };
    case CLIENT_REMOTE_CONTRACTORS_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export const getClientRemoteContractorsPage = payload => ({
    type: GET_CLIENT_REMOTE_CONTRACTORS_REQUEST,
    payload,
});

export const deleteClientRemoteContractor = payload => ({
    type: DELETE_CLIENT_REMOTE_CONTRACTOR_REQUEST,
    payload,
});

export const updateClientRemoteContractorStore = payload => ({
    type: CLIENT_REMOTE_CONTRACTORS_UPDATE_STORE,
    payload,
});

const clientRemoteContractorsSelector = ({clientRemoteContractors}) => clientRemoteContractors;

export const clientRemoteContractorsListProgressSelector = createSelector(clientRemoteContractorsSelector, ({listProgress}) => listProgress);
export const clientRemoteContractorsListSelector = createSelector(clientRemoteContractorsSelector, ({list}) => list);
export const clientRemoteContractorsListTotalPagesSelector = createSelector(clientRemoteContractorsSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));
export const clientRemoteContractorsListTotalCountSelector = createSelector(clientRemoteContractorsSelector, ({totalCount}) => totalCount);
export const clientRemoteContractorsProgressActionSelector = createSelector(clientRemoteContractorsSelector, ({progressAction}) => progressAction);

function* getClientRemoteContractorsPageSaga({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.post(`${controller}/getRichPage`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_REMOTE_CONTRACTORS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {contractors} = result;

        if (contractors?.length) {
            const contractorIds = contractors.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: GET_CLIENT_REMOTE_CONTRACTORS_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: GET_CLIENT_REMOTE_CONTRACTORS_ERROR,
        });
    }
}

//POST
// /api/contractors/clientremote/deletedById
function* deleteClientRemoteContractorSaga({payload}) {
    try {
        const {
            id,
            getResult,
        } = payload;

        const result = yield request.post(`${controller}/deletedById?id=${id}`);

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CLIENT_REMOTE_CONTRACTOR_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: DELETE_CLIENT_REMOTE_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CLIENT_REMOTE_CONTRACTOR_ERROR,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_CLIENT_REMOTE_CONTRACTORS_REQUEST, getClientRemoteContractorsPageSaga),
        takeEvery(DELETE_CLIENT_REMOTE_CONTRACTOR_REQUEST, deleteClientRemoteContractorSaga),
    ]);
}