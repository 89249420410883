import {useSelector} from "react-redux";

import {
    getCivilDepositValuesV2Selector,
    getDepositValuesV2Selector,
} from "../../../ducks/deposit";

export const useDepositValuesData = ({isCivil}) => {
    const depositValues = useSelector(getDepositValuesV2Selector);
    const civilDepositValues = useSelector(getCivilDepositValuesV2Selector);

    if (isCivil) {
        return {
            depositValues: civilDepositValues,
        };
    }

    return {
        depositValues,
    };
};