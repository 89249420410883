import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_PATENT_PAYMENT_DECLINE_ERROR,
    BFF_PATENT_PAYMENT_DECLINE_REQUEST,
    BFF_PATENT_PAYMENT_DECLINE_SUCCESS,
    BFF_PATENT_PAYMENT_REFRESH_ERROR,
    BFF_PATENT_PAYMENT_REFRESH_REQUEST,
    BFF_PATENT_PAYMENT_REFRESH_SUCCESS,
    BFF_PATENT_PAYMENTS_GET_PAGE_ERROR,
    BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST,
    BFF_PATENT_PAYMENTS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";

const controller = "/adm/patent";

//POST /bff/adm/patent/page
const getPagePatentPayments = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_PATENT_PAYMENTS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_PATENT_PAYMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PATENT_PAYMENTS_GET_PAGE_ERROR,
        });
    }
};

//POST /bff/adm/patent/refresh-payment
const refreshPatentPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/refresh-payment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_PATENT_PAYMENT_REFRESH_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_PATENT_PAYMENT_REFRESH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PATENT_PAYMENT_REFRESH_ERROR,
        });
    }
};

//POST /bff/adm/patent/decline-payment
const declinePatentPayment = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/decline-payment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_PATENT_PAYMENT_DECLINE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_PATENT_PAYMENT_DECLINE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PATENT_PAYMENT_DECLINE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_PATENT_PAYMENTS_GET_PAGE_REQUEST, getPagePatentPayments),
        takeEvery(BFF_PATENT_PAYMENT_REFRESH_REQUEST, refreshPatentPayment),
        takeEvery(BFF_PATENT_PAYMENT_DECLINE_REQUEST, declinePatentPayment),
    ]);
}