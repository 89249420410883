import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../components/ApplyButtons";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmCheck from "../../../components/NmCheck";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import ClientListNew from "../../client-list/components/new";

import formatDate, {formatLocalDate} from "../../../utils/dateFormat";
import {phoneFormat, removePhoneMask} from "../../../utils/stringFormat";
import {toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENTS_APPLICATION_STATUS, CLIENTS_APPLICATION_STATUS_OPTIONS} from "../../../constants/clientsApplication";
import {formatFileDict, UPLOAD_TYPE} from "../../../constants/financeExport";
import {JoiningRequests} from "../../../constants/headersTable";

import {
    bitrixLeadsSelector,
    clientApplicationUpdateStore,
    clientsApplicationGetPage,
    clientsApplicationPageProgressSelector,
    clientsApplicationPageSelector,
    clientsApplicationTotalCountSelector,
    clientsApplicationTotalPagesSelector,
    declineClientApplication,
    getBitrixLeadById,
    getClientApplicationStatusesByIds,
} from "../../../ducks/clientsApplication";
import {addFinanceExportTask} from "../../../ducks/financeExport";
import {
    getOrderCategoriesAll,
    orderCategoriesAllSelector,
} from "../../../ducks/orderCategories";
import {
    getSalesManagers,
    strapiSalesManagersSelector,
} from "../../../ducks/strapi";

import "./style.sass";

class SettingsJoiningRequests extends Component {
    constructor() {
        super();

        this.state = {
            pageNum: 1,
            pageSize: 25,
            filter: {},
            declineModalData: {},
        };

        this.pageSizes = [25, 50, 100];
    }

    componentDidMount() {
        const {
            getOrderCategoriesAll,
            getSalesManagers,
        } = this.props;

        getOrderCategoriesAll();
        getSalesManagers();
        this.fetchList();
        document.addEventListener("keypress", this.handleKeyPressFilter);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            list,
            getBitrixLeadById,
        } = this.props;

        if (!isEmpty(list) && !isEqual(list, prevProps.list)) {
            const ids = list.map(({applicationId}) => applicationId);

            this.fetchClientApplicationStatuses({ids});

            const leadIds = list.map(({bitrixLeadId}) => bitrixLeadId).filter(item => Boolean(item));

            leadIds.forEach(item => {
                getBitrixLeadById({leadId: item});
            });
        }
    }

    componentWillUnmount() {
        const {clientApplicationUpdateStore} = this.props;

        document.removeEventListener("keypress", this.handleKeyPressFilter);

        clientApplicationUpdateStore({
            list: [],
            clientApplicationStatuses: [],
            bitrixLeads: [],
        });
    }

    handleKeyPressFilter = event => {
        if (event.keyCode === 13) {

            this.submitFilter();
        }
    };

    fetchClientApplicationStatuses = ({ids}) => {
        const {getClientApplicationStatusesByIds} = this.props;

        getClientApplicationStatusesByIds({ids});
    };

    fetchList = () => {
        const {clientsApplicationGetPage} = this.props;

        const {
            pageNum,
            pageSize,
            filter,
        } = this.state;

        clientsApplicationGetPage({
            pageNum,
            pageSize,
            ...filter,
            representativePhoneFilter: filter.representativePhoneFilter ? removePhoneMask(filter.representativePhoneFilter) : undefined,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    renderMarker = ({sendEmail}) => {
        return (
            <div className="flex flex-content-center">
                {sendEmail ?
                    <i className="material-icons joining-requests__icon joining-requests__icon_green">
                        done
                    </i> :
                    <i className="material-icons joining-requests__icon joining-requests__icon_red">
                        close
                    </i>
                }
            </div>
        );
    };

    mapTableData = () => {
        const {list} = this.props;

        const rows = list.map(value => {
            return {
                ...value,
                phone: phoneFormat(value.phone),
                date: formatDate(value.date),
                sendEmail: this.renderMarker(value),
            };
        });

        return {
            headers: JoiningRequests,
            rows,
        };
    };

    getMediaControls(item) {
        const {
            applicationId,
            status,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            size: "xl",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "green",
                        icon: <AddIcon />,
                        children: "Создать компанию",
                        onClick: () => this.onOpenNewClient(item),
                    },
                    visible: [CLIENTS_APPLICATION_STATUS.INIT.value, CLIENTS_APPLICATION_STATUS.PROCESSING.value].includes(status),
                },
                {
                    component: COMPONENT.BUTTON_WITH_TOOLTIP,
                    props: {
                        color: "grey",
                        children: "Отклонить",
                        onClick: () => {
                            this.setState(prevState => ({
                                ...prevState,
                                declineModalData: {
                                    applicationId,
                                    isOpen: true,
                                },
                            }));
                        },
                    },
                    visible: [CLIENTS_APPLICATION_STATUS.INIT.value, CLIENTS_APPLICATION_STATUS.PROCESSING.value].includes(status),
                },
            ],
        };
    }

    getRows() {
        const {
            list,
        } = this.props;

        return list.map(item => {
            const {
                createdAt,
                status,
                name,
                fullName,
                representativeName,
                representativeEmail,
                representativePhone,
                registeredAddress,
                sendEmail,
                createLead,
                declinedReason,
                inn,
                managerName,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        alignItems="flex-end"
                        secondaryHeaderStatus={
                            <NmBadge
                                noWrap
                                mod={CLIENTS_APPLICATION_STATUS[status]?.mod}
                                text={CLIENTS_APPLICATION_STATUS[status]?.text}
                                description={declinedReason}
                                tooltipType="light"
                                tooltipPosition="bottom-left"
                                classNameTooltip="joining-requests__tooltip"
                                tooltipHover
                            />
                        }
                        secondaryHeader={`Заявка от ${formatLocalDate(createdAt, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={name}
                        primarySubHeader={fullName}
                        labels={[
                            {label: "ФИО", text: representativeName},
                            {label: "Телефон", text: phoneFormat(representativePhone)},
                            {label: "E-mail", text: representativeEmail},
                            {label: "Юридический адрес", text: registeredAddress},
                            {label: "ИНН", text: inn},
                            {
                                text:
                                    <div className="flex">
                                        <NmCheck
                                            label="E-mail отправлен"
                                            isCheck={sendEmail}
                                        />
                                        <NmCheck
                                            blockClassName="ms-2"
                                            label="Лид создан"
                                            isCheck={createLead}
                                        />
                                    </div>,
                            },
                            managerName && {label: "Ответственный", text: managerName},
                        ]}
                        classNameMainContent="col-16 col-xxl-6"
                        mediaControls={this.getMediaControls(item)}
                        isFixedActions
                    />
                ),
            };
        });
    };

    handleChange = (e, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [name]: value,
            },
        }));
    };

    submitFilter = () => {
        const {progressList} = this.props;

        if (progressList) {
            return;
        }

        this.setState({
            pageNum: 1,
        }, this.fetchList);
    };

    clearFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageNum: 1,
            filter: {},
        }), () => {
            this.fetchList();
        });
    };

    handleChangeDeclinedReason = (e, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            declineModalData: {
                ...prevState.declineModalData,
                [name]: value,
            },
        }));
    };

    closeDeclineModal = () => {
        this.setState({
            declineModalData: {},
        });
    };

    renderFilter() {
        const {
            filter: {
                nameFilter,
                representativeNameFilter,
                representativePhoneFilter,
                innFilter,
                statusesFilter,
            },
        } = this.state;

        return (
            <NmForm
                className="client-deposit-history__filter"
                addMargin
            >
                <NmInputV2
                    size="lg"
                    label="Название организации"
                    name="nameFilter"
                    onChange={this.handleChange}
                    value={nameFilter || ""}
                />
                <NmInputV2
                    size="lg"
                    label="ФИО представителя"
                    name="representativeNameFilter"
                    onChange={this.handleChange}
                    value={representativeNameFilter || ""}
                />
                <NmInputV2
                    size="lg"
                    label="Телефон представителя"
                    mask="+7 999 999 99 99"
                    name="representativePhoneFilter"
                    onChange={this.handleChange}
                    value={representativePhoneFilter || ""}
                />
                <NmInputV2
                    size="lg"
                    label="ИНН"
                    maskChar={null}
                    mask="999999999999"
                    name="innFilter"
                    onChange={this.handleChange}
                    value={innFilter || ""}
                />
                <NmDropdownV2
                    placeholder="Все"
                    label="Статус заявки"
                    name="statusesFilter"
                    onChange={this.handleChange}
                    value={statusesFilter}
                    options={CLIENTS_APPLICATION_STATUS_OPTIONS}
                    size="lg"
                    multiple
                />
                <FilterButtonsV2
                    onSearch={this.submitFilter}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    declineApplication = () => {
        const {declineClientApplication} = this.props;
        const {
            declineModalData: {
                applicationId,
                declinedReason,
            },
        } = this.state;

        declineClientApplication({
            applicationId,
            declinedReason,
            onSuccess: () => {
                this.fetchList();
                this.closeDeclineModal();
            },
        });
    };

    exportClientsApplication = () => {
        const {addFinanceExportTask} = this.props;

        addFinanceExportTask({
            uploadType: UPLOAD_TYPE.PUBLIC_CLIENT_APPLICATION_EXPORT.value,
            formatType: formatFileDict.XLSX,
            getResult: (errorMessage) => {
                if (!errorMessage) {
                    toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт отчетов\"");
                }
            },
        });
    };

    renderDeclineModal() {
        const {
            declineModalData: {
                isOpen,
                declinedReason,
            },
        } = this.state;

        return (
            isOpen &&
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        Отклонить
                    </NmTitle>
                }
                onClose={this.closeDeclineModal}
                footer={
                    <ApplyButtons
                        submit={this.declineApplication}
                        submitBtnContent="Продолжить"
                        onClose={this.closeDeclineModal}
                        cancelBtnContent="Отменить"
                    />
                }
            >
                <NmForm>
                    <NmTextareaV2
                        label="Укажите причину"
                        placeholder="Введите причину"
                        minRows={2}
                        maxRows={6}
                        maxLength="100"
                        name="declinedReason"
                        onClose={this.closeDeclineModal}
                        value={declinedReason}
                        onChange={this.handleChangeDeclinedReason}
                    />
                </NmForm>
            </NmModal>
        );
    }

    getHeaderMediaControls() {
        const {list} = this.props;

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            size: "xl",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        children: "Выгрузить",
                        onClick: this.exportClientsApplication,
                    },
                    visible: Boolean(list.length),
                },
            ],
        };
    }

    onOpenNewClient = (currentApplication) => {
        this.setState({
            isOpenNewClient: true,
            currentApplication,
        });
    };

    onCloseNewClient = () => {
        this.setState({
            isOpenNewClient: false,
            currentApplication: {},
        });
    };

    renderClientNew() {
        const {isOpenNewClient, currentApplication} = this.state;

        if (!isOpenNewClient) {
            return null;
        }

        return (
            <ClientListNew
                onClose={this.onCloseNewClient}
                currentApplication={currentApplication}
                fetchList={this.fetchList}
            />
        );
    }

    render() {
        const {
            list,
            progressList,
            totalPages,
            totalCount,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        return (
            <NmPage
                header={
                    <NmTitle size="xl">
                        Заявки на подключение
                    </NmTitle>
                }
                noPadding
                typeFilter="vertical"
                mediaControls={this.getHeaderMediaControls()}
                filtersBase={this.renderFilter()}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalCount={totalCount}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                isLoaded={progressList}
            >
                {this.renderClientNew()}
                {this.renderDeclineModal()}
                {
                    list.length ?
                        <CheckboxList rows={this.getRows()} /> :
                        <NmEmptyPageV2 fetchProgress={progressList} />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: clientsApplicationPageSelector(state),
        totalPages: clientsApplicationTotalPagesSelector(state),
        progressList: clientsApplicationPageProgressSelector(state),
        totalCount: clientsApplicationTotalCountSelector(state),
        categoryOptions: orderCategoriesAllSelector(state),
    }),
    {
        clientsApplicationGetPage,
        getOrderCategoriesAll,
        declineClientApplication,
        clientApplicationUpdateStore,
        getClientApplicationStatusesByIds,
        addFinanceExportTask,
        getBitrixLeadById,
        getSalesManagers,
    },
)(withTranslation()(SettingsJoiningRequests));