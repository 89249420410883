import {useCallback, useState} from "react";
import {useDispatch} from "react-redux";

import {REJECTION_SIGN_STATEMENT_RULE} from "../loading-form/validation-rule/validationRule";

import {DataChange} from "../../filter/hooks/useStatementFilter";

import {handleFormString} from "../../../../../utils/stringHelper";
import {toastError} from "../../../../../utils/toastHelper";
import validate from "../../../../../utils/validate";

import {rejectStatement} from "../../../../../ducks/edmStatements";

export type RejectFormType = {
    rejectionReason: string
}
export type RejectErrorReasonType = {
    rejectionReason?: string
}
export type useRejectSigningReturnStatement = {
    rejectForm: RejectFormType,
    setErrorRejectForm: (errorForm: RejectErrorReasonType) => void,
    errorRejectForm: RejectErrorReasonType,
    onChangeRejectForm: (event: any, data: DataChange) => void,
    submitRejectForm: () => void,
    onCloseRejectForm: () => void,
    openRejectForm: (selectedStatementList: string[]) => void,
    isVisibleRejectForm: boolean,
};

export function useRejectSigningStatement(fetchList: () => void, clearSelectedRows: () => void): useRejectSigningReturnStatement {
    const [rejectForm, setRejectForm] = useState<RejectFormType>({
        rejectionReason: "",
    });
    const [isVisibleRejectForm, setVisibleRejectForm] = useState<boolean>(false);

    const [selectedStatementList, setSelectedStatementList] = useState<string[]>([]);

    const [errorRejectForm, setErrorRejectForm] = useState<RejectErrorReasonType>({});

    const dispatch = useDispatch();

    const onChangeRejectForm = (event: any, {name, value}: DataChange) => {
        setRejectForm({
            ...rejectForm,
            [name]: value,
        });
    };

    const validateForm = () => {
        const _errorForm = validate(rejectForm, REJECTION_SIGN_STATEMENT_RULE, "");
        setErrorRejectForm({..._errorForm});

        return Object.values(_errorForm).length === 0;
    };

    const submitRejectForm = () => {
        if (validateForm()) {
            reject();
        }
    };

    const openRejectForm = useCallback((selectedStatementList: string[]) => {
        setSelectedStatementList(selectedStatementList);
        setVisibleRejectForm(true);
    }, []);

    const onCloseRejectForm = () => {
        setVisibleRejectForm(false);
        setRejectForm({rejectionReason: ""});
        setErrorRejectForm({
            rejectionReason: "",
        });
        setSelectedStatementList([]);
        clearSelectedRows();
    };

    const reject = () => {
        const data = {
            statementIds: selectedStatementList,
            rejectReason: handleFormString(rejectForm.rejectionReason),
        };

        dispatch(rejectStatement({
            data,
            getResult: (errorMessage: string) => {
                if (errorMessage) {
                    toastError(errorMessage);
                } else {
                    fetchList();
                    onCloseRejectForm();
                }
            },
        }));
    };

    return {
        rejectForm,
        onCloseRejectForm,
        setErrorRejectForm,
        errorRejectForm,
        onChangeRejectForm,
        submitRejectForm,
        isVisibleRejectForm,
        openRejectForm,
    };
}