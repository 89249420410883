import {
    BFF_BANK_W1_HISTORY_GET_PAGE_ERROR,
    BFF_BANK_W1_HISTORY_GET_PAGE_REQUEST,
    BFF_BANK_W1_HISTORY_GET_PAGE_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    data: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_BANK_W1_HISTORY_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    }
    case BFF_BANK_W1_HISTORY_GET_PAGE_SUCCESS: {
        const {
            history,
            totalCount,
            ...otherData
        } = payload;

        return {
            ...state,
            list: history,
            totalCount: totalCount,
            data: otherData,
            progress: false,
        };
    }
    case BFF_BANK_W1_HISTORY_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    default:
        return state;
    }
};