export const RUSSIA_PHONE_CODE_ID = "458597d2-8a9e-4959-9548-cd1281c51e46";

export const LENGTH_FOREIGN_PHONE = {
    MIN: 10,
    MAX: 15,
};

export const PHONE_MASK = {
    RU: "+7 (999) 999 99 99",
    FOREIGN: "+999999999999999",
};

export const PHONE_CODE_TYPE = {
    FLASH_CALL: "FLASH_CALL",
    SMS: "SMS",
};