import React, {FC} from "react";

import {ReactComponent as StarIcon} from "../../../images/star_24.svg";
import NmAdvancedTooltip from "../NmAdvancedTooltip";

import {COLOR} from "../../../constants/color";

interface UkepIndicatorProps {
    className?: string,
}

export const UkepIndicator: FC<UkepIndicatorProps> = (props) => {
    const {
        className,
    } = props;

    return (
        <NmAdvancedTooltip
            className={`${className} d-flex`}
            type="light"
            children="Может подписывать документы УКЭП компании"
            hover
            position="bottom-left"
            trigger={
                <StarIcon
                    color={COLOR.INERT_100}
                    width={18}
                    height={18}
                />
            }
        />
    );
};