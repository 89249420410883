import React, {useCallback} from "react";
import Dropzone from "react-dropzone";

import {toastError} from "../../utils/toastHelper";

import PropTypes from "prop-types";

import "./style.sass";

const DefaultDropzone = props => {
    const {
        onDropFiles,
        children,
        maxSize,
        accept,
        className,
        disabled,
        multiple,
        maxFiles,
        files = [],
    } = props;

    const onDrop = useCallback(acceptedFiles => {
        if (!multiple) {
            onDropFiles(acceptedFiles);

            return;
        }

        const _files = [
            ...files,
            ...acceptedFiles,
        ];

        if (maxFiles && _files.length > maxFiles) {
            toastError(`Максимальное возможное количество файлов для загрузки - ${maxFiles}`);
            onDropFiles(_files.slice(0, maxFiles));

            return;
        }

        onDropFiles(_files);
    }, [files]);

    return (
        <Dropzone
            multiple={multiple}
            className={className}
            onDrop={onDrop}
            maxSize={maxSize}
            accept={accept}
        >
            {({getRootProps, getInputProps})=> (
                <div {...getRootProps({})}>
                    <input
                        {...getInputProps({})}
                        disabled={disabled}
                    />
                    {children}
                </div>
            )}
        </Dropzone>
    );
};

DefaultDropzone.propTypes = {
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    maxFiles: PropTypes.number,
    accept: PropTypes.string,
    onDropFiles: PropTypes.func,
};

export default DefaultDropzone;