import React from "react";

import {ReactComponent as AvitoIcon} from "../../images/avito-logo.svg";
import {ReactComponent as HeadHunterIcon} from "../../images/head-hunter-logo.svg";
import {ReactComponent as NaimixIcon} from "../../images/nm-logo-rounded.svg";
import {ReactComponent as SuperJobIcon} from "../../images/super-job-logo.svg";
import {ReactComponent as ZarplataIcon} from "../../images/zarplata-logo.svg";
import DictIcon, {TIconDict} from "../ActualComponents/DictIcon";

import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../containers/recruitment/vacancies/constants";

const iconDict: TIconDict = {
    [RECRUITMENT_SOURCE_JOB_BOARD.AVITO]: AvitoIcon,
    [RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER]: HeadHunterIcon,
    [RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB]: SuperJobIcon,
    [RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX]: NaimixIcon,
    [RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU]: ZarplataIcon,
};

interface IJobBoardLogo extends React.SVGProps<SVGSVGElement> {
    name: string,
}

const JobBoardLogo = (props: IJobBoardLogo) => {
    return (
        <DictIcon
            dict={iconDict}
            {...props}
        />
    );
};

export default JobBoardLogo;