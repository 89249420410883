import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {filterEmptyValues} from "../../../../../utils/objectHelper";

import {isClientUser} from "../../../../../constants/roles";

import {
    addSettingsKedoSubdivision,
    deleteSettingsKedoSubdivision,
    getSettingsKedoSubdivisionsPage,
    updateSettingsKedoSubdivision,
} from "../../../../../ducks/bff/adm/settings/directories/kedo/subdivisions/services";
import {
    addClientsKedoSubdivision,
    deleteClientsKedoSubdivision,
    getClientsKedoSubdivisionsPage,
    updateClientsKedoSubdivision,
} from "../../../../../ducks/bff/clients/kedo/directories/services";

export const useKedoSubdivisionsBff = (params) => {
    const {
        clientId,
        fetchCounts,
        sortType,
    } = params;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const dispatch = useDispatch();

    const deleteSubdivision = (data) => {
        const requestData = {
            clientId: clientId ? clientId : data.clientId,
            SubdivisionId: data.id,
            name: data.value,
            onSuccess: () => {
                fetchCounts();

                data.callBack();
            },
        };

        if (isClientRole) {
            dispatch(deleteClientsKedoSubdivision(requestData));

            return;
        }

        dispatch(deleteSettingsKedoSubdivision(requestData));
    };

    const addSubdivision = (data) => {
        const requestData = {
            clientId: clientId ? clientId : data.clientId,
            name: data.value,
            onSuccess: () => {
                fetchCounts();

                data.callBack();
            },
        };

        if (isClientRole) {
            dispatch(addClientsKedoSubdivision(requestData));

            return;
        }

        dispatch(addSettingsKedoSubdivision(requestData));

    };

    const fetchSubdivisionsPage = (data) => {
        const {
            valueFilter: nameFilter,
            ...otherData
        } = data;

        const requestData = filterEmptyValues({
            clientIdFilter: clientId,
            nameFilter,
            orderType: sortType,
            ...otherData,
            onSuccess: () => {
                data.callBack();
            },
        });

        if (isClientRole) {
            dispatch(getClientsKedoSubdivisionsPage(requestData));

            return;
        }

        dispatch(getSettingsKedoSubdivisionsPage(requestData));
    };

    const updateSubdivision = (data) => {
        const requestData = {
            clientId,
            subdivisionId: data.id,
            name: data.value,
            onSuccess: () => {
                data.callBack();
            },
        };

        if (isClientRole) {
            dispatch(updateClientsKedoSubdivision(requestData));

            return;
        }

        dispatch(updateSettingsKedoSubdivision(requestData));
    };

    return {
        deleteSubdivision,
        addSubdivision,
        fetchSubdivisionsPage,
        updateSubdivision,
    };
};