import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearRecruitmentAccessControlRecruitersGroupsCardStore,
    getPageRecruitmentAccessControlRecruitersGroupsCard,
} from "../../../../../../ducks/bff/recruitment/access-control/recruiters-groups/actionsCreators";

export const useRecruitmentAccessControlRecruitersGroupsCardFetchList = (props) => {
    const {
        groupId,
        pageNum,
        pageSize,
        filterData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentAccessControlRecruitersGroupsCardStore());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const fetchList = () => {
        dispatch(getPageRecruitmentAccessControlRecruitersGroupsCard({
            id: groupId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};