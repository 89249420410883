import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {isNullOrWhitespace} from "../../utils/stringHelper";
import {toastError} from "../../utils/toastHelper";

import {TYPE_REASON_FOR_REFUSAL} from "../../constants/contractor";

import PropTypes from "prop-types";

import "./style.sass";

class RejectionReasonPopup extends Component {
    static propTypes = {
        close: PropTypes.func,
        submit: PropTypes.func,
    };

    state = {
        text: "",
        isOpenTextArea: false,
        radioValue: TYPE_REASON_FOR_REFUSAL.NO_REASON.VALUE,
    };

    onChangeRadio(radioValue) {
        this.setState({radioValue});
    }

    handleSubmit = () => {
        const {
            submit,
        } = this.props;

        const {radioValue} = this.state;

        if (isNullOrWhitespace(radioValue)) {
            toastError("Укажите причину отказа");
            return;
        }

        const data = {
            reasonForRefusal: TYPE_REASON_FOR_REFUSAL[radioValue].TEXT,
            refusalNotificationMessage: TYPE_REASON_FOR_REFUSAL[radioValue].NOTIFICATION_TEXT,
        };

        submit(data);
    };

    render() {
        const {t, close} = this.props;

        const {radioValue} = this.state;

        return (
            <NmModal
                size="md"
                footer={
                    <ApplyButtons
                        submitBtnContent={t("button.confirm")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={close}
                        submit={this.handleSubmit}
                    />
                }
                header={
                    <NmTitle
                        align="center"
                        size="lg"
                    >
                        Укажите причину отказа
                    </NmTitle>
                }
                onClose={close}
            >
                <NmForm>
                    {
                        Object.values(TYPE_REASON_FOR_REFUSAL).map(({VALUE, TEXT}, index) => {
                            return (
                                <NmRadioV2
                                    key={index}
                                    className="rejection-reason-popup__radio"
                                    checked={radioValue === VALUE}
                                    label={TEXT}
                                    onChange={() => this.onChangeRadio(VALUE)}
                                />
                            );
                        })
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default withTranslation()(RejectionReasonPopup);