import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import MapObjectsListModal from "../../components/MapObjectsListModal";
import NmPage from "../../components/NmPage";
import MapFilter from "./components/filter";
import MapInviteModal from "./components/invite-modal";
import MapComponent from "./components/map-component";
import MapObjectsList from "./components/objects-list";
import MapToolbar from "./components/toolbar";

import {useModal} from "../../hooks/useModal";
import {useMapFetch} from "./hooks/useFetch";
import {useMapFilter} from "./hooks/useFilter";
import {useMapPage} from "./hooks/usePage";

import bem from "../../utils/bem";

import {
    bffMapClustersSelector,
    bffMapProgressSelector,
} from "../../ducks/bff/map/selectors";

import "./style.sass";

const Map = () => {
    const [block, element] = bem("map");

    const [openFilter, setOpenFilter] = useState(false);

    const {
        showMap,
        isShowModalInfo,
        confirmData,
        onMarkerClick,
        onOpenModal,
        mediaControls,
        clearFilter,
        filterData,
        setFilterData,
        dataCoordinates,
        onCloseModal,
        setConfirmData,
        modalInfoData,
    } = useMapPage({});

    const {
        isSearch,
        onSubmitFilter,
        fetchMarkers,
    } = useMapFetch({
        setFilterData,
        clearFilter,
        showMap,
    });

    const {
        onChangeFilter,
        onClearFilter,
        filter,
    } = useMapFilter({
        onSubmitFilter,
    });

    const {
        isOpen: isOpenInviteModal,
        modalData: inviteData,
        onOpenModal: onOpenInviteModal,
        onCloseModal: onCloseInviteModal,
    } = useModal();

    const progressMarkers = useSelector(bffMapProgressSelector);
    const mapClusters = useSelector(bffMapClustersSelector);

    const toggleFilter = () => {
        setOpenFilter(prevState => !prevState);
    };

    const renderContent = () => {
        if (showMap) {
            return (
                <MapComponent
                    modalInfoData={modalInfoData}
                    isSearch={isSearch}
                    filterData={filterData}
                    fetchMarkers={fetchMarkers}
                    mapClusters={mapClusters}
                    onMarkerClick={onMarkerClick}
                    onOpenModal={onOpenModal}
                    onChangeFilter={onChangeFilter}
                />
            );
        }

        return (
            <MapObjectsList
                filter={filterData}
                onChangeFilter={onChangeFilter}
                isSearch={isSearch}
                onOpenInviteModal={onOpenInviteModal}
            />
        );
    };

    const renderModalInfo = () => {
        if (!isShowModalInfo) {
            return null;
        }

        const {
            contractorCoordinatesFilter,
            orderCoordinatesFilter,
            advertCoordinatesFilter,
            taskCoordinatesFilter,
        } = dataCoordinates;

        return (
            <MapObjectsListModal
                isOpen={isShowModalInfo}
                onClose={onCloseModal}
                contractorCoordinatesFilter={contractorCoordinatesFilter}
                orderCoordinatesFilter={orderCoordinatesFilter}
                advertCoordinatesFilter={advertCoordinatesFilter}
                taskCoordinatesFilter={taskCoordinatesFilter}
                onOpenInviteModal={onOpenInviteModal}
            />
        );
    };

    const renderConfirmModal = () => {
        if (isEmpty(confirmData)) {
            return null;
        }

        const {
            content,
            confirmButton,
            cancelButton,
            submit,
        } = confirmData;

        return (
            <NmConfirmV2
                content={content}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
                onConfirm={submit}
                onCancel={() => {
                    setConfirmData({});
                }}
            />
        );
    };

    const renderInviteModal = () => {
        return (
            isOpenInviteModal &&
            <MapInviteModal
                data={inviteData}
                onClose={onCloseInviteModal}
            />
        );
    };

    return (
        <NmPage
            className={block()}
            headerClassName={element("header", {listPage: !showMap})}
            openFilter={openFilter}
            controlsClassName={element("controls")}
            isVisibleFilterButton={false}
            typeFilter="vertical"
            onOpenFilter={toggleFilter}
            filtersBase={
                <MapFilter
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                    onClearFilter={onClearFilter}
                    onSubmitFilter={onSubmitFilter}
                />
            }
        >
            <MapToolbar
                filter={filter}
                onChangeFilter={onChangeFilter}
                onSearch={onSubmitFilter}
                onSubmitFilter={onSubmitFilter}
                mediaControls={mediaControls}
                showMap={showMap}
                toggleFilter={toggleFilter}
                progressMarkers={progressMarkers}
            />
            {renderContent()}
            {renderModalInfo()}
            {renderConfirmModal()}
            {renderInviteModal()}
        </NmPage>
    );
};

export default Map;