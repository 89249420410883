import React, {FC} from "react";

import {ReactComponent as NoOnIcon} from "../../../../images/no_on.svg";
import {ReactComponent as NotOnIcon} from "../../../../images/not_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../images/yes_on.svg";
import {RESULT_STATUS} from "../../index";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";

import "./style.sass";

interface IPaymentPossibleIcon {
    result?: RESULT_STATUS,
    size?: "md" | "lg",
    className?: string
}

const BankIndicatorIcon: FC<IPaymentPossibleIcon> = ({result, className = "", size} ) => {
    const [block] = bem("bank-indicator-icon", className);

    if (!result) {
        return null;
    }

    switch (result) {
    case RESULT_STATUS.CHECK_PASSED:
        return (
            <YesOnIcon
                color={COLOR.PRIMARY_100}
                className={block({size})}
            />
        );
    case RESULT_STATUS.CHECK_FAILED:
        return (
            <NoOnIcon
                color={COLOR.NEGATIVE_100}
                className={block({size})}
            />
        );
    case RESULT_STATUS.CHECK_IN_PROGRESS:
        return (
            <NotOnIcon
                color={COLOR.WHITE}
                className={block({size})}
            />
        );
    case RESULT_STATUS.CHECK_NOT_PERFORMED:
        return (
            <NoOnIcon
                color={COLOR.SECONDARY_10}
                className={block({size})}
            />
        );
    default:
        return (
            <NoOnIcon
                color={COLOR.NEGATIVE_100}
                className={block({size})}
            />
        );
    }
};

export default BankIndicatorIcon;