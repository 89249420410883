import React from "react";
import {Route, Switch} from "react-router";

import {ContractorFinanceIncomeNdflAwaiting} from "./awaiting";
import {ContractorFinanceIncomeNdflCompleted} from "./completed";
import {ContractorFinanceIncomeNdflInProgress} from "./in-progress";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST,
} from "../../../../constants/links";

export const ContractorFinanceIncomeNdflRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST}
                component={ContractorFinanceIncomeNdflCompleted}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST}
                component={ContractorFinanceIncomeNdflInProgress}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST}
                component={ContractorFinanceIncomeNdflAwaiting}
            />
        </Switch>
    );
};