import React, {FC, SyntheticEvent, useCallback, useMemo, useState} from "react";
import {isEmpty} from "lodash";

import {ChevronIcon} from "../ChevronIcon";
import ErrorTooltip from "../ErrorTooltip";
import NmLabel, {INmLabel} from "../NmLabel";
import Text from "../Text";
import {DropdownWithInputOptionsContainer} from "./OptionsContainer";
import {DropdownWithInputSelectedContainer} from "./SelectedContainer";
import {DropdownWithInputTextField} from "./TextField";

import {useClickOutside} from "../../../hooks/useClickOutside";
import {useDebounceFetch} from "../../../hooks/useDebounceFetch";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";
import {toastError} from  "../../../utils/toastHelper";

import {DropdownWithInputDeleteParams, DropdownWithInputParamsType, DropdownWithInputSizeType, DropdownWithInputValueType} from "./types";

import "./style.sass";

interface IDropdownWithInput {
    size: DropdownWithInputSizeType,
    name?: string,
    values?: Array<DropdownWithInputValueType>,
    onChange: <T>(event: T, params: DropdownWithInputParamsType) => void,
    options?: Array<OptionType>,
    labelProps?: INmLabel,
    isShowChevron?: boolean,
    error?: string,
    disabled?: boolean,
    fetchCallback?: (value: string) => void,
    placeholder?: string,
    minCharacters?: number,
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
    onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void,
    tooltip?: React.ReactNode,
    isVisibleTooltip?: boolean,
    label?: string,
    required?: boolean,
    maxLength?: number,
    match?: RegExp,
    maxCharacters?: number,
    isDetectEqualValues?: boolean,
    minSearchLength?: number,
    isClearInputValueOnBlur?: boolean,
    className?: string,
}

const blockClassName = "dropdown-with-input";

const DropdownWithInput: FC<IDropdownWithInput> = (props) => {
    const {
        name,
        values,
        label,
        required,
        tooltip,
        isVisibleTooltip,
        isShowChevron = true,
        error,
        fetchCallback,
        placeholder,
        size,
        disabled,
        minCharacters,
        onFocus,
        onMouseEnter,
        maxLength,
        match,
        maxCharacters,
        isDetectEqualValues,
        minSearchLength,
        isClearInputValueOnBlur,
        className,
    } = props;

    const {
        setValueFilter: setValue,
        valueFilter: inputValue,
    } = useDebounceFetch({
        fetchCallback,
        minSearchLength,
    });

    const [block, element] = bem(blockClassName, className);
    const [open, setOpen] = useState(false);

    const ref = useClickOutside<HTMLDivElement>((event: SyntheticEvent) => {
        const target = event.target as Element;

        const isClickedChildElementByBemNames = target.classList.value.includes(blockClassName);

        if (isClickedChildElementByBemNames) {
            return;
        }

        setOpen(false);
    }, open);

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const addValue = useCallback((selected: OptionType) => {
        if (
            typeof selected.text !== "string" ||
            values?.some(item => item.content === selected.text)
        ) {
            return;
        }

        const value = {
            content: selected.text,
            id: selected.key,
        };

        props.onChange(null, {
            name,
            value: values ?
                [...values, value] :
                [value],
        });
    }, [values]);

    const onClickDelete = useCallback((selected: DropdownWithInputDeleteParams) => {
        if (!values) {
            return;
        }

        const index = values.findIndex((item, index) => (index === selected.index));

        const newValues = [...values];

        newValues.splice(index, 1);

        props.onChange(null, {
            name,
            value: newValues,
        });
    }, [values]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") {
            return;
        }

        if (isDetectEqualValues) {
            const isEqual = values?.some(item => item.content === inputValue);

            if (isEqual) {
                setValue("");

                return;
            }
        }

        if (maxCharacters && inputValue.length > maxCharacters) {
            toastError(`Максимальная длина - ${maxCharacters} символа`);

            return;
        }

        if (match && !match.test(inputValue)) {
            toastError("Поле содержит некорректные символы");

            return;
        }

        if (typeof minCharacters === "number" && inputValue.length < minCharacters) {
            toastError(`Минимальное количество - ${minCharacters} символа`);

            return;
        }

        if (typeof maxLength === "number" && values?.length === maxLength) {
            return;
        }

        setValue("");

        props.onChange(event, {
            name,
            value: values ?
                [...values, {content: inputValue}] :
                [{content: inputValue}],
        });
    };

    const onBlur = () => {
        if (isClearInputValueOnBlur) {
            setValue("");
        }
    };

    const getSuggestion = () => {
        if (!open) {
            return null;
        }

        if (
            (
                fetchCallback &&
                options?.length === 0 &&
                inputValue
            ) ||
            (
                !fetchCallback &&
                isEmpty(values)
            )
        ) {
            return (
                <Text
                    className="dropdown-with-input__suggestion"
                    level="3"
                >
                    Нажмите Enter для добавления
                </Text>
            );
        }

        return null;
    };

    const options = useMemo(() => {
        return props.options?.filter(item => {
            const isTextFound = typeof item.text === "string" ? item.text.includes(inputValue) : true;

            if (!values) {
                return isTextFound;
            }

            const isExists = values.some(value => value.content === item.text);

            return !isExists && isTextFound;
        });
    }, [
        values,
        props.options,
        inputValue,
    ]);

    return (
        <div className={block()}>
            {
                label &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                    tooltip={tooltip}
                    isVisibleTooltip={isVisibleTooltip}
                />
            }
            <div
                onMouseEnter={onMouseEnter}
                ref={ref}
                onClick={() => {
                    if (disabled) {
                        return;
                    }

                    setOpen(true);
                }}
                className={element("control", {
                    open,
                    active: Boolean(inputValue) || !isEmpty(values),
                    error: Boolean(error),
                    disabled,
                    size,
                })}
            >
                <DropdownWithInputTextField
                    disabled={disabled}
                    containerSize={size}
                    name={name}
                    onKeyDown={onKeyDown}
                    onChange={onChangeInput}
                    value={inputValue}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    rightContent={
                        isShowChevron &&
                        <ChevronIcon
                            className={element("chevron-icon", {disabled})}
                            rotate={open ? 180 : 0}
                        />
                    }
                />
                {
                    !isEmpty(values) && values &&
                    <DropdownWithInputSelectedContainer
                        disabled={disabled}
                        values={values}
                        onClickDelete={onClickDelete}
                    />
                }
                {
                    open && !isEmpty(options) && options &&
                    <DropdownWithInputOptionsContainer
                        isEmptyValues={isEmpty(values)}
                        addValue={addValue}
                        options={options}
                    />
                }
                {getSuggestion()}
            </div>
            {
                error &&
                <ErrorTooltip
                    error={error}
                />
            }
        </div>
    );
};

export default React.memo(DropdownWithInput);
