import React, {useState} from "react";

import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import Text from "../ActualComponents/Text";
import AmountInput from "../AmountInput";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {useObjectChangeDepositForm} from "./hooks/useForm";

import {OBJECT_CHANGE_DEPOSIT_RADIO_TYPE} from "./constants";

const ObjectChangeDeposit = (props) => {
    const {
        text,
        onClose,
        onSubmit,
        header,
        progress,
        confirmContent,
    } = props;

    const [openConfirm, setOpenConfirm] = useState(false);

    const {
        touched,
        values,
        errors,
        handleSubmit,
        setFieldValue,
    } = useObjectChangeDepositForm({
        onSubmit,
    });

    const renderConfirmModal = () => {
        return (
            <NmConfirmV2
                content={confirmContent}
                onCancel={() => setOpenConfirm(false)}
                onConfirm={handleSubmit}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    return (
        openConfirm ?
            renderConfirmModal() :
            <NmModal
                size="md"
                header={
                    <NmTitle
                        noWrapToLines={true}
                        size="lg"
                    >
                        {header}
                    </NmTitle>
                }
                onClose={onClose}
                footer={
                    <ApplyButtons
                        disabled={progress}
                        mobile="column"
                        onClose={onClose}
                        submit={() => setOpenConfirm(true)}
                        isHiddenCancelOnMobile
                        cancelBtnContent="Отменить"
                        submitBtnContent="Сохранить"
                    />
                }
            >
                <NmForm addMargin>
                    <Text level="3">
                        {text}
                    </Text>
                    <div className="d-flex align-items-center">
                        <NmRadioV2
                            className="me-3"
                            checked={values.type === OBJECT_CHANGE_DEPOSIT_RADIO_TYPE.NPD}
                            label="НПД"
                            onChange={() => {
                                setFieldValue("type", OBJECT_CHANGE_DEPOSIT_RADIO_TYPE.NPD);
                            }}
                        />
                        <NmRadioV2
                            checked={values.type === OBJECT_CHANGE_DEPOSIT_RADIO_TYPE.NDFL}
                            label="НДФЛ"
                            onChange={() => {
                                setFieldValue("type", OBJECT_CHANGE_DEPOSIT_RADIO_TYPE.NDFL);
                            }}
                        />
                    </div>
                    <AmountInput
                        newInput
                        label="Сумма пополнения депозита объекта, ₽ "
                        required
                        size="xl"
                        maxLength={50}
                        placeholder="Введите сумму"
                        value={values.amount}
                        onChange={(event, {value}) => {
                            setFieldValue("amount", value);
                        }}
                        error={touched && touched.amount && touched.amount ?
                            errors && errors.amount && errors.amount :
                            undefined
                        }
                    />
                </NmForm>
            </NmModal>
    );
};

export default ObjectChangeDeposit;