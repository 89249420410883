import {FILTER} from "../index";

export const getClientNamesFilter = (params = {}) => {
    return {
        component: FILTER.CUSTOMER,
        label: "Компания",
        placeholder: "Не выбрано",
        name: "clientIdFilter",
        isClearable: true,
        isBff: true,
        search: true,
        ...params,
    };
};