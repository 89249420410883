import React, {FC} from "react";
import {useTranslation} from "react-i18next";

import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import { SelfEmployedIndicatorIcon } from "./components/icon";

import bem from "../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../utils/dateFormat";
import {getMessage} from "./utils/getMessage";

import {TAX_STATUS_TRANSLATE} from "./constants";

import "./style.sass";

interface ISelfEmployedIndicator {
    taxStatus: keyof typeof TAX_STATUS_TRANSLATE,
    isShowTaxRegistrationDate?: Boolean,
    taxRegistrationDate?: Date | null,
    labelClass?: string,
    className?: string
}

// Индикатор Самозанятый
const SelfEmployedIndicator: FC<ISelfEmployedIndicator> = (props) => {
    const {
        isShowTaxRegistrationDate,
        taxStatus,
        labelClass,
        taxRegistrationDate,
        className,
    } = props;
    const {t} = useTranslation();
    const [block, element] = bem("self-employed-indicator", className);

    return (
        <NmAdvancedTooltip
            position="bottom-left"
            className={block()}
            children={getMessage(taxStatus, t)}
            trigger={
                <div className={element("container")}>
                    <SelfEmployedIndicatorIcon taxStatus={taxStatus} />
                    <div className={`${element("label")} ${labelClass}`}>
Самозанятый
                    </div>
                    {
                        isShowTaxRegistrationDate && taxRegistrationDate &&
                        <div
                            title="Дата последней постановки на учет НПД"
                            className="ms-2"
                        >
                            {`(${formatDate(convertUtcToLocal(taxRegistrationDate), "dd.MM.yyyy")})`}
                        </div>
                    }
                </div>
            }
            hover
        />
    );
};

export default SelfEmployedIndicator;