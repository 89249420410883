import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    DOCUMENT_FIELD,
    DOCUMENT_TYPE,
    STORE_CONTRACTOR_DOCUMENT_INIT_STATE,
} from "../../../../constants/documentType";
import {isUserFromNm} from "../../../../constants/roles";
import {TAB} from "../constants";

import {
    downloadDocument,
    getContractorDocumentList,
    getContractorDocumentListCounts,
    getContractorIdentificationSheet,
    getContractorPendingContractsList,
    getRegistryFramecontractShortItems,
    updateFieldDocumentStore,
} from "../../../../ducks/documents";

export const useFetchDocuments = ({contractorId, tab}) => {
    const dispatch = useDispatch();

    const role = ls(USER_ROLE);

    useEffect(() => {
        dispatch(getContractorDocumentListCounts({contractorId}));
        isUserFromNm(role) && fetchRegistryFrameContractShortItems({});
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateFieldDocumentStore({
                ...STORE_CONTRACTOR_DOCUMENT_INIT_STATE,
                identificationSheetList: [],
                contractorDocumentCounts: {},
            }));
        };
    }, []);

    const openDocumentByDownloadLink = (documentType, downloadLink) => {
        dispatch(downloadDocument({
            downloadLink,
            documentType,
            isDownload: false,
        }));
    };

    const fetchRegistryFrameContractShortItems = ({pageNum = 1, pageSize = 25}) => {
        dispatch(getRegistryFramecontractShortItems({
            contractorId,
            pageNum,
            pageSize,
        }));
    };

    const fetchAgencyContract = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.AGENCY_CONTRACT,
            [DOCUMENT_TYPE.AGENCY_CONTRACT],
            pageNum,
            pageSize,
        );
    };

    const fetchCompletedWorkAct = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.ACT_OF_ACCEPTANCE_OF_WORK,
            [DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_WORK, DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_TASK],
            pageNum,
            pageSize,
        );
    };

    const fetchConsentToPd = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.CONSENT_TO_PROCESSING_PERSONAL_DATA,
            [DOCUMENT_TYPE.CONSENT_TO_PROCESSING_PERSONAL_DATA],
            pageNum,
            pageSize,
        );
    };

    const fetchFrameContract = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.FRAME_CONTRACT,
            [
                DOCUMENT_TYPE.FRAME_CONTRACT,
                DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT,
                DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT,
                DOCUMENT_TYPE.ADDITIONAL_AGREEMENT,
            ],
            pageNum,
            pageSize,
        );
    };

    const fetchInsurancePolicy = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.INSURANCE_POLICY,
            [DOCUMENT_TYPE.INSURANCE_POLICY],
            pageNum,
            pageSize,
        );
    };

    const fetchOrderApplication = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.ORDER_APPLICATION,
            [DOCUMENT_TYPE.ORDER_APPLICATION, DOCUMENT_TYPE.TASK_APPLICATION],
            pageNum,
            pageSize,
        );
    };

    const fetchReceiptRNKO = ({pageNum = 1, pageSize = 25}) => {
        fetchDocumentList(
            DOCUMENT_FIELD.RECEIPT_RNKO,
            [
                DOCUMENT_TYPE.NAIMIX_REWARD_RECEIPT,
                DOCUMENT_TYPE.CASH_RECEIPT,
                DOCUMENT_TYPE.RECEIPT_OF_PAYMENT,
                DOCUMENT_TYPE.TRANSACTION_RECEIPT,
                DOCUMENT_TYPE.CONTRACTOR_PHONE_TRANSACTION_RECEIPT,
            ],
            pageNum,
            pageSize,
        );
    };

    const fetchDocumentList = (field, documentTypes, pageNum, pageSize, actualFilter) => {
        dispatch(getContractorDocumentList({
            field,
            data: {
                actualFilter,
                contractorId,
                documentTypeFilter: [
                    ...documentTypes,
                ],
                pageNum,
                pageSize,
            },
        }));
    };

    const fetchIdentificationSheet = () => {
        dispatch(getContractorIdentificationSheet({
            contractorId,
        }));
    };

    const fetchPendingContracts = () => {
        dispatch(getContractorPendingContractsList({
            contractorId,
        }));
    };

    const getFetchListByTab = (tab) => {
        switch (tab) {
        /*Агентский договор с Наймикс*/
        case TAB.AGENCY_CONTRACT:
            return fetchAgencyContract;
            /*Согласие на обработку ПД*/
        case TAB.CONSENT_TO_PROCESSING_PERSONAL_DATA:
            return fetchConsentToPd;
            /*Договоры с заказчиками*/
        case TAB.FRAME_CONTRACT:
            return fetchFrameContract;
            /*Заявки на выполнение работ (оказание услуг)*/
        case TAB.ORDER_APPLICATION:
            return fetchOrderApplication;
            /*Акты выполненных работ*/
        case TAB.ACT_OF_ACCEPTANCE_OF_WORK:
            return fetchCompletedWorkAct;
            /*Страховые полисы*/
        case TAB.INSURANCE_POLICY:
            return fetchInsurancePolicy;
            /*Квитанции РНКО*/
        case TAB.RECEIPT_RNKO:
            return fetchReceiptRNKO;
            /*Реестры на подписание рамочных договоров с исполнителем*/
        case TAB.DOCUMENTS_REGISTRY:
            return fetchRegistryFrameContractShortItems;
            /*Лист идентификации личности*/
        case TAB.IDENTIFICATION_SHEET:
            return fetchIdentificationSheet;
            /*Договоры на подписании*/
        case TAB.PENDING_CONTRACTS:
            return fetchPendingContracts;
        }
    };

    return {
        onFetchList: getFetchListByTab(tab),
        fetchAgencyContract,
        fetchCompletedWorkAct,
        fetchConsentToPd,
        fetchFrameContract,
        fetchInsurancePolicy,
        fetchOrderApplication,
        fetchReceiptRNKO,
        fetchRegistryFrameContractShortItems,
        openDocumentByDownloadLink,
        fetchIdentificationSheet,
    };
};