import {
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_ERROR,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_SUCCESS,
    CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE,
    UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
    statusDict: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_REQUEST:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_REQUEST: {
            return {
                ...state,
                progressAction: true,
                error: null,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_SUCCESS:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_SUCCESS:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_SUCCESS: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_ADD_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_UPDATE_ERROR:
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_SUBMIT_ERROR: {
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        }
        case UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_STATUS_DICT_SUCCESS:
            return {
                ...state,
                statusDict: payload,
            };
        case CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};