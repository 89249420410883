import React from "react";
import {useFormik} from "formik";

import NmForm from "../../../ActualComponents/NmForm";
import NmInputV2 from "../../../ActualComponents/NmInputV2";
import NmModal from "../../../ActualComponents/NmModal";
import ApplyButtons from "../../../ApplyButtons";
import NmTitle from "../../../NmTitle";
import UpdatingDirectoriesClientField from "../ClientField";

import {getBaseDirectoryValidation} from "../../utils/getValidation";

import {UPDATING_SPECIALITIES_DIRECTORIES_ACTION_TYPE} from "../../constants";

export const UpdatingDirectoriesEdit = props => {
    const {
        onClose,
        onAdd,
        onEdit,
        inputTypePlaceholder,
        inputTypeLabel,
        inputTypeMaxLength = 500,
        modalData,
        modalData: {
            actionType,
            clientId = "",
            value = "",
            id,
            header,
            submitBtnContent,
            cancelBtnContent,
        },
        isNeedClosingOnSubmit,
        progressAction,
        inputTypeMinLength,
        fetchList,
        isClientField,
    } = props;

    const {
        handleSubmit,
        values,
        touched,
        setFieldValue,
        errors,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: {
            clientId: isClientField ? clientId : undefined,
            value: value || "",
        },
        validationSchema: getBaseDirectoryValidation({
            isClientField,
            inputTypeMinLength,
        }),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit() {
        const callBack = () => {
            fetchList();

            if (!isNeedClosingOnSubmit) {
                onClose();
            }
        };

        if (isNeedClosingOnSubmit) {
            onClose();
        }

        if (actionType === UPDATING_SPECIALITIES_DIRECTORIES_ACTION_TYPE.EDIT) {
            onEdit({
                id,
                clientId: values.clientId,
                value: values.value,
                callBack,
            });

            return;
        }

        onAdd({
            clientId: values.clientId,
            value: values.value,
            callBack,
        });
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {header}
                </NmTitle>
            }
            footer={<ApplyButtons
                mobile="column"
                isHiddenCancelOnMobile
                submitBtnContent={submitBtnContent}
                cancelBtnContent={cancelBtnContent}
                onClose={onClose}
                submit={handleSubmit}
                disabled={progressAction}
            />}
        >
            <NmForm addMargin={true}>
                {
                    isClientField &&
                    <UpdatingDirectoriesClientField
                        modalData={modalData}
                        onChange={onChange}
                        value={values.clientId}
                        error={
                            touched?.clientId && errors?.clientId ?
                                errors?.clientId :
                                undefined
                        }
                    />
                }
                <NmInputV2
                    required
                    size="xl"
                    maxLength={inputTypeMaxLength}
                    label={inputTypeLabel}
                    placeholder={inputTypePlaceholder}
                    value={values.value}
                    name="value"
                    onChange={onChange}
                    error={
                        touched?.value && errors?.value ?
                            errors?.value :
                            undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
};