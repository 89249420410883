import React, {} from "react";
import { HexColorPicker } from "react-colorful";

import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmLabel from "../ActualComponents/NmLabel";

import bem from "../../utils/bem";

import {COLOR} from "../../constants/color";

import "./style.sass";

const NmColorPicker = (props) => {
    const {
        className,
        label,
        currentColor,
        setColor,
        size = "xl",
        disabled,
    } = props;

    const [block, element] = bem("nm-color-picker", className);

    return (
        <div className={block()}>
            {
                label &&
                <NmLabel
                    className={element("label")}
                    label={label}
                />
            }
            <NmAdvancedTooltip
                disabled={disabled}
                type="light"
                position="top"
                classNameTooltip={element("tooltip")}
                children={
                    <>
                        <HexColorPicker
                            color={currentColor}
                            onChange={setColor}
                        />
                        <NmInputV2
                            className="mt-2"
                            size="lg"
                            value={currentColor}
                            onChange={(event, {value}) => {
                                const hexReg = /^#[0-9a-f]{0,6}$/i;

                                if (hexReg.test(value)) {
                                    setColor(value);
                                }
                            }}
                        />
                    </>
                }
                trigger={
                    <div
                        className={element("current-color", {
                            size,
                            border: currentColor === COLOR.WHITE,
                            cursorPointer: !disabled,
                        })}
                        style={{backgroundColor: currentColor}}
                    />
                }
            />
        </div>
    );
};

export default NmColorPicker;