import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import {updateRecruitmentVacancyStore} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {bffRecruitmentVacancyTabFilterDataSelector} from "../../../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    getPageRecruitmentVacancyResponses,
    updateRecruitmentVacancyResponsesStore,
} from "../../../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import {bffRecruitmentVacancyResponseIdsSelector} from "../../../../../../ducks/bff/recruitment/vacancyResponses/selectors";
import {
    fileStoreRecruitmentPageDataSelector,
    getRecruitmentCandidateSmallThumbnails,
} from "../../../../../../ducks/fileStoreRecruitment";

const useRecruitmentVacancyResponseListFetch = (props) => {
    const {
        clientId,
        pageData,
        vacancyId,
        isNew,
    } = props;

    const dispatch = useDispatch();

    const {filterData = {}} = useSelector(bffRecruitmentVacancyTabFilterDataSelector);
    const responseIds = useSelector(bffRecruitmentVacancyResponseIdsSelector);
    const fileStorePageData = useSelector(fileStoreRecruitmentPageDataSelector);

    useEffect(() => {
        dispatch(updateRecruitmentVacancyStore({
            isResponsesPageActive: true,
        }));

        return () => {
            dispatch(updateRecruitmentVacancyResponsesStore({
                pageData: {},
                list: [],
                totalCount: 0,
            }));

            dispatch(updateRecruitmentVacancyStore({
                isResponsesPageActive: false,
            }));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(responseIds) || isEqual(fileStorePageData.ids, responseIds)) {
            return;
        }

        dispatch(getRecruitmentCandidateSmallThumbnails({
            ids: responseIds,
            isResponses: true,
        }));
    }, [responseIds]);

    useEffect(() => {
        fetchList();
    }, [
        pageData,
        filterData,
        isNew,
    ]);

    const fetchList = (params = {}) => {
        const {
            onSuccess,
        } = params;
        const {
            pageNum,
        } = pageData;

        dispatch(getPageRecruitmentVacancyResponses({
            clientId,
            vacancyId,
            pageSize: 25,
            pageNum,
            isNew,
            isLoadMore: pageNum !== 1,
            onSuccess,
            ...filterData,
        }));
    };

    return {
        fetchList,
    };
};

export default useRecruitmentVacancyResponseListFetch;