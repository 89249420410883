export const FORM_CONFIRM_CONTENT = `- При изменении ставки комиссии компании все выплаты “На рассмотрении” и “В процессе”, созданные по договорам :typeContract, будут автоматически отклонены в указанную дату (:updateDate).
- При изменении ставки комиссии исполнителей новая комиссия будет применена только для заказов, которые будут опубликованы после изменения ставки. Выплаты “На рассмотрении” и “В процессе” не будут отклонены.`;

export const FORM_TIME_ZONE_OPTIONS = [
    {
        key: "MSK",
        value: "MSK",
        text: "МСК",
    },
    {
        key: "UTC",
        value: "UTC",
        text: "UTC",
    },
];