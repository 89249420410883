import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import validate from "../../../../../../utils/validate";

import {keywordRule} from "../../../../../../constants/validationRules";

import {addKeyword, updateKeyword} from "../../../../../../ducks/keyword";

const SettingsDirectoriesKeywordsEdit = (props) => {
    const {
        onClose,
        isEdit,
    } = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [form, setForm] = useState({keyword: props.keywordData.keyword});
    const [error, setError] = useState({});

    const handleChange = (e, {value, name}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const isValidForm = () => {
        const error = validate(form, keywordRule, "");

        setError(error);

        return Object.keys(error).length === 0;
    };

    const submit = () => {
        if (isEdit && props.keywordData.keyword === form.keyword) {
            onClose();

            return;
        }

        if (!isValidForm()) {
            return;
        }

        const _keyword = form.keyword.toLowerCase();

        if (isEdit) {
            dispatch(updateKeyword({
                keywordUuid: props.keywordData.keywordUuid,
                keyword: _keyword,
            }));
        } else {
            dispatch(addKeyword({
                keyword: _keyword,
                publicAvailable: true,
            }));
        }

        onClose();
    };

    return (
        <NmModal
            onClose={onClose}
            size="md"
            header={
                <NmTitle size="lg">
                    {isEdit ? t("keyword-edit-modal.title") : t("keyword-edit-modal.new-add-title")}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submitBtnContent={isEdit ? t("button.save") : t("button.add")}
                    submit={submit}
                    cancelBtnContent={t("button.cancel")}
                />
            }
        >
            <NmForm>
                <NmInputV2
                    size="xl"
                    label={t("keyword-edit-modal.keyword-label")}
                    error={error.keyword}
                    name="keyword"
                    value={form.keyword}
                    onChange={handleChange}
                />
            </NmForm>
        </NmModal>
    );
};

export default SettingsDirectoriesKeywordsEdit;