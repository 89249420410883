import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "adm/recruitment/job-boards-webhook";

// POST /bff/adm/recruitment/job-boards-webhook/vacancies-by-account-id
// Получить вакансии из подписки на события джоб-борда по фильтру
const getJobBoardSubscriptions = function* ({payload}) {
    const {
        onSuccess,
        isLoadMore,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/vacancies-by-account-id`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS,
            payload: {
                ...result,
                isLoadMore,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
        });
    }
};

// DELETE /bff/adm/recruitment/job-boards-webhook/delete-by-external-id
// Удалить подписку на события джоб-борда для вакансии
const deleteRecruitmentWebhook = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.delete(`${controller}/delete-by-external-id`, {params: reqData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/job-boards-webhook/add-by-external-id
// Добавить подписку на события джоб-борда для вакансии
const addRecruitmentWebhook = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/add-by-external-id`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_ERROR,
        });
    }
};

// GET /bff/adm/recruitment/job-boards-webhook/get-by-external-id
// Получить подписку на события джоб-борда для вакансии
const getRecruitmentWebhook = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get-by-external-id`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST, getJobBoardSubscriptions),
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_DELETE_REQUEST, deleteRecruitmentWebhook),
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_ADD_REQUEST, addRecruitmentWebhook),
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_REQUEST, getRecruitmentWebhook),
    ]);
}