import {floatToPercent} from "../../../../../../utils/mathHelper";

export const getDepositRateEditInitialState = (payment ) => {
    const {
        currentCommissionRate,
        deferredCurrentCommissionRate,
        deferredDatetime,
        deferredRegionTime,
        contractorOrderCommission,
        deferredContractorOrderCommission,
        deferredContractorOrderCommissionDatetime,
        deferredContractorOrderCommissionRegionTime,
        contractorRegistryCommission,
        deferredContractorRegistryCommission,
        deferredContractorRegistryCommissionDatetime,
        deferredContractorRegistryCommissionRegionTime,
        minCommission,
        threshold,
        deferredMinCommission,
        deferredThreshold,
        deferredMinCommissionDatetime,
        deferredMinCommissionRegionTime,
        minimalCommissionNdflPaymentsAmount = 0,
        deferredMinimalCommissionNdflPaymentsAmount,
        deferredMinimalCommissionNdflPaymentsAmountDatetime,
        deferredMinimalCommissionNdflPaymentsAmountRegionTime,
    } = payment;

    return {
        clientCommissionChange: {
            rate: String(floatToPercent(currentCommissionRate)),
            deferredRate: deferredCurrentCommissionRate ? floatToPercent(deferredCurrentCommissionRate) : "",
            fromDatetime: deferredCurrentCommissionRate ? new Date(deferredDatetime) : null,
            regionTime: deferredCurrentCommissionRate ? deferredRegionTime : "MSK",
            isDeferred: !!deferredCurrentCommissionRate,
        },
        contractorOrderCommissionChange: {
            rate: String(floatToPercent(contractorOrderCommission)),
            deferredRate: deferredContractorOrderCommission ? floatToPercent(deferredContractorOrderCommission) : "",
            fromDatetime: deferredContractorOrderCommission ? new Date(deferredContractorOrderCommissionDatetime) : "",
            regionTime: deferredContractorOrderCommission ? deferredContractorOrderCommissionRegionTime : "MSK",
            isDeferred: !!deferredContractorOrderCommission,
        },
        contractorRegistryCommissionChange: {
            rate: String(floatToPercent(contractorRegistryCommission)),
            deferredRate: deferredContractorRegistryCommission ? floatToPercent(deferredContractorRegistryCommission) : "",
            fromDatetime: deferredContractorRegistryCommission ? new Date(deferredContractorRegistryCommissionDatetime) : "",
            regionTime: deferredContractorRegistryCommission ? deferredContractorRegistryCommissionRegionTime : "MSK",
            isDeferred: !!deferredContractorRegistryCommission,
        },
        minCommissionChange: {
            minCommission: parseFloat(minCommission),
            thresholdAmount: parseFloat(threshold),
            fromDatetime: deferredMinCommission ? new Date(deferredMinCommissionDatetime) : null,
            regionTime: deferredMinCommission ? deferredMinCommissionRegionTime : "MSK",
            isDeferred: !!deferredMinCommission,
            deferredMinCommission: deferredMinCommission ? parseFloat(deferredMinCommission): undefined,
            deferredThreshold: deferredMinCommission ? parseFloat(deferredThreshold): undefined,
        },
        minimalCommissionNdflChange : {
            rate: parseFloat(minimalCommissionNdflPaymentsAmount),
            deferredRate: deferredMinimalCommissionNdflPaymentsAmount ? parseFloat(deferredMinimalCommissionNdflPaymentsAmount): undefined,
            fromDatetime: deferredMinimalCommissionNdflPaymentsAmountDatetime ? new Date(deferredMinimalCommissionNdflPaymentsAmountDatetime) : "",
            regionTime: deferredMinimalCommissionNdflPaymentsAmountRegionTime ? deferredMinimalCommissionNdflPaymentsAmountRegionTime : "MSK",
            isDeferred: !!deferredMinimalCommissionNdflPaymentsAmount,
        },
    };
};