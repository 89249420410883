export const BFF_KEDO_STAFF_UPDATE_REQUEST = "BFF_KEDO_STAFF_UPDATE_REQUEST";
export const BFF_KEDO_STAFF_UPDATE_SUCCESS = "BFF_KEDO_STAFF_UPDATE_SUCCESS";
export const BFF_KEDO_STAFF_UPDATE_ERROR = "BFF_KEDO_STAFF_UPDATE_ERROR";

export const BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST = "BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST";
export const BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS = "BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS";
export const BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR = "BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR";

export const BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST = "BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST";
export const BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS = "BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS";
export const BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR = "BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR";

export const BFF_KEDO_STAFF_GET_PAGE_REQUEST = "BFF_KEDO_STAFF_GET_PAGE_REQUEST";
export const BFF_KEDO_STAFF_GET_PAGE_SUCCESS = "BFF_KEDO_STAFF_GET_PAGE_SUCCESS";
export const BFF_KEDO_STAFF_GET_PAGE_ERROR = "BFF_KEDO_STAFF_GET_PAGE_ERROR";

export const BFF_KEDO_STAFF_ADD_REQUEST = "BFF_KEDO_STAFF_ADD_REQUEST";
export const BFF_KEDO_STAFF_ADD_SUCCESS = "BFF_KEDO_STAFF_ADD_SUCCESS";
export const BFF_KEDO_STAFF_ADD_ERROR = "BFF_KEDO_STAFF_ADD_ERROR";

export const BFF_KEDO_STAFF_GET_BY_ID_REQUEST = "BFF_KEDO_STAFF_GET_BY_ID_REQUEST";
export const BFF_KEDO_STAFF_GET_BY_ID_SUCCESS = "BFF_KEDO_STAFF_GET_BY_ID_SUCCESS";
export const BFF_KEDO_STAFF_GET_BY_ID_ERROR = "BFF_KEDO_STAFF_GET_BY_ID_ERROR";

export const BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST = "BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST";
export const BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS = "BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS";
export const BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR = "BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR";

export const BFF_KEDO_STAFF_COMMON_REQUEST = "BFF_KEDO_STAFF_COMMON_REQUEST";
export const BFF_KEDO_STAFF_COMMON_SUCCESS = "BFF_KEDO_STAFF_COMMON_SUCCESS";
export const BFF_KEDO_STAFF_COMMON_ERROR = "BFF_KEDO_STAFF_COMMON_ERROR";

export const BFF_KEDO_STAFF_UPDATE_STORE = "BFF_KEDO_STAFF_UPDATE_STORE";