import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE} from "../constants";

/**
 * nextAllProjectsParams - обновление списка проектов ВНЕ текущей страницы.
 * Чтобы обновление не затронуло текущую страницу - можно передать excludeProjectsIds
 * @param params
 * @returns {{boolParam: (boolean|*), excludeProjectIds: *}|null}
 */
export const getNextAllProjectsParams = (params) => {
    const {
        isVisibleClient,
        clientPropertiesParamSelectorType,
        client,
        projectsParams,
    } = params;

    if (
        isVisibleClient
        && clientPropertiesParamSelectorType !== CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY
    ) {
        return null;
    }

    // Необходимо отправлять nextAllProjectsParams только если клиент изменен
    if (client.changed) {
        return {
            boolParam: client.changedCheckedValue,
            excludeProjectIds: projectsParams.map(item => item.id),
        };
    }

    return null;
};