import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import AmountInput from "../../../../components/AmountInput";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import useSettingsDirectoriesWithdrawalCommissionForm from "./hooks/useForm";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {ADMIN} from "../../../../constants/roles";

import {
    contractorCommissionWithdrawalSelector,
    settingsDirectoriesBanksProgressActionSelector,
} from "../../../../ducks/settingsDirectoriesBanks";

const SettingsDirectoriesWithdrawalCommission = () => {
    const role = ls(USER_ROLE);
    const isEditable = [ADMIN].includes(role);

    const data = useSelector(contractorCommissionWithdrawalSelector);
    const progress = useSelector(settingsDirectoriesBanksProgressActionSelector);

    const {
        isEdit,
        values,
        errors,
        touched,
        handleChange,
        toggleCard,
        cancelEditMode,
    } = useSettingsDirectoriesWithdrawalCommissionForm({data});

    const renderReadOnlyCard = () => {
        return (
            <>
                <LabelTextApp
                    label="Минимальная сумма вывода, ₽"
                    text={formatAmountWithNullChecking(data.minWithdrawalAmount || 0)}
                />
                <LabelTextApp
                    label="Сумма вывода без комиссии, ₽"
                    text={formatAmountWithNullChecking(data.withdrawalAmountWithoutCommission || 0)}
                />
                <LabelTextApp
                    label="Комиссия исполнителя, ₽"
                    text={formatAmountWithNullChecking(data.contractorCommissionAmount || 0)}
                />
            </>
        );
    };

    const renderEditModeCard = () => {
        return (
            <NmForm addMargin={true}>
                <AmountInput
                    newInput
                    size="lg"
                    label="Минимальная сумма вывода, ₽"
                    name="minWithdrawalAmount"
                    placeholder="0,00"
                    value={values.minWithdrawalAmount}
                    onChange={handleChange}
                    error={touched.minWithdrawalAmount && errors.minWithdrawalAmount}
                />
                <AmountInput
                    newInput
                    size="lg"
                    label="Сумма вывода без комиссии, ₽"
                    name="withdrawalAmountWithoutCommission"
                    placeholder="0,00"
                    value={values.withdrawalAmountWithoutCommission}
                    onChange={handleChange}
                    error={touched.withdrawalAmountWithoutCommission && errors.withdrawalAmountWithoutCommission}
                />
                <AmountInput
                    newInput
                    size="lg"
                    label="Комиссия исполнителя, ₽"
                    name="contractorCommissionAmount"
                    placeholder="0,00"
                    value={values.contractorCommissionAmount}
                    onChange={handleChange}
                    error={touched.contractorCommissionAmount && errors.contractorCommissionAmount}
                />
            </NmForm>
        );
    };

    return (
        <CardApp
            title="Комиссия за вывод средств"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEditMode}
            className="col-16 col-xl-5"
            isEdit={isEdit}
            loading={progress}
        >
            {isEdit ? renderEditModeCard() : renderReadOnlyCard()}
        </CardApp>
    );
};

export default SettingsDirectoriesWithdrawalCommission;