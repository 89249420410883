import React from "react";
import {isEmpty} from "lodash";

import NmLabelText from "../../../../ActualComponents/NmLabelText";

import {isLastElementInArray} from "../../../../../utils/objectHelper";

export const CandidateInformationShort = (props) => {
    const {
        list,
        card,
    } = props;

    if (isEmpty(card)) {
        return null;
    }

    return list.map((item, index, array) => {
        const {
            className,
            label,
            value,
        } = card[item.name] || {};
        const isLast = isLastElementInArray(array, index);

        return (
            <NmLabelText
                key={label}
                label={label}
                text={value}
                className={`${className} ${isLast ? "" : "mb-2"}`}
            />
        );
    });
};
