import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {
    addRecruitmentVacancyCandidatesComment,
    uploadFileRecruitmentVacancyCandidatesComment,
} from "../../ducks/bff/recruitment/vacancyCandidatesComments/actionCreators";
import {
    bffRecruitmentVacancyCandidatesCommentsProgressActionSelector,
} from "../../ducks/bff/recruitment/vacancyCandidatesComments/selectors";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import {ReactComponent as SendIcon} from "../../images/send_24.svg";
import {DropzoneUploadedArea} from "../ActualComponents/DropzoneV2/components/uploaded-area";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import DefaultDropzone from "../DefaultDropzone";
import NmButton from "../NmButton";

import {useCounter} from "../../hooks/useCounter";

import {downloadLocalFile} from "../../utils/downloadBlob";
import {toastError} from "../../utils/toastHelper";

import {maxSize25MB} from "../../constants/validationRules";

import "./style.sass";

export const AddComment = (props) => {
    const {
        clientId,
        candidateId,
        vacancyId,
        fetchList,
    } = props;

    const progress = useSelector(bffRecruitmentVacancyCandidatesCommentsProgressActionSelector);

    const dispatch = useDispatch();

    const initialValues = {
        files: [],
        comment: "",
    };

    const {
        handleSubmit,
        values,
        setValues,
        setFieldValue,
        touched,
        errors,
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema: yup.object().shape({
            comment: yup.string()
                .nullable()
                .min(3, "Не менее 3 символов"),
        }),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    const {
        comment,
        files,
    } = values;

    const {
        isCounterFinish,
        increaseCounter,
    } = useCounter({countList: files});

    useEffect(() => {
        if (isCounterFinish) {
            refreshPage();
        }
    }, [isCounterFinish]);

    function refreshPage() {
        fetchList();
        setValues(initialValues);
    }

    function onSubmit() {
        dispatch(addRecruitmentVacancyCandidatesComment({
            clientId,
            vacancyId,
            candidateId,
            comment,
            onSuccess: ({id}) => {
                if (files && files.length) {
                    files.forEach(file => {
                        uploadFile(file, id);
                    });

                    return;
                }

                refreshPage();
            },
        }));
    }

    const uploadFile = (file, commentId) => {
        if (file.size > maxSize25MB) {
            return;
        }

        const formData = new FormData();

        formData.append("file", file);
        formData.append("clientId", clientId);
        formData.append("fileName", file.name);

        dispatch(uploadFileRecruitmentVacancyCandidatesComment({
            id: commentId,
            formData,
            onSuccess: () => {
                increaseCounter();
            },
        }));
    };

    const uploadFiles = (acceptedFiles) => {
        if (!acceptedFiles || !acceptedFiles.length) {
            return;
        }

        setFieldValue("files", acceptedFiles);
    };

    const onDownloadFile = (file) => {
        downloadLocalFile(file, file.name);
    };

    const onRemoveFile = ({name}) => {
        const _files = [...files];
        const index = _files.findIndex(file => (file.name === name));

        _files.splice(index, 1);
        setFieldValue("files", _files);
    };


    return (
        <div>
            <div className="d-flex align-items-start">
                <NmTextareaV2
                    label="Комментарий"
                    placeholder="Оставьте ваш комментарий..."
                    maxLength={5000}
                    value={values.comment}
                    onChange={(event, {value}) => {
                        setFieldValue("comment", value);
                    }}
                    error={
                        touched?.comment &&
                        errors?.comment
                    }
                />
                <DefaultDropzone
                    files={files}
                    multiple={true}
                    maxFiles={5}
                    accept=".jpg, .png, .docx, .doc, .pdf, .rtf."
                    onDropFiles={uploadFiles}
                >
                    <NmButton
                        className="mx-2 mx-xl-4 add-comment__button"
                        size="xl"
                        onlyIcon
                        color="grey"
                        icon={
                            <AddIcon
                                width={16}
                                height={16}
                            />
                        }
                        disabled={files.length > 4}
                    />
                </DefaultDropzone>
                <NmButton
                    disabled={!values.comment || progress}
                    className="add-comment__button"
                    size="xl"
                    onlyIcon={true}
                    icon={<SendIcon />}
                    color="grey"
                    onClick={handleSubmit}
                />
            </div>
            {
                Boolean(files.length) &&
                <DropzoneUploadedArea
                    files={files}
                    isFixedTooltip={true}
                    isShowDeleteButton={true}
                    multiple={true}
                    isDownload={true}
                    onDownloadFile={onDownloadFile}
                    onRemoveFile={onRemoveFile}
                />
            }
        </div>
    );
};