import React, {useContext} from "react";
import {useSelector} from "react-redux";

import DictVariantsEditModal from "../../../../ActualComponents/DictVariantsEditModal";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";

import {
    contractorDrivingLicenseDictWithKeySelector,
} from "../../../../../ducks/contractorProfile";

export const RecruitmentCandidateDriverLicencesEdit = (props) => {
    const {
        onClose,
        initialValue,
    } = props;

    const {
        updateInformation,
        fetchInformation,
        progressAction,
    } = useContext(RecruitmentCandidateInformationContext);

    const driverLicenseDict = useSelector(contractorDrivingLicenseDictWithKeySelector);

    const onSubmit = ({value}) => {
        updateInformation({
            driverLicences: value,
            onSuccess: () => {
                onClose();
                fetchInformation();
            },
        });
    };

    return (
        <DictVariantsEditModal
            title="Категории водительских прав"
            type="checkbox"
            multiple={true}
            initialValue={initialValue}
            progress={progressAction}
            onClose={onClose}
            onSubmit={onSubmit}
            fields={driverLicenseDict}
        />
    );
};
