import React, {FC, ReactNode, useContext} from "react";
import {Link} from "react-router-dom";

import {AppContext} from "../../../AppContext";
import {MediaButtons} from "../../MediaControls";
import NmInfoCard, {INmInfoCard} from "../../NmInfoCard";
import NmLabelText, {ILabelText} from "../../NmLabelText";
import NmListCardTitle, {TNmListCardTitleChildren} from "../CardTitle";
import NmListCardErrors from "./components/Errors";

import bem from "../../../../utils/bem";

import {history} from "../../../../store/configureStore";

import {TMediaButtonsConfig} from "../../MediaControls/types";
import {TNmListCardError} from "./types";

import "./style.sass";

export type TLabels = Array<ILabelText & {render?: () => void}>;

interface INmListCard {
    className?: string,
    primaryHeader?: TNmListCardTitleChildren,
    secondaryHeader?: ReactNode | string,
    onClickLink?: Function
    secondaryHeaderStatus?: ReactNode,
    otherContent?: ReactNode,
    primaryHeaderStatus?: ReactNode,
    classNameMainContent?: string,
    actionsClassName?: string,
    labels?: TLabels,
    cards?: INmInfoCard[],
    primaryHeaderStatusTooltip?: ReactNode,
    alignItems?: "flex-start" | "flex-end",
    actions?: ReactNode,
    noDivider?: boolean,
    onlyLabels?: boolean,
    checkbox?: boolean,
    fluidPrimaryHeader?: boolean,
    cardsWithContainer?: boolean,
    cardsContainerClassName?: string,
    cardsContainerRowClassName?: string,
    cardsWrapReverse?: boolean,
    avatar?: boolean,
    clientLogo?: boolean,
    primaryHeaderTitle?: string,
    primaryHeaderTooltip?: ReactNode,
    primaryHeaderNoWrap?: boolean,
    primaryHeaderTwoLines?: boolean,
    primaryHeaderLink?: boolean,
    primaryHeaderWordBreak?: boolean,
    mediaControls?: TMediaButtonsConfig,
    primarySubHeader?: ReactNode,
    classNameDivider?: boolean,
    secondaryHeaderRelative?: boolean,
    classNamePrimaryHeaderValue?: string,
    // для настройки отступов в случае, если надо показывать выбрано/не выбрано у чекбокса
    isShowCheckboxLabel?: boolean,
    // прижимает блок с действиями в правую верхнюю часть карточки
    isFixedActions?: boolean,
    secondaryHeaderNoReverse?: boolean,
    secondaryHeaderAdditionStatus?: ReactNode,
    cardsContainerAlign?: "left" | "right",
    errors?: Array<TNmListCardError>,
    classNamePrimaryHeader?: string,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
    checkboxBlock?: ReactNode,
    isVisibleEmptySecondaryHeader?: boolean,
    filterData?: any,
    paginationData?: any,
    link?: string,
}

const NmListCard: FC<INmListCard> = (props) => {
    const {
        otherContent,
        classNameMainContent = "",
        secondaryHeader,
        secondaryHeaderStatus,
        primaryHeader,
        primaryHeaderStatus,
        actionsClassName = "",
        secondaryHeaderAdditionStatus,
        secondaryHeaderNoReverse,
        secondaryHeaderRelative,
        classNamePrimaryHeaderValue = "",
        labels = [],
        noDivider = false,
        cards,
        primaryHeaderStatusTooltip,
        primaryHeaderNoWrap,
        primaryHeaderTitle,
        primaryHeaderWordBreak = false,
        primaryHeaderTwoLines = false,
        fluidPrimaryHeader = false,
        onlyLabels = false,
        checkbox,
        cardsWithContainer,
        alignItems,
        className,
        primaryHeaderTooltip,
        mediaControls,
        actions,
        cardsContainerClassName = "",
        cardsContainerAlign = "right",
        cardsWrapReverse = false,
        onClickLink = () => {},
        primaryHeaderLink = false,
        avatar,
        clientLogo,
        isFixedActions,
        isShowCheckboxLabel,
        classNameDivider = "",
        classNamePrimaryHeader = "",
        errors,
        primarySubHeader,
        checkboxBlock,
        onClick,
        isVisibleEmptySecondaryHeader = false,
        link,
        filterData,
        paginationData,
    } = props;
    const [block, element] = bem("nm-list-card", className);

    const isSecondaryHeader = Boolean(secondaryHeader) || isVisibleEmptySecondaryHeader;

    const {
        scrollPosition,
        pathname,
    } = useContext<any>(AppContext);

    const getCards = () => {
        return cards && cards.map((value, index) => {
            if (!value) {
                return null;
            }

            const {
                className = "",
                ...other
            } = value;

            return (
                <div
                    key={index}
                    className={className}
                >
                    <NmInfoCard
                        {...other}
                        className={element("info-box", {last: cards.length - 1 === index})}
                    />
                </div>
            );
        });
    };

    const getLabels = () => {
        if (!labels) {
            return null;
        }

        return (
            <div
                className={element("labels", {
                    onlyLabels,
                    checkbox,
                    avatar,
                    clientLogo,
                })}
            >
                {
                    labels.map((labelProps, index) => {
                        if (!labelProps) {
                            return null;
                        }

                        if (labelProps.render) {
                            return labelProps.render();
                        }

                        const {
                            className = "",
                            ...props
                        } = labelProps;

                        return (
                            <NmLabelText
                                {...props}
                                key={index}
                                className={`nm-list-card__label-text ${className}`}
                            />
                        );
                    })
                }
            </div>
        );
    };

    const getErrors = (isMobile?: boolean) => {
        if (!errors) {
            return null;
        }

        return (
            <NmListCardErrors
                className={element("errors", {isMobile})}
                errors={errors}
            />
        );
    };

    const getPrimaryHeader = () => {
        if (link) {
            return (
                <Link
                    onClick={() => {
                        if (onClickLink) {
                            onClickLink();
                        }

                        // для корректной работы браузерных кнопок навигации
                        history.replace(pathname, {
                            scrollTop: scrollPosition,
                            paginationData,
                            filterData,
                            pathname,
                        });
                    }}
                    to={{
                        pathname: link,
                        state: {
                            scrollTop: scrollPosition,
                            paginationData,
                            filterData,
                            pathname,
                        },
                    }}
                >
                    {primaryHeader}
                </Link>
            );
        }

        return primaryHeader;
    };

    return (
        <div
            onClick={onClick}
            className={`${block({alignItems})} row`}
        >
            {getErrors(true)}
            <div className={`${classNameMainContent} nm-list-card__main-content`}>
                {
                    (isSecondaryHeader || secondaryHeaderStatus) &&
                    <div
                        className={element("secondary-header", {
                            checkbox,
                            secondaryHeaderNoReverse,
                            secondaryHeaderRelative,
                        })}
                    >
                        {checkboxBlock}
                        {
                            secondaryHeaderAdditionStatus &&
                            <div className={element("secondary-additional-status")}>
                                {secondaryHeaderAdditionStatus}
                            </div>
                        }
                        {
                            secondaryHeaderStatus &&
                            <div
                                className={element("secondary-header-status", {
                                    onlyStatus: !isSecondaryHeader,
                                })}
                            >
                                {secondaryHeaderStatus}
                            </div>
                        }
                        <div
                            className={element("secondary-header-value", {
                                secondaryHeaderNoReverse,
                                minHeight: isVisibleEmptySecondaryHeader,
                            })}
                        >
                            {secondaryHeader}
                        </div>
                    </div>
                }
                {
                    primaryHeader &&
                    <div
                        className={`${element("primary-header", {
                            avatar,
                            checkbox,
                            withCheckboxLabel: isShowCheckboxLabel,
                            noSecondaryHeader: !isSecondaryHeader && !secondaryHeaderStatus,
                            secondaryHeader: isSecondaryHeader,
                            secondaryHeaderStatus: Boolean(secondaryHeaderStatus),
                            offsetRight: Boolean((actions || mediaControls)),
                        })} ${classNamePrimaryHeader}`}
                    >
                        <NmListCardTitle
                            onClickLink={onClickLink}
                            title={primaryHeaderTitle}
                            className={`${element("primary-header-value", {
                                wordBreak: primaryHeaderWordBreak,
                                fluid: fluidPrimaryHeader,
                                noWrap: primaryHeaderNoWrap,
                                twoLines: primaryHeaderTwoLines,
                                link: primaryHeaderLink,
                            })} ${classNamePrimaryHeaderValue}`}
                        >
                            {getPrimaryHeader()}
                        </NmListCardTitle>
                        {
                            primaryHeaderTooltip &&
                            <div className="nm-list-card__primary-header-tooltip">
                                {primaryHeaderTooltip}
                            </div>
                        }
                        {
                            primaryHeaderStatus &&
                            <div className="nm-list-card__status">
                                {primaryHeaderStatus}
                            </div>
                        }
                        {
                            primaryHeaderStatusTooltip &&
                            <div className="nm-list-card__primary-header-status-tooltip">
                                {primaryHeaderStatusTooltip}
                            </div>
                        }
                    </div>
                }
                {
                    primarySubHeader &&
                    <div
                        className={element("primary-header")}
                    >
                        {primarySubHeader}
                    </div>
                }
                {getLabels()}
            </div>
            {
                !noDivider &&
                <div className={`nm-list-card__divider ${classNameDivider}`} />
            }
            {
                cardsWithContainer ?
                    <div className={cardsContainerClassName}>
                        <div className={`row ${element("cards-container-row", {align: cardsContainerAlign, wrapReverse: cardsWrapReverse})}`}>
                            {getCards()}
                        </div>
                    </div> :
                    getCards()
            }
            {otherContent}
            {
                (actions || errors) &&
                <div className={`${actionsClassName} ${element("actions", {fixed: isFixedActions})}`}>
                    {getErrors()}
                    {actions}
                </div>
            }
            {
                mediaControls &&
                <div className={`${actionsClassName} ${element("actions", {fixed: isFixedActions})}`}>
                    <MediaButtons
                        inline={true}
                        config={mediaControls}
                    />
                </div>
            }
        </div>
    );
};

export default NmListCard;