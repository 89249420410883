import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";

import {ADMIN, NM_CHIEF_ACCOUNTANT} from "../../../../../constants/roles";

import {
    checkAccessSettingsDirectoriesBanksBalance,
    getSettingsDirectoriesBank,
} from "../../../../../ducks/settingsDirectoriesBanks";

export const useSettingsDirectoriesBanksFetch = () => {
    const dispatch = useDispatch();

    const role = ls(USER_ROLE);

    useEffect(() => {
        fetchSettings();

        if ([ADMIN, NM_CHIEF_ACCOUNTANT].includes(role)) {
            dispatch(checkAccessSettingsDirectoriesBanksBalance());
        }
    }, []);

    const fetchSettings = () => {
        dispatch(getSettingsDirectoriesBank({
            key: "contractorWalletWithdrawAvailable",
        }));
    };

    return {
        fetchSettings,
    };
};