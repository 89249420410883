import {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {formatFileDict} from "../../../../constants/financeExport";
import {ADMIN} from "../../../../constants/roles";

import {currentUserRestrictionsSelector} from "../../../../ducks/clientUserRestrictions";
import {
    addMutualSettlementsReconciliationAct,
    deleteMutualSettlementsReconciliationActById,
    exportMutualSettlementsReconciliationAct,
    mutualSettlementsReconciliationActProgressActionSelector,
} from "../../../../ducks/mutualSettlementsReconciliationActs";

export const useFinanceReconciliationActsActions = (params) => {
    const {
        fetchList,
        clientId,
        filter,
    } = params;

    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    const progressAction = useSelector(mutualSettlementsReconciliationActProgressActionSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);

    const [confirmData, setConfirmData] = useState({});

    const onClickAdd = () => {
        dispatch(addMutualSettlementsReconciliationAct({
            ...filter,
            onSuccess: () => {
                fetchList();
            },
        }));
    };

    const onClickDeleteAct = (item) => {
        setConfirmData({
            content: "Вы действительно хотите удалить выбранные строки реестра?",
            onConfirm: () => {
                dispatch(deleteMutualSettlementsReconciliationActById({
                    actId: item.actId,
                    onSuccess: () => {
                        fetchList();
                        setConfirmData({});
                    },
                }));
            },
        });
    };

    const onClickExport = (actId, formatType) => {
        dispatch(exportMutualSettlementsReconciliationAct({
            actId,
            formatType,
        }));
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    const filters = useMemo(() => {
        const period = {
            component: FILTER.DATE_RANGE,
            dateFormat: "dd.MM.yy",
            startFieldName: "fromDateFilter",
            endFieldName: "toDateFilter",
            label: "Период",
            isClearable: true,
            value: {
                fromDateFilter: null,
                toDateFilter: null,
            },
        };

        if (clientId) {
            return [
                {
                    row: [
                        period,
                    ],
                },
            ];
        }

        return [
            {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        label: "Компания",
                        multiple: true,
                        placeholder: "По всем заказчикам",
                        name: "clientIdsFilter",
                        isBff: true,
                    },
                ],
            },
            {
                row: [
                    period,
                ],
            },
        ];
    }, []);

    const getMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            if (progressAction) {
                                return;
                            }

                            onClickExport(item.actId, formatFileDict.PDF);
                        },
                        color: "light-green",
                        children: "Выгрузить pdf",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            if (progressAction) {
                                return;
                            }

                            onClickExport(item.actId, formatFileDict.XLSX);
                        },
                        color: "light-green",
                        children: "Выгрузить xlsx",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onClickDeleteAct(item);
                        },
                        disabled: progressAction,
                        color: "grey",
                        children: "Удалить",
                    },
                    visible: [ADMIN].includes(role)
                        && !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.actionsReconciliationReports),
                },
            ],
        };
    };

    return {
        filters,
        confirmData,
        onCloseConfirm,
        onClickDeleteAct,
        getMediaControls,
        onClickExport,
        onClickAdd,
    };
};