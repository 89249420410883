import {MediaButtonsBreakpointNames, TAsset, TDevice} from "../types";

/***
 * из объекта описывающего кнопку, берет поле asset, содержащее пропсы для замены в нужном брейкпоинте
 * @param device - текущие брейкпоинты из react-media (desktop, mobile, tablet)
 * @param asset - описывает пропсы, заменяющие основные пропсы, для каждого брейкпоинта
 */
export const extractButtonProps = (asset: TAsset, device: TDevice) => {
    // example: ["mobile", "tablet", "desktop"]
    const keys: Array<MediaButtonsBreakpointNames> = Object.keys(device) as Array<keyof typeof device>;
    // example: [true, false, false]
    const values: Array<boolean | undefined> = Object.values(device);
    // example: 0
    const currentQueryIndex: number = values.findIndex(value => value);
    // example: "mobile"
    const breakPoint: MediaButtonsBreakpointNames = keys[currentQueryIndex];
    // пропсы кнопки в текущем брейкпоинте
    const {[breakPoint]: buttonProps} = asset;

    return buttonProps;
};