import {useSelector} from "react-redux";

import {
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
} from "../../../../constants/links";

import {
    advertisementFunnelsPageDataSelector,
    advertisementFunnelsProgressSelector,
    advertisementFunnelsTotalCountSelector,
} from "../../../../ducks/advertisement";

import {SUB_PAGE_ORDER_CONTRACTOR} from "../../../../constants/link-params";

export const useContractorsOnOrdersTab = (params) => {
    const {
        clientId,
        advertisementId,
        countContractorOnOrders,
    } = params;

    const {isSearch} = useSelector(advertisementFunnelsPageDataSelector);
    const progress = useSelector(advertisementFunnelsProgressSelector);
    const totalCount = useSelector(advertisementFunnelsTotalCountSelector);

    return {
        count: (isSearch || progress) ? totalCount : countContractorOnOrders,
        name: "Исполнители на заказах",
        link: LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL
            .replace(":clientId", clientId)
            .replace(":advertisementId", advertisementId)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK),
        active: [
            LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS
                .replace(":clientId", clientId)
                .replace(":advertisementId", advertisementId),
        ],
    };
};