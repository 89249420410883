import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getCitizenships,
    getContractorRichList,
    getContractorStatusDict} from "../../../../../../../ducks/contractor";
import {
    getByIdDocumentsEdoRegistries,
    updateDocumentsEdoRegistriesStore,
} from "../../../../../../../ducks/documentsEdoRegistries";

export const useEdoDocumentsRegistriesFetch = ({filterData, pageNum, clientId, registryId, pageSize}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCitizenships());
        dispatch(getContractorStatusDict());
        fetchCard();
    }, []);

    useEffect(() => {
        fetchList();
    }, [filterData, pageNum, pageSize]);


    useEffect(() => {
        return () => {
            dispatch(updateDocumentsEdoRegistriesStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const fetchCard = () => {
        dispatch(getByIdDocumentsEdoRegistries({
            clientId,
            registryId,
        }));
    };

    const fetchList = () => {
        dispatch(getContractorRichList({
            ...filterData,
            clientId,
            edoDocumentsRegistryId: registryId,
            sortType: "JOIN_DATE_DESC",
            pageNum,
            pageSize,
        }));
    };

    return {
        fetchList,
    };
};