import React from "react";

import JobBoardLogo from "../../JobBoardLogo";

import {getRecruitmentResponseSourceText} from "./utils/getText";

const RecruitmentResponseSource = (props) => {
    const {
        source,
        isShowBoardNameText = true,
        isVisibleOnlyJobBoardName,
    } = props;

    if (!source) {
        return "-";
    }

    const {
        responseSource,
    } = source;

    const text = getRecruitmentResponseSourceText(source, {
        isShowBoardNameText,
        isVisibleOnlyJobBoardName,
    });

    return (
        <div className="flex align-items-center">
            <JobBoardLogo
                width={16}
                height={16}
                name={responseSource}
                className="me-1"
            />
            {text}
        </div>
    );
};

export default RecruitmentResponseSource;