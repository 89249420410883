import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

export const getValidationScheme = (isAdmin) => {
    if (isAdmin) {
        return yup.object().shape({
            name: yup.string()
                .max(100, "Допустимое количество символов 100")
                .required(VALIDATIONS_MESSAGE.REQUIRED),
            creatorClientUserId: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
            paymentMethod: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED),
        });
    }


    return yup.object().shape({
        name: yup.string()
            .max(100, "Допустимое количество символов 100")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        paymentMethod: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};