import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/specialities";
const controllerV2 = "v2/specialities";
//*  TYPES  *//

const GET_PAGE_SPECIALITIES_REQUEST = "GET_PAGE_SPECIALITIES_REQUEST";
const GET_PAGE_SPECIALITIES_SUCCESS = "GET_PAGE_SPECIALITIES_SUCCESS";
const GET_PAGE_SPECIALITIES_ERROR = "GET_PAGE_SPECIALITIES_ERROR";

const GET_ALL_SPECIALITIES_V2_REQUEST = "GET_ALL_SPECIALITIES_V2_REQUEST";
const GET_ALL_SPECIALITIES_V2_SUCCESS = "GET_ALL_SPECIALITIES_V2_SUCCESS";
const GET_ALL_SPECIALITIES_V2_ERROR = "GET_ALL_SPECIALITIES_V2_ERROR";

const ADD_SPECIALITIES_REQUEST = "ADD_SPECIALITIES_REQUEST";
const ADD_SPECIALITIES_SUCCESS = "ADD_SPECIALITIES_SUCCESS";
const ADD_SPECIALITIES_ERROR = "ADD_SPECIALITIES_ERROR";

const UPDATE_SPECIALITIES_REQUEST = "UPDATE_SPECIALITIES_REQUEST";
const UPDATE_SPECIALITIES_SUCCESS = "UPDATE_SPECIALITIES_SUCCESS";
const UPDATE_SPECIALITIES_ERROR = "UPDATE_SPECIALITIES_ERROR";

const DELETE_SPECIALITIES_REQUEST = "DELETE_SPECIALITIES_REQUEST";
const DELETE_SPECIALITIES_SUCCESS = "DELETE_SPECIALITIES_SUCCESS";
const DELETE_SPECIALITIES_ERROR = "DELETE_SPECIALITIES_ERROR";

const FIND_SPECIALITIES_BY_CLIENT_ID_V2_REQUEST = "FIND_SPECIALITIES_BY_CLIENT_ID_V2_REQUEST";
const FIND_SPECIALITIES_BY_CLIENT_ID_V2_SUCCESS = "FIND_SPECIALITIES_BY_CLIENT_ID_V2_SUCCESS";
const FIND_SPECIALITIES_BY_CLIENT_ID_V2_ERROR = "FIND_SPECIALITIES_BY_CLIENT_ID_V2_ERROR";

const SPECIALITIES_UPDATE_STORE = "SPECIALITIES_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    pageData: {},
    progressPage: false,
    progressAction: false,
    totalCount: 0,
    list: [],
    allList: [],
    allListV2: [],
    clientSpecialitiesV2: [],
    clientVisibilitySpecialities: [],
    progressAllList: false,
    progressAllListV2: false,
    isSpecialitiesLoaded: false,
    progressSpecialitiesByClientId: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case FIND_SPECIALITIES_BY_CLIENT_ID_V2_REQUEST: {
        return {
            ...state,
            progressSpecialitiesByClientId: true,
        };
    }
    case FIND_SPECIALITIES_BY_CLIENT_ID_V2_SUCCESS:
        return {
            ...state,
            clientSpecialitiesV2: payload,
            progressSpecialitiesByClientId: false,
        };
    case FIND_SPECIALITIES_BY_CLIENT_ID_V2_ERROR:
        return {
            ...state,
            progressSpecialitiesByClientId: false,
        };
    case GET_PAGE_SPECIALITIES_REQUEST:
        return {
            ...state,
            pageData: payload,
            progressPage: true,
        };
    case GET_ALL_SPECIALITIES_V2_REQUEST:
        return {
            ...state,
            progressAllListV2: true,
        };
    case GET_PAGE_SPECIALITIES_SUCCESS:
        const {
            results: list,
            totalCount,
        } = payload;

        return {
            ...state,
            progressPage: false,
            totalCount,
            list,
        };
    case GET_ALL_SPECIALITIES_V2_SUCCESS:
        const {
            clientIdVisibilityQualifier,
            result,
        } = payload;


        if (clientIdVisibilityQualifier) {
            return {
                ...state,
                clientVisibilitySpecialities: result,
                progressAllListV2: false,
            };
        }

        return {
            ...state,
            allListV2: result,
            progressAllListV2: false,
        };
    case GET_PAGE_SPECIALITIES_ERROR:
        return {
            ...state,
            progressPage: false,
            error: payload,
        };
    case UPDATE_SPECIALITIES_REQUEST:
    case ADD_SPECIALITIES_REQUEST:
    case DELETE_SPECIALITIES_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case UPDATE_SPECIALITIES_SUCCESS:
    case ADD_SPECIALITIES_SUCCESS:
    case DELETE_SPECIALITIES_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case UPDATE_SPECIALITIES_ERROR:
    case ADD_SPECIALITIES_ERROR:
    case DELETE_SPECIALITIES_ERROR:
        return {
            ...state,
            progressAction: false,
            error: payload,
        };
    case GET_ALL_SPECIALITIES_V2_ERROR:
        return {
            ...state,
            progressAllListV2: false,
        };
    case SPECIALITIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//

export function getSpecialitiesPage(payload) {
    return {
        type: GET_PAGE_SPECIALITIES_REQUEST,
        payload,
    };
}

export function getSpecialitiesAllV2(payload) {
    return {
        type: GET_ALL_SPECIALITIES_V2_REQUEST,
        payload,
    };
}

export function updateSpecialities(payload) {
    return {
        type: UPDATE_SPECIALITIES_REQUEST,
        payload,
    };
}

export function addSpecialities(payload) {
    return {
        type: ADD_SPECIALITIES_REQUEST,
        payload,
    };
}

export function deleteSpecialities(payload) {
    return {
        type: DELETE_SPECIALITIES_REQUEST,
        payload,
    };
}

export function findSpecialitiesByClientId(payload) {
    return {
        type: FIND_SPECIALITIES_BY_CLIENT_ID_V2_REQUEST,
        payload,
    };
}

export const updateSpecialitiesStore = (payload) => ({
    type: SPECIALITIES_UPDATE_STORE,
    payload,
});

//*  SELECTORS  *//
export const specialitiesSelector = state => state.speciality;
export const specialitiesPageDataSelector = createSelector(specialitiesSelector, ({pageData}) => pageData);
export const getSpecialitiesTotalPagesSelector = createSelector(specialitiesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getSpecialitiesTotalCountSelector = createSelector(specialitiesSelector, ({totalCount}) => totalCount);
export const getSpecialitiesListSelector = createSelector(specialitiesSelector, ({list}) => list);
export const specialitiesProgressPageSelector = createSelector(specialitiesSelector, ({progressPage}) => progressPage);
export const specialitiesProgressActionSelector = createSelector(specialitiesSelector, ({progressAction}) => progressAction);
export const specialitiesAllListV2Selector = createSelector(specialitiesSelector, ({allListV2}) => allListV2);

const formatSpecialityOption = (item) => {
    return {
        key: item.id,
        value: item.id,
        text: item.value,
    };
};

export const specialitiesClientVisibilityAllListV2Selector = createSelector(
    specialitiesSelector,
    ({clientVisibilitySpecialities}) => {
        return clientVisibilitySpecialities.map(formatSpecialityOption);
    },
);
export const specialitiesProgressAllListSelector = createSelector(specialitiesSelector, ({progressAllList}) => progressAllList);
export const specialitiesProgressAllListV2Selector = createSelector(specialitiesSelector, ({progressAllListV2}) => progressAllListV2);
export const specialitiesOptionsSelector = createSelector(specialitiesSelector, state => {
    return state.list.map(item => ({
        key: item.id,
        value: item.id,
        text: item.value,
    }));
},
);

export const specialitiesAllV2OptionsSelector = createSelector(
    specialitiesSelector,
    ({allListV2}) => {
        return allListV2.map(formatSpecialityOption);
    },
);

export const specialitiesAllV2DictSelector = createSelector(specialitiesSelector, ({allListV2}) => {
    return allListV2.reduce((result, {id, value}) => {
        result.set(id, value);
        return result;
    }, new Map());
},
);

// Список ВД клиентов
export const specialitiesClientV2Selector = createSelector(specialitiesSelector, ({clientSpecialitiesV2}) => clientSpecialitiesV2);
export const specialitiesClientOptionsV2Selector = createSelector(specialitiesSelector, ({clientSpecialitiesV2}) => {
    return clientSpecialitiesV2.map(item => ({
        key: item.id,
        value: item.id,
        text: item.value,
    }));
});

export const specialitiesProgressByClientIdSelector = createSelector(specialitiesSelector, ({progressSpecialitiesByClientId}) => progressSpecialitiesByClientId);
//*  SAGA  *//

export const getSpecialitiesPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controllerV2}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_SPECIALITIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_PAGE_SPECIALITIES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_PAGE_SPECIALITIES_ERROR, payload: error});
    }
};

export const getSpecialitiesAllV2Saga = function* ({payload = {}}) {
    try {
        const result = yield request.get(`${controllerV2}/all`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ALL_SPECIALITIES_V2_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ALL_SPECIALITIES_V2_SUCCESS,
            payload: {
                result,
                ...payload,
            },
        });
    } catch (error) {
        yield put({type: GET_ALL_SPECIALITIES_V2_ERROR, payload: error});
    }
};

export const addSpecialitiesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controllerV2}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ADD_SPECIALITIES_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        const {value} = payload;
        toastSuccess(`Вид деятельности "${value}" успешно добавлен`);
        onSuccess();

        yield put({type: ADD_SPECIALITIES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_SPECIALITIES_ERROR, payload: error});
    }
};

export const updateSpecialitiesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;
        const {id} = payload;
        const result = yield request.patch(`${controllerV2}/${id}`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_SPECIALITIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }
        toastSuccess("Изменения успешно сохранены");

        yield put({
            type: UPDATE_SPECIALITIES_SUCCESS,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: UPDATE_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

export const deleteSpecialitiesSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            id,
        } = payload;

        const result = yield request.delete(`${controllerV2}/${id}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DELETE_SPECIALITIES_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        toastSuccess("Вид деятельности успешно удален");
        onSuccess();

        yield put({type: DELETE_SPECIALITIES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_SPECIALITIES_ERROR, payload: error});
    }
};


export const findSpecialitiesByClientIdSaga = function* ({payload}) {
    try {
        const {
            clientId,
            filterBySetting = false,
        } = payload;
        const result = yield request.get(`${controllerV2}/findByClientId?clientId=${clientId}&filterBySetting=${filterBySetting}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FIND_SPECIALITIES_BY_CLIENT_ID_V2_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: FIND_SPECIALITIES_BY_CLIENT_ID_V2_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: FIND_SPECIALITIES_BY_CLIENT_ID_V2_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_SPECIALITIES_REQUEST, getSpecialitiesPageSaga),
        takeEvery(UPDATE_SPECIALITIES_REQUEST, updateSpecialitiesSaga),
        takeEvery(DELETE_SPECIALITIES_REQUEST, deleteSpecialitiesSaga),
        takeEvery(ADD_SPECIALITIES_REQUEST, addSpecialitiesSaga),
        takeEvery(GET_ALL_SPECIALITIES_V2_REQUEST, getSpecialitiesAllV2Saga),
        takeEvery(FIND_SPECIALITIES_BY_CLIENT_ID_V2_REQUEST, findSpecialitiesByClientIdSaga),
    ]);
}
