import * as yup from "yup";

import {getFullRegistrationIssuedDateValidation} from "../../../../components/FullRegistration/utils/validations/issuedDate";
import {getFullRegistrationValidToDateValidation} from "../../../../components/FullRegistration/utils/validations/validToDate";
import {
    addYears,
    calculateAge,
    getStartDate,
} from "../../../../utils/dateFormat";
import {
    clearSpace,
    removePhoneMask,
} from "../../../../utils/stringFormat";
import {
    checkInnFormat,
} from "../../../../utils/validate";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTOR_FIELD, EDM_PASSPORT_TYPE} from "../../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../../constants/contractorInfo";
import {
    departamentCodeReg,
    einReg,
    emailReg,
    passportNumberReg,
} from "../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        [CONTRACTOR_FIELD.LAST_NAME]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.FIRST_NAME]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.GENDER]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.EMAIL]: yup.string()
            .max(255, "Не более 255 символов")
            .matches(emailReg, {message: VALIDATIONS_MESSAGE.INCORRECT_EMAIL, excludeEmptyString: false}),
        [CONTRACTOR_FIELD.BIRTH_DATE]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable()
            .test("checkAge", "Данный сервис доступен для лиц старше 14 лет", function (value) {
                const userAges = calculateAge(value);

                return userAges >= BIRTH_DATE_LIMIT_FROM;
            }),
        [CONTRACTOR_FIELD.PHONE]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("checkPhone", "Номер телефона должен состоять из 11 цифр", function (value) {
                const mapValue = removePhoneMask(value);

                return mapValue.length === 11;


            }),
        [CONTRACTOR_FIELD.ADDRESS]: yup.string()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: value => ([citizenshipsDict.RU.value].includes(value)),
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(5, "Минимальная длина строки 5 символов"),
            }),
        [CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS]: yup.string()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.KG.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(5, "Минимальная длина строки 5 символов"),
            }),
        [CONTRACTOR_FIELD.INN]: yup.string()
            .when(CONTRACTOR_FIELD.ADDED_VIA_ADMIN_UI, {
                is: true,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .min(12, "Введите ИНН полностью")
                    .test("inn", "Неверный формат ИНН", function (value) {
                        if (!value) {
                            return true;
                        }

                        const mapValue = clearSpace(value);

                        return !checkInnFormat(mapValue);
                    }),
            }),

        [CONTRACTOR_FIELD.EIN]: yup.string()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.TJ.value,
                then: yup.string()
                    .when(CONTRACTOR_FIELD.EDM_PASSPORT_TYPE, {
                        is: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
                        then: yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED)
                            .matches(einReg, {message: "Ввод цифр и латинских букв", excludeEmptyString: false})
                            .length(13, "Допустимое количество символов 13"),
                    }),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.BY.value,
                then: yup.string()
                    .matches(einReg, "Ввод цифр и латинских букв")
                    .test("ein", "Допустимое количество символов 12 или 14", function (value) {
                        if (!value) {
                            return true;
                        }

                        return [12, 14].includes(value?.length);
                    }),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.KG.value,
                then: yup.string()
                    .matches(einReg, "Ввод цифр и латинских букв")
                    .test("ein", "Допустимое количество символов от 12 до 14", function (value) {
                        if (!value) {
                            return true;
                        }

                        return [12, 13, 14].includes(value?.length);
                    }),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.KG.value,
                then: yup.string()
                    .matches(einReg, "Ввод цифр и латинских букв")
                    .test("ein", "Допустимое количество символов от 12 до 14", function (value) {
                        if (!value) {
                            return true;
                        }

                        return [12, 13, 14].includes(value?.length);
                    }),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.KZ.value,
                then: yup.string()
                    .matches(einReg, "Ввод цифр и латинских букв")
                    .test("ein", "Допустимое количество символов 12 или 14", function (value) {
                        if (!value) {
                            return true;
                        }

                        return [12, 14].includes(value?.length);
                    }),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.UA.value,
                then: yup.string()
                    .matches(einReg, "Ввод цифр и латинских букв")
                    .length(13, "Допустимое количество символов 13"),
            }),
        [CONTRACTOR_FIELD.ID_DOC_NUMBER]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.UA.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .when(CONTRACTOR_FIELD.EDM_PASSPORT_TYPE, {
                        is: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
                        then: yup.string()
                            .length(9, "Допустимое количество символов 9"),
                        otherwise: yup.string()
                            .length(8, "Допустимое количество символов 8"),
                    }),

            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.KZ.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .length(9, "Допустимое количество символов 9")
                    .when(CONTRACTOR_FIELD.PASSPORT_TYPE, {
                        is: CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT,
                        then: yup.string(),
                        otherwise: yup.string()
                            .matches(passportNumberReg, "Ввод только латинских букв и цифр"),
                    }),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.RU.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .length(11, "Допустимое количество символов 10"),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: value => [
                    citizenshipsDict.BY.value,
                    citizenshipsDict.KG.value,
                    citizenshipsDict.AM.value,
                ].includes(value),
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .length(9, "Допустимое количество символов 9")
                    .matches(passportNumberReg, "Ввод только латинских букв и цифр"),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.TJ.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(9, "Не более 9-и символов")
                    .min(1, "Минимальная длина номера 1 символ"),
            })
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.UZ.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .max(50, "Не более 9-и символов")
                    .min(1, "Минимальная длина номера 1 символ"),
            }),
        [CONTRACTOR_FIELD.CITIZENSHIP]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]: yup.string()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: citizenshipsDict.RU.value,
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .matches(departamentCodeReg, "Неверный формат кода подразделения")
                    .length(7, "Допустимое количество символов 6"),
            }),
        [CONTRACTOR_FIELD.BIRTH_PLACE]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов")
            .min(3, "Минимальная длина строки 3 символов"),
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: getFullRegistrationIssuedDateValidation()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов"),
        [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: getFullRegistrationValidToDateValidation()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        [CONTRACTOR_FIELD.PASSPORT_TYPE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: value => [
                    citizenshipsDict.KG.value,
                    citizenshipsDict.KZ.value,
                ].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        [CONTRACTOR_FIELD.EDM_PASSPORT_TYPE]: yup.string()
            .nullable()
            .when(CONTRACTOR_FIELD.CITIZENSHIP, {
                is: value => [citizenshipsDict.UA.value, citizenshipsDict.TJ.value].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
    });
};

export default validationSchema;

const passportDateLimitationBY = new Date(2012, 6, 19); //Дата выдачи новых образцов паспортов в Беларуси

function dateComparison({passportDate, passportDateEnd, numberYears}) {
    const result = addYears(passportDate, numberYears);
    const leftPartEquation = getStartDate(result);
    const rightPartEquation = getStartDate(new Date(passportDateEnd));

    return leftPartEquation.getTime() === rightPartEquation.getTime();
}

const AGES_FOR_EDM_STATUS = {
    FOUR: 4,
    TEN: 10,
};

const BIRTH_DATE_LIMIT_FROM = 14;

const BIRTH_DATE_LIMIT_TO_KG = 55;

export const USER_AGE_LIMIT_RU = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 20,
    THIRD_LIMIT: 45,
};

const USER_AGE_LIMIT_BY = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 25,
    THIRD_LIMIT: 45,
};

const USER_AGE_LIMIT_UA = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 16,
    THIRD_LIMIT: 18,
};

const USER_AGE_LIMIT_UZ = {
    FIRST_LIMIT: 14,
    SECOND_LIMIT: 16,
};

const USER_AGE_LIMIT_KZ = 16;
const USER_AGE_LIMIT_KG = 16;
const USER_AGE_LIMIT_TJ = 16;

const YEARS_FOR_CHECK_PASSPORT_DATE_END = {
    UA: 10,
    BY: 10,
    TJ: 10,
    AM: {
        FIRST_CHECK: 10,
        SECOND_CHECK: 15,
    },
    KZ: 10,
    KG: 10,
    UZ: {
        FIRST_CHECK: 5,
        SECOND_CHECK: 10,
    },
};