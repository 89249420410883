import {isEmpty} from "lodash";

export const getSavedAdditionalDocumentsDecisions = (fileDecisions) => {
    if (isEmpty(fileDecisions)) {
        return {};
    }

    return Object.keys(fileDecisions).map((fileType) => {
        return {
            [fileType]: {
                decisionType: fileDecisions[fileType],
                fileType,
            },
        };
    }, {});
};