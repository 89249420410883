import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
// import "./style.sass";
import NmBadge from "../../../../components/NmBadge";
import ContractorProfessionalSkillsEditModal from "./components/сontractor-professional-skills-edit-modal";

import {
    contractorKeySkillsSelector,
    deleteContractorCourseCertificate,
    mergeContractorProfessionalSkills,
} from "../../../../ducks/contractorProfile";

const CONTRACTOR_PROFESSIONAL_SKILLS_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

const CONTRACTOR_PROFESSIONAL_SKILLS_OPTIONS = [
    {
        key: CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.EDIT,
        text: "Редактировать",
        value: CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.EDIT,
    },
    {
        key: CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.REMOVE,
        text: "Удалить",
        value: CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.REMOVE,
    },
];

export default function ContractorProfessionalSkills(props) {
    const {
        className,
        contractorId,
        getProfile,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isShowConfirm, setShowConfirm] = useState(false);
    const [editData, setEditData] = useState({});
    const {onConfirm, confirmContent} = confirmData;

    const contractorKeySkills = useSelector(contractorKeySkillsSelector);

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
        setShowConfirm(false);
    };

    const deleteElem = () => {
        dispatch(mergeContractorProfessionalSkills({
            contractorId,
            names: [],
            getResult: () => {
                handleCloseConfirm();
                getProfile();
            },
        }));
    };

    const onClickAction = ({value}) => {
        if (value === CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.EDIT) {
            toggleEditModal({names: [...contractorKeySkills]});
            return;
        }

        if (value === CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: () => {
                    deleteElem();
                },
                confirmContent: "Вы уверены, что хотите удалить сведения?" ,
            });
            setShowConfirm(true);
        }
    };

    function mapContent() {
        if (!contractorKeySkills?.length) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return <div className="d-flex flex-wrap">
            {contractorKeySkills.map(
                item => {
                    return (
                        <NmBadge
                            maxContent
                            className="ms-xl-1 mt-xl-1"
                            mod="gray"
                            text={item}
                        />
                    );
                },
            )}
        </div>;
    }

    return (
        <>
            {
                isShowConfirm && <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            {isShowEditModal && <ContractorProfessionalSkillsEditModal
                onClose={() => toggleEditModal()}
                getProfile={getProfile}
                contractorId={contractorId}
                editData={editData}
            />}
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Профессиональные навыки"
                onClickAdd={!contractorKeySkills?.length ? () => onClickAction({value: CONTRACTOR_PROFESSIONAL_SKILLS_OPTION.EDIT}) : null}
                options={contractorKeySkills?.length ? CONTRACTOR_PROFESSIONAL_SKILLS_OPTIONS : null}
                onClickItem={onClickAction}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}