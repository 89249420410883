import React from "react";

import bem from "../../utils/bem";

import PropTypes from "prop-types";

import "./style.sass";

const NmIcon = props => {
    const {
        name,
        className = "",
        onClick,
    } = props;
    const [block] = bem("nm-icon", className);

    return (
        <div
            onClick={onClick}
            className={block({name})}
        />
    );
};

NmIcon.propTypes = {
    name: PropTypes.oneOf([
        "close",
        "download",
        "green-plus",
        "green-check",
        "question",
        "semi-hamburger",
        "blocked",
        "blacklist",
        "filter",
        "blacklist-checked",
        "favorite",
        "favorite-checked",
        "blacklisted",
        "cross",
        "lock",
        "lock-red",
        "lock-out",
        "dispute",
        "history-icon",
        "plus-green-bg",
        "sberbank",
        "excel",
        "like",
        "dislike",
        "search",
        "canceled-checks-indicator",
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func,
};

NmIcon.defaultProps = {};

export default NmIcon;