import {
    BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_REQUEST,
    BFF_SETTINGS_INSTRUCTIONS_KEDO_UPDATE_STORE,
} from "./actions";

export const saveKedoInstruction = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_KEDO_SAVE_REQUEST,
        payload,
    };
};

export const addKedoInstructionFile = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_KEDO_ADD_FILE_REQUEST,
        payload,
    };
};

export const getKedoInstructions = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_KEDO_GET_LIST_REQUEST,
        payload,
    };
};

export const updateKedoInstructionStore = (payload) => {
    return {
        type: BFF_SETTINGS_INSTRUCTIONS_KEDO_UPDATE_STORE,
        payload,
    };
};