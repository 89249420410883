import React from "react";
import {useSelector} from "react-redux";

import Text from "../../../../components/ActualComponents/Text";
import NmButton from "../../../../components/NmButton";

import {useDadataInnCheck} from "../../../client-list/components/new/inn/hooks/useDadataInnCheck";

import bem from "../../../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {getClientCardSelector} from "../../../../ducks/client";

import "./style.sass";

const ClientInfoInnCheck = ({clientId}) => {
    const [block ] = bem("client-info-inn-check");

    const client = useSelector(getClientCardSelector);
    const {
        inn,
        lastActualizeDadataDate,
        clientType,
    } = client;

    const {
        onUpdate,
        progress,
    } = useDadataInnCheck({
        inn,
        clientType,
        clientId,
    });

    const updateDate = lastActualizeDadataDate ? formatDate(convertUtcToLocal(lastActualizeDadataDate), "dd.MM.yyyy HH:mm") : "-";

    return (
        <div className={block()}>
            <NmButton
                size="xl"
                color="green"
                disabled={progress}
                onClick={onUpdate}
            >
                Проверить данные
            </NmButton>
            <Text
                className="mt-3"
                level="2"
            >
                {`Последняя проверка: ${updateDate}`}
            </Text>
        </div>
    );
};

export default ClientInfoInnCheck;