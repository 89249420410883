import {
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST,
    BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST,
    CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTORS,
    CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE,
    UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE,
} from "./actions";

export const archiveRegistryOfAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ARCHIVE_REQUEST,
        payload,
    };
};

export const getRegistryOfAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_GET_REQUEST,
        payload,
    };
};

export const updateRegistryOfAdditionalAgreementStore = (payload) => {
    return {
        type: UPDATE_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE,
        payload,
    };
};

export const getRegistryOfAdditionalAgreementStatusDict = () => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_DICT_REQUEST,
    };
};

export const getRegistryOfAdditionalAgreementItemsPage = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_PAGE_REQUEST,
        payload,
    };
};

export const submitRegistryOfAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_SUBMIT_REQUEST,
        payload,
    };
};

export const cancelAllAdditionalAgreements = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CANCEL_REQUEST,
        payload,
    };
};

export const cancelAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_CANCEL_REQUEST,
        payload,
    };
};

export const deleteAdditionalAgreements = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_DELETE_REQUEST,
        payload,
    };
};

export const getAdditionalAgreementWarnings = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_WARNINGS_REQUEST,
        payload,
    };
};

export const addItemToAdditionalAgreementRegistry = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_ITEMS_ADD_REQUEST,
        payload,
    };
};

export const getAdditionalAgreementContractorCandidates = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTOR_CANDIDATES_REQUEST,
        payload,
    };
};

export const getAdditionalAgreementFrameContractorCandidates = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_FRAME_CONTRACTOR_CANDIDATES_REQUEST,
        payload,
    };
};

export const resubmitAdditionalAgreement = (payload) => {
    return {
        type: BFF_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_RESUBMIT_REQUEST,
        payload,
    };
};

export const clearRegistryOfAdditionalAgreementStore = () => {
    return {
        type: CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_STORE,
    };
};

export const clearDocumentsAdditionalAgreementContractors = () => {
    return {
        type: CLEAR_DOCUMENTS_ADDITIONAL_AGREEMENT_REGISTRY_CONTRACTORS,
    };
};