import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";

const controller = "adm/recruitment/access-control/observer";

// PUT /bff/adm/recruitment/access-control/observer/{id}
// Редактировать наблюдателя
const updateRecruitmentAccessControlObserver = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${requestData.id}`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Список вакансий наблюдателя успешно обновлен");

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/access-control/observer/search
// Получить список наблюдателей
const getRecruitmentAccessControlObserverList = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/access-control/observer/vacancies/getPage
// Получить список вакансий для наблюдателя
const getRecruitmentAccessControlVacancies = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/vacancies/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/access-control/observer
// Добавить наблюдателя
const addRecruitmentAccessControlObserver = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Список вакансий наблюдателя успешно обновлен");

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_UPDATE_REQUEST, updateRecruitmentAccessControlObserver),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_SEARCH_REQUEST, getRecruitmentAccessControlObserverList),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_VACANCIES_GET_PAGE_REQUEST, getRecruitmentAccessControlVacancies),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_OBSERVER_ADD_REQUEST, addRecruitmentAccessControlObserver),
    ]);
}