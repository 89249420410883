import React from "react";

import ApplyButtons, {TApplyButtonsAlign, TApplyButtonsMobile} from "../../ApplyButtons";
import {TNmButtonColor} from "../../NmButton";
import NmTitle, {TNmTitleAlign} from "../../NmTitle";
import NmModal, {TModalContentAlign, TModalSize} from "../NmModal";
import {TTextLevel} from "../Text";
import NmConfirmV2Content from "./Content";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmConfirm {
    onCancel: (event?: any) => void,
    onConfirm?: (event?: any) => void,
    onClickAdditional?: (event?: any) => void,
    title?: string | React.ReactNode,
    content?: string | React.ReactNode,
    confirmButton?: string,
    cancelButton?: string,
    additionalBtnContent?: string,
    warning?: boolean,
    isOnlyConfirm?: boolean,
    disabled?: boolean,
    loading?: boolean,
    titleAlign?: TNmTitleAlign,
    contentAlign?: TModalContentAlign,
    buttonsAlign?: TApplyButtonsAlign,
    size?: TModalSize,
    className?: string,
    isNeedClosing?: boolean,
    formId?: string,
    inline?: boolean,
    classNameContent?: string,
    isHiddenCancelOnMobile?: boolean,
    contentTextLevel?: TTextLevel,
    mobile?: TApplyButtonsMobile,
    cancelBtnColor?: TNmButtonColor,
    onClose?: () => void,
    isVisibleCloseButton?: boolean,
    zIndex?: number,
}

const NmConfirmV2 = (props: INmConfirm) => {
    const {
        confirmButton,
        cancelButton,
        onCancel,
        disabled,
        title,
        content,
        warning,
        className = "",
        titleAlign = "center",
        contentAlign = "center",
        buttonsAlign = "center",
        size = "md",
        inline,
        isNeedClosing = true,
        onConfirm,
        loading,
        isOnlyConfirm,
        formId,
        classNameContent,
        isHiddenCancelOnMobile,
        contentTextLevel,
        mobile,
        cancelBtnColor,
        additionalBtnContent,
        onClickAdditional,
        isVisibleCloseButton,
        zIndex,
    } = props;

    const [block] = bem("nm-confirm-v2", className);

    const submit = () => {
        if (isOnlyConfirm && onConfirm) {
            return onConfirm();
        }

        if (onConfirm) {
            onConfirm();
        }

        isNeedClosing && onCancel();
    };

    const onClose = () => {
        if (props.onClose) {
            props.onClose();

            return;
        }

        onCancel();
    };

    return (
        <NmModal
            zIndex={zIndex}
            size={size}
            className={block()}
            onClose={onClose}
            contentAlign={contentAlign}
            header={
                title &&
                <NmTitle
                    align={titleAlign}
                    size="lg"
                >
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile={mobile}
                    formId={formId}
                    align={buttonsAlign}
                    submitBtnContent={confirmButton}
                    cancelBtnContent={cancelButton}
                    additionalBtnContent={additionalBtnContent}
                    isHiddenCancelOnMobile={isHiddenCancelOnMobile}
                    onClose={onCancel}
                    submit={submit}
                    onClickAdditional={onClickAdditional}
                    loading={loading}
                    disabled={disabled}
                    cancelBtnColor={cancelBtnColor}
                />
            }
            isVisibleCloseButton={isVisibleCloseButton}
        >
            <NmConfirmV2Content
                contentTextLevel={contentTextLevel}
                className={classNameContent}
                align={contentAlign}
                warning={warning}
                content={content}
                inline={inline}
            />
        </NmModal>
    );
};

export default NmConfirmV2;