import React from "react";

import HelpTooltip from "../../ActualComponents/HelpTooltip";
import ExtLink from "../../ExtLink";

import {LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO} from "../../../constants/links";

export const RecruitmentCandidateAvailableIndicator = (props) => {
    const {
        candidateId,
        candidateName,
        similarCandidate,
        clientId,
    } = props;

    const tooltip = similarCandidate &&
        <HelpTooltip
            attention
            hover
            width={18}
            height={18}
            className="ms-1"
            text="По отклику найден похожий кандидат"
        />;

    if (!candidateId) {
        return (
            <div className="d-flex align-items-center">
                Нет
                {tooltip}
            </div>
        );
    }

    const link = LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO
        .replace(":id", candidateId)
        .replace(":clientId", clientId);

    return (
        <div className="d-flex align-items-center">
            <ExtLink
                historyEnabled
                to={link}
                title="Открыть карточку кандидата"
            >
                {candidateName || "ФИО не указано"}
            </ExtLink>
            {tooltip}
        </div>
    );
};