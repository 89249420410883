import {all, put, takeEvery} from "@redux-saga/core/effects";

import {DEPOSIT_SUB_PAGE} from "../../containers/deposit/client-list";
import {
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
    BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
    BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_ERROR,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_SUCCESS,
    BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_PAYMENTS_REJECT_ERROR,
    BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
    BFF_FINANCES_PAYMENTS_REJECT_SUCCESS,
} from "./actions";

import request from "../../utils/postman";
import {toastError} from "../../utils/toastHelper";
import {getBffUrl} from "../../utils/url";

import {
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
} from "../../constants/links";

const controllers = {
    client: {
        npd: "/adm/clients/npd-payments",
        ndlf: "/adm/clients/ndfl-payments",
        individual: "adm/clients/individual-payments",
    },
    admin: {
        npd: "/adm/npd-payments",
        ndlf: "/adm/ndfl-payments",
        individual: "adm/individual-payments",
    },
};

const links = {
    admin: {
        npd: LINK_FINANCE_PAYMENT_LIST,
        ndfl: LINK_FINANCE_NDFL_PAYMENT_LIST,
        individual: LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    },
    client: {
        npd: LINK_CLIENT_PAYMENTS_ORDERS_LIST,
        ndfl: LINK_CLIENT_NDFL_PAYMENTS_LIST,
        individual: LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
        depositCompletedPaymentsIndividual: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS)}/${DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR}`,
        depositPaymentsInProgressIndividual: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS)}/${DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR}`,
        depositPaymentsPendingIndividual: `${LINK_CLIENT_DEPOSIT_LIST
            .replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING)}/${DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR}`,
    },
};

const getController = (clientId) => {
    return getBffUrl(
        {
            [links.admin.npd]: controllers.admin.npd,
            [links.admin.ndfl]: controllers.admin.ndlf,
            [links.admin.individual]: controllers.admin.individual,
            [links.client.npd.replace(":clientId", clientId)]: controllers.client.npd,
            [links.client.ndfl.replace(":clientId", clientId)]: controllers.client.ndlf,
            [links.client.individual.replace(":clientId", clientId)]: controllers.client.individual,
            [links.client.depositCompletedPaymentsIndividual.replace(":clientId", clientId)]: controllers.client.individual,
            [links.client.depositPaymentsInProgressIndividual.replace(":clientId", clientId)]: controllers.client.individual,
            [links.client.depositPaymentsPendingIndividual.replace(":clientId", clientId)]: controllers.client.individual,
        },
        controllers.admin.npd,
    );
};

const getPageFinancesPayments = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController(payload.clientId)}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
        });
    }
};

const confirmFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/confirmPayment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
        });
    }
};

const rejectFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/rejectPayment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_PAYMENTS_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_REJECT_ERROR,
        });
    }
};

const createReceiptFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/createReceipt`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_ERROR,
        });
    }
};

const cancelReceiptFinancesPayments = function* ({payload}) {
    try {
        const {
            url,
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController(payload.clientId)}/cancelReceipt`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST, getPageFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST, confirmFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_REJECT_REQUEST, rejectFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_CREATE_RECEIPT_REQUEST, createReceiptFinancesPayments),
        takeEvery(BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST, cancelReceiptFinancesPayments),
    ]);
}