import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import TemplatesDropdown from "../../../../components/TemplatesDropdown";
import UnavailableTemplates from "../../../../components/UnavailableTemplates";
import {CREATE_ACTS_TYPE} from "../../registryActsType";

import useRegistryNewForm from "./hooks/useRegistryNewForm";

import {
    CURRENT_CLIENT_USER_ID,
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

import {UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE} from "../../../../components/UnavailableTemplates/constants";
import {COLOR} from "../../../../constants/color";
import {
    ORDER_AMOUNT_CALCULATION_METHOD,
    ORDER_WORK_REPORT_TYPE,
} from "../../../../constants/finance";
import {isUserFromNm} from "../../../../constants/roles";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../templates/constants";

import {bffClientsSettingsPaymentsSelector} from "../../../../ducks/bff/clients/settings/selectors";
import {clientMemberOptionsSelector} from "../../../../ducks/clientMember";
import {
    clientObjectListSelector,
    clientObjectOptionsSelector,
} from "../../../../ducks/clientObject";
import {getClientPropertiesCardSelector} from "../../../../ducks/clientProperties";
import {
    documentCustomTemplateAggregationShortTemplatesSelector,
    documentCustomTemplateProgressReplacementsForUnavailableSelector,
} from "../../../../ducks/documentsCustomTemplate";
import {registriesActionRegistryProgressSelector} from "../../../../ducks/registry";

import PropTypes from "prop-types";

import "./style.sass";

const RegistryNewPopup = (props) => {
    const {
        close,
        clientId,
        registryObj,
    } = props;

    const clientUserId = ls(CURRENT_CLIENT_USER_ID);
    const role = ls(USER_ROLE);
    const isNmAdmin = isUserFromNm(role);

    const {t} = useTranslation();
    const title = registryObj.registryId ? t("registries.edit-registry-form-header") : t("registries.add-registry-form-header");

    const actionRegistryProgress = useSelector(registriesActionRegistryProgressSelector);
    const progressReplacementsForUnavailable = useSelector(documentCustomTemplateProgressReplacementsForUnavailableSelector);
    const clientInfo = useSelector(getClientPropertiesCardSelector);
    const clientsSettingsPayments = useSelector(bffClientsSettingsPaymentsSelector);
    const clientMemberOptions = useSelector(clientMemberOptionsSelector);
    const objectOptions = useSelector(clientObjectOptionsSelector);
    const objectList = useSelector(clientObjectListSelector);
    const aggregationShortTemplates = useSelector(documentCustomTemplateAggregationShortTemplatesSelector);

    const {
        orderApplicationTemplateId: orderApplicationTemplatesOptions,
        actOfAcceptanceOfWorkTemplateId: actTemplatesOptions,
    } = aggregationShortTemplates;

    const {
        values,
        errors,
        isOpenUnavailableTemplates,
        handleSubmit,
        onSubmitEdit,
        onChangeRegistry,
        onCloseUnavailableTemplates,
        onSearchChangeObject,
        paymentMethodOptions,
    } = useRegistryNewForm({
        registryObj,
        clientInfo,
        isNmAdmin,
        objectList,
        clientUserId,
        clientId,
        clientsSettingsPayments,
    });

    return (
        <NmModal
            size="md"
            onClose={close}
            header={
                <NmTitle size="lg">
                    {title}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="column"
                    onClose={close}
                    submit={handleSubmit}
                    cancelBtnContent={t("button.cancel")}
                    loading={actionRegistryProgress || progressReplacementsForUnavailable}
                    disabled={actionRegistryProgress || progressReplacementsForUnavailable}
                    submitBtnContent={registryObj.registryId ? t("button.edit") : t("button.add")}
                />
            }
        >
            {
                isOpenUnavailableTemplates && <UnavailableTemplates
                    disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_PAYMENTS}
                    progress={actionRegistryProgress}
                    onClose={onCloseUnavailableTemplates}
                    onSubmit={() => {
                        onSubmitEdit(registryObj.registryId);
                    }}
                    header={title}
                />
            }
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    onChange={onChangeRegistry}
                    name="name"
                    required
                    error={errors.name ? errors.name : null}
                    value={values.name}
                    placeholder={t("registries.name-placeholder")}
                    label={t("registries.name")}
                />
                <NmTextareaV2
                    label={t("registries.comment")}
                    placeholder={t("registries.comment-placeholder")}
                    name="description"
                    minRows={3}
                    maxRows={7}
                    maxLength={255}
                    onChange={(e) => {
                        onChangeRegistry(e, {name: "description", value: e.target.value});
                    }}
                    value={values.description}
                />
                <NmDropdownV2
                    search
                    placeholder="Выберите объект"
                    label="Объект"
                    name="objectId"
                    required
                    onChange={onChangeRegistry}
                    onSearchChange={onSearchChangeObject}
                    value={values.objectId}
                    searchQuery={values.valueObjectFilter}
                    options={objectOptions}
                    error={errors.objectId ? errors.objectId : null}
                />
                {
                    isNmAdmin && <NmDropdownV2
                        search
                        label="Ответственный"
                        required
                        error={errors.creatorClientUserId ? errors.creatorClientUserId : null}
                        options={clientMemberOptions}
                        placeholder="Выберите ответственного"
                        value={values.creatorClientUserId}
                        name="creatorClientUserId"
                        onChange={onChangeRegistry}
                        size="xl"
                    />
                }
                <div className="registry-new-popup__deposit-type">
                    <Text
                        level="3"
                        className="mb-2"
                        color={COLOR.SECONDARY_100}
                    >
                        Выплаты по реестру будут осуществляться исполнителям
                    </Text>
                    <div className="registry-new-popup__row">
                        <NmRadioV2
                            label="НПД"
                            disabled={Boolean(registryObj.registryId) || !clientInfo.registryPaymentsAvailable}
                            className="registry-new-popup__radio-auto"
                            checked={values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ}
                            name="orderContractPaymentType"
                            value={ORDER_WORK_REPORT_TYPE.SMZ}
                            onChange={onChangeRegistry}
                        />
                        <NmRadioV2
                            label="НДФЛ"
                            className="registry-new-popup__radio"
                            disabled={Boolean(registryObj.registryId) || !clientInfo.civilRegistryPaymentsAvailable}
                            checked={values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL}
                            name="orderContractPaymentType"
                            value={ORDER_WORK_REPORT_TYPE.CIVIL}
                            onChange={onChangeRegistry}
                        />
                        {
                            clientInfo.individualRegistryPaymentsAvailable &&
                            <NmRadioV2
                                label="ИП"
                                className="registry-new-popup__radio"
                                disabled={Boolean(registryObj.registryId) || !clientInfo.individualRegistryPaymentsAvailable}
                                checked={values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                                name="orderContractPaymentType"
                                value={ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                                onChange={onChangeRegistry}
                            />
                        }
                    </div>
                </div>
                {
                    values.orderContractPaymentType !== ORDER_WORK_REPORT_TYPE.INDIVIDUAL &&
                    <NmDropdownV2
                        required
                        label="Способ проведения оплаты"
                        placeholder="Выберите способ проведения оплаты"
                        name="paymentMethod"
                        options={paymentMethodOptions}
                        value={values.paymentMethod}
                        onChange={onChangeRegistry}
                        error={errors.paymentMethod}
                    />
                }
                {
                    values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ &&
                    <div className="registry-new-popup__deposit-type">
                        <div className="registry-new-popup__radio-container">
                            <Text
                                level="3"
                                color={COLOR.SECONDARY_100}
                            >
                                Сумма на карту
                            </Text>
                            <HelpTooltip
                                info
                                className="registry-new-popup__tooltip"
                                text={"NET — Комиссия и налоги исполнителя за счет Заказчика.\nGROSS — Комиссия исполнителя за счет Заказчика, налоги включены в сумму выплаты. Если у исполнителя включена автоуплата налогов, 6% будет заморожено на счёте для последующей уплаты."}
                            />
                        </div>
                        <div className="registry-new-popup__row">
                            <NmRadioV2
                                disabled={Boolean(registryObj.registryId) || values.documentTablesEnabled}
                                label={"NET (\"на руки\" исполнителю)"}
                                className="registry-new-popup__radio-auto"
                                checked={values.orderAmountCalculationMethod === ORDER_AMOUNT_CALCULATION_METHOD.NET}
                                name="orderAmountCalculationMethod"
                                value={ORDER_AMOUNT_CALCULATION_METHOD.NET}
                                onChange={onChangeRegistry}
                            />
                            <NmRadioV2
                                disabled={Boolean(registryObj.registryId) || values.documentTablesEnabled}
                                label="GROSS без комиссии платформы"
                                className="registry-new-popup__radio"
                                checked={values.orderAmountCalculationMethod === ORDER_AMOUNT_CALCULATION_METHOD.GROSS}
                                name="orderAmountCalculationMethod"
                                value={ORDER_AMOUNT_CALCULATION_METHOD.GROSS}
                                onChange={onChangeRegistry}
                            />
                        </div>
                    </div>
                }
                <NmCheckboxV2
                    label={t("registry-new-popup.create-acts")}
                    checked={values.isCreateActs}
                    name="isCreateActs"
                    onChange={onChangeRegistry}
                />
                {
                    values.isCreateActs &&
                    <div className="registry-new-popup__row">
                        <NmRadioV2
                            label={t("registry-new-popup.automatically")}
                            className="registry-new-popup__radio-auto"
                            checked={values.createActsOfAcceptanceOfWorkType === CREATE_ACTS_TYPE.AUTOMATICALLY}
                            name="createActsOfAcceptanceOfWorkType"
                            value={CREATE_ACTS_TYPE.AUTOMATICALLY}
                            onChange={onChangeRegistry}
                        />
                        <div className="registry-new-popup__radio-container">
                            <NmRadioV2
                                className="registry-new-popup__radio"
                                label={t("registry-new-popup.with-confirmation")}
                                checked={values.createActsOfAcceptanceOfWorkType === CREATE_ACTS_TYPE.WITH_CONFIRMATION}
                                name="createActsOfAcceptanceOfWorkType"
                                value={CREATE_ACTS_TYPE.WITH_CONFIRMATION}
                                onChange={onChangeRegistry}
                            />
                            <HelpTooltip
                                info
                                className="registry-new-popup__tooltip"
                                text={t("registry-new-popup.tooltip")}
                            />
                        </div>
                    </div>
                }
                {
                    (
                        (clientInfo.documentTablesEnabled && values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ) ||
                        (clientInfo.civilDocumentTablesEnabled && values.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL)
                    ) && <NmCheckboxV2
                        disabled={Boolean(registryObj.registryId)}
                        label="Загрузить реестр с таблицей выполненных работ"
                        checked={values.documentTablesEnabled}
                        name="documentTablesEnabled"
                        onChange={onChangeRegistry}
                    />
                }
                <TemplatesDropdown
                    disabled={!clientInfo.customDocumentTemplatesAvailable}
                    className="mb-3 mb-md-4"
                    clientIdFilter={clientId}
                    isFetch={false}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ORDER_APPLICATION}
                    buttonText="Скачать заявку"
                    placeholder="Выберите шаблон заявки"
                    label="Шаблон заявки"
                    name="orderApplicationTemplateId"
                    onChange={onChangeRegistry}
                    options={orderApplicationTemplatesOptions}
                    value={values.orderApplicationTemplateId}
                    size="xl"
                />
                <TemplatesDropdown
                    disabled={!clientInfo.customDocumentTemplatesAvailable}
                    className="mb-3 mb-md-4"
                    clientIdFilter={clientId}
                    isFetch={false}
                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                    buttonText="Скачать акт"
                    placeholder="Выберите шаблон акта"
                    label="Шаблон акта"
                    name="actOfAcceptanceOfWorkTemplateId"
                    onChange={onChangeRegistry}
                    options={actTemplatesOptions}
                    value={values.actOfAcceptanceOfWorkTemplateId}
                    size="xl"
                />
            </NmForm>
        </NmModal>
    );
};

RegistryNewPopup.propTypes = {
    registryObj: PropTypes.object,
    clientId: PropTypes.string,
    close: PropTypes.func,
};

RegistryNewPopup.defaultProps = {
    registryObj: {},
    clientId: "",
    close: () => {
    },
};

export default RegistryNewPopup;