import React, {FC} from "react";

import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import {ReactComponent as InfoOutline} from "../../../images/info_outline.svg";
import Text from "../Text";

import {getClassNames} from "../../../utils/classNames";

import {COLOR} from "../../../constants/color";

interface InfoMarkProps {
    text: string,
    isAttention?: boolean,
    isOutline?: boolean,
    className?: string,
}

export const InfoMark: FC<InfoMarkProps> = (props) => {
    const {
        text,
        isAttention,
        isOutline,
        className,
    } = props;

    const getIcon = () => {
        const Icon = isOutline ? InfoOutline : InfoIcon;

        const props = {
            width: 20,
            height: 20,
            style: {minWidth: 20},
            color: isAttention ? COLOR.INERT_100 : COLOR.SECONDARY_100,
            className: "me-2",
        };

        return <Icon {...props} />;
    };

    return (
        <div
            className={getClassNames([
                "d-flex align-items-start",
                className,
            ])}
        >
            {getIcon()}
            <Text level="3">
                {text}
            </Text>
        </div>
    );
};