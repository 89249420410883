import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/contractorpushnotification";
//*  TYPES  *//

const ADD_CONTRACTOR_PUSH_NOTIFICATION_REQUEST = "ADD_CONTRACTOR_PUSH_NOTIFICATION_REQUEST";
const ADD_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS = "ADD_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS";
const ADD_CONTRACTOR_PUSH_NOTIFICATION_ERROR = "ADD_CONTRACTOR_PUSH_NOTIFICATION_ERROR";

const GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_REQUEST = "GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_REQUEST";
const GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_SUCCESS = "GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_SUCCESS";
const GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_ERROR = "GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_ERROR";

const CANCEL_CONTRACTOR_PUSH_NOTIFICATION_REQUEST = "CANCEL_CONTRACTOR_PUSH_NOTIFICATION_REQUEST";
const CANCEL_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS = "CANCEL_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS";
const CANCEL_CONTRACTOR_PUSH_NOTIFICATION_ERROR = "CANCEL_CONTRACTOR_PUSH_NOTIFICATION_ERROR";

const RETRY_CONTRACTOR_PUSH_NOTIFICATION_REQUEST = "RETRY_CONTRACTOR_PUSH_NOTIFICATION_REQUEST";
const RETRY_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS = "RETRY_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS";
const RETRY_CONTRACTOR_PUSH_NOTIFICATION_ERROR = "RETRY_CONTRACTOR_PUSH_NOTIFICATION_ERROR";

const PUSH_NOTIFICATION_UPDATE_FIELD_STORE = "PUSH_NOTIFICATION_UPDATE_FIELD_STORE";

const PUSH_NOTIFICATION_CLEAR_STORE = "PUSH_NOTIFICATION_CLEAR_STORE";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    list: [],
    pageData: {},
    totalCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_SUCCESS:
        const {results: list, totalCount} = payload;

        return {
            ...state,
            list,
            totalCount,
            progress: false,
        };
    case ADD_CONTRACTOR_PUSH_NOTIFICATION_REQUEST:
        return {
            ...state,
            payload,
            progress: true,
        };
    case ADD_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS:
        return {
            ...state,
            progress: false,
        };
    case ADD_CONTRACTOR_PUSH_NOTIFICATION_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    case GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_ERROR:
        return {
            ...state,
            error: payload,
            progress: false,
        };
    case PUSH_NOTIFICATION_UPDATE_FIELD_STORE:
        return {
            ...state,
            ...payload,
        };
    case PUSH_NOTIFICATION_CLEAR_STORE:
        return initial;
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function updateFieldPushNotificationStore(payload) {
    return {
        type: PUSH_NOTIFICATION_UPDATE_FIELD_STORE,
        payload,
    };
}

export function getContractorPushNotificationList(payload) {
    return {
        type: GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_REQUEST,
        payload,
    };
}

export function addContractorPushNotification(payload) {
    return {
        type: ADD_CONTRACTOR_PUSH_NOTIFICATION_REQUEST,
        payload,
    };
}

export function cancelContractorPushNotification(payload) {
    return {
        type: CANCEL_CONTRACTOR_PUSH_NOTIFICATION_REQUEST,
        payload,
    };
}

export function retryContractorPushNotification(payload) {
    return {
        type: RETRY_CONTRACTOR_PUSH_NOTIFICATION_REQUEST,
        payload,
    };
}

export function clearContractorPushNotificationStore() {
    return {
        type: PUSH_NOTIFICATION_CLEAR_STORE,
    };
}

//*  SELECTORS  *//

export const contractorPushNotificationSelector = state => state.contractorPushNotification;
export const getPushNotificationSuccessSelector = createSelector(contractorPushNotificationSelector, ({pushNotification}) => pushNotification);
export const contractorPushNotificationListSelector = createSelector(contractorPushNotificationSelector, ({list}) => list);
export const contractorPushNotificationTotalPagesSelector = createSelector(contractorPushNotificationSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const contractorPushNotificationTotalCountSelector = createSelector(contractorPushNotificationSelector, ({totalCount}) => totalCount);
export const contractorPushNotificationPageDataSelector = createSelector(contractorPushNotificationSelector, ({pageData}) => pageData);
export const contractorPushNotificationProgressSelector = createSelector(contractorPushNotificationSelector, ({progress}) => progress);
//*  SAGA  *//

export const addContractorPushNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse,
                formData,
            },
        } = action;

        const result = yield request.post(`${controller}/add`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CONTRACTOR_PUSH_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: ADD_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS,
            payload: result,
        });

        const state = yield select();
        yield put(getContractorPushNotificationList(contractorPushNotificationPageDataSelector(state)));
    } catch (error) {
        yield put({
            type: ADD_CONTRACTOR_PUSH_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

export const getContractorPushNotificationListSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const result = yield request.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_ERROR,
            payload: error,
        });
    }
};

export const cancelContractorPushNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse,
                data: {
                    id,
                    removePosted,
                },
            },
        } = action;

        const result = yield request.post(`${controller}/${id}/cancel?removePosted=${removePosted}`);

        handleResponse(result);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CANCEL_CONTRACTOR_PUSH_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CANCEL_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CANCEL_CONTRACTOR_PUSH_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractorpushnotification/{id}/retry
export const retryContractorPushNotificationSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse,
                data: {
                    id,
                },
            },
        } = action;

        const result = yield request.post(`${controller}/${id}/retry`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RETRY_CONTRACTOR_PUSH_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: RETRY_CONTRACTOR_PUSH_NOTIFICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: RETRY_CONTRACTOR_PUSH_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_CONTRACTOR_PUSH_NOTIFICATION_REQUEST, addContractorPushNotificationSaga),
        takeEvery(GET_CONTRACTOR_PUSH_NOTIFICATION_LIST_REQUEST, getContractorPushNotificationListSaga),
        takeEvery(CANCEL_CONTRACTOR_PUSH_NOTIFICATION_REQUEST, cancelContractorPushNotificationSaga),
        takeEvery(RETRY_CONTRACTOR_PUSH_NOTIFICATION_REQUEST, retryContractorPushNotificationSaga),
    ]);
}
