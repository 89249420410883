import React from "react";

import Text from "../../../../../components/ActualComponents/Text";
import {RecruitmentCandidateAvatar} from "../../../../../components/Recruitment/CandidateAvatar";

import {pluralizeYears} from "../../../../../utils/pluralize";

import {COLOR} from "../../../../../constants/color";

export const RecruitmentVacancyResponseListCard = (props) => {
    const {
        candidateId,
        responseId,
        onClickCard,
        name,
        age,
        lastOrganisation,
        lastPosition,
        isActive,
        base64,
    } = props;

    return (
        <div className="d-flex">
            <RecruitmentCandidateAvatar
                size="md"
                isEditable={false}
                isFetch={false}
                isList={true}
                base64={base64}
                candidateId={candidateId}
                responseId={responseId}
                className="me-2"
            />
            <div className="d-flex flex-column">
                <Text
                    level="3"
                    color={COLOR.PASSIVE_100}
                    className="mb-1"
                    onClick={() => {
                        if (isActive) {
                            return;
                        }

                        onClickCard();
                    }}
                    isCursorPointerByOnClick={!isActive}
                    title={isActive ? undefined : "Открыть карточку кандидата"}
                >
                    {name || "ФИО не указано"}
                </Text>
                <Text
                    level="2"
                    color={COLOR.SECONDARY_100}
                    className="mb-1"
                >
                    {age ? pluralizeYears(age) : "-"}
                </Text>
                <Text
                    level="2"
                    color={COLOR.SECONDARY_45}
                    className="mb-1"
                >
                    {lastPosition}
                </Text>
                <Text
                    level="2"
                    color={COLOR.SECONDARY_45}
                >
                    {lastOrganisation}
                </Text>
            </div>
        </div>
    );
};