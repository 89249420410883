import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getRichContractorById} from "../../../ducks/contractor";

export const useFullRegistrationFetchContractor = (params) => {
    const {
        contractorId,
    } = params;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRichContractorById(contractorId));
    }, []);

    return {
    };
};