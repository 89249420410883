import {isEmpty} from "lodash";

import {KEDO_TEMPLATE_DATA_TYPES} from "../constants";

export const getKedoTemplateEditData = (name, userReferenceParamModels) => {
    return {
        templateName: name,
        userReferenceParamModels: userReferenceParamModels.map(item => {
            const [restriction= {}] = item.restrictions;

            const {
                availableValues,
                min,
                max,
                multiSelection,
            } = restriction;

            return {
                field: item.field,
                type: multiSelection ? KEDO_TEMPLATE_DATA_TYPES.MULTIPLE_STRING_SELECTION : item.type,
                name: item.name,
                minValue: typeof min === "number" ? String(min) : "",
                maxValue: typeof max === "number" ? String(max) : "",
                maxLength: typeof max === "number" ? String(max) : "",
                availableValues: isEmpty(availableValues) ?
                    [] :
                    availableValues.map(content => {
                        return {
                            content,
                        };
                    }),
            };
        }),
    };
};