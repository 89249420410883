import {
    TASK_LIST_GET_PAGE_REQUEST,
    TASK_UPDATE_STORE,
} from "./actions";

export const getTaskListPage = (payload) => {
    return {
        type: TASK_LIST_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateTaskStore = (payload) => {
    return {
        type: TASK_UPDATE_STORE,
        payload,
    };
};