import {getDateWithoutTime} from "../../../../utils/dateFormat";

import {
    descriptionPaymentReg,
    descriptionPaymentRegWithSlach,
    requiredMessage,
} from "../../../../constants/validationRules";

export const orderDescriptionFnsValidation = {
    maxLength: {
        value: 160,
        message: "Максимальная длина - 160 символов",
    },
    minLength: {
        value: 5,
        message: "Минимальная длина строки 5 символов",
    },
    match: {
        value: descriptionPaymentRegWithSlach,
        message: "Неверный формат описания выполненных работ для чека ФНС России. Для ввода доступны следующие символы: кириллица, латиница, цифры, пробелы, знаки . , : ; - \" ' * № ! ? ( ) { } % < > + [ ] « » |",
    },
};

export const editPaymentRule = {
    orderWorkDescription: {
        required: requiredMessage,
        maxLength: {
            value: 5000,
            message: "Максимальная длина - 5000 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        match: {
            value: descriptionPaymentReg,
            message: "Неверный формат описания.",
        },
    },
    orderDescription: {
        maxLength: {
            value: 5000,
            message: "Максимальная длина - 5000 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
    comment: {
        maxLength: {
            value: 5000,
            message: "Максимальная длина - 5000 символов",
        },
    },
    commentForContractor: {
        maxLength: {
            value: 1500,
            message: "Максимальная длина - 1500 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
    actDate: {
        func: (actDate, orderWorkEndDate) => {
            if (getDateWithoutTime(actDate) < getDateWithoutTime(orderWorkEndDate)) {
                return "Дата акта не может быть раньше конца периода работ.";
            }
        },
        secondArg: "orderWorkEndDate",
    },
    orderWorkStartDate: {
        required: requiredMessage,
    },
    orderWorkEndDate: {
        required: requiredMessage,
    },
};