import React from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmPage from "../../../../../../components/NmPage";
import MapOrderListCard from "../../../order-list-card";

import {usePagination} from "../../../../../../hooks/usePagination";
import {useMapOrdersFetchList} from "./hooks/useFetchList";

import {
    bffMapOrdersSelector,
    bffMapOrdersTotalCountSelector,
    bffMapOrdersTotalPagesSelector,
    bffMapProgressListSelector,
} from "../../../../../../ducks/bff/map/selectors";

function MapOrdersList(props) {
    const {
        filter,
        isSearch,
        onOpenInviteModal,
    } = props;

    const list = useSelector(bffMapOrdersSelector);
    const totalCount = useSelector(bffMapOrdersTotalCountSelector);
    const totalPages = useSelector(bffMapOrdersTotalPagesSelector);
    const progress = useSelector(bffMapProgressListSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    useMapOrdersFetchList({
        pageNum,
        pageSize,
        filter,
    });

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.orderId,
                contentRow: <MapOrderListCard
                    order={item}
                    isShowInviteButton={true}
                    onOpenInviteModal={onOpenInviteModal}
                />,
            };
        });
    };

    return (
        <NmPage
            noPadding
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {
                list?.length ?
                    <CheckboxList rows={getRows()} /> :
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
}

export default MapOrdersList;