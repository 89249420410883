import React from "react";
import {isEmpty} from "lodash";

import {RESULT_STATUS} from "../../BankIndicator";
import BankIndicatorIcon from "../../BankIndicator/components/icon";
import HelpTooltip from "../HelpTooltip";
import Text from "../Text";
import {Loader} from "semantic-ui-react";

import bem from "../../../utils/bem";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import "./style.sass";

interface IChecks {
    beenChecked: boolean,
    success: boolean,
    errorMessage: string,
    errorCode: number,
    blockedReason: any
}

type TContractorPaymentPossibilityCheckDict = {
    [BANK_CARD_INDICATION: string]: string,
    BANK_REGISTRATION: string,
    BLOCKED: string,
    FNS_REGISTRATION: string,
    FORBIDDEN_INN: string,
    FULL_REGISTRATION: string,
    INN: string,
    NDP_NALOG: string,
    OVERALL_INCOME: string,
    PODFT_CHECK: string
};

interface IPossibleData {
    status: string,
    paymentPossible: boolean,
    paymentAbilityReason?: string,
    checks?: {
        BANK_CARD_INDICATION: IChecks,
        BANK_REGISTRATION: IChecks,
        BLOCKED: IChecks,
        FNS_REGISTRATION: IChecks,
        FORBIDDEN_INN: IChecks,
        FULL_REGISTRATION: IChecks,
        INN: IChecks,
        NDP_NALOG: IChecks,
        OVERALL_INCOME: IChecks,
        PODFT_CHECK: IChecks
    },
    hideLoaderContent?: boolean,
    className?: string,
    contractorPaymentPossibilityCheckDict?: TContractorPaymentPossibilityCheckDict
}

const PatentsPaymentStatus = (props: IPossibleData) => {
    const {
        status,
        checks,
        paymentPossible,
        contractorPaymentPossibilityCheckDict,
        className = "",
        hideLoaderContent,
        paymentAbilityReason,
    } = props;

    const [block, element] = bem("payment-status", className);

    const mapRenderPossibleErrors = () => {
        if (paymentAbilityReason) {
            return paymentAbilityReason;
        }

        const filteredChecks = checks && Object.entries(checks).filter(([, value]) => value.beenChecked && !value.success);

        if (isEmpty(filteredChecks)) {
            return null;
        }

        const checksTechErrorMessages = filteredChecks && filteredChecks.find(([, value]) => !isNullOrWhitespace(value.errorMessage) || !isNullOrWhitespace(value.errorCode));
        const checksErrorMessages = filteredChecks &&  filteredChecks.filter(([, value]) => isNullOrWhitespace(value.errorMessage));

        if (!isEmpty(checksTechErrorMessages)) {
            return "Проверка на возможность оплаты временно недоступна. Попробуйте позже";
        }

        if (isEmpty(checksErrorMessages)) {
            return null;
        }

        return (
            <ul className={element("suggestion-list")}>
                {
                    checksErrorMessages && checksErrorMessages.map(([key, value]) => {
                        return (
                            <li
                                key={key}
                                className={element("suggestion-item")}
                            >
                                <div>
                                    {value.blockedReason ?
                                        contractorPaymentPossibilityCheckDict && contractorPaymentPossibilityCheckDict[key].replace("{}", value.blockedReason) :
                                        contractorPaymentPossibilityCheckDict && contractorPaymentPossibilityCheckDict[key]}
                                </div>
                                {value.errorMessage && <div>
                                    {value.errorMessage}
                                </div>}
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    if (!status || status !== "COMPLETED") {
        return (
            <div className={block()}>
                <Loader
                    active
                    inline
                    size="tiny"
                    content={hideLoaderContent ? null : "Загрузка статуса возможности оплаты..."}
                />
            </div>
        );
    }

    if (!paymentPossible) {
        return (
            <div className={block()}>
                <BankIndicatorIcon result={RESULT_STATUS.CHECK_FAILED} />
                <Text
                    level="3"
                    className={element("text", {error: true})}
                >
                    Оплата невозможна
                </Text>
                <HelpTooltip
                    info
                    className={element("tooltip")}
                    width={18}
                    height={18}
                    children={mapRenderPossibleErrors()}
                />
            </div>
        );
    }

    return (
        <div className={block()}>
            <BankIndicatorIcon result={RESULT_STATUS.CHECK_PASSED} />
            <Text
                level="3"
                className={element("text", {success: true})}
            >
                Оплата возможна
            </Text>
        </div>
    );
};

export default PatentsPaymentStatus;
