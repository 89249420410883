import {EMPTY_OPTION_KEY} from "./dropdown";

export const STAFF_STATUS = {
    WORKING: "WORKING",
    ARCHIVED: "ARCHIVED",
};

export const STAFF_STATUS_DESCRIPTION = {
    WORKING: "Работает",
    ARCHIVED: "В архиве",
};

export const STAFF_STATUS_OPTIONS = [
    {
        key: STAFF_STATUS.WORKING,
        value: STAFF_STATUS.WORKING,
        text: "Работает",
    },
    {
        key: STAFF_STATUS.ARCHIVED,
        value: STAFF_STATUS.ARCHIVED,
        text: "Архивирован",
    },
];

export const STAFF_STATUS_OPTIONS_WITH_EMPTY_OPTION = [
    {
        key: EMPTY_OPTION_KEY,
        value: EMPTY_OPTION_KEY,
        text: "Все",
    },
    ...STAFF_STATUS_OPTIONS,
];

export const NEW_STAFF_FORM_STEPS = {
    FINDING: "FINDING",
    SELECTING: "SELECTING",
    FINISHING: "FINISHING",
};

export const NEW_STAFF_FORM_TYPE = {
    USER: "user",
    CONTRACTOR: "contractor",
};

export const EDM_STAFF_PRIVILEGES = {
    RECEIVE_DOCUMENT:"RECEIVE_DOCUMENT",
    SEND_DOCUMENT:"SEND_DOCUMENT",
    APPROVE_STATEMENT:"APPROVE_STATEMENT",
    RECEIVE_STATEMENT:"RECEIVE_STATEMENT",
};

export const STAFF_TYPE = {
    CONTRACTOR: "CONTRACTOR",
    CLIENT_USER: "CLIENT_USER",
};