import incomeForPeriod from "../containers/fns-service/send-modal/src/incomeForPeriod";
import innByPersonalInfo from "../containers/fns-service/send-modal/src/innByPersonalInfo";
import newlyUnboundTaxpayers from "../containers/fns-service/send-modal/src/newlyUnboundTaxpayers";
import partnerStatus from "../containers/fns-service/send-modal/src/partnerStatus";
import partnerWithInn from "../containers/fns-service/send-modal/src/partnerWithInn";
import partnerWithPhone from "../containers/fns-service/send-modal/src/partnerWithPhone";

const controller = "/taxAuthorities/wrapper/";

export const FNS_METHOD_LIST = [
    {
        title: "Запрос на привязку НП НПД к платформе-партнеру по ИНН",
        method: `${controller}bindPartnerWithInn`,
        data: partnerWithInn,
    },
    {
        title: "Запрос на привязку НП НПД к платформе-партнеру по номеру телефона",
        method: `${controller}bindPartnerWithPhone`,
        data: partnerWithPhone,
    },
    {
        title: "Получение информации о неоплаченных налоговых начислениях",
        method: `${controller}getAccrualsAndDebts`,
        data: partnerWithInn,
    },
    {
        title: "Получение статуса заявки на привязку НП НПД к платформе-партнеру",
        method: `${controller}getBindPartnerStatus`,
        data: partnerStatus,
    },
    {
        title: "Получение списка разрешений, предоставленных партнеру",
        method: `${controller}getGrantedPermissions`,
        data: partnerWithInn,
    },
    {
        title: "Получение статуса заявки на изменение прав НП НПД",
        method: `${controller}getGrantedPermissionsStatus`,
        data: partnerStatus,
    },
    {
        title: "Получение информации по доходу НП НПД за период",
        method: `${controller}getIncomeForPeriod`,
        data: incomeForPeriod,
    },
    {
        title: "Получение ИНН по персональным данным",
        method: `${controller}getInnByPersonalInfo`,
        data: innByPersonalInfo,
        typeMethod: "post",
    },
    {
        title: "Получение списка вновь отвязанных от партнера НП НПД",
        method: `${controller}getNewlyUnboundTaxpayers`,
        typeMethod: "post",
        data: newlyUnboundTaxpayers,
    },
    {
        title: "Получение платежных документов на оплату налоговых начислений, задолженности и пеней",
        method: `${controller}getPaymentDocuments`,
        data: partnerWithInn,
    },
    {
        title: "Получение состояния лицевого счета",
        method: `${controller}getTaxpayerAccountStatus`,
        data: partnerWithInn,
    },
    {
        title: "Получение детального статуса НП НПД",
        method: `${controller}getTaxpayerStatus`,
        data: partnerWithInn,
    },
    {
        title: "Запрос на изменение набора прав, выданных НП НПД банку/платформе-партнеру",
        method: `${controller}grantedPermissions`,
        typeMethod: "post",
        data: partnerWithInn,
    },
];

export const FNS_METHOD_LIST_NM_MANAGER = [
    {
        title: "Получение списка вновь отвязанных от партнера НП НПД",
        method: `${controller}getNewlyUnboundTaxpayers`,
        typeMethod: "post",
        data: newlyUnboundTaxpayers,
    },
    {
        title: "Получение списка разрешений, предоставленных партнеру",
        method: `${controller}getGrantedPermissions`,
        data: partnerWithInn,
    },
    {
        title: "Получение детального статуса НП НПД",
        method: `${controller}getTaxpayerStatus`,
        data: partnerWithInn,
    },
    {
        title: "Получение платежных документов на оплату налоговых начислений, задолженности и пеней",
        method: `${controller}getPaymentDocuments`,
        data: partnerWithInn,
    },
    {
        title: "Получение состояния лицевого счета",
        method: `${controller}getTaxpayerAccountStatus`,
        data: partnerWithInn,
    },
    {
        title: "Получение информации о неоплаченных налоговых начислениях",
        method: `${controller}getAccrualsAndDebts`,
        data: partnerWithInn,
    },
    {
        title: "Получение ИНН по персональным данным",
        method: `${controller}getInnByPersonalInfo`,
        data: innByPersonalInfo,
        typeMethod: "post",
    },
];

