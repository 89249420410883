import React, {ReactNode} from "react";

import HelpTooltip, {IHelpTooltip} from "../HelpTooltip";
import NmAdvancedTooltip, {INmAdvancedTooltip} from "../NmAdvancedTooltip";
import NmLabelText, {ILabelText} from "../NmLabelText";
import Text from "../Text";
import NmInfoCardText from "./Text";
import {Loader} from "semantic-ui-react";

import bem from "../../../utils/bem";

import "./style.sass";

export type TNmInfoCardDesktopViewFrom = "md" | "xl" | "xxl";

export interface INmInfoCard {
    alignItems?: "baseline" | "center",
    desktopViewFrom?: TNmInfoCardDesktopViewFrom,
    className?: string,
    value?: string | number | ReactNode,
    classNameValue?: string,
    classNameTitle?: string,
    icon?: ReactNode,
    icons?: ReactNode[],
    values?: [{bold?: boolean, text?: string}],
    title: string,
    subTitle?: ReactNode | string,
    titleAdditionalContent?: ReactNode | string,
    boldValue?: boolean,
    wrapValue?: boolean,
    fluidValue?: boolean,
    subValue?: string,
    titleBold?: boolean,
    helpTooltip?: IHelpTooltip,
    tooltip?: INmAdvancedTooltip,
    labels?: Array<ILabelText>,
    overflowEllipseText?: boolean,
    isLoading?: boolean,
    valueColor?: string,
    width?: number,
    titleText?: string
}

const NmInfoCard = (props: INmInfoCard) => {
    const {
        className = "",
        value,
        classNameValue = "",
        classNameTitle = "",
        values,
        title,
        titleAdditionalContent,
        alignItems = "center",
        subValue,
        wrapValue,
        subTitle,
        desktopViewFrom = "md",
        boldValue,
        fluidValue,
        titleBold = false,
        icons,
        helpTooltip,
        tooltip,
        labels,
        icon,
        overflowEllipseText = false,
        isLoading,
        valueColor,
        titleText,
        width,
    } = props;
    const [block, element] = bem("nm-info-card", className);

    const isIconsBlock = Boolean(icon || icons || tooltip || helpTooltip);

    const getTooltip = () => {
        if (helpTooltip) {
            return (
                <HelpTooltip
                    width={18}
                    height={18}
                    className={element("icon")}
                    {...helpTooltip}
                />
            );
        }

        if (tooltip) {
            return (
                <NmAdvancedTooltip
                    className={element("icon")}
                    {...tooltip}
                />
            );
        }
    };

    const getIcons = () => {
        if (icon) {
            return (
                <div className={element("icon")}>
                    {icon}
                </div>
            );
        }

        if (icons) {
            return icons.map(icon => (
                <div className={element("icon")}>
                    {icon}
                </div>
            ));
        }

        return null;
    };

    const getValue = () => {
        if (isLoading) {
            return (
                <Loader
                    className={element("loader")}
                    active
                    inverted={false}
                    content=""
                    size="tiny"
                />
            );
        }

        if (values) {
            return values.map(({bold, text}, index) =>
                <NmInfoCardText
                    key={index}
                    className={element("text", {multiple: true})}
                    bold={bold}
                    text={text}
                    overflowEllipse={overflowEllipseText}
                    title={titleText}
                    color={valueColor}
                />,
            );
        }

        return <NmInfoCardText
            title={titleText}
            text={value}
            className={classNameValue}
            bold={boldValue}
            wrap={wrapValue}
            fluid={fluidValue}
            overflowEllipse={overflowEllipseText}
            color={valueColor}
        />;
    };

    return (
        <div
            style={{width}}
            className={block({
                desktopViewFrom,
                alignItems,
            })}
        >
            <div
                className={`${element("title", {
                    desktopViewFrom,
                })} ${classNameTitle}`}
            >
                <Text
                    level="2"
                    medium={titleBold}
                >
                    {title}
                </Text>
                {
                    isIconsBlock &&
                    <div
                        className={element("icons-group", {
                            desktopViewFrom,
                        })}
                    >
                        {getIcons()}
                        {getTooltip()}
                    </div>
                }
                {titleAdditionalContent}
            </div>
            {
                subTitle &&
                <div className={element("sub-title", {desktopViewFrom})}>
                    {subTitle}
                </div>
            }
            <div className={element("value", {desktopViewFrom, wrapValue: wrapValue && Boolean(values?.length)})}>
                {getValue()}
            </div>
            {
                subValue &&
                <Text
                    className={element("sub-value", {desktopViewFrom})}
                    level="2"
                >
                    {subValue}
                </Text>
            }
            {
                labels &&
                <div className={element("divider")} />
            }
            {
                labels &&
                <div className={element("labels")}>
                    {
                        labels.map((labelProps, index) => {
                            const {
                                className,
                                ...otherProps
                            } = labelProps;

                            return (
                                <NmLabelText
                                    {...otherProps}
                                    key={index}
                                    className={`nm-info-card__label-text ${className}`}
                                />
                            );
                        })
                    }
                </div>
            }
        </div>
    );
};

export default NmInfoCard;