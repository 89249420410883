import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {bffCommonDictsLeadRegistrationStatusDictOptionsSelector} from "../../../../ducks/bff/common/dicts/selectors";

export const useClientRemoteContractorsFilter = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const registrationStatusDictOptions = useSelector(bffCommonDictsLeadRegistrationStatusDictOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter: params.filterData || {
            fioFilter: "",
            phoneFilter: "",
            innFilter: "",
        },
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        const {
            innFilter,
            phoneFilter,
            ...otherFilter
        } = filter;

        return {
            ...otherFilter,
            innFilter: handleFormString(innFilter),
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "fioFilter",
                        label: "ФИО",
                        placeholder: "ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "phoneFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "innFilter",
                        label: "ИНН",
                        placeholder: "ИНН",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
        ];
    }, [
        registrationStatusDictOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};