import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE, HIDING_PROJECT_OBJECT_TYPE} from "../constants";

export const getHidingProjectsObjectsNodes = (params) => {
    const {
        projects,
        isFirstChecked = false,
        isVisibleClient,
    } = params;

    const projectsChildren = projects.list.map(item => {
        return {
            label: item.projectName,
            checked: isFirstChecked ||
                item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
            changed: isFirstChecked,
            type: HIDING_PROJECT_OBJECT_TYPE.PROJECT,
            partiallyChecked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
            value: item.projectId,
            children: [],
        };
    });

    if (isVisibleClient) {
        return [
            {
                label: projects.clientInfo.clientName,
                value: projects.clientInfo.clientId,
                type: HIDING_PROJECT_OBJECT_TYPE.CLIENT,
                changed: isFirstChecked,
                checked: isFirstChecked ||
                    projects.clientInfo.clientSelectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                partiallyChecked: projects.clientInfo.clientSelectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
                children: projectsChildren,
            },
        ];
    }

    return projectsChildren;
};