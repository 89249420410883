import React from "react";
import {useSelector} from "react-redux";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import useCrowdTaskCardContractorRejectModalForm from "./hooks/useForm";

import {CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE} from "../../../../../../constants/crowd/task";

import {bffCrowdTasksCardProgressActionSelector} from "../../../../../../ducks/bff/crowd/taskCard/selectors";

const CrowdTaskCardContractorRejectModal = (props) => {
    const {
        onClose = () => {
        },
        fetchInfo,
        clientId,
        taskId,
        contractorId,
    } = props;

    const progressAction = useSelector(bffCrowdTasksCardProgressActionSelector);

    const {
        values,
        handleChange,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
    } = useCrowdTaskCardContractorRejectModalForm({
        onClose,
        clientId,
        taskId,
        contractorId,
        fetchInfo,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Отклонение исполнителя на задании
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    disabled={progressAction}
                    submitBtnContent="Подтвердить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
            loading={progressAction}
        >
            <NmForm addMargin>
                <Text type="title">
                    Укажите причину отказа
                </Text>
                {
                    Object.values(CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE).map(item => {
                        const {
                            VALUE,
                            TEXT,
                        } = item;

                        return (
                            <NmRadioV2
                                label={TEXT}
                                checked={values.rejectionReasonType === VALUE}
                                name="rejectionReasonType"
                                value={VALUE}
                                onChange={() => {
                                    setFieldValue("rejectionReasonType", VALUE);
                                }}
                            />
                        );
                    })
                }
                <NmTextareaV2
                    minRows={2}
                    maxRows={3}
                    maxLength={500}
                    error={
                        touched.rejectionReasonDescription &&
                        errors.rejectionReasonDescription
                    }
                    value={values.rejectionReasonDescription}
                    name="rejectionReasonDescription"
                    disabled={values.rejectionReasonType !== CROWD_TASK_CONTRACTOR_REJECT_REASON_TYPE.OTHER.VALUE}
                    placeholder="Введите свою причину"
                    onChange={handleChange}
                />
            </NmForm>
        </NmModal>
    );
};

export default CrowdTaskCardContractorRejectModal;