import {useState} from "react";

import {removePhoneMask} from "../../../utils/stringFormat";
import {handleFormString} from "../../../utils/stringHelper";

export function useReviewsFilterDto() {
    const [filterDto, setFilter] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    function mapFilterDto(filter) {
        const {
            fioFilter,
            clientIdsFilter,
            phoneFilter,
            reviewTextFilter,
            scoreFilter,
        } = filter;

        return {
            ...filter,
            clientIdsFilter: clientIdsFilter || undefined,
            fioFilter: handleFormString(fioFilter),
            phoneFilter: phoneFilter ? removePhoneMask(phoneFilter): undefined,
            reviewTextFilter: handleFormString(reviewTextFilter),
            scoreFilter: scoreFilter ? parseInt(scoreFilter) : undefined,
        };
    }

    const setFilterDto = (filter) => {
        setFilter(mapFilterDto(filter));
    };


    return {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    };
}