import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../hooks/useFilter";

import {getClientNamesFilter} from "../../../../../components/ActualComponents/Filter/utils/getClientsFilter";

export const useKedoDocumentTypesFilter = ({setPagination, pageSize, clientId}) => {
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const filters = useMemo(() => {
        return [
            !clientId && {
                row: [
                    getClientNamesFilter(),
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Наименование",
                        placeholder: "Введите наименование",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalIdFilter",
                        label: "Внешний идентификатор",
                        placeholder: "Введите идентификатор",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "onlyUkepFilter",
                        label: "Требуется УКЭП компании",
                        placeholder: "Не выбрано",
                        options: [
                            {
                                key: true,
                                value: true,
                                text: "Да",
                            },
                            {
                                key: false,
                                value: false,
                                text: "Нет",
                            },
                        ],
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "availableClientUsersFilter",
                        label: "Первый подписант",
                        placeholder: "Не выбрано",
                        options: [
                            {
                                key: true,
                                value: true,
                                text: "Сотрудник",
                            },
                            {
                                key: false,
                                value: false,
                                text: "Компания",
                            },
                        ],
                        size: "lg",
                    },
                ],
            },
        ].filter(Boolean);
    }, []);

    return {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    };
};