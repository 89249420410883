import React from "react";

import {ContractorFinanceIncomeNpd} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
} from "../../../../../constants/links";
import {STATUS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS} from "../../../../../constants/status";

import {getContractorInProgressFinanceNpdPayments} from "../../../../../ducks/contractorFinanceIncomeNpd";

const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS);

export const ContractorFinanceIncomeNpdInProgress = (props) => {
    return (
        <ContractorFinanceIncomeNpd
            subTabsLinks={{
                orders: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
                tasks: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
            }}
            fetchList={getContractorInProgressFinanceNpdPayments}
            isShowItemActions={false}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
