export const getQueryParam = (location, query) => {
    const params = new URLSearchParams(location.search);
    const result = params.get(query);
    return result;
};
/***
 * Для определения активной ссылки в боковом меню
 * @param pathname - текущий урл
 * @param checkData: {
 *     to: куда ведет ссылка элемента меню,
 *     activePath: str || Array<string>, - задаем строку, массив строк по которой/ым будем опредять активность пункта меню
 *     isSearchActiveInAllPath: true/false - цель найти совпадение checkedStr в любой части pathname
 *     activeExceptions: Array<string> - набор строк, совпадение с которыми если будет найдено в pathname,
 *     пункт меню будет неактивен
 * }
 * @returns {boolean|*}
 */
export const linkActiveChecker = (pathname, checkData) => {
    const {
        activePath,
        to,
        isSearchActiveInAllPath = false,
        activeExceptions,
    } = checkData;

    if (typeof activePath === "boolean") {
        return activePath;
    }

    const checkedStr = activePath || to;

    if (activeExceptions) {
        const exception = activeExceptions.find(str => pathname.includes(str));

        if (exception) {
            return false;
        }
    }

    if (Array.isArray(checkedStr)) {
        return Boolean(checkedStr.find(path => pathname.includes(path)));
    }

    if (isSearchActiveInAllPath) {
        return pathname.indexOf(checkedStr) !== -1;
    }

    return pathname.indexOf(checkedStr) === 0;
};

export const getFileDataByLink = (link, name) => {
    const parse = require("url-parse");

    const {query, pathname} = parse(link);

    const searchParams = new URLSearchParams(query);

    const fileName = searchParams.get("fileName");

    return {
        downloadLink: `${pathname}${query}`,
        fileName: name || fileName,
    };
};