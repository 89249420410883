import {useEffect, useState} from "react";

import {checkPatentCard} from "../../controlDateReadOnly";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

const useReadFormWarning = (values, card) => {
    const [errorsReadOnlyCard, setErrorsReadOnlyCard] = useState("");

    useEffect(() => {
        const citizenship = card[CONTRACTOR_FIELD.CITIZENSHIP];
        let error = "";

        if ([citizenshipsDict.TJ.value, citizenshipsDict.UZ.value, citizenshipsDict.UA.value].includes(citizenship)) {
            error = checkPatentCard({values, card});
        }

        setErrorsReadOnlyCard(error);
    }, [values]);

    return {
        errorsReadOnlyCard,
    };
};

export default useReadFormWarning;