const getModifiers = (className, modifiers = {}) => {
    const isMods = Object.keys(modifiers).length > 0;

    if (!isMods) {
        return;
    }

    const mods = Object
        .entries(modifiers)
        .filter(([, value]) => Boolean(value) || value === 0)
        .map(([name, value]) => {
            const result = value === true ? `${className}_${name}` : `${className}_${name}-${value}`;
            return result;
        })
        .join(" ");

    return mods;
};

const bem = (blockName, className) => {
    const block = (modifiers) => {
        const nameBasic = className ? `${blockName} ${className}` : blockName;
        const nameWithMods = className ? 
            `${blockName} ${getModifiers(blockName, modifiers)} ${className}` :
            `${blockName} ${getModifiers(blockName, modifiers)}`;

        return modifiers ? nameWithMods : nameBasic;
    };
    const element = (elementName, modifiers) => {
        const nameBasic = `${blockName}__${elementName}`;
        const nameWithMods = `${nameBasic} ${getModifiers(nameBasic, modifiers)}`;

        return modifiers ? nameWithMods : nameBasic;
    };

    return [block, element];
};

export default bem;