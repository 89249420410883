import {useState} from "react";
import {useDispatch} from "react-redux";

import {updateClientFavorite} from "../../../ducks/client";

export const useClientListFavorite = (params) => {
    const {
        fetchList,
    } = params;
    const [favoriteData, setFavoriteData] = useState(null);
    const dispatch = useDispatch();

    const onChangeFavorite = (data) => {
        const {
            favorite,
            clientId,
            clientName,
        } = data;

        setFavoriteData({
            favorite: !favorite,
            clientId,
            clientName,
        });
    };

    const onCloseFavorite = () => {
        setFavoriteData(null);
    };

    const updateFavorite = () => {
        const {
            clientId,
            favorite,
        } = favoriteData;

        dispatch(updateClientFavorite({
            clientId,
            favorite,
            onSuccess: () => {
                fetchList();
            },
        }));

        onCloseFavorite();
    };

    return {
        favoriteData,
        onChangeFavorite,
        onCloseFavorite,
        updateFavorite,
    };
};