import React, {FC, ReactNode} from "react";
import {ScrollMenu} from "react-horizontal-scrolling-menu";
import {useSelector} from "react-redux";

import {LeftButton, RightButton} from "./Button";
import TabsItem from "./Item";

import bem from "../../../utils/bem";
import {linkActiveChecker} from "../../../utils/gueryStringHelper";
import {onWheel} from "./utils/onWheel";

import "./style.sass";

interface ITabs {
    panes: {
        link?: string,
        active: boolean | string | string[],
        isVisible?: boolean,
        name: string | ReactNode,
        count?: number,
        id?: string,
        isSearchActiveInAllPath?: boolean,
        exceptions?: Array<string>,
        onClick?: () => void,
    }[],
    secondary?: boolean,
    pathname?: string,
    className?: string,
    handleRefreshCounters?: () => void
}

const Tabs: FC<ITabs> = (props) => {
    const pathname = useSelector((state: any) => state.router.location.pathname);

    return (
        <TabsComponent
            {...props}
            pathname={pathname}
        />
    );
};

export const TabsComponent = (props: ITabs) => {
    const {
        handleRefreshCounters,
        className,
        panes,
        pathname,
        secondary,
    } = props;
    const [block, element] = bem("tabs", className);

    return (
        <ScrollMenu
            LeftArrow={LeftButton}
            RightArrow={RightButton}
            onWheel={onWheel}
            scrollContainerClassName={element("content-container", {secondary})}
            wrapperClassName={block({secondary})}
            itemClassName={element("item-wrapper")}
        >
            {
                panes
                    .filter(({isVisible = true}) => isVisible)
                    .map((item, index) => {
                        const {
                            active,
                            link,
                            isSearchActiveInAllPath,
                            exceptions,
                            onClick,
                            id,
                            name,
                            count,
                        } = item;

                        const isActive = typeof active === "boolean" ?
                            active :
                            linkActiveChecker(pathname, {
                                activePath: active,
                                to: link,
                                isSearchActiveInAllPath,
                                activeExceptions: exceptions,
                            });

                        const isCount = Boolean(count) && count !== 0;

                        const key = id ? id : `${link}${index}`;

                        return (
                            <TabsItem
                                key={key}
                                itemId={key}
                                link={link}
                                count={count}
                                isCount={isCount}
                                isActive={isActive}
                                secondary={secondary}
                                name={name}
                                onClick={onClick}
                                handleRefreshCounters={handleRefreshCounters}
                            />
                        );
                    },
                    )}
        </ScrollMenu>
    );
};

export default Tabs;
