import dateFormat, {getStartFilterDateWithTimeInUtc} from "../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {METERS_IN_KM} from "../../../constants/map";

export const getRequestDataFromMapFilterData = (filterData) => {
    const {
        distanceFilter,
        crowdTasksFilters = {},
        ordersFilters = {},
        contractorsFilters = {},
        advertsFilters = {},
        ...otherFilterData
    } = filterData;

    return {
        ...otherFilterData,
        distanceFilter: {
            ...distanceFilter,
            coordinateLon: +filterData.distanceFilter.coordinateLon,
            coordinateLat: +filterData.distanceFilter.coordinateLat,
            distance: distanceFilter.distance * METERS_IN_KM,
        },
        locationAddressFiasIdsFilter: filterData.locationAddressFiasIdsFilter ?
            [filterData.locationAddressFiasIdsFilter] :
            undefined,
        crowdTasksFilters: {
            workStartDateFromFilter: !isNullOrWhitespace(crowdTasksFilters.workStartDateFromFilter) ?
                dateFormat(crowdTasksFilters.workStartDateFromFilter, "yyyy-MM-dd") :
                undefined,
            workEndDateToFilter: !isNullOrWhitespace(crowdTasksFilters.workEndDateToFilter) ?
                dateFormat(crowdTasksFilters.workEndDateToFilter, "yyyy-MM-dd") :
                undefined,
            specialityIdsFilter: crowdTasksFilters.specialityIdsFilter?.length ?
                crowdTasksFilters.specialityIdsFilter :
                undefined,
            clientIdsFilter: crowdTasksFilters.clientIdsFilter?.length ? crowdTasksFilters.clientIdsFilter : undefined,
            projectIdsFilter: crowdTasksFilters.projectIdsFilter?.length ? crowdTasksFilters.projectIdsFilter : undefined,
            objectIdsFilter: crowdTasksFilters.objectIdsFilter?.length ? crowdTasksFilters.objectIdsFilter : undefined,
        },
        ordersFilters: {
            workDateFrom: !isNullOrWhitespace(ordersFilters.workDateFrom) ?
                dateFormat(ordersFilters.workDateFrom, "yyyy-MM-dd") :
                undefined,
            workDateTo: !isNullOrWhitespace(ordersFilters.workDateTo) ?
                dateFormat(ordersFilters.workDateTo, "yyyy-MM-dd") :
                undefined,
            specialityIds: ordersFilters.specialityIdsFilter?.length ?
                ordersFilters.specialityIdsFilter :
                undefined,
            clientIdsFilter: ordersFilters.clientIdsFilter?.length ? ordersFilters.clientIdsFilter : undefined,
            projectIdsFilter: ordersFilters.projectIdsFilter?.length ? ordersFilters.projectIdsFilter : undefined,
            objectIdsFilter: ordersFilters.objectIdsFilter?.length ? ordersFilters.objectIdsFilter : undefined,
            statusesFilter: ordersFilters.statusFilter && ordersFilters.statusFilter !== "ALL" ?
                [ordersFilters.statusFilter] :
                undefined,
            contractorRecruitmentRequiredFilter: ordersFilters.contractorRecruitmentRequiredFilter ?
                ordersFilters.contractorRecruitmentRequiredFilter :
                undefined,
        },
        contractorsFilters: {
            specialityIdsFilter: contractorsFilters.specialityIdsFilter?.length ?
                contractorsFilters.specialityIdsFilter :
                undefined,
            citizenshipFilter: contractorsFilters.citizenshipFilter?.length ?
                contractorsFilters.citizenshipFilter :
                undefined,
            ageFromFilter: contractorsFilters.ageFromFilter ? +contractorsFilters.ageFromFilter : undefined,
            ageToFilter: contractorsFilters.ageToFilter ? +contractorsFilters.ageToFilter : undefined,
            genderFilter: contractorsFilters.genderFilter ? contractorsFilters.genderFilter : undefined,
            lastLoginDateFromFilter: getStartFilterDateWithTimeInUtc(contractorsFilters.lastLoginDateFromFilter),
            driverLicenseFilter: contractorsFilters.driverLicenseFilter?.length ?
                contractorsFilters.driverLicenseFilter :
                undefined,
            medicalBookFilter: contractorsFilters.medicalBookFilter,
        },
        advertsFilters: {
            clientIdsFilter: advertsFilters.clientIdsFilter?.length ? advertsFilters.clientIdsFilter : undefined,
            specialityIdsFilter: advertsFilters.specialityIdsFilter?.length ?
                advertsFilters.specialityIdsFilter :
                undefined,
            citizenshipFilter: advertsFilters.citizenshipFilter?.length ?
                advertsFilters.citizenshipFilter :
                undefined,
            ageFromFilter: advertsFilters.ageFromFilter ? +advertsFilters.ageFromFilter : undefined,
            ageToFilter: advertsFilters.ageToFilter ? +advertsFilters.ageToFilter : undefined,
            genderFilter: advertsFilters.genderFilter ? advertsFilters.genderFilter : undefined,
            publicationFromFilter: !isNullOrWhitespace(advertsFilters.publicationFromFilter) ?
                dateFormat(advertsFilters.publicationFromFilter, "yyyy-MM-dd") :
                undefined,
            publicationToFilter: !isNullOrWhitespace(advertsFilters.publicationToFilter) ?
                dateFormat(advertsFilters.publicationToFilter, "yyyy-MM-dd") :
                undefined,
        },
        types: filterData.types?.length ? filterData.types : [],
    };
};