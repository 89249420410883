import React from "react";

import NmSvg from "../NmSvg";

import "./style.sass";

const NmEmptyPage = (props) => {
    const {
        title,
    } = props;

    return (
        <div className="nm-empty-page">
            <div className="flex flex-column flex-aligned-center">
                <NmSvg
                    className="nm-empty-page__icon"
                    name="denied"
                />
                <div
                    className="nm-empty-page__text nm-empty-page__text_mt"
                >
                    {title}
                </div>
            </div>
        </div>
    );
};

export default NmEmptyPage;