import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useKedoTemplateError} from "./useError";

import {getKedoTemplateRequestData} from "../utils/getRequestData";
import {getKedoTemplateValidation} from "../utils/getValidation";

import {
    addAdmKedoTemplate,
    updateAdmKedoTemplate,
} from "../../../../../../ducks/bff/adm/document-templates/kedo/templates/services";
import {updateKedoTemplatesStore} from "../../../../../../ducks/kedo/templates/actionCreators";

export const useKedoTemplateEditForm = (params) => {
    const {
        fetchList,
        onClose,
        id,
    } = params;

    const dispatch = useDispatch();

    const initialValues = {
        files: [],
        templateName: "",
        userReferenceParamModels: [],
    };

    const validationSchema = getKedoTemplateValidation();

    useEffect(() => {
        return () => {
            dispatch(updateKedoTemplatesStore({
                usersFieldNames: [],
                error: null,
                isSuccess: false,
            }));
        };
    }, []);

    useKedoTemplateError();

    const onSubmit = (values) => {
        const formData = getKedoTemplateRequestData(values, id);

        if (id) {
            dispatch(updateAdmKedoTemplate({
                formData,
                onSuccess: () => {
                    onClose();
                    fetchList();
                },
            }));

            return;
        }

        dispatch(addAdmKedoTemplate({
            formData,
            onSuccess: () => {
                onClose();
                fetchList();
            },
        }));
    };

    return {
        onSubmit,
        initialValues,
        validationSchema,
    };
};