import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import AmountInput from "../AmountInput";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {getNumberFromFormattedAmount} from "../../utils/stringFormat";
import {toastSuccess} from "../../utils/toastHelper";

import {VALIDATIONS_MESSAGE} from "../../constants/validationsYup";

import {createTkbPayment} from "../../ducks/internalPayments/actionCreators";
import {internalPaymentsProgressActionSelector} from "../../ducks/internalPayments/selectors";

export const CreateTkbPayment = (props) => {
    const {
        onClose,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(internalPaymentsProgressActionSelector);

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
    } = useFormik({
        onSubmit,
        initialValues: {
            amount: "",
            description: "Договор № И-2228/23 от 09.06.2023 произвести возврат д/с в сумме *** рублей со счета для " +
                "осущ. расчетов № 30232810500004089141 на р/с ООО «Наймикс» № 40702810720020002716, в ПАО ТКБ Банке",
        },
        validationSchema: yup.object().shape({
            amount: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            description: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    function onSubmit() {
        dispatch(createTkbPayment({
            paymentType: "TRANSFER_302_TO_407",
            amount: getNumberFromFormattedAmount(values.amount),
            description: values.description,
            onSuccess: () => {
                onClose();
                toastSuccess("Платеж успешно создан. После завершения операции по обработке платежа, информация появится в логах");
            },
        }));
    }

    return (
        <NmModal
            size="md"
            contentAlign="left"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Создать внутренний перевод средств между счетами в ТКБ
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submit={handleSubmit}
                    submitBtnContent="Создать"
                    onClose={onClose}
                    cancelBtnContent="Отменить"
                    disabled={progress}
                />
            }
        >
            <NmForm addMargin={true}>
                <AmountInput
                    required={true}
                    newInput
                    size="xl"
                    label="Сумма в рублях"
                    placeholder="Введите сумму"
                    error={errors.amount}
                    value={values.amount}
                    onChange={(event, {value}) => {
                        setFieldValue("amount", value);
                    }}
                />
                <NmTextareaV2
                    required={true}
                    size="xl"
                    label="Описание"
                    placeholder="Введите описание"
                    value={values.description}
                    maxLength={255}
                    onChange={(event, {value}) => {
                        setFieldValue("description", value);
                    }}
                    error={errors.description}
                />
            </NmForm>
        </NmModal>
    );
};