import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageAdminCrowdTasks,
    updateStoreCrowdTasks,
} from "../../../../ducks/bff/crowd/tasks/actionCreators";

const useCrowdTaskRegistryFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreCrowdTasks({
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filter,
    ]);

    const fetchList = () => {
        dispatch(getPageAdminCrowdTasks({
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};

export default useCrowdTaskRegistryFetch;