import React from "react";

import {ContractorFinanceIncomeIndividual} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {STATUS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS} from "../../../../../constants/status";

import {getContractorInProgressFinanceIndividualPayments} from "../../../../../ducks/contractorFinanceIncomeIndividual";

const options = dictionaryToOptions(STATUS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS);

export const ContractorFinanceIncomeIndividualInProgress = (props) => {
    return (
        <ContractorFinanceIncomeIndividual
            fetchList={getContractorInProgressFinanceIndividualPayments}
            isShowItemActions={false}
            isShowBankStatusFilter={false}
            isShowRegistryNumberFilter={true}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
