import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router";

import Tabs from "../../../../components/ActualComponents/Tabs";
import ContractorEventLogRoute from "../contractor-event-log-route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_LIST,
    LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG,
    LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY,
    LINK_FORBIDDEN_PAGE,
} from "../../../../constants/links";
import {
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
} from "../../../../constants/roles";

import {getCanViewEventLogClientAdmin} from "../../../../ducks/contractor";

import "./style.sass";

function ContractorEventLogCommon(props) {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const [canViewContractorEventLogClientAdmin, setCanViewContractorEventLogClientAdmin] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isClientUser(role)) {
            dispatch(getCanViewEventLogClientAdmin({
                contractorId,
                onSuccess: (result) => {
                    setCanViewContractorEventLogClientAdmin(result);

                },
            }),
            );
        }
    }, []);

    const eventLogListLink = LINK_CONTRACTOR_CARD_EVENT_LOG_LIST.replace(":contractorId", contractorId);
    const signingDocumentLogLink = LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG.replace(":contractorId", contractorId);
    const orderHistoryLink = LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY.replace(":contractorId", contractorId);
    const notificationLogLink = LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG.replace(":contractorId", contractorId);
    const role = ls(USER_ROLE);

    function getLinks() {
        const links = {
            orderHistoryLink: {
                active: orderHistoryLink,
                link: orderHistoryLink,
                name: "История заявок",
            },
            eventLogListLink: {
                active: eventLogListLink,
                link: eventLogListLink,
                name: "Журнал событий",
            },
            signingDocumentLogLink: {
                active: signingDocumentLogLink,
                link: signingDocumentLogLink,
                name: "Журнал подписания документов",
            },
            notificationLogLink: {
                active: notificationLogLink,
                link: notificationLogLink,
                name: "Лог уведомлений",
            },
        };

        if (isClientUser(role) && !canViewContractorEventLogClientAdmin) {
            return [
                links.orderHistoryLink,
            ];
        }

        if ([NM_CHIEF_ACCOUNTANT].includes(role)) {
            return [
                links.orderHistoryLink,
                links.eventLogListLink,
                links.notificationLogLink,
            ];
        }
        return [
            links.orderHistoryLink,
            links.eventLogListLink,
            links.signingDocumentLogLink,
            links.notificationLogLink,
        ].filter(item => Boolean(item));
    }

    if (canViewContractorEventLogClientAdmin === false) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    return (
        <div className="contractor-event-log-common">
            <Tabs
                {...props}
                className="contractor-event-log-common__tabs"
                panes={getLinks()}
                secondary
            />
            <ContractorEventLogRoute
                {...props}
            />
        </div>
    );
}

export default ContractorEventLogCommon;
