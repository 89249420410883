import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {ClientSettingsContext} from "../../ContextProvider";

import {toastSuccess} from "../../../../../../utils/toastHelper";
import {isClientSettingsExternalApiMethodsChanged} from "../utils/isChangedData";

import {CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE, CLIENT_SETTINGS_TAB} from "../../../constants";

import {
    getClientsSettingsApi,
    updateClientsSettingsApi,
} from "../../../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsApiSelector,
} from "../../../../../../ducks/bff/clients/settings/selectors";

export const useClientSettingsExternalApiMethodsForm = (params) => {
    const {
        clientId,
        onClose,
    } = params;

    const {
        isOpenUnsavedDataConfirm,
        onCancelUnsavedDataConfirm,
        updateChanged,
    } = useContext(ClientSettingsContext);

    const dispatch = useDispatch();
    const card = useSelector(bffClientsSettingsApiSelector);

    useEffect(() => {
        dispatch(getClientsSettingsApi({clientId}));
    }, []);

    const {
        values,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues: card,
        enableReinitialize: true,
    });

    useEffect(() => {
        const isChangedData = isClientSettingsExternalApiMethodsChanged({
            values,
            card,
        });

        updateChanged({
            name: CLIENT_SETTINGS_TAB.EXTERNAL_API_METHODS,
            isChangedData,
        });
    }, [card, values]);

    function onSubmit({isClose}) {
        dispatch(updateClientsSettingsApi({
            clientId,
            ...values,
            onSuccess: () => {
                toastSuccess(CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE);

                if (isOpenUnsavedDataConfirm) {
                    onCancelUnsavedDataConfirm();
                }

                if (isClose) {
                    onClose();
                }

                dispatch(getClientsSettingsApi({clientId}));
            },
        }));
    }

    const onChange = (event, {name, checked}) => {
        setFieldValue(name, checked);
    };

    return {
        isLoading: isEmpty(card),
        onChange,
        values,
        onSubmit,
    };
};