import React from "react";
import {useSelector} from "react-redux";

import {NmPageHeader} from "../../../../components/NmPageHeader";
import UpdatingDirectories from "../../../../components/SettingsDirectoriesEdoTypes";
import {
    bffRecruitmentDirectoryProgressSelector,
    bffRecruitmentDirectoryRejectionReasonListSelector,
    bffRecruitmentDirectoryRejectionReasonTotalCountSelector,
    bffRecruitmentDirectoryRejectionReasonTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/directory/selectors";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import {useRecruitmentDirectoriesRejectionReasonsAction} from "./hooks/useAction";
import {useRecruitmentRejectionReasonsFetchList} from "./hooks/useFetchList";

import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

import {
    ADMIN,
    CLIENT_ADMIN,
    RECRUITER,
} from "../../../../constants/roles";

const ClientRecruitmentDirectoriesRejectionReasons = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentDirectoryRejectionReasonListSelector);
    const totalCount = useSelector(bffRecruitmentDirectoryRejectionReasonTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentDirectoryRejectionReasonTotalPagesSelector);
    const progressList = useSelector(bffRecruitmentDirectoryProgressSelector);

    const role = ls(USER_ROLE);
    const isAccessAction = [ADMIN, CLIENT_ADMIN, RECRUITER].includes(role);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentRejectionReasonsFetchList({
        clientId,
        pageNum,
        pageSize,
        filterData,
    });

    const {
        onAdd,
        onEdit,
        onDelete,
    } = useRecruitmentDirectoriesRejectionReasonsAction({
        clientId,
    });

    return (
        <UpdatingDirectories
            header={
                <NmPageHeader
                    noWrap={false}
                    text="Причины отказа"
                    tooltipText="Страница предназначена для управления причинами отказа, которые указываются при отказе кандидатам в вакансии"
                />
            }
            openFilter={true}
            widthByFilter={true}
            titleHiddenInMobile={false}
            labelFilter="Причина отказа"
            placeholderFilter="Введите название причины отказа"
            onSubmitFilter={onSearch}
            isSearch={isSearch}
            filterNameField="value"
            clearFilter={onClear}
            filter={filterData}
            isEditable={isAccessAction}
            onAdd={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            fetchList={fetchList}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            addButtonText="Добавить причину отказа"
            popupTitleAdd="Добавление причины отказа"
            popupTitleEdit="Редактирование причины отказа"
            popupTitleDelete="Вы действительно хотите удалить причину отказа из справочника?"
            popupSubmitDelete="Подтвердить"
            popupCancelDelete="Отмена"
            inputTypeLabel="Причина отказа"
            inputTypePlaceholder="Введите причину отказа"
            inputTypeMaxLength={100}
            isLoaded={progressList}
        />
    );
};

export default ClientRecruitmentDirectoriesRejectionReasons;