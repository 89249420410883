import {CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE, HIDING_PROJECT_OBJECT_TYPE} from "../constants";

export const getHidingProjectsObjectsSearchNodes = ({projectsAndObjects, isVisibleClient}) => {
    const {
        clientId,
        clientName,
        projects,
        clientSelectorBoolType,
        nextAllPagesSelectionType,
    } = projectsAndObjects;

    const projectsChildren = projects?.map(item => {
        const project = {
            expanded: true,
            nextAllPagesSelectionType: item.nextAllPagesSelectionType,
            label: item.projectName,
            checked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
            type: HIDING_PROJECT_OBJECT_TYPE.PROJECT,
            partiallyChecked: item.selectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
            value: item.projectId,
            children: [],
        };

        if (item.objects) {
            return {
                ...project,
                children: item.objects.map(item => {
                    return {
                        label: item.objectName,
                        checked: item.boolParam,
                        type: HIDING_PROJECT_OBJECT_TYPE.OBJECT,
                        value: item.objectId,
                    };
                }),
            };
        }

        return project;
    });

    if (isVisibleClient) {
        return [
            {
                expanded: true,
                label: clientName,
                value: clientId,
                type: HIDING_PROJECT_OBJECT_TYPE.CLIENT,
                nextAllPagesSelectionType,
                checked: clientSelectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.FULLY,
                partiallyChecked: clientSelectorBoolType === CLIENT_PROPERTIES_PARAM_SELECTOR_TYPE.PARTIALLY,
                children: projectsChildren,
            },
        ];
    }

    return projectsChildren;
};