import React, {useState} from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../NmDropdownV2";

import bem from "../../../utils/bem";
import {getBankOptions, getDadataBankFormatter} from "../../../utils/dadata";

import {DADATA} from "../../../constants/dadata";

import {strapiDktnSelector} from "../../../ducks/strapi";

interface INmDadataBank {
    count?: number,
    name?: string,
    onChange: (event?: any, params?: any) => void,
    className?: string,
    size?: "lg" | "xl",
    label?: string,
    isClearable?: boolean,
    onSubmit?: () => void,
    isAllSelectionShown?: boolean
}

const NmDadataBank = (props: INmDadataBank) => {
    const {
        name,
        onChange,
        className,
        count,
    } = props;

    const dtkn = useSelector(strapiDktnSelector);

    const [block] = bem("dadata-bank-select", className);

    const [options, setOptions] = useState<any>([]);
    const [selectedBank, setSelectedBank] = useState("");

    const fetchBanks = (searchQuery: string, url: string, mode?: string) => {
        if (url === DADATA.DADATA_ADDRESS_URL && !searchQuery) {
            return;
        }

        const params = {
            method: "POST",
            mode: "cors" as RequestMode,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Token ${dtkn}`,
            },
            body: JSON.stringify({
                count,
                query: searchQuery,
                status: ["ACTIVE"],
            }),
        };

        fetch(url, params)
            .then((response) => response.json())
            .then((response) => {
                const opts = getBankOptions(response.suggestions, getDadataBankFormatter);

                setOptions(opts);
            })
            .then((error) => {
                console.warn(error);
            });
    };

    const onSearchChange = (valueFilter: string) => {
        fetchBanks(valueFilter, DADATA.DADATA_BANK_URL);
    };

    const handleChange = (e: any, option: any) => {
        const {value} = option;

        setSelectedBank(value);
        onChange(e, {name, ...option});
    };

    return (
        <NmDropdownV2
            {...props}
            className={block()}
            onSearchChange={onSearchChange}
            onChange={handleChange}
            search
            dadata
            value={selectedBank}
            options={options}
        />
    );
};

export default NmDadataBank;