import {
    BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_API_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_OTHER_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_API_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST,
    BFF_CLIENTS_SETTINGS_UPDATE_STORE,
} from "./actions";

export const getClientsSettingsPayments = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST,
        payload,
    };
};

export const updateClientsSettingsPayments = (payload) => {
    return {
        type: BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST,
        payload,
    };
};

export const getClientsSettingsOther = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_OTHER_REQUEST,
        payload,
    };
};

export const updateClientsSettingsOther = (payload) => {
    return {
        type: BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST,
        payload,
    };
};

export const getClientsSettingsApi = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_API_REQUEST,
        payload,
    };
};

export const updateClientsSettingsApi = (payload) => {
    return {
        type: BFF_CLIENTS_PUT_SETTINGS_API_REQUEST,
        payload,
    };
};

export const getClientsSettingsContractors = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_REQUEST,
        payload,
    };
};

export const updateClientsSettingsContractors = (payload) => {
    return {
        type: BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST,
        payload,
    };
};

export const getClientsSettingsAdditionalModules = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_REQUEST,
        payload,
    };
};

export const updateClientsSettingsAdditionalModules = (payload) => {
    return {
        type: BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST,
        payload,
    };
};

export const getClientsSettingsDocuments = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_REQUEST,
        payload,
    };
};

export const updateClientsSettingsDocuments = (payload) => {
    return {
        type: BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST,
        payload,
    };
};

export const getClientsSettingsHideOrderProjectsPage = (payload) => {
    return {
        type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getClientsSettingsHideOrderProjectsObjectsList = (payload) => {
    return {
        type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST,
        payload,
    };
};

export const getClientsSettingsHideOrdersObjectsPage = (payload) => {
    return {
        type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST,
        payload,
    };
};

export const hideClientsSettingsOrders = (payload) => {
    return {
        type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST,
        payload,
    };
};

export const getClientsSettingsLogs = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST,
        payload,
    };
};

export const getClientSettingsProjectsTree = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getClientSettingsObjectsTree = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST,
        payload,
    };
};

export const getClientSettingsTreeList = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST,
        payload,
    };
};

export const saveClientSettingsTree = (payload) => {
    return {
        type: BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST,
        payload,
    };
};

export const updateStoreClientsSetting = (payload) => {
    return {
        type: BFF_CLIENTS_SETTINGS_UPDATE_STORE,
        payload,
    };
};