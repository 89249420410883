import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffCrowdActRegistrySelector = state => state.bff.crowd.actRegistryEntry;
export const bffCrowdActRegistryEntryListSelector = createSelector(bffCrowdActRegistrySelector, ({list}) => list);
export const bffCrowdActRegistryEntryTotalCountSelector = createSelector(bffCrowdActRegistrySelector, ({totalCount}) => totalCount);
export const bffCrowdActRegistryEntryTotalPagesSelector = createSelector(bffCrowdActRegistrySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffCrowdActRegistryEntryProgressSelector = createSelector(bffCrowdActRegistrySelector, ({progress}) => progress);
export const bffCrowdActRegistryEntryProgressActionSelector = createSelector(bffCrowdActRegistrySelector, ({progressAction}) => progressAction);
export const bffCrowdActRegistryEntryCardSelector = createSelector(bffCrowdActRegistrySelector, ({card}) => card);
export const bffCrowdActRegistryEntryProgressCardSelector = createSelector(bffCrowdActRegistrySelector, ({progressCard}) => progressCard);
export const bffCrowdActRegistryEntryErrorsListSelector = createSelector(bffCrowdActRegistrySelector, ({errorsList}) => errorsList);
export const bffCrowdActRegistryEntryErrorsTotalCountSelector = createSelector(bffCrowdActRegistrySelector, ({errorsTotalCount}) => errorsTotalCount);
export const bffCrowdActRegistryEntryErrorsTotalPagesSelector = createSelector(bffCrowdActRegistrySelector, ({errorsTotalCount, errorsPageData: {pageSize = 0}}) => getTotalPages(errorsTotalCount, pageSize));
export const bffCrowdActRegistryEntryErrorsProgressSelector = createSelector(bffCrowdActRegistrySelector, ({errorsProgress}) => errorsProgress);
