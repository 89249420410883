import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {OrderEditContext} from "../../containers/order/order-edit/context-provider";
import DropdownWithInput from "../ActualComponents/DropdownWithInput";
import HelpTooltip from "../ActualComponents/HelpTooltip";

import {handleFormString} from "../../utils/stringHelper";

import {directoriesReg} from "../../constants/validationRules";

import {getKeywordsAutocompletePage, getKeywordsOptionsSelector} from "../../ducks/keyword";

import "./style.sass";

const OrderKeywords = (props) => {
    const {
        editOrderId,
        isDuplicateOrder,
        isForemanRequestForAddition,
    } = useContext(OrderEditContext);

    const {t} = useTranslation();

    useEffect(() => {
        if ((editOrderId || isDuplicateOrder) && !isForemanRequestForAddition) {
            fetchKeywords();
        }
    }, []);

    const dispatch = useDispatch();

    const keywordsOptions = useSelector(getKeywordsOptionsSelector);

    const fetchKeywords = (keywordStartWithFilter) => {
        dispatch(getKeywordsAutocompletePage({
            pageNum: 1,
            pageSize: 150,
            keywordStartWithFilter: handleFormString(keywordStartWithFilter),
        }));
    };

    return (
        <DropdownWithInput
            size="xl"
            label={t("order-new.keywords")}
            fetchCallback={fetchKeywords}
            placeholder="Введите ключевые слова"
            minCharacters={3}
            maxCharacters={100}
            minSearchLength={4}
            isDetectEqualValues={true}
            isClearInputValueOnBlur={true}
            options={keywordsOptions}
            match={directoriesReg}
            tooltip={
                <HelpTooltip
                    info
                    hover
                    text={t("order-new.keywordSuggestionContent")}
                    position="bottom-left"
                />
            }
            {...props}
        />
    );
};

export default OrderKeywords;