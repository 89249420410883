import {STATUS_PASSPORT_INN} from "../../../../../../components/NmStatusPassportInnContractor";

export const getContractorSkipInnPassportConfirmContent = (data) => {
    const {
        innPassportDataValidStatus,
    } = data;

    const isFirstType = [STATUS_PASSPORT_INN.NOT_CHECKED.VALUE, STATUS_PASSPORT_INN.FNS_ERROR.VALUE].includes(innPassportDataValidStatus);
    const isSecondType = [STATUS_PASSPORT_INN.NOT_EXIST.VALUE, STATUS_PASSPORT_INN.NOT_EQUALS.VALUE].includes(innPassportDataValidStatus);

    const getTextModal = () => {
        if (isFirstType) {
            return "Проверка соответствия ИНН и паспортных данных еще не проводилась либо сервис ФНС России недоступен для проверки. Вы уверены, что хотите сохранить данные исполнителя и отправить их на проверку в банк?";
        }

        if (isSecondType) {
            return "ИНН не соответствует паспортным данным. Вы уверены, что хотите сохранить данные исполнителя и отправить их на проверку в банк?";
        }

        return "";
    };

    const getButtonsData = () => {
        return {
            cancelBtnColor: "red",
            cancelBtnContent: "Отменить",
            submitBtnContent: "Подтвердить",
        };
    };

    const {
        submitBtnContent,
        cancelBtnContent,
        cancelBtnColor,
    } = getButtonsData();

    return {
        text: getTextModal(),
        submitBtnContent,
        cancelBtnContent,
        cancelBtnColor,
    };
};