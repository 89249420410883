import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import bem from "../../utils/bem";

import {history} from "../../store/configureStore";

import PropTypes from "prop-types";

import "./style.sass";

class ExtLink extends Component {
    static propTypes = {
        to: PropTypes.string,
        enabled: PropTypes.bool,
        location: PropTypes.object.isRequired,
        historyEnabled: PropTypes.bool,
        style: PropTypes.object,
        className: PropTypes.string,
        onClick: PropTypes.func,
        noWrap: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        pageData: {},
        isLoadDataTarget: false,
        filterData: {},
        enabled: true,
        historyEnabled: false,
        className: "",
        onClick: () => {},
        noWrap: false,
    };

    constructor(props) {
        super(props);

        const [block, element] = bem("link-ext", props.className);

        this.block = block;
        this.element = element;
    }

    getToLinkObj = () => {
        const {historyEnabled, to, location, pageData, filterData, isLoadDataTarget, extData} = this.props;

        if (historyEnabled) {
            return {pathname: to, state: {prevPath: location.pathname, pageData, filterData, isLoadDataTarget, extData}};
        } else {
            return to;
        }
    };

    handleClick = () => {
        const {historyEnabled, location, pageData, filterData, isLoadDataTarget, pathname, extData, onClick} = this.props;

        if (historyEnabled) {
            // перезаписываем объект текущей страницы с которой делаем переход, необходимо для работы браузерных кнопок вперед/назад
            history.replace(pathname, {prevPath: location.pathname, pageData, filterData, extData, isLoadDataTarget: !isLoadDataTarget});
        }
        onClick();
    };

    renderEnabledLink = () => {
        const {
            children,
            style,
            target,
            title,
            noWrap,
        } = this.props;

        return (
            <Link
                title={title}
                target={target}
                className={this.block({noWrap})}
                onClick={this.handleClick}
                style={style}
                to={this.getToLinkObj()}
            >
                {children}
            </Link>
        );
    };

    renderDisabledLink = () => {
        const {children} = this.props;

        return (
            <div className={this.element("caption")}>
                {children}
            </div>
        );
    };

    render() {
        const {enabled} = this.props;

        return (
            <>
                {enabled ?
                    this.renderEnabledLink() :
                    this.renderDisabledLink()
                }
            </>
        );
    }
}

export default connect(state => ({
    location: state.router.location,
    pathname: state.router.location.pathname,
}), {})(ExtLink);
