import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";

import NmModal from "../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";

import pluralize from "../../../../utils/pluralize";

import {CONTRACTORS_TABS_TYPES} from "../../../../constants/contractor";
import {LINK_CLIENT_INVITING_CONTRACTORS_LIST} from "../../../../constants/links";

import {history} from "../../../../store/configureStore";

import {contractorCountSelector} from "../../../../ducks/contractor";
import {getCreatedOrderInfoSelector,orderClearFieldStore} from "../../../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

const OrderCreateSuccess = (props) => {
    const {
        t,
        orderClearFieldStore,
        createdOrderInfo: {
            orderId,
            name = "",
            orderNum,
        },
        contractorCount = 0,
        handleClose,
    } = props;
    const {specialityId} = useSelector(getCreatedOrderInfoSelector);

    useEffect(() => {
        return () => orderClearFieldStore({createdOrderInfo: {}});
    }, []);

    const getPluralizedCount = (count) => pluralize(count, [t("orderCreateSuccess.performer1"), t("orderCreateSuccess.performer2"), t("orderCreateSuccess.performer3")]);

    const transformFindWordByCount = count => count === 1 ? t("orderCreateSuccess.findWord1") : t("orderCreateSuccess.findWord2");

    const goSearchPerformers = () => {
        const {
            clientId,
            pageData = {},
            location,
        } = props;

        const state = {
            extData: {
                name,
                orderNum,
            },
            prevPath: location.pathname,
            pageData,
        };

        const link = LINK_CLIENT_INVITING_CONTRACTORS_LIST
            .replace(":type", CONTRACTORS_TABS_TYPES.BY_ORDER)
            .replace(":clientId", clientId)
            .replace(":orderId", orderId);

        history.push(link, state);
    };

    return (
        <NmModal
            size="md"
            onClose={handleClose}
            className="order-create-success"
            footer={
                <ApplyButtons
                    mobile="column"
                    className="order-create-success__apply-buttons"
                    submitBtnContent={t("orderCreateSuccess.buttonGoToListPerformers")}
                    cancelBtnContent={t("button.close")}
                    onClose={handleClose}
                    submit={goSearchPerformers}
                />
            }
            classNameHeader="order-create-success__header"
            header={
                <NmTitle size="lg">
                    <div className="order-create-success__ok">
                        <span className="material-icons order-create-success__ok-icon">
                            check
                        </span>
                    </div>
                    <div className="order-create-success__header-content">
                        Заказ опубликован
                    </div>
                </NmTitle>
            }
        >
            <div className="order-create-success__message">
                {
                    t("orderCreateSuccess.content", {
                        name,
                        count: transformFindWordByCount(contractorCount),
                        pluralizedPerformer: getPluralizedCount(contractorCount),
                    })
                }
            </div>
        </NmModal>
    );
};

OrderCreateSuccess.propTypes = {
    contractorCount: PropTypes.number,
    handleClose: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    filterData: PropTypes.object,
    pageData: PropTypes.object,
};

OrderCreateSuccess.defaultProps = {
    contractorCount: 0,
};

export default connect(
    state => ({
        location: state.router.location,
        contractorCount: contractorCountSelector(state),
        createdOrderInfo: getCreatedOrderInfoSelector(state),
    }),
    {
        orderClearFieldStore,
    },
)(withTranslation()(OrderCreateSuccess));