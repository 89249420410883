import {getPageCrowdTasks} from "../../crowd/tasks/actionCreators";

const controller = "/adm/crowd/tasks";

export const getPageAdmCrowdTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${controller}/page`,
        ...payload,
    });
};

export const getPageAdmCrowdProjectTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${controller}/by-project/page`,
        ...payload,
    });
};

export const getPageAdmCrowdObjectTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${controller}/by-object/page`,
        ...payload,
    });
};