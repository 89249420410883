import React from "react";

import {ContractorFinanceIncomeCrowd} from "../index";

import {dictionaryToOptions} from "../../../../../utils/objectHelper";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
} from "../../../../../constants/links";

import {getPageCrowdPaymentContractorInProgress} from "../../../../../ducks/bff/contractor/crowd-payments/services";

import {STATUS_TASKS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS} from "../../../../../constants/crowd/task-payment";

const options = dictionaryToOptions(STATUS_TASKS_PAYMENT_CONTRACTOR_INCOME_IN_PROCESS);

export const ContractorFinanceIncomeCrowdInProgress = (props) => {
    return (
        <ContractorFinanceIncomeCrowd
            subTabsLinks={{
                orders: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
                tasks: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
            }}
            fetchList={getPageCrowdPaymentContractorInProgress}
            isShowItemActions={false}
            isShowNaimixStatusFilter={true}
            naimixStatusFilterOptions={options}
            {...props}
        />
    );
};
