import {isEmpty} from "lodash";

import {getNumberFromFormattedAmount} from "../../../../../../utils/stringFormat";

import {KEDO_TEMPLATE_DATA_TYPES, KEDO_TEMPLATE_RESTRICTION_TYPE} from "../constants";

export const getKedoTemplateRestrictionsType = (values) => {
    if (
        [
            KEDO_TEMPLATE_DATA_TYPES.STRING,
        ].includes(values.type)
    ) {
        return {
            type: KEDO_TEMPLATE_RESTRICTION_TYPE.STRING_SIZE,
            max: values.maxLength ? +values.maxLength : null,
        };
    }

    if (
        [
            KEDO_TEMPLATE_DATA_TYPES.MULTIPLE_STRING_SELECTION,
            KEDO_TEMPLATE_DATA_TYPES.STRING_SELECTION,
        ].includes(values.type)
    ) {
        return {
            type: KEDO_TEMPLATE_RESTRICTION_TYPE.AVAILABLE_VALUES,
            availableValues: !isEmpty(values.availableValues) ?
                values.availableValues.map(item => item.content) :
                [],
            multiSelection: values.type === KEDO_TEMPLATE_DATA_TYPES.MULTIPLE_STRING_SELECTION,
        };
    }

    if (
        [
            KEDO_TEMPLATE_DATA_TYPES.MONEY,
        ].includes(values.type)
    )  {
        return {
            type: KEDO_TEMPLATE_RESTRICTION_TYPE.NUMBER_SIZE,
            min: values.minValue ? getNumberFromFormattedAmount(values.minValue) : null,
            max: values.maxValue ? getNumberFromFormattedAmount(values.maxValue) : null,
        };
    }

    if (
        [
            KEDO_TEMPLATE_DATA_TYPES.MONEY,
            KEDO_TEMPLATE_DATA_TYPES.DOUBLE,
        ].includes(values.type)
    )  {
        return {
            type: KEDO_TEMPLATE_RESTRICTION_TYPE.NUMBER_SIZE,
            min: values.minValue ? getNumberFromFormattedAmount(values.minValue) : null,
            max: values.maxValue ? getNumberFromFormattedAmount(values.maxValue) : null,
        };
    }

    if (
        [
            KEDO_TEMPLATE_DATA_TYPES.INTEGER,
        ].includes(values.type)
    )  {
        return {
            type: KEDO_TEMPLATE_RESTRICTION_TYPE.NUMBER_SIZE,
            min: values.minValue ? +values.minValue : null,
            max: values.maxValue ? +values.maxValue : null,
        };
    }
};