import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/actregistries";

const ACT_REGISTRIES_DELETE_REQUEST = "ACT_REGISTRIES_DELETE_REQUEST";
const ACT_REGISTRIES_DELETE_SUCCESS = "ACT_REGISTRIES_DELETE_SUCCESS";
const ACT_REGISTRIES_DELETE_ERROR = "ACT_REGISTRIES_DELETE_ERROR";

const ACT_REGISTRIES_ADD_REQUEST = "ACT_REGISTRIES_ADD_REQUEST";
const ACT_REGISTRIES_ADD_SUCCESS = "ACT_REGISTRIES_ADD_SUCCESS";
const ACT_REGISTRIES_ADD_ERROR = "ACT_REGISTRIES_ADD_ERROR";

const ACT_REGISTRIES_GET_RICH_BY_ID_REQUEST = "ACT_REGISTRIES_GET_RICH_BY_ID_REQUEST";
const ACT_REGISTRIES_GET_RICH_BY_ID_SUCCESS = "ACT_REGISTRIES_GET_RICH_BY_ID_SUCCESS";
const ACT_REGISTRIES_GET_RICH_BY_ID_ERROR = "ACT_REGISTRIES_GET_RICH_BY_ID_ERROR";

const ACT_REGISTRIES_GET_BY_ID_REQUEST = "ACT_REGISTRIES_GET_BY_ID_REQUEST";
const ACT_REGISTRIES_GET_BY_ID_SUCCESS = "ACT_REGISTRIES_GET_BY_ID_SUCCESS";
const ACT_REGISTRIES_GET_BY_ID_ERROR = "ACT_REGISTRIES_GET_BY_ID_ERROR";

const ACT_REGISTRIES_GET_PAGE_REQUEST = "ACT_REGISTRIES_GET_PAGE_REQUEST";
const ACT_REGISTRIES_GET_PAGE_SUCCESS = "ACT_REGISTRIES_GET_PAGE_SUCCESS";
const ACT_REGISTRIES_GET_PAGE_ERROR = "ACT_REGISTRIES_GET_PAGE_ERROR";

const ACT_REGISTRIES_PAY_REQUEST = "ACT_REGISTRIES_GET_PAY_REQUEST";
const ACT_REGISTRIES_PAY_SUCCESS = "ACT_REGISTRIES_GET_PAY_SUCCESS";
const ACT_REGISTRIES_PAY_ERROR = "ACT_REGISTRIES_GET_PAY_ERROR";

const ACT_REGISTRIES_SINGLE_PAY_REQUEST = "ACT_REGISTRIES_SINGLE_PAY_REQUEST";
const ACT_REGISTRIES_SINGLE_PAY_SUCCESS = "ACT_REGISTRIES_SINGLE_PAY_SUCCESS";
const ACT_REGISTRIES_SINGLE_PAY_ERROR = "ACT_REGISTRIES_SINGLE_PAY_ERROR";

const ACT_REGISTRIES_UPDATE_REQUEST = "ACT_REGISTRIES_GET_UPDATE_REQUEST";
const ACT_REGISTRIES_UPDATE_SUCCESS = "ACT_REGISTRIES_GET_UPDATE_SUCCESS";
const ACT_REGISTRIES_UPDATE_ERROR = "ACT_REGISTRIES_GET_UPDATE_ERROR";

const ACT_REGISTRIES_ARCHIVE_REQUEST = "ACT_REGISTRIES_ARCHIVE_REQUEST";
const ACT_REGISTRIES_ARCHIVE_SUCCESS = "ACT_REGISTRIES_ARCHIVE_SUCCESS";
const ACT_REGISTRIES_ARCHIVE_ERROR = "ACT_REGISTRIES_ARCHIVE_ERROR";

const ACT_REGISTRIES_CHECK_DUPLICATE_REQUEST = "ACT_REGISTRIES_CHECK_DUPLICATE_REQUEST";
const ACT_REGISTRIES_CHECK_DUPLICATE_SUCCESS = "ACT_REGISTRIES_CHECK_DUPLICATE_SUCCESS";
const ACT_REGISTRIES_CHECK_DUPLICATE_ERROR = "ACT_REGISTRIES_CHECK_DUPLICATE_ERROR";

const ACT_REGISTRIES_UPDATE_STORE = "ACT_REGISTRIES_UPDATE_STORE";

const initialState = {
    list: [],
    totalCount: 0,
    progressAction: false,
    card: {},
    progressCard: false,
    pageData: {
        pageNum: 1,
        pageSize: 25,
    },
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
    case ACT_REGISTRIES_PAY_REQUEST:
    case ACT_REGISTRIES_CHECK_DUPLICATE_REQUEST:
    case ACT_REGISTRIES_ADD_REQUEST:
    case ACT_REGISTRIES_UPDATE_REQUEST:
        return {
            ...state,
            progressAction: true,
        };
    case ACT_REGISTRIES_PAY_SUCCESS:
    case ACT_REGISTRIES_CHECK_DUPLICATE_SUCCESS:
    case ACT_REGISTRIES_ADD_SUCCESS:
    case ACT_REGISTRIES_UPDATE_SUCCESS:
        return {
            ...state,
            progressAction: false,
        };
    case ACT_REGISTRIES_PAY_ERROR:
    case ACT_REGISTRIES_CHECK_DUPLICATE_ERROR:
    case ACT_REGISTRIES_ADD_ERROR:
    case ACT_REGISTRIES_UPDATE_ERROR:
        return {
            ...state,
            progressAction: false,
        };
    case ACT_REGISTRIES_GET_RICH_BY_ID_REQUEST:
    case ACT_REGISTRIES_GET_BY_ID_REQUEST:
        return {
            ...state,
            progressCard: true,
        };
    case ACT_REGISTRIES_GET_RICH_BY_ID_ERROR:
    case ACT_REGISTRIES_GET_BY_ID_ERROR:
        return {
            ...state,
            progressCard: false,
        };
    case ACT_REGISTRIES_GET_BY_ID_SUCCESS:
    case ACT_REGISTRIES_GET_RICH_BY_ID_SUCCESS:
        return {
            ...state,
            progressCard: false,
            card: payload,
        };
    case ACT_REGISTRIES_GET_PAGE_REQUEST:
        return {
            ...state,
            pageData: payload,
            progress: true,
        };
    case ACT_REGISTRIES_GET_PAGE_SUCCESS:
        const {
            registries,
            totalCount,
        } = payload;

        return {
            ...state,
            list: registries,
            totalCount,
            progress: false,
        };

    case ACT_REGISTRIES_GET_PAGE_ERROR:
        return {
            ...state,
            progress: false,
        };
    case ACT_REGISTRIES_UPDATE_STORE:
        return {
            ...state,
            ...payload,
        };
    default:
        return state;
    }
};

export const deleteActRegistry = (payload) => ({
    type: ACT_REGISTRIES_DELETE_REQUEST,
    payload,
});

export const addActRegistry = (payload) => ({
    type: ACT_REGISTRIES_ADD_REQUEST,
    payload,
});

export const getActRegistry = (payload) => ({
    type: ACT_REGISTRIES_GET_BY_ID_REQUEST,
    payload,
});

export const getRichActRegistry = (payload) => ({
    type: ACT_REGISTRIES_GET_RICH_BY_ID_REQUEST,
    payload,
});

export const getActRegistries = (payload) => ({
    type: ACT_REGISTRIES_GET_PAGE_REQUEST,
    payload,
});

export const payActRegistry = (payload) => ({
    type: ACT_REGISTRIES_PAY_REQUEST,
    payload,
});

export const paySingleAct = (payload) => ({
    type: ACT_REGISTRIES_SINGLE_PAY_REQUEST,
    payload,
});


export const updateActRegistry = (payload) => ({
    type: ACT_REGISTRIES_UPDATE_REQUEST,
    payload,
});

export const updateActRegistriesStore = (payload) => ({
    type: ACT_REGISTRIES_UPDATE_STORE,
    payload,
});

export const archiveActRegistry = (payload) => ({
    type: ACT_REGISTRIES_ARCHIVE_REQUEST,
    payload,
});

export const checkDuplicateActRegistry = (payload) => ({
    type: ACT_REGISTRIES_CHECK_DUPLICATE_REQUEST,
    payload,
});

export const actRegistriesSelector = state => state.actRegistries;
export const actRegistriesListSelector = createSelector(actRegistriesSelector, ({list}) => list);
export const actRegistriesProgressListSelector = createSelector(actRegistriesSelector, ({progress}) => progress);
export const actRegistriesCardSelector = createSelector(actRegistriesSelector, ({card}) => card);
export const actRegistriesProgressActionSelector = createSelector(actRegistriesSelector, ({progressAction}) => progressAction);
export const actRegistriesProgressCardSelector = createSelector(actRegistriesSelector, ({progressCard}) => progressCard);
export const actRegistriesTotalCountSelector = createSelector(actRegistriesSelector, ({totalCount}) => totalCount);
export const actRegistriesTotalPagesSelector = createSelector(actRegistriesSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));

//DELETE /api/actregistries/{clientId}/{registryId}
const deleteActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            registryId,
        } = payload;
        const result = yield request.delete(`${controller}/${clientId}/${registryId}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ACT_REGISTRIES_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ACT_REGISTRIES_DELETE_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_DELETE_ERROR,
        });
    }
};

//POST /api/actregistries/add
const addActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/add`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ACT_REGISTRIES_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ACT_REGISTRIES_ADD_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_ADD_ERROR,
        });
    }
};

//GET /api/actregistries/getRichById
const getRichActRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;
        const result = yield request.get(`${controller}/getRichById?clientId=${clientId}&registryId=${registryId}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: ACT_REGISTRIES_GET_RICH_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ACT_REGISTRIES_GET_RICH_BY_ID_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_GET_RICH_BY_ID_ERROR,
            payload: error,
        });
    }
};

//POST /api/actregistries/getPage
const getActRegistriesSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: ACT_REGISTRIES_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ACT_REGISTRIES_GET_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_GET_PAGE_ERROR,
        });
    }
};

//POST /api/actregistries/pay
const payActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            getError = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/pay`, payload);

        if (result.errorMessage) {
            getError(result);

            yield put({
                type: ACT_REGISTRIES_PAY_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: ACT_REGISTRIES_PAY_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_PAY_ERROR,
        });
    }
};

//api/actregistries/paySingleAct
const paySingleActSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            getError = () => {
            },
        } = payload;

        const result = yield request.post(`${controller}/paySingleAct`, payload);

        if (result.errorMessage) {
            getError(result);

            yield put({
                type: ACT_REGISTRIES_SINGLE_PAY_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: ACT_REGISTRIES_SINGLE_PAY_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_SINGLE_PAY_ERROR,
        });
    }
};

//POST /api/actregistries/update
const updateActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/update`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ACT_REGISTRIES_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ACT_REGISTRIES_UPDATE_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_UPDATE_ERROR,
        });
    }
};

//GET /api/actregistries/getById
const getActRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;
        const result = yield request.get(`${controller}/getById?clientId=${clientId}&registryId=${registryId}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ACT_REGISTRIES_GET_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ACT_REGISTRIES_GET_BY_ID_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_GET_BY_ID_ERROR,
        });
    }
};

//POST /api/actregistries/{clientId}/{registryId}/archived/{archived}
const archiveActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            archived,
            registryId,
        } = payload;
        const result = yield request.post(`${controller}/${clientId}/${registryId}/archived/${archived}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: ACT_REGISTRIES_ARCHIVE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ACT_REGISTRIES_ARCHIVE_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_ARCHIVE_ERROR,
        });
    }
};

//POST
// /api/actregistries/checkForDuplicates
const checkDuplicateActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            getError = () => {
            },
            ...data
        } = payload;

        const result = yield request.post(`${controller}/checkForDuplicates`, data);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            getError(result.errorMessage);

            yield put({
                type: ACT_REGISTRIES_CHECK_DUPLICATE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);
        yield put({
            type: ACT_REGISTRIES_CHECK_DUPLICATE_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: ACT_REGISTRIES_CHECK_DUPLICATE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ACT_REGISTRIES_DELETE_REQUEST, deleteActRegistrySaga),
        takeEvery(ACT_REGISTRIES_ARCHIVE_REQUEST, archiveActRegistrySaga),
        takeEvery(ACT_REGISTRIES_ADD_REQUEST, addActRegistrySaga),
        takeEvery(ACT_REGISTRIES_GET_RICH_BY_ID_REQUEST, getRichActRegistrySaga),
        takeEvery(ACT_REGISTRIES_GET_BY_ID_REQUEST, getActRegistrySaga),
        takeEvery(ACT_REGISTRIES_GET_PAGE_REQUEST, getActRegistriesSaga),
        takeEvery(ACT_REGISTRIES_PAY_REQUEST, payActRegistrySaga),
        takeEvery(ACT_REGISTRIES_UPDATE_REQUEST, updateActRegistrySaga),
        takeEvery(ACT_REGISTRIES_CHECK_DUPLICATE_REQUEST, checkDuplicateActRegistrySaga),
        takeEvery(ACT_REGISTRIES_SINGLE_PAY_REQUEST, paySingleActSaga),
    ]);
}
