import * as yup from "yup";

import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../../constants/validationsYup";


const validationSchema = () => {
    const numbersRegExp = /[^0-9\s]+/;
    const migrationCardRegExp = /^[0-9]{4}\s{1}[0-9]{7}$/;

    return yup.object().shape({
        [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .length(12, "Номер карты может состоять из 12 символов вместе с пробелом")
            .test("checkSymbol", "Недопустимые символы", function (value) {
                if (!value) {
                    return true;
                }
                return !numbersRegExp.test(value);
            })
            .matches(migrationCardRegExp, { message: "Формат для ввода: 1234 1234567", excludeEmptyString: false }),
        [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: yup.string()
            .nullable(),
        [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: yup.string()
            .nullable(),
    });
};

export default validationSchema;